import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {getEntries, isNotEmptyNorFalsy, objEquals} from '../../../utilities/helperFunctions';
import ExpandableContent, {
    ExpandableLog,
    ExpandableOperationBacklogTable,
    ExpandableParametersTable,
    ExpandableRequiredProfilesTable
} from '../../common/ExpandableContent/ExpandableContent';
import {SettingsRowSeparator, SettingsRowValue} from '../../common/CustomTable/CustomTable';
import {getPriorityIcon, getStatusIconImgContent} from "../../../utilities/iconResolver";
import LoadingWrapper from "../../common/LoadingWrapper/LoadingWrapper";
import {StatusLabel} from "../../common/Common";
import {permissionKeys, priorityKeys} from "../../../i18next/keys";
import {jobQueueStates} from "../../../utilities/constants";
import Text from "../../common/Text/Text";
import {selectDetailsMapForJobNames, selectNamesFromJob} from "../selectors";

function JobConfirmPane(props) {
    const {t} = useTranslation(['job', 'common']);

    const {
        jobState,
        parameterBlacklist
    } = props;

    const {
        jobName,
        priority,
        notes,
        parameters,
        queueState,
        requiredProfiles,
        executionProfileId,
        operations,

        isLoadingSettings,
        isDisabled
    } = jobState;

    const isLoading = jobState.isLoading || isLoadingSettings;
    const executionProfileMissingRequiredProfiles = requiredProfiles != null && requiredProfiles.some(profile => profile.missing);
    const detailsMap = useSelector(selectDetailsMapForJobNames, objEquals);
    const names = selectNamesFromJob(detailsMap, jobState);
    const canViewSensitive = useSelector(state => {
        const workflow = state.workflowTemplateDetailsMap.get(jobState.workflowTemplateId);
        if (workflow != null) {
            return workflow.userPermissions.includes(permissionKeys.VIEW_SENSITIVE);
        }
    });

    const [filteredParameters, setFilteredParameters] = useState(new Map());
    useEffect(() => {
        if (parameterBlacklist?.size > 0) {
            const filteredEntries = getEntries(parameters).filter(([name]) => !parameterBlacklist.has(name));
            setFilteredParameters(new Map(filteredEntries));
        } else {
            setFilteredParameters(parameters);
        }
    }, [parameters, parameterBlacklist]);

    return (
        <div className="pane-margin">
            <LoadingWrapper isLoading={isLoading}>
                <div className="display-input">
                    <ExpandableJobSettingsTable jobName={jobName} queueState={queueState} names={names} priority={priority}
                        executionProfileMissingRequiredProfiles={executionProfileMissingRequiredProfiles} isDisabled={isDisabled}/>
                </div>
                {notes &&
                    <div className="display-input">
                        <ExpandableLog label={t('job:label.notes')} log={notes} isDisabled={isDisabled} />
                    </div>
                }
                {isNotEmptyNorFalsy(parameters) &&
                    <div className="display-input">
                        <ExpandableParametersTable parameters={filteredParameters} useUserFromCredential
                            isDisabled={isDisabled}/>
                    </div>
                }
                {isNotEmptyNorFalsy(requiredProfiles) &&
                    <div className="display-input">
                        <ExpandableRequiredProfilesTable requiredProfiles={requiredProfiles} executionProfileId={executionProfileId}
                            isDisabled={isDisabled}/>
                    </div>
                }
                <div className="display-input">
                    <ExpandableOperationBacklogTable label={t('job:label.workflow')} operations={operations}
                        viewSensitive={canViewSensitive} isDisabled={isDisabled}/>
                </div>
            </LoadingWrapper>
        </div>
    )
}

function ExpandableJobSettingsTable(props) {
    const {t} = useTranslation(['job', 'common']);
    const {jobName, names, queueState, priority, executionProfileMissingRequiredProfiles, isDisabled} = props;

    const disabled = isDisabled ? ' is-disabled' : '';

    let executionProfileLabel;
    if (names.executionProfile) {
        if (executionProfileMissingRequiredProfiles) {
            executionProfileLabel =
                <div style={{display: 'flex'}}>
                    <label className={'label' + disabled}>
                        {names.executionProfile}
                    </label>
                    <StatusLabel message={t('job:message.executionProfileMissingProfiles')} style={{marginLeft: '1rem'}}
                        isDisabled={isDisabled}/>
                </div>
        } else {
            executionProfileLabel = names.executionProfile;
        }
    } else {
        executionProfileLabel =
            <div style={{display: 'flex'}}>
                <label className={'label' + disabled}>
                    {t('common:label.unassigned')}
                </label>
                <StatusLabel message={t('job:message.unassignedExecutionProfile')} style={{marginLeft: '1rem'}}
                    isDisabled={isDisabled}/>
            </div>
    }

    let resourcePoolLabel;
    if (names.resourcePool) {
        resourcePoolLabel = names.resourcePool;
    } else {
        resourcePoolLabel =
            <div style={{display: 'flex'}}>
                <label className={'label' + disabled}>
                    {t('common:label.unassigned')}
                </label>
                <StatusLabel message={t('job:message.unassignedResourcePool')} style={{marginLeft: '1rem'}}
                    isDisabled={isDisabled}/>
            </div>
    }

    let priorityLabel = t(`common:priority.${priority}`);
    if (priority !== priorityKeys.MEDIUM) {
        priorityLabel = (
            <div className="flex-center">
                <span className="icon is-small" style={{marginRight: '0.25rem'}}>
                    <img src={getPriorityIcon(priority)} alt={t(`aria:hiddenAssistText.${priority}PriorityIcon`)}/>
                </span>
                <Text value={priorityLabel} isDisabled={isDisabled}/>
            </div>
        )
    }

    let stagingLabel = t(`job:queueState.${queueState}`);
    if (queueState === jobQueueStates.STAGING) {
        stagingLabel = (
            <div className="flex-center">
                <span className="icon is-small" style={{marginRight: '0.25rem'}}>
                    {getStatusIconImgContent('statusPaused')}
                </span>
                <Text value={stagingLabel} isDisabled={isDisabled}/>
            </div>
        );
    }

    return (
        <ExpandableContent label={t('job:label.settings')} isDisabled={isDisabled}>
            <div className="settings-table">
                <div className="table-row-group">
                    <SettingsRowValue label={t('common:label.name')} value={jobName} isDisabled={isDisabled}/>

                    <SettingsRowSeparator/>
                    <SettingsRowValue label={t('job:label.executionProfile')} value={executionProfileLabel} isDisabled={isDisabled}/>
                    <SettingsRowValue label={t('job:label.resourcePool')} value={resourcePoolLabel} isDisabled={isDisabled}/>
                    <SettingsRowValue label={t('job:label.priority')} value={priorityLabel} isDisabled={isDisabled} />
                    <SettingsRowValue label={t('job:label.queue')} value={stagingLabel} isDisabled={isDisabled} />

                    <SettingsRowSeparator/>
                    <SettingsRowValue label={t('job:label.client')} value={names.client} isDisabled={isDisabled}/>
                    <SettingsRowValue label={t('job:label.matter')} value={names.matter} isDisabled={isDisabled}/>

                    <SettingsRowSeparator/>
                    <SettingsRowValue label={t('job:label.library')} value={names.library} isDisabled={isDisabled}/>
                    <SettingsRowValue label={t('job:label.workflow')} value={names.workflowTemplate} isDisabled={isDisabled}/>
                </div>
            </div>
        </ExpandableContent>
    )
}

export default JobConfirmPane;
