import ComponentStateModel from "../generics/ComponentStateModel";
import {actionCreator} from "../../utilities/helperFunctions";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import {call, put, select, take, takeEvery, takeLatest, takeLeading} from "redux-saga/effects";
import {
    contextCall,
    contextPollUntil,
    contextSaga,
    editNavigation,
    poll,
    serverErrorResponseValues
} from "../../saga/sagaFunctions";
import {modelTypes, QUERY_INTERVAL, SLOW_QUERY_INTERVAL} from "../../utilities/constants";
import CurrentUserModel from "../user/CurrentUserModel";
import PopupModel from "./PopupModel";
import {JobSaga} from "../job/JobModel";
import {JobScheduleSaga} from "../job/JobScheduleModel";
import {JobArchiveSaga} from "../job/JobArchiveModel";
import {ClientSaga} from "../client/ClientModel";
import {LibrarySaga} from "../library/LibraryModel";
import {LegalHoldSaga} from "../legalhold/LegalHoldModel";
import {UserNoticeSaga} from "../notice/UserNoticeModel";
import {LicenceSourceSaga} from "../settings/LicenceSourceModel";
import {ServerSaga} from "../settings/ServerModel";
import {EngineSaga} from "../settings/EngineModel";
import {ResourcePoolSaga} from "../settings/ResourcePoolModel";
import {ExecutionProfileSaga} from "../settings/ExecutionProfileModel";
import {ClientPoolSaga} from "../settings/ClientPoolModel";
import {NotificationRuleSaga} from "../settings/NotificationRuleModel";
import {NoticeTemplateSaga} from "../notice/NoticeTemplateModel";
import {DataRepositorySaga} from "../data/DataRepositoryModel";
import {PolicySaga} from "../settings/PolicyModel";
import {tryCatchWrapper} from "../../saga/tryCatchWrapper";
import {JobPurviewSaga} from "../purview/JobPurviewModel";
import {JobVaultSaga} from "../job/JobVaultModel";

class SchedulerModel {

    static nom = 'SchedulerModel';
    static actions = SchedulerModel.buildActions();
    static actionCreators = SchedulerModel.buildActionCreators(SchedulerModel.actions);
    static reducer = SchedulerModel.buildReducer(SchedulerModel.actions);

    static componentActionCreators = {
        ...SchedulerModel.buildComponentUpdateActionCreators(),
        ...SchedulerModel.buildComponentSetActiveActionCreators()
    };

    constructor() {
        this.authMethods = {};
        this.disableRegex = false;

        this.isRelativityApplication = false;
        this.isServerScheduler = true;
        this.isDisconnected = false;

        this.configuration = {
            loggingConfiguration: {},
            fileUploadConfiguration: {},
            azureEnvironments: []
        };
        this.resourcesStatus = {};

        this.neoPlatformApps = [];
        this.neoExternalApps = [];
    }

    static Role = {
        SCHEDULER: 'SCHEDULER',
        ENGINE_SERVER: 'ENGINE_SERVER'
    };

    static PlatformApplication = {
        NUIX_AUTOMATE: 'NUIX_AUTOMATE',
        NUIX_NLP: 'NUIX_NLP',
        NUIX_INVESTIGATE: 'NUIX_INVESTIGATE',
        NUIX_DISCOVER: 'NUIX_DISCOVER'
    }

    static buildActions() {
        return {
            // SCHEDULER ACTIONS
            SET_IS_SCHEDULER: 'SET_IS_SCHEDULER',
            SET_IS_RELATIVITY_APPLICATION: 'SET_IS_RELATIVITY_APPLICATION',

            QUERY_AUTH_METHODS: 'QUERY_AUTH_METHODS',
            SET_AUTH_METHODS: 'SET_AUTH_METHODS',
            SET_ARCHIVE_EXISTS: 'SET_ARCHIVE_EXISTS',
            SET_DISCONNECTED: 'SET_DISCONNECTED',
            SET_CONNECTED: 'SET_CONNECTED',
            SET_DISABLE_REGEX: 'SET_DISABLE_REGEX',
            // IDLE HANDLER ACTIONS
            RESET_IDLE_TIMER: 'RESET_IDLE_TIMER',

            // CUSTOM BRANDING ACTIONS
            SET_BRANDING_LOGO: 'SET_BRANDING_LOGO',
            // SETTINGS PAGE ACTIONS
            SET_SETTINGS_DISPLAY: 'SET_SETTINGS_PAGE_DISPLAY',
            // GLOBAL SAGA ACCESS
            YIELD_EFFECT_DESCRIPTOR: 'YIELD_EFFECT_DESCRIPTOR',
            // GENERIC RESPONSE ERROR
            HANDLE_RESPONSE_ERROR: 'HANDLE_GENERIC_RESPONSE_ERROR',

            // GLOBAL APP CONFIGURATION
            UPDATE_CONFIGURATION: 'UPDATE_APP_CONFIGURATION',
            UPDATE_STATE_FROM_PATH: 'UPDATE_SCHEDULER_REDUX_STATE_FROM_PATH',
            SET_RESOURCES_STATUS: 'SET_RESOURCES_STATUS',
            UPDATE_RESOURCE_STATUS: 'UPDATE_RESOURCE_STATUS',

            SET_NEO_PLATFORM_APPS: 'SET_NEO_PLATFORM_APPS',
            SET_NEO_EXTERNAL_APPS: 'SET_NEO_EXTERNAL_APPS'
        }
    }

    static buildActionCreators(actions) {
        return {
            // SCHEDULER ACTION CREATORS
            setIsServerScheduler: actionCreator(actions.SET_IS_SCHEDULER, 'isServerScheduler'),
            setIsRelativityApplication: actionCreator(actions.SET_IS_RELATIVITY_APPLICATION, 'isRelativityApplication'),
            setDisableRegex: actionCreator(actions.SET_DISABLE_REGEX, 'disableRegex'),

            queryAuthMethods: actionCreator(actions.QUERY_AUTH_METHODS),
            setAuthMethods: actionCreator(actions.SET_AUTH_METHODS, 'authMethods'),
            setArchiveExists: actionCreator(actions.SET_ARCHIVE_EXISTS),
            setDisconnected: actionCreator(actions.SET_DISCONNECTED),
            setConnected: actionCreator(actions.SET_CONNECTED),
            // IDLE HANDLER ACTION CREATORS
            resetIdleTimer: actionCreator(actions.RESET_IDLE_TIMER),

            // CUSTOM BRANDING ACTION CREATORS
            setBrandingLogo: actionCreator(actions.SET_BRANDING_LOGO, 'brandingLogo'),
            // SETTINGS PAGE ACTION CREATORS
            setSettingsDisplay: actionCreator(actions.SET_SETTINGS_DISPLAY, 'activeDisplay'),
            // GLOBAL SAGA ACCESS ACTION CREATORS
            yieldEffectDescriptor: actionCreator(actions.YIELD_EFFECT_DESCRIPTOR, 'effectDescriptor'),
            handleResponseError: actionCreator(actions.HANDLE_RESPONSE_ERROR, 'error'),

            // GLOBAL APP CONFIGURATION ACTION CREATORS
            updateConfiguration: actionCreator(actions.UPDATE_CONFIGURATION, 'configuration'),
            setResourcesStatus: actionCreator(actions.SET_RESOURCES_STATUS, 'resourcesStatus'),
            updateResourceStatus: actionCreator(actions.UPDATE_RESOURCE_STATUS),
            updateStateFromPath: actionCreator(actions.UPDATE_STATE_FROM_PATH, 'modelType', 'args'),

            setNeoPlatformApps: actionCreator(actions.SET_NEO_PLATFORM_APPS, 'neoPlatformApps'),
            setNeoExternalApps: actionCreator(actions.SET_NEO_EXTERNAL_APPS, 'neoExternalApps')
        }
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'app',
                type: 'App',
                state: {
                    isLoading: true
                }
            },
            {
                key: 'settingsPage',
                type: 'SettingsPage',
                state: {
                    activeDisplay: null,
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'APP',
                type: 'App'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }

    static buildReducer(actions) {
        return function (state = new this(), action) {
            switch (action.type) {
                case actions.SET_IS_SCHEDULER: {
                    const {isServerScheduler} = action.payload;
                    return {
                        ...state,
                        isServerScheduler
                    };
                }
                case actions.SET_IS_RELATIVITY_APPLICATION: {
                    const {isRelativityApplication} = action.payload;
                    return {
                        ...state,
                        isRelativityApplication
                    };
                }
                case actions.SET_AUTH_METHODS: {
                    const {authMethods} = action.payload;
                    return {
                        ...state,
                        authMethods
                    }
                }
                case actions.SET_ARCHIVE_EXISTS: {
                    return {
                        ...state,
                        archiveExists: true
                    };
                }
                case actions.SET_DISCONNECTED: {
                    return {
                        ...state,
                        isDisconnected: true
                    };
                }
                case actions.SET_CONNECTED: {
                    return {
                        ...state,
                        isDisconnected: false
                    };
                }
                case actions.SET_DISABLE_REGEX: {
                    const {disableRegex} = action.payload;
                    return {
                        ...state,
                        disableRegex
                    };
                }
                case actions.SET_BRANDING_LOGO: {
                    const {brandingLogo} = action.payload;
                    return {
                        ...state,
                        brandingLogo
                    };
                }
                case actions.UPDATE_CONFIGURATION: {
                    const {configuration} = action.payload;
                    return {
                        ...state,
                        configuration: {
                            ...state.configuration,
                            ...configuration
                        }
                    };
                }
                case actions.SET_RESOURCES_STATUS: {
                    const {resourcesStatus} = action.payload;
                    return {
                        ...state,
                        resourcesStatus
                    };
                }
                case actions.SET_NEO_PLATFORM_APPS: {
                    const {neoPlatformApps} = action.payload;

                    return {
                        ...state,
                        neoPlatformApps
                    }
                }
                case actions.SET_NEO_EXTERNAL_APPS: {
                    const {neoExternalApps} = action.payload;

                    return {
                        ...state,
                        neoExternalApps
                    }
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }
}

export class SchedulerApi {

    static getRunId() {
        return axiosInstance.get('/services/runId');
    }

    static getServices() {
        return axiosInstance.get('/services');
    }

    static getVersion() {
        return axiosInstance.get('/version/automate');
    }

    static getAuthMethods() {
        return axiosInstance.get('/authMethods');
    }

    static getConfiguration() {
        return axiosInstance.get('/configuration');
    }

    static getNames(ids) {
        return axiosInstance.post('/scheduler/util/resolve', ids);
    }

    static getAzureEnvironments() {
        return axiosInstance.get('/scheduler/util/azureEnvironments');
    }

    static getResourcesStatus() {
        return axiosInstance.get('/scheduler/resources/status');
    }

    static getBrandingTitle() {
        return axiosInstance.get('/media/branding/title').catch(() => {});
    }

    static getBrandingFavicon() {
        return axiosInstance.get('/media/branding/favicon', {responseType: 'blob'}).catch(() => {});
    }

    static getBrandingLogo() {
        return axiosInstance.get('/media/branding/logoImage', {responseType: 'blob'}).catch(() => {});
    }

    static getBrandingLogoStyle() {
        return axiosInstance.get('/media/branding/logoStyle').catch(() => {});
    }

    static getBrandingHeaderStyle() {
        return axiosInstance.get('/media/branding/headerStyle').catch(() => {});
    }

    static getNeoPlatformApplications() {
        return axiosInstance.get('/platform/applications');
    }

    static getNeoExternalApplications() {
        return axiosInstance.get('/platform/externalApplications');
    }
}

export class SchedulerSaga {

    static buildActivationEffects(dispatch) {
        return [
            // ACTIVATION EFFECTS
            takeLatest(SchedulerModel.actions.SET_SETTINGS_DISPLAY, editNavigation, dispatch, contextSaga(this, 'setSettingsDisplay')),
            takeLatest(SchedulerModel.actions.QUERY_AUTH_METHODS, tryCatchWrapper, contextSaga(this, 'queryAuthMethods')),

            takeLatest(SchedulerModel.actions.UPDATE_RESOURCE_STATUS, tryCatchWrapper, contextSaga(this, 'queryResourcesStatus')),

            takeLatest(SchedulerModel.actions.UPDATE_STATE_FROM_PATH, tryCatchWrapper, contextSaga(SchedulerSaga, 'updateStateFromPath')),
            takeLatest(SchedulerModel.actions.HANDLE_RESPONSE_ERROR, contextSaga(this, 'handleResponseError')),
            takeEvery(SchedulerModel.actions.YIELD_EFFECT_DESCRIPTOR, tryCatchWrapper, contextSaga(SchedulerSaga, 'yieldEffectDescriptor')),
            takeLeading(SchedulerModel.actions.SET_DISCONNECTED, contextSaga(SchedulerSaga, 'pollConnection'))
        ]
    }

    static* updateStateFromPath(action) {
        const {modelType, args} = action.payload;

        // Case when user hasn't logged in and doesn't have credentials for query
        if (!(yield select(state => state.currentUser.isAuthenticated))) {
            // Wait for successful login
            yield take(CurrentUserModel.actions.SET_CURRENT_USER);
        }

        const saga = {
            [modelTypes.jobQueue]: JobSaga,
            [modelTypes.jobPurview]: JobPurviewSaga,
            [modelTypes.jobVault]: JobVaultSaga,
            [modelTypes.jobArchive]: JobArchiveSaga,
            [modelTypes.jobSchedule]: JobScheduleSaga,
            [modelTypes.client]: ClientSaga,
            [modelTypes.library]: LibrarySaga,
            [modelTypes.legalHold]: LegalHoldSaga,
            [modelTypes.userNotice]: UserNoticeSaga,
            [modelTypes.overview]: UserNoticeSaga,
            [modelTypes.nuixLicenseSource]: LicenceSourceSaga,
            [modelTypes.engineServer]: ServerSaga,
            [modelTypes.engine]: EngineSaga,
            [modelTypes.resourcePool]: ResourcePoolSaga,
            [modelTypes.executionProfile]: ExecutionProfileSaga,
            [modelTypes.clientPool]: ClientPoolSaga,
            [modelTypes.notificationRule]: NotificationRuleSaga,
            [modelTypes.noticeTemplate]: NoticeTemplateSaga,
            [modelTypes.dataRepository]: DataRepositorySaga,
            [modelTypes.policy]: PolicySaga
        }[modelType];

        if (saga != null) {
            yield contextCall(saga, 'setReduxState', {modelType, ...args});
        }
    }

    static* handleResponseError(action) {
        const {error} = action.payload;
        if (error == null || error.response == null) {
            console.log(error);
            return;
        }

        if (error.response.status === 401) {
            yield put(CurrentUserModel.actionCreators.logoutUser());
        } else {
            yield put(PopupModel.actionCreators.showError({
                info: {
                    ...(serverErrorResponseValues(error.response) || error.response.data)
                }
            }));
        }
    }

    static* yieldEffectDescriptor(action) {
        yield action.payload.effectDescriptor;
    }

    static* setSettingsDisplay(action) {
        const {activeDisplay} = action.payload;

        yield put(SchedulerModel.componentActionCreators.updateSettingsPage({activeDisplay}));
    }

    lastResourceStatusUpdate = null;
    static* queryResourcesStatus() {
        const response = yield contextCall(SchedulerApi, 'getResourcesStatus');

        if (AxiosProxy.shouldUpdate('resourcesStatus', response)) {
            this.lastResourceStatusUpdate = Date.now();
            yield put(SchedulerModel.actionCreators.setResourcesStatus(response.data));
        }
    }

    static* pollResourcesStatus() {
        yield call(poll, SLOW_QUERY_INTERVAL, contextSaga(this, 'queryResourcesStatus'));
    }

    static* queryNeoPlatformApps() {
        const response = yield contextCall(SchedulerApi, 'getNeoPlatformApplications');
        if (AxiosProxy.shouldUpdate('neoPlatformApps', response)) {
            yield put(SchedulerModel.actionCreators.setNeoPlatformApps(response.data));
        }
    }

    static* pollNeoPlatformApps() {
        yield call(poll, SLOW_QUERY_INTERVAL, contextSaga(this, 'queryNeoPlatformApps'));
    }

    static* queryNeoExternalApps() {
        const response = yield contextCall(SchedulerApi, 'getNeoExternalApplications');
        if (AxiosProxy.shouldUpdate('neoPlatformApps', response)) {
            yield put(SchedulerModel.actionCreators.setNeoExternalApps(response.data));
        }
    }

    static* pollNeoExternalApps() {
        yield call(poll, SLOW_QUERY_INTERVAL, contextSaga(this, 'queryNeoExternalApps'));
    }

    static* queryAuthMethods() {
        const {data} = yield contextCall(SchedulerApi, 'getAuthMethods');
        yield put(SchedulerModel.actionCreators.setAuthMethods(data));
    }

    static* pollConnection() {
        yield contextPollUntil(SchedulerModel.actions.SET_CONNECTED, QUERY_INTERVAL, this, 'queryConnection');
    }

    static* queryConnection() {
        try {
            yield contextCall(SchedulerApi, 'getServices');

            // If API call is successful then Scheduler is connected
            yield put(SchedulerModel.actionCreators.setConnected());
        } catch (ignore) {
        }
    }
}

export default SchedulerModel;