import ReduxModel from "./ReduxModel";
import {actionCreator} from "../../utilities/helperFunctions";
import WorkflowTemplateModel from "../library/WorkflowTemplateModel";

class OperationModel extends ReduxModel {

    constructor(model = {}) {
        super();
        this.name = model.name;
        this.operationAlias = model.operationAlias;
        this.notes = model.notes;
        this.options = model.options;
        this.disabled = model.disabled;
        this.skippable = model.skippable;
        this.softFail = model.softFail;
        this.suppressWarnings = model.suppressWarnings;
        this.enableFieldOverwrite = model.enableFieldOverwrite;
    }

    static buildActions(type) {
        return {
            // OPERATIONS ACTIONS
            SET_OPERATIONS: `SET_${type}_OPERATIONS`,
            DELETE_OPERATIONS: `DELETE_${type}_OPERATIONS`
        };
    }

    static buildActionCreators(actions) {
        return {
            // OPERATIONS ACTION CREATORS
            setOperations: actionCreator(actions.SET_OPERATIONS, 'id', 'operations'),
            deleteOperations: actionCreator(actions.DELETE_OPERATIONS, 'id')
        }
    }

    static buildReducer(actions) {
        return function(state = new Map(), action) {
            switch (action.type) {
                case actions.SET_OPERATIONS: {
                    const {id, operations} = action.payload;

                    const oldOperations = state.get(id);
                    const newOperations = this.setDetailsArray(oldOperations, operations);

                    // If reference is same, return same
                    if (oldOperations !== newOperations) {
                        return new Map(state).set(id, newOperations);
                    }

                    return state;
                }
                case WorkflowTemplateModel.actions.DELETE_DETAILS:
                case actions.DELETE_OPERATIONS: {
                    const {id} = action.payload;

                    return this.deleteDetails(state, id);
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }
}

export default OperationModel;