import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {
    downloadWorkflow,
    generateWorkflow,
    updateWorkflowDescription,
    updateWorkflowEnabled,
    updateWorkflowName
} from "../../../redux/WorkflowHandler/workflowHandlerSlice";

import './finishStep.css'
import '../../../wizard-general.css'
import '../../../../components/common/Button/Button.css'
import Checkbox from "../../../../components/common/Checkbox/Checkbox";
import TextArea from "../../../../components/common/TextArea/TextArea";
import {useTranslation} from "react-i18next";
import {DateFilterType} from "../../../utilities/constants";
import HTMLTextInput from "../../../../components/common/HTMLTextInput/HTMLTextInput";

function FinishStep(props) {
    const {isDisabled} = props;
    const {t} = useTranslation(['common', 'wizard']);

    const dispatch = useDispatch();
    const {
        reporting,
        exporting,
        workflowInformation,
        caseSettings,
        ingestionSettings,
        advancedIngestionSettings,
        legalExportSettings,
        workflowName,
        enabled,
        workflowDescription,
        operationsMap,
        isActive,
        discoverConfiguration,
        ocrSettings
    } = props;

    const [actionButtonDisabled, setActionButtonDisabled] = useState(true);

    const download = () => {
        dispatch(downloadWorkflow())
    }

    const onWorkflowNameChange = event => {
        let value = event.currentTarget.value;

        if (value.length === 0) {
            setActionButtonDisabled(true);
        } else {
            setActionButtonDisabled(false);
        }

        dispatch(updateWorkflowName({name: value}))
    }

    function generateWorkflowDescription () {
        const uiLanguage = localStorage.getItem("i18nextLng");

        if (uiLanguage === "en-US") {
            let description = `${t('wizard:phrase.englishDescription.prefix')} `;

            // Workflow information
            let caseTimezone = null;
            if (workflowInformation) {
                const config = workflowInformation;

                if (config.useProcessingProfile && config.useConfigurationProfile) {
                    description += `${t("wizard:phrase.englishDescription.workflowInformation.both")} and `
                } else if (config.useProcessingProfile) {
                    description += `${t("wizard:phrase.englishDescription.workflowInformation.processing")} and `
                } else if (config.useConfigurationProfile) {
                    description += `${t("wizard:phrase.englishDescription.workflowInformation.configuration")} and `
                }

                if (config.timezoneRadio === 1) {
                    caseTimezone = `${t("wizard:phrase.englishDescription.caseSettings.timezone.defined", {timezoneId: config.timezoneSelect})}`;
                } else if (config.timezoneRadio === 2) {
                    caseTimezone = `${t("wizard:phrase.englishDescription.caseSettings.timezone.system")}`;
                } else if (config.timezoneRadio === 3) {
                    caseTimezone = `${t("wizard:phrase.englishDescription.caseSettings.timezone.prompt")}`;
                }
            }

            // Case settings
            let caseStructure = null;
            if (caseSettings) {
                const config = caseSettings;

                if (config.caseStructure !== 3 || workflowInformation.timezoneRadio !== 3) {
                    if (config.caseStructure === 1) {
                        caseStructure = `${t("wizard:phrase.englishDescription.caseSettings.caseStructure.clientMatter")}`
                    } else if (config.caseStructure === 2) {
                        caseStructure = `${t("wizard:phrase.englishDescription.caseSettings.caseStructure.singleFolder")}`
                    } else if (config.caseStructure === 3) {
                        caseStructure = `${t("wizard:phrase.englishDescription.caseSettings.caseStructure.prompt")}`
                    }

                    description += `${t("wizard:phrase.englishDescription.caseSettings.createsCase", {caseStructure: caseStructure, timezone: caseTimezone})}`
                } else {
                    description += `${t("wizard:phrase.englishDescription.caseSettings.caseStructureAndTimezonePrompt")}`
                }
            }

            // Ingestion
            if (ingestionSettings) {
                if (ingestionSettings.dataStructure !== 3) {
                    const {deduplicateItems, useDateFilter, dateFilterType} = advancedIngestionSettings;

                    if (deduplicateItems && (useDateFilter && dateFilterType !== DateFilterType.NONE)) {
                        description += `, ${t("wizard:phrase.englishDescription.ingestionSettings.both")}`
                    } else if (deduplicateItems) {
                        description += `, ${t("wizard:phrase.englishDescription.ingestionSettings.deduplication")}`
                    } else if (useDateFilter && dateFilterType !== DateFilterType.NONE) {
                        description += `, ${t("wizard:phrase.englishDescription.ingestionSettings.dateFilter")}`
                    } else {
                        description += `, ${t("wizard:phrase.englishDescription.ingestionSettings.ingestsData")}`
                    }
                }
            }

            if (ocrSettings.performOcr) {
                const {defineProfileType, ocrProfileName} = ocrSettings;

                if (defineProfileType === 1) {
                    description += `, ${t("wizard:phrase.englishDescription.ocr.performDefault")}`
                }

                if (defineProfileType === 2) {
                    description += `, ${t("wizard:phrase.englishDescription.ocr.performPredefined", {profile: ocrProfileName})}`
                }

            }

            // Legal Export
            if (exporting.legalExport) {
                const config = legalExportSettings;

                const {exportNative, exportText, exportPdf, exportImage, exportXhtmlMetadataReport, exportThumbnailFile} = config.exportFiletypes;
                if (exportNative || exportText || exportPdf || exportImage || exportXhtmlMetadataReport || exportThumbnailFile) {
                    let filetypes = [];

                    if (exportNative) {
                        filetypes.push("native")
                    }
                    if (exportText) {
                        filetypes.push("text")
                    }
                    if (exportPdf) {
                        filetypes.push("pdf")
                    }
                    if (exportImage) {
                        filetypes.push("image")
                    }
                    if (exportXhtmlMetadataReport) {
                        filetypes.push("xhtml Metadata Report")
                    }
                    if (exportThumbnailFile) {
                        filetypes.push("thumbnail")
                    }

                    const exportFileTypes = filetypes.join(", ");
                    description += `, ${t("wizard:phrase.englishDescription.legalExportSettings.exportFiletype", {
                        exportType: config.exportType.toLowerCase(),
                        exportFiletypes: exportFileTypes
                    })}`;

                } else {
                    description += `, ${t("wizard:phrase.englishDescription.legalExportSettings.exportType", {exportType: config.exportType.toLowerCase()})}`;
                }

                if (config.useProductionProfile && config.useMetadataProfile) {
                    description += ` and ${t("wizard:phrase.englishDescription.legalExportSettings.profiles.both")}`
                } else if (config.useProductionProfile) {
                    description += ` and ${t("wizard:phrase.englishDescription.legalExportSettings.profiles.production")}`
                } else if (config.useMetadataProfile) {
                    description += ` and ${t("wizard:phrase.englishDescription.legalExportSettings.profiles.metadata")}`
                }
            }

            // Discover
            if (exporting.exportToDiscover) {
                const config = discoverConfiguration;

                const deploymentType = config.deploymentType === "SAAS" ? t('wizard:phrase.englishDescription.discoverConfiguration.deployment.saas')
                    : t('wizard:phrase.englishDescription.discoverConfiguration.deployment.local')
                description += `, ${t('wizard:phrase.englishDescription.discoverConfiguration.exportDiscover', {deployment: deploymentType})}`

                const isCloned = config.createCaseFromTemplate === "TEMPLATE";
                const hasOrganizationName = config.clonedCaseOrganization.length > 0;
                switch (config.caseNameType) {

                    case 1:
                        if (isCloned) {
                            description += `, ${t('wizard:phrase.englishDescription.discoverConfiguration.caseNameType.nuixClone', {clone: config.sourceCaseName})}`

                            if (hasOrganizationName) {
                                description += ` ${t('wizard:phrase.englishDescription.discoverConfiguration.organization', {organization: config.clonedCaseOrganization})}`
                            }
                        } else {
                            description += `, ${t('wizard:phrase.englishDescription.discoverConfiguration.caseNameType.nuixExisting')}`
                        }
                        break;

                    case 2:
                        if (isCloned) {
                            description += `, ${t('wizard:phrase.englishDescription.discoverConfiguration.caseNameType.predefinedClone', {clone: config.sourceCaseName, caseName: config.caseIdentifier})}`

                            if (hasOrganizationName) {
                                description += ` ${t('wizard:phrase.englishDescription.discoverConfiguration.organization', {organization: config.clonedCaseOrganization})}`
                            }
                        } else {
                            description += `, ${t('wizard:phrase.englishDescription.discoverConfiguration.caseNameType.predefinedExisting', {caseName: config.caseIdentifier})}`
                        }
                        break;

                    case 3:
                        if (isCloned) {
                            description += `, ${t('wizard:phrase.englishDescription.discoverConfiguration.caseNameType.executionClone', {clone: config.sourceCaseName})}`

                            if (hasOrganizationName) {
                                description += ` ${t('wizard:phrase.englishDescription.discoverConfiguration.organization', {organization: config.clonedCaseOrganization})}`
                            }
                        } else {
                            description += `, ${t('wizard:phrase.englishDescription.discoverConfiguration.caseNameType.executionExisting')}`
                        }
                        break;
                }

                if (config.setAssignUserToCase || config.setAssignGroupToCase) {
                    if (config.setAssignUserToCase && config.setAssignGroupToCase) {
                        description += `, ${t('wizard:phrase.englishDescription.discoverConfiguration.permissions.both', {user: config.userToCase, group: config.caseGroupName})}`
                    } else if (config.setAssignUserToCase) {
                        description += `, ${t('wizard:phrase.englishDescription.discoverConfiguration.permissions.userAssign', {user: config.userToCase})}`
                    } else if (config.setAssignGroupToCase) {
                        description += `, ${t('wizard:phrase.englishDescription.discoverConfiguration.permissions.groupAssign', {group: config.caseGroupName})}`
                    }
                }

                if (config.defineTokenAtExecution) {
                    description += `, ${t('wizard:phrase.englishDescription.discoverConfiguration.apiToken.execution')}`
                } else {
                    description += `, ${t('wizard:phrase.englishDescription.discoverConfiguration.apiToken.profile')}`
                }
            }

            // Reports
            if (reporting) {
                if (reporting.report) {
                    description += `, ${t("wizard:phrase.englishDescription.report.generatesReportSingle", {reportType: "Processing Report"})}`
                }
            }


            return `${description}.`;

        } else {
            let operationsUnfiltered = Object.keys(operationsMap).map(key => {
                return t(`wizard:phrase.generic.${operationsMap[key]}`);
            })
            const filteredOperation = [...new Set([...operationsUnfiltered])].join(", ");
            return `${t('wizard:phrase.generic.prefix')} ${filteredOperation}.`
        }
    }

    const toggleEnable = event => dispatch(updateWorkflowEnabled({enabled: event.currentTarget.dataset.value}))
    const onDescriptionChange = event => dispatch(updateWorkflowDescription({description: event.currentTarget.value}))

    useEffect(()=> {
        if (workflowName.length > 0) {
            dispatch(generateWorkflow())
        }
    })

    useEffect(() => {
        dispatch(updateWorkflowDescription({description: generateWorkflowDescription()}))
    }, [isActive])

    const disabled = isDisabled ? ' is-disabled' : '';

    return (
        <div className={"wizard-step-container"}>
            <div className={"display-item"}>
                <label className={"label" + disabled}>
                    {t('wizard:questions.finish.pageHeader')}
                </label>
            </div>
            <div className="display-input">
                <HTMLTextInput
                    label={t('common:label.name')}
                    name={'workflowTemplateName'}
                    value={workflowName}
                    onChange={onWorkflowNameChange}
                    isDisabled={isDisabled} isRequired={true}/>
            </div>

            <Checkbox
                label={t('common:label.enabled')}
                name={'enabled'}
                checked={enabled}
                onClick={toggleEnable}
                isDisabled={isDisabled}/>

            <div className="display-input">
                <TextArea
                    label={t('common:label.description')}
                    name={'description'}
                    value={workflowDescription}
                    onChange={onDescriptionChange}
                    isDisabled={isDisabled}/>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    const {
        wizardSlice: {optionalSteps: {reporting, exporting}, ocrSettings, workflowInformation, caseSettings, ingestionSettings, advancedIngestionSettings, legalExportSettings, discoverConfiguration},
        workflowHandlerSlice: {workflowName, enabled, workflowDescription, operationsMap}
    } = state;

    return {
        reporting,
        exporting,
        workflowInformation,
        caseSettings,
        ingestionSettings,
        advancedIngestionSettings,
        legalExportSettings,
        workflowName,
        enabled,
        workflowDescription,
        operationsMap,
        discoverConfiguration,
        ocrSettings
    };
}

function mapDispatchToProps (dispatch) {
    return {
        //updateState: updates => dispatch(updateGenericValues({stateName: "workflowInformation", updates: updates}))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinishStep);