import React from "react";
import {useTranslation} from "react-i18next";
import {TableLabelCell, TableLabelHeader, TableRow} from "../common/CustomTable/CustomTable";
import ExpandableContent from "../common/ExpandableContent/ExpandableContent";
import {useSelector} from "react-redux";
import {JobStatusIcons} from "../userSettings/JobCardDesigner/helpers";
import Text from "../common/Text/Text";
import JobStatus from "../job/JobStatus";

function ExpandableTriggerConfigurationEvents (props) {
    const {t} = useTranslation(['common']);
    const {
        isDisabled,
        triggerConfigurationEvents,
        navigateToJob,
        custodianParticipations
    } = props;

    const custodianIdToNameMap = new Map();
    custodianParticipations.map(custodian => {
        const custodianIdentifier = !!custodian.email ? custodian.email : custodian.name;
        custodianIdToNameMap.set(custodian.id, custodianIdentifier);
    });

    return (
        <ExpandableContent label={t('legalHold:label.triggerJobs')} isDisabled={isDisabled}>
            <div className="display-table tight-padding">
                <div className="table-header-group">
                    <TableLabelHeader label={t('common:label.state')} style={{width: '12rem'}}/>

                    <TableLabelHeader label={t('common:label.name')}/>

                    <TableLabelHeader label={t('legalHold:label.trigger')}/>

                    <TableLabelHeader label={t('legalHold:label.users')}/>

                    <TableLabelHeader label={t('guidedJob:label.submittedDate')} style={{width: '8rem'}}/>

                    <TableLabelHeader label={t('guidedJob:label.endDate')} style={{width: '8rem'}}/>
                </div>

                <div className="table-row-group">
                    {triggerConfigurationEvents.map(event =>
                        <LegalHoldJobRow key={event.id} event={event} custodianIdToNameMap={custodianIdToNameMap} t={t} onClick={navigateToJob}/>
                    )}
                </div>
            </div>
        </ExpandableContent>
    )
}

function LegalHoldJobRow(props) {
    const maxLength = 250;
    const {
        t,
        event,
        onClick,
        isActive,
        custodianIdToNameMap
    } = props;

    const {
        jobId,
        userIds,
        triggerType
    } = event;

    const job = useSelector(state => state.jobDetailsMap.get(jobId));
    if (job == null || custodianIdToNameMap == null) {
        return null;
    }

    let length = 0
    const usersArray = [];
    for (let i = 0; i < userIds.length; i++) {
        let userId = userIds[i];
        let userIdentifier = custodianIdToNameMap.get(userId);
        length += userIdentifier.length;

        if (length > maxLength) {
            usersArray.push("...");
            break;
        }

        usersArray.push(userIdentifier);
    }

    return (
        <TableRow value={job.id} isActive={isActive} onClick={onClick}>
            <div className="table-cell" style={{paddingTop: '0.45rem'}}>
                <div style={{display: 'flex'}}>
                    <JobStatusIcons {...job} size={'small'} hidePercentage/>
                    <Text style={{flex: '3'}}>
                        <JobStatus jobId={job.id}/>
                    </Text>
                </div>
            </div>

            <div className="table-cell">
                <Text value={job.name} isWordWrap isBold/>
            </div>

            <div className="table-cell no-stretch">
                <Text value={t(`legalHold:triggerConfigurationTrigger.${triggerType}`)}/>
            </div>

            <div className="table-cell">
                <Text value={usersArray.join(", ")}/>
            </div>

            <TableLabelCell label={job.submittedDate}/>
            <TableLabelCell label={job.finishedDate}/>
        </TableRow>
    )
}

export default ExpandableTriggerConfigurationEvents;