import React from 'react';
import {ListDropdown} from "../../../../components/common/Dropdown/Dropdown";
import {DateFilterType} from "../../../utilities/constants";
import {useTranslation} from "react-i18next";

function DateFilterTypeDropdown(props) {
    const {t} = useTranslation(['aria', 'wizard']);

    const {selectedDateFilterTypeId, onDateFilterTypeSelected, ...rest} = props;
    const dateFilterTypeValues = [
        {value: DateFilterType.NONE, name: t('wizard:questions.advancedIngestionSettings.dateFilterType.NONE')},
        {value: DateFilterType.BETWEEN, name: t('wizard:questions.advancedIngestionSettings.dateFilterType.BETWEEN')},
        {value: DateFilterType.NOT_BETWEEN, name: t('wizard:questions.advancedIngestionSettings.dateFilterType.NOT_BETWEEN')},
        {value: DateFilterType.AFTER, name: t('wizard:questions.advancedIngestionSettings.dateFilterType.AFTER')},
        {value: DateFilterType.BEFORE, name: t('wizard:questions.advancedIngestionSettings.dateFilterType.BEFORE')}
    ]

    return (
        <ListDropdown id={'dateFilterTypeDropdown'} name={'dateFilterType'}
            aria-label={t('aria:hiddenAssistText.dateFilterType')} value={selectedDateFilterTypeId}
            items={dateFilterTypeValues} onItemSelect={onDateFilterTypeSelected}
            buttonStyle={{maxWidth: '12rem', justifyContent: 'flex-start'}}
            {...rest}
        />
    )
}

export default DateFilterTypeDropdown;