import React, {useCallback, useEffect, useRef} from 'react';
import {connect, useDispatch} from 'react-redux';
import {useTranslation} from "react-i18next";
import {popupInfoKeys} from "../../i18next/keys";
import {getValues, isNotEmptyNorFalsy, objEquals, switchcase} from "../../utilities/helperFunctions";
import {details, updateMode} from "../../utilities/constants";
import NavigatePane from "../common/NavigatePane/NavigatePane";
import ScheduleSettingsPane from "./panes/ScheduleSettingsPane";
import PopupModel from "../../models/scheduler/PopupModel";
import JobScheduleModel from "../../models/job/JobScheduleModel";
import ScheduleTriggerPane from "./panes/ScheduleTriggerPane";
import ScheduleMatterPane from "./panes/ScheduleMatterPane";
import ScheduleWorkflowPane from "./panes/ScheduleWorkflowPane";
import ScheduleJobPane from "./panes/ScheduleJobPane";
import ScheduleConfirmPane from "./panes/ScheduleConfirmPane";
import {makeGetEditDetails} from "../../reselect/selectors";
import {usePrevious} from "../../utilities/hooks";
import CurrentUserModel from "../../models/user/CurrentUserModel";

function ScheduleJob(props) {
    const {t} = useTranslation(['jobSchedule', 'job', 'common']);
    const dispatch = useDispatch();

    const {
        force,
        mode,
        saveEdit,
        cancelEdit,

        scheduleName,
        description,
        scheduleTrigger,
        eventTrigger,
        conditions,
        clientId,
        matterId,
        libraryId,
        workflowTemplateId,
        parameters,

        getOperationsAndParameters,
        encryptProtectedParameters,
        setSameAsJobDefaults,

        isLoading,
        isDisabled
    } = props;


    const initialValues = useRef({});
    // If was previous loading and now isLoaded, update initialValues
    const prevIsLoading = usePrevious(isLoading);
    if (prevIsLoading && !isLoading) {

        initialValues.current = {
            scheduleName,
            description,
            scheduleTrigger,
            eventTrigger,
            conditions,
            clientId,
            matterId,
            libraryId,
            workflowTemplateId,
            parameters
        };
    }

    useEffect(() => {
        return () => dispatch(CurrentUserModel.actionCreators.pollActivity(CurrentUserModel.activities.ADMINISTRATION));
    }, [dispatch]);

    function onMatterPaneNext() {
        // Nothing to do
    }

    // Close Callbacks
    const {showWarning, close} = props;
    const warnOnClose = useCallback(() => {
        showWarning({
            info: {
                key: popupInfoKeys.DISCARD_SCHEDULE_JOB
            },
            buttons: [{
                title: t('common:option.discard'),
                onClick: close
            }]
        });
    }, [showWarning, close, t]);

    const onClose = useCallback(() => {
        const conditionsEnabled = getValues(conditions)
            .some(val => val.enabled);

        if (conditionsEnabled || isNotEmptyNorFalsy({scheduleName, description})) {
            warnOnClose();
        } else {
            close();
        }
    }, [scheduleName, description, conditions, warnOnClose, close]);

    // Submit Callbacks
    const {submitForm} = props;
    const warnOnDuplicateSchedule = useCallback(() => {
        showWarning({
            info: {
                key: popupInfoKeys.DUPLICATE_JOB_VALUES,
                values: {
                    action: t('jobSchedule:option.schedule_lower')
                }
            },
            buttons: [{
                title: t('jobSchedule:option.schedule'),
                onClick: submitForm
            }]
        })
    }, [submitForm, showWarning, t]);

    const onSchedule = useCallback(() => {
        const finalValues = {
            scheduleName,
            description,
            scheduleTrigger,
            eventTrigger,
            conditions,
            clientId,
            matterId,
            libraryId,
            workflowTemplateId,
            parameters
        };

        if (!objEquals(initialValues.current, finalValues)) {
            submitForm();
        } else {
            warnOnDuplicateSchedule();
        }
    }, [scheduleName, description, scheduleTrigger, eventTrigger, conditions, clientId, matterId, libraryId, workflowTemplateId, parameters, warnOnDuplicateSchedule, submitForm]);

    const allowedNavigations = JobScheduleModel.getAllowedPaneNavigations(props);

    const [onCancel, submitTitle, onSubmit, onTabletClose] = switchcase({
        [updateMode.REDUX]: [onClose, t('jobSchedule:option.add'), onSchedule, onClose],
        [updateMode.EDIT]: [cancelEdit, t('common:option.save'), saveEdit, close]
    })()(mode);

    const panes = [
        {title: t('jobSchedule:label.settings'), component: <ScheduleSettingsPane/>, backTitle: t('common:option.cancel'), onBackClick: onCancel, isNextEnabled: allowedNavigations.triggerPane},
        {title: t('jobSchedule:label.trigger_plural'), component: <ScheduleTriggerPane/>, onNextClick: setSameAsJobDefaults, isNextEnabled: allowedNavigations.clientPane},
        {title: t('job:label.queueMatter'), component: <ScheduleMatterPane/>, onNextClick: onMatterPaneNext, isNextEnabled: allowedNavigations.workflowPane},
        {title: t('job:label.queueWorkflow'), component: <ScheduleWorkflowPane/>, onNextClick: getOperationsAndParameters, isNextEnabled: allowedNavigations.settingsPane},
        {title: t('jobSchedule:label.jobSettings'), component: <ScheduleJobPane/>, onNextClick: encryptProtectedParameters, onBackClick: encryptProtectedParameters, isNextEnabled: allowedNavigations.submit},
        {title: t('job:label.queueConfirm'), component: <ScheduleConfirmPane/>, nextTitle: submitTitle, onNextClick: onSubmit, isNextEnabled: allowedNavigations.submit}
    ];

    return (
        <NavigatePane id={'scheduleJobForm'} panes={panes} onClose={onTabletClose}
            isLoading={isLoading} isDisabled={isDisabled} force={force} closeButtonAriaLabel={t('jobSchedule:option.closeForm')}
            bodyStyle={{
                height: '62.5vh'
            }}
        />
    )
}

function makeMapStateToProps() {
    const getEditDetails = makeGetEditDetails();

    return function(state) {
        const {componentStates: {jobScheduleForm}, hasLoaded} = state;
        const {clientId, libraryId} = jobScheduleForm;

        const {activeModel} = getEditDetails(state, {model: JobScheduleModel.nom});
        const mode = (activeModel === JobScheduleModel.nom) ? updateMode.EDIT : updateMode.REDUX;

        return {
            mode,
            ...jobScheduleForm,
            isLoadingClientMatters: !(hasLoaded[details.CLIENTS] && hasLoaded[clientId]),
            isLoadingLibraryWorkflows: !(hasLoaded[details.LIBRARIES] && hasLoaded[libraryId])
        }
    }
}

function mapDispatchToProps(dispatch) {
    return {
        submitForm: () => dispatch(JobScheduleModel.actionCreators.submitForm()),
        getOperationsAndParameters: () => dispatch(JobScheduleModel.actionCreators.getOperationsAndParameters()),
        setSameAsJobDefaults: () => dispatch(JobScheduleModel.actionCreators.setSameAsJobDefaults()),
        encryptProtectedParameters: () => dispatch(JobScheduleModel.actionCreators.encryptProtectedValues('parameters')),

        saveEdit: () => dispatch(JobScheduleModel.actionCreators.saveEdit()),
        cancelEdit: () => dispatch(JobScheduleModel.actionCreators.cancelEdit()),

        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        close: position => dispatch(JobScheduleModel.actionCreators.hideForm(position))
    }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(ScheduleJob);