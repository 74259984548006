import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import PolicyRow from './PolicyRow';
import {AddButtonHeader} from "../common/Button/Button";
import {applicationFeatures} from "../../utilities/constants";
import {getValues, isNotEmptyNorFalsy} from "../../utilities/helperFunctions";
import PolicyModel from "../../models/settings/PolicyModel";
import {TableLabelHeader} from "../common/CustomTable/CustomTable";

function PolicyTable() {
    const {t} = useTranslation(['aria', 'policy', 'common']);
    const dispatch = useDispatch();

    const policyDetailsMap = useSelector(state => state.policyDetailsMap);
    const userFeatures = useSelector(state => state.currentUser.features);
    const {policyId: selectedId} = useSelector(state => state.componentStates.policyDisplay);

    function showForm() {
        dispatch(PolicyModel.actionCreators.showForm());
    }

    const canAddPolicy = userFeatures.includes(applicationFeatures.ADD_SECURITY);
    const isNotEmpty = isNotEmptyNorFalsy(policyDetailsMap);
    if (!canAddPolicy && !isNotEmpty) {
        return null;
    }

    return (
        <div className="display-item">
            <AddButtonHeader aria-label={t('aria:hiddenAssistText.addPolicy')} text={t('policy:label.name')}
                canModify={canAddPolicy} onClick={showForm}
            />

            {isNotEmpty &&
                <div className="display-table" style={{tableLayout: 'fixed'}}>
                    <div className="table-header-group">
                        <TableLabelHeader style={{width: '4rem'}}/>

                        <TableLabelHeader label={t('common:label.name')} style={{width: '40%'}}/>

                        <TableLabelHeader label={t('policy:label.principals')}/>
                        <TableLabelHeader/>

                        <TableLabelHeader label={t('policy:label.permissions')}/>

                        <TableLabelHeader label={t('policy:label.scope')}/>
                        <TableLabelHeader/>
                    </div>

                    <div className="table-row-group">
                        {getValues(policyDetailsMap).map(policy =>
                            <PolicyRow key={policy.id} policy={policy}
                                isActive={policy.id === selectedId}/>
                        )}
                    </div>
                </div>
            }
        </div>
    );
}

export default PolicyTable;
