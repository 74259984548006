import React, {useRef} from "react";
import "./UserComment.css";
import {UserComment} from "./UserCommentBody";
import UserCommentForm from "./UserCommentForm";
import {useDispatch} from "react-redux";
import UserCommentModel from "../../models/generics/UserCommentModel";
import {useViewedCallback} from "../../utilities/hooks";

function UserCommentsDisplay(props) {
    const {
        objectType,
        objectId,

        comments,
        canComment,
        isDisabled
    } = props;

    return (
        <section className="user-comment__display">
            {Array.isArray(comments) && comments.map(comment =>
                <UserCommentWrapper key={comment.id} objectType={objectType} objectId={objectId}
                    canComment={canComment} comment={comment} isDisabled={isDisabled}/>
            )}

            {canComment &&
                <UserCommentForm objectType={objectType} objectId={objectId}
                    canComment={canComment} isDisabled={isDisabled}/>
            }
        </section>
    )
}

function UserCommentWrapper(props) {
    const dispatch = useDispatch();
    const {
        objectType,
        objectId,
        comment,
        canComment,
        isDisabled
    } = props;

    const ref = useRef();
    // Wait for comment to be visible for 1.5 seconds before dispatching userComment.setViewed
    useViewedCallback(ref, () => {
        dispatch(UserCommentModel.actionCreators.setViewed(objectType, objectId, comment.id));
    }, {isActive: !comment.viewedByUser, delay: 1000, visibleBoundsMargin: canComment && {bottom: 64}});

    return (
        <UserComment {...comment} ref={ref} isDisabled={isDisabled}/>
    )
}

export default UserCommentsDisplay;
