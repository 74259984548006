import {SettingsRowValue} from "../../common/CustomTable/CustomTable";
import React from "react";
import {useTranslation} from "react-i18next";
import ThirdPartyUserCredential from "../../../models/thirdparty/ThirdPartyUserCredential";
import UsernamePasswordUserCredentialFormBody from "../userCredential/UsernamePasswordCredentialFormBody";

function GraphUserCredentialFormBody(props) {
    return (
        <UsernamePasswordUserCredentialFormBody {...props} type={ThirdPartyUserCredential.Type.GRAPH}
            SettingRows={GraphUserCredentialFormBodySettingRows}/>
    )
}

function GraphUserCredentialFormBodySettingRows(props) {
    const {t} = useTranslation(['thirdPartyService', 'common']);
    const {
        thirdPartyService,
        isDisabled
    } = props;

    return (
        <>
            <SettingsRowValue label={t('thirdPartyService:label.hostname')} value={thirdPartyService.hostname}
                isTight isDisabled={isDisabled}/>
        </>
    )
}

export default GraphUserCredentialFormBody;
