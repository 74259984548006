import React, {useLayoutEffect, useMemo, useState} from "react";
import Tablet, {IconSubtitle} from "../common/Tablet/Tablet";
import {useDispatch, useSelector} from "react-redux";
import LegalHoldModel from "../../models/legalhold/LegalHoldModel";
import {useTranslation} from "react-i18next";
import {
    buildClassName,
    getMapValueName,
    getValues,
    includesSome,
    isNotEmptyNorFalsy,
    objectTruthyValues
} from "../../utilities/helperFunctions";
import {legalHoldStateKeys, permissionKeys} from "../../i18next/keys";
import ExpandableContent, {
    ExpandableChangeLogTable,
    ExpandableLog
} from "../common/ExpandableContent/ExpandableContent";
import {SettingsRowSeparator, SettingsRowValue, SettingsUserIconRow} from "../common/CustomTable/CustomTable";
import {details, legalHoldCommands, legalHoldRoles, userSettings} from "../../utilities/constants";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import UserNoticeView from "../notice/UserNoticeView";
import {ExpandableParameters} from "../noticeTemplate/panes/NoticeConfirmPane";
import PopupModel from "../../models/scheduler/PopupModel";
import LegalHoldExportForm from "./LegalHoldExportForm";
import EditModel from "../../models/scheduler/EditModel";
import ExpandableNoticeConfigurations from "./ExpandableNoticeConfigurations";
import NoticeInfoTable from "./panes/NoticeInfoTable";
import UserServiceModel from "../../models/user/UserServiceModel";
import Text from "../common/Text/Text";
import {ExpandableStatusLogWrapper, StatusLabel} from "../common/Common";
import {userIconModel} from "../../models/generics/IconModel";
import CustodiansTable from "./CustodiansTable";
import AdministratorTable from "./AdministratorTable";
import {MenuDropdown} from "../common/Dropdown/Dropdown";
import ProgressRing from "../common/ProgressRing/ProgressRing";
import ExpandableTriggerConfigurations from "./ExpandableTriggerConfigurations";
import {PriorityLabel} from "../job/JobTablet";
import JobModel from "../../models/job/JobModel";
import ExpandableTriggerConfigurationEvents from "./ExpandableTriggerConfigurationEvents";
import UserModel from "../../models/user/UserModel";
import ThirdPartyServiceModel from "../../models/thirdparty/ThirdPartyServiceModel";
import {SmtpEmailCounterSettings} from "../thirdPartyService/smtp/SmtpServiceSettings";
import {getExportFormComponent} from "../thirdPartyService/ThirdPartyServiceTablet";


function selectUserParticipations(state, legalHoldId) {
    const participations = state.legalHoldParticipationDetailsMap.get(legalHoldId);
    const userIdToNoticeDatasetEvents = state.noticeDatasetEventDetailsMap.get(legalHoldId);

    return useMemo(() => {
        return (participations || [])
            .map(participation => {
                const user = state.userDetailsMap.get(participation.userId) || {};
                const noticeDatasetEvents = (userIdToNoticeDatasetEvents || {})[participation.userId];

                return {
                    name: participation.userId,
                    ...participation,
                    ...user,
                    noticeDatasetEvents
                }
            });
    }, [participations, userIdToNoticeDatasetEvents, state.userDetailsMap]);
}

function selectSsoUserServiceStates(state, t, enableLoginLinks, userParticipations) {
    if (!enableLoginLinks) return;

    return useMemo(() => {
        const userServiceIds = userParticipations.reduce((acc, curr) => {
            acc[curr.userServiceId] = true;
            return acc;
        }, {});

        return objectTruthyValues(userServiceIds)
            // Assuming null userServiceId is from an internal user
            .map(userServiceId => (userServiceId == null || userServiceId === 'null') ? {name: t('common:label.internal')} : state.userServiceDetailsMap.get(userServiceId))
            .filter(service => service)
            .map(service => {
                let stateTranslationKey;
                if (!UserServiceModel.canTypeUseLoginLinks(service.type)) {
                    stateTranslationKey = 'common:label.na';
                } else if ((service.enabled && service.enableLoginLinks && service.loginLinkAutoExpireInterval > 0) || service.type === UserServiceModel.types.MICROSOFT) {
                    stateTranslationKey = 'legalHold:label.enabled';
                } else {
                    stateTranslationKey = 'legalHold:label.disabled';
                }
                return '\u2022 ' + service.name + ' - ' + t(stateTranslationKey);
            });
    }, [enableLoginLinks, userParticipations, state.userServiceDetailsMap]);
}

function selectNoticeInfos(state, legalHoldId) {
    return (state.noticeInfoDetailsMap.get(legalHoldId) || []);
}

export function selectLegalHoldNames(state, legalHold) {
    return {
        client: getMapValueName(state.clientDetailsMap, legalHold.clientId),
        matter: getMapValueName(state.matterDetailsMap, legalHold.matterId),
        dataRepository: getMapValueName(state.dataRepositoryDetailsMap, legalHold.dataRepositoryId),
        smtpService: getMapValueName(state.thirdPartyServiceDetailsMap, legalHold.smtpServiceId),
        executionProfile: getMapValueName(state.executionProfileDetailsMap, legalHold.executionProfileId),
        resourcePool: getMapValueName(state.resourcePoolDetailsMap, legalHold.resourcePoolId),
    }
}

function LegalHoldTablet(props) {
    const {t} = useTranslation(['legalHold', 'matter', 'dataset', 'workflow', 'common']);
    const dispatch = useDispatch();

    const {
        legalHoldId
    } = props;

    const isLoading = useSelector(state => !state.hasLoaded[legalHoldId]);
    const legalHold = useSelector(state => state.legalHoldDetailsMap.get(legalHoldId));
    const names = useSelector(state => selectLegalHoldNames(state, legalHold));
    const auditLog = useSelector(state => state.auditLogMap.get(legalHoldId));

    const [createdByUsername, createdByUserIconName] = UserModel.useUserNameAndIconName(legalHold.createdBy);
    const [lastChangedByUsername, lastChangedByUserIconName] = UserModel.useUserNameAndIconName(legalHold.lastChangedBy);

    const createdByIcon = userIconModel.useIcon(createdByUserIconName);
    const lastChangedByIcon = userIconModel.useIcon(lastChangedByUserIconName);

    const hasUserNotices = useSelector(state => {
        if (legalHold.silent) return false;
        return getValues(state.userNoticeDetailsMap)
                .filter(userNotice => userNotice.legalHoldId === legalHoldId && userNotice.userPermissions.includes(permissionKeys.RESPOND)).length > 0
        }
    );

    const userParticipations = useSelector(state => selectUserParticipations(state, legalHoldId));
    const requiresDataRepository = useSelector(state => LegalHoldModel.getRequiresDataRepository(legalHold, state.noticeTemplateDetailsMap));
    const noticeInfos = useSelector(state => selectNoticeInfos(state, legalHoldId));
    const ssoUserServiceKeys = useSelector(state => selectSsoUserServiceStates(state, t, legalHold.enableLoginLinks, userParticipations));

    const triggerConfigurationEvents = useSelector(state => state.triggerConfigurationEventDetailsMap.get(legalHoldId));
    const hasTriggeredConfigurationJobEvents = !!triggerConfigurationEvents && getValues(triggerConfigurationEvents).some(evt => evt.jobId);

    const {isDisabled: isNoticeCommentFormDisabled} = useSelector(state => state.componentStates.noticeCommentForm);
    const {isDisabled: isUserNoticeViewDisabled, isUploadActive} = useSelector(state => state.componentStates.userNoticeView);

    const {
        isExportFormActive,
        isDisabled
    } = useSelector(state => state.componentStates.legalHoldTablet);

    const {
        state,
        name,
        description,

        silent,
        holdNoticeConfiguration,
        recurringNoticeConfiguration,
        releaseNoticeConfiguration,
        surveyNoticeConfigurations,

        useTriggerConfigurations,
        triggerConfigurations,
        priority,

        surveyNoticesTrigger,
        enableLoginLinks,
        schedulerUrl,
        parameters,

        createdDate,
        lastChangedDate,

        status,
        taskState,
        userPermissions
    } = legalHold;

    const canView = userPermissions.includes(permissionKeys.VIEW);
    const canManage = userPermissions.includes(permissionKeys.MANAGE);
    const canModify = userPermissions.includes(permissionKeys.MODIFY);
    const canViewSensitive = userPermissions.includes(permissionKeys.VIEW_SENSITIVE);

    const isLegalHoldBusy = taskState !== LegalHoldModel.TaskState.DONE;
    const canModifyCustodians = includesSome([legalHoldStateKeys.DRAFT, legalHoldStateKeys.ACTIVE], [state]) && (canModify || canManage) && !isLegalHoldBusy;
    const hasLoadedJobDetails = useSelector(state => state.hasLoaded[details.QUEUE_JOBS]);

    const isErrord = taskState === LegalHoldModel.TaskState.ERROR || state === LegalHoldModel.State.ERROR;
    const canChangeState = canModify || canManage;


    const smtpService = useSelector(state => !legalHold.silent && state.thirdPartyServiceDetailsMap.get(legalHold.smtpServiceId));
    const serviceActionBodies = ThirdPartyServiceModel.getServiceActionBodies(smtpService, legalHold.emailCounter);

    const [isSmtpServiceExportFormActive, setIsSmtpServiceExportFormActive] = useState();
    const SmtpServiceExportFormComponent = getExportFormComponent(smtpService?.type);


    // Start and Stop polling settings
    useLayoutEffect(() => {
        dispatch(LegalHoldModel.actionCreators.startPollingSettings(legalHoldId));
        dispatch(JobModel.actionCreators.startPollingDetails());

        return () => {
            dispatch(LegalHoldModel.actionCreators.stopPollingSettings());
            dispatch(JobModel.actionCreators.stopPollingDetails());
        }
    }, [legalHoldId, dispatch]);

    function navigateToJob (event) {
        const {value} = event.currentTarget.dataset;
        dispatch(LegalHoldModel.componentActionCreators.resetDisplay())

        window.location.href = `${window.location.origin + window.location.pathname}#/jobs/queue?id=${value}`;
    }

    function menuOptionHandler(event) {
        const {value} = event.currentTarget.dataset;
        dispatch(EditModel.actionCreators.callEditBreakingFunc(function () {
            switch (value) {
                case 'startEdit':
                case 'promptDelete':
                case 'duplicateDetails':
                    dispatch(LegalHoldModel.actionCreators[value](legalHoldId));
                    break;
                case 'export':
                    dispatch(LegalHoldModel.componentActionCreators.updateTablet({isExportFormActive: true}));
                    break;
                case 'exportEmailsCsv':
                    if (SmtpServiceExportFormComponent != null) {
                        setIsSmtpServiceExportFormActive(true);
                    }
                    break;
                case legalHoldCommands.ACTIVATE:
                case legalHoldCommands.RELEASE:
                case legalHoldCommands.ARCHIVE:
                    const titleKey = `legalHold:option.${value}`;
                    // Ask for confirmation
                    dispatch(PopupModel.actionCreators.show({
                        info: {
                            key: `legalHoldCommand${value}`,
                            values: {name}
                        },
                        buttons: [{
                            titleKey,
                            onClick: () => {
                                dispatch(LegalHoldModel.actionCreators.sendCommand(legalHoldId, value));
                            }
                        }]
                    }));
                    break;
            }

            if (serviceActionBodies != null) {
                const actionBody = serviceActionBodies.find(a => a.action === value);
                if (actionBody != null) {
                    actionBody.onSuccess = () => dispatch(LegalHoldModel.actionCreators.queryDetails());
                    dispatch(ThirdPartyServiceModel.actionCreators.sendAction(actionBody));
                }
            }
        }));
    }

    const menuOptions = [];
    if (state === legalHoldStateKeys.DRAFT || state === legalHoldStateKeys.ACTIVE) {
        menuOptions.push({name: t('common:option.edit'), value: 'startEdit', isDisabled: !(canModify || canManage) || isLegalHoldBusy});
    }
    if (canModify) {
        menuOptions.push({name: t('common:option.duplicate'), value: 'duplicateDetails'});
    }
    menuOptions.push({name: t('common:option.delete'), value: 'promptDelete', isDisabled: !canModify || ((state === legalHoldStateKeys.ACTIVE || isLegalHoldBusy) && !isErrord)});

    if (state !== legalHoldStateKeys.DRAFT) {
        // menuOptions.push({isSeparator: true});
        menuOptions.push({name: t('common:option.export'), value: 'export', isDisabled: !(canViewSensitive || canManage)});
    }
    if (state === legalHoldStateKeys.DRAFT) {
        // menuOptions.push({isSeparator: true});
        menuOptions.push({name: t('legalHold:option.activate'), value: legalHoldCommands.ACTIVATE, isDisabled: !canChangeState || isLegalHoldBusy});

    } else if (state === legalHoldStateKeys.ACTIVE) {
        menuOptions.push({name: t('legalHold:option.release'), value: legalHoldCommands.RELEASE, isDisabled: !canChangeState || isLegalHoldBusy});

    } else if (state === legalHoldStateKeys.RELEASED) {
        menuOptions.push({name: t('legalHold:option.archive'), value: legalHoldCommands.ARCHIVE, isDisabled: !canModify || isLegalHoldBusy});
    }


    const canViewSmtpServiceSensitive = smtpService && smtpService.userPermissions.includes(permissionKeys.VIEW_SENSITIVE);
    const canExportSmtpEmailsCsv = canViewSmtpServiceSensitive && ThirdPartyServiceModel.canExport(smtpService);

    if (canExportSmtpEmailsCsv || isNotEmptyNorFalsy(serviceActionBodies)) {
        menuOptions.push({isSeparator: true});
    }
    if (canExportSmtpEmailsCsv) {
        menuOptions.push({name: t('common:option.exportEmailsCsv'), value: 'exportEmailsCsv'});
    }
    if (serviceActionBodies != null) {
        for (const actionBody of serviceActionBodies) {
            menuOptions.push({
                name: t(`legalHold:thirdPartyAction.${actionBody.action}`),
                value: actionBody.action,
                isDisabled: actionBody.disabled
            });
        }
    }


    const administratorParticipations = userParticipations.filter(p => p.role === legalHoldRoles.ADMINISTRATOR);
    const custodianParticipations = userParticipations.filter(p => p.role === legalHoldRoles.CUSTODIAN);

    const isTabletDisabled = isDisabled || isNoticeCommentFormDisabled || isUserNoticeViewDisabled || isUploadActive;
    const isLegalHoldDisabled = isDisabled || isUserNoticeViewDisabled;

    return (
        <>
            <Tablet width={'100rem'} height={'65vh'} onClose={() => dispatch(LegalHoldModel.actionCreators.hideTablet())}
                closeButtonAriaLabel={t('legalHold:option.closeTablet_name', {name})} isDisabled={isTabletDisabled}
                header={
                    <Header legalHold={legalHold} menuOptions={menuOptions}
                        menuOptionHandler={menuOptionHandler} isDisabled={isTabletDisabled}/>
                }
                body={
                    <LoadingWrapper isLoading={isLoading}>

                        <ExpandableStatusLogWrapper status={status}
                            isDisabled={isDisabled}/>

                        {legalHold.emailCounter &&
                            <ExpandableStatusLogWrapper status={legalHold.emailCounter.status}
                                isDisabled={isDisabled}/>
                        }


                        {(canView || canManage) &&
                        <div className="display-item">
                            <ExpandableContent label={t('common:label.settings')}
                                isDisabled={isLegalHoldDisabled}
                            >
                                <div className="settings-table">
                                    <div className="table-row-group">
                                        <SettingsRowValue label={t('legalHold:label.client')} value={names.client} isDisabled={isLegalHoldDisabled}/>
                                        <SettingsRowValue label={t('legalHold:label.matter')} value={names.matter} isDisabled={isLegalHoldDisabled}/>

                                        {!silent &&
                                        <>
                                            <SettingsRowSeparator/>
                                            {canView &&
                                                <>
                                                {requiresDataRepository &&
                                                    <SettingsRowValue label={t('legalHold:label.dataRepository')} isDisabled={isLegalHoldDisabled}
                                                        value={
                                                            <div style={{display: 'flex'}}>
                                                                {names.dataRepository &&
                                                                    <Text value={names.dataRepository} isDisabled={isLegalHoldDisabled}/>
                                                                }
                                                                {!names.dataRepository &&
                                                                    <StatusLabel isDisabled={isLegalHoldDisabled}
                                                                        message={t('legalHold:message.dataRepositoryRequiredToActivate')}/>
                                                                }
                                                            </div>
                                                        }/>
                                                }
                                                <SettingsRowValue label={t('legalHold:label.smtpService')} isDisabled={isLegalHoldDisabled}
                                                    value={
                                                        <div style={{display: 'flex'}}>
                                                            {names.smtpService &&
                                                            <Text value={names.smtpService} isDisabled={isLegalHoldDisabled}/>
                                                            }
                                                            {!names.smtpService &&
                                                            <StatusLabel isDisabled={isLegalHoldDisabled}
                                                                message={t('legalHold:message.smtpServiceRequiredToActivate')}/>
                                                            }
                                                        </div>
                                                    }/>
                                                </>
                                            }

                                            <SettingsRowSeparator/>
                                            <SettingsRowValue label={t('legalHold:label.schedulerUrl')} value={schedulerUrl} isDisabled={isLegalHoldDisabled}/>
                                            <SettingsRowValue label={t('legalHold:label.surveyNoticesTrigger')} value={t(`legalHold:surveyNoticesTrigger.${surveyNoticesTrigger}`)}
                                                isDisabled={isLegalHoldDisabled}/>

                                            <SettingsRowValue label={t('legalHold:label.singleSignOn')} value={t(`common:label.${enableLoginLinks ? 'on' : 'off'}`)} isDisabled={isLegalHoldDisabled}/>
                                            {isNotEmptyNorFalsy(ssoUserServiceKeys) && ssoUserServiceKeys.map((ssoUserServiceKey, i) =>
                                                <div key={i} className="settings-row">
                                                    <div className="settings-label-cell"/>
                                                    <div className="settings-value-cell">
                                                        <Text value={t(ssoUserServiceKey)} isDisabled={isLegalHoldDisabled}/>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                        }

                                        {canView && useTriggerConfigurations &&
                                            <>
                                                <SettingsRowSeparator/>
                                                <SettingsRowValue label={t('legalHold:label.executionProfile')} value={names.executionProfile} isDisabled={isDisabled}/>
                                                <SettingsRowValue label={t('legalHold:label.resourcePool')} value={names.resourcePool} isDisabled={isDisabled}/>
                                                <SettingsRowValue label={t('legalHold:label.priority')} value={<PriorityLabel priority={priority} isDisabled={isDisabled}/>}
                                                    isDisabled={isDisabled}/>
                                            </>
                                        }

                                        <SettingsRowSeparator/>
                                        <SettingsUserIconRow label={t('legalHold:label.createdBy')} value={createdByUsername} icon={createdByIcon} isDisabled={isLegalHoldDisabled}/>
                                        <SettingsRowValue label={t('legalHold:label.createdDate')} value={createdDate} isDisabled={isLegalHoldDisabled}/>

                                        {lastChangedByUsername && lastChangedByIcon &&
                                        <>
                                            <SettingsRowSeparator/>
                                            <SettingsUserIconRow label={t('legalHold:label.lastChangedBy')} value={lastChangedByUsername} icon={lastChangedByIcon} isDisabled={isLegalHoldDisabled}/>
                                            <SettingsRowValue label={t('legalHold:label.lastChangedDate')} value={lastChangedDate} isDisabled={isLegalHoldDisabled}/>
                                        </>
                                        }
                                    </div>
                                </div>
                            </ExpandableContent>
                        </div>
                        }

                        {isNotEmptyNorFalsy(description) &&
                        <div className="display-item">
                            <ExpandableLog label={t('common:label.description')} log={description}
                                isDisabled={isLegalHoldDisabled}
                            />
                        </div>
                        }

                        {!silent && isNotEmptyNorFalsy(parameters) &&
                        <div className="display-item">
                            <ExpandableParameters parameters={parameters}
                                isDisabled={isLegalHoldDisabled}/>
                        </div>
                        }

                        {state === legalHoldStateKeys.DRAFT && !silent && canView &&
                        <div className="display-item">
                            <ExpandableNoticeConfigurations holdNoticeConfiguration={holdNoticeConfiguration} releaseNoticeConfiguration={releaseNoticeConfiguration}
                                recurringNoticeConfiguration={recurringNoticeConfiguration} surveyNoticeConfigurations={surveyNoticeConfigurations} isDisabled={isLegalHoldDisabled}/>
                        </div>
                        }

                        {state !== legalHoldStateKeys.DRAFT && !silent && (canView || canManage) && isNotEmptyNorFalsy(noticeInfos) &&
                        <div className="display-item">
                            <ExpandableContent label={t('legalHold:label.notices')}
                                isDisabled={isLegalHoldDisabled}
                            >
                                <NoticeInfoTable noticeConfigurations={[holdNoticeConfiguration, recurringNoticeConfiguration, releaseNoticeConfiguration, ...surveyNoticeConfigurations]}
                                    noticeInfos={noticeInfos} isDisabled={isLegalHoldDisabled}/>

                            </ExpandableContent>
                        </div>
                        }

                        {legalHold.emailCounter &&
                            <SmtpEmailCounterSettings emailCounter={legalHold.emailCounter}
                                isDisabled={isDisabled}/>
                        }

                        {canView && useTriggerConfigurations &&
                            <div className="display-item">
                                <ExpandableTriggerConfigurations triggerConfigurations={triggerConfigurations}
                                                                 isDisabled={isDisabled}/>
                            </div>
                        }

                        {((canView || canManage) && hasLoadedJobDetails && hasTriggeredConfigurationJobEvents && !!triggerConfigurationEvents && triggerConfigurationEvents.size !== 0) &&
                            <div className="display-item">
                                <ExpandableTriggerConfigurationEvents triggerConfigurationEvents={triggerConfigurationEvents} isDisabled={isLegalHoldDisabled}
                                    custodianParticipations={custodianParticipations} navigateToJob={navigateToJob}/>
                            </div>
                        }

                        {isNotEmptyNorFalsy(administratorParticipations) &&
                        <div className="display-item">
                            <AdministratorTable
                                administrators={administratorParticipations} isDisabled={isLegalHoldDisabled}/>
                        </div>
                        }

                        {(canView || canManage) &&
                        <div className="display-item">
                            <CustodiansTable legalHold={legalHold} custodianParticipations={custodianParticipations}
                                canModify={canModifyCustodians} isDisabled={isLegalHoldDisabled}/>
                        </div>
                        }

                        {hasUserNotices &&
                        <div className="display-item">
                            <ExpandableContent label={t('legalHold:label.noticeInbox')}
                                isDisabled={isLegalHoldDisabled}
                            >
                                <UserNoticeView legalHoldId={legalHoldId} isDisabled={isDisabled}/>
                            </ExpandableContent>
                        </div>
                        }

                        {isNotEmptyNorFalsy(auditLog) &&
                        <div className="display-item">
                            <ExpandableChangeLogTable label={t('common:label.auditLog')} changeLogs={auditLog}
                                isDisabled={isLegalHoldDisabled}/>
                        </div>
                        }
                    </LoadingWrapper>
                }
            />

            {isExportFormActive &&
            <LegalHoldExportForm legalHoldId={legalHoldId} onClose={() => dispatch(LegalHoldModel.componentActionCreators.updateTablet({isExportFormActive: false}))}
                silent={silent} isDisabled={isLegalHoldDisabled}/>
            }

            {isSmtpServiceExportFormActive &&
                <SmtpServiceExportFormComponent initiatorId={legalHoldId} thirdPartyService={smtpService}
                    onClose={() => setIsSmtpServiceExportFormActive(false)} isDisabled={isDisabled}/>
            }
        </>
    )
}

function Header(props) {
    const {t} = useTranslation(['aria', 'legalHold']);

    const {
        legalHold,
        menuOptions,
        menuOptionHandler,
        isDisabled
    } = props;

    const {showObjectIds} = useSelector(state => state.userSettingsMap.get(userSettings.TROUBLESHOOT));

    const headerLeftClassName = buildClassName(
        'tablet-header-left',
        isDisabled && 'is-disabled'
    )

    return (
        <>
            <div className={headerLeftClassName}>
                <div className="object-id-subtitle">
                    <h2 className="subtitle is-bold">
                        {legalHold.name}
                    </h2>
                    {showObjectIds &&
                        <label className="id-label">
                            {legalHold.id}
                        </label>
                    }
                </div>
                <div className="display-menu">
                    <MenuDropdown aria-label={t('aria:hiddenAssistText.legalHoldMenu')} menuOptions={menuOptions}
                        onOptionClick={menuOptionHandler} id={'legalHoldMenuDropdown'} isDisabled={isDisabled}
                    />
                </div>
            </div>

            <LegalHoldState legalHold={legalHold} isDisabled={isDisabled}/>
        </>
    )
}

function LegalHoldState(props) {
    const {t} = useTranslation(['legalHold']);
    const {
        legalHold,
        isDisabled,
    } = props;

    const {
        state,
        taskState,
        taskPercentProgress
    } = legalHold;


    const isErrord = taskState === LegalHoldModel.TaskState.ERROR || state === LegalHoldModel.State.ERROR;
    if (isErrord) {
        return (
            <IconSubtitle type={'statusError'} subtitle={t(`legalHold:state.ERROR`)}
                style={{height: '3rem'}} isDisabled={isDisabled}/>
        )
    }

    const isLegalHoldBusy = taskState && taskState !== LegalHoldModel.TaskState.DONE;
    if (!isLegalHoldBusy) {
        const type = `legalHold${state}`;
        return (
            <IconSubtitle type={type} subtitle={t(`legalHold:state.${state}`)}
                style={{height: '3rem'}} isDisabled={isDisabled}/>
        )
    }

    const showPercentage = isLegalHoldBusy && taskPercentProgress != null;
    const disabled = isDisabled ? ' is-disabled' : '';

    return (
        <div className="icon-subtitle">
            <h2 className={'subtitle is-bold' + disabled} style={{marginRight: '0.5rem'}}>
                {t(`legalHold:taskState.${taskState}`)}
                {!!(showPercentage) && ` ${taskPercentProgress}%`}
            </h2>
            <span className={'icon is-large' + disabled}>
                <ProgressRing percentageComplete={taskPercentProgress} size={'large'}/>
            </span>
        </div>
    )
}

export default LegalHoldTablet;
