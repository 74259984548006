import React, {useEffect, useState} from "react";
import Form, {FormFooter, FormHeader} from "../common/Form/Form";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import LegalHoldModel from "../../models/legalhold/LegalHoldModel";
import {createStateHandler} from "../../utilities/componentFunctions";
import {createToggleHandler} from "../common/Checkbox/helpers";
import Checkbox from "../common/Checkbox/Checkbox";
import ToggleSwitch from "../common/ToggleSwitch/ToggleSwitch";
import {SwitchItemContainer} from "../common/ListContainer/ListContainer";
import {getValues, isNotEmptyNorFalsy} from "../../utilities/helperFunctions";
import {TypeSelectableItem} from "../common/SelectableItem/SelectableItem";


function selectNoticeItems(state, legalHoldId, t) {
    return getValues(state.noticeInfoDetailsMap.get(legalHoldId))
        .map(noticeInfo => ({
            type: t(`noticeTemplate:type.${noticeInfo.type}`),
            name: noticeInfo.subject,
            value: noticeInfo.id
        }));
}


function LegalHoldExportForm(props) {
    const {t} = useTranslation(['legalHold', 'common']);
    const dispatch = useDispatch();

    const {
        legalHoldId,
        silent,
        onClose,
        isDisabled
    } = props;

    const [notices, setNotices] = useState({
        left: [...useSelector(state => selectNoticeItems(state, legalHoldId, t))],
        right: []
    });

    useEffect(() => {
        if (silent) {
            dispatch(LegalHoldModel.componentActionCreators.updateExportForm({
                exportNoticesOverview: false,
                exportNoticeResponses: false,
                exportUserNoticeHtml: false,
                includeIcons: false,
                includeComments: false,
                includeAdminNotes: false,
                includeDatasetFiles: false
            }));
        }
    }, [silent]);

    const {
        exportCustodiansOverview,
        exportDatasetsOverview,
        exportNoticesOverview,
        exportNoticeResponses,
        exportUserNoticeHtml,
        includeIcons,
        includeComments,
        includeAdminNotes,
        includeDatasetFiles
    } = useSelector(state => state.componentStates.legalHoldExportForm);

    const reduxHandler = createStateHandler({
        updateState: updates => dispatch(LegalHoldModel.componentActionCreators.updateExportForm(updates))
    });

    const toggleEnable = createToggleHandler({
        handler: reduxHandler
    });


    function onSubmit() {
        const noticeIds = notices.right.map(item => item.value)
        dispatch(LegalHoldModel.actionCreators.exportLegalHold(legalHoldId, {noticeIds}));
    }


    const areNoticesRequired = !silent && (exportNoticesOverview || exportNoticeResponses || exportUserNoticeHtml);
    const areOptionsRequired = !exportCustodiansOverview && !exportDatasetsOverview && !exportNoticesOverview && !exportNoticeResponses && !exportUserNoticeHtml;
    const isSubmitEnabled = !areOptionsRequired && (!areNoticesRequired || isNotEmptyNorFalsy(notices.right));

    const required = areOptionsRequired ? ' is-required' : '';
    const isNoticeExportDisabled = silent || isDisabled;
    const isHtmlExportOptionsDisabled = !exportUserNoticeHtml || isNoticeExportDisabled;

    return (
        <Form onClose={onClose} isDisabled={isDisabled} closeButtonAriaLabel={t('legalHold:option.closeExportForm')}
            header={
                <FormHeader text={t('legalHold:label.export')} iconName={'legalHoldExport'} isDisabled={isDisabled}/>
            }
            body={
                <>
                    <div className={'display-input transparent-border' + required}>
                        <ToggleSwitch label={t('legalHold:label.exportCustodiansOverview')} name={'exportCustodiansOverview'}
                            checked={exportCustodiansOverview} onClick={toggleEnable} isDisabled={isDisabled}/>

                        <div className="display-input">
                            <ToggleSwitch label={t('legalHold:label.exportDatasetsOverview')} name={'exportDatasetsOverview'}
                                checked={exportDatasetsOverview} onClick={toggleEnable} isDisabled={isDisabled}/>
                        </div>

                        <div className="display-input">
                            <ToggleSwitch label={t('legalHold:label.exportNoticesOverview')} name={'exportNoticesOverview'}
                                checked={exportNoticesOverview} onClick={toggleEnable} isDisabled={isNoticeExportDisabled}/>
                        </div>

                        <div className="display-input">
                            <ToggleSwitch label={t('legalHold:label.exportNoticeResponses')} name={'exportNoticeResponses'}
                                checked={exportNoticeResponses} onClick={toggleEnable} isDisabled={isNoticeExportDisabled}/>
                        </div>

                        <ToggleSwitch label={t('legalHold:label.exportUserNoticeHtml')} name={'exportUserNoticeHtml'}
                            checked={exportUserNoticeHtml} onClick={toggleEnable} isDisabled={isNoticeExportDisabled}/>
                    </div>

                    <div className="display-input" style={{display: 'flex'}}>
                        <div className="display-input is-horizontal">
                            <Checkbox label={t('legalHold:label.includeIcons')} name={'includeIcons'}
                                checked={includeIcons} onClick={toggleEnable} isDisabled={isHtmlExportOptionsDisabled}/>
                        </div>

                        <div className="display-input is-horizontal">
                            <Checkbox label={t('legalHold:label.includeComments')} name={'includeComments'}
                                checked={includeComments} onClick={toggleEnable} isDisabled={isHtmlExportOptionsDisabled}/>
                        </div>

                        <div className="display-input is-horizontal">
                            <Checkbox label={t('legalHold:label.includeAdminNotes')} name={'includeAdminNotes'}
                                checked={includeAdminNotes} onClick={toggleEnable} isDisabled={isHtmlExportOptionsDisabled}/>
                        </div>

                        <div className="display-input is-horizontal">
                            <Checkbox label={t('legalHold:label.includeDatasetFiles')} name={'includeDatasetFiles'}
                                checked={includeDatasetFiles} onClick={toggleEnable} isDisabled={isHtmlExportOptionsDisabled}/>
                        </div>
                    </div>

                    <div className="display-input">
                        <SwitchItemContainer label={t('legalHold:label.notices')} items={notices} setItems={setNotices} style={{height: '14rem'}}
                            ariaLabelKey={'Notice'} ItemComponent={TypeSelectableItem}
                            isRequired={areNoticesRequired} isDisabled={isNoticeExportDisabled}/>
                    </div>
                </>
            }
            footer={
                <FormFooter addText={t('legalHold:option.export')} onAddClick={onSubmit} isAddEnabled={isSubmitEnabled}
                    onCancel={onClose} isDisabled={isDisabled}/>
            }
        />
    )
}

export default LegalHoldExportForm;
