import React from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {applicationFeatures} from "../../utilities/constants";
import {AddButtonHeader} from "../common/Button/Button";
import {getValues, isNotEmptyNorFalsy} from "../../utilities/helperFunctions";
import ExecutionProfileModel from "../../models/settings/ExecutionProfileModel";
import {StatusNameDescriptionCell, TableLabelCell, TableLabelHeader, TableRow} from "../common/CustomTable/CustomTable";
import {permissionKeys} from "../../i18next/keys";
import LimitedTableRow from "../limited/LimitedTableRow";

function ExecutionProfileTable() {
    const {t} = useTranslation(['aria', 'executionProfile', 'name']);
    const dispatch = useDispatch();

    const userFeatures = useSelector(state => state.currentUser.features);
    const executionProfiles = useSelector(state => state.executionProfileDetailsMap);
    const {executionProfileId: selectedId} = useSelector(state => state.componentStates.executionProfileDisplay);

    function showForm() {
        dispatch(ExecutionProfileModel.actionCreators.showForm());
    }

    const canAddExecutionProfile = userFeatures.includes(applicationFeatures.ADD_EXECUTION_PROFILE);
    const isNotEmpty = isNotEmptyNorFalsy(executionProfiles);
    if (!canAddExecutionProfile && !isNotEmpty) {
        return null;
    }

    return (
        <div className="display-item">
            <AddButtonHeader aria-label={t('aria:hiddenAssistText.addExecutionProfile')} text={t('executionProfile:label.name')}
                canModify={canAddExecutionProfile} onClick={showForm}
            />

            {isNotEmpty &&
                <div className="display-table" style={{tableLayout: 'fixed'}}>
                    <div className="table-header-group">
                        <TableLabelHeader label={t('common:label.name')}/>

                        <TableLabelHeader label={t('executionProfile:label.engineLogFolder')} style={{width: '20%'}}/>

                        <TableLabelHeader label={t('executionProfile:label.nuixEngineFolder')} style={{width: '20%'}}/>
                    </div>

                    <div className="table-row-group">
                        {getValues(executionProfiles).map(profile =>
                            <ExecutionProfileRow key={profile.id} executionProfile={profile}
                                isActive={profile.id === selectedId}/>
                        )}
                    </div>
                </div>
            }
        </div>
    )
}


function ExecutionProfileRow(props) {
    const dispatch = useDispatch();
    const {
        executionProfile,
        isActive
    } = props;

    const {
        name,
        description,
        engineLogFolder,
        nuixEngineFolder,
        status,
        userPermissions
    } = executionProfile;

    function showTablet(event) {
        dispatch(ExecutionProfileModel.actionCreators.showTablet(event.currentTarget.dataset.value));
    }

    const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);
    const canModify = userPermissions.includes(permissionKeys.MODIFY);
    if (isViewLimited && !canModify) {
        return (
            <LimitedTableRow id={executionProfile.id} isActive={isActive} onClick={showTablet} cellCount={3}
                nameComponent={<StatusNameDescriptionCell status={status} name={name} description={description}/>}
            />
        )
    }

    return (
        <TableRow value={executionProfile.id} isActive={isActive} onClick={showTablet}>
            <StatusNameDescriptionCell
                status={status} name={name} description={description}/>

            <TableLabelCell label={engineLogFolder} enableHighlightText={true}/>

            <TableLabelCell label={nuixEngineFolder} enableHighlightText={true}/>
        </TableRow>
    )
}

export default ExecutionProfileTable;
