import React, {useEffect, useRef} from "react";
import './MainPage.css';
import {buildClassName} from "../../../utilities/helperFunctions";
import {MenuDropdown} from "../../common/Dropdown/Dropdown";
import {useTranslation} from "react-i18next";

export function MainPageAsideHeader(props) {
    const {t} = useTranslation(['aria']);
    const {icon, text, ariaKey} = props;

    return (
        <>
            <span className="icon is-large spacing-right">
                <img src={icon} alt=""/>
            </span>
            <h1 className="subtitle is-5 is-bold">
                {text}
            </h1>
        </>
    )
}

export function MainPageContent(props) {
    const {
        id,
        'aria-labelledby': ariaLabelledBy,
        title,
        listHeader,
        list,
        children,
        isOverflow = true,
        isDisabled
    } = props;

    const ref = useRef();
    useEffect(() => {
        ref.current?.focus();
    }, [id]);

    const className = buildClassName(
        'selectPage-content',
        isOverflow && ' is-overflow',
        isDisabled && ' is-disabled'
    );

    return (
        <>
            <section id={id} className={className} role="tabpanel"
                aria-labelledby={ariaLabelledBy}
            >
                <div ref={ref} tabIndex={-1}/>

                <header className="display-header">
                    {title}
                    {listHeader}
                </header>
                <section className="display-list-container">
                    {list}
                </section>
            </section>

            {children}
        </>
    )
}

export function MainPageHeader(props) {
    const {text, description, canModify, menuDropdownProps} = props;

    return (
        <>
            <section className="display-title">
                <h2 className="subtitle is-5 is-bold">
                    {text}
                </h2>
                {canModify &&
                    <div className="display-menu">
                        <MenuDropdown {...menuDropdownProps} />
                    </div>
                }
            </section>

            {description &&
                <p className="display-item label">{description}</p>
            }
        </>
    )
}


