import ThirdPartyServiceModel from "../../../models/thirdparty/ThirdPartyServiceModel";
import ThirdPartyServiceForm, {ThirdPartyServiceFormBody} from "../ThirdPartyServiceForm";
import UserServiceModel from "../../../models/user/UserServiceModel";
import {useTranslation} from "react-i18next";
import React from "react";
import ExpandableContent from "../../common/ExpandableContent/ExpandableContent";
import Checkbox from "../../common/Checkbox/Checkbox";

const initialState = {
    type: ThirdPartyServiceModel.Type.PURVIEW,
    serviceName: '',
    enabled: true,
    description: '',
    whitelistedCertFingerprints: [],

    usePurviewDownload: true,

    authenticationScope: ThirdPartyServiceModel.AuthenticationScope.SERVICE,
    authenticationMethod: ThirdPartyServiceModel.AuthenticationMethod.OIDC_AUTHORIZATION_CODE,
    authenticationServiceId: null
};
const allowedAuthenticationMethods = [
    ThirdPartyServiceModel.AuthenticationMethod.OIDC_AUTHORIZATION_CODE
];

function PurviewServiceForm() {
    return (
        <ThirdPartyServiceForm initialState={initialState} FormBody={PurviewServiceFormBody}/>
    )
}

export function PurviewServiceFormBody(props) {
    return (
        <ThirdPartyServiceFormBody {...props} authenticationServiceType={UserServiceModel.types.MICROSOFT}
            allowedAuthenticationMethods={allowedAuthenticationMethods} FormBody={FormBody}/>
    )
}

function FormBody(props) {
    const {t} = useTranslation(['thirdPartyService', 'common']);
    const {
        thirdPartyService,

        toggleHandler,
        isDisabled
    } = props;

    return (
        <ExpandableContent label={t('thirdPartyService:label.purviewSettings')} isDisabled={isDisabled}>
            <div className="display-input">
                <Checkbox label={t('thirdPartyService:label.usePurviewDownload')} name={"usePurviewDownload"}
                    checked={thirdPartyService.usePurviewDownload} onClick={toggleHandler} isDisabled={isDisabled}/>
            </div>
        </ExpandableContent>
    )
}

export default PurviewServiceForm;
