import React, {useState} from 'react';
import '../ResourcePoolForm.css';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {createCloseHandler, createInputHandler, createStateHandler} from '../../../utilities/componentFunctions';
import {createToggleHandler} from "../../common/Checkbox/helpers";
import Checkbox from '../../common/Checkbox/Checkbox';
import TextArea from '../../common/TextArea/TextArea';
import {getEngineNameValues} from '../../../reselect/selectors';
import {SwitchItemContainer} from "../../common/ListContainer/ListContainer";
import Form, {FormFooter, FormHeader} from "../../common/Form/Form";
import {shouldEnableLocalResourcePool} from "../../../utilities/shouldEnableFunctions";
import {applicationFeatures, resourcePoolTypes} from "../../../utilities/constants";
import PopupModel from "../../../models/scheduler/PopupModel";
import ResourcePoolModel from "../../../models/settings/ResourcePoolModel";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import ExpandableContent from "../../common/ExpandableContent/ExpandableContent";

function LocalResourcePoolForm(props) {
    const {t} = useTranslation(['resourcePool', 'common']);

    const dispatch = useDispatch();
    const {isDisabled, ...duplicateValues} = useSelector(state => state.componentStates.resourcePoolForm);

    const [state, setState] = useState({
        localResourcePoolName: '',
        description: '',
        active: true,
        isAddEnabled: false,
        ...duplicateValues,
        type: resourcePoolTypes.LOCAL
    });
    // left -> available, right -> selected
    const [engines, setEngines] = useState(duplicateValues.engines || {
        left: [...useSelector(getEngineNameValues)],
        right: []
    });
    // left -> available, right -> selected
    const [remoteEngines, setRemoteEngines] = useState(duplicateValues.remoteEngines || {
        left: [...engines.left],
        right: []
    });

    const stateHandler = createStateHandler({
        updateState: setState,
        shouldEnable: shouldEnableLocalResourcePool
    });

    const inputHandler = createInputHandler({
        handler: stateHandler
    });
    const toggleHandler = createToggleHandler({
        handler: stateHandler
    });
    
    function onSubmit() {
        dispatch(ResourcePoolModel.actionCreators.submitForm({
            ...state,
            engines,
            remoteEngines
        }));
    }

    const onClose = createCloseHandler({
        t,
        item: t('resourcePool:label.name'),
        values: state,
        blacklist: ['engines'],
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        close: () => dispatch(ResourcePoolModel.actionCreators.hideForm(resourcePoolTypes.LOCAL))
    });
    
    const {
        localResourcePoolName,
        description,
        active,
        isAddEnabled
    } = state;

    const canViewRemoteEngines = useSelector(state => state.currentUser.features.includes(applicationFeatures.REMOTE_ENGINES));

    return (
        <Form onClose={onClose} isDisabled={isDisabled} closeButtonAriaLabel={t('resourcePool:option.closeLocalForm')}
            header={
                <FormHeader text={t('resourcePool:label.localName')} iconName={'localResourcePool'} isDisabled={isDisabled}/>
            }
            body={
                <>
                    <div className="display-input">
                        <HTMLTextInput label={t('common:label.name')} name={'localResourcePoolName'} value={localResourcePoolName}
                            onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                    </div>

                    <Checkbox label={t('resourcePool:label.active')} name={'active'} checked={active} onClick={toggleHandler}
                        isDisabled={isDisabled}/>

                    <div className="display-input">
                        <TextArea label={t('common:label.description')} name={'description'} value={description}
                            onChange={inputHandler} isDisabled={isDisabled}/>
                    </div>

                    <div className="display-input">
                        <ExpandableContent label={t('resourcePool:label.engines')} initialExpanded={true}
                                           headerBarStyle={{
                                               marginBottom: '0.25rem'
                                           }}
                        >
                            <SwitchItemContainer id="engineSwitchContainer" ariaLabelKey={'Engine'}
                                                 items={engines} setItems={setEngines} isDisabled={isDisabled}/>
                        </ExpandableContent>
                    </div>


                    {canViewRemoteEngines &&
                    <div className="display-input">
                        <ExpandableContent label={t('resourcePool:label.remoteEngines')} initialExpanded={remoteEngines.right.length > 0}
                            headerBarStyle={{
                                marginBottom: '0.25rem'
                            }}
                        >
                            <SwitchItemContainer id="remoteEngineSwitchContainer" items={remoteEngines} setItems={setRemoteEngines}
                                ariaLabelKey={'RemoteEngine'} isDisabled={isDisabled}/>
                        </ExpandableContent>
                    </div>
                    }
                </>
            }
            footer={
                <FormFooter addText={t('common:button.addResourcePool')} onAddClick={onSubmit} onCancel={onClose}
                    isAddEnabled={isAddEnabled} isDisabled={isDisabled}/>
            }
        />
    )
}

export default LocalResourcePoolForm;