import React, {useCallback, useEffect, useRef, useState} from "react";
import "./AddOperationForm.css";
import {useTranslation} from "react-i18next";
import {
    buildClassName,
    getEntries,
    getKeys,
    getTranslatedValueOrDefault,
    getValues,
    isNotEmptyNorFalsy,
    objectTruthyValues,
    textContainsEverySearchWord
} from "../../../utilities/helperFunctions";
import SearchBar from "../../common/SearchBar/SearchBar";
import {createInputHandler} from "../../../utilities/componentFunctions";
import {useFilterDropdownClearHandler, useFilterDropdownHandler} from "../../common/Dropdown/helpers";
import Text from "../../common/Text/Text";
import WorkflowBuilderModel from "../../../models/workflowbuilder/WorkflowBuilderModel";
import WorkflowBuilderOperation from "../../../models/workflowbuilder/WorkflowBuilderOperation";
import {operationIconModel} from "../../../models/generics/IconModel";
import {CheckedDropdown} from "../../common/Dropdown/Dropdown";
import {FormFooter} from "../../common/Form/Form";
import CustomModal from "../../common/CustomModal/CustomModal";
import {initialSelectedMapState, useKeyPressEffect, useValueSelectHandler} from "../../../utilities/hooks";

function AddOperationForm(props) {
    const {t} = useTranslation(['workflowBuilder', 'common']);

    const {
        updateState,
        operationFormConfigurations,
        addOperation,
        operationFilter,
        onClose,
        isDisabled
    } = props;

    const filteredOperationIndices = useRef(new Set());
    const [, forceUpdate] = useState();
    const [selected, setSelected] = useState(initialSelectedMapState);
    const valueSelectHandler = useValueSelectHandler({setSelected, useMap: true, filteredIndicesRef: filteredOperationIndices});

    const setOperationFilter = useCallback(function (updates) {
        updateState(prevState => {
            const update = typeof updates === 'function' ? updates(prevState.operationFilter) : updates;
            return {
                operationFilter: prevState.operationFilter.shallowDuplicate(update)
            }});
    }, []);

    const inputHandler = createInputHandler({
        handler: setOperationFilter
    });
    const onToggle = useFilterDropdownHandler({
        updateState: setOperationFilter
    });
    const onClear = useFilterDropdownClearHandler({
        updateState: setOperationFilter
    });

    const operationFormConfigurationEntries = getEntries(operationFormConfigurations);
    const selectedIndices = objectTruthyValues(selected.values);

    function onAddOperation() {
        // Get operation from configuration template
        const operations = [];
        for (const i of selectedIndices) {
            const formConfiguration = operationFormConfigurationEntries[i][1];
            operations.push(new WorkflowBuilderOperation(formConfiguration.template));
        }
        addOperation(operations, false, false);
        onClose();
    }

    const [formTags, setFormTags] = useState();
    const {searchText, ...operationTagFilters} = operationFilter;
    const operationTagFilterEntries = getEntries(operationTagFilters);
    const operationTagFilterSelected = isNotEmptyNorFalsy(operationTagFilters);

    // Filter operations
    useEffect(() => {
        filteredOperationIndices.current.clear();
        for (let i = 0; i < operationFormConfigurationEntries.length; i++) {
            const [operationAlias, formConfiguration] = operationFormConfigurationEntries[i];
            // Must match at least one value in all selected tagFilters
            if (operationTagFilterSelected) {
                let passesFilter = true;
                for (const [name, selectedTagValues] of operationTagFilterEntries) {
                    // Skip empty tagFilters
                    if (!isNotEmptyNorFalsy(selectedTagValues)) {
                        continue;
                    }
                    if (!Array.isArray(formConfiguration.tags[name]) || !formConfiguration.tags[name].some(tagValue => !!selectedTagValues[tagValue])) {
                        passesFilter = false;
                        break;
                    }
                }
                if (!passesFilter) {
                    continue;
                }
            }

            // Matches searchText
            const operationFormText = WorkflowBuilderModel.getOperationFormText(t, operationAlias);
            if (textContainsEverySearchWord(searchText, operationFormText)) {
                filteredOperationIndices.current.add(i);
            }
        }
        forceUpdate(v => !v);
    }, [operationFilter]);

    // Filter tagFilterDropdown values based on tagValue selections
    useEffect(() => {
        const filteredFormTags = {};
        for (const [name] of operationTagFilterEntries) {
            for (const [, formConfiguration] of operationFormConfigurationEntries) {
                let passesFilter = true;
                for (const [_name, _selectedTagValues] of operationTagFilterEntries) {
                    // Skip self
                    if (name === _name || !isNotEmptyNorFalsy(_selectedTagValues)) {
                        continue;
                    }
                    if (!Array.isArray(formConfiguration.tags[_name]) || !formConfiguration.tags[_name].some(tagValue => !!_selectedTagValues[tagValue])) {
                        passesFilter = false;
                        break;
                    }
                }
                const tagValues = formConfiguration.tags[name];
                if (passesFilter && Array.isArray(tagValues)) {
                    if (filteredFormTags[name] == null) {
                        filteredFormTags[name] = {};
                    }
                    tagValues.forEach(value => filteredFormTags[name][value] = operationFilter[name][value]);
                }
            }
        }
        setFormTags(filteredFormTags);
    }, getValues(operationTagFilters));

    const formTagsKeys = getKeys(formTags);
    const isAddEnabled = selectedIndices.length > 0;

    const title = t('workflowBuilder:label.addOperation');
    const addText = t(`workflowBuilder:option.addCount`, selectedIndices.length > 0 ? {count: selectedIndices.length} : {});

    return (
        <CustomModal id={'addOperationForm'} isActive onCancel={onClose}
            closeButtonAriaLabel={t('workflowBuilder:option.closeAddOperationForm')}
            aria-labelledby={'addOperationFormHeaderTitle'}
            header={
                <section className="workflow-builder__add-operation-header" id={'addOperationFormHeaderTitle'}>
                    <h2 className="subtitle is-bold">
                        {title}
                    </h2>
                </section>
            }
            body={
                <section className="workflow-builder__add-operation-body">
                    <section className="workflow-builder__sticky-header">
                        <div className="actions-row">
                            <div>
                                {formTagsKeys.map(name =>
                                    <div key={name} className="action-item">
                                        <OperationTagCheckedDropdown filterState={formTags[name]} name={name}
                                            onToggle={onToggle} onClear={onClear} isDisabled={isDisabled}/>
                                    </div>
                                )}
                            </div>

                            <SearchBar value={searchText} onChange={inputHandler} style={{flexGrow: 0.28}}
                                autoFocus isDisabled={isDisabled}/>
                        </div>
                    </section>

                    <table className="workflow-builder__add-operation-list tight-padding">
                        <thead className="table-header-group">
                        <tr>
                            <th className="table-header">
                                <Text value={t('common:label.name')} isBold/>
                            </th>
                            {formTagsKeys.map(name => {
                                const translationKey = `workflowBuilder:tag.${name}`;
                                const translation = getTranslatedValueOrDefault(t, name, translationKey);
                                return (
                                    <th key={name} className="table-header">
                                        <Text value={translation} isBold/>
                                    </th>
                                )
                            })}
                        </tr>
                        </thead>

                        <tbody className="table-row-group">
                        {operationFormConfigurationEntries.map(([operationAlias, formConfiguration], index) => {
                            if (!filteredOperationIndices.current.has(index)) {
                                return null;
                            }
                            return (
                                <OperationRow key={operationAlias} operationAlias={operationAlias} tagNames={formTagsKeys} index={index}
                                    isSelected={selected.values.get(index)} onClick={valueSelectHandler} formConfiguration={formConfiguration}/>
                            )
                        })}
                        </tbody>
                    </table>
                </section>
            }
            footer={
                <section className="workflow-builder__add-operation-footer">
                    <FormFooter addText={addText} onAddClick={onAddOperation} onCancel={onClose}
                        addButtonId={'addOperationButton'} cancelButtonId={'cancelAddOperationButton'}
                        isAddEnabled={isAddEnabled} isDisabled={isDisabled}
                    />
                </section>
            }
        />
    )
}

function OperationRow(props) {
    const {t} = useTranslation(['workflowBuilder']);
    const {
        operationAlias,
        formConfiguration,
        tagNames,

        onClick,
        index,
        isSelected
    } = props;

    const ref = useRef();
    const keyToCb = useRef({
        'Enter': 'click'
    });
    useKeyPressEffect({containerRef: ref, keyToCb: keyToCb.current});

    const operationIcon = operationIconModel.useIcon(operationAlias);
    const className = buildClassName(
        'table-row',
        isSelected && 'is-selected'
    );

    const operationName = t(`workflowBuilder:operationAlias.${operationAlias}`);

    return (
        <tr className={className} data-value={operationAlias} data-index={index}
            ref={ref} tabIndex={0} onClick={onClick}
        >
            <td className="table-cell workflow-builder__operation-cell">
                <div className="flex-center">
                    <span className="icon" style={{marginRight: '0.5rem'}}>
                        <img src={operationIcon} alt={operationName}/>
                    </span>
                    <Text value={operationName} isEllipsis/>
                </div>
            </td>
            {tagNames.map(name =>
                <td key={name} className="table-cell" style={{paddingBottom: 0}}>
                    {Array.isArray(formConfiguration.tags[name]) && formConfiguration.tags[name].map(value => {
                        const tag = `${name}.${value}`;
                        return (
                            <div key={tag} className="workflow-builder__tag">
                                <Text value={t(`workflowBuilder:${tag}`)} isEllipsis/>
                            </div>
                        )
                    })}
                </td>
            )}
        </tr>
    )
}

function OperationTagCheckedDropdown(props) {
    const {t} = useTranslation(['workflowBuilder', 'aria']);
    const {
        name,
        filterState,
        ...rest
    } = props;

    const noneSelectedMessageTranslationKey = `workflowBuilder:option.all${name}`;
    const noneSelectedMessage = getTranslatedValueOrDefault(t, t('workflowBuilder:option.all', {name}), noneSelectedMessageTranslationKey);

    const items = getEntries(filterState).map(([value, isChecked]) => {
        const translationKey = `workflowBuilder:${name}.${value}`;
        return {
            name: getTranslatedValueOrDefault(t, value, translationKey),
            value,
            isChecked: !!isChecked
        };
    }).sort((a, b) => a.name.localeCompare(b.name));

    return (
        <CheckedDropdown name={name} noneSelectedMessage={noneSelectedMessage}
            aria-label={t('aria:hiddenAssistText.operationTagChecked')} items={items} {...rest}/>
    )
}

export default AddOperationForm;