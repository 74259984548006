import React, {useRef} from "react";
import {buildClassName} from "../../../utilities/helperFunctions";
import './highlightTextInput.css'
import {workflowParameterHighlightRegex} from "../../../utilities/constants";
import {getInvalidMessageSpan} from "../InvalidMessageSpan";
import {useGetMissingClasses} from "../AccessibilityAssist";
import {Label} from "../Text/Text";

function HighlightHTMLTextInput(props) {
    const ref = useRef();
    const echoRef = useRef();
    const containerRef = useRef();

    const {
        label,
        value,
        onChange,
        invalidMessage,
        hideInvalidMessage,
        requiredMessage,
        'aria-labelledby': ariaLabelledBy,
        'aria-label': ariaLabel,

        isRequired,
        isInvalid,
        isDisabled,
        isInputDisabled,
        labelStyle,
        parameterNames,
        afterLabel,
        isSelected,
        ...attr
    } = props;


    const parts = typeof value === 'string' ? value.split(workflowParameterHighlightRegex) : [];
    const isEmpty = !value;

    function scroll () {
        const {paddingLeft} = window.getComputedStyle(ref.current);
        const distance = -1 * ((ref.current.scrollLeft || 0) - parseFloat(paddingLeft));
        echoRef.current.style.left = `${distance}px`;
    }

    const required = isRequired && isEmpty && !isDisabled;
    const invalid = isInvalid && !isDisabled;

    const id = attr.id || attr.name;

    let labelId;
    let labelComponent;

    if (label) {
        labelId = `${id}:label`;
        const labelElement = (
            <Label id={labelId} htmlFor={id} style={labelStyle}
                value={label} isDisabled={isDisabled}/>
        )

        if (afterLabel) {
            labelComponent = (
                <div className={"flex-center"}>
                    {labelElement}
                    {afterLabel}
                </div>
            );
        } else {
            labelComponent = labelElement;
        }
    }

    const {
        combinedAriaLabel,
        combinedAriaLabelledBy,
        invalidMessageSpan
    } = getInvalidMessageSpan({
        id,
        ariaLabel,
        ariaLabelledBy: [labelId, ariaLabelledBy],
        isInvalid: invalid,
        isRequired: required,
        invalidMessage,
        requiredMessage
    });

    const inputClassName = buildClassName(
        'highlight-input',
        'input',
        'is-small',
        required && 'is-required',
        invalid && 'is-invalid',
        isInputDisabled && 'is-input-disabled',
        isSelected && 'is-selected',
        useGetMissingClasses(label, {ariaLabel: combinedAriaLabel, ariaLabelledBy: combinedAriaLabelledBy})
    );


    return (
        <div className={"input-container"}>
            {labelComponent}

            <div className={'highlight-container'} tabIndex={0} ref={containerRef}>
                <input className={inputClassName} ref={ref} id={id} value={value} onChange={onChange}
                    onScroll={scroll} autoComplete={"off"} autoCorrect={"off"} spellCheck={false}
                    disabled={isDisabled || isInputDisabled} required={isRequired} aria-label={combinedAriaLabel} title={combinedAriaLabel}
                    aria-labelledby={combinedAriaLabelledBy} aria-invalid={required || invalid} {...attr}/>

                <div className="highlight-echo" aria-hidden={true} ref={echoRef}>
                    {parts.map((part, i) =>
                        part.match(workflowParameterHighlightRegex) ? <span key={i} className={'highlight-highlighted'}>{part}</span> : part
                    )}
                </div>
            </div>

            {!hideInvalidMessage && invalidMessageSpan}
        </div>
    )
}

export default HighlightHTMLTextInput;