import React, {useCallback, useEffect, useRef, useState} from "react";
import FileExplorer from "../common/PathExplorer/FileExplorer";
import {datasetType} from "../../utilities/constants";
import {DataRepositoryApi} from "../../models/data/DataRepositoryModel";
import SchedulerModel from "../../models/scheduler/SchedulerModel";
import {useDispatch, useSelector} from "react-redux";
import {useUpdateEffect} from "../../utilities/hooks";
import Text from "../common/Text/Text";
import {useTranslation} from "react-i18next";
import {axiosInstance} from "../../models/api/AxiosProxy";


function InPlaceFileExplorer(props) {
    const {t} = useTranslation(['dataset']);
    const dispatch = useDispatch();

    const {
        dataRepositoryId,
        allowedFileExtensions,
        path,
        setPath,

        height,
        includeFiles,
        isRequired,
        showPathLabel
    } = props;

    const dataRepository = useSelector(state => state.dataRepositoryDetailsMap.get(dataRepositoryId));
    const isDisabled = dataRepository == null;

    const [isLoadingFiles, setIsLoadingFiles] = useState(false);
    const [fileMap, setFileMap] = useState(new Map());

    const prevQueryPath = useRef();
    const activeQueries = useRef([]);

    const updateFile = useCallback((filePath, updates) => {
        setFileMap(prevFileMap => {
            const updatedFileMap = new Map(prevFileMap);
            const file = {...updatedFileMap.get(filePath), ...updates};
            return updatedFileMap.set(filePath, file);
        });
    }, []);

    const cancelActiveQueries = useCallback(async () => {
        const activePromises = [];
        for (const query of activeQueries.current) {
            query.aborted = true;
            axiosInstance.cancel(query.id);
            activePromises.push(query.activePromise);
        }
        await Promise.all(activePromises);
    }, []);

    const queryFileSize = useCallback(files => {
        const query = {};
        activeQueries.current.push(query);

        query.activePromise = new Promise(async (resolve) => {
            for (const file of files) {
                if (query.aborted) break;
                if (!file.path) continue;

                // Set loading and query file size
                updateFile(file.path, {isLoadingSize: true});
                let size;
                try {
                    const request = DataRepositoryApi.getFileSize(dataRepository.id, file.path);
                    query.id = request.id;
                    const response = await request.promise;
                    const resultJson = response.data;

                    if (resultJson.size == null && resultJson.key != null) {
                        throw {response: {data: resultJson}};
                    }

                    if (resultJson.error) {
                        size = NaN;
                    } else {
                        size = resultJson.size;
                    }
                } catch (error) {
                    size = NaN;
                    if (!query.aborted) {
                        dispatch(SchedulerModel.actionCreators.handleResponseError(error));
                    }
                } finally {
                    if (!query.aborted) {
                        updateFile(file.path, {size, isLoadingSize: false});
                    }
                }
            }
            activeQueries.current = activeQueries.current.filter(_query => _query !== query);
            resolve();
        });
    }, [dataRepository]);

    // Query for In-Place DataRepo files
    async function queryAndUpdateFiles(path, {force}={}) {
        if (dataRepository == null || dataRepository.type !== datasetType.IN_PLACE) {
            return;
        }
        // Don't re-query same path
        if (!force && path === prevQueryPath.current) {
            return;
        }

        setIsLoadingFiles(true);
        // Cancel all active sizeQueries and wait before setting new files and querying sizes
        await cancelActiveQueries();
        const query = {};
        activeQueries.current.push(query);

        return query.activePromise = new Promise(async (resolve, reject) => {
            try {
                const request = DataRepositoryApi.getFiles(dataRepository.id, path, {includeFiles, allowedFileExtensions});
                query.id = request.id;
                const response = await request.promise;
                const resultJson = response.data;

                if (!Array.isArray(resultJson) && resultJson.key != null) {
                    throw {response: {data: resultJson}};
                }

                setFileMap(prevFileMap => {
                    const updatedFileMap = new Map(prevFileMap);
                    for (const file of resultJson) {
                        if (file.aggregate && file.path == null) {
                            file.path = `${path}\\aggregate`;
                        }
                        const oldFile = updatedFileMap.get(file.path);
                        if (oldFile != null) {
                            file.size = oldFile.size;
                        }
                        updatedFileMap.set(file.path, file);
                    }
                    return updatedFileMap;
                });
            } catch (error) {
                if (!query.aborted) {
                    dispatch(SchedulerModel.actionCreators.handleResponseError(error));
                    reject(error);
                }
            } finally {
                prevQueryPath.current = path;
                activeQueries.current = activeQueries.current.filter(_query => _query !== query);
                if (!query.aborted) {
                    setIsLoadingFiles(false);
                }
                resolve();
            }
        });
    }

    useEffect(() => {
        return () => {
            cancelActiveQueries();
        }
    }, [dataRepository]);

    // Reset path + files if dataRepo changes
    useUpdateEffect(() => {
        setPath(null);
        setFileMap(new Map());
    }, [dataRepositoryId]);


    let rootPath = '';
    if (dataRepository != null) {
        rootPath = dataRepository.path;
    }

    return (
        <div className="flex-expand" style={{minHeight: '0'}}>
            {showPathLabel &&
                <div style={{display: 'flex', marginBottom: '0.75rem'}}>
                    <Text value={`${t('dataset:label.path')}:`} style={{marginRight: '1rem'}}
                        isDisabled={isDisabled}/>
                    <Text value={path} isDisabled={isDisabled}/>
                </div>
            }

            <FileExplorer id="inPlaceDataRepositoryFileExplorer" rootPath={rootPath} fileMap={fileMap}
                path={path} setPath={setPath} height={height} queryPath={queryAndUpdateFiles} queryFileSize={queryFileSize}
                isLoading={isLoadingFiles} isRequired={isRequired} isDisabled={isDisabled}/>
        </div>
    )
}

export default InPlaceFileExplorer;
