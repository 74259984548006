import React, {useCallback, useRef, useState} from "react";
import ReactDataSheet, {Cell} from "react-datasheet";
import {
    buildClassName,
    bytesCountToReadableCount,
    getEntries,
    getFileExtension,
    getKeys,
    getLocaleDateTimeFromUTC,
    getNewArrayWithUpdatedValue,
    getValues,
    isNotEmptyNorFalsy,
    objectTruthyValues,
    objEquals
} from "../../utilities/helperFunctions";
import {
    getRenderedHeightOffset,
    initialSelectedState,
    useClearSelectedEffect,
    useRenderedItemHeight,
    useSimpleVirtualRendering,
    useValueSelectHandler
} from "../../utilities/hooks";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {makeGetEditDetails} from "../../reselect/selectors";
import {Button} from "../common/Button/Button";
import {icon} from "../../utilities/iconResolver";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FileInfoModel, {FileInfoSaga} from "../../models/data/FileInfoModel";
import PopupModel from "../../models/scheduler/PopupModel";
import {popupInfoKeys} from "../../i18next/keys";
import DatasetModel from "../../models/data/DatasetModel";
import {createEditHandler, createFileHandler} from "../../utilities/componentFunctions";
import EditModel from "../../models/scheduler/EditModel";
import {DefaultEditPanel} from "../common/EditPanel/EditPanel";
import Switch from "../common/Switch/Switch";
import {TableLabelCell, TableLabelHeader} from "../common/CustomTable/CustomTable";
import {builtInDatasetMetadataHeaders, datasetType, userSettings} from "../../utilities/constants";
import Text from "../common/Text/Text";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import {fileIconModel} from "../../models/generics/IconModel";
import {ParameterObjectValuesDropdown} from "../parameters/ParameterInput";
import {StatusLabel} from "../common/Common";


function FileInfoTable(props) {
    const {t} = useTranslation(['dataset', 'datasetOption', 'common']);
    const dispatch = useDispatch();

    const {
        datasetId,
        onFileInfosDelete,
        canModify,
        label,
        isDisabled
    } = props;

    // useSelector requires the function to be the same in order to return the same values
    const fileInfoSelector = useCallback(state => selectFileInfos(state, {t, datasetId}), [t, datasetId]);
    const {
        fileInfoRows,
        editRows,
        isEditActive,
        isSaveEnabled
    } = useSelector(fileInfoSelector, objEquals);

    const {
        saveEdit,
        cancelEdit,
        updateEdit
    } = EditModel.buildDispatchers(dispatch);

    const editHandler = useCallback(createEditHandler({
        updateEdit,
        // All headers are defined
        shouldEnable: ({editRows}) => editRows[0].slice(1).every(cell => !!cell.value)
    }), []);


    let builtInHeadersLength = 0;
    if (Array.isArray(editRows)) {
        builtInHeadersLength = fileInfoRows[0].length - editRows[0].length;
    }

    const onCellsChanged = useCallback(changes => {
        editHandler(editValues => {
            let newEditRows = [...editValues.editRows];

            for (const change of changes) {
                const {col, row, value} = change;
                const effectiveCol = col - builtInHeadersLength;

                const fileRow = newEditRows[row];
                const cell = fileRow[effectiveCol];

                const newCell = {
                    ...cell,
                    value
                };
                newCell.invalid = FileInfoSaga.evaluateCellInvalid(newCell)

                // If header
                if (row === 0) {
                    // Set invalid for duplicate OR if builtInHeader
                    newCell.invalid = newEditRows[0].some(cell => cell.value === value) || builtInDatasetMetadataHeaders.includes(value.toUpperCase());
                }

                const newFileInfoRow = getNewArrayWithUpdatedValue(fileRow, newCell, effectiveCol);
                newEditRows = getNewArrayWithUpdatedValue(newEditRows, newFileInfoRow, row);
            }

            return {editRows: newEditRows};
        });
    }, [builtInHeadersLength, editHandler]);


    const addColumn = useCallback(() => {
        // Add an empty value to end of each row
        editHandler(editValues => {
            const newEditRows = editValues.editRows.map(row => [...row, {value: ''}]);

            return {editRows: newEditRows}
        });
    }, [editHandler]);

    const deleteColumn = useCallback(event => {
        const {col} = event.currentTarget.dataset;
        const effectiveCol = col - builtInHeadersLength;

        dispatch(DatasetModel.actionCreators.deleteMetadataColumn(effectiveCol, editHandler));
    }, [builtInHeadersLength, editHandler, dispatch]);


    const cellRenderer = useCallback(props => {
        const {row, col, editing, cell: {readOnly, value, invalid, regexAllowedValues}} = props;

        if (row === 0 && !editing && !readOnly) {
            const headerClassName = buildClassName(
                props.className,
                'header-cell',
                (!value || invalid) && 'is-required'
            );

            return (
                <Cell {...props} className={headerClassName}>
                    <Button isImg isClear data-col={props.col} onClick={deleteColumn}>
                        <FontAwesomeIcon icon="times"/>
                    </Button>
                    {props.children}
                </Cell>
            )
        }

        if (Array.isArray(regexAllowedValues) && regexAllowedValues.length > 0) {
            const className = buildClassName(
                props.className,
                invalid && 'is-required',
                'dropdown-cell'
            );

            return (
                <Cell {...props} className={className}>
                    <ParameterObjectValuesDropdown value={value} values={regexAllowedValues}
                        onValueChange={e => onCellsChanged([{row, col, value: e.target.value}])}/>
                </Cell>
            )
        }

        const className = buildClassName(
            props.className,
            invalid && 'is-required'
        );

        return (
            <Cell {...props} className={className}/>
        )
    }, [deleteColumn]);


    return (
        <>
            <Switch>

                {isEditActive &&
                <DefaultEditPanel isActive={isEditActive} isSaveEnabled={isSaveEnabled} onSave={saveEdit} onCancel={cancelEdit}>

                    <label className="label" style={{marginBottom: 'calc(-1rem - 0.48rem + 0.75em)', marginTop: '0.2em'}}>
                        {label || t('dataset:label.files')}
                    </label>

                    <div style={{display: 'flex'}}>
                        <div id="reactDataSheet" style={{overflowX: 'auto'}}>
                            <ReactDataSheet
                                data={fileInfoRows}
                                valueRenderer={cell => cell.value}
                                rowRenderer={rowRenderer}
                                cellRenderer={cellRenderer}
                                attributesRenderer={attributesRenderer}
                                onCellsChanged={onCellsChanged}
                            />
                        </div>

                        <div id="addColumnButton" className="add-column-bar">
                            <Button isImg isClear onClick={addColumn}
                                style={{height: '100%'}}
                            >
                                <FontAwesomeIcon icon="plus"/>
                            </Button>
                        </div>
                    </div>
                </DefaultEditPanel>
                }

                <NonEditTable datasetId={datasetId} label={label} fileInfoRows={fileInfoRows}
                    onFileInfosDelete={onFileInfosDelete} canModify={canModify} isDisabled={isDisabled}
                    onShowUpload={() => dispatch(DatasetModel.componentActionCreators.updateView({isUploadActive: true}))}
                />
            </Switch>
        </>
    )
}

export function NonEditTable(props) {
    const {t} = useTranslation(['dataset', 'datasetOption', 'common']);
    const dispatch = useDispatch();

    const {
        datasetId,
        fileInfoRows,
        onFileInfosDelete,

        onShowUpload,
        canModify,
        canModifyMetadata=props.canModify,

        label,
        isEditActive,
        inputHandler,

        isRequired,
        isDisabled
    } = props;

    const containerRef = useRef();
    const [selected, setSelected] = useState(initialSelectedState);

    const isRelativityApplication = useSelector(state => state.schedulerDetails.isRelativityApplication);
    const dataset = useSelector(state => state.datasetDetailsMap.get(datasetId));

    const selectHandler = useValueSelectHandler({setSelected});
    useClearSelectedEffect({
        containerRef,
        setSelected,
        dataStructure: fileInfoRows,
        hidePopup: () => dispatch(PopupModel.actionCreators.hide())
    });

    const listContainerSelector = '.table-row-group';
    const itemHeightRef = useRenderedItemHeight(containerRef, listContainerSelector, 38.6094);
    const heightOffset = getRenderedHeightOffset(containerRef, listContainerSelector, 32.3906 + itemHeightRef.current);
    const virtualRenderRows = useSimpleVirtualRendering({
        containerRef,
        itemHeightRef,
        size: fileInfoRows.length - 1,
        heightOffset
    });

    const hasFiles = Array.isArray(fileInfoRows) && fileInfoRows.length > 1;
    const canRemove = (canModify && hasFiles && objectTruthyValues(selected.values).length > 0);


    function onStartEdit() {
        dispatch(FileInfoModel.actionCreators.startEdit(datasetId));
    }

    function onUploadMetadataCsv() {
        const input = document.getElementById("filesMetadataCsvUploadInput");
        input.click();
    }

    const uploadFilesMetadataCsv = createFileHandler({
        readAs: 'readAsText',
        onloadend: function (event) {
            const csvText = event.target.result;
            dispatch(DatasetModel.actionCreators.uploadFilesMetadataCsv(datasetId, csvText));
        }
    });

    function onExportMetadataCsv() {
        dispatch(DatasetModel.actionCreators.downloadFilesMetadataCsv(datasetId, false));
    }

    function onRemoveClick() {
        let totalBytes = 0;
        let totalFileCount = 0;
        const fileIds = [];

        const selectedIndices = objectTruthyValues(selected.values);
        for (const index of selectedIndices) {
            // fileInfo is stored in first index
            const {id, size, fileCount} = fileInfoRows[index][0];

            fileIds.push(id);
            totalBytes += size;
            totalFileCount += fileCount;
        }

        dispatch(PopupModel.actionCreators.show({
            info: {
                key: popupInfoKeys.DELETE_DATASET_FILES,
                values: {
                    count: totalFileCount,
                    size: bytesCountToReadableCount(totalBytes)
                }
            },
            buttons: [{
                titleKey: 'common:option.delete',
                onClick: () => onFileInfosDelete(fileIds)
            }]
        }));
    }


    const isManagedType = dataset == null || dataset.type === datasetType.MANAGED;
    const filesTableClassName = buildClassName(
        'files-table',
        isDisabled && 'is-disabled'
    );

    const required = (isRequired && !isNotEmptyNorFalsy(fileInfoRows)) ? ' is-required' : '';
    return (
        <div id="fileInfoTable" ref={containerRef} className={required}>

            {isRelativityApplication &&
                <StatusLabel iconName={'statusInfo'} message={t('dataset:message.uploadDisabledInRelativityMode')}
                    style={{margin: '0.25rem 0'}}/>
            }

            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                {isEditActive &&
                <HTMLTextInput name={'label'} value={label} onChange={inputHandler} style={{marginBottom: '0.25rem', marginRight: '0.25rem'}}
                    placeholder={t('formBuilder:label.label_optional')} isDisabled={isDisabled}/>
                }

                {!isEditActive &&
                <div className="add-remove-buttons">
                    {isManagedType && canModify &&
                    <>
                        {!isRelativityApplication &&
                            <Button id="datasetUploadFilesButton" isImg onClick={onShowUpload} isDisabled={isDisabled}
                                aria-label={t('dataset:message.uploadFiles')}
                            >
                                <span className="icon is-small">
                                    <img src={icon('upload')} alt={t(`aria:hiddenAssistText.uploadFilesIcon`)}/>
                                </span>
                            </Button>
                        }

                        <Button id="datasetDeleteFilesButton" isImg onClick={onRemoveClick}
                            disabled={isDisabled || !canRemove}
                            aria-label={t('dataset:message.deleteFiles')}
                        >
                            <FontAwesomeIcon icon="minus"/>
                        </Button>
                    </>
                    }

                    <Text value={label != null ? label : t('dataset:label.files')} isReadOnly={!canModify} isDisabled={isDisabled}/>
                </div>
                }

                <div className="add-remove-buttons">
                    <Button id="datasetMetadataDownloadButton" isImg onClick={onExportMetadataCsv}
                        aria-label={t('dataset:message.exportMetadataCsv')}
                        isDisabled={isDisabled || !hasFiles}
                    >
                        <span className="icon is-small">
                            <img src={icon('download')} alt={t('aria:hiddenAssistText.downloadMetadataIcon')}/>
                        </span>
                    </Button>

                    {canModifyMetadata &&
                    <>
                        <Button id="datasetMetadataEditButton" isImg onClick={onStartEdit}
                            isDisabled={isDisabled || !hasFiles}
                            aria-label={t('dataset:message.editMetadataCsv')}>
                            <span className="icon is-small">
                                <img src={icon('metadataEdit')} alt={t('aria:hiddenAssistText.editMetadataIcon')}/>
                            </span>
                        </Button>

                        <Button id="datasetMetadataUploadButton" isImg onClick={onUploadMetadataCsv}
                            aria-label={t('dataset:message.uploadMetadataCsv')}
                            isDisabled={isDisabled || !hasFiles}
                        >
                            <span className="icon is-small">
                                <img src={icon('metadataAdd')} alt={t('aria:hiddenAssistText.uploadMetadataIcon')}/>
                            </span>
                        </Button>
                    </>
                    }

                    <input style={{display: "none"}} id="filesMetadataCsvUploadInput" type="file" accept=".csv"
                        onChange={uploadFilesMetadataCsv} disabled={isDisabled}/>
                </div>
            </div>

            <div className={filesTableClassName}>
                <div className="table-header-group">
                    {fileInfoRows[0].slice(1).map((header, index) =>
                        <TableLabelHeader key={`${index}-${header.value}`}
                            label={header.value} {...attributesRenderer(header)}/>
                    )}
                </div>

                <div className="table-row-group">
                    {virtualRenderRows((index, topOffset) => {
                        const rowIndex = index + 1;
                        const row = fileInfoRows[rowIndex];

                        if (row == null) {
                            return null;
                        }

                        return (
                            <FileInfoRow key={rowIndex} data-index={rowIndex} row={row} style={{position: 'relative', top: `${topOffset}px`}}
                                selectHandler={selectHandler} canModify={canModify} isManagedType={isManagedType}
                                isSelected={selected.values[rowIndex]} isDisabled={isDisabled}/>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

function FileInfoRow(props) {
    const {t} = useTranslation(['aria']);
    const {
        row,

        canModify,
        isManagedType,
        selectHandler,

        isSelected,
        isDisabled,

        ...attr
    } = props;

    const fileInfo = row[0];
    const nameCell = row[1];
    const otherCells = row.slice(2);

    const rowClassName = buildClassName(
        'table-row',
        canModify && isSelected && 'is-active',
        (!canModify || !isManagedType) && 'no-pointer'
    )

    const fileExtension = fileInfo.directory ? 'directory' : getFileExtension(nameCell.value, 'empty');
    const fileIcon = fileIconModel.useIcon(fileExtension);

    return (
        <div className={rowClassName} tabIndex={isDisabled ? -1 : 0} {...attr}
             onClick={(!canModify || !isManagedType || isDisabled) ? null : selectHandler}>

            <div className="table-cell" style={nameCell.style}>
                <div className="location-node">
                    <span className="icon is-small">
                        <img src={fileIcon} alt={fileExtension}/>
                    </span>
                    {nameCell.value != null &&
                        <Text value={nameCell.value} isEllipsis title={nameCell.title}/>
                    }
                </div>
            </div>

            {otherCells.map((cell, cindex) =>
                <TableLabelCell key={cindex} label={cell.value} {...attributesRenderer(cell)}/>
            )}
        </div>
    )
}


const selectEditDetails = makeGetEditDetails();
function selectFileInfos(state, props) {

    const {activeModel, values, isSaveEnabled} = selectEditDetails(state, {model: FileInfoModel.nom});
    const isEditActive = (activeModel === FileInfoModel.nom);

    let fileInfoRows = (isEditActive ? values.editRows : state.fileInfoRowsMap.get(props.datasetId)) || [];
    fileInfoRows = selectFileInfoRows(state, {...props, fileInfoRows, isEditActive});

    return {
        fileInfoRows,
        editRows: values.editRows,
        isEditActive,
        isSaveEnabled
    };
}

export function selectFileInfoRows(state, props) {
    const fileInfoRows = [];
    // Get list of builtInMetadataHeaders
    const builtInHeaders = getEntries(state.userSettingsMap.get(userSettings.DATASET_OPTION).builtInHeaders)
        .filter(([ignore, enabled]) => enabled)
        // If isEditActive, only show name
        .filter(([builtInHeader]) => !props.isEditActive || builtInHeader === 'name')
        .map(([builtInHeader]) => builtInHeader);

    const headerRow = [{}];
    for (const header of builtInHeaders) {

        const cell = {readOnly: true};
        switch (header) {
            case 'size':
            case 'fileCount':
                cell.style = {textAlign: 'end', width: '10rem'};
                break;
            case 'hashes':
                const firstFileInfo = (props.fileInfoRows[1] || [])[0] || {};
                if (firstFileInfo.hashes != null) {

                    for (const hashKey of getKeys(firstFileInfo.hashes)) {
                        headerRow.push({
                            value: props.t('datasetOption:label.hash', {hash: hashKey}),
                            readOnly: true
                        });
                    }
                    continue;
                }
                break;
            default:
                break;
        }

        cell.value = props.t(`datasetOption:label.${header}`);
        headerRow.push(cell);
    }

    headerRow.push(...(props.fileInfoRows[0] || []).slice(1));
    fileInfoRows.push(headerRow);


    // Adding row cells
    for (const row of props.fileInfoRows.slice(1)) {
        const fileInfo = row[0];
        const fileRow = [fileInfo];

        for (const header of builtInHeaders) {
            const cell = {value: fileInfo[header], readOnly: true};

            switch (header) {
                case 'name':
                    cell.title = fileInfo.name;
                    break;
                case 'size':
                    cell.value = bytesCountToReadableCount(fileInfo.size);
                    cell.style = {textAlign: 'end'};
                    break;
                case 'fileCount':
                    cell.value = fileInfo.directory ? fileInfo.fileCount : '';
                    cell.style = {textAlign: 'end'};
                    break;
                case 'addedDate':
                    cell.value = getLocaleDateTimeFromUTC(fileInfo.addedDate);
                    break;
                case 'hashes':
                    if (fileInfo.hashes != null) {

                        for (const hash of getValues(fileInfo.hashes)) {
                            fileRow.push({
                                value: hash,
                                readOnly: true
                            });
                        }
                        continue;
                    }
                    break;
                default:
                    break;
            }

            fileRow.push(cell);
        }

        fileRow.push(...row.slice(1));
        fileInfoRows.push(fileRow);
    }

    return fileInfoRows;
}

function rowRenderer(props) {
    return (
        <tr>
            {props.children.slice(1)}
        </tr>
    )
}

function attributesRenderer(cell) {
    return {
        style: cell.style,
        title: cell.title
    };
}

export default FileInfoTable;