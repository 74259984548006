import React, {useEffect, useRef, useState} from "react";
import "./DatasetView.css";
import {useTranslation} from "react-i18next";
import {buildClassName, getValues, isNotEmptyNorFalsy} from "../../utilities/helperFunctions";
import DatasetDisplay from "./DatasetDisplay";
import {useDispatch, useSelector} from "react-redux";
import {datasetStateKeys} from "../../i18next/keys";
import DatasetForm from "./DatasetForm";
import DatasetModel from "../../models/data/DatasetModel";
import {useAutoSelectId, useKeyPressEffect} from "../../utilities/hooks";
import DataRepositoryModel from "../../models/data/DataRepositoryModel";
import EditModel from "../../models/scheduler/EditModel";
import FileInfoModel from "../../models/data/FileInfoModel";
import {AddButtonHeader} from "../common/Button/Button";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {details, MIN_SIZE_FOR_SEARCH} from "../../utilities/constants";
import SearchBar from "../common/SearchBar/SearchBar";

function getDatasetText(t, dataset) {
    return [
        dataset.name, dataset.path, dataset.description,
        t(`dataset:type.${dataset.type}`), t(`dataset:state.${dataset.state}`)
    ].join().toUpperCase();
}

function selectMatterDatasetIds (state, {t, matterId, searchText}) {
    const matterDatasets = getValues(state.datasetDetailsMap)
        .filter(dataset => dataset.matterId === matterId)

    const datasetIds = matterDatasets
        .filter(dataset => !searchText || getDatasetText(t, dataset).includes(searchText.toUpperCase()))
        .map(dataset => dataset.id);

    return [datasetIds, matterDatasets.length > MIN_SIZE_FOR_SEARCH];
}

function DatasetView(props) {
    const {t} = useTranslation(['aria', 'dataset', 'common']);
    const dispatch = useDispatch();

    const {
        matterId,
        canModify,
        isDisabled
    } = props;

    const {
        datasetId,
        isDatasetFormActive,
        isUploadActive
    } = useSelector(state => state.componentStates.datasetView);

    const [searchText, setSearchText] = useState('');

    const [datasetIds, showSearchBar] = useSelector(state => selectMatterDatasetIds(state, {t, matterId, searchText}));
    const selectedDataset = useSelector(state => state.datasetDetailsMap.get(datasetId));

    const isFileInfoEditActive = useSelector(state => state.editDetails.activeModel === FileInfoModel.nom);
    const isLoading = useSelector(state => !state.hasLoaded[details.DATA_REPOSITORIES]);

    // Auto-select datasetId if not selected OR when previous deleted
    useAutoSelectId(datasetId, datasetIds,
        id => dispatch(DatasetModel.actionCreators.showDisplay(id))
    );

    // Start and stop polling collections/dataRepositories
    useEffect(() => {
        dispatch(DataRepositoryModel.actionCreators.startPollingDetails());
        return () => {
            dispatch(DataRepositoryModel.actionCreators.stopPollingDetails());
        }
    }, [dispatch]);

    // Start and stop polling fileInfos and uploadInfos
    useEffect(() => {
        if (isUploadActive) {
            dispatch(DatasetModel.actionCreators.stopPollingSettings());
        } else if (datasetId) {
            dispatch(DatasetModel.actionCreators.startPollingSettings(datasetId));
        }

        return () => dispatch(DatasetModel.actionCreators.stopPollingSettings());
    }, [datasetId, isUploadActive, dispatch]);


    function onDatasetSelect(event) {
        const {value} = event.currentTarget.dataset;

        dispatch(DatasetModel.actionCreators.showDisplay(value));
    }

    function showAddDataset() {
        dispatch(EditModel.actionCreators.callEditBreakingFunc(function () {

            dispatch(DatasetModel.actionCreators.showForm());
        }));
    }


    const datasetPanelsClassName = buildClassName(
        'tablet-view__panels',
        (datasetIds.length === 0 || selectedDataset == null) && 'no-border'
    );

    const areActionsDisabled = isDisabled || isUploadActive || isFileInfoEditActive;
    const isNotEmpty = isNotEmptyNorFalsy(datasetIds);
    if (!canModify && !isNotEmpty) {
        return null;
    }

    return (
        <LoadingWrapper isLoading={isLoading}>
            <section className={"tablet-view" + (isDisabled ? ' is-disabled' : '')}>
                <aside className={datasetPanelsClassName}>
                    <section className="tablet-view__panels__add">
                        <AddButtonHeader aria-label={t('aria:hiddenAssistText.addDataset')} text={t('dataset:label.name')}
                            onClick={showAddDataset} canModify={canModify} isDisabled={areActionsDisabled}
                        />

                        {showSearchBar &&
                            <SearchBar value={searchText} onChange={e => setSearchText(e.target.value)} style={{marginRight: '1rem', marginTop: '0.75rem'}}
                                isDisabled={areActionsDisabled}/>
                        }
                    </section>

                    {isNotEmpty &&
                    <section className="tablet-view__list">
                        {datasetIds.map(id =>
                            <DatasetPanel key={id} datasetId={id} isActive={id === datasetId} onClick={onDatasetSelect}
                                isDisabled={areActionsDisabled}
                            />
                        )}
                    </section>
                    }
                </aside>

                {selectedDataset != null &&
                <DatasetDisplay datasetId={datasetId} canModify={canModify}
                    isDisabled={isDisabled || isFileInfoEditActive}
                />
                }
            </section>

            {(canModify && isDatasetFormActive) &&
            <DatasetForm matterId={matterId}/>
            }
        </LoadingWrapper>
    )
}

function DatasetPanel(props) {

    const {
        datasetId,
        onClick,
        isActive,
        isDisabled
    } = props

    const {
        name,
        number,
        state
    } = useSelector(state => state.datasetDetailsMap.get(datasetId));

    const containerRef = useRef();
    const keyToCb = useRef({
        'Enter': 'click'
    });
    useKeyPressEffect({containerRef, keyToCb: keyToCb.current});

    const className = buildClassName(
        'tablet-view__panel',
        'selectPanelSimple',
        isActive && 'is-active',
        isDisabled && 'is-disabled'
    );

    const labelClassName = buildClassName(
        'label',
        'is-ellipsis',
        (state === datasetStateKeys.DRAFT) && 'is-italic',
        isDisabled && 'is-disabled'
    );

    const num = (number != null) ? `${number}.` : '';

    return (
        <article className={className} data-value={datasetId} style={{display: 'flex'}}
            ref={containerRef} onClick={isDisabled ? null : onClick} tabIndex={isDisabled ? -1 : 0}
        >
            <span className={labelClassName} style={{textAlign: 'start', width: '2.5rem', flexShrink: 0}}>
                {num}
            </span>

            <span className={labelClassName}>
                {name}
            </span>
        </article>
    )
}

export default DatasetView;
