import React from "react";
import {useTranslation} from "react-i18next";
import {buildOperationFieldParameterNames} from "../../guidedJob/operationFieldParameterNames";
import WorkflowBuilderOperation from "../../../models/workflowbuilder/WorkflowBuilderOperation";
import VaultOperationPane from "./VaultOperatonPane";
import {getDataNameFromParentNode, getKeys} from "../../../utilities/helperFunctions";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import {FormElementRow} from "../../common/CustomTable/CustomTable";
import Checkbox from "../../common/Checkbox/Checkbox";
import UtcDateTimeInput from "../../common/HTMLTextInput/UtcDateTimeInput";
import {vaultQueryDataScopes} from "../../../utilities/constants";
import {ListDropdown} from "../../common/Dropdown/Dropdown";

const fieldNames = ['queryNamePrefix', 'queryDataScope', 'useDateRange', 'timezone', 'startDate', 'endDate',
    'useQueryFiles', 'queryLocations', 'queryTerms'];
export const vaultCreateSavedQueriesParameterNames = buildOperationFieldParameterNames(WorkflowBuilderOperation.Alias.VAULT_CREATE_SAVED_QUERIES, fieldNames);

function getNextEnabled(getParameter) {
    const queryNamePrefix = getParameter(vaultCreateSavedQueriesParameterNames.queryNamePrefix);
    return {
        createQueryOptions: queryNamePrefix.value
    };
}

export function getVaultSavedQueryOptionsGuide(props) {
    const {
        workflowActions,
        getParameter,
        updateParameter
    } = props;

    const queryNamePrefix = getParameter(vaultCreateSavedQueriesParameterNames.queryNamePrefix);
    const queryDataScope = getParameter(vaultCreateSavedQueriesParameterNames.queryDataScope);

    updateParameter(vaultCreateSavedQueriesParameterNames.timezone, 'UTC');
    if (!queryNamePrefix.value) {
        updateParameter(vaultCreateSavedQueriesParameterNames.queryNamePrefix, '');
    }
    if (vaultQueryDataScopes[queryDataScope.value] == null) {
        updateParameter(vaultCreateSavedQueriesParameterNames.queryDataScope, vaultQueryDataScopes.ALL_DATA);
    }

    return {
        getPanes: function(t, props) {
            const nextEnabled = getNextEnabled(props.getParameter);
            return [{
                title: t('guidedJob:panes.queryOptions'),
                isNextEnabled: nextEnabled.createQueryOptions,
                component: <VaultOperationPane {...props}
                    workflowActions={workflowActions} OperationPane={VaultSavedQueryOptionsPane}/>
            }];
        }
    }
}

function VaultSavedQueryOptionsPane(props) {
    const {t} = useTranslation(['guidedJob', 'common']);
    const {
        getParameter,
        updateParameter,
        isDisabled
    } = props;

    const queryNamePrefix = getParameter(vaultCreateSavedQueriesParameterNames.queryNamePrefix);
    const queryDataScope = getParameter(vaultCreateSavedQueriesParameterNames.queryDataScope);
    const useDateRange = getParameter(vaultCreateSavedQueriesParameterNames.useDateRange, false, {parse: true});
    const startDate = getParameter(vaultCreateSavedQueriesParameterNames.startDate);
    const endDate = getParameter(vaultCreateSavedQueriesParameterNames.endDate);

    const isDateRangeDisabled = isDisabled || !useDateRange.value;

    function inputHandler(event) {
        const {name, value} = event.target;
        updateParameter(name, value);
    }

    function toggleHandler(event) {
        const {name, checked} = event.target;
        updateParameter(name, checked);
    }

    function dropdownHandler(event) {
        const {dataset: {value}, parentNode} = event.currentTarget;
        const name = getDataNameFromParentNode(parentNode);
        updateParameter(name, value);
    }

    return (
        <div>
            <div className="display-input">
                <HTMLTextInput label={t('guidedJob:label.queryNamePrefix')} name={vaultCreateSavedQueriesParameterNames.queryNamePrefix}
                    value={queryNamePrefix.value} onChange={inputHandler} isRequired isDisabled={isDisabled}/>
            </div>

            <div className="forms-table display-input">
                <div className="table-row-group">
                    <FormElementRow label={t('guidedJob:label.dataScope')} isDisabled={isDisabled}
                        element={
                            <QueryDataScopeDropdown name={vaultCreateSavedQueriesParameterNames.queryDataScope}
                                value={queryDataScope.value} onItemSelect={dropdownHandler}
                                isRequired isDisabled={isDisabled}/>
                        }/>
                </div>
            </div>

            <div className="display-input">
                <Checkbox label={t('guidedJob:label.useDateRange')} name={vaultCreateSavedQueriesParameterNames.useDateRange}
                    value={useDateRange.value} onClick={toggleHandler} containerStyle={{marginBottom: '0.5rem'}}
                    isDisabled={isDisabled}/>

                <div className="forms-table">
                    <div className="table-row-group">
                        <FormElementRow labelId={'vaultQueryOptions:label:vaultStartDate'} label={t('guidedJob:label.vaultStartDate')} isDisabled={isDateRangeDisabled}
                            element={
                                <UtcDateTimeInput aria-labelledby={'vaultQueryOptions:label:vaultStartDate'} name={vaultCreateSavedQueriesParameterNames.startDate}
                                    value={startDate.value} inputHandler={inputHandler} isDisabled={isDateRangeDisabled}/>
                            }/>

                        <FormElementRow labelId={'vaultQueryOptions:label:vaultEndDate'} label={t('guidedJob:label.vaultEndDate')} isDisabled={isDateRangeDisabled}
                            element={
                                <UtcDateTimeInput aria-labelledby={'vaultQueryOptions:label:vaultEndDate'} name={vaultCreateSavedQueriesParameterNames.endDate}
                                    value={endDate.value} inputHandler={inputHandler} isDisabled={isDateRangeDisabled}/>
                            }/>
                    </div>
                </div>
            </div>
        </div>
    )
}

function QueryDataScopeDropdown(props) {
    const {t} = useTranslation(['guidedJob', 'aria']);

    const items = getKeys(vaultQueryDataScopes)
        .map(type => ({
            name: t(`workflowBuilder:DataScope.${type}`),
            value: type
        }));

    return (
        <ListDropdown id={'vaultDataScopeDropdown'} name={'dataScope'} items={items}
            noneSelectedMessage={t('guidedJob:option.selectDataScope')}
            {...props} />
    );
}
