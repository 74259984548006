import React from "react";
import {useTranslation} from "react-i18next";
import {stringToBool} from "../../../utilities/helperFunctions";
import {useDispatch, useSelector} from "react-redux";
import {applicationFeatures} from "../../../utilities/constants";
import {StatusLabel} from "../../common/Common";
import SchedulerModel from "../../../models/scheduler/SchedulerModel";
import OptionToggleRow from "./OptionToggleRow";

function WorkflowOption(props) {
    const {setting: workflowOption, updateSetting} = props;
    const {t} = useTranslation(['workflowOption']);
    const dispatch = useDispatch();

    const canViewWorkflowOptions = useSelector(state => state.currentUser.features.includes(applicationFeatures.VIEW_SENSITIVE_WORKFLOW));

    function updateToggleSetting(event) {
        const {name, checked} = event.currentTarget.dataset;
        updateSetting(name, stringToBool(checked));
        dispatch(SchedulerModel.actionCreators.updateResourceStatus());
    }

    return (
        <>
            <div className="options-table display-input">
                <div className="table-header-group">
                    <div className="table-header"/>
                    <div className="table-header"/>
                    <div className="table-header"/>
                </div>

                <div className="table-row-group">
                    <OptionToggleRow label={t(`workflowOption:label.showPrintableOptions`)}
                        name={'warnOnComment'} checked={workflowOption.showWorkflowOptions} onClick={updateToggleSetting}>

                        <div className="table-cell">
                            {(!canViewWorkflowOptions && workflowOption.showWorkflowOptions) && (
                                <StatusLabel message={t('workflowOption:label.cannotViewOptions')}/>
                            )}
                        </div>

                    </OptionToggleRow>
                </div>
            </div>
        </>
    )
}

export default WorkflowOption;