import React, {useEffect, useMemo, useRef} from "react";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "./Uppy.css";
import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import Dashboard from "./dashboard/Dashboard";
import DatasetValidationPlugin from "./DatasetValidationPlugin";
import StatusBarPlugin from "./statusbar/StatusBarPlugin";
import {axiosInstance} from "../../../models/api/AxiosProxy";
import {useDispatch, useSelector} from "react-redux";
import PopupModel from "../../../models/scheduler/PopupModel";
import {userSettings} from "../../../utilities/constants";
import {getValues} from "../../../utilities/helperFunctions";


class UploadStartHandler {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }

    showPopup = () => {
        this.active = true;
        this.dispatch(PopupModel.actionCreators.show({
            id: 'uppyStartingUpload',
            info: {
                key: 'uppyStartingUpload'
            },
            isRigid: true,
            hideTitle: true,
            hideButtons: true,
            showX: false
        }));
    }

    clearPopup = () => {
        if (this.active) {
            this.active = false;
            this.dispatch(PopupModel.actionCreators.hide('uppyStartingUpload'));
        }
    }
}

function UppyUpload(props) {
    const dispatch = useDispatch();

    const {
        datasetId,
        uploadMaxSize,

        updateView,
        forceBrowse,
        isDisabled,

        ...attr
    } = props;

    const {promptUserToRemoveInvalidFiles} = useSelector(state => state.userSettingsMap.get(userSettings.DATASET_OPTION));
    const dashboardRef = useRef();
    // Uppy file finalizing promise
    const uploadFinalizeRequests = useRef({});
    const speedInfos = useRef({});
    // Ref to track timeout for popup
    const uploadStartHandler = useRef(new UploadStartHandler(dispatch));

    const uppy = useMemo(() => {
        if (!datasetId || isDisabled) {
            return new Uppy({
                autoProceed: false
            })
        }

        const uppy = new Uppy({
            autoProceed: false,
            infoTimeout: 30000,
            onBeforeFileAdded: (currentFile) => {
                const newFile = {...currentFile};

                newFile.meta.lastModified = newFile.data.lastModified;
                if (typeof newFile.meta.relativePath === 'string') {
                    newFile.meta.relativePath = DatasetValidationPlugin.normalizePath(newFile.meta.relativePath);
                } else {
                    newFile.meta.relativePath = newFile.name;
                }
                return newFile;
            }
        })
            .use(Tus, {
                limit: 3,
                endpoint: axiosInstance.baseUrl + `/scheduler/resources/dataset/${datasetId}/upload`,
                removeFingerprintOnSuccess: true,
                onBeforeRequest: function (req) {
                    uploadStartHandler.current.clearPopup();
                    if (axiosInstance.uiToken) {
                        req.setHeader("Authorization", `Bearer ${axiosInstance.uiToken}`);
                    }
                },
                retryDelays: [1000, 3000]
            })
            .use(DatasetValidationPlugin, {
                dispatch,
                datasetId,
                uploadStartHandler: uploadStartHandler.current
            })
            .use(StatusBarPlugin, {
                hideCancelButton: true,
                showProgressDetails: true,
                uploadStartHandler: uploadStartHandler.current
            })
            .on('files-added', function (files) {
                for (const file of files) {
                    uploadFinalizeRequests.current[file.id] = null;
                }
            })
            .on('file-removed', function (file) {
                delete uploadFinalizeRequests.current[file.id];
            })
            .on('upload-success', function (file, upload) {
                // PUT to finalize upload and add to list of finalizePromises
                uploadFinalizeRequests.current[file.id] = axiosInstance.put(upload.uploadURL);
            })
            .on('upload-error', function (file, error) {
                console.log(error);
            })
            .on('error', function (error) {
                console.log(error);
            })
            .on('complete', function (result) {
                const promises = [];
                for (const finalizePromise of getValues(uploadFinalizeRequests.current)) {
                    if (finalizePromise === null) {
                        return;
                    }
                    promises.push(finalizePromise);
                }

                const update = {};
                // Return to filesTable if no errors
                if (result.failed.length === 0) {
                    update.isUploadActive = false;
                }

                // Wait for all finalizePromises to complete before returning flow to user
                Promise.all(promises)
                    .then(() => updateView(update));
            });

        uppy.on('upload-progress', function (file, progress) {
            if (speedInfos.current[file.id] == null) {
                speedInfos.current[file.id] = {
                    lastProgressUpdate: file.progress.uploadStarted,
                    lastBytesUploaded: file.progress.bytesUploaded
                };
            }

            const speedInfo = speedInfos.current[file.id];
            const {lastProgressUpdate, lastBytesUploaded} = speedInfo;

            const currentTimeMillis = Date.now();
            // Only track every > 1 second
            if (currentTimeMillis - lastProgressUpdate > 1000) {
                const timeElapsed = currentTimeMillis - lastProgressUpdate;
                const speed = (progress.bytesUploaded - lastBytesUploaded) / (timeElapsed / 1000);

                uppy.setFileMeta(file.id, {speed});
                speedInfos.current[file.id] = {
                    lastProgressUpdate: currentTimeMillis,
                    lastBytesUploaded: progress.bytesUploaded
                };
            }
        });

        return uppy;
    }, [datasetId, isDisabled, dispatch]);

    useEffect(() => {
        return () => uppy.close();
    }, [uppy]);

    useEffect(() => {
        const statusBarPlugin = uppy.getPlugin('StatusBar');
        if (statusBarPlugin != null) {
            statusBarPlugin.setOptions({
                hideUploadButton: isDisabled
            });
        }
    }, [uppy, isDisabled]);

    useEffect(() => {
        const datasetValidationPlugin = uppy.getPlugin('DatasetValidator');
        if (datasetValidationPlugin != null) {
            datasetValidationPlugin.setOptions({
                uploadMaxSize: parseInt(uploadMaxSize),
                promptUserToRemoveInvalidFiles
            });
        }
    }, [uppy, uploadMaxSize, promptUserToRemoveInvalidFiles]);


    return (
        <div id="uppyDashboardContainer" dir="ltr" ref={dashboardRef}>
            <Dashboard uppy={uppy}
                width={'100%'}
                disableStatusBar
                showLinkToFileUploadResult={false}
                proudlyDisplayPoweredByUppy={false}
                fileManagerSelectionType={'both'}
                plugins={['StatusBar']}
                hideCancelButton
                dispatch={dispatch}
                {...attr}
            />
        </div>
    );
}

export default UppyUpload;
