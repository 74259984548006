import React, {useCallback, useEffect, useState} from 'react';

import {useDispatch, useSelector} from "react-redux";
import {resetWorkflow, updateLibraryId} from "./redux/WorkflowHandler/workflowHandlerSlice";
import NavigatePane from "../components/common/NavigatePane/NavigatePane";
import {ActionType, licenseEditionType} from "./utilities/constants";
import {
    handleAdvancedIngestionValues,
    handleDiscoverConfigurationValues,
    handleDiscoverValues,
    handleIngestionValues,
    handleLegalExportNumbering,
    handleLegalExportValues,
    handleOcrValues,
    handleRelativityValues,
    handleReportOperations,
    handleUseCaseValues,
    handleWorkflowRequiredFiles,
    handleWorkflowValues
} from "./utilities/operationFunctions";
import {popupInfoKeys} from "../i18next/keys";
import {useTranslation} from "react-i18next";
import {resetWizard, setWizardWasChanged, updateGenericValues} from "./redux/WizardSlice/wizardSlice";
import {deepCopy} from "../utilities/helperFunctions";
import WizardHeader from "./components/Navigate/Header/WizardHeader";
import {containsEdition, getGroupPanels} from "./utilities/helperFunctions";
import {StepComponents} from "./utilities/StepComponents";


function WorkflowWizardTablet(props) {
    const {t} = useTranslation(['common', 'wizard']);
    const {onClose, showWarning, isDisabled, libraryId, onFinish, uploadState, edition, files} = props;

    const dispatch = useDispatch()
    const {operationsMap, workflowName, rfnFile, enabled, workflowDescription} = useSelector(state => state.workflowHandlerSlice);
    const {
        optionalSteps: {exporting, reporting},
        workflowInformation,
        relativityConfiguration,
        discoverConfiguration,
        discoverExport,
        caseSettings,
        ocrSettings,
        ingestionSettings,
        advancedIngestionSettings,
        legalExportSettings,
        workflowInformationValid,
        caseSettingsValid,
        ingestionSettingsValid,
        advancedIngestionSettingsValid,
        legalExportSettingsValid,
        ocrSettingsValid,
        relativityConfigurationValid,
        discoverExportValid,
        wasChanged
    } = useSelector(state => state.wizardSlice);

    const [stepPosition, setStepPosition] = useState(0);

    useEffect(() => {
        dispatch(resetWizard());
        dispatch(resetWorkflow());
    }, [])

    const resetClose = useCallback(() => {
        dispatch(resetWizard());
        dispatch(resetWorkflow());
        onClose();
    }, [dispatch, onClose]);

    const close = useCallback(() => {
        const item = t('wizard:popup.title')

        if (wasChanged) {
            showWarning({
                info: {
                    key: popupInfoKeys.DISCARD_FORM,
                    values: {
                        itemTitle: item,
                        itemLower: item.toLowerCase()
                    }
                },
                buttons: [{
                    title: t('common:option.discard'),
                    onClick: resetClose
                }]
            });
        } else {
            onClose()
        }
    }, [wasChanged, t, onClose, resetClose, showWarning])

    const next = () => {
        const maxPosition = panes.length - 1;
        submit(ActionType.NEXT, panes[stepPosition].id)

        if (stepPosition < maxPosition) {
            setStepPosition(stepPosition => stepPosition + 1)
        }

        if (wasChanged === false && stepPosition === 0) {
            dispatch(setWizardWasChanged({changed: true}))
        }
    }

    const back = () => {
        submit(ActionType.BACK, panes[stepPosition-1].id)

        if (stepPosition > 0) {
            setStepPosition(stepPosition => stepPosition - 1)
        }
    }

    const uploadWorkflow = () => {
        onFinish(workflowName, rfnFile, workflowDescription, enabled);
    }

    const submit = (actionType, paneId) => {
        switch (paneId) {
            case "workflowInformation":
                handleWorkflowValues(dispatch, t,{...workflowInformation}, actionType, deepCopy(operationsMap));
                break;

            case "discoverConfiguration":
                handleDiscoverConfigurationValues(dispatch, t, {...discoverConfiguration}, actionType, deepCopy(operationsMap))
                break;

            case "exportToDiscover":
                handleDiscoverValues(dispatch, t, {...discoverConfiguration}, actionType, deepCopy(operationsMap));
                break;

            case "exportToRelativity":
                handleRelativityValues(dispatch, t, {...relativityConfiguration}, actionType, deepCopy(operationsMap))
                break;

            case "ocrSettings":
                handleOcrValues(dispatch, t, {...ocrSettings, ...advancedIngestionSettings}, actionType, deepCopy(operationsMap))
                break;

            case "caseSettings":
                handleUseCaseValues(dispatch, t,{...caseSettings}, actionType, deepCopy(operationsMap));
                break;

            case "ingestionSettings":
                handleIngestionValues(dispatch, t,{...ingestionSettings}, actionType, deepCopy(operationsMap));
                break;

            case "advancedIngestionSettings":
                handleAdvancedIngestionValues(dispatch, t, {...advancedIngestionSettings}, actionType, deepCopy(operationsMap));
                break;

            case "legalExport":
                handleLegalExportValues(dispatch, t, {...legalExportSettings}, actionType, deepCopy(operationsMap));
                break;

            case "customExportNumbering":
                handleLegalExportNumbering(dispatch, t, {...legalExportSettings}, actionType, deepCopy(operationsMap));
                break;

            case "reportOptions":
                handleReportOperations(dispatch, t, {...reporting, ...advancedIngestionSettings}, actionType, deepCopy(operationsMap));
                break;
            case "finishStep":
            case "welcomeStep":
            default:
                break;
        }

        if (paneId) {
            handleWorkflowRequiredFiles(dispatch, {...workflowInformation, ...ocrSettings, ...legalExportSettings}, deepCopy(operationsMap))
        }
    }

    const canUpload = (workflowName.length > 0)

    function getActivePanels (group) {
        return Object.keys(group).filter(key => {
            return group[key] ? key : null;
        })
    }

    const optionalValidationMap = {
        legalExport: legalExportSettingsValid,
        exportToRelativity: relativityConfigurationValid
    }

    function getPanes () {
        let panels = [
            {id: "workflowInformation", title: t('wizard:step.timezone.title'), description: t('wizard:step.timezone.description'), component: StepComponents({id: "workflowInformation", files}), onBackClick: close, onNextClick: next, isNextEnabled: workflowInformationValid, backTitle: t('common:option.cancel')}
        ];

        let defaultPanels = [
            {id: "caseSettings", title: t('wizard:step.case.title'), description: t('wizard:step.case.description'), component: StepComponents({id: "caseSettings", files}), isNextEnabled: caseSettingsValid, onBackClick: back, onNextClick: next},
            {id: "ingestionSettings", title: t('wizard:step.ingestion.title'), description: t('wizard:step.ingestion.description'), component: StepComponents({id: "ingestionSettings", files}), isNextEnabled: ingestionSettingsValid, onBackClick: back, onNextClick: next},
        ]

        let staticPanels = {
            advancedIngestionSettings: {id: "advancedIngestionSettings", title: t('wizard:step.advancedIngestionSettings.title'), description: t('wizard:step.advancedIngestionSettings.description'), component: StepComponents({id: "advancedIngestionSettings", files}), isNextEnabled: advancedIngestionSettingsValid, onBackClick: back, onNextClick: next},
            ocrSettingsPanel: {id: "ocrSettings", title: t('wizard:step.ocrSettings.title'), description: t('wizard:step.ocrSettings.description'), component: StepComponents({id: "ocrSettings", files}), isNextEnabled: ocrSettingsValid, onBackClick: back, onNextClick: next},
            exportOptionsPanel: {id: "exportOptions", title: t('wizard:step.exportOptions.title'), description: t('wizard:step.exportOptions.description'), component: StepComponents({id: "exportOptions", files}), isNextEnabled: true, onBackClick: back, onNextClick: next},
            reportOptionsPanel: {id: "reportOptions", title: t('wizard:step.reportOptions.title'), description: t('wizard:step.reportOptions.description'), component: StepComponents({id: "reportOptions", files}), isNextEnabled: true, onBackClick: back, onNextClick: next},
            finishPanel: {id: "finishStep", title: t('wizard:step.finish.title'), description: t('wizard:step.finish.description'), component: StepComponents({id: "finishStep", files, isDisabled: isDisabled}), nextTitle: t('wizard:step.finish.nextTitle'), isNextEnabled: canUpload, onBackClick: back, onNextClick: uploadWorkflow}
        }

        let exportPanels = getGroupPanels(getActivePanels(exporting), next, back, t, optionalValidationMap, files);
        panels = [...panels].concat(defaultPanels);

        if (ingestionSettings.dataStructure !== 3) {
            panels.push(staticPanels.advancedIngestionSettings);
        }

        panels.push(staticPanels.ocrSettingsPanel);
        panels.push(staticPanels.exportOptionsPanel);
        panels = [...panels].concat(exportPanels);

        panels.push(staticPanels.reportOptionsPanel);
        panels.push(staticPanels.finishPanel);

        return panels;
    }

    // Handling closing and uploading
    useEffect(() => {
        if (uploadState !== null) {
            dispatch(resetWizard());
            dispatch(resetWorkflow());
        }
    }, [uploadState, dispatch])


    // Storing library ID
    useEffect(() => {
        dispatch(updateLibraryId({libraryId: libraryId}))
        dispatch(updateGenericValues({stateName: "ingestionSettings", updates: {dataStructure: containsEdition(licenseEditionType.PREMIUM, edition) ? 4 : 1}}));
    }, [libraryId, edition, dispatch])

    const panes = getPanes()
    const {title, description} = panes[stepPosition <= panes.length-1 ? stepPosition : panes.length-1];

    return (
        <>
            {(panes.length > 0 && stepPosition <= panes.length - 1) &&
                <NavigatePane
                    id={"workflowWizardForm"}
                    panes={panes}
                    onClose={close}
                    isDisabled={isDisabled}
                    bodyStyle={{height: '62.5vh'}}
                    force={{position: stepPosition}}
                    closeButtonAriaLabel={t('wizard:option.closeForm')}
                    headerComponent={
                    <WizardHeader
                        title={title}
                        description={description}
                        paneCount={panes.length}
                        stepNumber={stepPosition + 1}
                        isDisabled={isDisabled}/>
                    }
                />
            }
        </>
    );
}


export default WorkflowWizardTablet;
