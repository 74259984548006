import React from "react";
import {useTranslation} from "react-i18next";
import ExpandableContent, {ExpandableLog} from "../../common/ExpandableContent/ExpandableContent";
import {SettingsRowLabel, SettingsRowValue} from "../../common/CustomTable/CustomTable";
import {isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import FormBuilder from "../../common/FormBuilder/FormBuilder";
import NoticeTemplateModel from "../../../models/notice/NoticeTemplateModel";
import {ParametersTable} from "../../parameters/ParameterList";
import Text from "../../common/Text/Text";
import Editor from "rich-markdown-editor";

function NoticeConfirmPane(props) {
    const {t} = useTranslation(['common']);
    const {
        type,
        noticeName,
        enabled,
        description,

        subject,
        message,

        parameters,
        surveyFormOptions,

        isDisabled
    } = props;


    return (
        <div className="pane-margin">

            <div className="display-input">
                <ExpandableContent label={t('common:label.settings')}
                    isDisabled={isDisabled}
                >
                    <div className="settings-table">
                        <div className="table-row-group">
                            <SettingsRowValue label={t('common:label.name')} value={noticeName} isDisabled={isDisabled}/>
                            {!enabled &&
                            <SettingsRowLabel label={t('common:label.inactive')} isDisabled={isDisabled}/>
                            }
                        </div>
                    </div>
                </ExpandableContent>
            </div>

            {description &&
            <div className="display-input">
                <ExpandableLog label={t('common:label.description')} log={description}
                    isDisabled={isDisabled}
                />
            </div>
            }

            {isNotEmptyNorFalsy(parameters) &&
            <div className="display-input">
                <ExpandableParameters parameters={parameters} isDisabled={isDisabled}/>
            </div>
            }

            <div className="display-input">
                <ExpandableMessage parameters={parameters} subject={subject} message={message}
                    isDisabled={isDisabled}
                />
            </div>

            {NoticeTemplateModel.isResponseType(type) && isNotEmptyNorFalsy(surveyFormOptions) && surveyFormOptions.some(opt => opt.enabled) &&
            <div className="display-input">
                <ExpandableSurveyFormOptions surveyFormOptions={surveyFormOptions}
                    isDisabled={isDisabled}/>
            </div>
            }
        </div>
    )
}

export function ExpandableSurveyFormOptions(props) {
    const {t} = useTranslation(['noticeTemplate', 'common']);
    const {
        surveyFormOptions,
        isDisabled
    } = props;

    return (
        <ExpandableContent label={t('noticeTemplate:label.surveyFormOptions')}
            isDisabled={isDisabled}
        >
            <FormBuilder formOptions={surveyFormOptions}
                showHiddenValues isReadOnly isDisabled={isDisabled}/>
        </ExpandableContent>
    )
}

export function ExpandableParameters(props) {
    const {t} = useTranslation(['noticeTemplate', 'workflow', 'common']);
    const {parameters, initialExpanded, isDisabled} = props;

    return (
        <ExpandableContent label={t('noticeTemplate:label.parameters')} initialExpanded={initialExpanded}
            isDisabled={isDisabled}
        >
            <ParametersTable parameters={parameters} isDisabled={isDisabled}/>
        </ExpandableContent>
    )
}

export function ExpandableMessage(props) {
    const {t} = useTranslation(['noticeTemplate', 'workflow', 'common']);
    const {subject, message, isDisabled} = props;

    const disabled = isDisabled ? ' is-disabled' : '';
    return (
        <ExpandableContent label={t('noticeTemplate:label.message')}
            isDisabled={isDisabled}
        >
            <Text value={subject} isDisabled={isDisabled}/>

            {typeof message === 'string' &&
                <div className="rich-markdown-editor is-readonly">
                    <Editor className={disabled} value={message} readOnly/>
                </div>
            }
            {/*{typeof message === 'string' &&*/}
            {/*    <MarkdownEditor markdown={message} isReadOnly*/}
            {/*        isDisabled={isDisabled}/>*/}
            {/*}*/}

        </ExpandableContent>
    )
}

export default NoticeConfirmPane;
