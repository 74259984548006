import AxiosProxy from "./AxiosProxy";
import AxiosModel from "./AxiosModel";
import {generateUUID4} from "../../utilities/helperFunctions";

class RelativityAxiosProxy extends AxiosProxy {
    constructor() {
        super();
        const browserUrl = window.location.origin + window.location.pathname
        const url = browserUrl === 'http://localhost:3000/' ? 'http://127.0.0.1:8080/' : browserUrl;

        this.baseUrl = url + 'Relativity.REST/api/nuix-automate/v1';
        this.idToRequest = {};
        const headers = {
            "X-CSRF-Header": "-"
        };
        this.worker.postMessage(['initialize', {baseURL: this.baseUrl, headers}]);
        this.worker.onmessage = this.handlerWorkerResponse.bind(this);

        this._axiosModel = new AxiosModel({baseURL: this.baseUrl, headers});
    }

    __get(...args) {
        return this.__postWorkerRequest('get', ...args);
    }

    __post(...args) {
        return this.__postWorkerRequest('post', ...args);
    }

    __postWorkerRequest(method, ...args) {
        const id = generateUUID4();
        return super.postWorkerRequestWithId(id, method, ...args);
    }

    postWorkerRequestWithId(id, method, endpoint, ...args) {
        let data, config;
        if (method === 'post' || method === 'put') {
            [data, config] = args;
        } else {
            [config] = args;
        }

        const request = {
            endpoint: '/api/v1' + endpoint,
            data: '',
            outputEncoding: (config != null && config.responseType === 'blob') ? 'base64' : ''
        };

        if (data != null) {
            request.data = JSON.stringify(data);
        }

        if (method === 'del') {
            request.verb = 'DELETE';
        } else {
            request.verb = method.toUpperCase();
        }

        return super.postWorkerRequestWithId(id, 'post', '/proxy-service/proxy', request, config)
            .then(async res => {
                if (res.data instanceof Blob) {
                    // res.data is a Blob of the Response object {body: iconSvg || responseErrorObj}
                    const response = JSON.parse(await res.data.text());
                    try {
                        // Check if body is a responseErrorObj JSON and update to be handled below
                        const bodyJson = atob(response.body);
                        JSON.parse(bodyJson);
                        res.data.body = bodyJson;
                        res.data.status = response.status;
                    } catch {
                        // body is not a JSON, return expected response
                        return {
                            data: await fetch(`data:${config.mimeType};base64,${response.body}`).then(res => res.blob())
                        }
                    }
                }

                const updatedRes = {
                    ...res,
                    data: this.safeParseJson(res.data.body),
                    status: Number(res.data.status)
                };
                // 100-199 Informational; 200-299 Successful responses; 300-399 Redirection messages; 400-499 Client error responses; 500-599 Server error responses
                if (isNaN(updatedRes.status) || updatedRes.status >= 400) {
                    // Expected pattern for a Response error
                    throw {
                        request: {
                            status: updatedRes.status,
                            statusText: updatedRes.statusText
                        },
                        response: updatedRes
                    };
                }
                return updatedRes;
            })
            .catch(error => {
                // console.log(error);
                throw error;
            });
    }

    safeParseJson(json) {
        try {
            return JSON.parse(json);
        } catch {
            return json;
        }
    }
}

export default RelativityAxiosProxy;
