import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import Checkbox from '../common/Checkbox/Checkbox';
import {createToggleHandler} from "../common/Checkbox/helpers";
import TextArea from '../common/TextArea/TextArea';
import Form, {FormFooter, FormHeader} from "../common/Form/Form";
import {shouldEnableMatter} from "../../utilities/shouldEnableFunctions";
import {userSettings} from "../../utilities/constants";
import {createDropdownHandler} from "../common/Dropdown/helpers";
import {ExecutionProfileDropdown, ResourcePoolDropdown} from "../common/Dropdown/Dropdown";
import MatterModel from "../../models/client/MatterModel";
import PopupModel from "../../models/scheduler/PopupModel";
import ClientModel from "../../models/client/ClientModel";
import {FormElementRow} from "../common/CustomTable/CustomTable";
import ExpandableContent from "../common/ExpandableContent/ExpandableContent";
import KeyValueList from "../common/KeyValueList/KeyValueList";
import {getUserSettingsMatterAllowedParameterValues} from "../../reselect/selectors";
import {createCloseHandler, createInputHandler, createStateHandler} from "../../utilities/componentFunctions";
import ParameterModel from "../../models/library/ParameterModel";
import {InputTableV2} from "../common/InputTable/InputTable";
import DatasetModel from "../../models/data/DatasetModel";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import {onWorkflowParameterNormalize} from "../common/InputTable/helpers";


function MatterForm(props) {
    const {t} = useTranslation(['matter', 'workflow', 'dataset', 'common']);
    const dispatch = useDispatch();

    const {
        clientId
    } = props;

    const {
        isDisabled
    } = useSelector(state => state.componentStates.matterForm)

    const [state, setState] = useState({
        matterName: '',
        description: '',
        enabled: true,
        defaultExecutionProfileId: null,
        defaultResourcePoolId: null,
        synchronizeJobs: false,
        isAddEnabled: false,
        ...useSelector(state => state.userSettingsMap.get(userSettings.NEW_MATTER))
    });
    const [allowedParameterValues, setAllowedParameterValues] = useState(
        useSelector(getUserSettingsMatterAllowedParameterValues)
    );
    const [requiredMetadataHeaders, setRequiredMetadataHeaders] = useState([]);
    const [workflowParameters, setWorkflowParameters] = useState([]);

    const stateHandler = createStateHandler({
        updateState: setState,
        shouldEnable: shouldEnableMatter
    });

    const inputHandler = createInputHandler({
        handler: stateHandler
    });
    const toggleHandler = createToggleHandler({
        handler: stateHandler
    });
    const dropdownHandler = createDropdownHandler({
        handler: stateHandler
    });

    const [clearParameterPasswordState, setClearParameterPasswordState] = useState({})
    const workflowParameterNormalizeHandler = onWorkflowParameterNormalize(clearParameterPasswordState, setClearParameterPasswordState);

    const onClose = createCloseHandler({
        t,
        item: t('matter:label.name'),
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        values: {...state, allowedParameterValues},
        close: () => dispatch(ClientModel.componentActionCreators.updateDisplay({isMatterFormActive: false}))
    });

    function onSubmit() {
        dispatch(MatterModel.actionCreators.submitForm({
            clientId,
            ...state,
            allowedParameterValues,
            requiredMetadataHeaders,
            workflowParameters
        }))
    }


    const {
        matterName,
        description,
        enabled,
        defaultExecutionProfileId,
        defaultResourcePoolId,
        synchronizeJobs,
        isAddEnabled
    } = state;

    return (
        <Form onClose={onClose} isDisabled={isDisabled} closeButtonAriaLabel={t('matter:option.closeForm')}
            header={
                <FormHeader text={t('matter:label.name')} iconName={'matter'} isDisabled={isDisabled}/>
            }
            body={
                <>
                    <div className="display-input">
                        <HTMLTextInput label={t('common:label.name')} name={'matterName'} value={matterName} onChange={inputHandler}
                            isDisabled={isDisabled} isRequired />
                    </div>

                    <Checkbox label={t('common:label.enabled')} name={'enabled'} checked={enabled} onClick={toggleHandler}
                        isDisabled={isDisabled}/>

                    <div className="display-input">
                        <TextArea label={t('common:label.description')} name={'description'} value={description} onChange={inputHandler}
                            isDisabled={isDisabled}/>
                    </div>

                    <div className="forms-table display-input">
                        <div className="table-row-group">
                            <FormElementRow label={t('client:label.defaultExecutionProfile')} isDisabled={isDisabled}
                                element={
                                    <ExecutionProfileDropdown name={'defaultExecutionProfileId'}
                                        selectedExecutionProfileId={defaultExecutionProfileId} onExecutionProfileSelect={dropdownHandler}/>
                                }/>

                            <FormElementRow label={t('client:label.defaultResourcePool')} isDisabled={isDisabled}
                                element={
                                    <ResourcePoolDropdown name={'defaultResourcePoolId'}
                                        selectedResourcePoolId={defaultResourcePoolId} onResourcePoolSelect={dropdownHandler}/>
                                }/>
                        </div>
                    </div>

                    <div className="display-input">
                        <Checkbox label={t('matter:label.synchronizeJobs')} name={'synchronizeJobs'} checked={synchronizeJobs} onClick={toggleHandler}
                            isDisabled={isDisabled}/>
                    </div>

                    <div className="display-input">
                        <ExpandableContent label={t('matter:label.workflowParameters')}
                                           initialExpanded={workflowParameters.length > 0}
                        >
                            <InputTableV2 headers={[{value: t('matter:header.name')}, {value: t('matter:header.value')}]}
                                          inputTable={workflowParameters} setInputTable={setWorkflowParameters}
                                          isDisabled={isDisabled} defaultRow={ClientModel.workflowParametersRowTemplate}
                                          blurHandler={[ParameterModel.normalizeParameterName, null]} inputNormalizer={workflowParameterNormalizeHandler}
                                ariaLabelKey={'Parameter'}
                            />
                        </ExpandableContent>
                    </div>

                    <div className="display-input">
                        <ExpandableContent label={t('matter:label.allowedParameters')}
                            initialExpanded={allowedParameterValues.length > 0}
                            headerBarStyle={{
                                marginBottom: '0.25rem'
                            }}
                            isDisabled={isDisabled}
                        >
                            <KeyValueList keysTitle={t('workflow:label.parameterName')} blurHandler={ParameterModel.normalizeParameterName}
                                ariaLabelKey={'Parameter'} entries={allowedParameterValues} setEntries={setAllowedParameterValues} isMoveEnabled={true}
                            />

                        </ExpandableContent>
                    </div>

                    <div className="display-input">
                        <ExpandableContent label={t('dataset:label.requiredMetadataHeaders')}
                            initialExpanded={allowedParameterValues.length > 0}
                        >
                            <InputTableV2 headers={[{value: t('dataset:label.header')}, {value: t('dataset:label.regex')}]}
                                inputTable={requiredMetadataHeaders} setInputTable={setRequiredMetadataHeaders}
                                defaultRow={DatasetModel.requiredMetadataHeadersRowTemplate} isDisabled={isDisabled}
                                ariaLabelKey={'RequiredMetadata'}
                            />
                        </ExpandableContent>
                    </div>
                </>
            }
            footer={
                <FormFooter addText={t('common:button.addMatter')} onAddClick={onSubmit} isAddEnabled={isAddEnabled}
                    onCancel={onClose} isDisabled={isDisabled}/>
            }
        />
    )
}

export default MatterForm;
