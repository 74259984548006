import ReduxModel from "../generics/ReduxModel";
import {
    actionCreator,
    capitalize,
    cleanFalsy,
    endsWithSome,
    getEntries,
    getLocaleDateFromUTC,
    getLocaleDateTimeFromUTC,
    getProfileTypeFromXml,
    getValues,
    isNotEmptyNorFalsy,
    parseNuixDate
} from "../../utilities/helperFunctions";
import WorkflowTemplateModel from "./WorkflowTemplateModel";
import {PASSWORD_PLACEHOLDER} from "../../utilities/constants";
import regexpTree from "regexp-tree";
import i18n from "i18next";
import {getLocalDateTimeValueFromUtc} from "../../components/common/HTMLTextInput/UtcDateTimeInput";
import {getLocalDateValueFromUtc} from "../../components/common/HTMLTextInput/UtcDateInput";

class ParameterModel extends ReduxModel {

    static nom = 'ParameterModel';
    static actions = ParameterModel.buildActions();
    static actionCreators = ParameterModel.buildActionCreators(ParameterModel.actions);
    static reducer = ParameterModel.buildReducer(ParameterModel.actions);

    constructor(model = {}) {
        super();

        this.id = `${model.name}Parameter`;
        this.friendlyName = model.friendlyName;
        this.name = model.name;
        this.parameterType = model.parameterType;
        this.userDisplayableValue = model.userDisplayableValue;

        this.value = model.value;
        this.valueName = model.valueName;
        this.description = model.description;
        this.regex = model.regex;
        this.min = model.min;
        this.max = model.max;

        this.allowedValuesMatchBy = model.allowedValuesMatchBy;
        if (Array.isArray(model.allowedValues) && typeof model.allowedValues[0] === 'string') {
            this.parameterAllowedValues = [...new Set(model.allowedValues)].map(_value => ({value: _value}));
        } else {
            this.parameterAllowedValues = model.allowedValues;
        }

        this.disabled = false;
        this.valid = null;
        this.notRequired = model.notRequired;
        this.readOnly = model.readOnly;
        this.displayCondition = model.displayCondition || {displayable: true};
        this.relativityCondition = model.relativityCondition || {}

        this.dependantParameters = model.dependantParameters;
        this.scriptLanguage = model.scriptLanguage;
        this.scriptCode = model.scriptCode;

        this.dataRepositoryId = model.dataRepositoryId;
        this.allowedDataRepositoryIds = model.allowedDataRepositoryIds;
        this.allowedFileExtensions = model.allowedFileExtensions;
    }

    static StaticType = {
        TEXT: 'TEXT',
        TEXT_MASKED: 'TEXT_MASKED',
        TEXT_PROTECTED: 'TEXT_PROTECTED',
        INTEGER: 'INTEGER',
        BOOLEAN: 'BOOLEAN',
        DATE: 'DATE',
        DATE_TIME: 'DATE_TIME',
        DATA_SET: 'DATA_SET',
        AZURE_STORE_ACCOUNT: 'AZURE_STORAGE_ACCOUNT',
        LEGAL_HOLD: 'LEGAL_HOLD',
        PURVIEW_SERVICE: 'PURVIEW_SERVICE',
        RELATIVITY_SERVICE: 'RELATIVITY_SERVICE',
        RELATIVITY_WORKSPACE : 'RELATIVITY_WORKSPACE',
        RELATIVITY_CLIENT : 'RELATIVITY_CLIENT',
        RELATIVITY_MATTER : 'RELATIVITY_MATTER',
        // RELATIVITY_USER : 'RELATIVITY_USER',
        RELATIVITY_DT_SEARCH: 'RELATIVITY_DT_SEARCH',
        RELATIVITY_WORKSPACE_FIELD: 'RELATIVITY_WORKSPACE_FIELD',
        LIBRARY_FILE : 'LIBRARY_FILE',
        SEPARATOR: 'SEPARATOR',
        FILE_CONTENTS: 'FILE_CONTENTS',
        DISCOVER_SERVICE: 'DISCOVER_SERVICE',
        ELASTICSEARCH_SERVICE: 'ELASTICSEARCH_SERVICE',
        GRAPH_SERVICE: 'GRAPH_SERVICE',
        GEN_AI_SERVICE: 'GEN_AI_SERVICE',
        SEMANTIC_SERVICE: 'SEMANTIC_SERVICE',
        NLP_SERVICE: 'NLP_SERVICE',
        ECC_SERVICE: 'ECC_SERVICE',
        SMTP_SERVICE: 'SMTP_SERVICE',
        VAULT_SERVICE: 'VAULT_SERVICE'
    };

    static Type = {
        ...this.StaticType,
        LOCK: 'LOCK',
        SCRIPTED: 'SCRIPTED',
        SERVER_FILE: 'SERVER_FILE',
        SERVER_FOLDER: 'SERVER_FOLDER',
        RELATIVITY_VIEW: "RELATIVITY_VIEW",
        RELATIVITY_FOLDER: "RELATIVITY_FOLDER",
        RELATIVITY_SAVED_SEARCH: "RELATIVITY_SAVED_SEARCH",
        ECC_COMPUTER: "ECC_COMPUTER",
        ECC_CONFIGURATION: "ECC_CONFIGURATION",
        TIMEZONE: 'TIMEZONE'
    };

    static AllowedValuesMatchBy = {
        SYSTEM_VALUE: 'SYSTEM_VALUE',
        USER_PRINTABLE_VALUE: 'USER_PRINTABLE_VALUE',
        SYSTEM_OR_USER_PRINTABLE_VALUE: 'SYSTEM_OR_USER_PRINTABLE_VALUE'
    };

    static isThirdPartyServiceParameter(type) {
        switch (type) {
            case ParameterModel.Type.PURVIEW_SERVICE:
            case ParameterModel.Type.VAULT_SERVICE:
            case ParameterModel.Type.RELATIVITY_SERVICE:
            case ParameterModel.Type.DISCOVER_SERVICE:
            case ParameterModel.Type.ELASTICSEARCH_SERVICE:
            case ParameterModel.Type.ECC_SERVICE:
            case ParameterModel.Type.NLP_SERVICE:
            case ParameterModel.Type.GRAPH_SERVICE:
            case ParameterModel.Type.GEN_AI_SERVICE:
            case ParameterModel.Type.SEMANTIC_SERVICE:
            case ParameterModel.Type.SMTP_SERVICE:
                return true;
        }
    }

    getUserDisplayableValue(val=this.value) {
        if (Array.isArray(this.parameterAllowedValues)) {
            const allowedValueItem = this.parameterAllowedValues.find(item => item.value === val);
            if (allowedValueItem != null) {
                return allowedValueItem.name;
            }
        }
        if (val) {
            return this.userDisplayableValue;
        }
    }

    // Override update to prevent updating protected parameters' value
    update(updates) {
        if (updates == null) {
            return this;
        }

        if (this.isPasswordParameter()) {
            Object
                .keys(updates)
                // Filter to avoid updating 'value' of protected
                .filter(prop => prop in this && prop !== 'value')
                .forEach(prop => {
                    this[prop] = updates[prop];
                });

            return this;
        }

        return super.update(updates);
    }

    getInvalidReason(value=this.value, opts={}) {
        try {
            if (this.isSeparatorParameter()) {
                return;
            }
            if (this.notRequired && !value) {
                return;
            }
            // If a value is not displayable do not validate
            if (this.displayCondition.displayable === false) {
                return;
            }

            if (this.isBooleanParameter()) {
                if (!['True', 'False'].includes(value)) {
                    if (!value) {
                        return i18n.t('workflow:invalidParameterMessage.missingValue');
                    }
                    return i18n.t('workflow:invalidParameterMessage.invalidBooleanValue');
                }

            } else if (this.isServerFolderParameter() || this.isServerFileParameter()) {
                if (!this.dataRepositoryId) {
                    return i18n.t('workflow:invalidParameterMessage.missingDataRepository');
                }
                if (!value) {
                    return i18n.t('workflow:invalidParameterMessage.missingValue');
                }
                if (Array.isArray(this.allowedDataRepositoryIds) && this.allowedDataRepositoryIds.length > 0) {
                    if (!this.allowedDataRepositoryIds.includes(this.dataRepositoryId)) {
                        return i18n.t('workflow:invalidParameterMessage.dataRepositoryNotAllowed');
                    }
                }
                if (Array.isArray(this.allowedFileExtensions) && this.allowedFileExtensions.length > 0) {
                    if (!this.allowedFileExtensions.some(fileExtension => value.endsWith(fileExtension))) {
                        return i18n.t('workflow:invalidParameterMessage.fileExtensionNotAllowed');
                    }
                }

            } else if ((this.isDateParameter() || this.isDateTimeParameter()) && value) {
                // Validate dateFormat: yyyyMMddTHHmmss
                if (!value.match(/^\d{8}(T\d{6})?$/)) {
                    console.log('Invalid date format, expected yyyyMMddTHHmmss: ' + value);
                    return i18n.t('workflow:invalidParameterMessage.invalidDateFormat');
                }
                if (isNaN(parseNuixDate(value))) {
                    return i18n.t('workflow:invalidParameterMessage.invalidDateValue');
                }
            }

            if (this.min != null || this.max != null) {
                const _min = parseInt(this.min);
                const _max = parseInt(this.max);
                if (this.isNumberParameter()) {
                    const val = parseInt(value);

                    if (!isNaN(_min) && val < _min) {
                        return i18n.t('workflow:invalidParameterMessage.valueLowerThanMin', {min: _min});
                    }
                    if (!isNaN(_max) && val > _max) {
                        return i18n.t('workflow:invalidParameterMessage.valueGreaterThanMax', {max: _max});
                    }
                } else if (this.isDateParameter() || this.isDateTimeParameter()) {
                    const dateMillis = parseNuixDate(value).getTime();

                    if (!isNaN(_min) && dateMillis < _min) {
                        const minDate = this.isDateParameter() ? getLocalDateValueFromUtc(_min) : getLocalDateTimeValueFromUtc(_min);
                        return i18n.t('workflow:invalidParameterMessage.valueLowerThanMin', {min: minDate});
                    }
                    if (!isNaN(_max) && dateMillis > _max) {
                        const maxDate = this.isDateParameter() ? getLocalDateValueFromUtc(_max) : getLocalDateTimeValueFromUtc(_max);
                        return i18n.t('workflow:invalidParameterMessage.valueGreaterThanMax', {max: maxDate});
                    }
                }
            }

            if (this.regex) {
                if (this.valueName != null) {
                    if (!!this.valueName.match(`^(${this.regex})$`)) {
                        return;
                    }
                }
                const userDisplayableValue = this.getUserDisplayableValue(value);
                if (userDisplayableValue != null) {
                    if (!!userDisplayableValue.match(`^(${this.regex})$`)) {
                        return;
                    }
                }
                if (!value.match(`^(${this.regex})$`)) {
                    return i18n.t('workflow:invalidParameterMessage.valueDoesNotMatchRegex', {regex: this.regex});
                }
            }
        } catch (error) {
            console.log(`Error validating parameter: ${this.name}`, error);
            return false;
        }

        if (!opts.ignoreAllowedValues) {
            const allowedValues = this.getAllowedValues();

            if (this.isDropdownParameter()) {
                if (!isNotEmptyNorFalsy(allowedValues)) {
                    return i18n.t('workflow:invalidParameterMessage.missingDropdownValues');
                }
            }
            // Parameter requires a value if allowedValues specified
            if (Array.isArray(allowedValues)) {
                if (allowedValues.length === 0) {
                    return i18n.t('workflow:invalidParameterMessage.missingDropdownValues');
                }
                if (!value) {
                    return i18n.t('workflow:invalidParameterMessage.missingValue');
                }
                if (!allowedValues.some(val => val.value === value)) {
                    return i18n.t('workflow:invalidParameterMessage.valueNotAllowed');
                }
            }
        }
    }

    validate(value=this.value, opts={}) {
        return this.getInvalidReason(value, opts) == null;
    }

    static getFilterTypesForRelativityParameterType(type) {
        switch (type) {
            case ParameterModel.Type.RELATIVITY_MATTER:
                return [ParameterModel.Type.RELATIVITY_CLIENT];
            case ParameterModel.Type.RELATIVITY_WORKSPACE:
                return [ParameterModel.Type.RELATIVITY_CLIENT, ParameterModel.Type.RELATIVITY_MATTER];
            case ParameterModel.Type.RELATIVITY_DT_SEARCH:
                return [ParameterModel.Type.RELATIVITY_WORKSPACE];
            case ParameterModel.Type.RELATIVITY_WORKSPACE_FIELD:
                return [ParameterModel.Type.RELATIVITY_WORKSPACE];
            case ParameterModel.Type.RELATIVITY_VIEW:
                return [ParameterModel.Type.RELATIVITY_WORKSPACE];
            case ParameterModel.Type.RELATIVITY_FOLDER:
                return [ParameterModel.Type.RELATIVITY_WORKSPACE];
            case ParameterModel.Type.RELATIVITY_SAVED_SEARCH:
                return [ParameterModel.Type.RELATIVITY_WORKSPACE];
        }
        return [];
    }

    static isPasswordParameter(name, value = '') {;
        return (value == null || endsWithSome(name, ['_password', '_password}', '_masked', '_masked}', '_protected', '_protected}']));
    }

    isPasswordParameter() {
        if (this.parameterType == null){
            return ParameterModel.isPasswordParameter(this.name,this.value);
        }
        return this.parameterType === ParameterModel.Type.TEXT_MASKED || this.parameterType === ParameterModel.Type.TEXT_PROTECTED ;
    }

    isProfileParameter() {
        return endsWithSome(this.name, ['_profile', '_profile}']);
    }

    isFileContentParameter() {
        return this.parameterType === ParameterModel.Type.FILE_CONTENTS;
    }

    isFileParameter() {
        return this.parameterType === ParameterModel.Type.LIBRARY_FILE;
    }

    isDatasetParameter() {
        return this.parameterType === ParameterModel.Type.DATA_SET;
    }

    isLegalHoldParameter() {
        return this.parameterType === ParameterModel.Type.LEGAL_HOLD;
    }

    isAzureStorageAccountParameter() {
        return this.parameterType === ParameterModel.Type.AZURE_STORE_ACCOUNT;
    }

    isThirdPartyServiceParameter() {
        return ParameterModel.isThirdPartyServiceParameter(this.parameterType);
    }

    isPurviewServiceParameter() {
        return this.parameterType === ParameterModel.Type.PURVIEW_SERVICE;
    }

    isVaultServiceParameter() {
        return this.parameterType === ParameterModel.Type.VAULT_SERVICE;
    }

    isRelativityServiceParameter() {
        return this.parameterType === ParameterModel.Type.RELATIVITY_SERVICE;
    }

    isDiscoverServiceParameter() {
        return this.parameterType === ParameterModel.Type.DISCOVER_SERVICE;
    }

    isElasticsearchServiceParameter() {
        return this.parameterType === ParameterModel.Type.ELASTICSEARCH_SERVICE;
    }

    isGraphServiceParameter() {
        return this.parameterType === ParameterModel.Type.GRAPH_SERVICE;
    }

    isGenAiServiceParameter() {
        return this.parameterType === ParameterModel.Type.GEN_AI_SERVICE;
    }

    isSemanticServiceParameter() {
        return this.parameterType === ParameterModel.Type.SEMANTIC_SERVICE;
    }

    isNlpServiceParameter() {
        return this.parameterType === ParameterModel.Type.NLP_SERVICE;
    }

    isEccServiceParameter() {
        return this.parameterType === ParameterModel.Type.ECC_SERVICE;
    }

    isSmtpServiceParameter() {
        return this.parameterType === ParameterModel.Type.SMTP_SERVICE;
    }

    isEccParameter() {
        return ParameterModel.isEccParameter(this.parameterType);
    }

    static isEccParameter(type) {
        return [ParameterModel.Type.ECC_CONFIGURATION, ParameterModel.Type.ECC_COMPUTER].includes(type);
    }

    isRelativityParameter() {
        return ParameterModel.isRelativityParameter(this.parameterType);
    }

    static isRelativityParameter(type) {
        return [ParameterModel.Type.RELATIVITY_CLIENT, ParameterModel.Type.RELATIVITY_MATTER, ParameterModel.Type.RELATIVITY_DT_SEARCH,
            ParameterModel.Type.RELATIVITY_WORKSPACE, ParameterModel.Type.RELATIVITY_WORKSPACE_FIELD, ParameterModel.Type.RELATIVITY_VIEW,
            ParameterModel.Type.RELATIVITY_FOLDER, ParameterModel.Type.RELATIVITY_SAVED_SEARCH].includes(type);
    }

    isSeparatorParameter() {
        return this.parameterType === ParameterModel.Type.SEPARATOR;
    }

    isDropdownParameter() {
        return this.isRelativityParameter();
    }

    isNumberParameter() {
        return this.parameterType === ParameterModel.Type.INTEGER;
    }

    isBooleanParameter() {
        return this.parameterType === ParameterModel.Type.BOOLEAN;
    }

    isDateParameter() {
        return this.parameterType === ParameterModel.Type.DATE;
    }

    isDateTimeParameter() {
        return this.parameterType === ParameterModel.Type.DATE_TIME;
    }

    isScriptedParameter() {
        return this.parameterType === ParameterModel.Type.SCRIPTED;
    }

    isServerFileParameter() {
        return this.parameterType === ParameterModel.Type.SERVER_FILE;
    }

    isServerFolderParameter() {
        return this.parameterType === ParameterModel.Type.SERVER_FOLDER;
    }

    // if empty/null use regexAllowedValues, else parameterAllowedValues
    getAllowedValues() {
        let allowedValues = this.parameterAllowedValues;
        if (Array.isArray(this.regexAllowedValues) && (!Array.isArray(allowedValues) || allowedValues.length === 0)) {
            allowedValues = this.regexAllowedValues;
        }
        return allowedValues;
    }

    getRestrictionsLabel() {
        let minMaxRestrictions = ''

        if (this.min != null || this.max != null) {
            if (this.isNumberParameter()) {
                minMaxRestrictions = `${this.min} - ${this.max}`;

            } else if (this.isDateParameter()) {
                const dateOptions = {timeZone: 'UTC'};
                const min = this.min ? getLocaleDateFromUTC(this.min, dateOptions) : '';
                const max = this.max ? getLocaleDateFromUTC(this.max, dateOptions) : '';

                minMaxRestrictions = `${min} - ${max}`;

            } else if (this.isDateTimeParameter()) {
                const dateTimeOptions = {timeZone: 'UTC', timeStyle: 'long'};
                const min = this.min ? getLocaleDateTimeFromUTC(this.min, dateTimeOptions) : '';
                const max = this.max ? getLocaleDateTimeFromUTC(this.max, dateTimeOptions) : '';

                minMaxRestrictions = `${min} - ${max}`;
            }
        }

        if (minMaxRestrictions) {
            if (this.regex) {
                return `${this.regex} | ${minMaxRestrictions}`;
            }
            return minMaxRestrictions;
        }
        return this.regex;
    }

    getProtectedValue(isEditActive) {
        if (this.isPasswordParameter()) {

            // Show '' if value === ''
            if ((!isEditActive && this.value !== '') || this.value == null) {
                return PASSWORD_PLACEHOLDER;
            }
        }

        return this.value;
    }

    buildRegexAllowedValues() {
        // if (this.regex != null && this.regex !== '') {
        //     const regexAllowedValues = ParameterModel.getRegexAllowedValues(this.regex);
        //     if (Array.isArray(regexAllowedValues) && regexAllowedValues.length > 0) {
        //         this.regexAllowedValues = regexAllowedValues;
        //     }
        // }
    }

    static getRegexAllowedValues(regex) {
        function processNode(node) {
            if (node === null) {
                return [{value: ''}];
            }

            const {type, left, right} = node;
            if (!['Disjunction', 'Alternative'].includes(type))
                throw new Error('Invalid Pattern');

            const regexValues = [];
            if (type === 'Alternative') {
                const {expressions} = node;

                let val = '';
                for (let i = 0; i < expressions.length; i++) {
                    const {type, kind, value} = expressions[i];

                    if (type !== 'Char' || kind !== 'simple')
                        throw new Error('Invalid Pattern');

                    val += value;
                }
                regexValues.push({value: val});
            }

            if (left !== undefined) {
                regexValues.push(...processNode(left));
            }

            if (right !== undefined) {
                regexValues.push(...processNode(right));
            }

            return regexValues;
        }

        try {
            // Some RegExps are not supported by browsers (negative lookbehind)
            const ast = regexpTree.parse(new RegExp(regex));
            const regexAllowedValues = processNode(ast.body);

            const existingValues = new Set();
            return (regexAllowedValues || []).filter(({value}) => {
                if (existingValues.has(value)) {
                    return false;
                }
                existingValues.add(value);
                return true;
            });
        } catch (ignore) {}
    }

    static postProtectedValue(value) {
        if (value === PASSWORD_PLACEHOLDER)
            return null;

        return value;
    }

    static getProtectedParameterHTMLValues(containerId) {
        const container = document.getElementById(containerId);

        const protectedParameters = {};
        if (container != null) {
            // Get all inputs
            const parameterInputs = [...container.getElementsByTagName('input')]
                // Filter for password types
                .filter(input => input.type === 'password' && input.value !== PASSWORD_PLACEHOLDER);

            for (const input of parameterInputs) {
                const {name, value} = input;

                protectedParameters[name] = value;
            }
        }

        return protectedParameters;
    }

    // Gets the protected parameters from HTML then encrypts and assigns it to new parameters map
    static encryptProtectedParametersFromHTML(containerId, encrypto, parameters) {
        const protectedParameters = ParameterModel.getProtectedParameterHTMLValues(containerId);

        const newParameters = new Map(parameters);
        for (const [name, value] of getEntries(protectedParameters)) {

            newParameters.set(name, newParameters.get(name)
                .duplicate()
                .forceUpdate({
                    value: encrypto.encrypt(name, value)
                }));
        }

        return newParameters;
    }

    // Decrypts protected parameter values from @parameters and sets the decrypted value to the respective HTML input
    static decryptProtectedParametersToHTML(containerId, encrypto, parameters) {
        const container = document.getElementById(containerId);
        if (container == null)
            return;

        const passwordInputs = [...container.getElementsByTagName('input')]
            // Filter for password types
            .filter(input => input.type === 'password');

        for (const input of passwordInputs) {
            const {name, value} = parameters.get(input.name);
            // Prevent decrypting non-encrypted values by skipping null or ''
            // (Default value is either null or ''; assume other values are encrypted)
            if (value == null || value === '')
                continue;

            // Only decrypt and show '' values (To show blank is still blank)
            if (encrypto.decrypt(name, value) === '') {
                input.value = '';
            } else {
                // To prevent re-encryption
                input.value = PASSWORD_PLACEHOLDER
            }
        }
    }

    // Transform object/array parameters into Map
    static buildParametersMap(parameters) {
        let paramArr;
        if (parameters instanceof Array) {
            paramArr = parameters;
        } else {
            paramArr = getValues(parameters);
        }

        const paramMap = new Map();
        for (let i = 0; i < paramArr.length; i++) {
            const param = paramArr[i];

            paramMap.set(param.name, new this(param));
        }

        return paramMap;
    }

    static buildAllowedParameterValues(allowedParameters) {
        // Build allowedParameterValues
        return allowedParameters.reduce((res, entry) => {
            const [name, values] = entry;

            if (name) {
                res[name] = cleanFalsy(values);
            }

            return res;
        }, {});
    }

    static normalizeParameterName(name) {
        if (!name)
            return name;

        let normalized = name.toLowerCase()
            .replace(/[^a-z0-9]/g, '_')
            .replace(/_+/g, '_')
            .replace(/^_/, '')
            .replace(/_$/, '')

        return '{' + normalized + '}';
    }

    static getFriendlyNameFromParameterName(name) {
        if (!name)
            return name;

        return name.replace(/[{}]/g, '').split(/[_\s]/)
            .map(word => capitalize(word))
            .join(' ');
    }

    static normalizeFileParameterName(name) {
        if (!name)
            return name;

        let normalizedFileParameter = this.normalizeParameterName(name);
        if (endsWithSome(normalizedFileParameter, ['_file', '_file}'])) {
            return normalizedFileParameter;
        }
        return `${normalizedFileParameter.substring(0, normalizedFileParameter.length - 1)}_file}`
    }

    static validateParameterInputTable(parameters) {
        return !Array.isArray(parameters) || parameters.length === 0
            || parameters.every(parameter => !!parameter.name && !!parameter.friendlyName && !parameter.isAzureStorageAccountParameter()
                && !parameter.isPasswordParameter() && !parameter.isDatasetParameter() && !parameter.isFileParameter() && !parameter.isThirdPartyServiceParameter());
    }

    static buildActions() {
        return {
            // PARAMETER ACTIONS
            SET_PARAMETERS: 'SET_PARAMETERS'
        }
    }

    static buildActionCreators(actions) {
        return {
            // PARAMETER ACTION CREATORS
            setParameters: actionCreator(actions.SET_PARAMETERS, 'id', 'parameters')
        }
    }

    static buildReducer(actions) {
        return function(state = new Map(), action) {
            switch (action.type) {
                case actions.SET_PARAMETERS: {
                    const { id, parameters } = action.payload;

                    const oldParameters = state.get(id);
                    const newParameters = this.setDetailsMapGeneric(oldParameters, parameters, 'name');

                    // If reference is same, return same
                    if (oldParameters !== newParameters) {
                        return new Map(state).set(id, newParameters);
                    }

                    return state;
                }
                case WorkflowTemplateModel.actions.DELETE_DETAILS: {
                    const { id } = action.payload;

                    return this.deleteDetails(state, id);
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }

    static validateNuixProfile(profileData) {
        const enc = new TextDecoder("utf-8");
        const profileXml = enc.decode(profileData);
        const profileType = getProfileTypeFromXml(profileXml);

        switch(profileType) {
            case "processing-profile":
                return "processingProfiles";
            case "metadata-profile":
                return "metadataProfiles";
            case "metadata-import-profile":
                return "metadataImportProfiles";
            case "ocr-profile":
                return "ocrProfiles";
            case "production-profile":
                return "productionProfiles";
            case "preferences":
                return "configurationProfiles";
            case "imaging-profile":
                return "imagingProfiles";
            default:
                return "invalidProfile";
        }
    }
}

export default ParameterModel;