import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import WebhookModel from "../../models/settings/WebhookModel";
import {isNotEmptyNorFalsy, switchcaseF} from "../../utilities/helperFunctions";
import {permissionKeys, statusKeys} from "../../i18next/keys";
import Tablet, {EditTabletHeader} from "../common/Tablet/Tablet";
import ExpandableContent, {
    ExpandableChangeLogTable,
    ExpandableValueList
} from "../common/ExpandableContent/ExpandableContent";
import {SettingsRowLabel, SettingsRowValue} from "../common/CustomTable/CustomTable";
import {makeGetEditDetails} from "../../reselect/selectors";
import EditModel from "../../models/scheduler/EditModel";
import {createEditHandler, createInputHandler} from "../../utilities/componentFunctions";
import {shouldEnableWebhook} from "../../utilities/shouldEnableFunctions";
import {createToggleHandler} from "../common/Checkbox/helpers";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import Checkbox from "../common/Checkbox/Checkbox";
import {SwitchItemContainer} from "../common/ListContainer/ListContainer";
import {WebhookEventTriggerTypeDropdown} from "../common/Dropdown/Dropdown";
import Text from "../common/Text/Text";
import {createDropdownHandler} from "../common/Dropdown/helpers";
import {DefaultEditPanel} from "../common/EditPanel/EditPanel";
import {ExpandableStatusLog} from "../common/Common";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import WebhookEventView from "./WebhookEventView";
import CopySecretLabel from "../common/Text/CopySecretLabel";
import LimitedTablet from "../limited/LimitedTablet";


const selectEditDetails = makeGetEditDetails();
function selectReduxState(state, props) {

    const {activeModel, values, isSaveEnabled} = selectEditDetails(state, {model: WebhookModel.nom})
    const isEditActive = (activeModel === WebhookModel.nom)

    return {
        webhookName: props.name,
        ...props,
        ...values,
        isEditActive,
        isSaveEnabled
    }
}

function WebhookTablet(props) {
    const {t} = useTranslation(['webhook', 'common']);
    const dispatch = useDispatch();


    const {
        isEditActive,
        isSaveEnabled,

        ...webhook
    } = useSelector(state => selectReduxState(state, props));

    const isLoading = useSelector(state => !state.hasLoaded[webhook.id]);
    const {
        signatureKey,
        isDisabled
    } = useSelector(state => state.componentStates.webhookTablet);

    const {
        id,
        webhookName,
        active,
        triggers,
        status,
        callbackAddress,
        historyEnabled,
        whitelistedCertFingerprints,
        auditLog,
        userPermissions
    } = webhook;

    useEffect(() => {
        dispatch(WebhookModel.actionCreators.startPollingSettings(id));

        return () => dispatch(WebhookModel.actionCreators.stopPollingSettings());
    }, [id]);

    const {
        saveEdit,
        cancelEdit,
        updateEdit,
        setEditSaveEnabled
    } = EditModel.buildDispatchers(dispatch);

    const editHandler = createEditHandler({
        updateEdit,
        setEditSaveEnabled,
        shouldEnable: shouldEnableWebhook,
        values: {webhookName, callbackAddress, triggers}
    });

    const inputHandler = createInputHandler({
        handler: editHandler
    });

    const toggleHandler = createToggleHandler({
        handler: editHandler
    });

    const [eventTriggerType, setEventTriggerType] = useState('');
    const dropdownHandler = createDropdownHandler({
        handler: updates => setEventTriggerType(updates.eventTriggerType)
    });


    function onClose() {
        dispatch(WebhookModel.actionCreators.hideTablet());
    }

    function setTriggers(update) {
        const updates = {
            triggers: update(triggers)
        };

        editHandler(updates);
    }

    function setWhitelistedCertFingerprints(update) {
        const updates = {
            whitelistedCertFingerprints: update(whitelistedCertFingerprints)
        };

        editHandler(updates);
    }

    function menuOptionHandler(event) {
        const {value} = event.currentTarget.dataset

        switchcaseF({
            'edit': () => dispatch(WebhookModel.actionCreators.startEdit(id)),
            'toggleActive': () => dispatch(WebhookModel.actionCreators.toggleEnabled(id)),
            'promptDelete': () => dispatch(WebhookModel.actionCreators.promptDelete(id))
        })()(value);
    }

    const menuOptions = [
        {name: t('common:option.edit'), value: 'edit'},
        {name: t(`common:option.${active ? 'deactivate' : 'activate'}`), value: 'toggleActive'},
        {name: t('common:option.delete'), value: 'promptDelete'}
    ];

    const canModify = userPermissions.includes(permissionKeys.MODIFY);
    const canViewSensitive = userPermissions.includes(permissionKeys.VIEW_SENSITIVE);

    const showWhitelistedCertFingerprints = isEditActive || isNotEmptyNorFalsy(whitelistedCertFingerprints);
    const height = isEditActive ? 'auto' : '65vh';

    const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);
    if (isViewLimited && !canModify) {
        return (
            <LimitedTablet id={id} type={'webhook'} name={webhookName} label={t(`webhook:label.name`)}
                           status={status} enabled={active} canModify={canModify} isDisabled={isDisabled}
                           onClose={onClose}
            />
        )
    }

    return (
        <Tablet width={'100rem'} height={height} onClose={onClose} isDisabled={isDisabled}
            closeButtonAriaLabel={t('webhook:option.closeTablet_name', {name: webhookName})}
            header={
                <EditTabletHeader label={t('webhook:label.name')} type={'webhook'} id={id} name={'webhookName'} enabled={active}
                    value={webhookName} canModify={canModify} inputHandler={inputHandler} menuOptions={menuOptions} menuOptionHandler={menuOptionHandler}
                    isEditActive={isEditActive} isDisabled={isDisabled}
                />
            }
            body={
                <>
                    {!isEditActive && (status.code === statusKeys.ERROR || status.code === statusKeys.WARNING) &&
                    <div className="display-item">
                        <ExpandableStatusLog {...status} isDisabled={isDisabled}/>
                    </div>
                    }

                    <div className="display-item">
                        <DefaultEditPanel isActive={isEditActive} isSaveEnabled={isSaveEnabled} onSave={saveEdit} onCancel={cancelEdit}>

                            <ExpandableContent label={t('common:label.settings')} isDisabled={isDisabled}>
                                {isEditActive ?
                                    <>
                                        <div className="display-input">
                                            <Checkbox label={t('webhook:label.historyEnabled')} name={'historyEnabled'} checked={historyEnabled} onClick={toggleHandler}
                                                isDisabled={isDisabled}/>
                                        </div>
                                        <div className="display-input">
                                            <HTMLTextInput label={t('webhook:label.callbackAddress')} name={'callbackAddress'} value={callbackAddress}
                                                onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                                        </div>
                                    </>
                                    :
                                    <div className="settings-table align-top">
                                        <div className="table-row-group">
                                            <SettingsRowValue label={t('webhook:label.callbackAddress')} value={callbackAddress}
                                                isDisabled={isDisabled} isWordWrap isBreakWord/>

                                            <SettingsRowValue label={t('webhook:label.signatureKey')} valueIsItalic={!signatureKey} isDisabled={isDisabled}
                                                value={!!signatureKey?
                                                    <CopySecretLabel copyText={signatureKey} isDisabled={isDisabled}/>
                                                    :
                                                    t('webhook:message.secretAvailableAtCreation')
                                                }
                                            />

                                            {historyEnabled &&
                                            <SettingsRowLabel label={t('webhook:label.historyEnabled')}
                                                isDisabled={isDisabled}/>
                                            }
                                        </div>
                                    </div>
                                }
                            </ExpandableContent>

                            <ExpandableContent label={t('webhook:label.triggers')}>
                                {isEditActive ?
                                    <>
                                        <WebhookEventTriggerTypeDropdown selectedEventTriggerType={eventTriggerType} onEventTriggerTypeSelect={dropdownHandler}
                                            isDisabled={isDisabled} isEdit/>

                                        <SwitchItemContainer id="webhookEventTriggerSwitchContainer" style={{marginTop: '0.125rem', height: '15rem'}}
                                            items={triggers} setItems={setTriggers} filter={{left: {type: eventTriggerType}}}
                                            ariaLabelKey={'EventTrigger'} isDisabled={isDisabled} isRequired/>
                                    </>
                                    :
                                    triggers.map(trigger =>
                                        <Text key={trigger} value={t(`events:trigger.${trigger}`)} isDisabled={isDisabled}/>
                                    )
                                }
                            </ExpandableContent>

                            {showWhitelistedCertFingerprints &&
                            <ExpandableValueList id={'whitelistedCertFingerprints'} label={t('common:label.whitelistedCertFingerprints')}
                                values={whitelistedCertFingerprints} setValues={setWhitelistedCertFingerprints}
                                ariaLabelKey={'WhitelistedCertFingerprint'} isEditActive={isEditActive} isDisabled={isDisabled}/>
                            }
                        </DefaultEditPanel>
                    </div>

                    {!isEditActive && historyEnabled &&
                    <div className="display-item">
                        <ExpandableContent label={t('webhook:label.events')} isDisabled={isDisabled}>
                            <LoadingWrapper isLoading={isLoading}>
                                <WebhookEventView id={id} canViewConfidential={canViewSensitive}/>
                            </LoadingWrapper>
                        </ExpandableContent>
                    </div>
                    }

                    {!isEditActive && isNotEmptyNorFalsy(auditLog) &&
                        <div className="display-item">
                            <ExpandableChangeLogTable label={t('common:label.auditLog')} changeLogs={auditLog}
                                isDisabled={isDisabled}/>
                        </div>
                    }
                </>
            }
        />
    )
}

export default WebhookTablet;
