import React from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {AddButtonHeader} from "../common/Button/Button";
import {applicationFeatures} from "../../utilities/constants";
import {getValues, includesSome, isNotEmptyNorFalsy} from "../../utilities/helperFunctions";
import ClientPoolModel from "../../models/settings/ClientPoolModel";
import {StatusNameDescriptionCell, TableRow} from "../common/CustomTable/CustomTable";
import Text from "../common/Text/Text";
import {permissionKeys} from "../../i18next/keys";
import LimitedTableRow from "../limited/LimitedTableRow";

function ClientPoolTable(props) {
    const {t} = useTranslation(['aria', 'clientPool', 'common']);
    const dispatch = useDispatch();

    const {
        activeId
    } = props;

    const clientPools = useSelector(state => state.clientPoolDetailsMap);
    const canAddClientPool = useSelector(state => {
        return includesSome(state.currentUser.features, [applicationFeatures.ADD_RESOURCE, applicationFeatures.ADD_CLIENT_POOL]);
    });

    function showForm() {
        dispatch(ClientPoolModel.actionCreators.showForm());
    }

    function showTablet(event) {
        const {value} = event.currentTarget.dataset;
        dispatch(ClientPoolModel.actionCreators.showTablet(value));
    }

    const isNotEmpty = isNotEmptyNorFalsy(clientPools);
    if (!canAddClientPool && !isNotEmpty) {
        return null;
    }

    return (
        <div className="display-item">
            <AddButtonHeader aria-label={t('aria:hiddenAssistText.addClientPool')} text={t('clientPool:label.name')}
                canModify={canAddClientPool} onClick={showForm}
            />

            {isNotEmpty &&
            <div className="display-table">
                <div className="table-header-group">
                    <div className="table-header">
                        <label className="label is-bold">
                            {t('common:label.name')}
                        </label>
                    </div>
                    <div className="table-header">
                        <label className="label is-bold">
                            {t('clientPool:label.clients')}
                        </label>
                    </div>
                </div>

                <div className="table-row-group">
                    {getValues(clientPools).map(clientPool =>
                        <ClientPoolRow key={clientPool.id} isActive={clientPool.id === activeId}
                            clientPool={clientPool} showTablet={showTablet}/>
                    )}
                </div>
            </div>
            }
        </div>
    );
}

function ClientPoolRow(props) {
    const {
        clientPool,
        showTablet,
        isActive
    } = props;
    const {
        id,
        name,
        description,
        clientIds,
        status,
        userPermissions
    } = clientPool;

    const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);
    const canModify = userPermissions.includes(permissionKeys.MODIFY);
    if (isViewLimited && !canModify) {
        return (
            <LimitedTableRow id={id} isActive={isActive} onClick={showTablet} cellCount={2}
                nameComponent={<StatusNameDescriptionCell name={name} status={status} description={description}/>}
            />
        )
    }

    return (
        <TableRow value={id} isActive={isActive} onClick={showTablet}>
            <StatusNameDescriptionCell name={name} status={status} description={description}/>

            <div className="table-cell">
                <Text value={clientIds.length}/>
            </div>
        </TableRow>
    );
}

export default ClientPoolTable;
