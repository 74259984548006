import {actionCreator} from "../../utilities/helperFunctions";

class ReduxStateModel {

    static nom = 'ReduxStateModel';
    static actions = ReduxStateModel.buildActions();
    static actionCreators = ReduxStateModel.buildActionCreators(ReduxStateModel.actions);
    static hasLoadedReducer = ReduxStateModel.buildHasLoadedReducer(ReduxStateModel.actions);

    static buildActions() {
        return {
            // REDUX STATE ACTIONS
            SET_HAS_LOADED: 'SET_HAS_LOADED',
            CLEAR_REDUX: 'CLEAR_REDUX'
        }
    }

    static buildActionCreators(actions) {
        return {
            // REDUX STATE ACTION CREATORS
            setHasLoaded: actionCreator(actions.SET_HAS_LOADED, 'dataKey', 'hasLoaded'),
            clearRedux: actionCreator(actions.CLEAR_REDUX)
        }
    }

    static buildHasLoadedReducer(actions) {
        return function (state = {}, action) {
            switch (action.type) {
                case actions.SET_HAS_LOADED: {
                    const {dataKey, hasLoaded = true} = action.payload;

                    if (state[dataKey] !== hasLoaded) {
                        return {
                            ...state,
                            [dataKey]: hasLoaded
                        }
                    }

                    return state;
                }
                default: {
                    return state;
                }
            }
        }
    }
}

export default ReduxStateModel;