import CryptoJS from "crypto-js";

class Encrypto {

    constructor(key, ivSeed) {
        this.key = CryptoJS.enc.Base64.parse(key);
        this.ivSeed = ivSeed;
    }

    encrypt(name, value) {
        const iv = CryptoJS.SHA512(`${name}${this.ivSeed}`);
        const result = CryptoJS.AES.encrypt(
            value,
            this.key,
            {iv}
        );

        return result.ciphertext.toString(CryptoJS.enc.Base64);
    }

    decrypt(name, cipher) {
        const iv = CryptoJS.SHA512(`${name}${this.ivSeed}`);
        const result = CryptoJS.AES.decrypt(
            cipher,
            this.key,
            {iv}
        );

        return result.toString(CryptoJS.enc.Utf8);
    }

    clear() {
        this.key = null;
        this.ivSeed = null;
    }
}

export default Encrypto;