import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {createInputHandler} from "../../utilities/componentFunctions";
import NoticeCommentModel from "../../models/notice/NoticeCommentModel";
import {userCommentTypes, userSettings} from "../../utilities/constants";
import PopupModel from "../../models/scheduler/PopupModel";
import {permissionKeys} from "../../i18next/keys";
import {CommentForm} from "../userComments/UserCommentForm";


function NoticeCommentForm(props) {
    const dispatch = useDispatch();

    const {
        noticeId,
        userNoticeId
    } = props;

    const {
        isNoticeCommentFormActive
    } = useSelector(state => state.componentStates.noticeCommentDisplay);

    const userNotice = useSelector(state => state.userNoticeDetailsMap.get(userNoticeId));
    const noticeCommentForm = useSelector(state => state.componentStates.noticeCommentForm);
    const warnOnComment = useSelector(state => state.userSettingsMap.get(userSettings.LEGAL_HOLD).warnOnComment);
    const isDisabled = props.isDisabled || noticeCommentForm.isDisabled;

    const inputHandler = createInputHandler({
        updateState: updates => dispatch(NoticeCommentModel.componentActionCreators.updateForm(updates)),
        shouldEnable: ({message}) => !!message
    });

    function showCommentForm(event) {
        const {type} = event.currentTarget.dataset;
        dispatch(NoticeCommentModel.actionCreators.showForm({type}));
    }

    function onSubmit(event) {
        const {submit} = event.currentTarget.dataset;
        const dispatchSubmit = () => dispatch(NoticeCommentModel.actionCreators.submitForm(noticeId, userNoticeId, submit, {
            ...noticeCommentForm,
            noticeEventId: userNoticeId,
        }));

        const isAdmin = userNotice.userPermissions.includes(permissionKeys.MANAGE);
        const isAdminNote = noticeCommentForm.type === userCommentTypes.ADMIN_NOTE;
        if (isAdmin && !isAdminNote && warnOnComment) {
            dispatch(PopupModel.actionCreators.showWarning({
                info: {
                    key: 'legalHoldAdminAddCommentWarning'
                },
                buttons: [{
                    titleKey: 'common:option.add',
                    onClick: dispatchSubmit
                }]
            }));
        } else {
            dispatchSubmit();
        }
    }

    function onClose() {
        dispatch(NoticeCommentModel.actionCreators.hideForm());
    }

    return (
        <CommentForm {...props} {...noticeCommentForm} inputHandler={inputHandler} showForm={showCommentForm}
            onSubmit={onSubmit} onClose={onClose} isFormActive={isNoticeCommentFormActive} isDisabled={isDisabled}/>
    )
}

export default NoticeCommentForm;
