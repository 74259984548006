import React from 'react';
import {jobQueueStates, jobStates} from "../../../utilities/constants";
import {IconItem, TextItem} from "../../common/CustomizableCard/CustomizableCard";
import {executionStateIcon, getPriorityIcon, getStatusIconImgContent} from "../../../utilities/iconResolver";
import {getLocaleDateTimeFromUTC, getValues, isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import {executionStateKeys, jobCardOptionKeys, positionKeys, priorityKeys} from "../../../i18next/keys";
import {useTranslation} from "react-i18next";

export function mockValues(t, submittedBy, parameterNames) {

    return {
        id: 'MockValues',
        name: t(`jobCardDesigner:optionName.${jobCardOptionKeys.JOB_NAME}`),
        notes: t(`jobCardDesigner:optionName.${jobCardOptionKeys.JOB_NOTES}`),

        clientName: t(`jobCardDesigner:optionName.${jobCardOptionKeys.CLIENT_NAME}`),
        matterName: t(`jobCardDesigner:optionName.${jobCardOptionKeys.MATTER_NAME}`),
        libraryName: t(`jobCardDesigner:optionName.${jobCardOptionKeys.LIBRARY_NAME}`),
        workflowName: t(`jobCardDesigner:optionName.${jobCardOptionKeys.WORKFLOW_NAME}`),
        engineName: t(`jobCardDesigner:optionName.${jobCardOptionKeys.ENGINE_NAME}`),
        executionProfileName: t(`jobCardDesigner:optionName.${jobCardOptionKeys.EXECUTION_PROFILE_NAME}`),
        resourcePoolName: t(`jobCardDesigner:optionName.${jobCardOptionKeys.RESOURCE_POOL_NAME}`),
        lastUserComment: t(`jobCardDesigner:optionName.${jobCardOptionKeys.LAST_USER_COMMENT}`),

        sessionParameters: parameterNames.map(name => ({name, value: name})),
        startedDate: getLocaleDateTimeFromUTC(Date.now()),
        finishedDate: getLocaleDateTimeFromUTC(new Date().setMinutes(new Date().getMinutes() + 15)),

        submittedBy: submittedBy,
        submittedDate: getLocaleDateTimeFromUTC(Date.now()),

        priority: priorityKeys.LOWEST,
        locked: false,
        hasWarnings: true,
        hasSoftErrors: true,
        executionState: jobStates.running[0],
        percentageComplete: 75,
        currentOperationProcessingSpeedText: '1.50 GB/h'
    };
}

//options is from jobCardDesigner, values is from jobDetails, etc...
export function buildCardItems(t, options, values) {
    const items = {};
    if (!isNotEmptyNorFalsy(options))
        return items;

    const optionTypes = getValues(jobCardOptionKeys);

    getValues(options)
        .filter(option => option && option.position !== positionKeys.NOT_VISIBLE && optionTypes.includes(option.type))
        .forEach((option, index) => {
            const item = getItem(t, index, values, option);
            pushTo(items, option.position, item);
        });

    return items;
}

function getItem(t, key, values, options) {
    const {value, type, size, isBold, isItalic} = options;
    const [valueKey, isIcon] = getTypeOptions(type);
    const val = values[valueKey];

    if (isIcon) {
        return (
            <IconItem key={key} value={val}
                size={size}/>
        )
    }

    switch (type) {
        case jobCardOptionKeys.PARAMETER: {
            let param = (val || []).find(param => param.name === value) || {};
            let paramValue = param.value || '';

            // Check session parameters if we don't have a value from operation parameters
            if (paramValue === '' && Array.isArray(values['sessionParameters'])) {
                const sessionParameter = values['sessionParameters'].find(sessionParameter => sessionParameter.name === value) || {};
                paramValue = sessionParameter.value || '';
            }
            return (
                <TextItem key={key} value={paramValue}
                    size={size} isBold={isBold} isItalic={isItalic}/>
            )
        }
        case jobCardOptionKeys.STATUS_TEXT: {
            let value;
            if (val === executionStateKeys.NOT_STARTED && values.queueState === jobQueueStates.STAGING) {
                value = t(`job:executionState.${values.queueState}`);
            } else {
                value = t(`job:executionState.${val}`);
            }
            return (
                <TextItem key={key} value={value}
                    size={size} isBold={isBold} isItalic={isItalic}/>
            )
        }
        case jobCardOptionKeys.PRIORITY_TEXT: {
            return (
                <TextItem key={key} value={t(`common:priority.${val}`)}
                    size={size} isBold={isBold} isItalic={isItalic}/>
            )
        }
        case jobCardOptionKeys.JOB_NAME: {
            const {name, confidential, locked} = values;

            return (
                <div style={{display: 'flex', flexShrink: '1', minWidth: '0'}} key={key}>
                    {confidential &&
                        <IconItem size={size}
                            value={getStatusIconImgContent(locked ? 'statusLocked' : 'statusUnlocked')}/>
                    }
                    <TextItem size={size} value={name}
                        isBold={isBold} isItalic={isItalic}/>
                </div>
            )
        }
        case jobCardOptionKeys.STATUS_ICONS: {
            return (
                <JobStatusIcons key={key} {...values}
                    size={size} isBold={isBold} isItalic={isItalic}/>
            )
        }
        case jobCardOptionKeys.LAST_USER_COMMENT: {
            let message;
            if (val != null) {
                // message = `${getLocaleDateTimeFromUTC(val.createdDate)}: ${val.message}`;
                message = val.message;
            }
            return (
                <TextItem key={key} value={message}
                    size={size} isBold={isBold} isItalic={isItalic}/>
            )
        }
        default: {
            return (
                <TextItem key={key} value={val}
                    size={size} isBold={isBold} isItalic={isItalic}/>
            )
        }
    }
}

export function JobStatusIcons(props) {
    const {t} = useTranslation(['aria']);
    const {
        queueState,
        executionState,
        priority,
        percentageComplete,
        hidePercentage,
        hasWarnings,
        hasSoftErrors,
        lastUserComment,
        isBold,
        isItalic,
    } = props;

    const priorityImg = getPriorityIcon(priority);
    const hasRunningWarnings = (hasWarnings && !jobStates.completed.includes(executionState));
    const hasBacklogWarnings = jobStates.backlog.includes(executionState) && (hasWarnings);

    const size = props.size.toLowerCase();
    let notificationSize = '1x';
    switch (size) {
        case 'medium':
            notificationSize = '2x';
            break;
        case 'large':
            notificationSize = '3x';
            break;
    }

    let commentIconName;
    if (lastUserComment != null) {
        if (lastUserComment.viewedByUser) {
            commentIconName = 'statusCommentRead'
        } else {
            commentIconName = 'statusCommentUnread'
        }
    }

    return (
        <div style={{display: 'flex', flex: '1', overflow: 'hidden'}}>
            {jobStates.running.includes(executionState) && percentageComplete != null && !hidePercentage &&
                <TextItem value={percentageComplete + '%'}
                    size={size} isBold={isBold} isItalic={isItalic}/>
            }
            {(hasRunningWarnings || hasBacklogWarnings) &&
                <IconItem size={size}>
                    {getStatusIconImgContent('statusWarning')}
                </IconItem>
            }
            {priorityImg &&
                <IconItem size={size}>
                    <img src={priorityImg} alt={t(`aria:hiddenAssistText.${priority}PriorityIcon`)}/>
                </IconItem>
            }
            <IconItem size={size}>
                {executionStateIcon({queueState, executionState, percentageComplete, hasWarnings, hasSoftErrors, size: size.toLowerCase()})}
            </IconItem>
            {commentIconName != null &&
                <IconItem size={size}>
                    {getStatusIconImgContent(commentIconName)}
                </IconItem>
            }
        </div>
    )
}

function pushTo(items, position, item) {
    if (position === positionKeys.MIDDLE) {
        let middle = items[position];
        middle ? middle.push(item) : items[position] = [item];
    } else {
        items[position] = item;
    }
}

function getTypeOptions(type) {
    switch (type) {
        case jobCardOptionKeys.JOB_NAME:
            return ['name', false];
        case jobCardOptionKeys.PARAMETER:
            return ['executionParameters', false];
        case jobCardOptionKeys.SUBMITTED_BY_ICON:
            return ['userIconUrl', true];
        case jobCardOptionKeys.SUBMITTED_BY_TEXT:
            return ['submittedBy', false];
        case jobCardOptionKeys.PRIORITY_TEXT:
            return ['priority', false];
        case jobCardOptionKeys.STATUS_TEXT:
            return ['executionState', false];
        default:
            return [type, false];
    }
}
