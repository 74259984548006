import React, {useEffect, useLayoutEffect, useRef} from 'react';
import './ParameterList.css';
import {getScrollElement, getValues} from '../../utilities/helperFunctions';
import ParameterInput from "./ParameterInput";
import HighlightText from "../userSettings/HighlightOption/HighlightText";
import {useSelector} from "react-redux";
import {SAME_AS_TRIGGERING_DATASET, SAME_AS_TRIGGERING_LEGAL_HOLD, userSettings} from "../../utilities/constants";
import {useTranslation} from "react-i18next";
import Text from "../common/Text/Text";
import {usePrevious} from "../../utilities/hooks";

function ParameterList(props) {
    const {parameters, parameterBlacklist, ...paramProps} = props;

    return (
        <div id="parameterList">
            {getValues(parameters)
                .filter(parameter => parameterBlacklist == null || !parameterBlacklist.has(parameter.name))
                .map(parameter =>
                    <ParameterEntry key={parameter.name} parameter={parameter} {...paramProps} isDisabled={parameter.disabled} />
                )}
        </div>
    )
}

function ParameterEntry(props) {
    const {parameter, showRegex, dispatchParameterUpdate, updateParameter, isDisabled, ...others} = props;
    const {name, friendlyName, value, displayCondition: {displayable}, regex, description} = parameter;

    const ref = useRef();
    const prevDisplayable = usePrevious(displayable);

    useEffect(() => {
        if (typeof dispatchParameterUpdate === 'function') {
            dispatchParameterUpdate(name, value, displayable);
        }
    }, [name, value, displayable]);

    useLayoutEffect(() => {
        if (prevDisplayable != null && displayable) {
            const scrollElement = getScrollElement(ref.current);
            const requiredScrollDistance = ref.current.getBoundingClientRect().bottom - scrollElement.getBoundingClientRect().bottom;
            // Parameter is hidden, scrollIntoView + offset
            if (requiredScrollDistance > 0) {
                scrollElement.scrollTop += requiredScrollDistance + 8;
            }
        }
    }, [displayable]);


    if (displayable === false) {
        return null;
    }

    if (parameter.isSeparatorParameter()) {
        return (
            <div className="parameter-entry" ref={ref}>
                <div className="param-name-value">
                    <div className={"separator-parameter"} ref={ref}>
                        <div className={"separator"}>
                            <label className={"label"}>{friendlyName}</label>
                        </div>

                        {description &&
                            <Text value={description} className={"description"} isWordWrap isDisabled={isDisabled}/>
                        }
                    </div>
                </div>
            </div>
        )
    }

    const allowedValues = parameter.getAllowedValues();
    return (
        <div className="parameter-entry" ref={ref}>
            <div className="param-name-value">
                <div className="param-name-input">
                    <Text id={`label:${parameter.id}`} htmlFor={parameter.id} value={`${friendlyName || name}:`}
                        isWordWrap isDisabled={isDisabled}/>
                </div>

                <div>
                    <ParameterInput aria-labelledby={`label:${parameter.id}`} updateParameter={updateParameter}
                        parameter={parameter} allowedValues={allowedValues} {...others} isDisabled={isDisabled}/>
                </div>
            </div>

            {description &&
                <Text value={description} isWordWrap isDisabled={isDisabled}/>
            }

            {showRegex && regex &&
                <Text value={regex} isWordWrap isDisabled={isDisabled}/>
            }
        </div>
    );
}

export function ParametersTable(props) {
    const {parameters, enableHighlightText, thirdPartyUsers, useUserFromCredential, isDisabled} = props;
    const {t} = useTranslation(['jobSchedule']);

    const {showObjectIds} = useSelector(state => state.userSettingsMap.get(userSettings.TROUBLESHOOT));
    const detailsMap = ['dataset', 'dataRepository', 'thirdPartyService', 'legalHold', 'libraryFile'].reduce((acc, curr) => {
        acc[curr] = useSelector(state => state[`${curr}DetailsMap`]);
        return acc;
    }, {});

    const options = {showObjectIds, thirdPartyUsers, useUserFromCredential};
    const disabled = isDisabled ? ' is-disabled' : '';

    return (
        <table className={'parameter-table' + disabled}>
            <colgroup>
                <col style={{width: "11rem"}}/>
                <col/>
                <col/>
            </colgroup>
            <tbody>
            {getValues(parameters).map((param, index) => {
                const name = param.friendlyName || param.name;
                if (param.isSeparatorParameter()) {
                    const separatorRowStyle = index !== 0 ? {marginTop: "2rem"} : {}
                    return (
                        <tr key={index}>
                            <td colSpan={2} className={"separator-parameter"}>
                                <div className={"separator"} style={separatorRowStyle}>
                                    <Text value={name}/>
                                </div>

                                {param.description &&
                                    <Text value={param.description} className={"description"} isWordWrap isDisabled={isDisabled}/>
                                }
                            </td>
                            <td/>
                        </tr>
                    )
                }

                const protectedValue = getProtectedValue(t, param, detailsMap, options);
                return (
                    <tr key={index}>
                        <td>
                            <span className="label is-ellipsis">
                                <HighlightText text={`${name}:`} isDisabled={!enableHighlightText}/>
                            </span>
                        </td>
                        <td>
                            <span className="label is-ellipsis">
                                <HighlightText text={protectedValue} isDisabled={!enableHighlightText}/>
                            </span>
                        </td>
                        <td>
                            <span className="label is-ellipsis" style={{width: "25rem"}}>
                                <HighlightText text={param.description} isDisabled={!enableHighlightText}/>
                            </span>
                        </td>
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}

function getProtectedValue(t, param, detailsMap, opts) {
    if (param.isDatasetParameter()) {
        const dataset = detailsMap.dataset.get(param.value);
        if (dataset != null) {
            // Display datasetName
            return dataset.getDisplayName();
        } else if (param.value === SAME_AS_TRIGGERING_DATASET) {
            return t(`jobSchedule:option.${SAME_AS_TRIGGERING_DATASET}`);
        }

    } else if (param.isAzureStorageAccountParameter()) {
        const dataRepository = detailsMap.dataRepository.get(param.value);
        if (dataRepository != null) {
            return dataRepository.name;
        }
    } else if (param.isThirdPartyServiceParameter()) {
        const thirdPartyService = detailsMap.thirdPartyService.get(param.value);
        if (thirdPartyService != null) {
            let protectedValue = thirdPartyService.name;

            let user = 0;
            if (opts.thirdPartyUsers != null) {
                user = opts.thirdPartyUsers[param.value];
            } else if (opts.useUserFromCredential && thirdPartyService.userCredential != null) {
                user = thirdPartyService.userCredential.username;
            }
            if (user !== 0 && user != null) {
                protectedValue += ` - ${user}`;
            } else if (user === 0) {
                protectedValue += ` - ${t('thirdPartyService:label.notFound')}`;
            }
            return protectedValue;
        }
    } else if (param.isLegalHoldParameter()) {
        const legalHold = detailsMap.legalHold.get(param.value);
        if (legalHold != null) {
            return legalHold.name;
        } else if (param.value === SAME_AS_TRIGGERING_LEGAL_HOLD) {
            return t(`jobSchedule:option.${SAME_AS_TRIGGERING_LEGAL_HOLD}`);
        }

    } else if (param.isFileParameter()) {
        const libraryFile = detailsMap.libraryFile.get(param.value);
        if (libraryFile != null) {
            return libraryFile.name;
        }

    } else if (param.isFileContentParameter()) {
        const parameterValue = param.getProtectedValue();
        if (parameterValue != null) {
            return parameterValue.split(":")[0];
        }
    } else {
        const userDisplayableValue = param.getUserDisplayableValue();
        if (userDisplayableValue != null) {
            if (opts.showObjectIds && userDisplayableValue !== param.value) {
                return `${userDisplayableValue} (${param.value})`;
            } else {
                return userDisplayableValue;
            }
        }
    }
    return param.getProtectedValue();
}

export default ParameterList;