import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import Form, {FormFooter, FormHeader} from "../common/Form/Form";
import Checkbox from '../common/Checkbox/Checkbox';
import {getSafeClose, onInputChange, warnOnClose} from '../../utilities/componentFunctions';
import TextArea from '../common/TextArea/TextArea';
import {onToggleEnable} from "../common/Checkbox/helpers";
import {shouldEnableLibrary} from "../../utilities/shouldEnableFunctions";
import {updateMode} from "../../utilities/constants";
import LibraryModel from "../../models/library/LibraryModel";
import PopupModel from "../../models/scheduler/PopupModel";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";

class LibraryForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            libraryName: '',
            description: '',
            enabled: true,
            isAddEnabled: false
        };

        this.getSafeClose = getSafeClose('state', ['enabled']).bind(this);
        this.warnOnClose = warnOnClose(this.props.t('library:label.name')).bind(this);

        this.onInputChange = onInputChange({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            isEnabled: 'isAddEnabled',
            shouldEnable: shouldEnableLibrary
        }).bind(this);
        this.onToggleEnable = onToggleEnable({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState
        }).bind(this);
    }

    addLibrary = () => {
        this.props.submitLibrary(this.state);
    };

    render() {

        const {
            t,
            isDisabled
        } = this.props;
        const {
            libraryName,
            description,
            enabled,
            isAddEnabled
        } = this.state;
        const close = this.getSafeClose();

        const nameInputProps = {
            label: t('common:label.name'),
            name: 'libraryName',
            value: libraryName,
            onChange: this.onInputChange,
            isRequired: true,
            isDisabled
        };
        const enabledCheckboxProps = {
            label: t('common:label.enabled'),
            name: 'enabled',
            checked: enabled,
            onClick: this.onToggleEnable,
            isDisabled
        };
        const descriptionProps = {
            label: t('common:label.description'),
            name: 'description',
            value: description,
            onChange: this.onInputChange,
            isDisabled
        };
        
        const footerProps = {
            addText: t('common:button.addLibrary'),
            onCancel: close,
            onAddClick: this.addLibrary,
            isAddEnabled,
            isDisabled
        };

        return (
            <Form onClose={close} isDisabled={isDisabled} closeButtonAriaLabel={t('library:option.closeForm')}
                header={
                    <FormHeader text={t('library:label.name')} iconName={'library'} isDisabled={isDisabled} />
                }
                body={
                    <>
                        <div className="display-input">
                            <HTMLTextInput {...nameInputProps}/>
                        </div>
                        <Checkbox {...enabledCheckboxProps} />
                        <div className="display-input">
                            <TextArea {...descriptionProps}/>
                        </div>
                    </>
                }
                footer={
                    <FormFooter {...footerProps} />
                }
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.componentStates.libraryForm
    }
};

const mapDispatchToProps = dispatch => {
    return {
        submitLibrary: library => dispatch(LibraryModel.actionCreators.submitForm(library)),
        close: () => dispatch(LibraryModel.componentActionCreators.updatePage({isLibraryFormActive: false})),

        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['library', 'common'])(LibraryForm));