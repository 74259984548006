import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import UserServiceModel, {UserServiceApi} from "../../models/user/UserServiceModel";
import {SettingsRowLabel, SettingsRowSeparator, SettingsRowValue} from "../common/CustomTable/CustomTable";
import Tablet, {EditTabletHeader} from "../common/Tablet/Tablet";
import {permissionKeys, statusKeys} from "../../i18next/keys";
import {
    camelCase,
    capitalize,
    getLocaleDateTimeFromUTC,
    getPluralTranslations,
    isNotEmptyNorFalsy,
    switchcaseF,
    translateMinutesToHours
} from "../../utilities/helperFunctions";
import {makeGetEditDetails} from "../../reselect/selectors";
import EditPanel from "../common/EditPanel/EditPanel";
import EditModel from "../../models/scheduler/EditModel";
import {createEditHandler, createFileHandler, createInputHandler} from "../../utilities/componentFunctions";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import {ButtonGroup} from "../common/Button/Button";
import {LdapSearchScopeDropdown} from "../common/Dropdown/Dropdown";
import {createDropdownHandler} from "../common/Dropdown/helpers";
import {ExpandableStatusLog} from "../common/Common";
import {downloadLogs} from "../../utilities/downloadHelper";
import ExpandableContent, {
    ExpandableEditTextArea,
    ExpandableValueList
} from "../common/ExpandableContent/ExpandableContent";
import Checkbox from "../common/Checkbox/Checkbox";
import {createToggleHandler} from "../common/Checkbox/helpers";
import Text from "../common/Text/Text";
import {LinkLoginFormBody, SynchronizationFormBody, SynchronizeUsersFormBody} from "./ldap/LdapUserServiceForm";
import {OidcUserServiceSettings} from "./oidc/OidcUserServiceForm";
import UsersInputTable from "./managed/UsersInputTable";
import {MicrosoftUserServiceFormBody} from "./purview/MicrosoftUserServiceForm";
import RelativityProxyModel from "../../models/relativity/RelativityProxyModel";
import CopySecretLabel from "../common/Text/CopySecretLabel";
import LimitedTablet from "../limited/LimitedTablet";
import SchedulerModel from "../../models/scheduler/SchedulerModel";
import PopupModel from "../../models/scheduler/PopupModel";

const selectEditDetails = makeGetEditDetails();
function selectReduxState(state, props) {
    const userService = state.userServiceDetailsMap.get(props.id);

    const {activeModel, values, isSaveEnabled} = selectEditDetails(state, {model: UserServiceModel.nom});
    const isEditActive = (activeModel === UserServiceModel.nom);

    return {
        ...userService,
        ...values,

        isEditActive,
        isSaveEnabled
    }
}

function UserServiceTablet(props) {
    const {t} = useTranslation(['userService']);
    const dispatch = useDispatch();

    const serviceAccountPasswordRef = useRef({});
    const clientSecretRef = useRef({});

    const isRelativityApplication = useSelector(state => state.schedulerDetails.isRelativityApplication);
    const reduxState = useSelector(state => selectReduxState(state, props));
    const {
        id,
        type,
        name,
        description,
        enabled,
        restrictByUrl,
        allowedUrls,

        whitelistedCertFingerprints,
        wellKnownConfiguration,

        synchronizeUsers,
        synchronizeObjects,
        synchronizeComputers,

        enableLoginLinks,
        loginLinkScope,
        loginLinkAutoExpireInterval,

        secret,

        users,
        status,
        warningStatus,
        userPermissions,
        isEditActive,
        isSaveEnabled
    } = reduxState;

    const {
        isDisabled
    } = useSelector(state => state.componentStates.userServiceTablet);

    const [userServiceSecret, setUserServiceSecret] = useState();
    useEffect(() => {
        if (secret != null) {
            setUserServiceSecret(secret);
        }
    }, [secret]);

    const {
        saveEdit,
        cancelEdit,
        updateEdit,
        setEditSaveEnabled
    } = EditModel.buildDispatchers(dispatch);

    const editHandler = createEditHandler({
        updateEdit,
        setEditSaveEnabled,
        passwordOptions: {
            'serviceAccountPassword': {
                ref: serviceAccountPasswordRef,
                resetNames: ['serviceAccountName', 'domainDn', 'url', 'ldapHost', 'ldapPort', 'userBaseDn', 'computerBaseDn']
            },
            'clientSecret': {
                ref: clientSecretRef,
                resetNames: ['scope', 'usernameClaim', 'wellKnownConfiguration', 'groupClaim', 'clientId', 'environment', 'tenant'],
            }
        },
        shouldEnable: UserServiceModel.validateFormData
    });

    const inputHandler = createInputHandler({
        handler: editHandler
    });
    const dropdownHandler = createDropdownHandler({
        handler: editHandler
    });
    const toggleHandler = createToggleHandler({
        handler: editHandler
    });

    function setUsers(update) {
        const updates = {};
        if (typeof update === 'function') {
            updates.users = update(users);
        } else {
            updates.users = update;
        }
        editHandler(updates);
    }

    function setWhitelistedCertFingerprints(update) {
        const updates = {
            whitelistedCertFingerprints: update(whitelistedCertFingerprints)
        };

        editHandler(updates);
    }

    function setAllowedUrls(update) {
        const updates = {
            allowedUrls: update(allowedUrls)
        };

        editHandler(updates);
    }

    function onClose() {
        dispatch(UserServiceModel.actionCreators.hideTablet())
    }

    function testServiceEdit() {
        dispatch(UserServiceModel.actionCreators.testUserServiceEdit(reduxState));
    }

    function downloadCsv() {
        if (isRelativityApplication) {
            dispatch(RelativityProxyModel.actionCreators.proxyDownload( `/scheduler/users/userService/${props.id}/csv`, null, UserServiceModel.componentActionCreators.updateTablet));
        } else {
            downloadLogs(`/scheduler/users/userService/${props.id}/csv`);
        }
    }

    const uploadCsvInputRef = useRef();
    function uploadCsv() {
        uploadCsvInputRef.current?.click();
    }

    const uploadCsvFileHandler = createFileHandler({
        readAs: 'readAsText',
        onloadstart: function () {
            dispatch(UserServiceModel.componentActionCreators.updateTablet({isDisabled: true}));
        },
        onloadend: async function (event) {
            try {
                const fileData = event.target.result;
                const {data} = await UserServiceApi.putCsv(id, fileData);

                let popupAction = 'showSuccess', popupKey = 'uploadUsers';

                if (data.invalidEntries) {
                    popupAction = 'showWarning';
                    if (data.invalidEntries) {
                        popupKey = 'uploadUsersInvalidEntries'
                    }
                }

                dispatch(PopupModel.actionCreators[popupAction]({
                    info: {
                        key: popupKey, values: data
                    },
                    cancelButton: {
                        title: t('common:option.ok')
                    }
                }));

            } catch (error) {
                dispatch(SchedulerModel.actionCreators.handleResponseError(error));
            } finally {
                dispatch(UserServiceModel.componentActionCreators.updateTablet({isDisabled: false}));
            }
        }
    });

    function menuOptionHandler(event) {
        const {value} = event.currentTarget.dataset

        switchcaseF({
            'edit': () => dispatch(UserServiceModel.actionCreators.startEdit(props.id)),
            'promptDelete': () => dispatch(UserServiceModel.actionCreators.promptDelete(props.id)),
            'toggleActive': () => dispatch(UserServiceModel.actionCreators.toggleEnabled(props.id)),
            'executeUserService': () => dispatch(UserServiceModel.actionCreators.executeUserService(props.id)),
            'downloadCsv': downloadCsv,
            'uploadCsv': uploadCsv,
            'test': () => dispatch(UserServiceModel.actionCreators.testUserService(props.id))
        })()(value)
    }

    const canModify = userPermissions.includes(permissionKeys.MODIFY);
    const shouldEnableTest = isEditActive && UserServiceModel.validateFormData(
        {...reduxState,
            serviceAccountPassword: (serviceAccountPasswordRef.current.value == null ? reduxState.serviceAccountPassword : serviceAccountPasswordRef.current.value),
            clientSecret: (clientSecretRef.current.value == null ? reduxState.clientSecret : clientSecretRef.current.value)}
    );

    const canSyncObjects = UserServiceModel.canTypeSyncObjects(type, wellKnownConfiguration);
    const canSyncUsers = UserServiceModel.canTypeSyncUsers(type, wellKnownConfiguration);
    const canSyncComputers = type === UserServiceModel.types.LDAP;

    const isManagedType = type === UserServiceModel.types.MANAGED;
    const isCustomUserType = UserServiceModel.doesTypeUseCustomUsers(type);

    const showDescription = isEditActive || description;
    const showRestrictByUrl = isEditActive || restrictByUrl;
    const showSyncUsers = canSyncUsers && (isEditActive || synchronizeUsers);
    const showSyncComputers = (canSyncComputers) && (isEditActive || synchronizeComputers);
    const showSyncObjects = canSyncObjects && synchronizeObjects;
    const showSynchronizationSettings = isCustomUserType || showSyncUsers || showSyncObjects || showSyncComputers;

    const showSettings = !isCustomUserType && (type !== UserServiceModel.types.RELATIVITY_IMPERSONATION || !isEditActive);
    const showLoginLinkSettings = UserServiceModel.canTypeUseLoginLinks(type) && type !== UserServiceModel.types.MICROSOFT && (isEditActive || enableLoginLinks);
    const showWhitelistedCertFingerprints = [UserServiceModel.types.LDAP, UserServiceModel.types.OIDC].includes(type) && (isEditActive || isNotEmptyNorFalsy(whitelistedCertFingerprints));


    const menuOptions = [
        {name: t('common:option.edit'), value: 'edit'},
        {name: t(`common:option.${enabled ? 'deactivate' : 'activate'}`), value: 'toggleActive'},
        {name: t('common:option.delete'), value: 'promptDelete'}
    ];
    if (canSyncObjects) {
        menuOptions.push({isSeparator: true});
        menuOptions.push({name: t('common:option.synchronize'), value: 'executeUserService', isDisabled: !enabled || !(showSyncUsers || showSyncObjects || showSyncComputers)});
    }

    const canUploadCsv = UserServiceModel.canTypeUploadCsv(type);
    if (canUploadCsv) {
        menuOptions.push(
            {name: t(`userService:label.uploadCsv`), value: 'uploadCsv', isDisabled: !showSynchronizationSettings}
        );
    }

    const canExportCsv = isCustomUserType || canSyncUsers || canSyncComputers;
    if (canExportCsv) {
        menuOptions.push(
            {name: t(`userService:label.exportCsv`), value: 'downloadCsv', isDisabled: !showSynchronizationSettings}
        );
    }

    const canTestAuth = UserServiceModel.canTypeTestAuth(type);
    if (canTestAuth) {
        menuOptions.push(
            {isSeparator: true},
            {name: t('common:option.test'), value: 'test'}
        );
    }


    let loginLinkExpireAfterMessage;
    if (showLoginLinkSettings && enableLoginLinks && !isNaN(loginLinkAutoExpireInterval)) {
        if (loginLinkAutoExpireInterval === 0) {
            loginLinkExpireAfterMessage = t('userService:message.loginLinkAutoExpireInterval_immediately');
        } else if (loginLinkAutoExpireInterval > 90) {
            loginLinkExpireAfterMessage = getPluralTranslations(t, 'userService:message.loginLinkAutoExpireInterval_hours', {count: translateMinutesToHours(loginLinkAutoExpireInterval)});
        } else {
            loginLinkExpireAfterMessage = getPluralTranslations(t, 'userService:message.loginLinkAutoExpireInterval', {count: loginLinkAutoExpireInterval});
        }
    }

    const height = isEditActive ? 'auto' : '50vh';
    const translationType = UserServiceModel.getTranslationType(type, wellKnownConfiguration);
    const menuName = `userService${capitalize(type)}`;

    const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);
    if (isViewLimited && !canModify) {
        return (
            <LimitedTablet id={id} type={translationType} name={name} label={t(`userService:label.${camelCase(type)}UserService`)}
                           status={status} enabled={enabled} canModify={canModify} isDisabled={isDisabled} description={description}
                           onClose={onClose}
            />
        )
    }

    return (
        <Tablet width={'70rem'} height={height} onClose={onClose} isDisabled={isDisabled}
            closeButtonAriaLabel={t(`userService:option.closeTablet_name${type}`, {name})}
            header={
                <EditTabletHeader label={t(`userService:label.${camelCase(type)}UserService`)} id={id}
                    enabled={enabled} value={name} canModify={canModify} type={translationType} menuName={menuName}
                    menuOptions={menuOptions} menuOptionHandler={menuOptionHandler}
                    isDisabled={isDisabled} name={'name'} inputHandler={inputHandler} isEditActive={isEditActive}
                />
            }
            body={
                <>
                    {(status.code === statusKeys.ERROR || status.code === statusKeys.WARNING) && !isEditActive &&
                        <div className="display-item">
                            <ExpandableStatusLog {...status} isDisabled={isDisabled}/>
                        </div>
                    }
                    {(warningStatus?.code === statusKeys.WARNING) && !isEditActive &&
                        <div className="display-item">
                            <ExpandableStatusLog {...warningStatus} isDisabled={isDisabled}/>
                        </div>
                    }

                    <div className="display-item">
                        <EditPanel isActive={isEditActive}
                            options={
                                <ButtonGroup
                                    buttons={[{
                                        label: t('common:option.cancel'),
                                        onClick: cancelEdit,
                                        isDisabled
                                    }, canTestAuth && {
                                        label: t('common:option.test'),
                                        onClick: testServiceEdit,
                                        isDisabled: isDisabled || !shouldEnableTest
                                    }, {
                                        label: t('common:option.save'),
                                        onClick: saveEdit,
                                        isDisabled: isDisabled || !isSaveEnabled
                                    }]}
                                    style={{
                                        marginTop: '0.75rem'
                                    }}
                                />
                            }
                        >
                           {showDescription &&
                                <ExpandableEditTextArea label={t('common:label.description')} name={'description'}
                                    value={description} onChange={inputHandler} isEditActive={isEditActive} isDisabled={isDisabled}/>
                            }



                            {showRestrictByUrl &&

                                <>
                                    {isEditActive &&
                                        <div className="display-input">
                                            <Checkbox label={t('userService:label.restrictByUrl')}
                                                      name={"restrictByUrl"}
                                                      checked={restrictByUrl} onClick={toggleHandler} isDisabled={isDisabled}/>
                                        </div>
                                    }

                                    {restrictByUrl &&
                                    <ExpandableValueList id={'allowedUrls'} label={t('userService:label.allowedUrls')}
                                                     values={allowedUrls} setValues={setAllowedUrls}
                                                     ariaLabelKey={'AllowedUrls'} isEditActive={isEditActive} isDisabled={isDisabled}/>
                                    }
                                </>
                            }


                            {showSettings &&
                                <ExpandableContent label={t('common:label.settings')} isDisabled={isDisabled}>
                                    {isEditActive ?
                                        <EditUserServiceSettings clientSecretRef={clientSecretRef} {...reduxState}
                                            inputHandler={inputHandler} toggleHandler={toggleHandler} dropdownHandler={dropdownHandler} isDisabled={isDisabled}/>
                                        :
                                        <UserServiceSettings isDisabled={isDisabled} {...reduxState}
                                            secret={userServiceSecret}/>
                                    }
                                </ExpandableContent>
                            }

                            {showSynchronizationSettings &&
                                <ExpandableContent label={t('userService:label.synchronizeTitle')} isDisabled={isDisabled}>
                                    {isEditActive ?
                                        <EditSynchronizationSettings serviceAccountPasswordRef={serviceAccountPasswordRef} {...reduxState}
                                            inputHandler={inputHandler} toggleHandler={toggleHandler} dropdownHandler={dropdownHandler} isDisabled={isDisabled}/>
                                        :
                                        <SynchronizationSettings isDisabled={isDisabled} {...reduxState}/>
                                    }
                                </ExpandableContent>
                            }

                            {showLoginLinkSettings &&
                                <ExpandableContent label={t('userService:label.loginLinks')} isDisabled={isDisabled}>
                                    {isEditActive ?
                                        <LinkLoginFormBody forceLoginLinks={isManagedType} enableLoginLinks={enableLoginLinks} loginLinkScope={loginLinkScope} loginLinkAutoExpireInterval={loginLinkAutoExpireInterval}
                                            inputHandler={inputHandler} toggleHandler={toggleHandler} dropdownHandler={dropdownHandler} isDisabled={isDisabled}/>
                                        :
                                        <>
                                            <div className="settings-table">
                                                <div className="table-row-group">
                                                    <SettingsRowValue label={t('userService:label.loginLinkScope')} value={t(`userService:loginLinkScope.${loginLinkScope}`)}
                                                        isDisabled={isDisabled}/>
                                                </div>
                                            </div>

                                            <Text value={loginLinkExpireAfterMessage} isDisabled={isDisabled}/>
                                        </>
                                    }
                                </ExpandableContent>
                            }

                            {isManagedType && isEditActive &&
                                <ExpandableContent label={t('userService:label.users')} isDisabled={isDisabled}>
                                    <UsersInputTable users={users} setUsers={setUsers} canModify={canModify}
                                        isDisabled={isDisabled}/>
                                </ExpandableContent>
                            }

                            {showWhitelistedCertFingerprints &&
                                <ExpandableValueList id={'whitelistedCertFingerprints'} label={t('common:label.whitelistedCertFingerprints')}
                                    values={whitelistedCertFingerprints} setValues={setWhitelistedCertFingerprints}
                                    ariaLabelKey={'WhitelistedCertFingerprint'} isEditActive={isEditActive} isDisabled={isDisabled}/>
                            }

                        </EditPanel>
                    </div>

                    {canUploadCsv &&
                    <input style={{display: "none"}} type="file" accept=".csv"
                        ref={uploadCsvInputRef} onChange={uploadCsvFileHandler} disabled={isDisabled}/>
                    }
                </>
            }>
        </Tablet>
    )
}

function EditUserServiceSettings (props) {
    const {t} = useTranslation(['userService', 'common'])
    const {type, ldapHost, ldapPort, domainDn, secureLdap, url, isDisabled, inputHandler, toggleHandler} = props;

    return (
        <>
            {type === UserServiceModel.types.UMS &&
            <div className="display-input">
                <HTMLTextInput label={t('userService:label.umsUrl')} name={'url'}
                               value={url} onChange={inputHandler} isDisabled={isDisabled} isRequired/>
            </div>
            }

            {type === UserServiceModel.types.LDAP &&
            <>
                <div className="display-input">
                    <HTMLTextInput label={t('userService:label.domainDn')} name={'domainDn'}
                                   value={domainDn} onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                </div>

                <div className="display-input" style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{width: 'calc(100% - 7.5rem'}}>
                        <HTMLTextInput label={t('userService:label.host')} name={'ldapHost'} placeholder={"127.0.0.1"}
                                       value={ldapHost} onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                    </div>

                    <div style={{width: '5rem'}}>
                        <HTMLTextInput label={t('userService:label.port')} name={'ldapPort'} type={"number"}
                                       value={ldapPort} onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                    </div>
                </div>

                <div className="display-input">
                    <Checkbox label={t('userService:label.secureLdap')} name={"secureLdap"} checked={secureLdap}
                        isDisabled={isDisabled} onClick={toggleHandler}/>
                </div>
            </>
            }

            {type === UserServiceModel.types.OIDC &&
                <OidcUserServiceSettings {...props} showWhitelistedCertFingerprints={false}/>
            }

            {type === UserServiceModel.types.MICROSOFT &&
                <MicrosoftUserServiceFormBody {...props}/>
            }
        </>
    )
}

function UserServiceSettings (props) {
    const {t} = useTranslation(['userService', 'common'])
    const {type, ldapHost, ldapPort, domainDn, secureLdap, url, isDisabled, enableAuthentication,
        restrictByUrl, allowedUrls,
        enableCollections,
        enableAuthorizationCodeFlow, enableJwtAccessToken, linkProfilesWithSyncedUsers,
        scope, usernameClaim, groupClaim, wellKnownConfiguration, oAuthVersion, environment, tenant, secret, id} = props;

    return (
        <div className="settings-table">
            <div className="table-row-group">
                <SettingsRowLabel label={t(`userService:label.${enableAuthentication ? 'authenticationEnabled' : 'authenticationDisabled'}`)} isDisabled={isDisabled}/>

                {enableAuthorizationCodeFlow &&
                    <SettingsRowLabel label={t(`userService:label.authorizationCodeFlow`)} isDisabled={isDisabled}/>
                }

                {enableJwtAccessToken &&
                    <SettingsRowLabel label={t(`userService:label.jwtAccessToken`)} isDisabled={isDisabled}/>
                }

                {linkProfilesWithSyncedUsers &&
                    <SettingsRowLabel label={t(`userService:label.linkProfilesWithSyncedUsers`)} isDisabled={isDisabled}/>
                }


                {enableCollections &&
                    <SettingsRowLabel label={t('userService:label.collectionsEnabled')} isDisabled={isDisabled}/>
                }
                <SettingsRowSeparator/>

                {type === UserServiceModel.types.RELATIVITY_IMPERSONATION &&
                    <>
                        <SettingsRowValue label={t('userService:label.relativityImpersonationServiceId')} value={id}
                                          isDisabled={isDisabled}/>
                        <SettingsRowValue label={t('userService:label.relativityImpersonationServiceSecret')} valueIsItalic={!secret} isDisabled={isDisabled}
                                          value={!!secret ?
                                              <CopySecretLabel copyLabel={t('userService:label.relativityImpersonationServiceCopy')}
                                                  copyText={id+":"+secret} isDisabled={isDisabled}/>
                                              :
                                              t('userService:label.relativityImpersonationServiceSecretAvailableAtCreation')
                                          }
                        />
                    </>
                }

                {type === UserServiceModel.types.UMS &&
                <SettingsRowValue label={t('userService:label.umsUrl')} value={url} isDisabled={isDisabled}/>
                }
                {type === UserServiceModel.types.LDAP &&
                <>
                    <SettingsRowValue label={t('userService:label.host')} value={ldapHost} isDisabled={isDisabled}/>
                    <SettingsRowValue label={t('userService:label.port')} value={ldapPort} isDisabled={isDisabled}/>
                    <SettingsRowValue label={t('userService:label.domainDn')} value={domainDn} isDisabled={isDisabled}/>
                    <SettingsRowValue label={t('userService:label.secureLdap')} value={secureLdap ? t('common:option.yes') : t('common:option.no')} isDisabled={isDisabled}/>
                </>
                }
                {type === UserServiceModel.types.OIDC &&
                    <>
                        <SettingsRowValue label={t(`userService:oidc.GENERIC.wellKnownConfiguration`)} value={wellKnownConfiguration} isDisabled={isDisabled}/>
                        <SettingsRowValue label={t(`userService:oidc.GENERIC.scope`)} value={scope} isDisabled={isDisabled}/>
                        <SettingsRowValue label={t(`userService:oidc.usernameClaim`)} value={usernameClaim} isDisabled={isDisabled}/>
                        <SettingsRowValue label={t(`userService:oidc.groupClaim`)} value={groupClaim} isDisabled={isDisabled}/>
                    </>
                }
                {type === UserServiceModel.types.MICROSOFT &&
                    <>
                        <SettingsRowValue label={t(`userService:oidc.environment`)} value={environment} isDisabled={isDisabled}/>
                        <SettingsRowValue label={t(`userService:oidc.microsoftOAuthVersion`)} value={t(`userService:microsoftOAuthVersion.${oAuthVersion}`)} isDisabled={isDisabled}/>
                        <SettingsRowValue label={t(`userService:oidc.tenant`)} value={tenant} isDisabled={isDisabled}/>
                    </>
                }
            </div>
        </div>
    )
}

function EditSynchronizationSettings (props) {
    const {t} = useTranslation(['userService', 'common'])
    const {inputHandler, toggleHandler, isDisabled, dropdownHandler, type, userBaseDn, userSearchScope, computerBaseDn, computerSearchScope,
        synchronizeUsers, synchronizeComputers, includeGuestUsers, includeInactiveUsers, includeDeletedUsers, usersEligibleLegalHoldAdministrator,
        usersEligibleLegalHoldCustodian, ...attr} = props;

    const isMicrosoftUserService = type === UserServiceModel.types.MICROSOFT;
    const isForceSyncUserService = UserServiceModel.doesTypeUseCustomUsers(type);
    const disabled = isDisabled ? ' is-disabled' : ''

    return (
        <>
            {!isForceSyncUserService &&
                <div style={{marginTop: '0.25rem', marginBottom: '0.5rem'}}>
                    <Checkbox label={t('userService:label.synchronizeUsers')} name={"synchronizeUsers"} checked={synchronizeUsers}
                        isDisabled={isDisabled} onClick={toggleHandler}/>
                </div>
            }

            {isMicrosoftUserService && synchronizeUsers &&
                <>
                    <div className="display-input">
                        <Checkbox label={t('userService:label.includeGuestUsers')} name={"includeGuestUsers"}
                                  checked={includeGuestUsers}
                                  onClick={toggleHandler} isDisabled={isDisabled}/>
                    </div>
                    <div className="display-input">
                        <Checkbox label={t('userService:label.includeInactiveUsers')} name={"includeInactiveUsers"} checked={includeInactiveUsers}
                                  onClick={toggleHandler} isDisabled={isDisabled}/>
                    </div>
                    <div className="display-input">
                        <Checkbox label={t('userService:label.includeDeletedUsers')} name={"includeDeletedUsers"} checked={includeDeletedUsers}
                                  onClick={toggleHandler} isDisabled={isDisabled}/>

                    </div>
                </>
            }

            {synchronizeUsers &&
                <SynchronizeUsersFormBody usersEligibleLegalHoldAdministrator={usersEligibleLegalHoldAdministrator}
                    usersEligibleLegalHoldCustodian={usersEligibleLegalHoldCustodian} toggleHandler={toggleHandler} isDisabled={isDisabled}/>
            }

            {type === UserServiceModel.types.LDAP && synchronizeUsers &&
                <>
                    <div className="display-input">
                        <HTMLTextInput label={t('userService:label.userBaseDn')} name={'userBaseDn'}
                            value={userBaseDn} onChange={inputHandler} isDisabled={isDisabled} isRequired={synchronizeUsers}/>
                    </div>

                    <div className="display-input" style={{display: 'flex', alignItems: 'center'}}>
                        <label className={'label' + disabled}>
                            {t('userService:label.userSearchScope')}:
                        </label>

                        <div style={{margin: '0 0.35rem 0 0.75rem'}}>
                            <LdapSearchScopeDropdown id={"userSearchScopeDropdown"} name={'userSearchScope'} selectedSearchScope={userSearchScope.toString()}
                                onSearchScopeSelect={dropdownHandler} isDisabled={isDisabled} isEdit/>
                        </div>
                    </div>
                </>
            }

            {type === UserServiceModel.types.LDAP &&
                <>
                    <div style={{marginTop: '0.25rem', marginBottom: '0.5rem'}}>
                        <Checkbox label={t('userService:label.synchronizeComputers')} name={"synchronizeComputers"} checked={synchronizeComputers}
                            isDisabled={isDisabled} onClick={toggleHandler}/>
                    </div>

                    {synchronizeComputers &&
                        <>
                            <div className="display-input">
                                <HTMLTextInput label={t('userService:label.computerBaseDn')} name={'computerBaseDn'}
                                    value={computerBaseDn} onChange={inputHandler} isDisabled={isDisabled}
                                    isRequired={synchronizeComputers}/>
                            </div>

                            <div className="display-input" style={{display: 'flex', alignItems: 'center'}}>
                                <label className={'label' + disabled}>
                                    {t('userService:label.computerSearchScope')}:
                                </label>

                                <div style={{margin: '0 0.35rem 0 0.75rem'}}>
                                    <LdapSearchScopeDropdown id={"computerSearchScopeDropdown"}
                                        name={'computerSearchScope'}
                                        selectedSearchScope={computerSearchScope.toString()}
                                        onSearchScopeSelect={dropdownHandler} isDisabled={isDisabled} isEdit/>
                                </div>
                            </div>
                        </>
                    }
                </>
            }

            {!isForceSyncUserService && (synchronizeComputers || synchronizeUsers) &&
                <SynchronizationFormBody {...attr} type={type} inputHandler={inputHandler} isDisabled={isDisabled}/>
            }
        </>
    )
}

function SynchronizationSettings (props) {
    const {t} = useTranslation(['userService', 'common']);
    const {
        type,
        serviceAccountName,
        searchDelay,
        userBaseDn,
        userSearchScope,
        computerBaseDn,
        computerSearchScope,
        objectCount,
        lastSynchronized,
        updateMessage,
        synchronizeUsers,
        synchronizeComputers,
        includeGuestUsers,
        includeInactiveUsers,
        includeDeletedUsers,
        usersEligibleLegalHoldCustodian,
        usersEligibleLegalHoldAdministrator,
        isDisabled
    } = props;

    const isMicrosoftUserService = type === UserServiceModel.types.MICROSOFT;
    const isCustomUsersType = UserServiceModel.doesTypeUseCustomUsers(type);
    const requiresServiceAccount = UserServiceModel.doesTypeRequireServiceAccount(type);

    return (
        <>
            <div className="settings-table">
                <div className="table-row-group">
                    {!isCustomUsersType &&
                        <SettingsRowValue label={t('userService:label.synchronizeUsers')} value={synchronizeUsers ? t('common:option.yes') : t('common:option.no')}
                            isDisabled={isDisabled}/>
                    }

                    {isMicrosoftUserService &&
                        <>

                            <SettingsRowValue label={t('userService:label.includeGuestUsers')} value={includeGuestUsers ? t('common:option.yes') : t('common:option.no')}
                                              isDisabled={isDisabled}/>
                            <SettingsRowValue label={t('userService:label.includeInactiveUsers')} value={includeInactiveUsers ? t('common:option.yes') : t('common:option.no')}
                                              isDisabled={isDisabled}/>
                            <SettingsRowValue label={t('userService:label.includeDeletedUsers')} value={includeDeletedUsers ? t('common:option.yes') : t('common:option.no')}
                                              isDisabled={isDisabled}/>
                        </>
                    }
                </div>
            </div>

            {usersEligibleLegalHoldAdministrator &&
                <Text value={t('userService:label.usersEligibleLegalHoldAdministrator')} isDisabled={isDisabled}/>
            }
            {usersEligibleLegalHoldCustodian &&
                <Text value={t('userService:label.usersEligibleLegalHoldCustodian')} isDisabled={isDisabled}/>
            }

            <div className="settings-table">
                <div className="table-row-group">
                    {type === UserServiceModel.types.LDAP &&
                        <SettingsRowValue label={t('userService:label.synchronizeComputers')} value={synchronizeComputers ? t('common:option.yes') : t('common:option.no')}
                            isDisabled={isDisabled}/>
                    }

                    {!isCustomUsersType &&
                        <>
                            <SettingsRowSeparator/>
                            <SettingsRowValue label={t('userService:label.searchDelay')} value={t('common:label.minutes', {minutes: searchDelay})} isDisabled={isDisabled}/>
                        </>
                    }
                    {requiresServiceAccount &&
                        <SettingsRowValue label={t('userService:label.serviceAccountName')} value={serviceAccountName} isDisabled={isDisabled}/>
                    }

                    {type === UserServiceModel.types.LDAP &&
                    <>
                        {userBaseDn &&
                        <>
                            <SettingsRowValue label={t('userService:label.userBaseDn')} value={userBaseDn} isDisabled={isDisabled}/>
                            <SettingsRowValue label={t('userService:label.userSearchScope')} value={t(`userService:searchScope.${userSearchScope}`)} isDisabled={isDisabled}/>
                        </>
                        }

                        {computerBaseDn &&
                        <>
                            <SettingsRowValue label={t('userService:label.computerBaseDn')} value={computerBaseDn} isDisabled={isDisabled}/>
                            <SettingsRowValue label={t('userService:label.computerSearchScope')} value={t(`userService:searchScope.${computerSearchScope}`)} isDisabled={isDisabled}/>
                        </>
                        }
                    </>
                    }

                    <SettingsRowSeparator/>
                    <SettingsRowValue label={t('userService:label.objectCount')} value={objectCount} isDisabled={isDisabled}/>
                    {lastSynchronized &&
                        <SettingsRowValue label={t('userService:label.lastUpdate')} value={getLocaleDateTimeFromUTC(lastSynchronized)} isDisabled={isDisabled}/>
                    }
                    {updateMessage &&
                        <SettingsRowValue label={t('userService:label.updateMessage')} value={updateMessage} isDisabled={isDisabled}/>
                    }
                </div>
            </div>
        </>
    )
}

export default UserServiceTablet;
