import React from 'react';
import {connect, useSelector} from 'react-redux';
import {permissionKeys} from "../../../i18next/keys";
import ResourcePoolModel from "../../../models/settings/ResourcePoolModel";
import {StatusNameDescriptionCell, TableEnableToggleCell, TableRow} from "../../common/CustomTable/CustomTable";
import {useTranslation} from "react-i18next";
import LimitedTableRow from "../../limited/LimitedTableRow";
import {applicationFeatures} from "../../../utilities/constants";

const LocalResourcePoolRow = props => {
    const {t} = useTranslation(['common']);

    const {
        resourcePoolId,
        name,
        description,
        engineNames,
        remoteEngines,
        showResourcePoolTablet,
        active,
        toggleActive,
        userPermissions,
        status,
        isActive
    } = props;

    const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);
    const canModify = userPermissions.includes(permissionKeys.MODIFY);
    const canViewRemoteEngines = useSelector(state => state.currentUser.features.includes(applicationFeatures.REMOTE_ENGINES));

    if (isViewLimited && !canModify) {
        return (
            <LimitedTableRow id={resourcePoolId} isActive={isActive} onClick={showResourcePoolTablet} cellCount={4}
                             nameComponent={<StatusNameDescriptionCell status={status} name={name} description={description}/>}
                             toggleComponent={<TableEnableToggleCell ariaLabelKey={'LocalResourcePool'} displayName={name} name={resourcePoolId}
                                                               checked={active} onClick={toggleActive} isReadOnly={!canModify}/>}
            />
        )
    }

    return (
        <TableRow value={resourcePoolId} isActive={isActive} onClick={showResourcePoolTablet}>
            <TableEnableToggleCell ariaLabelKey={'LocalResourcePool'} displayName={name} name={resourcePoolId}
                checked={active} onClick={toggleActive} isReadOnly={!canModify}/>

            <StatusNameDescriptionCell style={{maxWidth: '12rem'}}
                status={status}
                name={name}
                description={description}
            />

            <div className="table-cell max-width-20">
                {engineNames.map((name, index) => <label className="label is-ellipsis" key={index}>{name}</label>)}
            </div>
            {canViewRemoteEngines &&
            <div className="table-cell max-width-20">
                {remoteEngines.map((name, index) => <label className="label is-ellipsis" key={index}>{name}</label>)}
            </div>
            }
        </TableRow>
    );
}

const mapStateToProps = (state, ownProps) => {
  const {name, description, active, engineIds, remoteEngineIds, userPermissions, status} = state.resourcePoolDetailsMap.get(ownProps.resourcePoolId);
  const isActive = state.componentStates.resourcePoolDisplay.resourcePoolId === ownProps.resourcePoolId;

  function getName(id) {
    const details = state.engineDetailsMap.get(id);

    if (details != null)
      return details.name;

    return id;
  }

  const engineNames = engineIds.map(getName);
  const remoteEngines = remoteEngineIds.map(getName);

  return {name, description, active, engineNames, remoteEngines, userPermissions, status, isActive};
};

const mapDispatchToProps = dispatch => {
  return {
    showResourcePoolTablet: event => dispatch(ResourcePoolModel.actionCreators.showTablet(event.currentTarget.dataset.value)),
    toggleActive: event => dispatch(ResourcePoolModel.actionCreators.toggleEnabled(event.currentTarget.dataset.name))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocalResourcePoolRow);