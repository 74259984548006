import {all, cancel, fork, takeLeading} from "redux-saga/effects";
import ComponentStateModel from "./ComponentStateModel";
import {tryCatchAndReattemptWrapper} from "../../saga/tryCatchWrapper";

export class SagaRunnable {

    static activationComponent = '';

    constructor() {
        throw new Error('Attempted abstract class implementation. Sagas are abstract.');
    }

    static* run(dispatch) {
        this.dispatch = dispatch;

        yield all([
            // SAGA ACTIVATION LISTENER
            takeLeading(ComponentStateModel.actions.SET_COMPONENT_ACTIVE, function* (action) {
                const {component} = action.payload;

                if (component === this.activationComponent) {
                    if (this.tasks) {
                        yield cancel(this.tasks);
                    }

                    this.tasks = yield fork(tryCatchAndReattemptWrapper, function* () {
                        yield all(this.buildActivationEffects(dispatch));

                    }.bind(this))
                }
            }.bind(this)),
            // SAGA DEACTIVATION LISTENER
            takeLeading(ComponentStateModel.actions.SET_COMPONENT_INACTIVE, function* (action) {
                const {component} = action.payload;

                if (component === this.activationComponent) {
                    if (this.tasks) {
                        yield cancel(this.tasks);
                    }

                    yield all(this.buildDeactivationEffects());
                }
            }.bind(this))
        ])
    }

    static buildActivationEffects() {
        return [];
    }

    static buildDeactivationEffects() {
        return [];
    }
}

export default SagaRunnable;