import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import ApiKeyModel from "../../models/settings/ApiKeyModel";
import {TableLabelCell, TableLabelHeader, TableRow} from "../common/CustomTable/CustomTable";
import {getLocaleDateFromUTC, getValues} from "../../utilities/helperFunctions";
import {policyPrincipalTypeKeys, statusKeys} from "../../i18next/keys";
import {translatedStatusIcons} from "../../utilities/iconResolver";
import Text from "../common/Text/Text";


function ApiKeyTable(props) {
    const {t} = useTranslation(['apiKey', 'common']);
    const dispatch = useDispatch();

    const {
        apiKeys
    } = props

    function showTablet(event) {
        const {value} = event.currentTarget.dataset

        dispatch(ApiKeyModel.actionCreators.showDisplay(value))
    }

    return (
        <div className="display-table">
            <div className="table-header-group">
                <TableLabelHeader
                    label={t('common:label.name')}
                />

                <TableLabelHeader
                    label={t('apiKey:label.id')}
                />

                <TableLabelHeader
                    label={t('apiKey:label.user')}
                />

                <TableLabelHeader
                    label={t('apiKey:label.userType')}
                />

                <TableLabelHeader
                    label={t('apiKey:label.createdDay')}
                />

                <TableLabelHeader
                    label={t('apiKey:label.validityDays')}
                />

                <TableLabelHeader
                    label={t('apiKey:label.lastUsedDay')}
                />

            </div>

            <div className="table-row-group">
                {getValues(apiKeys).map(apiKey =>

                    <ApiKeyRow key={apiKey.id} {...apiKey} showTablet={showTablet}/>
                )}
            </div>
        </div>
    )
}

function ApiKeyRow(props) {
    const {t} = useTranslation(['common']);

    const {
        id,
        name,
        user,
        createdDay,
        validityDays,
        lastUsedDay,
        status,
        showTablet,
        isActive
    } = props;

    return (
        <TableRow value={id} isActive={isActive} onClick={showTablet}>
            <div className="table-cell status-name-description-cell">
                <div style={{display: 'flex', height: '100%'}}>

                    {status.code === statusKeys.ERROR &&
                    <span className="icon" title={t(`common:status.${status.code}`)}>
                        {translatedStatusIcons(status.code)}
                    </span>
                    }

                    {status.code === statusKeys.WARNING &&
                    <span className="icon" title={t(`common:status.${status.code}`)}>
                        {translatedStatusIcons(status.code)}
                    </span>
                    }


                    <div style={{flex: 1, minWidth: 0}}>
                        <Text isBold isEllipsis value={name}/>
                    </div>
                </div>
            </div>

            <TableLabelCell label={id}/>

            <div className="table-cell">
                    <label className="label is-ellipsis" key={user.identifierName}>
                        {user.identifierType === policyPrincipalTypeKeys.BUILTIN ? t(`policy:principalBuiltInType.${user.identifierName}`) : user.identifierName}
                    </label>
            </div>

            <div className="table-cell">
                <label className="label is-italic is-ellipsis" key={user.identifierType}>
                    {t(`policy:principalType.${user.identifierType}`)}
                </label>
            </div>

            <TableLabelCell label={getLocaleDateFromUTC(createdDay*24*3600*1000)}/>

            <TableLabelCell label={validityDays}/>

            <TableLabelCell label={lastUsedDay===0?t(`apiKey:label.na`):getLocaleDateFromUTC(lastUsedDay*24*3600*1000)}/>
        </TableRow>
    )
}

export default ApiKeyTable;
