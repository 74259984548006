import React from 'react';
import {updateGenericValues} from "../../../redux/WizardSlice/wizardSlice";
import {connect} from "react-redux";

import '../../../wizard-general.css';
import {useTranslation} from "react-i18next";
import {createToggleHandler} from "../../../../components/common/Checkbox/helpers";
import {DateFilterOptions} from "./DateFilterOptions";
import {createInputHandler, createValueHandler} from "../../../../utilities/componentFunctions";

import {QuestionTable, QuestionTableDependantRow, QuestionTableRow} from "../../Table/QuestionTable";
import {KeywordsType} from "../../../utilities/constants";
import GenericDropdown from "../../Dropdown/GenericDropdown";
import {createDropdownHandler} from "../../../../components/common/Dropdown/helpers";
import {fileTypeFilter} from "../../Dropdown/FileDropdown/WizardFileDropdown";
import ToggleSwitch from "../../../../components/common/ToggleSwitch/ToggleSwitch";

function AdvancedIngestionSettings (props) {
    const {updateState, files} = props;
    const {t} = useTranslation(['wizard']);

    const {
        deduplicateItems,
        useDateFilter,
        generateDuplicateCustodians,
        keywordsSearch,
        keywordsType,
        keywordsFile
    } = props;



    const questions = [
        {
            question: t('wizard:questions.advancedIngestionSettings.configureAdvancedSettings'),
            dataName: "advancedOptions",
            options: [
                {id: "deduplicateItems", value: t('wizard:questions.advancedIngestionSettings.deduplicateItems'), toggled: deduplicateItems},
                {id: "useDateFilter", value: t('wizard:questions.advancedIngestionSettings.dateFilter'), toggled: useDateFilter},
            ]
        },
        {
            question: t("wizard:questions.advancedIngestionSettings.custodianDeduplication"),
        },
        {
            question: t('wizard:questions.advancedIngestionSettings.keywordsSearch')
        },
        {
            question: t('wizard:questions.advancedIngestionSettings.keywordQuestion')
        }
    ]

    const keywordTypeValues = [
        {value: KeywordsType.RETAIN, name: t("wizard:questions.advancedIngestionSettings.keywords.tagRetain")},
        {value: KeywordsType.EXCLUDE, name: t("wizard:questions.advancedIngestionSettings.keywords.exclude")}
    ]

    const toggleEnable = createToggleHandler({
        handler: updateState
    });
    const valueHandler = createValueHandler({
        handler: updateState
    });
    const dropdownHandler = createDropdownHandler({
        handler: updateState
    });
    const inputHandler = createInputHandler({
        handler: updateState
    })

    const keywordDropdownProps = {
        title: t("wizard:questions.advancedIngestionSettings.keywordsTitle"),
        name: "keywordsType",
        values: [
            {value: KeywordsType.RETAIN, name: t("wizard:questions.advancedIngestionSettings.keywords.tagRetain")},
            {value: KeywordsType.EXCLUDE, name: t("wizard:questions.advancedIngestionSettings.keywords.exclude")}
        ],
        selected: keywordsType,
        onSelected: dropdownHandler
    }

    const keywordsInputProps = {
        id: "keywordFileDropdown",
        name: "keywordsFile",
        value: keywordsFile,
        onChange: dropdownHandler,
        isRequired: keywordsSearch,
        filter: libraryFile => fileTypeFilter(libraryFile, null),
        onFileSelected: dropdownHandler,
        selectedFiles: [keywordsFile],
        files,
        noFileWarning: t('wizard:questions.advancedIngestionSettings.noKeywordsFiles'),
    }

    const update = (event) => {
        const {name, checked} = event.currentTarget.dataset;

        if (name === "deduplicateItems" && checked === "false") {
            toggleEnable({currentTarget: {dataset: {name: "generateDuplicateCustodians", checked: "false"}}, target: {type: "switch"}});
        }

        return toggleEnable(event)
    }

    return (
        <div className={"wizard-step-container"}>

            <QuestionTable label={questions[0].question}>
                <QuestionTableRow component={
                    <ToggleSwitch label={questions[0].options[0].value} name={"deduplicateItems"} checked={deduplicateItems} onClick={update}/>
                } stateName={"deduplicateItems"}/>

                <QuestionTableDependantRow component={
                    <ToggleSwitch label={questions[1].question} name={"generateDuplicateCustodians"} checked={generateDuplicateCustodians} onClick={update} style={{paddingLeft: '1rem'}}/>
                } stateName={"generateDuplicateCustodians"} dependant={deduplicateItems}/>

                <QuestionTableRow component={
                    <ToggleSwitch label={questions[0].options[1].value} name={"useDateFilter"} checked={useDateFilter} onClick={toggleEnable}/>
                } stateName={"useDateFilter"}/>

                <QuestionTableRow component={
                    <ToggleSwitch label={questions[2].question} name={"keywordsSearch"} checked={keywordsSearch} onClick={toggleEnable}/>
                } stateName={"keywordsSearch"}/>
            </QuestionTable>

            {keywordsSearch &&
                <QuestionTable label={questions[3].question}>
                    <div className="table-row align-middle no-hover">
                        <div className="table-cell no-stretch">
                            <label className="label is-small">
                                {t("wizard:questions.advancedIngestionSettings.itemOptions")}
                            </label>
                            <GenericDropdown {...keywordDropdownProps}/>
                        </div>
                    </div>
                </QuestionTable>
            }

            <DateFilterOptions {...props}/>
        </div>
    )
}

function mapStateToProps (state) {
    const {wizardSlice:{
        advancedIngestionSettings: {deduplicateItems, generateDuplicateCustodians, useDateFilter, dateFilterType, dateFilterScope,
            filterBeforeDate, filterAfterDate, dateFilterLevel, dateFilterAction, dateFilterActionName, dateFilterCriteria,
            dateFilterActionScope, keywordsSearch, keywordsType, keywordsFile}}} = state;
    return {
        deduplicateItems,
        useDateFilter,
        dateFilterType,
        dateFilterScope,
        filterBeforeDate,
        filterAfterDate,
        dateFilterLevel,
        dateFilterAction,
        dateFilterActionName,
        dateFilterCriteria,
        dateFilterActionScope,
        generateDuplicateCustodians,
        keywordsSearch,
        keywordsType,
        keywordsFile
    };
}

function mapDispatchToProps (dispatch) {
    return {
        updateState: updates => dispatch(updateGenericValues({stateName: "advancedIngestionSettings", updates: updates}))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedIngestionSettings);