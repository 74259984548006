import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {RestrictedMatterPanel} from "../../panels/MatterPanel";
import {RestrictedClientPanel} from "../../panels/ClientPanel";
import LegalHoldModel from "../../../models/legalhold/LegalHoldModel";
import {legalHoldStateKeys, permissionKeys} from "../../../i18next/keys";


function HoldMatterPane() {
    const dispatch = useDispatch();

    const {
        state,
        clientId,
        matterId,

        userPermissions,
        isDisabled: isFormDisabled
    } = useSelector(state => state.componentStates.legalHoldForm);

    const updateState = updates => dispatch(LegalHoldModel.componentActionCreators.updateForm(updates));
    // Can only modify when DRAFT
    const isDisabled = isFormDisabled || (state !== legalHoldStateKeys.DRAFT) || (userPermissions != null && !userPermissions.includes(permissionKeys.MODIFY));

    return (
        <div className="selectPane">
            <RestrictedClientPanel clientId={clientId} updateState={updateState}
                canAddUnassigned={false} isDisabled={isDisabled}/>

            <RestrictedMatterPanel clientId={clientId} matterId={matterId} updateState={updateState}
                isDisabled={isDisabled}/>
        </div>
    )
}

export default HoldMatterPane;
