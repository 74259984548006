import ReduxModel from "../generics/ReduxModel";

class WorkflowBuilderOperationFilter extends ReduxModel {

    constructor(model={}) {
        super();
        this.searchText = model.searchText || '';
        this.forceUpdate(model);
    }
}

export default WorkflowBuilderOperationFilter;
