import {useTranslation} from "react-i18next";
import {getEntries, includesSome, isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import {applicationFeatures} from "../../../utilities/constants";
import {AddButtonHeader} from "../../common/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import React from "react";
import UserServiceModel from "../../../models/user/UserServiceModel";
import UserServiceRow from "../UserServiceRow";
import {TableLabelHeader} from "../../common/CustomTable/CustomTable";

const LdapUserServiceTable = () => {
    const {t} = useTranslation(['aria', 'userService', 'common']);
    const dispatch = useDispatch();

    const canAddUserService = useSelector(state => includesSome(state.currentUser.features, [applicationFeatures.ADD_RESOURCE, applicationFeatures.MODIFY_USER_SERVICES]));
    const ldapUserServiceIds = getEntries(useSelector(state => state.userServiceDetailsMap))
        .filter(([,userService]) => userService.type === UserServiceModel.types.LDAP)
        .map(([id]) => id);

    function showLdapUserServiceForm() {
        dispatch(UserServiceModel.actionCreators.showForm({type: UserServiceModel.types.LDAP}));
    }

    const isNotEmpty = isNotEmptyNorFalsy(ldapUserServiceIds);
    if (!canAddUserService && !isNotEmpty) {
        return null;
    }

    return (
        <div className="display-item">
            <AddButtonHeader aria-label={t('aria:hiddenAssistText.addLdapUserService')} text={t('userService:label.ldapUserServiceTitle')}
                canModify={canAddUserService} onClick={showLdapUserServiceForm}
            />

            {isNotEmpty &&
                <div className="display-table">
                    <div className="table-header-group">
                        <div className="table-header"/>
                        <TableLabelHeader label={t('common:label.name')} style={{width: '20rem'}}/>

                        <div className="table-header">
                            <label className="label is-bold">
                                {t('userService:label.synchronizeUsers')}
                            </label>
                        </div>

                        <div className="table-header">
                            <label className="label is-bold">
                                {t('userService:label.synchronizeComputers')}
                            </label>
                        </div>

                    <div className="table-header">
                        <label className="label is-bold">
                            {t('userService:label.serviceAccountName')}
                        </label>
                    </div>
                    <div className="table-header">
                        <label className="label is-bold">
                            {t('userService:label.searchDelay_detailed')}
                        </label>
                    </div>

                    <TableLabelHeader label={t('userService:label.enableSso')}/>
                </div>

                    <div className="table-row-group">
                        {ldapUserServiceIds.map(id =>
                            <UserServiceRow key={id} type={UserServiceModel.types.LDAP} userServiceId={id}/>
                        )}
                    </div>
                </div>
            }
        </div>
    );
};

export default LdapUserServiceTable;
