import {useDispatch, useSelector} from "react-redux";
import ThirdPartyServiceModel from "../../models/thirdparty/ThirdPartyServiceModel";
import {useTranslation} from "react-i18next";
import {FormElementRow} from "../common/CustomTable/CustomTable";
import {ListDropdown, UserServiceDropdown} from "../common/Dropdown/Dropdown";
import {getValues, lowerCaseFirstLetter} from "../../utilities/helperFunctions";
import React, {useState} from "react";
import ExpandableContent from "../common/ExpandableContent/ExpandableContent";
import {useDropdownHandler, useInputHandler, useToggleHandler, useUpdateHandler} from "../../utilities/formHooks";
import {createCloseHandler} from "../../utilities/componentFunctions";
import PopupModel from "../../models/scheduler/PopupModel";
import {ValueList} from "../common/InputList/InputList";
import Form, {FormFooter, FormHeader} from "../common/Form/Form";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import Checkbox from "../common/Checkbox/Checkbox";
import TextArea from "../common/TextArea/TextArea";


function ThirdPartyServiceForm(props) {
    const {t} = useTranslation(['thirdPartyService', 'common']);
    const dispatch = useDispatch();

    const {
        initialState,
        FormBody
    } = props;
    const {
        type,
        isDisabled
    } = useSelector(state => state.componentStates.thirdPartyServiceForm);

    const [state, setState] = useState(initialState);
    const updateHandler = useUpdateHandler({
        updateState: setState
    });
    const inputHandler = useInputHandler({
        updateHandler
    });
    const toggleHandler = useToggleHandler({
        updateHandler
    });
    const dropdownHandler = useDropdownHandler({
        updateHandler
    });
    function setWhitelistedCertFingerprints(update) {
        setState(prev => {
            const newState = {...prev};
            if (typeof update === 'function') {
                newState.whitelistedCertFingerprints = update(prev.whitelistedCertFingerprints);
            } else {
                newState.whitelistedCertFingerprints = update;
            }
            return newState;
        });
    }

    function onSubmit() {
        dispatch(ThirdPartyServiceModel.actionCreators.submitForm(state));
    }

    const camelCaseType = lowerCaseFirstLetter(type);
    const onClose = createCloseHandler({
        popupKey: `${camelCaseType}Discard`,
        state,
        initialState,
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        close: () => dispatch(ThirdPartyServiceModel.actionCreators.hideForm())
    });

    const isAddEnabled = ThirdPartyServiceModel.validateFormData(state);

    return (
        <Form onClose={onClose} isDisabled={isDisabled} closeButtonAriaLabel={t(`thirdPartyService:option.closeForm${type}`)}
            header={
                <FormHeader text={t(`thirdPartyService:label.${camelCaseType}`)} iconName={camelCaseType}
                    isDisabled={isDisabled}/>
            }
            body={
                <>
                    <div className="display-input">
                        <HTMLTextInput label={t('common:label.name')} name={'serviceName'} value={state.serviceName}
                            onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                    </div>

                    <div className="display-input">
                    <Checkbox label={t('common:label.enabled')} name={'enabled'} checked={state.enabled}
                              onClick={toggleHandler} isDisabled={isDisabled}/>
                    </div>

                    <div className="display-input">
                        <TextArea label={t('common:label.description')} name={'description'} value={state.description}
                            onChange={inputHandler} isDisabled={isDisabled}/>
                    </div>

                    <FormBody thirdPartyService={state} inputHandler={inputHandler} toggleHandler={toggleHandler}
                        dropdownHandler={dropdownHandler} setWhitelistedCertFingerprints={setWhitelistedCertFingerprints}
                        setState={setState} isDisabled={isDisabled}/>
                </>
            }
            footer={
                <FormFooter addText={t('thirdPartyService:option.addService')} onAddClick={onSubmit}
                    onCancel={onClose} isAddEnabled={isAddEnabled} isDisabled={isDisabled}
                />
            }
        />
    )
}

export function ThirdPartyServiceFormBody(props) {
    const {t} = useTranslation(['thirdPartyService']);

    const {
        thirdPartyService,
        allowedAuthenticationScopes,
        allowedAuthenticationMethods,
        authenticationServiceType,
        filterFunc,

        FormBody,
        inputHandler,
        toggleHandler,
        dropdownHandler,
        setWhitelistedCertFingerprints,
        setState,
        isDisabled
    } = props;

    const viewableSettings = ThirdPartyServiceModel.getViewableSettings(thirdPartyService);

    const showUserServices = [ThirdPartyServiceModel.AuthenticationMethod.OIDC_AUTHORIZATION_CODE,
        ThirdPartyServiceModel.AuthenticationMethod.OIDC_CLIENT_CREDENTIALS].includes(thirdPartyService.authenticationMethod);

    return (
        <>
            {viewableSettings.defaultSettings &&
            <div className="display-input">
                <ExpandableContent label={t('thirdPartyService:label.defaultSettings')} isDisabled={isDisabled}>
                    <div className="display-input">
                        <Checkbox label={t('thirdPartyService:common.availableByDefault')} name={"availableByDefault"}
                            checked={thirdPartyService.availableByDefault} onClick={toggleHandler} isDisabled={isDisabled}/>
                    </div>
                </ExpandableContent>
            </div>
            }

            {viewableSettings.authenticationSettings &&
            <div className="display-input">
                <ExpandableContent label={t('thirdPartyService:label.authenticationSettings')} isDisabled={isDisabled}>
                    <div className="forms-table">
                        <div className="table-row-group">
                            <FormElementRow label={t('thirdPartyService:label.scope')} isDisabled={isDisabled}
                                element={
                                    <AuthenticationScopeDropdown value={thirdPartyService.authenticationScope} onItemSelect={dropdownHandler}
                                        allowedItems={allowedAuthenticationScopes} isDisabled={isDisabled} isRequired/>
                                }/>

                            <FormElementRow label={t('thirdPartyService:label.method')} isDisabled={isDisabled}
                                element={
                                    <AuthenticationMethodDropdown value={thirdPartyService.authenticationMethod} onItemSelect={dropdownHandler}
                                        allowedItems={allowedAuthenticationMethods} isDisabled={isDisabled} isRequired/>
                                }/>

                            {showUserServices &&
                                <FormElementRow label={t('thirdPartyService:label.service')} isDisabled={isDisabled}
                                    element={
                                        <UserServiceDropdown type={authenticationServiceType} name={'authenticationServiceId'}
                                            value={thirdPartyService.authenticationServiceId} onItemSelect={dropdownHandler}
                                            filterFunc={filterFunc} isDisabled={isDisabled} isRequired/>
                                    }/>
                            }
                        </div>
                    </div>
                </ExpandableContent>
            </div>
            }

            {FormBody &&
            <div className="display-input">
                <FormBody thirdPartyService={thirdPartyService} inputHandler={inputHandler} toggleHandler={toggleHandler}
                    dropdownHandler={dropdownHandler} setState={setState} isDisabled={isDisabled}/>
            </div>
            }

            {viewableSettings.whitelistedCertFingerprints &&
            <div className="display-input">
                <ValueList id={'whitelistedCertFingerprints'} label={t('common:label.whitelistedCertFingerprints')}
                    ariaLabelKey={'WhitelistedCertFingerprint'} values={thirdPartyService.whitelistedCertFingerprints}
                    setValues={setWhitelistedCertFingerprints} isDisabled={isDisabled}/>
            </div>
            }
        </>
    )
}

function AuthenticationScopeDropdown(props) {
    const {t} = useTranslation(['thirdPartyService', 'aria']);

    const {
        allowedItems,
        ...rest
    } = props;

    const items = getValues(ThirdPartyServiceModel.AuthenticationScope)
        .filter(type => !Array.isArray(allowedItems) || allowedItems.includes(type))
        .map(type => ({name: t(`thirdPartyService:authenticationScope.${type}`), value: type}));

    return (
        <ListDropdown id={'authenticationScopeDropdown'} name={'authenticationScope'}
            aria-label={t('aria:hiddenAssistText.authenticationScope')} items={items} {...rest}/>
    )
}

function AuthenticationMethodDropdown(props) {
    const {t} = useTranslation(['thirdPartyService', 'aria']);

    const {
        allowedItems=[],
        ...rest
    } = props;

    const items = getValues(ThirdPartyServiceModel.AuthenticationMethod)
        .filter(type => allowedItems.includes(type))
        .map(type => ({name: t(`thirdPartyService:authenticationMethod.${type}`), value: type}));

    return (
        <ListDropdown id={'authenticationMethodDropdown'} name={'authenticationMethod'}
            aria-label={t('aria:hiddenAssistText.authenticationMethod')} items={items} {...rest}/>
    )
}

export default ThirdPartyServiceForm;
