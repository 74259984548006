import React, {useCallback, useState} from "react";
import './Parameter.sass';
import {useTranslation} from "react-i18next";
import {buildClassName} from "../../utilities/helperFunctions";
import Form from "../common/Form/Form";
import {Button} from "../common/Button/Button";
import {DataRepositoryDropdown} from "../common/Dropdown/Dropdown";
import {datasetType} from "../../utilities/constants";
import {FormElementRow} from "../common/CustomTable/CustomTable";
import InPlaceFileExplorer from "../dataRepository/InPlaceFileExplorer";
import ParameterModel from "../../models/library/ParameterModel";
import {permissionKeys} from "../../i18next/keys";
import {getInvalidMessageSpan} from "../common/InvalidMessageSpan";


function ServerFileParameter(props) {
    const {t} = useTranslation(['common']);
    const {
        id,
        value,
        parameter,
        updateParameter,

        invalidMessage,
        hideInvalidMessage,
        requiredMessage,

        isInvalid,
        isDisabled
    } = props;

    const [isFileExplorerActive, setIsFileExplorerActive] = useState();
    function toggleFileBrowserActive() {
        setIsFileExplorerActive(v => !v);
    }

    const _updateParameter = useCallback(updates => {
        updateParameter(parameter.name, updates);
    }, [parameter.name, updateParameter]);

    const className = buildClassName(
        'file is-fullwidth is-small is-right',
        isInvalid && 'is-invalid',
        isDisabled && 'is-disabled'
    );

    let chooseTitle;
    if (parameter.parameterType === ParameterModel.Type.SERVER_FOLDER) {
        chooseTitle = t('common:option.chooseFolder');
    } else {
        chooseTitle = t('common:option.chooseFile');
    }

    const allowedFileExtensions = (parameter.allowedFileExtensions || []).filter(v => v).join(';');

    const invalid = isInvalid && !isDisabled;

    const {
        combinedAriaLabelledBy,
        invalidMessageSpan
    } = getInvalidMessageSpan({
        id,
        isInvalid: invalid,
        invalidMessage,
        requiredMessage
    });

    return (
        <>
            <div>
                <div id={id} className={className} style={{height: 'fit-content', minWidth: 0}}
                    data-name={parameter.name} onClick={toggleFileBrowserActive}
                    aria-invalid={invalid} aria-labelledby={combinedAriaLabelledBy}
                >
                    <label className="file-label">
                        <input className="file-input" disabled/>
                        <span className="label file-choose no-wrap">
                        {chooseTitle}
                    </span>
                        <span className={'file-name'}>
                        {value}
                    </span>
                    </label>
                </div>

                {!hideInvalidMessage && invalidMessageSpan}
            </div>

            {isFileExplorerActive &&
                <FileExplorerForm label={parameter.friendlyName || parameter.name} type={parameter.parameterType}
                    dataRepositoryId={parameter.dataRepositoryId} allowedDataRepositoryIds={parameter.allowedDataRepositoryIds}
                    allowedFileExtensions={allowedFileExtensions} value={value} updateState={_updateParameter}
                    onClose={toggleFileBrowserActive} isDisabled={isDisabled}/>
            }
        </>
    )
}

function FileExplorerForm(props) {
    const {t} = useTranslation(['common', 'dataset']);
    const {
        label,
        type,
        dataRepositoryId,
        allowedDataRepositoryIds,
        allowedFileExtensions,
        value,
        updateState,
        onClose,

        isDisabled
    } = props;

    const [file, setFile] = useState({path: value});
    const [dataRepoId, setDataRepoId] = useState(dataRepositoryId);

    function setDataRepositoryId(event) {
        const {value} = event.currentTarget.dataset;
        setDataRepoId(value);
    }

    function setPath(file) {
        setFile(file || {path: ''});
    }

    function onConfirm() {
        updateState({value: file?.path, dataRepositoryId: dataRepoId});
        onClose();
    }

    const includeFiles = type === ParameterModel.Type.SERVER_FILE;
    const isConfirmDisabled = (includeFiles && file?.directory) || !dataRepoId || !file?.path || isDisabled;

    return (
        <Form id="fileExplorerForm" onClose={onClose} width={'55rem'}
            isDisabled={isDisabled} closeButtonAriaLabel={t('dataset:option.closeServerFileForm')}
            header={
                <h2 className={'subtitle is-5 is-bold' + (isDisabled ? ' is-disabled' : '')}>
                    {label}
                </h2>
            }
            body={
                <>
                    <div className="forms-table display-input">
                        <div className="table-row-group">
                            <FormElementRow label={t('dataset:label.repository')} isDisabled={isDisabled}
                                element={
                                    <DataRepositoryDropdown type={datasetType.IN_PLACE} selectedDataRepositoryId={dataRepoId}
                                        onDataRepositorySelect={setDataRepositoryId} permissionFilter={[permissionKeys.SUBMIT_JOB, permissionKeys.STAGE_JOB]}
                                        allowedDataRepositoryIds={allowedDataRepositoryIds} autoSelect isRequired isDisabled={isDisabled}/>
                                }/>
                        </div>
                    </div>

                    <div className="display-input">
                        <InPlaceFileExplorer dataRepositoryId={dataRepoId} path={file?.path} setPath={setPath}
                            allowedFileExtensions={allowedFileExtensions} height={'32rem'} includeFiles={includeFiles}
                            isDisabled={isDisabled}/>
                    </div>
                </>
            }
            footer={
                <div className="button-group">
                    <Button label={t('common:option.cancel')} onClick={onClose} isDisabled={isDisabled}/>
                    <Button label={t('common:option.ok')} onClick={onConfirm} isDisabled={isConfirmDisabled}/>
                </div>
            }
        />
    )
}

export default ServerFileParameter;
