import ThirdPartyServiceModel from "../../../models/thirdparty/ThirdPartyServiceModel";
import ThirdPartyServiceForm, {ThirdPartyServiceFormBody} from "../ThirdPartyServiceForm";
import {useTranslation} from "react-i18next";
import React from "react";
import ExpandableContent from "../../common/ExpandableContent/ExpandableContent";
import {InputTableV2} from "../../common/InputTable/InputTable";

const initialState = {
    type: ThirdPartyServiceModel.Type.ELASTICSEARCH,
    serviceName: '',
    enabled: true,
    description: '',
    whitelistedCertFingerprints: [],

    url: '',
    elasticProperties: [
        [{value: 'cluster.name'}, {value: ''}],
        [{value: 'nuix.transport.hosts'}, {value: ''}],
        [{value: 'index.number_of_shards'}, {value: '2'}],
        [{value: 'index.number_of_replicas'}, {value: '0'}],
        [{value: 'xpack.security.enabled'}, {value: 'true'}],
        [{value: 'xpack.security.user'}, {value: ''}],
        [{value: 'xpack.security.http.ssl.enabled'}, {value: 'true'}],
        [{value: 'xpack.security.http.ssl.certificate_authorities'}, {value: '/example/certs/ca.crt'}],
    ],

    authenticationScope: ThirdPartyServiceModel.AuthenticationScope.SERVICE,
    authenticationMethod: ThirdPartyServiceModel.AuthenticationMethod.SECRET_PROPERTIES,
    authenticationServiceId: null
};
const allowedAuthenticationMethods = [
    ThirdPartyServiceModel.AuthenticationMethod.SECRET_PROPERTIES,
    ThirdPartyServiceModel.AuthenticationMethod.NONE
];
const elasticPropertyDefaultRow = [{value: ''}, {value: ''}];

function ElasticsearchServiceForm() {
    return (
        <ThirdPartyServiceForm initialState={initialState}
            FormBody={ElasticsearchServiceFormBody}/>
    )
}

export function ElasticsearchServiceFormBody(props) {
    return (
        <ThirdPartyServiceFormBody {...props} FormBody={FormBody}
            allowedAuthenticationMethods={allowedAuthenticationMethods}/>
    )
}

function FormBody(props) {
    const {t} = useTranslation(['thirdPartyService']);
    const {
        thirdPartyService,
        setState,
        isDisabled
    } = props;

    function setElasticProperties(updateCb) {
        setState(state => ({
            ...state,
            elasticProperties: updateCb(state.elasticProperties)
        }));
    }

    return (
        <ExpandableContent label={t('thirdPartyService:label.elasticsearchSettings')} isDisabled={isDisabled}>
            <InputTableV2 ariaLabelKey={'Property'} label={t('thirdPartyService:label.properties')}
                headers={[{value: t('thirdPartyService:header.name')}, {value: t('thirdPartyService:header.value')}]}
                defaultRow={elasticPropertyDefaultRow} inputTable={thirdPartyService.elasticProperties}
                setInputTable={setElasticProperties} isDisabled={isDisabled}/>
        </ExpandableContent>
    )
}

export default ElasticsearchServiceForm;
