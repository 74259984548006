import {configureStore} from '@reduxjs/toolkit'

import useCaseSlice from "../components/Steps/UseCaseStep/useCaseSlice";
import workflowHandlerSlice from "./WorkflowHandler/workflowHandlerSlice";
import wizardSlice from "./WizardSlice/wizardSlice";

export default configureStore({
    reducer: {
        useCaseSlice: useCaseSlice,
        workflowHandlerSlice: workflowHandlerSlice,
        wizardSlice: wizardSlice
    }
})