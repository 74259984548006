import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {userSettings} from "../../../utilities/constants";
import {getValues} from "../../../utilities/helperFunctions";


function isTextValid(text) {
    return typeof text === 'string' || (Array.isArray(text) && text.every(s => typeof s === 'string'));
}

function HighlightText(props) {
    const {text, isDisabled} = props

    const {disableRegex} = useSelector(state => state.schedulerDetails);
    const highlightOptions = useSelector(state => {
        return props.highlightOptions || state.userSettingsMap.get(userSettings.HIGHLIGHTS)
    });

    const [resultArray, setResultArray] = useState(text)
    useEffect(() => {
        // Ensure text is a string or array of strings
        // Ensure at least one highlight options enabled
        if (disableRegex || isDisabled || !isTextValid(text) || getValues(highlightOptions).every(opt => !opt.enabled)) {
            setResultArray(text)
            return
        }

        // async behavior, use flag to abort callback if component unmounts before async finishes
        let isMounted = true;
        window.startHighlightWorker({
            text,
            highlightOptions,
            callback: res => {
                if (isMounted) {
                    setResultArray(convertWorkerResultToJsx(res))
                }
            }
        });

        return () => {
            isMounted = false;
        }
    }, [text, highlightOptions, isDisabled])

    return (resultArray != null ? resultArray : null);
}

function convertWorkerResultToJsx(result) {

    return result
        .map(_res => {

            if (typeof _res === 'object') {
                return (
                    <mark key={_res.key} style={_res.style}>
                        {_res.value}
                    </mark>
                )
            }

            return _res
        })
}

export default HighlightText
