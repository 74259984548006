import ThirdPartyServiceModel from "../../../models/thirdparty/ThirdPartyServiceModel";
import ThirdPartyServiceForm, {ThirdPartyServiceFormBody} from "../ThirdPartyServiceForm";
import {useTranslation} from "react-i18next";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import React from "react";
import ExpandableContent from "../../common/ExpandableContent/ExpandableContent";

const initialState = {
    type: ThirdPartyServiceModel.Type.NLP,
    serviceName: '',
    enabled: true,
    description: '',
    whitelistedCertFingerprints: [],

    url: '',
    feedsApiUrl: '',
    jobControlUrl: '',

    authenticationScope: ThirdPartyServiceModel.AuthenticationScope.SERVICE,
    authenticationMethod: ThirdPartyServiceModel.AuthenticationMethod.API_KEY,
    authenticationServiceId: null
};
const allowedAuthenticationMethods = [
    ThirdPartyServiceModel.AuthenticationMethod.API_KEY
];

function NlpServiceForm() {
    return (
        <ThirdPartyServiceForm initialState={initialState} FormBody={NlpServiceFormBody}/>
    )
}

export function NlpServiceFormBody(props) {
    return (
        <ThirdPartyServiceFormBody {...props} FormBody={FormBody}
            allowedAuthenticationMethods={allowedAuthenticationMethods}/>
    )
}

function FormBody(props) {
    const {t} = useTranslation(['thirdPartyService']);
    const {
        thirdPartyService,
        inputHandler,
        isDisabled
    } = props;

    return (
        <ExpandableContent label={t('thirdPartyService:label.nlpSettings')} isDisabled={isDisabled}>
            <div className="display-input">
                <HTMLTextInput label={t('thirdPartyService:label.url')} name={'url'}
                               value={thirdPartyService.url} onChange={inputHandler} isDisabled={isDisabled}
                               isRequired/>
            </div>
            <div className="display-input">
                <HTMLTextInput label={t('thirdPartyService:label.feedsApiUrl')} name={'feedsApiUrl'}
                               value={thirdPartyService.feedsApiUrl} onChange={inputHandler} isDisabled={isDisabled}/>
            </div>
            <div className="display-input">
                <HTMLTextInput label={t('thirdPartyService:label.jobControlUrl')} name={'jobControlUrl'}
                               value={thirdPartyService.jobControlUrl} onChange={inputHandler} isDisabled={isDisabled}/>
            </div>
        </ExpandableContent>


    )
}

export default NlpServiceForm;
