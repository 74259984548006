import React, {useCallback, useState} from "react";
import SelectWorkflowPane from "../../job/panes/SelectWorkflowPane";
import {useDispatch} from "react-redux";
import {useAsyncEffect} from "../../../utilities/hooks";
import SchedulerModel from "../../../models/scheduler/SchedulerModel";
import {LibraryApi} from "../../../models/library/LibraryModel";
import {WorkflowTemplateApi} from "../../../models/library/WorkflowTemplateModel";

export function useParameterFilters(getDetails) {
    const dispatch = useDispatch();
    const [objectIds, setObjectIds] = useState(new Set());
    const [isLoading, setIsLoading] = useState(true);

    useAsyncEffect(async () => {
        try {
            setIsLoading(true);
            const res = await getDetails();
            if (res != null) {
                setObjectIds(new Set(res.data.map(obj => obj.id)));
            }
        } catch (error) {
            dispatch(SchedulerModel.actionCreators.handleResponseError(error));
        } finally {
            setIsLoading(false);
        }
    }, [getDetails]);

    const filter = useCallback(obj => objectIds.has(obj.id), [objectIds]);
    return [filter, isLoading];
}

function ParameterWorkflowPane(props) {
    const {
        jobState,
        requiredParameterTypes,
        forbiddenParameterTypes
    } = props;

    const [libraryFilter, isLoadingLibraries] = useParameterFilters(useCallback(() => {
        return LibraryApi.getDetails(false, requiredParameterTypes, forbiddenParameterTypes);
    }, []));
    const [workflowFilter, isLoadingWorkflows] = useParameterFilters(useCallback(() => {
        if (jobState.libraryId) {
            return WorkflowTemplateApi.getDetails(jobState.libraryId, false, requiredParameterTypes, forbiddenParameterTypes);
        }
    }, [jobState.libraryId]));

    return (
        <SelectWorkflowPane {...props} libraryFilter={libraryFilter} workflowFilter={workflowFilter}
            isLoadingLibraries={isLoadingLibraries} isLoadingWorkflows={isLoadingWorkflows}/>
    )
}

export default ParameterWorkflowPane;
