import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getValues} from "../../../utilities/helperFunctions";
import SchedulerModel from "../../../models/scheduler/SchedulerModel";
import {VaultCacheSaga} from "../../../models/guidedjob/VaultCache";

function VaultOperationPane(props) {
    const dispatch = useDispatch();
    const {
        OperationPane,
        ...rest
    } = props;
    const {
        parameters
    } = props.guidedJobForm;

    const vaultServiceParameter = getValues(parameters).find(param => param.isVaultServiceParameter()) || {};
    const vaultService = useSelector(state => state.thirdPartyServiceDetailsMap.get(vaultServiceParameter.value));

    const proxyVaultRequest = useCallback((resource, opts) => {
        const queryEffect = VaultCacheSaga.queryResource(vaultServiceParameter.value, resource, opts);
        dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(queryEffect));
    }, [vaultServiceParameter.value]);

    return (
        <div className="pane-margin fill-height">
            <OperationPane {...rest} vaultService={vaultService}
                proxyRequest={proxyVaultRequest}/>
        </div>
    )
}

export default VaultOperationPane;
