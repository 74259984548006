import React from "react";
import {useTranslation} from "react-i18next";
import {getEntries, stringToBool} from "../../../utilities/helperFunctions";
import ToggleSwitch from "../../common/ToggleSwitch/ToggleSwitch";
import Text, {Label} from "../../common/Text/Text";

function DatasetOption(props) {
    const {setting: datasetOption, updateSetting} = props;
    const {t} = useTranslation(['datasetOption']);


    function setShowBuiltInMetadataHeaders(event) {
        const {name, checked} = event.currentTarget.dataset;

        updateSetting('builtInHeaders', {
            ...datasetOption.builtInHeaders,
            [name]: stringToBool(checked)
        });
    }

    function setPromptUserForInvalidFileRemove(event) {
        const {name, checked} = event.currentTarget.dataset;
        updateSetting(name, stringToBool(checked));
    }

    return (
        <>
            <div className="display-input">
                <table>
                    <thead className="table-header-group">
                    <tr>
                        <td className="table-header" colSpan={2}>
                            <Text isBold
                                value={t('datasetOption:label.uploadBehavior')}
                            />
                        </td>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <td className="table-cell enable-cell no-stretch">
                            <ToggleSwitch aria-labelledby={'datasetOption:promptUserToRemoveInvalidFiles'} name={'promptUserToRemoveInvalidFiles'}
                                checked={datasetOption.promptUserToRemoveInvalidFiles} onClick={setPromptUserForInvalidFileRemove}
                            />
                        </td>
                        <td className="table-cell">
                            <Label id={'datasetOption:promptUserToRemoveInvalidFiles'}
                                text={t(`datasetOption:label.promptUserToRemoveInvalidFiles`)}/>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div className="display-input">
                <table>
                    <thead className="table-header-group">
                    <tr>
                        <td className="table-header" colSpan={2}>
                            <Text isBold
                                value={t('datasetOption:label.builtInHeaders')}
                            />
                        </td>
                    </tr>
                    </thead>

                    <tbody>
                    {getEntries(datasetOption.builtInHeaders)
                        .map(([builtInHeader, value]) =>

                            <tr key={builtInHeader}>
                                <td className="table-cell enable-cell no-stretch">
                                    <ToggleSwitch  aria-labelledby={`datasetOption:${builtInHeader}`} name={builtInHeader} checked={value}
                                        onClick={setShowBuiltInMetadataHeaders}
                                    />
                                </td>
                                <td className="table-cell">
                                    <Label id={`datasetOption:${builtInHeader}`}
                                        text={t(`datasetOption:label.${builtInHeader}`)}/>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default DatasetOption;