import ReduxModel from "./ReduxModel";
import SagaModel from "./SagaModel";
import {actionCreator, getEntries, getKeys} from "../../utilities/helperFunctions";
import {takeLeading} from "redux-saga/effects";
import {
    contextCall,
    contextPollUntil,
    contextSaga,
    editNavigation,
    isDisabledWrapper,
    spawnSaga,
    takeLeadingPayload
} from "../../saga/sagaFunctions";
import {tryCatchWrapper} from "../../saga/tryCatchWrapper";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import {SchedulerApi} from "../scheduler/SchedulerModel";

class DetailsModel extends ReduxModel {

    constructor(model = {}) {
        super();
        const {id, name, status, description, enabled, userPermissions = []} = model;

        this.id = id;
        this.name = name;
        this.status = status || {};
        this.description = description;
        this.enabled = enabled;
        this.userPermissions = userPermissions;
    }

    static buildDefaultFormState(state, props={}) {
        return {};
    }

    static validateFormData(formData, state) {
        return true;
    }

    static buildActions(type) {
        return {
            // DETAILS ACTIONS
            SET_DETAILS_MAP: `SET_${type}_DETAILS_MAP`,
            ADD_DETAILS: `ADD_${type}_DETAILS`,
            UPDATE_DETAILS: `BULK_UPDATE_${type}_DETAILS`,
            DELETE_DETAILS: `DELETE_${type}_DETAILS`,
            CLEAR_DETAILS_MAP: `CLEAR_${type}_DETAILS_MAP`,
            DUPLICATE_DETAILS: `DUPLICATE_${type}_DETAILS`,
            // DISPLAY ACTIONS
            SHOW_FORM: `SHOW_${type}_FORM`,
            HIDE_FORM: `HIDE_${type}_FORM`,
            SHOW_DISPLAY: `SHOW_${type}_DISPLAY`,
            SHOW_TABLET: `SHOW_${type}_TABLET`,
            HIDE_TABLET: `HIDE_${type}_TABLET`,
            // EDIT ACTIONS
            SUBMIT_FORM: `SUBMIT_${type}_FORM`,
            START_EDIT: `START_${type}_EDIT`,
            PROMPT_DELETE: `PROMPT_DELETE_${type}`,
            DELETE: `DELETE_${type}`,
            TOGGLE_ENABLED: `TOGGLE_${type}_ENABLED`,
            SEND_COMMAND: `SEND_${type}_COMMAND`,
            // DETAILS POLLING ACTIONS
            START_POLLING_DETAILS: `START_POLLING_${type}_DETAILS`,
            STOP_POLLING_DETAILS: `STOP_POLLING_${type}_DETAILS`,
            QUERY_DETAILS: `QUERY_${type}_DETAILS`,
            // SETTINGS POLLING ACTIONS
            START_POLLING_SETTINGS: `START_POLLING_${type}_SETTINGS`,
            STOP_POLLING_SETTINGS: `STOP_POLLING_${type}_SETTINGS`,
            QUERY_SETTINGS: `QUERY_${type}_SETTINGS`,

            DOWNLOAD_AUDIT_LOG_VERSION: `DOWNLOAD_${type}_AUDIT_LOG_VERSION`
        };
    }

    static buildActionCreators(actions) {
        return {
            // DETAILS ACTION CREATORS
            setDetailsMap: actionCreator(actions.SET_DETAILS_MAP, 'details', 'keepOld'),
            addDetails: actionCreator(actions.ADD_DETAILS, 'details'),
            updateDetails: actionCreator(actions.UPDATE_DETAILS, 'idToUpdates'),
            deleteDetails: actionCreator(actions.DELETE_DETAILS, 'id'),
            clearDetailsMap: actionCreator(actions.CLEAR_DETAILS_MAP),
            duplicateDetails: actionCreator(actions.DUPLICATE_DETAILS, 'id'),
            // DISPLAY ACTION CREATORS
            showForm: actionCreator(actions.SHOW_FORM, 'initialState'),
            hideForm: actionCreator(actions.HIDE_FORM, 'type'),
            showDisplay: actionCreator(actions.SHOW_DISPLAY, 'id'),
            showTablet: actionCreator(actions.SHOW_TABLET, 'id'),
            hideTablet: actionCreator(actions.HIDE_TABLET),
            // EDIT ACTION CREATORS
            submitForm: actionCreator(actions.SUBMIT_FORM, 'formData'),
            startEdit: actionCreator(actions.START_EDIT, 'id'),
            promptDelete: actionCreator(actions.PROMPT_DELETE, 'id'),
            delete: actionCreator(actions.DELETE, 'id'),
            toggleEnabled: actionCreator(actions.TOGGLE_ENABLED, 'id'),
            sendCommand: actionCreator(actions.SEND_COMMAND, 'id', 'command', 'args'),
            // DETAIL POLLING ACTION CREATORS
            startPollingDetails: actionCreator(actions.START_POLLING_DETAILS, 'period'),
            stopPollingDetails: actionCreator(actions.STOP_POLLING_DETAILS),
            queryDetails: actionCreator(actions.QUERY_DETAILS),
            // SETTINGS POLLING ACTION CREATORS
            startPollingSettings: actionCreator(actions.START_POLLING_SETTINGS, 'id', 'period'),
            stopPollingSettings: actionCreator(actions.STOP_POLLING_SETTINGS, 'id'),
            querySettings: actionCreator(actions.QUERY_SETTINGS, 'id'),

            downloadAuditLogVersion: actionCreator(actions.DOWNLOAD_AUDIT_LOG_VERSION, 'id', 'downloadId')
        };
    }

    static buildReducer(actions) {
        return function (state = new Map(), action) {
            switch (action.type) {
                case actions.SET_DETAILS_MAP: {
                    const {details, keepOld} = action.payload;

                    this.lastUpdated = Date.now();
                    return this.setDetailsMapGeneric(state, details, 'id', keepOld);
                }
                case actions.ADD_DETAILS: {
                    const {details} = action.payload;

                    this.lastUpdated = Date.now();
                    return this.addDetailsGeneric(state, details, 'id');
                }
                case actions.UPDATE_DETAILS: {
                    const {idToUpdates} = action.payload;

                    this.lastUpdated = Date.now();
                    return this.bulkUpdateDetails(state, idToUpdates);
                }
                case actions.DELETE_DETAILS: {
                    const {id} = action.payload;

                    this.lastUpdated = Date.now();
                    return this.deleteDetails(state, id);
                }
                case actions.CLEAR_DETAILS_MAP: {
                    this.lastUpdated = Date.now();
                    return new Map();
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }
}

export class DetailsApi {

    static location = '';
    static type = '';

    static getAuditLogVersionDownloadEndpoint(id, downloadId) {
        return `/scheduler${this.location}${this.type}/${id}/auditLog/${downloadId}/download`;
    }

    static get(id) {
        return axiosInstance.get(`/scheduler${this.location}${this.type}/${id}`);
    }

    static getDetails() {
        return axiosInstance.get(`/scheduler${this.location}${this.type}`);
    }

    static getAuditLog(id) {
        return axiosInstance.get(`/scheduler${this.location}${this.type}/${id}/auditLog`);
    }

    static post(data) {
        return axiosInstance.post(`/scheduler${this.location}${this.type}`, data);
    }

    static putDetails(id, details) {
        return axiosInstance.put(`/scheduler${this.location}${this.type}/${id}`, details);
    }

    static delete(id) {
        return axiosInstance.del(`/scheduler${this.location}${this.type}/${id}`);
    }
}

export class DetailsSaga extends SagaModel {

    static buildActivationEffects(dispatch) {
        return [
            // ACTIVATION EFFECTS
            takeLeading(this.ModelType.actions.SHOW_FORM, editNavigation, dispatch, contextSaga(this, 'showForm')),
            takeLeading(this.ModelType.actions.HIDE_FORM, isDisabledWrapper, this.ModelType.componentActionCreators.updateForm, contextSaga(this, 'hideForm')),

            takeLeading([this.ModelType.actions.SHOW_DISPLAY, this.ModelType.actions.SHOW_TABLET], editNavigation, dispatch, contextSaga(this, 'showPane')),
            takeLeading(this.ModelType.actions.HIDE_TABLET, editNavigation, dispatch, contextSaga(this, 'hidePane')),

            takeLeading(this.ModelType.actions.SUBMIT_FORM, tryCatchWrapper, isDisabledWrapper, this.ModelType.componentActionCreators.updateForm, contextSaga(this, 'submitForm')),
            takeLeadingPayload(this.ModelType.actions.TOGGLE_ENABLED, tryCatchWrapper, contextSaga(this, 'toggleEnabled')),
            takeLeading(this.ModelType.actions.START_EDIT, spawnSaga, contextSaga(this, 'startEdit')),
            takeLeading(this.ModelType.actions.PROMPT_DELETE, contextSaga(this, 'promptDelete')),
            takeLeading(this.ModelType.actions.DELETE, tryCatchWrapper, isDisabledWrapper, this.ModelType.componentActionCreators[this.variableNames.updatePane], contextSaga(this, 'delete')),
            takeLeading(this.ModelType.actions.DUPLICATE_DETAILS, isDisabledWrapper, this.ModelType.componentActionCreators[this.variableNames.updatePane], contextSaga(this, 'duplicate')),

            takeLeading(this.ModelType.actions.DOWNLOAD_AUDIT_LOG_VERSION, tryCatchWrapper, isDisabledWrapper, this.ModelType.componentActionCreators[this.variableNames.updatePane], contextSaga(this, 'downloadAuditLogVersion'))
        ]
    }

    static buildDeactivationEffects(dispatch) {
        return [
            // DEACTIVATION EFFECTS
        ]
    }

    static* pollDetails(action) {
        const {period} = action.payload;

        yield contextPollUntil(this.ModelType.actions.STOP_POLLING_DETAILS, period, this, 'queryDetails', action);
    }

    static* pollSettings(action) {
        const {period} = action.payload;

        yield contextPollUntil(this.ModelType.actions.STOP_POLLING_SETTINGS, period, this, 'querySettings', action);
    }

    static queryDetails() {
        throw new Error("Method not implemented");
    }

    static querySettings() {
        throw new Error("Method not implemented");
    }

    static* queryModelNames(idToType) {
        const idList = getKeys(idToType);

        const typeToUpdate = {};
        if (idList.length > 0) {
            const response = yield contextCall(SchedulerApi, 'getNames', idList);

            if (AxiosProxy.shouldUpdate('modelNames', response)) {
                for (const [id, name] of getEntries(response.data)) {
                    const type = idToType[id];
                    if (type != null) {

                        if (typeToUpdate[type] == null)
                            typeToUpdate[type] = {};

                        typeToUpdate[type][id] = {id, name};
                    }
                }
            }
        }

        return typeToUpdate;
    }
}

export default DetailsModel;