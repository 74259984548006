import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {
    createCloseHandler,
    createFileHandler,
    createInputHandler,
    createStateHandler
} from "../../utilities/componentFunctions";
import PopupModel from "../../models/scheduler/PopupModel";
import Form, {FormFooter, FormHeader} from "../common/Form/Form";
import TextArea from "../common/TextArea/TextArea";
import {shouldEnableLibraryFile} from "../../utilities/shouldEnableFunctions";
import {libraryFileUpdateMode, popupInfoKeys} from "../../i18next/keys";
import LibraryFileModel from "../../models/filelibrary/LibraryFileModel";
import FileUpload from "../common/FileUpload/FileUpload";
import encode from 'base64-arraybuffer';
import {bytesCountToReadableCount} from "../../utilities/helperFunctions";

function LibraryFileForm (props) {
    const {t} = useTranslation(['fileLibrary', 'common'])
    const dispatch = useDispatch()
    const {fileLibraryId} = props;

    const {libraryFileMaxSize} = useSelector(state => state.schedulerDetails.configuration);
    const reduxState = useSelector(state => state.componentStates.libraryFileForm)

    const [state, setState] = useState({
        ...reduxState,
        isAddEnabled: false
    });

    const stateHandler = createStateHandler({
        updateState: setState,
        shouldEnable: shouldEnableLibraryFile
    });

    const inputHandler = createInputHandler({
        handler: stateHandler
    });

    const updateLibraryFile = (updates) => {
        stateHandler(updates);
    }

    function showMaximumFilesizeError(options) {
        dispatch(PopupModel.actionCreators.showError({
            info: {
                key: popupInfoKeys.TEMPLATE,
                values: {
                    title: `${t('popupInfo:maxFilesizeError.title')}`,
                    message: `${t('popupInfo:maxFilesizeError.message', {...options})}`
                }
            }
        }));
    }

    const libraryFileChange = createFileHandler({
        onloadend: (event, file) => {
            const uploadSize = file.size;

            if (uploadSize > libraryFileMaxSize) {
                showMaximumFilesizeError({maxUploadSize: bytesCountToReadableCount(libraryFileMaxSize)});
                return;
            }

            const uploadFileData = encode.encode(event.target.result);

            updateLibraryFile({
                fileName: file.name,
                fileData: uploadFileData,
            });
        }
    })

    function onSubmit() {
        const formData = {
            id: id,
            name: fileName,
            fileLibraryId: fileLibraryId,
            description: description,
            data: !!fileData ? fileData : null
        }

        if (mode === libraryFileUpdateMode.LOCAL) {
            dispatch(LibraryFileModel.actionCreators.submitForm(formData))
        } else {
            dispatch(LibraryFileModel.actionCreators.updateLibraryFile(formData))
        }
    }

    const {
        id,
        mode,
        fileName,
        description,
        fileData,
        isAddEnabled,
        isDisabled
    } = state;

    const onClose = createCloseHandler({
        t,
        item: t('fileLibrary:file.label.name_simple'),
        values: {fileName, fileData, description},
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        close: () => dispatch(LibraryFileModel.componentActionCreators.updateView({isLibraryFileFormActive: false}))
    });


    return (
        <Form onClose={onClose} isDisabled={isDisabled}
              header={
                  <FormHeader text={t('fileLibrary:label.file')}
                              isDisabled={isDisabled}
                  />
              }

              body={
                  <>
                      <div className="display-input">
                          <FileUpload label={t('fileLibrary:label.fileName')} fileName={fileName} onFileChange={libraryFileChange}
                                      isDisabled={isDisabled} isRequired/>
                      </div>

                      <div className="display-input">
                          <TextArea label={t('fileLibrary:label.notes')} name={'description'} value={description} onChange={inputHandler}
                                    isDisabled={isDisabled}
                          />
                      </div>
                  </>
              }

              footer={
                  <FormFooter addText={ t(`common:button.addLibraryFile${mode}`)} onAddClick={onSubmit}
                      onCancel={onClose} isAddEnabled={isAddEnabled} isDisabled={isDisabled}/>
              }
        />
    )
}

export default LibraryFileForm;