import React from "react";
import {useTranslation} from "react-i18next";
import {TableLabelCell, TableLabelHeader} from "../../common/CustomTable/CustomTable";
import ThirdPartyServiceModel from "../../../models/thirdparty/ThirdPartyServiceModel";
import ThirdPartyServiceTable from "../ThirdPartyServiceTable";

function GenAiServiceTable(props) {

    return (
        <ThirdPartyServiceTable {...props} type={ThirdPartyServiceModel.Type.GEN_AI} showMethod
            TableHeaders={GenAiServiceTableHeaders} TableRowCells={GenAiServiceTableRowCells}
        />
    )
}

function GenAiServiceTableHeaders() {
    const {t} = useTranslation(['thirdPartyService']);
    return (
        <>
            <TableLabelHeader label={t('thirdPartyService:label.url')}/>
            <TableLabelHeader label={t('thirdPartyService:label.model')}/>
        </>
    )
}

function GenAiServiceTableRowCells(props) {
    const {
        thirdPartyService
    } = props;

    return (
        <>
            <TableLabelCell label={thirdPartyService.url}/>
            <TableLabelCell label={thirdPartyService.model}/>
        </>
    )
}

export default GenAiServiceTable;
