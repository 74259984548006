import React from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {QuestionCheckBox} from "../../Question/QuestionCheckBox/QuestionCheckBox";
import {updateGenericValues} from "../../../redux/WizardSlice/wizardSlice";
import {createNestedToggleHandler} from "../../Question/QuestionCheckBox/helpers";

function ConfigurationOptional(props) {
    const {updateState} = props;
    const {t} = useTranslation(['wizard']);

    const {configuration} = props;
    const {relativity, discover} = configuration;

    const questions = [
        {
            question: t('wizard:questions.optional.performOptionalConfigurations'),
            dataName: "optionalConfigurations",
            options: [
                {id: "relativity", value: t('wizard:questions.optional.configurationOptions.relativity'), checked: relativity},
                {id: "discover", value: t('wizard:questions.optional.configurationOptions.discover'), checked: discover}
            ]
        }
    ]

    const toggleEnable = createNestedToggleHandler({
        handler: updateState
    });

    const update = (event) => {
        const {name, checked} = event.target;
        return toggleEnable({target: {name: name, checked: checked, nested: "configuration"}});
    }

    return (
        <div className={"wizard-step-container"}>
            <QuestionCheckBox
                question={questions[0].question}
                dataName={questions[0].dataName}
                options={questions[0].options}
                onClick={update}
            />
        </div>
    );
}

function mapStateToProps (state) {
    const {wizardSlice:{optionalSteps: {configuration}}} = state;
    return {
        configuration
    };
}

function mapDispatchToProps (dispatch) {
    return {
        updateState: updates => dispatch(updateGenericValues({stateName: "optionalSteps", updates: updates}))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationOptional);