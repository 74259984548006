import React, {useCallback, useEffect, useRef, useState} from "react";
import './Panel.css';
import {useDispatch, useSelector} from "react-redux";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import SelectPanel, {LibrarySelectPanel} from "./SelectPanel";
import {details, MIN_SIZE_FOR_SEARCH, SAME_AS_TRIGGERING_JOB} from "../../utilities/constants";
import {useTranslation} from "react-i18next";
import LibraryModel from "../../models/library/LibraryModel";
import SearchBar from "../common/SearchBar/SearchBar";
import {getObjectText, getValues} from "../../utilities/helperFunctions";
import {permissionKeys} from "../../i18next/keys";

export function RestrictedLibraryPanel(props) {
    const librarySelector = useCallback(state => {
        return getValues(state.libraryDetailsMap)
            .filter(library => library.enabled && library.userPermissions.includes(permissionKeys.SUBMIT_JOB));
    }, []);

    return (
        <LibraryPanel {...props} librarySelector={librarySelector}/>
    )
}

function LibraryPanel(props) {
    const {libraryId, librarySelector, updateState, canSetSameAs={}, isLoading, isDisabled} = props;
    const {t} = useTranslation(['workflow']);

    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');

    const _isLoading = useSelector(state => !state.hasLoaded[details.LIBRARIES]) || isLoading;
    const libraries = useSelector(librarySelector);

    // Query libraries
    useEffect(() => {
        dispatch(LibraryModel.actionCreators.queryDetails());
    }, [dispatch]);

    // Select Library if it's the only one (and no library is selected)
    useEffect(() => {
        if (!isDisabled && libraryId == null && libraries.length === 1) {
            const library = libraries[0];
            updateState({libraryId: library.id, workflowTemplateId: null});
        }
    }, [isDisabled, libraryId, libraries, updateState]);

    const scrollDivRef = useRef();
    // Scroll to selected library IFF exists
    useEffect(() => {
        if (!_isLoading && scrollDivRef.current != null) {
            const activePanel = scrollDivRef.current.getElementsByClassName('is-active')[0];
            if (activePanel != null) {
                activePanel.scrollIntoView();
            }
        }
    }, [_isLoading]);

    function librarySelect(event) {
        const {value: libraryId} = event.currentTarget.dataset;
        updateState({libraryId, workflowTemplateId: null});

        setTimeout(() => {
            document.getElementById(`${libraryId}_TABPANEL`)?.focus();
        }, 50);
    }

    const canSearchLibraries = libraries.length >= MIN_SIZE_FOR_SEARCH;
    const sameAsTriggeringJobTitle = t(`jobSchedule:option.${SAME_AS_TRIGGERING_JOB}`);

    return (
        <aside className="selectPane-panel" role="tablist" tabIndex={-1}>
            <LoadingWrapper isLoading={_isLoading}>
                {canSearchLibraries &&
                <SearchBar id="librarySearchBar" style={{margin: '0.5rem'}}
                    value={searchText} onChange={event => setSearchText(event.target.value)}
                />
                }

                <section style={{overflowY: 'auto'}} ref={scrollDivRef}>
                    {libraries
                        .filter(_library => getObjectText(_library, {whitelist: ['name']}).includes(searchText.toLowerCase()))
                        .map(({id}) =>
                            <LibrarySelectPanel key={id} libraryId={id} onPanelSelect={librarySelect}
                                isActive={id === libraryId} isDisabled={isDisabled}
                            />
                        )}

                    {canSetSameAs.job && sameAsTriggeringJobTitle.toLowerCase().includes(searchText.toLowerCase()) &&
                    <SelectPanel label={sameAsTriggeringJobTitle} value={SAME_AS_TRIGGERING_JOB} onPanelSelect={librarySelect}
                        isActive={libraryId === SAME_AS_TRIGGERING_JOB} isItalic isDisabled={isDisabled}
                    />
                    }
                </section>
            </LoadingWrapper>
        </aside>
    );
}

export default LibraryPanel;
