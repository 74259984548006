/** @jsxRuntime classic */
import {getFileTypeColor, getFileTypeIcon} from '../utils/getFileTypeIcon';
import i18next from "i18next";

/** @jsx h */

const { h } = require('preact')

export default function FilePreview (props) {
    const { file } = props

    if (file.preview) {
        return (
            <img
                className="uppy-Dashboard-Item-previewImg"
                alt={i18next.t('aria:hiddenAssistText.previewIcon')}
                src={file.preview}
            />
        )
    }

    const icon = getFileTypeIcon(file.type, file.extension)
    const color = getFileTypeColor(file.type)

    return (
        <div className="uppy-Dashboard-Item-previewIconWrap">
            <span className="uppy-Dashboard-Item-previewIcon" style={{ color }}>{icon}</span>
            <svg aria-hidden="true" focusable="false" className="uppy-Dashboard-Item-previewIconBg" width="58" height="76" viewBox="0 0 58 76">
                <rect fill="#FFF" width="58" height="76" rx="3" fillRule="evenodd" />
            </svg>
        </div>
    )
}