import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {initialSelectedState, useClearSelectedEffect, useValueSelectHandler} from "../../utilities/hooks";
import {buildClassName, getBytesPowAndSuffix, objectTruthyValues} from "../../utilities/helperFunctions";
import {Button} from "../common/Button/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TableLabelCell, TableLabelHeader} from "../common/CustomTable/CustomTable";
import {popupInfoKeys, uploadInfoStateKeys} from "../../i18next/keys";
import DatasetModel from "../../models/data/DatasetModel";
import PopupModel from "../../models/scheduler/PopupModel";
import Text from "../common/Text/Text";
import {useDispatch} from "react-redux";


function UploadInfoTable(props) {
    const {t} = useTranslation(['dataset', 'common']);
    const dispatch = useDispatch();

    const {
        uploadInfos,
        isFinalized,
        isDisabled,
        onUploadFilesDelete,
        canModify,
        label,
        ...attr
    } = props;

    const containerRef = useRef();
    const [selected, setSelected] = useState(initialSelectedState);

    const selectHandler = useValueSelectHandler({setSelected});
    useClearSelectedEffect({containerRef, setSelected, dataStructure: uploadInfos});


    function onRemoveClick() {
        const selectedIndices = objectTruthyValues(selected.values);

        const uploadInfoIds = [];
        const relativePaths = [];

        for (const index of selectedIndices) {
            const uploadInfo = uploadInfos[index];
            const {id, relativePath} = uploadInfo;

            // Can't delete active uploads
            if (!DatasetModel.isUploadInfoActive(uploadInfo)) {
                uploadInfoIds.push(id);
                relativePaths.push(relativePath);
            }
        }

        dispatch(PopupModel.actionCreators.show({
            info: {
                key: popupInfoKeys.DELETE_DATASET_UPLOAD_INFOS,
                values: {
                    fileNames: relativePaths.join('\n')
                }
            },
            buttons: [{
                titleKey: 'common:option.delete',
                onClick: () => onUploadFilesDelete(uploadInfoIds)
            }]
        }));
    }

    const canPerformActions = (canModify && !isFinalized);
    const canRemove = (canModify && objectTruthyValues(selected.values).length > 0);

    const filesTableClassName = buildClassName(
        'files-table',
        isDisabled && 'is-disabled'
    );

    return (
        <div id="uploadInfoTable" ref={containerRef} {...attr}>

            {canModify &&
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div className="add-remove-buttons">
                    {!isFinalized &&
                    <Button isImg onClick={onRemoveClick} isDisabled={isDisabled || !canRemove}
                        aria-label={t('dataset:message.deleteFiles')}
                    >
                        <FontAwesomeIcon icon="minus"/>
                    </Button>
                    }

                    <Text value={label || t('dataset:label.uploads')} isDisabled={isDisabled}/>
                </div>
            </div>
            }

            {!canModify &&
            <Text value={label || t('dataset:label.uploads')} isDisabled={isDisabled}/>
            }

            <div style={{overflowX: 'auto'}}>
                <div className={filesTableClassName}>
                    <div className="table-header-group">
                        <TableLabelHeader label={t('dataset:label.state')} style={{width: '8rem'}}/>

                        <TableLabelHeader label={t('dataset:label.filePath')}/>

                        <TableLabelHeader label={t('dataset:label.uploaded')} style={{textAlign: 'end', width: '10rem'}}/>
                    </div>

                    <div className="table-row-group">

                        {uploadInfos.map((_uploadInfo, index) =>
                            <UploadInfoRow key={_uploadInfo.relativePath} {..._uploadInfo} index={index}
                                isActive={canPerformActions && selected.values[index]}
                                selectHandler={canPerformActions ? selectHandler : null} noPointer={!canPerformActions}
                                isDisabled={isDisabled}
                            />
                        )}
                    </div>

                </div>
            </div>
        </div>
    )
}

function UploadInfoRow(props) {
    const {t} = useTranslation(['dataset']);

    const {
        index,
        relativePath,
        state,
        offset,
        length,
        selectHandler,
        noPointer,
        isActive,
        isDisabled
    } = props;

    const noSelect = state === uploadInfoStateKeys.ACTIVE;

    const className = buildClassName(
        'table-row',
        isActive && 'is-active',
        (noPointer || noSelect || isDisabled) && 'no-pointer'
    );

    const {pow, suffix} = getBytesPowAndSuffix(length);
    const offsetFixed = (offset / Math.pow(1000, pow)).toFixed((pow > 2) ? 2 : 0);
    const lengthFixed = (length / Math.pow(1000, pow)).toFixed((pow > 2) ? 2 : 0);

    const uploadProgress = `${offsetFixed} of ${lengthFixed} ${suffix}`;

    return (
        <div className={className} data-index={index} onClick={(isDisabled || noSelect) ? null : selectHandler} tabIndex={isDisabled ? -1 : 0}>
            <TableLabelCell label={t(`dataset:uploadInfoState.${state}`)}/>

            <TableLabelCell label={relativePath}/>

            <TableLabelCell label={uploadProgress} style={{textAlign: 'end'}}/>
        </div>
    )
}

export default UploadInfoTable;