import React, {Component} from "react";
import {connect} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {getValues, nameLocaleCompare, objectTruthyValues} from "../../../utilities/helperFunctions";
import {jobEventTriggerKeys, webhookPlatformKeys} from "../../../i18next/keys";
import {getSafeClose, onInputChange, warnOnClose} from "../../../utilities/componentFunctions";
import {onAddItemsToList, onSelectListItem} from "../../common/ListContainer/helpers";
import {updateMode, webhookPlatformHowToLinks} from "../../../utilities/constants";
import Form, {FormHeader} from "../../common/Form/Form";
import TextArea from "../../common/TextArea/TextArea";
import ListContainer, {SwitchListContainers,} from "../../common/ListContainer/ListContainer";
import PopupModel from "../../../models/scheduler/PopupModel";
import NotificationRuleModel, {NotificationRuleSaga} from "../../../models/settings/NotificationRuleModel";
import {WebhookPlatformDropdown} from "../../common/Dropdown/Dropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getWebhookPlatformType} from "../../../utilities/iconResolver";
import {onDropdownItemClick} from "../../common/Dropdown/helpers";
import {ButtonGroup} from "../../common/Button/Button";
import {contextCall} from "../../../saga/sagaFunctions";
import SchedulerModel from "../../../models/scheduler/SchedulerModel";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import i18n from "../../../i18next/i18n";

class WebhookNotificationRuleForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ruleType: NotificationRuleModel.Type.WEBHOOK,
            webhookNotificationRuleName: '',
            description: '',
            availableTriggers: {
                items: getValues(jobEventTriggerKeys)
                    .map(value => {return {name: i18n.t(`job:trigger.${value}`), value}})
                    .sort(nameLocaleCompare),
                selectedItems: {}
            },
            selectedTriggers: {
                items: [],
                selectedItems: {}
            },
            webhookPlatform: webhookPlatformKeys.MICROSOFT_TEAMS,
            webhookUrl: '',
            isAddEnabled: false
        };

        this.getSafeClose = getSafeClose('state', ['availableTriggers', 'webhookPlatform', 'howToLink']).bind(this);
        this.warnOnClose = warnOnClose(this.props.t('notificationRule:label.name')).bind(this);

        this.onAddClick = this.onAddClick.bind(this);
        this.testFormData = this.testFormData.bind(this);

        this.onInputChange = onInputChange({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            isEnabled: 'isAddEnabled',
            shouldEnable: NotificationRuleModel.validateFormData
        }).bind(this);
        this.onDropdownItemClick = onDropdownItemClick({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            isEnabled: 'isAddEnabled',
            shouldEnable: NotificationRuleModel.validateFormData
        }).bind(this);

        this.onAddTriggers = onAddItemsToList({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listTo: 'selectedTriggers',
            listFrom: 'availableTriggers'
        }).bind(this);
        this.onRemoveTriggers = onAddItemsToList({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listTo: 'availableTriggers',
            listFrom: 'selectedTriggers'
        }).bind(this);
        this.onSelectAvailableTriggers = onSelectListItem({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'availableTriggers'
        }).bind(this);
        this.onSelectSelectedTriggers = onSelectListItem({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'selectedTriggers'
        }).bind(this);
    }

    onAddClick() {
        this.props.addNotificationRule(this.state);
    }

    testFormData() {
        this.props.yieldEffect(contextCall(NotificationRuleSaga, 'testRuleData', 'Form', this.state));
    }

    render() {

        const {
            t,
            isDisabled
        } = this.props;
        const {
            webhookNotificationRuleName,
            description,
            availableTriggers,
            selectedTriggers,
            webhookPlatform,
            webhookUrl,
            isAddEnabled
        } = this.state;
        const close = this.getSafeClose();

        const nameInputProps = {
            label: t('common:label.name'),
            name: 'webhookNotificationRuleName',
            value: webhookNotificationRuleName,
            onChange: this.onInputChange,
            isRequired: true,
            isDisabled
        };
        const descriptionProps = {
            label: t('common:label.description'),
            name: 'description',
            value: description,
            onChange: this.onInputChange,
            isDisabled
        };

        const webhookPlatformDropdownProps = {
            selectedWebhookPlatform: webhookPlatform,
            onWebhookPlatformSelect: this.onDropdownItemClick,
            isDisabled
        };
        const webhookUrlProps = {
            label: t('notificationRule:label.webhookUrl'),
            name: 'webhookUrl',
            value: webhookUrl,
            onChange: this.onInputChange,
            isRequired: true,
            isDisabled
        };

        const triggerSwitchListContainersProps = {
            availableTriggers,
            selectedTriggers,
            triggerNavigateButtonProps: {
                onClick: [
                    this.onAddTriggers,
                    this.onRemoveTriggers,
                ],
                length: [
                    objectTruthyValues(availableTriggers.selectedItems).length,
                    objectTruthyValues(selectedTriggers.selectedItems).length,
                ]
            },
            onSelectAvailableTriggers: this.onSelectAvailableTriggers,
            onSelectSelectedTriggers: this.onSelectSelectedTriggers,
            isDisabled
        };

        const disabled = isDisabled ? ' is-disabled' : '';

        return (
            <Form onClose={close} isDisabled={isDisabled} closeButtonAriaLabel={t('notificationRule:option.closeWebhookRuleForm')}
                header={
                    <FormHeader text={t('notificationRule:label.webhookName')} iconName={getWebhookPlatformType(webhookPlatform)}
                        isDisabled={isDisabled}/>
                }
                body={
                    <>
                        <div className="display-input">
                            <HTMLTextInput {...nameInputProps}/>
                        </div>
                        <div className="display-input">
                            <TextArea {...descriptionProps}/>
                        </div>

                        <div className="display-input">
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <label className={'label' + disabled}>
                                    {t('notificationRule:label.webhookPlatform')}:
                                </label>

                                <div style={{margin: '0 0.35rem 0 0.75rem'}}>
                                    <WebhookPlatformDropdown {...webhookPlatformDropdownProps}/>
                                </div>

                                <a href={webhookPlatformHowToLinks[webhookPlatform]} target="_blank" rel="noopener noreferrer" style={{fontSize: '0.8rem'}}
                                    title={t('notificationRule:message.howToSetupWebhooks')}
                                >

                                    <FontAwesomeIcon icon="question-circle" color="light-blue"/>
                                </a>
                            </div>
                        </div>

                        <div className="display-input">
                            <HTMLTextInput {...webhookUrlProps}/>
                        </div>

                        <div className="display-input">
                            <label className={'label' + disabled}>
                                {t('notificationRule:label.triggers')}
                            </label>

                            <NotificationRuleTriggerSwitchListContainers {...triggerSwitchListContainersProps}/>
                        </div>
                    </>
                }
                footer={
                    <ButtonGroup buttons={[{
                        id: 'formFooterBackButton',
                        label: t('common:option.cancel'),
                        onClick: close,
                        isDisabled
                    }, {
                        id: 'formTestButton',
                        label: t('notificationRule:label.testRule'),
                        onClick: this.testFormData,
                        isDisabled: isDisabled || !isAddEnabled
                    }, {
                        id: 'formFooterNextButton',
                        label: t('common:button.addNotificationRule'),
                        onClick: this.onAddClick,
                        isDisabled: isDisabled || !isAddEnabled
                    }]}/>
                }
            />
        )
    }
}

export function NotificationRuleTriggerSwitchListContainers(props) {
    const {availableTriggers, selectedTriggers, triggerNavigateButtonProps, onSelectAvailableTriggers, onSelectSelectedTriggers, isDisabled} = props;
    const {t} = useTranslation(['notificationRule', 'job', 'common']);

    return (
        <SwitchListContainers id="notificationRuleTriggerSwitchContainer"
            ariaLabelKey={'Trigger'}
            navigateButtonsProps={{...triggerNavigateButtonProps, isDisabled}}
            leftContainer={
                <ListContainer label={t('common:label.available')} {...availableTriggers} onItemClick={onSelectAvailableTriggers} isDisabled={isDisabled}
                    items={
                        availableTriggers.items
                            .map(item => ({
                                ...item,
                                name: t(`job:trigger.${item.value}`)
                            }))
                    }
                />
            }
            rightContainer={
                <ListContainer label={t('common:label.selected')} {...selectedTriggers} onItemClick={onSelectSelectedTriggers} isDisabled={isDisabled}
                    items={
                        selectedTriggers.items
                            .map(item => ({
                                ...item,
                                name: t(`job:trigger.${item.value}`)
                            }))
                    }
                />
            }
        />
    )
}

const mapStateToProps = state => {
    return {
        ...state.componentStates.notificationRuleForm
    };
};

const mapDispatchToProps = dispatch => {
    return {
        yieldEffect: effect => dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(effect)),
        addNotificationRule: notificationRule => dispatch(NotificationRuleModel.actionCreators.submitForm(notificationRule)),

        close: () => dispatch(NotificationRuleModel.componentActionCreators.updateDisplay({isWebhookNotificationRuleFormActive: false})),
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['notificationRule'])(WebhookNotificationRuleForm));