import React from 'react';
import {connect} from 'react-redux';
import {switchcase} from '../../utilities/helperFunctions';
import {icon, translatedStatusIcons} from '../../utilities/iconResolver';
import {useTranslation} from "react-i18next";
import {maintenanceStatusKeys} from "../../i18next/keys";
import ServerModel from "../../models/settings/ServerModel";
import Text from "../common/Text/Text";
import {TableLabelCell, TableRow} from "../common/CustomTable/CustomTable";

const ServerRow = props => {
    const {
        name,
        description,
        url,
        version,
        javaVersion,
        status,
        maintenanceStatus,
        serverId,
        showServerTablet,
        isActive
    } = props;
    const {t} = useTranslation(['server', 'common']);

    return (
        <TableRow value={serverId} isActive={isActive} onClick={showServerTablet}>
            <div className="table-cell status-name-description-cell">
                <div style={{display: 'flex', height: '100%'}}>

                    <span className="icon" title={t(`common:status.${status}`)}>
                      {translatedStatusIcons(status)}
                    </span>

                    {maintenanceIcon(maintenanceStatus)}

                    <div style={{flex: 1, minWidth: 0}}>
                        <Text isBold isEllipsis value={name}/>

                        {description &&
                        <Text isWordWrap value={description}/>
                        }
                    </div>
                </div>
            </div>

            <TableLabelCell label={url}/>

            <TableLabelCell label={version}/>

            <TableLabelCell label={javaVersion}/>

            <TableLabelCell label={t(`common:status.${status}`)}/>
        </TableRow>
    );
}

const maintenanceIcon = maintenanceStatus => {
    const maintenanceIcon = switchcase({
        [maintenanceStatusKeys.ENTERING_MAINTENANCE]: 'enterMaintenance',
        [maintenanceStatusKeys.UNDER_MAINTENANCE]: 'underMaintenance'
    })()(maintenanceStatus);

    return maintenanceIcon ?
        <span className="icon" title={maintenanceStatus}>
          <img src={icon(maintenanceIcon)} alt={t(`aria:hiddenAssistText.${maintenanceIcon}Icon`)}/>
        </span>
        :
        null;
}

const mapStateToProps = (state, ownProps) => {
    const {
        name,
        description,
        url,
        version,
        javaVersion,
        status,
        maintenanceStatus
    } = state.serverDetailsMap.get(ownProps.serverId);
    const isActive = state.componentStates.serverDisplay.serverId === ownProps.serverId;

    return {
        name,
        description,
        url,
        version,
        javaVersion,
        status,
        maintenanceStatus,
        isActive
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showServerTablet: event => dispatch(ServerModel.actionCreators.showTablet(event.currentTarget.dataset.value))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServerRow);
