import React, {useLayoutEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {diagnosticLevelKeys, statusKeys} from "../../i18next/keys";
import {SettingsRowValue} from '../common/CustomTable/CustomTable';

import {applicationFeatures, details} from "../../utilities/constants";
import AutomateLicenceModel from "../../models/scheduler/AutomateLicenceModel";
import {Button} from "../common/Button/Button";
import {
    capitalize,
    getLocaleDateFromUTC,
    isNotEmptyNorFalsy,
    removeUnderlineCapitalizeAll
} from "../../utilities/helperFunctions";
import ExpandableContent, {
    ExpandableErrorAlertTable,
    ExpandableWarningAlertTable
} from "../common/ExpandableContent/ExpandableContent";
import UpdateAutomateLicenceForm from "./UpdateAutomateLicenceForm";
import Text from "../common/Text/Text";
import {MainPageContent, MainPageHeader} from "../app/pages/MainPage";

function AutomateLicenceDisplay(props) {
    const {t} = useTranslation(['automateLicence', 'common']);
    const dispatch = useDispatch();

    const {
        ...attr
    } = props;

    useSelector(state => !state.hasLoaded[details.AUTOMATE_LICENCE]);
    const {
        isFormActive
    } = useSelector(state => state.componentStates.automateLicenceDisplay);

    const {
        name,
        description,
        startDate,
        endDate,
        environment,
        editions,
        modules,
        allModules,
        status,
        nuixLicences,
        thirdPartyLicenses,
        diagnosticLevel,
        diagnosticKeyId,
        version
    } = useSelector(state => state.automateLicence);

    const {
        features
    } = useSelector(state => state.currentUser);


    useLayoutEffect(() => {
        dispatch(AutomateLicenceModel.componentActionCreators.setDisplayActive());

        return () => dispatch(AutomateLicenceModel.componentActionCreators.setDisplayInactive());
    }, [dispatch]);


    function onUpdateLicence() {
        dispatch(AutomateLicenceModel.componentActionCreators.updateDisplay({isFormActive: true}))
    }

    const canModify = features.includes(applicationFeatures.MODIFY_AUTOMATE_LICENCE);

    return (
        <MainPageContent {...attr}
            title={
                <>
                    <MainPageHeader text={t('automateLicence:page.title')}/>

                    {canModify &&
                    <div className="add-button-header">
                        <Button text={t('automateLicence:option.updateLicence')}
                            onClick={onUpdateLicence}/>
                    </div>
                    }
                </>
            }

            list={
                <>
                    <ExpandableContent label={name || t('automateLicence:label.noLicence')}>
                        {description &&
                        <label className="display-item label">{description}</label>}

                        <div className="settings-table">
                            <div className="table-row-group">
                                <SettingsRowValue
                                    label={t('automateLicence:label.startDate')}
                                    value={startDate && getLocaleDateFromUTC(startDate)}/>
                                <SettingsRowValue
                                    label={t('automateLicence:label.endDate')}
                                    value={endDate && getLocaleDateFromUTC(endDate)}/>
                                <SettingsRowValue
                                    label={t('automateLicence:label.environment')}
                                    value={environment && capitalize(environment)}/>
                                <SettingsRowValue
                                    label={t('automateLicence:label.diagnosticLevel')}
                                    value={diagnosticLevel && capitalize(diagnosticLevel)}/>

                                {diagnosticLevel && diagnosticLevel !== diagnosticLevelKeys.NONE &&
                                <SettingsRowValue
                                    label={t('automateLicence:label.diagnosticKeyId')}
                                    value={diagnosticKeyId}/>
                                }
                            </div>
                        </div>
                    </ExpandableContent>


                    {(editions && isNotEmptyNorFalsy(editions)) &&
                        <div className="display-item">
                            <ExpandableContent label={t('automateLicence:label.edition')}>
                                {editions && editions.map(name =>
                                    <label className="label" key={name}>{removeUnderlineCapitalizeAll(name)}</label>
                                )}
                            </ExpandableContent>
                        </div>
                    }

                    {(allModules && isNotEmptyNorFalsy(allModules)) &&
                        <div className="display-item">
                            <ExpandableContent label={t('automateLicence:label.modules')}>
                                {allModules && allModules.map(name =>
                                    <label className="label" key={name}>{removeUnderlineCapitalizeAll(name)}</label>
                                )}
                            </ExpandableContent>
                        </div>
                    }

                    <div className="display-item">
                        {nuixLicences && isNotEmptyNorFalsy(nuixLicences) &&
                            <div className="display-item">
                                <ExpandableContent label={t('automateLicence:label.workers')}>
                                    {nuixLicences && nuixLicences.map(nuixLicence =>
                                        <label className="label" key={nuixLicence}>{nuixLicence.sysId}</label>
                                    )}
                                </ExpandableContent>
                            </div>

                            }
                    </div>

                    <div className="display-item">
                        {isNotEmptyNorFalsy(thirdPartyLicenses) &&
                        <ExpandableContent label={t('automateLicence:label.thirdPartyLicense')}>
                            <div className="display-table">
                                <div className="table-header-group">
                                    <div className="table-header">
                                        <label className="label is-bold">
                                            {t('thirdPartyLicense:label.product')}
                                        </label>
                                    </div>

                                    <div className="table-header">
                                        <label className="label is-bold">
                                            {t('thirdPartyLicense:label.count')}
                                        </label>
                                    </div>

                                    <div className="table-header">
                                        <label className="label is-bold">
                                            {t('thirdPartyLicense:label.id')}
                                        </label>
                                    </div>

                                    <div className="table-header">
                                        <label className="label is-bold">
                                            {t('thirdPartyLicense:label.description')}
                                        </label>
                                    </div>
                                </div>


                                <div className="table-row-group">
                                    {thirdPartyLicenses && thirdPartyLicenses.map(thirdPartyLicense =>
                                        <div className={'table-row no-pointer'}
                                             key={thirdPartyLicense.thirdPartyProduct + thirdPartyLicense.count + thirdPartyLicense.id + thirdPartyLicense.description}>
                                            <div className="table-cell">
                                                <Text value={removeUnderlineCapitalizeAll(thirdPartyLicense.thirdPartyProduct)}/>
                                            </div>

                                            <div className="table-cell">
                                                <Text value={thirdPartyLicense.count}/>
                                            </div>

                                            <div className="table-cell">
                                                <Text value={thirdPartyLicense.id} isWordWrap/>
                                            </div>

                                            <div className="table-cell">
                                                <Text value={thirdPartyLicense.description}/>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ExpandableContent>
                            }
                    </div>


                    {status &&
                    status.code === statusKeys.ERROR &&
                    <div className="display-item">
                        <ExpandableErrorAlertTable label={t('automateLicence:label.error')}
                            errors={[status.message]} operations={[]}/>
                    </div>
                    }

                    {status &&
                    status.code === statusKeys.WARNING &&
                    <div className="display-item">
                        <ExpandableWarningAlertTable label={t('automateLicence:label.warning')}
                            warnings={[status.message]}/>
                    </div>
                    }
                </>
            }

            children={
                <>
                    {isFormActive &&
                    <UpdateAutomateLicenceForm/>
                    }
                </>
            }
        />
    )
}

export default AutomateLicenceDisplay;
