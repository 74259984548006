import {useTranslation} from "react-i18next";
import {getEntries, includesSome, isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import {applicationFeatures} from "../../../utilities/constants";
import {AddButtonHeader} from "../../common/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import React from "react";
import UserServiceModel from "../../../models/user/UserServiceModel";
import UserServiceRow from "../UserServiceRow";
import {TableLabelHeader} from "../../common/CustomTable/CustomTable";

const UmsUserServiceTable = () => {
    const {t} = useTranslation(['aria', 'userService', 'common']);
    const dispatch = useDispatch();

    const canAddUserService = useSelector(state => includesSome(state.currentUser.features, [applicationFeatures.ADD_RESOURCE, applicationFeatures.MODIFY_USER_SERVICES]));
    const umsUserServiceIds = getEntries(useSelector(state => state.userServiceDetailsMap))
        .filter(([,userService]) => userService.type === UserServiceModel.types.UMS)
        .map(([id]) => id);

    function showUmsUserServiceForm() {
        dispatch(UserServiceModel.actionCreators.showForm({type: UserServiceModel.types.UMS}));
    }

    const isNotEmpty = isNotEmptyNorFalsy(umsUserServiceIds);
    if (!canAddUserService && !isNotEmpty) {
        return null;
    }

    return (
        <div className="display-item">
            <AddButtonHeader aria-label={t('aria:hiddenAssistText.addUmsUserService')} text={t('userService:label.umsUserServiceTitle')}
                canModify={canAddUserService} onClick={showUmsUserServiceForm}
            />

            {isNotEmpty &&
                <div className="display-table">
                    <div className="table-header-group">

                        <div className="table-header"/>

                        <TableLabelHeader label={t('common:label.name')} style={{width: '20rem'}}/>

                        <div className="table-header">
                            <label className="label is-bold">
                                {t('userService:label.synchronizeUsers')}
                            </label>
                        </div>

                        <div className="table-header">
                            <label className="label is-bold">
                                {t('userService:label.serviceAccountName')}
                            </label>
                        </div>
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('userService:label.searchDelay_detailed')}
                            </label>
                        </div>
                    </div>

                    <div className="table-row-group">
                        {umsUserServiceIds.map(id =>
                            <UserServiceRow key={id} type={UserServiceModel.types.UMS} userServiceId={id}/>
                        )}
                    </div>
                </div>
            }
        </div>
    );
};

export default UmsUserServiceTable;