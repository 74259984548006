import React from "react";
import {Link} from "react-router-dom";
import {buildClassName} from "../../../utilities/helperFunctions";
import {statusIcon} from "../../../utilities/iconResolver";
import {useTranslation} from "react-i18next";


function NavBarLinkItem(props) {
    const {t} = useTranslation(['aria']);
    const {
        icon,
        label,
        to,
        statusIcons,
        onClick,
        isActive,
        ariaKey,
        ...attr
    } = props;

    const className = buildClassName(
        'navbar-item',
        isActive && 'is-active'
    );

    return (
        <Link className={className} to={to} title={!label ? t(`aria:hiddenAssistText.${ariaKey}`) : null}
            {...attr}
        >
            <span className="navbar-item__content">
                <StatusIcons icons={statusIcons}/>
                {icon &&
                    <span className="navbar-icon">
                        <img src={icon} alt={t(`aria:hiddenAssistText.${ariaKey}Icon`)}/>
                    </span>
                }
                {label &&
                    <p className="navbar-label">
                        {label}
                    </p>
                }
            </span>
            <span className="navbar-selected-bar"/>
        </Link>
    )
}

export function StatusIcons(props) {
    const {t} = useTranslation(['aria']);
    const {
        icons
    } = props;

    if (!Array.isArray(icons)) {
        return null;
    }

    return (
        <>
            {Array.isArray(icons) && icons.map(iconName =>
                <span key={iconName} className="navbar-status-icon">
                    <img src={statusIcon(iconName)} alt={t(`aria:hiddenAssistText.${iconName}Icon`)}/>
                </span>
            )}
        </>
    )
}

export default NavBarLinkItem;
