import React, {useEffect} from 'react';

import {Provider, useDispatch, useSelector} from "react-redux";
import store from './redux/configureStore';
import WorkflowWizardTablet from "./WorkflowWizardTablet";
import CurrentUserModel from "../models/user/CurrentUserModel";
import LibraryModel from "../models/library/LibraryModel";
import WorkflowTemplateModel from "../models/library/WorkflowTemplateModel";
import PopupModel from "../models/scheduler/PopupModel";
import UserDataDirModel from "../models/system/UserDataDirModel";


function WorkflowWizard(props) {
    const {selectedLibraryId, uploadState} = props;
    const dispatch = useDispatch();

    const {isDisabled} = useSelector(state => state.componentStates.workflowTemplateForm);
    const {edition} = useSelector(state => state.automateLicence);

    const userDataDir = useSelector(state => state.userDataDir);
    const files = (userDataDir.files || [])
        .map(file => ({
            name: file.name,
            value: file.name,
            type: file.fileType,
        }));

    useEffect(() => {
        dispatch(CurrentUserModel.actionCreators.pollActivity(CurrentUserModel.activities.WORKFLOW_DESIGN));
        dispatch(UserDataDirModel.actionCreators.querySettings());
        return () => dispatch(CurrentUserModel.actionCreators.resetPollActivity());
    }, []);


    const hideWorkflowWizard = () => {
        dispatch(LibraryModel.componentActionCreators.updateDisplay({isWorkflowWizardActive: false}))
    }

    const addWorkflowFromWizard = (workflowName, workflowXml, description, enabled) => {
        dispatch(WorkflowTemplateModel.componentActionCreators.updateForm({operationsXml: workflowXml, workflowName, enabled: true}))
        dispatch(WorkflowTemplateModel.actionCreators.submitForm({libraryId: selectedLibraryId}))
    }

    function showWarningPopup(args) {
        dispatch(PopupModel.actionCreators.showWarning(args));
    }

    return (
        <Provider store={store}>
            <WorkflowWizardTablet libraryId={selectedLibraryId} files={files}
                onClose={hideWorkflowWizard} showWarning={showWarningPopup} onFinish={addWorkflowFromWizard}
                uploadState={uploadState} edition={edition} isDisabled={isDisabled}/>
        </Provider>
    );
}

export default WorkflowWizard;
