import React from "react";
import {useTranslation} from "react-i18next";
import {TableLabelCell, TableLabelHeader} from "../common/CustomTable/CustomTable";
import {
    bytesCountToReadableCount,
    getBytesPowAndSuffix,
    getLocaleDateTimeFromUTC,
    getMapValueName,
    getReadableStopWatch
} from "../../utilities/helperFunctions";
import {uploadInfoStateKeys} from "../../i18next/keys";
import ProgressRing from "../common/ProgressRing/ProgressRing";
import statusPaused from "../../resources/images/status/status-paused.svg";
import Text from "../common/Text/Text";
import {useSelector} from "react-redux";

function UploadInfoTable(props) {
    const {t} = useTranslation(['uploadMonitor']);

    const {
        uploadInfos
    } = props;

    return (
        <div className="display-table">
            <div className="table-header-group">
                <TableLabelHeader label={t('uploadMonitor:label.user')} style={{width: '10rem'}}/>

                <TableLabelHeader label={t('uploadMonitor:label.clientIpAddress')}/>

                <TableLabelHeader label={t('uploadMonitor:label.serverIpAddress')}/>

                <TableLabelHeader label={t('uploadMonitor:label.serverName')}/>

                <TableLabelHeader label={t('uploadMonitor:label.serverRole')}/>

                <TableLabelHeader label={t('uploadMonitor:label.client')}/>

                <TableLabelHeader label={t('uploadMonitor:label.matter')}/>

                <TableLabelHeader label={t('uploadMonitor:label.dataset')}/>

                <TableLabelHeader label={t('uploadMonitor:label.dataRepository')}/>

                <TableLabelHeader label={t('uploadMonitor:label.progress')}/>

                <TableLabelHeader label={t('uploadMonitor:label.filePath')}/>

                <TableLabelHeader label={t('uploadMonitor:label.speed')} style={{width: '6rem'}}/>

                <TableLabelHeader label={t('uploadMonitor:label.uploaded')}/>

                <TableLabelHeader label={t('uploadMonitor:label.interruptions')}/>

                <TableLabelHeader label={t('uploadMonitor:label.uploadDuration')}/>

                <TableLabelHeader label={t('uploadMonitor:label.expiryDate')}/>
            </div>

            <div className="table-row-group">
                {uploadInfos.map(uploadInfo =>
                    <UploadInfoRow key={uploadInfo.id} uploadInfo={uploadInfo}/>
                )}
            </div>
        </div>
    )
}

function selectUploadInfoNames(state, uploadInfo) {
    return {
        client: getMapValueName(state.clientDetailsMap, uploadInfo.clientId),
        matter: getMapValueName(state.matterDetailsMap, uploadInfo.matterId),
        dataset: getMapValueName(state.datasetDetailsMap, uploadInfo.datasetId),
        dataRepository: getMapValueName(state.dataRepositoryDetailsMap, uploadInfo.dataRepositoryId)
    }
}

function UploadInfoRow(props) {
    const {t} = useTranslation(['scheduler']);

    const {
        ownerKey,
        clientIpAddresses,
        serverIpAddress,
        serverName,
        serverRole,

        state,
        relativePath,
        offset,
        length,
        interruptedCount,
        uploadDuration,
        expiryDate,

        lastTransferred,
        transferInterval
    } = props.uploadInfo;

    const names = useSelector(state => selectUploadInfoNames(state, props.uploadInfo));
    const percentageComplete = ((offset / length) * 100).toFixed(0);

    const speed = transferInterval ? (lastTransferred / (transferInterval / 1000)) : 0;

    const {pow, suffix} = getBytesPowAndSuffix(length);
    const offsetFixed = (offset / Math.pow(1000, pow)).toFixed((pow > 2) ? 2 : 0);
    const lengthFixed = (length / Math.pow(1000, pow)).toFixed((pow > 2) ? 2 : 0);

    const uploadProgress = t('uploadMonitor:message.offsetOfLength', {offset: offsetFixed, length: lengthFixed, suffix});

    return (
        <div className="table-row no-hover">
            <TableLabelCell label={ownerKey} isEllipsis style={{maxWidth: '15rem'}}/>

            <TableLabelCell label={clientIpAddresses}/>

            <TableLabelCell label={serverIpAddress}/>

            <TableLabelCell label={serverName}/>

            <TableLabelCell label={serverRole && t(`scheduler:role.${serverRole}`)}/>

            <TableLabelCell label={names.client}/>

            <TableLabelCell label={names.matter}/>

            <TableLabelCell label={names.dataset}/>

            <TableLabelCell label={names.dataRepository}/>

            <div className="table-cell">
                <div className="upload-progress">
                    <Text value={percentageComplete + '%'}/>
                    {state === uploadInfoStateKeys.ACTIVE &&
                        <ProgressRing percentageComplete={percentageComplete} size={'small'}/>
                    }
                    {state === uploadInfoStateKeys.IDLE &&
                        <span className="icon is-small">
                            <img src={statusPaused} alt={t('aria:hiddenAssistText.pausedIcon')} />
                        </span>
                    }
                </div>
            </div>

            <TableLabelCell label={relativePath} style={{maxWidth: '10rem'}}/>

            <TableLabelCell label={state === uploadInfoStateKeys.ACTIVE && `${bytesCountToReadableCount(speed)}/s`}/>

            <div className="table-cell no-stretch">
                <Text value={uploadProgress} style={{textAlign: 'end'}}/>
            </div>

            <TableLabelCell label={parseInt(interruptedCount) > 0 && interruptedCount}/>

            <TableLabelCell label={getReadableStopWatch(uploadDuration)}/>

            <TableLabelCell label={state === uploadInfoStateKeys.IDLE &&getLocaleDateTimeFromUTC(expiryDate)}/>
        </div>
    )
}

export default UploadInfoTable;