import React, {useLayoutEffect, useState} from "react";
import {statusKeys} from "../../i18next/keys";
import {ExpandableStatusLog} from "../common/Common";
import {useTranslation} from "react-i18next";
import {SettingsUserIconRow, SettingsRowValue} from "../common/CustomTable/CustomTable";
import {getLocaleDateTimeFromUTC} from "../../utilities/helperFunctions";
import TabPane from "../common/TabPane/TabPane";
import WebhookEventCallBody from "./WebhookEventCallBody";
import {userIconModel} from "../../models/generics/IconModel";
import UserModel from "../../models/user/UserModel";


function WebhookEventDisplay(props) {
    const {t} = useTranslation(['webhook']);

    const {
        id,
        triggerType,
        triggeredByUser,
        status,
        epoch,
        calls,
        body,

        canViewConfidential,
        isDisabled
    } = props;

    const [triggeredByUsername, triggeredByUserIconName] = UserModel.useUserNameAndIconName(triggeredByUser);
    const userIcon = userIconModel.useIcon(triggeredByUserIconName);

    const [forceTab, setForceTab] = useState();
    useLayoutEffect(() => {
        setForceTab({
            tabIndex: calls.length - 1
        });
    }, [id]);


    return (
        <section className="tablet-view__display">

            <section className="tablet-view__display__info">

                {(status.code === statusKeys.ERROR || status.code === statusKeys.WARNING) &&
                <section className="display-item">
                    <ExpandableStatusLog {...status} isDisabled={isDisabled}/>
                </section>
                }

                <div className="settings-table display-item">
                    <div className="table-row-group">
                        <SettingsRowValue label={t('webhook:label.eventTriggerType')} value={t(`events:trigger.${triggerType}`)}
                            isDisabled={isDisabled}/>
                        <SettingsUserIconRow label={t('webhook:label.triggeredBy')} value={triggeredByUsername} icon={userIcon}
                            isDisabled={isDisabled}/>
                        <SettingsRowValue label={t('webhook:label.triggerDate')} value={getLocaleDateTimeFromUTC(epoch)}
                            isDisabled={isDisabled}/>
                    </div>
                </div>

                <section className="display-item">
                    <TabPane force={forceTab}
                        labels={calls.map(call => t('webhook:label.attempt', {number: call.attemptNumber}))}
                    >
                        {calls.map((call, index) =>
                            <WebhookEventCallBody key={index} {...call} requestBody={body}
                                canViewConfidential={canViewConfidential} isDisabled={isDisabled}/>
                        )}
                    </TabPane>
                </section>
            </section>
        </section>
    )
}


export default WebhookEventDisplay;
