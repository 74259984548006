import React from 'react';
import './StepIcon.css'

function StepIcon(props) {
    const {current, total, isDisabled} = props;

    const disabled = isDisabled ? ' is-disabled' : '';

    function generateSteps () {
        let steps = [];
        for (let i = 1; i <= total; i++) {
            i === current ?
                steps.push(<span className={"step-selected"} key={`step_${i}`}/>) : steps.push(<span className={'step'} key={`step_${i}`}/>);
            if (i !== total) {
                steps.push(<span className={"step-separator"} key={`separator_${i}`}/>)
            }
        }
        return steps;
    }
    const stepsIcon = generateSteps();

    return (
        <span className={"steps " + disabled}>
            {stepsIcon}
        </span>
    );
}

export default StepIcon;
