import React from 'react';
import Checkbox from "../../../../components/common/Checkbox/Checkbox";

import '../questionStyle.css'

export function QuestionCheckBox (props) {
    const {question, dataName, options, onClick} = props;

    return (
        <div className={"display-item"}>
            {question.length > 0 &&
                <label className="label is-heading">
                    {question}
                </label>
            }
            <div className={'settings-table no-border tight-padding'} style={{border: '1px solid transparent'}}>
                <div className="table-row-group">
                    {options.map(option => {
                        return (
                            <>
                                {!(option.hasOwnProperty('dependant')) ?
                                    <div key={`${option.id}_row`} className="settings-row">
                                        <div key={`${option.id}_cell`} className="table-cell no-stretch">
                                            <Checkbox
                                                name={option.id || dataName}
                                                label={option.value}
                                                checked={option.checked}
                                                onClick={onClick}
                                                isWordWrap={false}/>
                                        </div>
                                    </div>
                                :
                                    <>
                                        {option.dependant &&
                                        <div key={`${option.id}_row`} className="settings-row">
                                                <div key={`${option.id}_cell`} className="table-cell no-stretch">
                                                    <Checkbox
                                                        name={option.id || dataName}
                                                        label={option.value}
                                                        checked={option.checked}
                                                        onClick={onClick}
                                                        isWordWrap={false}
                                                        style={{paddingLeft: '1rem'}}/>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </>
                        )
                    })}
                    <div key={"question_place"} className="table-cell no-stretch"/>
                </div>
            </div>
        </div>
    )
}

export function NestedStateCheckBox (props) {
    const {question, dataName, options, onClick, nested} = props;

    const update = (event) => {
        const {name, checked} = event.target;
        return onClick({target: {name: name, checked: checked, nested: nested}});
    }

    return (
        <QuestionCheckBox question={question} dataName={dataName} options={options} onClick={update}/>
    )
}

export function WizardCheckBox (props) {
    const {dataName, options, onCheck} = props;
    const {id, value, checked} = options;

    return (
        <div className={"display-item"}>
            <Checkbox
                name={id || dataName}
                label={value}
                checked={checked}
                onClick={onCheck}
                isWordWrap={false}/>
        </div>
    )
}