import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectJobFromDisplay} from "./selectors";
import {executionStateKeys} from "../../i18next/keys";
import {jobQueueStates} from "../../utilities/constants";
import HighlightText from "../userSettings/HighlightOption/HighlightText";
import React from "react";

function JobStatus(props) {
    const {t} = useTranslation(['job']);

    const {
        jobId,
        hidePercentage,
        enableHighlightText
    } = props;

    const job = useSelector(state => selectJobFromDisplay(state, jobId));
    const executionState = props.executionState || job.executionState;
    const percentageComplete = props.percentageComplete || job.percentageComplete;
    const hasWarnings = props.hasWarnings || job.hasWarnings;
    const hasSoftErrors = props.hasSoftErrors || job.hasSoftErrors;

    let text;
    switch (executionState) {
        case executionStateKeys.NOT_STARTED:
            if (job.queueState === jobQueueStates.STAGING) {
                text = t(`job:executionState.${job.queueState}`);
            } else if (job.queueState === jobQueueStates.BACKLOG) {
                text = t(`job:executionState.${executionStateKeys.NOT_STARTED}`);
            }
            break;
        case executionStateKeys.PENDING:
            text = t('job:message.startingPercentage', {percentage: percentageComplete});
            break;
        case executionStateKeys.FINISHED:
            if (hasSoftErrors) {
                text = t('job:message.finishedSoftErrors');
            } else if (hasWarnings) {
                text = t('job:message.finishedWarnings');
            } else {
                text = t(`job:executionState.${executionStateKeys.FINISHED}`);
            }
            break;
        default:
            if (hidePercentage || !percentageComplete) {
                text = t(`job:executionState.${executionState}`);
            } else {
                text = t('job:message.executionStatePercentage', {executionState, percentage: percentageComplete});
            }
    }

    return (
        <HighlightText text={text} isDisabled={!enableHighlightText}/>
    )
}

export default JobStatus;
