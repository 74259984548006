import React, {Component} from 'react';
import {connect} from 'react-redux';
import {clearOnFirstCallback, getSafeClose, warnOnClose} from "../../utilities/componentFunctions";
import {shouldEnableExecutionProfile} from "../../utilities/shouldEnableFunctions";
import {useTranslation, withTranslation} from "react-i18next";
import Form, {FormFooter, FormHeader} from "../common/Form/Form";
import TextArea from "../common/TextArea/TextArea";
import InputTable from "../common/InputTable/InputTable";
import {
    onInputTableAddInput,
    onInputTableDeleteInput,
    onWorkflowParameterInputTableBlur,
    onWorkflowParameterInputTableChange
} from "../common/InputTable/helpers";
import {updateMode} from "../../utilities/constants";
import PopupModel from "../../models/scheduler/PopupModel";
import ExecutionProfileModel from "../../models/settings/ExecutionProfileModel";
import ListContainer, {SwitchListContainers} from "../common/ListContainer/ListContainer";
import {onAddItemsToList, onSelectListItem} from "../common/ListContainer/helpers";
import {objectTruthyValues, stringToBool} from "../../utilities/helperFunctions";
import {getNotificationRuleNameValues} from "../../reselect/selectors";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import Checkbox from "../common/Checkbox/Checkbox";
import ToggleSwitch from "../common/ToggleSwitch/ToggleSwitch";
import {FileParameterTable, ProfileTable} from "../fileLibrary/FileLibraryTables";
import HighlightHTMLTextInput from "../common/HTMLTextInput/HighlightHTMLTextInput";
import HighlightTextArea from "../common/TextArea/HighlightTextArea";


class ExecutionProfileForm extends Component {

    constructor(props) {
        super(props);

        const defaultValues = [{value: ''}, {value: ''}];
        this.state = {
            executionProfileName: '',
            description: '',
            useServiceAccount: false,
            engineAccountUsername: '',
            workflowParameters: [[...defaultValues], [...defaultValues]],
            nuixProfiles: [],
            additionalFiles: [],
            engineCommandLineParameters: '',
            engineLogFolder: '',
            nuixEngineFolder: '',
            javaFolder: '',
            schedulerUrl: window.location.origin + window.location.pathname,
            availableNotificationRules: {
                items: [...props.notificationRuleNameValues],
                selectedItems: {}
            },
            selectedNotificationRules: {
                items: [],
                selectedItems: {}
            },
            timeoutSettingEnabled: {
                job: false,
                operation: false,
                skipOperation: false
            },
            jobTimeoutSettings: {
                jobProgressMinPercentage: 1,
                jobProgressTimeoutHours: 48,
                operationProgressMinPercentage: 1,
                operationProgressTimeoutHours: 24,
                skipOperationProgressMinPercentage: 1,
                skipOperationProgressTimeoutHours: 12
            },
            ...props.formProps,
            id: null,
            duplicateReferenceId: props.formProps.id
        };

        this.passwordRef = React.createRef();

        this.onInputChange = this.onInputChange.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.onTableUpdate = this.onTableUpdate.bind(this);
        this.onTimeoutSettingToggle = this.onTimeoutSettingToggle.bind(this);
        this.onTimeoutSettingInputChange = this.onTimeoutSettingInputChange.bind(this);
        this.onAddClick = this.onAddClick.bind(this);

        this.getSafeClose = getSafeClose('state', ['availableNotificationRules']).bind(this);
        this.warnOnClose = warnOnClose(this.props.t('executionProfile:label.name')).bind(this);

        this.clearOnFirstCallback = clearOnFirstCallback.bind(this);
        this.onAddWorkflowParameter = onInputTableAddInput(defaultValues, {
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'workflowParameters'
        }).bind(this);
        this.onDeleteWorkflowParameter = onInputTableDeleteInput({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'workflowParameters',
        }).bind(this);
        this.onWorkflowParameterChange = onWorkflowParameterInputTableChange({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'workflowParameters',
        }).bind(this);
        this.onWorkflowParameterBlur = onWorkflowParameterInputTableBlur({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'workflowParameters',
        }).bind(this);

        this.onAddNotificationRules = onAddItemsToList({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listTo: 'selectedNotificationRules',
            listFrom: 'availableNotificationRules'
        }).bind(this);
        this.onRemoveNotificationRules = onAddItemsToList({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listTo: 'availableNotificationRules',
            listFrom: 'selectedNotificationRules'
        }).bind(this);
        this.onSelectAvailableNotificationRules = onSelectListItem({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'availableNotificationRules'
        }).bind(this);
        this.onSelectSelectedNotificationRules = onSelectListItem({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'selectedNotificationRules'
        }).bind(this);
    }

    onInputChange(event) {
        const {name, value, type} = event.target;

        const updates = {};
        // Using same inputHandler for password field for isAddEnabled logic
        // Do not track password value
        if (type !== 'password')
            updates[name] = value;

        // Clear password when user changes value for below
        if (['engineAccountUsername'].includes(name)) {
            this.passwordRef.current.value = '';
        }

        this.setState(prevState => {
            return {
                ...prevState,
                ...updates
            }
        });
    }

    onToggle(event) {
        const {name, checked} = event.currentTarget;
        this.setState(prevState => ({
            ...prevState,
            [name]: stringToBool(checked)
        }));
    }

    onTimeoutSettingToggle(event) {
        const {name, checked} = event.currentTarget.dataset;

        this.setState(prevState => ({
            ...prevState,
            timeoutSettingEnabled: {
                ...prevState.timeoutSettingEnabled,
                [name]: stringToBool(checked)
            }
        }));
    }

    onTimeoutSettingInputChange(event) {
        const {name, value} = event.target;

        this.setState(prevState => ({
            ...prevState,
            jobTimeoutSettings: {
                ...prevState.jobTimeoutSettings,
                [name]: value
            }
        }));
    }

    onTableUpdate(updates) {
        this.setState(prevState => ({
            ...prevState,
            ...updates
        }));
    }

    updateState(updates) {

    }

    onAddClick() {
        this.props.addExecutionProfile(this.state);
    }

    render() {

        const {
            t,
            isDisabled,
            showError
        } = this.props;
        const {
            executionProfileName,
            description,
            useServiceAccount,
            engineAccountUsername,
            workflowParameters,
            nuixProfiles,
            additionalFiles,
            engineCommandLineParameters,
            engineLogFolder,
            nuixEngineFolder,
            javaFolder,
            schedulerUrl,
            availableNotificationRules,
            selectedNotificationRules,
            timeoutSettingEnabled,
            jobTimeoutSettings
        } = this.state;
        const close = this.getSafeClose();

        const nameInputProps = {
            label: t('common:label.name'),
            name: 'executionProfileName',
            value: executionProfileName,
            onChange: this.onInputChange,
            isRequired: true,
            isDisabled
        };
        const descriptionAreaProps = {
            label: t('common:label.description'),
            name: 'description',
            value: description,
            onChange: this.onInputChange,
            isDisabled
        };

        const usernameInputProps = {
            label: t('common:label.username'),
            name: 'engineAccountUsername',
            value: engineAccountUsername,
            onChange: this.onInputChange,
            isDisabled
        };

        const commandLineParametersTextAreaProps = {
            id: 'engineCommandLineParameters',
            label: t('executionProfile:label.commandLineParameters'),
            name: 'engineCommandLineParameters',
            value: engineCommandLineParameters,
            onChange: this.onInputChange,
            isDisabled
        };

        const logFolderInputProps = {
            id: 'engineLogFolder',
            label: t('executionProfile:label.engineLogFolder'),
            name: 'engineLogFolder',
            value: engineLogFolder,
            onChange: this.onInputChange,
            isRequired: true,
            isDisabled
        };
        const nuixEngineFolderProps = {
            id: 'nuixEngineFolder',
            label: t('executionProfile:label.nuixEngineFolder'),
            name: 'nuixEngineFolder',
            value: nuixEngineFolder,
            onChange: this.onInputChange,
            isDisabled
        };
        const javaFolderProps = {
            label: t('executionProfile:label.javaFolder'),
            name: 'javaFolder',
            value: javaFolder,
            onChange: this.onInputChange,
            isDisabled
        };
        const schedulerUrlInputProps = {
            label: t('executionProfile:label.schedulerUrl'),
            name: 'schedulerUrl',
            value: schedulerUrl,
            onChange: this.onInputChange,
            isDisabled
        };
        const workflowParametersInputTableProps = {
            id: 'workflowParametersInputTable',
            label: t('executionProfile:label.workflowParameters'),
            headers: [{title: t('workflow:label.parameterName')}, {title: t('common:label.value')}],
            rows: workflowParameters,
            onValueChange: this.onWorkflowParameterChange,
            onValueBlur: this.onWorkflowParameterBlur,
            onAddClick: this.onAddWorkflowParameter,
            onDeleteClick: this.onDeleteWorkflowParameter
        };

        const nuixProfilesTableInputProps = {
            profiles: nuixProfiles,
            stateName: "nuixProfiles",
            onUpdate: this.onTableUpdate,
            showError,
            isDisabled
        }

        const additionalFilesTableInputProps = {
            files: additionalFiles,
            stateName: "additionalFiles",
            onUpdate: this.onTableUpdate,
            showError,
            isDisabled
        }

        const notificationRuleNavigateButtonsProps = {
            ariaLabelKey: 'Rule',
            onClick: [
                this.onAddNotificationRules,
                this.onRemoveNotificationRules,
            ],
            length: [
                objectTruthyValues(availableNotificationRules.selectedItems).length,
                objectTruthyValues(selectedNotificationRules.selectedItems).length,
            ],
            isDisabled
        };

        const duplicateReferencePasswordPlaceholder = this.state.duplicateReferenceId != null ? this.state.engineAccountPassword : '';
        const footerProps = {
            addText: t('common:button.addExecutionProfile'),
            onAddClick: this.onAddClick,
            onCancel: close,
            isAddEnabled: shouldEnableExecutionProfile({
                ...this.state, engineAccountPassword: this.passwordRef.current ? this.passwordRef.current.value : duplicateReferencePasswordPlaceholder
            }),
            isDisabled
    };

        const disabled = isDisabled ? ' is-disabled' : '';

        return (
          <Form width={'60rem'} onClose={close} isDisabled={isDisabled} closeButtonAriaLabel={t('executionProfile:option.closeForm')}
            header={
                <FormHeader text={t(`executionProfile:label.name`)} iconName={'executionProfile'}
                  isDisabled={isDisabled}/>
            }
            body={
                <>
                    <div className="display-input">
                        <HTMLTextInput {...nameInputProps}/>
                    </div>
                    <div className="display-input">
                        <HighlightHTMLTextInput {...logFolderInputProps}/>
                    </div>

                    <div className="display-input">
                        <TextArea {...descriptionAreaProps}/>
                    </div>

                    <div className="display-input">
                        <Checkbox label={t('executionProfile:label.useServiceAccount')} name={'useServiceAccount'} checked={useServiceAccount}
                            onClick={this.onToggle} isDisabled={isDisabled}/>
                    </div>
                    {useServiceAccount &&
                        <>
                            <div className="display-input">
                                <HTMLTextInput {...usernameInputProps}/>
                            </div>
                            <div className="display-input">
                                <HTMLTextInput inputRef={this.passwordRef} label={t('common:label.password')}
                                  name={'engineAccountPassword'} type={'password'} defaultValue={duplicateReferencePasswordPlaceholder}
                                  onChange={this.onInputChange} onBeforeInput={this.clearOnFirstCallback} isDisabled={isDisabled}
                                  isRequired={engineAccountUsername.length > 0}/>
                            </div>
                        </>
                    }

                    <div className="display-input">
                        <HighlightTextArea {...commandLineParametersTextAreaProps}/>
                    </div>
                    <div className="display-input">
                        <HighlightHTMLTextInput {...nuixEngineFolderProps}/>
                    </div>

                    <div className="display-input">
                        <HTMLTextInput {...javaFolderProps}/>
                    </div>

                    <div className="display-input">
                        <HTMLTextInput {...schedulerUrlInputProps}/>
                    </div>

                    <div className="display-input">
                        <InputTable ariaLabelKey={'Parameter'}
                            {...workflowParametersInputTableProps} />
                    </div>

                    <div className="display-input">
                        <label className={'label' + disabled}>
                            {t('executionProfile:label.notificationRules')}
                        </label>
                        <SwitchListContainers id="notificationRuleSwitchContainer"
                          navigateButtonsProps={notificationRuleNavigateButtonsProps}
                          leftContainer={
                              <ListContainer label={t('common:label.available')} {...availableNotificationRules}
                                onItemClick={this.onSelectAvailableNotificationRules} isDisabled={isDisabled}/>
                          }
                          rightContainer={
                              <ListContainer label={t('common:label.selected')} {...selectedNotificationRules}
                                onItemClick={this.onSelectSelectedNotificationRules} isDisabled={isDisabled}/>
                          }
                        />
                    </div>

                    <div className="display-input">
                        <label className={'label' + disabled}>
                            {t('executionProfile:label.timeoutSettings')}
                        </label>

                        <TimeoutSettings timeoutSettingEnabled={timeoutSettingEnabled} onTimeoutSettingToggle={this.onTimeoutSettingToggle}
                          jobTimeoutSettings={jobTimeoutSettings} onTimeoutSettingChange={this.onTimeoutSettingInputChange}
                        />
                    </div>

                    <div className={"display-input"}>
                        <label className={'label' + disabled}>
                            {t('executionProfile:label.nuixProfilesHeader')}
                        </label>

                        <ProfileTable {...nuixProfilesTableInputProps}/>
                    </div>

                    <div className={"display-input"}>
                        <label className={'label' + disabled}>
                            {t('executionProfile:label.additionalFilesHeader')}
                        </label>

                        <FileParameterTable {...additionalFilesTableInputProps}/>
                    </div>
                </>
            }
            footer={
                <FormFooter {...footerProps} />
            }
          />
        )
    }
}

export function TimeoutSettings(props) {
    const {timeoutSettingEnabled, jobTimeoutSettings, onTimeoutSettingToggle, onTimeoutSettingChange, isDisabled, ...attr} = props;

    return (jobTimeoutSettings &&
      <div className="settings-table align-middle no-table-padding no-border" {...attr}>

          <TimeoutSettingRow
            name={'job'}
            enabled={timeoutSettingEnabled.job}
            percentage={jobTimeoutSettings.jobProgressMinPercentage}
            hours={jobTimeoutSettings.jobProgressTimeoutHours}

            onToggle={onTimeoutSettingToggle}
            onInputChange={onTimeoutSettingChange}
            isDisabled={isDisabled}
          />

          <TimeoutSettingRow
            name={'operation'}
            enabled={timeoutSettingEnabled.operation}
            percentage={jobTimeoutSettings.operationProgressMinPercentage}
            hours={jobTimeoutSettings.operationProgressTimeoutHours}

            onToggle={onTimeoutSettingToggle}
            onInputChange={onTimeoutSettingChange}
            isDisabled={isDisabled}
          />

          <TimeoutSettingRow
              name={'skipOperation'}
              enabled={timeoutSettingEnabled.skipOperation}
              percentage={jobTimeoutSettings.skipOperationProgressMinPercentage}
              hours={jobTimeoutSettings.skipOperationProgressTimeoutHours}

              onToggle={onTimeoutSettingToggle}
              onInputChange={onTimeoutSettingChange}
              isDisabled={isDisabled}
          />

      </div>
    )
}

function TimeoutSettingRow(props) {
    const {name, enabled, percentage, hours, onToggle, onInputChange, isDisabled} = props;
    const {t} = useTranslation(['executionProfile']);

    const disabled = (!enabled || isDisabled) ? ' is-disabled' : '';
    return (
      <div className="settings-row">
          <div className="table-cell no-stretch">
              <ToggleSwitch name={name} checked={enabled} onClick={onToggle} isWordWrap={false}
                label={`${t(`executionProfile:label.${name}TimeoutSetting_1`)}:`}
              />
          </div>

          <div className="table-cell" style={{display: 'flex', alignItems: 'center'}}>
              <HTMLTextInput id={`executionProfile${name}ProgressMinPercentage`} style={{width: '3.5rem', marginRight: '0.5rem'}}
                type="number" min={0} max={100}
                name={`${name}ProgressMinPercentage`} value={percentage} onChange={onInputChange}
                isDisabled={!enabled || isDisabled}
              />

              <label htmlFor={`executionProfile${name}ProgressMinPercentage`} className={'label no-wrap' + disabled}>
                  {`% ${t(`executionProfile:label.${name}TimeoutSetting_2`)}`}
              </label>

              <HTMLTextInput id={`executionProfile${name}ProgressTimeoutHours`} style={{width: '3.5rem', margin: '0 0.5rem'}}
                type="number" min={0}
                name={`${name}ProgressTimeoutHours`} value={hours} onChange={onInputChange}
                isDisabled={!enabled || isDisabled}
              />

              <label htmlFor={`executionProfile${name}ProgressTimeoutHours`} className={'label no-wrap' + disabled}>
                  {t(`executionProfile:label.${name}TimeoutSetting_3`)}
              </label>
          </div>
      </div>
    )
}

const mapStateToProps = state => {
    const {isDisabled, ...formProps} = state.componentStates.executionProfileForm;

    return {
        notificationRuleNameValues: getNotificationRuleNameValues(state),
        formProps,
        isDisabled
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addExecutionProfile: executionProfile => dispatch(ExecutionProfileModel.actionCreators.submitForm(executionProfile)),
        close: () => dispatch(ExecutionProfileModel.actionCreators.hideForm()),

        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        showError: payload => dispatch(PopupModel.actionCreators.showError(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['executionProfile', 'workflow', 'common'])(ExecutionProfileForm));
