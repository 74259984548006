import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {createInputHandler, createStateHandler, createUpdateHandler} from "../../../utilities/componentFunctions";
import LegalHoldModel from "../../../models/legalhold/LegalHoldModel";
import {stringToBool} from "../../../utilities/helperFunctions";
import NoticeConfigurationTable from "../../notice/NoticeConfigurationTable";
import {createToggleHandler} from "../../common/Checkbox/helpers";
import {createDropdownHandler} from "../../common/Dropdown/helpers";
import {noticeTypeKeys} from "../../../utilities/constants";
import Text from "../../common/Text/Text";
import {legalHoldStateKeys, permissionKeys} from "../../../i18next/keys";
import Checkbox from "../../common/Checkbox/Checkbox";
import SurveyNoticeConfigurationTable from "./SurveyNoticeConfigurationTable";


function HoldNoticeConfigurationsPane() {
    const {t} = useTranslation(['legalHold', 'workflow', 'common']);
    const dispatch = useDispatch();

    const {
        state,
        silent,
        holdNoticeConfiguration,
        releaseNoticeConfiguration,
        surveyNoticeConfigurations,
        recurringNoticeConfiguration,

        userPermissions,
        isDisabled: isFormDisabled
    } = useSelector(state => state.componentStates.legalHoldForm);


    const reduxHandler = createStateHandler({
        updateState: updates => dispatch(LegalHoldModel.componentActionCreators.updateForm(updates))
    });

    const holdNoticeConfigurationHandler = createUpdateHandler({
        handler: reduxHandler,
        name: 'holdNoticeConfiguration',
        values: holdNoticeConfiguration
    });
    const releaseNoticeConfigurationHandler = createUpdateHandler({
        handler: reduxHandler,
        name: 'releaseNoticeConfiguration',
        values: releaseNoticeConfiguration
    });
    const surveyNoticeConfigurationsHandler = createUpdateHandler({
        handler: reduxHandler,
        name: 'surveyNoticeConfigurations',
        values: surveyNoticeConfigurations
    });
    const recurringNoticeConfigurationHandler = createUpdateHandler({
        handler: reduxHandler,
        name: 'recurringNoticeConfiguration',
        values: recurringNoticeConfiguration
    })

    const holdNoticeHandler = updates => holdNoticeConfigurationHandler(prevNotice => prevNotice.duplicate(updates));
    const holdNoticeInputHandler = createInputHandler({
        handler: holdNoticeHandler
    });
    const holdNoticeToggleHandler = createToggleHandler({
        handler: holdNoticeHandler
    });
    const holdDropdownHandler = createDropdownHandler({
        handler: holdNoticeHandler
    });

    const releaseNoticeHandler = updates => releaseNoticeConfigurationHandler(prevNotice => prevNotice.duplicate(updates));
    const releaseNoticeInputHandler = createInputHandler({
        handler: releaseNoticeHandler
    });
    const releaseNoticeToggleHandler = createToggleHandler({
        handler: releaseNoticeHandler
    });
    const releaseDropdownHandler = createDropdownHandler({
        handler: releaseNoticeHandler
    });

    const recurringNoticeHandler = updates => recurringNoticeConfigurationHandler(prevNotice => prevNotice.duplicate(updates));
    const recurringNoticeInputHandler = createInputHandler({
        handler: recurringNoticeHandler
    });
    const recurringNoticeToggleHandler = createToggleHandler({
        handler: recurringNoticeHandler
    });
    const recurringNoticeDropdownHandler = createDropdownHandler({
        handler: recurringNoticeHandler
    });

    function silentToggleHandler(event) {
        const {name, checked} = event.target;
        reduxHandler({[name]: stringToBool(!checked)});
    }


    const isDraft = state === legalHoldStateKeys.DRAFT;
    const isActive = state === legalHoldStateKeys.ACTIVE;
    const cantModify = userPermissions != null && !userPermissions.includes(permissionKeys.MODIFY);

    const isSilentDisabled = isFormDisabled || !isDraft || cantModify;
    // Can only modify when DRAFT or ACTIVE
    const areConfigurationsDisabled = isFormDisabled || (!isDraft && !isActive) || cantModify;

    return (
        <div className="pane-margin">
            <div style={{marginTop: '0.75rem'}}>
                <Checkbox label={t('legalHold:option.sendNotices')} name={'silent'} checked={!silent}
                    onClick={silentToggleHandler} isDisabled={isSilentDisabled}/>
            </div>

            {!silent &&
            <>
                <div className="display-input">
                    <Text value={t('legalHold:label.holdNoticeConfiguration')}
                        isDisabled={areConfigurationsDisabled}/>

                    <div className="display-table">
                        <div className="table-header-group">
                            <div className="table-header"/>
                        </div>

                        <div className="table-row-group">
                            <div className="table-row no-hover">
                                <div className="table-cell no-padding no-border">
                                    <NoticeConfigurationTable id={'holdNoticeConfiguration'} type={noticeTypeKeys.HOLD} noticeConfiguration={holdNoticeConfiguration}
                                        inputHandler={holdNoticeInputHandler} toggleHandler={holdNoticeToggleHandler} dropdownHandler={holdDropdownHandler}
                                        isDisabled={areConfigurationsDisabled}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="display-input">
                    <Text value={t('legalHold:label.recurringNoticeConfiguration')}
                           isDisabled={areConfigurationsDisabled}/>

                    <div className="display-table">
                        <div className="table-header-group">
                            <div className="table-header"/>
                        </div>

                        <div className="table-row-group">
                            <div className="table-row no-hover">
                                <div className="table-cell no-padding no-border">
                                    <NoticeConfigurationTable id={'recurringNoticeConfiguration'} type={noticeTypeKeys.RECURRING} noticeConfiguration={recurringNoticeConfiguration}
                                                              inputHandler={recurringNoticeInputHandler} toggleHandler={recurringNoticeToggleHandler} dropdownHandler={recurringNoticeDropdownHandler}
                                                              isDisabled={areConfigurationsDisabled}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="display-input">
                    <Text value={t('legalHold:label.releaseNoticeConfiguration')}
                        isDisabled={areConfigurationsDisabled}/>

                    <div className="display-table">
                        <div className="table-header-group">
                            <div className="table-header"/>
                        </div>

                        <div className="table-row-group">
                            <div className="table-row no-hover">
                                <div className="table-cell no-padding no-border">
                                    <NoticeConfigurationTable id={'releaseNoticeConfiguration'} type={noticeTypeKeys.RELEASE} noticeConfiguration={releaseNoticeConfiguration}
                                        inputHandler={releaseNoticeInputHandler} toggleHandler={releaseNoticeToggleHandler} dropdownHandler={releaseDropdownHandler}
                                        isDisabled={areConfigurationsDisabled}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="display-input">
                    <SurveyNoticeConfigurationTable state={state} surveyNoticeConfigurations={surveyNoticeConfigurations}
                        setSurveyNoticeConfigurations={surveyNoticeConfigurationsHandler} isDisabled={areConfigurationsDisabled}/>
                </div>
            </>
            }
        </div>
    )
}

export default HoldNoticeConfigurationsPane;
