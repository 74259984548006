import ReduxModel from "../generics/ReduxModel";

class ThirdPartyUserCredential extends ReduxModel {

    constructor(model={}) {
        super();
        this.forceUpdate(model);
    }

    static Type = {
        PURVIEW: 'PurviewUserCredential',
        RELATIVITY: 'RelativityUserCredential',
        DISCOVER: 'DiscoverUserCredential',
        NLP: 'NlpUserCredential',
        ECC: 'EccUserCredential',
        DERBY_CONTROL: 'DerbyControlUserCredential',
        GRAPH: 'GraphUserCredential',
        ELASTICSEARCH: 'ElasticsearchUserCredential',
        GEN_AI: 'GenAiUserCredential',
        SEMANTIC: 'SemanticUserCredential',
        SMTP: 'SmtpUserCredential',
        DUMMY: 'DummyUserCredential'
    }

    static isInvalid(userCredential) {
        return !userCredential.signedIn || userCredential.expired;
    }

    isInvalid() {
        return ThirdPartyUserCredential.isInvalid(this);
    }
}

export default ThirdPartyUserCredential;
