import React from "react";
import {connect} from "react-redux";

import './useCase.css'
import '../../../wizard-general.css'
import {updateGenericValues} from "../../../redux/WizardSlice/wizardSlice";
import QuestionRadio from "../../Question/QuestionRadio/QuestionRadio";
import {QuestionInput} from "../../Question/QuestionInput/QuestionInput";
import CaseExampleGroup from "../../Text/CaseExampleGroup/CaseExampleGroup";
import {useTranslation} from "react-i18next";
import {getExampleType} from "../../../utilities/helperFunctions";
import {createInputHandler, createValueHandler} from "../../../../utilities/componentFunctions";


function UseCase(props) {
    const {updateState} = props;
    const {t} = useTranslation(['wizard']);

    const {
        caseStructure,
        baseFolderPath
    } = props;


    const questions = [
        {
            question: t('wizard:questions.case.caseStructure'),
            options: [
                {id: 1, value: t('wizard:questions.case.options.clientMatter')},
                {id: 2, value: t('wizard:questions.case.options.singleFolder')},
                {id: 3, value: t('wizard:questions.case.options.workflowExecution')}
            ]
        },
        {
            question: t('wizard:questions.case.folderLocation')
        }
    ]

    const inputHandler = createInputHandler({
        handler: updateState
    })

    const valuesHandler = createValueHandler({
        handler: updateState
    })

    const exampleType = getExampleType(caseStructure);

    return (
        <div className={"wizard-step-container"}>
            <QuestionRadio
                question={questions[0].question}
                dataName={"caseStructure"}
                options={questions[0].options}
                selectedValue={caseStructure}
                onChange={valuesHandler} />

            {exampleType !== "NONE" &&
                <QuestionInput
                    name={"baseFolderPath"}
                    question={questions[1].question}
                    value={baseFolderPath}
                    onChange={inputHandler}
                    isRequired={true} />
            }

            {exampleType !== "NONE" &&
                <div className={"dynamic-examples-container"}>
                    <CaseExampleGroup
                        baseFolder={baseFolderPath}
                        type={exampleType} />
                </div>

            }

        </div>
    );
}

function mapStateToProps (state) {
    const {wizardSlice:{caseSettings:{caseStructure, baseFolderPath}}} = state;
    return {
        caseStructure,
        baseFolderPath
    };
}

function mapDispatchToProps (dispatch) {
    return {
        updateState: updates => dispatch(updateGenericValues({stateName: "caseSettings", updates: updates}))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(UseCase);