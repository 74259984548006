import DetailsModel, {DetailsApi, DetailsSaga} from "../generics/DetailsModel";
import ComponentStateModel from "../generics/ComponentStateModel";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import {all, call, put, select} from "redux-saga/effects";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import {details, routes, SLOW_QUERY_INTERVAL} from "../../utilities/constants";
import {contextCall} from "../../saga/sagaFunctions";
import {getValues, nameLocaleCompare} from "../../utilities/helperFunctions";
import {separateItems, separateNameValues} from "../../components/common/ListContainer/helpers";
import {jobEventTriggerKeys, settingsDisplayKeys} from "../../i18next/keys";
import {genericErrorHandler} from "../../saga/tryCatchWrapper";
import i18next from "i18next";
import PopupModel from "../scheduler/PopupModel";
import SchedulerModel from "../scheduler/SchedulerModel";
import i18n from "../../i18next/i18n";
import ThirdPartyServiceModel from "../thirdparty/ThirdPartyServiceModel";

class NotificationRuleModel extends DetailsModel {

    static nom = 'NotificationRuleModel';
    static actions = NotificationRuleModel.buildActions('NOTIFICATION_RULE');
    static actionCreators = NotificationRuleModel.buildActionCreators(NotificationRuleModel.actions);
    static reducer = NotificationRuleModel.buildReducer(NotificationRuleModel.actions);

    static componentActionCreators = {
        ...NotificationRuleModel.buildComponentUpdateActionCreators(),
        ...NotificationRuleModel.buildComponentSetActiveActionCreators()
    };

    constructor(model = {}) {
        super(model);

        this.ruleType = model.ruleType;
        this.triggers = model.triggers;

        this.smtpServiceId = model.smtpServiceId;
        this.to = model.to;
        this.cc = model.cc;
        this.htmlFormat = model.htmlFormat;
        this.emailCounter = model.emailCounter;

        this.url = model.url;
        this.webhookPlatform = model.webhookPlatform;
    }

    static Type = {
        EMAIL_SMTP: 'EMAIL_SMTP',
        WEBHOOK: 'WEBHOOK'
    }

    static getSimpleType(ruleType) {
        switch (ruleType) {
            case NotificationRuleModel.Type.EMAIL_SMTP:
                return 'Email';
            case NotificationRuleModel.Type.WEBHOOK:
                return 'Webhook';
        }
    }

    static validateFormData(formData) {
        switch (formData.ruleType) {
            case NotificationRuleModel.Type.EMAIL_SMTP:
                return !!(formData.emailNotificationRuleName && formData.to && formData.smtpServiceId);
            case NotificationRuleModel.Type.WEBHOOK:
                return !!(formData.webhookNotificationRuleName && formData.webhookUrl);
        }
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'notificationRuleDisplay',
                type: 'Display',
                state: {
                    notificationRuleId: null,
                    isEmailNotificationRuleFormActive: false,
                    isWebhookNotificationRuleFormActive: false
                }
            },
            {
                key: 'notificationRuleForm',
                type: 'Form',
                state: {
                    isDisabled: false
                }
            },
            {
                key: 'notificationRuleTablet',
                type: 'Tablet',
                state: {
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'NOTIFICATION_RULE_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }
}

export class NotificationRuleApi extends DetailsApi {

    static location = '/resources';
    static type = '/notificationRules';

    static putTest(id) {
        return axiosInstance.put(`/scheduler/resources/notificationRules/${id}/test`);
    }

    static postEmail(data) {
        return axiosInstance.post('/scheduler/resources/notificationRules/email/', data);
    }

    static postEmailTest(data) {
        return axiosInstance.post('/scheduler/resources/notificationRules/email/test', data);
    }

    static putEmailDetails(id, details) {
        return axiosInstance.put(`/scheduler/resources/notificationRules/email/${id}`, details);
    }

    static postWebhook(data) {
        return axiosInstance.post('/scheduler/resources/notificationRules/webhook/', data);
    }

    static postWebhookTest(data) {
        return axiosInstance.post('/scheduler/resources/notificationRules/webhook/test', data);
    }

    static putWebhookDetails(id, details) {
        return axiosInstance.put(`/scheduler/resources/notificationRules/webhook/${id}`, details);
    }
}

export class NotificationRuleSaga extends DetailsSaga {

    static ModelType = NotificationRuleModel;
    static ModelApi = NotificationRuleApi;

    static activationComponent = 'NOTIFICATION_RULE_DISPLAY';
    static variableNames = {
        detailsMap: 'notificationRuleDetailsMap',
        instanceId: 'notificationRuleId',
        updateDisplay: 'updateDisplay',
        updatePane: 'updateTablet',
        route: routes.SETTINGS
    };

    static translations = {
        itemTitle: '$t(notificationRule:label.name)',
        itemLower: '$t(notificationRule:label.name_lower)'
    };

    static buildActivationEffects(dispatch) {
        return [
            ...super.buildActivationEffects(dispatch),
            // ACTIVATION EFFECTS
            put(NotificationRuleModel.actionCreators.startPollingDetails()),
            put(ThirdPartyServiceModel.actionCreators.startPollingDetails(SLOW_QUERY_INTERVAL))
        ]
    }

    static buildDeactivationEffects() {
        return [
            // DEACTIVATION EFFECTS
            put(NotificationRuleModel.actionCreators.stopPollingDetails())
        ]
    }

    static* setInstanceId(args) {
        const {updateDisplay, instanceId} = this.variableNames;

        yield all ([
            put(SchedulerModel.actionCreators.setSettingsDisplay(settingsDisplayKeys.NOTIFICATION_RULES)),
            put(this.ModelType.componentActionCreators[updateDisplay]({[instanceId]: args.id}))
        ]);
    }

    // Saga called manually, w/o actions pattern
    static* testRule(id) {
        try {
            // Makeshift isDisabledWrapper
            yield put(NotificationRuleModel.componentActionCreators.updateTablet({isDisabled: true}));

            const {data} = yield call(NotificationRuleApi.putTest, id);
            yield put(PopupModel.actionCreators.showSuccess({
                info: data
            }));

        } catch (error) {
            yield call(genericErrorHandler, error);
        } finally {
            yield put(NotificationRuleModel.componentActionCreators.updateTablet({isDisabled: false}));
        }
    }

    // Saga called manually, w/o action pattern
    static* testRuleData(component, formData) {
        try {
            // Makeshift isDisabledWrapper
            yield put(NotificationRuleModel.componentActionCreators[`update${component}`]({isDisabled: true}));
            const saveValues = this.getSaveValues(formData);

            if (formData.id != null) {
                saveValues.id = formData.id;
            }

            const type = NotificationRuleModel.getSimpleType(saveValues.ruleType);
            const {data} = yield call(NotificationRuleApi[`post${type}Test`], saveValues);

            yield put(PopupModel.actionCreators.showSuccess({
                info: data
            }));
        } catch (error) {
            yield call(genericErrorHandler, error);
        } finally {
            yield put(NotificationRuleModel.componentActionCreators[`update${component}`]({isDisabled: false}));
        }
    }

    static* submitForm(action) {
        const {formData} = action.payload;
        const saveValues = this.getSaveValues(formData);

        const type = NotificationRuleModel.getSimpleType(saveValues.ruleType);
        const {data} = yield contextCall(NotificationRuleApi, `post${type}`, saveValues);

        yield all([
            put(NotificationRuleModel.actionCreators.addDetails(data)),
            put(NotificationRuleModel.componentActionCreators.updateDisplay({[`is${type}NotificationRuleFormActive`]: false, notificationRuleId: data.id}))
        ])
    }

    static* saveEdit(id) {
        const editValues = yield select(state => state.editDetails.values);
        const saveValues = yield contextCall(this, 'getSaveValues', editValues);

        const type = NotificationRuleModel.getSimpleType(editValues.ruleType);
        const {data} = yield contextCall(NotificationRuleApi, `put${type}Details`, id, saveValues);

        yield put(NotificationRuleModel.actionCreators.updateDetails({[id]: new NotificationRuleModel(data)}));
    }

    static* getEditValues(id) {
        const details = yield select(state => state.notificationRuleDetailsMap.get(id));

        switch (details.ruleType) {
            case NotificationRuleModel.Type.EMAIL_SMTP:
                return this.buildEmailEditModel(details);
            case NotificationRuleModel.Type.WEBHOOK:
                return this.buildWebhookEditModel(details);
            default:
                throw new Error("Rule type not implemented");
        }
    }

    static getSaveValues(values) {
        switch (values.ruleType) {
            case NotificationRuleModel.Type.EMAIL_SMTP:
                return this.buildEmailSaveModel(values);
            case NotificationRuleModel.Type.WEBHOOK:
                return this.buildWebhookSaveModel(values);
            default:
                throw new Error("Rule type not implemented");
        }
    }

    static buildEmailEditModel(details) {
        const {name: emailNotificationRuleName, description, ruleType, smtpServiceId, to, cc, htmlFormat} = details;
        const translatedJobEventTriggers = getValues(jobEventTriggerKeys)
            .map(val => ({
                name: i18next.t(`job:trigger.${val}`),
                value: val
            }))

        const triggers = separateItems(details.triggers, translatedJobEventTriggers);
        return {
            emailNotificationRuleName,
            description,
            ruleType,
            to,
            cc,
            htmlFormat,
            triggers,
            smtpServiceId
        }
    }

    static buildEmailSaveModel(values) {
        const {emailNotificationRuleName: name, description, smtpServiceId, to, cc, htmlFormat} = values;
        const triggers = values.triggers.right.map(item => item.value);

        return {
            ruleType: NotificationRuleModel.Type.EMAIL_SMTP,
            name,
            description,
            triggers,
            to,
            cc,
            htmlFormat,
            smtpServiceId
        }
    }

    static buildWebhookEditModel(details) {
        const {name: webhookNotificationRuleName, description, ruleType, triggers, url: webhookUrl, webhookPlatform} = details;
        const jobEventTriggerNameValues = getValues(jobEventTriggerKeys)
            .map(value => {
                return {name: i18n.t(`job:trigger.${value}`), value}
            })
            .sort(nameLocaleCompare)

        const {available: availableTriggers, selected: selectedTriggers} = separateNameValues(triggers, jobEventTriggerNameValues);
        return {
            webhookNotificationRuleName,
            description,
            ruleType,
            webhookUrl,
            webhookPlatform,
            availableTriggers,
            selectedTriggers
        }
    }

    static buildWebhookSaveModel(values) {
        const {webhookNotificationRuleName: name, description, webhookUrl: url, webhookPlatform, selectedTriggers} = values;
        const triggers = selectedTriggers.items.map(item => item.value);

        return {
            ruleType: NotificationRuleModel.Type.WEBHOOK,
            name,
            description,
            url,
            webhookPlatform,
            triggers
        }
    }

    static* queryDetails() {
        const response = yield contextCall(NotificationRuleApi, 'getDetails');
        const key = details.NOTIFICATION_RULES;

        if (AxiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(NotificationRuleModel.actionCreators.setDetailsMap(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }
}

export default NotificationRuleModel;