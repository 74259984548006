import React, {useRef} from 'react';
import './Panel.css';
import {useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {buildClassName, getValues} from "../../utilities/helperFunctions";
import {useKeyPressEffect} from "../../utilities/hooks";
import {selectStatusIcon, updateStatusToIcon} from "../app/navbar/NavBar";
import {statusIcon} from "../../utilities/iconResolver";
import {SchedulerSaga} from "../../models/scheduler/SchedulerModel";
import {details} from "../../utilities/constants";
import Text from "../common/Text/Text";

function SelectPanel(props) {
    const {label, name, value, leftChildren, onPanelSelect, isActive, isItalic, isFlex, isDisabled, labelStyle, ...attr} = props;

    const containerRef = useRef();
    const keyToCb = useRef({
        'Enter': 'click'
    });
    useKeyPressEffect({containerRef, keyToCb: keyToCb.current});

    const className = buildClassName(
        'selectPanelSimple',
        'active-bold',
        isFlex && 'is-flex',
        isActive && 'is-active',
        isDisabled && 'is-disabled'
    );

    return (
        <article role="tab" id={`${value}_TAB`} ref={containerRef} className={className}
            data-name={name} data-value={value} onClick={isDisabled ? null : onPanelSelect}
            aria-controls={`${value}_TABPANEL`} aria-selected={!!isActive} tabIndex={isDisabled ? -1 : 0}
            {...attr}
        >
            {leftChildren}

            <Text ElementTag="span" value={label} isEllipsis isItalic={isItalic}
                style={labelStyle} isDisabled={isDisabled}/>
        </article>
    );
}

function selectClientMatterStatusIcons(state, clientStatus, clientId) {
    let statuses = [];
    if (state.componentStates.clientPage.clientId === clientId) {
        statuses.push(clientStatus);
        statuses.push(...getValues(state.matterDetailsMap || {})
            .filter(matter => matter.clientId === clientId && matter.enabled)
            .flatMap(matter => matter.status));
    } else {
        if (clientStatus.lastUpdatedDate > SchedulerSaga.lastResourceStatusUpdate) {
            statuses.push(clientStatus);
        } else {
            if (state.schedulerDetails.resourcesStatus[details.CLIENTS] != null) {
                statuses = [state.schedulerDetails.resourcesStatus[details.CLIENTS][clientId]];
            }
        }
    }

    if (statuses != null) {
        const statusToIcon = {};
        updateStatusToIcon(statuses, statusToIcon);
        return selectStatusIcon(statusToIcon);
    }
}

export function ClientSelectPanel(props) {
    const {t} = useTranslation(['common']);
    const {clientId, ...attr} = props;

    const {name, status, enabled} = useSelector(state => state.clientDetailsMap.get(clientId)) || {};
    const statuses = useSelector(state => selectClientMatterStatusIcons(state, status, clientId));

    return (
        <SelectPanel label={name + (enabled ? '' : ` (${t('common:label.inactive')})`)}
            value={clientId} labelStyle={{marginTop: '-1px'}} {...attr} isFlex
            leftChildren={Array.isArray(statuses) && statuses.map(status =>
                <span key={status} className="icon is-small">
                    <img src={statusIcon(status)} alt={t(`aria:hiddenAssistText.${status}Icon`)}/>
                </span>
            )}
        />
    );
}

export function LibrarySelectPanel(props) {
    const {t} = useTranslation(['common']);
    const {libraryId, ...attr} = props;

    const {name, enabled} = useSelector(state => state.libraryDetailsMap.get(libraryId)) || {};

    return (
        <SelectPanel label={name + (enabled ? '' : ` (${t('common:label.inactive')})`)}
            value={libraryId} {...attr}
        />
    );
}

export default SelectPanel;
