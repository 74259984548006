import React, {useCallback, useLayoutEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import JobQueueHeader from "./JobQueueHeader";
import {details, jobQueueStates, jobStates, userSettings} from "../../utilities/constants";
import {JobListContainer, MushroomHead} from "./JobList";
import {useTranslation} from "react-i18next";
import {stateFilterKeys} from "../../i18next/keys";
import JobTablet from "./JobTablet";
import JobQueueModel from "../../models/job/JobQueueModel";
import CurrentUserModel from "../../models/user/CurrentUserModel";
import {deepCopy, getKeys, getValues, objEquals} from "../../utilities/helperFunctions";
import {selectDetailsMapForJobNames, selectJobText} from "./selectors";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import JobModel from "../../models/job/JobModel";


const jobLanesTemplate = {
    [stateFilterKeys.STAGING]: [],
    [stateFilterKeys.BACKLOG]: [],
    [stateFilterKeys.RUNNING]: [],
    [stateFilterKeys.FINISHED]: []
};

export function selectFilteredQueueJobs({detailsMap, jobQueueDisplay}) {
    const jobs = getValues(detailsMap.job);
    const filteredStateFilters = getKeys(jobQueueDisplay.stateFilters)
        .filter(key => !!jobQueueDisplay.stateFilters[key]);

    return jobs.filter(job => {
        const {
            executionState,
            queueState,
            resourcePoolId,
            executionProfileId,
            hasWarnings,
            hasSoftErrors,
            engineHasWarnings
        } = job;

        if (filteredStateFilters.length > 0) {
            const isBacklog = jobStates.backlog.includes(executionState);
            const isStaging = isBacklog && queueState === jobQueueStates.STAGING;
            const isRunning = jobStates.running.includes(executionState);
            const isFinished = jobStates.completed.includes(executionState);
            let passesFilters = false;

            // SOFT_ERROR
            passesFilters ||= filteredStateFilters.includes(stateFilterKeys.SOFT_ERROR) && hasSoftErrors;
            // WARNINGS
            const hasBacklogWarning = isBacklog && (!resourcePoolId || !executionProfileId || engineHasWarnings);
            passesFilters ||= filteredStateFilters.includes(stateFilterKeys.WARNING) && (hasWarnings || hasBacklogWarning);
            // STAGING
            passesFilters ||= filteredStateFilters.includes(stateFilterKeys.STAGING) && isStaging;
            // BACKLOG
            passesFilters ||= filteredStateFilters.includes(stateFilterKeys.BACKLOG) && isBacklog && !isStaging;
            // RUNNING
            passesFilters ||= filteredStateFilters.includes(stateFilterKeys.RUNNING) && isRunning;
            // FINISHED
            passesFilters ||= filteredStateFilters.includes(stateFilterKeys.FINISHED) && isFinished;
            // COMMENTS
            passesFilters ||= filteredStateFilters.includes(stateFilterKeys.COMMENTS) && job.lastUserComment != null;
            // UNREAD COMMENTS
            passesFilters ||= filteredStateFilters.includes(stateFilterKeys.UNREAD_COMMENTS) && job.lastUserComment != null && !job.lastUserComment.viewedByUser;

            // OTHERS
            passesFilters ||= filteredStateFilters.includes(executionState);
            if (!passesFilters) {
                return false;
            }
        }
        return selectJobText(job, detailsMap).includes(jobQueueDisplay.searchText.toLowerCase());
    });
}

export function selectFilteredQueueJobLanes({detailsMap, jobQueueDisplay, userSettingsMap}) {
    const jobLanes = deepCopy(jobLanesTemplate);
    const jobs = getValues(detailsMap.job);

    // Remove stagingLane if userSetting hideStagingLaneWhenEmpty === true && noStagingJobs
    const {hideStagingLaneWhenEmpty} = userSettingsMap.get(userSettings.JOB_LANE);
    if (hideStagingLaneWhenEmpty) {
        const noStagingJobs = jobs.every(job => job.queueState !== jobQueueStates.STAGING || !jobStates.backlog.includes(job.executionState));
        if (noStagingJobs) {
            delete jobLanes[stateFilterKeys.STAGING];
        }
    }

    const filteredJobs = selectFilteredQueueJobs({detailsMap, jobQueueDisplay});
    filteredJobs.forEach(job => {
        let laneKey;
        if (jobStates.backlog.includes(job.executionState)) {
            if (job.queueState === jobQueueStates.STAGING) {
                laneKey = stateFilterKeys.STAGING;
            } else {
                laneKey = stateFilterKeys.BACKLOG;
            }
        } else if (jobStates.running.includes(job.executionState)) {
            laneKey = stateFilterKeys.RUNNING;
        } else if (jobStates.completed.includes(job.executionState)) {
            laneKey = stateFilterKeys.FINISHED;
        }
        if (jobLanes[laneKey] != null) {
            jobLanes[laneKey].push(job);
        }
    });
    return jobLanes;
}

function JobQueueDisplay() {
    const {t} = useTranslation(['job']);
    const dispatch = useDispatch();

    const isLoading = useSelector(state => !state.hasLoaded[details.QUEUE_JOBS]);
    const activeJob = useSelector(state => state.jobDetailsMap.get(state.componentStates.jobPage.jobId));

    const userSettingsMap = useSelector(state => state.userSettingsMap);
    const jobQueueDisplay = useSelector(state => state.componentStates.jobQueueDisplay);
    const detailsMap = useSelector(state => ({
        job: state.jobDetailsMap,
        ...selectDetailsMapForJobNames(state),
    }), objEquals);

    const jobLanes = selectFilteredQueueJobLanes({detailsMap, jobQueueDisplay, userSettingsMap});
    const jobsToArchive = jobLanes[stateFilterKeys.FINISHED].map(job => job.id);

    // Set QueueDisplay active and start polling JOB_MONITORING
    useLayoutEffect(() => {
        dispatch(JobQueueModel.componentActionCreators.setDisplayActive());
        dispatch(CurrentUserModel.actionCreators.pollActivity(CurrentUserModel.activities.JOB_MONITORING));
        return () => dispatch(JobQueueModel.componentActionCreators.setDisplayInactive());
    }, [dispatch]);

    const showQueueJob = useCallback(() => {
        dispatch(JobModel.actionCreators.showForm());
    }, []);

    let jobLaneKeys = getKeys(jobLanes);
    const {showJobListCount} = userSettingsMap.get(userSettings.JOB_LANE);
    const activeJobId = activeJob && activeJob.id;


    return (
        <>
            <section className="mainPage-header">
                <JobQueueHeader text={t('job:label.name')} aria-label={t('aria:hiddenAssistText.addJob')}
                    showJobForm={showQueueJob} isLoading={isLoading} jobsToArchive={jobsToArchive}/>
            </section>

            <section className="job-content" id={'jobQueueDisplay'}>
                <div className="mushroom-heads">
                    <div className="columns">
                        {jobLaneKeys.map(key => {
                            let header = t(`job:stateFilter.${key}`);
                            const numberOfJobs = jobLanes[key].length;
                            if (showJobListCount && numberOfJobs > 0) {
                                header += ` (${numberOfJobs})`;
                            }
                            return (
                                <MushroomHead key={key} text={header}/>
                            )
                        })}
                    </div>
                </div>

                <div className="job-containers">
                    <LoadingWrapper isLoading={isLoading}>
                        <div className="columns">
                            {jobLaneKeys.map(key =>
                                <JobListContainer key={key} id={`jobList${key}`} aria-label={t(`aria:hiddenAssistText.${key}`)}
                                    activeJobId={activeJobId} jobs={jobLanes[key]}/>
                            )}
                        </div>
                    </LoadingWrapper>
                </div>
            </section>

            {!!activeJob &&
                <JobTablet jobId={activeJobId}/>
            }
        </>
    );
}

export default JobQueueDisplay;
