import React from 'react';
import WelcomeStep from "../components/Steps/Welcome/WelcomeStep";
import WorkflowInformation from "../components/Steps/WorkflowInformation/WorkflowInformation";
import UseCase from "../components/Steps/UseCaseStep/UseCase";
import IngestionSettings from "../components/Steps/IngestionStep/IngestionSettings";
import ExportOptions from "../components/Steps/OptionalSteps/ExportOptions";
import ReportOptions from "../components/Steps/OptionalSteps/ReportOptions";
import LegalExport from "../components/Steps/LegalExport/LegalExport";
import CustomExportNumbering from "../components/Steps/LegalExport/CustomExportNumbering";
import FinishStep from "../components/Steps/Finish/FinishStep";
import AdvancedIngestionSettings from "../components/Steps/IngestionStep/AdvancedIngestionSettings";
import ConfigurationOptional from "../components/Steps/OptionalSteps/ConfigurationOptional";
import RelativityConfiguration from "../components/Steps/Relativity/RelativityConfiguration";
import DiscoverConfiguration from "../components/Steps/Discover/DiscoverConfiguration";
import DiscoverExport from "../components/Steps/Discover/DiscoverExport";
import RelativityExport from "../components/Steps/Relativity/RelativityExport";
import OcrStep from "../components/Steps/Ocr/OcrStep";

export function StepComponents(props) {
    const {id, ...attr} = props;

    const components = {
        welcomeStep: <WelcomeStep {...attr}/>,
        workflowInformation: <WorkflowInformation {...attr}/>,
        caseSettings: <UseCase {...attr}/>,
        configurationOptions: <ConfigurationOptional {...attr}/>,
        relativityConfiguration: <RelativityConfiguration {...attr}/>,
        discoverConfiguration: <DiscoverConfiguration {...attr}/>,
        ingestionSettings: <IngestionSettings {...attr}/>,
        advancedIngestionSettings: <AdvancedIngestionSettings {...attr}/>,
        exportOptions: <ExportOptions {...attr}/>,
        reportOptions: <ReportOptions {...attr}/>,
        legalExport: <LegalExport {...attr}/>,
        ocrSettings: <OcrStep {...attr}/>,
        exportToDiscover: <DiscoverExport {...attr}/>,
        exportToRelativity: <RelativityExport {...attr}/>,
        customExportNumbering: <CustomExportNumbering {...attr}/>,
        finishStep: <FinishStep {...attr}/>
    }

    const emptyComponent = (
        <div className={"wizard-step-container"}>
            <label className={"label"}>Not supported yet</label>
        </div>
    )

    return components[id] || emptyComponent;
}