import {useTranslation} from "react-i18next";
import {purviewUseCaseParameterNames} from "./PurviewUseCasePane";
import React, {useEffect, useRef} from "react";
import Text from "../../common/Text/Text";
import SelectableTable from "../../common/Tables/SelectableTable";
import {StatusNameDescriptionCell, TableLabelCell} from "../../common/CustomTable/CustomTable";
import {getLocaleDateTimeFromUTC, getNonEmptyValueOrDefault} from "../../../utilities/helperFunctions";
import PurviewCache from "../../../models/guidedjob/PurviewCache";
import {objectIdentifierType} from "../../../utilities/constants";
import {useSelector} from "react-redux";
import {buildOperationFieldParameterNames} from "../../guidedJob/operationFieldParameterNames";
import WorkflowBuilderOperation from "../../../models/workflowbuilder/WorkflowBuilderOperation";
import PurviewOperationPane from "./PurviewOperationPane";

const setExportOperationFieldNames = ['exportJobIdentifierType', 'exportJobIdentifier', 'waitForCompletion'];
export const purviewSetExportOperationParameterNames = buildOperationFieldParameterNames(WorkflowBuilderOperation.Alias.PURVIEW_SET_EXPORT, setExportOperationFieldNames);

const downloadExportOperationFieldNames = ['exportJobIdentifierType', 'exportJobIdentifier'];
export const purviewDownloadExportOperationParameterNames = buildOperationFieldParameterNames(WorkflowBuilderOperation.Alias.PURVIEW_DOWNLOAD_EXPORT, downloadExportOperationFieldNames);

function getNextEnabled(getParameter) {
    const exportJobIdentifierType = getParameter(purviewSetExportOperationParameterNames.exportJobIdentifierType);
    const exportJobIdentifier = getParameter(purviewSetExportOperationParameterNames.exportJobIdentifier);
    return {
        selectExport: objectIdentifierType[exportJobIdentifierType.value] != null && exportJobIdentifier.value.length > 0,
    };
}

export function getPurviewSelectExportGuide(operation, props) {
    const {
        workflowActions,
        updateParameter
    } = props;

    updateParameter(purviewSetExportOperationParameterNames.waitForCompletion, JSON.stringify(true));
    updateParameter(purviewSetExportOperationParameterNames.exportJobIdentifierType, objectIdentifierType.ID);
    updateParameter(purviewSetExportOperationParameterNames.exportJobIdentifier, '');

    updateParameter(purviewDownloadExportOperationParameterNames.exportJobIdentifierType, objectIdentifierType.ID);
    updateParameter(purviewDownloadExportOperationParameterNames.exportJobIdentifier, '');

    return {
        getPanes: function(t, props) {
            const selectExportTitle = getNonEmptyValueOrDefault(operation?.notes, t('guidedJob:panes.selectExport'));
            const nextEnabled = getNextEnabled(props.getParameter);

            return [{
                title: selectExportTitle,
                isNextEnabled: nextEnabled.selectExport,
                component: <PurviewOperationPane {...props}
                    workflowActions={workflowActions} OperationPane={PurviewSelectExportPane}/>
            }];
        }
    }
}

function PurviewSelectExportPane(props) {
    const {t} = useTranslation(['guidedJob', 'common']);
    const {
        proxyPurviewRequest,
        getParameter,
        updateParameter,
        isDisabled
    } = props;

    const exportJobIdentifier = getParameter(purviewSetExportOperationParameterNames.exportJobIdentifier);

    const caseIdentifierType = getParameter(purviewUseCaseParameterNames.caseIdentifierType);
    const caseIdentifier = getParameter(purviewUseCaseParameterNames.caseIdentifier);
    const useExistingCase = caseIdentifierType.value === objectIdentifierType.ID;

    const exportJobsKey = PurviewCache.getResourceKey(PurviewCache.Resource.EXPORT_JOBS, {caseId: caseIdentifier.value});
    const exportJobs = useSelector(state => state.purviewCache[exportJobsKey]) || {};
    const isLoadingExportJobs = exportJobs.cache == null;

    useEffect(() => {
        if (useExistingCase) {
            proxyPurviewRequest(PurviewCache.Resource.EXPORT_JOBS, {caseId: caseIdentifier.value});
        }
    }, [useExistingCase]);

    useEffect(() => {
        if (!Array.isArray(exportJobs.cache) || exportJobs.cache.every(exportJob => exportJob.id !== exportJobIdentifier.value)) {
            updateParameter(purviewSetExportOperationParameterNames.exportJobIdentifier, '');
            updateParameter(purviewDownloadExportOperationParameterNames.exportJobIdentifier, '');
        }
    }, [exportJobs.cache]);

    function valueHandler(event) {
        const {value} = event.currentTarget.dataset;
        updateParameter(purviewSetExportOperationParameterNames.exportJobIdentifier, value);
        updateParameter(purviewDownloadExportOperationParameterNames.exportJobIdentifier, value);
    }

    const headers = useRef([
        {label: t('common:label.name')},
        {label: t('common:label.createdDate'), noStretch: true},
        {label: t('common:label.status'), noStretch: true}
    ]);

    return (
        <div className="display-input">
            <div style={{display: 'flex', marginBottom: '0.25rem'}}>
                <Text value={`${t('guidedJob:label.exportJob')}:`} style={{marginRight: '0.5rem'}} isDisabled={isDisabled}/>
                <Text value={exportJobIdentifier.value} isDisabled={isDisabled}/>
            </div>

            <SelectableTable itemNameKey={'outputName'} items={exportJobs.cache} headers={headers.current}
                selectedItemId={exportJobIdentifier.value} onItemSelect={valueHandler} emptyMessage={t('guidedJob:message.purviewCaseHasNoExports')}
                isLoading={isLoadingExportJobs} isDisabled={isDisabled} isStale={exportJobs.stale} isRequired
                RowComponent={props => (
                    <>
                        <StatusNameDescriptionCell name={props.outputName} description={props.description}/>
                        <TableLabelCell label={getLocaleDateTimeFromUTC(props.createdDate)}/>
                        <TableLabelCell label={t(`guidedJob:purviewCaseOperationStatus:${props.status}`)}/>
                    </>
                )}
            />
        </div>
    )
}