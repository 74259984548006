import PopupModel from "../scheduler/PopupModel";
import EditModel from "../scheduler/EditModel";
import {popupInfoKeys} from "../../i18next/keys";
import {all, call, cancel, put, race, select, take} from "redux-saga/effects";
import {retryCall} from "../../saga/tryCatchWrapper";
import {
    deepCopy,
    deleteNestedKeyFromObject,
    getValues,
    isAllTruthy,
    objEquals,
    objEqualsNotOrdered,
    objShallowDifferences
} from "../../utilities/helperFunctions";
import {SagaRunnable} from "./SagaRunnable";
import {contextCall, contextFork, contextSaga, isDisabledWrapper} from "../../saga/sagaFunctions";
import SchedulerModel from "../scheduler/SchedulerModel";
import {routes} from "../../utilities/constants";
import {axiosInstance} from "../api/AxiosProxy";
import RelativityProxyModel from "../relativity/RelativityProxyModel";
import {renderSubmitForm} from "../../components/common/SubmitForm/SubmitForm";

class SagaModel extends SagaRunnable {

    // Model Class
    static ModelType = class {
        static nom;
        constructor() {
            throw new Error("Missing ModelClass");
        }
    };
    static ModelApi = {};

    static activationComponent = '';
    static variableNames = {
        detailsMap: 'baseDetailsMap',
        instanceId: 'instanceId',
        modelName: 'name',
        isFormActive: 'isBaseFormActive',
        formState: 'baseForm',
        updateDisplay: 'updateBase',
        updatePane: 'updateTablet',
        route: routes.SETTINGS
    };

    static editValuesBlackList = [];

    static translations = {
        itemTitle: '$t(base:label.name)',
        itemLower: '$t(base:label.name_lower)'
    };

    static getIsFormActive(args) {
        return this.variableNames.isFormActive;
    }

    static getModelName(args) {
        return this.variableNames.modelName;
    }

    static* setInstanceId(args) {
        const {updateDisplay, instanceId} = this.variableNames;

        yield put(this.ModelType.componentActionCreators[updateDisplay]({[instanceId]: args.id}));
    }

    static* initializeState() {
        yield contextCall(this, 'queryDetails');
    }

    static* setReduxState(args) {
        yield contextCall(this, 'initializeState');
        const {detailsMap, route} = this.variableNames;

        const details = yield select(state => state[detailsMap].get(args.id));
        if (args.id != null && details == null) {
            yield put(PopupModel.actionCreators.showError({
                info: {
                    key: 'ModelCannotBeFound',
                    valueKeys: {
                        itemTitle: this.translations.itemTitle
                    }
                }
            }));
        }

        yield contextCall(this, 'setInstanceId', args);
        if (args.cbEffect != null) {
            yield args.cbEffect;
        }

        window.location.href = `#${route}`;
    }

    static* showForm(action) {
        const {updateDisplay} = this.variableNames;
        const {updateForm, resetForm} = this.ModelType.componentActionCreators;

        const {initialState} = action.payload;
        if (resetForm != null) {
            yield put(resetForm());
        }
        if (updateForm != null) {
            yield put(updateForm({
                ...yield this.ModelType.buildDefaultFormState(yield select(), initialState),
                ...initialState
            }));
        }

        const isFormActive = this.getIsFormActive(initialState);
        yield put(this.ModelType.componentActionCreators[updateDisplay]({[isFormActive]: true}));
    }

    static* hideForm(action) {
        const {updateDisplay, formState} = this.variableNames;
        // If not in edit mode
        const hideAndReset = function* () {
            const isFormActive = this.getIsFormActive(action.payload);
            yield put(this.ModelType.componentActionCreators[updateDisplay]({[isFormActive]: false}));
            // Reset form values
            const {resetForm} = this.ModelType.componentActionCreators;
            if (resetForm != null) {
                yield put(resetForm());
            }
        }.bind(this);

        const {activeModel} = yield select(state => state.editDetails);
        if (activeModel !== this.ModelType.nom) {
            yield* hideAndReset();
            return;
        }

        const callAfterResetEffect = call(function* () {
            yield take(EditModel.actions.RESET);
            yield* hideAndReset();
        });

        // Check if edit has changed, if changed prompt save popup
        const [{id, ...editValues}, state] = yield select(state => [state.componentStates[formState], state]);
        const {id: ignore, ...values} = yield contextCall(this, 'getEditValues', id);
        const isChanged = !objEquals(editValues, values);

        if (isChanged) {
            const isSaveEnabled = isAllTruthy(this.ModelType.validateFormData(editValues, state));

            yield put(PopupModel.actionCreators.show({
                info: {
                    key: popupInfoKeys.SAFE_CLOSE
                },
                buttons: [{
                    titleKey: 'common:option.dontSave',
                    onClick: function () {
                        this.dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(callAfterResetEffect));
                        this.dispatch(EditModel.actionCreators.cancel());
                    }.bind(this),
                }, {
                    titleKey: 'common:option.save',
                    onClick: function () {
                        this.dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(callAfterResetEffect));
                        this.dispatch(EditModel.actionCreators.save());
                    }.bind(this),
                    isDisabled: !isSaveEnabled
                }]
            }));
        } else {
            yield all([
                callAfterResetEffect,
                put(EditModel.actionCreators.cancel())
            ]);
        }
    }

    static* submitForm(action) {
        const {updateDisplay, instanceId} = this.variableNames;

        const {formData} = action.payload;
        const saveValues = yield contextCall(this, 'getSaveValues', formData);

        const {data} = yield contextCall(this.ModelApi, 'post', saveValues);
        const isFormActive = this.getIsFormActive(formData);

        yield all([
            put(this.ModelType.actionCreators.addDetails(data)),
            put(this.ModelType.componentActionCreators[updateDisplay]({[isFormActive]: false, [instanceId]: data.id}))
        ])
    }

    static* showPane(action) {
        const {updateDisplay, instanceId} = this.variableNames;

        const {id} = action.payload;
        yield put(this.ModelType.componentActionCreators[updateDisplay]({[instanceId]: id}));
    }

    static* hidePane() {
        const {updateDisplay, updatePane, instanceId} = this.variableNames;

        const resetPane = this.ModelType.componentActionCreators[updatePane.replace('update', 'reset')];
        if (typeof resetPane === 'function') {
            yield put(resetPane());
        }
        yield put(this.ModelType.componentActionCreators[updateDisplay]({[instanceId]: null}));
    }

    static* toggleEnabled(action) {
        const {detailsMap} = this.variableNames;

        const {id} = action.payload;
        const {enabled} = yield select(state => state[detailsMap].get(id));

        const {data} = yield contextCall(this.ModelApi, 'putDetails', id, {enabled: !enabled});
        yield put(this.ModelType.actionCreators.updateDetails({[id]: {enabled: data.enabled}}));
    }

    static* duplicate(action) {
        const {detailsMap} = this.variableNames;

        const {id} = action.payload;
        const editValues = yield contextCall(this, 'getEditValues', id);

        const map = yield select(state => state[detailsMap]);
        const modelName = this.getModelName(editValues);
        editValues[modelName] = yield contextCall(this, 'getUniqueName', editValues[modelName], map);

        const duplicateValues = yield contextCall(this, 'getDuplicateValues', editValues);
        // Track initialValues
        const initialValues = deepCopy(duplicateValues);

        yield all([
            put(this.ModelType.actionCreators.hideTablet()),
            put(this.ModelType.actionCreators.showForm({
                initialValues,
                ...duplicateValues
            }))
        ]);
    }

    static* getDuplicateValues(editValues) {
        editValues.isAddEnabled = true;
        return editValues;
    }

    //======================================================EDIT======================================================\\

    static* startEdit(action) {
        const {id} = action.payload;
        const editValues = yield contextCall(this, 'getEditValues', id);

        // this.name is the model class
        yield all([
            put(EditModel.actionCreators.start(this.ModelType.nom, editValues)),
            retryCall(contextSaga(this, 'waitForEditAction'), id, editValues)
        ]);

        yield put(EditModel.actionCreators.reset());
    }

    static* waitForEditAction(id, editValues) {
        const elsewhereEditTask = yield contextFork(this, 'checkElsewhereEdit', id, editValues);

        // Wait for save/cancel action
        const [save] = yield race([
            take(EditModel.actions.SAVE),
            take(EditModel.actions.CANCEL)
        ]);

        // Cancel poll for checking if edit was updated elsewhere
        yield cancel(elsewhereEditTask);
        if (save) {
            yield contextCall(this, 'verifySave', save);
            if (this.ModelType.componentActionCreators) {
                const updateState = this.ModelType.componentActionCreators[this.variableNames.updatePane];
                yield* isDisabledWrapper(updateState, contextSaga(this, 'saveEdit'), id, save);
            } else {
                yield contextCall(this, 'saveEdit', id, save);
            }
        }
    }

    static* verifySave(action) {
        // no-op
    }

    static* saveEdit(id, action) {
        const editValues = yield select(state => state.editDetails.values);
        const saveValues = yield contextCall(this, 'getSaveValues', editValues);

        const {data} = yield contextCall(this.ModelApi, 'putDetails', id, saveValues);
        yield put(this.ModelType.actionCreators.updateDetails({[id]: new this.ModelType(data)}));
    }

    static* getEditValues(id) {
        const {detailsMap} = this.variableNames;

        // Default implementation is to return a duplicated copy of the model w/o userPermissions
        const {userPermissions, ...rest} = yield select(state => state[detailsMap].get(id));
        return rest;
    }

    static getSaveValues(values) {
        // Default implementation is to return values
        return values;
    }

    // To determine if the model was edited elsewhere while in edit mode
    static* checkElsewhereEdit(id, editValues) {
        const editValuesCopy = deepCopy(editValues);
        // Clean comparison objects of blacklisted fields
        for (const field of this.editValuesBlackList) {
            deleteNestedKeyFromObject(editValuesCopy, field);
        }

        while (true) {
            yield contextCall(this, 'checkElsewhereTake');
            const model = yield contextCall(this, 'getEditValues', id);

            // Clean comparison objects of blacklisted fields
            for (const field of this.editValuesBlackList) {
                deleteNestedKeyFromObject(model, field);
            }

            if (!objEqualsNotOrdered(editValuesCopy, model)) {
                console.log('Found differences: ' + JSON.stringify(objShallowDifferences(editValuesCopy, model)));
                // Warn user that model was updated elsewhere
                yield contextCall(this, 'showEditElsewherePopup');
                break;
            }
        }
    }

    static* checkElsewhereTake() {
        yield take(this.ModelType.actions.SET_DETAILS_MAP);
    }

    static* showEditElsewherePopup() {
        yield put(PopupModel.actionCreators.showWarning({
            info: {
                key: popupInfoKeys.VALUES_CHANGED_WHILE_EDIT,
                valueKeys: {
                    itemLower: this.translations.itemLower
                }
            },
            buttons: [{
                titleKey: 'common:option.loadChanges',
                onClick: () => this.dispatch(EditModel.actionCreators.cancel())
            }],
            cancelButton: {
                titleKey: 'common:option.ignoreChanges'
            }
        }));
    }

    //================================================================================================================\\

    static* promptDelete(action) {
        const {detailsMap} = this.variableNames;

        const {id} = action.payload;
        const {name} = yield select(state => state[detailsMap].get(id));

        yield put(PopupModel.actionCreators.showWarning({
            info: {
                key: popupInfoKeys.DELETE_ITEM,
                values: {
                    itemName: name
                },
                valueKeys: {
                    itemTitle: this.translations.itemTitle
                }
            },
            buttons: [{
                titleKey: 'common:option.delete',
                onClick: () => this.dispatch(this.ModelType.actionCreators.delete(id))
            }]
        }));
    }

    static* delete(action) {
        const {updateDisplay, instanceId} = this.variableNames;

        const {id} = action.payload;

        yield* this.handleForceRequest(function* (force) {
            yield* isDisabledWrapper(this.ModelType.componentActionCreators.updateTablet, contextSaga(this.ModelApi, 'delete'), id, force);
            yield all([
                put(this.ModelType.actionCreators.deleteDetails(id)),
                put(this.ModelType.componentActionCreators[updateDisplay]({[instanceId]: null}))
            ]);
        }, {confirmTitleKey: 'common:option.delete'});
    }

    //=====================================================UTILITY====================================================\\

    static* downloadAuditLogVersion(action) {
        const {id, downloadId} = action.payload;
        const isRelativityApplication = yield select(state => state.schedulerDetails.isRelativityApplication);

        const url = this.ModelApi.getAuditLogVersionDownloadEndpoint(id, downloadId);
        const fields = [];
        if (axiosInstance.uiToken) {
            fields.push({name: 'token', value: axiosInstance.uiToken});
        }

        if (isRelativityApplication) {
            yield put(RelativityProxyModel.actionCreators.proxyDownload(url, fields, this.ModelType.componentActionCreators[this.variableNames.updatePane]));
        } else {
            renderSubmitForm(url, {fields});
        }
    }

    static* handleForceRequest(requestFunc, ...args) {
        const options = args.pop() || {};
        const {
            throwError=true
        } = options;

        let force = false;
        do {
            try {
                yield* requestFunc.call(this, ...args, force);
                force = false;
            } catch (error) {
                if (error.response == null) {
                    console.log(error);
                    return;
                }
                force = yield call(this.handleForceRequestError, error, options);
                if (!force && throwError) {
                    throw 'force request was rejected by user';
                }
            }
        } while (force);
    }

    static* handleForceRequestError(error, options={}) {
        switch (error.response.data.key) {
            // Define popupKeys and behaviors
        }
        throw error;
    }

    static* showConfirmPopup(info, options={}) {
        const popupId = `confirmPopup${info.key}`;
        const {
            confirmTitleKey='common:option.ok'
        } = options;

        yield put(PopupModel.actionCreators.show({
            id: popupId,
            info,
            buttons: [{
                titleKey: confirmTitleKey,
                onClick: () => {
                    this.dispatch({type: 'CONFIRM_SAVE'})
                }
            }]
        }));

        do {
            const [confirmed, hidden] = yield race([
                take('CONFIRM_SAVE'),
                take(PopupModel.actions.HIDE)
            ]);

            if (confirmed || hidden.payload.id === popupId) {
                return !!confirmed;
            }
        } while (true);
    }

    static* showSaveWarning(info, options={}) {
        const popupId = `saveWarning${info.key}`;
        const {
            confirmTitleKey='common:option.save',
            cancelTitleKey='common:option.cancel',
            throwError=true
        } = options;

        yield put(PopupModel.actionCreators.showWarning({
            id: popupId,
            info,
            buttons: [{
                titleKey: confirmTitleKey,
                onClick: dispatch => dispatch({type: 'CONFIRM_SAVE'})
            }],
            cancelButton: {
                titleKey: cancelTitleKey,
                onClick: dispatch => dispatch({type: 'CANCEL_SAVE'})
            }
        }));

        const [confirmed, rejected] = yield race([
            take('CONFIRM_SAVE'),
            take('CANCEL_SAVE')
        ]);

        if (rejected && throwError) {
            throw 'save was rejected by user';
        }
        return !!confirmed;
    }

    static* getUniqueName(name, detailsMap) {
        const copyRegex = / - Copy$/;
        const copyDigitRegex = / - Copy \([0-9]+\)$/;

        const nameList = getValues(detailsMap)
            .map(model => model.name);

        let duplicateName = name;
        while (nameList.includes(duplicateName)) {

            const copyDigitMatch = duplicateName.match(copyDigitRegex);
            if (copyDigitMatch != null) {

                const digits = copyDigitMatch[0].slice(' - Copy ('.length, -1);
                const number = parseInt(digits) + 1;

                duplicateName = duplicateName.slice(0, -(digits.length + 2)) + `(${number})`;

            } else if (duplicateName.match(copyRegex)) {
                duplicateName += ' (2)';
            } else {
                duplicateName += ' - Copy';
            }
        }

        return duplicateName;
    }
}

export default SagaModel;