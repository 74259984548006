import React from 'react';
import {ListDropdown} from "../../../../components/common/Dropdown/Dropdown";
import {RelativityWorkspaceIdentifierType} from "../../../utilities/constants";
import {useTranslation} from "react-i18next";

function RelativityWorkspaceIdentifierTypeDropdown(props) {
    const {t} = useTranslation(['aria', 'wizard']);

    const {selectedWorkspaceIdentifierType, onWorkspaceIdentifierTypeSelected, ...rest} = props;
    const dateFilterTypeValues = [
        {value: RelativityWorkspaceIdentifierType.NAME, name: t('wizard:questions.relativity.workspaceIdentifierDropdown.name')},
        {value: RelativityWorkspaceIdentifierType.ARTIFACT_ID, name: t('wizard:questions.relativity.workspaceIdentifierDropdown.artifactId')}
    ]

    return (
        <ListDropdown id={'dateFilterActionDropdown'} name={'workspaceIdentifierType'}
            aria-label={t('aria:hiddenAssistText.workspaceIdentifierType')} value={selectedWorkspaceIdentifierType}
            items={dateFilterTypeValues} onItemSelect={onWorkspaceIdentifierTypeSelected}
            buttonStyle={{maxWidth: '12rem'}}
            {...rest}
        />
    )
}

export default RelativityWorkspaceIdentifierTypeDropdown;