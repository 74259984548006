import React, {useCallback, useEffect, useRef} from 'react';
import './LoginPage.css';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {icon} from '../../../utilities/iconResolver';
import {getEntries} from '../../../utilities/helperFunctions';
import CurrentUserModel from "../../../models/user/CurrentUserModel";
import {axiosInstance} from "../../../models/api/AxiosProxy";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import {createInputHandler, createStateHandler} from "../../../utilities/componentFunctions";
import {shouldEnableLogin} from "../../../utilities/shouldEnableFunctions";
import {ListDropdown} from "../../common/Dropdown/Dropdown";
import {createDropdownHandler} from "../../common/Dropdown/helpers";
import SchedulerModel from "../../../models/scheduler/SchedulerModel";
import UserServiceModel from "../../../models/user/UserServiceModel";
import {Button} from "../../common/Button/Button";
import {Label} from "../../common/Text/Text";

const oidcTypeSortOrder = {
	[UserServiceModel.AuthMethodType.OIDC_MICROSOFT]: 0,
	[UserServiceModel.AuthMethodType.OIDC_GOOGLE]: 1,
	[UserServiceModel.AuthMethodType.OIDC_RELATIVITY]: 2,
	[UserServiceModel.AuthMethodType.OIDC]: 3
};

function LoginPage() {
	const {t} = useTranslation(['aria', 'common', 'scheduler']);
	const dispatch = useDispatch();

	const {
		authMethods
	} = useSelector(state => state.schedulerDetails);

	const authMethodEntries = getEntries(authMethods)
		.sort((entry1, entry2) => {
			return oidcTypeSortOrder[entry1[1]] - oidcTypeSortOrder[entry2[1]] || entry1[0].localeCompare(entry2[0]);
		});

	const {
		authMethod,
		username,
		invalidMessage,
		isLoginInvalid,
		isLoginPending,
		isLoginEnabled
	} = useSelector(state => state.componentStates.loginPage);

	const passwordRef = useRef();

	// query auth method onmount
	useEffect(() => {
		dispatch(SchedulerModel.actionCreators.queryAuthMethods());
	}, []);

	// Auto select first UsernamePassword authMethod if null
	useEffect(() => {
		if (authMethod == null) {
			const firstUsernamePasswordAuthMethod = authMethodEntries
				.find(([ignore, type]) => type === UserServiceModel.AuthMethodType.USERNAME_PASSWORD);

			if (firstUsernamePasswordAuthMethod != null) {
				dispatch(CurrentUserModel.componentActionCreators.updateLoginPage({authMethod: firstUsernamePasswordAuthMethod[0]}))
			}
		}
	}, [authMethod]);

	useEffect(() => {
		if (isLoginInvalid) {
			if (passwordRef && passwordRef.current) {
				passwordRef.current.value = '';
			}
		}
	}, [isLoginInvalid, isLoginPending]);


	const reduxHandler = createStateHandler({
		updateState: updates => dispatch(CurrentUserModel.componentActionCreators.updateLoginPage(updates)),
		passwordRef,
		shouldEnable: shouldEnableLogin,
		isEnabled: 'isLoginEnabled'
	});

	const inputHandler = createInputHandler({
		handler: reduxHandler
	});

	const dropdownHandler = createDropdownHandler({
		handler: reduxHandler
	})


	const onLogin = useCallback(event => {
		event.preventDefault();

		dispatch(CurrentUserModel.actionCreators.loginUser(passwordRef.current.value));
	}, [dispatch]);


	const usernamePasswordAuthDisplayNames = [];
	const oidcAuthDisplayNames = [];

	for (const entry of authMethodEntries) {
		const [displayName, type] = entry;

		switch (type) {
			case UserServiceModel.AuthMethodType.USERNAME_PASSWORD:
				usernamePasswordAuthDisplayNames.push(displayName);
				break;
			case UserServiceModel.AuthMethodType.OIDC_MICROSOFT:
			case UserServiceModel.AuthMethodType.OIDC_GOOGLE:
			case UserServiceModel.AuthMethodType.OIDC_RELATIVITY:
			case UserServiceModel.AuthMethodType.OIDC:
				oidcAuthDisplayNames.push(entry)
				break;
		}
	}

	const showUsernamePasswordAuthMethod = usernamePasswordAuthDisplayNames.length > 0;
	const showUsernamePasswordAuthMethodDropdown = usernamePasswordAuthDisplayNames.length > 1;
	const showOidcAuthMethod = oidcAuthDisplayNames.length > 0;

	const loginText = t('scheduler:option.login', {serviceDisplayName: authMethod})

	return (
		<div className="loginPage">
			<div className="login-box">

				<div className="login-header">
					<h1 className="subtitle is-bold">
						{t('scheduler:option.loginHeader')}
					</h1>
				</div>

				<div className="login-content">
					{!showUsernamePasswordAuthMethod && !showOidcAuthMethod &&
					<label className="label">
						{t('scheduler:option.noLoginMethodsAvailable')}
					</label>
					}

					{showUsernamePasswordAuthMethod &&
					<form id="userLoginForm" onSubmit={onLogin}>
						<HTMLTextInput name={'username'} value={username} onChange={inputHandler}
							placeholder={t('common:label.username')} autoComplete={'username'}
							hideInvalidMessage isInvalid={isLoginInvalid} isDisabled={isLoginPending}
						/>

						<div className="display-input">
							<HTMLTextInput name={'password'} type={'password'} inputRef={passwordRef} onChange={inputHandler}
								placeholder={t('common:label.password')} autoComplete={'current-password'}
								hideInvalidMessage isInvalid={isLoginInvalid} isDisabled={isLoginPending}
							/>

							{isLoginInvalid &&
								<span id={'invalidUserLoginMessage'} className="label is-error">{t(invalidMessage)}</span>
							}
						</div>

						{showUsernamePasswordAuthMethodDropdown &&
						<div className="display-input" style={{display: 'flex'}}>
							<label className={'label' + (isLoginPending ? ' is-disabled' : '')} style={{alignText: 'middle', margin: 'auto 0', marginRight: '0.5rem'}}>
								{t('common:label.scope')}:
							</label>

							<AuthMethodDropdown value={authMethod} onItemSelect={dropdownHandler} buttonStyle={{maxWidth: 'calc(195px - 41px - 0.5rem)'}}
								displayNames={usernamePasswordAuthDisplayNames} isDisabled={isLoginPending}
							/>
						</div>
						}

						<Button className="login-button" type="submit" text={loginText} isBold isEllipsis isFullWidth
							style={{marginTop: '1.75rem'}} labelStyle={{pointerEvents: 'none'}}
							isDisabled={!isLoginEnabled || isLoginPending}/>
					</form>
					}

					{showOidcAuthMethod &&
					<>
						{oidcAuthDisplayNames.map(([name, type], index) => {
							let serviceIcon;
							if (type !== UserServiceModel.AuthMethodType.OIDC) {
								serviceIcon = <img src={icon(type)}
									alt={t(`aria:hiddenAssistText.${type}Icon`)}/>
							}

							return (
								<React.Fragment key={index}>
									{(showUsernamePasswordAuthMethod || index > 0) &&
										<Label className="or-label"
											value={t('common:label.or')}/>
									}

									<div className={'oidc-form-container'}>
										<OidcLoginForm name={name} isDisabled={isLoginPending}>
											<span className="oidc-button-label">
												{serviceIcon}
												<p>
													{t('scheduler:option.loginOidcDisplayName', {displayName: name})}
												</p>
											</span>
										</OidcLoginForm>
									</div>
								</React.Fragment>
							)
						})}
					</>
					}
				</div>
			</div>
		</div>
	)
}

function AuthMethodDropdown(props) {
	const {name='authMethod', displayNames, ...attr} = props;
	const {t} = useTranslation(['aria']);

	const authMethodItems = displayNames
		.map(name => ({name, value: name}));

	return (
		<ListDropdown id={'authMethodDropdown'} aria-label={t('aria:hiddenAssistText.authMethod')}
			{...attr} name={name} items={authMethodItems}
		/>
	);
}

function OidcLoginForm(props) {
	const {name, children, isDisabled} = props;

	const action = axiosInstance.baseUrl + '/users/oidcAuth';
	return (
		<form id={`oidcLoginForm_${name}`} action={action} method="POST">
			<input type="hidden" name="scope" value={name}/>

			<Button className="login-button" type="submit"
				isFullWidth isDisabled={isDisabled}
			>
				{children}
			</Button>
		</form>
	);
}

export default LoginPage;
