import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import WebhookModel from "../../models/settings/WebhookModel";
import {
    StatusNameDescriptionCell,
    TableEnableToggleCell,
    TableLabelHeader,
    TableRow
} from "../common/CustomTable/CustomTable";
import {getValues} from "../../utilities/helperFunctions";
import {permissionKeys, statusKeys} from "../../i18next/keys";
import {translatedStatusIcons} from "../../utilities/iconResolver";
import Text from "../common/Text/Text";
import LimitedTableRow from "../limited/LimitedTableRow";


function WebhookTable(props) {
    const {t} = useTranslation(['webhook', 'common']);
    const dispatch = useDispatch();

    const {
        webhooks
    } = props

    function showTablet(event) {
        const {value} = event.currentTarget.dataset
        dispatch(WebhookModel.actionCreators.showDisplay(value))
    }

    function toggleEnable(event) {
        const {value} = event.currentTarget.dataset;
        dispatch(WebhookModel.actionCreators.toggleEnabled(value));
    }

    return (
        <div className="display-table">
            <div className="table-header-group">
                <TableLabelHeader/>

                <TableLabelHeader
                    label={t('common:label.name')}
                />

                <TableLabelHeader
                    label={t('webhook:label.triggers')}
                />

                <TableLabelHeader
                    label={t('webhook:label.callbackAddress')}
                />
            </div>

            <div className="table-row-group">
                {getValues(webhooks).map(webhook =>

                    <WebhookRow key={webhook.id} {...webhook} showTablet={showTablet} toggleEnable={toggleEnable}/>
                )}
            </div>
        </div>
    )
}

function WebhookRow(props) {
    const {t} = useTranslation(['common']);

    const {
        id,
        name,
        active,
        triggers,
        status,
        callbackAddress,
        userPermissions,
        showTablet,
        isActive,
        toggleEnable
    } = props;

    const canModify = userPermissions.includes(permissionKeys.MODIFY);
    const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);
    if (isViewLimited && !canModify) {
        return (
            <LimitedTableRow id={id} isActive={isActive} onClick={showTablet} cellCount={4}
                             nameComponent={<StatusNameDescriptionCell
                                 status={status} name={name}
                             />}
                             toggleComponent={<TableEnableToggleCell ariaLabelKey={'Webhook'} displayName={name} data-value={id}
                                                               checked={active} onClick={toggleEnable} isReadOnly={!canModify}/>}
            />
        )
    }

    return (
        <TableRow value={id} isActive={isActive} onClick={showTablet}>
            <TableEnableToggleCell ariaLabelKey={'Webhook'} displayName={name} data-value={id}
                checked={active} onClick={toggleEnable} isReadOnly={!canModify}/>

            <div className="table-cell status-name-description-cell" style={{maxWidth: '20rem'}}>
                <div style={{display: 'flex', height: '100%'}}>

                    {(status.code === statusKeys.ERROR || status.code === statusKeys.WARNING) &&
                    <span className="icon" title={t(`common:status.${status.code}`)}>
                        {translatedStatusIcons(status.code)}
                    </span>
                    }

                    <div style={{flex: 1, minWidth: 0}}>
                        <Text isBold isEllipsis value={name}/>
                    </div>
                </div>
            </div>

            <div className="table-cell no-stretch">
                {triggers.map(trigger =>
                    <Text key={trigger} value={t(`events:trigger.${trigger}`)}/>
                )}
            </div>

            <div className="table-cell status-name-description-cell" style={{maxWidth: '30rem'}}>
                <div style={{display: 'flex', height: '100%'}}>
                    <div style={{flex: 1, minWidth: 0}}>
                        <Text isEllipsis value={callbackAddress}/>
                    </div>
                </div>
            </div>
        </TableRow>
    )
}

export default WebhookTable;
