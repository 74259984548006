import React from 'react';
import {UseCaseExamples} from "../../../utilities/constants";
import CaseExample from "../CaseExample/CaseExample";
import './caseExampleGroup.css'
import {useTranslation} from "react-i18next";

function CaseExampleGroup (props) {
    const {t} = useTranslation(['wizard'])
    const {baseFolder, type} = props;

    const caseExampleText = (type === "CLIENT_MATTER" ? 0 : 1);
    return (
        <div>
            <label className={"label is-small"}>{t('wizard:examples.caseDescription')}</label>
            {caseExampleText === 0 &&
                <div className={"example-group"}>
                    {UseCaseExamples.CLIENT_MATTER.text.map((values, index) => {
                        return (
                            <div key={`client_matter_example_${index}`}>
                                <CaseExample baseFolder={baseFolder} type={type} text={[values.client, values.matter]}/>
                            </div>
                        )
                    })}
                </div>
            }
            {caseExampleText === 1 &&
                <div className={"example-group"}>
                    {UseCaseExamples.SINGLE_FOLDER.text.map((value, index) => {
                        return (
                            <div key={`single_folder_example_${index}`}>
                                <CaseExample baseFolder={baseFolder} type={type} text={[value]}/>
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    )
}

export default CaseExampleGroup;