import {SettingsRowValue} from "../../common/CustomTable/CustomTable";
import {useTranslation} from "react-i18next";
import ThirdPartyUserCredential from "../../../models/thirdparty/ThirdPartyUserCredential";
import ApiKeyUserCredentialFormBody from "../userCredential/ApiKeyUserCredentialFormBody";

function GenAiUserCredentialFormBody(props) {
    return (
        <ApiKeyUserCredentialFormBody {...props} type={ThirdPartyUserCredential.Type.GEN_AI}
            SettingRows={GenAiUserCredentialFormBodySettingRows}/>
    )
}

function GenAiUserCredentialFormBodySettingRows(props) {
    const {t} = useTranslation(['thirdPartyService', 'common']);
    const {
        thirdPartyService,
        isDisabled
    } = props;

    return (
        <>
            <SettingsRowValue label={t('thirdPartyService:label.url')} value={thirdPartyService.url}
                isTight isDisabled={isDisabled}/>
        </>
    )
}

export default GenAiUserCredentialFormBody;
