import React, {useEffect, useLayoutEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {createNestedUpdateState} from "../../../utilities/componentFunctions";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import {getValues} from "../../../utilities/helperFunctions";
import {ListDropdown} from "../../common/Dropdown/Dropdown";
import {httpVerb} from "../../../utilities/constants";
import JobScheduleModel from "../../../models/job/JobScheduleModel";
import {useDispatch} from "react-redux";
import Checkbox from "../../common/Checkbox/Checkbox";
import {
    useClearOnFirstCallback,
    useDropdownHandler,
    useInputHandler,
    useToggleHandler,
    useUpdateHandler
} from "../../../utilities/formHooks";

function WebhookTriggerPanel(props) {
    const dispatch = useDispatch();
    const {t} = useTranslation(['jobSchedule']);

    const {
        webhookTrigger,
        updateState
    } = props;

    const clearOnFirstCallback = useClearOnFirstCallback();
    const updateWebhookTriggerState = createNestedUpdateState(updateState, ['webhookTrigger']);

    const updateHandler = useUpdateHandler({
        updateState: updateWebhookTriggerState
    });
    const dropdownHandler = useDropdownHandler({updateHandler});
    const inputHandler = useInputHandler({updateHandler});
    const toggleHandler = useToggleHandler({updateHandler});

    const signatureKeyRef = useRef();
    // onMount decrypt, unMount encrypt
    useLayoutEffect(() => {
        dispatch(JobScheduleModel.actionCreators.decryptProtectedValues('webhookTrigger'));
        return () => {
            if (signatureKeyRef.current) {
                dispatch(JobScheduleModel.actionCreators.encryptProtectedValues('webhookTrigger', {signatureKey: signatureKeyRef.current.value}));
            }
        }
    }, []);

    useEffect(() => {
        if (!webhookTrigger.useSignatureKey) {
            updateWebhookTriggerState({signatureKey: ''});
        }
    }, [webhookTrigger.useSignatureKey]);

    useEffect(() => {
        if (webhookTrigger.useSignatureKey && webhookTrigger.httpVerb === httpVerb.GET) {
            updateWebhookTriggerState({useSignatureKey: false});
        }
    }, [webhookTrigger.httpVerb]);


    const isUseSignatureKeyDisabled = webhookTrigger.httpVerb === httpVerb.GET;
    const isSignatureKeyDisabled = isUseSignatureKeyDisabled || !webhookTrigger.useSignatureKey;

    return (
        <>
            <div className="display-input" style={{display: 'flex', alignItems: 'center'}}>
                <label className="label" style={{marginRight: '0.75rem'}}>
                    {t('jobSchedule:label.httpVerb')}:
                </label>
                <HttpVerbDropdown value={webhookTrigger.httpVerb} onItemSelect={dropdownHandler}/>
            </div>

            <Checkbox label={t('jobSchedule:label.useSignatureKey')} name={'useSignatureKey'} value={webhookTrigger.useSignatureKey}
                onChange={toggleHandler} isDisabled={isUseSignatureKeyDisabled}/>

            {webhookTrigger.useSignatureKey &&
                <div className="display-input">
                    <HTMLTextInput ref={signatureKeyRef} label={t('jobSchedule:label.signatureKey')} name={'signatureKey'} onBeforeInput={clearOnFirstCallback}
                        type={'password'} defaultValue={webhookTrigger.signatureKey} onChange={inputHandler} isDisabled={isSignatureKeyDisabled}
                    />
                </div>
            }
        </>
    )
}

function HttpVerbDropdown(props) {
    const {t} = useTranslation(['aria', 'jobSchedule']);

    const httpVerbItems = getValues(httpVerb)
        .map(verb => ({
            name: verb,
            value: verb
        }));

    return (
        <ListDropdown id={'httpVerbDropdown'} name={'httpVerb'} aria-label={t('aria:hiddenAssistText.httpVerb')}
            noneSelectedMessage={t('jobSchedule:option.selectHttpVerb')} items={httpVerbItems} {...props}/>
    )
}

export default WebhookTriggerPanel;
