import {createSelector, createSelectorCreator, defaultMemoize} from 'reselect';
import {getEntries, getKeys, nameLocaleCompare, objEquals} from '../utilities/helperFunctions';

export const createDeepEqualSelector = createSelectorCreator(
    defaultMemoize,
    objEquals
);

const getDetailsMap = type => state => {
    return state[`${type}DetailsMap`];
};

//Basic deep equal selector, used to memoize input
export const makeIdentityDeepEqualSelector = input => createDeepEqualSelector(
    input,
    output => output
);

//Selector logic builds array of ids from detailsMap and uses that as input to build memoized list of Ids
//Only changes to id(s) will cause re-memoization
export const getIdsList = type => createSelector(
    getDetailsMap(type),
    detailsMap => {
        return getKeys(detailsMap);
    }
);

export const getNameValueList = type => createSelector(
    getDetailsMap(type),
    detailsMap => {
        return getEntries(detailsMap)
            .map(([id, model]) => ({name: model.name, value: id}))
            .sort(nameLocaleCompare);
    }
);

export const getNameValueTypeList = (type, typeName) => createSelector(
    getDetailsMap(type),
    detailsMap => {
        return getEntries(detailsMap)
            .map(([id, model]) => ({name: model.name, value: id, type: typeName}))
            .sort(nameLocaleCompare);
    }
);

export const getUserSettingsAllowedParameterValues = type => createSelector(
    (state) => state.userSettingsMap.get(type).allowedParameterValues,
    allowedParameterValues => {

        return allowedParameterValues
            .flatMap(curr => getEntries(curr));
    }
)
