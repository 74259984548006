import React, {Component} from 'react';
import './App.css';
import NavBar from './components/app/navbar/NavBar';
import JobPage from './components/job/JobPage';
import LoginPage from './components/app/pages/LoginPage';
import LibraryPage from './components/app/pages/LibraryPage';
import ClientPage from './components/app/pages/ClientPage';
import logoPoweredBy from './resources/images/logo/logo-light-powered-by.svg';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {routes} from './utilities/constants';
import {connect, useDispatch, useSelector} from 'react-redux';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
	faAngleDoubleDown,
	faAngleDoubleLeft,
	faAngleDoubleRight,
	faAngleDoubleUp,
	faAngleDown,
	faAngleLeft,
	faAngleRight,
	faAngleUp,
	faArchive,
	faArrowUp,
	faBan,
	faBell,
	faCheckCircle,
	faCopy,
	faEdit,
	faEllipsisH,
	faEye,
	faEyeSlash,
	faFileExport,
	faFileUpload,
	faFolderOpen,
	faForwardStep,
	faMinus,
	faPen,
	faPencilAlt,
	faPlus,
	faQuestionCircle,
	faRotateRight,
	faSave,
	faSearch,
	faShieldHalved,
	faSync,
	faTimes,
	faTimesCircle,
	faUpload,
	faUpRightFromSquare
} from '@fortawesome/free-solid-svg-icons';
import {DisconnectPopup, Popup} from './components/common/Popups/Popups';
import SettingsPage from './components/app/pages/SettingsPage';
import {getDefaultRoute, getUserCanViewRoutePrivileges} from './reselect/selectors';
import JobPdfPage from "./components/job/JobPdfPage";
import {popupInfoKeys} from "./i18next/keys";
import PopupModel from "./models/scheduler/PopupModel";
import EditModel from "./models/scheduler/EditModel";
import {getStatusIconImgContent} from "./utilities/iconResolver";
import {getSearchParamsAsObject, objEquals, switchcase} from "./utilities/helperFunctions";
import {useTranslation} from "react-i18next";
import LegalHoldPage from "./components/legalhold/LegalHoldPage";
import UserNoticePage from "./components/notice/UserNoticePage";
import SchedulerModel from "./models/scheduler/SchedulerModel";
import {linkLogin, triggerOidcLogin} from "./saga/rootSaga";
import {call, put} from "redux-saga/effects";
import CurrentUserModel from "./models/user/CurrentUserModel";

library.add(faTimesCircle, faCheckCircle, faAngleDown, faAngleDoubleDown, faAngleUp, faAngleDoubleUp, faAngleRight, faAngleDoubleRight, faAngleLeft,
	faAngleDoubleLeft, faTimes, faPlus, faMinus, faSearch, faEllipsisH, faQuestionCircle, faPen, faUpload, faSave, faSync, faFileExport, faFileUpload,
	faEyeSlash, faEye, faArchive, faFolderOpen, faEdit, faBell, faPencilAlt, faCopy, faArrowUp, faBan, faForwardStep, faShieldHalved, faUpRightFromSquare,
	faRotateRight
);


class App extends Component {

	constructor(props) {
		super(props);

		this.getSafeLinkNavigation = this.getSafeLinkNavigation.bind(this);
		this.linkNavigation = this.linkNavigation.bind(this);

		this.state = {
			searchParams: {}
		}
	}

	componentDidMount() {
		const searchParams = getSearchParamsAsObject(this.props.location.search);
		const paths = this.props.location.pathname.split('/').filter(e => e);

		if (searchParams['id'] != null || paths.length > 1) {
			const modelType = this.props.location.pathname;
			this.props.updateStateFromPath(modelType, searchParams);
		}

		document.body.addEventListener('mouseup', App.blurElementOnMouseUp);

		this.historyUnblock = this.props.history.block(this.getSafeLinkNavigation);
		this.historyUnlisten = this.props.history.listen(this.linkNavigation);

		['keyup', 'keydown'].forEach(e => window.addEventListener(e, App.preventTextSelection));
	}

	componentWillUnmount() {
		document.body.removeEventListener('mouseup', App.blurElementOnMouseUp);
		this.historyUnblock();
		this.historyUnlisten();

		['keyup', 'keydown'].forEach(e => window.removeEventListener(e, App.preventTextSelection));
	}

	componentDidUpdate(prevProps) {
		if (window.location.href.includes('disableHighlightText')) {
			this.props.setDisableRegex(true);
		}
	}

	//To remove focus on buttons after click
	static blurElementOnMouseUp() {
		const activeElement = document.activeElement;

		if (['BUTTON'].includes(activeElement.tagName)) {
			activeElement.blur();
		}
	}

	//To prevent text selection when shft key pressed
	static preventTextSelection(event) {
		document.onselectstart = () => !event.shiftKey;
	}

	linkNavigation(location) {
		const searchParams = getSearchParamsAsObject(location.search);
		const paths = location.pathname.split('/').filter(e => e);

		if (!objEquals(this.state.searchParams, searchParams)) {
			this.setState({searchParams});

			if (searchParams['ssotoken'] != null) {
				const loginLink = window.location.origin + window.location.pathname + '#' + location.pathname + location.search;
				this.props.yieldEffect(call(function* () {
					const userData = yield linkLogin(loginLink);
					if (userData != null) {
						yield put(CurrentUserModel.actionCreators.ssoLogin(userData));
					}
				}.bind(this)));

			} else if (searchParams['oidcScope'] != null) {
				this.props.yieldEffect(call(triggerOidcLogin, searchParams));

			} else if (searchParams['id'] != null || paths.length > 1) {
				const modelType = location.pathname;
				this.props.updateStateFromPath(modelType, searchParams);
			}
		}
	}

	getSafeLinkNavigation({pathname}) {
		if (pathname === this.props.location.pathname) {
			return false;
		}

		if (!this.navigated && this.props.editDetails.activeModel != null && this.props.currentUser.isAuthenticated) {
			this.navigated = true;
			const navigate = () => {
				this.props.history.push(pathname);
				this.navigated = false;
			};

			if (this.props.editDetails.isChanged) {
				this.props.showPopup({
					info: {
						key: popupInfoKeys.SAFE_CLOSE
					},
					buttons: [{
						titleKey: 'common:option.dontSave',
						onClick: [this.props.cancelEdit, navigate]
					}, {
						titleKey: 'common:option.save',
						onClick: [this.props.saveEdit, navigate],
						isDisabled: !this.props.editDetails.isSaveEnabled
					}
					],
					cancelButton: {
						titleKey: 'common:option.cancel',
						onClick: () => this.navigated = false
					}
				});
			} else {
				this.props.cancelEdit();
				navigate();
			}
			return false;
		}
		return true;
	};

	render() {

		const {
			canViewRoute,
			defaultRoute,
			location,
			brandingLogo,
			isLoading,
			isServerScheduler,
			isRelativityApplication,
			isDisconnected
		} = this.props;

		const logoPadding = location.pathname === routes.LOGIN ? ' login-padding' : '';
		const showAppHeader = [routes.JOB_PDF, routes.USER_NOTICE].every(route => !location.pathname.startsWith(route));

		return (
			<div className="App">
				{!isLoading &&
				<>
					{showAppHeader && !isRelativityApplication &&
					<NavBar currentRoute={location.pathname} />
					}
					{isServerScheduler && !isRelativityApplication &&
					<section className="pages">
						<Switch>
							<Route exact path="/" render={() => <Redirect to={routes.LOGIN}/>} />
							<Route path={routes.OIDCRESPONSE} render={() => <Redirect to={routes.LOGIN}/>}/>
							<Route path={routes.OIDC_ERROR} render={() => <Redirect to={routes.LOGIN}/>}/>
							<Route path={routes.LOGIN}
								render={() => defaultRoute ? <Redirect to={defaultRoute}/> : <LoginPage/>}/>

							<SecretRoute path={routes.JOBS} component={JobPage} isAuthenticated={canViewRoute.jobs} />
							<SecretRoute path={routes.LEGAL_HOLD} component={LegalHoldPage} isAuthenticated={canViewRoute.legalHold} />
							<SecretRoute path={routes.CLIENTS} component={ClientPage} isAuthenticated={canViewRoute.clients} />
							<SecretRoute path={routes.LIBRARY} component={LibraryPage} isAuthenticated={canViewRoute.libraries} />
							<SecretRoute path={routes.SETTINGS} component={SettingsPage} isAuthenticated={canViewRoute.settings} />

							<SecretRoute path={routes.JOB_PDF} component={JobPdfPage} isAuthenticated={canViewRoute.jobs} />
							<SecretRoute path={routes.USER_NOTICE} component={UserNoticePage} isAuthenticated={canViewRoute.legalHoldNotices} />

							<Redirect to={routes.LOGIN}/>
						</Switch>
					</section>
					}
					{isRelativityApplication &&
						<section className="pages no-header">
							<Switch>
								<Route exact path="/" render={() => <Redirect to={routes.LOGIN}/>} />
								<Route path={routes.LOGIN}
									render={() => <Redirect to={defaultRoute}/>}/>

								<SecretRoute path={routes.LEGAL_HOLD} component={LegalHoldPage} isAuthenticated={canViewRoute.legalHold} />
								<SecretRoute path={routes.USER_NOTICE} component={UserNoticePage} isAuthenticated={canViewRoute.legalHoldNotices} />

								<SecretRoute path={routes.JOBS} component={JobPage} isAuthenticated={canViewRoute.jobs} />
								<SecretRoute path={routes.JOB_PDF} component={JobPdfPage} isAuthenticated={canViewRoute.jobs} />
								<SecretRoute path={routes.CLIENTS} component={ClientPage} isAuthenticated={canViewRoute.clients} />
								<SecretRoute path={routes.LIBRARY} component={LibraryPage} isAuthenticated={canViewRoute.libraries} />
								<SecretRoute path={routes.SETTINGS} component={SettingsPage} isAuthenticated={canViewRoute.settings} />

								<Redirect to={routes.LOGIN}/>
							</Switch>
						</section>
					}

					{brandingLogo != null &&
						<img className={'logo bottom-right' + logoPadding} src={logoPoweredBy} alt="Powered by Automate"/>
					}
				</>
				}

				<SimplePopup/>
				<Popup id="notSchedulerPopup" isActive={!isServerScheduler} showX={false} hideButtons isRigid
					info={{key: popupInfoKeys.NOT_SCHEDULER_SERVER}}
					icon={getStatusIconImgContent('statusCancelled')}
				/>
				<DisconnectPopup isActive={isDisconnected}/>
			</div>
		);
	}
}

function SecretRoute(props) {
	const {component: Component, componentProps, isAuthenticated, ...rest} = props;

	return (
		<Route {...rest}
			render={props => (
				isAuthenticated ?
					<Component {...props} {...componentProps} />
					:
					<Redirect to={routes.LOGIN}/>
			)}
		/>
	)
}

function SimplePopup(props) {
	const {t} = useTranslation(['popupInfo']);

	const dispatch = useDispatch();
	const popupEntry = useSelector(state => [...state.popupDetails].pop());

	if (popupEntry == null)
		return null;

	const [id, popup] = popupEntry;
	const icon = switchcase({
		'info': getStatusIconImgContent('statusInfo'),
		'link': getStatusIconImgContent('statusLink'),
		'success': getStatusIconImgContent('statusFinished'),
		'warning': getStatusIconImgContent('statusWarning'),
		'error': getStatusIconImgContent('statusCancelled')
	})(popup.icon)(popup.icon);

	return (
		<Popup {...popup} {...props} title={t(`popupInfo:label.${popup.icon}`)} icon={icon}
			hide={() => dispatch(PopupModel.actionCreators.hide(id))}
		/>
	)
}


const mapStateToProps = state => {
	const {currentUser, schedulerDetails: {isServerScheduler, isRelativityApplication, brandingLogo, isDisconnected}, editDetails, componentStates: {app}} = state;

	return {
		currentUser,
		isServerScheduler,
		isRelativityApplication,
		isDisconnected,
		editDetails,
		canViewRoute: getUserCanViewRoutePrivileges(state),
		defaultRoute: getDefaultRoute(state),
		brandingLogo,
		...app
	};
};

const mapDispatchToProps = dispatch => {
	return {
		saveEdit: () => dispatch(EditModel.actionCreators.save()),
		cancelEdit: () => dispatch(EditModel.actionCreators.cancel()),

		showPopup: payload => dispatch(PopupModel.actionCreators.show(payload)),
		updateStateFromPath: (modelType, args) => {
			dispatch(EditModel.actionCreators.callEditBreakingFunc(() => dispatch(SchedulerModel.actionCreators.updateStateFromPath(modelType, args))));
		},

		yieldEffect: effect => dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(effect)),
		setDisableRegex: disableRegex => dispatch(SchedulerModel.actionCreators.setDisableRegex(disableRegex))
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));