import React from "react";
import WorkflowBuilderOperation from "../../../models/workflowbuilder/WorkflowBuilderOperation";
import {buildOperationFieldParameterNames} from "../../guidedJob/operationFieldParameterNames";
import {getDataNameFromParentNode, getKeys, stringToBool} from "../../../utilities/helperFunctions";
import {useTranslation} from "react-i18next";
import {FormElementRow} from "../../common/CustomTable/CustomTable";
import Checkbox from "../../common/Checkbox/Checkbox";
import {objectIdentifierType, vaultExportFormats, vaultExportRegions} from "../../../utilities/constants";
import {vaultJobParameterNames} from "../VaultGuidedJobForm";
import VaultOperationPane from "./VaultOperatonPane";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import {ListDropdown} from "../../common/Dropdown/Dropdown";
import Text from "../../common/Text/Text";

const fieldNames = ['exportNamePrefix', 'exportRegion', 'exportFormat', 'savedQueryIdentifierType',
    'savedQueryIdentifiers', 'mailExportOptions', 'driveExportOptions', 'waitForCompletion'];
export const vaultExportSavedQueriesParameterNames = buildOperationFieldParameterNames(WorkflowBuilderOperation.Alias.VAULT_EXPORT_SAVED_QUERIES, fieldNames);


function getNextEnabled(workflowActions, getParameter) {
    const exportNamePrefix = getParameter(vaultExportSavedQueriesParameterNames.exportNamePrefix);
    const exportRegion = getParameter(vaultExportSavedQueriesParameterNames.exportRegion);
    const exportFormat = getParameter(vaultExportSavedQueriesParameterNames.exportFormat);
    return {
        exportOptions: exportNamePrefix.value && vaultExportRegions[exportRegion.value] != null && vaultExportFormats[exportFormat.value] != null
    };
}

export function getVaultExportOptionsGuide(props) {
    const {
        workflowActions,
        getParameter,
        updateParameter
    } = props;

    const exportNamePrefix = getParameter(vaultExportSavedQueriesParameterNames.exportNamePrefix);
    const exportRegion = getParameter(vaultExportSavedQueriesParameterNames.exportRegion);
    const exportFormat = getParameter(vaultExportSavedQueriesParameterNames.exportFormat);
    const waitForCompletion = getParameter(vaultExportSavedQueriesParameterNames.waitForCompletion);

    if (!exportNamePrefix.value) {
        updateParameter(vaultExportSavedQueriesParameterNames.exportNamePrefix, '');
    }
    updateParameter(vaultExportSavedQueriesParameterNames.savedQueryIdentifierType, objectIdentifierType.ID);
    if (!workflowActions.selectSavedQueries) {
        updateParameter(vaultExportSavedQueriesParameterNames.savedQueryIdentifiers, JSON.stringify([vaultJobParameterNames.savedQueryIds]));
    }
    if (vaultExportRegions[exportRegion.value] == null) {
        updateParameter(vaultExportSavedQueriesParameterNames.exportRegion, vaultExportRegions.ANY);
    }
    if (vaultExportFormats[exportFormat.value] == null) {
        updateParameter(vaultExportSavedQueriesParameterNames.exportFormat, vaultExportFormats.MBOX);
    }
    if (waitForCompletion.value !== JSON.stringify(stringToBool(waitForCompletion.value))) {
        updateParameter(vaultExportSavedQueriesParameterNames.waitForCompletion, JSON.stringify(true));
    }

    return {
        getPanes: function(t, props) {
            const nextEnabled = getNextEnabled(workflowActions, props.getParameter);
            return [{
                title: t('guidedJob:panes.exportOptions'),
                isNextEnabled: nextEnabled.exportOptions,
                component: <VaultOperationPane {...props}
                    workflowActions={workflowActions} OperationPane={VaultExportOptionsPane}/>
            }];
        }
    }
}

function VaultExportOptionsPane(props) {
    const {t} = useTranslation(['guidedJob', 'common']);
    const {
        getParameter,
        updateParameter,
        isDisabled
    } = props;

    const exportNamePrefix = getParameter(vaultExportSavedQueriesParameterNames.exportNamePrefix);
    const exportRegion = getParameter(vaultExportSavedQueriesParameterNames.exportRegion);
    const exportFormat = getParameter(vaultExportSavedQueriesParameterNames.exportFormat);
    const mailExportOptions = getParameter(vaultExportSavedQueriesParameterNames.mailExportOptions, '{}', {parse: true});
    const driveExportOptions = getParameter(vaultExportSavedQueriesParameterNames.driveExportOptions, '{}', {parse: true});

    const {
        exportLinkedDriveFiles,
        showConfidentialModeContent,
        useNewExport
    } = mailExportOptions.value;
    const {
        includeAccessInfo
    } = driveExportOptions.value;

    function optionsToggleHandler(event) {
        const {name, checked, dataset: {option}} = event.target;
        let prevValue = {};
        if (option === 'mail') {
            prevValue = mailExportOptions.value;
        } else if (option === 'drive') {
            prevValue = driveExportOptions.value;
        }
        const update = {...prevValue, [name]: checked};
        updateParameter(vaultExportSavedQueriesParameterNames[`${option}ExportOptions`], JSON.stringify(update));
    }

    function inputHandler(event) {
        const {name, value} = event.target;
        updateParameter(name, value);
    }

    function dropdownHandler(event) {
        const {dataset: {value}, parentNode} = event.currentTarget;
        const name = getDataNameFromParentNode(parentNode);
        updateParameter(name, value);
    }


    return (
        <div>
            <div className="display-input">
                <HTMLTextInput label={t('guidedJob:label.exportNamePrefix')} name={vaultExportSavedQueriesParameterNames.exportNamePrefix}
                    value={exportNamePrefix.value} onChange={inputHandler} isRequired isDisabled={isDisabled}/>
            </div>

            <div className="forms-table display-input">
                <div className="table-row-group">
                    <FormElementRow label={t('guidedJob:label.exportRegion')} isDisabled={isDisabled}
                        element={
                            <ExportRegionDropdown name={vaultExportSavedQueriesParameterNames.exportRegion}
                                value={exportRegion.value} onItemSelect={dropdownHandler} isRequired isDisabled={isDisabled}/>
                        }/>

                    <FormElementRow label={t('guidedJob:label.exportFormat')} isDisabled={isDisabled}
                        element={
                            <ExportFormatDropdown name={vaultExportSavedQueriesParameterNames.exportFormat}
                                value={exportFormat.value} onItemSelect={dropdownHandler} isRequired isDisabled={isDisabled}/>
                        }/>
                </div>
            </div>

            <div className="display-input flex-column">
                <Text value={t('guidedJob:label.mailOptions')}
                    style={{marginBottom: '0.5rem'}} isDisabled={isDisabled}/>

                <Checkbox label={t('guidedJob:label.exportLinkedDriveFiles')} name={'exportLinkedDriveFiles'}
                    data-option={'mail'} value={exportLinkedDriveFiles} onClick={optionsToggleHandler}
                    containerStyle={{marginBottom: '0.5rem'}} isDisabled={isDisabled}/>

                <Checkbox label={t('guidedJob:label.showConfidentialModeContent')} name={'showConfidentialModeContent'}
                    data-option={'mail'} value={showConfidentialModeContent} onClick={optionsToggleHandler}
                    containerStyle={{marginBottom: '0.5rem'}} isDisabled={isDisabled}/>

                <Checkbox label={t('guidedJob:label.useNewExport')} name={'useNewExport'}
                    data-option={'mail'} value={useNewExport} onClick={optionsToggleHandler}
                    containerStyle={{marginBottom: '0.5rem'}} isDisabled={isDisabled}/>
            </div>

            <div className="display-input flex-column">
                <Text value={t('guidedJob:label.driveOptions')}
                    style={{marginBottom: '0.5rem'}} isDisabled={isDisabled}/>

                <Checkbox label={t('guidedJob:label.includeAccessInfo')} name={'includeAccessInfo'}
                    data-option={'drive'} value={includeAccessInfo} onClick={optionsToggleHandler}
                    containerStyle={{marginBottom: '0.5rem'}} isDisabled={isDisabled}/>
            </div>
        </div>
    )
}

function ExportRegionDropdown(props) {
    const {t} = useTranslation(['guidedJob', 'aria']);

    const items = getKeys(vaultExportRegions)
        .map(type => ({
            name: t(`workflowBuilder:ExportRegion.${type}`),
            value: type
        }));

    return (
        <ListDropdown id={'vaultExportRegionDropdown'} name={'exportRegion'} items={items}
            noneSelectedMessage={t('guidedJob:option.selectExportRegion')}
            {...props} />
    );
}

function ExportFormatDropdown(props) {
    const {t} = useTranslation(['guidedJob', 'aria']);

    const items = getKeys(vaultExportFormats)
        .map(type => ({
            name: t(`workflowBuilder:ExportFormat.${type}`),
            value: type
        }));

    return (
        <ListDropdown id={'vaultExportFormatDropdown'} name={'exportFormat'} items={items}
            noneSelectedMessage={t('guidedJob:option.selectExportFormat')}
            {...props} />
    );
}
