import DetailsModel, {DetailsApi, DetailsSaga} from "../generics/DetailsModel";
import ComponentStateModel from "../generics/ComponentStateModel";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import {all, put} from "redux-saga/effects";
import {contextCall} from "../../saga/sagaFunctions";
import {details, routes} from "../../utilities/constants";
import SchedulerModel from "../scheduler/SchedulerModel";
import {settingsDisplayKeys} from "../../i18next/keys";
import AxiosProxy from "../api/AxiosProxy";

class ApiKeyModel extends DetailsModel {

    static nom = 'ApiKeyModel';
    static actions = ApiKeyModel.buildActions('API_KEY');
    static actionCreators = ApiKeyModel.buildActionCreators(ApiKeyModel.actions);
    static reducer = ApiKeyModel.buildReducer(ApiKeyModel.actions);

    static componentActionCreators = {
        ...ApiKeyModel.buildComponentUpdateActionCreators(),
        ...ApiKeyModel.buildComponentSetActiveActionCreators()
    };

    constructor(model = {}) {
        super(model);
        const {user, createdDay, lastUsedDay, validityDays, status} = model;

        this.user = user;
        this.createdDay = createdDay;
        this.lastUsedDay = lastUsedDay;
        this.validityDays = validityDays;
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'apiKeyDisplay',
                type: 'Display',
                state: {
                    apiKeyId: null,
                    isApiKeyFormActive: false
                }
            },
            {
                key: 'apiKeyTablet',
                type: 'Tablet',
                state: {}
            },
            {
                key: 'apiKeyForm',
                type: 'Form',
                state: {
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'API_KEY_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }
}

export class ApiKeyApi extends DetailsApi {
    static location = '/security';
    static type = '/apiKey';
}

export class ApiKeySaga extends DetailsSaga {

    static ModelType = ApiKeyModel;
    static ModelApi = ApiKeyApi;

    static activationComponent = 'API_KEY_DISPLAY';
    static variableNames = {
        detailsMap: 'apiKeyDetailsMap',
        instanceId: 'apiKeyId',
        modelName: 'apiKeyName',
        isFormActive: 'isApiKeyFormActive',
        updateDisplay: 'updateDisplay',
        updatePane: 'updateTablet',
        route: routes.SETTINGS
    };

    static translations = {
        itemTitle: '$t(apiKey:label.name)',
        itemLower: '$t(apiKey:label.name_lower)'
    };

    static buildActivationEffects(dispatch) {
        return [
            ...super.buildActivationEffects(dispatch),
            // ACTIVATION EFFECTS
            put(ApiKeyModel.actionCreators.startPollingDetails()),
        ]
    }

    static buildDeactivationEffects() {
        return [
            ...super.buildDeactivationEffects(),
            // DEACTIVATION EFFECTS
            put(ApiKeyModel.actionCreators.stopPollingDetails()),
        ]
    }

    static* submitForm(action) {
        const {updateDisplay} = this.variableNames;

        const {formData} = action.payload;
        const saveValues = yield contextCall(this, 'getSaveValues', formData);

        const {data} = yield contextCall(this.ModelApi, 'post', saveValues);
        const isFormActive = this.getIsFormActive(formData);

        yield all([
            put(this.ModelType.actionCreators.addDetails(data)),
            put(this.ModelType.componentActionCreators[updateDisplay]({[isFormActive]: false, apiKeyId: data.id, createdApiKeySecret: data.secret}))
        ]);
    }

    static* hidePane() {
        const {updateDisplay} = this.variableNames;

        yield put(this.ModelType.componentActionCreators[updateDisplay]({apiKeyId: null, createdApiKeySecret: null}));
    }
    static* setInstanceId(args) {
        const {updateDisplay, instanceId} = this.variableNames;

        yield all ([
            put(SchedulerModel.actionCreators.setSettingsDisplay(settingsDisplayKeys.API_KEYS)),
            put(this.ModelType.componentActionCreators[updateDisplay]({[instanceId]: args.id}))
        ]);
    }

    static getSaveValues(values) {
        const {apiKeyName: name, validityDays} = values;

        return {
            name,
            validityDays
        }
    }

    static* queryDetails() {
        const response = yield contextCall(ApiKeyApi, 'getDetails');
        const key = details.API_KEYS;

        if (AxiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(ApiKeyModel.actionCreators.setDetailsMap(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }
}

export default ApiKeyModel;