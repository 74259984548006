import {SettingsRowValue} from "../../common/CustomTable/CustomTable";
import React, {useRef} from "react";
import {useTranslation} from "react-i18next";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import {useClearOnFirstCallback} from "../../../utilities/formHooks";
import Form, {FormFooter, FormHeader} from "../../common/Form/Form";
import Text from "../../common/Text/Text";
import {capitalize, lowerCaseFirstLetter} from "../../../utilities/helperFunctions";
import {useForceRenderer} from "../../../utilities/hooks";


function ApiKeyUserCredentialFormBody(props) {
    const {t} = useTranslation(['thirdPartyService', 'common']);
    const {
        type,
        thirdPartyService,
        SettingRows,
        onClose,
        isDisabled
    } = props;

    const apiTokenRef = useRef({});
    const clearOnFirstCallback = useClearOnFirstCallback();
    const forceRender = useForceRenderer();

    function onLogin() {
        props.onLogin({
            type,
            token: apiTokenRef.current.value
        });
    }

    const camelCaseType = lowerCaseFirstLetter(thirdPartyService.type);
    const isLoginEnabled = !!(apiTokenRef.current.value);

    return (
        <Form onClose={onClose} width={'35rem'}
            isDisabled={isDisabled} closeButtonAriaLabel={t(`thirdPartyService:option.closeApiKeyUserCredentialForm${thirdPartyService.type}`)}
            header={
                <FormHeader text={t(`thirdPartyService:option.signIn${capitalize(thirdPartyService.authenticationScope)}`)}
                    iconName={`${camelCaseType}UserSignIn`} isDisabled={isDisabled}/>
            }
            body={
                <form onSubmit={onLogin} style={{paddingTop: '0.5rem', paddingBottom: '0.25rem'}}>
                    <div className="settings-table">
                        <div className="table-row-group">
                            <SettingsRowValue label={t(`thirdPartyService:label.${camelCaseType}`)} value={thirdPartyService.name}
                                isTight isDisabled={isDisabled}/>

                            {SettingRows &&
                                <SettingRows thirdPartyService={thirdPartyService} isDisabled={isDisabled}/>
                            }
                        </div>
                    </div>

                    <div className="display-input">
                        <Text value={t('thirdPartyService:label.credentials')} style={{marginBottom: '0.25rem'}}
                            isDisabled={isDisabled}/>

                        <HTMLTextInput name={`${camelCaseType}ApiToken`} type={'password'} inputRef={apiTokenRef} onChange={forceRender}
                            placeholder={t('common:label.apiToken')} autoComplete={`${camelCaseType}ApiToken`}
                            onBeforeInput={clearOnFirstCallback} isDisabled={isDisabled}/>
                    </div>

                    <button type="submit" hidden disabled={!isLoginEnabled}/>
                </form>
            }
            footer={
                <FormFooter addText={t('thirdPartyService:option.signIn')} onAddClick={onLogin}
                    onCancel={onClose} isAddEnabled={isLoginEnabled} isDisabled={isDisabled}
                />
            }
        />
    )
}

export default ApiKeyUserCredentialFormBody;
