import ReduxModel from "../generics/ReduxModel";
import {
    deepCopy,
    getEntries,
    getTranslatedValueOrDefault,
    includesAll,
    isNotEmptyNorFalsy,
    objEquals,
    stringToBool
} from "../../utilities/helperFunctions";
import FormBuilderConfiguration from "./FormBuilderConfiguration";
import * as ReactDOMServer from "react-dom/server";

class FormBuilderComponentConfiguration extends ReduxModel {

    constructor(model = {}) {
        super();
        this.forceUpdate(model);
        if (Array.isArray(this.componentConfigurations)) {
            this.componentConfigurations = this.componentConfigurations.map(config => new FormBuilderComponentConfiguration(config));
        }

        // Objects
        if (this.formConfiguration != null) {
            this.formConfiguration = FormBuilderConfiguration.buildFormConfiguration(this.formConfiguration);
        }

        // InputList
        if (this.componentType === FormBuilderComponentConfiguration.ComponentType.INPUT_LIST && !Array.isArray(this.componentConfigurations)) {
            this.componentConfigurations = [
                new FormBuilderComponentConfiguration({
                    name: 0,
                    componentType: FormBuilderComponentConfiguration.ComponentType.INPUT,
                    defaultValue: '',
                    required: this.required,
                    min: this.min,
                    max: this.max,
                    regex: this.regex,
                    allowedValues: this.allowedValues
                })
            ];
        }

        // RadioButton
        if (this.radioButtonConfiguration != null) {
            this.radioButtonConfiguration = new FormBuilderComponentConfiguration(this.radioButtonConfiguration);
        }
        if (this.valueConfigurations != null) {
            this.valueConfigurations = getEntries(this.valueConfigurations).reduce((acc, curr) => {
                const [value, valueConfiguration] = curr;
                if (valueConfiguration != null) {
                    acc[value] = new FormBuilderComponentConfiguration(valueConfiguration);
                } else {
                    acc[value] = null;
                }
                return acc;
            }, {});
        }
        if (this.componentConfiguration != null) {
            this.componentConfiguration = new FormBuilderComponentConfiguration(this.componentConfiguration);
        }
    }

    static Type = {
        BOOLEAN: 'boolean',
        DOUBLE: 'double'
    };

    static ComponentType = {
        NULL: 'NULL',

        GROUP: 'GROUP',
        ROW: 'ROW',
        TABLE_GROUP: 'TABLE_GROUP',
        RADIO_BUTTON_GROUP: 'RADIO_BUTTON_GROUP',
        RADIO_BUTTON_CHECKBOX_GROUP: 'RADIO_BUTTON_CHECKBOX_GROUP',

        INPUT: 'INPUT',
        TEXTAREA: 'TEXTAREA',
        NUMBER: 'NUMBER',
        CHECKBOX: 'CHECKBOX',
        RADIO_BUTTON: 'RADIO_BUTTON',
        DROPDOWN: 'DROPDOWN',
        CHECKED_DROPDOWN: 'CHECKED_DROPDOWN',
        DROPDOWN_SELECTED_LIST: 'DROPDOWN_SELECTED_LIST',
        DATE: 'DATE',
        TIME: 'TIME',
        DATE_TIME: 'DATE_TIME',

        OBJECT: 'OBJECT',
        OBJECT_LIST: 'OBJECT_LIST',
        OBJECT_TABLE: 'OBJECT_TABLE',
        FAKE_OBJECT: 'FAKE_OBJECT',

        INPUT_LIST: 'INPUT_LIST',
        SCRIPT_PANE: 'SCRIPT_PANE',
        NUIX_PROFILE: 'NUIX_PROFILE',

        PARAMETER_TABLE: 'PARAMETER_TABLE',
        PARAMETER_INPUT: 'PARAMETER_INPUT',
        PARAMETER_DROPDOWN: 'PARAMETER_DROPDOWN'
    };

    static NuixProfileDynamicValuesType = {
        PROCESSING_PROFILES: 'PROCESSING_PROFILES',
        CONFIGURATION_PROFILES: 'CONFIGURATION_PROFILES',
        METADATA_PROFILES: 'METADATA_PROFILES',
        PLAYBOOKS: 'PLAYBOOKS',
        METADATA_IMPORT_PROFILES: 'METADATA_IMPORT_PROFILES',
        OCR_PROFILES: 'OCR_PROFILES',
        IMAGING_PROFILES: 'IMAGING_PROFILES',
        PRODUCTION_PROFILES: 'PRODUCTION_PROFILES',
    }

    static DynamicValuesType = {
        ...FormBuilderComponentConfiguration.NuixProfileDynamicValuesType,
        IN_PLACE_DATA_REPOSITORIES: 'IN_PLACE_DATA_REPOSITORIES',
        PARAMETERS: 'PARAMETERS'
    };

    static TableGroupComponents = new Set([
        FormBuilderComponentConfiguration.ComponentType.ROW,
        FormBuilderComponentConfiguration.ComponentType.DROPDOWN,
        FormBuilderComponentConfiguration.ComponentType.CHECKED_DROPDOWN,
        FormBuilderComponentConfiguration.ComponentType.PARAMETER_DROPDOWN,
        FormBuilderComponentConfiguration.ComponentType.RADIO_BUTTON,
        FormBuilderComponentConfiguration.ComponentType.CHECKBOX,
        FormBuilderComponentConfiguration.ComponentType.NUMBER,
        FormBuilderComponentConfiguration.ComponentType.DATE,
        FormBuilderComponentConfiguration.ComponentType.TIME,
        FormBuilderComponentConfiguration.ComponentType.DATE_TIME,
        FormBuilderComponentConfiguration.ComponentType.NUIX_PROFILE
    ]);

    static NonRootTableGroupComponents = new Set([
        FormBuilderComponentConfiguration.ComponentType.INPUT
    ]);

    static GroupComponentTypes = new Set([
        FormBuilderComponentConfiguration.ComponentType.GROUP,
        FormBuilderComponentConfiguration.ComponentType.ROW,
        FormBuilderComponentConfiguration.ComponentType.TABLE_GROUP,
        FormBuilderComponentConfiguration.ComponentType.RADIO_BUTTON_GROUP,
        FormBuilderComponentConfiguration.ComponentType.RADIO_BUTTON_CHECKBOX_GROUP,
    ]);

    static getId(configuration, parentId) {
        let id = `field:${configuration.name}`;
        if (parentId != null) {
            id = `${parentId}:${id}`;
        }
        return id;
    }

    getValueTextArray(t, formKey, value) {
        const translatedValues = [];
        switch (this.componentType) {
            case FormBuilderComponentConfiguration.ComponentType.PARAMETER_TABLE:
            case FormBuilderComponentConfiguration.ComponentType.OBJECT_LIST:
            case FormBuilderComponentConfiguration.ComponentType.OBJECT: {
                for (const config of this.formConfiguration.componentConfigurations) {
                    // Check if enabled/visible
                    let translatedValue = '';
                    if (config.isEnabled(value, this.formConfiguration.fieldConfigurations) && config.isVisible(value, this.formConfiguration.fieldConfigurations)) {
                        const fieldValue = value[config.name];
                        translatedValue = config.getValueTextArray(t, formKey, fieldValue).filter(c => c).join(', ');
                    }
                    translatedValues.push(translatedValue);
                }
                break;
            }
            default:
                if (Array.isArray(value)) {
                    if (value.length > 0) {
                        translatedValues.push(`[${value.join(', ')}]`);
                    }
                } else if (this.enumName != null) {
                    const translationKeys = [
                        `workflowBuilder:${formKey}.${this.enumName}.${value}`,
                        `workflowBuilder:${this.enumName}.${value}`
                    ];
                    translatedValues.push(getTranslatedValueOrDefault(t, value, ...translationKeys));
                } else {
                    translatedValues.push(value);
                }
        }
        return translatedValues;
    }

    generateDefaultObject() {
        if (this.formConfiguration != null) {
            return FormBuilderConfiguration.generateDefaultObject(this.formConfiguration);
        }
        if (Array.isArray(this.componentConfigurations)) {
            const initialValue = isNaN(this.componentConfigurations[0].name) ? {} : [];
            return this.componentConfigurations
                .reduce((acc, config) => {
                    acc[config.name] = config.generateDefaultObject();
                    return acc;
                }, initialValue);
        }
        return deepCopy(this.defaultValue);
    }

    isGroupType() {
        return FormBuilderComponentConfiguration.GroupComponentTypes.has(this.componentType);
    }

    isDefaultValue(value) {
        // Only compare fields with fieldConfigurations
        if (value != null) {
            if (this.formConfiguration != null && this.componentType === FormBuilderComponentConfiguration.ComponentType.OBJECT) {
                for (const [fieldName, fieldConfiguration] of this.formConfiguration.fieldConfigurations) {
                    if (!fieldConfiguration.isDefaultValue(value[fieldName])) {
                        return false;
                    }
                }
            } else {
                return objEquals(this.generateDefaultObject(), value);
            }
        }
        return true;
    }

    // Required if required || a required field within form is non-default
    isRequired(value) {
        if (this.required) {
            return true;
        }
        if (value && this.formConfiguration != null && this.componentType === FormBuilderComponentConfiguration.ComponentType.OBJECT) {
            for (const [fieldName, fieldConfiguration] of this.formConfiguration.fieldConfigurations) {
                const fieldValue = value[fieldName];
                if (fieldConfiguration.isRequired(fieldValue) && !fieldConfiguration.isDefaultValue(fieldValue)) {
                    return true;
                }
            }
        }
        return false;
    }

    isValid(value) {
        if (this.isGroupType()) {
            return true;
        }

        // Hack to propagate invalid
        if (value?._invalid || this._invalid) {
            return false;
        }

        // For arrays of arrays/objects
        if (Array.isArray(value)) {
            if (this.required && value.length === 0) {
                return false;
            }
            if (this.formConfiguration != null) {
                for (const val of value) {
                    if (!this.formConfiguration.isValid(val)) {
                        return false;
                    }
                }
            }
            if (Array.isArray(this.componentConfigurations)) {
                for (const val of value) {
                    for (const componentConfig of this.componentConfigurations) {
                        const fieldValue = typeof val === 'string' ? val : val[componentConfig.name];
                        if (!componentConfig.isValid(fieldValue)) {
                            return false;
                        }
                    }
                }
            }
            if (isNotEmptyNorFalsy(this.allowedValues) && !includesAll(this.allowedValues, value)) {
                return false;
            }
            return true;
        }

        if (this.componentType === FormBuilderComponentConfiguration.ComponentType.NUMBER) {
            if (this.min != null && Number(value) < this.min) {
                return false;
            }
            if (this.max != null && Number(value) > this.max) {
                return false;
            }
            return true;
        }

        if (this.formConfiguration != null && this.isRequired(value)) {
            return this.formConfiguration.isValid(value);
        }

        if (this.required) {
            if (!value) {
                return false;
            }
            if (isNotEmptyNorFalsy(this.allowedValues) && !this.allowedValues.includes(value)) {
                return false;
            }
        }
        try {
            if (this.regex && (!value || !value.match(`^(${this.regex})$`))) {
                return false;
            }
        } catch (error) {
            return false;
        }
        return true;
    }

    isEnabled(...args) {
        if (this.disabled) return false;
        return this.validateOnCondition('enableOn', ...args);
    }

    isVisible(...args) {
        return this.validateOnCondition('visibleOn', ...args);
    }

    validateOnCondition(conditionOn, object, fieldConfigurations, parentSkipStatePropagation) {
        // Propagate parent group validation
        if (this.group) {
            if (!getFieldConfiguration(this.group, fieldConfigurations).validateOnCondition(conditionOn, object, fieldConfigurations)) {
                return false;
            }
        }

        // Validate @OnField entry then validate entry itself
        const validateOnFieldEntry = (entry, skipRequiredValuesCheck) => {
            const fieldConfiguration = getFieldConfiguration(entry[0], fieldConfigurations);

            if (skipRequiredValuesCheck || fieldConfiguration.validateFieldAgainstRequiredValues(object[entry[0]], entry[1])) {
                return fieldConfiguration.validateOnCondition(conditionOn, object, fieldConfigurations, entry[1].skipStatePropagation);
            }
            return false;
        }

        const validateHierarchy = _conditionOn => {
            const conditionalFieldToRequiredValues = getEntries(this[_conditionOn]);
            const skipRequiredValuesCheck = _conditionOn !== conditionOn;
            switch (this[`${_conditionOn}Operator`]) {
                case 'OR':
                    return conditionalFieldToRequiredValues.some(entry => validateOnFieldEntry(entry, skipRequiredValuesCheck));
                case 'AND':
                default:
                    return conditionalFieldToRequiredValues.every(entry => validateOnFieldEntry(entry, skipRequiredValuesCheck));
            }
        }

        if (!parentSkipStatePropagation) {
            if (!validateHierarchy('enableOn')) {
                return false;
            }
            if (!validateHierarchy('visibleOn')) {
                return false;
            }
        }

        return true;
    }

    validateFieldAgainstRequiredValues(fieldValue, {comparisonOperator, values}) {
        // Check if fieldValue matches requiredValues according to comparisonOperator
        const arrayFunc = comparisonOperator === ComparisonOperator.NOT_EQUAL ? 'every' : 'some';
        return values[arrayFunc](requiredValue => {
            // Parse fieldValue and requiredValue based on fieldType
            let parsedValue = fieldValue;
            let parsedRequiredValue = requiredValue;
            switch (this.componentType) {
                case FormBuilderComponentConfiguration.ComponentType.RADIO_BUTTON:
                    if (this.type !== FormBuilderComponentConfiguration.Type.BOOLEAN) {
                        break;
                    }
                case FormBuilderComponentConfiguration.ComponentType.CHECKBOX:
                    parsedValue = stringToBool(fieldValue);
                    parsedRequiredValue = stringToBool(requiredValue);
                    break;
                case FormBuilderComponentConfiguration.ComponentType.NUMBER:
                case FormBuilderComponentConfiguration.ComponentType.DATE:
                case FormBuilderComponentConfiguration.ComponentType.TIME:
                case FormBuilderComponentConfiguration.ComponentType.DATE_TIME:
                    parsedValue = Number(fieldValue);
                    parsedRequiredValue = Number(requiredValue);
                    break;
            }
            return compareValueAgainstRequiredValue(parsedValue, parsedRequiredValue, comparisonOperator);
        });
    }
}

function getFieldConfiguration(fieldName, fieldConfigurations) {
    const config = fieldConfigurations.get(fieldName);
    if (config == null) {
        console.log(fieldConfigurations);
        document.body.innerHTML = ReactDOMServer.renderToStaticMarkup(
            <div>
                <label>{`FieldConfiguration not found for fieldName: ${fieldName}. Possible misnaming in Operation definition.`}</label>
            </div>
        );
        throw new Error(`FieldConfiguration not found for fieldName: ${fieldName}. Possible misnaming in Operation definition.`);
    }
    return config;
}

function compareValueAgainstRequiredValue(value, requiredValue, comparisonOperator) {
    switch (comparisonOperator) {
        case ComparisonOperator.GREATER_THAN:
            return value > requiredValue;
        case ComparisonOperator.GREATER_THAN_OR_EQUAL:
            return value >= requiredValue;
        case ComparisonOperator.LESS_THAN:
            return value < requiredValue;
        case ComparisonOperator.LESS_THAN_OR_EQUAL:
            return value <= requiredValue;
        case ComparisonOperator.NOT_EQUAL:
            return value !== requiredValue;
        default:
            return value === requiredValue;
    }
}

const ComparisonOperator = {
    EQUAL: 'EQUAL',
    NOT_EQUAL: 'NOT_EQUAL',
    GREATER_THAN: 'GREATER_THAN',
    GREATER_THAN_OR_EQUAL: 'GREATER_THAN_OR_EQUAL',
    LESS_THAN: 'LESS_THAN',
    LESS_THAN_OR_EQUAL: 'LESS_THAN_OR_EQUAL'
};

export default FormBuilderComponentConfiguration;
