import React, {Component} from 'react';
import {connect} from 'react-redux';
import {useTranslation, withTranslation} from "react-i18next";
import Tablet, {EditTabletHeader} from '../common/Tablet/Tablet';
import {getModifyPrivilegedAction, onInputChange,} from '../../utilities/componentFunctions';
import ExpandableContent, {
    ExpandableErrorLog,
    ExpandableWarningAlertTable
} from '../common/ExpandableContent/ExpandableContent';
import {DefaultEditPanel} from '../common/EditPanel/EditPanel';
import {SettingsRowValue} from '../common/CustomTable/CustomTable';
import {
    ExecutionProfileDropdown,
    NuixLicenceSourceDropdown,
    PriorityDropdown,
    SupportedExecutionModeDropdown
} from "../common/Dropdown/Dropdown";
import {onDropdownItemClick, onMenuOptionClick} from "../common/Dropdown/helpers";
import {compareNumStrings, removeUnderlineCapitalizeAll} from "../../utilities/helperFunctions";
import {shouldEnableEngine} from "../../utilities/shouldEnableFunctions";
import {executionModeKeys, permissionKeys, statusKeys as statusKey} from "../../i18next/keys";
import {executionModeIcon, getPriorityIcon} from "../../utilities/iconResolver";
import {updateMode} from "../../utilities/constants";
import PopupModel from "../../models/scheduler/PopupModel";
import EngineModel from "../../models/settings/EngineModel";
import EditModel from "../../models/scheduler/EditModel";
import {makeGetEditDetails} from "../../reselect/selectors";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";


class EngineTablet extends Component {
    constructor(props) {
        super(props);

        this.editEngine = this.editEngine.bind(this);
        this.deleteEngine = this.deleteEngine.bind(this);

        this.getModifyPrivilegedAction = getModifyPrivilegedAction('engine', this.props.t('engine:label.name')).bind(this);
        this.onMenuOptionClick = onMenuOptionClick.bind(this);

        this.onEditInputChange = onInputChange({
            mode: updateMode.EDIT,
            shouldEnable: shouldEnableEngine
        }).bind(this);
        this.onEditDropdownItemClick = onDropdownItemClick({
            mode: updateMode.EDIT,
            shouldEnable: shouldEnableEngine
        }).bind(this);
    }

    editEngine() {
        this.props.editEngine(this.props.engineId);
    }

    deleteEngine() {
        this.props.promptDeleteEngine(this.props.engineId);
    }

    render() {

        const {
            t,
            engineId,
            engineName,
            serverName,
            nuixLicenceSourceId,
            nuixLicenceSourceName,
            executionProfileId,
            executionProfileName,
            status,
            nuixLicenceType,
            priority,
            nuixVersion,
            javaVersion,
            currentExecutionMode,
            supportedExecutionMode,
            targetNuixWorkers,
            minNuixWorkers,
            acquiredWorkers,
            error,
            userPermissions,
            isEditActive,
            isSaveEnabled,
            logFile,
            runningJobName,
            isDisabled
        } = this.props;

        const menuOptions = [];
        menuOptions.push({name: t('common:option.edit'), value: 'editEngine', isDisabled: false});
        menuOptions.push({name: t('common:option.delete'), value: 'deleteEngine', isDisabled: false});

        const targetNuixWorkersInputProps = {
            type: 'number',
            style: {width: '5rem'},
            name: 'targetNuixWorkers',
            value: targetNuixWorkers,
            onChange: this.getModifyPrivilegedAction(this.onEditInputChange),
            isRequired: true,
            isInvalid: targetNuixWorkers <= 0,
            isDisabled
        };
        const minNuixWorkersInputProps = {
            type: 'number',
            style: {width: '5rem'},
            name: 'minNuixWorkers',
            value: minNuixWorkers,
            onChange: this.getModifyPrivilegedAction(this.onEditInputChange),
            isRequired: true,
            isInvalid: compareNumStrings(targetNuixWorkers, minNuixWorkers) < 0 || minNuixWorkers <= 0,
            isDisabled
        };
        const nuixLicenceSourceDropdownProps = {
            selectedNuixLicenceSourceId: nuixLicenceSourceId,
            onNuixLicenceSourceSelect: this.getModifyPrivilegedAction(this.onEditDropdownItemClick),
            isEdit: isEditActive,
            isRequired: true,
            isDisabled
        };
        const executionProfileDropdownProps = {
            selectedExecutionProfileId: executionProfileId,
            onExecutionProfileSelect: this.getModifyPrivilegedAction(this.onEditDropdownItemClick),
            isEdit: isEditActive,
            isRequired: false,
            isDisabled
        }
        const priorityDropdownProps = {
            selectedPriority: priority,
            onPrioritySelect: this.getModifyPrivilegedAction(this.onEditDropdownItemClick),
            isEdit: isEditActive,
            isDisabled
        };
        const supportedExecutionModeDropdownProps = {
            selectedSupportedExecutionMode: supportedExecutionMode,
            onSupportedExecutionModeSelect: this.getModifyPrivilegedAction(this.onEditDropdownItemClick),
            isEdit: isEditActive,
            isDisabled
        };

        const settingsProps = {
            status,
            serverName,
            acquiredWorkers,
            targetNuixWorkers,
            minNuixWorkers,
            nuixLicenceSource: nuixLicenceSourceName,
            executionProfile: executionProfileName,
            nuixLicenceType,
            nuixVersion,
            javaVersion,
            currentExecutionMode,
            supportedExecutionMode,
            targetNuixWorkersInputProps,
            minNuixWorkersInputProps,
            nuixLicenceSourceDropdownProps,
            executionProfileDropdownProps,
            priorityDropdownProps,
            supportedExecutionModeDropdownProps,
            priority,
            isEditActive,
            isDisabled
        };

        const canModifyEngine = userPermissions.includes(permissionKeys.MODIFY);
        const showJobInfo = !isEditActive && (runningJobName || logFile);

        return (
            <Tablet height={isEditActive ? 'auto' : '50vh'} onClose={this.props.close} isDisabled={isDisabled}
                closeButtonAriaLabel={t('engine:option.closeTablet_name', {name: engineName})}
                header={
                    <EditTabletHeader label={t('engine:label.name')} type={'engine'} id={engineId} name={'engineName'}
                        value={engineName} inputHandler={this.onEditInputChange}
                        menuOptions={menuOptions} menuOptionHandler={this.onMenuOptionClick} canModify={canModifyEngine}
                        isEditActive={isEditActive} isDisabled={isDisabled}
                    />
                }
                body={
                    <>

                        {error && status === statusKey.ERROR && !isEditActive &&
                            <div className="display-item">
                                <ExpandableErrorLog label={t('common:label.errors')} log={error}/>
                            </div>
                        }

                        {error && status === statusKey.WARNING && !isEditActive &&
                            <div className="display-item">
                                <ExpandableWarningAlertTable label={t('common:label.warnings')} warnings={error}/>
                            </div>
                        }

                        <div className="display-item">
                            <DefaultEditPanel isActive={isEditActive} isSaveEnabled={isSaveEnabled}
                                onSave={this.props.saveEdit} onCancel={this.props.cancelEdit}>

                                <ExpandableContent label={t('common:label.settings')} isDisabled={isDisabled}>
                                    <EngineSettings {...settingsProps} />
                                </ExpandableContent>

                                {showJobInfo &&
                                    <ExpandableContent label={t('engine:label.jobInfo')} isDisabled={isDisabled}>
                                        <EngineJobInfo logFile={logFile} runningJobName={runningJobName}
                                            isDisabled={isDisabled}/>
                                    </ExpandableContent>
                                }

                            </DefaultEditPanel>
                        </div>
                    </>
                }
            />
        );
    }
}


const EngineSettings = props => {
    const {
        status,
        serverName,
        acquiredWorkers,
        nuixLicenceSource,
        executionProfile,
        nuixLicenceType,
        nuixVersion,
        javaVersion,
        currentExecutionMode,
        supportedExecutionMode,
        supportedExecutionModeDropdownProps,
        nuixLicenceSourceDropdownProps,
        executionProfileDropdownProps,
        priorityDropdownProps,
        priority
    } = props;
    const {
        targetNuixWorkers,
        targetNuixWorkersInputProps,
        minNuixWorkers,
        minNuixWorkersInputProps,
        isEditActive,
        isDisabled
    } = props;

    const {t} = useTranslation(['engine', 'common']);
    const iconPriority = getPriorityIcon(priority);
    const iconExecutionMode = executionModeIcon(supportedExecutionMode);

    const disabled = isDisabled ? ' is-disabled' : '';
    return (
        <div className={'settings-table' + disabled}>
            <div className="table-row-group">
                {!isEditActive && SettingsRowValue({
                    label: t('engine:label.status'),
                    value: t(`common:status.${status}`)
                })}
                {SettingsRowValue({label: t('engine:label.server'), value: serverName})}


                {SettingsRowValue({
                    label: t('engine:label.supportedExecutionMode'),
                    value: isEditActive ?
                        <SupportedExecutionModeDropdown {...supportedExecutionModeDropdownProps}
                            isRequired={!supportedExecutionModeDropdownProps.selectedSupportedExecutionMode}/>
                        :
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {iconExecutionMode &&
                                <span className="icon is-small" style={{marginRight: '0.25rem'}}>
	                                <img src={iconExecutionMode} alt={t(`aria:hiddenAssistText.${supportedExecutionMode}Icon`)}/>
	                            </span>
                            }
                            <label className="label">
                                {t(`common:executionMode.${supportedExecutionMode}`)}
                            </label>
                        </div>
                })}


                {!isEditActive && currentExecutionMode && SettingsRowValue({
                    label: t('engine:label.currentExecutionMode'),
                    value: removeUnderlineCapitalizeAll(currentExecutionMode)
                })}

                {supportedExecutionMode === executionModeKeys.AUTOMATE_NUIX && SettingsRowValue({
                    label: t('engine:label.nuixLicenceSource'),
                    value: isEditActive ?
                        <NuixLicenceSourceDropdown {...nuixLicenceSourceDropdownProps} />
                        :
                        nuixLicenceSource
                })}

                {SettingsRowValue({
                    label: t('engine:label.initializationExecutionProfile'),
                    value: isEditActive ?
                        <ExecutionProfileDropdown {...executionProfileDropdownProps} />
                        :
                        executionProfile
                })}

                {SettingsRowValue({
                    label: t('engine:label.priority'),
                    value: isEditActive ?
                        <PriorityDropdown {...priorityDropdownProps} />
                        :
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {iconPriority &&
                                <span className="icon is-small" style={{marginRight: '0.25rem'}}>
	                                <img src={iconPriority} alt={t(`aria:hiddenAssistText.${priority}PriorityIcon`)}/>
	                            </span>
                            }
                            <label className="label">
                                {t(`common:priority.${priority}`)}
                            </label>
                        </div>
                })}

                {supportedExecutionMode === executionModeKeys.AUTOMATE_NUIX && SettingsRowValue({
                    label: t('engine:label.targetWorkers'),
                    htmlFor: 'engineTargetNuixWorkers',
                    value: isEditActive ?
                        <HTMLTextInput id={'engineTargetNuixWorkers'} {...targetNuixWorkersInputProps}/>
                        :
                        targetNuixWorkers
                })}
                {supportedExecutionMode === executionModeKeys.AUTOMATE_NUIX && SettingsRowValue({
                    label: t('engine:label.minWorkers'),
                    htmlFor: 'engineMinNuixWorkers',
                    value: isEditActive ?
                        <HTMLTextInput id={'engineMinNuixWorkers'} {...minNuixWorkersInputProps}/>
                        :
                        minNuixWorkers
                })}
                {!isEditActive &&
                    <>
                        {supportedExecutionMode === executionModeKeys.AUTOMATE_NUIX && SettingsRowValue({
                            label: t('engine:label.acquiredWorkers'),
                            value: acquiredWorkers
                        })}
                        {supportedExecutionMode === executionModeKeys.AUTOMATE_NUIX && SettingsRowValue({
                            label: t('engine:label.nuixLicenceType'),
                            value: nuixLicenceType
                        })}
                        {supportedExecutionMode === executionModeKeys.AUTOMATE_NUIX && SettingsRowValue({
                            label: t('engine:label.nuixVersion'),
                            value: nuixVersion
                        })}
                        {SettingsRowValue({label: t('engine:label.javaVersion'), value: javaVersion})}
                    </>
                }
            </div>
        </div>
    );
}


const EngineJobInfo = props => {
    const {logFile, runningJobName, isDisabled} = props;
    const {t} = useTranslation(['engine', 'common']);

    const disabled = isDisabled ? ' is-disabled' : '';
    return (
        <div className={'settings-table' + disabled}>
            <div className="table-row-group">
                {SettingsRowValue({label: t('engine:label.runningJobName'), value: runningJobName})}
                {SettingsRowValue({label: t('engine:label.logFile'), value: logFile})}
            </div>
        </div>
    );
}

const makeMapStateToProps = () => {
    const getEditDetails = makeGetEditDetails();
    return state => {
        const {
            engineDetailsMap,
            serverDetailsMap,
            nuixLicenceSourceDetailsMap,
            executionProfileDetailsMap,
            jobDetailsMap,
            componentStates: {engineDisplay: {engineId}, engineTablet}
        } = state;
        const {
            name: engineName,
            serverId,
            nuixLicenceSourceId,
            executionProfileId,
            runningJobId,
            ...otherDetails
        } = engineDetailsMap.get(engineId);

        const serverDetails = serverDetailsMap.get(serverId);
        const serverName = serverDetails ? serverDetails.name : serverId;

        const jobDetails = jobDetailsMap.get(runningJobId);
        const runningJobName = jobDetails ? jobDetails.name : runningJobId;

        const nuixLicenceSourceDetails = nuixLicenceSourceDetailsMap.get(nuixLicenceSourceId);
        const nuixLicenceSourceName = nuixLicenceSourceDetails ? nuixLicenceSourceDetails.name : nuixLicenceSourceId;

        const executionProfileDetails = executionProfileDetailsMap.get(executionProfileId);
        const executionProfileName = executionProfileDetails ? executionProfileDetails.name : executionProfileId;

        const {activeModel, values, isSaveEnabled} = getEditDetails(state, {model: EngineModel.nom});
        const isEditActive = (activeModel === EngineModel.nom);

        return {
            engineId,
            engineName,
            serverName,
            nuixLicenceSourceName,
            nuixLicenceSourceId,
            executionProfileName,
            executionProfileId,
            runningJobName,
            ...otherDetails,
            ...values,
            isEditActive,
            isSaveEnabled,
            ...engineTablet
        };
    }
}

const mapDispatchToProps = dispatch => {
    return {
        editEngine: engineId => dispatch(EngineModel.actionCreators.startEdit(engineId)),
        promptDeleteEngine: engineId => dispatch(EngineModel.actionCreators.promptDelete(engineId)),

        ...EditModel.buildDispatchers(dispatch),

        showError: payload => dispatch(PopupModel.actionCreators.showError(payload)),
        close: () => dispatch(EngineModel.actionCreators.hideTablet())
    };
}

export default connect(makeMapStateToProps, mapDispatchToProps)(withTranslation(['engine', 'common'])(EngineTablet));