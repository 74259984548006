import React from "react";
import {useSelector} from "react-redux";
import {StatusNameDescriptionCell, TableEnableToggleCell, TableRow} from "../common/CustomTable/CustomTable";
import {useTranslation} from "react-i18next";
import {permissionKeys} from "../../i18next/keys";
import LimitedTableRow from "../limited/LimitedTableRow";

function NoticeTemplateRow(props) {
    const {t} = useTranslation(['common']);

    const {
        isActive,
        noticeTemplateId,
        canModify,
        onSelect,
        toggleEnable
    } = props;

    const {
        name,
        description,
        enabled,
        userPermissions
    } = useSelector(state => state.noticeTemplateDetailsMap.get(noticeTemplateId));

    const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);
    if (isViewLimited && !canModify) {
        return (
            <LimitedTableRow id={noticeTemplateId} isActive={isActive} onClick={onSelect} cellCount={2}
                             toggleComponent={<TableEnableToggleCell ariaLabelKey={'NoticeTemplate'} displayName={name} data-value={noticeTemplateId}
                                                               checked={enabled} onClick={toggleEnable} isReadOnly={!canModify}/>}
                             nameComponent={<StatusNameDescriptionCell name={name} description={description}/>}
            />
        )
    }

    return (
        <TableRow value={noticeTemplateId} isActive={isActive} onClick={onSelect}>
            <TableEnableToggleCell ariaLabelKey={'NoticeTemplate'} displayName={name} data-value={noticeTemplateId}
                checked={enabled} onClick={toggleEnable} isReadOnly={!canModify}/>

            <StatusNameDescriptionCell name={name} description={description}/>
        </TableRow>
    )
}

export default NoticeTemplateRow;
