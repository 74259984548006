import React, {useEffect, useState} from 'react';
import './LoadingWrapper.css';
import {useTranslation} from "react-i18next";
import Text from "../Text/Text";

const SHOW_AFTER = 250;

export default props => {
    const {t} = useTranslation(['common']);
    const {isLoading, isDisabled, children, ignoreRelativeWrapper, timeout=SHOW_AFTER, ...attr} = props;

    const [showLoading, setShowLoading] = useState(false);
    // Show LOADING... after timeout
    useEffect(() => {
        if (isLoading) {
            const showLoadingTimer = setTimeout(() => {
                setShowLoading(true);
            }, timeout);

            return () => clearTimeout(showLoadingTimer);
        }
    }, [isLoading, timeout]);

    if (!isLoading) {
        return children ? children : null;
    }

    if (showLoading) {
        const loadingWrapper = (
            <div className="absolutely-centered" {...attr}>
                {!isDisabled &&
                    <Text isItalic>
                        {t('common:status.LOADING')}...
                    </Text>
                }
            </div>
        );

        if (ignoreRelativeWrapper) {
            return loadingWrapper;
        }
        return (
            <div style={{position: 'relative'}}>
                {loadingWrapper}
            </div>
        )
    }
    return null;
}
