import axios from "axios";

class AxiosModel {
    constructor(props) {
        this.baseUrl = props.baseURL;
        this.httpClient = axios.create({
            ...props,
            headers: {
                "Content-Type" : "application/json",
                ...props.headers
            }
        });

        this.get = this.httpClient.get;
        this.post = this.httpClient.post;
        this.put = this.httpClient.put;
        this.del = this.httpClient.delete;
    }

    setAcceptLanguageHeader(language) {
        this.httpClient.defaults.headers['Accept-Language'] = language;
    }

    setAxiosToken(token) {
        if (token) {
            this.httpClient.defaults.headers.Authorization = 'Bearer ' + token;
        } else {
            this.clearAxiosToken();
        }
    }

    clearAxiosToken() {
        delete this.httpClient.defaults.headers.Authorization;
    }
}

export default AxiosModel;