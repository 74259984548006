import {useCallback, useEffect, useLayoutEffect, useReducer, useRef, useState} from 'react';
import {deepCopy, getScrollElement, getValues, objectTruthyValues, objEquals} from "./helperFunctions";
import CustomIntersectionObserver from "./CustomIntersectionObserver";


export function useForceRenderer() {
    return useReducer(x => x + 1, 0)[1];
}

export function useAsyncEffect(asyncFunc, deps) {
    useEffect(() => {
        const res = asyncFunc();
        // Should never be the case for async functions
        // Only here to handle wrong uses
        if (typeof res === 'function') {
            return res;
        }
    }, deps);
}

export function useUpdateEffect(fn, deps) {
    const didMount = useRef();

    useEffect(() => {
        if (didMount.current) {
            return fn();
        }
        didMount.current = true;
    }, deps);
}

export function useUpdateLayoutEffect(fn, deps) {
    const didMount = useRef();

    useLayoutEffect(() => {
        if (didMount.current) {
            return fn();
        }
        didMount.current = true;
    }, deps);
}

export function useObjectState(initialState) {
    const [state, setState] = useState(initialState);

    const setObjectState = useCallback(update => {
        setState(prevState => ({
            ...prevState,
            ...((typeof update === 'function') ? update(prevState) : update)
        }));
    });

    return [state, setObjectState];
}


export function useDebounceCallback(callback, debouncePeriod) {
    const timeout = useRef();
    const wasEventDebounced = useRef(false);

    useEffect(() => {
        function clear() {
            clearTimeout(timeout.current);

            timeout.current = null;
            wasEventDebounced.current = false;
        }

        clear();
        return clear;
    }, [callback]);

    return useCallback(() => {

        function timeoutCallback() {
            if (wasEventDebounced.current) {
                callback();

                wasEventDebounced.current = false;
                timeout.current = setTimeout(timeoutCallback, debouncePeriod);
            } else {
                timeout.current = null;
            }
        }

        if (timeout.current != null) {
            wasEventDebounced.current = true;

        } else {
            callback();
            timeout.current = setTimeout(timeoutCallback, debouncePeriod);
        }
    }, [callback, debouncePeriod]);
}

export function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

export function useObjEqualsMemoize(obj) {
    const ref = useRef();

    if (!objEquals(obj, ref.current)) {
        ref.current = obj;
    }
    // Will either return the old obj or the new obj
    return ref.current;
}

export function useAutoSelectId(id, ids, updateId, options={}) {
    const {
        autoSelectIfNull=true
    } = options;

    const prevIds = useRef(ids);
    // Set id if null or no longer included
    useEffect(() => {
        if (id == null) {
            if (autoSelectIfNull && ids[0] != null) {
                updateId(ids[0]);
            }

        } else if (!ids.includes(id)) {
            let index = prevIds.current.indexOf(id);
            // If index is out of bounds
            if (index !== 0 && index >= ids.length) {
                index = ids.length - 1;
            }

            if (ids[index]) {
                updateId(ids[index]);
            }
        }
    }, [id, ids, autoSelectIfNull]);

    // Update ids
    useEffect(() => {
        prevIds.current = ids;
    }, [ids]);
}

export function getRenderedHeightOffset(containerRef, listContainerSelector, initialHeightOffset) {
    if (containerRef.current != null) {
        if (containerRef.current.matches(listContainerSelector)) {
            return 0;
        }
        const listContainer = containerRef.current.querySelector(listContainerSelector);
        if (listContainer != null) {
            return listContainer.getBoundingClientRect().top - containerRef.current.getBoundingClientRect().top;
        }
    }
    return initialHeightOffset;
}

export function useRenderedItemHeight(containerRef, listContainerSelector, initialHeight) {
    const itemHeight = useRef(initialHeight);
    useEffect(() => {
        if (containerRef.current != null) {
            const item = containerRef.current.querySelector(listContainerSelector + ' > *:last-child');
            if (item != null) {
                itemHeight.current = item.getBoundingClientRect().height;
            }
        }
    }, [containerRef.current, listContainerSelector]);
    return itemHeight;
}

export const virtualRenderingTriggerBroadcastChannel = 'VIRTUAL_RENDERING_TRIGGER_BROADCAST_CHANNEL';
export function getScrollElementSignature(scrollElement) {
    if (scrollElement != null) {
        const signatureValues = [scrollElement.className, scrollElement.childElementCount];
        return JSON.stringify(signatureValues);
    }
}

export function usePreciseVirtualRendering(options) {
    const {containerRef, listRef, size, heightOffset=0, windowSize=6} = options;

    const isLoading = useRef(true);
    const itemHeights = useRef({});
    const startHeight = useRef();

    const scrollContainerRef = useRef();
    const position = useRef({});
    const forceRender = useForceRenderer();


    const items = listRef.current?.children;
    useEffect(() => {
        if (isLoading.current && items?.length === size && containerRef.current != null) {
            itemHeights.current = {};
            for (const item of listRef.current.children) {
                const index = item.dataset.index;
                itemHeights.current[index] = item.getBoundingClientRect().height;
            }

            // Incorporate borders
            const {borderTopWidth, borderBottomWidth} = window.getComputedStyle(containerRef.current);
            const borderHeight = Number(borderTopWidth.replace('px', '')) + Number(borderBottomWidth.replace('px', ''));

            const containerHeight = getValues(itemHeights.current).reduce((sum, curr) => sum + curr, 0);
            containerRef.current.style.height = `${containerHeight + heightOffset + borderHeight}px`;
            isLoading.current = false;
            forceRender();
        }
    }, [items?.length === size]);

    useEffect(() => {
        if (!isLoading.current) {
            isLoading.current = true;
            forceRender();
        }
    }, [size]);

    const positionHandler = useCallback(() => {
        if (containerRef.current == null || scrollContainerRef.current == null)
            return;

        if (isLoading.current) {
            position.current = {start: 0, end: size};
            forceRender();

        } else {
            // Calculate number of items to render
            const {top: parentTop, bottom: parentBottom} = scrollContainerRef.current.getBoundingClientRect();
            const {top} = containerRef.current.getBoundingClientRect();


            // At what index to start from
            const topDiff = ((top + heightOffset) - parentTop);
            // topDiff > 0 means first item has not been scrolled out yet
            // topDiff < 0 means first item was scrolled out
            // heightOffset is difference between containerRef.current height and when the start of first item
            let topOffset = topDiff > 0 ? 0 : Math.abs(topDiff);
            let itemHeight;
            let start = 0;

            // Get starting index based on topOffset (how far scrollContainer has been scrolled)
            while (topOffset > 0 && (itemHeight = itemHeights.current[start]) != null) {
                if ((topOffset -= itemHeight) > 0) {
                    start++;
                    startHeight.current += itemHeight;
                }
            }
            start = Math.max(start - windowSize, 0);

            // How much of container is in scrollContainer
            const listTop = Math.max(top + heightOffset, parentTop);
            let listHeight = parentBottom - listTop;
            let end = start + 1;

            // Increment end index until entire list is covered
            while (listHeight > 0 && (itemHeight = itemHeights.current[end]) != null) {
                if ((listHeight -= itemHeight) > 0) {
                    end++;
                }
            }
            end = Math.min(end + windowSize, size);

            if (start !== position.current.start || end !== position.current.end) {
                position.current = {start, end};
                forceRender();
            }
        }
    }, [size, windowSize, heightOffset]);

    // Force updating position
    useEffect(() => {
        if (options.forceRender != null) {
            positionHandler();
        }
    }, [options.forceRender]);

    // Attach scrollHandler to scrollContainer
    useEffect(() => {
        if (containerRef.current != null) {
            scrollContainerRef.current = getScrollElement(containerRef.current);
            scrollContainerRef.current.addEventListener('scroll', positionHandler);

            const containerResizeObserver = new ResizeObserver(() => setTimeout(positionHandler, 1));
            containerResizeObserver.observe(containerRef.current);

            positionHandler();
            return () => {
                scrollContainerRef.current.removeEventListener('scroll', positionHandler);
                containerResizeObserver.disconnect();
            }
        }
    }, [containerRef.current, positionHandler]);

    // Listen to broadcastChannel to trigger virtualRender positionHandler
    const broadcastChannel = useRef();
    useEffect(() => {
        broadcastChannel.current = new BroadcastChannel(virtualRenderingTriggerBroadcastChannel);
        return () => broadcastChannel.current.close();
    }, []);

    useEffect(() => {
        broadcastChannel.current.onmessage = event => {
            const scrollElementSignature = getScrollElementSignature(scrollContainerRef.current);
            if (event.data === scrollElementSignature) {
                isLoading.current = true;
                forceRender();
            }
        }
    }, [broadcastChannel.current]);

    return useCallback(renderRow => {
        const renderedRows = [];
        let currentHeight = 0;
        for (let i = 0; i < position.current.end; i++) {
            if (i < position.current.start) {
                if (itemHeights.current[i] != null) {
                    currentHeight += itemHeights.current[i];
                }
            } else {
                renderedRows.push(renderRow(i, currentHeight));
            }
        }
        return renderedRows;
    }, []);
}

export function useSimpleVirtualRendering(options) {
    const {containerRef, itemHeightRef, size, heightOffset=0, windowSize=6} = options;
    const itemHeight = itemHeightRef.current;

    const scrollContainerRef = useRef();
    const position = useRef({});
    const [, forceRender] = useState(true);

    const positionHandler = useCallback(() => {
        if (containerRef.current == null || scrollContainerRef.current == null)
            return;

        // Calculate number of items to render
        const {top: parentTop, bottom: parentBottom} = scrollContainerRef.current.getBoundingClientRect();
        const {top} = containerRef.current.getBoundingClientRect();


        // At what index to start from
        const topDiff = ((top + heightOffset) - parentTop);
        // topDiff > 0 means first item has not been scrolled out yet
        // topDiff < 0 means first item was scrolled out
        // heightOffset is difference between containerRef.current height and when the start of first item
        const topOffset = topDiff > 0 ? 0 : Math.abs(topDiff);
        const start = topOffset === 0 ? 0 : Math.max(Math.floor(topOffset / itemHeight) - windowSize, 0);

        // How much of container is in scrollContainer
        const listTop = Math.max(top + heightOffset, parentTop);

        const listHeight = parentBottom - listTop;
        const numberOfItems = Math.ceil(listHeight / itemHeight);

        // end = 5 for 5 items
        const end = Math.min(start + numberOfItems + (windowSize * 2), size);

        if (start !== position.current.start || end !== position.current.end) {
            position.current = {start, end};
            forceRender(v => !v);
        }
    }, [itemHeight, size, windowSize, heightOffset]);

    // Update internally tracked itemHeight
    const updateContainerHeight = useCallback(() => {
        if (containerRef.current != null) {
            // Incorporate borders
            const {borderTopWidth, borderBottomWidth} = window.getComputedStyle(containerRef.current);
            const borderHeight = Number(borderTopWidth.replace('px', '')) + Number(borderBottomWidth.replace('px', ''));
            containerRef.current.style.height = `${itemHeight * size + heightOffset + borderHeight}px`;
        }
    }, [itemHeight, size, heightOffset]);

    useEffect(updateContainerHeight, [updateContainerHeight]);

    // Force updating position
    useEffect(() => {
        if (options.forceRender != null) {
            positionHandler();
        }
    }, [options.forceRender]);

    // Attach scrollHandler to scrollContainer
    useEffect(() => {
        if (containerRef.current != null) {
            scrollContainerRef.current = getScrollElement(containerRef.current);
            scrollContainerRef.current.addEventListener('scroll', positionHandler);

            const containerResizeObserver = new ResizeObserver(() => setTimeout(positionHandler, 1));
            containerResizeObserver.observe(containerRef.current);

            positionHandler();
            return () => {
                scrollContainerRef.current.removeEventListener('scroll', positionHandler);
                containerResizeObserver.disconnect();
            }
        }
    }, [containerRef.current, positionHandler]);

    // Listen to broadcastChannel to trigger virtualRender positionHandler
    const broadcastChannel = useRef();
    useEffect(() => {
        broadcastChannel.current = new BroadcastChannel(virtualRenderingTriggerBroadcastChannel);
        return () => broadcastChannel.current.close();
    }, []);

    useEffect(() => {
        broadcastChannel.current.onmessage = event => {
            const scrollElementSignature = getScrollElementSignature(scrollContainerRef.current);
            if (event.data === scrollElementSignature) {
                updateContainerHeight();
                positionHandler();
            }
        }
    }, [broadcastChannel.current, positionHandler, updateContainerHeight]);

    return useCallback(renderRow => {
        const renderedRows = [];
        for (let i = position.current.start; i < position.current.end; i++) {
            renderedRows.push(renderRow(i, position.current.start * itemHeight));
        }

        return renderedRows;
    }, [position.current, itemHeight]);
}

export const initialSelectedState = {
    key: null,
    clickCount: 0,
    values: {},
    lastSelectedValue: null
};
export const initialSelectedMapState = {
    key: null,
    clickCount: 0,
    values: new Map(),
    lastSelectedValue: null
};

export function usePopupWindow(options) {
    const {
        windowName,
        features,
        onError,
        onSuccess,
        onMessage
    } = options;

    const ref = useRef({});
    // Close window and channel on unmount
    const onUnmount = useCallback(() => {
        if (ref.current.channel != null) {
            ref.current.channel.close();
        }
        if (ref.current.window != null) {
            ref.current.window.close();
        }
    }, []);

    // Connect to window channel
    useEffect(() => {
        ref.current.channel = new BroadcastChannel(windowName);
        return onUnmount;
    }, [windowName]);

    useEffect(() => {
        ref.current.channel.onmessage = event => {
            if (event.data.type === 'ERROR' && typeof onError === 'function') {
                onError(event.data);
            } else if (event.data.type === 'SUCCESS' && typeof onSuccess === 'function') {
                onSuccess(event.data);
            } else if (typeof onMessage === 'function') {
                onMessage(event.data);
            }
        }
    }, [onError, onSuccess, onMessage]);

    // Close window on page unload
    useEffect(() => {
        window.addEventListener('beforeunload', onUnmount);
        return () => window.removeEventListener('beforeunload', onUnmount);
    }, []);

    // Open or focus on window if open
    return useCallback((url='') => {
        if (ref.current.window != null && !ref.current.window.closed) {
            ref.current.window.blur();
            ref.current.window.focus();
        } else {
            let _features = 'popup=true, width=650, height=750, top=250, left=250';
            if (features != null) {
                _features += `, ${features}`;
            }
            ref.current.window = window.open(url, windowName, _features);
            if (ref.current.window != null) {
                ref.current.window.opener = null;
            }
        }
        return ref.current.window;
    }, [windowName]);
}

export function useScrollIntoView(options) {
    const {ref, trigger, onMount} = options;

    useLayoutEffect(() => {
        if (onMount || trigger) {
            const scrollElement = getScrollElement(ref.current);
            const topRequiredScrollDistance = ref.current.getBoundingClientRect().top - scrollElement.getBoundingClientRect().top;
            const bottomRequiredScrollDistance = ref.current.getBoundingClientRect().bottom - scrollElement.getBoundingClientRect().bottom;

            // If hidden, scrollIntoView + offset
            if (bottomRequiredScrollDistance > 0) {
                scrollElement.scrollTop += bottomRequiredScrollDistance + 16;
            } else if (topRequiredScrollDistance < 0) {
                scrollElement.scrollTop += topRequiredScrollDistance - 96;
            }
        }
    }, [trigger]);
}

// Wait for element to be visible for x seconds before dispatching callback
// Use IntersectionObserver to trigger callback when element intersects/passes bottom of scrollableContainer
export function useViewedCallback(ref, callback, options={}) {
    useEffect(() => {
        if (!options.isActive) {
            return;
        }

        const intersectionOptions = {
            root: getScrollElement(ref.current),
            ...options
        };

        const intersectionObserver = new CustomIntersectionObserver(() => {
            callback();
            intersectionObserver.disconnect();
        }, intersectionOptions);

        intersectionObserver.observe(ref.current);
        return () => intersectionObserver.disconnect();
    }, []);
}

export function useSelectAll(options) {
    const {containerRef, selected, array, setSelected} = options;

    const ref = useRef({selected, array})
    useEffect(() => {
        ref.current = {selected, array};
    }, [selected, array]);

    const onSelectAll = useCallback(() => {
        const values = {}
        for (let i = 0; i < ref.current.array.length; i++) {
            values[i] = true;
        }

        setSelected({
            ...ref.current.selected,
            values
        });
    }, []);

    const onKeyDown = useCallback(function (event) {
        if (event.ctrlKey && containerRef.current.contains(event.target)) {
            const key = event.key.toLowerCase();
            switch (key) {
                case 'a':
                    event.preventDefault();
                    onSelectAll();
                    break;
            }
        }
    }, [onSelectAll]);

    useEffect(() => {
        document.addEventListener('keydown', onKeyDown);
        return () => document.removeEventListener('keydown', onKeyDown);
    }, [onKeyDown]);
}

export function useCutCopyPasteAndDelete(options) {
    const {containerRef, selected, array, onPaste, onCut, onDelete} = options;

    const ref = useRef({selected, array});
    useEffect(() => {
        ref.current = {selected, array};
    }, [selected, array]);

    // Save selected indices in copyState
    const onCopy = useCallback(() => {
        const selectedValues = objectTruthyValues(ref.current.selected.values).sort((e1, e2) => e1 - e2);
        const selectedOperations = selectedValues.map(value => deepCopy(ref.current.array[value]));

        const jsonStr = JSON.stringify(selectedOperations, undefined, 2);
        navigator.clipboard.writeText(jsonStr).catch((ex) => {
            console.log("Cannot write to clipboard",ex)
        });
    }, []);

    const onKeyDown = useCallback(function (event) {
        if (containerRef.current != null && containerRef.current.contains(event.target)) {
            const key = event.key.toLowerCase();
            if (event.ctrlKey) {
                switch (key) {
                    case 'c':
                        onCopy();
                        break;
                    case 'v':
                        onPaste();
                        break;
                    case 'x':
                        onCut();
                        break;
                }
            } else {
                switch (key) {
                    case 'delete':
                        event.preventDefault();
                        onDelete();
                        break;
                }
            }
        }

    }, [onCopy, onPaste]);

    useEffect(() => {
        document.addEventListener('keydown', onKeyDown);
        return () => document.removeEventListener('keydown', onKeyDown);
    }, [onKeyDown]);
}

export function useMoveUpDown(options) {
    const {selected, setSelected, setArray, allowLooping} = options;

    const move = useCallback(up => {
        setArray(prevArray => {
            const selectedIndices = objectTruthyValues(selected.values).map(i => parseInt(i));
            // No-loop; if selectedIndices require looping, do nothing
            if (!allowLooping) {
                let requiresLooping;
                if (up) {
                    requiresLooping = selectedIndices.some(i => i === 0);
                } else {
                    requiresLooping = selectedIndices.some(i => i === (prevArray.length - 1));
                }
                if (requiresLooping) {
                    return prevArray;
                }
            }

            const array = [], selectedValues = {};
            let lastSelectedValue;

            for (const selectedIndex of selectedIndices) {
                let index;
                if (up) {
                    index = ((selectedIndex === 0) ? prevArray.length : selectedIndex) - 1;
                } else {
                    index = (selectedIndex === (prevArray.length - 1)) ? 0 : (selectedIndex + 1);
                }

                array[index] = prevArray[selectedIndex];
                selectedValues[index] = true;
                // Update lastSelectedValue
                if (selected.lastSelectedValue === selectedIndex) {
                    lastSelectedValue = index;
                }
            }

            for (let i = 0; i < prevArray.length; i++) {
                // selected values already added, skip
                if (selected.values[i]) {
                    continue;
                }

                let index = i;
                // find new index
                while (selectedValues[index] || array[index] != null) {
                    if (up && index === prevArray.length - 1) {
                        index = 0;
                    } else if (!up && index === 0) {
                        index = prevArray.length - 1;
                    } else {
                        up ? index++ : index--;
                    }
                }
                array[index] = prevArray[i];
            }

            setSelected(prevSelected => ({
                ...prevSelected,
                values: selectedValues,
                lastSelectedValue
            }));
            return array;
        });
    }, [selected, setSelected, setArray]);

    const moveUp = useCallback(() => move(true), [move]);
    const moveDown = useCallback(() => move(false), [move]);
    return [moveUp, moveDown];
}

export function useClearSelectedEffect(options) {
    const {containerRef, setSelected, dataStructure, hidePopup, ignorePopup=true} = options;

    const prevDataStructure = usePrevious(dataStructure);
    // Clear effect whenever order of dataStructure changes
    useEffect(() => {
        if (prevDataStructure === dataStructure)
            return;

        // Since we care about order of props, JSON serialization satisfies
        if (JSON.stringify(prevDataStructure) !== JSON.stringify(dataStructure)) {
            // Objects have changed / their prop orders have changed
            setSelected(initialSelectedState);
            typeof hidePopup === 'function' && hidePopup();
        }
    }, [prevDataStructure, dataStructure, setSelected]);

    const clearSelected = useCallback(event => {
        // If event target is outside component
        if (containerRef && containerRef.current != null && !containerRef.current.contains(event.target)) {

            const popup = document.getElementById('popup');
            if (ignorePopup || popup == null || !popup.contains(event.target)) {

                setSelected(prev => {
                    const reset = {
                        ...initialSelectedState,
                        key: prev.key
                    };
                    if (objEquals(prev, reset)) {
                        return prev;
                    }
                    return reset;
                });
            }
        }
    }, [containerRef, setSelected]);

    // Set eventListener to clear selectedState on outside click
    useEffect(() => {
        document.addEventListener('mousedown', clearSelected);

        return () => document.removeEventListener('mousedown', clearSelected);
    }, [clearSelected]);
}

export function useTabNavigateEffect(options) {
    const {containerRef} = options;

    const tabClick = useCallback(event => {
        const {keyCode, target} = event;

        if (keyCode === 9 && target.tagName !== 'BUTTON' &&
            containerRef.current != null && containerRef.current.contains(target)) {

            if (!target.className?.includes('toggle-container')
                && !target.className?.includes('checkbox-container') && target.type !== 'checkbox') {

                target.click();
            }
        }

    }, [containerRef]);

    // Set eventListener for pseudo onClick when user tabs to valid input
    useEffect(() => {
        document.addEventListener('keyup', tabClick);
        return () => document.removeEventListener('keyup', tabClick);
    }, [tabClick]);
}

const escapeKeyPressComponentStack = new Map();
let escapeKeyPressListenerAdded = false;
// Remove last active modal on 'Escape' press
function onEscapePress(e) {
    if ((e.key === 'Escape' || e.keyCode === 27) && escapeKeyPressComponentStack.size > 0) {
        const entries = [...escapeKeyPressComponentStack];

        let onClose, entry;
        while ((entry = entries.pop()) != null) {
            if (entry[1].isActive && typeof entry[1].onClose === 'function') {
                onClose = entry[1].onClose;
                break;
            }
        }
        if (onClose != null) {
            onClose();
        }
    }
}

export function useEscapeKeyPressClose(options) {
    // Hook event listener
    useEffect(() => {
        if (!escapeKeyPressListenerAdded) {
            window.addEventListener('keyup', onEscapePress);
            escapeKeyPressListenerAdded = true;
            return () => {
                window.removeEventListener('keyup', onEscapePress);
                escapeKeyPressListenerAdded = false;
            }
        }
    }, []);

    const {
        onClose,
        isActive
    } = options;

    const id = useRef(generateUUID4());
    useEffect(() => {
        escapeKeyPressComponentStack.set(id.current, {onClose, isActive});
        return () => {
            escapeKeyPressComponentStack.delete(id.current);
        }
    }, [id.current, isActive]);
}

export function useKeyPressEffect(options) {
    const {containerRef, keyToCb, targetClassName} = options;

    const keyup = useCallback(event => {
        const {key, keyCode, target, ctrlKey, shiftKey, altKey} = event;

        // If keyPress from container
        if (target.tagName !== 'BUTTON' && containerRef.current.contains(target)
            && (!targetClassName || target.className.includes(targetClassName))) {

            const responseCb = keyToCb[key] || keyToCb[keyCode];
            if (responseCb === 'click' || responseCb === 'dblclick') {
                const clickEvent = new MouseEvent(responseCb, {bubbles: true, cancelable: true, ctrlKey, shiftKey, altKey});
                target.dispatchEvent(clickEvent);

            } else if (typeof responseCb === 'function') {
                responseCb(event);
            }
        }
    }, [containerRef, keyToCb, targetClassName]);

    // Set eventListener
    useEffect(() => {
        document.addEventListener('keyup', keyup);
        return () => document.removeEventListener('keyup', keyup);
    }, [keyup]);
}

export function useKeySelectHandler(options) {
    const {setSelected, clickCount=1} = options;
    // Set index of selected key; requires clickCount to activate inputChange
    return useCallback(event => {
        // index turns into a string when stored in HTML
        const {dataset: {index}} = event.target;

        setSelected(prevSelected => {
            if (prevSelected.key === index) {

                if (prevSelected.clickCount === clickCount)
                    return prevSelected;

                return {
                    ...prevSelected,
                    clickCount: 1
                }
            }

            return {
                ...initialSelectedState,
                key: index
            }
        });
    }, [setSelected, clickCount]);
}

export function useValueSelectHandler(options) {
    const {setSelected, key, useMap, filteredIndicesRef} = options;

    function newIndexSet() {
        return (useMap ? new Map() : {});
    }
    function setIndex(indexSet, index, value) {
        useMap ? indexSet.set(index, value) : (indexSet[index] = value);
    }
    function getIndex(indexSet, index) {
        return (useMap ? indexSet.get(index) : indexSet[index]);
    }
    function _shallowCopy(indexSet) {
        return (useMap ? new Map(indexSet) : {...indexSet});
    }

    return useCallback(event => {
        if ((event.target.dataset.key || event.currentTarget.dataset.key) !== key) {
            return;
        }

        const index = parseInt(event.target.dataset.index || event.currentTarget.dataset.index);
        const keyPressed = {
            ctrl: event.ctrlKey,
            shft: event.shiftKey
        }

        setSelected(prevSelected => {
            // Maintain previous selection if CTRL/SHFT key pressed
            let selectedIndexSet;
            if (keyPressed.ctrl || keyPressed.shft) {
                selectedIndexSet = _shallowCopy(prevSelected.values);
            } else {
                selectedIndexSet = newIndexSet();
            }

            // False IFF CTRL key pressed and was previously selected
            setIndex(selectedIndexSet, index, (!keyPressed.ctrl || !getIndex(selectedIndexSet, index)));

            // If range click with SHFT
            if (keyPressed.shft && prevSelected.lastSelectedValue != null) {
                // Get index start/end range
                let start, end;
                if (index > prevSelected.lastSelectedValue) {
                    start = prevSelected.lastSelectedValue;
                    end = index;
                } else {
                    start = index;
                    end = prevSelected.lastSelectedValue;
                }

                for (let i = start; i < end; i++) {
                    if (filteredIndicesRef == null || filteredIndicesRef.current.has(i)) {
                        setIndex(selectedIndexSet, i, true);
                    }
                }
            }

            const update = {
                ...prevSelected,
                values: selectedIndexSet,
                lastSelectedValue: index
            };
            if (objEquals(prevSelected, update)) {
                return prevSelected;
            }
            return update;
        });
    }, [setSelected, key]);
}
