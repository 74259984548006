import React, {useCallback, useEffect, useRef, useState} from "react";
import UserServiceModel, {UserServiceApi} from "../../models/user/UserServiceModel";
import {useTranslation} from "react-i18next";
import {ListDropdown} from "../common/Dropdown/Dropdown";
import {getNewArrayWithUpdatedValue} from "../../utilities/helperFunctions";
import {axiosInstance} from "../../models/api/AxiosProxy";


function UserServiceObjectDropdown(props) {
    const {t} = useTranslation(['guidedJob', 'aria']);
    const {
        userServiceId,
        objectType,
        ...attr
    } = props;

    const [items, searchTextCallback] = useUserServiceFilteredObjects({userServiceId, objectType});
    const disableSearch = items[items.length - 1]?.hasRemaining;

    return (
        <ListDropdown id={'userServiceObjectDropdown'} name={'value'} disableSearchFilter={disableSearch}
            noneSelectedMessage={t('guidedJob:option.selectValue')} isOutlined
            items={items} trackSearchText={searchTextCallback} {...attr}/>
    );
}

const emptyItems = [{name: '', value: ''}];

function useUserServiceFilteredObjects({userServiceId, objectType}) {
    const {t} = useTranslation(['common']);

    if (!Array.isArray(objectType)) {
        objectType = [objectType];
    }

    const [items, setItems] = useState([]);
    const filterText = useRef('');
    const activeQueries = useRef({queries: []});

    const cancelActiveQueries = useCallback(async () => {
        activeQueries.current.aborted = true;
        for (const query of activeQueries.current.queries) {
            axiosInstance.cancel(query.id);
        }
    }, []);

    const queryUserServiceObjects = useCallback(async (searchText, limit=1000, replaceLoadingAtEnd) => {
        await cancelActiveQueries();
        activeQueries.current.queries = [];
        activeQueries.current.aborted = false;

        const loadingTimeout = setTimeout(() => {
            setItems(prevItems => {
                const loadingItem = {
                    name: t('common:label.loading'),
                    onSelect: e => e.stopPropagation(),
                    exclude: true,
                    isItalic: true,
                    isCentered: true,
                    isLoading: true
                };
                if (replaceLoadingAtEnd) {
                    return getNewArrayWithUpdatedValue(prevItems, loadingItem, prevItems.length - 1);
                } else {
                    return [loadingItem];
                }
            });
        }, 100);

        let items = [];
        try {
            let remaining = 0;
            for (const type of objectType) {
                activeQueries.current.queries.push(
                    UserServiceApi.getUserServiceObjects(userServiceId, {objectType: type, filterText: searchText, limit})
                );
            }
            const responses = await Promise.all(activeQueries.current.queries.map(query => query.promise));
            if (activeQueries.current.aborted) {
                return;
            }
            for (const res of responses) {
                res.data.result.forEach(obj => items.push(UserServiceModel.getObjectItem(obj)));
                remaining += res.data.remaining;
            }
            if (remaining > 0) {
                items.push({
                    hasRemaining: true,
                    name: t('common:option.moreItems', {count: remaining}),
                    onSelect: e => {
                        e.stopPropagation();
                        queryUserServiceObjects(searchText, limit + 1000, true);
                    },
                    isItalic: true,
                    isCentered: true
                });
            }
        } catch (error) {
            return;
        } finally {
            clearTimeout(loadingTimeout);
        }

        if (items.length === 0) {
            items = emptyItems;
        }
        setItems(items);
        filterText.current = searchText;

    }, [userServiceId, JSON.stringify(objectType)]);

    useEffect(() => {
        queryUserServiceObjects('');
    }, [JSON.stringify(objectType)])

    const queryTimeout = useRef();
    const searchTextCallback = useCallback(searchText => {
        if (queryTimeout.current != null) {
            clearTimeout(queryTimeout.current);
        }

        // Only query for items if @searchText is different || lastItem.hasMoreItems === true
        const lastItem = items[items.length - 1];
        if (!searchText.startsWith(filterText.current) || (lastItem?.hasRemaining || lastItem?.isLoading)) {
            queryTimeout.current = setTimeout(async () => {
                queryUserServiceObjects(searchText);
                queryTimeout.current = null;
            }, 250);
        }
    }, [queryUserServiceObjects, items]);

    return [items, searchTextCallback];
}

export default UserServiceObjectDropdown;
