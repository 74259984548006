import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {capitalizeFirstLetter, getTranslatedValueOrDefault} from "../../../utilities/helperFunctions";
import Form from "../../common/Form/Form";
import ObjectField from "./ObjectField";
import FormBuilderConfiguration from "../../../models/workflowbuilder/FormBuilderConfiguration";
import ParameterModel from "../../../models/library/ParameterModel";
import {useSelector} from "react-redux";
import FormBuilderComponentConfiguration from "../../../models/workflowbuilder/FormBuilderComponentConfiguration";
import {Button} from "../../common/Button/Button";
import {selectPrecedingParameters} from "./ParameterField";
import {scriptLanguageKeys} from "../../../utilities/constants";
import {useUpdateEffect} from "../../../utilities/hooks";
import {libraryFileTypeKeys} from "../../../i18next/keys";


function useParameterConfiguration(opts) {
    const {
        activeIndex,
        configuration,
        value,
        updateState
    } = opts;

    const workflowBuilder = useSelector(state => state.workflowBuilder);
    const [parameterConfiguration, setParameterConfiguration] = useState();
    const prevNormalizedName = useRef(value.name);

    // Translate configuration into parameterConfiguration
    useEffect(() => {
        const parameterConfiguration = configuration.duplicate();
        const formConfiguration = FormBuilderConfiguration.buildFormConfiguration(parameterConfiguration.formConfiguration);
        parameterConfiguration.formConfiguration = formConfiguration;

        const nameConfiguration = formConfiguration.fieldConfigurations.get('name');
        nameConfiguration.callbacks = {
            onBlur: function (event) {
                const {name, value} = event.target;
                const prevDefaultFriendlyName = ParameterModel.getFriendlyNameFromParameterName(prevNormalizedName.current);
                const normalizedName = ParameterModel.normalizeParameterName(value);
                prevNormalizedName.current = normalizedName;

                updateState(prev => {
                    const update = {[name]: ParameterModel.normalizeParameterName(value)};

                    // Auto-set friendlyName if null/empty OR if friendlyName was default friendlyName of previous param name
                    if (!prev.friendlyName || prev.friendlyName === prevDefaultFriendlyName) {
                        update.friendlyName = ParameterModel.getFriendlyNameFromParameterName(normalizedName);
                    }
                    return update;
                });
            }
        };

        // Changes specific to staticParameters
        if (parameterConfiguration.name === 'staticParameters') {
            // Restrict parameterTypes
            const parameterTypeConfiguration = formConfiguration.fieldConfigurations.get('parameterType');
            parameterTypeConfiguration.allowedValues = parameterTypeConfiguration.allowedValues
                .filter(allowedValue => ParameterModel.StaticType[allowedValue] != null);
        }

        const valueConfiguration = formConfiguration.fieldConfigurations.get('value');
        valueConfiguration.parameterType = value.parameterType;

        // Wrap allowedValues fields into a FAKE_OBJECT
        const allowedValuesMatchByConfiguration = formConfiguration.fieldConfigurations.get('allowedValuesMatchBy');
        const allowedValuesConfiguration = formConfiguration.fieldConfigurations.get('allowedValues');
        if (allowedValuesConfiguration != null && allowedValuesMatchByConfiguration != null) {
            const allowedValuesFakeObjectConfiguration = new FormBuilderComponentConfiguration({
                name: 'allowedValuesFakeObject',
                label: 'allowedValues',
                componentType: FormBuilderComponentConfiguration.ComponentType.FAKE_OBJECT,
                formConfiguration: {
                    componentConfigurations: [allowedValuesMatchByConfiguration, allowedValuesConfiguration]
                },
                visibleOn: allowedValuesConfiguration.visibleOn,
                visibleOnOperator: allowedValuesConfiguration.visibleOnOperator,
                enableOn: allowedValuesConfiguration.enableOn,
                enableOnOperator: allowedValuesConfiguration.enableOnOperator
            });

            allowedValuesFakeObjectConfiguration.formConfiguration.fieldConfigurations.get('allowedValues').label = 'values';
            for (const config of allowedValuesFakeObjectConfiguration.formConfiguration.componentConfigurations) {
                delete config.visibleOn;
                delete config.visibleOnOperator;
                delete config.enableOn;
                delete config.enableOnOperator;
            }

            const allowedValuesIndex = formConfiguration.componentConfigurations.indexOf(allowedValuesConfiguration);
            formConfiguration.componentConfigurations[allowedValuesIndex] = allowedValuesFakeObjectConfiguration;
            formConfiguration.fieldConfigurations.set('allowedValuesFakeObject', allowedValuesFakeObjectConfiguration);
            formConfiguration.fieldConfigurations.delete('allowedValuesMatchBy');
            formConfiguration.fieldConfigurations.delete('allowedValues');
            formConfiguration.componentConfigurations = formConfiguration.componentConfigurations.filter(config => !['allowedValuesMatchBy', 'allowedValues'].includes(config.name));
        }

        // Update displayCondition
        const displayConditionConfiguration = formConfiguration.fieldConfigurations.get('displayCondition');
        if (displayConditionConfiguration != null) {
            const displayParameterConfiguration = displayConditionConfiguration.formConfiguration.fieldConfigurations.get('parameter');
            displayParameterConfiguration.listIndex = activeIndex;
            displayParameterConfiguration.showClearSelectedOption = true;
            displayParameterConfiguration.callbacks = {
                onClear: function() {
                    updateState({displayCondition: displayConditionConfiguration.generateDefaultObject()});
                }
            }
        }

        // Update relativityCondition
        const relativityConditionConfiguration = formConfiguration.fieldConfigurations.get('relativityCondition');
        if (relativityConditionConfiguration != null) {
            const filterParameterConfiguration = relativityConditionConfiguration.formConfiguration.fieldConfigurations.get('filterParameter');
            filterParameterConfiguration.listIndex = activeIndex;

            const filterTypeConfiguration = relativityConditionConfiguration.formConfiguration.fieldConfigurations.get('filterType');
            filterTypeConfiguration.showClearSelectedOption = true;
            filterTypeConfiguration.callbacks = {
                onClear: function() {
                    updateState({relativityCondition: relativityConditionConfiguration.generateDefaultObject()});
                }
            }
        }

        const dependantParametersConfiguration = formConfiguration.fieldConfigurations.get('dependantParameters');
        if (dependantParametersConfiguration != null) {
            dependantParametersConfiguration.listIndex = activeIndex;
        }

        // Filter for script-type libraryFiles
        const libraryFiledIdConfiguration = formConfiguration.fieldConfigurations.get('libraryFileId');
        if (libraryFiledIdConfiguration != null) {
            libraryFiledIdConfiguration.filter = [libraryFileTypeKeys.PYTHON_SCRIPT, libraryFileTypeKeys.JS_SCRIPT, libraryFileTypeKeys.RUBY_SCRIPT ,libraryFileTypeKeys.POWERSHELL_SCRIPT];
        }

        setParameterConfiguration(parameterConfiguration);
    }, [configuration]);


    // Update parameterConfiguration on parameter update
    const parametersConfigurationDependencies = [
        value.name, value.friendlyName, value.parameterType, value.regex, value.allowedValues, value.min, value.max, value.dependantParameters,
        value.dataRepositoryId, value.allowedDataRepositoryIds, value.allowedFileExtensions, value.displayCondition, value.relativityCondition?.filterType
    ];
    useEffect(() => {
        setParameterConfiguration(prev => {
            const parameterConfiguration = prev.duplicate();
            const formConfiguration = FormBuilderConfiguration.buildFormConfiguration(parameterConfiguration.formConfiguration);
            parameterConfiguration.formConfiguration = formConfiguration;

            // ParameterType changes
            let parameterType = value.parameterType;
            if (ParameterModel.isRelativityParameter(parameterType) || ParameterModel.isThirdPartyServiceParameter(parameterType)
                || [ParameterModel.Type.DATA_SET, ParameterModel.Type.LEGAL_HOLD].includes(parameterType)) {

                parameterType = ParameterModel.Type.TEXT;
            }

            // AllowedValues
            const allowedValuesConfiguration = formConfiguration.fieldConfigurations.get('allowedValues');
            if (allowedValuesConfiguration != null) {
                const allowedValuesValueConfiguration = allowedValuesConfiguration.componentConfigurations[0];
                allowedValuesValueConfiguration.componentType = FormBuilderComponentConfiguration.ComponentType.PARAMETER_INPUT;
                allowedValuesValueConfiguration.parameterName = 'allowedValues';
                allowedValuesValueConfiguration.parameterType = parameterType;
                allowedValuesValueConfiguration.regex = value.regex;
                allowedValuesValueConfiguration.min = isNaN(parseInt(value.min)) ? null : value.min;
                allowedValuesValueConfiguration.max = isNaN(parseInt(value.max)) ? null : value.max;
                delete allowedValuesValueConfiguration.allowedValues;
            }

            // MinMax
            const minConfiguration = formConfiguration.fieldConfigurations.get('min');
            if (minConfiguration != null) {
                switch (value.parameterType) {
                    case ParameterModel.Type.INTEGER:
                        minConfiguration.componentType = FormBuilderComponentConfiguration.ComponentType.NUMBER;
                        break;
                    case ParameterModel.Type.DATE:
                        minConfiguration.componentType = FormBuilderComponentConfiguration.ComponentType.DATE;
                        minConfiguration.dateMode = 'utc';
                        break;
                    case ParameterModel.Type.DATE_TIME:
                        minConfiguration.componentType = FormBuilderComponentConfiguration.ComponentType.DATE_TIME;
                        break;
                }
                minConfiguration.max = isNaN(parseInt(value.max)) ? null : value.max;
            }
            const maxConfiguration = formConfiguration.fieldConfigurations.get('max');
            if (maxConfiguration != null) {
                switch (value.parameterType) {
                    case ParameterModel.Type.INTEGER:
                        maxConfiguration.componentType = FormBuilderComponentConfiguration.ComponentType.NUMBER;
                        break;
                    case ParameterModel.Type.DATE:
                        maxConfiguration.componentType = FormBuilderComponentConfiguration.ComponentType.DATE;
                        maxConfiguration.dateMode = 'utc';
                        break;
                    case ParameterModel.Type.DATE_TIME:
                        maxConfiguration.componentType = FormBuilderComponentConfiguration.ComponentType.DATE_TIME;
                        break;
                }
                maxConfiguration.min = isNaN(parseInt(value.min)) ? null : value.min;
            }

            const precedingParameters = selectPrecedingParameters(workflowBuilder, {listIndex: activeIndex});
            // Display condition
            const displayConditionConfiguration = formConfiguration.fieldConfigurations.get('displayCondition');
            if (displayConditionConfiguration != null) {
                const isDefaultValue = displayConditionConfiguration.isDefaultValue(value.displayCondition);
                // Disable if no parameter available to select && condition isEmpty
                displayConditionConfiguration.disabled = isDefaultValue && precedingParameters.length === 0;

                // Enable clearSelected option if condition is filled
                const displayParameterConfiguration = displayConditionConfiguration.formConfiguration.fieldConfigurations.get('parameter');
                displayParameterConfiguration.enableClearSelectedOption = !isDefaultValue;
            }

            // RelativityCondition
            const relativityConditionConfiguration = formConfiguration.fieldConfigurations.get('relativityCondition');
            if (relativityConditionConfiguration != null) {
                const filterTypeConfiguration = relativityConditionConfiguration.formConfiguration.fieldConfigurations.get('filterType');
                const filterTypeAllowedValues = ParameterModel.getFilterTypesForRelativityParameterType(value.parameterType);
                filterTypeConfiguration.allowedValues = filterTypeAllowedValues;

                // Enable clearSelected option if condition is filled
                const isDefaultValue = relativityConditionConfiguration.isDefaultValue(value.relativityCondition);
                filterTypeConfiguration.enableClearSelectedOption = !isDefaultValue;

                // Disable if no relativityParameter available to select && condition isEmpty
                relativityConditionConfiguration.disabled = isDefaultValue &&
                    precedingParameters.filter(param => filterTypeAllowedValues.includes(param.parameterType)).length === 0;

                const filterParameterConfiguration = relativityConditionConfiguration.formConfiguration.fieldConfigurations.get('filterParameter');
                filterParameterConfiguration.parameterType = value.relativityCondition?.filterType || 'none';
            }

            const dependantParametersConfiguration = formConfiguration.fieldConfigurations.get('dependantParameters');
            if (dependantParametersConfiguration != null) {
                const isDefaultValue = dependantParametersConfiguration.isDefaultValue(value.dependantParameters);
                dependantParametersConfiguration.disabled = isDefaultValue && precedingParameters.length === 0;
            }

            const regexConfiguration = formConfiguration.fieldConfigurations.get('regex');
            if (value.regex) {
                try {
                    value.value.match(`^(${value.regex})$`);
                    delete regexConfiguration._invalid;
                } catch (error) {
                    console.log(error);
                    regexConfiguration._invalid = true;
                }
            }

            const valueConfiguration = formConfiguration.fieldConfigurations.get('value');
            valueConfiguration.parameterName = value.name;
            valueConfiguration.friendlyName = value.friendlyName;
            valueConfiguration.parameterType = parameterType;
            valueConfiguration.regex = value.regex;
            valueConfiguration.allowedValues = value.allowedValues;
            valueConfiguration.min = value.min;
            valueConfiguration.max = value.max;
            valueConfiguration.dataRepositoryId = value.dataRepositoryId;
            valueConfiguration.allowedDataRepositoryIds = value.allowedDataRepositoryIds;
            valueConfiguration.allowedFileExtensions = value.allowedFileExtensions;
            valueConfiguration.notRequired = true;
            return parameterConfiguration;
        });
    }, parametersConfigurationDependencies);

    useUpdateEffect(() => {
        if (value.parameterType === ParameterModel.Type.SCRIPTED && (!value.scriptCode || value.scriptCode === defaultPythonScriptValue || value.scriptCode === defaultPowershellScriptValue || value.scriptCode === defaultJavascriptScriptValue || value.scriptCode === defaultRubyScriptValue)) {
            updateState(() => {
                const update = {scriptCode: ''};
                const scriptLanguage = value.scriptLanguage;

                if (scriptLanguage === scriptLanguageKeys.PYTHON) {
                    update.scriptCode = defaultPythonScriptValue;
                } else if (scriptLanguage === scriptLanguageKeys.POWERSHELL) {
                    update.scriptCode = defaultPowershellScriptValue;
                } else if (scriptLanguage === scriptLanguageKeys.RUBY) {
                    update.scriptCode = defaultRubyScriptValue;
                } else if (scriptLanguage === scriptLanguageKeys.ECMA_SCRIPT) {
                    update.scriptCode = defaultJavascriptScriptValue;
                }
                return update;
            });
        }
    }, [value.scriptLanguage]);

    useEffect(() => {
        if (parameterConfiguration == null) return;
        const valueConfiguration = parameterConfiguration.formConfiguration.fieldConfigurations.get('value');
        const relativityConditionConfiguration = parameterConfiguration.formConfiguration.fieldConfigurations.get('relativityCondition');
        // Reset value, allowedValues, and relativityCondition filterType if parameterTypeChanged
        if (valueConfiguration.parameterType !== value.parameterType) {
            updateState(prev => {
                const update = {value: '', min: '', max: '', allowedValues: []};
                if (relativityConditionConfiguration != null) {
                    update.relativityCondition = {
                        ...prev.relativityCondition,
                        filterType: null
                    };
                }
                return update;
            });
        }
    }, [value.parameterType]);

    return parameterConfiguration;
}

function ParameterFieldForm(props) {
    const {t} = useTranslation(['workflowBuilder']);
    const {
        id,
        formKey,
        activeIndex,
        configuration,
        value,
        updateState,

        onClose,
        isDisabled
    } = props;

    const parameterConfiguration = useParameterConfiguration({activeIndex, configuration, value, updateState});
    if (parameterConfiguration == null) {
        return null;
    }

    const translationKeys = [
        `workflowBuilder:${formKey}.${configuration.name}_singular`,
        `workflowBuilder:${formKey}.${configuration.name}`
    ];
    const title = getTranslatedValueOrDefault(t, configuration.name, ...translationKeys);

    return (
        <Form onClose={onClose} isDisabled={isDisabled} closeButtonAriaLabel={t(`workflowBuilder:option.closeParameterForm${capitalizeFirstLetter(configuration.name)}`)}
            header={
                <h2 className={'subtitle is-5 is-bold' + (isDisabled ? ' is-disabled' : '')}>
                    {title}
                </h2>
            }
            body={
                <ObjectField root fixed autoFocus id={id} formKey={formKey}
                    configuration={parameterConfiguration} value={value} updateState={updateState} isDisabled={isDisabled}/>
            }
            footer={
                <Button label={t('common:option.close')} onClick={onClose} isDisabled={isDisabled}/>
            }
        />
    )
}

const defaultPowershellScriptValue = '## DEBUG\n' +
    '#Write-Output("Starting script to evaluate "+{parameter_name}+" - this will be logged to the Scheduler log")\n' +
    '\n' +
    '$result = @()\n' +
    '\n' +
    '# Sample Result\n' +
    '$result += "C:\\Temp\\Powershell"\n' +
    '\n' +
    '# Sample result to confirm live execution\n' +
    '$date = Get-Date\n' +
    '$result += "C:\\Temp\\$date"\n' +
    '\n' +
    '# Sample result containing client name\n' +
    '$result +=  "C:\\Client\\{client_name}"\n' +
    '\n' +
    '## DEBUG\n' +
    '#Write-Output(result)\n' +
    '\n' +
    '# Return the array\n' +
    'Set-Content -Path "{results_file}" -Value $result';
const defaultPythonScriptValue = `import datetime

## DEBUG
#print("Starting script to evaluate "+parameter_name+" - this will be logged to the Scheduler log")

result=[]

# Sample result to confirm live execution
result.append('C:\\\\Temp\\\\Python')

# Sample result to confirm live execution
result.append('C:\\\\Temp\\\\'+str(datetime.datetime.now()))

# Sample result containing client name
result.append("C:\\\\Client\\\\"+client_name)

## DEBUG
#print("returning: "+str(result))

# The resulting list is expected in the variable named "result"`;
const defaultJavascriptScriptValue = '//// DEBUG\n' +
    '//print("Starting script to evaluate "+parameter_name+" - this will be logged to the Scheduler log")\n' +
    '\n' +
    'var result = []\n' +
    '\n' +
    '// Sample result\n' +
    'result.push("C:\\\\Temp\\\\JS")\n' +
    '\n' +
    '// Sample result to confirm live execution\n' +
    'var today = new Date();\n' +
    'result.push("C:\\\\Temp\\\\"+today)\n' +
    '\n' +
    '// Sample result containing client name\n' +
    'result.push("C:\\\\Client\\\\"+client_name)\n' +
    '\n' +
    '//// DEBUG\n' +
    '//print (result)\n' +
    '\n' +
    '// Return the array\n' +
    'result';
const defaultRubyScriptValue = '## DEBUG\n' +
    '#puts("Starting script to evaluate "+parameter_name+" - this will be logged to the Scheduler log")\n' +
    '\n' +
    'result = []\n' +
    '\n' +
    '# Sample result\n' +
    'result << "C:\\\\Temp\\\\Ruby"\n' +
    '\n' +
    '# Sample result to confirm live execution\n' +
    'result << "C:\\\\Temp\\\\"+Time.now.strftime("%d/%m/%Y %H:%M:%s")\n' +
    '\n' +
    '# Sample result containing client name\n' +
    'result << "C:\\\\Client\\\\"+client_name\n' +
    '\n' +
    '## DEBUG\n' +
    '#puts(result)\n' +
    '\n' +
    '# Return the array\n' +
    'result';

export default ParameterFieldForm;
