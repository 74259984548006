import React, {Component} from 'react';
import '../ResourcePoolForm.css';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {clearOnFirstCallback, getSafeClose, onChange, warnOnClose} from '../../../utilities/componentFunctions';
import {onToggleEnable} from "../../common/Checkbox/helpers";
import TextArea from '../../common/TextArea/TextArea';
import InputList from '../../common/InputList/InputList';
import Checkbox from '../../common/Checkbox/Checkbox';
import {
    AwsIdTypeDropdown,
    AwsRegionDropdown,
    InstanceIdleActionDropdown,
    NuixLicenceSourceDropdown,
} from '../../common/Dropdown/Dropdown';
import {awsIdTypeKeys, instanceIdleActionKeys, popupInfoKeys} from "../../../i18next/keys";
import {onInputListAddInput, onInputListDeleteInput, onInputListInputChange} from "../../common/InputList/helpers";
import {onDropdownItemClick} from "../../common/Dropdown/helpers";
import Form, {FormFooter, FormHeader} from "../../common/Form/Form";
import {shouldEnableAwsResourcePool} from "../../../utilities/shouldEnableFunctions";
import {compareNumStrings, getDataNameFromParentNode} from "../../../utilities/helperFunctions";
import {resourcePoolTypes, updateMode} from "../../../utilities/constants";
import ResourcePoolModel from "../../../models/settings/ResourcePoolModel";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import PopupModel from "../../../models/scheduler/PopupModel";
import Switch from "../../common/Switch/Switch";
import ExpandableContent from "../../common/ExpandableContent/ExpandableContent";
import {FormElementRow} from "../../common/CustomTable/CustomTable";

class AwsResourcePoolForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            awsResourcePoolName: '',
            description: '',
            active: true,
            accessKey: '',
            region: '',
            awsIdType: awsIdTypeKeys.INSTANCE_IDS,
            instanceIds: [],
            tagName: '',
            tagValue: '',
            launchTemplateId: '',
            maxConcurrentInstances: 2,
            nuixLicenceSourceId: '',
            targetNuixWorkers: 2,
            minNuixWorkers: 1,
            whitelistedCertFingerprints: [''],
            instanceIdleAction: instanceIdleActionKeys.STOP,
            forceIdleActionBetweenJobs: true,

            useRemoteEngines: false,
            remoteAwsIdType: awsIdTypeKeys.LAUNCH_TEMPLATE_ID,
            remoteInstanceIds: [''],
            remoteTagName: '',
            remoteTagValue: '',
            remoteLaunchTemplateId: '',
            remoteMaxConcurrentInstances: 2,
            remoteNuixLicenceSourceId: '',
            remoteTargetNuixWorkers: 2,
            remoteMinNuixWorkers: 1,
            remoteInstanceIdleAction: instanceIdleActionKeys.STOP,
            remoteNoIdleBeforeFirstJob: false,
            remoteNoIdleDuringJob: false,

            isAddEnabled: !!props.formProps.referenceId || false,
            ...props.formProps,

            type: resourcePoolTypes.AWS
        };

        this.passwordRef = React.createRef();

        this.onInputChange = this.onInputChange.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.onInstanceIdleActionDropdownClick = this.onInstanceIdleActionDropdownClick.bind(this);
        this.onRemoteInstanceIdleActionDropdownClick = this.onRemoteInstanceIdleActionDropdownClick.bind(this);

        this.clearOnFirstCallback = clearOnFirstCallback.bind(this);
        this.getSafeClose = getSafeClose('state', ['active', 'forceIdleActionBetweenJobs', 'targetNuixWorkers', 'minNuixWorkers', 'maxConcurrentInstances', 'instanceIdleAction', 'awsIdType',
            'useRemoteEngines', 'remoteNoIdleBeforeFirstJob', 'remoteNoIdleDuringJob', 'remoteTargetNuixWorkers', 'remoteMinNuixWorkers', 'remoteMaxConcurrentInstances', 'remoteInstanceIdleAction', 'remoteAwsIdType']).bind(this);
        this.warnOnClose = warnOnClose(this.props.t('resourcePool:label.name')).bind(this);
        this.onDropdownItemClick = onDropdownItemClick({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            isEnabled: 'isAddEnabled',
            shouldEnable: shouldEnableAwsResourcePool,
            passwordRef: this.passwordRef,
            passwordName: 'secretKey'
        }).bind(this);
        this.onToggleEnable = onToggleEnable({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            isEnabled: 'isAddEnabled',
            shouldEnable: shouldEnableAwsResourcePool,
            passwordRef: this.passwordRef,
            passwordName: 'secretKey'
        }).bind(this);

        this.onAddAwsInstanceId = onInputListAddInput({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'instanceIds'
        }).bind(this);
        this.onDeleteAwsInstanceId = onInputListDeleteInput({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'instanceIds',
            isEnabled: 'isAddEnabled',
            shouldEnable: shouldEnableAwsResourcePool,
            passwordRef: this.passwordRef,
            passwordName: 'secretKey'
        }).bind(this);
        this.onAwsInstanceIdChange = onInputListInputChange({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'instanceIds',
            isEnabled: 'isAddEnabled',
            shouldEnable: shouldEnableAwsResourcePool,
            passwordRef: this.passwordRef,
            passwordName: 'secretKey'
        }).bind(this);

        this.onAddRemoteAwsInstanceId = onInputListAddInput({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'remoteInstanceIds'
        }).bind(this);
        this.onDeleteRemoteAwsInstanceId = onInputListDeleteInput({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'remoteInstanceIds',
            isEnabled: 'isAddEnabled',
            shouldEnable: shouldEnableAwsResourcePool,
            passwordRef: this.passwordRef,
            passwordName: 'secretKey'
        }).bind(this);
        this.onRemoteAwsInstanceIdChange = onInputListInputChange({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'remoteInstanceIds',
            isEnabled: 'isAddEnabled',
            shouldEnable: shouldEnableAwsResourcePool,
            passwordRef: this.passwordRef,
            passwordName: 'secretKey'
        }).bind(this);

        this.onAddWhitelistedCertFingerprint = onInputListAddInput({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'whitelistedCertFingerprints'
        }).bind(this);
        this.onDeleteWhitelistedCertFingerprint = onInputListDeleteInput({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'whitelistedCertFingerprints',
            isEnabled: 'isAddEnabled',
            shouldEnable: shouldEnableAwsResourcePool,
            passwordRef: this.passwordRef,
            passwordName: 'secretKey'
        }).bind(this);
        this.onWhitelistedCertFingerprintChange = onInputListInputChange({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'whitelistedCertFingerprints',
            isEnabled: 'isAddEnabled',
            shouldEnable: shouldEnableAwsResourcePool,
            passwordRef: this.passwordRef,
            passwordName: 'secretKey'
        }).bind(this);
    }

    onInputChange(event) {
        const {name, value, type} = event.target;

        const updates = {};
        // Using same inputHandler for password field for isAddEnabled logic
        // Do not track password value
        if (type !== 'password')
            updates[name] = value;

        // Clear password when user changes value for below
        if (['accessKey'].includes(name)) {
            this.passwordRef.current.value = '';
        }

        this.setState(prevState => {

            const isAddEnabled = shouldEnableAwsResourcePool({
                ...prevState,
                ...updates,
                secretKey: this.passwordRef.current.value
            });

            return {
                ...prevState,
                ...updates,
                isAddEnabled
            }
        });
    }

    onAddClick() {
        this.props.addResourcePool(this.state);
    }

    onInstanceIdleActionDropdownClick(event) {
        const {dataset: {value}, parentNode} = event.currentTarget;
        const name = getDataNameFromParentNode(parentNode);
        const updates = {[name]: value};

        if (this.state.instanceIdleAction === value)
            return;

        const updateCall = () => onChange.call(this, updates, {
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            isEnabled: 'isAddEnabled',
            shouldEnable: shouldEnableAwsResourcePool
        });

        if (value === instanceIdleActionKeys.TERMINATE) {
            this.props.showWarning({
                info: {
                    key: popupInfoKeys.INSTANCE_IDLE_ACTION_TERMINATE_WARNING
                },
                buttons: [{
                    titleKey: 'common:option.ok',
                    onClick: updateCall
                }]
            });
        } else {
            updateCall();
        }
    }

    onRemoteInstanceIdleActionDropdownClick(event) {
        const {dataset: {value}, parentNode} = event.currentTarget;
        const name = getDataNameFromParentNode(parentNode);
        const updates = {[name]: value};

        if (this.state.remoteInstanceIdleAction === value)
            return;

        const updateCall = () => onChange.call(this, updates, {
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            isEnabled: 'isAddEnabled',
            shouldEnable: shouldEnableAwsResourcePool
        });

        if (value === instanceIdleActionKeys.TERMINATE) {
            this.props.showWarning({
                info: {
                    key: popupInfoKeys.INSTANCE_IDLE_ACTION_TERMINATE_WARNING
                },
                buttons: [{
                    titleKey: 'common:option.ok',
                    onClick: updateCall
                }]
            });
        } else {
            updateCall();
        }
    }


    render() {

        const {
            t,
            isDisabled
        } = this.props;
        const {
            awsResourcePoolName,
            description,
            active,
            accessKey,
            region,
            awsIdType,
            launchTemplateId,
            maxConcurrentInstances,
            instanceIds,
            tagName,
            tagValue,
            nuixLicenceSourceId,
            targetNuixWorkers,
            minNuixWorkers,
            whitelistedCertFingerprints,
            instanceIdleAction,
            forceIdleActionBetweenJobs,

            remoteAwsIdType,
            remoteLaunchTemplateId,
            remoteMaxConcurrentInstances,
            remoteInstanceIds,
            remoteTagName,
            remoteTagValue,
            remoteNuixLicenceSourceId,
            remoteTargetNuixWorkers,
            remoteMinNuixWorkers,
            remoteInstanceIdleAction,
            useRemoteEngines,
            remoteNoIdleBeforeFirstJob,
            remoteNoIdleDuringJob,

            referenceId,
            referenceValue,

            isAddEnabled
        } = this.state;

        const close = this.getSafeClose();
        const disabled = isDisabled ? ' is-disabled' : '';
        const isKeyRequired = !!referenceId ? !(!!referenceValue && referenceValue === accessKey) : true;

        return (
            <Form onClose={close} isDisabled={isDisabled} closeButtonAriaLabel={t('resourcePool:option.closeAwsForm')}
                header={
                    <FormHeader text={t('resourcePool:label.awsName')} iconName={'awsResourcePool'} isDisabled={isDisabled} />
                }
                body={
                    <div className="cloudResourcePoolForm-body">
                        <div className="display-input">
                            <HTMLTextInput label={t('common:label.name')} name={'awsResourcePoolName'}
                                value={awsResourcePoolName} onChange={this.onInputChange}
                                isDisabled={isDisabled} isRequired
                            />
                        </div>

                        <Checkbox label={t('resourcePool:label.active')} name={'active'}
                            checked={active} onClick={this.onToggleEnable}
                            isDisabled={isDisabled}
                        />

                        <div className="display-input">
                            <TextArea label={t('common:label.description')} name={'description'}
                                value={description} onChange={this.onInputChange}
                                isDisabled={isDisabled}
                            />
                        </div>

                        <div className="display-input">
                            <HTMLTextInput label={t('resourcePool:label.accessKey')} name={'accessKey'}
                                value={accessKey} onChange={this.onInputChange}
                                isDisabled={isDisabled} isRequired
                            />
                        </div>

                        <div className="display-input">
                            <HTMLTextInput inputRef={this.passwordRef} label={t('resourcePool:label.secretKey')} name={'secretKey'} type={'password'}
                                onChange={this.onInputChange} onBeforeInput={this.clearOnFirstCallback} isDisabled={isDisabled} isRequired={isKeyRequired}
                            />
                        </div>

                        <div className="display-input forms-table">
                            <div className="table-row-group">
                                <FormElementRow label={t('resourcePool:label.region')} isDisabled={isDisabled}
                                    element={
                                        <AwsRegionDropdown value={region} onItemSelect={this.onDropdownItemClick}
                                            isDisabled={isDisabled} isRequired/>
                                    }/>
                            </div>
                        </div>

                        <div className="display-input">
                            <ExpandableContent label={t('resourcePool:label.engines')} initialExpanded={true}
                                               headerBarStyle={{
                                                   marginBottom: '0.25rem'
                                               }}
                            >
                                <div className="display-input">

                                    <div className="display-input forms-table">
                                        <div className="table-row-group">
                                            <div className="table-row">
                                                <div className="table-cell no-stretch">
                                                    <label className={'label' + disabled}>
                                                        {t('resourcePool:label.startVmFrom')}:
                                                    </label>
                                                </div>
                                                <div className="table-cell">
                                                    <AwsIdTypeDropdown selectedAwsIdType={awsIdType} onAwsIdTypeSelect={this.onDropdownItemClick}
                                                                       isDisabled={isDisabled}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <Switch>
                                        {awsIdType === awsIdTypeKeys.LAUNCH_TEMPLATE_ID &&
                                        <div className="aws-template-values">
                                            <div className="template-id">
                                                <HTMLTextInput label={t('resourcePool:label.launchTemplateId')} name={'launchTemplateId'}
                                                               value={launchTemplateId} onChange={this.onInputChange} isDisabled={isDisabled} isRequired
                                                />
                                            </div>
                                            <div className="max-concurrent-instances">
                                                <HTMLTextInput label={t('resourcePool:label.maxConcurrentInstances')} name={'maxConcurrentInstances'}
                                                               type={'number'} value={maxConcurrentInstances} onChange={this.onInputChange}
                                                               style={{width: '5rem'}} isDisabled={isDisabled} isRequired
                                                />
                                            </div>
                                        </div>
                                        }

                                        {awsIdType === awsIdTypeKeys.INSTANCE_IDS &&
                                        <InputList id={'awsInstanceIds'} values={instanceIds} onValueChange={this.onAwsInstanceIdChange}
                                            onAddClick={this.onAddAwsInstanceId} onDeleteClick={this.onDeleteAwsInstanceId}
                                            ariaLabelKey={'AwsInstanceId'} isDisabled={isDisabled} isRequired/>
                                        }

                                        {awsIdType === awsIdTypeKeys.TAGS &&
                                        <div className="aws-template-values">
                                            <div className="tag-name">
                                                <HTMLTextInput label={t('resourcePool:label.tagName')} name={'tagName'}
                                                               value={tagName} onChange={this.onInputChange} isDisabled={isDisabled} isRequired
                                                />
                                            </div>
                                            <div className="tag-value">
                                                <HTMLTextInput label={t('resourcePool:label.tagValue')} name={'tagValue'}
                                                               value={tagValue} onChange={this.onInputChange} isDisabled={isDisabled} isRequired
                                                />
                                            </div>
                                        </div>
                                        }
                                    </Switch>
                                </div>

                                <div className="display-input forms-table">
                                    <div className="table-row-group">
                                        <FormElementRow label={t('resourcePool:label.licenceSource')} isDisabled={isDisabled}
                                            element={
                                                <NuixLicenceSourceDropdown value={nuixLicenceSourceId}
                                                    onItemSelect={this.onDropdownItemClick} isDisabled={isDisabled} isRequired/>
                                            }/>

                                        <FormElementRow htmlFor={'awsResourcePoolTargetNuixWorkers'} label={t('resourcePool:label.targetWorkers')}
                                            isDisabled={isDisabled}
                                            element={
                                                <HTMLTextInput id={'awsResourcePoolTargetNuixWorkers'} type={'number'} name={'targetNuixWorkers'}
                                                    value={targetNuixWorkers} onChange={this.onInputChange} style={{width: '5rem'}}
                                                    isDisabled={isDisabled} isRequired/>
                                            }/>

                                        <FormElementRow htmlFor={'awsResourcePoolMinNuixWorkers'} label={t('resourcePool:label.minWorkers')}
                                            element={
                                                <HTMLTextInput id={'awsResourcePoolMinNuixWorkers'} name={'minNuixWorkers'} value={minNuixWorkers}
                                                    onChange={this.onInputChange} type={'number'} style={{width: '5rem'}} isDisabled={isDisabled} isRequired
                                                    isInvalid={compareNumStrings(targetNuixWorkers, minNuixWorkers) < 0}/>
                                            }/>
                                    </div>
                                </div>

                                <div className="display-input" style={{display: 'flex'}}>
                                    <label className={'label' + disabled} style={{margin: 'auto 1rem auto 0'}}>
                                        {t('resourcePool:label.instanceIdleAction')}:
                                    </label>
                                    <InstanceIdleActionDropdown selectedInstanceIdleAction={instanceIdleAction} onInstanceIdleActionSelect={this.onInstanceIdleActionDropdownClick}
                                                                isDisabled={isDisabled}
                                    />
                                </div>
                                <div className="display-input">
                                    <Checkbox label={t('resourcePool:label.forceIdleActionBetweenJobs')} name={'forceIdleActionBetweenJobs'}
                                              checked={forceIdleActionBetweenJobs} onClick={this.onToggleEnable}
                                              isDisabled={isDisabled}
                                    />
                                </div>

                            </ExpandableContent>
                        </div>



                        <div className="display-input">
                            <ExpandableContent label={t('resourcePool:label.remoteEngines')} initialExpanded={true}
                                               headerBarStyle={{
                                                   marginBottom: '0.25rem'
                                               }}
                            >
                                <div className="display-input">
                                    <Checkbox label={t('resourcePool:label.useRemoteEngines')} name={'useRemoteEngines'}
                                              checked={useRemoteEngines} onClick={this.onToggleEnable}
                                              isDisabled={isDisabled}

                                    />
                                </div>


                                {useRemoteEngines &&
                                <div className="display-input">

                                    <div className="display-input">
                                        <div className="display-input forms-table">
                                            <div className="table-row-group">
                                                <div className="table-row">
                                                    <div className="table-cell no-stretch">
                                                        <label className={'label' + disabled}>
                                                            {t('resourcePool:label.startVmFrom')}:
                                                        </label>
                                                    </div>
                                                    <div className="table-cell">
                                                        <AwsIdTypeDropdown id={'remoteAwsIdTypeDropdown'} name={'remoteAwsIdType'}
                                                            selectedAwsIdType={remoteAwsIdType}
                                                            onAwsIdTypeSelect={this.onDropdownItemClick} isDisabled={isDisabled}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <Switch>
                                            {remoteAwsIdType === awsIdTypeKeys.LAUNCH_TEMPLATE_ID &&
                                            <div className="aws-template-values">
                                                <div className="template-id">
                                                    <HTMLTextInput
                                                        label={t('resourcePool:label.launchTemplateId')}
                                                        name={'remoteLaunchTemplateId'}
                                                        value={remoteLaunchTemplateId} onChange={this.onInputChange}
                                                        isDisabled={isDisabled} isRequired
                                                    />
                                                </div>
                                                <div className="max-concurrent-instances">
                                                    <HTMLTextInput
                                                        label={t('resourcePool:label.maxConcurrentInstances')}
                                                        name={'remoteMaxConcurrentInstances'}
                                                        type={'number'} value={remoteMaxConcurrentInstances}
                                                        onChange={this.onInputChange}
                                                        style={{width: '5rem'}} isDisabled={isDisabled} isRequired
                                                    />
                                                </div>
                                            </div>
                                            }

                                            {remoteAwsIdType === awsIdTypeKeys.INSTANCE_IDS &&
                                            <InputList id={'remoteAwsInstanceIds'}
                                                       values={remoteInstanceIds}
                                                       onValueChange={this.onRemoteAwsInstanceIdChange}
                                                       onAddClick={this.onAddRemoteAwsInstanceId}
                                                       onDeleteClick={this.onDeleteRemoteAwsInstanceId}
                                                       isDisabled={isDisabled} isRequired
                                            />
                                            }

                                            {remoteAwsIdType === awsIdTypeKeys.TAGS &&
                                            <div className="aws-template-values">
                                                <div className="tag-name">
                                                    <HTMLTextInput
                                                        label={t('resourcePool:label.tagName')}
                                                        name={'remoteTagName'}
                                                        value={remoteTagName} onChange={this.onInputChange}
                                                        isDisabled={isDisabled} isRequired
                                                    />
                                                </div>
                                                <div className="tag-value">
                                                    <HTMLTextInput
                                                        label={t('resourcePool:label.tagValue')}
                                                        name={'remoteTagValue'}
                                                        value={remoteTagValue} onChange={this.onInputChange}
                                                        isDisabled={isDisabled} isRequired
                                                    />
                                                </div>
                                            </div>
                                            }
                                        </Switch>
                                    </div>

                                    <div className="display-input forms-table">
                                        <div className="table-row-group">
                                            <div className="table-row">
                                                <div className="table-cell no-stretch">
                                                    <label className={'label' + disabled}>
                                                        {t('resourcePool:label.licenceSource')}:
                                                    </label>
                                                </div>
                                                <div className="table-cell">
                                                    <NuixLicenceSourceDropdown id={'remoteLicenceSourceDropdown'} name={'remoteNuixLicenceSourceId'}
                                                        selectedNuixLicenceSourceId={remoteNuixLicenceSourceId}
                                                        onNuixLicenceSourceSelect={this.onDropdownItemClick}
                                                        isDisabled={isDisabled} isRequired
                                                    />
                                                </div>
                                            </div>

                                            <div className="table-row">
                                                <div className="table-cell no-stretch">
                                                    <label htmlFor={'awsResourcePoolRemoteTargetNuixWorkers'}
                                                           className={'label' + disabled}>
                                                        {t('resourcePool:label.targetWorkers')}:
                                                    </label>
                                                </div>
                                                <div className="table-cell">
                                                    <HTMLTextInput id={'awsResourcePoolRemoteTargetNuixWorkers'}
                                                                   name={'remoteTargetNuixWorkers'}
                                                                   value={remoteTargetNuixWorkers}
                                                                   onChange={this.onInputChange}
                                                                   type={'number'} style={{width: '5rem'}}
                                                                   isDisabled={isDisabled} isRequired
                                                    />
                                                </div>
                                            </div>
                                            <div className="table-row">
                                                <div className="table-cell no-stretch">
                                                    <label htmlFor={'awsResourcePoolRemoteMinNuixWorkers'}
                                                           className={'label' + disabled}>
                                                        {t('resourcePool:label.minWorkers')}:
                                                    </label>
                                                </div>
                                                <div className="table-cell">
                                                    <HTMLTextInput id={'awsResourcePoolRemoteMinNuixWorkers'}
                                                                   name={'remoteMinNuixWorkers'}
                                                                   value={remoteMinNuixWorkers}
                                                                   onChange={this.onInputChange}
                                                                   type={'number'} style={{width: '5rem'}}
                                                                   isDisabled={isDisabled} isRequired
                                                                   isInvalid={compareNumStrings(remoteTargetNuixWorkers, remoteMinNuixWorkers) < 0}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="display-input" style={{display: 'flex'}}>
                                        <label className={'label' + disabled} style={{margin: 'auto 1rem auto 0'}}>
                                            {t('resourcePool:label.instanceIdleAction')}:
                                        </label>
                                        <InstanceIdleActionDropdown id={'remoteInstanceIdleActionDropdown'} name={'remoteInstanceIdleAction'}
                                            selectedInstanceIdleAction={remoteInstanceIdleAction}
                                            onInstanceIdleActionSelect={this.onRemoteInstanceIdleActionDropdownClick}
                                            isDisabled={isDisabled}
                                        />


                                    </div>

                                    <div className="display-input">
                                        <Checkbox label={t('resourcePool:label.remoteNoIdleBeforeFirstJob')} name={'remoteNoIdleBeforeFirstJob'}
                                                  checked={remoteNoIdleBeforeFirstJob} onClick={this.onToggleEnable}
                                                  isDisabled={isDisabled}

                                        />
                                    </div>

                                    <div className="display-input">
                                        <Checkbox label={t('resourcePool:label.remoteNoIdleDuringJob')} name={'remoteNoIdleDuringJob'}
                                                  checked={remoteNoIdleDuringJob} onClick={this.onToggleEnable}
                                                  isDisabled={isDisabled}

                                        />
                                    </div>
                                </div>
                                }
                            </ExpandableContent>
                        </div>

                        <div className="display-input">
                            <InputList id={'whitelistedCertFingerprints'} label={t('resourcePool:label.whitelistedCertFingerprints')}
                                values={whitelistedCertFingerprints} onValueChange={this.onWhitelistedCertFingerprintChange}
                                onAddClick={this.onAddWhitelistedCertFingerprint} onDeleteClick={this.onDeleteWhitelistedCertFingerprint}
                                ariaLabelKey={'WhitelistedCertFingerprint'} isDisabled={isDisabled}
                            />
                        </div>

                    </div>
                }
                footer={
                    <FormFooter addText={t('common:button.addResourcePool')}
                        onAddClick={this.onAddClick} onCancel={close}
                        isDisabled={isDisabled} isAddEnabled={isAddEnabled}
                    />
                }
            />
        );
    }
}

const mapStateToProps = (state, props) => {
    const {isDisabled, ...formProps} = state.componentStates.resourcePoolForm;
    
    return {
        formProps,
        isDisabled
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addResourcePool: resourcePool => dispatch(ResourcePoolModel.actionCreators.submitForm(resourcePool)),
        close: () => dispatch(ResourcePoolModel.actionCreators.hideForm(resourcePoolTypes.AWS)),

        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload))
    };
};

export default withTranslation(['resourcePool', 'common'])(connect(mapStateToProps, mapDispatchToProps)(AwsResourcePoolForm));