import OperationModel from "../generics/OperationModel";

class TemplateOperationModel extends OperationModel {

    static nom = 'TemplateOperationModel';
    static actions = TemplateOperationModel.buildActions('TEMPLATE');
    static actionCreators = TemplateOperationModel.buildActionCreators(TemplateOperationModel.actions);
    static reducer = TemplateOperationModel.buildReducer(TemplateOperationModel.actions);

    constructor(model = {}) {
        super(model);
    }
}

export default TemplateOperationModel;