import React, {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {makeGetEditDetails} from "../../reselect/selectors";
import {updateMode} from "../../utilities/constants";
import NoticeTemplateModel from "../../models/notice/NoticeTemplateModel";
import {isNotEmptyNorFalsy, objEqualsNotOrdered, switchcase} from "../../utilities/helperFunctions";
import PopupModel from "../../models/scheduler/PopupModel";
import {popupInfoKeys} from "../../i18next/keys";
import NavigatePane from "../common/NavigatePane/NavigatePane";
import NoticeSettingsPane from "./panes/NoticeSettingsPane";
import {createStateHandler} from "../../utilities/componentFunctions";
import NoticeMessagePane from "./panes/NoticeMessagePane";
import NoticeConfirmPane from "./panes/NoticeConfirmPane";
import NoticeSurveyPane from "./panes/NoticeSurveyPane";


function NoticeTemplateForm() {
    const {t} = useTranslation(['noticeTemplate', 'common']);
    const dispatch = useDispatch();

    // useMemo to memoize selector instance
    const getEditDetails = useMemo(makeGetEditDetails, []);
    const mode = useSelector(state => {
        return getEditDetails(state, {model: NoticeTemplateModel.nom})
            .activeModel === NoticeTemplateModel.nom ? updateMode.EDIT : updateMode.REDUX
    });

    const stateHandler = useMemo(() => createStateHandler({
        updateState: updates => dispatch(NoticeTemplateModel.componentActionCreators.updateForm(updates))
    }), [dispatch]);

    const noticeTemplateForm = useSelector(state => state.componentStates.noticeTemplateForm);
    const {
        force,
        type,
        noticeName,
        description,
        reminderNoticeTemplateId,
        escalateNoticeTemplateId
    } = noticeTemplateForm;

    // Only considering 1st pane values (can't proceed to 2nd pane without passing 1st)
    const formNotEmpty = isNotEmptyNorFalsy({
        noticeName,
        description,
        reminderNoticeTemplateId,
        escalateNoticeTemplateId
    });
    const onClose = useCallback(() => {
        const close = () => dispatch(NoticeTemplateModel.actionCreators.hideForm());

        if (!formNotEmpty || mode === updateMode.EDIT) {
            close();
        } else {

            dispatch(PopupModel.actionCreators.showWarning({
                info: {
                    key: popupInfoKeys.DISCARD_NOTICE_TEMPLATE
                },
                buttons: [{
                    title: t('common:option.discard'),
                    onClick: close
                }]
            }));
        }
    }, [mode, formNotEmpty, dispatch]);


    function onSubmit() {
        const submit = () => dispatch(NoticeTemplateModel.actionCreators.submitForm());
        const {
            id,
            force,
            isDisabled,

            initialValues,
            ...formValues
        } = noticeTemplateForm;

        if (!objEqualsNotOrdered(initialValues, formValues)) {
            submit();
        } else {
            dispatch(PopupModel.actionCreators.showWarning({
                info: {
                    key: popupInfoKeys.DUPLICATE_VALUES
                },
                buttons: [{
                    title: t('common:option.add'),
                    onClick: submit
                }]
            }));
        }
    }


    const saveEdit = () => dispatch(NoticeTemplateModel.actionCreators.saveEdit());
    const cancelEdit = () => dispatch(NoticeTemplateModel.actionCreators.cancelEdit());

    const [onCancel, submitTitle, submit] = switchcase({
        [updateMode.REDUX]: [onClose, t('common:option.add'), onSubmit],
        [updateMode.EDIT]: [cancelEdit, t('common:option.save'), saveEdit]
    })()(mode);

    const canNavigateTo = NoticeTemplateModel.validateFormData(noticeTemplateForm);

    const panes = [
        {title: t('common:label.settings'), backTitle: t('common:option.cancel'), onBackClick: onCancel, isNextEnabled: canNavigateTo.messagePane,
            component: <NoticeSettingsPane model={NoticeTemplateModel.nom} {...noticeTemplateForm} handler={stateHandler}/>
        },
        {title: t('noticeTemplate:label.message'), isNextEnabled: canNavigateTo.responsePane,
            component: <NoticeMessagePane {...noticeTemplateForm} handler={stateHandler}/>
        },
        {title: t('common:label.confirm'), nextTitle: submitTitle, onNextClick: submit, isNextEnabled: canNavigateTo.submit,
            component: <NoticeConfirmPane {...noticeTemplateForm}/>
        }
    ];

    if (NoticeTemplateModel.isResponseType(type)) {
        panes.splice(2, 0,
            {title: t('noticeTemplate:label.response'), isNextEnabled: canNavigateTo.submit,
                component: <NoticeSurveyPane {...noticeTemplateForm} handler={stateHandler}/>
            }
        );
    }


    return (
        <NavigatePane id={'noticeTemplateForm'} force={force} panes={panes} closeButtonAriaLabel={t(`noticeTemplate:option.closeForm${type}`)}
            onClose={onClose} isDisabled={noticeTemplateForm.isDisabled} bodyStyle={{height: '62.5vh', width: '100rem'}}
        />
    )
}

export default NoticeTemplateForm;
