import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {formElementTypes, legalHoldCollectionTrigger, legalHoldTriggerConfiguration} from "../../utilities/constants";
import {NoticeTemplateApi} from "../../models/notice/NoticeTemplateModel";
import ExpandableContent from "../common/ExpandableContent/ExpandableContent";
import {TableLabelCell, TableLabelHeader} from "../common/CustomTable/CustomTable";
import {getMapValueName, objectTruthyValues, stringToBool} from "../../utilities/helperFunctions";
import Text from "../common/Text/Text";

export function ExpandableTriggerConfigurations(props) {
    const {t} = useTranslation(['legalHold']);

    const [surveyFormOptionsMap, setSurveyFormOptionsMap] = useState(new Map());
    const noticeTemplateDetailsMap = useSelector(state => state.noticeTemplateDetailsMap);
    const {
        triggerConfigurations,
        isDisabled
    } = props;

    useEffect(() => {
        let isCancelled = false;

        function updateSurveyFormOptions(id, surveyFormOptions) {
            setSurveyFormOptionsMap(prev => {
                const newSurveyFormOptions = new Map(prev);
                newSurveyFormOptions.set(
                    id,
                    surveyFormOptions
                        .filter(opt => opt.enabled && (opt.type === formElementTypes.CHECKBOX || opt.type === formElementTypes.DROPDOWN))
                        .reduce((acc, opt) => {
                            acc[opt.key] = opt;
                            return acc;
                        }, {})
                );

                return newSurveyFormOptions;
            });
        }

        const queryPromises = {};
        for (const config of triggerConfigurations) {
            if (surveyFormOptionsMap.has(config.noticeTemplateIdAndVersion) || config.trigger !== legalHoldTriggerConfiguration.ON_CUSTODIAN_RESPONSE) {
                continue;
            }

            const [id, version] = config.noticeTemplateIdAndVersion.split(':');
            const template = noticeTemplateDetailsMap.get(id);

            if (template != null && template.version === version) {
                updateSurveyFormOptions(config.noticeTemplateIdAndVersion, template.surveyFormOptions);

            } else if (queryPromises[config.noticeTemplateIdAndVersion] == null) {
                NoticeTemplateApi.getSurveyFormOptions(id, version).then(res => {
                    if (!isCancelled) {
                        updateSurveyFormOptions(config.noticeTemplateIdAndVersion, res.data);
                    }
                });
            }
        }

        return () => isCancelled = true;
    }, [triggerConfigurations, noticeTemplateDetailsMap])

    return (
        <ExpandableContent label={t('legalHold:label.triggerConfigurations')} isDisabled={isDisabled}>
            <div className="display-table tight-padding">
                <div className="table-header-group">
                    <TableLabelHeader label={t('legalHold:label.trigger')}
                                      isDisabled={isDisabled}/>

                    <TableLabelHeader label={t('legalHold:label.workflowTemplate')} isDisabled={isDisabled}/>

                    <TableLabelHeader label={t('legalHold:label.noticeTemplate')} isDisabled={isDisabled}/>

                    <TableLabelHeader label={t('legalHold:label.question')} style={{minWidth: '12rem'}} isDisabled={isDisabled}/>

                    <TableLabelHeader label={t('legalHold:label.triggerResponses')} isDisabled={isDisabled}/>
                </div>

                <div className="table-row-group">
                    {triggerConfigurations.filter(config => config.trigger).map(config =>
                        <TriggerConfigurationRow key={config.id} configuration={config} isDisabled={isDisabled}
                                                    surveyFormOptions={surveyFormOptionsMap.get(config.noticeTemplateIdAndVersion)} />
                    )}
                </div>
            </div>
        </ExpandableContent>
    )
}

function selectNames(state, config) {

    const names = {
        workflowTemplate: getMapValueName(state.workflowTemplateDetailsMap, config.workflowTemplateId),
    };

    const [id, version] = config.noticeTemplateIdAndVersion.split(':');
    const template = state.noticeTemplateDetailsMap.get(id);

    if (template != null && template.version === version) {
        names.noticeTemplate = template.name;
    } else {
        names.noticeTemplate = `${template != null ? template.name : id} (${version})`;
    }

    return names;
}

function TriggerConfigurationRow(props) {
    const {t} = useTranslation(['noticeTemplate']);

    const {
        configuration,
        surveyFormOptions
    } = props;

    const names = useSelector(state => selectNames(state, configuration));

    const {
        enabled,
        trigger,
        optionKey,
        triggerResponses
    } = configuration;

    const option = surveyFormOptions && surveyFormOptions[optionKey];

    const isDisabled = props.isDisabled || !enabled;
    const isResponse = trigger === legalHoldCollectionTrigger.ON_RESPONSE;

    const _triggerResponses = Array.isArray(triggerResponses) ? triggerResponses : objectTruthyValues(triggerResponses);
    return (
        <div className="table-row no-pointer">
            <TableLabelCell label={t(`legalHold:triggerConfigurationTrigger.${trigger}`)} isDisabled={isDisabled}/>

            <div className="table-cell">
                <Text value={names.workflowTemplate} isDisabled={isDisabled}/>
            </div>

            <TableLabelCell label={isResponse && names.noticeTemplate} isDisabled={isDisabled}/>

            <TableLabelCell label={isResponse && option && (option.description || option.label)}
                            isWordWrap isDisabled={isDisabled}/>

            <div className="table-cell" style={{maxWidth: '30rem'}}>
                {(option && option.type === formElementTypes.CHECKBOX) ?
                    _triggerResponses.map((response, r_index) => {
                        const responseLabel = t(`common:option.${stringToBool(response) ? 'checked' : 'unchecked'}`);
                        return (
                            <Text key={r_index} value={responseLabel} isEllipsis isDisabled={isDisabled}/>
                        )
                    })
                    :
                    _triggerResponses.map((response, r_index) =>
                        <Text key={r_index} value={response} isEllipsis isDisabled={isDisabled}/>
                    )
                }
            </div>
        </div>
    )
}

export default ExpandableTriggerConfigurations;