import React from "react";
import {SettingsRowValue} from "../common/CustomTable/CustomTable";
import {useTranslation} from "react-i18next";
import {getLocaleDateTimeFromUTC, prettyPrintJson} from "../../utilities/helperFunctions";
import Text from "../common/Text/Text";
import ExpandableContent from "../common/ExpandableContent/ExpandableContent";
import {Light as SyntaxHighlighter} from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import xml from 'react-syntax-highlighter/dist/esm/languages/hljs/xml';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/docco';
import Switch from "../common/Switch/Switch";

SyntaxHighlighter.registerLanguage('json', json);
SyntaxHighlighter.registerLanguage('xml', xml);

function WebhookEventCallBody(props) {
    const {t} = useTranslation(['webhook']);

    const {
        requestEpoch,
        requestHeaders,
        requestBody,

        responseStatus,
        responseEpoch,
        responseHeaders,
        responseBody,

        canViewConfidential,
        isDisabled
    } = props;


    return (
        <>
            <div className="display-input">
                <ExpandableRequest label={t('webhook:label.response')} status={responseStatus} epoch={responseEpoch} headers={responseHeaders} body={responseBody}
                    canViewConfidential={canViewConfidential} cantViewConfidentialMessage={t('webhook:message.cantViewConfidentialResponseBody')}
                    isDisabled={isDisabled}/>
            </div>

            <div className="display-input">
                <ExpandableRequest label={t('webhook:label.request')} epoch={requestEpoch} headers={requestHeaders} body={requestBody}
                    canViewConfidential={canViewConfidential} cantViewConfidentialMessage={t('webhook:message.cantViewConfidentialRequestBody')}
                    initialExpanded={false} isDisabled={isDisabled}/>
            </div>
        </>
    )
}

const htmlContentTypes = ['text/html', 'text/xml', 'application/xhtml+xml', 'application/xml'];
function ExpandableRequest(props) {
    const {t} = useTranslation(['webhook']);

    const {
        status,
        epoch,
        headers,
        body,

        cantViewConfidentialMessage,
        canViewConfidential,
        isDisabled,

        ...attr
    } = props;

    let syntaxLanguage = 'json';
    if (typeof headers === 'string' && htmlContentTypes.some(type => headers.toLowerCase().includes(`content-type: ${type}`))) {
        syntaxLanguage = 'xml';
    }

    return (
        <ExpandableContent {...attr} isDisabled={isDisabled}>
            <div className="settings-table align-top">
                <div className="table-row-group">
                    {status !== undefined &&
                    <SettingsRowValue label={t('webhook:label.statusCode')} value={status} showIfNull
                        isDisabled={isDisabled}/>
                    }
                    <SettingsRowValue label={t('webhook:label.date')} value={getLocaleDateTimeFromUTC(epoch)}
                        isDisabled={isDisabled}/>
                    <SettingsRowValue label={t('webhook:label.headers')} value={headers} isBreakWord
                        isDisabled={isDisabled}/>
                </div>
            </div>

            <Switch>
                {!!body &&
                <>
                    <Text value={`${t('webhook:label.body')}:`} isDisabled={isDisabled}/>
                    <SyntaxHighlighter language={syntaxLanguage} style={style}>
                        {prettyPrintJson(body)}
                    </SyntaxHighlighter>
                </>
                }

                {!canViewConfidential &&
                <>
                    <Text value={`${t('webhook:label.body')}:`} isDisabled={isDisabled}/>
                    <Text value={cantViewConfidentialMessage} isItalic isDisabled={isDisabled}/>
                </>
                }
            </Switch>
        </ExpandableContent>
    )
}

export default WebhookEventCallBody;
