import {updateEditState} from "../../../utilities/componentFunctions";
import {deepCopy, filterArrayIndices} from "../../../utilities/helperFunctions";
import {updateMode} from "../../../utilities/constants";
import ParameterModel from "../../../models/library/ParameterModel";

export function onInputTableAddInput(defaultValues, {listName, mode, stateUpdater}) {
    switch (mode) {
        case updateMode.LOCAL:
        case updateMode.REDUX:
            return function () {
                stateUpdater.call(this, prevState => {
                    return {
                        [listName]: [
                            ...prevState[listName],
                            deepCopy(defaultValues)
                        ]
                    }
                });
            };
        case updateMode.EDIT:
            return function() {
                const updates = {
                    [listName]: [
                        ...this.props[listName],
                        deepCopy(defaultValues)
                    ]
                };
                updateEditState.call(this, updates, {stateUpdater});
            };
        default:
            break;
    }
}

export function onInputTableDeleteInput({listName, stateUpdater, mode, isEnabled, shouldEnable, passwordName, passwordRef}) {
    switch (mode) {
        case updateMode.LOCAL:
        case updateMode.REDUX:
            return function (indices) {
                stateUpdater.call(this, prevState => {
                    const updates = {
                        [listName]: filterArrayIndices(prevState[listName], indices)
                    };
                    if (isEnabled && shouldEnable) {
                        updates[isEnabled] = shouldEnable({
                            ...prevState,
                            ...updates,
                            [passwordName]: passwordRef && passwordRef.current.value
                        });
                    }
                    return updates;
                });
            };
        case updateMode.EDIT:
            return function (indices) {
                const updates = {
                    [listName]: filterArrayIndices(this.props[listName], indices)
                };
                updateEditState.call(this, updates, {shouldEnable, passwordName, passwordRef});
            };
        default:
            break;
    }
}

export function onInputTableChange({listName, stateUpdater, mode, isEnabled, shouldEnable}) {
    switch (mode) {
        case updateMode.LOCAL:
        case updateMode.REDUX:
            return function(event) {
                const { value } = event.target;
                const indices = buildIndices(event);

                stateUpdater.call(this, prevState => {
                    const updates = {
                        [listName]: modifyMatrixValue(prevState[listName], indices, value)
                    };
                    if (isEnabled && shouldEnable) {
                        updates[isEnabled] = shouldEnable({...prevState, ...updates});
                    }
                    return updates;
                });
            };
        case updateMode.EDIT:
            return function(event) {
                const { value } = event.target;
                const indices = buildIndices(event);

                const updates = {
                    [listName]: modifyMatrixValue(this.props[listName], indices, value)
                };
                updateEditState.call(this, updates, {shouldEnable});
            };
        default:
            break;
    }
}

export function onWorkflowParameterInputTableChange({listName, stateUpdater, mode, isEnabled, shouldEnable, passwordName, passwordRef}) {
    switch (mode) {
        case updateMode.LOCAL:
        case updateMode.REDUX:
            return function(event) {
                const { value } = event.target;
                const indices = buildIndices(event);

                stateUpdater.call(this, prevState => {
                    const updates = {
                        [listName]: updateWorkflowParameters(prevState[listName], indices, value)
                    };
                    if (isEnabled && shouldEnable) {
                        updates[isEnabled] = shouldEnable({
                            ...prevState,
                            ...updates,
                            [passwordName]: passwordRef && passwordRef.current.value
                        });
                    }
                    return updates;
                });
            };
        case updateMode.EDIT:
            return function(event) {
                const { value } = event.target;
                const indices = buildIndices(event);

                const updates = {
                    [listName]: updateWorkflowParameters(this.props[listName], indices, value)
                };
                updateEditState.call(this, updates, {shouldEnable, passwordName, passwordRef});
            };
        default:
            break;
    }
}

export function onWorkflowParameterInputTableBlur({listName, stateUpdater, mode, isEnabled, shouldEnable, passwordName, passwordRef}) {
    switch (mode) {
        case updateMode.LOCAL:
        case updateMode.REDUX:
            return function(event) {

                const indices = buildIndices(event);
                // No-op if blur is not from name column
                if (indices[0] !== 0)
                    return;

                const normalizedValue = ParameterModel.normalizeParameterName(event.target.value);
                // No need to update if already normalized
                if (normalizedValue === event.target.value) return;

                stateUpdater.call(this, prevState => {
                    const updates = {
                        [listName]: updateWorkflowParameters(prevState[listName], indices, normalizedValue)
                    };
                    if (isEnabled && shouldEnable) {
                        updates[isEnabled] = shouldEnable({
                            ...prevState,
                            ...updates,
                            [passwordName]: passwordRef && passwordRef.current.value
                        });
                    }
                    return updates;
                });
            };
        case updateMode.EDIT:
            return function(event) {

                const indices = buildIndices(event);
                // No-op if blur is not from name column
                if (indices[0] !== 0)
                    return;

                const normalizedValue = ParameterModel.normalizeParameterName(event.target.value);
                // No need to update if already normalized
                if (normalizedValue === event.target.value) return;

                const updates = {
                    [listName]: updateWorkflowParameters(this.props[listName], indices, normalizedValue)
                };
                updateEditState.call(this, updates, {shouldEnable, passwordName, passwordRef});
            };
        default:
            break;
    }
}

export function onWorkflowParameterNormalize (clearParameterPasswordState, setClearParameterPasswordState) {
    return function (parameters, prevParameters, row, col) {
        if (col === '0') {
            const fieldParameterNameChanged = parameters[row][0].value !== prevParameters[row][0].value;
            const isPassword = ParameterModel.isPasswordParameter(parameters[row][0].value);
            const valueInput = deepCopy(parameters[row][1]);

            if (isPassword) {
                if (fieldParameterNameChanged) {
                    valueInput.value = "";

                    if (valueInput.type !== 'password') {
                        valueInput.type = 'password';
                    }
                }
            } else if (valueInput.type === 'password') {
                valueInput.type = null;
                valueInput.value = '';
            }

            parameters[row] = [
                parameters[row][0],
                valueInput
            ]
        }

        if (col === '1') {
            const previousColField = prevParameters[row][col];
            if (previousColField.type === 'password') {
                const nameFieldValue = parameters[row][0].value;

                if (clearParameterPasswordState[nameFieldValue] == null) {
                    const valueInput = deepCopy(parameters[row][1]);
                    valueInput.value = valueInput.value.replace(previousColField.value, '');

                    const updatedParameterClearState = deepCopy(clearParameterPasswordState);
                    updatedParameterClearState[nameFieldValue] = true;

                    setClearParameterPasswordState({...updatedParameterClearState});

                    parameters[row] = [
                        parameters[row][0],
                        valueInput
                    ]
                }
            }
        }
    }
}

function updateWorkflowParameters(prevWorkflowParameters, [x, y], value) {
    const workflowParameters = modifyMatrixValue(prevWorkflowParameters, [x, y], value);
    // If event is from first column (parameter name column)
    if (x === 0) {
        // Check if isPasswordParameter
        const isPassword = ParameterModel.isPasswordParameter(value);
        const valueInput = workflowParameters[y][1];

        if (isPassword) {
            // Clear value when changing password name
            valueInput.value = '';
            // Set type of second column (parameter value column) to 'password'
            if (valueInput.type !== 'password') {
                valueInput.type = 'password';
            }

        } else if (valueInput.type === 'password') {
            // Password to non-password type, clear value and reset type
            valueInput.type = null;
            valueInput.value = '';
        }
    }

    return workflowParameters;
}

export function buildIndices(event) {
    const indices = [];

    let parent = event.target;
    while (parent) {
        const {index} = parent.dataset || {};
        if (index != null) {
            indices.push(parseInt(index));
        }

        parent = parent.parentNode;
    }

    return indices;
}

function modifyMatrixValue(matrix, [x, y], value) {
    const modifiedRow = [
        ...matrix[y].slice(0, x),
        {
            ...matrix[y][x],
            value
        },
        ...matrix[y].slice(x + 1)
    ];

    return [
        ...matrix.slice(0, y),
        modifiedRow,
        ...matrix.slice(y + 1)
    ];
}
