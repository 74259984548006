import {useTranslation} from "react-i18next";
import {createInputHandler, createValueHandler} from "../../../../utilities/componentFunctions";
import {createDropdownHandler} from "../../../../components/common/Dropdown/helpers";
import {createToggleHandler} from "../../../../components/common/Checkbox/helpers";
import React from "react";
import {updateGenericValues} from "../../../redux/WizardSlice/wizardSlice";
import {connect} from "react-redux";
import ToggleSwitch from "../../../../components/common/ToggleSwitch/ToggleSwitch";
import ExpandableContent from "../../../../components/common/ExpandableContent/ExpandableContent";
import {QuestionInput} from "../../Question/QuestionInput/QuestionInput";
import RelativityWorkspaceIdentifierTypeDropdown
    from "../../Dropdown/RelativityDropdown/RelativityWorkspaceIdentifierTypeDropdown";
import HTMLTextInput from "../../../../components/common/HTMLTextInput/HTMLTextInput";
import QuestionRadio from "../../Question/QuestionRadio/QuestionRadio";

function RelativityExport(props) {
    const {t} = useTranslation(['wizard'])
    const {updateState} = props;


    const {
        hostName,
        serviceEndpoint,
        endpointType,
        username,
        password,
        workspaceIdentifierType,
        workspaceIdentifier,
        folderPath,
        createIfNotExists,
        importThreads,
        importThreadTimeout,
        importThreadRetries,
        metadataThreads,
        patchInvalidEntries,
        useCustomClientVersion,
        customClientVersion,
        defineUsernameExecution,
        definePasswordExecution,
        defineWorkspaceExecution,
        defineHostNameExecution,
        defineFieldsMappingExecution,
        definedWorkspace
    } = props;

    const questions = [
        {
            question: t("wizard:questions.relativity.folderPath")
        },
        {
            question: t("wizard:questions.relativity.folderPathOptions")
        },
        {
            question: t("wizard:questions.relativity.defineWorkspaceIdentifier"),
            dataName: "definedWorkspace",
            options: [
                {id: 1, value: t("wizard:questions.relativity.workspace.profile")},
                {id: 2, value: t("wizard:questions.relativity.workspace.predefined")},
                {id: 3, value: t("wizard:questions.relativity.workspace.execution")}
            ]
        },
        {
            question: t("wizard:questions.relativity.workspaceIdentifier")
        }
    ]

    const inputHandler = createInputHandler({
        handler: updateState
    });
    const dropdownHandler = createDropdownHandler({
        handler: updateState
    });
    const toggleHandler = createToggleHandler({
        handler: updateState
    });
    const valuesHandler = createValueHandler({
        handler: updateState
    })

    function generateExample() {
        const parameters = [];

        if (!defineUsernameExecution) {
            parameters.push("{relativity_username}")
        }

        if (!definePasswordExecution) {
            parameters.push("{relativity_password}")
        }

        if (!defineHostNameExecution) {
            parameters.push("{relativity_hostname}")
        }

        if (!defineFieldsMappingExecution) {
            parameters.push("{relativity_fields_mapping}")
        }

        if (parameters.length !== 0) {
            const pString = parameters.join(", ");

            if (parameters.length === 1) {
                return (
                    <div className="dynamic-examples-container">
                        <label
                            className="label is-small">{t('wizard:questions.relativity.expected', {parameter: pString})}</label>
                    </div>
                )
            } else {
                return (
                    <div className="dynamic-examples-container">
                        <label
                            className="label is-small">{t('wizard:questions.relativity.expectedMultiple', {parameters: pString})}</label>
                    </div>
                )
            }
        }
    }

    return (
        <div className="wizard-step-container">

            <QuestionRadio
                question={questions[2].question}
                dataName={questions[2].dataName}
                options={questions[2].options}
                selectedValue={definedWorkspace}
                onChange={valuesHandler}/>

            {definedWorkspace === 1 &&
                <div className="dynamic-examples-container">
                    <label
                        className="label is-small">{t('wizard:questions.relativity.expected', {parameter: "{relativity_workspace_name}"})}</label>
                </div>
            }

            {definedWorkspace === 2 &&
                <div className={"display-item"}>
                    <label className="label is-heading">
                        {questions[3].question}
                    </label>
                    <div className='settings-table no-border tight-padding'
                         style={{border: '1px solid transparent', width: '50rem'}}>
                        <div className="table-row-group">
                            <div className="table-row align-middle no-hover">
                                <div className="table-cell no-stretch">
                                    <RelativityWorkspaceIdentifierTypeDropdown
                                        selectedWorkspaceIdentifierType={workspaceIdentifierType}
                                        onWorkspaceIdentifierTypeSelected={dropdownHandler}
                                        isRequired={true}
                                        isDisabled={false}/>
                                </div>
                                <div className="table-cell">
                                    <HTMLTextInput name={"workspaceIdentifier"} value={workspaceIdentifier}
                                                   onChange={inputHandler} isRequired={true}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <QuestionInput
                name={"folderPath"}
                value={folderPath}
                question={questions[0].question}
                onChange={inputHandler}
                isRequired={!createIfNotExists}/>

            <div className={"display-item"}>
                <label className="label is-heading">
                    {questions[1].question}
                </label>
                <div className='settings-table no-border tight-padding'
                     style={{border: '1px solid transparent', width: '50rem'}}>
                    <div className="table-row-group">
                        <div className="table-row align-middle no-hover">
                            <div className="table-cell">
                                <ToggleSwitch id={"createFolderNotExist"} name={"createIfNotExists"}
                                              label={t("wizard:questions.relativity.createNotExists")}
                                              checked={createIfNotExists} onClick={toggleHandler}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <ExpandableContent label={t("wizard:questions.discover.advanced")} initialExpanded={false}>

                <div style={{padding: '0.0rem 0.75rem'}}>
                    <div className="display-item">
                        <ToggleSwitch id={"defineUsernameExecution"} name={"defineUsernameExecution"}
                                      label={t('wizard:questions.relativity.defineParameterExecution', {parameter: "{relativity_username}"})}
                                      checked={defineUsernameExecution} onClick={toggleHandler}/>
                    </div>
                    <div className="display-item">
                        <ToggleSwitch id={"definePasswordExecution"} name={"definePasswordExecution"}
                                      label={t('wizard:questions.relativity.defineParameterExecution', {parameter: "{relativity_password}"})}
                                      checked={definePasswordExecution} onClick={toggleHandler}/>
                    </div>
                    <div className="display-item">
                        <ToggleSwitch id={"defineHostNameExecution"} name={"defineHostNameExecution"}
                                      label={t('wizard:questions.relativity.defineParameterExecution', {parameter: "{relativity_hostname}"})}
                                      checked={defineHostNameExecution} onClick={toggleHandler}/>
                    </div>
                    <div className="display-item">
                        <ToggleSwitch id={"defineFieldsMappingExecution"} name={"defineFieldsMappingExecution"}
                                      label={t('wizard:questions.relativity.defineParameterExecution', {parameter: "{relativity_fields_mapping}"})}
                                      checked={defineFieldsMappingExecution} onClick={toggleHandler}/>
                    </div>
                    {generateExample()}
                </div>
            </ExpandableContent>

        </div>
    )
}

function mapStateToProps(state) {
    const {
        wizardSlice: {
            relativityConfiguration:
                {
                    hostName, serviceEndpoint, endpointType, username, password, workspaceIdentifierType, workspaceIdentifier, folderPath, createIfNotExists,
                    importThreads, importThreadTimeout, importThreadRetries, metadataThreads, patchInvalidEntries, useCustomClientVersion, customClientVersion,
                    defineUsernameExecution, definePasswordExecution, defineWorkspaceExecution, defineHostNameExecution, defineFieldsMappingExecution, definedWorkspace
                }
        }
    } = state;
    return {
        hostName,
        serviceEndpoint,
        endpointType,
        username,
        password,
        workspaceIdentifierType,
        workspaceIdentifier,
        folderPath,
        createIfNotExists,
        importThreads,
        importThreadTimeout,
        importThreadRetries,
        metadataThreads,
        patchInvalidEntries,
        useCustomClientVersion,
        customClientVersion,
        defineUsernameExecution,
        definePasswordExecution,
        defineWorkspaceExecution,
        defineHostNameExecution,
        defineFieldsMappingExecution,
        definedWorkspace
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateState: updates => dispatch(updateGenericValues({stateName: "relativityConfiguration", updates: updates}))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RelativityExport);

