import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {selectDetailsMapForJobNames, selectJobFromDisplay, selectNamesFromJob} from "./selectors";
import {objEquals} from "../../utilities/helperFunctions";
import {userIconModel} from "../../models/generics/IconModel";
import {jobStates, updateMode} from "../../utilities/constants";
import {executionStateKeys, permissionKeys} from "../../i18next/keys";
import {createDropdownHandler} from "../common/Dropdown/helpers";
import JobModel from "../../models/job/JobModel";
import {ExecutionProfileDropdown, PriorityDropdown, ResourcePoolDropdown} from "../common/Dropdown/Dropdown";
import {StatusLabel} from "../common/Common";
import React, {useEffect} from "react";
import ExpandableContent from "../common/ExpandableContent/ExpandableContent";
import {SettingsUserIconRow, SettingsRowSeparator, SettingsRowValue} from "../common/CustomTable/CustomTable";
import {PriorityLabel} from "./JobTablet";
import UserModel from "../../models/user/UserModel";

function JobSettings(props) {
    const {t} = useTranslation(['job', 'resourcePool', 'common']);
    const dispatch = useDispatch();

    const {
        jobId
    } = props;

    const job = useSelector(state => selectJobFromDisplay(state, jobId));
    const detailsMap = useSelector(selectDetailsMapForJobNames, objEquals);
    const names = selectNamesFromJob(detailsMap, job);
    const requiredProfiles = useSelector(state => state.jobRequiredProfilesMap.get(jobId));

    const [submittedByUsername, submittedByUserIconName] = UserModel.useUserNameAndIconName(job.submittedBy);
    const [stagedByUsername, stagedByUserIconName] = UserModel.useUserNameAndIconName(job.stagedBy);

    const userIconSubmitted = userIconModel.useIcon(submittedByUserIconName);
    const userIconStaged = userIconModel.useIcon(stagedByUserIconName);

    // IFF not in backlog or pausing states
    const isReadOnly = ![...jobStates.backlog, executionStateKeys.PAUSING].includes(job.executionState);
    const canModify = job.userPermissions.includes(permissionKeys.MODIFY);

    const dropdownHandler = createDropdownHandler({
        mode: updateMode.LOCAL,
        updateState: updates => dispatch(JobModel.actionCreators.sendUpdates(jobId, updates()))
    });

    function executionProfileDropdownHandler (event) {
        const {dataset: {value}} = event.currentTarget;
        dispatch(JobModel.actionCreators.updateExecutionProfile(jobId, value, job.workflowXml, job.workflowTemplateId));
    }

    const executionProfileMissingRequiredProfiles = requiredProfiles != null && requiredProfiles.some(profile => profile.missing);
    let executionProfileValue;
    if (isReadOnly) {
        executionProfileValue = names.executionProfile;
    } else {
        executionProfileValue = (
            <div className="jobTablet-dropdown">
                <ExecutionProfileDropdown selectedExecutionProfileId={job.executionProfileId}
                    onExecutionProfileSelect={executionProfileDropdownHandler} isDisabled={!canModify}
                />
                {job.executionProfileId == null &&
                    <StatusLabel message={t('job:message.unassignedExecutionProfile')} enableHighlightText/>
                }
                {(!!job.executionProfileId && executionProfileMissingRequiredProfiles) &&
                    <StatusLabel message={t('job:message.executionProfileMissingProfiles')}/>
                }
            </div>
        )
    }

    useEffect(() => {
        dispatch(JobModel.actionCreators.getMissingRequiredProfiles(job.executionProfileId, job.workflowXml, job.workflowTemplateId));
    }, [job.executionProfileId, job.workflowXml, job.workflowTemplateId]);

    let resourcePoolValue;
    if (isReadOnly) {
        resourcePoolValue = names.resourcePool;
    } else {
        resourcePoolValue = (
            <div className="jobTablet-dropdown">
                <ResourcePoolDropdown selectedResourcePoolId={job.resourcePoolId}
                    onResourcePoolSelect={dropdownHandler} isDisabled={!canModify}
                />
                {job.resourcePoolId == null &&
                    <StatusLabel message={t('job:message.unassignedResourcePool')} enableHighlightText/>
                }
            </div>
        )
    }

    let priorityValue;
    if (isReadOnly) {
        priorityValue = (
            <PriorityLabel priority={job.priority}/>
        )
    } else {
        priorityValue = (
            <div className="jobTablet-dropdown">
                <PriorityDropdown selectedPriority={job.priority}
                    onPrioritySelect={dropdownHandler} isDisabled={!canModify}
                />
            </div>
        )
    }

    let matterValue = names.matter;
    if (!isReadOnly && job.matterLock) {
        matterValue = (
            <div style={{display: 'flex', marginLeft: '-0.5rem'}}>
                <label className="label is-wordwrap">
                    {names.matter}
                </label>
                <StatusLabel message={t('job:message.matterLockPendingExecution')} iconName={'statusScheduled'} enableHighlightText/>
            </div>
        )
    }

    let engineValue;
    if (isReadOnly) {
        engineValue = names.engine;
    } else if (job.engineHasWarnings) {
        engineValue = (
            <div style={{display: 'flex'}}>
                <span className="label is-wordwrap">
                    {names.engine}
                </span>
                <StatusLabel message={t('job:message.engineHasWarnings')} enableHighlightText/>
            </div>
        )
    }

    return (
        <ExpandableContent label={t('job:label.settings')}>
            <div className="settings-table">
                <div className="table-row-group">
                    <SettingsRowValue label={t('job:label.executionProfile')} value={executionProfileValue} enableHighlightText/>
                    <SettingsRowValue label={t('job:label.resourcePool')} value={resourcePoolValue} enableHighlightText/>
                    <SettingsRowValue label={t('job:label.priority')} value={priorityValue}/>
                    <SettingsRowSeparator size={!isReadOnly && 'small'}/>

                    {names.client && matterValue &&
                        <>
                            <SettingsRowValue label={t('job:label.client')} value={names.client} enableHighlightText/>
                            <SettingsRowValue label={t('job:label.matter')} value={matterValue} enableHighlightText/>
                            <SettingsRowSeparator/>
                        </>
                    }
                    {names.library && names.workflowTemplate &&
                        <>
                            <SettingsRowValue label={t('job:label.library')} value={names.library} enableHighlightText/>
                            <SettingsRowValue label={t('job:label.workflow')} value={names.workflowTemplate} enableHighlightText/>
                            <SettingsRowSeparator/>
                        </>
                    }

                    {!job.submittedDate &&
                        <SettingsRowValue label={t('job:label.autoSubmitOnDate')} value={job.autoSubmitOnDate}
                            enableHighlightText/>
                    }

                    {stagedByUsername &&
                        <>
                            <SettingsUserIconRow label={t('job:label.stagedBy')} value={stagedByUsername}
                                icon={userIconStaged} highlightValue/>
                        </>
                    }
                    <SettingsRowValue label={t('job:label.stagedDate')} value={job.stagedDate} enableHighlightText/>

                    {submittedByUsername &&
                        <>
                            <SettingsUserIconRow label={t('job:label.submittedBy')} value={submittedByUsername}
                                icon={userIconSubmitted} highlightValue/>
                        </>
                    }

                    <SettingsRowValue label={t('job:label.submittedDate')} value={job.submittedDate} enableHighlightText/>
                    <SettingsRowSeparator/>

                    {job.startedDate &&
                        <SettingsRowValue label={t('job:label.startedDate')} value={job.startedDate} enableHighlightText/>
                    }
                    {job.finishedDate &&
                        <SettingsRowValue label={t('job:label.finishedDate')} value={job.finishedDate} enableHighlightText/>
                    }
                    {(job.startedDate || job.finishedDate) &&
                        <SettingsRowSeparator/>
                    }

                    {engineValue &&
                        <SettingsRowValue label={t('job:label.engine')} value={engineValue} enableHighlightText/>
                    }
                    {names.remoteEngines &&
                        <SettingsRowValue label={t('resourcePool:label.remoteEngines')} value={names.remoteEngines} enableHighlightText/>
                    }

                    {job.excludeUtilization &&
                        <SettingsRowValue label={t('job:label.metrics')} value={t('job:label.excluded')} enableHighlightText/>
                    }
                </div>
            </div>
        </ExpandableContent>
    )
}

export default JobSettings;
