import React, {Component} from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import {ListDropdown} from "../../common/Dropdown/Dropdown";
import {getValues} from "../../../utilities/helperFunctions";
import {jobSortKeys, sortFieldKeys, sortOrderKeys} from "../../../i18next/keys";

class JobQueueSort extends Component {

    constructor(props) {
        super(props);

        this.onDropdownItemClick = this.onDropdownItemClick.bind(this);
        this.saveUpdates = this.saveUpdates.bind(this);
    }

    onDropdownItemClick(event) {
        const {dataset, parentNode} = event.currentTarget;
        const {value} = dataset;

        const updates = JobQueueSort.buildUpdates(parentNode, value);
        this.saveUpdates(updates);
    }

    saveUpdates(updates) {
        for (const setting of Object.getOwnPropertyNames(updates)) {
            const value = {
                ...this.props.setting[setting],
                ...updates[setting]
            };

            this.props.updateSetting(setting, value)
        }
    }

    static buildUpdates(node, value) {
        let updates = value;
        let parent = node;
        while (parent) {
            const {name} = parent.dataset || {};
            if (name) {
                updates = {[name]: updates};
            }

            parent = parent.parentNode;
        }

        return updates;
    }

    render() {
        const {
            t,
            setting: jobQueueSort
        } = this.props;

        return (
            <div className="options-table display-input">
                <div className="table-header-group">
                    <div className="table-header" style={{width: ''}}/>
                    <div className="table-header"/>
                    <div className="table-header"/>
                </div>

                <div className="table-row-group">

                    {getValues(jobSortKeys)
                        .map(keyValue =>

                            <div className="options-row" key={keyValue} data-name={keyValue}>
                                <div className="table-cell max-width-10" style={{width: '10rem'}}>
                                    <label className="label">
                                        {t(`jobQueueSort:label.${keyValue}`)}
                                    </label>
                                </div>
                                <div className="table-cell" style={{width: '18rem'}}>
                                    <SortFieldDropdown selectedSortField={jobQueueSort[keyValue].sortField}
                                        onSortFieldSelect={this.onDropdownItemClick}/>
                                </div>
                                <div className="table-cell">
                                    <SortOrderDropdown selectedSortOrder={jobQueueSort[keyValue].sortOrder}
                                        onSortOrderSelect={this.onDropdownItemClick}
                                    />
                                </div>
                            </div>
                        )
                    }

                </div>
            </div>
        )
    }
}

function SortOrderDropdown(props) {
    const {selectedSortOrder, onSortOrderSelect, ...rest} = props;
    const {t} = useTranslation(['aria', 'jobQueueSort']);

    const sortOrderItems = getValues(sortOrderKeys)
        .map(_sortOrder => ({
            name: t(`jobQueueSort:sortOrder.${_sortOrder}`),
            value: _sortOrder
        }));

    return (
        <ListDropdown name={'sortOrder'}
            aria-label={t('aria:hiddenAssistText.sortOrder')} value={selectedSortOrder}
            items={sortOrderItems} onItemSelect={onSortOrderSelect}
            buttonStyle={{maxWidth: '20rem'}}
            {...rest}
        />
    );
}

function SortFieldDropdown(props) {
    const {selectedSortField, onSortFieldSelect, ...rest} = props;
    const {t} = useTranslation(['aria', 'jobQueueSort']);

    const sortFieldItems = getValues(sortFieldKeys)
        .map(_sortField => ({
            name: t(`jobQueueSort:sortField.${_sortField}`),
            value: _sortField
        }));

    return (
        <ListDropdown name={'sortField'}
            aria-label={t('aria:hiddenAssistText.sortField')} value={selectedSortField}
            items={sortFieldItems} onItemSelect={onSortFieldSelect}
            buttonStyle={{maxWidth: '20rem'}}
            {...rest}
        />
    );
}

export default withTranslation(['jobQueueSort'])(JobQueueSort);