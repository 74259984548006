import SettingModel from "../generics/SettingModel";

class JobWarningsModel extends SettingModel.Simple {

    static nom = 'JobWarningsModel';
    static actions = JobWarningsModel.buildActions('JOB_WARNINGS');
    static actionCreators = JobWarningsModel.buildActionCreators(JobWarningsModel.actions);
    static reducer = JobWarningsModel.buildReducer(JobWarningsModel.actions);

}

export default JobWarningsModel;