import ReduxModel from "../generics/ReduxModel";
import {actionCreator} from "../../utilities/helperFunctions";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import {put} from "redux-saga/effects";
import {contextCall, contextPollUntil} from "../../saga/sagaFunctions";
import {SLOW_QUERY_INTERVAL} from "../../utilities/constants";


class JobUserModel extends ReduxModel {

    static nom = 'JobUserModel';
    static actions = JobUserModel.buildActions();
    static actionCreators = JobUserModel.buildActionCreators(JobUserModel.actions);
    static reducer = JobUserModel.buildReducer(JobUserModel.actions);

    constructor(model = {}) {
        super(model);
        const {id, name} = model;

        this.id = id;
        this.name = name;
    }

    static buildActions() {
        return {
            // JOB USER ACTIONS
            SET_DETAILS_MAP: 'SET_JOB_USER_DETAILS_MAP',
            // POLLING ACTIONS
            START_POLLING_DETAILS: 'START_POLLING_JOB_USER_DETAILS',
            STOP_POLLING_DETAILS: 'STOP_POLLING_JOB_USER_DETAILS'
        }
    }

    static buildActionCreators(actions) {
        return {
            // JOB USER ACTION CREATORS
            setDetailsMap: actionCreator(actions.SET_DETAILS_MAP, 'details'),
            // POLLING ACTION CREATORS
            startPollingDetails: actionCreator(actions.START_POLLING_DETAILS),
            stopPollingDetails: actionCreator(actions.STOP_POLLING_DETAILS)
        }
    }

    static buildReducer(actions) {
        return function (state = new Map(), action) {
            switch (action.type) {
                case actions.SET_DETAILS_MAP: {
                    const {details} = action.payload;

                    return this.setDetailsMapGeneric(state, details, 'id');
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }
}

export class JobUserApi {

    static getUsers() {
        return axiosInstance.get('/scheduler/users/job/submitters');
    }
}

export class JobUserSaga {

    static* pollDetails() {
        yield contextPollUntil(JobUserModel.actions.STOP_POLLING_DETAILS, SLOW_QUERY_INTERVAL, this, 'queryDetails');
    }

    static* queryDetails() {
        const response = yield contextCall(JobUserApi, 'getUsers');

        if (AxiosProxy.shouldUpdate('JobUserModel', response)) {
            yield put(JobUserModel.actionCreators.setDetailsMap(response.data.map(name => ({id: name, name}))));
        }
    }
}

export default JobUserModel;