import React from "react";

import './caseExample.css'
import {trimBaseFolder} from "../../../utilities/helperFunctions";
import {useTranslation} from "react-i18next";

function CaseExample(props) {
    const {t} = useTranslation(['wizard'])
    const {baseFolder, type, text, exampleKey} = props;

    const separator = (<div className={"folder-separator"}><span> <label className={"label is-small"}>/</label> </span> <span> <hr/> </span>
        <span> <label className={"placeholder label is-small"}>a</label> </span></div>);

    return (
        <div className={"example-container"} data-name={exampleKey}>
            {type === "CLIENT_MATTER" &&
            <div>
                <div className={"base-folder"}>
                    <span>
                        <label className={"label is-small"}>{trimBaseFolder(baseFolder)}</label>
                        <hr className={"line-separator"}/>
                        <label className={"label is-small"}>{t('wizard:examples.baseFolder')}</label>
                    </span>
                </div>
                {separator}
                <div className={"suffix-text"}>
                    <span>
                        <label className={"label is-small"}>{text[0]}</label>
                        <hr className={"line-separator"}/>
                        <label className={"label is-small"}>{t('wizard:examples.clientName')}</label>
                    </span>
                </div>
                {separator}
                <div className={"suffix-text"}>
                    <span>
                        <label className={"label is-small"}>{text[1]}</label>
                        <hr className={"line-separator"}/>
                        <label className={"label is-small"}>{t('wizard:examples.matterName')}</label>
                    </span>
                </div>
            </div>
            }
            {type === "SINGLE_FOLDER" &&
            <div>
                <div className={"base-folder"}>
                    <span>
                        <label className={"label is-small"}>{trimBaseFolder(baseFolder)}</label>
                        <hr className={"line-separator"}/>
                        <label className={"label is-small"}>{t('wizard:examples.baseFolder')}</label>
                    </span>
                </div>
                {separator}
                <div className={"suffix-text"}>
                    <span>
                        <label className={"label is-small"}>{text[0]}</label>
                        <hr className={"line-separator"}/>
                        <label className={"label is-small"}>{t('wizard:examples.caseName')}</label>
                    </span>
                </div>
            </div>
            }
        </div>
    )

}

export default CaseExample;