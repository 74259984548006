import React, {Component} from 'react';
import {connect} from 'react-redux';
import ClientPoolForm from "./ClientPoolForm";
import ClientPoolTablet from "./ClientPoolTablet";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import ClientPoolTable from "./ClientPoolTable";
import {details} from "../../utilities/constants";
import {withTranslation} from "react-i18next";
import ClientPoolModel from "../../models/settings/ClientPoolModel";
import {MainPageContent, MainPageHeader} from "../app/pages/MainPage";

class ClientPoolDisplay extends Component {

    componentDidMount() {
        this.props.setDisplayActive();
    }

    componentWillUnmount() {
        this.props.setDisplayInactive();
    }

    render() {
        const {
            t,
            isLoading,
            clientPoolId,
            clientPool,
            isClientPoolFormActive,
            ...attr
        } = this.props;

        return (
            <MainPageContent {...attr}
                title={
                    <MainPageHeader text={t('clientPool:page.title')}
                        description={t('clientPool:page.description')}/>
                }
                list={
                    <LoadingWrapper isLoading={isLoading}>
                        <ClientPoolTable activeId={clientPoolId}/>
                    </LoadingWrapper>
                }
                children={
                    (clientPool && <ClientPoolTablet clientPoolId={clientPoolId} />)
                    ||
                    (isClientPoolFormActive && <ClientPoolForm />)
                }
            />
        );
    }
}

const mapStateToProps = state => {
    const clientPoolDisplay = state.componentStates.clientPoolDisplay;
    return {
        ...clientPoolDisplay,
        clientPool: state.clientPoolDetailsMap.get(clientPoolDisplay.clientPoolId),
        isLoading: !state.hasLoaded[details.CLIENT_POOLS]
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setDisplayActive: () => dispatch(ClientPoolModel.componentActionCreators.setDisplayActive()),
        setDisplayInactive: () => dispatch(ClientPoolModel.componentActionCreators.setDisplayInactive())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['clientPool'])(ClientPoolDisplay));