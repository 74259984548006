import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {details, noticeTypeKeys, updateMode} from "../../utilities/constants";
import React, {useCallback, useEffect, useState} from "react";
import SchedulerModel from "../../models/scheduler/SchedulerModel";
import {contextCall} from "../../saga/sagaFunctions";
import UserNoticeModel, {UserNoticeApi, UserNoticeSaga} from "../../models/notice/UserNoticeModel";
import {createInputHandler} from "../../utilities/componentFunctions";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import {
    CheckedDropdown,
    ClientCheckedDropdown,
    LegalHoldCheckedDropdown,
    MatterCheckedDropdown
} from "../common/Dropdown/Dropdown";
import {Button} from "../common/Button/Button";
import SearchBar from "../common/SearchBar/SearchBar";
import {useFilterDropdownClearHandler, useFilterDropdownHandler} from "../common/Dropdown/helpers";
import {UserSelectableItem} from "../common/SelectableItem/SelectableItem";
import {getEntries, getValues} from "../../utilities/helperFunctions";
import {noticeStateFilterKeys} from "../../i18next/keys";


function LegalHoldNoticeFilterHeader() {
    const {t} = useTranslation(['legalHold', 'aria']);
    const dispatch = useDispatch();

    const isLoading = useSelector(state => !state.hasLoaded[details.USER_NOTICES]);
    const {
        searchText,
        userIds,
        fromDate,
        toDate,
        viewCount,
        clientIds,
        matterIds,
        legalHoldIds,
        noticeTypes,
        noticeStateFilter
    } = useSelector(state => state.componentStates.legalHoldNoticeDisplay);

    useEffect(() => {
        dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(
            contextCall(UserNoticeSaga, 'queryDetails')
        ));
    }, [dispatch]);

    const updateState = useCallback(updates =>
            dispatch(UserNoticeModel.componentActionCreators.updateLegalHoldNoticeDisplay(updates))
        , [dispatch]);

    const inputHandler = createInputHandler({
        mode: updateMode.REDUX,
        updateState
    });

    const onToggle = useFilterDropdownHandler({
        updateState
    });

    const onClear = useFilterDropdownClearHandler({
        updateState
    });


    return (
        <>
            <div className="header-section left">
                <div className="header-item">
                    <label htmlFor="fromDate" className="label" style={{marginRight: '0.5rem'}}>
                        {t('common:label.from')}:
                    </label>
                    <HTMLTextInput id="fromDate" name={'fromDate'} value={fromDate} onChange={inputHandler}
                        type={'date'} isDisabled={isLoading}
                    />
                </div>

                <div className="header-item">
                    <label htmlFor="toDate" className="label" style={{marginRight: '0.5rem'}}>
                        {t('common:label.to')}:
                    </label>
                    <HTMLTextInput id="toDate" name={'toDate'} value={toDate} onChange={inputHandler}
                        type={'date'} isDisabled={isLoading}
                    />
                </div>

                <div className="header-item">
                    <label htmlFor="viewCount" className="label" style={{marginRight: '0.5rem'}}>
                        {t('common:label.viewCount')}:
                    </label>
                    <HTMLTextInput id="viewCount" name={'viewCount'} value={viewCount} onChange={inputHandler}
                        type={'number'} max={1000} containerStyle={{width: '5rem'}} isDisabled={isLoading}
                    />
                </div>

                <div className="header-item">
                    <NoticeUsersCheckedDropdown userIds={userIds} isDisabled={isLoading}
                        onToggle={onToggle} onClear={onClear}
                    />
                </div>

                <div className="header-item">
                    <ClientCheckedDropdown clientIds={clientIds} isDisabled={isLoading}
                        onToggle={onToggle} onClear={onClear}
                    />
                </div>

                <div className="header-item">
                    <MatterCheckedDropdown matterIds={matterIds} clientIds={clientIds} isDisabled={isLoading}
                        updateState={updateState} onToggle={onToggle} onClear={onClear}
                    />
                </div>

                <div className="header-item">
                    <LegalHoldCheckedDropdown legalHoldIds={legalHoldIds} matterIds={matterIds} isDisabled={isLoading}
                        updateState={updateState} onToggle={onToggle} onClear={onClear}
                    />
                </div>

                <div className="header-item">
                    <NoticeTypeCheckedDropdown noticeTypes={noticeTypes} isDisabled={isLoading}
                        updateState={updateState} onToggle={onToggle} onClear={onClear}
                    />
                </div>

                <div className="header-item">
                    <NoticeStateFilterCheckedDropdown noticeStateFilter={noticeStateFilter} isDisabled={isLoading}
                        updateState={updateState} onToggle={onToggle} onClear={onClear}
                    />
                </div>

                <div className="header-item">
                    <Button label={t('common:label.refresh')} isDisabled={isLoading}
                        onClick={() => dispatch(UserNoticeModel.actionCreators.queryDetails())}
                    />
                </div>
            </div>

            <div className="header-section right">
                <div className="header-item">
                    <SearchBar value={searchText} onChange={inputHandler} isDisabled={isLoading}/>
                </div>
            </div>
        </>
    )
}

function NoticeUsersCheckedDropdown(props) {
    const {t} = useTranslation(['job']);

    const {
        userIds,
        ...rest
    } = props;

    const [noticeUsers, setNoticeUsers] = useState([]);
    // Query for archivedUsers (users whose job is archived)
    useEffect(() => {
        UserNoticeApi.getUsers()
            .then(res => {
                setNoticeUsers(res.data);
            })
            .catch(error => {
                dispatch(SchedulerModel.actionCreators.handleResponseError(error));
            })
    }, []);

    const noticeUserItems = noticeUsers.map(user => ({
        name: user.name,
        value: user.id,
        isChecked: !!userIds[user.id]
    }))

    return (
        <CheckedDropdown name={'userIds'} aria-label={t('aria:hiddenAssistText.users')}
            noneSelectedMessage={t('job:option.allUsers')} items={noticeUserItems} ItemComponent={UserSelectableItem} {...rest}
        />
    )
}

function NoticeTypeCheckedDropdown(props) {
    const {t} = useTranslation(['aria', 'noticeTemplate']);

    const {
        name='noticeTypes',
        noticeTypes,
        ...rest
    } = props;

    const noticeTypeItems = getValues(noticeTypeKeys)
        .map(noticeType => ({
            name: t(`noticeTemplate:type.${noticeType}`),
            value: noticeType,
            isChecked: !!noticeTypes[noticeType]
        }));

    return (
        <CheckedDropdown name={name} aria-label={t('aria:hiddenAssistText.noticeTypeChecked')} noneSelectedMessage={t('noticeTemplate:option.allTypes')}
            items={noticeTypeItems} {...rest}
        />
    )
}

function NoticeStateFilterCheckedDropdown(props) {
    const {t} = useTranslation(['aria', 'noticeTemplate']);

    const {
        name='noticeStateFilter',
        noticeStateFilter,
        ...rest
    } = props;

    const noticeStateFilterItems = getEntries(noticeStateFilterKeys)
        .map(([key, noticeState]) => ({
            name: t(`noticeTemplate:state.${noticeState}`),
            value: key,
            isChecked: !!noticeStateFilter[key]
        }));

    return (
        <CheckedDropdown name={name} aria-label={t('aria:hiddenAssistText.noticeStateFilterChecked')} noneSelectedMessage={t('noticeTemplate:option.allStates')}
            items={noticeStateFilterItems} {...rest}
        />
    )
}

export default LegalHoldNoticeFilterHeader;
