import React from "react";
import Text from "../../common/Text/Text";
import {buildClassName, shallowCopy} from "../../../utilities/helperFunctions";
import FormBuilder from "./FormBuilder";

function ObjectField(props) {
    const {
        id,
        formKey,
        configuration,

        'data-type': dataType,
        label,
        value,
        updateState,

        root,
        fixed,
        autoFocus,
        isRequired,
        isDisabled
    } = props;

    const className = buildClassName(
        'workflow-builder__object-field',
        root && 'root',
        fixed && 'fixed-table',
        value._invalid && 'is-invalid'
    );

    return (
        <>
            {label &&
                <div className="workflow-builder__field-group-label">
                    <Text value={label} isDisabled={isDisabled}/>
                </div>
            }

            <div id={id} data-type={dataType} className={className}>
                <FormBuilder id={id} autoFocus={autoFocus} formKey={formKey} formConfiguration={configuration.formConfiguration}
                    state={value} updateState={updateState} isRequired={isRequired} isDisabled={isDisabled}/>
            </div>
        </>
    )
}

export function ObjectFieldWrapper(props) {
    const {
        configuration,
        updateState
    } = props;

    function updateObject(updates, {clear = false} = {}) {
        updateState(prevState => {
            const prev = prevState[configuration.name];
            const update = typeof updates === 'function' ? updates(prev) : updates;
            return {
                [configuration.name]: clear ? update : shallowCopy(prev, update)
            }
        });
    }

    return (
        <ObjectField {...props} updateState={updateObject}/>
    )
}

export default ObjectField;
