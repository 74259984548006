import React from "react";
import {useTranslation} from "react-i18next";
import {TableLabelCell, TableLabelHeader} from "../../common/CustomTable/CustomTable";
import ThirdPartyServiceModel from "../../../models/thirdparty/ThirdPartyServiceModel";
import ThirdPartyServiceTable from "../ThirdPartyServiceTable";

function RelativityServiceTable(props) {
    return (
        <ThirdPartyServiceTable {...props} type={ThirdPartyServiceModel.Type.RELATIVITY} showMethod showService
            TableHeaders={RelativityServiceTableHeaders} TableRowCells={RelativityServiceTableRowCells}
        />
    )
}

function RelativityServiceTableHeaders() {
    const {t} = useTranslation(['thirdPartyService']);
    return (
        <>
            <TableLabelHeader label={t('thirdPartyService:label.hostname')}/>
            <TableLabelHeader label={t('thirdPartyService:label.relativityRestVersion')}/>
        </>
    )
}

function RelativityServiceTableRowCells(props) {
    const {t} = useTranslation(['thirdPartyService'])
    const {
        thirdPartyService
    } = props;

    return (
        <>
            <TableLabelCell label={thirdPartyService.hostname}/>
            <TableLabelCell label={t(`thirdPartyService:relativityRestVersion.${thirdPartyService.relativityRestVersion}`)}/>
        </>
    )
}

export default RelativityServiceTable;
