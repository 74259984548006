class Folder {

    constructor(source, relativePath) {
        this.source = source;
        this.id = `folder_upload_${relativePath}`
        this.name = relativePath;
        this.extension = '';
        this.type = 'folder';
        this.meta = {
            name: relativePath,
            type: 'folder',
            relativePath
        };

        this.files = [];
        this.data = {
            type: 'folder',
            name: relativePath,
            webKitRelativePath: relativePath,
            size: 0
        };

        this.size = 0;
        this.progress = {
            bytesTotal: 0,
            bytesUploaded: 0,
            percentage: 0,
            // &&= ANDs every file to get completion
            uploadComplete: true,
            uploadStarted: null
        };

        this.preview = undefined;
        this.isRemote = false;
        this.remote = '';
    }

    addFile(file) {
        this.files.push(file);
        this.size += file.size;

        this.data.size += file.size;
        if (this.data.lastModified < file.data.lastModified) {
            this.data.lastModified = file.data.lastModified;
            this.data.lastModifiedDate = file.data.lastModifiedDate;
        }

        this.progress.bytesTotal += file.progress.bytesTotal;
        this.progress.bytesUploaded += file.progress.bytesUploaded;

        if (this.progress.bytesTotal > 0) {
            this.progress.percentage = Math.floor(this.progress.bytesUploaded * 100 / this.progress.bytesTotal);
        }

        this.progress.uploadComplete &&= file.progress.uploadComplete;
        this.progress.uploadStarted ||= file.progress.uploadStarted;
        this.progress.preprocess ||= file.progress.preprocess;
        this.progress.postprocess ||= file.progress.postprocess;

        // Unknown uppy props
        this.preview = file.preview;
        this.remote = file.remote;
        this.isRemote = file.isRemote;
        this.isGhost ||= file.isGhost;
    }

    forEachFile(func) {
        for (const file of this.files) {
            func(file);
        }
    }

    calculate() {
        const filesInProgress = this.files.filter(file => file.progress.uploadStarted && !file.progress.uploadComplete);
        this.isPaused = filesInProgress.length > 0 && filesInProgress.every(file => file.isPaused);

        if (this.progress.uploadStarted && this.progress.uploadComplete) {
            const firstErroredFile = this.files.find(file => file.error);
            if (firstErroredFile != null) {
                this.error = firstErroredFile.error;
            }
        }
    }
}

export default Folder;