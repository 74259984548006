import React from "react";
import ThirdPartyServiceModel from "../../../models/thirdparty/ThirdPartyServiceModel";
import ThirdPartyServiceForm, {ThirdPartyServiceFormBody} from "../ThirdPartyServiceForm";
import {useTranslation} from "react-i18next";
import ExpandableContent from "../../common/ExpandableContent/ExpandableContent";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";

const initialState = {
    type: ThirdPartyServiceModel.Type.SEMANTIC,
    serviceName: '',
    enabled: true,
    description: '',
    whitelistedCertFingerprints: [],

    url: '',

    authenticationScope: ThirdPartyServiceModel.AuthenticationScope.SERVICE,
    authenticationMethod: ThirdPartyServiceModel.AuthenticationMethod.NONE,
    authenticationServiceId: null
};

const allowedAuthenticationMethods = [
    ThirdPartyServiceModel.AuthenticationMethod.NONE
];
const allowedAuthenticationScopes = [
    ThirdPartyServiceModel.AuthenticationScope.SERVICE
];

function SemanticServiceForm() {
    return (
        <ThirdPartyServiceForm initialState={initialState} FormBody={SemanticServiceFormBody}/>
    )
}

export function SemanticServiceFormBody(props) {
    return (
        <ThirdPartyServiceFormBody {...props} allowedAuthenticationMethods={allowedAuthenticationMethods}
            allowedAuthenticationScopes={allowedAuthenticationScopes} FormBody={FormBody}/>
    )
}

function FormBody(props) {
    const {t} = useTranslation(['thirdPartyService', 'common']);
    const {
        thirdPartyService,

        inputHandler,
        isDisabled
    } = props;

    return (
        <ExpandableContent label={t('thirdPartyService:label.settings')} isDisabled={isDisabled}>
            <div className="display-input">
                <HTMLTextInput label={t('thirdPartyService:label.url')} name={'url'} value={thirdPartyService.url}
                    onChange={inputHandler} isDisabled={isDisabled} isRequired/>
            </div>
        </ExpandableContent>
    );
}

export default SemanticServiceForm;
