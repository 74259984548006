import React from "react";
import ThirdPartyServiceModel from "../../../models/thirdparty/ThirdPartyServiceModel";
import ThirdPartyServiceTable from "../ThirdPartyServiceTable";
import {useTranslation} from "react-i18next";
import {TableLabelCell, TableLabelHeader} from "../../common/CustomTable/CustomTable";

function SemanticServiceTable(props) {
    return (
        <ThirdPartyServiceTable {...props} type={ThirdPartyServiceModel.Type.SEMANTIC} hideScope
                                TableHeaders={SemanticServiceTableHeaders} TableRowCells={SemanticServiceTableRowCells}
        />
    )
}

function SemanticServiceTableHeaders() {
    const {t} = useTranslation(['thirdPartyService']);
    return (
        <>
            <TableLabelHeader label={t('thirdPartyService:label.url')}/>
        </>
    )
}

function SemanticServiceTableRowCells(props) {
    const {
        thirdPartyService
    } = props;

    return (
        <>
            <TableLabelCell label={thirdPartyService.url}/>
        </>
    )
}

export default SemanticServiceTable;
