import React, {useCallback, useLayoutEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {applicationFeatures, details, jobStates} from "../../utilities/constants";
import GuidedJobModel from "../../models/guidedjob/GuidedJobModel";
import PurviewGuidedJobForm from "./PurviewGuidedJobForm";
import {objEquals} from "../../utilities/helperFunctions";
import {selectFilteredQueueJobs} from "../job/JobQueueDisplay";
import {selectDetailsMapForJobNames} from "../job/selectors";
import JobQueueHeader from "../job/JobQueueHeader";
import JobTablet from "../job/JobTablet";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import JobModel from "../../models/job/JobModel";
import ParameterModel from "../../models/library/ParameterModel";
import {TableLabelCell, TableLabelHeader, TableRow} from "../common/CustomTable/CustomTable";
import Text from "../common/Text/Text";
import {JobStatusIcons} from "../userSettings/JobCardDesigner/helpers";
import JobPurviewModel from "../../models/purview/JobPurviewModel";

function usePurviewJobs() {
    const jobQueueDisplay = useSelector(state => state.componentStates.jobQueueDisplay);
    const detailsMap = useSelector(state => ({
        job: state.jobDetailsMap,
        ...selectDetailsMapForJobNames(state),
    }), objEquals);

    const jobs = selectFilteredQueueJobs({detailsMap, jobQueueDisplay});
    const jobIdsToArchive = [];
    const purviewJobs = jobs.filter(job => {
        if (Array.isArray(job.sessionParameters)) {
            if (job.sessionParameters.some(param => param.parameterType === ParameterModel.Type.PURVIEW_SERVICE)) {
                if (jobStates.completed.includes(job.executionState)) {
                    jobIdsToArchive.push(job.id);
                }
                return true;
            }
        }
    });
    purviewJobs.sort((p1, p2) => p2.submittedDateUtc - p1.submittedDateUtc);
    return [purviewJobs, jobIdsToArchive];
}

function PurviewJobDisplay() {
    const {t} = useTranslation(['guidedJob', 'common']);
    const dispatch = useDispatch();

    const [purviewJobs, jobsToArchive] = usePurviewJobs();
    const activeJob = useSelector(state => state.jobDetailsMap.get(state.componentStates.jobPage.jobId));

    const isLoading = useSelector(state => !state.hasLoaded[details.QUEUE_JOBS]);
    const isGuidedJobFormActive = useSelector(state => state.guidedJobForm.isActive);

    // Active display
    useLayoutEffect(() => {
        dispatch(JobPurviewModel.componentActionCreators.setDisplayActive());
        return () => dispatch(JobPurviewModel.componentActionCreators.setDisplayInactive());
    }, []);

    const showGuidedForm = useCallback(() => {
        dispatch(GuidedJobModel.actionCreators.open());
    }, []);

    const showJobTablet = useCallback(event => {
        const {value} = event.currentTarget.dataset;
        dispatch(JobModel.actionCreators.showTablet(value));
    }, []);


    return (
        <>
            <section className="mainPage-header">
                <JobQueueHeader text={t(`guidedJob:label.purviewJob`)} title={t(`aria:hiddenAssistText.addGuidedPurviewJob`)}
                    canQueueFeatures={[applicationFeatures.ADD_PURVIEW_JOB]} showJobForm={showGuidedForm}
                    jobsToArchive={jobsToArchive} isLoading={isLoading}/>
            </section>

            <section className="mainPage-content" id={'purviewJobDisplay'}>
                <LoadingWrapper isLoading={isLoading}>
                    <div className="display-table">
                        <div className="table-header-group">
                            <TableLabelHeader label={t('common:label.name')}/>

                            <TableLabelHeader label={t('guidedJob:label.submittedDate')} style={{width: '8rem'}}/>

                            <TableLabelHeader label={t('guidedJob:label.startDate')} style={{width: '8rem'}}/>

                            <TableLabelHeader label={t('guidedJob:label.endDate')} style={{width: '8rem'}}/>

                            <TableLabelHeader label={t('common:label.state')} style={{width: '6rem'}}/>
                        </div>

                        <div className="table-row-group">
                            {Array.isArray(purviewJobs) && purviewJobs.map(job =>
                                <PurviewJobRow key={job.id} job={job} onClick={showJobTablet}
                                    isActive={job.id === activeJob?.id}/>
                            )}
                        </div>
                    </div>
                </LoadingWrapper>
            </section>

            {isGuidedJobFormActive &&
                <PurviewGuidedJobForm/>
            }

            {!!activeJob &&
                <JobTablet jobId={activeJob?.id}/>
            }
        </>
    )
}

function PurviewJobRow(props) {
    const {
        job,
        isActive,
        onClick
    } = props;

    return (
        <TableRow value={job.id} isActive={isActive} onClick={onClick}>
            <div className="table-cell">
                <Text value={job.name} isWordWrap isBold/>
                <Text value={job.notes} isWordWrap/>
            </div>

            <TableLabelCell label={job.submittedDate}/>
            <TableLabelCell label={job.startedDate}/>
            <TableLabelCell label={job.finishedDate}/>

            <div className="table-cell">
                <JobStatusIcons {...job} size={'small'}/>
            </div>
        </TableRow>
    )
}

export default PurviewJobDisplay;