import React, {useCallback, useEffect, useRef, useState} from "react";
import './Panel.css';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import SelectPanel, {ClientSelectPanel} from "./SelectPanel";
import {
    applicationFeatures,
    CLIENT_UNASSIGNED,
    details,
    MIN_SIZE_FOR_SEARCH,
    SAME_AS_TRIGGERING_DATASET,
    SAME_AS_TRIGGERING_JOB,
    SAME_AS_TRIGGERING_LEGAL_HOLD
} from "../../utilities/constants";
import ClientModel from "../../models/client/ClientModel";
import SearchBar from "../common/SearchBar/SearchBar";
import {getObjectText, getValues} from "../../utilities/helperFunctions";
import Switch from "../common/Switch/Switch";
import {permissionKeys} from "../../i18next/keys";

export function RestrictedClientPanel(props) {
    const {
        requireSubmitJob
    } = props;

    const clientSelector = useCallback(state => {
        return getValues(state.clientDetailsMap)
            .filter(client => client.enabled
                && (!requireSubmitJob || client.userPermissions.includes(permissionKeys.SUBMIT_JOB)));
    }, [requireSubmitJob]);

    return (
        <ClientPanel {...props} clientSelector={clientSelector}/>
    )
}

function ClientPanel(props) {
    const {clientId, clientSelector, updateState, canSetSameAs={}, isDisabled} = props;
    const {t} = useTranslation(['client', 'jobSchedule']);

    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');

    const clients = useSelector(clientSelector);
    const isLoading = useSelector(state => !state.hasLoaded[details.CLIENTS]);
    const canAddUnassigned = props.canAddUnassigned && useSelector(state => state.currentUser.features.includes(applicationFeatures.ADD_JOB_UNASSIGNED_CLIENT));

    // Query clients
    useEffect(() => {
        dispatch(ClientModel.actionCreators.queryDetails());
    }, [dispatch]);

    // Select Client if it's the only one (and no client is selected)
    useEffect(() => {
        if (clientId == null && clients.length === 1) {
            const client = clients[0];
            updateState({clientId: client.id, matterId: null});
        }
    }, [clientId, clients, updateState]);

    useEffect(() => {
        if (canSetSameAs.dataset) {
            updateState({clientId: SAME_AS_TRIGGERING_DATASET, matterId: null});
        }
        if (canSetSameAs.legalHold) {
            updateState({clientId: SAME_AS_TRIGGERING_LEGAL_HOLD, matterId: null});
        }
    }, [canSetSameAs.dataset, canSetSameAs.legalHold]);

    const scrollDivRef = useRef();
    // Scroll to selected client IFF exists
    useEffect(() => {
        if (!isLoading && scrollDivRef.current != null) {
            const activePanel = scrollDivRef.current.getElementsByClassName('is-active')[0];

            if (activePanel != null) {
                activePanel.scrollIntoView();
            }
        }
    }, [isLoading]);

    function clientSelect(event) {
        const {value} = event.currentTarget.dataset;
        if (value !== clientId) {
            updateState({clientId: value, matterId: null});
        }

        setTimeout(() => {
            document.getElementById(`${value}_TABPANEL`)?.focus();
        }, 50);
    }

    const canSearchClients = clients.length >= MIN_SIZE_FOR_SEARCH;
    const unassignedTitle = t('client:option.unassigned');
    const sameAsTriggeringJobTitle = t(`jobSchedule:option.${SAME_AS_TRIGGERING_JOB}`);
    const sameAsTriggeringDatasetTitle = t(`jobSchedule:option.${SAME_AS_TRIGGERING_DATASET}`);
    const sameAsTriggeringLegalHoldTitle = t(`jobSchedule:option.${SAME_AS_TRIGGERING_LEGAL_HOLD}`);

    return (
        <aside className="selectPane-panel" role="tablist" tabIndex={-1}>
            <LoadingWrapper isLoading={isLoading}>

                {canSearchClients &&
                <SearchBar id="clientSearchBar" style={{margin: '0.5rem'}}
                    value={searchText} onChange={event => setSearchText(event.target.value)}
                />
                }

                <Switch>
                    {canSetSameAs.dataset &&
                        <SelectPanel label={sameAsTriggeringDatasetTitle} value={SAME_AS_TRIGGERING_DATASET}
                            onPanelSelect={clientSelect}
                            isActive={clientId === SAME_AS_TRIGGERING_DATASET} isItalic isDisabled={isDisabled}
                        />
                    }
                    {canSetSameAs.legalHold &&
                        <SelectPanel label={sameAsTriggeringLegalHoldTitle} value={SAME_AS_TRIGGERING_LEGAL_HOLD}
                            onPanelSelect={clientSelect}
                            isActive={clientId === SAME_AS_TRIGGERING_LEGAL_HOLD} isItalic isDisabled={isDisabled}
                        />
                    }
                    <section style={{overflowY: 'auto'}} ref={scrollDivRef}>
                        {clients
                            .filter(_client => getObjectText(_client, {whitelist: ['name']}).includes(searchText.toLowerCase()))
                            .map(({id}) =>
                                <ClientSelectPanel key={id} clientId={id} onPanelSelect={clientSelect}
                                    isActive={id === clientId} isDisabled={isDisabled}
                                />
                            )}

                        {canAddUnassigned && unassignedTitle.toLowerCase().includes(searchText.toLowerCase()) &&
                        <SelectPanel label={unassignedTitle} value={CLIENT_UNASSIGNED} onPanelSelect={clientSelect}
                            isActive={clientId === CLIENT_UNASSIGNED} isItalic isDisabled={isDisabled}
                        />
                        }

                        {canSetSameAs.job && sameAsTriggeringJobTitle.toLowerCase().includes(searchText.toLowerCase()) &&
                        <SelectPanel label={sameAsTriggeringJobTitle} value={SAME_AS_TRIGGERING_JOB} onPanelSelect={clientSelect}
                            isActive={clientId === SAME_AS_TRIGGERING_JOB} isItalic isDisabled={isDisabled}
                        />
                        }
                    </section>
                </Switch>
            </LoadingWrapper>
        </aside>
    )
}

export default ClientPanel;