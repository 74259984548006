import {
    actionCreator,
    arrayIntoBoolObject,
    boolObjectToArray,
    deepCopy,
    getInputFormattedDateAndTime,
    getLocaleDateTimeFromUTC,
    getProtectedValue,
    getUtcFromFormattedDate,
    getValues,
    isAllTruthy,
    isNotEmptyNorFalsy,
    objEquals,
    postProtectedValue,
    switchcase
} from "../../utilities/helperFunctions";
import {
    datasetEventTriggerKeys,
    jobEventTriggerKeys,
    jobPageViewKeys,
    jobSubmissionMechanismKeys,
    legalHoldEvenTriggerKeys,
    popupInfoKeys,
    priorityKeys,
    recurOnDayKeys,
    scheduleFrequencyKeys,
    scheduleTriggerTypeKeys
} from "../../i18next/keys";
import ComponentStateModel from "../generics/ComponentStateModel";
import {all, call, put, race, select, take, takeEvery, takeLatest} from "redux-saga/effects";
import DetailsModel, {DetailsApi, DetailsSaga} from "../generics/DetailsModel";
import {
    CLIENT_UNASSIGNED,
    details,
    httpVerb,
    routes,
    SAME_AS_TRIGGERING_DATASET,
    SAME_AS_TRIGGERING_JOB,
    SAME_AS_TRIGGERING_LEGAL_HOLD
} from "../../utilities/constants";
import {
    contextCall,
    contextSaga,
    contextTakeLatest,
    contextTakeLeading,
    isDisabledWrapper
} from "../../saga/sagaFunctions";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import ParameterModel from "../library/ParameterModel";
import {WorkflowTemplateSaga} from "../library/WorkflowTemplateModel";
import JobModel, {JobApi, JobSaga} from "./JobModel";
import {retryCall, tryCatchWrapper} from "../../saga/tryCatchWrapper";
import EditModel from "../scheduler/EditModel";
import SchedulerModel from "../scheduler/SchedulerModel";
import PopupModel from "../scheduler/PopupModel";
import {validateParameters} from "../../utilities/shouldEnableFunctions";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import JobScheduleRunModel from "./JobScheduleRunModel";
import Encrypto from "../../utilities/encrypto";

class JobScheduleModel extends DetailsModel {

    static nom = 'JobScheduleModel';
    static actions = JobScheduleModel.buildActions('JOB_SCHEDULE');
    static actionCreators = JobScheduleModel.buildActionCreators(JobScheduleModel.actions);
    static reducer = JobScheduleModel.buildReducer(JobScheduleModel.actions);

    static componentActionCreators = {
        ...JobScheduleModel.buildComponentUpdateActionCreators(),
        ...JobScheduleModel.buildComponentSetActiveActionCreators()
    };

    constructor(model = {}) {
        super(model);
        this.conditions = new JobScheduleModel.Conditions(model.conditions || {});
        this.scheduleTrigger = new JobScheduleModel.ScheduleTrigger(model.scheduleTrigger || {});
        this.eventTrigger = new JobScheduleModel.EventTrigger(model.eventTrigger || {});
        this.webhookTrigger = new JobScheduleModel.WebhookTrigger(model.webhookTrigger || {});

        if (isNotEmptyNorFalsy(model.scheduleTrigger)) {
            this.scheduleTriggerType = scheduleTriggerTypeKeys.ON_SCHEDULE;
        } else if (isNotEmptyNorFalsy(this.eventTrigger.jobEvents)) {
            this.scheduleTriggerType = scheduleTriggerTypeKeys.ON_JOB_EVENT;
        } else if (isNotEmptyNorFalsy(this.eventTrigger.datasetEvents)) {
            this.scheduleTriggerType = scheduleTriggerTypeKeys.ON_DATASET_EVENT;
        } else if (isNotEmptyNorFalsy(this.eventTrigger.legalHoldEvents)) {
            this.scheduleTriggerType = scheduleTriggerTypeKeys.ON_LEGAL_HOLD_EVENT;
        } else if (isNotEmptyNorFalsy(this.webhookTrigger)) {
            this.scheduleTriggerType = scheduleTriggerTypeKeys.ON_WEBHOOK_TRIGGER;
        }

        this.reference = model.reference;
        this.createdBy = model.createdBy;
        this.createdDate = getLocaleDateTimeFromUTC(model.createdDate);
        this.lastModifiedBy = model.lastModifiedBy;
        this.lastModifiedDate = getLocaleDateTimeFromUTC(model.lastModifiedDate);

        this.sameMatterAsTriggerMatter = model.sameMatterAsTriggerMatter;
        this.matterId = model.matterId;
        this.clientId = model.clientId;
        this.sameExecutionProfileAsTriggerMatter = model.sameExecutionProfileAsTriggerMatter;
        this.executionProfileId = model.executionProfileId;
        this.sameResourcePoolIdAsTriggerMatter = model.sameResourcePoolIdAsTriggerMatter;
        this.resourcePoolId = model.resourcePoolId;
        this.samePriorityAsTriggerMatter = model.samePriorityAsTriggerMatter;
        this.priority = model.priority;
        this.sameWorkflowTemplateAsTriggerMatter = model.sameWorkflowTemplateAsTriggerMatter;
        this.workflowTemplateId = model.libraryWorkflowId;
        this.libraryId = model.libraryId;
        this.sessionParameters = ParameterModel.buildParametersMap(model.sessionParameters);
    }

    static EventTrigger = class EventTrigger {
        constructor(model = {}) {
            this.jobEvents = model.jobEvents;
            this.datasetEvents = model.datasetEvents;
            this.legalHoldEvents = model.legalHoldEvents;
            this.triggerFilter = new JobScheduleModel.TriggerFilter(model.triggerFilter);
        }
    };

    static TriggerFilter = class TriggerFilter {
        constructor(model = {}) {
            this.jobNameContains = model.jobNameContains;
            this.jobDescriptionContains = model.jobDescriptionContains;
            this.clientNameContains = model.clientNameContains;
            this.matterNameContains = model.matterNameContains;
            this.datasetNameContains = model.datasetNameContains;
            this.dataRepositoryNameContains = model.dataRepositoryNameContains;
            this.legalHoldNameContains = model.legalHoldNameContains;
            this.legalHoldDescriptionContains = model.legalHoldDescriptionContains;
            this.libraryNameContains = model.libraryNameContains;
            this.workflowNameContains = model.workflowNameContains;
            this.errorTextContains = model.errorTextContains;
            this.priorityAnyOf = model.priorityAnyOf;
            this.submissionMechanisms = model.submissionMechanisms;
        }
    };

    static ScheduleTrigger = class ScheduleTrigger {
        constructor(model = {}) {
            this.startDate = getLocaleDateTimeFromUTC(model.startDate);
            this.frequency = model.frequency;
            this.recurEvery = model.recurEvery;
            this.addNextJobToStaging = model.addNextJobToStaging;
            this.recurOnDays = [];

            // Sort return days
            if (Array.isArray(model.recurOnDays)) {
                this.recurOnDays = getValues(recurOnDayKeys)
                    .reduce((prev, day) => {
                        if (model.recurOnDays.includes(day)) {
                            prev.push(day);
                        }
                        return prev;
                    }, []);
            }
        }
    };

    static Conditions = class Conditions {
        constructor(model={}) {
            this.expireAfter = getLocaleDateTimeFromUTC(model.expireAfter);
            this.commenceAfter = getLocaleDateTimeFromUTC(model.commenceAfter);
            this.skipIfJobsRunning = model.skipIfJobsRunning;
            this.skipIfJobsQueued = model.skipIfJobsQueued;
        }
    };

    static WebhookTrigger = class WebhookTrigger {
        constructor(model = {}) {
            this.httpVerb = model.httpVerb;
            this.signatureKey = model.signatureKey;
            this.useSignatureKey = this.httpVerb !== httpVerb.GET && this.signatureKey !== '';
            this.url = model.url;
        }
    };

    static buildActions(type) {
        return {
            ...super.buildActions(type),
            // JOB SCHEDULE ACTIONS
            SAVE_EDIT: 'SAVE_SCHEDULE_EDIT',
            CANCEL_EDIT: 'CANCEL_SCHEDULE_EDIT',

            ENCRYPT_PROTECTED_VALUES: 'ENCRYPT_PROTECTED_SCHEDULE_VALUES',
            DECRYPT_PROTECTED_VALUES: 'DECRYPT_PROTECTED_SCHEDULE_VALUES',

            SET_SAME_AS_JOB_DEFAULTS: 'SET_SAME_AS_JOB_DEFAULTS',
            PREPARE_GET_OPERATIONS_AND_PARAMETERS_SAGA: 'SCHEDULE_PREPARE_GET_OPERATIONS_AND_PARAMETERS_SAGA',
            GET_OPERATIONS_AND_PARAMETERS: 'SCHEDULE_GET_OPERATIONS_AND_PARAMETERS',

            UPDATE_PARAMETER: 'UPDATE_SCHEDULE_PARAMETER',
            LOAD_TSV_PARAMETERS: 'SCHEDULE_LOAD_TSV_PARAMETERS',
            RESET_PARAMETERS: 'SCHEDULE_RESET_PARAMETERS'
        }
    }

    static buildActionCreators(actions) {
        return {
            ...super.buildActionCreators(actions),
            // JOB SCHEDULE ACTIONS
            showForm: actionCreator(actions.SHOW_FORM, 'initialState'),
            hideForm: actionCreator(actions.HIDE_FORM, 'position'),

            saveEdit: actionCreator(actions.SAVE_EDIT),
            cancelEdit: actionCreator(actions.CANCEL_EDIT),

            encryptProtectedValues: actionCreator(actions.ENCRYPT_PROTECTED_VALUES, 'type', 'values'),
            decryptProtectedValues: actionCreator(actions.DECRYPT_PROTECTED_VALUES, 'type'),

            setSameAsJobDefaults: actionCreator(actions.SET_SAME_AS_JOB_DEFAULTS),
            prepareGetOperationsAndParametersSaga: actionCreator(actions.PREPARE_GET_OPERATIONS_AND_PARAMETERS_SAGA),
            getOperationsAndParameters: actionCreator(actions.GET_OPERATIONS_AND_PARAMETERS),

            updateParameter: actionCreator(actions.UPDATE_PARAMETER, 'name', 'value', 'displayable'),
            loadTsvParameters: actionCreator(actions.LOAD_TSV_PARAMETERS, 'tsvText'),
            resetParameters: actionCreator(actions.RESET_PARAMETERS)
        }
    }

    static getAllowedPaneNavigations(opts) {
        const {
            position,
            oldWorkflowTemplateId,

            scheduleName,
            scheduleTriggerType,
            scheduleTrigger,
            eventTrigger,
            webhookTrigger,

            parameters,
            clientId,
            matterId,
            libraryId,
            workflowTemplateId,
            isLoadingClientMatters,
            isLoadingLibraryWorkflows,
            isLoadingSettings,
            isLoading
        } = opts;

        const {jobEvents, datasetEvents, legalHoldEvents, legalHoldName, legalHoldDescription, datasetName, dataRepositoryName, ...otherFilters} = eventTrigger;
        const areParametersSatisfied = (position == null || workflowTemplateId === oldWorkflowTemplateId || position > 3) && validateParameters(parameters);

        const eventTriggerType = {
            job: scheduleTriggerType === scheduleTriggerTypeKeys.ON_JOB_EVENT,
            dataset: scheduleTriggerType === scheduleTriggerTypeKeys.ON_DATASET_EVENT,
            legalHold: scheduleTriggerType === scheduleTriggerTypeKeys.ON_LEGAL_HOLD_EVENT
        };

        return {
            triggerPane: !!scheduleName,

            clientPane: switchcase({
                [scheduleTriggerTypeKeys.ON_SCHEDULE]: (scheduleTrigger.frequency !== scheduleFrequencyKeys.WEEKLY) || (isNotEmptyNorFalsy(scheduleTrigger.recurOnDay)),
                [scheduleTriggerTypeKeys.ON_JOB_EVENT]: isNotEmptyNorFalsy(jobEvents) && getValues(otherFilters)
                    .filter(flter => flter.enabled).every(({enabled, ...rest}) => isNotEmptyNorFalsy(rest)),
                [scheduleTriggerTypeKeys.ON_DATASET_EVENT]: isNotEmptyNorFalsy(datasetEvents) && [datasetName, dataRepositoryName, otherFilters.clientName, otherFilters.matterName]
                    .filter(flter => flter.enabled).every(({enabled, ...rest}) => isNotEmptyNorFalsy(rest)),
                [scheduleTriggerTypeKeys.ON_LEGAL_HOLD_EVENT]: isNotEmptyNorFalsy(legalHoldEvents) && [legalHoldName, legalHoldDescription, otherFilters.clientName, otherFilters.matterName]
                    .filter(flter => flter.enabled).every(({enabled, ...rest}) => isNotEmptyNorFalsy(rest)),
                [scheduleTriggerTypeKeys.ON_WEBHOOK_TRIGGER]: !!webhookTrigger.httpVerb
            })()(scheduleTriggerType),

            workflowPane: (clientId === CLIENT_UNASSIGNED) || (matterId != null && !isLoadingClientMatters)
                || (eventTriggerType.job && clientId === SAME_AS_TRIGGERING_JOB) || (eventTriggerType.dataset && clientId === SAME_AS_TRIGGERING_DATASET)
                || (eventTriggerType.legalHold && clientId === SAME_AS_TRIGGERING_LEGAL_HOLD),

            settingsPane: (workflowTemplateId != null && !isLoadingLibraryWorkflows)
                || (eventTriggerType.job && libraryId === SAME_AS_TRIGGERING_JOB),

            submit: areParametersSatisfied && !(isLoadingSettings || isLoading)
        }
    }

    static getDefaultFormState(utc) {
        const [startDate, startTime] = getInputFormattedDateAndTime(0, utc);
        const [expireAfterDate, expireAfterTime] = getInputFormattedDateAndTime(3, utc);
        const [commenceAfterDate, commenceAfterTime] = getInputFormattedDateAndTime(0, utc);

        return {
            scheduleName: '',
            enabled: true,
            description: '',
            scheduleTriggerType: scheduleTriggerTypeKeys.ON_SCHEDULE,
            scheduleTrigger: {
                startDate,
                startTime,
                frequency: scheduleFrequencyKeys.ONE_TIME,
                recurEvery: 1,
                recurOnDay: arrayIntoBoolObject(getValues(recurOnDayKeys)),
                addNextJobToStaging: true
            },
            eventTrigger: {
                jobEvents: arrayIntoBoolObject(getValues(jobEventTriggerKeys)),
                datasetEvents: arrayIntoBoolObject(getValues(datasetEventTriggerKeys)),
                legalHoldEvents: arrayIntoBoolObject(getValues(legalHoldEvenTriggerKeys)),
                submissionMechanism: {
                    enabled: true,
                    ...arrayIntoBoolObject(getValues(jobSubmissionMechanismKeys), [jobSubmissionMechanismKeys.QUEUED])
                },
                jobName: {
                    enabled: false,
                    contains: ''
                },
                description: {
                    enabled: false,
                    contains: ''
                },
                clientName: {
                    enabled: false,
                    contains: ''
                },
                matterName: {
                    enabled: false,
                    contains: ''
                },
                datasetName: {
                    enabled: false,
                    contains: ''
                },
                dataRepositoryName: {
                    enabled: false,
                    contains: ''
                },
                legalHoldName: {
                    enabled: false,
                    contains: ''
                },
                legalHoldDescription: {
                    enabled: false,
                    contains: ''
                },
                libraryName: {
                    enabled: false,
                    contains: ''
                },
                workflowName: {
                    enabled: false,
                    contains: ''
                },
                errorText: {
                    enabled: false,
                    contains: ''
                },
                priority: {
                    enabled: false,
                    ...arrayIntoBoolObject(getValues(priorityKeys))
                }
            },
            conditions: {
                expireAfter: {
                    enabled: false,
                    date: expireAfterDate,
                    time: expireAfterTime
                },
                commenceAfter: {
                    enabled: false,
                    date: commenceAfterDate,
                    time: commenceAfterTime
                },
                skipIfJobsRunning: {
                    enabled: false,
                    count: 1
                },
                skipIfJobsQueued: {
                    enabled: false,
                    count: 1
                }
            },
            webhookTrigger: {
                httpVerb: httpVerb.GET,
                signatureKey: ''
            },
            clientId: null,
            matterId: null,
            libraryId: null,
            workflowTemplateId: null,
            executionProfileId: null,
            resourcePoolId: null,
            priority: priorityKeys.MEDIUM,
            parameters: null,
            operations: null,
            didUserChange: {},
            isLoading: false,
            isLoadingSettings: false,
            isDisabled: false
        }
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'jobScheduleDisplay',
                type: 'Display',
                state: {
                    jobScheduleId: null,
                    isJobScheduleFormActive: false,
                    searchText: ''
                }
            },
            {
                key: 'jobScheduleForm',
                type: 'Form',
                state: JobScheduleModel.getDefaultFormState()
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'JOB_SCHEDULE_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }
}

export class JobScheduleApi extends DetailsApi {
    static location = '/schedules';

    static getRuns(id) {
        return axiosInstance.get(`/scheduler/schedules/${id}/runs`);
    }
}

export class JobScheduleSaga extends DetailsSaga {

    static ModelType = JobScheduleModel;
    static ModelApi = JobScheduleApi;

    static activationComponent = 'JOB_SCHEDULE_DISPLAY';
    static variableNames = {
        detailsMap: 'jobScheduleDetailsMap',
        instanceId: 'jobScheduleId',
        isFormActive: 'isJobScheduleFormActive',
        updateDisplay: 'updateDisplay',
        updatePane: 'updateForm',
        route: routes.JOBS
    };

    static translations = {
        itemTitle: '$t(jobSchedule:label.name)',
        itemLower: '$t(jobSchedule:label.name_lower)'
    };

    // Encrypto for password parameters
    static encrypto;

    static buildActivationEffects(dispatch) {
        return [
            ...super.buildActivationEffects(dispatch),
            // ACTIVATION EFFECTS
            contextTakeLeading(JobScheduleModel.actions.SAVE_EDIT, this, 'editSave'),
            contextTakeLeading(JobScheduleModel.actions.CANCEL_EDIT, this, 'editCancel'),

            contextTakeLatest(JobScheduleModel.actions.ENCRYPT_PROTECTED_VALUES, this, 'encryptProtectedValues'),
            contextTakeLatest(JobScheduleModel.actions.DECRYPT_PROTECTED_VALUES, this, 'decryptProtectedValues'),

            contextTakeLatest(JobScheduleModel.actions.SET_SAME_AS_JOB_DEFAULTS, this, 'setSameAsJobDefaults'),
            contextTakeLeading(JobScheduleModel.actions.PREPARE_GET_OPERATIONS_AND_PARAMETERS_SAGA, this, 'getOperationsAndParameters', dispatch),

            takeEvery(JobScheduleModel.actions.UPDATE_PARAMETER, tryCatchWrapper, contextSaga(this, 'updateParameter')),
            takeLatest(JobScheduleModel.actions.LOAD_TSV_PARAMETERS, tryCatchWrapper, isDisabledWrapper, JobScheduleModel.componentActionCreators.updateForm, contextSaga(this, 'loadTsvParameters')),
            takeLatest(JobScheduleModel.actions.RESET_PARAMETERS, isDisabledWrapper, JobScheduleModel.componentActionCreators.updateForm, contextSaga(this, 'resetParameters')),

            contextTakeLatest(JobScheduleModel.actions.START_POLLING_SETTINGS, this, 'pollSettings'),
            put(JobScheduleModel.actionCreators.startPollingDetails()),
            put(JobModel.actionCreators.startPollingDetails())
        ]
    }

    static buildDeactivationEffects() {
        return [
            ...super.buildDeactivationEffects(),
            // DEACTIVATION EFFECTS
            put(JobScheduleModel.actionCreators.stopPollingDetails()),
            put(JobModel.actionCreators.stopPollingDetails())
        ]
    }

    static* setInstanceId(args) {
        const {updateDisplay, instanceId} = this.variableNames;

        yield all([
            put(JobModel.componentActionCreators.updatePage({activeView: jobPageViewKeys.SCHEDULE})),
            put(this.ModelType.componentActionCreators[updateDisplay]({[instanceId]: args.id}))
        ]);
    }

    static* initializeEncrypto() {
        // Get one time key
        if (this.encrypto === undefined) {
            this.encrypto = null;
            const {data: {key, ivSeed}} = yield contextCall(JobApi, 'getOneTimeKey');
            this.encrypto = new Encrypto(key, ivSeed);
        }
    }

    static* showForm(action) {
        const {initialState} = action.payload;
        yield put(JobScheduleModel.componentActionCreators.resetForm());

        yield all([
            put(JobScheduleModel.componentActionCreators.updateForm({
                ...JobScheduleModel.getDefaultFormState(),
                ...initialState
            })),
            put(JobScheduleModel.componentActionCreators.updateDisplay({
                isJobScheduleFormActive: true
            }))
        ]);

        yield* this.initializeEncrypto();
    }

    static* hideForm(action) {
        // If not in edit mode
        const hideAndReset = function*() {
            // Reset form values and clear encrypto
            yield all([
                put(JobScheduleModel.componentActionCreators.resetForm()),
                put(JobScheduleModel.componentActionCreators.updateDisplay({
                    isJobScheduleFormActive: false
                }))
            ]);

            this.encrypto.clear();
            delete this.encrypto;
        }.bind(this);

        const {activeModel} = yield select(state => state.editDetails);
        if (activeModel !== JobScheduleModel.nom) {
            yield* hideAndReset();
            return;
        }

        const callAfterResetEffect = call(function* () {
            yield take(EditModel.actions.RESET);
            yield* hideAndReset();
        });

        // Make a call to grab protected parameter HTML values and encrypt
        // For case of saving when on ScheduleJobPane and HTML values have not been encrypted and tracked
        yield contextCall(this, 'encryptProtectedValues', {payload: {type: 'parameters'}});
        yield contextCall(this, 'encryptProtectedValues', {payload: {type: 'webhookTrigger'}});

        const {id, isDisabled: ignore2, ...editValues} = yield select(state => state.componentStates.jobScheduleForm);
        const {id: ignore, isDisabled: ignore3, ...values} = yield contextCall(this, 'getEditValues', id);
        const isChanged = !objEquals(editValues, values);

        if (isChanged) {
            const allowedNavigations = JobScheduleModel.getAllowedPaneNavigations({
                position: action.payload.position,
                oldWorkflowTemplateId: values.workflowTemplateId,
                ...editValues
            });

            yield put(PopupModel.actionCreators.show({
                info: {
                    key: popupInfoKeys.SAFE_CLOSE
                },
                buttons: [{
                    titleKey: 'common:option.dontSave',
                    onClick: function () {
                        this.dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(callAfterResetEffect));
                        this.dispatch(EditModel.actionCreators.cancel());
                    }.bind(this),
                }, {
                    titleKey: 'common:option.save',
                    onClick: function () {
                        this.dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(callAfterResetEffect));
                        this.dispatch(EditModel.actionCreators.save());
                    }.bind(this),
                    isDisabled: !(isAllTruthy(allowedNavigations))
                }]
            }));
        } else {
            yield all([
                callAfterResetEffect,
                put(EditModel.actionCreators.cancel())
            ]);
        }
    }

    static* submitForm() {
        const jobScheduleForm = yield select(state => state.componentStates.jobScheduleForm);
        const schedule = yield this.getSaveValues(jobScheduleForm);

        // Submitting
        const {data} = yield contextCall(JobScheduleApi, 'post', schedule);
        yield put(JobScheduleModel.actionCreators.addDetails(data));

        yield all([
            put(JobScheduleModel.actionCreators.hideForm()),
            put(JobScheduleModel.actionCreators.showTablet(data.id))
        ]);
    }

    static* startEdit(action) {
        this.startEditDate = new Date();

        const {id} = action.payload;
        const editValues = yield contextCall(this, 'getEditValues', id);

        yield put(JobScheduleModel.actionCreators.hideTablet());
        yield put(JobScheduleModel.actionCreators.showForm(editValues));

        yield all([
            put(EditModel.actionCreators.start(this.ModelType.nom, editValues)),
            retryCall(contextSaga(this, 'waitForEditAction'), id, editValues)
        ]);

        yield put(EditModel.actionCreators.reset());
    }

    static* saveEdit(id) {
        const {jobScheduleForm} = yield select(state => state.componentStates);

        try {
            const schedule = yield this.getSaveValues(jobScheduleForm);
            const {data} = yield contextCall(this.ModelApi, 'putDetails', id, schedule);

            yield all([
                put(this.ModelType.actionCreators.updateDetails({[id]: new this.ModelType(data)})),
                put(EditModel.actionCreators.saveSuccess())
            ]);
        } catch (error) {
            yield put(EditModel.actionCreators.saveFail());
            throw error;
        }
    }

    static* showEditElsewherePopup() {
        yield put(PopupModel.actionCreators.showWarning({
            info: {
                key: popupInfoKeys.VALUES_CHANGED_WHILE_EDIT,
                valueKeys: {
                    itemLower: this.translations.itemLower
                }
            },
            buttons: [{
                titleKey: 'common:option.loadChanges',
                onClick: () => this.dispatch(JobScheduleModel.actionCreators.cancelEdit())
            }],
            cancelButton: {
                titleKey: 'common:option.ignoreChanges'
            }
        }));
    }

    static* editSave() {
        const {id} = yield select(state => state.componentStates.jobScheduleForm);
        yield put(EditModel.actionCreators.save());

        const [success] = yield race([
            take(EditModel.actions.SAVE_SUCCESS),
            take(EditModel.actions.SAVE_FAIL)
        ]);

        if (success) {
            yield put(JobScheduleModel.componentActionCreators.updateDisplay({isJobScheduleFormActive: false, jobScheduleId: id}));
        }
    }

    static* editCancel() {
        const {id} = yield select(state => state.componentStates.jobScheduleForm);
        yield put(EditModel.actionCreators.cancel());

        yield put(JobScheduleModel.componentActionCreators.updateDisplay({isJobScheduleFormActive: false, jobScheduleId: id}));
    }

    static* getEditValues(id) {
        const jobSchedule = yield select(state => state.jobScheduleDetailsMap.get(id));
        const {name: scheduleName, enabled, description, scheduleTriggerType, conditions, scheduleTrigger, eventTrigger, webhookTrigger} = jobSchedule;

        const defaultFormState = JobScheduleModel.getDefaultFormState(this.startEditDate);
        const editValues = {
            ...defaultFormState,
            id,
            scheduleName,
            enabled,
            description,
            scheduleTriggerType,
            isEdit: true
        };

        // Conditions
        const {commenceAfter, expireAfter, skipIfJobsRunning, skipIfJobsQueued} = conditions;
        if (expireAfter != null) {
            const [date, time] = getInputFormattedDateAndTime(0, expireAfter);
            editValues.conditions.expireAfter = {
                enabled: true,
                date,
                time
            };
        }
        if (commenceAfter != null) {
            const [date, time] = getInputFormattedDateAndTime(0, commenceAfter);
            editValues.conditions.commenceAfter = {
                enabled: true,
                date,
                time
            };
        }
        if (skipIfJobsRunning) {
            editValues.conditions.skipIfJobsRunning = {
                enabled: true,
                count: skipIfJobsRunning
            }
        }
        if (skipIfJobsQueued) {
            editValues.conditions.skipIfJobsQueued = {
                enabled: true,
                count: skipIfJobsQueued
            }
        }

        // Triggers
        switch (scheduleTriggerType) {
            case scheduleTriggerTypeKeys.ON_SCHEDULE: {
                const {addNextJobToStaging, startDate, frequency, recurEvery, recurOnDays} = scheduleTrigger;
                const [date, time] = getInputFormattedDateAndTime(0, startDate);

                editValues.scheduleTrigger = {
                    startDate: date,
                    startTime: time,
                    frequency,
                    recurEvery,
                    recurOnDay: arrayIntoBoolObject(getValues(recurOnDayKeys), recurOnDays),
                    addNextJobToStaging
                }
                break;
            }
            case scheduleTriggerTypeKeys.ON_JOB_EVENT: {
                const {
                    submissionMechanisms, jobNameContains, jobDescriptionContains, clientNameContains, matterNameContains,
                    libraryNameContains, workflowNameContains, errorTextContains, priorityAnyOf
                } = eventTrigger.triggerFilter;

                editValues.eventTrigger.jobEvents = arrayIntoBoolObject(getValues(jobEventTriggerKeys), eventTrigger.jobEvents);
                if (Array.isArray(submissionMechanisms)) {
                    editValues.eventTrigger.submissionMechanism = {
                        enabled: true,
                        ...arrayIntoBoolObject(getValues(jobSubmissionMechanismKeys), submissionMechanisms)
                    }
                } else {
                    // Enabled by default, set to false if not used
                    editValues.eventTrigger.submissionMechanism.enabled = false;
                }

                if (jobNameContains) {
                    editValues.eventTrigger.jobName = {
                        enabled: true,
                        contains: jobNameContains
                    }
                } else {
                    // Enabled by default, set to false if not used
                    editValues.eventTrigger.jobName.enabled = false;
                }

                if (jobDescriptionContains) {
                    editValues.eventTrigger.description = {
                        enabled: true,
                        contains: jobDescriptionContains
                    }
                }
                if (clientNameContains) {
                    editValues.eventTrigger.clientName = {
                        enabled: true,
                        contains: clientNameContains
                    }
                }
                if (matterNameContains) {
                    editValues.eventTrigger.matterName = {
                        enabled: true,
                        contains: matterNameContains
                    }
                }
                if (libraryNameContains) {
                    editValues.eventTrigger.libraryName = {
                        enabled: true,
                        contains: libraryNameContains
                    }
                }
                if (workflowNameContains) {
                    editValues.eventTrigger.workflowName = {
                        enabled: true,
                        contains: workflowNameContains
                    }
                }
                // Only if JOB_ERROR is true
                if (editValues.eventTrigger.jobEvents[jobEventTriggerKeys.JOB_ERROR] && errorTextContains) {
                    editValues.eventTrigger.errorText = {
                        enabled: true,
                        contains: errorTextContains
                    }
                }
                if (Array.isArray(priorityAnyOf)) {
                    editValues.eventTrigger.priority = {
                        enabled: true,
                        ...arrayIntoBoolObject(getValues(priorityKeys), priorityAnyOf)
                    }
                }
                break;
            }
            case scheduleTriggerTypeKeys.ON_DATASET_EVENT: {
                const {clientNameContains, matterNameContains, datasetNameContains, dataRepositoryNameContains} = eventTrigger.triggerFilter;

                editValues.eventTrigger.datasetEvents = arrayIntoBoolObject(getValues(datasetEventTriggerKeys), eventTrigger.datasetEvents);
                if (clientNameContains) {
                    editValues.eventTrigger.clientName = {
                        enabled: true,
                        contains: clientNameContains
                    }
                }
                if (matterNameContains) {
                    editValues.eventTrigger.matterName = {
                        enabled: true,
                        contains: matterNameContains
                    }
                }
                if (datasetNameContains) {
                    editValues.eventTrigger.datasetName = {
                        enabled: true,
                        contains: datasetNameContains
                    }
                }
                if (dataRepositoryNameContains) {
                    editValues.eventTrigger.dataRepositoryName = {
                        enabled: true,
                        contains: dataRepositoryNameContains
                    }
                }
                break;
            }
            case scheduleTriggerTypeKeys.ON_LEGAL_HOLD_EVENT: {
                const {clientNameContains, matterNameContains, legalHoldNameContains, legalHoldDescriptionContains} = eventTrigger.triggerFilter;

                editValues.eventTrigger.legalHoldEvents = arrayIntoBoolObject(getValues(legalHoldEvenTriggerKeys), eventTrigger.legalHoldEvents);
                if (clientNameContains) {
                    editValues.eventTrigger.clientName = {
                        enabled: true,
                        contains: clientNameContains
                    }
                }
                if (matterNameContains) {
                    editValues.eventTrigger.matterName = {
                        enabled: true,
                        contains: matterNameContains
                    }
                }
                if (legalHoldNameContains) {
                    editValues.eventTrigger.legalHoldName = {
                        enabled: true,
                        contains: legalHoldNameContains
                    }
                }
                if (legalHoldDescriptionContains) {
                    editValues.eventTrigger.legalHoldDescription = {
                        enabled: true,
                        contains: legalHoldDescriptionContains
                    }
                }
                break;
            }
            case scheduleTriggerTypeKeys.ON_WEBHOOK_TRIGGER: {
                yield* this.initializeEncrypto();
                editValues.webhookTrigger = {
                    ...webhookTrigger
                };
                editValues.webhookTrigger.signatureKey = this.encrypto.encrypt('signatureKey', getProtectedValue(webhookTrigger.signatureKey));
                break;
            }
            default:
                break;
        }

        // Same as Job
        const {sameMatterAsTriggerMatter, sameWorkflowTemplateAsTriggerMatter, sameExecutionProfileAsTriggerMatter, sameResourcePoolIdAsTriggerMatter, samePriorityAsTriggerMatter,
            clientId, matterId, libraryId, workflowTemplateId, executionProfileId, resourcePoolId, priority, sessionParameters} = jobSchedule;

        if (sameMatterAsTriggerMatter) {
            editValues.clientId = {
                [scheduleTriggerTypeKeys.ON_JOB_EVENT]: SAME_AS_TRIGGERING_JOB,
                [scheduleTriggerTypeKeys.ON_DATASET_EVENT]: SAME_AS_TRIGGERING_DATASET,
                [scheduleTriggerTypeKeys.ON_LEGAL_HOLD_EVENT]: SAME_AS_TRIGGERING_LEGAL_HOLD
            }[scheduleTriggerType];

        } else {
            const [cId, mId] = yield contextCall(JobSaga, 'getClientAndMatterId', clientId, matterId);
            editValues.clientId = cId;
            editValues.matterId = mId;
        }

        if (sameWorkflowTemplateAsTriggerMatter) {
            editValues.libraryId = SAME_AS_TRIGGERING_JOB;
        } else {
            const [lId, wId] = yield contextCall(JobSaga, 'getLibraryAndWorkflowId', libraryId, workflowTemplateId);
            editValues.libraryId = lId;

            editValues.workflowTemplateId = wId;
            editValues.operations = yield select(state => state.templateOperationsMap.get(workflowTemplateId));

            editValues.parameters = deepCopy(sessionParameters);
            // Assume all passwords valid since they passed save check previously
            editValues.parameters.forEach(parameter => parameter.valid = true);
        }

        if (sameExecutionProfileAsTriggerMatter) {
            editValues.executionProfileId = SAME_AS_TRIGGERING_JOB;
        } else {
            editValues.executionProfileId = executionProfileId;
        }
        if (sameResourcePoolIdAsTriggerMatter) {
            editValues.resourcePoolId = SAME_AS_TRIGGERING_JOB;
        } else {
            editValues.resourcePoolId = resourcePoolId;
        }
        if (samePriorityAsTriggerMatter) {
            editValues.priority = SAME_AS_TRIGGERING_JOB;
        } else {
            editValues.priority = priority || priorityKeys.MEDIUM;
        }

        return editValues;
    }

    static* getSaveValues(values) {
        const {scheduleName: name, enabled, description, conditions, scheduleTriggerType, scheduleTrigger, eventTrigger, webhookTrigger} = values;
        const saveValues = {
            name,
            enabled,
            description,
            conditions: {}
        };

        // Conditions
        const {expireAfter, commenceAfter, skipIfJobsRunning, skipIfJobsQueued} = conditions;
        if (expireAfter.enabled) {
            saveValues.conditions.expireAfter = getUtcFromFormattedDate(expireAfter.date, expireAfter.time);
        }
        if (commenceAfter.enabled) {
            saveValues.conditions.commenceAfter = getUtcFromFormattedDate(commenceAfter.date, commenceAfter.time);
        }
        if (skipIfJobsRunning.enabled) {
            saveValues.conditions.skipIfJobsRunning = skipIfJobsRunning.count;
        }
        if (skipIfJobsQueued.enabled) {
            saveValues.conditions.skipIfJobsQueued = skipIfJobsQueued.count;
        }

        // Triggers
        switch (scheduleTriggerType) {
            case scheduleTriggerTypeKeys.ON_SCHEDULE: {
                const {addNextJobToStaging, startDate, startTime, frequency, recurEvery, recurOnDay} = scheduleTrigger;

                saveValues.scheduleTrigger = {
                    startDate: getUtcFromFormattedDate(startDate, startTime),
                    frequency,
                    recurEvery,
                    recurOnDays: boolObjectToArray(recurOnDay),
                    addNextJobToStaging
                };

                break;
            }
            case scheduleTriggerTypeKeys.ON_JOB_EVENT: {
                const {
                    jobEvents, submissionMechanism, jobName, description, clientName, matterName,
                    libraryName, workflowName, errorText, priority
                } = eventTrigger;

                saveValues.eventTrigger = {
                    jobEvents: boolObjectToArray(jobEvents),
                    triggerFilter: {}
                }

                if (submissionMechanism.enabled) {
                    saveValues.eventTrigger.triggerFilter.submissionMechanisms = boolObjectToArray(submissionMechanism, ['enabled']);
                }
                if (jobName.enabled) {
                    saveValues.eventTrigger.triggerFilter.jobNameContains = jobName.contains;
                }
                if (description.enabled) {
                    saveValues.eventTrigger.triggerFilter.jobDescriptionContains = description.contains;
                }
                if (clientName.enabled) {
                    saveValues.eventTrigger.triggerFilter.clientNameContains = clientName.contains;
                }
                if (matterName.enabled) {
                    saveValues.eventTrigger.triggerFilter.matterNameContains = matterName.contains;
                }
                if (libraryName.enabled) {
                    saveValues.eventTrigger.triggerFilter.libraryNameContains = libraryName.contains;
                }
                if (workflowName.enabled) {
                    saveValues.eventTrigger.triggerFilter.workflowNameContains = workflowName.contains;
                }
                // Only if JOB_ERROR is true
                if (saveValues.eventTrigger.jobEvents.includes(jobEventTriggerKeys.JOB_ERROR) && errorText.enabled) {
                    saveValues.eventTrigger.triggerFilter.errorTextContains = errorText.contains;
                }
                if (priority.enabled) {
                    saveValues.eventTrigger.triggerFilter.priorityAnyOf = boolObjectToArray(priority, ['enabled'])
                }
                break;
            }
            case scheduleTriggerTypeKeys.ON_DATASET_EVENT: {
                const {datasetEvents, clientName, matterName, datasetName, dataRepositoryName} = eventTrigger;

                saveValues.eventTrigger = {
                    datasetEvents: boolObjectToArray(datasetEvents),
                    triggerFilter: {}
                }

                if (clientName.enabled) {
                    saveValues.eventTrigger.triggerFilter.clientNameContains = clientName.contains;
                }
                if (matterName.enabled) {
                    saveValues.eventTrigger.triggerFilter.matterNameContains = matterName.contains;
                }
                if (datasetName.enabled) {
                    saveValues.eventTrigger.triggerFilter.datasetNameContains = datasetName.contains;
                }
                if (dataRepositoryName.enabled) {
                    saveValues.eventTrigger.triggerFilter.dataRepositoryNameContains = dataRepositoryName.contains;
                }
                break;
            }
            case scheduleTriggerTypeKeys.ON_LEGAL_HOLD_EVENT: {
                const {legalHoldEvents, clientName, matterName, legalHoldName, legalHoldDescription} = eventTrigger;

                saveValues.eventTrigger = {
                    legalHoldEvents: boolObjectToArray(legalHoldEvents),
                    triggerFilter: {}
                }

                if (clientName.enabled) {
                    saveValues.eventTrigger.triggerFilter.clientNameContains = clientName.contains;
                }
                if (matterName.enabled) {
                    saveValues.eventTrigger.triggerFilter.matterNameContains = matterName.contains;
                }
                if (legalHoldName.enabled) {
                    saveValues.eventTrigger.triggerFilter.legalHoldNameContains = legalHoldName.contains;
                }
                if (legalHoldDescription.enabled) {
                    saveValues.eventTrigger.triggerFilter.legalHoldDescriptionContains = legalHoldDescription.contains;
                }
                break;
            }
            case scheduleTriggerTypeKeys.ON_WEBHOOK_TRIGGER: {
                saveValues.webhookTrigger = {
                    httpVerb: webhookTrigger.httpVerb
                };
                if (webhookTrigger.useSignatureKey) {
                    saveValues.webhookTrigger.signatureKey = postProtectedValue(this.encrypto.decrypt('signatureKey', webhookTrigger.signatureKey));
                } else {
                    saveValues.webhookTrigger.signatureKey = '';
                }

                const info = {};
                if (saveValues.webhookTrigger.httpVerb === httpVerb.GET) {
                    info.key = 'creatingScheduleWebhookWithGetVerbWarning';
                } else if (saveValues.webhookTrigger.signatureKey === '') {
                    info.key = 'creatingScheduleWebhookWithoutSignatureKeyWarning';
                }
                if (info.key != null) {
                    const options = {confirmTitleKey: 'common:option.yes', cancelTitleKey: 'common:option.no'};
                    yield contextCall(this, 'showSaveWarning', info, options);
                }
                break;
            }
            default:
                break;
        }

        // Same as Job
        const {clientId, matterId, libraryId, workflowTemplateId, executionProfileId, resourcePoolId, priority, parameters} = values

        if ([SAME_AS_TRIGGERING_JOB, SAME_AS_TRIGGERING_DATASET, SAME_AS_TRIGGERING_LEGAL_HOLD].includes(clientId)) {
            saveValues.sameMatterAsTriggerMatter = true;

        } else if (clientId !== CLIENT_UNASSIGNED) {
            saveValues.clientId = clientId;
            saveValues.matterId = matterId;
        }

        if (libraryId === SAME_AS_TRIGGERING_JOB) {
            saveValues.sameWorkflowTemplateAsTriggerMatter = true;
        } else {
            saveValues.libraryId = libraryId;
            saveValues.libraryWorkflowId = workflowTemplateId;

            saveValues.sessionParameters = [];
            for (const parameter of getValues(parameters)) {

                let {value, ...rest} = parameter;
                // Protected parameters with value != null assumed to be encrypted at this point
                if (parameter.isPasswordParameter() && value != null && value !== '') {
                    value = this.encrypto.decrypt(parameter.name, value);
                }

                saveValues.sessionParameters.push({
                    ...rest,
                    value,
                    userDisplayableValue: parameter.getUserDisplayableValue()
                });
            }
        }
        if (executionProfileId === SAME_AS_TRIGGERING_JOB) {
            saveValues.sameExecutionProfileAsTriggerMatter = true;
        } else {
            saveValues.executionProfileId = executionProfileId;
        }
        if (resourcePoolId === SAME_AS_TRIGGERING_JOB) {
            saveValues.sameResourcePoolIdAsTriggerMatter = true;
        } else {
            saveValues.resourcePoolId = resourcePoolId;
        }
        if (priority === SAME_AS_TRIGGERING_JOB) {
            saveValues.samePriorityAsTriggerMatter = true;
        } else {
            saveValues.priority = priority;
        }

        return saveValues;
    }

    // Set to default values if changed to triggerType === SCHEDULE
    static* setSameAsJobDefaults() {
        const {jobScheduleForm: {scheduleTriggerType, clientId, libraryId, executionProfileId, resourcePoolId, priority}} = yield select(state => state.componentStates);

        const updates = {};

        if (scheduleTriggerType !== scheduleTriggerTypeKeys.ON_LEGAL_HOLD_EVENT) {
            if (clientId === SAME_AS_TRIGGERING_LEGAL_HOLD) {
                updates.clientId = null;
                updates.matterId = null;
                updates.parameters = null;
            }
        }

        if (scheduleTriggerType !== scheduleTriggerTypeKeys.ON_DATASET_EVENT) {
            if (clientId === SAME_AS_TRIGGERING_DATASET) {
                updates.clientId = null;
                updates.matterId = null;
                updates.parameters = null;
            }
        }

        if (scheduleTriggerType !== scheduleTriggerTypeKeys.ON_JOB_EVENT) {
            if (clientId === SAME_AS_TRIGGERING_JOB) {
                updates.clientId = null;
                updates.matterId = null;
            }
            if (libraryId === SAME_AS_TRIGGERING_JOB) {
                updates.libraryId = null;
                updates.workflowTemplateId = null;
                updates.parameters = null;
            }
            if (executionProfileId === SAME_AS_TRIGGERING_JOB) {
                updates.executionProfileId = null;
            }
            if (resourcePoolId === SAME_AS_TRIGGERING_JOB) {
                updates.resourcePoolId = null;
            }
            if (priority === SAME_AS_TRIGGERING_JOB) {
                updates.priority = priorityKeys.MEDIUM;
            }
        }

        yield put(JobScheduleModel.componentActionCreators.updateForm(updates));
    }

    static* getOperationsAndParameters() {
        const {workflowTemplateId: oldWorkflowTemplateId} = yield select(state => state.componentStates.jobScheduleForm);
        // Reset force to null so Redux can register next force position
        // Otherwise Redux will think there was no change; if force.position is set to 1 when it was already set to 1
        yield put(JobScheduleModel.componentActionCreators.updateForm({force: null}));

        // Waits for action
        const action = yield take([JobScheduleModel.actions.GET_OPERATIONS_AND_PARAMETERS, JobScheduleModel.actions.HIDE_FORM]);
        if (action.type === JobScheduleModel.actions.HIDE_FORM) {
            return;
        }
        let jobScheduleForm = yield select(state => state.componentStates.jobScheduleForm);

        // If workflowTemplateId == null is SameAsTriggeringJob
        if (jobScheduleForm.workflowTemplateId == null)
            return;

        try {
            yield put(JobScheduleModel.componentActionCreators.updateForm({
                isLoadingSettings: true
            }));

            const workflowTemplateId = jobScheduleForm.workflowTemplateId;
            yield contextCall(WorkflowTemplateSaga, 'querySettings', {payload: {id: workflowTemplateId}});

            let [operations, newParameters] = yield select(state => ([state.templateOperationsMap.get(workflowTemplateId), deepCopy(state.parametersMap.get(workflowTemplateId))]));
            // Maintain values for same parameters
            if (jobScheduleForm.parameters != null) {
                if (workflowTemplateId === oldWorkflowTemplateId) {
                    newParameters = jobScheduleForm.parameters;
                } else {
                    for (const [key, param] of jobScheduleForm.parameters) {
                        const newParam = newParameters.get(key);
                        if (newParam != null) {
                            newParam.value = param.value;
                            newParam.valueName = param.valueName;
                        }
                    }
                }
            }

            yield put(JobScheduleModel.componentActionCreators.updateForm({operations, parameters: newParameters}));
            yield contextCall(JobScheduleSaga, 'updateJobScheduleParametersAllowedValues');

        } finally {
            // Reset isLoading
            yield put(JobScheduleModel.componentActionCreators.updateForm({
                isLoadingSettings: false
            }));
        }
    }

    static* updateJobScheduleParametersAllowedValues() {
        function* handleError(error) {
            yield contextCall(JobScheduleSaga, 'handleJobScheduleParametersAllowedValuesError', error);
        }
        try {
            yield put(JobScheduleModel.componentActionCreators.updateForm({isDisabled: true}));
            const jobScheduleForm = yield select(state => state.componentStates.jobScheduleForm);
            const parameters = yield WorkflowTemplateSaga.updateParametersAllowedValues(jobScheduleForm, handleError);
            yield put(JobScheduleModel.componentActionCreators.updateForm({parameters}));
        } finally {
            yield put(JobScheduleModel.componentActionCreators.updateForm({isDisabled: false}));
        }
    }

    static* updateParameter(action) {
        const jobScheduleForm = yield select(state => state.componentStates.jobScheduleForm);
        const callbacks = this.getParameterUpdateCallbacks();
        yield contextCall(WorkflowTemplateSaga, 'debounceParameterUpdate', action.payload, jobScheduleForm, callbacks);
    }

    static getParameterUpdateCallbacks() {
        return {
            handleUpdate: function* (update) {
                yield put(JobScheduleModel.componentActionCreators.updateForm(prev => ({parameters: update(prev.parameters)})));
            },
            handleError: function* (error) {
                yield contextCall(JobScheduleSaga, 'handleJobScheduleParametersAllowedValuesError', error);
            }
        }
    }

    static* handleJobScheduleParametersAllowedValuesError(error) {
        if (error.cause) {
            switch (error.cause) {
                case 'noFinalizedOrAllowedDatasets': {
                    yield contextCall(WorkflowTemplateSaga, 'handleNoDataSetsError', error, {
                        hideFormActionCreator: JobScheduleModel.actionCreators.hideForm
                    });
                    break;
                }
                default:
                    yield put(PopupModel.actionCreators.showError({
                        info: {
                            key: error.cause,
                            values: error.data
                        }
                    }));
            }
        } else {
            yield contextCall(WorkflowTemplateSaga, 'handleAllowedParameterValuesError', error);
        }
    }

    static* loadTsvParameters(action) {
        const {tsvText} = action.payload;
        const parameters = yield select(state => deepCopy(state.componentStates.jobScheduleForm.parameters));
        const updatedParameters = yield WorkflowTemplateSaga.loadTsvParameters({tsvText, parameters});

        yield put(JobScheduleModel.componentActionCreators.updateForm({parameters: updatedParameters}));
    }

    static* resetParameters() {
        const jobScheduleForm = yield select(state => state.componentStates.jobScheduleForm);
        const updatedParameters = yield WorkflowTemplateSaga.resetParameters(jobScheduleForm);

        yield put(JobScheduleModel.componentActionCreators.updateForm({parameters: updatedParameters}));
    }

    static* encryptProtectedValues(action) {
        const {type, values={}} = action.payload;

        const jobScheduleForm = yield select(state => state.componentStates.jobScheduleForm);
        const encryptedUpdates = {};
        switch (type) {
            case 'parameters':
                encryptedUpdates.parameters = ParameterModel.encryptProtectedParametersFromHTML('parameterList', this.encrypto, jobScheduleForm.parameters);
                break;
            case 'webhookTrigger':
                let signatureKey = values.signatureKey;
                if (signatureKey == null) {
                    signatureKey = (document.getElementsByName('signatureKey')[0] || {}).value;
                }
                if (signatureKey != null) {
                    encryptedUpdates.webhookTrigger = {
                        ...jobScheduleForm.webhookTrigger,
                        signatureKey: this.encrypto?.encrypt('signatureKey', signatureKey)
                    };
                }
                break;
        }
        yield put(JobScheduleModel.componentActionCreators.updateForm(encryptedUpdates));
    }

    static* decryptProtectedValues(action) {
        const {type} = action.payload;

        const jobScheduleForm = yield select(state => state.componentStates.jobScheduleForm);
        switch (type) {
            case 'parameters':
                ParameterModel.decryptProtectedParametersToHTML('parameterList', this.encrypto, jobScheduleForm.parameters);
                break;
            case 'webhookTrigger':
                const signatureKeyInput = document.getElementsByName('signatureKey')[0];
                if (signatureKeyInput) {
                    signatureKeyInput.value = this.encrypto.decrypt('signatureKey', jobScheduleForm.webhookTrigger.signatureKey);
                }
                break;
        }
    }

    static* queryDetails() {
        const response = yield contextCall(JobScheduleApi, 'getDetails');
        const key = details.SCHEDULE_JOBS;

        if (AxiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(JobScheduleModel.actionCreators.setDetailsMap(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }

    static* querySettings(action) {
        const {id} = action.payload;
        if (id == null)
            return;

        const response = yield contextCall(JobScheduleApi, 'getRuns', id);
        if (AxiosProxy.shouldUpdate(id, response)) {
            yield all([
                put(JobScheduleRunModel.actionCreators.setSetting(id, response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(id))
            ]);
        }
    }
}

export default JobScheduleModel;