import DetailsModel, {DetailsApi, DetailsSaga} from "./DetailsModel";
import {actionCreator, getValues} from "../../utilities/helperFunctions";
import {axiosInstance} from "../api/AxiosProxy";
import {contextPollUntil} from "../../saga/sagaFunctions";

class SubsetDetailsModel extends DetailsModel {

    static buildActions(type) {
        return {
            ...super.buildActions(type),
            // SUBSET DETAILS ACTIONS
            QUERY_DETAILS_SUBSET: `QUERY_${type}_DETAILS_SUBSET`,
            SET_SUBSET_DETAILS_MAP: `SET_${type}_SUBSET_DETAILS_MAP`,
            // SUBSET POLLING ACTIONS
            START_POLLING_DETAILS_SUBSET: `START_POLLING_${type}_DETAILS_SUBSET`,
            STOP_POLLING_DETAILS_SUBSET: `STOP_POLLING_${type}_DETAILS_SUBSET`
        }
    }

    static buildActionCreators(actions) {
        return {
            ...super.buildActionCreators(actions),
            // SUBSET DETAILS ACTION CREATORS
            queryDetailsSubset: actionCreator(actions.QUERY_DETAILS_SUBSET, 'parentId'),
            setSubsetDetailsMap: actionCreator(actions.SET_SUBSET_DETAILS_MAP, 'parentId', 'details'),
            // SUBSET POLLING ACTION CREATORS
            startPollingDetailsSubset: actionCreator(actions.START_POLLING_DETAILS_SUBSET, 'parentId', 'period'),
            stopPollingDetailsSubset: actionCreator(actions.STOP_POLLING_DETAILS_SUBSET)
        }
    }

    static buildReducer(actions) {
        return function (state = new Map(), action) {
            switch (action.type) {
                case actions.SET_DETAILS_MAP: {
                    const {details} = action.payload;

                    return this.setDetailsMapGeneric(state, details, 'id');
                }
                case actions.SET_SUBSET_DETAILS_MAP: {
                    const {parentId, details} = action.payload;

                    return this.setSubsetDetailsMap(state, parentId, details);
                }
                case actions.ADD_DETAILS: {
                    const {details} = action.payload;

                    return this.addDetailsGeneric(state, details, 'id');
                }
                case actions.UPDATE_DETAILS: {
                    const {idToUpdates} = action.payload;

                    return this.bulkUpdateDetails(state, idToUpdates);
                }
                case actions.DELETE_DETAILS: {
                    const {id} = action.payload;

                    return this.deleteDetails(state, id);
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }

    static setSubsetDetailsMap(state, parentId, details) {
        const newSubset = this.buildNewDetailsMap(state, details, 'id');
        const filtered = [], subset = [];
        // Separate same parent entries into subset and rest into filtered
        for (const entry of state) {
            const [key, model] = entry;

            // If exists in newSubset || have same parentId, then they belong to same subset
            if (newSubset.get(key) != null || model[this.parentKey] === parentId) {
                subset.push(entry);
            } else {
                filtered.push(entry)
            }
        }

        // If subset size has changed, return new state
        if (newSubset.size !== subset.length) {
            return new Map([...newSubset, ...filtered]);
        }

        // Determine if matter or order has been updated
        const newValues = getValues(newSubset);
        for (let i = 0; i < newValues.length; i++) {
            const model = newValues[i];
            const oldModel = subset[i][1];
            // If there's a new reference (re-ordered/state updated) return new state
            if (model !== oldModel) {
                return new Map([...newSubset, ...filtered]);
            }
        }
        // If no changes detected, return old state
        return state;
    }
}

export class SubsetDetailsApi extends DetailsApi {

    static getDetails(locationId, includeDisabled) {
        return axiosInstance.get(`/scheduler${this.location}/${locationId}${this.type}s?includeDisabled=${includeDisabled}`)
    }
}

export class SubsetDetailsSaga extends DetailsSaga {

    static* pollDetailsSubset(action) {
        const {parentId, period} = action.payload;

        yield contextPollUntil(this.ModelType.actions.STOP_POLLING_DETAILS_SUBSET, period, this, 'queryDetailsSubset', {payload: {parentId}});
    }

    static queryDetailsSubset() {
        throw new Error("Method not implemented");
    }
}

export default SubsetDetailsModel;