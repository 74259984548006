import SettingModel from "../generics/SettingModel";

class JobLinksModel extends SettingModel.Simple {

    static nom = 'JobLinksModel';
    static actions = JobLinksModel.buildActions('JOB_LINKS');
    static actionCreators = JobLinksModel.buildActionCreators(JobLinksModel.actions);
    static reducer = JobLinksModel.buildReducer(JobLinksModel.actions);

}

export default JobLinksModel;