import React, {useState} from 'react';
import './CustomTable.css';
import HighlightText from "../../userSettings/HighlightOption/HighlightText";
import {buildClassName, generateUUID4} from "../../../utilities/helperFunctions";
import HTMLTextInput from "../HTMLTextInput/HTMLTextInput";
import Text, {Paragraph} from "../Text/Text";
import {StatusIcon} from "../Common";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import {useTranslation} from "react-i18next";
import {getInvalidMessageSpan} from "../InvalidMessageSpan";


export function FormElementRow(props) {
    const {label, labelId, labelComponent, htmlFor, element, children, className, isWordWrap, isDisabled} = props;

    const clazzName = buildClassName(
        'table-row',
        className
    );

    const labelStyle = {paddingTop: 'calc(0.375em)'};

    return (
        <div className={clazzName}>
            <div className="table-cell no-stretch">
                {labelComponent != null ?
                    labelComponent
                    :
                    <Text id={labelId} htmlFor={htmlFor} value={`${label}:`} style={labelStyle}
                        isDisabled={isDisabled} isWordWrap={isWordWrap}/>
                }
            </div>

            <div className="table-cell">
                {element}
                {children}
            </div>
        </div>
    )
}

export const SettingsRowSeparator = ({size}) => (
    <div className="settings-row">
        <div className={`settings-row-separator is-${size}`}/>
    </div>
);

export const SettingsRowValue = props => {
    const {
        htmlFor,
        label,
        labelId,
        value,
        isItalic,
        isBold,
        isWordWrap=true,
        isBreakWord,
        isNoWrap,
        isTight,
        isEllipsis,
        isDisabled,
        labelStyle,
        enableHighlightText,
        showIfNull,
        valueIsItalic,
        valueIsBold
    } = props;

    if ((value == null || value === '') && !showIfNull)
        return null;

    const tight = isTight ? ' is-tight' : '';

    return (
        <div className="settings-row">
            <div className={'settings-label-cell' + tight} style={labelStyle}>
                {label &&
                <Text id={labelId} htmlFor={htmlFor} value={`${label}:`} isItalic={isItalic} isBold={isBold}
                    isNoWrap={isNoWrap} isDisabled={isDisabled}/>
                }
            </div>
            <div className="settings-value-cell">
                {value && typeof value === 'object' ?
                    value
                    :
                    <Text isItalic={valueIsItalic} isBold={valueIsBold} isWordWrap={isWordWrap}
                        isBreakWord={isBreakWord} isEllipsis={isEllipsis} isDisabled={isDisabled}
                    >
                        <HighlightText text={value != null ? value : '-'} isDisabled={isDisabled || !enableHighlightText}/>
                    </Text>
                }
            </div>
        </div>
    );
}

export function SettingsUserIconRow(props) {
    const {t} = useTranslation(['aria']);
    const {label, icon, value, highlightValue, isDisabled} = props;

    const disabled = isDisabled ? ' is-disabled' : '';

    return (
        <div className="settings-row">
            <div className="settings-label-cell">
                <label className={'label' + disabled}>
                    {label}:
                </label>
            </div>

            <div className="settings-value-cell" style={{display: 'flex', alignItems: 'flex-end'}}>
                {icon &&
                <span className={'icon is-medium' + disabled} style={{marginRight: '0.5rem'}}>
                    <img className="round-image" src={icon} alt={t('aria:hiddenAssistText.userIcon')}/>
                </span>
                }
                <label className={'label' + disabled}>
                    {highlightValue ?
                        <HighlightText text={value || '-'}/>
                        :
                        (value || '-')
                    }
                </label>
            </div>
        </div>
    )
}

export function SettingsRowLabel(props) {
    const {key, label, isItalic, isBold, isDisabled, isTight} = props;

    const italic = isItalic ? ' is-italic' : '';
    const bold = isBold ? ' is-bold' : '';
    const disabled = isDisabled ? ' is-disabled' : '';
    const tight = isTight ? ' is-tight' : '';

    return (
        <div className="settings-row" key={key}>
            <div className={'settings-label-cell' + tight} >
                <label className={'label' + italic + bold + disabled}>
                    {label}
                </label>
            </div>
        </div>
    )
}

export function StatusNameDescriptionCell(props) {
    const {
        status,
        statuses,
        name,
        description,
        ...attr
    } = props;

    return (
        <div className="table-cell status-name-description-cell" {...attr}>
            <div style={{display: 'flex', height: '100%'}}>

                {Array.isArray(statuses) && statuses.map((status, index) =>
                    <StatusIcon key={index} status={status}/>
                )}
                <StatusIcon status={status}/>

                <div style={{flex: 1, minWidth: 0}}>
                    <Text isEllipsis isBold>{name}</Text>
                    {description &&
                        <Paragraph>{description}</Paragraph>
                    }
                </div>
            </div>
        </div>
    )
}

export function TableRow(props) {
    const {
        Tag='div',
        className,
        isActive,
        onClick,
        value,
        children,
        isDisabled,
        ...attr
    } = props;

    function onKeyPress(e) {
        if (!isDisabled && e.key === 'Enter') {
            onClick(e);
        }
    }

    const clazzName = buildClassName(
        'table-row',
        isActive && 'is-active',
        isDisabled && 'is-disabled',
        className
    );

    return (
        <Tag className={clazzName} tabIndex={isDisabled ? -1 : 0} onKeyDown={onKeyPress}
            onClick={isDisabled ? null : onClick} data-value={value} {...attr}>

            {children}
        </Tag>
    )
}

export function TableLabelHeader(props) {
    const {
        label,
        alignRight,
        isBold = true,
        className,
        noStretch,
        isDisabled,
        ...attr
    } = props;

    const _className = buildClassName(
        'table-header',
        noStretch && 'no-stretch',
        alignRight && 'align-right',
        className
    );

    return (
        <div className={_className} {...attr}>
            {label &&
                <Text value={label} isBold={isBold} isEllipsis
                    isDisabled={isDisabled}/>
            }
        </div>
    )
}

export function TableLabelCell(props) {
    const {
        label,
        alignRight,
        className,
        style,
        noStretch,
        enableHighlightText,
        'aria-label': ariaLabel,
        'aria-labelledby': ariaLabelledBy,
        ...attr
    } = props

    const [id,] = useState(attr.id || generateUUID4());
    const {
        combinedAriaLabel,
        combinedAriaLabelledBy
    } = getInvalidMessageSpan({
        ariaLabel,
        ariaLabelledBy: [ariaLabelledBy, id]
    });

    const _className = buildClassName(
        'table-cell',
        alignRight && 'align-right',
        noStretch && 'no-stretch',
        className
    );

    return (
        <div className={_className} style={style}>
            {label != null &&
                <Text id={id} aria-label={combinedAriaLabel} aria-labelledby={combinedAriaLabelledBy}
                    isEllipsis {...attr}
                >
                    <HighlightText id={generateUUID4()} text={label} isDisabled={attr.isDisabled || !enableHighlightText}/>
                </Text>
            }
        </div>
    )
}

export function TableInputCell(props) {
    const {id, label, ...attr} = props;

    const labelClassName = buildClassName(
        'label',
        attr.isDisabled && 'is-disabled'
    );

    return (
        <div className="table-cell">
            <div className="flex-center">
                <HTMLTextInput id={id} {...attr}/>
                {label &&
                    <label htmlFor={id} className={labelClassName}>
                        {label}
                    </label>
                }
            </div>
        </div>
    )
}

export function TableEnableToggleCell(props) {
    const {t} = useTranslation(['aria']);
    const {
        displayName,
        ariaLabelKey,
        ...attr
    } = props;

    return (
        <div className="table-cell enable-cell no-stretch" onClick={event => event.stopPropagation()}
            onKeyDown={e => e.stopPropagation()}
        >
            <ToggleSwitch aria-label={t(`aria:hiddenAssistText.enable${ariaLabelKey}`, {name: displayName})}
                {...attr}/>
        </div>
    )
}