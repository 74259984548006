import React from "react";
import {icon} from "../../../utilities/iconResolver";
import {buildClassName} from "../../../utilities/helperFunctions";
import {useTranslation} from "react-i18next";


export function OperationStateIcons(props) {
    const {t} = useTranslation(['aria']);
    const {
        operation,
        size='is-small'
    } = props;

    const iconClassName = buildClassName(
        'icon',
        size
    );

    return (
        <div className="flex-center">
            {operation.enableFieldOverwrite &&
                <span className={iconClassName}>
                    <img src={icon('operationOverwrite')} alt={t('aria:hiddenAssistText.operationOverwriteIcon')}/>
                </span>
            }
            {operation.skippable &&
                <span className={iconClassName}>
                    <img src={icon('operationSkip')} alt={t('aria:hiddenAssistText.operationSkippableIcon')}/>
                </span>
            }
            {operation.suppressWarnings &&
                <span className={iconClassName}>
                    <img src={icon('operationSuppressWarnings')} alt={t('aria:hiddenAssistText.operationSuppressWarningsIcon')}/>
                </span>
            }
            {operation.softFail &&
                <span className={iconClassName}>
                    <img src={icon('operationSoftFail')} alt={t('aria:hiddenAssistText.operationSoftFailIcon')}/>
                </span>
            }
            {operation.disabled &&
                <span className={iconClassName}>
                    <img src={icon('operationDisabled')} alt={t('aria:hiddenAssistText.operationDisabledIcon')}/>
                </span>
            }
        </div>
    )
}

export default OperationStateIcons;
