import React from "react";
import {useTranslation} from "react-i18next";
import {createUpdateHandler} from "../../../utilities/componentFunctions";
import FormBuilder from "../../common/FormBuilder/FormBuilder";


function NoticeSurveyPane(props) {
    const {t} = useTranslation(['noticeTemplate', 'common']);

    const {
        parameters,
        surveyFormOptions,

        handler,
        isDisabled
    } = props;


    const formOptionsHandler = createUpdateHandler({
        handler,
        name: 'surveyFormOptions',
        values: surveyFormOptions
    });


    return (
        <div className="pane-margin">

            <div className="display-input">
                <FormBuilder id="noticeTemplateFormBuilder" label={t('noticeTemplate:label.surveyFormOptions')}
                    parameters={parameters} formOptions={surveyFormOptions} setFormOptions={formOptionsHandler}
                    isEditActive isDisabled={isDisabled}
                />
            </div>
        </div>
    )
}

export default NoticeSurveyPane;
