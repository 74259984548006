import ReduxModel from "../generics/ReduxModel";
import {generateUUID4, getInputFormattedDateFromUtc, getUtcFromFormattedDate} from "../../utilities/helperFunctions";
import {noticeTypeKeys} from "../../utilities/constants";
import LegalHoldModel from "../legalhold/LegalHoldModel";


class NoticeConfiguration extends ReduxModel {

    constructor(model={}) {
        super(model);
        const {id, noticeId, noticeTemplateId, noticeTemplateVersion, enabled, requireResponse, respondByDate, respondByNDays=31, disableComments, disableAdminNotes,
            enableRemind, remindEveryNDays=7, reminderNoticeTemplateId, reminderNoticeId, escalateOnResponseFail, escalateNoticeTemplateId, escalateNoticeId,
            sendRecurringNoticeEveryN, recurringNoticeFrequency
        } = model || {};

        this.id = id || generateUUID4();
        this.noticeId = noticeId;
        this.noticeTemplateId = noticeTemplateId;
        this.noticeTemplateVersion = noticeTemplateVersion;
        this.enabled = enabled;

        this.requireResponse = requireResponse;
        // Date takes priority over days if both defined
        if (!!respondByDate) {
            this.respondByKey = 'respondByDate';
        } else {
            this.respondByKey = 'respondByNDays';
        }
        this.respondByDate = getInputFormattedDateFromUtc(0, respondByDate);
        this.respondByNDays = respondByNDays;

        this.enableRemind = enableRemind;
        this.remindEveryNDays=  remindEveryNDays;
        this.reminderNoticeTemplateId = reminderNoticeTemplateId;
        this.reminderNoticeId = reminderNoticeId;

        this.escalateOnResponseFail = escalateOnResponseFail;
        this.escalateNoticeTemplateId = escalateNoticeTemplateId;
        this.escalateNoticeId = escalateNoticeId;

        this.disableComments = disableComments;
        this.disableAdminNotes = disableAdminNotes;

        this.sendRecurringNoticeEveryN = sendRecurringNoticeEveryN;
        this.recurringNoticeFrequency = recurringNoticeFrequency;
    }

    isValid({state}) {
        if (!this.enabled && !(this.type === noticeTypeKeys.HOLD || this.type === noticeTypeKeys.RELEASE)) {
            return true;
        }
        if (this.noticeTemplateId == null) {
            return false;
        }

        // If requireResponse defined, require Date or NDays defined
        if (this.requireResponse) {
            switch (this.respondByKey) {
                case 'respondByDate':
                    if (state === LegalHoldModel.DRAFT || this.noticeId == null) {
                        if (!(new Date(this.respondByDate).getTime() > new Date().getTime())) return false;
                    }
                    break;
                case 'respondByNDays':
                    if (!this.respondByNDays) return false;
                    break;
            }
            // If enableRemind enabled, require NDays && templateId defined
            if (this.enableRemind && (!this.remindEveryNDays || !this.reminderNoticeTemplateId)) {
                return false;
            }
            // If escalateOnResponseFail enabled, require templateId defined
            if (this.escalateOnResponseFail && !this.escalateNoticeTemplateId) {
                return false;
            }
        }
        return true;
    }

    wasEnabledDisabled(oldConfig={}) {
        if (!!this.enabled !== !!oldConfig.enabled) {
            return true;
        }
        if (!!this.enableRemind !== !!oldConfig.enableRemind) {
            return true;
        }
        if (!!this.escalateOnResponseFail !== !!oldConfig.escalateOnResponseFail) {
            return true;
        }

        return false;
    }

    clearSensitive() {
        this.id = generateUUID4();
        this.noticeId = null;
        this.reminderNoticeId = null;
        this.escalateNoticeId = null;
    }

    getSaveValues() {
        const {noticeTemplateVersion, respondByKey, respondByDate, ...rest} = this;

        if (respondByKey === 'respondByDate') {
            rest.respondByDate = getUtcFromFormattedDate(respondByDate);
        }
        return rest;
    }

    getEditValues() {
        return this.duplicate();
    }
}

export default NoticeConfiguration;
