import React from 'react';
import {connect} from 'react-redux';
import {useTranslation} from "react-i18next";
import {AddButtonHeader} from "../../common/Button/Button";
import {getCloudLicenceSourceIds} from '../../../reselect/selectors';
import ClsLicenceSourceRow from './ClsLicenceSourceRow';
import {includesSome, isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import LicenceSourceModel from "../../../models/settings/LicenceSourceModel";
import {applicationFeatures} from "../../../utilities/constants";

const ClsLicenceSourceTable = props => {
    const {cloudLicenceSourceIds, showClsLicenceSourceForm, userFeatures} = props;
    const {t} = useTranslation(['aria', 'nuixLicenceSource', 'common']);

    const canAddLicenceSource = includesSome(userFeatures, [applicationFeatures.ADD_RESOURCE, applicationFeatures.ADD_NUIX_LICENCE_SOURCE]);
    const isNotEmpty = isNotEmptyNorFalsy(cloudLicenceSourceIds);
    if (!canAddLicenceSource && !isNotEmpty) {
        return null;
    }

    return (
        <div className="display-item">
            <AddButtonHeader aria-label={t('aria:hiddenAssistText.addClsLicenceSource')}
                text={t('nuixLicenceSource:label.clsLicenceSourceName')}
                canModify={canAddLicenceSource} onClick={showClsLicenceSourceForm}
            />

            {isNotEmpty &&
                <div className="display-table">
                    <div className="table-header-group">
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('common:label.name')}
                            </label>
                        </div>
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('nuixLicenceSource:label.filter')}
                            </label>
                        </div>
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('common:label.username')}
                            </label>
                        </div>
                    </div>

                    <div className="table-row-group">
                        {cloudLicenceSourceIds.map(id =>
                            <ClsLicenceSourceRow key={id} nuixLicenceSourceId={id}/>
                        )}
                    </div>
                </div>
            }
        </div>
    );
}

const mapStateToProps = state => {
    return {
        cloudLicenceSourceIds: getCloudLicenceSourceIds(state),
        userFeatures: state.currentUser.features
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showClsLicenceSourceForm: () => dispatch(LicenceSourceModel.componentActionCreators.updateDisplay({isClsLicenceSourceFormActive: true}))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClsLicenceSourceTable);