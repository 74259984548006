import DetailsModel, {DetailsApi, DetailsSaga} from "../generics/DetailsModel";
import ComponentStateModel from "../generics/ComponentStateModel";
import {all, put, select} from "redux-saga/effects";
import {actionCreator} from "../../utilities/helperFunctions";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import {details, routes} from "../../utilities/constants";
import {contextCall, contextTakeLeading} from "../../saga/sagaFunctions";
import {settingsDisplayKeys} from "../../i18next/keys";
import SchedulerModel from "../scheduler/SchedulerModel";

class ServerModel extends DetailsModel {

    static nom = 'ServerModel';
    static actions = ServerModel.buildActions();
    static actionCreators = ServerModel.buildActionCreators(ServerModel.actions);
    static reducer = ServerModel.buildReducer(ServerModel.actions);

    static componentActionCreators = {
        ...ServerModel.buildComponentUpdateActionCreators(),
        ...ServerModel.buildComponentSetActiveActionCreators()
    };

    constructor(model = {}) {
        super(model);
        const { url, version, javaVersion, whitelistedCertFingerprints, status, maintenanceStatus, error } = model;

        this.url = url;
        this.version = version;
        this.javaVersion = javaVersion;
        this.whitelistedCertFingerprints = whitelistedCertFingerprints;
        this.maintenanceStatus = maintenanceStatus;
        this.error = error;
    }

    static buildActions() {
        return {
            ...super.buildActions('SERVER'),
            // SERVER ACTIONS
            SEND_COMMAND: 'SEND_SERVER_COMMAND'
        }
    }

    static buildActionCreators(actions) {
        return {
            ...super.buildActionCreators(actions),
            sendCommand: actionCreator(actions.SEND_COMMAND, 'id', 'command')
        }
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'serverDisplay',
                type: 'Display',
                state: {
                    serverId: null,
                    isServerFormActive: false
                }
            },
            {
                key: 'serverTablet',
                type: 'Tablet',
                state: {
                    isDisabled: false
                }
            },
            {
                key: 'serverForm',
                type: 'Form',
                state: {
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'SERVER_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }
}

export class ServerApi extends DetailsApi {

    static location = '/resources';
    static type = '/servers';

    static putCommand(id, command) {
        return axiosInstance.put(`/scheduler/resources/servers/${id}/command`, {command});
    }
}

export class ServerSaga extends DetailsSaga {

    static ModelType = ServerModel;
    static ModelApi = ServerApi;

    static activationComponent = 'SERVER_DISPLAY';
    static variableNames = {
        detailsMap: 'serverDetailsMap',
        instanceId: 'serverId',
        isFormActive: 'isServerFormActive',
        updateDisplay: 'updateDisplay',
        updatePane: 'updateTablet',
        route: routes.SETTINGS
    };

    static translations = {
        itemTitle: '$t(server:label.name_simple)',
        itemLower: '$t(server:label.name_simple_lower)'
    };

    static buildActivationEffects(dispatch) {
        return [
            ...super.buildActivationEffects(dispatch),
            // ACTIVATION EFFECTS
            contextTakeLeading(ServerModel.actions.SEND_COMMAND, this, 'sendCommand'),

            put(ServerModel.actionCreators.startPollingDetails())
        ]
    }

    static buildDeactivationEffects() {
        return [
            ...super.buildDeactivationEffects(),
            // DEACTIVATION EFFECTS
            put(ServerModel.actionCreators.stopPollingDetails())
        ]
    }

    static* setInstanceId(args) {
        const {updateDisplay, instanceId} = this.variableNames;

        yield all ([
            put(SchedulerModel.actionCreators.setSettingsDisplay(settingsDisplayKeys.SERVERS)),
            put(this.ModelType.componentActionCreators[updateDisplay]({[instanceId]: args.id}))
        ]);
    }

    static* sendCommand(action) {
        const {id, command} = action.payload;

        yield contextCall(ServerApi, 'putCommand', id, command);
    }

    static* getEditValues(id) {
        const {name: serverName, description, whitelistedCertFingerprints} = yield select(state => state.serverDetailsMap.get(id));

        return {
            serverName,
            description,
            whitelistedCertFingerprints
        }
    }

    static getSaveValues(values) {
        const {serverName: name, description, serverUrl: url, whitelistedCertFingerprints} = values;

        return {
            name,
            description,
            url,
            whitelistedCertFingerprints
        }
    }

    static* queryDetails() {
        const response = yield contextCall(ServerApi, 'getDetails');
        const key = details.SERVERS;

        if (AxiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(ServerModel.actionCreators.setDetailsMap(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ])
        }
    }
}

export default ServerModel;