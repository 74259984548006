import React, {useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {purviewExportReviewSetParameterNames} from "./PurviewExportReviewSetPane";
import {objectIdentifierType} from "../../../utilities/constants";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import TextArea from "../../common/TextArea/TextArea";
import Text from "../../common/Text/Text";
import SelectableTable from "../../common/Tables/SelectableTable";
import {StatusNameDescriptionCell, TableLabelCell} from "../../common/CustomTable/CustomTable";
import {getLocaleDateTimeFromUTC} from "../../../utilities/helperFunctions";
import PurviewCache from "../../../models/guidedjob/PurviewCache";
import {purviewUseCaseParameterNames} from "./PurviewUseCasePane";
import {purviewJobParameterNames} from "../PurviewGuidedJobForm";
import {Button} from "../../common/Button/Button";
import {icon} from "../../../utilities/iconResolver";
import RadioButton from "../../common/RadioButton/RadioButton";

function PurviewExportReviewSetQueryPane(props) {
    const {t} = useTranslation(['guidedJob', 'common']);
    const {
        proxyPurviewRequest,
        getParameter,
        updateParameter,
        isDisabled
    } = props;

    const caseIdentifier = getParameter(purviewUseCaseParameterNames.caseIdentifier);
    const reviewSetIdentifierType = getParameter(purviewExportReviewSetParameterNames.reviewSetIdentifierType);
    const reviewSetIdentifier = getParameter(purviewExportReviewSetParameterNames.reviewSetIdentifier);

    const exportReviewSetQuery = getParameter(purviewExportReviewSetParameterNames.exportReviewSetQuery, false, {parse: true});
    const reviewSetQueryIdentifierType = getParameter(purviewExportReviewSetParameterNames.reviewSetQueryIdentifierType);
    const reviewSetQueryIdentifier = getParameter(purviewExportReviewSetParameterNames.reviewSetQueryIdentifier);
    const kqlContentQuery = getParameter(purviewExportReviewSetParameterNames.kqlContentQuery);

    const useExistingReviewSet = reviewSetIdentifierType.value === objectIdentifierType.ID && reviewSetIdentifier.value !== purviewJobParameterNames.reviewSetId;
    const useExistingReviewSetQuery = reviewSetQueryIdentifierType.value === objectIdentifierType.ID;
    const createNewReviewSetQuery = !useExistingReviewSetQuery;

    const isUseExistingReviewSetQueryDisabled = isDisabled || !useExistingReviewSet;

    const reviewSetQueriesKey = PurviewCache.getResourceKey(PurviewCache.Resource.REVIEW_SET_QUERIES, {caseId: caseIdentifier.value, reviewSetId: reviewSetIdentifier.value});
    const reviewSetQueries = useSelector(state => state.purviewCache[reviewSetQueriesKey]) || {};
    const isLoadingReviewSetQueries = reviewSetQueries.cache == null;

    const headers = useRef([
        {label: t('common:label.name')},
        {label: t('common:label.createdDate'), noStretch: true}
    ]);
    
    useEffect(() => {
        if (exportReviewSetQuery.value && useExistingReviewSet && useExistingReviewSetQuery) {
            proxyPurviewRequest(PurviewCache.Resource.REVIEW_SET_QUERIES, {caseId: caseIdentifier.value, reviewSetId: reviewSetIdentifier.value});
        }
    }, [exportReviewSetQuery.value && useExistingReviewSet && useExistingReviewSetQuery]);

    useEffect(() => {
        if (!useExistingReviewSet) {
            updateParameter(purviewExportReviewSetParameterNames.reviewSetQueryIdentifierType, objectIdentifierType.NAME);
            updateParameter(purviewExportReviewSetParameterNames.reviewSetQueryIdentifier, '');
        }
    }, [useExistingReviewSet]);

    useEffect(() => {
        updateParameter(purviewExportReviewSetParameterNames.createNewOrUpdateIfExists, JSON.stringify(createNewReviewSetQuery));
    }, [createNewReviewSetQuery]);

    function inputHandler(event) {
        const {name, value} = event.target;
        updateParameter(name, value);
        if (name === purviewExportReviewSetParameterNames.reviewSetQueryIdentifierType && reviewSetQueryIdentifierType.value !== value) {
            updateParameter(purviewExportReviewSetParameterNames.reviewSetQueryIdentifier, '');
        }
    }

    function valueHandler(event) {
        const {name, value} = event.currentTarget.dataset;
        updateParameter(name, value);
    }


    return (
        <>
            <div className="flex-column" style={{marginTop: '0.5rem'}}>
                <RadioButton label={t('guidedJob:label.exportWholeReviewSet')} name={purviewExportReviewSetParameterNames.exportReviewSetQuery}
                    selected={exportReviewSetQuery.value} value={false} onClick={inputHandler} isDisabled={isDisabled}
                    style={{marginTop: '0.5rem', marginBottom: '1rem'}}/>

                <RadioButton label={t('guidedJob:label.exportReviewSetQuery')} name={purviewExportReviewSetParameterNames.exportReviewSetQuery}
                    selected={exportReviewSetQuery.value} value={true} onClick={inputHandler} isDisabled={isDisabled}/>
            </div>

            {exportReviewSetQuery.value &&
                <>
                <div className="display-input flex-column">
                    <Text value={t('guidedJob:label.reviewSetQuery')} style={{marginTop: '0.5rem', marginBottom: '0.25rem'}} isDisabled={isDisabled}/>
                    <RadioButton name={purviewExportReviewSetParameterNames.reviewSetQueryIdentifierType} label={t(`guidedJob:option.createNewOrUpdatePurviewReviewSetQuery`)}
                        style={{marginBottom: '1rem'}} selected={reviewSetQueryIdentifierType.value} value={objectIdentifierType.NAME}
                        onClick={inputHandler} isDisabled={isDisabled}/>

                    <RadioButton name={purviewExportReviewSetParameterNames.reviewSetQueryIdentifierType} label={t(`guidedJob:option.useExistingPurviewReviewSetQuery`)}
                        selected={reviewSetQueryIdentifierType.value} value={objectIdentifierType.ID} onClick={inputHandler}
                        isDisabled={isUseExistingReviewSetQueryDisabled}/>
                </div>

                {createNewReviewSetQuery &&
                    <div>
                        <div className="display-input">
                            <HTMLTextInput label={t('guidedJob:label.reviewSetQueryName')} name={purviewExportReviewSetParameterNames.reviewSetQueryIdentifier}
                                value={reviewSetQueryIdentifier.value} onChange={inputHandler} isRequired isDisabled={isDisabled}/>
                        </div>

                        <div className="display-input">
                            <TextArea label={t('guidedJob:label.kqlContentQuery')} name={purviewExportReviewSetParameterNames.kqlContentQuery}
                                placeholder={contentQueryPlaceHolder} value={kqlContentQuery.value} onChange={inputHandler}
                                isRequired isDisabled={isDisabled}
                                afterLabel={
                                    <a href={purviewReviewSetQueryGuideUrl} target="_blank" rel="noopener noreferrer" style={{marginLeft: '0.25rem'}}>
                                        <Button aria-label={t('aria:hiddenAssistText.purviewReviewSetKQLContentQueryGuide')} isImg isClear isDisabled={isDisabled}>
                                            <span className="icon">
                                                <img src={icon("operationHelper")} alt={t(`aria:hiddenAssistText.userGuideIcon`)}/>
                                            </span>
                                        </Button>
                                    </a>
                                }/>
                        </div>
                    </div>
                }

                {useExistingReviewSetQuery &&
                    <div className="display-input">
                        <div style={{display: 'flex', marginBottom: '0.25rem'}}>
                            <Text value={`${t('guidedJob:label.reviewSetQuery')}:`} style={{marginRight: '0.5rem'}} isDisabled={isUseExistingReviewSetQueryDisabled}/>
                            <Text value={reviewSetQueryIdentifier.value} isDisabled={isUseExistingReviewSetQueryDisabled}/>
                        </div>

                        <SelectableTable name={purviewExportReviewSetParameterNames.reviewSetQueryIdentifier} items={reviewSetQueries.cache}
                            selectedItemId={reviewSetQueryIdentifier.value} onItemSelect={valueHandler} isLoading={isLoadingReviewSetQueries}
                            emptyMessage={t('guidedJob:message.purviewReviewSetHasNoQueries')} headers={headers.current}
                            isDisabled={isUseExistingReviewSetQueryDisabled} isStale={reviewSetQueries.stale} isRequired
                            RowComponent={props => (
                                <>
                                    <StatusNameDescriptionCell name={props.displayName} description={props.contentQuery}/>
                                    <TableLabelCell label={getLocaleDateTimeFromUTC(props.createdDate)}/>
                                </>
                            )}
                        />
                    </div>
                }
                </>
            }
        </>
    )
}

const purviewReviewSetQueryGuideUrl = 'https://learn.microsoft.com/en-us/purview/ediscovery-document-metadata-fields';
const contentQueryPlaceHolder = '(Custodian="leeg@nd80.onmicrosoft.com" OR Custodian="miriamg@nd80.onmicrosoft.com" OR Custodian="pradeepg@nd80.onmicrosoft.com")';

export default PurviewExportReviewSetQueryPane;
