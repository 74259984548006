import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import './ListContainer.css';
import NavigateButtons from "../NavigateButtons/NavigateButtons";
import {NameSelectableItem} from "../SelectableItem/SelectableItem";
import SearchBar from "../SearchBar/SearchBar";
import {
    buildClassName,
    nameLocaleCompare,
    objectTruthyValues,
    stringToBool,
    textContainsEverySearchWord
} from "../../../utilities/helperFunctions";
import {useTranslation} from "react-i18next";
import {
    getRenderedHeightOffset,
    initialSelectedState,
    useClearSelectedEffect,
    useRenderedItemHeight,
    useSimpleVirtualRendering,
    useValueSelectHandler
} from "../../../utilities/hooks";
import Checkbox from "../Checkbox/Checkbox";
import LoadingWrapper from "../LoadingWrapper/LoadingWrapper";
import Text from "../Text/Text";
import {ImportCsvButton} from "../Button/Button";

export function ItemContainer(props) {
    const {t} = useTranslation(['common']);

    const {
        parentRef,
        selected,
        setSelected,
        ItemComponent=NameSelectableItem,

        label,
        items,
        filter,
        canShowSelected,
        emptyMessage,

        enableVirtualRendering,
        isRequired,
        isSearchable,
        searchableFields,
        isLoading,
        isDisabled,
        force,
        ...attr
    } = props;

    useEffect(() => {
        if (force != null && force.selected != null) {
            setSelected(force.selected);
        }
    }, [force]);

    const containerRef = useRef();
    useEffect(() => {
        if (parentRef != null) {
            containerRef.current = parentRef.current;
        }

    }, [parentRef]);

    const filteredIndicesRef = useRef(new Set());
    const valueSelectHandler = useValueSelectHandler({setSelected, filteredIndicesRef});
    useClearSelectedEffect({containerRef, setSelected});

    const [searchText, setSearchText] = useState('');
    const [showSelected, setShowSelected] = useState(false);

    function searchHandler(event) {
        const {value} = event.target;
        setSearchText(value);
        // Reset selected values
        // setSelected(initialSelectedState);
    }

    function showSelectedHandler(event) {
        const {checked} = event.target;
        setShowSelected(stringToBool(checked));
    }

    const [filteredItems, setFilteredItems] = useState([]);
    useEffect(() => {
        filteredIndicesRef.current.clear();
        const newFilteredItems = [];

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            let passesFilter = true;

            if (filter != null) {
                if (filter.type != null && item.type !== filter.type) {
                    passesFilter = false;
                }
                if (filter.regex != null && !filter.regex.test(item.regexValue)) {
                    passesFilter = false;
                }
            }

            let itemText = (item.name || '');
            if (Array.isArray(searchableFields)) {
                searchableFields.forEach(field => {
                    if (typeof item[field] === 'string')
                        itemText += item[field];
                });
            }

            if (!textContainsEverySearchWord(searchText, itemText)) {
                passesFilter = false;
            }
            if (showSelected && !selected.values[i]) {
                passesFilter = false;
            }

            if (passesFilter) {
                newFilteredItems.push({...item, index: i});
                filteredIndicesRef.current.add(i);
            }
        }

        setFilteredItems(newFilteredItems);
    }, [items, showSelected, selected.values, searchText, filter]);

    const listContainerBoxProps = {
        selected,
        valueSelectHandler,
        items: filteredItems,
        ItemComponent,
        emptyMessage,
        isLoading,
        isDisabled
    };

    const listContainerBoxClassName = buildClassName(
        'list-container-box',
        (isRequired && items.length === 0) && 'is-required',
        isDisabled && 'disabled'
    );

    return (
        <div className="list-container" ref={containerRef} {...attr}>
            <Text value={label} isNote isItalic isCentered
                isDisabled={isDisabled}/>

            {canShowSelected &&
            <div style={{marginBottom: '0.25rem'}}>
                <Checkbox label={t('common:option.showSelected')} checked={showSelected} onClick={showSelectedHandler}
                    isDisabled={isDisabled}/>
            </div>
            }

            {(isSearchable || searchText) &&
            <div style={{marginBottom: '0.5rem'}}>
                <SearchBar value={searchText} onChange={searchHandler}
                    isDisabled={isDisabled}/>
            </div>
            }

            <div className={listContainerBoxClassName}>
                {enableVirtualRendering ?
                    <VirtualRenderedListContainerBox {...listContainerBoxProps}/>
                    :
                    <ListContainerBox {...listContainerBoxProps}/>
                }
            </div>
        </div>
    );
}

function ListContainerBox(props) {
    const {
        selected,
        valueSelectHandler,

        ItemComponent,
        items,
        emptyMessage,

        isLoading,
        isDisabled
    } = props;

    return (
        <>
            <LoadingWrapper isLoading={isLoading} isDisabled={isDisabled}>
                {items.map(item => {
                    const isSelected = selected.values[item.index];
                    return (
                        <ItemComponent key={item.index} index={item.index} item={item} onItemClick={valueSelectHandler}
                            isSelected={isSelected} isDisabled={isDisabled} isBorder isCentered isWordWrap/>
                    )
                })}

                {emptyMessage && Array.isArray(items) && items.length === 0 &&
                    <Text value={emptyMessage} className="absolutely-centered" style={{position: 'relative', textAlign: 'center'}}
                        isItalic isDisabled={isDisabled}/>
                }
            </LoadingWrapper>
        </>
    );
}

function VirtualRenderedListContainerBox(props) {
    const {
        selected,
        valueSelectHandler,

        ItemComponent,
        items,
        emptyMessage,

        isLoading,
        isDisabled
    } = props;

    const listContainerRef = useRef();

    const listContainerSelector = '.list-container-box';
    const itemHeightRef = useRenderedItemHeight(listContainerRef, listContainerSelector, 31.1875);
    const heightOffset = getRenderedHeightOffset(listContainerRef, listContainerSelector, 0);
    const virtualRenderRows = useSimpleVirtualRendering({
        containerRef: listContainerRef,
        itemHeightRef,
        size: items.length,
        heightOffset
    });

    return (
        <div ref={listContainerRef}>
            <LoadingWrapper isLoading={isLoading} isDisabled={isDisabled}>
                {virtualRenderRows((index, topOffset) => {
                    const item = items[index];
                    if (item == null) {
                        return null;
                    }

                    const isSelected = selected.values[item.index];
                    return (
                        <ItemComponent key={item.index} index={item.index} item={item} onItemClick={valueSelectHandler} style={{position: 'relative', top: `${topOffset}px`}}
                            isSelected={isSelected} isDisabled={isDisabled} isBorder isCentered isWordWrap={false}/>
                    )
                })}

                {emptyMessage && Array.isArray(items) && items.length === 0 &&
                    <Text value={emptyMessage} className="absolutely-centered" style={{position: 'relative', textAlign: 'center'}}
                        isItalic isDisabled={isDisabled}/>
                }
            </LoadingWrapper>
        </div>
    )
}

// items = {left: [], right: []}
export function SwitchItemContainer(props) {
    const {t} = useTranslation(['common']);

    const containerRef = useRef();
    const [selectedLeft, setSelectedLeft] = useState(initialSelectedState);
    const [selectedRight, setSelectedRight] = useState(initialSelectedState);

    const {
        label,
        ariaLabelKey,
        filter={},
        items,
        setItems,
        callbacks={},
        emptyMessage,
        ItemComponent,
        LeftItemComponent=ItemComponent,
        RightItemComponent=ItemComponent,
        OptionsComponent=NavigateButtons,
        optionComponentProps,
        enableVirtualRendering,
        isSearchable,
        searchableFields,
        canShowSelected,
        isLoading,
        isRequired,
        isDisabled,
        force,
        ...attr
    } = props;

    const [forceLeft, setForceLeft] = useState({});
    const [forceRight, setForceRight] = useState({});
    useEffect(() => {
        if (force != null && force.left) {
            setForceLeft(force.left);
        }
        if (force != null && force.right) {
            setForceRight(force.right);
        }
    }, [force]);

    const [sortedItems, setSortedItems] = useState({left: [], right: []});
    useLayoutEffect(() => {
        const sorted = {left: [], right: []};
        if (Array.isArray(items.left)) {
            sorted.left = [...items.left].sort(nameLocaleCompare);
        }
        if (Array.isArray(items.right)) {
            sorted.right = [...items.right].sort(nameLocaleCompare);
        }
        setSortedItems(sorted);
    }, [items]);

    const switchItems = useCallback((from, to, selected, setSelected, callback) => {
        return function () {
            setItems(() => {
                const selectedIndices = objectTruthyValues(selected.values);
                // Remove items from items.right and push to items.left
                const fromItems = [], toItems = [...sortedItems[to]];
                const switchedItems = [];

                for (let i = 0; i < sortedItems[from].length; i++) {
                    const item = sortedItems[from][i];
                    // If item is selected, push to to, else leave in from
                    if (selectedIndices.includes(i.toString())) {
                        toItems.push(item);
                        switchedItems.push(item);
                    } else {
                        fromItems.push(item);
                    }
                }

                if (typeof callback === 'function') {
                    callback(switchedItems);
                }
                return {
                    [from]: fromItems,
                    [to]: toItems
                }
            });

            setSelected(initialSelectedState);
        }
    }, [sortedItems]);

    const switchItemsLeft = switchItems('left', 'right', selectedLeft, setSelectedLeft, callbacks.left);
    const switchItemsRight = switchItems('right', 'left', selectedRight, setSelectedRight, callbacks.right);

    const selectedLeftCount = objectTruthyValues(selectedLeft.values).length;
    const selectedRightCount = objectTruthyValues(selectedRight.values).length;

    const disabled = isDisabled ? ' is-disabled' : '';

    return (
        <>
            {label &&
            <label className={'label' + disabled}>
                {label}
            </label>
            }

            <div ref={containerRef} className="switch-container" {...attr}>
                <div className="left-container">
                    <ItemContainer label={t('common:label.available')} items={sortedItems.left} parentRef={containerRef} filter={filter.left}
                        selected={selectedLeft} setSelected={setSelectedLeft} isSearchable={isSearchable} searchableFields={searchableFields}
                        canShowSelected={canShowSelected} enableVirtualRendering={enableVirtualRendering} emptyMessage={emptyMessage}
                        force={forceLeft} ItemComponent={LeftItemComponent} isLoading={isLoading} isDisabled={isDisabled}/>
                </div>

                <OptionsComponent ariaLabelKey={ariaLabelKey} onNavigateLeft={switchItemsLeft} onNavigateRight={switchItemsRight} items={sortedItems}
                    selectedLeftCount={selectedLeftCount} selectedRightCount={selectedRightCount} isDisabled={isDisabled}
                    {...optionComponentProps} />

                <div className="right-container">
                    <ItemContainer label={t('common:label.selected')} items={sortedItems.right} parentRef={containerRef} filter={filter.right}
                        selected={selectedRight} setSelected={setSelectedRight} isSearchable={isSearchable} searchableFields={searchableFields}
                        canShowSelected={canShowSelected} isRequired={isRequired} enableVirtualRendering={enableVirtualRendering}
                        force={forceRight} ItemComponent={RightItemComponent} isDisabled={isDisabled}/>
                </div>
            </div>
        </>
    );
}

export function ImportExportContainerOptions(props) {
    const {
        ariaLabelKey,
        items,
        importCsv,
        parseText,
        selectedLeftCount,
        selectedRightCount,
        onNavigateLeft,
        onNavigateRight,
        isDisabled
    } = props;

    const leftCount = (items.left || []).length;
    const isImportDisabled = isDisabled || leftCount === 0;

    return (
        <div style={{flex: '0.2 1', margin: 'auto 0'}}>
            <div style={{display: 'flex', justifyContent: 'center', marginBottom: '0.25rem'}}>
                <ImportCsvButton ariaLabelKey={ariaLabelKey} importCsv={importCsv}
                    parseText={parseText} isDisabled={isImportDisabled}/>
            </div>

            <NavigateButtons ariaLabelKey={ariaLabelKey} onNavigateLeft={onNavigateLeft} onNavigateRight={onNavigateRight}
                selectedLeftCount={selectedLeftCount} selectedRightCount={selectedRightCount}/>
        </div>
    )
}

function ListContainer(props) {
    const {itemComponent = NameSelectableItem, label, items, selectedItems, onItemClick, height, isRequired, isSearchable,
        searchPlaceholder, searchStyle, isDisabled} = props;

    const required = (isRequired && items.length === 0) ? ' is-required' : '';
    const disabled = isDisabled ? ' is-disabled' : '';

    const [searchText, setSearchText] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const filteredIndices = useRef(new Set());

    useEffect(() => {
        filteredIndices.current.clear();
        const newFilteredItems = [];

        for (let i = 0; i < items.length; i++) {
            const item = items[i];

            const itemText = (item.name || '').toLowerCase();
            if (textContainsEverySearchWord(searchText, itemText)) {
                newFilteredItems.push({...item, index: i});
                filteredIndices.current.add(i);
            }
        }

        setFilteredItems(newFilteredItems);
    }, [items, searchText]);

    const onItemSelect = useCallback(e => {
        onItemClick(e, filteredIndices.current);
    }, [onItemClick]);

    return (
        <div className="list-container">
            <label className={'label is-italic' + disabled} style={{textAlign: 'center', fontSize: '0.90rem'}}>
                {label}
            </label>
            {(isSearchable || searchText) &&
            <div style={!!searchStyle ? searchStyle : {marginBottom: '0.5rem'}}>
                <SearchBar value={searchText} placeholder={searchPlaceholder} onChange={event => setSearchText(event.target.value)}/>
            </div>
            }
            <div className={'list-container-box' + required} style={{height}}>
                {filteredItems.map(item => {
                    const _props = {
                        key: item.index,
                        ['data-index']: item.index,
                        item,
                        onItemClick: onItemSelect,
                        isSelected: selectedItems[item.index],
                        isBorder: true,
                        isCentered: true,
                        isWordWrap: true,
                        isDisabled
                    };
                    if (item.isItalic) {
                        _props.isItalic = item.isItalic;
                    }
                    return itemComponent(_props);
                })}
            </div>
        </div>
    );
}


export function SwitchListContainers(props) {
    const {id, leftContainer, navigateButtonsProps, rightContainer, ariaLabelKey} = props;

    return (
        <div id={id} className="switch-container">
            <div className="left-container">
                {leftContainer}
            </div>
            <NavigateButtons ariaLabelKey={ariaLabelKey} {...navigateButtonsProps} />
            <div className="right-container">
                {rightContainer}
            </div>
        </div>
    );
}

export default ListContainer;