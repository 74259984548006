import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import Form, {FormFooter, FormHeader} from "../../common/Form/Form";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import {getInputFormattedDate, getValues, isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import {SwitchItemContainer} from "../../common/ListContainer/ListContainer";
import SmtpEmailModel from "../../../models/settings/SmtpEmailModel";
import {useInputHandler, useUpdateHandler} from "../../../utilities/formHooks";
import ThirdPartyServiceModel from "../../../models/thirdparty/ThirdPartyServiceModel";
import {createCloseHandler} from "../../../utilities/componentFunctions";
import PopupModel from "../../../models/scheduler/PopupModel";


function getInitialState() {
    const fromDate = new Date();
    fromDate.setFullYear(fromDate.getFullYear() - 4);

    const toDate = new Date();
    toDate.setMonth(toDate.getMonth() + 1);

    return {
        fromDate: getInputFormattedDate(fromDate),
        toDate: getInputFormattedDate(toDate),
    };
}

function SmtpServiceExportForm(props) {
    const {t} = useTranslation(['smtpEmail', 'smtpServer']);
    const dispatch = useDispatch();

    const {
        initiatorId,
        thirdPartyService,
        onClose,
        isDisabled
    } = props;

    const [emailStates, setEmailStates] = useState({
        left: getValues(SmtpEmailModel.State).map(state => ({name: t(`smtpEmail:state.${state}`), value: state})),
        right: []
    });

    const [state, setState] = useState(getInitialState);
    const updateHandler = useUpdateHandler({
        updateState: setState
    })
    const inputHandler = useInputHandler({
        updateHandler
    });

    const onSubmit = useCallback(() => {
        const from = new Date(state.fromDate.split('-'));
        const to = new Date(state.toDate.split('-'));
        to.setDate(to.getDate() + 1);
        to.setMilliseconds(to.getMilliseconds() - 1);

        const options = {
            initiatorId,
            states: emailStates.right.map(item => item.value),
            afterDate: from.getTime(),
            beforeDate: to.getTime()
        };
        dispatch(ThirdPartyServiceModel.actionCreators.exportCsv(thirdPartyService.id, options));

    }, [thirdPartyService.id, initiatorId, state, emailStates]);

    const closeHandler = createCloseHandler({
        popupKey: `smtpServiceExportCsvDiscard`,
        state,
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        close: onClose
    });


    const _fromDate = new Date(state.fromDate);
    const _toDate = new Date(state.toDate);

    const areDatesValid = _fromDate < _toDate;
    const isSubmitEnabled = isNotEmptyNorFalsy(emailStates.right) && areDatesValid;

    return (
        <Form onClose={closeHandler} isDisabled={isDisabled} closeButtonAriaLabel={t('thirdPartyService:option.closeExportFormSmtpService')}
            header={
                <FormHeader text={t('thirdPartyService:label.exportEmailsCsv')} iconName={'smtpServerExport'} isDisabled={isDisabled}/>
            }
            body={
                <>
                    <div className="display-input">
                        <HTMLTextInput id="fromDate" name={'fromDate'} value={state.fromDate} onChange={inputHandler}
                            label={t('common:label.from')} type={'date'} isInvalid={!areDatesValid} isRequired isDisabled={isDisabled}
                        />
                    </div>

                    <div className="display-input">
                        <HTMLTextInput id="toDate" name={'toDate'} value={state.toDate} onChange={inputHandler}
                            label={t('common:label.to')} type={'date'} isInvalid={!areDatesValid} isRequired isDisabled={isDisabled}
                        />
                    </div>

                    <div className="display-input">
                        <SwitchItemContainer items={emailStates} setItems={setEmailStates}
                            ariaLabelKey={'SmtpEmailState'} label={t('common:label.states')} isRequired isDisabled={isDisabled}/>
                    </div>
                </>
            }
            footer={
                <FormFooter addText={t('common:option.export')} onAddClick={onSubmit} onCancel={closeHandler}
                    isAddEnabled={isSubmitEnabled} isDisabled={isDisabled}
                />
            }
        />
    )
}

export default SmtpServiceExportForm;
