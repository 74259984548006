import {executionStateKeys, settingsDisplayKeys, webhookPlatformKeys} from "../i18next/keys";

export const FAST_QUERY_INTERVAL = 1000;
export const QUERY_INTERVAL = 5000;
export const MED_QUERY_INTERVAL = 3 * QUERY_INTERVAL;
export const SLOW_QUERY_INTERVAL = 6 * QUERY_INTERVAL;

export const CLIENT_UNASSIGNED = "CLIENT_UNASSIGNED";
export const RESOURCE_POOL_UNASSIGNED = "RESOURCE_POOL_UNASSIGNED";
export const SAME_AS_TRIGGERING_JOB = "SAME_AS_TRIGGERING_JOB";
export const SAME_AS_TRIGGERING_DATASET = "SAME_AS_TRIGGERING_DATASET";
export const SAME_AS_TRIGGERING_LEGAL_HOLD = "SAME_AS_TRIGGERING_LEGAL_HOLD";

export const PASSWORD_PLACEHOLDER = Array(24).fill("*").join('');
export const MIN_SIZE_FOR_SEARCH = 10;

export const pathSplitterRegex = /[\\/]+/g;
export const workflowParameterHighlightRegex = new RegExp("(\{[a-z0-9_]+\})", 'g');

export const libraryConstants = {
    SAME_WORKFLOW_AS_JOB: "SAME_WORKFLOW_AS_JOB",
    WORKFLOW_UPLOAD: "WORKFLOW_UPLOAD"
};

export const updateMode = {
    LOCAL: 'REACT_LOCAL_STATE',
    REDUX: 'REDUX_STATE',
    EDIT: 'EDIT_DETAILS',
    CUSTOM: 'CUSTOM'
};

export const resourcesLinks = {
    PARAMETERS_GUIDE: (language) => `resources/${language}/parameters-guide.html`,
    WORKFLOW_DESIGN_GUIDE: (language) => `resources/${language}/workflow-design-guide.html`,
    USER_GUIDE: (language) => `resources/${language}/user-guide.html`,
    INSTALLATION_GUIDE: (language) => `resources/${language}/installation-guide.html`,
    AGREEMENTS_TERMS: 'https://www.nuix.com/legal',
    THIRD_PARTY_LICENCES: 'resources/third-party.txt',
    OPENAPI: 'openapi/',
    ODATA_REPORTING: 'api/v2/reporting/odata',
    PRIVACY_POLICY: 'https://www.nuix.com/privacy-policy'
};

export const licenseFeatures = {
    SCHEDULER: 'SCHEDULER',
    LEGAL_HOLD: 'LEGAL_HOLD',
    COLLECTION: "DUMMY",
    COLLECTION_ECC: 'COLLECTION_ECC',
    COLLECTION_PURVIEW: 'COLLECTION_PURVIEW',
    COLLECTION_VAULT: 'COLLECTION_VAULT',
    NUIX_DISCOVER: 'NUIX_DISCOVER',
    NUIX_NLP: 'NUIX_NLP',
    RELATIVITY_REVIEW: 'RELATIVITY_REVIEW',
    DATA_UPLOAD: 'DATA_UPLOAD',
    API_KEY: 'API_KEY',
    WEBHOOK: 'WEBHOOK',
    SCHEDULES: 'SCHEDULES',
    CUSTOM_BRANDING: 'CUSTOM_BRANDING',
    CLOUD_RESOURCE_POOLS: 'CLOUD_RESOURCE_POOLS',
    DASHBOARD: 'DASHBOARD',
    JOB_SIDE_SCRIPTING: 'JOB_SIDE_SCRIPTING',
    NATIVE_ENGINES: 'NATIVE_ENGINES',
    REMOTE_ENGINES: 'REMOTE_ENGINES'
};

export const applicationFeatures = {
    VIEW_JOBS: 'VIEW_JOBS',
    VIEW_PURVIEW_JOBS: 'VIEW_PURVIEW_JOBS',
    VIEW_VAULT_JOBS: 'VIEW_VAULT_JOBS',
    VIEW_SCHEDULES: 'VIEW_SCHEDULES',
    VIEW_LIBRARIES: 'VIEW_LIBRARIES',
    VIEW_CLIENTS: 'VIEW_CLIENTS',
    VIEW_NUIX_LICENCE_SOURCES: 'VIEW_NUIX_LICENCE_SOURCES',
    VIEW_RESOURCE_POOLS: 'VIEW_RESOURCE_POOLS',
    VIEW_NOTIFICATION_RULES: 'VIEW_NOTIFICATION_RULES',
    VIEW_EXECUTION_PROFILES: 'VIEW_EXECUTION_PROFILES',
    VIEW_CLIENT_POOLS: 'VIEW_CLIENT_POOLS',
    VIEW_DATA_REPOSITORIES: 'VIEW_DATA_REPOSITORIES',
    VIEW_THIRD_PARTY_SERVICES: 'VIEW_THIRD_PARTY_SERVICES',
    VIEW_FILE_LIBRARIES: 'VIEW_FILE_LIBRARIES',
    VIEW_NOTICE_TEMPLATES: 'VIEW_NOTICE_TEMPLATES',
    VIEW_LEGAL_HOLDS: 'VIEW_LEGAL_HOLDS',
    VIEW_LEGAL_HOLD_NOTICES: 'VIEW_LEGAL_HOLD_NOTICES',
    VIEW_SECURITY: 'VIEW_SECURITY',
    VIEW_RESOURCES: 'VIEW_RESOURCES',
    VIEW_USER_SETTINGS: 'VIEW_USER_SETTINGS',
    VIEW_AUTOMATE_LICENCE: 'VIEW_AUTOMATE_LICENCE',
    VIEW_USER_DATA_DIR: 'VIEW_USER_DATA_DIR',
    VIEW_USER_SERVICES: 'VIEW_USER_SERVICES',
    VIEW_API_KEYS: 'VIEW_API_KEYS',
    VIEW_WEBHOOKS: 'VIEW_WEBHOOKS',
    VIEW_DERBY_CONTROL_SERVICE: 'VIEW_DERBY_CONTROL_SERVICE',
    VIEW_SEMANTIC_SERVICE: 'VIEW_SEMANTIC_SERVICE',
    VIEW_INVESTIGATE_SERVICE: 'VIEW_INVESTIGATE_SERVICE',
    VIEW_DISCOVER_SERVICE: 'VIEW_DISCOVER_SERVICE',
    ADD_JOB: 'ADD_JOB',
    STAGE_JOB: 'STAGE_JOB',
    ADD_PURVIEW_JOB: 'ADD_PURVIEW_JOB',
    ADD_VAULT_JOB: 'ADD_VAULT_JOB',
    ADD_SCHEDULE: 'ADD_SCHEDULE',
    ADD_JOB_UNASSIGNED_CLIENT: 'ADD_JOB_UNASSIGNED_CLIENT',
    ADD_LIBRARY: 'ADD_LIBRARY',
    ADD_CLIENT: 'ADD_CLIENT',
    ADD_NUIX_LICENCE_SOURCE: 'ADD_NUIX_LICENCE_SOURCE',
    ADD_RESOURCE_POOLS: 'ADD_RESOURCE_POOLS',
    ADD_NOTIFICATION_RULE: 'ADD_NOTIFICATION_RULE',
    ADD_EXECUTION_PROFILE: 'ADD_EXECUTION_PROFILE',
    ADD_CLIENT_POOL: 'ADD_CLIENT_POOL',
    ADD_DATA_REPOSITORY: 'ADD_DATA_REPOSITORY',
    ADD_THIRD_PARTY_SERVICE: 'ADD_THIRD_PARTY_SERVICE',
    ADD_ADVANCED: 'ADD_ADVANCED',
    ADD_FILE_LIBRARY: 'ADD_FILE_LIBRARY',
    ADD_NOTICE_TEMPLATE: 'ADD_NOTICE_TEMPLATE',
    ADD_LEGAL_HOLD: 'ADD_LEGAL_HOLD',
    ADD_RESOURCE: 'ADD_RESOURCE',
    ADD_SECURITY: 'ADD_SECURITY',
    ADD_API_KEYS: 'ADD_API_KEYS',
    ADD_WEBHOOKS: 'ADD_WEBHOOKS',
    MODIFY_DEFAULT_USER_SETTINGS: 'MODIFY_DEFAULT_USER_SETTINGS',
    MODIFY_AUTOMATE_LICENCE: 'MODIFY_AUTOMATE_LICENCE',
    MODIFY_USER_DATA_DIR: 'MODIFY_USER_DATA_DIR',
    MODIFY_USER_SERVICES: 'MODIFY_USER_SERVICES',
    DOWNLOAD_SYSTEM_LOGS: "DOWNLOAD_SYSTEM_LOGS",
    DOWNLOAD_UTILIZATION_FULL: 'DOWNLOAD_UTILIZATION_FULL',
    DOWNLOAD_UTILIZATION_ANONYMOUS: 'DOWNLOAD_UTILIZATION_ANONYMOUS',
    UPLOAD_UTILIZATION: 'UPLOAD_UTILIZATION',
    CENTRALIZED_LOGGING: "CENTRALIZED_LOGGING",
    VIEW_SENSITIVE_JOB: 'VIEW_SENSITIVE_JOB',
    VIEW_SENSITIVE_WORKFLOW: 'VIEW_SENSITIVE_WORKFLOW',
    GEN_AI: 'GEN_AI',
    ECC: 'ECC',
    PURVIEW: 'PURVIEW',
    VAULT: 'VAULT',
    REMOTE_ENGINES: 'REMOTE_ENGINES',
    CLOUD_RESOURCE_POOLS: 'CLOUD_RESOURCE_POOLS',
    SCHEDULER_UPLOAD: 'SCHEDULER_UPLOAD',
    API_ADVANCED: 'API_ADVANCED'
};

export const jobQueueStates = {
    BACKLOG: 'BACKLOG',
    STAGING: 'STAGING'
};

export const jobStates = {
    backlog: [executionStateKeys.NOT_STARTED, executionStateKeys.PENDING, executionStateKeys.PAUSED],
    running: [executionStateKeys.RUNNING, executionStateKeys.PAUSING, executionStateKeys.STOPPING, executionStateKeys.DISCONNECTED],
    completed: [executionStateKeys.CANCELLED, executionStateKeys.STOPPED, executionStateKeys.ERROR, executionStateKeys.FINISHED]
};

export const routes = {
    LOGIN: '/login',
    JOBS: '/jobs',
    LEGAL_HOLD: '/legalHold',
    CLIENTS: '/clients',
    LIBRARY: '/workflow-libraries',
    SETTINGS: '/settings',
    RESOURCES: '/resources',
    OIDCRESPONSE: '/oidcResponse',
    OIDC_ERROR: '/oidcError',
    JOB_PDF: '/jobPdf',
    USER_NOTICE: '/userNotice'
};

export const details = {
    QUEUE_FILTER: 'queueFilter',
    QUEUE_JOBS: 'queueJobs',
    SCHEDULE_JOBS: 'scheduleJobs',
    ARCHIVE_JOBS: 'archiveJobs',
    CLIENTS: 'clients',
    MATTERS: 'matters',
    LIBRARIES: 'libraries',
    WORKFLOW_TEMPLATES: 'workflowTemplates',
    NUIX_LICENCE_SOURCES: 'nuixLicenseSources',
    SERVERS: 'engineServers',
    ENGINES: 'engines',
    EXECUTION_PROFILES: 'executionProfiles',
    RESOURCE_POOLS: 'resourcePools',
    CLIENT_POOLS: 'clientPools',
    NOTIFICATION_RULES: 'notificationRules',
    DATA_REPOSITORIES: 'dataRepositories',
    DATASET: 'dataset',
    UPLOAD_INFOS: 'uploadInfos',
    FILE_UPLOAD_HISTORY: 'fileUploadHistory',
    LEGAL_HOLDS: 'legalHolds',
    NOTICE_TEMPLATES: 'noticeTemplates',
    API_KEYS: 'apiKeys',
    WEBHOOKS: 'webhooks',
    SMTP_EMAILS: 'smtpEmails',
    USER_NOTICES: 'userNotices',
    POLICIES: 'policies',
    USER_SETTINGS: 'userSettings',
    USER_SERVICES: 'authenticationServices',
    USERS: 'users',
    AUTOMATE_LICENCE: 'automateLicense',
    USER_DATA_DIR: 'userDataDir',
    RELATIVITY_PROXY: 'relativityProxy',
    UTILIZATION_STATISTICS: 'utilizationStatistics',
    FILE_LIBRARIES: 'fileLibraries',
    LIBRARY_FILES: 'libraryFiles',
    THIRD_PARTY_SERVICES: 'thirdPartyServices',
    USER_COMMENTS: 'objectUserComments'
};

export const settingsKeyToDetailsKey = {
    [settingsDisplayKeys.RELATIVITY_PROXY]: details.RELATIVITY_PROXY,
    [settingsDisplayKeys.AUTOMATE_LICENCE]: details.AUTOMATE_LICENCE,
    [settingsDisplayKeys.USER_SERVICES]: details.USER_SERVICES,
    [settingsDisplayKeys.NUIX_LICENCE_SOURCES]: details.NUIX_LICENCE_SOURCES,
    [settingsDisplayKeys.SERVERS]: details.SERVERS,
    [settingsDisplayKeys.ENGINES]: details.ENGINES,
    [settingsDisplayKeys.RESOURCE_POOLS]: details.RESOURCE_POOLS,
    [settingsDisplayKeys.EXECUTION_PROFILES]: details.EXECUTION_PROFILES,
    [settingsDisplayKeys.NOTIFICATION_RULES]: details.NOTIFICATION_RULES,
    [settingsDisplayKeys.DATA_REPOSITORIES]: details.DATA_REPOSITORIES,
    [settingsDisplayKeys.THIRD_PARTY_SERVICES]: details.THIRD_PARTY_SERVICES,
    [settingsDisplayKeys.API_KEYS]: details.API_KEYS,
    [settingsDisplayKeys.WEBHOOKS]: details.WEBHOOKS,
    [settingsDisplayKeys.POLICIES]: details.POLICIES,
    [settingsDisplayKeys.CLIENT_POOLS]: details.CLIENT_POOLS,
    [settingsDisplayKeys.SYSTEM_RESOURCES]: 'systemResources',
    [settingsDisplayKeys.USER_SETTINGS]: 'userSettings'
};

export const detailsKeyToSettingsKey = {
    [details.RELATIVITY_PROXY]: settingsDisplayKeys.RELATIVITY_PROXY,
    [details.AUTOMATE_LICENCE]: settingsDisplayKeys.AUTOMATE_LICENCE,
    [details.USER_SERVICES]: settingsDisplayKeys.USER_SERVICES,
    [details.NUIX_LICENCE_SOURCES]: settingsDisplayKeys.NUIX_LICENCE_SOURCES,
    [details.SERVERS]: settingsDisplayKeys.SERVERS,
    [details.ENGINES]: settingsDisplayKeys.ENGINES,
    [details.RESOURCE_POOLS]: settingsDisplayKeys.RESOURCE_POOLS,
    [details.EXECUTION_PROFILES]: settingsDisplayKeys.EXECUTION_PROFILES,
    [details.NOTIFICATION_RULES]: settingsDisplayKeys.NOTIFICATION_RULES,
    [details.DATA_REPOSITORIES]: settingsDisplayKeys.DATA_REPOSITORIES,
    [details.THIRD_PARTY_SERVICES]: settingsDisplayKeys.THIRD_PARTY_SERVICES,
    [details.API_KEYS]: settingsDisplayKeys.API_KEYS,
    [details.WEBHOOKS]: settingsDisplayKeys.WEBHOOKS,
    [details.POLICIES]: settingsDisplayKeys.POLICIES,
    [details.CLIENT_POOLS]: settingsDisplayKeys.CLIENT_POOLS,
    'systemResources': settingsDisplayKeys.SYSTEM_RESOURCES,
    'userSettings': [settingsDisplayKeys.USER_SETTINGS]
};

export const modelTypes = {
    jobQueue: '/jobs/queue',
    jobPurview: '/jobs/purview',
    jobVault: '/jobs/vault',
    jobSchedule: '/jobs/schedule',
    jobArchive: '/jobs/archive',
    client: '/clients',
    matter: '/clients/matter',
    library: '/workflow-libraries',
    workflowTemplate: '/workflow-libraries/workflowTemplate',
    legalHold: '/legalHold/matter',
    userNotice: '/legalHold/notice',
    overview: '/legalHold/overview',
    nuixLicenseSource: '/settings/nuixLicenseSource',
    engineServer: '/settings/engineServer',
    engine: '/settings/engine',
    resourcePool: '/settings/resourcePool',
    executionProfile: '/settings/executionProfile',
    clientPool: '/settings/clientPool',
    notificationRule: '/settings/notificationRule',
    noticeTemplate: '/settings/noticeTemplate',
    dataRepository: '/settings/dataRepository',
    apiKey: '/settings/apiKey',
    webhook: '/settings/webhook',
    policy: '/settings/securityPolicy'
};

export const componentKeys = {
    NOTES: 'NOTES',
    PARAMETERS_PANEL: 'PARAMETERS_PANEL'
};

export const userPlatforms = {
    INTERNAL: 'INTERNAL',
    UMS: 'UMS',
    LDAP: 'LDAP'
}

export const userSettings = {
    DID_USER_CHANGE: 'didUserChange',
    UI_LANGUAGE: 'uiLanguage',
    ACCESSIBILITY: 'accessibility',
    SHOW_DISABLED_ITEMS: 'showDisabledItems',
    JOB_CARD: 'jobCardDesigner',
    JOB_PANEL: 'jobPanel',
    QUEUE_JOB: 'queueJobDesigner',
    DEFAULT_JOB_SETTINGS: 'defaultValues',
    JOB_QUEUE_SORT: 'JOB_QUEUE_SORT',
    JOB_LANE: 'jobLaneOption',
    NEW_CLIENT_POOL: 'newClientPool',
    NEW_CLIENT: 'newClient',
    NEW_MATTER: 'newMatter',
    HIGHLIGHTS: 'highlights',
    TROUBLESHOOT: 'troubleshoot',
    DATASET_OPTION: 'datasetOption',
    LEGAL_HOLD: 'legalHoldOption',
    WORKFLOW_OPTION: 'workflowOption'
};

export const systemResources = {
    SYSTEM_LOGS: 'systemLogs'
}

export const jobCommands = {
    RESUBMIT_JOB: 'RESUBMIT_JOB',
    ABORT: 'ABORT',
    STOP: 'STOP',
    CANCEL: 'CANCEL',
    MOVE_TO_BACKLOG: 'MOVE_TO_BACKLOG',
    MOVE_TO_STAGING: 'MOVE_TO_STAGING',
    HARD_PAUSE: 'HARD_PAUSE',
    PAUSE: 'PAUSE',
    SKIP: 'SKIP',
    RESUME: 'RESUME',
    ARCHIVE: 'ARCHIVE',
    METRICS_INCLUDE: 'METRICS_INCLUDE',
    METRICS_EXCLUDE: 'METRICS_EXCLUDE'
};

export const datasetType = {
    MANAGED: 'MANAGED',
    IN_PLACE: 'IN_PLACE',
    AZURE_STORE_ACCOUNT: 'AZURE_STORAGE_ACCOUNT',
    ECC: 'ECC'
};

export const datasetCommands = {
    FINALIZE: 'FINALIZE',
    HIDE: 'HIDE',
    SHOW: 'SHOW',
    ARCHIVE: 'ARCHIVE',
    UNARCHIVE: 'UNARCHIVE',
    EXPIRE: 'EXPIRE'
};

export const userNoticeCommands = {
    ENABLE: 'ENABLE',
    DISABLE: 'DISABLE',
    DISABLE_COMMENTS: 'DISABLE_COMMENTS',
    ENABLE_COMMENTS: 'ENABLE_COMMENTS',
    RESET_SUBMISSION: 'RESET_SUBMISSION',
    CREATE_DATASET: 'CREATE_DATASET'
};

export const legalHoldCommands = {
    ACTIVATE: 'ACTIVATE',
    RELEASE: 'RELEASE',
    ARCHIVE: 'ARCHIVE',
    ISSUE_HOLD: 'ISSUE_HOLD',
    RELEASE_HOLD: 'RELEASE_HOLD'
};

export const legalHoldRoles = {
    ADMINISTRATOR: 'ADMINISTRATOR',
    CUSTODIAN: 'CUSTODIAN'
};

export const custodianState = {
    ON_HOLD: 'ON_HOLD',
    RELEASED: 'RELEASED'
};

export const legalHoldCollectionTrigger = {
    ON_HOLD: 'ON_HOLD',
    ON_RESPONSE: 'ON_RESPONSE'
};

export const legalHoldTriggerConfiguration = {
    ON_CUSTODIAN_HOLD: 'ON_CUSTODIAN_HOLD',
    ON_CUSTODIAN_RESPONSE: 'ON_CUSTODIAN_RESPONSE',
    ON_CUSTODIAN_REMINDER: 'ON_CUSTODIAN_REMINDER',
    ON_CUSTODIAN_ESCALATION: 'ON_CUSTODIAN_ESCALATION',
    ON_CUSTODIAN_RELEASE: 'ON_CUSTODIAN_RELEASE',
    ON_MATTER_ACTIVATE: 'ON_MATTER_ACTIVATE',
    ON_MATTER_RELEASE: 'ON_MATTER_RELEASE',
    ON_MATTER_ARCHIVED: 'ON_MATTER_ARCHIVED',
    ON_MATTER_CUSTODIANS_HOLD: 'ON_MATTER_CUSTODIANS_HOLD',
    ON_MATTER_CUSTODIANS_RELEASE: 'ON_MATTER_CUSTODIANS_RELEASE',
    ON_MATTER_DELETE: 'ON_MATTER_DELETE'
}

export const builtInDatasetMetadataHeaders = [
    'NAME', 'UPLOADED BY', 'SIZE (DISPLAY)', 'SIZE (BYTES)'
];

export const queueRowTypeKeys = {
    WORKFLOW: 'WORKFLOW',
    MATTER: 'MATTER'
}

export const licenceSourceTypes = {
    DONGLE: 'DONGLE',
    NMS: 'NMS',
    CLS: 'CLS'
};

export const licenseRelayTypes = {
    NONE: 'NONE',
    RELAY_CLS_AUTH: 'RELAY_CLS_AUTH',
    RELAY_NMS_AUTH: 'RELAY_NMS_AUTH'
};

export const resourcePoolTypes = {
    LOCAL: 'LOCAL',
    AWS: 'AWS',
    AZURE: 'AZURE'
};

export const azureVmSources = {
    vmNames: 'VM_NAMES',
    customVmImage: 'CUSTOM_VM_IMAGE',
    tags: 'TAGS'
};

export const azureVmTypes = {
    onDemand: 'ON_DEMAND',
    spot: 'SPOT'
};

export const noticeTypeKeys = {
    // GENERAL: 'GENERAL',
    HOLD: 'HOLD',
    RECURRING: 'RECURRING',
    RELEASE: 'RELEASE',
    SURVEY: 'SURVEY',
    REMINDER: 'REMINDER',
    ESCALATION: 'ESCALATION'
};

export const recurringNoticeFrequencyKeys = {
    DAYS: 'DAYS',
    MONTHS: 'MONTHS'
}

export const userCommentTypes = {
    REGULAR: 'REGULAR',
    ADMIN_NOTE: 'ADMIN_NOTE'
};

export const notificationRuleTypes = {
    EMAIL_SMTP: 'EMAIL_SMTP',
    WEBHOOK: 'WEBHOOK'
};

export const logEndpoints = {
    SYSTEM: "/scheduler/resources/logs/system",
    JOB: "/scheduler/resources/logs/job"
}

export const utilizationEndpoints = {
    FULL: "/scheduler/resources/utilization/full",
    ANONYMIZED: "/scheduler/resources/utilization/anonymized",
    INFERRED: "/scheduler/resources/utilization/inferred",
}

export const userAccountStateKeys = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    DELETED: 'DELETED'
}

export const scriptLanguageKeys = {
    PYTHON: 'PYTHON',
    RUBY: 'RUBY',
    ECMA_SCRIPT: 'ECMA_SCRIPT',
    POWERSHELL: 'POWERSHELL'
}

export const httpVerb = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT'
};

export const formElementTypes = {
    CHECKBOX: 'CHECKBOX',
    DATA_UPLOAD: 'DATA_UPLOAD',
    DATE: 'DATE',
    // DATE_TIME: 'DATE_TIME',
    DROPDOWN: 'DROPDOWN',
    HEADER: 'HEADER',
    INPUT: 'INPUT',
    NUMBER: 'NUMBER',
    TEXTAREA: 'TEXTAREA',
    TIME: 'TIME'
};

export const objectIdentifierType = {
    ID: "ID",
    NAME: "NAME",
    NAME_REGEX: "NAME_REGEX"
};

export const purviewDataSourceInputType = {
    ALL_CASE: 'ALL_CASE',
    FILE: 'FILE',
    IDS_JSON: 'IDS_JSON'
};

export const purviewDataSourceType = {
    USER: 'USER',
    UNIFIED_GROUP: 'UNIFIED_GROUP',
    SITE: 'SITE'
};

export const purviewDataLocationType = {
    EXCHANGE_LOCATION: 'EXCHANGE_LOCATION',
    SHAREPOINT_SITE: 'SHAREPOINT_SITE'
};

export const vaultMatterState = {
    OPEN: 'OPEN',
    CLOSED: 'CLOSED',
    DELETED: 'DELETED'
};

export const vaultCorpusTypes = {
    MAIL: 'MAIL',
    DRIVE: 'DRIVE',
    GROUPS: 'GROUPS',
    HANGOUTS_CHAT: 'HANGOUTS_CHAT',
    VOICE: 'VOICE'
};

export const vaultSearchMethods = {
    ACCOUNT: 'ACCOUNT',
    ORG_UNIT: 'ORG_UNIT',
    ENTIRE_ORG: 'ENTIRE_ORG',
    ROOM: 'ROOM',
    SITES_URL: 'SITES_URL',
    SHARED_DRIVE: 'SHARED_DRIVE'
};

export const vaultQueryDataScopes = {
    ALL_DATA: 'ALL_DATA',
    HELD_DATA: 'HELD_DATA',
    UNPROCESSED_DATA: 'UNPROCESSED_DATA'
};

export const vaultExportRegions = {
    ANY: 'ANY',
    US: 'US',
    EUROPE: 'EUROPE'
};

export const vaultExportFormats = {
    MBOX: 'MBOX',
    PST: 'PST'
};

export const vaultHoldLocationsInputTypes = {
    ALL_LOCATIONS: 'ALL_LOCATIONS',
    FILE: 'FILE',
    VALUES: 'VALUES'
};

export const userServiceObjectTypes = {
    USER_ACCOUNT: 'USER_ACCOUNT',
    UNIFIED_GROUP: 'UNIFIED_GROUP',
    SHARE_POINT_SITE: 'SHARE_POINT_SITE',
    COMPUTER: 'COMPUTER',
    GOOGLE_GROUP: 'GOOGLE_GROUP',
    GOOGLE_ORG_UNIT: 'GOOGLE_ORG_UNIT',
    GOOGLE_SHARED_DRIVE: 'GOOGLE_SHARED_DRIVE',
    GOOGLE_SITE: 'GOOGLE_SITE',
    GOOGLE_CHAT_SPACE: 'GOOGLE_CHAT_SPACE'
};

export const webhookPlatformHowToLinks = {
    [webhookPlatformKeys.MICROSOFT_TEAMS]: "https://docs.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook#add-an-incoming-webhook-to-a-teams-channel",
    [webhookPlatformKeys.SLACK]: "https://slack.com/intl/en-us/help/articles/115005265063-Incoming-Webhooks-for-Slack?eu_nc=1#set-up-incoming-webhooks",
    [webhookPlatformKeys.DISCORD]: "https://support.discordapp.com/hc/en-us/articles/228383668-Intro-to-Webhooks"
};

export const byteUnits = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

////////////////////////////////////////////////// QUERIED-CONSTANTS //////////////////////////////////////////////////////

export let awsRegions = [];
export function setAwsRegions(regions) {
    awsRegions = regions;
}

export let azureRegions = [];
export function setAzureRegions(regions) {
    azureRegions = regions;
}

export let azureVmSizes = [];
export function setAzureVmSizes(sizes) {
    azureVmSizes = sizes;
}

export let webhookEventTriggers = {};
export function setWebhookEventTriggers(eventTriggers) {
    webhookEventTriggers = eventTriggers;
}
