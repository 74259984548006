import BasePlugin from "@uppy/core/lib/BasePlugin";
import {DatasetApi} from "../../../models/data/DatasetModel";
import {bytesCountToReadableCount, endsWithSome, getKeys, isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import PopupModel from "../../../models/scheduler/PopupModel";
import {getLocalStorageSubsetWithPrefix} from "../../../utilities/localStorageHelper";
import {pathSplitterRegex} from "../../../utilities/constants";
import {uploadInfoStateKeys} from "../../../i18next/keys";


class DatasetValidationPlugin extends BasePlugin {

    constructor(uppy, opts = {}) {
        super(uppy, opts);

        this.id = opts.id || 'DatasetValidator';
        this.type = 'preProcessValidator';

        this.rejectUpload = this.rejectUpload.bind(this);
        this.removeInvalidFiles = this.removeInvalidFiles.bind(this);
        this.populateNewUploadsList = this.populateNewUploadsList.bind(this);
        this.populateFileRestrictions = this.populateFileRestrictions.bind(this);

        this.validateFileSizes = this.validateFileSizes.bind(this);
        this.validateFileExtensions = this.validateFileExtensions.bind(this);
        this.validateFileNameAgainstUploads = this.validateFileNameAgainstUploads.bind(this);
        this.validateFileNameAgainstFiles = this.validateFileNameAgainstFiles.bind(this);
        this.validateUsableSpace = this.validateUsableSpace.bind(this);
    }

    install() {
        this.uppy.addPreProcessor(this.populateNewUploadsList);
        this.uppy.addPreProcessor(this.validateFileSizes);
        this.uppy.addPreProcessor(this.populateFileRestrictions);
        this.uppy.addPreProcessor(this.validateFileExtensions);
        this.uppy.addPreProcessor(this.validateFileNameAgainstUploads);
        this.uppy.addPreProcessor(this.validateFileNameAgainstFiles);
        this.uppy.addPreProcessor(this.validateUsableSpace);
    }

    uninstall() {
        this.uppy.removePreProcessor(this.populateNewUploadsList);
        this.uppy.removePreProcessor(this.validateFileSizes);
        this.uppy.removePreProcessor(this.populateFileRestrictions);
        this.uppy.removePreProcessor(this.validateFileExtensions);
        this.uppy.removePreProcessor(this.validateFileNameAgainstUploads);
        this.uppy.removePreProcessor(this.validateFileNameAgainstFiles);
        this.uppy.removePreProcessor(this.validateUsableSpace);
    }

    static normalizePath(path) {
        return path.replace(pathSplitterRegex, '/').replace(/^[\\/]+/, '');
    }

    rejectUpload(reason) {
        this.opts.uploadStartHandler.clearPopup();
        return Promise.reject(reason);
    }

    // Remove file from uppy and internal tracking
    removeInvalidFiles(invalidFiles) {
        for (const file of invalidFiles) {
            const rootPath = file.meta.relativePath.split('/')[0];
            const rootUploadInfo = this.rootUploadInfos[rootPath];

            this.uppy.removeFile(file.id);
            switch (rootUploadInfo.type) {
                case 'file':
                    delete this.rootUploadInfos[rootPath];
                    break;
                case 'folder':
                    delete rootUploadInfo.files[file.meta.relativePath];
                    break;
            }
        }

        // Remove folder rootUploadInfos if no more files
        for (const rootPath of getKeys(this.rootUploadInfos)) {
            const rootUploadInfo = this.rootUploadInfos[rootPath];
            if (rootUploadInfo.type === 'folder' && !isNotEmptyNorFalsy(rootUploadInfo.files)) {
                delete this.rootUploadInfos[rootPath];
            }
        }

        // Filter newUppyFiles
        this.newUppyFiles = this.newUppyFiles.filter(file => !invalidFiles.includes(file));
        if (this.newUppyFiles.length === 0) {
            this.opts.uploadStartHandler.clearPopup();
        }
    }

    validateFileSizes() {
        if (isNaN(this.opts.uploadMaxSize)) {
            return;
        }

        // Warning prompt for files greater than uploadMaxSize
        const invalidFiles = [];
        for (const file of this.newUppyFiles) {
            if (file.size > this.opts.uploadMaxSize) {
                invalidFiles.push(file);
            }
        }

        if (invalidFiles.length > 0) {
            const readableUploadMaxSize = bytesCountToReadableCount(this.opts.uploadMaxSize);

            if (this.opts.promptUserToRemoveInvalidFiles) {
                // Ask user to cancel upload or remove files
                const popupKey = invalidFiles.length === 1 ? 'uploadContainsFilesWithInvalidSize' : 'uploadContainsFilesWithInvalidSize_plural';
                return new Promise((resolve, reject) => {
                    this.opts.dispatch(PopupModel.actionCreators.showWarning({
                        info: {
                            key: popupKey,
                            values: {
                                invalidFilesCount: invalidFiles.length,
                                uploadMaxSize: readableUploadMaxSize
                            }
                        },
                        buttons: [{
                            titleKey: 'dataset:option.remove',
                            onClick: () => {
                                this.removeInvalidFiles(invalidFiles);
                                resolve();
                            }
                        }],
                        cancelButton: {
                            titleKey: 'common:option.cancel',
                            onClick: () => reject('User cancelled upload')
                        }
                    }));
                });

            } else {
                // Show error and cancel upload
                const popupKey = invalidFiles.length === 1 ? 'rejectedUploadContainsFilesWithInvalidSize' : 'rejectedUploadContainsFilesWithInvalidSize_plural';
                this.opts.dispatch(PopupModel.actionCreators.showError({
                    info: {
                        key: popupKey,
                        values: {
                            invalidFilesCount: invalidFiles.length,
                            uploadMaxSize: readableUploadMaxSize
                        }
                    }
                }));
                return this.rejectUpload(`Upload has ${invalidFiles.length} file(s) with a size greater that the max upload size of ${readableUploadMaxSize}`);
            }
        }
    }

    validateFileExtensions() {
        if (this.restrictions.invalidExtensionPaths.length === 0) {
            return;
        }

        // Warning prompt for files with invalid extensions
        const invalidFiles = [];
        for (const invalidPath of this.restrictions.invalidExtensionPaths) {
            const rootPath = invalidPath.split('/')[0];
            const rootUploadInfo = this.rootUploadInfos[rootPath];

            switch (rootUploadInfo.type) {
                case 'file':
                    invalidFiles.push(rootUploadInfo.file);
                    break;
                case 'folder':
                    const file = rootUploadInfo.files[invalidPath];
                    invalidFiles.push(file);
                    break;
            }
        }

        if (this.opts.promptUserToRemoveInvalidFiles) {
            // Ask user to cancel upload or remove files
            const popupKey = this.restrictions.invalidExtensionPaths.length === 1 ? 'uploadFileExtensionNotAllowed' : 'uploadFileExtensionNotAllowed_plural';
            return new Promise((resolve, reject) => {
                this.opts.dispatch(PopupModel.actionCreators.showWarning({
                    info: {
                        key: popupKey,
                        values: {
                            invalidFilesCount: this.restrictions.invalidExtensionPaths.length
                        }
                    },
                    buttons: [{
                        titleKey: 'dataset:option.remove',
                        onClick: () => {
                            this.removeInvalidFiles(invalidFiles);
                            resolve();
                        }
                    }],
                    cancelButton: {
                        titleKey: 'common:option.cancel',
                        onClick: () => reject('User cancelled upload')
                    }
                }));
            });

        } else {
            // Show error and cancel upload
            const popupKey = invalidFiles.length === 1 ? 'rejectedUploadFileExtensionNotAllowed' : 'rejectedUploadFileExtensionNotAllowed_plural';
            this.opts.dispatch(PopupModel.actionCreators.showError({
                info: {
                    key: popupKey,
                    values: {
                        invalidExtensionCount: invalidFiles.length
                    }
                }
            }));
            return this.rejectUpload(`Upload has ${invalidFiles.length} file(s) with a restricted file extension`);
        }
    }

    validateFileNameAgainstUploads() {
        // Track invalid files
        const uploadInfoExistsForFilePaths = new Set();
        // Validate fileName not already in an active upload (tracked by server)
        for (const uploadInfo of this.uploadInfos) {
            if (uploadInfo.state === uploadInfoStateKeys.ACTIVE) {
                const relativePath = DatasetValidationPlugin.normalizePath(uploadInfo.relativePath);
                const rootPath = relativePath.split('/')[0];
                const rootUploadInfo = this.rootUploadInfos[rootPath];

                if (rootUploadInfo != null) {
                    switch (rootUploadInfo.type) {
                        case 'file':
                            uploadInfoExistsForFilePaths.add(relativePath);
                            break;
                        case 'folder':
                            if (rootUploadInfo.files[relativePath] != null) {
                                uploadInfoExistsForFilePaths.add(relativePath);
                            }
                            break;
                    }
                }
            }
        }

        if (uploadInfoExistsForFilePaths.size > 0) {
            this.opts.dispatch(PopupModel.actionCreators.showError({
                id: 'uploadInfoExistsError',
                info: {
                    key: 'uploadInfoExistsForFileNames',
                    values: {
                        fileNames: Array.from(uploadInfoExistsForFilePaths).join('\n')
                    }
                }
            }));

            // Cancel upload
            return this.rejectUpload("Active upload already exists for files");
        }
    }

    validateFileNameAgainstFiles() {
        // Track quota space freed by overwrite
        this.spaceFreedFromOverwrite = 0;
        if (this.restrictions.duplicatePaths.length === 0) {
            return;
        }

        // Track files that require overwrite
        const rootPathsToOverwrite = new Set();
        const duplicateFiles = [];

        // Validate fileName not already used by a fileInfo
        for (const duplicatePath of this.restrictions.duplicatePaths) {
            const rootPath = duplicatePath.split('/')[0];
            const rootUploadInfo = this.rootUploadInfos[rootPath];

            rootPathsToOverwrite.add(rootPath);
            switch (rootUploadInfo.type) {
                case 'file':
                    duplicateFiles.push(rootUploadInfo.file);
                    break;
                case 'folder':
                    const file = rootUploadInfo.files[duplicatePath];
                    duplicateFiles.push(file);
                    break;
            }
        }

        if (rootPathsToOverwrite.size > 0) {
            // Ask user to skip files or overwrite
            return new Promise((resolve, reject) => {
                this.opts.dispatch(PopupModel.actionCreators.showWarning({
                    info: {
                        key: 'datasetContainsFilesWithNames',
                        values: {
                            fileNames: Array.from(rootPathsToOverwrite).join('\n')
                        }
                    },
                    buttons: [{
                        titleKey: 'dataset:option.overwrite',
                        onClick: async () => {
                            // Query for total size of duplicate paths; size that will be freed as a result of file overwrite
                            this.spaceFreedFromOverwrite = (await DatasetApi.getSizeOfFiles(this.opts.datasetId, this.restrictions.duplicatePaths)).data;

                            // Update uppy file metas to set overwrite
                            for (const duplicateFile of duplicateFiles) {
                                this.uppy.setFileMeta(duplicateFile.id, {overwrite: true});
                            }
                            resolve();
                        }
                    }, {
                        titleKey: 'dataset:option.skip',
                        onClick: () => {
                            this.removeInvalidFiles(duplicateFiles);
                            resolve();
                        }
                    }],
                    cancelButton: {
                        titleKey: 'common:option.cancel',
                        onClick: () => reject('User cancelled upload')
                    }
                }));
            });
        }
    }

    validateUsableSpace() {
        return DatasetApi.getUsableSpace(this.opts.datasetId)
            .then(res => {

                const usableSpace = res.data;
                let {effectiveUsableSpace, fileSystemUsableSpace} = usableSpace;

                // Track requiredSpace
                let requiredSpace = 0;
                for (const file of this.newUppyFiles) {
                    requiredSpace += file.size;
                }

                // effectiveUsableSpace takes space freed from file overwriting into account
                if (effectiveUsableSpace != null) {
                    effectiveUsableSpace += this.spaceFreedFromOverwrite;
                }
                // Required disk space does not because space is not freed until upload is complete
                const requiredDiskSpace = requiredSpace;

                // If effectiveUsableSpace null, computeFileSystemUsableSpace && quota has been turned off
                // Not enough quota space
                if (effectiveUsableSpace != null && requiredSpace > effectiveUsableSpace) {
                    this.opts.dispatch(PopupModel.actionCreators.showError({
                        info: {
                            key: 'datasetNotEnoughUsableSpace',
                            values: {
                                requiredSpace: bytesCountToReadableCount(requiredSpace),
                                usableSpace: bytesCountToReadableCount(effectiveUsableSpace)
                            }
                        }
                    }));
                    // Cancel upload
                    return this.rejectUpload("Dataset does not have enough usable space");
                }

                // If fileSystemUsableSpace null, computeFileSystemUsableSpace has been turned off
                // Not enough disk space
                if (fileSystemUsableSpace != null && requiredDiskSpace > fileSystemUsableSpace) {
                    this.opts.dispatch(PopupModel.actionCreators.showError({
                        info: {
                            key: 'datasetNotEnoughDiskSpace',
                            values: {
                                requiredSpace: bytesCountToReadableCount(requiredDiskSpace),
                                usableSpace: bytesCountToReadableCount(fileSystemUsableSpace)
                            }
                        }
                    }));
                    // Cancel upload
                    return this.rejectUpload("Not enough space on the disk");
                }
            });
    }

    async populateFileRestrictions() {
        // Query for restrictions
        this.restrictions = {
            invalidExtensionPaths: [],
            duplicatePaths: []
        };

        const fileRelativePaths = this.newUppyFiles.map(file => file.meta.relativePath);
        this.restrictions = (await DatasetApi.getRestrictions(this.opts.datasetId, fileRelativePaths)).data;
    }

    // Excluding uploads that will be resumed
    async populateNewUploadsList() {
        // Query for uploadInfos
        this.uploadInfos = (await DatasetApi.getUploadInfos(this.opts.datasetId, true)).data;
        // Get uploadIds for dataset
        const datasetUploadIds = this.uploadInfos.map(info => info.id);

        const tusPlugin = this.uppy.getPlugin('Tus');
        const tusFingerprints = getLocalStorageSubsetWithPrefix('tus::tus-');
        const fingerPrintKeys = getKeys(tusFingerprints)
            // Remove partial uploads from localStorage if not tracked in backend
            .filter(fingerPrint => {
                const fileData = tusFingerprints[fingerPrint];

                // FingerPrint not tracked by an UploadInfo
                if (!endsWithSome(fileData.uploadUrl, datasetUploadIds)) {
                    localStorage.removeItem(fingerPrint);
                    delete tusFingerprints[fingerPrint];
                    return false;
                }
                return true;
            });

        this.newUppyFiles = this.uppy.getFiles()
            .filter(file => {
                // uploadStarted is null for not-started uploads
                let isNewFile = file.progress.uploadStarted == null;

                // Check if file will be resumed (file has a TUS fingerprint in localstorage)
                if (isNewFile) {
                    const storageKey = fingerPrintKeys.find(print => print.includes(file.id) && print.includes(tusPlugin.opts.endpoint));
                    const fileData = tusFingerprints[storageKey];
                    // If Tus does not have a fileData for file -> new upload
                    isNewFile = fileData == null;
                }
                return isNewFile;
            });

        this.rootUploadInfos = {};
        // Build map of rootPath (rootFiles) to rootUploadInfo
        for (const file of this.newUppyFiles) {
            const rootPathSplit = file.meta.relativePath.split('/');
            const rootPath = rootPathSplit[0];

            if (rootPathSplit.length > 1) {
                // Initialize rootUploadInfo obj
                if (this.rootUploadInfos[rootPath] == null) {
                    this.rootUploadInfos[rootPath] = {
                        type: 'folder',
                        files: {}
                    };
                }

                const rootUploadInfo = this.rootUploadInfos[rootPath];
                rootUploadInfo.files[file.meta.relativePath] = file;
            } else {
                this.rootUploadInfos[rootPath] = {
                    type: 'file',
                    file
                };
            }
        }
    }
}

export default DatasetValidationPlugin;