import {getValues} from "../../utilities/helperFunctions";
import {operationFieldParameterNames} from "../guidedJob/operationFieldParameterNames";
import GuidedJobForm from "../guidedJob/GuidedJobForm";
import ParameterModel from "../../models/library/ParameterModel";
import React from "react";
import WorkflowBuilderOperation from "../../models/workflowbuilder/WorkflowBuilderOperation";
import {getVaultSetMatterGuide} from "./panes/VaultSetMatterPane";
import GuidedJobModel from "../../models/guidedjob/GuidedJobModel";
import {getVaultSelectSavedQueriesGuide} from "./panes/VaultSelectSavedQueriesPane";
import {getVaultSelectExportsGuide} from "./panes/VaultSelectExportsPane";
import {getVaultDownloadExportsGuide} from "./panes/VaultDownloadExportsPane";
import {getVaultServiceQueryTermsGuide} from "./panes/VaultServiceQueryTermsPane";
import {getVaultSavedQueryOptionsGuide} from "./panes/VaultSavedQueryOptionsPane";
import {getVaultHoldOptionsGuide} from "./panes/VaultHoldOptionsPane";
import {getVaultExportOptionsGuide} from "./panes/VaultExportOptionsPane";
import {getVaultSelectHeldLocationsGuide} from "./panes/VaultSelectHeldLocationsPane";
import {getVaultAddLocationsGuide} from "./panes/VaultAddLocationsPane";
import {useTranslation} from "react-i18next";

export const vaultJobParameterNames = {
    matterName: '{vault_matter_name}',
    savedQueryIds: '{vault_saved_query_ids}',
    exportIds: '{vault_export_ids}'
};

for (const name of getValues(vaultJobParameterNames)) {
    operationFieldParameterNames.add(name);
}

function VaultGuidedJobForm() {
    const {t} = useTranslation(['guidedJob']);
    return (
        <GuidedJobForm requiredParameterTypes={[ParameterModel.Type.VAULT_SERVICE]}
            getGuides={getVaultOperationGuides} closeButtonAriaLabel={t('guidedJob:option.closeVaultGuidedJob')}/>
    )
}

function getVaultOperationGuides(aliasToOperation, props) {
    const workflowActions = getVaultWorkflowActions(aliasToOperation);
    const guideProps = {workflowActions, ...props};
    const guides = [];

    if (workflowActions.setMatter) {
        const operation = aliasToOperation[WorkflowBuilderOperation.Alias.VAULT_SET_MATTER];
        guides.push(getVaultSetMatterGuide(operation, guideProps));
    }
    if (workflowActions.selectHeldLocations) {
        const operation = aliasToOperation[WorkflowBuilderOperation.Alias.VAULT_REMOVE_LOCATIONS_FROM_HOLDS];
        guides.push(getVaultSelectHeldLocationsGuide(operation, guideProps));
    }

    if (workflowActions.createSavedQueries) {
        guides.push(getVaultSavedQueryOptionsGuide(guideProps));
    }
    if (workflowActions.addHolds) {
        guides.push(getVaultHoldOptionsGuide(guideProps));
    }

    if (workflowActions.addLocations) {
        let operation = aliasToOperation[WorkflowBuilderOperation.Alias.VAULT_CREATE_SAVED_QUERIES];
        if (operation == null) operation = aliasToOperation[WorkflowBuilderOperation.Alias.VAULT_ADD_HOLDS];
        guides.push(getVaultAddLocationsGuide(operation, guideProps));

    } else if (workflowActions.selectSavedQueries) {
        const operation = aliasToOperation[WorkflowBuilderOperation.Alias.VAULT_EXPORT_SAVED_QUERIES];
        guides.push(getVaultSelectSavedQueriesGuide(operation, guideProps));

    } else if (workflowActions.selectExports) {
        let operation = aliasToOperation[WorkflowBuilderOperation.Alias.VAULT_SET_EXPORTS];
        if (operation == null) operation = aliasToOperation[WorkflowBuilderOperation.Alias.VAULT_DOWNLOAD_EXPORTS];
        guides.push(getVaultSelectExportsGuide(guideProps, operation));
    }

    if (workflowActions.addLocations) {
        guides.push(getVaultServiceQueryTermsGuide(guideProps));
    }
    if (workflowActions.exportSavedQueries) {
        guides.push(getVaultExportOptionsGuide(guideProps));
    }
    if (workflowActions.downloadExports) {
        const operation = aliasToOperation[WorkflowBuilderOperation.Alias.VAULT_DOWNLOAD_EXPORTS];
        guides.push(getVaultDownloadExportsGuide(operation, guideProps));
    }
    return guides;
}

function getVaultWorkflowActions(aliasToOperation) {
    const actions = {};

    actions.setMatter = GuidedJobModel.validGuideAction(aliasToOperation[WorkflowBuilderOperation.Alias.VAULT_SET_MATTER]);
    actions.createNewMatter = aliasToOperation[WorkflowBuilderOperation.Alias.VAULT_CREATE_SAVED_QUERIES] != null
        || aliasToOperation[WorkflowBuilderOperation.Alias.VAULT_ADD_HOLDS] != null;

    actions.createSavedQueries = GuidedJobModel.validGuideAction(aliasToOperation[WorkflowBuilderOperation.Alias.VAULT_CREATE_SAVED_QUERIES]);
    actions.addHolds = GuidedJobModel.validGuideAction(aliasToOperation[WorkflowBuilderOperation.Alias.VAULT_ADD_HOLDS]);
    actions.addLocations = actions.createSavedQueries || actions.addHolds;

    actions.exportSavedQueries = GuidedJobModel.validGuideAction(aliasToOperation[WorkflowBuilderOperation.Alias.VAULT_EXPORT_SAVED_QUERIES]);
    actions.downloadExports = GuidedJobModel.validGuideAction(aliasToOperation[WorkflowBuilderOperation.Alias.VAULT_DOWNLOAD_EXPORTS]);
    actions.removeLocationsFromHolds = GuidedJobModel.validGuideAction(aliasToOperation[WorkflowBuilderOperation.Alias.VAULT_REMOVE_LOCATIONS_FROM_HOLDS]);

    actions.setOptions = actions.addLocations || actions.exportSavedQueries;

    actions.selectSavedQueries = !actions.createNewMatter && actions.exportSavedQueries;

    actions.selectExports = !actions.createNewMatter &&
        (GuidedJobModel.validGuideAction(aliasToOperation[WorkflowBuilderOperation.Alias.VAULT_SET_EXPORTS]) || actions.downloadExports);

    actions.selectHeldLocations = !actions.createNewMatter && actions.removeLocationsFromHolds;

    return actions;
}

export default VaultGuidedJobForm;
