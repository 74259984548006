import React, {useEffect, useRef} from "react";
import WorkflowBuilderOperation from "../../../models/workflowbuilder/WorkflowBuilderOperation";
import VaultCache from "../../../models/guidedjob/VaultCache";
import VaultOperationPane from "./VaultOperatonPane";
import {buildOperationFieldParameterNames} from "../../guidedJob/operationFieldParameterNames";
import {objectIdentifierType, vaultMatterState} from "../../../utilities/constants";
import {getNonEmptyValueOrDefault} from "../../../utilities/helperFunctions";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import TextArea from "../../common/TextArea/TextArea";
import Text from "../../common/Text/Text";
import SelectableTable from "../../common/Tables/SelectableTable";
import {StatusNameDescriptionCell, TableLabelCell} from "../../common/CustomTable/CustomTable";
import {vaultJobParameterNames} from "../VaultGuidedJobForm";
import RadioButton from "../../common/RadioButton/RadioButton";

const fieldNames = ['matterIdentifierType', 'matterIdentifier', 'matterState', 'createMatterIfNotExists', 'matterDescription'];
export const vaultSetMatterParameterNames = buildOperationFieldParameterNames(WorkflowBuilderOperation.Alias.VAULT_SET_MATTER, fieldNames);

function getNextEnabled(getParameter) {
    const matterIdentifierType = getParameter(vaultSetMatterParameterNames.matterIdentifierType);
    const matterIdentifier = getParameter(vaultSetMatterParameterNames.matterIdentifier);

    return {
        setMatter: objectIdentifierType[matterIdentifierType.value] != null && matterIdentifier.value?.length > 0
    };
}

export function getVaultSetMatterGuide(operation, props) {
    const {
        workflowActions,
        getParameter,
        updateParameter
    } = props;

    const matterIdentifierType = getParameter(vaultSetMatterParameterNames.matterIdentifierType);
    const matterIdentifier = getParameter(vaultSetMatterParameterNames.matterIdentifier);
    const createNewMatter = matterIdentifierType.value !== objectIdentifierType.ID;

    if (createNewMatter) {
        if (workflowActions.createNewMatter) {
            updateParameter(vaultSetMatterParameterNames.matterIdentifierType, objectIdentifierType.NAME);
        } else {
            updateParameter(vaultSetMatterParameterNames.matterIdentifierType, objectIdentifierType.ID);
            if (!matterIdentifier.value) {
                updateParameter(vaultSetMatterParameterNames.matterIdentifier, '');
            }
        }
    }

    return {
        getPanes: function(t, props) {
            const setMatterTitle = getNonEmptyValueOrDefault(operation.notes, t('guidedJob:panes.setVaultMatter'));
            const nextEnabled = getNextEnabled(props.getParameter, workflowActions);

            return [{
                title: setMatterTitle,
                isNextEnabled: nextEnabled.setMatter,
                component: <VaultOperationPane {...props}
                    workflowActions={workflowActions} OperationPane={VaultSetMatterPane}/>
            }];
        }
    };
}

function VaultSetMatterPane(props) {
    const {t} = useTranslation(['guidedJob', 'common']);
    const {
        guidedJobForm,
        workflowActions,
        proxyRequest,
        getParameter,
        updateParameter,
        updateState,
        isDisabled
    } = props;

    const matterIdentifierType = getParameter(vaultSetMatterParameterNames.matterIdentifierType);
    const matterIdentifier = getParameter(vaultSetMatterParameterNames.matterIdentifier);
    const matterState = getParameter(vaultSetMatterParameterNames.matterState, vaultMatterState.OPEN);
    const description = getParameter(vaultSetMatterParameterNames.matterDescription);

    const useExistingMatter = matterIdentifierType.value === objectIdentifierType.ID;
    const createNewMatter = !useExistingMatter;
    const isCreateMatterDisabled = isDisabled || !workflowActions.createNewMatter;

    const isMatterIdentifierTypeDisabled = isDisabled || getParameter(vaultSetMatterParameterNames.matterIdentifierTypeDisabled, false, {parse: true}).value;
    const isMatterIdentifierDisabled = isCreateMatterDisabled || getParameter(vaultSetMatterParameterNames.matterIdentifierDisabled, false, {parse: true}).value;

    const mattersKey = VaultCache.getResourceKey(VaultCache.Resource.MATTERS, {matterState: matterState.value});
    const vaultMatters = useSelector(state => state.vaultCache[mattersKey]) || {};
    const isLoadingMatters = vaultMatters.cache == null;

    const headers = useRef([
        {label: t('common:label.state'), noStretch: true},
        {label: t('common:label.name')}
    ]);

    const matterName = useSelector(state => (state.matterDetailsMap.get(guidedJobForm.matterId) || {}).name);
    useEffect(() => {
        if (createNewMatter && matterName) {
            if (!matterIdentifier.value || matterIdentifier.value === guidedJobForm.prevMatterName) {
                updateParameter(vaultSetMatterParameterNames.matterIdentifier, matterName);
                updateState({prevMatterName: matterName});
            }
        }
    }, [matterName]);

    useEffect(() => {
        if (useExistingMatter) {
            proxyRequest(VaultCache.Resource.MATTERS, {matterState: matterState.value});
        }
        updateParameter(vaultSetMatterParameterNames.createMatterIfNotExists, JSON.stringify(!useExistingMatter));
    }, [useExistingMatter]);

    function inputHandler(event) {
        const {name, value} = event.target;
        updateParameter(name, value);

        if (name === vaultSetMatterParameterNames.matterIdentifierType && matterIdentifierType.value !== value) {
            updateParameter(vaultSetMatterParameterNames.matterIdentifier, '');

        } else if (name === vaultSetMatterParameterNames.matterIdentifier && !useExistingMatter) {
            updateParameter(vaultJobParameterNames.matterName, value);
        }
    }

    function valueHandler(event) {
        const {name, value, displayname} = event.currentTarget.dataset;
        updateParameter(name, value);

        if (name === vaultSetMatterParameterNames.matterIdentifier && useExistingMatter) {
            updateParameter(vaultJobParameterNames.matterName, displayname);
        }
    }


    return (
        <>
            <div className="flex-column" style={{marginTop: '0.5rem'}}>
                <RadioButton name={vaultSetMatterParameterNames.matterIdentifierType} label={t(`guidedJob:option.createNewVaultMatter`)}
                    style={{marginTop: '0.5rem', marginBottom: '1rem'}} selected={matterIdentifierType.value} value={objectIdentifierType.NAME}
                    onClick={inputHandler} isDisabled={isMatterIdentifierTypeDisabled || isCreateMatterDisabled}/>
                <RadioButton name={vaultSetMatterParameterNames.matterIdentifierType} label={t(`guidedJob:option.useExistingVaultMatter`)}
                    selected={matterIdentifierType.value} value={objectIdentifierType.ID} onClick={inputHandler} isDisabled={isMatterIdentifierTypeDisabled}/>
            </div>

            {createNewMatter &&
                <div>
                    <div className="display-input">
                        <HTMLTextInput label={t('guidedJob:label.matterName')} name={vaultSetMatterParameterNames.matterIdentifier}
                            value={matterIdentifier.value} onChange={inputHandler} isRequired isDisabled={isMatterIdentifierDisabled}/>
                    </div>

                    <div className="display-input">
                        <TextArea label={t('guidedJob:label.description')} name={vaultSetMatterParameterNames.matterDescription}
                            value={description.value} onChange={inputHandler} isDisabled={isCreateMatterDisabled}/>
                    </div>
                </div>
            }

            {useExistingMatter &&
                <div className="display-input">
                    <div style={{display: 'flex', marginBottom: '0.25rem'}}>
                        <Text value={`${t('guidedJob:label.vaultMatterId')}:`} style={{marginRight: '0.5rem'}} isDisabled={isDisabled}/>
                        <Text value={matterIdentifier.value} isDisabled={isDisabled}/>
                    </div>

                    <SelectableTable name={vaultSetMatterParameterNames.matterIdentifier} headers={headers.current} items={vaultMatters.cache}
                        itemIdKey={'matterId'} selectedItemId={matterIdentifier.value} onItemSelect={valueHandler} isLoading={isLoadingMatters}
                        emptyMessage={t('guidedJob:message.vaultServiceHasNoMatters', {state: t(`guidedJob:vaultMatterState.${matterState.value}`).toLowerCase()})}
                        isDisabled={isDisabled} isStale={vaultMatters.stale} isRequired
                        RowComponent={props => (
                            <>
                                <TableLabelCell label={t(`guidedJob:vaultMatterState.${props.state}`)}/>
                                <StatusNameDescriptionCell name={props.name} description={props.description}/>
                            </>
                        )}
                    />
                </div>
            }
        </>
    )
}
