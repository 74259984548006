import React, {Component} from 'react';
import {connect} from 'react-redux';
import {useTranslation, withTranslation} from "react-i18next";
import Tablet, {EditTabletHeader} from '../../common/Tablet/Tablet';
import {getModifyPrivilegedAction, onInputChange} from '../../../utilities/componentFunctions';
import {DefaultEditPanel} from '../../common/EditPanel/EditPanel';
import ExpandableContent, {ExpandableEditTextArea} from '../../common/ExpandableContent/ExpandableContent';
import {SettingsRowValue} from '../../common/CustomTable/CustomTable';
import {onMenuOptionClick} from "../../common/Dropdown/helpers";
import {shouldEnableDongleLicenceSource} from "../../../utilities/shouldEnableFunctions";
import {permissionKeys} from "../../../i18next/keys";
import {updateMode} from "../../../utilities/constants";
import PopupModel from "../../../models/scheduler/PopupModel";
import LicenceSourceModel from "../../../models/settings/LicenceSourceModel";
import EditModel from "../../../models/scheduler/EditModel";
import {makeGetEditDetails} from "../../../reselect/selectors";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import LimitedTablet from "../../limited/LimitedTablet";

class NuixLicenceSourceTablet extends Component {

    constructor(props) {
        super(props);

        this.editLicenceSource = this.editLicenceSource.bind(this);
        this.deleteLicenceSource = this.deleteLicenceSource.bind(this);

        this.getModifyPrivilegedAction = getModifyPrivilegedAction('nuixLicenceSource', this.props.t('nuixLicenceSource:label.name')).bind(this);
        this.onMenuOptionSelect = onMenuOptionClick.bind(this);

        this.onEditInputChange = onInputChange({
            mode: updateMode.EDIT,
            shouldEnable: shouldEnableDongleLicenceSource
        }).bind(this);
    }

    editLicenceSource() {
        this.props.editLicenceSource(this.props.nuixLicenceSourceId);
    }

    deleteLicenceSource() {
        this.props.promptDeleteLicenceSource(this.props.nuixLicenceSourceId);
    }

    render() {

        const {
            t,
            dongleName,
            description,
            filter,
            userPermissions,
            isEditActive,
            isSaveEnabled,

            nuixLicenceSourceId
        } = this.props;

        const menuOptions = [];
        menuOptions.push({name: t('common:option.edit'), value: 'editLicenceSource', isDisabled: false});
        menuOptions.push({name: t('common:option.delete'), value: 'deleteLicenceSource', isDisabled: false});

        const descriptionProps = {
            label: t('common:label.description'),
            name: 'description',
            value: description,
            onChange: this.getModifyPrivilegedAction(this.onEditInputChange),
            isEditActive
        };

        const canModifyDongleLicenceSource = userPermissions.includes(permissionKeys.MODIFY);
        const isDescriptionVisible = isEditActive || description;
        const isSettingsVisible = isEditActive || filter;

        const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);
        if (isViewLimited && !canModifyDongleLicenceSource) {
            return (
                <LimitedTablet id={nuixLicenceSourceId} type={'dongleLicenceSource'} name={dongleName} label={t(`nuixLicenceSource:label.dongleName`)}
                               enabled={true} canModify={canModifyDongleLicenceSource} isDisabled={false} description={description} onClose={this.props.close}
                />
            )
        }

        return (
            <Tablet height={isEditActive ? 'auto' : '50vh'} onClose={this.props.close}
                closeButtonAriaLabel={t('nuixLicenceSource:option.closeDongleTablet_name', {name: dongleName})}
                header={
                    <EditTabletHeader label={t('nuixLicenceSource:label.dongleName')} type={'dongleLicenceSource'}
                        id={nuixLicenceSourceId} name={'dongleName'} value={dongleName} inputHandler={this.onEditInputChange}
                        menuOptions={menuOptions} menuOptionHandler={this.onMenuOptionSelect}
                        canModify={canModifyDongleLicenceSource} isEditActive={isEditActive}
                    />
                }
                body={(isDescriptionVisible || isSettingsVisible) &&
                <div className="display-item">
                    <DefaultEditPanel isActive={isEditActive} isSaveEnabled={isSaveEnabled} onSave={this.props.saveEdit}
                        onCancel={this.props.cancelEdit}
                    >
                        {isDescriptionVisible &&
                        <ExpandableEditTextArea {...descriptionProps}/>
                        }

                        {isSettingsVisible &&
                        <ExpandableContent label={t('common:label.settings')}>
                            <LicenceSettings filter={filter} onInputChange={this.onEditInputChange}
                                isEditActive={isEditActive}
                            />
                        </ExpandableContent>
                        }
                    </DefaultEditPanel>
                </div>
                }
            />
        );
    }
}


const LicenceSettings = props => {
    const {isEditActive, filter, onInputChange} = props;

    const {t} = useTranslation(['nuixLicenceSource']);
    const filterLabel = t('nuixLicenceSource:label.filter');

    return (
        <div className="settings-table">
            <div className="table-row-group">
                {isEditActive ?
                    <HTMLTextInput {...{label: filterLabel, name: 'filter', value: filter, onChange: onInputChange}}/>
                    :
                    SettingsRowValue({label: filterLabel, value: filter})
                }
            </div>
        </div>
    );
};

const makeMapStateToProps = () => {
    const getEditDetails = makeGetEditDetails();
    return state => {
        const {nuixLicenceSourceDetailsMap, componentStates: {licenceSourceDisplay: {nuixLicenceSourceId}}} = state;
        const {name: dongleName, ...otherDetails} = nuixLicenceSourceDetailsMap.get(nuixLicenceSourceId);

        const {activeModel, values, isSaveEnabled} = getEditDetails(state, {model: LicenceSourceModel.nom});
        const isEditActive = (activeModel === LicenceSourceModel.nom);

        return {
            nuixLicenceSourceId,
            dongleName,
            ...otherDetails,
            ...values,
            isEditActive,
            isSaveEnabled
        }
    }
};

const mapDispatchToProps = dispatch => {
    return {
        editLicenceSource: nuixLicenceSourceId => dispatch(LicenceSourceModel.actionCreators.startEdit(nuixLicenceSourceId)),
        promptDeleteLicenceSource: nuixLicenceSourceId => dispatch(LicenceSourceModel.actionCreators.promptDelete(nuixLicenceSourceId)),

        ...EditModel.buildDispatchers(dispatch),

        showError: payload => dispatch(PopupModel.actionCreators.showError(payload)),
        close: () => dispatch(LicenceSourceModel.actionCreators.hideTablet())
    };
};

export default connect(makeMapStateToProps, mapDispatchToProps)(withTranslation(['nuixLicenceSource', 'common'])(NuixLicenceSourceTablet));