import {call, fork, put, take} from 'redux-saga/effects';
import {serverErrorResponseValues} from "./sagaFunctions";
import PopupModel from "../models/scheduler/PopupModel";
import CurrentUserModel from "../models/user/CurrentUserModel";
import SchedulerModel from "../models/scheduler/SchedulerModel";

export function* tryCatchAndReattemptWrapper(saga, ...args) {
    try {
        yield call(saga, ...args);
    } catch (error) {
        yield call(genericErrorHandler, error, null, saga, ...args);
    }
}

export function retryCall(saga, ...args) {
    return call(function* () {
        try {
            return yield call(saga, ...args);
        } catch (error) {
            return yield call(genericErrorHandler, error, null, saga, ...args);
        }
    });
}

export function* tryCatchWrapper(saga, ...args) {
    try {
        return yield call(saga, ...args);
    } catch (error) {
        yield call(genericErrorHandler, error);
    }
}

//Used to wrap effectDescriptions used in 'all' effectDescriptor so to avoid rejecting all on first reject
export function* emptyTryCatchWrapper(saga, ...args) {
    try {
        return yield call(saga, ...args);
    } catch {
        //Do nothing
    }
}

//!ERROR.REQUEST - error generated without any sort of network request (assume not network related)
//ERROR.RESPONSE - network request returned with error response
//ERROR.REQUEST && !ERROR.RESPONSE - network request sent but failed to receive response (assume connection issue)
//After setting schedulerDisconnected handler waits for server to reconnect and then restarts saga which was disconnected

//replace generic error handling with custom callbacks for each case (see above)
//genericError = !error.request
//responseError = error.response
//noResponseError = error.request && !error.response
export function* genericErrorHandler(error, errorCbs, saga, ...args) {
    const { genericErrorCb, responseErrorCb, noResponseErrorCb } = errorCbs || {};
    if (error.request) {
        if (error.response) {
            yield call(responseErrorCb ? responseErrorCb : handleResponseError, error, saga, ...args);
        } else {
            yield call(noResponseErrorCb ? noResponseErrorCb : handleNoResponseError, error, saga, ...args);
        }
    } else {
        yield call(genericErrorCb ? genericErrorCb : handleGenericError, error, saga, ...args);
    }
}

//!error.request
function* handleGenericError(error, saga, ...args) {
    console.log(error);
    if (saga != null) {
        yield fork(tryCatchAndReattemptWrapper, saga, ...args);
    }
}

//error.request && error.response
function* handleResponseError({response}, saga, ...args) {
    if (response.status === 401) {
        yield put(CurrentUserModel.actionCreators.logoutUser());
    } else {
        yield put(PopupModel.actionCreators.showError({
            info: {
                ...(serverErrorResponseValues(response) || response.data)
            }
        }));
        if (saga != null) {
            yield fork(tryCatchAndReattemptWrapper, saga, ...args);
        }
    }
}

//error.request && !error.response
function* handleNoResponseError(error, saga, ...args) {
    yield put(SchedulerModel.actionCreators.setDisconnected());
    yield take(SchedulerModel.actions.SET_CONNECTED);
    if (saga != null) {
        yield fork(tryCatchAndReattemptWrapper, saga, ...args);
    }
}