import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {isNotEmptyNorFalsy, removeUnderlineCapitalizeAll, switchcaseF} from '../../utilities/helperFunctions';
import ExpandableContent, {
    ExpandableChangeLogTable,
    ExpandableLog,
    ExpandableOperationBacklogTable,
    ExpandableValueList
} from '../common/ExpandableContent/ExpandableContent';
import Tablet, {EditTabletHeader} from '../common/Tablet/Tablet';
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {permissionKeys} from "../../i18next/keys";
import WorkflowTemplateModel from "../../models/library/WorkflowTemplateModel";
import WorkflowParameters from "../parameters/WorkflowParameters";
import {SettingsRowValue} from "../common/CustomTable/CustomTable";
import {downloadLogs} from "../../utilities/downloadHelper";
import RelativityProxyModel from "../../models/relativity/RelativityProxyModel";
import WorkflowBuilderModel from "../../models/workflowbuilder/WorkflowBuilderModel";
import JobModel, {JobSaga} from "../../models/job/JobModel";
import {all, call, put} from "redux-saga/effects";
import {contextCall} from "../../saga/sagaFunctions";
import SchedulerModel from "../../models/scheduler/SchedulerModel";

function selectWorkflowTemplate(state, props) {
    const {name: workflowTemplateName, ...otherDetails} = state.workflowTemplateDetailsMap.get(props.workflowTemplateId);
    return {
        workflowTemplateName,
        parameters: state.parametersMap.get(props.workflowTemplateId),
        operations: state.templateOperationsMap.get(props.workflowTemplateId),
        ...otherDetails
    };
}

function WorkflowTemplateTablet(props) {
    const {t} = useTranslation(['workflow', 'common']);
    const dispatch = useDispatch();

    const {
        workflowTemplateId
    } = props;

    const auditLog = useSelector(state => state.auditLogMap.get(workflowTemplateId));
    const {isDisabled} = useSelector(state => state.componentStates.workflowTemplateTablet);
    const isLoading = useSelector(state => !state.hasLoaded[workflowTemplateId]);
    const isRelativityApplication = useSelector(state => state.schedulerDetails.isRelativityApplication);

    const {
        libraryId,
        workflowTemplateName,
        description,
        prerequisites,
        usage,
        executionMode,
        enabled,
        parameters,
        operations,
        userPermissions
    } = useSelector(state => selectWorkflowTemplate(state, {workflowTemplateId}));

    // Start and Stop polling
    useEffect(() => {
        dispatch(WorkflowTemplateModel.actionCreators.startPollingSettings(workflowTemplateId));
        return () => dispatch(WorkflowTemplateModel.actionCreators.stopPollingSettings());
    }, [workflowTemplateId, dispatch]);

    function queueJob() {
        function* navigateAndOpenJobForm() {
            yield all([
                put(JobModel.componentActionCreators.resetPage()),
                put(WorkflowTemplateModel.actionCreators.hideTablet())
            ]);

            const initialState = {
                libraryId,
                workflowTemplateId
            };

            // Navigate to job page
            window.location.href = '#/jobs';
            yield contextCall(JobSaga, 'showForm', {initialState});
        }

        dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(call(navigateAndOpenJobForm)));
    }

    function downloadWorkflow() {
        const endpoint = `/scheduler/library/workflow/${workflowTemplateId}/download`;
        if (isRelativityApplication) {
            dispatch(RelativityProxyModel.actionCreators.proxyDownload(endpoint, null, WorkflowTemplateModel.componentActionCreators.updateTablet));
        } else {
            downloadLogs(endpoint);
        }
    }

    function downloadAuditLogVersion(event) {
        const {value} = event.currentTarget;
        dispatch(WorkflowTemplateModel.actionCreators.downloadAuditLogVersion(workflowTemplateId, value));
    }

    function menuOptionHandler(event) {
        const {value} = event.currentTarget.dataset;
        switchcaseF({
            'edit': () => dispatch(WorkflowBuilderModel.actionCreators.startEdit(workflowTemplateId)),
            'toggleActive': () => dispatch(WorkflowTemplateModel.actionCreators.toggleEnabled(workflowTemplateId)),
            'promptDelete': () => dispatch(WorkflowTemplateModel.actionCreators.promptDelete(workflowTemplateId)),
            'queueJob': queueJob,
            'download': downloadWorkflow
        })()(value);
    }

    const canModifyWorkflow = userPermissions.includes(permissionKeys.MODIFY);
    const canViewSensitive = userPermissions.includes(permissionKeys.VIEW_SENSITIVE);

    const menuOptions = [
        {name: t('common:option.edit'), value: 'edit', isDisabled: !canModifyWorkflow},
        {name: t(`common:option.${enabled ? 'deactivate' : 'activate'}`), value: 'toggleActive'},
        {name: t('common:option.delete'), value: 'promptDelete'},
        {isSeparator: true},
        {name: t('workflow:option.queueJob'), value: 'queueJob', isDisabled: !enabled},
        {name: t('common:option.download'), value: 'download', isDisabled: !canViewSensitive}
    ];

    const showDescription = !!description;
    const showPrerequisites = isNotEmptyNorFalsy(prerequisites);
    const showUsage = usage;
    const showParameters = isNotEmptyNorFalsy(parameters);

    return (
        <Tablet width={'100rem'} height={'65vh'} onClose={() => dispatch(WorkflowTemplateModel.actionCreators.hideTablet())}
            closeButtonAriaLabel={t('workflow:option.closeTablet_name', {name: workflowTemplateName})}
            header={
                <EditTabletHeader id={workflowTemplateId} label={t('workflow:label.nameLast')} type={'workflow'}
                    name={'workflowTemplateName'} value={workflowTemplateName} enabled={enabled} canModify={canModifyWorkflow}
                    menuOptions={menuOptions} menuOptionHandler={menuOptionHandler} isLoading={isLoading} isDisabled={isDisabled}/>
            }
            body={
                <LoadingWrapper isLoading={isLoading}>
                    {showDescription &&
                        <div className="display-item">
                            <ExpandableLog label={t('common:label.description')} name={'description'}
                                value={description} isDisabled={isDisabled}/>
                        </div>
                    }

                    {showPrerequisites &&
                        <div className="display-item">
                            <ExpandableValueList id={'prerequisites'} label={t('common:label.prerequisites')}
                                ariaLabelKey={'Prerequisite'}
                                values={prerequisites} listDisplay={true} isDisabled={isDisabled}/>
                        </div>
                    }

                    {showParameters &&
                        <div className="display-item">
                            <ExpandableContent label={t('workflow:label.parameters')} isDisabled={isDisabled}>
                                <WorkflowParameters parameters={parameters} style={{marginTop: '0.25rem'}}
                                    isDisabled={isDisabled}/>
                            </ExpandableContent>
                        </div>
                    }

                    {showUsage &&
                        <div className="display-item">
                            <ExpandableLog label={t('common:label.usage')} name={'usage'}
                                value={usage} isDisabled={isDisabled}/>
                        </div>
                    }

                    <div className="display-item">
                        <ExpandableContent label={t('common:label.settings')} isDisabled={isDisabled}>
                            <div className="settings-table">
                                <div className="table-row-group">
                                    <SettingsRowValue isDisabled={isDisabled}
                                        label={t('common:label.executionMode')}
                                        value={executionMode && removeUnderlineCapitalizeAll(executionMode)}/>
                                </div>
                            </div>
                        </ExpandableContent>
                    </div>

                    <div className="display-item">
                        <ExpandableOperationBacklogTable label={t('workflow:label.nameFirst')} operations={operations}
                            viewSensitive={canViewSensitive} isDisabled={isDisabled}/>
                    </div>

                    {isNotEmptyNorFalsy(auditLog) &&
                        <div className="display-item">
                            <ExpandableChangeLogTable label={t('common:label.auditLog')} changeLogs={auditLog}
                                downloadAuditLogVersion={downloadAuditLogVersion} isDisabled={isDisabled}/>
                        </div>
                    }
                </LoadingWrapper>
            }
        />
    )
}

export default WorkflowTemplateTablet;
