import SettingModel from "../generics/SettingModel";
import {getLocaleDateTimeFromUTC} from "../../utilities/helperFunctions";
import i18next from "i18next";
import {popupInfoKeys} from "../../i18next/keys";

class JobScheduleRunModel extends SettingModel.Complex {

    static nom = 'JobScheduleRunModel';
    static actions = JobScheduleRunModel.buildActions('JOB_SCHEDULE_RUN');
    static actionCreators = JobScheduleRunModel.buildActionCreators(JobScheduleRunModel.actions);
    static reducer = JobScheduleRunModel.buildReducer(JobScheduleRunModel.actions);

    constructor(model = {}) {
        super(model);
        const {id, jobId, date, triggerDescription, jobState, jobPercentageComplete, error, hasWarnings, hasSoftErrors} = model;

        this.id = id;
        this.jobId = jobId;
        this.date = getLocaleDateTimeFromUTC(date);
        this.triggerDescription = triggerDescription;

        this.jobState = jobState;
        this.error = error;
        this.hasWarnings = hasWarnings;
        this.hasSoftErrors = hasSoftErrors;

        if (!isNaN(jobPercentageComplete)) {
            this.jobPercentageComplete = Math.round(jobPercentageComplete);
        }
    }

    getErrorMessage(t=i18next.t) {
        if (this.error == null || typeof this.error === 'string')
            return this.error;

        const {message=this.error.key} = t(`popupInfo:${this.error.key || popupInfoKeys.TEMPLATE}`, {
            ...this.error.values, ...this.error.valueKeys,
            returnObjects: true
        });

        return message;
    }
}

export default JobScheduleRunModel;