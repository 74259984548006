import './FileUpload.css';
import React, {useRef} from 'react';
import {useTranslation} from "react-i18next";
import {buildClassName} from "../../../utilities/helperFunctions";
import {getInvalidMessageSpan} from "../InvalidMessageSpan";
import Text from "../Text/Text";
import {useGetMissingClasses} from "../AccessibilityAssist";

function FileUpload(props) {
    const {t} = useTranslation(['common']);
    const {
        label,
        fileName,
        onFileChange,
        isRequired,
        isDisabled,
        fileTypes,
        className,
        invalidMessage,
        hideInvalidMessage,
        requiredMessage,
        ...attr
    } = props;

    const fileInputRef = useRef();

    function onClick(e) {
        if (e.key === 'Enter') {
            fileInputRef.current.click();
        }
    }

    const required = (isRequired && !fileName);
    const requiredClassName = required ? ' is-required' : '';

    const id = attr.id || attr.name;
    const labelId = label && `${id}:label`;

    const {
        combinedAriaLabelledBy,
        invalidMessageSpan
    } = getInvalidMessageSpan({
        id,
        ariaLabelledBy: labelId,
        isRequired: required,
        invalidMessage,
        requiredMessage
    });

    const clazzName = buildClassName(
        'field file-upload',
        isDisabled && 'is-disabled',
        className,
        useGetMissingClasses(label, {ariaLabelledBy: combinedAriaLabelledBy}),
    );

    return (
        <div>
            <div className={clazzName} {...attr}>
                {label &&
                    <Text id={labelId} value={label}/>
                }
                <div className="control">
                    <div className={'file is-small is-fullwidth is-right' + requiredClassName}
                        tabIndex={0} onKeyUp={onClick} aria-labelledby={combinedAriaLabelledBy} aria-invalid={required}>

                        <label className={'file-label'}>
                            <input className="file-input" type="file" accept={fileTypes} onChange={onFileChange}
                                ref={fileInputRef} tabIndex={-1} disabled={isDisabled}/>

                            <span className="label file-choose">
                                {t('common:option.chooseFile')}
                            </span>
                            <span className={'file-name'}>
                                {fileName}
                            </span>
                        </label>
                    </div>
                </div>
            </div>

            {!hideInvalidMessage && invalidMessageSpan}
        </div>
    );
}

export default FileUpload;
