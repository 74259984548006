import React from "react";
import DetailsModel, {DetailsApi, DetailsSaga} from "../generics/DetailsModel";
import ComponentStateModel from "../generics/ComponentStateModel";
import {all, cancel, put, race, select, take} from "redux-saga/effects";
import {contextCall, contextFork, contextSaga, contextTakeLeading, isDisabledWrapper} from "../../saga/sagaFunctions";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import {details, formElementTypes, noticeTypeKeys, userCommentTypes} from "../../utilities/constants";
import {
    actionCreator,
    deepCopy,
    generateUUID4,
    getElementAndDescendantCssText,
    getEntries,
    getInputFormattedDateAndTime,
    getValues,
    isNotEmptyNorFalsy
} from "../../utilities/helperFunctions";
import EditModel from "../scheduler/EditModel";
import {retryCall} from "../../saga/tryCatchWrapper";
import ParameterModel from "../library/ParameterModel";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import UserNoticeHtmlTemplate from "../../components/noticeTemplate/UserNoticeHtmlTemplate";
import {Provider} from "react-redux";
import {ICON_SRC_SET} from "../../components/common/Tablet/Tablet";
import {reduxStore} from "../../index";
import UserNoticeModel from "./UserNoticeModel";
import {permissionKeys} from "../../i18next/keys";
import ReactDOM from "react-dom";


class NoticeTemplateModel extends DetailsModel {

    static nom = 'NoticeTemplateModel';
    static actions = NoticeTemplateModel.buildActions('NOTICE_TEMPLATE');
    static actionCreators = NoticeTemplateModel.buildActionCreators(NoticeTemplateModel.actions);
    static reducer = NoticeTemplateModel.buildReducer(NoticeTemplateModel.actions);

    static readOnlyParameters = [
        new ParameterModel({name: '{legal_hold_name}', value: '', friendlyName: 'Legal Hold Name', description: '', regex: '', readOnly: true}),
        new ParameterModel({name: '{custodian_name}', value: '', friendlyName: 'Custodian Name', description: '', regex: '', readOnly: true}),
        new ParameterModel({name: '{sent_date}', value: '', friendlyName: 'Sent Date', description: '', regex: '', readOnly: true}),
        new ParameterModel({name: '{notice_subject}', value: '', friendlyName: 'Notice Subject', description: '', regex: '', readOnly: true})
    ];
    static originalNoticeReadOnlyParameters = [
        new ParameterModel({name: '{original_notice_subject}', value: '', friendlyName: 'Original Notice Subject', description: '', regex: '', readOnly: true}),
        new ParameterModel({name: '{original_notice_respond_by_date}', value: '', friendlyName: 'Original Notice Respond By Date', description: '', regex: '', readOnly: true}),
        new ParameterModel({name: '{original_notice_sent_date}', value: '', friendlyName: 'Original Notice Sent Date', description: '', regex: '', readOnly: true})
    ];
    static builtInParameterDummyValues = {
        '{legal_hold_name}': 'Project Hold',
        '{custodian_name}': 'John H. Smith',
        '{sent_date}': '8/27/21, 3:30 PM',
        '{notice_subject}': 'Notice Subject',
        '{original_notice_subject}': 'Legal Hold Survey #2 - Data Collection',
        '{original_notice_respond_by_date}': '8/30/21, 12:00 AM',
        '{original_notice_sent_date}': '8/24/21, 12:00 PM'
    };

    static componentActionCreators = {
        ...NoticeTemplateModel.buildComponentUpdateActionCreators(),
        ...NoticeTemplateModel.buildComponentSetActiveActionCreators()
    };

    constructor(model={}) {
        super(model);
        const {version, type, subject, message, parameters, surveyFormOptions} = model;

        this.version = version;
        this.type = type;
        this.subject = subject;
        this.message = message;

        this.surveyFormOptions = surveyFormOptions || [];
        this.parameters = getValues(parameters || {})
            .map(parameter => new ParameterModel(parameter));
    }

    static formElementDefaultRow = {enabled: true, optional: false, type: formElementTypes.INPUT, title: '', description: '',
        label: '', modelNamingPattern: '{custodian_name} - Files', value: '', allowedValues: []};

    static makeFormElementRow(opts) {
        return {
            ...NoticeTemplateModel.formElementDefaultRow,
            ...opts,
            key: generateUUID4()
        };
    }

    static isResponseType(type) {
        switch (type) {
            case noticeTypeKeys.HOLD:
            case noticeTypeKeys.RELEASE:
            case noticeTypeKeys.SURVEY:
            case noticeTypeKeys.RECURRING:
                return true;
            default:
                return false;
        }
    }

    static getIdVersionKey(id, version) {
        return `${id}:${version}`;
    }

    static evaluateParametersInputTable(obj, parameters) {
        let evaluatedObj = obj;

        if (obj != null && typeof obj === 'object') {
            evaluatedObj = new obj.constructor();
            for (const [key, val] of getEntries(obj)) {
                evaluatedObj[key] = this.evaluateParametersInputTable(val, parameters);
            }

        } else if (typeof obj === 'string') {
            for (const parameter of parameters) {
                if (!parameter.name) continue;

                const paramValue = this.builtInParameterDummyValues[parameter.name] || parameter.value || parameter.friendlyName;
                // Replace parameterName w/ value
                evaluatedObj = evaluatedObj.replaceAll(parameter.name, paramValue);
            }
        }
        return evaluatedObj;
    }

    static buildDefaultFormState(state, props={}) {
        const parameters = deepCopy(NoticeTemplateModel.readOnlyParameters);
        // Reminders and Escalations original notice parameters
        if (noticeTypeKeys.REMINDER === props.type || noticeTypeKeys.ESCALATION === props.type) {
            parameters.push(...deepCopy(NoticeTemplateModel.originalNoticeReadOnlyParameters));
        }

        return {
            force: null,
            type: null,
            noticeName: '',
            description: '',
            enabled: true,

            subject: '',
            message: '',
            // inputTable data struct: [[{value: ''}, {value: ''}, ...], ...]
            parameters,
            surveyFormOptions: [
                NoticeTemplateModel.makeFormElementRow({type: formElementTypes.HEADER, title: 'Questionnaire', description: 'Please answer the following questions'}),
                NoticeTemplateModel.makeFormElementRow({type: formElementTypes.CHECKBOX, description: 'Confirm your acknowledgement of this hold notice', label: 'I, {custodian_name}, acknowledge'}),
            ],
            isDisabled: false
        };
    }

    static isFormOptionValid(option) {
        switch (option.type) {
            case formElementTypes.HEADER:
                return true;
            case formElementTypes.DROPDOWN:
                return !!option.description && option.allowedValues.some(val => !!val)
            case formElementTypes.CHECKBOX:
                return !!option.label;
            case formElementTypes.DATA_UPLOAD:
                return !!option.modelNamingPattern;
            default:
                return !!option.description;
        }
    }

    static validateFormData(formData) {
        const {noticeName, subject, parameters, surveyFormOptions} = formData;

        // name is required
        const isParametersValid = ParameterModel.validateParameterInputTable(parameters);
        const isSurveyFormValid = !isNotEmptyNorFalsy(surveyFormOptions) || surveyFormOptions
            .filter(opt => opt.enabled)
            .every(this.isFormOptionValid);

        return {
            messagePane: !!noticeName && isParametersValid,
            responsePane: !!subject,
            submit: isSurveyFormValid
        };
    }

    static buildActions(type) {
        return {
            ...super.buildActions(type),
            // NOTICE TEMPLATE ACTIONS
            SAVE_EDIT: 'SAVE_NOTICE_TEMPLATE_EDIT',
            CANCEL_EDIT: 'CANCEL_NOTICE_TEMPLATE_EDIT'
        }
    }

    static buildActionCreators(actions) {
        return {
            ...super.buildActionCreators(actions),
            // NOTICE TEMPLATE ACTION CREATORS
            saveEdit: actionCreator(actions.SAVE_EDIT),
            cancelEdit: actionCreator(actions.CANCEL_EDIT)
        }
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'noticeTemplateDisplay',
                type: 'Display',
                state: {
                    noticeTemplateId: null,
                    isNoticeTemplateFormActive: false
                }
            },
            {
                key: 'noticeTemplateTablet',
                type: 'Tablet',
                state: {
                    isDisabled: false
                }
            },
            {
                key: 'noticeTemplateForm',
                type: 'Form',
                state: NoticeTemplateModel.buildDefaultFormState()
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'NOTICE_TEMPLATE_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }
}

export class NoticeTemplateApi extends DetailsApi {

    static location = '/resources';
    static type = '/noticeTemplate';

    static getSurveyFormOptions(id, version) {
        return axiosInstance.get(`/scheduler/resources/noticeTemplate/${id}/surveyFormOptions/${version}`);
    }
}

export class NoticeTemplateSaga extends DetailsSaga {

    static ModelType = NoticeTemplateModel;
    static ModelApi = NoticeTemplateApi;

    static activationComponent = 'NOTICE_TEMPLATE_DISPLAY';
    static variableNames = {
        detailsMap: 'noticeTemplateDetailsMap',
        instanceId: 'noticeTemplateId',
        modelName: 'noticeName',
        isFormActive: 'isNoticeTemplateFormActive',
        formState: 'noticeTemplateForm',
        updateDisplay: 'updateDisplay',
        updatePane: 'updateTablet'
    };

    static translations = {
        itemTitle: '$t(noticeTemplate:label.name)',
        itemLower: '$t(noticeTemplate:label.name_lower)'
    };

    static buildActivationEffects(dispatch) {
        return [
            ...super.buildActivationEffects(dispatch),
            put(NoticeTemplateModel.actionCreators.startPollingDetails()),

            contextTakeLeading(NoticeTemplateModel.actions.SAVE_EDIT, this, 'editSave'),
            contextTakeLeading(NoticeTemplateModel.actions.CANCEL_EDIT, this, 'editCancel')
        ]
    }

    static buildDeactivationEffects() {
        return [
            ...super.buildDeactivationEffects(),
            put(NoticeTemplateModel.actionCreators.stopPollingDetails())
        ]
    }

    static* submitForm(action) {
        const formData = {
            ...(yield select(state => state.componentStates.noticeTemplateForm)),
            ...action.payload.formData
        };
        const noticeTemplate = yield contextCall(this, 'getSaveValues', formData);

        const {data} = yield contextCall(NoticeTemplateApi, 'post', noticeTemplate);
        yield put(NoticeTemplateModel.actionCreators.addDetails(data));

        yield all([
            put(NoticeTemplateModel.actionCreators.hideForm()),
            put(NoticeTemplateModel.actionCreators.showTablet(data.id))
        ]);
    }

    static* startEdit(action) {
        const {id} = action.payload;
        const editValues = yield contextCall(this, 'getEditValues', id);

        yield all([
            put(this.ModelType.actionCreators.showForm(editValues)),
            put(this.ModelType.actionCreators.hideTablet())
        ]);

        yield all([
            put(EditModel.actionCreators.start(this.ModelType.nom, editValues)),
            retryCall(contextSaga(this, 'waitForEditAction'), id, editValues)
        ]);

        yield put(EditModel.actionCreators.reset());
    }

    static* waitForEditAction(id, editValues) {
        const elsewhereEditTask = yield contextFork(this, 'checkElsewhereEdit', id, editValues);

        // Wait for save/cancel action
        const [save] = yield race([
            take(EditModel.actions.SAVE),
            take(EditModel.actions.CANCEL)
        ]);

        // Cancel poll-check if edit was updated elsewhere
        yield cancel(elsewhereEditTask);
        if (save) {
            yield contextCall(this, 'verifySave', save);
            yield* isDisabledWrapper(this.ModelType.componentActionCreators.updateForm, contextSaga(this, 'saveEdit'), id, save);
        }
    }

    static* saveEdit(id) {
        const editValues = yield select(state => state.componentStates.noticeTemplateForm);
        const saveValues = yield contextCall(this, 'getSaveValues', editValues);

        try {
            const {data} = yield contextCall(this.ModelApi, 'putDetails', id, saveValues);

            yield all([
                put(this.ModelType.actionCreators.updateDetails({[id]: new this.ModelType(data)})),
                put(EditModel.actionCreators.saveSuccess())
            ]);
        } catch (error) {
            yield put(EditModel.actionCreators.saveFail());
            throw error;
        }
    }

    static* editSave() {
        const {id} = yield select(state => state.componentStates.noticeTemplateForm);
        yield put(EditModel.actionCreators.save());

        const [success] = yield race([
            take(EditModel.actions.SAVE_SUCCESS),
            take(EditModel.actions.SAVE_FAIL)
        ]);

        if (success) {
            yield put(this.ModelType.componentActionCreators.updateDisplay({isNoticeTemplateFormActive: false, noticeTemplateId: id}));
        }
    }

    static* editCancel() {
        const {id} = yield select(state => state.componentStates.noticeTemplateForm);
        yield put(EditModel.actionCreators.cancel());

        yield put(this.ModelType.componentActionCreators.updateDisplay({isNoticeTemplateFormActive: false, noticeTemplateId: id}));
    }

    static* getEditValues(id) {
        const {name: noticeName, version, respondByDate, parameters, userPermissions, ...rest} = yield select(state => state.noticeTemplateDetailsMap.get(id));

        const editValues = {
            id,
            ...NoticeTemplateModel.buildDefaultFormState(null, {type: rest.type}),
            noticeName,
            ...rest
        };
        // Add after read-only parameters
        editValues.parameters.push(...deepCopy(parameters));

        if (!!respondByDate) {
            editValues.respondByDate = getInputFormattedDateAndTime(0, respondByDate)[0];
        }

        return editValues;
    }

    static* getSaveValues(values) {
        const {
            noticeName: name,
            type,
            subject,
            message,
            parameters,
            surveyFormOptions,

            force,
            isDisabled,
            ...rest
        } = values;

        const saveValues = {
            ...rest,
            name,
            type,
            subject,
            message: typeof message === 'function' ? message() : message,
            parameters: parameters.filter(param => !param.readOnly).reduce((acc, curr) => {
                acc[curr.name] = curr;
                return acc;
            }, {})
        };

        if (NoticeTemplateModel.isResponseType(type)) {
            saveValues.surveyFormOptions = surveyFormOptions.filter(NoticeTemplateModel.isFormOptionValid);
        }

        // yield this.getNoticeHtml(saveValues);
        return saveValues;
    }

    static* getDuplicateValues(editValues) {
        const {id, version, force, isDisabled, ...rest} = editValues;
        return rest;
    }

    static* getNoticeHtml(noticeTemplate) {
        const {type, message, subject, surveyFormOptions} = noticeTemplate;

        const now = Date.now();
        const templateUserNotice = new UserNoticeModel({
            type,
            subject,
            message,
            createdDate: now,
            lastViewedDate: now,
            respondByDate: now,
            lastRespondedDate: now,
            respondedDate: now,
            escalatedDate: now,

            surveyFormOptions,
            userPermissions: getValues(permissionKeys)
        });

        const templateComments = [{
            id: 1,
            type: userCommentTypes.REGULAR,
            message: 'Comment',
            createdDate: now
        }, {
            id: 2,
            type: userCommentTypes.ADMIN_NOTE,
            message: 'Admin Note',
            createdDate: now + 1000
        }];

        const rootDiv = document.createElement('div');
        rootDiv.id = 'root';
        rootDiv.classList.add('App');

        const body = document.createElement('body');
        body.appendChild(rootDiv);

        ReactDOM.render(
            <Provider store={reduxStore}>
                <UserNoticeHtmlTemplate legalHoldName={'Legal Hold'}
                    userNotice={templateUserNotice} comments={templateComments}/>
            </Provider>,
            rootDiv
        );
        // Wait for icon src + render to complete before proceeding
        yield take(ICON_SRC_SET);

        // Get all required styles
        const style = document.createElement('style');
        style.innerHTML = getElementAndDescendantCssText(rootDiv);

        console.log(style.innerHTML)

        const title = document.createElement('title');
        title.id = 'userNoticeTitle';
        title.textContent = 'User Notice';

        const head = document.createElement('head');
        head.appendChild(title);
        head.appendChild(style);

        const userNoticeHtmlDoc = document.createElement('html');
        userNoticeHtmlDoc.append(head, body);

        return userNoticeHtmlDoc.outerHTML;
    }

    static* queryDetails() {
        const response =  yield contextCall(NoticeTemplateApi, 'getDetails');
        const key = details.NOTICE_TEMPLATES;

        if (AxiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(NoticeTemplateModel.actionCreators.setDetailsMap(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }
}

export default NoticeTemplateModel;
