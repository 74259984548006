import React from "react";
import {useTranslation} from "react-i18next";
import {stringToBool} from "../../../utilities/helperFunctions";
import OptionToggleRow from "./OptionToggleRow";

function ShowDisabledItemsOption(props) {
    const {t} = useTranslation(['showDisabledItems']);
    const {setting: showDisabledItems, updateSetting} = props;

    function setShowDisabledItemSetting(event) {
        const {name: setting, checked} = event.currentTarget.dataset;
        updateSetting(setting, stringToBool(checked));
    }

    return (
        <div className="options-table display-input">
            <div className="table-header-group">
                <div className="table-header"/>
                <div className="table-header"/>
            </div>
            <div className="table-row-group">
                {Object
                    .keys(showDisabledItems)
                    .map(key =>
                        <OptionToggleRow key={key} label={t(`showDisabledItems:item.${key}`)} name={key}
                            useTrElement={false} checked={showDisabledItems[key]} onClick={setShowDisabledItemSetting}/>
                    )
                }
            </div>
        </div>
    )
}

export default ShowDisabledItemsOption;