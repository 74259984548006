import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import "./ParameterList.css";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import {getLocalDateTimeValueFromUtc} from "../common/HTMLTextInput/UtcDateTimeInput";
import {ListDropdown} from "../common/Dropdown/Dropdown";
import {useDispatch, useSelector} from "react-redux";
import {
    buildFakeEvent,
    bytesCountToReadableCount,
    getDataNameFromParentNode,
    getParentDatasetAttr,
    getValues,
    nameLocaleCompare,
    parseDateInputFormatToNuixDate,
    parseNuixDateToDateInputFormat,
    parseNuixDateToTimeInputFormat,
    parseTimeInputFormatToNuixDate
} from "../../utilities/helperFunctions";
import {useTranslation} from "react-i18next";
import {FileLibrarySelectableItem} from "../common/SelectableItem/SelectableItem";
import {libraryFileTypeKeys} from "../../i18next/keys";
import {details, userSettings} from "../../utilities/constants";
import FileUpload from "../common/FileUpload/FileUpload";
import {createFileHandler} from "../../utilities/componentFunctions";
import encode from "base64-arraybuffer";
import PopupModel from "../../models/scheduler/PopupModel";
import FileLibraryModel from "../../models/filelibrary/FileLibraryModel";
import ServerFileParameter from "./ServerFileParameter";
import {useClearOnFirstCallback} from "../../utilities/formHooks";
import ParameterModel from "../../models/library/ParameterModel";
import HighlightHTMLTextInput from "../common/HTMLTextInput/HighlightHTMLTextInput";
import {getLocalDateValueFromUtc} from "../common/HTMLTextInput/UtcDateInput";

const emptyArray = [];
function ParameterInput(props) {

    const {
        parameter,
        onValueChange,
        updateParameter,
        allowedValues,
        filter,
        ignoreRegex,
        isEditActive,
        isDisabled,
        fromWorkflowBuilder,
        ...attr
    } = props;

    const value = parameter.getProtectedValue(isEditActive);
    const clearOnFirstCallback = useClearOnFirstCallback();

    const {id, parameterType, name, min, max, disabled} = parameter;
    const isInvalid = !ignoreRegex && !(parameter.valid != null ? parameter.valid : parameter.validate());
    const invalidMessage = !ignoreRegex && parameter.getInvalidReason();

    const [values, setValues] = useState(getAllowedValues(parameterType, allowedValues));
    useEffect(() => {
        setValues(getAllowedValues(parameterType, allowedValues));
    }, [parameterType, allowedValues]);

    if (parameter.isPasswordParameter()) {
        return (
            <HTMLTextInput id={id} name={name} type={'password'} defaultValue={value} onChange={onValueChange}
                onBeforeInput={clearOnFirstCallback} isInputDisabled={!isEditActive} invalidMessage={invalidMessage}
                {...attr} isDisabled={isDisabled} isInvalid={isInvalid}/>
        )
    }

    if (isEditActive) {
        const hasAllowedValues = Array.isArray(values) && values.length > 0;

        if (parameter.isDatasetParameter() || parameter.isLegalHoldParameter() || parameter.isThirdPartyServiceParameter()) {
            let _values = values;
            if (!Array.isArray(values)) {
                _values = emptyArray;
            }
            return (
                <TypeParameterInput id={id} name={name} value={value} values={_values} onValueChange={onValueChange}
                    invalidMessage={invalidMessage} {...attr} hasAllowedValues={hasAllowedValues}
                    isInvalid={isInvalid} isDisabled={isDisabled}/>
            )
        }

        if (parameter.isFileParameter()) {
            let _values = values;
            if (!Array.isArray(values)) {
                _values = emptyArray;
            }
            return (
                <FileParameterInput id={id} name={name} value={value} values={_values} onValueChange={onValueChange}
                    invalidMessage={invalidMessage} {...attr} filter={filter} hasAllowedValues={hasAllowedValues}
                    isInvalid={isInvalid} isDisabled={isDisabled}/>
            )
        }

        if (parameter.isServerFileParameter() || parameter.isServerFolderParameter()) {
            return (
                <ServerFileParameter id={id} parameter={parameter} value={value} updateParameter={updateParameter}
                    invalidMessage={invalidMessage} {...attr} isInvalid={isInvalid} isDisabled={isDisabled}/>
            )
        }

        if (parameter.isFileContentParameter()) {
            return (
                <FileContentsParameterInput id={id} parameter={parameter} name={name} value={value} values={values}
                    invalidMessage={invalidMessage} onValueChange={onValueChange} hasAllowedValues={hasAllowedValues}
                    {...attr} isInvalid={isInvalid} isDisabled={isDisabled}/>
            )
        }

        if (Array.isArray(values) || parameter.isDropdownParameter() || parameter.isBooleanParameter()) {
            let _values = values;
            if (!Array.isArray(values)) {
                _values = emptyArray;
            }
            return (
                <ParameterObjectValuesDropdown id={id} name={name} value={value} values={_values}
                    onValueChange={onValueChange} invalidMessage={invalidMessage}
                    {...attr} isInvalid={isInvalid} isDisabled={disabled || isDisabled}/>
            )
        }

        if (parameter.isDateParameter()) {
            return <NuixDateInput id={id} name={name} value={value} onValueChange={onValueChange}
                min={min} max={max} invalidMessage={invalidMessage}
                {...attr} isInvalid={isInvalid} isDisabled={isDisabled}/>
        }

        if (parameter.isDateTimeParameter()) {
            return <NuixDateTimeInput id={id} name={name} value={value} onValueChange={onValueChange}
                min={min} max={max} invalidMessage={invalidMessage}
                {...attr} isInvalid={isInvalid} isDisabled={isDisabled}/>
        }
    }


    let _attr = {type: 'text'};
    if (parameter.isNumberParameter()) {
        _attr.type = 'number';
        _attr.step = 'any';
    }

    if (fromWorkflowBuilder) {
        return (
            <HighlightHTMLTextInput id={id} name={name} value={value} onChange={onValueChange} {..._attr}
                min={min} max={max} invalidMessage={invalidMessage}
                {...attr} isInputDisabled={!isEditActive} isDisabled={isDisabled} isInvalid={isInvalid}/>
        )
    }

    return (
        <HTMLTextInput id={id} name={name} value={value} onChange={onValueChange} {..._attr}
            min={min} max={max} invalidMessage={invalidMessage}
            {...attr} isInputDisabled={!isEditActive} isDisabled={isDisabled} isInvalid={isInvalid}/>
    )
}

function getAllowedValues(type, allowedValues) {
    if (type === ParameterModel.Type.BOOLEAN && !Array.isArray(allowedValues)) {
        return [{value: 'True'}, {value: 'False'}];
    }
    return allowedValues;
}

function FileContentsParameterInput(props) {
    const dispatch = useDispatch();

    const {id, name, value, onValueChange, isDisabled} = props;
    const {libraryFileMaxSize} = useSelector(state => state.schedulerDetails.configuration);
    const [fileName, setFileName] = useState();

    useEffect(() => {
        if (value != null) {
            setFileName(value.split(":")[0])
        }
    }, [value]);

    const fileChangeHandler = createFileHandler({
        onloadend: (event, file) => {
            const fileSize = file.size;

            if (fileSize > libraryFileMaxSize) {
                dispatch(PopupModel.actionCreators.showError({
                    info: {
                        key: 'maxFilesizeError',
                        values: {
                            maxUploadSize: bytesCountToReadableCount(libraryFileMaxSize)
                        }
                    }
                }));
                return;
            }

            const fileData = file.name +":"+encode.encode(event.target.result);
            onValueChange({
                target:{
                    name,
                    value: fileData,
                }
            }, event);
        }
    });

    return (
        <FileUpload id={id} isDisabled={isDisabled}
            fileName={fileName} onFileChange={fileChangeHandler}/>
    )
}

// format: yyyyMMdd
function NuixDateInput(props) {
    const {name, value, onValueChange, min, max, ...attr} = props;
    const {t} = useTranslation(['aria']);

    const [date, setDate] = useState();
    const [minMaxDates, setMinMaxDates] = useState({});

    useEffect(() => {
        setDate(parseNuixDateToDateInputFormat(value));
        setMinMaxDates({
            max: isNaN(parseInt(max)) ? null : getLocalDateValueFromUtc(max),
            min: isNaN(parseInt(min)) ? null : getLocalDateValueFromUtc(min),
        });
    }, [value, min, max]);

    function onChange(event) {
        if (typeof onValueChange === 'function') {
            const {value} = event.target;
            onValueChange({target: {name, value: parseDateInputFormatToNuixDate(value)}}, event);
            setDate(value);
        }
    }

    if (date == null) return null;
    return (
        <HTMLTextInput aria-label={t('aria:input.date', {name})} name={name} type={'date'}
            min={minMaxDates.min} max={minMaxDates.max} value={date}
            onChange={onChange} style={{width: 'fit-content'}} {...attr}
        />
    )
}

// format: yyyyMMddTHHmmss
function NuixDateTimeInput(props) {
    const {name, value, onValueChange, min, max, ...attr} = props;
    const {t} = useTranslation(['aria']);

    // Track as internal state to prevent re-computing on every render
    // YYYY-MM-ddTHH:mm
    const [dateTime, setDateTime] = useState();
    const [minMaxDates, setMinMaxDates] = useState({});

    useEffect(() => {
        if (value) {
            setDateTime(`${parseNuixDateToDateInputFormat(value)}T${parseNuixDateToTimeInputFormat(value)}`);
        } else {
            setDateTime('');
        }
        setMinMaxDates({
            max: isNaN(parseInt(max)) ? null : getLocalDateTimeValueFromUtc(max),
            min: isNaN(parseInt(min)) ? null : getLocalDateTimeValueFromUtc(min),
        });
    }, [value, min, max]);

    function onChange(event) {
        if (typeof onValueChange === 'function') {
            const {name, value} = event.target;
            let newValue = '';
            if (value) {
                const [date, time] = value.split('T');
                const nuixDate = parseDateInputFormatToNuixDate(date);
                const nuixTime = parseTimeInputFormatToNuixDate(time);
                newValue = `${nuixDate}T${nuixTime}`;
            }
            onValueChange({target: {name, value: newValue}}, event);
            setDateTime(value);
        }
    }

    if (dateTime == null) return null;
    return (
        <HTMLTextInput aria-label={t('aria:input.datetime', {name})} type={'datetime-local'}
            name={name} value={dateTime} onChange={onChange} min={minMaxDates.min} max={minMaxDates.max}
            style={{width: 'fit-content'}} {...attr}
        />
    )
}

function TypeParameterInput(props) {
    const {name, value, onValueChange, hasAllowedValues} = props;

    // If no allowedValues, set value to blank
    useEffect(() => {
        if (!hasAllowedValues && value !== '' && typeof onValueChange === 'function') {
            onValueChange({target: {name, value: ''}});
        }
    }, [name, value, onValueChange, hasAllowedValues]);

    return <ParameterObjectValuesDropdown isRequired {...props}/>
}

const scriptedLibraryFileTypeFilter = [libraryFileTypeKeys.PYTHON_SCRIPT, libraryFileTypeKeys.JS_SCRIPT, libraryFileTypeKeys.RUBY_SCRIPT ,libraryFileTypeKeys.POWERSHELL_SCRIPT];
const libraryFileTypeFilter = [libraryFileTypeKeys.CUSTOM_FILE, ...scriptedLibraryFileTypeFilter];
function FileParameterInput(props) {
    const {value, values, hasAllowedValues, filter, isDisabled} = props;
    const dispatch = useDispatch();

    const toggleRef = useRef();
    const onHeaderClick = toggleRef.current;

    const fileLibraryDetailsMap = useSelector(state => state.fileLibraryDetailsMap);
    const libraryFileDetailsMap = useSelector(state => state.libraryFileDetailsMap);

    const sortedFileLibraries = getValues(fileLibraryDetailsMap).sort(nameLocaleCompare);
    const sortedLibraryFiles = getValues(libraryFileDetailsMap).sort(nameLocaleCompare);

    const hasLoaded = useSelector(state => state.hasLoaded);
    const isLoading = !hasLoaded[details.FILE_LIBRARIES] || sortedFileLibraries.some(fileLibrary => !hasLoaded[fileLibrary.id]);

    // Query for fileLibraries if not queried before
    useEffect(() => {
        if (!hasLoaded[details.FILE_LIBRARIES]) {
            dispatch(FileLibraryModel.actionCreators.queryDetails());
        } else {
            // Query libraryFiles for fileLibraries not previously queried
            for (const fileLibrary of sortedFileLibraries) {
                if (!hasLoaded[fileLibrary.id]) {
                    dispatch(FileLibraryModel.actionCreators.querySettings(fileLibrary.id));
                }
            }
        }
    }, [hasLoaded]);

    const allowedValues = new Set(values.map(({value}) => value));
    const fileLibraryFiles = {};

    for (const file of sortedLibraryFiles) {
        if (hasAllowedValues && !allowedValues.has(file.id)) {
            continue;
        }
        if (!libraryFileTypeFilter.includes(file.nuixFileType)) {
            continue;
        }
        if (Array.isArray(filter) && !filter.includes(file.nuixFileType)) {
            continue;
        }
        if (fileLibraryFiles[file.fileLibraryId] == null) {
            fileLibraryFiles[file.fileLibraryId] = [];
        }
        fileLibraryFiles[file.fileLibraryId].push(file);
    }

    const items = [];
    for (const fileLibrary of sortedFileLibraries) {
        let childrenText = fileLibrary.name;
        if (!Array.isArray(fileLibraryFiles[fileLibrary.id])) {
            continue;
        }
        const libraryFileItems = fileLibraryFiles[fileLibrary.id]
            .map(file => {
                childrenText += file.name;
                return {
                    name: value === file.id ? (<><i>{fileLibrary.name}</i> {file.name}</>) : (<>{file.name}</>),
                    fileName: file.name,
                    value: file.id,
                    fileType: file.nuixFileType,
                    isHeader: false,
                    childrenText: `${file.name} ${fileLibrary.name}`,
                    hoverTitle: file.description
                }
            });

        if (libraryFileItems.length > 0) {
            const fileLibraryItem = {
                name: fileLibrary.name,
                value: fileLibrary.id,
                isHeader: true,
                childrenText,
                onItemClick: onHeaderClick
            };
            items.push(fileLibraryItem, ...libraryFileItems);
        }
    }

    return <ParameterObjectValuesDropdown {...props} searchKey={"childrenText"} toggleRef={toggleRef} values={items}
        ItemComponent={FileLibrarySelectableItem} isRequired isLoading={isLoading} isDisabled={isDisabled}/>
}

export function ParameterObjectValuesDropdown(props) {
    const {id, name, value, values=[], onValueChange, isLoading, ...rest} = props;
    const {t} = useTranslation(['aria']);

    const ref = useRef();
    const [selectedValue, setSelectedValue] = useState(value);
    const onValueSelect = useCallback(event => {
        if (typeof onValueChange === 'function') {
            const {dataset: {value}, parentNode} = event.currentTarget;
            const name = getDataNameFromParentNode(parentNode);

            setSelectedValue(value);
            onValueChange({target: {name, value}}, event);
        }
    }, [onValueChange]);

    // If mismatch between value && selectedValue, force @value
    useLayoutEffect(() => {
        if (selectedValue !== value) {
            setSelectedValue(value);
        }
    }, [selectedValue, value]);

    // If only 1 value, select value
    // If value not in values, clear value
    useLayoutEffect(() => {
        if (rest.isDisabled) return;
        if (values.length === 1 && values[0].value !== value) {
            if (isLoading) return;
            const val = values[0].value || '';
            const index = getParentDatasetAttr(ref.current, 'index');
            onValueSelect(buildFakeEvent({name, value: val, index}));

        } else if (value && values.every(val => val.value !== value)) {
            const index = getParentDatasetAttr(ref.current, 'index');
            onValueSelect(buildFakeEvent({name, value: '', index}));
        }
    }, [values, isLoading, rest.isDisabled]);

    const {showObjectIds} = useSelector(state => state.userSettingsMap.get(userSettings.TROUBLESHOOT));
    const items = values.map(_value => {
        const item = {
            ..._value
        };

        item.name ||= item.value;
        if (showObjectIds && item.name !== item.value) {
            if (typeof item.name === 'object') {
                item.name = <>{item.name} (${item.value})</>;
            } else {
                item.name += ` (${item.value})`;
            }
        }
        return item;
    });

    return (
        <div className="value-dropdown" ref={ref}>
            <ListDropdown isEdit id={id} name={name} style={{width: '100%'}}
                aria-label={t('aria:hiddenAssistText.parameter', {parameter: name})}
                value={selectedValue} items={items} onItemSelect={onValueSelect}
                buttonStyle={{maxWidth: '100%', width: '100%', justifyContent: 'space-between'}}
                {...rest}
            />
        </div>
    )
}

export default ParameterInput;