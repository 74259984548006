import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import './Dropdown.css';
import {useDispatch, useSelector} from 'react-redux';
import {
    buildClassName,
    buildFakeEvent,
    bytesCountToReadableCount,
    checkNestedExists,
    convertPixelsToRem,
    convertRemToPixels,
    getKeys,
    getReactElementTextContent,
    getValues,
    includesSome,
    isNotEmptyNorFalsy,
    nameLocaleCompare
} from '../../../utilities/helperFunctions';
import {
    awsRegions,
    azureRegions,
    azureVmSizes,
    azureVmSources,
    azureVmTypes,
    byteUnits,
    datasetType,
    details,
    legalHoldTriggerConfiguration,
    recurringNoticeFrequencyKeys,
    SAME_AS_TRIGGERING_JOB,
    webhookEventTriggers
} from '../../../utilities/constants';
import {executionModeIcon, getPriorityIcon} from '../../../utilities/iconResolver';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getNuixLicenceSourceNameValues, getServerNameValues} from '../../../reselect/selectors';
import {
    CheckedSelectableItem,
    FileLibrarySelectableItem,
    IconSelectableItem,
    NameSelectableItem
} from "../SelectableItem/SelectableItem";
import {useTranslation} from "react-i18next";
import {
    awsIdTypeKeys,
    executionModeKeys,
    instanceIdleActionKeys,
    ldapSearchScopeKeys,
    legalHoldStateKeys,
    libraryFileTypeKeys,
    loginLinkScopeKeys,
    permissionKeys,
    policyPrincipalTypeKeys,
    policyScopeTypeOptionKeys,
    priorityKeys,
    statusKeys,
    statusStateFilterKeys,
    webhookPlatformKeys
} from "../../../i18next/keys";
import SearchBar from "../SearchBar/SearchBar";
import ClientModel from "../../../models/client/ClientModel";
import MatterModel from "../../../models/client/MatterModel";
import LegalHoldModel from "../../../models/legalhold/LegalHoldModel";
import FileLibraryModel from "../../../models/filelibrary/FileLibraryModel";
import {
    getRenderedHeightOffset,
    useAsyncEffect,
    useRenderedItemHeight,
    useSimpleVirtualRendering
} from "../../../utilities/hooks";
import DataRepositoryModel from "../../../models/data/DataRepositoryModel";
import ExecutionProfileModel from "../../../models/settings/ExecutionProfileModel";
import ResourcePoolModel from "../../../models/settings/ResourcePoolModel";
import SchedulerModel, {SchedulerApi} from "../../../models/scheduler/SchedulerModel";
import ReduxStateModel from "../../../models/scheduler/ReduxStateModel";
import FocusTrap from "focus-trap-react";
import UserServiceModel from "../../../models/user/UserServiceModel";
import Text from "../Text/Text";
import LibraryModel from "../../../models/library/LibraryModel";
import WorkflowTemplateModel from "../../../models/library/WorkflowTemplateModel";
import {getInvalidMessageSpan} from "../InvalidMessageSpan";
import {Button} from "../Button/Button";
import {useGetMissingClasses} from "../AccessibilityAssist";


const DropdownButton = React.forwardRef((props, ref) => {
    const {
        icon,
        selectedItems,
        isBold,
        isIconDropdown,
        children,
        ...attr
    } = props;

    return (
        <Button ref={ref} isImg={isIconDropdown} {...attr}>
            {icon}
            {!isIconDropdown &&
                <>
                    <Text ElementTag="span" value={selectedItems}
                        isEllipsis isBold={isBold}/>

                    <span className="icon">
                        <FontAwesomeIcon icon="angle-down"/>
                    </span>
                </>
            }
            {children}
        </Button>
    )
});

function Dropdown(props) {

    const [isOpen, setIsOpen] = useState(false);
    const [_dropdownStyle, setDropdownStyle] = useState({});
    const [_contentStyle, setContentStyle] = useState({});
    const [_menuStyle, setMenuStyle] = useState({});
    const [_fillerStyle, setFillerStyle] = useState({});

    const dropdownRef = useRef();
    const menuRef = useRef();
    const buttonRef = useRef();

    const {
        name,
        icon,
        items,
        keepOpen,
        buttonStyle,
        style,
        searchKey,
        trackSearchText,
        disableSearchFilter,
        enableVirtualRendering=true,
        ButtonComponent=DropdownButton,
        className,

        'data-value': dataValue,
        'data-type': dataType,
        toggleRef,

        invalidMessage,
        requiredMessage,
        hideInvalidMessage,

        'aria-labelledby': ariaLabelledBy,
        'aria-label': ariaLabel,

        isTransparent,
        isIconDropdown,
        isBold,
        isRight,
        isUp,
        isEdit,
        isOutlined,
        isSelected,
        isRequired,
        isInvalid,
        isDisabled
    } = props;


    const getDropdownStyles = useCallback(({minWidth=0, minHeight=0}={}) => {
        const {top, left, bottom, height} = dropdownRef.current.getBoundingClientRect();
        const {width} = buttonRef.current.getBoundingClientRect();
        const offsetParent = dropdownRef.current.offsetParent;

        const {top: parentTop, left: parentLeft} = offsetParent.getBoundingClientRect();
        const offsetParentComputedStyle = window.getComputedStyle(offsetParent);

        const offsetTopBorderWidth = offsetParentComputedStyle.getPropertyValue('border-top-width');
        const offsetLeftBorderWidth = offsetParentComputedStyle.getPropertyValue('border-left-width');

        const newWidth = Math.max(width, minWidth);
        const newHeight = Math.max(height, minHeight);

        // Set style to absolute and position accordingly so dropdown can hover over parent containers if needed
        const dropdownStyle = {
            position: 'absolute',
            top: `calc(${top - parentTop}px - ${offsetTopBorderWidth})`,
            left: `calc(${left - parentLeft}px - ${offsetLeftBorderWidth})`,
            width: `${newWidth}px`
        };

        // Set filler dimensions to give weight in place of the absolute positioned dropdown
        // Because absolute positioning removes the dropdown from DOM flow
        const fillerStyle = {
            width: `${newWidth}px`,
            height: `${newHeight}px`
        };

        // Calculate the content max-height to not overflow browser window
        const bottomPadding = 1;
        const allowedMenuHeight = convertPixelsToRem(window.innerHeight - bottom) - bottomPadding;
        const contentStyle = {
            minWidth: `${Math.max(newWidth, 175)}px`,
            maxHeight: `${allowedMenuHeight}rem`
        };

        return {
            dropdownStyle,
            fillerStyle,
            contentStyle
        }
    }, []);

    const toggleDropdown = useCallback(() => {
        const open = dropdownRef.current && dropdownRef.current.classList.contains('is-open');
        let styles = {};

        if (!open) {
            styles = getDropdownStyles();
        }

        // Set dropdown styles
        const {dropdownStyle, fillerStyle, contentStyle} = styles;
        setDropdownStyle(dropdownStyle);
        setFillerStyle(fillerStyle);
        setContentStyle(contentStyle);

        // toggle isOpen and reset searchText
        setIsOpen(prevIsOpen => !prevIsOpen);
        setSearchText('');

    }, [getDropdownStyles]);

    const closeOnOutsideEvent = useCallback(event => {
        const open = dropdownRef.current && dropdownRef.current.classList.contains('is-open');
        if (open && !dropdownRef.current.contains(event.target)) {
            toggleDropdown();
        }
    }, [toggleDropdown]);

    const closeOnResizeEvent = useCallback(() => {
        const open = dropdownRef.current && dropdownRef.current.classList.contains('is-open');
        if (open) {
            toggleDropdown();
        }
    }, [toggleDropdown]);

    useEffect(() => {
        if (toggleRef != null) {
            toggleRef.current = toggleDropdown;
        }
    }, [toggleRef, toggleDropdown]);


    ///////////////////////////////////////////////// SEARCH BAR ///////////////////////////////////////////////////////
    const searchBarRef = useRef();
    const [_searchText, setSearchText] = useState("");
    function searchHandler(event) {
        setSearchText(event.target.value);
    }

    const searchTextEq0 = _searchText.length === 0;
    const showSearchInput = useCallback(event => {
        // No search for iconDropdowns
        if (isIconDropdown || dropdownRef.current == null || event.key == null || event.key.length > 1)
            return;

        const open = dropdownRef.current.classList.contains('is-open');
        if (open && searchTextEq0) {
            // Update styles to have minWidth: 9rem
            const {dropdownStyle, fillerStyle} = getDropdownStyles({minWidth: convertRemToPixels(9)});
            setDropdownStyle(dropdownStyle);
            setFillerStyle(fillerStyle);

            // Set starting searchText
            setSearchText(event.key);
        }
    }, [isIconDropdown, searchTextEq0, getDropdownStyles]);

    useEffect(() => {
        // Focus on searchBar
        if (isOpen && !searchTextEq0) {
            searchBarRef.current.querySelector('input').focus();
        }
    }, [isOpen, searchTextEq0]);

    useEffect(() => {
        if (typeof trackSearchText === 'function') {
            trackSearchText(_searchText);
        }
    }, [_searchText]);

    // Set dropdown eventListeners
    useEffect(() => {
        document.body.addEventListener('keypress', showSearchInput);
        document.body.addEventListener('mousedown', closeOnOutsideEvent);
        document.body.addEventListener('scroll', closeOnOutsideEvent, true);
        window.addEventListener('resize', closeOnResizeEvent);

        return () => {
            document.body.removeEventListener('keypress', showSearchInput);
            document.body.removeEventListener('mousedown', closeOnOutsideEvent);
            document.body.removeEventListener('scroll', closeOnOutsideEvent, true);
            window.removeEventListener('resize', closeOnResizeEvent);
        }
    }, [closeOnOutsideEvent, closeOnResizeEvent, showSearchInput]);

    const {selectedItems} = props;
    // If selectedItems is larger than current filler width (when dropdown isOpen)
    // Expand filler width
    useLayoutEffect(() => {
        if (isOpen && buttonRef.current != null) {
            const {width} = buttonRef.current.getBoundingClientRect();

            setFillerStyle(prevStyle => ({
                ...prevStyle,
                width: `${width}px`
            }));
            setContentStyle(prevStyle => ({
                ...prevStyle,
                minWidth: `${Math.max(width, 175)}px`,
            }));
        }
    }, [isOpen, selectedItems, searchTextEq0]);

    // Re-position dropdown menu if needed
    useLayoutEffect(() => {
        if (menuRef.current != null) {
            const {left, right} = menuRef.current.getBoundingClientRect();

            const menuStyle = {};
            if (left < 10 && right > 10) {
                menuStyle.right = 'auto';
                menuStyle.left = 0;
            } else if (right < 10 && left > 10) {
                menuStyle.right = 0;
                menuStyle.left = 'auto';
            }
            setMenuStyle(menuStyle);
        }
    }, [isOpen]);



    // Items will either be an Array or a React.Fragment with an Array of children
    let dropdownItems;
    if (Array.isArray(items)) {
        dropdownItems = items;
    } else if (checkNestedExists(items, 'props', 'children') && items.type === React.Fragment) {
        dropdownItems = items.props.children.flat();
    } else {
        dropdownItems = [items];
    }

    const dropdownItemsNotFalsy = isNotEmptyNorFalsy(dropdownItems);
    let filteredItems = dropdownItemsNotFalsy && dropdownItems;

    if (!disableSearchFilter) {
        filteredItems = filteredItems && filteredItems
            .filter(item => {
                if (!item) return false;
                if (item.props?.['data-exclude']) return true;

                const textContent = getReactElementTextContent(item, searchKey).toLowerCase();
                return textContent.includes(_searchText.toLowerCase());
            });
    }

    useEffect(() => {
        if (isOpen && !dropdownItemsNotFalsy) {
            toggleDropdown();
        }
    }, [isOpen, dropdownItemsNotFalsy]);

    const id = props.id || name;
    const required = isRequired && !isDisabled;
    const invalid = isInvalid && !isDisabled;

    const {
        combinedAriaLabel,
        combinedAriaLabelledBy,
        invalidMessageSpan
    } = getInvalidMessageSpan({
        id,
        isInvalid: invalid,
        isRequired: required,
        invalidMessage,
        requiredMessage,
        ariaLabel,
        ariaLabelledBy: [ariaLabelledBy]
    });


    const dropdownClassName = buildClassName(
        'dropdown',
        isOpen && 'is-open',
        isTransparent && 'is-transparent',
        isRight && 'is-right',
        isUp && 'is-up',
        isEdit && 'is-edit',
        useGetMissingClasses(undefined, {ariaLabel: combinedAriaLabel, ariaLabelledBy: combinedAriaLabelledBy}),
        className
    );

    return (
        <>
            <div style={_fillerStyle}/>
            <FocusTrap active={isOpen}
                focusTrapOptions={{allowOutsideClick: true, escapeDeactivates: toggleDropdown, fallbackFocus: document.activeElement}}
            >
                <div>
                    <article id={id} className={dropdownClassName} ref={dropdownRef}
                        data-value={dataValue} data-type={dataType} style={{...style, ..._dropdownStyle}}
                    >
                        {_searchText ?
                            <SearchBar searchBarRef={searchBarRef} value={_searchText} onChange={searchHandler}/>
                            :
                            <ButtonComponent ref={buttonRef} selectedItems={selectedItems} icon={icon} style={buttonStyle}
                                aria-haspopup="listbox" aria-expanded={isOpen} aria-controls={`${id}:dropdownMenu`} title={combinedAriaLabel}
                                aria-labelledby={combinedAriaLabelledBy} aria-label={combinedAriaLabel} aria-invalid={required || invalid}
                                ignoreAccessibility onClick={toggleDropdown} isIconDropdown={isIconDropdown} isWhite={!(isOutlined || isOpen || isEdit)}
                                isBold={isBold} isRequired={isRequired} isInvalid={isInvalid} isSelected={isSelected} isDisabled={isDisabled}>

                            </ButtonComponent>
                        }

                        {isNotEmptyNorFalsy(filteredItems) &&
                        <section role="listbox" id={`${id}:dropdownMenu`} className="dropdown-menu" ref={menuRef} style={_menuStyle}>
                            <div className="dropdown-content" data-name={name}
                                onClick={keepOpen ? null : toggleDropdown} style={_contentStyle}>

                                {enableVirtualRendering ?
                                    <VirtualRenderedDropdownContent isOpen={isOpen} items={filteredItems}/>
                                    :
                                    filteredItems
                                }
                            </div>
                        </section>
                        }
                    </article>
                    {!hideInvalidMessage && invalidMessageSpan}
                </div>
            </FocusTrap>
        </>
    )
}

function VirtualRenderedDropdownContent(props) {
    const {
        items,
        isOpen,
        ...attr
    } = props;

    const dropdownContentRef = useRef();
    const listContainerSelector = '.dropdown-content';

    const itemHeightRef = useRenderedItemHeight(dropdownContentRef, listContainerSelector, 31.1875);
    const heightOffset = getRenderedHeightOffset(dropdownContentRef, listContainerSelector, 0);
    const virtualRenderRows = useSimpleVirtualRendering({
        containerRef: dropdownContentRef,
        itemHeightRef,
        size: items.length,
        heightOffset,
        forceRender: isOpen
    });

    return (
        <div ref={dropdownContentRef} {...attr}>
            {virtualRenderRows((index, topOffset) => {
                const item = items[index];
                if (item == null) {
                    return null;
                }

                return {
                    ...item,
                    props: {
                        ...item.props,
                        style: {position: 'relative', top: topOffset}
                    }
                };
            })}
        </div>
    )
}


export function ListDropdown(props) {
    const {t} = useTranslation(['aria']);
    const {
        value,
        items,
        onItemSelect,
        hiddenValues,

        ItemComponent=NameSelectableItem,
        componentProps,

        autoSelect,
        noneSelectedMessage,
        isRequired,
        ...rest
    } = props;

    let selectedItem = null;
    if (value !== undefined) {
        selectedItem = items.find(_item => _item.key === value || _item.value === value);
    }

    // If only 1 value && value not included in values, select first value
    useEffect(() => {
        if (!autoSelect || selectedItem != null) return;

        let val;
        if (items.length === 1 && items[0] != null) {
            val = items[0].value
        }
        if (val === undefined) {
            val = null;
        }

        if (value !== val) {
            onItemSelect(buildFakeEvent({name: rest.name, value: val}));
        }
    }, [autoSelect, rest.name, value, items, onItemSelect]);

    const selectedName = (selectedItem != null && selectedItem.name) || value || noneSelectedMessage;
    const ariaLabel = rest['aria-label'] || t(`aria:hiddenAssistText.${rest.name}`);

    return (
        <Dropdown isBold isRequired={isRequired && !value} data-value={value}
            selectedItems={selectedName} aria-label={ariaLabel} {...rest}
            items={items
                .filter(item => hiddenValues == null || !hiddenValues[item.value])
                .map((item, index) => {
                    const {
                        exclude,
                        isItalic,
                        isBorder,
                        isCentered,
                        isNoWrap,
                        isDisabled,
                        onSelect
                    } = item;

                    const onItemClick = typeof onSelect === 'function' ? onSelect : onItemSelect;
                    return (
                        <ItemComponent role="option" key={index} item={item} onItemClick={onItemClick} isCentered={isCentered} isBorder={isBorder}
                            data-exclude={exclude} isNoWrap={isNoWrap} isItalic={isItalic} isDisabled={isDisabled || rest.isDisabled}
                            {...componentProps}
                        />
                    )
                })
            }
        />
    )
}

export function CheckedDropdown(props) {
    const {t} = useTranslation(['common']);
    const {
        onToggle,
        onClear,
        setSelected,
        items,
        ItemComponent=CheckedSelectableItem,
        noneSelectedMessage,
        isRequired,
        ...attr
    } = props;

    function onItemClick(event) {
        if (typeof onToggle === 'function') {
            onToggle(event);
        }

        if (typeof setSelected === 'function') {
            const {value} = event.currentTarget.dataset;
            setSelected(prevSelected => ({
                ...prevSelected,
                [value]: !prevSelected[value]
            }));
        }
    }

    function onClearSelected(event) {
        if (typeof onClear === 'function') {
            onClear(event);
        }

        if (typeof setSelected === 'function') {
            setSelected({});
        }
    }

    const checkedItems = items.filter(item => item.isChecked).map(item => item.name);
    const someChecked = checkedItems.length > 0;
    const selectedItems = someChecked ? checkedItems.join(', ') : noneSelectedMessage;
    const ariaLabel = attr['aria-label'] || t(`aria:hiddenAssistText.${attr.name}`);

    return (
        <Dropdown keepOpen selectedItems={selectedItems} isRequired={isRequired && !someChecked}
            aria-label={ariaLabel} {...attr}
            items={items.length > 0 &&
            <>
                <NameSelectableItem item={{name: t('common:option.clearSelected')}} onItemClick={onClearSelected}
                    isNoWrap isCentered isBorder isDisabled={attr.isDisabled || !someChecked}
                />
                {items.map(item =>
                    <ItemComponent key={item.key || item.value} item={item} onItemClick={onItemClick}
                        isEllipsis isNoWrap isDisabled={attr.isDisabled}
                    />
                )}
            </>
            }
        />
    )
}

export function MenuDropdown(props) {
    const {menuOptions, onOptionClick, menuIcon, isDisabled, ...attr} = props;

    let icon = menuIcon;
    if (icon === undefined) {
        icon = (
            <span className="icon">
                <FontAwesomeIcon icon="angle-down"/>
            </span>
        )
    }

    return (
        <Dropdown isIconDropdown isRight isTransparent enableVirtualRendering={false}
            icon={icon} isDisabled={isDisabled} {...attr}
            items={
                menuOptions.map((option, index) => {
                    const {name, value, isSeparator} = option;

                    return isSeparator ?
                        <span key={index} className="dropdown-menu-separator"/>
                        :
                        <NameSelectableItem key={value} onItemClick={onOptionClick} item={{name, value}}
                            isNoWrap isDisabled={isDisabled || option.isDisabled}
                        />
                })
            }
        />
    );
}

export function ClientCheckedDropdown(props) {
    const {t} = useTranslation(['aria', 'client']);
    const dispatch = useDispatch();

    const {
        name='clientIds',
        clientIds,
        onToggle,
        ...rest
    } = props;

    const hasLoaded = useSelector(state => state.hasLoaded);
    // Query for clients
    useEffect(() => {
        dispatch(ClientModel.actionCreators.queryDetails());
    }, [dispatch]);

    const onSelect = useCallback(event => {
        onToggle(event);

        const {value} = event.currentTarget.dataset;
        if (hasLoaded[value] == null) {
            // Query for matters
            dispatch(MatterModel.actionCreators.queryDetailsSubset(value));
        }
    }, [hasLoaded, onToggle, dispatch]);

    const clientItems = useSelector(useCallback(state => {
        return getValues(state.clientDetailsMap)
            .map(client => ({
                name: client.name,
                value: client.id,
                isChecked: !!clientIds[client.id]
            }));
    }, [clientIds]));

    return (
        <CheckedDropdown name={name} aria-label={t('aria:hiddenAssistText.clientChecked')} noneSelectedMessage={t('client:option.allClients')}
            items={clientItems} onToggle={onSelect} {...rest}
        />
    )
}

export function MatterCheckedDropdown(props) {
    const {t} = useTranslation(['aria', 'matter']);
    const dispatch = useDispatch();

    const {
        name='matterIds',
        matterIds,
        clientIds,
        updateState,
        querySettings,
        onToggle,
        ...rest
    } = props;

    const hasLoaded = useSelector(state => state.hasLoaded);
    const matterDetailsMap = useSelector(state => state.matterDetailsMap);
    // Filter matters on clientIds change
    useEffect(() => {
        updateState(prev => ({
            [name]: getKeys(prev[name])
                .reduce((acc, id) => {
                    const {clientId} = matterDetailsMap.get(id) || {};
                    // If client is still checked, maintain prev selection
                    if (clientIds[clientId]) {
                        acc[id] = prev[name][id];
                    }
                    return acc;
                }, {})
        }));
    }, [name, JSON.stringify(clientIds), updateState, matterDetailsMap]);

    const onSelect = useCallback(event => {
        const {value} = event.currentTarget.dataset;
        onToggle(event);

        if (querySettings && hasLoaded[value] == null) {
            // Query settings
            dispatch(MatterModel.actionCreators.querySettings(value));
        }
    }, [hasLoaded, onToggle, querySettings, dispatch]);

    const matterItems = useSelector(useCallback(state => {
        return getValues(state.matterDetailsMap)
            .filter(matter => clientIds[matter.clientId])
            .map(matter => ({
                name: matter.name,
                value: matter.id,
                isChecked: !!matterIds[matter.id]
            }));
    }, [matterIds, clientIds]));

    return (
        <CheckedDropdown name={name} aria-label={t('aria:hiddenAssistText.matterChecked')} noneSelectedMessage={t('matter:option.allMatters')}
            items={matterItems} onToggle={onSelect} {...rest}
        />
    )
}

export function LegalHoldCheckedDropdown(props) {
    const {t} = useTranslation(['aria', 'legalHold']);
    const dispatch = useDispatch();

    const {
        name='legalHoldIds',
        legalHoldIds,
        matterIds,
        updateState,
        ...rest
    } = props;

    const hasLoaded = useSelector(state => state.hasLoaded[details.LEGAL_HOLDS]);
    const legalHoldDetailsMap = useSelector(state => state.legalHoldDetailsMap);

    // Query for legalHolds if not loaded
    useEffect(() => {
        if (!hasLoaded) {
            dispatch(LegalHoldModel.actionCreators.queryDetails());
        }
    }, [hasLoaded, dispatch]);

    const isMatterIdsEmpty = !isNotEmptyNorFalsy(matterIds);
    // Filter legalHolds on matterIds change
    useEffect(() => {
        if (isMatterIdsEmpty) return;

        updateState(prev => ({
            [name]: getKeys(prev[name])
                .reduce((acc, id) => {
                    const {matterId} = legalHoldDetailsMap.get(id) || {};
                    // If matter is still checked, maintain prev selection
                    if (matterIds[matterId]) {
                        acc[id] = prev[name][id];
                    }
                    return acc;
                }, {})
        }));
    }, [name, matterIds, updateState, legalHoldDetailsMap]);

    const legalHoldItems = useSelector(useCallback(state => {
        return getValues(state.legalHoldDetailsMap)
            .filter(legalHold => isMatterIdsEmpty || matterIds[legalHold.matterId])
            .map(legalHold => ({
                name: legalHold.name,
                value: legalHold.id,
                isChecked: !!legalHoldIds[legalHold.id]
            }));
    }, [matterIds, legalHoldIds]));

    return (
        <CheckedDropdown name={name} aria-label={t('aria:hiddenAssistText.legalHoldChecked')} noneSelectedMessage={t('legalHold:option.allLegalHolds')}
            items={legalHoldItems} {...rest}
        />
    )
}

export function EngineCheckedDropdown(props) {
    const {t} = useTranslation(['aria', 'job']);

    const {
        name='engineIds',
        engineIds,
        ...rest
    } = props;

    const engineItems = useSelector(useCallback(state => {
        return getValues(state.engineDetailsMap)
            .map(engine => ({
                name: engine.name,
                value: engine.id,
                isChecked: !!engineIds[engine.id]
            }));
    }, [engineIds]));

    return (
        <CheckedDropdown name={name} aria-label={t('aria:hiddenAssistText.engineChecked')} noneSelectedMessage={t('job:option.allEngines')}
            items={engineItems} {...rest}
        />
    )
}

export function ResourcePoolCheckedDropdown(props) {
    const {t} = useTranslation(['aria', 'job']);

    const {
        name='resourcePoolIds',
        resourcePoolIds,
        ...rest
    } = props;

    const resourcePoolItems = useSelector(useCallback(state => {
        const items = [{
            name: 'Unassigned',
            value: '',
            isChecked: !!resourcePoolIds['']
        }];

        for (const resourcePool of getValues(state.resourcePoolDetailsMap)) {
            items.push({
                name: resourcePool.name,
                value: resourcePool.id,
                isChecked: !!resourcePoolIds[resourcePool.id]
            });
        }
        return items;
    }, [resourcePoolIds]));

    return (
        <CheckedDropdown name={name} aria-label={t('aria:hiddenAssistText.resourcePoolChecked')} noneSelectedMessage={t('job:option.allResourcePools')}
            items={resourcePoolItems} {...rest}
        />
    )
}

export function StatusFilterCheckedDropdown(props) {
    const {t} = useTranslation(['aria', 'common']);

    const {
        name='statusStateFilter',
        statusStateFilter,
        ...rest
    } = props;

    const noticeStateFilterItems = getValues(statusStateFilterKeys)
        .map(state => ({
            name: t(`common:status.${state}`),
            value: state,
            isChecked: !!statusStateFilter[state]
        }));

    return (
        <CheckedDropdown name={name} aria-label={t('aria:hiddenAssistText.statusStateFilterChecked')} noneSelectedMessage={t('common:option.allStates')}
            items={noticeStateFilterItems} {...rest}
        />
    )
}

export function PriorityDropdown(props) {
    const {t} = useTranslation(['aria', 'common', 'jobSchedule']);
    const {selectedPriority, onPrioritySelect, canSetSameAs={}, isDisabled, ...rest} = props;

    console.log(selectedPriority)
    return (
        <Dropdown id={'priorityDropdown'} aria-label={t('aria:hiddenAssistText.priority')} name={'priority'}
            isBold isDisabled={isDisabled}
            selectedItems={selectedPriority === SAME_AS_TRIGGERING_JOB ?
                t(`jobSchedule:option.${SAME_AS_TRIGGERING_JOB}`)
                :
                t(`common:priority.${selectedPriority}`)
            }
            icon={selectedPriority !== priorityKeys.MEDIUM &&
            <span className="icon is-small">
                <img src={getPriorityIcon(selectedPriority)}
                    alt={t(`aria:hiddenAssistText.${selectedPriority}PriorityIcon`)}/>
            </span>
            }
            items={
                <>
                    {canSetSameAs.job &&
                    <NameSelectableItem item={{name: t(`jobSchedule:option.${SAME_AS_TRIGGERING_JOB}`), value: SAME_AS_TRIGGERING_JOB}}
                        isNoWrap onItemClick={onPrioritySelect} isDisabled={isDisabled}
                    />
                    }

                    {getValues(priorityKeys).map(_priority => {
                        const item = {
                            name: t(`common:priority.${_priority}`),
                            value: _priority
                        }
                        if (_priority !== priorityKeys.MEDIUM) {
                            item.icon = <img src={getPriorityIcon(_priority)} alt={t(`aria:hiddenAssistText.${_priority}PriorityIcon`)}/>;
                        }

                        return (
                            <IconSelectableItem key={_priority} item={item} onItemClick={onPrioritySelect}
                                isDisabled={isDisabled}
                            />
                        );
                    })}
                </>
            }
            {...rest}
        />
    )
}

export function SupportedExecutionModeDropdown(props) {
    const {
        selectedSupportedExecutionMode,
        onSupportedExecutionModeSelect,
        canSetSameAs = {},
        isDisabled,
        ...rest
    } = props;
    const {t} = useTranslation(['aria', 'common']);

    return (
        <Dropdown id={'supportedExecutionModeDropdown'} aria-label={t('aria:hiddenAssistText.supportedExecutionMode')}
            name={'supportedExecutionMode'} isBold
            selectedItems={t(`common:executionMode.${selectedSupportedExecutionMode}`)}
            icon={
                <span className="icon is-small">
                        <img src={executionModeIcon(selectedSupportedExecutionMode)}
                            alt={t(`aria:hiddenAssistText.${selectedSupportedExecutionMode}Icon`)}/>
                  </span>
            }
            items={
                <>
                    {getValues(executionModeKeys).map(_executionMode => {
                        const item = {
                            name: t(`common:executionMode.${_executionMode}`),
                            value: _executionMode
                        }

                        item.icon = <img src={executionModeIcon(_executionMode)} alt={t(`aria:hiddenAssistText.${_executionMode}Icon`)}/>;

                        return (
                            <IconSelectableItem key={_executionMode} item={item}
                                onItemClick={onSupportedExecutionModeSelect}
                                isDisabled={isDisabled}
                            />
                        );
                    })}
                </>
            }
            {...rest}
        />
    )
}

export  function NuixLicenceSourceDropdown(props) {
    const {selectedNuixLicenceSourceId, onNuixLicenceSourceSelect, ...rest} = props;
    const {t} = useTranslation(['aria', 'nuixLicenceSource']);

    const nuixLicenceSourceItems = useSelector(getNuixLicenceSourceNameValues);

    return (
        <ListDropdown id={'licenceSourceDropdown'} name={'nuixLicenceSourceId'}
            aria-label={t('aria:hiddenAssistText.nuixLicenceSource')} noneSelectedMessage={t('nuixLicenceSource:option.select')}
            value={selectedNuixLicenceSourceId} items={nuixLicenceSourceItems} onItemSelect={onNuixLicenceSourceSelect}
            {...rest}
        />
    );
}

export function ServerDropdown(props) {
    const {selectedServerId, onServerSelect, ...rest} = props;
    const {t} = useTranslation(['aria', 'server']);

    const serverItems = useSelector(getServerNameValues);

    return (
        <ListDropdown id={'serverDropdown'} name={'serverId'}
            aria-label={t('aria:hiddenAssistText.server')} noneSelectedMessage={t('server:option.select')}
            value={selectedServerId} items={serverItems} onItemSelect={onServerSelect}
            {...rest}
        />
    );
}

export function ExecutionProfileDropdown(props) {
    const {t} = useTranslation(['aria', 'executionProfile', 'jobSchedule']);
    const dispatch = useDispatch();

    const {
        selectedExecutionProfileId,
        onExecutionProfileSelect,
        canSetSameAs = {},
        permissionFilter = [permissionKeys.SUBMIT_JOB, permissionKeys.STAGE_JOB],
        ...rest
    } = props;

    const executionProfileItems = [{name: t('executionProfile:option.unassigned'), value: ''}];
    if (canSetSameAs.job) {
        executionProfileItems.push({
            name: t(`jobSchedule:option.${SAME_AS_TRIGGERING_JOB}`),
            value: SAME_AS_TRIGGERING_JOB
        });
    }

    const hasLoaded = useSelector(state => state.hasLoaded[details.EXECUTION_PROFILES]);
    const executionProfileDetailsMap = useSelector(state => state.executionProfileDetailsMap);

    // Query for executionProfiles if not loaded
    useEffect(() => {
        if (!hasLoaded) {
            dispatch(ExecutionProfileModel.actionCreators.queryDetails());
        }
    }, [hasLoaded, dispatch]);

    executionProfileItems.push(
        ...getValues(executionProfileDetailsMap)
            .filter(executionProfile => includesSome(executionProfile.userPermissions, permissionFilter))
            .map(executionProfile => ({
                name: executionProfile.name,
                value: executionProfile.id
            }))
    );

    return (
        <ListDropdown id={'executionProfileDropdown'} name={'executionProfileId'}
            aria-label={t('aria:hiddenAssistText.executionProfile')} noneSelectedMessage={t('executionProfile:option.unassigned')}
            value={selectedExecutionProfileId} items={executionProfileItems} onItemSelect={onExecutionProfileSelect}
            {...rest}
        />
    );
}

export function ResourcePoolDropdown(props) {
    const {t} = useTranslation(['aria', 'resourcePool', 'jobSchedule']);
    const dispatch = useDispatch();

    const {
        selectedResourcePoolId,
        onResourcePoolSelect,
        canSetSameAs = {},
        permissionFilter = [permissionKeys.SUBMIT_JOB, permissionKeys.STAGE_JOB],
        ...rest
    } = props;

    const resourcePoolItems = [{name: t('resourcePool:option.unassigned'), value: ''}];
    if (canSetSameAs.job) {
        resourcePoolItems.push({
            name: t(`jobSchedule:option.${SAME_AS_TRIGGERING_JOB}`),
            value: SAME_AS_TRIGGERING_JOB
        });
    }

    const hasLoaded = useSelector(state => state.hasLoaded[details.RESOURCE_POOLS]);
    const resourcePoolDetailsMap = useSelector(state => state.resourcePoolDetailsMap);

    // Query for executionProfiles if not loaded
    useEffect(() => {
        if (!hasLoaded) {
            dispatch(ResourcePoolModel.actionCreators.queryDetails());
        }
    }, [hasLoaded, dispatch]);

    resourcePoolItems.push(
        ...getValues(resourcePoolDetailsMap)
            .filter(resourcePool => includesSome(resourcePool.userPermissions, permissionFilter))
            .map(resourcePool => ({
                name: resourcePool.name,
                value: resourcePool.id
            }))
    );

    return (
        <ListDropdown id={'resourcePoolDropdown'} name={'resourcePoolId'}
            aria-label={t('aria:hiddenAssistText.resourcePool')} noneSelectedMessage={t('resourcePool:option.unassigned')}
            value={selectedResourcePoolId} items={resourcePoolItems} onItemSelect={onResourcePoolSelect}
            {...rest}
        />
    );
}

export function DataRepositoryDropdown(props) {
    const {t} = useTranslation(['aria', 'dataRepository']);
    const dispatch = useDispatch();

    const {
        type,
        selectedDataRepositoryId,
        allowedDataRepositoryIds,
        onDataRepositorySelect,
        permissionFilter,
        ...rest
    } = props;

    const hasLoaded = useSelector(state => state.hasLoaded[details.DATA_REPOSITORIES]);
    const dataRepositoryDetailsMap = useSelector(state => state.dataRepositoryDetailsMap);

    // Query for dataRepositories if not loaded
    useEffect(() => {
        if (!hasLoaded) {
            dispatch(DataRepositoryModel.actionCreators.queryDetails());
        }
    }, [hasLoaded, dispatch]);

    const dataRepositoryItems = getValues(dataRepositoryDetailsMap)
        .filter(repository => repository.status && repository.status.code !== statusKeys.ERROR)
        .filter(repository => !type || repository.type === type)
        .filter(repository => !isNotEmptyNorFalsy(allowedDataRepositoryIds) || allowedDataRepositoryIds.includes(repository.id))
        .filter(repository => !permissionFilter || includesSome(repository.userPermissions, permissionFilter))
        .map(repository => {

            let name = repository.name;
            switch (type) {
                case datasetType.MANAGED:
                    const availableSpace = repository.getAvailableSpace();
                    if (availableSpace) {
                        name += ` - ${bytesCountToReadableCount(availableSpace)}`;
                    }
                    break;
            }

            return {
                name,
                value: repository.id
            };
        });

    return (
        <ListDropdown id={'dataRepositoryDropdown'} name={'dataRepositoryId'}
            aria-label={t('aria:hiddenAssistText.dataRepository')} noneSelectedMessage={t('dataRepository:option.select')}
            value={selectedDataRepositoryId} items={dataRepositoryItems} onItemSelect={onDataRepositorySelect}
            buttonStyle={{maxWidth: '100%'}}
            {...rest}
        />
    );
}

export function LegalHoldTriggerConfigurationTriggerDropdown(props) {
    const {isSilent, state, isDisabled, ...attr} = props;
    const {t} = useTranslation(['aria', 'legalHold']);

    const triggerConfigurationTriggerItems = getValues(legalHoldTriggerConfiguration)
        .filter(trigger => !isSilent || trigger !== legalHoldTriggerConfiguration.ON_CUSTODIAN_RESPONSE)
        .filter(trigger => state === legalHoldStateKeys.DRAFT || isDisabled || state === legalHoldStateKeys.ACTIVE && trigger !== legalHoldTriggerConfiguration.ON_MATTER_ACTIVATE)
        .map(trigger => ({
            name: t(`legalHold:triggerConfigurationTrigger.${trigger}`),
            value: trigger
        }));

    return (
        <ListDropdown id={'legalHoldTriggerConfigurationTriggerDropdown'} name={'trigger'} aria-label={t(`aria:hiddenAssistText.triggerConfigurationTrigger`)}
                      noneSelectedMessage={t(`legalHold:option.selectJobTrigger`)} items={triggerConfigurationTriggerItems} isDisabled={isDisabled} {...attr}
        />
    );
}

export function WorkflowLibraryDropdown(props) {
    const {isSilent, libraryFilter, ...attr} = props;
    const {t} = useTranslation(['aria']);
    const dispatch = useDispatch();

    const permissionFilter = [permissionKeys.SUBMIT_JOB, permissionKeys.STAGE_JOB]
    const libraryItems = [];

    const libraryDetailsMap = useSelector(state => state.libraryDetailsMap);
    const hasLoaded = useSelector(state => state.hasLoaded[details.LIBRARIES]);
    useEffect(() => {
        if (!hasLoaded) {
            dispatch(LibraryModel.actionCreators.queryDetails())
        }

    }, [hasLoaded, libraryDetailsMap]);


    libraryItems.push(
        ...getValues(libraryDetailsMap)
            .filter(library => includesSome(library.userPermissions, permissionFilter))
            .filter(library => library.enabled)
            .filter(library => typeof libraryFilter !== 'function' || libraryFilter(library))
            .map(({name, id}) => ({
                name,
                value: id
            }))
    );


    return (
        <ListDropdown id={'libraryDropdown'} name={'workflowLibraryId'} aria-label={t(`aria:hiddenAssistText.workflowLibrary`)}
                      noneSelectedMessage={t(`legalHold:option.selectWorkflowLibrary`)} items={libraryItems} {...attr}
        />
    );
}

export function WorkflowTemplateDropdown(props) {
    const {isSilent, workflowLibraryId, workflowFilter, ...attr} = props;
    const {t} = useTranslation(['aria']);
    const dispatch = useDispatch();

    const permissionFilter = [permissionKeys.SUBMIT_JOB, permissionKeys.STAGE_JOB]
    const workflowTemplateItems = [];

    const workflowTemplateDetailsMap = useSelector(state => state.workflowTemplateDetailsMap);
    const hasLoaded = useSelector(state => state.hasLoaded[workflowLibraryId]);
    useEffect(() => {
        if (workflowLibraryId == null) {
            return;
        }

        if (!hasLoaded) {
            dispatch(WorkflowTemplateModel.actionCreators.queryDetailsSubset(workflowLibraryId))
        }
    }, [workflowLibraryId]);

    workflowTemplateItems.push(
        ...getValues(workflowTemplateDetailsMap)
        .filter(workflowTemplate => includesSome(workflowTemplate.userPermissions, permissionFilter))
        .filter(workflowTemplate => workflowTemplate.enabled)
        .filter(workflowTemplate => workflowTemplate.libraryId === workflowLibraryId)
        .filter(template => typeof workflowFilter !== 'function' || workflowFilter(template))
        .map(({name, id}) => ({
            name,
            value: id
        }))
    );


    return (
        <ListDropdown id={'workflowTemplateDropdown'} name={'workflowTemplateId'} aria-label={t(`aria:hiddenAssistText.workflowTemplate`)}
                      noneSelectedMessage={t(`legalHold:option.selectWorkflowTemplate`)} items={workflowTemplateItems} {...attr}
        />
    );
}

export function InstanceIdleActionDropdown(props) {
    const {selectedInstanceIdleAction, onInstanceIdleActionSelect, ...rest} = props;
    const {t} = useTranslation(['aria', 'resourcePool']);

    const instanceIdleActionItems = getValues(instanceIdleActionKeys)
        .map(_instanceIdleAction => ({
            name: t(`resourcePool:instanceIdleAction.${_instanceIdleAction}`),
            value: _instanceIdleAction
        }));

    return (
        <ListDropdown id={'instanceIdleActionDropdown'} name={'instanceIdleAction'} noneSelectedMessage={t('resourcePool:option.selectInstanceIdleAction')}
            aria-label={t('aria:hiddenAssistText.instanceIdleAction')} value={selectedInstanceIdleAction}
            items={instanceIdleActionItems} onItemSelect={onInstanceIdleActionSelect}
            {...rest}
        />
    );
}

export function AwsRegionDropdown(props) {
    const {selectedAwsRegion, onAwsRegionSelect, ...rest} = props;
    const {t} = useTranslation(['aria', 'resourcePool']);

    const awsRegionItems = getValues(awsRegions)
        .map(_awsRegion => ({
            name: _awsRegion,
            value: _awsRegion
        }));

    return (
        <ListDropdown id={'awsRegionDropdown'} name={'region'}
            aria-label={t('aria:hiddenAssistText.awsRegion')} noneSelectedMessage={t('resourcePool:option.selectRegion')}
            value={selectedAwsRegion} items={awsRegionItems} onItemSelect={onAwsRegionSelect}
            {...rest}
        />
    );
}

export function AwsIdTypeDropdown(props) {
    const {selectedAwsIdType=awsIdTypeKeys.LAUNCH_TEMPLATE_ID, onAwsIdTypeSelect, ...rest} = props;
    const {t} = useTranslation(['aria', 'resourcePool']);

    const awsIdTypeItems = getValues(awsIdTypeKeys)
        .map(_awsIdType => ({
            name: t(`resourcePool:awsIdType.${_awsIdType}`),
            value: _awsIdType
        }));

    return (
        <ListDropdown id={'awsIdTypeDropdown'} name={'awsIdType'}
            aria-label={t('aria:hiddenAssistText.awsIdType')} value={selectedAwsIdType}
            items={awsIdTypeItems} onItemSelect={onAwsIdTypeSelect}
            {...rest}
        />
    );
}

export function AzureEnvironmentDropdown(props) {
    const {t} = useTranslation(['aria', 'resourcePool']);
    const dispatch = useDispatch();

    const hasLoaded = useSelector(state => state.hasLoaded['AZURE_ENVIRONMENTS']);
    const azureEnvironments = useSelector(state => state.schedulerDetails.configuration.azureEnvironments);
    useAsyncEffect(async () => {
        if (!hasLoaded) {
            const {data} = await SchedulerApi.getAzureEnvironments();
            dispatch(SchedulerModel.actionCreators.updateConfiguration({azureEnvironments: data}));
            dispatch(ReduxStateModel.actionCreators.setHasLoaded('AZURE_ENVIRONMENTS'));
        }
    }, [hasLoaded]);

    const azureEnvironmentItems = azureEnvironments
        .map(environment => ({
            name: environment,
            value: environment
        }));

    return (
        <ListDropdown id={'azureEnvironmentDropdown'} name={'azureEnvironment'} aria-label={t('aria:hiddenAssistText.azureEnvironment')}
            noneSelectedMessage={t('resourcePool:option.selectEnvironment')} items={azureEnvironmentItems} {...props}/>
    )
}

export function AzureRegionDropdown(props) {
    const {t} = useTranslation(['aria', 'resourcePool']);

    const azureRegionItems = getValues(azureRegions)
        .map(region => ({
            name: region,
            value: region
        }));

    return (
        <ListDropdown id={'azureRegionDropdown'} name={'region'} aria-label={t('aria:hiddenAssistText.azureRegion')}
            noneSelectedMessage={t('resourcePool:option.selectRegion')} items={azureRegionItems} {...props}/>
    )
}

export function AzureVmSourceDropdown(props) {
    const {t} = useTranslation(['aria', 'resourcePool']);

    const azureVmSourceItems = getValues(azureVmSources)
        .map(source => ({
            name: t(`resourcePool:azureVmSource.${source}`),
            value: source
        }));

    return (
        <ListDropdown id={'azureVmSourceDropdown'} name={'azureVmSource'} aria-label={t('aria:hiddenAssistText.azureVmSource')}
            noneSelectedMessage={t('resourcePool:option.selectVmSource')} items={azureVmSourceItems} {...props}/>
    )
}

export function AzureVmTypeDropdown(props) {
    const {t} = useTranslation(['aria', 'resourcePool']);

    const azureVmTypeItems = getValues(azureVmTypes)
        .map(type => ({
            name: t(`resourcePool:azureVmType.${type}`),
            value: type
        }));

    return (
        <ListDropdown id={'azureVmTypeDropdown'} name={'vmType'} aria-label={t('aria:hiddenAssistText.azureVmType')}
            noneSelectedMessage={t('resourcePool:option.selectVmType')} items={azureVmTypeItems} {...props}/>
    )
}

export function AzureVmSizeDropdown(props) {
    const {t} = useTranslation(['aria', 'resourcePool']);

    const azureVmSizeItems = getValues(azureVmSizes)
        .map(size => ({
            name: size,
            value: size
        }));

    return (
        <ListDropdown id={'azureVmSizeDropdown'} name={'vmSize'} aria-label={t('aria:hiddenAssistText.azureVmSize')}
            noneSelectedMessage={t('resourcePool:option.selectVmSize')} items={azureVmSizeItems} {...props}/>
    )
}

export function PolicyPrincipalTypeDropdown(props) {
    const {selectedPrincipalType, onPrincipalTypeSelect, ...rest} = props;
    const {t} = useTranslation(['aria', 'policy']);

    const policyPrincipalTypeItems = getValues(policyPrincipalTypeKeys)
        .map(_policyPrincipalType => ({
            name: t(`policy:principalType.${_policyPrincipalType}`),
            value: _policyPrincipalType
        }));

    return (
        <ListDropdown id={'policyPrincipalTypeDropdown'} name={'principalType'}
            aria-label={t('aria:hiddenAssistText.policyPrincipalType')} noneSelectedMessage={t('policy:principalType.SELECT')}
            value={selectedPrincipalType} items={policyPrincipalTypeItems} onItemSelect={onPrincipalTypeSelect}
            {...rest}
        />
    );
}

export function PolicyScopeTypeDropdown(props) {
    const {selectedScopeType, onScopeTypeSelect, ...rest} = props;
    const {t} = useTranslation(['aria', 'policy']);

    const policyScopeTypeItems = getValues(policyScopeTypeOptionKeys)
        .map(_policyScopeType => ({
            name: t(`policy:scopeTypeOption.${_policyScopeType}`),
            value: _policyScopeType
        }));

    return (
        <ListDropdown id={'policyScopeTypeDropdown'} name={'scopeType'}
            aria-label={t('aria:hiddenAssistText.policyScopeType')} noneSelectedMessage={t('policy:scopeTypeOption.SELECT')}
            value={selectedScopeType} items={policyScopeTypeItems} onItemSelect={onScopeTypeSelect}
            {...rest}
        />
    );
}

export function WebhookPlatformDropdown(props) {
    const {selectedWebhookPlatform, onWebhookPlatformSelect, ...rest} = props;
    const {t} = useTranslation(['aria', 'notificationRule']);

    const webhookPlatformItems = getValues(webhookPlatformKeys)
        .map(_webhookPlatform => ({
            name: t(`notificationRule:webhookPlatform.${_webhookPlatform}`),
            value: _webhookPlatform
        }));

    return (
        <ListDropdown id={'webhookPlatformDropdown'} name={'webhookPlatform'}
            aria-label={t('aria:hiddenAssistText.webhookPlatform')} value={selectedWebhookPlatform}
            items={webhookPlatformItems} onItemSelect={onWebhookPlatformSelect}
            {...rest}
        />
    );
}

export function ByteUnitDropdown(props) {
    const {selectedByteUnit, onByteUnitSelect, ...rest} = props;
    const {t} = useTranslation(['aria']);

    const byteUnitItems = ['N/A',...byteUnits]
        .map(_byteUnit => ({
            name: _byteUnit,
            value: _byteUnit
        }));

    return (
        <ListDropdown id={'byteUnitDropdown'} name={'byteUnit'}
            aria-label={t('aria:hiddenAssistText.byteUnit')} value={selectedByteUnit}
            items={byteUnitItems} onItemSelect={onByteUnitSelect}
            {...rest}
        />
    );

}

export function LdapSearchScopeDropdown(props) {
    const {selectedSearchScope, onSearchScopeSelect, ...rest} = props;
    const {t} = useTranslation(['aria', 'userService']);

    const searchScopeItems = getValues(ldapSearchScopeKeys)
        .map(_searchScope => ({
            name: t(`userService:searchScope.${_searchScope}`),
            value: _searchScope
        }));

    return (
        <ListDropdown id={'ldapSearchScopeDropdown'} name={'searchScope'}
            aria-label={t('aria:hiddenAssistText.ldapSearchScope')} value={selectedSearchScope}
            items={searchScopeItems} onItemSelect={onSearchScopeSelect}
            noneSelectedMessage={t('userService:option.selectLdapSearchScope')}{...rest}
        />
    );
}

export function LoginLinkScopeDropdown(props) {
    const {t} = useTranslation(['aria', 'userService']);

    const searchScopeItems = getValues(loginLinkScopeKeys)
        .map(_loginLinkScope => ({
            name: t(`userService:loginLinkScope.${_loginLinkScope}`),
            value: _loginLinkScope
        }));

    return (
        <ListDropdown id={'loginLinkScopeDropdown'} name={'loginLinkScope'}
            aria-label={t('aria:hiddenAssistText.loginLinkScope')} items={searchScopeItems}
            noneSelectedMessage={t('userService:option.selectLoginLinkScope')} {...props}
        />
    )
}

export function RecurringNoticeFrequencyDropdown(props) {
    const {t} = useTranslation(['aria', 'legalHold']);

    const recurringFrequencyItems = getValues(recurringNoticeFrequencyKeys)
        .map(frequency => ({
            name: t(`legalHold:recurringFrequency.${frequency}`),
            value: frequency
        }));

    return (
        <ListDropdown id={'recurringNoticeFrequency'} name={'recurringNoticeFrequency'}
            aria-label={t('aria:hiddenAssistText.recurringNoticeFrequency')} items={recurringFrequencyItems}
            noneSelectedMessage={t('legalHold:option.selectRecurringNoticeFrequency')} {...props}
        />
    );
}

export function WebhookEventTriggerTypeDropdown(props) {
    const {selectedEventTriggerType, onEventTriggerTypeSelect, ...rest} = props;
    const {t} = useTranslation(['aria', 'webhook']);

    const searchScopeItems = getKeys(webhookEventTriggers)
        .map(eventTriggerType => ({
            name: t(`webhook:eventTriggerType.${eventTriggerType}`),
            value: eventTriggerType
        }));

    return (
        <ListDropdown id={'webhookEvenTriggerDropdown'} name={'eventTriggerType'} noneSelectedMessage={t('webhook:option.selectEventTriggerType')}
            aria-label={t('aria:hiddenAssistText.webhookEventTriggerType')} value={selectedEventTriggerType}
            items={searchScopeItems} onItemSelect={onEventTriggerTypeSelect}
            {...rest}
        />
    );
}

export function LibraryFileDropdown(props) {
    const {id, selectedLibraryFile, onLibraryFileSelected, isDisabled, selectedFiles, isNuixFiles, shouldFilter=true, toggleRef, updateSelectableItemCount, ...rest} = props;
    const {t} = useTranslation(['aria', 'fileLibrary']);
    const dispatch = useDispatch();

    const fileLibraryDetailsMap = useSelector(state => state.fileLibraryDetailsMap);
    const hasLoaded = useSelector(state => state.hasLoaded);
    useEffect(() => {
        getValues(fileLibraryDetailsMap).map(library => {
            if (!hasLoaded[library.id]) {
                dispatch(FileLibraryModel.actionCreators.querySettings(library.id));
            }
        })
    }, [dispatch]);

    const libraryFileDetailsMap = useSelector(state => state.libraryFileDetailsMap)

    const onHeaderClick = () => {
        toggleRef.current();
    }

    const sortedFileLibraries = getValues(fileLibraryDetailsMap).sort(nameLocaleCompare);
    const sortedLibraryFiles = getValues(libraryFileDetailsMap).sort(nameLocaleCompare);

    const nuixFileTypes = [libraryFileTypeKeys.CONFIGURATION_PROFILE, libraryFileTypeKeys.METADATA_PROFILE, libraryFileTypeKeys.IMAGING_PROFILE,
        libraryFileTypeKeys.OCR_PROFILE, libraryFileTypeKeys.PROCESSING_PROFILE, libraryFileTypeKeys.PRODUCTION_PROFILE, libraryFileTypeKeys.PLAYBOOK];

    const customFileTypes = [libraryFileTypeKeys.CUSTOM_FILE, libraryFileTypeKeys.PYTHON_SCRIPT, libraryFileTypeKeys.RUBY_SCRIPT,
        libraryFileTypeKeys.JS_SCRIPT, libraryFileTypeKeys.POWERSHELL_SCRIPT];

    const items = [];
    sortedFileLibraries
        .map(fileLibrary => {
            let childrenText = fileLibrary.name;
            const libraryFiles = sortedLibraryFiles
                .filter(libraryFile => fileLibrary.id === libraryFile.fileLibraryId)
                .filter(libraryFile => !shouldFilter || isNuixFiles ? nuixFileTypes.includes(libraryFile.nuixFileType) : customFileTypes.includes(libraryFile.nuixFileType))
                .filter(libraryFile => !selectedFiles.includes(libraryFile.id))
                .map(file => {
                    childrenText += file.name;
                    return {
                        name: file.name,
                        fileName: file.name,
                        value: file.id,
                        fileType: file.nuixFileType,
                        isHeader: false,
                        childrenText: `${file.name} ${fileLibrary.name}`,
                        hoverTitle: file.description
                    }
                });
            const isLibraryVisible = libraryFiles.length > 0;

            if (isLibraryVisible) {
                items.push({name: fileLibrary.name, value: fileLibrary.id, isHeader: true, childrenText: childrenText, onItemClick: onHeaderClick}, ...libraryFiles);
            }
        });

    useEffect(() => {
        updateSelectableItemCount(items.length)
    }, [items])

    return (
        <Dropdown aria-label={t('aria:hiddenAssistText.fileDropdown')} isDisabled={isDisabled}
                  toggleRef={toggleRef} id={id} searchKey={"childrenText"} {...rest}
                  items={items.map((item, index) => (
                      <FileLibrarySelectableItem key={index} item={item} onItemClick={onLibraryFileSelected} isNoWrap isDisabled={isDisabled}/>
                  ))}
        />
    );
}

export function UserServiceDropdown(props) {
    const {t} = useTranslation(['aria', 'userService']);
    const dispatch = useDispatch();
    const {type, filterFunc, enabledCollections, enabledAuthentication, ...rest} = props;

    const hasLoaded = useSelector(state => state.hasLoaded[details.USER_SERVICES]);
    // Query for userServiceDetailsMap if not loaded
    useEffect(() => {
        if (!hasLoaded) {
            dispatch(UserServiceModel.actionCreators.queryDetails());
        }
    }, [hasLoaded, dispatch]);

    const userServiceDetailsMap = useSelector(state => state.userServiceDetailsMap);
    const userServiceItems = getValues(userServiceDetailsMap)
        // .filter(userService => userService.status && userService.status.code !== statusKeys.ERROR)
        .filter(userService => userService.enabled && (!type || userService.type === type)
            && (!enabledCollections || userService.enableCollections) && (!enabledAuthentication || userService.enableAuthentication)
        )
        .filter(userService => typeof filterFunc !== 'function' || filterFunc(userService))
        .map(userService => ({
            name: userService.name,
            value: userService.id
        }));

    return (
        <ListDropdown id={'userServiceDropdown'} name={'userServiceId'}
            aria-label={t('aria:hiddenAssistText.userService')} noneSelectedMessage={t('userService:option.select')}
            items={userServiceItems} buttonStyle={{maxWidth: '100%'}} {...rest}/>
    );
}

export default Dropdown;
