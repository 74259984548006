import React from 'react';
import {connect} from 'react-redux';
import {RestrictedClientPanel} from "../../panels/ClientPanel";
import {RestrictedMatterPanel} from "../../panels/MatterPanel";
import {scheduleTriggerTypeKeys} from "../../../i18next/keys";
import JobScheduleModel from "../../../models/job/JobScheduleModel";

function ScheduleMatterPane(props) {
    const {scheduleTriggerType, clientId, matterId, updateState} = props;

    return (
        <div className="selectPane">
            <RestrictedClientPanel clientId={clientId} updateState={updateState} canAddUnassigned requireSubmitJob
                canSetSameAs={{
                    job: scheduleTriggerType === scheduleTriggerTypeKeys.ON_JOB_EVENT,
                    dataset: scheduleTriggerType === scheduleTriggerTypeKeys.ON_DATASET_EVENT,
                    legalHold: scheduleTriggerType === scheduleTriggerTypeKeys.ON_LEGAL_HOLD_EVENT
                }}
            />

            <RestrictedMatterPanel clientId={clientId} matterId={matterId} updateState={updateState} requireSubmitJob
                canSetSameAs={{
                    job: scheduleTriggerType === scheduleTriggerTypeKeys.ON_JOB_EVENT,
                    dataset: scheduleTriggerType === scheduleTriggerTypeKeys.ON_DATASET_EVENT,
                    legalHold: scheduleTriggerType === scheduleTriggerTypeKeys.ON_LEGAL_HOLD_EVENT
                }}
            />
        </div>
    )
}

const mapStateToProps = state => {
    const {componentStates: {jobScheduleForm: {scheduleTriggerType, clientId, matterId}}} = state;

    return {
        clientId,
        matterId,
        scheduleTriggerType
    };
};

function mapDispatchToProps(dispatch) {
    return {
        updateState: updates => dispatch(JobScheduleModel.componentActionCreators.updateForm(updates)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleMatterPane);
