import React, {useLayoutEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import JobCardDesigner from "./JobCardDesigner/JobCardDesigner";
import ShowDisabledItemsOption from "./options/ShowDisabledItemsOption";
import ExpandableContent from "../common/ExpandableContent/ExpandableContent";
import QueueJobDesigner from "./options/QueueJobDesigner";
import {applicationFeatures, details, userSettings} from "../../utilities/constants";
import {ButtonGroup} from "../common/Button/Button";
import UiLanguageOption from "./options/UiLanguageOption";
import DefaultJobSettings from "./options/DefaultJobSettings";
import UserSettingsModel from "../../models/user/UserSettingsModel";
import JobQueueSort from "./options/JobQueueSort";
import TroubleshootOption from "./options/TroubleshootOption";
import HighlightOption from "./HighlightOption/HighlightOption";
import DatasetOption from "./options/DatasetOption";
import LegalHoldOption from "./options/LegalHoldOption";
import WorkflowOption from "./options/WorkflowOption";
import {statusIcon} from "../../utilities/iconResolver";
import AccessibilityOptions from "./options/AccessibilityOptions";
import JobLaneOption from "./options/JobLaneOption";
import JobPanelOption from "./options/JobPanelOption";
import {MainPageContent, MainPageHeader} from "../app/pages/MainPage";

function UserSettingsDisplay(props) {
    const {t} = useTranslation(['userSettings']);
    const dispatch = useDispatch();

    const {
        ...attr
    } = props;

    const isLoading = useSelector(state => !state.hasLoaded[details.USER_SETTINGS]);
    const statuses = useSelector(state => state.schedulerDetails.resourcesStatus["userSettings"]);

    useLayoutEffect(() => {
        dispatch(UserSettingsModel.componentActionCreators.setDisplayActive())
        return () => dispatch(UserSettingsModel.componentActionCreators.setDisplayInactive());
    }, [dispatch]);


    return (
        <MainPageContent {...attr}
            title={
                <MainPageHeader text={t('userSettings:page.title')}
                    description={t('userSettings:page.description')}/>
            }
            list={
                <LoadingWrapper isLoading={isLoading}>

                    <div className="display-item">
                        <ExpandableUserOption
                            category={userSettings.UI_LANGUAGE}
                            component={UiLanguageOption}
                        />
                    </div>

                    <div className="display-item">
                        <ExpandableUserOption
                            category={userSettings.ACCESSIBILITY}
                            component={AccessibilityOptions}
                        />
                    </div>

                    <div className="display-item">
                        <ExpandableUserOption
                            category={userSettings.SHOW_DISABLED_ITEMS}
                            component={ShowDisabledItemsOption}
                        />
                    </div>

                    <div className="display-item">
                        <ExpandableUserOption
                            category={userSettings.JOB_CARD}
                            component={JobCardDesigner}
                        />
                    </div>

                    <div className="display-item">
                        <ExpandableUserOption
                            category={userSettings.JOB_PANEL}
                            component={JobPanelOption}
                        />
                    </div>

                    <div className="display-item">
                        <ExpandableUserOption
                            category={userSettings.QUEUE_JOB}
                            component={QueueJobDesigner}
                        />
                    </div>

                    <div className="display-item">
                        <ExpandableUserOption
                            category={userSettings.DEFAULT_JOB_SETTINGS}
                            component={DefaultJobSettings}
                        />
                    </div>

                    <div className="display-item">
                        <ExpandableUserOption
                            translationNs={'jobQueueSort'}
                            category={userSettings.JOB_QUEUE_SORT}
                            component={JobQueueSort}
                        />
                    </div>

                    <div className="display-item">
                        <ExpandableUserOption
                            translationNs={'jobLaneOption'}
                            category={userSettings.JOB_LANE}
                            component={JobLaneOption}
                        />
                    </div>

                    <div className="display-item">
                        <ExpandableUserOption
                            translationNs={'workflowOption'}
                            category={userSettings.WORKFLOW_OPTION}
                            component={WorkflowOption}
                            hasWarning={!!statuses && !!statuses[userSettings.WORKFLOW_OPTION]}
                        />
                    </div>

                    <div className="display-item">
                        <ExpandableUserOption
                            translationNs={'datasetOption'}
                            category={userSettings.DATASET_OPTION}
                            component={DatasetOption}
                        />
                    </div>

                    <div className="display-item">
                        <ExpandableUserOption
                            translationNs={'legalHoldOption'}
                            category={userSettings.LEGAL_HOLD}
                            component={LegalHoldOption}
                        />
                    </div>

                    <div className="display-item">
                        <ExpandableUserOption
                            category={userSettings.HIGHLIGHTS}
                            component={HighlightOption}
                        />
                    </div>

                    <div className="display-item">
                        <ExpandableUserOption
                            category={userSettings.TROUBLESHOOT}
                            component={TroubleshootOption}
                        />
                    </div>
                </LoadingWrapper>
            }
        />
    )
}

function ExpandableUserOptionWarningHeader(props) {
    const {label} = props;

    return (
        <label className={"label is-heading"} style={{display: 'flex', alignItems: 'center'}}>
            <span className="icon is-small" style={{marginRight:'0.25rem', flexShrink: 0}}>
                <img src={statusIcon("statusWarning")} alt={t('aria:hiddenAssistText.statusWarningIcon')}/>
            </span>

            {label}
        </label>
    )
}

function ExpandableUserOption(props) {
    const dispatch = useDispatch();
    const {t} = useTranslation(['userSettings', 'uiLanguage', 'accessibility', 'troubleshoot', 'showDisabledItems', 'jobCardDesigner',
        'queueJobDesigner', 'defaultValues', 'highlights']);

    const {category, translationNs, hasWarning=false} = props;
    const userSettingsMap = useSelector(state => state.userSettingsMap);
    const canSetAsDefault = useSelector(state => state.currentUser.features.includes(applicationFeatures.MODIFY_DEFAULT_USER_SETTINGS));


    const buttons = [{
        label: t('userSettings:option.resetToDefault'),
        onClick: function () {
            dispatch(UserSettingsModel.actionCreators.promptResetSettingsCategory(category));
        }
    }];

    if (canSetAsDefault) {
        buttons.unshift({
            label: t('userSettings:option.setAsDefault'),
            onClick: function () {
                dispatch(UserSettingsModel.actionCreators.promptSetSettingsAsCategoryDefault(category));
            }
        })
    }

    const updateCategorySetting = (setting, value) => {
        dispatch(UserSettingsModel.actionCreators.putCategorySetting(category, setting, value));
    }

    let header = null;
    if (hasWarning) {
        header = <ExpandableUserOptionWarningHeader label={t(`${translationNs || category}:title`)}/>
    }

    return (
        <ExpandableContent label={t(`${translationNs || category}:title`)} header={header} initialExpanded={false}>
            <props.component setting={userSettingsMap.get(category)} updateSetting={updateCategorySetting}/>
            <ButtonGroup buttons={buttons}/>
        </ExpandableContent>
    )
}

export default UserSettingsDisplay;