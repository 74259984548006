import Text from "../../common/Text/Text";
import {useTranslation} from "react-i18next";
import {statusIcon} from "../../../utilities/iconResolver";
import React from "react";

function OperationHelpers(props) {
    const {formConfiguration, operationAlias, isDisabled} = props;

    const {operationHelpers} = formConfiguration;

    return (
        <div className="workflow-builder__operation-operation-helpers">
            {operationHelpers.map((operationHelper, index) =>
                <OperationHelperRow key={index} operationAlias={operationAlias} operationHelper={operationHelper} isDisabled={isDisabled}/>
            )}
        </div>
    )
}

function OperationHelperRow (props) {
    const {t} = useTranslation(['workflowBuilder']);
    const {operationAlias, operationHelper, isDisabled} = props;
    const {label, icon} = operationHelper;

    const translatedLabel = t(`workflowBuilder:${operationAlias}.OperationHelpers.${label}`);

    let iconName = "";
    switch (icon) {
        default:
        case "INFO":
            iconName = "statusInfo";
            break;
        case "WARNING":
            iconName = "statusWarning";
            break;
    }

    return (
        <div className="operation-helper-container">
            <span className={"icon is-small"}>
                <img src={statusIcon(iconName)} alt={t(`aria:hiddenAssistText.${iconName}Icon`)}/>
            </span>
            <Text className="translated-label" value={translatedLabel}/>
        </div>
    )
}

export default OperationHelpers;