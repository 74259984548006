import React, {Component, useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import Tablet, {EditTabletHeader} from "../common/Tablet/Tablet";
import {permissionKeys, statusKeys} from "../../i18next/keys";
import {getModifyPrivilegedAction} from "../../utilities/componentFunctions";
import {useTranslation, withTranslation} from "react-i18next";
import ExpandableContent, {ExpandableEditTextArea} from "../common/ExpandableContent/ExpandableContent";
import {DefaultEditPanel} from "../common/EditPanel/EditPanel";
import {shouldEnableExecutionProfile} from "../../utilities/shouldEnableFunctions";
import {onMenuOptionClick} from "../common/Dropdown/helpers";
import {SettingsRowValue} from "../common/CustomTable/CustomTable";
import {
    onInputTableAddInput,
    onInputTableDeleteInput,
    onWorkflowParameterInputTableBlur,
    onWorkflowParameterInputTableChange
} from "../common/InputTable/helpers";
import InputTable from "../common/InputTable/InputTable";
import ParameterList from "../parameters/ParameterList";
import {updateMode, userSettings} from "../../utilities/constants";
import {
    checkNestedExists,
    getFileNameWithoutExtension,
    getKeys,
    getValues,
    objectTruthyValues,
    stringToBool
} from "../../utilities/helperFunctions";
import PopupModel from "../../models/scheduler/PopupModel";
import ExecutionProfileModel from "../../models/settings/ExecutionProfileModel";
import EditModel from "../../models/scheduler/EditModel";
import {makeGetEditDetails} from "../../reselect/selectors";
import ListContainer, {SwitchListContainers} from "../common/ListContainer/ListContainer";
import {onAddItemsToList, onSelectListItem} from "../common/ListContainer/helpers";
import ParameterModel from "../../models/library/ParameterModel";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import {ExpandableStatusLog} from "../common/Common";
import {TimeoutSettings} from "./ExecutionProfileForm";
import {FileParameterTable, ProfileTable} from "../fileLibrary/FileLibraryTables";
import FileLibraryModel from "../../models/filelibrary/FileLibraryModel";
import LibraryFileModel from "../../models/filelibrary/LibraryFileModel";
import Checkbox from "../common/Checkbox/Checkbox";
import {useClearOnFirstCallback} from "../../utilities/formHooks";
import HighlightHTMLTextInput from "../common/HTMLTextInput/HighlightHTMLTextInput";
import TextArea from "../common/TextArea/TextArea";
import LimitedTablet from "../limited/LimitedTablet";

class ExecutionProfileTablet extends Component {

    constructor(props) {
        super(props);

        this.passwordRef = React.createRef();

        this.onInputChange = this.onInputChange.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.onTimeoutSettingToggle = this.onTimeoutSettingToggle.bind(this);
        this.onTimeoutSettingInputChange = this.onTimeoutSettingInputChange.bind(this);

        this.updateEdit = this.updateEdit.bind(this);
        this.editExecutionProfile = this.editExecutionProfile.bind(this);
        this.deleteExecutionProfile = this.deleteExecutionProfile.bind(this);
        this.duplicateExecutionProfile = this.duplicateExecutionProfile.bind(this);
        this.promptPropagateProfile = this.promptPropagateProfile.bind(this);
        this.promptDeletePropagatedProfiles = this.promptDeletePropagatedProfiles.bind(this);

        this.getModifyPrivilegedAction = getModifyPrivilegedAction('executionProfile', this.props.t('executionProfile:label.name')).bind(this);
        this.onMenuOptionClick = onMenuOptionClick.bind(this);

        this.onEditAddWorkflowParameter = onInputTableAddInput([{value: ''}, {value: ''}], {
            mode: updateMode.EDIT,
            listName: 'workflowParameters'
        }).bind(this);
        this.onEditDeleteWorkflowParameter = onInputTableDeleteInput({
            mode: updateMode.EDIT,
            listName: 'workflowParameters',
            shouldEnable: shouldEnableExecutionProfile,
            passwordRef: this.passwordRef,
            passwordName: 'engineAccountPassword'
        }).bind(this);
        this.onEditWorkflowParameterChange = onWorkflowParameterInputTableChange({
            mode: updateMode.EDIT,
            listName: 'workflowParameters',
            shouldEnable: shouldEnableExecutionProfile,
            passwordRef: this.passwordRef,
            passwordName: 'engineAccountPassword'
        }).bind(this);
        this.onEditWorkflowParameterBlur = onWorkflowParameterInputTableBlur({
            mode: updateMode.EDIT,
            listName: 'workflowParameters',
            shouldEnable: shouldEnableExecutionProfile,
            passwordRef: this.passwordRef,
            passwordName: 'engineAccountPassword'
        }).bind(this);

        this.onEditAddNotificationRules = onAddItemsToList({
            mode: updateMode.EDIT,
            listTo: 'selectedNotificationRules',
            listFrom: 'availableNotificationRules',
            shouldEnable: shouldEnableExecutionProfile,
            passwordRef: this.passwordRef,
            passwordName: 'engineAccountPassword'
        }).bind(this);
        this.onEditRemoveNotificationRules = onAddItemsToList({
            mode: updateMode.EDIT,
            listTo: 'availableNotificationRules',
            listFrom: 'selectedNotificationRules',
            shouldEnable: shouldEnableExecutionProfile,
            passwordRef: this.passwordRef,
            passwordName: 'engineAccountPassword'
        }).bind(this);
        this.onEditSelectAvailableNotificationRules = onSelectListItem({
            mode: updateMode.EDIT,
            listName: 'availableNotificationRules'
        }).bind(this);
        this.onEditSelectSelectedNotificationRules = onSelectListItem({
            mode: updateMode.EDIT,
            listName: 'selectedNotificationRules'
        }).bind(this);
    }

    onInputChange(event) {
        const {name, value, type} = event.target;

        const updates = {};
        // Using same inputHandler for password field for isAddEnabled logic
        // Do not track password value
        if (type !== 'password')
            updates[name] = value;

        // Clear password when user changes value for below
        if (['engineAccountUsername'].includes(name)) {
            this.passwordRef.current.value = '';
        }

        this.updateEdit(updates);
    }

    onToggle(event) {
        const {name, checked} = event.currentTarget;

        const updates = {
            [name]: stringToBool(checked)
        };

        this.updateEdit(updates);
    }

    onTimeoutSettingToggle(event) {
        const {name, checked} = event.currentTarget.dataset;

        const updates = {
            timeoutSettingEnabled: {
                ...this.props.timeoutSettingEnabled,
                [name]: stringToBool(checked)
            }
        };

        this.updateEdit(updates);
    }

    onTimeoutSettingInputChange(event) {
        const {name, value} = event.target;

        const updates = {
            jobTimeoutSettings: {
                ...this.props.jobTimeoutSettings,
                [name]: value
            }
        };

        this.updateEdit(updates);
    }

    updateEdit(updates) {
        const isSaveEnabled = shouldEnableExecutionProfile({
            ...this.props,
            ...updates,
            engineAccountPassword: this.passwordRef.current && this.passwordRef.current.value
        });

        this.props.setEditSaveEnabled(isSaveEnabled);
        this.props.updateEdit(updates);
    }


    editExecutionProfile() {
        this.props.editExecutionProfile(this.props.executionProfileId);
    }

    deleteExecutionProfile() {
        this.props.promptDeleteExecutionProfile(this.props.executionProfileId);
    }

    duplicateExecutionProfile() {
        this.props.duplicateExecutionProfile(this.props.executionProfileId);
    }

    promptPropagateProfile () {
        this.props.promptPropagateProfile(this.props.executionProfileId);
    }

    promptDeletePropagatedProfiles() {
        this.props.promptDeletePropagatedProfiles(this.props.executionProfileId);
    }

    render() {

        const {
            t,
            executionProfileName,
            description,
            useServiceAccount,
            engineAccountUsername,
            engineAccountPassword,
            workflowParameters,
            nuixProfiles,
            additionalFiles,
            engineCommandLineParameters,
            engineLogFolder,
            nuixEngineFolder,
            javaFolder,
            schedulerUrl,
            availableNotificationRules,
            selectedNotificationRules,
            timeoutSettingEnabled,
            jobTimeoutSettings,
            userPermissions,
            status,
            isEditActive,
            isSaveEnabled,
            isDisabled,
            showError,

            fileLibraryIds,

            executionProfileId
        } = this.props;

        const menuOptions = [
            {name: t('common:option.edit'), value: 'editExecutionProfile', isDisabled: isDisabled},
            {name: t('common:option.duplicate'), value: 'duplicateExecutionProfile', isDisabled: isDisabled},
            {name: t('common:option.delete'), value: 'deleteExecutionProfile', isDisabled: isDisabled},
            {isSeparator: true},
            {name: t('executionProfile:label.duplicateNuixVersions'), value: 'promptPropagateProfile', isDisabled: isDisabled},
            {name: t('executionProfile:label.deleteDuplicateVersions'), value: 'promptDeletePropagatedProfiles', isDisabled: isDisabled}
        ];

        const descriptionProps = {
            label: t('common:label.description'),
            name: 'description',
            value: description,
            onChange: this.getModifyPrivilegedAction(this.onInputChange),
            isEditActive,
            isDisabled
        };
        const settingsProps = {
            passwordRef: this.passwordRef,
            label: t('executionProfile:label.engineSettings'),
            useServiceAccount,
            engineAccountUsername,
            engineAccountPassword,
            engineCommandLineParameters,
            engineLogFolder,
            nuixEngineFolder,
            javaFolder,
            schedulerUrl,
            isEditActive,
            onInputChange: this.getModifyPrivilegedAction(this.onInputChange),
            onToggle: this.onToggle,
            isDisabled
        };

        const workflowParametersTableProps = {
            id: 'workflowParametersInputTable',
            label: t('executionProfile:label.workflowParameters'),
            headers: [{title: t('workflow:label.parameterName')}, {title: t('common:label.value')}],
            workflowParameters,
            onValueChange: this.getModifyPrivilegedAction(this.onEditWorkflowParameterChange),
            onValueBlur: this.getModifyPrivilegedAction(this.onEditWorkflowParameterBlur),
            onAddClick: this.getModifyPrivilegedAction(this.onEditAddWorkflowParameter),
            onDeleteClick: this.getModifyPrivilegedAction(this.onEditDeleteWorkflowParameter),
            isEditActive,
            isDisabled
        };

        const nuixProfileInputTableProps = {
            nuixProfiles,
            isEditActive,
            updateEdit: this.updateEdit,
            showError,
            isDisabled,
        }

        const additionalFilesTableProps = {
            additionalFiles,
            isEditActive,
            updateEdit: this.updateEdit,
            showError,
            isDisabled,
        }

        const notificationRulesInputListProps = {
            availableNotificationRules,
            selectedNotificationRules,
            ariaLabelKey: 'Rule',
            notificationRuleNavigateButtonProps: {
                onClick: [
                    this.onEditAddNotificationRules,
                    this.onEditRemoveNotificationRules
                ],
                length: [
                    checkNestedExists(availableNotificationRules, 'selectedItems') && objectTruthyValues(availableNotificationRules.selectedItems).length,
                    checkNestedExists(selectedNotificationRules, 'selectedItems') && objectTruthyValues(selectedNotificationRules.selectedItems).length,
                ]
            },
            onSelectAvailableNotificationRules: this.onEditSelectAvailableNotificationRules,
            onSelectSelectedNotificationRules: this.onEditSelectSelectedNotificationRules,
            isEditActive,
            isDisabled
        };

        const timeoutSettingsProps = {
            isEditActive,
            timeoutSettingEnabled,
            jobTimeoutSettings,
            onTimeoutSettingToggle: this.getModifyPrivilegedAction(this.onTimeoutSettingToggle),
            onTimeoutSettingInputChange: this.getModifyPrivilegedAction(this.onTimeoutSettingInputChange),
            isDisabled
        }

        const canModifyExecutionProfile = userPermissions.includes(permissionKeys.MODIFY);

        const isDescriptionVisible = isEditActive || description;
        const isWorkflowParametersVisible = isEditActive || getKeys(workflowParameters).length > 0;
        const isNuixProfilesVisible = isEditActive || getKeys(nuixProfiles).length > 0;
        const isAdditionalFilesVisible = isEditActive || getKeys(additionalFiles).length > 0;
        const isNotificationRulesVisible = isEditActive || getKeys(selectedNotificationRules).length > 0
        const isJobTimeoutSettingsVisible = isEditActive
            || (jobTimeoutSettings && (jobTimeoutSettings.jobProgressMinPercentage != null
                || jobTimeoutSettings.operationProgressMinPercentage != null
                || jobTimeoutSettings.skipOperationProgressMinPercentage != null
            ));

        const fileSettingsTableProps = {
            nuixProfileInputTableProps,
            additionalFilesTableProps,
            isNuixProfilesVisible,
            isAdditionalFilesVisible,
            fileLibraryIds,
            queryFileLibrarySettings: this.props.queryFileLibraryDetails
        }

        const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);
        if (isViewLimited && !canModifyExecutionProfile) {
            return (
                <LimitedTablet id={executionProfileId} type={'executionProfile'} name={executionProfileName} label={t(`executionProfile:label.name`)}
                               status={status} enabled={true} canModify={canModifyExecutionProfile} isDisabled={isDisabled} description={description}
                               onClose={this.props.close}
                />
            )
        }

        return (
            <Tablet width={'70rem'} height={isEditActive ? 'auto' : '50vh'} onClose={this.props.close} isDisabled={isDisabled}
                closeButtonAriaLabel={t('executionProfile:option.closeTablet_name', {name: executionProfileName})}
                header={
                    <EditTabletHeader label={t('executionProfile:label.name')} type={'executionProfile'}
                        id={executionProfileId} name={'executionProfileName'} value={executionProfileName} inputHandler={this.onInputChange}
                        menuOptions={menuOptions} menuOptionHandler={this.onMenuOptionClick}
                        canModify={canModifyExecutionProfile} isEditActive={isEditActive}
                        isDisabled={isDisabled}
                    />
                }
                body={
                    <>
                        {status.code === statusKeys.ERROR && !isEditActive &&
                        <div className="display-item">
                            <ExpandableStatusLog {...status} isDisabled={isDisabled}/>
                        </div>
                        }

                        <div className="display-item">
                            <DefaultEditPanel isActive={isEditActive} isSaveEnabled={isSaveEnabled}
                                onSave={this.props.saveEdit} onCancel={this.props.cancelEdit}>
                                {isDescriptionVisible && <ExpandableEditTextArea {...descriptionProps} />}
                                <ExpandableSettings {...settingsProps}/>
                                {isWorkflowParametersVisible && <ExpandableWorkflowParameters {...workflowParametersTableProps}/>}
                                {isNotificationRulesVisible && <ExpandableNotificationRules {...notificationRulesInputListProps}/>}
                                {isJobTimeoutSettingsVisible && <ExpandableTimeoutSettings {...timeoutSettingsProps}/>}
                                <FileSettings {...fileSettingsTableProps}/>
                            </DefaultEditPanel>
                        </div>
                    </>
                }
            />
        )
    }
}

const ExpandableSettings = props => {
    const {label, isEditActive, isDisabled, ...settingsProps} = props;
    const {passwordRef, useServiceAccount, engineAccountUsername, engineAccountPassword, engineCommandLineParameters, engineLogFolder, nuixEngineFolder, javaFolder, schedulerUrl, onInputChange, onToggle} = settingsProps;

    return (
        <ExpandableContent label={label} isDisabled={isDisabled}>
            {isEditActive ?
                <EditExecutionProfileSettings {...{
                    passwordRef,
                    useServiceAccount,
                    engineAccountUsername,
                    engineAccountPassword,
                    engineCommandLineParameters,
                    engineLogFolder,
                    nuixEngineFolder,
                    javaFolder,
                    schedulerUrl,
                    onInputChange,
                    onToggle
                }} />
                :
                <ExecutionProfileSettings {...{engineAccountUsername, engineCommandLineParameters, engineLogFolder, nuixEngineFolder, javaFolder, schedulerUrl, isDisabled}} />
            }
        </ExpandableContent>
    )
};

const ExecutionProfileSettings = props => {
    const {engineAccountUsername, engineCommandLineParameters, engineLogFolder, nuixEngineFolder, javaFolder, schedulerUrl, isDisabled} = props;
    const {t} = useTranslation(['executionProfile', 'common']);

    const disabled = isDisabled ? ' is-disabled' : ''
    return (
        <div className={`settings-table` + disabled}>
            <div className="table-row-group">
                {SettingsRowValue({label: t('executionProfile:label.engineLogFolder'), value: engineLogFolder, enableHighlightText: true})}
                {engineAccountUsername && SettingsRowValue({
                    label: t('common:label.username'),
                    value: engineAccountUsername
                })}
                {engineCommandLineParameters && SettingsRowValue({
                    label: t('executionProfile:label.commandLineParameters'),
                    value: engineCommandLineParameters,
                    enableHighlightText: true
                })}
                {nuixEngineFolder && SettingsRowValue({label: t('executionProfile:label.nuixEngineFolder'), value: nuixEngineFolder, enableHighlightText: true})}
                {javaFolder && SettingsRowValue({label: t('executionProfile:label.javaFolder'), value: javaFolder})}
                {schedulerUrl && SettingsRowValue({label: t('executionProfile:label.schedulerUrl'), value: schedulerUrl})}
            </div>
        </div>
    )
};

const EditExecutionProfileSettings = props => {
    const {passwordRef, useServiceAccount, engineAccountUsername, engineAccountPassword, engineCommandLineParameters,
        engineLogFolder, nuixEngineFolder, javaFolder, schedulerUrl, onInputChange, onToggle, isDisabled} = props;
    const {t} = useTranslation(['executionProfile', 'common']);

    const clearOnFirstCallback = useClearOnFirstCallback();
    return (
        <>
            <div className="display-input">
                <HighlightHTMLTextInput {...{
                    id: 'engineLogFolder',
                    label: t('executionProfile:label.engineLogFolder'),
                    name: 'engineLogFolder',
                    value: engineLogFolder,
                    onChange: onInputChange,
                    isRequired: true
                }}/>
            </div>

            <div className="display-input">
                <Checkbox label={t('executionProfile:label.useServiceAccount')} name={'useServiceAccount'} checked={useServiceAccount}
                    onClick={onToggle} isDisabled={isDisabled}/>
            </div>
            {useServiceAccount &&
                <>
                    <div className="display-input">
                        <HTMLTextInput {...{
                            label: t('common:label.username'),
                            name: 'engineAccountUsername',
                            value: engineAccountUsername,
                            onChange: onInputChange
                        }}/>
                    </div>
                    <div className="display-input">
                        <HTMLTextInput inputRef={passwordRef} label={t('common:label.password')} name={'engineAccountPassword'} type={'password'}
                            defaultValue={engineAccountPassword} onChange={onInputChange} onBeforeInput={clearOnFirstCallback}
                            isRequired={engineAccountUsername.length > 0}/>
                    </div>
                </>
            }

            <div className="display-input">
                <TextArea {...{
                    id: 'engineCommandLineParameters',
                    label: t('executionProfile:label.commandLineParameters'),
                    name: 'engineCommandLineParameters',
                    value: engineCommandLineParameters,
                    onChange: onInputChange
                }}/>
            </div>
            <div className="display-input">
                <HighlightHTMLTextInput {...{
                    id: 'nuixEngineFolder',
                    label: t('executionProfile:label.nuixEngineFolder'),
                    name: 'nuixEngineFolder',
                    value: nuixEngineFolder,
                    onChange: onInputChange
                }}/>
            </div>
            <div className="display-input">
                <HTMLTextInput {...{
                    label: t('executionProfile:label.javaFolder'),
                    name: 'javaFolder',
                    value: javaFolder,
                    onChange: onInputChange
                }}/>
            </div>
            <div className="display-input">
                <HTMLTextInput {...{
                    label: t('executionProfile:label.schedulerUrl'),
                    name: 'schedulerUrl',
                    value: schedulerUrl,
                    onChange: onInputChange
                }}/>
            </div>
        </>
    )
};

function ExpandableWorkflowParameters(props) {
    const {id, label, workflowParameters, isEditActive, isDisabled, ...inputTableProps} = props;

    let body;
    if (isEditActive) {
        body = <InputTable ariaLabelKey={'Parameter'} {...{id, rows: workflowParameters, ...inputTableProps, isDisabled}} />
    } else {
        const parameters = ParameterModel.buildParametersMap(workflowParameters);

        body =
            <div className="display-input">
                <ParameterList parameters={parameters}/>
            </div>
    }

    return (
        <ExpandableContent label={label} isDisabled={isDisabled}>
            {body}
        </ExpandableContent>
    )
}

function FileSettings (props) {
    const {nuixProfileInputTableProps, additionalFilesTableProps, isNuixProfilesVisible, isAdditionalFilesVisible, fileLibraryIds, queryFileLibrarySettings} = props;

    const hasLoaded = useSelector(state => state.hasLoaded);
    useEffect(() => {
        fileLibraryIds.map(libraryId => {
            if (!hasLoaded[libraryId]) {
                queryFileLibrarySettings(libraryId);
            }
        });
    }, [])

    const isFileTablesVisible = fileLibraryIds.every(id => hasLoaded[id]);

    return (isFileTablesVisible &&
        <>
            {isNuixProfilesVisible && <ExpandableProfileParameters {...nuixProfileInputTableProps}/>}
            {isAdditionalFilesVisible && <ExpandableAdditionalFiles {...additionalFilesTableProps}/>}
        </>
    )
}

function ExpandableProfileParameters(props) {
    const {nuixProfiles, isEditActive, updateEdit, showError, isDisabled} = props;
    const {t} = useTranslation(['executionProfile', 'popupInfo']);

    const libraryFileDetailsMap = useSelector(state => state.libraryFileDetailsMap);

    const fileIds = nuixProfiles.map(file => file.fileId);
    const types = [];
    getValues(libraryFileDetailsMap)
        .filter(profile => getValues(fileIds).includes(profile.id))
        .map(profile => {
            const type = LibraryFileModel.libraryFileTypeToString(profile.nuixFileType);

            if (!types.includes(type)) {
                types.push(type)
            }

            return type;
        });

    const profiles = getValues(libraryFileDetailsMap)
        .filter(profile => getValues(fileIds).includes(profile.id))
        .map(profile => {
            const type = LibraryFileModel.libraryFileTypeToString(profile.nuixFileType);
            return ({type: type, name: profile.name})
        });

    const profileTableProps = {
        profiles: nuixProfiles,
        stateName: "nuixProfiles",
        onUpdate: updateEdit,
        showError: showError,
        isDisabled
    }

    let body;
    const disabled = isDisabled ? ' is-disabled' : '';
    if (isEditActive) {
        body = <ProfileTable {...profileTableProps}/>
    } else {
        body = (
            <>
                {types.map((profileType, index) => (
                    <div key={`${profileType}_${index}`} className={"display-input"}>
                        <ExpandableContent label={t(`executionProfile:label.${profileType}Header`)} isDisabled={isDisabled}>
                            {profiles.filter(profile => profile.type === profileType).map(profile => {
                                return <label className={"label"+disabled} key={`${profileType}${profile.name}`}>{getFileNameWithoutExtension(profile.name)}</label>
                            })}
                        </ExpandableContent>
                    </div>
                ))}
            </>
        )
    }

    return (
        isEditActive ?
            <ExpandableContent label={t('executionProfile:label.nuixProfilesHeader')}>
                {body}
            </ExpandableContent>
            :
            body
    )
}

function ExpandableAdditionalFiles(props) {
    const {additionalFiles, isEditActive, updateEdit, showError, isDisabled} = props;
    const {t} = useTranslation(['executionProfile', 'popupInfo']);

    const libraryFileDetailsMap = useSelector(state => state.libraryFileDetailsMap);

    const fileIds = additionalFiles.map(file => file.fileId);
    const libraryFileAdditionFiles = getValues(libraryFileDetailsMap)
        .filter(file => fileIds.includes(file.id))
        .map(file => {
            const parameter = additionalFiles.filter(otherFile => otherFile.fileId === file.id)[0];
            return ({parameter: parameter.parameterName, fileName: file.name})
        })

    const profileTableProps = {
        files: additionalFiles,
        stateName: "additionalFiles",
        onUpdate: updateEdit,
        showError: showError,
        isDisabled
    }

    let body;
    const disabled = isDisabled ? ' is-disabled' : '';
    if (isEditActive) {
        body = <FileParameterTable {...profileTableProps}/>
    } else {

        body = (libraryFileAdditionFiles.length > 0 &&

            <ExpandableContent label={t('executionProfile:label.additionalFilesHeader')} isDisabled={isDisabled}>
                <div className={"settings-table"+disabled}>
                    {libraryFileAdditionFiles.map((file, index) => {
                        return (
                            <div key={`file_parameter_${index}`} className={"table-row-group"}>
                                {SettingsRowValue({
                                    label: file.parameter,
                                    value: file.fileName,
                                })}
                            </div>
                        )
                    })}
                </div>
            </ExpandableContent>
        )
    }

    return (
        isEditActive ?
            <ExpandableContent label={t('executionProfile:label.additionalFilesHeader')}>
                {body}
            </ExpandableContent>
            :
            body
    );
}

function ExpandableNotificationRules(props) {
    const {isEditActive, availableNotificationRules, selectedNotificationRules} = props;
    const {notificationRuleNavigateButtonProps, onSelectAvailableNotificationRules, onSelectSelectedNotificationRules} = props;
    const {t} = useTranslation(['executionProfile']);

    return (
        <ExpandableContent label={t('executionProfile:label.notificationRules')}>
            {isEditActive ?
                <SwitchListContainers id="notificationRuleSwitchContainer"
                    ariaLabelKey={'Rule'}
                    navigateButtonsProps={notificationRuleNavigateButtonProps}
                    leftContainer={
                        <ListContainer {...availableNotificationRules} onItemClick={onSelectAvailableNotificationRules}/>
                    }
                    rightContainer={
                        <ListContainer {...selectedNotificationRules} onItemClick={onSelectSelectedNotificationRules}/>
                    }
                />
                :
                selectedNotificationRules.map(name =>
                    <label className="label" key={name}>{name}</label>
                )
            }
        </ExpandableContent>
    )
}

function ExpandableTimeoutSettings(props) {
    const {t} = useTranslation(['executionProfile']);
    const {isEditActive, timeoutSettingEnabled, jobTimeoutSettings, onTimeoutSettingToggle, onTimeoutSettingInputChange, isDisabled} = props;

    const disabled = isDisabled ? ' is-disabled' : '';
    return (
        <ExpandableContent label={t('executionProfile:label.timeoutSettings')} isDisabled={isDisabled}>
            {isEditActive ?
                <TimeoutSettings
                    timeoutSettingEnabled={timeoutSettingEnabled}
                    jobTimeoutSettings={jobTimeoutSettings}
                    onTimeoutSettingToggle={onTimeoutSettingToggle}
                    onTimeoutSettingChange={onTimeoutSettingInputChange}
                    isDisabled={isDisabled}
                />
                :
                <>
                    {jobTimeoutSettings.jobProgressMinPercentage &&
                    <label className={'label' + disabled}>
                        {t('executionProfile:message.timeoutSettings_job', {percentage: jobTimeoutSettings.jobProgressMinPercentage, hours: jobTimeoutSettings.jobProgressTimeoutHours})}
                    </label>
                    }

                    {jobTimeoutSettings.operationProgressMinPercentage &&
                    <label className={'label' + disabled}>
                        {t('executionProfile:message.timeoutSettings_operation', {percentage: jobTimeoutSettings.operationProgressMinPercentage, hours: jobTimeoutSettings.operationProgressTimeoutHours})}
                    </label>
                    }

                    {jobTimeoutSettings.skipOperationProgressMinPercentage &&
                    <label className={'label' + disabled}>
                        {t('executionProfile:message.timeoutSettings_skipOperation', {percentage: jobTimeoutSettings.skipOperationProgressMinPercentage, hours: jobTimeoutSettings.skipOperationProgressTimeoutHours})}
                    </label>
                    }
                </>
            }
        </ExpandableContent>
    )
}

const makeMapStateToProps = () => {
    const getEditDetails = makeGetEditDetails();
    return state => {
        const {executionProfileDetailsMap, notificationRuleDetailsMap, fileLibraryDetailsMap, componentStates: {executionProfileDisplay: {executionProfileId, isDisabled}}} = state;
        const {name: executionProfileName, notificationRuleIds, ...otherDetails} = executionProfileDetailsMap.get(executionProfileId);

        const {activeModel, values, isSaveEnabled} = getEditDetails(state, {model: ExecutionProfileModel.nom});
        const isEditActive = (activeModel === ExecutionProfileModel.nom);

        const selectedNotificationRules = (notificationRuleIds || []).map(id => {
            const notificationRuleDetails = notificationRuleDetailsMap.get(id);

            if (notificationRuleDetails != null) {
                return notificationRuleDetails.name;
            }
            return id;
        });

        const fileLibraryIds = getValues(fileLibraryDetailsMap).map(_library => _library.id);

        return {
            executionProfileId,
            executionProfileName,
            selectedNotificationRules,
            fileLibraryIds,
            ...otherDetails,
            ...values,
            isEditActive,
            isSaveEnabled,
            isDisabled,

            showObjectIds: state.userSettingsMap.get(userSettings.TROUBLESHOOT).showObjectIds
        };
    }
};

const mapDispatchToProps = dispatch => {
    return {
        editExecutionProfile: executionProfileId => dispatch(ExecutionProfileModel.actionCreators.startEdit(executionProfileId)),
        promptDeleteExecutionProfile: executionProfileId => dispatch(ExecutionProfileModel.actionCreators.promptDelete(executionProfileId)),
        duplicateExecutionProfile: executionProfileId => dispatch(ExecutionProfileModel.actionCreators.duplicateDetails(executionProfileId)),

        promptPropagateProfile: executionProfileId => dispatch(ExecutionProfileModel.actionCreators.promptPropagateProfile(executionProfileId)),
        promptDeletePropagatedProfiles: executionProfileId => dispatch(ExecutionProfileModel.actionCreators.promptDeletePropagatedProfiles(executionProfileId)),

        queryFileLibraryDetails: fileLibraryId => dispatch(FileLibraryModel.actionCreators.querySettings(fileLibraryId)),

        ...EditModel.buildDispatchers(dispatch),

        showError: payload => dispatch(PopupModel.actionCreators.showError(payload)),
        close: () => dispatch(ExecutionProfileModel.actionCreators.hideTablet())
    };
};

export default connect(makeMapStateToProps, mapDispatchToProps)(withTranslation(['executionProfile', 'workflow', 'common'])(ExecutionProfileTablet));