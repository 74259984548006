import ComponentStateModel from "../generics/ComponentStateModel";
import {actionCreator} from "../../utilities/helperFunctions";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import {all, put, takeLeading} from "redux-saga/effects";
import {contextCall, contextPollUntil, contextSaga, isDisabledWrapper} from "../../saga/sagaFunctions";
import {details} from "../../utilities/constants";
import {SagaRunnable} from "../generics/SagaRunnable";
import ReduxStateModel from "./ReduxStateModel";
import {tryCatchWrapper} from "../../saga/tryCatchWrapper";
import PopupModel from "./PopupModel";
import {popupInfoKeys} from "../../i18next/keys";

class UtilizationModel {

    static nom = 'UtilizationModel';
    static actions = UtilizationModel.buildActions();
    static actionCreators = UtilizationModel.buildActionCreators(UtilizationModel.actions);
    static reducer = UtilizationModel.buildReducer(UtilizationModel.actions);

    static componentActionCreators = {
        ...UtilizationModel.buildComponentUpdateActionCreators(),
        ...UtilizationModel.buildComponentSetActiveActionCreators()
    };

    constructor(model) {
        this.sessionsCount = model.sessionsCount;
        this.statusMessage = model.statusMessage;
    }

    static buildActions() {
        return {
            // DETAILS ACTIONS
            SET_UTILIZATION_STATISTICS: `SET_UTILIZATION_STATISTICS`,
            // DETAILS POLLING ACTIONS
            START_POLLING_DETAILS: `START_POLLING_UTILIZATION_DETAILS`,
            STOP_POLLING_DETAILS: `STOP_POLLING_UTILIZATION_DETAILS`,

            SUBMIT_FILE: `SUBMIT_UTILIZATION_FILE`,
            SUBMIT_INFERRED_FILE: `SUBMIT_INFERRED_UTILIZATION_FILE`,
        };
    }

    static buildActionCreators(actions) {
        return {
            // DETAIL POLLING ACTION CREATORS
            startPollingDetails: actionCreator(actions.START_POLLING_DETAILS, 'period'),
            stopPollingDetails: actionCreator(actions.STOP_POLLING_DETAILS),

            submitFile: actionCreator(actions.SUBMIT_FILE, 'contents'),
            submitInferredFile: actionCreator(actions.SUBMIT_INFERRED_FILE, 'contents'),
            setUtilizationStatistics: actionCreator(actions.SET_UTILIZATION_STATISTICS, 'statistics')
        };
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'utilizationDisplay',
                type: 'Display',
                state: {
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'UTILIZATION_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }

    static buildReducer(actions) {
        return function (state = {}, action) {
            switch (action.type) {
                case actions.SET_UTILIZATION_STATISTICS: {
                    const {statistics} = action.payload;
                    return new this(statistics);
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }
}

export class UtilizationApi {

    static getStatistics(){
        return axiosInstance.get('/scheduler/resources/utilization/statistics');
    }

    static setFile(contents){
        return axiosInstance.put('/scheduler/resources/utilization/file', {contents});
    }

    static setInferredFile(contents){
        return axiosInstance.put('/scheduler/resources/utilization/inferred', {contents});
    }
}

export class UtilizationSaga extends SagaRunnable {

    static activationComponent = 'UTILIZATION_DISPLAY';

    static buildActivationEffects(dispatch) {
        return [
            ...super.buildActivationEffects(dispatch),
            // ACTIVATION EFFECTS
            put(UtilizationModel.actionCreators.startPollingDetails()),
            takeLeading(UtilizationModel.actions.SUBMIT_FILE, tryCatchWrapper, isDisabledWrapper, UtilizationModel.componentActionCreators.updateDisplay, contextSaga(this, 'submitFile')),
            takeLeading(UtilizationModel.actions.SUBMIT_INFERRED_FILE, tryCatchWrapper, isDisabledWrapper, UtilizationModel.componentActionCreators.updateDisplay, contextSaga(this, 'submitInferredFile')),
        ]
    }

    static buildDeactivationEffects() {
        return [
            ...super.buildDeactivationEffects(),
            // DEACTIVATION EFFECTS
            put(UtilizationModel.actionCreators.stopPollingDetails())
        ]
    }

    static* submitFile(action) {
        const {contents} = action.payload;
        const {data} = yield contextCall(UtilizationApi, 'setFile', contents);

        yield all([
            put(UtilizationModel.componentActionCreators.updateDisplay({isFormActive: false})),
            put(PopupModel.actionCreators.showSuccess({
                info: {
                    key: popupInfoKeys.UPLOAD_UTILIZATION_SUCCESS,
                    values: data
                }
            }))
        ]);
    }

    static* submitInferredFile(action) {
        const {contents} = action.payload;
        const {data} = yield contextCall(UtilizationApi, 'setInferredFile', contents);

        yield all([
            put(UtilizationModel.componentActionCreators.updateDisplay({isFormActive: false})),
            put(PopupModel.actionCreators.showSuccess({
                info: {
                    key: popupInfoKeys.UPLOAD_UTILIZATION_SUCCESS,
                    values: data
                }
            }))
        ]);
    }

    static* pollDetails(action) {
        const {period} = action.payload;

        yield contextPollUntil(UtilizationModel.actions.STOP_POLLING_DETAILS, period, this, 'queryDetails');
    }

    static* queryDetails() {
        const response = yield contextCall(UtilizationApi, 'getStatistics');
        const key = details.UTILIZATION_STATISTICS;

        if (AxiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(UtilizationModel.actionCreators.setUtilizationStatistics(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }
}

export default UtilizationModel;
