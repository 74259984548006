import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {getEngineIds} from '../../reselect/selectors';
import EngineForm from './EngineForm';
import EngineTable from './EngineTable';
import EngineTablet from './EngineTablet';
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {details} from "../../utilities/constants";
import EngineModel from "../../models/settings/EngineModel";
import {MainPageContent, MainPageHeader} from "../app/pages/MainPage";

class EngineDisplay extends Component {

    componentDidMount() {
        this.props.setDisplayActive();
    }

    componentWillUnmount() {
        this.props.setDisplayInactive();
    }

    render() {

        const {
            t,
            isLoading,
            engineId,
            engineIds,
            isEngineFormActive,
            ...attr
        } = this.props;

        return (
            <MainPageContent {...attr}
                title={
                    <MainPageHeader text={t('engine:page.title')}
                        description={t('engine:page.description')}/>
                }
                list={
                    <LoadingWrapper isLoading={isLoading}>
                        <EngineTable />
                    </LoadingWrapper>
                }
                children={
                    (engineIds.includes(engineId) && <EngineTablet />)
                    ||
                    (isEngineFormActive  && <EngineForm />)
                }
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.componentStates.engineDisplay,
        engineIds: getEngineIds(state),
        isLoading: !state.hasLoaded[details.ENGINES]
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setDisplayActive: () => dispatch(EngineModel.componentActionCreators.setDisplayActive()),
        setDisplayInactive: () => dispatch(EngineModel.componentActionCreators.setDisplayInactive())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['engine'])(EngineDisplay));