import React, {Component} from "react";
import {connect} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {onDropdownItemClick, onMenuOptionClick} from "../../common/Dropdown/helpers";
import {updateMode, webhookPlatformHowToLinks} from "../../../utilities/constants";
import NotificationRuleModel, {NotificationRuleSaga} from "../../../models/settings/NotificationRuleModel";
import {getModifyPrivilegedAction, onInputChange} from "../../../utilities/componentFunctions";
import {permissionKeys, statusKeys} from "../../../i18next/keys";
import Tablet, {EditTabletHeader} from "../../common/Tablet/Tablet";
import EditPanel from "../../common/EditPanel/EditPanel";
import ExpandableContent, {ExpandableEditTextArea} from "../../common/ExpandableContent/ExpandableContent";
import {makeGetEditDetails} from "../../../reselect/selectors";
import EditModel from "../../../models/scheduler/EditModel";
import PopupModel from "../../../models/scheduler/PopupModel";
import {SettingsRowValue} from "../../common/CustomTable/CustomTable";
import {checkNestedExists, isNotEmptyNorFalsy, objectTruthyValues} from "../../../utilities/helperFunctions";
import {onAddItemsToList, onSelectListItem} from "../../common/ListContainer/helpers";
import {WebhookPlatformDropdown} from "../../common/Dropdown/Dropdown";
import {getWebhookPlatformType} from "../../../utilities/iconResolver";
import SchedulerModel from "../../../models/scheduler/SchedulerModel";
import {contextCall} from "../../../saga/sagaFunctions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ButtonGroup} from "../../common/Button/Button";
import {ExpandableStatusLog} from "../../common/Common";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import {NotificationRuleTriggerSwitchListContainers} from "./WebhookNotificationRuleForm";
import LimitedTablet from "../../limited/LimitedTablet";

class WebhookNotificationRuleTablet extends Component {

    constructor(props) {
        super(props);

        this.getModifyPrivilegedAction = getModifyPrivilegedAction('notificationRule', this.props.t('notificationRule:label.name')).bind(this);
        this.onMenuOptionClick = onMenuOptionClick.bind(this);

        this.testNotificationRuleEdit = this.testNotificationRuleEdit.bind(this);
        this.testNotificationRule = this.testNotificationRule.bind(this);
        this.editNotificationRule = this.editNotificationRule.bind(this);
        this.deleteNotificationRule = this.deleteNotificationRule.bind(this);

        this.onEditInputChange = onInputChange({
            mode: updateMode.EDIT,
            shouldEnable: NotificationRuleModel.validateFormData
        }).bind(this);
        this.onEditDropdownItemClick = onDropdownItemClick({
            mode: updateMode.EDIT,
            shouldEnable: NotificationRuleModel.validateFormData
        }).bind(this);

        this.onEditAddTriggers = onAddItemsToList({
            mode: updateMode.EDIT,
            listTo: 'selectedTriggers',
            listFrom: 'availableTriggers',
            shouldEnable: NotificationRuleModel.validateFormData
        }).bind(this);
        this.onEditRemoveTriggers = onAddItemsToList({
            mode: updateMode.EDIT,
            listTo: 'availableTriggers',
            listFrom: 'selectedTriggers',
            shouldEnable: NotificationRuleModel.validateFormData
        }).bind(this);
        this.onEditSelectAvailableTriggers = onSelectListItem({
            mode: updateMode.EDIT,
            listName: 'availableTriggers',
            shouldEnable: NotificationRuleModel.validateFormData
        }).bind(this);
        this.onEditSelectSelectedTriggers = onSelectListItem({
            mode: updateMode.EDIT,
            listName: 'selectedTriggers',
            shouldEnable: NotificationRuleModel.validateFormData
        }).bind(this);
    }

    testNotificationRuleEdit() {
        const {
            notificationRuleId,
            ruleType,
            webhookNotificationRuleName,
            description,
            webhookPlatform,
            selectedTriggers,
            webhookUrl
        } = this.props;

        const testEffect = contextCall(NotificationRuleSaga, 'testRuleData', 'Tablet', {
            id: notificationRuleId,
            ruleType,
            webhookNotificationRuleName,
            description,
            webhookPlatform,
            webhookUrl,
            selectedTriggers
        });
        this.props.yieldEffect(testEffect);
    }

    testNotificationRule() {
        const testEffect = contextCall(NotificationRuleSaga, 'testRule', this.props.notificationRuleId);
        this.props.yieldEffect(testEffect);
    }

    editNotificationRule() {
        this.props.editNotificationRule(this.props.notificationRuleId);
    }

    deleteNotificationRule() {
        this.props.promptDeleteNotificationRule(this.props.notificationRuleId);
    }

    render() {

        const {
            t,
            webhookNotificationRuleName,
            description,
            selectedTriggers,
            availableTriggers,
            webhookPlatform,
            webhookUrl,
            userPermissions,
            status,
            isEditActive,
            isSaveEnabled,
            isDisabled,

            notificationRuleId
        } = this.props;

        const menuOptions = [{
            name: t('common:option.edit'),
            value: 'editNotificationRule'
        }, {
            name: t('common:option.delete'),
            value: 'deleteNotificationRule'
        }, {
            isSeparator: true
        }, {
            name: t('common:option.test'),
            value: 'testNotificationRule'
        }];

        const descriptionProps = {
            label: t('common:label.description'),
            name: 'description',
            value: description,
            onChange: this.getModifyPrivilegedAction(this.onEditInputChange),
            isEditActive,
            isDisabled
        };
        const settingsProps = {
            webhookPlatform,
            webhookUrl,
            onInputChange: this.getModifyPrivilegedAction(this.onEditInputChange),
            onDropdownItemClick: this.getModifyPrivilegedAction(this.onEditDropdownItemClick),
            isEditActive,
            isDisabled
        };

        const triggersProps = {
            availableTriggers,
            selectedTriggers,
            onSelectAvailableTriggers: this.onEditSelectAvailableTriggers,
            onSelectSelectedTriggers: this.onEditSelectSelectedTriggers,
            triggerNavigateButtonProps: {
                onClick: [
                    this.onEditAddTriggers,
                    this.onEditRemoveTriggers
                ],
                length: [
                    checkNestedExists(availableTriggers, 'selectedItems') && objectTruthyValues(availableTriggers.selectedItems).length,
                    checkNestedExists(selectedTriggers, 'selectedItems') && objectTruthyValues(selectedTriggers.selectedItems).length
                ]
            },
            isEditActive,
            isDisabled
        };

        const canModifyNotificationRule = userPermissions.includes(permissionKeys.MODIFY);
        const isDescriptionVisible = isEditActive || !!description;
        const isTriggersVisible = isEditActive || isNotEmptyNorFalsy(selectedTriggers);

        const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);
        if (isViewLimited && !canModifyNotificationRule) {
            return (
                <LimitedTablet id={notificationRuleId} type={'webhook'} name={webhookNotificationRuleName} label={t(`notificationRule:label.webhookName`)}
                               status={status} enabled={true} canModify={canModifyNotificationRule} isDisabled={isDisabled} description={description}
                               onClose={this.props.close}
                />
            )
        }

        return (
            <Tablet width={'100rem'} height={isEditActive ? 'auto' : '50vh'} onClose={this.props.close}
                closeButtonAriaLabel={t('notificationRule:option.closeWebhookTablet_name', {name: webhookNotificationRuleName})}
                isDisabled={isDisabled}
                header={

                    <EditTabletHeader label={t('notificationRule:label.webhookName')} type={getWebhookPlatformType(webhookPlatform)} menuName={'webhookNotificationRule'}
                        id={notificationRuleId} name={'webhookNotificationRuleName'} value={webhookNotificationRuleName} inputHandler={this.onEditInputChange}
                        canModify={canModifyNotificationRule} menuOptions={menuOptions} menuOptionHandler={this.onMenuOptionClick}
                        isEditActive={isEditActive} isDisabled={isDisabled}
                    />
                }
                body={
                    <>
                        {status.code === statusKeys.ERROR && !isEditActive &&
                        <div className="display-item">
                            <ExpandableStatusLog {...status} isDisabled={isDisabled}/>
                        </div>
                        }

                        <div className="display-item">
                            <EditPanel isActive={isEditActive} options={
                                isEditActive &&
                                <ButtonGroup
                                    buttons={[{
                                        label: t('common:option.cancel'),
                                        onClick: this.props.cancelEdit,
                                        isDisabled
                                    }, {
                                        label: t('common:option.test'),
                                        onClick: this.testNotificationRuleEdit,
                                        isDisabled: isDisabled || !isSaveEnabled
                                    }, {
                                        label: t('common:option.save'),
                                        onClick: this.props.saveEdit,
                                        isDisabled: isDisabled || !isSaveEnabled
                                    }]}
                                    style={{
                                        marginTop: '0.75rem'
                                    }}
                                />
                            }>
                                {isDescriptionVisible && <ExpandableEditTextArea {...descriptionProps} />}
                                {<WebhookSettings{...settingsProps} />}
                                {isTriggersVisible && <ExpandableNotificationRuleTriggers {...triggersProps}/>}
                            </EditPanel>
                        </div>
                    </>
                }
            />
        )
    }
}

function WebhookSettings(props) {
    const {webhookPlatform, webhookUrl, isEditActive, isDisabled, ...editProps} = props;
    const {t} = useTranslation(['notificationRule']);

    return (
        <ExpandableContent label={t('common:label.settings')} isDisabled={isDisabled}>
            {isEditActive ?
                <EditWebhookSettings {...{webhookUrl, webhookPlatform, isDisabled, ...editProps}} />
                :
                <div className="settings-table">
                    <div className="table-row-group">
                        {SettingsRowValue({
                            label: t('notificationRule:label.webhookPlatform'),
                            value: t(`notificationRule:webhookPlatform.${webhookPlatform}`),
                            isDisabled
                        })}
                        {SettingsRowValue({
                            label: t('notificationRule:label.webhookUrl'),
                            value: webhookUrl,
                            isDisabled
                        })}
                    </div>
                </div>
            }
        </ExpandableContent>
    )
}

function EditWebhookSettings(props) {
    const {webhookPlatform, webhookUrl, onInputChange, onDropdownItemClick, isDisabled} = props;
    const {t} = useTranslation(['notificationRule']);

    return (
        <>
            <div className="display-input" style={{display: 'flex', alignItems: 'center'}}>
                <label className="label">
                    {t('notificationRule:label.webhookPlatform')}:
                </label>

                <div style={{margin: '0 0.35rem 0 0.75rem'}}>
                    <WebhookPlatformDropdown selectedWebhookPlatform={webhookPlatform} onWebhookPlatformSelect={onDropdownItemClick} isDisabled={isDisabled} isEdit/>
                </div>

                <a href={webhookPlatformHowToLinks[webhookPlatform]} target="_blank" rel="noopener noreferrer" style={{fontSize: '0.8rem'}}
                    title={t('notificationRule:message.howToSetupWebhooks')}
                >
                    <FontAwesomeIcon icon="question-circle" color="light-blue"/>
                </a>
            </div>

            <div className="display-input">
                <HTMLTextInput {...{
                    label: t('notificationRule:label.webhookUrl'),
                    name: 'webhookUrl',
                    value: webhookUrl,
                    onChange: onInputChange,
                    isRequired: true,
                    isDisabled
                }}/>
            </div>
        </>
    )
}

function ExpandableNotificationRuleTriggers(props) {
    const {isEditActive, ...attr} = props;
    const {t} = useTranslation(['notificationRule', 'job']);

    const disabled = attr.isDisabled ? ' is-disabled' : '';

    return (
        <ExpandableContent label={t('notificationRule:label.triggers')} isDisabled={attr.isDisabled}>
            {isEditActive ?
                <NotificationRuleTriggerSwitchListContainers {...attr}/>
                :
                attr.selectedTriggers
                    .map(trigger =>
                        <label className={'label' + disabled} key={trigger}>
                            {t(`job:trigger.${trigger}`)}
                        </label>
                    )
            }
        </ExpandableContent>
    )
}

const makeMapStateToProps = () => {
    const getEditDetails = makeGetEditDetails();
    return state => {
        const {notificationRuleDetailsMap, componentStates: {notificationRuleDisplay: {notificationRuleId}, notificationRuleTablet}} = state;
        const {name: webhookNotificationRuleName, url: webhookUrl, triggers: selectedTriggers, ...otherDetails} = notificationRuleDetailsMap.get(notificationRuleId);

        const {activeModel, values, isSaveEnabled} = getEditDetails(state, {model: NotificationRuleModel.nom});
        const isEditActive = (activeModel === NotificationRuleModel.nom);

        return {
            notificationRuleId,
            webhookNotificationRuleName,
            webhookUrl,
            selectedTriggers,
            ...otherDetails,
            ...values,
            isEditActive,
            isSaveEnabled,
            ...notificationRuleTablet
        }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        yieldEffect: effect => dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(effect)),

        editNotificationRule: id => dispatch(NotificationRuleModel.actionCreators.startEdit(id)),
        promptDeleteNotificationRule: id => dispatch(NotificationRuleModel.actionCreators.promptDelete(id)),

        ...EditModel.buildDispatchers(dispatch),

        showError: payload => dispatch(PopupModel.actionCreators.showError(payload)),
        close: () => dispatch(NotificationRuleModel.actionCreators.hideTablet())
    }
};

export default connect(makeMapStateToProps, mapDispatchToProps)(withTranslation(['notificationRule', 'common'])(WebhookNotificationRuleTablet));