import React from 'react';
import {connect} from 'react-redux';
import LicenceSourceModel from "../../../models/settings/LicenceSourceModel";
import {StatusNameDescriptionCell, TableRow} from "../../common/CustomTable/CustomTable";
import {licenseRelayTypes} from "../../../utilities/constants";
import {useTranslation} from "react-i18next";
import {permissionKeys} from "../../../i18next/keys";
import LimitedTableRow from "../../limited/LimitedTableRow";

const NmsLicenceSourceRow = props => {
    const {
        nuixLicenceSourceId,
        name,
        description,
        filter,
        relayType,
        username,
        serverName,
        serverPort,
        status,
        showNuixLicenceSourceTablet,
        isActive,
        userPermissions
    } = props;
    const {t} = useTranslation(['nuixLicenceSource', 'common']);

    const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);
    const canModify = userPermissions.includes(permissionKeys.MODIFY);
    if (isViewLimited && !canModify) {
        return (
            <LimitedTableRow id={nuixLicenceSourceId} isActive={isActive} onClick={showNuixLicenceSourceTablet} cellCount={6}
                             nameComponent={<StatusNameDescriptionCell status={status} name={name} description={description}/>}
            />
        )
    }

    return (
        <TableRow value={nuixLicenceSourceId} isActive={isActive} onClick={showNuixLicenceSourceTablet}>
            <StatusNameDescriptionCell style={{maxWidth: '10rem'}}
                status={status}
                name={name}
                description={description}
            />

            <div className="table-cell max-width-8">
                <label className="label is-ellipsis">{filter}</label>
            </div>
            <div className="table-cell max-width-8">
                <label className="label is-ellipsis">{username}</label>
            </div>
            <div className="table-cell max-width-8">
                <label className="label is-ellipsis">{serverName}</label>
            </div>
            <div className="table-cell max-width-8">
                <label className="label is-ellipsis">{serverPort}</label>
            </div>
            <div className="table-cell max-width-8">

                {relayType
                    && (relayType === licenseRelayTypes.RELAY_CLS_AUTH || relayType === licenseRelayTypes.RELAY_NMS_AUTH) &&
                    <label className="label is-ellipsis">{t(`nuixLicenceSource:relayType.${relayType}`)}</label>
                }
            </div>
        </TableRow>
    );
}

const mapStateToProps = (state, ownProps) => {
    const {componentStates: {licenceSourceDisplay: {nuixLicenceSourceId}}, nuixLicenceSourceDetailsMap} = state;

    return {
        isActive: nuixLicenceSourceId === ownProps.nuixLicenceSourceId,
        ...nuixLicenceSourceDetailsMap.get(ownProps.nuixLicenceSourceId)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showNuixLicenceSourceTablet: event => dispatch(LicenceSourceModel.actionCreators.showTablet(event.currentTarget.dataset.value))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NmsLicenceSourceRow);