import React from "react";
import './ToggleSwitch.css';
import {buildClassName, stringToBool} from "../../../utilities/helperFunctions";
import {useGetMissingClasses} from "../AccessibilityAssist";
import Text from "../Text/Text";


export function ToggleSwitch(props) {
    const {
        label,
        text=label,

        name,
        checked,
        onClick,
        labelStyle,
        isWordWrap=true,
        isHeading,
        isReadOnly,
        isInvalid,
        isDisabled,
        ...attr
    } = props;

    const canTabSelect = !isDisabled && !isReadOnly;
    function onKeyUp(e) {
        if (canTabSelect && e.key === 'Enter') {
            onClick(e);
            e.stopPropagation();
        }
    }

    const containerClassName = buildClassName(
        'toggle-container',
        isReadOnly && 'is-readonly',
        isDisabled && 'is-disabled',
        ...useGetMissingClasses(text, {ariaLabel: attr['aria-label'], ariaLabelledBy: attr['aria-labelledby']})
    );
    const switchClassName = buildClassName(
        'toggle-switch'
    );
    const spanClassName = buildClassName(
        'switch round',
        isInvalid && !isDisabled && 'is-invalid'
    )


    const _onClick = (isReadOnly || isDisabled) ? null : onClick;

    return (
        <span role="checkbox" className={containerClassName} data-name={name} data-checked={!checked}
            aria-checked={checked} tabIndex={canTabSelect ? 0 : -1} title={text ? null : attr['aria-label']}
            onClick={_onClick} {...attr} onKeyUp={onKeyUp}
        >
            <span className={switchClassName}>
                <input type="checkbox" name={name} checked={stringToBool(checked)}
                    readOnly/>
                <span className={spanClassName}/>
            </span>

            {text &&
                <Text ElementTag={'span'} value={text} style={labelStyle}
                    isEllipsis isWordWrap={isWordWrap} isNoWrap={!isWordWrap} isHeading={isHeading}/>
            }
        </span>
    );
}

export default ToggleSwitch;
