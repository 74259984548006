import React, {useCallback, useState} from "react";
import SimpleTestForm from "../test/SimpleTestForm";
import {SettingsRowValue} from "../../common/CustomTable/CustomTable";
import {useTranslation} from "react-i18next";
import {useInputHandler, useUpdateHandler} from "../../../utilities/formHooks";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import {lowerCaseFirstLetter} from "../../../utilities/helperFunctions";
import ThirdPartyServiceModel from "../../../models/thirdparty/ThirdPartyServiceModel";
import {useDispatch} from "react-redux";


function SmtpServiceTestForm(props) {
    const {t} = useTranslation(['thirdPartyService', 'common']);
    const dispatch = useDispatch();

    const {
        thirdPartyService,
        isDisabled
    } = props;

    const [state, setState] = useState({
        to: ''
    });

    const updateHandler = useUpdateHandler({
        updateState: setState
    });
    const inputHandler = useInputHandler({
        updateHandler
    });

    const onTest = useCallback(() => {
        const successPopupInfo = {
            key: 'smtpServiceTestSuccess',
            values: {
                to: state.to
            }
        };
        dispatch(ThirdPartyServiceModel.actionCreators.sendCommand(thirdPartyService.id, ThirdPartyServiceModel.UserCredentialAction.TEST, state, successPopupInfo));
    }, [state]);

    const camelCaseType = lowerCaseFirstLetter(thirdPartyService.type);
    const isEnabled = !!(state.to);

    return (
        <SimpleTestForm {...props} onTest={onTest} isSubmitEnabled={isEnabled}
            addText={t('common:label.send')} SettingRows={SmtpServiceTestFormSettingRows}
            body={
                <>
                    <div className="display-input">
                        <HTMLTextInput name={'to'} value={state.to} onChange={inputHandler}
                            label={t('common:label.to')} autoComplete={`${camelCaseType}Username`}
                            isRequired isDisabled={isDisabled}/>
                    </div>
                </>
            }
        />
    )
}

function SmtpServiceTestFormSettingRows(props) {
    const {t} = useTranslation(['thirdPartyService', 'common']);
    const {
        thirdPartyService,
        isDisabled
    } = props;

    return (
        <>
            <SettingsRowValue label={t('thirdPartyService:label.host')} value={thirdPartyService.host}
                isTight isDisabled={isDisabled}/>

            <SettingsRowValue label={t('thirdPartyService:label.from')} value={thirdPartyService.from}
                isTight isDisabled={isDisabled}/>
        </>
    )
}

export default SmtpServiceTestForm;
