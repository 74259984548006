import {SettingsRowValue} from "../../common/CustomTable/CustomTable";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Form, {FormFooter, FormHeader} from "../../common/Form/Form";
import {capitalize, isNotEmptyNorFalsy, lowerCaseFirstLetter} from "../../../utilities/helperFunctions";
import {buildMapFromInputTable, InputTableV2} from "../../common/InputTable/InputTable";


function SecretPropertiesUserCredentialFormBody(props) {
    const {t} = useTranslation(['thirdPartyService', 'common']);
    const {
        type,
        thirdPartyService,
        initialProperties=[],
        SettingRows,
        onClose,
        isDisabled
    } = props;

    const propertiesId = 'thirdPartyServiceSecretProperties';
    const [properties, setProperties] = useState(initialProperties);

    function onLogin() {
        props.onLogin({
            type,
            properties: buildMapFromInputTable(properties, propertiesId, inputColumnProps)
        });
    }

    const camelCaseType = lowerCaseFirstLetter(thirdPartyService.type);
    const isLoginEnabled = isNotEmptyNorFalsy(properties);

    return (
        <Form onClose={onClose}
            isDisabled={isDisabled} closeButtonAriaLabel={t(`thirdPartyService:option.closeSecretPropertiesUserCredentialForm${thirdPartyService.type}`)}
            header={
                <FormHeader text={t(`thirdPartyService:option.signIn${capitalize(thirdPartyService.authenticationScope)}`)}
                    iconName={`${camelCaseType}UserSignIn`} isDisabled={isDisabled}/>
            }
            body={
                <form onSubmit={onLogin} style={{paddingTop: '0.5rem', paddingBottom: '0.25rem'}}>
                    <div className="settings-table">
                        <div className="table-row-group">
                            <SettingsRowValue label={t(`thirdPartyService:label.${camelCaseType}`)} value={thirdPartyService.name}
                                isTight isDisabled={isDisabled}/>

                            {SettingRows &&
                                <SettingRows thirdPartyService={thirdPartyService} isDisabled={isDisabled}/>
                            }
                        </div>
                    </div>

                    <div className="display-input">
                        <InputTableV2 id={propertiesId} ariaLabelKey={'Property'} label={t('thirdPartyService:label.secretProperties')}
                            headers={[{value: t('thirdPartyService:header.name')}, {value: t('thirdPartyService:header.value')}]}
                            inputColumnProps={inputColumnProps} defaultRow={secretPropertyDefaultRow}
                            inputTable={properties} setInputTable={setProperties} isDisabled={isDisabled}/>
                    </div>

                    <button type="submit" hidden disabled={!isLoginEnabled}/>
                </form>
            }
            footer={
                <FormFooter addText={t('thirdPartyService:option.signIn')} onAddClick={onLogin}
                    onCancel={onClose} isAddEnabled={isLoginEnabled} isDisabled={isDisabled}
                />
            }
        />
    )
}

const secretPropertyDefaultRow = [{value: ''}, {value: ''}];
const inputColumnProps = [{}, {type: 'password'}];

export default SecretPropertiesUserCredentialFormBody;
