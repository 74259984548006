import React, {useRef} from "react";
import {TableLabelCell, TableLabelHeader} from "../common/CustomTable/CustomTable";
import {buildClassName} from "../../utilities/helperFunctions";
import {useTranslation} from "react-i18next";
import ExpandableContent from "../common/ExpandableContent/ExpandableContent";
import {getRenderedHeightOffset, useRenderedItemHeight, useSimpleVirtualRendering} from "../../utilities/hooks";

function AdministratorTable(props) {
    const {t} = useTranslation(['legalHold', 'common']);

    const {
        administrators,
        isDisabled
    } = props;

    const containerRef = useRef();
    const listContainerSelector = '.table-row-group';

    const itemHeightRef = useRenderedItemHeight(containerRef, listContainerSelector, 31.6094);
    const heightOffset = getRenderedHeightOffset(containerRef, listContainerSelector, itemHeightRef.current);
    const virtualRenderRows = useSimpleVirtualRendering({
        containerRef,
        itemHeightRef,
        size: administrators.length,
        heightOffset
    });

    const className = buildClassName(
        'display-table',
        'tight-padding',
        isDisabled && 'is-disabled'
    );

    return (
        <ExpandableContent label={t('legalHold:label.administrators')}
            isDisabled={isDisabled}
        >
            <div ref={containerRef}>
                <div className={className}>
                    <div className="table-header-group">
                        <TableLabelHeader label={t('common:label.name')} style={{width: '28rem'}}/>

                        <TableLabelHeader label={t('legalHold:label.email')}/>
                    </div>

                    <div className="table-row-group">
                        {virtualRenderRows((index, topOffset) => {
                            const administrator = administrators[index];
                            if (administrator == null) {
                                return null;
                            }

                            return (
                                <div key={administrator.name} className="table-row no-pointer" style={{position: 'relative', top: topOffset}}>
                                    <TableLabelCell label={administrator.displayName || administrator.name}/>

                                    <TableLabelCell label={administrator.email}/>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </ExpandableContent>
    )
}

export default AdministratorTable;
