import React, {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {usePopupWindow} from "../../../utilities/hooks";
import SchedulerModel from "../../../models/scheduler/SchedulerModel";
import ThirdPartyServiceModel, {ThirdPartyServiceApi} from "../../../models/thirdparty/ThirdPartyServiceModel";
import UserServiceModel from "../../../models/user/UserServiceModel";
import PopupModel from "../../../models/scheduler/PopupModel";
import RelativityUserCredentialFormBody from "../relativity/RelativityUserCredentialFormBody";
import DiscoverUserCredentialFormBody from "../discover/DiscoverUserCredentialFormBody";
import NlpUserCredentialFormBody from "../nlp/NlpUserCredentialFormBody";
import EccUserCredentialFormBody from "../ecc/EccUserCredentialFormBody";
import GraphUserCredentialFormBody from "../graph/GraphUserCredentialFormBody";
import GenAiUserCredentialFormBody from "../genAi/GenAiUserCredentialFormBody";
import SmtpUserCredentialFormBody from "../smtp/SmtpUserCredentialFormBody";
import ElasticsearchUserCredentialFormBody from "../elasticsearch/ElasticsearchUserCredentialFormBody";
import {lowerCaseFirstLetter} from "../../../utilities/helperFunctions";


function ThirdPartyUserCredentialForm(props) {
    const dispatch = useDispatch();
    const {
        thirdPartyService,
        onSuccess,
        onError,
        onClose
    } = props;

    const [isDisabled, setIsDisabled] = useState(false);
    const onLogin = useCallback(async userCredential => {
        try {
            setIsDisabled(true);
            const {data} = await ThirdPartyServiceApi.postUserCredential(thirdPartyService.id, userCredential);
            if (typeof onSuccess === 'function') {
                onSuccess(data);
            }
            dispatch(PopupModel.actionCreators.showSuccess({
                info: {key: 'thirdPartyUserCredentialTestSuccess'}
            }));
            onClose();
        } catch (error) {
            dispatch(SchedulerModel.actionCreators.handleResponseError(error));
            if (typeof onError === 'function') {
                onError(error);
            }
            setIsDisabled(false);
        } finally {
            dispatch(ThirdPartyServiceModel.actionCreators.queryDetails());
        }
    }, []);

    const _onClose = useCallback(() => {
        const camelCaseType = lowerCaseFirstLetter(thirdPartyService.type);
        dispatch(PopupModel.actionCreators.showWarning({
            info: {
                key: `${camelCaseType}DiscardUserCredential`
            },
            buttons: [{
                titleKey: 'common:option.discard',
                onClick: onClose
            }]
        }));
    }, [thirdPartyService.type]);

    const FormBody = getFormBodyComponent(thirdPartyService.type);
    return (
        <FormBody thirdPartyService={thirdPartyService} onLogin={onLogin}
            onClose={_onClose} isDisabled={isDisabled}/>
    )
}

export const THIRD_PARTY_SERVICE_LOGIN_WINDOW_NAME = 'THIRD_PARTY_SERVICE_LOGIN_WINDOW_NAME';
export function useThirdPartySignIn(props) {
    const dispatch = useDispatch();
    const {
        setUserCredentialFormActive,
        onSuccess,
        onError
    } = props;

    const thirdPartyService = props.thirdPartyService || {};
    const authenticationService = useSelector(state => state.userServiceDetailsMap.get(thirdPartyService.authenticationServiceId));

    const openPopupWindow = usePopupWindow({
        windowName: THIRD_PARTY_SERVICE_LOGIN_WINDOW_NAME,
        onSuccess: useCallback((success) => {
            dispatch(ThirdPartyServiceModel.actionCreators.queryDetails());
            if (typeof onSuccess === 'function') {
                onSuccess(success);
            }
        }, [onSuccess]),
        onError: useCallback((error) => {
            dispatch(SchedulerModel.actionCreators.handleResponseError(error.body))
            if (typeof onError === 'function') {
                onError(error);
            }
        }, [onError]),
    });

    const onUserCredentialSignIn = useCallback(() => {
        switch (thirdPartyService.authenticationMethod) {
            case ThirdPartyServiceModel.AuthenticationMethod.OIDC_AUTHORIZATION_CODE:
                const queryParams = new URLSearchParams();
                queryParams.append('oidcScope', authenticationService?.name);
                queryParams.append('thirdPartyServiceId', thirdPartyService.id);

                let mode = UserServiceModel.OidcLoginMode.THIRD_PARTY_SERVICE;
                if (thirdPartyService.type === ThirdPartyServiceModel.Type.PURVIEW) {
                    mode = UserServiceModel.OidcLoginMode.E_DISCOVERY_MANAGER;
                    if (thirdPartyService.usePurviewDownload) {
                        mode = [mode, UserServiceModel.OidcLoginMode.E_DISCOVERY_DOWNLOADER];
                    }
                } else if (thirdPartyService.type === ThirdPartyServiceModel.Type.VAULT) {
                    mode = UserServiceModel.OidcLoginMode.VAULT_USER;
                }
                queryParams.append('mode', mode);

                const oidcLoginUrl = `${window.location.origin + window.location.pathname}#/oidcAccountLogin?${queryParams.toString()}`;
                openPopupWindow(oidcLoginUrl);
                break;
            default:
                setUserCredentialFormActive(true);
                break;
        }
    }, [thirdPartyService.id, thirdPartyService.authenticationMethod, thirdPartyService.usePurviewDownload, authenticationService?.name]);

    const onUserCredentialSignOut = useCallback(async (scope=thirdPartyService.authenticationScope) => {
        try {
            dispatch(ThirdPartyServiceModel.componentActionCreators.updateTablet({isDisabled: true}));
            const {data} = await ThirdPartyServiceApi.deleteUserCredential(thirdPartyService.id, scope);
            if (data.redirectUrl) {
                openPopupWindow(data.redirectUrl);
            } else {
                dispatch(PopupModel.actionCreators.showInfo({info: data}));
            }
        } catch (error) {
            dispatch(SchedulerModel.actionCreators.handleResponseError(error));
        } finally {
            dispatch(ThirdPartyServiceModel.actionCreators.queryDetails());
            dispatch(ThirdPartyServiceModel.componentActionCreators.updateTablet({isDisabled: false}));
        }
    }, [thirdPartyService.id, thirdPartyService.authenticationScope]);

    return [onUserCredentialSignIn, onUserCredentialSignOut];
}

function getFormBodyComponent(type) {
    switch (type) {
        case ThirdPartyServiceModel.Type.RELATIVITY:
            return RelativityUserCredentialFormBody;
        case ThirdPartyServiceModel.Type.GRAPH:
            return GraphUserCredentialFormBody;
        case ThirdPartyServiceModel.Type.GEN_AI:
            return GenAiUserCredentialFormBody;
        case ThirdPartyServiceModel.Type.DISCOVER:
            return DiscoverUserCredentialFormBody;
        case ThirdPartyServiceModel.Type.ELASTICSEARCH:
            return ElasticsearchUserCredentialFormBody;
        case ThirdPartyServiceModel.Type.NLP:
            return NlpUserCredentialFormBody;
        case ThirdPartyServiceModel.Type.ECC:
            return EccUserCredentialFormBody;
        case ThirdPartyServiceModel.Type.SMTP:
            return SmtpUserCredentialFormBody;
    }
}

export default ThirdPartyUserCredentialForm;
