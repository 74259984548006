import {useTranslation} from "react-i18next";
import {stringToBool} from "../../../utilities/helperFunctions";
import initialUserSettings from "../../../models/user/initialUserSettings";
import {userSettings} from "../../../utilities/constants";
import OptionToggleRow from "./OptionToggleRow";
import React from "react";

function JobPanelOption(props) {
    const {setting: jobPanel, updateSetting} = props;
    const {t} = useTranslation(['jobPanel']);

    function toggleSetting(event) {
        const {name: setting, checked} = event.currentTarget.dataset;
        updateSetting(setting, stringToBool(checked));
    }

    return (
        <>
            <div className="options-table display-input">
                <div className="table-header-group">
                    <div className="table-header"/>
                    <div className="table-header"/>
                </div>

                <div className="table-row-group">
                    {Object
                        .keys(initialUserSettings.get(userSettings.JOB_PANEL))
                        .map(key =>
                            <OptionToggleRow key={key} label={t(`jobPanel:label.${key}`)} useTrElement={false}
                                name={key} checked={jobPanel[key]} onClick={toggleSetting}/>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default JobPanelOption;
