import React from 'react';
import './SearchBar.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslation} from "react-i18next";
import HTMLTextInput from "../HTMLTextInput/HTMLTextInput";
import {buildClassName} from "../../../utilities/helperFunctions";
import {Button} from "../Button/Button";

function SearchBar(props) {
    const {t} = useTranslation(['aria', 'common']);
    const {
        ariaLabelKey='',
        searchBarRef,
        value,
        onChange,
        autoFocus,
        placeholder,
        isDisabled,
        ...attr
    } = props;

    function clearInput() {
        const event = {
            target: {name: 'searchText', value: ''}
        };
        onChange(event);
    }

    const hasValue = value && value.length > 0;
    const icon = hasValue ? 'times' : 'search';
    const searchButtonAriaLabelKey = hasValue ? 'clearSearch' : 'performSearch';

    const className = buildClassName(
        'search-bar',
        isDisabled && 'no-hover'
    );

    return (
        <search className={className} ref={searchBarRef} {...attr}>
            <HTMLTextInput name="searchText" type="search" autoComplete="off" placeholder={placeholder || t('aria:input.search')}
                aria-label={t(`aria:hiddenAssistText.searchBar${ariaLabelKey}`)} value={value} onChange={onChange}
                expandContainer autoFocus={autoFocus} isDisabled={isDisabled}/>

            <Button aria-label={t(`aria:hiddenAssistText.${searchButtonAriaLabelKey}${ariaLabelKey}`)}
                className="search-bar__button" isImg onClick={clearInput} isDisabled={isDisabled}
            >
                <span className="icon">
                    <FontAwesomeIcon icon={icon} />
                </span>
            </Button>
        </search>
    );
}

export default SearchBar;