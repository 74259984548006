import ReduxModel from "../generics/ReduxModel";
import {generateUUID4, getValues, objectTruthyValues} from "../../utilities/helperFunctions";
import {legalHoldTriggerConfiguration} from "../../utilities/constants";

class TriggerConfiguration extends ReduxModel {

    constructor(model = {}) {
        super();
        const {id, enabled, workflowLibraryId, workflowTemplateId, trigger, noticeTemplateId, noticeTemplateVersion, optionKey, triggerResponses, triggered} = model;

        this.id = id || generateUUID4();
        this.enabled = enabled;
        this.workflowLibraryId = workflowLibraryId || null;
        this.workflowTemplateId = workflowTemplateId || null;
        this.trigger = trigger;

        if (noticeTemplateId && noticeTemplateVersion) {
            this.noticeTemplateIdAndVersion = `${noticeTemplateId}:${noticeTemplateVersion}`;
        } else {
            this.noticeTemplateIdAndVersion = '';
        }
        this.optionKey = optionKey;
        this.triggerResponses = triggerResponses;
        this.triggered = triggered;
    }

    isValid(silent) {
        if (!this.enabled) {
            return true;
        }

        if (!!this.workflowTemplateId) {
            switch(this.trigger) {
                case legalHoldTriggerConfiguration.ON_CUSTODIAN_RELEASE:
                case legalHoldTriggerConfiguration.ON_CUSTODIAN_REMINDER:
                case legalHoldTriggerConfiguration.ON_CUSTODIAN_ESCALATION:
                case legalHoldTriggerConfiguration.ON_CUSTODIAN_HOLD:
                case legalHoldTriggerConfiguration.ON_MATTER_ACTIVATE:
                case legalHoldTriggerConfiguration.ON_MATTER_RELEASE:
                case legalHoldTriggerConfiguration.ON_MATTER_ARCHIVED:
                case legalHoldTriggerConfiguration.ON_MATTER_CUSTODIANS_HOLD:
                case legalHoldTriggerConfiguration.ON_MATTER_CUSTODIANS_RELEASE:
                case legalHoldTriggerConfiguration.ON_MATTER_DELETE:
                    return true;
                case legalHoldTriggerConfiguration.ON_CUSTODIAN_RESPONSE:
                    return !silent && !!(this.noticeTemplateIdAndVersion && this.optionKey && getValues(this.triggerResponses).some(v => v));
            }
        }

        return false;
    }

    wasEnabledDisabled(oldConfig={}) {
        if (!!this.enabled !== !!oldConfig.enabled) {
            return true;
        }
        return false;
    }

    clearSensitive() {
        this.id = generateUUID4();
        this.triggered = false;
    }

    getSaveValues() {
        const {noticeTemplateIdAndVersion, triggerResponses, ...rest} = this;
        const [noticeTemplateId, noticeTemplateVersion] = noticeTemplateIdAndVersion.split(':');

        return {
            ...rest,
            noticeTemplateId,
            noticeTemplateVersion,
            triggerResponses: objectTruthyValues(triggerResponses)
        };
    }

    getEditValues() {
        const editValues = this.duplicate();
        editValues.triggerResponses = (editValues.triggerResponses || []).reduce((acc, response) => {
            acc[response] = true;
            return acc;
        }, {});
        return editValues;
    }

}

export default TriggerConfiguration;