import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {DefaultEditPanel} from '../common/EditPanel/EditPanel';
import {permissionKeys, statusKeys} from "../../i18next/keys";
import TextArea from '../common/TextArea/TextArea';
import {SettingsRowValue} from '../common/CustomTable/CustomTable';
import {createDropdownHandler} from "../common/Dropdown/helpers";
import {isNotEmptyNorFalsy, switchcaseF} from "../../utilities/helperFunctions";
import {ExecutionProfileDropdown, MenuDropdown, ResourcePoolDropdown} from "../common/Dropdown/Dropdown";
import ClientModel from "../../models/client/ClientModel";
import EditModel from "../../models/scheduler/EditModel";
import {shouldEnableClient} from "../../utilities/shouldEnableFunctions";
import ExpandableContent, {ExpandableWorkflowParametersV2} from "../common/ExpandableContent/ExpandableContent";
import KeyValueList from "../common/KeyValueList/KeyValueList";
import {createEditHandler, createInputHandler} from "../../utilities/componentFunctions";
import ParameterModel from "../../models/library/ParameterModel";
import {selectClientDetails} from "./selectors";
import {applicationFeatures, userSettings} from "../../utilities/constants";
import {InputTableV2} from "../common/InputTable/InputTable";
import DatasetModel from "../../models/data/DatasetModel";
import MatterDisplay from "../matter/MatterDisplay";
import {ExpandableStatusLog} from "../common/Common";
import {onWorkflowParameterNormalize} from "../common/InputTable/helpers";
import {MainPageContent} from "../app/pages/MainPage";


function ClientDisplay(props) {
    const {t} = useTranslation(['aria', 'client', 'matter', 'workflow', 'common']);
    const dispatch = useDispatch();

    const {clientId} = props;
    const {showObjectIds} = useSelector(state => state.userSettingsMap.get(userSettings.TROUBLESHOOT));

    const {
        clientName,
        description,
        enabled,
        defaultExecutionProfile,
        defaultResourcePool,
        allowedParameterValues,
        requiredMetadataHeaders,
        workflowParameters,
        status,
        userPermissions,
        isEditActive,
        isSaveEnabled
    } = useSelector(state => selectClientDetails(state, {clientId}), shallowEqual);

    const [forceExpand, setForceExpand] = useState();
    const [clearParameterPasswordState, setClearParameterPasswordState] = useState({});
    useEffect(() => {
        if (isEditActive) {
            setForceExpand({isExpanded: true});
        }

        setClearParameterPasswordState({});
    }, [isEditActive]);


    const {saveEdit, cancelEdit, updateEdit, setEditSaveEnabled} = EditModel.buildDispatchers(dispatch);
    const editHandler = createEditHandler({
        updateEdit,
        setEditSaveEnabled,
        shouldEnable: shouldEnableClient,
        values: {clientName, allowedParameterValues}
    });

    const inputHandler = createInputHandler({
        handler: editHandler
    });
    const dropdownItemClick = createDropdownHandler({
        handler: editHandler
    });


    function menuOptionHandler(event) {
        const {value} = event.currentTarget.dataset;

        switchcaseF({
            'edit': () => dispatch(ClientModel.actionCreators.startEdit(clientId)),
            'toggleEnable': () => dispatch(ClientModel.actionCreators.toggleEnabled(clientId)),
            'promptDelete': () => dispatch(ClientModel.actionCreators.promptDelete(clientId))
        })()(value);
    }

    // Custom inputHandler for allowedParameters
    function setAllowedParameterValues(update) {
        const updates = {
            allowedParameterValues: update(allowedParameterValues)
        };

        editHandler(updates);
    }

    function setRequiredMetadataHeaders(update) {
        const updates = {
            requiredMetadataHeaders: update(requiredMetadataHeaders)
        };

        editHandler(updates);
    }

    const workflowParameterNormalizeHandler = onWorkflowParameterNormalize(clearParameterPasswordState, setClearParameterPasswordState);

    function setWorkflowParameters(update) {
        const updates = {
            workflowParameters: update(workflowParameters)
        };

        editHandler(updates);
    }

    const menuOptions = [
        {name: t('common:option.edit'), value: 'edit'},
        {name: t(`common:option.${enabled ? 'deactivate' : 'activate'}`), value: 'toggleEnable'},
        {name: t('common:option.delete'), value: 'promptDelete'}
    ];

    const canModifyClient = userPermissions.includes(permissionKeys.MODIFY);
    const canAddMatter = userPermissions.includes(permissionKeys.MODIFY_CHILDREN) || userPermissions.includes(permissionKeys.MODIFY);

    const showAllowedParameters = isEditActive || isNotEmptyNorFalsy(allowedParameterValues);
    const showRequiredMetadataHeaders = isEditActive || isNotEmptyNorFalsy(requiredMetadataHeaders);
    const showWorkflowParameters = isEditActive || isNotEmptyNorFalsy(workflowParameters);

    const canManageDataSets = useSelector(state => state.currentUser.features.includes(applicationFeatures.SCHEDULER_UPLOAD));

    return (
        <MainPageContent id={`${clientId}_TABPANEL`} aria-labelledby={`${clientId}_TAB`}
            title={
                <>
                    <DefaultEditPanel isActive={isEditActive} isSaveEnabled={isSaveEnabled} onSave={saveEdit} onCancel={cancelEdit}>
                        {isEditActive ?
                            <ClientEditSection name={clientName} description={description}
                                inputHandler={inputHandler} dropdownItemClick={dropdownItemClick}
                                defaultExecutionProfile={defaultExecutionProfile} defaultResourcePool={defaultResourcePool}
                            />
                            :
                            <ClientSection
                                showObjectIds={showObjectIds} id={clientId} status={status}
                                name={clientName} description={description} enabled={enabled}
                                showMenu={canModifyClient} menuOptions={menuOptions} menuOptionHandler={menuOptionHandler}
                                defaultExecutionProfile={defaultExecutionProfile} defaultResourcePool={defaultResourcePool}
                            />
                        }

                        {showWorkflowParameters &&
                            <ExpandableWorkflowParametersV2 label={t('client:label.workflowParameters')} name={'workflowParameters'} force={forceExpand}
                                                          initialExpanded={workflowParameters.length > 0} isEditActive={isEditActive} workflowParameters={workflowParameters}
                                                          headers={[{value: t('client:header.name')}, {value: t('client:header.value')}]}
                                                          inputTable={workflowParameters} setInputTable={setWorkflowParameters}
                                                          isReadOnly={!isEditActive} defaultRow={ClientModel.workflowParametersRowTemplate}
                                                          blurHandler={[ParameterModel.normalizeParameterName, null]} inputNormalizer={workflowParameterNormalizeHandler}
                            />
                        }

                        {showAllowedParameters &&
                        <ExpandableContent label={t('client:label.allowedParameters')} name={'allowedParameterValues'} force={forceExpand}
                            initialExpanded={allowedParameterValues.length > 0} headerBarStyle={{marginBottom: '0.25rem'}}
                        >
                            <KeyValueList keysTitle={t('workflow:label.parameterName')} blurHandler={ParameterModel.normalizeParameterName}
                                ariaLabelKey={'Parameter'} entries={allowedParameterValues} setEntries={setAllowedParameterValues}
                                isReadOnly={!isEditActive} isMoveEnabled={true}/>
                        </ExpandableContent>
                        }

                        {showRequiredMetadataHeaders && canManageDataSets &&
                        <ExpandableContent label={t('dataset:label.requiredMetadataHeaders')} name={'requiredMetadataHeaders'} force={forceExpand}
                            initialExpanded={requiredMetadataHeaders.length > 0}
                        >
                            <InputTableV2 headers={[{value: t('dataset:label.header')}, {value: t('dataset:label.regex')}]}
                                ariaLabelKey={'RequiredMetadata'} inputTable={requiredMetadataHeaders} setInputTable={setRequiredMetadataHeaders}
                                defaultRow={DatasetModel.requiredMetadataHeadersRowTemplate} isReadOnly={!isEditActive}
                            />
                        </ExpandableContent>
                        }
                    </DefaultEditPanel>
                </>
            }
            list={
                <MatterDisplay clientId={clientId} canAddMatter={canAddMatter}/>
            }
        />
    )
}

function ClientSection(props) {
    const {showObjectIds, id, name, description, enabled, defaultExecutionProfile, defaultResourcePool, showMenu, menuOptions, menuOptionHandler, status} = props;
    const {t} = useTranslation(['aria', 'common']);

    const showDefaults = defaultExecutionProfile || defaultResourcePool;

    return (
        <>
            <div className="display-title">
                <h2 className="subtitle is-bold">
                    {name + (enabled ? '' : ` (${t('common:label.inactive')})`)}
                </h2>
                {showMenu &&
                <div className="display-menu">
                    <MenuDropdown menuOptions={menuOptions} onOptionClick={menuOptionHandler}
                        id={'clientMenuDropdown'} aria-label={t('aria:hiddenAssistText.clientMenu')}
                    />
                </div>
                }
            </div>

            {showObjectIds &&
                <div className="id-label">
                    {id}
                </div>
            }

            {status?.code === statusKeys.ERROR &&
                <div className="display-item">
                    <ExpandableStatusLog {...status}/>
                </div>
            }

            <label className="display-item label">{description}</label>

            {showDefaults &&
            <div className="settings-table display-item">
                <div className="table-row-group">
                    {defaultExecutionProfile &&
                    <SettingsRowValue label={t('client:label.defaultExecutionProfile')} value={defaultExecutionProfile} isBold/>
                    }
                    {defaultResourcePool &&
                    <SettingsRowValue label={t('client:label.defaultResourcePool')} value={defaultResourcePool} isBold/>
                    }
                </div>
            </div>
            }
        </>
    )
}

function ClientEditSection(props) {
    const {name, description, defaultExecutionProfile, defaultResourcePool, inputHandler, dropdownItemClick} = props;
    const {t} = useTranslation(['aria', 'client']);

    const nameRequired = name ? '' : ' is-required';

    return (
        <>
            <input aria-label={t('aria:input.clientName')} className={'input subtitle-edit' + nameRequired} name={'clientName'}
                value={name} onChange={inputHandler}
            />

            <div className="display-item">
                <TextArea aria-label={'aria:input.description'} name={'description'} value={description} onChange={inputHandler}/>
            </div>

            <div className="settings-table display-item">
                <div className="table-row-group">
                    <SettingsRowValue label={t('client:label.defaultExecutionProfile')} isBold
                        value={
                            <ExecutionProfileDropdown name={'defaultExecutionProfileId'} isEdit
                                selectedExecutionProfileId={defaultExecutionProfile} onExecutionProfileSelect={dropdownItemClick}
                                buttonStyle={{
                                    marginBottom: '0.1rem'
                                }}
                            />
                        }
                    />
                    <SettingsRowValue label={t('client:label.defaultResourcePool')} isBold
                        value={
                            <ResourcePoolDropdown name={'defaultResourcePoolId'} isEdit
                                selectedResourcePoolId={defaultResourcePool} onResourcePoolSelect={dropdownItemClick}
                                buttonStyle={{
                                    marginBottom: '0.1rem'
                                }}
                            />
                        }
                    />
                </div>
            </div>
        </>
    )
}

export default ClientDisplay;