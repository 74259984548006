import {axiosInstance} from "../api/AxiosProxy";
import {actionCreator, getLocaleDateTimeFromUTC,} from "../../utilities/helperFunctions";
import {all, put, select, takeLeading} from "redux-saga/effects";
import {tryCatchWrapper} from "../../saga/tryCatchWrapper";
import {contextCall, contextSaga, isDisabledWrapper} from "../../saga/sagaFunctions";
import SubsetDetailsModel, {SubsetDetailsSaga} from "../generics/SubsetDetailsModel";
import ComponentStateModel from "../generics/ComponentStateModel";
import PopupModel from "../scheduler/PopupModel";
import {libraryFileTypeKeys, libraryFileUpdateMode, popupInfoKeys} from "../../i18next/keys";
import FileLibraryModel from "./FileLibraryModel";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import AuditLogModel from "../generics/AuditLogModel";


class LibraryFileModel extends SubsetDetailsModel {

    static nom = 'LibraryFileModel';
    static parentKey = 'fileLibraryId';

    static actions = LibraryFileModel.buildActions('LIBRARY_FILE');
    static actionCreators = LibraryFileModel.buildActionCreators(LibraryFileModel.actions);
    static reducer = LibraryFileModel.buildReducer(LibraryFileModel.actions);

    static componentActionCreators = {
        ...LibraryFileModel.buildComponentActionCreators()
    };

    constructor(model={}) {
        super(model);
        const {id, fileLibraryId, name, size, description, nuixFileType, version, createdDate, createdBy, lastStateChangedDate} = model;

        this.id = id;
        this.fileLibraryId = fileLibraryId;
        this.name = name;
        this.description = description;
        this.nuixFileType = libraryFileTypeKeys[nuixFileType];
        this.version = version;

        this.size = size;
        this.createdBy = createdBy;
        this.createdDate = getLocaleDateTimeFromUTC(createdDate);
        this.lastStateChangedDate = getLocaleDateTimeFromUTC(lastStateChangedDate)
    }

    static libraryFileTypeToString (profileType) {
        switch(profileType) {
            case libraryFileTypeKeys.PROCESSING_PROFILE:
                return "processingProfiles";
            case libraryFileTypeKeys.METADATA_PROFILE:
                return "metadataProfiles";
            case libraryFileTypeKeys.METADATA_IMPORT_PROFILE:
                return "metadataProfiles";
            case libraryFileTypeKeys.PLAYBOOK:
                return "playbooks";
            case libraryFileTypeKeys.OCR_PROFILE:
                return "ocrProfiles";
            case libraryFileTypeKeys.PRODUCTION_PROFILE:
                return "productionProfiles";
            case libraryFileTypeKeys.CONFIGURATION_PROFILE:
                return "configurationProfiles";
            case libraryFileTypeKeys.IMAGING_PROFILE:
                return "imagingProfiles";
            default:
                return "invalidProfile";
        }
    }

    static buildActions(type) {
        return {
            ...super.buildActions(type),
            // DATASET ACTIONS
            QUERY: 'QUERY_LIBRARY_FILES',
            START_FILE_UPDATE: 'START_LIBRARY_FILE_UPDATE',
            UPDATE_LIBRARY_FILE: 'UPDATE_LIBRARY_FILE'
        }
    }

    static buildActionCreators(actions) {
        return {
            ...super.buildActionCreators(actions),
            // DATASET ACTION CREATORS
            query: actionCreator(actions.QUERY, 'id'),
            startUpdate: actionCreator(actions.START_FILE_UPDATE, 'id'),
            updateLibraryFile: actionCreator(actions.UPDATE_LIBRARY_FILE, 'formData')
        }
    }

    static getDefaultFormState() {
        return {
            id: '',
            fileName: '',
            description: '',
            fileData: '',
            fileExtension: '',
            mode: libraryFileUpdateMode.LOCAL,
            isDisabled: false
        }
    }

    static buildComponentActionCreators() {
        const components = [
            {
                key: 'libraryFileView',
                type: 'View',
                state: {
                    libraryFileId: null,
                    isLibraryFileFormActive: false,
                }
            },
            {
                key: 'libraryFileForm',
                type: 'Form',
                state: LibraryFileModel.getDefaultFormState()
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }
}

export class LibraryFileApi {

    static getDetails (libraryId) {
        return axiosInstance.get(`/scheduler/resources/fileLibraries/${libraryId}/files`);
    }

    static get (fileId, libraryId) {
        return axiosInstance.get(`/scheduler/resources/fileLibraries/${libraryId}/files/${fileId}`);
    }

    static update (fileId, libraryId, data) {
        return axiosInstance.put(`/scheduler/resources/fileLibraries/${libraryId}/files/${fileId}`, {...data});
    }

    static post (libraryId, data) {
        return axiosInstance.post(`/scheduler/resources/fileLibraries/${libraryId}/files`, {...data});
    }

    static delete (fileId, libraryId) {
        return axiosInstance.del(`/scheduler/resources/fileLibraries/${libraryId}/files/${fileId}`);
    }

    static downloadLibraryFile (fileId, libraryId) {
        return axiosInstance.get(`/scheduler/resources/fileLibraries/${libraryId}/files/${fileId}/download`);
    }
}

export class LibraryFileSaga extends SubsetDetailsSaga {

    static ModelType = LibraryFileModel;
    static ModelApi = LibraryFileApi;

    static activationComponent = 'FILE_LIBRARY_DISPLAY';
    static variableNames = {
        detailsMap: 'libraryFileDetailsMap',
        instanceId: 'libraryFileId',
        isFormActive: 'isLibraryFileFormActive',
        updatePane: 'updateForm'
    };

    static translations = {
        itemTitle: '$t(fileLibrary:file.label.name)',
        itemLower: '$t(fileLibrary:file.label.name_lower)'
    };

    static buildActivationEffects(dispatch) {
        return [
            // ACTIVATION EFFECTS
            takeLeading(LibraryFileModel.actions.SHOW_FORM, contextSaga(this, 'showForm')),
            takeLeading(LibraryFileModel.actions.SUBMIT_FORM, tryCatchWrapper, isDisabledWrapper, LibraryFileModel.componentActionCreators.updateForm, contextSaga(this, 'submitForm')),

            takeLeading(LibraryFileModel.actions.QUERY, contextSaga(this, 'query')),
            takeLeading(LibraryFileModel.actions.START_FILE_UPDATE, contextSaga(this, 'startFileUpdate')),
            takeLeading(LibraryFileModel.actions.UPDATE_LIBRARY_FILE, contextSaga(this, 'updateLibraryFile')),
            takeLeading(LibraryFileModel.actions.PROMPT_DELETE, contextSaga(this, 'promptDelete')),
            takeLeading(LibraryFileModel.actions.DELETE, tryCatchWrapper, isDisabledWrapper, FileLibraryModel.componentActionCreators.updateTablet, contextSaga(this, 'delete')),
        ]
    }

    static* showForm(action) {
        yield all([
            put(LibraryFileModel.componentActionCreators.updateForm({
                ...LibraryFileModel.getDefaultFormState()
            })),
            put(LibraryFileModel.componentActionCreators.updateView({isLibraryFileFormActive: true})),
        ]);
    }

    static* submitForm(action) {
        const {formData} = action.payload;
        const {fileLibraryId} = yield select(state => state.componentStates.fileLibraryDisplay);

        const {data} = yield contextCall(LibraryFileApi, 'post', fileLibraryId, formData);

        yield all([
            put(LibraryFileModel.actionCreators.addDetails(data)),
            put(LibraryFileModel.componentActionCreators.updateView({libraryFileId: data.id, isLibraryFileFormActive: false})),
        ]);
    }

    static* updateLibraryFile (action) {
        const {formData} = action.payload;
        const {id} = formData;
        const {fileLibraryId} = yield select(state => state.componentStates.fileLibraryDisplay);

        const {data} = yield contextCall(LibraryFileApi, 'update', id, fileLibraryId, formData);

        yield all([
            put(LibraryFileModel.actionCreators.updateDetails({[id]: new this.ModelType(data)})),
            put(LibraryFileModel.componentActionCreators.updateView({libraryFileId: data.id, isLibraryFileFormActive: false})),
        ]);
    }

    static* startFileUpdate (action) {
        const {id} = action.payload;
        const file = yield select(state => state.libraryFileDetailsMap.get(id));

        yield all([
            put(LibraryFileModel.componentActionCreators.updateForm({
                id: id,
                fileName: '',
                description: file.description,
                fileData: null,
                fileExtension: '',
                mode: libraryFileUpdateMode.REDUX,
                isDisabled: false
            })),
            put(LibraryFileModel.componentActionCreators.updateView({isLibraryFileFormActive: true})),
        ]);
    }

    static* promptDelete(action) {
        const {id} = action.payload;
        const {name} = yield select(state => state.libraryFileDetailsMap.get(id));

        yield put(PopupModel.actionCreators.showWarning({
            info: {
                key: popupInfoKeys.DELETE_ITEM,
                values: {
                    itemName: name
                },
                valueKeys: {
                    itemTitle: this.translations.itemTitle
                }
            },
            buttons: [{
                titleKey: 'common:option.delete',
                onClick: () => this.dispatch(LibraryFileModel.actionCreators.delete(id))
            }]
        }));
    }

    static* delete(action) {
        const {id} = action.payload;
        const {fileLibraryId} = yield select(state => state.componentStates.fileLibraryDisplay);

        const afterDeleteSuccessEffect = all([
            put(LibraryFileModel.actionCreators.deleteDetails(id))
        ]);

        yield contextCall(LibraryFileApi, 'delete', id, fileLibraryId);
        yield afterDeleteSuccessEffect;
    }

    static* querySettings (action) {
        const {id} = action.payload;
        const {fileLibraryId} = yield select(state => state.componentStates.fileLibraryDisplay);
        if (id == null || fileLibraryId == null)
            return;

        try {
            const {data} = yield contextCall(LibraryFileApi, "get", id, fileLibraryId);
            const {auditLog} = data;

            yield all ([
                put(AuditLogModel.actionCreators.setSetting(id, auditLog))
            ]);
        } finally {
            yield put(ReduxStateModel.actionCreators.setHasLoaded(id))
        }
    }

    static* query(action) {
        const {id} = action.payload;

        const {data} = yield contextCall(LibraryFileApi, 'get', id);
        yield put(LibraryFileModel.actionCreators.addDetails(data));
    }
}

export default LibraryFileModel;