import React from 'react';
import {connect} from 'react-redux';
import LicenceSourceModel from "../../../models/settings/LicenceSourceModel";
import {StatusNameDescriptionCell, TableRow} from "../../common/CustomTable/CustomTable";
import {permissionKeys} from "../../../i18next/keys";
import LimitedTableRow from "../../limited/LimitedTableRow";

const DongleLicenceSourceRow = props => {
    const {name, description, filter, nuixLicenceSourceId, showNuixLicenceSourceTablet, status, isActive, userPermissions} = props;

    const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);
    const canModify = userPermissions.includes(permissionKeys.MODIFY);
    if (isViewLimited && !canModify) {
        return (
            <LimitedTableRow id={nuixLicenceSourceId} isActive={isActive} onClick={showNuixLicenceSourceTablet} cellCount={2}
                             nameComponent={<StatusNameDescriptionCell status={status} name={name} description={description}/>}
            />
        )
    }

    return (
        <TableRow value={nuixLicenceSourceId} isActive={isActive} onClick={showNuixLicenceSourceTablet}>

            <StatusNameDescriptionCell style={{maxWidth: '10rem'}}
                status={status}
                name={name}
                description={description}
            />

            <div className="table-cell max-width-8">
                <label className="label is-ellipsis">{filter}</label>
            </div>
        </TableRow>
    );
}

const mapStateToProps = (state, ownProps) => {

    return {
        isActive: state.componentStates.licenceSourceDisplay.nuixLicenceSourceId === ownProps.nuixLicenceSourceId,
        ...state.nuixLicenceSourceDetailsMap.get(ownProps.nuixLicenceSourceId)
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showNuixLicenceSourceTablet: event => dispatch(LicenceSourceModel.actionCreators.showTablet(event.currentTarget.dataset.value))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DongleLicenceSourceRow);