import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import ApiKeyModel from "../../models/settings/ApiKeyModel";
import {getLocaleDateFromUTC, switchcaseF} from "../../utilities/helperFunctions";
import {permissionKeys, statusKeys} from "../../i18next/keys";
import Tablet, {EditTabletHeader} from "../common/Tablet/Tablet";
import ExpandableContent from "../common/ExpandableContent/ExpandableContent";
import {ExpandableStatusLog} from "../common/Common";
import {SettingsRowValue} from "../common/CustomTable/CustomTable";
import CopySecretLabel from "../common/Text/CopySecretLabel";

function ApiKeyTablet(props) {
    const {t} = useTranslation(['apiKey', 'common']);
    const dispatch = useDispatch();


    const {
        isDisabled
    } = useSelector(state => state.componentStates.apiKeyTablet);

    const {
        id,name ,user, createdDay, lastUsedDay, validityDays,status,
        userPermissions, secret
    } = props;


    function onClose() {
        dispatch(ApiKeyModel.actionCreators.hideTablet());
    }

    function menuOptionHandler(event) {
        const {value} = event.currentTarget.dataset

        switchcaseF({
            'promptDelete': () => dispatch(ApiKeyModel.actionCreators.promptDelete(id)),
        })()(value)
    }

    const canModify = userPermissions.includes(permissionKeys.MODIFY);

    const menuOptions = [
        {name: t('common:option.delete'), value: 'promptDelete'}
    ];


    return (
        <Tablet onClose={onClose} isDisabled={isDisabled} closeButtonAriaLabel={t('apiKey:option.closeTablet_name', {name})}
            header={
                <EditTabletHeader label={t('apiKey:label.name')} type={'apiKey'}
                    id={id} name={'apiKey'} value={name} canModify={canModify} menuOptions={menuOptions}
                    menuOptionHandler={menuOptionHandler} isEditActive={false} isDisabled={isDisabled}/>
            }
            body={
                <>
                    {status.code === statusKeys.ERROR &&
                    <div className="display-item">
                        <ExpandableStatusLog {...status} isDisabled={isDisabled}/>
                    </div>
                    }

                    <div className="display-item">
                        <ExpandableContent label={t('common:label.credentials')} isDisabled={isDisabled}>
                            <div className="settings-table">
                                <div className="table-row-group">

                                    <SettingsRowValue label={t('apiKey:label.id')} value={id}
                                                      isDisabled={isDisabled}/>

                                    <SettingsRowValue label={t('apiKey:label.secret')} valueIsItalic={!secret} isDisabled={isDisabled}
                                        value={!!secret ?
                                            <CopySecretLabel copyLabel={t('apiKey:label.copyHeader')}
                                                copyText={"Bearer "+id+":"+secret} isDisabled={isDisabled}/>
                                            :
                                            t('apiKey:label.secretAvailableAtCreation')
                                        }
                                    />
                                </div>
                            </div>
                        </ExpandableContent>
                    </div>

                    <div className="display-item">
                        <ExpandableContent label={t('common:label.settings')} isDisabled={isDisabled}>
                            <div className="settings-table">
                                <div className="table-row-group">
                                    <SettingsRowValue label={t('apiKey:label.user')} value={user.identifierName}
                                        isDisabled={isDisabled}/>

                                    <SettingsRowValue label={t('apiKey:label.userType')} value={t(`policy:principalType.${user.identifierType}`)}
                                        isDisabled={isDisabled}/>

                                    <SettingsRowValue label={t('apiKey:label.createdDay')} value={getLocaleDateFromUTC(createdDay*24*3600*1000)}
                                        isDisabled={isDisabled}/>

                                    <SettingsRowValue label={t('apiKey:label.validityDays')} value={validityDays}
                                                      isDisabled={isDisabled}/>

                                    <SettingsRowValue label={t('apiKey:label.lastUsedDay')} value={lastUsedDay===0?t(`apiKey:label.na`):getLocaleDateFromUTC(lastUsedDay*24*3600*1000)}
                                                      isDisabled={isDisabled}/>
                                </div>
                            </div>
                        </ExpandableContent>
                    </div>
                </>
            }
        />
    )
}

export default ApiKeyTablet;
