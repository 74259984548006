import {useTranslation} from "react-i18next";
import {createInputHandler, createNestedUpdateState, createStateHandler} from "../../../utilities/componentFunctions";
import {chunkArray, getValues, isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import {recurOnDayKeys, scheduleFrequencyKeys} from "../../../i18next/keys";
import Checkbox from "../../common/Checkbox/Checkbox";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import React from "react";
import {createToggleHandler} from "../../common/Checkbox/helpers";
import {updateMode} from "../../../utilities/constants";
import RadioButton from "../../common/RadioButton/RadioButton";
import {Label} from "../../common/Text/Text";

function TimerTriggersPanel(props) {
    const {scheduleTrigger, updateState} = props;
    const {t} = useTranslation(['jobSchedule']);

    const reduxHandler = createStateHandler({
        updateState: createNestedUpdateState(updateState, ['scheduleTrigger'])
    });

    const inputHandler = createInputHandler({
        handler: reduxHandler
    });
    const toggleHandler = createToggleHandler({
        handler: reduxHandler
    });


    const {
        startDate,
        startTime,
        frequency,
        recurEvery,
        recurOnDay,
        addNextJobToStaging
    } = scheduleTrigger;

    return (
        <>
            <div style={{display: 'flex', marginTop: '0.75rem'}}>
                <div>
                    {getValues(scheduleFrequencyKeys).map(value =>

                        <div key={value} style={{padding: '0.45rem 0'}}>
                            <RadioButton name={'frequency'} selected={frequency} label={t(`jobSchedule:frequency.${value}`)} value={value}
                                onClick={inputHandler} isWordWrap={false}
                            />
                        </div>
                    )}
                </div>

                <div style={{marginLeft: '5rem', marginTop: '-0.125rem', width: '100%'}}>
                    <div className="forms-table tight-padding no-table-padding">
                        <div className="table-row-group">

                            <div className="table-row">
                                <div className="table-cell" style={{width: '6rem'}}>
                                    <Label value={t('common:option.start')} htmlFor="startDateDate"/>
                                </div>

                                <div className="table-cell" style={{display: 'flex', alignItems: 'center'}}>
                                    <HTMLTextInput id={`startDateDate`} aria-label={t('aria:input.date', {name: t('common:label.start')})}
                                        name={'startDate'} type={'date'} value={startDate} onChange={inputHandler}
                                        containerStyle={{flexBasis: '10rem', marginRight: '0.25rem'}}/>

                                    <HTMLTextInput aria-label={t('aria:input.time', {name: t('common:label.start')})}
                                        name={'startTime'} type={'time'} value={startTime} onChange={inputHandler}
                                        containerStyle={{flexBasis: '8rem'}}/>
                                </div>
                            </div>

                            {frequency !== scheduleFrequencyKeys.ONE_TIME &&
                                <div className="table-row">
                                    <div className="table-cell" style={{width: '6rem'}}>
                                        <Label id={'jobSchedule:recurEvery'} value={t('jobSchedule:label.recurEvery')}/>
                                    </div>

                                    <div className="table-cell" style={{display: 'flex', alignItems: 'center'}}>
                                        <HTMLTextInput id={'recurEveryInput'} name={'recurEvery'} type={'number'} isRequired
                                            value={recurEvery} onChange={inputHandler} aria-labelledby={'jobSchedule:recurEvery jobSchedule:recurEveryFrequency'}
                                            containerStyle={{flexBasis: '4rem', marginRight: '0.5rem'}}/>

                                        <Label id={'jobSchedule:recurEveryFrequency'} value={t(`jobSchedule:recurEvery.${frequency}`)}/>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    {frequency === scheduleFrequencyKeys.WEEKLY &&
                        <div className="display-item">
                            <FrequencyWeekly recurOnDay={recurOnDay} updateState={updateState}/>
                        </div>
                    }
                </div>
            </div>

            <div className="display-item">
                <Checkbox name={'addNextJobToStaging'} label={t(`jobSchedule:label.addNextJobToStaging`)}
                    checked={addNextJobToStaging} onClick={toggleHandler}
                />
            </div>
        </>
    )
}

function FrequencyWeekly(props) {
    const {recurOnDay, updateState} = props;
    const {t} = useTranslation(['jobSchedule']);

    const toggleEnable = createToggleHandler({
        mode: updateMode.REDUX,
        updateState: createNestedUpdateState(updateState, ['scheduleTrigger', 'recurOnDay'])
    });

    const chunkedRecurOnDays = chunkArray(getValues(recurOnDayKeys), 4);
    const required = isNotEmptyNorFalsy(recurOnDay) ? '' : ' is-required';
    return (
        <div className={'settings-table no-border tight-padding' + required} style={{width: 'auto', border: '1px solid transparent'}}>
            <div className="table-row-group">
                {chunkedRecurOnDays.map((days, index) =>

                    <div key={index} className="settings-row">
                        {days.map(day =>

                            <div key={day} className="table-cell">
                                <Checkbox name={day} label={t(`jobSchedule:recurOnDay.${day}`)} isWordWrap={false}
                                    checked={recurOnDay[day]} onClick={toggleEnable}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default TimerTriggersPanel;
