import React from 'react';
import {useSelector} from 'react-redux';
import {TableRow} from "../common/CustomTable/CustomTable";
import {workflowIconModel} from "../../models/library/WorkflowTemplateIconModel";
import Text from "../common/Text/Text";

export function MatterQueueRow(props) {
    const {
        matterId,
        onPanelSelect,
        isActive,
        isDisabled
    } = props;

    const matter = useSelector(state => state.matterDetailsMap.get(matterId));

    return (
        <TableRow value={matterId} onClick={isDisabled ? null : onPanelSelect}
            isActive={isActive} isDisabled={isDisabled}>

            <div className="table-cell">
                <Text value={matter.name} isBold isWordWrap/>
                <Text value={matter.description} isWordWrap/>
            </div>
        </TableRow>
    )
}

export function WorkflowTemplateQueueRow(props) {
    const {
        workflowTemplateId,
        onPanelSelect,
        isActive,
        isDisabled
    } = props;

    const workflow = useSelector(state => state.workflowTemplateDetailsMap.get(workflowTemplateId));
    const icon = workflowIconModel.useWorkflowIcon(workflowTemplateId);

    return (
        <TableRow value={workflowTemplateId} onClick={isDisabled ? null : onPanelSelect}
            isActive={isActive} isDisabled={isDisabled}>

            <div className="table-cell">
                <div className={"flex-center"}>
                    {icon &&
                        <span className="icon is-large spacing-right">
                            <img src={icon} alt={t('aria:hiddenAssistText.workflowIcon')}/>
                        </span>
                    }
                    <div>
                        <Text value={workflow.name} isBold isWordWrap/>
                        <Text value={workflow.description} isWordWrap/>
                    </div>
                </div>
            </div>
        </TableRow>
    )
}
