import React from "react";
import {useTranslation} from "react-i18next";
import {stringToBool} from "../../../utilities/helperFunctions";
import OptionToggleRow from "./OptionToggleRow";

function LegalHoldOption(props) {
    const {setting: legalHoldOption, updateSetting} = props;
    const {t} = useTranslation(['legalHoldOption']);

    function toggleSetting(event) {
        const {name, checked} = event.currentTarget.dataset;
        updateSetting(name, stringToBool(checked));
    }

    return (
        <div className="display-input">
            <table>
                <thead className="table-header-group">
                <tr>
                    <td className="table-header" colSpan={2}/>
                </tr>
                </thead>

                <tbody>
                    <OptionToggleRow label={t(`legalHoldOption:label.warnOnComment`)}
                        name={'warnOnComment'} checked={legalHoldOption.warnOnComment} onClick={toggleSetting}/>
                </tbody>
            </table>
        </div>
    )
}

export default LegalHoldOption;