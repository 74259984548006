import {put} from "redux-saga/effects";
import SagaRunnable from "../generics/SagaRunnable";
import ComponentStateModel from "../generics/ComponentStateModel";
import JobQueueModel from "../job/JobQueueModel";
import GuidedJobModel from "../guidedjob/GuidedJobModel";
import {jobPageViewKeys} from "../../i18next/keys";
import JobModel from "../job/JobModel";
import PurviewCache from "../guidedjob/PurviewCache";

class JobPurviewModel {

    static componentActionCreators = {
        ...JobPurviewModel.buildComponentSetActiveActionCreators()
    };

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'JOB_PURVIEW_DISPLAY',
                type: 'Display'
            }
        ];
        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }
}

export class JobPurviewSaga extends SagaRunnable {

    static activationComponent = 'JOB_PURVIEW_DISPLAY';

    static buildActivationEffects(dispatch) {
        return [
            put(JobQueueModel.componentActionCreators.setDisplayActive()),
            put(GuidedJobModel.componentActionCreators.setDisplayActive())
        ]
    }

    static buildDeactivationEffects() {
        return [
            put(JobQueueModel.componentActionCreators.setDisplayInactive()),
            put(GuidedJobModel.componentActionCreators.setDisplayInactive()),
            put(PurviewCache.actionCreators.reset())
        ]
    }

    static* setInstanceId(args) {
        yield put(JobModel.componentActionCreators.updatePage({activeView: jobPageViewKeys.PURVIEW, jobId: args.id}));
    }
}

export default JobPurviewModel;
