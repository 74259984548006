import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import './ExpandableContent.css';
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ParameterList, {ParametersTable} from '../../parameters/ParameterList';
import TextArea from '../TextArea/TextArea';
import {
    AlertHeader,
    ErrorAlertTable,
    InfoAlertTable,
    LinksAlertTable,
    SoftErrorAlertTable,
    WarningAlertTable
} from '../Tables/Tables';
import {SettingsRowSeparator, SettingsRowValue} from "../CustomTable/CustomTable";
import InputList, {ValueList} from "../InputList/InputList";
import {getStatusIconImgContent} from "../../../utilities/iconResolver";
import {StatusLabel} from "../Common";
import {OperationBacklogTable, OperationProgressTable} from "../../tables/OperationTable/OperationTable";
import {ChangeLogTable} from "../../tables/ChangeLogTable/ChangeLogTable";
import {buildAriaName, buildClassName, getScrollElement, nameLocaleCompare} from "../../../utilities/helperFunctions";
import Text from "../Text/Text";
import {useSelector} from "react-redux";
import {userSettings} from "../../../utilities/constants";
import {getScrollElementSignature, virtualRenderingTriggerBroadcastChannel} from "../../../utilities/hooks";
import ParameterModel from "../../../models/library/ParameterModel";
import {InputTableV2} from "../InputTable/InputTable";


function ExpandableContent(props) {
    const {t} = useTranslation(['aria']);
    const {className, label, name, initialExpanded = true, hideArrow, conditionallyRender=true, containerRef, titleName, onToggleCb,
        headerBar, headerBarStyle, useHeaderClick, children, isDisabled, force, header, flexExpand, ...attr} = props;

    const [isExpanded, setIsExpanded] = useState(initialExpanded);
    function toggleExpanded() {
        setIsExpanded(prev => !prev);
    }

    useEffect(() => {
        if (force != null && force.isExpanded != null && isExpanded !== force.isExpanded) {
            setIsExpanded(force.isExpanded)
        }
    }, [force]);

    const ref = useRef();
    const scrollElementRef = useRef();
    const broadcastChannel = useRef();
    useEffect(() => {
        broadcastChannel.current = new BroadcastChannel(virtualRenderingTriggerBroadcastChannel);
        scrollElementRef.current = getScrollElement(ref.current);
        return () => broadcastChannel.current.close();
    }, []);

    useLayoutEffect(() => {
        if (typeof onToggleCb === 'function') {
            onToggleCb(name, isExpanded);
        }
        const scrollElementSignature = getScrollElementSignature(scrollElementRef.current);
        if (scrollElementSignature != null) {
            broadcastChannel.current.postMessage(scrollElementSignature);
        }
    }, [isExpanded]);

    useEffect(() => {
        if (containerRef != null)
            containerRef.current = ref.current;

        return () => {
            if (containerRef != null)
                containerRef.current = {};
        }
    }, [containerRef, ref]);

    const expandableClassName = buildClassName(
        'expandable-content',
        useHeaderClick && 'use-header',
        isExpanded && 'is-expanded',
        flexExpand && 'flex-expand',
        className
    );
    const expandableBodyClassName = buildClassName(
        'expandable-body',
        flexExpand && 'flex-expand'
    );

    const arrowDirection = isExpanded ? 'right' : 'down';
    const customHeader = props.header || (
        <Text value={label} isHeading isDisabled={isDisabled}/>
    )

    const ariaName = buildAriaName(titleName, label);

    return (
        <section ref={ref} className={expandableClassName} {...attr}>
            <div style={headerBarStyle}>
                <button className="expandable-button" data-name={name} onClick={toggleExpanded}
                    aria-label={ariaName} disabled={isDisabled}
                >
                    {!hideArrow &&
                    <span className="expandable-arrow">
                        <FontAwesomeIcon icon={'angle-' + arrowDirection}/>
                    </span>
                    }
                    {customHeader}
                </button>

                <div className="expandable-header-bar" onClick={useHeaderClick ? toggleExpanded : null}>
                    {headerBar}
                </div>
            </div>

            <div className={expandableBodyClassName}>
                {conditionallyRender ?
                    (isExpanded && children)
                    :
                    children
                }
            </div>
        </section>
    );
}

export function ExpandableValueList(props) {
    const {label, values, isEditActive, listDisplay=false, ...attr} = props;

    const disabled = attr.isDisabled ? ' is-disabled' : '';
    return (
        <ExpandableContent label={label} isDisabled={attr.isDisabled}>
            {isEditActive ?
                <ValueList values={values} itemAriaLabel={label} {...attr}/>
                :
                listDisplay ?
                    <ul className={disabled} style={{paddingLeft: "1.5rem"}}>
                        {values.map((value, index) => <li key={index}><Text value={value} isEllipsis/></li>)}
                    </ul>
                    :
                    values.map((value, index) =>
                        <Text key={index} value={value} isEllipsis isDisabled={attr.isDisabled}/>
                    )
            }
        </ExpandableContent>
    );
}

export function ExpandableInputList(props) {
    const {label, values, onInputChange, isEditActive, ...attr} = props;

    return (
        <ExpandableContent label={label} isDisabled={attr.isDisabled}>
            {isEditActive ?
                <InputList values={values} onValueChange={onInputChange} {...attr}/>
                :
                values.map((value, index) => <label key={index} className="label is-ellipsis">{value}</label>)
            }
        </ExpandableContent>
    );
}

export function ExpandableTimeStampedLog(props) {
    const {label, log, isDisabled} = props;
    const initialExpanded = useSelector(state => !!state.userSettingsMap.get(userSettings.TROUBLESHOOT).showObjectIds);

    return (
        <ExpandableContent label={label} initialExpanded={initialExpanded} isDisabled={isDisabled}>
            <TimeStampedLog log={log} isDisabled={isDisabled}/>
        </ExpandableContent>
    );
}

export function TimeStampedLog(props) {
    const {log, isDisabled} = props;

    return (
        <div className="settings-table align-top">
            <div className="table-row-group">

                {log.map((row, index) => {
                    const [label, value] = row;

                    if (value == null) {
                        return (
                            <SettingsRowSeparator key={index}/>
                        )
                    }

                    return (
                        <SettingsRowValue key={index} label={label} value={value}
                            isBreakWord isNoWrap={index === 0} isDisabled={isDisabled}/>
                    )
                })}
            </div>
        </div>
    )
}

export function ExpandableParametersTable(props) {
    const {parameterLock, isDisabled, ...others} = props;
    const {t} = useTranslation(['job', 'workflow']);

    return (
        <ExpandableContent label={t('workflow:label.parameters')} isDisabled={isDisabled}>
            {parameterLock &&
            <StatusLabel iconName={'statusScheduled'} message={t('job:message.parameterLockPendingExecution')} style={{margin: '0.125rem 0 0.5rem -0.5rem'}}
                isDisabled={isDisabled}/>
            }
            <ParametersTable {...others} isDisabled={isDisabled}/>
        </ExpandableContent>
    );
}

export function ExpandableRequiredProfilesTable(props) {
    const {requiredProfiles, executionProfileId, isDisabled} = props;
    const {t} = useTranslation(['job', 'workflow', 'fileLibrary']);

    requiredProfiles.sort(nameLocaleCompare);

    return (
        <ExpandableContent label={t('job:label.requiredProfiles')} isDisabled={isDisabled}>
            <div className="settings-table align-top">
                <div className="table-row-group">
                    {requiredProfiles.map((row, index) =>
                        <div className="settings-row" key={index}>
                            <div className={'settings-label-cell'}>
                                <Text value={`${row.name}`} isDisabled={isDisabled}/>
                            </div>
                            <div className="settings-value-cell">
                                {(row.missing && !!executionProfileId) &&
                                    <StatusLabel message={t('job:message.nuixProfileTypeMissing', {profileType: t(`fileLibrary:file.type.${row.profileType}`)})} isDisabled={isDisabled}/>
                                }
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </ExpandableContent>
    );
}

export function ExpandableLog(props) {
    const {value, log=value, ...attr} = props;

    return (
        <ExpandableContent {...attr}>
            <Text value={log} isWordWrap
                isDisabled={attr.isDisabled}/>
        </ExpandableContent>
    );
}

export function ExpandableErrorLog(props) {
    const {label, log, isDisabled} = props;

    return (
        <ExpandableContent isDisabled={isDisabled}
            header={
                <div style={{display: 'flex', alignItems: 'center'}}>
                   <span className="icon is-small" style={{marginRight: '0.5rem'}}>
                       {getStatusIconImgContent('statusError')}
                   </span>
                    <label className="label is-heading" style={{marginTop: '-2px'}}>
                        {label}
                    </label>
                </div>
            }
        >
            <label className="label is-wordwrap">
                {log}
            </label>
        </ExpandableContent>
    );
}

export function ExpandableEditTextArea(props) {
    const {label, value, style, isEditActive, ...attr} = props;
    const {t} = useTranslation(['aria']);

    const disabled = attr.isDisabled ? ' is-disabled' : '';

    return (
        <ExpandableContent label={label} isDisabled={attr.isDisabled}>
            {isEditActive ?
                <TextArea aria-label={t('aria:input.description')} value={value} style={style} {...attr}/>
                :
                <label style={style} className={'label is-wordwrap' + disabled}>{value}</label>
            }
        </ExpandableContent>
    );
}

export function ExpandableOperationBacklogTable(props) {
    const {label, ...attr} = props;

    return (
        <ExpandableContent label={label} isDisabled={attr.isDisabled}>
            <OperationBacklogTable {...attr}/>
        </ExpandableContent>
    );
}

export function ExpandableOperationProgressTable(props) {
    const {label, ...attr} = props;

    return (
        <ExpandableContent label={label} isDisabled={attr.isDisabled}>
            <OperationProgressTable {...attr}/>
        </ExpandableContent>
    );
}

export function ExpandableWarningAlertTable(props) {
    const {label, ...attr} = props;

    return (
        <ExpandableContent isDisabled={attr.isDisabled}
            header={
                <AlertHeader label={label} iconName={'statusWarning'} />
            }
        >
            <WarningAlertTable {...attr}/>
        </ExpandableContent>
    );
}

export function ExpandableLinkAlertTable(props) {
    const {label, ...attr} = props;

    return (
        <ExpandableContent isDisabled={attr.isDisabled}
                           header={
                               <AlertHeader label={label} iconName={'statusLink'} />
                           }
        >
            <LinksAlertTable {...attr}/>
        </ExpandableContent>
    );
}

export function ExpandableInfoAlertTable(props) {
    const {label, ...attr} = props;

    return (
        <ExpandableContent isDisabled={attr.isDisabled}
            header={
                <AlertHeader label={label} iconName={'statusInfo'} />
            }
        >
            <InfoAlertTable {...attr}/>
        </ExpandableContent>
    );
}

export function ExpandableSoftErrorAlertTable(props) {
    const {label, ...attr} = props;

    return (
        <ExpandableContent isDisabled={attr.isDisabled}
            header={
                <AlertHeader label={label} iconName={'statusSoftError'} />
            }
        >
            <SoftErrorAlertTable {...attr}/>
        </ExpandableContent>
    );
}


export function ExpandableErrorAlertTable(props) {
    const {label, ...attr} = props;

    return (
        <ExpandableContent isDisabled={attr.isDisabled}
            header={
                <AlertHeader label={label} iconName={'statusError'} />
            }
        >
            <ErrorAlertTable {...attr} />
        </ExpandableContent>
    );
}

export function ExpandableChangeLogTable(props) {
    const {label, ...attr} = props;
    const initialExpanded = useSelector(state => !!state.userSettingsMap.get(userSettings.TROUBLESHOOT).showObjectIds);

    return (
        <ExpandableContent label={label} initialExpanded={initialExpanded} isDisabled={attr.isDisabled}>
            <ChangeLogTable {...attr}/>
        </ExpandableContent>
    );
}

export function ExpandableWorkflowParametersV2(props) {
    const {label, name, force, initialExpanded, isEditActive, workflowParameters, ...attr} = props;

    let body;
    if (isEditActive) {
        body = <InputTableV2 ariaLabelKey={'Parameter'} {...attr}/>
    } else {
        const parameters = new Map()
        for (const param of workflowParameters) {
            parameters.set(param[0].value, new ParameterModel({name: param[0].value, value: param[1].value}))
        }

        body = (
            <div className="display-input">
                <ParameterList parameters={parameters}/>
            </div>
        )
    }

    return (
        <ExpandableContent label={label} name={name} force={force} initialExpanded={initialExpanded}>
            {body}
        </ExpandableContent>
    )
}

export default ExpandableContent;