import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import {actionCreator, getLocaleDateFromUTC, getValues} from "../../utilities/helperFunctions";
import {all, call, put, race, select, take, takeLeading} from "redux-saga/effects";
import {tryCatchWrapper} from "../../saga/tryCatchWrapper";
import {contextCall, contextSaga, editNavigation, isDisabledWrapper, poll} from "../../saga/sagaFunctions";
import MatterModel, {MatterSaga} from "../client/MatterModel";
import SubsetDetailsModel, {SubsetDetailsSaga} from "../generics/SubsetDetailsModel";
import ComponentStateModel from "../generics/ComponentStateModel";
import PopupModel from "../scheduler/PopupModel";
import {datasetStateKeys, popupInfoKeys, statusKeys, uploadInfoStateKeys} from "../../i18next/keys";
import SchedulerModel from "../scheduler/SchedulerModel";
import FileInfoModel, {FileInfoSaga} from "./FileInfoModel";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import UploadInfoModel from "./UploadInfoModel";
import {blobUrlDownload} from "../../utilities/downloadHelper";


class DatasetModel extends SubsetDetailsModel {

    static nom = 'DatasetModel';
    static parentKey = 'matterId';

    static actions = DatasetModel.buildActions('DATASET');
    static actionCreators = DatasetModel.buildActionCreators(DatasetModel.actions);
    static reducer = DatasetModel.buildReducer(DatasetModel.actions);

    static requiredMetadataHeadersRowTemplate = [{value: ''}, {value: ''}];
    static componentActionCreators = {
        ...DatasetModel.buildComponentActionCreators()
    };

    constructor(model={}) {
        super(model);
        const {type, matterId, dataRepositoryId, state, fileMetadataHeaders,
            path, number, createdDate, lastModifiedDate, finalizedDate, usableSpace} = model;

        this.type = type;
        this.matterId = matterId;
        this.dataRepositoryId = dataRepositoryId;
        this.path = path;
        this.state = state;
        this.fileMetadataHeaders = fileMetadataHeaders;

        this.number = number;
        this.createdDate = createdDate;
        this.lastModifiedDate = lastModifiedDate;
        this.finalizedDate = finalizedDate;
        this.usableSpace = usableSpace;
    }

    isDraft() {
        return this.state === datasetStateKeys.DRAFT;
    }

    isFinalized() {
        return this.state === datasetStateKeys.FINALIZED;
    }

    isHidden() {
        return this.state === datasetStateKeys.HIDDEN;
    }

    isArchived() {
        return this.state === datasetStateKeys.ARCHIVED;
    }

    getDisplayName() {
        let displayName = this.name;
        if (this.number != null) {
            displayName = `${this.number}. ${displayName}`;
        }
        if (this.finalizedDate != null) {
            displayName = `${displayName} (${getLocaleDateFromUTC(this.finalizedDate)})`;
        }

        return displayName;
    }

    static isUploadInfoActive(uploadInfo) {
        return (uploadInfo || {}).state === uploadInfoStateKeys.ACTIVE;
    }

    static buildActions(type) {
        return {
            ...super.buildActions(type),
            // DATASET ACTIONS
            QUERY: 'QUERY_DATASET',
            CANCEL_UPLOAD: 'CANCEL_DATASET_UPLOAD',
            SEND_COMMAND: 'SEND_DATASET_COMMAND',

            UPLOAD_FILES_METADATA_CSV: 'UPLOAD_FILES_METADATA_CSV',
            DOWNLOAD_FILES_METADATA_CSV: 'DOWNLOAD_FILES_METADATA_CSV',

            // FILE METADATA EDIT
            DELETE_METADATA_COLUMN: 'DELETE_METADATA_COLUMN',
        }
    }

    static buildActionCreators(actions) {
        return {
            ...super.buildActionCreators(actions),
            // DATASET ACTION CREATORS
            query: actionCreator(actions.QUERY, 'id'),
            cancelUpload: actionCreator(actions.CANCEL_UPLOAD),
            sendDatasetCommand: actionCreator(actions.SEND_COMMAND, 'id', 'command'),

            uploadFilesMetadataCsv: actionCreator(actions.UPLOAD_FILES_METADATA_CSV, 'id', 'csvText'),
            downloadFilesMetadataCsv: actionCreator(actions.DOWNLOAD_FILES_METADATA_CSV, 'id', 'showNested'),

            // FILE METADATA EDIT
            deleteMetadataColumn: actionCreator(actions.DELETE_METADATA_COLUMN, 'col', 'editHandler'),
        }
    }

    static buildComponentActionCreators() {
        const components = [
            {
                key: 'datasetView',
                type: 'View',
                state: {
                    datasetId: null,
                    isDatasetFormActive: false,
                    isUploadActive: false
                }
            },
            {
                key: 'datasetForm',
                type: 'Form',
                state: {
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }
}

export class DatasetApi {

    static get(id) {
        return axiosInstance.get(`/scheduler/resources/dataset/${id}`);
    }

    static getDetails(matterId) {
        return axiosInstance.get(`/scheduler/resources/dataset/${matterId}/datasets`);
    }

    static post(dataset) {
        return axiosInstance.post(`/scheduler/resources/dataset`, dataset);
    }

    static delete(id, force=false) {
        return axiosInstance.del(`/scheduler/resources/dataset/${id}?force=${force}`);
    }

    static postCommand(id, command, force=false) {
        return axiosInstance.post(`/scheduler/resources/dataset/${id}/command?force=${force}`, {command});
    }

    static getUsableSpace(id) {
        return axiosInstance.get(`/scheduler/resources/dataset/${id}/usableSpace`);
    }

    static getRestrictions(id, fileRelativePaths) {
        return axiosInstance.post(`/scheduler/resources/dataset/${id}/restrictions`, fileRelativePaths);
    }

    static getSizeOfFiles(id, fileRelativePaths) {
        return axiosInstance.post(`/scheduler/resources/dataset/${id}/sizeOfFiles`, fileRelativePaths);
    }

    static getFiles(id, showNested=false) {
        return axiosInstance.get(`/scheduler/resources/dataset/${id}/files?showNested=${showNested}`);
    }

    static deleteFiles(id, fileIds) {
        return axiosInstance.post(`/scheduler/resources/dataset/${id}/files:batchDelete`, fileIds);
    }

    static postFilesMetadataCsv(id, csvText) {
        return axiosInstance.post(`/scheduler/resources/dataset/${id}/files/metadata`, csvText, {headers: {"Content-Type" : "text/plain"}});
    }

    static getFilesMetadataCsv(id, showNested=false) {
        return axiosInstance.get(`/scheduler/resources/dataset/${id}/files/metadata?showNested=${showNested}`);
    }

    static getUploadInfos(id, showNested=false) {
        return axiosInstance.get(`/scheduler/resources/dataset/${id}/uploadInfos?showNested=${showNested}`);
    }

    static deleteUploadInfos(id, uploadIds) {
        return axiosInstance.post(`/scheduler/resources/dataset/${id}/uploadInfos:batchDelete`, uploadIds);
    }

    static getRequiredMetadataHeaders(id) {
        return axiosInstance.get(`/scheduler/resources/dataset/${id}/files/requiredMetadataHeaders`);
    }
}

export class DatasetSaga extends SubsetDetailsSaga {

    static ModelType = DatasetModel;
    static ModelApi = DatasetApi;

    static activationComponent = 'CLIENT_PAGE';
    static variableNames = {
        detailsMap: 'datasetDetailsMap',
        instanceId: 'datasetId',
        isFormActive: 'isDatasetFormActive',
        updateDisplay: 'updateView'
    };

    static translations = {
        itemTitle: '$t(dataset:label.name)',
        itemLower: '$t(dataset:label.name_lower)'
    };

    static buildActivationEffects(dispatch) {
        return [
            // ACTIVATION EFFECTS
            takeLeading(DatasetModel.actions.SHOW_FORM, contextSaga(this, 'showForm')),
            takeLeading(DatasetModel.actions.SUBMIT_FORM, tryCatchWrapper, isDisabledWrapper, DatasetModel.componentActionCreators.updateForm, contextSaga(this, 'submitForm')),
            takeLeading(DatasetModel.actions.SHOW_DISPLAY, editNavigation, this.dispatch, contextSaga(this, 'showPane')),

            takeLeading(DatasetModel.actions.CANCEL_UPLOAD, contextSaga(this, 'cancelUpload')),
            takeLeading(DatasetModel.actions.UPLOAD_FILES_METADATA_CSV, tryCatchWrapper, isDisabledWrapper, MatterModel.componentActionCreators.updateTablet, contextSaga(this, 'uploadFilesMetadataCsv')),
            takeLeading(DatasetModel.actions.DOWNLOAD_FILES_METADATA_CSV, tryCatchWrapper, contextSaga(this, 'downloadFilesMetadataCsv')),

            takeLeading(DatasetModel.actions.QUERY, contextSaga(this, 'query')),
            takeLeading(DatasetModel.actions.SEND_COMMAND, tryCatchWrapper, isDisabledWrapper, MatterModel.componentActionCreators.updateTablet, contextSaga(this, 'sendCommand')),
            takeLeading(DatasetModel.actions.PROMPT_DELETE, contextSaga(this, 'promptDelete')),
            takeLeading(DatasetModel.actions.DELETE, tryCatchWrapper, isDisabledWrapper, MatterModel.componentActionCreators.updateTablet, contextSaga(this, 'delete')),

            takeLeading(DatasetModel.actions.DELETE_METADATA_COLUMN, contextSaga(this, 'deleteMetadataColumn')),
        ]
    }

    static* showPane(action) {
        const {id} = action.payload;

        yield all([
            put(FileInfoModel.actionCreators.clearFileInfos(id)),
            put(DatasetModel.componentActionCreators.resetView({datasetId: id}))
        ]);
    }

    static* showForm(action) {
        const repositoriesCount = yield select(state => getValues(state.dataRepositoryDetailsMap).length);
        // Show popup if no repositories available
        if (repositoriesCount === 0) {
            yield put(PopupModel.actionCreators.showWarning({
                info: {
                    key: 'noDataRepositories'
                }
            }));

        } else {
            yield put(DatasetModel.componentActionCreators.updateView({
                isDatasetFormActive: true,
                isUploadActive: false
            }));
        }
    }

    static* submitForm(action) {
        const {formData} = action.payload;

        const {data} = yield contextCall(DatasetApi, 'post', formData);

        yield all([
            put(DatasetModel.actionCreators.addDetails(data)),
            put(DatasetModel.actionCreators.showDisplay(data.id))
        ]);
    }

    static* deleteMetadataColumn(action) {
        const {col, editHandler} = action.payload;
        const {editRows} = yield select(state => state.editDetails.values);

        function deleteColumn () {
            // Remove entire column from editRows
            const newEditRows = editRows.map(row => row.filter((cell, index) => col !== index));
            editHandler({editRows: newEditRows});
        }

        // If column has value, show warning popup
        if (editRows.some(row => !!row[col].value)) {

            yield put(PopupModel.actionCreators.showWarning({
                info: {
                    key: popupInfoKeys.DELETE_DATASET_METADATA_COLUMN_WARNING,
                    values: {
                        header: editRows[0][col].value
                    }
                },
                buttons: [{
                    titleKey: 'common:option.yes',
                    onClick: deleteColumn
                }]
            }));

        } else {
            deleteColumn();
        }
    }

    static* uploadFilesMetadataCsv(action) {
        const {id, csvText} = action.payload;

        const {matterId} = yield select(state => state.componentStates.clientDisplay);
        yield contextCall(DatasetApi, 'postFilesMetadataCsv', id, csvText);
        // re-query dataset + fileInfos
        const [filesRes] = yield all([
            contextCall(DatasetApi, 'getFiles', id),
            contextCall(MatterSaga, 'querySettings', {payload: {id: matterId}})
        ]);

        const fileInfoRows = yield contextCall(FileInfoSaga, 'buildFileInfoRows', id, filesRes.data);
        yield put(FileInfoModel.actionCreators.setDetails(id, fileInfoRows));
    }

    static* downloadFilesMetadataCsv(action) {
        const {id, showNested} = action.payload;

        const csvName = yield select(state => {
            const dataset = state.datasetDetailsMap.get(id) || {};
            const repository = state.dataRepositoryDetailsMap.get(dataset.dataRepositoryId);

            if (repository != null) {
                return `${repository.name} - ${dataset.name}.csv`;
            }
            return `${dataset.name} - ${dataset.path}.csv`;
        });

        const {data} = yield contextCall(DatasetApi, 'getFilesMetadataCsv', id, showNested);
        blobUrlDownload(data, {type: 'text/csv;charset=utf-8;', fileName: csvName})
    }

    static* cancelUpload() {
        yield put(PopupModel.actionCreators.showWarning({
            info: {
                key: popupInfoKeys.CANCEL_DATASET_UPLOAD
            },
            buttons: [{
                titleKey: 'common:option.yes',
                onClick: function () {
                    // Setting isUploadActive->false will close uppy instance and cancel uploads
                    this.dispatch(DatasetModel.componentActionCreators.updateView({isUploadActive: false}));
                }.bind(this)
            }],
            cancelButton: {
                titleKey: 'common:option.no'
            }
        }));
    }

    static* sendCommand(action) {
        const {id, command} = action.payload;

        try {
            const {data: {dataset, status}} = yield contextCall(DatasetApi, 'postCommand', id, command);
            yield put(DatasetModel.actionCreators.updateDetails({[id]: new DatasetModel(dataset)}));

            if (status.code === statusKeys.WARNING) {
                yield put(PopupModel.actionCreators.showWarning({
                    info: {
                        key: status.shortMessage,
                        values: status
                    },
                    cancelButton: {
                        titleKey: 'common:option.ok'
                    }
                }));
            }
        } catch (error) {
            yield this.handleDatasetCommandError(error, async () => {
                // Popup onClick
                const {data: {dataset}} = await DatasetApi.postCommand(id, command, true);
                this.dispatch(DatasetModel.actionCreators.updateDetails({[id]: new DatasetModel(dataset)}));
            });
        }
    }

    static* promptDelete(action) {
        const {id} = action.payload;
        const {name} = yield select(state => state.datasetDetailsMap.get(id));

        yield put(PopupModel.actionCreators.showWarning({
            info: {
                key: popupInfoKeys.DELETE_ITEM,
                values: {
                    itemName: name
                },
                valueKeys: {
                    itemTitle: this.translations.itemTitle
                }
            },
            buttons: [{
                titleKey: 'common:option.delete',
                onClick: () => this.dispatch(DatasetModel.actionCreators.delete(id))
            }]
        }));
    }

    static* delete(action) {
        const {id} = action.payload;

        const afterDeleteSuccessEffect = all([
            put(DatasetModel.actionCreators.deleteDetails(id)),
            put(DatasetModel.componentActionCreators.updateView({isUploadActive: false, datasetId: null}))
        ]);

        try {
            yield contextCall(DatasetApi, 'delete', id);
            yield afterDeleteSuccessEffect;

        } catch (error) {
            yield this.handleDatasetCommandError(error, async function () {

                await DatasetApi.delete(id, true);
                this.dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(afterDeleteSuccessEffect));
            }.bind(this));
        }
    }

    static* handleDatasetCommandError(error, onForce) {
        if (error.response == null) {
            console.log(error);
            return;
        }

        // Handle case where active/idleUploads were found
        switch (error.response.data.key) {
            case 'datasetHasActiveUploads': {
                yield put(PopupModel.actionCreators.showError({
                    info: error.response.data
                }));
                break;
            }
            case 'datasetHasIdleUploads': {
                // Give option to force finalize if case === idleUploads
                yield put(PopupModel.actionCreators.showWarning({
                    info: error.response.data,
                    buttons: [{
                        titleKey: 'common:option.ok',
                        onClick: async function () {
                            try {
                                this.dispatch(MatterModel.componentActionCreators.updateTablet({isDisabled: true}));
                                await onForce();

                            } catch (error) {
                                this.dispatch(SchedulerModel.actionCreators.handleResponseError(error));
                            } finally {
                                this.dispatch(MatterModel.componentActionCreators.updateTablet({isDisabled: false}));
                            }
                        }.bind(this)
                    }]
                }));
                break;
            }
            default:
                throw error;
        }
    }

    static* pollSettings(action) {
        const {period, id} = action.payload;

        const waitForStop = function* () {
            while (true) {
                const {payload} = yield take(this.ModelType.actions.STOP_POLLING_SETTINGS);
                // Check if stop is for this poll id
                // Using 'null' as a STOP_ALL command
                if (payload.id == null || id === payload.id) {
                    return;
                }
            }
        }.bind(this);

        yield race([
            call(waitForStop),
            call(poll, period, contextSaga(this, 'querySettings'), action)
        ]);
    }

    static* querySettings(action) {
        const {id} = action.payload;
        if (id == null)
            return;

        try {
            const responses = yield all([
                contextCall(DatasetApi, 'getFiles', id),
                contextCall(DatasetApi, 'getUploadInfos', id, true)
            ]);

            const dataset = yield select(state => state.datasetDetailsMap.get(id));
            const matterEtag = AxiosProxy.etags[dataset.matterId];
            // matterEtag tracks etag for matterDatasets query
            // Include matterEtag in AxiosProxy.shouldUpdate because 'buildFileInfoRows' needs to be updated if dataset was also updated
            responses.push({headers: {etag: matterEtag}});

            if (AxiosProxy.shouldUpdate(id, responses)) {
                const [fileInfoRes, uploadInfoRes] = responses;
                const fileInfoRows = yield contextCall(FileInfoSaga, 'buildFileInfoRows', id, fileInfoRes.data);

                yield all([
                    put(FileInfoModel.actionCreators.setDetails(id, fileInfoRows)),
                    put(UploadInfoModel.actionCreators.setSubsetDetailsMap(id, uploadInfoRes.data))
                ]);
            }
        } finally {
            yield put(ReduxStateModel.actionCreators.setHasLoaded(id));
        }
    }

    static* query(action) {
        const {id} = action.payload;

        const {data} = yield contextCall(DatasetApi, 'get', id);
        yield put(DatasetModel.actionCreators.addDetails(data));
    }
}

export default DatasetModel;