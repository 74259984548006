import React, {useEffect} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import Tablet from "../common/Tablet/Tablet";
import {MenuDropdown} from "../common/Dropdown/Dropdown";
import {useTranslation} from "react-i18next";
import {getScheduleIconName, icon} from "../../utilities/iconResolver";
import {getKeys, getPluralTranslations, isNotEmptyNorFalsy, switchcaseF} from "../../utilities/helperFunctions";
import JobScheduleModel from "../../models/job/JobScheduleModel";
import ExpandableContent, {ExpandableLog} from "../common/ExpandableContent/ExpandableContent";
import {SettingsRowSeparator, SettingsRowValue, SettingsUserIconRow} from "../common/CustomTable/CustomTable";
import {scheduleFrequencyKeys, scheduleTriggerTypeKeys, statusKeys} from "../../i18next/keys";
import {
    applicationFeatures,
    jobStates,
    modelTypes,
    SAME_AS_TRIGGERING_DATASET,
    SAME_AS_TRIGGERING_JOB,
    SAME_AS_TRIGGERING_LEGAL_HOLD,
    userSettings
} from "../../utilities/constants";
import {
    ExpandableBacklogOperationTable,
    ExpandableJobParameters,
    ExpandableJobSettings,
    ExpandableWebhookTriggers
} from "./panes/ScheduleConfirmPane";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import WorkflowTemplateModel from "../../models/library/WorkflowTemplateModel";
import {JobStatusIcons} from "../userSettings/JobCardDesigner/helpers";
import {put} from "redux-saga/effects";
import Switch from "../common/Switch/Switch";
import SchedulerModel from "../../models/scheduler/SchedulerModel";
import {userIconModel} from "../../models/generics/IconModel";
import Text from "../common/Text/Text";
import {ExpandableStatusLog} from "../common/Common";
import JobStatus from "../job/JobStatus";
import UserModel from "../../models/user/UserModel";


function ScheduleTablet(props) {
    const {id, workflowTemplateId} = props;
    const {t} = useTranslation(['common']);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(WorkflowTemplateModel.actionCreators.querySettings(workflowTemplateId));
        dispatch(JobScheduleModel.actionCreators.startPollingSettings(id));

        // On exit, stop polling settings
        return () => JobScheduleModel.actionCreators.stopPollingSettings(id);
    }, [id, workflowTemplateId, dispatch]);

    function menuOptionHandler(event) {
        const {value} = event.currentTarget.dataset;

        switchcaseF({
            'edit': () => dispatch(JobScheduleModel.actionCreators.startEdit(id)),
            'toggleEnable': () => dispatch(JobScheduleModel.actionCreators.toggleEnabled(id)),
            'promptDelete': () => dispatch(JobScheduleModel.actionCreators.promptDelete(id))
        })()(value);
    }

    const {
        createdBy,
        createdDate,
        lastModifiedBy,
        lastModifiedDate,
        scheduleName,
        enabled,
        description,
        conditions = {},
        scheduleTriggerType,
        scheduleTrigger = {},
        eventTrigger = {},
        webhookTrigger = {},
        client,
        matter,
        library,
        workflow,
        executionProfile,
        resourcePool,
        priority,
        parameters,
        operations,
        scheduleRuns,
        status,
        isLoading,
        isDisabled
    } = props;


    const [createdByUsername, createdByUserIconName] = UserModel.useUserNameAndIconName(createdBy);
    const [lastModifiedByUsername, lastModifiedByUserIconName] = UserModel.useUserNameAndIconName(lastModifiedBy);

    const createdByIcon = userIconModel.useIcon(createdByUserIconName);
    const lastModifiedByIcon = userIconModel.useIcon(lastModifiedByUserIconName);


    const menuOptions = [
        {name: t('common:option.edit'), value: 'edit', isDisabled: isLoading},
        {name: t(`common:option.${enabled ? 'deactivate' : 'activate'}`), value: 'toggleEnable'},
        {name: t('common:option.delete'), value: 'promptDelete'}
    ];

    const close = () => dispatch(JobScheduleModel.componentActionCreators.updateDisplay({jobScheduleId: null}));
    const sameWorkflowAsJob = library === SAME_AS_TRIGGERING_JOB;
    const canManageDataSets = useSelector(state => state.currentUser.features.includes(applicationFeatures.SCHEDULER_UPLOAD));

    return (
        <Tablet width={'100rem'} height={'65vh'} onClose={close}
            closeButtonAriaLabel={t('jobSchedule:option.closeTablet_name', {name: scheduleName})}
            header={
                <Header id={id} name={scheduleName} enabled={enabled} scheduleTriggerType={scheduleTriggerType}
                    menuOptions={menuOptions} onMenuOptionClick={menuOptionHandler}/>
            }
            body={
                <LoadingWrapper isLoading={isLoading}>

                    {status && (status.code === statusKeys.ERROR || status.code === statusKeys.WARNING) &&
                        <div className="display-item">
                            <ExpandableStatusLog {...status} isDisabled={isDisabled}/>
                        </div>
                    }

                    <div className="display-item">
                        <ExpandableSettings createdBy={createdByUsername} createdByIcon={createdByIcon} createdDate={createdDate}
                            lastModifiedBy={lastModifiedByUsername} lastModifiedByIcon={lastModifiedByIcon} lastModifiedDate={lastModifiedDate}/>
                    </div>

                    {isNotEmptyNorFalsy(description) &&
                    <div className="display-item">
                        <ExpandableLog label={t('common:label.description')} log={description}/>
                    </div>
                    }

                    {isNotEmptyNorFalsy(conditions) &&
                    <div className="display-item">
                        <ExpandableConditions conditions={conditions}/>
                    </div>
                    }

                    <Switch>

                        {scheduleTriggerType === scheduleTriggerTypeKeys.ON_SCHEDULE &&
                        <div className="display-item">
                            <ExpandableTimeTriggers scheduleTrigger={scheduleTrigger}/>
                        </div>
                        }

                        {scheduleTriggerType === scheduleTriggerTypeKeys.ON_JOB_EVENT &&
                        <>
                            <div className="display-item">
                                <ExpandableEvents type={'job'} events={eventTrigger.jobEvents}/>
                            </div>
                            {isNotEmptyNorFalsy(eventTrigger.triggerFilter) &&
                            <div className="display-item">
                                <ExpandablePropertyFilters triggerFilter={eventTrigger.triggerFilter}/>
                            </div>
                            }
                        </>
                        }

                        {scheduleTriggerType === scheduleTriggerTypeKeys.ON_DATASET_EVENT &&
                        <>
                            <div className="display-item">
                                <ExpandableEvents type={'dataset'} events={eventTrigger.datasetEvents}/>
                            </div>
                            {isNotEmptyNorFalsy(eventTrigger.triggerFilter) &&
                            <div className="display-item">
                                <ExpandablePropertyFilters triggerFilter={eventTrigger.triggerFilter}/>
                            </div>
                            }
                        </>
                        }

                        {scheduleTriggerType === scheduleTriggerTypeKeys.ON_LEGAL_HOLD_EVENT &&
                            <>
                                <div className="display-item">
                                    <ExpandableEvents type={'legalHold'} events={eventTrigger.legalHoldEvents}/>
                                </div>
                                {isNotEmptyNorFalsy(eventTrigger.triggerFilter) &&
                                    <div className="display-item">
                                        <ExpandablePropertyFilters triggerFilter={eventTrigger.triggerFilter}/>
                                    </div>
                                }
                            </>
                        }

                        {scheduleTriggerType === scheduleTriggerTypeKeys.ON_WEBHOOK_TRIGGER &&
                            <div className="display-input">
                                <ExpandableWebhookTriggers webhookTrigger={webhookTrigger}/>
                            </div>
                        }
                    </Switch>

                    {
                    <div className="display-item">
                        <ExpandableScheduleRuns runs={scheduleRuns}/>
                    </div>
                    }

                    <div className="display-item">
                        <ExpandableJobSettings client={client} matter={matter} library={library} workflow={workflow} executionProfile={executionProfile}
                            resourcePool={resourcePool} priority={priority}/>
                    </div>

                    {(isNotEmptyNorFalsy(parameters) || sameWorkflowAsJob) &&
                    <div className="display-item">
                        <ExpandableJobParameters parameters={parameters}
                            sameAs={{
                                job: sameWorkflowAsJob
                            }}
                        />
                    </div>
                    }

                    {(isNotEmptyNorFalsy(operations) || sameWorkflowAsJob)&&
                    <div className="display-item">
                        <ExpandableBacklogOperationTable operations={operations}
                            sameAs={{
                                job: sameWorkflowAsJob
                            }}
                        />
                    </div>
                    }
                </LoadingWrapper>
            }
        />
    )
}

function Header(props) {
    const {id, name, enabled, scheduleTriggerType, menuOptions, onMenuOptionClick, isDisabled} = props;
    const {t} = useTranslation(['aria', 'jobSchedule']);

    const {showObjectIds} = useSelector(state => state.userSettingsMap.get(userSettings.TROUBLESHOOT));
    const disabled = isDisabled ? ' is-disabled' : '';
    const title = enabled ? name : `${name} (${t('common:label.inactive')})`;

    const scheduleTypeIconName = getScheduleIconName(scheduleTriggerType);

    return (
        <>
            <div className="tablet-header-left">
                <div className="object-id-subtitle">
                    <h2 className={'subtitle is-bold' + disabled}>
                        {title}
                    </h2>
                    {showObjectIds &&
                        <label className="id-label">
                            {id}
                        </label>
                    }
                </div>
                <div className="display-menu">
                    <MenuDropdown aria-label={t('aria:hiddenAssistText.jobScheduleMenu')} menuOptions={menuOptions}
                        onOptionClick={onMenuOptionClick} id={'scheduleMenuDropdown'} isDisabled={isDisabled}
                    />
                </div>
            </div>

            <div className="tablet-header-right">
                <h2 className={'subtitle is-bold' + disabled} style={{marginRight: '0.5rem'}}>
                    {t('jobSchedule:label.schedule')}
                </h2>
                <span className={'icon is-medium' + disabled}>
                    <img src={icon(scheduleTypeIconName)} alt={t(`aria:hiddenAssistText.${scheduleTypeIconName}Icon`)}/>
                </span>
            </div>
        </>
    )
}

function ExpandableSettings(props) {
    const {createdBy, createdByIcon, createdDate, lastModifiedBy, lastModifiedByIcon, lastModifiedDate} = props;
    const {t} = useTranslation(['jobSchedule', 'common']);

    return (
        <ExpandableContent label={t('common:label.settings')}>
            <div className="settings-table">
                <div className="table-row-group">
                    <SettingsUserIconRow label={t('jobSchedule:label.createdBy')} value={createdBy} icon={createdByIcon}/>
                    <SettingsRowValue label={t('jobSchedule:label.createdDate')} value={createdDate}/>

                    {lastModifiedBy && lastModifiedDate &&
                    <>
                        <SettingsRowSeparator/>

                        <SettingsUserIconRow label={t('jobSchedule:label.lastModifiedBy')} value={lastModifiedBy} icon={lastModifiedByIcon}/>
                        <SettingsRowValue label={t('jobSchedule:label.lastModifiedDate')} value={lastModifiedDate}/>
                    </>
                    }
                </div>
            </div>
        </ExpandableContent>
    )
}

function ExpandableConditions(props) {
    const {conditions: {expireAfter, commenceAfter, skipIfJobsRunning, skipIfJobsQueued, isDisabled}} = props;
    const {t} = useTranslation(['jobSchedule', 'common']);

    return (
        <ExpandableContent label={t('jobSchedule:label.conditions')} isDisabled={isDisabled}>
            {(commenceAfter || expireAfter) &&
            <div className="settings-table">
                <div className="table-row-group">
                    {commenceAfter &&
                    <SettingsRowValue label={t('jobSchedule:condition.commenceAfter')} value={commenceAfter}/>
                    }
                    {expireAfter &&
                    <SettingsRowValue label={t('jobSchedule:condition.expireAfter')} value={expireAfter}/>
                    }
                    <SettingsRowSeparator/>
                </div>
            </div>
            }

            {skipIfJobsRunning &&
            <label className="label">
                {getPluralTranslations(t, 'jobSchedule:condition.skipIfJobsRunning', {count: skipIfJobsRunning})}
            </label>
            }
            {skipIfJobsQueued &&
            <label className="label">
                {getPluralTranslations(t, 'jobSchedule:condition.skipIfJobsQueued', {count: skipIfJobsQueued})}
            </label>
            }
        </ExpandableContent>
    )
}

function ExpandableTimeTriggers(props) {
    const {scheduleTrigger: {addNextJobToStaging, startDate, frequency, recurEvery, recurOnDays}, isDisabled} = props;
    const {t} = useTranslation(['jobSchedule']);

    return (
        <ExpandableContent label={t('jobSchedule:label.schedule')} isDisabled={isDisabled}>
            <div className="settings-table">
                <div className="table-row-group">
                    <SettingsRowValue label={t('jobSchedule:label.frequency')} value={getPluralTranslations(t, `jobSchedule:recurs.${frequency}`, {
                        count: (frequency === scheduleFrequencyKeys.ONE_TIME ? 0 : recurEvery),
                        days: (frequency === scheduleFrequencyKeys.WEEKLY) && recurOnDays.map(day => t(`jobSchedule:recurOnDay.${day}`)).join(', ')
                    })}/>

                    <SettingsRowValue label={t('common:option.start')} value={startDate}/>
                </div>
            </div>
            {addNextJobToStaging &&
                <Text value={t('jobSchedule:label.addNextJobToStaging')} />
            }
        </ExpandableContent>
    )
}

function ExpandableEvents(props) {
    const {type, events, isDisabled} = props;
    const {t} = useTranslation(['jobSchedule', 'job']);

    return (
        <ExpandableContent label={t(`jobSchedule:label.${type}Events`)} isDisabled={isDisabled}>
            {events.map(event =>
                <label key={event} className="label">
                    {t(`${type}:trigger.${event}`)}
                </label>
            )}
        </ExpandableContent>
    )
}

function ExpandablePropertyFilters(props) {
    const {triggerFilter: {priorityAnyOf, submissionMechanisms, ...properties}, isDisabled} = props;
    const {t} = useTranslation(['jobSchedule']);

    return (
        <ExpandableContent label={t('jobSchedule:label.propertyFilters')} isDisabled={isDisabled}>
            <div className="settings-table tight-padding">
                <div className="table-header-group">
                    <div className="table-header">
                        <label className="label is-bold">
                            {t('jobSchedule:label.property')}
                        </label>
                    </div>

                    <div className="table-header">
                        <label className="label is-bold">
                            {t('jobSchedule:label.contains')}
                        </label>
                    </div>
                </div>

                <div className="table-row-group">
                    {submissionMechanisms &&
                    <SubmissionMechanismPropertyFilterRow submissionMechanisms={submissionMechanisms}/>
                    }

                    {getKeys(properties).map(property => {
                        let translationKey = property.slice(0, -8);

                        if (translationKey.startsWith('jobDescription')) {
                            translationKey = `d${translationKey.slice(4)}`;
                        }

                        return (
                            <PropertyFilterRow key={property} label={t(`jobSchedule:filter.${translationKey}`)} value={properties[property]}/>
                        )
                    })}

                    {priorityAnyOf &&
                    <PriorityPropertyFilterRow priorityAnyOf={priorityAnyOf}/>
                    }
                </div>
            </div>
        </ExpandableContent>
    )
}

function SubmissionMechanismPropertyFilterRow(props) {
    const {submissionMechanisms} = props;
    const {t} = useTranslation(['jobSchedule']);

    if (!Array.isArray(submissionMechanisms))
        return null;

    return (
        <PropertyFilterRow label={t('jobSchedule:filter.submissionMechanism')} value={submissionMechanisms.map(m => t(`jobSchedule:submissionMechanism.${m}`)).join(', ')}/>
    )
}

function PriorityPropertyFilterRow(props) {
    const {priorityAnyOf} = props;
    const {t} = useTranslation(['jobSchedule', 'common']);

    if (!Array.isArray(priorityAnyOf))
        return null;

    return (
        <PropertyFilterRow label={t('jobSchedule:filter.submissionMechanism')} value={priorityAnyOf.map(p => t(`common:priority.${p}`)).join(', ')}/>
    )
}

function PropertyFilterRow(props) {
    const {label, value} = props;

    if (!value)
        return null;

    return (
        <div className="settings-row">
            <div className="table-cell" style={{width: '12rem'}}>
                <label className="label">
                    {label}
                </label>
            </div>

            <div className="table-cell">
                <label className="label">
                    {value}
                </label>
            </div>
        </div>
    )
}

function ExpandableScheduleRuns(props) {
    const {t} = useTranslation(['jobSchedule']);
    const {runs} = props;

    const dispatch = useDispatch();

    function navigateToJob(event) {
        const {value: jobId} = event.currentTarget.dataset;
        if (jobId == null)
            return;

        const cbEffect = put(JobScheduleModel.componentActionCreators.updateDisplay({jobScheduleId: null}));
        dispatch(SchedulerModel.actionCreators.updateStateFromPath(modelTypes.jobQueue, {id: jobId, cbEffect}));
    }

    return (
        <ExpandableContent label={t('jobSchedule:label.scheduleRuns')}>
            <div style={{maxHeight: '25rem', overflowY: 'auto'}}>
                <div className="settings-table tight-padding">
                    <div className="table-header-group">
                        <div className="table-header" style={{width: '2rem'}}/>

                        <div className="table-header" style={{width: '10rem'}}>
                            <label className="label is-bold">
                                {t('common:label.status')}
                            </label>
                        </div>

                        <div className="table-header" style={{width: '12rem'}}>
                            <label className="label is-bold">
                                {t('common:label.date')}
                            </label>
                        </div>

                        <div className="table-header">
                            <label className="label is-bold">
                                {t('jobSchedule:label.triggerDescription')}
                            </label>
                        </div>

                        <div className="table-header">
                            <label className="label is-bold">
                                {t('common:label.errors')}
                            </label>
                        </div>
                    </div>

                    <div className="table-row-group">
                        {runs.map(run =>
                            <ScheduleRunRow key={run.id} run={run} navigateToJob={navigateToJob}/>
                        )}
                    </div>
                </div>
            </div>
        </ExpandableContent>
    )
}

function ScheduleRunRow(props) {
    const {id, jobId, date, triggerDescription, jobState, jobPercentageComplete, hasWarnings, hasSoftErrors} = props.run;
    const {t} = useTranslation(['popupInfo']);

    const {
        priority,
        executionProfileId,
        resourcePoolId,
        engineHasWarnings
    } = useSelector(state => state.jobDetailsMap.get(jobId) || {});

    let percentageComplete;
    if (jobStates.running.includes(jobState))
        percentageComplete = jobPercentageComplete;

    const hover = (jobId == null) ? ' no-hover' : '';

    return (
        <div key={id} className={'table-row' + hover} data-value={jobId} onClick={props.navigateToJob} tabIndex={0}>
            <div className="table-cell" style={{paddingTop: '0.45rem'}}>
                <JobStatusIcons executionState={jobState} priority={priority} executionProfileId={executionProfileId} resourcePoolId={resourcePoolId}
                   hasWarnings={hasWarnings} hasSoftErrors={hasSoftErrors} engineHasWarnings={engineHasWarnings} percentageComplete={percentageComplete} hidePercentage size={'small'}/>
            </div>

            <div className="table-cell">
                <label className="label is-wordwrap">
                    <JobStatus executionState={jobState} percentageComplete={percentageComplete}
                        hasWarnings={hasWarnings} hasSoftErrors={hasSoftErrors}/>
                </label>
            </div>

            <div className="table-cell">
                <label className="label">
                    {date}
                </label>
            </div>

            <div className="table-cell">
                <label className="label is-wordwrap">
                    {triggerDescription}
                </label>
            </div>

            <div className="table-cell">
                <label className="label is-wordwrap">
                    {props.run.getErrorMessage(t)}
                </label>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const {jobScheduleDetailsMap, jobScheduleRunsMap, componentStates: {jobScheduleDisplay: {jobScheduleId}}, hasLoaded} = state;
    const {clientDetailsMap, matterDetailsMap, libraryDetailsMap, workflowTemplateDetailsMap, templateOperationsMap, executionProfileDetailsMap, resourcePoolDetailsMap} = state;

    const jobSchedule = jobScheduleDetailsMap.get(jobScheduleId) || {};
    const {scheduleTriggerType, name: scheduleName, clientId, matterId, libraryId, workflowTemplateId, executionProfileId, resourcePoolId, ...otherDetails} = jobSchedule;

    const getName = function(detailsMap, id, sameAsJob) {
        if (sameAsJob) {
            return {
                [scheduleTriggerTypeKeys.ON_JOB_EVENT]: SAME_AS_TRIGGERING_JOB,
                [scheduleTriggerTypeKeys.ON_DATASET_EVENT]: SAME_AS_TRIGGERING_DATASET,
                [scheduleTriggerTypeKeys.ON_LEGAL_HOLD_EVENT]: SAME_AS_TRIGGERING_LEGAL_HOLD
            }[scheduleTriggerType];
        }

        const details = detailsMap.get(id);
        if (details != null)
            return details.name;
    }

    let priority = jobSchedule.priority;
    if (jobSchedule.samePriorityAsTriggerMatter) {
        priority = SAME_AS_TRIGGERING_JOB;
    }

    let parameters = jobSchedule.sessionParameters;
    if (jobSchedule.sameWorkflowTemplateAsTriggerMatter) {
        parameters = SAME_AS_TRIGGERING_JOB;
    }

    const isLoadingSettings = !hasLoaded[jobSchedule.id];
    const isLoadingWorkflow = !jobSchedule.sameWorkflowTemplateAsTriggerMatter && workflowTemplateId != null && !hasLoaded[workflowTemplateId];

    return {
        scheduleTriggerType,
        scheduleName,
        client: getName(clientDetailsMap, clientId, jobSchedule.sameMatterAsTriggerMatter),
        matter: getName(matterDetailsMap, matterId, jobSchedule.sameMatterAsTriggerMatter),
        library: getName(libraryDetailsMap, libraryId, jobSchedule.sameWorkflowTemplateAsTriggerMatter),
        workflow: getName(workflowTemplateDetailsMap, workflowTemplateId, jobSchedule.sameWorkflowTemplateAsTriggerMatter),
        executionProfile: getName(executionProfileDetailsMap, executionProfileId, jobSchedule.sameExecutionProfileAsTriggerMatter),
        resourcePool: getName(resourcePoolDetailsMap, resourcePoolId, jobSchedule.sameResourcePoolIdAsTriggerMatter),
        parameters,
        operations: templateOperationsMap.get(workflowTemplateId),
        isLoading: isLoadingSettings || isLoadingWorkflow,
        ...otherDetails,
        priority,
        workflowTemplateId,
        scheduleRuns: jobScheduleRunsMap.get(jobSchedule.id)
    }
}

export default connect(mapStateToProps)(ScheduleTablet);