import {createSlice} from '@reduxjs/toolkit';
import * as convert from "@netless/xml-js";
import {ParameterType} from "../../utilities/constants";
import {download} from "../../utilities/helperFunctions";
import {createOperation, createParameter, createWorkflow} from "../../utilities/Operations";
import {deepCopy, stringToBool} from "../../../utilities/helperFunctions";

const initialState = {
    libraryId: "c127725b-fe75-4e10-a1c3-990c13bb8e2a",
    workflowName: "",
    operations: [],
    operationsMap: {},
    rfnFile: "xml",
    enabled: true,
    workflowDescription: ""
}

const workflowHandlerSlice = createSlice({
    name: "workflowHandlerSlice",
    initialState,
    reducers: {
        updateWorkflowName(state, action) {
            const {name} = action.payload;
            state.workflowName = name;
            return state;
        },
        addOperation(state, action) {
            const {name, ...attr} = action.payload;

            const operation = createOperation(name);
            for (const [key, value] of Object.entries(attr)) {
                operation[key] = value
            }

            if (attr.index) {
                let index = 0;
                const updatedOperationsMap = {};
                for (const [key, value] of Object.entries(state.operationsMap)) {

                    if (index === attr.index) {
                        updatedOperationsMap[key] = name;
                        index++;
                        updatedOperationsMap[index] = value;
                    } else {
                        updatedOperationsMap[index] = value;
                    }
                    index++;
                }

                state.operations.splice(parseInt(attr.index), 0, operation);
                state.operationsMap = updatedOperationsMap;

            } else {
                const operationIndex = state.operations.length;
                state.operations.push(operation)
                state.operationsMap[operationIndex] = name
            }

            return state;
        },
        removeOperation(state, action) {
            const {operations, parameters} = action.payload;

            for (let i = operations.length-1; i >= 0; i--) {
                const index = operations[i];
                state.operations.splice(parseInt(index), 1)
                delete state.operationsMap[index];
            }

            // Renumber operations map
            let indexKey = 0;
            const updatedOperationsMap = {};
            for (const [key, value] of Object.entries(state.operationsMap)) {
                updatedOperationsMap[indexKey] = value;
                indexKey++;
            }

            state.operationsMap = updatedOperationsMap;

            if (parameters.length > 0) {
                // Constant parameters
                if (state.operations[0]["staticParameters"] !== null) {
                    state.operations[0]["staticParameters"][0]["parameter"] = state.operations[0]["staticParameters"][0]["parameter"].filter(value => {
                        return !parameters.includes(value.name);
                    });
                }
                // Prompted parameters
                if (state.operations[0]["userParameters"] !== null) {
                    state.operations[0]["userParameters"][0]["parameter"] = state.operations[0]["userParameters"][0]["parameter"].filter(value => {
                        return !parameters.includes(value.name);
                    })
                }
            }

            return state;
        },
        updateOperation(state, action) {
            const {operation, ...attr} = action.payload;
            let operationIndex = -1;

            Object.keys(state.operationsMap).forEach((key, index) => {
                if (state.operationsMap[key] === operation) {
                    operationIndex = index;
                }
                return index;
            })

            if (operationIndex !== -1) {
                for (const [key, value] of Object.entries(attr)) {
                    state.operations[operationIndex][key] = value
                }
            }

            return state;
        },
        generateWorkflow(state, action) {
            let workflow = createWorkflow();
            const jsonOptions = {spaces: 2, compact: true};
            workflow.name = state.workflowName;
            workflow.description = state.workflowDescription;
            workflow.operations = {};

            let operations = state.operations.map((value, index) => {
                return convert.json2xml({[state.operationsMap[index]]: (deepCopy(value))}, jsonOptions).replace(/^/gm, "\t")
            });
            workflow.operations = "{workflow_operations}";

            let workflowFile = convert.json2xml({
                "_declaration":{"_attributes":{"version":"1.0","encoding":"utf-8"}},
                workflow: workflow}, jsonOptions);
            workflowFile = workflowFile.replace("{workflow_operations}", "\n" + operations.join("\n") + "\n  ")

            state.rfnFile = workflowFile;
            return state;
        },
        addConfigurationParameter(state, action) {
            const {name, description, regex, friendlyName, value, type, parameterType} = action.payload;
            const parameterFieldType = (type === ParameterType.CONSTANT ? "staticParameters" : "userParameters")

            let parameter = createParameter();
            parameter.name = name;
            parameter.description = description;
            parameter.regex = regex;
            parameter.friendlyName = friendlyName;
            parameter.value = value;
            if (parameterType != null) {
                parameter.parameterType = parameterType;
            }

            if (state.operations[0][parameterFieldType] == null) {
                state.operations[0][parameterFieldType] = []
            }

            if (state.operations[0][parameterFieldType].length === 0) {
                state.operations[0][parameterFieldType][0] = {}
                state.operations[0][parameterFieldType][0]["parameter"] = []
            }

            state.operations[0][parameterFieldType][0]["parameter"].push(parameter)

            return state;
        },
        removeParameter(state, action) {
            const {parameters} = action.payload;

            if (parameters.length > 0) {
                // Constant parameters
                if (state.operations[0]["staticParameters"] !== null) {
                    state.operations[0]["staticParameters"][0]["parameter"] = state.operations[0]["staticParameters"][0]["parameter"].filter(value => {
                        return !parameters.includes(value.name);
                    });
                }
                // Prompted parameters
                if (state.operations[0]["userParameters"] !== null) {
                    state.operations[0]["userParameters"][0]["parameter"] = state.operations[0]["userParameters"][0]["parameter"].filter(value => {
                        return !parameters.includes(value.name);
                    })
                }
            }

            return state;
        },
        removeAllOperations(state, action) {
            state.operations = [];
            state.operationsMap = [];
            return state;
        },
        updateLibraryId(state, action) {
            const {libraryId} = action.payload;

            state.libraryId = libraryId;
            return state;
        },
        downloadWorkflow(state, action) {
            // Download workflow
            download(state.workflowName, state.rfnFile)
        },
        resetWorkflow(state, action) {
            state = {...initialState};
            return state;
        },
        updateWorkflowDescription(state, action) {
            const {description} = action.payload;
            state.workflowDescription = description
            return state;
        },
        updateWorkflowEnabled(state, action) {
            const {enabled} = action.payload;
            state.enabled = stringToBool(enabled);
            return state;
        }
    }
});

export const {
    addOperation,
    updateWorkflowName,
    removeOperation,
    updateOperation,
    generateWorkflow,
    addConfigurationParameter,
    removeParameter,
    removeAllOperations,
    updateLibraryId,
    downloadWorkflow,
    resetWorkflow,
    updateWorkflowDescription,
    updateWorkflowEnabled
} = workflowHandlerSlice.actions;

export default workflowHandlerSlice.reducer;