const React = require('react')
const basePropTypes = require('@uppy/react/lib/propTypes').dashboard
const getHTMLProps = require('@uppy/react/lib/getHTMLProps')
const nonHtmlPropsHaveChanged = require('@uppy/react/lib/nonHtmlPropsHaveChanged')

import DashboardPlugin from "./DashboardPlugin";

const h = React.createElement

/**
 * React Component that renders a Dashboard for an Uppy instance. This component
 * renders the Dashboard inline, so you can put it anywhere you want.
 */

class Dashboard extends React.Component {
    componentDidMount () {
        this.installPlugin()
    }

    componentDidUpdate (prevProps) {
        if (prevProps.uppy !== this.props.uppy) {
            this.uninstallPlugin(prevProps)
            this.installPlugin()
        } else if (nonHtmlPropsHaveChanged(this, prevProps)) {
            const options = { ...this.props, target: this.container }
            delete options.uppy
            this.plugin.setOptions(options)
        }
    }

    componentWillUnmount () {
        this.uninstallPlugin()
    }

    installPlugin () {
        const { uppy } = this.props
        const options = {
            id: 'react:Dashboard',
            ...this.props,
            target: this.container,
        }
        delete options.uppy
        uppy.use(DashboardPlugin, options)

        this.plugin = uppy.getPlugin(options.id)
    }

    uninstallPlugin (props = this.props) {
        const { uppy } = props

        uppy.removePlugin(this.plugin)
    }

    render () {
        // TODO: stop exposing `validProps` as a public property and rename it to `htmlProps`
        this.validProps = getHTMLProps(this.props)
        return h('div', {
            ref: (container) => {
                this.container = container
            },
            ...this.validProps,
        })
    }
}

Dashboard.propTypes = basePropTypes

Dashboard.defaultProps = {
    inline: true,
}

export default Dashboard
