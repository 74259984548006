import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {createFileHandler} from "../../utilities/componentFunctions";
import {includesSome, isNotEmptyNorFalsy} from "../../utilities/helperFunctions";
import Dropdown from "../common/Dropdown/Dropdown";
import ParameterList from "./ParameterList";
import {permissionKeys, statusKeys} from "../../i18next/keys";
import PopupModel from "../../models/scheduler/PopupModel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FileSelectableItem, NameSelectableItem} from "../common/SelectableItem/SelectableItem";
import Text from "../common/Text/Text";
import {useAsyncEffect, useUpdateEffect} from "../../utilities/hooks";
import ThirdPartyUserCredentialForm, {
    useThirdPartySignIn
} from "../thirdPartyService/userCredential/ThirdPartyUserCredentialForm";
import ThirdPartyServiceModel from "../../models/thirdparty/ThirdPartyServiceModel";

export function ParametersPanel(props) {
    const {t} = useTranslation(['workflow']);
    const {
        parameters,
        showRegex,

        loadTsvParameters,
        refreshParameters,
        resetParameters,
        dispatchParameterUpdate,
        updateState,
        updateParameters,
        parameterBlacklist,
        isDisabled
    } = props;

    const libraryFileDetailsMap = useSelector(state => state.libraryFileDetailsMap);
    const thirdPartyServiceDetailsMap = useSelector(state => state.thirdPartyServiceDetailsMap);

    const [isUserCredentialFormActive, setUserCredentialFormActive] = useState();
    const [thirdPartyUserCredentialFormProps, setThirdPartyUserCredentialFormProps] = useState({});
    const [onUserCredentialSignIn] = useThirdPartySignIn({...thirdPartyUserCredentialFormProps, setUserCredentialFormActive});

    // Trigger userCredential SignIn when props change (on non-signedIn thirdPartyService parameter select)
    useUpdateEffect(() => {
        if (thirdPartyUserCredentialFormProps.thirdPartyService != null
            && thirdPartyUserCredentialFormProps.thirdPartyService.authenticationScope === ThirdPartyServiceModel.AuthenticationScope.USER) {
            onUserCredentialSignIn()
        }
    }, [thirdPartyUserCredentialFormProps]);

    // Trigger userCredential verification onMount
    useAsyncEffect(async () => {
        if (Array.isArray(parameters)) {
            for (const [, param] of parameters) {
                if (param.value) {
                    if (param.isThirdPartyServiceParameter()) {
                        const thirdPartyService = thirdPartyServiceDetailsMap.get(param.value);
                        await promptThirdPartyUserCredentialRefresh(thirdPartyService)
                            .catch(console.log);
                    }
                }
            }
        }
    }, []);

    const parameterTsvFileSelect = createFileHandler({
        readAs: 'readAsText',
        onloadend: event => {
            loadTsvParameters(event.target.result);
        }
    });

    const promptThirdPartyUserCredentialRefresh = useThirdPartyParameterSignIn({
        updateState,
        setThirdPartyUserCredentialFormProps
    });

    const updateParameter = useCallback((name, updates) => {
        updateParameters(prevParameters => {
            const params = new Map(prevParameters);
            const parameter = params.get(name);
            const updatedParameter = parameter.duplicate(updates);

            if (parameter.isFileParameter() && !!updates.value) {
                const libraryFile = libraryFileDetailsMap.get(updates.value) || {};
                updatedParameter.valueName = libraryFile.name
            }

            updatedParameter.valid = updatedParameter.validate(updates.value);
            params.set(name, updatedParameter);
            return params;
        });
    }, []);

    const parameterHandler = useCallback(event => {
        const {name, value} = event.target;
        const param = parameters.get(name);

        if (value && param.isThirdPartyServiceParameter()) {
            const thirdPartyService = thirdPartyServiceDetailsMap.get(value);
            promptThirdPartyUserCredentialRefresh(thirdPartyService)
                .then(() => updateParameter(name, {value}))
                .catch(console.log);
        } else {
            updateParameter(name, {value});
        }
    }, [parameters, updateParameter,  promptThirdPartyUserCredentialRefresh, thirdPartyServiceDetailsMap]);


    return (isNotEmptyNorFalsy(parameters) &&
        <>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Text value={t('workflow:label.parameters')} isHeading
                    isDisabled={isDisabled}/>
                <LoadTSVParametersDropdown onSelectParameterFile={parameterTsvFileSelect}
                    onRefreshParameters={refreshParameters} onResetParameters={resetParameters} isDisabled={isDisabled}/>
            </div>

            <ParameterList parameters={parameters} onValueChange={parameterHandler} updateParameter={updateParameter}
                dispatchParameterUpdate={dispatchParameterUpdate} parameterBlacklist={parameterBlacklist}
                showRegex={showRegex} isDisabled={isDisabled} isEditActive
            />

            {thirdPartyUserCredentialFormProps != null && isUserCredentialFormActive &&
                <ThirdPartyUserCredentialForm {...thirdPartyUserCredentialFormProps}
                    onClose={() => setUserCredentialFormActive(false)}/>
            }
        </>
    )
}

function LoadTSVParametersDropdown(props) {
    const {onSelectParameterFile, onResetParameters, onRefreshParameters, isDisabled} = props;
    const {t} = useTranslation(['aria', 'workflow']);

    const items = [];
    if (typeof onRefreshParameters === 'function') {
        items.push({
            name: t('workflow:option.refreshParameters'),
            onClick: onRefreshParameters
        });
    }
    items.push({
        name: t('workflow:option.resetParameters'),
        onClick: onResetParameters
    });

    return (
        <Dropdown aria-label={t('aria:hiddenAssistText.parameterMenu')}
            isIconDropdown isRight isDisabled={isDisabled}
            icon={
                <span className="icon">
                    <FontAwesomeIcon icon={'ellipsis-h'}/>
                </span>
            }
            items={
                <>
                    <FileSelectableItem name={t('workflow:option.loadTSVParameters')} onFileChange={onSelectParameterFile}
                        isNoWrap isDisabled={isDisabled}/>
                    {items.map(item => (
                        <NameSelectableItem key={item.name} item={item} onItemClick={item.onClick}
                            isNoWrap isDisabled={isDisabled}/>
                    ))}
                </>
            }
        />
    );
}

function useThirdPartyParameterSignIn(props) {
    const {t} = useTranslation(['common']);
    const dispatch = useDispatch();
    const {
        setThirdPartyUserCredentialFormProps,
    } = props;

    const showUserWarning = useCallback((thirdPartyService, res, rej) => {
        const buttons = [{
            title: t('common:option.ignore'),
            onClick: res
        }, {
            title: t('common:option.signIn'),
            onClick: () => {
                setThirdPartyUserCredentialFormProps({thirdPartyService, onSuccess: res, onError: rej});
            }
        }];
        dispatch(PopupModel.actionCreators.showWarning({
            info: {
                key: 'thirdPartyServiceUserCredentialWarningPrompt',
                values: {
                    message: thirdPartyService.status.message
                }
            },
            cancelButton: {
                onClick: () => rej('canceled selection')
            },
            buttons
        }));
    }, []);

    const showServiceWarning = useCallback((thirdPartyService, res, rej) => {
        const buttons = [{
            title: t('common:option.ignore'),
            onClick: res
        }];
        dispatch(PopupModel.actionCreators.showWarning({
            info: {
                key: 'thirdPartyServiceUserCredentialWarningPrompt',
                values: {
                    message: thirdPartyService.status.message
                }
            },
            cancelButton: {
                onClick: () => rej('canceled selection')
            },
            buttons
        }));
    }, []);

    return useCallback(thirdPartyService => {
        return new Promise((res, rej) => {
            if (!thirdPartyService) {
                rej('Could not find third-party service');
            } else if (!includesSome(thirdPartyService.userPermissions, [permissionKeys.SUBMIT_JOB, permissionKeys.STAGE_JOB])) {
                rej('User does not have add or stage job permissions for third-party service: ' + thirdPartyService.name);
            } else {
                switch (thirdPartyService.authenticationScope) {
                    case ThirdPartyServiceModel.AuthenticationScope.SERVICE:
                        // Throw error if service not signedIn
                        if (!thirdPartyService.userCredential.signedIn) {
                            dispatch(PopupModel.actionCreators.showError({
                                info: {
                                    key: 'thirdPartyServiceUserCredentialNotSignedIn'
                                }
                            }));
                            rej('User credential is not signed in for third-party service: ' + thirdPartyService.name);
                        } else if ([statusKeys.WARNING, statusKeys.ERROR].includes(thirdPartyService.status.code)) {
                            showServiceWarning(thirdPartyService, res, rej);
                        } else {
                            res();
                        }
                        break;
                    case ThirdPartyServiceModel.AuthenticationScope.USER:
                        // Trigger sign-in
                        if (!thirdPartyService.userCredential.signedIn) {
                            setThirdPartyUserCredentialFormProps({thirdPartyService, onSuccess: res, onError: rej});
                            // Prompt re-sign-in if warning or error
                        } else if ([statusKeys.WARNING, statusKeys.ERROR].includes(thirdPartyService.status.code)) {
                            showUserWarning(thirdPartyService, res, rej);
                        } else {
                            res();
                        }
                        break;
                    default:
                        rej('Unexpected scope: ' + thirdPartyService.authenticationScope)
                }
            }
        });
    }, [showServiceWarning, showUserWarning]);
}

export default ParametersPanel;
