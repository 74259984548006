import React, {useCallback, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import TextArea from '../../common/TextArea/TextArea';
import LoadingWrapper from "../../common/LoadingWrapper/LoadingWrapper";
import {applicationFeatures, componentKeys, jobQueueStates, userSettings} from "../../../utilities/constants";
import JobModel from "../../../models/job/JobModel";
import {
    ExecutionProfileSettingsRow,
    PrioritySettingsRow,
    ResourcePoolSettingsRow
} from "../../schedule/panes/ScheduleJobPane";
import {getKeys, getValues} from "../../../utilities/helperFunctions";
import {createInputHandler} from "../../../utilities/componentFunctions";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import ParametersPanel from "../../parameters/ParametersPanel";
import {permissionKeys} from "../../../i18next/keys";
import Text from "../../common/Text/Text";
import {getStatusIconImgContent} from "../../../utilities/iconResolver";
import {ListDropdown} from "../../common/Dropdown/Dropdown";
import {IconSelectableItem} from "../../common/SelectableItem/SelectableItem";
import {createDropdownHandler} from "../../common/Dropdown/helpers";
import {useUpdateEffect} from "../../../utilities/hooks";

function JobSettingsPane(props) {
    const {t} = useTranslation(['job', 'common']);

    const {
        jobState,
        updateState,

        parameterBlacklist,
        loadTsvParameters,
        resetParameters,
        refreshParameters,
        decryptProtectedParameters,
        dispatchParameterUpdate,
        updateExecutionProfile,
        getMissingRequiredProfiles
    } = props;

    const {
        jobName,
        matterId,
        executionProfileId,
        resourcePoolId,
        priority,
        notes,
        isDisabled,
        workflowXml,
        workflowTemplateId,
        requiredProfiles,
        parameters,
        queueState,

        isLoadingSettings
    } = jobState;

    const isLoading = jobState.isLoading || isLoadingSettings;
    const queueJobDesignerUserSetting = useSelector(state => state.userSettingsMap.get(userSettings.QUEUE_JOB));
    const canAddJob = useSelector(state => state.currentUser.features.includes(applicationFeatures.ADD_JOB)
        && JobModel.getSubmitPermissions(state, {matterId, workflowTemplateId, resourcePoolId, executionProfileId, parameters})
            .includes(permissionKeys.SUBMIT_JOB)
    );

    const userUpdateHandler = useCallback(updates => {
        updateState(prevState => {
            const update = typeof updates === 'function' ? updates(prevState) : updates;
            update.didUserChange = {...prevState.didUserChange};
            if (update.jobName != null)
                update.didUserChange.jobName = true;
            if (update.executionProfileId != null)
                update.didUserChange.executionProfile = true;
            if (update.resourcePoolId != null)
                update.didUserChange.resourcePool = true;
            if (update.priority != null)
                update.didUserChange.priority = true;
            return update;
        });
    }, [updateState]);
    const inputHandler = createInputHandler({
        handler: userUpdateHandler
    });
    const dropdownHandler = createDropdownHandler({
        handler: userUpdateHandler
    });

    // onMount decryptProtectedParameters
    useEffect(() => {
        if (!isLoading && typeof decryptProtectedParameters === 'function') {
            decryptProtectedParameters();
        }
    }, [isLoading, decryptProtectedParameters]);

    // Force STAGING if cant submit job
    useEffect(() => {
        if (!canAddJob && queueState !== jobQueueStates.STAGING) {
            updateState({queueState: jobQueueStates.STAGING});
        }
    }, [canAddJob, queueState]);

    useUpdateEffect(() => {
        if (typeof refreshParameters === 'function') {
            refreshParameters();
        }
    }, [queueState]);

    const updateParameters = useCallback(updates => {
        updateState(prevState => ({
            parameters: updates(prevState.parameters)
        }));
    }, [updateState]);

    const components = {
        [componentKeys.NOTES]: <TextArea label={t('job:label.notes')} name={'notes'} value={notes}
            onChange={inputHandler} isDisabled={isDisabled}/>,
        [componentKeys.PARAMETERS_PANEL]: <ParametersPanel parameters={parameters} loadTsvParameters={loadTsvParameters}
            refreshParameters={refreshParameters} resetParameters={resetParameters} updateState={updateState} updateParameters={updateParameters}
            parameterBlacklist={parameterBlacklist} dispatchParameterUpdate={dispatchParameterUpdate} isDisabled={isDisabled}/>
    };

    const keys = getKeys(components);
    const componentList = queueJobDesignerUserSetting.componentOrder.map(k => {
        let key, index;
        if ((index = keys.indexOf(k)) > -1) {
            key = keys.splice(index, 1)[0];
        } else {
            key = keys.shift();
        }
        return {
            key,
            component: components[key]
        }
    });

    const executionProfileMissingRequiredProfiles = requiredProfiles != null && requiredProfiles.some(profile => profile.missing);
    function executionProfileDropdownHandler (event) {
        if (typeof updateExecutionProfile === 'function') {
            const {dataset: {value}} = event.currentTarget;
            updateExecutionProfile(value, workflowXml, workflowTemplateId);
        } else {
            dropdownHandler(event);
        }
    }

    useEffect(() => {
        if (typeof getMissingRequiredProfiles === 'function') {
            getMissingRequiredProfiles(executionProfileId, workflowXml, workflowTemplateId);
        }
    }, [workflowXml, workflowTemplateId, executionProfileId])

    return (
        <div className="pane-margin">
            <LoadingWrapper isLoading={isLoading}>
                <div className="settings-table display-input">
                    <div className="table-row-group">
                        <ExecutionProfileSettingsRow executionProfileId={executionProfileId} onItemSelect={executionProfileDropdownHandler}
                            missingRequiredFiles={executionProfileMissingRequiredProfiles} isDisabled={isDisabled}/>

                        <ResourcePoolSettingsRow resourcePoolId={resourcePoolId}
                            onItemSelect={dropdownHandler} isDisabled={isDisabled}/>

                        <PrioritySettingsRow priority={priority}
                            onItemSelect={dropdownHandler} isDisabled={isDisabled}/>

                        <QueueStateSettingsRow queueState={queueState}
                            onItemSelect={dropdownHandler} isDisabled={isDisabled}/>
                    </div>
                </div>

                <div className="display-input">
                    <HTMLTextInput label={t('common:label.name')} name={'jobName'} isRequired
                        value={jobName} onChange={inputHandler} isDisabled={isDisabled}/>
                </div>

                {componentList.map(({key, component}) =>
                    <div className="display-input" key={key}>
                        {component}
                    </div>
                )}
            </LoadingWrapper>
        </div>
    )
}

function QueueStateSettingsRow(props) {
    const {queueState, onItemSelect, isDisabled} = props;
    const {t} = useTranslation(['job']);

    return (
        <div className="settings-row">
            <div className="settings-label-cell no-stretch">
                <Text id="queueJob:queueStateLabel" value={`${t('job:label.queue')}:`}
                    isDisabled={isDisabled}/>
            </div>

            <div className="settings-value-cell" style={{display: 'flex', marginLeft: '1rem'}}>
                <QueueStateDropdown queueState={queueState} onItemSelect={onItemSelect}
                    aria-labelledby="queueJob:queueStateLabel" isDisabled={isDisabled}/>
            </div>
        </div>
    )
}

function QueueStateDropdown(props) {
    const {t} = useTranslation(['job']);
    const {
        queueState,
        ...rest
    } = props;

    const stagingIconImg = getStatusIconImgContent('statusPaused');
    let icon;
    if (queueState === jobQueueStates.STAGING) {
        icon = (
            <span className="icon is-small">
                {stagingIconImg}
            </span>
        );
    }

    const items = getValues(jobQueueStates)
        .map(state => {
            const item = {name: t(`job:queueState.${state}`), value: state}
            if (state === jobQueueStates.STAGING) {
                item.icon = stagingIconImg;
            }
            return item;
        });

    return (
        <ListDropdown id={'queueStateDropdown'} name={'queueState'} {...rest} value={queueState}
            icon={icon} items={items} ItemComponent={IconSelectableItem}/>
    )
}

export default JobSettingsPane;
