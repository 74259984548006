import React from 'react';
import HTMLTextInput from "../../../../components/common/HTMLTextInput/HTMLTextInput";

export function DateTimeInput (props) {
    const {name, stateName, dateTimeValue, isDisabled, isRequired, onChange, label} = props;

    const disabled = isDisabled ? ' is-disabled' : '';
    const localeDate = dateTimeValue.split("T")

    const time = {
        filterAfter: "T00:00:00.000Z",
        filterBefore: "T59:59:59.999Z"
    }

    const dateTime = {
        date: localeDate[0]
    }

    const updateDate = event => {
        const {name, value} = event.currentTarget;
        const date = value +""+ time[props.name];
        onChange({target: {value: date, name: stateName}});
    }

    return (
        <div style={{display: 'flex'}}>
            <HTMLTextInput name={`${name}Date`} type={'date'} value={dateTime.date} onChange={updateDate} isDisabled={isDisabled} style={{marginRight: '0.25rem'}} isRequired={isRequired}/>
        </div>
    )
}