import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RestrictedLibraryPanel} from "../../panels/LibraryPanel";
import {RestrictedWorkflowTemplatePanel} from "../../panels/WorkflowTemplatePanel";
import {scheduleTriggerTypeKeys} from "../../../i18next/keys";
import JobScheduleModel from "../../../models/job/JobScheduleModel";

function ScheduleWorkflowPane() {
    const dispatch = useDispatch();

    const {
        matterId,
        libraryId,
        workflowTemplateId,
        scheduleTriggerType,
        isDisabled
    } = useSelector(state => state.componentStates.jobScheduleForm);

    useEffect(() => {
        dispatch(JobScheduleModel.actionCreators.prepareGetOperationsAndParametersSaga());
    },[]);

    function updateState(updates) {
        dispatch(JobScheduleModel.componentActionCreators.updateForm(updates));
    }

    return (
        <div className="selectPane">
            <RestrictedLibraryPanel libraryId={libraryId} updateState={updateState} isDisabled={isDisabled}
                canSetSameAs={{
                    job: scheduleTriggerType === scheduleTriggerTypeKeys.ON_JOB_EVENT
                }}
            />

            <RestrictedWorkflowTemplatePanel matterId={matterId} libraryId={libraryId} workflowTemplateId={workflowTemplateId}
                updateState={updateState} isDisabled={isDisabled}
                canSetSameAs={{
                    job: scheduleTriggerType === scheduleTriggerTypeKeys.ON_JOB_EVENT
                }}
            />
        </div>
    )
}

export default ScheduleWorkflowPane;
