import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {
    initialSelectedState,
    useClearSelectedEffect,
    useTabNavigateEffect,
    useValueSelectHandler
} from "../../../utilities/hooks";
import LegalHoldModel from "../../../models/legalhold/LegalHoldModel";
import {createInputHandler, createStateHandler, createUpdateHandler} from "../../../utilities/componentFunctions";
import {createToggleHandler} from "../../common/Checkbox/helpers";
import {createDropdownHandler} from "../../common/Dropdown/helpers";
import TriggerConfiguration from "../../../models/legalhold/TriggerConfiguration";
import {
    buildClassName,
    getNewArrayWithUpdatedValue,
    getParentDatasetAttr,
    isNotEmptyNorFalsy,
    objectTruthyValues
} from "../../../utilities/helperFunctions";
import {legalHoldStateKeys, permissionKeys} from "../../../i18next/keys";
import {AddRemoveButtons} from "../../common/Button/Button";
import Text from "../../common/Text/Text";
import TriggerConfigurationTable from "./TriggerConfigurationTable";
import Checkbox from "../../common/Checkbox/Checkbox";

function HoldTriggerConfigurationPane(props) {
    const {t} = useTranslation(['legalHold', 'common']);
    const dispatch = useDispatch();

    const {
        isReadOnly
    } = props;

    const {
        noticeTemplateItems,
        noticeTemplateItemKeyToSurveyFormOptions,

        state,
        silent,
        triggerConfigurations,
        useTriggerConfigurations,

        userPermissions,
        isDisabled: isFormDisabled
    } = useSelector(state => state.componentStates.legalHoldForm);

    const containerRef = useRef();
    const [selected, setSelected] = useState(initialSelectedState);
    const [lastAddedIndex, setLastAddedIndex] = useState();

    const valueSelectHandler = useValueSelectHandler({setSelected});
    useClearSelectedEffect({containerRef, setSelected});
    useTabNavigateEffect({containerRef});

    useEffect(() => {
        dispatch(LegalHoldModel.actionCreators.prepareTriggerConfigurationSettings())
    }, [])

    const reduxHandler = createStateHandler({
        updateState: updates => dispatch(LegalHoldModel.componentActionCreators.updateForm(updates))
    });

    const triggerConfigurationsHandler = createUpdateHandler({
        handler: reduxHandler,
        name: 'triggerConfigurations'
    });
    const triggerResponsesHandler = createUpdateHandler({
        handler,
        name: 'triggerResponses'
    });
    const inputHandler = createInputHandler({
        handler
    });
    const toggleHandler = createToggleHandler({
        handler
    });
    const dropdownHandler = createDropdownHandler({
        handler
    });
    const triggerConfigurationToggleHandler = createToggleHandler({
        handler: reduxHandler
    })

    // Add TriggerConfiguration Redux Model
    function addRow () {
        triggerConfigurationsHandler([
            ...triggerConfigurations,
            new TriggerConfiguration({enabled: true})
        ]);
        setLastAddedIndex(triggerConfigurations.length);
    }

    function removeSelectedRows() {
        const selectedRowIndices = objectTruthyValues(selected.values);
        triggerConfigurationsHandler(
          triggerConfigurations.filter((config, index) => config.triggered || !selectedRowIndices.includes(index.toString()))
        );

        setSelected(prevSelected => ({
            ...prevSelected,
            values: {},
            lastSelectedValue: null
        }));
    }

    function noticeConfigurationTemplateDropdownHandler(event) {
        const {dataset: {value}} = event.currentTarget;
        const updates = {
            noticeTemplateIdAndVersion: value,
            optionKey: '',
            triggerResponses: {}
        };

        handler(updates, event);
    }

    function noticeQuestionDropdownHandler(event) {
        const {dataset: {value}} = event.currentTarget;
        const updates = {
            optionKey: value,
            triggerResponses: {}
        };

        handler(updates, event);
    }

    function handler(updates, event) {
        const index = getParentDatasetAttr(event.target || event.currentTarget, 'index');
        const newConfiguration = triggerConfigurations[index].duplicate(updates);

        triggerConfigurationsHandler(getNewArrayWithUpdatedValue(triggerConfigurations, newConfiguration, index));
    }

    const tableClassName = buildClassName(
        'display-table',
        'align-top',
        isReadOnly && 'is-readonly',
    );

    const isDraft = state === legalHoldStateKeys.DRAFT;
    const isActive = state === legalHoldStateKeys.ACTIVE;
    const cantModify = userPermissions != null && !userPermissions.includes(permissionKeys.MODIFY);

    const isDisabled = isFormDisabled || (!isDraft && !isActive) || cantModify;


    const disableSelection = isDisabled || isReadOnly;
    const canRemove = objectTruthyValues(selected.values).length > 0;

    return (
        <div className="pane-margin">
            <div style={{marginTop: '0.75rem'}}>
                <Checkbox label={t('legalHold:option.useTriggerConfigurations')} name={'useTriggerConfigurations'} checked={useTriggerConfigurations}
                          onClick={triggerConfigurationToggleHandler} isDisabled={isDisabled}/>
            </div>

            {useTriggerConfigurations &&
            <div className="display-input" ref={containerRef} id="triggerConfigurationsTable">
                <AddRemoveButtons label={t('legalHold:label.triggerConfigurations')} onAddClick={addRow} noPadding
                    ariaLabelKey={'LegalHoldJobTrigger'} onRemoveClick={removeSelectedRows}
                    isRemoveDisabled={!canRemove} isReadOnly={isReadOnly} isDisabled={isDisabled}
                />

                <div className={tableClassName}>
                    <div className="table-header-group">
                        <div className="table-header"/>
                    </div>

                    {isNotEmptyNorFalsy(triggerConfigurations) ?
                        <div className="table-row-group initial-bg-color">
                            {triggerConfigurations.map((config, index) => {
                                const _disableSelection = !!(config.triggered || disableSelection);

                                const isSelected = !_disableSelection && selected.values[index];
                                const _tableRowClassName = buildClassName(
                                    'table-row',
                                    _disableSelection && 'no-hover'
                                );

                                const _lastAddedIndex = index === lastAddedIndex ? index : null;
                                const _onClick = _disableSelection ? null : valueSelectHandler

                                return (
                                    <div className={_tableRowClassName} key={index}>
                                        <div className="table-cell no-padding no-border">
                                            <TriggerConfigurationTable id={`triggerConfigurationRow_${index}_`} data-index={index} onClick={_onClick}
                                                lastAddedIndex={_lastAddedIndex} configuration={config} noticeTemplateItems={noticeTemplateItems}
                                                surveyFormOptions={noticeTemplateItemKeyToSurveyFormOptions[config.noticeTemplateIdAndVersion]}
                                                noticeConfigurationTemplateDropdownHandler={noticeConfigurationTemplateDropdownHandler}
                                                noticeQuestionDropdownHandler={noticeQuestionDropdownHandler} triggerResponsesHandler={triggerResponsesHandler}
                                                inputHandler={inputHandler} toggleHandler={toggleHandler} dropdownHandler={dropdownHandler}
                                                isSilent={silent} isSelected={isSelected} isDisabled={isDisabled} index={index} state={state}/>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        :
                        <Text value={t('legalHold:message.noLinkedJobs')} style={{paddingTop: '2.5rem'}}
                               isItalic isCenter/>
                    }
                </div>
            </div>
            }
        </div>
    )
}

export default HoldTriggerConfigurationPane;