import React from 'react';
import './CustomizableCard.css';
import HighlightText from "../../userSettings/HighlightOption/HighlightText";
import Text from "../Text/Text";

const CustomizableCard = props => {
    const {value, onClick, isActive, topLeft, topRight, middle, bottomLeft, bottomRight, ...attr} = props;
    const active = isActive ? ' is-active' : '';

    function onKeyPress(e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            onClick(e);
        }
    }

    return (
        <article className={'customizable-card' + active} data-value={value} onKeyDown={onKeyPress}
            role="listitem" onClick={onClick} tabIndex={attr.isDisabled ? -1 : 0} {...attr}
        >
            {(topLeft || topRight) &&
                <section className="customizable-top">
                    <span className="customizable-left">
                        {topLeft}
                    </span>
                    <span className="customizable-right">
                        {topRight}
                    </span>
                </section>
            }

            <section className="customizable-middle">
                {middle && middle.map(item => item)}
            </section>

            {(bottomLeft || bottomRight) &&
                <section className="customizable-bottom">
                    <span className="customizable-left">
                        {bottomLeft}
                    </span>
                    <span className="customizable-right">
                        {bottomRight}
                    </span>
                </section>
            }
        </article>
    );
};

export function TextItem(props) {
    const {value, size = 'small', ...attr} = props;

    return (
        <Text ElementTag="span" className="text-item"
            size={size} isEllipsis {...attr}
        >
            <HighlightText text={value}/>
            {/*Added <br/> to take force empty space*/}
            <br/>
        </Text>
    );
}

export const IconItem = props => {
    const {value, size = 'small', children} = props;

    return (
        <span className={`icon-item icon is-${size.toLowerCase()}`}>
            {value}
            {children}
        </span>
    )
}

export default CustomizableCard;