import React from 'react';
import './NavBar.css';
import UserMenu from "./UserMenu";
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {Link} from 'react-router-dom';
import {details, detailsKeyToSettingsKey, routes} from '../../../utilities/constants';
import AppsMenu from "./AppsMenu";
import JobModel from "../../../models/job/JobModel";
import {jobPageViewKeys, legalHoldPageViewKeys, statusKeys} from "../../../i18next/keys";
import LegalHoldModel from "../../../models/legalhold/LegalHoldModel";
import {buildClassName, getKeys, getValues} from "../../../utilities/helperFunctions";
import {getDefaultRoute, getUserCanViewRoutePrivileges} from '../../../reselect/selectors';
import {selectResourceStatuses} from "../pages/SettingsPage";
import {SchedulerSaga} from "../../../models/scheduler/SchedulerModel";
import JobScheduleModel from "../../../models/job/JobScheduleModel";
import ClientModel from "../../../models/client/ClientModel";
import NavBarLinkMenu, {NavBarLinkMenuItem} from "./NavBarLinkMenu";
import NavBarLinkItem from "./NavBarLinkItem";
import settingsIcon from "../../../resources/images/neo/fill-white/settings.svg";


function NavBar(props) {
    const {t} = useTranslation(['aria', 'scheduler']);
    const dispatch = useDispatch();

    const {
        currentRoute
    } = props;

    const {
        schedulerDetails,
        currentUser,
        jobPageActiveView,
        legalHoldPageActiveView
    } = useSelector(state => {
        const {schedulerDetails, componentStates: {jobPage, legalHoldPage}} = state;
        return {
            schedulerDetails,
            currentUser: state.currentUser,
            jobPageActiveView: jobPage.activeView,
            legalHoldPageActiveView: legalHoldPage.activeView,
        }
    }, shallowEqual);

    const schedulesStatusIcon = useSelector(selectSchedulesStatusIcon);
    const legalHoldsStatusIcon = useSelector(selectLegalHoldsStatusIcon);
    const clientsStatusIcon = useSelector(selectClientsStatusIcon);
    const settingsStatusIcon = useSelector(selectResourcesStatusIcons);

    function onJobViewClick(event) {
        const {value} = event.currentTarget.dataset;
        dispatch(JobModel.componentActionCreators.updatePage({activeView: value}));
    }

    function onLegalHoldViewClick(event) {
        const {value} = event.currentTarget.dataset;
        dispatch(LegalHoldModel.componentActionCreators.updatePage({activeView: value}));
    }

    const isUserLoggedOn = useSelector(state => state.currentUser.isAuthenticated && state.schedulerDetails.isServerScheduler);
    const canViewRoute = useSelector(getUserCanViewRoutePrivileges);
    const defaultRoute = useSelector(getDefaultRoute) || routes.LOGIN;
    const onLoginPage = currentRoute === routes.LOGIN;


    const logoClassName = buildClassName(
        'navbar-logo',
        onLoginPage && 'logo-disabled'
    );

    return (
        <nav className="navbar" aria-label={t('main navigation')}>
            <section className="navbar-left">
                {isUserLoggedOn &&
                    <div className="navbar-app-menu">
                        <div className="navbar-app-menu-dropdown">
                            <AppsMenu platformApps={schedulerDetails.neoPlatformApps}
                                defaultRoute={defaultRoute}/>

                            <span className="navbar-selected-bar"/>
                        </div>
                        <span className="navbar-separator"/>
                    </div>
                }

                <Link className={logoClassName} to={defaultRoute}
                    style={isUserLoggedOn ? {} : {paddingLeft: '4px'}}>
                    <p className="navbar-label is-bold">
                        Automate
                    </p>
                </Link>
            </section>

            {isUserLoggedOn &&
            <section className="navbar-right">

                {canViewRoute.jobs &&
                    <NavBarLinkMenu id="jobsLinkMenu" label={t('scheduler:link.jobs')}
                        statusIcons={schedulesStatusIcon} isActive={currentRoute === routes.JOBS}
                    >
                        <NavBarLinkMenuItem text={t(`scheduler:jobPageView.${jobPageViewKeys.QUEUE}`)}
                            to={routes.JOBS} onClick={onJobViewClick} data-value={jobPageViewKeys.QUEUE}
                            isActive={currentRoute === routes.JOBS && jobPageActiveView === jobPageViewKeys.QUEUE}/>

                        {canViewRoute.purview &&
                            <NavBarLinkMenuItem text={t(`scheduler:jobPageView.${jobPageViewKeys.PURVIEW}`)}
                                to={routes.JOBS} onClick={onJobViewClick} data-value={jobPageViewKeys.PURVIEW}
                                isActive={currentRoute === routes.JOBS && jobPageActiveView === jobPageViewKeys.PURVIEW}/>
                        }
                        {canViewRoute.vault &&
                            <NavBarLinkMenuItem text={t(`scheduler:jobPageView.${jobPageViewKeys.VAULT}`)}
                                to={routes.JOBS} onClick={onJobViewClick} data-value={jobPageViewKeys.VAULT}
                                isActive={currentRoute === routes.JOBS && jobPageActiveView === jobPageViewKeys.VAULT}/>
                        }
                        {canViewRoute.schedules &&
                            <NavBarLinkMenuItem text={t(`scheduler:jobPageView.${jobPageViewKeys.SCHEDULE}`)} to={routes.JOBS}
                                statusIcons={schedulesStatusIcon} onClick={onJobViewClick} data-value={jobPageViewKeys.SCHEDULE}
                                isActive={currentRoute === routes.JOBS && jobPageActiveView === jobPageViewKeys.SCHEDULE}/>
                        }
                        <NavBarLinkMenuItem text={t(`scheduler:jobPageView.${jobPageViewKeys.ARCHIVE}`)}
                            to={routes.JOBS} onClick={onJobViewClick} data-value={jobPageViewKeys.ARCHIVE}
                            isActive={currentRoute === routes.JOBS && jobPageActiveView === jobPageViewKeys.ARCHIVE}/>

                    </NavBarLinkMenu>
                }

                {canViewRoute.legalHold &&
                    <NavBarLinkMenu id="legalHoldLinkMenu" label={t('scheduler:link.legalHold')} statusIcons={legalHoldsStatusIcon}
                        isActive={currentRoute === routes.LEGAL_HOLD}
                    >
                        <NavBarLinkMenuItem text={t(`scheduler:legalHoldPageView.${legalHoldPageViewKeys.OVERVIEW}`)}
                            to={routes.LEGAL_HOLD} onClick={onLegalHoldViewClick} data-value={legalHoldPageViewKeys.OVERVIEW}
                            isActive={currentRoute === routes.LEGAL_HOLD && legalHoldPageActiveView === legalHoldPageViewKeys.OVERVIEW}/>

                        <NavBarLinkMenuItem text={t(`scheduler:legalHoldPageView.${legalHoldPageViewKeys.MATTERS}`)} to={routes.LEGAL_HOLD}
                            statusIcons={legalHoldsStatusIcon} onClick={onLegalHoldViewClick} data-value={legalHoldPageViewKeys.MATTERS}
                            isActive={currentRoute === routes.LEGAL_HOLD && legalHoldPageActiveView === legalHoldPageViewKeys.MATTERS}/>

                        {canViewRoute.legalHoldNotices &&
                            <NavBarLinkMenuItem text={t(`scheduler:legalHoldPageView.${legalHoldPageViewKeys.NOTICES}`)}
                                to={routes.LEGAL_HOLD} onClick={onLegalHoldViewClick} data-value={legalHoldPageViewKeys.NOTICES}
                                isActive={currentRoute === routes.LEGAL_HOLD && legalHoldPageActiveView === legalHoldPageViewKeys.NOTICES}/>
                        }
                    </NavBarLinkMenu>
                }

                {canViewRoute.clients &&
                    <NavBarLinkItem id="clientsLinkItem" to={routes.CLIENTS} isActive={currentRoute === routes.CLIENTS}
                        label={t('scheduler:link.clients')} statusIcons={clientsStatusIcon}
                    />
                }
                {canViewRoute.libraries &&
                    <NavBarLinkItem id="librariesLinkItem" to={routes.LIBRARY} isActive={currentRoute === routes.LIBRARY}
                        label={t('scheduler:link.libraries')}
                    />
                }

                <div className="navbar-right no-gap">
                    {canViewRoute.settings &&
                        <NavBarLinkItem id="settingsLinkItem" to={routes.SETTINGS} isActive={currentRoute === routes.SETTINGS}
                            ariaKey={'settings'} icon={settingsIcon} statusIcons={settingsStatusIcon}
                        />
                    }

                    <UserMenu currentUser={currentUser} hideLogoutButton={schedulerDetails.isRelativityApplication}
                        externalApps={schedulerDetails.neoExternalApps}/>
                </div>
            </section>
            }
        </nav>
    );
}

export function updateStatusToIcon(statuses, statusToIcon) {
    for (const status of getValues(statuses)) {
        if (statusToIcon.warning && statusToIcon.error && statusToIcon.info) break;
        if (status == null) continue;

        if (status.code === statusKeys.WARNING) {
            statusToIcon.warning = 'statusWarning';
        }
        if (status.code === statusKeys.ERROR) {
            statusToIcon.error = 'statusError';
        }
        if (status.code === statusKeys.INFO) {
            statusToIcon.info = 'statusInfo';
        }
    }
}

function selectResourcesStatusIcons(state) {
    const statusToIcon = {}
    for (const key of getKeys(state.schedulerDetails.resourcesStatus)) {
        if (statusToIcon.warning && statusToIcon.error) break;

        const settingsKey = detailsKeyToSettingsKey[key];
        if (settingsKey != null) {
            const statuses = selectResourceStatuses(state, settingsKey);
            updateStatusToIcon(statuses, statusToIcon);
        }
    }

    return selectStatusIcon(statusToIcon);
}

function selectLegalHoldsStatusIcon(state) {
    let statuses;
    if (state.hasLoaded[details.LEGAL_HOLDS]) {
        const useLegalHoldsDetailsMap = LegalHoldModel.lastUpdated > SchedulerSaga.lastResourceStatusUpdate
            || (legalHoldPageViewKeys.MATTERS === state.componentStates.legalHoldPage.activeView && window.location.hash.includes(routes.LEGAL_HOLD));
        if (useLegalHoldsDetailsMap) {
            statuses = getValues(state.legalHoldDetailsMap).flatMap(legalHold => [legalHold.status, legalHold.emailCounter?.status]);
        }
    }
    if (statuses == null) {
        statuses = state.schedulerDetails.resourcesStatus[details.LEGAL_HOLDS];
    }

    if (statuses != null) {
        const statusToIcon = {}
        updateStatusToIcon(statuses, statusToIcon);
        return selectStatusIcon(statusToIcon);
    }
}

function selectSchedulesStatusIcon(state) {
    let statuses;
    if (state.hasLoaded[details.SCHEDULE_JOBS]) {
        const useSchedulesDetailsMap = JobScheduleModel.lastUpdated > SchedulerSaga.lastResourceStatusUpdate
            || (jobPageViewKeys.SCHEDULE === state.componentStates.jobPage.activeView && window.location.hash.includes(routes.JOBS));
        if (useSchedulesDetailsMap) {
            statuses = getValues(state.jobScheduleDetailsMap).map(schedule => schedule.status);
        }
    }
    if (statuses == null) {
        statuses = state.schedulerDetails.resourcesStatus[details.SCHEDULE_JOBS];
    }

    if (statuses != null) {
        const statusToIcon = {}
        updateStatusToIcon(statuses, statusToIcon);
        return selectStatusIcon(statusToIcon);
    }
}

function selectClientsStatusIcon(state) {
    let statuses;
    if (state.hasLoaded[details.CLIENTS]) {
        const useClientDetailsMap = ClientModel.lastUpdated > SchedulerSaga.lastResourceStatusUpdate
            || (window.location.hash.includes(routes.CLIENTS));

        if (useClientDetailsMap) {
            statuses = getValues(state.clientDetailsMap).map(client => client.status);
        }
    }

    if (statuses == null) {
        statuses = state.schedulerDetails.resourcesStatus[details.CLIENTS];
    }

    if (statuses != null) {
        const statusToIcon = {}
        updateStatusToIcon(statuses, statusToIcon);
        return selectStatusIcon(statusToIcon);
    }
}

export function selectStatusIcon(statusToIcon) {
    if (statusToIcon.error) {
        return [statusToIcon.error];
    }
    if (statusToIcon.warning) {
        return [statusToIcon.warning];
    }
    if (statusToIcon.info) {
        return [statusToIcon.info];
    }
}

export default NavBar;
