import FormBuilderComponentConfiguration from "../../../models/workflowbuilder/FormBuilderComponentConfiguration";
import Text from "../../common/Text/Text";
import {buildClassName, getEntries, isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import FieldComponent from "./FieldComponent";
import FieldConfigurationWrapper from "./FieldConfigurationWrapper";
import {useTranslation} from "react-i18next";
import React from "react";
import FormBuilderConfiguration from "../../../models/workflowbuilder/FormBuilderConfiguration";
import {HelpButton} from "../../common/Button/Button";

const componentsOverrideRowLabel = new Set([
    FormBuilderComponentConfiguration.ComponentType.CHECKBOX,
    FormBuilderComponentConfiguration.ComponentType.RADIO_BUTTON
]);

export function useCommonFieldRowConfiguration(options) {
    const {fieldConfigurations, configuration, label, state} = options;

    const filteredComponentConfigurations = configuration.componentConfigurations.filter(config => config.isVisible(state, fieldConfigurations));
    const hasInput = !!filteredComponentConfigurations.find(config => config.componentType === FormBuilderComponentConfiguration.ComponentType.INPUT);

    const firstComponentConfiguration = configuration.componentConfigurations[0];
    const showRowLabel = label && !componentsOverrideRowLabel.has(firstComponentConfiguration.componentType)
        && !(firstComponentConfiguration.positionedLabel.top || firstComponentConfiguration.positionedLabel.left)

    return {
        filteredComponentConfigurations,
        showRowLabel,
        hasInput
    };
}

export function useCommonFieldRowCellConfiguration(options) {
    const {filteredComponentConfigurations, configuration, index, showRowLabel, hasInput} = options;

    // Expand last input column or last column in row
    const isInput = configuration.componentType === FormBuilderComponentConfiguration.ComponentType.INPUT;
    const hasMultipleInputs = filteredComponentConfigurations.filter(config => config.componentType === FormBuilderComponentConfiguration.ComponentType.INPUT).length > 1;
    const isLastIndex = (index + 1) === filteredComponentConfigurations.length;
    const expandCol = isInput || (!hasInput && isLastIndex);

    let colSpan = (expandCol && !hasMultipleInputs) ? 100 : 1;
    if (!hasMultipleInputs && isInput && isLastIndex) {
        colSpan = 400;
    }

    const nextComponent = filteredComponentConfigurations[index + 1];
    const addColonToLabel = !showRowLabel && componentsOverrideRowLabel.has(configuration.componentType)
        && nextComponent != null && !componentsOverrideRowLabel.has(nextComponent.componentType) && !nextComponent.positionedLabel.left;

    return {
        expandCol,
        colSpan,
        addColonToLabel
    };
}

function FieldRow(props) {
    const {
        label,
        isDisabled
    } = props;

    const commonFieldRowConfiguration = useCommonFieldRowConfiguration(props);
    const {
        showRowLabel,
        filteredComponentConfigurations
    } = commonFieldRowConfiguration;

    const firstComponentHasLabel = componentsOverrideRowLabel.has(filteredComponentConfigurations[0]?.componentType);
    let ariaLabelledBy;
    if (showRowLabel) {
        ariaLabelledBy = props.configuration.label;
    } else if (firstComponentHasLabel) {
        ariaLabelledBy = FormBuilderComponentConfiguration.getId(filteredComponentConfigurations[0], props.parentId);
    }

    return (
        <tr className="table-row">
            {showRowLabel &&
                <td className="table-cell no-stretch align-top" style={{lineHeight: '2.2'}}>
                    <Text id={props.configuration.label} value={`${label}:`} isDisabled={isDisabled}/>
                </td>
            }

            {filteredComponentConfigurations.map((config, index) =>
                <FieldRowCell key={config.name} aria-labelledby={ariaLabelledBy} {...commonFieldRowConfiguration}
                    {...props} configuration={config} index={index} isDisabled={isDisabled}/>
            )}
        </tr>
    )
}

function FieldRowCell(props) {
    const {t} = useTranslation(['workflowBuilder']);
    const {
        formKey,
        fieldConfigurations,
        configuration,
        state,
        isDisabled,
        ...rest
    } = props;

    const {expandCol, colSpan, addColonToLabel} = useCommonFieldRowCellConfiguration(props);
    const Component = selectPositionedLabelFieldComponent(configuration);
    const className = buildClassName(
        'table-cell',
        !expandCol && 'no-stretch'
    );

    const _isDisabled = isDisabled || !configuration.isEnabled(state, fieldConfigurations);
    const combinedAriaLabelledBy = [];
    if (configuration.positionedLabel.left) combinedAriaLabelledBy.push(configuration.positionedLabel.left);

    return (
        <React.Fragment key={configuration.name}>
            {configuration.positionedLabel.left ?
                <td className="table-cell no-stretch">
                    <Text id={configuration.positionedLabel.left} isDisabled={_isDisabled}
                        value={`${FormBuilderConfiguration.getLabelTranslation(t, formKey, configuration.positionedLabel.left)}:`}/>
                </td>
                :
                null
            }
            <td className={className} colSpan={colSpan}>
                <FieldConfigurationWrapper aria-labelledby={combinedAriaLabelledBy.join(' ')} {...rest} Component={Component}
                    addColonToLabel={addColonToLabel} expandDropdown={expandCol} formKey={formKey} fieldConfigurations={fieldConfigurations}
                    configuration={configuration} state={state} isDisabled={_isDisabled}/>
            </td>
        </React.Fragment>
    )
}

export function FieldComponentRow(props) {
    const {
        parentId,
        formKey,
        configuration,

        label,
        value,
        state,

        updateState,
        inputHandler,
        toggleHandler,
        dropdownHandler,

        isRequired,
        isInvalid,
        isDisabled
    } = props;

    const Component = selectPositionedLabelFieldComponent(configuration);
    const isRadioButton = configuration.componentType === FormBuilderComponentConfiguration.ComponentType.RADIO_BUTTON;
    const isCheckbox = configuration.componentType === FormBuilderComponentConfiguration.ComponentType.CHECKBOX;

    if (isCheckbox) {
        return (
            <tr className="table-row">
                <td className="table-cell" colSpan={400}>
                    <Component parentId={parentId} label={label} state={state} value={value} configuration={configuration}
                        toggleHandler={toggleHandler} isRequired={isRequired} isInvalid={isInvalid} isDisabled={isDisabled}/>
                </td>
            </tr>
        )
    }

    const labelCellClassName = buildClassName(
        'table-cell no-stretch',
        'align-top'
    );

    const labelId = configuration.label;

    return (
        <tr className="table-row">
            {!label ? null :
                <td className={labelCellClassName}>
                    <Text id={labelId} value={`${label}:`} style={isRadioButton ? {} : {lineHeight: 2.2}}
                        isDisabled={isDisabled}/>
                </td>
            }
            <td className="table-cell" colSpan={400}>
                <Component parentId={parentId} formKey={formKey} state={state} value={value} configuration={configuration} updateState={updateState}
                    inputHandler={inputHandler} toggleHandler={toggleHandler} dropdownHandler={dropdownHandler}
                    aria-labelledby={labelId} isRequired={isRequired} isInvalid={isInvalid} isDisabled={isDisabled} expandDropdown/>
            </td>
        </tr>
    )
}

export function selectPositionedLabelFieldComponent(configuration) {
    if (isNotEmptyNorFalsy(configuration.positionedLabel) || isNotEmptyNorFalsy(configuration.helperValues)) {
        return InlineFieldComponent;
    }
    return FieldComponent;
}

function InlineFieldComponent(props) {
    const {t} = useTranslation(['workflowBuilder']);
    const {
        formKey,
        configuration,
        'aria-labelledby': ariaLabelledBy
    } = props;

    const positionedLabel = getEntries(configuration.positionedLabel)
        .reduce((acc, [position, label]) => {
            acc[position] = FormBuilderConfiguration.getLabelTranslation(t, formKey, label);
            return acc;
        }, {});

    const componentHasHelpers = isNotEmptyNorFalsy(configuration.helperValues);
    const combinedAriaLabelledBy = [];
    if (ariaLabelledBy) combinedAriaLabelledBy.push(ariaLabelledBy);
    if (positionedLabel.top) combinedAriaLabelledBy.push(configuration.positionedLabel.top);
    if (positionedLabel.right) combinedAriaLabelledBy.push(configuration.positionedLabel.right);
    if (positionedLabel.bottom) combinedAriaLabelledBy.push(configuration.positionedLabel.bottom);

    return (
        <>
            {positionedLabel.top &&
                <Text id={configuration.positionedLabel.top} value={positionedLabel.top}
                    isDisabled={props.isDisabled}/>
            }
            <div className="workflow-builder__field-component-inline">
                <FieldComponent {...props} aria-labelledby={combinedAriaLabelledBy.join(' ')}/>

                {positionedLabel.right &&
                    <Text id={configuration.positionedLabel.right} value={positionedLabel.right}
                        isDisabled={props.isDisabled}/>
                }
                {componentHasHelpers &&
                    <HelpButton helpers={configuration.helperValues} value={props.value} isDisabled={props.isDisabled}/>
                }
            </div>
            {positionedLabel.bottom &&
                <Text id={configuration.positionedLabel.bottom} value={positionedLabel.bottom} style={{marginTop: '0.25rem'}}
                    isDisabled={props.isDisabled}/>
            }
        </>
    )
}

export default FieldRow;
