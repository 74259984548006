import FieldGroup from "./FieldGroup";
import {createInputHandler} from "../../../utilities/componentFunctions";
import {createToggleHandler} from "../../common/Checkbox/helpers";
import {createDropdownHandler} from "../../common/Dropdown/helpers";
import React from "react";
import {Button} from "../../common/Button/Button";
import {useTranslation} from "react-i18next";

function FormBuilder(props) {
    const {t} = useTranslation(['common']);
    const {
        id,
        formKey,
        formConfiguration,
        state,
        updateState,

        autoFocus,
        isRequired,
        isDisabled
    } = props;

    const inputHandler = createInputHandler({
        handler: updateState
    });
    const toggleHandler = createToggleHandler({
        handler: updateState
    });
    const dropdownHandler = createDropdownHandler({
        handler: updateState
    });

    function onClearForm() {
        updateState(formConfiguration.generateDefaultObject(), {clear: true});
    }

    return (
        <>
            <FieldGroup root parentId={id} autoFocus={autoFocus} formKey={formKey} state={state} updateState={updateState}
                configuration={formConfiguration} fieldConfigurations={formConfiguration.fieldConfigurations}
                inputHandler={inputHandler} toggleHandler={toggleHandler} dropdownHandler={dropdownHandler}
                isRequired={isRequired} isDisabled={isDisabled}/>

            {formConfiguration.showClearFormButton &&
                <div className="flex-center flex-end" style={{marginBottom: '0.75rem'}}>
                    <Button label={t('common:option.clear')} onClick={onClearForm}
                        isDisabled={isDisabled}/>
                </div>
            }
        </>
    )
}

export default FormBuilder;