import React, {useCallback, useEffect, useRef, useState} from "react";
import {buildFakeEvent, getKeys, getNonEmptyValueOrDefault, stringToBool} from "../../../utilities/helperFunctions";
import {useTranslation} from "react-i18next";
import {purviewDataSourceInputType, purviewDataSourceType, userServiceObjectTypes} from "../../../utilities/constants";
import {ListDropdown} from "../../common/Dropdown/Dropdown";
import Checkbox from "../../common/Checkbox/Checkbox";
import DataSourcesTable from "../DataSourcesTable";
import WorkflowBuilderOperation from "../../../models/workflowbuilder/WorkflowBuilderOperation";
import PurviewOperationPane from "./PurviewOperationPane";
import Papa from "papaparse";
import {blobUrlDownload} from "../../../utilities/downloadHelper";
import {purviewJobParameterNames} from "../PurviewGuidedJobForm";
import {buildOperationFieldParameterNames} from "../../guidedJob/operationFieldParameterNames";
import SchedulerModel from "../../../models/scheduler/SchedulerModel";
import {useDispatch} from "react-redux";
import GuidedJobModel from "../../../models/guidedjob/GuidedJobModel";
import {ThirdPartyServiceApi} from "../../../models/thirdparty/ThirdPartyServiceModel";
import PurviewCache from "../../../models/guidedjob/PurviewCache";
import UserServiceObjectDropdown from "../../userservice/UserServiceObjectDropdown";


const fieldNames = ['disabled', 'useCustodialDataSourcesFile', 'custodialDataSources'];
export const purviewAddCustodialDataSourcesParameterNames = buildOperationFieldParameterNames(WorkflowBuilderOperation.Alias.PURVIEW_ADD_CUSTODIAL_DATA_SOURCES, fieldNames);

const applyHoldToCustodiansFieldNames = ['disabled', 'inputType', 'custodianIdsJson', 'waitForCompletion'];
export const purviewApplyHoldToCustodiansParameterNames = buildOperationFieldParameterNames(WorkflowBuilderOperation.Alias.PURVIEW_APPLY_HOLD_TO_CUSTODIANS, applyHoldToCustodiansFieldNames);

function getNextEnabled(getParameter) {
    const addCustodialDataSourcesDisabled = getParameter(purviewAddCustodialDataSourcesParameterNames.disabled, false, {parse: true});
    const custodialDataSources = getParameter(purviewAddCustodialDataSourcesParameterNames.custodialDataSources, '[]', {parse: true});
    return {
        addDataSources: addCustodialDataSourcesDisabled.value || (custodialDataSources.value.length > 0
            && custodialDataSources.value.every(source => !!(source.custodianEmail && source.type && source.value)))
    }
}

export function getPurviewAddCustodialDataSourcesGuide(operation, props) {
    const {
        workflowActions,
        getParameter,
        updateParameter
    } = props;

    const custodialDataSources = getParameter(purviewAddCustodialDataSourcesParameterNames.custodialDataSources);
    const useCustodialDataSourcesFile = getParameter(purviewAddCustodialDataSourcesParameterNames.useCustodialDataSourcesFile);

    if (!custodialDataSources?.value.startsWith('[') || !custodialDataSources?.value.endsWith(']')) {
        updateParameter(purviewAddCustodialDataSourcesParameterNames.custodialDataSources, JSON.stringify([]));
    }
    if (stringToBool(useCustodialDataSourcesFile.value)) {
        updateParameter(purviewAddCustodialDataSourcesParameterNames.useCustodialDataSourcesFile, JSON.stringify(false));
    }
    if (workflowActions.applyHoldToCustodians) {
        updateParameter(purviewApplyHoldToCustodiansParameterNames.inputType, purviewDataSourceInputType.IDS_JSON);
        updateParameter(purviewApplyHoldToCustodiansParameterNames.custodianIdsJson, purviewJobParameterNames.custodianIds);
    }

    return {
        getPanes: function(t, props) {
            const addCustodialDataSourcesTitle = getNonEmptyValueOrDefault(operation.notes, t('guidedJob:panes.addCustodialDataSources'));
            const nextEnabled = getNextEnabled(props.getParameter);

            return [{
                title: addCustodialDataSourcesTitle,
                isNextEnabled: nextEnabled.addDataSources,
                component: <PurviewOperationPane {...props}
                    workflowActions={workflowActions} OperationPane={PurviewAddCustodialDataSourcesPane}/>
            }];
        }
    }
}

const defaultCustodialDataSource = {
    type: purviewDataSourceType.USER,
    custodianEmail: '',
    value: '',
    includeMailbox: true,
    includeSite: false,
    includeTeams: false
};

function PurviewAddCustodialDataSourcesPane(props) {
    const {t} = useTranslation(['guidedJob', 'common']);
    const dispatch = useDispatch();

    const {
        purviewService,
        getParameter,
        updateParameter,
        isDisabled
    } = props;

    const addCustodialDataSourcesDisabled = getParameter(purviewAddCustodialDataSourcesParameterNames.disabled, false, {parse: true});
    const isOperationDisabled = isDisabled || addCustodialDataSourcesDisabled.value;

    const custodialDataSources = getParameter(purviewAddCustodialDataSourcesParameterNames.custodialDataSources, '[]', {parse: true});
    const [dataSources, setDataSources] = useState(custodialDataSources.value);

    useEffect(() => {
        updateParameter(purviewAddCustodialDataSourcesParameterNames.custodialDataSources, JSON.stringify(dataSources));
    }, [dataSources]);

    const headers = useRef([
        {value: t('guidedJob:label.custodianEmail')},
        {value: t('guidedJob:label.type'), style: {width: '12rem'}},
        {value: t('guidedJob:label.emailUrl')},
        {value: t('guidedJob:label.mailbox'), style: {textAlign: 'center', width: '8rem'}},
        {value: t('guidedJob:label.oneDrive'), style: {textAlign: 'center', width: '8rem'}},
        {value: t('guidedJob:label.teams'), style: {textAlign: 'center', width: '8rem'}}
    ]);

    const rowProps = useRef({
        userServiceId: purviewService.authenticationServiceId
    });

    const exportCsv = useCallback(() => {
        const fields = headers.current.map(header => header.value);
        const data = dataSources.map(dataSource =>
            [dataSource.custodianEmail, dataSource.type, dataSource.value, dataSource.includeMailbox, dataSource.includeSite, dataSource.includeTeams]
        );
        const fileName = `Custodial Data Sources.csv`;
        blobUrlDownload(Papa.unparse({fields, data}), {type: 'text/csv;charset=utf-8;', fileName});
    }, [dataSources]);

    const importCsv = useCallback(async resultArray => {
        try {
            dispatch(GuidedJobModel.actionCreators.update({isDisabled: true}));
            const {data} = await ThirdPartyServiceApi.parseImportedObjects(purviewService.id, PurviewCache.Resource.CUSTODIAN_DATA_SOURCES, resultArray);
            const values = {
                importedCount: data.objects.length,
                invalidCount: data.invalidCount
            };
            dispatch(GuidedJobModel.showImportPopup(values));
            setDataSources(data.objects);
        } catch (error) {
            dispatch(SchedulerModel.actionCreators.handleResponseError(error));
        } finally {
            dispatch(GuidedJobModel.actionCreators.update({isDisabled: false}));
        }
    }, [purviewService.id]);

    function skipToggleHandler(event) {
        const {checked} = event.target;
        [purviewAddCustodialDataSourcesParameterNames, purviewApplyHoldToCustodiansParameterNames].forEach(paramName => {
            updateParameter(paramName.disabled, checked);
        });
    }

    return (
        <div className="display-input">
            <Checkbox label={t('guidedJob:label.skipAddingCustodialDataSources')}
                containerStyle={{marginBottom: '0.75rem'}} value={addCustodialDataSourcesDisabled.value} onClick={skipToggleHandler}
                isDisabled={isDisabled}/>

            <DataSourcesTable id={"custodialDataSources"} label={t('guidedJob:label.custodialDataSources')} dataSources={dataSources}
                setDataSources={setDataSources} defaultObject={defaultCustodialDataSource} headers={headers.current}
                RowCells={CustodialDataSourceRowCells} rowProps={rowProps.current} importCsv={importCsv} exportCsv={exportCsv}
                ariaLabelKey={'CustodialDataSource'} isDisabled={isOperationDisabled}/>
        </div>
    )
}

function CustodialDataSourceRowCells(props) {
    const {t} = useTranslation(['aria']);
    const {
        rIndex,
        userServiceId,
        dataSource,
        toggleHandler,
        dropdownHandler,
        dropdownButtonStyle,
        checkboxContainerStyle,
        checkboxStyle,
        isSelected,
        isDisabled
    } = props;


    const custodianEmailDropdownHandler = useCallback(event => {
        dropdownHandler(event);
        const {value: newEmail} = event.currentTarget.dataset;
        // Update dataSource value to match custodianEmail if type is USER
        if (newEmail !== dataSource.custodianEmail && dataSource.type === purviewDataSourceType.USER) {
            dropdownHandler(buildFakeEvent({name: 'value', value: newEmail, index: rIndex}));
        }
    }, [dataSource.type, dataSource.custodianEmail]);

    const dataSourceTypeDropdownHandler = useCallback(event => {
        dropdownHandler(event);
        const newType = event.currentTarget.dataset.value;
        // Reset values
        if (newType !== dataSource.type) {
            dropdownHandler(buildFakeEvent({name: 'value', value: '', index: rIndex}));
            if (newType === purviewDataSourceType.SITE) {
                toggleHandler(buildFakeEvent({type: 'checkbox', name: 'includeMailbox', value: false, index: rIndex}));
                toggleHandler(buildFakeEvent({type: 'checkbox', name: 'includeSite', value: false, index: rIndex}));
            } else if (dataSource.type === purviewDataSourceType.SITE) {
                toggleHandler(buildFakeEvent({type: 'checkbox', name: 'includeMailbox', value: true, index: rIndex}));
            }
            if (newType !== purviewDataSourceType.USER) {
                toggleHandler(buildFakeEvent({type: 'checkbox', name: 'includeTeams', value: false, index: rIndex}));
            }
        }
    }, [dataSource.type]);

    let valueObjectType;
    if (dataSource.type === purviewDataSourceType.UNIFIED_GROUP) {
        valueObjectType = userServiceObjectTypes.UNIFIED_GROUP;
    } else if (dataSource.type === purviewDataSourceType.SITE) {
        valueObjectType = userServiceObjectTypes.SHARE_POINT_SITE;
    } else {
        valueObjectType = userServiceObjectTypes.USER_ACCOUNT;
    }

    const isMailboxOneDriveDisabled = isDisabled || dataSource.type === purviewDataSourceType.SITE;
    const isTeamsDisabled =  isDisabled || dataSource.type !== purviewDataSourceType.USER;

    return (
        <>
            <td style={{height: '1px'}}>
                <UserServiceObjectDropdown id={`purviewDataSourceCustodianEmailDropdown${rIndex}`} name={'custodianEmail'}
                    value={dataSource.custodianEmail} userServiceId={userServiceId} objectType={userServiceObjectTypes.USER_ACCOUNT}
                    onItemSelect={custodianEmailDropdownHandler} buttonStyle={dropdownButtonStyle}
                    isRequired isSelected={isSelected} isDisabled={isDisabled}/>
            </td>
            <td style={{height: '1px'}}>
                <DataSourceTypeDropdown id={`purviewDataSourceTypeDropdown${rIndex}`} name={'type'} value={dataSource.type}
                    onItemSelect={dataSourceTypeDropdownHandler} buttonStyle={dropdownButtonStyle}
                    isRequired isSelected={isSelected} isDisabled={isDisabled}/>
            </td>
            <td style={{height: '1px'}}>
                <UserServiceObjectDropdown id={`purviewDataSourceValueDropdown${rIndex}`} name={'value'}
                    value={dataSource.value} userServiceId={userServiceId} objectType={valueObjectType} onItemSelect={dropdownHandler}
                    buttonStyle={dropdownButtonStyle} isRequired isSelected={isSelected} isDisabled={isDisabled}/>
            </td>
            <td style={{height: '1px', width: '8rem'}}>
                <Checkbox name={'includeMailbox'} checked={dataSource.includeMailbox} onClick={toggleHandler}
                    containerStyle={checkboxContainerStyle} style={checkboxStyle} isSelected={isSelected}
                    aria-label={t('aria:hiddenAssistText.includeMailbox')} isDisabled={isMailboxOneDriveDisabled}/>
            </td>
            <td style={{height: '1px', width: '8rem'}}>
                <Checkbox name={'includeSite'} checked={dataSource.includeSite} onClick={toggleHandler}
                    containerStyle={checkboxContainerStyle} style={checkboxStyle} isSelected={isSelected}
                    aria-label={t('aria:hiddenAssistText.includeOneDrive')} isDisabled={isMailboxOneDriveDisabled}/>
            </td>
            <td style={{height: '1px', width: '8rem'}}>
                <Checkbox name={'includeTeams'} checked={dataSource.includeTeams} onClick={toggleHandler}
                    containerStyle={checkboxContainerStyle} style={checkboxStyle} isSelected={isSelected}
                    aria-label={t('aria:hiddenAssistText.includeTeams')} isDisabled={isTeamsDisabled}/>
            </td>
        </>
    )
}

function DataSourceTypeDropdown(props) {
    const {t} = useTranslation(['guidedJob', 'aria']);

    const dataSourceTypeItems = getKeys(purviewDataSourceType)
        .map(type => ({
            name: t(`guidedJob:purviewDataSourceType.${type}`),
            value: type
        }));

    return (
        <ListDropdown id={'purviewDataSourceTypeDropdown'} name={'dataSourceType'}
            noneSelectedMessage={t('guidedJob:option.selectDataSourceType')} isOutlined items={dataSourceTypeItems}
            {...props}/>
    );
}
