import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {getValues} from "../../../utilities/helperFunctions";
import {ListDropdown} from "../../common/Dropdown/Dropdown";
import ThirdPartyServiceModel from "../../../models/thirdparty/ThirdPartyServiceModel";

export function SmtpServiceDropdown(props) {
    const {t} = useTranslation(['aria', 'thirdPartyService']);

    const thirdPartyServiceDetailsMap = useSelector(state => state.thirdPartyServiceDetailsMap);
    const smtpServiceItems = getValues(thirdPartyServiceDetailsMap)
        .filter(service => service.type === ThirdPartyServiceModel.Type.SMTP && service.userCredential?.signedIn)
        .map(({name, id}) => ({
            name,
            value: id
        }));

    return (
        <ListDropdown id={'smtpServiceDropdown'} name={'smtpServiceId'} aria-label={t('aria:hiddenAssistText.smtpService')}
            noneSelectedMessage={t('thirdPartyService:option.select')} items={smtpServiceItems} {...props}
        />
    );
}