import SettingModel from "../generics/SettingModel";

class NoticeDatasetEventModel extends SettingModel.Simple {

    static nom = 'NoticeDatasetEventModel';
    static actions = NoticeDatasetEventModel.buildActions('NOTICE_DATASET_EVENT');
    static actionCreators = NoticeDatasetEventModel.buildActionCreators(NoticeDatasetEventModel.actions);
    static reducer = NoticeDatasetEventModel.buildReducer(NoticeDatasetEventModel.actions);

    constructor(model={}) {
        super(model);
    }
}

export default NoticeDatasetEventModel;
