import React from "react";
import {useTranslation} from "react-i18next";
import {AddButtonHeader} from "../../common/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import {getValues, isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import UserServiceModel from "../../../models/user/UserServiceModel";
import {
    StatusNameDescriptionCell,
    TableEnableToggleCell,
    TableLabelHeader,
    TableRow
} from "../../common/CustomTable/CustomTable";
import {permissionKeys} from "../../../i18next/keys";
import {applicationFeatures} from "../../../utilities/constants";

function ManagedUserServiceTable() {
    const {t} = useTranslation(['userService', 'common']);
    const dispatch = useDispatch();

    const canAddUserService = useSelector(state => state.currentUser.features.includes(applicationFeatures.MODIFY_USER_SERVICES));
    const activeUserServiceId = useSelector(state => state.componentStates.userServiceDisplay.userServiceId);
    const managedUserServices = getValues(useSelector(state => state.userServiceDetailsMap))
        .filter(userService => userService.type === UserServiceModel.types.MANAGED);

    function toggleEnabled(event) {
        const {name} = event.currentTarget.dataset;
        dispatch(UserServiceModel.actionCreators.toggleEnabled(name));
    }

    function showManagedUserServiceForm() {
        dispatch(UserServiceModel.componentActionCreators.updateDisplay({isManagedUserServiceFormActive: true}));
    }

    function showManagedUserServiceTablet(e) {
        dispatch(UserServiceModel.componentActionCreators.updateDisplay({userServiceId: e.currentTarget.dataset.value}));
    }

    const isNotEmpty = isNotEmptyNorFalsy(managedUserServices);
    if (!canAddUserService && !isNotEmpty) {
        return null;
    }

    return (
        <div className="display-item">
            <AddButtonHeader aria-label={t('aria:hiddenAssistText.addManagedUserService')} text={t('userService:label.managedUserServiceTitle')}
                canModify={canAddUserService} onClick={showManagedUserServiceForm}/>

            {isNotEmpty &&
                <div className="display-table">
                    <div className="table-header-group">

                        <div className="table-header"/>
                        <TableLabelHeader label={t('common:label.name')}/>
                    </div>

                    <div className="table-row-group">
                        {managedUserServices.map(userService =>
                            <ManagedUserServiceRow key={userService.id} userService={userService} toggleEnabled={toggleEnabled}
                                activeUserServiceId={activeUserServiceId} showTablet={showManagedUserServiceTablet}/>
                        )}
                    </div>
                </div>
            }
        </div>
    )
}

function ManagedUserServiceRow(props) {
    const {
        activeUserServiceId,
        userService: {
            id,
            enabled,
            status,
            name,
            description,
            userPermissions
        },
        toggleEnabled,
        showTablet
    } = props;

    const canModify = userPermissions && userPermissions.includes(permissionKeys.MODIFY);

    return (
        <TableRow value={id} isActive={id === activeUserServiceId} onClick={showTablet}>

            <TableEnableToggleCell ariaLabelKey={'ManagedUserService'} displayName={name} name={id}
                checked={enabled} onClick={toggleEnabled} isReadOnly={!canModify}/>

            <StatusNameDescriptionCell status={status} name={name} description={description}/>
        </TableRow>
    )
}

export default ManagedUserServiceTable;
