import React, {useEffect, useLayoutEffect, useState} from 'react';
import "./WorkDisplay.css";
import "./LegalHold.css";
import LegalHoldModel from "../../models/legalhold/LegalHoldModel";
import LegalHoldMatterDisplay from "./LegalHoldMatterDisplay";
import LegalHoldNoticeDisplay from "./LegalHoldNoticeDisplay";
import {useDispatch, useSelector} from "react-redux";
import Switch from "../common/Switch/Switch";
import {legalHoldPageViewKeys} from "../../i18next/keys";
import {applicationFeatures, details} from "../../utilities/constants";
import LegalHoldOverviewDisplay from "./LegalHoldOverviewDisplay";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";


function LegalHoldPage() {
    const dispatch = useDispatch();

    const {
        activeView
    } = useSelector(state => state.componentStates.legalHoldPage);
    const userFeatures = useSelector(state => state.currentUser.features);

    useLayoutEffect(() => {
        dispatch(LegalHoldModel.componentActionCreators.setPageActive());
        return () => dispatch(LegalHoldModel.componentActionCreators.setPageInactive());
    }, []);

    const usersLoaded = useSelector(state => state.hasLoaded[details.USERS] && state.hasLoaded[details.USER_SERVICES]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (usersLoaded && isLoading) {
            setIsLoading(false);
        }
    }, [usersLoaded]);


    return (
        <main className="mainPage column-direction">
            <LoadingWrapper isLoading={isLoading}>
                <Switch>
                    {activeView === legalHoldPageViewKeys.OVERVIEW && userFeatures.includes(applicationFeatures.VIEW_LEGAL_HOLDS) &&
                    <LegalHoldOverviewDisplay/>
                    }
                    {activeView === legalHoldPageViewKeys.MATTERS && userFeatures.includes(applicationFeatures.VIEW_LEGAL_HOLDS) &&
                    <LegalHoldMatterDisplay/>
                    }
                    {activeView === legalHoldPageViewKeys.NOTICES && userFeatures.includes(applicationFeatures.VIEW_LEGAL_HOLD_NOTICES) &&
                    <LegalHoldNoticeDisplay/>
                    }
                </Switch>
            </LoadingWrapper>
       </main>
    );
}

export default LegalHoldPage;
