import {axiosInstance} from "../models/api/AxiosProxy";


export function download (url, attr={}) {
    const action = axiosInstance.baseUrl + url;
    if (axiosInstance.uiToken) {
        attr.token = axiosInstance.uiToken;
    }

    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", action);

    Object.keys(attr).map((key) => {
        const element = document.createElement("input");
        element.setAttribute("type", "hidden");
        element.setAttribute("name", key);
        element.setAttribute("value", attr[key]);

        form.appendChild(element);
    })
    const iFrame = document.createElement("iframe");
    iFrame.setAttribute("id", "download_frame")
    document.body.appendChild(iFrame);

    iFrame.contentWindow.document.body.appendChild(form);
    form.submit();

    setTimeout(() => {
        const frame = document.getElementById("download_frame");
        frame.parentNode.removeChild(frame);
    }, 10000)
}

export function downloadLogs (logsEndpoint, attr) {
    download(logsEndpoint, attr);
}

export function blobUrlDownload(data, {type, ...opts}) {
    const blob = new Blob([data], {type});
    blobDownload(blob, opts);
}

export function blobDownload(blob, {fileName}) {
    const url = URL.createObjectURL(blob);
    // Download with temp a-link
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.setAttribute('target', '_blank')
    link.style.visibility = 'hidden';
    // Firefox requires link to be attached to DOM
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // clear blobUrl
    window.URL.revokeObjectURL(url);
}

const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
export function getFileNameFromContentDisposition(contentDisposition){
    if (contentDisposition) {
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
            return matches[1].replace(/['"]/g, '');
        }
    }
}