import React, {useRef, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {useTranslation} from "react-i18next";
import {licenceSourceTypes, licenseRelayTypes} from "../../../utilities/constants";
import TextArea from '../../common/TextArea/TextArea';
import {ValueList} from "../../common/InputList/InputList";
import Form, {FormHeader} from "../../common/Form/Form";
import PopupModel from "../../../models/scheduler/PopupModel";
import LicenceSourceModel, {LicenceSourceSaga} from "../../../models/settings/LicenceSourceModel";
import {ButtonGroup} from "../../common/Button/Button";
import {contextCall} from "../../../saga/sagaFunctions";
import SchedulerModel from "../../../models/scheduler/SchedulerModel";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import {shouldEnableNmsLicenceSource} from "../../../utilities/shouldEnableFunctions";
import {createCloseHandler, createInputHandler, createStateHandler} from "../../../utilities/componentFunctions";
import {getValues} from "../../../utilities/helperFunctions";
import {ListDropdown} from "../../common/Dropdown/Dropdown";
import {FormElementRow} from "../../common/CustomTable/CustomTable";
import {createDropdownHandler} from "../../common/Dropdown/helpers";
import {useClearOnFirstCallback} from "../../../utilities/formHooks";

function NmsLicenceSourceForm(props) {
    const {t} = useTranslation(['nuixLicenceSource', 'common']);
    const dispatch = useDispatch();

    const [state, setState] = useState({
        sourceType: licenceSourceTypes.NMS,
        relayType: licenseRelayTypes.NONE,
        nmsLicenceSourceName: '',
        description: '',
        filter: '',
        nmsName: '',
        nmsPort: 27443,
        nmsLicenseSourceUsername: '',
        isAddEnabled: false
    });
    const [whitelistedCertFingerprints, setWhitelistedCertFingerprints] = useState(['']);

    const nmsLicenseSourcePasswordRef = useRef();
    const clearOnFirstCallback = useClearOnFirstCallback();

    const stateHandler = createStateHandler({
        updateState: setState,
        passwordRef: nmsLicenseSourcePasswordRef,
        passwordName: 'nmsLicenseSourcePassword',
        passwordResetNames: ['nmsName', 'nmsPort', 'nmsLicenseSourceUsername'],
        shouldEnable: shouldEnableNmsLicenceSource
    });

    const inputHandler = createInputHandler({
        handler: stateHandler
    });

    const dropdownHandler = createDropdownHandler({
        handler: stateHandler
    });

    function onSubmit() {
        props.addNuixLicenceSource({
            ...state,
            whitelistedCertFingerprints
        });
    }

    function onTest() {
        const testEffect = contextCall(
            LicenceSourceSaga,
            'testRuleData',
            'Form',
            {
                ...state,
                whitelistedCertFingerprints
            }
        );

        props.yieldEffect(testEffect);
    }

    const onClose = createCloseHandler({
        t,
        item: t('nuixLicenceSource:label.name'),
        values: state,
        blacklist: ['sourceType', 'relayType', 'nmsPort'],
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        close: () => dispatch(LicenceSourceModel.componentActionCreators.updateDisplay({isNmsLicenceSourceFormActive: false}))
    });

    const {
        isDisabled
    } = props;

    const {
        nmsLicenceSourceName,
        relayType,
        description,
        filter,
        nmsName,
        nmsPort,
        nmsLicenseSourceUsername,
        isAddEnabled
    } = state;

    return (
        <Form onClose={onClose} isDisabled={isDisabled} closeButtonAriaLabel={t('nuixLicenceSource:option.closeNmsForm')}
            header={
                <FormHeader text={t('nuixLicenceSource:label.nmsLicenceSourceName')} iconName={'nmsLicenceSource'} isDisabled={isDisabled} />
            }
            body={
                <>
                    <div className="display-input">
                        <HTMLTextInput label={t('common:label.name')} name={'nmsLicenceSourceName'} value={nmsLicenceSourceName} onChange={inputHandler}
                            isDisabled={isDisabled} isRequired/>
                    </div>

                    <div className="display-input">
                        <TextArea label={t('common:label.description')} name={'description'} value={description} onChange={inputHandler}
                            isDisabled={isDisabled}/>
                    </div>

                    <div className="display-input">
                        <HTMLTextInput label={t('nuixLicenceSource:label.filter')} name={'filter'} value={filter} onChange={inputHandler}
                            isDisabled={isDisabled}/>
                    </div>

                    <div className="display-input" style={{display: 'flex', justifyContent: 'space-between'}}>
                        <HTMLTextInput label={t('nuixLicenceSource:label.serverName')} name={'nmsName'} value={nmsName} onChange={inputHandler}
                            isDisabled={isDisabled} isRequired containerStyle={{flex: 1, marginRight: '2.5rem'}}
                        />

                        <HTMLTextInput label={t('nuixLicenceSource:label.serverPort')} name={'nmsPort'} value={nmsPort} onChange={inputHandler}
                            type={'number'} isDisabled={isDisabled} isRequired containerStyle={{flexBasis: '5rem'}}
                        />
                    </div>

                    <div className="forms-table display-input">
                        <div className="table-row-group">
                            <FormElementRow label={t('nuixLicenceSource:label.relayType')} isDisabled={isDisabled}
                                element={
                                    <LicenseRelayTypeDropdown value={relayType} onItemSelect={dropdownHandler}
                                        isRequired isDisabled={isDisabled}/>
                                }/>
                        </div>
                    </div>

                    <div className="display-input">
                        <HTMLTextInput label={t('common:label.username')} name={'nmsLicenseSourceUsername'} value={nmsLicenseSourceUsername}
                            onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                    </div>

                    <div className="display-input">
                        <HTMLTextInput inputRef={nmsLicenseSourcePasswordRef} label={t('common:label.password')} name={'nmsLicenseSourcePassword'} type={'password'}
                            onChange={inputHandler} onBeforeInput={clearOnFirstCallback} isDisabled={isDisabled} isRequired/>
                    </div>

                    <div className="display-input">
                        <ValueList id={'whitelistedCertFingerprints'} label={t('common:label.whitelistedCertFingerprints')}
                            ariaLabelKey={'WhitelistedCertFingerprint'} values={whitelistedCertFingerprints}
                            setValues={setWhitelistedCertFingerprints} isDisabled={isDisabled}/>
                    </div>
                </>
            }
            footer={
                <ButtonGroup buttons={[{
                    id: 'formFooterBackButton',
                    label: t('common:option.cancel'),
                    onClick: onClose,
                    isDisabled
                }, {
                    id: 'formTestButton',
                    label: t('nuixLicenceSource:label.testSource'),
                    onClick: onTest,
                    isDisabled: isDisabled || !isAddEnabled
                }, {
                    id: 'formFooterNextButton',
                    label: t('common:button.addNuixLicenseSource'),
                    onClick: onSubmit,
                    isDisabled: isDisabled || !isAddEnabled
                }]}/>
            }
        />
    )
}

export function LicenseRelayTypeDropdown(props) {
    const {t} = useTranslation(['aria', 'nuixLicenceSource']);

    const relayTypeItems = getValues(licenseRelayTypes)
        .map(type => ({
            name: t(`nuixLicenceSource:relayType.${type}`),
            value: type
        }));

    return (
        <ListDropdown id={'licenseRelayTypeDropdown'} name={'relayType'} aria-label={t('aria:hiddenAssistText.licenseRelayType')}
            items={relayTypeItems} noneSelectedMessage={t('nuixLicenceSource:option.selectRelayType')}
            buttonStyle={{maxWidth: 'none'}} {...props}/>
    );
}

const mapStateToProps = state => {
    return {
        ...state.componentStates.licenceSourceForm
    }
};

const mapDispatchToProps = dispatch => {
    return {
        yieldEffect: effect => dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(effect)),
        addNuixLicenceSource: nuixLicenceSource => dispatch(LicenceSourceModel.actionCreators.submitForm(nuixLicenceSource))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NmsLicenceSourceForm);