import React from "react";

export function QuestionTable (props) {
    const {label, children} = props;

    return (
        <div className="display-item">
            {label &&
                <label className="label is-heading">
                    {label}
                </label>
            }
            <div className={'settings-table no-border tight-padding'} style={{border: '1px solid transparent', maxWidth: '40rem'}}>
                <div className="table-row-group">
                    {children}
                </div>
            </div>
        </div>
    )
}

export function QuestionTableRow (props) {
    const {component, stateName} = props;

    return (
        <div key={`${stateName}Row`} className="table-row align-middle no-hover">
            <div key={`${stateName}Component`} className="table-cell no-stretch">
                {component}
            </div>
        </div>
    )
}

export function QuestionTableDependantRow (props) {
    const {component, stateName, dependant} = props;

    return (
        <>
            {dependant &&
                <QuestionTableRow component={component} stateName={stateName}/>
            }
        </>
    )
}