import React from "react";
import {TableLabelCell, TableLabelHeader} from "../common/CustomTable/CustomTable";
import {useTranslation} from "react-i18next";
import {bytesCountToReadableCount, getMapValueName} from "../../utilities/helperFunctions";
import Text from "../common/Text/Text";
import {useSelector} from "react-redux";

function FileUploadHistoryTable(props) {
    const {t} = useTranslation(['uploadMonitor']);

    const {
        fileUploadHistories
    } = props;

    return (
        <div className="display-table">
            <div className="table-header-group">
                <TableLabelHeader label={t('uploadMonitor:label.user')} style={{width: '10rem'}}/>

                <TableLabelHeader label={t('uploadMonitor:label.clientIpAddress')}/>

                <TableLabelHeader label={t('uploadMonitor:label.serverIpAddress')}/>

                <TableLabelHeader label={t('uploadMonitor:label.serverName')}/>

                <TableLabelHeader label={t('uploadMonitor:label.serverRole')}/>

                <TableLabelHeader label={t('uploadMonitor:label.client')}/>

                <TableLabelHeader label={t('uploadMonitor:label.matter')}/>

                <TableLabelHeader label={t('uploadMonitor:label.dataset')}/>

                <TableLabelHeader label={t('uploadMonitor:label.dataRepository')}/>

                <TableLabelHeader label={t('uploadMonitor:label.files')}/>

                <TableLabelHeader label={t('uploadMonitor:label.size')}/>

                <TableLabelHeader label={t('uploadMonitor:label.averageSpeed')}/>
            </div>

            <div className="table-row-group">
                {fileUploadHistories.map(fileUploadHistory =>
                    <FileUploadHistoryRow key={fileUploadHistory.id} fileUploadHistory={fileUploadHistory}/>
                )}
            </div>
        </div>
    )
}

function selectFileUploadHistoryNames(state, fileUploadHistory) {
    return {
        client: getMapValueName(state.clientDetailsMap, fileUploadHistory.clientId),
        matter: getMapValueName(state.matterDetailsMap, fileUploadHistory.matterId),
        dataset: getMapValueName(state.datasetDetailsMap, fileUploadHistory.datasetId),
        dataRepository: getMapValueName(state.dataRepositoryDetailsMap, fileUploadHistory.dataRepositoryId)
    }
}

function FileUploadHistoryRow(props) {
    const {t} = useTranslation(['uploadMonitor', 'scheduler']);

    const {
        username,
        clientIpAddresses,
        serverIpAddress,
        serverName,
        serverRole,

        fileCount,
        size,
        uploadDuration
    } = props.fileUploadHistory;

    const names = useSelector(state => selectFileUploadHistoryNames(state, props.fileUploadHistory));

    const averageSpeed = uploadDuration ? (size / (uploadDuration / 1000)) : 0;
    const averageSpeedText = `${bytesCountToReadableCount(averageSpeed)}/s`;

    return (
        <div className="table-row no-hover">
            <TableLabelCell label={username} isEllipsis/>

            <TableLabelCell label={clientIpAddresses}/>

            <TableLabelCell label={serverIpAddress}/>

            <TableLabelCell label={serverName}/>

            <TableLabelCell label={serverRole && t(`scheduler:role.${serverRole}`)}/>

            <TableLabelCell label={names.client}/>

            <TableLabelCell label={names.matter}/>

            <TableLabelCell label={names.dataset}/>

            <TableLabelCell label={names.dataRepository}/>

            <div className="table-cell no-stretch">
                <Text value={fileCount} style={{textAlign: 'end'}}/>
            </div>

            <div className="table-cell no-stretch">
                <Text value={bytesCountToReadableCount(size)} style={{textAlign: 'end'}}/>
            </div>

            <div className="table-cell no-stretch">
                <Text value={averageSpeedText} style={{textAlign: 'end'}}/>
            </div>
        </div>
    )
}

export default FileUploadHistoryTable;