import React from "react";
import HTMLTextInput from "../../../../components/common/HTMLTextInput/HTMLTextInput";
import RelativityWorkspaceIdentifierTypeDropdown
    from "../../Dropdown/RelativityDropdown/RelativityWorkspaceIdentifierTypeDropdown";
import {useTranslation} from "react-i18next";


export function RelativityWorkspaceIdentifierInput (props) {
    const {t} = useTranslation(['wizard']);
    const {workspaceId, workspaceText, dropdownChange, inputChange} = props;

    const dropdownProps = {
        selectedWorkspaceIdentifierType: workspaceId,
        onWorkspaceIdentifierTypeSelected: dropdownChange,
        isRequired: true,
        isDisabled: false
    }

    const inputProps = {
        name: "workspaceIdentifier",
        value: workspaceText,
        onChange: inputChange,
        isRequired: true,
        isDisabled: false
    }


    return (
        <div className={"display-item"}>
            <label className="label is-heading">
                {t('wizard:questions.relativity.workspaceIdentifier')}
            </label>
            <div className={'settings-table no-border tight-padding'} style={{border: '1px solid transparent', maxWidth: '40rem'}}>
                <div className="table-row-group">
                    <div key={"workspace-row"} className="table-row align-middle no-hover">
                        <div key={"workspace-dropdown"} className="table-cell no-stretch">
                            <RelativityWorkspaceIdentifierTypeDropdown {...dropdownProps}/>
                        </div>
                        <div key={"workspace-input"} className="table-cell">
                            <HTMLTextInput {...inputProps}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}