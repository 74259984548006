import DetailsModel, {DetailsApi, DetailsSaga} from '../generics/DetailsModel';
import ComponentStateModel from "../generics/ComponentStateModel";
import ClientPoolModel, {ClientPoolApi} from "../settings/ClientPoolModel";
import ExecutionProfileModel from "../settings/ExecutionProfileModel";
import ResourcePoolModel from "../settings/ResourcePoolModel";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import {all, put, select} from "redux-saga/effects"
import {getEntries, getValues, postProtectedValue} from "../../utilities/helperFunctions";
import {details, routes, userSettings} from "../../utilities/constants";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import {contextCall} from "../../saga/sagaFunctions";
import ParameterModel from "../library/ParameterModel";
import {
    buildInputTableParameterValues,
    buildInputTableValuesFromMap,
    buildMapFromInputTable
} from "../../components/common/InputTable/InputTable";
import PopupModel from "../scheduler/PopupModel";
import DatasetModel from "../data/DatasetModel";

class ClientModel extends DetailsModel {

    static nom = 'ClientModel';
    static actions = ClientModel.buildActions('CLIENT');
    static actionCreators = ClientModel.buildActionCreators(ClientModel.actions);
    static reducer = ClientModel.buildReducer(ClientModel.actions);

    static workflowParametersRowTemplate = [{value: ''}, {value: ''}];
    static componentActionCreators = {
        ...ClientModel.buildComponentUpdateActionCreators(),
        ...ClientModel.buildComponentSetActiveActionCreators()
    };

    constructor(model = {}) {
        super(model);
        const {defaultExecutionProfileId, defaultResourcePoolId, allowedParameterValues, requiredMetadataHeaders, workflowParameters, status} = model;

        this.defaultExecutionProfileId = defaultExecutionProfileId;
        this.defaultResourcePoolId = defaultResourcePoolId;
        this.allowedParameterValues = getEntries(allowedParameterValues);
        this.requiredMetadataHeaders = buildInputTableValuesFromMap(requiredMetadataHeaders);
        this.workflowParameters = buildInputTableParameterValues(workflowParameters);
        this.status = status;
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'clientPage',
                type: 'Page',
                state: {
                    clientId: null,
                    isClientFormActive: false,
                    searchText: ''
                }
            },
            {
                key: 'clientDisplay',
                type: 'Display',
                state: {
                    matterId: null,
                    isMatterFormActive: false
                }
            },
            {
                key: 'clientForm',
                type: 'Form',
                state: {
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'CLIENT_PAGE',
                type: 'Page'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }
}

export class ClientApi extends DetailsApi {

    static location = '/client';

    static getDetails(includeDisabled) {
        return axiosInstance.get(`/scheduler/client?includeDisabled=${includeDisabled}`);
    }
}

export class ClientSaga extends DetailsSaga {

    static ModelType = ClientModel;
    static ModelApi = ClientApi;

    static activationComponent = 'CLIENT_PAGE';
    static variableNames = {
        detailsMap: 'clientDetailsMap',
        instanceId: 'clientId',
        isFormActive: 'isClientFormActive',
        updateDisplay: 'updatePage',
        updatePane: 'updatePage',
        route: routes.CLIENTS
    };

    static translations = {
        itemTitle: '$t(client:label.name)',
        itemLower: '$t(client:label.name_lower)'
    };

    static buildActivationEffects(dispatch) {
        return [
            ...super.buildActivationEffects(dispatch),
            // ACTIVATION EFFECTS
            put(ClientModel.actionCreators.startPollingDetails()),
            put(ClientPoolModel.actionCreators.startPollingDetails()),
            put(ExecutionProfileModel.actionCreators.startPollingDetails()),
            put(ResourcePoolModel.actionCreators.startPollingDetails())
        ]
    }

    static buildDeactivationEffects() {
        return [
            ...super.buildDeactivationEffects(),
            // DEACTIVATION EFFECTS
            put(ClientModel.actionCreators.stopPollingDetails()),
            put(ClientPoolModel.actionCreators.stopPollingDetails()),
            put(ExecutionProfileModel.actionCreators.stopPollingDetails()),
            put(ResourcePoolModel.actionCreators.stopPollingDetails())
        ]
    }

    static* setReduxState(args) {
        const {detailsMap, updateDisplay, instanceId, route} = this.variableNames;

        yield all([
            contextCall(this, 'initializeState'),
            // Setting clientId = '' to prevent autoSelection
            put(this.ModelType.componentActionCreators[updateDisplay]({[instanceId]: ''})),
        ]);

        if (args.id != null) {
            let [clientId, matterId, datasetId] = args.id.split('/matter?id=');
            if (matterId != null) {
                [matterId, datasetId] = matterId.split('/dataset?id=');
            }

            const details = yield select(state => state[detailsMap].get(clientId));
            if (details == null) {
                yield put(PopupModel.actionCreators.showError({
                    info: {
                        key: 'ModelCannotBeFound',
                        valueKeys: {
                            itemTitle: this.translations.itemTitle
                        }
                    }
                }));
            }

            yield all([
                put(this.ModelType.componentActionCreators[updateDisplay]({[instanceId]: clientId})),
                put(ClientModel.componentActionCreators.updateDisplay({matterId})),
                put(DatasetModel.componentActionCreators.updateView({datasetId}))
            ]);
        }

        if (args.cbEffect != null) {
            yield args.cbEffect;
        }

        window.location.href = `#${route}`;
    }

    static* submitForm(action) {
        const {formData} = action.payload;
        const saveValues = this.getSaveValues(formData);

        const clientPoolFilter = yield select(state => state.clientPoolFilter);
        const poolIds = getValues(clientPoolFilter)
            .filter(filter => filter.isChecked)
            .map(filter => filter.key);

        // If ClientPool(s) selected, post Client to first ClientPool and then update remainders
        let client;
        if (poolIds.length > 0) {
            const firstPool = poolIds[0];
            client = (yield contextCall(ClientPoolApi, 'postClient', firstPool, saveValues)).data;

            const effects = [];
            for (let i = 0; i < poolIds.length; i++) {
                const poolId = poolIds[i];
                effects[i] = contextCall(ClientPoolApi, 'putClient', poolId, client.id);
            }

            const data = (yield all(effects)).map(res => res.data);
            const idToUpdates = {};
            for (let i = 0; i < data.length; i++) {
                const clientPool = new ClientPoolModel(data[i]);

                idToUpdates[clientPool.id] = clientPool;
            }

            yield put(ClientPoolModel.actionCreators.updateDetails(idToUpdates));
        } else {
            client = (yield contextCall(ClientApi, 'post', saveValues)).data;
        }

        const {updateDisplay, isFormActive, instanceId} = this.variableNames;
        yield all([
            put(ClientModel.actionCreators.addDetails(client)),
            put(ClientModel.componentActionCreators[updateDisplay]({[isFormActive]: false, [instanceId]: client.id}))
        ]);
    }

    static* getEditValues(id) {
        const {name: clientName, ...rest} = yield select(state => state.clientDetailsMap.get(id));

        return {
            clientName,
            ...rest
        }
    }

    static getSaveValues(values) {
        const {clientName: name, description, enabled, defaultExecutionProfileId, defaultResourcePoolId} = values;

        const allowedParameterValues = ParameterModel.buildAllowedParameterValues(values.allowedParameterValues);
        const requiredMetadataHeaders = buildMapFromInputTable(values.requiredMetadataHeaders);

        const workflowParameters = {}
        for (let i = 0; i < values.workflowParameters.length; i++) {
            const [{value: name}, {value}] = values.workflowParameters[i];

            workflowParameters[name] = postProtectedValue(value);
        }

        return {
            name,
            description,
            enabled,
            defaultExecutionProfileId,
            defaultResourcePoolId,
            allowedParameterValues,
            requiredMetadataHeaders,
            workflowParameters
        }
    }

    static* queryDetails() {
        const showDisabled = yield select(state => state.userSettingsMap.get(userSettings.SHOW_DISABLED_ITEMS));
        const response = yield contextCall(ClientApi, 'getDetails', showDisabled.clients);

        const key = details.CLIENTS;
        if (AxiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(ClientModel.actionCreators.setDetailsMap(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }

    static* delete(action) {
        const {id} = action.payload;

        yield contextCall(ClientApi, 'delete', id);
        yield put(ClientModel.actionCreators.deleteDetails(id));
    }
}

export default ClientModel;