import ReduxModel from "./ReduxModel";
import {actionCreator, getEntries} from "../../utilities/helperFunctions";

class FilterModel extends ReduxModel {

    constructor(model = {}) {
        super(model);
        const { key, name } = model;

        this.key = key;
        this.name = name;
        this.isChecked = false;
    }

    static buildActions(type) {
        return {
            // FILTER ACTIONS
            SET_FILTER_MAP: `SET_${type}_DETAILS_MAP`,
            TOGGLE_FILTER: `TOGGLE_${type}_FILTER`,
            RESET_FILTER: `RESET_${type}_FILTER`
        }
    }

    static buildActionCreators(actions) {
        return {
            // FILTER ACTION CREATORS
            toggleFilter: actionCreator(actions.TOGGLE_FILTER, 'key'),
            resetFilter: actionCreator(actions.RESET_FILTER)
        }
    }

    static buildReducer(actions) {
        return function(state = new Map(), action) {
            switch (action.type) {
                case actions.SET_FILTER_MAP: {
                    const { details } = action.payload;

                    return this.setFilterMapGeneric(state, details, 'id');
                }
                case actions.TOGGLE_FILTER: {
                    const { key } = action.payload;

                    return this.toggleFilter(state, key);
                }
                case actions.RESET_FILTER: {
                    return this.resetFilter(state);
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }

    static setFilterMapGeneric(state = new Map(), details, key) {
        if (details.length !== state.size) {
            return this.updateFilterMapGeneric(state, details, key);
        }

        for (let i = 0; i < details.length; i++) {
            const {name, [key]: id} = details;
            const model = state.get(id);

            // If it's a new item or if it's name has changed
            if (model == null || model.name !== name) {
                return this.updateFilterMapGeneric(state, details, key);
            }
        }

        return state;
    }

    static updateFilterMapGeneric(state = new Map(), details, key) {
        const newState = new Map();

        for (let i = 0; i < details.length; i++) {
            const { name , [key]: id } = details[i];
            const oldModel = state.get(id);

            let model;
            if (oldModel == null) {
                model = new this({...details[i], key: id});
            } else {
                model = oldModel.duplicate({name});
            }

            newState.set(id, model);
        }

        return newState;
    }

    static toggleFilter(state = new Map(), key) {
        const model = state.get(key);

        const newModel = model.duplicate({isChecked: !model.isChecked});
        return new Map(state).set(key, newModel);
    }

    static resetFilter(state) {
        const newState = new Map();

        getEntries(state)
            .forEach(([key, oldModel]) => {
                const model = oldModel.duplicate({isChecked: false});
                newState.set(key, model);
            });

        return newState;
    }
}

export default FilterModel;