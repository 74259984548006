import React, {useCallback} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {createDropdownHandler} from "../../common/Dropdown/helpers";
import {applicationFeatures, updateMode} from "../../../utilities/constants";
import {useTranslation} from "react-i18next";
import {scheduleTriggerTypeKeys} from "../../../i18next/keys";
import JobScheduleModel from "../../../models/job/JobScheduleModel";
import Switch from "../../common/Switch/Switch";
import {ListDropdown} from "../../common/Dropdown/Dropdown";
import TimerTriggersPanel from "../triggerPanels/TimerTriggersPanel";
import DatasetEventTriggersPanel from "../triggerPanels/DatasetEventTriggersPanel";
import JobEventTriggersPanel from "../triggerPanels/JobEventTriggersPanel";
import LegalHoldEventTriggersPanel from "../triggerPanels/LegalHoldEventTriggersPanel";
import WebhookTriggerPanel from "../triggerPanels/WebhookTriggerPanel";


function ScheduleTriggerPane(props) {
    const {t} = useTranslation(['jobSchedule']);
    const dispatch = useDispatch();


    const jobScheduleForm = useSelector(state => state.componentStates.jobScheduleForm);
    const {
        scheduleTriggerType,
        scheduleTrigger,
        eventTrigger,
        webhookTrigger
    } = jobScheduleForm;

    const updateState = useCallback(updates => {
        dispatch(JobScheduleModel.componentActionCreators.updateForm(updates));
    }, []);

    const dropdownItemClick = createDropdownHandler({
        mode: updateMode.REDUX,
        updateState
    });


    return (
        <div className="pane-margin">
            <div className="display-input" style={{display: 'flex', alignItems: 'center'}}>
                <label className="label" style={{marginRight: '0.75rem'}}>
                    {t('jobSchedule:label.trigger')}:
                </label>
                <ScheduleTriggerTypeDropdown selectedScheduleTriggerType={scheduleTriggerType} onScheduleTriggerTypeSelect={dropdownItemClick}/>
            </div>

            <Switch>
                {scheduleTriggerType === scheduleTriggerTypeKeys.ON_SCHEDULE &&
                    <TimerTriggersPanel scheduleTrigger={scheduleTrigger} updateState={updateState}/>
                }
                {scheduleTriggerType === scheduleTriggerTypeKeys.ON_JOB_EVENT &&
                    <JobEventTriggersPanel eventTrigger={eventTrigger} updateState={updateState}/>
                }
                {scheduleTriggerType === scheduleTriggerTypeKeys.ON_DATASET_EVENT &&
                    <DatasetEventTriggersPanel eventTrigger={eventTrigger} updateState={updateState}/>
                }
                {scheduleTriggerType === scheduleTriggerTypeKeys.ON_LEGAL_HOLD_EVENT &&
                    <LegalHoldEventTriggersPanel eventTrigger={eventTrigger} updateState={updateState}/>
                }
                {scheduleTriggerType === scheduleTriggerTypeKeys.ON_WEBHOOK_TRIGGER &&
                    <WebhookTriggerPanel webhookTrigger={webhookTrigger} updateState={updateState}/>
                }
            </Switch>
        </div>
    )
}

function ScheduleTriggerTypeDropdown(props) {
    const {selectedScheduleTriggerType, onScheduleTriggerTypeSelect, ...rest} = props;
    const {t} = useTranslation(['aria', 'jobSchedule']);

    const canManageWebhookTriggers = useSelector(state => state.currentUser.features.includes(applicationFeatures.API_ADVANCED));
    const canManageLegalHoldTriggers = useSelector(state => state.currentUser.features.includes(applicationFeatures.VIEW_LEGAL_HOLDS));
    const canManageDataSetTriggers = useSelector(state => state.currentUser.features.includes(applicationFeatures.SCHEDULER_UPLOAD));
    const canManageJobTriggers = useSelector(state => state.currentUser.features.includes(applicationFeatures.SCHEDULER_UPLOAD));


    const allowedTriggerTypeKeys = []
    allowedTriggerTypeKeys.push(scheduleTriggerTypeKeys.ON_SCHEDULE)

    if (canManageJobTriggers) {
        allowedTriggerTypeKeys.push(scheduleTriggerTypeKeys.ON_JOB_EVENT)
    }

    if (canManageDataSetTriggers) {
        allowedTriggerTypeKeys.push(scheduleTriggerTypeKeys.ON_DATASET_EVENT)
    }

    if (canManageLegalHoldTriggers) {
        allowedTriggerTypeKeys.push(scheduleTriggerTypeKeys.ON_LEGAL_HOLD_EVENT)
    }

    if (canManageWebhookTriggers) {
        allowedTriggerTypeKeys.push(scheduleTriggerTypeKeys.ON_WEBHOOK_TRIGGER)
    }

    const scheduleTriggerTypeItems = allowedTriggerTypeKeys
        .map(_scheduleTrigger => ({
            name: t(`jobSchedule:scheduleTriggerType.${_scheduleTrigger}`),
            value: _scheduleTrigger
        }));

    console.log(scheduleTriggerTypeItems)

    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <ListDropdown id={'scheduleTriggerTypeDropdown'} name={'scheduleTriggerType'}
                aria-label={t('aria:hiddenAssistText.scheduleTriggerType')} value={selectedScheduleTriggerType}
                items={scheduleTriggerTypeItems} onItemSelect={onScheduleTriggerTypeSelect}
                {...rest}
            />
        </div>
    );
}

export default ScheduleTriggerPane;
