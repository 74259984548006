import ThirdPartyServiceModel from "../../../models/thirdparty/ThirdPartyServiceModel";
import ThirdPartyServiceForm, {ThirdPartyServiceFormBody} from "../ThirdPartyServiceForm";
import {useTranslation} from "react-i18next";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import React from "react";
import ExpandableContent from "../../common/ExpandableContent/ExpandableContent";
import UserServiceModel from "../../../models/user/UserServiceModel";

const initialState = {
    type: ThirdPartyServiceModel.Type.DERBY_CONTROL,
    serviceName: '',
    enabled: true,
    description: '',
    whitelistedCertFingerprints: [],

    url: '',

    authenticationScope: ThirdPartyServiceModel.AuthenticationScope.SERVICE,
    authenticationMethod: ThirdPartyServiceModel.AuthenticationMethod.OIDC_CLIENT_CREDENTIALS,
    authenticationServiceId: null
};
const allowedAuthenticationMethods = [
    ThirdPartyServiceModel.AuthenticationMethod.OIDC_CLIENT_CREDENTIALS
];

function DerbyControlServiceForm() {
    return (
        <ThirdPartyServiceForm initialState={initialState} FormBody={DerbyControlServiceFormBody}/>
    )
}

export function DerbyControlServiceFormBody(props) {
    return (
        <ThirdPartyServiceFormBody {...props} authenticationServiceType={UserServiceModel.types.OIDC} FormBody={FormBody}
            allowedAuthenticationMethods={allowedAuthenticationMethods}/>
    )
}

function FormBody(props) {
    const {t} = useTranslation(['thirdPartyService']);
    const {
        thirdPartyService,
        inputHandler,
        isDisabled
    } = props;

    return (
        <ExpandableContent label={t('thirdPartyService:label.derbyControlSettings')} isDisabled={isDisabled}>
            <div className="display-input">
                <HTMLTextInput label={t('thirdPartyService:label.url')} name={'url'}
                    value={thirdPartyService.url} onChange={inputHandler} isDisabled={isDisabled} isRequired/>
            </div>
        </ExpandableContent>
    )
}

export default DerbyControlServiceForm;
