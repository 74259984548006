import DetailsModel, {DetailsSaga} from "../generics/DetailsModel";
import ComponentStateModel from "../generics/ComponentStateModel";
import ExecutionProfileModel from "../settings/ExecutionProfileModel";
import ResourcePoolModel from "../settings/ResourcePoolModel";
import JobUserModel from "../user/JobUserModel";
import EngineModel from "../settings/EngineModel";
import ClientModel, {ClientSaga} from "../client/ClientModel";
import MatterModel, {MatterSaga} from "../client/MatterModel";
import LibraryModel, {LibrarySaga} from "../library/LibraryModel";
import WorkflowTemplateModel, {WorkflowTemplateApi, WorkflowTemplateSaga} from "../library/WorkflowTemplateModel";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import {all, call, fork, put, select, take, takeEvery, takeLatest, takeLeading} from "redux-saga/effects"
import {
    actionCreator,
    deepCopy,
    getEntries,
    getKeys,
    getLocaleDateTimeFromUTC,
    getValues,
    switchcase
} from "../../utilities/helperFunctions";
import {
    applicationFeatures,
    CLIENT_UNASSIGNED,
    details,
    jobCommands,
    jobQueueStates,
    QUERY_INTERVAL,
    routes,
    SLOW_QUERY_INTERVAL,
    userSettings
} from "../../utilities/constants";
import {
    contextCall,
    contextSaga,
    contextTakeLatest,
    contextTakeLeading,
    contextTakeLeadingPayload,
    isDisabledWrapper,
    poll
} from "../../saga/sagaFunctions";
import AuditLogModel from "../generics/AuditLogModel";
import JobOperationModel from "./JobOperationModel";
import ParameterModel from "../library/ParameterModel";
import JobWarningsModel from "./JobWarningsModel";
import JobInfosModel from "./JobInfosModel";
import JobErrorsModel from "./JobErrorsModel";
import ClientPoolModel from "../settings/ClientPoolModel";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import JobArchiveModel from "./JobArchiveModel";
import LicenceSourceModel from "../settings/LicenceSourceModel";
import {jobPageViewKeys, libraryFileTypeKeys, permissionKeys, priorityKeys} from "../../i18next/keys";
import JobScheduleModel from "./JobScheduleModel";
import {genericErrorHandler, tryCatchWrapper} from "../../saga/tryCatchWrapper";
import PopupModel from "../scheduler/PopupModel";
import Encrypto from "../../utilities/encrypto";
import DatasetModel from "../data/DatasetModel";
import {validateParameters} from "../../utilities/shouldEnableFunctions";
import LibraryFileModel from "../filelibrary/LibraryFileModel";
import JobRequiredProfilesModel from "./JobRequiredProfilesModel";
import JobSoftErrorsModel from "./JobSoftErrorsModel";
import SchedulerModel from "../scheduler/SchedulerModel";
import UserModel from "../user/UserModel";
import ThirdPartyServiceModel, {ThirdPartyServiceSaga} from "../thirdparty/ThirdPartyServiceModel";
import ThirdPartyUserCredential from "../thirdparty/ThirdPartyUserCredential";
import DataRepositoryModel from "../data/DataRepositoryModel";
import JobLinksModel from "./JobLinksModel";
import {GuidedJobSaga} from "../guidedjob/GuidedJobModel";


class JobModel extends DetailsModel {

    static nom = 'JobModel';
    static actions = JobModel.buildActions();
    static actionCreators = JobModel.buildActionCreators(JobModel.actions);
    static reducer = JobModel.buildReducer(JobModel.actions);

    static componentActionCreators = {
        ...JobModel.buildComponentUpdateActionCreators(),
        ...JobModel.buildComponentSetActiveActionCreators()
    };

    constructor(model = {}) {
        super(model);
        const {confidential, excludeUtilization, notes, priority, locked, stagedBy, stagedDate, submittedBy, submittedDate, autoSubmitOnDate, executionState, percentageComplete, hasWarnings, hasSoftErrors, engineHasWarnings,
            clientId, matterId, libraryId, libraryWorkflowId, executionProfileId, resourcePoolId, engineId, workerAgentIds, runningOperationId,
            sessionParameters, executionParameters, parameterLock, matterLock, startedDate, finishedDate, missingRequiredProfiles
        } = model;

        this.excludeUtilization = excludeUtilization;
        this.confidential = confidential;
        this.notes = notes || '';
        this.priority = priority;
        this.locked = locked;
        this.stagedBy = stagedBy;
        this.stagedDate = getLocaleDateTimeFromUTC(stagedDate);
        this.submittedBy = submittedBy;
        this.submittedDate = getLocaleDateTimeFromUTC(submittedDate);
        this.submittedDateUtc = submittedDate;
        this.autoSubmitOnDate = getLocaleDateTimeFromUTC(autoSubmitOnDate);
        this.startedDate = getLocaleDateTimeFromUTC(startedDate);
        this.finishedDate = getLocaleDateTimeFromUTC(finishedDate);

        this.executionState = executionState;
        this.percentageComplete = (!isNaN(percentageComplete) && percentageComplete !== null) ? Math.round(percentageComplete) : '0';
        this.hasWarnings = hasWarnings;
        this.hasSoftErrors = hasSoftErrors;
        this.engineHasWarnings = engineHasWarnings;
        this.missingRequiredProfiles = missingRequiredProfiles;
        this.queueState = model.inStaging ? jobQueueStates.STAGING : jobQueueStates.BACKLOG;

        this.clientId = clientId;
        this.matterId = matterId;
        this.libraryId = libraryId;
        this.workflowTemplateId = libraryWorkflowId;
        this.executionProfileId = executionProfileId;
        this.resourcePoolId = resourcePoolId;
        this.engineId = engineId;
        this.workerAgentIds = workerAgentIds;

        this.sessionParameters = sessionParameters;
        this.executionParameters = executionParameters;
        this.parameterLock = parameterLock;
        this.matterLock = matterLock;

        this.runningOperationId = runningOperationId;

        this.lastUserComment = model.lastUserComment;
        this.currentOperationProcessingSpeedText = model.currentOperationProcessingSpeedText;
    }

    static buildActions() {
        return {
            ...super.buildActions('JOB'),
            // JOB ACTIONS
            SEND_UPDATES: 'SEND_JOB_UPDATES',
            SEND_COMMAND: 'SEND_JOB_COMMAND',
            SEND_COMMANDS: 'SEND_JOB_COMMANDS',
            // QUEUE JOB ACTIONS
            HIDE_FORM: 'HIDE_JOB_FORM',

            SHOULD_ENABLE_QUEUE_JOB: 'SHOULD_ENABLE_QUEUE_JOB',
            PREPARE_GET_DEFAULT_JOB_SETTINGS_SAGA: 'PREPARE_GET_DEFAULT_JOB_SETTINGS_SAGA',
            PREPARE_GET_OPERATIONS_AND_PARAMETERS_SAGA: 'PREPARE_GET_OPERATIONS_AND_PARAMETERS_SAGA',
            GET_OPERATIONS_AND_PARAMETERS: 'GET_OPERATIONS_AND_PARAMETERS',

            UPDATE_PARAMETER: 'UPDATE_JOB_PARAMETER',
            ENCRYPT_PROTECTED_PARAMETERS: 'ENCRYPT_PROTECTED_JOB_PARAMETERS',
            DECRYPT_PROTECTED_PARAMETERS: 'DECRYPT_PROTECTED_JOB_PARAMETERS',

            GET_REQUIRED_PROFILES: 'GET_REQUIRED_JOB_PROFILES',
            UPDATE_EXECUTION_PROFILE: 'UPDATE_JOB_EXECUTION_PROFILE',

            LOAD_TSV_PARAMETERS: 'LOAD_TSV_PARAMETERS',
            RESET_PARAMETERS: 'RESET_PARAMETERS'
        }
    }

    static buildActionCreators(actions) {
        return {
            ...super.buildActionCreators(actions),
            // JOB ACTION CREATORS
            sendUpdates: actionCreator(actions.SEND_UPDATES, 'id', 'updates'),
            sendCommand: actionCreator(actions.SEND_COMMAND, 'id', 'command', 'args'),
            sendCommands: actionCreator(actions.SEND_COMMANDS, 'ids', 'command'),
            // QUEUE JOB ACTION CREATORS
            showForm: actionCreator(actions.SHOW_FORM, 'initialState'),
            hideForm: actionCreator(actions.HIDE_FORM),

            shouldEnableQueueJob: actionCreator(actions.SHOULD_ENABLE_QUEUE_JOB),
            prepareGetDefaultJobSettingsSaga: actionCreator(actions.PREPARE_GET_DEFAULT_JOB_SETTINGS_SAGA),
            prepareGetOperationsAndParametersSaga: actionCreator(actions.PREPARE_GET_OPERATIONS_AND_PARAMETERS_SAGA),
            getOperationsAndParameters: actionCreator(actions.GET_OPERATIONS_AND_PARAMETERS),

            updateParameter: actionCreator(actions.UPDATE_PARAMETER, 'name', 'value', 'displayable'),
            encryptProtectedParameters: actionCreator(actions.ENCRYPT_PROTECTED_PARAMETERS),
            decryptProtectedParameters: actionCreator(actions.DECRYPT_PROTECTED_PARAMETERS),

            getMissingRequiredProfiles: actionCreator(actions.GET_REQUIRED_PROFILES, 'executionProfileId', 'workflowXml', 'workflowTemplateId', 'id'),
            updateExecutionProfile: actionCreator(actions.UPDATE_EXECUTION_PROFILE, 'jobId', 'executionProfileId', 'workflowXml', 'workflowTemplateId'),
            loadTsvParameters: actionCreator(actions.LOAD_TSV_PARAMETERS, 'tsvText'),
            resetParameters: actionCreator(actions.RESET_PARAMETERS)
        }
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'jobPage',
                type: 'Page',
                state: {
                    activeView: jobPageViewKeys.QUEUE,
                    jobId: null,
                    isQueueJobFormActive: false
                }
            },
            {
                key: 'jobQueueForm',
                type: 'Form',
                state: {
                    clientId: null,
                    matterId: null,
                    libraryId: null,
                    workflowTemplateId: null,
                    jobName: '',
                    queueState: jobQueueStates.BACKLOG,
                    priority: priorityKeys.MEDIUM,
                    executionProfileId: null,
                    resourcePoolId: null,
                    notes: '',
                    parameters: null,
                    operations: null,
                    didUserChange: {},
                    isQueueEnabled: false,
                    isLoading: false,
                    isLoadingSettings: false,
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'JOB_PAGE',
                type: 'Page',
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }

    static getSubmitPermissions(reduxState, jobOptions) {
        const {
            matterId,
            workflowTemplateId,
            resourcePoolId,
            executionProfileId,
            parameters
        } = jobOptions;

        let submitPermissions = [permissionKeys.SUBMIT_JOB, permissionKeys.STAGE_JOB];
        function filterOnObjectPermission(object) {
            if (object != null && Array.isArray(object.userPermissions)) {
                submitPermissions = submitPermissions.filter(permission => object.userPermissions.includes(permission));
            }
        }

        if (matterId) {
            filterOnObjectPermission(reduxState.matterDetailsMap.get(matterId));
        }
        if (workflowTemplateId) {
            filterOnObjectPermission(reduxState.workflowTemplateDetailsMap.get(workflowTemplateId));
        }
        if (resourcePoolId) {
            filterOnObjectPermission(reduxState.resourcePoolDetailsMap.get(resourcePoolId));
        }
        if (executionProfileId) {
            filterOnObjectPermission(reduxState.executionProfileDetailsMap.get(executionProfileId));
        }
        if (Array.isArray(parameters) && parameters.length > 0) {
            for (const param of parameters) {
                if (param.isThirdPartyServiceParameter() && param.value) {
                    filterOnObjectPermission(reduxState.thirdPartyServiceDetailsMap.get(param.value));
                }
            }
        }
        return submitPermissions;
    }
}

export class JobApi {

    static getDetails() {
        return axiosInstance.get('/scheduler/jobs/filterResults');
    }

    static getSettings(id) {
        return axiosInstance.get(`/scheduler/jobs/${id}/details`);
    }

    static getWorkflowOperations(workflowXml) {
        return axiosInstance.post('/scheduler/jobs/workflowOperations', {workflowXml});
    }

    static getOneTimeKey() {
        return axiosInstance.get('/scheduler/security/oneTimeKey');
    }

    static getWorkflowRequiredSessionParameters(workflowXml) {
        return axiosInstance.post('/scheduler/jobs/requiredSessionParameters', {workflowXml});
    }

    static getMissingRequiredProfileNames (executionProfileId, workflowXml, libraryWorkflowId, jobId) {
        return axiosInstance.post('/scheduler/jobs/requiredProfiles', {executionProfileId, workflowXml, libraryWorkflowId, id: jobId});
    }

    static getThirdPartyServiceUserCredentials(id) {
        return axiosInstance.get(`/scheduler/jobs/${id}/thirdPartyServiceUsers`);
    }

    static post(job) {
        return axiosInstance.post('/scheduler/jobs', job);
    }

    static putDetails(id, details) {
        return axiosInstance.put(`/scheduler/jobs/${id}/settings`, details);
    }

    static putCommand(id, command, args) {
        return axiosInstance.put(`/scheduler/jobs/${id}/execution`, {command, ...args});
    }

    static putCommands(ids, command) {
        return axiosInstance.put('/scheduler/jobs/execution', {
            command,
            jobIds: ids
        });
    }
}

export class JobSaga extends DetailsSaga {

    static ModelType = JobModel;
    static ModelApi = JobApi;

    static activationComponent = 'JOB_PAGE';
    static variableNames = {
        detailsMap: 'jobDetailsMap',
        instanceId: 'jobId',
        updateDisplay: 'updatePage',
        updatePane: 'updateTablet',
        route: routes.JOBS
    };

    static translations = {
        itemTitle: '$t(job:label.name)',
        itemLower: '$t(job:label.name_lower)'
    };
    // Encrypto for password parameters
    static encrypto;

    static buildActivationEffects(dispatch) {
        function* initializeJobDisplay() {
            // Call modelNames after respective display query returns
            yield take([JobModel.actions.SET_DETAILS_MAP, JobArchiveModel.actions.SET_DETAILS_MAP, JobScheduleModel.actions.SET_DETAILS_MAP]);
            yield contextCall(JobSaga, 'getModelNames');
        }

        return [
            fork(initializeJobDisplay),
            // ACTIVATION EFFECTS
            fork(poll, QUERY_INTERVAL, contextSaga(this, 'getModelNames')),

            contextTakeLatest(JobModel.actions.SHOW_FORM, this, 'showForm'),
            contextTakeLatest(JobModel.actions.SHOW_TABLET, this, 'showPane'),
            contextTakeLatest(JobModel.actions.HIDE_FORM, this, 'hideForm'),
            contextTakeLeading(JobModel.actions.HIDE_TABLET, this, 'hidePane'),

            contextTakeLatest(JobModel.actions.SHOULD_ENABLE_QUEUE_JOB, this, 'shouldEnableQueueJob'),
            contextTakeLeading(JobModel.actions.PREPARE_GET_DEFAULT_JOB_SETTINGS_SAGA, this, 'getDefaultProfilePool'),
            contextTakeLeading(JobModel.actions.PREPARE_GET_OPERATIONS_AND_PARAMETERS_SAGA, this, 'getOperationsAndParameters'),
            contextTakeLeading([JobModel.actions.PREPARE_GET_DEFAULT_JOB_SETTINGS_SAGA, JobModel.actions.PREPARE_GET_OPERATIONS_AND_PARAMETERS_SAGA], this, 'getJobNameFromMatterAndWorkflow'),

            takeEvery(JobModel.actions.UPDATE_PARAMETER, tryCatchWrapper, contextSaga(this, 'updateParameter')),
            contextTakeLatest(JobModel.actions.ENCRYPT_PROTECTED_PARAMETERS, this, 'encryptProtectedValues'),
            contextTakeLatest(JobModel.actions.DECRYPT_PROTECTED_PARAMETERS, this, 'decryptProtectedValues'),

            takeLatest(JobModel.actions.SUBMIT_FORM, isDisabledWrapper, JobModel.componentActionCreators.updateForm, contextSaga(this, 'submitForm')),
            takeLatest(JobModel.actions.LOAD_TSV_PARAMETERS, tryCatchWrapper, isDisabledWrapper, JobModel.componentActionCreators.updateForm, contextSaga(this, 'loadTsvParameters')),
            takeLatest(JobModel.actions.RESET_PARAMETERS, isDisabledWrapper, JobModel.componentActionCreators.updateForm, contextSaga(this, 'resetParameters')),

            takeLatest(JobModel.actions.UPDATE_EXECUTION_PROFILE, tryCatchWrapper, contextSaga(this, 'updateExecutionProfile')),
            takeLatest(JobModel.actions.GET_REQUIRED_PROFILES, tryCatchWrapper, contextSaga(this, 'getMissingRequiredProfiles')),

            contextTakeLeadingPayload(JobModel.actions.SEND_UPDATES, this, 'sendUpdates'),
            contextTakeLeading(JobModel.actions.SEND_COMMAND, this, 'sendCommand'),
            contextTakeLeading(JobModel.actions.SEND_COMMANDS, this, 'sendCommands'),
            takeLeading(JobModel.actions.DUPLICATE_DETAILS, tryCatchWrapper, contextSaga(this, 'duplicate')),

            put(JobUserModel.actionCreators.startPollingDetails()),
            put(EngineModel.actionCreators.startPollingDetails()),
            put(ResourcePoolModel.actionCreators.startPollingDetails()),
            put(ExecutionProfileModel.actionCreators.startPollingDetails()),
            put(UserModel.actionCreators.startPollingDetails(SLOW_QUERY_INTERVAL)),
            put(ThirdPartyServiceModel.actionCreators.startPollingDetails(SLOW_QUERY_INTERVAL))
        ]
    }

    static buildDeactivationEffects() {
        return [
            // DEACTIVATION EFFECTS
            put(JobUserModel.actionCreators.stopPollingDetails()),
            put(EngineModel.actionCreators.stopPollingDetails()),
            put(ResourcePoolModel.actionCreators.stopPollingDetails()),
            put(ExecutionProfileModel.actionCreators.stopPollingDetails()),
            put(UserModel.actionCreators.stopPollingDetails()),
            put(ThirdPartyServiceModel.actionCreators.stopPollingDetails())
        ]
    }

    static* setInstanceId(args) {
        const {updateDisplay, instanceId} = this.variableNames;
        yield put(this.ModelType.componentActionCreators[updateDisplay]({activeView: jobPageViewKeys.QUEUE, [instanceId]: args.id}));
    }

    static* sendUpdates(action) {
        const {id, updates} = action.payload;

        const {data: {settings}} = yield contextCall(JobApi, 'putDetails', id, updates);
        yield put(JobModel.actionCreators.updateDetails({[id]: new JobModel(settings)}));
    }

    static* sendCommand(action) {
        const {id, command, args} = action.payload;
        const {data} = yield contextCall(JobApi, 'putCommand', id, command, args);

        switch (command) {
            case jobCommands.ARCHIVE:
                yield all([
                    put(JobArchiveModel.actionCreators.startPollingArchiveExists()),
                    put(JobModel.actionCreators.hideTablet())
                ]);
                break;
            case jobCommands.RESUBMIT_JOB:
                let {activeView} = yield select(state => state.componentStates.jobPage);
                if (activeView === jobPageViewKeys.ARCHIVE) {
                    activeView = jobPageViewKeys.QUEUE;
                }
                yield all([
                    put(JobModel.componentActionCreators.updatePage({activeView: activeView})),
                    put(JobModel.actionCreators.showTablet(data.id))
                ]);
                break;
        }

        // Update job after command
        yield put(JobModel.actionCreators.querySettings(id));
    }

    static* sendCommands(action) {
        const {ids, command} = action.payload;

        yield contextCall(JobApi, 'putCommands', ids, command);
        if (command === jobCommands.ARCHIVE) {
            yield put(JobArchiveModel.actionCreators.startPollingArchiveExists());
        }
    }

    static* showForm(action) {
        const {initialState} = action.payload || action;
        yield put(JobModel.componentActionCreators.resetForm());

        yield all([
            put(JobModel.componentActionCreators.updateForm(initialState)),
            put(JobModel.componentActionCreators.updatePage({isQueueJobFormActive: true}))
        ]);

        // Get one time key
        let response = yield contextCall(JobApi, 'getOneTimeKey');

        this.encrypto = new Encrypto(response.data.key, response.data.ivSeed);
        this.sessionParametersKeyId = response.data.id

        // Clear XMLHttpRequest data by clearing each key-value pair
        getKeys(response)
            .forEach(key => response[key] = null);
    }

    static* hideForm() {
        // Reset form values and clear encrypto
        yield all([
            put(JobModel.componentActionCreators.updatePage({
                isQueueJobFormActive: false
            })),
            put(JobModel.componentActionCreators.resetForm())
        ]);

        // Clear values
        this.encrypto.clear();
        this.encrypto = null;
        this.sessionParametersKeyId = null;
    }

    static* submitForm() {
        const jobQueueForm = yield select(state => state.componentStates.jobQueueForm);
        const {jobName, matterId, workflowTemplateId, executionProfileId, resourcePoolId, priority, queueState, notes, parameters} = jobQueueForm;

        const job = {
            sessionParametersKeyId: this.sessionParametersKeyId,
            name: jobName,
            matterId,
            inStaging: queueState === jobQueueStates.STAGING,
            libraryWorkflowId: workflowTemplateId,
            executionProfileId,
            resourcePoolId,
            priority,
            notes,
            sessionParameters: getValues(parameters).map(param => {
                return {
                    ...param,
                    userDisplayableValue: param.getUserDisplayableValue(),
                };
            })
        };

        const {data} = yield contextCall(JobApi, 'post', job);
        yield put(JobModel.actionCreators.addDetails(data));

        yield all([
            put(JobModel.componentActionCreators.updatePage({
                activeView: jobPageViewKeys.QUEUE
            })),
            put(JobModel.actionCreators.hideForm()),
            put(JobModel.actionCreators.showTablet(data.id))
        ]);
    }

    static* getDefaultProfilePool() {
        const {clientId: oldClientId, matterId: oldMatterId} = yield select(state => state.componentStates.jobQueueForm);

        yield take(JobModel.actions.GET_OPERATIONS_AND_PARAMETERS);
        const {clientId, matterId, didUserChange} = yield select(state => state.componentStates.jobQueueForm);

        if ((clientId !== oldClientId) || (matterId !== oldMatterId)) {
            const defaultProfilePool = yield *this.getDefaultExecutionProfileAndResourcePool({clientId, matterId});
            const updates = {};
            if (!didUserChange.executionProfile) {
                updates.executionProfileId = defaultProfilePool.executionProfileId;
            }
            if (!didUserChange.resourcePool) {
                updates.resourcePoolId = defaultProfilePool.resourcePoolId
            }
            yield put(JobModel.componentActionCreators.updateForm(updates));
        }
    }

    static* getDefaultExecutionProfileAndResourcePool(opts={}) {
        const {
            clientId,
            matterId
        } = opts;
        const [defaultJobSettings, client, matter] = yield select(state => {
            return [
                state.userSettingsMap.get(userSettings.DEFAULT_JOB_SETTINGS),
                state.clientDetailsMap.get(clientId) || {},
                state.matterDetailsMap.get(matterId) || {}
            ]
        });
        return {
            executionProfileId: matter.defaultExecutionProfileId || client.defaultExecutionProfileId || defaultJobSettings.executionProfileId,
            resourcePoolId: matter.defaultResourcePoolId || client.defaultResourcePoolId || defaultJobSettings.resourcePoolId
        };
    }

    static* getOperationsAndParameters() {
        const {workflowTemplateId: oldWorkflowTemplateId} = yield select(state => state.componentStates.jobQueueForm);
        // Reset force to null so Redux can register next force position
        // Otherwise Redux will think there was no change; if force.position is set to 1 when it was already set to 1
        yield put(JobModel.componentActionCreators.updateForm({force: null}));

        // Waits for action
        const action = yield take([JobModel.actions.GET_OPERATIONS_AND_PARAMETERS, JobModel.actions.HIDE_FORM]);
        if (action.type === JobModel.actions.HIDE_FORM) {
            return;
        }
        let jobQueueForm = yield select(state => state.componentStates.jobQueueForm);

        try {
            yield put(JobModel.componentActionCreators.updateForm({
                isLoadingSettings: true
            }));

            const workflowTemplateId = jobQueueForm.workflowTemplateId;
            yield contextCall(WorkflowTemplateSaga, 'querySettings', {payload: {id: workflowTemplateId}});

            let [operations, newParameters] = yield select(state => ([state.templateOperationsMap.get(workflowTemplateId), deepCopy(state.parametersMap.get(workflowTemplateId))]));
            // Maintain values for same parameters
            if (jobQueueForm.parameters != null) {
                if (workflowTemplateId === oldWorkflowTemplateId) {
                    newParameters = jobQueueForm.parameters;
                } else {
                    for (const [key, param] of jobQueueForm.parameters) {
                        const newParam = newParameters.get(key);
                        if (newParam != null) {
                            newParam.value = param.value;
                            newParam.valueName = param.valueName;
                        }
                    }
                }
            }

            yield put(JobModel.componentActionCreators.updateForm({operations, parameters: newParameters}));
            yield contextCall(JobSaga, 'updateJobParametersAllowedValues');
            yield contextCall(JobSaga, 'shouldEnableQueueJob');

        } finally {
            // Reset isLoading
            yield put(JobModel.componentActionCreators.updateForm({
                isLoadingSettings: false
            }));
        }
    }

    static* updateJobParametersAllowedValues() {
        function* handleError(error) {
            yield contextCall(JobSaga, 'handleJobParametersAllowedValuesError', error);
        }
        try {
            yield put(JobModel.componentActionCreators.updateForm({isDisabled: true}));
            const jobQueueForm = yield select(state => state.componentStates.jobQueueForm);
            const parameters = yield WorkflowTemplateSaga.updateParametersAllowedValues(jobQueueForm, handleError);
            yield put(JobModel.componentActionCreators.updateForm({parameters}));
        } finally {
            yield put(JobModel.componentActionCreators.updateForm({isDisabled: false}));
        }
    }

    static* updateParameter(action) {
        const jobQueueForm = yield select(state => state.componentStates.jobQueueForm);
        const callbacks = this.getParameterUpdateCallbacks();
        yield contextCall(WorkflowTemplateSaga, 'debounceParameterUpdate', action.payload, jobQueueForm, callbacks);
    }

    static getParameterUpdateCallbacks() {
        return {
            handleUpdate: function* (update) {
                yield put(JobModel.componentActionCreators.updateForm(prev => ({parameters: update(prev.parameters)})));
                yield contextCall(JobSaga, 'shouldEnableQueueJob');
            },
            handleError: function* (error) {
                yield contextCall(JobSaga, 'handleJobParametersAllowedValuesError', error);
            }
        }
    }

    static* handleJobParametersAllowedValuesError(error) {
        if (error.cause) {
            switch (error.cause) {
                case 'noFinalizedOrAllowedDatasets': {
                    yield contextCall(WorkflowTemplateSaga, 'handleNoDataSetsError', error, {
                        hideFormActionCreator: JobModel.actionCreators.hideForm
                    });
                    break;
                }
                default:
                    yield put(PopupModel.actionCreators.showError({
                        id: error.cause,
                        info: {
                            key: error.cause,
                            values: error.data
                        }
                    }));
            }
        } else {
            yield contextCall(WorkflowTemplateSaga, 'handleAllowedParameterValuesError', error);
        }
    }

    static* loadTsvParameters(action) {
        const {tsvText} = action.payload;
        const parameters = yield select(state => deepCopy(state.componentStates.jobQueueForm.parameters));
        const updatedParameters = yield WorkflowTemplateSaga.loadTsvParameters({tsvText, parameters});

        yield put(JobModel.componentActionCreators.updateForm({parameters: updatedParameters}));
        yield contextCall(JobSaga, 'shouldEnableQueueJob');
    }

    static* resetParameters() {
        const jobQueueForm = yield select(state => state.componentStates.jobQueueForm);
        const updatedParameters = yield WorkflowTemplateSaga.resetParameters(jobQueueForm);

        yield put(JobModel.componentActionCreators.updateForm({parameters: updatedParameters}));
        yield contextCall(JobSaga, 'shouldEnableQueueJob');
    }

    static* shouldEnableQueueJob() {
        const {jobName, parameters} = yield select(state => state.componentStates.jobQueueForm);

        yield put(JobModel.componentActionCreators.updateForm({
            isQueueEnabled: !!jobName && validateParameters(parameters)
        }));
    }

    static* encryptProtectedValues() {
        const {parameters} = yield select(state => state.componentStates.jobQueueForm);

        yield put(JobModel.componentActionCreators.updateForm({
            parameters: ParameterModel.encryptProtectedParametersFromHTML('parameterList', this.encrypto, parameters)
        }));
    }

    static* decryptProtectedValues() {
        const {parameters} = yield select(state => state.componentStates.jobQueueForm);

        ParameterModel.decryptProtectedParametersToHTML('parameterList', this.encrypto, parameters);
    }

    static* getJobNameFromMatterAndWorkflow() {
        const {matterId: oldMatterId, workflowTemplateId: oldWorkflowTemplateId} = yield select(state => state.componentStates.jobQueueForm);

        yield take(JobModel.actions.GET_OPERATIONS_AND_PARAMETERS);
        const {matterId, workflowTemplateId, workflowName, didUserChange} = yield select(state => state.componentStates.jobQueueForm);

        const workflowChanged = (workflowTemplateId !== oldWorkflowTemplateId);
        const matterChanged = (matterId !== oldMatterId);

        // Update only if user didn't change name
        if (!didUserChange.jobName && (workflowChanged || matterChanged)) {

            const [matterDetails, workflowTemplateDetails] = yield select(state => ([
                state.matterDetailsMap.get(matterId),
                state.workflowTemplateDetailsMap.get(workflowTemplateId)
            ]));

            const matterName = matterDetails ? `${matterDetails.name} - ` : '';

            let workflowTemplateName;
            if (workflowTemplateDetails == null) {
                workflowTemplateName = workflowName || '';
            } else {
                workflowTemplateName = workflowTemplateDetails.name;
            }

            yield put(JobModel.componentActionCreators.updateForm({
                jobName: matterName + workflowTemplateName
            }));
        }
    }

    static* addWorkflowFromQueue(args) {
        const {updateState, workflowName, workflowXml, libraryId} = args;
        try {
            updateState({isDisabled: true});
            const workflow = {
                name: workflowName,
                operationsXml: workflowXml,
                enabled: true
            }

            const {data} = yield contextCall(WorkflowTemplateApi, 'post', workflow,  libraryId, true, args.updateExisting, args.createCopy);
            // Update after overwriting workflow
            if (args.updateExisting) {
                yield put(WorkflowTemplateModel.actionCreators.queryDetailsSubset(libraryId));
            }

            yield all([
                put(WorkflowTemplateModel.actionCreators.addDetails(data)),
                updateState({
                    workflowTemplateId: data.id,
                    libraryId: data.libraryId,
                    workflowName: data.name
                })
            ]);
        } catch (error) {
            function retryAddWorkflow(dispatch, updateExisting, createCopy) {
                const addWorkflowFromQueueEffect = call(JobSaga.addWorkflowFromQueue, {updateState, workflowName, workflowXml, libraryId, updateExisting, createCopy});
                dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(addWorkflowFromQueueEffect));
            }
            // Custom error callback to attach a callback to error popup
            const handleResponseError = function* (error) {
                const {key, values} = error.response.data;
                let workflowTemplateId = null;

                switch (key) {
                    case 'workflowXmlExists':
                        workflowTemplateId = values.existingWorkflowTemplateId;

                        yield all([
                            put(PopupModel.actionCreators.showInfo({
                                info: error.response.data
                            })),
                            updateState({
                                workflowTemplateId: workflowTemplateId,
                                workflowName: values.existingWorkflowTemplateName || ""
                            })
                        ]);
                        break;

                    case 'workflowXmlExistsOfferOverwriteOrCopy':
                    case 'workflowExistsOfferOverwriteOrCopy':
                        yield put(PopupModel.actionCreators.showWarning({
                            info: error.response.data,
                            buttons: [{
                                titleKey: 'common:option.update',
                                onClick: dispatch => {
                                    retryAddWorkflow(dispatch, true, false);
                                }
                            }, {
                                titleKey: 'common:option.copy',
                                onClick: dispatch => {
                                    retryAddWorkflow(dispatch, false, true);
                                }
                            }]
                        }));
                        break;

                    case 'workflowExistsInJobOfferCopy':
                    case 'workflowExistsInScheduleOfferCopy':
                    case 'workflowExistsInPurviewOfferCopy':
                        yield put(PopupModel.actionCreators.showWarning({
                            info: error.response.data,
                            buttons: [{
                                titleKey: 'common:option.copy',
                                onClick: dispatch => {
                                    retryAddWorkflow(dispatch, false, true);
                                }
                            }]
                        }));
                        break;

                    default:
                        yield call(genericErrorHandler, error);
                }
            }.bind(this);

            // Call genericErrorHandler with custom response error callback
            yield call(genericErrorHandler, error, {responseErrorCb: handleResponseError});
        } finally {
            updateState({isDisabled: false});
        }
    }

    static* updateExecutionProfile(action) {
        const {jobId, executionProfileId, workflowXml, workflowTemplateId} = action.payload;

        if (!!executionProfileId) {
            let missingProfiles = [];
            const {data} = yield contextCall(JobApi, 'getMissingRequiredProfileNames', executionProfileId, workflowXml, workflowTemplateId, jobId);

            if (jobId == null) {
                yield put(JobModel.componentActionCreators.updateForm({
                    requiredProfiles: data
                }));
            } else {
                yield put(JobRequiredProfilesModel.actionCreators.setSetting(jobId, data));
            }

            Object.keys(libraryFileTypeKeys).forEach(type => {
                const missingProfileTypeValues = data
                    .filter(profile => profile.profileType === type && profile.missing)
                    .map(profile => profile.name);

                if (missingProfileTypeValues.length > 0) {
                    let typeKey = missingProfileTypeValues.length === 1 ? 'type' : 'typePlural';
                    missingProfiles.push(`$t(fileLibrary:file.${typeKey}.${type}): ${missingProfileTypeValues.join(", ")}`);
                }
            });

            const missingProfilesMessage = missingProfiles.join("\n");

            if (!!missingProfilesMessage) {
                yield put(PopupModel.actionCreators.showWarning({
                    info: {
                        key: 'missingRequiredProfiles',
                        values: {
                            missingProfiles: missingProfilesMessage
                        }
                    },
                    buttons: [{
                        titleKey: 'common:option.ok',
                    }],
                }));
            }
        }

        if (jobId == null) {
            yield put(JobModel.componentActionCreators.updateForm(prevState => ({
                    executionProfileId: executionProfileId,
                    didUserChange: {
                        ...prevState.didUserChange,
                        executionProfile: true
                }
            })));
            yield put(JobModel.actionCreators.shouldEnableQueueJob());
        } else {
            yield JobSaga.sendUpdates({payload: {id: jobId, updates: {executionProfileId}}});
        }
    }

    static* getMissingRequiredProfiles(action) {
        const {executionProfileId, workflowXml, workflowTemplateId, id} = action.payload;
        const pageJobId = yield select(state => state.componentStates.jobPage.jobId);
        const jobId = pageJobId || id;

        let data;
        try {
            const response = yield contextCall(JobApi, 'getMissingRequiredProfileNames', executionProfileId, workflowXml, workflowTemplateId, jobId);
            data = response.data;
        } catch (e) {}


        if (jobId == null) {
            yield put(JobModel.componentActionCreators.updateForm({
                requiredProfiles: data
            }));
        } else {
            yield put(JobRequiredProfilesModel.actionCreators.setSetting(jobId, data));
        }
    }

    static* duplicate(action) {
        const {updateDisplay, instanceId} = this.variableNames;
        const {id} = action.payload;

        const {activeView} = yield select(state => state.componentStates.jobPage);
        if ([jobPageViewKeys.PURVIEW, jobPageViewKeys.VAULT].includes(activeView)) {
            return yield* GuidedJobSaga.duplicate(id);
        }

        let detailsMap = 'jobDetailsMap';
        if (activeView === jobPageViewKeys.ARCHIVE) {
            detailsMap = 'jobArchiveMap';
        }

        const jobDetailsMap = yield select(state => state[detailsMap]);
        const jobDetails = jobDetailsMap.get(id);

        try {
            yield all([
                put(JobModel.componentActionCreators[updateDisplay]({[instanceId]: null})),
                contextCall(JobSaga, 'showForm', {initialState: {isLoading: true}})
            ]);

            const [jobName, [clientId, matterId], [libraryId, workflowTemplateId]] = yield all([
                contextCall(this, 'getUniqueName', jobDetails.name, jobDetailsMap),
                contextCall(this, 'getClientAndMatterId', jobDetails.clientId, jobDetails.matterId),
                contextCall(this, 'getLibraryAndWorkflowId', jobDetails.libraryId, jobDetails.workflowTemplateId)
            ]);

            const {executionProfileId, resourcePoolId, priority, queueState, notes} = jobDetails;
            const job = {
                jobName,
                clientId,
                matterId,
                libraryId,
                workflowTemplateId,
                executionProfileId,
                resourcePoolId,
                priority,
                queueState,
                notes
            };
            yield put(JobModel.componentActionCreators.updateForm(job));

            // Re-query workflow settings
            if (workflowTemplateId != null) {
                yield contextCall(WorkflowTemplateSaga, 'querySettings', WorkflowTemplateModel.actionCreators.querySettings(workflowTemplateId));
                job.operations = yield select(state => state.templateOperationsMap.get(workflowTemplateId));
            }

            const [parameters, jobParameters] = yield select(state => [deepCopy(state.parametersMap.get(workflowTemplateId)), state.parametersMap.get(id)]);
            const parameterValues = getValues(parameters);

            const requiresModels = {};
            for (const param of parameterValues) {
                requiresModels.thirdPartyService ||= param.isThirdPartyServiceParameter();
            }
            if (requiresModels.thirdPartyService) {
                yield contextCall(ThirdPartyServiceSaga, 'queryDetails');
            }

            const thirdPartyServiceDetailsMap = yield select(state => state.thirdPartyServiceDetailsMap);
            for (const param of parameterValues) {
                // Update param values with Job param values
                const jobParam = jobParameters.get(param.name);
                if (jobParam != null) {
                    param.value = jobParam.value;
                }
                // Replace null with empty ''
                if (!param.value) {
                    param.value = '';
                }
                // Replace invalid thirdPartyServices
                if (param.isThirdPartyServiceParameter()) {
                    const thirdPartyService = thirdPartyServiceDetailsMap.get(param.value);
                    if (thirdPartyService == null || ThirdPartyUserCredential.isInvalid(thirdPartyService.userCredential)) {
                        param.value = '';
                    }
                }
            }

            // Populate allowedValues
            job.parameters = yield WorkflowTemplateSaga.updateParametersAllowedValues({...job, parameters},
                function* (error) {
                    yield contextCall(JobSaga, 'handleJobParametersAllowedValuesError', error);
                }
            );
            yield put(JobModel.componentActionCreators.updateForm(job));
            yield contextCall(JobSaga, 'shouldEnableQueueJob');

            // Need to encrypt parameters since final queue parameters will be encrypted
            const initialParameters = deepCopy((yield select(state => state.componentStates.jobQueueForm)).parameters);
            for (const [name, parameter] of initialParameters) {

                if (parameter.isPasswordParameter() && parameter.value != null)
                    parameter.value = this.encrypto.encrypt(name, parameter.value);
            }

            // Track initialValues
            const initialValues = {
                clientId,
                matterId,
                libraryId,
                workflowTemplateId,
                workflowName: job.workflowName,
                notes,
                parameters: initialParameters
            };
            yield put(JobModel.componentActionCreators.updateForm({initialValues}));
        } finally {
            yield put(JobModel.componentActionCreators.updateForm({isLoading: false}));
        }
    }

    static* getClientAndMatterId(jobClientId, jobMatterId) {
        const hasLoaded = yield select(state => state.hasLoaded);

        if (!hasLoaded[details.CLIENTS]) {
            yield contextCall(ClientSaga, 'queryDetails');
        }
        const [features, clientDetails] = yield select(state => ([state.currentUser.features, state.clientDetailsMap.get(jobClientId)]));

        let clientId;
        if (clientDetails != null && clientDetails.enabled) {
            clientId = jobClientId;
        } else if (features.includes(applicationFeatures.ADD_JOB_UNASSIGNED_CLIENT)) {
            clientId = CLIENT_UNASSIGNED;
        }

        if (clientId !== jobClientId) return [clientId];

        if (!hasLoaded[jobClientId]) {
            yield contextCall(MatterSaga, 'queryDetailsSubset', {payload: {parentId: jobClientId}});
        }
        const matterDetails = yield select(state => state.matterDetailsMap.get(jobMatterId));

        let matterId;
        if (matterDetails != null && matterDetails.enabled) {
            matterId = jobMatterId;
        }

        return [clientId, matterId];
    }

    static* getLibraryAndWorkflowId(jobLibraryId, jobWorkflowId) {
        const hasLoaded = yield select(state => state.hasLoaded);

        if (!hasLoaded[details.LIBRARIES]) {
            yield contextCall(LibrarySaga, 'queryDetails');
        }
        const [features, libraryDetails] = yield select(state => ([state.currentUser.features, state.libraryDetailsMap.get(jobLibraryId)]));

        let libraryId;
        if (libraryDetails != null && libraryDetails.enabled) {
            libraryId = jobLibraryId;
        }

        if (libraryId !== jobLibraryId) return [libraryId];

        if (!hasLoaded[jobLibraryId]) {
            yield contextCall(WorkflowTemplateSaga, 'queryDetailsSubset', {payload: {parentId: jobLibraryId}});
        }
        const workflowTemplateDetails = yield select(state => state.workflowTemplateDetailsMap.get(jobWorkflowId));

        let workflowTemplateId;
        if (workflowTemplateDetails != null && workflowTemplateDetails.enabled) {
            workflowTemplateId = jobWorkflowId;
        }

        return [libraryId, workflowTemplateId];
    }

    static* queryDetails() {
        const response = yield contextCall(JobApi, 'getDetails');
        const key = details.QUEUE_JOBS;

        if (AxiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(JobModel.actionCreators.setDetailsMap(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }

    static* querySettings(action) {
        const {id} = action.payload;
        try {
            const response = yield contextCall(JobApi, 'getSettings', id);

            if (AxiosProxy.shouldUpdate(id, response)) {
                const {auditLog, operations, settings} = response.data;
                const {sessionParameters, warnings, infos, links, softErrors, error} = settings;

                yield all([
                    put(AuditLogModel.actionCreators.setSetting(id, auditLog)),
                    put(JobOperationModel.actionCreators.setOperations(id, operations)),
                    put(ParameterModel.actionCreators.setParameters(id, sessionParameters)),
                    put(JobWarningsModel.actionCreators.setSetting(id, warnings)),
                    put(JobSoftErrorsModel.actionCreators.setSetting(id, softErrors)),
                    put(JobInfosModel.actionCreators.setSetting(id, infos)),
                    put(JobLinksModel.actionCreators.setSetting(id, links)),
                    put(JobErrorsModel.actionCreators.setSetting(id, error))
                ]);

                const {activeView} = yield select(state => state.componentStates.jobPage);
                const model = switchcase({
                    [jobPageViewKeys.QUEUE]: JobModel,
                    [jobPageViewKeys.PURVIEW]: JobModel,
                    [jobPageViewKeys.VAULT]: JobModel,
                    [jobPageViewKeys.ARCHIVE]: JobArchiveModel
                })()(activeView);

                yield put(model.actionCreators.updateDetails({[id]: new model(settings)}));
            }
        } finally {
            yield put(ReduxStateModel.actionCreators.setHasLoaded(id));
        }
    }

    static* getModelNames() {
        const {jobDetailsMap, jobArchiveMap, jobScheduleDetailsMap, parametersMap} = yield select();

        const idToType = {};
        getValues(new Map([...jobDetailsMap, ...jobArchiveMap, ...jobScheduleDetailsMap]))
            .forEach(job => {

                if (job.clientId)
                    idToType[job.clientId] = ClientModel.nom;
                if (job.matterId)
                    idToType[job.matterId] = MatterModel.nom;
                if (job.libraryId)
                    idToType[job.libraryId] = LibraryModel.nom;
                if (job.workflowTemplateId)
                    idToType[job.workflowTemplateId] = WorkflowTemplateModel.nom;

                if (job.executionProfileId)
                    idToType[job.executionProfileId] = ExecutionProfileModel.nom;
                if (job.resourcePoolId)
                    idToType[job.resourcePoolId] = ResourcePoolModel.nom;
                if (job.engineId)
                    idToType[job.engineId] = EngineModel.nom;

                // Resolve parameter names
                const parameters = getValues(parametersMap.get(job.id));
                for (const param of parameters) {
                    if (param.isDatasetParameter()) {
                        idToType[param.value] = DatasetModel.nom;
                    }
                    if (param.isAzureStorageAccountParameter()) {
                        idToType[param.value] = DataRepositoryModel.nom;
                    }
                    if (param.isThirdPartyServiceParameter()) {
                        idToType[param.value] = ThirdPartyServiceModel.nom;
                    }
                    if (param.isFileParameter()) {
                        idToType[param.value] = LibraryFileModel.nom;
                    }
                }
            });

        const typeToUpdate = yield contextCall(this, 'queryModelNames', idToType);
        const updateActionMap = {
            [ClientModel.nom]: ClientModel.actionCreators.updateDetails,
            [MatterModel.nom]: MatterModel.actionCreators.updateDetails,
            [LibraryModel.nom]: LibraryModel.actionCreators.updateDetails,
            [WorkflowTemplateModel.nom]: WorkflowTemplateModel.actionCreators.updateDetails,
            [LicenceSourceModel.nom]: LicenceSourceModel.actionCreators.updateDetails,
            [ExecutionProfileModel.nom]: ExecutionProfileModel.actionCreators.updateDetails,
            [ResourcePoolModel.nom]: ResourcePoolModel.actionCreators.updateDetails,
            [ClientPoolModel.nom]: ClientPoolModel.actionCreators.updateDetails,
            [DatasetModel.nom]: DatasetModel.actionCreators.updateDetails,
            [DataRepositoryModel.nom]: DataRepositoryModel.actionCreators.updateDetails,
            [ThirdPartyServiceModel.nom]: ThirdPartyServiceModel.actionCreators.updateDetails,
            [LibraryFileModel.nom]: LibraryFileModel.actionCreators.updateDetails
        };

        const updateEffects = [];
        getEntries(typeToUpdate)
            .forEach(([type, updates]) => {

                const updateAction = updateActionMap[type](updates);
                updateEffects.push(
                    put(updateAction)
                );
            });

        yield all(updateEffects);
    }
}

export default JobModel;