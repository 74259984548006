import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import UserServiceModel from "../../../models/user/UserServiceModel";
import {createCloseHandler, createInputHandler, createStateHandler} from "../../../utilities/componentFunctions";
import {createToggleHandler} from "../../common/Checkbox/helpers";
import {createDropdownHandler} from "../../common/Dropdown/helpers";
import PopupModel from "../../../models/scheduler/PopupModel";
import Form, {FormFooter, FormHeader} from "../../common/Form/Form";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import Checkbox from "../../common/Checkbox/Checkbox";
import TextArea from "../../common/TextArea/TextArea";
import {SynchronizationFormBody, SynchronizeUsersFormBody} from "../ldap/LdapUserServiceForm";
import {getValues} from "../../../utilities/helperFunctions";
import {AzureEnvironmentDropdown, ListDropdown} from "../../common/Dropdown/Dropdown";
import {FormElementRow} from "../../common/CustomTable/CustomTable";
import {useClearOnFirstCallback} from "../../../utilities/formHooks";
import {ValueList} from "../../common/InputList/InputList";

function MicrosoftUserServiceForm() {
    const {t} = useTranslation(['userService', 'common']);
    const dispatch = useDispatch();

    const azureEnvironments = useSelector(state => state.schedulerDetails.configuration.azureEnvironments);
    const {
        isDisabled
    } = useSelector(state => state.componentStates.userServiceForm);

    const [state, setState] = useState({
        type: UserServiceModel.types.MICROSOFT,
        name: '',
        description: '',
        enabled: true,
        restrictByUrl: false,
        allowedUrls: [],

        oAuthVersion: UserServiceModel.MicrosoftOAuthVersion.V1,
        environment: azureEnvironments[0],
        tenant: '',
        clientId: '',

        enableAuthentication: true,
        synchronizeUsers: false,
        includeGuestUsers: false,
        includeInactiveUsers: true,
        includeDeletedUsers: false,
        usersEligibleLegalHoldAdministrator: false,
        usersEligibleLegalHoldCustodian: false,
        searchDelay: 30,

        isAddEnabled: false
    });

    const clientSecretRef = useRef({});
    const stateHandler = createStateHandler({
        updateState: setState,
        shouldEnable: UserServiceModel.validateFormData,
        passwordOptions: {
            'clientSecret': {
                ref: clientSecretRef,
                resetNames: ['tenant', 'clientId']
            }
        }
    });

    const inputHandler = createInputHandler({
        handler: stateHandler
    })
    const toggleHandler = createToggleHandler({
        handler: stateHandler
    });
    const dropdownHandler = createDropdownHandler({
        handler: stateHandler
    });

    const [allowedUrls, setAllowedUrls] = useState([]);

    function onSubmit() {
        dispatch(UserServiceModel.actionCreators.submitForm({...state, allowedUrls}));
    }

    const {
        name,
        description,
        enabled,
        restrictByUrl,

        tenant,
        clientId,

        synchronizeUsers,
        searchDelay,
        includeGuestUsers,
        includeInactiveUsers,
        includeDeletedUsers,
        usersEligibleLegalHoldAdministrator,
        usersEligibleLegalHoldCustodian,

        isAddEnabled
    } = state;

    const onClose = createCloseHandler({
        item: t('userService:label.microsoftService'),
        values: [name, description, tenant, clientId, clientSecretRef.current],
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        close: () => dispatch(UserServiceModel.actionCreators.hideForm(UserServiceModel.types.MICROSOFT))
    });

    return (
        <Form onClose={onClose} isDisabled={isDisabled} closeButtonAriaLabel={t('userService:option.closeFormMicrosoft')}
            header={
                <FormHeader text={t('userService:label.microsoftUserServiceTitle')} iconName={'userServiceMicrosoft'} isDisabled={isDisabled} />
            }
            body={
                <>
                    <div className="display-input">
                        <HTMLTextInput label={t('common:label.name')} name={'name'} value={name} onChange={inputHandler}
                            isDisabled={isDisabled} isRequired/>
                    </div>

                    <Checkbox label={t('common:label.enabled')} name={'enabled'} checked={enabled} onClick={toggleHandler}
                        isDisabled={isDisabled}/>

                    <div className="display-input">
                        <TextArea label={t('common:label.description')} name={'description'} value={description} onChange={inputHandler}
                            isDisabled={isDisabled}/>
                    </div>

                    <div className="display-input">
                        <Checkbox label={t('userService:label.restrictByUrl')} name={'restrictByUrl'}
                                  checked={restrictByUrl} onClick={toggleHandler}
                                  isDisabled={isDisabled}/>
                    </div>

                    {restrictByUrl &&
                        <div className="display-input">
                            <ValueList id={'allowedUrls'} label={t('userService:label.allowedUrls')}
                                       values={allowedUrls} setValues={setAllowedUrls}
                                       ariaLabelKey={'AllowedUrls'} isDisabled={isDisabled}/>
                        </div>
                    }

                    <MicrosoftUserServiceFormBody {...state} clientSecretRef={clientSecretRef}
                        inputHandler={inputHandler} dropdownHandler={dropdownHandler} toggleHandler={toggleHandler} isDisabled={isDisabled}/>

                    <div className="display-input">
                        <Checkbox label={t('userService:label.synchronizeUsers')} name={"synchronizeUsers"}
                            checked={synchronizeUsers} onClick={toggleHandler} isDisabled={isDisabled}/>
                    </div>

                    {synchronizeUsers &&
                        <>
                            <div className="display-input">
                                <Checkbox label={t('userService:label.includeGuestUsers')} name={"includeGuestUsers"} checked={includeGuestUsers} onClick={toggleHandler}/>
                            </div>
                            <div className="display-input">
                                <Checkbox label={t('userService:label.includeInactiveUsers')} name={"includeInactiveUsers"} checked={includeInactiveUsers} onClick={toggleHandler}/>
                            </div>
                            <div className="display-input">
                                <Checkbox label={t('userService:label.includeDeletedUsers')} name={"includeDeletedUsers"} checked={includeDeletedUsers} onClick={toggleHandler}/>
                            </div>

                            <SynchronizeUsersFormBody usersEligibleLegalHoldAdministrator={usersEligibleLegalHoldAdministrator}
                                usersEligibleLegalHoldCustodian={usersEligibleLegalHoldCustodian} toggleHandler={toggleHandler} isDisabled={isDisabled}/>

                            <SynchronizationFormBody type={UserServiceModel.types.MICROSOFT}
                                searchDelay={searchDelay} inputHandler={inputHandler} isDisabled={isDisabled}/>
                        </>
                    }
                </>
            }
            footer={
                <FormFooter addText={t('userService:option.addUserService')} onAddClick={onSubmit}
                    onCancel={onClose} isAddEnabled={isAddEnabled} isDisabled={isDisabled}
                />
            }
        />
    );
}

export function MicrosoftUserServiceFormBody(props) {
    const {t} = useTranslation(['userService']);

    const {
        environment,
        tenant,
        clientId,
        clientSecret,

        oAuthVersion,
        enableAuthentication,

        clientSecretRef,
        inputHandler,
        toggleHandler,
        dropdownHandler,
        isDisabled
    } = props;


    const clearOnFirstCallback = useClearOnFirstCallback();

    return (
        <>
            <div className="forms-table display-input">
                <div className="table-row-group">
                    <FormElementRow label={t('userService:label.environment')} isDisabled={isDisabled}
                        element={
                            <AzureEnvironmentDropdown name={'environment'} value={environment} onItemSelect={dropdownHandler}
                                isDisabled={isDisabled} isRequired/>
                        }/>

                    <FormElementRow label={t('userService:label.microsoftOAuthVersion')} isDisabled={isDisabled}
                        element={
                            <MicrosoftAuthVersionDropdown value={oAuthVersion} onItemSelect={dropdownHandler}
                                isDisabled={isDisabled}/>
                        }/>
                </div>
            </div>

            <div className="display-input">
                <HTMLTextInput label={t(`userService:oidc.tenant`)} name={'tenant'} value={tenant} onChange={inputHandler}
                    isDisabled={isDisabled} isRequired/>
            </div>

            <div className="display-input">
                <HTMLTextInput label={t('userService:oidc.clientId')} name={'clientId'} value={clientId} onChange={inputHandler}
                    isDisabled={isDisabled} isRequired/>
            </div>

            <div className="display-input">
                <HTMLTextInput label={t('userService:oidc.clientSecret')} name={'clientSecret'} type={'password'} inputRef={clientSecretRef}
                    onChange={inputHandler} onBeforeInput={clearOnFirstCallback} isDisabled={isDisabled} defaultValue={clientSecret} isRequired/>
            </div>

            <div className="display-input">
                <Checkbox label={t('userService:label.enableAuthentication')} name={"enableAuthentication"}
                    checked={enableAuthentication} onClick={toggleHandler} isDisabled={isDisabled}/>
            </div>
        </>
    )
}

function MicrosoftAuthVersionDropdown(props) {
    const {t} = useTranslation(['userService', 'aria']);

    const microsoftOAuthVersionItems = getValues(UserServiceModel.MicrosoftOAuthVersion)
        .map(version => ({name: t(`userService:microsoftOAuthVersion.${version}`), value: version}));

    return (
        <ListDropdown id={'microsoftOAuthVersionDropdown'} name={'oAuthVersion'}
            aria-label={t('aria:hiddenAssistText.microsoftOAuthVersion')} items={microsoftOAuthVersionItems} {...props}/>
    )
}

export default MicrosoftUserServiceForm;
