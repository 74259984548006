import React, {useRef} from "react";
import {useTranslation} from "react-i18next";
import ExpandableContent, {ExpandableLog} from "../../common/ExpandableContent/ExpandableContent";
import {
    SettingsRowLabel,
    SettingsRowSeparator,
    SettingsRowValue,
    TableLabelCell,
    TableLabelHeader
} from "../../common/CustomTable/CustomTable";
import {useSelector} from "react-redux";
import {isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import {ExpandableParameters} from "../../noticeTemplate/panes/NoticeConfirmPane";
import {selectLegalHoldNames} from "../LegalHoldTablet";
import ExpandableNoticeConfigurations from "../ExpandableNoticeConfigurations";
import Text from "../../common/Text/Text";
import {StatusLabel} from "../../common/Common";
import {getRenderedHeightOffset, useRenderedItemHeight, useSimpleVirtualRendering} from "../../../utilities/hooks";
import AdministratorTable from "../AdministratorTable";
import ExpandableTriggerConfigurations from "../ExpandableTriggerConfigurations";
import {PriorityLabel} from "../../job/JobTablet";


function HoldConfirmPane() {
    const {t} = useTranslation(['legalHold', 'matter', 'dataset', 'workflow', 'common']);

    const legalHoldForm = useSelector(state => state.componentStates.legalHoldForm);
    const names = useSelector(state => selectLegalHoldNames(state, legalHoldForm));

    const {
        legalHoldName,
        description,

        silent,
        holdNoticeConfiguration,
        releaseNoticeConfiguration,
        recurringNoticeConfiguration,
        surveyNoticeConfigurations,
        triggerConfigurations,
        useTriggerConfigurations,
        requiresDataRepository,

        priority,

        surveyNoticesTrigger,
        enableLoginLinks,
        schedulerUrl,
        parameters,
        administrators,
        custodians,

        isDisabled
    } = legalHoldForm;


    return (
        <div className="pane-margin">
            <div className="display-input">
                <ExpandableContent label={t('common:label.settings')}
                    isDisabled={isDisabled}
                >
                    <div className="settings-table">
                        <div className="table-row-group">
                            <SettingsRowValue label={t('common:label.name')} value={legalHoldName} isDisabled={isDisabled}/>
                            <SettingsRowSeparator/>

                            <SettingsRowValue label={t('legalHold:label.client')} value={names.client} isDisabled={isDisabled}/>
                            <SettingsRowValue label={t('legalHold:label.matter')} value={names.matter} isDisabled={isDisabled}/>

                            {!silent &&
                            <>
                                <SettingsRowSeparator/>
                                {requiresDataRepository &&
                                    <SettingsRowValue label={t('legalHold:label.dataRepository')} isDisabled={isDisabled}
                                        value={
                                            <div style={{display: 'flex'}}>
                                                {names.dataRepository &&
                                                    <Text value={names.dataRepository} isDisabled={isDisabled}/>
                                                }
                                                {!names.dataRepository &&
                                                    <StatusLabel isDisabled={isDisabled}
                                                        message={t('legalHold:message.dataRepositoryRequiredToActivate')}/>
                                                }
                                            </div>
                                        }/>
                                }
                                <SettingsRowValue label={t('legalHold:label.smtpService')} isDisabled={isDisabled}
                                    value={
                                        <div style={{display: 'flex'}}>
                                            {names.smtpService &&
                                            <Text value={names.smtpService} isDisabled={isDisabled}/>
                                            }
                                            {!names.smtpService &&
                                            <StatusLabel isDisabled={isDisabled}
                                                message={t('legalHold:message.smtpServiceRequiredToActivate')}/>
                                            }
                                        </div>
                                    }/>

                                <SettingsRowSeparator/>
                                <SettingsRowValue label={t('legalHold:label.surveyNoticesTrigger')} value={t(`legalHold:surveyNoticesTrigger.${surveyNoticesTrigger}`)}
                                    isDisabled={isDisabled}/>

                                <SettingsRowValue label={t('legalHold:label.schedulerUrl')} value={schedulerUrl} isDisabled={isDisabled}/>
                                {enableLoginLinks &&
                                <SettingsRowLabel label={t('legalHold:message.loginLinksEnabled')}  isDisabled={isDisabled}/>
                                }
                            </>
                            }

                            {useTriggerConfigurations &&
                                <>
                                    <SettingsRowSeparator/>
                                    <SettingsRowValue label={t('legalHold:label.executionProfile')} value={names.executionProfile} isDisabled={isDisabled}/>
                                    <SettingsRowValue label={t('legalHold:label.resourcePool')} value={names.resourcePool} isDisabled={isDisabled}/>
                                    <SettingsRowValue label={t('legalHold:label.priority')} value={<PriorityLabel priority={priority} isDisabled={isDisabled}/>}
                                        isDisabled={isDisabled}/>
                                </>
                            }
                        </div>
                    </div>
                </ExpandableContent>
            </div>

            {description &&
            <div className="display-input">
                <ExpandableLog label={t('common:label.description')} log={description} isDisabled={isDisabled}/>
            </div>
            }

            {!silent && isNotEmptyNorFalsy(parameters) &&
            <div className="display-input">
                <ExpandableParameters parameters={parameters} isDisabled={isDisabled}/>
            </div>
            }

            {!silent &&
            <div className="display-input">
                <ExpandableNoticeConfigurations holdNoticeConfiguration={holdNoticeConfiguration}
                    releaseNoticeConfiguration={releaseNoticeConfiguration} surveyNoticeConfigurations={surveyNoticeConfigurations}
                    recurringNoticeConfiguration={recurringNoticeConfiguration} isDisabled={isDisabled}/>
            </div>
            }

            {useTriggerConfigurations &&
                <div className="display-input">
                    <ExpandableTriggerConfigurations triggerConfigurations={triggerConfigurations} isDisabled={isDisabled}/>
                </div>
            }

            <div className="display-input">
                <AdministratorTable administrators={administrators.right}
                    isDisabled={isDisabled}/>
            </div>

            <div className="display-input">
                <ExpandableCustodiansTable custodians={custodians.right}
                    isDisabled={isDisabled}/>
            </div>
        </div>
    )
}

function ExpandableCustodiansTable(props) {
    const {t} = useTranslation(['legalHold', 'common']);

    const {
        custodians,
        isDisabled
    } = props;

    const containerRef = useRef();
    const listContainerSelector = '.table-row-group';

    const itemHeightRef = useRenderedItemHeight(containerRef, listContainerSelector, 31.6094);
    const heightOffset = getRenderedHeightOffset(containerRef, listContainerSelector, itemHeightRef.current);
    const virtualRenderRows = useSimpleVirtualRendering({
        containerRef,
        itemHeightRef,
        size: custodians.length,
        heightOffset
    });

    return (
        <ExpandableContent label={t('legalHold:label.custodians')}
            isDisabled={isDisabled}
        >
            <div ref={containerRef}>
                <div className="display-table tight-padding">
                    <div className="table-header-group">
                        <TableLabelHeader label={t('common:label.status')} style={{width: '6rem'}}
                            isDisabled={isDisabled}/>

                        <TableLabelHeader label={t('common:label.name')} style={{width: '22rem'}}
                            isDisabled={isDisabled}/>

                        <TableLabelHeader label={t('legalHold:label.email')}
                            isDisabled={isDisabled}/>
                    </div>

                    <div className="table-row-group">
                        {virtualRenderRows((index, topOffset) => {
                            const custodian = custodians[index];
                            if (custodian == null) {
                                return null;
                            }

                            return (
                                <div key={custodian.name} className="table-row no-pointer" style={{position: 'relative', top: topOffset}}>
                                    <TableLabelCell isDisabled={isDisabled}/>

                                    <TableLabelCell label={custodian.name} isDisabled={isDisabled}/>

                                    <TableLabelCell label={custodian.email} isDisabled={isDisabled}/>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </ExpandableContent>
    )
}

export default HoldConfirmPane;
