import React from 'react';
import {switchcase, switchcaseF} from './helperFunctions';
import {
    executionModeKeys,
    executionStateKeys,
    positionKeys,
    priorityKeys,
    scheduleTriggerTypeKeys,
    statusKeys,
    webhookPlatformKeys
} from "../i18next/keys";
import ProgressRing from '../components/common/ProgressRing/ProgressRing';
import statusBrokenLink from '../resources/images/status/status-broken-link.svg';
import statusError from '../resources/images/status/status-error.svg';
import statusSoftError from '../resources/images/status/status-soft-error.svg';
import statusFinished from '../resources/images/status/status-finished.svg';
import statusPaused from '../resources/images/status/status-paused.svg';
import statusCommentUnread from '../resources/images/status/status-comment-unread.svg';
import statusCommentRead from '../resources/images/status/status-comment-read.svg';
import statusPending from '../resources/images/status/status-pending.svg';
import statusRunningRemote from '../resources/images/status/status-running-remote.svg';
import statusPausing from '../resources/images/status/status-pausing.svg';
import statusScheduled from '../resources/images/status/status-scheduled.svg';
import statusInfo from '../resources/images/status/status-info.svg';
import statusLink from '../resources/images/status/status-link.svg';
import statusWarning from '../resources/images/status/status-warning.svg';
import statusWarningResolved from '../resources/images/status/status-warning-resolved.svg';
import statusStopping from '../resources/images/status/status-stopping.svg';
import statusStopped from '../resources/images/status/status-stopped.svg';
import statusCancelled from '../resources/images/status/status-cancelled.svg';
import statusUnlocked from '../resources/images/status/status-confidential-unlocked.svg';
import statusLocked from '../resources/images/status/status-confidential-locked.svg';
import priorityLowest from '../resources/images/priority/priority-lowest.svg';
import priorityLow from '../resources/images/priority/priority-low.svg';
import priorityHigh from '../resources/images/priority/priority-high.svg';
import priorityHighest from '../resources/images/priority/priority-highest.svg';
import enterMaintenance from '../resources/images/icons/enter-maintenance.svg';
import underMaintenance from '../resources/images/icons/under-maintenance.svg';
import logout from '../resources/images/icons/logout.svg';
import user from '../resources/images/icons/user.svg';
import client from '../resources/images/icons/client.svg';
import matter from '../resources/images/icons/matter.svg';
import library from '../resources/images/icons/library.svg';
import template from '../resources/images/icons/template.svg';
import settings from '../resources/images/icons/settings.svg';
import licenceSourceCls from '../resources/images/icons/licenceSourceCls.svg';
import licenceSourceDongle from '../resources/images/icons/licenceSourceDongle.svg';
import licenceSourceNms from '../resources/images/icons/licenceSourceNms.svg';
import engineServer from '../resources/images/icons/engineServer.svg';
import engine from '../resources/images/icons/engine.svg';
import executionProfile from '../resources/images/icons/executionProfile.svg'
import resourcePoolAws from '../resources/images/icons/resourcePoolAws.svg';
import resourcePoolAzure from '../resources/images/icons/resourcePoolAzure.svg';
import resourcePoolLocal from '../resources/images/icons/resourcePoolLocal.svg';
import clientPool from '../resources/images/icons/clientPool.svg';
import securityPolicy from '../resources/images/icons/securityPolicy.svg';
import positionTopLeft from '../resources/images/position/topLeft.svg';
import positionTopRight from '../resources/images/position/topRight.svg';
import positionCenter from '../resources/images/position/center.svg';
import positionBottomRight from '../resources/images/position/bottomRight.svg';
import positionBottomLeft from '../resources/images/position/bottomLeft.svg';
import genericUser from '../resources/images/icons/generic-user.svg';
import notificationRuleEmail from '../resources/images/icons/notificationRuleEmail.svg';
import notificationRuleSlack from '../resources/images/icons/notificationRuleSlack.svg';
import notificationRuleDiscord from '../resources/images/icons/notificationRuleDiscord.svg';
import notificationRuleTeams from '../resources/images/icons/notificationRuleTeams.svg';
import scheduleTime from '../resources/images/icons/scheduleTime.svg';
import scheduleEvent from '../resources/images/icons/scheduleEvent.svg';
import automateLicence from '../resources/images/icons/automateLicence.svg';
import userDataDir from '../resources/images/icons/userDataDir.svg';
import relativityProxy from '../resources/images/icons/relativityProxy.svg';
import closedFolder from '../resources/images/icons/closedFolder.svg';
import openedFolder from '../resources/images/icons/openedFolder.svg';
import dataRepositoryMANAGED from '../resources/images/icons/dataRepositoryManaged.svg';
import dataRepositoryIN_PLACE from '../resources/images/icons/dataRepositoryInPlace.svg';
import dataRepositoryAZURE_STORAGE_ACCOUNT from '../resources/images/icons/dataRepositoryAzureStorageAccount.svg';
import upload from '../resources/images/icons/upload.svg';
import download from '../resources/images/icons/download.svg';
import save from '../resources/images/icons/save.svg';
import metadataAdd from '../resources/images/icons/metadataAdd.svg';
import metadataEdit from '../resources/images/icons/metadataEdit.svg';
import datasetHide from '../resources/images/icons/datasetHide.svg';
import datasetShow from '../resources/images/icons/datasetShow.svg';
import datasetArchive from '../resources/images/icons/datasetArchive.svg';
import datasetUnarchive from '../resources/images/icons/datasetUnarchive.svg';
import datasetDraft from '../resources/images/icons/datasetDraft.svg';
import datasetCollectionInProgress from '../resources/images/icons/datasetCollectionInProgress.svg';
import datasetFinalized from '../resources/images/icons/datasetFinalized.svg';
import datasetHidden from '../resources/images/icons/datasetHidden.svg';
import datasetArchived from '../resources/images/icons/datasetArchived.svg';
import datasetExpired from '../resources/images/icons/datasetExpired.svg';
import datasetExpire from '../resources/images/icons/datasetExpire.svg';
import userServiceUms from '../resources/images/icons/userServiceUms.svg';
import userServiceLdap from '../resources/images/icons/userServiceLdap.svg';
import userServiceManaged from '../resources/images/icons/userServiceManaged.svg';
import userServiceInternal from '../resources/images/icons/userServiceInternal.svg';
import eccProfile from '../resources/images/icons/eccProfile.svg'
import legalHoldDRAFT from '../resources/images/icons/legalHoldDraft.svg';
import legalHoldACTIVE from '../resources/images/icons/legalHoldActive.svg';
import legalHoldRELEASED from '../resources/images/icons/legalHoldReleased.svg';
import legalHoldARCHIVED from '../resources/images/icons/legalHoldArchived.svg';
import issueHold from '../resources/images/icons/actionHoldAdd.svg';
import releaseHold from '../resources/images/icons/actionHoldRelease.svg';
import legalHoldExport from '../resources/images/icons/legalHoldExport.svg';
import smtpServer from '../resources/images/icons/smtpServer.svg';
import smtpServerExport from '../resources/images/icons/smtpServerExport.svg';
import apiKey from '../resources/images/icons/apiKey.svg';
import webhook from '../resources/images/icons/webhook.svg';
import noticeTypeHOLD from '../resources/images/icons/noticeTypeHold.svg';
import noticeTypeRELEASE from '../resources/images/icons/noticeTypeRelease.svg';
import noticeTypeSURVEY from '../resources/images/icons/noticeTypeSurvey.svg';
import noticeTypeREMINDER from '../resources/images/icons/noticeTypeReminder.svg';
import noticeTypeESCALATION from '../resources/images/icons/noticeTypeEscalation.svg';
import noticeTypeRECURRING from '../resources/images/icons/noticeTypeRecurring.svg';
import actionPreview from '../resources/images/icons/actionPreview.svg';
import actionPreviewCancel from '../resources/images/icons/actionPreviewCancel.svg';
import roleAdmin from '../resources/images/icons/roleAdmin.svg';
import roleCustodian from '../resources/images/icons/roleCustodians.svg';
import sourceNonCustodial from '../resources/images/icons/sourceNonCustodial.svg';
import executionModeAUTOMATE_NATIVE from '../resources/images/logo/neo_automate.svg';
import executionModeAUTOMATE_NUIX from '../resources/images/icons/nuixEngine.svg';
import fileLibrary from '../resources/images/icons/fileLibrary.svg';
import fileNuix from '../resources/images/icons/fileNuix.svg';
import file from '../resources/images/icons/file.svg';
import oidcMicrosoft from '../resources/images/icons/oidcMicrosoft.svg';
import oidcGoogle from '../resources/images/icons/oidcGoogle.svg';
import oidcRelativity from '../resources/images/icons/oidcRelativity.svg';
import userServiceOidcGeneric from '../resources/images/icons/userServiceOidcGeneric.svg';
import userServiceMicrosoft from '../resources/images/icons/userServiceMicrosoft.svg';
import userServiceGoogle from '../resources/images/icons/userServiceGoogle.svg';
import userServiceRelativity from '../resources/images/icons/userServiceRelativity.svg';
import userServiceGraph from '../resources/images/icons/userServiceGraph.svg';
import userServiceRelativityImpersonation from '../resources/images/icons/userServiceRelativityImpersonation.svg';
import relativityUserSignIn from '../resources/images/icons/relativityUserSignIn.svg';
import relativityService from '../resources/images/icons/relativityService.svg';
import relativityServiceUserSignIn from '../resources/images/icons/relativityServiceUserSignIn.svg';
import smtpService from '../resources/images/icons/smtpService.svg';
import smtpServiceUserSignIn from '../resources/images/icons/smtpServiceUserSignIn.svg';
import linkService from '../resources/images/icons/linkService.svg';
import purviewService from '../resources/images/icons/purviewService.svg';
import derbyControlService from '../resources/images/icons/derbyControlService.svg';
import graphService from '../resources/images/icons/graph.svg';
import graphServiceUserSignIn from '../resources/images/icons/graphServiceUserSignIn.svg';
import genAiService from '../resources/images/icons/genAiService.svg';
import genAiServiceUserSignIn from '../resources/images/icons/genAiServiceUserSignIn.svg';
import semanticService from '../resources/images/icons/semanticService.svg';
import semanticUserSignIn from '../resources/images/icons/semanticUserSignIn.svg';
import investigateService from '../resources/images/icons/investigateService.svg';
import elasticsearchService from '../resources/images/icons/elasticsearchService.svg';
import elasticsearchServiceUserSignIn from '../resources/images/icons/elasticsearchService.svg';
import vaultService from '../resources/images/icons/vaultService.svg';
import discoverService from '../resources/images/icons/discoverService.svg';
import discoverServiceUserSignIn from '../resources/images/icons/discoverServiceUserSignIn.svg';
import nlpService from '../resources/images/icons/nlpService.svg';
import nlpServiceUserSignIn from '../resources/images/icons/nlpServiceUserSignIn.svg';
import operationSkip from '../resources/images/icons/operationSkip.svg';
import copyToClipboard from '../resources/images/icons/copyToClipboard.svg';
import edit from '../resources/images/icons/edit.svg';
import operationOverwrite from '../resources/images/icons/operationOverwrite.svg';
import operationSoftFail from '../resources/images/icons/operationSoftFail.svg';
import operationSuppressWarnings from '../resources/images/icons/operationSuppressWarnings.svg';
import operationDisabled from '../resources/images/icons/operationDisabled.svg';
import operationHelper from '../resources/images/icons/operationHelper.svg';
import vaultSavedQuery from '../resources/images/icons/vaultSavedQuery.svg';
import {jobQueueStates} from "./constants";
import i18next from "i18next";


const icons = {
    client,
    matter,
    library,
    workflow: template,
    settings,
    logout,
    user,
    enterMaintenance,
    underMaintenance,
    clsLicenceSource: licenceSourceCls,
    dongleLicenceSource: licenceSourceDongle,
    nmsLicenceSource: licenceSourceNms,
    engineServer,
    engine,
    executionProfile,
    awsResourcePool: resourcePoolAws,
    azureResourcePool: resourcePoolAzure,
    localResourcePool: resourcePoolLocal,
    clientPool,
    policy: securityPolicy,
    genericUser,
    emailNotificationRule: notificationRuleEmail,
    slackNotificationRule: notificationRuleSlack,
    discordNotificationRule: notificationRuleDiscord,
    teamsNotificationRule: notificationRuleTeams,
    scheduleTime,
    scheduleEvent,
    automateLicence,
    userDataDir,
    relativityProxy,
    dataRepositoryMANAGED,
    dataRepositoryIN_PLACE,
    dataRepositoryAZURE_STORAGE_ACCOUNT,
    upload,
    download,
    save,
    datasetHide,
    datasetShow,
    datasetArchive,
    datasetUnarchive,
    fileLibrary,
    openedFolder,
    closedFolder,
    metadataAdd,
    metadataEdit,
    datasetDraft,
    datasetCollectionInProgress,
    datasetFinalized,
    datasetHidden,
    datasetArchived,
    datasetExpired,
    datasetExpire,
    userServiceUms,
    userServiceLdap,
    userServiceManaged,
    userServiceInternal,
    eccProfile,
    legalHoldDRAFT,
    legalHoldACTIVE,
    legalHoldRELEASED,
    legalHoldARCHIVED,
    issueHold,
    releaseHold,
    legalHoldExport,
    smtpServer,
    smtpServerExport,
    apiKey,
    webhook,
    noticeTypeHOLD,
    noticeTypeRELEASE,
    noticeTypeSURVEY,
    noticeTypeREMINDER,
    noticeTypeESCALATION,
    noticeTypeRECURRING,
    actionPreview,
    actionPreviewCancel,
    roleCustodian,
    roleAdmin,
    sourceNonCustodial,
    executionModeAUTOMATE_NATIVE,
    executionModeAUTOMATE_NUIX,
    file,
    fileNuix,
    userServiceOidcGeneric,
    userServiceMicrosoft,
    userServiceGoogle,
    userServiceRelativity,
    userServiceGraph,
    OidcMicrosoft: oidcMicrosoft,
    OidcGoogle: oidcGoogle,
    OidcRelativity: oidcRelativity,
    userServiceRelativityImpersonation,
    relativityUserSignIn,
    relativityService,
    relativityServiceUserSignIn,
    smtpService,
    smtpServiceUserSignIn,
    linkService,
    purviewService,
    derbyControlService,
    graphService,
    graphServiceUserSignIn,
    genAiService,
    genAiServiceUserSignIn,
    semanticService,
    semanticUserSignIn,
    investigateService,
    elasticsearchService,
    elasticsearchServiceUserSignIn,
    vaultService,
    discoverService,
    discoverServiceUserSignIn,
    nlpService,
    nlpServiceUserSignIn,
    eccService: eccProfile,
    eccServiceUserSignIn: eccProfile,
    operationSkip,
    copyToClipboard,
    edit,
    operationOverwrite,
    operationSoftFail,
    operationSuppressWarnings,
    operationHelper,
    operationDisabled,
    vaultSavedQuery
};

export const icon = name =>
    switchcase(icons)(statusError)(name);

export const getWebhookPlatformType = platform =>
    switchcase({
        [webhookPlatformKeys.MICROSOFT_TEAMS]: 'teamsNotificationRule',
        [webhookPlatformKeys.SLACK]: 'slackNotificationRule',
        [webhookPlatformKeys.DISCORD]: 'discordNotificationRule'
    })()(platform);

export const getScheduleIconName = scheduleType =>
    switchcase({
        [scheduleTriggerTypeKeys.ON_SCHEDULE]: 'scheduleTime',
        [scheduleTriggerTypeKeys.ON_JOB_EVENT]: 'scheduleEvent',
        [scheduleTriggerTypeKeys.ON_DATASET_EVENT]: 'scheduleEvent',
        [scheduleTriggerTypeKeys.ON_LEGAL_HOLD_EVENT]: 'scheduleEvent',
        [scheduleTriggerTypeKeys.ON_WEBHOOK_TRIGGER]: 'webhook'
    })()(scheduleType);

export const executionStateIcon = opts => {
    const {
        queueState,
        executionState,
        percentageComplete,
        hasWarnings,
        hasSoftErrors,
        size='small'
    } = opts;

    return switchcaseF({
        [executionStateKeys.NOT_STARTED]: () => {
            if (queueState === jobQueueStates.STAGING) {
                return getStatusIconImgContent('statusPaused');
            } else {
                return getStatusIconImgContent('statusScheduled');
            }
        },
        [executionStateKeys.PENDING]: <ProgressRing {...{percentageComplete, size}} />,
        [executionStateKeys.RUNNING]: <ProgressRing {...{percentageComplete, size}} />,
        [executionStateKeys.PAUSING]: getStatusIconImgContent('statusPausing'),
        [executionStateKeys.PAUSED]: getStatusIconImgContent('statusPaused'),
        [executionStateKeys.STOPPING]: getStatusIconImgContent('statusStopping'),
        [executionStateKeys.STOPPED]: getStatusIconImgContent('statusStopped'),
        [executionStateKeys.CANCELLED]: getStatusIconImgContent('statusCancelled'),
        [executionStateKeys.ERROR]: getStatusIconImgContent('statusError'),
        [executionStateKeys.SOFT_ERROR]: getStatusIconImgContent('statusSoftError'),
        [executionStateKeys.DISCONNECTED]: getStatusIconImgContent('statusBrokenLink'),
        [executionStateKeys.FINISHED]: () => {
            if (hasSoftErrors) {
                return getStatusIconImgContent('statusSoftError');
            } else if (hasWarnings) {
                return getStatusIconImgContent('statusWarning');
            } else {
                return getStatusIconImgContent('statusFinished');
            }
        }
    })()(executionState);
}

export const getStatusIconImgContent = iconName => (
    <img src={statusIcon(iconName)} alt={i18next.t(`aria:hiddenAssistText.${iconName}Icon`)} />
)

const priorityIcons = {
    [priorityKeys.HIGHEST]: priorityHighest,
    [priorityKeys.HIGH]: priorityHigh,
    [priorityKeys.LOW]: priorityLow,
    [priorityKeys.LOWEST]: priorityLowest
};

export const getPriorityIcon = priority =>
    switchcase(priorityIcons)()(priority);


const executionModeIcons = {
    [executionModeKeys.AUTOMATE_NUIX]: executionModeAUTOMATE_NUIX,
    [executionModeKeys.AUTOMATE_NATIVE]: executionModeAUTOMATE_NATIVE
};

export const executionModeIcon = executionMode =>
    switchcase(executionModeIcons)()(executionMode);

const statusIcons = {
    statusScheduled,
    statusPaused,
    statusPausing,
    statusFinished,
    statusStopping,
    statusStopped,
    statusCancelled,
    statusWarning,
    statusWarningResolved,
    statusInfo,
    statusLink,
    statusError,
    statusSoftError,
    statusBrokenLink,
    statusLocked,
    statusPending,
    statusRunningRemote,
    statusUnlocked,
    statusCommentUnread,
    statusCommentRead
};

export const statusIcon = name =>
    switchcase(statusIcons)()(name);

const positionIcons = {
    [positionKeys.TOP_LEFT]: positionTopLeft,
    [positionKeys.TOP_RIGHT]: positionTopRight,
    [positionKeys.MIDDLE]: positionCenter,
    [positionKeys.BOTTOM_LEFT]: positionBottomLeft,
    [positionKeys.BOTTOM_RIGHT]: positionBottomRight
};

export const positionIcon = position =>
    switchcase(positionIcons)()(position);


export const translatedStatusIcons = status => {
  const icon = switchcase({
    [statusKeys.INITIALIZED]: 'statusScheduled',
    [statusKeys.INITIALIZING]: 'statusBrokenLink',
    [statusKeys.OK]: 'statusOk',
    [statusKeys.INFO]: 'statusInfo',
    [statusKeys.UNKNOWN]: 'statusUnknown',
    [statusKeys.ERROR]: 'statusError',
    [statusKeys.WARNING]: 'statusWarning'
  })()(status);

  return getStatusIconImgContent(icon);
};
