import React from "react";
import {useTranslation} from "react-i18next";
import {TableLabelCell, TableLabelHeader} from "../../common/CustomTable/CustomTable";
import ThirdPartyServiceModel from "../../../models/thirdparty/ThirdPartyServiceModel";
import ThirdPartyServiceTable from "../ThirdPartyServiceTable";

function NlpServiceTable(props) {
    return (
        <ThirdPartyServiceTable {...props} type={ThirdPartyServiceModel.Type.NLP}
            TableHeaders={NlpServiceTableHeaders} TableRowCells={NlpServiceTableRowCells}
        />
    )
}

function NlpServiceTableHeaders() {
    const {t} = useTranslation(['thirdPartyService']);
    return (
        <>
            <TableLabelHeader label={t('thirdPartyService:label.url')}/>
        </>
    )
}

function NlpServiceTableRowCells(props) {
    const {
        thirdPartyService
    } = props;

    return (
        <>
            <TableLabelCell label={thirdPartyService.url}/>
        </>
    )
}

export default NlpServiceTable;
