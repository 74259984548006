import React, {useCallback, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {createInputHandler, createStateHandler} from "../../utilities/componentFunctions";
import PopupModel from "../../models/scheduler/PopupModel";
import RelativityProxyModel from "../../models/relativity/RelativityProxyModel";
import Form, {FormFooter, FormHeader} from "../common/Form/Form";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import {isNotEmptyNorFalsy} from "../../utilities/helperFunctions";
import {useClearOnFirstCallback} from "../../utilities/formHooks";

function RelativityProxyForm() {
    const {t} = useTranslation(['aria', 'relativityProxy', 'common']);
    const dispatch = useDispatch();

    const {
        isDisabled
    } = useSelector(state => state.componentStates.relativityProxyForm)

    const serviceSecretRef = useRef({});
    const [state, setState] = useState({
        apiUri: '',
        serviceId: ''
    });

    const clearOnFirstCallback = useClearOnFirstCallback();
    const stateHandler = createStateHandler({
        updateState: setState,
        passwordOptions: {
            'serviceSecret': {
                resetNames: ['apiUri', 'serviceId'],
                ref: serviceSecretRef
            }
        }
    });

    const inputHandler = createInputHandler({
        handler: stateHandler
    });

    const onClose = useCallback(() => {
        const close = () => dispatch(RelativityProxyModel.componentActionCreators.updateDisplay({isFormActive: false}));

        if (!isNotEmptyNorFalsy(state) && !serviceSecretRef.current.value) {
            close();
        } else {

            dispatch(PopupModel.actionCreators.showWarning({
                info: {
                    key: 'discardRelativityProxy'
                },
                buttons: [{
                    title: t('common:option.discard'),
                    onClick: close
                }]
            }));
        }
    }, [state, serviceSecretRef.current, dispatch]);

    function onSubmit() {
        dispatch(RelativityProxyModel.actionCreators.submitForm({
            ...state,
            serviceSecret: serviceSecretRef.current.value
        }));
    }

    const {
        apiUri,
        serviceId,
    } = state;

    const isUpdateEnabled = !!(apiUri && serviceId && serviceSecretRef.current && serviceSecretRef.current.value);

    return (
        <Form onClose={onClose} isDisabled={isDisabled} closeButtonAriaLabel={t('relativityProxy:option.closeForm')}
            header={
                <FormHeader text={t('relativityProxy:label.proxyConfiguration')} iconName={'relativityProxy'}
                    isDisabled={isDisabled}/>
            }
            body={
                <>
                    <div className="display-input">
                        <HTMLTextInput label={t('relativityProxy:label.apiUri')} name={'apiUri'}
                            value={apiUri} onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                    </div>

                    <div className="display-input">
                        <HTMLTextInput label={t('relativityProxy:label.serviceId')} name={'serviceId'}
                            value={serviceId} onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                    </div>

                    <div className="display-input">
                        <HTMLTextInput label={t('relativityProxy:label.serviceSecret')} name={'serviceSecret'} type={'password'}
                            onChange={inputHandler} onBeforeInput={clearOnFirstCallback} inputRef={serviceSecretRef}
                            isDisabled={isDisabled} isRequired/>
                    </div>
                </>
            }
            footer={
                <FormFooter addText={t('relativityProxy:option.updateConfiguration')} onAddClick={onSubmit}
                    onCancel={onClose} isAddEnabled={isUpdateEnabled} isDisabled={isDisabled}
                />
            }
        />
    )
}

export default RelativityProxyForm;
