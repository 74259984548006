import React, {useCallback} from "react";
import {getValues} from "../../../utilities/helperFunctions";
import {PurviewCacheSaga} from "../../../models/guidedjob/PurviewCache";
import SchedulerModel from "../../../models/scheduler/SchedulerModel";
import {useDispatch, useSelector} from "react-redux";

function PurviewOperationPane(props) {
    const dispatch = useDispatch();
    const {
        OperationPane,
        ...rest
    } = props;
    const {
        parameters
    } = props.guidedJobForm;

    const purviewServiceParameter = getValues(parameters).find(param => param.isPurviewServiceParameter()) || {};
    const purviewService = useSelector(state => state.thirdPartyServiceDetailsMap.get(purviewServiceParameter.value));

    const proxyPurviewRequest = useCallback((resource, opts) => {
        const queryEffect = PurviewCacheSaga.queryResource(purviewServiceParameter.value, resource, opts);
        dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(queryEffect));
    }, [purviewServiceParameter.value]);

    return (
        <div className="pane-margin fill-height">
            <OperationPane {...rest} purviewService={purviewService}
                proxyPurviewRequest={proxyPurviewRequest}/>
        </div>
    )
}

export default PurviewOperationPane;
