import React, {useRef, useState} from "react";
import "./HighlightOption.sass";
import {useTranslation} from "react-i18next";
import {AddRemoveButtons} from "../../common/Button/Button";
import {TableLabelHeader} from "../../common/CustomTable/CustomTable";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import Dropdown from "../../common/Dropdown/Dropdown";
import {SelectableItem} from "../../common/SelectableItem/SelectableItem";
import ToggleSwitch from "../../common/ToggleSwitch/ToggleSwitch";
import TextArea from "../../common/TextArea/TextArea";
import {initialSelectedState, useClearSelectedEffect, useValueSelectHandler} from "../../../utilities/hooks";
import {
    getDataNameFromParentNode,
    getEntries,
    getKeys,
    getParentDatasetAttr,
    isNotEmptyNorFalsy,
    objectTruthyValues,
    stringToBool
} from "../../../utilities/helperFunctions";
import {userSettings} from "../../../utilities/constants";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import HighlightText from "./HighlightText";


const selectHighlightOptions = createSelector(
    state => state.userSettingsMap.get(userSettings.HIGHLIGHTS),
    highlightOptions => {

        const options = {}
        for (const [_key, _option] of getEntries(highlightOptions)) {

            if (isNotEmptyNorFalsy(_option)) {
                options[_key] = _option
            }
        }

        return options
    }
)

function HighlightOption(props) {
    const {t} = useTranslation(['highlights'])

    const highlightOptions = useSelector(selectHighlightOptions)
    const [nonTrackedRegexes, setNonTrackedRegexes] = useState(highlightOptions)


    const containerRef = useRef()
    const [selected, setSelected] = useState(initialSelectedState)

    const selectHandler = useValueSelectHandler({setSelected})
    useClearSelectedEffect({containerRef, setSelected})


    function addHighlightOption() {
        let index = 0
        for (const _key of getKeys(highlightOptions)) {

            if (index === parseInt(_key))
                index++
        }

        props.updateSetting(index, {
            enabled: true,
            regex: '',
            color: 'black',
            backgroundColor: 'yellow',
            isBold: false,
            isItalic: false,
            isUnderline: false,
            isLineThrough: false,
            lineColor: 'black',
            lineStyle: 'solid'
        })
    }

    function removeHighlightOption() {
        const selectedIndices = objectTruthyValues(selected.values)

        for (const index of selectedIndices) {
            props.updateSetting(index, {})
        }

        setSelected(initialSelectedState)
    }

    return (
        <div className="display-input" ref={containerRef}>
            <AddRemoveButtons onAddClick={addHighlightOption} onRemoveClick={removeHighlightOption}
                ariaLabelKey={'Highlight'} label={t('highlights:label.highlights')}
                isRemoveDisabled={objectTruthyValues(selected.values).length < 1}
            />

            {isNotEmptyNorFalsy(highlightOptions) &&
            <HighlightOptionTable highlightOptions={highlightOptions} updateSetting={props.updateSetting}
                selected={selected} selectHandler={selectHandler} setNonTrackedRegexes={setNonTrackedRegexes}
            />
            }

            <ExampleBox highlightOptions={highlightOptions} nonTrackedRegexes={nonTrackedRegexes}/>
        </div>
    )
}


function HighlightOptionTable(props) {
    const {t} = useTranslation(['highlights'])
    const {
        highlightOptions,
        selected
    } = props


    function inputHandler(event) {
        const {target: {name, value}} = event
        const setting = getFirstParentIndex(event)

        // Hack to have exampleBox respond to immediate changes
        props.setNonTrackedRegexes(prev => ({
            ...prev,
            [setting]: {
                [name]: value
            }
        }))
    }
    // Used to avoid triggering a settings save on each input onChange
    function inputBlurHandler(event) {
        const {target: {name, value}} = event
        const setting = getFirstParentIndex(event)

        props.updateSetting(setting, {
            ...highlightOptions[setting],
            [name]: value
        })
    }

    function toggleHandler(event) {
        const {name, checked} = event.currentTarget.dataset
        const setting = getFirstParentIndex(event)

        props.updateSetting(setting, {
            ...highlightOptions[setting],
            [name]: stringToBool(checked)
        })
    }

    function dropdownHandler(event) {
        const {dataset: {value}, parentNode} = event.currentTarget
        const name = getDataNameFromParentNode(parentNode);
        const setting = getFirstParentIndex(event)

        props.updateSetting(setting, {
            ...highlightOptions[setting],
            [name]: value
        })
    }

    function getFirstParentIndex(event) {
        return getParentDatasetAttr(event.target, 'index');
    }

    return (
        <div className="display-table">
            <div className="table-header-group">

                <div className="table-header" style={{width: '4rem'}}/>

                <TableLabelHeader style={{width: '99%', minWidth: '10rem'}}
                    label={t('highlights:label.regex')}
                />
                <TableLabelHeader
                    label={t('highlights:label.textColor')}
                />
                <TableLabelHeader
                    label={t('highlights:label.backgroundColor')}
                />

                <TableLabelHeader
                    label={t('highlights:label.bold')}
                />
                <TableLabelHeader
                    label={t('highlights:label.italic')}
                />

                <TableLabelHeader
                    label={t('highlights:label.underline')}
                />
                <TableLabelHeader
                    label={t('highlights:label.lineThrough')}
                />
                <TableLabelHeader
                    label={t('highlights:label.lineColor')}
                />
                <TableLabelHeader
                    label={t('highlights:label.lineStyle')}
                />
            </div>

            <div className="table-row-group">
                {getEntries(highlightOptions)
                    .map(([index, _option]) =>

                        <HighlightOptionRow {..._option} isActive={selected.values[index]}
                            key={index} index={index} onClick={props.selectHandler}
                            inputHandler={inputHandler} inputBlurHandler={inputBlurHandler}
                            toggleHandler={toggleHandler} dropdownHandler={dropdownHandler}
                        />
                    )}
            </div>
        </div>
    )
}

function HighlightOptionRow(props) {
    const {t} = useTranslation(['highlights']);

    const {
        index,
        isActive,
        enabled,
        regex,
        color,
        backgroundColor,
        isBold,
        isItalic,
        isUnderline,
        isLineThrough,
        lineColor,
        lineStyle
    } = props

    const active = isActive ? ' is-active' : ''
    const isLineUsed = isUnderline || isLineThrough

    return (
        <div className={'table-row' + active} onClick={props.onClick}
            data-index={index}
        >
            <div className="table-cell">
                <ToggleSwitch aria-label={t('aria:hiddenAssistText.enableHighlightOption')} name={'enabled'}
                    checked={enabled} onClick={props.toggleHandler}
                />
            </div>

            <div className="table-cell">
                <HTMLTextInput name={'regex'} defaultValue={regex} aria-label={t('aria:hiddenAssistText.highlightRegex')}
                    onChange={props.inputHandler} onBlur={props.inputBlurHandler}
                />
            </div>

            <div className="table-cell">
                <ColorDropdown name={'color'} value={color}
                    onItemClick={props.dropdownHandler}/>
            </div>

            <div className="table-cell">
                <BackgroundColorDropdown name={'backgroundColor'} value={backgroundColor}
                    onItemClick={props.dropdownHandler}
                />
            </div>

            <div className="table-cell">
                <ToggleSwitch aria-label={t('highlights:label.bold')} name={'isBold'} checked={isBold}
                    onClick={props.toggleHandler}
                />
            </div>
            <div className="table-cell">
                <ToggleSwitch aria-label={t('highlights:label.italic')} name={'isItalic'} checked={isItalic}
                    onClick={props.toggleHandler}
                />
            </div>
            <div className="table-cell">
                <ToggleSwitch aria-label={t('highlights:label.underline')} name={'isUnderline'} checked={isUnderline}
                    onClick={props.toggleHandler}
                />
            </div>
            <div className="table-cell">
                <ToggleSwitch aria-label={t('highlights:label.lineThrough')} name={'isLineThrough'} checked={isLineThrough}
                    onClick={props.toggleHandler}
                />
            </div>

            <div className="table-cell">
                <LineColorDropdown name={'lineColor'} value={lineColor}
                    onItemClick={props.dropdownHandler} isDisabled={!isLineUsed}
                />
            </div>
            <div className="table-cell">
                <LineStyleDropdown name={'lineStyle'} value={lineStyle}
                    onItemClick={props.dropdownHandler} isDisabled={!isLineUsed}
                />
            </div>
        </div>
    )
}

function ExampleBox(props) {
    const {t} = useTranslation(['highlights'])
    const {
        nonTrackedRegexes
    } = props

    const [text, setText] = useState(t('highlights:message.sampleText', {doubleNewLine: "\n\n", newLine: "\n", escapedNewLine: "\\n"}))
    const highlightOptions = {}

    for (const [key, _option] of getEntries(props.highlightOptions)) {
        highlightOptions[key] = {
            ..._option,
            regex: (nonTrackedRegexes[key] || {}).regex
        }
    }

    return (
        <div className="example-box display-input">
            <div className="example-box__textarea">
                <TextArea label={t('highlights:label.sampleText')}
                    rows={10} value={text} onChange={event => setText(event.target.value)}
                />
            </div>

            <div className="example-box__text">
                <label className="label is-wordwrap">

                    <HighlightText highlightOptions={highlightOptions} text={text}/>
                </label>
            </div>
        </div>
    )
}

const colors = {
    white: 'white',
    red: 'red',
    yellow: 'yellow',
    orange: 'orange',
    green: 'green',
    blue: 'blue',
    workflowBlue: 'rgb(0, 86, 150)',
    purple: 'purple',
    brown: 'brown',
    black: 'black'
}
function ColorDropdown(props) {
    const {value, onItemClick, ...rest} = props
    const {t} = useTranslation(['aria', 'highlights'])

    return (
        <Dropdown aria-label={t('aria:hiddenAssistText.color')} {...rest}
            selectedItems={
                <label style={{color: colors[value]}}>
                  {t(`highlights:color.${value}`)}
                </label>
            }

            items={getEntries(colors).map(([key, color]) =>
                <SelectableItem key={key} value={key}
                    onItemClick={onItemClick}
                >
                    <label className="label" style={{color: color}}>
                        {t(`highlights:color.${key}`)}
                    </label>
                </SelectableItem>
            )}
        />
    )
}

const backgroundColors = {
    transparent: 'transparent',
    ...colors
}
function BackgroundColorItem(props) {
    const {t} = useTranslation(['aria', 'highlights'])
    const {bgColor} = props;

    if (bgColor === 'transparent') {
        return (
            <label className="label">
                {t('highlights:color.transparent')}
            </label>
        )
    }

    return (
        <span className="background-color" style={{backgroundColor: bgColor, width: '100%'}}/>
    )
}
function BackgroundColorDropdown(props) {
    const {value, onItemClick, ...rest} = props
    const {t} = useTranslation(['aria', 'highlights'])

    return (
        <Dropdown aria-label={t('aria:hiddenAssistText.backgroundColor')} {...rest}
            selectedItems={
                <BackgroundColorItem bgColor={value}/>
            }

            items={getEntries(backgroundColors).map(([key, color]) =>
                <SelectableItem key={key} value={key}
                    onItemClick={onItemClick}
                >
                    <BackgroundColorItem bgColor={color}/>
                </SelectableItem>
            )}
        />
    )
}

function LineColorDropdown(props) {
    const {value, onItemClick, ...rest} = props
    const {t} = useTranslation(['aria'])

    return (
        <Dropdown aria-label={t('aria:hiddenAssistText.lineColor')} {...rest}
            selectedItems={
                <span className="line-style" style={{textDecorationColor: colors[value]}}>
                    LINE_STYLE
                </span>
            }

            items={getEntries(colors).map(([key, color]) =>
                    <SelectableItem key={key} value={key}
                        onItemClick={onItemClick}
                    >
                        <span className="line-style" style={{textDecorationColor: color}}>
                            LINE_STYLE_FILLER
                        </span>
                    </SelectableItem>
            )}
        />
    )
}

const lineStyles = ['solid', 'double', 'dotted', 'dashed', 'wavy']
function LineStyleDropdown(props) {
    const {value, onItemClick, ...rest} = props
    const {t} = useTranslation(['aria'])

    return (
        <Dropdown aria-label={t('aria:hiddenAssistText.lineStyle')} {...rest}
            selectedItems={
                <span className="line-style" style={{textDecorationStyle: value}}>
                    LINE_STYLE
                </span>
            }
            items={lineStyles.map(_lineStyle =>

                <SelectableItem key={_lineStyle}
                    value={_lineStyle} onItemClick={onItemClick}
                >
                    <label className="line-style" style={{textDecorationStyle: _lineStyle}}>
                        LINE_STYLE_FILLER
                    </label>
                </SelectableItem>
            )}
        />
    )
}

export default HighlightOption
