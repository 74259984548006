import React, {useRef, useState} from "react";
import {initialSelectedState, useClearSelectedEffect, useValueSelectHandler} from "../../utilities/hooks";
import {
    deepCopy,
    filterArrayIndices,
    getFileNameWithoutExtension,
    objectTruthyValues
} from "../../utilities/helperFunctions";
import {AddRemoveButtons} from "../common/Button/Button";
import {LibraryFileDropdown} from "../common/Dropdown/Dropdown";
import {useTranslation} from "react-i18next";
import ParameterModel from "../../models/library/ParameterModel";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import {popupInfoKeys} from "../../i18next/keys";
import {useSelector} from "react-redux";
import LibraryFileModel from "../../models/filelibrary/LibraryFileModel";

function selectResolvedFiles (state, profiles) {
    return profiles.map(file => {
        const libraryFile = state.libraryFileDetailsMap.get(file.fileId);

        if (libraryFile == null) {
            return {
                name: file.name,
                type: "",
                libraryName: ""
            }
        }

        const fileLibrary = state.fileLibraryDetailsMap.get(libraryFile.fileLibraryId);

        return {
            ...file,
            ...libraryFile,
            type: LibraryFileModel.libraryFileTypeToString(libraryFile.nuixFileType),
            libraryName: fileLibrary.name
        }
    })
}

export function ProfileTable (props) {
    const {profiles, stateName, onUpdate, showError, isDisabled} = props;
    const {t} = useTranslation(['common', 'executionProfile'])
    const [selectableItemCount, setSelectableItemCount] = useState(0);

    const containerRef = useRef();
    const [selected, setSelected] = useState(initialSelectedState);

    const valueSelectHandler = useValueSelectHandler({setSelected});
    useClearSelectedEffect({containerRef, setSelected});
    const getSelectedObjects = objectTruthyValues(selected.values);

    const isRemoveDisabled = getSelectedObjects.length === 0;
    const onRemoveClick = () => {
        const updates = {
            [stateName]: filterArrayIndices(profiles, getSelectedObjects)
        };

        onUpdate(updates);
        setSelected(initialSelectedState);
    }

    const toggleRef = useRef();
    const dropdownHandler = (event) => {
        const {dataset: {name, value, type}} = event.currentTarget;
        const profileType = LibraryFileModel.libraryFileTypeToString(type)

        const profile = {name: name, type: profileType, fileId: value};
        const updates = {
            [stateName]: [
                ...profiles,
                profile
            ]
        };

        onUpdate(updates)
    }

    const onAddButtonClick = () => {
        if (selectableItemCount === 0) {
            showError({
                info: {
                    key: popupInfoKeys.TEMPLATE,
                    values: {
                        message: `${t('fileLibrary:error.noNuixProfiles')}`
                    }
                }
            });
        } else {
            toggleRef.current();
        }
    }

    const isAllNuixProfilesSelected = selectableItemCount === 0;
    const resolvedProfiles = useSelector(state => selectResolvedFiles(state, profiles));
    const disabled = isDisabled ? ' is-disabled' : '';
    const selectedProfiles = profiles.map(profile => profile.fileId);

    return (
        <div ref={containerRef}>
            <AddRemoveButtons ariaLabelKey={'ProfileParameter'} onAddClick={onAddButtonClick}
                onRemoveClick={onRemoveClick} isRemoveDisabled={isRemoveDisabled}/>
            <LibraryFileDropdown name={"profileDropdown"}
                                 id={"profileDropdown"}
                                 toggleRef={toggleRef}
                                 selectedFiles={selectedProfiles}
                                 isNuixFiles={true}
                                 selectedLibraryFile={null}
                                 onLibraryFileSelected={dropdownHandler}
                                 updateSelectableItemCount={setSelectableItemCount}
                                 isDisabled={isDisabled}
                                 buttonStyle={{visibility: "hidden", zIndex: 999, position: "absolute"}}/>

            <div className={"display-table"} style={{backgroundColor: '#FFF'}}>
                <div className={"table-header-group"}>
                    <div className={"table-header"}>
                        <label className={"label is-bold" + disabled}>
                            {t('executionProfile:label.profileNameHeader')}
                        </label>
                    </div>
                    <div className={"table-header"}>
                        <label className={"label is-bold" + disabled}>
                            {t('executionProfile:label.profileTypeHeader')}
                        </label>
                    </div>
                </div>
                <div className={"table-row-group"}>
                    {resolvedProfiles.map((profile, index) => {
                        const isSelected = selected.values[index] ? ' is-active' : '';

                        return <div key={`row_${index}`} data-index={index} className={"table-row"+isSelected} onClick={valueSelectHandler}>
                            <div className={"table-cell"}>
                                <label className={"label" + disabled}>
                                    <i>{profile.libraryName}</i> {`${getFileNameWithoutExtension(profile.name)}`}
                                </label>
                            </div>
                            <div className={"table-cell"}>
                                {!!profile.type &&
                                <label
                                    className={"label" + disabled}>{t(`executionProfile:label.${profile.type}`)}</label>
                                }
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export function FileParameterTable (props) {
    const {files, stateName, onUpdate, showError, isDisabled} = props;
    const {t} = useTranslation(['common', 'executionProfile']);
    const [selectableItemCount, setSelectableItemCount] = useState(0);

    const containerRef = useRef();
    const [selected, setSelected] = useState(initialSelectedState);

    const valueSelectHandler = useValueSelectHandler({setSelected});
    useClearSelectedEffect({containerRef, setSelected});
    const getSelectedObjects = objectTruthyValues(selected.values);

    const isRemoveDisabled = getSelectedObjects.length === 0;
    const onRemoveClick = () => {
        const updates = {
            [stateName]: filterArrayIndices(files, getSelectedObjects)
        };

        onUpdate(updates);
        setSelected(initialSelectedState);
    }

    function sameFileName(options) {
        showError({
            info: {
                key: popupInfoKeys.TEMPLATE,
                values: {
                    title: `${t('popupInfo:fileNameExists.title', {...options})}`,
                    message: `${t('popupInfo:fileNameExists.message', {...options})}`
                }
            }
        });
    }

    function sameParameterName(options) {
        showError({
            info: {
                key: popupInfoKeys.TEMPLATE,
                values: {
                    title: `${t('popupInfo:fileParameterExists.title', {...options})}`,
                    message: `${t('popupInfo:fileParameterExists.message', {...options})}`
                }
            }
        });
    }

    function parameterNameBlacklisted(options) {
        showError({
            info: {
                key: popupInfoKeys.TEMPLATE,
                values: {
                    title: `${t('popupInfo:parameterIsBlacklisted.title', {...options})}`,
                    message: `${t('popupInfo:parameterIsBlacklisted.message', {...options})}`
                }
            }
        });
    }

    function renameParameter(existingParameterName) {
        let suffixIndex = 1;
        let parameterName = ParameterModel.normalizeFileParameterName(`${existingParameterName}_${suffixIndex}`);
        for (let j = 0; j < 256; j++) {
            for (let i = 0; i < files.length; i++) {
                parameterName = ParameterModel.normalizeFileParameterName(`${existingParameterName}_${suffixIndex}`)

                if (parameterName === files[i].parameterName) {
                    suffixIndex++;
                }
            }
        }
        return parameterName;
    }

    function isBlackListedParameter (parameterName) {
        const blackListRegex = '^{?(last_metadata_export_file|last_report_file)}?$'
        return parameterName.match(blackListRegex);
    }

    function isUniqueParameterName(parameterName) {
        let existingParameterName = null;

        const seen = [];
        files.forEach((profile) => {
            if (!!profile.parameterName) {
                if (seen.includes(profile.parameterName)) {
                    existingParameterName = parameterName
                }
                seen.push(profile.parameterName);
                seen.push(profile.parameterName.replace("_file", ""))
            }
        });

        return existingParameterName;
    }

    const onParameterChange = (event) => {
        const {value} = event.target;
        const newAdditionalFields = deepCopy(files);
        newAdditionalFields[selected.lastSelectedValue].parameterName = value;

        const updates = {
            [stateName]: newAdditionalFields
        };

        onUpdate(updates);
    }

    const normalizeParameter = (event, index) => {
        const {value} = event.target;
        const newAdditionalFields = deepCopy(files);

        const existingParameterName = isUniqueParameterName(newAdditionalFields[index].parameterName);
        if (isBlackListedParameter(value)) {
            const parameterName = ParameterModel.normalizeFileParameterName(`${value.replace('file', 'user')}`);
            parameterNameBlacklisted({existingParameter: value, updatedParameter: parameterName})
            newAdditionalFields[index].parameterName = parameterName;

        } else if (existingParameterName !== null) {
            const parameterName = renameParameter(value.replace('file', ''));
            sameParameterName({existingParameter: existingParameterName, updatedParameter: parameterName});
            newAdditionalFields[index].parameterName = parameterName;

        } else {
            newAdditionalFields[index].parameterName = ParameterModel.normalizeFileParameterName(value);
        }

        const updates = {
            [stateName]: newAdditionalFields
        };

        onUpdate(updates);
    }

    const toggleRef = useRef();
    const dropdownHandler = (event) => {
        const {dataset: {name, value}} = event.currentTarget;
        const fileName = name;
        let parameterName = ParameterModel.normalizeFileParameterName(fileName.split(".")[0]);

        if (isBlackListedParameter(parameterName)) {
            const updatedParameterName = ParameterModel.normalizeFileParameterName(`${(fileName.split(".")[0]).replace('file', 'user')}`);
            parameterNameBlacklisted({existingParameter: parameterName, updatedParameter: updatedParameterName})
            parameterName = updatedParameterName;
        }

        for (let i = 0; i < files.length; i++) {
            const existingFileParameter = files[i].parameterName;
            const existingFileName = files[i].fileName;

            if (fileName === existingFileName) {
                sameFileName({fileName: fileName});
                return;
            }

            if (parameterName === existingFileParameter) {
                parameterName = renameParameter(fileName.split(".")[0])
                sameParameterName({existingParameter: existingFileParameter, updatedParameter: parameterName})
            }
        }

        const additionalFile = {parameterName: parameterName, fileName: fileName, fileId: value};
        const updates = {
            [stateName]: [
                ...files,
                additionalFile
            ]
        };

        onUpdate(updates)
    }

    const onAddButtonClick = () => {
        if (selectableItemCount === 0) {
            showError({
                info: {
                    key: popupInfoKeys.TEMPLATE,
                    values: {
                        message: `${t('fileLibrary:error.noOtherFiles')}`
                    }
                }
            });
        } else {
            toggleRef.current();
        }
    }

    const isAllCustomFilesSelected = selectableItemCount === 0;
    const disabled = isDisabled ? ' is-disabled' : '';
    const selectedFiles = files.map(file => file.fileId);

    return (
        <div ref={containerRef}>
            <AddRemoveButtons ariaLabelKey={'LibraryFileParameter'}
                onAddClick={onAddButtonClick} onRemoveClick={onRemoveClick} isRemoveDisabled={isRemoveDisabled}/>
            <LibraryFileDropdown name={"fileDropdown"}
                                 id={"fileDropdown"}
                                 toggleRef={toggleRef}
                                 selectedFiles={selectedFiles}
                                 isNuixFiles={false}
                                 selectedLibraryFile={null}
                                 onLibraryFileSelected={dropdownHandler}
                                 isDisabled={isDisabled}
                                 updateSelectableItemCount={setSelectableItemCount}
                                 buttonStyle={{visibility: "hidden", zIndex: 999, position: "absolute"}}/>

            <div className={"display-table"} style={{backgroundColor: '#FFF'}}>
                <div className={"table-header-group"}>
                    <div className={"table-header"}>
                        <label className={"label is-bold" + disabled}>
                            {t('executionProfile:label.parameterName')}
                        </label>
                    </div>
                    <div className={"table-header"}>
                        <label className={"label is-bold" + disabled}>
                            {t('executionProfile:label.fileNameHeader')}
                        </label>
                    </div>
                </div>
                <div className={"table-row-group"}>
                    {files.map((file, index) => {
                        const isSelected = selected.values[index] ? ' is-active' : '';

                        return <div key={`row_${index}`} data-index={index} className={"table-row"+isSelected} onClick={valueSelectHandler}>
                            <div className={"table-cell"} style={{padding: 0}}>
                                <HTMLTextInput aria-label={t('executionProfile:label.parameterName')} containerClassName={'input-list-container'}
                                    className={'input'+ isSelected + disabled} name={`file_parameter_${index}`} value={file.parameterName} onChange={onParameterChange}
                                    onBlur={(event) => normalizeParameter(event, index)} style={{height: '37px', backgroundColor: 'transparent', padding: '0 16px'}}/>
                            </div>
                            <FileLibraryCell fileId={file.fileId} disabled={disabled}/>
                        </div>
                    })}
                </div>
            </div>
        </div>
    );
}

function FileLibraryCell (props) {
    const {fileId, disabled} = props;
    const fileLibraryDetailsMap = useSelector(state => state.fileLibraryDetailsMap);
    const libraryFileDetailsMap = useSelector(state => state.libraryFileDetailsMap);

    let fileName = "";
    let fileLibraryName = "";

    const libraryFile = libraryFileDetailsMap.get(fileId);
    if (!!libraryFile && !!libraryFile.name && !!libraryFile.fileLibraryId) {
        fileName = libraryFile.name;

        const fileLibrary = fileLibraryDetailsMap.get(libraryFile.fileLibraryId);
        if (!!fileLibrary && !!fileLibrary.name) {
            fileLibraryName = fileLibrary.name;
        }
    }

    return (
        <div className={"table-cell"}>
            <label className={"label" + disabled}>
                <i>{fileLibraryName}</i> {`${fileName}`}
            </label>
        </div>
    )
}