import {checkNestedExists, getEntries, getKeys, includesSome, isNotEmptyNorFalsy, objEquals} from "./helperFunctions";
import {permissionKeys, popupInfoKeys} from "../i18next/keys";
import {updateMode} from "./constants";

//scopeName = resource to modify (libraryDetails, clientDetails, jobDetails... etc)
export function getModifyPrivilegedAction(scopeName, item) {
    return function(modifyAction) {
        const details = this.props[`${scopeName}Details`];
        const userPermissions = this.props['userPermissions'] || (checkNestedExists(details, 'userPermissions') && details.userPermissions);

        if (userPermissions.includes(permissionKeys.MODIFY)) {
            return modifyAction;
        } else {
            if (!this.onMissingModifyPrivilege) {
                this.onMissingModifyPrivilege = onMissingModifyPrivilege(item).bind(this);
            }
            return this.onMissingModifyPrivilege;
        }
    };
}

function onMissingModifyPrivilege(item) {
    return function() {
        this.props.showError({
            info: {
                key: popupInfoKeys.MISSING_MODIFY_PRIVILEGE,
                values: {
                    itemLower: item
                }
            }
        });
    }
}

export function getSafeClose(source, blacklist = []) {
    return function() {
        // Debug Log:
        // console.log(getKeys(this[source]).filter(key => !blacklist.includes(key) && typeof this[source][key] !== 'function').filter(key => isNotEmptyNorFalsy(this[source][key])));
        return getKeys(this[source]).filter(key => !blacklist.includes(key) && typeof this[source][key] !== 'function').some(key => isNotEmptyNorFalsy(this[source][key])) ? this.warnOnClose : this.props.close;
    }
}

export function warnOnClose(item) {
    return function() {
        this.props.showWarning({
            info: {
                key: popupInfoKeys.DISCARD_FORM,
                values: {
                    itemTitle: item,
                    itemLower: item.toLowerCase()
                }
            },
            buttons: [{
                title: this.props.t('common:option.discard'),
                onClick: this.props.close
            }]
        });
    }
}
///////////////////////////////////// NO PRE-OPTIMIZATION STUFF////////////////////////////////////////////////////////////////

export function createModifyingActionHandler(options) {
    const {item, userPermissions, checkModifyChildren, showError} = options;

    function errorOnMissingModifyPrivilege() {
        showError({
            info: {
                key: popupInfoKeys.MISSING_MODIFY_PRIVILEGE,
                values: {
                    itemLower: item
                }
            }
        })
    }

    const permissions = [permissionKeys.MODIFY];
    if (checkModifyChildren)
        permissions.push(permissionKeys.MODIFY_CHILDREN);

    return function(action) {
        if (includesSome(userPermissions, permissions))
            return action;

        return errorOnMissingModifyPrivilege;
    }
}

export function createCloseHandler(options) {
    const {popupKey, item, values, blacklist=[], close, showWarning, state, initialState} = options;

    function warnOnClose() {
        showWarning({
            info: {
                key: popupKey || popupInfoKeys.DISCARD_FORM,
                values: {
                    itemTitle: item,
                    itemLower: item && item.toLowerCase()
                }
            },
            buttons: [{
                titleKey: 'common:option.discard',
                onClick: close
            }]
        });
    }

    if (state != null && initialState != null) {
        if (!objEquals(state, initialState)) {
            return warnOnClose;
        }
        return close;
    }

    const notSafe = getEntries(values)
        .filter(([key, val]) => !blacklist.includes(key) && !['function', 'boolean'].includes(typeof val))
        .some(([ignore, val]) => isNotEmptyNorFalsy(val));

    if (notSafe)
        return warnOnClose;

    return close;
}

function setNativeValue(element, value) {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
    const prototype = Object.getPrototypeOf(element);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

    if (valueSetter && valueSetter !== prototypeValueSetter) {
        prototypeValueSetter.call(element, value);
    } else {
        valueSetter.call(element, value);
    }
}

export const inputPasteHandler = createInputPasteHandler();
function createInputPasteHandler() {
    return function onInputPaste(event) {
        const pastedText = event.clipboardData.getData('text');

        // Assuming input values are colon separated
        const idToValues = pastedText.split('\n').reduce((acc, curr) => {
            let [key, val] = curr.split(/:\s+/);
            if (val != null) {
                val = val.trim();
            }
            acc[key] = val;
            return acc;
        }, {});

        let defaultBehavior = true;
        // Resume default behavior if pastedText doesn't follow required pattern; else preventDefault
        if (!isNotEmptyNorFalsy(idToValues)) {
            return;
        }

        for (const [key, val] of getEntries(idToValues)) {
            const input = document.getElementById(key);
            if (input) {
                defaultBehavior = false;
                setNativeValue(input, val);
                input.dispatchEvent(new Event('input', {bubbles: true}));
            }
        }

        if (!defaultBehavior) {
            event.preventDefault();
        }
    }
}

// InputHandler with safe password handling
export function createInputHandler(options) {
    const handler = getHandler(options);

    return function(event) {
        const {name, value, type} = event.target;

        const updates = {};
        // Using same inputHandler for password field for isEnabled logic
        // Do not track passwords
        if (type !== 'password')
            updates[name] = value;

        handler(updates, event);
    }
}

export function createValueHandler(options) {
    const handler = getHandler(options);

    return function(event) {
        const {name, value} = event.currentTarget.dataset;
        const updates = {
            [name]: value
        };

        handler(updates);
    }
}

export function createUpdateHandler(options) {
    const {name, values} = options;
    const handler = getHandler(options);

    return function(update, event) {
        const updates = {
            [name]: typeof update === 'function' ? update(values) : update
        };

        handler(updates, event);
    }
}

export function getHandler(options) {
    const {mode, handler} = options;

    if (handler != null)
        return handler;

    switch (mode) {
        case updateMode.EDIT:
            return createEditHandler(options);
        default:
            return createStateHandler(options);
    }
}

export function createStateHandler(options) {
    const {updateState, passwordRef, passwordName='password', passwordResetNames=['username'], passwordOptions, shouldEnable, isEnabled='isAddEnabled'} = options;

    let _passwordOptions = passwordOptions;
    // New password options format to handle multiple passwords
    if (_passwordOptions == null && passwordRef != null) {
        _passwordOptions = {[passwordName]: {ref: passwordRef, resetNames: passwordResetNames}};
    }

    return function(updates) {

        const passwords = {};
        if (_passwordOptions != null) {
            for (const name of getKeys(_passwordOptions)) {
                const {ref, resetNames = []} = _passwordOptions[name];
                // clear password if a resetName is being updated
                if (ref != null && ref.current != null) {
                    if (resetNames.some(name => updates[name] != null)) {
                        ref.current.value = '';
                    }
                    // value for shouldEnable function
                    passwords[name] = ref.current.value;
                }
            }
        }

        updateState(prevState => {
            if (typeof shouldEnable === 'function' && typeof isEnabled === 'string') {
                updates[isEnabled] = shouldEnable({
                    ...prevState,
                    ...updates,
                    ...passwords
                })
            }

            return {
                ...prevState,
                ...updates
            }
        });
    }
}

export function createEditHandler(options) {
    const {updateEdit, values, shouldEnable, setEditSaveEnabled, ...rest} = options;

    return function(updates) {
        if (typeof shouldEnable !== 'function' && typeof setEditSaveEnabled === 'function') {
            setEditSaveEnabled(true);
        }
        updateEdit(updates, {shouldEnable, ...rest});
    }
}

export function createFileHandler(options) {
    const {onloadend, onloadstart, readAs='readAsArrayBuffer'} = options;

    return function(event) {
        const file = event.target.files[0];

        if (file instanceof File) {
            const fileReader = new FileReader();
            fileReader.onloadstart = onloadstart;
            fileReader.onloadend = e => onloadend(e, file);
            fileReader[readAs](file);
        }

        // Clear file to allow re-selection
        event.target.value = null;
    }
}

// Magic; regular updateState only updates with depth level = 1; this updates with depth-level = nest.length
export function createNestedUpdateState(updateState, nesting) {
    return function(updates) {
        updateState(prevState => {
            const state = {
                ...prevState
            };

            let current = state;
            for (let i = 0; i < nesting.length; i++) {
                current = current[nesting[i]] = {
                    ...current[nesting[i]]
                }
            }

            let update = updates;
            if (typeof updates === 'function') {
                update = updates(current);
            }

            Object.assign(current, update);
            return state;
        });
    }
}

//////////////////////////////////////////////////////////////////////REACT FUNCTIONS//////////////////////////////////////////////////////////////////

export function clearOnFirstCallback(event) {
    const {name} = event.target;
    if (this.didClear == null) {
        this.didClear = {};
    }
    if (this.didClear[name] == null) {
        event.target.value = '';
        this.didClear[name] = true;
    }
}

//options = {stateUpdater, mode, dataNamesToClear, isEnabled, shouldEnable}
export function onInputChange(options) {
    return function(event) {
        const {name, value, type} = event.target;
        const updates = {[name]: value};

        onChange.call(this, updates, options, type);
    }
}

export function onValueChange(options) {
    return function(event) {
        const {name, value} = event.currentTarget.dataset;
        const updates = {[name]: value};

        onChange.call(this, updates, options);
    }
}

export function onChange(updates, options, type) {
    const {dataNamesToClear = []} = options;

    for (let i = 0; i < dataNamesToClear.length; i++) {
        const dataName = dataNamesToClear[i];
        updates[dataName] = '';
    }

    updateState.call(this, updates, options, type);
}

function updateState(updates, {stateUpdater, mode, isEnabled, shouldEnable, passwordName, passwordRef}, type) {
    switch (mode) {
        case updateMode.LOCAL:
        case updateMode.REDUX:
            stateUpdater.call(this, prevState => {
                if (isEnabled && shouldEnable) {
                    updates[isEnabled] = shouldEnable({
                        ...prevState,
                        ...updates,
                        [passwordName]: passwordRef && passwordRef.current.value
                    });
                }

                // If password, do not save state
                if (type === 'password')
                    return {[isEnabled]: updates[isEnabled]};

                return typeof updates === 'function' ? updates(prevState) : updates;
            });
            break;
        case updateMode.EDIT:
            updateEditState.call(this, updates, {shouldEnable, passwordName, passwordRef}, type);
            break;
        case updateMode.CUSTOM:
            stateUpdater(updates);
            break;
        default:
            break;
    }
}

export function updateEditState(updates, {shouldEnable, passwordName, passwordRef}, type) {
    if (shouldEnable != null) {
        
        let values;
        if (checkNestedExists(this.props, 'editDetails', 'values')) {
            values = this.props.editDetails.values;
        } else {
            values = this.props;
        }

        const isSaveEnabled = shouldEnable({
            ...values,
            ...updates,
            [passwordName]: passwordRef && passwordRef.current && passwordRef.current.value
        });

        this.props.setEditSaveEnabled(isSaveEnabled);
    }

    // If password, do not save state
    if (type !== 'password')
        this.props.updateEdit(updates);
}
