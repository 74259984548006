import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {selectJobFromDisplay} from "./selectors";
import {
    applicationFeatures,
    jobCommands,
    jobQueueStates,
    jobStates,
    logEndpoints,
    userSettings
} from "../../utilities/constants";
import {executionStateKeys, jobPageViewKeys, permissionKeys, popupInfoKeys} from "../../i18next/keys";
import JobModel from "../../models/job/JobModel";
import React, {useEffect} from "react";
import MatterModel from "../../models/client/MatterModel";
import WorkflowTemplateModel from "../../models/library/WorkflowTemplateModel";
import PopupModel from "../../models/scheduler/PopupModel";
import RelativityProxyModel from "../../models/relativity/RelativityProxyModel";
import {downloadLogs} from "../../utilities/downloadHelper";
import {includesSome, switchcaseF} from "../../utilities/helperFunctions";
import {executionStateIcon, getStatusIconImgContent} from "../../utilities/iconResolver";
import HighlightText from "../userSettings/HighlightOption/HighlightText";
import {MenuDropdown} from "../common/Dropdown/Dropdown";
import JobStatus from "./JobStatus";

function JobTabletHeader(props) {
    const {t} = useTranslation(['aria', 'job']);
    const dispatch = useDispatch();

    const {
        jobId
    } = props;

    const {
        name,
        clientId,
        matterId,
        libraryId,
        workflowTemplateId,
        resourcePoolId,
        executionProfileId,
        confidential,
        locked,
        executionState,
        percentageComplete,
        hasWarnings,
        hasSoftErrors,
        excludeUtilization,
        userPermissions,
        runningOperationId,
        queueState
    } = useSelector(state => selectJobFromDisplay(state, jobId));

    const {showObjectIds} = useSelector(state => state.userSettingsMap.get(userSettings.TROUBLESHOOT));
    const {autoArchiveOnJobResubmit} = useSelector(state => state.userSettingsMap.get(userSettings.QUEUE_JOB));

    const hasLoaded = useSelector(state => state.hasLoaded);
    const isLoading = !hasLoaded[jobId];
    const isRelativityApplication = useSelector(state => state.schedulerDetails.isRelativityApplication);
    const isArchiveMode = useSelector(state => state.componentStates.jobPage.activeView === jobPageViewKeys.ARCHIVE);
    const isDownloadLogsVisible = useSelector(state => state.currentUser.features.includes(applicationFeatures.CENTRALIZED_LOGGING));

    const runningOperation = useSelector(state => {
        const operations = state.jobOperationsMap.get(jobId);
        return (operations != null && operations[runningOperationId]) || {};
    });
    const submitPermissions = useSelector(state => {
        const parameters = state.parametersMap.get(jobId);
        return JobModel.getSubmitPermissions(state, {matterId, workflowTemplateId, resourcePoolId, executionProfileId, parameters});
    });

    // Query client & library to populate matter & workflow userPermissions
    useEffect(() => {
        if (!hasLoaded[clientId]) {
            dispatch(MatterModel.actionCreators.queryDetailsSubset(clientId));
        }
        if (!hasLoaded[libraryId]) {
            dispatch(WorkflowTemplateModel.actionCreators.queryDetailsSubset(libraryId));
        }
    }, []);


    function resubmit() {
        dispatch(PopupModel.actionCreators.show({
            info: {
                key: 'resubmitJob'
            },
            buttons: [{
                title: t('job:option.submit'),
                onClick: () => dispatch(JobModel.actionCreators.sendCommand(jobId, jobCommands.RESUBMIT_JOB, {autoArchive: autoArchiveOnJobResubmit}))
            }]
        }));
    }

    function duplicate() {
        dispatch(JobModel.actionCreators.duplicateDetails(jobId));
    }

    function downloadJobLogs() {
        if (isRelativityApplication) {
            dispatch(RelativityProxyModel.actionCreators.proxyDownload(logEndpoints.JOB, {jobId}, JobModel.componentActionCreators.updateTablet));
        } else {
            downloadLogs(logEndpoints.JOB, {jobId});
        }
    }

    function printPdf() {
        window.location.href = `${window.location.origin + window.location.pathname}#/jobPdf/${jobId}`
    }

    function skipOperation() {
        dispatch(PopupModel.actionCreators.showWarning({
            info: {
                key: popupInfoKeys.SKIP_OPERATION,
                values: {
                    operationNumber: (runningOperationId + 1),
                    operationName: runningOperation.name
                }
            },
            buttons: [{
                title: t('job:option.skipOperation'),
                onClick: () => dispatch(JobModel.actionCreators.sendCommand(jobId, jobCommands.SKIP, {operationId: runningOperationId}))
            }]
        }));
    }

    function pause() {
        dispatch(PopupModel.actionCreators.showWarning({
            info: {
                key: popupInfoKeys.PAUSE_JOB
            },
            buttons: [{
                title: t('job:option.pause'),
                onClick: () => dispatch(JobModel.actionCreators.sendCommand(jobId, jobCommands.PAUSE))
            }]
        }));
    }

    function jobCommand(command) {
        dispatch(JobModel.actionCreators.sendCommand(jobId, command));
    }

    function cancel() {
        dispatch(PopupModel.actionCreators.showWarning({
            info: {
                key: popupInfoKeys.CANCEL_JOB
            },
            buttons: [{
                title: t('job:option.cancelExecution'),
                onClick: () => dispatch(JobModel.actionCreators.sendCommand(jobId, jobCommands.CANCEL))
            }],
            cancelButton: {
                title: t('job:option.keepJob')
            }
        }));
    }

    function stop() {
        dispatch(PopupModel.actionCreators.showWarning({
            info: {
                key: popupInfoKeys.STOP_JOB
            },
            buttons: [{
                title: t('job:option.stop'),
                onClick: () => dispatch(JobModel.actionCreators.sendCommand(jobId, jobCommands.STOP))
            }]
        }));
    }

    function abort() {
        dispatch(PopupModel.actionCreators.showWarning({
            info: {
                key: popupInfoKeys.ABORT_JOB
            },
            buttons: [{
                title: t('job:option.abort'),
                onClick: () => dispatch(JobModel.actionCreators.sendCommand(jobId, jobCommands.ABORT))
            }]
        }));
    }

    function archive() {
        dispatch(JobModel.actionCreators.sendCommand(jobId, jobCommands.ARCHIVE));
    }

    function requestIncludeUtilization() {
        dispatch(JobModel.actionCreators.sendCommand(jobId, jobCommands.METRICS_INCLUDE));
    }

    function requestExcludeUtilization() {
        dispatch(JobModel.actionCreators.sendCommand(jobId, jobCommands.METRICS_EXCLUDE));
    }

    function menuOptionHandler(event) {
        const {value} = event.currentTarget.dataset;

        switchcaseF({
            'resubmit': resubmit,
            'duplicate': duplicate,
            'downloadJobLogs': downloadJobLogs,
            'printPdf': printPdf,
            'skipOperation': skipOperation,
            'pause': pause,
            'cancel': cancel,
            'stop': stop,
            'abort': abort,
            'archive': archive,
            'requestIncludeUtilization': requestIncludeUtilization,
            'requestExcludeUtilization': requestExcludeUtilization
        })(() => jobCommand(value))(value);
    }

    const canSubmitJob = userPermissions.includes(permissionKeys.SUBMIT_JOB);
    const canStageJob = userPermissions.includes(permissionKeys.STAGE_JOB);
    const canModify = canSubmitJob && includesSome(userPermissions, [permissionKeys.MODIFY, permissionKeys.MODIFY_CHILDREN]);
    const canDownload = userPermissions.includes(permissionKeys.DOWNLOAD_LOGS);

    const isBacklog = jobStates.backlog.includes(executionState);
    const isRunning = jobStates.running.includes(executionState);
    const isFinished = jobStates.completed.includes(executionState);

    const menuOptions = [];
    if (isFinished) {
        menuOptions.push({
            name: t('job:option.resubmit'),
            value: 'resubmit',
            isDisabled: locked || (!canSubmitJob && !canStageJob)
        });
    }
    if (workflowTemplateId != null) {
        menuOptions.push({
            name: t('job:option.duplicate'),
            value: 'duplicate',
            isDisabled: locked || (!canSubmitJob && !canStageJob)
        });
    }
    if (menuOptions.length > 0) {
        menuOptions.push({isSeparator: true});
    }

    if (!isRelativityApplication) {
        menuOptions.push({
            name: t('job:option.print'),
            value: 'printPdf'
        });
    }
    if (isDownloadLogsVisible) {
        menuOptions.push({
            name: t('job:option.downloadLogs'),
            value: 'downloadJobLogs',
            isDisabled: !canDownload
        });
    }

    menuOptions.push({isSeparator: true});
    if (isBacklog) {
        if (queueState === jobQueueStates.STAGING) {
            menuOptions.push({
                name: t('job:option.moveToBacklog'),
                value: jobCommands.MOVE_TO_BACKLOG,
                isDisabled: !canSubmitJob || !submitPermissions.includes(permissionKeys.SUBMIT_JOB)
            });
        } else if (queueState === jobQueueStates.BACKLOG) {
            menuOptions.push({
                name: t('job:option.moveToStaging'),
                value: jobCommands.MOVE_TO_STAGING,
                isDisabled: !canModify
            });
        }
        menuOptions.push({
            name: t('job:option.cancelExecution'),
            value: 'cancel',
            isDisabled: !canModify
        });

    } else if (isRunning) {
        if (runningOperation.skippable && executionState === executionStateKeys.RUNNING) {
            menuOptions.push({
                name: t('job:option.skipOperationSpecific', {
                    operationNumber: (runningOperationId + 1),
                    operationName: runningOperation.name
                }),
                value: 'skipOperation',
                isDisabled: !canModify
            });
        } else {
            menuOptions.push({
                name: t('job:option.skipOperationGeneric'),
                value: 'skipOperation',
                isDisabled: true
            });
        }

        menuOptions.push({
            name: t('job:option.pause'),
            value: 'pause',
            isDisabled: !canModify
        }, {
            name: t('job:option.stop'),
            value: 'stop',
            isDisabled: !canModify
        }, {
            name: t('job:option.abort'),
            value: 'abort',
            isDisabled: !canModify
        });

    } else if (isFinished) {
        if (!isArchiveMode) {
            menuOptions.push({
                name: t('job:option.archive'),
                value: 'archive',
                isDisabled: !canModify
            });
        }
        if (excludeUtilization) {
            menuOptions.push({
                name: t('job:option.includeUtilization'),
                value: 'requestIncludeUtilization',
                isDisabled: !canModify
            });
        } else {
            menuOptions.push({
                name: t('job:option.excludeUtilization'),
                value: 'requestExcludeUtilization',
                isDisabled: !canModify
            });
        }
    }

    return (
        <section id={'jobTabletHeader'} className="tablet-header">
            <section className="tablet-header-left">
                {confidential &&
                    <span id="jobConfidentialIcon" className="confidential-lock icon is-large">
                        {getStatusIconImgContent(locked ? 'statusLocked' : 'statusUnlocked')}
                    </span>
                }

                <div className="job-title">
                    <p className="subtitle is-5 is-bold">
                        <HighlightText text={name}/>
                    </p>
                    {showObjectIds &&
                        <p className="id-label">
                            {jobId}
                        </p>
                    }
                </div>

                {!isLoading &&
                    <article className="display-menu">
                        <MenuDropdown aria-label={t('aria:hiddenAssistText.jobMenu')} isRight menuOptions={menuOptions}
                            onOptionClick={menuOptionHandler} id={'jobMenuDropdown'}
                        />
                    </article>
                }
            </section>

            <section className="tablet-header-right" id="jobTabletExecutionProgress">
                <h2 className="subtitle is-5 is-bold">
                    <JobStatus jobId={jobId} enableHighlightText/>
                </h2>
                <span className="icon is-large" style={{marginLeft: '0.75rem'}}>
                    {executionStateIcon({queueState, executionState, percentageComplete, hasWarnings, hasSoftErrors, size: 'large'})}
                </span>
            </section>
        </section>
    )
}

export default JobTabletHeader;
