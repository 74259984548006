import ComponentStateModel from "../generics/ComponentStateModel";
import {actionCreator, objEquals} from "../../utilities/helperFunctions";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import {all, put, takeLeading} from "redux-saga/effects";
import {contextCall, contextPollUntil, contextSaga, isDisabledWrapper} from "../../saga/sagaFunctions";
import {details} from "../../utilities/constants";
import {SagaRunnable} from "../generics/SagaRunnable";
import ReduxStateModel from "./ReduxStateModel";
import {tryCatchWrapper} from "../../saga/tryCatchWrapper";
import {SchedulerApi} from "./SchedulerModel";
import {CurrentUserSaga} from "../user/CurrentUserModel";


class AutomateLicenceModel {

    static nom = 'AutomateLicenceModel';
    static actions = AutomateLicenceModel.buildActions();
    static actionCreators = AutomateLicenceModel.buildActionCreators(AutomateLicenceModel.actions);
    static reducer = AutomateLicenceModel.buildReducer(AutomateLicenceModel.actions);

    static componentActionCreators = {
        ...AutomateLicenceModel.buildComponentUpdateActionCreators(),
        ...AutomateLicenceModel.buildComponentSetActiveActionCreators()
    };

    constructor(model) {
        this.version = '';
        this.id = model.licenceInfo && model.licenceInfo.id;
        this.name = model.licenceInfo && model.licenceInfo.name;
        this.description = model.licenceInfo && model.licenceInfo.description;
        this.enabled = model.licenceInfo && model.licenceInfo.enabled;
        this.startDate = model.licenceInfo && model.licenceInfo.startDate;
        this.endDate = model.licenceInfo && model.licenceInfo.endDate;
        this.environment = model.licenceInfo && model.licenceInfo.environment;
        this.edition = model.licenceInfo && model.licenceInfo.edition;
        this.editions = model.licenceInfo && model.licenceInfo.editions;
        this.modules = model.licenceInfo && model.licenceInfo.modules;
        this.allModules = model.licenceInfo && model.licenceInfo.allModules;
        this.nuixLicences = model.licenceInfo && model.licenceInfo.nuixLicenses;
        this.thirdPartyLicenses = model.licenceInfo && model.licenceInfo.thirdPartyLicenses;
        this.status = model.status;
        this.diagnosticLevel = model.diagnosticLevel;
        this.diagnosticKeyId = model.diagnosticKeyId && model.diagnosticKeyId.split("-")[0];
        this.userPermissions = model.userPermissions || [];
    }


    static buildActions() {
        return {
            // DETAILS ACTIONS
            SET_LICENCE: `SET_LICENCE`,
            // DETAILS POLLING ACTIONS
            START_POLLING_DETAILS: `START_POLLING_AUTOMATE_LICENCE_DETAILS`,
            STOP_POLLING_DETAILS: `STOP_POLLING_AUTOMATE_LICENCE_DETAILS`,
            QUERY_DETAILS: 'QUERY_AUTOMATE_LICENCE_DETAILS',
            SUBMIT_FORM: `SUBMIT_AUTOMATE_LICENCE_FORM`,
            SUBMIT_FILE: `SUBMIT_AUTOMATE_LICENCE_FILE`,

            SET_VERSION: 'SET_VERSION',
        };
    }

    static buildActionCreators(actions) {
        return {
            // DETAILS ACTION CREATORS
            setLicence: actionCreator(actions.SET_LICENCE, 'licence'),
            // DETAIL POLLING ACTION CREATORS
            startPollingDetails: actionCreator(actions.START_POLLING_DETAILS, 'period'),
            stopPollingDetails: actionCreator(actions.STOP_POLLING_DETAILS),
            queryDetails: actionCreator(actions.QUERY_DETAILS),

            submitForm: actionCreator(actions.SUBMIT_FORM, 'formData'),
            submitFile: actionCreator(actions.SUBMIT_FILE, 'contents'),

            setVersion: actionCreator(actions.SET_VERSION, 'version'),
        };
    }


    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'automateLicenceDisplay',
                type: 'Display',
                state: {
                    isFormActive: false
                }
            },
            {
                key: 'automateLicenceForm',
                type: 'Form',
                state: {
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }


    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'AUTOMATE_LICENCE_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }

    static buildReducer(actions) {
        return function (state = {}, action) {
            switch (action.type) {
                case actions.SET_LICENCE: {
                    const {licence} = action.payload;

                    this.lastUpdated = Date.now();
                    return new this(licence);
                }
                case actions.SET_VERSION: {
                    const {version} = action.payload;

                    this.lastUpdated = Date.now();
                    return {
                        ...state,
                        version
                    }
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }
}

export class AutomateLicenceApi {

    static getLicence(){
        return axiosInstance.get('/scheduler/resources/automateLicense');
    }

    static testLicence(){
        return axiosInstance.put('/scheduler/resources/test');
    }

    static setCredentials(credentials){
        return axiosInstance.put('/scheduler/resources/automateLicense/credentials',credentials);
    }

    static setFile(contents){
        return axiosInstance.put('/scheduler/resources/automateLicense/file', {contents});
    }
}

export class AutomateLicenceSaga extends SagaRunnable {

    static activationComponent = 'AUTOMATE_LICENCE_DISPLAY';

    static buildActivationEffects(dispatch) {
        return [
            ...super.buildActivationEffects(dispatch),
            // ACTIVATION EFFECTS
            put(AutomateLicenceModel.actionCreators.startPollingDetails()),
            takeLeading(AutomateLicenceModel.actions.SUBMIT_FORM, tryCatchWrapper, isDisabledWrapper, AutomateLicenceModel.componentActionCreators.updateForm, contextSaga(this, 'submitForm')),
            takeLeading(AutomateLicenceModel.actions.SUBMIT_FILE, tryCatchWrapper, isDisabledWrapper, AutomateLicenceModel.componentActionCreators.updateForm, contextSaga(this, 'submitFile')),
        ]
    }

    static buildDeactivationEffects() {
        return [
            ...super.buildDeactivationEffects(),
            // DEACTIVATION EFFECTS
            put(AutomateLicenceModel.actionCreators.stopPollingDetails())
        ]
    }

    static* submitForm(action) {
        const {formData} = action.payload;
        const {data} = yield contextCall(AutomateLicenceApi, 'setCredentials', formData);

        yield all([
            put(AutomateLicenceModel.actionCreators.setLicence(data)),
            put(AutomateLicenceModel.componentActionCreators.updateDisplay({isFormActive: false}))
        ])
    }

    static* submitFile(action) {
        const {contents} = action.payload;
        const {data} = yield contextCall(AutomateLicenceApi, 'setFile', contents);

        yield all([
            put(AutomateLicenceModel.actionCreators.setLicence(data)),
            put(AutomateLicenceModel.componentActionCreators.updateDisplay({isFormActive: false}))
        ])
    }

    static* pollDetails(action) {
        const {period} = action.payload;

        yield contextPollUntil(AutomateLicenceModel.actions.STOP_POLLING_DETAILS, period, this, 'queryDetails');
    }

    static* queryDetails() {
        const responses = yield all([
            contextCall(AutomateLicenceApi, 'getLicence'),
            contextCall(SchedulerApi, 'getVersion')
        ]);
        const key = details.AUTOMATE_LICENCE;

        if (AxiosProxy.shouldUpdate(key, responses)) {
            const [licenceRes, versionRes] = responses;
            yield all([
                put(AutomateLicenceModel.actionCreators.setLicence(licenceRes.data)),
                put(AutomateLicenceModel.actionCreators.setVersion(versionRes.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }

    deployedLicence;
    static* isAutomateLicenceChanged(action) {
        const {licence} = action.payload;

        if (licence && !objEquals(licence.licenceInfo, this.deployedLicence)) {
            yield all([
                CurrentUserSaga.queryFeatures()
            ]);
        }
        this.deployedLicence = licence.licenceInfo;
    }
}

export default AutomateLicenceModel;
