import {buildOperationFieldParameterNames} from "../../guidedJob/operationFieldParameterNames";
import WorkflowBuilderOperation from "../../../models/workflowbuilder/WorkflowBuilderOperation";
import VaultOperationPane from "./VaultOperatonPane";
import React from "react";
import {getNonEmptyValueOrDefault, stringToBool} from "../../../utilities/helperFunctions";
import Checkbox from "../../common/Checkbox/Checkbox";
import {useTranslation} from "react-i18next";
import HighlightHTMLTextInput from "../../common/HTMLTextInput/HighlightHTMLTextInput";
import {vaultJobParameterNames} from "../VaultGuidedJobForm";
import {objectIdentifierType} from "../../../utilities/constants";

const downloadExportsFieldNames = ['downloadLocation', 'includeLinkedExports', 'exportIdentifiers', 'exportIdentifierType'];
export const vaultDownloadExportsParameterNames = buildOperationFieldParameterNames(WorkflowBuilderOperation.Alias.VAULT_DOWNLOAD_EXPORTS, downloadExportsFieldNames);

function getNextEnabled(getParameter) {
    const downloadLocation = getParameter(vaultDownloadExportsParameterNames.downloadLocation);
    return {
        downloadExports: downloadLocation.value
    }
}

export function getVaultDownloadExportsGuide(operation, props) {
    const {
        workflowActions,
        getParameter,
        updateParameter
    } = props;

    const includeLinkedExports = getParameter(vaultDownloadExportsParameterNames.includeLinkedExports);
    const exportIdentifiers = getParameter(vaultDownloadExportsParameterNames.exportIdentifiers, '[]');

    if (includeLinkedExports.value !== JSON.stringify(stringToBool(includeLinkedExports.value))) {
        updateParameter(vaultDownloadExportsParameterNames.includeLinkedExports, JSON.stringify(true));
    }
    if (workflowActions.exportSavedQueries) {
        updateParameter(vaultDownloadExportsParameterNames.exportIdentifierType, objectIdentifierType.ID);
        if (!exportIdentifiers.value.startsWith('[') || !exportIdentifiers.value.endsWith(']')) {
            updateParameter(vaultDownloadExportsParameterNames.exportIdentifiers, JSON.stringify([vaultJobParameterNames.exportIds]));

        } else if (!exportIdentifiers.value.includes(vaultJobParameterNames.exportIds)) {
            const updatedIdentifiers = JSON.parse(exportIdentifiers.value).push(vaultJobParameterNames.exportIds);
            updateParameter(vaultDownloadExportsParameterNames.exportIdentifiers, JSON.stringify(updatedIdentifiers));
        }
    }

    return {
        getPanes: function(t, props) {
            const downloadExportsTitle = getNonEmptyValueOrDefault(operation.notes, t('guidedJob:panes.downloadExports'));
            const nextEnabled = getNextEnabled(props.getParameter);

            return [{
                title: downloadExportsTitle,
                isNextEnabled: nextEnabled.downloadExports,
                component: <VaultOperationPane {...props}
                    workflowActions={workflowActions} OperationPane={VaultDownloadExportsPane}/>
            }];
        }
    }
}


function VaultDownloadExportsPane(props) {
    const {t} = useTranslation(['guidedJob']);
    const {
        getParameter,
        updateParameter,
        isDisabled
    } = props;

    const downloadLocation = getParameter(vaultDownloadExportsParameterNames.downloadLocation);
    const includeLinkedExports = getParameter(vaultDownloadExportsParameterNames.includeLinkedExports, false, {parse: true});

    function inputHandler(event) {
        const {name, value} = event.target;
        updateParameter(name, value);
    }

    function toggleHandler(event) {
        const {name, checked} = event.target;
        updateParameter(name, JSON.stringify(checked));
    }

    return (
        <div>
            <div className="display-input">
                <HighlightHTMLTextInput label={t('guidedJob:label.downloadLocation')} name={vaultDownloadExportsParameterNames.downloadLocation}
                    value={downloadLocation.value} onChange={inputHandler} isRequired isDisabled={isDisabled}/>
            </div>

            <div className="display-input">
                <Checkbox label={t('guidedJob:label.includeLinkedExports')} name={vaultDownloadExportsParameterNames.includeLinkedExports}
                    value={includeLinkedExports.value} onClick={toggleHandler} isDisabled={isDisabled}/>
            </div>
        </div>
    )
}
