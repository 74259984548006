import React from 'react';
import {ListDropdown} from "../../../../components/common/Dropdown/Dropdown";
import {useTranslation} from "react-i18next";

function DiscoverCaseIdentifierDropdown(props) {
    const {t} = useTranslation(['aria', 'wizard']);

    const {selectedCaseIdentifierType, onCaseIdentifierTypeSelected, ...rest} = props;
    const dateFilterTypeValues = [
        {value: "SAAS", name: "SaaS"},
        {value: "LOCAL", name: "Local"}
    ]

    return (
        <ListDropdown id={'caseIdentifierDropdown'} name={'deploymentType'}
            aria-label={t('aria:hiddenAssistText.endpointType')} value={selectedCaseIdentifierType}
                        items={dateFilterTypeValues} onItemSelect={onCaseIdentifierTypeSelected}
                        buttonStyle={{maxWidth: '12rem'}}
                        {...rest}
        />
    )
}

export default DiscoverCaseIdentifierDropdown;