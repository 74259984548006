import DetailsModel, {DetailsApi, DetailsSaga} from "../generics/DetailsModel";
import ComponentStateModel from "../generics/ComponentStateModel";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import {all, call, put, select} from "redux-saga/effects";
import {contextCall} from "../../saga/sagaFunctions";
import {details, licenceSourceTypes, PASSWORD_PLACEHOLDER, routes} from "../../utilities/constants";
import {capitalize, cleanFalsy, postProtectedValue} from "../../utilities/helperFunctions";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import {genericErrorHandler} from "../../saga/tryCatchWrapper";
import PopupModel from "../scheduler/PopupModel";
import SchedulerModel from "../scheduler/SchedulerModel";
import {settingsDisplayKeys} from "../../i18next/keys";

class LicenceSourceModel extends DetailsModel {

    static nom = 'LicenceSourceModel';
    static actions = LicenceSourceModel.buildActions('LICENCE_SOURCE');
    static actionCreators = LicenceSourceModel.buildActionCreators(LicenceSourceModel.actions);
    static reducer = LicenceSourceModel.buildReducer(LicenceSourceModel.actions);

    static componentActionCreators = {
        ...LicenceSourceModel.buildComponentUpdateActionCreators(),
        ...LicenceSourceModel.buildComponentSetActiveActionCreators()
    };

    constructor(model = {}) {
        super(model);
        const {sourceType, relayType, serverName, serverPort, username, filter, whitelistedCertFingerprints} = model;

        this.sourceType = sourceType;
        this.relayType = relayType;
        this.serverName = serverName;
        this.serverPort = serverPort;
        this.username = username;
        this.filter = filter;
        this.whitelistedCertFingerprints = whitelistedCertFingerprints;
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'licenceSourceDisplay',
                type: 'Display',
                state: {
                    nuixLicenceSourceId: null,
                    isNmsLicenceSourceFormActive: false,
                    isClsLicenceSourceFormActive: false,
                    isDongleLicenceSourceFormActive: false
                }
            },
            {
                key: 'licenceSourceTablet',
                type: 'Tablet',
                state: {
                    isDisabled: false
                }
            },
            {
                key: 'licenceSourceForm',
                type: 'Form',
                state: {
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'LICENCE_SOURCE_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }
}

export class LicenceSourceApi extends DetailsApi {

    static location = '/resources';
    static type = '/nuixLicenseSource';

    static putTest(id) {
        return axiosInstance.put(`/scheduler/resources/nuixLicenseSource/${id}/test`);
    }

    static postTest(data) {
        return axiosInstance.post('/scheduler/resources/nuixLicenseSource/test', data);
    }
}

export class LicenceSourceSaga extends DetailsSaga {

    static ModelType = LicenceSourceModel;
    static ModelApi = LicenceSourceApi;

    static activationComponent = 'LICENCE_SOURCE_DISPLAY';
    static variableNames = {
        detailsMap: 'nuixLicenceSourceDetailsMap',
        instanceId: 'nuixLicenceSourceId',
        updateDisplay: 'updateDisplay',
        updatePane: 'updateTablet',
        route: routes.SETTINGS
    };

    static translations = {
        itemTitle: '$t(nuixLicenceSource:label.name_simple)',
        itemLower: '$t(nuixLicenceSource:label.name_simple_lower)'
    };

    static buildActivationEffects(dispatch) {
        return [
            ...super.buildActivationEffects(dispatch),
            // ACTIVATION EFFECTS
            put(LicenceSourceModel.actionCreators.startPollingDetails())
        ]
    }

    static buildDeactivationEffects() {
        return [
            ...super.buildDeactivationEffects(),
            // DEACTIVATION EFFECTS
            put(LicenceSourceModel.actionCreators.stopPollingDetails())
        ]
    }

    static* setInstanceId(args) {
        const {updateDisplay, instanceId} = this.variableNames;

        yield all ([
            put(SchedulerModel.actionCreators.setSettingsDisplay(settingsDisplayKeys.NUIX_LICENCE_SOURCES)),
            put(this.ModelType.componentActionCreators[updateDisplay]({[instanceId]: args.id}))
        ]);
    }

    // Saga called manually, w/o actions pattern
    static* testRule(id) {
        try {
            yield put(LicenceSourceModel.componentActionCreators.updateTablet({isDisabled: true}));

            const {data} = yield contextCall(LicenceSourceApi, 'putTest', id);
            yield put(PopupModel.actionCreators.showSuccess({
                info: data
            }));
        } catch (error) {
            yield call(genericErrorHandler, error);
        } finally {
            yield put(LicenceSourceModel.componentActionCreators.updateTablet({isDisabled: false}));
        }
    }

    // Saga called manually, w/o actions pattern
    static* testRuleData(component, formData) {
        try {
            yield put(LicenceSourceModel.componentActionCreators[`update${component}`]({isDisabled: true}));
            const saveValues = this.getSaveValues(formData);

            if (formData.id != null) {
                saveValues.id = formData.id;
            }

            const {data} = yield contextCall(LicenceSourceApi, 'postTest', saveValues);
            yield put(PopupModel.actionCreators.showSuccess({
                info: data
            }));

        } catch (error) {
            yield call(genericErrorHandler, error);
        } finally {
            yield put(LicenceSourceModel.componentActionCreators[`update${component}`]({isDisabled: false}));
        }
    }

    static* submitForm(action) {
        const {formData} = action.payload;

        const saveValues = this.getSaveValues(formData);
        const {data} = yield contextCall(LicenceSourceApi, 'post', saveValues);

        const type = capitalize(formData.sourceType);
        const isFormActive = `is${type}LicenceSourceFormActive`;

        yield all([
            put(LicenceSourceModel.actionCreators.addDetails(data)),
            put(LicenceSourceModel.componentActionCreators.updateDisplay({
                nuixLicenceSourceId: data.id,
                [isFormActive]: false
            }))
        ]);
    }

    static* getEditValues(id) {
        const details = yield select(state => state.nuixLicenceSourceDetailsMap.get(id));

        switch (details.sourceType) {
            case licenceSourceTypes.NMS:
                return this.buildNmsEditModel(details);
            case licenceSourceTypes.CLS:
                return this.buildClsEditModel(details);
            case licenceSourceTypes.DONGLE:
                return this.buildDongleEditModel(details);
            default:
                throw new Error("Type not implemented");
        }
    }

    static getSaveValues(values) {
        switch (values.sourceType) {
            case licenceSourceTypes.NMS:
                return this.buildNmsSaveModel(values);
            case licenceSourceTypes.CLS:
                return this.buildClsSaveModel(values);
            case licenceSourceTypes.DONGLE:
                return this.buildDongleSaveModel(values);
            default:
                throw new Error("Type not implemented");
        }
    }

    static buildNmsEditModel(details) {
        const {name, description, filter, serverName, serverPort, username, whitelistedCertFingerprints, sourceType, relayType} = details;

        return {
            nmsLicenceSourceName: name,
            description,
            filter,
            nmsName: serverName,
            nmsPort: serverPort,
            nmsLicenseSourceUsername: username,
            nmsLicenseSourcePassword: (!!username ? PASSWORD_PLACEHOLDER : ''),
            whitelistedCertFingerprints,
            sourceType,
            relayType
        }
    }

    static buildNmsSaveModel(values) {
        const {nmsLicenceSourceName: name, description, filter, relayType, nmsName, nmsPort, nmsLicenseSourceUsername, whitelistedCertFingerprints} = values;

        // Get password from DOM (assume only 1 password input)
        const nmsLicenseSourcePassword = (document.getElementsByName('nmsLicenseSourcePassword')[0] || {}).value;

        return {
            sourceType: licenceSourceTypes.NMS,
            name,
            description,
            filter,
            relayType,
            serverName: nmsName,
            serverPort: nmsPort,
            username: nmsLicenseSourceUsername,
            password: postProtectedValue(nmsLicenseSourcePassword),
            whitelistedCertFingerprints: cleanFalsy(whitelistedCertFingerprints),
        }
    }

    static buildClsEditModel(details) {
        const {name, description, filter, username, sourceType} = details;

        return {
            clsLicenceSourceName: name,
            description,
            filter,
            clsLicenseSourceUsername: username,
            clsLicenseSourcePassword: (!!username ? PASSWORD_PLACEHOLDER: ''),
            sourceType
        }
    }

    static buildClsSaveModel(values) {
        const {clsLicenceSourceName: name, description, filter, clsLicenseSourceUsername} = values;

        // Get password from DOM (assume only 1 password input)
        const clsLicenseSourcePassword = (document.getElementsByName('clsLicenseSourcePassword')[0] || {}).value;

        return {
            sourceType: licenceSourceTypes.CLS,
            name,
            description,
            filter,
            username: clsLicenseSourceUsername,
            password: postProtectedValue(clsLicenseSourcePassword)
        }
    }

    static buildDongleEditModel(details) {
        const {name, description, filter, sourceType} = details;

        return {
            dongleName: name,
            description,
            filter,
            sourceType
        }
    }

    static buildDongleSaveModel(values) {
        const {dongleName: name, description, filter} = values;

        return {
            sourceType: licenceSourceTypes.DONGLE,
            name,
            description,
            filter
        }
    }

    static* queryDetails() {
        const response = yield contextCall(LicenceSourceApi, 'getDetails');
        const key = details.NUIX_LICENCE_SOURCES;

        if (AxiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(LicenceSourceModel.actionCreators.setDetailsMap(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }
}

export default LicenceSourceModel;