import React from "react";
import {getNonEmptyValueOrDefault} from "../../../utilities/helperFunctions";
import {useTranslation} from "react-i18next";
import {objectIdentifierType} from "../../../utilities/constants";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import TextArea from "../../common/TextArea/TextArea";
import PurviewOperationPane from "./PurviewOperationPane";
import {purviewJobParameterNames} from "../PurviewGuidedJobForm";
import WorkflowBuilderOperation from "../../../models/workflowbuilder/WorkflowBuilderOperation";
import {buildOperationFieldParameterNames} from "../../guidedJob/operationFieldParameterNames";
import {icon} from "../../../utilities/iconResolver";
import {Button} from "../../common/Button/Button";

const fieldNames = ['searchIdentifierType', 'searchIdentifier', 'createSearchIfNotExists', 'description', 'kqlContentQuery',
    'allCaseCustodians', 'allCaseNoncustodialDataSources', 'useCustodialDataSourceIds', 'custodialDataSourceIds',
    'useNonCustodialDataSourceIds', 'nonCustodialDataSourceIds'];
export const purviewAddToSearchParameterNames = buildOperationFieldParameterNames(WorkflowBuilderOperation.Alias.PURVIEW_ADD_TO_SEARCH, fieldNames);

const estimateSearchFieldNames = ['searchIdentifierType', 'searchIdentifier', 'waitForCompletion'];
export const purviewEstimateSearchParameterNames = buildOperationFieldParameterNames(WorkflowBuilderOperation.Alias.PURVIEW_ESTIMATE_SEARCH_STATISTICS, estimateSearchFieldNames);

function getNextEnabled(getParameter) {
    const searchIdentifierType = getParameter(purviewAddToSearchParameterNames.searchIdentifierType);
    const searchIdentifier = getParameter(purviewAddToSearchParameterNames.searchIdentifier);
    return {
        addToSearch: objectIdentifierType[searchIdentifierType.value] != null && searchIdentifier.value.length > 0,
    };
}

export function getPurviewAddToSearchGuide(operation, props) {
    const {
        workflowActions,
        getParameter,
        updateParameter
    } = props;

    const searchIdentifier = getParameter(purviewAddToSearchParameterNames.searchIdentifier);

    updateParameter(purviewAddToSearchParameterNames.searchIdentifierType, objectIdentifierType.NAME);
    updateParameter(purviewAddToSearchParameterNames.createSearchIfNotExists, JSON.stringify(true));
    if (!searchIdentifier.value) {
        updateParameter(purviewAddToSearchParameterNames.searchIdentifier, `Search-{date_time}`);
    }
    if (workflowActions.addCustodialDataSources) {
        updateParameter(purviewAddToSearchParameterNames.useCustodialDataSourceIds, JSON.stringify(true));
        updateParameter(purviewAddToSearchParameterNames.custodialDataSourceIds, purviewJobParameterNames.custodialDataSourceODataIds);
    }
    if (workflowActions.addNonCustodialDataSources) {
        updateParameter(purviewAddToSearchParameterNames.useNonCustodialDataSourceIds, JSON.stringify(true));
        updateParameter(purviewAddToSearchParameterNames.nonCustodialDataSourceIds, purviewJobParameterNames.nonCustodialDataSourceODataIds);
    }
    updateParameter(purviewEstimateSearchParameterNames.searchIdentifierType, objectIdentifierType.ID);
    updateParameter(purviewEstimateSearchParameterNames.searchIdentifier, purviewJobParameterNames.searchId);
    updateParameter(purviewEstimateSearchParameterNames.waitForCompletion, JSON.stringify(true));

    return {
        getPanes: function(t, props) {
            const addToSearchTitle = getNonEmptyValueOrDefault(operation.notes, t('guidedJob:panes.addToPurviewSearch'));
            const nextEnabled = getNextEnabled(props.getParameter);

            return [{
                title: addToSearchTitle,
                isNextEnabled: nextEnabled.addToSearch,
                component: <PurviewOperationPane {...props}
                    workflowActions={workflowActions} OperationPane={PurviewAddToSearchPane}/>
            }];
        }
    }
}

function PurviewAddToSearchPane(props) {
    const {t} = useTranslation(['guidedJob', 'common']);
    const {
        getParameter,
        updateParameter,
        isDisabled
    } = props;

    const searchIdentifier = getParameter(purviewAddToSearchParameterNames.searchIdentifier);
    const description = getParameter(purviewAddToSearchParameterNames.description);
    const kqlContentQuery = getParameter(purviewAddToSearchParameterNames.kqlContentQuery);

    function inputHandler(event) {
        const {name, value} = event.target;
        updateParameter(name, value);
    }

    return (
        <div>
            <div className="display-input">
                <HTMLTextInput label={t('guidedJob:label.searchName')} name={purviewAddToSearchParameterNames.searchIdentifier}
                    value={searchIdentifier.value} onChange={inputHandler} isRequired isDisabled={isDisabled}/>
            </div>

            <div className="display-input">
                <TextArea label={t('guidedJob:label.description')} name={purviewAddToSearchParameterNames.description}
                    value={description.value} onChange={inputHandler} isDisabled={isDisabled}/>
            </div>

            <div className="display-input">
                <TextArea label={t('guidedJob:label.kqlContentQuery')} name={purviewAddToSearchParameterNames.kqlContentQuery}
                    value={kqlContentQuery.value} onChange={inputHandler} placeholder={contentQueryPlaceHolder} isDisabled={isDisabled}
                    afterLabel={
                        <a href={purviewSearchQueryGuideUrl} target="_blank" rel="noopener noreferrer" style={{marginLeft: '0.25rem'}}>
                            <Button aria-label={t('aria:hiddenAssistText.purviewSearchKQLContentQueryGuide')} isImg isClear isDisabled={isDisabled}>
                                <span className="icon">
                                    <img src={icon("operationHelper")} alt={t(`aria:hiddenAssistText.userGuideIcon`)}/>
                                </span>
                            </Button>
                        </a>
                    }/>
            </div>
        </div>
    )
}

const purviewSearchQueryGuideUrl = 'https://learn.microsoft.com/en-us/purview/ediscovery-keyword-queries-and-search-conditions';
const contentQueryPlaceHolder = '(FileType:zip OR FileType:7z OR FileType:rar OR FileType:pst) OR (Size<"3072B" AND (FileType:gif OR FileType:bmp OR FileType:png OR FileType:jpg OR FileType:jpeg))';
