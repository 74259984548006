import React from 'react';
import {ListDropdown} from "../../../../components/common/Dropdown/Dropdown";
import {useTranslation} from "react-i18next";

function DiscoverCaseCreationDropdown(props) {
    const {t} = useTranslation(['aria', 'wizard']);

    const {selectedCaseCreationType, onCaseCreationTypeSelected, ...rest} = props;
    const caseCreationTypeValues = [
        {value: "TEMPLATE", name: t('wizard:questions.discover.cloneCase')},
        {value: "NO_TEMPLATE", name: t('wizard:questions.discover.existingCase')}
    ]

    return (
        <ListDropdown id={'caseCreationDropdown'} name={'createCaseFromTemplate'}
            aria-label={t('aria:hiddenAssistText.endpointType')} value={selectedCaseCreationType}
                        items={caseCreationTypeValues} onItemSelect={onCaseCreationTypeSelected}
                        buttonStyle={{maxWidth: '20rem'}}
                        {...rest}
        />
    )
}

export default DiscoverCaseCreationDropdown;