import React from "react";
import {useTranslation} from "react-i18next";
import {stringToBool} from "../../../utilities/helperFunctions";
import OptionToggleRow from "./OptionToggleRow";
import initialUserSettings from "../../../models/user/initialUserSettings";
import {userSettings} from "../../../utilities/constants";

function JobLaneOption(props) {
    const {setting: jobLaneOption, updateSetting} = props;
    const {t} = useTranslation(['jobLaneOption']);

    function toggleSetting(event) {
        const {name, checked} = event.currentTarget.dataset;
        updateSetting(name, stringToBool(checked));
    }

    return (
        <div className="display-input">
            <table>
                <thead className="table-header-group">
                <tr>
                    <td className="table-header" colSpan={2}/>
                </tr>
                </thead>

                <tbody>
                {Object
                    .keys(initialUserSettings.get(userSettings.JOB_LANE))
                    .map(key =>
                        <OptionToggleRow key={key} label={t(`jobLaneOption:label.${key}`)}
                            name={key} checked={jobLaneOption[key]} onClick={toggleSetting}/>
                    )
                }
                </tbody>
            </table>
        </div>
    )
}

export default JobLaneOption;
