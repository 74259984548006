
function Switch(props) {

  let children = props.children;
  if (!Array.isArray(children)) {
    children = [props.children];
  }

  return children.find(_child => !!_child) || null;
}

export default Switch;