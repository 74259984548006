import React, {useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    getLocaleDateTimeFromUTC,
    getNewArrayWithUpdatedValue,
    getValues,
    isNotEmptyNorFalsy
} from "../../utilities/helperFunctions";
import {userNoticeCommands} from "../../utilities/constants";
import FormBuilder from "../common/FormBuilder/FormBuilder";
import {permissionKeys} from "../../i18next/keys";
import UserNoticeModel, {UserNoticeApi} from "../../models/notice/UserNoticeModel";
import {makeGetEditDetails} from "../../reselect/selectors";
import EditModel from "../../models/scheduler/EditModel";
import {createEditHandler} from "../../utilities/componentFunctions";
import {ButtonGroup} from "../common/Button/Button";
import Text from "../common/Text/Text";
import NoticeCommentDisplay from "./NoticeCommentDisplay";
import SchedulerModel from "../../models/scheduler/SchedulerModel";
import {activityEvents} from "../../saga/idleHandler";
import {useDebounceCallback} from "../../utilities/hooks";
import ExpandableContent from "../common/ExpandableContent/ExpandableContent";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {call, put} from "redux-saga/effects";
import {contextCall} from "../../saga/sagaFunctions";
import {NoticeCommentSaga} from "../../models/notice/NoticeCommentModel";
import {userIconModel} from "../../models/generics/IconModel";
import Editor from "rich-markdown-editor";

const selectEditDetails = makeGetEditDetails();
function selectUserNoticeResponseEditDetails (state) {
    const {activeModel, values, isSaveEnabled} = selectEditDetails(state, {model: UserNoticeModel.nom});
    const isResponseEditActive = (activeModel === UserNoticeModel.nom);

    return {
        ...values,
        isResponseEditActive,
        isSaveEnabled
    }
}

export function selectNoticeComments(state, props) {
    return getValues(state.noticeCommentDetailsMap)
        .filter(comment => comment.noticeEventId === props.userNoticeId)
}

function UserNoticeDisplay(props) {
    const dispatch = useDispatch();

    const {
        legalHoldName,
        userNotice,
        showSubject,

        isDisabled
    } = props;

    const {
        id,
        noticeId,
        userId,
        enabled,
        lastRespondedDate,

        userPermissions
    } = userNotice;

    const isLoading = useSelector(state => !state.hasLoaded[id]);
    const comments = useSelector(state => selectNoticeComments(state, {userNoticeId: id}));

    const {isUploadActive} = useSelector(state => state.componentStates.userNoticeView);
    const recipient = useSelector(state => state.userDetailsMap.get(userId)) || {};
    const recipientIcon = userIconModel.useIcon(recipient.name);

    const canReply = userPermissions.includes(permissionKeys.RESPOND);
    const canManage = userPermissions.includes(permissionKeys.MANAGE);
    const canComment = canReply || canManage;

    const {
        surveyFormOptions=userNotice.surveyFormOptions,
        isResponseEditActive,
        isSaveEnabled
    } = useSelector(state => selectUserNoticeResponseEditDetails(state));

    // Start and Stop polling
    useEffect(() => {
        dispatch(UserNoticeModel.actionCreators.startPollingSettings(noticeId, id));

        return () => dispatch(UserNoticeModel.actionCreators.stopPollingSettings());
    }, [noticeId, id, dispatch]);

    // Start edit
    useEffect(() => {
        if (enabled && canReply && lastRespondedDate == null && !isResponseEditActive) {
            dispatch(UserNoticeModel.actionCreators.startEdit(id));
        }
    }, [id, enabled, lastRespondedDate, isResponseEditActive]);


    const setNoticeViewed = useDebounceCallback(
        useCallback(() => {
            if (canComment) {
                dispatch(UserNoticeModel.actionCreators.setViewed(noticeId, id));
            }
        }, [canComment, noticeId, id, dispatch])
    , 30000);

    // Set user viewed + onClick
    useEffect(() => {
        setNoticeViewed();
        activityEvents.forEach(e => document.body.addEventListener(e, setNoticeViewed));

        return () => activityEvents.forEach(e => document.body.removeEventListener(e, setNoticeViewed));
    }, [setNoticeViewed]);


    const {
        updateEdit,
        setEditSaveEnabled
    } = EditModel.buildDispatchers(dispatch);

    const editHandler = createEditHandler({
        updateEdit,
        setEditSaveEnabled
    });

    const surveyFormOptionsHandler = useCallback(updates => {
        editHandler(editValues => ({
            surveyFormOptions: updates(editValues.surveyFormOptions)
        }));
    }, []);

    const saveEdit = useCallback(event => {
        const {submit} = event.currentTarget.dataset;

        const saveEffect = call(function* () {
            const saveEditEffect = put(UserNoticeModel.actionCreators.saveEdit(submit));
            yield contextCall(NoticeCommentSaga, 'callCommentFormDiscardingEffect', saveEditEffect);
        });
        dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(saveEffect));
    }, []);

    function onCreateDataset(key) {
        return UserNoticeApi.postCommand(noticeId, id, {
            command: userNoticeCommands.CREATE_DATASET,
            surveyFormKey: key
        })
            .then(res => {
                const resDatasetOption = res.data.surveyFormOptions.find(opt => opt.key === key);
                const datasetOptionIndex = surveyFormOptions.findIndex(opt => opt.key === key);

                const newDatasetOption = {
                    ...surveyFormOptions[datasetOptionIndex],
                    value: resDatasetOption.value
                };

                const newSurveyFormOptions = getNewArrayWithUpdatedValue(surveyFormOptions, newDatasetOption, datasetOptionIndex);
                editHandler({
                    surveyFormOptions: newSurveyFormOptions
                });
            })
            .catch(error => {
                dispatch(SchedulerModel.actionCreators.handleResponseError(error));
            });
    }


    // Opt either not enabled, is a header, not required, or is defined
    const isSubmitEnabled = userNotice.isSubmitEnabled(surveyFormOptions);

    return (
        <UserNoticeBody legalHoldName={legalHoldName} userNotice={userNotice} comments={comments} recipientName={recipient.displayName || recipient.name} recipientIcon={recipientIcon}
            showNext surveyFormOptions={surveyFormOptions} surveyFormOptionsHandler={surveyFormOptionsHandler} onCreateDataset={onCreateDataset} showSubject={showSubject}
            onUploadActive={isUploadActive => dispatch(UserNoticeModel.componentActionCreators.updateView({isUploadActive}))} isUploadActive={isUploadActive} saveEdit={saveEdit}
            isResponseEditActive={isResponseEditActive} isSubmitEnabled={isSubmitEnabled} isSaveEnabled={isSaveEnabled} isLoading={isLoading} isDisabled={isDisabled}/>
    )
}

export function UserNoticeBody(props) {
    const {t} = useTranslation(['notice', 'noticeTemplate', 'common']);

    const {
        legalHoldName,
        userNotice,
        comments,

        recipientName,
        recipientIcon,

        surveyFormOptions,
        surveyFormOptionsHandler,
        onCreateDataset,
        onUploadActive,
        saveEdit,

        showSubject,
        showNext,
        isResponseEditActive,
        isSaveEnabled,
        isSubmitEnabled,

        isLoading,
        isTemplate,
        isUploadActive,
        isDisabled
   } = props;

    const {
        id,
        noticeId,
        subject,
        message,

        sentDate,
        lastRespondedDate,
        respondByDate,
        lastRemindedDate,
        escalatedDate,

        enabled,
        noticeEnabled,

        disableComments,
        disableAdminNotes,
        noticeDisableComments,

        userPermissions
    } = userNotice;

    const canReply = userPermissions.includes(permissionKeys.RESPOND);
    const canManage = userPermissions.includes(permissionKeys.MANAGE);

    const isEnabled = enabled && noticeEnabled;
    const isReadOnly = props.isReadOnly || !isEnabled;

    const isCommentsDisabled = disableComments || noticeDisableComments;
    const canComment = userNotice.hasSurveyForm() && !isReadOnly && !isCommentsDisabled && (canReply || canManage);
    const canAddAdminNotes = !props.isReadOnly && !disableAdminNotes && canManage;

    const disabled = (isDisabled ? ' is-disabled' : '');

    return (
        <>
            <table className="user-notice__table display-item">
                <tbody>
                <tr>
                    <td>
                        <Text value={`${t('notice:label.sent')}:`} isDisabled={isDisabled}/>
                    </td>
                    <td>
                        <Text value={getLocaleDateTimeFromUTC(sentDate)} id="userNoticeSentDate"
                            isDisabled={isDisabled}/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <Text value={`${t('common:label.to')}:`} isDisabled={isDisabled}/>
                    </td>
                    <td>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {recipientIcon &&
                            <span className={'icon is-medium' + disabled} style={{marginRight: '0.5rem'}} id="userNoticeRecipientIconContainer">
                                <img className="round-image" src={recipientIcon} alt={t('aria:hiddenAssistText.recipientIcon')}
                                    id="userNoticeRecipientIcon"/>
                            </span>
                            }
                            <label className={'label' + disabled} id="userNoticeRecipientName">
                                {recipientName || '-'}
                            </label>
                        </div>
                    </td>
                </tr>
                {showSubject &&
                <tr>
                    <td>
                        <Text value={`${t('notice:label.subject')}:`} isDisabled={isDisabled}/>
                    </td>
                    <td>
                        <Text value={subject} id="userNoticeSubject" isDisabled={isDisabled}/>
                    </td>
                </tr>
                }

                {(legalHoldName != null || respondByDate != null || lastRespondedDate != null || escalatedDate != null) &&
                <tr id="userNoticeSettingsTableSpacer"><td style={{height: '1rem'}}/></tr>
                }

                {legalHoldName &&
                <tr>
                    <td>
                        <Text value={`${t('notice:label.legalHold')}:`} isDisabled={isDisabled}/>
                    </td>
                    <td>
                        <Text value={legalHoldName} id="userNoticeLegalHoldName" isDisabled={isDisabled}/>
                    </td>
                </tr>
                }
                {respondByDate != null &&
                <tr id="userNoticeRespondByDateContainer">
                    <td>
                        <Text value={`${t('notice:label.respondByDate')}:`} isDisabled={isDisabled}/>
                    </td>

                    <td>
                        <Text value={getLocaleDateTimeFromUTC(respondByDate)} id="userNoticeRespondByDate"
                            isDisabled={isDisabled}/>
                    </td>
                </tr>
                }
                {lastRespondedDate != null &&
                <tr id="userNoticeRespondedDateContainer">
                    <td>
                        <Text value={`${t('notice:label.responded')}:`} isDisabled={isDisabled}/>
                    </td>

                    <td>
                        <Text value={getLocaleDateTimeFromUTC(lastRespondedDate)} id="userNoticeRespondedDate"
                            isDisabled={isDisabled}/>
                    </td>
                </tr>
                }
                {lastRemindedDate != null &&
                <tr id="userNoticeLastRemindedDateContainer">
                    <td>
                        <Text value={`${t('notice:label.lastReminded')}:`} isDisabled={isDisabled}/>
                    </td>

                    <td>
                        <Text value={getLocaleDateTimeFromUTC(lastRemindedDate)} id="userNoticeLastRemindedDate"
                            isDisabled={isDisabled}/>
                    </td>
                </tr>
                }
                {escalatedDate != null &&
                <tr id="userNoticeEscalatedDateContainer">
                    <td>
                        <Text value={`${t('notice:label.escalated')}:`} isDisabled={isDisabled}/>
                    </td>

                    <td>
                        <Text value={getLocaleDateTimeFromUTC(escalatedDate)} id="userNoticeEscalatedDate"
                            isDisabled={isDisabled}/>
                    </td>
                </tr>
                }
                </tbody>
            </table>

            {typeof message === 'string' &&
                <div className="rich-markdown-editor is-readonly">
                    <Editor id="userNoticeMessage" className={'user-notice__message' + disabled} value={message} readOnly
                        style={{marginBottom: '1rem'}}/>
                </div>
            }
            {/*{typeof message === 'string' &&*/}
            {/*    <MarkdownEditor id="userNoticeMessage" markdown={message}*/}
            {/*        isReadOnly isDisabled={isDisabled} style={{marginBottom: '1rem'}}/>*/}
            {/*}*/}

            {isNotEmptyNorFalsy(surveyFormOptions) &&
            <div className="display-item">
                {isReadOnly ?
                    <FormBuilder formOptions={surveyFormOptions} isReadOnly isDisabled={isDisabled}/>
                    :
                    <>
                        <FormBuilder formOptions={surveyFormOptions} setFormOptions={surveyFormOptionsHandler}
                            setUploadActive={onUploadActive} onCreateDataset={onCreateDataset} isReadOnly={!isResponseEditActive} isDisabled={isDisabled}/>

                        {isResponseEditActive &&
                        <ButtonGroup
                            buttons={[
                                {
                                    label: t('common:option.save'),
                                    onClick: saveEdit,
                                    isDisabled: !isSaveEnabled || isDisabled || isUploadActive
                                },
                                {
                                    label: t('common:option.submit'),
                                    onClick: saveEdit,
                                    isDisabled: !isSubmitEnabled || isDisabled || isUploadActive,
                                    "data-submit": "true"
                                },
                                showNext && canReply && {
                                    label: t('notice:option.submitAndNext'),
                                    onClick: saveEdit,
                                    isDisabled: !isSubmitEnabled || isDisabled || isUploadActive,
                                    "data-submit": "next"
                                }
                            ]}
                        />
                        }
                    </>
                }
            </div>
            }

            {(isNotEmptyNorFalsy(comments) || isTemplate || canComment || canAddAdminNotes) &&
            <div className="display-item" id="userNoticeCommentDisplayContainer">

                <ExpandableContent label={t('notice:label.comment_plural')}
                    isDisabled={isDisabled}
                >
                    <LoadingWrapper isLoading={isLoading}>
                        <NoticeCommentDisplay noticeId={noticeId} userNoticeId={id} comments={comments} canComment={canComment} canAddAdminNotes={canAddAdminNotes}
                            showNext={showNext && canManage} isTemplate={isTemplate} isReadOnly={props.isReadOnly} isDisabled={isDisabled}/>
                    </LoadingWrapper>
                </ExpandableContent>
            </div>
            }
        </>
    )
}

export default UserNoticeDisplay;
