import React from "react";
import FieldComponent from "./FieldComponent";
import FormBuilderComponentConfiguration from "../../../models/workflowbuilder/FormBuilderComponentConfiguration";
import Text from "../../common/Text/Text";
import {buildClassName} from "../../../utilities/helperFunctions";
import FieldConfigurationWrapper from "./FieldConfigurationWrapper";
import FieldRadioButtonGroup from "./FieldRadioButtonGroup";
import FieldRow, {FieldComponentRow} from "./FieldRow";
import {useTranslation} from "react-i18next";
import FormBuilderConfiguration from "../../../models/workflowbuilder/FormBuilderConfiguration";

function FieldGroup(props) {
    const {t} = useTranslation(['workflowBuilder']);
    const {
        parentId,
        formKey,
        configuration,
        fieldConfigurations,

        label,
        state,
        updateState,

        inputHandler,
        toggleHandler,
        dropdownHandler,

        root,
        autoFocus,
        isRequired,
        isDisabled
    } = props;

    const className = buildClassName(
        'workflow-builder__field-group',
        'forms-table',
        root && 'is-root',
        !configuration.styled && 'no-style'
    );

    const childConfigurations = FormBuilderConfiguration.collectIntoTableGroups({state, configuration, fieldConfigurations}, root);
    const groupLeftLabel = !root && FormBuilderConfiguration.getLabelTranslation(t, formKey, configuration.positionedLabel.left);
    const _isDisabled = isDisabled || (!root && !configuration.isEnabled(state, fieldConfigurations));

    return (
        <>
            {configuration.styled && label &&
                <div className="workflow-builder__field-group-label">
                    <Text value={label} isDisabled={_isDisabled}/>
                </div>
            }

            <div className={className}>
                <div className="table-row">
                    {groupLeftLabel &&
                        <div className="table-cell no-stretch align-top">
                            <Text value={`${groupLeftLabel}:`} isDisabled={_isDisabled}/>
                        </div>
                    }
                    <div className="table-cell">
                        {childConfigurations.map((config, index) => {
                            let Component;
                            switch (config.componentType) {
                                case FormBuilderComponentConfiguration.ComponentType.GROUP:
                                    Component = FieldGroup;
                                    break;
                                case FormBuilderComponentConfiguration.ComponentType.TABLE_GROUP:
                                    Component = FieldTableGroup;
                                    break;
                                case FormBuilderComponentConfiguration.ComponentType.RADIO_BUTTON_GROUP:
                                case FormBuilderComponentConfiguration.ComponentType.RADIO_BUTTON_CHECKBOX_GROUP:
                                    Component = FieldRadioButtonGroup;
                                    break;
                                default:
                                    Component = FieldComponent;
                            }

                            return (
                                <div key={index} className="workflow-builder__field-component">
                                    <FieldConfigurationWrapper parentId={parentId} Component={Component} formKey={formKey} fieldConfigurations={fieldConfigurations}
                                        configuration={config} state={state} updateState={updateState} inputHandler={inputHandler} autoFocus={autoFocus && index === 0}
                                        toggleHandler={toggleHandler} dropdownHandler={dropdownHandler} isRequired={isRequired} isDisabled={_isDisabled}/>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

function FieldTableGroup(props) {
    const {
        configuration,
        autoFocus
    } = props;

    return (
        <table className="forms-table">
            <tbody className="table-row-group">
            {configuration.componentConfigurations.map((config, index) => {
                let Component;
                if (config.componentType === FormBuilderComponentConfiguration.ComponentType.ROW) {
                    Component = FieldRow;
                } else {
                    Component = FieldComponentRow;
                }

                return (
                    <FieldConfigurationWrapper key={index} autoFocus={autoFocus && index === 0} {...props}
                        configuration={config} Component={Component}/>
                )
            })}
            </tbody>
        </table>
    )
}

export default FieldGroup;
