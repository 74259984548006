import React, {useState} from "react";
import {FormElementRow} from "../common/CustomTable/CustomTable";
import TextArea from "../common/TextArea/TextArea";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getValues} from "../../utilities/helperFunctions";
import {DataRepositoryDropdown, ListDropdown} from "../common/Dropdown/Dropdown";
import {createCloseHandler, createInputHandler, createStateHandler} from "../../utilities/componentFunctions";
import {createDropdownHandler} from "../common/Dropdown/helpers";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import {Button} from "../common/Button/Button";
import DatasetModel from "../../models/data/DatasetModel";
import Form from "../common/Form/Form";
import PopupModel from "../../models/scheduler/PopupModel";
import {datasetType} from "../../utilities/constants";
import {statusKeys} from "../../i18next/keys";
import InPlaceFileExplorer from "../dataRepository/InPlaceFileExplorer";


function selectMostFreeSpaceDataRepositoryId(dataRepositoryMap, type) {
    const repositories = getValues(dataRepositoryMap)
        .filter(repo => repo.status && repo.status.code !== statusKeys.ERROR)
        .filter(repo => repo.type === type);

    if (repositories.length === 0) {
        return null;
    }

    switch (type) {
        case datasetType.MANAGED:
            const mostFreeSpaceRepository = repositories
                .reduce((prev, curr) => {
                    if (curr.usableSpace == null) {
                        return prev;
                    }
                    if (prev.getAvailableSpace() >= curr.getAvailableSpace()) {
                        return prev;
                    }
                    return curr;
                });

            return mostFreeSpaceRepository && mostFreeSpaceRepository.id;
        case datasetType.IN_PLACE:
            return repositories[0].id;
    }
}

// Start with repository with most free space available
function buildInitialState(dataRepositoryMap) {
    let type = datasetType.MANAGED, dataRepositoryId = selectMostFreeSpaceDataRepositoryId(dataRepositoryMap, type);
    if (dataRepositoryId == null) {
        type = datasetType.IN_PLACE;
        dataRepositoryId = selectMostFreeSpaceDataRepositoryId(dataRepositoryMap, type);
    }
    return {
        type,
        dataRepositoryId,
        datasetName: '',
        description: '',
        path: '',
        isAddEnabled: false
    }
}

function datasetShouldEnable(formData) {
    let typeValid = false;
    if (formData.type === datasetType.MANAGED) {
        typeValid = true;
    } else if (formData.type === datasetType.IN_PLACE) {
        typeValid = formData.path;
    }
    return !!(formData.datasetName && formData.dataRepositoryId && typeValid);
}

function DatasetForm(props) {
    const {t} = useTranslation(['common', 'dataset']);
    const dispatch = useDispatch();

    const {
        matterId
    } = props;

    const {
        isDisabled
    } = useSelector(state => state.componentStates.datasetForm)

    const dataRepositoryMap = useSelector(state => state.dataRepositoryDetailsMap);
    const [state, setState] = useState(() => buildInitialState(dataRepositoryMap));
    const dataRepository = dataRepositoryMap.get(state.dataRepositoryId);

    const stateHandler = createStateHandler({
        updateState: setState,
        shouldEnable: datasetShouldEnable
    });

    const inputHandler = createInputHandler({
        handler: stateHandler
    });

    const dropdownHandler = createDropdownHandler({
        handler: (updates, ...others) => {
            // Update dataRepo if changing datasetType
            if (updates.type != null) {
                const mostFreeSpaceId = selectMostFreeSpaceDataRepositoryId(dataRepositoryMap, updates.type);
                if (mostFreeSpaceId !== dataRepositoryId) {
                    updates.dataRepositoryId = mostFreeSpaceId;
                }
            }
            stateHandler(updates, ...others);
        }
    });

    function setPath(file) {
        let path = file?.path || '';
        if (path === dataRepository.path) {
            path = '';
        }
        const fakeEvent = {target: {name: 'path', value: path}};
        inputHandler(fakeEvent);
    }

    const {
        type,
        datasetName,
        description,
        dataRepositoryId,
        path,
        isAddEnabled
    } = state;

    const onClose = createCloseHandler({
        t,
        item: t('dataset:label.name'),
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        values: {datasetName, description, path},
        close: () => dispatch(DatasetModel.componentActionCreators.updateView({isDatasetFormActive: false}))
    });

    function onSubmit() {
        dispatch(DatasetModel.actionCreators.submitForm({
            matterId,
            type,
            name: datasetName,
            description,
            dataRepositoryId,
            path
        }));
    }

    const isInPlaceType = dataRepository && type === datasetType.IN_PLACE;
    const inPlaceStyle = {display: 'flex', flexDirection: 'column', width: '55rem'};

    return (
        <Form onClose={onClose} isDisabled={isDisabled} style={isInPlaceType ? inPlaceStyle : {}} closeButtonAriaLabel={t('dataset:option.closeForm')}
            header={
                <h2 className={'subtitle is-5 is-bold' + (isDisabled ? ' is-disabled' : '')}>
                    {t('dataset:label.name')}
                </h2>
            }
            body={
                <>
                    <div className="display-input">
                        <HTMLTextInput label={t('common:label.name')} name={'datasetName'} value={datasetName} onChange={inputHandler}
                            isDisabled={isDisabled} isRequired/>
                    </div>

                    <div className="display-input">
                        <TextArea label={t('common:label.description')} name={'description'}
                            value={description} onChange={inputHandler} isDisabled={isDisabled}
                        />
                    </div>

                    <div className="forms-table display-input">
                        <div className="table-row-group">
                            <FormElementRow label={t('dataset:label.type')} isDisabled={isDisabled}
                                element={
                                    <DatasetTypeDropdown value={type} onItemSelect={dropdownHandler}
                                        isRequired isDisabled={isDisabled}/>
                                }/>

                            <FormElementRow label={t('dataset:label.repository')} isDisabled={isDisabled}
                                element={
                                    <DataRepositoryDropdown type={type} selectedDataRepositoryId={dataRepositoryId}
                                        onDataRepositorySelect={dropdownHandler} isRequired isDisabled={isDisabled}/>
                                }/>
                        </div>
                    </div>

                    {isInPlaceType &&
                        <div className="display-input">
                            <InPlaceFileExplorer dataRepositoryId={dataRepositoryId} path={path} setPath={setPath}
                                showPathLabel isRequired isDisabled={isDisabled}/>
                        </div>
                    }
                </>
            }
            footer={
                <div className="button-group">
                    <Button id="formFooterBackButton" text={t('common:option.cancel')}
                        onClick={onClose} isDisabled={isDisabled}
                    />

                    <Button id="formFooterNextButton" text={t('common:button.addDataset')}
                        onClick={onSubmit} isDisabled={isDisabled || !isAddEnabled}
                    />
                </div>
            }
        />
    );
}

export function DatasetTypeDropdown(props) {
    const {t} = useTranslation(['aria', 'dataset']);

    const datasetTypeItems = [datasetType.MANAGED, datasetType.IN_PLACE]
        .map(type => ({
            name: t(`dataset:type.${type}`),
            value: type
        }));

    return (
        <ListDropdown id={'datasetTypeDropdown'} name={'type'} aria-label={t('aria:hiddenAssistText.datasetType')}
            items={datasetTypeItems} noneSelectedMessage={t('dataset:option.selectType')} {...props}
        />
    );
}

export default DatasetForm;
