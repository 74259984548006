import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import SearchBar from "../common/SearchBar/SearchBar";
import {createInputHandler} from "../../utilities/componentFunctions";
import {details, updateMode} from "../../utilities/constants";
import JobArchiveModel, {JobArchiveApi} from "../../models/job/JobArchiveModel";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import {Button} from "../common/Button/Button";
import {CheckedDropdown, ClientCheckedDropdown, MatterCheckedDropdown} from "../common/Dropdown/Dropdown";
import {UserSelectableItem} from "../common/SelectableItem/SelectableItem";
import SchedulerModel from "../../models/scheduler/SchedulerModel";
import {useFilterDropdownClearHandler, useFilterDropdownHandler} from "../common/Dropdown/helpers";
import {Label} from "../common/Text/Text";


function JobArchiveHeader() {
    const dispatch = useDispatch();
    const {t} = useTranslation(['job', 'common']);

    const isLoading = useSelector(state => !state.hasLoaded[details.ARCHIVE_JOBS]);
    const {
        searchText,
        fromDate,
        toDate,
        viewCount,
        submitters,
        clientIds,
        matterIds
    } = useSelector(state => state.componentStates.jobArchiveDisplay);

    // Toggle active
    useLayoutEffect(() => {
        dispatch(JobArchiveModel.componentActionCreators.setDisplayActive());

        return () => dispatch(JobArchiveModel.componentActionCreators.setDisplayInactive())
    }, [dispatch]);


    const updateState = useCallback(updates =>
        dispatch(JobArchiveModel.componentActionCreators.updateDisplay(updates))
    , [dispatch]);

    const inputHandler = createInputHandler({
        mode: updateMode.REDUX,
        updateState
    });

    const onToggle = useFilterDropdownHandler({
        updateState
    });

    const onClear = useFilterDropdownClearHandler({
        updateState
    });


    return (
        <>
            <div className="header-section left">
                <div className="header-item">
                    <Label htmlFor="fromDate" style={{marginRight: '0.5rem'}}
                        value={`${t('common:label.from')}:`}/>

                    <HTMLTextInput id="fromDate" name={'fromDate'} value={fromDate} onChange={inputHandler}
                        type={'date'} isDisabled={isLoading}
                    />
                </div>

                <div className="header-item">
                    <Label htmlFor="toDate" style={{marginRight: '0.5rem'}}
                        value={`${t('common:label.to')}:`}/>

                    <HTMLTextInput id="toDate" name={'toDate'} value={toDate} onChange={inputHandler}
                        type={'date'} isDisabled={isLoading}
                    />
                </div>

                <div className="header-item">
                    <Label htmlFor="viewCount" style={{marginRight: '0.5rem'}}
                        value={`${t('common:label.viewCount')}:`}/>

                    <HTMLTextInput id="viewCount" name={'viewCount'} value={viewCount} onChange={inputHandler}
                        type={'number'} max={1000} containerStyle={{width: '5rem'}} isDisabled={isLoading}
                    />
                </div>

                <div className="header-item">
                    <SubmittersCheckedDropdown submitters={submitters} isDisabled={isLoading}
                        onToggle={onToggle} onClear={onClear}
                    />
                </div>

                <div className="header-item">
                    <ClientCheckedDropdown clientIds={clientIds} isDisabled={isLoading}
                        onToggle={onToggle} onClear={onClear}
                    />
                </div>

                <div className="header-item">
                    <MatterCheckedDropdown matterIds={matterIds} clientIds={clientIds} isDisabled={isLoading}
                        updateState={updateState} onToggle={onToggle} onClear={onClear}
                    />
                </div>

                <div className="header-item">
                    <Button text={t('common:label.refresh')} isDisabled={isLoading}
                        onClick={() => dispatch(JobArchiveModel.actionCreators.loadArchives())}
                    />
                </div>
            </div>

            <div className="header-section right">
                <div className="header-item">
                    <SearchBar value={searchText} onChange={inputHandler} isDisabled={isLoading}/>
                </div>
            </div>
        </>
    );
}


function SubmittersCheckedDropdown(props) {
    const {t} = useTranslation(['job']);
    const dispatch = useDispatch();

    const {
        name='submitters',
        submitters,
        ...rest
    } = props;

    const [archivedUsers, setArchivedUsers] = useState([]);
    // Query for archivedUsers (users whose job is archived)
    useEffect(() => {
        JobArchiveApi.getArchivedUsers()
            .then(res => {
                setArchivedUsers(res.data);
            })
            .catch(error => {
                dispatch(SchedulerModel.actionCreators.handleResponseError(error));
            })
    }, []);

    const submitterItems = archivedUsers.map(user => ({
        name: user,
        value: user,
        isChecked: !!submitters[user]
    }));

    return (
        <CheckedDropdown name={name} aria-label={t('aria:hiddenAssistText.jobSubmitters')}
            noneSelectedMessage={t('job:option.allUsers')} items={submitterItems}
            ItemComponent={UserSelectableItem} {...rest}
        />
    )
}

export default JobArchiveHeader;
