import ThirdPartyServiceModel from "../../../models/thirdparty/ThirdPartyServiceModel";
import {useTranslation} from "react-i18next";
import React, {useCallback} from "react";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import Checkbox from "../../common/Checkbox/Checkbox";
import ThirdPartyServiceForm, {ThirdPartyServiceFormBody} from "../ThirdPartyServiceForm";
import UserServiceModel from "../../../models/user/UserServiceModel";
import {FormElementRow} from "../../common/CustomTable/CustomTable";
import Text from "../../common/Text/Text";
import {getValues} from "../../../utilities/helperFunctions";
import {ListDropdown} from "../../common/Dropdown/Dropdown";
import ExpandableContent from "../../common/ExpandableContent/ExpandableContent";

const initialState = {
    type: ThirdPartyServiceModel.Type.RELATIVITY,
    serviceName: '',
    enabled: true,
    description: '',
    whitelistedCertFingerprints: [],

    hostname: '',
    serviceEndpoint: '/relativitywebapi',
    endpointType: ThirdPartyServiceModel.EndpointType.HTTPS,

    importThreads: 1,
    importThreadTimeout: 3600,
    importThreadRetries: 3,
    metadataThreads: 8,

    patchInvalidEntries: true,
    relativityRestVersion: ThirdPartyServiceModel.RelativityRestVersion.REST_V1,
    useCustomClientVersion: false,
    customClientVersion: '',

    authenticationScope: ThirdPartyServiceModel.AuthenticationScope.SERVICE,
    authenticationMethod: ThirdPartyServiceModel.AuthenticationMethod.OIDC_AUTHORIZATION_CODE,
    authenticationServiceId: null
};
const allowedAuthenticationMethods = [
    ThirdPartyServiceModel.AuthenticationMethod.OIDC_AUTHORIZATION_CODE,
    ThirdPartyServiceModel.AuthenticationMethod.USERNAME_PASSWORD
];

function RelativityServiceForm() {
    return (
        <ThirdPartyServiceForm initialState={initialState} FormBody={RelativityServiceFormBody}/>
    )
}

export function RelativityServiceFormBody(props) {
    const filterFunc = useCallback(userService => {
        return UserServiceModel.isRelativityOidc(userService.type, userService.wellKnownConfiguration);
    }, []);

    return (
        <ThirdPartyServiceFormBody {...props} authenticationServiceType={UserServiceModel.types.OIDC}
            allowedAuthenticationMethods={allowedAuthenticationMethods} filterFunc={filterFunc} FormBody={FormBody}/>
    )
}

function FormBody(props) {
    const {t} = useTranslation(['thirdPartyService', 'common']);
    const {
        thirdPartyService,

        inputHandler,
        toggleHandler,
        dropdownHandler,
        isDisabled
    } = props;

    return (
        <ExpandableContent label={t('thirdPartyService:label.relativitySettings')} isDisabled={isDisabled}>
            <div className="display-input">
                <HTMLTextInput label={t('thirdPartyService:label.hostname')} name={'hostname'}
                    value={thirdPartyService.hostname} onChange={inputHandler} isDisabled={isDisabled} isRequired/>
            </div>

            <div className="display-input">
                <HTMLTextInput label={t('thirdPartyService:label.serviceEndpoint')} name={'serviceEndpoint'}
                    value={thirdPartyService.serviceEndpoint} onChange={inputHandler} isDisabled={isDisabled} isRequired/>
            </div>

            <div className="forms-table display-input">
                <div className="table-row-group">
                    <FormElementRow label={t('thirdPartyService:label.endpointType')} isDisabled={isDisabled}
                        element={
                            <EndpointTypeDropdown value={thirdPartyService.endpointType}
                                onItemSelect={dropdownHandler} isDisabled={isDisabled} isRequired/>
                        }/>

                    <FormElementRow label={t('thirdPartyService:label.relativityRestVersion')} isDisabled={isDisabled}
                        element={
                            <RelativityRestVersionDropdown value={thirdPartyService.relativityRestVersion}
                                onItemSelect={dropdownHandler} isDisabled={isDisabled}/>
                        }/>

                    <FormElementRow htmlFor={'importThreads'} label={t('thirdPartyService:label.importThreads')} isDisabled={isDisabled}
                        element={
                            <HTMLTextInput id={'importThreads'} name={'importThreads'} value={thirdPartyService.importThreads}
                                onChange={inputHandler} type="number" min={1} style={{width: '5rem'}} isDisabled={isDisabled}
                                isInvalid={!thirdPartyService.importThreads || thirdPartyService.importThreads < 1}
                            />
                        }/>

                    <FormElementRow htmlFor={'importThreadTimeout'} label={t('thirdPartyService:label.importThreadTimeout')} isDisabled={isDisabled}
                        element={
                            <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                <HTMLTextInput id={'importThreadTimeout'} name={'importThreadTimeout'} value={thirdPartyService.importThreadTimeout}
                                    onChange={inputHandler} type="number" min={1} style={{width: '5rem', marginRight: '0.5rem'}} isDisabled={isDisabled}
                                    isInvalid={!thirdPartyService.importThreadTimeout || thirdPartyService.importThreadTimeout < 1}
                                />
                                <Text value={t('common:duration.seconds_plural')} isDisabled={isDisabled}/>
                            </div>
                        }/>

                    <FormElementRow htmlFor={'importThreadRetries'} label={t('thirdPartyService:label.importThreadRetries')} isDisabled={isDisabled}
                        element={
                            <HTMLTextInput id={'importThreadRetries'} name={'importThreadRetries'} value={thirdPartyService.importThreadRetries}
                                onChange={inputHandler} type="number" min={0} style={{width: '5rem'}} isDisabled={isDisabled}
                                isInvalid={!thirdPartyService.importThreadRetries || thirdPartyService.importThreadRetries < 0}
                            />
                        }/>

                    <FormElementRow htmlFor={'metadataThreads'} label={t('thirdPartyService:label.metadataThreads')} isDisabled={isDisabled}
                        element={
                            <HTMLTextInput id={'metadataThreads'} name={'metadataThreads'} value={thirdPartyService.metadataThreads}
                                onChange={inputHandler} type="number" min={1} style={{width: '5rem'}} isDisabled={isDisabled}
                                isInvalid={!thirdPartyService.metadataThreads || thirdPartyService.metadataThreads < 1}
                            />
                        }/>
                </div>
            </div>

            <div className="display-input">
                <Checkbox label={t('thirdPartyService:label.patchInvalidEntries')} name={"patchInvalidEntries"}
                    checked={thirdPartyService.patchInvalidEntries} onClick={toggleHandler} isDisabled={isDisabled}/>
            </div>

            <div className="display-input">
                <Checkbox labelId={'customClientVersionLabel'} label={t('thirdPartyService:label.customClientVersion')} name={"useCustomClientVersion"}
                    checked={thirdPartyService.useCustomClientVersion} onClick={toggleHandler} isDisabled={isDisabled}/>
            </div>

            <div className="display-input">
                <HTMLTextInput name={'customClientVersion'} aria-labelledby={'customClientVersionLabel'}
                    value={thirdPartyService.customClientVersion} onChange={inputHandler}
                    isDisabled={!thirdPartyService.useCustomClientVersion || isDisabled} isRequired/>
            </div>
        </ExpandableContent>
    );
}

export function EndpointTypeDropdown(props) {
    const {t} = useTranslation(['thirdPartyService', 'aria']);

    const endpointTypeItems = getValues(ThirdPartyServiceModel.EndpointType)
        .map(type => ({name: t(`thirdPartyService:endpointType.${type}`), value: type}));

    return (
        <ListDropdown id={'endpointTypeDropdown'} name={'endpointType'}
            aria-label={t('aria:hiddenAssistText.endpointType')} items={endpointTypeItems} {...props}/>
    )
}

function RelativityRestVersionDropdown(props) {
    const {t} = useTranslation(['thirdPartyService', 'aria']);

    const relativityRestVersionItems = getValues(ThirdPartyServiceModel.RelativityRestVersion)
        .map(version => ({name: t(`thirdPartyService:relativityRestVersion.${version}`), value: version}));

    return (
        <ListDropdown id={'relativityRestVersionDropdown'} name={'relativityRestVersion'}
            aria-label={t('aria:hiddenAssistText.relativityRestVersion')} items={relativityRestVersionItems} {...props}/>
    )
}

export default RelativityServiceForm;
