import React, {useCallback, useLayoutEffect, useState} from "react";
import "./TabPane.css";
import {Button} from "../Button/Button";
import {buildClassName, checkNestedExists} from "../../../utilities/helperFunctions";


function TabPane(props) {

    const {
        labels,
        children,
        force,
        ...attr
    } = props;


    const [tabIndex, setTabIndex] = useState(0);
    const selectTab = useCallback(event => {
        setTabIndex(event.currentTarget.value);
    }, []);

    useLayoutEffect(() => {
        if (children[tabIndex] == null && children.length > 0) {
            setTabIndex(0);
        }
    }, [children]);

    useLayoutEffect(() => {
        if (checkNestedExists(force, 'tabIndex')) {
            setTabIndex(force.tabIndex);
        }
    }, [force]);



    return (
        <section className="tab-pane" {...attr}>
            <section className="tab-pane__tab-list">

                {labels.map((label, index) => {
                    const tabClassName = buildClassName(
                        'tab-pane__tab',
                        (index == tabIndex) && 'is-selected'
                    );

                    return (
                        <Button key={index} className={tabClassName} isBold={index == tabIndex}
                            label={label} value={index} onClick={selectTab}
                        />
                    )
                })}
            </section>

            <section className="tab-pane__content">
                {children[tabIndex]}
            </section>
        </section>
    )
}

export default TabPane;
