import React, {useCallback, useEffect, useState} from "react";
import "./GuidedJob.css";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import NavigatePane from "../common/NavigatePane/NavigatePane";
import GuidedJobModel, {GuidedJobSaga} from "../../models/guidedjob/GuidedJobModel";
import PopupModel from "../../models/scheduler/PopupModel";
import SelectMatterPane from "../job/panes/SelectMatterPane";
import JobSettingsPane from "../job/panes/JobSettingsPane";
import {contextCall} from "../../saga/sagaFunctions";
import SchedulerModel from "../../models/scheduler/SchedulerModel";
import ParameterWorkflowPane from "./panes/ParameterWorkflowPane";
import JobConfirmPane from "../job/panes/JobConfirmPane";
import {permissionKeys} from "../../i18next/keys";
import {jobQueueStates} from "../../utilities/constants";
import JobModel from "../../models/job/JobModel";
import ParameterModel from "../../models/library/ParameterModel";
import {operationFieldParameterNames} from "./operationFieldParameterNames";
import {stringToBool} from "../../utilities/helperFunctions";


function GuidedJobForm(props) {
    const {t} = useTranslation(['guidedJob', 'common']);
    const dispatch = useDispatch();

    const {
        requiredParameterTypes,
        forbiddenParameterTypes,
        getGuides,
        closeButtonAriaLabel
    } = props;

    const guidedJobForm = useSelector(state => state.guidedJobForm);
    const jobSubmissionPermissions = useSelector(state => JobModel.getSubmitPermissions(state, guidedJobForm));
    const {
        clientId,
        queueState,
        parameters,
        force,
        isLoading,
        isDisabled
    } = guidedJobForm;

    const updateState = useCallback(updates => dispatch(GuidedJobModel.actionCreators.update(updates)), []);
    const preparePopulateJobSettings = useCallback(() => dispatch(GuidedJobModel.actionCreators.preparePopulateJobSettingsSaga()), []);
    const populateJobSettings = useCallback(() => dispatch(GuidedJobModel.actionCreators.populateJobSettings()), []);

    const loadTsvParameters = useCallback(tsvText => dispatch(GuidedJobModel.actionCreators.loadTsvParameters(tsvText)), []);
    const resetParameters = useCallback(() => dispatch(GuidedJobModel.actionCreators.resetParameters()), []);

    const refreshParameters = useCallback(() => {
        const refreshEffect = contextCall(GuidedJobSaga, 'updateParametersAllowedValues');
        dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(refreshEffect));
    }, []);

    const dispatchParameterUpdate = useCallback((...args) => {
        dispatch(GuidedJobModel.actionCreators.updateParameter(...args));
    }, []);

    const onClose = useCallback(() => {
        const close = () => dispatch(GuidedJobModel.actionCreators.close());
        if (clientId == null) {
            close();
        } else {
            dispatch(PopupModel.actionCreators.showWarning({
                info: {
                    key: 'discardGuidedJob'
                },
                buttons: [{
                    title: t('common:option.discard'),
                    onClick: close
                }]
            }));
        }
    }, [clientId]);

    const onSubmit = useCallback(() => {
        dispatch(GuidedJobModel.actionCreators.submit());
    }, []);

    const nextEnabled = GuidedJobModel.validateFormData(guidedJobForm);
    const queueText = (jobSubmissionPermissions.includes(permissionKeys.SUBMIT_JOB) && queueState === jobQueueStates.BACKLOG) ? t('job:option.submit') : t('job:option.stage');

    const panes = [{
        title: t('guidedJob:panes.clientMatter'), isNextEnabled: nextEnabled.matterPane, backTitle: t('common:option.cancel'), onBackClick: onClose,
        component: <SelectMatterPane jobState={guidedJobForm} updateState={updateState}/>
    }, {
        title: t('guidedJob:panes.libraryWorkflow'), onNextClick: populateJobSettings, isNextEnabled: nextEnabled.workflowPane,
        component: <ParameterWorkflowPane jobState={guidedJobForm} updateState={updateState} requiredParameterTypes={requiredParameterTypes}
            forbiddenParameterTypes={forbiddenParameterTypes} preparePopulateJobSettings={preparePopulateJobSettings}/>
    }, {
        title: t('guidedJob:panes.jobSettings'), isNextEnabled: nextEnabled.jobSettingsPane,
        component: <JobSettingsPane jobState={guidedJobForm} updateState={updateState} dispatchParameterUpdate={dispatchParameterUpdate}
            loadTsvParameters={loadTsvParameters} refreshParameters={refreshParameters} resetParameters={resetParameters}
            parameterBlacklist={operationFieldParameterNames}/>
    }];

    const [getParameter, updateParameter] = useParameters({
        parameters,
        updateState
    });
    const guideProps = {
        guidedJobForm,
        updateState,
        getParameter,
        updateParameter,
        isLoading
    };

    const operationGuides = useOperationGuides(guideProps, getGuides);
    panes.push(...operationGuides.flatMap(guide =>
        guide.getPanes(t, guideProps)
    ));

    panes.push({
        title: t('guidedJob:panes.confirm'), nextTitle: queueText, onNextClick: onSubmit, isNextEnabled: nextEnabled.submitPane,
        component: <JobConfirmPane jobState={guidedJobForm} parameterBlacklist={operationFieldParameterNames}/>
    });

    return (
        <NavigatePane id={'guidedJobForm'} panes={panes} onClose={onClose}
            force={force} closeButtonAriaLabel={closeButtonAriaLabel} isDisabled={isDisabled}/>
    );
}

export function useParameters({parameters, updateState}) {
    const getParameter = useCallback((name, defaultValue='', opts={}) => {
        let param = parameters.get(name);
        if (param == null) {
            param = {value: defaultValue};
        } else {
            param = {...param};
        }
        if (opts.parse && typeof param.value === 'string' && param.value) {
            try {
                if (['true', 'false'].includes(param.value.toLowerCase())) {
                    param.value = stringToBool(param.value);
                } else {
                    param.value = JSON.parse(param.value);
                }
            } catch (error) {
                param.value = defaultValue;
                console.log('Could not parse parameter: ' + param.name);
            }
        }
        return param;
    }, [parameters]);

    const updateParameter = useCallback((name, value, others={}) => {
        updateState(prevState => {
            const params = new Map(prevState.parameters);
            const param = params.get(name);
            let updatedParam;
            if (param != null) {
                updatedParam = param.duplicate({value, ...others});
            } else {
                updatedParam = new ParameterModel({name, value, parameterType: ParameterModel.Type.TEXT, ...others});
            }
            if (typeof updatedParam.value !== 'string') {
                updatedParam.value = JSON.stringify(updatedParam.value);
            }
            updatedParam.valid = true;
            params.set(name, updatedParam);
            return {parameters: params};
        });
    }, [updateState]);

    return [getParameter, updateParameter];
}

export function useOperationGuides(props, getGuides) {
    const {
        guidedJobForm
    } = props;
    const {
        operations,
        isLoading
    } = guidedJobForm;

    const [operationGuides, setOperationGuides] = useState([]);

    // Update operation panes
    useEffect(() => {
        const operationGuides = [];
        if (!isLoading && Array.isArray(operations)) {
            const aliasToOperation = {};
            for (const operation of operations) {
                aliasToOperation[operation.operationAlias] = operation;
            }
            operationGuides.push(...getGuides(aliasToOperation, props));
        }
        setOperationGuides(operationGuides);
    }, [operations, isLoading]);

    return operationGuides;
}

export default GuidedJobForm;
