import SettingModel from "../generics/SettingModel";

class LegalHoldParticipationModel extends SettingModel.Complex {

    static nom = 'LegalHoldParticipationModel';
    static actions = LegalHoldParticipationModel.buildActions('LEGAL_HOLD_PARTICIPATION');
    static actionCreators = LegalHoldParticipationModel.buildActionCreators(LegalHoldParticipationModel.actions);
    static reducer = LegalHoldParticipationModel.buildReducer(LegalHoldParticipationModel.actions);

    constructor(model={}) {
        super(model);
    }
}

export default LegalHoldParticipationModel;
