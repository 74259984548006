import React from 'react';
import {useTranslation} from "react-i18next";
import {ExecutionProfileDropdown, ResourcePoolDropdown} from "../../common/Dropdown/Dropdown";
import {getDataNameFromParentNode} from "../../../utilities/helperFunctions";

function DefaultJobSettings(props) {
    const {setting: defaultValues, updateSetting} = props;
    const {t} = useTranslation(['defaultValues']);

    function setDefaultValuesSetting(event) {
        const {dataset: {value}, parentNode} = event.currentTarget;
        const setting = getDataNameFromParentNode(parentNode);

        updateSetting(setting, JSON.stringify(value));
    }

    return (
        <div className="options-table display-input">
            <div className="table-header-group">
                <div className="table-header" style={{width: '10rem'}}/>
                <div className="table-header"/>
            </div>

            <div className="table-row-group">
                <div className="options-row">
                    <div className="table-cell">
                        <label className="label">
                            {t('defaultValues:label.executionProfile')}:
                        </label>
                    </div>
                    <div className="table-cell">
                        <ExecutionProfileDropdown selectedExecutionProfileId={defaultValues.executionProfileId}
                            onExecutionProfileSelect={setDefaultValuesSetting}
                            buttonStyle={{maxWidth: '20rem'}}
                        />
                    </div>
                </div>

                <div className="options-row">
                    <div className="table-cell">
                        <label className="label">
                            {t('defaultValues:label.resourcePool')}:
                        </label>
                    </div>
                    <div className="table-cell">
                        <ResourcePoolDropdown selectedResourcePoolId={defaultValues.resourcePoolId}
                            onResourcePoolSelect={setDefaultValuesSetting}
                            buttonStyle={{maxWidth: '20rem'}}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DefaultJobSettings;