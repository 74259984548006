import DetailsModel, {DetailsApi, DetailsSaga} from "../generics/DetailsModel";
import ComponentStateModel from "../generics/ComponentStateModel";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import ClientModel from "../client/ClientModel";
import {all, put, select} from "redux-saga/effects";
import {contextCall} from "../../saga/sagaFunctions";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import {details, routes} from "../../utilities/constants";
import {separateItems} from "../../components/common/ListContainer/helpers";
import {getEntries, getValues, postProtectedValue} from "../../utilities/helperFunctions";
import ParameterModel from "../library/ParameterModel";
import {
    buildInputTableParameterValues,
    buildInputTableValuesFromMap,
    buildMapFromInputTable
} from "../../components/common/InputTable/InputTable";
import SchedulerModel from "../scheduler/SchedulerModel";
import {settingsDisplayKeys} from "../../i18next/keys";

class ClientPoolModel extends DetailsModel {

    static nom = 'ClientPoolModel';
    static actions = ClientPoolModel.buildActions('CLIENT_POOL');
    static actionCreators = ClientPoolModel.buildActionCreators(ClientPoolModel.actions);
    static reducer = ClientPoolModel.buildReducer(ClientPoolModel.actions);

    static componentActionCreators = {
        ...ClientPoolModel.buildComponentUpdateActionCreators(),
        ...ClientPoolModel.buildComponentSetActiveActionCreators()
    };

    constructor(model = {}) {
        super(model);
        const {clientIds, allowedParameterValues, requiredMetadataHeaders, workflowParameters, status} = model;

        this.clientIds = clientIds;
        this.allowedParameterValues = getEntries(allowedParameterValues);
        this.requiredMetadataHeaders = buildInputTableValuesFromMap(requiredMetadataHeaders);
        this.workflowParameters = buildInputTableParameterValues(workflowParameters);
        this.status = status;
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'clientPoolDisplay',
                type: 'Display',
                state: {
                    clientPoolId: null,
                    isClientPoolFormActive: false
                }
            },
            {
                key: 'clientPoolTablet',
                type: 'Tablet',
                state: {}
            },
            {
                key: 'clientPoolForm',
                type: 'Form',
                state: {
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'CLIENT_POOL_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }
}

export class ClientPoolApi extends DetailsApi {

    static type = '/clientPool';

    static postClient(id, client) {
        return axiosInstance.post(`/scheduler/clientPool/${id}/client`, client);
    }

    static putClient(id, clientId) {
        return axiosInstance.put(`/scheduler/clientPool/${id}/client/${clientId}`);
    }
}

export class ClientPoolSaga extends DetailsSaga {

    static ModelType = ClientPoolModel;
    static ModelApi = ClientPoolApi;

    static activationComponent = 'CLIENT_POOL_DISPLAY';
    static variableNames = {
        detailsMap: 'clientPoolDetailsMap',
        instanceId: 'clientPoolId',
        modelName: 'clientPoolName',
        isFormActive: 'isClientPoolFormActive',
        updateDisplay: 'updateDisplay',
        updatePane: 'updateTablet',
        route: routes.SETTINGS
    };

    static translations = {
        itemTitle: '$t(clientPool:label.name)',
        itemLower: '$t(clientPool:label.name_lower)'
    };

    static buildActivationEffects(dispatch) {
        return [
            ...super.buildActivationEffects(dispatch),

            // ACTIVATION EFFECTS
            put(ClientPoolModel.actionCreators.startPollingDetails()),
            put(ClientModel.actionCreators.startPollingDetails())
        ]
    }

    static buildDeactivationEffects() {
        return [
            ...super.buildDeactivationEffects(),
            // DEACTIVATION EFFECTS
            put(ClientPoolModel.actionCreators.stopPollingDetails()),
            put(ClientModel.actionCreators.stopPollingDetails())
        ]
    }

    static* setInstanceId(args) {
        const {updateDisplay, instanceId} = this.variableNames;

        yield all ([
            put(SchedulerModel.actionCreators.setSettingsDisplay(settingsDisplayKeys.CLIENT_POOLS)),
            put(this.ModelType.componentActionCreators[updateDisplay]({[instanceId]: args.id}))
        ]);
    }

    static* getEditValues(id) {
        const {name: clientPoolName, clientIds, ...rest} = yield select(state => state.clientPoolDetailsMap.get(id));

        const clientNameValues = yield select(state => getValues(state.clientDetailsMap)
            .map(client => ({name: client.name, value: client.id})));
        const clients = separateItems(clientIds, clientNameValues);

        return {
            clientPoolName,
            clients,
            ...rest
        }
    }

    static getSaveValues(values) {
        const {clientPoolName: name, description, clients} = values;

        const clientIds = clients.right.map(({value}) => value);
        const allowedParameterValues = ParameterModel.buildAllowedParameterValues(values.allowedParameterValues);
        const requiredMetadataHeaders = buildMapFromInputTable(values.requiredMetadataHeaders);

        const workflowParameters = {}
        for (let i = 0; i < values.workflowParameters.length; i++) {
            const [{value: name}, {value}] = values.workflowParameters[i];

            workflowParameters[name] = postProtectedValue(value);
        }

        return {
            name,
            description,
            clientIds,
            allowedParameterValues,
            requiredMetadataHeaders,
            workflowParameters
        }
    }

    static* queryDetails() {
        const response = yield contextCall(ClientPoolApi, 'getDetails');
        const key = details.CLIENT_POOLS;

        if (AxiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(ClientPoolModel.actionCreators.setDetailsMap(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }
}

export default ClientPoolModel;