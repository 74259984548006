import React from "react";
import {useTranslation} from "react-i18next";
import HighlightText from "../../userSettings/HighlightOption/HighlightText";
import {
    getLocaleDateTimeFromUTC,
    getTranslatedValueOrDefault,
    removeUnderlineCapitalizeAll
} from "../../../utilities/helperFunctions";
import Text from "../../common/Text/Text";
import {icon} from "../../../utilities/iconResolver";
import {Button} from "../../common/Button/Button";

export function ChangeLogTable(props) {
    const {t} = useTranslation(['common']);

    const {
        changeLogs,
        downloadAuditLogVersion,

        isDisabled
    } = props;

    const disabled = isDisabled ? ' is-disabled' : '';
    return (
        <div className={'display-table' + disabled} style={{marginTop: '0.5rem'}}>
            <div className="table-header-group">
                <div className="table-header" style={{width: '13rem'}}>
                    <Text value={t('common:label.date')} isBold/>
                </div>

                <div className="table-header" style={{width: '13rem'}}>
                    <Text value={t('common:label.event')} isBold/>
                </div>

                <div className="table-header">
                    <Text value={t('common:label.performedBy')} isBold/>
                </div>

                <div className="table-header" style={{width: '50%'}}>
                    <Text value={t('common:label.details')} isBold/>
                </div>

                <div className="table-header" style={{width: '4rem'}}/>
            </div>

            <div className="table-row-group">
                {Array.isArray(changeLogs) && changeLogs.map((log, index) =>
                    <ChangeLogRow key={`AUDIT${index}_DATE${log.date}`} {...log}
                        downloadAuditLogVersion={downloadAuditLogVersion} isDisabled={isDisabled}/>
                )}
            </div>
        </div>
    )
}

function ChangeLogRow(props) {
    const {t} = useTranslation(['events']);
    const {
        date,
        performedBy,
        legacyEventType,
        details,
        downloadId,

        downloadAuditLogVersion,
        isDisabled
    } = props;

    let eventTypeTranslation;
    if (typeof legacyEventType === 'string') {
        const translatedValue = getTranslatedValueOrDefault(t, null, `events:trigger.${legacyEventType}`);
        if (translatedValue != null) {
            eventTypeTranslation = translatedValue;
        } else {
            eventTypeTranslation = removeUnderlineCapitalizeAll(legacyEventType);
        }
    }

    return (
        <div className="table-row no-pointer">
            <div className="table-cell">
                <label className="label is-wordwrap">
                    <HighlightText text={getLocaleDateTimeFromUTC(date)}/>
                </label>
            </div>

            <div className="table-cell">
                <label className="label is-wordwrap">
                    <HighlightText text={eventTypeTranslation}/>
                </label>
            </div>

            <div className="table-cell">
                <label className="label is-wordwrap">
                    <HighlightText text={performedBy}/>
                </label>
            </div>

            <div className="table-cell">
                <label className="label is-wordwrap break-all">
                    <HighlightText text={details}/>
                </label>
            </div>

            <div className="table-cell">
                {downloadId &&
                    <Button className="ignore-icon-margins" aria-label={t('aria:hiddenAssistText.changeLogDownloadVersion')}
                        value={downloadId} isImg isClear onClick={downloadAuditLogVersion} isDisabled={isDisabled}
                    >
                        <span className="icon is-small">
                            <img src={icon('download')} alt={t('aria:hiddenAssistText.downloadChangelogIcon')}/>
                        </span>
                    </Button>
                }
            </div>
        </div>
    );
}
