import ReduxModel from "../generics/ReduxModel";
import {put} from "redux-saga/effects";
import {actionCreator} from "../../utilities/helperFunctions";
import {ThirdPartyServiceApi} from "./ThirdPartyServiceModel";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import {contextPollUntil} from "../../saga/sagaFunctions";

class ObjectCache extends ReduxModel {

    static getResourceKey(resource, opts={}) {
        return resource + JSON.stringify(opts);
    }

    static buildActions(type) {
        return {
            SET: `SET_${type}`,
            SET_STALE: `SET_STALE_${type}`,
            RESET: `RESET_${type}`
        }
    }

    static buildActionCreators(actions) {
        return {
            set: actionCreator(actions.SET, 'key', 'cache'),
            setStale: actionCreator(actions.SET_STALE, 'key'),
            reset: actionCreator(actions.RESET)
        }
    }

    static buildReducer(actions) {
        return function (state = {}, action) {
            switch (action.type) {
                case actions.SET: {
                    const {key, cache} = action.payload;
                    return {
                        ...state,
                        [key]: {
                            cache
                        }
                    };
                }
                case actions.SET_STALE: {
                    const {key} = action.payload;
                    return {
                        ...state,
                        [key]: {
                            ...state[key],
                            stale: true
                        }
                    };
                }
                case actions.RESET: {
                    return {};
                }
                default:
                    return state;
            }
        }
    }
}

export class ObjectCacheSaga {

    static CacheModel = ObjectCache;

    static* pollResource(untilPattern, serviceId, resource, opts={}, period) {
        yield contextPollUntil(untilPattern, period, this, 'queryResource', serviceId, resource, opts);
    }

    static* queryResource(serviceId, resource, opts={}) {
        const key = this.CacheModel.getResourceKey(resource, opts);
        yield put(this.CacheModel.actionCreators.setStale(key));
        try {
            const {data} = yield ThirdPartyServiceApi.getServiceObjects(serviceId, resource, opts);
            yield put(this.CacheModel.actionCreators.set(key, data));
        } finally {
            yield put(ReduxStateModel.actionCreators.setHasLoaded(key));
        }
    }
}

export default ObjectCache;
