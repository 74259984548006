import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from "react-i18next";
import {axiosInstance} from "../models/api/AxiosProxy";
import ar_AE from "./ar_AE.json";
import da_DK from "./da_DK.json";
import de_DE from "./de_DE.json";
import en_US from "./en_US.json";
import es_419 from "./es_419.json";
import fr_CA from "./fr_CA.json";
import he_IL from "./he_IL.json";
import ja_JP from "./ja_JP.json";
import ko_KR from "./ko_KR.json";
import nl_NL from "./nl_NL.json";
import pt_BR from "./pt_BR.json";
import zh_CN from "./zh_CN.json";
import {userSettings} from "../utilities/constants";
import {all, put, select} from 'redux-saga/effects';
import PopupModel from "../models/scheduler/PopupModel";
import UserSettingsModel, {UserSettingsApi} from "../models/user/UserSettingsModel";
import {getKeys} from "../utilities/helperFunctions";
import {contextCall} from "../saga/sagaFunctions";

const resources = {
    "ar-AE": ar_AE,
    "da-DK": da_DK,
    "de-DE": de_DE,
    "en-US": en_US,
    "es-419": es_419,
    "fr-CA": fr_CA,
    "he-IL": he_IL,
    "ja-JP": ja_JP,
    "ko-KR": ko_KR,
    "nl-NL": nl_NL,
    "pt-BR": pt_BR,
    "zh-CN": zh_CN
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        interpolation: {
            escapeValue: false
        },
        fallbackLng: 'en-US',
        resources
    });

export function* updateLocale(locale) {
    //Need to double parse for null case
    const value = locale === "null" ? JSON.parse(locale) : locale;
    const language = value == null ? browserDefaultLanguage : locale;

    if (i18n.language !== language) {
        axiosInstance.setAcceptLanguageHeader(language);
        i18n.changeLanguage(language).then(() => {}).catch(err => console.log(err));
    }

    // If first time user changed languageFromEnglish, show popup warning
    const {languageFromEnglish} = yield select(state => state.userSettingsMap.get(userSettings.DID_USER_CHANGE));
    if (!languageFromEnglish && language !== 'en-US') {
        yield contextCall(UserSettingsApi, 'putCategorySetting', userSettings.DID_USER_CHANGE, 'languageFromEnglish', true);

        yield all([
            put(UserSettingsModel.actionCreators.updateCategorySetting(userSettings.DID_USER_CHANGE, 'languageFromEnglish', true)),
            put(PopupModel.actionCreators.show({
                info: {
                    key: 'changeLanguageFromEnglish'
                },
                cancelButton: {
                    titleKey: 'common:option.ok'
                }
            }))
        ]);
    }
}

export let browserDefaultLanguage;
{
    const languages = getKeys(resources);

    let browserDefault = navigator.language;

    if (!browserDefault) {
        browserDefault = "en"
    }

    if (!languages.includes(browserDefault)) {
        const language = browserDefault.slice(0, 2);
        browserDefault = languages.find(lang => lang.startsWith(language));
    }

    browserDefaultLanguage = browserDefault;
}

export default i18n;
