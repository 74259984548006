import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import {actionCreator} from "../../utilities/helperFunctions";
import {all, put, takeLeading} from "redux-saga/effects";
import ComponentStateModel from "../generics/ComponentStateModel";
import {tryCatchWrapper} from "../../saga/tryCatchWrapper";
import {contextCall, contextPollUntil, contextSaga, isDisabledWrapper} from "../../saga/sagaFunctions";
import {details} from "../../utilities/constants";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import SagaRunnable from "../generics/SagaRunnable";
import {blobDownload, getFileNameFromContentDisposition} from "../../utilities/downloadHelper";


class RelativityProxyModel {

    static nom = 'RelativityProxyModel';
    static actions = RelativityProxyModel.buildActions();
    static actionCreators = RelativityProxyModel.buildActionCreators(RelativityProxyModel.actions);
    static reducer = RelativityProxyModel.buildReducer(RelativityProxyModel.actions);

    static componentActionCreators = {
        ...RelativityProxyModel.buildComponentUpdateActionCreators(),
        ...RelativityProxyModel.buildComponentSetActiveActionCreators()
    };

    constructor(model) {
        this.apiUri = model.apiUri;
        this.message = model.mesage;
        this.state = model.state;
    }

    static buildActions() {
        return {
            SET_PROXY: `SET_RELATIVITY_PROXY`,
            SUBMIT_FORM: `SUBMIT_RELATIVITY_PROXY`,
            PROXY_DOWNLOAD: 'PROXY_DOWNLOAD_RELATIVITY_PROXY',

            START_POLLING_DETAILS: `START_POLLING_RELATIVITY_PROXY`,
            STOP_POLLING_DETAILS: `STOP_POLLING_RELATIVITY_PROXY`,
            QUERY_DETAILS: 'QUERY_RELATIVITY_PROXY'
        };
    }

    static buildActionCreators(actions) {
        return {
            setProxy: actionCreator(actions.SET_PROXY, 'relativityProxy'),
            submitForm: actionCreator(actions.SUBMIT_FORM, 'formData'),
            proxyDownload: actionCreator(actions.PROXY_DOWNLOAD, 'url', 'fields', 'updateState'),

            startPollingDetails: actionCreator(actions.START_POLLING_DETAILS, 'period'),
            stopPollingDetails: actionCreator(actions.STOP_POLLING_DETAILS),
            queryDetails: actionCreator(actions.QUERY_DETAILS)
        };
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'relativityProxyDisplay',
                type: 'Display',
                state: {
                    isFormActive: false
                }
            },
            {
                key: 'relativityProxyForm',
                type: 'Form',
                state: {
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'RELATIVITY_PROXY_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }

    static buildReducer(actions) {
        return function (state = {}, action) {
            switch (action.type) {
                case actions.SET_PROXY: {
                    const {relativityProxy} = action.payload;

                    this.lastUpdated = Date.now();
                    return new this(relativityProxy);
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }
}

export class RelativityProxyApi {

    static getStatus() {
        return axiosInstance.__get('/proxy-service/status');
    }

    static postConfiguration(configuration) {
        return axiosInstance.__post('/proxy-service/configure', configuration);
    }

    static postProxyDownload(endpoint) {
        const formData = new URLSearchParams({endpoint});
        return axiosInstance._axiosModel.post('/proxy-service/proxyForm', formData, {headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }});
    }
}

export class RelativityProxySaga extends SagaRunnable {

    static activationComponent = 'RELATIVITY_PROXY_DISPLAY';

    static buildGlobalEffects() {
        return [
            takeLeading(RelativityProxyModel.actions.PROXY_DOWNLOAD, contextSaga(this, 'proxyDownload')),
        ]
    }

    static buildActivationEffects(dispatch) {
        return [
            ...super.buildActivationEffects(dispatch),
            // ACTIVATION EFFECTS
            put(RelativityProxyModel.actionCreators.startPollingDetails()),
            takeLeading(RelativityProxyModel.actions.SUBMIT_FORM, tryCatchWrapper, isDisabledWrapper, RelativityProxyModel.componentActionCreators.updateForm, contextSaga(this, 'submitForm'))
        ]
    }

    static buildDeactivationEffects() {
        return [
            ...super.buildDeactivationEffects(),
            // DEACTIVATION EFFECTS
            put(RelativityProxyModel.actionCreators.stopPollingDetails())
        ]
    }

    static* proxyDownload(action) {
        const {url, fields, updateState} = action.payload;

        if (typeof updateState === 'function') {
            yield put(updateState({isDisabled: true}));
        }

        try {
            let params;
            if (Array.isArray(fields)) {
                params = new URLSearchParams();
                for (const field of fields) {
                    params.append(field.name, field.value);
                }
            } else {
                params = new URLSearchParams(fields);
            }

            const endpoint = `/api/v1${url}?${params.toString()}`;
            const res = yield contextCall(RelativityProxyApi, 'postProxyDownload', endpoint);

            const fileName = getFileNameFromContentDisposition(res.data.contentDisposition);
            fetch(`data:application/octet-stream;base64,${res.data.body}`)
                .then(res => res.blob())
                .then(blob => {
                    blobDownload(blob, {type: 'application/octet-stream', fileName});
                });

        } catch (error) {
            console.log(error)
        } finally {
            if (typeof updateState === 'function') {
                yield put(updateState({isDisabled: false}));
            }
        }
    }

    static* submitForm(action) {
        const {formData} = action.payload;
        const {data} = yield contextCall(RelativityProxyApi, 'postConfiguration', formData);

        yield all([
            put(RelativityProxyModel.actionCreators.setProxy(data)),
            put(RelativityProxyModel.componentActionCreators.updateDisplay({isFormActive: false}))
        ]);

        // Reload app on success to test proxy configuration
        window.location.reload();
    }

    static* pollDetails(action) {
        const {period} = action.payload;
        yield contextPollUntil(RelativityProxyModel.actions.STOP_POLLING_DETAILS, period, this, 'queryDetails');
    }

    static* queryDetails() {
        const res = yield contextCall(RelativityProxyApi, 'getStatus');
        const key = details.RELATIVITY_PROXY;

        if (AxiosProxy.shouldUpdate(key, res)) {
            yield all([
                put(RelativityProxyModel.actionCreators.setProxy(res.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }
}

export default RelativityProxyModel;
