import React from "react";
import {buildClassName} from "../../../utilities/helperFunctions";
import Dropdown from "../../common/Dropdown/Dropdown";
import {Link} from "react-router-dom";
import expandArrow from "../../../resources/images/neo/fill-white/expand_more.svg";
import {StatusIcons} from "./NavBarLinkItem";
import {Button} from "../../common/Button/Button";
import {useTranslation} from "react-i18next";

function NavBarLinkMenu(props) {
    const {
        id,
        label,
        statusIcons,
        isActive,
        children,
        'aria-label': ariaLabel=label
    } = props;

    const className = buildClassName(
        'navbar-dropdown',
        isActive && 'is-active'
    );

    return (
        <div className={className}>
            <Dropdown id={id} isTransparent enableVirtualRendering={false}
                ButtonComponent={LinkMenuButton} selectedItems={label} items={children}
                aria-label={ariaLabel} icon={{statusIcons}}
            />
            <span className="navbar-selected-bar"/>
        </div>
    )
}

export function NavBarLinkMenuItem(props) {
    const {
        text,
        to,
        statusIcons,
        isActive,
        onClick,
        ...attr
    } = props;

    function onKeyPress(e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            e.target.blur();
        }
    }

    function _onClick(e) {
        if (typeof onClick === 'function') {
            onClick(e);
        }
        e.target.blur();
    }

    const className = buildClassName(
        'navbar-menu-item',
        isActive && 'is-active'
    );

    return (
        <Link role="option" className={className} to={to} onClick={_onClick}
            onKeyPress={onKeyPress} {...attr}
        >
            {statusIcons &&
                <StatusIcons icons={statusIcons}/>
            }
            <p className="navbar-label">
                {text}
            </p>
        </Link>
    )
}

const LinkMenuButton = React.forwardRef((props, ref) => {
    const {t} = useTranslation(['aria']);
    const {
        icon,
        selectedItems,
        isIconDropdown,
        ...attr
    } = props;

    return (
        <Button ref={ref} isImg={isIconDropdown} {...attr}>
            {icon?.statusIcons &&
                <StatusIcons icons={icon.statusIcons}/>
            }
            {!isIconDropdown &&
                <>
                    <p className="navbar-label">
                        {selectedItems}
                    </p>
                    <span className="navbar-icon">
                        <img src={expandArrow} alt={t('aria:hiddenAssistText.expandArrowIcon')}/>
                    </span>
                </>
            }
        </Button>
    )
});

export default NavBarLinkMenu;
