import React from "react";
import {useTranslation} from "react-i18next";
import {buildClassName} from "../../utilities/helperFunctions";
import {useSelector} from "react-redux";


export function VisuallyHiddenAssistText(props) {
    const {t} = useTranslation(['aria']);
    const {
        text,
        translationKey,
        ...attr
    } = props;

    const hiddenText = text || t(`aria:hiddenAssistText.${translationKey}`);
    const className = buildClassName(
        'visually-hidden',
        getMissingTranslationClass(hiddenText)
    );

    return (
        <span className={className} {...attr}>
            {hiddenText}
        </span>
    )
}

export function getMissingTranslationClass(text) {
    if (isMissingTranslation(text)) {
        return 'missing-translation-key';
    }
}

export function useGetMissingClasses(text, opts={}) {
    const {
        hasContext,
        ariaLabel,
        ariaLabelledBy
    } = opts;

    const enableDebugMode = useSelector(state => state.schedulerDetails.configuration.enableUserInterfaceDebugMode);
    if (!enableDebugMode) {
        return [];
    }

    let missingAccessibility, missingTranslation;
    if (ariaLabel && isMissingTranslation(ariaLabel)) {
        missingTranslation = true;
    }
    if (!text && !hasContext) {
        if (!ariaLabel && !ariaLabelledBy) {
            missingAccessibility = true;
        }
    }
    return [
        missingAccessibility && 'missing-accessibility',
        missingTranslation && 'missing-translation-key',
    ].filter(e => e);
}

export function isMissingTranslation(text) {
    return text?.match(/^[a-zA-Z]+\.[_a-zA-Z]+$/);
}
