import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Tablet, {EditTabletHeader} from "../common/Tablet/Tablet";
import {nuixFileTypeKeys, permissionKeys} from "../../i18next/keys";
import UserDataDirModel from "../../models/system/UserDataDirModel";
import SearchBar from "../common/SearchBar/SearchBar";
import Text from "../common/Text/Text";
import {useKeyPressEffect,} from "../../utilities/hooks";
import {
    buildClassName,
    bytesCountToReadableCount,
    getFileExtension,
    getLocaleDateTimeFromUTC,
    textContainsEverySearchWord
} from "../../utilities/helperFunctions";
import {fileIconModel} from "../../models/generics/IconModel";
import {SettingsRowSeparator, SettingsRowValue} from "../common/CustomTable/CustomTable";
import {icon} from "../../utilities/iconResolver";


function UserDataDirTablet() {
    const {t} = useTranslation(['systemResources']);
    const dispatch = useDispatch();

    const userDataDir = useSelector(state => state.userDataDir);
    const isDisabled = false;

    useEffect(() => {
        dispatch(UserDataDirModel.actionCreators.startPollingSettings());
        return () => dispatch(UserDataDirModel.actionCreators.stopPollingSettings());
    }, []);

    function onClose() {
        dispatch(UserDataDirModel.componentActionCreators.updateDisplay({isTabletActive: false}));
    }

    const canModify = userDataDir.userPermissions.includes(permissionKeys.MODIFY);

    return (
        <Tablet width={'100rem'} height={'65vh'} onClose={onClose} isDisabled={isDisabled}
            closeButtonAriaLabel={t('systemResources:option.closeUserDataDirTablet')}
            header={
                <EditTabletHeader id={userDataDir.id} label={t(`systemResources:label.userDataDir`)}
                    value={userDataDir.path} type={'userDataDir'} canModify={canModify} isDisabled={isDisabled}/>
            }
            body={
                <>
                    <div className="display-input" style={{height: 'calc(100% - 1.5rem)'}}>
                        <FileInfoView fileInfos={userDataDir.files}/>
                    </div>
                </>
            }
        />
    )
}

function FileInfoView(props) {
    const {t} = useTranslation(['systemResources', 'common']);

    const {
        fileInfos,
        isDisabled
    } = props;

    const [searchText, setSearchText] = useState('');
    const [selectedIndex, setSelectedIndex] = useState();

    function updateSearchText(event) {
        setSearchText(event.target.value);
    }

    function updateSelectedIndex(event) {
        setSelectedIndex(event.currentTarget.dataset.value);
    }

    const selectedFile = fileInfos[selectedIndex];
    const showSearchBar = fileInfos.length > 10;

    return (
        <section className="tablet-view" style={{height: '100%'}}>
            <aside className="tablet-view__panels" style={{overflowY: 'auto'}}>
                <section className="tablet-view__panels__add sticky-header" style={{marginBottom: '0'}}>
                    <Text isHeading value={t('systemResources:label.files')}
                        isDisabled={isDisabled}/>
                    {showSearchBar &&
                        <SearchBar value={searchText} style={{marginRight: '0.75rem', marginTop: '0.75rem'}}
                            onChange={updateSearchText} isDisabled={isDisabled}/>
                    }
                </section>

                <section className="tablet-view__list">
                    {fileInfos.map((info, index) => {
                        let infoText = info.relativePath;
                        if (info.fileType != null) {
                            infoText += ` ${t(`fileLibrary:file.type.${info.fileType}`)}`;
                        }
                        if (textContainsEverySearchWord(searchText, infoText)) {
                            return (
                                <FileInfoPanel key={index} fileInfo={info} index={index} onClick={updateSelectedIndex}
                                    isActive={parseInt(selectedIndex) === index} isDisabled={isDisabled}/>
                            )
                        }
                        return null;
                    })}
                </section>
            </aside>

            {selectedFile != null &&
                <FileInfoDisplay fileInfo={selectedFile} isDisabled={isDisabled}/>
            }
        </section>
    )
}

function FileInfoPanel(props) {
    const {t} = useTranslation(['fileLibrary']);

    const {
        fileInfo,
        index,
        onClick,
        isActive,
        isDisabled,
        ...attr
    } = props

    const containerRef = useRef();
    const keyToCb = useRef({
        'Enter': 'click'
    });
    useKeyPressEffect({containerRef, keyToCb: keyToCb.current});

    const className = buildClassName(
        'tablet-view__panel',
        'selectPanelSimple',
        isActive && 'is-active',
        isDisabled && 'is-disabled'
    );

    let fileType;
    if (fileInfo.fileType != null) {
        fileType = t(`fileLibrary:file.type.${fileInfo.fileType}`);
    }

    return (
        <article className={className} data-value={index} data-index={index} {...attr}
            ref={containerRef} onClick={isDisabled ? null : onClick} tabIndex={isDisabled ? -1 : 0}
        >
            <Text value={fileInfo.name}
                isBold={isActive} isEllipsis isDisabled={isDisabled}/>

            <Text value={fileType}
                isItalic isEllipsis isDisabled={isDisabled}/>
        </article>
    )
}

function FileInfoDisplay(props) {
    const {t} = useTranslation(['systemResources', 'common']);

    const {
        fileInfo,
        isDisabled
    } = props;

    const fileExtension = getFileExtension(fileInfo.name, 'empty');
    const fileIconName = fileInfo.fileType != null ? t(`fileLibrary:file.type.${fileInfo.fileType}`) : fileExtension;

    let fileIcon = fileIconModel.useIcon(fileExtension, {getLargeIcon: true});
    if (nuixFileTypeKeys[fileInfo.fileType] != null) {
        fileIcon = icon("fileNuix");
    }

    const className = buildClassName(
        'tablet-view__display',
        isDisabled && 'is-disabled'
    );

    return (
        <section className={className}>
            <section className="tablet-view__display__header">
                <h2 className="subtitle is-bold" style={{marginRight: '1rem'}}>
                    {fileInfo.name}
                </h2>

                <div style={{display: 'inline-flex', alignItems: 'center'}}>
                    {fileInfo.fileType &&
                        <h2 className="subtitle" style={{marginRight: '0.5rem'}}>
                            {fileIconName}
                        </h2>
                    }
                    <span className="icon is-medium">
                        <img src={fileIcon} alt={fileExtension} title={fileIconName}/>
                    </span>
                </div>
            </section>

            <section className="tablet-view__display__info" style={{marginTop: '2rem'}}>
                <div className="settings-table">
                    <div className="table-row-group">
                        <SettingsRowValue label={t('common:label.path')} value={fileInfo.relativePath}/>
                        <SettingsRowValue label={t('common:label.md5')} value={fileInfo.md5Hash}/>
                        <SettingsRowSeparator/>

                        <SettingsRowValue label={t('common:label.size')} value={bytesCountToReadableCount(fileInfo.size)}/>
                        <SettingsRowValue label={t('common:label.createdDate')} value={getLocaleDateTimeFromUTC(fileInfo.createdDate)}/>
                        <SettingsRowValue label={t('common:label.lastModifiedDate')} value={getLocaleDateTimeFromUTC(fileInfo.lastModifiedDate)}/>
                    </div>
                </div>
            </section>
        </section>
    )
}

export default UserDataDirTablet;
