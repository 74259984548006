import React, {useMemo} from "react";
import ParameterModel from "../../../models/library/ParameterModel";
import Text from "../../common/Text/Text";
import Switch from "../../common/Switch/Switch";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import ParameterInput from "../../parameters/ParameterInput";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import WorkflowBuilderOperation from "../../../models/workflowbuilder/WorkflowBuilderOperation";
import {InternalDropdownField} from "./DropdownField";

export function selectPrecedingParameters(workflowBuilder, configuration) {
    const precedingOperations = workflowBuilder.operations.slice(0, workflowBuilder.selectedOperationIndex);
    const currentOperation = workflowBuilder.operations[workflowBuilder.selectedOperationIndex];

    const parameters = precedingOperations
        .filter(operation => operation.operationAlias === WorkflowBuilderOperation.Alias.CONFIGURE_PARAMETERS)
        .flatMap(operation => [...operation.staticParameters, ...operation.userParameters]);

    if (currentOperation.operationAlias === WorkflowBuilderOperation.Alias.CONFIGURE_PARAMETERS) {
        parameters.push(...currentOperation.staticParameters);
        parameters.push(...currentOperation.userParameters.slice(0, configuration.listIndex));
    }
    return parameters
        .filter(param => param.name && (!configuration.parameterType || param.parameterType === configuration.parameterType));
}

export function ParameterInputField(props) {
    const {
        label,
        value,
        configuration,
        updateState,
        ...rest
    } = props;

    const parameter = useMemo(() => new ParameterModel({
        name: configuration.parameterName,
        friendlyName: configuration.friendlyName,
        value: value,
        parameterType: configuration.parameterType,
        allowedValues: configuration.allowedValues?.length > 0 && configuration.allowedValues.filter(v => v),
        min: configuration.min,
        max: configuration.max,
        regex: configuration.regex,
        dataRepositoryId: configuration.dataRepositoryId,
        allowedDataRepositoryIds: configuration.allowedDataRepositoryIds,
        allowedFileExtensions: configuration.allowedFileExtensions,
        notRequired: configuration.notRequired
    }), [value, configuration]);

    function onChange(event, _event=event) {
        updateState({[configuration.name]: event.target.value}, _event);
        parameter.update({value: event.target.value});
    }

    return (
        <>
            <Text value={label} isDisabled={rest.isDisabled}/>
            <Switch>
                {parameter.isPasswordParameter() &&
                    <HTMLTextInput {...rest} name={parameter.name} type={'password'}
                        value={value} onChange={onChange}/>
                }
                <ParameterInput {...rest} onValueChange={onChange} updateParameter={updateState} isEditActive
                    filter={configuration.filter} parameter={parameter} allowedValues={parameter.getAllowedValues()} fromWorkflowBuilder/>
            </Switch>
        </>
    )
}

export function ParameterDropdownField(props) {
    const {t} = useTranslation(['workflowBuilder']);
    const {
        configuration,
        ...rest
    } = props;

    const parameterItems = useSelector(state => selectPrecedingParameters(state.workflowBuilder, configuration))
        .map(param => ({name: param.name, value: param.name}));

    return (
        <InternalDropdownField configuration={configuration} items={parameterItems}
            noneSelectedMessage={t('workflowBuilder:option.selectParameter')} {...rest}/>
    )
}
