import React, {useEffect, useState} from "react";
import {getUtcFromDateTimeValue} from "./UtcDateTimeInput";
import HTMLTextInput from "./HTMLTextInput";
import {buildFakeEvent} from "../../../utilities/helperFunctions";

function UtcDateInput(props) {
    const {
        value,
        inputHandler,
        min,
        max,
        ...rest
    } = props;

    // Track as internal state to prevent re-computing on every render
    const [dateValue, setDateValue] = useState();
    const [minMaxDates, setMinMaxDates] = useState({});

    useEffect(() => {
        setDateValue(getLocalDateValueFromUtc(value));
        setMinMaxDates({
            max: isNaN(parseInt(max)) ? null : getLocalDateValueFromUtc(max),
            min: isNaN(parseInt(min)) ? null : getLocalDateValueFromUtc(min),
        });
    }, [value, min, max]);

    // Translate to UTC and send update
    function onChange(event) {
        const {name, value} = event.target;
        inputHandler(buildFakeEvent({name, value: getUtcFromDateTimeValue(value)}));
    }

    if (dateValue == null) return null;
    return (
        <HTMLTextInput type={'date'} value={dateValue} min={minMaxDates.min} max={minMaxDates.max}
            onChange={onChange} style={{width: '9rem'}} {...rest}/>
    )
}

export function getLocalDateValueFromUtc(utcMillis) {
    try {
        const millis = Number(utcMillis);
        const localIsoString = new Date(millis).toISOString();
        return localIsoString.substring(0, localIsoString.indexOf('T'));
    } catch (error) {
        return '';
    }
}

export default UtcDateInput;
