import React from "react";
import {SettingsRowValue} from "../../common/CustomTable/CustomTable";
import {useTranslation} from "react-i18next";
import ThirdPartyUserCredential from "../../../models/thirdparty/ThirdPartyUserCredential";
import UsernamePasswordUserCredentialFormBody from "../userCredential/UsernamePasswordCredentialFormBody";

function SmtpUserCredentialFormBody(props) {
    return (
        <UsernamePasswordUserCredentialFormBody {...props} type={ThirdPartyUserCredential.Type.SMTP}
            SettingRows={SmtpUserCredentialFormBodySettingRows}/>
    )
}

function SmtpUserCredentialFormBodySettingRows(props) {
    const {t} = useTranslation(['thirdPartyService', 'common']);
    const {
        thirdPartyService,
        isDisabled
    } = props;

    return (
        <>
            <SettingsRowValue label={t('thirdPartyService:label.host')} value={thirdPartyService.host}
                isTight isDisabled={isDisabled}/>
        </>
    )
}

export default SmtpUserCredentialFormBody;
