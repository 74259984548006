import React, {useEffect, useRef} from "react";
import {axiosInstance} from "../../../models/api/AxiosProxy";
import * as ReactDOM from "react-dom";


const submitFormDivId = 'submitFormParentDiv';
const defaultFormAttr = {
    target: '_blank'
}
export function renderSubmitForm(url, {fields=[], formAttr=defaultFormAttr}={}) {
    let tempDiv = document.getElementById(submitFormDivId);
    if (tempDiv == null) {

        tempDiv = document.createElement('div');
        tempDiv.id = submitFormDivId;
        tempDiv.display = 'none';

        const root = document.getElementById('root');
        root.appendChild(tempDiv);
    }

    if (fields.find(field => field.name === 'token') == null) {
        if (axiosInstance.uiToken) {
            fields.push({name: 'token', value: axiosInstance.uiToken});
        }
    }

    ReactDOM.render(
        <SubmitForm url={axiosInstance.baseUrl + url} fields={fields} {...formAttr}/>,
        tempDiv
    );
}

function SubmitForm(props) {

    const ref = useRef();

    const {
        url,
        fields,
        ...formAttr
    } = props;

    useEffect(() => {
        if (ref.current != null) {
            ref.current.submit();
            ReactDOM.unmountComponentAtNode(document.getElementById(submitFormDivId));
        }

    }, [ref]);


    return (
        <form ref={ref} rel="noopener noreferrer" action={url} method="POST" {...formAttr}>
            {fields.map((field, i) =>
                <input key={field.name || i} type="hidden" {...field}/>
            )}
        </form>
    )
}
