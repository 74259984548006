import DetailsModel, {DetailsApi, DetailsSaga} from "../generics/DetailsModel";
import {all, put, race, select, take} from "redux-saga/effects";
import {
    actionCreator,
    camelCase,
    capitalizeFirstLetter,
    lowerCaseFirstLetter,
    parseTimeStampedLog
} from "../../utilities/helperFunctions";
import ComponentStateModel from "../generics/ComponentStateModel";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import {details, routes} from "../../utilities/constants";
import {tryCatchWrapper} from "../../saga/tryCatchWrapper";
import {contextCall, contextSaga, isDisabledWrapper, takeLeadingPayload} from "../../saga/sagaFunctions";
import SchedulerModel, {SchedulerSaga} from "../scheduler/SchedulerModel";
import {settingsDisplayKeys} from "../../i18next/keys";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import PopupModel from "../scheduler/PopupModel";
import UserServiceModel from "../user/UserServiceModel";
import ThirdPartyUserCredential from "./ThirdPartyUserCredential";
import {renderSubmitForm} from "../../components/common/SubmitForm/SubmitForm";
import {buildInputTableValuesFromMap, buildMapFromInputTable} from "../../components/common/InputTable/InputTable";

class ThirdPartyServiceModel extends DetailsModel {

    static nom = 'ThirdPartyServiceModel';
    static actions = ThirdPartyServiceModel.buildActions('THIRD_PARTY_SERVICE');
    static actionCreators = ThirdPartyServiceModel.buildActionCreators(ThirdPartyServiceModel.actions);
    static reducer = ThirdPartyServiceModel.buildReducer(ThirdPartyServiceModel.actions);

    static componentActionCreators = {
        ...ThirdPartyServiceModel.buildComponentUpdateActionCreators(),
        ...ThirdPartyServiceModel.buildComponentSetActiveActionCreators()
    };

    constructor(model) {
        super(model);
        if (model != null) {
            this.forceUpdate(model.service);
            this.userCredential = new ThirdPartyUserCredential(model.userCredential);
            this.status = model.status;
            this.log = parseTimeStampedLog(model.log);
        }
    }

    static Type = {
        PURVIEW: 'PurviewService',
        VAULT: 'VaultService',
        RELATIVITY: 'RelativityService',
        DISCOVER: 'DiscoverService',
        ELASTICSEARCH: 'ElasticsearchService',
        NLP: 'NlpService',
        ECC: 'EccService',
        DERBY_CONTROL: 'DerbyControlService',
        SEMANTIC: 'SemanticService',
        GRAPH: 'GraphService',
        GEN_AI: 'GenAiService',
        INVESTIGATE: 'InvestigateService',
        SMTP: 'SmtpService',
        LINK: 'LinkService'
    }

    static AuthenticationScope = {
        SERVICE: 'SERVICE',
        USER: 'USER'
    }

    static AuthenticationMethod = {
        OIDC_AUTHORIZATION_CODE: 'OIDC_AUTHORIZATION_CODE',
        OIDC_CLIENT_CREDENTIALS: 'OIDC_CLIENT_CREDENTIALS',
        USERNAME_PASSWORD: 'USERNAME_PASSWORD',
        SECRET_PROPERTIES: 'SECRET_PROPERTIES',
        API_KEY: 'API_KEY',
        NONE: 'NONE'
    }

    static UserCredentialAction = {
        RESET: 'RESET',
        TEST: 'TEST',
        REFRESH: 'REFRESH'
    }

    static Action = {
        // SMTP
        RESUBMIT_FAILED: 'RESUBMIT_FAILED',
        PURGE_FAILED: 'PURGE_FAILED'
    }

    static EndpointType = {
        HTTP: 'HTTP',
        HTTPS: 'HTTPS'
    }

    static RelativityRestVersion = {
        RSAPI: 'RSAPI',
        REST_V1: 'REST_V1',
        REST_SERVER_2021: 'REST_SERVER_2021'
    };

    static validateFormData(formData, state) {
        const isServiceValid = !!(formData.serviceName && formData.authenticationScope);
        if (!isServiceValid) {
            return false;
        }

        switch (formData.type) {
            case ThirdPartyServiceModel.Type.PURVIEW:
                return !!(formData.authenticationServiceId);
            case ThirdPartyServiceModel.Type.VAULT:
                return !!(formData.authenticationServiceId);
            case ThirdPartyServiceModel.Type.RELATIVITY:
                return !!(formData.hostname && formData.serviceEndpoint && (!formData.useCustomClientVersion || formData.customClientVersion)
                    && parseInt(formData.importThreads) > 0 && parseInt(formData.importThreadTimeout) > 0 && parseInt(formData.importThreadRetries) >= 0 && parseInt(formData.metadataThreads) > 0
                    && (formData.authenticationMethod !== ThirdPartyServiceModel.AuthenticationMethod.OIDC_AUTHORIZATION_CODE || formData.authenticationServiceId)
                );
            case ThirdPartyServiceModel.Type.DISCOVER:
                return !!(formData.hostname);
            case ThirdPartyServiceModel.Type.NLP:
                return !!(formData.url);
            case ThirdPartyServiceModel.Type.ECC:
                return !!(formData.url);
            case ThirdPartyServiceModel.Type.DERBY_CONTROL:
                return !!(formData.url) && !!(formData.authenticationServiceId);
            case ThirdPartyServiceModel.Type.GRAPH:
                return !!(formData.url);
            case ThirdPartyServiceModel.Type.SEMANTIC:
                return !!(formData.url);
            case ThirdPartyServiceModel.Type.GEN_AI:
                return !!(formData.url) &&
                    (!!(formData.model) || formData.url.endsWith("/completions"));
            case ThirdPartyServiceModel.Type.INVESTIGATE:
                return !!(formData.url) && !!(formData.authenticationServiceId);
            case ThirdPartyServiceModel.Type.SMTP:
                return !!(formData.host && formData.port && formData.from && formData.emailRetryInterval > 0 && formData.emailMaxAttempts >= 0);
            case ThirdPartyServiceModel.Type.LINK:
                return !!(formData.url);
            case ThirdPartyServiceModel.Type.ELASTICSEARCH:
                return true;
        }
    }

    static canSignIn(thirdPartyService) {
        return thirdPartyService.authenticationMethod === ThirdPartyServiceModel.AuthenticationMethod.USERNAME_PASSWORD
            || thirdPartyService.authenticationMethod === ThirdPartyServiceModel.AuthenticationMethod.API_KEY
            || thirdPartyService.authenticationMethod === ThirdPartyServiceModel.AuthenticationMethod.SECRET_PROPERTIES
            || thirdPartyService.authenticationMethod === ThirdPartyServiceModel.AuthenticationMethod.OIDC_AUTHORIZATION_CODE;
    }

    static canExport(thirdPartyService) {
        if (thirdPartyService != null) {
            return thirdPartyService.type === ThirdPartyServiceModel.Type.SMTP;
        }
    }

    static canRefreshToken(thirdPartyService) {
        return thirdPartyService.authenticationMethod === ThirdPartyServiceModel.AuthenticationMethod.OIDC_AUTHORIZATION_CODE;
    }

    static getViewableSettings(thirdPartyService) {
        const viewable = {
            defaultSettings: true,
            authenticationSettings: true,
            whitelistedCertFingerprints: true
        }
        if (thirdPartyService != null) {
            switch (thirdPartyService.type) {
                case ThirdPartyServiceModel.Type.GRAPH:
                    viewable.whitelistedCertFingerprints = false;
                    break;
                case ThirdPartyServiceModel.Type.GEN_AI:
                case ThirdPartyServiceModel.Type.ELASTICSEARCH:
                    viewable.whitelistedCertFingerprints = false;
                    break;
                case ThirdPartyServiceModel.Type.LINK:
                    viewable.defaultSettings = false;
                    viewable.authenticationSettings = false;
                    viewable.whitelistedCertFingerprints = false;
                    break;
                case ThirdPartyServiceModel.Type.SEMANTIC:
                    viewable.authenticationSettings = false;
                    break;
            }
        }
        return viewable;
    }

    static getServiceActionBodies(thirdPartyService, initiatorEmailCounter) {
        if (thirdPartyService == null || initiatorEmailCounter == null) {
            return null;
        }

        const commonFields = {
            id: thirdPartyService.id,
            type: thirdPartyService.type,
            initiatorId: initiatorEmailCounter.initiatorId
        };

        // Custom third-party service actions
        // switch (thirdPartyService.type) {
        //     case ThirdPartyServiceModel.Type.SMTP:
        //         return [
        //             {
        //                 ...commonFields,
        //                 action: ThirdPartyServiceModel.Action.RESUBMIT_FAILED,
        //                 disabled: initiatorEmailCounter?.errorEmailCount === 0
        //             },
        //             {
        //                 ...commonFields,
        //                 prompt: true,
        //                 action: ThirdPartyServiceModel.Action.PURGE_FAILED,
        //                 disabled: initiatorEmailCounter?.errorEmailCount === 0
        //             }
        //         ]
        // }
    }

    static buildActions(type) {
        return {
            ...super.buildActions(type),
            SEND_ACTION: `SEND_${type}_ACTION`,
            EXPORT_CSV: `EXPORT_${type}_CSV`
        }
    }

    static buildActionCreators(actions) {
        return {
            ...super.buildActionCreators(actions),
            sendCommand: actionCreator(actions.SEND_COMMAND, 'id', 'command', 'args', 'successPopupInfo'),
            sendAction: actionCreator(actions.SEND_ACTION, 'command'),
            exportCsv: actionCreator(actions.EXPORT_CSV, 'id', 'options')
        }
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'thirdPartyServiceDisplay',
                type: 'Display',
                state: {
                    thirdPartyServiceId: null,
                    isThirdPartyServiceFormActive: false,
                    idDisabled: null
                }
            },
            {
                key: 'thirdPartyServiceTablet',
                type: 'Tablet',
                state: {
                    triggerUserCredentialSignIn: null,
                    isDisabled: false
                }
            },
            {
                key: 'thirdPartyServiceForm',
                type: 'Form',
                state: {
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'THIRD_PARTY_SERVICE_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }
}

export class ThirdPartyServiceApi extends DetailsApi {

    static location = '/resources';
    static type = '/thirdPartyService';

    static postAction(id, action, body) {
        return axiosInstance.post(`/scheduler${this.location}${this.type}/${id}/action`, {action, body});
    }

    static postUserCredentialCommand(id, action, body) {
        return axiosInstance.post(`/scheduler${this.location}${this.type}/${id}/userCredential/command`, {action, body});
    }

    static postUserCredential(id, userCredential) {
        return axiosInstance.post(`/scheduler${this.location}${this.type}/${id}/userCredential`, userCredential);
    }

    static deleteUserCredential(id, scope) {
        return axiosInstance.del(`/scheduler${this.location}${this.type}/${id}/userCredential/${camelCase(scope)}`);
    }

    static getServiceObjects(id, type, request) {
        return axiosInstance.post(`/scheduler${this.location}${this.type}/${id}/serviceObjects?objectType=${type}`, request);
    }

    static parseImportedObjects(id, type, rows) {
        return axiosInstance.post(`/scheduler${this.location}${this.type}/${id}/parseImportedObjects?objectType=${type}`, rows);
    }
}

export class ThirdPartyServiceSaga extends DetailsSaga {

    static ModelType = ThirdPartyServiceModel;
    static ModelApi = ThirdPartyServiceApi;

    static activationComponent = 'THIRD_PARTY_SERVICE_DISPLAY';
    static variableNames = {
        detailsMap: 'thirdPartyServiceDetailsMap',
        instanceId: 'thirdPartyServiceId',
        isFormActive: 'isThirdPartyServiceFormActive',
        updateDisplay: 'updateDisplay',
        updatePane: 'updateTablet',
        route: routes.SETTINGS
    };

    static translations = {
        itemTitle: '$t(thirdPartyService:label.name)',
        itemLower: '$t(thirdPartyService:label.name_lower)'
    };

    static buildGlobalEffects() {
        return [
            takeLeadingPayload(ThirdPartyServiceModel.actions.SEND_ACTION, tryCatchWrapper, isDisabledWrapper, ThirdPartyServiceModel.componentActionCreators.updateTablet, contextSaga(this, 'sendAction')),
            takeLeadingPayload(ThirdPartyServiceModel.actions.EXPORT_CSV, tryCatchWrapper, isDisabledWrapper, ThirdPartyServiceModel.componentActionCreators.updateTablet, contextSaga(this, 'exportCsv')),
        ]
    }

    static buildActivationEffects(dispatch) {
        return [
            ...super.buildActivationEffects(dispatch),
            put(ThirdPartyServiceModel.actionCreators.startPollingDetails()),
            put(UserServiceModel.actionCreators.startPollingDetails()),

            takeLeadingPayload(ThirdPartyServiceModel.actions.SEND_COMMAND, tryCatchWrapper, isDisabledWrapper, ThirdPartyServiceModel.componentActionCreators.updateTablet, contextSaga(this, 'sendCommand'))
        ]
    }

    static buildDeactivationEffects() {
        return [
            ...super.buildDeactivationEffects(),
            put(ThirdPartyServiceModel.actionCreators.stopPollingDetails()),
            put(UserServiceModel.actionCreators.stopPollingDetails())
        ]
    }

    static* setInstanceId(args) {
        const {updateDisplay, instanceId} = this.variableNames;

        yield all([
            put(SchedulerModel.actionCreators.setSettingsDisplay(settingsDisplayKeys.THIRD_PARTY_SERVICES)),
            put(this.ModelType.componentActionCreators[updateDisplay]({[instanceId]: args.id}))
        ]);
    }

    static* sendAction(_action) {
        const {command} = _action.payload;

        const {id, type, action, prompt, successPopupInfo, onSuccess, ...rest} = command;
        const camelCaseType = lowerCaseFirstLetter(type);

        let perform = true;
        if (prompt) {
            const performAction = `PERFORM_THIRD_PARTY_ACTION_${action}_${id}`;
            const discardAction = `DISCARD_THIRD_PARTY_ACTION_${action}_${id}`;

            yield put(PopupModel.actionCreators.showWarning({
                info: {
                    key: `${camelCaseType}Prompt${action}`
                },
                buttons: [{
                    titleKey: `thirdPartyService:action.${action}`,
                    onClick: dispatch => dispatch({type: performAction})
                }],
                cancelButton: {
                    onClick: dispatch => dispatch({type: discardAction})
                }
            }));

            [perform] = yield race([
                take(performAction),
                take(discardAction)
            ]);
        }

        if (perform) {
            const {data} = yield contextCall(ThirdPartyServiceApi, 'postAction', id, action, rest);
            if (typeof onSuccess === 'function') {
                onSuccess(data);
            }
            yield put(PopupModel.actionCreators.showSuccess({
                info: successPopupInfo || {key: `${camelCaseType}Success${action}`, values: data}
            }));
        }
    }

    static* sendCommand(action) {
        const {id, command, args, successPopupInfo} = action.payload;
        const {data} = yield contextCall(ThirdPartyServiceApi, 'postUserCredentialCommand', id, command, args);

        const popupInfo = successPopupInfo || {key: `thirdPartyUserCredential${capitalizeFirstLetter(camelCase(command))}Success`};

        yield all([
            put(this.ModelType.actionCreators.updateDetails({[id]: new this.ModelType(data)})),
            put(PopupModel.actionCreators.showSuccess({
                info: popupInfo
            }))
        ]);
    }

    static* exportCsv(action) {
        const {id, options} = action.payload;

        const url = `/scheduler/resources/thirdPartyService/${id}/exportCsv`;
        const formAttr = {
            target: '_blank'
        };
        const fields = [
            {name: 'token', value: axiosInstance.uiToken},
            {name: 'optionsJson', value: JSON.stringify(options)}
        ];

        renderSubmitForm(url, {fields, formAttr});
    }

    static* toggleEnabled(action) {
        const {id} = action.payload;
        const {detailsMap, updatePane, updateDisplay} = this.variableNames;
        try {
            yield all([
                put(this.ModelType.componentActionCreators[updatePane]({isDisabled: true})),
                put(this.ModelType.componentActionCreators[updateDisplay]({idDisabled: id}))
            ]);
            const {type, enabled} = yield select(state => state[detailsMap].get(id));
            const {data} = yield contextCall(this.ModelApi, 'putDetails', id, {type, enabled: !enabled});
            yield put(this.ModelType.actionCreators.updateDetails({[id]: new this.ModelType(data)}));
        } finally {
            yield all([
                put(this.ModelType.componentActionCreators[updatePane]({isDisabled: false})),
                put(this.ModelType.componentActionCreators[updateDisplay]({idDisabled: null}))
            ]);
        }
    }

    static* submitForm(action) {
        const {updateDisplay, instanceId} = this.variableNames;

        const {formData} = action.payload;
        const saveValues = yield contextCall(this, 'getSaveValues', formData, true);

        const {data} = yield contextCall(this.ModelApi, 'post', saveValues);
        const isFormActive = this.getIsFormActive(formData);

        yield all([
            put(this.ModelType.actionCreators.addDetails(data)),
            put(this.ModelType.componentActionCreators[updateDisplay]({[isFormActive]: false, [instanceId]: data.id}))
        ]);

        yield all([
            this.promptUserCredentialSignIn(data),
            // Re-query neo apps
            SchedulerSaga.queryNeoPlatformApps(),
            SchedulerSaga.queryNeoExternalApps()
        ]);
    }

    static* saveEdit(id, action) {
        const editValues = yield select(state => state.editDetails.values);
        const saveValues = yield contextCall(this, 'getSaveValues', editValues);

        const {data} = yield contextCall(this.ModelApi, 'putDetails', id, saveValues);
        yield put(this.ModelType.actionCreators.updateDetails({[id]: new this.ModelType(data)}));

        yield all([
            this.promptUserCredentialSignIn(data),
            // Re-query neo apps
            SchedulerSaga.queryNeoPlatformApps(),
            SchedulerSaga.queryNeoExternalApps()
        ]);
    }

    static* delete(action) {
        yield* super.delete(action);
        yield all([
            // Re-query neo apps
            SchedulerSaga.queryNeoPlatformApps(),
            SchedulerSaga.queryNeoExternalApps()
        ]);
    }

    static* promptUserCredentialSignIn(data) {
        if (data.service.authenticationScope === ThirdPartyServiceModel.AuthenticationScope.USER
            && ThirdPartyUserCredential.isInvalid(data.userCredential)) {

            const typeKey = lowerCaseFirstLetter(data.service.type);
            yield put(PopupModel.actionCreators.showInfo({
                info: {
                    key: `${typeKey}PromptUserSignIn`
                },
                cancelButton: {
                    titleKey: 'common:option.no'
                },
                buttons: [{
                    titleKey: 'common:option.signIn',
                    onClick: function (dispatch) {
                        dispatch(ThirdPartyServiceModel.componentActionCreators.updateTablet(prev => ({triggerUserCredentialSignIn: !prev.triggerUserCredentialSignIn})));
                    },
                }]
            }));
        }
    }

    static* getEditValues(id) {
        const {name: serviceName, userCredential, status, log, emailCounter, ...rest} = yield select(state => state.thirdPartyServiceDetailsMap.get(id));
        const editValues = {
            serviceName,
            ...rest
        };

        // Handle cases where UI component uses different data structure (i.e. elasticProperties)
        switch (rest.type) {
            case ThirdPartyServiceModel.Type.ELASTICSEARCH:
                editValues.elasticProperties = buildInputTableValuesFromMap(editValues.elasticProperties);
                break;
        }
        return editValues;
    }

    static* getSaveValues(formData) {
        const {serviceName: name, ...rest} = formData;
        const saveValues = {
            name,
            ...rest
        };

        // Handle cases where UI component uses different data structure (i.e. elasticProperties)
        switch (rest.type) {
            case ThirdPartyServiceModel.Type.ELASTICSEARCH:
                saveValues.elasticProperties = buildMapFromInputTable(saveValues.elasticProperties);
                break;
        }
        return saveValues;
    }

    static* queryDetails() {
        const response = yield contextCall(ThirdPartyServiceApi, 'getDetails');
        const key = details.THIRD_PARTY_SERVICES;

        if (AxiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(ThirdPartyServiceModel.actionCreators.setDetailsMap(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }
}

export default ThirdPartyServiceModel;
