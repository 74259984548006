import React from "react";
import {useTranslation} from "react-i18next";
import Text from "../../common/Text/Text";
import {CopyLabel} from "../../common/Common";

function OperationParameters(props) {
    const {
        formConfiguration,
        operationAlias,
        isDisabled
    } = props;

    const {operationParameters} = formConfiguration

    return (
        <div className="workflow-builder__operation-parameters-table">
            {operationParameters.map((operationParameter, index) =>
                <OperationParameterRow key={index} operationAlias={operationAlias}
                    operationParameter={operationParameter} isDisabled={isDisabled}/>
            )}
        </div>
    )
}

function OperationParameterRow(props) {
    const {t} = useTranslation(['workflowBuilder']);
    const {
        operationAlias,
        operationParameter,
        isDisabled
    } = props;
    const {
        label,
        parameter
    } = operationParameter;


    const translatedLabel = t(`workflowBuilder:${operationAlias}.OperationParameters.${label}`);

    return (
        <div className="operation-parameter-container">
            <Text className="translated-label" value={`${translatedLabel}:`}/>
            <CopyLabel value={parameter} isDisabled={isDisabled}/>
        </div>
    )
}

export default OperationParameters;