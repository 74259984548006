import {SettingsRowValue} from "../../common/CustomTable/CustomTable";
import React from "react";
import {useTranslation} from "react-i18next";
import ThirdPartyUserCredential from "../../../models/thirdparty/ThirdPartyUserCredential";
import ApiKeyUserCredentialFormBody from "../userCredential/ApiKeyUserCredentialFormBody";

function DiscoverUserCredentialFormBody(props) {
    return (
        <ApiKeyUserCredentialFormBody {...props} type={ThirdPartyUserCredential.Type.DISCOVER}
            SettingRows={DiscoverUserCredentialFormBodySettingRows}/>
    )
}

function DiscoverUserCredentialFormBodySettingRows(props) {
    const {t} = useTranslation(['thirdPartyService', 'common']);
    const {
        thirdPartyService,
        isDisabled
    } = props;

    return (
        <>
            <SettingsRowValue label={t('thirdPartyService:label.hostname')} value={thirdPartyService.hostname}
                isTight isDisabled={isDisabled}/>
        </>
    )
}

export default DiscoverUserCredentialFormBody;
