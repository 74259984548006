import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {createCloseHandler, createInputHandler, createStateHandler} from "../../utilities/componentFunctions";
import Form, {FormFooter, FormHeader} from "../common/Form/Form";
import TextArea from "../common/TextArea/TextArea";
import {SwitchItemContainer} from "../common/ListContainer/ListContainer";
import {shouldEnableClientPool} from "../../utilities/shouldEnableFunctions";
import PopupModel from "../../models/scheduler/PopupModel";
import ClientPoolModel from "../../models/settings/ClientPoolModel";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import ExpandableContent from "../common/ExpandableContent/ExpandableContent";
import {InputTableV2} from "../common/InputTable/InputTable";
import DatasetModel from "../../models/data/DatasetModel";
import KeyValueList from "../common/KeyValueList/KeyValueList";
import ParameterModel from "../../models/library/ParameterModel";
import {userSettings} from "../../utilities/constants";
import {getValues} from "../../utilities/helperFunctions";
import ClientModel from "../../models/client/ClientModel";
import {onWorkflowParameterNormalize} from "../common/InputTable/helpers";


function ClientPoolForm() {
    const {t} = useTranslation(['clientPool', 'dataset', 'common']);
    const dispatch = useDispatch();

    const {isDisabled, ...duplicateValues} = useSelector(state => state.componentStates.clientPoolForm);
    const clientDetailsMap = useSelector(state => state.clientDetailsMap);

    const [state, setState] = useState({
        clientPoolName: duplicateValues.clientPoolName || '',
        description: duplicateValues.description || '',
        isAddEnabled: duplicateValues.isAddEnabled || false
    });

    // left -> available, right -> selected
    const [clients, setClients] = useState(duplicateValues.clients || {
        left: [...getValues(clientDetailsMap).map(client => ({name: client.name, value: client.id}))],
        right: []
    });

    const userSettingsAllowedParameterValues = useSelector(state => state.userSettingsMap.get(userSettings.NEW_CLIENT_POOL)).allowedParameterValues;
    const [allowedParameterValues, setAllowedParameterValues] = useState(
        duplicateValues.allowedParameterValues || userSettingsAllowedParameterValues
    );
    const [requiredMetadataHeaders, setRequiredMetadataHeaders] = useState(duplicateValues.requiredMetadataHeaders || []);
    const [workflowParameters, setWorkflowParameters] = useState(duplicateValues.workflowParameters || []);

    const stateHandler = createStateHandler({
        updateState: setState,
        shouldEnable: shouldEnableClientPool
    });
    const inputHandler = createInputHandler({
        handler: stateHandler
    });

    const [clearParameterPasswordState, setClearParameterPasswordState] = useState({})
    const workflowParameterNormalizeHandler = onWorkflowParameterNormalize(clearParameterPasswordState, setClearParameterPasswordState);

    function onSubmit() {
        dispatch(ClientPoolModel.actionCreators.submitForm({
            ...state,
            clients,
            allowedParameterValues,
            requiredMetadataHeaders,
            workflowParameters
        }));
    }

    const onClose = createCloseHandler({
        t,
        item: t('clientPool:label.name'),
        values: {...state},
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        close: () => dispatch(ClientPoolModel.actionCreators.hideForm())
    });

    const {
        clientPoolName,
        description,
        isAddEnabled
    } = state;


    return (
        <Form onClose={onClose} isDisabled={isDisabled} closeButtonAriaLabel={t('clientPool:option.closeForm')}
            header={
                <FormHeader text={t('clientPool:label.name')} iconName={'clientPool'} isDisabled={isDisabled}/>
            }
            body={
                <>
                    <div className="display-input">
                        <HTMLTextInput label={t('common:label.name')} name={'clientPoolName'} value={clientPoolName}
                            onChange={inputHandler} isDisabled={isDisabled} isRequired
                        />
                    </div>
                    <div className="display-input">
                        <TextArea label={t('common:label.description')} name={'description'} value={description}
                            onChange={inputHandler} isDisabled={isDisabled}
                        />
                    </div>

                    <div className="display-input">
                        <SwitchItemContainer id="clientSwitchContainer" label={t('clientPool:label.clients')}
                            ariaLabelKey={'Client'} items={clients} setItems={setClients} isDisabled={isDisabled}
                        />
                    </div>

                    <div className="display-input">
                        <ExpandableContent label={t('clientPool:label.workflowParameters')} initialExpanded={false}>

                            <InputTableV2 headers={[{value: t('clientPool:header.name')}, {value: t('clientPool:header.value')}]}
                                          inputTable={workflowParameters} setInputTable={setWorkflowParameters} isDisabled={isDisabled}
                                          defaultRow={ClientModel.workflowParametersRowTemplate}
                                ariaLabelKey={'Parameter'}
                                          blurHandler={[ParameterModel.normalizeParameterName, null]} inputNormalizer={workflowParameterNormalizeHandler}
                            />
                        </ExpandableContent>
                    </div>

                    <div className="display-input">
                        <ExpandableContent label={t('client:label.allowedParameters')} initialExpanded={allowedParameterValues.length > 0}
                            headerBarStyle={{marginBottom: '0.25rem'}}
                        >
                            <KeyValueList keysTitle={t('workflow:label.parameterName')} blurHandler={ParameterModel.normalizeParameterName}
                                ariaLabelKey={'Parameter'} entries={allowedParameterValues} setEntries={setAllowedParameterValues} isMoveEnabled={true}/>
                        </ExpandableContent>
                    </div>

                    <div className="display-input">
                        <ExpandableContent label={t('dataset:label.requiredMetadataHeaders')} initialExpanded={false}>

                            <InputTableV2 headers={[{value: t('dataset:label.header')}, {value: t('dataset:label.regex')}]}
                                inputTable={requiredMetadataHeaders} setInputTable={setRequiredMetadataHeaders}
                                ariaLabelKey={'RequiredMetadata'}
                                defaultRow={DatasetModel.requiredMetadataHeadersRowTemplate} isDisabled={isDisabled}
                            />
                        </ExpandableContent>
                    </div>
                </>
            }
            footer={
                <FormFooter addText={t('common:button.addClientPool')} onAddClick={onSubmit} onCancel={onClose}
                    isAddEnabled={isAddEnabled} isDisabled={isDisabled}
                />
            }
        />
    );
}

export default ClientPoolForm;
