import React, {useEffect} from 'react';
import './JobTablet.css';
import CustomModal from '../common/CustomModal/CustomModal';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {getPriorityIcon} from '../../utilities/iconResolver';
import {FAST_QUERY_INTERVAL, jobStates} from '../../utilities/constants';
import JobModel from "../../models/job/JobModel";
import HighlightText from "../userSettings/HighlightOption/HighlightText";
import CurrentUserModel from "../../models/user/CurrentUserModel";
import {selectJobFromDisplay} from "./selectors";
import JobExecutionLogModel from "../../models/job/JobExecutionLogModel";
import JobOperationMimeTypeStatsModel from "../../models/job/JobOperationMimeTypeStatsModel";
import JobRunningLogModel from "../../models/job/JobRunningLogModel";
import Text from "../common/Text/Text";
import {UserCommentSaga} from "../../models/generics/UserCommentModel";
import {contextCall} from "../../saga/sagaFunctions";
import SchedulerModel from "../../models/scheduler/SchedulerModel";
import {call, put} from "redux-saga/effects";
import JobTabletHeader from "./JobTabletHeader";
import JobTabletBody from "./JobTabletBody";

function JobTablet(props) {
    const {t} = useTranslation(['common']);
    const dispatch = useDispatch();

    const {
        jobId
    } = props;
    const job = useSelector(state => selectJobFromDisplay(state, jobId));
    const {executionState} = job;

    // Start polling JOB_MONITORING
    useEffect(() => {
        dispatch(CurrentUserModel.actionCreators.pollActivity(CurrentUserModel.activities.JOB_MONITORING, jobId));
        return () => dispatch(CurrentUserModel.actionCreators.pollActivity(CurrentUserModel.activities.JOB_MONITORING));
    }, [jobId]);

    // Poll IFF backlog or running; else query
    useEffect(() => {
        if ([...jobStates.backlog, ...jobStates.running].includes(executionState)) {
            dispatch(JobModel.actionCreators.startPollingSettings(jobId,FAST_QUERY_INTERVAL));
        } else {
            dispatch(JobModel.actionCreators.querySettings(jobId));
        }
        return () => dispatch(JobModel.actionCreators.stopPollingSettings());
    }, [jobId, executionState]);

    useEffect(() => {
        let queryTimeout;
        if (jobStates.running.includes(executionState)) {
            dispatch(JobExecutionLogModel.actionCreators.startPollingSettings(jobId, FAST_QUERY_INTERVAL));
            dispatch(JobOperationMimeTypeStatsModel.actionCreators.startPollingSettings(jobId, FAST_QUERY_INTERVAL));
            dispatch(JobRunningLogModel.actionCreators.startPollingSettings(jobId, FAST_QUERY_INTERVAL));
        } else {
            dispatch(JobExecutionLogModel.actionCreators.querySettings(jobId));
            dispatch(JobOperationMimeTypeStatsModel.actionCreators.querySettings(jobId));
            queryTimeout = setTimeout(() => dispatch(JobExecutionLogModel.actionCreators.querySettings(jobId)), 2500);
        }

        return () => {
            clearTimeout(queryTimeout);
            dispatch(JobExecutionLogModel.actionCreators.stopPollingSettings());
            dispatch(JobOperationMimeTypeStatsModel.actionCreators.stopPollingSettings());
            dispatch(JobRunningLogModel.actionCreators.stopPollingSettings());
        }
    }, [jobId, executionState]);

    useEffect(() => {
        return () => {
            dispatch(JobExecutionLogModel.actionCreators.deleteExecutionLog());
            dispatch(JobOperationMimeTypeStatsModel.actionCreators.deleteMimeTypeStats());
            dispatch(JobRunningLogModel.actionCreators.deleteRunningLog());
        }
    }, [jobId]);

    function onClose() {
        dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(call(function* () {
            const updateEffect = put(JobModel.actionCreators.hideTablet());
            yield contextCall(UserCommentSaga, 'callCommentFormDiscardingEffect', updateEffect);
        })));
    }

    return (
        <CustomModal id="tablet" onCancel={onClose} isActive
            closeButtonAriaLabel={t('job:option.close_name', {name: job.name})}
            aria-labelledby={'jobTabletHeader'}
            header={
                <JobTabletHeader jobId={jobId}/>
            }
            body={
                <JobTabletBody jobId={jobId}/>
            }
            footer={
                <section className="jobTablet-footer"/>
            }
        />
    );
}

export function PriorityLabel(props) {
    const {t} = useTranslation(['common']);
    const {
        priority,
        priorityLabel,
        isDisabled
    } = props;

    const priorityIcon = getPriorityIcon(priority);
    const disabled = isDisabled ? ' is-disabled' : '';

    return (
        <div className="flex-center">
            {priorityIcon != null &&
                <span className={'icon is-small' + disabled} style={{marginRight: '0.25rem'}}>
                    <img src={priorityIcon} alt={t(`aria:hiddenAssistText.${priorityIcon}PriorityIcon`)}/>
                </span>
            }
            <Text isDisabled={isDisabled}>
                <HighlightText text={priorityLabel || t(`common:priority.${priority}`)}/>
            </Text>
        </div>
    )
}

export default JobTablet;
