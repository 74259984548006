import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import "./NavigatePane.css"
import CustomModal from "../CustomModal/CustomModal";
import {useTranslation} from "react-i18next";
import {ButtonGroup} from "../Button/Button";
import LoadingWrapper from "../LoadingWrapper/LoadingWrapper";
import StepIcon from "../StepIcon/StepIcon";

function NavigatePane(props) {
    const {t} = useTranslation(['common']);

    const bodyRef = useRef();
    const [position, setPosition] = useState(0);

    const {
        force,
        panes: {
            [position]: {
                title,
                header,
                component,
                backTitle,
                onBackClick,
                nextTitle,
                onNextClick,
                isNextEnabled,
                bodyStyle: paneBodyStyle
            },
            length
        },
        headerComponent,
        onClose,
        bodyStyle,
        isLoading,
        isDisabled,
        ...attr
    } = props;

    const scrollTops = useRef(Array(length).fill(0));

    // Set position if parent sets forcePosition
    useEffect(() => {
        if (force != null && force.position != null) {
            setPosition(force.position);
        }
    }, [force]);

    // Update panes scrollPosition after position update
    useLayoutEffect(() => {
        if (bodyRef.current != null) {
            bodyRef.current.scrollTop = scrollTops.current[position];
        }
    }, [position]);


    const navigateBackward = useCallback(event => {
        if (onBackClick != null) {
            onBackClick(event);
        }

        if (position > 0) {
            setPosition(prevPosition => {
                if (bodyRef.current != null) {
                    scrollTops.current[prevPosition] = bodyRef.current.scrollTop;
                }

                return prevPosition - 1;
            });
        }
    }, [position, onBackClick]);

    const navigateForward = useCallback(event => {
        if (onNextClick != null) {
            onNextClick(event);
        }

        const maxPosition = length - 1;
        if (position < maxPosition) {

            setPosition(prevPosition => {
                if (bodyRef.current != null) {
                    scrollTops.current[prevPosition] = bodyRef.current.scrollTop;
                }

                return prevPosition + 1;
            });
        }
    }, [position, onNextClick, length]);

    const close = useCallback(() => {
        onClose(position);
    }, [onClose, position]);


    const isLoadingDisabled = isLoading || isDisabled;
    const headerId = `${(attr.id || 'navigatePane')}HeaderTitle`;

    return (
        <CustomModal id="navigatePane" onCancel={close}
            isDisabled={isDisabled} isRigid={isLoadingDisabled} isActive
            aria-labelledby={headerId} {...attr}
            header={
                headerComponent || <Header id={headerId} title={title} header={header} paneCount={length}
                    stepNumber={position + 1} isDisabled={isDisabled}/>
            }
            body={
                <section id="navigatePaneBody" className="navigatePane-body" ref={bodyRef} style={paneBodyStyle || bodyStyle}>
                    <LoadingWrapper isLoading={isLoading}>
                        {component}
                    </LoadingWrapper>
                </section>
            }
            footer={
                <Footer backTitle={backTitle || t('common:option.back')} onBackClick={navigateBackward} isDisabled={isLoadingDisabled}
                    nextTitle={nextTitle || t('common:option.next')} onNextClick={navigateForward} isNextEnabled={isNextEnabled}/>
            }>
        </CustomModal>
    );
}

function Header(props) {
    const {t} = useTranslation(['common']);
    const {
        id,
        title,
        header,
        paneCount,
        stepNumber,
        isDisabled
    } = props;

    const disabled = isDisabled ? ' is-disabled' : '';
    const headerTitle = header || t('common:label.stepNumber', {stepNumber, title});

    return (
        <section id={id} className="navigatePane-header">
            <h2 className={'subtitle' + disabled}>
                {headerTitle}
            </h2>
            {paneCount > 1 &&
                <span>
                    <StepIcon current={stepNumber} total={paneCount} isDisabled={isDisabled}/>
                </span>
            }
        </section>
    )
}

function Footer(props) {
    const {backTitle, onBackClick, nextTitle, onNextClick, isNextEnabled = true, isDisabled} = props;

    const backButton = {
        id: 'formFooterBackButton',
        label: backTitle,
        onClick: onBackClick,
        isDisabled
    };
    const nextButton ={
        id: 'formFooterNextButton',
        label: nextTitle,
        onClick: onNextClick,
        isDisabled: isDisabled || !isNextEnabled
    };

    return (
        <section className="navigatePane-footer">
            <ButtonGroup buttons={[backButton, nextButton]}/>
        </section>
    )
}

export default NavigatePane;