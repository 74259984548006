import {axiosInstance} from "../models/api/AxiosProxy";

const AUTOMATE_ID = "automateId";
const AUTOMATE_USERNAME = "automateUsername";
export const AUTOMATE_USERTOKEN = "automateUserToken";
export const AUTOMATE_USERTOKEN_DATE = "automateUserTokenDate";
const REDIRECT_AFTER_OIDC_LOGIN = "automateRedirectAfterOidcLogin";
const OIDC_LOGIN_MODE = 'OIDC_LOGIN_MODE';
const OIDC_LOGIN_THIRD_PARTY_SERVICE_ID = 'OIDC_LOGIN_THIRD_PARTY_SERVICE_ID';
const OIDC_LOGIN_SCOPE = 'OIDC_LOGIN_SCOPE';

export const setRedirectAfterOidcLogin = redirectUrl => {
	localStorage.setItem(REDIRECT_AFTER_OIDC_LOGIN, redirectUrl);
};

export const getRedirectAfterOidcLogin = () => {
	return getAndRemoveItem(REDIRECT_AFTER_OIDC_LOGIN);
};

export const setUserData = (username, uiToken, tokenDate) => {
	localStorage.setItem(AUTOMATE_USERNAME, username);
	setUiToken(uiToken, tokenDate);
};

export const setUiToken = (uiToken, tokenDate=Date.now()) => {
	localStorage.setItem(AUTOMATE_USERTOKEN, uiToken);
	localStorage.setItem(AUTOMATE_USERTOKEN_DATE, tokenDate);
	axiosInstance.uiToken = uiToken;
}

export const getUserData = () => ({
	name: localStorage.getItem(AUTOMATE_USERNAME),
	uiToken: localStorage.getItem(AUTOMATE_USERTOKEN),
	tokenDate: localStorage.getItem(AUTOMATE_USERTOKEN_DATE)
});

export const clearUserData = (force) => {
	// Only remove localStorage items if tokens match
	if (force || axiosInstance.uiToken === localStorage.getItem(AUTOMATE_USERTOKEN)) {
		localStorage.removeItem(AUTOMATE_ID);
		localStorage.removeItem(AUTOMATE_USERNAME);
		localStorage.removeItem(AUTOMATE_USERTOKEN);
		localStorage.removeItem(AUTOMATE_USERTOKEN_DATE);
	}
	axiosInstance.clearAxiosToken();
};

export const setOidcLoginSearchParams = searchParams => {
	localStorage.setItem(OIDC_LOGIN_MODE, searchParams.mode);
	localStorage.setItem(OIDC_LOGIN_SCOPE, searchParams.oidcScope);
	localStorage.setItem(OIDC_LOGIN_THIRD_PARTY_SERVICE_ID, searchParams.thirdPartyServiceId);
}

export const getOidcLoginSearchParams = () => ({
	mode: getAndRemoveItem(OIDC_LOGIN_MODE),
	oidcScope: getAndRemoveItem(OIDC_LOGIN_SCOPE),
	thirdPartyServiceId: getAndRemoveItem(OIDC_LOGIN_THIRD_PARTY_SERVICE_ID)
})

const getAndRemoveItem = key => {
	const value = localStorage.getItem(key);
	localStorage.removeItem(key);
	return value;
}

export function getLocalStorageSubsetWithPrefix(prefix) {
	const subset = {};

	for (let i = 0; i < localStorage.length; i++) {
		const key = localStorage.key(i);
		if (key.match(`^${prefix}`)) {

			subset[key] = JSON.parse(window.localStorage.getItem(key));
		}
	}
	return subset;
}

export function getLocalStorageSubsetContaining(str) {
	const subset = {};

	for (let i = 0; i < localStorage.length; i++) {
		const key = localStorage.key(i);
		if (key.includes(str)) {

			subset[key] = JSON.parse(window.localStorage.getItem(key));
		}
	}
	return subset;
}