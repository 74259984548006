import React, {useCallback} from 'react';
import SearchBar from '../common/SearchBar/SearchBar';
import {useDispatch, useSelector} from 'react-redux';
import {applicationFeatures, jobCommands, updateMode} from '../../utilities/constants';
import {AddButtonHeader, Button} from "../common/Button/Button";
import {createInputHandler} from "../../utilities/componentFunctions";
import {useTranslation} from "react-i18next";
import JobModel from "../../models/job/JobModel";
import PopupModel from "../../models/scheduler/PopupModel";
import JobQueueModel from "../../models/job/JobQueueModel";
import {
    CheckedDropdown,
    ClientCheckedDropdown,
    EngineCheckedDropdown,
    MatterCheckedDropdown,
    ResourcePoolCheckedDropdown
} from "../common/Dropdown/Dropdown";
import JobQueueFilterModel from "../../models/job/JobQueueFilterModel";
import {useFilterDropdownClearHandler, useFilterDropdownHandler} from "../common/Dropdown/helpers";
import {getValues, includesSome, isNotEmptyNorFalsy} from "../../utilities/helperFunctions";
import {UserSelectableItem} from "../common/SelectableItem/SelectableItem";
import {permissionKeys, stateFilterKeys} from "../../i18next/keys";


function JobQueueHeader(props) {
    const {t} = useTranslation(['job', 'common']);
    const dispatch = useDispatch();

    const {
        text,
        'aria-label': ariaLabel,
        showJobForm,
        jobsToArchive=[],
        canQueueFeatures=[]
    } = props;

    const canQueueJob = useSelector(state => includesSome(state.currentUser.features,
        [applicationFeatures.ADD_JOB, applicationFeatures.STAGE_JOB, ...canQueueFeatures]));

    const archivableJobIds = useSelector(state => {
        return jobsToArchive.filter(id => {
            const job = state.jobDetailsMap.get(id);
            if (job != null) {
                return job.userPermissions.includes(permissionKeys.SUBMIT_JOB)
                    && (job.userPermissions.includes(permissionKeys.MODIFY) || job.userPermissions.includes(permissionKeys.MODIFY_CHILDREN));
            }
        });
    });

    const {
        searchText,
        stateFilters
    } = useSelector(state => state.componentStates.jobQueueDisplay);

    const {
        submitters,
        clientIds,
        matterIds,
        engineIds,
        resourcePoolIds
    } = useSelector(state => state.jobQueueFilter);

    const updateState = useCallback(updates =>
        dispatch(JobQueueModel.componentActionCreators.updateDisplay(updates))
    , [dispatch]);

    const inputHandler = createInputHandler({
        mode: updateMode.REDUX,
        updateState
    });

    const updateFilterState = useCallback(updates =>
            dispatch(JobQueueFilterModel.actionCreators.updateFilter(updates))
        , [dispatch]);

    const onToggle = useFilterDropdownHandler({
        updateState: updateFilterState
    });

    const onClear = useFilterDropdownClearHandler({
        updateState: updateFilterState
    });

    const onStateToggle = useFilterDropdownHandler({
        updateState
    });

    const onStateClear = useFilterDropdownClearHandler({
        updateState
    });

    function promptArchiveAll() {
        dispatch(PopupModel.actionCreators.show({
            info: {
                key: 'archiveAll'
            },
            buttons: [{
                titleKey: 'job:option.archiveAll',
                onClick: () => dispatch(JobModel.actionCreators.sendCommands(archivableJobIds, jobCommands.ARCHIVE))
            }]
        }));
    }


    return (
        <>
            <section className="header-section left">
                <div className="header-item">
                    <AddButtonHeader useH1 text={text} aria-label={ariaLabel}
                        style={{margin: '0'}} onClick={showJobForm} canModify={canQueueJob}/>
                </div>

                <div className="header-item">
                    <SubmittersCheckedDropdown submitters={submitters}
                        onToggle={onToggle} onClear={onClear}
                    />
                </div>

                <div className="header-item">
                    <ClientCheckedDropdown clientIds={clientIds}
                        onToggle={onToggle} onClear={onClear}
                    />
                </div>

                <div className="header-item">
                    <MatterCheckedDropdown matterIds={matterIds} clientIds={clientIds}
                        updateState={updateFilterState} onToggle={onToggle} onClear={onClear}
                    />
                </div>

                <div className="header-item">
                    <EngineCheckedDropdown engineIds={engineIds}
                        onToggle={onToggle} onClear={onClear}
                    />
                </div>

                <div className="header-item">
                    <ResourcePoolCheckedDropdown resourcePoolIds={resourcePoolIds}
                        onToggle={onToggle} onClear={onClear}
                    />
                </div>

                <div className="header-item">
                    <StateFilterCheckedDropdown id="queueJobStateFilters" stateFilters={stateFilters}
                        onToggle={onStateToggle} onClear={onStateClear}
                    />
                </div>
            </section>

            <section className="header-section right">
                <div className="header-item">
                    <SearchBar value={searchText} onChange={inputHandler}/>
                </div>

                {isNotEmptyNorFalsy(archivableJobIds) &&
                <div className="header-item">
                    <Button id={'jobArchiveAllButton'} label={t('job:option.archiveAll')}
                        onClick={promptArchiveAll}
                    />
                </div>
                }
            </section>
        </>
    )
}

function SubmittersCheckedDropdown(props) {
    const {t} = useTranslation(['aria', 'job']);

    const {
        name='submitters',
        submitters,
        ...rest
    } = props;

    const submitterItems = useSelector(useCallback(state => {
        return getValues(state.jobUserDetailsMap)
            .map(user => ({
                name: user.name,
                value: user.name,
                isChecked: !!submitters[user.name]
            }));
    }, [submitters]));

    return (
        <CheckedDropdown name={name} aria-label={t('aria:hiddenAssistText.jobSubmitters')}
            noneSelectedMessage={t('job:option.allUsers')} items={submitterItems}
            ItemComponent={UserSelectableItem} {...rest}
        />
    )
}

function StateFilterCheckedDropdown(props) {
    const {t} = useTranslation(['aria', 'job']);

    const {
        name='stateFilters',
        stateFilters,
        ...rest
    } = props;

    const stateFilterItems = getValues(stateFilterKeys)
        .map(stateFilter => ({
            name: t(`job:stateFilter.${stateFilter}`),
            value: stateFilter,
            isChecked: !!stateFilters[stateFilter]
        }));

    return (
        <CheckedDropdown name={name} aria-label={t('aria:hiddenAssistText.stateFilterChecked')} noneSelectedMessage={t('job:option.allStates')}
            items={stateFilterItems} {...rest}
        />
    )
}

export default JobQueueHeader;
