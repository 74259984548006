import React, {Component} from 'react';
import {connect} from 'react-redux';
import {useTranslation, withTranslation} from "react-i18next";
import Tablet, {EditTabletHeader} from '../common/Tablet/Tablet';
import {getModifyPrivilegedAction, onInputChange} from '../../utilities/componentFunctions';
import ExpandableContent, {
    ExpandableEditTextArea,
    ExpandableErrorLog,
    ExpandableInputList
} from '../common/ExpandableContent/ExpandableContent';
import {DefaultEditPanel} from '../common/EditPanel/EditPanel';
import {SettingsRowValue} from '../common/CustomTable/CustomTable';
import {onMenuOptionClick} from "../common/Dropdown/helpers";
import {onInputListAddInput, onInputListDeleteInput, onInputListInputChange} from "../common/InputList/helpers";
import {shouldEnableServer} from "../../utilities/shouldEnableFunctions";
import {permissionKeys} from "../../i18next/keys";
import {updateMode} from "../../utilities/constants";
import PopupModel from "../../models/scheduler/PopupModel";
import ServerModel from "../../models/settings/ServerModel";
import EditModel from "../../models/scheduler/EditModel";
import {makeGetEditDetails} from "../../reselect/selectors";

class ServerTablet extends Component {

    constructor(props) {
        super(props);

        this.editServer = this.editServer.bind(this);
        this.deleteServer = this.deleteServer.bind(this);

        this.onMenuOptionClick = onMenuOptionClick.bind(this);
        this.getModifyPrivilegedAction = getModifyPrivilegedAction('server', this.props.t('server:label.name')).bind(this);

        this.onEditInputChange = onInputChange({
            mode: updateMode.EDIT,
            shouldEnable: shouldEnableServer
        }).bind(this);

        this.onEditInputListAddInput = onInputListAddInput({
            mode: updateMode.EDIT,
            listName: 'whitelistedCertFingerprints'
        }).bind(this);
        this.onEditInputListDeleteInput = onInputListDeleteInput({
            mode: updateMode.EDIT,
            listName: 'whitelistedCertFingerprints',
            shouldEnable: shouldEnableServer
        }).bind(this);
        this.onEditInputListInputChange = onInputListInputChange({
            mode: updateMode.EDIT,
            listName: 'whitelistedCertFingerprints',
            shouldEnable: shouldEnableServer
        }).bind(this);
    }

    editServer() {
        this.props.editServer(this.props.serverId);
    }

    deleteServer() {
        this.props.promptDeleteServer(this.props.serverId);
    }

    render() {

        const {
            t,
            serverId,
            serverName,
            description,
            url,
            status,
            version,
            javaVersion,
            whitelistedCertFingerprints,
            error,
            userPermissions,
            isEditActive,
            isSaveEnabled,
            isDisabled
        } = this.props;

        const menuOptions = [];
        menuOptions.push({name: t('common:option.edit'), value: 'editServer', isDisabled});
        menuOptions.push({name: t('common:option.delete'), value: 'deleteServer', isDisabled});

        const descriptionProps = {
            label: t('common:label.description'),
            name: 'description',
            value: description,
            onChange: this.getModifyPrivilegedAction(this.onEditInputChange),
            isEditActive
        };
        const whitelistedCertFingerprintsProps = {
            id: 'whitelistedCertFingerprints',
            label: t('common:label.whitelistedCertFingerprints'),
            values: whitelistedCertFingerprints,
            onInputChange: this.getModifyPrivilegedAction(this.onEditInputListInputChange),
            onAddClick: this.getModifyPrivilegedAction(this.onEditInputListAddInput),
            onDeleteClick: this.getModifyPrivilegedAction(this.onEditInputListDeleteInput),
            isEditActive
        };
        const errorLogProps = {
            label: t('common:label.errors'),
            log: error
        };

        const canModifyServer = userPermissions.includes(permissionKeys.MODIFY);
        const isDescriptionVisible = isEditActive || description;
        const isWhitelistedCertFingerprintsVisible = isEditActive || whitelistedCertFingerprints.some(fingerprint => fingerprint);

        return (
            <Tablet height={isEditActive ? 'auto' : '50vh'} onClose={this.props.close}
                closeButtonAriaLabel={t('server:option.closeTablet_name', {name: serverName})}
                header={
                    <EditTabletHeader label={t('server:label.name')} type={'engineServer'}
                        id={serverId} name={'serverName'} value={serverName} inputHandler={this.onEditInputChange}
                        canModify={canModifyServer} menuOptions={menuOptions} menuOptionHandler={this.onMenuOptionClick}
                        isEditActive={isEditActive} isDisabled={isDisabled}
                    />
                }
                body={
                    <>
                        {error && !isEditActive &&
                        <div className="display-item">
                            {ExpandableErrorLog(errorLogProps)}
                        </div>
                        }

                        <div className="display-item">
                            <DefaultEditPanel isActive={isEditActive} isSaveEnabled={isSaveEnabled}
                                onSave={this.props.saveEdit} onCancel={this.props.cancelEdit}>
                                {isDescriptionVisible && <ExpandableEditTextArea {...descriptionProps}/>}

                                <ExpandableContent label={t('common:label.settings')}>
                                    <ServerSettings status={status} url={url}
                                        serverVersion={version} javaVersion={javaVersion}
                                    />
                                </ExpandableContent>

                                {isWhitelistedCertFingerprintsVisible && <ExpandableInputList {...whitelistedCertFingerprintsProps} />}
                            </DefaultEditPanel>
                        </div>
                    </>
                }
            />
        );
    }
}

const ServerSettings = props => {
    const {url, status, serverVersion, javaVersion} = props;
    const {t} = useTranslation(['server', 'common']);

    return (
        <div className="settings-table">
            <div className="table-row-group">
                {SettingsRowValue({label: t('common:label.status'), value: t(`common:status.${status}`)})}
                {SettingsRowValue({label: t('server:label.serverVersion'), value: serverVersion})}
                {SettingsRowValue({label: t('server:label.javaVersion'), value: javaVersion})}
                {SettingsRowValue({label: t('server:label.url'), value: url})}
            </div>
        </div>
    );
};

const makeMapStateToProps = () => {
    const getEditDetails = makeGetEditDetails();
    return state => {
        const {serverDetailsMap, componentStates: {serverDisplay: {serverId}}} = state;
        const {name: serverName, ...otherDetails} = serverDetailsMap.get(serverId);

        const {activeModel, values, isSaveEnabled} = getEditDetails(state, {model: ServerModel.nom});
        const isEditActive = (activeModel === ServerModel.nom);

        return {
            serverId,
            serverName,
            ...otherDetails,
            ...values,
            isEditActive,
            isSaveEnabled
        }
    }
};

const mapDispatchToProps = dispatch => {
    return {
        editServer: serverId => dispatch(ServerModel.actionCreators.startEdit(serverId)),
        promptDeleteServer: serverId => dispatch(ServerModel.actionCreators.promptDelete(serverId)),

        ...EditModel.buildDispatchers(dispatch),

        showPopup: payload => dispatch(PopupModel.actionCreators.show(payload)),
        showError: payload => dispatch(PopupModel.actionCreators.showError(payload)),
        close: () => dispatch(ServerModel.actionCreators.hideTablet())
    };
};

export default connect(makeMapStateToProps, mapDispatchToProps)(withTranslation(['server', 'common'])(ServerTablet));