import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {licenceSourceTypes, updateMode} from "../../../utilities/constants";
import {getSafeClose, onInputChange, warnOnClose} from '../../../utilities/componentFunctions';
import TextArea from '../../common/TextArea/TextArea';
import Form, {FormFooter, FormHeader} from "../../common/Form/Form";
import {shouldEnableDongleLicenceSource} from "../../../utilities/shouldEnableFunctions";
import PopupModel from "../../../models/scheduler/PopupModel";
import LicenceSourceModel from "../../../models/settings/LicenceSourceModel";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";

class DongleLicenceSourceForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sourceType: licenceSourceTypes.DONGLE,
            dongleName: '',
            description: '',
            filter: '',
            isAddEnabled: false
        };

        this.getSafeClose = getSafeClose('state', ['sourceType']).bind(this);
        this.warnOnClose = warnOnClose(this.props.t('nuixLicenceSource:label.name')).bind(this);

        this.onInputChange = onInputChange({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            isEnabled: 'isAddEnabled',
            shouldEnable: shouldEnableDongleLicenceSource
        }).bind(this);
    }

    onAddClick = () => {
        this.props.addNuixLicenceSource(this.state);
    };

    render() {

        const {
            t,
            isDisabled
        } = this.props;
        const {
            dongleName,
            description,
            filter,
            isAddEnabled
        } = this.state;
        const close = this.getSafeClose();

        const nameInputProps = {
            label: t('common:label.name'),
            name: 'dongleName',
            value: dongleName,
            onChange: this.onInputChange,
            isRequired: true,
            isDisabled
        };
        const descriptionInputProps = {
            label: t('common:label.description'),
            name: 'description',
            value: description,
            onChange: this.onInputChange,
            isDisabled
        };
        const filterInputProps = {
            label: t('nuixLicenceSource:label.filter'),
            name: 'filter',
            value: filter,
            onChange: this.onInputChange,
            isDisabled
        };

        const footerProps = {
            addText: t(`common:button.addNuixLicenseSource`),
            onAddClick: this.onAddClick,
            onCancel: close,
            isAddEnabled,
            isDisabled
        };

        return (
            <Form onClose={close} isDisabled={isDisabled} closeButtonAriaLabel={t('nuixLicenceSource:option.closeDongleForm')}
                header={
                    <FormHeader text={t('nuixLicenceSource:label.dongleName')} iconName={'dongleLicenceSource'} isDisabled={isDisabled} />
                }
                body={
                    <>
                        <div className="display-input">
                            <HTMLTextInput {...nameInputProps}/>
                        </div>
                        <div className="display-input">
                            <TextArea {...descriptionInputProps}/>
                        </div>
                        <div className="display-input">
                            <HTMLTextInput {...filterInputProps}/>
                        </div>
                    </>
                }
                footer={
                    <FormFooter {...footerProps} />
                }
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.componentStates.licenceSourceForm
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addNuixLicenceSource: nuixLicenceSource => dispatch(LicenceSourceModel.actionCreators.submitForm(nuixLicenceSource)),
        close: () => dispatch(LicenceSourceModel.componentActionCreators.updateDisplay({isDongleLicenceSourceFormActive: false})),

        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['nuixLicenceSource', 'common'])(DongleLicenceSourceForm));