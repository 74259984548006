import React from 'react';
import ExportTypeDropdown from "../../Dropdown/ExportTypeDropdown/ExportTypeDropdown";
import {NestedStateCheckBox} from "../../Question/QuestionCheckBox/QuestionCheckBox";
import ExpandableContent from "../../../../components/common/ExpandableContent/ExpandableContent";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {updateGenericValues} from "../../../redux/WizardSlice/wizardSlice";
import {ProfileQuestionSwitch} from "../../Question/QuestionSwitch/QuestionSwitch";

import '../../../wizard-general.css';
import {ExampleType} from "../../../utilities/constants";
import GenericPathExample from "../../Text/GenericPathExample/GenericPathExample";
import {createInputHandler, createValueHandler} from "../../../../utilities/componentFunctions";
import {createDropdownHandler} from "../../../../components/common/Dropdown/helpers";
import {createNestedSwitchHandler, createNestedToggleHandler} from "../../Question/QuestionCheckBox/helpers";
import WizardFileDropdown, {fileTypeFilter} from "../../Dropdown/FileDropdown/WizardFileDropdown";
import {libraryFileTypeKeys} from "../../../../i18next/keys";

function LegalExport(props) {
    const {updateState, files} = props;
    const {t} = useTranslation(['wizard']);

    const {
        exportType,
        exportFiletypes,
        useMetadataProfile,
        useProductionProfile,
        metadataProfileName,
        productionProfilePath,
        caseStructure,
        baseFolderPath,
        fieldsMappingFileType,
        fieldsMappingFile,
        metadataMappingFileType,
        metadataMappingFile,
        exportToRelativity
    } = props;

    const inputHandler = createInputHandler({
        handler: updateState
    });
    const toggleEnable = createNestedToggleHandler({
        handler: updateState
    });
    const toggleSwitch = createNestedSwitchHandler({
        handler: updateState
    })
    const dropdownHandler = createDropdownHandler({
        handler: updateState
    })
    const valuesHandler = createValueHandler({
        handler: updateState
    })

    const questions = [
        {
            question: t("wizard:questions.legalExport.legalExportType"),
        },
        {
            question: t("wizard:questions.legalExport.legalExportFiletypes"),
            dataName: "exportFiletypes",
            options: [
                {id: "exportNative", value: t("wizard:questions.legalExport.exportFiletypes.native"), checked: exportFiletypes.exportNative},
                {id: "exportText", value: t("wizard:questions.legalExport.exportFiletypes.text"), checked: exportFiletypes.exportText},
                {id: "exportPdf", value: t("wizard:questions.legalExport.exportFiletypes.pdf"), checked: exportFiletypes.exportPdf},
                {id: "exportImage", value: t("wizard:questions.legalExport.exportFiletypes.image"), checked: exportFiletypes.exportImage},
                {id: "exportXhtmlMetadataReport", value: t("wizard:questions.legalExport.exportFiletypes.xhtml"), checked: exportFiletypes.exportXhtmlMetadataReport},
                {id: "exportThumbnailFile", value: t("wizard:questions.legalExport.exportFiletypes.thumbnail"), checked: exportFiletypes.exportThumbnailFile},
            ]
        },
        {
            dataName: "advancedLegalExportOptions",
            options: [
                {
                    name: "useProductionProfile",
                    value: t("wizard:questions.legalExport.productionProfile"),
                    toggled: useProductionProfile,
                },
                {
                    name: "useMetadataProfile",
                    value: t("wizard:questions.legalExport.metadataProfile"),
                    toggled: useMetadataProfile,
                }
            ],
            components: [
                {
                    name: "productionProfilePath",
                    value: productionProfilePath,
                    isRequired: useProductionProfile,
                    placeholder: t("wizard:questions.legalExport.productionProfilePlaceholder"),
                    filter: libraryFile => fileTypeFilter(libraryFile, libraryFileTypeKeys.PRODUCTION_PROFILE),
                    onFileSelected: dropdownHandler,
                    selectedFiles: [productionProfilePath],
                    files,
                    noFileWarning: t('wizard:questions.advancedIngestionSettings.noKeywordsFiles'),
                },
                {
                    name: "metadataProfileName",
                    value: metadataProfileName,
                    isRequired: useMetadataProfile,
                    placeholder: t("wizard:questions.legalExport.metadataProfilePlaceholder"),
                    filter: libraryFile => fileTypeFilter(libraryFile, libraryFileTypeKeys.METADATA_PROFILE),
                    onFileSelected: dropdownHandler,
                    selectedFiles: [metadataProfileName],
                    files,
                    noFileWarning: t('wizard:questions.advancedIngestionSettings.noKeywordsFiles'),
                }
            ]
        },
        {
            question: t("wizard:questions.legalExport.relativity.defined"),
            dataName: "fieldsMappingFileType",
            inputQuestion: t("wizard:questions.legalExport.relativity.definedInput"),
            options: [
                {id: 1, value: t("wizard:questions.legalExport.relativity.executionProfile")},
                {id: 2, value: t("wizard:questions.legalExport.relativity.workflowExecution")},
                {id: 3, value: t("wizard:questions.legalExport.relativity.here")}
            ]
        },
        {
            question: t("wizard:questions.legalExport.ringtail.defined"),
            dataName: "metadataMappingFileType",
            inputQuestion: t("wizard:questions.legalExport.ringtail.definedInput"),
            options: [
                {id: 1, value: t("wizard:questions.legalExport.ringtail.executionProfile")},
                {id: 2, value: t("wizard:questions.legalExport.ringtail.workflowExecution")},
                {id: 3, value: t("wizard:questions.legalExport.ringtail.here")}
            ]
        },
    ]

    return (
        <div className={"wizard-step-container"}>
            {!exportToRelativity &&
            <div className={"display-item"}>
                <label className="label is-heading">
                    {questions[0].question}
                </label>
                <div className={'settings-table no-border tight-padding'} style={{border: '1px solid transparent'}}>
                    <div className="table-row-group">
                        <div key={"question-row"} className="settings-row">
                            <div key={"question-cell"} className="table-cell no-stretch">
                                <ExportTypeDropdown
                                    selectedExportTypeId={exportType}
                                    onExportTypeSelected={dropdownHandler}
                                    isRequired={true}
                                    isDisabled={false}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }

            <NestedStateCheckBox
                question={questions[1].question}
                dataName={questions[1].dataName}
                options={questions[1].options}
                onClick={toggleEnable}
                nested={"exportFiletypes"}
            />

            <div className={"dynamic-examples-container"}>
                <GenericPathExample
                    exampleType={ExampleType.EXPORT}
                    exampleTypeKey={"legalExport"}
                    caseType={caseStructure}
                    baseFolder={baseFolderPath}
                    example={"Export\\Legal Export"}/>
            </div>

            <div className={"display-item"}>
                <ExpandableContent label={t("wizard:questions.legalExport.exportProfiles")} initialExpanded={false}
                    style={{
                        marginLeft: "17px"
                    }}>

                    <ProfileQuestionSwitch
                        question={""}
                        dataName={questions[2].dataName}
                        options={questions[2].options}
                        values={questions[2].components}
                        ProfileComponent={WizardFileDropdown}
                        onClick={toggleSwitch}
                        onChange={inputHandler}/>

                </ExpandableContent>
            </div>
        </div>
    );
}

function mapStateToProps (state) {
    const {wizardSlice: {
        legalExportSettings:{exportType, exportFiletypes, useMetadataProfile, useProductionProfile, metadataProfileName, productionProfilePath, fieldsMappingFileType, fieldsMappingFile, metadataMappingFileType, metadataMappingFile},
        caseSettings: {caseStructure, baseFolderPath},
        optionalSteps: {exporting: {exportToRelativity}}}} = state;
    return {
        exportType,
        exportFiletypes,
        useMetadataProfile,
        useProductionProfile,
        metadataProfileName,
        productionProfilePath,
        caseStructure,
        baseFolderPath,
        fieldsMappingFileType,
        fieldsMappingFile,
        metadataMappingFileType,
        metadataMappingFile,
        exportToRelativity
    };
}

function mapDispatchToProps (dispatch) {
    return {
        updateState: updates => dispatch(updateGenericValues({stateName: "legalExportSettings", updates: updates}))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LegalExport);