import initialUserSettings, {jobDesignerOptionsBuilder} from "./initialUserSettings";
import ComponentStateModel from "../generics/ComponentStateModel";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import ExecutionProfileModel from "../settings/ExecutionProfileModel";
import ResourcePoolModel from "../settings/ResourcePoolModel";
import PopupModel from "../scheduler/PopupModel";
import SagaRunnable from "../generics/SagaRunnable";
import {actionCreator, getEntries, getKeys, getValues, objEquals} from "../../utilities/helperFunctions";
import {all, call, fork, put, select} from "redux-saga/effects";
import {contextCall, contextPollUntil, contextTakeLeading, contextTakeLeadingPayload} from "../../saga/sagaFunctions";
import {details, QUERY_INTERVAL, userSettings} from "../../utilities/constants";
import {updateLocale} from "../../i18next/i18n";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import {jobCardOptionKeys, localeKeys, popupInfoKeys, positionKeys, sizeKeys} from "../../i18next/keys";

class UserSettingsModel {

    static nom = 'UserSettingsModel';
    static actions = UserSettingsModel.buildActions();
    static actionCreators = UserSettingsModel.buildActionCreators(UserSettingsModel.actions);
    static reducer = UserSettingsModel.buildReducer(UserSettingsModel.actions);

    static componentActionCreators = UserSettingsModel.buildComponentActionCreators();

    static buildActions() {
        return {
            // USER SETTINGS ACTIONS
            SET_SETTINGS_MAP: 'SET_USER_SETTINGS_MAP',
            RESET_SETTINGS_MAP: 'RESET_USER_SETTINGS_MAP',

            PROMPT_RESET_SETTINGS_CATEGORY: 'PROMPT_RESET_USER_SETTINGS_CATEGORY',
            RESET_SETTINGS_CATEGORY: 'RESET_USER_SETTINGS_CATEGORY',

            PROMPT_SET_SETTINGS_AS_CATEGORY_DEFAULT: 'PROMPT_SAVE_SETTINGS_AS_CATEGORY_DEFAULT',
            SET_SETTINGS_AS_CATEGORY_DEFAULT: 'SAVE_SETTINGS_AS_CATEGORY_DEFAULT',

            PUT_CATEGORY_SETTING: 'PUT_USER_SETTINGS_CATEGORY_SETTING',
            UPDATE_CATEGORY_SETTING: 'UPDATE_USER_SETTINGS_CATEGORY_SETTING',
            // POLLING ACTIONS
            START_POLLING_SETTINGS: `START_POLLING_USER_SETTINGS`,
            STOP_POLLING_SETTINGS: `STOP_POLLING_USER_SETTINGS`
        }
    }

    static buildActionCreators(actions) {
        return {
            // USER SETTING ACTION CREATORS
            setSettingsMap: actionCreator(actions.SET_SETTINGS_MAP, 'settings'),
            resetSettingsMap: actionCreator(actions.RESET_SETTINGS_MAP),

            promptResetSettingsCategory: actionCreator(actions.PROMPT_RESET_SETTINGS_CATEGORY, 'category'),
            resetSettingsCategory: actionCreator(actions.RESET_SETTINGS_CATEGORY, 'category'),

            promptSetSettingsAsCategoryDefault: actionCreator(actions.PROMPT_SET_SETTINGS_AS_CATEGORY_DEFAULT, 'category'),
            setSettingsAsCategoryDefault: actionCreator(actions.SET_SETTINGS_AS_CATEGORY_DEFAULT, 'category'),

            putCategorySetting: actionCreator(actions.PUT_CATEGORY_SETTING, 'category', 'setting', 'value'),
            updateCategorySetting: actionCreator(actions.UPDATE_CATEGORY_SETTING, 'category', 'setting', 'value'),
            // POLLING ACTION CREATORS
            startPollingSettings: actionCreator(actions.START_POLLING_SETTINGS, 'pollingDelay'),
            stopPollingSettings: actionCreator(actions.STOP_POLLING_SETTINGS)
        }
    }

    static buildComponentActionCreators() {
        const components = [
            {
                key: 'USER_SETTINGS_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }

    static buildReducer(actions) {
        return function(state = initialUserSettings, action) {
            switch (action.type) {
                case actions.SET_SETTINGS_MAP: {
                    const {settings} = action.payload;
                    // Compare newly obtained settings with settings stored in settingsMap
                    // Build new map with result of comparison; if category equals keep oldValue reference, if category has new values keep newValue
                    const newState = new Map();
                    for (const [category, oldSetting] of state) {
                        const setting = settings[category];
                        //If setting == null, use initialUserSettings
                        let newSetting;
                        if (setting == null) {
                            newSetting = initialUserSettings.get(category);
                        } else {
                            newSetting = {
                                ...initialUserSettings.get(category),
                                ...setting
                            }
                        }

                        if (objEquals(newSetting, oldSetting)) {
                            newState.set(category, oldSetting);
                        } else {
                            newState.set(category, newSetting);
                        }
                    }

                    this.lastUpdated = Date.now();
                    UserSettingsModel.updateAccessibilityStyling(newState.get(userSettings.ACCESSIBILITY));
                    UserSettingsModel.updateAxiosInstanceSettings(newState.get(userSettings.TROUBLESHOOT));

                    // Check if there is a new reference to the same key, if there is return new map
                    for (const [key, val] of newState) {
                        if (val !== state.get(key)) {
                            return newState;
                        }
                    }
                    // If not return old
                    return state;
                }
                case actions.UPDATE_CATEGORY_SETTING: {
                    const { category, setting, value } = action.payload;

                    const oldSettings = state.get(category);
                    const newSettings = {
                        ...oldSettings,
                        [setting]: value
                    };

                    this.lastUpdated = Date.now();
                    if (category === userSettings.ACCESSIBILITY) {
                        UserSettingsModel.updateAccessibilityStyling(newSettings);
                    }
                    if (category === userSettings.TROUBLESHOOT) {
                        UserSettingsModel.updateAxiosInstanceSettings(newSettings);
                    }
                    if (!objEquals(newSettings, oldSettings)) {
                        return new Map(state).set(category, newSettings);
                    }
                    return state;
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }

    static styleElementId = 'automateAccessibilityStyling';
    static updateAccessibilityStyling(accessibility) {
        let styleElement = document.getElementById(this.styleElementId);
        if (accessibility.outlineInputsOnFocus) {
            if (styleElement == null) {
                styleElement = document.createElement('style');
                styleElement.id = this.styleElementId;
                styleElement.innerHTML = 'textarea:focus, textarea:focus-visible, input:focus, input:focus-visible {outline-style: auto;}';
                document.head.appendChild(styleElement);
            }
        } else {
            if (styleElement != null) {
                styleElement.remove();
            }
        }
    }

    // Set ignore API errors on axiosInstance
    static updateAxiosInstanceSettings(settings) {
        if (settings.maskApiGetErrors != null) {
            axiosInstance.maskGetErrors = settings.maskApiGetErrors;
        } else {
            axiosInstance.maskGetErrors = initialUserSettings.get(userSettings.TROUBLESHOOT).maskApiGetErrors;
        }
    }
}

export class UserSettingsApi {

    static getSettings() {
        return axiosInstance.get('/scheduler/userSettings');
    }

    static resetSettings() {
        return axiosInstance.del('/scheduler/userSettings');
    }

    static resetSettingsCategory(category) {
        return axiosInstance.del(`/scheduler/userSettings/${category}`)
    }

    static putCategorySetting(category, setting, value) {
        return axiosInstance.put(`/scheduler/userSettings/${category}/${setting}`, value);
    }

    static resetCategorySetting(category, setting) {
        return axiosInstance.del(`/scheduler/userSettings/${category}/${setting}`);
    }

    static putCategoryDefaultSetting(category, setting, value) {
        return axiosInstance.put(`/scheduler/defaultUserSettings/${category}/${setting}`, value)
    }
}

export class UserSettingsSaga extends SagaRunnable {

    static activationComponent = 'USER_SETTINGS_DISPLAY';

    static buildActivationEffects(dispatch) {
        return [
            // ACTIVATION EFFECTS
            contextTakeLeadingPayload(UserSettingsModel.actions.PUT_CATEGORY_SETTING, this, 'updateCategorySetting'),
            contextTakeLeading(UserSettingsModel.actions.PROMPT_RESET_SETTINGS_CATEGORY, this, 'promptCategoryReset'),
            contextTakeLeading(UserSettingsModel.actions.RESET_SETTINGS_CATEGORY, this, 'resetCategory'),
            contextTakeLeading(UserSettingsModel.actions.PROMPT_SET_SETTINGS_AS_CATEGORY_DEFAULT, this, 'promptSetSettingsAsCategoryDefault'),
            contextTakeLeading(UserSettingsModel.actions.SET_SETTINGS_AS_CATEGORY_DEFAULT, this, 'setSettingsAsCategoryDefault'),

            put(ExecutionProfileModel.actionCreators.startPollingDetails()),
            put(ResourcePoolModel.actionCreators.startPollingDetails())
        ]
    }

    static buildDeactivationEffects() {
        return [
            // DEACTIVATION EFFECTS
            put(ExecutionProfileModel.actionCreators.stopPollingDetails()),
            put(ResourcePoolModel.actionCreators.stopPollingDetails())
        ]
    }

    static* updateCategorySetting(action) {
        const {category, setting, value} = action.payload;
        yield contextCall(UserSettingsApi, 'putCategorySetting', category, setting, value);

        let val = value;
        //Have to parse because string value is sent to server with JSON.stringify string since it expects application/json
        if (category === userSettings.DEFAULT_JOB_SETTINGS) {
            val = JSON.parse(value);
        }
        if (category === userSettings.UI_LANGUAGE && setting === 'locale') {
            yield fork(updateLocale, value);
        }

        yield put(UserSettingsModel.actionCreators.updateCategorySetting(category, setting, val));
    }

    static* promptCategoryReset(action) {
        const {category} = action.payload;

        yield put(PopupModel.actionCreators.show({
            info: {
                key: popupInfoKeys.RESET_CATEGORY_TO_DEFAULT,
                valueKeys: {
                    category: categoryToName[category]
                }
            },
            buttons: [{
                titleKey: 'userSettings:option.resetToDefault',
                onClick: () => this.dispatch(UserSettingsModel.actionCreators.resetSettingsCategory(category))
            }]
        }));
    }

    static* resetCategory(action) {
        const {category} = action.payload;

        yield contextCall(UserSettingsApi, 'resetSettingsCategory', category);
        yield contextCall(this, 'updateUserSettings');
        yield contextCall(this, 'queryUserSettings');
    }

    static* promptSetSettingsAsCategoryDefault(action) {
        const {category} = action.payload;

        yield put(PopupModel.actionCreators.show({
            info: {
                key: popupInfoKeys.SET_CATEGORY_AS_DEFAULT,
                valueKeys: {
                    category: categoryToName[category]
                }
            },
            buttons: [{
                titleKey: 'userSettings:option.setAsDefault',
                onClick: () => this.dispatch(UserSettingsModel.actionCreators.setSettingsAsCategoryDefault(category))
            }]
        }));
    }

    static* setSettingsAsCategoryDefault(action) {
        const {category} = action.payload;
        const categorySettings = yield select(state => state.userSettingsMap.get(category));

        const saveEffects = [];
        getEntries(categorySettings)
            .forEach(([setting, value]) => {
                if ([userSettings.UI_LANGUAGE, userSettings.DEFAULT_JOB_SETTINGS].includes(category)) {
                    //Have to stringify because it is a direct value that is not true/false/null
                    value = JSON.stringify(value);
                }

                saveEffects.push(contextCall(UserSettingsApi, 'putCategoryDefaultSetting', category, setting, value));
            });

        yield all(saveEffects);
    }

    static* updateUserSettings() {
        const userSetting = (yield contextCall(UserSettingsApi, 'getSettings')).data;
        const {[userSettings.JOB_CARD]: jobCardSetting, [userSettings.DATASET_OPTION]: datasetSetting, [userSettings.UI_LANGUAGE]: uiLanguageSetting} = userSetting;

        const effects = [];
        // Migrate uiLanguage setting if not exist in ui
        if (uiLanguageSetting != null && getValues(localeKeys).every(locale => uiLanguageSetting.locale !== locale)) {
            effects.push(contextCall(UserSettingsApi, 'putCategorySetting', userSettings.UI_LANGUAGE, 'locale', null));
        }

        // Migrate datasetBuiltInHeaders
        if (datasetSetting != null && datasetSetting.builtInHeaders != null) {
            const {uploadedBy, uploadTime, ...updatedBuiltInHeaders} = datasetSetting.builtInHeaders;

            if (uploadedBy != null) {
                updatedBuiltInHeaders.addedBy ||= uploadedBy;
            }
            if (uploadTime != null) {
                updatedBuiltInHeaders.addedDate ||= uploadTime;
            }
            if (uploadedBy != null || uploadTime != null) {
                effects.push(contextCall(UserSettingsApi, 'putCategorySetting', userSettings.DATASET_OPTION, 'builtInHeaders', updatedBuiltInHeaders));
            }
        }

        // Migrate jobCardSettings
        const updatedSettings = [];
        const keys = getKeys(jobCardSetting);

        for (let i = 0; i < keys.length; i++) {
            const setting = keys[i];
            const value = jobCardSetting[setting];

            if (isNaN(setting) && value != null) {
                const newValue = {
                    ...value,
                    type: setting
                };

                effects.push(contextCall(UserSettingsApi, 'putCategorySetting', userSettings.JOB_CARD, setting, null));
                effects.push(contextCall(UserSettingsApi, 'putCategorySetting', userSettings.JOB_CARD, i, newValue));
                updatedSettings.push(newValue);
            }
        }

        // Set default JobCard settings
        if (jobCardSetting == null || updatedSettings.length > 0) {
            let i = updatedSettings.length;

            if (updatedSettings.every(opt => opt.type !== jobCardOptionKeys.JOB_NAME && opt.position !== positionKeys.TOP_LEFT)) {
                const value = jobDesignerOptionsBuilder(Date.now(), jobCardOptionKeys.JOB_NAME, positionKeys.TOP_LEFT, sizeKeys.SMALL, true);
                effects.push(contextCall(UserSettingsApi, 'putCategorySetting', userSettings.JOB_CARD, i, value));
                i++;
            }
            if (updatedSettings.every(opt => opt.type !== jobCardOptionKeys.JOB_NOTES && opt.position !== positionKeys.BOTTOM_LEFT)) {
                const value = jobDesignerOptionsBuilder(Date.now(), jobCardOptionKeys.JOB_NOTES, positionKeys.BOTTOM_LEFT, sizeKeys.SMALL, false);
                effects.push(contextCall(UserSettingsApi, 'putCategorySetting', userSettings.JOB_CARD, i, value));
                i++;
            }
            if (updatedSettings.every(opt => opt.type !== jobCardOptionKeys.RESOURCE_POOL_NAME && opt.position !== positionKeys.TOP_RIGHT)) {
                const value = jobDesignerOptionsBuilder(Date.now(), jobCardOptionKeys.RESOURCE_POOL_NAME, positionKeys.TOP_RIGHT, sizeKeys.SMALL, false);
                effects.push(contextCall(UserSettingsApi, 'putCategorySetting', userSettings.JOB_CARD, i, value));
                i++;
            }
            if (updatedSettings.every(opt => opt.type !== jobCardOptionKeys.STATUS_ICONS && opt.position !== positionKeys.BOTTOM_RIGHT)) {
                const value = jobDesignerOptionsBuilder(Date.now(), jobCardOptionKeys.STATUS_ICONS, positionKeys.BOTTOM_RIGHT, sizeKeys.SMALL, false);
                effects.push(contextCall(UserSettingsApi, 'putCategorySetting', userSettings.JOB_CARD, i, value));
                i++;
            }
        }

        yield all(effects);
    }

    static* pollSettings() {
        yield contextPollUntil(UserSettingsModel.actions.STOP_POLLING_SETTINGS, QUERY_INTERVAL, this, 'queryUserSettings');
    }

    static* queryUserSettings() {
        const response = yield contextCall(UserSettingsApi, 'getSettings');
        const key = details.USER_SETTINGS;

        if (AxiosProxy.shouldUpdate(key, response)) {
            const jobCardSettings = response.data[userSettings.JOB_CARD];
            if (jobCardSettings != null) {
                // Filter out nulls in jobCardDesigner refactor
                response.data[userSettings.JOB_CARD] = getKeys(jobCardSettings)
                    .reduce((acc, key) => {
                        const value = jobCardSettings[key];

                        if (value != null) {
                            acc[key] = value;
                        }
                        return acc;
                    }, {});
            }

            yield all([
                put(UserSettingsModel.actionCreators.setSettingsMap(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);

            const userSettingsMap = yield select(state => state.userSettingsMap);
            yield call(updateLocale, userSettingsMap.get(userSettings.UI_LANGUAGE).locale);
        }
    }
}

const categoryToName = {
    [userSettings.UI_LANGUAGE]: '$t(uiLanguage:title)',
    [userSettings.SHOW_DISABLED_ITEMS]: '$t(showDisabledItems:title)',
    [userSettings.JOB_CARD]: '$t(jobCardDesigner:title)',
    [userSettings.QUEUE_JOB]: '$t(queueJobDesigner:title)',
    [userSettings.DEFAULT_JOB_SETTINGS]: '$t(defaultValues:title)',
    [userSettings.JOB_QUEUE_SORT]: '$t(jobQueueSort:title)',
    [userSettings.TROUBLESHOOT]: '$t(troubleshoot:title)',
    [userSettings.HIGHLIGHTS]: '$t(highlights:title)'
};

export default UserSettingsModel;