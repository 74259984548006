import SagaRunnable from "../generics/SagaRunnable";
import {all, put, select} from "redux-saga/effects";
import {actionCreator, objectTruthyValues} from "../../utilities/helperFunctions";
import ReduxModel from "../generics/ReduxModel";
import {contextCall, contextFork, contextTakeLatest} from "../../saga/sagaFunctions";
import {axiosInstance} from "../api/AxiosProxy";
import {JobSaga} from "./JobModel";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import {details} from "../../utilities/constants";

class JobQueueFilterModel extends ReduxModel {

    static nom = 'JobQueueFilterModel';
    static actions = JobQueueFilterModel.buildActions('JOB_QUEUE_FILTER_MODEL');
    static actionCreators = JobQueueFilterModel.buildActionCreators(JobQueueFilterModel.actions);
    static reducer = JobQueueFilterModel.buildReducer(JobQueueFilterModel.actions);

    constructor(model={}) {
        super();
        const {submitters, clientIds, matterIds, engineIds, resourcePoolIds} = model;

        function arrayIntoTruthyObj(arr) {
            return (arr || []).reduce((obj, e) => {
                obj[e] = true;

                return obj;
            }, {});
        }

        this.submitters = arrayIntoTruthyObj(submitters);
        this.clientIds = arrayIntoTruthyObj(clientIds);
        this.matterIds = arrayIntoTruthyObj(matterIds);
        this.engineIds = arrayIntoTruthyObj(engineIds);
        this.resourcePoolIds = arrayIntoTruthyObj(resourcePoolIds);
    }

    static buildActions() {
        return {
            // ACTIONS
            SET_FILTER: 'SET_JOB_QUEUE_FILTER',
            UPDATE_FILTER: 'UPDATE_JOB_QUEUE_FILTER',
            CLEAR_FILTER: 'CLEAR_JOB_QUEUE_FILTER'
        }
    }

    static buildActionCreators(actions) {
        return {
            // ACTION CREATORS
            setFilter: actionCreator(actions.SET_FILTER, 'filter'),
            updateFilter: actionCreator(actions.UPDATE_FILTER, 'updates'),
            clearFilter: actionCreator(actions.CLEAR_FILTER, 'name')
        }
    }

    static buildReducer(actions) {
        return function (state = new this(), action) {
            switch (action.type) {
                case actions.SET_FILTER: {
                    const {filter} = action.payload;

                    return new this(filter);
                }
                case actions.UPDATE_FILTER: {
                    const {updates} = action.payload;

                    const update = (typeof updates === 'function') ? updates(state) : updates;
                    return state.duplicate(update);
                }
                case actions.CLEAR_FILTER: {
                    const {name} = action.payload;

                    return state.duplicate({[name]: {}});
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }
}

class JobQueueFilterApi {

    static getDetails() {
        return axiosInstance.get('/scheduler/jobs/filter');
    }

    static post(filter) {
        return axiosInstance.post('/scheduler/jobs/filter', filter);
    }
}

export class JobQueueFilterSaga extends SagaRunnable {

    static activationComponent = 'JOB_QUEUE_DISPLAY';

    static buildActivationEffects() {
        return [
            contextFork(this, 'queryDetails'),
            contextTakeLatest([JobQueueFilterModel.actions.UPDATE_FILTER, JobQueueFilterModel.actions.CLEAR_FILTER], this, 'updateModel'),

            contextTakeLatest(JobQueueFilterModel.actions.SET_FILTER, JobSaga, 'queryDetails')
        ];
    }

    static* updateModel() {
        const {submitters, clientIds, matterIds, engineIds, resourcePoolIds} = yield select(state => state.jobQueueFilter);
        const filter = {
            submitters: objectTruthyValues(submitters),
            clientIds: objectTruthyValues(clientIds),
            matterIds: objectTruthyValues(matterIds),
            engineIds: objectTruthyValues(engineIds),
            resourcePoolIds: objectTruthyValues(resourcePoolIds)
        };

        const {data} = yield contextCall(JobQueueFilterApi, 'post', filter);
        yield put(JobQueueFilterModel.actionCreators.setFilter(data));
    }

    static* queryDetails() {
        const {data} = yield contextCall(JobQueueFilterApi, 'getDetails');

        yield all([
            put(JobQueueFilterModel.actionCreators.setFilter(data)),
            put(ReduxStateModel.actionCreators.setHasLoaded(details.QUEUE_FILTER))
        ]);
    }
}

export default JobQueueFilterModel;
