import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import React, {useRef, useState} from "react";
import {createCloseHandler, createInputHandler, createStateHandler} from "../../../utilities/componentFunctions";
import PopupModel from "../../../models/scheduler/PopupModel";
import Form, {FormHeader} from "../../common/Form/Form";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import TextArea from "../../common/TextArea/TextArea";
import {ValueList} from "../../common/InputList/InputList";
import {ButtonGroup} from "../../common/Button/Button";
import UserServiceModel from "../../../models/user/UserServiceModel";
import {ldapSearchScopeKeys, loginLinkScopeKeys} from "../../../i18next/keys";
import Checkbox from "../../common/Checkbox/Checkbox";
import {LdapSearchScopeDropdown, LoginLinkScopeDropdown} from "../../common/Dropdown/Dropdown";
import {createDropdownHandler} from "../../common/Dropdown/helpers";
import {createToggleHandler} from "../../common/Checkbox/helpers";
import {FormElementRow} from "../../common/CustomTable/CustomTable";
import Text, {Label} from "../../common/Text/Text";
import {useClearOnFirstCallback} from "../../../utilities/formHooks";


function LdapUserServiceForm() {
    const {t} = useTranslation(['userService', 'common']);
    const dispatch = useDispatch();

    const {
        isDisabled
    } = useSelector(state => state.componentStates.userServiceForm);

    const [state, setState] = useState({
        type: UserServiceModel.types.LDAP,
        name: '',
        description: '',
        enabled: true,
        restrictByUrl: false,
        allowedUrls: [],

        serviceAccountName: '',
        searchDelay: 30,
        userBaseDn: '',
        userSearchScope: ldapSearchScopeKeys.SUB,
        computerBaseDn: '',
        computerSearchScope: ldapSearchScopeKeys.SUB,
        ldapHost: '',
        ldapPort: 389,
        domainDn: '',
        secureLdap: false,
        synchronizeUsers: false,
        synchronizeComputers: false,

        enableLoginLinks: false,
        loginLinkScope: loginLinkScopeKeys.LEGAL_HOLD,
        // hours
        loginLinkAutoExpireInterval: 4,

        enableAuthentication: true,
        enableCollections: true,
        usersEligibleLegalHoldAdministrator: true,
        usersEligibleLegalHoldCustodian: true,
        isAddEnabled: false
    });
    const [whitelistedCertFingerprints, setWhitelistedCertFingerprints] = useState(['']);

    const [allowedUrls, setAllowedUrls] = useState([]);

    const serviceAccountPasswordRef = useRef({});
    const stateHandler = createStateHandler({
        updateState: setState,
        passwordRef: serviceAccountPasswordRef,
        passwordName: 'serviceAccountPassword',
        passwordResetNames: ['serviceAccountName', 'userBaseDn', 'computerBaseDn', 'ldapHost', 'ldapPort', 'domainDn', 'secureLdap'],
        shouldEnable: UserServiceModel.validateFormData
    });
    const inputHandler = createInputHandler({
        handler: stateHandler
    })
    const dropdownHandler = createDropdownHandler({
        handler: stateHandler
    });
    const toggleHandler = createToggleHandler({
        handler: stateHandler
    });

    function onSubmit() {
        dispatch(UserServiceModel.actionCreators.submitForm({
            ...state,
            whitelistedCertFingerprints, allowedUrls
        }));
    }

    const onClose = createCloseHandler({
        t,
        item: t('userService:label.name'),
        values: state,
        blacklist: ['type', 'ldapPort', 'searchDelay', 'userSearchScope', 'computerSearchScope', 'loginLinkScope', 'loginLinkAutoExpireInterval'],
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        close: () => dispatch(UserServiceModel.actionCreators.hideForm(UserServiceModel.types.LDAP))
    });

    const {
        name,
        description,
        enabled,
        restrictByUrl,

        serviceAccountName,
        searchDelay,
        userBaseDn,
        userSearchScope,
        computerBaseDn,
        computerSearchScope,
        ldapHost,
        ldapPort,
        domainDn,
        secureLdap,
        synchronizeUsers,
        synchronizeComputers,

        usersEligibleLegalHoldAdministrator,
        usersEligibleLegalHoldCustodian,

        enableLoginLinks,
        loginLinkScope,
        loginLinkAutoExpireInterval,

        isAddEnabled
    } = state;

    const disabled = isDisabled ? ' is-disabled' : '';
    const isUserSyncRequired = synchronizeUsers && !computerBaseDn;
    const isComputerSyncRequired = synchronizeComputers && !userBaseDn;

    return (
        <Form onClose={onClose} isDisabled={isDisabled} closeButtonAriaLabel={t('userService:option.closeFormLdap')}
              header={
                  <FormHeader text={t('userService:label.ldapUserServiceTitle')} iconName={`userServiceLdap`} isDisabled={isDisabled} />
              }
              body={
                  <>
                      <div className="display-input">
                          <HTMLTextInput label={t('common:label.name')} name={'name'} value={name} onChange={inputHandler}
                                         isDisabled={isDisabled} isRequired/>
                      </div>

                      <Checkbox label={t('common:label.enabled')} name={'enabled'} checked={enabled} onClick={toggleHandler}
                          isDisabled={isDisabled}/>

                      <div className="display-input">
                          <TextArea label={t('common:label.description')} name={'description'} value={description} onChange={inputHandler}
                                    isDisabled={isDisabled}/>
                      </div>

                      <div className="display-input">
                          <Checkbox label={t('userService:label.restrictByUrl')} name={'restrictByUrl'}
                                    checked={restrictByUrl} onClick={toggleHandler}
                                    isDisabled={isDisabled}/>
                      </div>

                      {restrictByUrl &&
                          <div className="display-input">
                              <ValueList id={'allowedUrls'} label={t('userService:label.allowedUrls')}
                                         values={allowedUrls} setValues={setAllowedUrls}
                                         ariaLabelKey={'AllowedUrls'} isDisabled={isDisabled}/>
                          </div>
                      }

                      <div className="display-input">
                          <HTMLTextInput label={t('userService:label.domainDn')} name={'domainDn'} placeholder={"DC=lab,DC=local"}
                                         value={domainDn} onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                      </div>

                      <div className="display-input" style={{display: 'flex', justifyContent: 'space-between'}}>
                          <div style={{width: 'calc(100% - 7.5rem'}}>
                            <HTMLTextInput label={t('userService:label.host')} name={'ldapHost'} placeholder={"127.0.0.1"}
                                         value={ldapHost} onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                          </div>

                          <div style={{width: '5rem'}}>
                          <HTMLTextInput label={t('userService:label.port')} name={'ldapPort'} type={"number"}
                                         value={ldapPort} onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                          </div>
                      </div>

                      <div className="display-input">
                          <Checkbox label={t('userService:label.secureLdap')} name={"secureLdap"} checked={secureLdap}
                              onClick={toggleHandler} isDisabled={isDisabled}/>
                      </div>

                      <div className="display-input">
                          <Checkbox label={t('userService:label.synchronizeUsers')} name={"synchronizeUsers"} checked={synchronizeUsers}
                              onClick={toggleHandler} isDisabled={isDisabled}/>
                      </div>

                      {synchronizeUsers &&
                          <>
                              <SynchronizeUsersFormBody usersEligibleLegalHoldAdministrator={usersEligibleLegalHoldAdministrator}
                                  usersEligibleLegalHoldCustodian={usersEligibleLegalHoldCustodian} toggleHandler={toggleHandler} isDisabled={isDisabled}/>

                              <div className="display-input">
                                  <HTMLTextInput label={t('userService:label.userBaseDn')} name={'userBaseDn'} placeholder={"CN=Users,DC=lab,DC=local"}
                                                 value={userBaseDn} onChange={inputHandler} isDisabled={isDisabled} isRequired={isUserSyncRequired}/>
                              </div>

                              <div className="display-input" style={{display: 'flex', alignItems: 'center'}}>
                                  <label className={'label' + disabled}>
                                      {t('userService:label.userSearchScope')}:
                                  </label>

                                  <div style={{margin: '0 0.35rem 0 0.75rem'}}>
                                      <LdapSearchScopeDropdown id={"userSearchScopeDropdown"} name={'userSearchScope'} selectedSearchScope={userSearchScope.toString()}
                                                               onSearchScopeSelect={dropdownHandler} isDisabled={isDisabled}/>
                                  </div>
                              </div>
                          </>
                      }

                      <div className="display-input">
                          <Checkbox label={t('userService:label.synchronizeComputers')} name={"synchronizeComputers"} checked={synchronizeComputers}
                                    onClick={toggleHandler} isDisabled={isDisabled}/>
                      </div>

                      {synchronizeComputers &&
                        <>
                            <div className="display-input">
                                <HTMLTextInput label={t('userService:label.computerBaseDn')} name={'computerBaseDn'} placeholder={"CN=Computers,DC=lab,DC=local"}
                                               value={computerBaseDn} onChange={inputHandler} isDisabled={isDisabled} isRequired={isComputerSyncRequired}/>
                            </div>

                            <div className="display-input" style={{display: 'flex', alignItems: 'center'}}>
                                <label className={'label' + disabled}>
                                    {t('userService:label.computerSearchScope')}:
                                </label>

                                <div style={{margin: '0 0.35rem 0 0.75rem'}}>
                                    <LdapSearchScopeDropdown id={"computerSearchScopeDropdown"} name={'computerSearchScope'} selectedSearchScope={computerSearchScope.toString()}
                                                             onSearchScopeSelect={dropdownHandler} isDisabled={isDisabled}/>
                                </div>
                            </div>
                        </>
                      }

                      {(synchronizeUsers || synchronizeComputers) &&
                          <SynchronizationFormBody type={UserServiceModel.types.LDAP} serviceAccountName={serviceAccountName} serviceAccountPasswordRef={serviceAccountPasswordRef} searchDelay={searchDelay}
                              serviceAccountNamePlaceHolder={"DOMAIN\\Administrator"} inputHandler={inputHandler} isDisabled={isDisabled}/>
                      }

                      <LinkLoginFormBody enableLoginLinks={enableLoginLinks} loginLinkScope={loginLinkScope} loginLinkAutoExpireInterval={loginLinkAutoExpireInterval}
                          inputHandler={inputHandler} toggleHandler={toggleHandler} dropdownHandler={dropdownHandler} isDisabled={isDisabled}/>

                      <div className="display-input">
                          <ValueList id={'whitelistedCertFingerprints'} label={t('common:label.whitelistedCertFingerprints')}
                              values={whitelistedCertFingerprints} setValues={setWhitelistedCertFingerprints}
                              ariaLabelKey={'WhitelistedCertFingerprint'} isDisabled={isDisabled}/>
                      </div>
                  </>
              }
              footer={
                  <ButtonGroup buttons={[{
                      id: 'formFooterBackButton',
                      label: t('common:option.cancel'),
                      onClick: onClose,
                      isDisabled
                  },{
                      id: 'formFooterNextButton',
                      label: t('userService:option.addUserService'),
                      onClick: onSubmit,
                      isDisabled: isDisabled || !isAddEnabled
                  }]}/>
              }
        />
    )
}

export function SynchronizeUsersFormBody(props) {
    const {t} = useTranslation(['userService']);
    const {
        usersEligibleLegalHoldAdministrator,
        usersEligibleLegalHoldCustodian,
        toggleHandler,
        isDisabled
    } = props;

    return (
        <>
            <div className="display-input">
                <Checkbox label={t('userService:label.usersEligibleLegalHoldAdministrator')} name={"usersEligibleLegalHoldAdministrator"}
                    checked={usersEligibleLegalHoldAdministrator} onClick={toggleHandler} isDisabled={isDisabled}/>
            </div>

            <div className="display-input">
                <Checkbox label={t('userService:label.usersEligibleLegalHoldCustodian')} name={"usersEligibleLegalHoldCustodian"}
                    checked={usersEligibleLegalHoldCustodian} onClick={toggleHandler} isDisabled={isDisabled}/>
            </div>
        </>
    )
}

export function SynchronizationFormBody(props) {
    const {t} = useTranslation(['userService', 'common']);
    const {
        type,
        serviceAccountName,
        serviceAccountNamePlaceHolder,
        serviceAccountPassword,
        serviceAccountPasswordRef,
        searchDelay,
        inputHandler,
        isDisabled
    } = props;

    const requiresServiceAccount = UserServiceModel.doesTypeRequireServiceAccount(type);
    const disabled = isDisabled ? ' is-disabled': '';
    const clearOnFirstCallback = useClearOnFirstCallback();

    return (
        <>
            <div className="forms-table">
                <div className="table-row-group">

                    <FormElementRow labelId={'userService:searchDelay'} label={t('userService:label.searchDelay')} isDisabled={isDisabled}
                        element={
                            <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                <HTMLTextInput id={'searchDelay'} name={'searchDelay'} value={searchDelay} isInvalid={searchDelay == 0}
                                    onChange={inputHandler} type="number" style={{width: '5rem', marginRight: '0.5rem'}}
                                    aria-labelledby={'userService:searchDelay userService:minutes'} isDisabled={isDisabled}
                                />

                                <Label id={'userService:minutes'} value={t('common:label.minutes')}
                                    isDisabled={isDisabled}/>
                            </div>
                        }/>
                </div>
            </div>

            {requiresServiceAccount &&
                <>
                    <div className="display-input">
                        <HTMLTextInput label={t('userService:label.serviceAccountName')} name={'serviceAccountName'} value={serviceAccountName}
                            onChange={inputHandler} isDisabled={isDisabled} placeholder={serviceAccountNamePlaceHolder} isRequired/>
                    </div>

                    <div className="display-input">
                        <HTMLTextInput label={t('userService:label.serviceAccountPassword')} name={'serviceAccountPassword'} type={'password'}
                            inputRef={serviceAccountPasswordRef} defaultValue={serviceAccountPassword} onChange={inputHandler}
                            onBeforeInput={clearOnFirstCallback} isDisabled={isDisabled} isRequired/>
                    </div>
                </>
            }
        </>
    )
}

export function LinkLoginFormBody(props) {
    const {forceLoginLinks, enableLoginLinks=forceLoginLinks, loginLinkScope, loginLinkAutoExpireInterval, inputHandler, toggleHandler, dropdownHandler, isDisabled} = props;
    const {t} = useTranslation(['userService']);

    return (
        <>
            {!forceLoginLinks &&
                <div className="display-input">
                    <Checkbox containerId={'ldapEnableLoginLinks'} label={t('userService:label.enableLoginLinks')} name={"enableLoginLinks"} checked={enableLoginLinks}
                        onClick={toggleHandler} isDisabled={isDisabled}/>
                </div>
            }

            {enableLoginLinks &&
            <div className="forms-table">
                <div className="table-row-group">
                    <FormElementRow label={t('userService:label.loginLinkScope')} isDisabled={isDisabled}
                        element={
                            <LoginLinkScopeDropdown value={loginLinkScope} onItemSelect={dropdownHandler}
                                isDisabled={isDisabled} isRequired/>
                        }/>

                    <FormElementRow htmlFor={'loginLinkAutoExpireInterval'} label={t('userService:label.loginLinkAutoExpireInterval')} isDisabled={isDisabled}
                        element={
                            <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                <HTMLTextInput id={'loginLinkAutoExpireInterval'} name={'loginLinkAutoExpireInterval'} value={loginLinkAutoExpireInterval} min={0}
                                    isInvalid={parseFloat(loginLinkAutoExpireInterval) < 0} onChange={inputHandler} type="number" style={{width: '5rem', marginRight: '0.5rem'}} isDisabled={isDisabled}
                                />

                                <Text value={t('common:duration.hours_plural')} isDisabled={isDisabled}/>
                            </div>
                        }/>
                </div>
            </div>
            }
        </>
    )
}

export default LdapUserServiceForm;
