import React, {useRef} from 'react';
import './Button.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {buildClassName, openLinkNewTab} from "../../../utilities/helperFunctions";
import {useTranslation} from "react-i18next";
import Text from "../Text/Text";
import {icon} from "../../../utilities/iconResolver";
import {createFileHandler} from "../../../utilities/componentFunctions";
import Papa from "papaparse";
import {useGetMissingClasses} from "../AccessibilityAssist";

export const Button = React.forwardRef(function (props, ref) {
    const {
        label,
        text=label,
        'aria-label': ariaLabel,
        ignoreAccessibility,

        onClick,
        icon,
        children,
        className,
        labelStyle,

        isImg,
        isClear,
        isSquare,
        isBold,
        isEllipsis,
        isFullWidth,
        isDisabled,
        isInvalid,
        isRequired,
        isSelected,
        isWhite,
        ...attr
    } = props;


    const invalid = isInvalid && !isDisabled;
    const required = isRequired && !isDisabled;

    const classes = buildClassName(
        'button',
        isImg && 'is-img',
        isClear && 'is-clear',
        isWhite && 'is-white',
        isSquare && 'is-square',
        isFullWidth && 'is-fullwidth',
        icon && 'is-button-icon',
        required && 'is-required',
        invalid && 'is-invalid',
        isSelected && 'is-selected',
        !ignoreAccessibility && useGetMissingClasses(text, {hasContext: !!children && !isImg, ariaLabel, ariaLabelledBy: attr['aria-labelledby']}),
        className
    );

    return (
        <button type="button" ref={ref} className={classes} onClick={onClick} disabled={isDisabled}
            aria-invalid={required || invalid} aria-label={ariaLabel} title={isImg ? ariaLabel : null} {...attr}
        >
            {text &&
                <Text ElementTag="span" value={text} isBold={isBold} isEllipsis={isEllipsis}
                    style={labelStyle} isDisabled={isDisabled}/>
            }

            {children}
            {icon &&
                <span>
                    {icon}
                </span>
            }

            {/*{!text && ariaLabel &&*/}
            {/*    <VisuallyHiddenAssistText text={ariaLabel}/>*/}
            {/*}*/}
        </button>
    )
});

export function AddButtonHeader(props) {
    const {t} = useTranslation(['aria', 'common']);
    const {id, text, useH1, canModify, children, style, ...attr} = props;

    return (
        <div id={id} className="add-button-header" style={style}>
            {canModify &&
                <Button text={t('common:option.add')} {...attr}>
                    <span className="icon">
                        <FontAwesomeIcon icon="plus"/>
                    </span>
                </Button>
            }
            {useH1 ?
                <h1 className="default-font is-heading">
                    {text}
                </h1>
                :
                <Text value={text} isHeading isDisabled={attr.isDisabled}/>
            }
            {children}
        </div>
    )
}

export function ButtonGroup(props) {
    const {buttons, ...attr} = props;

    return (
        <div className="button-group" {...attr}>
            {buttons
                .filter(e => e)
                .map((bouton, i) =>
                    <Button key={i} {...bouton} />
                )}
        </div>
    )
}

export function HelpButton(props) {
    const {t} = useTranslation(['aria']);
    const {
        helpers,
        value,
        isDisabled
    } = props;

    function onHelpClick() {
        let urlItems = [window.location.origin + window.location.pathname+"resources/en_US/workflow-design-guide.html"];

        if (helpers.length === 1) {
            urlItems.push(helpers[0].location);
        } else {
            helpers.some(helper => {
                if (helper.values[0] === value) {
                    urlItems.push(helper.location);
                }
            })
        }

        const url = urlItems.join("");
        openLinkNewTab(url);
    }

    const isHidden = !helpers.some(helper => (helper.values.length === 0 || helper.values[0] === value));
    if (isHidden) {
        return null;
    }

    const helpButtonAriaLabel = t('common:label.help');

    return (
        <Button name="helpButton" aria-label={helpButtonAriaLabel} isImg
            onClick={onHelpClick} isDisabled={isDisabled}
            icon={
                <img src={icon("operationHelper")} alt={t(`aria:hiddenAssistText.userGuideIcon`)}/>
        }/>
    )
}

export function AddRemoveButtons(props) {
    const {t} = useTranslation(['aria']);

    const {
        ariaLabelKey,
        labelId,
        label,
        afterLabel,

        onAddClick,
        onRemoveClick,
        onEditClick,
        isAddDisabled,
        isRemoveDisabled,
        isEditDisabled,
        isBold,
        isReadOnly,
        isHeading,
        isDisabled,
        noPadding,
        className,
        ...attr
    } = props;

    const mainClasses = buildClassName(
        'add-remove-buttons',
        isReadOnly && 'is-read-only',
        noPadding && 'no-padding'
    );
    const labelClasses = buildClassName(
        'label',
        isBold && 'is-bold',
        isHeading && 'is-heading',
        isReadOnly && 'is-read-only',
        isDisabled && 'is-disabled'
    );


    return (
        <section className={mainClasses} {...attr}>
            {!isReadOnly &&
            <>
                <Button name="addButton" isImg aria-label={t(`aria:hiddenAssistText.add${ariaLabelKey}`)}
                    onClick={onAddClick} isDisabled={isDisabled || isAddDisabled}
                >
                    <FontAwesomeIcon icon="plus"/>
                </Button>

                <Button name="removeButton" isImg aria-label={t(`aria:hiddenAssistText.remove${ariaLabelKey}`)}
                    onClick={onRemoveClick} isDisabled={isDisabled || isRemoveDisabled}
                >
                    <FontAwesomeIcon icon="minus"/>
                </Button>

                {onEditClick &&
                    <Button name="editButton" isImg aria-label={t(`aria:hiddenAssistText.edit${ariaLabelKey}`)}
                        onClick={onEditClick} isDisabled={isDisabled || isEditDisabled}
                        icon={
                        <img src={icon("edit")} alt={t('aria:hiddenAssistText.editIcon')}/>
                    }/>
                }
            </>
            }
            {label &&
                <label id={labelId} className={labelClasses}>{label}</label>
            }
            {afterLabel}
        </section>
    )
}

export function ImportCsvButton(props) {
    const {t} = useTranslation(['common', 'aria']);
    const {
        ariaLabelKey,
        importInputId,
        importCsv,
        parseText=Papa.parse,
        setIsDisabled,
        isDisabled,
        ...attr
    } = props;

    const importCsvInputRef = useRef();
    function onImport() {
        importCsvInputRef.current?.click();
    }

    const importCsvFileHandler = createFileHandler({
        readAs: 'readAsText',
        onloadstart: function () {
            if (typeof setIsDisabled === 'function') {
                setIsDisabled(true);
            }
        },
        onloadend: function (event) {
            const csvText = event.target.result;
            const {data: resultArray} = parseText(csvText);
            importCsv(resultArray);
            if (typeof setIsDisabled === 'function') {
                setIsDisabled(false);
            }
        }
    });

    const importCsvButtonAriaLabel = t(`aria:hiddenAssistText.importCsv${ariaLabelKey}`);

    return (
        <>
            <Button isImg isClear onClick={onImport} aria-label={importCsvButtonAriaLabel}
                isDisabled={isDisabled} {...attr}
            >
                <span className="icon is-small">
                    <img src={icon('metadataAdd')} alt={t('aria:hiddenAssistText.importCsvIcon')}/>
                </span>
            </Button>

            <input style={{display: "none"}} id={importInputId} type="file" accept=".csv"
                ref={importCsvInputRef} onChange={importCsvFileHandler} disabled={isDisabled}/>
        </>
    )
}

export function ImportExportCsvButtons(props) {
    const {t} = useTranslation(['common']);
    const {
        id,
        importCsv,
        exportCsv,
        canModify,

        ariaLabelKey,
        importInputId,
        setIsDisabled,

        isDisabled,
        isExportDisabled
    } = props;


    const exportCsvButtonAriaLabel = t(`aria:hiddenAssistText.exportCsv${ariaLabelKey}`);

    return (
        <div id={id} className="add-remove-buttons">
            {typeof importCsv === 'function' && canModify &&
                <ImportCsvButton ariaLabelKey={ariaLabelKey} importInputId={importInputId} importCsv={importCsv}
                    setIsDisabled={setIsDisabled} isDisabled={isDisabled}/>
            }
            {typeof exportCsv === 'function' &&
                <Button isImg onClick={exportCsv} aria-label={exportCsvButtonAriaLabel}
                    isDisabled={isDisabled || isExportDisabled}
                >
                    <span className="icon is-small">
                        <img src={icon('download')} alt={t('aria:hiddenAssistText.downloadCsvIcon')}/>
                    </span>
                </Button>
            }
        </div>
    )
}
