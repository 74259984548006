import React, {useLayoutEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import JobScheduleHeader from "./JobScheduleHeader";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import ScheduleJob from "./ScheduleJob";
import JobScheduleModel from "../../models/job/JobScheduleModel";
import {details} from "../../utilities/constants";
import ScheduleTablet from "./ScheduleTablet";
import {useTranslation} from "react-i18next";
import {
    SettingsRowSeparator,
    SettingsRowValue,
    StatusNameDescriptionCell,
    TableEnableToggleCell,
    TableLabelHeader,
    TableRow
} from "../common/CustomTable/CustomTable";
import {getPluralTranslations, getValues, isNotEmptyNorFalsy, objEquals} from "../../utilities/helperFunctions";
import {scheduleFrequencyKeys, scheduleTriggerTypeKeys} from "../../i18next/keys";
import CurrentUserModel from "../../models/user/CurrentUserModel";
import {selectDetailsMapForJobNames, selectJobScheduleText} from "../job/selectors";
import Text from "../common/Text/Text";


function JobScheduleDisplay() {
    const {t} = useTranslation(['jobSchedule', 'common']);
    const dispatch = useDispatch();

    const isLoading = useSelector(state => !state.hasLoaded[details.SCHEDULE_JOBS]);
    const jobScheduleDisplay = useSelector(state => state.componentStates.jobScheduleDisplay);
    const detailsMap = useSelector(state => ({
        jobSchedule: state.jobScheduleDetailsMap,
        ...selectDetailsMapForJobNames(state),
    }), objEquals);

    const jobSchedules = getValues(detailsMap.jobSchedule)
        .filter(job => selectJobScheduleText(detailsMap, job).includes(jobScheduleDisplay.searchText.toLowerCase()));

    const {
        jobScheduleId,
        isJobScheduleFormActive
    } = jobScheduleDisplay;


    // Set ScheduleDisplay active and start polling JOB_MONITORING
    useLayoutEffect(() => {
        dispatch(JobScheduleModel.componentActionCreators.setDisplayActive());
        dispatch(CurrentUserModel.actionCreators.pollActivity(CurrentUserModel.activities.ADMINISTRATION));

        return () => dispatch(JobScheduleModel.componentActionCreators.setDisplayInactive());
    }, [dispatch]);


    const showJobScheduleTable = event => dispatch(JobScheduleModel.actionCreators.showTablet(event.currentTarget.dataset.value));
    const toggleEnable = event => dispatch(JobScheduleModel.actionCreators.toggleEnabled(event.currentTarget.dataset.name));

    return (
        <>
            <section className="mainPage-header">
                <JobScheduleHeader/>
            </section>

            <section className="mainPage-content" id={'jobScheduleDisplay'}>
                <LoadingWrapper isLoading={isLoading}>
                    {isNotEmptyNorFalsy(jobSchedules) &&
                    <div className="display-table">
                        <div className="table-header-group">
                            <div className="table-header" style={{width: '4rem'}}/>

                            <TableLabelHeader label={t('common:label.name')} style={{width: '20rem'}}/>

                            <div className="table-header">
                                <label className="label is-bold">
                                    {t('jobSchedule:label.conditions')}
                                </label>
                            </div>

                            <div className="table-header">
                                <label className="label is-bold">
                                    {t('jobSchedule:label.schedule')}
                                </label>
                            </div>

                            <div className="table-header">
                                <label className="label is-bold">
                                    {t('jobSchedule:label.jobEvents')}
                                </label>
                            </div>

                            <div className="table-header">
                                <label className="label is-bold">
                                    {t('jobSchedule:label.propertyFilters')}
                                </label>
                            </div>
                        </div>

                        <div className="table-row-group">
                            {jobSchedules.map(schedule =>
                                <JobScheduleRow key={schedule.id} {...schedule} isActive={jobScheduleId === schedule.id} onClick={showJobScheduleTable}
                                    toggleEnable={toggleEnable}
                                />
                            )}
                        </div>
                    </div>
                    }
                </LoadingWrapper>
            </section>

            {jobScheduleId &&
            <ScheduleTablet/>
            }

            {isJobScheduleFormActive &&
            <ScheduleJob/>
            }
        </>
    )
}

function JobScheduleRow(props) {
    const {id, status, name, enabled, description, conditions, scheduleTriggerType, scheduleTrigger, eventTrigger, isActive, onClick, toggleEnable} = props;
    const {t} = useTranslation(['jobSchedule']);

    const {
        commenceAfter,
        expireAfter,
        skipIfJobsRunning,
        skipIfJobsQueued
    } = conditions;

    const {
        startDate,
        frequency,
        recurEvery,
        recurOnDays,
        addNextJobToStaging
    } = scheduleTrigger;

    const {
        jobEvents,
        triggerFilter: {
            submissionMechanisms,
            jobNameContains,
            jobDescriptionContains,
            clientNameContains,
            matterNameContains,
            libraryNameContains,
            workflowNameContains,
            errorTextContains,
            priorityAnyOf
        }
    } = eventTrigger;

    return (
        <TableRow value={id} isActive={isActive} onClick={onClick}>
            <TableEnableToggleCell ariaLabelKey={'Schedule'} displayName={name} name={id}
                checked={enabled} onClick={toggleEnable}/>

            <StatusNameDescriptionCell status={status} name={name} description={description}/>

            <div className="table-cell">
                {(commenceAfter || expireAfter) &&
                <div className="settings-table">
                    <div className="table-row-group">
                        {commenceAfter &&
                        <SettingsRowValue label={t('jobSchedule:condition.commenceAfter')} value={commenceAfter} isTight/>
                        }
                        {expireAfter &&
                        <SettingsRowValue label={t('jobSchedule:condition.expireAfter')} value={expireAfter} isTight/>
                        }
                        <SettingsRowSeparator/>
                    </div>
                </div>
                }

                {skipIfJobsRunning &&
                <label className="label">
                    {getPluralTranslations(t, 'jobSchedule:condition.skipIfJobsRunning', {count: skipIfJobsRunning})}
                </label>
                }
                {skipIfJobsQueued &&
                <label className="label">
                    {getPluralTranslations(t, 'jobSchedule:condition.skipIfJobsQueued', {count: skipIfJobsQueued})}
                </label>
                }
            </div>

            <div className="table-cell">
                {scheduleTriggerType === scheduleTriggerTypeKeys.ON_SCHEDULE &&
                <>
                <div className="settings-table tight-padding no-table-padding no-border align-top">
                    <div className="table-row-group">
                        <SettingsRowValue label={t('jobSchedule:label.frequency')} isTight value={
                            getPluralTranslations(t, `jobSchedule:recurs.${frequency}`, {
                                count: recurEvery,
                                days: (frequency === scheduleFrequencyKeys.WEEKLY) && recurOnDays.map(day => t(`jobSchedule:recurOnDay.${day}`)).join(', ')
                            })}/>

                        <SettingsRowValue label={t('common:option.start')} value={startDate} isTight/>

                    </div>
                </div>
                    {addNextJobToStaging &&
                        <Text value={t('jobSchedule:label.addNextJobToStaging')} />
                    }
                </>
                }
            </div>

            <div className="table-cell">
                {scheduleTriggerType === scheduleTriggerTypeKeys.ON_JOB_EVENT &&
                    jobEvents.map(event =>
                        <label key={event} className="label">
                            {t(`job:trigger.${event}`)}
                        </label>
                    )
                }
            </div>

            <div className="table-cell">
                {scheduleTriggerType === scheduleTriggerTypeKeys.ON_JOB_EVENT &&
                    <div className="settings-table">
                        <div className="table-row-group">
                            <SubmissionMechanismPropertyFilterRow submissionMechanisms={submissionMechanisms}/>

                            <PropertyFilterRow label={t('jobSchedule:filter.jobName')} value={jobNameContains}/>

                            <PropertyFilterRow property={t('jobSchedule:filter.description')} contains={jobDescriptionContains}/>

                            <PropertyFilterRow property={t('jobSchedule:filter.clientName')} contains={clientNameContains}/>

                            <PropertyFilterRow property={t('jobSchedule:filter.matterName')} contains={matterNameContains}/>

                            <PropertyFilterRow property={t('jobSchedule:filter.libraryName')} contains={libraryNameContains}/>

                            <PropertyFilterRow property={t('jobSchedule:filter.workflowName')} contains={workflowNameContains}/>

                            <PropertyFilterRow property={t('jobSchedule:filter.errorText')} contains={errorTextContains}/>

                            <PriorityPropertyFilterRow priorityAnyOf={priorityAnyOf}/>
                        </div>
                    </div>
                }
            </div>
        </TableRow>
    )
}

function SubmissionMechanismPropertyFilterRow(props) {
    const {submissionMechanisms} = props;
    const {t} = useTranslation(['jobSchedule', 'job']);

    if (!Array.isArray(submissionMechanisms))
        return null;

    return (
        <PropertyFilterRow label={t('jobSchedule:filter.submissionMechanism')} value={submissionMechanisms.map(m => t(`jobSchedule:submissionMechanism.${m}`)).join(', ')}/>
    )
}

function PriorityPropertyFilterRow(props) {
    const {priorityAnyOf} = props;
    const {t} = useTranslation(['jobSchedule', 'common']);

    if (!Array.isArray(priorityAnyOf))
        return null;

    return (
        <PropertyFilterRow label={t('job:label.priority')} value={priorityAnyOf.map(p => t(`common:priority.${p}`)).join(', ')}/>
    )
}

function PropertyFilterRow(props) {
    const {label, value} = props;

    if (!value)
        return null;

    return (
        <SettingsRowValue label={label} value={value} isTight/>
    )
}

export default JobScheduleDisplay;
