import React from "react";
import './welcomeStep.css'
import '../../../wizard-general.css'
import {useTranslation} from "react-i18next";

function WelcomeStep(props) {
    const {t} = useTranslation(['wizard']);

    return (
        <div className={"wizard-step-container"}>
            <div className={"display-item"}>
                <label className="label is-heading">
                    {t("wizard:questions.welcome.title")}
                </label>
            </div>
            <label className={"label"}>
                {t("wizard:questions.welcome.description")}
            </label>
        </div>
    );
}

export default WelcomeStep;