import {all, call, cancel, fork, put, race, select, spawn, take, takeLatest, takeLeading} from 'redux-saga/effects';
import {genericErrorHandler, tryCatchAndReattemptWrapper, tryCatchWrapper} from './tryCatchWrapper';
import {
    contextCall,
    contextFork,
    contextSaga,
    contextTakeEvery,
    contextTakeLatest,
    contextTakeLeadingPayload,
    editNavigation,
    serverErrorResponseValues
} from './sagaFunctions';
import {
    AUTOMATE_USERTOKEN,
    clearUserData,
    getOidcLoginSearchParams,
    getRedirectAfterOidcLogin,
    getUserData,
    setOidcLoginSearchParams,
    setRedirectAfterOidcLogin,
    setUserData
} from '../utilities/localStorageHelper';
import {
    addStyleElement,
    getEntries,
    getSearchParamsAsObject,
    stringStartsWithIgnoreCase,
    submitFieldsViaForm
} from '../utilities/helperFunctions';
import {popupInfoKeys} from "../i18next/keys";
import ClientModel, {ClientSaga} from "../models/client/ClientModel";
import JobModel, {JobSaga} from "../models/job/JobModel";
import MatterModel, {MatterSaga} from "../models/client/MatterModel";
import LibraryModel, {LibrarySaga} from "../models/library/LibraryModel";
import WorkflowTemplateModel, {WorkflowTemplateSaga} from "../models/library/WorkflowTemplateModel";
import PopupModel from "../models/scheduler/PopupModel";
import ClientPoolModel, {ClientPoolSaga} from "../models/settings/ClientPoolModel";
import ResourcePoolModel, {ResourcePoolSaga} from "../models/settings/ResourcePoolModel";
import ExecutionProfileModel, {ExecutionProfileSaga} from "../models/settings/ExecutionProfileModel";
import EngineModel, {EngineSaga} from "../models/settings/EngineModel";
import ServerModel, {ServerSaga} from "../models/settings/ServerModel";
import LicenceSourceModel, {LicenceSourceSaga} from "../models/settings/LicenceSourceModel";
import PolicyModel, {PolicySaga} from "../models/settings/PolicyModel";
import SchedulerModel, {SchedulerApi, SchedulerSaga} from "../models/scheduler/SchedulerModel";
import CurrentUserModel, {CurrentUserApi, CurrentUserSaga} from "../models/user/CurrentUserModel";
import ReduxStateModel from "../models/scheduler/ReduxStateModel";
import JobUserModel, {JobUserSaga} from "../models/user/JobUserModel";
import AxiosProxy, {axiosInstance, setAxiosInstance} from "../models/api/AxiosProxy";
import {UserSettingsApi, UserSettingsSaga} from "../models/user/UserSettingsModel";
import JobArchiveModel, {JobArchiveSaga} from "../models/job/JobArchiveModel";
import NotificationRuleModel, {NotificationRuleSaga} from "../models/settings/NotificationRuleModel";
import JobScheduleModel, {JobScheduleSaga} from "../models/job/JobScheduleModel";
import {JobQueueSaga} from "../models/job/JobQueueModel";
import EditModel, {EditSaga} from "../models/scheduler/EditModel";
import AutomateLicenceModel, {AutomateLicenceSaga} from "../models/scheduler/AutomateLicenceModel";
import DataRepositoryModel, {DataRepositorySaga} from "../models/data/DataRepositoryModel";
import DatasetModel, {DatasetSaga} from "../models/data/DatasetModel";
import {FileInfoSaga} from "../models/data/FileInfoModel";
import UserServiceModel, {UserServiceSaga} from "../models/user/UserServiceModel";
import LegalHoldModel, {LegalHoldSaga} from "../models/legalhold/LegalHoldModel";
import NoticeTemplateModel, {NoticeTemplateSaga} from "../models/notice/NoticeTemplateModel";
import UserNoticeModel, {UserNoticeSaga} from "../models/notice/UserNoticeModel";
import UserModel, {UserSaga} from "../models/user/UserModel";
import {NoticeCommentSaga} from "../models/notice/NoticeCommentModel";
import {JobQueueFilterSaga} from "../models/job/JobQueueFilterModel";
import UtilizationModel, {UtilizationSaga} from "../models/scheduler/UtilizationModel";
import {SmtpEmailSaga} from "../models/settings/SmtpEmailModel";
import idleHandler from "./idleHandler";
import ApiKeyModel, {ApiKeySaga} from "../models/settings/ApiKeyModel";
import WebhookModel, {WebhookSaga} from "../models/settings/WebhookModel";
import FileLibraryModel, {FileLibrarySaga} from "../models/filelibrary/FileLibraryModel";
import LibraryFileModel, {LibraryFileSaga} from "../models/filelibrary/LibraryFileModel";
import JobExecutionLogModel, {JobExecutionLogSaga} from "../models/job/JobExecutionLogModel";
import FileUploadHistoryModel, {FileUploadHistorySaga} from "../models/data/FileUploadHistoryModel";
import AxiosModel from "../models/api/AxiosModel";
import JobOperationMimeTypeStatsModel, {
    JobOperationMimeTypeStatsSaga
} from "../models/job/JobOperationMimeTypeStatsModel";
import JobRunningLogModel, {JobRunningLogSaga} from "../models/job/JobRunningLogModel";
import RelativityAxiosProxy from "../models/api/RelativityAxiosProxy";
import RelativityProxyModel, {RelativityProxyApi, RelativityProxySaga} from "../models/relativity/RelativityProxyModel";
import {WorkflowBuilderSaga} from "../models/workflowbuilder/WorkflowBuilderModel";
import UserCommentModel, {UserCommentSaga} from "../models/generics/UserCommentModel";
import ThirdPartyServiceModel, {ThirdPartyServiceSaga} from "../models/thirdparty/ThirdPartyServiceModel";
import {
    THIRD_PARTY_SERVICE_LOGIN_WINDOW_NAME
} from "../components/thirdPartyService/userCredential/ThirdPartyUserCredentialForm";
import {GuidedJobSaga} from "../models/guidedjob/GuidedJobModel";
import {JobPurviewSaga} from "../models/purview/JobPurviewModel";
import {JobVaultSaga} from "../models/job/JobVaultModel";
import UserDataDirModel, {UserDataDirSaga} from "../models/system/UserDataDirModel";

function* loadCustomBranding() {
    const [titleRes, faviconRes, logoRes, logoStyleRes={}, headerStyleRes={}] = yield all([
        contextCall(SchedulerApi, 'getBrandingTitle'),
        contextCall(SchedulerApi, 'getBrandingFavicon'),
        contextCall(SchedulerApi, 'getBrandingLogo'),
        contextCall(SchedulerApi, 'getBrandingLogoStyle'),
        contextCall(SchedulerApi, 'getBrandingHeaderStyle'),
    ]);
    // Modify head title to branding title
    if (titleRes != null) {
        document.getElementById('tabTitle').innerHTML = titleRes.data
    }

    if (faviconRes != null) {
        document.getElementById('favicon').href = URL.createObjectURL(faviconRes.data);
    }

    if (logoRes != null) {
        yield put(SchedulerModel.actionCreators.setBrandingLogo(URL.createObjectURL(logoRes.data)));
    }

    // Add branding styles as HTML style element
    addStyleElement({
        '.logo': logoStyleRes.data,
        '.appHeader': headerStyleRes.data,
    });
}

function* onAppStart(dispatch) {
    const search = window.location.href.split('?')[1];
    const searchParams = getSearchParamsAsObject(search);

    let path = window.location.hash.substring(1);
    if (!path.startsWith('/')) {
        path = '/' + path;
    }

    const isRelativityApplication = window.location.pathname.startsWith('/Relativity');
    yield put(SchedulerModel.actionCreators.setIsRelativityApplication(isRelativityApplication));

    // If in Relativity mode, replace axiosInstance with RelativityAxiosProxy
    if (isRelativityApplication) {
        setAxiosInstance(new RelativityAxiosProxy());
    } else {
        setAxiosInstance(new AxiosProxy());
    }

    try {
        // Initiate OIDC login with mode
        if (path.startsWith('/oidcAccountLogin')) {
            const userData = getUserData();
            if (userData.uiToken) {
                const [loginMode, ...otherModes] = searchParams['mode']?.split(',') || [];
                if (otherModes) {
                    const nextLoginSearchParams = {...searchParams, mode: otherModes};
                    setOidcLoginSearchParams(nextLoginSearchParams);
                }
                if (loginMode) {
                    triggerOidcLogin({token: userData.uiToken, ...searchParams, mode: loginMode});
                    return;
                }
            }
        }

        if (isRelativityApplication) {
            // Track proxy configuration status
            // If configuration is invalid, following queries will fail
            const statusRes = yield RelativityProxyApi.getStatus();
            yield put(RelativityProxyModel.actionCreators.setProxy(statusRes.data));
        }

        // Initialization queries and check if new version of Scheduler
        const [runIdRes, servicesRes] = yield all([
            contextCall(SchedulerApi, 'getRunId'),
            contextCall(SchedulerApi, 'getServices')
        ]);

        // Reload window if version doesn't match stored version
        const runId = localStorage.getItem('automateSchedulerRunId');
        if (runId !== runIdRes.data) {
            window.location.reload();
            localStorage.setItem('automateSchedulerRunId', runIdRes.data);
        }

        // If not SCHEDULER, show error
        if (!servicesRes.data.includes(SchedulerModel.Role.SCHEDULER)) {
            yield put(SchedulerModel.actionCreators.setIsServerScheduler(false));
            return;
        }
        // yield call(loadCustomBranding);

        if (isRelativityApplication) {
            // Already authenticated by Relativity
            yield call(setUser, {name: 'RelativityApplication', isRelativityApplication}, dispatch);

        } else {
            // Check if coming back from OIDC login, if not check if user is already logged in
            // If not spawn oidcLogin or userLogin (depending on which are available)
            yield contextCall(SchedulerSaga, 'queryAuthMethods');

            // OIDC RedirectUrl after successful login; call to always clear
            const redirectUrl = getRedirectAfterOidcLogin() || searchParams['redirectUrl'];
            let userData;

            if (path === '/oidcResponse') {
                userData = yield call(handleOidcResponse);
                if (!userData) {
                    return;
                }

            } else if (path === '/oidcError') {
                yield call(handleOidcError)

            } else if (path === '/oidcUserSignOut' && window.name === THIRD_PARTY_SERVICE_LOGIN_WINDOW_NAME) {
                window.close();
                return;

            } else if (searchParams['ssotoken'] != null) {
                userData = yield call(linkLogin, window.location.href);
            } else {
                try {
                    const {data} = yield contextCall(CurrentUserApi, 'getMe');
                    userData = data;
                    clearUserData(true);
                } catch (error) {
                    userData = getUserData();
                    // Attempt persistent login (refresh / new tab) with uiToken
                    // Only possible if uiToken === bearerToken
                    if (userData.uiToken) {
                        axiosInstance.setAxiosToken(userData.uiToken);
                    }
                }
            }

            // Apply redirectUrl
            if (redirectUrl) {
                userData.redirectUrl = redirectUrl;
            }

            if (userData?.name) {
                yield call(setUser, userData, dispatch);

            } else if (searchParams['oidcScope'] != null) {
                triggerOidcLogin(searchParams);

            } else {
                yield spawn(userLogin, dispatch);
            }
        }
    } catch (error) {
        console.log(error);
        yield call(genericErrorHandler, error, {
            responseErrorCb: function* ({response}) {
                if (response.status === 401 && !isRelativityApplication) {
                    clearUserData();
                    return yield spawn(userLogin, dispatch);
                }

                if (response.data.key === 'instanceDoesNotHaveScheduler') {
                    yield put(SchedulerModel.actionCreators.setIsServerScheduler(false));
                } else {
                    yield put(PopupModel.actionCreators.showError({
                        info: {
                            ...(serverErrorResponseValues(response) || response.data)
                        }
                    }));
                }

                if (isRelativityApplication) {
                    yield spawn(startAppSagasForRelativityApplication, dispatch);
                }
            },
            noResponseErrorCb: function* () {
                yield put(SchedulerModel.actionCreators.setDisconnected());
                yield take(SchedulerModel.actions.SET_CONNECTED);
                yield spawn(onAppStart);
            }
        });
    } finally {
        yield put(SchedulerModel.componentActionCreators.updateApp({isLoading: false}));
    }
}

function* getOnlyOidcScope() {
    const authMethods = yield select(state => state.schedulerDetails.authMethods);
    const authMethodEntries = getEntries(authMethods);
    const firstAuthMethodEntry = authMethodEntries[0];

    const isOnlyOidcLogin = authMethodEntries.length === 1 && stringStartsWithIgnoreCase(firstAuthMethodEntry[1], UserServiceModel.AuthMethodType.OIDC);
    if (isOnlyOidcLogin) {
        return firstAuthMethodEntry[0];
    }
}

function* userLogin(dispatch) {
    const {isRelativityApplication} = yield select(state => state.schedulerDetails);
    if (isRelativityApplication) return;

    const onlyOidcScope = yield* getOnlyOidcScope();
    if (onlyOidcScope != null) {
        triggerOidcLogin({oidcScope: onlyOidcScope});
        return;
    }

    try {
        const [loginAttempt, ssoLogin] = yield race([
            take(CurrentUserModel.actions.LOGIN_USER),
            take(CurrentUserModel.actions.SSO_LOGIN)
        ]);

        if (loginAttempt != null) {
            const {payload: {password}} = loginAttempt;
            yield put(CurrentUserModel.componentActionCreators.updateLoginPage({isLoginPending: true}));

            // Will fail if authMethod is not supported
            const {authMethod, username} = yield select(state => state.componentStates.loginPage);
            const {data} = yield contextCall(CurrentUserApi, 'postLogin', username, password, authMethod);

            yield call(setUser, data, dispatch);
            yield put(CurrentUserModel.componentActionCreators.resetLoginPage({authMethod}));

        } else if (ssoLogin.payload.userData != null) {
            yield call(setUser, ssoLogin.payload.userData, dispatch);
        }

    } catch (error) {
        function* responseErrorCb({response}) {
            if (response.status !== 401) {
                yield put(PopupModel.actionCreators.showError({
                    info: {
                        ...(serverErrorResponseValues(response) || response.data)
                    }
                }));
            } else {
                yield put(CurrentUserModel.componentActionCreators.updateLoginPage({
                    invalidMessage: `popupInfo:${response.data.key}.message`
                }));
            }
            // Re-query authMethods on response error
            yield contextCall(SchedulerSaga, 'queryAuthMethods');
        }

        function* noResponseErrorCb() {
            yield put(SchedulerModel.actionCreators.setDisconnected());
            yield take(SchedulerModel.actions.SET_CONNECTED);
        }

        yield call(genericErrorHandler, error, {
            responseErrorCb,
            noResponseErrorCb
        });

        yield all([
            spawn(userLogin, dispatch),
            put(CurrentUserModel.componentActionCreators.updateLoginPage({username: '', isLoginEnabled: false, isLoginPending: false, isLoginInvalid: true}))
        ]);
    }
}

function* handleOidcError() {
    try {
        const {data} = yield contextCall(CurrentUserApi, 'postOidcError');
    } catch (error) {
        yield call(genericErrorHandler, error, {
            responseErrorCb: function* ({response}) {
                yield put(PopupModel.actionCreators.showError({
                    info: {
                        ...(serverErrorResponseValues(response) || response.data)
                    }
                }));
            }
        });
        return {};
    }
}

function* handleOidcResponse() {
    try {
        // If handling oidcResponse for loginMode
        if (window.name === THIRD_PARTY_SERVICE_LOGIN_WINDOW_NAME) {
            const oidcLoginSearchParams = getOidcLoginSearchParams();
            if (UserServiceModel.OidcLoginMode[oidcLoginSearchParams.mode] != null) {
                const userData = getUserData();
                triggerOidcLogin({token: userData.uiToken, ...oidcLoginSearchParams});
                return;
            }

            const containerDiv = window.document.body.getElementsByTagName('div')[0];
            containerDiv.style.height = '100vh';
            containerDiv.style.display = 'flex';
            containerDiv.style.justifyContent = 'center';

            const labelElement = document.createElement('label');
            labelElement.className = 'label';
            labelElement.style.marginTop = '10rem';
            labelElement.textContent = 'Loading...';
            containerDiv.replaceChildren(labelElement);

            const channel = new BroadcastChannel(window.name);
            channel.postMessage({type: 'SUCCESS'});
            channel.close();
            window.close();
            return;
        }

        const {data} = yield contextCall(CurrentUserApi, 'postOidcComplete');
        return data;

    } catch (error) {
        yield call(genericErrorHandler, error, {
            responseErrorCb: function* ({response}) {
                yield put(PopupModel.actionCreators.showError({
                    info: {
                        ...(serverErrorResponseValues(response) || response.data)
                    }
                }));
            }
        });
        return {};
    }
}

export function triggerOidcLogin(opts) {
    const {rootElement, url=axiosInstance.baseUrl + '/users/oidcAuth', oidcScope, redirectUrl, token, mode, thirdPartyServiceId} = opts;

    if (redirectUrl) {
        setRedirectAfterOidcLogin(redirectUrl);
    }
    // build custom form data so to avoid rendering Login page then force-submitting
    const fields = [{name: 'scope', value: oidcScope}];
    if (token) {
        fields.push({name: 'token', value: token});
    }
    if (mode != null) {
        fields.push({name: 'mode', value: mode});
    }
    if (thirdPartyServiceId != null) {
        fields.push({name: 'thirdPartyServiceId', value: thirdPartyServiceId});
    }
    if (Array.isArray(opts.fields)) {
        fields.push(...opts.fields);
    }
    submitFieldsViaForm({rootElement, url, fields});
}

export function* linkLogin(loginLink) {
    // Logout of current user if active
    const currentUser = yield select(state => state.currentUser);
    if (currentUser.isAuthenticated) {
        yield all([
            put(CurrentUserModel.actionCreators.safeLogout(true)),
            // Wait for user to be logged out
            take(ReduxStateModel.actions.CLEAR_REDUX)
        ]);
    }

    try {
        window.location.href = window.location.origin + window.location.pathname + "#/login"
        // Always post loginLink to clear entry
        const res = yield contextCall(CurrentUserApi, 'postLoginLink', loginLink);
        // oidcScope redirect
        if (res.data.oidcScope != null) {
            window.location.href = window.location.origin + window.location.pathname + `#/?oidcScope=${encodeURIComponent(res.data.oidcScope)}&redirectUrl=${encodeURIComponent(res.data.redirectUrl)}`
        }
        return res.data;

    } catch (error) {
        yield call(genericErrorHandler, error, {
            responseErrorCb: function* ({response}) {
                const info = {
                    ...(serverErrorResponseValues(response) || response.data)
                };

                switch (response.data.key) {
                    case 'loginLinkExpired':
                        return yield put(PopupModel.actionCreators.showError({
                            id: 'loginLinkExpired',
                            info,
                            buttons: [{
                                titleKey: 'common:option.refresh',
                                onClick: dispatch => dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(refreshLogin(loginLink))),
                                ignoreHide: true
                            }],
                            cancelButton: {
                                titleKey: 'common:option.cancel'
                            }
                        }));
                    default:
                        return yield put(PopupModel.actionCreators.showError({
                            id: 'loginLinkError',
                            info
                        }));
                }
            }
        });
    }
}

function* refreshLogin(loginLink) {
    yield all([
        put(PopupModel.actionCreators.setDisabled('loginLinkExpired', true)),
        CurrentUserApi.refreshLoginLink(loginLink)
    ]);

    return yield all([
        put(PopupModel.actionCreators.hide('loginLinkExpired')),
        put(PopupModel.actionCreators.showSuccess({
            info: {
                key: 'loginLinkRefreshSent'
            }
        }))
    ]);
}

function* setUser(userData, dispatch) {
    if (userData.redirectUrl) {
        window.location.href = window.location.origin + window.location.pathname + '#/' + userData.redirectUrl;
    }

    setUserData(userData.name, userData.uiToken, userData.tokenDate);
    yield call(initializeAndSetUser, userData, dispatch);
}

function* initializeAndSetUser(userData, dispatch) {
    const [{data: features}] = yield all([
        contextCall(CurrentUserApi, 'getFeatures'),
        contextCall(UserSettingsApi, 'putCategorySetting', "BUILTIN", "TIMEZONE", "\""+Intl.DateTimeFormat().resolvedOptions().timeZone+"\"")
    ]);

    //user has application features, proceed with app startup
    if (CurrentUserModel.validateFeatures(features)) {
        const {data} = yield contextCall(SchedulerApi, 'getConfiguration');
        yield put(SchedulerModel.actionCreators.updateConfiguration(data));

        if (userData.isRelativityApplication) {
            yield spawn(startAppSagasForRelativityApplication, dispatch);
        } else {
            yield spawn(startAppSagasAndRaceLogout, data, dispatch);
            yield call(setupUserRefreshPolling, data);
        }

        yield put(CurrentUserModel.actionCreators.setCurrentUser(userData.id, userData.name, features));

    } else {
        if (userData.isRelativityApplication) {
            yield put(PopupModel.actionCreators.showError({
                info: {
                    key: popupInfoKeys.NO_APPLICATION_FEATURES
                },
                showX: false,
                hideButtons: true
            }));
        } else {
            //user has no application features, force logout with warning
            yield contextCall(CurrentUserApi, 'deleteLogin');
            yield all([
                put(CurrentUserModel.actionCreators.clearCurrentUser()),
                put(PopupModel.actionCreators.showError({
                    info: {
                        key: popupInfoKeys.NO_APPLICATION_FEATURES
                    },
                    cancelButton: {
                        onClick: dispatch => {
                            dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(spawn(userLogin, dispatch)));
                        }
                    }
                })),
                put(CurrentUserModel.componentActionCreators.updateLoginPage({isLoginInvalid: true}))
            ]);
            clearUserData();
        }
    }
}

function* startAppSagasForRelativityApplication(dispatch) {
    yield fork(tryCatchAndReattemptWrapper, startModelSagas, dispatch);
    yield put(SchedulerModel.componentActionCreators.setAppActive());

    //Start App sagas
    yield all([
        fork(tryCatchWrapper, startPollingSagas),
        fork(tryCatchAndReattemptWrapper, startGlobalSagas),
        fork(tryCatchAndReattemptWrapper, startRelativityApplicationGlobalSagas),
        fork(tryCatchAndReattemptWrapper, startWatchingIsChangedSagas)
    ]);
}

//this will become head of saga tree after onAppStart/userLogin/initializeAndSetUser
function* startAppSagasAndRaceLogout(data, dispatch) {
    yield fork(tryCatchAndReattemptWrapper, startModelSagas, dispatch);
    yield put(SchedulerModel.componentActionCreators.setAppActive());

    //Start App sagas
    yield all([
        fork(tryCatchAndReattemptWrapper, startPollingSagas),
        fork(tryCatchAndReattemptWrapper, startGlobalSagas),
        fork(tryCatchAndReattemptWrapper, startWatchingIsChangedSagas),
        takeLeading(CurrentUserModel.actions.SAFE_LOGOUT, editNavigation, dispatch, function* (action) {
            yield put(CurrentUserModel.actionCreators.logoutUser(action.payload.hidePopup));
        }),
    ]);

    const [logout] = yield race([
        take(CurrentUserModel.actions.LOGOUT_USER),
        call(idleHandler, data.userSessionTimeout, dispatch)
    ]);

    if (CurrentUserSaga.downgradeWebWorkerToken) {
        // To always set flag->false
        CurrentUserSaga.clearRefreshPollingFlagOnUnload();
        window.removeEventListener('storage', CurrentUserSaga.refreshTokenStorageListener);
    }

    //End of userLogout spawns new userLogin (if not oidc logout) saga which will become head of new saga tree
    yield all([
        // Send edit cancel for case when user was in editMode when forced logout
        put(EditModel.actionCreators.cancel()),
        call(userLogout, logout && logout.payload.hidePopup, dispatch)
    ]);
    yield cancel();
}

function* setupUserRefreshPolling(configData) {
    try {
        // Make request with uiToken to endpoint to check if downgradeWebWorker=true
        const uiAxiosInstance = new AxiosModel({baseURL: axiosInstance.baseUrl});
        uiAxiosInstance.setAxiosToken(localStorage.getItem(AUTOMATE_USERTOKEN));
        yield uiAxiosInstance.get('/users/features');

        CurrentUserSaga.downgradeWebWorkerToken = true;
        // Attach storageListener / start polling refresh if needed
        window.addEventListener('storage', CurrentUserSaga.refreshTokenStorageListener);

    } catch (error) {
        CurrentUserSaga.downgradeWebWorkerToken = false;
    }

    // Token refreshRate is half of token life
    if (configData.disableAuthTokenExpiration) {
        CurrentUserSaga.refreshRate = 0;
    } else {
        CurrentUserSaga.refreshRate = Math.floor(configData.authTokenTtl * 1000 * 0.4);
    }
    yield put(CurrentUserModel.actionCreators.startPollingRefreshToken());
}

function* userLogout(hidePopup, dispatch) {
    let redirectUrl;
    try {
        redirectUrl = (yield contextCall(CurrentUserApi, 'deleteLogin')).data.redirectUrl;
    } catch (error) {
        //user was already logged out on scheduler-side
        yield fork(genericErrorHandler, error, {responseErrorCb: error => console.log(error)});
    }

    clearUserData();
    if (redirectUrl) {
        //No need to clear redux here since redirection restarts App
        if (redirectUrl.endsWith("/#login")) {
            window.location.reload();
        } else {
            window.location.href = redirectUrl;
        }
    } else {
        yield all([
            spawn(userLogin, dispatch),
            put(ReduxStateModel.actionCreators.clearRedux())
        ]);

        if (!hidePopup) {
            yield put(PopupModel.actionCreators.show({
                id: 'logout',
                info: {
                    key: popupInfoKeys.LOGOUT
                },
                cancelButton: {
                    titleKey: 'common:option.ok'
                }
            }))
        }
    }
}

function* startModelSagas(dispatch) {
    yield all([
        contextFork(AutomateLicenceSaga, 'run', dispatch),
        contextFork(RelativityProxySaga, 'run', dispatch),
        contextFork(UtilizationSaga, 'run', dispatch),
        contextFork(UserDataDirSaga, 'run', dispatch),

        contextFork(CurrentUserSaga, 'run', dispatch),
        contextFork(EditSaga, 'run', dispatch),
        
        contextFork(UserSettingsSaga, 'run', dispatch),
        contextFork(UserServiceSaga, 'run', dispatch),

        contextFork(JobSaga, 'run', dispatch),
        contextFork(JobQueueSaga, 'run', dispatch),
        contextFork(JobPurviewSaga, 'run', dispatch),
        contextFork(JobVaultSaga, 'run', dispatch),
        contextFork(JobArchiveSaga, 'run', dispatch),
        contextFork(JobScheduleSaga, 'run', dispatch),
        contextFork(JobQueueFilterSaga, 'run', dispatch),
        contextFork(GuidedJobSaga, 'run', dispatch),

        contextFork(FileLibrarySaga, 'run', dispatch),
        contextFork(LibraryFileSaga, 'run', dispatch),

        contextFork(ClientSaga, 'run', dispatch),
        contextFork(MatterSaga, 'run', dispatch),
        contextFork(LibrarySaga, 'run', dispatch),
        contextFork(WorkflowTemplateSaga, 'run', dispatch),

        contextFork(LicenceSourceSaga, 'run', dispatch),
        contextFork(ServerSaga, 'run', dispatch),
        contextFork(EngineSaga, 'run', dispatch),
        contextFork(ResourcePoolSaga, 'run', dispatch),
        contextFork(NotificationRuleSaga, 'run', dispatch),
        contextFork(ExecutionProfileSaga, 'run', dispatch),
        contextFork(ClientPoolSaga, 'run', dispatch),
        contextFork(ThirdPartyServiceSaga, 'run', dispatch),

        contextFork(DataRepositorySaga, 'run', dispatch),
        contextFork(DatasetSaga, 'run', dispatch),
        contextFork(PolicySaga, 'run', dispatch),

        contextFork(SmtpEmailSaga, 'run', dispatch),

        contextFork(ApiKeySaga, 'run', dispatch),
        contextFork(WebhookSaga, 'run', dispatch),

        contextFork(LegalHoldSaga, 'run', dispatch),
        contextFork(NoticeTemplateSaga, 'run', dispatch),
        contextFork(UserNoticeSaga, 'run', dispatch),
        contextFork(NoticeCommentSaga, 'run', dispatch),

        contextFork(FileInfoSaga, 'run', dispatch),
        contextFork(FileUploadHistorySaga, 'run', dispatch),

        contextFork(WorkflowBuilderSaga, 'run'),

        contextFork(UserCommentSaga, 'run')
    ]);
}

function* startPollingSagas() {
    yield all([
        contextTakeLatest(AutomateLicenceModel.actions.START_POLLING_DETAILS, AutomateLicenceSaga, 'pollDetails'),
        contextTakeLatest(AutomateLicenceModel.actions.QUERY_DETAILS, AutomateLicenceSaga, 'queryDetails'),

        contextTakeLatest(RelativityProxyModel.actions.START_POLLING_DETAILS, RelativityProxySaga, 'pollDetails'),
        contextTakeLatest(RelativityProxyModel.actions.QUERY_DETAILS, RelativityProxySaga, 'queryDetails'),

        contextTakeLatest(UserDataDirModel.actions.START_POLLING_DETAILS, UserDataDirSaga, 'pollDetails'),
        contextTakeLatest(UserDataDirModel.actions.QUERY_DETAILS, UserDataDirSaga, 'queryDetails'),
        contextTakeLatest(UserDataDirModel.actions.START_POLLING_SETTINGS, UserDataDirSaga, 'pollSettings'),
        contextTakeLatest(UserDataDirModel.actions.QUERY_SETTINGS, UserDataDirSaga, 'querySettings'),

        contextTakeLatest(ThirdPartyServiceModel.actions.START_POLLING_DETAILS, ThirdPartyServiceSaga, 'pollDetails'),
        contextTakeLatest(ThirdPartyServiceModel.actions.QUERY_DETAILS, ThirdPartyServiceSaga, 'queryDetails'),

        contextTakeLatest(UtilizationModel.actions.START_POLLING_DETAILS, UtilizationSaga, 'pollDetails'),
        contextTakeLatest(JobUserModel.actions.START_POLLING_DETAILS, JobUserSaga, 'pollDetails'),
        contextTakeLatest(UserModel.actions.START_POLLING_DETAILS, UserSaga, 'pollDetails'),
        contextTakeLatest(UserModel.actions.QUERY_DETAILS, UserSaga, 'queryDetails'),

        contextTakeLatest(JobModel.actions.START_POLLING_DETAILS, JobSaga, 'pollDetails'),
        contextTakeLatest(JobModel.actions.START_POLLING_SETTINGS, JobSaga, 'pollSettings'),
        contextTakeLatest(JobModel.actions.QUERY_DETAILS, JobSaga, 'queryDetails'),
        contextTakeLatest(JobModel.actions.QUERY_SETTINGS, JobSaga, 'querySettings'),

        contextTakeLatest(JobScheduleModel.actions.START_POLLING_DETAILS, JobScheduleSaga, 'pollDetails'),
        contextTakeLatest(JobArchiveModel.actions.START_POLLING_ARCHIVE_EXISTS, JobArchiveSaga, 'pollArchiveExists'),
        contextTakeLatest(JobScheduleModel.actions.QUERY_SETTINGS, JobScheduleSaga, 'querySettings'),

        contextTakeLatest(JobExecutionLogModel.actions.START_POLLING_SETTINGS, JobExecutionLogSaga, 'pollSettings'),
        contextTakeLatest(JobExecutionLogModel.actions.QUERY_SETTINGS, JobExecutionLogSaga, 'querySettings'),
        contextTakeLatest(JobOperationMimeTypeStatsModel.actions.START_POLLING_SETTINGS, JobOperationMimeTypeStatsSaga, 'pollSettings'),
        contextTakeLatest(JobOperationMimeTypeStatsModel.actions.QUERY_SETTINGS, JobOperationMimeTypeStatsSaga, 'querySettings'),
        contextTakeLatest(JobRunningLogModel.actions.START_POLLING_SETTINGS, JobRunningLogSaga, 'pollSettings'),
        contextTakeLatest(JobRunningLogModel.actions.QUERY_SETTINGS, JobRunningLogSaga, 'querySettings'),

        contextTakeLatest(FileLibraryModel.actions.START_POLLING_DETAILS, FileLibrarySaga, 'pollDetails'),
        contextTakeLatest(FileLibraryModel.actions.START_POLLING_SETTINGS, FileLibrarySaga, 'pollSettings'),
        contextTakeEvery(FileLibraryModel.actions.QUERY_SETTINGS, FileLibrarySaga, 'querySettings'),
        contextTakeLatest(LibraryFileModel.actions.START_POLLING_SETTINGS, LibraryFileSaga, 'pollSettings'),

        contextTakeLatest(ClientModel.actions.START_POLLING_DETAILS, ClientSaga, 'pollDetails'),
        contextTakeLatest(ClientModel.actions.QUERY_DETAILS, ClientSaga, 'queryDetails'),

        contextTakeLatest(MatterModel.actions.START_POLLING_DETAILS_SUBSET, MatterSaga, 'pollDetailsSubset'),
        contextTakeLeadingPayload(MatterModel.actions.QUERY_DETAILS_SUBSET, MatterSaga, 'queryDetailsSubset'),

        contextTakeLatest(MatterModel.actions.START_POLLING_SETTINGS, MatterSaga, 'pollSettings'),
        contextTakeLatest(MatterModel.actions.QUERY_SETTINGS, MatterSaga, 'querySettings'),

        contextTakeLatest(LibraryModel.actions.START_POLLING_DETAILS, LibrarySaga, 'pollDetails'),
        contextTakeLatest(LibraryModel.actions.QUERY_DETAILS, LibrarySaga, 'queryDetails'),

        contextTakeLatest(WorkflowTemplateModel.actions.START_POLLING_DETAILS_SUBSET, WorkflowTemplateSaga, 'pollDetailsSubset'),
        contextTakeLeadingPayload(WorkflowTemplateModel.actions.QUERY_DETAILS_SUBSET, WorkflowTemplateSaga, 'queryDetailsSubset'),

        contextTakeLatest(WorkflowTemplateModel.actions.START_POLLING_SETTINGS, WorkflowTemplateSaga, 'pollSettings'),
        contextTakeLatest(WorkflowTemplateModel.actions.QUERY_SETTINGS, WorkflowTemplateSaga, 'querySettings'),

        contextTakeLatest(PolicyModel.actions.START_POLLING_DETAILS, PolicySaga, 'pollDetails'),
        contextTakeLatest(LicenceSourceModel.actions.START_POLLING_DETAILS, LicenceSourceSaga, 'pollDetails'),
        contextTakeLatest(ServerModel.actions.START_POLLING_DETAILS, ServerSaga, 'pollDetails'),
        contextTakeLatest(EngineModel.actions.START_POLLING_DETAILS, EngineSaga, 'pollDetails'),

        contextTakeLatest(ExecutionProfileModel.actions.START_POLLING_DETAILS, ExecutionProfileSaga, 'pollDetails'),
        contextTakeLatest(ExecutionProfileModel.actions.QUERY_DETAILS, ExecutionProfileSaga, 'queryDetails'),
        contextTakeLatest(ResourcePoolModel.actions.START_POLLING_DETAILS, ResourcePoolSaga, 'pollDetails'),
        contextTakeLatest(ResourcePoolModel.actions.QUERY_DETAILS, ResourcePoolSaga, 'queryDetails'),
        
        contextTakeLatest(ClientPoolModel.actions.START_POLLING_DETAILS, ClientPoolSaga, 'pollDetails'),

        contextTakeLatest(NotificationRuleModel.actions.START_POLLING_DETAILS, NotificationRuleSaga, 'pollDetails'),
        contextTakeLatest(NotificationRuleModel.actions.QUERY_DETAILS, NotificationRuleSaga, 'queryDetails'),

        contextTakeLatest(ApiKeyModel.actions.START_POLLING_DETAILS, ApiKeySaga, 'pollDetails'),
        contextTakeLatest(ApiKeyModel.actions.QUERY_DETAILS, ApiKeySaga, 'queryDetails'),

        contextTakeLatest(WebhookModel.actions.START_POLLING_DETAILS, WebhookSaga, 'pollDetails'),
        contextTakeLatest(WebhookModel.actions.START_POLLING_SETTINGS, WebhookSaga, 'pollSettings'),
        contextTakeLatest(WebhookModel.actions.QUERY_DETAILS, WebhookSaga, 'queryDetails'),

        contextTakeLatest(DataRepositoryModel.actions.START_POLLING_DETAILS, DataRepositorySaga, 'pollDetails'),
        contextTakeLatest(DataRepositoryModel.actions.QUERY_DETAILS, DataRepositorySaga, 'queryDetails'),
        contextTakeEvery(DatasetModel.actions.START_POLLING_SETTINGS, DatasetSaga, 'pollSettings'),

        contextTakeLatest(FileUploadHistoryModel.actions.START_POLLING_PAST_AND_ACTIVE_UPLOADS, FileUploadHistorySaga, 'pollPastAndActiveUploads'),
        contextTakeLatest(FileUploadHistoryModel.actions.QUERY_PAST_AND_ACTIVE_UPLOADS, FileUploadHistorySaga, 'queryPastAndActiveUploads'),

        contextTakeLatest(LegalHoldModel.actions.START_POLLING_DETAILS, LegalHoldSaga, 'pollDetails'),
        contextTakeLatest(LegalHoldModel.actions.START_POLLING_SETTINGS, LegalHoldSaga, 'pollSettings'),
        contextTakeLatest(LegalHoldModel.actions.QUERY_DETAILS, LegalHoldSaga, 'queryDetails'),
        contextTakeLatest(LegalHoldModel.actions.QUERY_SETTINGS, LegalHoldSaga, 'querySettings'),

        contextTakeLatest(UserNoticeModel.actions.START_POLLING_SETTINGS, UserNoticeSaga, 'pollSettings'),
        contextTakeLatest(UserNoticeModel.actions.START_POLLING_OUTSTANDING_NOTICES, UserNoticeSaga, 'pollOutstandingNotices'),
        takeLatest(UserNoticeModel.actions.QUERY_DETAILS, tryCatchWrapper, contextSaga(UserNoticeSaga, 'queryDetails')),

        contextTakeLatest(NoticeTemplateModel.actions.START_POLLING_DETAILS, NoticeTemplateSaga, 'pollDetails'),
        contextTakeLatest(UserServiceModel.actions.START_POLLING_DETAILS, UserServiceSaga, 'pollDetails'),
        contextTakeLatest(UserServiceModel.actions.QUERY_DETAILS, UserServiceSaga, 'queryDetails'),

        contextTakeEvery(UserCommentModel.actions.START_POLLING_DETAILS, UserCommentSaga, 'pollDetails'),
        contextTakeEvery(UserCommentModel.actions.QUERY_DETAILS, UserCommentSaga, 'queryDetails'),

        contextFork(CurrentUserSaga, 'pollFeatures'),
        contextFork(SchedulerSaga, 'pollResourcesStatus'),
        contextFork(SchedulerSaga, 'pollNeoPlatformApps'),
        contextFork(SchedulerSaga, 'pollNeoExternalApps'),
        fork(function * () {
            yield contextCall(UserSettingsSaga, 'updateUserSettings');
            yield contextCall(UserSettingsSaga, 'pollSettings');
        })
    ]);
}

function* startRelativityApplicationGlobalSagas() {
    yield all([
        ...RelativityProxySaga.buildGlobalEffects(),
    ]);
}

function* startGlobalSagas() {
    yield all([
        ...WorkflowTemplateSaga.buildGlobalEffects(),
        ...ThirdPartyServiceSaga.buildGlobalEffects()
    ]);
}

function* startWatchingIsChangedSagas () {
    yield all([
        contextTakeLatest([PolicyModel.actions.SET_DETAILS_MAP, PolicyModel.actions.ADD_DETAILS,
            PolicyModel.actions.UPDATE_DETAILS, PolicyModel.actions.DELETE_DETAILS], PolicySaga, 'isDetailsMapChanged'),
        takeLatest(AutomateLicenceModel.actions.SET_LICENCE, tryCatchWrapper, contextSaga(AutomateLicenceSaga, 'isAutomateLicenceChanged')),
    ]);
}

export default function*(dispatch) {
    yield takeLeading(EditModel.actions.CALL_EDIT_BREAKING_FUNC, tryCatchWrapper, contextSaga(EditSaga, 'callEditBreakingFunc'));
    yield all(SchedulerSaga.buildActivationEffects(dispatch));

    yield call(onAppStart, dispatch);
};