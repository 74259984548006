import {useTranslation} from "react-i18next";
import {createToggleHandler} from "../../common/Checkbox/helpers";
import {updateMode} from "../../../utilities/constants";
import {createNestedUpdateState} from "../../../utilities/componentFunctions";
import {chunkArray, getValues, isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import {jobEventTriggerKeys, jobSubmissionMechanismKeys, priorityKeys} from "../../../i18next/keys";
import Checkbox from "../../common/Checkbox/Checkbox";
import ToggleSwitch from "../../common/ToggleSwitch/ToggleSwitch";
import React from "react";
import {StatusLabel} from "../../common/Common";
import PropertyFilterRow from "./PropertyFilterRow";

function JobEventTriggersPanel(props) {
    const {t} = useTranslation(['job', 'jobSchedule']);
    const {eventTrigger, updateState} = props;

    const toggleEnable = createToggleHandler({
        mode: updateMode.REDUX,
        updateState: createNestedUpdateState(updateState, ['eventTrigger', 'jobEvents'])
    });

    const {
        jobEvents,
        jobName,
        description,
        clientName,
        matterName,
        libraryName,
        workflowName,
        errorText,
        priority,
        submissionMechanism
    } = eventTrigger;

    const chunkEvents = chunkArray(getValues(jobEventTriggerKeys), 3);
    const required = isNotEmptyNorFalsy(jobEvents) ? '' : ' is-required';
    return (
        <>
            <div className="display-input">
                <label className="label is-heading">
                    {t('jobSchedule:label.jobEvents')}
                </label>
                <label className="label is-note">
                    {t('jobSchedule:message.jobEventsTriggerSchedule')}
                </label>

                <div className={'settings-table no-border tight-padding display-input' + required} style={{border: '1px solid transparent'}}>
                    <div className="table-row-group">
                        {chunkEvents.map((evnts, index) =>

                            <div key={index} className="settings-row">
                                {evnts.map(evnt =>

                                    <div key={evnt} className="table-cell no-stretch">
                                        <Checkbox name={evnt} label={t(`job:trigger.${evnt}`)} isWordWrap={false}
                                            checked={jobEvents[evnt]} onClick={toggleEnable}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="display-item">
                <label className="label is-heading">
                    {t('jobSchedule:label.propertyFilters')}
                </label>
                <label className="label is-note">
                    {t('jobSchedule:message.jobEventsTriggerPropertyFilterUsage')}
                </label>

                <div className="settings-table align-middle tight-padding no-table-padding no-border" style={{marginTop: '0.5rem'}}>
                    <div className="table-header-group" style={{background: 'none'}}>
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('jobSchedule:label.property')}
                            </label>
                        </div>
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('jobSchedule:label.contains')}
                            </label>
                        </div>
                    </div>

                    <div className="table-row-group">
                        <SubmissionMechanismPropertyFilterRows submissionMechanism={submissionMechanism} updateState={updateState}/>

                        <PropertyFilterRow label={t('jobSchedule:filter.jobName')} name={'jobName'} property={jobName} updateState={updateState}/>

                        <PropertyFilterRow label={t('jobSchedule:filter.description')} name={'description'} property={description} updateState={updateState}/>

                        <PropertyFilterRow label={t('jobSchedule:filter.clientName')} name={'clientName'} property={clientName} updateState={updateState}/>

                        <PropertyFilterRow label={t('jobSchedule:filter.matterName')} name={'matterName'} property={matterName} updateState={updateState}/>

                        <PropertyFilterRow label={t('jobSchedule:filter.libraryName')} name={'libraryName'} property={libraryName} updateState={updateState}/>

                        <PropertyFilterRow label={t('jobSchedule:filter.workflowName')} name={'workflowName'} property={workflowName} updateState={updateState}/>

                        {jobEvents[jobEventTriggerKeys.JOB_ERROR] &&
                            <PropertyFilterRow label={t('jobSchedule:filter.errorText')} name={'errorText'} property={errorText} updateState={updateState}/>
                        }

                        <PriorityPropertyFilterRow label={t('jobSchedule:filter.priorities')} priority={priority} updateState={updateState}/>
                    </div>
                </div>
            </div>
        </>
    )
}

function SubmissionMechanismPropertyFilterRows(props) {
    const {submissionMechanism, updateState} = props;
    const {t} = useTranslation(['jobSchedule']);

    const toggleEnable = createToggleHandler({
        mode: updateMode.REDUX,
        updateState: createNestedUpdateState(updateState, ['eventTrigger', 'submissionMechanism'])
    });

    const {enabled, ...rest} = submissionMechanism;
    const required = enabled && !isNotEmptyNorFalsy(rest) ? ' is-required' : '';

    return (
        <div className="settings-row">
            <div className="table-cell no-stretch">
                <ToggleSwitch id={'submissionMechanismToggle'} label={`${t('jobSchedule:filter.submissionMechanism')}:`} name={'enabled'}
                    checked={enabled} onClick={toggleEnable} isWordWrap={false}
                />
            </div>

            <div className="table-cell" style={{display: 'flex'}}>

                <div className={'checkbox-row' + required}>
                    {getValues(jobSubmissionMechanismKeys).map(key =>
                        <Checkbox key={key} name={key} label={t(`jobSchedule:submissionMechanism.${key}`)}
                            checked={submissionMechanism[key]} onClick={toggleEnable} isDisabled={!submissionMechanism.enabled}
                        />
                    )}
                </div>

                {submissionMechanism.SCHEDULED && enabled &&
                    <StatusLabel message={t('jobSchedule:message.filterScheduledJobWarning')}/>
                }
            </div>
        </div>
    )
}

function PriorityPropertyFilterRow(props) {
    const {label, priority, updateState} = props;
    const {t} = useTranslation(['common']);

    const toggleEnable = createToggleHandler({
        mode: updateMode.REDUX,
        updateState: createNestedUpdateState(updateState, ['eventTrigger', 'priority'])
    });

    const {enabled, ...rest} = priority;
    const required = enabled && !isNotEmptyNorFalsy(rest) ? ' is-required' : '';

    return (
        <div className="settings-row">
            <div className="table-cell no-stretch">
                <ToggleSwitch id={'priorityAnyOfToggle'} label={`${label}:`} name={'enabled'} isWordWrap={false}
                    checked={priority.enabled} onClick={toggleEnable}
                />
            </div>

            <div className="table-cell" style={{display: 'flex'}}>

                <div className={'checkbox-row' + required}>
                    {getValues(priorityKeys).map(key =>
                        <Checkbox key={key} name={key} label={t(`common:priority.${key}`)}
                            checked={priority[key]} onClick={toggleEnable} isDisabled={!priority.enabled}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default JobEventTriggersPanel;
