import React, {useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {useTranslation, withTranslation} from 'react-i18next';
import Checkbox from '../common/Checkbox/Checkbox';
import {createToggleHandler} from "../common/Checkbox/helpers";
import TextArea from '../common/TextArea/TextArea';
import Form, {FormFooter, FormHeader} from "../common/Form/Form";
import {shouldEnableClient} from "../../utilities/shouldEnableFunctions";
import {ExecutionProfileDropdown, ResourcePoolDropdown} from "../common/Dropdown/Dropdown";
import {createDropdownHandler} from "../common/Dropdown/helpers";
import ClientModel from "../../models/client/ClientModel";
import PopupModel from "../../models/scheduler/PopupModel";
import KeyValueList from "../common/KeyValueList/KeyValueList";
import ExpandableContent from "../common/ExpandableContent/ExpandableContent";
import {FormElementRow} from "../common/CustomTable/CustomTable";
import {getUserSettingsClientAllowedParameterValues} from "../../reselect/selectors";
import {createCloseHandler, createInputHandler, createStateHandler} from "../../utilities/componentFunctions";
import ParameterModel from "../../models/library/ParameterModel";
import {InputTableV2} from "../common/InputTable/InputTable";
import DatasetModel from "../../models/data/DatasetModel";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import {onWorkflowParameterNormalize} from "../common/InputTable/helpers";


function ClientForm(props) {
    const {t} = useTranslation(['client', 'workflow', 'dataset', 'common']);
    const dispatch = useDispatch();

    const [state, setState] = useState({
        clientName: '',
        description: '',
        enabled: true,
        defaultExecutionProfileId: null,
        defaultResourcePoolId: null,
        isAddEnabled: false
    });
    const [allowedParameterValues, setAllowedParameterValues] = useState(props.allowedParameterValues);
    const [requiredMetadataHeaders, setRequiredMetadataHeaders] = useState([]);
    const [workflowParameters, setWorkflowParameters] = useState([]);

    const stateHandler = createStateHandler({
        updateState: setState,
        shouldEnable: shouldEnableClient
    })

    const inputHandler = createInputHandler({
        handler: stateHandler
    });
    const toggleEnable = createToggleHandler({
        handler: stateHandler
    });
    const dropdownItemClick = createDropdownHandler({
        handler: stateHandler
    });

    const [clearParameterPasswordState, setClearParameterPasswordState] = useState({})
    const workflowParameterNormalizeHandler = onWorkflowParameterNormalize(clearParameterPasswordState, setClearParameterPasswordState);

    const onClose = createCloseHandler({
        t,
        item: t('client:label.name'),
        values: {...state, allowedParameterValues},
        blacklist: [props.allowedParameterValues.length > 0 ? 'allowedParameterValues' : ''],
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        close: () => dispatch(ClientModel.componentActionCreators.updatePage({isClientFormActive: false}))
    });

    function onSubmit() {
        dispatch(ClientModel.actionCreators.submitForm({
            ...state,
            allowedParameterValues,
            requiredMetadataHeaders,
            workflowParameters
        }));
    }

    const {
        isDisabled
    } = props;

    const {
        clientName,
        description,
        enabled,
        defaultExecutionProfileId,
        defaultResourcePoolId,
        isAddEnabled
    } = state;

    return (
        <Form onClose={onClose} isDisabled={isDisabled} closeButtonAriaLabel={t('client:option.closeForm')}
            header={
                <FormHeader text={t('client:label.name')} iconName={'client'} isDisabled={isDisabled}/>
            }
            body={
                <>
                    <div className="display-input">
                        <HTMLTextInput label={t('common:label.name')} name={'clientName'} value={clientName} onChange={inputHandler}
                            isDisabled={isDisabled} isRequired />
                    </div>

                    <Checkbox label={t('common:label.enabled')} name={'enabled'} checked={enabled} onClick={toggleEnable}
                        isDisabled={isDisabled}/>

                    <div className="display-input">
                        <TextArea label={t('common:label.description')} name={'description'} value={description} onChange={inputHandler}
                            isDisabled={isDisabled}/>
                    </div>

                    <div className="forms-table display-input">
                        <div className="table-row-group">
                            <FormElementRow label={t('client:label.defaultExecutionProfile')} isDisabled={isDisabled}
                                element={
                                    <ExecutionProfileDropdown name={'defaultExecutionProfileId'}
                                        selectedExecutionProfileId={defaultExecutionProfileId} onExecutionProfileSelect={dropdownItemClick}/>
                                }/>

                            <FormElementRow label={t('client:label.defaultResourcePool')} isDisabled={isDisabled}
                                element={
                                    <ResourcePoolDropdown name={'defaultResourcePoolId'}
                                        selectedResourcePoolId={defaultResourcePoolId} onResourcePoolSelect={dropdownItemClick}/>
                                }/>
                        </div>
                    </div>

                    <div className="display-input">
                        <ExpandableContent label={t('client:label.workflowParameters')} initialExpanded={false}>
                            <InputTableV2 headers={[{value: t('client:header.name')}, {value: t('client:header.value')}]}
                                inputTable={workflowParameters} setInputTable={setWorkflowParameters}
                                ariaLabelKey={'Parameter'}
                                isDisabled={isDisabled} defaultRow={ClientModel.workflowParametersRowTemplate}
                                blurHandler={[ParameterModel.normalizeParameterName, null]} inputNormalizer={workflowParameterNormalizeHandler}
                            />
                        </ExpandableContent>
                    </div>

                    <div className="display-input">
                        <ExpandableContent label={t('client:label.allowedParameters')} initialExpanded={allowedParameterValues.length > 0}
                            headerBarStyle={{marginBottom: '0.25rem'}}
                        >
                            <KeyValueList keysTitle={t('workflow:label.parameterName')} blurHandler={ParameterModel.normalizeParameterName}
                                ariaLabelKey={'Parameter'} entries={allowedParameterValues} setEntries={setAllowedParameterValues} isMoveEnabled={true}/>
                        </ExpandableContent>
                    </div>

                    <div className="display-input">
                        <ExpandableContent label={t('dataset:label.requiredMetadataHeaders')} initialExpanded={false}>
                            <InputTableV2 headers={[{value: t('dataset:label.header')}, {value: t('dataset:label.regex')}]}
                                inputTable={requiredMetadataHeaders} setInputTable={setRequiredMetadataHeaders}
                                ariaLabelKey={'RequiredMetadata'}
                                defaultRow={DatasetModel.requiredMetadataHeadersRowTemplate} isDisabled={isDisabled}
                            />
                        </ExpandableContent>
                    </div>
                </>
            }
            footer={
                <FormFooter addText={t('common:button.addClient')} onAddClick={onSubmit} isAddEnabled={isAddEnabled}
                    onCancel={onClose} isDisabled={isDisabled}/>
            }
        />
    )
}

const mapStateToProps = state => {
    const {componentStates: {clientForm}} = state;

    return {
        ...clientForm,
        allowedParameterValues: getUserSettingsClientAllowedParameterValues(state)
    }
};

export default connect(mapStateToProps)(withTranslation(['client', 'common'])(ClientForm));
