import {deepCopy, getValues, mergeMaps, objEquals, shallowCopy} from "../../utilities/helperFunctions";

class ReduxModel {

    static addDetailsGeneric(state = new Map(), details, key) {
        const model = new this(details);

        return new Map(state).set(model[key], model);
    }

    static deleteDetails(state, key) {
        const newState = new Map(state);
        newState.delete(key);

        return newState;
    }

    static buildNewDetailsMap(state, details, key, keepOld) {
        const newDetails = new Map();

        for (let i = 0; i < details.length; i++) {
            const model = new this(details[i]);
            const oldModel = state.get(model[key]);

            if (model.equals(oldModel) || (keepOld && keepOld.has(model[key]))) {
                newDetails.set(model[key], oldModel);
            } else {
                newDetails.set(model[key], model);
            }
        }

        return newDetails;
    }

    static setDetailsMapGeneric(state = new Map(), details, key, keepOld) {
        const newState = this.buildNewDetailsMap(state, details, key, keepOld);
        // Check if state has been updated
        if (newState.size !== state.size) {
            return newState;
        }

        const values = getValues(newState);
        const oldValues = getValues(state);
        for (let i = 0; i < values.length; i++) {
            // If there's a new reference (re-ordered/state updated) return new state
            if (values[i] !== oldValues[i]) {
                return newState;
            }
        }
        // Else, return old state
        return state;
    }

    static setDetailsArray(state = [], details) {
        const newState = [];

        for (let i = 0; i < details.length; i++) {
            const model = new this(details[i]);
            const oldModel = state[i];

            if (model.equals(oldModel)) {
                newState.push(oldModel);
            } else {
                newState.push(model);
            }
        }

        // Check if state has been updated
        if (newState.length !== state.length) {
            return newState;
        }

        for (let i = 0; i < newState.length; i++) {
            // If there is a new reference (means new/updated model) return newState
            if (newState[i] !== state[i]) {
                return newState;
            }
        }

        // Else, return old state
        return state;
    }

    // Generic to cover simple types (number, string, array)
    static setValue(state = new Map(), id, value) {
        const oldValue = state.get(id);

        // If values are different, update
        if (!objEquals(oldValue, value)) {
            return new Map(state).set(id, value);
        }

        return state;
    }

    static bulkUpdateDetails(state = new Map(), keyToUpdates) {
        const newPartialMap = new Map();

        Object
            .keys(keyToUpdates)
            .forEach(key => {
                const updates = keyToUpdates[key];

                const oldModel = state.get(key);
                const model = (oldModel || new this()).duplicate(updates);

                if (model.equals(oldModel)) {
                    newPartialMap.set(key, oldModel);
                } else {
                    newPartialMap.set(key, model);
                }
            });

        for (const [key, model] of newPartialMap) {
            // If there is a new reference (means a model was updated), return newPartialMap merged into state
            if (model !== state.get(key)) {
                return mergeMaps(state, newPartialMap);
            }
        }

        // If not, return old state
        return state;
    }

    nullUpdate(updates) {
        Object
            .keys(updates)
            .filter(prop => this[prop] == null)
            .forEach(prop => {
                this[prop] = updates[prop];
            })

        return this;
    }

    forceUpdate(updates) {
        Object
            .keys(updates)
            .forEach(prop => {
                this[prop] = updates[prop];
            });

        return this;
    }

    update(updates) {
        if (updates != null) {
            Object
                .keys(updates)
                .filter(prop => prop in this)
                .forEach(prop => {
                    this[prop] = updates[prop];
                });
        }

        return this;
    }

    shallowDuplicate(updates) {
        return shallowCopy(this).update(updates);
    }

    duplicate(updates) {
        return deepCopy(this).update(updates);
    }

    equals(obj) {
        return objEquals(this, obj);
    }
}

export default ReduxModel;