import React, {Component} from 'react';
import {connect} from 'react-redux';
import {useTranslation, withTranslation} from "react-i18next";
import Tablet, {EditTabletHeader} from '../common/Tablet/Tablet';
import {
    getBuiltInPolicyPrincipalValueTypes,
    getBuiltInPolicyScopeNameValueTypes,
    getClientNameValueTypes,
    getClientPoolNameValueTypes,
    getDataRepositoryNameValueTypes,
    getExecutionProfileNameValueTypes,
    getFileLibraryNameValueTypes,
    getLegalHoldNameValueTypes,
    getLibraryNameValueTypes,
    getMatterNameValueTypes,
    getNoticeTemplateNameValueTypes,
    getNotificationRuleNameValueTypes,
    getNuixLicenceSourceNameValueTypes,
    getResourcePoolNameValueTypes,
    getTabletUpdatedPolicyDetailsScope,
    getThirdPartyServiceNameValueTypes,
    getUserServiceNameValueTypes,
    getWorkflowTemplateNameValueTypes,
    makeGetEditDetails
} from "../../reselect/selectors";
import {getModifyPrivilegedAction, onInputChange,} from "../../utilities/componentFunctions";
import {DefaultEditPanel} from "../common/EditPanel/EditPanel";
import ExpandableContent, {
    ExpandableChangeLogTable,
    ExpandableEditTextArea,
    ExpandableWarningAlertTable
} from "../common/ExpandableContent/ExpandableContent";
import {SettingsRowValue} from "../common/CustomTable/CustomTable";
import {checkNestedExists, isNotEmptyNorFalsy, objectTruthyValues, switchcase} from "../../utilities/helperFunctions";
import ListContainer, {SwitchListContainers} from "../common/ListContainer/ListContainer";
import {PolicyPrincipalTypeDropdown, PolicyScopeTypeDropdown} from "../common/Dropdown/Dropdown";
import {onMenuOptionClick} from "../common/Dropdown/helpers";
import {
    addInputToList,
    addItemsToList,
    addScopesToList,
    getScopeChildItems,
    getScopeItems,
    getScopeParentItems,
    getSelectedParents,
    onAddItemsToList,
    onSelectListItem,
    removeInputFromList,
    removeScopesFromList
} from "../common/ListContainer/helpers";
import {TypeSelectableItem} from "../common/SelectableItem/SelectableItem";
import {shouldEnablePolicy} from "../../utilities/shouldEnableFunctions";
import {
    permissionKeys,
    policyPrincipalTypeKeys,
    policyScopeTypeKeys,
    policyScopeTypeOptionKeys
} from "../../i18next/keys";
import {updateMode} from "../../utilities/constants";
import PopupModel from "../../models/scheduler/PopupModel";
import PolicyModel from "../../models/settings/PolicyModel";
import EditModel from "../../models/scheduler/EditModel";
import SchedulerModel from "../../models/scheduler/SchedulerModel";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";


class PolicyTablet extends Component {

    constructor(props) {
        super(props);

        this.state = {
            expanded: {
                principals: true,
                permissions: true,
                scope: true
            }
        };

        this.onToggleExpanded = this.onToggleExpanded.bind(this);

        this.editPolicy = this.editPolicy.bind(this);
        this.togglePolicyActive = this.togglePolicyActive.bind(this);
        this.deletePolicy = this.deletePolicy.bind(this);

        this.getModifyPrivilegedAction = getModifyPrivilegedAction('policy', this.props.t('policy.label.name_simple')).bind(this);
        this.onMenuOptionClick = onMenuOptionClick.bind(this);

        this.onEditSelectPrincipalType = this.onEditSelectPrincipalType.bind(this);
        this.onEditInputChangePrincipal = this.onEditInputChangePrincipal.bind(this);
        this.onEditAddPrincipals = this.onEditAddPrincipals.bind(this);
        this.onEditRemovePrincipals = this.onEditRemovePrincipals.bind(this);
        this.onEditSelectAvailablePrincipals = onSelectListItem({
            mode: updateMode.EDIT,
            listName: 'availablePrincipals'
        }).bind(this);
        this.onEditSelectSelectedPrincipals = onSelectListItem({
            mode: updateMode.EDIT,
            listName: 'selectedPrincipals'
        }).bind(this);

        this.onEditAddPermissions = onAddItemsToList({
            mode: updateMode.EDIT,
            listTo: 'selectedPermissions',
            listFrom: 'availablePermissions',
            shouldEnable: shouldEnablePolicy
        }).bind(this);
        this.onEditRemovePermissions = onAddItemsToList({
            mode: updateMode.EDIT,
            listTo: 'availablePermissions',
            listFrom: 'selectedPermissions',
            shouldEnable: shouldEnablePolicy
        }).bind(this);
        this.onEditSelectAvailablePermissions = onSelectListItem({
            mode: updateMode.EDIT,
            listName: 'availablePermissions'
        }).bind(this);
        this.onEditSelectSelectedPermissions = onSelectListItem({
            mode: updateMode.EDIT,
            listName: 'selectedPermissions'
        }).bind(this);

        this.onEditSelectScopeType = this.onEditSelectScopeType.bind(this);
        this.onEditSelectScopeParent = this.onEditSelectScopeParent.bind(this);
        this.onEditAddScopes = this.onEditAddScopes.bind(this);
        this.onEditRemoveScopes = this.onEditRemoveScopes.bind(this);
        this.onEditSelectAvailableScopes = onSelectListItem({
            mode: updateMode.EDIT,
            listName: 'availableScopes'
        }).bind(this);
        this.onEditSelectSelectedScopes = onSelectListItem({
            mode: updateMode.EDIT,
            listName: 'selectedScopes'
        }).bind(this);

        this.onEditInputChange = onInputChange({
            mode: updateMode.EDIT,
            shouldEnable: shouldEnablePolicy
        }).bind(this);
    }

    componentDidUpdate(prevProps) {
        const type = switchcase({
            [policyScopeTypeOptionKeys.CLIENT_ID_MATTER_ID]: policyScopeTypeKeys.MATTER_ID,
            [policyScopeTypeOptionKeys.LIBRARY_ID_WORKFLOW_ID]: policyScopeTypeKeys.WORKFLOW_ID
        })()(this.props.scopeType);

        if (this.props.scopeValues[type] !== prevProps.scopeValues[type]) {
            const {selectedScopes, availableScopeParents, scopeType} = this.props;
            const items = getScopeChildItems.call(this, scopeType, selectedScopes, availableScopeParents);

            this.props.updateEdit({
                availableScopes: {
                    ...this.props.availableScopes,
                    items
                }
            });
        }
    }

    onEditSelectPrincipalType(event) {
        const {value} = event.currentTarget.dataset;
        //To reset selectedItems
        this.props.updateEdit({
            principalType: value,
            availablePrincipals: {
                ...this.props.availablePrincipals,
                selectedItems: {}
            }
        });
    }

    onEditInputChangePrincipal(event) {
        const {value} = event.target;

        this.props.updateEdit({
            availablePrincipals: {
                ...this.props.availablePrincipals,
                inputValue: value
            }
        });
    }

    onEditAddPrincipals() {
        const {selectedPrincipals: prevTo, availablePrincipals: prevFrom, principalType} = this.props;
        const {to, from} = addItemsToList(prevTo, prevFrom);

        if (principalType !== policyPrincipalTypeKeys.BUILTIN) {
            addInputToList(to, prevFrom, principalType);
        }

        this.props.updateEdit({
            availablePrincipals: {
                ...prevFrom,
                items: from,
                selectedItems: {},
                inputValue: ''
            },
            selectedPrincipals: {
                ...prevTo,
                items: to
            }
        });
        this.props.setEditSaveEnabled(true);
    };

    onEditRemovePrincipals() {
        const {availablePrincipals: prevTo, selectedPrincipals: prevFrom, principalType} = this.props;
        const {to, from, inputValue} = removeInputFromList(prevTo, prevFrom, principalType, policyPrincipalTypeKeys.BUILTIN);

        this.props.updateEdit({
            availablePrincipals: {
                ...prevTo,
                items: to,
                inputValue
            },
            selectedPrincipals: {
                ...prevFrom,
                items: from,
                selectedItems: {}
            }
        });
        this.props.setEditSaveEnabled(true);
    }

    onEditSelectScopeType(event) {
        const {value: scopeType} = event.currentTarget.dataset;

        const parentItems = getScopeParentItems.call(this, scopeType, this.props.selectedScopes);
        const items = getScopeItems.call(this, scopeType, this.props.selectedScopes);

        this.props.updateEdit({
            scopeType,
            availableScopeParents: {
                ...this.props.availableScopeParents,
                items: parentItems,
                selectedItems: {}
            },
            availableScopes: {
                ...this.props.availableScopes,
                items,
                selectedItems: {}
            }
        });
    }

    onEditSelectScopeParent(event) {
        const selectedParents = getSelectedParents.call(this, event, this.props.availableScopeParents.items);

        const {selectedScopes, scopeType} = this.props;
        const items = getScopeChildItems.call(this, scopeType, selectedScopes, selectedParents);

        this.props.updateEdit({
            availableScopeParents: {
                ...this.props.availableScopeParents,
                selectedItems: selectedParents.selectedItems
            },
            availableScopes: {
                ...this.props.availableScopes,
                items,
                selectedItems: {}
            }
        });
    }

    onEditAddScopes() {
        const {selectedScopes: prevTo, availableScopes: prevFrom, availableScopeParents: prevParents} = this.props;
        const {to, from, parents} = addScopesToList.call(this, prevTo, prevFrom, prevParents);

        this.props.updateEdit({
            availableScopeParents: {
                ...prevParents,
                ...parents
            },
            availableScopes: {
                ...prevFrom,
                items: from,
                selectedItems: {}
            },
            selectedScopes: {
                ...prevTo,
                items: to
            }
        });
        this.props.setEditSaveEnabled(true);
    }

    onEditRemoveScopes() {
        const {availableScopes: prevTo, selectedScopes: prevFrom, availableScopeParents: prevParents, scopeType} = this.props;
        const {to, from, parentItems} = removeScopesFromList.call(this, prevTo, prevFrom, prevParents, scopeType);

        this.props.updateEdit({
            availableScopeParents: {
                ...prevParents,
                items: parentItems
            },
            availableScopes: {
                ...prevTo,
                items: to
            },
            selectedScopes: {
                ...prevFrom,
                items: from,
                selectedItems: {}
            }
        });
        this.props.setEditSaveEnabled(true);
    }

    editPolicy() {
        this.props.editPolicy(this.props.policyId);
    }

    togglePolicyActive() {
        this.props.togglePolicyActive(this.props.policyId);
    }

    deletePolicy() {
        this.props.promptDeletePolicy(this.props.policyId);
    }

    onToggleExpanded(name, isExpanded) {
        this.setState(prev => ({
            expanded: {
                ...prev.expanded,
                [name]: isExpanded
            }
        }));
    }

    render() {

        const {
            t,
            updatedScope,
            policyName,
            enabled,
            hasWarnings,
            warnings,
            description,
            principals,
            permissions,
            auditLog,
            selectedPrincipals,
            availablePrincipals,
            selectedPermissions,
            availablePermissions,
            selectedScopes,
            availableScopes,
            availableScopeParents,
            principalType,
            scopeType,
            userPermissions,
            isEditActive,
            isSaveEnabled,
            isDisabled,

            policyId
        } = this.props;

        const menuOptions = [
            {name: t('common:option.edit'), value: 'editPolicy'},
            {name: t(`common:option.${enabled ? 'deactivate' : 'activate'}`), value: 'togglePolicyActive'},
            {name: t('common:option.delete'), value: 'deletePolicy', isDisabled: false}
        ];


        const descriptionProps = {
            label: t('common:label.description'),
            name: 'description',
            value: description,
            onChange: this.getModifyPrivilegedAction(this.onEditInputChange),
            isEditActive,
            isDisabled
        };

        const principalTypeDropdownProps = {
            selectedPrincipalType: principalType,
            onPrincipalTypeSelect: this.getModifyPrivilegedAction(this.onEditSelectPrincipalType),
            isEdit: isEditActive,
            isDisabled
        };
        const principalsSwitchListContainersProps = {
            leftListContainerProps: {
                label: t('common:label.available'),
                onItemClick: this.onEditSelectAvailablePrincipals,
                ...availablePrincipals,
                items: checkNestedExists(availablePrincipals, 'items') && availablePrincipals.items
                    .map(item => ({
                        ...item,
                        name: t(`policy:principalBuiltInType.${item.value}`)
                    }))
            },
            textInputProps: {
                title: t(`policy:principalType.${principalType}`),
                value: availablePrincipals && availablePrincipals.inputValue,
                onChange: this.getModifyPrivilegedAction(this.onEditInputChangePrincipal),
                style: {flex: '1'}
            },
            navigateButtonsProps: {
                onClick: [
                    this.onEditAddPrincipals,
                    this.onEditRemovePrincipals
                ],
                length: [
                    availablePrincipals && (principalType === policyPrincipalTypeKeys.BUILTIN ? objectTruthyValues(availablePrincipals.selectedItems).length : (availablePrincipals.inputValue ? 1 : 0)),
                    selectedPrincipals && objectTruthyValues(selectedPrincipals.selectedItems).length
                ]
            },
            rightListContainerProps: {
                label: t('common:label.selected'),
                onItemClick: this.onEditSelectSelectedPrincipals,
                itemComponent: TypeSelectableItem,
                ...selectedPrincipals,
                items: checkNestedExists(selectedPrincipals, 'items') && selectedPrincipals.items
                    .map(item => ({
                        ...item,
                        name: item.type === policyPrincipalTypeKeys.BUILTIN ? t(`policy:principalBuiltInType.${item.value}`) : item.value,
                        type: t(`policy:principalType.${item.type}`)
                    }))
            }
        };
        const principalsProps = {
            principals,
            principalTypeDropdownProps,
            principalsSwitchListContainersProps,
            isEditActive,
            isDisabled
        };

        const permissionsSwitchListContainersProps = {
            leftListContainerProps: {
                label: t('common:label.available'),
                onItemClick: this.onEditSelectAvailablePermissions,
                ...availablePermissions,
                items: checkNestedExists(availablePermissions, 'items') && availablePermissions.items
                    .map(item => ({
                        ...item,
                        name: t(`common:permission.${item.value}`)
                    }))
            },
            navigateButtonsProps: {
                onClick: [
                    this.onEditAddPermissions,
                    this.onEditRemovePermissions,
                ],
                length: [
                    availablePermissions && objectTruthyValues(availablePermissions.selectedItems).length,
                    selectedPermissions && objectTruthyValues(selectedPermissions.selectedItems).length
                ]
            },
            rightListContainerProps: {
                label: t('common:label.selected'),
                onItemClick: this.onEditSelectSelectedPermissions,
                ...selectedPermissions,
                items: checkNestedExists(selectedPermissions, 'items') && selectedPermissions.items
                    .map(item => ({
                        ...item,
                        name: t(`common:permission.${item.value}`)
                    }))
            }
        };
        const permissionsProps = {
            permissions,
            permissionsSwitchListContainersProps,
            isEditActive,
            isDisabled
        };

        const scopeTypeDropdownProps = {
            selectedScopeType: scopeType,
            onScopeTypeSelect: this.getModifyPrivilegedAction(this.onEditSelectScopeType),
            isEdit: isEditActive,
            isDisabled
        };
        const scopeSwitchListContainersProps = {
            parentContainerProps: {
                onItemClick: this.onEditSelectScopeParent,
                ...availableScopeParents
            },
            childContainerProps: {
                onItemClick: this.onEditSelectAvailableScopes,
                ...availableScopes,
                items: checkNestedExists(availableScopes, 'items') && availableScopes.items
                    .map(item => {
                        //FOR ALL MATTERS/WORKFLOWS
                        switch (item.type) {
                            case policyScopeTypeKeys.CLIENT_ID:
                            case policyScopeTypeKeys.CLIENT_ID_MATTERS:
                            case policyScopeTypeKeys.LIBRARY_ID:
                                return {
                                    ...item,
                                    name: t(`policy:scopeTypeName.${item.type}`),
                                    isItalic: true
                                };
                            case policyScopeTypeKeys.BUILTIN:
                                return {
                                    ...item,
                                    name: t(`policy:scopeBuiltInType.${item.value}`)
                                };
                            default:
                                return item;
                        }
                    })
            },
            navigateButtonsProps: {
                onClick: [
                    this.onEditAddScopes,
                    this.onEditRemoveScopes
                ],
                length: [
                    availableScopes && objectTruthyValues(availableScopes.selectedItems).length,
                    selectedScopes && objectTruthyValues(selectedScopes.selectedItems).length
                ]
            },
            rightListContainerProps: {
                label: t('common:label.selected'),
                onItemClick: this.onEditSelectSelectedScopes,
                itemComponent: TypeSelectableItem,
                ...selectedScopes,
                items: checkNestedExists(selectedScopes, 'items') && selectedScopes.items
                    .map(item => {
                        const translatedItem = {
                            ...item,
                            type: t(`policy:scopeType.${item.type}`)
                        };
                        if (item.type === policyScopeTypeKeys.BUILTIN) {
                            translatedItem.name = t(`policy:scopeBuiltInType.${item.value}`)
                        }

                        return translatedItem;
                    })
            }
        };
        const scopeProps = {
            scope: updatedScope,
            scopeTypeDropdownProps,
            scopeSwitchListContainersProps,
            isEditActive,
            isDisabled
        };

        const canModifyPolicy = userPermissions.includes(permissionKeys.MODIFY);
        const isDescriptionVisible = isEditActive || description;
        const isPrincipalsVisible = isEditActive || principals.length > 0;
        const isPermissionsVisible = isEditActive || permissions.length > 0;
        const isScopeVisible = isEditActive || updatedScope.length > 0;
        const isWarningsVisible = enabled && !isEditActive && (hasWarnings && !!warnings);

        const {expanded} = this.state;
        const flexExpandChildren = [false, false, expanded.principals, expanded.permissions, expanded.scope];
        return (
            <Tablet width={'70rem'} height={isEditActive ? '85vh' : '50vh'} onClose={this.props.close} isDisabled={isDisabled}
                closeButtonAriaLabel={t('policy:option.closeTablet_name', {name: policyName})}
                header={
                    <EditTabletHeader label={t('policy:label.name')} type={'policy'} enabled={enabled}
                        id={policyId} name={'policyName'} value={policyName} inputHandler={this.onEditInputChange}
                        canModify={canModifyPolicy} menuOptions={menuOptions} menuOptionHandler={this.onMenuOptionClick}
                        isEditActive={isEditActive} isDisabled={isDisabled}
                    />
                }
                body={
                    <div className="display-item flex-expand" style={{height: 'calc(100% - 2rem)'}}>
                        <DefaultEditPanel isActive={isEditActive} isSaveEnabled={isSaveEnabled} onSave={this.props.saveEdit}
                            onCancel={this.props.cancelEdit} flexExpand flexExpandChildren={flexExpandChildren}
                        >
                            {isWarningsVisible &&
                            <ExpandableWarningAlertTable label={t('job:label.warnings')} warnings={warnings}/>
                            }
                            {isDescriptionVisible &&
                            <ExpandableEditTextArea {...descriptionProps} />
                            }
                            {isPrincipalsVisible &&
                                <ExpandableContent label={t('policy:label.principals')} name={'principals'}
                                    onToggleCb={this.onToggleExpanded} flexExpand
                                >
                                    <ExpandablePrincipalsList {...principalsProps} />
                                </ExpandableContent>
                            }
                            {isPermissionsVisible &&
                                <ExpandableContent label={t('policy:label.permissions')} name={'permissions'}
                                    onToggleCb={this.onToggleExpanded} flexExpand
                                >
                                    <ExpandablePermissionsList {...permissionsProps} />
                                </ExpandableContent>
                            }
                            {isScopeVisible &&
                                <ExpandableContent label={t('policy:label.scope')} name={'scope'}
                                    onToggleCb={this.onToggleExpanded} flexExpand
                                >
                                    <ExpandableScopeList {...scopeProps} />
                                </ExpandableContent>
                            }

                            {isNotEmptyNorFalsy(auditLog) && !isEditActive &&
                            <div className="display-item">
                                <ExpandableChangeLogTable label={t('common:label.auditLog')} changeLogs={auditLog}
                                    isDisabled={isDisabled}/>
                            </div>
                            }
                        </DefaultEditPanel>
                    </div>
                }
            />
        );
    }
}

const ExpandablePrincipalsList = props => {
    const {isEditActive, principals, principalTypeDropdownProps, principalsSwitchListContainersProps, isDisabled} = props;
    const {t} = useTranslation(['policy']);

    const principalType = principalTypeDropdownProps.selectedPrincipalType;
    return (
        isEditActive ?
            <>
                <div style={{marginBottom: '0.5rem'}}>
                    <PolicyPrincipalTypeDropdown {...principalTypeDropdownProps} />
                </div>

                <SwitchListContainers id="principalSwitchContainer" ariaLabelKey={'Principal'}
                    navigateButtonsProps={principalsSwitchListContainersProps.navigateButtonsProps}
                    leftContainer={principalType && (principalType === policyPrincipalTypeKeys.BUILTIN ?
                        <ListContainer {...principalsSwitchListContainersProps.leftListContainerProps} isDisabled={isDisabled} />
                        :
                        <div style={{marginTop: '1.35rem'}}>
                            <HTMLTextInput {...principalsSwitchListContainersProps.textInputProps} isDisabled={isDisabled}/>
                        </div>)
                    }
                    rightContainer={
                        <ListContainer {...principalsSwitchListContainersProps.rightListContainerProps} isDisabled={isDisabled}/>
                    }
                />
            </>
            :
            <div className="settings-table">
                <div className="table-row-group">
                    {principals.map(({identifierType, identifierName}) =>
                        <SettingsRowValue key={`${identifierType}:${identifierName}`} label={t(`policy:principalType.${identifierType}`)}
                                value={identifierType === policyPrincipalTypeKeys.BUILTIN ? t(`policy:principalBuiltInType.${identifierName}`) : identifierName}
                                isItalic isWordWrap isDisabled={isDisabled}/>
                    )}
                </div>
            </div>
    )
}

const ExpandablePermissionsList = props => {
    const {isEditActive, permissions, permissionsSwitchListContainersProps, isDisabled} = props;
    const {t} = useTranslation(['common']);

    const disabled = isDisabled ? ' is-disabled' : '';

    return (
        isEditActive ?
            <SwitchListContainers id="permissionSwitchContainer" ariaLabelKey={'Permission'}
                navigateButtonsProps={permissionsSwitchListContainersProps.navigateButtonsProps}
                leftContainer={
                    <ListContainer {...permissionsSwitchListContainersProps.leftListContainerProps} isDisabled={isDisabled} />
                }
                rightContainer={
                    <ListContainer {...permissionsSwitchListContainersProps.rightListContainerProps} isDisabled={isDisabled}/>
                }
            />
            :
            permissions.map((permission) =>
                <label className={'label' + disabled} key={permission}>
                    {t(`common:permission.${permission}`)}
                </label>
            )
    );
}


const ExpandableScopeList = props => {
    const {isEditActive, scope, scopeTypeDropdownProps, scopeSwitchListContainersProps, isDisabled} = props;
    const {t} = useTranslation(['policy']);

    const scopeType = scopeTypeDropdownProps.selectedScopeType;
    const disabled = isDisabled ? ' is-disabled' : '';
    const searchStyle = {
        marginBottom: '0'
    }

    const {
        childContainerProps,
        parentContainerProps,
        rightListContainerProps
    } = scopeSwitchListContainersProps;

    const isChildContainerSearchable = !!childContainerProps.items && childContainerProps.items.length > 10;
    const isParentContainerSearchable = !!parentContainerProps.items && parentContainerProps.items.length > 10;
    const isSelectedContainerSearchable = !!rightListContainerProps.items && rightListContainerProps.items.length > 10;

    return (
        isEditActive ?
            <>
                <div style={{marginBottom: '0.5rem'}}>
                    <PolicyScopeTypeDropdown {...scopeTypeDropdownProps} />
                </div>

                <SwitchListContainers id="scopeSwitchContainer" ariaLabelKey={'Scope'}
                    navigateButtonsProps={scopeSwitchListContainersProps.navigateButtonsProps}
                    leftContainer={scopeType && ([policyScopeTypeOptionKeys.CLIENT_ID_MATTER_ID, policyScopeTypeOptionKeys.LIBRARY_ID_WORKFLOW_ID].includes(scopeType) ?
                            <>
                                <label className={'label is-italic' + disabled} style={{textAlign: 'center'}}>
                                    {t('common:label.available')}
                                </label>
                                <div className="flex-expand is-row">
                                    <div className="flex-expand">
                                        <ListContainer {...scopeSwitchListContainersProps.parentContainerProps} isSearchable={isParentContainerSearchable} searchPlaceholder={t(`policy:searchPlaceholder.${scopeType}`)} searchStyle={searchStyle} isDisabled={isDisabled} />
                                    </div>
                                    <div className="flex-expand">
                                        <ListContainer {...scopeSwitchListContainersProps.childContainerProps} isSearchable={isChildContainerSearchable} searchPlaceholder={t(`policy:searchPlaceholder.${scopeType}_CHILD`)} searchStyle={searchStyle} isDisabled={isDisabled}/>
                                    </div>
                                </div>
                            </>
                            :
                            <ListContainer {...scopeSwitchListContainersProps.childContainerProps}
                                label={t('common:label.available')} isSearchable={isChildContainerSearchable} searchPlaceholder={t(`policy:searchPlaceholder.${scopeType}`)} searchStyle={searchStyle} isDisabled={isDisabled}
                            />
                    )

                    }
                    rightContainer={
                        <ListContainer {...scopeSwitchListContainersProps.rightListContainerProps} isSearchable={isSelectedContainerSearchable} searchPlaceholder={t(`policy:searchPlaceholder.SELECTED_SCOPES`)} searchStyle={searchStyle} isDisabled={isDisabled}/>
                    }
                />
            </>
            :
            <div className="settings-table">
                <div className="table-row-group">
                    {scope.map(({identifierType, identifierName}) =>
                            <SettingsRowValue key={`${identifierType}:${identifierName}`} label={t(`policy:scopeIdentifierType.${identifierType}`)}
                                value={identifierType === policyScopeTypeKeys.BUILTIN ? t(`policy:scopeBuiltInType.${identifierName}`) : identifierName}
                                isItalic isDisabled={isDisabled}/>
                    )}
                </div>
            </div>
    )
};

const makeMapStateToProps = () => {
    const getEditDetails = makeGetEditDetails();
    return state => {
        const {policyDetailsMap, componentStates: {policyDisplay: {policyId}, policyTablet}} = state;
        const {name: policyName, ...otherDetails} = policyDetailsMap.get(policyId);

        const principalValues = {
            [policyPrincipalTypeKeys.BUILTIN]: getBuiltInPolicyPrincipalValueTypes()
        };

        const scopeValues = {
            [policyScopeTypeKeys.BUILTIN]: getBuiltInPolicyScopeNameValueTypes(),
            [policyScopeTypeKeys.CLIENT_ID]: getClientNameValueTypes(state),
            [policyScopeTypeKeys.MATTER_ID]: getMatterNameValueTypes(state),
            [policyScopeTypeKeys.LIBRARY_ID]: getLibraryNameValueTypes(state),
            [policyScopeTypeKeys.WORKFLOW_ID]: getWorkflowTemplateNameValueTypes(state),
            [policyScopeTypeKeys.NUIX_LICENCE_SOURCE_ID]: getNuixLicenceSourceNameValueTypes(state),
            [policyScopeTypeKeys.EXECUTION_PROFILE_ID]: getExecutionProfileNameValueTypes(state),
            [policyScopeTypeKeys.RESOURCE_POOL_ID]: getResourcePoolNameValueTypes(state),
            [policyScopeTypeKeys.CLIENT_POOL_ID]: getClientPoolNameValueTypes(state),
            [policyScopeTypeKeys.NOTIFICATION_RULE_ID]: getNotificationRuleNameValueTypes(state),
            [policyScopeTypeKeys.DATA_REPOSITORY_ID]: getDataRepositoryNameValueTypes(state),
            [policyScopeTypeKeys.THIRD_PARTY_SERVICE_ID]: getThirdPartyServiceNameValueTypes(state),
            [policyScopeTypeKeys.NOTICE_TEMPLATE_ID]: getNoticeTemplateNameValueTypes(state),
            [policyScopeTypeKeys.USER_SERVICE_ID]: getUserServiceNameValueTypes(state),
            [policyScopeTypeKeys.LEGAL_HOLD_ID]: getLegalHoldNameValueTypes(state),
            [policyScopeTypeKeys.FILE_LIBRARY_ID]: getFileLibraryNameValueTypes(state)
        };

        const {activeModel, values, isSaveEnabled} = getEditDetails(state, {model: PolicyModel.nom});
        const isEditActive = (activeModel === PolicyModel.nom);

        return {
            policyId,
            updatedScope: getTabletUpdatedPolicyDetailsScope(state, {policyId}),
            principalValues,
            scopeValues,
            policyName,
            ...otherDetails,
            ...values,
            isEditActive,
            isSaveEnabled,
            ...policyTablet
        };
    }
};

const mapDispatchToProps = dispatch => {
    return {
        yieldEffect: effect => dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(effect)),

        editPolicy: policyId => dispatch(PolicyModel.actionCreators.startEdit(policyId)),
        togglePolicyActive: policyId => dispatch(PolicyModel.actionCreators.toggleEnabled(policyId)),
        promptDeletePolicy: policyId => dispatch(PolicyModel.actionCreators.promptDelete(policyId)),

        ...EditModel.buildDispatchers(dispatch),

        showError: payload => dispatch(PopupModel.actionCreators.showError(payload)),
        close: () => dispatch(PolicyModel.actionCreators.hideTablet())
    };
};

export default connect(makeMapStateToProps, mapDispatchToProps)(withTranslation(['policy', 'common'])(PolicyTablet));