import ReduxModel from "../generics/ReduxModel";
import SagaModel from "../generics/SagaModel";
import ComponentStateModel from "../generics/ComponentStateModel";
import {actionCreator, deepCopy, getEntries, getValues, shallowCopy} from "../../utilities/helperFunctions";
import {all, put, select, take, takeEvery, takeLatest, takeLeading} from "redux-saga/effects";
import {contextCall, contextSaga, isDisabledWrapper} from "../../saga/sagaFunctions";
import {tryCatchWrapper} from "../../saga/tryCatchWrapper";
import JobModel, {JobApi, JobSaga} from "../job/JobModel";
import WorkflowTemplateModel, {WorkflowTemplateApi, WorkflowTemplateSaga} from "../library/WorkflowTemplateModel";
import PopupModel from "../scheduler/PopupModel";
import {validateParameters} from "../../utilities/shouldEnableFunctions";
import {CLIENT_UNASSIGNED, jobQueueStates} from "../../utilities/constants";
import {priorityKeys} from "../../i18next/keys";
import WorkflowBuilderOperation from "../workflowbuilder/WorkflowBuilderOperation";
import ParameterModel from "../library/ParameterModel";
import {
    getOperationFieldParameterName,
    operationFieldParameterNames
} from "../../components/guidedJob/operationFieldParameterNames";
import {ThirdPartyServiceSaga} from "../thirdparty/ThirdPartyServiceModel";

class GuidedJobModel extends ReduxModel {

    static nom = 'GuidedJobModel';
    static actions = GuidedJobModel.buildActions('GUIDED_JOB_MODEL');
    static actionCreators = GuidedJobModel.buildActionCreators(GuidedJobModel.actions);
    static reducer = GuidedJobModel.buildReducer(GuidedJobModel.actions);

    static componentActionCreators = {
        ...GuidedJobModel.buildComponentSetActiveActionCreators()
    }

    constructor(model = {}) {
        super();
        this.forceUpdate(model);

        if (!this.jobName) this.jobName = '';
        if (!this.notes) this.notes = '';
        if (!this.priority) this.priority = priorityKeys.MEDIUM;
        if (!this.queueState) this.queueState = jobQueueStates.BACKLOG;
        if (!this.didUserChange) this.didUserChange = {};
    }

    static validGuideAction(operation, ignoreDisabled) {
        if (operation != null) {
            return operation.enableFieldOverwrite && (ignoreDisabled || !operation.disabled);
        }
        return false;
    }

    static validateFormData(formData) {
        return {
            matterPane: !!(formData.clientId === CLIENT_UNASSIGNED || (formData.clientId && formData.matterId)),
            workflowPane: !!(formData.libraryId && formData.workflowTemplateId),
            jobSettingsPane: !!(formData.jobName && validateParameters(formData.parameters)),
            submitPane: true
        }
    }

    static showImportPopup(values) {
        let showPopupKey = 'showSuccess';
        let popupKey = 'importCsvImported';
        if (values.importedCount !== 1) {
            popupKey += '_plural';
        }
        if (values.invalidCount > 0) {
            showPopupKey = 'showWarning';
            popupKey += 'Skip';
            if (values.invalidCount !== 1) {
                popupKey += '_plural';
            }
        }
        return PopupModel.actionCreators[showPopupKey]({
            info: {key: popupKey, values},
            cancelButton: {titleKey: 'common:option.ok'}
        });
    }

    static buildActions(type) {
        return {
            OPEN: `OPEN_${type}`,
            CLOSE: `CLOSE_${type}`,
            UPDATE: `UPDATE_${type}`,
            SUBMIT: `SUBMIT_${type}`,
            RESET: `RESET_${type}`,

            PREPARE_POPULATE_JOB_SETTINGS_SAGA: `PREPARE_GET_OPERATIONS_AND_PARAMETERS_SAGA_${type}`,
            POPULATE_JOB_SETTINGS: `POPULATE_JOB_SETTINGS_${type}`,
            UPDATE_PARAMETER: `UPDATE_${type}_PARAMETER`,
            LOAD_TSV_PARAMETERS: `LOAD_${type}_TSV_PARAMETERS`,
            RESET_PARAMETERS: `RESET_${type}_PARAMETERS`
        }
    }

    static buildActionCreators(actions) {
        return {
            open: actionCreator(actions.OPEN, 'initialState'),
            close: actionCreator(actions.CLOSE),
            update: actionCreator(actions.UPDATE, 'updates'),
            submit: actionCreator(actions.SUBMIT, 'formData'),
            reset: actionCreator(actions.RESET),

            preparePopulateJobSettingsSaga: actionCreator(actions.PREPARE_POPULATE_JOB_SETTINGS_SAGA),
            populateJobSettings: actionCreator(actions.POPULATE_JOB_SETTINGS),
            updateParameter: actionCreator(actions.UPDATE_PARAMETER, 'name', 'value', 'displayable'),
            loadTsvParameters: actionCreator(actions.LOAD_TSV_PARAMETERS, 'tsvText'),
            resetParameters: actionCreator(actions.RESET_PARAMETERS),
        }
    }

    static buildReducer(actions) {
        return function (state = new GuidedJobModel(), action) {
            switch (action.type) {
                case actions.UPDATE: {
                    let updates = action.payload.updates;
                    if (typeof updates === 'function') {
                        updates = updates(state);
                    }
                    return shallowCopy(state).forceUpdate(updates);
                }
                case actions.RESET: {
                    return new GuidedJobModel();
                }
                default:
                    return state;
            }
        }
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'GUIDED_JOB_DISPLAY',
                type: 'Display'
            }
        ];
        return ComponentStateModel.buildSetActiveActionCreators(...components)
    }
}

export class GuidedJobApi {

}

export class GuidedJobSaga extends SagaModel {

    static ModelType = GuidedJobModel;
    static ModelApi = GuidedJobApi;

    static activationComponent = 'GUIDED_JOB_DISPLAY';

    static buildActivationEffects() {
        return [
            takeLeading(this.ModelType.actions.OPEN, contextSaga(this, 'open')),
            takeLeading(this.ModelType.actions.CLOSE, contextSaga(this, 'close')),
            takeLeading(this.ModelType.actions.SUBMIT, tryCatchWrapper, isDisabledWrapper, this.ModelType.actionCreators.update, contextSaga(this, 'submit')),

            takeLeading(this.ModelType.actions.PREPARE_POPULATE_JOB_SETTINGS_SAGA, contextSaga(this, 'preparePopulateOperationsAndParameters')),
            takeLeading(this.ModelType.actions.PREPARE_POPULATE_JOB_SETTINGS_SAGA, contextSaga(this, 'getJobNameFromMatterAndWorkflow')),
            takeLeading(this.ModelType.actions.POPULATE_JOB_SETTINGS, contextSaga(this, 'populateDefaultJobSettings')),

            takeEvery(this.ModelType.actions.UPDATE_PARAMETER, tryCatchWrapper, contextSaga(this, 'updateParameter')),
            takeLatest(this.ModelType.actions.LOAD_TSV_PARAMETERS, tryCatchWrapper, isDisabledWrapper, this.ModelType.actionCreators.update, contextSaga(this, 'loadTsvParameters')),
            takeLatest(this.ModelType.actions.RESET_PARAMETERS, isDisabledWrapper, this.ModelType.actionCreators.update, contextSaga(this, 'resetParameters')),
        ]
    }

    static buildDeactivationEffects() {
        return [];
    }

    static* open(action) {
        const {initialState} = action.payload;
        yield put(GuidedJobModel.actionCreators.reset());

        const openState = {...initialState, isActive: true, isLoading: true};
        yield put(GuidedJobModel.actionCreators.update(openState));

        yield put(GuidedJobModel.actionCreators.update({isLoading: false}));
    }

    static* close() {
        yield put(GuidedJobModel.actionCreators.reset());
    }

    static* submit() {
        const guidedJobForm = yield select(state => state.guidedJobForm);
        const {
            jobName,
            matterId,
            workflowTemplateId,
            executionProfileId,
            resourcePoolId,
            priority,
            queueState,
            notes,
            parameters
        } = guidedJobForm;

        const job = {
            name: jobName,
            matterId,
            inStaging: queueState === jobQueueStates.STAGING,
            libraryWorkflowId: workflowTemplateId,
            executionProfileId,
            resourcePoolId,
            priority,
            notes,
            sessionParameters: getValues(parameters).map(param => {
                return {
                    ...param,
                    userDisplayableValue: param.getUserDisplayableValue(),
                };
            })
        };

        const {data} = yield contextCall(JobApi, 'post', job);
        yield put(JobModel.actionCreators.addDetails(data));

        yield all([
            put(GuidedJobModel.actionCreators.close()),
            put(JobModel.actionCreators.showTablet(data.id))
        ]);
    }

    static* duplicate(id) {
        try {
            const jobDetailsMap = yield select(state => state.jobDetailsMap);
            const job = jobDetailsMap.get(id);

            yield all([
                put(JobModel.componentActionCreators.updatePage({jobId: null})),
                put(GuidedJobModel.actionCreators.open())
            ]);
            yield put(GuidedJobModel.actionCreators.update({isLoading: true}));

            const [jobName, [clientId, matterId], [libraryId, workflowTemplateId]] = yield all([
                contextCall(JobSaga, 'getUniqueName', job.name, jobDetailsMap),
                contextCall(JobSaga, 'getClientAndMatterId', job.clientId, job.matterId),
                contextCall(JobSaga, 'getLibraryAndWorkflowId', job.libraryId, job.workflowTemplateId),
                contextCall(ThirdPartyServiceSaga, 'queryDetails')
            ]);

            const duplicateJob = {
                ...job,
                jobName,
                clientId,
                matterId,
                libraryId,
                workflowTemplateId
            };
            yield put(GuidedJobModel.actionCreators.update(duplicateJob));

            // Re-query workflow settings
            if (workflowTemplateId != null) {
                yield contextCall(WorkflowTemplateSaga, 'querySettings', WorkflowTemplateModel.actionCreators.querySettings(workflowTemplateId));
                duplicateJob.operations = yield select(state => state.templateOperationsMap.get(workflowTemplateId));
            }

            const parameters = yield select(state => deepCopy(state.parametersMap.get(id)));
            // Populate allowedValues
            duplicateJob.parameters = yield WorkflowTemplateSaga.updateParametersAllowedValues({...duplicateJob, parameters},
                function* (error) {
                    yield contextCall(GuidedJobSaga, 'handleParametersAllowedValuesError', error);
                }
            );
            yield put(GuidedJobModel.actionCreators.update(duplicateJob));

        } finally {
            yield put(GuidedJobModel.actionCreators.update({isLoading: false}));
        }
    }

    static* updateParameter(action) {
        const guidedJobForm = yield select(state => state.guidedJobForm);
        const callbacks = this.getParameterUpdateCallbacks();
        yield contextCall(WorkflowTemplateSaga, 'debounceParameterUpdate', action.payload, guidedJobForm, callbacks);
    }

    static getParameterUpdateCallbacks() {
        return {
            handleUpdate: function* (update) {
                yield put(GuidedJobModel.actionCreators.update(prev => ({parameters: update(prev.parameters)})));
            },
            handleError: function* (error) {
                yield contextCall(GuidedJobSaga, 'handleParametersAllowedValuesError', error);
            }
        }
    }

    static* loadTsvParameters(action) {
        const {tsvText} = action.payload;
        const parameters = yield select(state => deepCopy(state.guidedJobForm.parameters));
        const updatedParameters = yield WorkflowTemplateSaga.loadTsvParameters({tsvText, parameters});

        yield put(GuidedJobModel.actionCreators.update({parameters: updatedParameters}));
    }

    static* resetParameters() {
        const guidedJobForm = yield select(state => state.guidedJobForm);
        const updatedParameters = yield WorkflowTemplateSaga.resetParameters(guidedJobForm);

        yield put(GuidedJobModel.actionCreators.update({parameters: updatedParameters}));
    }

    static* populateDefaultJobSettings() {
        const {clientId, matterId, didUserChange} = yield select(state => state.guidedJobForm);
        const defaultProfilePool = yield *JobSaga.getDefaultExecutionProfileAndResourcePool({clientId, matterId});

        const updates = {};
        if (!didUserChange.executionProfile) {
            updates.executionProfileId = defaultProfilePool.executionProfileId;
        }
        if (!didUserChange.resourcePool) {
            updates.resourcePoolId = defaultProfilePool.resourcePoolId
        }
        yield put(GuidedJobModel.actionCreators.update(updates));
    }

    static* getJobNameFromMatterAndWorkflow() {
        const {matterId: oldMatterId, workflowTemplateId: oldWorkflowTemplateId} = yield select(state => state.guidedJobForm);

        // Waits for action
        const action = yield take([GuidedJobModel.actions.POPULATE_JOB_SETTINGS, GuidedJobModel.actions.CLOSE]);
        if (action.type === GuidedJobModel.actions.CLOSE) {
            return;
        }

        const {matterId, workflowTemplateId, workflowName, didUserChange} = yield select(state => state.guidedJobForm);
        const workflowChanged = (workflowTemplateId !== oldWorkflowTemplateId);
        const matterChanged = (matterId !== oldMatterId);

        // Update only if user didn't change name
        if (!didUserChange.jobName && (workflowChanged || matterChanged)) {
            const [matter, workflowTemplate] = yield select(state => ([
                state.matterDetailsMap.get(matterId),
                state.workflowTemplateDetailsMap.get(workflowTemplateId)
            ]));

            const matterName = matter ? `${matter.name} - ` : '';

            let workflowTemplateName;
            if (workflowTemplate == null) {
                workflowTemplateName = workflowName || '';
            } else {
                workflowTemplateName = workflowTemplate.name;
            }

            yield put(GuidedJobModel.actionCreators.update({
                jobName: matterName + workflowTemplateName
            }));
        }
    }

    static* preparePopulateOperationsAndParameters() {
        const {workflowTemplateId: oldWorkflowTemplateId} = yield select(state => state.guidedJobForm);
        // Reset force to null so Redux can register next force position
        // Otherwise Redux will think there was no change; if force.position is set to 1 when it was already set to 1
        yield put(GuidedJobModel.actionCreators.update({force: null}));

        // Waits for action
        const action = yield take([GuidedJobModel.actions.POPULATE_JOB_SETTINGS, GuidedJobModel.actions.CLOSE]);
        if (action.type === GuidedJobModel.actions.CLOSE) {
            return;
        }

        let guidedJobForm = yield select(state => state.guidedJobForm);
        try {
            yield put(GuidedJobModel.actionCreators.update({isLoading: true}));
            const workflowTemplateId = guidedJobForm.workflowTemplateId;

            const [detailedOperationsRes] = yield all([
                contextCall(WorkflowTemplateApi, 'getDetailedOperations', workflowTemplateId, {withFieldOverwrite: true}),
                contextCall(WorkflowTemplateSaga, 'querySettings', {payload: {id: workflowTemplateId}})
            ]);

            let [newParameters, operations] = yield select(state => ([
                deepCopy(state.parametersMap.get(workflowTemplateId)), state.templateOperationsMap.get(workflowTemplateId)
            ]));

            const sameWorkflow = workflowTemplateId === oldWorkflowTemplateId;
            // Maintain values for same parameters
            if (guidedJobForm.parameters != null) {
                if (sameWorkflow) {
                    newParameters = guidedJobForm.parameters;
                } else {
                    for (const [name, param] of guidedJobForm.parameters) {
                        const newParam = newParameters.get(name);
                        if (newParam != null) {
                            newParam.value = param.value;
                            newParam.valueName = param.valueName;
                        }
                        if (operationFieldParameterNames.has(name) && !name.endsWith('_disabled}')) {
                            newParameters.set(name, param);
                        }
                    }
                }
            }

            const ignoreOperationFields = new Set(['@class', 'operationAlias', 'name', 'notes', 'options', 'skippable',
                'softFail', 'suppressWarnings', 'enableFieldOverwrite']);

            for (const detailedOperation of detailedOperationsRes.data) {
                if (detailedOperation.operationAlias !== WorkflowBuilderOperation.Alias.DUMMY_OPERATION) {

                    for (const [fieldName, value] of getEntries(detailedOperation)) {
                        if (!ignoreOperationFields.has(fieldName) && value) {
                            const fieldParameterName = getOperationFieldParameterName(detailedOperation.operationAlias, fieldName);
                            operationFieldParameterNames.add(fieldParameterName);

                            let param = newParameters.get(fieldParameterName);
                            if (param == null || !sameWorkflow) {
                                param = new ParameterModel({name: fieldParameterName, parameterType: ParameterModel.Type.TEXT});
                                newParameters.set(fieldParameterName, param);
                            }
                            if (!param.value) {
                                if (typeof value === 'object') {
                                    param.value = JSON.stringify(value);
                                } else {
                                    param.value = value;
                                }
                            }
                        }
                    }
                }
            }

            yield put(GuidedJobModel.actionCreators.update({operations, parameters: newParameters}));
            yield contextCall(GuidedJobSaga, 'updateParametersAllowedValues');

        } finally {
            yield put(GuidedJobModel.actionCreators.update({isLoading: false}));
        }
    }

    static* updateParametersAllowedValues() {
        function* handleError(error) {
            yield contextCall(GuidedJobSaga, 'handleParametersAllowedValuesError', error);
        }
        try {
            yield put(GuidedJobModel.actionCreators.update({isDisabled: true}));
            const guidedJobForm = yield select(state => state.guidedJobForm);
            const parameters = yield WorkflowTemplateSaga.updateParametersAllowedValues(guidedJobForm, handleError);
            yield put(GuidedJobModel.actionCreators.update({parameters}));
        } finally {
            yield put(GuidedJobModel.actionCreators.update({isDisabled: false}));
        }
    }

    static* handleParametersAllowedValuesError(error) {
        if (error.cause) {
            switch (error.cause) {
                case 'noFinalizedOrAllowedDatasets': {
                    yield contextCall(WorkflowTemplateSaga, 'handleNoDataSetsError', error, {
                        hideFormActionCreator: GuidedJobModel.actionCreators.close
                    });
                    break;
                }
                default:
                    yield put(PopupModel.actionCreators.showError({
                        id: error.cause,
                        info: {
                            key: error.cause,
                            values: error.data
                        }
                    }));
            }
        } else {
            yield contextCall(WorkflowTemplateSaga, 'handleAllowedParameterValuesError', error);
        }
    }
}

export default GuidedJobModel;
