import {makeIdentityDeepEqualSelector} from "../../../reselect/reselectFunctions";
import {createSelector} from "reselect";
import {getObjectText, getValues} from "../../../utilities/helperFunctions";
import {permissionKeys} from "../../../i18next/keys";
import {applicationFeatures} from "../../../utilities/constants";


export const selectClientPoolFilteredClientIds = makeIdentityDeepEqualSelector(
  createSelector(
    state => state.clientDetailsMap,
    state => state.clientPoolDetailsMap,
    state => state.clientPoolFilter,
    (ignore, props) => props.searchText,
    (clientDetailsMap, clientPoolDetailsMap, clientPoolFilter, searchText) => {

      let filteredClientIds = getValues(clientDetailsMap)
        .filter(_client => getObjectText(_client, {whitelist: ['name']}).includes(searchText.toLowerCase()))
        .map(_client => _client.id);

      const selectedClientPools = getValues(clientPoolFilter)
        .filter(_filter => _filter.isChecked);

      // Filter against their ids
      if (selectedClientPools.length > 0) {

        const clientPoolClientIds = selectedClientPools
          .flatMap(_filter => clientPoolDetailsMap.get(_filter.key).clientIds);

        return filteredClientIds.filter(_id => clientPoolClientIds.includes(_id));
      }

      return filteredClientIds;
    }
  )
);

export function selectCanAddClient(state) {
  const {currentUser: {features}, clientPoolDetailsMap, clientPoolFilter} = state;
  const selectedClientPools = getValues(clientPoolFilter).filter(_filter => _filter.isChecked);
  // If clientPools selected, check if user has MODIFY permission for each
  if (selectedClientPools.length > 0) {
    return selectedClientPools.every(_filter => {

      const {userPermissions} = clientPoolDetailsMap.get(_filter.key);
      return userPermissions.includes(permissionKeys.MODIFY) || userPermissions.includes(permissionKeys.MODIFY_CHILDREN);
    });
  }

  return features.includes(applicationFeatures.ADD_CLIENT);
}

export const selectFilteredLibraryIds = createSelector(
    state => state.libraryDetailsMap,
    (ignore, props) => props.searchText,
    (libraryDetailsMap, searchText) => {

        return getValues(libraryDetailsMap)
            .filter(_library =>
                getObjectText(_library, {whitelist: ['name']}).includes(searchText.toLowerCase())
            )
            .map(_library => _library.id);
    }
);
