import ThirdPartyServiceModel from "../../../models/thirdparty/ThirdPartyServiceModel";
import ThirdPartyServiceForm, {ThirdPartyServiceFormBody} from "../ThirdPartyServiceForm";
import {useTranslation} from "react-i18next";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import React from "react";
import ExpandableContent from "../../common/ExpandableContent/ExpandableContent";

const initialState = {
    type: ThirdPartyServiceModel.Type.GEN_AI,
    serviceName: '',
    enabled: true,
    description: '',
    whitelistedCertFingerprints: [],

    url: 'https://api.example.com/v1',
    model: '',

    authenticationScope: ThirdPartyServiceModel.AuthenticationScope.SERVICE,
    authenticationMethod: ThirdPartyServiceModel.AuthenticationMethod.API_KEY,
    authenticationServiceId: null
};
const allowedAuthenticationMethods = [
    ThirdPartyServiceModel.AuthenticationMethod.API_KEY,
    ThirdPartyServiceModel.AuthenticationMethod.NONE

];

function GenAiServiceForm() {
    return (
        <ThirdPartyServiceForm initialState={initialState} FormBody={GenAiServiceFormBody}/>
    )
}

export function GenAiServiceFormBody(props) {
    return (
        <ThirdPartyServiceFormBody {...props} FormBody={FormBody}
            allowedAuthenticationMethods={allowedAuthenticationMethods}/>
    )
}

function FormBody(props) {
    const {t} = useTranslation(['thirdPartyService']);
    const {
        thirdPartyService,
        inputHandler,
        isDisabled
    } = props;

    const isModelDisabled =  thirdPartyService.url.endsWith("/completions");

    return (
        <ExpandableContent label={t('thirdPartyService:label.genAiSettings')} isDisabled={isDisabled}>
            <div className="display-input">
                <HTMLTextInput label={t('thirdPartyService:label.url')} name={'url'}
                    value={thirdPartyService.url} onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                <HTMLTextInput label={t('thirdPartyService:label.model')} name={'model'}
                               value={thirdPartyService.model} onChange={inputHandler} isDisabled={isDisabled || isModelDisabled} isRequired/>
            </div>
        </ExpandableContent>
    )
}

export default GenAiServiceForm;
