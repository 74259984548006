import ThirdPartyServiceModel from "../../../models/thirdparty/ThirdPartyServiceModel";
import ThirdPartyServiceForm, {ThirdPartyServiceFormBody} from "../ThirdPartyServiceForm";
import UserServiceModel from "../../../models/user/UserServiceModel";
import React, {useCallback} from "react";

const initialState = {
    type: ThirdPartyServiceModel.Type.VAULT,
    serviceName: '',
    enabled: true,
    description: '',
    whitelistedCertFingerprints: [],

    authenticationScope: ThirdPartyServiceModel.AuthenticationScope.SERVICE,
    authenticationMethod: ThirdPartyServiceModel.AuthenticationMethod.OIDC_AUTHORIZATION_CODE,
    authenticationServiceId: null
};
const allowedAuthenticationMethods = [
    ThirdPartyServiceModel.AuthenticationMethod.OIDC_AUTHORIZATION_CODE
];

function VaultServiceForm() {
    return (
        <ThirdPartyServiceForm initialState={initialState} FormBody={VaultServiceFormBody}/>
    )
}

export function VaultServiceFormBody(props) {
    const filterFunc = useCallback(userService => {
        return UserServiceModel.isGoogleOidc(userService.type, userService.wellKnownConfiguration);
    }, []);

    return (
        <ThirdPartyServiceFormBody {...props} authenticationServiceType={UserServiceModel.types.OIDC}
            allowedAuthenticationMethods={allowedAuthenticationMethods} filterFunc={filterFunc}/>
    )
}

export default VaultServiceForm;
