import SubsetDetailsModel from "../generics/SubsetDetailsModel";

class WebhookEventModel extends SubsetDetailsModel {

    static nom = 'WebhookEventModel';
    static parentKey = 'webhookId';

    static actions = WebhookEventModel.buildActions('WEBHOOK_EVENT');
    static actionCreators = WebhookEventModel.buildActionCreators(WebhookEventModel.actions);
    static reducer = WebhookEventModel.buildReducer(WebhookEventModel.actions);

    constructor(model={}) {
        super();

        this.forceUpdate(model);
    }
}

export default WebhookEventModel;
