import React from 'react';

import '../CaseExample/caseExample.css';
import {trimBaseFolder} from "../../../utilities/helperFunctions";
import {useTranslation} from "react-i18next";

function SourceDataExample (props) {
    const {t} = useTranslation(['wizard'])
    const {sourceDataFolder, datasetId} = props;

    const example = {
        folder: sourceDataFolder,
        client: "Hydro, Inc. - Accounting Department",
        matter: "Information Governance",
        datasetId: datasetId
    }

    const separator = (<div className={"folder-separator"}><span> <label className={"label is-small"}>/</label> </span> <span> <hr/> </span>
        <span> <label className={"placeholder label is-small"}>a</label> </span></div>);

    return (
        <div>
            <label className={"label is-small"}>{t('wizard:examples.sourceDataDescription')}</label>
            <div className={"example-container"} data-name={'sourceDataExample'}>
                <div>
                    <div className={"base-folder"}>
                        <span>
                            <label className={"label is-small"}>{trimBaseFolder(example.folder)}</label>
                            <hr className={"line-separator"}/>
                            <label className={"label is-small"}>{t('wizard:examples.sourceData')}</label>
                        </span>
                    </div>
                    {separator}
                    <div className={"suffix-text"}>
                        <span>
                            <label className={"label is-small"}>{example.client}</label>
                            <hr className={"line-separator"}/>
                            <label className={"label is-small"}>{t('wizard:examples.clientName')}</label>
                        </span>
                    </div>
                    {separator}
                    <div className={"suffix-text"}>
                        <span>
                            <label className={"label is-small"}>{example.matter}</label>
                            <hr className={"line-separator"}/>
                            <label className={"label is-small"}>{t('wizard:examples.matterName')}</label>
                        </span>
                    </div>
                    {separator}
                    <div className={"base-folder"}>
                        <span>
                            <label className={"label is-small"}>{example.datasetId}</label>
                            <hr className={"line-separator"}/>
                            <label className={"label is-small"}>{t('wizard:examples.datasetId')}</label>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SourceDataExample;