import React from 'react';
import {useTranslation} from "react-i18next";
import './wizardHeader.css'
import StepIcon from "../../../../components/common/StepIcon/StepIcon";

function WizardHeader (props) {
    const {title, description, paneCount, stepNumber, isDisabled} = props;
    const {t} = useTranslation(['common']);

    const disabled = isDisabled ? ' is-disabled' : '';

    return (
        <div className="navigatePane-header">
            <h2 className={'subtitle is-bold' + disabled}>
                {t('common:label.stepNumber', {stepNumber, title})}
                <p className={"label is-small"}>{description}</p>
            </h2>
            <span>
                <StepIcon current={stepNumber} total={paneCount} isDisabled={isDisabled}/>
            </span>
        </div>
    )

}

export default WizardHeader;