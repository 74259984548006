import React from 'react';
import JobCard from "./JobCard";

export function MushroomHead(props) {
    const {text} = props;

    return (
        <article className="mushroom-head">
            <div className="column">
                <h2 className="jobState-header column">
                    {text}
                </h2>
            </div>
        </article>
    );
}

export function JobListContainer(props) {
    const {
        id,
        activeJobId,
        'aria-label': ariaLabel,
        jobs
    } = props;

    return (
        <div id={id} role="listbox" aria-label={ariaLabel} className={'jobListContainer'}>
            <div className="column box">
                {jobs.map(job =>
                    <JobCard key={job.id} isActive={job.id === activeJobId} job={job}/>
                )}
            </div>
        </div>
    );
}