import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {purviewUseCaseParameterNames} from "./PurviewUseCasePane";
import {buildClassName, buildFakeEvent, getNonEmptyValueOrDefault} from "../../../utilities/helperFunctions";
import {purviewJobParameterNames} from "../PurviewGuidedJobForm";
import PurviewCache from "../../../models/guidedjob/PurviewCache";
import Checkbox from "../../common/Checkbox/Checkbox";
import {useDispatch, useSelector} from "react-redux";
import WorkflowBuilderOperation from "../../../models/workflowbuilder/WorkflowBuilderOperation";
import PurviewOperationPane from "./PurviewOperationPane";
import {objectIdentifierType, purviewDataSourceInputType} from "../../../utilities/constants";
import {icon} from "../../../utilities/iconResolver";
import {ImportExportContainerOptions, SwitchItemContainer} from "../../common/ListContainer/ListContainer";
import {asyncSeparateItems} from "../../common/ListContainer/helpers";
import {purviewApplyHoldToCustodiansParameterNames} from "./PurviewAddCustodialDataSourcesPane";
import {buildOperationFieldParameterNames} from "../../guidedJob/operationFieldParameterNames";
import PopupModel from "../../../models/scheduler/PopupModel";
import {useAsyncEffect} from "../../../utilities/hooks";

const releaseCustodiansFieldNames = ['disabled', 'inputType', 'custodianIdsJson'];
export const purviewReleaseCustodiansParameterNames = buildOperationFieldParameterNames(WorkflowBuilderOperation.Alias.PURVIEW_RELEASE_CUSTODIANS, releaseCustodiansFieldNames);

const removeHoldFromCustodiansFieldNames = ['disabled', 'inputType', 'custodianIdsJson', 'waitForCompletion'];
export const purviewRemoveHoldFromCustodiansParameterNames = buildOperationFieldParameterNames(WorkflowBuilderOperation.Alias.PURVIEW_REMOVE_HOLD_FROM_CUSTODIANS, removeHoldFromCustodiansFieldNames);

export function getNextEnabled(getParameter) {
    const releaseCustodiansDisabled = getParameter(purviewReleaseCustodiansParameterNames.disabled, false, {parse: true});
    const removeHoldFromCustodiansDisabled = getParameter(purviewRemoveHoldFromCustodiansParameterNames.disabled, false, {parse: true});
    const existingCustodianIds = getParameter(purviewJobParameterNames.selectedCustodianIds, '[]', {parse: true});
    return {
        selectCustodians: (releaseCustodiansDisabled.value && removeHoldFromCustodiansDisabled.value)
            || existingCustodianIds.value.length > 0
    };
}

export function getPurviewSelectCustodiansGuide(operation, props) {
    const {
        workflowActions,
        updateParameter
    } = props;

    if (workflowActions.applyHoldToCustodians) {
        updateParameter(purviewApplyHoldToCustodiansParameterNames.inputType, purviewDataSourceInputType.IDS_JSON);
        updateParameter(purviewApplyHoldToCustodiansParameterNames.custodianIdsJson, purviewJobParameterNames.selectedCustodianIds);
    }
    if (workflowActions.removeHoldFromCustodians) {
        updateParameter(purviewRemoveHoldFromCustodiansParameterNames.inputType, purviewDataSourceInputType.IDS_JSON);
        updateParameter(purviewRemoveHoldFromCustodiansParameterNames.custodianIdsJson, purviewJobParameterNames.selectedCustodianIds);
    }
    if (workflowActions.releaseCustodians) {
        updateParameter(purviewReleaseCustodiansParameterNames.inputType, purviewDataSourceInputType.IDS_JSON);
        updateParameter(purviewReleaseCustodiansParameterNames.custodianIdsJson, purviewJobParameterNames.selectedCustodianIds);
    }

    return {
        getPanes: function(t, props) {
            const selectCustodiansTitle = getNonEmptyValueOrDefault(operation.notes, t('guidedJob:panes.selectCustodians'));
            const nextEnabled = getNextEnabled(props.getParameter);

            return [{
                title: selectCustodiansTitle,
                isNextEnabled: nextEnabled.selectCustodians,
                component: <PurviewOperationPane {...props}
                    workflowActions={workflowActions} OperationPane={PurviewSelectCustodiansPane}/>
            }];
        }
    }
}

function PurviewSelectCustodiansPane(props) {
    const {t} = useTranslation(['guidedJob', 'common']);
    const dispatch = useDispatch();

    const {
        proxyPurviewRequest,
        getParameter,
        updateParameter
    } = props;

    const caseIdentifierType = getParameter(purviewUseCaseParameterNames.caseIdentifierType);
    const useExistingCase = caseIdentifierType.value === objectIdentifierType.ID;
    const isDisabled = props.isDisabled || !useExistingCase;

    const applyHoldToCustodiansDisabled = getParameter(purviewApplyHoldToCustodiansParameterNames.disabled, false, {parse: true});
    const removeHoldFromCustodiansDisabled = getParameter(purviewRemoveHoldFromCustodiansParameterNames.disabled, false, {parse: true});
    const releaseCustodiansDisabled = getParameter(purviewReleaseCustodiansParameterNames.disabled, false, {parse: true});
    const isSelectCustodiansDisabled = applyHoldToCustodiansDisabled.value && removeHoldFromCustodiansDisabled.value && releaseCustodiansDisabled.value;
    const isOperationDisabled = isDisabled || isSelectCustodiansDisabled;

    const caseIdentifier = getParameter(purviewUseCaseParameterNames.caseIdentifier);
    const selectedCustodianIds = getParameter(purviewJobParameterNames.selectedCustodianIds, '[]', {parse: true});
    const [custodianItems, setCustodianItems] = useState({left: [], right: []});

    const custodiansKey = PurviewCache.getResourceKey(PurviewCache.Resource.CUSTODIANS, {caseId: caseIdentifier.value});
    const custodians = useSelector(state => state.purviewCache[custodiansKey]) || {};
    const isLoadingCustodians = custodians.cache == null;

    useEffect(() => {
        if (!isOperationDisabled) {
            proxyPurviewRequest(PurviewCache.Resource.CUSTODIANS, {caseId: caseIdentifier.value});
        }
    }, [isOperationDisabled]);

    useEffect(() => {
        if (!useExistingCase) {
            toggleHandler(buildFakeEvent({name: 'selectCustodiansDisabled', value: true}))
        }
    }, [useExistingCase]);

    useAsyncEffect(async () => {
        if (Array.isArray(custodians.cache)) {
            const custodianItems = custodians.cache.map(custodian => ({
                name: custodian.email,
                value: custodian.id
            }));
            const items = await asyncSeparateItems(selectedCustodianIds.value, custodianItems);
            setCustodianItems(items);
        }
    }, [custodians.cache]);

    useEffect(() => {
        const custodianIds = custodianItems.right.map(item => item.value);
        updateParameter(purviewJobParameterNames.selectedCustodianIds, JSON.stringify(custodianIds));
    }, [custodianItems]);

    const importCsv = useCallback(resultArray => {
        const newCustodianItems = {left: [], right: [...custodianItems.right]};
        const custodianEmails = new Set(resultArray.map(row => row[0]));
        const emailsCount = custodianEmails.size;

        for (const item of custodianItems.left) {
            if (custodianEmails.delete(item.name)) {
                newCustodianItems.right.push(item);
            } else {
                newCustodianItems.left.push(item);
            }
        }
        const values = {
            importedCount: emailsCount - custodianEmails.size,
            invalidCount: custodianEmails.size
        };
        dispatch(showImportCustodianPopup(values));
        setCustodianItems(newCustodianItems);
    }, [custodianItems]);

    function toggleHandler(event) {
        const {name, checked} = event.target;
        if (name === 'selectCustodiansDisabled') {
            updateParameter(purviewApplyHoldToCustodiansParameterNames.disabled, checked);
            updateParameter(purviewRemoveHoldFromCustodiansParameterNames.disabled, checked);
            updateParameter(purviewReleaseCustodiansParameterNames.disabled, checked);
        }
    }

    const iconClassName = buildClassName(
        'icon is-large',
        isOperationDisabled && 'is-disabled'
    );
    const containerStyle = {flex: 1};
    if (!isLoadingCustodians && custodians.stale) {
        containerStyle.opacity = 0.6;
    }


    return (
        <div className="display-input flex-expand" style={{position: 'relative'}}>
            <Checkbox label={t('guidedJob:label.skipSelectingCustodians')} name={'selectCustodiansDisabled'}
                value={isSelectCustodiansDisabled} onClick={toggleHandler}
                containerStyle={{marginBottom: '0.75rem'}} isDisabled={isDisabled}/>

            <span className={iconClassName} style={{position: 'absolute', left: 0, right: 0, margin: '6rem auto'}}>
                <img src={icon('roleCustodian')} alt={t(`aria:hiddenAssistText.custodianRoleIcon`)}/>
            </span>

            <SwitchItemContainer id="custodiansSwitchContainer" ariaLabelKey={'Custodian'} style={containerStyle} optionComponentProps={{importCsv}}
                OptionsComponent={ImportExportContainerOptions} items={custodianItems} setItems={setCustodianItems}
                enableVirtualRendering isSearchable isLoading={isLoadingCustodians} isDisabled={isOperationDisabled}/>
        </div>
    )
}

function showImportCustodianPopup(values) {
    let showPopupKey = 'showSuccess';
    let popupKey = 'importCustodiansSelected';
    if (values.importedCount !== 1) {
        popupKey += '_plural';
    }
    if (values.invalidCount > 0) {
        showPopupKey = 'showWarning';
        popupKey += 'Skipped';
        if (values.invalidCount !== 1) {
            popupKey += '_plural';
        }
    }
    return PopupModel.actionCreators[showPopupKey]({
        info: {key: popupKey, values},
        cancelButton: {titleKey: 'common:option.ok'}
    });
}

