import React, {useCallback, useEffect} from 'react';
import LibraryPanel from "../../panels/LibraryPanel";
import WorkflowTemplatePanel from "../../panels/WorkflowTemplatePanel";
import {getValues, includesSome} from "../../../utilities/helperFunctions";
import {permissionKeys} from "../../../i18next/keys";

function SelectWorkflowPane(props) {
    const {
        jobState,
        updateState,
        preparePopulateJobSettings,
        libraryFilter,
        workflowFilter,
        isLoadingLibraries,
        isLoadingWorkflows
    } = props;

    useEffect(() => {
        if (typeof preparePopulateJobSettings === 'function') {
            preparePopulateJobSettings();
        }
    },[]);

    const librarySelector = useCallback(reduxState => {
        return getValues(reduxState.libraryDetailsMap)
            .filter(library => library.enabled &&
                includesSome(library.userPermissions, [permissionKeys.SUBMIT_JOB, permissionKeys.STAGE_JOB]))
            .filter(library => typeof libraryFilter !== 'function' || libraryFilter(library));
    }, [libraryFilter]);

    const workflowSelector = useCallback(reduxState => {
        return getValues(reduxState.workflowTemplateDetailsMap)
            .filter(template => template.libraryId === jobState.libraryId && template.enabled &&
                includesSome(template.userPermissions, [permissionKeys.SUBMIT_JOB, permissionKeys.STAGE_JOB]))
            .filter(template => typeof workflowFilter !== 'function' || workflowFilter(template));
    }, [workflowFilter, jobState.libraryId]);


    return (
        <section className="selectPane">
            <LibraryPanel libraryId={jobState.libraryId} librarySelector={librarySelector}
                updateState={updateState} isLoading={isLoadingLibraries} isDisabled={jobState.isDisabled}/>

            <WorkflowTemplatePanel matterId={jobState.matterId} libraryId={jobState.libraryId}
                workflowSelector={workflowSelector} workflowTemplateId={jobState.workflowTemplateId} workflowName={jobState.workflowName}
                updateState={updateState} isLoading={isLoadingWorkflows} isDisabled={jobState.isDisabled}/>
        </section>
    )
}

export default SelectWorkflowPane;
