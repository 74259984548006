import SagaModel from "../generics/SagaModel";
import {put, select, take, takeLeading} from "redux-saga/effects";
import {contextCall, contextSaga, spawnSaga} from "../../saga/sagaFunctions";
import {DatasetApi, DatasetSaga} from "./DatasetModel";
import ComponentStateModel from "../generics/ComponentStateModel";
import ReduxModel from "../generics/ReduxModel";
import {
    actionCreator,
    deepCopy,
    getEntries,
    getKeys,
    intersectArrays,
    objEquals,
    sanitizeCsvValue,
    validateRegex
} from "../../utilities/helperFunctions";
import {builtInDatasetMetadataHeaders} from "../../utilities/constants";
import PopupModel from "../scheduler/PopupModel";
import AxiosProxy from "../api/AxiosProxy";
import ParameterModel from "../library/ParameterModel";


class FileInfoModel extends ReduxModel {

    static nom = 'FileInfoModel';
    static componentActionCreators = {
        updateTablet: updates => ({
            type: ComponentStateModel.actions.UPDATE_COMPONENT_STATE,
            payload: {component: 'matterTablet', updates}
        })
    }

    constructor(model = {}) {
        super();
        const {id, datasetId, relativePath, directory, addedBy, addedDate, hashes, size, fileCount} = model;

        this.id = id;
        this.name = relativePath;
        this.directory = directory;
        this.datasetId = datasetId;
        this.addedBy = addedBy;
        this.addedDate = addedDate;
        this.hashes = hashes;
        this.size = size;
        this.fileCount = fileCount;
    }

    static actions = {
        SET_DETAILS: 'SET_FILE_INFO_DETAILS',
        CLEAR_DETAILS: 'CLEAR_FILE_INFO_DETAILS',
        START_EDIT: 'START_FILE_INFO_EDIT'
    };

    static actionCreators = {
        setDetails: actionCreator(this.actions.SET_DETAILS, 'id', 'details'),
        clearFileInfos: actionCreator(this.actions.CLEAR_DETAILS, 'id', 'range'),
        startEdit: actionCreator(this.actions.START_EDIT, 'id')
    }

    static reducer = function(state=new Map(), action) {
        switch (action.type) {
            case this.actions.SET_DETAILS: {
                const {id, details} = action.payload;

                return this.addFileInfoRows(state, id, details);
            }
            case this.actions.CLEAR_DETAILS: {
                const {id, maxSize=10} = action.payload;

                if (state.size < maxSize) {
                    return state;
                }

                // Keep last 10 datasets
                const entries = getEntries(state);
                const startIndex = Math.max(0, entries.length - maxSize);

                const newState = new Map();
                for (let i = 0; i < entries.length; i++) {
                    const [key, value] = entries[i];

                    if (key === id || i >= startIndex) {
                        newState.set(key, value)

                    // Clear etag cache
                    } else {
                        delete AxiosProxy.etags[key];
                    }
                }
                return newState;
            }
            default: {
                return state;
            }
        }
    }.bind(this);

    static addFileInfoRows(state, datasetId, fileInfoRows) {
        const oldRows = state.get(datasetId);

        if (objEquals(fileInfoRows, oldRows)) {
            return state;
        }
        return new Map(state).set(datasetId, fileInfoRows);
    }
}

export class FileInfoSaga extends SagaModel {

    static ModelType = FileInfoModel;

    static activationComponent = 'CLIENT_PAGE';
    static variableNames = {
        updatePane: 'updateTablet'
    };

    static translations = {
        itemTitle: '$t(dataset:label.name)',
        itemLower: '$t(dataset:label.name_lower)'
    };

    static buildActivationEffects() {
        return [
            takeLeading(FileInfoModel.actions.START_EDIT, spawnSaga, contextSaga(this, 'startEdit'))
        ]
    }

    static evaluateCellInvalid(cell) {
        return Array.isArray(cell.regexps) && cell.regexps.some(regex => !validateRegex(regex, cell.value));
    }

    static* buildFileInfoRows(datasetId, fileInfos) {
        const fileInfoRows = [];

        const dataset = yield select(state => state.datasetDetailsMap.get(datasetId));
        const {data: requiredMetadataHeaders} = yield contextCall(DatasetApi, 'getRequiredMetadataHeaders', datasetId);

        const headerRow = [{}];
        const definedHeaders = dataset.fileMetadataHeaders || [];
        const nonDefinedRequiredHeaders = [];
        // Check if requiredHeaders already defined and track indices
        const indexToRequiredHeader = {};
        for (const entry of getEntries(requiredMetadataHeaders)) {

            const index = definedHeaders.findIndex(header => header === entry[0]);
            // Not defined
            if (index < 0) {
                headerRow.push({value: entry[0], readOnly: true});
                nonDefinedRequiredHeaders.push(entry);
            } else {
                indexToRequiredHeader[index] = entry;
            }
        }

        for (let i = 0; i < definedHeaders.length; i++) {
            const cell = {value: definedHeaders[i]};
            // If is a requiredHeader, make readOnly
            if (indexToRequiredHeader[i] != null) {
                cell.readOnly = true;
            }
            headerRow.push(cell);
        }
        fileInfoRows.push(headerRow);


        for (const fileInfo of fileInfos) {
            const row = [new FileInfoModel(fileInfo)];

            // Push blank cells for nonDefinedRequiredHeaders
            for (const entry of nonDefinedRequiredHeaders) {

                const cell = {
                    value: '',
                    regexps: entry[1]
                };
                cell.invalid = this.evaluateCellInvalid(cell);
                this.populateRegexAllowedValues(cell);

                row.push(cell);
            }

            const metadata = fileInfo.metadata || [];
            for (let i = 0; i < metadata.length; i++) {
                const cell = {value: metadata[i]};
                // If is a requiredHeader, specify regex and invalid
                const entry = indexToRequiredHeader[i];
                if (entry != null) {
                    cell.regexps = entry[1];
                    cell.invalid = this.evaluateCellInvalid(cell);
                    this.populateRegexAllowedValues(cell);
                }
                row.push(cell);
            }

            // Pad columns
            let firstRow = fileInfoRows[0] || [];
            while (row.length < firstRow.length) {
                row.push({value: ''});
            }

            fileInfoRows.push(row);
        }

        return fileInfoRows;
    }

    static populateRegexAllowedValues(cell) {
        const allowedValuesMatrix = [];
        for (const regexp of cell.regexps) {
            const allowedValues = ParameterModel.getRegexAllowedValues(regexp);
            if (Array.isArray(allowedValues)) {
                allowedValuesMatrix.push(allowedValues);
            }
        }
        // Allowed values must satisfy all regexes
        cell.regexAllowedValues = intersectArrays(...allowedValuesMatrix);
    }

    static* saveEdit(datasetId) {
        const editValues = yield select(state => state.editDetails.values);
        const saveValues = yield contextCall(this, 'getSaveValues', editValues);

        yield contextCall(DatasetSaga, 'uploadFilesMetadataCsv', {payload: {id: datasetId, csvText: saveValues}});
    }

    static* getEditValues(datasetId) {
        const fileInfoRows = yield select(state => state.fileInfoRowsMap.get(datasetId));

        return {editRows: deepCopy(fileInfoRows)};
    }

    static* getSaveValues(editValues) {

        const builtInHeaders = [], duplicateHeaders = {}, seenHeaders = {};
        const headers = editValues.editRows[0].slice(1);

        for (let i = 0; i < headers.length; i++) {
            const {value} = headers[i];
            // Built-In Header
            if (builtInDatasetMetadataHeaders.includes(value.toUpperCase())) {
                builtInHeaders.push(`  \u2022 ${value}`);
            }
            // Duplicate headers
            if (seenHeaders[value]) {
                duplicateHeaders[`  \u2022 ${value}`] = true;
            }
            seenHeaders[value] = true;
        }

        // Throw error if a header is using a builtInHeader
        if (builtInHeaders.length > 0) {
            yield put(PopupModel.actionCreators.showError({
                info: {
                    key: 'cannotUseBuiltInHeaders',
                    values: {
                        headers: builtInHeaders.join('\n')
                    }
                }
            }));
            throw "Cannot use built-in header values";
        }
        const duplicates = getKeys(duplicateHeaders);
        // Throw error if duplicate headers
        if (duplicates.length > 0) {
            yield put(PopupModel.actionCreators.showError({
                info: {
                    key: 'duplicateHeaderValues',
                    values: {
                        headers: duplicates.join('\n')
                    }
                }
            }));
            throw "Found headers with duplicate values";
        }

        // Translate into csvText
        return editValues.editRows.map(row => {
            // 1st row is Headers, 2nd rows and on are file rows
            // 1st column is fileNames
            return [sanitizeCsvValue(row[0].name), ...row.slice(1).map(cell => sanitizeCsvValue(cell.value))].join(',');
        }).join('\n');
    }

    static* checkElsewhereTake() {
        yield take(FileInfoModel.actions.SET_DETAILS);
    }
}

export default FileInfoModel;