import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createUpdateHandler} from "../../../utilities/componentFunctions";
import LegalHoldModel from "../../../models/legalhold/LegalHoldModel";
import {SwitchItemContainer} from "../../common/ListContainer/ListContainer";
import {legalHoldStateKeys, permissionKeys} from "../../../i18next/keys";
import {UserEmailSelectableItem} from "../../common/SelectableItem/SelectableItem";
import NavigateButtons from "../../common/NavigateButtons/NavigateButtons";
import {icon} from "../../../utilities/iconResolver";
import {useTranslation} from "react-i18next";
import {ImportCustodiansButton} from "../CustodiansTable";


function HoldCustodiansPane() {
    const {t} = useTranslation(['aria']);
    const dispatch = useDispatch();

    const {
        state,
        custodians,

        userPermissions,
        force,
        isDisabled: isFormDisabled
    } = useSelector(state => state.componentStates.legalHoldForm);


    const custodiansHandler = createUpdateHandler({
        updateState: updates => dispatch(LegalHoldModel.componentActionCreators.updateForm(updates)),
        name: 'custodians',
        values: custodians
    });

    const isDraft = state === legalHoldStateKeys.DRAFT;
    const isActive = state === legalHoldStateKeys.ACTIVE;
    const cantModify = userPermissions != null && !userPermissions.includes(permissionKeys.MODIFY);
    const cantManage = userPermissions != null && !userPermissions.includes(permissionKeys.MANAGE);
    // Can only modify when DRAFT or ACTIVE
    const isDisabled = isFormDisabled || (!isDraft && !isActive) || (cantModify && cantManage);

    const disabled = isDisabled ? ' is-disabled' : '';
    return (
        <div className="pane-margin fill-height">

            <div className="display-input flex-expand" style={{position: 'relative'}}>
                <span className={'icon is-large' + disabled} style={{position: 'absolute', left: 0, right: 0, margin: '6rem auto'}}>
                    <img src={icon('roleCustodian')} alt={t('aria:hiddenAssistText.custodianRoleIcon')}/>
                </span>

                <SwitchItemContainer id="custodiansSwitchContainer" style={{flex: 1}} ItemComponent={UserEmailSelectableItem}
                    OptionsComponent={CustodianContainerOptions} items={custodians} setItems={custodiansHandler}
                    enableVirtualRendering force={force} isSearchable searchableFields={['upn', 'email']}
                    ariaLabelKey={'Custodian'} canShowSelected isDisabled={isDisabled}
                />
            </div>
        </div>
    )
}

function CustodianContainerOptions(props) {
    const {t} = useTranslation(['legalHold']);
    const dispatch = useDispatch();

    const {
        items,
        selectedLeftCount,
        selectedRightCount,
        onNavigateLeft,
        onNavigateRight,
        isDisabled
    } = props;

    const onImport = useCallback(event => {
        dispatch(LegalHoldModel.actionCreators.importCustodiansToForm(event.target.result));
    }, [dispatch]);

    const leftCount = (items.left || []).length;
    const isImportDisabled = isDisabled || leftCount === 0;

    return (
        <div style={{flex: '0.2 1', margin: 'auto 0'}}>
            <ImportCustodiansButton aria-label={t('legalHold:message.addCustodianEmails')}
                style={{display: 'flex', justifyContent: 'center', marginBottom: '0.25rem'}}
                onloadend={onImport} isDisabled={isImportDisabled}/>

            <NavigateButtons ariaLabelKey={'Custodian'} onNavigateLeft={onNavigateLeft} onNavigateRight={onNavigateRight}
                selectedLeftCount={selectedLeftCount} selectedRightCount={selectedRightCount}/>
        </div>
    )
}

export default HoldCustodiansPane;
