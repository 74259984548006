import React, {useLayoutEffect} from "react";
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import FileLibraryModel from "../../models/filelibrary/FileLibraryModel";
import {applicationFeatures, details} from "../../utilities/constants";
import {includesSome, isNotEmptyNorFalsy} from "../../utilities/helperFunctions";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {AddButtonHeader} from "../common/Button/Button";
import FileLibraryTable from "./FileLibraryTable";
import FileLibraryTablet from "./FileLibraryTablet";
import FileLibraryForm from "./FileLibraryForm";
import LibraryFileModel from "../../models/filelibrary/LibraryFileModel";
import {MainPageContent, MainPageHeader} from "../app/pages/MainPage";

function selectReduxState (state) {
    return {
        isLoading: !state.hasLoaded[details.FILE_LIBRARIES],
        fileLibraries: state.fileLibraryDetailsMap,
        canModify: includesSome(state.currentUser.features, [applicationFeatures.ADD_RESOURCE, applicationFeatures.ADD_FILE_LIBRARY]),
        ...state.componentStates.fileLibraryDisplay
    }
}


function FileLibraryDisplay (props) {
    const {t} = useTranslation(['aria', 'fileLibrary'])
    const dispatch = useDispatch()

    const {
        ...attr
    } = props;

    const {
        isLoading,
        canModify,
        fileLibraries,
        fileLibraryId,
        isFileLibraryFormActive
    } = useSelector(selectReduxState, shallowEqual)

    useLayoutEffect(() => {
        dispatch(FileLibraryModel.componentActionCreators.setDisplayActive());

        return () => dispatch(FileLibraryModel.componentActionCreators.setDisplayInactive())
    }, [dispatch]);

    useLayoutEffect(() => {
        if (!!fileLibraryId) {
            dispatch(FileLibraryModel.actionCreators.startPollingSettings(fileLibraryId))
        } else {
            dispatch(FileLibraryModel.actionCreators.stopPollingSettings(fileLibraryId))
            dispatch(LibraryFileModel.componentActionCreators.updateView({libraryFileId: null}))
        }
    }, [dispatch, fileLibraryId]);

    function showForm() {
        dispatch(FileLibraryModel.actionCreators.showForm())
    }

    const selectedFileLibrary = fileLibraries.get(fileLibraryId);

    const isNotEmpty = isNotEmptyNorFalsy(fileLibraries);
    if (!canModify && !isNotEmpty) {
        return null;
    }

    return (
        <MainPageContent {...attr}
            title={
                <MainPageHeader
                    text={t('fileLibrary:page.title')}
                    description={t('fileLibrary:page.description')}
                />
            }
            list={
                <LoadingWrapper isLoading={isLoading}>

                    {(canModify || isNotEmpty) &&
                        <div className="display-item">
                            <AddButtonHeader aria-label={t('aria:hiddenAssistText.addFileLibrary')} text={t('fileLibrary:label.name')}
                                             canModify={canModify} onClick={showForm}
                            />

                            {isNotEmpty &&
                                <FileLibraryTable fileLibraries={fileLibraries}/>
                            }
                        </div>
                    }
                </LoadingWrapper>
            }
        >
            {(selectedFileLibrary != null) &&
                <FileLibraryTablet fileLibraryId={fileLibraryId} canModify={canModify}/>
            }

            {isFileLibraryFormActive &&
                <FileLibraryForm/>
            }
        </MainPageContent>
    )
}

export default FileLibraryDisplay;