import React from 'react';
import {connect} from 'react-redux';
import {removeUnderlineCapitalizeAll, switchcase} from '../../utilities/helperFunctions';
import ProgressRing from '../common/ProgressRing/ProgressRing';
import {getPriorityIcon, getStatusIconImgContent, icon} from "../../utilities/iconResolver";
import {statusKeys} from "../../i18next/keys";
import {useTranslation} from "react-i18next";
import EngineModel from "../../models/settings/EngineModel";
import Text from "../common/Text/Text";
import {TableLabelCell, TableRow} from "../common/CustomTable/CustomTable";

const EngineRow = props => {
    const {
        name,
        serverName,
        priority,
        currentExecutionMode,
        targetNuixWorkers,
        acquiredWorkers
    } = props;
    const {status, runningJobPercentageComplete, engineId, showEngineTablet, isActive, isRemote} = props;
    const {t} = useTranslation(['common']);

    const priorityImg = getPriorityIcon(priority);

    return (
        <TableRow value={engineId} isActive={isActive} onClick={showEngineTablet}>
            <div className="table-cell status-name-description-cell" style={{maxWidth: '25rem'}}>
                <div style={{display: 'flex', height: '100%'}}>

                    <span className="icon" title={t(`common:status.${status}`)}>
                      {statusIcon(status, runningJobPercentageComplete, isRemote)}
                    </span>

                    <span className="icon" title={priorityImg ? (`common:priority.${priority}`) : null}>
                        {priorityImg &&
                        <img src={priorityImg} alt={t(`aria:hiddenAssistText.${priority}PriorityIcon`)}/>
                        }
                    </span>

                    <Text value={name} style={{flex: 1, minWidth: 0}}
                        isBold isEllipsis/>
                </div>
            </div>

            <TableLabelCell label={serverName}/>

            <TableLabelCell label={targetNuixWorkers}/>

            <TableLabelCell label={acquiredWorkers}/>

            <TableLabelCell label={t(`common:status.${status}`)}/>

            <div className="table-cell">
              <span className="icon" title={currentExecutionMode && removeUnderlineCapitalizeAll(currentExecutionMode)}
                  style={{margin: '-0.25rem', position: 'relative', top: '0.25rem'}}>
              {
                  ((currentExecutionMode === 'AUTOMATE_NUIX' || currentExecutionMode === 'AUTOMATE_NATIVE') &&
                      <img src={icon("executionMode" + currentExecutionMode)} alt={t(`aria:hiddenAssistText.${currentExecutionMode}Icon`)}/>
                  )
              }
              </span>
            </div>
        </TableRow>
    );
}

const statusIcon = (status, percentageComplete, isRemote) => {

  const icon = switchcase({
    [statusKeys.NOT_INITIALIZED]: 'statusPaused',
    [statusKeys.INITIALIZING]: 'statusBrokenLink',
    [statusKeys.INITIALIZED]: 'statusScheduled',
    [statusKeys.STANDBY]: 'statusScheduled',
    [statusKeys.PENDING]: 'statusPending',
    [statusKeys.ERROR]: 'statusError',
    [statusKeys.WARNING]: 'statusWarning',
    [statusKeys.RUNNING]: isRemote ? 'statusRunningRemote' : (
      <ProgressRing percentageComplete={percentageComplete} size={'medium'}/>),
    [statusKeys.FINISHED]: <ProgressRing percentageComplete={percentageComplete} size={'medium'}/>
  })()(status);

  return (typeof icon === 'string') ? getStatusIconImgContent(icon) : icon;
};

const mapStateToProps = (state, ownProps) => {
  const {name, serverId, nuixLicenceSourceId, priority, nuixLicenceType, nuixVersion, javaVersion, currentExecutionMode, targetNuixWorkers, minNuixWorkers, acquiredWorkers, runningJobPercentageComplete, status, workerAgentOnly} = state.engineDetailsMap.get(ownProps.engineId);
  const isActive = state.componentStates.engineDisplay.engineId === ownProps.engineId;
  const isRemote = workerAgentOnly;

  const serverDetails = state.serverDetailsMap.get(serverId);
  const serverName = serverDetails ? serverDetails.name : serverId;

  const nuixLicenceSourceDetails = state.nuixLicenceSourceDetailsMap.get(nuixLicenceSourceId);
  const nuixLicenceSourceName = nuixLicenceSourceDetails ? nuixLicenceSourceDetails.name : nuixLicenceSourceId;

  return {
    name,
    serverName,
    nuixLicenceSourceName,
    priority,
    nuixLicenceType,
    nuixVersion,
    javaVersion,
    currentExecutionMode,
    targetNuixWorkers,
    minNuixWorkers,
    acquiredWorkers,
    status,
    runningJobPercentageComplete,
    isActive,
    isRemote
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showEngineTablet: event => dispatch(EngineModel.actionCreators.showTablet(event.currentTarget.dataset.value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EngineRow);
