import {filterArrayIndices, getNewArrayWithUpdatedValue} from "../../../utilities/helperFunctions";
import {updateEditState} from "../../../utilities/componentFunctions";
import {updateMode} from "../../../utilities/constants";

export function onInputListAddInput({listName, stateUpdater, mode}) {
    switch (mode) {
        case updateMode.LOCAL:
        case updateMode.REDUX:
            return function() {
                stateUpdater.call(this, prevState => {
                    return {
                        [listName]: [...prevState[listName], '']
                    }
                });
            };
        case updateMode.EDIT:
            return function() {
                const updates = {
                    [listName]: [...this.props[listName], '']
                };
                updateEditState.call(this, updates, {stateUpdater});
            };
        default:
            break;
    }
}

export function onInputListDeleteInput({listName, stateUpdater, mode, isEnabled, shouldEnable, passwordName, passwordRef}) {
    switch (mode) {
        case updateMode.LOCAL:
        case updateMode.REDUX:
            return function(indices) {
                stateUpdater.call(this, prevState => {
                    const updates = {
                        [listName]: filterArrayIndices(prevState[listName], indices)
                    };
                    if (isEnabled && shouldEnable) {
                        updates[isEnabled] = shouldEnable({
                            ...prevState,
                            ...updates,
                            [passwordName]: passwordRef && passwordRef.current.value
                        });
                    }
                    return updates;
                });
            };
        case updateMode.EDIT:
            return function(indices) {
                const updates = {
                    [listName]: filterArrayIndices(this.props[listName], indices)
                };
                updateEditState.call(this, updates, {shouldEnable, passwordName, passwordRef});
            };
        default:
            break;
    }
}

export function onInputListInputChange({listName, stateUpdater, mode, isEnabled, shouldEnable, passwordName, passwordRef}) {
    switch (mode) {
        case updateMode.LOCAL:
        case updateMode.REDUX:
            return function(event) {
                const { value, dataset: {value: index} } = event.target;

                stateUpdater.call(this, prevState => {
                    const updates = {
                        [listName]: getNewArrayWithUpdatedValue(prevState[listName], value, index)
                    };
                    if (isEnabled && shouldEnable) {
                        updates[isEnabled] = shouldEnable({
                            ...prevState,
                            ...updates,
                            [passwordName]: passwordRef && passwordRef.current.value
                        });
                    }
                    return updates;
                });
            };
        case updateMode.EDIT:
            return function(event) {
                const { value, dataset: {value: index} } = event.target;

                const updates = {
                    [listName]: getNewArrayWithUpdatedValue(this.props[listName], value, index)
                };
                updateEditState.call(this, updates, {shouldEnable, passwordName, passwordRef});
            };
        default:
            break;
    }
}
