import {getHandler, onChange} from "../../../utilities/componentFunctions";
import {useCallback} from "react";
import {getDataNameFromParentNode, getKeys} from "../../../utilities/helperFunctions";

export function onMenuOptionClick(event) {
    const { value } = event.currentTarget.dataset;
    (this[value] || this.props[value])(event);
}

export function onDropdownItemClick(options) {
    return function (event) {
        const {dataset: {value}, parentNode} = event.currentTarget;
        const name = getDataNameFromParentNode(parentNode);

        const updates = {[name]: value};
        onChange.call(this, updates, options)
    }
}

export function createDropdownHandler(options) {
    const handler = getHandler(options);

    return function(event) {
        const {dataset: {value}, parentNode} = event.currentTarget;
        const name = getDataNameFromParentNode(parentNode);

        const updates = {
            [name]: value
        };
        handler(updates, event);
    }
}

export function useFilterDropdownHandler(options) {
    const {updateState} = options;

    return useCallback(event => {
        const {dataset: {value}, parentNode} = event.currentTarget;
        const name = getDataNameFromParentNode(parentNode);

        updateState(prevState => {
            const prevFilter = prevState[name] || {};
            const prevValue = prevFilter[value];
            return {
                [name]: {
                    ...prevFilter,
                    [value]: !prevValue
                }
            }
        });
    }, [updateState]);
}

export function useFilterDropdownClearHandler(options) {
    const {updateState} = options;

    return useCallback(event => {
        const name = getDataNameFromParentNode(event.currentTarget.parentNode);
        updateState(prevState => {
            const clearedFilter = {};
            for (const key of getKeys(prevState[name])) {
                clearedFilter[key] = null;
            }
            return {[name]: clearedFilter}
        });
    }, [updateState]);
}
