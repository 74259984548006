import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {updateGenericValues} from "../../../redux/WizardSlice/wizardSlice";
import {createToggleHandler} from "../../../../components/common/Checkbox/helpers";
import {createInputHandler, createValueHandler} from "../../../../utilities/componentFunctions";
import React from "react";
import ToggleSwitch from "../../../../components/common/ToggleSwitch/ToggleSwitch";
import QuestionRadio from "../../Question/QuestionRadio/QuestionRadio";
import WizardFileDropdown, {fileTypeFilter} from "../../Dropdown/FileDropdown/WizardFileDropdown";
import {createDropdownHandler} from "../../../../components/common/Dropdown/helpers";
import {libraryFileTypeKeys} from "../../../../i18next/keys";

function OcrStep (props) {
    const {t} = useTranslation(['wizard'])
    const {updateState, files} = props;

    const {
        performOcr,
        defineProfileType,
        ocrProfileName
    } = props;

    const questions = [
        {
            question: t("wizard:questions.ocr.ocrOptions")
        },
        {
            question: t("wizard:questions.ocr.defineProfile"),
            dataName: "defineProfileType",
            options: [
                {id: 1, value: t("wizard:questions.ocr.profile.default")},
                {id: 2, value: t("wizard:questions.ocr.profile.define")},
                // {id: 3, value: t("wizard:questions.ocr.profile.prompt")}
            ]
        },
        {
            question: t("wizard:questions.ocr.profileName")
        }
    ]

    const toggleHandler = createToggleHandler({
        handler: updateState
    });
    const valueHandler = createValueHandler({
        handler: updateState
    });
    const inputHandler = createInputHandler({
        handler: updateState
    })
    const dropdownHandler = createDropdownHandler({
        handler: updateState
    })

    return (
        <div className="wizard-step-container">
            <div className={"display-item"}>
                <label className="label is-heading">
                    {questions[0].question}
                </label>
                <div className={'settings-table no-border'} style={{border: '1px solid transparent'}}>
                    <div className="table-row-group">
                        <div className="table-row no-hover align-middle">
                            <div className="table-cell no-stretch">
                                <ToggleSwitch label={t("wizard:questions.ocr.performOcr")} name={"performOcr"} checked={performOcr} onClick={toggleHandler}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {performOcr &&
                <>
                    <QuestionRadio
                        question={questions[1].question}
                        dataName={questions[1].dataName}
                        options={questions[1].options}
                        selectedValue={defineProfileType}
                        onChange={valueHandler}/>

                    {defineProfileType === 2 &&
                        <div className={"display-input"}>
                            <label className="label is-small">
                                {questions[2].question}
                            </label>
                            <WizardFileDropdown
                                id={"ocrProfileDropdown"}
                                name={"ocrProfileName"}
                                value={ocrProfileName}
                                isRequired={defineProfileType === 2}
                                filter={libraryFile => fileTypeFilter(libraryFile, libraryFileTypeKeys.OCR_PROFILE)}
                                onFileSelected={dropdownHandler}
                                selectedFiles={[ocrProfileName]}
                                files={files}
                                noFileWarning={t("wizard:questions.ocr.noOcrProfiles")}
                            />
                        </div>
                    }
                </>
            }
        </div>
    )
}

function mapStateToProps(state) {
    const {
        wizardSlice: { ocrSettings: {performOcr, defineProfileType, ocrProfileName}}
    } = state;

    return {
        performOcr,
        defineProfileType,
        ocrProfileName
    };
}

function mapDispatchToProps (dispatch) {
    return {
        updateState: updates => dispatch(updateGenericValues({stateName: "ocrSettings", updates: updates}))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OcrStep);