import React, {useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import Tablet, {EditTabletHeader} from '../../common/Tablet/Tablet';
import {
    createEditHandler,
    createInputHandler,
    createModifyingActionHandler,
} from "../../../utilities/componentFunctions";
import {DefaultEditPanel} from "../../common/EditPanel/EditPanel";
import ExpandableContent, {
    ExpandableEditTextArea,
    ExpandableTimeStampedLog
} from "../../common/ExpandableContent/ExpandableContent";
import {isNotEmptyNorFalsy, objEquals, switchcaseF} from "../../../utilities/helperFunctions";
import {makeGetEditDetails} from "../../../reselect/selectors";
import {shouldEnableLocalResourcePool} from "../../../utilities/shouldEnableFunctions";
import {permissionKeys, statusKeys} from "../../../i18next/keys";
import PopupModel from "../../../models/scheduler/PopupModel";
import ResourcePoolModel from "../../../models/settings/ResourcePoolModel";
import EditModel from "../../../models/scheduler/EditModel";
import {SwitchItemContainer} from "../../common/ListContainer/ListContainer";
import {ExpandableStatusLog} from "../../common/Common";
import {SettingsRowValue} from '../../common/CustomTable/CustomTable';
import {applicationFeatures} from "../../../utilities/constants";
import LimitedTablet from "../../limited/LimitedTablet";

function LocalResourcePoolTablet(props) {
    const {t} = useTranslation(['resourcePool']);

    const dispatch = useDispatch();
    const {isDisabled} = useSelector(state => state.componentStates.resourcePoolTablet);

    const {resourcePoolId} = props;
    let [resourcePoolDetails, status, userPermissions] = useSelector(state => {
        const {name: localResourcePoolName, engineIds, remoteEngineIds, userPermissions, status, ...others} = state.resourcePoolDetailsMap.get(resourcePoolId);
        function getName(id) {
            const details = state.engineDetailsMap.get(id);

            if (details != null)
                return details.name;

            return id;
        }

        return [
            {
                localResourcePoolName,
                engines: engineIds.map(getName),
                remoteEngines: remoteEngineIds.map(getName),
                ...others
            },
            status,
            userPermissions
        ];
    }, objEquals);

    // useMemo to memoize selector instance
    const getEditDetails = useMemo(makeGetEditDetails, []);
    // Get resourcePoolDetails from Redux state or EditDetails
    const {
        editDetails,
        isEditActive,
        isSaveEnabled
    } = useSelector(state => {
        const {activeModel, values, isSaveEnabled} = getEditDetails(state, {model: ResourcePoolModel.nom});
        const isEditActive = (activeModel === ResourcePoolModel.nom);

        return {
            editDetails: values,
            isEditActive,
            isSaveEnabled
        }
    }, shallowEqual);
    // If Edit, grab details from editValues
    if (isEditActive) {
        resourcePoolDetails = editDetails;
    }

    const {
        localResourcePoolName,
        description,
        active,
        log,
        engines,
        remoteEngines,
        workerBrokerIp,
        workerBrokerPort
    } = resourcePoolDetails;

    const modifyingActionHandler = createModifyingActionHandler({
        item: t('resourcePool:label.name'),
        showError: payload => dispatch(PopupModel.actionCreators.showError(payload)),
        userPermissions
    });

    const {
        updateEdit,
        setEditSaveEnabled,
        saveEdit,
        cancelEdit
    } = EditModel.buildDispatchers(dispatch);

    const editHandler = createEditHandler({
        updateEdit,
        setEditSaveEnabled,
        shouldEnable: shouldEnableLocalResourcePool,
        values: {localResourcePoolName}
    });

    const inputHandler = createInputHandler({
        handler: editHandler
    });

    function setEngines(update) {
        const updates = {
            engines: update(engines)
        };

        editHandler(updates);
    }

    function setRemoteEngines(update) {
        const updates = {
            remoteEngines: update(remoteEngines)
        };

        editHandler(updates);
    }

    function menuOptionHandler(event) {
        const {value} = event.currentTarget.dataset;

        switchcaseF({
            'edit': () => dispatch(ResourcePoolModel.actionCreators.startEdit(resourcePoolId)),
            'toggleActive': () => dispatch(ResourcePoolModel.actionCreators.toggleEnabled(resourcePoolId)),
            'promptDelete': () => dispatch(ResourcePoolModel.actionCreators.promptDelete(resourcePoolId)),

            'duplicate': () => dispatch(ResourcePoolModel.actionCreators.duplicateDetails(resourcePoolId))
        })()(value);
    }

    const menuOptions = [
        {name: t('common:option.edit'), value: 'edit'},
        {name: t(`common:option.${active ? 'deactivate' : 'activate'}`), value: 'toggleActive'},
        {name: t('common:option.delete'), value: 'promptDelete'},
        {isSeparator: true},
        {name: t('common:option.duplicate'), value: 'duplicate'}
    ];

    const close = () => dispatch(ResourcePoolModel.actionCreators.hideTablet());
    const canModify = userPermissions.includes(permissionKeys.MODIFY);
    const canViewRemoteEngines = useSelector(state => state.currentUser.features.includes(applicationFeatures.REMOTE_ENGINES));
    const showDescription = isEditActive || description;
    const showEngines = isEditActive || isNotEmptyNorFalsy(engines);
    const showRemoteEngines = (isEditActive || isNotEmptyNorFalsy(remoteEngines)) && canViewRemoteEngines;

    const height = isEditActive ? 'auto' : '50vh';

    const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);
    if (isViewLimited && !canModify) {
        return (
            <LimitedTablet id={resourcePoolId} type={'localResourcePool'} name={localResourcePoolName} label={t(`resourcePool:label.localName`)}
                           status={status} enabled={active} canModify={canModify} isDisabled={isDisabled} description={description}
                           onClose={close}
            />
        )
    }

    return (
        <Tablet height={height} onClose={close} isDisabled={isDisabled}
            closeButtonAriaLabel={t('resourcePool:option.closeLocalTablet_name', {name: localResourcePoolName})}
            header={
                <EditTabletHeader label={t('resourcePool:label.localName')} type={'localResourcePool'}
                    id={resourcePoolId} name={'localResourcePoolName'} enabled={active} value={localResourcePoolName} inputHandler={inputHandler}
                    canModify={canModify} menuOptions={menuOptions} menuOptionHandler={menuOptionHandler}
                    isEditActive={isEditActive} isDisabled={isDisabled}
                />
            }
            body={
                <>
                    {status.code === statusKeys.ERROR &&
                    <div className="display-item">
                        <ExpandableStatusLog {...status} isDisabled={isDisabled}/>
                    </div>
                    }

                    <div className="display-item">
                        <DefaultEditPanel isActive={isEditActive} isSaveEnabled={isSaveEnabled} onSave={saveEdit} onCancel={cancelEdit}>
                            {showDescription &&
                            <ExpandableEditTextArea label={t('common:label.description')} name={'description'} value={description}
                                onChange={modifyingActionHandler(inputHandler)} isEditActive={isEditActive} isDisabled={isDisabled}/>
                            }

                            {showEngines &&
                            <ExpandableContent label={t('resourcePool:label.engines')}>
                                {isEditActive ?
                                    <SwitchItemContainer id="engineSwitchContainer" items={engines} setItems={modifyingActionHandler(setEngines)}
                                        ariaLabelKey={'Engine'} isDisabled={isDisabled}/>
                                    :
                                    engines.map(name =>
                                        <label key={name} className="label">
                                            {name}
                                        </label>
                                    )
                                }
                            </ExpandableContent>
                            }

                            {showRemoteEngines &&
                            <ExpandableContent label={t('resourcePool:label.remoteEngines')} initialExpanded={remoteEngines.length > 0}>
                                
                                {isEditActive ?
                                    <SwitchItemContainer id="remoteEngineSwitchContainer" items={remoteEngines} setItems={modifyingActionHandler(setRemoteEngines)}
                                        ariaLabelKey={'RemoteEngine'} isDisabled={isDisabled}/>
                                    :
                                    remoteEngines.map(name =>
                                        <label key={name} className="label">
                                            {name}
                                        </label>
                                    )
                                }
                            </ExpandableContent>
                            }

                            {workerBrokerIp && workerBrokerPort &&
                            <ExpandableContent label={t('resourcePool:label.workerBroker')}>
                                <div className="settings-table">
                                    <div className="table-row-group">
                                        {SettingsRowValue({label: t('resourcePool:label.ip'), value: workerBrokerIp})}
                                        {SettingsRowValue({label: t('resourcePool:label.port'), value: workerBrokerPort})}
                                    </div>
                                </div>
                            </ExpandableContent>
                            }

                        </DefaultEditPanel>
                    </div>

                    {isNotEmptyNorFalsy(log) &&
                    <ExpandableTimeStampedLog label={t('resourcePool:label.log')} log={log} isDisabled={isDisabled}/>
                    }
                </>
            }
        />
    )
}

export default LocalResourcePoolTablet;
