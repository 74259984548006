import {
    actionCreator,
    camelCase,
    capitalizeFirstLetter,
    postProtectedValue,
    translateMinutesToHours
} from "../../utilities/helperFunctions";
import ComponentStateModel from "../generics/ComponentStateModel";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import {contextCall, contextSaga, isDisabledWrapper} from "../../saga/sagaFunctions";
import {all, put, race, select, take, takeLeading} from "redux-saga/effects";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import {details, PASSWORD_PLACEHOLDER, routes, userServiceObjectTypes} from "../../utilities/constants";
import {tryCatchWrapper} from "../../saga/tryCatchWrapper";
import PopupModel from "../scheduler/PopupModel";
import {loginLinkScopeKeys, settingsDisplayKeys, statusKeys} from "../../i18next/keys";
import DetailsModel, {DetailsSaga} from "../generics/DetailsModel";
import SchedulerModel from "../scheduler/SchedulerModel";


class UserServiceModel extends DetailsModel {

    static nom = 'UserServiceModel';
    static actions = UserServiceModel.buildActions('USER_SERVICE');
    static actionCreators = UserServiceModel.buildActionCreators(UserServiceModel.actions);
    static reducer = UserServiceModel.buildReducer(UserServiceModel.actions);

    static managedUsersRowTemplate = [{value: ''}, {value: ''}];
    static componentActionCreators = {
        ...UserServiceModel.buildComponentUpdateActionCreators(),
        ...UserServiceModel.buildComponentSetActiveActionCreators()
    };

    static types = {
        INTERNAL: 'INTERNAL',
        MANAGED: 'MANAGED',
        LDAP: 'LDAP',
        RELATIVITY_IMPERSONATION: 'RELATIVITY_IMPERSONATION',
        UMS: 'UMS',
        OIDC: 'OIDC',
        MICROSOFT: 'MICROSOFT'
    };

    static MicrosoftOAuthVersion = {
        V1: 'V1',
        V2: 'V2'
    };

    static oidcTypes = {
        GENERIC: 'GENERIC'
    };

    static AuthMethodType = {
        USERNAME_PASSWORD: 'UsernamePassword',
        OIDC: 'Oidc',
        OIDC_MICROSOFT: 'OidcMicrosoft',
        OIDC_GOOGLE: 'OidcGoogle',
        OIDC_RELATIVITY: 'OidcRelativity'
    };

    static OidcLoginMode = {
        THIRD_PARTY_SERVICE: 'THIRD_PARTY_SERVICE',
        E_DISCOVERY_MANAGER: 'E_DISCOVERY_MANAGER',
        E_DISCOVERY_DOWNLOADER: 'E_DISCOVERY_DOWNLOADER',
        VAULT_USER: 'VAULT_USER'
    };

    constructor(model={}) {
        super();
        this.forceUpdate(model);
        this.type = this.userServiceType;
        this.description ||= '';
        this.whitelistedCertFingerprints = model.whitelistedCertFingerprints || [];
    }

    static doesTypeUseCustomUsers(type) {
        return type === UserServiceModel.types.INTERNAL || type === UserServiceModel.types.MANAGED;
    }

    static doesTypeRequireServiceAccount(type) {
        return type === UserServiceModel.types.UMS || type === UserServiceModel.types.LDAP;
    }

    static canTypeTestAuth(type) {
        return type === UserServiceModel.types.UMS || type === UserServiceModel.types.LDAP;
    }

    static canTypeUploadCsv(type) {
        return type === UserServiceModel.types.MICROSOFT;
    }

    static canTypeSyncUsers(type, wellKnownConfiguration) {
        return [UserServiceModel.types.UMS, UserServiceModel.types.LDAP, UserServiceModel.types.MICROSOFT].includes(type)
            || UserServiceModel.isGoogleOidc(type, wellKnownConfiguration);
    }

    static canTypeSyncObjects(type, wellKnownConfiguration) {
        return UserServiceModel.canTypeSyncUsers(type, wellKnownConfiguration);
    }

    static canTypeUseLoginLinks(type) {
        return type === UserServiceModel.types.MANAGED || (UserServiceModel.canTypeSyncUsers(type) && type !== UserServiceModel.types.UMS);
    }

    static isRelativityOidc(type, wellKnownConfiguration) {
        return type === UserServiceModel.types.OIDC && wellKnownConfiguration.toLowerCase().includes('/relativity/identity/');
    }

    static isGoogleOidc(type, wellKnownConfiguration) {
        return type === UserServiceModel.types.OIDC && wellKnownConfiguration?.toLowerCase().includes('accounts.google');
    }

    static getTranslationType(type, wellKnownConfiguration) {
        wellKnownConfiguration = wellKnownConfiguration || ''
        if (type === UserServiceModel.types.OIDC) {
            if (this.isGoogleOidc(type, wellKnownConfiguration)) {
                return 'userServiceGoogle';
            }
            if (this.isRelativityOidc(type, wellKnownConfiguration)) {
                return 'userServiceRelativity';
            }
            return 'userServiceOidcGeneric';
        }
        return `userService${capitalizeFirstLetter(camelCase(type))}`;
    }

    static validateFormData(formData) {
        switch (formData.type) {
            case UserServiceModel.types.MANAGED: {
                return UserServiceModel.validateManagedFormData(formData);
            }
            case UserServiceModel.types.UMS: {
                return UserServiceModel.validateUmsUserService(formData);
            }
            case UserServiceModel.types.LDAP: {
                return UserServiceModel.validateLdapUserService(formData);
            }
            case UserServiceModel.types.RELATIVITY_IMPERSONATION: {
                return UserServiceModel.validateRelativityImpersonationUserService(formData);
            }
            case UserServiceModel.types.OIDC: {
                return UserServiceModel.validateOidcUserService(formData);
            }
            case UserServiceModel.types.MICROSOFT: {
                return UserServiceModel.validateMicrosoftUserService(formData);
            }
        }
    }

    static isManagedUsersTableValid(users) {
        return users.every(row => !!(row[0].value && row[1].value));
    }

    static validateManagedFormData(formData) {
        const {name, users} = formData;

        return !!(name && UserServiceModel.isManagedUsersTableValid(users));
    }

    static validateUmsUserService(formData) {
        const {name, url, synchronizeUsers, serviceAccountName, serviceAccountPassword, searchDelay} = formData;

        const isUserSyncValid = !synchronizeUsers || (!!serviceAccountName && !!serviceAccountPassword && searchDelay > 0);
        return !!name && !!url && isUserSyncValid;
    }

    static validateLdapUserService(formData) {
        const {name, ldapHost, ldapPort, domainDn, synchronizeUsers, synchronizeComputers, userBaseDn, userSearchScope, computerBaseDn, computerSearchScope,
            serviceAccountName, serviceAccountPassword, searchDelay, enableLoginLinks, loginLinkScope, loginLinkAutoExpireInterval} = formData;

        const isUserSyncValid = !synchronizeUsers || (!!serviceAccountName && !!serviceAccountPassword && searchDelay > 0 && !!userBaseDn && !!userSearchScope)
        const isComputerSyncValid = !synchronizeComputers || (!!serviceAccountName && !!serviceAccountPassword && searchDelay > 0 && !!computerBaseDn && !!computerSearchScope)
        const isLoginLinkValid = !enableLoginLinks || (!!loginLinkScope && parseFloat(loginLinkAutoExpireInterval) >= 0);

        return !!ldapHost && ldapPort > 0 && !!domainDn && !!name && isUserSyncValid && isComputerSyncValid && isLoginLinkValid;
    }

    static validateRelativityImpersonationUserService(formData) {
        const {name} = formData;
        return !!name;
    }

    static validateOidcUserService(formData) {
        const {name, wellKnownConfiguration, scope, usernameClaim, clientId, clientSecret} = formData;
        return !!name && !!clientId && !!clientSecret && !!scope && !!usernameClaim && !!wellKnownConfiguration;
    }

    static validateMicrosoftUserService(formData) {
        const {name, environment, tenant, clientId, clientSecret, synchronizeUsers, searchDelay} = formData;

        return (!synchronizeUsers || searchDelay > 0)
            && !!name && !!environment && !!tenant && !!clientId && !!clientSecret;
    }

    static getObjectItem(obj) {
        switch (obj.objectType) {
            case userServiceObjectTypes.USER_ACCOUNT:
            case userServiceObjectTypes.UNIFIED_GROUP:
                return {name: obj.email, value: obj.email};
            case userServiceObjectTypes.SHARE_POINT_SITE:
                return {name: obj.webUrl, value: obj.webUrl};
            case userServiceObjectTypes.GOOGLE_GROUP:
                return {name: obj.email, value: obj.email};
            case userServiceObjectTypes.GOOGLE_ORG_UNIT:
                let name = obj.path;
                if (name.startsWith('/') && name.length > 1) {
                    name = name.slice(1);
                }
                if (!name.endsWith(obj.name)) {
                    name += ` (${obj.name})`;
                }
                return {name, value: obj.id};
            case userServiceObjectTypes.GOOGLE_CHAT_SPACE:
                return {name: obj.displayName, value: obj.id};
            default:
                return {name: obj.name, value: obj.id};
        }
    }

    static buildActions(type) {
        return {
            ...super.buildActions(type),
            // USER SERVICE ACTIONS
            EXECUTE_USER_SERVICE: 'EXECUTE_USER_SERVICE',
            TEST_USER_SERVICE_EDIT: 'TEST_USER_SERVICE_EDIT',
            TEST_USER_SERVICE: 'TEST_USER_SERVICE'
        }
    }

    static buildActionCreators(actions) {
        return {
            ...super.buildActionCreators(actions),
            // USER SERVICE ACTION CREATOR
            executeUserService: actionCreator(actions.EXECUTE_USER_SERVICE, 'id'),
            testUserServiceEdit: actionCreator(actions.TEST_USER_SERVICE_EDIT, 'formData'),
            testUserService: actionCreator(actions.TEST_USER_SERVICE, 'id')
        }
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'userServiceDisplay',
                type: 'Display',
                state: {
                    userServiceId: null,
                    isManagedUserServiceFormActive: false,
                    isLdapUserServiceFormActive: false,
                    isUmsUserServiceFormActive: false,
                    isOidcUserServiceFormActive: false,
                    isMicrosoftUserServiceFormActive: false,
                    isRelativityImpersonationUserServiceFormActive: false
                }
            },
            {
                key: 'userServiceTablet',
                type: 'Tablet',
                state: {
                    isDisabled: false
                }
            },
            {
                key: 'userServiceForm',
                type: 'Form',
                state: {
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'USER_SERVICES_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }
}

export class UserServiceApi {

    static getDetails() {
        return axiosInstance.get(`/scheduler/users/userService`)
    }

    static putDetails(type, updates, force=false) {
        return axiosInstance.put(`/scheduler/users/userService/${camelCase(type)}?force=${force}`, updates)
    }

    static putExecuteUserService(userServiceId) {
        return axiosInstance.put(`/scheduler/users/userService/${userServiceId}/execute`);
    }

    static putTestUserService(userServiceId) {
        return axiosInstance.put(`/scheduler/users/userService/${userServiceId}/test`);
    }

    static putTestUserServiceEdit(type, userService) {
        return axiosInstance.put(`/scheduler/users/userService/${camelCase(type)}/test`, userService);
    }

    static putCsv(id, contents) {
        return axiosInstance.put(`/scheduler/users/userService/${id}/csv`, {contents});
    }

    static post(type, userService) {
        return axiosInstance.post(`/scheduler/users/userService/${camelCase(type)}`, userService);
    }

    static delete(userServiceId, force=false) {
        return axiosInstance.del(`/scheduler/users/userService/${userServiceId}?force=${force}`);
    }

    static postManagedUserServiceUsers(userServiceId, users, force=false) {
        return axiosInstance.post(`/scheduler/users/userService/managed/${userServiceId}/users?force=${force}`, users);
    }

    static getManagedUserServiceUsers(userServiceId) {
        return axiosInstance.get(`/scheduler/users/userService/managed/${userServiceId}/users`);
    }

    static getUserServiceObjects(userServiceId, {objectType, filterText, limit=1000}) {
        const queryParams = new URLSearchParams();
        if (objectType) queryParams.append('objectType', objectType);
        if (filterText) queryParams.append('filterText', filterText);
        if (limit) queryParams.append('limit', String(limit));

        const requestId = generateUUID4();
        return {
            id: requestId,
            promise: axiosInstance.postWorkerRequestWithId(requestId, 'get', `/scheduler/users/userService/${userServiceId}/objects?${queryParams.toString()}`)
        };
    }
}

export class UserServiceSaga extends DetailsSaga {

    static ModelType = UserServiceModel;
    static ModelApi = UserServiceApi;

    static activationComponent = 'USER_SERVICES_DISPLAY';
    static variableNames = {
        detailsMap: 'userServiceDetailsMap',
        instanceId: 'userServiceId',
        updateDisplay: 'updateDisplay',
        updatePane: 'updateTablet',
        route: routes.SETTINGS
    };

    static translations = {
        itemTitle: '$t(userService:label.name)',
        itemLower: '$t(userService:label.name_lower)'
    };

    static buildActivationEffects(dispatch) {
        return [
            ...super.buildActivationEffects(dispatch),
            put(this.ModelType.actionCreators.startPollingDetails()),
            // ACTIVATION EFFECTS
            takeLeading(this.ModelType.actions.EXECUTE_USER_SERVICE, tryCatchWrapper, isDisabledWrapper, UserServiceModel.componentActionCreators.updateTablet, contextSaga(this, 'executeUserService')),
            takeLeading(this.ModelType.actions.TEST_USER_SERVICE_EDIT, tryCatchWrapper, isDisabledWrapper, UserServiceModel.componentActionCreators.updateTablet, contextSaga(this, 'testUserServiceEdit')),
            takeLeading(this.ModelType.actions.TEST_USER_SERVICE, tryCatchWrapper, isDisabledWrapper, UserServiceModel.componentActionCreators.updateTablet, contextSaga(this, 'testUserService')),
        ];
    }

    static buildDeactivationEffects() {
        return [
            ...super.buildDeactivationEffects(),
            put(this.ModelType.actionCreators.stopPollingDetails())
        ]
    }

    static* setInstanceId(args) {
        const {updateDisplay, instanceId} = this.variableNames;

        yield all ([
            put(SchedulerModel.actionCreators.setSettingsDisplay(settingsDisplayKeys.USER_SERVICES)),
            put(this.ModelType.componentActionCreators[updateDisplay]({[instanceId]: args.id}))
        ]);
    }

    static getIsFormActive(args) {
        return `is${capitalizeFirstLetter(camelCase(args.type))}UserServiceFormActive`;
    }

    static* executeUserService(action) {
        const {id} = action.payload;
        let data;
        try {
            const res = yield contextCall(UserServiceApi, 'putExecuteUserService', id);
            data = res.data;

        } catch (error) {
            if (error.response?.data?.key === 'userServiceExecutionWarning') {
                yield put(PopupModel.actionCreators.showWarning({
                    info: error.response.data
                }));
                return;
            }
            throw error;
        }

        yield put(this.ModelType.actionCreators.updateDetails({[id]: new this.ModelType(data)}));
        const userService = yield select(state => state.userServiceDetailsMap.get(id));

        const popupKey = 'userServiceSynchronized';
        const values = {
            count: userService.objectCount
        };
        const valueKeys = {
            type: `$t(userService:type.${userService.type})`
        };

        let showPopupAction;
        if (userService.status.code === statusKeys.WARNING) {
            showPopupAction = PopupModel.actionCreators.showWarning({
                info: {key: popupKey, values, valueKeys},
                cancelButton: {titleKey: 'common:option.ok'}
            });
        } else {
            showPopupAction = PopupModel.actionCreators.showSuccess({
                info: {key: popupKey, values, valueKeys}
            });
        }
        yield put(showPopupAction);
    }

    static* testUserService(action) {
        const {id} = action.payload;
        const {data} = yield contextCall(UserServiceApi, 'putTestUserService', id);

        yield put(PopupModel.actionCreators.showSuccess({
            info: data
        }));
    }

    static* testUserServiceEdit(action) {
        const {formData} = action.payload;

        const saveValues = yield contextCall(this, 'getSaveValues', formData, true);
        const {data} = yield contextCall(UserServiceApi, 'putTestUserServiceEdit', formData.type, saveValues);

        yield put(PopupModel.actionCreators.showSuccess({
            info: data
        }));
    }

    static* toggleEnabled(action) {
        const {id} = action.payload;
        const userService = yield select(state => state.userServiceDetailsMap.get(id));
        const {enabled, type} = userService;
        // If disabling a userService with loginLinks
        if (!!enabled && this.getUserServiceLoginLinkEnabled(userService)) {
            yield contextCall(this, 'showSaveWarning', {key: 'ldapDisabledWithLoginLinkEnable'});
        }

        yield* this.handleForceRequest(function* (force) {
            const {data} = yield contextCall(UserServiceApi, 'putDetails', type, {enabled: !enabled, id: id}, force);
            yield put(UserServiceModel.actionCreators.updateDetails({[id]: new this.ModelType(data)}));
        });
    }

    static* submitForm(action) {
        const {updateDisplay, instanceId} = this.variableNames;

        const {formData} = action.payload;
        const saveValues = yield contextCall(this, 'getSaveValues', formData, true);

        const {data} = yield contextCall(this.ModelApi, 'post', saveValues.type, saveValues);
        // Add managed users
        if (saveValues.type === UserServiceModel.types.MANAGED) {
            yield contextCall(this, 'updateManagedUserServiceUsers', data.id, saveValues.users);
        }

        const isFormActive = this.getIsFormActive(formData);
        yield all([
            put(this.ModelType.actionCreators.addDetails(data)),
            put(this.ModelType.componentActionCreators[updateDisplay]({[isFormActive]: false, [instanceId]: data.id}))
        ]);
    }

    static* updateManagedUserServiceUsers(id, users, force) {
        yield contextCall(UserServiceApi, 'postManagedUserServiceUsers', id, users, force);
        yield put(UserServiceModel.actionCreators.queryDetails());
    }

    static getUserServiceLoginLinkEnabled(userService) {
        return userService != null && userService.type !== UserServiceModel.types.MICROSOFT &&
            !!userService.enabled && !!userService.enableLoginLinks && userService.loginLinkAutoExpireInterval != 0;
    }

    static* getSaveValues(editValues, ignoreWarnings) {
        const {userPermissions, status, loginLinkAutoExpireInterval, objectCount, lastSynchronized, updateMessage, log, users, ...rest} = editValues;

        // Get password from DOM (assume only 1 password input)
        const password = (document.getElementsByName('serviceAccountPassword')[0] || {}).value;
        const clientSecret = (document.getElementsByName('clientSecret')[0] || {}).value;

        // Show warning if making a change that will expire all login links
        if (!ignoreWarnings) {
            const oldUserService = yield select(state => state.userServiceDetailsMap.get(rest.id));
            if (this.getUserServiceLoginLinkEnabled(oldUserService)) {
                if (!rest.enableLoginLinks) {
                    yield contextCall(this, 'showSaveWarning', {key: 'ldapLoginLinkDisabled'});
                } else if (loginLinkAutoExpireInterval == 0) {
                    yield contextCall(this, 'showSaveWarning', {key: 'ldapLoginLink0AutoExpireInterval'});
                } else if (!rest.enabled) {
                    yield contextCall(this, 'showSaveWarning', {key: 'ldapDisabledWithLoginLinkEnable'});
                }
            }
        }

        const saveValues = {
            loginLinkAutoExpireInterval: loginLinkAutoExpireInterval && Math.round(loginLinkAutoExpireInterval * 60),
            ...rest,
            userServiceType: rest.type,
            serviceAccountPassword: postProtectedValue(password),
            clientSecret: postProtectedValue(clientSecret)
        };

        // Managed user service users
        if (saveValues.type === UserServiceModel.types.MANAGED) {
            saveValues.synchronizeUsers = true;
            saveValues.enableLoginLinks = true;
            saveValues.users = users.filter(row => row.every(cell => !!cell.value)).map(row => ({
                name: row[0].value,
                email: row[1].value
            }));
        }

        return saveValues;
    }

    static* getEditValues(id) {
        const {userPermissions, status, loginLinkAutoExpireInterval, loginLinkScope, groupClaim,
            objectCount, lastSynchronized, updateMessage, searchDelay, ...rest} = yield select(state => state.userServiceDetailsMap.get(id));

        const editValues = {
            loginLinkAutoExpireInterval: translateMinutesToHours(loginLinkAutoExpireInterval),
            loginLinkScope: loginLinkScope || loginLinkScopeKeys.LEGAL_HOLD,
            searchDelay: searchDelay || 30,
            ...rest,
            groupClaim: !!groupClaim ? groupClaim : "",
            serviceAccountPassword: PASSWORD_PLACEHOLDER,
            clientSecret: PASSWORD_PLACEHOLDER
        };

        if (editValues.type === UserServiceModel.types.MANAGED) {
            const {data: managedUsers} = yield contextCall(UserServiceApi, 'getManagedUserServiceUsers', id);
            editValues.users = managedUsers.map(user => ([{value: user.name}, {value: user.email}]));
        }

        editValues.warningStatus = null;
        return editValues;
    }

    static* saveEdit(id) {
        const editValues = yield select(state => state.editDetails.values);
        const saveValues = yield contextCall(this, 'getSaveValues', editValues);

        let data;
        yield* this.handleForceRequest(function* (force) {
            const res = yield contextCall(this.ModelApi, 'putDetails', saveValues.type, saveValues, force);
            data = res.data;
            yield put(this.ModelType.actionCreators.updateDetails({[id]: new this.ModelType(data)}));

            // Update managed users
            if (saveValues.type === UserServiceModel.types.MANAGED) {
                yield contextCall(this, 'updateManagedUserServiceUsers', data.id, saveValues.users, force);
            }
        });
    }

    static* handleForceRequestError(error, options={}) {
        const {
            confirmTitleKey='common:option.save'
        } = options;

        switch (error.response.data.key) {
            case 'usersPartOfLegalHolds':
            case 'usersPartOfActiveLegalHolds':
                yield put(PopupModel.actionCreators.showWarning({
                    info: error.response.data,
                    buttons: [{
                        titleKey: confirmTitleKey,
                        onClick: async function (dispatch) {
                            try {
                                dispatch(UserServiceModel.componentActionCreators.updateTablet({isDisabled: true}));
                                dispatch({type: 'FORCE_USER_SERVICE_REQUEST'});
                            } catch (error) {
                                dispatch(SchedulerModel.actionCreators.handleResponseError(error));
                            } finally {
                                dispatch(UserServiceModel.componentActionCreators.updateTablet({isDisabled: false}));
                            }
                        }
                    }],
                    cancelButton: {
                        titleKey: 'common:option.cancel',
                        onClick: function (dispatch) {
                            dispatch({type: 'CANCEL_USER_SERVICE_REQUEST'});
                        }
                    }
                }));

                const [forced,] = yield race([
                    take('FORCE_USER_SERVICE_REQUEST'),
                    take('CANCEL_USER_SERVICE_REQUEST')
                ]);
                return !!forced;
        }

        yield* super.handleForceRequestError(error, options);
    }

    static* queryDetails() {
        const response = yield contextCall(UserServiceApi, 'getDetails');

        const key = details.USER_SERVICES;
        if (AxiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(UserServiceModel.actionCreators.setDetailsMap(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }
}

export default UserServiceModel;
