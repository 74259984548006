import React, {useEffect, useState} from "react";
import {getEntries, getObjectText, getValues, isNotEmptyNorFalsy} from "../../utilities/helperFunctions";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {applicationFeatures, MIN_SIZE_FOR_SEARCH} from "../../utilities/constants";
import MatterModel from "../../models/client/MatterModel";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {AddButtonHeader} from "../common/Button/Button";
import SearchBar from "../common/SearchBar/SearchBar";
import MatterRow from "./MatterRow";
import Switch from "../common/Switch/Switch";
import MatterTablet from "./MatterTablet";
import MatterForm from "./MatterForm";


function selectMatters(state, props) {
    return getEntries(state.matterDetailsMap)
        .filter(([ignore, _matter]) => _matter.clientId === props.clientId
            && getObjectText(_matter, {blacklist: ['id']}).includes(props.searchText.toLowerCase()))
        .map(([id]) => id);
}

function MatterDisplay(props) {
    const {t} = useTranslation(['matter', 'aria']);
    const dispatch = useDispatch();

    const canManageDataSets = useSelector(state => state.currentUser.features.includes(applicationFeatures.SCHEDULER_UPLOAD));

    const {
        clientId,
        canAddMatter
    } = props;

    const [searchText, setSearchText] = useState('');

    const matterIds = useSelector(state => selectMatters(state, {clientId, searchText}));
    const canSearchMatters = useSelector(state => getValues(state.matterDetailsMap)
        .filter(matter => matter.clientId === clientId)
        .length > MIN_SIZE_FOR_SEARCH
    );

    const isLoading = useSelector(state => !state.hasLoaded[clientId]);
    const {
        matterId,
        isMatterFormActive
    } = useSelector(state => state.componentStates.clientDisplay);

    // Start/stop polling
    useEffect(() => {
        dispatch(MatterModel.actionCreators.startPollingDetailsSubset(clientId));

        return () => dispatch(MatterModel.actionCreators.stopPollingDetailsSubset());
    }, [clientId, dispatch]);

    function showForm() {
        dispatch(MatterModel.actionCreators.showForm());
    }

    function showTablet(event) {
        const {value} = event.currentTarget.dataset;
        dispatch(MatterModel.actionCreators.showTablet(value));
    }

    function toggleEnable(event) {
        const {value} = event.currentTarget.dataset;
        dispatch(MatterModel.actionCreators.toggleEnabled(value));
    }

    const isNotEmpty = isNotEmptyNorFalsy(matterIds);
    if (!canAddMatter && !isNotEmpty) {
        return null;
    }

    return (
        <div className="display-item">
            <LoadingWrapper isLoading={isLoading}>
                <div className="flex-center space-between">
                    <AddButtonHeader aria-label={t('aria:hiddenAssistText.addMatter')} text={t('matter:label.name')}
                        onClick={showForm} canModify={canAddMatter}
                    />

                    {canSearchMatters &&
                    <SearchBar id="matterSearchBar" style={{maxWidth: '15rem', margin: '0.5rem 0'}}
                        value={searchText} onChange={event => setSearchText(event.target.value)}
                    />
                    }
                </div>

                {isNotEmpty &&
                <div className="display-table">
                    <div className="table-header-group">
                        <div className="table-header enable-cell"/>
                        <div className="table-header"/>
                    </div>

                    <div className="table-row-group">
                        {matterIds.map(mId =>

                            <MatterRow key={mId} isActive={mId === matterId} id={mId}
                                onSelect={showTablet} toggleEnable={toggleEnable}
                            />
                        )}
                    </div>
                </div>
                }
            </LoadingWrapper>

            <Switch>
                {isMatterFormActive &&
                <MatterForm clientId={clientId}/>
                }

                {matterIds.includes(matterId) &&
                <MatterTablet matterId={matterId}/>
                }
            </Switch>
        </div>
    )
}

export default MatterDisplay;
