import React from "react";
import {SettingsRowValue} from "../../common/CustomTable/CustomTable";
import {useTranslation} from "react-i18next";
import ThirdPartyUserCredential from "../../../models/thirdparty/ThirdPartyUserCredential";
import UsernamePasswordUserCredentialFormBody from "../userCredential/UsernamePasswordCredentialFormBody";

function RelativityUserCredentialFormBody(props) {
    return (
        <UsernamePasswordUserCredentialFormBody {...props} type={ThirdPartyUserCredential.Type.RELATIVITY}
            SettingRows={RelativityUserCredentialFormBodySettingRows}/>
    )
}

function RelativityUserCredentialFormBodySettingRows(props) {
    const {t} = useTranslation(['thirdPartyService', 'common']);
    const {
        thirdPartyService,
        isDisabled
    } = props;

    return (
        <>
            <SettingsRowValue label={t('thirdPartyService:label.hostname')} value={thirdPartyService.hostname}
                isTight isDisabled={isDisabled}/>
        </>
    )
}

export default RelativityUserCredentialFormBody;
