import React, {useCallback, useRef, useState} from 'react';
import '../ResourcePoolForm.css';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {createCloseHandler, createInputHandler, createStateHandler} from "../../../utilities/componentFunctions";
import {createToggleHandler} from "../../common/Checkbox/helpers";
import Checkbox from "../../common/Checkbox/Checkbox";
import TextArea from "../../common/TextArea/TextArea";
import {ValueList} from "../../common/InputList/InputList";
import {
    AzureEnvironmentDropdown,
    AzureRegionDropdown,
    AzureVmSizeDropdown,
    AzureVmSourceDropdown,
    AzureVmTypeDropdown,
    InstanceIdleActionDropdown,
    NuixLicenceSourceDropdown
} from "../../common/Dropdown/Dropdown";
import Form, {FormFooter, FormHeader} from "../../common/Form/Form";
import {shouldEnableAzureResourcePool} from "../../../utilities/shouldEnableFunctions";
import {
    buildFakeEvent,
    capitalizeFirstLetter,
    compareNumStrings,
    getDataNameFromParentNode
} from "../../../utilities/helperFunctions";
import {instanceIdleActionKeys, popupInfoKeys} from "../../../i18next/keys";
import {azureVmSources, resourcePoolTypes} from "../../../utilities/constants";
import ResourcePoolModel from "../../../models/settings/ResourcePoolModel";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import PopupModel from "../../../models/scheduler/PopupModel";
import {createDropdownHandler} from "../../common/Dropdown/helpers";
import {FormElementRow} from "../../common/CustomTable/CustomTable";
import Text from "../../common/Text/Text";
import Switch from "../../common/Switch/Switch";
import ExpandableContent from "../../common/ExpandableContent/ExpandableContent";
import {useClearOnFirstCallback} from "../../../utilities/formHooks";


function AzureResourcePoolForm() {
    const {t} = useTranslation(['resourcePool', 'common']);
    const dispatch = useDispatch();

    const azureEnvironments = useSelector(state => state.schedulerDetails.configuration.azureEnvironments);
    const {isDisabled, ...formProps} = useSelector(state => state.componentStates.resourcePoolForm);

    const passwordRef = useRef({});
    const customVmPasswordRef = useRef({});
    const remoteCustomVmPasswordRef = useRef({});

    const [state, setState] = useState({
        azureResourcePoolName: '',
        description: '',
        active: true,

        azureEnvironment: azureEnvironments[0],
        tenant: '',
        appId: '',
        subscriptionId: '',

        ...formProps,
        type: resourcePoolTypes.AZURE,

        // Engine configuration
        azureVmSource: azureVmSources.vmNames,
        region: '',
        resourceGroupId: '',
        networkName: '',
        networkSubnetName: 'default',
        networkSecurityGroup: '',
        customVmImageId: '',
        customVmUsername:'',
        tagName: '',
        tagValue: '',
        maxConcurrentInstances: 2,
        vmType: '',
        vmSize: '',
        diskSize: 100,

        nuixLicenceSourceId: '',
        targetNuixWorkers: 2,
        minNuixWorkers: 1,

        instanceIdleAction: instanceIdleActionKeys.STOP,
        forceIdleActionBetweenJobs: true,

        useRemoteEngines: false,
        // Remote engine configuration
        remoteAzureVmSource: azureVmSources.vmNames,
        remoteRegion: '',
        remoteResourceGroupId: '',
        remoteNetworkName: '',
        remoteNetworkSubnetName: 'default',
        remoteNetworkSecurityGroup: '',
        remoteCustomVmImageId: '',
        remoteCustomVmUsername: '',
        remoteTagName: '',
        remoteTagValue: '',
        remoteMaxConcurrentInstances: 2,
        remoteVmType: '',
        remoteVmSize: '',
        remoteDiskSize: 100,

        remoteNuixLicenceSourceId: '',
        remoteTargetNuixWorkers: 2,
        remoteMinNuixWorkers: 1,

        remoteInstanceIdleAction: instanceIdleActionKeys.STOP,
        remoteNoIdleBeforeFirstJob: false,
        remoteNoIdleDuringJob: false
    });


    const [vmNames, setVmNames] = useState(['']);
    const [remoteVmNames, setRemoteVmNames] = useState(['']);
    const [whitelistedCertFingerprints, setWhitelistedCertFingerprints] = useState(['']);

    const stateHandler = createStateHandler({
        updateState: setState,
        passwordOptions: {
            azureKey: {
                ref: passwordRef,
                resetNames: ['tenant']
            },
            customVmPassword: {
                ref: customVmPasswordRef,
                resetNames: ['customVmUsername']
            },
            remoteCustomVmPassword: {
                ref: remoteCustomVmPasswordRef,
                resetNames: ['remoteCustomVmUsername', 'useRemoteEngines']
            }
        }
    });

    const inputHandler = createInputHandler({
        handler: stateHandler
    });

    const toggleHandler = createToggleHandler({
        handler: stateHandler
    });

    const dropdownHandler = createDropdownHandler({
        handler: stateHandler
    });


    function onSubmit() {
        dispatch(ResourcePoolModel.actionCreators.submitForm({
            ...state,

            vmNames,
            remoteVmNames,
            whitelistedCertFingerprints
        }));
    }

    const onClose = createCloseHandler({
        t,
        item: t('resourcePool:label.name'),
        values: state,
        blacklist: [''],
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        close: () => dispatch(ResourcePoolModel.actionCreators.hideForm(resourcePoolTypes.AZURE))
    });

    const {
        azureResourcePoolName,
        description,
        active,

        azureEnvironment,
        tenant,
        appId,
        subscriptionId,

        azureVmSource,
        region,
        resourceGroupId,
        networkName,
        networkSubnetName,
        networkSecurityGroup,
        customVmImageId,
        customVmUsername,
        tagName,
        tagValue,
        maxConcurrentInstances,
        vmType,
        vmSize,
        diskSize,

        nuixLicenceSourceId,
        targetNuixWorkers,
        minNuixWorkers,

        instanceIdleAction,
        forceIdleActionBetweenJobs,

        useRemoteEngines,
        // Remote engine configuration
        remoteAzureVmSource,
        remoteRegion,
        remoteResourceGroupId,
        remoteNetworkName,
        remoteNetworkSubnetName,
        remoteNetworkSecurityGroup,
        remoteCustomVmImageId,
        remoteCustomVmUsername,
        remoteTagName,
        remoteTagValue,
        remoteMaxConcurrentInstances,
        remoteVmType,
        remoteVmSize,
        remoteDiskSize,

        remoteNuixLicenceSourceId,
        remoteTargetNuixWorkers,
        remoteMinNuixWorkers,

        remoteInstanceIdleAction,
        remoteNoIdleBeforeFirstJob,
        remoteNoIdleDuringJob,

        referenceId,
        referenceValue
    } = state;

    const engineConfiguration = {
        azureVmSource, region, resourceGroupId, networkName, networkSubnetName, networkSecurityGroup, customVmImageId, customVmUsername, maxConcurrentInstances, vmType, vmSize, diskSize,
        nuixLicenceSourceId, targetNuixWorkers, minNuixWorkers, instanceIdleAction, forceIdleActionBetweenJobs, tagName, tagValue
    };
    const remoteEngineConfiguration = {
        azureVmSource: remoteAzureVmSource, region: remoteRegion, resourceGroupId: remoteResourceGroupId, networkName: remoteNetworkName, networkSubnetName: remoteNetworkSubnetName, networkSecurityGroup: remoteNetworkSecurityGroup,
        customVmImageId: remoteCustomVmImageId, customVmUsername: remoteCustomVmUsername, maxConcurrentInstances: remoteMaxConcurrentInstances, vmType: remoteVmType, vmSize: remoteVmSize, diskSize: remoteDiskSize,
        nuixLicenceSourceId: remoteNuixLicenceSourceId, targetNuixWorkers: remoteTargetNuixWorkers, minNuixWorkers: remoteMinNuixWorkers,
        instanceIdleAction: remoteInstanceIdleAction, noIdleBeforeFirstJob: remoteNoIdleBeforeFirstJob, noIdleDuringJob: remoteNoIdleDuringJob,
        tagName: remoteTagName, tagValue: remoteTagValue
    };

    const isKeyRequired = !(referenceId && !!referenceValue && referenceValue === tenant);
    const isAddEnabled = shouldEnableAzureResourcePool({...state, vmNames, remoteVmNames,
        azureKey: passwordRef.current.value, customVmPassword: customVmPasswordRef.current.value, remoteCustomVmPassword: remoteCustomVmPasswordRef.current.value});

    return (
        <Form onClose={onClose} isDisabled={isDisabled} closeButtonAriaLabel={t('resourcePool:option.closeAzureForm')}
            header={
                <FormHeader text={t('resourcePool:label.azureName')} iconName={'azureResourcePool'} isDisabled={isDisabled} />
            }
            body={
                <div className="cloudResourcePoolForm-body">
                    <div className="display-input">
                        <HTMLTextInput label={t('common:label.name')} name={'azureResourcePoolName'} value={azureResourcePoolName}
                            onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                    </div>

                    <Checkbox label={t('resourcePool:label.active')} name={'active'} checked={active}
                        onClick={toggleHandler} isDisabled={isDisabled}/>

                    <div className="display-input">
                        <TextArea label={t('common:label.description')} name={'description'} value={description}
                            onChange={inputHandler} isDisabled={isDisabled}/>
                    </div>

                    <AzurePoolSettingsForm azureEnvironment={azureEnvironment} tenant={tenant} appId={appId} subscriptionId={subscriptionId}
                        passwordRef={passwordRef} inputHandler={inputHandler} dropdownHandler={dropdownHandler}
                        isKeyRequired={isKeyRequired} isDisabled={isDisabled}/>

                    <div className="display-input">
                        <ExpandableContent label={t('resourcePool:label.engines')}
                            isDisabled={isDisabled}
                        >
                            <AzurePoolEngineForm {...engineConfiguration} customVmPasswordRef={customVmPasswordRef}
                                inputHandler={inputHandler} toggleHandler={toggleHandler} dropdownHandler={dropdownHandler}
                                vmNames={vmNames} setVmNames={setVmNames} isDisabled={isDisabled}/>
                        </ExpandableContent>
                    </div>

                    <div className="display-input">
                        <ExpandableContent label={t('resourcePool:label.remoteEngines')}
                            isDisabled={isDisabled}
                        >
                            <div style={{marginTop: '0.25rem', marginBottom: '0.5rem'}}>
                                <Checkbox label={t('resourcePool:label.useRemoteEngines')} name={'useRemoteEngines'} checked={useRemoteEngines}
                                    onClick={toggleHandler} isDisabled={isDisabled}/>
                            </div>

                            {useRemoteEngines &&
                            <AzurePoolEngineForm {...remoteEngineConfiguration} customVmPasswordRef={remoteCustomVmPasswordRef}
                                inputHandler={inputHandler} toggleHandler={toggleHandler} dropdownHandler={dropdownHandler}
                                vmNames={remoteVmNames} setVmNames={setRemoteVmNames} isRemoteConfiguration  isDisabled={isDisabled}/>
                            }
                        </ExpandableContent>
                    </div>

                    <div className="display-input">
                        <ValueList id={'whitelistedCertFingerprints'} label={t('common:label.whitelistedCertFingerprints')}
                            ariaLabelKey={'WhitelistedCertFingerprint'} values={whitelistedCertFingerprints}
                            setValues={setWhitelistedCertFingerprints} isDisabled={isDisabled}/>
                    </div>
                </div>
            }
            footer={
                <FormFooter addText={t('common:button.addResourcePool')} onAddClick={onSubmit} onCancel={onClose}
                isAddEnabled={isAddEnabled} isDisabled={isDisabled}/>
            }
        />
    )
}

export function AzurePoolSettingsForm(props) {
    const {t} = useTranslation(['resourcePool']);

    const {
        azureEnvironment,
        tenant,
        azureKey,
        appId,
        subscriptionId,

        passwordRef,
        inputHandler,
        dropdownHandler,

        isKeyRequired,
        isDisabled
    } = props;

    const clearOnFirstCallback = useClearOnFirstCallback();
    return (
        <>
            <div className="forms-table display-input">
                <div className="table-row-group">

                    <FormElementRow label={t('resourcePool:label.environment')} isDisabled={isDisabled}
                        element={
                            <AzureEnvironmentDropdown onItemSelect={dropdownHandler} value={azureEnvironment}
                                isDisabled={isDisabled} isRequired/>
                        }/>
                </div>
            </div>

            <div className="display-input">
                <HTMLTextInput label={t('resourcePool:label.tenant')} name={'tenant'} value={tenant}
                    onChange={inputHandler} isDisabled={isDisabled} isRequired/>
            </div>

            <div className="display-input">
                <HTMLTextInput inputRef={passwordRef} label={t('resourcePool:label.key')} name={'azureKey'} type={'password'}
                    defaultValue={azureKey} onChange={inputHandler} onBeforeInput={clearOnFirstCallback}
                    isDisabled={isDisabled} isRequired={isKeyRequired}/>
            </div>

            <div className="display-input">
                <HTMLTextInput label={t('resourcePool:label.appId')} name={'appId'} value={appId}
                    onChange={inputHandler} isDisabled={isDisabled} isRequired/>
            </div>

            <div className="display-input">
                <HTMLTextInput label={t('resourcePool:label.subscriptionId')} name={'subscriptionId'} value={subscriptionId}
                    onChange={inputHandler} isDisabled={isDisabled}/>
            </div>
        </>
    )
}

export function AzurePoolEngineForm(props) {
    const {t} = useTranslation(['resourcePool', 'common']);
    const dispatch = useDispatch();

    const {
        azureVmSource,
        region,
        resourceGroupId,
        networkName,
        networkSubnetName,
        networkSecurityGroup,
        customVmImageId,
        customVmUsername,
        customVmPassword,
        customVmPasswordRef,
        tagName,
        tagValue,
        maxConcurrentInstances,
        vmType,
        vmSize,
        diskSize,

        nuixLicenceSourceId,
        targetNuixWorkers,
        minNuixWorkers,

        instanceIdleAction,
        forceIdleActionBetweenJobs,
        noIdleBeforeFirstJob,
        noIdleDuringJob,

        vmNames,
        setVmNames,

        inputHandler,
        toggleHandler,
        dropdownHandler,

        isRemoteConfiguration,
        isDisabled
    } = props;

    const instanceIdleActionDropdownHandler = useCallback(event => {
        const {dataset: {value}, parentNode} = event.currentTarget;
        if (instanceIdleAction === value) return;

        // event loses 'target/currentTarget' when sending to popup onClick
        const name = getDataNameFromParentNode(parentNode);
        const fakeEvent = buildFakeEvent({name, value});

        if (value === instanceIdleActionKeys.TERMINATE) {
            // Show warning popup
            dispatch(PopupModel.actionCreators.showWarning({
                info: {
                    key: popupInfoKeys.INSTANCE_IDLE_ACTION_TERMINATE_WARNING
                },
                buttons: [{
                    titleKey: 'common:option.ok',
                    onClick: () => dropdownHandler(fakeEvent)
                }]
            }));

        } else {
            dropdownHandler(fakeEvent);
        }
    }, [instanceIdleAction]);

    function translateToRemoteName(name) {
        if (isRemoteConfiguration) {
            return `remote${capitalizeFirstLetter(name)}`;
        }
        return name;
    }


    return (
        <>
            <div className="forms-table">
                <div className="table-row-group">

                    <FormElementRow label={t('resourcePool:label.licenceSource')} isDisabled={isDisabled}
                        element={
                            <NuixLicenceSourceDropdown name={translateToRemoteName('nuixLicenceSourceId')} value={nuixLicenceSourceId} onItemSelect={dropdownHandler}
                                isDisabled={isDisabled} isRequired/>
                        }/>

                    <FormElementRow htmlFor={'azureTargetNuixWorkers'} label={t('resourcePool:label.targetWorkers')} isDisabled={isDisabled}
                        element={
                            <HTMLTextInput id={'azureTargetNuixWorkers'} type={'number'} name={translateToRemoteName('targetNuixWorkers')} value={targetNuixWorkers} style={{width: '5rem'}}
                                onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                        }/>

                    <FormElementRow htmlFor={'azureMinNuixWorkers'} label={t('resourcePool:label.minWorkers')} isDisabled={isDisabled}
                        element={
                            <HTMLTextInput id={'azureMinNuixWorkers'} type={'number'} name={translateToRemoteName('minNuixWorkers')} value={minNuixWorkers} style={{width: '5rem'}}
                                onChange={inputHandler} isInvalid={compareNumStrings(targetNuixWorkers, minNuixWorkers) < 0} isDisabled={isDisabled} isRequired/>
                        }/>
                </div>
            </div>

            <div className="display-input" style={{display: 'flex', alignItems: 'center'}}>
                <Text value={`${t('resourcePool:label.startVmFrom')}:`} style={{marginRight: '1rem'}}/>

                <AzureVmSourceDropdown name={translateToRemoteName('azureVmSource')} value={azureVmSource} onItemSelect={dropdownHandler}
                    isDisabled={isDisabled} isRequired/>
            </div>

            <Switch>
                {azureVmSource === azureVmSources.vmNames &&
                <div className="display-input">
                    <ValueList id={'azureVmNames'} label={t('resourcePool:label.vmNames')}
                        ariaLabelKey={'VmName'} values={vmNames} setValues={setVmNames}
                        isDisabled={isDisabled} isRequired/>
                </div>
                }

                {azureVmSource === azureVmSources.tags &&
                <div className="aws-template-values">
                    <div className="tag-name">
                        <HTMLTextInput label={t('resourcePool:label.tagName')} name={translateToRemoteName('tagName')}
                                       value={tagName} onChange={inputHandler} isDisabled={isDisabled} isRequired
                        />
                    </div>
                    <div className="tag-value">
                        <HTMLTextInput label={t('resourcePool:label.tagValue')} name={translateToRemoteName('tagValue')}
                                       value={tagValue} onChange={inputHandler} isDisabled={isDisabled} isRequired
                        />
                    </div>
                </div>
                }

                {azureVmSource === azureVmSources.customVmImage &&
                <>
                    <div className="display-input">
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Text value={`${t('resourcePool:label.region')}:`} style={{marginRight: '1rem'}}/>

                            <AzureRegionDropdown name={translateToRemoteName('region')} value={region} onItemSelect={dropdownHandler}
                                isDisabled={isDisabled} isRequired/>
                        </div>
                    </div>

                    <div className="display-input">
                        <HTMLTextInput label={t('resourcePool:label.resourceGroupId')} name={translateToRemoteName('resourceGroupId')} value={resourceGroupId}
                            onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                    </div>

                    <div className="display-input">
                        <HTMLTextInput label={t('resourcePool:label.networkName')} name={translateToRemoteName('networkName')} value={networkName}
                            onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                    </div>

                    <div className="display-input">
                        <HTMLTextInput label={t('resourcePool:label.networkSubnetName')} name={translateToRemoteName('networkSubnetName')} value={networkSubnetName}
                            onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                    </div>

                    <div className="display-input">
                        <HTMLTextInput label={t('resourcePool:label.networkSecurityGroup')} name={translateToRemoteName('networkSecurityGroup')} value={networkSecurityGroup}
                                       onChange={inputHandler} isDisabled={isDisabled}/>
                    </div>

                    <div className="display-input" style={{display: 'flex', alignItems: 'flex-end'}}>
                        <HTMLTextInput label={t('resourcePool:label.customVmImageId')} name={translateToRemoteName('customVmImageId')} value={customVmImageId}
                            onChange={inputHandler} isDisabled={isDisabled} isRequired containerStyle={{flexGrow: 1}}/>

                        <div style={{marginLeft: '1rem'}}>
                            <Text htmlFor={'azureMaxConcurrentInstances'} value={t('resourcePool:label.maxConcurrentInstances')} isDisabled={isDisabled}/>

                            <HTMLTextInput id={'azureMaxConcurrentInstances'} name={translateToRemoteName('maxConcurrentInstances')} type={'number'} value={maxConcurrentInstances}
                                onChange={inputHandler} isDisabled={isDisabled} isRequired style={{width: '5rem'}}/>
                        </div>
                    </div>

                    <div className="display-input">
                        <HTMLTextInput label={t('resourcePool:label.customVmUsername')} name={translateToRemoteName('customVmUsername')} value={customVmUsername}
                                       onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                    </div>

                    <div className="display-input">
                        <HTMLTextInput type="password" inputRef={customVmPasswordRef} label={t('resourcePool:label.customVmPassword')} name={translateToRemoteName('customVmPassword')}
                            defaultValue={customVmPassword} onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                    </div>

                    <div className="forms-table display-input">
                        <div className="table-row-group">

                            <FormElementRow label={t('resourcePool:label.vmType')} isDisabled={isDisabled}
                                element={
                                    <AzureVmTypeDropdown name={translateToRemoteName('vmType')} value={vmType} onItemSelect={dropdownHandler}
                                        isDisabled={isDisabled} isRequired/>
                                }/>

                            <FormElementRow label={t('resourcePool:label.vmSize')} isDisabled={isDisabled}
                                element={
                                    <AzureVmSizeDropdown name={translateToRemoteName('vmSize')} value={vmSize} onItemSelect={dropdownHandler}
                                        isDisabled={isDisabled} isRequired/>
                                }/>

                            <FormElementRow htmlFor={'azureVmDiskSize'} label={t('resourcePool:label.diskSize')} isDisabled={isDisabled}
                                element={
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <HTMLTextInput id={'azureVmDiskSize'} type={'number'} name={translateToRemoteName('diskSize')} value={diskSize} style={{width: '5rem'}}
                                            onChange={inputHandler} isInvalid={diskSize < 0} isDisabled={isDisabled} isRequired/>

                                        <Text value={'GB'} style={{marginLeft: '0.5rem'}} isDisabled={isDisabled}/>
                                    </div>
                                }/>
                        </div>
                    </div>
                </>
                }
            </Switch>

            <div className="display-input" style={{display: 'flex'}}>
                <Text value={`${t('resourcePool:label.instanceIdleAction')}:`} isDisabled={isDisabled}
                    style={{margin: 'auto 1rem auto 0'}}/>

                <InstanceIdleActionDropdown name={translateToRemoteName('instanceIdleAction')} value={instanceIdleAction} onItemSelect={instanceIdleActionDropdownHandler}
                    isDisabled={isDisabled} isRequired/>
            </div>

            {isRemoteConfiguration ?
                <>
                    <div className="display-input">
                        <Checkbox label={t('resourcePool:label.remoteNoIdleBeforeFirstJob')} name={translateToRemoteName('noIdleBeforeFirstJob')} checked={noIdleBeforeFirstJob}
                            onClick={toggleHandler} isDisabled={isDisabled}/>
                    </div>

                    <div className="display-input">
                        <Checkbox label={t('resourcePool:label.remoteNoIdleDuringJob')} name={translateToRemoteName('noIdleDuringJob')} checked={noIdleDuringJob}
                            onClick={toggleHandler} isDisabled={isDisabled}/>
                    </div>
                </>
                :
                <div className="display-input">
                    <Checkbox label={t('resourcePool:label.forceIdleActionBetweenJobs')} name={translateToRemoteName('forceIdleActionBetweenJobs')} checked={forceIdleActionBetweenJobs}
                        onClick={toggleHandler} isDisabled={isDisabled}/>
                </div>
            }
        </>
    )
}

export default AzureResourcePoolForm;
