import SubsetDetailsModel from "../generics/SubsetDetailsModel";
import SagaRunnable from "../generics/SagaRunnable";
import {contextPollUntil} from "../../saga/sagaFunctions";
import {actionCreator} from "../../utilities/helperFunctions";
import {put} from "redux-saga/effects";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import {details} from "../../utilities/constants";
import {axiosInstance} from "../api/AxiosProxy";

class UploadInfoModel extends SubsetDetailsModel {

    static nom = 'UploadInfoModel';
    static parentKey = 'datasetId';

    static actions = UploadInfoModel.buildActions('UPLOAD_INFO');
    static actionCreators = UploadInfoModel.buildActionCreators(UploadInfoModel.actions);
    static reducer = UploadInfoModel.buildReducer(UploadInfoModel.actions);

    constructor(model={}) {
        super();

        this.forceUpdate(model);
    }

    static buildActionCreators(actions) {
        return {
            ...super.buildActionCreators(actions),
            startPollingDetails: actionCreator(actions.START_POLLING_DETAILS, 'period', 'filter')
        }
    }
}

export class UploadInfoApi {

    static getUploadInfos(filter) {
        return axiosInstance.post('/scheduler/resources/dataset/uploadInfos', filter);
    }
}

export class UploadInfoSaga extends SagaRunnable {

    static* pollDetails(action) {
        const {period} = action.payload;

        yield contextPollUntil(UploadInfoModel.actions.STOP_POLLING_DETAILS, period, this, 'queryDetails', action);
    }

    static* queryDetails(action) {
        const {filter} = action.payload;

        try {
            const {data} = yield UploadInfoApi.getUploadInfos(filter);
            yield put(UploadInfoModel.actionCreators.setDetailsMap(data));
        } finally {
            yield put(ReduxStateModel.actionCreators.setHasLoaded(details.UPLOAD_INFOS));
        }
    }
}

export default UploadInfoModel;