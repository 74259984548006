import React from 'react';
import {connect} from 'react-redux';
import {useTranslation} from "react-i18next";
import {AddButtonHeader} from "../common/Button/Button";
import {getServerIds} from '../../reselect/selectors';
import ServerRow from './ServerRow';
import {applicationFeatures} from "../../utilities/constants";
import {includesSome, isNotEmptyNorFalsy} from "../../utilities/helperFunctions";
import ServerModel from "../../models/settings/ServerModel";
import {TableLabelHeader} from "../common/CustomTable/CustomTable";

const ServerTable = props => {
    const { serverIds, showServerForm, userFeatures } = props;
    const { t } = useTranslation(['aria', 'server', 'common']);

    const canAddServer = includesSome(userFeatures, [applicationFeatures.ADD_RESOURCE]);
    const isNotEmpty = isNotEmptyNorFalsy(serverIds);
    if (!canAddServer && !isNotEmpty) {
        return null;
    }

    return (
        <div className="display-item">
            <AddButtonHeader aria-label={t('aria:hiddenAssistText.addServer')} text={t('server:label.name')}
                canModify={canAddServer} onClick={showServerForm}
            />

            {isNotEmpty &&
                <div className="display-table">
                    <div className="table-header-group">
                        <TableLabelHeader
                            label={t('common:label.name')}
                        />

                        <TableLabelHeader
                            label={t('server:label.url')}
                        />

                        <TableLabelHeader
                            label={t('server:label.serverVersion')}
                        />

                        <TableLabelHeader
                            label={t('server:label.javaVersion')}
                        />

                        <TableLabelHeader
                            label={t('common:label.status')}
                        />
                    </div>

                    <div className="table-row-group">
                        {serverIds.map(id =>
                            <ServerRow key={id} serverId={id} />
                        )}
                    </div>
                </div>
            }
        </div>
    );
};

const mapStateToProps = state => {
    return {
        serverIds: getServerIds(state),
        userFeatures: state.currentUser.features
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showServerForm: () => dispatch(ServerModel.componentActionCreators.updateDisplay({isServerFormActive: true}))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServerTable);