import {createSelector, createStructuredSelector} from 'reselect';
import {
    applicationFeatures,
    licenceSourceTypes,
    notificationRuleTypes,
    resourcePoolTypes,
    routes,
    userSettings,
} from '../utilities/constants';
import {
    createDeepEqualSelector,
    getIdsList,
    getNameValueList,
    getNameValueTypeList,
    getUserSettingsAllowedParameterValues,
    makeIdentityDeepEqualSelector
} from './reselectFunctions';
import {getEntries, getKeys, getValues, nameLocaleCompare} from "../utilities/helperFunctions";
import {
    policyPrincipalBuiltInKeys,
    policyPrincipalTypeKeys,
    policyScopeBuiltInKeys,
    policyScopeTypeKeys
} from "../i18next/keys";
import EditModel from "../models/scheduler/EditModel";

export const getIsUserLoggedOn = createSelector(
    state => state.currentUser.isAuthenticated,
    state => state.schedulerDetails.isServerScheduler,
    (isAuthenticated, isServerScheduler) => {
        return isAuthenticated && isServerScheduler;
    }
);

export const getUserCanViewRoutePrivileges = createSelector(
    getIsUserLoggedOn,
    state => state.currentUser.features,
    state => state.schedulerDetails.isRelativityApplication,
    (isLoggedOn, userFeatures, isRelativityApplication) => {
        return {
            jobs: isLoggedOn && userFeatures.includes(applicationFeatures.VIEW_JOBS),
            purview: isLoggedOn && userFeatures.includes(applicationFeatures.VIEW_PURVIEW_JOBS),
            vault: isLoggedOn && userFeatures.includes(applicationFeatures.VIEW_VAULT_JOBS),
            schedules: isLoggedOn && userFeatures.includes(applicationFeatures.VIEW_SCHEDULES),
            legalHold: isLoggedOn && userFeatures.includes(applicationFeatures.VIEW_LEGAL_HOLDS),
            legalHoldNotices: isLoggedOn && userFeatures.includes(applicationFeatures.VIEW_LEGAL_HOLD_NOTICES),
            clients: isLoggedOn && userFeatures.includes(applicationFeatures.VIEW_CLIENTS),
            collections: isLoggedOn && userFeatures.includes(applicationFeatures.VIEW_COLLECTIONS),
            libraries: isLoggedOn && userFeatures.includes(applicationFeatures.VIEW_LIBRARIES),
            // settings: isLoggedOn && includesSome(userFeatures, [applicationFeatures.VIEW_SECURITY, applicationFeatures.VIEW_RESOURCES])
            settings: isLoggedOn || isRelativityApplication
        };
    }
);

export const getDefaultRoute = createSelector(
    getUserCanViewRoutePrivileges,
    ({jobs, legalHold, clients, libraries, settings}) => {
        return jobs ? routes.JOBS : legalHold ? routes.LEGAL_HOLD : clients ? routes.CLIENTS : libraries ? routes.LIBRARY : settings ? routes.SETTINGS : null;
    }
);

// Gets EditDetails only if component has association to the Model that is being edited
export const makeGetEditDetails = () => createSelector(
    (state, ownProps) => ownProps.model,
    state => state.editDetails,
    (model, editDetails) => {
        if (model === editDetails.activeModel) {
            return editDetails;
        } else {
            return new EditModel();
        }
    }
);

const getIdNameMap = makeIdentityDeepEqualSelector(
    createSelector(
        state => state.clientDetailsMap,
        state => state.matterDetailsMap,
        state => state.libraryDetailsMap,
        state => state.workflowTemplateDetailsMap,
        state => state.nuixLicenceSourceDetailsMap,
        state => state.executionProfileDetailsMap,
        state => state.resourcePoolDetailsMap,
        state => state.clientPoolDetailsMap,
        state => state.notificationRuleDetailsMap,
        state => state.dataRepositoryDetailsMap,
        state => state.datasetDetailsMap,
        state => state.thirdPartyServiceDetailsMap,
        state => state.noticeTemplateDetailsMap,
        state => state.legalHoldDetailsMap,
        state => state.fileLibraryDetailsMap,
        state => state.userServiceDetailsMap,
        (...args) => {
            const IdNameMap = {};
            args.forEach(arg => getKeys(arg).forEach(id => {
                IdNameMap[id] = arg.get(id).name
            }));

            return IdNameMap;
        }
    )
);

const getNameFromJobDetails = type => (state, ownProps) => {
    const id = (state.jobDetailsMap.get(ownProps.jobId) || state.jobArchiveMap.get(ownProps.jobId) || {})[`${type}Id`];
    const { name } = state[`${type}DetailsMap`].get(id) || {};
    return name;
};

export const makeGetNamesFromJobDetails = () => createStructuredSelector({
    clientName: getNameFromJobDetails('client'),
    matterName: getNameFromJobDetails('matter'),
    libraryName: getNameFromJobDetails('library'),
    workflowName: getNameFromJobDetails('workflowTemplate'),
    engineName: getNameFromJobDetails('engine'),
    executionProfileName: getNameFromJobDetails('executionProfile'),
    resourcePoolName: getNameFromJobDetails('resourcePool'),
    remoteEngines: (state, ownProps) => {
        const {workerAgentIds=[]} = (state.jobDetailsMap.get(ownProps.jobId) || state.jobArchiveMap.get(ownProps.jobId) || {});

        return workerAgentIds
            .map(id => (state.engineDetailsMap.get(id) || {}).name)
            .filter(name => !!name);
    }
});

export const makeGetUpdatedPolicyDetailsScope = () => makeIdentityDeepEqualSelector(
    createSelector(
        (state, props) => state.policyDetailsMap.get(props.policyId || props.policy.id).scope,
        getIdNameMap,
        (scope, idNameMap) => {
            function getUpdatedScope(id, type) {
                const name = idNameMap[id];
                if (name) {
                    return [name, type.slice(0, -3)];
                } else {
                    return [id, type];
                }
            }

            const updatedScope = [];
            for (const item of scope) {
                const [identifierName, identifierType] = getUpdatedScope(item.identifierName, item.identifierType);

                updatedScope.push({identifierName, identifierType});
            }

            return updatedScope;
        }
    )
);

export const getTabletUpdatedPolicyDetailsScope = makeGetUpdatedPolicyDetailsScope();

export const getUserSettingsClientAllowedParameterValues = getUserSettingsAllowedParameterValues(userSettings.NEW_CLIENT);
export const getUserSettingsMatterAllowedParameterValues = getUserSettingsAllowedParameterValues(userSettings.NEW_MATTER);

export const getPolicyIds = makeIdentityDeepEqualSelector(getIdsList('policy'));

const getNuixLicenceSourceIdSources = createSelector(
    state => state.nuixLicenceSourceDetailsMap,
    nuixLicenceSourceDetailsMap => {
        return getEntries(nuixLicenceSourceDetailsMap)
            .map(([id, nuixLicenceSourceModel]) => ({id, sourceType: nuixLicenceSourceModel.sourceType}))
    }
);
export const getNmsNuixLicenceSourceIds = createDeepEqualSelector(
    getNuixLicenceSourceIdSources,
    nuixLicenceSourceIdSources => {
        return nuixLicenceSourceIdSources
            .filter(({sourceType}) => sourceType === licenceSourceTypes.NMS)
            .map(({id}) => id);
    }
);
export const getCloudLicenceSourceIds = createDeepEqualSelector(
    getNuixLicenceSourceIdSources,
    nuixLicenceSourceIdSources => {
        return nuixLicenceSourceIdSources
            .filter(({sourceType}) => sourceType === licenceSourceTypes.CLS)
            .map(({id}) => id);
    }
);
export const getNuixDongleLicenceSourceIds = createDeepEqualSelector(
    getNuixLicenceSourceIdSources,
    nuixLicenceSourceIdSources => {
        return nuixLicenceSourceIdSources
            .filter(({sourceType}) => sourceType === licenceSourceTypes.DONGLE)
            .map(({id}) => id);
    }
);

export const getServerIds = makeIdentityDeepEqualSelector(getIdsList('server'));
export const getEngineIds = makeIdentityDeepEqualSelector(getIdsList('engine'));
export const getExecutionProfileIds = makeIdentityDeepEqualSelector(getIdsList('executionProfile'));

const getResourcePoolIdTypes = createSelector(
    state => state.resourcePoolDetailsMap,
    resourcePoolDetailsMap => {
        return getEntries(resourcePoolDetailsMap)
            .map(([id, resourcePoolModel]) => ({id, type: resourcePoolModel.type}))
    }
);
export const getLocalResourcePoolIds = createDeepEqualSelector(
    getResourcePoolIdTypes,
    resourcePoolIdTypes => {
        return resourcePoolIdTypes
            .filter(({type}) => type === resourcePoolTypes.LOCAL)
            .map(({id}) => id);
    }
);
export const getAwsResourcePoolIds = createDeepEqualSelector(
    getResourcePoolIdTypes,
    resourcePoolIdTypes => {
        return resourcePoolIdTypes
            .filter(({type}) => type === resourcePoolTypes.AWS)
            .map(({id}) => id);
    }
);

export const getAzureResourcePoolIds = createDeepEqualSelector(
    getResourcePoolIdTypes,
    resourcePoolIdTypes => {
        return resourcePoolIdTypes
            .filter(({type}) => type === resourcePoolTypes.AZURE)
            .map(({id}) => id);
    }
);

const getNotificationRuleIdTypes = createSelector(
    state => state.notificationRuleDetailsMap,
    notificationRuleDetailsMap => {
        return getEntries(notificationRuleDetailsMap)
            .map(([id, notificationRule]) => ({id, type: notificationRule.ruleType}))
    }
);
export const getEmailNotificationRuleIds = createDeepEqualSelector(
    getNotificationRuleIdTypes,
    notificationRuleIdTypes => {
        return notificationRuleIdTypes
            .filter(({type}) => type === notificationRuleTypes.EMAIL_SMTP)
            .map(({id}) => id)
    }
);

export const getWebhookNotificationRuleIds = createDeepEqualSelector(
    getNotificationRuleIdTypes,
    notificationRuleIdTypes => {
        return notificationRuleIdTypes
            .filter(({type}) => type === notificationRuleTypes.WEBHOOK)
            .map(({id}) => id)
    }
);

export const getNuixLicenceSourceNameValues = makeIdentityDeepEqualSelector(getNameValueList('nuixLicenceSource'));
export const getServerNameValues = makeIdentityDeepEqualSelector(getNameValueList('server'));

export const getEngineNameValues = makeIdentityDeepEqualSelector(
    createSelector(
        state => state.engineDetailsMap,
        detailsMap => {
            return getEntries(detailsMap)
                .filter(([id, engineModel]) => engineModel.tags.eligibleForLocalPool)
                .map(([id, engineModel]) => ({name: engineModel.name, value: id}));
        }
    )
);

export const getNotificationRuleNameValues = makeIdentityDeepEqualSelector(getNameValueList('notificationRule'));

export const getClientNameValueTypes = makeIdentityDeepEqualSelector(getNameValueTypeList('client', policyScopeTypeKeys.CLIENT_ID));
export const getLibraryNameValueTypes = makeIdentityDeepEqualSelector(getNameValueTypeList('library', policyScopeTypeKeys.LIBRARY_ID));

export const getNuixLicenceSourceNameValueTypes = makeIdentityDeepEqualSelector(getNameValueTypeList('nuixLicenceSource', policyScopeTypeKeys.NUIX_LICENCE_SOURCE_ID));
export const getExecutionProfileNameValueTypes = makeIdentityDeepEqualSelector(getNameValueTypeList('executionProfile', policyScopeTypeKeys.EXECUTION_PROFILE_ID));

export const getResourcePoolNameValueTypes = makeIdentityDeepEqualSelector(getNameValueTypeList('resourcePool', policyScopeTypeKeys.RESOURCE_POOL_ID));
export const getClientPoolNameValueTypes = makeIdentityDeepEqualSelector(getNameValueTypeList('clientPool', policyScopeTypeKeys.CLIENT_POOL_ID));
export const getNotificationRuleNameValueTypes = makeIdentityDeepEqualSelector(getNameValueTypeList('notificationRule', policyScopeTypeKeys.NOTIFICATION_RULE_ID));

export const getDataRepositoryNameValueTypes = makeIdentityDeepEqualSelector(getNameValueTypeList('dataRepository', policyScopeTypeKeys.DATA_REPOSITORY_ID))
export const getThirdPartyServiceNameValueTypes = makeIdentityDeepEqualSelector(getNameValueTypeList('thirdPartyService', policyScopeTypeKeys.THIRD_PARTY_SERVICE_ID))

export const getNoticeTemplateNameValueTypes = makeIdentityDeepEqualSelector(getNameValueTypeList('noticeTemplate', policyScopeTypeKeys.NOTICE_TEMPLATE_ID))
export const getLegalHoldNameValueTypes = makeIdentityDeepEqualSelector(getNameValueTypeList('legalHold', policyScopeTypeKeys.LEGAL_HOLD_ID))
export const getUserServiceNameValueTypes = makeIdentityDeepEqualSelector(
    createSelector(
        state => state.userServiceDetailsMap,
        userServiceDetailsMap => {
            return getEntries(userServiceDetailsMap).map(([id, model]) => ({name: model.name, value: id, type: policyScopeTypeKeys.USER_SERVICE_ID}));
        }
    )
);

export const getFileLibraryNameValueTypes = makeIdentityDeepEqualSelector(getNameValueTypeList('fileLibrary', policyScopeTypeKeys.FILE_LIBRARY_ID))

export const getMatterNameValueTypes = makeIdentityDeepEqualSelector(
    createSelector(
        state => state.matterDetailsMap,
        matterDetailsMap => {
            return getEntries(matterDetailsMap)
                .map(([id, matterModel]) => {
                    const { name, clientId } = matterModel;

                    return {
                        name,
                        value: id,
                        parentId: clientId,
                        type: policyScopeTypeKeys.MATTER_ID
                    }
                }).sort(nameLocaleCompare);
        }
    )
);

export const getWorkflowTemplateNameValueTypes = makeIdentityDeepEqualSelector(
    createSelector(
        state => state.workflowTemplateDetailsMap,
        workflowDetailsMap => {
            return getEntries(workflowDetailsMap)
                .map(([id, workflowTemplateModel]) => {
                    const { name, libraryId } = workflowTemplateModel;

                    return {
                        name,
                        value: id,
                        parentId: libraryId,
                        type: policyScopeTypeKeys.WORKFLOW_ID
                    };
                }).sort(nameLocaleCompare);
        }
    )
);

export const getBuiltInPolicyScopeNameValueTypes = makeIdentityDeepEqualSelector(() => {
    return getValues(policyScopeBuiltInKeys).map(keyValue => {
        return {
            value: keyValue,
            type: policyScopeTypeKeys.BUILTIN
        };
    }).sort(nameLocaleCompare);
});

export const getBuiltInPolicyPrincipalValueTypes = makeIdentityDeepEqualSelector(() => {
    return getValues(policyPrincipalBuiltInKeys).map(keyValue => {
        return {
            value: keyValue,
            type: policyPrincipalTypeKeys.BUILTIN
        };
    }).sort(nameLocaleCompare);
});