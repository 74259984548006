import React from "react";
import Form, {FormFooter, FormHeader} from "../../common/Form/Form";
import {useTranslation} from "react-i18next";
import {lowerCaseFirstLetter} from "../../../utilities/helperFunctions";
import {SettingsRowValue} from "../../common/CustomTable/CustomTable";

export function SimpleTestForm(props) {
    const {t} = useTranslation(['thirdPartyService', 'common']);

    const {
        thirdPartyService,
        SettingRows,
        body,
        onTest,
        onClose,
        isSubmitEnabled,
        isDisabled
    } = props;

    const camelCaseType = lowerCaseFirstLetter(thirdPartyService.type);

    return (
        <Form onClose={onClose} width={'35rem'}
            isDisabled={isDisabled} closeButtonAriaLabel={t(`thirdPartyService:option.closeTestForm${thirdPartyService.type}`)}
            header={
                <FormHeader text={t(`thirdPartyService:option.test`)}
                    iconName={`${camelCaseType}`} isDisabled={isDisabled}/>
            }
            body={
                <form onSubmit={onTest} style={{paddingTop: '0.5rem', paddingBottom: '0.25rem'}}>
                    <div className="settings-table">
                        <div className="table-row-group">
                            <SettingsRowValue label={t(`thirdPartyService:label.${camelCaseType}`)} value={thirdPartyService.name}
                                isTight isDisabled={isDisabled}/>

                            {SettingRows &&
                                <SettingRows thirdPartyService={thirdPartyService} isDisabled={isDisabled}/>
                            }
                        </div>
                    </div>

                    {body}

                    <button type="submit" hidden disabled={!isSubmitEnabled}/>
                </form>
            }
            footer={
                <FormFooter addText={props.addText || t('thirdPartyService:option.test')} onAddClick={onTest}
                    onCancel={onClose} isAddEnabled={isSubmitEnabled} isDisabled={isDisabled}
                />
            }
        />
    )
}

export default SimpleTestForm;
