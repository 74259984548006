import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import {CheckedDropdown, ClientCheckedDropdown, MatterCheckedDropdown} from "../common/Dropdown/Dropdown";
import SearchBar from "../common/SearchBar/SearchBar";
import {useTranslation} from "react-i18next";
import FileUploadHistoryModel from "../../models/data/FileUploadHistoryModel";
import {createInputHandler} from "../../utilities/componentFunctions";
import {datasetType, details} from "../../utilities/constants";
import {useFilterDropdownClearHandler, useFilterDropdownHandler} from "../common/Dropdown/helpers";
import {getKeys, getValues} from "../../utilities/helperFunctions";
import DataRepositoryModel from "../../models/data/DataRepositoryModel";
import {Button} from "../common/Button/Button";
import {UserSelectableItem} from "../common/SelectableItem/SelectableItem";

function UploadMonitorFilterHeader() {
    const {t} = useTranslation(['aria', 'common']);
    const dispatch = useDispatch();

    const isLoading = useSelector(state => !state.hasLoaded[details.FILE_UPLOAD_HISTORY]);
    const {
        isPollingActive,
        searchText,
        fromDate,
        toDate,
        usernames,
        clientIds,
        matterIds,
        datasetIds,
        dataRepositoryIds
    } = useSelector(state => state.componentStates.uploadMonitorDisplay);

    const updateState = useCallback(updates => {
        dispatch(FileUploadHistoryModel.componentActionCreators.updateDisplay(updates));
    }, []);

    const updateStateThenQuery = useCallback(updates => {
        dispatch(FileUploadHistoryModel.componentActionCreators.updateDisplay(updates));
        if (isPollingActive) {
            dispatch(FileUploadHistoryModel.actionCreators.queryPastAndActiveUploads())
        }
    }, [isPollingActive]);

    const inputHandler = createInputHandler({
        updateState
    });

    const onToggle = useFilterDropdownHandler({
        updateState: updateStateThenQuery
    });

    const onClear = useFilterDropdownClearHandler({
        updateState: updateStateThenQuery
    });

    function onToggleRefresh() {
        updateState(prev => ({isPollingActive: !prev.isPollingActive}));
    }

    function onCopy() {
        dispatch(FileUploadHistoryModel.actionCreators.copyUploadMonitorTables());
    }

    // Toggle polling past and active uploads
    useEffect(() => {
      if (isPollingActive) {
          dispatch(FileUploadHistoryModel.actionCreators.startPollingPastAndActiveUploads());
      } else {
          dispatch(FileUploadHistoryModel.actionCreators.stopPollingPastAndActiveUploads());
      }
    }, [isPollingActive]);

    return (
        <>
            <div className="header-section left">
                <div className="header-item">
                    <label htmlFor="fromDate" className="label" style={{marginRight: '0.5rem'}}>
                        {t('common:label.from')}:
                    </label>
                    <HTMLTextInput id="fromDate" name={'fromDate'} value={fromDate} onChange={inputHandler}
                        type={'date'} isDisabled={isLoading}
                    />
                </div>

                <div className="header-item">
                    <label htmlFor="toDate" className="label" style={{marginRight: '0.5rem'}}>
                        {t('common:label.to')}:
                    </label>
                    <HTMLTextInput id="toDate" name={'toDate'} value={toDate} onChange={inputHandler}
                        type={'date'} isDisabled={isLoading}
                    />
                </div>

                <div className="header-item">
                    <UploadUsersCheckedDropdown usernames={usernames} isDisabled={isLoading}
                        onToggle={onToggle} onClear={onClear}
                    />
                </div>

                <div className="header-item">
                    <ClientCheckedDropdown clientIds={clientIds} isDisabled={isLoading}
                        onToggle={onToggle} onClear={onClear}
                    />
                </div>

                <div className="header-item">
                    <MatterCheckedDropdown matterIds={matterIds} clientIds={clientIds} isDisabled={isLoading}
                        querySettings updateState={updateState} onToggle={onToggle} onClear={onClear}
                    />
                </div>

                <div className="header-item">
                    <DatasetCheckedDropdown type={datasetType.MANAGED} datasetIds={datasetIds} matterIds={matterIds}
                        updateState={updateState} onToggle={onToggle} onClear={onClear} isDisabled={isLoading}
                    />
                </div>

                <div className="header-item">
                    <DataRepositoryCheckedDropdown type={datasetType.MANAGED} dataRepositoryIds={dataRepositoryIds}
                        onToggle={onToggle} onClear={onClear} isDisabled={isLoading}
                    />
                </div>

                <div className="header-item">
                    <Button label={t(`uploadMonitor:label.${isPollingActive ? 'disablePolling' : 'enablePolling'}`)} isDisabled={isLoading}
                        onClick={onToggleRefresh}
                    />
                </div>

                <div className="header-item">
                    <Button label={t('common:label.copy')} isDisabled={isLoading}
                        onClick={onCopy}
                    />
                </div>
            </div>

            <div className="header-section right">
                <div className="header-item">
                    <SearchBar value={searchText} onChange={inputHandler} isDisabled={isLoading}/>
                </div>
            </div>
        </>
    )
}

function UploadUsersCheckedDropdown(props) {
    const {t} = useTranslation(['job']);

    const {
        users,
        usernames,
        ...rest
    } = props;


    const userItems = useSelector(useCallback(state => {
        const users = new Set();
        for (const info of state.uploadInfoDetailsMap.values()) {
            users.add(info.ownerKey);
        }
        for (const uploadHistory of state.fileUploadHistoryDetailsMap.values()) {
            users.add(uploadHistory.username);
        }

        return [...users].map(username => ({
            name: username,
            value: username,
            isChecked: !!usernames[username]
        }));
    }, [usernames]));

    return (
        <CheckedDropdown name={'usernames'} noneSelectedMessage={t('job:option.allUsers')}
            aria-label={t('aria:hiddenAssistText.uploadUsers')} items={userItems}
            ItemComponent={UserSelectableItem} {...rest}
        />
    )
}

function DataRepositoryCheckedDropdown(props) {
    const {t} = useTranslation(['aria', 'dataset']);
    const dispatch = useDispatch();

    const {
        type,
        name='dataRepositoryIds',
        dataRepositoryIds,
        ...rest
    } = props;

    const hasLoaded = useSelector(state => state.hasLoaded[details.DATA_REPOSITORIES]);
    // Query if not loaded
    useEffect(() => {
        if (!hasLoaded) {
            dispatch(DataRepositoryModel.actionCreators.queryDetails());
        }
    }, [dispatch, hasLoaded]);

    const dataRepositoryItems = useSelector(useCallback(state => {
        return getValues(state.dataRepositoryDetailsMap)
            .filter(repo => !type || repo.type === type)
            .map(repo => ({
                name: repo.name,
                value: repo.id,
                isChecked: !!dataRepositoryIds[repo.id]
            }));
    }, [type, dataRepositoryIds]));

    return (
        <CheckedDropdown name={name} aria-label={t('aria:hiddenAssistText.dataRepositoryChecked')} noneSelectedMessage={t('dataRepository:option.allDataRepositories')}
            items={dataRepositoryItems} {...rest}
        />
    )
}

function DatasetCheckedDropdown(props) {
    const {t} = useTranslation(['aria', 'dataset']);

    const {
        type,
        name='datasetIds',
        datasetIds,
        matterIds,
        updateState,
        ...rest
    } = props;

    const datasetDetailsMap = useSelector(state => state.datasetDetailsMap);
    // Filter legalHolds on matterIds change
    useEffect(() => {
        updateState(prev => ({
            [name]: getKeys(prev[name])
                .reduce((acc, id) => {
                    const {matterId} = datasetDetailsMap.get(id) || {};
                    // If matter is still checked, maintain prev selection
                    if (matterIds[matterId]) {
                        acc[id] = prev[name][id];
                    }
                    return acc;
                }, {})
        }));
    }, [name, matterIds, updateState, datasetDetailsMap]);

    const datasetItems = useSelector(useCallback(state => {
        return getValues(state.datasetDetailsMap)
            .filter(dataset => matterIds[dataset.matterId] && (!type || dataset.type === type))
            .map(dataset => ({
                name: dataset.name,
                value: dataset.id,
                isChecked: !!datasetIds[dataset.id]
            }));
    }, [type, matterIds, datasetIds]));

    return (
        <CheckedDropdown name={name} aria-label={t('aria:hiddenAssistText.datasetChecked')} noneSelectedMessage={t('dataset:option.allDatasets')}
            items={datasetItems} {...rest}
        />
    )
}

export default UploadMonitorFilterHeader;