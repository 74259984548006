import React, {useCallback, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {details} from "../../utilities/constants";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {
    getObjectText,
    getValues,
    isNotEmptyNorFalsy,
    textContainsEverySearchWord
} from "../../utilities/helperFunctions";
import UserNoticeModel from "../../models/notice/UserNoticeModel";
import {TableLabelHeader} from "../common/CustomTable/CustomTable";
import LegalHoldNoticeFilterHeader from "./LegalHoldNoticeFilterHeader";
import UserNoticeRow, {selectNamesFromUserNotice} from "../notice/UserNoticeRow";
import UserNoticeTablet from "./UserNoticeTablet";
import EditModel from "../../models/scheduler/EditModel";
import {put} from "redux-saga/effects";
import {contextCall} from "../../saga/sagaFunctions";
import {NoticeCommentSaga} from "../../models/notice/NoticeCommentModel";
import {getRenderedHeightOffset, useRenderedItemHeight, useSimpleVirtualRendering} from "../../utilities/hooks";


function selectUserNoticeText(userNotice, state) {
    const names = getValues(selectNamesFromUserNotice(state, userNotice)).filter(v => v).join('');
    return getObjectText(userNotice) + names;
}

function selectFilteredUserNotices(state) {
    const {searchText} = state.componentStates.legalHoldNoticeDisplay

    return getValues(state.userNoticeDetailsMap)
        .filter(userNotice => textContainsEverySearchWord(searchText, selectUserNoticeText(userNotice, state)))
}

function LegalHoldNoticeDisplay() {
    const {t} = useTranslation(['noticeTemplate', 'legalHold']);
    const dispatch = useDispatch();

    const isLoading = useSelector(state => !state.hasLoaded[details.USER_NOTICES]);
    const userNotices = useSelector(selectFilteredUserNotices);

    const containerRef = useRef();
    const listContainerSelector = '.table-row-group';

    const itemHeightRef = useRenderedItemHeight(containerRef, listContainerSelector, 60.2188);
    const heightOffset = getRenderedHeightOffset(containerRef, listContainerSelector, itemHeightRef.current);
    const virtualRenderRows = useSimpleVirtualRendering({
        containerRef,
        itemHeightRef,
        size: userNotices.length,
        heightOffset
    });

    const {
        userNoticeId
    } = useSelector(state => state.componentStates.userNoticeView);

    const showTablet = useCallback(event => {
        const {value} = event.currentTarget.dataset;
        dispatch(UserNoticeModel.actionCreators.showTablet(value));
    }, []);

    const closeTablet = useCallback(() => {
        dispatch(EditModel.actionCreators.callEditBreakingFunc(function* () {
            const updateEffect = put(UserNoticeModel.actionCreators.hideTablet());
            yield contextCall(NoticeCommentSaga, 'callCommentFormDiscardingEffect', updateEffect);
        }));
    }, []);


    return (
        <>
            <section className="mainPage-header">
                <LegalHoldNoticeFilterHeader/>
            </section>

            <section className="mainPage-content" id={'legalHoldNoticesDisplay'}>
                <div ref={containerRef}>
                    <LoadingWrapper isLoading={isLoading}>
                        {isNotEmptyNorFalsy(userNotices) &&
                            <div className="display-table">

                                <div className="table-header-group">
                                    <div className="table-header"/>

                                    <TableLabelHeader id={'noticeTable:state'} label={t('notice:label.state')}/>

                                    <TableLabelHeader id={'noticeTable:user'} label={t('notice:label.user')}/>

                                    <TableLabelHeader id={'noticeTable:legalHold'} label={t('legalHold:label.name')}/>

                                    <TableLabelHeader id={'noticeTable:subject'} label={t('notice:label.subject')}/>

                                    <TableLabelHeader id={'noticeTable:sentDate'} label={t('notice:label.sentDate')}
                                        style={{width: '8rem'}}/>

                                    <TableLabelHeader id={'noticeTable:respondedDate'} label={t('notice:label.respondedDate')}
                                        style={{width: '8rem'}}/>

                                    <TableLabelHeader id={'noticeTable:escalatedDate'} label={t('notice:label.escalatedDate')}
                                        style={{width: '8rem'}}/>
                                </div>

                                <div className="table-row-group">
                                    {virtualRenderRows((index, topOffset) => {
                                        const userNotice = userNotices[index];
                                        if (userNotice == null) {
                                            return null;
                                        }

                                        return (
                                            <UserNoticeRow key={userNotice.id} style={{position: 'relative', top: topOffset}}
                                                userNotice={userNotice} onSelect={showTablet} isActive={userNotice.id === userNoticeId}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                        }
                    </LoadingWrapper>
                </div>
            </section>

            {userNoticeId != null &&
                <UserNoticeTablet userNoticeId={userNoticeId} onClose={closeTablet}/>
            }
        </>
    )
}

export default LegalHoldNoticeDisplay;
