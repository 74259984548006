import React, {useLayoutEffect} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import DataRepositoryModel from "../../models/data/DataRepositoryModel";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {applicationFeatures, datasetType, details} from "../../utilities/constants";
import DataRepositoryForm from "./DataRepositoryForm";
import {getValues, includesSome} from "../../utilities/helperFunctions";
import DataRepositoryTable from "./DataRepositoryTable";
import DataRepositoryTablet from "./DataRepositoryTablet";
import {MainPageContent, MainPageHeader} from "../app/pages/MainPage";


function selectReduxState(state) {
    return {
        isLoading: !state.hasLoaded[details.DATA_REPOSITORIES],
        dataRepositories: state.dataRepositoryDetailsMap,
        canModify: includesSome(state.currentUser.features, [applicationFeatures.ADD_RESOURCE, applicationFeatures.ADD_DATA_REPOSITORY]),
        ...state.componentStates.dataRepositoryDisplay
    }
}

function DataRepositoryDisplay(props) {
    const {t} = useTranslation(['aria', 'dataRepository'])
    const dispatch = useDispatch()

    const {
        ...attr
    } = props;

    const {
        isLoading,
        canModify,
        dataRepositories,
        dataRepositoryId,
        isDataRepositoryFormActive
    } = useSelector(selectReduxState, shallowEqual)

    useLayoutEffect(() => {
        dispatch(DataRepositoryModel.componentActionCreators.setDisplayActive())

        return () => dispatch(DataRepositoryModel.componentActionCreators.setDisplayInactive())
    }, [dispatch]);

    const selectedRepository = dataRepositories.get(dataRepositoryId)

    const repositoriesByType = {};
    for (const repo of getValues(dataRepositories)) {
        if (repositoriesByType[repo.type] == null) {
            repositoriesByType[repo.type] = [];
        }
        repositoriesByType[repo.type].push(repo);
    }

    return (
        <MainPageContent {...attr}
            title={
                <MainPageHeader text={t('dataRepository:page.title')} description={t('dataRepository:page.description')}/>
            }
            list={
                <LoadingWrapper isLoading={isLoading}>
                    <DataRepositoryTable type={datasetType.MANAGED} canModify={canModify}
                        activeRepositoryId={dataRepositoryId} dataRepositories={repositoriesByType[datasetType.MANAGED]}/>

                    <DataRepositoryTable type={datasetType.IN_PLACE} canModify={canModify}
                        activeRepositoryId={dataRepositoryId} dataRepositories={repositoriesByType[datasetType.IN_PLACE]}/>

                    <DataRepositoryTable type={datasetType.AZURE_STORE_ACCOUNT} canModify={canModify}
                        activeRepositoryId={dataRepositoryId} dataRepositories={repositoriesByType[datasetType.AZURE_STORE_ACCOUNT]}/>
                </LoadingWrapper>
            }
        >
            {(selectedRepository != null) &&
            <DataRepositoryTablet {...selectedRepository}/>
            }
            {isDataRepositoryFormActive &&
            <DataRepositoryForm/>
            }
        </MainPageContent>
    )
}


export default DataRepositoryDisplay