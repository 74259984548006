import React from 'react'
import {connect, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {AddButtonHeader} from "../../common/Button/Button";
import {getLocalResourcePoolIds} from '../../../reselect/selectors';
import LocalResourcePoolRow from './LocalResourcePoolRow';
import {applicationFeatures, resourcePoolTypes} from "../../../utilities/constants";
import {includesSome, isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import ResourcePoolModel from "../../../models/settings/ResourcePoolModel";
import {StatusLabel} from "../../common/Common";

function LocalResourcePoolTable(props) {
    const {prerequisites, localResourcePoolIds, showLocalResourcePoolForm, userFeatures} = props;
    const {t} = useTranslation(['aria', 'resourcePool', 'common']);

    const canAddResourcePool = includesSome(userFeatures, [applicationFeatures.ADD_RESOURCE, applicationFeatures.ADD_RESOURCE_POOLS]);
    const canViewRemoteEngines = useSelector(state => state.currentUser.features.includes(applicationFeatures.REMOTE_ENGINES));

    const isNotEmpty = isNotEmptyNorFalsy(localResourcePoolIds);
    if (!canAddResourcePool && !isNotEmpty) {
        return null;
    }

    return (
        <div className="display-item">
            <AddButtonHeader id={'localResourcePoolAddButton'} aria-label={t('aria:hiddenAssistText.addLocalResourcePool')}
                text={t('resourcePool:label.localName')} canModify={canAddResourcePool} onClick={showLocalResourcePoolForm}/>

            {canAddResourcePool && !prerequisites.engine &&
                <StatusLabel message={t('resourcePool:message.noEngines')} style={{margin: '0.5rem 0'}}/>
            }

            {isNotEmpty &&
                <div className="display-table">
                    <div className="table-header-group">
                        <div className="table-header" style={{width: '4rem'}}/>

                        <div className="table-header">
                            <label className="label is-bold">
                                {t('common:label.name')}
                            </label>
                        </div>

                        <div className="table-header">
                            <label className="label is-bold">
                                {t('resourcePool:label.engines')}
                            </label>
                        </div>

                        {canViewRemoteEngines &&
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('resourcePool:label.remoteEngines')}
                            </label>
                        </div>
                        }
                    </div>

                    <div className="table-row-group">
                        {localResourcePoolIds.map(id =>
                            <LocalResourcePoolRow key={id} resourcePoolId={id}/>
                        )}
                    </div>
                </div>
            }
        </div>
    );
}

const mapStateToProps = state => {
    const {engineDetailsMap, currentUser: {features}} = state;

    const prerequisites = {
        engine: isNotEmptyNorFalsy(engineDetailsMap)
    };

    return {
        prerequisites,
        localResourcePoolIds: getLocalResourcePoolIds(state),
        userFeatures: features
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showLocalResourcePoolForm: () => dispatch(ResourcePoolModel.actionCreators.showForm({type: resourcePoolTypes.LOCAL}))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LocalResourcePoolTable);