import React, {useCallback, useRef} from 'react';
import './Checkbox.css';
import {buildClassName, stringToBool} from "../../../utilities/helperFunctions";
import {useGetMissingClasses} from "../AccessibilityAssist";
import {Label} from "../Text/Text";

function Checkbox(props) {
    const {
        label,
        text=label,
        title,

        name,
        value,
        checked=value,
        onClick,
        isEllipsis=true,
        isWordWrap,
        isReadOnly,
        isRequired,
        isInvalid,
        isDisabled,
        isSelected,
        containerStyle,
        containerId,
        labelId,
        ...attr
    } = props;

    const textRef = useRef();
    const labelClick = useCallback(e => {
        // If clicking the label when onClick not defined
        if (e.target === textRef.current) {
            if (typeof onClick !== 'function') {
                e.stopPropagation();
            }
        }
    }, [onClick]);


    const containerClassName = buildClassName(
        'checkbox-container',
        text && 'has-label',
        isSelected && 'is-selected',
        isReadOnly && 'is-readonly',
        isDisabled && 'is-disabled',
        ...useGetMissingClasses(text, {ariaLabel: attr['aria-label'], ariaLabelledBy: attr['aria-labelledby']})
    );

    const id = attr.id || attr.name;

    const input = (
        <input type="checkbox" id={id} name={name} checked={stringToBool(checked)}
            onClick={onClick} disabled={isDisabled || isReadOnly} {...attr} readOnly
        />
    );

    return (
        <span id={containerId} className={containerClassName} style={containerStyle}
            title={text ? null : attr['aria-label']}
        >
            {text ?
                <Label ref={textRef} id={labelId} htmlFor={id}
                    isEllipsis={isEllipsis} isWordWrap={isWordWrap} onClick={labelClick}>

                    {input}
                    {text}
                </Label>
                :
                input
            }
        </span>
    );
}

export default Checkbox;
