import {applyMiddleware, combineReducers, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import ClientModel from "./models/client/ClientModel";
import MatterModel from "./models/client/MatterModel";
import LibraryModel from "./models/library/LibraryModel";
import WorkflowTemplateModel from "./models/library/WorkflowTemplateModel";
import TemplateOperationModel from "./models/library/TemplateOperationModel";
import ParameterModel from "./models/library/ParameterModel";
import JobOperationModel from "./models/job/JobOperationModel";
import ClientPoolFilterModel from "./models/filter/ClientPoolFilterModel";
import JobModel from "./models/job/JobModel";
import AuditLogModel from "./models/generics/AuditLogModel";
import JobExecutionLogModel from "./models/job/JobExecutionLogModel";
import JobWarningsModel from "./models/job/JobWarningsModel";
import JobInfosModel from "./models/job/JobInfosModel";
import JobErrorsModel from "./models/job/JobErrorsModel";
import PopupModel from "./models/scheduler/PopupModel";
import LicenceSourceModel from "./models/settings/LicenceSourceModel";
import ServerModel from "./models/settings/ServerModel";
import EngineModel from "./models/settings/EngineModel";
import ExecutionProfileModel from "./models/settings/ExecutionProfileModel";
import ResourcePoolModel from "./models/settings/ResourcePoolModel";
import ClientPoolModel from "./models/settings/ClientPoolModel";
import PolicyModel from "./models/settings/PolicyModel";
import ReduxStateModel from "./models/scheduler/ReduxStateModel";
import SchedulerModel from "./models/scheduler/SchedulerModel";
import UserSettingsModel from "./models/user/UserSettingsModel";
import EditModel from "./models/scheduler/EditModel";
import CurrentUserModel from "./models/user/CurrentUserModel";
import JobUserModel from "./models/user/JobUserModel";
import UserModel from "./models/user/UserModel";
import ComponentStateModel from "./models/generics/ComponentStateModel";
import JobArchiveModel from "./models/job/JobArchiveModel";
import NotificationRuleModel from "./models/settings/NotificationRuleModel";
import JobScheduleModel from "./models/job/JobScheduleModel";
import JobScheduleRunModel from "./models/job/JobScheduleRunModel";
import AutomateLicenceModel from "./models/scheduler/AutomateLicenceModel";
import DataRepositoryModel from "./models/data/DataRepositoryModel";
import DatasetModel from "./models/data/DatasetModel";
import UploadInfoModel from "./models/data/UploadInfoModel";
import FileInfoModel from "./models/data/FileInfoModel";
import UserServiceModel from "./models/user/UserServiceModel";
import JobQueueFilterModel from "./models/job/JobQueueFilterModel";
import UtilizationModel from "./models/scheduler/UtilizationModel";
import LegalHoldModel from "./models/legalhold/LegalHoldModel";
import NoticeTemplateModel from "./models/notice/NoticeTemplateModel";
import UserNoticeModel from "./models/notice/UserNoticeModel";
import LegalHoldParticipationModel from "./models/legalhold/LegalHoldParticipationModel";
import NoticeCommentModel from "./models/notice/NoticeCommentModel";
import NoticeInfoModel from "./models/notice/NoticeInfoModel";
import SmtpEmailModel from "./models/settings/SmtpEmailModel";
import ApiKeyModel from "./models/settings/ApiKeyModel";
import WebhookModel from "./models/settings/WebhookModel";
import WebhookEventModel from "./models/settings/WebhookEventModel";
import FileLibraryModel from "./models/filelibrary/FileLibraryModel";
import LibraryFileModel from "./models/filelibrary/LibraryFileModel";
import AxiosProxy from "./models/api/AxiosProxy";
import NoticeDatasetEventModel from "./models/legalhold/NoticeDatasetEventModel";
import JobRequiredProfilesModel from "./models/job/JobRequiredProfilesModel";
import FileUploadHistoryModel from "./models/data/FileUploadHistoryModel";
import JobOperationMimeTypeStatsModel from "./models/job/JobOperationMimeTypeStatsModel";
import JobRunningLogModel from "./models/job/JobRunningLogModel";
import {clearAllIcons} from "./models/generics/IconModel";
import JobSoftErrorsModel from "./models/job/JobSoftErrorsModel";
import RelativityProxyModel from "./models/relativity/RelativityProxyModel";
import WorkflowBuilderModel from "./models/workflowbuilder/WorkflowBuilderModel";
import UserCommentModel from "./models/generics/UserCommentModel";
import ThirdPartyServiceModel from "./models/thirdparty/ThirdPartyServiceModel";
import PurviewCache from "./models/guidedjob/PurviewCache";
import GuidedJobModel from "./models/guidedjob/GuidedJobModel";
import TriggerConfigurationEventModel from "./models/legalhold/TriggerConfigurationEventModel";
import JobLinksModel from "./models/job/JobLinksModel";
import VaultCache from "./models/guidedjob/VaultCache";
import UserDataDirModel from "./models/system/UserDataDirModel";
import ServiceObjectCache from "./models/thirdparty/ServiceObjectCache";


export default () => {
    const appReducer = combineReducers({
        schedulerDetails: SchedulerModel.reducer,
        popupDetails: PopupModel.reducer,
        hasLoaded: ReduxStateModel.hasLoadedReducer,
        editDetails: EditModel.reducer,
        currentUser: CurrentUserModel.reducer,
        jobUserDetailsMap: JobUserModel.reducer,
        userDetailsMap: UserModel.reducer,
        userSettingsMap: UserSettingsModel.reducer,
        userServiceDetailsMap: UserServiceModel.reducer,
        jobQueueFilter: JobQueueFilterModel.reducer,
        jobDetailsMap: JobModel.reducer,
        jobScheduleDetailsMap: JobScheduleModel.reducer,
        jobArchiveMap: JobArchiveModel.reducer,
        jobOperationsMap: JobOperationModel.reducer,
        jobExecutionLog: JobExecutionLogModel.reducer,
        jobOperationMimeTypeStats: JobOperationMimeTypeStatsModel.reducer,
        jobRunningLog: JobRunningLogModel.reducer,
        jobWarningsMap: JobWarningsModel.reducer,
        jobSoftErrorsMap: JobSoftErrorsModel.reducer,
        jobInfosMap: JobInfosModel.reducer,
        jobLinksMap: JobLinksModel.reducer,
        jobErrorsMap: JobErrorsModel.reducer,
        jobRequiredProfilesMap: JobRequiredProfilesModel.reducer,
        auditLogMap: AuditLogModel.reducer,
        jobScheduleRunsMap: JobScheduleRunModel.reducer,
        clientDetailsMap: ClientModel.reducer,
        matterDetailsMap: MatterModel.reducer,
        libraryDetailsMap: LibraryModel.reducer,
        workflowTemplateDetailsMap: WorkflowTemplateModel.reducer,
        templateOperationsMap: TemplateOperationModel.reducer,
        parametersMap: ParameterModel.reducer,
        nuixLicenceSourceDetailsMap: LicenceSourceModel.reducer,
        serverDetailsMap: ServerModel.reducer,
        engineDetailsMap: EngineModel.reducer,
        executionProfileDetailsMap: ExecutionProfileModel.reducer,
        resourcePoolDetailsMap: ResourcePoolModel.reducer,
        clientPoolDetailsMap: ClientPoolModel.reducer,
        notificationRuleDetailsMap: NotificationRuleModel.reducer,
        dataRepositoryDetailsMap: DataRepositoryModel.reducer,
        datasetDetailsMap: DatasetModel.reducer,
        fileInfoRowsMap: FileInfoModel.reducer,
        uploadInfoDetailsMap: UploadInfoModel.reducer,
        fileUploadHistoryDetailsMap: FileUploadHistoryModel.reducer,
        legalHoldDetailsMap: LegalHoldModel.reducer,
        noticeTemplateDetailsMap: NoticeTemplateModel.reducer,
        apiKeyDetailsMap: ApiKeyModel.reducer,
        webhookDetailsMap: WebhookModel.reducer,
        webhookEventDetailsMap: WebhookEventModel.reducer,
        smtpEmailDetailsMap: SmtpEmailModel.reducer,
        userNoticeDetailsMap: UserNoticeModel.reducer,
        noticeCommentDetailsMap: NoticeCommentModel.reducer,
        legalHoldParticipationDetailsMap: LegalHoldParticipationModel.reducer,
        triggerConfigurationEventDetailsMap: TriggerConfigurationEventModel.reducer,
        noticeDatasetEventDetailsMap: NoticeDatasetEventModel.reducer,
        noticeInfoDetailsMap: NoticeInfoModel.reducer,
        policyDetailsMap: PolicyModel.reducer,
        clientPoolFilter: ClientPoolFilterModel.reducer,
        componentStates: ComponentStateModel.reducer,
        automateLicence: AutomateLicenceModel.reducer,
        userDataDir: UserDataDirModel.reducer,
        relativityProxy: RelativityProxyModel.reducer,
        utilizationStatistics: UtilizationModel.reducer,
        fileLibraryDetailsMap: FileLibraryModel.reducer,
        libraryFileDetailsMap: LibraryFileModel.reducer,
        thirdPartyServiceDetailsMap: ThirdPartyServiceModel.reducer,
        workflowBuilder: WorkflowBuilderModel.reducer,
        guidedJobForm: GuidedJobModel.reducer,
        purviewCache: PurviewCache.reducer,
        vaultCache: VaultCache.reducer,
        serviceObjectCache: ServiceObjectCache.reducer,
        objectUserComments: UserCommentModel.reducer
    });

    const rootReducer = (state, action) => {
        //Take out and reassign to the state those that should not be reset (like schedulerDetails)
        if (action.type === ReduxStateModel.actions.CLEAR_REDUX) {
            const {schedulerDetails, componentStates: {app, loginPage: {authMethod}}, popupDetails} = state;
            const {authMethods, isServerScheduler, isDisconnected, brandingLogo, configuration} = schedulerDetails;

            AxiosProxy.etags = {};
            clearAllIcons();
            UserSettingsModel.updateAccessibilityStyling({});
            UserSettingsModel.updateAxiosInstanceSettings({});

            state = {
                schedulerDetails: {
                    ...new SchedulerModel(),
                    authMethods,
                    isServerScheduler,
                    isDisconnected,
                    brandingLogo,
                    configuration,
                    resourcesStatus: {}
                },
                componentStates: {
                    ...ComponentStateModel.initialState,
                    app,
                    loginPage: {
                        ...ComponentStateModel.initialState.loginPage,
                        authMethod,
                    }
                },
                popupDetails
            };
        }

        return appReducer(state, action);
    };

    const sagaMiddleware = createSagaMiddleware();

    return {
        ...createStore(rootReducer, applyMiddleware(sagaMiddleware)),
        runSaga: sagaMiddleware.run
    };
}
