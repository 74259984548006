import React, {useRef, useState} from 'react';
import './JobCardDesigner.css';
import {useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import CustomizableCard from "../../common/CustomizableCard/CustomizableCard";
import ToggleSwitch from "../../common/ToggleSwitch/ToggleSwitch";
import {buildCardItems, mockValues} from "./helpers";
import {
    getDataNameFromParentNode,
    getEntries,
    getKeys,
    getValues,
    isNotEmptyNorFalsy,
    objectTruthyValues,
    stringToBool
} from "../../../utilities/helperFunctions";
import {ListDropdown} from "../../common/Dropdown/Dropdown";
import {jobCardOptionKeys, positionKeys, sizeKeys} from "../../../i18next/keys";
import {initialSelectedState, useClearSelectedEffect, useValueSelectHandler} from "../../../utilities/hooks";
import {AddRemoveButtons} from "../../common/Button/Button";
import {jobDesignerOptionsBuilder} from "../../../models/user/initialUserSettings";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import ParameterModel from "../../../models/library/ParameterModel";
import {userIconModel} from "../../../models/generics/IconModel";
import {TableRow} from "../../common/CustomTable/CustomTable";
import UserModel from "../../../models/user/UserModel";
import {positionIcon} from "../../../utilities/iconResolver";
import {IconSelectableItem} from "../../common/SelectableItem/SelectableItem";


function JobCardDesigner(props) {
    const {t} = useTranslation(['jobCardDesigner']);

    const {
        setting: jobCardOptions,
        updateSetting
    } = props;

    const containerRef = useRef()
    const [selected, setSelected] = useState(initialSelectedState)

    const selectHandler = useValueSelectHandler({setSelected})
    useClearSelectedEffect({containerRef, setSelected})

    const currentUserName = useSelector(state => state.currentUser.name);
    const [username, userIconName] = UserModel.useUserNameAndIconName(currentUserName);
    const userIconUrl = userIconModel.useIcon(userIconName);

    function addJobCardSetting() {
        let index = 0
        for (const _key of getKeys(jobCardOptions)) {

            if (index === parseInt(_key))
                index++
        }

        updateSetting(index, jobDesignerOptionsBuilder(Date.now(), null, positionKeys.NOT_VISIBLE, sizeKeys.SMALL, false));
    }

    function removeJobCardSettings() {
        const selectedIndices = objectTruthyValues(selected.values)

        for (const index of selectedIndices) {
            props.updateSetting(index, null)
        }

        setSelected(initialSelectedState)
    }

    function onDropdownItemClick(event) {
        const {dataset: {value}, parentNode} = event.currentTarget;
        const name = getDataNameFromParentNode(parentNode);

        const updates = buildUpdates(parentNode, value);

        if (name === 'position' && ![positionKeys.MIDDLE, positionKeys.NOT_VISIBLE].includes(value)) {
            //Check if updates[key] == null so not to find the item which started the updated
            const setting = getKeys(jobCardOptions).find(key => jobCardOptions[key] && jobCardOptions[key].position === value && updates[key] == null);
            if (setting && updates[setting] == null) {
                updates[setting] = {
                    position: positionKeys.MIDDLE
                };
            }
        }

        saveUpdates(updates);
    }

    function onInputChange(event) {
        const node = event.target;
        const value = event.target.value;
        const updates = buildUpdates(node, value);

        saveUpdates(updates);
    }

    function onParameterNameBlur(event) {
        const node = event.target;
        const value = ParameterModel.normalizeParameterName(event.target.value);
        const updates = buildUpdates(node, value);

        saveUpdates(updates);
    }

    function onToggleEnable(event) {
        const node = event.currentTarget;
        const value = stringToBool(node.dataset.checked);
        const updates = buildUpdates(node, value);

        saveUpdates(updates);
    }

    function saveUpdates(updates) {
        for (const setting of Object.getOwnPropertyNames(updates)) {
            const value = {
                ...jobCardOptions[setting],
                ...updates[setting],
                time: Date.now()
            };

            updateSetting(setting, value)
        }
    }

    function buildUpdates(node, value) {
        let updates = value;
        let parent = node;
        while (parent) {
            const {name} = parent.dataset || {};
            if (name) {
                updates = {[name]: updates};
            }

            parent = parent.parentNode;
        }

        return updates;
    }

    const parameterNames = getValues(jobCardOptions)
        .filter(opt => opt && opt.type === jobCardOptionKeys.PARAMETER)
        .map(opt => opt.value);

    const jobCardItems = buildCardItems(t, jobCardOptions, {
        ...mockValues(t, username, parameterNames),
        userIconUrl: <img className="round-image" src={userIconUrl} alt={t(`aria:hiddenAssistText.userIcon`)}/>
    });

    return (
        <div className="display-input" ref={containerRef}>

            <AddRemoveButtons onAddClick={addJobCardSetting} onRemoveClick={removeJobCardSettings}
                ariaLabelKey={'JobCardOption'} isRemoveDisabled={objectTruthyValues(selected.values).length < 1}
            />

            <div className="job-card-designer">
                <DesignerSettingsTable jobCardOptions={jobCardOptions} onDropdownItemClick={onDropdownItemClick} onParameterNameBlur={onParameterNameBlur}
                    selected={selected} selectHandler={selectHandler} onToggleEnable={onToggleEnable} onInputChange={onInputChange}/>

                <div className="sample-job-card">
                    <label className="label is-bold" style={{textAlign: 'center', padding: '0.5rem 1rem'}}>
                        {t('jobCardDesigner:label.sampleJobCard')}
                    </label>
                    <CustomizableCard
                        topRight={jobCardItems[positionKeys.TOP_RIGHT]}
                        topLeft={jobCardItems[positionKeys.TOP_LEFT]}
                        middle={jobCardItems[positionKeys.MIDDLE]}
                        bottomRight={jobCardItems[positionKeys.BOTTOM_RIGHT]}
                        bottomLeft={jobCardItems[positionKeys.BOTTOM_LEFT]}
                    />
                </div>
            </div>
        </div>
    );
}

function DesignerSettingsTable(props) {
    const {jobCardOptions, selected, selectHandler, onDropdownItemClick, onToggleEnable, onInputChange, onParameterNameBlur} = props;
    const {t} = useTranslation(['jobCardDesigner', 'common']);

    return (
        <table>
            <thead className="table-header-group">
                <tr>
                    <th className="table-header" style={{width: '233px'}}>
                        <label className="label is-bold">
                            {t('common:label.type')}
                        </label>
                    </th>

                    <th className="table-header">
                        <label className="label is-bold">
                            {t('jobCardDesigner:label.position')}
                        </label>
                    </th>

                    <th className="table-header">
                        <label className="label is-bold">
                            {t('jobCardDesigner:label.size')}
                        </label>
                    </th>

                    <th className="table-header">
                        <label className="label is-bold">
                            {t('common:label.bold')}
                        </label>
                    </th>

                    <th className="table-header">
                        <label className="label is-bold">
                            {t('common:label.italic')}
                        </label>
                    </th>
                </tr>
            </thead>

            <tbody className="table-row-group">
                {getEntries(jobCardOptions).filter(([ignore, _option]) => isNotEmptyNorFalsy(_option)).map(([index, _option]) =>
                    <DesignerSettingsRow {..._option} key={index} index={index} isActive={selected.values[index]} inputHandler={onInputChange}
                        onClick={selectHandler} onDropdownItemClick={onDropdownItemClick} onToggleEnable={onToggleEnable} onParameterNameBlur={onParameterNameBlur}/>
                )}
            </tbody>
        </table>
    )
}

function DesignerSettingsRow(props) {
    const {index, type, value, position, size, isActive, isBold, isItalic, onClick, onDropdownItemClick, onToggleEnable, inputHandler, onParameterNameBlur} = props;
    const {t} = useTranslation(['jobCardDesigner']);

    return (
        <TableRow Tag="tr" data-index={index} data-name={index} onClick={onClick}>
            <td className="table-cell">
                <JobCardOptionTypeDropdown selectedType={type} onTypeSelect={onDropdownItemClick}/>

                {type === jobCardOptionKeys.PARAMETER &&
                <HTMLTextInput label={t('jobCardDesigner:label.parameterName')} data-name={'value'} value={value} onChange={inputHandler}
                    onBlur={onParameterNameBlur} isRequired/>
                }
            </td>

            <td className="table-cell">
                <PositionDropdown selectedPosition={position} onPositionSelect={onDropdownItemClick}/>
            </td>

            <td className="table-cell" style={{minWidth: '10rem', width: '10rem'}}>
                <SizeDropdown selectedSize={size} onSizeSelect={onDropdownItemClick}/>
            </td>

            <td className="table-cell" style={{width: '8rem'}}>
                <ToggleSwitch aria-label={t('common:label.bold')} name={'isBold'}
                    checked={isBold} onClick={onToggleEnable}/>
            </td>

            <td className="table-cell" style={{width: '8rem'}}>
                <ToggleSwitch aria-label={t('common:label.italic')} name={'isItalic'}
                    checked={isItalic} onClick={onToggleEnable}/>
            </td>
        </TableRow>
    )
}

function JobCardOptionTypeDropdown(props) {
    const {selectedType, onTypeSelect, ...rest} = props;
    const {t} = useTranslation(['aria', 'jobCardDesigner']);

    const policyScopeTypeItems = getValues(jobCardOptionKeys)
        .map(_optionType => ({
            name: t(`jobCardDesigner:optionName.${_optionType}`),
            value: _optionType
        }));

    return (
        <ListDropdown name={'type'}
            aria-label={t('aria:hiddenAssistText.jobCardType')} noneSelectedMessage={t('jobCardDesigner:label.selectType')}
            value={selectedType} items={policyScopeTypeItems} onItemSelect={onTypeSelect}
            {...rest}
        />
    );
}

function PositionDropdown(props) {
    const {selectedPosition = positionKeys.BOTTOM_LEFT, onPositionSelect, isDisabled, ...rest} = props;
    const {t} = useTranslation(['aria', 'jobCardDesigner']);

    const positionItems = getValues(positionKeys)
        .map(_position => {
            const item = {
                name: t(`jobCardDesigner:position.${_position}`),
                value: _position
            }

            const icon = positionIcon(_position);
            if (icon != null) {
                item.icon = <img src={icon} alt={t(`aria:hiddenAssistText.position${_position}Icon`)}/>
            }
            return item;
        });

    const icon = positionIcon(selectedPosition);
    return (
        <ListDropdown name={'position'}
            aria-label={t('aria:hiddenAssistText.position')} noneSelectedMessage={t('policy:scopeTypeOption.SELECT')}
            value={selectedPosition} items={positionItems} onItemSelect={onPositionSelect}
            ItemComponent={IconSelectableItem}
            icon={icon &&
                <span className="icon is-small">
                    <img src={icon} alt={t(`aria:hiddenAssistText.position${selectedPosition}Icon`)}/>
                </span>
            }
            {...rest}
        />
    );
}

function SizeDropdown(props) {
    const {selectedSize=sizeKeys.SMALL, onSizeSelect, ...rest} = props;
    const {t} = useTranslation(['aria', 'jobCardDesigner']);

    const sizeItems = getValues(sizeKeys)
        .map(_size => ({
            name: t(`jobCardDesigner:size.${_size}`),
            value: _size
        }));

    return (
        <ListDropdown name={'size'}
            aria-label={t('aria:hiddenAssistText.size')} value={selectedSize}
            items={sizeItems} onItemSelect={onSizeSelect}
            {...rest}
        />
    );
}

export default JobCardDesigner;
