import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    baseFolderLocation: "C:\\Cases"
}

const useCaseSlice = createSlice({
    name: "useCaseSlice",
    initialState,
    reducers: {
        updateBaseFolderLocation (state, action) {
            const {baseFolderLocation} = action.payload;
            state.baseFolderLocation = baseFolderLocation;
            return state;
        },
    }
});

export const {updateBaseFolderLocation} = useCaseSlice.actions;
export default useCaseSlice.reducer;