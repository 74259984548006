import {useTranslation} from "react-i18next";
import {createToggleHandler} from "../../common/Checkbox/helpers";
import Checkbox from "../../common/Checkbox/Checkbox";
import ToggleSwitch from "../../common/ToggleSwitch/ToggleSwitch";
import React, {useCallback, useEffect, useRef, useState} from "react";
import Text from "../../common/Text/Text";
import {
    LegalHoldTriggerConfigurationTriggerDropdown,
    ListDropdown,
    WorkflowLibraryDropdown,
    WorkflowTemplateDropdown
} from "../../common/Dropdown/Dropdown";
import {formElementTypes, legalHoldTriggerConfiguration} from "../../../utilities/constants";
import {buildFakeEvent, getValues, isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import {useDispatch} from "react-redux";
import {LibraryApi} from "../../../models/library/LibraryModel";
import SchedulerModel from "../../../models/scheduler/SchedulerModel";
import ParameterModel from "../../../models/library/ParameterModel";
import {WorkflowTemplateApi} from "../../../models/library/WorkflowTemplateModel";
import {useAsyncEffect, useUpdateEffect} from "../../../utilities/hooks";

function TriggerConfigurationTable(props) {
    const {t} = useTranslation(['legalHold', 'common'])
    const dispatch = useDispatch();

    const {
        id = '',
        index,
        configuration,
        noticeTemplateItems,
        surveyFormOptions,
        lastAddedIndex,

        triggerResponsesHandler,
        inputHandler,
        toggleHandler,
        dropdownHandler,
        noticeConfigurationTemplateDropdownHandler,
        noticeQuestionDropdownHandler,

        state,
        isSilent,
        isSelected,
        isDisabled,
        ...attr
    } = props;

    const {
        enabled,
        workflowTemplateId,
        workflowLibraryId,
        trigger,

        noticeTemplateIdAndVersion,
        optionKey,
        triggerResponses,
        triggered
    } = configuration;

    // Legal hold parameter dropdown....
    const requiredParameterTypes = [ParameterModel.Type.LEGAL_HOLD];
    const [parameterLibraryIds, setParameterLibraryIds] = useState(new Set());
    const [parameterWorkflowIds, setParameterWorkflowIds] = useState(new Set());
    const [isLoadingLibraries, setIsLoadingLibraries] = useState(true);
    const [isLoadingWorkflows, setIsLoadingWorkflows] = useState(true);


    const ref = useRef();
    useEffect(() => {
        if (lastAddedIndex != null) {
            ref.current?.focus();
        }
    }, [lastAddedIndex]);

    useUpdateEffect(() => {
        dropdownHandler(buildFakeEvent({name: "workflowTemplateId", value: null, index}))
    }, [workflowLibraryId])

    useAsyncEffect(async () => {
        try {
            setIsLoadingLibraries(true);
            const {data} = await LibraryApi.getDetails(false, requiredParameterTypes);
            setParameterLibraryIds(new Set(data.map(library => library.id)));
        } catch (error) {
            dispatch(SchedulerModel.actionCreators.handleResponseError(error));
        } finally {
            setIsLoadingLibraries(false);
        }
    }, []);

    useAsyncEffect(async () => {
        if (workflowLibraryId == null) {
            return;
        }

        try {
            setIsLoadingWorkflows(true);
            const {data} = await WorkflowTemplateApi.getDetails(workflowLibraryId, false, requiredParameterTypes);
            setParameterWorkflowIds(new Set(data.map(template => template.id)));
        } catch (error) {
            dispatch(SchedulerModel.actionCreators.handleResponseError(error));
        } finally {
            setIsLoadingWorkflows(false);
        }
    }, [workflowLibraryId]);

    const libraryFilter = useCallback(library => {
        return parameterLibraryIds.has(library.id);
    }, [parameterLibraryIds]);

    const workflowFilter = useCallback(template => {
        return parameterWorkflowIds.has(template.id);
    }, [parameterWorkflowIds]);

    const triggerResponsesToggleHandler = createToggleHandler({
        handler: _triggerResponseHandler
    });

    function _triggerResponseHandler(update, event) {
        triggerResponsesHandler({
            ...triggerResponses,
            ...update
        }, event);
    }

    const isTableDisabled = !enabled || isDisabled;
    const isConfigurationDisabled = isTableDisabled || triggered;
    const selected = isSelected ? 'is-selected' : '';

    return (
        <form className={selected} id={id} style={{display: 'flex'}}
            ref={ref} tabIndex={0} {...attr}>

            <div className="display-table" style={{height: '0', flex: '0'}}>
                <div className="table-row-group initial-bg-color">

                    <div className="table-row no-hover">
                        <div className="table-cell no-stretch">
                            <ToggleSwitch aria-label={t(`aria:hiddenAssistText.legalHoldTriggerRow`)} name={'enabled'} checked={enabled}
                                onClick={toggleHandler} isDisabled={isDisabled}/>
                        </div>
                        <div className="table-cell no-stretch">
                            <Text value={`${t(`legalHold:label.trigger`)}:`} isDisabled={isTableDisabled}/>
                        </div>

                        <div className="table-cell">
                            <LegalHoldTriggerConfigurationTriggerDropdown name={'trigger'} value={trigger} onItemSelect={dropdownHandler}
                                isSilent={isSilent} isRequired={enabled} state={state} isDisabled={isConfigurationDisabled}/>
                        </div>

                    </div>

                    <div className="table-row no-hover">
                        <div className="table-cell no-stretch"/>
                        <div className="table-cell no-stretch">
                            <Text value={`${t(`legalHold:label.workflowLibrary`)}:`} isDisabled={isTableDisabled}/>
                        </div>

                        <div className="table-cell">
                            <WorkflowLibraryDropdown name={'workflowLibraryId'} value={workflowLibraryId} onItemSelect={dropdownHandler}
                                                      isSilent={isSilent} isRequired={enabled} isDisabled={isConfigurationDisabled || isLoadingLibraries}
                                                      libraryFilter={libraryFilter}
                            />
                        </div>
                    </div>

                    <div className="table-row no-hover">
                        <div className="table-cell no-stretch"/>
                        <div className="table-cell no-stretch">
                            <Text value={`${t(`legalHold:label.workflow`)}:`} isDisabled={isTableDisabled}/>
                        </div>

                        <div className="table-cell">
                            <WorkflowTemplateDropdown name={'workflowTemplateId'} value={workflowTemplateId} workflowLibraryId={workflowLibraryId} onItemSelect={dropdownHandler}
                                                      isSilent={isSilent} isRequired={enabled} isDisabled={isConfigurationDisabled || isLoadingWorkflows} workflowFilter={workflowFilter}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {trigger === legalHoldTriggerConfiguration.ON_CUSTODIAN_RESPONSE &&
                <div style={{marginLeft: '2rem', flex: '1'}}>
                    <div className="display-table">
                        <div className="table-row-group">
                            <div className="table-row no-hover">
                                <div className="table-cell no-stretch">
                                    <Text value={`${t(`legalHold:label.noticeTemplate`)}:`}
                                           isDisabled={isTableDisabled}/>
                                </div>

                                <div className="table-cell">
                                    <NoticeConfigurationTemplateDropdown value={noticeTemplateIdAndVersion}
                                                                         items={noticeTemplateItems} onItemSelect={noticeConfigurationTemplateDropdownHandler}
                                                                         isRequired={enabled} isDisabled={isConfigurationDisabled}
                                    />
                                </div>
                            </div>

                            <div className="table-row no-hover">
                                <div className="table-cell no-stretch">
                                    <Text value={`${t(`legalHold:label.noticeQuestion`)}:`}
                                           isDisabled={isTableDisabled}/>
                                </div>

                                <div className="table-cell">
                                    <NoticeQuestionDropdown name={'optionKey'} value={optionKey} onItemSelect={noticeQuestionDropdownHandler}
                                                            surveyFormOptions={getValues(surveyFormOptions)} isRequired={enabled} isDisabled={isConfigurationDisabled}
                                    />
                                </div>
                            </div>

                            {surveyFormOptions && surveyFormOptions[optionKey] &&
                                <div className="table-row no-hover">
                                    <div className="table-cell no-stretch">
                                        <Text value={`${t(`legalHold:label.triggerResponses`)}:`}
                                               isDisabled={isTableDisabled}/>
                                    </div>

                                    <div className="table-cell">
                                        <SurveyFormOptionTriggerResponses option={surveyFormOptions[optionKey]} triggerResponses={triggerResponses}
                                                                          toggleHandler={triggerResponsesToggleHandler} isRequired={!isNotEmptyNorFalsy(triggerResponses)} isDisabled={isConfigurationDisabled}/>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </form>
    )
}

export function SurveyFormOptionTriggerResponses(props) {
    const {t} = useTranslation(['common']);
    const {option, triggerResponses, toggleHandler, isRequired, isReadOnly, isDisabled} = props;

    if (option == null) {
        return null;
    }

    let responses;
    switch (option.type) {
        case formElementTypes.CHECKBOX:
            responses = [{name: t('common:option.checked'), value: 'true'}, {name: t('common:option.unchecked'), value: 'false'}];
            break;
        case formElementTypes.DROPDOWN:
            responses = option.allowedValues.map(value => ({name: value, value}));
            break;
    }

    const required = isRequired ? ' is-required' : '';

    return (
        <div className={required} style={{display: 'flex', flexDirection: 'column', width: 'fit-content', border: '1px solid transparent'}}>
            {responses.map((response, r_index) =>
                <Checkbox name={response.value} label={response.name} onClick={toggleHandler} key={r_index} containerStyle={{padding: '0 0.5rem'}}
                          checked={triggerResponses && triggerResponses[response.value]} isWordWrap isReadOnly={isReadOnly} isDisabled={isDisabled}/>
            )}
        </div>
    )
}

function NoticeConfigurationTemplateDropdown(props) {
    const {t} = useTranslation(['aria', 'noticeTemplate']);

    return (
        <ListDropdown id={'noticeConfigurationTemplateDropdown'} name={'noticeTemplateIdAndVersion'}
            aria-label={t(`aria:hiddenAssistText.noticeTemplate`)} noneSelectedMessage={t(`noticeTemplate:option.selectNoticeTemplate`)}
            {...props}
        />
    );
}

export function NoticeQuestionDropdown(props) {
    const {surveyFormOptions, ...attr} = props;
    const {t} = useTranslation(['aria']);

    const optionsDigits = Math.floor(Math.log10(surveyFormOptions.length) + 1);

    const optionItems = surveyFormOptions
        .map((option, index) => {

            const number = index + 1;
            const numberDigits = Math.floor(Math.log10(number) + 1);
            const spacingNeeded = optionsDigits - numberDigits;

            return {
                name: `${number}.${'\u2007'.repeat(spacingNeeded)} ${(option.description || option.label)}`,
                value: option.key
            }
        });

    return (
        <ListDropdown id={'noticeQuestionDropdown'} name={'noticeOptionKey'} aria-label={t(`aria:hiddenAssistText.noticeQuestion`)}
            buttonStyle={{maxWidth: '20rem'}} noneSelectedMessage={t(`legalHold:option.selectNoticeQuestion`)}
            items={optionItems} {...attr}/>
    );
}

export default TriggerConfigurationTable;