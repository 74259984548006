import {compareNumStrings, getValues, isNotEmptyNorFalsy, switchcase} from "./helperFunctions";
import {awsIdTypeKeys, executionModeKeys, libraryFileUpdateMode} from "../i18next/keys";
import {azureVmSources} from "./constants";

export function shouldEnableLogin(formData) {
    const {username, password} = formData;

    return !!username && !!password;
}


export function validateParameters(parameters) {
    return getValues(parameters || {})
        .every(param => {
            return param.valid;
        });
}

export function shouldEnableClient(formData) {
    const {clientName, allowedParameters} = formData;

    return !!clientName && getValues(allowedParameters).every(entry => {
        const [name, values] = entry;

        const valuesNotEmpty = isNotEmptyNorFalsy(values);
        if (!!name && valuesNotEmpty) {
            return true;
        }

        // If either name or values are not empty, return false
        return !name && !valuesNotEmpty;
    });
}

export function shouldEnableMatter(formData) {
    const {matterName, datasets} = formData;

    return !!matterName && getValues(datasets)
      .every(_dataset => !!_dataset.name && !!_dataset.dataRepositoryId);
}

export function shouldEnableLibrary(formData) {
    const {libraryName} = formData;

    return !!libraryName;
}

export function shouldEnableUpdateAutomateLicence(formData) {
    const {licenceId, licenceKey} = formData;

    return !!(licenceId && licenceKey);
}


export function shouldEnableNmsLicenceSource(formData) {
    const {nmsLicenceSourceName, nmsLicenseSourceUsername, nmsLicenseSourcePassword, nmsName, nmsPort} = formData;

    return !!nmsLicenceSourceName && !!nmsLicenseSourceUsername && !!nmsLicenseSourcePassword && !!nmsName && !!nmsPort;
}

export function shouldEnableClsLicenceSource(formData) {
    const {clsLicenceSourceName, clsLicenseSourceUsername, clsLicenseSourcePassword} = formData;

    return !!clsLicenceSourceName && !!clsLicenseSourceUsername && !!clsLicenseSourcePassword;
}

export function shouldEnableDongleLicenceSource(formData) {
    const {dongleName} = formData;

    return !!dongleName;
}

export function shouldEnableServer(formData) {
    const {serverName, serverUrl} = formData;

    return !!serverName && (serverUrl == null || serverUrl.length > 0);
}

export function shouldEnableEngine(formData) {
    const {engineName, serverId, supportedExecutionMode, nuixLicenceSourceId, targetNuixWorkers, minNuixWorkers} = formData;

    const areGeneralValuesFilled = !!engineName && (serverId == null || serverId.length > 0);
    const isLicenseValid = (supportedExecutionMode === executionModeKeys.AUTOMATE_NATIVE) || !!nuixLicenceSourceId;
    const areWorkerCountsValid = (supportedExecutionMode === executionModeKeys.AUTOMATE_NATIVE) || (compareNumStrings(targetNuixWorkers, minNuixWorkers) >= 0 && targetNuixWorkers > 0 && minNuixWorkers > 0);
    return areGeneralValuesFilled && isLicenseValid && areWorkerCountsValid;
}

export function shouldEnableExecutionProfile(formData) {
    const {executionProfileName, useServiceAccount, engineAccountUsername, engineAccountPassword, engineLogFolder} = formData;

    return !!executionProfileName && !!engineLogFolder && !!(!useServiceAccount || (!engineAccountUsername || engineAccountPassword));
}

export function shouldEnableLocalResourcePool(formData) {
    const {localResourcePoolName} = formData;

    return !!localResourcePoolName;
}

function isAzureConfigurationStateValid(state, vmNames) {
    const {azureVmSource, region, resourceGroupId, networkName, networkSubnetName, networkSecurityGroup, customVmImageId, customVmUsername, customVmPassword, maxConcurrentInstances,
        vmType, vmSize, diskSize, nuixLicenceSourceId, targetNuixWorkers, minNuixWorkers, tagName, tagValue
    } = state;

    const areWorkersValid = compareNumStrings(targetNuixWorkers, minNuixWorkers) >= 0 && targetNuixWorkers > 0 && minNuixWorkers > 0;
    if (!areWorkersValid) return false;

    switch (azureVmSource) {
        case azureVmSources.vmNames:
            return vmNames.some(name => name);
        case azureVmSources.tags:
            return !!(tagName && tagValue);
        case azureVmSources.customVmImage:
            return !!(region && resourceGroupId && networkName && networkSubnetName && customVmImageId && customVmUsername && customVmPassword && parseInt(maxConcurrentInstances) >= 0
                && vmType && vmSize && parseInt(diskSize) > 0 && nuixLicenceSourceId);
    }
}

export function shouldEnableAzureResourcePool(formData) {
    const {azureResourcePoolName, tenant, azureKey, appId, referenceId, referenceValue, vmNames, remoteVmNames,
        azureVmSource, region, resourceGroupId, networkName, networkSubnetName, networkSecurityGroup, customVmImageId, customVmUsername, customVmPassword, maxConcurrentInstances, vmType, vmSize, diskSize, nuixLicenceSourceId,
        targetNuixWorkers, minNuixWorkers, tagName, tagValue,

        useRemoteEngines, remoteAzureVmSource, remoteRegion, remoteResourceGroupId, remoteNetworkName, remoteNetworkSubnetName, remoteNetworkSecurityGroup, remoteCustomVmImageId,
        remoteCustomVmUsername, remoteCustomVmPassword, remoteMaxConcurrentInstances,
        remoteVmType, remoteVmSize, remoteDiskSize, remoteNuixLicenceSourceId, remoteTargetNuixWorkers, remoteMinNuixWorkers,
        remoteTagName, remoteTagValue } = formData;

    const engineConfiguration = {azureVmSource, region, resourceGroupId, networkName, networkSubnetName, networkSecurityGroup, customVmImageId, customVmUsername, customVmPassword, maxConcurrentInstances,
        vmType, vmSize, diskSize, nuixLicenceSourceId, targetNuixWorkers, minNuixWorkers, tagName, tagValue
    };
    const remoteEngineConfiguration = {
        azureVmSource: remoteAzureVmSource, region: remoteRegion, resourceGroupId: remoteResourceGroupId, networkName: remoteNetworkName, networkSubnetName: remoteNetworkSubnetName, networkSecurityGroup: remoteNetworkSecurityGroup,
        customVmImageId: remoteCustomVmImageId, customVmUsername: remoteCustomVmUsername, customVmPassword: remoteCustomVmPassword, maxConcurrentInstances: remoteMaxConcurrentInstances, vmType: remoteVmType, vmSize: remoteVmSize, diskSize: remoteDiskSize,
        nuixLicenceSourceId: remoteNuixLicenceSourceId, targetNuixWorkers: remoteTargetNuixWorkers, minNuixWorkers: remoteMinNuixWorkers,
        tagName: remoteTagName, tagValue: remoteTagValue
    };

    const isConfigurationValid = isAzureConfigurationStateValid(engineConfiguration, vmNames);
    const isRemoteConfigurationValid = !useRemoteEngines || isAzureConfigurationStateValid(remoteEngineConfiguration, remoteVmNames);

    const areSettingsValid = !!(tenant && (azureKey || (referenceId && referenceValue === tenant)))

    return !!(azureResourcePoolName && appId && isConfigurationValid && isRemoteConfigurationValid && areSettingsValid);
}

export function shouldEnableAwsResourcePool(formData) {
    const {awsResourcePoolName, accessKey, secretKey, region, awsIdType, launchTemplateId, maxConcurrentInstances, instanceIds, tagName, tagValue, nuixLicenceSourceId, targetNuixWorkers, minNuixWorkers,
        useRemoteEngines, remoteAwsIdType, remoteLaunchTemplateId, remoteMaxConcurrentInstances, remoteInstanceIds, remoteTagName, remoteTagValue, remoteNuixLicenceSourceId, remoteTargetNuixWorkers, remoteMinNuixWorkers,
        referenceId, referenceValue
    } = formData;

    const duplicatedPoolOption = !!referenceId ? (!!secretKey || (!!referenceValue && referenceValue === accessKey)) : (!!secretKey);

    const areRequiredValuesFilled = !!awsResourcePoolName && !!accessKey && duplicatedPoolOption && !!region && !!nuixLicenceSourceId;
    const areWorkerCountsValid = compareNumStrings(targetNuixWorkers, minNuixWorkers) >= 0 && targetNuixWorkers > 0 && minNuixWorkers > 0;
    const areTypeSpecificValuesValid = switchcase({
        [awsIdTypeKeys.LAUNCH_TEMPLATE_ID]: !!launchTemplateId && maxConcurrentInstances > 0,
        [awsIdTypeKeys.INSTANCE_IDS]: instanceIds.some(id => id),
        [awsIdTypeKeys.TAGS]: !!tagName && !!tagValue,
    })()(awsIdType);

    const result = areRequiredValuesFilled && areWorkerCountsValid && areTypeSpecificValuesValid;


    if (useRemoteEngines) {
        const areRemoteRequiredValuesFilled = !!remoteNuixLicenceSourceId;
        const areRemoteWorkerCountsValid = compareNumStrings(remoteTargetNuixWorkers, remoteMinNuixWorkers) >= 0 && remoteTargetNuixWorkers > 0 && remoteMinNuixWorkers > 0;
        const areRemoteTypeSpecificValuesValid = switchcase({
            [awsIdTypeKeys.LAUNCH_TEMPLATE_ID]: !!remoteLaunchTemplateId && remoteMaxConcurrentInstances > 0,
            [awsIdTypeKeys.INSTANCE_IDS]: remoteInstanceIds.some(id => id),
            [awsIdTypeKeys.TAGS]: !!remoteTagName && !!remoteTagValue,
        })()(remoteAwsIdType);
        return result && (areRemoteRequiredValuesFilled && areRemoteWorkerCountsValid && areRemoteTypeSpecificValuesValid);
    }

    return result;
}

export function shouldEnableClientPool(formData) {
    const {clientPoolName} = formData;

    return !!clientPoolName;
}

export function shouldEnablePolicy(formData) {
    const {policyName} = formData;

    return !!policyName;
}

export function shouldEnableFileLibraryResource (formData) {
    const {fileLibraryName, description} = formData;
    return !!fileLibraryName && (!!description || true);
}

export function shouldEnableLibraryFile (formData) {
    const {fileName, fileData, mode} = formData;
    return mode !== libraryFileUpdateMode.LOCAL || !!fileName && fileData !== null;
}

export function shouldEnableApiKey(formData) {
    const {apiKeyName, validityDays} = formData;

    return !!apiKeyName && validityDays>0;
}

export function shouldEnableWebhook(formData) {
    const {webhookName, callbackAddress, triggers} = formData;

    return !!webhookName && !!callbackAddress && triggers.right.length > 0;
}
