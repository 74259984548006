import SettingModel from "../generics/SettingModel";

class JobRequiredProfilesModel extends SettingModel.Simple {

    static nom = 'JobRequiredProfilesModel';
    static actions = JobRequiredProfilesModel.buildActions('JOB_REQUIRED_PROFILES');
    static actionCreators = JobRequiredProfilesModel.buildActionCreators(JobRequiredProfilesModel.actions);
    static reducer = JobRequiredProfilesModel.buildReducer(JobRequiredProfilesModel.actions);

    constructor(model = {}) {
        super(model);
    }
}

export default JobRequiredProfilesModel;
