import React from "react";
import ThirdPartyServiceModel from "../../../models/thirdparty/ThirdPartyServiceModel";
import ThirdPartyServiceTable from "../ThirdPartyServiceTable";

function VaultServiceTable(props) {
    return (
        <ThirdPartyServiceTable {...props} type={ThirdPartyServiceModel.Type.VAULT}
            showService/>
    )
}

export default VaultServiceTable;
