import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import React, {useLayoutEffect} from "react";
import ThirdPartyServiceModel from "../../models/thirdparty/ThirdPartyServiceModel";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import Switch from "../common/Switch/Switch";
import PurviewServiceTable from "./purview/PurviewServiceTable";
import {applicationFeatures, details} from "../../utilities/constants";
import {getMapValueName, getValues} from "../../utilities/helperFunctions";
import ThirdPartyServiceTablet from "./ThirdPartyServiceTablet";
import RelativityServiceTable from "./relativity/RelativityServiceTable";
import PurviewServiceForm from "./purview/PurviewServiceForm";
import RelativityServiceForm from "./relativity/RelativityServiceForm";
import DiscoverServiceTable from "./discover/DiscoverServiceTable";
import DiscoverServiceForm from "./discover/DiscoverServiceForm";
import NlpServiceTable from "./nlp/NlpServiceTable";
import NlpServiceForm from "./nlp/NlpServiceForm";
import EccServiceTable from "./ecc/EccServiceTable";
import EccServiceForm from "./ecc/EccServiceForm";
import VaultServiceTable from "./vault/VaultServiceTable";
import VaultServiceForm from "./vault/VaultServiceForm";
import DerbyControlServiceTable from "./derbyControl/DerbyControlServiceTable";
import DerbyControlServiceForm from "./derbyControl/DerbyControlServiceForm";
import InvestigateServiceTable from "./investigate/InvestigateServiceTable";
import InvestigateServiceForm from "./investigate/InvestigateServiceForm";
import GraphServiceForm from "./graph/GraphServiceForm";
import GraphServiceTable from "./graph/GraphServiceTable";
import GenAiServiceForm from "./genAi/GenAiServiceForm";
import GenAiServiceTable from "./genAi/GenAiServiceTable";
import SmtpServiceForm from "./smtp/SmtpServiceForm";
import SmtpServiceTable from "./smtp/SmtpServiceTable";
import LinkServiceForm from "./external/LinkServiceForm";
import LinkServiceTable from "./external/LinkServiceTable";
import {MainPageContent, MainPageHeader} from "../app/pages/MainPage";
import SemanticServiceTable from "./semantic/SemanticServiceTable";
import SemanticServiceForm from "./semantic/SemanticServiceForm";
import ElasticsearchServiceTable from "./elasticsearch/ElasticsearchServiceTable";
import ElasticsearchServiceForm from "./elasticsearch/ElasticsearchServiceForm";

function ThirdPartyServiceDisplay() {
    const {t} = useTranslation(['aria', 'thirdPartyService']);
    const dispatch = useDispatch();

    const {
        thirdPartyServiceId,
        isThirdPartyServiceFormActive
    } = useSelector(state => state.componentStates.thirdPartyServiceDisplay);

    const isLoading = useSelector(state => !state.hasLoaded[details.THIRD_PARTY_SERVICES]);
    const thirdPartyServiceDetailsMap = useSelector(state => state.thirdPartyServiceDetailsMap);
    const userFeatures = useSelector(state => state.currentUser.features);

    const canAddThirdPartyService = userFeatures.includes(applicationFeatures.ADD_THIRD_PARTY_SERVICE);
    const canViewThirdPartyService = userFeatures.includes(applicationFeatures.VIEW_THIRD_PARTY_SERVICES);

    const canViewGenAi = userFeatures.includes(applicationFeatures.GEN_AI);
    const canViewPurview = userFeatures.includes(applicationFeatures.PURVIEW);
    const canViewVault = userFeatures.includes(applicationFeatures.VAULT);
    const canViewDerbyControlService = userFeatures.includes(applicationFeatures.VIEW_DERBY_CONTROL_SERVICE);
    const canViewSemanticService = userFeatures.includes(applicationFeatures.VIEW_SEMANTIC_SERVICE);
    const canViewInvestigateService =  userFeatures.includes(applicationFeatures.VIEW_INVESTIGATE_SERVICE);
    const canViewDiscoverService = userFeatures.includes(applicationFeatures.VIEW_DISCOVER_SERVICE);

    useLayoutEffect(() => {
        dispatch(ThirdPartyServiceModel.componentActionCreators.setDisplayActive());
        return () => dispatch(ThirdPartyServiceModel.componentActionCreators.setDisplayInactive());
    }, [dispatch]);

    function showForm(event) {
        const {value} = event.currentTarget.dataset;
        dispatch(ThirdPartyServiceModel.actionCreators.showForm({type: value}));
    }

    function showTablet(event) {
        const {value} = event.currentTarget.dataset;
        dispatch(ThirdPartyServiceModel.actionCreators.showTablet(value));
    }

    const selectedThirdPartyService = thirdPartyServiceDetailsMap.get(thirdPartyServiceId);
    const services = getValues(thirdPartyServiceDetailsMap)
        .reduce((acc, curr) => {
            if (acc[curr.type] == null) {
                acc[curr.type] = [];
            }
            acc[curr.type].push(curr);
            return acc;
        }, {});

    return (
        <MainPageContent
            title={
                <MainPageHeader text={t('thirdPartyService:page.title')}
                    description={t('thirdPartyService:page.description')}/>
            }
            list={
                <LoadingWrapper isLoading={isLoading}>


                    {canViewThirdPartyService &&
                        <EccServiceTable showForm={showForm} showTablet={showTablet} canModify={canAddThirdPartyService}
                                         thirdPartyServices={services[ThirdPartyServiceModel.Type.ECC]}/>
                    }
                    {canViewDiscoverService &&
                        <DiscoverServiceTable showForm={showForm} showTablet={showTablet} canModify={canAddThirdPartyService}
                                              thirdPartyServices={services[ThirdPartyServiceModel.Type.DISCOVER]}/>
                    }
                    {canViewThirdPartyService &&
                        <NlpServiceTable showForm={showForm} showTablet={showTablet} canModify={canAddThirdPartyService}
                                         thirdPartyServices={services[ThirdPartyServiceModel.Type.NLP]}/>
                    }
                    {canViewInvestigateService &&
                        <InvestigateServiceTable showForm={showForm} showTablet={showTablet} canModify={canAddThirdPartyService}
                                                 thirdPartyServices={services[ThirdPartyServiceModel.Type.INVESTIGATE]}/>
                    }


                    {canViewDerbyControlService &&
                        <DerbyControlServiceTable showForm={showForm} showTablet={showTablet} canModify={canAddThirdPartyService}
                                                  thirdPartyServices={services[ThirdPartyServiceModel.Type.DERBY_CONTROL]}/>
                    }

                    {canViewThirdPartyService &&
                        <ElasticsearchServiceTable showForm={showForm} showTablet={showTablet} canModify={canAddThirdPartyService}
                                                   thirdPartyServices={services[ThirdPartyServiceModel.Type.ELASTICSEARCH]}/>
                    }

                    {canViewGenAi &&
                        <GenAiServiceTable showForm={showForm} showTablet={showTablet} canModify={canAddThirdPartyService}
                                           thirdPartyServices={services[ThirdPartyServiceModel.Type.GEN_AI]}/>
                    }
                    {canViewVault &&
                        <VaultServiceTable showForm={showForm} showTablet={showTablet} canModify={canAddThirdPartyService}
                                           thirdPartyServices={services[ThirdPartyServiceModel.Type.VAULT]}/>
                    }
                    {canViewThirdPartyService &&
                        <GraphServiceTable showForm={showForm} showTablet={showTablet} canModify={canAddThirdPartyService}
                                           thirdPartyServices={services[ThirdPartyServiceModel.Type.GRAPH]}/>
                    }
                    {canViewThirdPartyService &&
                        <LinkServiceTable showForm={showForm} showTablet={showTablet} canModify={canAddThirdPartyService}
                                          thirdPartyServices={services[ThirdPartyServiceModel.Type.LINK]}/>
                    }
                    {canViewPurview &&
                    <PurviewServiceTable showForm={showForm} showTablet={showTablet} canModify={canAddThirdPartyService}
                        thirdPartyServices={services[ThirdPartyServiceModel.Type.PURVIEW]}/>
                    }
                    {canViewThirdPartyService &&
                    <RelativityServiceTable showForm={showForm} showTablet={showTablet} canModify={canAddThirdPartyService}
                        thirdPartyServices={services[ThirdPartyServiceModel.Type.RELATIVITY]}/>
                    }
                    {canViewSemanticService &&
                    <SemanticServiceTable showForm={showForm} showTablet={showTablet} canModify={canAddThirdPartyService}
                                          thirdPartyServices={services[ThirdPartyServiceModel.Type.SEMANTIC]}/>
                    }
                    {canViewThirdPartyService &&
                    <SmtpServiceTable showForm={showForm} showTablet={showTablet} canModify={canAddThirdPartyService}
                        thirdPartyServices={services[ThirdPartyServiceModel.Type.SMTP]}/>
                    }

                </LoadingWrapper>
            }
        >
            <Switch>
                {selectedThirdPartyService != null &&
                    <ThirdPartyServiceTablet thirdPartyService={selectedThirdPartyService}/>
                }
                {isThirdPartyServiceFormActive &&
                    <ThirdPartyServiceFormWrapper/>
                }
            </Switch>
        </MainPageContent>
    )
}

function ThirdPartyServiceFormWrapper() {
    const {
        type
    } = useSelector(state => state.componentStates.thirdPartyServiceForm);

    switch (type) {
        case ThirdPartyServiceModel.Type.PURVIEW:
            return <PurviewServiceForm/>
        case ThirdPartyServiceModel.Type.VAULT:
            return <VaultServiceForm/>
        case ThirdPartyServiceModel.Type.RELATIVITY:
            return <RelativityServiceForm/>
        case ThirdPartyServiceModel.Type.DISCOVER:
            return <DiscoverServiceForm/>
        case ThirdPartyServiceModel.Type.ELASTICSEARCH:
            return <ElasticsearchServiceForm/>
        case ThirdPartyServiceModel.Type.NLP:
            return <NlpServiceForm/>
        case ThirdPartyServiceModel.Type.ECC:
            return <EccServiceForm/>
        case ThirdPartyServiceModel.Type.DERBY_CONTROL:
            return <DerbyControlServiceForm/>
        case ThirdPartyServiceModel.Type.GRAPH:
            return <GraphServiceForm/>
        case ThirdPartyServiceModel.Type.GEN_AI:
            return <GenAiServiceForm/>
        case ThirdPartyServiceModel.Type.SEMANTIC:
            return <SemanticServiceForm/>
        case ThirdPartyServiceModel.Type.INVESTIGATE:
            return <InvestigateServiceForm/>
        case ThirdPartyServiceModel.Type.SMTP:
            return <SmtpServiceForm/>
        case ThirdPartyServiceModel.Type.LINK:
            return <LinkServiceForm/>
    }
}

export function selectThirdPartyServiceNames(state, service) {
    return {
        authenticationService: getMapValueName(state.userServiceDetailsMap, service.authenticationServiceId)
    }
}

export default ThirdPartyServiceDisplay;
