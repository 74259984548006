import React from 'react';
import './CustomModal.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {buildClassName} from "../../../utilities/helperFunctions";
import {useTranslation} from "react-i18next";
import FocusTrap from "focus-trap-react";
import {Button} from "../Button/Button";

function CustomModal(props) {
    const {t} = useTranslation(['aria']);
    const {
        id,
        header,
        body,
        footer,
        children,
        isActive,
        disableFocusTrap,
        onCancel,

        showX=true,
        closeButtonAriaLabel=t('aria:hiddenAssistText.close'),
        'aria-labelledby': ariaLabelledBy,

        isRigid,
        isDisabled,
        className,
        ...attr
    } = props;

    const onBackClick = (isDisabled || isRigid) ? null : onCancel;
    const onXClick = isDisabled ? null : onCancel;
    const escapeDeactivates = () => {
        onXClick();
        return false;
    }

    const _className = buildClassName(
        'custom-modal',
        isActive && 'is-active',
        className
    );

    return (
        <article id={id} className={_className} {...attr}>
            <div className="custom-modal-background" onClick={onBackClick}/>

            <FocusTrap active={isActive && !disableFocusTrap}
                focusTrapOptions={{initialFocus: false, allowOutsideClick: true, escapeDeactivates, fallbackFocus: document.activeElement}}
            >
                <section className="custom-modal-content"
                    role="dialog" aria-modal="true" aria-labelledby={ariaLabelledBy}
                >
                    <header className="custom-modal-header" id={`${id}Header`}>
                        {header || <div/>}

                        {showX &&
                            <Button id={`${id}CloseButton`} className={'custom-modal-close'} onClick={onXClick}
                                isImg isClear aria-label={closeButtonAriaLabel} isDisabled={isDisabled}
                            >
                                <span className="icon">
                                    <FontAwesomeIcon icon="times"/>
                                </span>
                            </Button>
                        }
                    </header>

                    {body}

                    <footer className="custom-modal-footer">
                        {footer}
                    </footer>
                    {children}
                </section>
            </FocusTrap>
        </article>
    );
}

export default CustomModal;
