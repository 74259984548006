import {useCallback, useRef} from "react";
import {getDataNameFromParentNode, getKeys, stringToBool} from "./helperFunctions";

export function useInputHandler(opts) {
    const {updateHandler} = opts;
    return useCallback(function(event) {
        const {name, value, type} = event.target;
        const updates = {};

        // Do not track passwords
        if (type !== 'password')
            updates[name] = value;

        updateHandler(updates, event);
    }, [updateHandler]);
}

export function useToggleHandler(opts) {
    const {updateHandler} = opts;
    return useCallback(function(event) {
        const type = event.target.type;
        let {name, checked, value} = (type === 'checkbox' || type === 'radio') ? event.target : event.currentTarget.dataset;
        if (type === 'radio') {
            checked = value;
        }
        const updates = {
            [name]: stringToBool(checked)
        };
        updateHandler(updates, event);
    }, [updateHandler]);
}

export function useDropdownHandler(opts) {
    const {updateHandler} = opts;
    return useCallback(function(event) {
        const {dataset: {value}, parentNode} = event.currentTarget;
        const name = getDataNameFromParentNode(parentNode);
        const updates = {
            [name]: value
        };
        updateHandler(updates, event);
    }, [updateHandler]);
}

export function useUpdateHandler(opts) {
    const {updateState, passwordOptions, setEnabled} = opts;
    return useCallback(function(updates) {
        const passwords = {};
        if (passwordOptions != null) {
            for (const name of getKeys(passwordOptions)) {
                const {ref, resetNames = []} = passwordOptions[name];
                // clear password if a resetName is being updated
                if (ref != null && ref.current != null) {
                    if (resetNames.some(name => updates[name] != null)) {
                        ref.current.value = '';
                    }
                    passwords[name] = ref.current.value;
                }
            }
        }
        updateState(prevState => {
            if (typeof setEnabled === 'function') {
                setEnabled(...prevState, ...updates, ...passwords);
            }
            return {...prevState, ...updates};
        });
    }, []);
}

export function useClearOnFirstCallback() {
    const didClear = useRef({});
    return useCallback(event => {
        const {name} = event.target;
        if (didClear.current[name] == null) {
            event.target.value = '';
            didClear.current[name] = true;
        }
    }, []);
}
