import SettingModel from "../generics/SettingModel";

class JobErrorsModel extends SettingModel.Simple {

    static nom = 'JobErrorsModel';
    static actions = JobErrorsModel.buildActions('JOB_ERRORS');
    static actionCreators = JobErrorsModel.buildActionCreators(JobErrorsModel.actions);
    static reducer = JobErrorsModel.buildReducer(JobErrorsModel.actions);

}

export default JobErrorsModel;