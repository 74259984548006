import React from "react";
import {useSelector} from "react-redux";
import {permissionKeys} from "../../i18next/keys";
import {StatusNameDescriptionCell, TableEnableToggleCell, TableRow} from "../common/CustomTable/CustomTable";


function MatterRow(props) {
    const {
        isActive,
        id,
        onSelect,
        toggleEnable
    } = props;

    const {
        name,
        description,
        status,
        enabled,
        userPermissions
    } = useSelector(state => state.matterDetailsMap.get(id));

    const canModify = userPermissions.includes(permissionKeys.MODIFY);

    return (
        <TableRow value={id} isActive={isActive} onClick={onSelect}>
            <TableEnableToggleCell ariaLabelKey={'Matter'} displayName={name} data-value={id} checked={enabled}
                onClick={toggleEnable} isReadOnly={!canModify}/>

            <StatusNameDescriptionCell style={{maxWidth: '10rem'}}
                                       status={status}
                                       name={name}
                                       description={description}
            />

        </TableRow>
    )
}

export default MatterRow;