import React from 'react';

import '../CaseExample/caseExample.css';
import {useTranslation} from "react-i18next";
import {trimBaseFolder} from "../../../utilities/helperFunctions";
import {ExampleType} from "../../../utilities/constants";
import {ClientMatterExample, SingleFolderExample} from "../TextExample/TextExample";

function GenericPathExample (props) {
    const {t} = useTranslation(['wizard'])
    const {exampleType, exampleTypeKey, caseType, baseFolder, example} = props;

    const client = "ABC Health Services, Inc.";
    const matter = "ABC Health Services, Inc. v. IBM Corp.";

    const folderTranslationKey = [1, 2].includes(caseType) ? "baseFolder" : "casePath";
    const folderExample = folderTranslationKey === "baseFolder" ? trimBaseFolder(baseFolder) : "{nuix_case_path}";
    const exampleTranslationKey = exampleType === ExampleType.EXPORT ? "exportDirectory" : "reportFile";


    const separator = (<div className={"folder-separator"}><span> <label className={"label is-small"}>/</label> </span> <span> <hr/> </span>
        <span> <label className={"placeholder label is-small"}>a</label> </span></div>);

    return (
        <div>
            <label className={"label is-small"}>{t(`wizard:examples.${exampleTypeKey}`)}</label>
            <div className={"example-container"}>
                <div>
                    <div className={"base-folder"} key={"prefix"}>
                        <span>
                            <label className={"label is-small"}>{folderExample}</label>
                            <hr className={"line-separator"}/>
                            <label className={"label is-small"}>{t(`wizard:examples.${folderTranslationKey}`)}</label>
                        </span>
                    </div>
                    {separator}
                    {caseType === 1 &&
                        <ClientMatterExample client={client} matter={matter}/>
                    }
                    {caseType === 2 &&
                        <SingleFolderExample caseName={matter}/>
                    }
                    <div className={"suffix-text"} key={"suffix"}>
                        <span>
                            <label className={"label is-small"}>{example}</label>
                            <hr className={"line-separator"}/>
                            <label className={"label is-small"}>{t(`wizard:examples.${exampleTranslationKey}`)}</label>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GenericPathExample;