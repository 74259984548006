import React, {useCallback, useEffect, useRef, useState} from "react";
import './Panel.css';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {
    getFileNameWithoutExtension,
    getObjectText,
    getValues,
    isNotEmptyNorFalsy
} from "../../utilities/helperFunctions";
import {MIN_SIZE_FOR_SEARCH, SAME_AS_TRIGGERING_JOB} from "../../utilities/constants";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {WorkflowTemplateQueueRow} from "./QueueRow";
import WorkflowTemplateModel from "../../models/library/WorkflowTemplateModel";
import {JobSaga} from "../../models/job/JobModel";
import {createFileHandler} from "../../utilities/componentFunctions";
import SearchBar from "../common/SearchBar/SearchBar";
import {AddButtonHeader} from "../common/Button/Button";
import {permissionKeys} from "../../i18next/keys";
import SchedulerModel from "../../models/scheduler/SchedulerModel";
import {call} from "redux-saga/effects";
import encode from "base64-arraybuffer";

export function RestrictedWorkflowTemplatePanel(props) {
    const {
        libraryId
    } = props;

    const workflowSelector = useCallback(state => {
        return getValues(state.workflowTemplateDetailsMap)
            .filter(template => template.enabled && template.libraryId === libraryId
                && template.userPermissions.includes(permissionKeys.SUBMIT_JOB));
    }, [libraryId]);

    return (
        <WorkflowTemplatePanel {...props} workflowSelector={workflowSelector}/>
    )
}

function WorkflowTemplatePanel(props) {
    const {t} = useTranslation(['library', 'jobSchedule']);
    const dispatch = useDispatch()

    const {
        libraryId,
        workflowTemplateId,
        workflowName,
        workflowSelector,
        updateState,
        canSetSameAs={},
        isLoading,
        isDisabled
    } = props;

    const _isLoading = useSelector(state => !state.hasLoaded[libraryId]) || isLoading;
    const workflowTemplates = useSelector(workflowSelector);
    const [searchText, setSearchText] = useState('');

    const canUpload = useSelector(state => {
        if (props.canUpload != null) {
            return props.canUpload;
        }
        const library = state.libraryDetailsMap.get(libraryId);
        if (library != null) {
            return library.userPermissions.includes(permissionKeys.MODIFY);
        }
    });

    // Query workflowTemplates
    useEffect(() => {
        dispatch(WorkflowTemplateModel.actionCreators.queryDetailsSubset(libraryId));
    }, [libraryId]);

    const workflowFileChange = createFileHandler({
        readAs: 'readAsArrayBuffer',
        onloadend: (event, file) => {
            const workflowXml = encode.encode(event.target.result);
            const workflowName = getFileNameWithoutExtension(file.name);

            const addWorkflowFromQueueEffect = call(JobSaga.addWorkflowFromQueue, {updateState, workflowName, workflowXml, libraryId});
            dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(addWorkflowFromQueueEffect));
        }
    });

    const workflowUploadRef = useRef({});
    const onWorkflowAddClick = () => {
        workflowUploadRef.current.click();
    }

    // Select Workflow if it's the only one (and no workflow is selected)
    useEffect(() => {
        if (workflowTemplateId == null && workflowTemplates.length === 1) {
            const workflowTemplate = workflowTemplates[0];
            updateState({workflowTemplateId: workflowTemplate.id});
        }
    }, [workflowTemplateId, workflowTemplates, updateState]);

    const scrollDivRef = useRef();
    // Scroll to selected workflowTemplate IFF exists
    useEffect(() => {
        if (!_isLoading && scrollDivRef.current != null) {
            const activePanel = scrollDivRef.current.getElementsByClassName('is-active')[0];
            if (activePanel != null) {
                activePanel.scrollIntoView();
            }
        }
    }, [_isLoading, workflowName]);

    function workflowTemplateSelect(event) {
        const {value: workflowTemplateId} = event.currentTarget.dataset;
        updateState({workflowTemplateId});

        setTimeout(() => {
            document.getElementById('formFooterNextButton')?.focus();
        }, 50);
    }

    const isLibraryEmpty = !isNotEmptyNorFalsy(workflowTemplates);
    const canSearchWorkflowTemplates = workflowTemplates.length >= MIN_SIZE_FOR_SEARCH;

    const centered = isLibraryEmpty ? ' is-centered' : '';
    const disabled = isDisabled ? ' is-disabled' : '';

    let content;
    if (libraryId == null || (!canSetSameAs.job && libraryId === SAME_AS_TRIGGERING_JOB)) {
        content = <label className={'label is-italic' + disabled}>{t('library:message.selectLibrary')}</label>;
    } else if (libraryId === SAME_AS_TRIGGERING_JOB) {
        content = <label
            className={'label is-italic' + disabled}>{t('jobSchedule:message.sameWorkflowAsTriggeringMatter')}</label>;

    } else if (isLibraryEmpty && !_isLoading) {
        content =
            <label className={'label is-italic' + disabled} style={{marginBottom: '1rem'}}>{t('library:message.libraryNoActiveWorkflows')}</label>;

    } else {
        content =
            <LoadingWrapper isLoading={_isLoading}>
                <section style={{overflowY: 'auto'}} ref={scrollDivRef}>
                    <div className="select-table">
                        <div className="table-row-group">
                            {workflowTemplates
                                .filter(_workflowTemplate => getObjectText(_workflowTemplate).includes(searchText.toLowerCase()))
                                .map(({id}) =>
                                    <WorkflowTemplateQueueRow key={id} workflowTemplateId={id}
                                        isActive={id === workflowTemplateId} onPanelSelect={workflowTemplateSelect}
                                        isDisabled={isDisabled}/>
                                )}
                        </div>
                    </div>
                </section>
            </LoadingWrapper>
    }

    return (
        <article className={'selectPane-display'} role="tabpanel" tabIndex={-1}
            id={libraryId ? `${libraryId}_TABPANEL` : null} aria-labelledby={libraryId ? `${libraryId}_TAB` : null}
        >
            <section className={'selectPane-header'}>
                {(!_isLoading && canUpload) &&
                <>
                    <AddButtonHeader aria-label={t('aria:hiddenAssistText.addWorkflow')} style={{margin: '0 0 0.25rem'}} canModify
                        text={t('workflow:label.name')} onClick={onWorkflowAddClick} isDisabled={isDisabled}
                    />
                    <input type={'file'} ref={workflowUploadRef} accept=".rfn,.zip" style={{display: 'none'}} onChange={workflowFileChange}/>

                </>
                }

                {canSearchWorkflowTemplates &&
                <SearchBar id="workflowSearchBar" autoFocus style={{margin: '0 0 0.25rem'}}
                    value={searchText} onChange={event => setSearchText(event.target.value)}/>
                }
            </section>

            <section className={'selectPane-body' + centered}>
                {content}
            </section>
        </article>
    );
}

export default WorkflowTemplatePanel;
