import {deepCopy} from "../../utilities/helperFunctions";

const operations = {
    "addEvidence": {
        "customMetadata": {
            "item-array": [
                {
                    "item": [
                        null,
                        null
                    ]
                },
                {
                    "item": [
                        null,
                        null
                    ]
                }
            ]
        },
        "dateFilterAfter": "2018-01-01 05:00:00.0 UTC",
        "dateFilterBefore": "2019-01-01 04:59:00.0 UTC",
        "dateFilterIntervalType": "NONE",
        "dateFilterScope": "TOP_LEVEL_COMMUNICATION",
        "deduplicateAgainstDigestList": "false",
        "deduplicationActionSkipEntirely": "false",
        "deduplicationAtIngestion": "false",
        "deduplicationMethodEmailMapiSearchKey": "false",
        "deduplicationMethodEmailMessageId": "false",
        "deduplicationMethodTopLevelMD5": "true",
        "deduplicationScopeTrackMultiBatchLoads": "true",
        "digestListName": null,
        "disabled": "false",
        "evidenceName": null,
        "mimeTypeFilterType": "NONE",
        "mimeTypeFilters": null,
        "notes": null,
        "predefined": "true",
        "source": null,
        "sourceEncoding": "UTF-8",
        "sourceScope": "PATH_ITEM",
        "timeZoneId": "America/New_York"
    },
    "addToItemset": {
        "batchName": null,
        "createIfNotExists": "true",
        "deduplicateBy": "FAMILY",
        "deduplicationMethod": "MD5",
        "disabled": "false",
        "inclusionStrategy": "SELECTED_ITEMS",
        "itemSetDescription": null,
        "itemSetName": null,
        "notes": null,
        "scope": null
    },
    "addToProductionSet": {
        "continuedNumbering": "true",
        "createIfNotExistsOrFrozen": "true",
        "disabled": "false",
        "documentIdBoxCycleFrom": "1",
        "documentIdBoxCycleTo": "999",
        "documentIdBoxStart": "1",
        "documentIdDigits": "7",
        "documentIdFolderCycleFrom": "1",
        "documentIdFolderCycleTo": "999",
        "documentIdFolderStart": "1",
        "documentIdPageCycleFrom": "1",
        "documentIdPageCycleTo": "9000",
        "documentIdPageStart": "1",
        "documentIdPrefix": null,
        "familyDigits": "3",
        "familyNumbering": "false",
        "familySeparator": ".",
        "filenameNumbering": "false",
        "freezeProductionSet": "false",
        "inclusionStrategy": "SELECTED_ITEMS",
        "notes": null,
        "pageDelimiter": ".",
        "prefixBoxFolderPageNumbering": "false",
        "prefixFolderPageNumbering": "false",
        "prefixPageNumbering": "false",
        "productionSetDescription": null,
        "productionSetName": "Production {date_time}",
        "promptForNumberingAtExecution": "false",
        "scope": null,
        "sortFields": {
            "object-array": [
                {
                    "sortDirection": "ASCENDING",
                    "sortField": "TOP_LEVEL_EFFECTIVE_PATH_NAME"
                },
                {
                    "sortDirection": "DESCENDING",
                    "sortField": "TOP_LEVEL_ITEM_DATE"
                },
                {
                    "sortDirection": "ASCENDING",
                    "sortField": "POSITION"
                }
            ]
        },
        "sortOrder": "TOP_LEVEL_ITEM_DATE",
        "startNumberingAt": "1"
    },
    "assignCustodian": {
        "notes": null,
        "disabled": "false",
        "interactiveAssignment": "false",
        "automaticDetectionFromPST": "false",
        "automaticDetectionFromFolderName": "false",
        "setCustodiansFromFolderName": "false",
        "setCustodiansFromDataSetMetadata": "true",
        "scope": "",
        "automaticDetectionFromFolderNameMaxDepth": "4",
        "setCustodiansFromFolderNameDepth": "1"
    },
    "configureParameters": {
        "disabled": "false",
        "notes": null,
        "staticParameters": [],
        "userParameters": []
    },
    "configureNuix": {
        "disabled": "false",
        "notes": null,
        "parallelProcessingSettingsSource": "PROFILE",
        "passwordFilePath": null,
        "passwordList": null,
        "passwordSettingsType": "NONE",
        "persistParameters": "false",
        "placeholders": null,
        "processingProfileName": "Default",
        "profileFilePath": null,
        "promptAtExecution": "false",
        "useConfigurationProfile": "false",
        "useProcessingProfile": "true",
        "workflowBrokerIp": null,
        "workflowBrokerPort": "0",
        "workflowBrokerWorkersCount": "0",
        "workflowLocalWorkersCount": "2",
        "workflowLocalWorkersMemoryMb": "2000",
        "workflowLocalWorkersTempFolder": "C:\\Temp",
        "requireFilesInExecutionProfile": false
    },
    "dateRangeFilter": {
        "notes": null,
        "disabled": "false",
        "scope": "flag:audited",
        "actionName": "Date Range Filter",
        "filterActionType": "TAG",
        "filterActionScope": "MATCHES",
        "dateCriteria": "ITEM_DATE",
        "dateFilterIntervalType": "BETWEEN",
        "dateAfter": "2021-01-01 05:00:00.32 UTC",
        "dateBefore": "2022-01-01 04:59:00.32 UTC"
    },
    "excludeItems": {
        "notes": null,
        "disabled": "false",
        "scope": "flag:audited",
        "skipPreviouslyExcluded": "true",
        "useExclusionsFile": "false",
        "exclusionFileName": null,
        "exclusions": {
            "item-array": {
                "item": [
                    "Excluded Items",
                    "{exclude_tag_query}"
                ]
            }
        }
    },
    "legalExport": {
        "disabled": "false",
        "exportConsolidateSplits": "false",
        "exportFileTypeImage": "false",
        "exportFileTypeNative": "false",
        "exportFileTypePdf": "false",
        "exportFileTypeText": "false",
        "exportFileTypeThumbnail": "false",
        "exportFileTypeXhtmlReport": "false",
        "exportFolder": "{case_folder}/Export/Legal Export {date_time}",
        "exportMode": "PRODUCTION_SET",
        "exportSplit": "false",
        "exportSplitAt": "100000",
        "failedItemTagName": "Automate|Failed Items|Legal Export|{export_production_set_name}",
        "imagingProfile": null,
        "legalExportType": "CONCORDANCE",
        "mailType": "NATIVE",
        "metadataMappingFile": null,
        "metadataProfile": "Default",
        "notes": null,
        "productionProfile": null,
        "productionSetName": null,
        "relativityMappingLocation": null,
        "scope": null,
        "tagFailedItems": "true",
        "untagSuccessfulItems": "true"
    },
    "report": {
        "additionalCasesLocations": null,
        "customReportTemplatePath": null,
        "disabled": "false",
        "notes": null,
        "processingStages": {
            "object-array": [
                {
                    "item": [
                        "Source data",
                        "flag:physical_file"
                    ],
                    "sizeMethod": "FILE_SISE"
                },
                {
                    "item": [
                        "Extracted",
                        "flag:( NOT physical_file OR audited )"
                    ],
                    "sizeMethod": "AUDITED_SIZE"
                },
                {
                    "item": [
                        "Material",
                        "flag:audited"
                    ],
                    "sizeMethod": "AUDITED_SIZE"
                },
                {
                    "item": [
                        "Post exclusions",
                        "flag:audited has-exclusion:0"
                    ],
                    "sizeMethod": "AUDITED_SIZE"
                },
                {
                    "item": [
                        "Post deduplication",
                        "is-item-original:1"
                    ],
                    "sizeMethod": "AUDITED_SIZE"
                },
                {
                    "item": [
                        "Export",
                        "has-production-set:1"
                    ],
                    "sizeMethod": "AUDITED_ATTACHMENTS_2X"
                },
                {
                    "item": [
                        "Export Errors",
                        "tag:\"Automate|Failed Items|Legal Export|*\""
                    ],
                    "sizeMethod": "AUDITED_SIZE"
                }
            ]
        },
        "reportLocation": "{case_folder}/Reports/Processing Report {date_time}.xlsx",
        "reportViews": {
            "object-array": [
                {
                    "item": "Processing overview",
                    "reportView": {
                        "horizontalFieldProperties": null,
                        "horizontalFieldType": "PROCESSING_STAGE",
                        "sizeUnit": "GiB",
                        "summarizeBy": "COUNT_AND_SIZE",
                        "templateWorksheetName": "__2D_COUNT_SIZE_CHART__",
                        "useCustomViewTemplate": "false",
                        "verticalFieldProperties": null,
                        "verticalFieldProperties2": null,
                        "verticalFieldType": "EVIDENCE_CONTAINER",
                        "verticalFieldType2": "NONE"
                    }
                },
                {
                    "item": "Material items by custodian",
                    "reportView": {
                        "horizontalFieldProperties": null,
                        "horizontalFieldType": "CATEGORY",
                        "processingStage": "Material",
                        "sizeUnit": "GiB",
                        "summarizeBy": "COUNT_AND_SIZE",
                        "templateWorksheetName": "__2D_COUNT_SIZE_CHART_PIE__",
                        "useCustomViewTemplate": "true",
                        "verticalFieldProperties": null,
                        "verticalFieldProperties2": null,
                        "verticalFieldType": "CUSTODIAN",
                        "verticalFieldType2": "NONE"
                    }
                },
                {
                    "item": "Export items by custodian",
                    "reportView": {
                        "horizontalFieldProperties": null,
                        "horizontalFieldType": "CATEGORY",
                        "processingStage": "Export",
                        "sizeUnit": "GiB",
                        "summarizeBy": "COUNT_AND_SIZE",
                        "templateWorksheetName": "__2D_COUNT_SIZE_CHART_PIE__",
                        "useCustomViewTemplate": "false",
                        "verticalFieldProperties": null,
                        "verticalFieldProperties2": null,
                        "verticalFieldType": "CUSTODIAN",
                        "verticalFieldType2": "NONE"
                    }
                },
                {
                    "item": "Material items by year",
                    "reportView": {
                        "horizontalFieldProperties": {
                            "entry": [
                                {
                                    "item": [
                                        "Start",
                                        "2008"
                                    ]
                                },
                                {
                                    "item": [
                                        "End",
                                        "2018"
                                    ]
                                }
                            ]
                        },
                        "horizontalFieldType": "TOP_LEVEL_YEAR",
                        "processingStage": "Material",
                        "sizeUnit": "GiB",
                        "summarizeBy": "COUNT",
                        "templateWorksheetName": "__2D_COUNT_CHART__",
                        "useCustomViewTemplate": "false",
                        "verticalFieldProperties": null,
                        "verticalFieldProperties2": null,
                        "verticalFieldType": "CUSTODIAN",
                        "verticalFieldType2": "NONE"
                    }
                },
                {
                    "item": "Export items by year",
                    "reportView": {
                        "horizontalFieldProperties": {
                            "_attributes": {
                                "reference": "../../../object-array[4]/reportView/horizontalFieldProperties"
                            }
                        },
                        "horizontalFieldType": "TOP_LEVEL_YEAR",
                        "processingStage": "Export",
                        "sizeUnit": "GiB",
                        "summarizeBy": "COUNT",
                        "templateWorksheetName": "__2D_COUNT_CHART__",
                        "useCustomViewTemplate": "false",
                        "verticalFieldProperties": null,
                        "verticalFieldProperties2": null,
                        "verticalFieldType": "CUSTODIAN",
                        "verticalFieldType2": "NONE"
                    }
                },
                {
                    "item": "Material items by type",
                    "reportView": {
                        "horizontalFieldType": "NONE",
                        "processingStage": "Material",
                        "sizeUnit": "GiB",
                        "summarizeBy": "COUNT_AND_SIZE",
                        "templateWorksheetName": "__SINGLE_COUNT_SIZE__",
                        "useCustomViewTemplate": "true",
                        "verticalFieldProperties": null,
                        "verticalFieldProperties2": null,
                        "verticalFieldType": "KIND",
                        "verticalFieldType2": "TYPE"
                    }
                },
                {
                    "item": "Export items by type",
                    "reportView": {
                        "horizontalFieldType": "NONE",
                        "processingStage": "Export",
                        "sizeUnit": "GiB",
                        "summarizeBy": "COUNT_AND_SIZE",
                        "templateWorksheetName": "__SINGLE_COUNT_SIZE__",
                        "useCustomViewTemplate": "true",
                        "verticalFieldProperties": null,
                        "verticalFieldProperties2": null,
                        "verticalFieldType": "KIND",
                        "verticalFieldType2": "TYPE"
                    }
                },
                {
                    "item": "Material items by extension",
                    "reportView": {
                        "horizontalFieldType": "NONE",
                        "processingStage": "Material",
                        "sizeUnit": "GiB",
                        "summarizeBy": "COUNT_AND_SIZE",
                        "templateWorksheetName": "__SINGLE_COUNT_SIZE__",
                        "useCustomViewTemplate": "true",
                        "verticalFieldProperties": null,
                        "verticalFieldProperties2": null,
                        "verticalFieldType": "KIND",
                        "verticalFieldType2": "FILE_EXTENSION"
                    }
                },
                {
                    "item": "Export items by extension",
                    "reportView": {
                        "horizontalFieldType": "NONE",
                        "processingStage": "Export",
                        "sizeUnit": "GiB",
                        "summarizeBy": "COUNT_AND_SIZE",
                        "templateWorksheetName": "__SINGLE_COUNT_SIZE__",
                        "useCustomViewTemplate": "true",
                        "verticalFieldProperties": null,
                        "verticalFieldProperties2": null,
                        "verticalFieldType": "KIND",
                        "verticalFieldType2": "FILE_EXTENSION"
                    }
                },
                {
                    "item": "Material images by dimensions",
                    "reportView": {
                        "horizontalFieldProperties": null,
                        "horizontalFieldType": "IMAGE_WIDTH",
                        "processingStage": "Material",
                        "sizeUnit": "GiB",
                        "summarizeBy": "COUNT",
                        "templateWorksheetName": "__2D_COUNT__",
                        "useCustomViewTemplate": "true",
                        "verticalFieldProperties": null,
                        "verticalFieldProperties2": null,
                        "verticalFieldType": "IMAGE_HEIGHT",
                        "verticalFieldType2": "NONE"
                    }
                },
                {
                    "item": "Export images by dimensions",
                    "reportView": {
                        "horizontalFieldProperties": null,
                        "horizontalFieldType": "IMAGE_WIDTH",
                        "processingStage": "Export",
                        "sizeUnit": "GiB",
                        "summarizeBy": "COUNT",
                        "templateWorksheetName": "__2D_COUNT__",
                        "useCustomViewTemplate": "true",
                        "verticalFieldProperties": null,
                        "verticalFieldProperties2": null,
                        "verticalFieldType": "IMAGE_HEIGHT",
                        "verticalFieldType2": "NONE"
                    }
                },
                {
                    "item": "Irregular items",
                    "reportView": {
                        "horizontalFieldProperties": null,
                        "horizontalFieldType": "PROCESSING_STAGE",
                        "sizeUnit": "GiB",
                        "summarizeBy": "COUNT",
                        "templateWorksheetName": "__2D_COUNT__",
                        "useCustomViewTemplate": "true",
                        "verticalFieldProperties": null,
                        "verticalFieldProperties2": null,
                        "verticalFieldType": "IRREGULAR",
                        "verticalFieldType2": "TYPE"
                    }
                },
                {
                    "item": "Exclusion by type",
                    "reportView": {
                        "horizontalFieldType": "NONE",
                        "processingStage": "Material",
                        "sizeUnit": "GiB",
                        "summarizeBy": "COUNT_AND_SIZE",
                        "templateWorksheetName": "__SINGLE_COUNT_SIZE__",
                        "useCustomViewTemplate": "true",
                        "verticalFieldProperties": null,
                        "verticalFieldProperties2": null,
                        "verticalFieldType": "EXCLUSION",
                        "verticalFieldType2": "TYPE"
                    }
                },
                {
                    "item": "Source Data",
                    "reportView": {
                        "horizontalFieldProperties": null,
                        "horizontalFieldType": "NONE",
                        "processingStage": "Source data",
                        "sizeUnit": "GiB",
                        "templateWorksheetName": "__METADATA__",
                        "useCustomViewTemplate": "false",
                        "verticalFieldProperties": {
                            "entry": [
                                {
                                    "item": [
                                        "Sort order",
                                        "Evidence order (ascending)"
                                    ]
                                },
                                {
                                    "item": [
                                        "Profile",
                                        "Default"
                                    ]
                                }
                            ]
                        },
                        "verticalFieldProperties2": null,
                        "verticalFieldType": "METADATA",
                        "verticalFieldType2": "NONE"
                    }
                },
                {
                    "item": "Export Errors",
                    "reportView": {
                        "horizontalFieldProperties": null,
                        "horizontalFieldType": "NONE",
                        "processingStage": "Export Errors",
                        "sizeUnit": "GiB",
                        "templateWorksheetName": "__METADATA__",
                        "useCustomViewTemplate": "false",
                        "verticalFieldProperties": {
                            "entry": [
                                {
                                    "item": [
                                        "Sort order",
                                        "Evidence order (ascending)"
                                    ]
                                },
                                {
                                    "item": [
                                        "Profile",
                                        "Default"
                                    ]
                                }
                            ]
                        },
                        "verticalFieldProperties2": null,
                        "verticalFieldType": "METADATA",
                        "verticalFieldType2": "NONE"
                    }
                }
            ]
        },
        "scope": null
    },
    "useCase": {
        "attemptReopenIfLocked": "false",
        "attemptTimeout": "1",
        "caseBackend": "CLASSIC",
        "caseLocation": null,
        "caseType": "SIMPLE",
        "disabled": "false",
        "elasticProperties": {
            "item-array": [
                {
                    "item": [
                        "cluster.name",
                        "localhost"
                    ]
                },
                {
                    "item": [
                        "nuix.transport.hosts",
                        "127.0.0.1"
                    ]
                },
                {
                    "item": [
                        "index.number_of_shards",
                        null
                    ]
                },
                {
                    "item": [
                        "index.number_of_replicas",
                        null
                    ]
                }
            ]
        },
        "method": "OPEN_CREATE",
        "migrateCaseIfRequired": "false",
        "notes": null,
        "predefined": "true",
        "timeZoneId": "America/New_York"
    },
    "configureRelativityConnection": {
        "notes": null,
        "disabled": "false",
        "hostname": "{relativity_hostname}",
        "username": "{relativity_username}",
        "password": "{relativity_password}",
        "endpointType": "HTTPS",
        "importThreads": "1",
        "metadataThreads": "8",
        "importThreadTimeout": "3600",
        "importThreadRetries": "3",
        "patchInvalidEntries": "true",
        "useCustomClientVersion": "false",
        "customClientVersion": null,
        "serviceEndpoint": "/relativitywebapi"
    },
    "setRelativityWorkspace": {
        "workspaceIdentifierType": "NAME",
        "workspaceIdentifier": null,
        "folderPath": null,
        "createPathIfNotExists": "true",
        "cloneIfNotExists": false
    },
    "useDiscoverCase": {
        "notes": null,
        "disabled": "false",
        "hostname": "ringtail.us.nuix.com",
        "apiToken": "{discover_api_token_protected}",
        "caseIdentifierType": "NAME",
        "caseIdentifier": "Automate_{case_name}",
        "discoverFileRepositoryType": "AMAZON_S3",
        "windowsFileRepository": "\\\\AIO.DISCOVER.local\\Repository\\Import\\{discover_case_name}",
        "cloneCaseIfNotExists": "false",
        "clonedCaseDescription": "Case created by workflow",
        "cloneSourceCaseIdentifier": "Template Case",
        "clonedCaseName": "",
        "cloneSourceCaseIdentifierType": "NAME",
        "clonedCaseOrgIdentifier": "",
        "clonedCaseOrgIdentifierType": "NAME",
        "clonedCaseScheduleMetrics": "false",
        "waitForCaseToBeActive": "false",
        "waitForCaseToBeActiveTimeout": "60",
        "assignUserToCase": "false",
        "assignUserToCaseCategory": "ADMINISTRATOR",
        "assignUserToGroup": "false",
        "caseUserGroupIdentifierType": "NAME",
        "caseUserGroupIdentifier": "Case Manager"
    },
    "promoteToDiscover": {
        "notes": null,
        "disabled": "false",
        "productionSetName": "{last_production_set_name}",
        "metadataProfileEnabled": "false",
        "metadataProfile": "Default",
        "mailTypeEnabled": "true",
        "mailType": "HTML",
        "exportSchemeEnabled": "false",
        "exportScheme": "LEAVE_ATTACHMENTS_ON_EMAILS",
        "runIndexingInDiscover": "true",
        "runDeduplicationInDiscover": "false",
        "documentIdType": "DISCOVER_SEQUENTIAL",
        "level": "Imports",
        "documentsPerLevel": "1000",
        "exportFileTypeNative": "true",
        "exportFileTypeText": "false",
        "exportFolder": "{case_folder}/Export/{export_production_set_name}_{date_time}",
        "exportSplitAt": "10000",
        "waitForDiscoverJobToFinish": "false",
        "tagFailedItems": "true",
        "failedItemTagName": "Automate|Failed Items|Promote to Discover|{export_production_set_name}",
        "untagSuccessfulItems": "true"
    },
    "ocr": {
        "notes": null,
        "disabled": "false",
        "useOcrProfile": "true",
        "ocrProfileName": "Default",
        "scope": "mime-type:application/pdf AND NOT content:*",
        "replaceText": "true",
        "quality": "HIGH_QUALITY",
        "rotation": "AUTO",
        "deskew": "true",
        "languages": {
            "item": [
                "English",
                "French"
            ]
        },
        "updatePdf": "false",
        "updateDuplicateItems": "false",
        "clearCacheOnCompletion": "true",
        "customCacheDirectory": null,
        "timeoutMinutes": "90",
        "tagFailedItems": "true",
        "failedItemTagName": "Automate|Failed Items|OCR",
        "untagSuccessfulItems": "true"
    },
    "generateDuplicateCustodiansField": {
        "notes": null,
        "disabled": "false",
        "resultsCsvPath": "{case_folder}/Export/Duplicate Custodians {date_time}.csv",
        "fieldsInclusiveness": "ALTERNATE",
        "separator": ";",
        "originalFieldDocIds": "false"
    },
    "searchAndTag": {
        "notes": null,
        "disabled": "false",
        "scope": "flag:audited",
        "identifyFamilies": "false",
        "identifyDescendants": "false",
        "identifyExclusiveHits": "false",
        "computeSize": "false",
        "computeTotals": "false",
        "assignTags": "true",
        "alwaysCreateTags": "true",
        "removePreviousTags": "false",
        "removePreviousMethod": "RENAME",
        "searches": {
            "item-array": [
                {
                    "item": [
                        "KW 01",
                        "Plan*"
                    ]
                },
                {
                    "item": [
                        "KW 02",
                        "Non-Conform*"
                    ]
                }
            ]
        },
        "tagPrefix": "{search_tag_prefix}",
        "writeReport": "true",
        "reportLocation": "{case_folder}/Reports/{case_name} - Search Report {date_time}.xlsx",
        "customReportTemplatePath": null,
        "keywordsFileName": null,
        "useKeywordsFile": "true",
        "searchExcludedItems": "true",
        "searchContent": "true",
        "searchProperties": "true",
        "searchNames": "true",
        "searchPathNames": "true",
        "searchEvidenceMetadata": "true",
        "searchCustomMetadata": "true",
        "defaultNuixSearchLocations": "true"
    }
}

const parameter = {
    name: "",
    description: "",
    regex: "",
    friendlyName: "",
    value: ""
}

const workflow = {
    name: "",
    version: "1.0.0",
    operations: []
}

export function createWorkflow() {
    return deepCopy(workflow)
}

export function createOperation(name) {
    return deepCopy(operations[name]);
}

export function createParameter() {
    return deepCopy(parameter)
}