import {useEffect as useReactEffect, useState as useReactState} from "react";
import {axiosInstance} from "../api/AxiosProxy";

const reactHooks = [useReactEffect, useReactState];

class WorkflowTemplateIconModel {

    constructor(getIconFn) {
        this.getIcon = getIconFn;
        this.iconMap = {};
    }

    useWorkflowIcon(key) {
        let [useEffect, useState] = reactHooks;
        let iconMap = this.iconMap;

        const [icon, setIcon] = useState(iconMap[key]);
        // If icon is cached, iconMap[key] will contain a URL string to it's blob, else not cached
        useEffect(() => {
            // Call for icon
            if (iconMap[key] !== undefined) {
                setIcon(iconMap[key]);
            } else {
                this.getIcon(key, {mimeType: 'image/svg+xml'}, {responseType: 'blob'}).then(response => {
                    const {status, data} = response;
                    if (status === 200) {
                        const iconBlob = new Blob([data], {type: 'image/svg+xml'})
                        iconMap[key] = URL.createObjectURL(iconBlob);
                        setIcon(iconMap[key]);
                    } else {
                        iconMap[key] = null;
                        setIcon(null);
                    }
                });
            }
        }, [key, iconMap[key] == null]);

        return icon;
    }

    clearIcon(id) {
        window.URL.revokeObjectURL(this.iconMap[id]);
        delete this.iconMap[id];
    }

    clearIcons(map) {
        for (let i = 0; i < map.length; i++) {
            const workflow = map[i];
            window.URL.revokeObjectURL(this.iconMap[workflow.id]);
            delete this.iconMap[workflow.id];
        }
    }
}

export const workflowIconModel = new WorkflowTemplateIconModel((id) => {
    return axiosInstance.get(`/scheduler/library/workflow/${id}/icon`)
})