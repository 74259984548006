import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {selectNoticeComments, UserNoticeBody} from "./UserNoticeDisplay";
import {details} from "../../utilities/constants";
import {all, call, put} from "redux-saga/effects";
import SchedulerModel from "../../models/scheduler/SchedulerModel";
import {contextCall} from "../../saga/sagaFunctions";
import UserNoticeModel, {UserNoticeApi, UserNoticeSaga} from "../../models/notice/UserNoticeModel";
import ReduxStateModel from "../../models/scheduler/ReduxStateModel";
import {UserSaga} from "../../models/user/UserModel";
import {LegalHoldSaga} from "../../models/legalhold/LegalHoldModel";
import {UserNoticeHeader} from "../legalhold/UserNoticeTablet";
import {userIconModel} from "../../models/generics/IconModel";


function UserNoticePage() {
    const dispatch = useDispatch();

    const locationSplit = useLocation().pathname.split('/');
    const noticeId = locationSplit[locationSplit.length - 2];
    const userNoticeId = locationSplit[locationSplit.length - 1];

    const isLoading = useSelector(state => !state.hasLoaded[details.USER_NOTICES] || !state.hasLoaded[userNoticeId]);

    const userNotice = useSelector(state => state.userNoticeDetailsMap.get(userNoticeId)) || {};
    const comments = useSelector(state => selectNoticeComments(state, {userNoticeId}));

    const {
        userId,
        legalHoldId,
        surveyFormOptions
    } = userNotice;

    const {name: legalHoldName} = useSelector(state => state.legalHoldDetailsMap.get(legalHoldId)) || {};

    const recipient = useSelector(state => state.userDetailsMap.get(userId)) || {};
    const recipientIcon = userIconModel.useIcon(recipient.name);

    // Query for userNotice settings
    useEffect(() => {
        if (!noticeId || !userNoticeId)
            return;

        // Query userNotice + query for settings
        const noticeQueryEffect = call(function* () {
            try {
                const [{data: noticeData}] = yield all([
                    contextCall(UserNoticeApi, 'get', noticeId, userNoticeId),
                    contextCall(UserNoticeSaga, 'querySettings', {payload: {noticeId, id: userNoticeId}}),
                    contextCall(UserSaga, 'queryDetails'),
                    contextCall(LegalHoldSaga, 'queryDetails')
                ]);

                yield put(UserNoticeModel.actionCreators.addDetails(noticeData));
            } finally {
                yield put(ReduxStateModel.actionCreators.setHasLoaded(details.USER_NOTICES));
            }
        });

        dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(noticeQueryEffect));
    }, [noticeId, userNoticeId, dispatch]);



    if (!noticeId || !userNoticeId || !userNotice)
        return null;

    return (
        <LoadingWrapper isLoading={isLoading}>
            <UserNoticeHeader userNotice={userNotice} type={userNotice.type} subject={userNotice.subject}/>

            <div className="work-tablet__body">
                <UserNoticeBody legalHoldName={legalHoldName} userNotice={userNotice} surveyFormOptions={surveyFormOptions} comments={comments}
                    recipientName={recipient.displayName || recipient.name} recipientIcon={recipientIcon} isReadOnly/>
            </div>
        </LoadingWrapper>
    )
}

export default UserNoticePage;