import FilterModel from "../generics/FilterModel";

class ClientPoolFilterModel extends FilterModel {

    static nom = 'ClientPoolFilterModel';
    static actions = ClientPoolFilterModel.buildActions('CLIENT_POOL');
    static actionCreators = ClientPoolFilterModel.buildActionCreators(ClientPoolFilterModel.actions);
    static reducer = ClientPoolFilterModel.buildReducer(ClientPoolFilterModel.actions);

    constructor(model = {}) {
        super(model);
    }
}

export default ClientPoolFilterModel;