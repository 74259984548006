import React from "react";

import '../../../wizard-general.css'

import {connect} from "react-redux";
import QuestionRadio from "../../Question/QuestionRadio/QuestionRadio";
import TimezoneDropdown from "../../Dropdown/TimezoneDropdown/TimezoneDropdown";
import {updateGenericValues} from "../../../redux/WizardSlice/wizardSlice";
import {useTranslation} from "react-i18next";
import {ProfileQuestionSwitch} from "../../Question/QuestionSwitch/QuestionSwitch";
import {createInputHandler, createValueHandler} from "../../../../utilities/componentFunctions";
import {createToggleHandler} from "../../../../components/common/Checkbox/helpers";
import {createDropdownHandler} from "../../../../components/common/Dropdown/helpers";
import WizardFileDropdown, {fileTypeFilter} from "../../Dropdown/FileDropdown/WizardFileDropdown";
import {libraryFileTypeKeys} from "../../../../i18next/keys";

function WorkflowInformation(props) {
    const {updateState, files} = props;
    const {t} = useTranslation(['wizard']);

    const {
        timezoneRadio,
        timezoneSelect,
        useConfigurationProfile,
        useProcessingProfile,
        configurationProfilePath,
        processingProfilePath
    } = props;

    const inputHandler = createInputHandler({
        handler: updateState
    });
    const toggleEnable = createToggleHandler({
        handler: updateState
    });
    const dropdownHandler = createDropdownHandler({
        handler: updateState
    })
    const valuesHandler = createValueHandler({
        handler: updateState
    })

    const questions = [
        {
            question: t('wizard:questions.timezone.setCaseTimezone'),
            options: [
                {id: 1, value: t('wizard:questions.timezone.options.selectFromList')},
                {id: 2, value: t('wizard:questions.timezone.options.systemTimezone')},
                {id: 3, value: t('wizard:questions.timezone.options.prompAtExecution')}
            ]
        },
        {
            question: t('wizard:questions.timezone.profileSettings'),
            dataName: "profileSettings",
            options: [
                {
                    name: "useConfigurationProfile",
                    value: t('wizard:questions.timezone.useConfigurationProfile'),
                    toggled: useConfigurationProfile,
                },
                {
                    name: "useProcessingProfile",
                    value: t('wizard:questions.timezone.useProcessingProfile'),
                    toggled: useProcessingProfile,
                }
            ],
            components: [
                {
                    id: "configurationProfilePathDropdown",
                    name: 'configurationProfilePath',
                    isRequired: useConfigurationProfile,
                    placeholder: t('wizard:questions.timezone.configurationProfilePlaceholder'),
                    filter: libraryFile => fileTypeFilter(libraryFile, libraryFileTypeKeys.CONFIGURATION_PROFILE),
                    value: configurationProfilePath,
                    onFileSelected: dropdownHandler,
                    selectedFiles: [configurationProfilePath],
                    files,
                    noFileWarning: t('wizard:questions.timezone.configurationProfileWarning'),
                },
                {
                    id: "processingProfilePathDropdown",
                    name: 'processingProfilePath',
                    isRequired: useProcessingProfile,
                    placeholder: t('wizard:questions.timezone.processingProfilePlaceholder'),
                    filter: libraryFile => fileTypeFilter(libraryFile, libraryFileTypeKeys.PROCESSING_PROFILE),
                    value: processingProfilePath,
                    onFileSelected: dropdownHandler,
                    selectedFiles: [processingProfilePath],
                    files,
                    noFileWarning: t('wizard:questions.timezone.processingProfileWarning'),
                }
            ]
        }
    ]

    return (
        <div className={"wizard-step-container"}>

            <QuestionRadio
                question={questions[0].question}
                dataName={"timezoneRadio"}
                options={questions[0].options}
                selectedValue={timezoneRadio}
                onChange={valuesHandler} />

            {timezoneRadio === 1 &&
            <div className={"display-item"}>
                <label className="label is-heading">
                    Select a timezone
                </label>
                <div className={'settings-table no-border tight-padding'} style={{border: '1px solid transparent'}}>
                    <div className="table-row-group">
                        <div className="settings-row">
                            <div className="table-cell">
                                <TimezoneDropdown
                                    selectedTimezoneId={timezoneSelect}
                                    onTimezoneSelect={dropdownHandler}
                                    isRequired={(timezoneRadio === 1)}
                                    isDisabled={!(timezoneRadio === 1)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }

            <ProfileQuestionSwitch
                question={questions[1].question}
                dataName={questions[1].dataName}
                options={questions[1].options}
                values={questions[1].components}
                ProfileComponent={WizardFileDropdown}
                onClick={toggleEnable}
                onChange={inputHandler}/>
        </div>
    );
}

function mapStateToProps(state) {
    const {wizardSlice: {workflowInformation: {timezoneRadio, timezoneSelect, useConfigurationProfile, useProcessingProfile, configurationProfilePath, processingProfilePath}}} = state;
    return {
        timezoneRadio,
        timezoneSelect,
        useConfigurationProfile,
        useProcessingProfile,
        configurationProfilePath,
        processingProfilePath
    };
}

function mapDispatchToProps (dispatch) {
    return {
        updateState: updates => dispatch(updateGenericValues({stateName: "workflowInformation", updates: updates}))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowInformation);