import {
    ActionType,
    DateFilterLevel,
    DateFilterType,
    DiscoverFileRepository,
    KeywordsType,
    ParameterType,
    timezoneRegex
} from "./constants";
import {
    addConfigurationParameter,
    addOperation,
    removeOperation,
    removeParameter,
    updateOperation
} from "../redux/WorkflowHandler/workflowHandlerSlice";
import {getSystemDefaultTimezone, trimBaseFolder} from "./helperFunctions";


export function handleWorkflowValues (dispatch, t, values, actionType, operationMap) {
    if (actionType === ActionType.NEXT) {
        // Create configuration operations
        createConfigurationOperations(dispatch, values)

        addConstantConfigurationParameter(dispatch, t,"{local_worker_count}", "", 2);
        addConstantConfigurationParameter(dispatch, t,"{local_worker_memory}", "", 2048);

        let selectedValue = "";

        // Set the timezone parameter from the values parameter
        switch (values.timezoneRadio) {
            case 1:
            default: // Default
                selectedValue = values["timezoneSelect"];
                addConstantConfigurationParameter(dispatch, t,"{case_timezone_id}", timezoneRegex, selectedValue);
                break;
            case 2: // Prompt
                selectedValue = getSystemDefaultTimezone();
                addConstantConfigurationParameter(dispatch, t,"{case_timezone_id}", timezoneRegex, selectedValue);
                break;
            case 3: // List
                selectedValue = getSystemDefaultTimezone();
                addPromptedConfigurationParameter(dispatch, t, "{case_timezone_id}", timezoneRegex, selectedValue);
                break;
        }

    } else if (actionType === ActionType.BACK) {
        removeOperations(dispatch, "configureParameters", operationMap, [])
        removeOperations(dispatch, "configureNuix", operationMap, [])
    }
}

export function handleUseCaseValues (dispatch, t, values, actionType, operationMap) {
    const parameters = ["{nuix_case_name}", "{base_folder}", "{nuix_case_path}"];
    if (actionType === ActionType.NEXT) {
        let baseFolder = "";
        let caseName = "";

        let properties = {};
        switch (values.caseStructure) {

            case 1:
                caseName = "{client_name}/{matter_name}"
                baseFolder = `${trimBaseFolder(values.baseFolderPath)}`;
                properties["caseLocation"] = "{base_folder}/{nuix_case_name}"

                addConstantConfigurationParameter(dispatch, t, "{nuix_case_name}", "", caseName)
                addConstantConfigurationParameter(dispatch, t, "{base_folder}", "", baseFolder)
                break;

            case 2:
                caseName = ""
                baseFolder = `${trimBaseFolder(values.baseFolderPath)}`;
                properties["caseLocation"] = "{base_folder}/{nuix_case_name}"

                addPromptedConfigurationParameter(dispatch, t, "{nuix_case_name}", "", caseName)
                addConstantConfigurationParameter(dispatch, t, "{base_folder}", "", baseFolder)
                break;

            case 3:
            default:
                let nuixCasePath = ""
                properties["caseLocation"] = "{nuix_case_path}"

                addPromptedConfigurationParameter(dispatch, t, "{nuix_case_path}", "", nuixCasePath)
                break;
        }

        dispatch(addOperation({name: "useCase", ...properties}));

    } else if (actionType === ActionType.BACK) {
        removeOperations(dispatch, "useCase", operationMap, parameters)
    }
}

export function handleIngestionValues (dispatch, t, values, actionType, operationMap) {
    if (values["dataStructure"] === 3) {
        return;
    }

    const parameters = ["{base_source_location}", "{source_dataset_id}", "{source_location}", "{source_evidence_name}", "{source_dataset}"];
    if (actionType === ActionType.NEXT) {

        let parameterSourceData = "";
        let parameterEvidenceName = "";
        let properties = {};

        switch (values.dataStructure) {
            case 1:
                parameterSourceData = trimBaseFolder(values.sourceDataFolder);
                parameterEvidenceName = values.datasetId;

                properties["sourceScope"] = "PATH_ITEM";
                properties["source"] = "{base_source_location}/{client_name}/{matter_name}/{source_dataset_id}";
                properties["evidenceName"] = "Evidence {source_dataset_id}";

                addConstantConfigurationParameter(dispatch, t, "{base_source_location}", "", parameterSourceData);
                addConstantConfigurationParameter(dispatch, t, "{source_dataset_id}", "", parameterEvidenceName);
                break;

            case 4:
                parameterSourceData = "";

                properties["sourceScope"] = "DATASET";
                properties["source"] = "{source_dataset}";
                properties["evidenceName"] = "Evidence {source_dataset}";

                addPromptedConfigurationParameter(dispatch, t, "{source_dataset}", "", parameterSourceData);
                break;

            default:
            case 2:
                parameterSourceData = "";
                parameterEvidenceName = "";

                properties["sourceScope"] = "PATH_ITEM";
                properties["source"] = "{source_location}";
                properties["evidenceName"] = "{source_evidence_name}";

                addPromptedConfigurationParameter(dispatch, t, "{source_location}", "", parameterSourceData);
                addPromptedConfigurationParameter(dispatch, t, "{source_evidence_name}", "", parameterEvidenceName);
                break;
        }

        dispatch(addOperation({name: "addEvidence", ...properties}));

        if (values.dataStructure === 4) {
            const custodianProps = {}
            custodianProps["scope"] = "(guid:{last_evidence_guid} OR path-guid:{last_evidence_guid})"
            dispatch(addOperation({name: "assignCustodian", ...custodianProps}))
        }

    } else if (actionType === ActionType.BACK) {
        removeOperations(dispatch, "addEvidence", operationMap, parameters)
        removeOperations(dispatch, "assignCustodian", operationMap, [])
    }
}

export function handleAdvancedIngestionValues(dispatch, t, values, actionType, operationMap) {

    let parameters = ["{filter_after_date}", "{filter_before_date}", "{search_tag_prefix}", "{exclude_tag_query}", "{keywords_file}"];
    if (actionType === ActionType.NEXT) {
        let properties = {};
        const {useDateFilter, deduplicateItems, dateFilterLevel, dateFilterType} = values;

        if (useDateFilter && dateFilterLevel === DateFilterLevel.CASE && dateFilterType !== DateFilterType.NONE) {
            const {filterAfterDate, filterBeforeDate, dateFilterAction, dateFilterActionName, dateFilterCriteria, dateFilterActionScope} = values;

            const dateRangeFilterProperties = {};
            dateRangeFilterProperties["filterActionType"] = dateFilterAction;
            dateRangeFilterProperties["actionName"] = dateFilterActionName;
            dateRangeFilterProperties["dateCriteria"] = dateFilterCriteria;
            dateRangeFilterProperties["dateFilterIntervalType"] = dateFilterType;
            dateRangeFilterProperties["dateAfter"] = filterAfterDate;
            dateRangeFilterProperties["dateBefore"] = filterBeforeDate;
            dateRangeFilterProperties["filterActionScope"] = dateFilterActionScope;

            dispatch(addOperation({name: "dateRangeFilter", ...dateRangeFilterProperties}))
        }

        const {keywordsSearch, keywordsType, keywordsFile} = values;

        if (keywordsSearch) {
            const keywordProperties = {};

            keywordProperties["keywordsFileName"] = "{keywords_file}";
            addPromptedConfigurationParameter(dispatch, t, "{keywords_file}", "", keywordsFile, {parameterType: 'FILE_CONTENTS'})

            if (keywordsType === KeywordsType.RETAIN) {
                addConstantConfigurationParameter(dispatch, t, "{search_tag_prefix}", "", "Searches|Retain")
                dispatch(addOperation({name: "searchAndTag", ...keywordProperties}))

            } else if (keywordsType === KeywordsType.EXCLUDE) {
                addConstantConfigurationParameter(dispatch, t, "{search_tag_prefix}", "", "Searches|Exclude")
                addConstantConfigurationParameter(dispatch, t, "{exclude_tag_query}", "", "tag:\"Searches|Exclude|*\"")
                dispatch(addOperation({name: "searchAndTag", ...keywordProperties}))
                createExcludeItem(dispatch, operationMap);
            }
        }

        if (deduplicateItems) {
            createItemSet(dispatch, values, operationMap);

        }

        if (useDateFilter && dateFilterLevel === DateFilterLevel.INGESTION) {
            const {filterAfterDate, filterBeforeDate, dateFilterScope} = values;

            switch (dateFilterType) {
                case DateFilterType.NOT_BETWEEN:
                case DateFilterType.BETWEEN:
                    addConstantConfigurationParameter(dispatch, t, "{filter_after_date}", "", filterAfterDate);
                    addConstantConfigurationParameter(dispatch, t, "{filter_before_date}", "", filterBeforeDate);
                    properties["dateFilterIntervalType"] = dateFilterType;
                    properties["dateFilterScope"] = dateFilterScope;
                    break;

                case DateFilterType.AFTER:
                    addConstantConfigurationParameter(dispatch, t, "{filter_after_date}", "", filterAfterDate);
                    properties["dateFilterIntervalType"] = dateFilterType;
                    properties["dateFilterScope"] = dateFilterScope;
                    break;

                case DateFilterType.BEFORE:
                    addConstantConfigurationParameter(dispatch, t, "{filter_before_date}", "", filterBeforeDate);
                    properties["dateFilterIntervalType"] = dateFilterType;
                    properties["dateFilterScope"] = dateFilterScope;
                    break;

                default:
                case DateFilterType.NONE:
                    // DO NOTHING
                    break;
            }
            dispatch(updateOperation({operation: "addEvidence", ...properties}));
        }

    } else if (actionType === ActionType.BACK) {
        const properties = {
            dateFilterIntervalType: "NONE",
            dateFilterScope: "TOP_LEVEL_COMMUNICATION"
        }

        dispatch(updateOperation({operation: "addEvidence", ...properties}));
        removeOperations(dispatch, "generateDuplicateCustodiansField", operationMap, []);
        removeOperations(dispatch, "excludeItems", operationMap, []);
        removeOperations(dispatch, "searchAndTag", operationMap, []);
        removeOperations(dispatch, "addToItemset", operationMap, []);
        removeOperations(dispatch, "dateRangeFilter", operationMap, [])
        removeParameters(dispatch, parameters);
    }
}

export function handleOcrValues (dispatch, t, values, actionType, operationsMap) {
    const properties = {};
    const parameters = ["{profile_name_ocr}"];

    if (actionType === ActionType.NEXT) {
        const {performOcr} = values;

        if (performOcr) {
            const {defineProfileType, ocrProfileName, deduplicateItems} = values;

            properties["scope"] = deduplicateItems ? 'item-set-batch:"{last_item_set_originals_batch}" AND mime-type:application/pdf AND NOT content:*' :
                'mime-type:application/pdf AND NOT content:*';

            switch (defineProfileType) {
                case 1:
                    properties["ocrProfileName"] = "Default";
                    break;

                case 2:
                    properties["ocrProfileName"] = ocrProfileName;
                    break;

                case 3:
                    properties["ocrProfileName"] = "{profile_name_ocr}"
                    addPromptedConfigurationParameter(dispatch, t, "{profile_name_ocr}", "", "");
            }

            dispatch(addOperation({name: "ocr", ...properties}))
        }

    } else if (actionType === ActionType.BACK) {
        removeOperations(dispatch, "ocr", operationsMap, [])
        removeParameters(dispatch, parameters)
    }
}

export function handleLegalExportValues(dispatch, t, values, actionType, operationMap) {
    const properties = {};
    let parameters = ["{relativity_fields_mapping}", "{ringtail_metadata_mapping}"]
    if (actionType === ActionType.NEXT) {
        createProductionSet(dispatch, operationMap, values);

        const {exportType, exportFiletypes} = values;
        properties["legalExportType"] = exportType;
        properties["exportFileTypeNative"] = exportFiletypes.exportNative;
        properties["exportFileTypeText"] = exportFiletypes.exportText;
        properties["exportFileTypePdf"] = exportFiletypes.exportPdf;
        properties["exportFileTypeImage"] = exportFiletypes.exportImage;
        properties["exportFileTypeXhtmlReport"] = exportFiletypes.exportXhtmlMetadataReport;
        properties["exportFileTypeThumbnail"] = exportFiletypes.exportThumbnailFile;

        const {fieldsMappingFileType, fieldsMappingFile} = values;
        if (exportType === "RELATIVITY") {

            if (fieldsMappingFileType === 1) {
                addConstantConfigurationParameter(dispatch, t, "{relativity_fields_mapping}", "", "");
                properties["relativityMappingLocation"] = "{relativity_fields_mapping}"

            } else if (fieldsMappingFileType === 2) {
                addPromptedConfigurationParameter(dispatch, t, "{relativity_fields_mapping}", "", "");
                properties["relativityMappingLocation"] = "{relativity_fields_mapping}"

            } else if (fieldsMappingFileType === 3) {
                properties["relativityMappingLocation"] = fieldsMappingFile;
            }
        }

        const {metadataMappingFileType, metadataMappingFile} = values;
        if (exportType === "RINGTAIL") {
            if (metadataMappingFileType === 1) {
                addConstantConfigurationParameter(dispatch, t, "{ringtail_metadata_mapping}", "", "");
                properties["metadataMappingFile"] = "{ringtail_metadata_mapping}"

            } else if (metadataMappingFileType === 2) {
                addPromptedConfigurationParameter(dispatch, t, "{ringtail_metadata_mapping}", "", "");
                properties["metadataMappingFile"] = "{ringtail_metadata_mapping}"

            } else if (metadataMappingFileType === 3) {
                properties["metadataMappingFile"] = metadataMappingFile;
            }
        }

        const {useMetadataProfile, metadataProfileName, useProductionProfile, productionProfilePath} = values;
        if (useMetadataProfile) {
            properties["metadataProfile"] = metadataProfileName;
        }

        if (useProductionProfile) {
            properties["productionProfile"] = productionProfilePath;
        }

        properties["productionSetName"] = "{last_production_set_name}"
        dispatch(addOperation({name: "legalExport", ...properties}));

    } else if (actionType === ActionType.BACK) {
        removeOperations(dispatch, "legalExport", operationMap, []);
        removeOperations(dispatch, "addToProductionSet", operationMap, []);
        removeParameters(dispatch, parameters);
    }
}

export function handleLegalExportNumbering (dispatch, t, values, actionType, operationMap) {
    const properties = {};
    if (actionType === ActionType.NEXT) {

        const {numberingProps, exportNumberingType} = values;
        properties["documentIdPrefix"] = numberingProps.prefix;
        properties["documentIdBoxStart"] = parseInt(numberingProps.boxStartAt);
        properties["documentIdBoxCycleFrom"] = parseInt(numberingProps.boxCycleFrom);
        properties["documentIdBoxCycleTo"] = parseInt(numberingProps.boxCycleTo);
        properties["documentIdFolderStart"] = parseInt(numberingProps.folderStartAt);
        properties["documentIdFolderCycleFrom"] = parseInt(numberingProps.folderCycleFrom);
        properties["documentIdFolderCycleTo"] = parseInt(numberingProps.folderCycleTo);
        properties["documentIdPageStart"] = parseInt(numberingProps.pageStartAt);
        properties["documentIdPageCycleFrom"] = parseInt(numberingProps.pageCycleFrom);
        properties["documentIdPageCycleTo"] = parseInt(numberingProps.pageCycleTo);
        properties["documentIdDigits"] = parseInt(numberingProps.numberingDigits);

        properties["prefixPageNumbering"] = (exportNumberingType === 4 || (numberingProps.numberingType === 2 && exportNumberingType === 1))
        properties["prefixFolderPageNumbering"] = (exportNumberingType === 5)
        properties["prefixBoxFolderPageNumbering"] = (exportNumberingType === 6)
        properties["filenameNumbering"] = (exportNumberingType === 3)
        properties["familyNumbering"] = (exportNumberingType === 2)

        properties["pageDelimiter"] = numberingProps.delimiter;
        properties["familySeparator"] = numberingProps.separator;
        properties["familyDigits"] = numberingProps.familyDigits;
        properties["continuedNumbering"] = (exportNumberingType === 1 && numberingProps.numberingType === 1)
        properties["startNumberingAt"] = parseInt(numberingProps.startNumberingAt);

        dispatch(updateOperation({operation: "addToProductionSet", ...properties}));
    } else if (actionType === ActionType.BACK) {
        const defaultValues = {
            continuedNumbering: true,
            documentIdBoxCycleFrom: 1,
            documentIdBoxCycleTo: 999,
            documentIdBoxStart: 1,
            documentIdDigits: 7,
            documentIdFolderCycleFrom: 1,
            documentIdFolderCycleTo: 999,
            documentIdFolderStart: 1,
            documentIdPageCycleFrom: 1,
            documentIdPageCycleTo: 9000,
            documentIdPageStart: 1,
            documentIdPrefix: null,
            familyDigits: 3,
            familyNumbering: false,
            familySeparator: ".",
            filenameNumbering: false,
            freezeProductionSet: false,
            pageDelimiter: ".",
            prefixBoxFolderPageNumbering: false,
            prefixFolderPageNumbering: false,
            prefixPageNumbering: false,
            startNumberingAt: 1
        }
        dispatch(updateOperation({operation: "addToProductionSet", defaultValues}));
    }
}

export function handleReportOperations (dispatch, t, values, actionType, operationMap) {
    // TODO: Will have more than one type of processing report to choose from
    const properties = {};
    if (actionType === ActionType.NEXT) {
        const {report} = values;
        const {generateDuplicateCustodians} = values;
        if (generateDuplicateCustodians) {
            createDuplicateCustodianFields(dispatch, operationMap)
        }

        if (report) {
            dispatch(addOperation({name: "report", ...properties}));
        }
    } else if (actionType === ActionType.BACK) {
        removeOperations(dispatch, "report", operationMap, []);
    }
}

export function handleRelativityValues (dispatch, t, values, actionType, operationMap) {

    const properties = {};
    const workspaceProperties = {};
    const legalExportProperties = {};
    let parameters = ["{relativity_username}", "{relativity_password}", "{relativity_hostname}", "{relativity_fields_mapping}", "{relativity_workspace_name}"];
    if (actionType === ActionType.NEXT) {

        const {defineUsernameExecution, definePasswordExecution, defineHostNameExecution, defineFieldsMappingExecution, definedWorkspace, workspaceIdentifier} = values;
        const {workspaceIdentifierType, folderPath, createIfNotExists, importThreads, metadataThreads, importThreadTimeout, importThreadRetries, serviceEndpoint, relativityRestVersion} = values;
        const realFolderPath = folderPath.length > 0 ? folderPath : (createIfNotExists ? "Upload_{date_time}" : "")

        // Production set
        createProductionSet(dispatch, operationMap)

        // Relativity settings
        if (!defineUsernameExecution) {
            addConstantConfigurationParameter(dispatch, t, "{relativity_username}", "", "")
        } else {
            addPromptedConfigurationParameter(dispatch, t, "{relativity_username}", "", "")
        }

        if (!definePasswordExecution) {
            addConstantConfigurationParameter(dispatch, t, "{relativity_password}", "", "")
        } else {
            addPromptedConfigurationParameter(dispatch, t, "{relativity_password}", "", "")
        }

        if (!defineHostNameExecution) {
            addConstantConfigurationParameter(dispatch, t, "{relativity_hostname}", "", "")
        } else {
            addPromptedConfigurationParameter(dispatch, t, "{relativity_hostname}", "", "")
        }

        if (!defineFieldsMappingExecution) {
            addConstantConfigurationParameter(dispatch, t, "{relativity_fields_mapping}", "", "")
        } else {
            addPromptedConfigurationParameter(dispatch, t, "{relativity_fields_mapping}", "", "")
        }

        switch (definedWorkspace) {
            case 1:
                addConstantConfigurationParameter(dispatch, t, "{relativity_workspace_name}", "", "")
                workspaceProperties["workspaceIdentifier"] = "{relativity_workspace_name}";
                workspaceProperties["workspaceIdentifierType"] = "NAME";
                break;

            case 2:
                workspaceProperties["workspaceIdentifier"] = workspaceIdentifier;
                workspaceProperties["workspaceIdentifierType"] = workspaceIdentifierType;
                break;

            case 3:
                addPromptedConfigurationParameter(dispatch, t, "{relativity_workspace_name}", "", "")
                workspaceProperties["workspaceIdentifier"] = "{relativity_workspace_name}";
                workspaceProperties["workspaceIdentifierType"] = "NAME";
                break;
        }

        properties["hostname"] = "{relativity_hostname}";
        properties["username"] = "{relativity_username}";
        properties["password"] = "{relativity_password}";



        properties["importThreads"] = importThreads;
        properties["metadataThreads"] = metadataThreads;
        properties["importThreadTimeout"] = importThreadTimeout;
        properties["importThreadRetries"] = importThreadRetries;
        properties["serviceEndpoint"] = serviceEndpoint;
        properties["relativityRestVersion"] = relativityRestVersion;

        workspaceProperties["folderPath"] = realFolderPath;

        dispatch(addOperation({name: "configureRelativityConnection", index: 3, ...properties}))
        dispatch(addOperation({name: "setRelativityWorkspace", index: 4, ...workspaceProperties}))

        // Legal export Settings
        legalExportProperties["legalExportType"] = "RELATIVITY";
        legalExportProperties["relativityMappingLocation"] = "{relativity_fields_mapping}"

        dispatch(updateOperation({operation: "legalExport", ...legalExportProperties}))
    } else if (actionType === ActionType.BACK) {
        removeOperations(dispatch, "setRelativityWorkspace", operationMap, [])
        removeOperations(dispatch, "configureRelativityConnection", operationMap, [])
        removeParameters(dispatch, parameters);
    }
}

export function handleDiscoverConfigurationValues(dispatch, t, values, actionType, operationMap) {
    const properties = {};
    const parameters = ["{discover_api_token_protected}"]

    if (actionType === ActionType.NEXT) {
        const {hostname, caseIdentifierType, discoverFileRepositoryType, apiToken, caseIdentifier} = values;

        properties["hostname"] = hostname;
        properties["discoverFileRepositoryType"] = discoverFileRepositoryType;
        properties["apiToken"] = "{discover_api_token_protected}";
        properties["caseIdentifier"] = caseIdentifier;
        properties["caseIdentifierType"] = caseIdentifierType;

        dispatch(addOperation({name: "useDiscoverCase", ...properties}))

    } else if (actionType === ActionType.BACK) {
        removeOperations(dispatch, "useDiscoverCase", operationMap, []);
        removeParameters(dispatch, parameters);
    }
}

export function handleDiscoverValues(dispatch, t, values, actionType, operationsMap) {

    const clonedDiscoverCaseProperties = {};
    const discoverCaseProperties = {};
    const discoverExportProperties = {};
    const parameters = ["{case_name_discover}, {discover_api_token_protected}"];

    if (actionType === ActionType.NEXT) {

        const {
            windowsFileRepository, caseIdentifier, deploymentType, deploymentUrl, caseNameType, createCaseFromTemplate,
            setConvertMail, sourceCaseName, clonedCaseOrganization, userToCase, caseGroupName, setAssignUserToCase,
            setAssignGroupToCase, mailType, defineTokenAtExecution
        } = values;

        createProductionSet(dispatch, operationsMap, values);

        // Check case name type
        switch (caseNameType) {
            case 1:
                clonedDiscoverCaseProperties["caseIdentifier"] = "{case_name}";
                discoverCaseProperties["caseIdentifier"] = "{case_name}";
                break;
            case 2:
                addConstantConfigurationParameter(dispatch, t, "{case_name_discover}", "", caseIdentifier);
                clonedDiscoverCaseProperties["caseIdentifier"] = caseIdentifier;
                discoverCaseProperties["caseIdentifier"] = caseIdentifier;
                break;
            case 3:
                addPromptedConfigurationParameter(dispatch, t, "{case_name_discover}", "", "");
                clonedDiscoverCaseProperties["caseIdentifier"] = "{case_name_discover}";
                discoverCaseProperties["caseIdentifier"] = "{case_name_discover}";
                break;
        }

        if (defineTokenAtExecution) {
            addPromptedConfigurationParameter(dispatch, t, "{discover_api_token_protected}", "", "");
        }

        // If the user clones
        if (createCaseFromTemplate === "TEMPLATE") {
            // Case after Nuix Case
            clonedDiscoverCaseProperties["hostname"] = deploymentUrl;
            clonedDiscoverCaseProperties["apiToken"] = "{discover_api_token_protected}";
            clonedDiscoverCaseProperties["cloneCaseIfNotExists"] = true;
            if (deploymentType === 1) {
                clonedDiscoverCaseProperties["discoverFileRepositoryType"] = DiscoverFileRepository.AMAZON_S3;
            } else if (deploymentType === 2) {
                clonedDiscoverCaseProperties["discoverFileRepositoryType"] = DiscoverFileRepository.WINDOWS_FILE_SHARE;
                clonedDiscoverCaseProperties["windowsFileRepository"] = windowsFileRepository;
            }
            clonedDiscoverCaseProperties["clonedCaseName"] = "{case_name_discover}";
            clonedDiscoverCaseProperties["cloneSourceCaseIdentifier"] = sourceCaseName;
            clonedDiscoverCaseProperties["clonedCaseOrgIdentifier"] = clonedCaseOrganization;


            // Case before Promote to Discover
            discoverCaseProperties["hostname"] = deploymentUrl;
            discoverCaseProperties["apiToken"] = "{discover_api_token_protected}";
            discoverCaseProperties["caseIdentifierType"] = "ID"
            discoverCaseProperties["caseIdentifier"] = "{discover_case_id}"
            if (deploymentType === 1) {
                clonedDiscoverCaseProperties["discoverFileRepositoryType"] = DiscoverFileRepository.AMAZON_S3;
            } else if (deploymentType === 2) {
                clonedDiscoverCaseProperties["discoverFileRepositoryType"] = DiscoverFileRepository.WINDOWS_FILE_SHARE;
                clonedDiscoverCaseProperties["windowsFileRepository"] = windowsFileRepository;
            }
            discoverCaseProperties["cloneCaseIfNotExists"] = false;
            discoverCaseProperties["waitForCaseToBeActive"] = true;
            discoverCaseProperties["waitForCaseToBeActiveTimeout"] = 60;
            if (setAssignUserToCase) {
                discoverCaseProperties["assignUserToCase"] = true;
                discoverCaseProperties["assignUserToCaseCategory"] = userToCase;
            }

            if (setAssignGroupToCase) {
                discoverCaseProperties["assignUserToGroup"] = true;
                discoverCaseProperties["caseUserGroupIdentifier"] = caseGroupName;
            }

            dispatch(addOperation({name: "useDiscoverCase", index: 3, ...clonedDiscoverCaseProperties}))
        } else {
            // If the user does not clone
            discoverCaseProperties["hostname"] = deploymentUrl;
            discoverCaseProperties["apiToken"] = "{discover_api_token_protected}";
            discoverCaseProperties["caseIdentifierType"] = "NAME"
            if (deploymentType === 1) {
                clonedDiscoverCaseProperties["discoverFileRepositoryType"] = DiscoverFileRepository.AMAZON_S3;
            } else if (deploymentType === 2) {
                clonedDiscoverCaseProperties["discoverFileRepositoryType"] = DiscoverFileRepository.WINDOWS_FILE_SHARE;
                clonedDiscoverCaseProperties["windowsFileRepository"] = windowsFileRepository;
            }
            discoverCaseProperties["cloneCaseIfNotExists"] = false;
            discoverCaseProperties["waitForCaseToBeActive"] = (setAssignUserToCase || setAssignGroupToCase);
            discoverCaseProperties["waitForCaseToBeActiveTimeout"] = 60;
            if (setAssignUserToCase) {
                discoverCaseProperties["assignUserToCase"] = true;
                discoverCaseProperties["assignUserToCaseCategory"] = userToCase;
            }

            if (setAssignGroupToCase) {
                discoverCaseProperties["assignUserToGroup"] = true;
                discoverCaseProperties["caseUserGroupIdentifier"] = caseGroupName;
            }
        }

        // Check if convert mail is selected
        if (setConvertMail) {
            discoverExportProperties["mailType"] = mailType;
        }

        // Add Discover case ( wait for case if it was cloned )
        dispatch(addOperation({name: "useDiscoverCase", ...discoverCaseProperties}))
        dispatch(addOperation({name: "promoteToDiscover", ...discoverExportProperties}))

    } else if (actionType === ActionType.BACK) {
        removeOperations(dispatch, "useDiscoverCase", operationsMap, []);
        const updatedOperations = updateOperationsMap(operationsMap, "useDiscoverCase");

        removeOperations(dispatch, "promoteToDiscover", updatedOperations, []);
        updateOperationsMap(operationsMap, "promoteToDiscover")

        removeParameters(dispatch, parameters);
    }
}

function createConfigurationOperations (dispatch, values) {
    const {useConfigurationProfile, configurationProfilePath, useProcessingProfile, processingProfilePath} = values

    let configureParameterProperties = {};
    let configureNuixProperties = {};

    configureParameterProperties["notes"] = "Workflow Parameters";
    configureNuixProperties["notes"] = "Nuix Settings"

    configureNuixProperties["requireFilesInExecutionProfile"] = false;

    if (useConfigurationProfile) {
        configureNuixProperties["parallelProcessingSettingsSource"] = "PROFILE";
        configureNuixProperties["useConfigurationProfile"] = true;
        configureNuixProperties["profileFilePath"] = configurationProfilePath;
    }

    if (useProcessingProfile) {
        configureNuixProperties["parallelProcessingSettingsSource"] = "PROFILE";
        configureNuixProperties["useProcessingProfile"] = true;
        configureNuixProperties["processingProfileName"] = processingProfilePath;
    }

    dispatch(addOperation({name: "configureParameters", ...configureParameterProperties}));
    dispatch(addOperation({name: "configureNuix", ...configureNuixProperties}))
}

export function handleWorkflowRequiredFiles (dispatch, values, operationsMap) {
    const {ocrProfileName, useConfigurationProfile, useProcessingProfile, useMetadataProfile, useProductionProfile} = values;

    const customOcrProfile = (!!ocrProfileName && ocrProfileName !== "Default");

    if (customOcrProfile || useConfigurationProfile || useProcessingProfile || useMetadataProfile || useProductionProfile) {
        const configurationProperties = {};
        configurationProperties["requireFilesInExecutionProfile"] = true;
        dispatch(updateOperation({operation: "configureNuix", ...configurationProperties}))
    } else {
        const configurationProperties = {};
        configurationProperties["requireFilesInExecutionProfile"] = false
        dispatch(updateOperation({operation: "configureNuix", ...configurationProperties}))
    }
}

function createExcludeItem (dispatch, operationMap) {
    const excludeProperties = {};
    excludeProperties["scope"] = "flag:audited";
    dispatch(addOperation({name: "excludeItems", ...excludeProperties}))
}

function createDuplicateCustodianFields (dispatch, operationMap) {
    dispatch(addOperation({name: "generateDuplicateCustodiansField"}))
}

function createProductionSet (dispatch, operationMap) {
    const existingOperation = getOperationIndex("addToItemset", operationMap);
    const scopeQuery = existingOperation.length > 0 ? "item-set-batch:\"{last_item_set_originals_batch}\"" : "flag:audited";

    const productionExists = getOperationIndex("addToProductionSet", operationMap);

    if (productionExists.length === 0) {
        const productionSetProperties = {};
        productionSetProperties["productionSetName"] = "Production {date_time}";
        productionSetProperties["documentIdPrefix"] = "DOC_"
        productionSetProperties["scope"] = scopeQuery;

        dispatch(addOperation({name: "addToProductionSet", ...productionSetProperties}))
    }
}


function createItemSet (dispatch, values, operationMap) {
    const existingOperation = getOperationIndex("addEvidence", operationMap);
    const scopeQuery = existingOperation.length > 0 ? "batch-load-guid:{last_batch_load_guid}" : "flag:audited";

    // MD5_PER_CUSTODIAN
    const itemSetProperties = {};
    itemSetProperties["itemSetName"] = "All Items";
    itemSetProperties["batchName"] = "{date_time}";
    itemSetProperties["scope"] = scopeQuery;

    dispatch(addOperation({name: "addToItemset", ...itemSetProperties}));
}

// Configuration helpers
function getFriendlyName (name, t) {
    const translationId = parameterKeys[name];
    return t(`wizard:parameters.friendlyNames.${translationId}`);
}

function getDescription (name, t) {
    const translationId = parameterKeys[name];
    return t(`wizard:parameters.descriptions.${translationId}`);
}

function updateOperationsMap (operationsMap, name) {
    let indexKey = 0;
    const updatedOperationsMap = {};
    const operationIndexes = getOperationIndex(name, operationsMap)

    for (const [key, value] of Object.entries(operationsMap)) {
        if (!operationIndexes.includes(key)) {
            updatedOperationsMap[indexKey] = value;
            indexKey++;
        }
    }

    return updatedOperationsMap;
}

function addConstantConfigurationParameter (dispatch, t, name, regex, value) {
    dispatch(addConfigurationParameter({
        name: name,
        description: getDescription(name, t),
        regex: regex,
        friendlyName: getFriendlyName(name, t),
        value: value,
        type: ParameterType.CONSTANT
    }));
}

function addPromptedConfigurationParameter (dispatch, t, name, regex, value, {parameterType}={}) {
    dispatch(addConfigurationParameter({
        name: name,
        description: getDescription(name, t),
        regex: regex,
        friendlyName: getFriendlyName(name, t),
        value: value,
        type: ParameterType.PROMPTED,
        parameterType
    }));
}

function removeOperations (dispatch, name, operationMap, parameters) {
    const existingOperations = getOperationIndex(name, operationMap);

    if (existingOperations.length > 0) {
        dispatch(removeOperation({operations: existingOperations, parameters: parameters}))
    }
}

function removeParameters (dispatch, parameters) {
    if (parameters.length > 0) {
        dispatch(removeParameter({parameters: parameters}));
    }
}

function getOperationIndex (name, operationMap) {
    return Object.keys(operationMap).filter((value, index) => {
        if (operationMap[value] === name) {
            return value;
        }
        return null;
    });
}

const parameterKeys = {
    "{case_timezone_id}": 'caseTimezoneId',
    "{base_folder}": 'baseFolder',
    "{nuix_case_name}": 'nuixCaseName',
    "{nuix_case_path}": "nuixCasePath",
    "{base_source_location}": 'baseSourceLocation',
    "{source_dataset_id}": 'sourceDatasetId',
    "{source_dataset}": 'sourceDataset',
    "{source_location}": 'sourceLocation',
    "{source_evidence_name}": 'sourceEvidenceName',
    "{filter_after_date}": 'filterAfterDate',
    "{filter_before_date}": 'filterBeforeDate',
    "{relativity_hostname}":"relativityHostName",
    "{relativity_username}":"relativityUsername",
    "{relativity_password}":"relativityPassword",
    "{relativity_fields_mapping}":"relativityFieldsMapping",
    "{relativity_workspace_name}":"relativityWorkspaceName",
    "{discover_api_token_protected}":"discoverApiToken",
    "{ringtail_metadata_mapping}":"ringtailMetadataMapping",
    "{case_name_discover}": "discoverCaseName",
    "{discover_case_name}": "discoverCaseName",
    "{profile_name_ocr}": "ocrProfileName",
    "{search_tag_prefix}": "searchTagPrefix",
    "{exclude_tag_query}": "excludeTagQuery",
    "{keywords_file}": "keywordsFile",
    "{local_worker_count}": "localWorkerCount",
    "{local_worker_memory}": "localMemoryCount"
}

