import React from 'react'
import {useTranslation} from "react-i18next";
import {DateFilterType} from "../../../utilities/constants";
import {DateTimeInput} from "../../Text/DateInput/DateInput";
import HTMLTextInput from "../../../../components/common/HTMLTextInput/HTMLTextInput";

export function QuestionInput (props) {
    const {name, question, value, onChange, isRequired} = props;

    const inputProps = {
        name,
        value,
        onChange,
        isRequired,
        isDisabled: false
    };

    return (
        <div className={"display-item"}>
            <label className="label is-heading">
                {question}
            </label>
            <div className={'settings-table no-border tight-padding'} style={{border: '1px solid transparent'}}>
                <div className="table-row-group">
                    <div key={name} className="settings-row">
                        <div key={name} className="table-cell no-stretch">
                            <HTMLTextInput {...inputProps}/>
                        </div>
                        <div key={`${name}_2`} className="table-cell no-stretch"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function QuestionIngestionInput (props) {
    const {t} = useTranslation(['wizard']);
    const {id, question, onChange, sourceDataValue, datasetValue, isRequired, isDisabled} = props;

    const sourceDataInputProps = {
        name: "sourceDataFolder",
        value: sourceDataValue,
        onChange: onChange,
        isRequired: isRequired,
        isDisabled: isDisabled,
        placeholder: t('wizard:questions.ingestion.sourceDataInputPlaceholder')
    };

    const datasetInputProps = {
        name: "datasetId",
        value: datasetValue,
        onChange: onChange,
        isRequired: isRequired,
        isDisabled: isDisabled,
        placeholder: t('wizard:questions.ingestion.datasetIdInputPlaceholder')
    };

    const cellStyle = {
        border: "0.5rem solid transparent"
    }

    return (
        <div className={"display-item"}>
            <label className="label is-heading">
                {question}
            </label>
            <div className={'settings-table no-border display-input max-width-50'} style={{border: '1px solid transparent'}}>
                <div className="table-row-group">
                    <div key={id} className="settings-row">
                        <div key={`sourceDataInput-${id}`} className="settings-value-cell max-width-20" style={cellStyle}>
                            <label className={"label is-small"}>{sourceDataInputProps.placeholder}</label>
                            <HTMLTextInput {...sourceDataInputProps}/>
                        </div>
                        <div key={`datasetIdInput-${id}`} className="settings-value-cell max-width-5" style={cellStyle}>
                            <label className={"label is-small"}>{datasetInputProps.placeholder}</label>
                            <HTMLTextInput {...datasetInputProps}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function QuestionIngestionDateInput (props) {
    const {t} = useTranslation(['wizard']);
    const {name, dateFilterType, onChange, beforeDateTimeValue, afterDateTimeValue} = props;

    const afterDateDisabled = (dateFilterType !== DateFilterType.BEFORE && dateFilterType !== DateFilterType.NONE);
    const beforeDateDisabled = (dateFilterType !== DateFilterType.AFTER && dateFilterType !== DateFilterType.NONE);

    const afterDateProps = {
        name: "filterAfter",
        stateName: "filterAfterDate",
        dateTimeValue: afterDateTimeValue,
        onChange: onChange,
        isRequired: afterDateDisabled,
        isDisabled: !afterDateDisabled,
        label: t('wizard:questions.advancedIngestionSettings.filterAfterDate')
    };

    const beforeDateProps = {
        name: "filterBefore",
        stateName: "filterBeforeDate",
        dateTimeValue: beforeDateTimeValue,
        onChange: onChange,
        isRequired: beforeDateDisabled,
        isDisabled: !beforeDateDisabled,
        label: t('wizard:questions.advancedIngestionSettings.filterBeforeDate')
    };


    return (
            <div className={'settings-table no-border max-width-30'} style={{border: '1px solid transparent'}}>
                <div className="table-row-group">
                    <div key={name} className="settings-row">
                        <div key={`afterDateInput-${name}`} className={"table-cell"}>
                            <DateTimeInput {...afterDateProps}/>
                        </div>
                        <div key={`beforeDateInput-${name}`} className={"table-cell"}>
                            <DateTimeInput {...beforeDateProps}/>
                        </div>
                    </div>
                </div>
            </div>
    )
}