import {useTranslation} from "react-i18next";
import ExpandableContent from "../../common/ExpandableContent/ExpandableContent";
import {SettingsRowValue} from "../../common/CustomTable/CustomTable";
import React from "react";

function EccServiceSettings(props) {
    const {t} = useTranslation(['thirdPartyService']);
    const {
        thirdPartyService,
        style,
        isDisabled
    } = props;

    return (
        <ExpandableContent label={t('thirdPartyService:label.eccSettings')}
                           style={style} isDisabled={isDisabled}
        >
            <div className="settings-table">
                <div className="table-row-group">
                    <SettingsRowValue label={t('thirdPartyService:label.url')}
                                      value={thirdPartyService.url} isDisabled={isDisabled}/>
                </div>
            </div>
        </ExpandableContent>
    )
}

export default EccServiceSettings;