import ReduxModel from "../generics/ReduxModel";
import FormBuilderComponentConfiguration from "./FormBuilderComponentConfiguration";
import {
    chunkArray,
    deepCopy,
    getEntries,
    getTranslatedValueOrDefault,
    getValues,
    shallowCopy
} from "../../utilities/helperFunctions";

class FormBuilderConfiguration extends ReduxModel {

    constructor(model = {}) {
        super();
        this.forceUpdate(model);
        // Check if templateObject should be an array instead of object
        if (this.template != null) {
            const fields = getEntries(this.template);
            if (!isNaN(fields[0][0])) {
                this.template = [];
                for (const [key, value] of fields) {
                    this.template[key] = value;
                }
            }
        }
        if (Array.isArray(this.componentConfigurations)) {
            this.componentConfigurations = this.componentConfigurations.map(config => new FormBuilderComponentConfiguration(config));
        }
    }

    static buildFormConfiguration(model={}) {
        const formConfiguration = new this(model);
        formConfiguration.fieldConfigurations = formConfiguration.componentConfigurations
            .flatMap(config => config.componentType === FormBuilderComponentConfiguration.ComponentType.GROUP ?
                [config, ...config.componentConfigurations] : config)
            .flatMap(config => {
                switch (config.componentType) {
                    case FormBuilderComponentConfiguration.ComponentType.RADIO_BUTTON_GROUP:
                        return [config, config.radioButtonConfiguration, ...getValues(config.valueConfigurations).map(v => v.componentConfiguration)].filter(c => c);
                    case FormBuilderComponentConfiguration.ComponentType.RADIO_BUTTON_CHECKBOX_GROUP:
                        return [config, config.radioButtonConfiguration, ...getValues(config.valueConfigurations).flatMap(v => [v, v.componentConfiguration])].filter(c => c);
                    default:
                        return config;
                }
            })
            .flatMap(config => config.componentType === FormBuilderComponentConfiguration.ComponentType.ROW ?
                [config, ...config.componentConfigurations] : config)
            .reduce((acc, config) => {
                if (acc.has(config.name)) {
                    console.log(model, config);
                    throw new Error(`Invalid field naming, detected duplicate or empty name: ${config.name}`);
                }
                acc.set(config.name, config);
                return acc;
        }, new Map());

        return formConfiguration;
    }

    // Group elements into tables
    static collectIntoTableGroups(options, root) {
        const {
            state,
            configuration,
            fieldConfigurations
        } = options;

        const components = [];
        for (const config of configuration.componentConfigurations) {
            // Skip non-visible components
            if (!config.isVisible(state, fieldConfigurations)) {
                continue;
            }

            if (FormBuilderComponentConfiguration.TableGroupComponents.has(config.componentType)
                || (!root && FormBuilderComponentConfiguration.NonRootTableGroupComponents.has(config.componentType))) {

                let tableConfig = components[components.length - 1];
                // Create new tableConfig if previous isn't a table
                if (tableConfig == null || tableConfig.componentType !== FormBuilderComponentConfiguration.ComponentType.TABLE_GROUP) {
                    tableConfig = new FormBuilderComponentConfiguration({componentType: FormBuilderComponentConfiguration.ComponentType.TABLE_GROUP, componentConfigurations: []});
                    components.push(tableConfig);
                }
                // Split row by numberOfColumns
                if (config.componentType === FormBuilderComponentConfiguration.ComponentType.ROW && config.numberOfColumns != null) {
                    const chunkedComponentConfigs = chunkArray(config.componentConfigurations, config.numberOfColumns);
                    for (let i = 0; i < chunkedComponentConfigs.length; i++) {
                        const copyRowConfig = shallowCopy(config, {componentConfigurations: chunkedComponentConfigs[i]});
                        copyRowConfig.name += i;
                        tableConfig.componentConfigurations.push(copyRowConfig);
                    }
                } else {
                    tableConfig.componentConfigurations.push(config);
                }
            } else {
                components.push(config);
            }
        }
        return components;
    }

    static getLabelTranslation(t, formKey, value) {
        const labelTranslationKey = `workflowBuilder:${formKey}.${value}`;
        return getTranslatedValueOrDefault(t, value, labelTranslationKey);
    }

    static generateDefaultObject(formConfiguration) {
        const obj = deepCopy(formConfiguration.template);
        for (const [, config] of formConfiguration.fieldConfigurations) {
            // Initialize values for child objects if not initialized in parent
            if (config.formConfiguration != null) {
                const childObj = obj[config.name];
                const childDefaultObj = this.generateDefaultObject(config.formConfiguration);
                for (const [name, value] of getEntries(childDefaultObj)) {
                    if (childObj[name] == null) {
                        childObj[name] = value;
                    }
                }
            }
        }
        return obj;
    }

    generateDefaultObject() {
        return FormBuilderConfiguration.generateDefaultObject(this);
    }

    isValid(state) {
        if (state.disabled) {
            return true;
        }
        for (const [fieldName, fieldConfiguration] of this.fieldConfigurations) {
            if (!fieldConfiguration.isEnabled(state, this.fieldConfigurations)) {
                continue;
            }
            if (!fieldConfiguration.isVisible(state, this.fieldConfigurations)) {
                continue;
            }
            if (!fieldConfiguration.isValid(state[fieldName])) {
                return false;
            }
        }
        return true;
    }
}

export default FormBuilderConfiguration;
