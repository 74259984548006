import React from 'react'
import {connect} from 'react-redux';
import {useTranslation} from "react-i18next";
import {AddButtonHeader} from "../../common/Button/Button";
import {getAwsResourcePoolIds} from '../../../reselect/selectors';
import AwsResourcePoolRow from './AwsResourcePoolRow';
import {applicationFeatures, resourcePoolTypes} from "../../../utilities/constants";
import {includesSome, isAllTruthy, isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import ResourcePoolModel from "../../../models/settings/ResourcePoolModel";
import {StatusLabel} from "../../common/Common";

function AwsResourcePoolTable(props) {
    const {prerequisites, awsResourcePoolIds, showAwsResourcePoolForm, userFeatures} = props;
    const {t} = useTranslation(['aria', 'resourcePool', 'common']);

    const canAddResourcePool = includesSome(userFeatures, [applicationFeatures.ADD_RESOURCE, applicationFeatures.ADD_RESOURCE_POOLS]);
    const isNotEmpty = isNotEmptyNorFalsy(awsResourcePoolIds);
    if (!canAddResourcePool && !isNotEmpty) {
        return null;
    }

    return (
        <div className="display-item">
            <AddButtonHeader aria-label={t('aria:hiddenAssistText.addAwsResourcePool')} text={t('resourcePool:label.awsName')}
                canModify={canAddResourcePool}
                onClick={showAwsResourcePoolForm} isDisabled={!isAllTruthy(prerequisites)}
            />

            {canAddResourcePool && !prerequisites.nuixLicenceSource &&
                <StatusLabel message={t('resourcePool:message.noNuixLicenceSources', {type: 'AWS'})}
                    style={{margin: '0.5rem 0'}}/>
            }

            {isNotEmpty &&
                <div className="display-table">
                    <div className="table-header-group">
                        <div className="table-header" style={{width: '4rem'}}/>

                        <div className="table-header">
                            <label className="label is-bold">
                                {t('common:label.name')}
                            </label>
                        </div>
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('resourcePool:label.accessKey')}
                            </label>
                        </div>
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('resourcePool:label.region')}
                            </label>
                        </div>
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('resourcePool:label.instanceIds')}
                            </label>
                        </div>
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('resourcePool:label.launchTemplateId')}
                            </label>
                        </div>
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('resourcePool:label.tags')}
                            </label>
                        </div>
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('resourcePool:label.maxConcurrentInstances')}
                            </label>
                        </div>
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('resourcePool:label.nuixLicenceSource')}
                            </label>
                        </div>
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('resourcePool:label.targetWorkers')}
                            </label>
                        </div>
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('resourcePool:label.minWorkers')}
                            </label>
                        </div>
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('resourcePool:label.instanceIdleAction')}
                            </label>
                        </div>
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('resourcePool:label.forceIdleAction')}
                            </label>
                        </div>
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('resourcePool:label.remoteEngines')}
                            </label>
                        </div>
                    </div>

                    <div className="table-row-group">
                        {awsResourcePoolIds.map(id =>
                            <AwsResourcePoolRow key={id} resourcePoolId={id}/>
                        )}
                    </div>
                </div>
            }
        </div>
    )
}

const mapStateToProps = state => {
    const {nuixLicenceSourceDetailsMap, currentUser: {features}} = state;

    const prerequisites = {
        nuixLicenceSource: isNotEmptyNorFalsy(nuixLicenceSourceDetailsMap)
    };

    return {
        prerequisites,
        awsResourcePoolIds: getAwsResourcePoolIds(state),
        userFeatures: features
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showAwsResourcePoolForm: () => dispatch(ResourcePoolModel.actionCreators.showForm({type: resourcePoolTypes.AWS}))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AwsResourcePoolTable);