import {useTranslation} from "react-i18next";
import {createInputHandler, createNestedUpdateState, createStateHandler} from "../../../utilities/componentFunctions";
import {createToggleHandler} from "../../common/Checkbox/helpers";
import ToggleSwitch from "../../common/ToggleSwitch/ToggleSwitch";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import React from "react";

function PropertyFilterRow(props) {
    const {label, name, property, updateState} = props;
    const {t} = useTranslation(['aria']);

    const reduxHandler = createStateHandler({
        updateState: createNestedUpdateState(updateState, ['eventTrigger', name])
    });

    const inputHandler = createInputHandler({
        handler: reduxHandler
    });
    const toggleEnable = createToggleHandler({
        handler: reduxHandler
    });

    const isRequired = property.enabled && !property.contains;
    return (
        <div className="settings-row">
            <div className="table-cell no-stretch">
                <ToggleSwitch id={`${name}Toggle`} label={`${label}:`} name={'enabled'} isWordWrap={false}
                    checked={property.enabled} onClick={toggleEnable}
                />
            </div>
            <div className="table-cell">
                <HTMLTextInput id={`${name}Contains`} aria-label={t('aria:input.contains')} name={'contains'} value={property.contains} onChange={inputHandler}
                    isDisabled={!property.enabled} isRequired={isRequired}
                />
            </div>
        </div>
    )
}

export default PropertyFilterRow;
