import React from 'react';
import {connect} from 'react-redux';
import {useTranslation} from "react-i18next";
import {AddButtonHeader} from "../common/Button/Button";
import {getEngineIds} from '../../reselect/selectors';
import EngineRow from './EngineRow';
import {applicationFeatures} from "../../utilities/constants";
import {includesSome, isAllTruthy, isNotEmptyNorFalsy} from "../../utilities/helperFunctions";
import EngineModel from "../../models/settings/EngineModel";
import {StatusLabel} from "../common/Common";
import {TableLabelHeader} from "../common/CustomTable/CustomTable";

function EngineTable(props) {
    const {prerequisites, engineIds, showEngineForm, userFeatures} = props;
    const {t} = useTranslation(['aria', 'engine', 'common']);

    const canAddEngine = includesSome(userFeatures, [applicationFeatures.ADD_RESOURCE]);
    const isNotEmpty = isNotEmptyNorFalsy(engineIds);
    if (!canAddEngine && !isNotEmpty) {
        return null;
    }

    return (
        <div className="display-item">
            <AddButtonHeader aria-label={t('aria:hiddenAssistText.addEngine')} text={t('engine:label.name')}
                canModify={canAddEngine} onClick={showEngineForm} isDisabled={!isAllTruthy(prerequisites)}
            />
            {canAddEngine && !prerequisites.server &&
            <StatusLabel message={t('engine:message.noServers')} style={{margin: '0.5rem 0'}}/>
            }

            {isNotEmpty &&
            <div className="display-table">
                <div className="table-header-group">
                    <TableLabelHeader
                        label={t('common:label.name')}
                    />

                    <TableLabelHeader
                        label={t('engine:label.server')}
                    />

                    <TableLabelHeader style={{width: '16rem'}}
                        label={t('engine:label.targetWorkers')}
                    />

                    <TableLabelHeader style={{width: '16rem'}}
                        label={t('engine:label.acquiredWorkers')}
                    />
                    <TableLabelHeader noStretch
                        label={t('engine:label.status')}
                    />

                    <TableLabelHeader noStretch/>
                </div>

                <div className="table-row-group">
                    {engineIds.map(id =>
                        <EngineRow key={id} engineId={id}/>
                    )}
                </div>
            </div>
            }
        </div>
    );
}

const mapStateToProps = state => {
    const {serverDetailsMap, currentUser: {features}} = state;

    const prerequisites = {
        server: isNotEmptyNorFalsy(serverDetailsMap)
    };

    return {
        prerequisites,
        engineIds: getEngineIds(state),
        userFeatures: features
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showEngineForm: () => dispatch(EngineModel.componentActionCreators.updateDisplay({isEngineFormActive: true}))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EngineTable);