import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {DefaultEditPanel} from '../common/EditPanel/EditPanel';
import Tablet, {EditTabletHeader} from '../common/Tablet/Tablet';
import {createDropdownHandler} from "../common/Dropdown/helpers";
import {shouldEnableMatter} from "../../utilities/shouldEnableFunctions";
import {permissionKeys, statusKeys} from "../../i18next/keys";
import {SettingsRowLabel, SettingsRowValue} from "../common/CustomTable/CustomTable";
import {ExecutionProfileDropdown, ResourcePoolDropdown} from "../common/Dropdown/Dropdown";
import ExpandableContent, {
    ExpandableEditTextArea,
    ExpandableWorkflowParametersV2
} from "../common/ExpandableContent/ExpandableContent";
import MatterModel from "../../models/client/MatterModel";
import EditModel from "../../models/scheduler/EditModel";
import Checkbox from "../common/Checkbox/Checkbox";
import {createToggleHandler} from "../common/Checkbox/helpers";
import {getValues, isNotEmptyNorFalsy, switchcaseF} from "../../utilities/helperFunctions";
import KeyValueList from "../common/KeyValueList/KeyValueList";
import {createEditHandler, createInputHandler} from "../../utilities/componentFunctions";
import ParameterModel from "../../models/library/ParameterModel";
import DatasetView from "../dataset/DatasetView";
import {makeGetEditDetails} from "../../reselect/selectors";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {InputTableV2} from "../common/InputTable/InputTable";
import DatasetModel from "../../models/data/DatasetModel";
import CurrentUserModel from "../../models/user/CurrentUserModel";
import FileInfoModel from "../../models/data/FileInfoModel";
import ClientModel from "../../models/client/ClientModel";
import {ExpandableStatusLog} from "../common/Common";
import {onWorkflowParameterNormalize} from "../common/InputTable/helpers";
import {applicationFeatures} from "../../utilities/constants";


const selectEditDetails = makeGetEditDetails();
function selectMatterDetails (state, matterId) {

    const {activeModel, values, isSaveEnabled} = selectEditDetails(state, {model: MatterModel.nom});
    const isEditActive = (activeModel === MatterModel.nom);
    const isFileInfoEditActive = state.editDetails.activeModel === FileInfoModel.nom;

    const {name: matterName, defaultExecutionProfileId, defaultResourcePoolId, ...otherDetails} = state.matterDetailsMap.get(matterId);
    const {defaultExecutionProfileId: editExecutionProfileId, defaultResourcePoolId: editResourcePoolId, ...otherEditValues} = values;

    let defaultExecutionProfile = (state.executionProfileDetailsMap.get(defaultExecutionProfileId) || {}).name;
    let defaultResourcePool = (state.resourcePoolDetailsMap.get(defaultResourcePoolId) || {}).name;

    if (isEditActive) {
        defaultExecutionProfile = editExecutionProfileId;
        defaultResourcePool = editResourcePoolId;
    }

    return {
        matterName,
        defaultExecutionProfile,
        defaultResourcePool,
        ...otherDetails,
        ...otherEditValues,
        isEditActive,
        isSaveEnabled,
        isFileInfoEditActive
    };
}

export function selectMatterHasDatasets(state, matterId) {
    return getValues(state.datasetDetailsMap)
        .filter(dataset => dataset.matterId === matterId)
        .length > 0;
}

function MatterTablet(props) {
    const {t} = useTranslation(['matter', 'workflow', 'common']);
    const dispatch = useDispatch();

    const canManageDataSets = useSelector(state => state.currentUser.features.includes(applicationFeatures.SCHEDULER_UPLOAD));

    const {matterId} = props;
    const {
        matterName,
        enabled,
        description,
        defaultExecutionProfile,
        defaultResourcePool,
        synchronizeJobs,
        allowedParameterValues,
        requiredMetadataHeaders,
        workflowParameters,
        status,
        userPermissions,
        isEditActive,
        isSaveEnabled,
        isFileInfoEditActive
    } = useSelector(state => selectMatterDetails(state, matterId));

    const hasDatasets = canManageDataSets && useSelector(state => selectMatterHasDatasets(state, matterId));
    const {
        isDisabled
    } = useSelector(state => state.componentStates.matterTablet);

    const isLoading = useSelector(state => !state.hasLoaded[matterId]);
    const {
        isUploadActive
    } = useSelector(state => state.componentStates.datasetView);


    useEffect(() => {
        dispatch(CurrentUserModel.actionCreators.pollActivity(CurrentUserModel.activities.ADMINISTRATION));
    }, [isUploadActive, matterId, dispatch]);

    useEffect(() => {
        // reset on matterTablet unmount
        return () => dispatch(CurrentUserModel.actionCreators.resetPollActivity());
    }, [dispatch]);

    // Start and Stop polling
    useEffect(() => {
        dispatch(MatterModel.actionCreators.startPollingSettings(matterId));

        return () => dispatch(MatterModel.actionCreators.stopPollingSettings());
    }, [matterId, dispatch]);

    const [clearParameterPasswordState, setClearParameterPasswordState] = useState({});
    useEffect(() => {
        setClearParameterPasswordState({});
    }, [isEditActive]);


    const {saveEdit, cancelEdit, updateEdit, setEditSaveEnabled} = EditModel.buildDispatchers(dispatch);
    const editHandler = createEditHandler({
        updateEdit,
        setEditSaveEnabled,
        shouldEnable: shouldEnableMatter,
        values: {matterName, allowedParameterValues}
    });

    const inputHandler = createInputHandler({
        handler: editHandler
    });
    const toggleEnable = createToggleHandler({
        handler: editHandler
    });
    const dropdownHandler = createDropdownHandler({
        handler: editHandler
    });

    const workflowParameterNormalizeHandler = onWorkflowParameterNormalize(clearParameterPasswordState, setClearParameterPasswordState);

    // Custom inputHandler for allowedParameters
    function setAllowedParameterValues(update) {
        const updates = {
            allowedParameterValues: update(allowedParameterValues)
        };

        editHandler(updates);
    }

    function setRequiredMetadataHeaders(update) {
        const updates = {
            requiredMetadataHeaders: update(requiredMetadataHeaders)
        }

        editHandler(updates);
    }

    function setWorkflowParameters(update) {
        const updates = {
            workflowParameters: update(workflowParameters)
        }

        editHandler(updates);
    }

    function menuOptionHandler(event) {
        const {value} = event.currentTarget.dataset;

        switchcaseF({
            'queueJob': () => dispatch(MatterModel.actionCreators.queueMatterJob(matterId)),
            'edit': () => dispatch(MatterModel.actionCreators.startEdit(matterId)),
            'toggleActive': () => dispatch(MatterModel.actionCreators.toggleEnabled(matterId)),
            'promptDelete': () => dispatch(MatterModel.actionCreators.promptDelete(matterId))
        })()(value);
    }

    const menuOptions = [
        {name: t('common:option.edit'), value: 'edit'},
        {name: t(`common:option.${enabled ? 'deactivate' : 'activate'}`), value: 'toggleActive'},
        {name: t('common:option.delete'), value: 'promptDelete'},
        {isSeparator: true},
        {name: t('matter:option.queueJob'), value: 'queueJob', isDisabled: !enabled}
    ];


    const canModify = userPermissions.includes(permissionKeys.MODIFY);
    const canModifyChildren = canModify || userPermissions.includes(permissionKeys.MODIFY_CHILDREN);
    // Matter is disabled when uploading to a dataset or if tablet is set to disabled or if editing metadata
    const isMatterDisabled = isDisabled || isUploadActive || isFileInfoEditActive;
    const isTabletDisabled = isDisabled || isUploadActive;

    const showDescription = isEditActive || description;
    const showSettings = isEditActive || defaultExecutionProfile || defaultResourcePool || synchronizeJobs;
    const showAllowedParameters = isEditActive || isNotEmptyNorFalsy(allowedParameterValues);
    const showRequiredMetadataHeaders = isEditActive || isNotEmptyNorFalsy(requiredMetadataHeaders);
    const showWorkflowParameters = isEditActive || isNotEmptyNorFalsy(workflowParameters);
    const showDatasets = hasDatasets || canModifyChildren;

    const height = isEditActive ? 'auto' : '65vh';
    const datasetViewStyle = (showDescription || showSettings || showAllowedParameters || showRequiredMetadataHeaders) ? {paddingTop: '1rem'} : {};

    return (
        <Tablet width={'100rem'} height={height} onClose={() => dispatch(MatterModel.actionCreators.hideTablet())}
            isDisabled={isTabletDisabled} closeButtonAriaLabel={t('matter:option.closeTablet_name', {name: matterName})}
            header={
                <EditTabletHeader label={t('matter:label.name')} type={'matter'}
                    id={matterId} name={'matterName'} value={matterName} enabled={enabled} inputHandler={inputHandler}
                    menuOptions={menuOptions} menuOptionHandler={menuOptionHandler}
                    canModify={canModify} isEditActive={isEditActive} isDisabled={isMatterDisabled}
                />
            }
            body={
                <>
                    {status.code === statusKeys.ERROR && !isEditActive &&
                        <section className="display-item">
                            <ExpandableStatusLog {...status} isDisabled={isDisabled}/>
                        </section>
                    }

                    <DefaultEditPanel isActive={isEditActive} isSaveEnabled={isSaveEnabled} style={(isEditActive || !(showDescription || showSettings || showAllowedParameters)) ? {} : {marginBottom: '-2rem'}}
                        onSave={saveEdit} onCancel={cancelEdit}>

                        <>
                            {showDescription &&
                            <section className="display-item">
                                <ExpandableEditTextArea label={t('common:label.description')} name={'description'}
                                    value={description} onChange={inputHandler} isEditActive={isEditActive}
                                    isDisabled={isMatterDisabled}
                                />
                            </section>
                            }

                            {showSettings &&
                            <section className="display-item">
                                <ExpandableContent label={t('common:label.settings')} isDisabled={isMatterDisabled}>
                                    <div className="settings-table">
                                        <div className="table-row-group">

                                            <SettingsRowValue label={t('matter:label.defaultExecutionProfile')}
                                                value={isEditActive ?
                                                    <ExecutionProfileDropdown name={'defaultExecutionProfileId'} isEdit
                                                        selectedExecutionProfileId={defaultExecutionProfile}
                                                        onExecutionProfileSelect={dropdownHandler}
                                                        buttonStyle={{
                                                            marginBottom: '0.1rem'
                                                        }}
                                                    />
                                                    :
                                                    defaultExecutionProfile
                                                }
                                            />

                                            <SettingsRowValue label={t('matter:label.defaultResourcePool')}
                                                value={isEditActive ?
                                                    <ResourcePoolDropdown name={'defaultResourcePoolId'} isEdit
                                                        selectedResourcePoolId={defaultResourcePool}
                                                        onResourcePoolSelect={dropdownHandler}
                                                        buttonStyle={{
                                                            marginBottom: '0.1rem'
                                                        }}
                                                    />
                                                    :
                                                    defaultResourcePool
                                                }
                                            />

                                            {!isEditActive && synchronizeJobs &&
                                            <SettingsRowLabel label={t('matter:label.synchronizeJobs')}/>
                                            }
                                        </div>
                                    </div>

                                    {isEditActive &&
                                    <div className="display-input">
                                        <Checkbox label={t('matter:label.synchronizeJobs')} name={'synchronizeJobs'}
                                            checked={synchronizeJobs} onClick={toggleEnable}/>
                                    </div>
                                    }
                                </ExpandableContent>
                            </section>
                            }

                            {showWorkflowParameters &&
                                <section className="display-item">
                                    <ExpandableWorkflowParametersV2 label={t('client:label.workflowParameters')} name={'workflowParameters'} force={workflowParameters.length > 0}
                                                                    initialExpanded={workflowParameters.length > 0} isEditActive={isEditActive} workflowParameters={workflowParameters}
                                                                    headers={[{value: t('client:header.name')}, {value: t('client:header.value')}]}
                                                                    inputTable={workflowParameters} setInputTable={setWorkflowParameters}
                                                                    isReadOnly={!isEditActive} defaultRow={ClientModel.workflowParametersRowTemplate}
                                                                    blurHandler={[ParameterModel.normalizeParameterName, null]} inputNormalizer={workflowParameterNormalizeHandler}
                                    />
                                </section>
                            }

                            {showAllowedParameters &&
                            <section className="display-item">
                                <ExpandableContent initialExpanded={allowedParameterValues.length > 0} isDisabled={isMatterDisabled}
                                    label={t('matter:label.allowedParameters')}
                                >

                                    <KeyValueList keysTitle={t('workflow:label.parameterName')} style={{marginTop: '0.25rem'}}
                                        isDisabled={isMatterDisabled}
                                        blurHandler={ParameterModel.normalizeParameterName} entries={allowedParameterValues}
                                        setEntries={setAllowedParameterValues}
                                        ariaLabelKey={'Parameter'} isReadOnly={!isEditActive} isMoveEnabled={true}
                                    />
                                </ExpandableContent>
                            </section>
                            }

                            {showRequiredMetadataHeaders && canManageDataSets &&
                            <section className="display-item">
                                <ExpandableContent label={t('dataset:label.requiredMetadataHeaders')} initialExpanded={requiredMetadataHeaders.length > 0}
                                    isDisabled={isMatterDisabled}
                                >
                                    <InputTableV2 headers={[{value: t('dataset:label.header')}, {value: t('dataset:label.regex')}]}
                                        inputTable={requiredMetadataHeaders} setInputTable={setRequiredMetadataHeaders}
                                        defaultRow={DatasetModel.requiredMetadataHeadersRowTemplate} isReadOnly={!isEditActive} isDisabled={isMatterDisabled}
                                        ariaLabelKey={'RequiredMetadata'}
                                    />
                                </ExpandableContent>
                            </section>
                            }
                        </>
                    </DefaultEditPanel>

                    {!isEditActive && showDatasets &&
                    <section className="display-item" style={datasetViewStyle}>
                        <LoadingWrapper isLoading={isLoading}>

                            {canManageDataSets &&
                            <DatasetView matterId={matterId} canModify={canModifyChildren}
                                isDisabled={isDisabled}
                            />
                            }
                        </LoadingWrapper>
                    </section>
                    }
                </>
            }
        />
    )
}

export default MatterTablet;
