import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import JobScheduleModel, {JobScheduleSaga} from "../../../models/job/JobScheduleModel";
import LoadingWrapper from "../../common/LoadingWrapper/LoadingWrapper";
import {ExecutionProfileDropdown, PriorityDropdown, ResourcePoolDropdown} from "../../common/Dropdown/Dropdown";
import {StatusLabel} from "../../common/Common";
import {SAME_AS_TRIGGERING_JOB} from "../../../utilities/constants";
import {scheduleTriggerTypeKeys} from "../../../i18next/keys";
import ParametersPanel from "../../parameters/ParametersPanel";
import Text from "../../common/Text/Text";
import SchedulerModel from "../../../models/scheduler/SchedulerModel";
import {contextCall} from "../../../saga/sagaFunctions";
import {createDropdownHandler} from "../../common/Dropdown/helpers";

function ScheduleJobPane() {
    const dispatch = useDispatch();

    const jobScheduleForm = useSelector(state => state.componentStates.jobScheduleForm);
    const {
        scheduleTriggerType,
        libraryId,
        executionProfileId,
        resourcePoolId,
        priority,
        parameters,

        isLoadingSettings,
        isDisabled
    } = jobScheduleForm;

    const updateState = useCallback(updates => dispatch(JobScheduleModel.componentActionCreators.updateForm(updates)), []);
    const loadTsvParameters = useCallback(tsvText => dispatch(JobScheduleModel.actionCreators.loadTsvParameters(tsvText)), []);
    const resetParameters = useCallback(() => dispatch(JobScheduleModel.actionCreators.resetParameters()), []);
    const decryptProtectedParameters = useCallback(() => dispatch(JobScheduleModel.actionCreators.decryptProtectedValues('parameters')), []);

    // onMount decryptProtectedParameters
    useEffect(() => {
        if (!isLoadingSettings) {
            decryptProtectedParameters();
        }
    }, [isLoadingSettings, decryptProtectedParameters]);

    const dispatchParameterUpdate = useCallback((...args) => {
        dispatch(JobScheduleModel.actionCreators.updateParameter(...args));
    }, []);

    const updateParameters = useCallback(updates => {
        updateState(prevState => ({
            parameters: updates(prevState.parameters)
        }));
    }, [updateState]);

    const userUpdateHandler = useCallback(updates => {
        updateState(prevState => {
            const update = typeof updates === 'function' ? updates(prevState) : updates;
            update.didUserChange = {...prevState.didUserChange};
            if (update.executionProfileId != null)
                update.didUserChange.executionProfile = true;
            if (update.resourcePoolId != null)
                update.didUserChange.resourcePool = true;
            if (update.priority != null)
                update.didUserChange.priority = true;
            return update;
        });
    }, [updateState]);

    const dropdownHandler = createDropdownHandler({
        handler: userUpdateHandler
    });

    function refreshParameters() {
        const refreshEffect = contextCall(JobScheduleSaga, 'updateJobScheduleParametersAllowedValues');
        dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(refreshEffect));
    }

    return (
        <div className="pane-margin">
            <LoadingWrapper isLoading={isLoadingSettings}>
                <div className="settings-table display-input">
                    <div className="table-row-group">
                        <ExecutionProfileSettingsRow executionProfileId={executionProfileId} onItemSelect={dropdownHandler}
                            isDisabled={isDisabled}
                            canSetSameAs={{
                                job: scheduleTriggerType === scheduleTriggerTypeKeys.ON_JOB_EVENT
                            }}
                        />

                        <ResourcePoolSettingsRow resourcePoolId={resourcePoolId} onItemSelect={dropdownHandler}
                            isDisabled={isDisabled}
                            canSetSameAs={{
                                job: scheduleTriggerType === scheduleTriggerTypeKeys.ON_JOB_EVENT
                            }}
                        />

                        <PrioritySettingsRow priority={priority} onItemSelect={dropdownHandler}
                            isDisabled={isDisabled}
                            canSetSameAs={{
                                job: scheduleTriggerType === scheduleTriggerTypeKeys.ON_JOB_EVENT
                            }}
                        />
                    </div>
                </div>

                {libraryId !== SAME_AS_TRIGGERING_JOB &&
                <div className="display-item">
                    <ParametersPanel parameters={parameters} loadTsvParameters={loadTsvParameters} updateState={updateState} updateParameters={updateParameters}
                        dispatchParameterUpdate={dispatchParameterUpdate} refreshParameters={refreshParameters} resetParameters={resetParameters}
                        isDisabled={isDisabled}/>
                </div>
                }
            </LoadingWrapper>
        </div>
    )
}

export function ExecutionProfileSettingsRow(props) {
    const {canSetSameAs={}, executionProfileId, onItemSelect, missingRequiredFiles, isRequired, isInvalid, isDisabled} = props;
    const {t} = useTranslation(['job']);

    return (
        <div className="settings-row">
            <div className="settings-label-cell no-stretch">
                <Text value={`${t('job:label.executionProfile')}:`}
                    isDisabled={isDisabled}/>
            </div>

            <div className="settings-value-cell" style={{display: 'flex', marginLeft: '1rem'}}>
                <ExecutionProfileDropdown selectedExecutionProfileId={executionProfileId} onItemSelect={onItemSelect}
                    canSetSameAs={canSetSameAs} isDisabled={isDisabled} isRequired={isRequired} isInvalid={isInvalid}/>
                {!executionProfileId &&
                <StatusLabel message={t('job:message.unassignedExecutionProfile')} isDisabled={isDisabled}/>
                }
                {(!!executionProfileId && missingRequiredFiles) &&
                <StatusLabel message={t('job:message.executionProfileMissingProfiles')} isDisabled={isDisabled}/>
                }
            </div>
        </div>
    )
}

export function ResourcePoolSettingsRow(props) {
    const {canSetSameAs={}, resourcePoolId, onItemSelect, isRequired, isInvalid, isDisabled} = props;
    const {t} = useTranslation(['job']);

    return (
        <div className="settings-row">
            <div className="settings-label-cell no-stretch">
                <Text value={`${t('job:label.resourcePool')}:`} isDisabled={isDisabled}/>
            </div>

            <div className="settings-value-cell" style={{display: 'flex', marginLeft: '1rem'}}>
                <ResourcePoolDropdown selectedResourcePoolId={resourcePoolId} onItemSelect={onItemSelect}
                    canSetSameAs={canSetSameAs} isDisabled={isDisabled} isRequired={isRequired} isInvalid={isInvalid}/>
                {!resourcePoolId &&
                <StatusLabel message={t('job:message.unassignedResourcePool')}  isDisabled={isDisabled}/>
                }
            </div>
        </div>
    )
}

export function PrioritySettingsRow(props) {
    const {canSetSameAs={}, priority, onItemSelect, isDisabled} = props;
    const {t} = useTranslation(['job']);

    return (
        <div className="settings-row">
            <div className="settings-label-cell no-stretch">
                <Text value={`${t('job:label.priority')}:`} isDisabled={isDisabled}/>
            </div>

            <div className="settings-value-cell" style={{display: 'flex', marginLeft: '1rem'}}>
                <PriorityDropdown selectedPriority={priority} onPrioritySelect={onItemSelect}
                    canSetSameAs={canSetSameAs} isDisabled={isDisabled}/>
            </div>
        </div>
    )
}

export default ScheduleJobPane;
