import React from "react";
import {useTranslation} from "react-i18next";
import {AddButtonHeader} from "../../common/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import {getValues, isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import UserServiceModel from "../../../models/user/UserServiceModel";
import {
    StatusNameDescriptionCell,
    TableEnableToggleCell,
    TableLabelHeader,
    TableRow
} from "../../common/CustomTable/CustomTable";

function InternalUserServiceTable() {
    const {t} = useTranslation(['userService', 'common']);
    const dispatch = useDispatch();

    const activeUserServiceId = useSelector(state => state.componentStates.userServiceDisplay.userServiceId);
    const internalUserServices = getValues(useSelector(state => state.userServiceDetailsMap))
        .filter(userService => userService.type === UserServiceModel.types.INTERNAL);

    function showInternalUserServiceTablet(e) {
        dispatch(UserServiceModel.componentActionCreators.updateDisplay({userServiceId: e.currentTarget.dataset.value}));
    }

    if (isNotEmptyNorFalsy(internalUserServices)) {
        return (
            <div className="display-item">
                <AddButtonHeader text={t('userService:label.internalUserServiceTitle')}/>

                <div className="display-table">
                    <div className="table-header-group">

                        <div className="table-header"/>
                        <TableLabelHeader label={t('common:label.name')}/>
                    </div>

                    <div className="table-row-group">
                        {internalUserServices.map(userService =>
                            <InternalUserServiceRow key={userService.id} userService={userService}
                                activeUserServiceId={activeUserServiceId} showTablet={showInternalUserServiceTablet}/>
                        )}
                    </div>
                </div>
            </div>
        )
    }
    return null;
}

function InternalUserServiceRow(props) {
    const {
        activeUserServiceId,
        userService: {
            id,
            enabled,
            status,
            name,
            description
        },
        showTablet
    } = props;

    return (
        <TableRow value={id} isActive={id === activeUserServiceId} onClick={showTablet}>

            <TableEnableToggleCell ariaLabelKey={'InternalUserService'} displayName={name} name={id} checked={enabled} isReadOnly/>

            <StatusNameDescriptionCell status={status} name={name} description={description}/>
        </TableRow>
    )
}

export default InternalUserServiceTable;
