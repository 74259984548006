import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import Form, {FormHeader} from "../common/Form/Form";
import {shouldEnableUpdateAutomateLicence,} from "../../utilities/shouldEnableFunctions";
import PopupModel from "../../models/scheduler/PopupModel";
import {
    createCloseHandler,
    createFileHandler,
    createInputHandler,
    createStateHandler,
    inputPasteHandler
} from "../../utilities/componentFunctions";
import {ButtonGroup} from "../common/Button/Button";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import AutomateLicenceModel from "../../models/scheduler/AutomateLicenceModel";
import {diagnosticLevelKeys} from "../../i18next/keys";
import {ListDropdown} from "../common/Dropdown/Dropdown";
import {createDropdownHandler} from "../common/Dropdown/helpers";
import {getValues} from "../../utilities/helperFunctions";
import {useClearOnFirstCallback} from "../../utilities/formHooks";

function UpdateAutomateLicenceForm() {
    const {t} = useTranslation(['aria', 'automateLicence', 'common']);
    const dispatch = useDispatch();

    const {
        isDisabled
    } = useSelector(state => state.componentStates.automateLicenceForm)

    const licenceKeyInputRef = useRef({});
    const clearOnFirstCallback = useClearOnFirstCallback();

    const [state, setState] = useState({
        licenceId: '',
        diagnosticLevel: diagnosticLevelKeys.ENHANCED,
        isUpdateEnabled: false
    });

    const stateHandler = createStateHandler({
        updateState: setState,
        shouldEnable: shouldEnableUpdateAutomateLicence,
        isEnabled: 'isUpdateEnabled',
        passwordRef: licenceKeyInputRef,
        passwordResetNames: ['licenceId'],
        passwordName: 'licenceKey'
    })

    const inputHandler = createInputHandler({
        handler: stateHandler
    });

    const dropdownItemClick = createDropdownHandler({
        handler: stateHandler
    });

    const onClose = createCloseHandler({
        t,
        item: t('automateLicence:label.licenceCredentials'),
        values: state,
        blacklist: ['diagnosticLevel'],
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        close: () => dispatch(AutomateLicenceModel.componentActionCreators.updateDisplay({isFormActive: false}))
    });

    const {
        licenceId,
        diagnosticLevel,
        isUpdateEnabled
    } = state;

    function onBrowse() {
        const input = document.getElementById("licenceFileUploadInput");
        input.click();
    }

    function onSubmit() {
        dispatch(AutomateLicenceModel.actionCreators.submitForm({
            licenseId: licenceId,
            licenseKey: licenceKeyInputRef.current.value.trim(),
            diagnosticLevel: diagnosticLevel,
        }));
    }

    const onFileChange =
        createFileHandler({
            readAs: 'readAsText',
            onloadend: (event, file) => {
                const fileData = event.target.result;
                dispatch(AutomateLicenceModel.actionCreators.submitFile(
                    fileData
                ));
            }
        })

    const disabled = isDisabled ? ' is-disabled' : '';

    return (
        <Form onClose={onClose} isDisabled={isDisabled} closeButtonAriaLabel={t('automateLicence:option.closeForm')}
            header={
                <FormHeader text={t('automateLicence:label.licenceCredentials')} iconName={'automateLicence'}
                    isDisabled={isDisabled}/>
            }
            body={
                <>
                    <div className="display-input">
                        <HTMLTextInput id={'License ID'} label={t('automateLicence:label.licenceId')} name={'licenceId'}
                            value={licenceId} onChange={inputHandler} onPaste={inputPasteHandler}
                            isDisabled={isDisabled} isRequired/>
                    </div>

                    <div className="display-input">
                        <HTMLTextInput id={'License Key'} label={t('automateLicence:label.licenceKey')} name={'licenceKey'} type={'password'}
                            onChange={inputHandler} inputRef={licenceKeyInputRef} onPaste={inputPasteHandler} onBeforeInput={clearOnFirstCallback}
                            isDisabled={isDisabled} isRequired/>
                    </div>

                    <div className="forms-table">
                        <div className="table-row-group">

                            <div className="table-row">
                                <div className="table-cell no-stretch">
                                    <label className={'label' + disabled}>
                                        {t('automateLicence:label.diagnosticLevel')} (<a
                                        href="https://www.nuix.com/privacy-policy" target="_blank"
                                        rel="noopener noreferrer">{t('common:label.privacyPolicy')}</a>):
                                    </label>
                                </div>

                                <div className="table-cell">
                                    <ListDropdown id={'diagnosticLevelDropdown'} name={'diagnosticLevel'} value={diagnosticLevel}
                                        aria-label={t('aria:hiddenAssistText.diagnosticLevel')} onItemSelect={dropdownItemClick}
                                        items={getValues(diagnosticLevelKeys)
                                            .map(_diagnosticLevel => ({
                                                name: t(`automateLicence:diagnosticLevels.${_diagnosticLevel}`),
                                                value: _diagnosticLevel
                                            }))
                                        }
                                        isDisabled={isDisabled}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <input style={{display: "none"}} id="licenceFileUploadInput" type="file" accept=".rlic"
                        onChange={onFileChange} disabled={isDisabled}/>
                </>

            }
            footer={
                <ButtonGroup buttons={[{
                    id: 'formFooterBackButton',
                    label: t('common:option.cancel'),
                    onClick: onClose,
                    isDisabled
                }, {
                    id: 'formFooterBrowseButton',
                    label: t('common:option.browse'),
                    onClick: onBrowse,
                    isDisabled: isDisabled
                }, {
                    id: 'formFooterNextButton',
                    label: t('automateLicence:option.update'),
                    onClick: onSubmit,
                    isDisabled: isDisabled || !isUpdateEnabled
                }]}/>
            }
        />
    )
}


export default (UpdateAutomateLicenceForm);