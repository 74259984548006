import DetailsModel, {DetailsApi, DetailsSaga} from "../generics/DetailsModel";
import ComponentStateModel from "../generics/ComponentStateModel";
import ClientModel, {ClientSaga} from "../client/ClientModel";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import {
    policyPermissionKeys,
    policyPrincipalBuiltInKeys,
    policyPrincipalTypeKeys,
    policyScopeTypeKeys,
    setPolicyPrincipalTypeKeys,
    setPolicyScopeBuiltInKeys,
    settingsDisplayKeys
} from "../../i18next/keys";
import {all, call, fork, put, select, take} from "redux-saga/effects";
import LibraryModel, {LibrarySaga} from "../library/LibraryModel";
import ResourcePoolModel, {ResourcePoolSaga} from "./ResourcePoolModel";
import ClientPoolModel, {ClientPoolSaga} from "./ClientPoolModel";
import {
    getEntries,
    getGeneralizedItemKey,
    getValues,
    nameLocaleCompare,
    objEquals,
    switchcase
} from "../../utilities/helperFunctions";
import MatterModel from "../client/MatterModel";
import WorkflowTemplateModel from "../library/WorkflowTemplateModel";
import {contextCall, contextPollUntil, contextSaga, contextSpawn, isDisabledWrapper} from "../../saga/sagaFunctions";
import {details, QUERY_INTERVAL, routes} from "../../utilities/constants";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import {getTabletUpdatedPolicyDetailsScope} from "../../reselect/selectors";
import LicenceSourceModel, {LicenceSourceSaga} from "./LicenceSourceModel";
import ExecutionProfileModel, {ExecutionProfileSaga} from "./ExecutionProfileModel";
import NotificationRuleModel, {NotificationRuleSaga} from "./NotificationRuleModel";
import {separateNameValues} from "../../components/common/ListContainer/helpers";
import EditModel from "../scheduler/EditModel";
import {retryCall} from "../../saga/tryCatchWrapper";
import DataRepositoryModel, {DataRepositorySaga} from "../data/DataRepositoryModel";
import {CurrentUserSaga} from "../user/CurrentUserModel";
import NoticeTemplateModel, {NoticeTemplateSaga} from "../notice/NoticeTemplateModel";
import LegalHoldModel, {LegalHoldSaga} from "../legalhold/LegalHoldModel";
import SchedulerModel, {SchedulerSaga} from "../scheduler/SchedulerModel";
import FileLibraryModel, {FileLibrarySaga} from "../filelibrary/FileLibraryModel";
import UserServiceModel, {UserServiceSaga} from "../user/UserServiceModel";
import ThirdPartyServiceModel, {ThirdPartyServiceSaga} from "../thirdparty/ThirdPartyServiceModel";
import i18n from "../../i18next/i18n";

class PolicyModel extends DetailsModel {

    static nom = 'PolicyModel';
    static actions = PolicyModel.buildActions('POLICY');
    static actionCreators = PolicyModel.buildActionCreators(PolicyModel.actions);
    static reducer = PolicyModel.buildReducer(PolicyModel.actions);

    static componentActionCreators = {
        ...PolicyModel.buildComponentUpdateActionCreators(),
        ...PolicyModel.buildComponentSetActiveActionCreators()
    };

    constructor(model = {}) {
        super(model);
        const { principals = [], permissions = [], scope = [], warnings = [], hasWarnings, auditLog, id } = model;

        this.id = id;
        this.principals = principals.map(p => new PolicyModel.Identifier(p));
        this.permissions = permissions;
        this.scope = scope.map(s => new PolicyModel.Identifier(s));
        this.auditLog = auditLog;

        this.hasWarnings = hasWarnings;
        this.warnings = warnings;
    }

    static Identifier = class Identifier {
        constructor(model = {}) {
            const { identifierType, identifierName } = model;

            this.identifierType = identifierType;
            this.identifierName = identifierName;
        }
    };

    static isUsingModifyOnPolicyScopes (values) {
        return values.permissions.includes(policyPermissionKeys.MODIFY) &&
            values.scope.some(scope =>
                (['RESOURCES', 'ALL_EXECUTION_PROFILES', 'ALL_WORKFLOWS', 'SCRIPTS'].includes(scope.identifierName) || ['EXECUTION_PROFILE_ID', 'WORKFLOW_ID'].includes(scope.identifierType)));

    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'policyDisplay',
                type: 'Display',
                state: {
                    policyId: null,
                    isPolicyFormActive: false
                }
            },
            {
                key: 'policyTablet',
                type: 'Tablet',
                state: {
                    isDisabled: false
                }
            },
            {
                key: 'policyForm',
                type: 'Form',
                state: {
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'POLICY_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }
}

class PolicyApi extends DetailsApi {

    static location = '/security';
    static type = '/policies';

    static getIdentifierTypes() {
        return axiosInstance.get('/scheduler/security/policies/identifierTypes');
    }
}

export class PolicySaga extends DetailsSaga {

    static ModelType = PolicyModel;
    static ModelApi = PolicyApi;

    static activationComponent = 'POLICY_DISPLAY';
    static variableNames = {
        detailsMap: 'policyDetailsMap',
        instanceId: 'policyId',
        isFormActive: 'isPolicyFormActive',
        updateDisplay: 'updateDisplay',
        updatePane: 'updateTablet',
        route: routes.SETTINGS
    };

    static translations = {
        itemTitle: '$t(policy:label.name_simple)',
        itemLower: '$t(policy:label.name_simple_lower)'
    };

    static buildActivationEffects(dispatch) {
        return [
            ...super.buildActivationEffects(dispatch),
            // ACTIVATION EFFECTS
            contextPollUntil(PolicyModel.actions.STOP_POLLING_DETAILS, QUERY_INTERVAL, this, 'getModelNames'),
            fork(function* () {
                yield take(PolicyModel.actions.SET_DETAILS_MAP);
                yield contextCall(this, 'getModelNames');
            }.bind(this)),

            put(PolicyModel.actionCreators.startPollingDetails()),
            contextSpawn(PolicySaga, 'populateIdentifierTypes')
        ]
    }

    static buildDeactivationEffects() {
        return [
            ...super.buildDeactivationEffects(),
            // DEACTIVATION EFFECTS
            put(PolicyModel.actionCreators.stopPollingDetails())
        ]
    }

    static* setInstanceId(args) {
        const {updateDisplay, instanceId} = this.variableNames;

        yield all ([
            put(SchedulerModel.actionCreators.setSettingsDisplay(settingsDisplayKeys.POLICIES)),
            put(this.ModelType.componentActionCreators[updateDisplay]({[instanceId]: args.id}))
        ]);
    }

    static* showForm() {
        const {updateDisplay, isFormActive} = this.variableNames;

        yield contextCall(this, 'populateModels');
        yield put(PolicyModel.componentActionCreators[updateDisplay]({[isFormActive]: true}));
    }

    static* startEdit(action) {
        const {id} = action.payload;

        yield call(isDisabledWrapper, PolicyModel.componentActionCreators.updateTablet, contextSaga(this, 'populateModels'));
        const editValues = yield contextCall(this, 'getEditValues', id);

        // this.name is the model class
        yield all([
            put(EditModel.actionCreators.start(this.ModelType.nom, editValues)),
            retryCall(contextSaga(this, 'waitForEditAction'), id, editValues)
        ]);

        yield put(EditModel.actionCreators.reset());
    }

    static* getEditValues(id) {
        const {name: policyName, description, principals, permissions, scope} = yield select(state => state.policyDetailsMap.get(id));

        const policyPermissionNameValues = getValues(policyPermissionKeys)
            .map(value => {
                return {name: i18n.t(`common:permission.${value}`), value}
            })
            .sort(nameLocaleCompare)
        const {available: availablePermissions, selected: selectedPermissions} = separateNameValues(permissions, policyPermissionNameValues);

        return {
            id,
            policyName,
            description,
            ...this.separateEditPrincipals(principals),
            availablePermissions,
            selectedPermissions,
            ...(yield contextCall(this, 'separateEditScopes', id, scope))
        }
    }

    static* getSaveValues(values) {
        const {id, policyName: name, description, enabled, selectedPrincipals, selectedPermissions, selectedScopes} = values;

        const principals = [];
        for (let i = 0; i < selectedPrincipals.items.length; i++) {
            const {value, type} = selectedPrincipals.items[i];

            principals[i] = {
                identifierName: value,
                identifierType: type
            }
        }

        const permissions = [];
        for (let i = 0; i < selectedPermissions.items.length; i++) {
            const {value} = selectedPermissions.items[i];

            permissions[i] = value;
        }

        const scope = [];
        for (let i = 0; i < selectedScopes.items.length; i++) {
            const {value, type} = selectedScopes.items[i];

            scope[i] = {
                identifierName: value,
                identifierType: type
            }
        }

        if (PolicyModel.isUsingModifyOnPolicyScopes({permissions, scope})) {
            const oldPolicy = yield select(state => state.policyDetailsMap.get(id))
            if (oldPolicy == null || !PolicyModel.isUsingModifyOnPolicyScopes(oldPolicy)) {
                const options = {
                    confirmTitleKey: 'common:option.yes',
                    cancelTitleKey: 'common:option.no'
                }
                yield contextCall(this, 'showSaveWarning', {key: 'securityPolicyWithCodeExecution'}, options);
            }
        }

        return {
            name,
            description,
            enabled,
            principals,
            permissions,
            scope
        };
    }

    static separateEditPrincipals(principals) {
        const selected = [];
        for (let i = 0; i < principals.length; i++) {
            const {identifierName, identifierType} = principals[i];

            selected[i] = {
                value: identifierName,
                type: identifierType
            };
        }
        selected.sort(nameLocaleCompare);

        const selectedKeys = selected.map(getGeneralizedItemKey);
        const available = getValues(policyPrincipalBuiltInKeys)
            .filter(value => !selectedKeys.includes(value))
            .map(value => ({
                value,
                type: policyPrincipalTypeKeys.BUILTIN
            }))
            .sort(nameLocaleCompare);

        return {
            selectedPrincipals: {
                items: selected,
                selectedItems: {}
            },
            availablePrincipals: {
                inputValue: '',
                items: available,
                selectedItems: {}
            }
        }
    }

    static* separateEditScopes(id, scope) {
        const nameUpdatedScope = getTabletUpdatedPolicyDetailsScope(yield select(), {policyId: id});

        const selected = [];
        for (let i = 0; i < scope.length; i++) {
            const {identifierName: value, identifierType: type} = scope[i];
            const {identifierName: name} = nameUpdatedScope[i];

            selected.push({
                name,
                value,
                type
            });
        }

        selected.sort(nameLocaleCompare);
        return {
            selectedScopes: {
                items: selected,
                selectedItems: {}
            },
            availableScopes: {
                items: [],
                selectedItems: {}
            },
            availableScopeParents: {
                items: [],
                selectedItems: {}
            }
        }
    }

    static* queryDetails() {
        const response = yield contextCall(PolicyApi, 'getDetails');
        const key = details.POLICIES;

        if (AxiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(PolicyModel.actionCreators.setDetailsMap(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }

    static* getModelNames() {
        const policyDetailsMap = yield select(state => state.policyDetailsMap);
        const typeNameMap = {
            [policyScopeTypeKeys.CLIENT_ID]: ClientModel.nom,
            [policyScopeTypeKeys.CLIENT_ID_MATTERS]: ClientModel.nom,
            [policyScopeTypeKeys.MATTER_ID]: MatterModel.nom,
            [policyScopeTypeKeys.LIBRARY_ID]: LibraryModel.nom,
            [policyScopeTypeKeys.WORKFLOW_ID]: WorkflowTemplateModel.nom,
            [policyScopeTypeKeys.NUIX_LICENCE_SOURCE_ID]: LicenceSourceModel.nom,
            [policyScopeTypeKeys.EXECUTION_PROFILE_ID]: ExecutionProfileModel.nom,
            [policyScopeTypeKeys.RESOURCE_POOL_ID]: ResourcePoolModel.nom,
            [policyScopeTypeKeys.CLIENT_POOL_ID]: ClientPoolModel.nom,
            [policyScopeTypeKeys.NOTIFICATION_RULE_ID]: NotificationRuleModel.nom,
            [policyScopeTypeKeys.DATA_REPOSITORY_ID]: DataRepositoryModel.nom,
            [policyScopeTypeKeys.THIRD_PARTY_SERVICE_ID]: ThirdPartyServiceModel.nom,
            [policyScopeTypeKeys.NOTICE_TEMPLATE_ID]: NoticeTemplateModel.nom,
            [policyScopeTypeKeys.LEGAL_HOLD_ID]: LegalHoldModel.nom,
            [policyScopeTypeKeys.FILE_LIBRARY_ID]: FileLibraryModel.nom,
            [policyScopeTypeKeys.USER_SERVICE_ID]: UserServiceModel.nom
        };

        const idToType = {};
        getValues(policyDetailsMap)
            .map(policy => policy.scope)
            .forEach(scopes => {
                for (const scope of scopes) {
                    const {identifierName: id, identifierType: type} = scope;

                    const typeName = switchcase(typeNameMap)()(type);
                    if (typeName != null) {
                        idToType[id] = typeName;
                    }
                }
            });

        const typeToUpdate = yield contextCall(DetailsSaga, 'queryModelNames', idToType);
        const updateActionMap = {
            [ClientModel.nom]: ClientModel.actionCreators.updateDetails,
            [MatterModel.nom]: MatterModel.actionCreators.updateDetails,
            [LibraryModel.nom]: LibraryModel.actionCreators.updateDetails,
            [WorkflowTemplateModel.nom]: WorkflowTemplateModel.actionCreators.updateDetails,
            [LicenceSourceModel.nom]: LicenceSourceModel.actionCreators.updateDetails,
            [ExecutionProfileModel.nom]: ExecutionProfileModel.actionCreators.updateDetails,
            [ResourcePoolModel.nom]: ResourcePoolModel.actionCreators.updateDetails,
            [ClientPoolModel.nom]: ClientPoolModel.actionCreators.updateDetails,
            [NotificationRuleModel.nom]: NotificationRuleModel.actionCreators.updateDetails,
            [DataRepositoryModel.nom]: DataRepositoryModel.actionCreators.updateDetails,
            [ThirdPartyServiceModel.nom]: ThirdPartyServiceModel.actionCreators.updateDetails,
            [NoticeTemplateModel.nom]: NoticeTemplateModel.actionCreators.updateDetails,
            [LegalHoldModel.nom]: LegalHoldModel.actionCreators.updateDetails,
            [FileLibraryModel.nom]: FileLibraryModel.actionCreators.updateDetails,
            [UserServiceModel.nom]: UserServiceModel.actionCreators.updateDetails
        };

        const updateEffects = [];
        getEntries(typeToUpdate)
            .forEach(([type, updates]) => {

                const updateActionCreator = switchcase(updateActionMap)()(type);
                if (updateActionCreator != null) {
                    updateEffects.push(
                        put(updateActionCreator(updates))
                    );
                }
            });

        yield all(updateEffects);
    }

    static* populateModels() {
        yield all([
            ClientSaga,
            LibrarySaga,
            ResourcePoolSaga,
            ClientPoolSaga,
            ExecutionProfileSaga,
            LicenceSourceSaga,
            NotificationRuleSaga,
            DataRepositorySaga,
            ThirdPartyServiceSaga,
            FileLibrarySaga,
            NoticeTemplateSaga,
            LegalHoldSaga,
            UserServiceSaga
        ].map(saga => contextCall(saga, 'queryDetails')));
    }

    static* populateIdentifierTypes() {
        const {data} = yield contextCall(PolicyApi, 'getIdentifierTypes');

        setPolicyPrincipalTypeKeys(data.principal);
        setPolicyScopeBuiltInKeys(data.scope['BUILTIN']);
    }

    policyDetailsMap;
    static* isDetailsMapChanged () {
        const policies = yield select (state => state.policyDetailsMap);

        if (!objEquals(policies, this.policyDetailsMap)) {
            yield all([
                CurrentUserSaga.queryFeatures(), 
                SchedulerSaga.queryResourcesStatus()
            ]);
        }

        this.policyDetailsMap = policies;
    }
}

export default PolicyModel;