import React, {useLayoutEffect} from 'react';
import {useTranslation} from "react-i18next";
import {downloadLogs} from "../../../utilities/downloadHelper";
import {userSettings, utilizationEndpoints} from "../../../utilities/constants";
import {SettingsRowValue} from "../../common/CustomTable/CustomTable";
import {useDispatch, useSelector} from "react-redux";
import {createFileHandler} from "../../../utilities/componentFunctions";
import encode from "base64-arraybuffer";
import UtilizationModel from "../../../models/scheduler/UtilizationModel";
import RelativityProxyModel from "../../../models/relativity/RelativityProxyModel";
import SchedulerModel from "../../../models/scheduler/SchedulerModel";


function DownloadUtilizationOptions (props) {
    const {t} = useTranslation(['utilization', 'common'])
    const dispatch = useDispatch();

    const {enabledInferredUtilizationEditing} = useSelector(state => state.userSettingsMap.get(userSettings.TROUBLESHOOT));
    const {
        canDownloadFull,
        canDownloadAnonymous,
        canUploadUtilization
    } = props;


    useLayoutEffect(() => {
        dispatch(UtilizationModel.componentActionCreators.setDisplayActive());
        return () => dispatch(UtilizationModel.componentActionCreators.setDisplayInactive());
    }, [dispatch]);

    const {
        sessionsCount,
        statusMessage
    } = useSelector(state => state.utilizationStatistics);

    const {
        isDisabled: isUtilizationDisplayDisabled
    } = useSelector(state => state.componentStates.utilizationDisplay);

    const isDisabled = isUtilizationDisplayDisabled || props.isDisabled;

    const isRelativityApplication = useSelector(state => state.schedulerDetails.isRelativityApplication);

    const download = (endpoint) => {
        if (isRelativityApplication) {
            dispatch(RelativityProxyModel.actionCreators.proxyDownload(endpoint, null, SchedulerModel.componentActionCreators.updateSettingsPage));
        } else {
            downloadLogs(endpoint);
        }
    }

    const downloadFull = () => {
        download(utilizationEndpoints.FULL);
    }

    const downloadInferred = () => {
        download(utilizationEndpoints.INFERRED);
    }

    const downloadAnonymized = () => {
        download(utilizationEndpoints.ANONYMIZED);
    }

    const upload = () => {
        const input = document.getElementById("utilizationFileUploadInput");
        input.click();
    }

    const uploadInferred = () => {
        const input = document.getElementById("utilizationInferredFileUploadInput");
        input.click();
    }

    const onFileChange =
        createFileHandler({
            onloadend: (event, file) => {
                const fileData = encode.encode(event.target.result);
                dispatch(UtilizationModel.actionCreators.submitFile(
                    fileData
                ));
            }
        })

    const onInferredFileChange =
        createFileHandler({
            onloadend: (event, file) => {
                const fileData = encode.encode(event.target.result);
                dispatch(UtilizationModel.actionCreators.submitInferredFile(
                    fileData
                ));
            }
        })


    return (
        <>
            <div className="settings-table">
                <div className="table-row-group">
                    <SettingsRowValue label={t('utilization:label.sessionsCount')} value={sessionsCount}
                                      enableHighlightText isDisabled={isDisabled}/>

                    <SettingsRowValue label={t('utilization:label.statusMessage')} value={statusMessage}
                        enableHighlightText isDisabled={isDisabled}/>
                </div>
            </div>

            <div className="add-button-header">
                <button type="button" className="button is-small" onClick={downloadFull}
                        disabled={!canDownloadFull || isDisabled}>
                        <span>
                            {t('utilization:label.downloadFull')}
                        </span>
                </button>

                <button type="button" className="button is-small" onClick={downloadAnonymized}
                        disabled={!canDownloadAnonymous || isDisabled}>
                        <span>
                            {t('utilization:label.downloadAnonymized')}
                        </span>
                </button>

                <button type="button" className="button is-small" onClick={upload}
                        disabled={!canUploadUtilization || isDisabled}>
                        <span>
                            {t('utilization:label.upload')}
                        </span>
                </button>
            </div>

            {enabledInferredUtilizationEditing &&
            <div className="add-button-header">
                <button type="button" className="button is-small" onClick={downloadInferred}
                        disabled={!canDownloadFull || isDisabled}>
                        <span>
                            {t('utilization:label.downloadInferred')}
                        </span>
                </button>


                <button type="button" className="button is-small" onClick={uploadInferred}
                        disabled={!canUploadUtilization || isDisabled}>
                        <span>
                            {t('utilization:label.uploadInferred')}
                        </span>
                </button>
            </div>
            }


            <input style={{display: "none"}} id="utilizationFileUploadInput" type="file" accept=".json,.zip"
                   onChange={onFileChange} disabled={isDisabled}/>

            <input style={{display: "none"}} id="utilizationInferredFileUploadInput" type="file" accept=".csv,.zip"
                   onChange={onInferredFileChange} disabled={isDisabled}/>

        </>
    )
}



export default DownloadUtilizationOptions;