import {useCallback} from 'react';
import {
    binarySearch,
    getGeneralizedItemKey,
    getValues,
    nameLocaleCompare,
    objectTruthyValues,
    pauseLoop,
    switchcase
} from "../../../utilities/helperFunctions";
import {put} from "redux-saga/effects";
import {updateEditState} from "../../../utilities/componentFunctions";
import {policyScopeTypeKeys, policyScopeTypeOptionKeys} from "../../../i18next/keys";
import {updateMode} from "../../../utilities/constants";
import MatterModel from "../../../models/client/MatterModel";
import WorkflowTemplateModel from "../../../models/library/WorkflowTemplateModel";

export function onAddItemsToList({listTo, listFrom, stateUpdater, mode, isEnabled, shouldEnable, passwordName, passwordRef}) {
    switch (mode) {
        case updateMode.LOCAL:
        case updateMode.REDUX:
            return function() {
                stateUpdater.call(this, prevState => {
                    const [ prevTo, prevFrom ] = [prevState[listTo], prevState[listFrom]];
                    const updates = buildAddItemsUpdates(listTo, listFrom, prevTo, prevFrom);

                    if (isEnabled && shouldEnable) {
                        updates[isEnabled] = shouldEnable({
                            ...prevState,
                            ...updates,
                            [passwordName]: passwordRef
                        });
                    }
                    return updates;
                });
            };
        case updateMode.EDIT:
            return function() {
                const { [listTo]: prevTo, [listFrom]: prevFrom } = this.props;
                const updates = buildAddItemsUpdates(listTo, listFrom, prevTo, prevFrom);

                updateEditState.call(this, updates, {shouldEnable});
            };
        default:
            break;
    }
}

export function useAddRemoveItems(options) {
    const {lists, updateState} = options;

    const addListTwoToListOne = useCallback(() => {
        const [listOne, listTwo] = lists;
        updateState(prevState => {
            const {[listOne]: prevListOne, [listTwo]: prevListTwo} = prevState;

            return buildAddItemsUpdates(listOne, listTwo, prevListOne, prevListTwo);
        });
    }, [lists, updateState]);

    const addListOneToListTwo = useCallback(() => {
        const [listOne, listTwo] = lists;
        updateState(prevState => {
            const {[listOne]: prevListOne, [listTwo]: prevListTwo} = prevState;

            return buildAddItemsUpdates(listTwo, listOne, prevListTwo, prevListOne);
        });
    }, [lists, updateState]);

    return [addListTwoToListOne, addListOneToListTwo];
}

export function onSelectListItem({listName, stateUpdater, mode}) {
    switch (mode) {
        case updateMode.LOCAL:
        case updateMode.REDUX:
            return function (event, filteredIndices) {
                const index = parseInt(event.target.dataset.index || event.currentTarget.dataset.index);
                const keyPressed = {
                    ctrl: event.ctrlKey,
                    shft: event.shiftKey
                };

                if (this.lastSelectedValue == null) {
                    this.lastSelectedValue = {};
                }

                stateUpdater.call(this, prevState => {
                    return buildSelectItemsUpdates(listName, index, prevState, keyPressed, this.lastSelectedValue, filteredIndices);
                });
            };
        case updateMode.EDIT:
            return function (event, filteredIndices) {
                const index = parseInt(event.target.dataset.index || event.currentTarget.dataset.index);
                const keyPressed = {
                    ctrl: event.ctrlKey,
                    shft: event.shiftKey
                };

                if (this.lastSelectedValue == null) {
                    this.lastSelectedValue = {};
                }

                const updates = buildSelectItemsUpdates(listName, index, this.props, keyPressed, this.lastSelectedValue, filteredIndices);
                updateEditState.call(this, updates, {stateUpdater});
            };
        default:
            break;
    }
}

export function addItemsToList(prevTo, prevFrom) {
    const to = [...prevTo.items];
    const from = [];

    for (let i = 0; i < prevFrom.items.length; i++) {
        const item = prevFrom.items[i];

        if (prevFrom.selectedItems[i]) {
            //Find sorted index of item then splice item into sorted array
            const sortedIndex = binarySearch(to, item, nameLocaleCompare);
            to.splice(sortedIndex, 0, item);
        } else {
            from.push(item);
        }
    }
    to.sort(nameLocaleCompare);
    from.sort(nameLocaleCompare);
    return {to, from};
}

export function addScopesToList(prevTo, prevFrom, prevParents) {
    const {to, from} = addItemsToList(prevTo, prevFrom);

    const parentValues = [...this.props.scopeValues[policyScopeTypeKeys.CLIENT_ID], ...this.props.scopeValues[policyScopeTypeKeys.LIBRARY_ID]];
    const selected = to.map(item => {
        if ([policyScopeTypeKeys.CLIENT_ID, policyScopeTypeKeys.CLIENT_ID_MATTERS, policyScopeTypeKeys.LIBRARY_ID].includes(item.type)) {
            const parent = parentValues.find(p => p.value === item.value);
            item.name = parent.name;
        }
        return item;
    }).filter(item => {
        // Filter out all items whose parent is present
        return !to.find(i => (i.value === item.parentId || (i.value === item.value && i.type === policyScopeTypeKeys.CLIENT_ID && item.type === policyScopeTypeKeys.CLIENT_ID_MATTERS)));
    });

    const selectedValues = selected.map(item => item.value);
    //Filter out from whose parent is now selected
    const filteredFrom = from.filter(item => !selectedValues.find(value => value === item.value || value === item.parentId));
    //Filter out parents that are now selected
    const availableParentItems = prevParents.items.filter(parent => !selectedValues.includes(parent.value));

    //Go through each selectedParent and check if it is in new available parents (Case for when it is moved to selected list and should be removed from selectedItems in available parents)
    const selectedParents = {};
    const parentKeys = availableParentItems.map(p => p.value);

    for (const index of objectTruthyValues(prevParents.selectedItems)) {
        const parentItem = prevParents.items[index];
        selectedParents[index] = parentItem != null && parentKeys.includes(parentItem.value);
    }

    return {to: selected, from: filteredFrom, parents: {items: availableParentItems, selectedItems: selectedParents}};
}

export function removeScopesFromList(prevTo, prevFrom, prevParents, scopeType) {
    const {to, from} = addItemsToList(prevTo, prevFrom);
    const parentItems = [...prevParents.items];

    //Filter to only include items relevant to selected scopeType
    const scopeTypeFiltered = to.filter(item => scopeType && (scopeType.includes(item.type) || item.type.includes(scopeType.slice(0, -3))));
    //Filter items into parentItems if they are of type Client or Library and filter rest into childItems
    const childItems = [];
    scopeTypeFiltered.forEach(item => {
        if ([policyScopeTypeKeys.CLIENT_ID, policyScopeTypeKeys.CLIENT_ID_MATTERS, policyScopeTypeKeys.LIBRARY_ID].includes(item.type)) {
            //For All Matters/Workflows
            if (item.name == null) {
                //Always keeps at top of list
                childItems.unshift(item);
            } else {
                // Is a Client/Library item
                // Find sorted index for parents
                const sortedIndex = binarySearch(parentItems, item, nameLocaleCompare);
                parentItems.splice(sortedIndex, 0, item);
            }

        } else {
            childItems.push(item);
        }
    });

    //Filter out childItems with parents not selected (If they don't have a parent, then they have no parent/child relationship and should be shown)
    const selectedParents = objectTruthyValues(prevParents.selectedItems);
    const selected = childItems.filter(item => {
        return item.parentId == null || selectedParents.find(index => parentItems[index].value === item.parentId);
    });

    return {to: selected, from, parentItems};
}

export function addInputToList(to, prevFrom, type) {
    const value = prevFrom.inputValue;
    const key = type + value;

    const item = {
        type,
        name: value,
        value: value,
        key
    };

    to.every(item => item.key !== key) && to.push(item);
}

export function removeInputFromList(prevTo, prevFrom, type, ...listItemTypes) {
    const to = [...prevTo.items];
    const from = [];

    for (let i = 0; i < prevFrom.items.length; i++) {
        const item = prevFrom.items[i];

        if (prevFrom.selectedItems[i]) {
            if (listItemTypes.includes(item.type)) {
                to.push(item);
            }
        } else {
            from.push(item);
        }
    }

    const selectedIndices = objectTruthyValues(prevFrom.selectedItems);
    // If last selected item shares the same type as the currently selected @param=type
    // And it does not belong to one of the listItemTypes (e.g. Built-In types)
    // Then it is assumed to be an inputValue
    // So get its value and return it as the inputValue, if not use previous inputValue from prevTo
    const lastIndex = selectedIndices[selectedIndices.length - 1];
    const lastItem = prevFrom.items[lastIndex];
    const inputValue = type === lastItem.type && !listItemTypes.includes(type) ? lastItem.value : prevTo.inputValue;

    to.sort(nameLocaleCompare);
    from.sort(nameLocaleCompare);
    return {to, from, inputValue};
}

export function getScopeParentItems(scopeType, selectedScopes) {
    const type = switchcase({
        [policyScopeTypeOptionKeys.CLIENT_ID_MATTER_ID]: policyScopeTypeKeys.CLIENT_ID,
        [policyScopeTypeOptionKeys.LIBRARY_ID_WORKFLOW_ID]: policyScopeTypeKeys.LIBRARY_ID
    })()(scopeType);

    return filterWithSelected.call(this, type, selectedScopes);
}

export function getScopeItems(scopeType, selectedScopes) {
    const type = switchcase({
        [policyScopeTypeOptionKeys.NUIX_LICENCE_SOURCE_ID]: policyScopeTypeKeys.NUIX_LICENCE_SOURCE_ID,
        [policyScopeTypeOptionKeys.EXECUTION_PROFILE_ID]: policyScopeTypeKeys.EXECUTION_PROFILE_ID,
        [policyScopeTypeOptionKeys.CLIENT_POOL_ID]: policyScopeTypeKeys.CLIENT_POOL_ID,
        [policyScopeTypeOptionKeys.RESOURCE_POOL_ID]: policyScopeTypeKeys.RESOURCE_POOL_ID,
        [policyScopeTypeOptionKeys.NOTIFICATION_RULE_ID]: policyScopeTypeKeys.NOTIFICATION_RULE_ID,
        [policyScopeTypeOptionKeys.DATA_REPOSITORY_ID]: policyScopeTypeKeys.DATA_REPOSITORY_ID,
        [policyScopeTypeOptionKeys.THIRD_PARTY_SERVICE_ID]: policyScopeTypeKeys.THIRD_PARTY_SERVICE_ID,
        [policyScopeTypeOptionKeys.NOTICE_TEMPLATE_ID]: policyScopeTypeKeys.NOTICE_TEMPLATE_ID,
        [policyScopeTypeOptionKeys.LEGAL_HOLD_ID]: policyScopeTypeKeys.LEGAL_HOLD_ID,
        [policyScopeTypeOptionKeys.USER_SERVICE_ID]: policyScopeTypeKeys.USER_SERVICE_ID,
        [policyScopeTypeOptionKeys.FILE_LIBRARY_ID]: policyScopeTypeKeys.FILE_LIBRARY_ID,
        [policyScopeTypeOptionKeys.BUILTIN]: policyScopeTypeKeys.BUILTIN
    })()(scopeType);

    return filterWithSelected.call(this, type, selectedScopes);
}

export function getScopeChildItems(scopeType, selectedScopes, availableScopeParents) {
    const items = [];
    const type = switchcase({
        [policyScopeTypeOptionKeys.CLIENT_ID_MATTER_ID]: {item: policyScopeTypeKeys.MATTER_ID, parent: policyScopeTypeKeys.CLIENT_ID},
        [policyScopeTypeOptionKeys.LIBRARY_ID_WORKFLOW_ID]: {item: policyScopeTypeKeys.WORKFLOW_ID, parent: policyScopeTypeKeys.LIBRARY_ID}
    })()(scopeType);

    const selectedValues = selectedScopes.items.map(item => item.value);
    const selectedParentValues = [];
    for (const index of objectTruthyValues(availableScopeParents.selectedItems)) {
        if (availableScopeParents.items[index] != null) {
            selectedParentValues.push(availableScopeParents.items[index].value);
        }
    }

    selectedParentValues.forEach(value => {
        const allMattersSelected = selectedScopes.items.find(scope => scope.type === type.parent && scope.value === value);
        if (allMattersSelected == null) {
            items.push({type: type.parent, value});
        }

        // 'All Matters' && 'Entire Client'
        if (scopeType === policyScopeTypeOptionKeys.CLIENT_ID_MATTER_ID) {
            const entireClientSelected = selectedScopes.items.find(scope => scope.type === policyScopeTypeKeys.CLIENT_ID_MATTERS && scope.value === value);
            if (entireClientSelected == null) {
                items.push({type: policyScopeTypeKeys.CLIENT_ID_MATTERS, value});
            }
        }
    });

    for (const item of this.props.scopeValues[type.item]) {
        // If item is not already in selected list and if it's parent is selected
        if (!selectedValues.includes(item.value) && selectedParentValues.includes(item.parentId)) {
            //Push to items
            items.push(item);
        }
    }

    return items;
}

export function getSelectedParents(event, items) {
    const index = parseInt(event.target.dataset.index || event.currentTarget.dataset.index);
    const parent = items[index];

    const querySaga = switchcase({
        [policyScopeTypeKeys.CLIENT_ID]: put(MatterModel.actionCreators.queryDetailsSubset(parent.value)),
        [policyScopeTypeKeys.LIBRARY_ID]: put(WorkflowTemplateModel.actionCreators.queryDetailsSubset(parent.value))
    })()(parent.type);

    this.props.yieldEffect(querySaga);
    this.lastSelectedValue = {};
    return {
        items: {[index]: parent},
        selectedItems: {[index]: true}
    };
}

function filterWithSelected(type, selectedScopes) {
    const selectedKeys = selectedScopes.items.map(item => getGeneralizedItemKey(item));
    const values = this.props.scopeValues[type];

    return values ? values.filter(item => {
        const key = getGeneralizedItemKey(item);

        return !selectedKeys.includes(key);
    }) : [];
}

function buildAddItemsUpdates(listTo, listFrom, prevTo, prevFrom) {
    const { to, from } = addItemsToList(prevTo, prevFrom);
    return {
        [listTo]: {
            ...prevTo,
            items: to
        },
        [listFrom]: {
            ...prevFrom,
            items: from,
            selectedItems: {}
        }
    };
}

function buildSelectItemsUpdates(listName, index, prevState, keyPressed, lastSelectedValue, filteredIndices) {
    const list = prevState[listName];
    let selectedItems = {};
    if (keyPressed.ctrl || keyPressed.shft) {
        selectedItems = {...list.selectedItems};
    }

    // False IFF CTRL key pressed and was previously selected
    selectedItems[index] = !keyPressed.ctrl || !selectedItems[index];

    if (keyPressed.shft && lastSelectedValue.current != null) {
        // Get index start/end range
        let start, end;
        if (index > lastSelectedValue.current) {
            start = lastSelectedValue.current;
            end = index;
        } else {
            start = index;
            end = lastSelectedValue.current;
        }

        for (let i = start; i < end; i++) {
            if (filteredIndices == null || filteredIndices.has(i)) {
                selectedItems[i] = true;
            }
        }
    }

    lastSelectedValue.current = index;
    return {
        [listName]: {
            ...list,
            selectedItems
        }
    }
}

export async function asyncSeparateItems(selected, values) {
    const left = [], right = [];

    await pauseLoop(selected, val => {
        if (!!val) {
            const nameValue = values.find(v => v.value === val) || {name: val, value: val};
            right.push(nameValue);
        }
    });

    await pauseLoop(values, val => {
        if (!right.includes(val)) {
            left.push(val);
        }
    });

    return {
        left,
        right
    }
}

export function separateItems(selected, values) {
    const left = [], right = [];

    for (const nameValue of values) {
        if (selected.includes(nameValue.value)) {
            right.push(nameValue);
        } else {
            left.push(nameValue);
        }
    }

    for (const val of selected) {
        if (!val) continue;
        const nameValue = right.find(v => v.value === val);
        if (nameValue == null) {
            right.push({name: val, value: val});
        }
    }

    return {
        left,
        right
    }
}

export function separateMultiItems(selected, values) {
    const left = [], right = [];

    const rightItems = {};
    getValues(selected)
        // Push selected to right
        .forEach(vals => {

            right.push(
                (vals || [])
                    // Get rid of empty/null ids
                    .filter(val => !!val)
                    // If a selected value no longer exists in @values(deleted or hidden etc...)
                    // Build a nameValue object for it and add it to right list
                    .map(val => {

                        const item = values.find(v => v.value === val);
                        if (item != null) {
                            // Track taken
                            rightItems[item.value] = true;
                            return item;
                        }
                        return {name: val, value: val};
                    })
            );
        });

    // Place remaining nameValues into left
    left.push(
        values.filter(val => !rightItems[val.value])
    );

    return {
        left,
        right
    };
}

export function separateNameValues(ids, nameValues) {
    const available = [], selected = [];

    ids
        // Get rid of empty/null ids
        .filter(id => !!id)
        .forEach(id => {
            // If an id is not represented in nameValues from Redux (deleted or hidden etc...)
            // Build a nameValue object for it and add it to list
            const nameValue = nameValues.find(item => item.value === id) || {name: id, value: id};
            selected.push(nameValue);
        });

    // Place remaining nameValues into available
    nameValues
        .filter(nameValue => !selected.includes(nameValue))
        .forEach(nameValue => {
            available.push(nameValue);
        });

    available.sort(nameLocaleCompare);
    selected.sort(nameLocaleCompare);

    return {
        available: {
            items: available,
            selectedItems: {}
        },
        selected: {
            items: selected,
            selectedItems: {}
        }
    }
}
