import ReduxModel from "../generics/ReduxModel";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import SagaRunnable from "../generics/SagaRunnable";
import ComponentStateModel from "../generics/ComponentStateModel";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import {all, put, takeLeading} from "redux-saga/effects";
import {actionCreator} from "../../utilities/helperFunctions";
import {tryCatchWrapper} from "../../saga/tryCatchWrapper";
import {contextCall, contextPollUntil, contextSaga, isDisabledWrapper} from "../../saga/sagaFunctions";
import {details} from "../../utilities/constants";


class UserDataDirModel extends ReduxModel {

    static nom = 'UserDataDirModel';
    static actions = UserDataDirModel.buildActions('USER_DATA_DIR');
    static actionCreators = UserDataDirModel.buildActionCreators(UserDataDirModel.actions);
    static reducer = UserDataDirModel.buildReducer(UserDataDirModel.actions);

    static componentActionCreators = {
        ...UserDataDirModel.buildComponentUpdateActionCreators(),
        ...UserDataDirModel.buildComponentSetActiveActionCreators()
    };

    constructor(model={}) {
        super();
        this.forceUpdate(model);
        this.files = model.files || [];
    }

    static buildActions(type) {
        return {
            SET_USER_DATA_DIR: `SET_${type}`,
            SET_FILES: `SET_${type}_FILES`,
            REMOVE_USER_DATA_DIR: `REMOVE_${type}`,
            SUBMIT_FORM: `SUBMIT_${type}`,

            START_POLLING_DETAILS: `START_POLLING_${type}`,
            STOP_POLLING_DETAILS: `STOP_POLLING_${type}`,
            QUERY_DETAILS: `QUERY_${type}`,

            START_POLLING_SETTINGS: `START_POLLING_${type}_SETTINGS`,
            STOP_POLLING_SETTINGS: `STOP_POLLING_${type}_SETTINGS`,
            QUERY_SETTINGS: `QUERY_${type}_SETTINGS`,
        };
    }

    static buildActionCreators(actions) {
        return {
            setUserDataDir: actionCreator(actions.SET_USER_DATA_DIR, 'userDataDir'),
            setFiles: actionCreator(actions.SET_FILES, 'files'),
            removeUserDataDir: actionCreator(actions.REMOVE_USER_DATA_DIR),
            submitForm: actionCreator(actions.SUBMIT_FORM, 'formData'),

            startPollingDetails: actionCreator(actions.START_POLLING_DETAILS, 'period'),
            stopPollingDetails: actionCreator(actions.STOP_POLLING_DETAILS),
            queryDetails: actionCreator(actions.QUERY_DETAILS),

            startPollingSettings: actionCreator(actions.START_POLLING_SETTINGS, 'period'),
            stopPollingSettings: actionCreator(actions.STOP_POLLING_SETTINGS),
            querySettings: actionCreator(actions.QUERY_SETTINGS),
        };
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'userDataDirDisplay',
                type: 'Display',
                state: {
                    isTabletActive: false,
                    isFormActive: false
                }
            },
            {
                key: 'userDataDirForm',
                type: 'Form',
                state: {
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'USER_DATA_DIR_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }

    static buildReducer(actions) {
        return function (state = new this(), action) {
            switch (action.type) {
                case actions.SET_USER_DATA_DIR: {
                    const {userDataDir} = action.payload;
                    this.lastUpdated = Date.now();
                    const newState = new this(userDataDir);
                    if (state.id === newState.id) {
                        newState.files = state.files;
                    }
                    return newState;
                }
                case actions.SET_FILES: {
                    const {files} = action.payload;
                    return state.shallowDuplicate({files});
                }
                case actions.REMOVE_USER_DATA_DIR: {
                    this.lastUpdated = Date.now();
                    return {};
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }
}

export class UserDataDirApi {

    static get() {
        return axiosInstance.get('/scheduler/resources/userDataDir');
    }

    static getFiles() {
        return axiosInstance.get('/scheduler/resources/userDataDir/files');
    }

    static update(updates) {
        return axiosInstance.put('/scheduler/resources/userDataDir', updates);
    }

    static remove(id) {
        return axiosInstance.del(`/scheduler/resources/userDataDir/${id}`);
    }
}

export class UserDataDirSaga extends SagaRunnable {

    static activationComponent = 'USER_DATA_DIR_DISPLAY';

    static buildActivationEffects(dispatch) {
        return [
            ...super.buildActivationEffects(dispatch),
            put(UserDataDirModel.actionCreators.startPollingDetails()),
            takeLeading(UserDataDirModel.actions.SUBMIT_FORM, tryCatchWrapper, isDisabledWrapper, UserDataDirModel.componentActionCreators.updateForm, contextSaga(this, 'submitForm'))
        ]
    }

    static buildDeactivationEffects() {
        return [
            ...super.buildDeactivationEffects(),
            put(UserDataDirModel.actionCreators.stopPollingDetails())
        ]
    }

    static* submitForm(action) {
        const {formData} = action.payload;
        const {data} = yield contextCall(UserDataDirApi, 'update', formData);

        yield all([
            put(UserDataDirModel.actionCreators.setUserDataDir(data)),
            put(UserDataDirModel.componentActionCreators.updateDisplay({isFormActive: false}))
        ]);
    }

    static* pollDetails(action) {
        const {period} = action.payload;
        yield contextPollUntil(UserDataDirModel.actions.STOP_POLLING_DETAILS, period, this, 'queryDetails');
    }

    static* pollSettings(action) {
        const {period} = action.payload;
        yield contextPollUntil(UserDataDirModel.actions.STOP_POLLING_SETTINGS, period, this, 'querySettings', action);
    }

    static* querySettings() {
        const response = yield contextCall(UserDataDirApi, 'getFiles');
        const id = 'userDataDirFiles';

        if (AxiosProxy.shouldUpdate(id, response)) {
            yield all([
                put(UserDataDirModel.actionCreators.setFiles(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(id))
            ]);
        }
    }

    static* queryDetails() {
        const response = yield contextCall(UserDataDirApi, 'get');
        const key = details.USER_DATA_DIR;

        if (AxiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(UserDataDirModel.actionCreators.setUserDataDir(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }
}

export default UserDataDirModel;
