import React from 'react';
import {connect, useDispatch} from 'react-redux';
import {useTranslation} from "react-i18next";
import {makeGetUpdatedPolicyDetailsScope} from '../../reselect/selectors';
import {permissionKeys, policyPrincipalTypeKeys, statusKeys} from "../../i18next/keys";
import PolicyModel from "../../models/settings/PolicyModel";
import {StatusNameDescriptionCell, TableEnableToggleCell, TableRow} from "../common/CustomTable/CustomTable";
import {getKeys} from "../../utilities/helperFunctions";
import Text from "../common/Text/Text";

function PolicyRow(props) {
    const {t} = useTranslation(['policy', 'common']);
    const dispatch = useDispatch();

    const {
        scope,
        policy,
        isActive
    } = props;

    const {
        name,
        description,
        enabled,
        hasWarnings,
        principals,
        permissions,
        userPermissions
    } = policy;

    function showTablet(event) {
        dispatch(PolicyModel.actionCreators.showTablet(event.currentTarget.dataset.value));
    }

    function toggleEnabled(event) {
        dispatch(PolicyModel.actionCreators.toggleEnabled(event.currentTarget.dataset.name));
    }

    const scopes = scope.reduce((acc, curr) => {
        if (acc[curr.identifierType] == null) acc[curr.identifierType] = 0;
        acc[curr.identifierType]++;
        return acc;
    }, {});

    const canModify = userPermissions.includes(permissionKeys.MODIFY);
    const status = (hasWarnings && enabled) && {code: statusKeys.WARNING};

    return (
        <TableRow value={policy.id} isActive={isActive} onClick={showTablet}>
            <TableEnableToggleCell ariaLabelKey={'SecurityPolicy'} displayName={name} name={policy.id}
                checked={enabled} onClick={toggleEnabled} isReadOnly={!canModify}/>

            <StatusNameDescriptionCell name={name}
                description={description} status={status}/>

            <div className="table-cell">
                {principals.map(({identifierType, identifierName}) =>
                    <label className="label is-italic is-ellipsis" key={`${identifierType}:${identifierName}`}>
                        {t(`policy:principalType.${identifierType}`)}
                    </label>
                )}
            </div>
            <div className="table-cell">
                {principals.map(({identifierType, identifierName}) =>
                    <label className="label is-ellipsis" key={`${identifierType}:${identifierName}`}>
                        {identifierType === policyPrincipalTypeKeys.BUILTIN ? t(`policy:principalBuiltInType.${identifierName}`) : identifierName}
                    </label>
                )}
            </div>

            <div className="table-cell">
                {permissions.map(permission =>
                    <label className="label is-ellipsis" key={permission}>
                        {t(`common:permission.${permission}`)}
                    </label>
                )}
            </div>

            <div className="table-cell no-stretch">
                {getKeys(scopes).map(identifierType =>
                    <Text key={identifierType} value={t(`policy:scopeIdentifierType.${identifierType}`)}/>
                )}
            </div>
            <div className="table-cell">
                {getKeys(scopes).map(identifierType =>
                    <Text key={identifierType} value={scopes[identifierType]}/>
                )}
            </div>
        </TableRow>
    );
}

const makeMapStateToProps = () => {
    const getUpdatedPolicyDetailsScope = makeGetUpdatedPolicyDetailsScope();
    return (state, ownProps) => {
        return {
            scope: getUpdatedPolicyDetailsScope(state, ownProps),
        };
    }
}

export default connect(makeMapStateToProps)(PolicyRow);
