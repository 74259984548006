import React from "react";
import ThirdPartyServiceModel from "../../../models/thirdparty/ThirdPartyServiceModel";
import ThirdPartyServiceTable from "../ThirdPartyServiceTable";
import {useTranslation} from "react-i18next";
import {TableLabelCell, TableLabelHeader} from "../../common/CustomTable/CustomTable";

function LinkServiceTable(props) {
    return (
        <ThirdPartyServiceTable {...props} type={ThirdPartyServiceModel.Type.LINK} hideScope
            TableHeaders={LinkServiceTableHeaders} TableRowCells={LinkServiceTableRowCells}
        />
    )
}

function LinkServiceTableHeaders() {
    const {t} = useTranslation(['thirdPartyService']);
    return (
        <>
            <TableLabelHeader label={t('thirdPartyService:label.url')}/>
        </>
    )
}

function LinkServiceTableRowCells(props) {
    const {
        thirdPartyService
    } = props;

    return (
        <>
            <TableLabelCell label={thirdPartyService.url}/>
        </>
    )
}

export default LinkServiceTable;
