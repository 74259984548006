import React from 'react';
import ReactDOM from "react-dom";
import App from './App';
import './index.css';
import {HashRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import {unregister} from './serviceWorker';
import configureStore from './configureStore';
import rootSaga from './saga/rootSaga';
import themedFavicon from "./themedFavicon";

themedFavicon();
unregister();

const store = configureStore();
store.runSaga(rootSaga, store.dispatch);

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>,
    document.getElementById('root')
);

export {store as reduxStore};
