import React from "react";
import {useTranslation} from "react-i18next";
import {TableLabelCell, TableLabelHeader} from "../../common/CustomTable/CustomTable";
import ThirdPartyServiceModel from "../../../models/thirdparty/ThirdPartyServiceModel";
import ThirdPartyServiceTable from "../ThirdPartyServiceTable";

function ElasticsearchServiceTable(props) {

    return (
        <ThirdPartyServiceTable {...props} type={ThirdPartyServiceModel.Type.ELASTICSEARCH} showMethod
            TableHeaders={ElasticsearchServiceTableHeaders} TableRowCells={ElasticsearchServiceTableRowCells}
        />
    )
}

function ElasticsearchServiceTableHeaders() {
    const {t} = useTranslation(['thirdPartyService']);
    return (
        <>
            <TableLabelHeader label={t('thirdPartyService:label.url')}/>
        </>
    )
}

function ElasticsearchServiceTableRowCells(props) {
    const {
        thirdPartyService
    } = props;

    return (
        <>
            <TableLabelCell label={thirdPartyService.url}/>
        </>
    )
}

export default ElasticsearchServiceTable;
