import React from "react";
import ToggleSwitch from "../../common/ToggleSwitch/ToggleSwitch";
import Text from "../../common/Text/Text";

function OptionToggleRow(props) {
    const {
        label,
        name,
        checked,
        onClick,

        useTrElement=true,
        children
    } = props;

    if (useTrElement) {
        return (
            <tr>
                <td className="table-cell enable-cell no-stretch">
                    <ToggleSwitch aria-labelledby={`option:${name}Label`} name={name}
                        checked={checked} onClick={onClick}/>
                </td>
                <td className="table-cell">
                    <Text id={`option:${name}Label`} value={label}/>
                </td>
                {children}
            </tr>
        )
    }

    return (
        <div className="options-row">
            <div className="table-cell enable-cell" onClick={event => event.stopPropagation()}>
                <ToggleSwitch aria-labelledby={`option:${name}Label`} name={name}
                    checked={checked} onClick={onClick}/>
            </div>
            <div className="table-cell">
                <Text id={`option:${name}Label`} value={label}/>
            </div>
            {children}
        </div>
    )
}

export default OptionToggleRow;
