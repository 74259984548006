import React, {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {getLocaleDateTimeFromUTC, getNonEmptyValueOrDefault} from "../../../utilities/helperFunctions";
import {useSelector} from "react-redux";
import PurviewCache from "../../../models/guidedjob/PurviewCache";
import {objectIdentifierType} from "../../../utilities/constants";
import Checkbox from "../../common/Checkbox/Checkbox";
import Text from "../../common/Text/Text";
import {StatusNameDescriptionCell, TableLabelCell} from "../../common/CustomTable/CustomTable";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import TextArea from "../../common/TextArea/TextArea";
import SelectableTable from "../../common/Tables/SelectableTable";
import {purviewUseCaseParameterNames} from "./PurviewUseCasePane";
import WorkflowBuilderOperation from "../../../models/workflowbuilder/WorkflowBuilderOperation";
import PurviewOperationPane from "./PurviewOperationPane";
import {purviewExportReviewSetParameterNames} from "./PurviewExportReviewSetPane";
import {purviewJobParameterNames} from "../PurviewGuidedJobForm";
import {buildOperationFieldParameterNames} from "../../guidedJob/operationFieldParameterNames";
import RadioButton from "../../common/RadioButton/RadioButton";

const fieldNames = ['disabled', 'reviewSetIdentifierType', 'reviewSetIdentifier', 'createReviewSetIfNotExists',
    'reviewSetDescription', 'allVersions', 'linkedFiles', 'waitForCompletion'];
export const purviewAddToReviewSetParameterNames = buildOperationFieldParameterNames(WorkflowBuilderOperation.Alias.PURVIEW_ADD_TO_REVIEW_SET, fieldNames);

function getNextEnabled(getParameter) {
    const reviewSetIdentifierType = getParameter(purviewAddToReviewSetParameterNames.reviewSetIdentifierType);
    const reviewSetIdentifier = getParameter(purviewAddToReviewSetParameterNames.reviewSetIdentifier);
    return {
        addToReviewSet: objectIdentifierType[reviewSetIdentifierType.value] != null && reviewSetIdentifier.value.length > 0,
    };
}

export function getPurviewAddToReviewSetGuide(operation, props) {
    const {
        workflowActions,
        getParameter,
        updateParameter
    } = props;

    const reviewSetIdentifierType = getParameter(purviewAddToReviewSetParameterNames.reviewSetIdentifierType);
    const reviewSetIdentifier = getParameter(purviewAddToReviewSetParameterNames.reviewSetIdentifier);

    const createNewReviewSet = reviewSetIdentifierType !== objectIdentifierType.ID;
    if (createNewReviewSet) {
        updateParameter(purviewAddToReviewSetParameterNames.reviewSetIdentifierType, objectIdentifierType.NAME);
        if (!reviewSetIdentifier.value) {
            updateParameter(purviewAddToReviewSetParameterNames.reviewSetIdentifier, `ReviewSet-{date_time}`);
        }
    }

    updateParameter(purviewAddToReviewSetParameterNames.waitForCompletion, JSON.stringify(true));
    updateParameter(purviewExportReviewSetParameterNames.reviewSetIdentifierType, objectIdentifierType.ID);
    updateParameter(purviewExportReviewSetParameterNames.reviewSetIdentifier, purviewJobParameterNames.reviewSetId);

    return {
        getPanes: function(t, props) {
            const addToReviewSetTitle = getNonEmptyValueOrDefault(operation.notes, t('guidedJob:panes.addToPurviewReviewSet'));
            const nextEnabled = getNextEnabled(props.getParameter);

            return [{
                title: addToReviewSetTitle,
                isNextEnabled: nextEnabled.addToReviewSet,
                component: <PurviewOperationPane {...props}
                    workflowActions={workflowActions} OperationPane={PurviewAddToReviewSetPane}/>
            }];
        }
    }
}

function PurviewAddToReviewSetPane(props) {
    const {t} = useTranslation(['guidedJob', 'common']);
    const {
        proxyPurviewRequest,
        getParameter,
        updateParameter,
        isDisabled
    } = props;

    const caseIdentifierType = getParameter(purviewUseCaseParameterNames.caseIdentifierType);
    const caseIdentifier = getParameter(purviewUseCaseParameterNames.caseIdentifier);

    const reviewSetIdentifierType = getParameter(purviewAddToReviewSetParameterNames.reviewSetIdentifierType);
    const reviewSetIdentifier = getParameter(purviewAddToReviewSetParameterNames.reviewSetIdentifier);
    const description = getParameter(purviewAddToReviewSetParameterNames.reviewSetDescription);
    const allVersions = getParameter(purviewAddToReviewSetParameterNames.allVersions);
    const linkedFiles = getParameter(purviewAddToReviewSetParameterNames.linkedFiles);

    const useExistingCase = caseIdentifierType.value === objectIdentifierType.ID;
    const useExistingReviewSet = reviewSetIdentifierType.value === objectIdentifierType.ID;
    const createNewReviewSet = !useExistingReviewSet;
    const isUseExistingReviewSetDisabled = isDisabled || !useExistingCase;

    const reviewSetsKey = PurviewCache.getResourceKey(PurviewCache.Resource.REVIEW_SETS, {caseId: caseIdentifier.value});
    const reviewSets = useSelector(state => state.purviewCache[reviewSetsKey]) || {};
    const isLoadingReviewSets = reviewSets.cache == null;

    const headers = useRef([
        {label: t('common:label.name')},
        {label: t('common:label.createdDate'), noStretch: true}
    ]);
    
    useEffect(() => {
        if (useExistingReviewSet && isUseExistingReviewSetDisabled) {
            updateParameter(purviewAddToReviewSetParameterNames.reviewSetIdentifierType, objectIdentifierType.NAME);
            updateParameter(purviewAddToReviewSetParameterNames.reviewSetIdentifier, '');
        }
    }, [useExistingReviewSet, isUseExistingReviewSetDisabled]);

    useEffect(() => {
        if (useExistingReviewSet && useExistingCase) {
            proxyPurviewRequest(PurviewCache.Resource.REVIEW_SETS, {caseId: caseIdentifier.value});
        }
        updateParameter(purviewAddToReviewSetParameterNames.createReviewSetIfNotExists, JSON.stringify(!useExistingReviewSet));
    }, [useExistingReviewSet]);

    function inputHandler(event) {
        const {name, value} = event.target;
        updateParameter(name, value);
        if (name === purviewAddToReviewSetParameterNames.reviewSetIdentifierType && reviewSetIdentifierType.value !== value) {
            updateParameter(purviewAddToReviewSetParameterNames.reviewSetIdentifier, '');
        }
    }

    function toggleHandler(event) {
        const {name, checked} = event.target;
        updateParameter(name, JSON.stringify(checked));
    }

    function valueHandler(event) {
        const {name, value} = event.currentTarget.dataset;
        updateParameter(name, value);
    }


    return (
        <>
            <div className="flex-column" style={{marginTop: '0.75rem'}}>
                <Text value={t('guidedJob:label.collectAdditionalItems')}/>
                <Checkbox label={t('guidedJob:label.allVersions')} name={purviewAddToReviewSetParameterNames.allVersions}
                    containerStyle={{marginBottom: '0.5rem'}} value={allVersions.value} onClick={toggleHandler} isDisabled={isDisabled}/>

                <Checkbox label={t('guidedJob:label.linkedFiles')} name={purviewAddToReviewSetParameterNames.linkedFiles}
                    containerStyle={{marginBottom: '0.5rem'}} value={linkedFiles.value} onClick={toggleHandler} isDisabled={isDisabled}/>
            </div>

            <div className="display-input flex-column">
                <RadioButton name={purviewAddToReviewSetParameterNames.reviewSetIdentifierType} label={t(`guidedJob:option.createNewPurviewReviewSet`)}
                    style={{marginTop: '0.5rem', marginBottom: '1rem'}} selected={reviewSetIdentifierType.value} value={objectIdentifierType.NAME}
                    onClick={inputHandler} isDisabled={isDisabled}/>

                <RadioButton name={purviewAddToReviewSetParameterNames.reviewSetIdentifierType} label={t(`guidedJob:option.useExistingPurviewReviewSet`)}
                    selected={reviewSetIdentifierType.value} value={objectIdentifierType.ID} onClick={inputHandler} isDisabled={isUseExistingReviewSetDisabled}/>
            </div>

            {createNewReviewSet &&
                <div>
                    <div className="display-input">
                        <HTMLTextInput label={t('guidedJob:label.reviewSetName')} name={purviewAddToReviewSetParameterNames.reviewSetIdentifier}
                            value={reviewSetIdentifier.value} onChange={inputHandler} isRequired isDisabled={isDisabled}/>
                    </div>

                    <div className="display-input">
                        <TextArea label={t('guidedJob:label.description')} name={purviewAddToReviewSetParameterNames.reviewSetDescription}
                            value={description.value} onChange={inputHandler} isDisabled={isDisabled}/>
                    </div>
                </div>
            }

            {useExistingReviewSet &&
                <div className="display-input">
                    <div style={{display: 'flex', marginBottom: '0.25rem'}}>
                        <Text value={`${t('guidedJob:label.reviewSet')}:`} style={{marginRight: '0.5rem'}} isDisabled={isUseExistingReviewSetDisabled}/>
                        <Text value={reviewSetIdentifier.value} isDisabled={isUseExistingReviewSetDisabled}/>
                    </div>

                    <SelectableTable name={purviewAddToReviewSetParameterNames.reviewSetIdentifier} items={reviewSets.cache}
                        headers={headers.current} emptyMessage={t('guidedJob:message.purviewCaseHasNoReviewSets')}
                        selectedItemId={reviewSetIdentifier.value} onItemSelect={valueHandler} isLoading={isLoadingReviewSets}
                        isStale={reviewSets.stale} isRequired isDisabled={isUseExistingReviewSetDisabled}
                        RowComponent={props => (
                            <>
                                <StatusNameDescriptionCell name={props.displayName} description={props.description}/>
                                <TableLabelCell label={getLocaleDateTimeFromUTC(props.createdDate)}/>
                            </>
                        )}
                    />
                </div>
            }
        </>
    )
}
