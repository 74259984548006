import {detectIncognito} from "detectincognitojs";


export default function() {
    const colorSchemeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    colorSchemeMediaQuery.addEventListener('change', setThemedFavicon);
    setThemedFavicon(colorSchemeMediaQuery);
}

async function setThemedFavicon(event) {
    let theme;
    if (event?.matches) {
        theme = 'dark';
    } else {
        const result = await detectIncognito();
        if (result.isPrivate) {
            theme = 'dark';
        } else {
            theme = 'light';
        }
    }

    const linkElements = document.querySelectorAll('link[class="favicon"]');
    for (const link of linkElements) {
        link.href = link.href.replace(/(light|dark)/, theme);
    }
}
