import React from "react";
import {useTranslation} from "react-i18next";
import {getEntries, includesSome, isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import {applicationFeatures} from "../../../utilities/constants";
import {useDispatch, useSelector} from "react-redux";
import {AddButtonHeader} from "../../common/Button/Button";
import UserServiceModel from "../../../models/user/UserServiceModel";
import {
    StatusNameDescriptionCell,
    TableEnableToggleCell,
    TableLabelCell,
    TableLabelHeader,
    TableRow
} from "../../common/CustomTable/CustomTable";
import {permissionKeys} from "../../../i18next/keys";

function MicrosoftUserServiceTable() {
    const {t} = useTranslation(['aria', 'userService', 'common']);
    const dispatch = useDispatch();

    const activeUserServiceId = useSelector(state => state.componentStates.userServiceDisplay.userServiceId);
    const canAddUserService = useSelector(state => includesSome(state.currentUser.features, [applicationFeatures.ADD_RESOURCE, applicationFeatures.MODIFY_USER_SERVICES]));

    const microsoftUserServiceIds = getEntries(useSelector(state => state.userServiceDetailsMap))
        .filter(([,userService]) => userService.type === UserServiceModel.types.MICROSOFT)
        .map(([id]) => id);

    function toggleEnable(event) {
        const {name} = event.currentTarget.dataset;
        dispatch(UserServiceModel.actionCreators.toggleEnabled(name));
    }

    function showMicrosoftUserServiceForm() {
        dispatch(UserServiceModel.actionCreators.showForm({type: UserServiceModel.types.MICROSOFT}));
    }

    function showMicrosoftUserServiceTablet(event) {
        const {value} = event.currentTarget.dataset;
        dispatch(UserServiceModel.actionCreators.showTablet(value));
    }

    const isNotEmpty = isNotEmptyNorFalsy(microsoftUserServiceIds);
    if (!canAddUserService && !isNotEmpty) {
        return null;
    }

    return (
        <div className="display-item">
            <AddButtonHeader aria-label={t('aria:hiddenAssistText.addMicrosoftUserService')}
                text={t('userService:label.microsoftUserServiceTitle')} canModify={canAddUserService} onClick={showMicrosoftUserServiceForm}
            />

            {isNotEmpty &&
                <div className="display-table">
                    <div className="table-header-group">

                        <TableLabelHeader/>
                        <TableLabelHeader label={t('common:label.name')} style={{width: '20rem'}}/>
                        <TableLabelHeader label={t('userService:label.synchronizeUsers')}/>
                        <TableLabelHeader label={t('userService:label.searchDelay_detailed')}/>
                        <TableLabelHeader label={t('userService:label.enableSso')}/>
                    </div>

                    <div className="table-row-group">
                        {microsoftUserServiceIds.map(id =>
                            <MicrosoftUserServiceRow key={id} userServiceId={id} showMicrosoftUserServiceTablet={showMicrosoftUserServiceTablet}
                                toggleEnable={toggleEnable} isActive={activeUserServiceId === id}/>
                        )}
                    </div>
                </div>
            }
        </div>
    )
}

function MicrosoftUserServiceRow(props) {
    const {t} = useTranslation(['userService', 'common']);
    const {userServiceId, isActive, toggleEnable, showMicrosoftUserServiceTablet} = props;

    const userService = useSelector(state => state.userServiceDetailsMap.get(userServiceId));
    const {
        name,
        description,
        enabled,

        synchronizeUsers,
        searchDelay,
        enableLoginLinks,

        status,
        userPermissions
    } = userService;

    const canModify = userPermissions && userPermissions.includes(permissionKeys.MODIFY);
    const isViewLimited = userPermissions && userPermissions.includes(permissionKeys.VIEW_LIMITED);

    if (isViewLimited && !canModify) {
        return (
            <TableRow value={userServiceId} isActive={isActive} onClick={showMicrosoftUserServiceTablet}>
                <TableEnableToggleCell ariaLabelKey={userService.type} displayName={name} name={userServiceId} checked={enabled}
                                 onClick={toggleEnable} isReadOnly={!canModify}/>
                <StatusNameDescriptionCell status={status} name={name} description={description}/>

                <div className={"table-cell"}/>
                <div className={"table-cell"}/>
                <div className={"table-cell"}/>
            </TableRow>
        );
    }

    return (
        <TableRow value={userServiceId} isActive={isActive} onClick={showMicrosoftUserServiceTablet}>
            <TableEnableToggleCell ariaLabelKey={userService.type} displayName={name} name={userServiceId} checked={enabled}
                onClick={toggleEnable} isReadOnly={!canModify}/>
            <StatusNameDescriptionCell status={status} name={name} description={description}/>

            <TableLabelCell label={synchronizeUsers ? t('common:option.yes') : t('common:option.no')}/>
            <TableLabelCell label={synchronizeUsers ? searchDelay : ''}/>
            <TableLabelCell label={enableLoginLinks ? t('common:option.yes') : t('common:option.no')}/>
        </TableRow>
    )
}

export default MicrosoftUserServiceTable;