import ReduxModel from "../generics/ReduxModel";
import {getObjectText} from "../../utilities/helperFunctions";

class WorkflowBuilderOperation extends ReduxModel {

    constructor(model={}) {
        super();
        this.notes = model.notes || '';
        this.disabled = model.disabled;
        this.skippable = model.skippable;
        this.softFail = model.softFail;
        this.suppressWarnings = model.suppressWarnings;
        this.enableFieldOverwrite = model.enableFieldOverwrite;
        this.forceUpdate(model);
    }

    static ExecutionMode = {
        NATIVE: 'NATIVE'
    };

    static Category = {
        RELATIVITY: 'RELATIVITY'
    };

    static Action = {
        ENABLE: 'ENABLE',
        DISABLE: 'DISABLE',
        MAKE_SKIPPABLE: 'MAKE_SKIPPABLE',
        REMOVE_SKIPPABLE: 'REMOVE_SKIPPABLE',
        ENABLE_SOFT_FAIL: 'ENABLE_SOFT_FAIL',
        DISABLE_SOFT_FAIL: 'DISABLE_SOFT_FAIL',
        ENABLE_SUPPRESS_WARNINGS: 'ENABLE_SUPPRESS_WARNINGS',
        DISABLE_SUPPRESS_WARNINGS: 'DISABLE_SUPPRESS_WARNINGS',
        ENABLE_FIELD_OVERWRITE: 'ENABLE_FIELD_OVERWRITE',
        DISABLE_FIELD_OVERWRITE: 'DISABLE_FIELD_OVERWRITE',
        DELETE: 'DELETE',
        APPEND_WORKFLOW: 'APPEND_WORKFLOW',
        CUT: 'CUT',
        COPY: 'COPY',
        PASTE: 'PASTE'
    };

    static getActionProperty(action) {
        switch (action) {
            case WorkflowBuilderOperation.Action.ENABLE:
            case WorkflowBuilderOperation.Action.DISABLE:
                return 'disabled';
            case WorkflowBuilderOperation.Action.MAKE_SKIPPABLE:
            case WorkflowBuilderOperation.Action.REMOVE_SKIPPABLE:
                return 'skippable';
            case WorkflowBuilderOperation.Action.ENABLE_SOFT_FAIL:
            case WorkflowBuilderOperation.Action.DISABLE_SOFT_FAIL:
                return 'softFail';
            case WorkflowBuilderOperation.Action.ENABLE_SUPPRESS_WARNINGS:
            case WorkflowBuilderOperation.Action.DISABLE_SUPPRESS_WARNINGS:
                return 'suppressWarnings';
            case WorkflowBuilderOperation.Action.ENABLE_FIELD_OVERWRITE:
            case WorkflowBuilderOperation.Action.DISABLE_FIELD_OVERWRITE:
                return 'enableFieldOverwrite';
        }
    }

    static Alias = {
        DUMMY_OPERATION: 'DUMMY_OPERATION',
        CONFIGURE_PARAMETERS: 'CONFIGURE_PARAMETERS',

        AZURE_STORAGE_ACCOUNT_CONFIGURE_CONNECTION: 'AZURE_STORAGE_ACCOUNT_CONFIGURE_CONNECTION',
        AZURE_STORAGE_ACCOUNT_CREATE_CONTAINER: 'AZURE_STORAGE_ACCOUNT_CREATE_CONTAINER',
        AZURE_STORAGE_ACCOUNT_GENERATE_SAS_TOKEN: 'AZURE_STORAGE_ACCOUNT_GENERATE_SAS_TOKEN',

        SET_PURVIEW_CASE: 'SET_PURVIEW_CASE',
        PURVIEW_UPDATE_CASE_SETTINGS: 'PURVIEW_UPDATE_CASE_SETTINGS',
        PURVIEW_ADD_CUSTODIAL_DATA_SOURCES: 'PURVIEW_ADD_CUSTODIAL_DATA_SOURCES',
        PURVIEW_ADD_NON_CUSTODIAL_DATA_SOURCES: 'PURVIEW_ADD_NON_CUSTODIAL_DATA_SOURCES',
        PURVIEW_ADD_TO_SEARCH: 'PURVIEW_ADD_TO_SEARCH',
        PURVIEW_ESTIMATE_SEARCH_STATISTICS: 'PURVIEW_ESTIMATE_SEARCH_STATISTICS',
        PURVIEW_ADD_TO_REVIEW_SET: 'PURVIEW_ADD_TO_REVIEW_SET',
        PURVIEW_SET_EXPORT: 'PURVIEW_SET_EXPORT',
        PURVIEW_EXPORT_REVIEW_SET: 'PURVIEW_EXPORT_REVIEW_SET',
        PURVIEW_DOWNLOAD_EXPORT: 'PURVIEW_DOWNLOAD_EXPORT',

        PURVIEW_APPLY_HOLD_TO_CUSTODIANS: 'PURVIEW_APPLY_HOLD_TO_CUSTODIANS',
        PURVIEW_APPLY_HOLD_TO_NON_CUSTODIAL_DATA_SOURCES: 'PURVIEW_APPLY_HOLD_TO_NON_CUSTODIAL_DATA_SOURCES',
        PURVIEW_REMOVE_HOLD_FROM_CUSTODIANS: 'PURVIEW_REMOVE_HOLD_FROM_CUSTODIANS',
        PURVIEW_REMOVE_HOLD_FROM_NON_CUSTODIAL_DATA_SOURCES: 'PURVIEW_REMOVE_HOLD_FROM_NON_CUSTODIAL_DATA_SOURCES',
        PURVIEW_RELEASE_CUSTODIANS: 'PURVIEW_RELEASE_CUSTODIANS',
        PURVIEW_RELEASE_NON_CUSTODIAL_DATA_SOURCES: 'PURVIEW_RELEASE_NON_CUSTODIAL_DATA_SOURCES',

        VAULT_CONFIGURE_CONNECTION: 'VAULT_CONFIGURE_CONNECTION',
        VAULT_SET_MATTER: 'VAULT_SET_MATTER',
        VAULT_CREATE_SAVED_QUERIES: 'VAULT_CREATE_SAVED_QUERIES',
        VAULT_EXPORT_SAVED_QUERIES: 'VAULT_EXPORT_SAVED_QUERIES',
        VAULT_SET_EXPORTS: 'VAULT_SET_EXPORTS',
        VAULT_DOWNLOAD_EXPORTS: 'VAULT_DOWNLOAD_EXPORTS',

        VAULT_ADD_HOLDS: 'VAULT_ADD_HOLDS',
        VAULT_REMOVE_LOCATIONS_FROM_HOLDS: 'VAULT_REMOVE_LOCATIONS_FROM_HOLDS'
    };

    getText(t) {
        return getObjectText(this) + t(`workflowBuilder:operationAlias.${this.operationAlias}`);
    }
}

export default WorkflowBuilderOperation;
