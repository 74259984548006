import React from "react";
import {useTranslation} from "react-i18next";
import {buildClassName} from "../../utilities/helperFunctions";

export function getInvalidMessageSpan(props) {
    const {t} = useTranslation(['common']);
    const {
        id,
        isInvalid,
        isRequired,
        invalidMessage,
        requiredMessage,
        ariaLabelledBy,
        ariaLabel,
        minimalSpacing = true
    } = props;

    let _invalidMessage;
    if (isRequired) {
        _invalidMessage = requiredMessage || t('common:label.required');
    } else if (isInvalid) {
        _invalidMessage = invalidMessage || t('common:label.invalid');
    }


    const className = buildClassName(
        'label invalid-message',
        minimalSpacing && 'spaced-minimal'
    );

    const invalidMessageId = `${id}:invalidMessage`;
    const invalidMessageSpan = _invalidMessage && (
        <span id={invalidMessageId} className={className}>
            {_invalidMessage}
        </span>
    );

    let ariaLabelledByIds = [];
    if (ariaLabelledBy) {
        if (Array.isArray(ariaLabelledBy)) {
            ariaLabelledByIds.push(...ariaLabelledBy);
        } else {
            ariaLabelledByIds.push(ariaLabelledBy);
        }
    }

    ariaLabelledByIds = ariaLabelledByIds.filter(e => e);
    if (ariaLabelledByIds.length === 0 && ariaLabel) {
        return {
            combinedAriaLabel: ariaLabel,
            // combinedAriaLabel: `${ariaLabel}${_invalidMessage ? (' ' + _invalidMessage) : ''}`,
            invalidMessageSpan
        }
    }

    if (_invalidMessage) {
        ariaLabelledByIds.push(invalidMessageId);
    }

    const combinedAriaLabelledBy = [...new Set(ariaLabelledByIds.filter(e => e))].join(' ');
    return {
        combinedAriaLabelledBy: combinedAriaLabelledBy.length > 0 ? combinedAriaLabelledBy : null,
        invalidMessageSpan
    }
}
