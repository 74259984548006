import React from 'react';
import {connect} from 'react-redux';
import {boolToString} from "../../../utilities/helperFunctions";
import {permissionKeys} from "../../../i18next/keys";
import ResourcePoolModel from "../../../models/settings/ResourcePoolModel";
import {useTranslation} from "react-i18next";
import {StatusNameDescriptionCell, TableEnableToggleCell, TableRow} from "../../common/CustomTable/CustomTable";
import LimitedTableRow from "../../limited/LimitedTableRow";

const AwsResourcePoolRow = props => {
    const {t} = useTranslation(['resourcePool', 'common']);
    const {
        name,
        active,
        description,
        accessKey,
        region,
        instanceIds,
        tagName,
        tagValue,
        launchTemplateId,
        maxConcurrentInstances,
        nuixLicenceSourceName,
        targetNuixWorkers,
        minNuixWorkers,
        instanceIdleAction,
        forceIdleActionBetweenJobs,
        resourcePoolId,
        showResourcePoolTablet,
        toggleActive,
        userPermissions,
        isActive,
        status,
        useRemoteEngines,
    } = props;

    const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);
    const canModify = userPermissions.includes(permissionKeys.MODIFY);
    if (isViewLimited && !canModify) {
        return (
            <LimitedTableRow id={resourcePoolId} isActive={isActive} onClick={showResourcePoolTablet} cellCount={14}
                             nameComponent={<StatusNameDescriptionCell status={status} name={name} description={description}/>}
                             toggleComponent={<TableEnableToggleCell ariaLabelKey={'AwsResourcePool'} displayName={name} name={resourcePoolId}
                                                               checked={active} onClick={toggleActive} isReadOnly={!canModify}/>}
            />
        )
    }

    return (
        <TableRow value={resourcePoolId} isActive={isActive} onClick={showResourcePoolTablet}>

            <TableEnableToggleCell ariaLabelKey={'AwsResourcePool'} displayName={name} name={resourcePoolId}
                checked={active} onClick={toggleActive} isReadOnly={!canModify}/>

            <StatusNameDescriptionCell style={{maxWidth: '10rem'}}
                status={status}
                name={name}
                description={description}
            />

            <div className="table-cell max-width-10">
                <label className="label is-ellipsis">{accessKey}</label>
            </div>
            <div className="table-cell">
                <label className="label is-ellipsis">{region}</label>
            </div>
            <div className="table-cell max-width-10">
                {instanceIds.map((instanceId, index) => <label className="label is-ellipsis"
                    key={index}>{instanceId}</label>)}
            </div>
            <div className="table-cell max-width-10">
                <label className="label is-ellipsis">{launchTemplateId}</label>
            </div>
            <div className="table-cell max-width-10">
                <label className="label is-ellipsis">{tagName}</label>
                <label className="label is-ellipsis">{tagValue}</label>
            </div>
            <div className="table-cell">
                <label className="label is-ellipsis">{maxConcurrentInstances}</label>
            </div>
            <div className="table-cell max-width-10">
                <label className="label is-ellipsis">{nuixLicenceSourceName}</label>
            </div>
            <div className="table-cell">
                <label className="label is-ellipsis">{targetNuixWorkers}</label>
            </div>
            <div className="table-cell">
                <label className="label is-ellipsis">{minNuixWorkers}</label>
            </div>
            <div className="table-cell">
                <label
                    className="label is-ellipsis">{t(`resourcePool:instanceIdleAction.${instanceIdleAction}`)}</label>
            </div>
            <div className="table-cell">
                <label className="label is-ellipsis">{boolToString(forceIdleActionBetweenJobs)}</label>
            </div>
            <div className="table-cell">
                <label className="label is-ellipsis">{boolToString(useRemoteEngines)}</label>
            </div>
        </TableRow>
    );
}

//assumption that awsSettings & cloudSettings not null
const mapStateToProps = (state, ownProps) => {
  const {resourcePoolDetailsMap, nuixLicenceSourceDetailsMap, componentStates: {resourcePoolDisplay}} = state;

  const {name, description, active, cloudSettings, userPermissions, status} = resourcePoolDetailsMap.get(ownProps.resourcePoolId);
  const {nuixLicenceSourceId, targetNuixWorkers, minNuixWorkers, instanceIdleAction, forceIdleActionBetweenJobs, awsSettings, useRemoteEngines} = cloudSettings;
  const {accessKey, region, instanceIds, tagName, tagValue, launchTemplateId, maxConcurrentInstances} = awsSettings;

  const nuixLicenceSourceDetails = nuixLicenceSourceDetailsMap.get(nuixLicenceSourceId);
  const nuixLicenceSourceName = nuixLicenceSourceDetails ? nuixLicenceSourceDetails.name : nuixLicenceSourceId;
  const isActive = resourcePoolDisplay.resourcePoolId === ownProps.resourcePoolId;

  return {
    name,
    description,
    active,
    accessKey,
    region,
    instanceIds,
    tagName,
    tagValue,
    launchTemplateId,
    maxConcurrentInstances,
    nuixLicenceSourceName,
    targetNuixWorkers,
    minNuixWorkers,
    instanceIdleAction,
    forceIdleActionBetweenJobs,
    useRemoteEngines,
    userPermissions,
    status,
    isActive
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showResourcePoolTablet: event => dispatch(ResourcePoolModel.actionCreators.showTablet(event.currentTarget.dataset.value)),
    toggleActive: event => dispatch(ResourcePoolModel.actionCreators.toggleEnabled(event.currentTarget.dataset.name))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AwsResourcePoolRow);