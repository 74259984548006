import ThirdPartyServiceModel from "../../../models/thirdparty/ThirdPartyServiceModel";
import ThirdPartyServiceForm, {ThirdPartyServiceFormBody} from "../ThirdPartyServiceForm";
import {useTranslation} from "react-i18next";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import React from "react";
import ExpandableContent from "../../common/ExpandableContent/ExpandableContent";

const initialState = {
    type: ThirdPartyServiceModel.Type.DISCOVER,
    serviceName: '',
    enabled: true,
    description: '',
    whitelistedCertFingerprints: [],

    hostname: '',

    authenticationScope: ThirdPartyServiceModel.AuthenticationScope.SERVICE,
    authenticationMethod: ThirdPartyServiceModel.AuthenticationMethod.API_KEY,
    authenticationServiceId: null
};
const allowedAuthenticationMethods = [
    ThirdPartyServiceModel.AuthenticationMethod.API_KEY
];

function DiscoverServiceForm() {
    return (
        <ThirdPartyServiceForm initialState={initialState} FormBody={DiscoverServiceFormBody}/>
    )
}

export function DiscoverServiceFormBody(props) {
    return (
        <ThirdPartyServiceFormBody {...props} FormBody={FormBody}
            allowedAuthenticationMethods={allowedAuthenticationMethods}/>
    )
}

function FormBody(props) {
    const {t} = useTranslation(['thirdPartyService']);
    const {
        thirdPartyService,
        inputHandler,
        isDisabled
    } = props;

    return (
        <ExpandableContent label={t('thirdPartyService:label.discoverSettings')} isDisabled={isDisabled}>
            <div className="display-input">
                <HTMLTextInput label={t('thirdPartyService:label.hostname')} name={'hostname'}
                    value={thirdPartyService.hostname} onChange={inputHandler} isDisabled={isDisabled} isRequired/>
            </div>
        </ExpandableContent>
    )
}

export default DiscoverServiceForm;
