import ReduxModel from "../generics/ReduxModel";
import SagaModel from "../generics/SagaModel";
import {
    actionCreator,
    getEntries,
    getValues,
    isAllTruthy,
    isNotEmptyNorFalsy,
    objEqualsNotOrdered
} from "../../utilities/helperFunctions";
import {all, call, put, race, select, take, takeLeading} from "redux-saga/effects";
import WorkflowBuilderOperation from "./WorkflowBuilderOperation";
import {contextCall, contextSaga, isDisabledWrapper, spawnSaga} from "../../saga/sagaFunctions";
import EditModel from "../scheduler/EditModel";
import {genericErrorHandler, retryCall, tryCatchWrapper} from "../../saga/tryCatchWrapper";
import WorkflowTemplateModel, {WorkflowTemplateApi} from "../library/WorkflowTemplateModel";
import PopupModel from "../scheduler/PopupModel";
import {permissionKeys, popupInfoKeys} from "../../i18next/keys";
import {axiosInstance} from "../api/AxiosProxy";
import WorkflowBuilderOperationFilter from "./WorkflowBuilderOperationFilter";
import FormBuilderConfiguration from "./FormBuilderConfiguration";
import i18n from "i18next";
import {DataRepositorySaga} from "../data/DataRepositoryModel";
import {UserDataDirSaga} from "../system/UserDataDirModel";

class WorkflowBuilderModel extends ReduxModel {

    static nom = 'WorkflowBuilderModel';
    static actions = WorkflowBuilderModel.buildActions();
    static actionCreators = WorkflowBuilderModel.buildActionCreators(WorkflowBuilderModel.actions);
    static reducer = WorkflowBuilderModel.buildReducer(WorkflowBuilderModel.actions);

    constructor(model = {}) {
        super();

        this.libraryId = model.libraryId;
        this.workflowId = model.workflowId;
        this.workflowName = model.workflowName || '';
        this.explicitExecutionMode = model.explicitExecutionMode || WorkflowTemplateModel.ExecutionMode.AUTODETECT;
        this.enabled = model.enabled || true;
        this.description = model.description || '';
        this.usage = model.usage || '';
        this.prerequisites = model.prerequisites || [[{value: ''}]];
        this.operations = model.operations || [];
        this.userPermissions = model.userPermissions;

        this.operationFilter = model.operationFilter || new WorkflowBuilderOperationFilter();
        this.operationFormConfigurations = model.operationFormConfigurations;
        this.selectedOperationIndex = model.selectedOperationIndex;

        this.isAddOperationFormActive = model.isAddOperationFormActive;
        this.isLoading = model.isLoading;
        this.isActive = model.isActive;
        this.isDisabled = model.isDisabled;
    }

    static getOperationFormText(t, operationAlias) {
        return t(`workflowBuilder:operationAlias.${operationAlias}`);
    }

    static validateFormData(formData) {
        if (formData.isLoading) {
            return {};
        }
        const panesValid = {
            submit: !!formData.workflowName,
        };

        if (Array.isArray(formData.operations)) {
            const formConfigurations = formData.operationFormConfigurations;
            panesValid.builderPane = formData.operations.length > 0 && true ||
                formData.operations.every(op => formConfigurations.get(op.operationAlias) == null || formConfigurations.get(op.operationAlias).isValid(op));
        }
        return panesValid;
    }

    static buildActions() {
        return {
            OPEN: 'OPEN_WORKFLOW_BUILDER',
            OPEN_WITH_TEMPLATE: 'OPEN_WORKFLOW_BUILDER_WITH_TEMPLATE',

            SAFE_CLOSE: 'SAFE_CLOSE_WORKFLOW_BUILDER',
            UPDATE: 'UPDATE_WORKFLOW_BUILDER',
            SUBMIT: 'SUBMIT_WORKFLOW_BUILDER',
            RESET: 'RESET_WORKFLOW_BUILDER',

            APPEND_WORKFLOW: 'APPEND_WORKFLOW_BUILDER',

            START_EDIT: 'START_EDIT_WORKFLOW_BUILDER',
            SAVE_EDIT: 'SAVE_WORKFLOW_BUILDER_EDIT',
            CANCEL_EDIT: 'CANCEL_WORKFLOW_BUILDER_EDIT'
        }
    }

    static buildActionCreators(actions) {
        return {
            open: actionCreator(actions.OPEN, 'initialState'),
            openWithTemplate: actionCreator(actions.OPEN_WITH_TEMPLATE, 'libraryId', 'templateId'),

            safeClose: actionCreator(actions.SAFE_CLOSE),
            update: actionCreator(actions.UPDATE, 'updates'),
            submit: actionCreator(actions.SUBMIT, 'libraryId', 'options'),
            reset: actionCreator(actions.RESET),

            appendWorkflow: actionCreator(actions.APPEND_WORKFLOW, 'workflowXml', 'setSelected'),

            startEdit: actionCreator(actions.START_EDIT, 'id'),
            saveEdit: actionCreator(actions.SAVE_EDIT),
            cancelEdit: actionCreator(actions.CANCEL_EDIT)
        }
    }

    static buildReducer(actions) {
        return function (state = new WorkflowBuilderModel(), action) {
            switch (action.type) {
                case actions.UPDATE: {
                    let updates = action.payload.updates;
                    if (typeof updates === 'function') {
                        updates = updates(state);
                    }
                    return state.shallowDuplicate(updates);
                }
                case actions.RESET: {
                    return new WorkflowBuilderModel();
                }
                default:
                    return state;
            }
        }
    }
}

export class WorkflowBuilderApi {

    static getOperationFormConfigurations() {
        return axiosInstance.get('/scheduler/library/workflowBuilder/operationFormConfigurations');
    }

    static postWorkflow(workflow, libraryId, {copy, update}={}) {
        return axiosInstance.post(`/scheduler/library/${libraryId}/workflowBuilder?createCopy=${!!copy}&updateExisting=${!!update}`, workflow);
    }

    static updateWorkflow(id, workflow) {
        return axiosInstance.put(`/scheduler/library/workflowBuilder/${id}`, workflow);
    }
}

export class WorkflowBuilderSaga extends SagaModel {

    static ModelType = WorkflowBuilderModel;
    static ModelApi = WorkflowBuilderApi;

    static activationComponent = 'LIBRARY_PAGE';

    static buildActivationEffects() {
        return [
            takeLeading(this.ModelType.actions.OPEN, contextSaga(this, 'open')),
            takeLeading(this.ModelType.actions.OPEN_WITH_TEMPLATE, tryCatchWrapper, contextSaga(this, 'openWithTemplate')),

            takeLeading(this.ModelType.actions.SAFE_CLOSE, contextSaga(this, 'close')),
            takeLeading(this.ModelType.actions.SUBMIT, tryCatchWrapper, isDisabledWrapper, this.ModelType.actionCreators.update, contextSaga(this, 'submit')),

            takeLeading(this.ModelType.actions.APPEND_WORKFLOW, tryCatchWrapper, isDisabledWrapper, this.ModelType.actionCreators.update, contextSaga(this, 'appendWorkflow')),

            takeLeading(this.ModelType.actions.START_EDIT, spawnSaga, tryCatchWrapper, contextSaga(this, 'startEdit')),
            takeLeading(this.ModelType.actions.SAVE_EDIT, isDisabledWrapper, this.ModelType.actionCreators.update, contextSaga(this, 'editSave')),
            takeLeading(this.ModelType.actions.CANCEL_EDIT, contextSaga(this, 'editCancel'))
        ]
    }

    static buildDeactivationEffects() {
        return [];
    }

    static* openWithTemplate(action) {
        const {libraryId, templateId} = action.payload;
        const editValues = yield call(isDisabledWrapper, WorkflowTemplateModel.componentActionCreators.updateForm, function* () {
            return yield contextCall(this, 'getEditValues', templateId);
        }.bind(this));

        const workflowTemplates = yield select(state => getValues(state.workflowTemplateDetailsMap).filter(workflow => workflow.libraryId === libraryId));
        const workflowName = yield contextCall(this, 'getUniqueName', editValues.workflowName, workflowTemplates);
        const initialValues = {
            libraryId,
            workflowName,
            enabled: editValues.enabled,
            description: editValues.description,
            usage: editValues.usage,
            prerequisites: editValues.prerequisites,
            operations: editValues.operations || []
        };

        yield put(WorkflowTemplateModel.actionCreators.hideForm());
        yield put(WorkflowBuilderModel.actionCreators.open(initialValues));
    }

    static* open(action) {
        const {initialState} = action.payload;
        yield put(WorkflowBuilderModel.actionCreators.reset())

        const openState = {...initialState, isActive: true, isLoading: true};
        // Open addOperationForm if no operations OR select first operation
        if (isNotEmptyNorFalsy(openState.operations)) {
            openState.selectedOperationIndex = 0;
        } else {
            openState.isAddOperationFormActive = true;
        }
        yield put(WorkflowBuilderModel.actionCreators.update(openState));

        // Query objects
        yield* this.queryObjects();

        // Query for all operations and set isLoading
        const {data} = yield contextCall(WorkflowBuilderApi, 'getOperationFormConfigurations');
        const operationFormConfigurations = new Map();
        const operationFilter = {};

        const translationCompare = (namespace, a, b) => {
            const aTranslated = i18n.t(`workflowBuilder:${namespace}.${a}`);
            const bTranslated = i18n.t(`workflowBuilder:${namespace}.${b}`);
            return aTranslated.localeCompare(bTranslated);
        }

        const dataEntries = getEntries(data);
        dataEntries.sort(([, a], [, b]) => translationCompare('operationAlias', a.template.operationAlias, b.template.operationAlias));

        // Store as Map and build operationTags for filtering
        for (const [operationAlias, formConfig] of dataEntries) {
            const formConfiguration = FormBuilderConfiguration.buildFormConfiguration(formConfig);
            operationFormConfigurations.set(operationAlias, formConfiguration);

            for (const [name, values] of getEntries(formConfiguration.tags)) {
                if (operationFilter[name] == null) {
                    operationFilter[name] = {};
                }
                values.sort((a, b) => translationCompare(name, a, b));
                values.forEach(value => operationFilter[name][value] = false);
            }
        }

        yield put(WorkflowBuilderModel.actionCreators.update({
            operationFormConfigurations,
            operationFilter: new WorkflowBuilderOperationFilter(operationFilter),
            isLoading: false
        }));
    }

    static* queryObjects() {
        yield all([
            call(DataRepositorySaga.queryDetails),
            call(UserDataDirSaga.querySettings)
        ]);
    }

    static* close() {
        const {activeModel} = yield select(state => state.editDetails);
        if (activeModel !== this.ModelType.nom) {
            yield put(WorkflowBuilderModel.actionCreators.reset());
            return;
        }

        // Check if edit has changed, if changed prompt save popup
        const workflowBuilder = yield select(state => state.workflowBuilder);
        const editValues = yield contextCall(this, 'getEditValues', workflowBuilder.workflowId);
        const values = {
            libraryId: workflowBuilder.libraryId,
            workflowId: workflowBuilder.workflowId,
            workflowName: workflowBuilder.workflowName,
            enabled: workflowBuilder.enabled,
            description: workflowBuilder.description,
            usage: workflowBuilder.usage,
            prerequisites: workflowBuilder.prerequisites,
            operations: workflowBuilder.operations,
            userPermissions: workflowBuilder.userPermissions
        };

        const isChanged = !objEqualsNotOrdered(values, editValues);
        if (isChanged) {
            const isSaveEnabled = isAllTruthy(this.ModelType.validateFormData(workflowBuilder));
            yield put(PopupModel.actionCreators.show({
                info: {
                    key: popupInfoKeys.SAFE_CLOSE
                },
                buttons: [{
                    titleKey: 'common:option.dontSave',
                    onClick: function (dispatch) {
                        dispatch(EditModel.actionCreators.cancel());
                    }
                }, {
                    titleKey: 'common:option.save',
                    onClick: function (dispatch) {
                        dispatch(EditModel.actionCreators.save());
                    },
                    isDisabled: !isSaveEnabled
                }]
            }));
        } else {
            yield put(EditModel.actionCreators.cancel());
        }
    }

    static* submit(action) {
        const {libraryId, options} = action.payload;
        const workflowBuilder = yield select(state => state.workflowBuilder);

        const formData = {...workflowBuilder, libraryId};
        const saveValues = yield contextCall(this, 'getSaveValues', formData);

        try {
            const {data} = yield contextCall(WorkflowBuilderApi, 'postWorkflow', saveValues, libraryId, options);
            yield put(WorkflowTemplateModel.actionCreators.addDetails(data));

            yield all([
                put(WorkflowBuilderModel.actionCreators.reset()),
                put(WorkflowTemplateModel.actionCreators.showTablet(data.id))
            ]);
        } catch (error) {
            const handleResponseError = function* (error) {
                switch (error.response.data.key) {
                    case 'workflowXmlExistsOfferOverwriteOrCopy':
                    case 'workflowExistsOfferOverwriteOrCopy':
                        yield put(PopupModel.actionCreators.showWarning({
                            info: error.response.data,
                            buttons: [{
                                titleKey: 'common:option.update',
                                onClick: dispatch => dispatch(WorkflowBuilderModel.actionCreators.submit(libraryId, {update: true}))
                            }, {
                                titleKey: 'common:option.copy',
                                onClick: dispatch => dispatch(WorkflowBuilderModel.actionCreators.submit(libraryId, {copy: true}))
                            }]
                        }));
                        break;
                    case 'workflowExistsInJobOfferCopy':
                    case 'workflowExistsInScheduleOfferCopy':
                    case 'workflowExistsInPurviewOfferCopy':
                        yield put(PopupModel.actionCreators.showWarning({
                            info: error.response.data,
                            buttons: [{
                                titleKey: 'common:option.copy',
                                onClick: dispatch => dispatch(WorkflowBuilderModel.actionCreators.submit(libraryId, {copy: true}))
                            }]
                        }));
                        break;
                    default:
                        yield call(genericErrorHandler, error);
                }
            }.bind(this);
            yield call(genericErrorHandler, error, {responseErrorCb: handleResponseError});
        }
    }

    static* appendWorkflow(action) {
        const {workflowXml, setSelected} = action.payload;
        const workflowBuilder = yield select(state => state.workflowBuilder);

        const {data} = yield contextCall(WorkflowTemplateApi, 'getDetailedOperationsFromWorkflowXml', workflowXml);
        if (Array.isArray(data) && data.length > 0) {
            const updatedOperations = [...workflowBuilder.operations];
            let startIndex;
            if (workflowBuilder.selectedOperationIndex == null) {
                startIndex = 0;
            } else {
                startIndex = workflowBuilder.selectedOperationIndex + 1;
            }

            for (let i = 0; i < data.length; i++) {
                updatedOperations.splice(startIndex + i, 0, new WorkflowBuilderOperation(data[i]));
            }

            setSelected(prevState => {
                const values = {};
                for (let i = 0; i < data.length; i++) {
                    values[startIndex + i] = true;
                }
                return {
                    ...prevState,
                    values,
                    lastSelectedValue: startIndex
                }
            });
            yield put(WorkflowBuilderModel.actionCreators.update({
                operations: updatedOperations
            }));
        }
    }

    static* startEdit(action) {
        const {id} = action.payload;

        // Check if workflow is used elsewhere, if true prompt a copy
        const {data} = yield contextCall(WorkflowTemplateApi, 'getObjectsUsingWorkflow', id, true);
        if (isNotEmptyNorFalsy(data)) {
            const {libraryId} = yield select(state => state.workflowTemplateDetailsMap.get(id));
            let popupKey = '';
            if (data.backlogJobs.length > 0) {
                popupKey = 'BacklogJob';
            } else if (data.schedules.length > 0) {
                popupKey = 'Schedule';
            }
            return yield put(PopupModel.actionCreators.showWarning({
                info: {key: `workflowUsedIn${popupKey}OfferCopy`},
                buttons: [{
                    titleKey: 'common:option.copy',
                    onClick: dispatch => {
                        dispatch(WorkflowTemplateModel.actionCreators.hideTablet());
                        dispatch(WorkflowBuilderModel.actionCreators.openWithTemplate(libraryId, id));
                    }
                }]
            }));
        }

        const editValues = yield call(isDisabledWrapper, WorkflowTemplateModel.componentActionCreators.updateTablet, function* () {
            return yield contextCall(this, 'getEditValues', id);
        }.bind(this));

        yield put(WorkflowTemplateModel.actionCreators.hideTablet());
        yield all([
            put(EditModel.actionCreators.start(this.ModelType.nom, editValues)),
            put(WorkflowBuilderModel.actionCreators.open(editValues)),
            retryCall(contextSaga(this, 'waitForEditAction'), id, editValues)
        ]);

        yield all([
            put(EditModel.actionCreators.reset()),
            put(WorkflowBuilderModel.actionCreators.reset())
        ]);
    }

    static* checkElsewhereTake() {
        yield take(WorkflowTemplateModel.actions.SET_DETAILS_MAP);
    }

    static* saveEdit(id) {
        const workflowBuilder = yield select(state => state.workflowBuilder);
        try {
            const saveValues = yield contextCall(this, 'getSaveValues', workflowBuilder);
            const {data} = yield contextCall(this.ModelApi, 'updateWorkflow', id, saveValues);

            yield all([
                put(WorkflowTemplateModel.actionCreators.updateDetails({[id]: new WorkflowTemplateModel(data)})),
                put(WorkflowTemplateModel.actionCreators.querySettings(id)),
                put(EditModel.actionCreators.saveSuccess())
            ]);
        } catch (error) {
            yield put(EditModel.actionCreators.saveFail());
            if (error.response) {
                switch (error.response.data.key) {
                    case 'workflowUsedInBacklogJobOfferCopy':
                    case 'workflowUsedInScheduleOfferCopy':
                    case 'workflowUsedInPurviewOfferCopy':
                        yield put(PopupModel.actionCreators.showWarning({
                            info: error.response.data,
                            buttons: [{
                                titleKey: 'common:option.copy',
                                onClick: dispatch => {
                                    dispatch(WorkflowBuilderModel.actionCreators.submit(workflowBuilder.libraryId, {copy: true}));
                                    dispatch(EditModel.actionCreators.cancel());
                                }
                            }]
                        }));
                        throw 'Workflow used by object, prompting offer to create a copy instead';
                }
            }
            throw error;
        }
    }

    static* editSave() {
        const {workflowId} = yield select(state => state.workflowBuilder);
        yield put(EditModel.actionCreators.save());

        const [success] = yield race([
            take(EditModel.actions.SAVE_SUCCESS),
            take(EditModel.actions.SAVE_FAIL)
        ]);

        if (success) {
            yield take(EditModel.actions.RESET);
            yield put(WorkflowTemplateModel.actionCreators.showTablet(workflowId));
        }
    }

    static* editCancel() {
        const {workflowId} = yield select(state => state.workflowBuilder);
        yield put(EditModel.actionCreators.cancel());

        yield put(WorkflowTemplateModel.actionCreators.showTablet(workflowId));
    }

    static* getEditValues(id, queryOperations) {
        const editDetails = yield select(state => state.editDetails);
        if (editDetails.activeModel === this.ModelType.nom) {
            return editDetails.values;
        }

        const workflowTemplate = yield select(state => state.workflowTemplateDetailsMap.get(id));
        let operations;
        if (workflowTemplate.userPermissions.includes(permissionKeys.VIEW_SENSITIVE)) {
            const {data} = yield contextCall(WorkflowTemplateApi, 'getDetailedOperations', id);
            if (Array.isArray(data)) {
                operations = data.map(op => new WorkflowBuilderOperation(op));
            }
        }

        return {
            libraryId: workflowTemplate.libraryId,
            workflowId: id,
            workflowName: workflowTemplate.name,
            enabled: workflowTemplate.enabled,
            explicitExecutionMode: workflowTemplate.explicitExecutionMode || workflowTemplate.executionMode,
            description: workflowTemplate.description || '',
            usage: workflowTemplate.usage || '',
            prerequisites: (workflowTemplate.prerequisites || []).map(prereq => ([{value: prereq}])),
            operations,
            userPermissions: workflowTemplate.userPermissions
        };
    }

    static getSaveValues(values) {
        let operations;
        if (Array.isArray(values.operations)) {
            operations = values.operations;
        }

        return {
            libraryId: values.libraryId,
            workflowId: values.workflowId,
            workflowName: values.workflowName,
            enabled: values.enabled,
            explicitExecutionMode: values.explicitExecutionMode,
            description: values.description,
            usage: values.usage,
            prerequisites: values.prerequisites.map(prereqRow => prereqRow[0].value),
            operations
        };
    }
}

export default WorkflowBuilderModel;
