import DetailsModel, {DetailsApi, DetailsSaga} from "../generics/DetailsModel";
import {getLocaleDateTimeFromUTC} from "../../utilities/helperFunctions";
import ComponentStateModel from "../generics/ComponentStateModel";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import {all, put, select} from "redux-saga/effects";
import {contextCall} from "../../saga/sagaFunctions";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import {details} from "../../utilities/constants";
import LibraryFileModel from "./LibraryFileModel";

class FileLibraryModel extends DetailsModel {

    static nom = 'FileLibraryModel';
    static actions = FileLibraryModel.buildActions('FILE_LIBRARIES');
    static actionCreators = FileLibraryModel.buildActionCreators(FileLibraryModel.actions);
    static reducer = FileLibraryModel.buildReducer(FileLibraryModel.actions);

    static componentActionCreators = {
        ...FileLibraryModel.buildComponentUpdateActionCreators(),
        ...FileLibraryModel.buildComponentSetActiveActionCreators()
    };

    constructor(model = {}) {
        super(model);
        const {id, name, description, createdBy, createdDate, lastStateChangedDate} = model;

        this.id = id;
        this.name = name;
        this.description = description;
        this.createdBy = createdBy;
        this.createdDate = getLocaleDateTimeFromUTC(createdDate);
        this.lastStateChangedDate = getLocaleDateTimeFromUTC(lastStateChangedDate)
    }

    static buildActions(type) {
        return {
            ...super.buildActions(type),
        }
    }

    static buildActionCreators(actions) {
        return {
            ...super.buildActionCreators(actions)
        }
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'fileLibraryDisplay',
                type: 'Display',
                state: {
                    fileLibraryId: null,
                    isFileLibraryFormActive: false,
                    isLibraryFileFormActive: false
                }
            },
            {
                key: 'fileLibraryTablet',
                type: 'Tablet',
                state: {
                    libraryFileId: null,
                    isDisabled: false
                }
            },
            {
                key: 'fileLibraryForm',
                type: 'Form',
                state: {
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'FILE_LIBRARY_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }
}

export class FileLibraryApi extends DetailsApi {

    static location = '/resources';
    static type = '/fileLibraries';

    static getLibraryFiles (libraryId) {
        return axiosInstance.get(`/scheduler/resources/fileLibraries/${libraryId}/files`);
    }
}

export class FileLibrarySaga extends DetailsSaga {

    static ModelType = FileLibraryModel;
    static ModelApi = FileLibraryApi;

    static activationComponent = 'FILE_LIBRARY_DISPLAY';
    static variableNames = {
        detailsMap: 'fileLibraryDetailsMap',
        instanceId: 'fileLibraryId',
        isFormActive: 'isFileLibraryFormActive',
        updateDisplay: 'updateDisplay',
        updatePane: 'updateTablet'
    };

    static translations = {
        itemTitle: '$t(fileLibrary:label.name_simple)',
        itemLower: '$t(fileLibrary:label.name_simple_lower)'
    };

    static buildActivationEffects(dispatch) {
        return [
            ...super.buildActivationEffects(dispatch),

            // ACTIVATION EFFECTS
            put(FileLibraryModel.actionCreators.startPollingDetails())
        ]
    }

    static buildDeactivationEffects() {
        return [
            ...super.buildDeactivationEffects(),
            // DEACTIVATION EFFECTS
            put(FileLibraryModel.actionCreators.stopPollingDetails())
        ]
    }

    static* submitForm(action) {
        const {formData} = action.payload;

        const saveValues = this.getSaveValues(formData);
        const {data} = yield contextCall(FileLibraryApi, 'post', saveValues);

        const isFormActive = `isFileLibraryFormActive`;

        yield all([
            put(FileLibraryModel.actionCreators.addDetails(data)),
            put(FileLibraryModel.componentActionCreators.updateDisplay({
                fileLibraryId: data.id,
                [isFormActive]: false
            }))
        ]);
    }

    static* getEditValues(id) {
        const details = yield select(state => state.fileLibraryDetailsMap.get(id));
        const {name, description} = details;

        return {
            id,
            fileLibraryName: name,
            description
        }
    }

    static getSaveValues(values) {
        const {fileLibraryName: name, description, id} = values;

        return {
            id,
            name,
            description
        }
    }

    static* querySettings(action) {
        const {id} = action.payload;
        if (id == null)
            return;

        const response = yield contextCall(FileLibraryApi, 'getLibraryFiles', id);
        if (AxiosProxy.shouldUpdate(id, response)) {
            yield all([
                put(LibraryFileModel.actionCreators.setSubsetDetailsMap(id, response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(id))
            ]);
        }
    }

    static* queryDetails() {
        const response = yield contextCall(FileLibraryApi, 'getDetails');
        const key = details.FILE_LIBRARIES;

        if (AxiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(FileLibraryModel.actionCreators.setDetailsMap(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }
}

export default FileLibraryModel;