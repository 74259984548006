import React, {Component} from 'react';
import {connect, useSelector} from 'react-redux';
import {useTranslation, withTranslation} from "react-i18next";
import {icon, statusIcon} from '../../../utilities/iconResolver';
import PolicyDisplay from '../../securityPolicy/PolicyDisplay';
import SelectPanel from '../../panels/SelectPanel';
import {settingsDisplayKeys, statusKeys} from "../../../i18next/keys";
import {applicationFeatures, routes, settingsKeyToDetailsKey} from '../../../utilities/constants';
import ServerDisplay from '../../engineServer/ServerDisplay';
import NuixLicenceSourceDisplay from '../../nuixLicenceSource/NuixLicenceSourceDisplay';
import EngineDisplay from '../../engine/EngineDisplay';
import ResourcePoolDisplay from '../../resourcePool/ResourcePoolDisplay';
import UserSettingsDisplay from "../../userSettings/UserSettingsDisplay";
import ResourcesDisplay from "../../resources/ResourcesDisplay";
import ClientPoolDisplay from "../../clientPool/ClientPoolDisplay";
import ExecutionProfileDisplay from "../../executionProfile/ExecutionProfileDisplay";
import SchedulerModel, {SchedulerSaga} from "../../../models/scheduler/SchedulerModel";
import NotificationRuleDisplay from "../../notificationRule/NotificationRuleDisplay";
import {getKeys, getValues, includesSome, textContainsEverySearchWord} from "../../../utilities/helperFunctions";
import AutomateLicenceDisplay from "../../automateLicence/AutomateLicenceDisplay";
import DataRepositoryDisplay from "../../dataRepository/DataRepositoryDisplay";
import SystemResourcesDisplay from "../../systemResources/SystemResourcesDisplay";
import CurrentUserModel from "../../../models/user/CurrentUserModel";
import UserServiceDisplay from "../../userservice/UserServiceDisplay";
import NoticeTemplateDisplay from "../../noticeTemplate/NoticeTemplateDisplay";
import ApiKeyDisplay from "../../apiKey/ApiKeyDisplay";
import WebhookDisplay from "../../webhook/WebhookDisplay";
import FileLibraryDisplay from "../../fileLibrary/FileLibraryDisplay";
import {selectStatusIcon, updateStatusToIcon} from "../navbar/NavBar";
import AutomateLicenceModel from "../../../models/scheduler/AutomateLicenceModel";
import UserServiceModel from "../../../models/user/UserServiceModel";
import LicenceSourceModel from "../../../models/settings/LicenceSourceModel";
import ServerModel from "../../../models/settings/ServerModel";
import EngineModel from "../../../models/settings/EngineModel";
import ResourcePoolModel from "../../../models/settings/ResourcePoolModel";
import ExecutionProfileModel from "../../../models/settings/ExecutionProfileModel";
import NotificationRuleModel from "../../../models/settings/NotificationRuleModel";
import DataRepositoryModel from "../../../models/data/DataRepositoryModel";
import ApiKeyModel from "../../../models/settings/ApiKeyModel";
import WebhookModel from "../../../models/settings/WebhookModel";
import SearchBar from "../../common/SearchBar/SearchBar";
import i18next from "i18next";
import UploadMonitorDisplay from "../../uploadMonitor/UploadMonitorDisplay";
import PolicyModel from "../../../models/settings/PolicyModel";
import RelativityProxyDisplay from "../../relativityProxy/RelativityProxyDisplay";
import RelativityProxyModel from "../../../models/relativity/RelativityProxyModel";
import ThirdPartyServiceModel from "../../../models/thirdparty/ThirdPartyServiceModel";
import ThirdPartyServiceDisplay from "../../thirdPartyService/ThirdPartyServiceDisplay";
import ClientPoolModel from "../../../models/settings/ClientPoolModel";
import {MainPageAsideHeader} from "./MainPage";


class SettingsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            searchText: ''
        }

        this.onPanelSelect = this.onPanelSelect.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        const {userFeatures, isRelativityApplication, startPollingActivity} = this.props;
        startPollingActivity();

        // Only set default activeDisplay if none selected
        if (this.props.activeDisplay != null) return;

        let activeDisplay;
        // noinspection IfStatementWithTooManyBranchesJS
        if (isRelativityApplication) {
            activeDisplay = settingsDisplayKeys.RELATIVITY_PROXY;
        } else if (userFeatures.includes(applicationFeatures.VIEW_AUTOMATE_LICENCE)) {
            activeDisplay = settingsDisplayKeys.AUTOMATE_LICENCE;
        } else if (userFeatures.includes(applicationFeatures.VIEW_RESOURCE_POOLS)) {
            activeDisplay = settingsDisplayKeys.RESOURCE_POOLS;
        } else if (userFeatures.includes(applicationFeatures.VIEW_NOTIFICATION_RULES)) {
            activeDisplay = settingsDisplayKeys.NOTIFICATION_RULES;
        } else if (userFeatures.includes(applicationFeatures.VIEW_EXECUTION_PROFILES)) {
            activeDisplay = settingsDisplayKeys.EXECUTION_PROFILES;
        } else if (userFeatures.includes(applicationFeatures.VIEW_FILE_LIBRARIES)) {
            activeDisplay = settingsDisplayKeys.FILE_LIBRARIES;
        } else if (userFeatures.includes(applicationFeatures.VIEW_CLIENT_POOLS)) {
            activeDisplay = settingsDisplayKeys.CLIENT_POOLS;
        } else if (userFeatures.includes(applicationFeatures.VIEW_DATA_REPOSITORIES)) {
            activeDisplay = settingsDisplayKeys.DATA_REPOSITORIES;
        } else if (userFeatures.includes(applicationFeatures.VIEW_USER_SERVICES)) {
            activeDisplay = settingsDisplayKeys.USER_SERVICES;
        } else if (userFeatures.includes(applicationFeatures.VIEW_NOTICE_TEMPLATES)) {
            activeDisplay = settingsDisplayKeys.NOTICE_TEMPLATES;
        } else if (userFeatures.includes(applicationFeatures.VIEW_SECURITY)) {
            activeDisplay = settingsDisplayKeys.POLICIES;
        } else if (userFeatures.includes(applicationFeatures.VIEW_API_KEYS)) {
            activeDisplay = settingsDisplayKeys.API_KEYS;
        } else if (userFeatures.includes(applicationFeatures.VIEW_THIRD_PARTY_SERVICES)
            || userFeatures.includes(applicationFeatures.VIEW_DERBY_CONTROL_SERVICE)
            || userFeatures.includes(applicationFeatures.VIEW_INVESTIGATE_SERVICE)
            || userFeatures.includes(applicationFeatures.VIEW_DISCOVER_SERVICE)) {
            activeDisplay = settingsDisplayKeys.THIRD_PARTY_SERVICES;
        } else if (includesSome(userFeatures, [applicationFeatures.DOWNLOAD_SYSTEM_LOGS, applicationFeatures.DOWNLOAD_UTILIZATION_ANONYMOUS,
            applicationFeatures.DOWNLOAD_UTILIZATION_FULL, applicationFeatures.UPLOAD_UTILIZATION])) {
            activeDisplay = settingsDisplayKeys.SYSTEM_RESOURCES;
        } else {
            activeDisplay = settingsDisplayKeys.USER_SETTINGS;
        }

        this.props.setDisplay(activeDisplay);
    }

    onPanelSelect(event) {
        const {value} = event.currentTarget.dataset;
        if (value !== this.props.activeDisplay) {
            this.props.setDisplay(value);
        }
    }

    onInputChange(event) {
        const {value} = event.target;
        this.setState({searchText: value});
    }

    render() {
        const {
            t,
            isRelativityApplication,
            activeDisplay,
            resourceSearchableText,
            isDisabled
        } = this.props;

        const {
            searchText
        } = this.state;

        const userFeatures = this.props.userFeatures || [];
        const canViewResources = userFeatures.includes(applicationFeatures.VIEW_RESOURCES);
        const displays = {
            [settingsDisplayKeys.RELATIVITY_PROXY]: {
                canView: isRelativityApplication && (canViewResources || this.props.userFeatures == null),
                component: RelativityProxyDisplay
            },
            [settingsDisplayKeys.AUTOMATE_LICENCE]: {
                canView: userFeatures.includes(applicationFeatures.VIEW_AUTOMATE_LICENCE),
                component: AutomateLicenceDisplay
            },
            [settingsDisplayKeys.USER_SERVICES]: {
                canView: userFeatures.includes(applicationFeatures.VIEW_USER_SERVICES),
                component: UserServiceDisplay
            },
            [settingsDisplayKeys.NUIX_LICENCE_SOURCES]: {
                canView: userFeatures.includes(applicationFeatures.VIEW_NUIX_LICENCE_SOURCES),
                component: NuixLicenceSourceDisplay
            },
            [settingsDisplayKeys.ENGINES]: {
                canView: userFeatures.includes(applicationFeatures.VIEW_RESOURCE_POOLS),
                component: EngineDisplay
            },
            [settingsDisplayKeys.SERVERS]: {
                canView: canViewResources,
                component: ServerDisplay
            },
            [settingsDisplayKeys.RESOURCE_POOLS]: {
                canView: userFeatures.includes(applicationFeatures.VIEW_RESOURCE_POOLS),
                component: ResourcePoolDisplay
            },
            [settingsDisplayKeys.NOTIFICATION_RULES]: {
                canView: userFeatures.includes(applicationFeatures.VIEW_NOTIFICATION_RULES),
                component: NotificationRuleDisplay
            },
            [settingsDisplayKeys.EXECUTION_PROFILES]: {
                canView: userFeatures.includes(applicationFeatures.VIEW_EXECUTION_PROFILES),
                component: ExecutionProfileDisplay
            },
            [settingsDisplayKeys.FILE_LIBRARIES]: {
                canView: userFeatures.includes(applicationFeatures.VIEW_FILE_LIBRARIES),
                component: FileLibraryDisplay
            },
            [settingsDisplayKeys.CLIENT_POOLS]: {
                canView: userFeatures.includes(applicationFeatures.VIEW_CLIENT_POOLS),
                component: ClientPoolDisplay
            },
            [settingsDisplayKeys.DATA_REPOSITORIES]: {
                canView: userFeatures.includes(applicationFeatures.VIEW_DATA_REPOSITORIES) && userFeatures.includes(applicationFeatures.SCHEDULER_UPLOAD),
                component: DataRepositoryDisplay
            },
            [settingsDisplayKeys.UPLOAD_MONITOR]: {
                canView: userFeatures.includes(applicationFeatures.VIEW_DATA_REPOSITORIES),
                component: UploadMonitorDisplay
            },
            [settingsDisplayKeys.NOTICE_TEMPLATES]: {
                canView: userFeatures.includes(applicationFeatures.VIEW_NOTICE_TEMPLATES),
                component: NoticeTemplateDisplay
            },
            [settingsDisplayKeys.THIRD_PARTY_SERVICES]: {
                canView: userFeatures.includes(applicationFeatures.VIEW_THIRD_PARTY_SERVICES)
                    || userFeatures.includes(applicationFeatures.VIEW_DERBY_CONTROL_SERVICE)
                    || userFeatures.includes(applicationFeatures.VIEW_INVESTIGATE_SERVICE)
                    || userFeatures.includes(applicationFeatures.VIEW_DISCOVER_SERVICE),
                component: ThirdPartyServiceDisplay
            },
            [settingsDisplayKeys.POLICIES]: {
                canView: userFeatures.includes(applicationFeatures.VIEW_SECURITY),
                component: PolicyDisplay
            },
            [settingsDisplayKeys.API_KEYS]: {
                canView: userFeatures.includes(applicationFeatures.VIEW_API_KEYS),
                component: ApiKeyDisplay
            },
            [settingsDisplayKeys.WEBHOOKS]: {
                canView: userFeatures.includes(applicationFeatures.VIEW_WEBHOOKS),
                component: WebhookDisplay
            },
            [settingsDisplayKeys.USER_SETTINGS]: {
                canView: userFeatures.includes(applicationFeatures.VIEW_USER_SETTINGS),
                component: UserSettingsDisplay
            },
            [settingsDisplayKeys.RESOURCES]: {
                canView: true,
                component: ResourcesDisplay
            },
            [settingsDisplayKeys.SYSTEM_RESOURCES]: {
                canView: includesSome(userFeatures, [applicationFeatures.DOWNLOAD_SYSTEM_LOGS, applicationFeatures.DOWNLOAD_UTILIZATION_ANONYMOUS,
                    applicationFeatures.DOWNLOAD_UTILIZATION_FULL, applicationFeatures.UPLOAD_UTILIZATION]),
                component: SystemResourcesDisplay
            }
        };


        return (
            <main className="mainPage">
                <aside className="selectPage-aside">
                    <section className="panel-header">
                        <MainPageAsideHeader text={t('settings:page.title')}
                            icon={icon('settings')} ariaKey={'settings'}
                        />
                    </section>

                    <section className="panel-action-bar">
                        <SearchBar id={'settingsSearchBar'} value={searchText} onChange={this.onInputChange}/>
                    </section>

                    <section className="panel-list" role="tablist">
                        {getValues(settingsDisplayKeys)
                            .filter(display => {
                                return displays[display].canView && textContainsEverySearchWord(searchText, resourceSearchableText[display]);
                            })
                            .map(display =>
                                <StatusSelectPanel key={display} isActive={activeDisplay === display}
                                    value={display} onPanelSelect={this.onPanelSelect}
                                />
                            )}
                    </section>
                </aside>

                {getValues(settingsDisplayKeys)
                    .filter(display => display === activeDisplay && displays[display].canView)
                    .map(display => {
                        const Component = displays[display].component;

                        return <Component key={display} id={`${display}_TABPANEL`}
                            aria-labelledby={`${display}_TAB`} isDisabled={isDisabled}/>
                    })}
            </main>
        )
    }
}


const settingsKeyToDetailsMap = (state, settingsKey) => {
    switch (settingsKey) {
        case settingsDisplayKeys.RELATIVITY_PROXY: return [RelativityProxyModel, [state.relativityProxy]];
        case settingsDisplayKeys.AUTOMATE_LICENCE: return [AutomateLicenceModel, [state.automateLicence]];
        case settingsDisplayKeys.USER_SERVICES: return [UserServiceModel, state.userServiceDetailsMap];
        case settingsDisplayKeys.NUIX_LICENCE_SOURCES: return [LicenceSourceModel, state.nuixLicenceSourceDetailsMap];
        case settingsDisplayKeys.SERVERS: return [ServerModel, state.serverDetailsMap];
        case settingsDisplayKeys.ENGINES: return [EngineModel, state.engineDetailsMap];
        case settingsDisplayKeys.RESOURCE_POOLS: return [ResourcePoolModel, state.resourcePoolDetailsMap];
        case settingsDisplayKeys.EXECUTION_PROFILES: return [ExecutionProfileModel, state.executionProfileDetailsMap];
        case settingsDisplayKeys.NOTIFICATION_RULES: return [NotificationRuleModel, state.notificationRuleDetailsMap];
        case settingsDisplayKeys.DATA_REPOSITORIES: return [DataRepositoryModel, state.dataRepositoryDetailsMap];
        case settingsDisplayKeys.THIRD_PARTY_SERVICES: return [ThirdPartyServiceModel, state.thirdPartyServiceDetailsMap];
        case settingsDisplayKeys.API_KEYS: return [ApiKeyModel, state.apiKeyDetailsMap];
        case settingsDisplayKeys.WEBHOOKS: return [WebhookModel, state.webhookDetailsMap];
        case settingsDisplayKeys.POLICIES: return [PolicyModel, state.policyDetailsMap];
        case settingsDisplayKeys.CLIENT_POOLS: return [ClientPoolModel, state.clientPoolDetailsMap];
    }
}

export function selectResourceStatuses(state, settingsKey) {
    const {activeDisplay} = state.componentStates.settingsPage;
    const detailsKey = settingsKeyToDetailsKey[settingsKey];

    // If on settingsPage, use detailsMap for activeDisplay OR if detailsMap was updated more recently than resourceStatuses
    if (detailsKey !== 'systemResources' && detailsKey !== 'userSettings' && state.hasLoaded[detailsKey]) {
        const [modelType, detailsMap] = settingsKeyToDetailsMap(state, settingsKey);

        const useDetailsMap = modelType.lastUpdated > SchedulerSaga.lastResourceStatusUpdate
            || (settingsKey === activeDisplay && window.location.hash.includes(routes.SETTINGS));

        if (useDetailsMap) {
            return getValues(detailsMap).flatMap(detail => {
                switch (settingsKey) {
                    case settingsDisplayKeys.SERVERS:
                    case settingsDisplayKeys.ENGINES:
                        return {
                            code: detail.status,
                            maintenance: detail.maintenanceStatus,
                            error: detail.error
                        }
                    case settingsDisplayKeys.USER_SERVICES:
                        return [detail.status, detail.warningStatus];
                    case settingsDisplayKeys.POLICIES:
                        return {code: detail.enabled && detail.hasWarnings ? statusKeys.WARNING : statusKeys.UNKNOWN}
                    case settingsDisplayKeys.NOTIFICATION_RULES:
                        return [detail.status, detail.emailCounter?.status];
                    default:
                        return detail.status;
                }
            });
        }
    }
    // Else return endpoint result
    return state.schedulerDetails.resourcesStatus[detailsKey];
}

function selectPanelStatusIcons(state, settingsKey) {
    const statusToIcon = {}
    const statuses = selectResourceStatuses(state, settingsKey)

    updateStatusToIcon(statuses, statusToIcon);
    return selectStatusIcon(statusToIcon);
}

function StatusSelectPanel(props) {
    const {t} = useTranslation(['settings']);
    const {value, ...rest} = props;

    const statuses = useSelector(state => selectPanelStatusIcons(state, value));
    return (
        <SelectPanel label={t(`settings:displays.${value}`)} value={value} labelStyle={{marginTop: '-1px'}}
            isFlex {...rest} leftChildren={Array.isArray(statuses) && statuses.map(status =>
                <span key={status} className="icon is-small">
                    <img src={statusIcon(status)} alt={t(`aria:hiddenAssistText.${status}Icon`)}/>
                </span>
            )}
        />
    )
}

const settingsKeyToNamespace = {
    [settingsDisplayKeys.RELATIVITY_PROXY]: 'relativityProxy',
    [settingsDisplayKeys.AUTOMATE_LICENCE]: 'automateLicence',
    [settingsDisplayKeys.USER_SERVICES]: 'userService',
    [settingsDisplayKeys.NUIX_LICENCE_SOURCES]: 'nuixLicenceSource',
    [settingsDisplayKeys.SERVERS]: 'server',
    [settingsDisplayKeys.ENGINES]: 'engine',
    [settingsDisplayKeys.RESOURCE_POOLS]: 'resourcePool',
    [settingsDisplayKeys.FILE_LIBRARIES]: 'fileLibrary',
    [settingsDisplayKeys.EXECUTION_PROFILES]: 'executionProfile',
    [settingsDisplayKeys.CLIENT_POOLS]: 'clientPool',
    [settingsDisplayKeys.NOTIFICATION_RULES]: 'notificationRule',
    [settingsDisplayKeys.NOTICE_TEMPLATES]: 'noticeTemplate',
    [settingsDisplayKeys.DATA_REPOSITORIES]: 'dataRepository',
    [settingsDisplayKeys.UPLOAD_MONITOR]: 'uploadMonitor',
    [settingsDisplayKeys.THIRD_PARTY_SERVICES]:'thirdPartyService',
    [settingsDisplayKeys.POLICIES]: 'policy',
    [settingsDisplayKeys.API_KEYS]: 'apiKey',
    [settingsDisplayKeys.WEBHOOKS]: 'webhook',
    [settingsDisplayKeys.USER_SETTINGS]: 'userSettings',
    [settingsDisplayKeys.RESOURCES]: 'resources',
    [settingsDisplayKeys.SYSTEM_RESOURCES]: 'systemResources'
};


function selectResourcesSearchableText() {
    return getKeys(settingsDisplayKeys).reduce((acc, curr) => {
        const namespace = settingsKeyToNamespace[curr];
        let searchableText = i18next.getResource(i18next.language, namespace, 'searchable');
        if (!Array.isArray(searchableText)) {
            searchableText = i18next.getResource('en-US', namespace, 'searchable');
        }
        if (Array.isArray(searchableText)) {
            acc[curr] = searchableText.join('').toUpperCase();
        }
        return acc;
    }, {});
}

const mapStateToProps = state => {
    const {currentUser, schedulerDetails: {isRelativityApplication}} = state;
    return {
        isRelativityApplication,
        userFeatures: currentUser.features,
        resourceSearchableText: selectResourcesSearchableText(),
        ...state.componentStates.settingsPage
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setDisplay: activeDisplay => dispatch(SchedulerModel.actionCreators.setSettingsDisplay(activeDisplay)),
        startPollingActivity: () => dispatch(CurrentUserModel.actionCreators.pollActivity(CurrentUserModel.activities.ADMINISTRATION))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['settings'])(SettingsPage));
