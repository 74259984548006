import Tablet, {EditTabletHeader} from "../common/Tablet/Tablet";
import React, {useEffect, useState} from "react";
import ThirdPartyServiceModel from "../../models/thirdparty/ThirdPartyServiceModel";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import EditModel from "../../models/scheduler/EditModel";
import {createEditHandler, createInputHandler} from "../../utilities/componentFunctions";
import {createToggleHandler} from "../common/Checkbox/helpers";
import {createDropdownHandler} from "../common/Dropdown/helpers";
import {
    capitalize,
    getLocaleDateTimeFromUTC,
    includesSome,
    isNotEmptyNorFalsy,
    lowerCaseFirstLetter,
    objEquals
} from "../../utilities/helperFunctions";
import {permissionKeys, statusKeys} from "../../i18next/keys";
import {ExpandableStatusLog} from "../common/Common";
import ExpandableContent, {
    ExpandableEditTextArea,
    ExpandableTimeStampedLog
} from "../common/ExpandableContent/ExpandableContent";
import {DefaultEditPanel} from "../common/EditPanel/EditPanel";
import {SettingsRowSeparator, SettingsRowValue} from "../common/CustomTable/CustomTable";
import {selectThirdPartyServiceNames} from "./ThirdPartyServiceDisplay";
import ThirdPartyUserCredentialForm, {useThirdPartySignIn} from "./userCredential/ThirdPartyUserCredentialForm";
import RelativityServiceSettings from "./relativity/RelativityServiceSettings";
import Text from "../common/Text/Text";
import {PurviewServiceFormBody} from "./purview/PurviewServiceForm";
import {RelativityServiceFormBody} from "./relativity/RelativityServiceForm";
import {makeGetEditDetails} from "../../reselect/selectors";
import {DiscoverServiceFormBody} from "./discover/DiscoverServiceForm";
import DiscoverServiceSettings from "./discover/DiscoverServiceSettings";
import {NlpServiceFormBody} from "./nlp/NlpServiceForm";
import NlpServiceSettings from "./nlp/NlpServiceSettings";
import LimitedTablet from "../limited/LimitedTablet";
import PurviewServiceSettings from "./purview/PurviewServiceSettings";
import {EccServiceFormBody} from "./ecc/EccServiceForm";
import EccServiceSettings from "./ecc/EccServiceSettings";
import {VaultServiceFormBody} from "./vault/VaultServiceForm";
import {DerbyControlServiceFormBody} from "./derbyControl/DerbyControlServiceForm";
import DerbyControlServiceSettings from "./derbyControl/DerbyControlServiceSettings";
import {InvestigateServiceFormBody} from "./investigate/InvestigateServiceForm";
import InvestigateServiceSettings from "./investigate/InvestigateServiceSettings";
import {GraphServiceFormBody} from "./graph/GraphServiceForm";
import GraphServiceSettings from "./graph/GraphServiceSettings";
import {GenAiServiceFormBody} from "./genAi/GenAiServiceForm";
import GenAiServiceSettings from "./genAi/GenAiServiceSettings";
import {SmtpServiceFormBody} from "./smtp/SmtpServiceForm";
import SmtpServiceSettings from "./smtp/SmtpServiceSettings";
import SmtpServiceTestForm from "./smtp/SmtpServiceTestForm";
import SmtpServiceExportForm from "./smtp/SmtpServiceExportForm";
import {LinkServiceFormBody} from "./external/LinkServiceForm";
import LinkServiceSettings from "./external/LinkServiceSettings";
import {SemanticServiceFormBody} from "./semantic/SemanticServiceForm";
import SemanticServiceSettings from "./semantic/SemanticServiceSettings";
import {ElasticsearchServiceFormBody} from "./elasticsearch/ElasticsearchServiceForm";
import ElasticsearchServiceSettings from "./elasticsearch/ElasticsearchServiceSettings";

const selectEditDetails = makeGetEditDetails()
function selectReduxState(state, props) {
    const {activeModel, values, isSaveEnabled} = selectEditDetails(state, {model: ThirdPartyServiceModel.nom});
    const isEditActive = (activeModel === ThirdPartyServiceModel.nom);

    const thirdPartyService = props.thirdPartyService || {};
    return {
        serviceName: thirdPartyService.name,
        ...thirdPartyService,
        ...values,
        isEditActive,
        isSaveEnabled
    };
}

function ThirdPartyServiceTablet(props) {
    const {t} = useTranslation(['thirdPartyService', 'common'])
    const dispatch = useDispatch()

    const reduxState = useSelector(state => selectReduxState(state, props), objEquals);
    const {
        userPermissions,
        isEditActive,
        isSaveEnabled,

        ...thirdPartyService
    } = reduxState;

    const {
        triggerUserCredentialSignIn,
        isDisabled
    } = useSelector(state => state.componentStates.thirdPartyServiceTablet);

    const {
        saveEdit,
        cancelEdit,
        updateEdit,
        setEditSaveEnabled
    } = EditModel.buildDispatchers(dispatch);

    const editHandler = createEditHandler({
        updateEdit,
        setEditSaveEnabled,
        shouldEnable: ThirdPartyServiceModel.validateFormData
    });

    const inputHandler = createInputHandler({
        handler: editHandler
    });
    const toggleHandler = createToggleHandler({
        handler: editHandler
    });
    const dropdownHandler = createDropdownHandler({
        handler: editHandler
    });

    function setWhitelistedCertFingerprints(update) {
        editHandler(prev => {
            if (typeof update === 'function') {
                return {whitelistedCertFingerprints: update(prev.whitelistedCertFingerprints)}
            }
            return {whitelistedCertFingerprints: update}
        });
    }

    const serviceActionBodies = ThirdPartyServiceModel.getServiceActionBodies(thirdPartyService);
    const camelCaseType = lowerCaseFirstLetter(thirdPartyService.type);

    const [TestFormComponent, setTestFormComponent] = useState();
    const [ExportFormComponent, setExportFormComponent] = useState();

    const [isUserCredentialFormActive, setUserCredentialFormActive] = useState();
    const [onUserCredentialSignIn, onUserCredentialSignOut] = useThirdPartySignIn({
        thirdPartyService,
        setUserCredentialFormActive
    });


    useEffect(() => {
        if (triggerUserCredentialSignIn != null) {
            onUserCredentialSignIn();
        }
    }, [triggerUserCredentialSignIn]);

    function onClose() {
        dispatch(ThirdPartyServiceModel.actionCreators.hideTablet());
    }

    function onTest() {
        const testFormComponent = getTestFormComponent(thirdPartyService.type);
        if (testFormComponent != null) {
            setTestFormComponent(() => testFormComponent);
        } else {
            dispatch(ThirdPartyServiceModel.actionCreators.sendCommand(thirdPartyService.id, ThirdPartyServiceModel.UserCredentialAction.TEST));
        }
    }

    function onExport() {
        const exportFormComponent = getExportFormComponent(thirdPartyService.type);
        if (exportFormComponent != null) {
            setExportFormComponent(() => exportFormComponent);
        } else {
            dispatch(ThirdPartyServiceModel.actionCreators.exportCsv(thirdPartyService.id));
        }
    }

    function menuOptionHandler(event) {
        const {value} = event.currentTarget.dataset

        let unmatched;
        switch (value) {
            case 'startEdit':
            case 'promptDelete':
            case 'toggleEnabled':
                dispatch(ThirdPartyServiceModel.actionCreators[value](thirdPartyService.id));
                break;
            case 'exportCsv':
                onExport();
                break;
            case ThirdPartyServiceModel.UserCredentialAction.TEST:
                onTest();
                break;
            case 'saveUserCredential':
                onUserCredentialSignIn();
                break;
            case 'deleteUserCredential':
                onUserCredentialSignOut(thirdPartyService.authenticationScope);
                break;
            default:
                unmatched = true;
        }

        if (unmatched) {
            if (ThirdPartyServiceModel.UserCredentialAction[value] != null) {
                dispatch(ThirdPartyServiceModel.actionCreators.sendCommand(thirdPartyService.id, value));

            } else if (serviceActionBodies != null) {
                const actionBody = serviceActionBodies.find(a => a.action === value);
                if (actionBody != null) {
                    actionBody.onSuccess = () => {
                        dispatch(ThirdPartyServiceModel.actionCreators.queryDetails());
                    }
                    dispatch(ThirdPartyServiceModel.actionCreators.sendAction(actionBody));
                }
            }
        }
    }

    const canModify = userPermissions.includes(permissionKeys.MODIFY);
    const canSignIn = userPermissions.includes(permissionKeys.VIEW) && includesSome(userPermissions, [permissionKeys.SUBMIT_JOB, permissionKeys.STAGE_JOB]);
    const canViewSensitive = userPermissions.includes(permissionKeys.VIEW_SENSITIVE);
    const canExport = false && canViewSensitive && ThirdPartyServiceModel.canExport(thirdPartyService);

    const menuOptions = [];
    if (canModify) {
        menuOptions.push(
            {name: t('common:option.edit'), value: 'startEdit'},
            {name: t(`common:option.${thirdPartyService.enabled ? 'deactivate' : 'activate'}`), value: 'toggleEnabled'},
            {name: t('common:option.delete'), value: 'promptDelete'},
        );
    }
    if (canSignIn && thirdPartyService.type !== ThirdPartyServiceModel.Type.LINK) {
        menuOptions.push({isSeparator: true});
        menuOptions.push(
            {name: t('common:option.test'), value: ThirdPartyServiceModel.UserCredentialAction.TEST},
            {name: t('common:option.reset'), value: ThirdPartyServiceModel.UserCredentialAction.RESET}
        );
        if (thirdPartyService.userCredential.signedIn && ThirdPartyServiceModel.canRefreshToken(thirdPartyService)) {
            menuOptions.push(
                {name: t('common:option.refresh'), value: ThirdPartyServiceModel.UserCredentialAction.REFRESH}
            );
        }
        if (ThirdPartyServiceModel.canSignIn(thirdPartyService)) {
            // SERVICE scope requires MODIFY permission
            if (thirdPartyService.authenticationScope !== ThirdPartyServiceModel.AuthenticationScope.SERVICE || canModify) {
                const capitalizedScope = capitalize(thirdPartyService.authenticationScope);
                if (thirdPartyService.userCredential.signedIn) {
                    menuOptions.push({name: t('thirdPartyService:option.signOut' + capitalizedScope), value: 'deleteUserCredential'});
                } else {
                    menuOptions.push({name: t('thirdPartyService:option.signIn' + capitalizedScope), value: 'saveUserCredential'});
                }
            }
        }
    }
    if (canExport || isNotEmptyNorFalsy(serviceActionBodies)) {
        menuOptions.push({isSeparator: true});
    }
    if (canExport) {
        menuOptions.push({name: t('common:option.exportCsv'), value: 'exportCsv'});
    }
    if (serviceActionBodies != null) {
        for (const actionBody of serviceActionBodies) {
            menuOptions.push({
                name: t(`thirdPartyService:action.${actionBody.action}`),
                value: actionBody.action,
                isDisabled: actionBody.disabled
            });
        }
    }

    const showDescription = isEditActive || thirdPartyService.description;
    const height = isEditActive ? 'auto' : '65vh';

    const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);
    if (isViewLimited && !canModify) {
        return (
            <LimitedTablet id={thirdPartyService.id} type={camelCaseType} name={thirdPartyService.serviceName} label={t(`thirdPartyService:label.${camelCaseType}`)}
                           status={thirdPartyService.status} enabled={thirdPartyService.enabled} availableByDefault={thirdPartyService.availableByDefault} canModify={canModify} isDisabled={isDisabled} description={thirdPartyService.description}
                           onClose={onClose}
            />
        )
    }

    return (
        <>
            <Tablet width={'100rem'} height={height} onClose={onClose} isDisabled={isDisabled}
                closeButtonAriaLabel={t(`thirdPartyService:option.closeTablet_name${thirdPartyService.type}`, {name: thirdPartyService.name})}
                header={
                    <EditTabletHeader id={thirdPartyService.id} label={t(`thirdPartyService:label.${camelCaseType}`)} type={camelCaseType}
                        name={'serviceName'} value={thirdPartyService.serviceName} enabled={thirdPartyService.enabled} inputHandler={inputHandler}
                        menuOptions={menuOptions} menuOptionHandler={menuOptionHandler} canModify={canModify || canSignIn}
                        isEditActive={isEditActive} isDisabled={isDisabled}/>
                }
                body={
                <>
                    {!isEditActive && [statusKeys.ERROR, statusKeys.WARNING, statusKeys.INFO].includes(thirdPartyService.status.code) &&
                        <div className="display-item">
                            <ExpandableStatusLog {...thirdPartyService.status} isDisabled={isDisabled}/>
                        </div>
                    }

                    <div className="display-item">
                        <DefaultEditPanel isActive={isEditActive} isSaveEnabled={isSaveEnabled} onSave={saveEdit}
                            onCancel={cancelEdit} isDisabled={isDisabled}>

                            {showDescription &&
                                <ExpandableEditTextArea label={t('common:label.description')} name={'description'} value={thirdPartyService.description}
                                    onChange={inputHandler} isEditActive={isEditActive} isDisabled={isDisabled}/>
                            }

                            <ThirdPartyServiceTabletBody thirdPartyService={thirdPartyService} inputHandler={inputHandler} toggleHandler={toggleHandler}
                                dropdownHandler={dropdownHandler} setWhitelistedCertFingerprints={setWhitelistedCertFingerprints}
                                setState={editHandler} isEditActive={isEditActive} isDisabled={isDisabled}/>

                        </DefaultEditPanel>
                    </div>

                    {isNotEmptyNorFalsy(thirdPartyService.log) && !isEditActive &&
                        <div className="display-item">
                            <ExpandableTimeStampedLog label={t('common:label.log')} log={thirdPartyService.log} isDisabled={isDisabled}/>
                        </div>
                    }
                </>
                }
            />

            {isUserCredentialFormActive &&
                <ThirdPartyUserCredentialForm thirdPartyService={thirdPartyService}
                    onClose={() => setUserCredentialFormActive(false)}/>
            }

            {TestFormComponent &&
                <TestFormComponent thirdPartyService={thirdPartyService}
                    onClose={() => setTestFormComponent(null)} isDisabled={isDisabled}/>
            }

            {ExportFormComponent &&
                <ExportFormComponent thirdPartyService={thirdPartyService}
                    onClose={() => setExportFormComponent(null)} isDisabled={isDisabled}/>
            }
        </>
    )
}

function ThirdPartyServiceTabletBody(props) {
    const {
        thirdPartyService,

        inputHandler,
        toggleHandler,
        dropdownHandler,
        setWhitelistedCertFingerprints,
        setState,

        isEditActive,
        isDisabled
    } = props;

    const [TypeFormBody, TypeSettings] = getSettingsAndFormBodyComponent(thirdPartyService.type);

    if (isEditActive) {
        return (
            <TypeFormBody thirdPartyService={thirdPartyService} inputHandler={inputHandler} toggleHandler={toggleHandler}
                dropdownHandler={dropdownHandler} setWhitelistedCertFingerprints={setWhitelistedCertFingerprints}
                setState={setState} isDisabled={isDisabled}/>
        )
    }
    return (
        <ThirdPartyServiceSettings thirdPartyService={thirdPartyService}
            TypeSettings={TypeSettings} isDisabled={isDisabled}/>
    )
}

function ThirdPartyServiceSettings(props) {
    const {t} = useTranslation(['thirdPartyService']);
    const {
        thirdPartyService,
        TypeSettings,
        isDisabled
    } = props;

    const names = useSelector(state => selectThirdPartyServiceNames(state, thirdPartyService));
    const userCredential = thirdPartyService.userCredential;

    const viewableSettings = ThirdPartyServiceModel.getViewableSettings(thirdPartyService);
    
    const typeSettingsStyle = ((viewableSettings.defaultSettings && thirdPartyService.availableByDefault)
        || viewableSettings.authenticationSettings) ? {marginTop: '1rem'} : {};

    return (
        <>
            {viewableSettings.defaultSettings && thirdPartyService.availableByDefault &&
                <ExpandableContent label={t('thirdPartyService:label.defaultSettings')} isDisabled={isDisabled}>
                    <div className="settings-table">
                        <div className="table-row-group">
                            <Text value={t(`thirdPartyService:common.availableByDefault`)}
                                   isDisabled={isDisabled}/>
                        </div>
                    </div>
                </ExpandableContent>
            }

            {viewableSettings.authenticationSettings &&
            <ExpandableContent label={t('thirdPartyService:label.authenticationSettings')} isDisabled={isDisabled}
                style={thirdPartyService.availableByDefault ? {marginTop: '1rem'} : {}}>

                <div className="settings-table">
                    <div className="table-row-group">
                    <SettingsRowValue label={t('thirdPartyService:label.scope')}
                        value={t(`thirdPartyService:authenticationScope.${thirdPartyService.authenticationScope}`)}
                        isDisabled={isDisabled}/>

                    <SettingsRowValue label={t('thirdPartyService:label.method')}
                        value={t(`thirdPartyService:authenticationMethod.${thirdPartyService.authenticationMethod}`)} isDisabled={isDisabled}/>

                    {thirdPartyService.authenticationMethod === ThirdPartyServiceModel.AuthenticationMethod.OIDC_AUTHORIZATION_CODE &&
                        <SettingsRowValue label={t('thirdPartyService:label.service')}
                            value={names.authenticationService} isDisabled={isDisabled}/>
                    }
                    </div>

                    {userCredential.signedIn && (userCredential.username || userCredential.tokenLastRefreshDate || userCredential.tokenExpiryDate) &&
                        <>
                            <SettingsRowSeparator/>
                            <SettingsRowValue label={t('thirdPartyService:label.username')} value={userCredential.username}
                                isDisabled={isDisabled}/>
                            <SettingsRowValue label={t('thirdPartyService:label.tokenLastRefreshDate')}
                                value={getLocaleDateTimeFromUTC(userCredential.tokenLastRefreshDate)} isDisabled={isDisabled}/>
                            <SettingsRowValue label={t('thirdPartyService:label.tokenExpiryDate')}
                                value={getLocaleDateTimeFromUTC(userCredential.tokenExpiryDate)} isDisabled={isDisabled}/>
                        </>
                    }
                </div>
            </ExpandableContent>
            }

            {TypeSettings &&
                <TypeSettings thirdPartyService={thirdPartyService} names={names}
                    style={typeSettingsStyle} isDisabled={isDisabled}/>
            }

            {viewableSettings.whitelistedCertFingerprints && isNotEmptyNorFalsy(thirdPartyService.whitelistedCertFingerprints) &&
                <ExpandableContent label={t('common:label.whitelistedCertFingerprints')}
                    style={{marginTop: '1rem'}} isDisabled={isDisabled}
                >
                    {thirdPartyService.whitelistedCertFingerprints.map(fingerprint => (
                        <Text key={fingerprint} value={fingerprint} isEllipsis isDisabled={isDisabled}/>
                    ))}
                </ExpandableContent>
            }
        </>
    )
}

function getSettingsAndFormBodyComponent(type) {
    let TypeSettings, TypeFormBody;
    switch (type) {
        case ThirdPartyServiceModel.Type.PURVIEW:
            TypeFormBody = PurviewServiceFormBody;
            TypeSettings = PurviewServiceSettings;
            break;
        case ThirdPartyServiceModel.Type.VAULT:
            TypeFormBody = VaultServiceFormBody;
            break;
        case ThirdPartyServiceModel.Type.RELATIVITY:
            TypeFormBody = RelativityServiceFormBody;
            TypeSettings = RelativityServiceSettings;
            break;
        case ThirdPartyServiceModel.Type.DISCOVER:
            TypeFormBody = DiscoverServiceFormBody;
            TypeSettings = DiscoverServiceSettings;
            break;
        case ThirdPartyServiceModel.Type.ELASTICSEARCH:
            TypeFormBody = ElasticsearchServiceFormBody;
            TypeSettings = ElasticsearchServiceSettings;
            break;
        case ThirdPartyServiceModel.Type.NLP:
            TypeFormBody = NlpServiceFormBody;
            TypeSettings = NlpServiceSettings;
            break;
        case ThirdPartyServiceModel.Type.ECC:
            TypeFormBody = EccServiceFormBody;
            TypeSettings = EccServiceSettings;
            break;
        case ThirdPartyServiceModel.Type.DERBY_CONTROL:
            TypeFormBody = DerbyControlServiceFormBody;
            TypeSettings = DerbyControlServiceSettings;
            break;
        case ThirdPartyServiceModel.Type.GRAPH:
            TypeFormBody = GraphServiceFormBody;
            TypeSettings = GraphServiceSettings;
            break;
        case ThirdPartyServiceModel.Type.GEN_AI:
            TypeFormBody = GenAiServiceFormBody;
            TypeSettings = GenAiServiceSettings;
            break;
        case ThirdPartyServiceModel.Type.SEMANTIC:
            TypeFormBody = SemanticServiceFormBody;
            TypeSettings = SemanticServiceSettings;
            break;
        case ThirdPartyServiceModel.Type.INVESTIGATE:
            TypeFormBody = InvestigateServiceFormBody;
            TypeSettings = InvestigateServiceSettings;
            break;
        case ThirdPartyServiceModel.Type.SMTP:
            TypeFormBody = SmtpServiceFormBody;
            TypeSettings = SmtpServiceSettings;
            break;
        case ThirdPartyServiceModel.Type.LINK:
            TypeFormBody = LinkServiceFormBody;
            TypeSettings = LinkServiceSettings;
            break;
    }
    return [TypeFormBody, TypeSettings];
}

function getTestFormComponent(type) {
    switch (type) {
        case ThirdPartyServiceModel.Type.SMTP:
            return SmtpServiceTestForm;
    }
}

export function getExportFormComponent(type) {
    switch (type) {
        case ThirdPartyServiceModel.Type.SMTP:
            return SmtpServiceExportForm;
    }
}

export default ThirdPartyServiceTablet;
