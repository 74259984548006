import React, {useRef, useState} from "react";
import FormBuilderConfiguration from "../../../models/workflowbuilder/FormBuilderConfiguration";
import {
    buildClassName,
    filterArrayIndices,
    getNewArrayWithUpdatedValue,
    getTranslatedValueOrDefault,
    isNotEmptyNorFalsy,
    objectTruthyValues,
    shallowCopy
} from "../../../utilities/helperFunctions";
import {
    initialSelectedState,
    useClearSelectedEffect,
    useMoveUpDown,
    useValueSelectHandler
} from "../../../utilities/hooks";
import {AddRemoveButtons, Button} from "../../common/Button/Button";
import Form from "../../common/Form/Form";
import {useTranslation} from "react-i18next";
import ObjectField from "./ObjectField";
import Text from "../../common/Text/Text";
import NavigateButtons from "../../common/NavigateButtons/NavigateButtons";
import {icon} from "../../../utilities/iconResolver";
import {getInvalidMessageSpan} from "../../common/InvalidMessageSpan";


function ObjectListField(props) {
    const {t} = useTranslation(['workflowBuilder', 'common', 'aria']);
    const {
        id,
        formKey,
        configuration,

        tableHeaders,
        FormComponent=ObjectFieldForm,
        RowComponent=ObjectFieldRow,

        'data-type': dataType,
        invalidMessage,
        hideInvalidMessage,
        requiredMessage,
        label,
        value,
        updateState,

        isInvalid,
        isDisabled
    } = props;

    const containerRef = useRef();
    const [selected, setSelected] = useState(initialSelectedState);

    const [activeIndex, setActiveIndex] = useState();
    const valueSelectHandler = useValueSelectHandler({setSelected});
    const [moveUp, moveDown] = useMoveUpDown({selected, setSelected, setArray: updateList});
    useClearSelectedEffect({containerRef, setSelected});


    function updateList(update) {
        updateState(prevState => {
            return {
                [configuration.name]: typeof update === 'function' ? update(prevState[configuration.name]) : update
            };
        });
    }

    function updateField(updates) {
        updateList(prevList => {
            const update = typeof updates === 'function' ? updates(prevList[activeIndex]) : updates;
            const updatedObject = shallowCopy(prevList[activeIndex], update);
            return getNewArrayWithUpdatedValue(prevList, updatedObject, activeIndex);
        });
    }

    function addObject() {
        const defaultObject = configuration.generateDefaultObject();
        updateList(prevList => {
            const updatedList = [...prevList];
            const newIndex = selected.lastSelectedValue != null ? selected.lastSelectedValue + 1 : updatedList.length;
            updatedList.splice(newIndex, 0, defaultObject);
            setSelected(prev => ({...prev, lastSelectedValue: newIndex, values: {[newIndex]: true}}));
            setActiveIndex(newIndex);
            return updatedList;
        });
    }

    function removeSelectedObjects() {
        updateList(prevList => {
            const selectedRowIndices = objectTruthyValues(selected.values);
            const updatedList = filterArrayIndices(prevList, selectedRowIndices);

            let newIndex = selected.lastSelectedValue;
            while (newIndex > 0 && updatedList[newIndex] == null) {
                newIndex--;
            }
            if (newIndex < 0 || updatedList[newIndex] == null) {
                setSelected(initialSelectedState);
            } else {
                setSelected({...initialSelectedState, values: {[newIndex]: true}, lastSelectedValue: newIndex});
            }
            return updatedList;
        });
    }

    const required = isInvalid && !isDisabled && value.length === 0;
    const labelId = label && `${id}:label`;

    const {
        combinedAriaLabelledBy,
        invalidMessageSpan
    } = getInvalidMessageSpan({
        id,
        ariaLabelledBy: [labelId],
        isRequired: required,
        invalidMessage,
        requiredMessage
    });

    const headers = tableHeaders || configuration.formConfiguration.componentConfigurations
        .map(config => FormBuilderConfiguration.getLabelTranslation(t, formKey, config.label))
        .filter(c => c);

    const className = buildClassName(
        'workflow-builder__object-list-field',
        required && 'is-invalid'
    );

    const selectedIndices = objectTruthyValues(selected.values);
    const isRemoveDisabled = selectedIndices.length === 0;

    return (
        <div>
            <div id={id} data-type={dataType} className={className} ref={containerRef}
                aria-labelledby={combinedAriaLabelledBy} aria-invalid={required}
            >
                {value[activeIndex] &&
                    <FormComponent id={id} formKey={formKey} configuration={configuration} onClose={() => setActiveIndex(null)}
                        activeIndex={activeIndex} value={value[activeIndex]} updateState={updateField}/>
                }

                <div className="flex-center" style={{display: 'inline-flex'}}>
                    <AddRemoveButtons onAddClick={addObject} onRemoveClick={removeSelectedObjects}
                        ariaLabelKey={configuration.name} noPadding isRemoveDisabled={isRemoveDisabled} isDisabled={isDisabled}/>

                    <NavigateButtons onClick={[moveUp, moveDown]} length={[selectedIndices.length, selectedIndices.length]}
                        ariaLabelKey={configuration.name} isVertical isDisabled={isDisabled}/>

                    <Text id={labelId} value={label} style={{marginLeft: '0.5rem'}}
                        isDisabled={isDisabled}/>
                </div>

                <div className="workflow-builder__object-list align-top">
                    <div className="table-header-group">
                        <div className={'table-row'}>
                            <div className="table-header no-stretch"/>
                            {headers.map((header, index) =>
                                <div key={index} className="table-header">
                                    <Text value={header} isEllipsis
                                        isDisabled={isDisabled}/>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="table-row-group">
                        {value.map((rowObject, rIndex) => {
                            const isSelected = !isDisabled && selected.values[rIndex];
                            const isInvalid = !configuration.formConfiguration.isValid(rowObject);

                            const className = buildClassName(
                                'table-row',
                                isSelected && 'is-selected',
                                !isDisabled && isInvalid && 'is-required',
                                isDisabled && 'no-hover'
                            );

                            return (
                                <RowComponent key={rIndex} className={className} index={rIndex} setActiveIndex={setActiveIndex}
                                    configuration={configuration} object={rowObject} selectHandler={valueSelectHandler}
                                    formKey={formKey} isDisabled={isDisabled}/>
                            )
                        })}
                    </div>
                </div>
            </div>
            {!hideInvalidMessage && invalidMessageSpan}
        </div>
    )
}

function ObjectFieldRow(props) {
    const {t} = useTranslation(['aria']);
    const {
        className,
        index,

        formKey,
        configuration,
        object,
        selectHandler,
        setActiveIndex,

        isDisabled
    } = props;

    const translatedValues = configuration.getValueTextArray(t, formKey, object);
    return (
        <div className={className} data-index={index} onClick={selectHandler}>
            <div className="table-cell">
                <Button aria-label={t(`aria:hiddenAssistText.edit${configuration.name}`)} isImg isClear
                    onClick={() => setActiveIndex(index)} isDisabled={isDisabled}
                    icon={<img src={icon("edit")} alt={t('aria:hiddenAssistText.editIcon')}/>}
                />
            </div>
            {configuration.formConfiguration.componentConfigurations.map((config, index) => {
                return (
                    <div key={index} className="table-cell">
                        <Text value={translatedValues[index]} isWordWrap
                            isBreakWord={!isNotEmptyNorFalsy(config.allowedValues)} isDisabled={isDisabled}/>
                    </div>
                )
            })}
        </div>
    )
}

function ObjectFieldForm(props) {
    const {t} = useTranslation(['workflowBuilder']);
    const {
        id,
        formKey,
        configuration,
        value,
        updateState,

        onClose,
        isDisabled
    } = props;

    const translationKeys = [
        `workflowBuilder:${formKey}.${configuration.name}_singular`,
        `workflowBuilder:${formKey}.${configuration.name}`
    ];
    const title = getTranslatedValueOrDefault(t, configuration.name, ...translationKeys);

    return (
        <Form onClose={onClose} isDisabled={isDisabled} closeButtonAriaLabel={t('common:option.close_nameForm', {name: title})}
            header={
                <h2 className={'subtitle is-5 is-bold' + (isDisabled ? ' is-disabled' : '')}>
                    {title}
                </h2>
            }
            body={
                <ObjectField root autoFocus id={id} formKey={formKey} configuration={configuration}
                    value={value} updateState={updateState} isDisabled={isDisabled}/>
            }
            footer={
                <Button label={t('common:option.close')} onClick={onClose} isDisabled={isDisabled}/>
            }
        />
    )
}

export default ObjectListField;