import {actionCreator} from "../../utilities/helperFunctions";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import {contextCall, contextSaga, isDisabledWrapper, poll} from "../../saga/sagaFunctions";
import {all, call, put, race, select, take, takeEvery, takeLeading} from "redux-saga/effects";
import ComponentStateModel from "../generics/ComponentStateModel";
import PopupModel from "../scheduler/PopupModel";
import {popupInfoKeys} from "../../i18next/keys";
import SchedulerModel from "../scheduler/SchedulerModel";
import {tryCatchWrapper} from "../../saga/tryCatchWrapper";
import SagaModel from "./SagaModel";
import ReduxModel from "./ReduxModel";
import {details, QUERY_INTERVAL} from "../../utilities/constants";
import ReduxStateModel from "../scheduler/ReduxStateModel";


class UserCommentModel extends ReduxModel {
    static nom = 'UserCommentModel';
    static parentKey = 'objectId';

    static actions = UserCommentModel.buildActions('USER_COMMENT');
    static actionCreators = UserCommentModel.buildActionCreators(UserCommentModel.actions);
    static reducer = UserCommentModel.buildReducer(UserCommentModel.actions);

    static componentActionCreators = {
        ...UserCommentModel.buildComponentActionCreators()
    };

    constructor(model = {}) {
        super();
        this.forceUpdate(model);
    }

    static ObjectType = {
        JOB: 'JOB'
    }

    static buildDefaultFormState(state, props={}) {
        return {};
    }

    static validateFormData(formData, state) {
        return true;
    }

    static buildActions(type) {
        return {
            SET_COMMENTS: `SET_${type}_DETAILS`,
            ADD_COMMENT: `ADD_${type}_DETAILS`,
            UPDATE_COMMENT: `UPDATE_${type}_DETAILS`,
            DELETE_COMMENT: `DELETE_${type}_DETAILS`,

            SHOW_FORM: `SHOW_${type}_FORM`,
            HIDE_FORM: `HIDE_${type}_FORM`,
            SUBMIT_FORM: `SUBMIT_${type}_FORM`,
            SET_VIEWED: `SET_${type}_VIEWED`,

            START_POLLING_DETAILS: `START_POLLING_${type}_DETAILS`,
            STOP_POLLING_DETAILS: `STOP_POLLING_${type}_DETAILS`,
            QUERY_DETAILS: `QUERY_${type}_DETAILS`
        }
    }

    static buildActionCreators(actions) {
        return {
            setComments: actionCreator(actions.SET_COMMENTS, 'objectId', 'comments'),
            addComment: actionCreator(actions.ADD_COMMENT, 'objectId', 'comment'),
            updateComment: actionCreator(actions.UPDATE_COMMENT, 'objectId', 'commentId', 'updates'),
            deleteComment: actionCreator(actions.DELETE_COMMENT, 'objectId', 'commentId'),

            showForm: actionCreator(actions.SHOW_FORM, 'initialState'),
            hideForm: actionCreator(actions.HIDE_FORM),
            submitForm: actionCreator(actions.SUBMIT_FORM, 'objectType', 'objectId', 'formData'),
            setViewed: actionCreator(actions.SET_VIEWED, 'objectType', 'objectId', 'commentId'),

            startPollingDetails: actionCreator(actions.START_POLLING_DETAILS, 'objectType', 'objectId'),
            stopPollingDetails: actionCreator(actions.STOP_POLLING_DETAILS, 'objectId'),
            queryDetails: actionCreator(actions.QUERY_DETAILS, 'objectType', 'objectId')
        }
    }

    static buildComponentActionCreators() {
        const components = [
            {
                key: 'userCommentDisplay',
                type: 'Display',
                state: {
                    isUserCommentFormActive: false
                }
            },
            {
                key: 'userCommentForm',
                type: 'Form',
                state: {
                    objectType: null,
                    message: '',
                    isAddEnabled: false,
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildReducer(actions) {
        return function (state = new Map(), action) {
            switch (action.type) {
                case actions.SET_COMMENTS: {
                    const {objectId, comments} = action.payload;

                    const oldSetting = state.get(objectId);
                    const newSetting = this.setDetailsArray(oldSetting, comments);
                    // If reference is same, return same
                    if (oldSetting !== newSetting) {
                        return new Map(state).set(objectId, newSetting);
                    }
                    return state;
                }
                case actions.ADD_COMMENT: {
                    const {objectId, comment} = action.payload;

                    const newState = new Map(state);
                    const updatedComments = [...(newState.get(objectId) || []), new this(comment)];
                    return newState.set(objectId, updatedComments);
                }
                case actions.UPDATE_COMMENT: {
                    const {objectId, commentId, updates} = action.payload;

                    const comments = state.get(objectId);
                    const index = comments.findIndex(v => v.id === commentId);
                    if (index >= 0) {
                        const oldModel = comments[index];
                        const model = oldModel.duplicate(updates);

                        if (!model.equals(oldModel)) {
                            const newState = new Map(state);
                            const updatedComments = [...comments];
                            updatedComments[index] = model;
                            return newState.set(objectId, updatedComments);
                        }
                    }
                    return state;
                }
                case actions.DELETE_COMMENT: {
                    const {objectId, commentId} = action.payload;

                    const newState = new Map(state);
                    const updatedComments = newState.get(objectId).filter(v => v.id !== commentId);
                    return newState.set(objectId, updatedComments);
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }
}

export class UserCommentApi {

    static get(objectType, objectId) {
        return axiosInstance.get(`/scheduler/userComment/${objectType.toLowerCase()}/${objectId}`);
    }

    static post(objectType, objectId, userComment) {
        return axiosInstance.post(`/scheduler/userComment/${objectType.toLowerCase()}/${objectId}`, userComment);
    }

    static postViewed(objectType, objectId, userCommentId) {
        return axiosInstance.post(`/scheduler/userComment/${objectType.toLowerCase()}/${objectId}/${userCommentId}/viewed`);
    }
}

export class UserCommentSaga extends SagaModel {
    static ModelType = UserCommentModel;
    static ModelApi = UserCommentApi;

    static activationComponent = 'APP';
    static variableNames = {
        detailsMap: 'objectUserComments',
        instanceId: 'userCommentId',
        isFormActive: 'isUserCommentFormActive',
        updateDisplay: 'updateDisplay'
    };

    static translations = {
        itemTitle: '$t(userComment:label.comment)',
        itemLower: '$t(userComment:label.comment_lower)'
    };

    static buildActivationEffects() {
        return [
            // ACTIVATION EFFECTS
            takeLeading(this.ModelType.actions.SHOW_FORM, contextSaga(this, 'showForm')),
            takeLeading(this.ModelType.actions.HIDE_FORM, contextSaga(this, 'hideForm')),
            takeLeading(this.ModelType.actions.SUBMIT_FORM, tryCatchWrapper, isDisabledWrapper, this.ModelType.componentActionCreators.updateForm, contextSaga(this, 'submitForm')),
            takeEvery(this.ModelType.actions.SET_VIEWED, tryCatchWrapper, contextSaga(this, 'setViewed'))
        ];
    }

    static* setViewed(action) {
        const {objectType, objectId, commentId} = action.payload;

        const {data} = yield contextCall(UserCommentApi, 'postViewed', objectType, objectId, commentId);
        yield put(UserCommentModel.actionCreators.updateComment(objectId, commentId, data));
    }

    static* submitForm(action) {
        const {objectType, objectId, formData} = yield action.payload;
        const saveValues = yield contextCall(this, 'getSaveValues', formData);

        const {data} = yield contextCall(UserCommentApi, 'post', objectType, objectId, saveValues);
        yield all([
            put(UserCommentModel.actionCreators.addComment(objectId, data)),
            put(UserCommentModel.actionCreators.hideForm())
        ]);
    }

    static* callCommentFormDiscardingEffect(effect) {
        const yieldEffectAndHideForm = all([
            effect,
            put(UserCommentModel.actionCreators.hideForm())
        ]);

        const [{isUserCommentFormActive}, {message}] = yield all([
            select(state => state.componentStates.userCommentDisplay),
            select(state => state.componentStates.userCommentForm)
        ]);

        // Show discard warning if commentForm has message
        if (isUserCommentFormActive && !!message) {
            yield put(PopupModel.actionCreators.showWarning({
                info: {
                    key: popupInfoKeys.DISCARD_COMMENT
                },
                buttons: [{
                    titleKey: 'common:option.discard',
                    onClick: dispatch => dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(yieldEffectAndHideForm))
                }]
            }));
        } else {
            yield yieldEffectAndHideForm;
        }
    }

    static* pollDetails(action) {
        const {objectId} = action.payload;

        const waitForStop = function* () {
            while (true) {
                const {payload} = yield take(this.ModelType.actions.STOP_POLLING_DETAILS);
                // Check if stop is for this poll id; using 'null' as a STOP_ALL command
                if (payload.objectId == null || objectId === payload.objectId) {
                    return;
                }
            }
        }.bind(this);

        yield race([
            call(waitForStop),
            call(poll, QUERY_INTERVAL, contextSaga(this, 'queryDetails'), action)
        ]);
    }

    static* queryDetails(action) {
        const {objectType, objectId} = action.payload;
        const response = yield contextCall(UserCommentApi, 'get', objectType, objectId);

        const key = `${details.USER_COMMENTS}:${objectId}`;
        if (AxiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(UserCommentModel.actionCreators.setComments(objectId, response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }
}

export default UserCommentModel;
