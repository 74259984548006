import React from 'react';
import './Form.css';
import CustomModal from "../CustomModal/CustomModal";
import {icon} from "../../../utilities/iconResolver";
import {useTranslation} from "react-i18next";
import {ButtonGroup} from "../Button/Button";

function Form(props) {
    const {
        id='form',
        header,
        body,
        footer,
        width,
        height,
        onClose,
        hideFooter,
        style,
        disableFocusTrap,

        closeButtonAriaLabel,

        isDisabled,
        ...attr
    } = props;

    const bodyStyle = {width, height};
    if (style != null) {
        Object.assign(bodyStyle, style);
    }

    const headerId = `${(attr.id || 'form')}HeaderTitle`;

    return (
        <CustomModal id={id} isActive onCancel={onClose} disableFocusTrap={disableFocusTrap}
            isDisabled={isDisabled} isRigid={isDisabled} closeButtonAriaLabel={closeButtonAriaLabel}
            aria-labelledby={headerId}
            header={
                <section className="form-header" id={headerId}
                    style={width ? {width: `calc(${width} - 1.5em + 0.75em + 2px - 1.5em)`} : {}}>
                    {header}
                </section>
            }
            body={
                <section className="form-body" style={bodyStyle} {...attr}>
                    {body}
                </section>
            }
            footer={!hideFooter &&
                <section className="form-footer">
                    {footer}
                </section>
            }
        />
    )
}

export const FormHeader = props => {
    const {t} = useTranslation(['aria']);
    const {headerId, text, iconName, isDisabled} = props;

    const disabled = isDisabled ? ' is-disabled': '';

    return (
        <>
            <h2 id={headerId} className={'subtitle is-5 is-bold' + disabled}>
                {text}
            </h2>
            {iconName &&
            <span className={'icon is-medium' + disabled}>
                <img src={icon(iconName)} alt=""/>
            </span>
            }
        </>
    )
}

export function FormFooter(props) {
    const {t} = useTranslation(['common']);
    const {addText, addButtonId, onAddClick, isAddEnabled, cancelButtonId, cancelTitle, onCancel, isDisabled} = props;

    const buttons = [];
    if (typeof onCancel === 'function') {
        buttons.push({
            id: cancelButtonId || 'formFooterBackButton',
            label: cancelTitle || t('common:option.cancel'),
            onClick: onCancel,
            isDisabled
        });
    }
    if (typeof onAddClick === 'function') {
        buttons.push({
            id: addButtonId || 'formFooterNextButton',
            label: addText,
            onClick: onAddClick,
            isDisabled: isDisabled || !isAddEnabled
        });
    }

    return (
        <ButtonGroup buttons={buttons}/>
    )
}

export default Form;
