import React, {useCallback, useEffect} from 'react';
import ClientPanel from "../../panels/ClientPanel";
import MatterPanel from "../../panels/MatterPanel";
import {getValues, includesSome} from "../../../utilities/helperFunctions";
import {permissionKeys} from "../../../i18next/keys";

function SelectMatterPane(props) {
    const {
        jobState,
        updateState,
        preparePopulateJobSettings
    } = props;

    useEffect(() => {
        if (typeof preparePopulateJobSettings === 'function') {
            preparePopulateJobSettings();
        }
    },[]);

    const clientSelector = useCallback(reduxState => {
        return getValues(reduxState.clientDetailsMap)
            .filter(client => client.enabled
                && includesSome(client.userPermissions, [permissionKeys.SUBMIT_JOB, permissionKeys.STAGE_JOB]));
    }, []);

    const matterSelector = useCallback(state => {
        return getValues(state.matterDetailsMap)
            .filter(matter => matter.enabled && matter.clientId === jobState.clientId
                && includesSome(matter.userPermissions, [permissionKeys.SUBMIT_JOB, permissionKeys.STAGE_JOB]));
    }, [jobState.clientId]);


    return (
        <section className="selectPane">
            <ClientPanel clientId={jobState.clientId} updateState={updateState} canAddUnassigned
                clientSelector={clientSelector} isDisabled={jobState.isDisabled}/>

            <MatterPanel clientId={jobState.clientId} matterId={jobState.matterId} updateState={updateState}
                matterSelector={matterSelector} isDisabled={jobState.isDisabled}/>
        </section>
    )
}

export default SelectMatterPane;
