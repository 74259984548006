import {licenseEditionType, operationComponents} from "./constants";
import {StepComponents} from "./StepComponents";

export function trimBaseFolder (baseFolder) {
    if (baseFolder.endsWith("\\") || baseFolder.endsWith("/")) {
        return baseFolder.toString().slice(0, -1)
    }
    return baseFolder;
}

export function getCaseNameFromPath (baseFolder) {
    let caseName = trimBaseFolder(baseFolder);
    if (caseName.length < 5) {
        return ""
    }
    if (caseName.split("/").length !== 1) {
        return caseName.split("/").slice(-1);
    }

    if (caseName.split("\\").length !== 1) {
        return caseName.split("\\").slice(-1);
    }
}

export function getCaseParentFolder (baseFolder) {
    let caseName = trimBaseFolder(baseFolder);

    if (caseName.lastIndexOf("/") !== -1) {
        return caseName.substr(0, caseName.lastIndexOf("/"));
    }

    if (caseName.lastIndexOf("\\") !== -1) {
        return caseName.substr(0, caseName.lastIndexOf("\\"));
    }
}

export function getSystemDefaultTimezone () {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function getConfigurationProfileName (path) {
    if (path.length === 0) {
        return "";
    }

    const pathSplit = path.split("\\");
    return pathSplit[pathSplit.length - 1];
}

export function getExampleType (caseStructureIndex) {
    let exampleType = "";
    switch (caseStructureIndex) {
        default:
        case 1:
            exampleType = "CLIENT_MATTER";
            break;
        case 2:
            exampleType = "SINGLE_FOLDER";
            break;
        case 3:
            exampleType = "NONE";
            break;
    }
    return exampleType;
}

export function getGroupPanels (panelIds, next, back, t, valid, files) {
    return panelIds.map(key => {
        const operation = operationComponents[key];
        return {
            id: operation.id,
            title: t(operation.title),
            description: t(operation.description),
            component: StepComponents({id: operation.component, files}),
            isNextEnabled: valid[operation.id],
            onBackClick: back,
            onNextClick: next
        }
    })
}

export function download(fileName, data) {
    const element = document.createElement('a');
    const file = new Blob([data], {type: 'text/rfn'});
    const blobUrl = URL.createObjectURL(file);
    element.href = blobUrl;
    element.download = `${fileName}.rfn`;
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();

    document.body.removeChild(element);
    // clear blobUrl
    window.URL.revokeObjectURL(blobUrl);
}

export function containsEdition (expectedEdition, editionString) {
    let currentEdition = 0;
    Object.keys(licenseEditionType).forEach((o) => {
        if (licenseEditionType[o].name === editionString) {
            currentEdition = licenseEditionType[o].edition;
        }
    });
    return currentEdition >= expectedEdition.edition;
}