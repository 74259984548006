import React from "react";
import {useTranslation} from "react-i18next";
import {createInputHandler} from "../../../utilities/componentFunctions";
import TextArea from "../../common/TextArea/TextArea";
import {operationIconModel} from "../../../models/generics/IconModel";
import {buildClassName, isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import FormBuilder from "../components/FormBuilder";
import OperationParameters from "../components/OperationParameters";
import OperationHelpers from "../components/OperationHelpers";
import Text from "../../common/Text/Text";
import OperationStateIcons from "./OperationStateIcon";

function OperationForm(props) {
    const {t} = useTranslation(['workflowBuilder', 'common']);

    const {
        formKey,
        formConfiguration,
        operation,
        updateState,
        isDisabled
    } = props;

    const inputHandler = createInputHandler({
        handler: updateState
    });

    const title = t(`workflowBuilder:operationAlias.${operation.operationAlias}`);
    const operationIcon = operationIconModel.useIcon(operation.operationAlias);

    const headerClassName = buildClassName(
        'workflow-builder__sticky-header flex-center space-between',
        operation.disabled && 'is-disabled'
    );

    return (
        <div className="flex-expand" style={{height: '100%'}}>
            <div className={headerClassName} style={{marginBottom: '1rem'}}>
                <div className="flex-center">
                    <span className="icon is-medium" style={{marginRight: '0.5rem'}}>
                        <img src={operationIcon} alt={title}/>
                    </span>
                    <h2 id="operationFormTitle" className="subtitle is-bold">
                        {title}
                    </h2>
                </div>

                <OperationStateIcons operation={operation} size={'lg'}/>
            </div>

            {formConfiguration &&
                <div id={"operationFormContent"}>
                    {isNotEmptyNorFalsy(formConfiguration?.operationHelpers) &&
                        <OperationHelpers formConfiguration={formConfiguration}
                                             operationAlias={operation.operationAlias} isDisabled={isDisabled}/>
                    }

                    <TextArea id="operationNotes" label={t('workflowBuilder:label.notes')} rows={3}
                        name={'notes'} value={operation.notes} onChange={inputHandler} isDisabled={isDisabled}/>

                    <FormBuilder formKey={formKey} formConfiguration={formConfiguration}
                        state={operation} updateState={updateState} isDisabled={isDisabled}/>

                    {isNotEmptyNorFalsy(formConfiguration?.operationParameters) &&
                        <OperationParameters formConfiguration={formConfiguration}
                            operationAlias={operation.operationAlias} isDisabled={isDisabled}/>
                    }
                </div>
            }
            {!formConfiguration &&
                <div className="is-invalid" style={{border: '1px solid transparent', flex: 1}}>
                    <Text value={t('workflowBuilder:message.operationDetailsNotAvailable')}
                        isCentered isItalic style={{marginTop: '12.5rem'}}/>
                </div>
            }
        </div>
    )
}

export default OperationForm;
