import SettingModel from "../generics/SettingModel";

class TriggerConfigurationEventModel extends SettingModel.Complex {

    static nom = 'TriggerConfigurationEventModel';
    static actions = TriggerConfigurationEventModel.buildActions('TRIGGER_CONFIGURATION_EVENT');
    static actionCreators = TriggerConfigurationEventModel.buildActionCreators(TriggerConfigurationEventModel.actions);
    static reducer = TriggerConfigurationEventModel.buildReducer(TriggerConfigurationEventModel.actions);

    constructor(model={}) {
        super(model);
    }
}

export default TriggerConfigurationEventModel;