import React from 'react'
import RadioButton from "../../../../components/common/RadioButton/RadioButton";

function QuestionRadio (props) {
    const {question, dataName, options, selectedValue, onChange} = props;

    const update = (event) => {
        const {name, value} = event.target;
        return onChange({currentTarget: {dataset: {name: name, value: parseInt(value)}}});
    }

    return (
        <div className={"display-item"}>
            <label className="label is-heading">
                {question}
            </label>
            <div className={'settings-table no-border tight-padding'} style={{border: '1px solid transparent'}}>
                <div className="table-row-group">
                    {options.map(option => {
                        return (
                            <div key={option.id} className="settings-row">
                                <div key={option.id} className="table-cell no-stretch">
                                    <RadioButton name={dataName} label={option.value} selected={selectedValue} value={option.id} onClick={update} isWordWrap={false}/>
                                </div>
                            </div>
                        )
                    })}
                    <div key={"place"} className="table-cell no-stretch"/>
                </div>
            </div>
        </div>
    )

}

export default QuestionRadio;