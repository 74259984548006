import React from "react";
import {getStatusIconImgContent} from "../../../../utilities/iconResolver";

export function WizardStatusLabel(props) {
    const {iconName='statusWarning', message, style, isDisabled} = props;

    const disabled = isDisabled ? ' is-disabled' : '';

    return (
        <div style={{display: 'flex', alignItems: 'center', flex: 1, ...style}}>
            <span className={'icon is-small' + disabled} style={{margin: '0 0.5rem'}}>
                {getStatusIconImgContent(iconName)}
            </span>
            <label className={'label' + disabled} style={{flex: 1, margin: 'auto'}}>
                {message}
            </label>
        </div>
    )
}