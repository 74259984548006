import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {SettingsRowSeparator, SettingsRowValue} from "../common/CustomTable/CustomTable";
import {
    arrayIsNotEmptyNorFalsy,
    bytesCountToReadableCount,
    capitalize,
    getLocaleDateTimeFromUTC,
    getValues,
} from "../../utilities/helperFunctions";
import UppyUpload from "./uppy/UppyUpload";
import {datasetStateKeys} from "../../i18next/keys";
import DatasetModel, {DatasetApi} from "../../models/data/DatasetModel";
import PopupModel from "../../models/scheduler/PopupModel";
import Switch from "../common/Switch/Switch";
import {Button} from "../common/Button/Button";
import SchedulerModel from "../../models/scheduler/SchedulerModel";
import MatterModel from "../../models/client/MatterModel";
import {setIsIdleHandlerPaused} from "../../saga/idleHandler";
import UploadInfoTable from "./UploadInfoTable";
import {datasetCommands, datasetType, userSettings} from "../../utilities/constants";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {icon} from "../../utilities/iconResolver";
import UploadInfoModel from "../../models/data/UploadInfoModel";
import FileInfoTable from "./FileInfoTable";
import {MenuDropdown} from "../common/Dropdown/Dropdown";
import EditModel from "../../models/scheduler/EditModel";
import FileInfoModel, {FileInfoSaga} from "../../models/data/FileInfoModel";
import {StatusLabel} from "../common/Common";
import {call, put} from "redux-saga/effects";
import {contextCall} from "../../saga/sagaFunctions";


function selectHasFiles(state, datasetId) {
    const fileInfoRows = state.fileInfoRowsMap.get(datasetId);
    return Array.isArray(fileInfoRows) && arrayIsNotEmptyNorFalsy(fileInfoRows.slice(1));
}

function selectFileInfoEditActive(state) {
    return state.editDetails.activeModel === FileInfoModel.nom;
}

function DatasetDisplay(props) {
    const {t} = useTranslation(['aria', 'dataset', 'dataRepository']);
    const dispatch = useDispatch();

    const {
        datasetId,
        canModify,
        isDisabled
    } = props;

    const dataset = useSelector(state => state.datasetDetailsMap.get(datasetId));
    const {
        name,
        type,
        description,
        dataRepositoryId,
        state,
        createdDate,
        lastModifiedDate,
        finalizedDate,
        usableSpace: datasetUsableSpace
    } = dataset;

    const hasFiles = useSelector(state => selectHasFiles(state, datasetId));
    const {showObjectIds} = useSelector(state => state.userSettingsMap.get(userSettings.TROUBLESHOOT));

    const dataRepository = useSelector(state => state.dataRepositoryDetailsMap.get(dataRepositoryId)) || {};
    const {
        name: repositoryName,
        usableSpace,
        quotaEnabled,
        computeFileSystemUsableSpace,
        datasetMaxSizeEnabled,
        uploadMaxSizeEnabled,
        uploadMaxSize
    } = dataRepository;

    const {
        isUploadActive
    } = useSelector(state => state.componentStates.datasetView);


    function menuHandler(event) {
        const {value} = event.currentTarget.dataset;

        switch (value) {
            case 'exportAll':
                dispatch(DatasetModel.actionCreators.downloadFilesMetadataCsv(datasetId, true));
                break;
            case 'delete':
                dispatch(EditModel.actionCreators.callEditBreakingFunc(function () {
                    dispatch(DatasetModel.actionCreators.promptDelete(datasetId));
                }));
                break;
            default:
                const titleKey = `dataset:option.${value}`;
                const popupKey = `${value}Dataset`;
                const popupShow = (value === 'expire') ? 'showWarning' : 'show';

                dispatch(PopupModel.actionCreators[popupShow]({
                    info: {
                        key: popupKey,
                        values: {
                            itemName: name
                        }
                    },
                    buttons: [{
                        titleKey,
                        onClick: () => {
                            dispatch(DatasetModel.actionCreators.sendDatasetCommand(datasetId, value.toUpperCase()));
                        }
                    }]
                }));
                break;
        }
    }

    let menuOptions;
    switch (state) {
        case datasetStateKeys.DRAFT:
            menuOptions = [{
                name: t('dataset:option.finalize'),
                value: 'finalize',
                isDisabled: isDisabled || !hasFiles
            }];
            break;
        case datasetStateKeys.FINALIZED:
            menuOptions = [{
                name: t('dataset:option.hide'),
                value: 'hide',
                isDisabled
            }];
            break;
        case datasetStateKeys.HIDDEN:
            menuOptions = [{
                name: t('dataset:option.show'),
                value: 'show',
                isDisabled
            }, {
                name: t('dataset:option.archive'),
                value: 'archive',
                isDisabled
            }];
            break;
        case datasetStateKeys.ARCHIVED:
            menuOptions = [{
                name: t('dataset:option.unarchive'),
                value: 'unarchive',
                isDisabled
            }, {
                name: t('dataset:option.expire'),
                value: 'expire',
                isDisabled
            }];
            break;
        default:
            menuOptions = [];
            break;
    }
    menuOptions.push({
        name: t('common:option.delete'),
        value: 'delete',
        isDisabled
    }, {
        isSeparator: true
    }, {
        name: t('dataset:option.exportAll'),
        value: 'exportAll',
        isDisabled
    });

    const isManagedType = type === datasetType.MANAGED;
    const isInPlaceType = type === datasetType.IN_PLACE;
    const showAvailableSpace = isManagedType && (quotaEnabled || computeFileSystemUsableSpace || datasetMaxSizeEnabled)
        && state === datasetStateKeys.DRAFT;
    const showUploadMaxSize = isManagedType && uploadMaxSizeEnabled && state === datasetStateKeys.DRAFT;

    return (
        <section className="tablet-view__display">

            {!isUploadActive &&
            <>
                <section className="tablet-view__display__header">
                    <div style={{display: 'inline-flex'}}>
                        <h2 className="subtitle is-bold">
                            {name}
                        </h2>

                        {canModify && menuOptions.length > 0 &&
                            <div className="display-menu">
                                <MenuDropdown id={"datasetMenuDropdown"} aria-label={t('aria:hiddenAssistText.datasetMenu')}
                                    menuOptions={menuOptions} onOptionClick={menuHandler} isDisabled={isDisabled || isUploadActive}
                                />
                            </div>
                        }
                    </div>

                    <div style={{display: 'inline-flex', alignItems: 'center'}}>
                        <h2 className="subtitle" style={{flexShrink: '0', marginLeft: '2.5rem', marginRight: '0.5rem'}}>
                            {t(`dataset:state.${state}`)}
                        </h2>
                        <span className="icon is-medium">
                        <img src={icon(`dataset${capitalize(state)}`)} alt={t(`aria:hiddenAssistText.${state}Icon`)}/>
                    </span>
                    </div>
                </section>

                {showObjectIds &&
                    <span className="label" style={{fontSize: '0.75rem', padding: '0 1rem'}}>
                        {datasetId}
                    </span>
                }
            </>
            }

            <section className="tablet-view__display__info">
                {!isUploadActive &&
                <>
                    <div className="settings-table display-item">
                        <div className="table-row-group">

                            <SettingsRowValue label={t('dataset:label.type')}
                                value={t(`dataset:type.${type}`)}
                            />

                            <SettingsRowValue label={t('dataset:label.repository')}
                                value={repositoryName}
                            />

                            {isInPlaceType &&
                                <SettingsRowValue label={t('dataset:label.path')}
                                    value={dataset.path}
                                />
                            }

                            {showAvailableSpace &&
                                <SettingsRowValue label={t('dataRepository:label.effectiveUsableSpace')}
                                    value={usableSpace == null ?
                                        <StatusLabel
                                            message={t('dataRepository:message.repositoryInaccessible')}/>
                                        :
                                        bytesCountToReadableCount(dataRepository.getAvailableSpace && dataRepository.getAvailableSpace(datasetUsableSpace))
                                    }
                                />
                            }

                            {showUploadMaxSize &&
                                <SettingsRowValue label={t('dataset:label.uploadMaxSize')}
                                    value={bytesCountToReadableCount(uploadMaxSize)}
                                />
                            }

                            <SettingsRowSeparator/>

                            <SettingsRowValue label={t('dataset:label.createdDate')}
                                value={getLocaleDateTimeFromUTC(createdDate)}
                            />
                            <SettingsRowValue label={t('dataset:label.lastModifiedDate')}
                                value={getLocaleDateTimeFromUTC(lastModifiedDate)}
                            />
                            <SettingsRowValue label={t('dataset:label.finalizedDate')}
                                value={getLocaleDateTimeFromUTC(finalizedDate)}
                            />
                        </div>
                    </div>

                    {description &&
                    <div className="display-item">
                        <p className="label is-wordwrap">
                            {description}
                        </p>
                    </div>
                    }
                </>
                }

                <DatasetDisplayBody datasetId={datasetId} uploadMaxSize={showUploadMaxSize && uploadMaxSize}
                    canModify={canModify} isDisabled={isDisabled}/>

            </section>
        </section>
    )
}


export function DatasetDisplayBody(props) {
    const {t} = useTranslation(['aria', 'dataset', 'dataRepository']);
    const dispatch = useDispatch();

    const {
        datasetId,
        canModify,
        uploadMaxSize,
        isDisabled
    } = props;

    const dataset = useSelector(state => state.datasetDetailsMap.get(datasetId));
    const {
        name,
        type,
        state
    } = dataset;

    const isLoading = useSelector(state => !state.hasLoaded[datasetId]);
    const isFileInfoEditActive = useSelector(selectFileInfoEditActive);
    const {
        isUploadActive
    } = useSelector(state => state.componentStates.datasetView);

    const uploadInfos = useSelector(state => getValues(state.uploadInfoDetailsMap).filter(info => info.datasetId === datasetId));
    const hasFiles = useSelector(state => selectHasFiles(state, datasetId));

    // Reset isUploadActive if state switches away from DRAFT (e.g. from another source)
    useEffect(() => {
        if (state !== datasetStateKeys.DRAFT) {
            dispatch(DatasetModel.componentActionCreators.updateView({isUploadActive: false}));
        }
    }, [state, dispatch]);

    // Pause idleHandler when isUploadActive == true
    useEffect(() => {
        if (isUploadActive) {
            setIsIdleHandlerPaused(true);
            // onReset clear pause
            return () => setIsIdleHandlerPaused(false);
        }
    }, [isUploadActive]);


    async function onFileInfosDelete(fileInfoIds) {
        // Disable pane
        dispatch(MatterModel.componentActionCreators.updateTablet({isDisabled: true}));
        try {
            const fileInfosRes = await DatasetApi.deleteFiles(datasetId, fileInfoIds);

            // Using yieldEffectDescriptor to leverage sagas
            dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(call(function* () {
                const fileInfoRows = yield contextCall(FileInfoSaga, 'buildFileInfoRows', datasetId, fileInfosRes.data);
                yield put(FileInfoModel.actionCreators.setDetails(datasetId, fileInfoRows));
            })));
        } catch (error) {
            dispatch(SchedulerModel.actionCreators.handleResponseError(error));

        } finally {
            dispatch(MatterModel.componentActionCreators.updateTablet({isDisabled: false}));
        }
    }

    async function onUploadInfosDelete(uploadInfoIds) {
        // Disable pane
        dispatch(MatterModel.componentActionCreators.updateTablet({isDisabled: true}));
        try {
            const uploadInfosRes = await DatasetApi.deleteUploadInfos(datasetId, uploadInfoIds);
            dispatch(UploadInfoModel.actionCreators.setDetailsMap(datasetId, uploadInfosRes.data));

        } catch (error) {
            dispatch(SchedulerModel.actionCreators.handleResponseError(error));

        } finally {
            dispatch(MatterModel.componentActionCreators.updateTablet({isDisabled: false}));
        }
    }

    function finalize() {
        dispatch(PopupModel.actionCreators.show({
            info: {
                key: 'finalizeDataset',
                values: {
                    itemName: name
                }
            },
            buttons: [{
                titleKey: 'dataset:option.finalize',
                onClick: () => {
                    dispatch(DatasetModel.actionCreators.sendDatasetCommand(datasetId, datasetCommands.FINALIZE));
                }
            }]
        }));
    }

    function cancelUpload() {
        dispatch(DatasetModel.actionCreators.cancelUpload());
    }

    const isManagedType = type === datasetType.MANAGED;

    return (
        <Switch>
            {isManagedType && (canModify && isUploadActive) &&
            <div className="display-item">

                <UppyUpload datasetId={datasetId} uploadMaxSize={uploadMaxSize}
                    height={'calc(65vh - 3rem - 4rem)'} isDisabled={isDisabled} updateView={updates => dispatch(DatasetModel.componentActionCreators.updateView(updates))}/>

                <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem'}}>
                    <Button id={'uppyUploadCancel'} label={t(`common:option.${isUploadActive ? 'cancel' : 'back'}`)} onClick={cancelUpload}
                        isDisabled={isDisabled}
                    />
                </div>
            </div>
            }

            <LoadingWrapper isLoading={isLoading}>
                {arrayIsNotEmptyNorFalsy(uploadInfos) &&
                <div className="display-item">
                    <UploadInfoTable uploadInfos={uploadInfos} onUploadFilesDelete={onUploadInfosDelete}
                        canModify={canModify} isFinalized={dataset.isFinalized()} isDisabled={isDisabled}
                    />
                </div>
                }

                <div className="display-item">
                    <Switch>
                        <>
                            <FileInfoTable datasetId={datasetId} canModify={canModify && dataset.isDraft()}
                                onFileInfosDelete={onFileInfosDelete} isDisabled={isDisabled}
                            />

                            {!hasFiles &&
                            <label className="label is-italic" style={{paddingTop: '2.5rem', textAlign: 'center'}}>
                                {t('dataset:message.datasetHasNoFiles')}
                            </label>
                            }

                            {hasFiles && !isFileInfoEditActive && dataset.isDraft() &&
                            <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '0.75rem'}}>
                                <Button label={t('dataset:option.finalize')} onClick={finalize}
                                    data-value={'finalize'} disabled={isDisabled}
                                >
                                        <span className="icon is-small" style={{marginTop: '1px', marginLeft: '0.25rem'}}>
                                            <img src={icon('save')} alt={t('aria:hiddenAssistText.saveIcon')}/>
                                        </span>
                                </Button>
                            </div>
                            }
                        </>
                    </Switch>
                </div>
            </LoadingWrapper>
        </Switch>
    )
}

export default DatasetDisplay;
