import {getObjectText, getValues} from "../../utilities/helperFunctions";
import {createSelector} from "reselect";
import {MIN_SIZE_FOR_SEARCH} from "../../utilities/constants";
import {makeGetEditDetails} from "../../reselect/selectors";
import LibraryModel from "../../models/library/LibraryModel";


function makeSelectLibraryDetails() {
  const selectEditDetails = makeGetEditDetails();

  return function(state, props) {

    const {activeModel, values, isSaveEnabled} = selectEditDetails(state, {model: LibraryModel.nom});
    const isEditActive = (activeModel === LibraryModel.nom);

    const {name: libraryName, ...otherDetails} = state.libraryDetailsMap.get(props.libraryId);

    return {
      libraryName,
      ...otherDetails,
      ...values,
      isEditActive,
      isSaveEnabled
    };
  }
}
export const selectLibraryDetails = makeSelectLibraryDetails();

export const selectFilteredWorkflowTemplateIds = createSelector(
  state => state.workflowTemplateDetailsMap,
  (ignore, props) => props.libraryId,
  (ignore, props) => props.searchText,
  (workflowTemplateDetailsMap, libraryId, searchText) => {

    return getValues(workflowTemplateDetailsMap)
      .filter(_workflow => getObjectText(_workflow).includes(searchText.toLowerCase()))
      .filter(_workflow => _workflow.libraryId === libraryId)
      .map(_workflow => _workflow.id);
  }
);

export function selectCanSearchWorkflows(state, props) {
  return getValues(state.workflowTemplateDetailsMap)
    .filter(_workflow => _workflow.libraryId === props.libraryId)
    .length >= MIN_SIZE_FOR_SEARCH;
}
