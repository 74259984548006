import React, {useEffect, useState} from "react";
import HTMLTextInput from "./HTMLTextInput";
import Text from "../Text/Text";
import {buildFakeEvent} from "../../../utilities/helperFunctions";


function UtcDateTimeInput(props) {
    const {
        value,
        inputHandler,
        min,
        max,
        leftLabel,
        ...rest
    } = props;

    // Track as internal state to prevent re-computing on every render
    const [dateTimeValue, setDateTimeValue] = useState();
    const [minMaxDates, setMinMaxDates] = useState({});

    useEffect(() => {
        setDateTimeValue(getLocalDateTimeValueFromUtc(value));
        setMinMaxDates({
            max: isNaN(parseInt(max)) ? null : getLocalDateTimeValueFromUtc(max),
            min: isNaN(parseInt(min)) ? null : getLocalDateTimeValueFromUtc(min),
        });
    }, [value, min, max]);

    // Translate to UTC and send update
    function onChange(event) {
        const {name, value} = event.target;
        inputHandler(buildFakeEvent({name, value: getUtcFromDateTimeValue(value)}));
    }

    if (dateTimeValue == null) return null;
    const dateTimeElement = (
        <HTMLTextInput type={'datetime-local'} value={dateTimeValue} min={minMaxDates.min} max={minMaxDates.max}
            onChange={onChange} style={{width: '13rem'}} {...rest}/>
    )
    if (leftLabel) {
        return (
            <div className="flex-center">
                <Text value={leftLabel} style={{marginRight: '0.5rem'}}
                    isDisabled={rest.isDisabled}/>
                {dateTimeElement}
            </div>
        )
    }
    return dateTimeElement;
}

// YYYY-MM-ddTHH:mm
export function getLocalDateTimeValueFromUtc(utcMillis) {
    try {
        const millis = Number(utcMillis);
        const timeZoneOffsetMillis = new Date(millis).getTimezoneOffset() * 60 * 1000;
        const localIsoString = new Date(millis - timeZoneOffsetMillis).toISOString();
        return localIsoString.substring(0, localIsoString.indexOf('T') + 6);
    } catch (error) {
        return '';
    }
}

// YYYY-MM-ddTHH:mm -> epochMillis
// new Date(dateString) reads above format into local date
export function getUtcFromDateTimeValue(value) {
    if (!value) return '';
    return new Date(value).getTime();
}

export default UtcDateTimeInput;
