import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import PolicyTable from './PolicyTable';
import PolicyTablet from './PolicyTablet';
import {getPolicyIds} from '../../reselect/selectors';
import PolicyForm from "./PolicyForm";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {details} from "../../utilities/constants";
import PolicyModel from "../../models/settings/PolicyModel";
import {MainPageContent, MainPageHeader} from "../app/pages/MainPage";

class PolicyDisplay extends Component {

    componentDidMount() {
        this.props.setDisplayActive();
    }

    componentWillUnmount() {
        this.props.setDisplayInactive();
    }

    render() {

        const {
            t,
            isLoading,
            policyId,
            policyIds,
            isPolicyFormActive,
            ...attr
        } = this.props;

        return (
            <MainPageContent {...attr}
                title={
                    <MainPageHeader text={t('policy:page.title')}
                        description={t('policy:page.description')}/>
                }
                list={
                    <LoadingWrapper isLoading={isLoading}>
                        <PolicyTable />
                    </LoadingWrapper>
                }
                children={
                    (policyIds.includes(policyId) && <PolicyTablet />)
                    ||
                    (isPolicyFormActive && <PolicyForm />)
                }
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.componentStates.policyDisplay,
        policyIds: getPolicyIds(state),
        isLoading: !state.hasLoaded[details.POLICIES]
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setDisplayActive: () => dispatch(PolicyModel.componentActionCreators.setDisplayActive()),
        setDisplayInactive: () => dispatch(PolicyModel.componentActionCreators.setDisplayInactive())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['policy'])(PolicyDisplay));