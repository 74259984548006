import OperationModel from "../generics/OperationModel";
import {getLocaleDateTimeFromUTC} from "../../utilities/helperFunctions";

class JobOperationModel extends OperationModel {

    static nom = 'JobOperationModel';
    static actions = JobOperationModel.buildActions('JOB');
    static actionCreators = JobOperationModel.buildActionCreators(JobOperationModel.actions);
    static reducer = JobOperationModel.buildReducer(JobOperationModel.actions);

    constructor(model = {}) {
        super(model);

        this.executionState = model.executionState;
        this.percentageComplete = model.percentageComplete;
        this.progressText = model.progressText;
        this.processingSpeedText = model.processingSpeedText;
        this.eta = getLocaleDateTimeFromUTC(model.eta);
        this.error = model.error;
        this.warnings = model.warnings;
    }
}

export default JobOperationModel;