import React, {useCallback, useEffect, useState} from "react";
import TableField from "./TableField";
import {objEquals} from "../../../utilities/helperFunctions";

const translateValueToTableFormat = value => Array.isArray(value) && value.map(val => ([val]));
function ListField(props) {
    const {
        value,
        updateState,
        ...rest
    } = props;

    // Translation into OBJECT_LIST format
    // Track as internal state to prevent re-computing on every render
    const [tableValue, setTableValue] = useState(translateValueToTableFormat(value));
    useEffect(() => {
        setTableValue(translateValueToTableFormat(value));
    }, [value]);

    const fieldName = props.configuration.name;
    const updateField = useCallback(update => {
        updateState(prevState => {
            const prevField = prevState[fieldName];
            // Need to provide prevState with OBJECT_LIST format (prevState tracks value as INPUT_LIST)
            const _prevState = {
                [fieldName]: translateValueToTableFormat(prevField)
            };
            // Get update and translate it back to INPUT_LIST format
            const updates = typeof update === 'function' ? update(_prevState) : update;
            const newField = updates[fieldName].filter(val => val).map(val => val[0]);
            if (objEquals(prevField, newField)) {
                return prevState;
            }

            // Set tableState immediately to prevent render issues
            setTableValue(updates[fieldName]);

            return {
                [fieldName]: newField
            }
        });
    }, [fieldName, updateState]);

    return (
        <TableField {...rest} value={tableValue} updateState={updateField}/>
    )
}

export default ListField;
