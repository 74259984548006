import React from "react";
import {useTranslation} from "react-i18next";
import Dropdown from "../../common/Dropdown/Dropdown";
import ticTacToeIcon from "../../../resources/images/icons/ticTacToeMenu.svg";
import {buildClassName} from "../../../utilities/helperFunctions";
import {thirdPartyServiceIconModel} from "../../../models/generics/IconModel";
import {useDispatch} from "react-redux";
import PopupModel from "../../../models/scheduler/PopupModel";
import SchedulerModel from "../../../models/scheduler/SchedulerModel";

function AppsMenu(props) {
    const {t} = useTranslation(['aria']);

    const {
        platformApps=[],
        defaultRoute
    } = props;

    return (
        <Dropdown id={'appsMenuDropdown'} aria-label={t('aria:hiddenAssistText.nuixApps')}
            isTransparent isIconDropdown enableVirtualRendering={false}
            icon={
                <span className="navbar-icon">
                    <img src={ticTacToeIcon} alt={t('aria:hiddenAssistText.appsMenuIcon')}/>
                </span>
            }
            items={
                Array.isArray(platformApps) && platformApps.map((app, index) => {
                    const updatedApp = {...app};
                    if (updatedApp.type === SchedulerModel.PlatformApplication.NUIX_AUTOMATE) {
                        updatedApp.url = `#${defaultRoute}`;
                    }
                    return (
                        <AppMenuItem key={index} app={updatedApp}/>
                    )
                })
            }
        />
    )
}

function AppMenuItem(props) {
    const {t} = useTranslation(['aria']);
    const dispatch = useDispatch();

    const {
        app
    } = props;

    const icon = thirdPartyServiceIconModel.useIcon(app.type);
    const isActive = app.type === SchedulerModel.PlatformApplication.NUIX_AUTOMATE;

    function onClick(event) {
        let errorPopupKey;
        if (!app.configured) {
            errorPopupKey = 'neoPlatformApplicationNotConfigured' + app.type;
        } else if (!app.connected) {
            errorPopupKey = 'neoPlatformApplicationNotConnected' + app.type;
        }

        if (errorPopupKey != null) {
            dispatch(PopupModel.actionCreators.showError({
                info: {
                    key: errorPopupKey,
                    values: {
                        serviceName: app.serviceName
                    }
                }
            }));
            event.preventDefault();
        }
    }

    function onKeyPress(e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            e.target.blur();
            e.target.click();
        }
    }

    const menuItemClassName = buildClassName(
        'navbar-menu-item',
        isActive && 'is-active'
    );

    return (
        <a className={menuItemClassName} title={app.url || app.name} tabIndex={0}
            onClick={onClick} onKeyDown={onKeyPress} href={app.url} rel="noopener noreferrer"
        >
            <span className="navbar-icon si-24">
                <img src={icon} alt={t(`aria:hiddenAssistText.${app.type}Icon`)}/>
            </span>
            <p className="navbar-label">
                {app.name}
            </p>
        </a>
    )
}

export default AppsMenu;
