import React from 'react';
import {connect} from 'react-redux';
import {useTranslation} from "react-i18next";
import {AddButtonHeader} from "../../common/Button/Button";
import {getNuixDongleLicenceSourceIds} from '../../../reselect/selectors';
import NuixDongleLicenceSourceRow from './DongleLicenceSourceRow';
import {applicationFeatures} from "../../../utilities/constants";
import {includesSome, isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import LicenceSourceModel from "../../../models/settings/LicenceSourceModel";

const DongleLicenceSourceTable = props => {
    const {nuixDongleLicenceSourceIds, showDongleLicenceSourceForm, userFeatures} = props;
    const {t} = useTranslation(['aria', 'nuixLicenceSource', 'common']);

    const canAddLicenceSource = includesSome(userFeatures, [applicationFeatures.ADD_RESOURCE, applicationFeatures.ADD_NUIX_LICENCE_SOURCE]);

    const isNotEmpty = isNotEmptyNorFalsy(nuixDongleLicenceSourceIds);
    if (!canAddLicenceSource && !isNotEmpty) {
        return null;
    }

    return (
        <div className="display-item">
            <AddButtonHeader aria-label={t('aria:hiddenAssistText.addDongleLicenceSource')}
                text={t('nuixLicenceSource:label.dongleName')}
                canModify={canAddLicenceSource} onClick={showDongleLicenceSourceForm}
            />

            {isNotEmpty &&
                <div className="display-table">
                    <div className="table-header-group">
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('common:label.name')}
                            </label>
                        </div>
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('nuixLicenceSource:label.filter')}
                            </label>
                        </div>
                    </div>

                    <div className="table-row-group">
                        {nuixDongleLicenceSourceIds.map(id =>
                            <NuixDongleLicenceSourceRow key={id} nuixLicenceSourceId={id}/>
                        )}
                    </div>
                </div>
            }
        </div>
    );
}

const mapStateToProps = state => {
    return {
        nuixDongleLicenceSourceIds: getNuixDongleLicenceSourceIds(state),
        userFeatures: state.currentUser.features
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showDongleLicenceSourceForm: () => dispatch(LicenceSourceModel.componentActionCreators.updateDisplay({isDongleLicenceSourceFormActive: true}))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DongleLicenceSourceTable);
