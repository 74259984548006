import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import PurviewCache from "../../../models/guidedjob/PurviewCache";
import {useDispatch, useSelector} from "react-redux";
import {buildClassName, buildFakeEvent, getNonEmptyValueOrDefault} from "../../../utilities/helperFunctions";
import Checkbox from "../../common/Checkbox/Checkbox";
import {objectIdentifierType, purviewDataSourceInputType} from "../../../utilities/constants";
import {purviewUseCaseParameterNames} from "./PurviewUseCasePane";
import {purviewJobParameterNames} from "../PurviewGuidedJobForm";
import WorkflowBuilderOperation from "../../../models/workflowbuilder/WorkflowBuilderOperation";
import PurviewOperationPane from "./PurviewOperationPane";
import {ImportExportContainerOptions, SwitchItemContainer} from "../../common/ListContainer/ListContainer";
import {asyncSeparateItems} from "../../common/ListContainer/helpers";
import {icon} from "../../../utilities/iconResolver";
import {purviewApplyHoldToNonCustodialDataSourcesParameterNames} from "./PurviewAddNonCustodialDataSourcesPane";
import {buildOperationFieldParameterNames} from "../../guidedJob/operationFieldParameterNames";
import PopupModel from "../../../models/scheduler/PopupModel";
import {useAsyncEffect} from "../../../utilities/hooks";

const releaseNonCustodialDataSourcesFieldNames = ['disabled', 'inputType', 'nonCustodialDataSourceIdsJson'];
export const purviewReleaseNonCustodialDataSourcesParameterNames = buildOperationFieldParameterNames(WorkflowBuilderOperation.Alias.PURVIEW_RELEASE_NON_CUSTODIAL_DATA_SOURCES, releaseNonCustodialDataSourcesFieldNames);

const removeHoldFromNonCustodialDataSourcesFieldNames = ['disabled', 'inputType', 'nonCustodialDataSourceIdsJson', 'waitForCompletion'];
export const purviewRemoveHoldFromNonCustodialDataSourcesParameterNames = buildOperationFieldParameterNames(WorkflowBuilderOperation.Alias.PURVIEW_REMOVE_HOLD_FROM_NON_CUSTODIAL_DATA_SOURCES, removeHoldFromNonCustodialDataSourcesFieldNames);

export function getNextEnabled(getParameter) {
    const releaseNonCustodialDataSourcesDisabled = getParameter(purviewReleaseNonCustodialDataSourcesParameterNames.disabled, false, {parse: true});
    const removeHoldFromNonCustodialDataSourcesDisabled = getParameter(purviewRemoveHoldFromNonCustodialDataSourcesParameterNames.disabled, false, {parse: true});
    const selectedNonCustodialDataSources = getParameter(purviewJobParameterNames.selectedNonCustodialDataSourceIds, '[]', {parse: true});
    return {
        selectNonCustodialDataSources: (releaseNonCustodialDataSourcesDisabled.value && removeHoldFromNonCustodialDataSourcesDisabled.value)
            || selectedNonCustodialDataSources.value.length > 0
    };
}

export function getPurviewSelectNonCustodialDataSourcesGuide(operation, props) {
    const {
        workflowActions,
        updateParameter
    } = props;

    if (workflowActions.applyHoldToNonCustodialDataSources) {
        updateParameter(purviewApplyHoldToNonCustodialDataSourcesParameterNames.inputType, purviewDataSourceInputType.IDS_JSON);
        updateParameter(purviewApplyHoldToNonCustodialDataSourcesParameterNames.nonCustodialDataSourceIdsJson, purviewJobParameterNames.selectedNonCustodialDataSourceIds);
    }
    if (workflowActions.removeHoldFromNonCustodialDataSources) {
        updateParameter(purviewRemoveHoldFromNonCustodialDataSourcesParameterNames.inputType, purviewDataSourceInputType.IDS_JSON);
        updateParameter(purviewRemoveHoldFromNonCustodialDataSourcesParameterNames.nonCustodialDataSourceIdsJson, purviewJobParameterNames.selectedNonCustodialDataSourceIds);
    }
    if (workflowActions.releaseNonCustodialDataSources) {
        updateParameter(purviewReleaseNonCustodialDataSourcesParameterNames.inputType, purviewDataSourceInputType.IDS_JSON);
        updateParameter(purviewReleaseNonCustodialDataSourcesParameterNames.nonCustodialDataSourceIdsJson, purviewJobParameterNames.selectedNonCustodialDataSourceIds);
    }

    return {
        getPanes: function(t, props) {
            const selectNonCustodialDataSourcesTitle = getNonEmptyValueOrDefault(operation.notes, t('guidedJob:panes.selectNonCustodialDataSources'));
            const nextEnabled = getNextEnabled(props.getParameter);

            return [{
                title: selectNonCustodialDataSourcesTitle,
                isNextEnabled: nextEnabled.selectNonCustodialDataSources,
                component: <PurviewOperationPane {...props}
                    workflowActions={workflowActions} OperationPane={PurviewSelectNonCustodialDataSourcesPane}/>
            }];
        }
    }
}

function PurviewSelectNonCustodialDataSourcesPane(props) {
    const {t} = useTranslation(['guidedJob', 'common']);
    const dispatch = useDispatch();

    const {
        proxyPurviewRequest,
        getParameter,
        updateParameter
    } = props;

    const caseIdentifierType = getParameter(purviewUseCaseParameterNames.caseIdentifierType);
    const useExistingCase = caseIdentifierType.value === objectIdentifierType.ID;
    const isDisabled = props.isDisabled || !useExistingCase;

    const applyHoldToNonCustodialDataSourcesDisabled = getParameter(purviewApplyHoldToNonCustodialDataSourcesParameterNames.disabled, false, {parse: true});
    const removeHoldFromNonCustodialDataSourcesDisabled = getParameter(purviewRemoveHoldFromNonCustodialDataSourcesParameterNames.disabled, false, {parse: true});
    const releaseNonCustodialDataSourcesDisabled = getParameter(purviewReleaseNonCustodialDataSourcesParameterNames.disabled, false, {parse: true});
    const isSelectNonCustodialDataSourcesDisabled = applyHoldToNonCustodialDataSourcesDisabled.value && removeHoldFromNonCustodialDataSourcesDisabled.value && releaseNonCustodialDataSourcesDisabled.value;
    const isOperationDisabled = isDisabled || isSelectNonCustodialDataSourcesDisabled;

    const caseIdentifier = getParameter(purviewUseCaseParameterNames.caseIdentifier);
    const selectedNonCustodialDataSourceIds = getParameter(purviewJobParameterNames.selectedNonCustodialDataSourceIds, '[]', {parse: true});
    const [nonCustodialItems, setNonCustodialItems] = useState({left: [], right: []});

    const nonCustodialDataSourcesKey = PurviewCache.getResourceKey(PurviewCache.Resource.NON_CUSTODIAL_DATA_SOURCES, {caseId: caseIdentifier.value});
    const nonCustodialDataSources = useSelector(state => state.purviewCache[nonCustodialDataSourcesKey]) || {};
    const isLoadingDataSources = nonCustodialDataSources.cache == null;

    useEffect(() => {
        if (!isOperationDisabled) {
            proxyPurviewRequest(PurviewCache.Resource.NON_CUSTODIAL_DATA_SOURCES, {caseId: caseIdentifier.value});
        }
    }, [isOperationDisabled]);

    useEffect(() => {
        if (!useExistingCase) {
            toggleHandler(buildFakeEvent({name: 'selectNonCustodialDataSourcesDisabled', value: true}))
        }
    }, [useExistingCase]);

    useAsyncEffect(async () => {
        if (Array.isArray(nonCustodialDataSources.cache)) {
            const nonCustodialDataSourceItems = nonCustodialDataSources.cache.map(nonCustodial => ({
                name:  PurviewCache.getDataSourceValue(nonCustodial.dataSource),
                value: nonCustodial.id
            }));
            const items = await asyncSeparateItems(selectedNonCustodialDataSourceIds.value, nonCustodialDataSourceItems);
            setNonCustodialItems(items);
        }
    }, [nonCustodialDataSources.cache]);

    useEffect(() => {
        const nonCustodialDataSourceIds = nonCustodialItems.right.map(item => item.value);
        updateParameter(purviewJobParameterNames.selectedNonCustodialDataSourceIds, JSON.stringify(nonCustodialDataSourceIds));
    }, [nonCustodialItems]);

    const importCsv = useCallback(resultArray => {
        const newItems = {left: [], right: [...nonCustodialItems.right]};
        const firstColValues = new Set(resultArray.map(row => row[0]));
        const secondColValues = new Set(resultArray.map(row => row[1]));
        const initialCount = firstColValues.size + secondColValues.size;

        for (const item of nonCustodialItems.left) {
            if (firstColValues.delete(item.name) || secondColValues.delete(item.name)) {
                newItems.right.push(item);
            } else {
                newItems.left.push(item);
            }
        }
        const finalCount = firstColValues.size + secondColValues.size;
        const values = {
            importedCount: initialCount - finalCount,
            invalidCount: finalCount
        };
        dispatch(showImportNonCustodialPopup(values));
        setNonCustodialItems(newItems);
    }, [nonCustodialItems]);

    function toggleHandler(event) {
        const {name, checked} = event.target;
        if (name === 'selectNonCustodialDataSourcesDisabled') {
            updateParameter(purviewApplyHoldToNonCustodialDataSourcesParameterNames.disabled, checked);
            updateParameter(purviewRemoveHoldFromNonCustodialDataSourcesParameterNames.disabled, checked);
            updateParameter(purviewReleaseNonCustodialDataSourcesParameterNames.disabled, checked);
        }
    }

    const iconClassName = buildClassName(
        'icon is-large',
        isOperationDisabled && 'is-disabled'
    );
    const containerStyle = {flex: 1};
    if (!isLoadingDataSources && nonCustodialDataSources.stale) {
        containerStyle.opacity = 0.6;
    }


    return (
        <div className="display-input flex-expand" style={{position: 'relative'}}>
            <Checkbox label={t('guidedJob:label.skipSelectingNonCustodialDataSources')} name={'selectNonCustodialDataSourcesDisabled'}
                value={isSelectNonCustodialDataSourcesDisabled} onClick={toggleHandler}
                containerStyle={{marginBottom: '0.75rem'}} isDisabled={isDisabled}/>

            <span className={iconClassName} style={{position: 'absolute', left: 0, right: 0, margin: '6rem auto'}}>
                <img src={icon('sourceNonCustodial')} alt={t(`aria:hiddenAssistText.sourceNonCustodialIcon`)}/>
            </span>

            <SwitchItemContainer id="nonCustodialDataSourcesSwitchContainer" ariaLabelKey={'NonCustodialDataSource'} style={containerStyle}
                optionComponentProps={{importCsv}} OptionsComponent={ImportExportContainerOptions} items={nonCustodialItems} setItems={setNonCustodialItems}
                enableVirtualRendering isSearchable isLoading={isLoadingDataSources} isDisabled={isOperationDisabled}/>
        </div>
    )
}

function showImportNonCustodialPopup(values) {
    let showPopupKey = 'showSuccess';
    let popupKey = 'importNonCustodialsSelected';
    if (values.importedCount !== 1) {
        popupKey += '_plural';
    }
    if (values.invalidCount > 0) {
        showPopupKey = 'showWarning';
        popupKey += 'Skipped';
        if (values.invalidCount !== 1) {
            popupKey += '_plural';
        }
    }
    return PopupModel.actionCreators[showPopupKey]({
        info: {key: popupKey, values},
        cancelButton: {titleKey: 'common:option.ok'}
    });
}
