import React from 'react';
import {ListDropdown} from "../../../components/common/Dropdown/Dropdown";

function GenericDropdown(props) {
    const {name, values, selected, onSelected, ...rest} = props;

    return (
        <ListDropdown id={name} name={name}
                      value={selected}
                      items={values} onItemSelect={onSelected}
                      buttonStyle={{maxWidth: '12rem'}}
                      {...rest}
        />
    )
}

export default GenericDropdown;