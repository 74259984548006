import React from "react";
import {useTranslation} from "react-i18next";
import {getNonEmptyValueOrDefault, getValues, stringToBool} from "../../../utilities/helperFunctions";
import Checkbox from "../../common/Checkbox/Checkbox";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import TextArea from "../../common/TextArea/TextArea";
import Text from "../../common/Text/Text";
import PurviewOperationPane from "./PurviewOperationPane";
import WorkflowBuilderOperation from "../../../models/workflowbuilder/WorkflowBuilderOperation";
import PurviewExportReviewSetQueryPane from "./PurviewExportReviewSetQueryPane";
import {objectIdentifierType} from "../../../utilities/constants";
import PurviewSelectReviewSetPane from "./PurviewSelectReviewSetPane";
import {purviewJobParameterNames} from "../PurviewGuidedJobForm";
import {buildOperationFieldParameterNames} from "../../guidedJob/operationFieldParameterNames";
import RadioButton from "../../common/RadioButton/RadioButton";

const fieldNames = ['disabled', 'reviewSetIdentifierType', 'reviewSetIdentifier', 'exportReviewSetQuery', 'reviewSetQueryIdentifierType',
    'reviewSetQueryIdentifier', 'createNewOrUpdateIfExists', 'kqlContentQuery', 'exportName', 'exportDescription',
    'exportStructure', 'originalFiles', 'tags', 'text', 'pdfReplacement', 'waitForCompletion'];
export const purviewExportReviewSetParameterNames = buildOperationFieldParameterNames(WorkflowBuilderOperation.Alias.PURVIEW_EXPORT_REVIEW_SET, fieldNames);

function getNextEnabled(getParameter) {
    const reviewSetIdentifierType = getParameter(purviewExportReviewSetParameterNames.reviewSetIdentifierType);
    const reviewSetIdentifier = getParameter(purviewExportReviewSetParameterNames.reviewSetIdentifier);
    const nextEnabled = {
        selectReviewSet: reviewSetIdentifierType.value === objectIdentifierType.ID && reviewSetIdentifier.value.length > 0
    };

    const exportReviewSetQuery = getParameter(purviewExportReviewSetParameterNames.exportReviewSetQuery);
    const reviewSetQueryIdentifierType = getParameter(purviewExportReviewSetParameterNames.reviewSetQueryIdentifierType);
    const reviewSetQueryIdentifier = getParameter(purviewExportReviewSetParameterNames.reviewSetQueryIdentifier);
    const kqlContentQuery = getParameter(purviewExportReviewSetParameterNames.kqlContentQuery);
    const createNewReviewSetQuery = reviewSetQueryIdentifierType.value === objectIdentifierType.NAME;

    const queryIdentifierValid = objectIdentifierType[reviewSetQueryIdentifierType.value] != null && reviewSetQueryIdentifier.value.length > 0;
    nextEnabled.exportReviewSetQuery = !stringToBool(exportReviewSetQuery.value)
        || (queryIdentifierValid && (!createNewReviewSetQuery || kqlContentQuery.value.length > 0));

    const exportName = getParameter(purviewExportReviewSetParameterNames.exportName);
    const exportStructure = getParameter(purviewExportReviewSetParameterNames.exportStructure);
    nextEnabled.exportReviewSet = exportStructures[exportStructure.value] != null && exportName.value.length > 0;

    return nextEnabled;
}

export function getPurviewExportReviewSetGuide(operation, props) {
    const {
        workflowActions,
        getParameter,
        updateParameter
    } = props;

    const exportName = getParameter(purviewExportReviewSetParameterNames.exportName);
    const exportStructure = getParameter(purviewExportReviewSetParameterNames.exportStructure);
    const exportReviewSetQuery = getParameter(purviewExportReviewSetParameterNames.exportReviewSetQuery);

    updateParameter(purviewExportReviewSetParameterNames.reviewSetIdentifierType, objectIdentifierType.ID);
    if (!workflowActions.selectExistingReviewSetPane) {
        updateParameter(purviewExportReviewSetParameterNames.reviewSetIdentifier, purviewJobParameterNames.reviewSetId);
    }

    if (!exportName.value) {
        updateParameter(purviewExportReviewSetParameterNames.exportName, `Export-{date_time}`)
    }
    if (exportStructures[exportStructure.value] == null) {
        updateParameter(purviewExportReviewSetParameterNames.exportStructure, exportStructures.CONDENSED_DIRECTORY);
    }
    if (exportReviewSetQuery.value !== JSON.stringify(stringToBool(exportReviewSetQuery.value))) {
        updateParameter(purviewExportReviewSetParameterNames.exportReviewSetQuery, JSON.stringify(false));
    }

    const reviewSetQueryIdentifierType = getParameter(purviewExportReviewSetParameterNames.reviewSetQueryIdentifierType);
    const reviewSetQueryIdentifier = getParameter(purviewExportReviewSetParameterNames.reviewSetQueryIdentifier);
    const createNewReviewSetQuery = reviewSetQueryIdentifierType.value !== objectIdentifierType.ID;

    if (createNewReviewSetQuery) {
        updateParameter(purviewExportReviewSetParameterNames.reviewSetQueryIdentifierType, objectIdentifierType.NAME);
        if (!reviewSetQueryIdentifier.value) {
            updateParameter(purviewExportReviewSetParameterNames.reviewSetQueryIdentifier, `Export-{date_time}`);
        }
    }

    const waitForCompletion = getParameter(purviewExportReviewSetParameterNames.waitForCompletion);
    if (waitForCompletion.value !== JSON.stringify(stringToBool(waitForCompletion.value))) {
        updateParameter(purviewExportReviewSetParameterNames.waitForCompletion, JSON.stringify(true));
    }

    return {
        getPanes: function(t, props) {
            const {
                getParameter
            } = props;

            const exportReviewSetQuery = getParameter(purviewExportReviewSetParameterNames.exportReviewSetQuery);
            const exportReviewSetTitleKey = `exportPurviewReviewSet${stringToBool(exportReviewSetQuery.value) ? 'Query' : ''}`;

            const exportReviewSetTitle = getNonEmptyValueOrDefault(operation.notes, t(`guidedJob:panes.${exportReviewSetTitleKey}`));
            const nextEnabled = getNextEnabled(getParameter);

            const panes = [];
            if (workflowActions.selectExistingReviewSetPane) {
                panes.push({
                    title: t(`guidedJob:panes.selectPurviewReviewSet`),
                    isNextEnabled: nextEnabled.selectReviewSet,
                    component: <PurviewOperationPane {...props}
                        workflowActions={workflowActions} OperationPane={PurviewSelectReviewSetPane}/>
                });
            }
            panes.push({
                title: exportReviewSetTitle,
                isNextEnabled: nextEnabled.exportReviewSetQuery,
                component: <PurviewOperationPane {...props}
                    workflowActions={workflowActions} OperationPane={PurviewExportReviewSetQueryPane}/>
            }, {
                title: exportReviewSetTitle,
                isNextEnabled: nextEnabled.exportReviewSet,
                component: <PurviewOperationPane {...props}
                    workflowActions={workflowActions} OperationPane={PurviewExportReviewSetPane}/>
            });
            return panes;
        }
    }
}

function PurviewExportReviewSetPane(props) {
    const {t} = useTranslation(['guidedJob', 'common']);
    const {
        workflowActions,
        getParameter,
        updateParameter,
        isDisabled
    } = props;

    const exportName = getParameter(purviewExportReviewSetParameterNames.exportName);
    const exportDescription = getParameter(purviewExportReviewSetParameterNames.exportDescription);
    const exportStructure = getParameter(purviewExportReviewSetParameterNames.exportStructure);
    const originalFiles = getParameter(purviewExportReviewSetParameterNames.originalFiles);
    const tags = getParameter(purviewExportReviewSetParameterNames.tags);
    const text = getParameter(purviewExportReviewSetParameterNames.text);
    const pdfReplacement = getParameter(purviewExportReviewSetParameterNames.pdfReplacement);

    const isCondensedDirectoryDisabled = isDisabled
        || ![exportStructures.CONDENSED_DIRECTORY].includes(exportStructure.value);

    const isExportNameDisabled = isDisabled || getParameter(purviewExportReviewSetParameterNames.exportNameDisabled, false, {parse: true}).value;
    const isExportStructureDisabled = isDisabled || getParameter(purviewExportReviewSetParameterNames.exportStructureDisabled, false, {parse: true}).value;
    const isTagsDisabled = isDisabled || getParameter(purviewExportReviewSetParameterNames.tagsDisabled, false, {parse: true}).value;
    const isTextDisabled = isCondensedDirectoryDisabled || getParameter(purviewExportReviewSetParameterNames.textDisabled, false, {parse: true}).value;
    const isOriginalFilesDisabled = isCondensedDirectoryDisabled || getParameter(purviewExportReviewSetParameterNames.originalFilesDisabled, false, {parse: true}).value;
    const isPdfReplacementDisabled = isCondensedDirectoryDisabled || getParameter(purviewExportReviewSetParameterNames.pdfReplacementDisabled, false, {parse: true}).value;

    const {..._exportStructures} = exportStructures;

    function inputHandler(event) {
        const {name, value} = event.target;
        updateParameter(name, value);
    }

    function toggleHandler(event) {
        const {name, checked} = event.target;
        updateParameter(name, JSON.stringify(checked));
    }

    return (
        <div>
            <div className="display-input">
                <HTMLTextInput label={t('guidedJob:label.exportName')} name={purviewExportReviewSetParameterNames.exportName}
                    value={exportName.value} onChange={inputHandler} isRequired isDisabled={isExportNameDisabled}/>
            </div>

            <div className="display-input">
                <TextArea label={t('guidedJob:label.description')} name={purviewExportReviewSetParameterNames.exportDescription}
                    value={exportDescription.value} onChange={inputHandler} isDisabled={isDisabled}/>
            </div>

            <div className="display-input flex-column">
                <Text value={t('guidedJob:label.exportStructure')}/>
                {getValues(_exportStructures).map(structure => (
                    <RadioButton key={structure} label={t(`guidedJob:purviewExportStructure.${structure}`)}
                        name={purviewExportReviewSetParameterNames.exportStructure} style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}
                        selected={exportStructure.value} value={structure} onClick={inputHandler} isDisabled={isExportStructureDisabled}/>
                ))}
            </div>

            <div className="display-input flex-column">
                <Text value={t('guidedJob:label.include')}/>

                <Checkbox label={t('guidedJob:label.tags')} name={purviewExportReviewSetParameterNames.tags}
                    containerStyle={{marginBottom: '0.5rem'}} value={tags.value} onClick={toggleHandler}
                    isDisabled={isTagsDisabled}/>

                <Checkbox label={t('guidedJob:label.text')} name={purviewExportReviewSetParameterNames.text}
                    containerStyle={{marginBottom: '0.5rem'}} value={text.value} onClick={toggleHandler}
                    isDisabled={isTextDisabled}/>

                <Checkbox label={t('guidedJob:label.originalFiles')} name={purviewExportReviewSetParameterNames.originalFiles}
                    containerStyle={{marginBottom: '0.5rem'}} value={originalFiles.value} onClick={toggleHandler}
                    isDisabled={isOriginalFilesDisabled}/>

                <Checkbox label={t('guidedJob:label.pdfReplacement')} name={purviewExportReviewSetParameterNames.pdfReplacement}
                    containerStyle={{marginBottom: '0.5rem'}} value={pdfReplacement.value} onClick={toggleHandler}
                    isDisabled={isPdfReplacementDisabled}/>
            </div>
        </div>
    )
}

export const exportStructures = {
    NONE: 'NONE',
    LOOSE_FILES_AND_PST: 'LOOSE_FILES_AND_PST',
    CONDENSED_DIRECTORY: 'CONDENSED_DIRECTORY'
};
