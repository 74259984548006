import React from "react";
import {SwitchItemContainer} from "../../common/ListContainer/ListContainer";
import {createUpdateHandler} from "../../../utilities/componentFunctions";
import LegalHoldModel from "../../../models/legalhold/LegalHoldModel";
import {useDispatch, useSelector} from "react-redux";
import {legalHoldStateKeys, permissionKeys} from "../../../i18next/keys";
import {UserEmailSelectableItem} from "../../common/SelectableItem/SelectableItem";
import {icon} from "../../../utilities/iconResolver";
import {useTranslation} from "react-i18next";


function HoldAdministratorsPane() {
    const {t} = useTranslation(['aria']);
    const dispatch = useDispatch();

    const {
        state,
        administrators,

        userPermissions,
        isDisabled: isFormDisabled
    } = useSelector(state => state.componentStates.legalHoldForm);


    const administratorsHandler = createUpdateHandler({
        updateState: updates => dispatch(LegalHoldModel.componentActionCreators.updateForm(updates)),
        name: 'administrators',
        values: administrators
    });

    // Can only modify when DRAFT or ACTIVE
    const isDraft = state === legalHoldStateKeys.DRAFT;
    const isActive = state === legalHoldStateKeys.ACTIVE;
    const cantModify = userPermissions != null && !userPermissions.includes(permissionKeys.MODIFY);

    const isDisabled = isFormDisabled || (!isDraft && !isActive) || cantModify;

    const disabled = isDisabled ? ' is-disabled' : '';

    return (
        <div className="pane-margin fill-height">

            <div className="display-input flex-expand">
                <span className={'icon is-large' + disabled} style={{position: 'absolute', left: 0, right: 0, margin: '6rem auto'}}>
                    <img src={icon('roleAdmin')} alt={t('aria:hiddenAssistText.adminRoleIcon')}/>
                </span>

                <SwitchItemContainer id="administratorsSwitchContainer" style={{flex: 1}} ItemComponent={UserEmailSelectableItem}
                    enableVirtualRendering items={administrators} setItems={administratorsHandler} isSearchable
                    ariaLabelKey={'Administrator'} searchableFields={['upn', 'email']} canShowSelected isDisabled={isDisabled}/>
            </div>
        </div>
    )
}

export default HoldAdministratorsPane;
