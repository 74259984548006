import DetailsModel, {DetailsApi, DetailsSaga} from "../generics/DetailsModel";
import ComponentStateModel from "../generics/ComponentStateModel";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import {all, put, select} from "redux-saga/effects";
import {details, routes, userSettings} from "../../utilities/constants";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import {contextCall} from "../../saga/sagaFunctions";
import PopupModel from "../scheduler/PopupModel";
import FileLibraryModel from "../filelibrary/FileLibraryModel";

class LibraryModel extends DetailsModel {

    static nom = 'LibraryModel';
    static actions = LibraryModel.buildActions('LIBRARY');
    static actionCreators = LibraryModel.buildActionCreators(LibraryModel.actions);
    static reducer = LibraryModel.buildReducer(LibraryModel.actions);

    static componentActionCreators = {
        ...LibraryModel.buildComponentUpdateActionCreators(),
        ...LibraryModel.buildComponentSetActiveActionCreators()
    };

    constructor(model = {}) {
        super(model);
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'libraryPage',
                type: 'Page',
                state: {
                    libraryId: null,
                    isLibraryFormActive: false,
                    searchText: ''
                }
            },
            {
                key: 'libraryDisplay',
                type: 'Display',
                state: {
                    workflowTemplateId: null,
                    isWorkflowTemplateFormActive: false,
                    isWorkflowWizardActive: false
                }
            },
            {
                key: 'libraryForm',
                type: 'Form',
                state: {
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'LIBRARY_PAGE',
                type: 'Page'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }
}

export class LibraryApi extends DetailsApi {

    static location = '/library';

    static getDetails(includeDisabled, requiredParameterTypes, forbiddenParameterTypes) {

        let url = `/scheduler/library?includeDisabled=${!!includeDisabled}`;
        if (requiredParameterTypes != null) {
            for (let i = 0; i < requiredParameterTypes.length; i++) {
                url += `&requiredParameterTypes=${requiredParameterTypes[i]}`;
            }
        }
        if (forbiddenParameterTypes != null) {
            for (let i = 0; i < forbiddenParameterTypes.length; i++) {
                url += `&forbiddenParameterTypes=${forbiddenParameterTypes[i]}`;
            }
        }
        return axiosInstance.get(url);
    }

    static postLibraries(contents) {
        return axiosInstance.post('/scheduler/library/uploadZip', {contents});
    }
}

export class LibrarySaga extends DetailsSaga {

    static ModelType = LibraryModel;
    static ModelApi = LibraryApi;

    static activationComponent = 'LIBRARY_PAGE';
    static variableNames = {
        detailsMap: 'libraryDetailsMap',
        instanceId: 'libraryId',
        isFormActive: 'isLibraryFormActive',
        updateDisplay: 'updatePage',
        updatePane: 'updatePage',
        route: routes.LIBRARY
    };

    static translations = {
        itemTitle: '$t(library:label.name)',
        itemLower: '$t(library:label.name_lower)'
    };

    static buildActivationEffects(dispatch) {
        return [
            ...super.buildActivationEffects(dispatch),
            // ACTIVATION EFFECTS
            put(LibraryModel.actionCreators.startPollingDetails()),
            put(FileLibraryModel.actionCreators.startPollingDetails())
        ]
    }

    static buildDeactivationEffects() {
        return [
            ...super.buildDeactivationEffects(),
            // DEACTIVATION EFFECTS
            put(LibraryModel.actionCreators.stopPollingDetails()),
            put(FileLibraryModel.actionCreators.stopPollingDetails())
        ]
    }

    static* setReduxState(args) {
        yield contextCall(this, 'initializeState');
        const {detailsMap, updateDisplay, instanceId, route} = this.variableNames;

        if (args.id != null) {
            const [libraryId, workflowTemplateId] = args.id.split('/workflowTemplate?id=');

            const details = yield select(state => state[detailsMap].get(libraryId));
            if (details == null) {
                yield put(PopupModel.actionCreators.showError({
                    info: {
                        key: 'ModelCannotBeFound',
                        valueKeys: {
                            itemTitle: this.translations.itemTitle
                        }
                    }
                }));
            }

            yield all([
                put(this.ModelType.componentActionCreators[updateDisplay]({[instanceId]: libraryId})),
                put(LibraryModel.componentActionCreators.updateDisplay({workflowTemplateId}))
            ]);
        }

        if (args.cbEffect != null) {
            yield args.cbEffect;
        }

        window.location.href = `#${route}`;
    }

    static* getEditValues(id) {
        const {name: libraryName, ...rest} = yield select(state => state.libraryDetailsMap.get(id));

        return {
            libraryName,
            ...rest
        }
    }

    static getSaveValues(values) {
        const {libraryName: name, description, enabled} = values;

        return {
            name,
            description,
            enabled
        }
    }

    static* queryDetails() {
        const showDisabled = yield select(state => state.userSettingsMap.get(userSettings.SHOW_DISABLED_ITEMS));
        const response = yield contextCall(LibraryApi, 'getDetails', showDisabled.libraries);
        const key = details.LIBRARIES;

        if (AxiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(LibraryModel.actionCreators.setDetailsMap(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }

    static* delete(action) {
        const {id} = action.payload;

        yield contextCall(LibraryApi, 'delete', id);
        yield put(LibraryModel.actionCreators.deleteDetails(id));
    }
}

export default LibraryModel;