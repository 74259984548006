import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {getSafeClose, onInputChange, warnOnClose} from '../../utilities/componentFunctions';
import {
    ExecutionProfileDropdown,
    NuixLicenceSourceDropdown,
    PriorityDropdown,
    ServerDropdown,
    SupportedExecutionModeDropdown
} from '../common/Dropdown/Dropdown';
import {onDropdownItemClick} from "../common/Dropdown/helpers";
import Form, {FormFooter, FormHeader} from "../common/Form/Form";
import {executionModeKeys, priorityKeys} from "../../i18next/keys";
import {compareNumStrings} from "../../utilities/helperFunctions";
import {shouldEnableEngine} from "../../utilities/shouldEnableFunctions";
import {updateMode} from "../../utilities/constants";
import PopupModel from "../../models/scheduler/PopupModel";
import EngineModel from "../../models/settings/EngineModel";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";

class EngineForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            engineName: '',
            serverId: '',
            nuixLicenceSourceId: '',
            executionProfileId: '',
            priority: priorityKeys.MEDIUM,
            supportedExecutionMode: executionModeKeys.AUTOMATE_NUIX,
            targetNuixWorkers: 2,
            minNuixWorkers: 1,
            isAddEnabled: false
        };

        this.getSafeClose = getSafeClose('state', ['targetNuixWorkers', 'minNuixWorkers', 'priority', 'supportedExecutionMode']).bind(this);
        this.warnOnClose = warnOnClose(this.props.t('engine:label.name')).bind(this);

        this.onInputChange = onInputChange({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            isEnabled: 'isAddEnabled',
            shouldEnable: shouldEnableEngine
        }).bind(this);
        this.onDropdownItemClick = onDropdownItemClick({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            isEnabled: 'isAddEnabled',
            shouldEnable: shouldEnableEngine
        }).bind(this);
    }

    onAddClick = () => {
        this.props.addEngine(this.state);
    };

    render() {

        const {
            t,
            isDisabled
        } = this.props;
        const { 
            engineName,
            serverId,
            nuixLicenceSourceId,
            executionProfileId,
            priority,
            supportedExecutionMode,
            targetNuixWorkers,
            minNuixWorkers,
            isAddEnabled
        } = this.state;
        const close = this.getSafeClose();

        const nameInputProps = {
            label: t('common:label.name'),
            name: 'engineName',
            value: engineName,
            onChange: this.onInputChange,
            isRequired: true,
            isDisabled
        };
        const serverDropdownProps = {
            selectedServerId: serverId,
            onServerSelect: this.onDropdownItemClick,
            isRequired: true,
            isDisabled
        };
        const nuixLicenceSourceDropdownProps = {
            selectedNuixLicenceSourceId: nuixLicenceSourceId,
            onNuixLicenceSourceSelect: this.onDropdownItemClick,
            isRequired: true,
            isDisabled
        };
        const executionProfileDropdownProps = {
            selectedExecutionProfileId: executionProfileId,
            onExecutionProfileSelect: this.onDropdownItemClick,
            isRequired: false,
            isDisabled
        }
        const priorityDropdownProps = {
            selectedPriority: priority,
            onPrioritySelect: this.onDropdownItemClick
        };

        const supportedExecutionModeDropdownProps = {
            selectedSupportedExecutionMode: supportedExecutionMode,
            onSupportedExecutionModeSelect: this.onDropdownItemClick
        };

        const targetNuixWorkersInputProps = {
            type: 'number',
            style: {width: '5rem'},
            name: 'targetNuixWorkers',
            value: targetNuixWorkers,
            onChange: this.onInputChange,
            isInvalid: targetNuixWorkers <= 0,
            isRequired: true,
            isDisabled
        };
        const minNuixWorkersInputProps = {
            type: 'number',
            style: {width: '5rem'},
            name: 'minNuixWorkers',
            value: minNuixWorkers,
            onChange: this.onInputChange,
            isRequired: true,
            isInvalid: compareNumStrings(targetNuixWorkers, minNuixWorkers) < 0 || minNuixWorkers <= 0,
            isDisabled
        };

        const footerProps = {
            addText: t('common:button.addEngine'),
            onAddClick: this.onAddClick,
            onCancel: close,
            isAddEnabled,
            isDisabled
        };

        const disabled = isDisabled ? ' is-disabled' : '';

        return (
            <Form onClose={close} isDisabled={isDisabled} closeButtonAriaLabel={t('engine:option.closeForm')}
                header={
                    <FormHeader text={t('engine:label.name')} iconName={'engine'} isDisabled={isDisabled} />
                }
                body={
                    <>
                        <div className="display-input">
                            <HTMLTextInput {...nameInputProps}/>
                        </div>
                        <div className="display-input forms-table">
                            <div className="table-row-group">
                                <div className="table-row">
                                    <div className="table-cell no-stretch">
                                        <label className={'label' + disabled}>
                                            {t('engine:label.server')}:
                                        </label>
                                    </div>
                                    <div className="table-cell">
                                        <ServerDropdown {...serverDropdownProps} />
                                    </div>
                                </div>
                                <div className="table-row">
                                    <div className="table-cell no-stretch">
                                        <label className={'label' + disabled}>
                                            {t('engine:label.executionMode')}:
                                        </label>
                                    </div>
                                    <div className="table-cell">
                                        <SupportedExecutionModeDropdown {...supportedExecutionModeDropdownProps} />
                                    </div>
                                </div>
                                {supportedExecutionMode === executionModeKeys.AUTOMATE_NUIX &&
                                    <div className="table-row">
                                        <div className="table-cell no-stretch">
                                            <label className={'label' + disabled}>
                                                {t('engine:label.nuixLicenceSource')}:
                                            </label>
                                        </div>
                                        <div className="table-cell">
                                            <NuixLicenceSourceDropdown {...nuixLicenceSourceDropdownProps} />
                                        </div>
                                    </div>
                                }
                                <div className="table-row">
                                    <div className="table-cell no-stretch">
                                        <label className={'label' + disabled}>
                                            {t('engine:label.initializationExecutionProfile')}:
                                        </label>
                                    </div>
                                    <div className="table-cell">
                                        <ExecutionProfileDropdown {...executionProfileDropdownProps} />
                                    </div>
                                </div>
                                <div className="table-row">
                                    <div className="table-cell no-stretch">
                                        <label className={'label' + disabled}>
                                            {t('engine:label.priority')}:
                                        </label>
                                    </div>
                                    <div className="table-cell">
                                        <PriorityDropdown {...priorityDropdownProps} />
                                    </div>
                                </div>
                                {supportedExecutionMode === executionModeKeys.AUTOMATE_NUIX &&
                                    <div className="table-row">
                                        <div className="table-cell no-stretch">
                                            <label htmlFor={'engineTargetWorkers'} className={'label' + disabled}>
                                                {t('engine:label.targetWorkers')}:
                                            </label>
                                        </div>
                                        <div className="table-cell">
                                            <HTMLTextInput id={'engineTargetWorkers'} {...targetNuixWorkersInputProps}/>
                                        </div>
                                    </div>
                                }

                                {supportedExecutionMode === executionModeKeys.AUTOMATE_NUIX &&
                                    <div className="table-row">
                                        <div className="table-cell no-stretch">
                                            <label htmlFor={'engineMinWorkers'} className={'label' + disabled}>
                                                {t('engine:label.minWorkers')}:
                                            </label>
                                        </div>
                                        <div className="table-cell">
                                            <HTMLTextInput id={'engineMinWorkers'} {...minNuixWorkersInputProps}/>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                }
                footer={
                    <FormFooter {...footerProps} />
                }
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.componentStates.engineForm
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addEngine: engine => dispatch(EngineModel.actionCreators.submitForm(engine)),
        close: () => dispatch(EngineModel.componentActionCreators.updateDisplay({isEngineFormActive: false})),

        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['engine', 'common'])(EngineForm));