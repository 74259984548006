import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {selectJobFromDisplay} from "./selectors";
import {details, jobStates, QUERY_INTERVAL, userSettings} from "../../utilities/constants";
import {executionStateKeys, logLevelKeys, logLevelStatusIconKeys, permissionKeys} from "../../i18next/keys";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {
    buildClassName,
    getScrollElement,
    getValues,
    includesSome,
    isNotEmptyNorFalsy
} from "../../utilities/helperFunctions";
import UserCommentModel from "../../models/generics/UserCommentModel";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import ExpandableContent, {
    ExpandableChangeLogTable,
    ExpandableErrorAlertTable,
    ExpandableInfoAlertTable,
    ExpandableLinkAlertTable,
    ExpandableOperationBacklogTable,
    ExpandableOperationProgressTable,
    ExpandableParametersTable,
    ExpandableRequiredProfilesTable,
    ExpandableSoftErrorAlertTable,
    ExpandableTimeStampedLog,
    ExpandableWarningAlertTable
} from "../common/ExpandableContent/ExpandableContent";
import HighlightText from "../userSettings/HighlightOption/HighlightText";
import Switch from "../common/Switch/Switch";
import {ExecutionParametersTable} from "./ExecutionParametersTable";
import {MimeTypeTable} from "./MimeTypeStatsTable";
import UserCommentsDisplay from "../userComments/UserCommentsDisplay";
import {statusIcon} from "../../utilities/iconResolver";
import Text from "../common/Text/Text";
import JobSettings from "./JobSettings";
import CustomIntersectionObserver from "../../utilities/CustomIntersectionObserver";
import {JobApi, JobSaga} from "../../models/job/JobModel";
import SchedulerModel from "../../models/scheduler/SchedulerModel";
import {contextCall} from "../../saga/sagaFunctions";

function JobTabletBody(props) {
    const dispatch = useDispatch();
    const {t} = useTranslation(['job']);

    const {
        jobId,
        hideCommentForm
    } = props;

    const job = useSelector(state => selectJobFromDisplay(state, jobId));
    const {
        executionState,
        notes,
        confidential,
        locked,
        hasWarnings,
        hasSoftErrors,
        parameterLock,
        executionProfileId,
        runningOperationId
    } = job;

    const isLoading = useSelector(state => !state.hasLoaded[jobId]);
    const isConfidentialUnlocked = !confidential || !locked;

    const operations = useSelector(state => state.jobOperationsMap.get(jobId));
    const parameters = useSelector(state => state.parametersMap.get(jobId));
    const errors = useSelector(state => state.jobErrorsMap.get(jobId));
    const softErrors = useSelector(state => state.jobSoftErrorsMap.get(jobId));
    const warnings = useSelector(state => state.jobWarningsMap.get(jobId));
    const links = useSelector(state => state.jobLinksMap.get(jobId));
    const infos = useSelector(state => state.jobInfosMap.get(jobId));
    const requiredProfiles = useSelector(state => state.jobRequiredProfilesMap.get(jobId));

    const executionLogObjects = useSelector(state => state.jobExecutionLog.executionLogs);
    const operationMimeTypeStats = useSelector(state => state.jobOperationMimeTypeStats)
    const {runningLog, runningLogIndex} = useSelector(state => state.jobRunningLog)
    const executionParameters = job.executionParameters;

    const auditLog = useSelector(state => state.auditLogMap.get(jobId));
    const userSettingsTroubleshoot = useSelector(state => state.userSettingsMap.get(userSettings.TROUBLESHOOT));
    const jobLogInitialExpanded = userSettingsTroubleshoot.showObjectIds;
    const executionParametersInitialExpanded = userSettingsTroubleshoot.showObjectIds;

    const userSettingsJobPanel = useSelector(state => state.userSettingsMap.get(userSettings.JOB_PANEL));
    const executionParametersVisible = userSettingsJobPanel.showExecutionParameters;

    let canViewSensitive = false;
    if (operations != null && operations[0] != null) {
        canViewSensitive = operations[0].options != null
    }

    const canComment = !hideCommentForm && includesSome(job.userPermissions, [permissionKeys.SUBMIT_JOB, permissionKeys.MODIFY, permissionKeys.MODIFY_CHILDREN]);
    const isLoadingComments = useSelector(state => !state.hasLoaded[`${details.USER_COMMENTS}:${jobId}`]);
    const userComments = useSelector(state => state.objectUserComments.get(jobId));


    const [thirdPartyUsers, setThirdPartyUsers] = useState({});
    const thirdPartyQueryOptions = useRef({});
    thirdPartyQueryOptions.current.jobId = jobId;
    thirdPartyQueryOptions.current.executionState = executionState;

    async function queryThirdPartyUsers() {
        const hasThirdPartyServiceParameter = getValues(parameters).some(param => param.isThirdPartyServiceParameter());
        if (hasThirdPartyServiceParameter) {
            const {data} = await JobApi.getThirdPartyServiceUserCredentials(thirdPartyQueryOptions.current.jobId);

            if (!thirdPartyQueryOptions.current.isCancelled) {
                setThirdPartyUsers(data);
                if (jobStates.backlog.includes(executionState)) {
                    clearTimeout(thirdPartyQueryOptions.current.timeout);
                    thirdPartyQueryOptions.current.timeout = setTimeout(queryThirdPartyUsers, QUERY_INTERVAL);
                }
            }
        }
    }

    useEffect(() => {
        thirdPartyQueryOptions.current.isCancelled = false;
        queryThirdPartyUsers();
        return () => {
            clearTimeout(thirdPartyQueryOptions.current.timeout);
            thirdPartyQueryOptions.current.isCancelled = true;
        }
    }, [parameters]);

    useEffect(() => {
        const resolveEffect = contextCall(JobSaga, 'getModelNames');
        dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(resolveEffect));
        dispatch(UserCommentModel.actionCreators.startPollingDetails(UserCommentModel.ObjectType.JOB, jobId));
        return () => dispatch(UserCommentModel.actionCreators.stopPollingDetails(jobId));
    }, []);

    const showWarnings = (warnings != null && warnings.length > 0) || (operations != null && operations.some(_operation => _operation.warnings.length > 0))
    const className = buildClassName(
        'jobTablet-body',
        (executionState === executionStateKeys.DISCONNECTED) && 'is-disconnected'
    );


    return (
        <section id="tabletBody" className={className}>
            <LoadingWrapper isLoading={isLoading}>
                {isConfidentialUnlocked && executionState === executionStateKeys.ERROR &&
                    <section className="display-item">
                        <ExpandableErrorAlertTable id={jobId} label={t('job:label.errors')}
                            errors={errors} operations={operations}
                        />
                    </section>
                }

                {isConfidentialUnlocked && hasSoftErrors &&
                    <section className="display-item">
                        <ExpandableSoftErrorAlertTable id={jobId} label={t('job:label.softErrors')}
                            softErrors={softErrors} operations={operations}
                        />
                    </section>
                }

                {isConfidentialUnlocked && hasWarnings && showWarnings &&
                    <section className="display-item">
                        <ExpandableWarningAlertTable id={jobId} label={t('job:label.warnings')}
                            warnings={warnings} operations={operations}
                        />
                    </section>
                }

                {isConfidentialUnlocked && isNotEmptyNorFalsy(links) &&
                    <section className="display-item">
                        <ExpandableLinkAlertTable id={jobId} label={t('job:label.links')}
                                                  links={links}
                        />
                    </section>
                }

                {isConfidentialUnlocked && isNotEmptyNorFalsy(infos) &&
                    <section className="display-item">
                        <ExpandableInfoAlertTable id={jobId} label={t('job:label.infos')}
                            infos={infos}
                        />
                    </section>
                }

                <section className="display-item">
                    <JobSettings jobId={jobId}/>
                </section>

                {isConfidentialUnlocked && notes &&
                    <section className="display-item">
                        <ExpandableContent label={t('job:label.notes')}>
                            <label className="label is-wordwrap">
                                <HighlightText text={notes}/>
                            </label>
                        </ExpandableContent>
                    </section>
                }

                {isConfidentialUnlocked && isNotEmptyNorFalsy(parameters) &&
                    <section className="display-item">
                        <ExpandableParametersTable enableHighlightText thirdPartyUsers={thirdPartyUsers}
                            parameters={parameters} parameterLock={parameterLock}
                        />
                    </section>
                }

                {isConfidentialUnlocked && isNotEmptyNorFalsy(requiredProfiles) &&
                    <section className="display-item">
                        <ExpandableRequiredProfilesTable
                            requiredProfiles={requiredProfiles} executionProfileId={executionProfileId}
                        />
                    </section>
                }

                {isConfidentialUnlocked && isNotEmptyNorFalsy(operations) &&
                    <section className="display-item">
                        <Switch>
                            {executionState !== executionStateKeys.PAUSED && jobStates.backlog.includes(executionState) &&
                                <ExpandableOperationBacklogTable id={jobId} enableHighlightText
                                    label={t('job:label.workflow')} operations={operations}
                                    viewSensitive={canViewSensitive}
                                />
                            }

                            <ExpandableOperationProgressTable id={jobId} enableHighlightText
                                label={t('job:label.workflow')} operations={operations} runningOperationId={runningOperationId}
                            />
                        </Switch>
                    </section>
                }

                {isConfidentialUnlocked && executionParametersVisible && isNotEmptyNorFalsy(executionParameters) &&
                    <ExpandableContent label={t('job:label.executionParameters')} initialExpanded={executionParametersInitialExpanded}>
                        <ExecutionParametersTable executionParameters={executionParameters}/>
                    </ExpandableContent>
                }

                {isConfidentialUnlocked && operationMimeTypeStats != null && Object.keys(operationMimeTypeStats).length > 0 &&
                    <>
                        {Object.keys(operationMimeTypeStats).map(key => (
                            <section key={`jobOperationMimeTypeStats_${key}`} id={`jobOperationMimeTypeStats_${key}`} className="display-item" style={{display: operationMimeTypeStats[key] != null && operationMimeTypeStats[key].columns.length > 0 ? 'flex' : 'none'}}>
                                <ExpandableContent label={t('job:label.operationMimeTypeStats', {index: key, operationName: operationMimeTypeStats[key].operationName})} initialExpanded={jobLogInitialExpanded}>
                                    <MimeTypeTable {...operationMimeTypeStats[key]}/>
                                </ExpandableContent>
                            </section>
                        ))}
                    </>
                }

                {isConfidentialUnlocked && runningLog != null && runningLog.length > 0 && runningLogIndex !== -1 &&
                    <ExpandableTimeStampedLog label={t("job:label.runningLog", {index: (runningLogIndex + 1)})} log={runningLog} initialExpanded={jobLogInitialExpanded}/>
                }

                {isConfidentialUnlocked && isNotEmptyNorFalsy(executionLogObjects) &&
                    <section id="jobExecutionLog" className="display-item">
                        <ExpandableExecutionLog executionLogs={executionLogObjects} initialExpanded={jobLogInitialExpanded}/>
                    </section>
                }

                {isConfidentialUnlocked && isNotEmptyNorFalsy(auditLog) &&
                    <section className="display-item">
                        <ExpandableChangeLogTable label={t('common:label.auditLog')} changeLogs={auditLog}/>
                    </section>
                }

                {(isNotEmptyNorFalsy(userComments) || canComment) &&
                    <section className="display-item" id="userCommentDisplayContainer">
                        <ExpandableContent label={t('userComment:label.comment_plural')}>
                            <LoadingWrapper isLoading={isLoadingComments}>
                                <UserCommentsDisplay objectType={UserCommentModel.ObjectType.JOB} objectId={jobId}
                                    comments={userComments} canComment={canComment}/>
                            </LoadingWrapper>
                        </ExpandableContent>
                    </section>
                }
            </LoadingWrapper>
        </section>
    );
}

function ExpandableExecutionLog (props) {
    const {t} = useTranslation('job');
    const {initialExpanded, executionLogs} = props;

    return (
        <ExpandableContent label={t('job:label.executionLog')} initialExpanded={initialExpanded}>
            {executionLogs.map((executionLog, index) => {
                return (
                    <ExecutionLog key={index} executionLog={executionLog}
                        isLastLog={index === executionLogs.length - 1}/>
                )
            })}
        </ExpandableContent>
    )
}

function ExecutionLog(props) {
    const {t} = useTranslation(['job']);
    const {
        executionLog,
        isLastLog
    } = props;


    const ref = useRef();
    const intersectionObserver = useRef();
    const prevVisible = useRef();

    useLayoutEffect(() => {
        if (isLastLog) {
            if (intersectionObserver.current == null) {
                intersectionObserver.current = new CustomIntersectionObserver(() => prevVisible.current = true, {
                    invisibleCallback: () => prevVisible.current = false,
                    root: getScrollElement(ref.current),
                    delay: 0
                });
            }
            intersectionObserver.current.observe(ref.current);
            prevVisible.current = intersectionObserver.current.isVisible();
        }

        // If previouslyVisible and no longer lastLog, scroll to new lastLog
        if (!isLastLog && intersectionObserver.current != null) {
            if (prevVisible.current) {
                let lastLogElement = ref.current;
                let height = 0;
                while (lastLogElement.nextSibling != null) {
                    lastLogElement = lastLogElement.nextSibling;
                    height += lastLogElement.getBoundingClientRect().height;
                }
                const scrollElement = intersectionObserver.current.root;
                scrollElement.scrollTop += height;
            }
            intersectionObserver.current.disconnect();
            intersectionObserver.current = null;
            prevVisible.current = false;
        }
    }, [isLastLog]);


    let message = executionLog.message;
    if (executionLog.level === logLevelKeys.TRANSIENT_WARNING_RESOLVED) {
        message = t('job:message.resolvedTransientWarning', {warning: executionLog.message});
    }
    message += '\r\n';

    const logLevelIcon = logLevelStatusIconKeys[executionLog.level];
    if (logLevelIcon != null) {
        if (executionLog.level == "LINK") {
            try {
                let logMessage = JSON.parse(message);
                return (
                    <Text ref={ref} className="flex-center" isWordWrap isBold>
                        <span className="icon is-small" style={{marginRight: '0.25rem', flexShrink: 0, alignSelf: 'start'}}>
                            <img src={statusIcon(logLevelIcon)} alt={t(`aria:hiddenAssistText.${logLevelIcon}Icon`)}/>
                        </span>

                        <HighlightText text={logMessage.prefix + (logMessage.prefix.length > 0 ? " " : "")}/>
                        <a href={logMessage.linkUrl} target="_blank" rel="noopener noreferrer">{logMessage.linkName}</a>
                        <HighlightText text={" " + logMessage.suffix}/>
                    </Text>
                )
            } catch (e){
                return (
                    <Text ref={ref} className="flex-center" isWordWrap isBold>
                <span className="icon is-small" style={{marginRight: '0.25rem', flexShrink: 0, alignSelf: 'start'}}>
                    <img src={statusIcon(logLevelIcon)} alt={t(`aria:hiddenAssistText.${logLevelIcon}Icon`)}/>
                </span>
                        <HighlightText text={message}/>
                    </Text>
                )
            }
        } else {
            return (
                <Text ref={ref} className="flex-center" isWordWrap isBold>
                <span className="icon is-small" style={{marginRight: '0.25rem', flexShrink: 0, alignSelf: 'start'}}>
                    <img src={statusIcon(logLevelIcon)} alt={t(`aria:hiddenAssistText.${logLevelIcon}Icon`)}/>
                </span>
                    <HighlightText text={message}/>
                </Text>
            )
        }
    }

    return (
        <Text ref={ref} isWordWrap>
            <HighlightText text={ message}/>
        </Text>
    )

}

export default JobTabletBody;
