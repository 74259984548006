import ReduxStateModel from "../scheduler/ReduxStateModel";
import ComponentStateModel from "../generics/ComponentStateModel";
import ReduxModel from "../generics/ReduxModel";
import {axiosInstance} from "../api/AxiosProxy";
import {
    actionCreator,
    getInputFormattedDateAndTime,
    getLocaleDateTimeFromUTC,
    objectTruthyValues
} from "../../utilities/helperFunctions";
import SagaModel from "../generics/SagaModel";
import {all, put, select, takeLeading} from "redux-saga/effects";
import {contextCall, contextSaga, editNavigation, spawnSaga} from "../../saga/sagaFunctions";
import {tryCatchWrapper} from "../../saga/tryCatchWrapper";
import {details} from "../../utilities/constants";


class SmtpEmailModel extends ReduxModel {

    static nom = 'SmtpEmailModel';

    static actions = SmtpEmailModel.buildActions('SMTP_EMAIL');
    static actionCreators = SmtpEmailModel.buildActionCreators(SmtpEmailModel.actions);
    static reducer = SmtpEmailModel.buildReducer(SmtpEmailModel.actions);

    static componentActionCreators = {
        ...SmtpEmailModel.buildComponentUpdateActionCreators()
    };

    constructor(model={}) {
        super(model);
        const {id, smtpServerId, from, to, cc, subject, createdDate, sentDate, status} = model;

        this.id = id;
        this.smtpServerId = smtpServerId;

        this.from = from;
        this.to = to;
        this.cc = cc;

        this.subject = subject;
        this.createdDate = getLocaleDateTimeFromUTC(createdDate);
        this.sentDate = getLocaleDateTimeFromUTC(sentDate);
    }

    static State = {
        PENDING: 'PENDING',
        SENT: 'SENT',
        ERROR: 'ERROR',
        PURGED: 'PURGED'
    };

    static buildActions(type) {
        return {
            // DETAILS ACTIONS
            SET_DETAILS_MAP: `SET_${type}_DETAILS_MAP`,
            UPDATE_DETAILS: `BULK_UPDATE_${type}_DETAILS`,
            // DISPLAY ACTIONS
            SHOW_TABLET: `SHOW_${type}_TABLET`,
            HIDE_TABLET: `HIDE_${type}_TABLET`,
            // EDIT ACTIONS
            START_EDIT: `START_${type}_EDIT`,
            // DETAILS POLLING ACTIONS
            QUERY_DETAILS: `QUERY_${type}_DETAILS`
        };
    }

    static buildActionCreators(actions) {
        return {
            // DETAILS ACTION CREATORS
            setDetailsMap: actionCreator(actions.SET_DETAILS_MAP, 'details'),
            updateDetails: actionCreator(actions.UPDATE_DETAILS, 'idToUpdates'),
            // DISPLAY ACTION CREATORS
            showTablet: actionCreator(actions.SHOW_TABLET, 'id'),
            hideTablet: actionCreator(actions.HIDE_TABLET),
            // EDIT ACTION CREATORS
            startEdit: actionCreator(actions.START_EDIT, 'id'),
            // DETAIL POLLING ACTION CREATORS
            queryDetails: actionCreator(actions.QUERY_DETAILS)
        };
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'legalHoldEmailDisplay',
                type: 'LegalHoldEmailDisplay',
                state: {
                    smtpEmailId: null,
                    searchText: '',
                    fromDate: getInputFormattedDateAndTime(-1)[0],
                    toDate: getInputFormattedDateAndTime()[0],
                    viewCount: 50,
                    legalHoldIds: {},
                    smtpServerIds: {},
                    toEmails: {},
                    statusStateFilter: {}
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildReducer(actions) {
        return function (state = new Map(), action) {
            switch (action.type) {
                case actions.SET_DETAILS_MAP: {
                    const {details} = action.payload;

                    return this.setDetailsMapGeneric(state, details, 'id');
                }
                case actions.UPDATE_DETAILS: {
                    const {idToUpdates} = action.payload;

                    return this.bulkUpdateDetails(state, idToUpdates);
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }
}

export class SmtpEmailApi {

    static getDetails(filter) {
        return axiosInstance.post('/scheduler/resources/smtpEmails', filter);
    }
}

export class SmtpEmailSaga extends SagaModel {

    static ModelType = SmtpEmailModel;
    static ModelApi = SmtpEmailApi;

    static activationComponent = 'LEGAL_HOLD_PAGE';
    static variableNames = {
        detailsMap: 'smtpEmailDetailsMap',
        instanceId: 'smtpEmailId',
        updatePane: 'updateTablet',
        updateDisplay: 'updateLegalHoldEmailDisplay'
    };

    static translations = {
        itemTitle: '$t(smtpEmail:label.name)',
        itemLower: '$t(smtpEmail:label.name_lower)'
    };

    static buildActivationEffects(dispatch) {
        return [
            // ACTIVATION EFFECTS
            takeLeading(this.ModelType.actions.HIDE_TABLET, editNavigation, dispatch, contextSaga(this, 'hidePane')),
            takeLeading(this.ModelType.actions.START_EDIT, spawnSaga, contextSaga(this, 'startEdit')),

            takeLeading(this.ModelType.actions.QUERY_DETAILS, tryCatchWrapper, contextSaga(this, 'queryDetails'))
        ]
    }

    static* queryDetails() {
        const {
            fromDate,
            toDate,
            viewCount,
            legalHoldIds,
            smtpServerIds,
            toEmails,
            statusStateFilter
        } = yield select(state => state.componentStates.legalHoldEmailDisplay);

        // Unload previous emails
        yield put(ReduxStateModel.actionCreators.setHasLoaded(details.SMTP_EMAILS, false));

        const from = new Date(fromDate.split('-'));
        const to = new Date(toDate.split('-'));
        to.setDate(to.getDate() + 1);
        to.setMilliseconds(to.getMilliseconds() - 1);

        const filter = {
            afterDate: from.getTime(),
            beforeDate: to.getTime(),
            count: viewCount,
            legalHoldIds: objectTruthyValues(legalHoldIds),
            smtpServerIds: objectTruthyValues(smtpServerIds),
            toEmails: objectTruthyValues(toEmails),
            states: objectTruthyValues(statusStateFilter),
        };

        try {
            const {data} = yield contextCall(SmtpEmailApi, 'getDetails', filter);
            yield put(SmtpEmailModel.actionCreators.setDetailsMap(data));

        } finally {

            yield all([
                put(ReduxStateModel.actionCreators.setHasLoaded(details.SMTP_EMAILS)),
                put(SmtpEmailModel.componentActionCreators.updateLegalHoldEmailDisplay({searchText: ''}))
            ]);
        }
    }
}

export default SmtpEmailModel;
