import React from "react";
import {buildOperationFieldParameterNames} from "../../guidedJob/operationFieldParameterNames";
import WorkflowBuilderOperation from "../../../models/workflowbuilder/WorkflowBuilderOperation";
import VaultOperationPane from "./VaultOperatonPane";
import {useTranslation} from "react-i18next";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import {FormElementRow} from "../../common/CustomTable/CustomTable";
import Checkbox from "../../common/Checkbox/Checkbox";
import UtcDateInput from "../../common/HTMLTextInput/UtcDateInput";
import Text from "../../common/Text/Text";

const fieldNames = ['holdNamePrefix', 'useDateRange', 'startDate', 'endDate',
    'includeSharedDriveFiles', 'includeChatRooms', 'useHoldFiles', 'holdLocations', 'queryTerms'];
export const vaultAddHoldsParameterNames = buildOperationFieldParameterNames(WorkflowBuilderOperation.Alias.VAULT_ADD_HOLDS, fieldNames);

function getNextEnabled(getParameter) {
    const holdNamePrefix = getParameter(vaultAddHoldsParameterNames.holdNamePrefix);
    return {
        holdOptions: holdNamePrefix.value
    };
}

export function getVaultHoldOptionsGuide(props) {
    const {
        workflowActions,
        getParameter,
        updateParameter
    } = props;

    const holdNamePrefix = getParameter(vaultAddHoldsParameterNames.holdNamePrefix);
    if (!holdNamePrefix.value) {
        updateParameter(vaultAddHoldsParameterNames.holdNamePrefix, '');
    }

    return {
        getPanes: function(t, props) {
            const nextEnabled = getNextEnabled(props.getParameter);
            return [{
                title: t('guidedJob:panes.holdOptions'),
                isNextEnabled: nextEnabled.holdOptions,
                component: <VaultOperationPane {...props}
                    workflowActions={workflowActions} OperationPane={VaultHoldOptionsPane}/>
            }];
        }
    }
}

function VaultHoldOptionsPane(props) {
    const {t} = useTranslation(['guidedJob', 'common']);
    const {
        getParameter,
        updateParameter,
        isDisabled
    } = props;

    const holdNamePrefix = getParameter(vaultAddHoldsParameterNames.holdNamePrefix);
    const includeSharedDriveFiles = getParameter(vaultAddHoldsParameterNames.includeSharedDriveFiles, false, {parse: true});
    const includeChatRooms = getParameter(vaultAddHoldsParameterNames.includeChatRooms, false, {parse: true});

    const useDateRange = getParameter(vaultAddHoldsParameterNames.useDateRange, false, {parse: true});
    const startDate = getParameter(vaultAddHoldsParameterNames.startDate);
    const endDate = getParameter(vaultAddHoldsParameterNames.endDate);

    const isDateRangeDisabled = isDisabled || !useDateRange.value;

    function inputHandler(event) {
        const {name, value} = event.target;
        updateParameter(name, value);
    }

    function toggleHandler(event) {
        const {name, checked} = event.target;
        updateParameter(name, JSON.stringify(checked));
    }


    return (
        <div>
            <div className="display-input">
                <HTMLTextInput label={t('guidedJob:label.holdNamePrefix')} name={vaultAddHoldsParameterNames.holdNamePrefix}
                    value={holdNamePrefix.value} onChange={inputHandler} isRequired isDisabled={isDisabled}/>
            </div>

            <div className="display-input">
                <Checkbox label={t('guidedJob:label.useDateRange')} name={vaultAddHoldsParameterNames.useDateRange}
                    value={useDateRange.value} onClick={toggleHandler} containerStyle={{marginBottom: '0.5rem'}}
                    isDisabled={isDisabled}/>

                <div className="forms-table">
                    <div className="table-row-group">
                        <FormElementRow labelId={'vaultQueryOptions:label:vaultStartDate'} label={t('guidedJob:label.vaultStartDate')} isDisabled={isDateRangeDisabled}
                            element={
                                <UtcDateInput aria-labelledby={'vaultQueryOptions:label:vaultStartDate'} name={vaultAddHoldsParameterNames.startDate}
                                    value={startDate.value} inputHandler={inputHandler} isDisabled={isDateRangeDisabled}/>
                            }/>

                        <FormElementRow labelId={'vaultQueryOptions:label:vaultEndDate'} label={t('guidedJob:label.vaultEndDate')} isDisabled={isDateRangeDisabled}
                            element={
                                <UtcDateInput aria-labelledby={'vaultQueryOptions:label:vaultEndDate'} name={vaultAddHoldsParameterNames.endDate}
                                    value={endDate.value} inputHandler={inputHandler} isDisabled={isDateRangeDisabled}/>
                            }/>
                    </div>
                </div>
            </div>

            <div className="display-input flex-column">
                <Text value={t('guidedJob:label.driveChatOptions')}
                    style={{marginBottom: '0.5rem'}} isDisabled={isDisabled}/>

                <Checkbox label={t('guidedJob:label.includeSharedDriveFiles')} name={vaultAddHoldsParameterNames.includeSharedDriveFiles}
                    value={includeSharedDriveFiles.value} onClick={toggleHandler}
                    containerStyle={{marginBottom: '0.5rem'}} isDisabled={isDisabled}/>

                <Checkbox label={t('guidedJob:label.includeChatRooms')} name={vaultAddHoldsParameterNames.includeChatRooms}
                    value={includeChatRooms.value} onClick={toggleHandler}
                    containerStyle={{marginBottom: '0.5rem'}} isDisabled={isDisabled}/>
            </div>
        </div>
    )
}