import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import TextArea from '../common/TextArea/TextArea';
import {getSafeClose, onInputChange, warnOnClose} from '../../utilities/componentFunctions';
import InputList from '../common/InputList/InputList';
import {onInputListAddInput, onInputListDeleteInput, onInputListInputChange} from "../common/InputList/helpers";
import Form, {FormFooter, FormHeader} from "../common/Form/Form";
import {shouldEnableServer} from "../../utilities/shouldEnableFunctions";
import {updateMode} from "../../utilities/constants";
import PopupModel from "../../models/scheduler/PopupModel";
import ServerModel from "../../models/settings/ServerModel";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";

class ServerForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            serverName: '',
            serverUrl: '',
            description: '',
            whitelistedCertFingerprints: [''],
            isAddEnabled: false
        };

        this.getSafeClose = getSafeClose('state').bind(this);
        this.warnOnClose = warnOnClose(this.props.t('server:label.name')).bind(this);

        this.onInputChange = onInputChange({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            isEnabled: 'isAddEnabled',
            shouldEnable: shouldEnableServer
        }).bind(this);

        this.onAddWhitelistCerFingerprint = onInputListAddInput({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'whitelistedCertFingerprints'
        }).bind(this);
        this.onDeleteWhitelistCertFingerprint = onInputListDeleteInput({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'whitelistedCertFingerprints',
            isEnabled: 'isAddEnabled',
            shouldEnable: shouldEnableServer
        }).bind(this);
        this.onWhitelistCertFingerprintChange = onInputListInputChange({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'whitelistedCertFingerprints',
            isEnabled: 'isAddEnabled',
            shouldEnable: shouldEnableServer
        }).bind(this);
    }

    onAddClick = () => {
        this.props.addServer(this.state);
    };

    render() {

        const {
            t,
            isDisabled
        } = this.props;
        const {
            serverName,
            serverUrl,
            description,
            whitelistedCertFingerprints,
            isAddEnabled
        } = this.state;
        const close = this.getSafeClose();

        const nameInputProps = {
            label: t('common:label.name'),
            name: 'serverName',
            value: serverName,
            onChange: this.onInputChange,
            isRequired: true,
            isDisabled
        };
        const descriptionInputProps = {
            label: t('common:label.description'),
            name: 'description',
            value: description,
            onChange: this.onInputChange,
            isDisabled
        };
        const urlInputProps = {
            label: t('server:label.url'),
            name: 'serverUrl',
            value: serverUrl,
            onChange: this.onInputChange,
            isRequired: true,
            isDisabled
        };
        const whitelistCertFingerprintsInputListProps = {
            id: 'whitelistedCertFingerprints',
            label: t('common:label.whitelistedCertFingerprints'),
            values: whitelistedCertFingerprints,
            onValueChange: this.onWhitelistCertFingerprintChange,
            onAddClick: this.onAddWhitelistCerFingerprint,
            onDeleteClick: this.onDeleteWhitelistCertFingerprint,
            isDisabled
        };

        const footerProps = {
            addText: t('common:button.addEngineServer'),
            onAddClick: this.onAddClick,
            onCancel: close,
            isAddEnabled,
            isDisabled
        };

        return (
            <Form onClose={close} isDisabled={isDisabled} closeButtonAriaLabel={t('server:option.closeForm')}
                header={
                    <FormHeader text={t('server:label.name')} iconName={'engineServer'} isDisabled={isDisabled} />
                }
                body={
                    <>
                        <div className="display-input">
                            <HTMLTextInput {...nameInputProps}/>
                        </div>
                        <div className="display-input">
                            <HTMLTextInput {...urlInputProps}/>
                        </div>
                        <div className="display-input">
                            <TextArea {...descriptionInputProps}/>
                        </div>
                        <div className="display-input">
                            <InputList ariaLabelKey={'WhitelistedCertFingerprint'}
                                {...whitelistCertFingerprintsInputListProps} />
                        </div>
                    </>
                }
                footer={
                    <FormFooter {...footerProps} />
                }
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.componentStates.serverForm
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addServer: server => dispatch(ServerModel.actionCreators.submitForm(server)),
        close: () => dispatch(ServerModel.componentActionCreators.updateDisplay({isServerFormActive: false})),

        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['server', 'common'])(ServerForm));