import {
    buildClassName,
    getObjectText,
    isNotEmptyNorFalsy,
    objectTruthyValues,
    switchcase,
    textContainsEverySearchWord
} from "../../utilities/helperFunctions";
import {custodianState, legalHoldCommands} from "../../utilities/constants";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import React, {useCallback, useRef, useState} from "react";
import {
    getRenderedHeightOffset,
    initialSelectedState,
    useClearSelectedEffect,
    usePreciseVirtualRendering,
    useRenderedItemHeight,
    useValueSelectHandler
} from "../../utilities/hooks";
import LegalHoldModel from "../../models/legalhold/LegalHoldModel";
import PopupModel from "../../models/scheduler/PopupModel";
import {legalHoldStateKeys} from "../../i18next/keys";
import Text from "../common/Text/Text";
import {Button} from "../common/Button/Button";
import {icon} from "../../utilities/iconResolver";
import SearchBar from "../common/SearchBar/SearchBar";
import {TableLabelCell, TableLabelHeader} from "../common/CustomTable/CustomTable";
import {createFileHandler} from "../../utilities/componentFunctions";
import ExpandableContent from "../common/ExpandableContent/ExpandableContent";

function selectSelectedParticipations(participations, selected) {
    return objectTruthyValues(selected.values)
        .reduce((acc, curr) => {
            const participation = participations[curr];

            if (participation.custodianState === custodianState.ON_HOLD) {
                acc.toRelease.push(participation);
            } else {
                acc.toHold.push(participation);
            }
            return acc;
        }, {toRelease: [], toHold: []});
}

function CustodiansTable(props) {
    const {t} = useTranslation(['legalHold', 'common']);
    const dispatch = useDispatch();

    const {
        legalHold,
        custodianParticipations,
        canModify,
        isDisabled
    } = props;


    const fullContainerRef = useRef();
    const containerRef = useRef();
    const listRef = useRef();
    const [selected, setSelected] = useState(initialSelectedState);

    const selectHandler = useValueSelectHandler({setSelected});
    useClearSelectedEffect({ignorePopup: false, fullContainerRef, setSelected});

    const datasetDetailsMap = useSelector(state => state.datasetDetailsMap);

    const selectedParticipations = selectSelectedParticipations(custodianParticipations, selected);
    const [searchText, setSearchText] = useState('');


    const onImport = useCallback(event => {
        dispatch(LegalHoldModel.actionCreators.importCustodians(legalHold.id, event.target.result));
    }, [legalHold.id, dispatch]);

    const searchHandler = useCallback(event => {
        setSearchText(event.target.value);
    }, []);

    function sendCommand(event) {
        const {value} = event.currentTarget.dataset;
        const titleKey = `legalHold:option.${value}`;

        const selectedCustodians = switchcase({
            [legalHoldCommands.ISSUE_HOLD]: selectedParticipations.toHold,
            [legalHoldCommands.RELEASE_HOLD]: selectedParticipations.toRelease
        })()(value);

        // Ask for confirmation
        dispatch(PopupModel.actionCreators.show({
            info: {
                key: `legalHoldCommand${value}`,
                values: {names: selectedCustodians.map(user => user.displayName || user.name).join('\n')}
            },
            buttons: [{
                titleKey,
                onClick: () => {
                    dispatch(LegalHoldModel.actionCreators.sendCommand(legalHold.id, legalHoldCommands[value], {userIds: selectedCustodians.map(user => user.id)}));
                    setSelected(initialSelectedState);
                }
            }]
        }));
    }

    function navigateToDataset(event) {
        const {value} = event.currentTarget.dataset;
        dispatch(LegalHoldModel.componentActionCreators.resetDisplay())

        window.location.href = `${window.location.origin + window.location.pathname}#/clients?id=${legalHold.clientId}/matter?id=${legalHold.matterId}/dataset?id=${value}`;
    }

    const filteredCustodians = custodianParticipations.filter(participation => {
        // Filter based on searchText
        return textContainsEverySearchWord(searchText, getObjectText(participation, {blacklist: ['id']}));
    }).map((p, index) => ({...p, index}));

    const listContainerSelector = '.table-row-group';
    const itemHeightRef = useRenderedItemHeight(containerRef, listContainerSelector, 31.6094);
    const heightOffset = getRenderedHeightOffset(containerRef, listContainerSelector, itemHeightRef.current);
    const virtualRenderRows = usePreciseVirtualRendering({
        containerRef,
        listRef,
        size: filteredCustodians.length,
        heightOffset
    });

    const hasUploadedData = custodianParticipations.some(participation => isNotEmptyNorFalsy(participation.noticeDatasetEvents));

    const isActive = legalHold.state === legalHoldStateKeys.ACTIVE;
    const disabled = isDisabled ? ' is-disabled' : '';

    return (
        <ExpandableContent label={t('legalHold:label.custodians_total', {total: filteredCustodians.length})} isDisabled={isDisabled} containerRef={fullContainerRef}
            headerBarStyle={{display: 'flex', marginBottom: '0.25rem', height: '2.15rem'}}
            headerBar={
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="add-remove-buttons">
                        {canModify &&
                            <ImportCustodiansButton aria-label={t('legalHold:message.addCustodianEmails')}
                                isDisabled={isDisabled} onloadend={onImport}/>
                        }
                    </div>

                    <div className="add-remove-buttons">
                        {canModify &&
                            <>
                                <Button isImg onClick={sendCommand} data-value={legalHoldCommands.RELEASE_HOLD}
                                    aria-label={t('legalHold:message.releaseHold')}
                                    isDisabled={selectedParticipations.toRelease.length < 1 || isDisabled}
                                >
                                    <span className="icon is-small">
                                        <img src={icon('releaseHold')} alt={t('aria:hiddenAssistText.releaseHoldsIcon')}/>
                                    </span>
                                </Button>

                                <Button isImg onClick={sendCommand} data-value={legalHoldCommands.ISSUE_HOLD}
                                    aria-label={t('legalHold:message.issueHold')}
                                    isDisabled={selectedParticipations.toHold.length < 1 || isDisabled}
                                >
                                    <span className="icon is-small">
                                        <img src={icon('issueHold')} alt={t('aria:hiddenAssistText.issueHoldsIcon')}/>
                                    </span>
                                </Button>
                            </>
                        }
                        <SearchBar value={searchText} onChange={searchHandler} style={{marginLeft: '0.5rem'}}
                            isDisabled={isDisabled}/>
                    </div>
                </div>
            }
        >
            <div ref={containerRef}>
                <div className={"display-table tight-padding" + disabled}>
                    <div className="table-header-group">
                        <TableLabelHeader label={t('common:label.status')} style={{width: '6rem'}}/>

                        <TableLabelHeader label={t('common:label.name')} style={{width: '20rem'}}/>

                        <TableLabelHeader label={t('legalHold:label.email')} style={{width: '14rem'}}/>

                        {!legalHold.silent && hasUploadedData &&
                        <TableLabelHeader label={t('legalHold:label.uploadedDatasets')}/>
                        }
                    </div>

                    <div className="table-row-group" ref={listRef}>
                        {virtualRenderRows((index, topOffset) => {
                            const participation = filteredCustodians[index];
                            if (participation == null) {
                                return null;
                            }

                            return (
                                <CustodianRow key={participation.id || participation.userId} participation={participation} style={{position: 'relative', top: topOffset}}
                                    datasetDetailsMap={datasetDetailsMap} navigateToDataset={navigateToDataset} selectHandler={selectHandler} isSelected={selected.values[index]}
                                    isSilent={legalHold.silent} isActive={isActive} isDisabled={isDisabled} canModify={canModify} hasUploadedData={hasUploadedData}/>
                            )
                        })}
                    </div>
                </div>
            </div>
        </ExpandableContent>
    )
}

function CustodianRow(props) {
    const {t} = useTranslation(['legalHold']);
    const {
        participation,
        datasetDetailsMap,

        isSilent,
        hasUploadedData,

        selectHandler,
        navigateToDataset,

        canModify,
        isSelected,
        isActive,
        isDisabled,
        ...attr
    } = props;

    const noSelection = (!canModify || !isActive || isDisabled);

    const _tableClassName = buildClassName(
        'table-row',
        !noSelection && isSelected && 'is-selected',
        noSelection && 'no-pointer'
    );

    return (
        <div className={_tableClassName}
            data-index={participation.index} onClick={noSelection ? null : selectHandler} {...attr}
        >
            <TableLabelCell label={participation.custodianState && t(`legalHold:custodianState.${participation.custodianState}`)}/>

            <TableLabelCell label={participation.displayName || participation.name}/>

            <TableLabelCell label={participation.email}/>

            {!isSilent && hasUploadedData &&
                <div className="table-cell" style={{maxWidth: '15rem'}}>
                    {isNotEmptyNorFalsy(participation.noticeDatasetEvents) &&
                        <div className="table-cell__inner-row">
                            {participation.noticeDatasetEvents.map(event => {
                                const dataset = datasetDetailsMap.get(event.datasetId);
                                return (
                                    dataset != null ?
                                        <a className="is-ellipsis" key={event.datasetId} data-value={event.datasetId}
                                            onClick={navigateToDataset}
                                        >
                                            {event.formLabel}
                                        </a>
                                        :
                                        <Text key={event.datasetId} value={event.formLabel}
                                            isEllipsis/>
                                )
                            })}
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export function ImportCustodiansButton(props) {
    const {t} = useTranslation(['aria']);
    const {
        ['aria-label']: ariaLabel,
        onloadend,
        isDisabled,
        ...attr
    } = props;

    const onImportCustodians = useCallback(() => {
        const input = document.getElementById('importCustodiansButtonFileInput');
        input.click();
    }, []);

    const uploadCustodiansFile = createFileHandler({
        readAs: 'readAsText',
        onloadend
    });


    return (
        <div className="legalHold__import-custodians" {...attr}>
            <Button onClick={onImportCustodians} aria-label={ariaLabel}
                isImg isClear isDisabled={isDisabled}
            >
                <span className="icon is-small">
                    <img src={icon('metadataAdd')} alt={t('aria:hiddenAssistText.importCustodiansIcon')}/>
                </span>
            </Button>

            <input id="importCustodiansButtonFileInput" type="file" accept=".txt" style={{display: 'none'}}
                onChange={uploadCustodiansFile} disabled={isDisabled}/>
        </div>
    )
}

export default CustodiansTable;
