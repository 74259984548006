import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Tablet, {EditTabletHeader} from "../common/Tablet/Tablet";
import {isNotEmptyNorFalsy, switchcaseF} from "../../utilities/helperFunctions";
import {permissionKeys} from "../../i18next/keys";
import NoticeTemplateModel from "../../models/notice/NoticeTemplateModel";
import {ExpandableLog} from "../common/ExpandableContent/ExpandableContent";
import {ExpandableMessage, ExpandableParameters, ExpandableSurveyFormOptions} from "./panes/NoticeConfirmPane";
import LimitedTablet from "../limited/LimitedTablet";


function NoticeTemplateTablet(props) {
    const {t} = useTranslation(['noticeTemplate', 'common']);
    const dispatch = useDispatch();

    const {
        noticeTemplateId
    } = props;

    const {
        name,
        type,
        enabled,
        description,

        subject,
        message,

        parameters,
        surveyFormOptions,

        userPermissions
    } = useSelector(state => state.noticeTemplateDetailsMap.get(noticeTemplateId));

    const {
        isDisabled
    } = useSelector(state => state.componentStates.noticeTemplateDisplay);



    function menuOptionHandler(event) {
        const {value} = event.currentTarget.dataset;

        switchcaseF({
            'edit': () => dispatch(NoticeTemplateModel.actionCreators.startEdit(noticeTemplateId)),
            'toggleEnable': () => dispatch(NoticeTemplateModel.actionCreators.toggleEnabled(noticeTemplateId)),
            'duplicate': () => dispatch(NoticeTemplateModel.actionCreators.duplicateDetails(noticeTemplateId)),
            'promptDelete': () => dispatch(NoticeTemplateModel.actionCreators.promptDelete(noticeTemplateId))
        })()(value);
    }

    const menuOptions = [
        {name: t('common:option.edit'), value: 'edit'},
        {name: t(`common:option.${enabled ? 'deactivate' : 'activate'}`), value: 'toggleEnable'},
        {name: t('common:option.duplicate'), value: 'duplicate'},
        {name: t('common:option.delete'), value: 'promptDelete'}
    ];


    const canModifyNoticeTemplate = userPermissions.includes(permissionKeys.MODIFY);
    const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);
    if (isViewLimited && !canModifyNoticeTemplate) {
        return (
            <LimitedTablet id={noticeTemplateId} type={`noticeType${type}`} name={name} label={t(`noticeTemplate:label.name_${type}`)}
                           enabled={enabled} canModify={canModifyNoticeTemplate} isDisabled={isDisabled} description={description}
                           onClose={() => dispatch(NoticeTemplateModel.actionCreators.hideTablet())}
            />
        )
    }


    return (
        <Tablet width={'100rem'} height={'65vh'} closeButtonAriaLabel={t(`noticeTemplate:option.closeTablet_name${type}`, {name})}
            onClose={() => dispatch(NoticeTemplateModel.actionCreators.hideTablet())}
            header={
                <EditTabletHeader label={t(`noticeTemplate:label.name_${type}`)} type={`noticeType${type}`}
                    id={noticeTemplateId} value={name} enabled={enabled} menuName={'noticeTemplate'} menuOptions={menuOptions}
                    menuOptionHandler={menuOptionHandler} canModify={canModifyNoticeTemplate} isDisabled={isDisabled}
                />
            }
            body={
                <>
                    {isNotEmptyNorFalsy(description) &&
                    <div className="display-item">
                        <ExpandableLog label={t('common:label.description')} log={description}
                            isDisabled={isDisabled}
                        />
                    </div>
                    }

                    {isNotEmptyNorFalsy(parameters) &&
                    <div className="display-input">
                        <ExpandableParameters parameters={parameters} isDisabled={isDisabled}/>
                    </div>
                    }

                    <div className="display-input">
                        <ExpandableMessage parameters={parameters} subject={subject} message={message}
                            isDisabled={isDisabled}
                        />
                    </div>

                    {NoticeTemplateModel.isResponseType(type) && isNotEmptyNorFalsy(surveyFormOptions) && surveyFormOptions.some(opt => opt.enabled) &&
                    <div className="display-input">
                        <ExpandableSurveyFormOptions surveyFormOptions={surveyFormOptions}
                            isDisabled={isDisabled}/>
                    </div>
                    }
                </>
            }
        />
    )
}

export default NoticeTemplateTablet;
