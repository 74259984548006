import React from "react";
import ThirdPartyServiceModel from "../../../models/thirdparty/ThirdPartyServiceModel";
import ThirdPartyServiceTable from "../ThirdPartyServiceTable";
import {useTranslation} from "react-i18next";
import {TableLabelCell, TableLabelHeader} from "../../common/CustomTable/CustomTable";

function SmtpServiceTable(props) {
    return (
        <ThirdPartyServiceTable {...props} type={ThirdPartyServiceModel.Type.SMTP} showMethod
            TableHeaders={SmtpServiceTableHeaders} TableRowCells={SmtpServiceTableRowCells}
        />
    )
}

function SmtpServiceTableHeaders() {
    const {t} = useTranslation(['thirdPartyService']);
    return (
        <>
            <TableLabelHeader label={t('thirdPartyService:label.host')}/>
            <TableLabelHeader label={t('thirdPartyService:label.port')}/>
            <TableLabelHeader label={t('thirdPartyService:label.from')}/>
        </>
    )
}

function SmtpServiceTableRowCells(props) {
    const {
        thirdPartyService
    } = props;

    return (
        <>
            <TableLabelCell label={thirdPartyService.host}/>
            <TableLabelCell label={thirdPartyService.port}/>
            <TableLabelCell label={thirdPartyService.from}/>
        </>
    )
}

export default SmtpServiceTable;
