import React from 'react';
import {useTranslation} from "react-i18next";
import HighlightText from "../userSettings/HighlightOption/HighlightText";
import {mimeTypeIconModel} from "../../models/generics/IconModel";

export function MimeTypeTable (props) {
    const {columns, rows} = props;
    const {t} = useTranslation(['job'])

    const correctedColumns = columns && columns.map((colName) => {
        let translatedColumnName = t(`job:mimeTypeHeader.${colName}`);

        if (translatedColumnName && translatedColumnName.startsWith("mimeTypeHeader")) {
            if (translatedColumnName.endsWith("_processed")) {
                const correctedName = colName.replace("_processed", "");
                translatedColumnName = t(`job:mimeTypeHeader.processed_translated`, {translation_name: correctedName});
            } else if (translatedColumnName.endsWith("_asis")) {
                const correctedName = colName.replace("_asis", "");
                translatedColumnName = t(`job:mimeTypeHeader.asis_translated`, {translation_name: correctedName});
            } else{
                const correctedName = colName.replace("_failed", "");
                translatedColumnName = t(`job:mimeTypeHeader.processed_translated`, {translation_name: correctedName});
            }
        }

        return translatedColumnName;
    });

    return (
        <div className="display-table align-middle" style={{marginTop: '0.5rem'}}>
            <div className="table-header-group">

                <div className="table-header">
                    <label className="label is-bold">
                        <HighlightText text={t('job:mimeTypeHeader.mimeType')}/>
                    </label>
                </div>

                {correctedColumns && correctedColumns.map((colName, index) => (
                    <div className="table-header" key={index}>
                        <label className="label is-bold">
                            <HighlightText text={colName}/>
                        </label>
                    </div>
                ))}
            </div>

            <div className="table-row-group">
                {rows && rows.map((op, index) =>
                    <OperationMimeTypeStatRow key={`OP_STAT_${index}`} {...op} columns={columns}/>
                )}
            </div>
        </div>
    );
}

function OperationMimeTypeStatRow (props) {
    const {fileType, mimeType, columnItemCount, columns} = props;
    const mimeTypeIcon = mimeTypeIconModel.useIcon(mimeType);

    return (
        <div className="table-row no-pointer">

            <div className="table-cell">
                <div style={{display: 'flex', alignItems: "center", justifyContent: 'start'}}>
                    <span className="icon is-small" style={{flexShrink: 0}}>
                        <img src={mimeTypeIcon} alt={mimeType}/>
                    </span>
                    <label className="label" style={{marginLeft: '0.5rem'}}>
                        <HighlightText text={fileType}/>
                    </label>
                </div>
            </div>

            {columns && columns.map((colName, index) => (
                <div key={`MIME_${index}`} className="table-cell no-stretch" style={{textAlign: 'right', backgroundColor:
                        (!!columnItemCount[colName] && (colName.endsWith("_failed") || colName === "corrupted" || colName === "encrypted" || colName === "deleted")) ? "#fffde6" : "inherit"}}>
                    <label className="label is-wordwrap">
                        {columnItemCount[colName]}
                    </label>
                </div>
            ))}
        </div>
    )
}
