import React from 'react';
import './Popups.css';
import CustomModal from '../CustomModal/CustomModal';
import {useTranslation} from "react-i18next";
import {getPluralTranslations, isNotEmptyNorFalsy} from '../../../utilities/helperFunctions';
import {getStatusIconImgContent} from "../../../utilities/iconResolver";
import {popupInfoKeys} from "../../../i18next/keys";
import {Button} from "../Button/Button";
import {useDispatch} from "react-redux";


export function Popup(props) {
    const {t} = useTranslation(['popupInfo', 'common']);
    const dispatch = useDispatch();

    const {
        title,
        info,
        icon,
        content,
        hide,
        hideTitle,
        hideButtons,
        buttons=[],
        cancelButton={},
        ...attr
    } = props;

    // Popup assumed active if info or content exists
    if (info == null && content == null)
        return null;

    function onCancel() {
        if (cancelButton.onClick != null) {
            cancelButton.onClick(dispatch);
        }
        hide();
    }


    let message, translationTitle;
    if (info != null) {
        const values = {...info.values, ...info.valueKeys, returnObjects: true};
        const translatedInfo = getPluralTranslations(t, `popupInfo:${info.key || popupInfoKeys.TEMPLATE}`, values);

        translationTitle = translatedInfo.title;
        if (translatedInfo.message != null) {
            message = translatedInfo.message;
        } else {
            message = translatedInfo;
        }
    }

    // If no message, title becomes message with default title
    if (!isNotEmptyNorFalsy(message)) {
        message = translationTitle
        translationTitle = title;
    }

    const cancelTitle = (cancelButton.titleKey && t(cancelButton.titleKey)) || cancelButton.title || t('common:option.cancel');
    const popupTitle = translationTitle || title;
    const disabled = attr.isDisabled ? ' is-disabled' : '';

    return (
        <CustomModal id="popup" role="dialog" className={'popup'} onCancel={onCancel} isActive
            closeButtonAriaLabel={t('aria:hiddenAssistText.closePopup', {name: popupTitle})}
            aria-labelledby={'popupHeaderTitle'} {...attr}
            header={
                <section id={'popupHeaderTitle'} className={'popup-header' + disabled}>
                    {!hideTitle &&
                        <h2 className="subtitle is-6 is-bold">
                            {popupTitle}
                        </h2>
                    }
                </section>
            }
            body={
                <section className={'popup-body' + disabled}>
                    {icon &&
                    <span className="icon is-large">
                        {icon}
                    </span>
                    }

                    <article className="label is-wordwrap">
                        {mapLines(message)}
                    </article>

                    {content}
                </section>
            }
            footer={
                <section className={'popup-footer button-group' + disabled}>
                    {!hideButtons &&
                    <>
                        <Button label={cancelTitle} onClick={onCancel}
                            isDisabled={attr.isDisabled || cancelButton.isDisabled}/>

                        {buttons.map((bouton, index) => {
                            function onClick(event) {
                                if (Array.isArray(bouton.onClick)) {
                                    bouton.onClick.forEach(func => func(dispatch, event));
                                } else if (typeof bouton.onClick === 'function') {
                                    bouton.onClick(dispatch, event);
                                }
                                if (!bouton.ignoreHide) {
                                    hide();
                                }
                            }

                            return (
                                <Button key={index} name={bouton.name} value={bouton.value} onClick={onClick}
                                    label={bouton.title || t(bouton.titleKey)} isDisabled={attr.isDisabled || bouton.isDisabled}
                                />
                            )
                        })}
                    </>
                    }
                </section>
            }
        />
    )
}

function mapLines(lines) {
    if (!Array.isArray(lines)) {
        return lines;
    }

    return lines.map((line, index) => {
        if (typeof line === 'string') {
            return (
                <p key={index}>
                    {line}
                </p>
            )
        }

        return (
            <p key={index} style={line.style}>
                {line.value}
            </p>
        )
    });
}

export function DisconnectPopup(props) {
    const {t} = useTranslation(['popupInfo']);
    const {isActive} = props;

    const {title, message} = t(`popupInfo:disconnected`, {returnObjects: true});

    return (
        <CustomModal id={"disconnectPopup"} isActive={isActive} isRigid showX={false}>
            <section className="disconnect-popup">
                {getStatusIconImgContent('statusBrokenLink')}
                <h2 className="title is-5">
                    {title}
                </h2>
                <article className="label is-wordwrap">
                    {Array.isArray(message) ?
                        message.map((msg, i) =>
                            <p key={i}>{msg}</p>
                        )
                        :
                        <p>{message}</p>
                    }
                </article>
            </section>
        </CustomModal>
    );
}
