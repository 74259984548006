import React from 'react';
import {AddButtonHeader} from "../common/Button/Button";
import {resourcesLinks} from "../../utilities/constants";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {MainPageContent, MainPageHeader} from "../app/pages/MainPage";

function ResourcesDisplay(props) {
    const {t} = useTranslation(['resources']);

    const {
        ...attr
    } = props;

    return (
        <MainPageContent {...attr}
            title={
                <MainPageHeader text={t('resources:page.title')}
                    description={t('resources:page.description')}/>
            }
            list={
                <>
                    <AddButtonHeader text={t('resources:label.links')}/>

                    <div className="options-table">
                        <div className="table-header-group">
                            <div className="table-header"/>
                        </div>

                        <div className="table-row-group">
                            {LinkRow({
                                title: t('resources:link.userGuide'),
                                url: resourcesLinks.USER_GUIDE(i18next.language.replace('-', '_'))
                            })}
                            {LinkRow({
                                title: t('resources:link.workflowDesignGuide'),
                                url: resourcesLinks.WORKFLOW_DESIGN_GUIDE(i18next.language.replace('-', '_'))
                            })}
                            {LinkRow({
                                title: t('resources:link.parametersGuide'),
                                url: resourcesLinks.PARAMETERS_GUIDE(i18next.language.replace('-', '_'))
                            })}
                            {LinkRow({
                                title: t('resources:link.installationGuide'),
                                url: resourcesLinks.INSTALLATION_GUIDE(i18next.language.replace('-', '_'))
                            })}
                            {LinkRow({
                                title: t('resources:link.thirdPartyLicences'),
                                url: resourcesLinks.THIRD_PARTY_LICENCES
                            })}
                            {LinkRow({title: t('resources:link.apiDocumentation'), url: resourcesLinks.OPENAPI})}
                            {LinkRow({title: t('resources:link.odataReporting'), url: resourcesLinks.ODATA_REPORTING})}
                            {LinkRow({title: t('resources:link.privacyPolicy'), url: resourcesLinks.PRIVACY_POLICY})}
                        </div>
                    </div>
                </>
            }
        />
    )
}

function LinkRow(props) {
    const {title, url} = props;

    return (
        <div className="options-row">
            <div className="table-cell">
                <a href={url} target="_blank" rel="noopener noreferrer">{title}</a>
            </div>
        </div>
    )
}

export default ResourcesDisplay;