import React from "react";
import "./RadioButton.css";
import {buildClassName} from "../../../utilities/helperFunctions";
import {useGetMissingClasses} from "../AccessibilityAssist";
import {Label} from "../Text/Text";

function RadioButton(props) {
    const {
        label,
        name,
        value,
        selected,
        labelId,
        onClick,
        isWordWrap=true,
        isRequired,
        isInvalid,
        isDisabled,
        ...attr
    } = props;

    const containerClassName = buildClassName(
        'checkbox-container',
        label && 'has-label',
        isDisabled && 'is-disabled',
        ...useGetMissingClasses(label, {ariaLabel: attr['aria-label'], ariaLabelledBy: attr['aria-labelledby']})
    );

    const id = attr.id || attr.name;

    const input = (
        <input type="radio" id={id} name={name} value={value} checked={selected === value}
            onClick={isDisabled ? null : onClick} readOnly disabled={isDisabled} {...attr}
        />
    );

    return (
        <span className={containerClassName} title={label ? null : attr['aria-label']}>
            {label ?
                <Label isEllipsis isWordWrap={isWordWrap} isNoWrap={!isWordWrap}
                    id={labelId} htmlFor={id}
                >
                    {input}
                    {label}
                </Label>
                :
                input
            }
        </span>
    )
}

export function RadioButtonGroup(props) {
    const {
        id,
        items,
        ...attr
    } = props;

    return (
        <div id={id} className="radio-button-group">
            {items.map(item =>
                <RadioButton id={`${id}:${item.value}`} key={item.value} {...attr}
                    value={item.value} label={item.name}/>
            )}
        </div>
    )
}

export default RadioButton;
