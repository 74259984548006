import React, {useCallback, useMemo} from "react";
import "./WorkflowBuilder.css";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import NavigatePane from "../common/NavigatePane/NavigatePane";
import WorkflowBuilderModel from "../../models/workflowbuilder/WorkflowBuilderModel";
import PopupModel from "../../models/scheduler/PopupModel";
import {isNotEmptyNorFalsy, switchcase} from "../../utilities/helperFunctions";
import {makeGetEditDetails} from "../../reselect/selectors";
import BuilderPane from "./panes/BuilderPane";
import DetailsPane from "./panes/DetailsPane";


function WorkflowBuilder(props) {
    const {t} = useTranslation(['workflowBuilder', 'common']);
    const dispatch = useDispatch();

    const workflowBuilder = useSelector(state => state.workflowBuilder);
    // useMemo to memoize selector instance
    const getEditDetails = useMemo(makeGetEditDetails, []);
    const isEditActive = useSelector(state => getEditDetails(state, {model: WorkflowBuilderModel.nom}).activeModel === WorkflowBuilderModel.nom);

    const changesMade = true || isNotEmptyNorFalsy(workflowBuilder.operations);
    const onClose = useCallback(() => {
        const close = () => dispatch(WorkflowBuilderModel.actionCreators.safeClose());
        const warnOnClose = !isEditActive && changesMade;
        if (warnOnClose) {
            dispatch(PopupModel.actionCreators.showWarning({
                info: {
                    key: 'discardWorkflowBuilder'
                },
                buttons: [{
                    title: t('common:option.discard'),
                    onClick: close
                }]
            }));
        } else {
            close();
        }
    }, [isEditActive, changesMade]);

    const onSubmit = () => dispatch(WorkflowBuilderModel.actionCreators.submit(props.libraryId));
    const saveEdit = () => dispatch(WorkflowBuilderModel.actionCreators.saveEdit());
    const cancelEdit = () => dispatch(WorkflowBuilderModel.actionCreators.cancelEdit());

    const [onCancel, submitTitle, submit] = switchcase({
        [false]: [onClose, t('common:option.add'), onSubmit],
        [true]: [cancelEdit, t('common:option.save'), saveEdit]
    })()(isEditActive);

    const nextEnabled = WorkflowBuilderModel.validateFormData(workflowBuilder);
    const panes = []
    if (Array.isArray(workflowBuilder.operations)) {
        panes.push({
            header: t('workflowBuilder:panes.builder'), isNextEnabled: nextEnabled.builderPane,
            backTitle: t('common:option.cancel'), onBackClick: onCancel,
            component: <BuilderPane/>
        }, {
            header: t('workflowBuilder:panes.details'), nextTitle: submitTitle,
            onNextClick: submit, isNextEnabled: nextEnabled.submit,
            component: <DetailsPane/>
        });
    } else {
        panes.push({
            header: t('workflowBuilder:panes.details'),
            backTitle: t('common:option.cancel'), onBackClick: onCancel,
            nextTitle: submitTitle, onNextClick: submit, isNextEnabled: nextEnabled.submit,
            component: <DetailsPane/>
        });
    }

    return (
        <NavigatePane id={'workflowBuilder'} panes={panes} onClose={onClose} isActive={workflowBuilder.isActive}
            isLoading={workflowBuilder.isLoading} isDisabled={workflowBuilder.isDisabled}
            closeButtonAriaLabel={t('workflowBuilder:option.closeForm')} bodyStyle={{height: '80vh', width: '90vw', maxWidth: '148rem'}}/>
    )
}

export default WorkflowBuilder;
