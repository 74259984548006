import React, {useRef} from 'react';
import './highlightTextArea.css'
import {buildClassName} from "../../../utilities/helperFunctions";
import {workflowParameterHighlightRegex} from "../../../utilities/constants";

function HighlightTextArea(props) {
    const ref = useRef();
    const echoRef = useRef();
    const containerRef = useRef();

    const {id, label, value, rows = 4, isRequired, isReadOnly, isSelected, isInvalid, isDisabled, ...attr} = props;

    const parts = value.split(workflowParameterHighlightRegex);

    function scroll () {
        const {paddingLeft, paddingTop} = window.getComputedStyle(ref.current);
        const leftDistance = -1 * ((ref.current.scrollLeft || 0) - parseFloat(paddingLeft));
        const topDistance = -1 * ((ref.current.scrollTop || 0) - parseFloat(paddingTop));

        echoRef.current.style.left = `${leftDistance}px`;
        echoRef.current.style.top = `${topDistance}px`;
    }

    const textAreaClassName = buildClassName(
        'highlight-text-area',
        (isRequired && !value && !isDisabled) && 'is-required',
        isSelected && 'is-selected',
        isReadOnly && 'is-readonly',
        isDisabled && 'is-disabled'
    );
    const labelClassName = buildClassName(
        'label',
        isDisabled && 'is-disabled'
    );

    return (
        <>
            {label &&
                <label className={labelClassName} htmlFor={id}>
                    {label}
                </label>
            }

            <div className={'highlight-text-area-container'} tabIndex={0} ref={containerRef}>
                <textarea ref={ref} className={textAreaClassName} id={id} value={value} rows={rows}
                          disabled={isDisabled || isReadOnly} onScroll={scroll} {...attr}
                          autoComplete={"off"} autoCorrect={"off"} spellCheck={false}
                />
                <div className="highlight-text-area-echo" aria-hidden={true} ref={echoRef}>
                    {parts.map((part, i) =>
                        part.match(workflowParameterHighlightRegex) ? <span key={i} className={'highlight-text-area-highlighted'}>{part}</span> : part
                    )}
                </div>
            </div>
        </>
    )
}

export default HighlightTextArea;