import {actionCreator, isNotEmptyNorFalsy} from "../../utilities/helperFunctions";
import {axiosInstance} from "../api/AxiosProxy";
import {contextCall, contextPollUntil} from "../../saga/sagaFunctions";
import {put, select} from "redux-saga/effects"

class JobExecutionLogModel {

    static nom = 'JobExecutionLogModel';
    static actions = JobExecutionLogModel.buildActions('JOB_EXECUTION_LOG');
    static actionCreators = JobExecutionLogModel.buildActionCreators(JobExecutionLogModel.actions);
    static reducer = JobExecutionLogModel.buildReducer(JobExecutionLogModel.actions);

    static buildActions(type) {
        return {
            // Details
            UPDATE_EXECUTION_LOG: `UPDATE_${type}`,
            DELETE_EXECUTION_LOG: `DELETE_${type}`,

            // POLLING ACTIONS
            START_POLLING_SETTINGS: `START_POLLING_${type}`,
            STOP_POLLING_SETTINGS: `STOP_POLLING_${type}`,
            QUERY_SETTINGS: `QUERY_SETTINGS_${type}`
        }
    }

    static buildActionCreators(actions) {
        return {
            updateExecutionLog: actionCreator(actions.UPDATE_EXECUTION_LOG, 'executionLogUpdate'),
            deleteExecutionLog: actionCreator(actions.DELETE_EXECUTION_LOG),

            // POLLING ACTION CREATORS
            startPollingSettings: actionCreator(actions.START_POLLING_SETTINGS, 'jobId', 'pollingDelay'),
            stopPollingSettings: actionCreator(actions.STOP_POLLING_SETTINGS),
            querySettings: actionCreator(actions.QUERY_SETTINGS, 'jobId')
        }
    }

    static buildReducer(actions) {
        return function (state = {lastModified: 0, executionLogs: []}, action) {
            switch (action.type) {
                case actions.UPDATE_EXECUTION_LOG: {
                    const {executionLogUpdate} = action.payload;

                    if (executionLogUpdate.lastModified !== state.lastModified && isNotEmptyNorFalsy(executionLogUpdate.executionLogs)) {
                        const executionLogs = [];
                        executionLogs.push(...state.executionLogs);
                        executionLogs.push(...executionLogUpdate.executionLogs);

                        return {lastModified: executionLogUpdate.lastModified, executionLogs: executionLogs}
                    }

                    return state;
                }
                case actions.DELETE_EXECUTION_LOG: {
                    return {lastModified: 0, executionLogs: []};
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }
}

export class JobExecutionLogApi {

    static getJobExecutionLog(id, lastModified) {
        return axiosInstance.post(`/scheduler/jobs/${id}/executionLog?lastUpdate=${lastModified}`)
    }
}

export class JobExecutionLogSaga {

    static* pollSettings(action) {
        const {pollingDelay} = action.payload;

        yield contextPollUntil(JobExecutionLogModel.actions.STOP_POLLING_SETTINGS, pollingDelay, this, 'querySettings', action);
    }

    static* querySettings(action) {
        const {jobId} = action.payload;
        if (jobId == null) {
            return;
        }

        const {lastModified} = yield select(state => state.jobExecutionLog);
        try {
            const {data} = yield contextCall(JobExecutionLogApi, 'getJobExecutionLog', jobId, lastModified)

            if (!!data && data.lastModified !== lastModified) {
                yield put(JobExecutionLogModel.actionCreators.updateExecutionLog({...data}))
            }
        } catch (e) {
            //Execution log is unavailable
        }
    }
}

export default JobExecutionLogModel;