import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {
    getCloudLicenceSourceIds,
    getNmsNuixLicenceSourceIds,
    getNuixDongleLicenceSourceIds
} from '../../reselect/selectors';
import NmsLicenceSourceForm from './nms/NmsLicenceSourceForm';
import ClsLicenceSourceForm from './cls/ClsLicenceSourceForm';
import DongleLicenceSourceForm from './dongle/DongleLicenceSourceForm';
import NuixDongleLicenceSourceTable from './dongle/DongleLicenceSourceTable';
import ClsLicenceSourceTable from './cls/ClsLicenceSourceTable';
import NmsLicenceSourceTable from './nms/NmsLicenceSourceTable';
import NmsLicenceSourceTablet from './nms/NmsLicenceSourceTablet';
import DongleLicenceSourceTablet from './dongle/DongleLicenceSourceTablet';
import ClsLicenceSourceTablet from './cls/ClsLicenceSourceTablet';
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {details} from "../../utilities/constants";
import LicenceSourceModel from "../../models/settings/LicenceSourceModel";
import {MainPageContent, MainPageHeader} from "../app/pages/MainPage";

class NuixLicenceSourceDisplay extends Component {

    componentDidMount() {
        this.props.setDisplayActive();
    }

    componentWillUnmount() {
        this.props.setDisplayInactive();
    }

    render() {

        const {
            t,
            isLoading,
            nuixLicenceSourceId,
            nmsLicenceSourceIds,
            cloudLicenceSourceIds,
            nuixDongleLicenceSourceIds,
            isNmsLicenceSourceFormActive,
            isClsLicenceSourceFormActive,
            isDongleLicenceSourceFormActive,
            ...attr
        } = this.props;

        return (
            <MainPageContent {...attr}
                title={
                    <MainPageHeader text={t('nuixLicenceSource:page.title')}
                        description={t('nuixLicenceSource:page.description')}/>
                }
                list={
                    <LoadingWrapper isLoading={isLoading}>
                        <NmsLicenceSourceTable />

                        <ClsLicenceSourceTable />

                        <NuixDongleLicenceSourceTable />
                    </LoadingWrapper>
                }
                children={
                    <>
                        {nmsLicenceSourceIds.includes(nuixLicenceSourceId) &&
                        <NmsLicenceSourceTablet nuixLicenceSourceId={nuixLicenceSourceId}/>
                        }
                        {cloudLicenceSourceIds.includes(nuixLicenceSourceId) &&
                        <ClsLicenceSourceTablet nuixLicenceSourceId={nuixLicenceSourceId}/>
                        }
                        {nuixDongleLicenceSourceIds.includes(nuixLicenceSourceId) &&
                        <DongleLicenceSourceTablet nuixLicenceSourceId={nuixLicenceSourceId}/>
                        }

                        {isNmsLicenceSourceFormActive &&
                        <NmsLicenceSourceForm />
                        }
                        {isClsLicenceSourceFormActive &&
                        <ClsLicenceSourceForm />
                        }
                        {isDongleLicenceSourceFormActive &&
                        <DongleLicenceSourceForm />
                        }
                    </>
                }
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.componentStates.licenceSourceDisplay,
        nmsLicenceSourceIds: getNmsNuixLicenceSourceIds(state),
        cloudLicenceSourceIds: getCloudLicenceSourceIds(state),
        nuixDongleLicenceSourceIds: getNuixDongleLicenceSourceIds(state),
        isLoading: !state.hasLoaded[details.NUIX_LICENCE_SOURCES]
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setDisplayActive: () => dispatch(LicenceSourceModel.componentActionCreators.setDisplayActive()),
        setDisplayInactive: () => dispatch(LicenceSourceModel.componentActionCreators.setDisplayInactive())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['nuixLicenceSource'])(NuixLicenceSourceDisplay));