import {actionCreator, objEquals} from "../../utilities/helperFunctions";
import {axiosInstance} from "../api/AxiosProxy";
import {contextCall, contextPollUntil} from "../../saga/sagaFunctions";
import {put} from "redux-saga/effects";

class JobOperationMimeTypeStatsModel {

    static nom = 'JobOperationMimeTypeStatsModel';
    static actions = JobOperationMimeTypeStatsModel.buildActions('JOB_OPERATION_MIME_TYPE_STATS');
    static actionCreators = JobOperationMimeTypeStatsModel.buildActionCreators(JobOperationMimeTypeStatsModel.actions);
    static reducer = JobOperationMimeTypeStatsModel.buildReducer(JobOperationMimeTypeStatsModel.actions);

    static buildActions(type) {
        return {
            // Details
            SET_MIME_TYPE_STATS: `SET_${type}`,
            DELETE_MIME_TYPE_STATS: `DELETE_${type}`,

            // POLLING ACTIONS
            START_POLLING_SETTINGS: `START_POLLING_${type}`,
            STOP_POLLING_SETTINGS: `STOP_POLLING_${type}`,
            QUERY_SETTINGS: `QUERY_SETTINGS_${type}`
        }
    }

    static buildActionCreators (actions) {
        return {
            setOperationMimeTypeStats: actionCreator(actions.SET_MIME_TYPE_STATS, 'operationMimeTypeStats'),
            deleteMimeTypeStats: actionCreator(actions.DELETE_MIME_TYPE_STATS),

            // POLLING ACTION CREATORS
            startPollingSettings: actionCreator(actions.START_POLLING_SETTINGS, 'jobId', 'pollingDelay'),
            stopPollingSettings: actionCreator(actions.STOP_POLLING_SETTINGS),
            querySettings: actionCreator(actions.QUERY_SETTINGS, 'jobId')
        }
    }

    static buildReducer(actions) {
        return function (state = {}, action) {
            switch (action.type) {
                case actions.SET_MIME_TYPE_STATS: {
                    const {operationMimeTypeStats} = action.payload;
                    if (!objEquals(state, operationMimeTypeStats)) {
                        return operationMimeTypeStats;
                    }
                    return state;
                }
                case actions.DELETE_MIME_TYPE_STATS: {
                    return {};
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }
}

export class JobOperationMimeTypeStatsApi {

    static getOperationMimeTypeStats(id) {
        return axiosInstance.get(`/scheduler/jobs/${id}/operationMimeTypeStats`)
    }
}

export class JobOperationMimeTypeStatsSaga {

    static* pollSettings(action) {
        const {pollingDelay} = action.payload;

        yield contextPollUntil(JobOperationMimeTypeStatsModel.actions.STOP_POLLING_SETTINGS, pollingDelay, this, 'querySettings', action);
    }

    static* querySettings(action) {
        const {jobId} = action.payload;
        if (jobId == null) {
            return;
        }

        try {
            const {data} = yield contextCall(JobOperationMimeTypeStatsApi, 'getOperationMimeTypeStats', jobId)
            yield put(JobOperationMimeTypeStatsModel.actionCreators.setOperationMimeTypeStats(data));
        } catch (e) {
            //Execution log is unavailable
        }
    }
}

export default JobOperationMimeTypeStatsModel;