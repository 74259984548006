import React from 'react';
import './SelectableItem.css';
import Checkbox from "../Checkbox/Checkbox";
import {buildClassName, getGeneralizedItemKey} from "../../../utilities/helperFunctions";
import Text from "../Text/Text";
import {userIconModel} from "../../../models/generics/IconModel";
import UserModel from "../../../models/user/UserModel";
import {useTranslation} from "react-i18next";

export const NameSelectableItem = props => {
    const {item, isEllipsis=true, isWordWrap, isNoWrap, isItalic, isDisabled, ...attr} = props;

    const key = getGeneralizedItemKey(item);
    return (
        <SelectableItem name={item.name} value={key} data-alias={item.alias} {...attr}
            isDisabled={isDisabled}
        >
            <Text value={item.name || item.value} isEllipsis={isEllipsis} isWordWrap={isWordWrap} isNoWrap={isNoWrap}
                isItalic={isItalic} isDisabled={isDisabled}/>
        </SelectableItem>
    )
}

export const TypeSelectableItem = props => {
    const {item, isEllipsis=true, isWordWrap, isNoWrap, isDisabled, isCentered, ...attr} = props;

    const key = getGeneralizedItemKey(item);
    return (
        <SelectableItem name={item.name} value={key} {...attr} className={'selectable-item__type'}
            isDisabled={isDisabled}
        >
            <Text value={item.type} isItalic isDisabled={isDisabled}/>

            <Text value={item.name} isEllipsis={isEllipsis} isWordWrap={isWordWrap} isNoWrap={isNoWrap}
                isDisabled={isDisabled}/>
        </SelectableItem>
    )
}

export const CheckedSelectableItem = props => {
    const {item, isEllipsis=true, isWordWrap, isNoWrap, isDisabled, ...attr} = props;
    const key = getGeneralizedItemKey(item);

    return (
        <SelectableItem name={item.name} value={key} {...attr}
            aria-checked={item.isChecked} isDisabled={isDisabled}
        >
            <Checkbox label={item.name} checked={item.isChecked} isEllipsis={isEllipsis}
                isWordWrap={isNoWrap == null ? isWordWrap : !isNoWrap} isDisabled={isDisabled} tabIndex={-1}
            />
        </SelectableItem>
    )
}

export const IconSelectableItem = props => {
    const {item, isLeft=true, isNoWrap, isItalic, isDisabled, ...attr} = props;

    const key = getGeneralizedItemKey(item);
    const disabled = isDisabled ? ' is-disabled' : '';

    return (
        <SelectableItem name={item.name} value={key} {...attr}
            isDisabled={isDisabled}
        >
            {isLeft &&
            <span className="icon is-small" style={{marginRight: '0.5rem'}}>
                {item.icon}
            </span>
            }

            <label className={'label is-ellipsis' + disabled}>
                {item.name}
            </label>

            {!isLeft &&
            <span className="icon is-small" style={{marginLeft: '0.5rem'}}>
                {item.icon}
            </span>
            }
        </SelectableItem>
    )
}

export const UserSelectableItem = props => {
    const {t} = useTranslation(['aria']);
    const {item, isEllipsis=true, isWordWrap, isNoWrap, isDisabled, ...attr} = props;

    const key = getGeneralizedItemKey(item);
    const [username, userIconName] = UserModel.useUserNameAndIconName(item.name);
    const icon = userIconModel.useIcon(userIconName);

    const labelId = `label:${attr.id || ''}:${username}`;

    return (
        <SelectableItem name={item.name} value={key} {...attr}
            aria-checked={item.isChecked} isDisabled={isDisabled}
        >
            <Checkbox checked={item.isChecked} aria-labelledby={labelId}
                tabIndex={-1} isDisabled={isDisabled}/>

            <span className="icon is-small" style={{flexShrink: 0, margin: '0 0.5rem'}}>
                <img className="round-image" src={icon} alt={t('aria:hiddenAssistText.userIcon')}/>
            </span>

            <Text id={labelId} value={username} isEllipsis={isEllipsis} isWordWrap={isWordWrap}
                isNoWrap={isNoWrap} isDisabled={isDisabled}/>
        </SelectableItem>
    )
}

export const UserEmailSelectableItem = props => {
    const {item, isEllipsis=true, isWordWrap, isNoWrap, isDisabled, ...attr} = props;
    const key = getGeneralizedItemKey(item);

    return (
        <SelectableItem name={item.name} value={key} {...attr} className={'selectable-item__email'}
            isDisabled={isDisabled}
        >
            <Text value={item.name} isEllipsis={isEllipsis} isWordWrap={isWordWrap} isNoWrap={isNoWrap}
                isDisabled={isDisabled}/>

            <span className="dot-span"/>

            <Text value={item.email} isEllipsis={isEllipsis} isWordWrap={isWordWrap} isNoWrap={isNoWrap}
                isDisabled={isDisabled}/>
        </SelectableItem>
    )
}

export const FileSelectableItem = props => {
    const {name, onFileChange, isNoWrap, isDisabled, ...attr} = props;

    const disabled = isDisabled ? ' is-disabled' : '';
    const wrap = isNoWrap ? ' no-wrap' : '';

    return (
        <SelectableItem isDisabled={isDisabled} {...attr}>
            <label className={'file-label' + disabled}>
                <label className={'label' + wrap}>
                    {name}
                    <input className="file-input" type="file" onChange={onFileChange} disabled={isDisabled}/>
                </label>
            </label>
        </SelectableItem>
    )
}

export const FileLibrarySelectableItem = props => {
    const {item, isEllipsis=true, isWordWrap, isNoWrap, isDisabled, ...attr} = props;

    const isHeader = item.isHeader;
    const headerClassName = buildClassName('no-hover', 'no-pointer', 'no-focus');

    const onItemClick = isHeader ? item.onItemClick : attr.onItemClick;

    return (
        <SelectableItem name={item.name} value={isHeader ? "" : item.value} data-type={isHeader ? null : item.fileType} {...attr}
                        className={isHeader ? headerClassName : ""} onItemClick={onItemClick}>

            <Text value={item.isHeader ? item.name : item.fileName} title={item.hoverTitle} isEllipsis={isEllipsis}
                isWordWrap={isWordWrap} isNoWrap={isNoWrap} isItalic={isHeader} isDisabled={isDisabled && !isHeader}
                style={isHeader ? {} : {marginLeft: '0.375rem'}}/>
        </SelectableItem>
    )
}

export function SelectableItem(props) {
    const {role='option', className, children, index, name, value, onItemClick, isSelected, isCentered,
        isBorder, isUnselectable, isColumn, isSpaced, isRequired, isInvalid, isDisabled, ...attr} = props;

    function onClick(e) {
        if (!isDisabled && typeof onItemClick === 'function') {
            onItemClick(e);
        }
    }

    function onKeyPress(e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            onClick(e);
        }
    }

    const itemClassName = buildClassName(
        'selectable-item',
        className,
        isRequired && 'is-required',
        isInvalid && 'is-invalid',
        isSelected && 'is-selected',
        isUnselectable && 'is-unselectable',
        isBorder && 'is-bordered',
        isCentered && 'is-centered',
        isDisabled && 'is-disabled',
        isColumn && 'is-column',
        isSpaced && 'is-spaced'
    );

    return (
        <div role={role} className={itemClassName} data-name={name} data-value={value} data-index={index}
            aria-selected={isSelected} onClick={onClick} onKeyDown={onKeyPress}
            {...attr} tabIndex={isDisabled ? -1 : 0}
        >
            {children}
        </div>
    )
}
