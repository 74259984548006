import React, {Component, useRef, useState} from 'react';
import './InputList.css';
import {
    filterArrayIndices,
    getNewArrayWithUpdatedValue,
    isNotEmptyNorFalsy,
    objectTruthyValues
} from '../../../utilities/helperFunctions';
import {AddRemoveButtons} from "../Button/Button";
import {
    initialSelectedState,
    useClearSelectedEffect,
    useTabNavigateEffect,
    useValueSelectHandler
} from "../../../utilities/hooks";
import HTMLTextInput from "../HTMLTextInput/HTMLTextInput";

// values -> array of values ['', '', ...]
export function ValueList(props) {

    const {
        id,
        ariaLabelKey,
        label,
        itemAriaLabel,

        values,
        setValues,

        isRequired,
        isReadOnly,
        isDisabled,
        inputPlaceholder
    } = props;

    const containerRef = useRef();
    const [selected, setSelected] = useState(initialSelectedState);

    const valueSelectHandler = useValueSelectHandler({setSelected});
    useClearSelectedEffect({containerRef, setSelected});
    useTabNavigateEffect({containerRef});

    function addInput() {
        setValues(prevValues => {
            // Add empty value
            return [
                ...prevValues,
                ''
            ];
        });
    }

    function removeInput() {
        setValues(prevValues => {
            // Remove all selected indices
            const selectedIndices = objectTruthyValues(selected.values);
            return filterArrayIndices(prevValues, selectedIndices);
        });

        setSelected(prevSelected => {
            return {
                ...prevSelected,
                values: {},
                lastSelectedValue: null
            }
        });
    }

    function inputHandler(event) {
        const {dataset: {index}, value} = event.target;
        setValues(prevValues => {
            return getNewArrayWithUpdatedValue(prevValues, value, index);
        });
    }


    const canRemove = objectTruthyValues(selected.values).length > 0;
    const required = (isRequired && !isNotEmptyNorFalsy(values)) ? ' is-required' : '';

    return (
        <div id={id} ref={containerRef}>
            <AddRemoveButtons label={label} ariaLabelKey={ariaLabelKey} onAddClick={addInput} onRemoveClick={removeInput}
                isRemoveDisabled={!canRemove} isReadOnly={isReadOnly} isDisabled={isDisabled}/>

            <div role="list" className={'inputList-container' + required}>
                {values.map((value, index) => {
                    return (
                        <HTMLTextInput role="listitem" key={index} data-index={index} containerClassName="input-list-container"
                            id={`${id}${index}`} placeholder={inputPlaceholder} value={value} onChange={inputHandler}
                            aria-label={label || itemAriaLabel} onClick={valueSelectHandler} isSelected={selected.values[index]}
                            isDisabled={isDisabled}/>
                    )
                })}
            </div>
        </div>
    )
}

class InputList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            lastAccessedIndex: null,
            selectedIndexSet: {}
        };

        this.containerRef = React.createRef();

        this.onInputFocus = this.onInputFocus.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.clearSelected = this.clearSelected.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.clearSelected);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.clearSelected);
    }

    onInputFocus(event) {
        const { value: index } = event.currentTarget.dataset;
        const keyPressed = {
            ctrl: event.ctrlKey,
            shft: event.shiftKey
        };

        this.setState(prevState => {
            const selectedIndexSet = (keyPressed.ctrl || keyPressed.shft) ? {...prevState.selectedIndexSet} : {};
            selectedIndexSet[index] = !(keyPressed.ctrl && selectedIndexSet[index]);

            if (keyPressed.shft && prevState.lastAccessedIndex) {
                const indices = index > prevState.lastAccessedIndex ? {start: prevState.lastAccessedIndex, end: index} : {start: index, end: prevState.lastAccessedIndex};
                for (let i = indices.start; i < indices.end; i++) {
                    selectedIndexSet[i] = true;
                }
            }
            return {
                lastAccessedIndex: selectedIndexSet[index] ? index : null,
                selectedIndexSet
            };
        })
    }

    clearSelected(event) {
        if (!this.containerRef.current.contains(event.target)) {
            this.setState({
                selectedIndexSet: {}
            });
        }
    }

    onDeleteClick() {
        const selectedIndices = objectTruthyValues(this.state.selectedIndexSet);

        this.props.onDeleteClick(selectedIndices);
        this.setState({
            selectedIndexSet: {}
        });
    }

    render() {
        const {id, label, type = 'text', values, onValueChange, onAddClick, isRequired, isDisabled} = this.props;
        const {selectedIndexSet} = this.state;

        const required = (isRequired && values.every(value => !value)) ? ' is-required' : '';
        const selectedLength = objectTruthyValues(selectedIndexSet).length;

        return (
            <div id={id} ref={this.containerRef}>
                <AddRemoveButtons label={label} ariaLabelKey={this.props.ariaLabelKey} onAddClick={onAddClick}
                    onRemoveClick={this.onDeleteClick} isRemoveDisabled={selectedLength === 0} isDisabled={isDisabled}/>

                <div role="list" className={'inputList-container' + required}>
                    {values.map((value, index) => {
                        return (
                            <HTMLTextInput role="listitem" id={`${id}${index}`} key={index} type={type}
                                containerClassName={'input-list-container'} aria-label={label}
                                data-value={index} value={value} onChange={onValueChange}
                                onMouseDown={this.onInputFocus}
                                isSelected={selectedIndexSet[index]} isDisabled={isDisabled}/>
                        )
                    })}
                </div>
            </div>
        );
    }
}

export default InputList;