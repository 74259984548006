import React from "react";
import CurrentUserModel from "../../../models/user/CurrentUserModel";
import UserModel from "../../../models/user/UserModel";
import {useDispatch} from "react-redux";
import {userIconModel} from "../../../models/generics/IconModel";
import {useTranslation} from "react-i18next";
import Dropdown from "../../common/Dropdown/Dropdown";
import {NavBarLinkMenuItem} from "./NavBarLinkMenu";
import {routes} from "../../../utilities/constants";


function UserMenu(props) {
    const {t} = useTranslation(['scheduler']);
    const dispatch = useDispatch();

    const {
        currentUser,
        hideLogoutButton,
        externalApps
    } = props;

    const [, userIconName] = UserModel.useUserNameAndIconName(currentUser.name);
    const userIcon = userIconModel.useIcon(userIconName);

    function onLogout() {
        dispatch(CurrentUserModel.actionCreators.safeLogout())
    }

    const menuItems = [];
    if (Array.isArray(externalApps)) {
        for (let i = 0; i < externalApps.length; i++) {
            menuItems.push(
                <ExternalAppItem key={`externalAppItem${i}`} app={externalApps[i]}/>
            );
        }
    }
    if (menuItems.length > 0) {
        menuItems.push(
            <div key={'externalAppsSeparator'} className="navbar-menu-item-separator">
                <span/>
            </div>
        );
    }
    if (!hideLogoutButton) {
        menuItems.push(
            <NavBarLinkMenuItem id="userMenuLogoutItem" key={'logoutItem'} text={t(`scheduler:option.logout`)}
                to={routes.LOGIN} onClick={onLogout}/>
        );
    }

    return (
        <div className="navbar-user-menu">
            <Dropdown id={'userMenuDropdown'} aria-label={t('aria:hiddenAssistText.userMenu')}
                isTransparent isIconDropdown enableVirtualRendering={false} isRight
                icon={
                    <span className="navbar-icon si-24">
                        <img className="round-image" src={userIcon}
                            alt={t('aria:hiddenAssistText.userMenuIcon')}/>
                    </span>
                }
                items={menuItems}
            />
            <span className="navbar-selected-bar"/>
        </div>
    )
}

function ExternalAppItem(props) {
    const {
        app
    } = props;

    function onKeyPress(e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            e.target.blur();
            e.target.click();
        }
    }

    return (
        <a className="navbar-menu-item" title={app.url || app.name} tabIndex={0}
            onKeyDown={onKeyPress} href={app.url} target="_blank" rel="noopener noreferrer"
        >
            <p className="navbar-label">
                {app.name}
            </p>
        </a>
    )
}

export default UserMenu;
