import React, {useCallback, useRef, useState} from "react";
import "./KeyValueList.css";
import {AddRemoveButtons} from "../Button/Button";
import {useTranslation} from "react-i18next";
import {filterArrayIndices, getNewArrayWithUpdatedValue, objectTruthyValues} from "../../../utilities/helperFunctions";
import {
    initialSelectedState,
    useClearSelectedEffect,
    useKeySelectHandler,
    useMoveUpDown,
    useTabNavigateEffect,
    useValueSelectHandler
} from "../../../utilities/hooks";
import NavigateButtons from "../NavigateButtons/NavigateButtons";
import Text, {Label} from "../Text/Text";
import HTMLTextInput from "../HTMLTextInput/HTMLTextInput";
import {getInvalidMessageSpan} from "../InvalidMessageSpan";

// entries -> array of entries ('' , [])
function KeyValueList(props) {

    const containerRef = useRef();
    const [selected, setSelected] = useState(initialSelectedState);

    const keySelectHandler = useKeySelectHandler({setSelected, clickCount: 2});
    const valueSelectHandler = useValueSelectHandler({setSelected, key: selected.key});
    useClearSelectedEffect({containerRef, setSelected});
    useTabNavigateEffect({containerRef});

    const {
        id,
        ariaLabelKey,
        columnAriaLabels,

        label,
        entries,
        setEntries,
        keysTitle,
        valuesTitle,
        blurHandler,
        isReadOnly,
        isDisabled,
        isMoveEnabled=false,
        ...attr
    } = props;

    const [moveUp, moveDown] = useMoveUpDown({selected, setSelected, setArray: updateList});
    function updateList (update) {
        setEntries(prevEntries => {
            const entries = [...prevEntries];
            const [key, values] = entries[selected.key];
            const updatedValues = update(values);

            entries[selected.key] = [key, updatedValues];
            return entries;
        });
    }

    // Update value of entry's key
    const keyInputHandler = useCallback(event => {
        const {dataset: {index}, value: newName} = event.target;

        setEntries(prevEntries => {

            const values = prevEntries[index][1];
            return getNewArrayWithUpdatedValue(prevEntries, [newName, values], index);
        });
    }, [setEntries]);

    const keyBlurHandler = useCallback(event => {
        const {dataset: {index}, value: name} = event.target;

        if (blurHandler == null)
            return;

        setEntries(prevEntries => {
            const values = prevEntries[index][1];
            const newName = blurHandler(name);

            return getNewArrayWithUpdatedValue(prevEntries, [newName, values], index);
        });
    }, [setEntries, blurHandler]);

    // Update value of selected entry's value with index
    const valueInputHandler = useCallback(event => {
        const {dataset: {index}, value} = event.target;

        setEntries(prevEntries => {

            const [key, values] = prevEntries[selected.key];
            const newValues = getNewArrayWithUpdatedValue(values, value, index);

            return getNewArrayWithUpdatedValue(prevEntries, [key, newValues], selected.key)
        });
    }, [selected, setEntries]);

    // Add entry with empty key and value
    const addKey = useCallback(() => {
        setEntries(prevEntries => {

            return [
                ...prevEntries,
                ['', []]
            ]
        });
    }, [setEntries]);

    // Remove selected entry
    const removeKey = useCallback(() => {
        let newEntriesLength;

        setEntries(prevEntries => {

            const entries = [...prevEntries];
            entries.splice(selected.key, 1);

            newEntriesLength = entries.length;
            return entries;
        });

        setSelected(prevSelected => {
            let index = parseInt(prevSelected.key);

            // If index is >= newEntriesLength set it to the last index; otherwise keep it as same
            if (index >= newEntriesLength) {
                index = newEntriesLength - 1;
            }

            return {
                ...prevSelected,
                key: index.toString(),
                values: {},
                lastSelectedValue: null
            }
        });
    }, [selected, setSelected, setEntries]);

    // Add empty value to selected entry
    const addValue = useCallback(() => {
        setEntries(prevEntries => {

            const [key, values] = prevEntries[selected.key];
            const newValues = [
                ...values,
                ''
            ];

            return getNewArrayWithUpdatedValue(prevEntries, [key, newValues], selected.key);
        });
    }, [selected, setEntries]);

    // Remove selected value(s) from entry
    const removeValue = useCallback(() => {
        setEntries(prevEntries => {
            const entries = [...prevEntries];
            const [key, values] = entries[selected.key];

            const selectedIndices = objectTruthyValues(selected.values);
            const newValues = filterArrayIndices(values, selectedIndices);

            entries[selected.key] = [key, newValues];
            return entries;
        });

        setSelected(prevSelected => {
            return {
                ...prevSelected,
                values: {},
                lastSelectedValue: null
            }
        })
    }, [selected, setSelected, setEntries]);


    return (
        <>
            {label &&
                <Label value={label} isDisabled={isDisabled}/>
            }

            <div id={id} className="value-list" ref={containerRef} {...attr}>
                <KeyBox id={id} title={keysTitle} keys={entries.map(entry => entry[0])} addKey={addKey} removeKey={removeKey}
                    inputHandler={keyInputHandler} blurHandler={keyBlurHandler} selected={selected} selectHandler={keySelectHandler}
                    ariaLabelKey={ariaLabelKey} isReadOnly={isReadOnly} isDisabled={isDisabled}/>

                <ValuesBox id={id} title={valuesTitle} values={(entries[selected.key] || [])[1]} addValue={addValue} removeValue={removeValue}
                    ariaLabelKey={'Value'} inputHandler={valueInputHandler} selected={selected} selectHandler={valueSelectHandler}
                    isReadOnly={isReadOnly} isDisabled={isDisabled} moveUp={moveUp} moveDown={moveDown} isMoveEnabled={isMoveEnabled}/>
            </div>
        </>
    )
}

function KeyBox(props) {
    const {t} = useTranslation(['common']);
    const {id, ariaLabelKey, keys, addKey, removeKey, selected,
        selectHandler, inputHandler, blurHandler, isReadOnly, isDisabled} = props;

    const canRemove = parseInt(selected.key) >= 0;
    const title = props.title || t('common:label.name');

    return (
        <div className="list-box">

            <div className={'box-header no-padding'}>
                <AddRemoveButtons label={title} ariaLabelKey={ariaLabelKey} onAddClick={addKey} onRemoveClick={removeKey}
                    isRemoveDisabled={!canRemove} isReadOnly={isReadOnly} isDisabled={isDisabled}
                />
            </div>

            <div role="list" className="input-box">
                {keys.map((key, index) => {
                    index = index.toString()

                    const isSelected = (selected.key === index);

                    const readOnly = isReadOnly || !isSelected || selected.clickCount < 1;
                    const pointer = (isReadOnly || !isSelected) && 'is-pointer';

                    return (
                        <HTMLTextInput role="listitem" id={`${id}${index}`} key={index} containerClassName={'input-list-container'} className={pointer}
                            data-index={index} value={key} onChange={inputHandler} onBlur={blurHandler} onClick={isDisabled ? null : selectHandler}
                            aria-label={title} isSelected={isSelected} isDisabled={isDisabled} readOnly={readOnly}/>
                    )
                })}
            </div>
        </div>
    )
}

export function ValuesBox(props) {
    const {t} = useTranslation(['common']);
    const {id, ariaLabelKey, values, addValue, removeValue, selected, selectHandler, inputHandler, isReadOnly,
        isRequired, moveUp, moveDown, isMoveEnabled, isDisabled} = props;

    const canAdd = values != null
    const canRemove = objectTruthyValues(selected.values).length > 0
    const title = props.title || t('common:label.value_plural');

    const required = isRequired && values?.length === 0;
    const selectedIndices = objectTruthyValues(selected.values);

    const {
        invalidMessageSpan
    } = getInvalidMessageSpan({
        id,
        isRequired: required
    });


    return (
        <div id={id} className={'list-box' + (required ? ' is-required' : '')}>
            <div className="box-header no-padding flex-center" style={{display: 'inline-flex'}}>
                <AddRemoveButtons ariaLabelKey={ariaLabelKey} onAddClick={addValue} isAddDisabled={!canAdd} onRemoveClick={removeValue}
                    isRemoveDisabled={!canRemove} isReadOnly={isReadOnly} isDisabled={isDisabled} noPadding
                />

                {(isMoveEnabled && !isReadOnly) &&
                    <NavigateButtons ariaLabelKey={ariaLabelKey}
                        onClick={[moveUp, moveDown]} length={[selectedIndices.length, selectedIndices.length]}
                        isVertical isDisabled={isDisabled} style={{justifyContent: 'left', flex: "0", marginRight: '0.25rem'}}/>
                }

                {!isReadOnly &&
                    <Text value={title} style={{justifyContent: 'left'}} isDisabled={isDisabled}/>
                }
            </div>

            <div role="list" className="input-box">
                {values && values.map((value, index) => {
                    return (
                        <HTMLTextInput role="listitem" id={`${id}${index}`} key={index} containerClassName={'input-list-container'} value={value}
                            onChange={inputHandler} onClick={isDisabled ? null : selectHandler} data-index={index} data-key={selected.key}
                            aria-label={title} isSelected={selected.values[index]} isDisabled={isDisabled} isReadOnly={isReadOnly}/>
                    )
                })}
            </div>

            {invalidMessageSpan}
        </div>
    )
}

export default KeyValueList;