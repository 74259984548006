import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {getLocaleDateTimeFromUTC} from "../../utilities/helperFunctions";
import {TableLabelCell, TableRow} from "../common/CustomTable/CustomTable";
import React from "react";
import Text from "../common/Text/Text";
import {icon} from "../../utilities/iconResolver";


export function selectNamesFromUserNotice(state, userNotice) {
    const legalHold = state.legalHoldDetailsMap.get(userNotice.legalHoldId);
    const client = state.clientDetailsMap.get(legalHold?.clientId);
    const matter = state.matterDetailsMap.get(legalHold?.matterId);
    const user = state.userDetailsMap.get(userNotice.userId);

    return {
        userDisplayName: user?.displayName,
        user: user?.name,
        legalHold: legalHold?.name,
        client: client?.name,
        matter: matter?.name
    };
}

function UserNoticeRow(props) {
    const {t} = useTranslation(['noticeTemplate']);

    const {
        isActive,
        userNotice,
        onSelect,
        ...attr
    } = props;

    const names = useSelector(state => selectNamesFromUserNotice(state, userNotice));

    const {
        type,

        subject,
        sentDate,
        lastRespondedDate,
        escalatedDate
    } = userNotice;

    return (
        <TableRow value={userNotice.id} isActive={isActive} onClick={onSelect} {...attr}>
            <div className="table-cell no-stretch">
                <span className={'icon'}>
                    <img src={icon(`noticeType${type}`)} alt={t(`aria:hiddenAssistText.${type}NoticeIcon`)}/>
                </span>
            </div>

            <div className="table-cell no-stretch">
                {userNotice.getStateKeys().map(stateKey =>
                    <Text key={stateKey} value={t(`noticeTemplate:state.${stateKey}`)}/>
                )}
            </div>

            <TableLabelCell aria-labelledby={'noticeTable:user'} label={names.userDisplayName || names.user}/>

            <TableLabelCell aria-labelledby={'noticeTable:legalHold'} label={names.legalHold}/>

            <TableLabelCell aria-labelledby={'noticeTable:subject'} label={subject}/>

            <TableLabelCell aria-labelledby={'noticeTable:sentDate'} label={getLocaleDateTimeFromUTC(sentDate)}/>

            <TableLabelCell aria-labelledby={'noticeTable:respondedDate'} label={getLocaleDateTimeFromUTC(lastRespondedDate)}/>

            <TableLabelCell aria-labelledby={'noticeTable:escalatedDate'} label={getLocaleDateTimeFromUTC(escalatedDate)}/>
        </TableRow>
    )
}

export default UserNoticeRow;
