import React from 'react';
import './ProgressRing.css'

const ProgressRing = ({percentageComplete, size}) => {
  return (
    <div className={'progress-' + size + '-radial progress-' + Math.round(percentageComplete)}>
      <div className={'progress-' + size + '-overlay'}/>
    </div>
  );
};

export default ProgressRing;