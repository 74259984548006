import React, {Component} from 'react';
import {connect} from 'react-redux';
import {useTranslation, withTranslation} from "react-i18next";
import {getSafeClose, onInputChange, warnOnClose} from "../../utilities/componentFunctions";
import {onToggleEnable} from "../common/Checkbox/helpers";
import TextArea from "../common/TextArea/TextArea";
import Checkbox from "../common/Checkbox/Checkbox";
import {PolicyPrincipalTypeDropdown, PolicyScopeTypeDropdown} from "../common/Dropdown/Dropdown";
import {
    getBuiltInPolicyPrincipalValueTypes,
    getBuiltInPolicyScopeNameValueTypes,
    getClientNameValueTypes,
    getClientPoolNameValueTypes,
    getDataRepositoryNameValueTypes,
    getExecutionProfileNameValueTypes,
    getFileLibraryNameValueTypes,
    getLegalHoldNameValueTypes,
    getLibraryNameValueTypes,
    getMatterNameValueTypes,
    getNoticeTemplateNameValueTypes,
    getNotificationRuleNameValueTypes,
    getNuixLicenceSourceNameValueTypes,
    getResourcePoolNameValueTypes,
    getThirdPartyServiceNameValueTypes,
    getUserServiceNameValueTypes,
    getWorkflowTemplateNameValueTypes,
} from "../../reselect/selectors";
import ListContainer, {SwitchListContainers} from "../common/ListContainer/ListContainer";
import {getValues, nameLocaleCompare, objectTruthyValues, switchcase} from "../../utilities/helperFunctions";
import {
    addInputToList,
    addItemsToList,
    addScopesToList,
    getScopeChildItems,
    getScopeItems,
    getScopeParentItems,
    getSelectedParents,
    onAddItemsToList,
    onSelectListItem,
    removeInputFromList,
    removeScopesFromList
} from "../common/ListContainer/helpers";
import {TypeSelectableItem} from "../common/SelectableItem/SelectableItem";
import Form, {FormFooter, FormHeader} from "../common/Form/Form";
import {shouldEnablePolicy} from "../../utilities/shouldEnableFunctions";
import {
    policyPermissionKeys,
    policyPrincipalTypeKeys,
    policyScopeTypeKeys,
    policyScopeTypeOptionKeys
} from "../../i18next/keys";
import {updateMode} from "../../utilities/constants";
import PopupModel from "../../models/scheduler/PopupModel";
import PolicyModel from "../../models/settings/PolicyModel";
import SchedulerModel from "../../models/scheduler/SchedulerModel";
import ExpandableContent from "../common/ExpandableContent/ExpandableContent";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import i18n from "../../i18next/i18n";

class PolicyForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            policyName: '',
            description: '',
            enabled: true,
            principalType: null,
            availablePrincipals: {
                inputValue: '',
                items: [...props.principalValues[policyPrincipalTypeKeys.BUILTIN]],
                selectedItems: {}
            },
            selectedPrincipals: {
                items: [],
                selectedItems: {}
            },
            availablePermissions: {
                items: getValues(policyPermissionKeys)
                    .map(value => {return {name: i18n.t(`common:permission.${value}`), value}})
                    .sort(nameLocaleCompare)
                ,
                selectedItems: {}
            },
            selectedPermissions: {
                items: [],
                selectedItems: {}
            },
            scopeType: null,
            availableScopeParents: {
                items: [],
                selectedItems: {}
            },
            availableScopes: {
                items: [],
                selectedItems: {}
            },
            selectedScopes: {
                items: [],
                selectedItems: {}
            },
            expanded: {
                principals: true,
                permissions: true,
                scope: true
            },
            isAddEnabled: false
        };

        this.getSafeClose = getSafeClose('state', ['expanded', 'enabled', 'availablePrincipals', 'availablePermissions']).bind(this);
        this.warnOnClose = warnOnClose(this.props.t('policy:label.name_simple')).bind(this);

        this.onAddClick = this.onAddClick.bind(this);
        this.onSelectPrincipalType = this.onSelectPrincipalType.bind(this);
        this.onInputChangePrincipal = this.onInputChangePrincipal.bind(this);
        this.onAddPrincipals = this.onAddPrincipals.bind(this);
        this.onRemovePrincipals = this.onRemovePrincipals.bind(this);

        this.onSelectAvailablePrincipals = onSelectListItem({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'availablePrincipals'
        }).bind(this);
        this.onSelectSelectedPrincipals = onSelectListItem({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'selectedPrincipals'
        }).bind(this);

        this.onAddPermissions = onAddItemsToList({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listTo: 'selectedPermissions',
            listFrom: 'availablePermissions'
        }).bind(this);
        this.onRemovePermissions = onAddItemsToList({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listTo: 'availablePermissions',
            listFrom: 'selectedPermissions'
        }).bind(this);
        this.onSelectAvailablePermissions = onSelectListItem({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'availablePermissions'
        }).bind(this);
        this.onSelectSelectedPermissions = onSelectListItem({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'selectedPermissions'
        }).bind(this);

        this.onAddScopes = this.onAddScopes.bind(this);
        this.onRemoveScopes = this.onRemoveScopes.bind(this);
        this.onSelectScopeType = this.onSelectScopeType.bind(this);
        this.onSelectScopeParent = this.onSelectScopeParent.bind(this);

        this.onSelectAvailableScopes = onSelectListItem({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'availableScopes'
        }).bind(this);
        this.onSelectSelectedScopes = onSelectListItem({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            listName: 'selectedScopes'
        }).bind(this);

        this.onInputChange = onInputChange({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState,
            isEnabled: 'isAddEnabled',
            shouldEnable: shouldEnablePolicy
        }).bind(this);
        this.onToggleEnable = onToggleEnable({
            mode: updateMode.LOCAL,
            stateUpdater: this.setState
        }).bind(this);

        this.onToggleExpanded = this.onToggleExpanded.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const type = switchcase({
            [policyScopeTypeOptionKeys.CLIENT_ID_MATTER_ID]: policyScopeTypeKeys.MATTER_ID,
            [policyScopeTypeOptionKeys.LIBRARY_ID_WORKFLOW_ID]: policyScopeTypeKeys.WORKFLOW_ID
        })()(prevState.scopeType);

        if (this.props.scopeValues[type] !== prevProps.scopeValues[type]) {
            this.setState(prevState => {
                const {selectedScopes, availableScopeParents, scopeType} = prevState;
                const items = getScopeChildItems.call(this, scopeType, selectedScopes, availableScopeParents);

                return {
                    availableScopes: {
                        ...prevState.availableScopes,
                        items
                    }
                };
            });
        }
    }

    onAddClick() {
        this.props.submitPolicy(this.state);
    }

    onSelectPrincipalType(event) {
        const {value} = event.currentTarget.dataset;
        //To reset selectedItems
        this.setState(prevState => ({
            principalType: value,
            availablePrincipals: {
                ...prevState.availablePrincipals,
                selectedItems: {},
            }
        }));
    }

    onInputChangePrincipal(event) {
        const {value} = event.target;

        this.setState(prevState => ({
            availablePrincipals: {
                ...prevState.availablePrincipals,
                inputValue: value
            }
        }));
    }

    onAddPrincipals() {
        this.setState(prevState => {
            const {selectedPrincipals: prevTo, availablePrincipals: prevFrom, principalType} = prevState;
            const {to, from} = addItemsToList(prevTo, prevFrom);

            if (principalType !== policyPrincipalTypeKeys.BUILTIN) {
                addInputToList(to, prevFrom, principalType);
            }

            return {
                availablePrincipals: {
                    ...prevFrom,
                    items: from,
                    selectedItems: {},
                    inputValue: ''
                },
                selectedPrincipals: {
                    ...prevTo,
                    items: to
                }
            };
        });
    }

    onRemovePrincipals() {
        this.setState(prevState => {
            const {availablePrincipals: prevTo, selectedPrincipals: prevFrom, principalType} = prevState;
            const {to, from, inputValue} = removeInputFromList(prevTo, prevFrom, principalType, policyPrincipalTypeKeys.BUILTIN);

            return {
                availablePrincipals: {
                    ...prevTo,
                    items: to,
                    inputValue
                },
                selectedPrincipals: {
                    ...prevFrom,
                    items: from,
                    selectedItems: {}
                }
            }
        });
    }

    onSelectScopeType(event) {
        const {value: scopeType} = event.currentTarget.dataset;

        this.setState(prevState => {
            const parentItems = getScopeParentItems.call(this, scopeType, prevState.selectedScopes);
            const items = getScopeItems.call(this, scopeType, prevState.selectedScopes);

            return {
                scopeType,
                availableScopeParents: {
                    ...prevState.availableScopeParents,
                    items: parentItems,
                    selectedItems: {}
                },
                availableScopes: {
                    ...prevState.availableScopes,
                    items,
                    selectedItems: {}
                }
            }
        });
    }

    onSelectScopeParent(event) {
        const selectedParents = getSelectedParents.call(this, event, this.state.availableScopeParents.items);

        this.setState(prevState => {
            const {selectedScopes, scopeType} = prevState;
            const items = getScopeChildItems.call(this, scopeType, selectedScopes, selectedParents);

            return {
                availableScopeParents: {
                    ...prevState.availableScopeParents,
                    selectedItems: selectedParents.selectedItems
                },
                availableScopes: {
                    ...prevState.availableScopes,
                    items,
                    selectedItems: {}
                }
            };
        });
    }

    onAddScopes() {
        this.setState(prevState => {
            const {selectedScopes: prevTo, availableScopes: prevFrom, availableScopeParents: prevParents} = prevState;
            const {to, from, parents} = addScopesToList.call(this, prevTo, prevFrom, prevParents);

            return {
                availableScopeParents: {
                    ...prevParents,
                    ...parents
                },
                availableScopes: {
                    ...prevFrom,
                    items: from,
                    selectedItems: {}
                },
                selectedScopes: {
                    ...prevTo,
                    items: to
                }
            }
        });
    }

    onRemoveScopes() {
        this.setState(prevState => {
            const {availableScopes: prevTo, selectedScopes: prevFrom, availableScopeParents: prevParents, scopeType} = prevState;
            const {to, from, parentItems} = removeScopesFromList.call(this, prevTo, prevFrom, prevParents, scopeType);

            return {
                availableScopeParents: {
                    ...prevParents,
                    items: parentItems
                },
                availableScopes: {
                    ...prevTo,
                    items: to
                },
                selectedScopes: {
                    ...prevFrom,
                    items: from,
                    selectedItems: {}
                }
            }
        });
    }

    onToggleExpanded(name, isExpanded) {
        this.setState(prev => ({
            expanded: {
                ...prev.expanded,
                [name]: isExpanded
            }
        }));
    }

    render() {

        const {
            t,
            isDisabled
        } = this.props;
        const {
            policyName,
            description,
            enabled,
            principalType,
            availablePrincipals,
            selectedPrincipals,
            availablePermissions,
            selectedPermissions,
            scopeType,
            availableScopeParents,
            availableScopes,
            selectedScopes,
            expanded,
            isAddEnabled
        } = this.state;
        const close = this.getSafeClose();

        const nameInputProps = {
            label: t('common:label.name'),
            name: 'policyName',
            value: policyName,
            onChange: this.onInputChange,
            isRequired: true,
            isDisabled
        };
        const enabledCheckboxProps = {
            label: t('common:label.enabled'),
            name: 'enabled',
            checked: enabled,
            onClick: this.onToggleEnable,
            isDisabled
        };
        const descriptionInputProps = {
            label: t('common:label.description'),
            name: 'description',
            value: description,
            onChange: this.onInputChange,
            isDisabled
        };

        const principalTypeDropdownProps = {
            selectedPrincipalType: principalType,
            onPrincipalTypeSelect: this.onSelectPrincipalType,
            isDisabled
        };
        const principalsNavigateButtonsProps = {
            onClick: [
                this.onAddPrincipals,
                this.onRemovePrincipals
            ],
            length: [
                principalType === policyPrincipalTypeKeys.BUILTIN ? objectTruthyValues(availablePrincipals.selectedItems).length : (availablePrincipals.inputValue ? 1 : 0),
                objectTruthyValues(selectedPrincipals.selectedItems).length
            ],
            isDisabled
        };

        const permissionsNavigateButtonsProps = {
            onClick: [
                this.onAddPermissions,
                this.onRemovePermissions,
            ],
            length: [
                objectTruthyValues(availablePermissions.selectedItems).length,
                objectTruthyValues(selectedPermissions.selectedItems).length,
            ],
            isDisabled
        };

        const scopeTypeDropdownProps = {
            selectedScopeType: scopeType,
            onScopeTypeSelect: this.onSelectScopeType,
            isDisabled
        };
        const scopeNavigateButtonsProps = {
            onClick: [
                this.onAddScopes,
                this.onRemoveScopes
            ],
            length: [
                objectTruthyValues(availableScopes.selectedItems).length,
                objectTruthyValues(selectedScopes.selectedItems).length
            ],
            isDisabled
        };

        return (
            <Form width={'70rem'} height={'85vh'} closeButtonAriaLabel={t('policy:option.closeForm')}
                onClose={close} isDisabled={isDisabled}
                header={
                    <FormHeader text={t('policy:label.name')} iconName={'policy'} isDisabled={isDisabled}/>
                }
                body={
                    <div className="flex-expand" style={{height: '100%'}}>
                        <div className="display-input">
                            <HTMLTextInput {...nameInputProps}/>
                        </div>
                        <Checkbox {...enabledCheckboxProps} />
                        <div className="display-input">
                            <TextArea {...descriptionInputProps}/>
                        </div>

                        <div className={`display-input ${expanded.principals ? ' flex-expand' : ''}`}>
                            <ExpandableContent label={t('policy:label.principals')} headerBarStyle={{marginLeft: '0.5rem'}}
                                name={'principals'} onToggleCb={this.onToggleExpanded} flexExpand
                            >
                                <PrincipalsPanel
                                    principalType={principalType}
                                    principalTypeDropdownProps={principalTypeDropdownProps}
                                    principalsNavigateButtonsProps={principalsNavigateButtonsProps}
                                    availablePrincipals={availablePrincipals}
                                    selectedPrincipals={selectedPrincipals}
                                    onSelectAvailablePrincipals={this.onSelectAvailablePrincipals}
                                    onInputChangePrincipal={this.onInputChangePrincipal}
                                    onSelectSelectedPrincipals={this.onSelectSelectedPrincipals}
                                    isDisabled={isDisabled}
                                />
                            </ExpandableContent>
                        </div>

                        <div className={`display-input ${expanded.permissions ? ' flex-expand' : ''}`}>
                            <ExpandableContent label={t('policy:label.permissions')} headerBarStyle={{marginLeft: '0.5rem'}}
                                name={'permissions'} onToggleCb={this.onToggleExpanded} flexExpand
                            >
                                <PermissionsPanel
                                    permissionsNavigateButtonsProps={permissionsNavigateButtonsProps}
                                    availablePermissions={availablePermissions}
                                    selectedPermissions={selectedPermissions}
                                    onSelectAvailablePermissions={this.onSelectAvailablePermissions}
                                    onSelectSelectedPermissions={this.onSelectSelectedPermissions}
                                    isDisabled={isDisabled}
                                />
                            </ExpandableContent>
                        </div>

                        <div className={`display-input ${expanded.scope ? ' flex-expand' : ''}`}>
                            <ExpandableContent label={t('policy:label.scope')} headerBarStyle={{marginLeft: '0.5rem'}}
                                name={'scope'} onToggleCb={this.onToggleExpanded} flexExpand
                            >
                                <ScopePanel
                                    scopeType={scopeType}
                                    scopeTypeDropdownProps={scopeTypeDropdownProps}
                                    scopeNavigateButtonsProps={scopeNavigateButtonsProps}
                                    availableScopeParents={availableScopeParents}
                                    availableScopes={availableScopes}
                                    selectedScopes={selectedScopes}
                                    onSelectScopeParent={this.onSelectScopeParent}
                                    onSelectAvailableScopes={this.onSelectAvailableScopes}
                                    onSelectSelectedScopes={this.onSelectSelectedScopes}
                                    isDisabled={isDisabled}
                                />
                            </ExpandableContent>
                        </div>
                    </div>
                }
                footer={
                    <FormFooter addText={t('policy:option.addPolicy')} onAddClick={this.onAddClick}
                        onCancel={close} isAddEnabled={isAddEnabled} isDisabled={isDisabled}/>
                }
            />
        );
    }
}

function PrincipalsPanel(props) {
    const {t} = useTranslation(['policy']);
    const {principalType, principalTypeDropdownProps, principalsNavigateButtonsProps, availablePrincipals, selectedPrincipals, isDisabled,
        onSelectAvailablePrincipals, onInputChangePrincipal, onSelectSelectedPrincipals} = props;

    return (
        <>
            <PolicyPrincipalTypeDropdown {...principalTypeDropdownProps} />
            <SwitchListContainers id="principalSwitchContainer" navigateButtonsProps={principalsNavigateButtonsProps}
                ariaLabelKey={'Principal'}
                leftContainer={principalType && (principalType === policyPrincipalTypeKeys.BUILTIN ?
                    <ListContainer {...availablePrincipals} label={t('common:label.available')}
                        onItemClick={onSelectAvailablePrincipals} isDisabled={isDisabled}
                        items={availablePrincipals.items
                            .map(item => ({
                                ...item,
                                name: t(`policy:principalBuiltInType.${item.value}`),
                            }))}
                    />
                    :
                    <div style={{marginTop: '1.35rem'}}>
                        <HTMLTextInput {...{
                            title: t(`policy:principalType.${principalType}`),
                            value: availablePrincipals.inputValue,
                            onChange: onInputChangePrincipal,
                            style: {flex: '1'},
                            isDisabled
                        }}/>
                    </div>)
                }
                rightContainer={
                    <ListContainer {...selectedPrincipals} itemComponent={TypeSelectableItem}
                        label={t('common:label.selected')}
                        onItemClick={onSelectSelectedPrincipals} isDisabled={isDisabled}
                        items={selectedPrincipals.items
                            .map(item => ({
                                ...item,
                                name: item.type === policyPrincipalTypeKeys.BUILTIN ? t(`policy:principalBuiltInType.${item.value}`) : item.name,
                                type: t(`policy:principalType.${item.type}`)
                            }))}
                    />

                }
            />
        </>
    )
}

function PermissionsPanel(props) {
    const {t} = useTranslation(['policy']);
    const {permissionsNavigateButtonsProps, availablePermissions, selectedPermissions, isDisabled,
        onSelectAvailablePermissions, onSelectSelectedPermissions} = props;

    return (
        <SwitchListContainers id="permissionSwitchContainer" navigateButtonsProps={permissionsNavigateButtonsProps}
            ariaLabelKey={'Permission'}
            leftContainer={
                <ListContainer {...availablePermissions} label={t('common:label.available')}
                    onItemClick={onSelectAvailablePermissions} isDisabled={isDisabled}
                    items={availablePermissions.items
                        .map(item => ({
                            ...item,
                            name: t(`common:permission.${item.value}`)
                        }))}
                />
            }
            rightContainer={
                <ListContainer {...selectedPermissions} label={t('common:label.selected')}
                    onItemClick={onSelectSelectedPermissions} isDisabled={isDisabled}
                    items={selectedPermissions.items
                        .map(item => ({
                            ...item,
                            name: t(`common:permission.${item.value}`)
                        }))}
                />
            }
        />
    )
}

function ScopePanel(props) {
    const {t} = useTranslation(['policy']);
    const {scopeType, scopeTypeDropdownProps, scopeNavigateButtonsProps, availableScopeParents, availableScopes, selectedScopes, isDisabled,
        onSelectScopeParent, onSelectAvailableScopes, onSelectSelectedScopes} = props;

    const disabled = isDisabled ? ' is-disabled' : '';
    const searchStyle = {
        marginBottom: '0'
    }


    const isChildContainerSearchable = !!availableScopes.items && availableScopes.items.length > 10;
    const isParentContainerSearchable = !!availableScopeParents.items && availableScopeParents.items.length > 10;
    const isSelectedContainerSearchable = !!selectedScopes.items && selectedScopes.items.length > 10;

    return (
        <>
            <PolicyScopeTypeDropdown {...scopeTypeDropdownProps} />
            <SwitchListContainers id="scopeSwitchContainer" ariaLabelKey={'Scope'}
                navigateButtonsProps={scopeNavigateButtonsProps}
                leftContainer={scopeType && ([policyScopeTypeOptionKeys.CLIENT_ID_MATTER_ID, policyScopeTypeOptionKeys.LIBRARY_ID_WORKFLOW_ID].includes(scopeType) ?
                    <>
                        <label className={'label' + disabled} style={{textAlign: 'center'}}>
                            {t('common:label.available')}
                        </label>
                        <div className="flex-expand is-row">
                            <div className="flex-expand">
                                <ListContainer {...availableScopeParents}
                                    onItemClick={onSelectScopeParent} isSearchable={isParentContainerSearchable} searchPlaceholder={t(`policy:searchPlaceholder.${scopeType}`)} searchStyle={searchStyle} isDisabled={isDisabled}/>
                            </div>
                            <div className="flex-expand">
                                <ListContainer {...availableScopes}
                                    onItemClick={onSelectAvailableScopes} isSearchable={isChildContainerSearchable} searchPlaceholder={t(`policy:searchPlaceholder.${scopeType}_CHILD`)} searchStyle={searchStyle} isDisabled={isDisabled}
                                    items={availableScopes.items
                                        .map(item => {
                                            //FOR ALL MATTERS/WORKFLOWS
                                            if ([policyScopeTypeKeys.CLIENT_ID, policyScopeTypeKeys.CLIENT_ID_MATTERS, policyScopeTypeKeys.LIBRARY_ID].includes(item.type)) {
                                                return {
                                                    ...item,
                                                    name: t(`policy:scopeTypeName.${item.type}`),
                                                    isItalic: true
                                                }
                                            }
                                            return item;
                                        })}
                                />
                            </div>
                        </div>
                    </>
                    :
                    <ListContainer {...availableScopes} label={t('common:label.available')}
                        onItemClick={onSelectAvailableScopes} isDisabled={isDisabled}
                        isSearchable={isChildContainerSearchable} searchPlaceholder={t(`policy:searchPlaceholder.${scopeType}`)} searchStyle={searchStyle}
                        items={availableScopes.items
                            .map(item => {
                                if (item.type === policyScopeTypeKeys.BUILTIN) {
                                    return {
                                        ...item,
                                        name: t(`policy:scopeBuiltInType.${item.value}`)
                                    }
                                }
                                return item;
                            })
                        }
                    />)
                }
                rightContainer={
                    <ListContainer {...selectedScopes} itemComponent={TypeSelectableItem}
                        label={t('common:label.selected')}
                        onItemClick={onSelectSelectedScopes} isDisabled={isDisabled}
                        isSearchable={isSelectedContainerSearchable} searchPlaceholder={t(`policy:searchPlaceholder.SELECTED_SCOPES`)} searchStyle={searchStyle}
                        items={selectedScopes.items
                            .map(item => {
                                const translatedItem = {
                                    ...item,
                                    type: t(`policy:scopeType.${item.type}`)
                                };
                                if (item.type === policyScopeTypeKeys.BUILTIN) {
                                    translatedItem.name = t(`policy:scopeBuiltInType.${item.value}`)
                                }

                                return translatedItem;
                            })
                        }
                    />
                }
            />
        </>
    )
}

const mapStateToProps = state => {
    const principalValues = {
        [policyPrincipalTypeKeys.BUILTIN]: getBuiltInPolicyPrincipalValueTypes()
    };
    const scopeValues = {
        [policyScopeTypeKeys.BUILTIN]: getBuiltInPolicyScopeNameValueTypes(),
        [policyScopeTypeKeys.CLIENT_ID]: getClientNameValueTypes(state),
        [policyScopeTypeKeys.MATTER_ID]: getMatterNameValueTypes(state),
        [policyScopeTypeKeys.LIBRARY_ID]: getLibraryNameValueTypes(state),
        [policyScopeTypeKeys.WORKFLOW_ID]: getWorkflowTemplateNameValueTypes(state),
        [policyScopeTypeKeys.NUIX_LICENCE_SOURCE_ID]: getNuixLicenceSourceNameValueTypes(state),
        [policyScopeTypeKeys.EXECUTION_PROFILE_ID]: getExecutionProfileNameValueTypes(state),
        [policyScopeTypeKeys.RESOURCE_POOL_ID]: getResourcePoolNameValueTypes(state),
        [policyScopeTypeKeys.CLIENT_POOL_ID]: getClientPoolNameValueTypes(state),
        [policyScopeTypeKeys.NOTIFICATION_RULE_ID]: getNotificationRuleNameValueTypes(state),
        [policyScopeTypeKeys.DATA_REPOSITORY_ID]: getDataRepositoryNameValueTypes(state),
        [policyScopeTypeKeys.THIRD_PARTY_SERVICE_ID]: getThirdPartyServiceNameValueTypes(state),
        [policyScopeTypeKeys.NOTICE_TEMPLATE_ID]: getNoticeTemplateNameValueTypes(state),
        [policyScopeTypeKeys.LEGAL_HOLD_ID]: getLegalHoldNameValueTypes(state),
        [policyScopeTypeKeys.FILE_LIBRARY_ID]: getFileLibraryNameValueTypes(state),
        [policyScopeTypeKeys.USER_SERVICE_ID]: getUserServiceNameValueTypes(state),
    };

    return {
        principalValues,
        scopeValues,
        ...state.componentStates.policyForm
    };
};

const mapDispatchToProps = dispatch => {
    return {
        yieldEffect: effect => dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(effect)),
        submitPolicy: policy => dispatch(PolicyModel.actionCreators.submitForm(policy)),

        close: () => dispatch(PolicyModel.componentActionCreators.updateDisplay({isPolicyFormActive: false})),
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['policy', 'common'])(PolicyForm));