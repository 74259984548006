import React, {useRef, useState} from "react";
import {
    initialSelectedState,
    useClearSelectedEffect,
    useTabNavigateEffect,
    useValueSelectHandler
} from "../../utilities/hooks";
import ParameterModel from "../../models/library/ParameterModel";
import {filterArrayIndices, getNewArrayWithUpdatedValue, objectTruthyValues} from "../../utilities/helperFunctions";
import {AddRemoveButtons} from "../common/Button/Button";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import {useTranslation} from "react-i18next";
import ParameterInput from "./ParameterInput";


// parameters => [{parameter}, {parameter}]
function ParameterInputTable(props) {
    const {t} = useTranslation(['workflow', 'common']);

    const containerRef = useRef();
    const [selected, setSelected] = useState(initialSelectedState);

    const valueSelectHandler = useValueSelectHandler({setSelected});
    useClearSelectedEffect({containerRef, setSelected});
    useTabNavigateEffect({containerRef});

    const {
        parameters,
        setParameters,
        ignoreRegex,
        isDisabled
    } = props;


    function addRow() {
        setParameters(prevParameters => {

            return [
                ...prevParameters,
                new ParameterModel({name: '', value: '', friendlyName: '', description: '', regex: ''})
            ];
        });
    }

    function removeSelectedRows() {
        setParameters(prevParameters => {
            const selectedRowIndices = objectTruthyValues(selected.values);
            return filterArrayIndices(prevParameters, selectedRowIndices);
        });

        setSelected(prevSelected => ({
            ...prevSelected,
            values: {},
            lastSelectedValue: null
        }));
    }

    function inputHandler(event) {
        const {value, name, dataset: {row}} = event.target;

        setParameters(prevParameters => {
            const updatedParameter = prevParameters[row].duplicate({[name]: value});
            if (name === 'name') {
                // update friendlyName
                updatedParameter.friendlyName = ParameterModel.getFriendlyNameFromParameterName(value);
            }
            return getNewArrayWithUpdatedValue(prevParameters, updatedParameter, row);
        });
    }

    function valueInputHandler(row) {
        return function(event) {
            const {value} = event.target;

            inputHandler({
                target: {
                    dataset: {row},
                    name: 'value',
                    value
                }
            });
        }
    }

    function blurHandler(event) {
        const {value, name, dataset} = event.target;

        inputHandler({
            target: {
                name,
                dataset,
                value: ParameterModel.normalizeParameterName(value)
            }
        });
    }


    const canRemove = objectTruthyValues(selected.values).length > 0;

    return (
        <div id="parametersInputTable" ref={containerRef}>
            <AddRemoveButtons label={t('workflow:label.parameters')} onAddClick={addRow} noPadding
                onRemoveClick={removeSelectedRows} isRemoveDisabled={!canRemove} isDisabled={isDisabled}
                ariaLabelKey={'Parameter'}
            />

            <div style={{paddingBottom: '1px'}}>
                <table className="input-tableV2">
                    <thead>
                    <tr>
                        <td>
                            {t('workflow:label.parameterName')}
                        </td>
                        <td>
                            {t('common:label.defaultValue')}
                        </td>
                        <td>
                            {t('workflow:label.friendlyName')}
                        </td>
                        <td>
                            {t('workflow:label.description_optional')}
                        </td>
                        <td>
                            {t('workflow:label.regex_optional')}
                        </td>
                    </tr>
                    </thead>

                    <tbody>
                    {parameters.map((parameter, rindex) => {
                        const readOnly = parameter.readOnly ? 'is-readonly' : '';
                        return (
                            <tr key={rindex} className={readOnly} data-index={rindex} onClick={valueSelectHandler}>
                                <td>
                                    <HTMLTextInput aria-label={t('workflow:label.parameterName')} name={'name'} value={parameter.name}
                                        isInvalid={parameter.isPasswordParameter() || parameter.isDatasetParameter() || parameter.isAzureStorageAccountParameter()
                                            || parameter.isFileParameter() || parameter.isThirdPartyServiceParameter()}
                                        onChange={inputHandler} onBlur={blurHandler} data-row={rindex} isSelected={selected.values[rindex]}
                                        isRequired isReadOnly={parameter.readOnly} isDisabled={isDisabled}/>
                                </td>

                                <td>
                                    <ParameterInput aria-label={t('common:label.value')} parameter={parameter} onValueChange={parameter.readOnly ? null : valueInputHandler(rindex)}
                                        data-row={rindex} isEditActive isSelected={selected.values[rindex]} ignoreRegex={ignoreRegex}
                                        isDisabled={isDisabled} isReadOnly={parameter.readOnly}/>
                                </td>

                                <td>
                                    <HTMLTextInput aria-label={t('workflow:label.friendlyName')} name={'friendlyName'} value={parameter.friendlyName} onChange={inputHandler}
                                        data-row={rindex} isSelected={selected.values[rindex]} isRequired isDisabled={isDisabled} isReadOnly={parameter.readOnly}/>
                                </td>

                                <td>
                                    <HTMLTextInput aria-label={t('workflow:label.description_optional')} name={'description'} value={parameter.description} onChange={inputHandler}
                                        data-row={rindex} isSelected={selected.values[rindex]} isDisabled={isDisabled} isReadOnly={parameter.readOnly}/>
                                </td>

                                <td>
                                    <HTMLTextInput aria-label={t('workflow:label.regex_optional')} name={'regex'} value={parameter.regex} onChange={inputHandler}
                                        data-row={rindex} isSelected={selected.values[rindex]} isDisabled={isDisabled} isReadOnly={parameter.readOnly}/>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ParameterInputTable;
