import {userServiceObjectTypes, vaultCorpusTypes, vaultSearchMethods} from "../../../utilities/constants";
import DataSourcesTable from "../../purview/DataSourcesTable";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {vaultCreateSavedQueriesParameterNames} from "./VaultSavedQueryOptionsPane";
import {buildFakeEvent, getKeys, getNonEmptyValueOrDefault} from "../../../utilities/helperFunctions";
import VaultOperationPane from "./VaultOperatonPane";
import {ListDropdown} from "../../common/Dropdown/Dropdown";
import {WarningLabel} from "../../common/Common";
import {vaultAddHoldsParameterNames} from "./VaultHoldOptionsPane";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import Switch from "../../common/Switch/Switch";
import {useUpdateLayoutEffect} from "../../../utilities/hooks";
import UserServiceObjectDropdown from "../../userservice/UserServiceObjectDropdown";
import {blobUrlDownload} from "../../../utilities/downloadHelper";
import Papa from "papaparse";
import GuidedJobModel from "../../../models/guidedjob/GuidedJobModel";
import {ThirdPartyServiceApi} from "../../../models/thirdparty/ThirdPartyServiceModel";
import SchedulerModel from "../../../models/scheduler/SchedulerModel";
import {useDispatch} from "react-redux";
import VaultCache from "../../../models/guidedjob/VaultCache";


function getNextEnabled(workflowActions, getParameter) {
    const nextEnabled = {
        addLocations: true
    };
    if (workflowActions.createSavedQueries) {
        const useQueryFiles = getParameter(vaultCreateSavedQueriesParameterNames.useQueryFiles, false, {parse: true});
        const queryLocations = getParameter(vaultCreateSavedQueriesParameterNames.queryLocations, '[]', {parse: true});
        nextEnabled.addLocations &&= !useQueryFiles.value && queryLocations.value.every(location =>
            !!(location.corpusType && location.searchMethod && (location.searchMethod === vaultSearchMethods.ENTIRE_ORG || location.value))
        );
    }
    if (workflowActions.addHolds) {
        const useHoldFiles = getParameter(vaultAddHoldsParameterNames.useHoldFiles, false, {parse: true});
        const holdLocations = getParameter(vaultAddHoldsParameterNames.holdLocations, '[]', {parse: true});
        nextEnabled.addLocations &&= !useHoldFiles.value && holdLocations.value.every(location =>
            !!(location.corpusType && location.searchMethod && location.value)
        );
    }
    return nextEnabled;
}

export function getVaultAddLocationsGuide(operation, props) {
    const {
        workflowActions,
        getParameter,
        updateParameter
    } = props;

    let defaultValue = '[]';

    if (workflowActions.createSavedQueries) {
        const queryLocations = getParameter(vaultCreateSavedQueriesParameterNames.queryLocations, '[]');
        if (!queryLocations.value.startsWith('[') || !queryLocations.value.endsWith(']')) {
            updateParameter(vaultCreateSavedQueriesParameterNames.queryLocations, JSON.stringify([]));
        } else {
            defaultValue = queryLocations.value;
        }
        updateParameter(vaultCreateSavedQueriesParameterNames.useQueryFiles, JSON.stringify(false));
    }
    if (workflowActions.addHolds) {
        const holdLocations = getParameter(vaultAddHoldsParameterNames.holdLocations, '[]');
        if (!holdLocations.value.startsWith('[') || !holdLocations.value.endsWith(']')) {
            updateParameter(vaultAddHoldsParameterNames.holdLocations, defaultValue);
        }
        updateParameter(vaultAddHoldsParameterNames.useHoldFiles, JSON.stringify(false));
    }

    return {
        getPanes: function(t, props) {
            const addVaultLocationsTitle = getNonEmptyValueOrDefault(operation.notes, t('guidedJob:panes.addLocations'));
            const nextEnabled = getNextEnabled(workflowActions, props.getParameter);

            return [{
                title: addVaultLocationsTitle,
                isNextEnabled: nextEnabled.addLocations,
                component: <VaultOperationPane {...props}
                    workflowActions={workflowActions} OperationPane={VaultAddLocationsPane}/>
            }];
        }
    }
}

export function getVaultLocations(workflowActions, getParameter) {
    if (workflowActions.createSavedQueries) {
        return getParameter(vaultCreateSavedQueriesParameterNames.queryLocations, '[]', {parse: true});
    }
    return getParameter(vaultAddHoldsParameterNames.holdLocations, '[]', {parse: true});
}

const defaultVaultLocation = {
    corpusType: vaultCorpusTypes.MAIL,
    searchMethod: vaultSearchMethods.ACCOUNT,
    value: ''
};

function VaultAddLocationsPane(props) {
    const {t} = useTranslation(['guidedJob', 'common']);
    const dispatch = useDispatch();

    const {
        vaultService,
        workflowActions,
        getParameter,
        updateParameter,
        isDisabled
    } = props;

    const headers = useRef([
        {value: t('guidedJob:label.service'), style: {width: '12rem'}},
        {value: t('guidedJob:label.searchMethod'), style: {width: '12rem'}},
        {value: t('guidedJob:label.value')}
    ]);

    const rowProps = useRef({
        userServiceId: vaultService.authenticationServiceId,
        allowedSearchMethods: !workflowActions.createSavedQueries && [vaultSearchMethods.ACCOUNT, vaultSearchMethods.ORG_UNIT],
    });

    const _getVaultLocations = () => getVaultLocations(workflowActions, getParameter).value;
    const [locations, setLocations] = useState(_getVaultLocations);

    useEffect(() => {
        if (workflowActions.createSavedQueries) {
            updateParameter(vaultCreateSavedQueriesParameterNames.queryLocations, JSON.stringify(locations));
        }
        if (workflowActions.addHolds) {
            const holdLocations = locations
                .filter(loc => [vaultSearchMethods.ACCOUNT, vaultSearchMethods.ORG_UNIT].includes(loc.searchMethod));
            updateParameter(vaultAddHoldsParameterNames.holdLocations, JSON.stringify(holdLocations));
        }
    }, [locations]);

    const exportCsv = useCallback(() => {
        const fields = headers.current.map(header => header.value);
        const data = locations.map(location =>
            [location.corpusType, location.searchMethod, location.value]
        );
        const fileName = `Vault Locations.csv`;
        blobUrlDownload(Papa.unparse({fields, data}), {type: 'text/csv;charset=utf-8;', fileName});
    }, [locations]);

    const importCsv = useCallback(async resultArray => {
        try {
            dispatch(GuidedJobModel.actionCreators.update({isDisabled: true}));
            const {data} = await ThirdPartyServiceApi.parseImportedObjects(vaultService.id, VaultCache.Resource.ALL_HELD_LOCATIONS, resultArray);
            const values = {
                importedCount: data.objects.length,
                invalidCount: data.invalidCount
            };
            setLocations([]);
            setLocations(data.objects);
            dispatch(GuidedJobModel.showImportPopup(values));
        } catch (error) {
            dispatch(SchedulerModel.actionCreators.handleResponseError(error));
        } finally {
            dispatch(GuidedJobModel.actionCreators.update({isDisabled: false}));
        }
    }, [vaultService.id]);


    const showHoldLocationsWarningMessage = workflowActions.addHolds && workflowActions.createSavedQueries
        && locations.some(loc => ![vaultSearchMethods.ACCOUNT, vaultSearchMethods.ORG_UNIT].includes(loc.searchMethod));

    return (
        <div className="display-input">
            {showHoldLocationsWarningMessage &&
                <WarningLabel message={t('guidedJob:message.vaultHoldLocationsOnlySupportAccountAndOrgUnitTypes')} isDisabled={isDisabled}/>
            }

            <DataSourcesTable id={"vaultLocations"} label={t('guidedJob:label.vaultLocations')} dataSources={locations}
                setDataSources={setLocations} defaultObject={defaultVaultLocation} headers={headers.current}
                RowCells={VaultLocationRowCells} rowProps={rowProps.current} exportCsv={exportCsv}
                ariaLabelKey={'VaultLocation'} importCsv={importCsv} isDisabled={isDisabled}/>
        </div>
    )
}

function VaultLocationRowCells(props) {
    const {
        rIndex,
        userServiceId,
        dataSource,
        inputHandler,
        dropdownHandler,
        dropdownButtonStyle,
        isSelected,
        isDisabled
    } = props;

    const userServiceObjectType = getUserServiceObjectType(dataSource.corpusType, dataSource.searchMethod);
    const allowedSearchMethods = getAllowedSearchMethodsForType(dataSource.corpusType)
        .filter(method => !Array.isArray(props.allowedSearchMethods) || props.allowedSearchMethods.includes(method));

    const invalidSearchMethod = allowedSearchMethods.every(method => dataSource.searchMethod !== method);
    useUpdateLayoutEffect(() => {
        if (invalidSearchMethod) {
            dropdownHandler(buildFakeEvent({index: rIndex, name: 'searchMethod', value: null}));
        }
    }, [invalidSearchMethod]);
    useUpdateLayoutEffect(() => {
        dropdownHandler(buildFakeEvent({index: rIndex, name: 'value', value: ''}));
    }, [dataSource.searchMethod, dataSource.corpusType]);

    return (
        <>
            <td style={{height: '1px'}}>
                <VaultCorpusTypeDropdown id={`vaultCorpusTypeDropdown${rIndex}`} name={'corpusType'}
                    value={dataSource.corpusType} onItemSelect={dropdownHandler} buttonStyle={dropdownButtonStyle}
                    isRequired isSelected={isSelected} isDisabled={isDisabled}/>
            </td>
            <td style={{height: '1px'}}>
                <VaultSearchMethodDropdown id={`vaultSearchMethodTypeDropdown${rIndex}`} name={'searchMethod'}
                    value={dataSource.searchMethod} onItemSelect={dropdownHandler} buttonStyle={dropdownButtonStyle}
                    allowedSearchMethods={allowedSearchMethods} isRequired isSelected={isSelected} isDisabled={isDisabled}/>
            </td>
            <td style={{height: '1px'}}>
                <Switch>
                    {dataSource.searchMethod === vaultSearchMethods.SITES_URL &&
                        <HTMLTextInput id={`vaultLocationValueInput${rIndex}`} name={'value'} value={dataSource.value}
                            onChange={inputHandler} isRequired isSelected={isSelected} isDisabled={isDisabled}/>
                    }
                    {dataSource.searchMethod !== vaultSearchMethods.ENTIRE_ORG &&
                        <UserServiceObjectDropdown id={`vaultLocationValueDropdown${rIndex}`} name={'value'}
                            objectType={userServiceObjectType} value={dataSource.value} userServiceId={userServiceId}
                            onItemSelect={dropdownHandler} buttonStyle={dropdownButtonStyle} isRequired
                            isSelected={isSelected} isDisabled={isDisabled}/>
                    }
                </Switch>
            </td>
        </>
    )
}

function getAllowedSearchMethodsForType(corpusType) {
    switch (corpusType) {
        case vaultCorpusTypes.MAIL:
            return [vaultSearchMethods.ENTIRE_ORG, vaultSearchMethods.ACCOUNT, vaultSearchMethods.ORG_UNIT];
        case vaultCorpusTypes.DRIVE:
            return [vaultSearchMethods.ACCOUNT, vaultSearchMethods.ORG_UNIT, vaultSearchMethods.SHARED_DRIVE, vaultSearchMethods.SITES_URL];
        case vaultCorpusTypes.GROUPS:
            return [vaultSearchMethods.ACCOUNT];
        case vaultCorpusTypes.HANGOUTS_CHAT:
            return [vaultSearchMethods.ACCOUNT, vaultSearchMethods.ORG_UNIT, vaultSearchMethods.ROOM];
        case vaultCorpusTypes.VOICE:
            return [vaultSearchMethods.ACCOUNT, vaultSearchMethods.ORG_UNIT];
    }
    return [];
}

function getUserServiceObjectType(corpusType, searchMethod) {
    switch (searchMethod) {
        case vaultSearchMethods.ACCOUNT:
            if (corpusType === vaultCorpusTypes.GROUPS) {
                return userServiceObjectTypes.GOOGLE_GROUP;
            }
            return userServiceObjectTypes.USER_ACCOUNT;
        case vaultSearchMethods.ORG_UNIT:
            return userServiceObjectTypes.GOOGLE_ORG_UNIT;
        case vaultSearchMethods.SHARED_DRIVE:
            return userServiceObjectTypes.GOOGLE_SHARED_DRIVE;
        case vaultSearchMethods.SITES_URL:
            return userServiceObjectTypes.GOOGLE_SITE;
        case vaultSearchMethods.ROOM:
            return userServiceObjectTypes.GOOGLE_CHAT_SPACE;
    }
}

export function VaultCorpusTypeDropdown(props) {
    const {t} = useTranslation(['guidedJob', 'aria']);
    const {
        allowedTypes,
        ...rest
    } = props;

    const items = getKeys(vaultCorpusTypes)
        .filter(type => !allowedTypes || allowedTypes.includes(type))
        .map(type => ({
            name: t(`guidedJob:vaultCorpusType.${type}`),
            value: type
        }));

    return (
        <ListDropdown id={'vaultCorpusTypeDropdown'} name={'corpusType'}
            noneSelectedMessage={t('guidedJob:option.selectCorpusType')} items={items}
            isOutlined {...rest}/>
    );
}

function VaultSearchMethodDropdown(props) {
    const {t} = useTranslation(['guidedJob', 'aria']);
    const {
        allowedSearchMethods,
        ...rest
    } = props;

    const items = getKeys(vaultSearchMethods)
        .filter(type => !Array.isArray(allowedSearchMethods) || allowedSearchMethods.includes(type))
        .map(type => ({
            name: t(`guidedJob:vaultSearchMethod.${type}`),
            value: type
        }));

    return (
        <ListDropdown id={'vaultSearchMethodDropdown'} name={'searchMethod'}
            noneSelectedMessage={t('guidedJob:option.selectSearchMethod')} items={items}
            isOutlined {...rest}/>
    );
}
