import React, {useRef, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {useTranslation, withTranslation} from "react-i18next";
import {licenceSourceTypes, updateMode} from "../../../utilities/constants";
import {createCloseHandler, createInputHandler} from '../../../utilities/componentFunctions';
import TextArea from '../../common/TextArea/TextArea';
import Form, {FormHeader} from "../../common/Form/Form";
import {shouldEnableClsLicenceSource} from "../../../utilities/shouldEnableFunctions";
import PopupModel from "../../../models/scheduler/PopupModel";
import LicenceSourceModel from "../../../models/settings/LicenceSourceModel";
import {ButtonGroup} from "../../common/Button/Button";
import SchedulerModel from "../../../models/scheduler/SchedulerModel";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import {useClearOnFirstCallback} from "../../../utilities/formHooks";

function ClsLicenceSourceForm(props) {
    const {t} = useTranslation(['nuixLicenceSource', 'common']);
    const dispatch = useDispatch();

    const [state, setState] = useState({
        sourceType: licenceSourceTypes.CLS,
        clsLicenceSourceName: '',
        description: '',
        filter: '',
        clsLicenseSourceUsername: '',
        isAddEnabled: false
    });

    const clsLicenseSourcePasswordRef = useRef();
    const clearOnFirstCallback = useClearOnFirstCallback();

    const inputHandler = createInputHandler({
        mode: updateMode.LOCAL,
        updateState: setState,
        passwordRef: clsLicenseSourcePasswordRef,
        passwordName: 'clsLicenseSourcePassword',
        passwordResetNames: ['clsLicenseSourceUsername'],
        shouldEnable: shouldEnableClsLicenceSource
    });

    function onSubmit() {
        props.addNuixLicenceSource(state);
    }

    const onClose = createCloseHandler({
        t,
        item: t('nuixLicenceSource:label.name'),
        values: state,
        blacklist: ['sourceType'],
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        close: () => dispatch(LicenceSourceModel.componentActionCreators.updateDisplay({isClsLicenceSourceFormActive: false}))
    });

    const {
        isDisabled
    } = props;
    const {
        clsLicenceSourceName,
        description,
        filter,
        clsLicenseSourceUsername,
        isAddEnabled
    } = state;

    return (
        <Form onClose={onClose} isDisabled={isDisabled} closeButtonAriaLabel={t('nuixLicenceSource:option.closeClsForm')}
            header={
                <FormHeader text={t('nuixLicenceSource:label.clsLicenceSourceName')} iconName={'clsLicenceSource'} isDisabled={isDisabled}/>
            }
            body={
                <>
                    <div className="display-input">
                        <HTMLTextInput label={t('common:label.name')} name={'clsLicenceSourceName'} value={clsLicenceSourceName} onChange={inputHandler}
                            isDisabled={isDisabled} isRequired/>
                    </div>

                    <div className="display-input">
                        <TextArea label={t('common:label.description')} name={'description'} value={description} onChange={inputHandler}
                            isDisabled={isDisabled}/>
                    </div>

                    <div className="display-input">
                        <HTMLTextInput label={t('nuixLicenceSource:label.filter')} name={'filter'} value={filter} onChange={inputHandler}
                            isDisabled={isDisabled}/>
                    </div>

                    <div className="display-input cls-values">
                        <HTMLTextInput label={t('common:label.username')} name={'clsLicenseSourceUsername'} value={clsLicenseSourceUsername} onChange={inputHandler}
                            isDisabled={isDisabled} isRequired/>
                    </div>

                    <div className="display-input">
                        <HTMLTextInput label={t('common:label.password')} inputRef={clsLicenseSourcePasswordRef} name={'clsLicenseSourcePassword'}
                            type={'password'} onChange={inputHandler} onBeforeInput={clearOnFirstCallback} isDisabled={isDisabled} isRequired/>
                    </div>
                </>
            }
            footer={
                <ButtonGroup buttons={[{
                    id: 'formFooterBackButton',
                    label: t('common:option.cancel'),
                    onClick: onClose,
                    isDisabled
                }, {
                    id: 'formFooterNextButton',
                    label: t('common:button.addNuixLicenseSource'),
                    onClick: onSubmit,
                    isDisabled: isDisabled || !isAddEnabled
                }]}/>
            }
        />
    )
}

const mapStateToProps = state => {
    return {
        ...state.componentStates.licenceSourceForm
    }
};

const mapDispatchToProps = dispatch => {
    return {
        yieldEffect: effect => dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(effect)),
        addNuixLicenceSource: nuixLicenceSource => dispatch(LicenceSourceModel.actionCreators.submitForm(nuixLicenceSource))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['nuixLicenceSource', 'common'])(ClsLicenceSourceForm));