import React from "react";
import {useTranslation} from "react-i18next";
import {SettingsRowValue} from "../../common/CustomTable/CustomTable";
import ExpandableContent from "../../common/ExpandableContent/ExpandableContent";

function LinkServiceSettings(props) {
    const {t} = useTranslation(['thirdPartyService']);
    const {
        thirdPartyService,
        style,
        isDisabled
    } = props;

    return (
        <>
            <ExpandableContent label={t('thirdPartyService:label.settings')}
                style={style} isDisabled={isDisabled}
            >
                <div className="settings-table">
                    <div className="table-row-group">
                        <SettingsRowValue label={t('thirdPartyService:label.url')}
                            value={thirdPartyService.url} isDisabled={isDisabled}/>
                    </div>
                </div>
            </ExpandableContent>
        </>
    )
}

export default LinkServiceSettings;
