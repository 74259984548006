import ReduxModel from "./ReduxModel";
import {actionCreator} from "../../utilities/helperFunctions";

class SettingModel extends ReduxModel {

    static buildActions(type) {
        return {
            // SETTING ACTIONS
            SET_SETTING: `SET_${type}_SETTING`,
            DELETE_SETTING: `DELETE_${type}_SETTING`
        }
    }

    static buildActionCreators(actions) {
        return {
            // SETTING CREATORS
            setSetting: actionCreator(actions.SET_SETTING, 'id', 'setting'),
            deleteSetting: actionCreator(actions.DELETE_SETTING, 'id')
        }
    }

    static Simple = class extends SettingModel {

        constructor(model = {}) {
            super();
            this.forceUpdate(model)
        }

        static buildReducer(actions) {
            return function (state = new Map(), action) {
                switch (action.type) {
                    case actions.SET_SETTING: {
                        const {id, setting} = action.payload;

                        if (setting != null && !Array.isArray(setting) && typeof setting === 'object') {
                            return this.setValue(state, id, new this(setting));
                        }
                        return this.setValue(state, id, setting);
                    }
                    case actions.DELETE_SETTING: {
                        const {id} = action.payload;

                        return this.deleteDetails(state, id);
                    }
                    default: {
                        return state;
                    }
                }
            }.bind(this);
        }
    };

    static Complex = class extends SettingModel {

        constructor(model = {}) {
            super();

            this.nullUpdate(model);
        }

        static buildReducer(actions) {
            return function (state = new Map(), action) {
                switch (action.type) {
                    case actions.SET_SETTING: {
                        const {id, setting} = action.payload;

                        const oldSetting = state.get(id);
                        const newSetting = this.setDetailsArray(oldSetting, setting);

                        // If reference is same, return same
                        if (oldSetting !== newSetting) {
                            return new Map(state).set(id, newSetting);
                        }

                        return state;
                    }
                    case actions.DELETE_SETTING: {
                        const {id} = action.payload;

                        return this.deleteDetails(state, id);
                    }
                    default: {
                        return state;
                    }
                }
            }.bind(this);
        }
    };
}

export default SettingModel;