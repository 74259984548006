import DetailsModel, {DetailsApi, DetailsSaga} from "../generics/DetailsModel";
import ComponentStateModel from "../generics/ComponentStateModel";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import {contextCall, isDisabledWrapper} from "../../saga/sagaFunctions";
import {all, call, put, select} from "redux-saga/effects";
import {datasetType, details, PASSWORD_PLACEHOLDER, routes} from "../../utilities/constants";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import {arrayIsNotEmptyNorFalsy, bytesCountToReadableCount, postProtectedValue} from "../../utilities/helperFunctions";
import {popupInfoKeys, settingsDisplayKeys} from "../../i18next/keys";
import SchedulerModel from "../scheduler/SchedulerModel";
import PopupModel from "../scheduler/PopupModel";
import {tryCatchWrapper} from "../../saga/tryCatchWrapper";


class DataRepositoryModel extends DetailsModel {

    static nom = 'DataRepositoryModel';
    static actions = DataRepositoryModel.buildActions('DATA_REPOSITORY');
    static actionCreators = DataRepositoryModel.buildActionCreators(DataRepositoryModel.actions);
    static reducer = DataRepositoryModel.buildReducer(DataRepositoryModel.actions);

    static componentActionCreators = {
        ...DataRepositoryModel.buildComponentUpdateActionCreators(),
        ...DataRepositoryModel.buildComponentSetActiveActionCreators()
    };

    static fileExtensionSeparator = /[;, \n]/;

    constructor(model={}) {
        super(model);
        const {type, path, quotaEnabled, quota, computeFileSystemUsableSpace, usableSpace, allowedFileExtensions, datasetAutoExpireInterval, datasetAutoHide,
            datasetAutoArchive, datasetAutoArchiveJobWarning, datasetAutoArchiveJobError, datasetAutoArchiveJobSoftError, azureStorageAccount} = model;

        this.type = type;
        this.path = path;
        this.quotaEnabled = quotaEnabled;
        this.quota = quota;
        this.computeFileSystemUsableSpace = computeFileSystemUsableSpace;
        this.usableSpace = usableSpace;
        this.allowedFileExtensions = allowedFileExtensions;
        this.azureStorageAccount = azureStorageAccount;

        this.datasetAutoExpireInterval = datasetAutoExpireInterval;
        this.datasetAutoHide = datasetAutoHide;
        this.datasetAutoArchive = datasetAutoArchive;
        this.datasetAutoArchiveJobWarning = datasetAutoArchiveJobWarning;
        this.datasetAutoArchiveJobError = datasetAutoArchiveJobError;
        this.datasetAutoArchiveJobSoftError = datasetAutoArchiveJobSoftError;

        this.datasetMaxSizeEnabled = model.datasetMaxSizeEnabled;
        this.datasetMaxSize = model.datasetMaxSize;
        this.uploadMaxSizeEnabled = model.uploadMaxSizeEnabled;
        this.uploadMaxSize = model.uploadMaxSize;
    }

    getAvailableSpace(datasetUsableSpace) {
        if (this.usableSpace == null)
            return null;

        const effectiveUsableSpace = parseInt(this.usableSpace.effectiveUsableSpace);
        if (this.datasetMaxSizeEnabled) {
            if (datasetUsableSpace != null && datasetUsableSpace < effectiveUsableSpace) {
                return datasetUsableSpace;
            }
            if (parseInt(this.datasetMaxSize) < effectiveUsableSpace) {
                return this.datasetMaxSize;
            }
        }
        return effectiveUsableSpace;
    }

    static validateFormData(formData, state) {
        switch (formData.type) {
            case datasetType.MANAGED:
                return DataRepositoryModel.validateManagedDataRepositoryFormData(formData);
            case datasetType.IN_PLACE:
                return DataRepositoryModel.validateInPlaceDataRepositoryFormData(formData);
            case datasetType.AZURE_STORE_ACCOUNT:
                return DataRepositoryModel.validateAzureStorageAccountFormData(formData);
        }
    }

    static validateManagedDataRepositoryFormData(formData) {
        const {dataRepositoryName, path, quota, quotaEnabled, datasetMaxSize, datasetMaxSizeEnabled,
            uploadMaxSize, uploadMaxSizeEnabled, computeFileSystemUsableSpace, datasetAutoExpireIntervalEnabled, datasetAutoExpireInterval} = formData;

        const quotaValid = !quotaEnabled || quota >= 0;
        const computeUsableSpaceValid = computeFileSystemUsableSpace || quotaEnabled;
        const datasetMaxSizeValid = !datasetMaxSizeEnabled || (datasetMaxSize >= 0 && (!quotaEnabled || parseInt(datasetMaxSize) <= quota));
        const uploadMaxSizeValid = !uploadMaxSizeEnabled || (uploadMaxSize >= 0 && (!datasetMaxSizeEnabled || parseInt(uploadMaxSize) <= datasetMaxSize));
        const autoExpireIntervalValid = !datasetAutoExpireIntervalEnabled || datasetAutoExpireInterval >= 0;

        return !!dataRepositoryName && !!path && quotaValid && computeUsableSpaceValid && datasetMaxSizeValid && uploadMaxSizeValid && autoExpireIntervalValid;
    }

    static validateInPlaceDataRepositoryFormData(formData) {
        return !!(formData.dataRepositoryName && formData.path
            && (!formData.datasetAutoExpireIntervalEnabled || formData.datasetAutoExpireInterval >= 0));
    }

    static validateAzureStorageAccountFormData(formData) {
        const {dataRepositoryName, azureStorageAccount: {accountUrl, accountName}, accountKey} = formData;
        return !!(dataRepositoryName && accountUrl && accountName && accountKey);
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'dataRepositoryDisplay',
                type: 'Display',
                state: {
                    dataRepositoryId: null,
                    isDataRepositoryFormActive: false
                }
            },
            {
                key: 'dataRepositoryTablet',
                type: 'Tablet',
                state: {
                    isDisabled: false
                }
            },
            {
                key: 'dataRepositoryForm',
                type: 'Form',
                state: {
                    type: null,
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'DATA_REPOSITORY_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }
}

export class DataRepositoryApi extends DetailsApi {

    static location = '/resources';
    static type = '/dataRepository';

    static getFiles(id, path, {includeFiles, allowedFileExtensions}={}) {
        const searchParams = new URLSearchParams();
        if (path) searchParams.append('path', path);
        if (includeFiles) searchParams.append('includeFiles', includeFiles);
        if (allowedFileExtensions) searchParams.append('allowedFileExtensions', allowedFileExtensions);

        const requestId = generateUUID4();
        return {
            id: requestId,
            promise: axiosInstance.postWorkerRequestWithId(requestId, 'get', `/scheduler/resources/dataRepository/${id}/files?${searchParams.toString()}`)
        };
    }

    static getFileSize(id, path) {
        const searchParams = new URLSearchParams();
        searchParams.append('path', path);

        const requestId = generateUUID4();
        return {
            id: requestId,
            promise: axiosInstance.postWorkerRequestWithId(requestId, 'get', `/scheduler/resources/dataRepository/${id}/fileSize?${searchParams.toString()}`)
        };
    }

    static getRepositoryFileTree(id, {viewPaths=false}={}) {
        return axiosInstance.get(`/scheduler/resources/dataRepository/${id}/fileTree?viewPaths=${viewPaths}`);
    }

    static getAzureStorageAccountContainers(id) {
        return axiosInstance.get(`/scheduler/resources/dataRepository/${id}/azureStorageAccountContainers`);
    }

    static computeUsableSpace(id) {
        return axiosInstance.get(`/scheduler/resources/dataRepository/${id}/usableSpace`);
    }

    static computeUsableSpaceObj(dataRepository) {
        return axiosInstance.post('/scheduler/resources/dataRepository/usableSpace', dataRepository);
    }
}

export class DataRepositorySaga extends DetailsSaga {

    static ModelType = DataRepositoryModel;
    static ModelApi = DataRepositoryApi;

    static activationComponent = 'DATA_REPOSITORY_DISPLAY';
    static variableNames = {
        detailsMap: 'dataRepositoryDetailsMap',
        instanceId: 'dataRepositoryId',
        isFormActive: 'isDataRepositoryFormActive',
        updateDisplay: 'updateDisplay',
        updatePane: 'updateTablet',
        route: routes.SETTINGS
    };

    static translations = {
        itemTitle: '$t(dataRepository:label.name)',
        itemLower: '$t(dataRepository:label.name_lower)'
    };

    static buildActivationEffects(dispatch) {
        return [
            ...super.buildActivationEffects(dispatch),
            put(DataRepositoryModel.actionCreators.startPollingDetails())
        ]
    }

    static buildDeactivationEffects() {
        return [
            ...super.buildDeactivationEffects(),
            put(DataRepositoryModel.actionCreators.stopPollingDetails())
        ]
    }

    static* setInstanceId(args) {
        const {updateDisplay, instanceId} = this.variableNames;

        yield all ([
            put(SchedulerModel.actionCreators.setSettingsDisplay(settingsDisplayKeys.DATA_REPOSITORIES)),
            put(this.ModelType.componentActionCreators[updateDisplay]({[instanceId]: args.id}))
        ]);
    }

    // Saga called manually, w/o actions pattern
    static* computeRepositoryUsableSpace(id) {
        yield call(isDisabledWrapper, DataRepositoryModel.componentActionCreators.updateTablet, tryCatchWrapper, function* () {
            const {data} = yield contextCall(DataRepositoryApi, 'computeUsableSpace', id);
            yield all([
                put(DataRepositoryModel.actionCreators.updateDetails({[id]: data})),
                put(PopupModel.actionCreators.showSuccess({
                    info: {
                        key: 'dataRepositoryEffectiveUsableSpace',
                        values: {
                            path: data.path,
                            effectiveUsableSpace: bytesCountToReadableCount(data.usableSpace.effectiveUsableSpace)
                        }
                    }
                }))
            ]);
        });
    }

    // Saga called manually, w/o actions pattern
    static* testRepositoryUsableSpace(formData) {
        const saveValues = yield this.getSaveValues(formData, true);
        yield call(isDisabledWrapper, DataRepositoryModel.componentActionCreators.updateForm, tryCatchWrapper, function* () {
            const {data} = yield contextCall(DataRepositoryApi, 'computeUsableSpaceObj', saveValues);

            yield put(PopupModel.actionCreators.showSuccess({
                info: {
                    key: 'dataRepositoryComputedUsableSpace',
                    values: {
                        path: data.path,
                        fileSystemSpace: bytesCountToReadableCount(data.usableSpace.fileSystemUsableSpace)
                    }
                }
            }));
        });
    }

    static* getEditValues(id) {
        const {name: dataRepositoryName, quota, datasetMaxSize, uploadMaxSize, usableSpace, allowedFileExtensions, datasetAutoExpireInterval, azureStorageAccount, status,
            ...rest} = yield select(state => state.dataRepositoryDetailsMap.get(id))

        const editValues = {
            dataRepositoryName,
            quota: 100,
            datasetMaxSize: 20,
            uploadMaxSize: 5,
            allowedFileExtensions: '',
            allowedFileExtensionsEnabled: false,
            datasetAutoExpireInterval: '12',
            datasetAutoExpireIntervalEnabled: false,
            azureStorageAccount: {
                ...azureStorageAccount,
                accountKey: PASSWORD_PLACEHOLDER
            },
            ...rest
        }

        if (quota != null) {
            editValues.quota = quota / Math.pow(1000, 3)
        }

        if (datasetMaxSize != null) {
            editValues.datasetMaxSize = datasetMaxSize / Math.pow(1000, 3)
        }

        if (uploadMaxSize != null) {
            editValues.uploadMaxSize = uploadMaxSize / Math.pow(1000, 3)
        }

        if (datasetAutoExpireInterval != null) {
            editValues.datasetAutoExpireIntervalEnabled = true
            editValues.datasetAutoExpireInterval = datasetAutoExpireInterval / 1000 / 60 / 60
        }

        if (arrayIsNotEmptyNorFalsy(allowedFileExtensions)) {
            editValues.allowedFileExtensionsEnabled = true
            editValues.allowedFileExtensions = allowedFileExtensions.join(';') + ';'
        }

        return editValues
    }

    static* getSaveValues(values, ignoreWarnings) {
        const {id, dataRepositoryName: name, type, path, description, quota, quotaEnabled, datasetMaxSize, datasetMaxSizeEnabled, uploadMaxSize, uploadMaxSizeEnabled,
            computeFileSystemUsableSpace, allowedFileExtensions, allowedFileExtensionsEnabled, datasetAutoExpireInterval, datasetAutoExpireIntervalEnabled,
            datasetAutoHide, datasetAutoArchive, datasetAutoArchiveJobWarning, datasetAutoArchiveJobError, datasetAutoArchiveJobSoftError, azureStorageAccount} = values

        const saveValues = {
            type,
            name,
            description
        }

        const isAzure = type === datasetType.AZURE_STORE_ACCOUNT;
        const isManaged = type === datasetType.MANAGED;

        if (isManaged) {
            // Only used for MANAGED
            saveValues.computeFileSystemUsableSpace = computeFileSystemUsableSpace;
            saveValues.quotaEnabled = quotaEnabled;
            saveValues.quota = quota * Math.pow(1000, 3);

            saveValues.datasetMaxSizeEnabled = datasetMaxSizeEnabled;
            saveValues.datasetMaxSize = datasetMaxSize * Math.pow(1000, 3);

            saveValues.uploadMaxSizeEnabled = uploadMaxSizeEnabled;
            saveValues.uploadMaxSize = uploadMaxSize * Math.pow(1000, 3);

            if (allowedFileExtensionsEnabled && allowedFileExtensions.length > 0) {
                saveValues.allowedFileExtensions = allowedFileExtensions.split(DataRepositoryModel.fileExtensionSeparator)
                    // Filter out empty strings
                    .filter(fileExtension => !!fileExtension);
            } else {
                saveValues.allowedFileExtensions = []
            }
        }

        if (isAzure) {
            saveValues.azureStorageAccount = azureStorageAccount;

            const accountKey = (document.getElementsByName('accountKey')[0] || {}).value;
            saveValues.azureStorageAccount.accountKey = postProtectedValue(accountKey);

        } else {
            saveValues.path = path;
            saveValues.datasetAutoHide = datasetAutoHide;
            saveValues.datasetAutoArchive = datasetAutoArchive;
            saveValues.datasetAutoArchiveJobWarning = datasetAutoArchiveJobWarning;
            saveValues.datasetAutoArchiveJobError = datasetAutoArchiveJobError;
            saveValues.datasetAutoArchiveJobSoftError = datasetAutoArchiveJobSoftError;

            if (datasetAutoExpireIntervalEnabled) {
                saveValues.datasetAutoExpireInterval = (datasetAutoExpireInterval * 60 * 60 * 1000)
            } else {
                saveValues.datasetAutoExpireInterval = null
            }

            if (!ignoreWarnings) {
                const oldDataRepository = yield select(state => state.dataRepositoryDetailsMap.get(id));
                const intervalChanged = oldDataRepository == null || oldDataRepository.datasetAutoExpireInterval !== saveValues.datasetAutoExpireInterval;

                if (saveValues.datasetAutoExpireInterval != null && intervalChanged) {
                    yield contextCall(this, 'showSaveWarning', {key: popupInfoKeys.DATASET_AUTO_EXPIRE_WARNING});
                }
            }
        }

        return saveValues;
    }

    static* queryDetails() {
        const response = yield contextCall(DataRepositoryApi, 'getDetails')
        const key = details.DATA_REPOSITORIES;

        if (AxiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(DataRepositoryModel.actionCreators.setDetailsMap(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }
}

export default DataRepositoryModel;