import SubsetDetailsModel, {SubsetDetailsApi, SubsetDetailsSaga} from "../generics/SubsetDetailsModel";
import ComponentStateModel from "../generics/ComponentStateModel";
import ClientModel from "./ClientModel";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import {all, put, select, takeLeading} from "redux-saga/effects";
import {
    CLIENT_UNASSIGNED,
    SAME_AS_TRIGGERING_DATASET,
    SAME_AS_TRIGGERING_JOB,
    SAME_AS_TRIGGERING_LEGAL_HOLD,
    userSettings
} from "../../utilities/constants";
import {contextCall, contextSaga, spawnSaga} from "../../saga/sagaFunctions";
import {actionCreator, getEntries, includesSome, postProtectedValue} from "../../utilities/helperFunctions";
import ParameterModel from "../library/ParameterModel";
import DatasetModel from "../data/DatasetModel";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import JobModel, {JobSaga} from "../job/JobModel";
import {
    buildInputTableParameterValues,
    buildInputTableValuesFromMap,
    buildMapFromInputTable
} from "../../components/common/InputTable/InputTable";


class MatterModel extends SubsetDetailsModel {

    static nom = 'MatterModel';
    static parentKey = 'clientId';

    static actions = MatterModel.buildActions('MATTER');
    static actionCreators = MatterModel.buildActionCreators(MatterModel.actions);
    static reducer = MatterModel.buildReducer(MatterModel.actions);

    static componentActionCreators = {
        ...MatterModel.buildComponentActionCreators(),
        // ClientPage Update Display (Used in Sagas)
        updateDisplay: ClientModel.componentActionCreators.updateDisplay
    };

    constructor(model = {}) {
        super(model);
        const {clientId, synchronizeJobs, defaultExecutionProfileId, defaultResourcePoolId, allowedParameterValues, requiredMetadataHeaders, workflowParameters, status} = model;

        this.clientId = clientId;
        this.synchronizeJobs = !!synchronizeJobs;
        this.defaultExecutionProfileId = defaultExecutionProfileId;
        this.defaultResourcePoolId = defaultResourcePoolId;
        this.allowedParameterValues = getEntries(allowedParameterValues);
        this.requiredMetadataHeaders = buildInputTableValuesFromMap(requiredMetadataHeaders);
        this.workflowParameters = buildInputTableParameterValues(workflowParameters);
        this.status = status;
    }

    static buildActions(type) {
        return {
            ...super.buildActions(type),
            QUEUE_MATTER_JOB: 'QUEUE_MATTER_JOB'
        }
    }

    static buildActionCreators(actions) {
        return {
            ...super.buildActionCreators(actions),
            queueMatterJob: actionCreator(actions.QUEUE_MATTER_JOB, 'id')
        }
    }

    static buildComponentActionCreators() {
        const components = [
            {
                key: 'matterTablet',
                type: 'Tablet',
                state: {
                    isDisabled: false
                }
            },
            {
                key: 'matterForm',
                type: 'Form',
                state: {
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }
}

export class MatterApi extends SubsetDetailsApi {
    static location = '/client';
    static type = '/matter';

    static getDatasets(matterId) {
        return axiosInstance.get(`/scheduler/client/matter/${matterId}/datasets`);
    }
}

export class MatterSaga extends SubsetDetailsSaga {

    static ModelType = MatterModel;
    static ModelApi = MatterApi;

    static activationComponent = 'CLIENT_PAGE';
    static variableNames = {
        detailsMap: 'matterDetailsMap',
        instanceId: 'matterId',
        isFormActive: 'isMatterFormActive',
        updateDisplay: 'updateDisplay',
        updatePane: 'updateTablet'
    };

    static translations = {
        itemTitle: '$t(matter:label.name)',
        itemLower: '$t(matter:label.name_lower)'
    };

    static buildActivationEffects(dispatch) {
        return [
            ...super.buildActivationEffects(dispatch),
            takeLeading(MatterModel.actions.QUEUE_MATTER_JOB, spawnSaga, contextSaga(MatterSaga, 'queueMatterJob'))
        ]
    }

    static* queueMatterJob(action) {
        const {id} = action.payload;
        const matter = yield select(state => state.matterDetailsMap.get(id));

        if (matter != null) {
            yield all([
                put(JobModel.componentActionCreators.resetPage()),
                put(ClientModel.componentActionCreators.updateDisplay({matterId: null}))
            ]);

            const initialState = {
                matterId: id,
                clientId: matter.clientId,
                force: {
                    position: 1
                }
            };
            // Navigate to job page
            window.location.href = '#/jobs';
            yield all([
                contextCall(JobSaga, 'getDefaultProfilePool'),
                contextCall(JobSaga, 'showForm', {initialState})
            ]);
        }
    }

    static* hidePane(action) {
        yield super.hidePane(action);

        yield all([
            put(MatterModel.componentActionCreators.resetTablet()),
            put(DatasetModel.componentActionCreators.resetView())
        ]);
    }

    static* getEditValues(id) {
        const {name: matterName, ...rest} = yield select(state => state.matterDetailsMap.get(id));

        return {
            matterName,
            ...rest
        }
    }

    static getSaveValues(values) {
        const {clientId, matterName: name, description, enabled, defaultExecutionProfileId, defaultResourcePoolId, synchronizeJobs} = values;

        const allowedParameterValues = ParameterModel.buildAllowedParameterValues(values.allowedParameterValues);
        const requiredMetadataHeaders = buildMapFromInputTable(values.requiredMetadataHeaders);

        const workflowParameters = {}
        for (let i = 0; i < values.workflowParameters.length; i++) {
            const [{value: name}, {value}] = values.workflowParameters[i];

            workflowParameters[name] = postProtectedValue(value);
        }

        return {
            clientId,
            name,
            description,
            enabled,
            defaultExecutionProfileId,
            defaultResourcePoolId,
            synchronizeJobs,
            allowedParameterValues,
            requiredMetadataHeaders,
            workflowParameters
        }
    }

    static* queryDetailsSubset(action) {
        const {parentId} = action.payload;
        if (parentId == null || includesSome([parentId], [CLIENT_UNASSIGNED, SAME_AS_TRIGGERING_JOB, SAME_AS_TRIGGERING_DATASET, SAME_AS_TRIGGERING_LEGAL_HOLD]))
            return;

        const showDisabled = yield select(state => state.userSettingsMap.get(userSettings.SHOW_DISABLED_ITEMS));
        const response = yield contextCall(MatterApi, 'getDetails', parentId, showDisabled.matters);

        const key = parentId;
        if (AxiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(MatterModel.actionCreators.setSubsetDetailsMap(parentId, response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }

    static* querySettings(action) {
        const {id} = action.payload;
        if (id == null)
            return;

        const response = yield contextCall(MatterApi, 'getDatasets', id);
        if (AxiosProxy.shouldUpdate(id, response)) {
            yield all([
                put(DatasetModel.actionCreators.setSubsetDetailsMap(id, response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(id))
            ]);
        }
    }
}

export default MatterModel;