import {all, call, delay, put, race, take} from 'redux-saga/effects';
import {popupInfoKeys} from "../i18next/keys";
import PopupModel from "../models/scheduler/PopupModel";
import SchedulerModel from "../models/scheduler/SchedulerModel";
import {getKeys, getValues, stringToBool} from "../utilities/helperFunctions";
import {getLocalStorageSubsetContaining, getLocalStorageSubsetWithPrefix} from "../utilities/localStorageHelper";


//DEBOUNCE_TIME must be smaller than idleTime because resetAction is sent AT MOST ONCE every DEBOUNCE_TIME
//resetAction sent -> Must wait DEBOUNCE_TIME before another resetAction can be sent
const COUNTDOWN = 30;
const DEFAULT_IDLE_TIME = 300; //5 minutes
const DEBOUNCE_TIME = 1000;
export const activityEvents = ['mousedown', 'keydown', 'touchstart', 'scroll'];

export default function* (idleTimeLimit = DEFAULT_IDLE_TIME, dispatch) {
    function dispatchReset() {
        dispatch(SchedulerModel.actionCreators.resetIdleTimer());

        // Using localStorage to reset counter between tabs/windows
        const resetBool = stringToBool(localStorage.getItem('idleHandlerResetBool'));
        localStorage.setItem('idleHandlerResetBool', !resetBool);
    }
    //Only send an action every 10 seconds, keep track of any debouncedEvents
    //If an event was debounced then dispatch action once timer finished
    let debounceTimeout, eventWasDebounced;
    function resetIdleTimerEventListener() {

        if (debounceTimeout) {
            eventWasDebounced = true;
        } else {
            dispatchReset();

            debounceTimeout = setTimeout(() => {
                if (eventWasDebounced) {
                    dispatchReset();
                    eventWasDebounced = false;
                }
                debounceTimeout = null;
            }, DEBOUNCE_TIME);
        }
    }

    function resetOnStorageListener(event) {
        if (event.key === 'idleHandlerResetBool') {
            dispatch(SchedulerModel.actionCreators.resetIdleTimer());
        }
    }

    function checkTimeOnWindowFocus() {
        const currentTime = Date.now();
        if (lastResetTime && (currentTime - lastResetTime) > (idleTimeLimit * 1000)) {

            // Effectively delay while idleHandler is paused
            const anyTabsPaused = getValues(getLocalStorageSubsetWithPrefix(pauseKeyPrefix))
                .some(e => e);

            if (!anyTabsPaused) {
                dispatch({type: 'WINDOW_FOCUS_RESET'});
            }
        }
    }

    try {
        activityEvents.forEach(e => document.body.addEventListener(e, resetIdleTimerEventListener));
        window.addEventListener('storage', resetOnStorageListener);
        window.addEventListener('focus', checkTimeOnWindowFocus);

        yield race([
            take('WINDOW_FOCUS_RESET'),
            call(idleWatcher, idleTimeLimit),
        ]);
    } finally {
        activityEvents.forEach(e => document.body.removeEventListener(e, resetIdleTimerEventListener));
        window.removeEventListener('storage', resetOnStorageListener);
        window.removeEventListener('focus', checkTimeOnWindowFocus);
    }
}

let lastResetTime;
function* idleWatcher(idleTime) {
    let resetAction;

    do {
        lastResetTime = Date.now();

        [resetAction] = yield race([
            take(SchedulerModel.actions.RESET_IDLE_TIMER),
            call(idleTimer, idleTime)
        ]);
    } while (resetAction);
}

function* idleTimer(idleTime) {
    // Delay time before showing warning popup (min 5 seconds)
    const delayTime = Math.max(5, idleTime - COUNTDOWN);

    let anyTabsPaused = false;
    do {
        yield delay(delayTime * 1000);
        // Effectively delay while idleHandler is paused
        anyTabsPaused = getValues(getLocalStorageSubsetWithPrefix(pauseKeyPrefix))
            .some(e => e);

    } while (anyTabsPaused);

    // If idleTime is less than COUNTDOWN, - 5 for min effectiveCountdown since delayTime = 5
    const effectiveCountdown = Math.min(idleTime - 5, COUNTDOWN);
    try {
        for (let countdown = effectiveCountdown; countdown > 0; countdown--) {
            yield all([
                put(PopupModel.actionCreators.showWarning({
                    id: 'idleHandlerWarningTimerPopup',
                    info: {
                        key: popupInfoKeys.IDLE_WARNING,
                        values: {
                            timer: countdown
                        }
                    }
                })),
                delay(1000)
            ]);
        }
    } finally {
        yield put(PopupModel.actionCreators.hide('idleHandlerWarningTimerPopup'))
    }
}

export function setIsIdleHandlerPaused(isPaused) {
    localStorage.setItem(pauseId, isPaused);
}

export function setIsIdleHandlerPausedId(id, isPaused) {
    localStorage.setItem(`${pauseId}-${id}`, isPaused);
}

const pauseKeyPrefix = 'idleHandlerPauseBool';
// Clear pauseEntries to prevent build-up
{
    const pauseKeys = getKeys(getLocalStorageSubsetContaining(pauseKeyPrefix));
    if (pauseKeys.length > 20) {
        for (const key of pauseKeys) {
            localStorage.removeItem(key);
        }
    }
}
// Create a tab session id to be used for tracking tab-paused
let pauseId;
{
    const randomArr = new Uint32Array(1);
    do {
        window.crypto.getRandomValues(randomArr);
        pauseId = `${pauseKeyPrefix}_${randomArr[0]}`;

    } while (localStorage.getItem(pauseId) != null);

    // Add tabId to storage
    localStorage.setItem(pauseId, false)
    window.addEventListener('beforeunload', clearTabKeysOnUnload);
}

// Remove unique tabId + pauseKey
function clearTabKeysOnUnload() {
    const keys = getKeys(getLocalStorageSubsetContaining(pauseId));
    for (const key of keys) {
        localStorage.removeItem(key);
    }
    //
    window.removeEventListener('beforeunload', clearTabKeysOnUnload);
}