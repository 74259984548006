import React, {useState} from "react";
import Form, {FormFooter, FormHeader} from "../common/Form/Form";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {createCloseHandler, createInputHandler, createStateHandler} from "../../utilities/componentFunctions";
import PopupModel from "../../models/scheduler/PopupModel";
import UserDataDirModel from "../../models/system/UserDataDirModel";

const initialState = {
    path: ''
};
function SetUserDataDirForm() {
    const {t} = useTranslation(['systemResources']);
    const dispatch = useDispatch();

    const {
        isDisabled
    } = useSelector(state => state.componentStates.userDataDirForm);

    const [state, setState] = useState(initialState);
    const stateHandler = createStateHandler({
        updateState: setState
    })
    const inputHandler = createInputHandler({
        handler: stateHandler
    });

    function onSubmit() {
        dispatch(UserDataDirModel.actionCreators.submitForm(state));
    }

    const onClose = createCloseHandler({
        popupKey: `userDataDirDiscard`,
        state,
        initialState,
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        close: () => dispatch(UserDataDirModel.componentActionCreators.updateDisplay({isFormActive: false}))
    });

    const isAddEnabled = !!(state.path);

    return (
        <Form id="setUserDataDirForm" onClose={onClose}
            isDisabled={isDisabled} closeButtonAriaLabel={t('systemResources:option.closeUserDataDirForm')}
            header={
                <FormHeader text={t('systemResources:label.userDataDir')} iconName={'userDataDir'}
                    isDisabled={isDisabled}/>
            }
            body={
                <>
                    <div className="display-input">
                        <HTMLTextInput label={t('systemResources:label.path')} name={'path'}
                            value={state.path} onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                    </div>
                </>
            }
            footer={
                <FormFooter addText={t('systemResources:option.setPath')} onAddClick={onSubmit}
                    isAddEnabled={isAddEnabled} onCancel={onClose} isDisabled={isDisabled}/>
            }
        />
    )
}

export default SetUserDataDirForm;
