import React, {useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import ExpandableContent from "../common/ExpandableContent/ExpandableContent";
import Text from "../common/Text/Text";
import {useDispatch, useSelector} from "react-redux";
import UserNoticeModel from "../../models/notice/UserNoticeModel";
import {getEntries, getLocaleDateTimeFromUTC, getValues} from "../../utilities/helperFunctions";
import CustomizableCard from "../common/CustomizableCard/CustomizableCard";
import UserNoticeTablet from "./UserNoticeTablet";
import {legalHoldPageViewKeys, legalHoldStateKeys, permissionKeys} from "../../i18next/keys";
import EditModel from "../../models/scheduler/EditModel";
import {put} from "redux-saga/effects";
import {contextCall} from "../../saga/sagaFunctions";
import {NoticeCommentSaga} from "../../models/notice/NoticeCommentModel";
import LegalHoldModel from "../../models/legalhold/LegalHoldModel";
import Switch from "../common/Switch/Switch";
import {icon} from "../../utilities/iconResolver";


function selectOutstandingNotices(state) {
    return state.componentStates.outstandingWorkDisplay.userNoticeIds
        .map(id => state.userNoticeDetailsMap.get(id))
        .filter(userNotice => userNotice && userNotice.outstanding);
}

function selectReplyLegalHoldWorkPanels(state) {
    return buildLegalHoldWorkPanels(
        UserNoticeModel.sortUserNotices(
            selectOutstandingNotices(state).filter(notice => notice.userPermissions.includes(permissionKeys.RESPOND))
        )
    );
}

function selectManageLegalHoldWorkPanels(state) {
    return buildLegalHoldWorkPanels(
        UserNoticeModel.sortUserNotices(
            selectOutstandingNotices(state).filter(notice => notice.userPermissions.includes(permissionKeys.MANAGE))
        )
    );
}

function buildLegalHoldWorkPanels(userNotices) {
    return userNotices.reduce((acc, curr) => {
        let notices = acc[curr.legalHoldId];
        if (notices == null) {
            notices = [];
            acc[curr.legalHoldId] = notices;
        }
        notices.push(curr);
        return acc;
    }, {});
}

function selectOutstandingNoticesCount(state) {
    return selectOutstandingNotices(state)
        .reduce((acc, curr) => {
            if (curr.userPermissions.includes(permissionKeys.RESPOND)) {
                acc[0]++;
            } else if (curr.userPermissions.includes(permissionKeys.MANAGE)) {
                acc[1]++;
            }
            return acc;
        }, [0, 0]);
}

function selectSubjectToAndAdministerCount(state) {
    return getValues(state.legalHoldDetailsMap)
        .reduce((acc, curr) => {
            if (curr.userPermissions.includes(permissionKeys.RESPOND) && curr.state === legalHoldStateKeys.ACTIVE) {
                acc[0]++;
            }
            if (curr.userPermissions.includes(permissionKeys.MANAGE)) {
                acc[1]++;
            }
            return acc;
        }, [0, 0]);
}


function LegalHoldOverviewDisplay() {
    const {t} = useTranslation(['legalHold', 'notice', 'common']);
    const dispatch = useDispatch();

    const replyLegalHoldWorkPanels = useSelector(selectReplyLegalHoldWorkPanels);
    const manageLegalHoldWorkPanels = useSelector(selectManageLegalHoldWorkPanels);
    const [replyNoticesCount, manageNoticesCount] = useSelector(selectOutstandingNoticesCount);
    const [subjectToCount, administerCount] = useSelector(selectSubjectToAndAdministerCount);

    const {
        legalHoldId,
        userNoticeId
    } = useSelector(state => state.componentStates.userNoticeView);



    useEffect(() => {
        dispatch(UserNoticeModel.actionCreators.startPollingOutstandingNotices());

        return () => dispatch(UserNoticeModel.actionCreators.stopPollingOutstandingNotices());
    }, [dispatch]);


    const navigateToMattersView = useCallback(() => {
        dispatch(LegalHoldModel.componentActionCreators.updatePage({activeView: legalHoldPageViewKeys.MATTERS}));
    }, []);

    const showWorkerTablet = useCallback(event => {
        const {value} = event.currentTarget.dataset;
        let parentId, parent = event.currentTarget.parentNode;

        do {
            parentId = (parent.dataset || {}).parentid;
            parent = parent.parentNode;
        } while (!parentId && parent);

        dispatch(UserNoticeModel.componentActionCreators.updateView({
            legalHoldId: parentId,
            userNoticeId: value
        }));
    }, [dispatch]);

    const closeWorkerTablet = useCallback(() => {
        dispatch(EditModel.actionCreators.callEditBreakingFunc(function* () {
            const updateEffect = put(UserNoticeModel.componentActionCreators.resetView());
            yield contextCall(NoticeCommentSaga, 'callCommentFormDiscardingEffect', updateEffect);
        }));
    }, []);


    return (
        <section className="work-display" id={'legalHoldOverviewDisplay'}>
            <section className="work-display-header">
                <h1 className="subtitle is-bold">
                    {t('legalHold:label.overview')}
                </h1>

                {(subjectToCount > 0 || administerCount > 0) &&
                <p className="label">
                    <Switch>
                        {administerCount > 0 && subjectToCount > 0 &&
                            t(`legalHold:message.administerAndSubjectToNHolds_${administerCount > 1 ? '2' : '1'}_${subjectToCount > 1 ? '2' : '1'}`, {administerCount, subjectToCount})
                        }
                        {administerCount > 0 &&
                            t('legalHold:message.administerNHolds', {count: administerCount})
                        }
                        {subjectToCount > 0 &&
                            t('legalHold:message.subjectToNHolds', {count: subjectToCount})
                        }
                    </Switch>
                        <a href="/#legalHold" onClick={navigateToMattersView} style={{marginLeft: '0.5rem'}}>
                            {t('legalHold:option.viewLegalHolds')}
                        </a>
                </p>
                }

                {replyNoticesCount > 0 &&
                    <Text value={t('legalHold:message.nNoticesRequireResponse', {count: replyNoticesCount})}/>
                }
                {manageNoticesCount > 0 &&
                    <Text value={t('legalHold:message.nNoticesWithUserComments', {count: manageNoticesCount})}/>
                }
            </section>

            {replyNoticesCount === 0 && manageNoticesCount === 0 &&
                <Text className="empty-queue-message"
                    value={t('legalHold:message.noMoreOutstandingWork')} isItalic isCenter/>
            }

            <section className="work-header-container">
                {replyNoticesCount > 0 &&
                <section className="work-header">
                    <label className="heading is-bold">
                        {t('legalHold:label.respond')}
                    </label>
                </section>
                }

                {manageNoticesCount > 0 &&
                <section className="work-header">
                    <label className="heading is-bold">
                        {t('legalHold:label.manage')}
                    </label>
                </section>
                }
            </section>

            <section className="work-container">
                {replyNoticesCount > 0 &&
                <section role="listbox" aria-label={t('legalHold:label.respond')} className="work-queue">
                    {getEntries(replyLegalHoldWorkPanels).map(([legalHoldId, userNotices]) =>
                        <LegalHoldWorkPanel key={legalHoldId} legalHoldId={legalHoldId} userNotices={userNotices}
                            showWorkerTablet={showWorkerTablet} activeUserNoticeId={userNoticeId}/>
                    )}
                </section>
                }

                {manageNoticesCount > 0 &&
                <section role="listbox" aria-label={t('legalHold:label.manage')} className="work-queue">
                    {getEntries(manageLegalHoldWorkPanels).map(([legalHoldId, userNotices]) =>
                        <LegalHoldWorkPanel key={legalHoldId} legalHoldId={legalHoldId} userNotices={userNotices}
                            showWorkerTablet={showWorkerTablet} activeUserNoticeId={userNoticeId}/>
                    )}
                </section>
                }
            </section>

            {legalHoldId != null && userNoticeId != null &&
            <UserNoticeTablet legalHoldId={legalHoldId} userNoticeId={userNoticeId}
                onClose={closeWorkerTablet}/>
            }
        </section>
    )
}


function LegalHoldWorkPanel(props) {

    const {
        legalHoldId,
        userNotices,

        showWorkerTablet,
        activeUserNoticeId
    } = props;

    const {
        name: legalHoldName
    } = useSelector(state => state.legalHoldDetailsMap.get(legalHoldId)) || {};

    return (
        <ExpandableContent titleName={legalHoldName} className={'work-panel'} useHeaderClick
            headerBarStyle={{display: 'flex'}}
            headerBar={
                <section className="work-panel-header">
                    <Text value={legalHoldName} isHeading/>
                </section>
            }
        >
            <section role="list" className="work-panel-body"
                data-parentid={legalHoldId} aria-label={legalHoldName}
            >
                {userNotices.map(userNotice =>
                    <UserNoticeCard key={userNotice.id} userNotice={userNotice} showWorkerTablet={showWorkerTablet}
                        isActive={activeUserNoticeId === userNotice.id}/>
                )}
            </section>
        </ExpandableContent>
    )
}

function UserNoticeCard(props) {
    const {t} = useTranslation(['aria']);
    const {
        userNotice,
        showWorkerTablet,
        isActive
    } = props;

    const {
        id,
        type,
        subject,

        sentDate
    } = userNotice;


    return (
        <CustomizableCard name={'userNoticeCard'} value={id} isActive={isActive} onClick={showWorkerTablet}
            bottomLeft={
                <Text value={getLocaleDateTimeFromUTC(sentDate)}/>
            }
            bottomRight={
                <span className={'icon'}>
                    <img src={icon(`noticeType${type}`)} alt={t(`aria:hiddenAssistText.${type}NoticeIcon`)}/>
                </span>
            }
            topLeft={
                <Text value={subject} isBold/>
            }
        />
    )
}

export default LegalHoldOverviewDisplay;
