import React from "react";
import ThirdPartyServiceModel from "../../../models/thirdparty/ThirdPartyServiceModel";
import ThirdPartyServiceForm, {ThirdPartyServiceFormBody} from "../ThirdPartyServiceForm";
import {useTranslation} from "react-i18next";
import ExpandableContent from "../../common/ExpandableContent/ExpandableContent";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import Checkbox from "../../common/Checkbox/Checkbox";
import Text from "../../common/Text/Text";
import {FormElementRow} from "../../common/CustomTable/CustomTable";

const initialState = {
    type: ThirdPartyServiceModel.Type.SMTP,
    serviceName: '',
    enabled: true,
    description: '',
    whitelistedCertFingerprints: [],

    host: '',
    port: 587,
    from: '',
    tls: true,

    emailRetryInterval: 12,
    emailMaxAttempts: 12,

    authenticationScope: ThirdPartyServiceModel.AuthenticationScope.SERVICE,
    authenticationMethod: ThirdPartyServiceModel.AuthenticationMethod.USERNAME_PASSWORD
};

const allowedAuthenticationMethods = [
    ThirdPartyServiceModel.AuthenticationMethod.USERNAME_PASSWORD,
    ThirdPartyServiceModel.AuthenticationMethod.NONE
];
const allowedAuthenticationScopes = [
    ThirdPartyServiceModel.AuthenticationScope.SERVICE
];

function SmtpServiceForm() {
    return (
        <ThirdPartyServiceForm initialState={initialState} FormBody={SmtpServiceFormBody}/>
    )
}

export function SmtpServiceFormBody(props) {
    return (
        <ThirdPartyServiceFormBody {...props} allowedAuthenticationMethods={allowedAuthenticationMethods}
            allowedAuthenticationScopes={allowedAuthenticationScopes} FormBody={FormBody}/>
    )
}

function FormBody(props) {
    const {t} = useTranslation(['thirdPartyService', 'common']);
    const {
        thirdPartyService,

        inputHandler,
        toggleHandler,
        isDisabled
    } = props;

    return (
        <ExpandableContent label={t('thirdPartyService:label.smtpSettings')} isDisabled={isDisabled}>
            <div className="flex space-between display-input">
                <div style={{width: 'calc(100% - 7.5rem'}}>
                    <HTMLTextInput label={t('thirdPartyService:label.host')} name={'host'} value={thirdPartyService.host}
                        onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                </div>

                <div style={{width: '5rem'}}>
                    <HTMLTextInput label={t('thirdPartyService:label.port')} name={'port'} type={'number'} value={thirdPartyService.port}
                        onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                </div>
            </div>

            <div className="display-input">
                <HTMLTextInput label={t('thirdPartyService:label.from')} name={'from'} value={thirdPartyService.from}
                    onChange={inputHandler} isDisabled={isDisabled} isRequired/>
            </div>

            <div className="inline-checkboxes">
                <Checkbox label={t('thirdPartyService:label.tls')} name={'tls'} checked={thirdPartyService.tls}
                    onClick={toggleHandler} isDisabled={isDisabled}/>
            </div>

            <div className="forms-table">
                <div className="table-row-group">
                    <FormElementRow labelId={'smtpService:emailRetryInterval'} label={t('thirdPartyService:label.emailRetryInterval')}
                        isDisabled={isDisabled}
                        element={
                            <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                <HTMLTextInput id={'smtpEmailRetryInterval'} style={{width: '8rem'}}
                                    type="number" name={'emailRetryInterval'} value={thirdPartyService.emailRetryInterval} onChange={inputHandler}
                                    aria-labelledby={'smtpService:emailRetryInterval smtpService:hours'}
                                    isInvalid={thirdPartyService.emailRetryInterval <= 0} isDisabled={isDisabled}/>

                                <Text id={'smtpService:hours'} value={t('common:label.hours')} style={{marginLeft: '0.75rem'}}
                                    isDisabled={isDisabled}/>
                            </div>
                        }/>

                    <FormElementRow labelId={'smtpService:emailMaxAttempts'} label={t('thirdPartyService:label.emailMaxAttempts')}
                        isDisabled={isDisabled}
                        element={
                            <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                <HTMLTextInput id={'smtpEmailMaxAttempts'} style={{width: '8rem'}}
                                    type="number" name={'emailMaxAttempts'} value={thirdPartyService.emailMaxAttempts} onChange={inputHandler}
                                    aria-labelledby={'smtpService:emailMaxAttempts'}
                                    isInvalid={thirdPartyService.emailMaxAttempts < 0} isDisabled={isDisabled}/>
                            </div>
                        }/>
                </div>
            </div>
        </ExpandableContent>
    );
}

export default SmtpServiceForm;
