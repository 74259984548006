import React from 'react'
import {useTranslation} from "react-i18next";
import DateFilterTypeDropdown from "../../Dropdown/DateFilterDropdown/DateFilterTypeDropdown";
import {DateFilterType} from "../../../utilities/constants";
import {QuestionIngestionDateInput} from "../../Question/QuestionInput/QuestionInput";
import DateFilterScopeDropdown from "../../Dropdown/DateFilterDropdown/DateFilterScopeDropdown";
import {createDropdownHandler} from "../../../../components/common/Dropdown/helpers";
import {createInputHandler} from "../../../../utilities/componentFunctions";
import DateFilterCriteriaDropdown from "../../Dropdown/DateFilterDropdown/DateFilterCriteriaDropdown";

export function DateFilterOptions (props) {
    const {t} = useTranslation(['wizard']);
    const {
        updateState,
        useDateFilter,
        dateFilterLevel,
        dateFilterAction,
        dateFilterCriteria,
        dateFilterActionName,
        dateFilterActionScope,
        dateFilterType,
        dateFilterScope,
        filterAfterDate,
        filterBeforeDate
    } = props;

    const dropdownHandler = createDropdownHandler({
        handler: updateState
    })

    return (
        <>
            {useDateFilter &&
                <>

                        <CaseLevelDateFilter
                        t={t}
                        dateFilterType={dateFilterType}
                        dateFilterAction={dateFilterAction}
                        dateFilterCriteria={dateFilterCriteria}
                        filterBeforeDate={filterBeforeDate}
                        filterAfterDate={filterAfterDate}
                        updateState={updateState}
                        dateFilterActionName={dateFilterActionName}
                        dateFilterActionScope={dateFilterActionScope}/>

                </>
            }
        </>
    )

}

function IngestionLevelDateFilter (props) {
    const {
        t,
        dateFilterType,
        filterAfterDate,
        filterBeforeDate,
        dateFilterScope,
        updateState
    } = props;

    const dropdownHandler = createDropdownHandler({
        handler: updateState
    })
    const inputHandler = createInputHandler({
        handler: updateState
    })

    return (
        <>
            <div className={"display-item"}>
                <label className="label is-heading">
                    {t('wizard:questions.advancedIngestionSettings.dateFilterMode')}
                </label>
                <div className={'settings-table no-border tight-padding'} style={{border: '1px solid transparent'}}>
                    <div className="table-row-group">
                        <div key={"question-row-type"} className="settings-row">
                            <div key={"question-cell-type"} className="table-cell no-stretch">
                                <DateFilterTypeDropdown
                                    selectedDateFilterTypeId={dateFilterType}
                                    onDateFilterTypeSelected={dropdownHandler}
                                    isRequired={true}
                                    isDisabled={false}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {(dateFilterType !== DateFilterType.NONE) &&
                <div className={"dynamic-date-container"}>
                    <QuestionIngestionDateInput
                        name={"dateFilterValues"}
                        onChange={inputHandler}
                        afterDateTimeValue={filterAfterDate}
                        beforeDateTimeValue={filterBeforeDate}
                        dateFilterType={dateFilterType} />
                </div>
            }

            {(dateFilterType !== DateFilterType.NONE) &&
                <div className={"display-item"}>
                    <label className="label is-heading">
                        {t('wizard:questions.advancedIngestionSettings.dateFilterScope')}
                    </label>
                    <div className={'settings-table no-border tight-padding'} style={{border: '1px solid transparent'}}>
                        <div className="table-row-group">
                            <div key={"question-row-scope"} className="settings-row">
                                <div key={"question-cell-scope"} className="table-cell no-stretch">
                                    <DateFilterScopeDropdown
                                        selectedDateFilterScopeId={dateFilterScope}
                                        onDateFilterScopeSelected={dropdownHandler}
                                        isRequired={true}
                                        isDisabled={false}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

function CaseLevelDateFilter (props) {
    const {
        t,
        updateState,
        dateFilterType,
        filterAfterDate,
        filterBeforeDate,
        dateFilterAction,
        dateFilterActionName,
        dateFilterCriteria,
        dateFilterActionScope
    } = props;

    const dropdownHandler = createDropdownHandler({
        handler: updateState
    })
    const inputHandler = createInputHandler({
        handler: updateState
    })

    const actionNameInputProps = {
        name: "dateFilterActionName",
        value: dateFilterActionName,
        onChange: inputHandler,
        isRequired: true,
        isDisabled: false
    }

    return (
        <>
            <div className={"display-item"}>
                <label className="label is-heading">
                    {t('wizard:questions.advancedIngestionSettings.dateFilterMode')}
                </label>
                <div className={'settings-table no-border tight-padding'} style={{border: '1px solid transparent'}}>
                    <div className="table-row-group">
                        <div key={"question-row-type"} className="table-row align-middle no-hover">
                            <div key={"question-cell-type"} className="table-cell no-stretch">
                                <DateFilterTypeDropdown
                                    selectedDateFilterTypeId={dateFilterType}
                                    onDateFilterTypeSelected={dropdownHandler}
                                    isRequired={true}
                                    isDisabled={false}/>
                            </div>
                            <div key={"question-cell-type-date"} className="table-cell">
                                {(dateFilterType !== DateFilterType.NONE) &&
                                    <QuestionIngestionDateInput
                                        name={"dateFilterValues"}
                                        onChange={inputHandler}
                                        afterDateTimeValue={filterAfterDate}
                                        beforeDateTimeValue={filterBeforeDate}
                                        dateFilterType={dateFilterType} />
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {(dateFilterType !== DateFilterType.NONE) &&
            <div className={"display-item"}>
                <label className="label is-heading">
                    {t('wizard:questions.advancedIngestionSettings.dateFilterCriteriaTitle')}
                </label>
                <div className={'settings-table no-border tight-padding'} style={{border: '1px solid transparent'}}>
                    <div className="table-row-group">
                        <div key={"question-row-type"} className="settings-row">
                            <div key={"question-cell-type"} className="table-cell no-stretch">
                                <DateFilterCriteriaDropdown
                                    selectedDateFilterCriteria={dateFilterCriteria}
                                    onDateFilterCriteriaSelected={dropdownHandler}
                                    isRequired={true}
                                    isDisabled={false}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }

        </>
    )
}