import React, {useLayoutEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Button} from "../common/Button/Button";
import RelativityProxyModel from "../../models/relativity/RelativityProxyModel";
import RelativityProxyForm from "./RelativityProxyForm";
import {SettingsRowValue} from "../common/CustomTable/CustomTable";
import {applicationFeatures} from "../../utilities/constants";
import {MainPageContent, MainPageHeader} from "../app/pages/MainPage";

function RelativityProxyDisplay(props) {
    const {t} = useTranslation(['relativityProxy', 'common']);
    const dispatch = useDispatch();

    const {
        ...attr
    } = props;

    const {
        isFormActive
    } = useSelector(state => state.componentStates.relativityProxyDisplay);

    const relativityProxy = useSelector(state => state.relativityProxy);
    const userFeatures = useSelector(state => state.currentUser.features);

    useLayoutEffect(() => {
        dispatch(RelativityProxyModel.componentActionCreators.setDisplayActive());
        return () => dispatch(RelativityProxyModel.componentActionCreators.setDisplayInactive());
    }, [dispatch]);

    function showUpdateConfigurationForm() {
        dispatch(RelativityProxyModel.componentActionCreators.updateDisplay({isFormActive: true}))
    }

    const canModify = userFeatures == null || userFeatures.includes(applicationFeatures.ADD_RESOURCE);

    return (
        <MainPageContent {...attr}
            title={
                <>
                    <MainPageHeader text={t('relativityProxy:page.title')}
                        description={t('relativityProxy:page.description')}/>

                    {canModify &&
                        <div className="add-button-header">
                            <Button label={t('relativityProxy:option.updateConfiguration')}
                                onClick={showUpdateConfigurationForm}/>
                        </div>
                    }
                </>
            }
            list={
                <>
                    <div className="settings-table align-top">
                        <div className="table-row-group">
                            <SettingsRowValue label={t('relativityProxy:label.apiUri')} value={relativityProxy.apiUri || t('relativityProxy:label.configurationNotFound')}/>
                            <SettingsRowValue label={t('relativityProxy:label.state')} value={relativityProxy.state}/>
                            <SettingsRowValue label={t('relativityProxy:label.message')} value={relativityProxy.message}/>
                        </div>
                    </div>
                </>
            }
            children={
                isFormActive &&
                    <RelativityProxyForm/>
            }
        />
    )
}

export default RelativityProxyDisplay;
