import React from 'react';
import {buildClassName} from "../../../utilities/helperFunctions";
import {getInvalidMessageSpan} from "../InvalidMessageSpan";
import {useGetMissingClasses} from "../AccessibilityAssist";
import {Label} from "../Text/Text";


const TextArea = React.forwardRef(function (props, ref) {
    const {
        text,
        label=text,
        value,
        rows = 4,
        afterLabel,
        requiredMessage,
        'aria-labelledby': ariaLabelledBy,
        'aria-label': ariaLabel,

        expandContainer,
        isRequired,
        isReadOnly,
        isSelected,
        isInvalid,
        isDisabled,
        ...attr
    } = props;


    const id = attr.id || attr.name;
    const labelId = label && `${id}:label`;
    const required = isRequired && !value && !isDisabled;

    const {
        combinedAriaLabel,
        combinedAriaLabelledBy,
        invalidMessageSpan
    } = getInvalidMessageSpan({
        id,
        ariaLabel,
        ariaLabelledBy: [labelId, ariaLabelledBy],
        isRequired: required,
        requiredMessage
    });

    const textAreaClassName = buildClassName(
        'textarea',
        required && 'is-required',
        isSelected && 'is-selected',
        isReadOnly && 'is-readonly',
        isDisabled && 'is-disabled',
        useGetMissingClasses(label, {ariaLabel: combinedAriaLabel, ariaLabelledBy: combinedAriaLabelledBy})
    );
    const inputContainerClassName = buildClassName(
        'input-container',
        expandContainer && 'expand'
    );

    const textArea = (
        <textarea ref={ref} id={id} className={textAreaClassName} value={value} rows={rows} title={combinedAriaLabel}
            aria-label={combinedAriaLabel} aria-labelledby={combinedAriaLabelledBy} aria-invalid={required}
            required={isRequired} disabled={isDisabled || isReadOnly} {...attr}
        />
    );


    if (afterLabel && label) {
        return (
            <div className={inputContainerClassName}>
                <div className="flex-center">
                    <Label id={labelId} value={label} isDisabled={isDisabled}/>
                    {afterLabel}
                </div>

                {textArea}
                {invalidMessageSpan}
            </div>
        )
    }

    return (
        <div className={inputContainerClassName}>
            {label ?
                <Label id={labelId} htmlFor={id} value={label}
                    isDisabled={isDisabled}
                >
                    {textArea}
                </Label>
            :
                textArea
            }

            {invalidMessageSpan}
        </div>
    )
});

export default TextArea;
