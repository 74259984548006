import {useTranslation} from "react-i18next";
import React, {useRef, useState} from "react";
import {
    initialSelectedState,
    useClearSelectedEffect,
    useTabNavigateEffect,
    useValueSelectHandler
} from "../../../utilities/hooks";
import {createInputHandler} from "../../../utilities/componentFunctions";
import {createToggleHandler} from "../../common/Checkbox/helpers";
import {createDropdownHandler} from "../../common/Dropdown/helpers";
import NoticeConfiguration from "../../../models/notice/NoticeConfiguration";
import {
    buildClassName,
    getNewArrayWithUpdatedValue,
    getParentDatasetAttr,
    objectTruthyValues
} from "../../../utilities/helperFunctions";
import {legalHoldStateKeys} from "../../../i18next/keys";
import {AddRemoveButtons} from "../../common/Button/Button";
import NoticeConfigurationTable from "../../notice/NoticeConfigurationTable";
import {noticeTypeKeys} from "../../../utilities/constants";

function SurveyNoticeConfigurationTable(props) {
    const {t} = useTranslation(['legalHold']);

    const {
        state,
        surveyNoticeConfigurations,
        setSurveyNoticeConfigurations,

        isReadOnly,
        isDisabled
    } = props;

    const containerRef = useRef();
    const [selected, setSelected] = useState(initialSelectedState);
    const [lastAddedIndex, setLastAddedIndex] = useState();

    const valueSelectHandler = useValueSelectHandler({setSelected});
    useClearSelectedEffect({containerRef, setSelected});
    useTabNavigateEffect({containerRef});

    const inputHandler = createInputHandler({
        handler
    });
    const toggleHandler = createToggleHandler({
        handler
    });
    const dropdownHandler = createDropdownHandler({
        handler
    });

    function addRow() {
        setSurveyNoticeConfigurations([
            ...surveyNoticeConfigurations,
            new NoticeConfiguration({enabled: true})
        ]);
        setLastAddedIndex(surveyNoticeConfigurations.length);
    }

    function removeSelectedRows() {
        const selectedRowIndices = objectTruthyValues(selected.values);
        setSurveyNoticeConfigurations(
            surveyNoticeConfigurations.filter((config, index) => config.noticeId != null || !selectedRowIndices.includes(index.toString()))
        );

        setSelected(prevSelected => ({
            ...prevSelected,
            values: {},
            lastSelectedValue: null
        }));
    }

    function handler(updates, event) {
        const index = getParentDatasetAttr(event.target || event.currentTarget, 'index');
        const newConfiguration = surveyNoticeConfigurations[index].duplicate(updates);
        // update row
        setSurveyNoticeConfigurations(getNewArrayWithUpdatedValue(surveyNoticeConfigurations, newConfiguration, index));
    }


    const tableClassName = buildClassName(
        'display-table',
        'align-top',
        isReadOnly && 'is-readonly'
    );

    const disableSelection = isDisabled || isReadOnly;
    const canRemove = objectTruthyValues(selected.values).length > 0;
    const isActive = state === legalHoldStateKeys.ACTIVE;

    return (
        <div ref={containerRef} id="surveyNoticeConfigurationsTable">
            <AddRemoveButtons label={t('legalHold:label.surveyNoticeConfigurations')} onAddClick={addRow} noPadding
                ariaLabelKey={'SurveyNoticeConfiguration'} onRemoveClick={removeSelectedRows}
                isRemoveDisabled={!canRemove} isReadOnly={isReadOnly} isDisabled={isDisabled}
            />


            <div className={tableClassName}>
                <div className="table-header-group">
                    <div className="table-header"/>
                </div>

                <div className="table-row-group">
                    {surveyNoticeConfigurations.map((config, index) => {
                        const _disableSelection = !!(config.noticeId != null || disableSelection);

                        const isSelected = !_disableSelection && selected.values[index];
                        const _tableRowClassName = buildClassName(
                            'table-row',
                            _disableSelection && 'no-hover'
                        );

                        const _lastAddedIndex = index === lastAddedIndex ? index : null;
                        const _onClick = _disableSelection ? null : valueSelectHandler

                        return (
                            <div className={_tableRowClassName} key={index}>
                                <div className="table-cell no-padding no-border">
                                    <NoticeConfigurationTable id={`surveyNoticeConfigurationRow_${index}_`} data-index={index}
                                        lastAddedIndex={_lastAddedIndex} onClick={_onClick} type={noticeTypeKeys.SURVEY}
                                        noticeConfiguration={config} inputHandler={inputHandler} toggleHandler={toggleHandler}
                                        dropdownHandler={dropdownHandler} isLegalHoldActive={isActive}
                                        isSelected={isSelected} isDisabled={isDisabled}/>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default SurveyNoticeConfigurationTable;
