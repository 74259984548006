import React from "react";
import {useDispatch, useSelector} from "react-redux";
import NotificationRuleModel from "../../../models/settings/NotificationRuleModel";
import EmailNotificationRuleRow from "./EmailNotificationRuleRow";
import {applicationFeatures} from "../../../utilities/constants";
import {useTranslation} from "react-i18next";
import {AddButtonHeader} from "../../common/Button/Button";
import {getValues, includesSome, isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";

function EmailNotificationRuleTable() {
    const {t} = useTranslation(['aria', 'notificationRule']);
    const dispatch = useDispatch();

    const userFeatures = useSelector(state => state.currentUser.features);
    const emailNotificationRules = useSelector(state => {
        return getValues(state.notificationRuleDetailsMap)
            .filter(rule => rule.ruleType === NotificationRuleModel.Type.EMAIL_SMTP);
    });

    const canAddNotificationRule = includesSome(userFeatures, [applicationFeatures.ADD_RESOURCE, applicationFeatures.ADD_NOTIFICATION_RULE]);
    const isNotEmpty = isNotEmptyNorFalsy(emailNotificationRules);

    function showForm() {
        dispatch(NotificationRuleModel.componentActionCreators.updateDisplay({isEmailNotificationRuleFormActive: true}));
    }

    if (!canAddNotificationRule && !isNotEmpty) {
        return null;
    }


    return (
        <div className="display-item">
            <AddButtonHeader aria-label={t('aria:hiddenAssistText.addEmailNotificationRule')} text={t('notificationRule:label.emailName')}
                canModify={canAddNotificationRule} onClick={showForm}/>

            {isNotEmpty &&
                <div className="display-table">
                    <div className="table-header-group">

                        <div className="table-header">
                            <label className="label is-bold">
                                {t('common:label.name')}
                            </label>
                        </div>

                        <div className="table-header">
                            <label className="label is-bold">
                                {t('notificationRule:label.smtpService')}
                            </label>
                        </div>

                        <div className="table-header">
                            <label className="label is-bold">
                                {t('notificationRule:label.to')}
                            </label>
                        </div>

                        <div className="table-header">
                            <label className="label is-bold">
                                {t('notificationRule:label.triggers')}
                            </label>
                        </div>

                    </div>

                    <div className="table-row-group">
                        {emailNotificationRules.map(rule =>
                            <EmailNotificationRuleRow key={rule.id} rule={rule}/>
                        )}
                    </div>
                </div>
            }
        </div>
    )
}

export default EmailNotificationRuleTable;
