/** @jsxRuntime classic */
/** @jsx h */

const { h } = require('preact')
const classNames = require('classnames')
const VirtualList = require('@uppy/dashboard/lib/components/VirtualList')

import FileItem from './FileItem/index.js';
import Folder from "./Folder";
import {getValues} from "../../../../../utilities/helperFunctions";

function chunks (list, size) {
  const chunked = []
  let currentChunk = []
  list.forEach((item) => {
    if (currentChunk.length < size) {
      currentChunk.push(item)
    } else {
      chunked.push(currentChunk)
      currentChunk = [item]
    }
  })
  if (currentChunk.length) chunked.push(currentChunk)
  return chunked
}

export default (props) => {
  const noFiles = props.totalFileCount === 0
  const dashboardFilesClass = classNames(
    'uppy-Dashboard-files',
    { 'uppy-Dashboard-files--noFiles': noFiles },
  )

  // It's not great that this is hardcoded!
  // It's ESPECIALLY not great that this is checking against `itemsPerRow`!
  const rowHeight = props.itemsPerRow === 1
    // Mobile
    ? 71
    // 190px height + 2 * 5px margin
    : 200

  const fileProps = {
    // FIXME This is confusing, it's actually the Dashboard's plugin ID
    id: props.id,
    error: props.error,
    // TODO move this to context
    i18n: props.i18n,
    uppy: props.uppy,
    // features
    acquirers: props.acquirers,
    resumableUploads: props.resumableUploads,
    individualCancellation: props.individualCancellation,
    // visual options
    hideRetryButton: props.hideRetryButton,
    hidePauseResumeButton: props.hidePauseResumeButton,
    hideCancelButton: props.hideCancelButton,
    showLinkToFileUploadResult: props.showLinkToFileUploadResult,
    showRemoveButtonAfterComplete: props.showRemoveButtonAfterComplete,
    isWide: props.isWide,
    metaFields: props.metaFields,
    recoveredState: props.recoveredState,
    // callbacks
    toggleFileCard: props.toggleFileCard,
    handleRequestThumbnail: props.handleRequestThumbnail,
    handleCancelThumbnail: props.handleCancelThumbnail,
  }

  const sortByGhostComesFirst = (file1, file2) => {
    return props.files[file2].isGhost - props.files[file1].isGhost
  }

  const files = Object.keys(props.files)
  // Sort files by file.isGhost, ghost files first, only if recoveredState is present
  if (props.recoveredState) files.sort(sortByGhostComesFirst)

  const foldersAndFiles = files.map(fileId => props.files[fileId]).reduce((acc, curr) => {
    let isFolderFile = false;

    const pathSplit = curr.meta.relativePath.split('/');
    // If > 1, file is from a folder
    if (pathSplit.length > 1) {
      const rootFolderPath = pathSplit[0];
      if (acc[rootFolderPath] == null) {
        acc[rootFolderPath] = new Folder(curr.source, rootFolderPath);
      }
      isFolderFile = true;
      acc[rootFolderPath].addFile(curr);
    }

    if (!isFolderFile) {
      acc[curr.id] = curr;
    }
    return acc;
  }, {});

  const fileValues = getValues(foldersAndFiles);
  for (const file of fileValues) {
    if (file.type === 'folder') file.calculate();
  }
  const rows = chunks(fileValues, props.itemsPerRow)

  const renderRow = (row) => (
    // The `role="presentation` attribute ensures that the list items are properly
    // associated with the `VirtualList` element.
    // We use the first file ID as the key—this should not change across scroll rerenders
    <div role="presentation" key={row[0]}>
      {row.map(file => (
        <FileItem
          key={file.id}
          uppy={props.uppy}
          {...fileProps}
          role="listitem"
          openFileEditor={props.openFileEditor}
          canEditFile={props.canEditFile}
          toggleAddFilesPanel={props.toggleAddFilesPanel}
          file={file}
          dispatch={props.dispatch}
        />
      ))}
    </div>
  )

  return (
    <VirtualList
      class={dashboardFilesClass}
      role="list"
      data={rows}
      renderRow={renderRow}
      rowHeight={rowHeight}
    />
  )
}
