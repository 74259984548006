import SubsetDetailsModel, {SubsetDetailsApi, SubsetDetailsSaga} from "../generics/SubsetDetailsModel";
import ComponentStateModel from "../generics/ComponentStateModel";
import LibraryModel from "./LibraryModel";
import ParameterModel from "./ParameterModel";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import TemplateOperationModel from "./TemplateOperationModel";
import {
    actionCreator,
    capitalize,
    deepCopy,
    getKeys,
    getValues,
    includesSome,
    isNotEmptyNorFalsy,
    lowerCaseFirstLetter
} from "../../utilities/helperFunctions";
import {all, call, cancel, fork, put, select, take, takeLatest, takeLeading} from "redux-saga/effects";
import {genericErrorHandler, tryCatchWrapper} from "../../saga/tryCatchWrapper";
import {contextCall, contextSaga, isDisabledWrapper, serverErrorResponseValues} from "../../saga/sagaFunctions";
import {
    applicationFeatures,
    datasetType,
    jobQueueStates,
    pathSplitterRegex,
    SAME_AS_TRIGGERING_DATASET,
    SAME_AS_TRIGGERING_JOB,
    SAME_AS_TRIGGERING_LEGAL_HOLD,
    userSettings
} from "../../utilities/constants";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import {MatterSaga} from "../client/MatterModel";
import {FileLibrarySaga} from "../filelibrary/FileLibraryModel";
import {permissionKeys, scheduleTriggerTypeKeys} from "../../i18next/keys";
import SchedulerModel from "../scheduler/SchedulerModel";
import PopupModel from "../scheduler/PopupModel";
import ClientModel from "../client/ClientModel";
import DatasetModel from "../data/DatasetModel";
import CurrentUserModel from "../user/CurrentUserModel";
import i18next from "i18next";
import {UserServiceSaga} from "../user/UserServiceModel";
import AuditLogModel from "../generics/AuditLogModel";
import {DataRepositorySaga} from "../data/DataRepositoryModel";
import ThirdPartyServiceModel, {ThirdPartyServiceSaga} from "../thirdparty/ThirdPartyServiceModel";
import {workflowIconModel} from "./WorkflowTemplateIconModel";

class WorkflowTemplateModel extends SubsetDetailsModel {

    static nom = 'WorkflowTemplateModel';
    static parentKey = 'libraryId';

    static actions = WorkflowTemplateModel.buildActions();
    static actionCreators = WorkflowTemplateModel.buildActionCreators(WorkflowTemplateModel.actions);
    static reducer = WorkflowTemplateModel.buildReducer(WorkflowTemplateModel.actions);

    static componentActionCreators = {
        ...WorkflowTemplateModel.buildComponentActionCreators(),
        // LibraryPage Update Display (Used in Sagas)
        updateDisplay: LibraryModel.componentActionCreators.updateDisplay
    };

    constructor(model = {}) {
        super(model);
        const {libraryId, executionMode, prerequisites, usage} = model;

        this.libraryId = libraryId;
        this.explicitExecutionMode = model.explicitExecutionMode;
        this.executionMode = executionMode;
        this.prerequisites = prerequisites;
        this.usage = usage;
    }

    static ExecutionMode = {
        AUTOMATE_NATIVE: 'AUTOMATE_NATIVE',
        AUTOMATE_NUIX: 'AUTOMATE_NUIX',
        AUTODETECT: 'AUTODETECT'
    };

    static validateFormData(formData, state) {
        return !!(formData.workflowName && formData.operationsXml);
    }

    static buildActions() {
        return {
            ...super.buildActions('WORKFLOW_TEMPLATE'),
            // WORKFLOW ACTIONS
            VALIDATE_WORKFLOW: 'VALIDATE_WORKFLOW',
            ADD_WORKFLOW: 'ADD_WORKFLOW_TEMPLATE',

            SEND_PARAMETER_UPDATE: 'SEND_PARAMETER_UPDATE'
        };
    }

    static buildActionCreators(actions) {
        return {
            ...super.buildActionCreators(actions),
            // WORKFLOW ACTION CREATORS
            validateWorkflow: actionCreator(actions.VALIDATE_WORKFLOW, 'workflow'),
            addWorkflow: actionCreator(actions.ADD_WORKFLOW, 'workflow', 'updateExisting', 'createCopy'),

            sendParameterUpdate: actionCreator(actions.SEND_PARAMETER_UPDATE, 'parameter', 'options', 'callbacks')
        }
    }

    static buildComponentActionCreators() {
        const components = [
            {
                key: 'workflowTemplateTablet',
                type: 'Tablet',
                state: {}
            },
            {
                key: 'workflowTemplateForm',
                type: 'Form',
                state: {
                    workflowName: '',
                    operationsXml: '',
                    enabled: true,
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }
}

export class WorkflowTemplateApi extends SubsetDetailsApi {

    static location = '/library';
    static type = '/workflow';

    static post(workflow, libraryId, fromJobQueue = false, updateExisting = false, createCopy = false) {
        return axiosInstance.post(`/scheduler/library/${libraryId}/workflow?fromJobQueue=${fromJobQueue}&updateExisting=${updateExisting}&createCopy=${createCopy}`, workflow);
    }

    static* getAllowedMatterWorkflowSessionParameterValues(matterId, workflowId, parameterValues, queueState) {
        const url = `/scheduler/client/matter/${matterId}/allowedSessionParameterValues/workflow/${workflowId}?queueState=${!!queueState ? queueState : jobQueueStates.BACKLOG}`;

        return yield contextCall(axiosInstance, 'cancelWrapper', 'post', url, parameterValues);
    }

    static* getParameterUpdate(fromSchedule, update) {
        // const url = `/scheduler/jobs/parameterUpdate?fromSchedule=${!!fromSchedule}`;
        const url = `/scheduler/jobs/parameterUpdate`;
        return yield contextCall(axiosInstance, 'cancelWrapper', 'post', url, update);
    }

    static getTsvParameters(tsvText) {
        return axiosInstance.post('/scheduler/jobs/tsvParameters', tsvText);
    }

    static getRequiredSessionParameters(id) {
        return axiosInstance.get(`/scheduler/library/workflow/${id}/requiredSessionParameters`);
    }

    static getOperations(id) {
        return axiosInstance.get(`/scheduler/library/workflow/${id}/operations`);
    }

    static getDetailedOperations(id, {withFieldOverwrite=false}={}) {
        return axiosInstance.get(`/scheduler/library/workflow/${id}/detailedOperations?withFieldOverwrite=${!!withFieldOverwrite}`);
    }o

    static getDetailedOperationsFromWorkflowXml(operationsXml) {
        return axiosInstance.post(`/scheduler/library/workflow/workflowDetailedOperationsFromXml`, {operationsXml})
    }

    static getObjectsUsingWorkflow(id) {
        return axiosInstance.get(`/scheduler/library/workflow/${id}/dependants`);
    }

    static getWorkflowTemplateValidation(workflowXml) {
        return axiosInstance.post('/scheduler/jobs/requiredSessionParameters', {workflowXml});
    }

    static postParameters(id, parameters) {
        return axiosInstance.post(`/scheduler/library/workflow/${id}/requiredSessionParameters`, parameters);
    }

    static getDetails(locationId, includeDisabled, requiredParameterTypes, forbiddenParameterTypes) {
        let url = `/scheduler${this.location}/${locationId}${this.type}s?includeDisabled=${includeDisabled}`;
        if (requiredParameterTypes != null) {
            for (let i = 0; i < requiredParameterTypes.length; i++) {
                url += `&requiredParameterTypes=${requiredParameterTypes[i]}`;
            }
        }
        if (forbiddenParameterTypes != null) {
            for (let i = 0; i < forbiddenParameterTypes.length; i++) {
                url += `&forbiddenParameterTypes=${forbiddenParameterTypes[i]}`;
            }
        }
        return axiosInstance.get(url)
    }
}

export class WorkflowTemplateSaga extends SubsetDetailsSaga {

    static ModelType = WorkflowTemplateModel;
    static ModelApi = WorkflowTemplateApi;

    static activationComponent = 'LIBRARY_PAGE';
    static variableNames = {
        detailsMap: 'workflowTemplateDetailsMap',
        instanceId: 'workflowTemplateId',
        isFormActive: 'isWorkflowTemplateFormActive',
        updateDisplay: 'updateDisplay',
        updatePane: 'updateTablet'
    };

    static translations = {
        itemTitle: '$t(workflow:label.nameLast)',
        itemLower: '$t(workflow:label.nameLast_lower)'
    };

    static buildGlobalEffects() {
        return [
            this.takeLatestParameterUpdate(WorkflowTemplateModel.actions.SEND_PARAMETER_UPDATE, tryCatchWrapper, contextSaga(this, 'sendParameterUpdate'))
        ]
    }

    static buildActivationEffects(dispatch) {
        return [
            ...super.buildActivationEffects(dispatch),
            // ACTIVATION EFFECTS
            takeLeading(WorkflowTemplateModel.actions.VALIDATE_WORKFLOW, tryCatchWrapper, isDisabledWrapper, WorkflowTemplateModel.componentActionCreators.updateForm, contextSaga(this, 'validateWorkflow')),
            takeLatest(WorkflowTemplateModel.actions.ADD_WORKFLOW, tryCatchWrapper, isDisabledWrapper, WorkflowTemplateModel.componentActionCreators.updateForm, contextSaga(this, 'addWorkflow'))
        ]
    }

    static buildDeactivationEffects() {
        return [
            ...super.buildDeactivationEffects()
            // DEACTIVATION EFFECTS
        ]
    }

    static* getParametersAndOperations(args) {
        const {
            workflowTemplateId
        } = args;

        yield contextCall(WorkflowTemplateSaga, 'querySettings', {payload: {id: workflowTemplateId}});
        return yield select(state => ([state.templateOperationsMap.get(workflowTemplateId), deepCopy(state.parametersMap.get(workflowTemplateId))]));
    }

    static* loadTsvParameters(args) {
        const {
            tsvText,
            parameters
        } = args;

        const {data} = yield contextCall(WorkflowTemplateApi, 'getTsvParameters', tsvText);
        for (let i = 0; i < data.length; i++) {

            const {name, value} = data[i];
            const parameter = parameters.get(name);

            if (parameter != null) {
                parameters.set(name, parameter.duplicate({
                    valid: parameter.validate(value),
                    value
                }));
            }
        }
        return parameters;
    }

    static* resetParameters(args) {
        const {
            workflowTemplateId
        } = args;

        let parameters = yield select(state => deepCopy(state.parametersMap.get(workflowTemplateId)));
        // Always re-evaluate allowed parameters due to some parameters being script-based
        return yield call(tryCatchWrapper, contextSaga(WorkflowTemplateSaga, 'updateParametersAllowedValues'), {...args, parameters});
    }

    // While debounce is active, updates will be delayed and sent after timeout is finished
    // Each consecutive update will overwrite @args field
    static parameterUpdates = {};
    static debounceParameterUpdate(...args) {
        const {name} = args[0];

        // If debounce is active, set args to update once timeout finished
        if (this.parameterUpdates[name] != null) {
            this.parameterUpdates[name].args = args;
        } else {
            // If debounce not active, send update and start debounce timeout
            this.dispatch(WorkflowTemplateModel.actionCreators.sendParameterUpdate(...args));
            this.parameterUpdates[name] = {
                args: null,
                timeout: setTimeout(() => {
                    // If flag set, send update
                    if (this.parameterUpdates[name].args != null) {
                        this.dispatch(WorkflowTemplateModel.actionCreators.sendParameterUpdate(...this.parameterUpdates[name].args));
                    }
                    delete this.parameterUpdates[name];
                }, 500)
            }
        }
    }

    static takeLatestParameterUpdate(pattern, saga, ...args) {
        return fork(function* () {
            let payloadTasks = {};
            const intervalTimer = setInterval(() => {
                for (const key of getKeys(payloadTasks)) {
                    if (!payloadTasks[key].isRunning()) {
                        delete payloadTasks[key];
                    }
                }
            }, 60000);

            try {
                while (true) {
                    const action = yield take(pattern);
                    const {name} = action.payload.parameter;

                    let prevTask = payloadTasks[name];
                    if (prevTask != null) {
                        yield cancel(prevTask);
                        prevTask = null;
                    }

                    if (prevTask == null) {
                        payloadTasks[name] = yield fork(saga, ...args.concat(action));
                    }
                }
            } finally {
                clearInterval(intervalTimer);
            }
        });
    }

    static* sendParameterUpdate(action) {
        const {parameter: {name, value, displayable}, options, callbacks} = action.payload;
        if (!isNotEmptyNorFalsy(options.parameters)) {
            return;
        }

        const parameterValues = getValues(options.parameters);
        const parameter = options.parameters.get(name);
        const updatingRelativityServiceParameter = parameter.isRelativityServiceParameter();
        const updatingEccServiceParameter = parameter.isEccServiceParameter();

        const parameterOptions = {
            matterId: options.matterId,
            workflowTemplateId: options.workflowTemplateId,
            queueState: options.queueState,
            serviceIds: {},
            parameterValues: {}
        };

        for (const param of parameterValues) {
            parameterOptions.parameterValues[param.name] = param.value
            if (param.displayCondition.displayable) {
                if (param.isThirdPartyServiceParameter()) {
                    if (parameterOptions.serviceIds[param.parameterType] == null) {
                        parameterOptions.serviceIds[param.parameterType] = param.value;
                    }
                }
            }
        }

        // Skip parameterUpdate if parameter is not used in a displayCondition OR a serviceCondition (e.g. relativityCondition)
        // Always query if updating a service
        if (!updatingRelativityServiceParameter && !updatingEccServiceParameter) {
            const usedInCondition = parameterValues.some(param => param.displayCondition.parameter === name || param.relativityCondition.filterParameter === name);
            const isDependantParameter = parameterValues.some(param => param.isScriptedParameter() && Array.isArray(param.dependantParameters) && param.dependantParameters.includes(name));
            if (!usedInCondition && !isDependantParameter) {
                return;
            }
        }

        // Disable parameters affected by parameter being updated
        const disabledParams = new Set();
        const relativityServiceValueDefined = parameterOptions.serviceIds[ParameterModel.Type.RELATIVITY_SERVICE] || updatingRelativityServiceParameter;
        const eccServiceValueDefined = parameterOptions.serviceIds[ParameterModel.Type.ECC_SERVICE] || updatingEccServiceParameter;

        yield* callbacks.handleUpdate(prevParameters => {
            const parameters = new Map(prevParameters);
            for (const [key, param] of parameters) {
                let parameterAffected = false;

                if (relativityServiceValueDefined && param.isRelativityParameter) {
                    if (updatingRelativityServiceParameter || param.relativityCondition.filterParameter === name) {
                        parameterAffected = true;
                    }
                } else if (param.isScriptedParameter()) {
                    if (Array.isArray(param.dependantParameters) && param.dependantParameters.includes(name)) {
                        parameterAffected = true;
                    }
                } else if (eccServiceValueDefined && param.isEccParameter()) {
                    if (updatingEccServiceParameter) {
                        parameterAffected = true;
                    }
                }


                if (parameterAffected) {
                    parameters.set(key, param.duplicate({disabled: true}));
                    disabledParams.add(key);
                }
            }
            return parameters;
        });

        const parameterUpdates = [];
        // Nested query all parameterUpdates
        function* queryParameterUpdate({name, value}, parentDisplayable = true) {
            const args = {...parameterOptions, parameter: {name, value}};
            const res = yield contextCall(WorkflowTemplateApi, 'getParameterUpdate', parameterOptions.fromSchedule, args);

            for (const update of res.data) {
                // Only displayable if parent is displayable
                update.displayable &&= parentDisplayable;
                parameterUpdates.push(update);
            }
        }
        yield call(queryParameterUpdate, {name, value}, displayable);



        // Update allowedValues for parameters that are now displayable
        const parametersToUpdate = parameterUpdates
            .filter(update => update.displayable !== false).map(update => options.parameters.get(update.name));

        const parameterAllowedValuesOptions = yield this.getParameterAllowedValuesOptions({
            ...options,
            parameters: parametersToUpdate
        });

        const errors = [];
        yield* callbacks.handleUpdate(prevParameters => {
            const parameters = new Map(prevParameters);

            for (const update of parameterUpdates) {
                const param = parameters.get(update.name);

                if (param != null) {
                    let updatedParameter = param.duplicate();
                    if (update.displayable != null) {
                        updatedParameter.displayCondition.displayable = update.displayable;
                    }
                    if (updatedParameter.displayCondition.displayable !== false) {
                        try {
                            updatedParameter = this.updateParameterAllowedValues(updatedParameter, update.allowedValues, parameterAllowedValuesOptions)
                        } catch (error) {
                            errors.push(error);
                        }
                    }

                    // If parameter is protected, attempt to get value from HTML
                    let value;
                    if (updatedParameter.isPasswordParameter()) {
                        const htmlInputField = document.getElementById(updatedParameter.id);
                        if (htmlInputField != null) {
                            value = htmlInputField.value;
                        }
                    }
                    updatedParameter.valid = updatedParameter.validate(value);
                    parameters.set(update.name, updatedParameter);
                }
            }

            for (const key of disabledParams) {
                parameters.set(key, parameters.get(key).duplicate({disabled: false}));
            }

            return parameters;
        });

        if (errors.length > 0) {
            if (typeof callbacks.handleError === 'function') {
                yield* callbacks.handleError(errors[0]);
            }
        }
    }

    static* updateParametersAllowedValues(args, handleError) {
        const {
            clientId,
            matterId,
            workflowTemplateId,
            parameters,
            scheduleTriggerType,
            queueState
        } = args;

        const parameterValues = getValues(parameters);
        const parameterAllowedValuesOptions = yield this.getParameterAllowedValuesOptions({
            clientId,
            matterId,
            parameters: parameterValues,
            scheduleTriggerType,
            queryDetails: true
        });

        const parameterUpdates = new Map(parameters);
        const parameterValueMap = {};
        parameterValues.map(param => {
            parameterValueMap[param.name] = param.value
        });

        // Populate dynamic allowedValues for all parameters with /parameterUpdate query
        const {data} = yield contextCall(WorkflowTemplateApi, 'getAllowedMatterWorkflowSessionParameterValues', matterId, workflowTemplateId, parameterValueMap, queueState);
        const errors = [];

        for (const parameterName of getKeys(parameterUpdates)) {
            let updatedParameter = parameterUpdates.get(parameterName).duplicate();

            if (updatedParameter.isServerFileParameter() || updatedParameter.isServerFolderParameter()) {
                if (updatedParameter.value || updatedParameter.dataRepositoryId) {
                    const dataRepository = parameterAllowedValuesOptions.dataRepositoryDetailsMap.get(updatedParameter.dataRepositoryId);
                    let clearValue = dataRepository == null;

                    if (!clearValue && updatedParameter.value) {
                        const pathSplit = updatedParameter.value.split(pathSplitterRegex);
                        clearValue = dataRepository.path.split(pathSplitterRegex).some((path, index) => path !== pathSplit[index]);
                    }
                    if (clearValue) {
                        updatedParameter.value = '';
                        updatedParameter.dataRepositoryId = null;
                    }
                }
            }

            if (data[parameterName] !== undefined) {
                try {
                    updatedParameter = this.updateParameterAllowedValues(updatedParameter, data[parameterName], parameterAllowedValuesOptions)
                } catch (error) {
                    errors.push(error);
                }
            }

            updatedParameter.valid = updatedParameter.validate();
            parameterUpdates.set(parameterName, updatedParameter);
        }

        if (errors.length > 0) {
            if (typeof handleError === 'function') {
                yield* handleError(errors[0]);
            }
        }

        return parameterUpdates;
    }

    static* getParameterAllowedValuesOptions(args) {
        const {
            clientId,
            matterId,
            parameters,
            scheduleTriggerType,
            queryDetails
        } = args;

        const hasParameterType = {};
        for (const param of parameters) {
            if (param.isFileParameter()) {
                hasParameterType.file = true;
            } else if (param.isDatasetParameter()) {
                hasParameterType.dataset = true;
            } else if (param.isServerFolderParameter() || param.isServerFileParameter() || param.isAzureStorageAccountParameter()) {
                hasParameterType.dataRepository = true;
            } else if (param.isThirdPartyServiceParameter()) {
                hasParameterType.thirdPartyService = true;
                hasParameterType.userService = true;
            }
        }

        const isEventTrigger = {
            dataset: scheduleTriggerType === scheduleTriggerTypeKeys.ON_DATASET_EVENT,
            legalHold: scheduleTriggerType === scheduleTriggerTypeKeys.ON_LEGAL_HOLD_EVENT
        };

        const queryEffects = [];
        // query for dataRepositories
        if (hasParameterType.dataRepository) {
            if (queryDetails) {
                queryEffects.push(contextCall(DataRepositorySaga, 'queryDetails'));
            }
        }

        // query for matter datasets
        if (!isEventTrigger.dataset && hasParameterType.dataset) {
            if (matterId != null && queryDetails) {
                queryEffects.push(contextCall(MatterSaga, 'querySettings', {payload: {id: matterId}}));
            }
        }
        // query for thirdPartyServices
        if (hasParameterType.thirdPartyService) {
            if (queryDetails) {
                queryEffects.push(contextCall(ThirdPartyServiceSaga, 'queryDetails'));
            }
        }
        if (hasParameterType.userService) {
            if (queryDetails) {
                queryEffects.push(contextCall(UserServiceSaga, 'queryDetails'));
            }
        }

        // query for file library parameters
        if (hasParameterType.file) {
            if (queryDetails) {
                yield contextCall(FileLibrarySaga, 'queryDetails');

                const fileLibraryDetailsMap = yield select(state => state.fileLibraryDetailsMap);
                const fileLibraryIds = getValues(fileLibraryDetailsMap).map(library => library.id);
                for (let i = 0; i < fileLibraryIds.length; i++) {
                    queryEffects.push(contextCall(FileLibrarySaga, 'querySettings', {payload: {id: fileLibraryIds[i]}}));
                }
            }
        }

        yield all(queryEffects);

        const [userFeatures, libraryFileDetailsMap, dataRepositoryDetailsMap, thirdPartyServiceDetailsMap] = yield select(state => [
            state.currentUser.features, state.libraryFileDetailsMap, state.dataRepositoryDetailsMap, state.thirdPartyServiceDetailsMap
        ]);
        return {
            clientId,
            matterId,
            scheduleTriggerType,
            libraryFileDetailsMap,
            dataRepositoryDetailsMap,
            thirdPartyServiceDetailsMap,
            userFeatures,
            isEventTrigger
        };
    }

    static updateParameterAllowedValues(param, allowedValues, options) {
        const {
            clientId,
            matterId,
            libraryFileDetailsMap,
            dataRepositoryDetailsMap,
            thirdPartyServiceDetailsMap,
            userFeatures,
            isEventTrigger
        } = options;

        // Filter allowedValues against regex (validate)
        const parameter = param.duplicate({parameterAllowedValues: allowedValues || param.parameterAllowedValues || []});
        parameter.parameterAllowedValues = parameter.parameterAllowedValues.filter(({value}) => parameter.validate(value, {ignoreAllowedValues: true}));

        if (param.isDatasetParameter()) {
            // If Dataset Event, only allow setting Same as Triggering Dataset
            if (isEventTrigger.dataset) {
                parameter.parameterAllowedValues = [{name: i18next.t(`jobSchedule:option.${SAME_AS_TRIGGERING_DATASET}`), value: SAME_AS_TRIGGERING_DATASET}];
            } else {
                if (matterId == null) {
                    throw {cause: 'undefinedMatterHasNoDatasets'};
                }
                if (parameter.parameterAllowedValues.length === 0) {
                    throw {cause: 'noFinalizedOrAllowedDatasets', data: {clientId, matterId}};
                }
            }
        } else if (param.isServerFolderParameter() || param.isServerFileParameter()) {
            const inPlaceDataRepos = getValues(dataRepositoryDetailsMap)
                .filter(dataRepo => dataRepo.type === datasetType.IN_PLACE
                    && includesSome(dataRepo.userPermissions, [permissionKeys.SUBMIT_JOB, permissionKeys.STAGE_JOB]));

            if (inPlaceDataRepos.length === 0) {
                throw {cause: 'noInPlaceDataRepositories'};
            }

        } else if (param.isAzureStorageAccountParameter()) {
            const azureStorageAccountDataRepos = getValues(dataRepositoryDetailsMap)
                .filter(dataRepo => dataRepo.type === datasetType.AZURE_STORE_ACCOUNT
                    && includesSome(dataRepo.userPermissions, [permissionKeys.SUBMIT_JOB, permissionKeys.STAGE_JOB]));

            if (azureStorageAccountDataRepos.length === 0) {
                throw {cause: 'noAzureStorageAccountDataRepositories'};
            }

        } else if (param.isThirdPartyServiceParameter()) {
            let thirdPartyType;
            if (param.isPurviewServiceParameter()) {
                thirdPartyType = ThirdPartyServiceModel.Type.PURVIEW;
            } else if (param.isVaultServiceParameter()) {
                thirdPartyType = ThirdPartyServiceModel.Type.VAULT;
            } else if (param.isRelativityServiceParameter()) {
                thirdPartyType = ThirdPartyServiceModel.Type.RELATIVITY;
            } else if (param.isDiscoverServiceParameter()) {
                thirdPartyType = ThirdPartyServiceModel.Type.DISCOVER;
            } else if (param.isElasticsearchServiceParameter()) {
                thirdPartyType = ThirdPartyServiceModel.Type.ELASTICSEARCH;
            } else if (param.isGraphServiceParameter()) {
                thirdPartyType = ThirdPartyServiceModel.Type.GRAPH;
            } else if (param.isGenAiServiceParameter()) {
                thirdPartyType = ThirdPartyServiceModel.Type.GEN_AI;
            } else if (param.isSemanticServiceParameter()) {
                thirdPartyType = ThirdPartyServiceModel.Type.SEMANTIC;
            } else if (param.isNlpServiceParameter()) {
                thirdPartyType = ThirdPartyServiceModel.Type.NLP;
            } else if (param.isEccServiceParameter()) {
                thirdPartyType = ThirdPartyServiceModel.Type.ECC;
            } else if (param.isSmtpServiceParameter()) {
                thirdPartyType = ThirdPartyServiceModel.Type.SMTP;
            }
            const thirdPartyServices = getValues(thirdPartyServiceDetailsMap)
                .filter(service => service.type === thirdPartyType
                    && includesSome(service.userPermissions, [permissionKeys.SUBMIT_JOB, permissionKeys.STAGE_JOB]));

            if (thirdPartyServices.length === 0) {
                throw {cause: `${lowerCaseFirstLetter(thirdPartyType)}NoneAvailable`};
            }
        } else if (param.isLegalHoldParameter()) {
            // If LegalHold Event, only allow setting Same as Triggering LegalHold
            if (isEventTrigger.legalHold) {
                parameter.parameterAllowedValues = [{name: i18next.t(`jobSchedule:option.${SAME_AS_TRIGGERING_LEGAL_HOLD}`), value: SAME_AS_TRIGGERING_LEGAL_HOLD}];
            } else {
                throw {cause: 'legalHoldParameterNotSupported'};
            }
        } else if (param.isBooleanParameter()) {
            parameter.parameterAllowedValues = parameter.parameterAllowedValues.filter(val => ({'True': true, 'False': true}[capitalize(val.value)]));
            if (parameter.parameterAllowedValues.length === 0) {
                parameter.parameterAllowedValues = [{value: 'True'}, {value: 'False'}];
            }
        } else if (param.isFileParameter()) {
            if (!userFeatures.includes(applicationFeatures.VIEW_FILE_LIBRARIES)) {
                throw {cause: 'noLibraryFileUserPermissions'}
            }
        }
        if (!param.isDropdownParameter() && !Array.isArray(allowedValues)) {
            parameter.parameterAllowedValues = null;
        }

        if (param.isFileParameter()) {
            const libraryFile = libraryFileDetailsMap.get(parameter.value);
            if (libraryFile != null) {
                parameter.valueName = libraryFile.name;
            }
        }
        parameter.valid = parameter.validate();
        return parameter;
    }

    static* handleNoDataSetsError(error, {hideFormActionCreator}) {
        const {clientId, matterId} = error.data;
        // Navigate to client, select matter, and set dataset formActive
        const waitForNavigateThenSetState = function* () {
            yield all([
                put(ClientModel.componentActionCreators.updatePage({clientId})),
                put(ClientModel.componentActionCreators.updateDisplay({matterId})),
                put(DatasetModel.componentActionCreators.updateView({isDatasetFormActive: true}))
            ]);
        }

        const navigateToMatterDatasetDisplay = function (dispatch) {
            // Close queueJob
            dispatch(hideFormActionCreator());
            window.location.href = '#/clients';
            dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(call(waitForNavigateThenSetState)));
        }.bind(this);

        yield put(PopupModel.actionCreators.showWarning({
            id: error.cause,
            info: {
                key: 'addDataSetToMatter'
            },
            buttons: [{
                titleKey: 'common:option.yes',
                onClick: navigateToMatterDatasetDisplay
            }],
            cancelButton: {
                titleKey: 'common:option.no'
            }
        }));
    }

    static* handleAllowedParameterValuesError(error) {
        // Custom error callback to attach a callback to error popup
        function* handleResponseError(error) {
            const {response} = error;

            if (response.status === 401) {
                yield put(CurrentUserModel.actionCreators.logoutUser());
            } else {
                yield put(PopupModel.actionCreators.showError({
                    info: {
                        ...(serverErrorResponseValues(response) || response.data)
                    }
                }));
            }
        }
        // Call genericErrorHandler with custom response error callback
        yield call(genericErrorHandler, error, {responseErrorCb: handleResponseError});
    }

    static* validateWorkflow(action) {
        const {workflow} = action.payload;
        yield contextCall(WorkflowTemplateApi, 'getWorkflowTemplateValidation', workflow.operationsXml);
        yield put(WorkflowTemplateModel.componentActionCreators.updateForm({workflowName: workflow.workflowName, ...workflow}));
    }

    static* addWorkflow(action) {
        const {workflow, updateExisting, createCopy} = action.payload;

        try {
            const workflowSubmission = {name: workflow.workflowName, operationsXml: workflow.operationsXml, enabled: workflow.enabled}
            const {data} = yield contextCall(WorkflowTemplateApi, 'post', workflowSubmission, workflow.libraryId, false, updateExisting, createCopy);

            workflowIconModel.clearIcon(data.id);
            yield all([
                put(WorkflowTemplateModel.actionCreators.addDetails(data)),
                put(WorkflowTemplateModel.componentActionCreators.updateDisplay({
                    workflowTemplateId: data.id,
                    isWorkflowWizardActive: false
                }))
            ]);
        } catch (error) {
            // Custom error callback to attach a callback to error popup
            const handleResponseError = function* (error) {
                const {key} = error.response.data;
                switch (key) {
                    case 'workflowXmlExistsOfferOverwriteOrCopy':
                    case 'workflowExistsOfferOverwriteOrCopy':
                        yield put(PopupModel.actionCreators.showWarning({
                            info: error.response.data,
                            buttons: [{
                                titleKey: 'common:option.update',
                                onClick: dispatch => dispatch(WorkflowTemplateModel.actionCreators.addWorkflow(workflow, true, false))
                            }, {
                                titleKey: 'common:option.copy',
                                onClick: dispatch => dispatch(WorkflowTemplateModel.actionCreators.addWorkflow(workflow, false, true))
                            }]
                        }));
                        break;

                    case 'workflowExistsInJobOfferCopy':
                    case 'workflowExistsInScheduleOfferCopy':
                    case 'workflowExistsInPurviewOfferCopy':
                        yield put(PopupModel.actionCreators.showWarning({
                            info: error.response.data,
                            buttons: [{
                                titleKey: 'common:option.copy',
                                onClick: dispatch => dispatch(WorkflowTemplateModel.actionCreators.addWorkflow(workflow, false, true))
                            }]
                        }));
                        break;

                    default:
                        yield call(genericErrorHandler, error);
                }
            }.bind(this);

            // Call genericErrorHandler with custom response error callback
            yield call(genericErrorHandler, error, {responseErrorCb: handleResponseError});
        }
    }

    static* submitForm(action) {
        const {formData: {libraryId}} = action.payload;
        const formData = yield select(state => state.componentStates.workflowTemplateForm);
        const workflow = {...formData, libraryId};
        yield WorkflowTemplateSaga.addWorkflow({payload: {workflow}});
    }

    static* queryDetailsSubset(action) {
        const {parentId} = action.payload;
        if (parentId == null || parentId === SAME_AS_TRIGGERING_JOB)
            return;

        const showDisabled = yield select(state => state.userSettingsMap.get(userSettings.SHOW_DISABLED_ITEMS));
        const response = yield contextCall(WorkflowTemplateApi, 'getDetails', parentId, showDisabled.workflows);

        const key = parentId;
        if (AxiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(WorkflowTemplateModel.actionCreators.setSubsetDetailsMap(parentId, response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);

            workflowIconModel.clearIcons(response.data);
        }
    }

    static* querySettings(action) {
        const {id} = action.payload;
        if (id == null)
            return;

        // Put in try-finally because even if fails, should still be marked as loaded
        try {
            const responses = yield all([
                contextCall(WorkflowTemplateApi, 'getRequiredSessionParameters', id),
                contextCall(WorkflowTemplateApi, 'getOperations', id),
                contextCall(WorkflowTemplateApi, 'getAuditLog', id)
            ]);

            if (AxiosProxy.shouldUpdate(id, responses)) {
                const [parametersRes, operationsRes, auditLogRes] = responses;
                yield all([
                    put(ParameterModel.actionCreators.setParameters(id, parametersRes.data)),
                    put(TemplateOperationModel.actionCreators.setOperations(id, operationsRes.data)),
                    put(AuditLogModel.actionCreators.setSetting(id, auditLogRes.data))
                ]);
            }
        } finally {
            yield put(ReduxStateModel.actionCreators.setHasLoaded(id));
        }
    }
}

export default WorkflowTemplateModel;