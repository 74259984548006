import React, {useEffect} from "react";
import HTMLTextInput from "../../../components/common/HTMLTextInput/HTMLTextInput";
import {createNestedUpdateHandler} from "../Question/QuestionCheckBox/helpers";
import {ListDropdown} from "../../../components/common/Dropdown/Dropdown";
import {useTranslation} from "react-i18next";
import {getDataNameFromParentNode} from "../../../utilities/helperFunctions";
import RadioButton from "../../../components/common/RadioButton/RadioButton";


export function NumberingOptions(props) {
    const {exportNumberingType, onChange, numberingProps, updateState, nested} = props;

    const updateHandler = createNestedUpdateHandler({
        handler: updateState
    });

    const componentProps = {
        values: numberingProps,
        onChange: updateHandler,
        nested: nested
    }

    function generateNumberingOptions() {
        switch (exportNumberingType) {
            case 1:
                return (<DocumentIdNumbering {...componentProps}/>)
            case 2:
                return (<FamilyDocumentIdNumbering {...componentProps}/>)
            case 3:
                return (<FilenameDocumentIdNumbering {...componentProps}/>)
            case 4:
                return (<PrefixPageNumbering {...componentProps}/>)
            case 5:
                return (<PrefixFolderPageNumbering {...componentProps}/>)
            case 6:
                return (<PrefixBoxFolderPageNumbering {...componentProps}/>)
        }
    }

    function pad(num, size) {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
    }

    function generateSample() {
        const values = numberingProps;
        let startingDocId = values.startNumberingAt;
        let prefix = values.prefix.replace("{case_prefix}", "SAMPL");

        if (values.numberingType === 1) {
            startingDocId = 500;
            if (exportNumberingType === 1) {
                let s1 = prefix + pad(startingDocId, values.numberingDigits);
                let s2 = prefix + pad(startingDocId + 1, values.numberingDigits) + ", " +
                    prefix + pad(startingDocId + 2, values.numberingDigits) + ", " + prefix + pad(startingDocId + 3, values.numberingDigits) + ", ...";

                return (<VisualNumberingExample topLevel={s1} descendants={s2}/>)
            }
        } else {
            if (exportNumberingType === 1 || exportNumberingType === 4) {
                let s1 = prefix + pad(startingDocId, values.numberingDigits);
                let s2 = prefix + pad(parseInt(startingDocId) + 1, values.numberingDigits) + ", " +
                    prefix + pad(parseInt(startingDocId) + 2, values.numberingDigits) + ", " + prefix + pad(parseInt(startingDocId) + 3, values.numberingDigits) + ", ...";

                return (<VisualNumberingExample topLevel={s1} descendants={s2}/>)
            }

            if (exportNumberingType === 2) {
                let s1 = prefix + pad(startingDocId, values.numberingDigits);
                let s2 = prefix + pad(startingDocId, values.numberingDigits) + values.separator + pad(1, values.familyDigits) + ", " +
                    prefix + pad(startingDocId, values.numberingDigits) + values.separator + pad(2, values.familyDigits) + ", " +
                    prefix + pad(startingDocId, values.numberingDigits) + values.separator + pad(3, values.familyDigits) + ", ...";

                return (<VisualNumberingExample topLevel={s1} descendants={s2}/>);
            }

            if (exportNumberingType === 5 || exportNumberingType === 6) {
                const boxDigits = values.boxCycleTo.toString().length;
                const folderDigits = values.folderCycleTo.toString().length;
                const pageDigits = values.pageCycleTo.toString().length;

                let box = exportNumberingType === 6 ? pad(values.boxStartAt, boxDigits) + values.delimiter : '';
                let s1 = prefix + values.delimiter + box + pad(values.folderStartAt, folderDigits) + values.delimiter + pad(values.pageStartAt, pageDigits);

                return (<VisualNumberingExample single={s1}/>);
            }
        }

    }

    return (
        <div className={'settings-table no-border tight-padding'} style={{border: '1px solid transparent'}}>
            <div className="table-row-group">
                <div key={"numbering_input"} className="table-row no-hover align-middle">
                    <div key={"numberingSettings"} className="table-cell no-stretch">
                        {generateNumberingOptions()}
                    </div>
                    <div key={"numberingVisualSettings"} className="table-cell" style={{paddingLeft: '5rem'}}>
                        {generateSample()}
                    </div>
                </div>
            </div>
        </div>
    )
}

function DocumentIdNumbering(props) {
    const {values, onChange, nested} = props;
    const isFamily = false;
    const continuedDisabled = false;

    return (
        <DocumentIdTemplate
            values={values}
            onChange={onChange}
            nested={nested}
            isFamily={isFamily}
            continuedDisabled={continuedDisabled}/>
    )
}

function FamilyDocumentIdNumbering(props) {
    const {values, onChange, nested} = props;
    const isFamily = true;
    const continuedDisabled = true;

    return (
        <DocumentIdTemplate
            values={values}
            onChange={onChange}
            nested={nested}
            isFamily={isFamily}
            continuedDisabled={continuedDisabled}/>
    )
}

function FilenameDocumentIdNumbering(props) {
    return (
        <h2></h2>
    )
}

function PrefixPageNumbering(props) {
    const {values, onChange, nested} = props;
    const isFamily = false;
    const continuedDisabled = true;

    return (
        <DocumentIdTemplate
            values={values}
            onChange={onChange}
            nested={nested}
            isFamily={isFamily}
            continuedDisabled={continuedDisabled}/>
    )
}

function PrefixFolderPageNumbering(props) {
    const {values, onChange, nested} = props;
    const isBoxDisabled = true;

    return (
        <PrefixPageTemplate
            isBoxDisabled={isBoxDisabled}
            {...props}/>
    )
}

function PrefixBoxFolderPageNumbering(props) {
    const {values, onChange, nested} = props;
    const isBoxDisabled = false;

    return (
        <PrefixPageTemplate
            isBoxDisabled={isBoxDisabled}
            {...props}/>
    )
}

function DocumentIdTemplate(props) {
    const {values, onChange, nested, isFamily, continuedDisabled} = props;
    const {t} = useTranslation(['wizard']);

    const updateInput = (event) => {
        const {name, value} = event.target;
        return onChange({update: {name: name, value: value, nested: nested}})
    }

    const prefixInputProps = {
        id: "prefix",
        name: "prefix",
        value: values.prefix,
        onChange: updateInput,
        isRequired: true,
        isDisabled: false
    }

    const numberingDigitsProps = {
        id: "numberingDigits",
        name: "numberingDigits",
        value: values.numberingDigits,
        type: "number",
        min: 1,
        onChange: updateInput,
        isRequired: true,
        isDisabled: false
    }

    const familyDigitProps = {
        id: "familyDigits",
        name: "familyDigits",
        value: values.familyDigits,
        type: "number",
        min: 1,
        onChange: updateInput,
        isRequired: isFamily,
        isDisabled: !isFamily
    }

    const disabled = isFamily ? '' : 'is-disabled';

    return (
        <div className={'settings-table no-border'} style={{border: '1px solid transparent'}}>
            <div className="table-row-group">
                <div key={"numbering_options_row"} className="table-row no-hover align-middle">
                    <div key={"prefix_cell"} className="table-cell no-stretch" style={{padding: '0.125rem 0.25rem'}}>
                        <label className="label is-small">{t("wizard:questions.customNumbering.documentTemplate.prefix")}</label>
                    </div>
                    <div key={"numbering_digits_cell"} className="table-cell no-stretch"
                         style={{padding: '0.125rem 0.25rem'}}>
                        <label className="label is-small">{t("wizard:questions.customNumbering.documentTemplate.numberingDigits")}</label>
                    </div>
                    <div key={"family_separator"} className="table-cell no-stretch"
                         style={{padding: '0.125rem 0.25rem'}}>
                        <label className={"label is-small " + disabled}>{t("wizard:questions.customNumbering.documentTemplate.separator")}</label>
                    </div>
                    <div key={"family_digits"} className="table-cell no-stretch" style={{padding: '0.125rem 0.25rem'}}>
                        <label className={"label is-small " + disabled}>{t("wizard:questions.customNumbering.documentTemplate.familyDigits")}</label>
                    </div>
                </div>
                <div key={"numbering_options_row_2"} className="table-row no-hover align-top">
                    <div key={"prefix_cell_inp"} className="table-cell no-stretch"
                         style={{padding: '0.125rem 0.25rem'}}>
                        <HTMLTextInput {...prefixInputProps}/>
                    </div>
                    <div key={"numbering_digits_cell_inp"} className="table-cell no-stretch"
                         style={{padding: '0.125rem 0.25rem'}}>
                        <HTMLTextInput {...numberingDigitsProps}/>
                    </div>
                    <div key={"family_separator_inp"} className="table-cell no-stretch"
                         style={{padding: '0.125rem 0.25rem'}}>
                        <SeparatorDropdown
                            selectedValue={values.separator}
                            onChange={onChange}
                            isRequired={isFamily}
                            isDisabled={!isFamily}
                            nested={nested}/>
                    </div>
                    <div key={"family_digits_inp"} className="table-cell no-stretch"
                         style={{padding: '0.125rem 0.25rem'}}>
                        <HTMLTextInput {...familyDigitProps}/>
                    </div>
                </div>
                <NumberingType
                    selectedValue={values.numberingType}
                    numberingValue={values.startNumberingAt}
                    onChange={onChange}
                    continuedDisabled={continuedDisabled}
                    nested={nested}/>
            </div>

        </div>
    )
}

function PrefixPageTemplate(props) {
    const {values, onChange, nested, isBoxDisabled} = props;
    const {t} = useTranslation(['wizard']);

    const updateInput = (event) => {
        const {name, value} = event.target;
        return onChange({update: {name: name, value: value, nested: nested}})
    }

    const inputStyle = {
        paddingRight: '0.5rem',
        paddingLeft: '0.5rem'
    }

    const prefixInputProps = {
        name: "prefix",
        value: values.prefix,
        onChange: updateInput,
        isRequired: true,
        isDisabled: false
    }

    return (
        <div className='settings-table no-border' style={{border: '1px solid transparent', width: '32rem'}}>
            <div className="table-row-group">
                <div key={"prefix_row1"} className="table-row no-hover align-middle">
                    <div key={`prefix_label_cell`} className="table-cell no-stretch" style={{padding: '0.125rem 0.125rem'}}>
                        <label className="label is-small">{t("wizard:questions.customNumbering.prefixPageTemplate.prefix")}</label>
                    </div>
                    <div key={"prefix_cell"} className="table-cell " style={{padding: '0.125rem 0.125rem'}}>
                        <HTMLTextInput {...prefixInputProps}/>
                    </div>
                </div>

                <div key={"prefix_row2"} className="table-row no-hover align-middle">
                    <div key={`prefix_label_cell`} className="table-cell no-stretch" style={{padding: '0.125rem 0.125rem'}}>
                        <label className="label is-small">{t("wizard:questions.customNumbering.prefixPageTemplate.box")}</label>
                    </div>
                    <PageRowTemplate statePrefix={"box"} isDisabled={isBoxDisabled} inputStyle={inputStyle} {...props}/>
                </div>
                <div key={"prefix_row3"} className="table-row no-hover align-middle">
                    <div key={`prefix_label_cell`} className="table-cell no-stretch" style={{padding: '0.125rem 0.125rem'}}>
                        <label className="label is-small">{t("wizard:questions.customNumbering.prefixPageTemplate.folder")}</label>
                    </div>
                    <PageRowTemplate statePrefix={"folder"} isDisabled={false} inputStyle={inputStyle} {...props}/>
                </div>
                <div key={"prefix_row4"} className="table-row no-hover align-middle">
                    <div key={`prefix_label_cell`} className="table-cell no-stretch" style={{padding: '0.125rem 0.125rem'}}>
                        <label className="label is-small">{t("wizard:questions.customNumbering.prefixPageTemplate.page")}</label>
                    </div>
                    <PageRowTemplate statePrefix={"page"} isDisabled={false} inputStyle={inputStyle} {...props}/>
                </div>

                <div key={"delimiter_row"} className="table-row no-hover align-middle">
                    <div key={`delimiter_label_cell`} className="table-cell no-stretch"
                         style={{padding: '0.125rem 0.125rem'}}>
                        <label className="label is-small">{t("wizard:questions.customNumbering.prefixPageTemplate.delimiter")}</label>
                    </div>
                    <div key={"delimiter_cell"} className="table-cell "
                         style={{padding: '0.125rem 0.125rem'}}>
                        <DelimiterDropdown
                            selectedValue={values.delimiter}
                            onChange={onChange}
                            isRequired={false}
                            isDisabled={false}
                            nested={nested}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

function PageRowTemplate(props) {
    const {values, onChange, nested, statePrefix, isDisabled, inputStyle} = props;
    const {t} = useTranslation(['wizard']);

    const updateInput = (event) => {
        const {name, value} = event.target;
        return onChange({update: {name: name, value: value, nested: nested}})
    }

    const isRequired = !isDisabled;
    const disabled = isDisabled ? 'is-disabled' : '';

    const startInputProps = {
        id: `${statePrefix}StartAt`,
        name: `${statePrefix}StartAt`,
        value: values[`${statePrefix}StartAt`],
        type: "number",
        min: 1,
        onChange: updateInput,
        isRequired: isRequired,
        isDisabled: isDisabled,
    }

    const cycleFromInputProps = {
        name: `${statePrefix}CycleFrom`,
        value: values[`${statePrefix}CycleFrom`],
        type: "number",
        min: 1,
        onChange: updateInput,
        isRequired: isRequired,
        isDisabled: isDisabled,
    }

    const cycleToInputProps = {
        name: `${statePrefix}CycleTo`,
        value: values[`${statePrefix}CycleTo`],
        type: "number",
        min: 1,
        onChange: updateInput,
        isRequired: isRequired,
        isDisabled: isDisabled,
    }

    return (
        <>
            <div className="table-cell " style={{padding: '0.125rem'}}>
                <div key={`${statePrefix}Values_row`} style={{display: 'flex', alignItems: 'center', flexFlow: 'row nowrap'}}>
                    <label className={"label is-small " + disabled} style={{marginRight: '0.5rem'}}>{t("wizard:questions.customNumbering.prefixPageTemplate.startAt")}</label>
                    <HTMLTextInput {...startInputProps}/>
                </div>
            </div>
            <div className="table-cell " style={{padding: '0.125rem'}}>
                <div key={`${statePrefix}Values_row`} style={{display: 'flex', alignItems: 'center', flexFlow: 'row nowrap', padding: '0.125rem'}}>
                    <label className={"label is-small " + disabled} style={{marginRight: '0.5rem'}}>{t("wizard:questions.customNumbering.prefixPageTemplate.cycleFrom")}</label>
                    <HTMLTextInput {...cycleFromInputProps}/>
                </div>
            </div>
            <div className="table-cell " style={{padding: '0.125rem'}}>
                <div key={`${statePrefix}Values_row`} style={{display: 'flex', alignItems: 'center', flexFlow: 'row nowrap', padding: '0.125rem'}}>
                    <label className={"label is-small " + disabled} style={{marginRight: '0.5rem'}}>{t("wizard:questions.customNumbering.prefixPageTemplate.cycleTo")}</label>
                    <HTMLTextInput {...cycleToInputProps}/>
                </div>
            </div>
        </>
    )

}

function NumberingType(props) {
    const {selectedValue, numberingValue, onChange, continuedDisabled, nested} = props;
    const {t} = useTranslation(['wizard']);

    const updateRadio = (event) => {
        const {name, value} = event.target;
        return onChange({update: {name: name, value: parseInt(value), nested: nested}});
    }

    const updateInput = (event) => {
        const {name, value} = event.target;
        return onChange({update: {name: name, value: value, nested: nested}})
    }

    const options = [
        {id: 1, value: t("wizard:questions.customNumbering.documentTemplate.continuedNumbering"), disabled: continuedDisabled},
        {id: 2, value: t("wizard:questions.customNumbering.documentTemplate.startNumberingAt")}
    ]

    const startNumberingAtProps = {
        name: "startNumberingAt",
        value: numberingValue,
        type: "number",
        onChange: updateInput,
        isRequired: true,
        isDisabled: (selectedValue !== 2)
    }

    useEffect(() => {
        if (continuedDisabled) {
            updateRadio({target: {name: "numberingType", value: 2}})
        }
    }, [continuedDisabled])

    return (
        <>
            <div className="settings-row-separator"/>
            <div key={options[0].id} className="table-row no-hover">
                <div key={options[0].id} className="table-cell no-stretch">
                    <RadioButton name={"numberingType"} label={options[0].value} selected={selectedValue}
                                 value={options[0].id} onClick={updateRadio} isWordWrap={false}
                                 isDisabled={options[0].disabled}/>
                </div>
                <div key={"placeholder"} className="table-cell no-stretch"/>
            </div>
            <div key={options[1].id} className="table-row no-hover">
                <div key={options[1].id} className="table-cell no-stretch">
                    <RadioButton name={"numberingType"} label={options[1].value} selected={selectedValue}
                                 value={options[1].id} onClick={updateRadio} isWordWrap={false}/>
                </div>
                <div key={"start_numbering"} className="settings-value-cell no-stretch"
                     style={{paddingRight: '0.25rem'}}>
                    <HTMLTextInput {...startNumberingAtProps}/>
                </div>
            </div>
        </>

    )
}

function VisualNumberingExample(props) {
    const {single, topLevel, descendants} = props;
    const {t} = useTranslation(['wizard']);

    return (
        <>
            {single &&
            <>
                <div key={"multi_sample_label"} className="table-row no-hover">
                    <div key={"top_level_label"} className="table-cell">
                        <label className="label is-small">{t("wizard:questions.customNumbering.sample.single")}</label>
                        <label className="label is-small is-bold">{single}</label>
                    </div>
                </div>
            </>
            }

            {!single &&
            <>
                <div key={"multi_sample_label"} className="table-row no-hover">
                    <div key={"top_level_label"} className="table-cell">
                        <label className="label is-small">{t("wizard:questions.customNumbering.sample.topLevel")}</label>
                        <label className="label is-small is-bold">{topLevel}</label>
                    </div>
                </div>
                <div className="settings-row-separator"/>
                <div key={"multi_sample_label2"} className="table-row no-hover">
                    <div key={"descendants_label"} className="table-cell">
                        <label className="label is-small">{t("wizard:questions.customNumbering.sample.descendants")}</label>
                        <label className="label is-small is-bold">{descendants}</label>
                    </div>
                </div>
            </>
            }
        </>
    )
}

function SeparatorDropdown(props) {
    const {selectedValue, onChange, nested, ...rest} = props;
    const {t} = useTranslation(['aria']);

    const separatorTypeValues = [
        {value: ".", name: "."},
        {value: "_", name: "_"},
        {value: "-", name: "-"},
        {value: " ", name: " "},
    ]

    const update = (event) => {
        const {dataset: {value}, parentNode} = event.currentTarget;
        const name = getDataNameFromParentNode(parentNode);
        return onChange({update: {name: name, value: value, nested: nested}})
    }

    return (
        <ListDropdown id={'separatorTypeDropdown'} name={'separator'}
            aria-label={t('aria:hiddenAssistText.separator')} value={selectedValue}
            items={separatorTypeValues} onItemSelect={update}
            buttonStyle={{paddingRight: '0.25rem'}}
            {...rest}
        />
    )
}

function DelimiterDropdown(props) {
    const {selectedValue, onChange, nested, ...rest} = props;
    const {t} = useTranslation(['aria']);

    const delimiterTypeValues = [
        {value: "", name: ""},
        {value: ".", name: "."},
        {value: "-", name: "-"},
    ]

    const update = (event) => {
        const {dataset: {value}, parentNode} = event.currentTarget;
        const name = getDataNameFromParentNode(parentNode);
        return onChange({update: {name: name, value: value, nested: nested}})
    }

    return (
        <ListDropdown id={'delimiterTypeDropdown'} name={'delimiter'}
            aria-label={t('aria:hiddenAssistText.delimiter')} value={selectedValue}
            items={delimiterTypeValues} onItemSelect={update}
            {...rest}
        />
    )
}
