import React from "react";
import '../questionStyle.css'
import ToggleSwitch from "../../../../components/common/ToggleSwitch/ToggleSwitch";
import HTMLTextInput from "../../../../components/common/HTMLTextInput/HTMLTextInput";

export function QuestionSwitch (props) {
    const {question, dataName, options, onClick} = props;

    const rowStyle = {
        borderBottom: "0.5rem solid transparent"
    }

    const componentExampleStyle = {
        fontSize: "14px",
        color: "#777",
    }

    const componentExampleSpace = {
        height: "24px",
        width: "20px"
    }

    return (
        <div className={"display-item"}>
            {question.length > 0 &&
                <label className="label is-heading">
                    {question}
                </label>
            }
            <div className={'settings-table no-border'} style={{border: '1px solid transparent'}}>
                <div className="table-row-group">
                    {options.map(option => {
                        return (
                            <div key={option.id} className="settings-row">
                                <div key={option.id} className="settings-label-cell" style={rowStyle}>
                                    <ToggleSwitch
                                        id={dataName}
                                        name={option.id}
                                        label={option.value}
                                        checked={option.toggled}
                                        onClick={onClick}/>
                                    {(option.componentExample != null && option.toggled) &&
                                        <div style={componentExampleSpace} />
                                    }
                                </div>

                                <div key={`input_${option.id}`} className="settings-value-cell" style={rowStyle}>
                                    {option.component}
                                    {(option.componentExample != null && option.toggled) &&
                                        <label style={componentExampleStyle}>{option.componentExample}</label>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export function ProfileQuestionSwitch (props) {
    const {question, dataName, options, values, onClick, onChange, ProfileComponent=HTMLTextInput} = props;
    const profileInputProps = values.map((profile) => {
        const {name, value, isRequired, placeholder, ...attr} = profile;
        return {
            name: name,
            value: value,
            onChange: onChange,
            isRequired: isRequired,
            isDisabled: !isRequired,
            placeholder: placeholder,
            ...attr
        }
    });

    const styles = {
        row: {
            borderBottom: "0.5rem solid transparent"
        },
        example: {
            fontSize: "14px",
            color: "#777",
        },
        space: {
            height: "24px",
            width: "20px"
        }
    }

    return (
        <div className={"display-item"}>
            {question.length > 0 &&
                <label className="label is-heading">
                    {question}
                </label>
            }

            <div className={'settings-table no-border'} style={{border: '1px solid transparent'}}>
                <div className="table-row-group">
                    {options.map((option, index) => {
                        const profileProps = profileInputProps[index];
                        return (
                            <div key={option.name} className="settings-row">
                                <div key={option.name} className="settings-label-cell" style={styles.row}>
                                    <ToggleSwitch
                                        id={dataName}
                                        name={option.name}
                                        label={option.value}
                                        checked={option.toggled}
                                        onClick={onClick}/>
                                    {(profileProps.example != null && option.toggled) &&
                                        <div style={styles.space} />
                                    }
                                </div>

                                <div key={`input_${option.name}`} className="settings-value-cell" style={styles.row}>
                                    <ProfileComponent {...profileProps}/>
                                    {(profileProps.example != null && option.toggled) &&
                                        <label style={styles.example}>{profileProps.example}</label>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )

}


export function GenericQuestionSwitch (props) {
    const {question, dataName, options, onClick} = props;

    return (
        <div className={"display-item"}>
            {question.length > 0 &&
            <label className="label is-heading">
                {question}
            </label>
            }
            <div className={'settings-table no-border tight-padding'} style={{border: '1px solid transparent'}}>
                <div className="table-row-group">
                    {options.map(option => {
                        return (
                            <div key={option.id} className="settings-row">
                                <div key={option.id} className="table-cell">
                                    <ToggleSwitch
                                        id={dataName}
                                        name={option.id}
                                        label={option.value}
                                        checked={option.toggled}
                                        onClick={onClick}/>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )

}