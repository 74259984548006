import React, {useState} from "react";
import "./FileTable.css";
import {buildClassName, bytesCountToReadableCount, getValues} from "../../../utilities/helperFunctions";
import {useTranslation} from "react-i18next";
import {icon} from "../../../utilities/iconResolver";


function FileTable(props) {
  const {t} = useTranslation(['dataset']);

  const {
    nodes=[],
    leafIcon,
    isDisabled,
    ...attr
  } = props;

  const [expanded, setExpanded] = useState({});
  function toggleExpanded(event) {
    const {index} = event.currentTarget.dataset;

    setExpanded(prevExpanded => {
      return {
        ...prevExpanded,
        [index]: !prevExpanded[index]
      }
    });
  }

  // Assuming 1 node has path means all nodes have path
  const hasPaths = getValues(nodes)
      .some(node => node.path != null)

  const disabled = isDisabled ? ' is-disabled' : '';

  return (
      <div className={'file-table' + disabled} {...attr}>
        <div className="file-table-header">
          <div className="file-header">
            <label className="label is-bold">
              {t('dataset:label.location')}
            </label>
          </div>

          <div className="file-header">
            {hasPaths &&
                <label className="label is-bold">
                  {t('dataset:label.path')}
                </label>
            }
          </div>

          <div className="file-header is-count" style={{width: '8rem'}}>
            <label className="label is-bold">
              {t('dataset:label.fileCount')}
            </label>
          </div>

          <div className="file-header is-count" style={{width: '8rem'}}>
            <label className="label is-bold">
              {t('dataset:label.size')}
            </label>
          </div>
        </div>


        <div className="file-table-body">
          {nodes.map((node, i) => {

            if (node.isDirectory) {
              return <FolderRow key={i} {...node} nesting={0} index={i}
                  onClick={isDisabled ? null : toggleExpanded} expanded={expanded} leafIcon={leafIcon}
              />
            }

            return <FileRow key={i} {...node} nesting={0}
                icon={leafIcon}
            />
          })}
        </div>
      </div>
  )
}


function FolderRow(props) {

  const {
    children,
    leafIcon,
    index,
    nesting,
    expanded,
    onClick,
    ...attr
  } = props;

  const isExpanded = !!expanded[index];

  return (
      <>
        <FileRow {...attr} nesting={nesting} onClick={onClick}
            index={index} iconName={isExpanded ? 'openedFolder' : 'closedFolder'}
        />

        {isExpanded && children.map((node, i) => {
          const key = `${index}${i}`;

          if (node.isDirectory) {
            return <FolderRow key={key} {...node} nesting={nesting + 1} onClick={onClick}
                index={`${index}${i}`} expanded={expanded} leafIcon={leafIcon}
            />
          }

          return <FileRow key={key} {...node} nesting={nesting + 1}
              iconName={leafIcon}
          />
        })}
      </>
  )
}

function FileRow(props) {
    const {t} = useTranslation(['dataset']);

    const {
        name,
        type,
        fileCount,
        size,
        path,
        iconName,
        index,
        nesting,
        onClick
    } = props;

    const locationNodeClassName = buildClassName(
        'location-node',
        (onClick != null) && 'is-expandable'
    );
    const paddingLeft = `${1 + nesting / 2}rem`;

    let effectiveName = name;
    if (effectiveName == null)
        effectiveName = t('dataset:label.other');

    const nameLabelClassName = buildClassName(
        'label',
        'is-ellipsis',
        name == null && 'is-italic'
    );

    return (
        <div className="file-row">
            <div className="file-cell" style={{paddingLeft}}>
                <div className={locationNodeClassName} data-index={index} onClick={onClick}>
                    {icon &&
                        <span className="icon is-small">
                            <img src={icon(iconName)} alt={t(`aria:hiddenAssistText.${iconName}Icon`)}/>
                        </span>
                    }

                    <span className={nameLabelClassName}>
                        {effectiveName}
                      </span>
                </div>
            </div>

            <FileSettings path={path} size={size} fileCount={fileCount}/>
        </div>
    )
}

function FileSettings(props) {

    const {
        path,
        size,
        fileCount
    } = props;

    return (
        <>
            <div className="file-cell">
                {path != null &&
                    <span className="label is-note">
                        {path}
                    </span>
                }
            </div>

            <div className="file-cell is-count">
                {fileCount != null &&
                    <span className="label is-note">
                        {fileCount}
                    </span>
                }
            </div>

            <div className="file-cell is-count">
                {size != null &&
                    <span className="label is-note">
                        {bytesCountToReadableCount(size)}
                    </span>
                }
            </div>
        </>
    )
}

export default FileTable;
