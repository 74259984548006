import React, {useRef, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from "react-i18next";
import Tablet, {EditTabletHeader} from '../../common/Tablet/Tablet';
import {createInputHandler} from '../../../utilities/componentFunctions';
import EditPanel from '../../common/EditPanel/EditPanel';
import ExpandableContent, {ExpandableEditTextArea} from '../../common/ExpandableContent/ExpandableContent';
import {SettingsRowValue} from '../../common/CustomTable/CustomTable';
import {shouldEnableClsLicenceSource} from "../../../utilities/shouldEnableFunctions";
import {permissionKeys, statusKeys} from "../../../i18next/keys";
import {updateMode} from "../../../utilities/constants";
import PopupModel from "../../../models/scheduler/PopupModel";
import LicenceSourceModel from "../../../models/settings/LicenceSourceModel";
import EditModel from "../../../models/scheduler/EditModel";
import {makeGetEditDetails} from "../../../reselect/selectors";
import {ButtonGroup} from "../../common/Button/Button";
import SchedulerModel from "../../../models/scheduler/SchedulerModel";
import {switchcaseF} from "../../../utilities/helperFunctions";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import {ExpandableStatusLog} from "../../common/Common";
import {useClearOnFirstCallback} from "../../../utilities/formHooks";
import LimitedTablet from "../../limited/LimitedTablet";

function ClsLicenceSourceTablet(props) {
    const {t} = useTranslation(['nuixLicenceSource', 'common']);

    const {
        nuixLicenceSourceId,
        clsLicenceSourceName,
        description,
        filter,

        username,
        clsLicenseSourceUsername,
        clsLicenseSourcePassword,

        userPermissions,
        status
    } = props;

    const clsLicenseSourcePasswordRef = useRef();
    const {updateEdit, setEditSaveEnabled} = props;

    const inputHandler = createInputHandler({
        mode: updateMode.EDIT,
        updateEdit,
        setEditSaveEnabled,
        passwordRef: clsLicenseSourcePasswordRef,
        passwordName: 'clsLicenseSourcePassword',
        passwordResetNames: ['clsLicenseSourceUsername'],
        values: {clsLicenceSourceName, clsLicenseSourceUsername},
        shouldEnable: shouldEnableClsLicenceSource
    })

    const {edit, promptDelete} = props;
    function menuOptionHandler(event) {
        const {value} = event.currentTarget.dataset;

        switchcaseF({
            'edit': edit,
            'promptDelete': promptDelete
        })()(value);
    }

    const [menuOptions] = useState([
        {name: t('common:option.edit'), value: 'edit'},
        {name: t('common:option.delete'), value: 'promptDelete'},
    ]);

    const {
        close,
        saveEdit,
        cancelEdit,
        isEditActive,
        isSaveEnabled,
        isDisabled
    } = props;

    const canModifyClsLicenceSource = userPermissions.includes(permissionKeys.MODIFY);

    const isDescriptionVisible = isEditActive || description;
    const height = isEditActive ? 'auto' : '50vh';

    const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);
    if (isViewLimited && !canModifyClsLicenceSource) {
        return (
            <LimitedTablet id={nuixLicenceSourceId} type={'clsLicenceSource'} name={clsLicenceSourceName} label={t(`nuixLicenceSource:label.clsLicenceSourceName`)}
                           status={status} enabled={true} canModify={canModifyClsLicenceSource} isDisabled={isDisabled} description={description}
                           onClose={close}
            />
        )
    }

    return (
        <Tablet height={height} onClose={close} isDisabled={isDisabled}
            closeButtonAriaLabel={t('nuixLicenceSource:option.closeClsTablet_name', {name: clsLicenceSourceName})}
            header={
                <EditTabletHeader label={t('nuixLicenceSource:label.clsLicenceSourceName')} type={'clsLicenceSource'}
                    id={nuixLicenceSourceId} name={'clsLicenceSourceName'} value={clsLicenceSourceName} inputHandler={inputHandler}
                    canModify={canModifyClsLicenceSource} menuOptions={menuOptions} menuOptionHandler={menuOptionHandler}
                    isEditActive={isEditActive} isDisabled={isDisabled}
                />
            }
            body={
                <>
                    {status.code === statusKeys.ERROR && !isEditActive &&
                    <div className="display-item">
                        <ExpandableStatusLog {...status} isDisabled={isDisabled}/>
                    </div>
                    }

                    <div className="display-input">
                        <EditPanel isActive={isEditActive}
                            options={
                                <ButtonGroup
                                    buttons={[{
                                        label: t('common:option.cancel'),
                                        onClick: cancelEdit,
                                        isDisabled
                                    }, {
                                        label: t('common:option.save'),
                                        onClick: saveEdit,
                                        isDisabled: isDisabled || !isSaveEnabled
                                    }]}
                                    style={{
                                        marginTop: '0.75rem'
                                    }}
                                />
                            }
                        >

                            {isDescriptionVisible &&
                            <ExpandableEditTextArea label={t('common:label.description')} name={'description'} value={description}
                                onChange={inputHandler} isEditActive={isEditActive} isDisabled={isDisabled}/>
                            }

                            <ExpandableSettings clsLicenseSourcePasswordRef={clsLicenseSourcePasswordRef} initialPassword={clsLicenseSourcePassword} filter={filter}
                                username={username} clsLicenseSourceUsername={clsLicenseSourceUsername}
                                inputHandler={inputHandler} isEditActive={isEditActive} isDisabled={isDisabled}/>

                        </EditPanel>
                    </div>
                </>
            }
        />
    )
}

function ExpandableSettings(props) {
    const {isEditActive, clsLicenseSourcePasswordRef, initialPassword, isDisabled, ...others} = props;
    const {t} = useTranslation(['common']);

    return (
        <ExpandableContent label={t('common:label.settings')} isDisabled={isDisabled}>
            {isEditActive ?
                <EditLicenceSettings clsLicenseSourcePasswordRef={clsLicenseSourcePasswordRef} initialPassword={initialPassword} {...others} isDisabled={isDisabled} />
                :
                <LicenceSettings {...others} isDisabled={isDisabled} />
            }
        </ExpandableContent>
    );
}

function LicenceSettings(props) {
    const {filter, username, isDisabled} = props;
    const {t} = useTranslation(['nuixLicenceSource', 'common']);

    return (
        <div className="settings-table">
            <div className="table-row-group">
                {filter &&
                <SettingsRowValue label={t('nuixLicenceSource:label.filter')} value={filter} isDisabled={isDisabled}/>
                }
                <SettingsRowValue label={t('common:label.username')} value={username} isDisabled={isDisabled}/>
            </div>
        </div>
    );
}

function EditLicenceSettings(props) {
    const {filter, clsLicenseSourceUsername, clsLicenseSourcePasswordRef, initialPassword, inputHandler, isDisabled} = props;
    const {t} = useTranslation(['nuixLicenceSource', 'common']);
    const clearOnFirstCallback = useClearOnFirstCallback();

    return (
        <>
            <HTMLTextInput label={t('nuixLicenceSource:label.filter')} name={'filter'} value={filter}
                onChange={inputHandler} isDisabled={isDisabled}/>

            <div className="display-input">
                <HTMLTextInput label={t('common:label.username')} name={'clsLicenseSourceUsername'} value={clsLicenseSourceUsername}
                    onChange={inputHandler} isDisabled={isDisabled} isRequired/>
            </div>
            <div className="display-input">
                <HTMLTextInput label={t('common:label.password')} inputRef={clsLicenseSourcePasswordRef} name={'clsLicenseSourcePassword'} type={'password'}
                    defaultValue={initialPassword} onChange={inputHandler} onBeforeInput={clearOnFirstCallback} isDisabled={isDisabled} isRequired/>
            </div>
        </>
    );
}

const makeMapStateToProps = () => {
    const getEditDetails = makeGetEditDetails();
    return (state, ownProps) => {
        const {nuixLicenceSourceId} = ownProps;

        const {nuixLicenceSourceDetailsMap, componentStates: {licenceSourceTablet}} = state;
        const {name: clsLicenceSourceName, ...otherDetails} = nuixLicenceSourceDetailsMap.get(nuixLicenceSourceId);

        const {activeModel, values, isSaveEnabled} = getEditDetails(state, {model: LicenceSourceModel.nom});
        const isEditActive = (activeModel === LicenceSourceModel.nom);

        return {
            nuixLicenceSourceId,
            clsLicenceSourceName,
            ...otherDetails,
            ...values,
            isEditActive,
            isSaveEnabled,
            ...licenceSourceTablet
        }
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        yieldEffect: effect => dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(effect)),

        edit: () => dispatch(LicenceSourceModel.actionCreators.startEdit(ownProps.nuixLicenceSourceId)),
        promptDelete: () => dispatch(LicenceSourceModel.actionCreators.promptDelete(ownProps.nuixLicenceSourceId)),

        ...EditModel.buildDispatchers(dispatch),

        showError: payload => dispatch(PopupModel.actionCreators.showError(payload)),
        close: () => dispatch(LicenceSourceModel.actionCreators.hideTablet())
    };
};

export default connect(makeMapStateToProps, mapDispatchToProps)(ClsLicenceSourceTablet);