import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getValues} from "../../../utilities/helperFunctions";
import {jobEventTriggerKeys} from "../../../i18next/keys";
import {createCloseHandler} from "../../../utilities/componentFunctions";
import NotificationRuleModel, {NotificationRuleSaga} from "../../../models/settings/NotificationRuleModel";
import PopupModel from "../../../models/scheduler/PopupModel";
import Form, {FormHeader} from "../../common/Form/Form";
import TextArea from "../../common/TextArea/TextArea";
import {SwitchItemContainer} from "../../common/ListContainer/ListContainer";
import Checkbox from "../../common/Checkbox/Checkbox";
import {ButtonGroup} from "../../common/Button/Button";
import {contextCall} from "../../../saga/sagaFunctions";
import SchedulerModel from "../../../models/scheduler/SchedulerModel";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import {useDropdownHandler, useInputHandler, useToggleHandler, useUpdateHandler} from "../../../utilities/formHooks";
import {SmtpServiceDropdown} from "../../thirdPartyService/smtp/SmtpServiceDropdown";
import Text from "../../common/Text/Text";


const initialState = {
    ruleType: NotificationRuleModel.Type.EMAIL_SMTP,
    emailNotificationRuleName: '',
    description: '',
    smtpServiceId: null,
    to: '',
    cc: '',
    htmlFormat: true
}

function EmailNotificationRuleForm() {
    const {t} = useTranslation(['notificationRule', 'job', 'common']);
    const dispatch = useDispatch();

    const {
        isDisabled
    } = useSelector(state => state.componentStates.notificationRuleForm);

    const [state, setState] = useState(initialState);

    // left -> available, right -> selected
    const [triggers, setTriggers] = useState({
        left: getValues(jobEventTriggerKeys).map(value => ({name: t(`job:trigger.${value}`), value})),
        right: []
    });

    const updateHandler = useUpdateHandler({
        updateState: setState
    });

    const inputHandler = useInputHandler({
        updateHandler
    });
    const toggleHandler = useToggleHandler({
        updateHandler
    });
    const dropdownHandler = useDropdownHandler({
        updateHandler
    });

    function onSubmit() {
        dispatch(NotificationRuleModel.actionCreators.submitForm({
            ...state,
            triggers
        }));
    }

    function onTest() {
        const testEffect = contextCall(NotificationRuleSaga, 'testRuleData', 'Form', {...state, triggers});
        dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(testEffect));
    }

    const onClose = createCloseHandler({
        popupKey: `emailNotificationRuleDiscard`,
        state,
        initialState,
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        close: () => dispatch(NotificationRuleModel.componentActionCreators.updateDisplay({isEmailNotificationRuleFormActive: false}))
    });


    const isAddEnabled = NotificationRuleModel.validateFormData(state);

    return (
        <Form onClose={onClose} isDisabled={isDisabled} closeButtonAriaLabel={t('notificationRule:option.closeEmailRuleForm')}
            header={
                <FormHeader text={t('notificationRule:label.emailName')} iconName={'emailNotificationRule'} isDisabled={isDisabled}/>
            }
            body={
                <>
                    <div className="display-input">
                        <HTMLTextInput label={t('common:label.name')} name={'emailNotificationRuleName'} value={state.emailNotificationRuleName}
                            onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                    </div>

                    <div className="display-input">
                        <TextArea label={t('common:label.description')} name={'description'} value={state.description}
                            onChange={inputHandler} isDisabled={isDisabled}/>
                    </div>

                    <EmailSettingsForm rule={state} inputHandler={inputHandler} toggleHandler={toggleHandler}
                        dropdownHandler={dropdownHandler} isDisabled={isDisabled}/>

                    <div className="display-input">
                        <SwitchItemContainer id="notificationRuleTriggerSwitchContainer" label={t('notificationRule:label.triggers')}
                            ariaLabelKey={'EmailNotificationTrigger'} items={triggers} setItems={setTriggers} isDisabled={isDisabled}/>
                    </div>
                </>
            }
            footer={
                <ButtonGroup buttons={[{
                    id: 'formFooterBackButton',
                    label: t('common:option.cancel'),
                    onClick: onClose,
                    isDisabled
                }, {
                    id: 'formTestButton',
                    label: t('notificationRule:label.testRule'),
                    onClick: onTest,
                    isDisabled: isDisabled || !isAddEnabled
                }, {
                    id: 'formFooterNextButton',
                    label: t('notificationRule:option.addEmailNotificationRule'),
                    onClick: onSubmit,
                    isDisabled: isDisabled || !isAddEnabled
                }]}/>
            }
        />
    )
}

export function EmailSettingsForm(props) {
    const {t} = useTranslation(['notificationRule']);

    const {
        rule,
        inputHandler,
        toggleHandler,
        dropdownHandler,
        isDisabled
    } = props;

    return (
        <>
            <div className="display-input flex-center">
                <Text value={`${t('notificationRule:label.smtpService')}:`} style={{marginRight: '0.75rem'}}
                    isDisabled={isDisabled}/>

                <SmtpServiceDropdown value={rule.smtpServiceId} onItemSelect={dropdownHandler}
                    autoSelect isRequired isDisabled={isDisabled} buttonStyle={{maxWidth: '100%'}}/>
            </div>

            <div className="display-input">
                <HTMLTextInput label={t('notificationRule:label.to')} name={'to'} value={rule.to}
                    onChange={inputHandler} isDisabled={isDisabled} isRequired/>
            </div>

            <div className="display-input">
                <HTMLTextInput label={t('notificationRule:label.cc')} name={'cc'} value={rule.cc}
                    onChange={inputHandler} isDisabled={isDisabled}/>
            </div>

            <div className="display-input">
                <Checkbox label={t('notificationRule:label.htmlFormat')} name={'htmlFormat'}
                    checked={rule.htmlFormat} onClick={toggleHandler} isDisabled={isDisabled}/>
            </div>
        </>
    )
}

export default EmailNotificationRuleForm;
