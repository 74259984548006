import React, {useLayoutEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {icon} from '../../../utilities/iconResolver';
import {ClientSelectPanel} from '../../panels/SelectPanel';
import ClientDisplay from '../../client/ClientDisplay';
import ClientForm from '../../client/ClientForm';
import {details, MIN_SIZE_FOR_SEARCH} from '../../../utilities/constants';
import LoadingWrapper from "../../common/LoadingWrapper/LoadingWrapper";
import {AddButtonHeader} from "../../common/Button/Button";
import SearchBar from "../../common/SearchBar/SearchBar";
import {CheckedDropdown} from "../../common/Dropdown/Dropdown";
import {getValues, isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import ClientModel from "../../../models/client/ClientModel";
import ClientPoolFilterModel from "../../../models/filter/ClientPoolFilterModel";
import {selectCanAddClient, selectClientPoolFilteredClientIds} from "./selectors";
import {useAutoSelectId} from "../../../utilities/hooks";
import CurrentUserModel from "../../../models/user/CurrentUserModel";
import {MainPageAsideHeader} from "./MainPage";

function ClientPage() {
    const {t} = useTranslation(['aria', 'client', 'common']);
    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState('');

    const isLoading = useSelector(state => !state.hasLoaded[details.CLIENTS]);
    const {clientId, isClientFormActive} = useSelector(state => state.componentStates.clientPage);
    const clientIds = useSelector(state => selectClientPoolFilteredClientIds(state, {searchText}));
    const clientPoolFilter = useSelector(state => state.clientPoolFilter);

    const canAddClient = useSelector(selectCanAddClient);
    const canSearchClients = useSelector(state => state.clientDetailsMap.size >= MIN_SIZE_FOR_SEARCH);

    // dispatch page active/inactive
    useLayoutEffect(() => {
        dispatch(ClientModel.componentActionCreators.setPageActive());
        dispatch(CurrentUserModel.actionCreators.pollActivity(CurrentUserModel.activities.ADMINISTRATION));

        return () => dispatch(ClientModel.componentActionCreators.setPageInactive());
    }, []);

    useAutoSelectId(clientId, clientIds,
        id => dispatch(ClientModel.actionCreators.showDisplay(id))
    );


    function clientSelectHandler(event) {
        const {value} = event.currentTarget.dataset;

        if (clientId !== value) {
            dispatch(ClientModel.actionCreators.showDisplay(value));
        }
    }

    return (
        <main className="mainPage">
            <aside className="selectPage-aside">
                <section className="panel-header">
                    <MainPageAsideHeader text={t('client:label.name_plural')}
                        icon={icon('client')} ariaKey={'client'}
                    />
                </section>

                <section className="panel-action-bar">
                    <section style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        marginTop: '1rem'
                    }}>
                        {canAddClient &&
                            <AddButtonHeader text={t('client:label.name')} canModify={canAddClient}
                                aria-label={t('aria:hiddenAssistText.addClient')} style={{marginTop: '0'}}
                                onClick={() => dispatch(ClientModel.actionCreators.showForm())}
                            />
                        }

                        {isNotEmptyNorFalsy(clientPoolFilter) &&
                            <CheckedDropdown aria-label={t('aria:hiddenAssistText.clientPoolChecked')} name={'clientPoolIds'}
                                noneSelectedMessage={t('client:option.allClients')}
                                items={getValues(clientPoolFilter)}
                                onClear={() => dispatch(ClientPoolFilterModel.actionCreators.resetFilter())}
                                onToggle={event => dispatch(ClientPoolFilterModel.actionCreators.toggleFilter(event.currentTarget.dataset.value))}
                            />
                        }
                    </section>

                    {canSearchClients &&
                        <SearchBar id="clientSearchBar"
                            value={searchText}
                            onChange={event => setSearchText(event.target.value)}
                        />
                    }
                </section>

                <section className="panel-list" role="tablist">
                    <LoadingWrapper isLoading={isLoading}>
                        {clientIds
                            .map(_id =>
                                <ClientSelectPanel key={_id} clientId={_id}
                                    isActive={_id === clientId} onPanelSelect={clientSelectHandler}
                                />
                            )}
                    </LoadingWrapper>
                </section>
            </aside>

            {clientIds.includes(clientId) &&
                <ClientDisplay clientId={clientId}/>
            }
            {isClientFormActive && canAddClient &&
                <ClientForm/>
            }
        </main>
    )
}

export default ClientPage;
