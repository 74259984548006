import React from "react";
import {useTranslation} from "react-i18next";
import {
    buildClassName,
    getEntries,
    getTranslatedValueOrDefault,
    isNotEmptyNorFalsy,
    stringToBool
} from "../../../utilities/helperFunctions";
import FieldConfigurationWrapper from "./FieldConfigurationWrapper";
import FormBuilderComponentConfiguration from "../../../models/workflowbuilder/FormBuilderComponentConfiguration";
import Text from "../../common/Text/Text";
import {
    selectPositionedLabelFieldComponent,
    useCommonFieldRowCellConfiguration,
    useCommonFieldRowConfiguration
} from "./FieldRow";
import FormBuilderConfiguration from "../../../models/workflowbuilder/FormBuilderConfiguration";
import RadioButton, {RadioButtonGroup} from "../../common/RadioButton/RadioButton";

function FieldRadioButtonGroup(props) {
    const {t} = useTranslation(['workflowBuilder']);
    const {
        parentId,
        formKey,
        configuration,
        fieldConfigurations,

        label,
        state,
        updateState,

        inputHandler,
        toggleHandler,
        dropdownHandler,

        isRequired,
        isDisabled
    } = props;

    const valueConfigurationEntries = getEntries(configuration.valueConfigurations);

    let radioButtonName, selectedValue, isBooleanType, onClick;
    if (configuration.componentType === FormBuilderComponentConfiguration.ComponentType.RADIO_BUTTON_CHECKBOX_GROUP) {
        radioButtonName = configuration.name;
        selectedValue = '';
        const updateTemplate = {};
        for (const [value] of valueConfigurationEntries) {
            if (value) {
                updateTemplate[value] = false;
                if (state[value]) {
                    selectedValue = value;
                }
            }
        }
        onClick = function(event) {
            const {value} = event.target;
            updateState(prevState => {
                let updates = {...updateTemplate};
                if (value) {
                    updates[value] = !prevState[value];
                }
                return updates;
            });
        }
    } else {
        const radioButtonConfiguration = configuration.radioButtonConfiguration;
        radioButtonName = radioButtonConfiguration.name;
        isBooleanType = radioButtonConfiguration.type === FormBuilderComponentConfiguration.Type.BOOLEAN;
        selectedValue = state[radioButtonName];

        if (!isBooleanType) {
            onClick = inputHandler;
        } else {
            onClick = toggleHandler;
        }
    }

    const groupClassName = buildClassName(
        'workflow-builder__field-group',
        'forms-table',
        !configuration.styled && 'no-style'
    );

    const tableClassName = buildClassName(
        'forms-table',
        isRequired && !selectedValue && 'is-required'
    );

    const groupLeftLabel = FormBuilderConfiguration.getLabelTranslation(t, formKey, configuration.positionedLabel.left);
    return (
        <>
            {configuration.styled && label &&
                <div className="workflow-builder__field-group-label">
                    <Text value={label} isDisabled={isDisabled}/>
                </div>
            }

            <div className={groupClassName}>
                <div className="table-row">
                    {groupLeftLabel &&
                        <div className="table-cell no-stretch align-top" style={{lineHeight: '2.3'}}>
                            <Text value={`${groupLeftLabel}:`} isDisabled={isDisabled}/>
                        </div>
                    }
                    <div className="table-cell">
                        <table className={tableClassName}>
                            <tbody className="table-row-group">
                            {valueConfigurationEntries.filter(([, valueConfiguration]) => {
                                return valueConfiguration.isVisible(state, fieldConfigurations);
                            }).map(([_value, valueConfiguration]) => {
                                const translationKey = `workflowBuilder:${formKey}.${radioButtonName}_${valueConfiguration.label}`;
                                let buttonLabel = getTranslatedValueOrDefault(t, valueConfiguration.label, translationKey);

                                // Only show ':' for selected visible values
                                const isValueVisible = valueConfiguration.componentConfiguration != null
                                    && valueConfiguration.componentConfiguration.isVisible(state, fieldConfigurations);
                                if (isValueVisible && typeof buttonLabel === 'string') {
                                    buttonLabel += ':'
                                }

                                let Component;
                                if (isValueVisible) {
                                    const isRowType = valueConfiguration.componentConfiguration.componentType === FormBuilderComponentConfiguration.ComponentType.ROW;
                                    if (isRowType) {
                                        Component = RadioButtonValueRow
                                    } else {
                                        Component = selectPositionedLabelFieldComponent(valueConfiguration.componentConfiguration);
                                    }
                                }

                                const value = isBooleanType ? stringToBool(_value) : _value;
                                let radioButtonId = `field:${radioButtonName}:${value}`;
                                if (parentId != null) {
                                    radioButtonId = `${parentId}:${radioButtonId}`;
                                }
                                return (
                                    <tr key={value} className="table-row">
                                        <td className="table-cell no-stretch align-top" style={{lineHeight: 2}}>
                                            <RadioButton id={radioButtonId} data-type={FormBuilderComponentConfiguration.ComponentType.RADIO_BUTTON}
                                                label={buttonLabel} name={radioButtonName} selected={selectedValue} value={value} onClick={onClick}
                                                isWordWrap={false} isDisabled={isDisabled}/>
                                        </td>
                                        <td className="table-cell">
                                            {isValueVisible &&
                                                <FieldConfigurationWrapper parentId={parentId} Component={Component} formKey={formKey} fieldConfigurations={fieldConfigurations}
                                                    configuration={valueConfiguration.componentConfiguration} state={state} updateState={updateState}
                                                    aria-labelledby={radioButtonId} inputHandler={inputHandler} toggleHandler={toggleHandler}
                                                    dropdownHandler={dropdownHandler} isDisabled={isDisabled}/>
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

function RadioButtonValueRow(props) {
    const {
        isDisabled
    } = props;

    const commonFieldRowConfiguration = useCommonFieldRowConfiguration(props);
    const {
        filteredComponentConfigurations
    } = commonFieldRowConfiguration;

    return (
        <div className="flex-center margin-children">
            {filteredComponentConfigurations.map((config, index) => {
                return (
                    <RadioButtonValueRowCell key={config.name} {...commonFieldRowConfiguration} {...props}
                        configuration={config} index={index} isDisabled={isDisabled}/>
                )
            })}
        </div>
    )
}

function RadioButtonValueRowCell(props) {
    const {
        fieldConfigurations,
        configuration,
        ...rest
    } = props;

    const {expandCol, addColonToLabel} = useCommonFieldRowCellConfiguration(props);
    const Component = selectPositionedLabelFieldComponent(configuration);

    return (
        <div style={expandCol ? {flex: 1} : {}}>
            <FieldConfigurationWrapper {...rest} Component={Component} addColonToLabel={addColonToLabel} expandDropdown={expandCol}
                fieldConfigurations={fieldConfigurations} configuration={configuration}/>
        </div>
    )
}

export function RadioButtonField(props) {
    const {t} = useTranslation(['workflowBuilder']);
    const {
        formKey,
        configuration,
        label,
        addColonToLabel,
        inputHandler,
        toggleHandler,
        ...attr
    } = props;

    let values = [true, false], onClick = toggleHandler;
    if (isNotEmptyNorFalsy(configuration.allowedValues)) {
        values = configuration.allowedValues;
    }
    if (configuration.type === FormBuilderComponentConfiguration.Type.BOOLEAN) {
        values = values.map(v => stringToBool(v));
    } else {
        onClick = inputHandler;
    }

    const items = values.map(value => {
        const translationKey = `workflowBuilder:${formKey}.${configuration.name}_${value}`;
        return {
            name: getTranslatedValueOrDefault(t, value, translationKey) + (addColonToLabel ? ':' : ''),
            value
        }
    });

    const radioButtonGroup = (
        <RadioButtonGroup name={configuration.name} items={items}
            onClick={onClick} isWordWrap={false} {...attr} />
    );

    if (label) {
        return (
            <table className="forms-table">
                <tbody className="table-row-group">
                <tr className="table-row">
                    <td className="table-cell no-stretch align-top">
                        <Text value={`${label}:`} isDisabled={attr.isDisabled}/>
                    </td>
                    <td className="table-cell">
                        {radioButtonGroup}
                    </td>
                </tr>
                </tbody>
            </table>
        )
    }

    return radioButtonGroup;
}

export default FieldRadioButtonGroup;
