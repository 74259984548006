import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {createEditHandler, createInputHandler} from "../../../utilities/componentFunctions";
import {getMapValueName, isNotEmptyNorFalsy, objEquals} from "../../../utilities/helperFunctions";
import {permissionKeys} from "../../../i18next/keys";
import Tablet, {EditTabletHeader} from "../../common/Tablet/Tablet";
import EditPanel from "../../common/EditPanel/EditPanel";
import ExpandableContent, {ExpandableEditTextArea} from "../../common/ExpandableContent/ExpandableContent";
import {makeGetEditDetails} from "../../../reselect/selectors";
import NotificationRuleModel, {NotificationRuleSaga} from "../../../models/settings/NotificationRuleModel";
import EditModel from "../../../models/scheduler/EditModel";
import {SettingsRowValue} from "../../common/CustomTable/CustomTable";
import {createToggleHandler} from "../../common/Checkbox/helpers";
import {contextCall} from "../../../saga/sagaFunctions";
import SchedulerModel from "../../../models/scheduler/SchedulerModel";
import {ButtonGroup} from "../../common/Button/Button";
import {EmailSettingsForm} from "./EmailNotificationRuleForm";
import {SwitchItemContainer} from "../../common/ListContainer/ListContainer";
import {ExpandableStatusLogWrapper} from "../../common/Common";
import LimitedTablet from "../../limited/LimitedTablet";
import ThirdPartyServiceModel from "../../../models/thirdparty/ThirdPartyServiceModel";
import {createDropdownHandler} from "../../common/Dropdown/helpers";
import Text from "../../common/Text/Text";
import {SmtpEmailCounterSettings} from "../../thirdPartyService/smtp/SmtpServiceSettings";
import {getExportFormComponent} from "../../thirdPartyService/ThirdPartyServiceTablet";


const selectEditDetails = makeGetEditDetails()
function selectReduxState(state, props) {
    const {activeModel, values, isSaveEnabled} = selectEditDetails(state, {model: NotificationRuleModel.nom});
    const isEditActive = (activeModel === NotificationRuleModel.nom);

    const emailNotificationRule = state.notificationRuleDetailsMap.get(props.id) || {};
    return {
        emailNotificationRuleName: emailNotificationRule.name,
        ...emailNotificationRule,
        ...values,
        isEditActive,
        isSaveEnabled
    };
}

function EmailNotificationRuleTablet(props) {
    const {t} = useTranslation(['notificationRule']);
    const dispatch = useDispatch();


    const reduxState = useSelector(state => selectReduxState(state, props), objEquals);
    const {
        userPermissions,
        isEditActive,
        isSaveEnabled,

        ...emailNotificationRule
    } = reduxState;

    const {
        isDisabled
    } = useSelector(state => state.componentStates.notificationRuleTablet);


    const smtpService = useSelector(state => state.thirdPartyServiceDetailsMap.get(emailNotificationRule.smtpServiceId));
    const serviceActionBodies = ThirdPartyServiceModel.getServiceActionBodies(smtpService, emailNotificationRule.emailCounter);

    const [isSmtpServiceExportFormActive, setIsSmtpServiceExportFormActive] = useState();
    const SmtpServiceExportFormComponent = getExportFormComponent(smtpService?.type);


    const {
        saveEdit,
        cancelEdit,
        updateEdit,
        setEditSaveEnabled
    } = EditModel.buildDispatchers(dispatch);

    const editHandler = createEditHandler({
        updateEdit,
        setEditSaveEnabled,
        shouldEnable: NotificationRuleModel.validateFormData
    });

    const inputHandler = createInputHandler({
        handler: editHandler
    });
    const toggleHandler = createToggleHandler({
        handler: editHandler
    });
    const dropdownHandler = createDropdownHandler({
        handler: editHandler
    });

    function setTriggers(update) {
        editHandler({
            triggers: update(emailNotificationRule.triggers)
        });
    }

    function testRuleEdit() {
        const values = {
            id: props.id,
            ruleType: NotificationRuleModel.Type.EMAIL_SMTP,
            ...emailNotificationRule
        };

        const testEffect = contextCall(NotificationRuleSaga, 'testRuleData', 'Tablet', values);
        dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(testEffect));
    }

    function onClose() {
        dispatch(NotificationRuleModel.actionCreators.hideTablet());
    }

    function menuOptionHandler(event) {
        const {value} = event.currentTarget.dataset;

        switch (value) {
            case 'startEdit':
            case 'promptDelete':
                dispatch(NotificationRuleModel.actionCreators[value](props.id));
                break;
            case 'testSource':
                const testEffect = contextCall(NotificationRuleSaga, 'testRule', props.id);
                dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(testEffect));
                break;
            case 'exportEmailsCsv':
                if (SmtpServiceExportFormComponent != null) {
                    setIsSmtpServiceExportFormActive(true);
                }
                break;
        }

        if (serviceActionBodies != null) {
            const actionBody = serviceActionBodies.find(a => a.action === value);
            if (actionBody != null) {
                actionBody.onSuccess = () => dispatch(NotificationRuleModel.actionCreators.queryDetails());
                dispatch(ThirdPartyServiceModel.actionCreators.sendAction(actionBody));
            }
        }
    }

    const menuOptions = [
        {name: t('common:option.edit'), value: 'startEdit'},
        {name: t('common:option.delete'), value: 'promptDelete'},
        {name: t('common:option.test'), value: 'testSource'}
    ];

    const canViewSmtpServiceSensitive = smtpService && smtpService.userPermissions.includes(permissionKeys.VIEW_SENSITIVE);
    const canExportSmtpEmailsCsv = canViewSmtpServiceSensitive && ThirdPartyServiceModel.canExport(smtpService);

    if (canExportSmtpEmailsCsv || isNotEmptyNorFalsy(serviceActionBodies)) {
        menuOptions.push({isSeparator: true});
    }
    if (canExportSmtpEmailsCsv) {
        menuOptions.push({name: t('common:option.exportEmailsCsv'), value: 'exportEmailsCsv'});
    }
    if (serviceActionBodies != null) {
        for (const actionBody of serviceActionBodies) {
            menuOptions.push({
                name: t(`notificationRule:thirdPartyAction.${actionBody.action}`),
                value: actionBody.action,
                isDisabled: actionBody.disabled
            });
        }
    }


    const canModify = userPermissions.includes(permissionKeys.MODIFY);
    const showDescription = isEditActive || emailNotificationRule.description;
    const showTriggers = isEditActive || isNotEmptyNorFalsy(emailNotificationRule.triggers);

    const height = isEditActive ? 'auto' : '50vh';

    const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);
    if (isViewLimited && !canModify) {
        return (
            <LimitedTablet id={props.id} type={'emailNotificationRule'} label={t(`notificationRule:label.emailName`)}
                name={emailNotificationRule.emailNotificationRuleName} description={emailNotificationRule.description}
                status={emailNotificationRule.status} enabled={true} canModify={canModify} onClose={onClose} isDisabled={isDisabled}
            />
        )
    }

    return (
        <Tablet height={height} onClose={onClose} isDisabled={isDisabled}
            closeButtonAriaLabel={t('notificationRule:option.closeEmailTablet_name', {name: emailNotificationRule.emailNotificationRuleName})}
            header={
                <EditTabletHeader label={t('notificationRule:label.emailName')} type={'emailNotificationRule'}
                    id={props.id} name={'emailNotificationRuleName'} value={emailNotificationRule.emailNotificationRuleName}
                    inputHandler={inputHandler} canModify={canModify} menuOptions={menuOptions} menuOptionHandler={menuOptionHandler}
                    isEditActive={isEditActive} isDisabled={isDisabled}
                />
            }
            body={
                <>
                    {!isEditActive &&
                        <ExpandableStatusLogWrapper status={emailNotificationRule.status}
                            isDisabled={isDisabled}/>
                    }

                    {!isEditActive && emailNotificationRule.emailCounter &&
                        <ExpandableStatusLogWrapper status={emailNotificationRule.emailCounter.status}
                            isDisabled={isDisabled}/>
                    }

                        <div className="display-item">
                        <EditPanel isActive={isEditActive}
                            options={
                                <ButtonGroup
                                    buttons={[{
                                        label: t('common:option.cancel'),
                                        onClick: cancelEdit,
                                        isDisabled
                                    }, {
                                        label: t('common:option.test'),
                                        onClick: testRuleEdit,
                                        isDisabled: isDisabled || !isSaveEnabled
                                    }, {
                                        label: t('common:option.save'),
                                        onClick: saveEdit,
                                        isDisabled: isDisabled || !isSaveEnabled
                                    }]}
                                    style={{
                                        marginTop: '0.75rem'
                                    }}
                                />
                            }
                        >

                            {showDescription &&
                            <ExpandableEditTextArea label={t('common:label.description')} name={'description'} value={emailNotificationRule.description}
                                onChange={inputHandler} isEditActive={isEditActive} isDisabled={isDisabled}/>
                            }

                             <ExpandableContent label={t('notificationRule:label.settings')} isDisabled={isDisabled}>
                                {isEditActive ?
                                    <EmailSettingsForm rule={emailNotificationRule} inputHandler={inputHandler} toggleHandler={toggleHandler}
                                        dropdownHandler={dropdownHandler} isDisabled={isDisabled}/>
                                    :
                                    <EmailSettings rule={emailNotificationRule} isDisabled={isDisabled}/>
                                }
                            </ExpandableContent>

                            {!isEditActive && emailNotificationRule.emailCounter &&
                                <SmtpEmailCounterSettings emailCounter={emailNotificationRule.emailCounter}
                                    isDisabled={isDisabled}/>
                            }

                            {showTriggers &&
                            <ExpandableContent label={t('notificationRule:label.triggers')} isDisabled={isDisabled}>
                                {isEditActive ?
                                    <SwitchItemContainer id="notificationRuleTriggerSwitchContainer" items={emailNotificationRule.triggers}
                                        ariaLabelKey={'EmailNotificationTrigger'} setItems={setTriggers} isDisabled={isDisabled}/>
                                    :
                                    emailNotificationRule.triggers.map(trig =>
                                        <Text key={trig} value={t(`job:trigger.${trig}`)}
                                            isDisabled={isDisabled}/>
                                    )
                                }
                            </ExpandableContent>
                            }
                        </EditPanel>
                    </div>

                    {isSmtpServiceExportFormActive &&
                        <SmtpServiceExportFormComponent initiatorId={emailNotificationRule.id} thirdPartyService={smtpService}
                            onClose={() => setIsSmtpServiceExportFormActive(false)} isDisabled={isDisabled}/>
                    }
                </>
            }
        />
    )
}

function EmailSettings(props) {
    const {t} = useTranslation(['notificationRule']);
    const {rule, isDisabled} = props;

    const smtpServiceName = useSelector(state => {
        return getMapValueName(state.thirdPartyServiceDetailsMap, rule.smtpServiceId);
    });

    return (
        <div className="settings-table">
            <div className="table-row-group">
                <SettingsRowValue label={t('notificationRule:label.smtpService')} value={smtpServiceName}
                    isDisabled={isDisabled}/>

                <SettingsRowValue label={t('notificationRule:label.to')} value={rule.to}
                    isDisabled={isDisabled}/>

                {rule.cc &&
                <SettingsRowValue label={t('notificationRule:label.cc')} value={rule.cc}
                    isDisabled={isDisabled}/>
                }

                {rule.htmlFormat &&
                    <Text value={t('notificationRule:label.htmlFormat')}
                        isDisabled={isDisabled}/>
                }
            </div>
        </div>
    )
}

export default EmailNotificationRuleTablet;
