import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import "./FileExplorer.css";
import HTMLTextInput from "../HTMLTextInput/HTMLTextInput";
import Text from "../Text/Text";
import {useTranslation} from "react-i18next";
import {pathSplitterRegex} from "../../../utilities/constants";
import {
    buildClassName,
    bytesCountToReadableCount,
    getFileExtension,
    getLocaleDateTimeFromUTC,
    getValues
} from "../../../utilities/helperFunctions";
import {Button} from "../Button/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    getRenderedHeightOffset,
    useKeyPressEffect,
    useRenderedItemHeight,
    useSimpleVirtualRendering,
    useUpdateEffect
} from "../../../utilities/hooks";
import LoadingWrapper from "../LoadingWrapper/LoadingWrapper";
import {fileIconModel} from "../../../models/generics/IconModel";
import {MenuDropdown} from "../Dropdown/Dropdown";


function FileExplorer(props) {
    const {t} = useTranslation(['common', 'dataset']);

    const {
        fileMap,
        rootPath,
        path,
        setPath,
        queryPath,
        queryFileSize,

        isLoading,
        isRequired,
        isDisabled,

        height,
        ...attr
    } = props;

    const [scroll, triggerScroll] = useState();
    const [internalPath, setInternalPath] = useState(path);

    const [files, setFiles] = useState([]);
    const [folderPath, setFolderPath] = useState(getParentPath(path) || '');
    const folderPathRef = useRef();

    const containerRef = useRef();
    const scrollContainerRef = useRef();

    const keyToCb = useRef({
        'Tab': 'click',
        'Enter': 'dblclick'
    });
    useKeyPressEffect({containerRef, keyToCb: keyToCb.current});

    const listContainerSelector = '.table-row-group';
    const itemHeightRef = useRenderedItemHeight(containerRef, listContainerSelector, 37.6094);
    const heightOffset = getRenderedHeightOffset(containerRef, listContainerSelector, itemHeightRef.current);
    const virtualRenderRows = useSimpleVirtualRendering({
        containerRef,
        itemHeightRef,
        size: files.length,
        heightOffset
    });


    const isRootPathChild = useCallback(path => {
        if (path && path !== rootPath) {
            const pathSplit = path.split(pathSplitterRegex);
            return rootPath.split(pathSplitterRegex).every((path, index) => pathSplit[index] != null && (!path || path === pathSplit[index]));
        }
        return false;
    }, [rootPath]);

    useEffect(() => {
        folderPathRef.current.value = folderPath;
        let _folderPath = addPathSplitterIfRoot(folderPath.replace(/[\\/]$/, ''));
        setFiles(getValues(fileMap).filter(file => _folderPath === getParentPath(file.path)));
    }, [fileMap, folderPath]);


    useEffect(() => {
        if (path !== internalPath) {
            const file = files.find(file => file.path === internalPath);
            if (file != null && !file.aggregate) {
                setPath(file);
            }
        }
    }, [files, path, internalPath]);

    // Query for parent folder files onMount
    useEffect(() => {
        let _folderPath;
        if (isRootPathChild(internalPath)) {
            _folderPath = getParentPath(internalPath);
        }
        queryFolderPath(_folderPath, {force: true});
    }, []);

    // Query root files on rootPath change
    useUpdateEffect(() => {
        setInternalPath(path);
        queryFolderPath(undefined, {force: true});
    }, [rootPath]);

    useLayoutEffect(() => {
        if (scrollContainerRef.current != null) {
            const selectedFileIndex = files.findIndex(file => file.path && areFilePathsEqual(internalPath, file.path));
            if (selectedFileIndex >= 0) {
                const {height} = scrollContainerRef.current.getBoundingClientRect();
                const itemTopDistance = selectedFileIndex * itemHeightRef.current;

                const distance = itemTopDistance - scrollContainerRef.current.scrollTop;
                if (distance > height || distance < 0) {
                    scrollContainerRef.current.scrollTop = itemTopDistance;
                }
            }
        }
    }, [scroll]);

    function queryFolderPath(pathToQuery, opts={}) {
        // Revert to rootPath if not rootPath child
        if (!isRootPathChild(pathToQuery)) {
            pathToQuery = rootPath;
        }
        setFolderPath(pathToQuery);

        // Clear path when querying folders
        queryPath(pathToQuery, {rethrow: true, ...opts})
            .then(() => {
                triggerScroll(prev => !prev);
            })
            .catch(() => {
                // Revert to parent on fail
                onHistoryPop(pathToQuery, {force: pathToQuery !== rootPath});
            });
    }

    function onHistoryPop(currentFolderPath, opts={}) {
        const {keepPath=true, ..._opts} = opts;
        const newFolderPath = getParentPath(currentFolderPath);

        setInternalPath(keepPath ? currentFolderPath : newFolderPath);
        queryFolderPath(newFolderPath, _opts);
    }

    function onNavigateUp() {
        onHistoryPop(folderPath);
    }

    function onFileClick(event) {
        setInternalPath(event.currentTarget.dataset.value);
    }

    function onFileDoubleClick(event) {
        queryFolderPath(event.currentTarget.dataset.value);
    }

    function onBlur(event) {
        const {value} = event.target;
        setFolderPath(value);
        queryFolderPath(value);
    }

    function onRefreshAll() {
        queryFolderPath(folderPath, {force: true});
    }

    function onKeyUp(event) {
        const {key, target: {value}} = event;
        if (key === 'Enter') {
            setFolderPath(value);
            queryFolderPath(value, {force: true});
        }
    }

    function onMenuOption(event) {
        const {value} = event.currentTarget.dataset;
        switch (value) {
            case 'queryFolderSizes':
                const folders = files.filter(file => file.directory);
                queryFileSize(folders);
                break;
        }
    }

    const menuOptions = [{name: t('dataset:option.queryFolderSizes'), value: 'queryFolderSizes', isDisabled: !files.some(file => file.directory)}];

    const folderPathSplitLength = folderPath.split(pathSplitterRegex).filter(path => path).length;
    const rootPathSplitLength = rootPath.split(pathSplitterRegex).filter(path => path).length;
    const isHistoryBackDisabled = isDisabled || folderPathSplitLength <= rootPathSplitLength;
    const isRefreshDisabled = isDisabled || !folderPath;
    const isMenuDisabled = isRefreshDisabled;

    const containerClassName = buildClassName(
        'file-container',
        isRequired && !path && 'is-required',
        isDisabled && 'is-disabled'
    );

    return (
        <div className="file-explorer flex-expand" {...attr}>

            <div className="file-explorer-header">
                <Button className="file-explorer__button" aria-label={t(`aria:hiddenAssistText.upTo`, [getParentPath(folderPath)])}
                    onClick={onNavigateUp} isImg isDisabled={isHistoryBackDisabled}
                >
                    <FontAwesomeIcon icon={`arrow-up`}/>
                </Button>

                <HTMLTextInput ref={folderPathRef} defaultValue={folderPath} expandContainer
                    onBlur={onBlur} onKeyUp={onKeyUp} isDisabled={isDisabled}/>

                <Button className="file-explorer__button" aria-label={t(`aria:hiddenAssistText.refreshNamed`, [folderPath])}
                    onClick={onRefreshAll} isImg isDisabled={isRefreshDisabled}
                >
                    <FontAwesomeIcon icon={`rotate-right`} rotation={270}/>
                </Button>

                <MenuDropdown aria-label={t('aria:hiddenAssistText.fileExplorerFileOptions')} menuOptions={menuOptions}
                    onOptionClick={onMenuOption} isDisabled={isMenuDisabled}/>
            </div>

            <div className={containerClassName} ref={scrollContainerRef} style={height ? {height} : {}}>
                <div ref={containerRef} tabIndex={0}>
                    <div className="file-table no-border">
                        <div className="table-header-group">
                            <div className="table-header">
                                <Text value={t('common:label.name')} isBold/>
                            </div>

                            <div className="table-header" style={{width: '10rem'}}>
                                <Text value={t('dataset:label.dateModified')} isBold/>
                            </div>

                            <div className="table-header" style={{textAlign: 'end', width: '6rem'}}>
                                <Text value={t('dataset:label.size')} isBold/>
                            </div>
                        </div>

                        <div className="table-row-group" style={{position: 'relative'}}>
                            <LoadingWrapper isLoading={isLoading} ignoreRelativeWrapper>
                                {virtualRenderRows((index, topOffset) => {
                                    const child = files[index];
                                    if (child == null) {
                                        return null;
                                    }

                                    return (
                                        <FileRow key={index} file={child} style={{position: 'relative', top: `${topOffset}px`}}
                                            onClick={onFileClick} onDoubleClick={onFileDoubleClick}
                                            isActive={child.path === internalPath} isDisabled={isDisabled}/>
                                    )
                                })}
                            </LoadingWrapper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function FileRow(props) {
    const {t} = useTranslation(['common']);
    const {file, isActive, onClick, onDoubleClick, isDisabled, ...attr} = props;

    const className = buildClassName(
        'table-row',
        file.aggregate && 'no-hover',
        isActive && !file.aggregate && 'is-active'
    );

    const fileExtension = file.directory ? 'directory' : getFileExtension(file.name, 'empty');
    const fileIcon = fileIconModel.useIcon(fileExtension);

    const readOnly = file.aggregate || isDisabled;

    let sizeLabel;
    if (file.isLoadingSize) {
        sizeLabel = `${t('common:status.LOADING')}...`;
    } else if (isNaN(file.size)) {
        sizeLabel = t('common:label.na');
    } else if (typeof file.size === 'number') {
        sizeLabel = bytesCountToReadableCount(file.size);
    }

    return (
        <div {...attr} className={className} tabIndex={0}
            data-value={file.path} data-directory={file.directory} data-aggregate={file.aggregate}
            onClick={readOnly ? null : onClick} onDoubleClick={(file.directory && !readOnly) ? onDoubleClick : null}
        >
            <div className="table-cell">
                <div style={{display: 'flex', alignItems: 'center', maxWidth: '24rem'}}>
                    <span className="icon is-small" style={{marginRight: '0.5rem', flexShrink: 0}}>
                        <img src={fileIcon} alt={fileExtension}/>
                    </span>
                    <Text value={file.name} isEllipsis/>
                </div>
            </div>

            <div className="table-cell">
                <Text value={getLocaleDateTimeFromUTC(file.lastModified)} isEllipsis/>
            </div>

            <div className="table-cell" style={{textAlign: 'end'}}>
                <Text value={sizeLabel} isEllipsis/>
            </div>
        </div>
    )
}

function areFilePathsEqual(path, otherPath) {
    const otherPathSplit = otherPath.split(pathSplitterRegex);
    return path.split(pathSplitterRegex).every((path, index) => path === otherPathSplit[index]);
}

function addPathSplitterIfRoot(path) {
    if (path.split(pathSplitterRegex).length === 1) {
        return path + '\\';
    }
    return path;
}

// Replace last part of path
function getParentPath(path) {
    if (path) {
        return addPathSplitterIfRoot(path.replace(/[\\/]+[^\\/]*$/, ''));
    }
}

export default FileExplorer;