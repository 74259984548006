import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {CLIENT_UNASSIGNED, details, jobQueueStates} from '../../utilities/constants';
import {objEquals} from '../../utilities/helperFunctions';
import SelectMatterPane from './panes/SelectMatterPane';
import JobSettingsPane from './panes/JobSettingsPane';
import JobConfirmPane from './panes/JobConfirmPane';
import {permissionKeys, popupInfoKeys} from "../../i18next/keys";
import PopupModel from "../../models/scheduler/PopupModel";
import JobModel, {JobSaga} from "../../models/job/JobModel";
import NavigatePane from "../common/NavigatePane/NavigatePane";
import CurrentUserModel from "../../models/user/CurrentUserModel";
import {contextCall} from "../../saga/sagaFunctions";
import SchedulerModel from "../../models/scheduler/SchedulerModel";
import ParameterWorkflowPane from "../guidedJob/panes/ParameterWorkflowPane";
import ParameterModel from "../../models/library/ParameterModel";

function QueueJob() {
    const {t} = useTranslation(['job', 'common']);
    const dispatch = useDispatch();

    const jobQueueForm = useSelector(state => state.componentStates.jobQueueForm);
    const {
        clientId,
        matterId,
        libraryId,
        workflowTemplateId,
        workflowName,
        notes,
        parameters,
        resourcePoolId,
        executionProfileId,
        queueState,

        force,
        didUserChange,
        initialValues,

        isQueueEnabled,
        isLoadingSettings,
        isLoading,
        isDisabled
    } = jobQueueForm;

    const jobSubmissionPermissions = useSelector(state =>
        JobModel.getSubmitPermissions(state, {matterId, workflowTemplateId, resourcePoolId, executionProfileId, parameters})
    );

    const hasLoaded = useSelector(state => state.hasLoaded);
    const isLoadingClientMatters = !(hasLoaded[details.CLIENTS] && hasLoaded[clientId]);
    const isLoadingLibraryWorkflows = !(hasLoaded[details.LIBRARIES] && hasLoaded[libraryId]);

    const forbiddenParameterTypes = [ParameterModel.Type.LEGAL_HOLD, ParameterModel.Type.PURVIEW_SERVICE, ParameterModel.Type.VAULT_SERVICE];

    useEffect(() => {
        return () => dispatch(CurrentUserModel.actionCreators.pollActivity(CurrentUserModel.activities.JOB_MONITORING));
    }, []);

    const updateState = useCallback(updates => {
        dispatch(JobModel.componentActionCreators.updateForm(updates));
        dispatch(JobModel.actionCreators.shouldEnableQueueJob());
    }, []);

    const preparePopulateDefaultJobSettings = useCallback(() => dispatch(JobModel.actionCreators.prepareGetDefaultJobSettingsSaga()), []);
    const prepareGetOperationsAndParameters = useCallback(() => dispatch(JobModel.actionCreators.prepareGetOperationsAndParametersSaga()), []);
    const getOperationsAndParameters = useCallback(() => dispatch(JobModel.actionCreators.getOperationsAndParameters()), []);
    const encryptProtectedParameters = useCallback(() => dispatch(JobModel.actionCreators.encryptProtectedParameters()), []);
    const decryptProtectedParameters = useCallback(() => dispatch(JobModel.actionCreators.decryptProtectedParameters()), []);
    const loadTsvParameters = useCallback(tsvText => dispatch(JobModel.actionCreators.loadTsvParameters(tsvText)), []);
    const resetParameters = useCallback(() => dispatch(JobModel.actionCreators.resetParameters()), []);
    const refreshParameters = useCallback(() => {
        const refreshEffect = contextCall(JobSaga, 'updateJobParametersAllowedValues');
        dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(refreshEffect));
    }, []);
    const dispatchParameterUpdate = useCallback((...args) => {
        dispatch(JobModel.actionCreators.updateParameter(...args));
    }, []);
    const updateExecutionProfile = useCallback((value, workflowXml, workflowTemplateId) => {
        dispatch(JobModel.actionCreators.updateExecutionProfile(null, value, workflowXml, workflowTemplateId));
    }, []);
    const getMissingRequiredProfiles = useCallback((executionProfileId, workflowXml, workflowTemplateId) => {
        dispatch(JobModel.actionCreators.getMissingRequiredProfiles(executionProfileId, workflowXml, workflowTemplateId));
    }, [])

    const onClose = useCallback(() => {
        const close = () => dispatch(JobModel.actionCreators.hideForm());
        const warnOnClose = () => dispatch(PopupModel.actionCreators.showWarning({
            info: {
                key: popupInfoKeys.DISCARD_QUEUE_JOB
            },
            buttons: [{
                title: t('common:option.discard'),
                onClick: close
            }]
        }));

        if (clientId != null) {
            warnOnClose();
        } else {
            close();
        }
    }, [dispatch, clientId]);

    function onQueue() {
        const submitJob = () => dispatch(JobModel.actionCreators.submitForm());
        const finalValues = {
            clientId,
            matterId,
            libraryId,
            workflowTemplateId,
            workflowName,
            notes,
            parameters
        };

        if (didUserChange.jobName || !objEquals(initialValues, finalValues)) {
            submitJob();
        } else {
            dispatch(PopupModel.actionCreators.showWarning({
                info: {
                    key: popupInfoKeys.DUPLICATE_JOB_VALUES,
                    values: {
                        action: t('job:option.queue_lower')
                    }
                },
                buttons: [{
                    title: t('job:option.queue'),
                    onClick: submitJob
                }]
            }));
        }
    }

    const canNavigateToWorkflowPane = !isLoading && ((clientId === CLIENT_UNASSIGNED) || (matterId != null && !isLoadingClientMatters));
    const canNavigateToSettingsPane = workflowTemplateId != null && !isLoadingLibraryWorkflows;
    const canQueue = isQueueEnabled && !(isLoadingSettings || isLoading || isDisabled);
    const queueText = (jobSubmissionPermissions.includes(permissionKeys.SUBMIT_JOB) && queueState === jobQueueStates.BACKLOG) ? t('job:option.submit') : t('job:option.stage');

    const panes = [{
        title: t('job:label.queueMatter'), backTitle: t('common:option.cancel'), onBackClick: onClose, isNextEnabled: canNavigateToWorkflowPane,
        component: <SelectMatterPane jobState={jobQueueForm} updateState={updateState} preparePopulateJobSettings={preparePopulateDefaultJobSettings}/>
    }, {
        title: t('job:label.queueWorkflow'), onNextClick: getOperationsAndParameters, isNextEnabled: canNavigateToSettingsPane,
        component: <ParameterWorkflowPane jobState={jobQueueForm} updateState={updateState} preparePopulateJobSettings={prepareGetOperationsAndParameters}
                                          forbiddenParameterTypes={forbiddenParameterTypes}/>
    }, {
        title: t('job:label.queueSettings'), onNextClick: encryptProtectedParameters, onBackClick: encryptProtectedParameters, isNextEnabled: canQueue,
        component: <JobSettingsPane jobState={jobQueueForm} updateState={updateState} decryptProtectedParameters={decryptProtectedParameters}
            loadTsvParameters={loadTsvParameters} refreshParameters={refreshParameters} updateExecutionProfile={updateExecutionProfile}
            resetParameters={resetParameters} dispatchParameterUpdate={dispatchParameterUpdate} getMissingRequiredProfiles={getMissingRequiredProfiles}/>
    }, {
        title: t('job:label.queueConfirm'), nextTitle: queueText, onNextClick: onQueue, isNextEnabled: canQueue,
        component: <JobConfirmPane jobState={jobQueueForm}/>
    }];

    return (
        <NavigatePane id={'queueJobForm'} panes={panes} onClose={onClose} isLoading={isLoading}
            isDisabled={isDisabled} force={force} closeButtonAriaLabel={t('job:option.closeQueueJobForm')}/>
    )
}

export default QueueJob;
