import React from "react";
import {connect} from "react-redux";
import NotificationRuleModel from "../../../models/settings/NotificationRuleModel";
import {useTranslation} from "react-i18next";
import {StatusNameDescriptionCell, TableRow} from "../../common/CustomTable/CustomTable";
import Text from "../../common/Text/Text";
import {permissionKeys} from "../../../i18next/keys";
import LimitedTableRow from "../../limited/LimitedTableRow";

function WebhookNotificationRuleRow(props) {
    const {t} = useTranslation(['notificationRule', 'job']);
    const {isActive, showNotificationRuleTablet, id, name, description, webhookPlatform, url, triggers, status, userPermissions} = props;

    const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);
    const canModify = userPermissions.includes(permissionKeys.MODIFY);
    if (isViewLimited && !canModify) {
        return (
            <LimitedTableRow id={id} isActive={isActive} onClick={showNotificationRuleTablet} cellCount={4}
                             nameComponent={<StatusNameDescriptionCell
                                 status={status} name={name} description={description}
                             />}
            />
        )
    }

    return (
        <TableRow value={id} isActive={isActive} onClick={showNotificationRuleTablet}>
            <StatusNameDescriptionCell style={{maxWidth: '12rem'}}
                status={status}
                name={name}
                description={description}
            />

            <div className="table-cell max-width-12">
                <label
                    className="label is-wordwrap">{t(`notificationRule:webhookPlatform.${!!webhookPlatform ? webhookPlatform : "null"}`)}</label>
            </div>

            <div className="table-cell max-width-12">
                <div style={{display: 'flex', height: '100%'}}>
                    <div style={{flex: 1, minWidth: 0}}>
                        <Text isEllipsis value={url}/>
                    </div>
                </div>
            </div>


            <div className="table-cell">
                {triggers.map((trigger, index) =>
                    <label className="label is-ellipsis" key={index}>{t(`job:trigger.${trigger}`)}</label>)
                }
            </div>
        </TableRow>
    )
}

const mapStateToProps = (state, ownProps) => {
    const {notificationRuleDetailsMap, componentStates: {notificationRuleDisplay}} = state;

    return {
        isActive: notificationRuleDisplay.notificationRuleId === ownProps.resourcePoolId,
        ...notificationRuleDetailsMap.get(ownProps.notificationRuleId)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showNotificationRuleTablet: event => dispatch(NotificationRuleModel.actionCreators.showTablet(event.currentTarget.dataset.value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebhookNotificationRuleRow);