import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {getServerIds} from '../../reselect/selectors';
import ServerForm from './ServerForm';
import ServerTable from './ServerTable';
import ServerTablet from './ServerTablet';
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {details} from "../../utilities/constants";
import ServerModel from "../../models/settings/ServerModel";
import {MainPageContent, MainPageHeader} from "../app/pages/MainPage";

class ServerDisplay extends Component {

    componentDidMount() {
        this.props.setDisplayActive();
    }

    componentWillUnmount() {
        this.props.setDisplayInactive();
    }

    render() {

        const {
            t,
            isLoading,
            serverId,
            serverIds,
            isServerFormActive,
            ...attr
        } = this.props;

        return (
            <MainPageContent {...attr}
                title={
                    <MainPageHeader text={t('server:page.title')}
                        description={t('server:page.description')}/>
                }
                list={
                    <LoadingWrapper isLoading={isLoading}>
                        <ServerTable />
                    </LoadingWrapper>
                }
                children={
                    (serverIds.includes(serverId) && <ServerTablet />)
                    ||
                    (isServerFormActive && <ServerForm />)
                }
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.componentStates.serverDisplay,
        serverIds: getServerIds(state),
        isLoading: !state.hasLoaded[details.SERVERS]
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setDisplayActive: () => dispatch(ServerModel.componentActionCreators.setDisplayActive()),
        setDisplayInactive: () => dispatch(ServerModel.componentActionCreators.setDisplayInactive())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['server'])(ServerDisplay));