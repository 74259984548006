import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import {selectCanSearchWorkflows, selectFilteredWorkflowTemplateIds} from "../library/selector";
import WorkflowTemplateModel from "../../models/library/WorkflowTemplateModel";
import {AddButtonHeader} from "../common/Button/Button";
import SearchBar from "../common/SearchBar/SearchBar";
import {isNotEmptyNorFalsy} from "../../utilities/helperFunctions";
import WorkflowTemplateTablet from "./WorkflowTemplateTablet";
import {permissionKeys} from "../../i18next/keys";
import {TableEnableToggleCell, TableRow} from "../common/CustomTable/CustomTable";
import {workflowIconModel} from "../../models/library/WorkflowTemplateIconModel";

function WorkflowTemplateTable(props) {
    const {t} = useTranslation(['library', 'workflow', 'common', 'aria']);
    const dispatch = useDispatch();

    const {
        libraryId,
        workflowTemplateId,
        canAddWorkflow
    } = props;

    const [searchText, setSearchText] = useState('');
    const canSearchWorkflows = useSelector(state => selectCanSearchWorkflows(state, {libraryId}));
    const workflowTemplateIds = useSelector(state => selectFilteredWorkflowTemplateIds(state, {libraryId, searchText}));

    function showTablet(event) {
        const {value} = event.currentTarget.dataset;
        dispatch(WorkflowTemplateModel.actionCreators.showTablet(value));
    }

    function toggleEnable(event) {
        const {value} = event.currentTarget.dataset;
        dispatch(WorkflowTemplateModel.actionCreators.toggleEnabled(value));
    }

    const isNotEmpty = isNotEmptyNorFalsy(workflowTemplateIds);
    if (!canAddWorkflow && !isNotEmpty) {
        return null;
    }

    return (
        <div className="display-item">
            <div className="flex-center space-between">
                <AddButtonHeader text={t('workflow:label.name')} aria-label={t('aria:hiddenAssistText.addWorkflowTemplate')}
                    canModify={canAddWorkflow} onClick={() => dispatch(WorkflowTemplateModel.actionCreators.showForm())}/>

                {canSearchWorkflows &&
                    <SearchBar id="workflowSearchBar" value={searchText} onChange={event => setSearchText(event.target.value)}
                        style={{maxWidth: '15rem', margin: '0.5rem 0'}}
                    />
                }
            </div>

            {isNotEmpty &&
                <div className="display-table">
                    <div className="table-header-group">
                        <div className="table-header" style={{width: '4rem'}}/>
                        <div className="table-header"/>
                    </div>

                    <div className="table-row-group">
                        {workflowTemplateIds.map(id =>
                            <WorkflowTemplateRow key={id} id={id} isActive={id === workflowTemplateId}
                                onSelect={showTablet} toggleEnable={toggleEnable}
                            />
                        )}
                    </div>
                </div>
            }

            {workflowTemplateIds.includes(workflowTemplateId) &&
                <WorkflowTemplateTablet workflowTemplateId={workflowTemplateId}/>
            }
        </div>
    )
}

function WorkflowTemplateRow(props) {

    const {
        isActive,
        id,
        onSelect,
        toggleEnable
    } = props;

    const {
        name,
        description,
        enabled,
        userPermissions
    } = useSelector(state => state.workflowTemplateDetailsMap.get(id));


    const canModify = userPermissions.includes(permissionKeys.MODIFY);

    const icon = workflowIconModel.useWorkflowIcon(id);

    return (
        <TableRow value={id} isActive={isActive} onClick={onSelect}>
            <TableEnableToggleCell ariaLabelKey={'Workflow'} displayName={name} data-value={id}
                checked={enabled} onClick={toggleEnable} isReadOnly={!canModify}/>

            <div className="table-cell">
                {icon ? (
                    <div className={"flex-center"}>
                        <div>
                            <span className="icon is-large spacing-right" >
                                <img src={icon} alt={""}/>
                            </span>
                        </div>
                        <div>
                            <label className="label is-wordwrap is-bold">
                                {name}
                            </label>
                            <label className="label is-wordwrap">
                                {description}
                            </label>
                        </div>
                    </div>
                    ) : (
                        <>
                            <label className="label is-wordwrap is-bold">
                                {name}
                            </label>
                            <label className="label is-wordwrap">
                                {description}
                            </label>
                        </>
                    )
                }
            </div>
        </TableRow>
    )
}

export default WorkflowTemplateTable;
