import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {StatusNameDescriptionCell, TableLabelHeader, TableRow} from "../common/CustomTable/CustomTable";
import {getValues} from "../../utilities/helperFunctions";
import React from "react";
import FileLibraryModel from "../../models/filelibrary/FileLibraryModel";
import {statusKeys} from "../../i18next/keys";

function FileLibraryTable(props) {
    const {t} = useTranslation(['fileLibrary', 'common'])
    const dispatch = useDispatch()

    const {
        fileLibraries
    } = props

    function showTablet(event) {
        const {value} = event.currentTarget.dataset
        dispatch(FileLibraryModel.actionCreators.showDisplay(value))
    }


    return (
        <div className="display-table">
            <div className="table-header-group">

                <TableLabelHeader
                    label={t('common:label.name')}
                />
            </div>


            <div className="table-row-group">
                {getValues(fileLibraries).map(library =>

                    <FileLibraryRow key={library.id} {...library} showTablet={showTablet}/>
                )}
            </div>
        </div>
    )
}

function FileLibraryRow(props) {

    const {
        id,
        name,
        description,
        isActive
    } = props

    return (
        <TableRow value={id} isActive={isActive} onClick={props.showTablet}>
            <StatusNameDescriptionCell status={statusKeys.UNKNOWN} name={name} description={description}/>
        </TableRow>
    )
}

export default FileLibraryTable