import React, {useRef, useState} from "react";
import "./UserNoticeView.css";
import {
    buildClassName,
    getLocaleDateTimeFromUTC,
    getObjectText,
    getValues,
    stringToBool
} from "../../utilities/helperFunctions";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useKeyPressEffect} from "../../utilities/hooks";
import UserNoticeModel from "../../models/notice/UserNoticeModel";
import EditModel from "../../models/scheduler/EditModel";
import UserNoticeDisplay from "./UserNoticeDisplay";
import SearchBar from "../common/SearchBar/SearchBar";
import Text from "../common/Text/Text";
import {icon, statusIcon} from "../../utilities/iconResolver";
import Checkbox from "../common/Checkbox/Checkbox";
import {permissionKeys} from "../../i18next/keys";
import {contextCall} from "../../saga/sagaFunctions";
import {NoticeCommentSaga} from "../../models/notice/NoticeCommentModel";
import {all, put} from "redux-saga/effects";


function selectLegalHoldUserNoticeIds (state, {legalHoldId, searchText, showDisabled}) {
    return getValues(state.userNoticeDetailsMap)
        .filter(userNotice => showDisabled || (userNotice.enabled && userNotice.noticeEnabled))
        .filter(userNotice => userNotice.legalHoldId === legalHoldId && userNotice.userPermissions.includes(permissionKeys.RESPOND))
        .filter(userNotice => !searchText || getObjectText(userNotice).includes(searchText.toLowerCase()))
        .map(userNotice => userNotice.id);
}

function UserNoticeView(props) {
    const dispatch = useDispatch();
    const {t} = useTranslation(['notice']);

    const {
        legalHoldId
    } = props;

    const [searchText, setSearchText] = useState('');
    const [showDisabled, setShowDisabled] = useState(true);

    const userNoticeView = useSelector(state => state.componentStates.userNoticeView);

    const {
        userNoticeId,
        isUploadActive
    } = userNoticeView;

    const isDisabled = props.isDisabled || userNoticeView.isDisabled
    const isViewDisabled = isDisabled || isUploadActive;

    const userNoticeIds = useSelector(state => selectLegalHoldUserNoticeIds(state, {legalHoldId, searchText, showDisabled}));
    const selectedUserNotice = useSelector(state => state.userNoticeDetailsMap.get(userNoticeId));


    function onUserNoticeSelect(event) {
        const {value} = event.currentTarget.dataset;

        dispatch(EditModel.actionCreators.callEditBreakingFunc(function* () {
            const updateEffect = all([
                put(UserNoticeModel.componentActionCreators.resetView()),
                put(UserNoticeModel.componentActionCreators.updateView({userNoticeId: value}))
            ]);

            yield contextCall(NoticeCommentSaga, 'callCommentFormDiscardingEffect', updateEffect);
        }));
    }


    return (
        <section className="table-view__border-container">
            <header className="tablet-view__header"/>

            <section className="tablet-view user-notice">
                <aside className="tablet-view__panels">
                    <section className="tablet-view__panels__add">
                        <SearchBar value={searchText} onChange={e => setSearchText(e.target.value)}
                            isDisabled={isViewDisabled}/>

                        <Checkbox label={t('notice:message.hideDisabled')} style={{marginTop: '0.5rem'}}
                            checked={!showDisabled} onClick={event => setShowDisabled(!stringToBool(event.target.checked))} isDisabled={isViewDisabled}/>
                    </section>

                    <section className="tablet-view__list">
                        {userNoticeIds.map(id =>

                            <UserNoticePanel key={id} userNoticeId={id} isActive={id === userNoticeId}
                                onClick={onUserNoticeSelect} isDisabled={isViewDisabled}/>
                        )}
                    </section>
                </aside>

                {selectedUserNotice != null && userNoticeIds.includes(userNoticeId) &&
                <section className="tablet-view__display">
                    <section className="tablet-view__display__info">
                        <UserNoticeDisplay userNotice={selectedUserNotice} showSubject
                            isDisabled={isDisabled}/>
                    </section>
                </section>
                }
            </section>

            <footer className="tablet-view__footer"/>
        </section>
    )
}


function UserNoticePanel(props) {
    const {t} = useTranslation(['aria']);
    const {
        userNoticeId,
        onClick,
        isActive,
        isDisabled
    } = props;

    const userNotice = useSelector(state => state.userNoticeDetailsMap.get(userNoticeId));
    const {
        noticeEnabled,
        type,
        subject,
        sentDate
    } = userNotice;


    const containerRef = useRef();
    const keyToCb = useRef({
        'Enter': 'click'
    });
    useKeyPressEffect({containerRef, keyToCb: keyToCb.current});

    const isNotification = !userNotice.getViewedAndResponded() && noticeEnabled;
    const className = buildClassName(
        'tablet-view__panel',
        'selectPanelSimple',
        isNotification && 'is-notification',
        isActive && 'is-active',
        isDisabled && 'is-disabled'
    );

    return (
        <article className={className} data-value={userNoticeId}
            ref={containerRef} onClick={isDisabled ? null : onClick} tabIndex={isDisabled ? -1 : 0}
        >
            <Text value={subject} isBold={isActive} isEllipsis isDisabled={isDisabled}/>

            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '0.25rem'}}>
                <Text value={getLocaleDateTimeFromUTC(sentDate)} isBold={isActive} isDisabled={isDisabled}/>

                <span className={'icon'}>
                    <img src={icon(`noticeType${type}`)} alt={t(`aria:hiddenAssistText.${type}NoticeIcon`)}/>
                </span>
            </div>
        </article>
    )
}

export function NoticeStateIcons(props) {
    const {t} = useTranslation(['aria']);
    const {
        userNotice,
        isVertical,
        isDisabled,

        ...attr
    } = props;
    const states = userNotice.getStates();

    let warningState = 'statusWarning';
    if (states.escalated) {
        warningState = 'statusError';
    } else if (states.reminded && states.responded) {
        warningState = 'statusWarningResolved';
    }

    const _className = buildClassName(
        'notice-status-icons',
        isVertical && 'is-vertical',
        isDisabled && 'is-disabled'
    );

    const requiredWarning = states.reminded || states.escalated;
    const requiresResponse = states.responded != null && !states.responded;
    const noticeFinished = states.viewed && !requiresResponse;

    return (
        <div className={_className} {...attr}>

            {requiredWarning &&
            <span className="icon">
                <img src={statusIcon(warningState)} alt={t(`aria:hiddenAssistText.${warningState}Icon`)}/>
            </span>
            }

            {requiresResponse &&
            <span className="icon">
                <img src={icon('noticeResponseRequired')} alt={t(`aria:hiddenAssistText.noticeResponseRequiredIcon`)}/>
            </span>
            }

            {noticeFinished &&
            <span className="icon">
                <img src={statusIcon('statusFinished')} alt={t(`aria:hiddenAssistText.statusFinishedIcon`)}/>
            </span>
            }
        </div>
    )
}


export default UserNoticeView;
