import SettingModel from "../generics/SettingModel";


class NoticeInfoModel extends SettingModel.Complex {

    static nom = 'NoticeInfoModel';
    static actions = NoticeInfoModel.buildActions('NOTICE_INFO');
    static actionCreators = NoticeInfoModel.buildActionCreators(NoticeInfoModel.actions);
    static reducer = NoticeInfoModel.buildReducer(NoticeInfoModel.actions);

    constructor(model = {}) {
        super(model);
    }
}

export default NoticeInfoModel;
