//Translation keys which are used/saved as values across the App
import {getKeys} from "../utilities/helperFunctions";

export const popupInfoKeys = {
    TEMPLATE: 'template',
    NOT_SCHEDULER_SERVER: 'notSchedulerServer',
    NO_APPLICATION_FEATURES: 'noApplicationFeatures',
    DISCONNECTED: 'disconnected',
    IDLE_WARNING: 'idleWarning',
    LOGOUT: 'logout',
    MISSING_MODIFY_PRIVILEGE: 'missingModifyPrivilege',
    DISCARD_QUEUE_JOB: 'discardQueueJob',
    DISCARD_SCHEDULE_JOB: 'discardScheduleJob',
    DISCARD_COLLECTION: 'discardCollection',
    DISCARD_COLLECTION_TEMPLATE: 'discardCollectionTemplate',
    DISCARD_LEGAL_HOLD: 'discardLegalHold',
    DISCARD_NOTICE_TEMPLATE: 'discardNoticeTemplate',
    DISCARD_NOTICE: 'discardNotice',
    DISCARD_COMMENT: 'discardComment',
    DISCARD_FORM: 'discardForm',
    DUPLICATE_JOB_VALUES: 'defaultJobValues',
    DUPLICATE_VALUES: 'duplicateValues',
    DELETE_ITEM: 'deleteItem',
    SAFE_CLOSE: 'safeClose',
    VALUES_CHANGED_WHILE_EDIT: 'valuesChangedWhileEdit',
    PAUSE_JOB: 'pauseJob',
    SKIP_OPERATION: 'skipOperation',
    STOP_JOB: 'stopJob',
    CANCEL_JOB: 'cancelJob',
    ABORT_JOB: 'abortJob',
    RESTART_SERVER: 'restartServer',
    RESET_CATEGORY_TO_DEFAULT: 'resetCategoryToDefault',
    SET_CATEGORY_AS_DEFAULT: 'setCategoryAsDefault',
    INSTANCE_IDLE_ACTION_TERMINATE_WARNING: 'instanceIdleActionTerminateWarning',
    DELETE_DATASET_FILES: 'deleteDatasetFiles',
    DELETE_DATASET_UPLOAD_INFOS: 'deleteDatasetUploadInfos',
    FINALIZE_DATASET: 'finalizeDataset',
    HIDE_DATASET: 'hideDataset',
    SHOW_DATASET: 'showDataset',
    ARCHIVE_DATASET: 'archiveDataset',
    UNARCHIVE_DATASET: 'unarchiveDataset',
    EXPIRE_DATASET: 'expireDataset',
    CANCEL_DATASET_UPLOAD: 'cancelDatasetUpload',
    DATASET_AUTO_EXPIRE_WARNING: 'datasetAutoExpireWarning',
    DELETE_DATASET_METADATA_COLUMN_WARNING: 'deleteDatasetMetadataColumnWarning',
    UPLOAD_UTILIZATION_SUCCESS: 'uploadUtilizationSuccess',
    USER_NOTICE_RESPONSE_SUBMIT_WARNING: 'userNoticeResponseSubmitWarning',
    LEGAL_HOLD_ACTIVE_CONFIG_CHANGE_WARNING: 'legalHoldActiveNoticeConfigChangeWarning',
};

export const jobPageViewKeys = {
    QUEUE: 'JOB_QUEUE',
    PURVIEW: 'JOB_PURVIEW',
    VAULT: 'JOB_VAULT',
    SCHEDULE: 'JOB_SCHEDULE',
    ARCHIVE: 'JOB_ARCHIVE'
};

export const legalHoldPageViewKeys = {
    OVERVIEW: 'LEGAL_HOLD_OVERVIEW',
    MATTERS: 'LEGAL_HOLD_MATTERS',
    NOTICES: 'LEGAL_HOLD_NOTICES'
}

export const statusKeys = {
    NOT_INITIALIZED: 'NOT_INITIALIZED',
    INITIALIZING: 'INITIALIZING',
    INITIALIZED: 'INITIALIZED',
    STANDBY: 'STANDBY',
    PENDING: 'PENDING',
    RUNNING: 'RUNNING',
    FINISHED: 'FINISHED',
    ERROR: 'ERROR',
    WARNING: 'WARNING',
    OK: 'OK',
    INFO: 'INFO',
    UNKNOWN: 'UNKNOWN'
};

export const maintenanceStatusKeys = {
    NONE: 'NONE',
    ENTERING_MAINTENANCE: 'ENTERING_MAINTENANCE',
    UNDER_MAINTENANCE: 'UNDER_MAINTENANCE'
};

export const executionStateKeys = {
    NOT_STARTED: 'NOT_STARTED',
    PENDING: 'PENDING',
    RUNNING: 'RUNNING',
    PAUSING: 'PAUSING',
    PAUSED: 'PAUSED',
    STOPPING: 'STOPPING',
    DISCONNECTED: 'DISCONNECTED',
    CANCELLED: 'CANCELLED',
    STOPPED: 'STOPPED',
    WARNING: 'WARNING',
    ERROR: 'ERROR',
    SOFT_ERROR: 'SOFT_ERROR',
    FINISHED: 'FINISHED'
};

export const stateFilterKeys = {
    STAGING: 'STAGING',
    BACKLOG: 'BACKLOG',
    RUNNING: 'RUNNING',
    FINISHED: 'FINISHED',
    STOPPED: 'STOPPED',
    CANCELLED: 'CANCELLED',
    WARNING: 'WARNING',
    SOFT_ERROR: 'SOFT_ERROR',
    ERROR: 'ERROR',
    COMMENTS: 'COMMENTS',
    UNREAD_COMMENTS: 'UNREAD_COMMENTS'
};

export const noticeStateFilterKeys = {
    viewed: 'VIEWED',
    notViewed: 'NOT_VIEWED',
    responded: 'RESPONDED',
    notResponded: 'NOT_RESPONDED',
    escalated: 'ESCALATED',
    notEscalated: 'NOT_ESCALATED'
};

export const statusStateFilterKeys = {
    // UNKNOWN: 'UNKNOWN',
    // INFO: 'INFO',
    OK: 'OK',
    WARNING: 'WARNING',
    ERROR: 'ERROR'
};

export const priorityKeys = {
    HIGHEST: "HIGHEST",
    HIGH: "HIGH",
    MEDIUM: "MEDIUM",
    LOW: "LOW",
    LOWEST: "LOWEST"
};

export const executionModeKeys = {
    AUTOMATE_NUIX: "AUTOMATE_NUIX",
    AUTOMATE_NATIVE: "AUTOMATE_NATIVE"
};

export const diagnosticLevelKeys = {
    NONE: "NONE",
    REQUIRED: "REQUIRED",
    ENHANCED: "ENHANCED",
    OPTIONAL: "OPTIONAL"
};


export const settingsDisplayKeys = {
    RELATIVITY_PROXY: 'RELATIVITY_PROXY',
    AUTOMATE_LICENCE: 'AUTOMATE_LICENCE',
    USER_SERVICES: 'USER_SERVICES',
    NUIX_LICENCE_SOURCES: 'NUIX_LICENCE_SOURCES',
    SERVERS: 'SERVERS',
    ENGINES: 'ENGINES',
    RESOURCE_POOLS: 'RESOURCE_POOLS',
    FILE_LIBRARIES: 'FILE_LIBRARIES',
    EXECUTION_PROFILES: 'EXECUTION_PROFILES',
    CLIENT_POOLS: 'CLIENT_POOLS',
    THIRD_PARTY_SERVICES: 'THIRD_PARTY_SERVICES',
    NOTIFICATION_RULES: 'NOTIFICATION_RULES',
    NOTICE_TEMPLATES: 'NOTICE_TEMPLATES',
    DATA_REPOSITORIES: 'DATA_REPOSITORIES',
    UPLOAD_MONITOR: 'UPLOAD_MONITOR',
    POLICIES: 'POLICIES',
    API_KEYS: 'API_KEYS',
    WEBHOOKS: 'WEBHOOKS',
    USER_SETTINGS: 'USER_SETTINGS',
    RESOURCES: 'RESOURCES',
    SYSTEM_RESOURCES: 'SYSTEM_RESOURCES'
};

export const logLevelKeys = {
    EXECUTION: 'EXECUTION',
    INFO: 'INFO',
    LINK: 'LINK',
    WARNING: 'WARNING',
    TRANSIENT_WARNING_TRIGGERED: 'TRANSIENT_WARNING_TRIGGERED',
    TRANSIENT_WARNING_RESOLVED: 'TRANSIENT_WARNING_RESOLVED',
    NON_CRITICAL_ERROR: 'NON_CRITICAL_ERROR',
    ERROR: 'ERROR'
}

export const logLevelStatusIconKeys = {
    EXECUTION: null,
    INFO: 'statusInfo',
    WARNING: 'statusWarning',
    TRANSIENT_WARNING_TRIGGERED: 'statusWarning',
    TRANSIENT_WARNING_RESOLVED: 'statusFinished',
    NON_CRITICAL_ERROR: 'statusError',
    SOFT_ERROR: 'statusSoftError',
    ERROR: 'statusError',
    LINK: 'statusLink'
}

export const localeKeys = {
    BROWSER_DEFAULT: null,
    AR_AE: 'ar-AE',
    DA_DK: 'da-DK',
    DE_DE: 'de-DE',
    EN_US: 'en-US',
    ES_419: 'es-419',
    FR_CA: 'fr-CA',
    HE_IL: 'he-IL',
    JA_JP: 'ja-JP',
    KO_KR: 'ko-KR',
    NL_NL: 'nl-NL',
    PT_BR: 'pt-BR',
    ZH_CN: 'zh-CN'
};

export const jobCardOptionKeys = {
    JOB_NAME: 'jobName',
    JOB_NOTES: 'notes',
    CLIENT_NAME: 'clientName',
    MATTER_NAME: 'matterName',
    LIBRARY_NAME: 'libraryName',
    WORKFLOW_NAME: 'workflowName',
    PARAMETER: 'parameter',
    ENGINE_NAME: 'engineName',
    EXECUTION_PROFILE_NAME: 'executionProfileName',
    RESOURCE_POOL_NAME: 'resourcePoolName',
    STARTED_DATE: 'startedDate',
    FINISHED_DATE: 'finishedDate',
    SUBMITTED_DATE: 'submittedDate',
    SUBMITTED_BY_ICON: 'submittedByIcon',
    SUBMITTED_BY_TEXT: 'submittedByText',
    PRIORITY_TEXT: 'priorityText',
    STATUS_TEXT: 'statusText',
    STATUS_ICONS: 'statusIcons',
    LAST_USER_COMMENT: 'lastUserComment',
    CURRENT_OPERATION_PROCESSING_SPEED_TEXT: 'currentOperationProcessingSpeedText'
};

export const ldapSearchScopeKeys = {
    BASE: '0',
    ONE: '1',
    SUB: '2',
    SUBORDINATES: '3'
};

export const loginLinkScopeKeys = {
    // ALL: 'ALL',
    LEGAL_HOLD: 'LEGAL_HOLD'
};

export const scheduleTriggerTypeKeys = {
    ON_SCHEDULE: 'onSchedule',
    ON_JOB_EVENT: 'onJobEvent',
    ON_DATASET_EVENT: 'onDatasetEvent',
    ON_LEGAL_HOLD_EVENT: 'onLegalHoldEvent',
    ON_WEBHOOK_TRIGGER: 'onWebhookTrigger'
};

export const scheduleFrequencyKeys = {
    ONE_TIME: 'ONE_TIME',
    HOURLY: 'HOURLY',
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY'
};

export const recurOnDayKeys = {
    SUNDAY: 'SUNDAY',
    MONDAY: 'MONDAY',
    TUESDAY: 'TUESDAY',
    WEDNESDAY: 'WEDNESDAY',
    THURSDAY: 'THURSDAY',
    FRIDAY: 'FRIDAY',
    SATURDAY: 'SATURDAY'
};

export const jobSubmissionMechanismKeys = {
    QUEUED: 'QUEUED',
    SCHEDULED: 'SCHEDULED'
};

export const positionKeys = {
    NOT_VISIBLE: 'NOT_VISIBLE',
    TOP_LEFT: 'TOP_LEFT',
    TOP_RIGHT: 'TOP_RIGHT',
    MIDDLE: 'MIDDLE',
    BOTTOM_LEFT: 'BOTTOM_LEFT',
    BOTTOM_RIGHT: 'BOTTOM_RIGHT'
};

export const sizeKeys = {
    SMALL: 'SMALL',
    MEDIUM: 'MEDIUM',
    LARGE: 'LARGE'
};

export const jobSortKeys = {
    BACKLOG: 'BACKLOG',
    RUNNING: 'RUNNING',
    FINISHED: 'FINISHED'
};

export const sortOrderKeys = {
    NORMAL: 'NORMAL',
    REVERSED: 'REVERSED'
};

export const sortFieldKeys = {
    SUBMISSION_DATE: 'SUBMISSION_DATE',
    CHANGED_DATE: 'CHANGED_DATE',
    PRIORITY_SUBMISSION_DATE: 'PRIORITY_SUBMISSION_DATE',
    PRIORITY_CHANGED_DATE: 'PRIORITY_CHANGED_DATE'
};

export const awsIdTypeKeys = {
    LAUNCH_TEMPLATE_ID: 'LAUNCH_TEMPLATE_ID',
    INSTANCE_IDS: 'INSTANCE_IDS',
    TAGS: 'TAGS'
};

export const instanceIdleActionKeys = {
    STOP: 'STOP',
    TERMINATE: 'TERMINATE',
    NONE: 'NONE'
};

export const webhookPlatformKeys = {
    MICROSOFT_TEAMS: 'MICROSOFT_TEAMS',
    SLACK: 'SLACK',
    DISCORD: 'DISCORD'
};

export const jobEventTriggerKeys = {
    JOB_QUEUED: 'JOB_QUEUED',
    JOB_MODIFIED: 'JOB_MODIFIED',
    JOB_STARTED: 'JOB_STARTED',
    JOB_PAUSED: 'JOB_PAUSED',
    JOB_INFO: "JOB_INFO",
    JOB_WARNING: "JOB_WARNING",
    JOB_WARNING_RESOLVED: "JOB_WARNING_RESOLVED",
    JOB_STOPPED: 'JOB_STOPPED',
    JOB_CANCELED: 'JOB_CANCELED',
    JOB_ERROR: 'JOB_ERROR',
    JOB_SOFT_ERROR: 'JOB_SOFT_ERROR',
    JOB_FINISHED_SUCCESSFULLY: 'JOB_FINISHED_SUCCESSFULLY',
    JOB_FINISHED_WITH_WARNINGS: 'JOB_FINISHED_WITH_WARNINGS',
    JOB_FINISHED_WITH_SOFT_ERRORS: 'JOB_FINISHED_WITH_SOFT_ERRORS'
};

export const datasetEventTriggerKeys = {
    DATASET_FINALIZED: 'DATA_SET_FINALIZED'
};

export const legalHoldEvenTriggerKeys = {
    LEGAL_HOLD_CREATED: 'LEGAL_HOLD_CREATED',
    LEGAL_HOLD_ACTIVATED: 'LEGAL_HOLD_ACTIVATED',
    LEGAL_HOLD_RELEASED: 'LEGAL_HOLD_RELEASED',
    CUSTODIANS_ISSUED_HOLD: 'CUSTODIANS_ISSUED_HOLD',
    CUSTODIANS_ISSUED_RELEASE: 'CUSTODIANS_ISSUED_RELEASE'
};

export const datasetStateKeys = {
    DRAFT: 'DRAFT',
    FINALIZED: 'FINALIZED',
    HIDDEN: 'HIDDEN',
    ARCHIVED: 'ARCHIVED',
    EXPIRED: 'EXPIRED'
}

export const nuixFileTypeKeys = {
    CONFIGURATION_PROFILE: "CONFIGURATION_PROFILE",
    METADATA_PROFILE: "METADATA_PROFILE",
    METADATA_IMPORT_PROFILE: "METADATA_IMPORT_PROFILE",
    PLAYBOOK: "PLAYBOOK",
    IMAGING_PROFILE: "IMAGING_PROFILE",
    OCR_PROFILE: "OCR_PROFILE",
    PROCESSING_PROFILE: "PROCESSING_PROFILE",
    PRODUCTION_PROFILE: "PRODUCTION_PROFILE",
}

export const libraryFileTypeKeys = {
    ...nuixFileTypeKeys,
    CUSTOM_FILE: "CUSTOM_FILE",
    PYTHON_SCRIPT: "PYTHON_SCRIPT",
    RUBY_SCRIPT: "RUBY_SCRIPT",
    JS_SCRIPT: "JS_SCRIPT",
    POWERSHELL_SCRIPT: "POWERSHELL_SCRIPT"
}

export const libraryFileUpdateMode = {
    LOCAL: 'LOCAL',
    REDUX: 'REDUX'
}

export const legalHoldStateKeys = {
    DRAFT: 'DRAFT',
    ACTIVE: 'ACTIVE',
    RELEASED: 'RELEASED',
    ARCHIVED: 'ARCHIVED'
}

export const uploadInfoStateKeys = {
    ACTIVE: 'ACTIVE',
    IDLE: 'IDLE'
}

export const printableOptionTypeKeys = {
    TEXT: 'TEXT',
    SINGLE: 'SINGLE',
    MULTILINE: 'MULTILINE',
    LIST: 'LIST'
}

export let policyPrincipalTypeKeys = {}, policyPrincipalBuiltInKeys = {};
export function setPolicyPrincipalTypeKeys(typeKeys) {
    getKeys(typeKeys).forEach(key => policyPrincipalTypeKeys[key] = key);

    typeKeys['BUILTIN'].forEach(key => policyPrincipalBuiltInKeys[key] = key);
}

export const policyPermissionKeys = {
    VIEW: 'VIEW',
    MODIFY: 'MODIFY',
    MODIFY_CHILDREN: 'MODIFY_CHILDREN',
    SUBMIT_JOB: 'SUBMIT_JOB',
    STAGE_JOB: 'STAGE_JOB',
    CREATE: 'CREATE',
    VIEW_LIMITED: 'VIEW_LIMITED',
    VIEW_SENSITIVE: 'VIEW_SENSITIVE',
    VIEW_NON_RECURSIVE: 'VIEW_NON_RECURSIVE',
    DOWNLOAD_LOGS: 'DOWNLOAD_LOGS',
    EXCLUDE_METRICS: 'EXCLUDE_METRICS'
};

export const permissionKeys = {
    ...policyPermissionKeys,
    MANAGE: 'MANAGE',
    RESPOND: 'RESPOND'
};

export const policyScopeTypeKeys = {
    BUILTIN: 'BUILTIN',
    CLIENT_ID: 'CLIENT_ID',
    CLIENT_ID_MATTERS: 'CLIENT_ID_MATTERS',
    CLIENT: 'CLIENT',
    MATTER_ID: 'MATTER_ID',
    MATTER: 'MATTER',
    LIBRARY_ID: 'LIBRARY_ID',
    LIBRARY: 'LIBRARY',
    WORKFLOW_ID: 'WORKFLOW_ID',
    WORKFLOW: 'WORKFLOW',
    NUIX_LICENCE_SOURCE_ID: 'NUIX_LICENCE_SOURCE_ID',
    NUIX_LICENCE_SOURCE: 'NUIX_LICENCE_SOURCE',
    EXECUTION_PROFILE_ID: 'EXECUTION_PROFILE_ID',
    EXECUTION_PROFILE: 'EXECUTION_PROFILE',
    CLIENT_POOL_ID: 'CLIENT_POOL_ID',
    CLIENT_POOL: 'CLIENT_POOL',
    RESOURCE_POOL_ID: 'RESOURCE_POOL_ID',
    RESOURCE_POOL: 'RESOURCE_POOL',
    NOTIFICATION_RULE_ID: 'NOTIFICATION_RULE_ID',
    NOTIFICATION_RULE: 'NOTIFICATION_RULE',
    DATA_REPOSITORY_ID: 'DATA_REPOSITORY_ID',
    DATA_REPOSITORY: 'DATA_REPOSITORY',
    THIRD_PARTY_SERVICE_ID: 'THIRD_PARTY_SERVICE_ID',
    THIRD_PARTY_SERVICE: 'THIRD_PARTY_SERVICE',
    USER_SERVICE: 'USER_SERVICE',
    USER_SERVICE_ID: 'USER_SERVICE_ID',
    NOTICE_TEMPLATE_ID: 'NOTICE_TEMPLATE_ID',
    NOTICE_TEMPLATE: 'NOTICE_TEMPLATE',
    LEGAL_HOLD_ID: 'LEGAL_HOLD_ID',
    LEGAL_HOLD: 'LEGAL_HOLD',
    FILE_LIBRARY_ID: 'FILE_LIBRARY_ID',
    FILE_LIBRARY: 'FILE_LIBRARY'
};

export const policyScopeTypeOptionKeys = {
    BUILTIN: 'BUILTIN',
    CLIENT_ID_MATTER_ID: 'CLIENT_ID_MATTER_ID',
    LIBRARY_ID_WORKFLOW_ID: 'LIBRARY_ID_WORKFLOW_ID',
    NUIX_LICENCE_SOURCE_ID: 'NUIX_LICENCE_SOURCE_ID',
    EXECUTION_PROFILE_ID: 'EXECUTION_PROFILE_ID',
    RESOURCE_POOL_ID: 'RESOURCE_POOL_ID',
    CLIENT_POOL_ID: 'CLIENT_POOL_ID',
    NOTIFICATION_RULE_ID: 'NOTIFICATION_RULE_ID',
    DATA_REPOSITORY_ID: 'DATA_REPOSITORY_ID',
    THIRD_PARTY_SERVICE_ID: 'THIRD_PARTY_SERVICE_ID',
    USER_SERVICE_ID: 'USER_SERVICE_ID',
    LEGAL_HOLD_ID: 'LEGAL_HOLD_ID',
    NOTICE_TEMPLATE_ID: 'NOTICE_TEMPLATE_ID',
    FILE_LIBRARY_ID: 'FILE_LIBRARY_ID'
};

export let policyScopeBuiltInKeys = {};
export function setPolicyScopeBuiltInKeys(builtInKeys) {
    builtInKeys.forEach(key => policyScopeBuiltInKeys[key] = key);
}