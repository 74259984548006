import React, {useLayoutEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import NoticeTemplateModel from "../../models/notice/NoticeTemplateModel";
import {applicationFeatures, details, noticeTypeKeys} from "../../utilities/constants";
import {getEntries, getValues, includesSome, isNotEmptyNorFalsy} from "../../utilities/helperFunctions";
import Switch from "../common/Switch/Switch";
import NoticeTemplateForm from "./NoticeTemplateForm";
import NoticeTemplateTablet from "./NoticeTemplateTablet";
import TabPane from "../common/TabPane/TabPane";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {AddButtonHeader} from "../common/Button/Button";
import NoticeTemplateRow from "./NoticeTemplateRow";
import {MainPageContent, MainPageHeader} from "../app/pages/MainPage";


function NoticeTemplateDisplay(props) {
    const {t} = useTranslation(['noticeTemplate']);
    const dispatch = useDispatch();

    const {
        ...attr
    } = props;

    const canModify = useSelector(state => includesSome(state.currentUser.features, [applicationFeatures.ADD_RESOURCE, applicationFeatures.ADD_NOTICE_TEMPLATE]));
    const isLoading = useSelector(state => !state.hasLoaded[details.NOTICE_TEMPLATES]);
    const {
        noticeTemplateId,
        isNoticeTemplateFormActive
    } = useSelector(state => state.componentStates.noticeTemplateDisplay);

    const noticeTemplateDetailsMap = useSelector(state => state.noticeTemplateDetailsMap);

    // Set active/inactive
    useLayoutEffect(() => {
        dispatch(NoticeTemplateModel.componentActionCreators.setDisplayActive());
        return () => dispatch(NoticeTemplateModel.componentActionCreators.setDisplayInactive());
    }, [dispatch]);


    function showForm(event) {
        const {value} = event.currentTarget.dataset;
        dispatch(NoticeTemplateModel.actionCreators.showForm({type: value}));
    }

    function showTablet(event) {
        const {value} = event.currentTarget.dataset;
        dispatch(NoticeTemplateModel.actionCreators.showTablet(value));
    }

    function toggleEnable(event) {
        const {value} = event.currentTarget.dataset;
        dispatch(NoticeTemplateModel.actionCreators.toggleEnabled(value));
    }

    const tables = {
        [noticeTypeKeys.HOLD]: [],
        [noticeTypeKeys.RECURRING]: [],
        [noticeTypeKeys.SURVEY]: [],
        [noticeTypeKeys.RELEASE]: [],
        [noticeTypeKeys.REMINDER]: [],
        [noticeTypeKeys.ESCALATION]: []
    };
    for (const noticeTemplate of getValues(noticeTemplateDetailsMap)) {
        tables[noticeTemplate.type].push(noticeTemplate);
    }

    const labels = {};
    for (const type of getValues(noticeTypeKeys)) {
        if (tables[type] != null || canModify) {
            labels[type] = (t(`noticeTemplate:type.${type}`));
        }
    }

    const selectedTemplate = noticeTemplateDetailsMap.get(noticeTemplateId);

    return (
        <MainPageContent {...attr}
            title={
                <MainPageHeader text={t('noticeTemplate:page.title')}
                    description={t('noticeTemplate:page.description')}
                />
            }
            list={
                <TabPane labels={getValues(noticeTypeKeys).map(type => labels[type]).filter(label => label)}>
                    {getValues(noticeTypeKeys).map((type, index) => {
                        if (tables[type] != null) {
                            return (
                                <NoticeTemplateTable key={index} type={type} canModify={canModify} isLoading={isLoading}
                                    noticeTemplateIds={tables[type]} noticeTemplateId={noticeTemplateId} showForm={showForm}
                                    showTablet={showTablet} toggleEnable={toggleEnable}/>
                            )
                        }
                    }).filter(e => e)}
                </TabPane>
            }
        >
            <Switch>
                {isNoticeTemplateFormActive &&
                <NoticeTemplateForm/>
                }

                {selectedTemplate &&
                <NoticeTemplateTablet noticeTemplateId={noticeTemplateId}/>
                }
            </Switch>
        </MainPageContent>
    )
}

function selectNoticeTemplates(state, props) {
    return getEntries(state.noticeTemplateDetailsMap)
        .filter(([ignore, _noticeTemplate]) => _noticeTemplate.type === props.type)
        .map(([id]) => id)
}

function NoticeTemplateTable(props) {
    const {noticeTemplateId, type, showForm, showTablet, toggleEnable, canModify, isLoading} = props;
    const {t} = useTranslation(['noticeTemplate', 'aria', 'common']);

    const noticeTemplateIds = useSelector(state => selectNoticeTemplates(state, {type}));

    return (
        <LoadingWrapper isLoading={isLoading}>
            <AddButtonHeader aria-label={t(`aria:hiddenAssistText.addNoticeTemplate${type}`)} text={t(`noticeTemplate:label.name_${type}`)}
                data-value={type} onClick={showForm} canModify={canModify}
            />

            {isNotEmptyNorFalsy(noticeTemplateIds) &&
            <div className="display-table">

                <div className="table-header-group">
                    <div className="table-header enable-cell"/>
                    <div className="table-header"/>
                </div>

                <div className="table-row-group">
                    {noticeTemplateIds.map(id =>
                        <NoticeTemplateRow key={id} isActive={id === noticeTemplateId} noticeTemplateId={id}
                            onSelect={showTablet} toggleEnable={toggleEnable} canModify={canModify}
                        />
                    )}
                </div>
            </div>
            }
        </LoadingWrapper>
    )
}

export default NoticeTemplateDisplay;
