import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {deepCopy, getLocaleDateTimeFromUTC} from "../../../utilities/helperFunctions";
import {downloadLogs} from "../../../utilities/downloadHelper";
import {logEndpoints} from "../../../utilities/constants";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import {SettingsRowSeparator, SettingsRowValue} from "../../common/CustomTable/CustomTable";
import {useDispatch, useSelector} from "react-redux";
import RelativityProxyModel from "../../../models/relativity/RelativityProxyModel";
import SchedulerModel from "../../../models/scheduler/SchedulerModel";
import {Button} from "../../common/Button/Button";

function DownloadLogsOptions (props) {
    const {t} = useTranslation(['systemLogs', 'common'])
    const dispatch = useDispatch();
    const [dateRange, setDateRange] = useState(getDates);

    const {
        isDisabled
    } = props;

    const {earliestLogDate, logRetentionPeriod} = useSelector(state => state.schedulerDetails.configuration);
    const isRelativityApplication = useSelector(state => state.schedulerDetails.isRelativityApplication);

    function getDates() {
        const date = new Date();
        const beforeDate = date.toJSON().split("T")[0];

        date.setDate(date.getDate() - 1);
        const afterDate = date.toJSON().split("T")[0];

        return {
            beforeDate: beforeDate,
            afterDate: afterDate
        }
    }

    function getEarliestLog() {
        const current = Number(earliestLogDate) || new Date().getTime();
        return getLocaleDateTimeFromUTC(current);
    }

    const onDateRangeAction = event => {
        const {name, value} = event.target;
        const range = deepCopy(dateRange);
        range[name] = value;
        setDateRange(range);
    }

    const downloadSystemLogs = () => {
        const beforeEpoch = new Date(dateRange.beforeDate).getTime();
        const afterEpoch = new Date(dateRange.afterDate).getTime();

        const attr = {
            beforeDate: isNaN(beforeEpoch) ? 0 : beforeEpoch,
            afterDate: isNaN(afterEpoch) ? 0 : afterEpoch,
        }
        if (isRelativityApplication) {
            dispatch(RelativityProxyModel.actionCreators.proxyDownload(logEndpoints.SYSTEM, attr, SchedulerModel.componentActionCreators.updateSettingsPage));
        } else {
            downloadLogs(logEndpoints.SYSTEM, attr);
        }
    }

    return (
        <>
            <div className="settings-table">
                <div className="table-row-group">
                    <SettingsRowSeparator size={'small'}/>
                    <SettingsRowValue label={t('systemLogs:label.earliestLogDate')} value={getEarliestLog()}
                        enableHighlightText/>
                    <SettingsRowSeparator size={'small'}/>
                    <SettingsRowValue label={t('systemLogs:label.logRetentionPeriod')}
                        value={`${logRetentionPeriod} ${t('systemLogs:label.days')}`}/>
                </div>
            </div>

            <div className="options-table display-input" style={{width: '50rem'}}>
                <div className="table-header-group">
                    <div className="table-header"/>
                </div>

                <div className="table-row-group">

                    <div className="table-cell" style={{display: 'flex', 'alignItems': 'center'}}>
                        <HTMLTextInput aria-label={t('aria:input.date', {name: t('common:label.after')})} type={"date"}
                            name={"afterDate"} value={dateRange["afterDate"]} isDisabled={isDisabled}
                            onChange={onDateRangeAction} containerStyle={{marginRight: '1.25rem'}}/>

                        <HTMLTextInput aria-label={t('aria:input.date', {name: t('common:label.before')})} type={"date"}
                            name={"beforeDate"} value={dateRange["beforeDate"]} isDisabled={isDisabled}
                            onChange={onDateRangeAction} containerStyle={{marginRight: '1.25rem'}}/>

                        <Button label={t('systemLogs:label.download')} onClick={downloadSystemLogs}
                            isDisabled={isDisabled}/>
                    </div>
                </div>
            </div>
        </>
    )
}



export default DownloadLogsOptions;