import React, {useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {createInputHandler} from "../../../utilities/componentFunctions";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import {Button} from "../../common/Button/Button";
import NoticeTemplateModel from "../../../models/notice/NoticeTemplateModel";
import {icon} from "../../../utilities/iconResolver";
import Editor from "rich-markdown-editor";

function NoticeMessagePane(props) {
    const {t} = useTranslation(['noticeTemplate', 'common']);
    const {
        subject,
        message,
        parameters,

        handler,
        isDisabled
    } = props;

    const [isPreviewMode, setIsPreviewMode] = useState(false);
    const valueUpdater = useRef();

    // Save value on exit
    useEffect(() => {
        return () => {
            if (typeof valueUpdater.current === 'function') {
                handler({message: valueUpdater.current()});
            }
        }
    }, []);

    const inputHandler = createInputHandler({
        handler
    });
    const onEditorChange = useCallback(value => {
        handler({message: value});
        valueUpdater.current = value;
    }, [handler]);

    function togglePreview() {
        if (typeof valueUpdater.current === 'function') {
            handler({message: valueUpdater.current()});
        }
        setIsPreviewMode(prev => !prev);
    }


    const disabled = isDisabled ? ' is-disabled' : '';

    return (
        <div className="pane-margin fill-height">

            <div style={{marginLeft: 'auto', marginBottom: '-2rem', marginTop: '0.75rem'}}>
                <Button id="previewButton" aria-label={t('aria:hiddenAssistText.previewNoticeMessage')} onClick={togglePreview}
                    isImg isClear isDisabled={isDisabled}
                >
                    <span className="icon">
                        {isPreviewMode ?
                            <img src={icon('actionPreviewCancel')} alt={t(`aria:hiddenAssistText.actionPreviewCancelIcon`)}/>
                            :
                            <img src={icon('actionPreview')} alt={t(`aria:hiddenAssistText.actionPreviewIcon`)}/>
                        }
                    </span>
                </Button>
            </div>

            <div className="display-input">
                <HTMLTextInput label={t('noticeTemplate:label.subject')} name={'subject'} onChange={inputHandler}
                    value={isPreviewMode ? NoticeTemplateModel.evaluateParametersInputTable(subject, parameters) : subject}
                    isReadOnly={isPreviewMode} isDisabled={isDisabled} isRequired/>
            </div>

            <div className="display-input" style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                <label className={'label' + disabled}>
                    {t('noticeTemplate:label.body')}
                </label>

                <div className={'rich-markdown-editor' + (isPreviewMode ? ' is-readonly' : '')}>
                    {isPreviewMode ?
                        typeof message === 'string' &&
                        <Editor key={message} value={NoticeTemplateModel.evaluateParametersInputTable(message, parameters)} readOnly/>
                        :
                        <Editor name={'message'} defaultValue={message} onChange={onEditorChange}
                            placeholder=""/>
                    }
                </div>
                {/*<MarkdownEditor markdown={message} onChange={onEditorChange}*/}
                {/*    isReadOnly={isPreviewMode} isDisabled={isDisabled}/>*/}
            </div>
        </div>
    )
}

export default NoticeMessagePane;
