import React from 'react';
import {updateGenericValues} from "../../../redux/WizardSlice/wizardSlice";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {createInputHandler} from "../../../../utilities/componentFunctions";
import {QuestionInput} from "../../Question/QuestionInput/QuestionInput";
import DiscoverFileRepositoryDropdown from "../../Dropdown/DiscoverDropdown/DiscoverFileRepositoryDropdown";
import {createDropdownHandler} from "../../../../components/common/Dropdown/helpers";
import {DiscoverFileRepository} from "../../../utilities/constants";
import HTMLTextInput from "../../../../components/common/HTMLTextInput/HTMLTextInput";
import DiscoverCaseIdentifierDropdown from "../../Dropdown/DiscoverDropdown/DiscoverCaseIdentifierDropdown";

function DiscoverConfiguration (props) {
    const {t} = useTranslation(['wizard'])
    const {updateState} = props;

    const {
        hostname,
        caseIdentifierType,
        discoverFileRepositoryType,
        windowsFileRepository,
        caseIdentifier
    } = props;

    const questions = [
        {
            question: t('wizard:questions.discover.hostname')
        },
        {
            question: t('wizard:questions.discover.caseIdentifier'),
        },
        {
            question: t('wizard:questions.discover.fileRepository')
        }
    ]

    const inputHandler = createInputHandler({
        handler: updateState
    });
    const dropdownHandler = createDropdownHandler({
        handler: updateState
    })

    const windowsRepositoryProps = {
        name: "windowsFileRepository",
        value: windowsFileRepository,
        onChange: inputHandler,
        isRequired: discoverFileRepositoryType === DiscoverFileRepository.WINDOWS_FILE_SHARE,
        isDisabled: discoverFileRepositoryType !== DiscoverFileRepository.WINDOWS_FILE_SHARE
    }


    return (
        <div className="wizard-step-container">
            <QuestionInput
                name={"hostname"}
                question={questions[0].question}
                value={hostname}
                onChange={inputHandler}
                isRequired={true}/>


            <div className={"display-item"}>
                <label className="label is-heading">
                    {questions[1].question}
                </label>
                <div className={'settings-table no-border tight-padding'} style={{border: '1px solid transparent'}}>
                    <div className="table-row-group">
                        <div key={"question-row2"} className="settings-row align-middle">
                            <div key={"question-cell2"} className="table-cell no-stretch">
                                <DiscoverCaseIdentifierDropdown
                                    selectedCaseIdentifierType={caseIdentifierType}
                                    onCaseIdentifierTypeSelected={dropdownHandler}
                                    isRequired={true}
                                    isDisabled={false}/>
                            </div>
                            <div key={"input-cell2"} className="table-cell">
                                <HTMLTextInput name={"caseIdentifier"}
                                               value={caseIdentifier}
                                               onChange={inputHandler}
                                               isRequired={true}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"display-item"}>
                <label className="label is-heading">
                    {questions[2].question}
                </label>
                <div className={'settings-table no-border tight-padding'} style={{border: '1px solid transparent'}}>
                    <div className="table-row-group">
                        <div key={"question-row"} className="settings-row align-middle">
                            <div key={"question-cell"} className="table-cell no-stretch">
                                <DiscoverFileRepositoryDropdown
                                    selectedRepositoryType={discoverFileRepositoryType}
                                    onRepositoryTypeSelected={dropdownHandler}
                                    isRequired={true}
                                    isDisabled={false}/>
                            </div>
                            <div key={"input-cell"} className="table-cell">
                                <HTMLTextInput {...windowsRepositoryProps}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dynamic-examples-container">
                <label className="label is-small">{t("wizard:questions.discover.expectedExample", {parameter: "{discover_api_token_protected}"})}</label>
            </div>
        </div>
    )
}

function mapStateToProps (state) {
    const {wizardSlice:{discoverConfiguration: {hostname, caseIdentifierType, caseIdentifierTypeId, discoverFileRepositoryType, windowsFileRepository, apiToken, caseIdentifier }}} = state;
    return {
        hostname,
        caseIdentifierType,
        discoverFileRepositoryType,
        windowsFileRepository,
        apiToken,
        caseIdentifier,
        caseIdentifierTypeId
    };
}

function mapDispatchToProps (dispatch) {
    return {
        updateState: updates => dispatch(updateGenericValues({stateName: "discoverConfiguration", updates: updates}))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverConfiguration);