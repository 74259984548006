import React, {useCallback, useEffect, useRef, useState} from "react";
import './Panel.css';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getObjectText, getValues, isNotEmptyNorFalsy} from "../../utilities/helperFunctions";
import {
    CLIENT_UNASSIGNED,
    MIN_SIZE_FOR_SEARCH,
    SAME_AS_TRIGGERING_DATASET,
    SAME_AS_TRIGGERING_JOB,
    SAME_AS_TRIGGERING_LEGAL_HOLD
} from "../../utilities/constants";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {MatterQueueRow} from "./QueueRow";
import MatterModel from "../../models/client/MatterModel";
import SearchBar from "../common/SearchBar/SearchBar";
import {permissionKeys} from "../../i18next/keys";

export function RestrictedMatterPanel(props) {
    const {
        clientId,
        requireSubmitJob
    } = props;

    const matterSelector = useCallback(state => {
        return getValues(state.matterDetailsMap)
            .filter(matter => matter.enabled && matter.clientId === clientId
                && (!requireSubmitJob || matter.userPermissions.includes(permissionKeys.SUBMIT_JOB)));
    }, [requireSubmitJob, clientId]);

    return (
        <MatterPanel {...props} matterSelector={matterSelector}/>
    )
}

function MatterPanel(props) {
    const {clientId, matterId, matterSelector, updateState, canSetSameAs={}, isDisabled} = props;
    const {t} = useTranslation(['client', 'job', 'jobSchedule']);

    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');

    const matters = useSelector(matterSelector);
    const isLoading = useSelector(state => !state.hasLoaded[clientId]);

    // Query matters
    useEffect(() => {
        dispatch(MatterModel.actionCreators.queryDetailsSubset(clientId));
    }, [clientId]);

    // Select Matter if it's the only one (and no matter is selected)
    useEffect(() => {
        if (!isDisabled && matterId == null && matters.length === 1) {
            const matter = matters[0];
            updateState({matterId: matter.id});
        }
    }, [isDisabled, matterId, matters, updateState]);

    const scrollDivRef = useRef();
    // Scroll to selected matter IFF exists
    useEffect(() => {
        if (!isLoading && scrollDivRef.current != null) {
            const activePanel = scrollDivRef.current.getElementsByClassName('is-active')[0];

            if (activePanel != null) {
                activePanel.scrollIntoView();
            }
        }
    }, [isLoading]);

    function matterSelect(event) {
        const {value: matterId} = event.currentTarget.dataset;
        updateState({matterId});

        setTimeout(() => {
            document.getElementById('formFooterNextButton')?.focus();
        }, 50);
    }

    const isClientEmpty = !isNotEmptyNorFalsy(matters);
    const canSearchMatters = matters.length >= MIN_SIZE_FOR_SEARCH;

    const centered = isClientEmpty ? ' is-centered' : '';
    const disabled = isDisabled ? ' is-disabled' : '';

    const promptSelectClient = clientId == null ||
        (!canSetSameAs.job && clientId === SAME_AS_TRIGGERING_JOB) || (!canSetSameAs.dataset && clientId === SAME_AS_TRIGGERING_DATASET) || (!canSetSameAs.legalHold && clientId === SAME_AS_TRIGGERING_LEGAL_HOLD);

    let content;
    if (promptSelectClient) {
        content = <label className={'label is-italic' + disabled}>{t('client:message.selectClient')}</label>;

    } else if (clientId === CLIENT_UNASSIGNED) {
        content = <label className={'label is-italic' + disabled}>{t('job:message.unassignedClient')}</label>;

    } else if (clientId === SAME_AS_TRIGGERING_JOB) {
        content = <label className={'label is-italic' + disabled}>{t('jobSchedule:message.sameMatterAsTriggeringJobMatter')}</label>;

    } else if (clientId === SAME_AS_TRIGGERING_DATASET) {
        content = <label className={'label is-italic' + disabled}>{t('jobSchedule:message.sameMatterAsTriggeringDatasetMatter')}</label>;

    } else if (clientId === SAME_AS_TRIGGERING_LEGAL_HOLD) {
        content = <label className={'label is-italic' + disabled}>{t('jobSchedule:message.sameMatterAsTriggeringLegalHoldMatter')}</label>;

    } else if (isClientEmpty && !isLoading) {
        content = <label className={'label is-italic' + disabled}>{t('client:message.clientNoActiveMatters')}</label>;

    } else {
        content =
            <LoadingWrapper isLoading={isLoading}>
                {canSearchMatters &&
                <SearchBar id="matterSearchBar" autoFocus style={{margin: '0.5rem 0'}}
                    value={searchText} onChange={event => setSearchText(event.target.value)}/>
                }

                <section style={{overflowY: 'auto'}} ref={scrollDivRef}>
                    <div className="select-table">
                        <div className="table-row-group">

                            {matters
                                .filter(_matter => getObjectText(_matter).includes(searchText.toLowerCase()))
                                .map(({id}) =>
                                    <MatterQueueRow key={id} matterId={id} isActive={id === matterId}
                                        onPanelSelect={matterSelect} isDisabled={isDisabled}/>
                                )}
                        </div>
                    </div>
                </section>
            </LoadingWrapper>
    }

    return (
        <section className={'selectPane-display' + centered} role="tabpanel" tabIndex={-1}
            id={clientId ? `${clientId}_TABPANEL` : null} aria-labelledby={clientId ? `${clientId}_TAB` : null}
        >
            {content}
        </section>
    );
}

export default MatterPanel;
