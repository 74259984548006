import React, {useRef, useState} from "react";
import "./WorkflowTemplate.css";
import CustomModal from "../common/CustomModal/CustomModal";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {FormFooter, FormHeader} from "../common/Form/Form";
import LibraryModel from "../../models/library/LibraryModel";
import Text from "../common/Text/Text";
import {buildClassName, getFileNameWithoutExtension} from "../../utilities/helperFunctions";
import WorkflowBuilderModel from "../../models/workflowbuilder/WorkflowBuilderModel";
import AutomateLicenceModel from "../../models/scheduler/AutomateLicenceModel";
import Blank from '../../resources/images/icons/blankFile.svg';
import Template from '../../resources/images/icons/template.svg';
import RfnFile from '../../resources/images/icons/templateFile.svg';
import Wizard from '../../resources/images/icons/wizard.svg';
import SelectTemplateOption from "./SelectTemplateOption";
import {useKeyPressEffect} from "../../utilities/hooks";
import {createFileHandler} from "../../utilities/componentFunctions";
import WorkflowTemplateModel, {WorkflowTemplateSaga} from "../../models/library/WorkflowTemplateModel";
import SchedulerModel from "../../models/scheduler/SchedulerModel";
import {contextCall} from "../../saga/sagaFunctions";
import {call, put} from "redux-saga/effects";
import encode from "base64-arraybuffer";

function WorkflowTemplateFormOptions(props) {
    const {t} = useTranslation(['workflow', 'common']);
    const dispatch = useDispatch();

    const {
        libraryId
    } = props;

    const {
        isDisabled,
    } = useSelector(state => state.componentStates.workflowTemplateForm);

    const workflowUploadRef = useRef();
    const [isTemplateOptionActive, setIsTemplateOptionActive] = useState();

    function onClose() {
        dispatch(LibraryModel.componentActionCreators.updateDisplay({isWorkflowTemplateFormActive: false}));
    }

    function openWorkflowBuilder() {
        dispatch(WorkflowBuilderModel.actionCreators.open());
        onClose();
    }

    function openSelectTemplateOption() {
        setIsTemplateOptionActive(true);
    }

    function openWorkflowWizard() {
        dispatch(LibraryModel.componentActionCreators.updateDisplay({isWorkflowWizardActive: true}));
        dispatch(AutomateLicenceModel.actionCreators.queryDetails());
        onClose();
    }

    function openWorkflowFileForm() {
        workflowUploadRef.current.click();
    }

    const onWorkflowFileChange = createFileHandler({
        readAs: 'readAsArrayBuffer',
        onloadend: (event, file) => {
            const operationsXml = encode.encode(event.target.result);
            const workflowName = getFileNameWithoutExtension(file.name);

            const workflow = {workflowName, operationsXml};
            const submitWorkflowEffect = call(function* () {
                yield contextCall(WorkflowTemplateSaga, 'validateWorkflow', {payload: {workflow}});
                yield put(WorkflowTemplateModel.actionCreators.submitForm({libraryId}));
                onClose();
            });
            dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(submitWorkflowEffect));
        }
    });

    if (isTemplateOptionActive) {
        return (
            <SelectTemplateOption libraryId={libraryId} onClose={onClose} isDisabled={isDisabled}/>
        )
    }

    return (
        <CustomModal id="workflowFormOptions" isActive onCancel={onClose} style={{minWidth: '46rem'}}
            closeButtonAriaLabel={t('workflow:option.closeAddWorkflowFormOptions')}
            aria-labelledby={'workflowTemplateFormOptionsHeader'}
            header={
                <section className="workflow-template__form-header">
                    <FormHeader headerId={'workflowTemplateFormOptionsHeader'}
                        text={t('workflow:label.addWorkflowTemplate')}
                        isDisabled={isDisabled}/>
                </section>
            }
            body={
                <section className="workflow-template__form-options-body">
                    <input ref={workflowUploadRef} type="file" accept=".rfn,.zip"
                        style={{display: 'none'}} onChange={onWorkflowFileChange}/>

                    <table className="workflow-template__form-option-table">
                        <tbody>
                        <tr>
                            <FormOption icon={Blank} onClick={openWorkflowBuilder} isDisabled={isDisabled}
                                label={t('workflow:option.startFromBlankCanvas')} iconAlt={t('aria:hiddenAssistText.blankWorkflowIcon')}
                                description={t('workflow:message.startFromBlankCanvas')}
                            />
                            <FormOption icon={Template} onClick={openSelectTemplateOption} isDisabled={isDisabled}
                                label={t('workflow:option.startFromTemplate')} iconAlt={t('aria:hiddenAssistText.workflowTemplateIcon')}
                                description={t('workflow:message.startFromTemplate')}
                            />
                            <FormOption icon={Wizard} onClick={openWorkflowWizard} isDisabled={isDisabled}
                                label={t('workflow:option.startWorkflowWizard')} iconAlt={t('aria:hiddenAssistText.workflowWizardIcon')}
                                description={t('workflow:message.startWorkflowWizard')}
                            />
                            <FormOption icon={RfnFile} onClick={openWorkflowFileForm} isDisabled={isDisabled}
                                label={t('workflow:option.addFromFile')} iconAlt={t('aria:hiddenAssistText.workflowFromFileIcon')}
                                description={t('workflow:message.addFromFile')}
                            />
                        </tr>
                        </tbody>
                    </table>
                </section>
            }
            footer={
                <section className="form-footer">
                    <FormFooter onCancel={onClose} isDisabled={isDisabled}/>
                </section>
            }
        />
    )
}

function FormOption(props) {
    const {
        label,
        icon,
        description,
        onClick,
        isDisabled
    } = props;

    const containerRef = useRef();
    const keyToCb = useRef({'Enter': 'click'});
    useKeyPressEffect({containerRef, keyToCb: keyToCb.current});

    const className = buildClassName(
        'workflow-template__form-option',
        isDisabled && 'is-disabled'
    );

    return (
        <td>
            <section className={className} ref={containerRef} onClick={onClick} tabIndex={0}>
                <Text value={label} isCenter isBold/>

                <span className="icon is-large">
                    <img src={icon} alt={label}/>
                </span>

                <Text value={description} isCenter isWordWrap/>
            </section>
        </td>
    )
}

export default WorkflowTemplateFormOptions;
