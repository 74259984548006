import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import JobArchiveHeader from "./JobArchiveHeader";
import {JobListContainer, MushroomHead} from "./JobList";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {details, userSettings} from "../../utilities/constants";
import {useTranslation} from "react-i18next";
import {jobPageViewKeys} from "../../i18next/keys";
import JobTablet from "./JobTablet";
import CurrentUserModel from "../../models/user/CurrentUserModel";
import {getValues, objEquals} from "../../utilities/helperFunctions";
import {selectDetailsMapForJobNames, selectJobText} from "./selectors";


function JobArchiveDisplay() {
    const {t} = useTranslation(['job']);
    const dispatch = useDispatch();

    const isLoading = useSelector(state => !state.hasLoaded[details.ARCHIVE_JOBS]);
    const activeJob = useSelector(state => state.jobArchiveMap.get(state.componentStates.jobPage.jobId));

    const jobArchiveDisplay = useSelector(state => state.componentStates.jobArchiveDisplay);
    const detailsMap = useSelector(state => ({
        jobArchive: state.jobArchiveMap,
        ...selectDetailsMapForJobNames(state),
    }), objEquals);

    const jobs = getValues(detailsMap.jobArchive)
        .filter(job => selectJobText(job, detailsMap).includes(jobArchiveDisplay.searchText.toLowerCase()));

    // Start polling JOB_MONITORING
    useEffect(() => {
        dispatch(CurrentUserModel.actionCreators.pollActivity(CurrentUserModel.activities.JOB_MONITORING));
    }, [dispatch]);


    const {showJobListCount} = useSelector(state => state.userSettingsMap.get(userSettings.JOB_LANE));
    const activeJobId = activeJob && activeJob.id;

    let header = t(`scheduler:jobPageView.${jobPageViewKeys.ARCHIVE}`);
    if (showJobListCount && jobs.length > 0) {
        header += ` (${jobs.length})`;
    }

    return (
        <>
            <header className="mainPage-header">
                <JobArchiveHeader/>
            </header>

            <section className="job-content" id={'jobArchiveDisplay'}>
                <div className="mushroom-heads">
                    <div className="columns">
                        <MushroomHead text={header}/>
                    </div>
                </div>
                <div className="job-containers">
                    <LoadingWrapper isLoading={isLoading}>
                        <div className="columns">
                            <JobListContainer id={"jobListARCHIVED"} activeJobId={activeJobId}
                                aria-label={t(`aria:hiddenAssistText.ARCHIVED`)} jobs={jobs}/>
                        </div>
                    </LoadingWrapper>
                </div>
            </section>

            {!!activeJob &&
            <JobTablet jobId={activeJobId}/>
            }
        </>
    )
}

export default JobArchiveDisplay;
