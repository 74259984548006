import React from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {NestedStateCheckBox} from "../../Question/QuestionCheckBox/QuestionCheckBox";
import {updateGenericValues} from "../../../redux/WizardSlice/wizardSlice";
import GenericPathExample from "../../Text/GenericPathExample/GenericPathExample";
import {ExampleType} from "../../../utilities/constants";
import {createNestedToggleHandler} from "../../Question/QuestionCheckBox/helpers";

function ReportOptions(props) {
    const {updateState} = props;
    const {t} = useTranslation(['wizard']);

    const {
        reporting,
        caseStructure,
        baseFolderPath
    } = props;

    const questions = [
        {
            question: t('wizard:questions.optional.performOptionalReport'),
            dataName: "optionalReports",
            options: [
                {id: "report", value: t('wizard:questions.optional.reportOptions.processingReport'), checked: reporting.report},
                {id: "treeCountSizeReport", value: t('wizard:questions.optional.reportOptions.treeCountSizeReport'), checked: reporting.treeCountSizeReport},
            ]
        }
    ]

    const toggleEnable = createNestedToggleHandler({
        handler: updateState
    });

    return (
        <div className={"wizard-step-container"}>

            <NestedStateCheckBox
                question={questions[0].question}
                dataName={questions[0].dataName}
                options={questions[0].options}
                onClick={toggleEnable}
                nested={"reporting"}
            />

            {reporting.report &&
                <div className={"display-item"}>
                    <div className={"dynamic-examples-container"}>
                        <GenericPathExample
                            exampleType={ExampleType.REPORT}
                            exampleTypeKey={"report"}
                            caseType={caseStructure}
                            baseFolder={baseFolderPath}
                            example={"Report\\Processing Report.xlsx"}/>
                    </div>
                </div>
            }

            {reporting.treeCountSizeReport &&
            <div className={"display-item"}>
                <div className={"dynamic-examples-container"}>
                    <GenericPathExample
                        exampleType={ExampleType.REPORT}
                        exampleTypeKey={"treeCountSizeReport"}
                        caseType={caseStructure}
                        baseFolder={baseFolderPath}
                        example={"Report\\Tree Count Size Report.xlsx"}/>
                </div>
            </div>
            }

        </div>
    );
}

function mapStateToProps (state) {
    const {wizardSlice:{optionalSteps: {reporting},caseSettings: {caseStructure, baseFolderPath}}} = state;
    return {
        reporting,
        caseStructure,
        baseFolderPath
    };
}

function mapDispatchToProps (dispatch) {
    return {
        updateState: updates => dispatch(updateGenericValues({stateName: "optionalSteps", updates: updates}))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportOptions);