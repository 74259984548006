import React, {useCallback, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import LibraryFileForm from "./LibraryFileForm";
import LibraryFileDisplay from "./LibraryFileDisplay";
import {
    buildClassName,
    getFileNameWithoutExtension,
    getValues,
    objectTruthyValues,
    shallowCopy
} from "../../utilities/helperFunctions";
import {useDispatch, useSelector} from "react-redux";
import EditModel from "../../models/scheduler/EditModel";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {AddButtonHeader} from "../common/Button/Button";
import {useAutoSelectId, useKeyPressEffect} from "../../utilities/hooks";
import LibraryFileModel from "../../models/filelibrary/LibraryFileModel";
import SearchBar from "../common/SearchBar/SearchBar";
import {CheckedDropdown} from "../common/Dropdown/Dropdown";
import {useFilterDropdownClearHandler, useFilterDropdownHandler} from "../common/Dropdown/helpers";
import {libraryFileTypeKeys} from "../../i18next/keys";
import './FileLibraryTablet.css';
import Text from "../common/Text/Text";

function selectLibraryFileIds (state, fileLibraryId, searchText, fileTypeFilters) {
    const fileTypes = objectTruthyValues(fileTypeFilters);

    return getValues(state.libraryFileDetailsMap)
        .filter(libraryFile => libraryFile.fileLibraryId === fileLibraryId)
        .filter(libraryFile => filterSearch(libraryFile, searchText))
        .filter(libraryFile => fileTypes.length === 0 || fileTypes.includes(libraryFile.nuixFileType))
        .map(libraryFile => libraryFile.id)
}

function filterSearch (libraryFile, searchText) {
    const {name, description} = libraryFile
    let nameLower = getFileNameWithoutExtension(name).toLowerCase();
    let descriptionLower = description.toLowerCase();
    let searchLower = searchText.toLowerCase();

    return nameLower.includes(searchLower) || descriptionLower.includes(searchLower);
}

function LibraryFileView(props) {
    const {t} = useTranslation(['aria', 'fileLibrary', 'common'])
    const dispatch = useDispatch();

    const {
        fileLibraryId,
        canModify,
        isDisabled
    } = props;

    const [searchText, setSearchText] = useState("");
    const [fileTypes, setFileTypes] = useState({fileTypeFiler:{}});

    const {
        libraryFileId,
        isLibraryFileFormActive
    } = useSelector(state => state.componentStates.libraryFileView)

    const libraryFileIds = useSelector(state => selectLibraryFileIds(state, fileLibraryId, searchText, fileTypes.fileTypeFiler));
    const selectedLibraryFile = useSelector(state => state.libraryFileDetailsMap.get(libraryFileId));

    const isLibraryFileEditActive = useSelector(state => state.editDetails.activeModel === LibraryFileModel.nom);
    const isLoading = useSelector(state => !state.hasLoaded[fileLibraryId]);

    const setFileTypeFilter = useCallback(function (updates) {
        setFileTypes(prevState => {
           const update = typeof updates === 'function' ? updates(prevState) : updates;
           return shallowCopy(prevState, update);
        });
    }, [])

    const onToggle = useFilterDropdownHandler({
        updateState: setFileTypeFilter
    });
    const onClear = useFilterDropdownClearHandler({
        updateState: setFileTypeFilter
    });

    useAutoSelectId(libraryFileId, libraryFileIds,
        id => dispatch(LibraryFileModel.componentActionCreators.updateView({libraryFileId: id}))
    );

    function onLibraryFileSelect (event) {
        const {value} = event.currentTarget.dataset;

        dispatch(EditModel.actionCreators.callEditBreakingFunc(function () {
            dispatch(LibraryFileModel.componentActionCreators.resetView());
            dispatch(LibraryFileModel.componentActionCreators.updateView({libraryFileId: value}));
        }))
    }

    function showAddLibraryFile () {
        dispatch(EditModel.actionCreators.callEditBreakingFunc(function () {
            dispatch(LibraryFileModel.actionCreators.showForm());
        }))
    }

    const libraryFilePanelsClassName = buildClassName(
        'fileLibraryTablet-panel',
        (libraryFileIds.length === 0 || selectedLibraryFile == null) && 'no-border'
    )

    const showFilters = !isDisabled && (libraryFileIds.length !== 0 || objectTruthyValues(fileTypes["fileTypeFiler"]).length > 0 || searchText.length > 0);

    return (
        <LoadingWrapper isLoading={isLoading}>
            <div id="libraryFileView" className={"fileLibraryTablet" + (isDisabled ? ' is-disabled' : '')}>
                <div className={libraryFilePanelsClassName}>
                    <div className="fileLibraryTablet-panel-header">
                        <div className={"fileLibraryTablet-panel-centered"}>
                            <AddButtonHeader aria-label={t('aria:hiddenAssistText.addFileLibraryFile')} text={t('fileLibrary:file.label.name')}
                                onClick={showAddLibraryFile} canModify={canModify}
                                id={"libraryFileAddButton"} isDisabled={isDisabled || isLibraryFileEditActive}
                            />

                            {showFilters &&
                                <FileTypeCheckedDropdown name={"fileTypeFiler"} filterState={fileTypes} onToggle={onToggle} onClear={onClear} isDisabled={isDisabled}/>
                            }
                        </div>


                        {showFilters &&
                            <SearchBar value={searchText} onChange={e => setSearchText(e.target.value)} style={{marginRight: '1rem', marginTop: '0.75rem'}}
                                       isDisabled={isDisabled}/>
                        }
                    </div>

                    {libraryFileIds.length > 0 &&
                    <div className="fileLibraryTablet-panel-list">
                        {libraryFileIds.map(id =>
                            <LibraryFilePanel key={id} libraryFileId={id} isActive={id === libraryFileId} onClick={onLibraryFileSelect}
                                              isDisabled={isDisabled || isLibraryFileEditActive}
                            />
                        )}
                    </div>
                    }
                </div>

                {(selectedLibraryFile != null && (fileLibraryId != null && selectedLibraryFile.fileLibraryId === fileLibraryId)) &&
                    <LibraryFileDisplay libraryFileId={libraryFileId} canModify={canModify} isDisabled={isDisabled || isLibraryFileEditActive}/>
                }
            </div>

            {(canModify && isLibraryFileFormActive) &&
                <LibraryFileForm fileLibraryId={fileLibraryId}/>
            }
        </LoadingWrapper>
    )
}

function LibraryFilePanel (props) {
    const {t} = useTranslation(['fileLibrary'])

    const {
        libraryFileId,
        onClick,
        isActive,
        isDisabled
    } = props

    const {
        name,
        nuixFileType
    } = useSelector(state => state.libraryFileDetailsMap.get(libraryFileId));

    const containerRef = useRef();
    const keyToCb = useRef({
        'Enter': 'click'
    });
    useKeyPressEffect({containerRef, keyToCb: keyToCb.current});

    const className = buildClassName(
        'tablet-view__panel',
        'selectPanelSimple',
        isActive && 'is-active',
        isDisabled && 'is-disabled'
    );

    const fileTypeClassName = buildClassName(
        'label', 'is-ellipsis', 'is-italic', isDisabled && 'is-disabled'
    )

    const displayName = getFileNameWithoutExtension(name);
    return (
        <article className={className} data-value={libraryFileId}
             ref={containerRef} onClick={isDisabled ? null : onClick} tabIndex={isDisabled ? -1 : 0}
        >
            <Text ElementTag="span" isEllipsis isDisabled={isDisabled}>
                {displayName}
            </Text>

            <span className={fileTypeClassName}>
                {t(`fileLibrary:file.type.${nuixFileType}`)}
            </span>
        </article>
    )
}

function FileTypeCheckedDropdown (props) {
    const {t} = useTranslation(['fileLibrary', 'aria']);
    const {
        name,
        filterState,
        ...rest
    } = props;

    const noneSelectedMessage = t(`fileLibrary:option.allFileTypes`);
    const items = getValues(libraryFileTypeKeys).map(state=> ({
        name: t(`fileLibrary:file.type.${state}`),
        value: state,
        isChecked: !!filterState[name][state]
    })).sort((a, b) => a.name.localeCompare(b.name));


    return (
        <CheckedDropdown name={name} noneSelectedMessage={noneSelectedMessage} style={{marginRight: '1rem'}}
            aria-label={t('aria:hiddenAssistText.fileLibraryFileTypeDropdown')} items={items} {...rest}/>
    )
}

export default LibraryFileView;
