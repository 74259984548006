import React from 'react';
import './NavigateButtons.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "../Button/Button";
import {buildClassName} from "../../../utilities/helperFunctions";
import {useTranslation} from "react-i18next";

function NavigateButtons(props) {
    const {t} = useTranslation(['aria']);
    const {
        ariaLabelKey,
        onClick=[],
        length=[],

        isVertical=false,
        isFlipped=false,

        onNavigateLeft = onClick[0],
        onNavigateRight = onClick[1],
        selectedLeftCount = length[0],
        selectedRightCount = length[1],
        isLeftDisabled,
        isRightDisabled,
        isDisabled,
        ...attr
    } = props;

    const type = [];
    type[0] = selectedLeftCount > 1 ? 'double-' : '';
    type[1] = selectedRightCount > 1 ? 'double-' : '';

    const direction = isVertical ? ['up', 'down'] : ['right', 'left'];
    if (isFlipped) {
        direction.reverse();
    }

    const leftDisabled = isDisabled || isLeftDisabled || selectedLeftCount < 1;
    const rightDisabled = isDisabled || isRightDisabled || selectedRightCount < 1;

    const ariaLabels = [];
    ariaLabels[0] = t(`aria:hiddenAssistText.move${direction[0]}${ariaLabelKey}`);
    ariaLabels[1] = t(`aria:hiddenAssistText.move${direction[1]}${ariaLabelKey}`);

    const _className = buildClassName(
        'navigate-buttons',
        isVertical && 'is-vertical'
    );

    return (
        <div className={_className} {...attr}>
            <Button onClick={onNavigateLeft} isDisabled={leftDisabled} isImg
                aria-label={ariaLabels[0]}
            >
                <FontAwesomeIcon icon={`angle-${type[0]}${direction[0]}`}/>
            </Button>

            <Button onClick={onNavigateRight} isDisabled={rightDisabled} isImg
                aria-label={ariaLabels[1]}
            >
                <FontAwesomeIcon icon={`angle-${type[1]}${direction[1]}`}/>
            </Button>
        </div>
    );
}

export default NavigateButtons;