import React, {useCallback, useMemo, useRef} from "react";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import TextArea from "../../common/TextArea/TextArea";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import WorkflowBuilderModel from "../../../models/workflowbuilder/WorkflowBuilderModel";
import {createFileHandler, createInputHandler} from "../../../utilities/componentFunctions";
import {createToggleHandler} from "../../common/Checkbox/helpers";
import Checkbox from "../../common/Checkbox/Checkbox";
import {InputTableV2} from "../../common/InputTable/InputTable";
import {AddRemoveButtons, Button} from "../../common/Button/Button";
import {icon} from "../../../utilities/iconResolver";
import {getValues, readLines} from "../../../utilities/helperFunctions";
import {ListDropdown} from "../../common/Dropdown/Dropdown";
import WorkflowTemplateModel from "../../../models/library/WorkflowTemplateModel";
import {FormElementRow} from "../../common/CustomTable/CustomTable";
import {createDropdownHandler} from "../../common/Dropdown/helpers";

function DetailsPane() {
    const {t} = useTranslation(['workflowBuilder', 'common']);
    const dispatch = useDispatch();

    const workflowBuilder = useSelector(state => state.workflowBuilder);
    const updateState = useCallback(updates => dispatch(WorkflowBuilderModel.actionCreators.update(updates)), []);

    const inputHandler = createInputHandler({
        handler: updateState
    });
    const toggleHandler = createToggleHandler({
        handler: updateState
    });
    const dropdownHandler = createDropdownHandler({
        handler: updateState
    });
    const setPrerequisites = useCallback(function (updates) {
        updateState(prevState => ({prerequisites: typeof updates === 'function' ? updates(prevState.prerequisites) : updates}));
    }, []);

    const uploadPrerequisites = useCallback(createFileHandler({
        readAs: 'readAsText',
        onloadend: function (event) {
            const prerequisites = readLines(event.target.result).filter(v => v).map(prerequisite => ([{value: prerequisite}]));
            setPrerequisites(prerequisites);
        }
    }));

    const InputTableHeader = useMemo(() => {
        return headerProps =>
            <PrerequisitesInputTableHeader {...headerProps}
                uploadPrerequisites={uploadPrerequisites}/>
    }, []);

    return (
        <div className="pane-margin">
            <div className="display-input">
                <HTMLTextInput label={t('common:label.name')} name={'workflowName'} value={workflowBuilder.workflowName}
                    onChange={inputHandler} isDisabled={workflowBuilder.isDisabled} isRequired/>
            </div>

            <div>
                <Checkbox label={t('common:label.enabled')} name={'enabled'} checked={workflowBuilder.enabled} onClick={toggleHandler}
                    isDisabled={workflowBuilder.isDisabled}/>
            </div>

            <div className="forms-table display-input">
                <div className="table-row-group">
                    <FormElementRow label={t('workflowBuilder:label.executionMode')} isDisabled={workflowBuilder.isDisabled}
                        element={
                            <ExecutionModeDropdown value={workflowBuilder.explicitExecutionMode} name={'explicitExecutionMode'}
                                onItemSelect={dropdownHandler} isDisabled={workflowBuilder.isDisabled}/>
                        }/>
                </div>
            </div>

            <div className="display-input">
                <TextArea label={t('common:label.description')} name={'description'} value={workflowBuilder.description} onChange={inputHandler}
                    isDisabled={workflowBuilder.isDisabled}/>
            </div>

            <div className="display-input">
                <InputTableV2 id="workflowPrerequisites" label={t('workflowBuilder:label.prerequisites')}
                    inputTable={workflowBuilder.prerequisites} setInputTable={setPrerequisites} defaultRow={[{value: ''}]}
                    HeaderComponent={InputTableHeader} isDisabled={workflowBuilder.isDisabled}
                    ariaLabelKey={'Prerequisite'} columnAriaLabels={[t('aria:hiddenAssistText.prerequisite')]}
                />
            </div>

            <div className="display-input">
                <TextArea label={t('workflowBuilder:label.usage')} name={'usage'} value={workflowBuilder.usage} onChange={inputHandler}
                    isDisabled={workflowBuilder.isDisabled}/>
            </div>
        </div>
    )
}

export function ExecutionModeDropdown(props) {
    const {t} = useTranslation(['aria', 'workflowBuilder']);

    const items = getValues(WorkflowTemplateModel.ExecutionMode)
        .map(mode => ({
            name: t(`workflowBuilder:ExecutionMode.${mode}`),
            value: mode
        }));

    return (
        <ListDropdown id={'workflowExecutionModeDropdown'} name={'executionMode'}
            items={items} aria-label={t('aria:hiddenAssistText.workflowExecutionMode')}
            noneSelectedMessage={t('workflowBuilder:option.selectExecutionMode')} {...props}/>
    )
}

function PrerequisitesInputTableHeader(props) {
    const {t} = useTranslation(['workflowBuilder']);
    const {
        label,
        onAddClick,
        onRemoveClick,
        noPadding,
        isReadOnly,
        isRemoveDisabled,
        isDisabled,

        uploadPrerequisites
    } = props;

    const prerequisitesUploadInputRef = useRef();
    function onPrerequisitesUpload() {
        if (prerequisitesUploadInputRef.current != null && typeof prerequisitesUploadInputRef.current.click === 'function') {
            prerequisitesUploadInputRef.current.click();
        }
    }

    return (
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <AddRemoveButtons label={label} onAddClick={onAddClick} isReadOnly={isReadOnly}
                ariaLabelKey={'Prerequisite'} noPadding={noPadding}
                onRemoveClick={onRemoveClick} isRemoveDisabled={isRemoveDisabled} isDisabled={isDisabled}/>

            <div className="add-remove-buttons">
                {typeof uploadPrerequisites === 'function' &&
                    <Button id="workflowBuilderUploadPrerequisitesButton" isImg onClick={onPrerequisitesUpload}
                        aria-label={t('workflowBuilder:message.uploadPrerequisites')} isDisabled={isDisabled}
                    >
                        <span className="icon is-small">
                            <img src={icon('metadataAdd')} alt={t('aria:hiddenAssistText.uploadPrerequisitesIcon')}/>
                        </span>
                    </Button>
                }

                <input style={{display: "none"}} id="managedUserServiceUsersCsvUploadInput" type="file" accept=".txt"
                    ref={prerequisitesUploadInputRef} onChange={uploadPrerequisites} disabled={isDisabled}/>
            </div>
        </div>
    )
}

export default DetailsPane;
