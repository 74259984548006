import React from "react";
import ParameterModel from "../../models/library/ParameterModel";
import WorkflowBuilderOperation from "../../models/workflowbuilder/WorkflowBuilderOperation";
import GuidedJobModel from "../../models/guidedjob/GuidedJobModel";
import GuidedJobForm from "../guidedJob/GuidedJobForm";
import {getValues} from "../../utilities/helperFunctions";
import {getPurviewUseCaseGuide} from "./panes/PurviewUseCasePane";
import {getPurviewAddCustodialDataSourcesGuide} from "./panes/PurviewAddCustodialDataSourcesPane";
import {getPurviewAddNonCustodialDataSourcesGuide} from "./panes/PurviewAddNonCustodialDataSourcesPane";
import {getPurviewAddToSearchGuide} from "./panes/PurviewAddToSearchPane";
import {getPurviewAddToReviewSetGuide} from "./panes/PurviewAddToReviewSetPane";
import {getPurviewExportReviewSetGuide} from "./panes/PurviewExportReviewSetPane";
import {getPurviewSelectCustodiansGuide} from "./panes/PurviewSelectCustodiansPane";
import {getPurviewSelectNonCustodialDataSourcesGuide} from "./panes/PurviewSelectNonCustodialDataSourcesPane";
import {operationFieldParameterNames} from "../guidedJob/operationFieldParameterNames";
import {getPurviewSelectExportGuide} from "./panes/PurviewSelectExportPane";
import {useTranslation} from "react-i18next";

export const purviewJobParameterNames = {
    caseName: '{purview_case_name}',
    custodianIds: '{purview_custodian_ids}',
    custodialDataSourceODataIds: '{purview_custodial_data_source_odata_ids}',
    nonCustodialDataSourceODataIds: '{purview_non_custodial_data_source_odata_ids}',
    nonCustodialDataSourceIds: '{purview_non_custodial_data_source_ids}',
    selectedCustodianIds: '{purview_selected_custodian_ids}',
    selectedNonCustodialDataSourceIds: '{purview_selected_non_custodial_data_source_ids}',
    searchId: '{purview_search_id}',
    reviewSetId: '{purview_review_set_id}'
};

for (const name of getValues(purviewJobParameterNames)) {
    operationFieldParameterNames.add(name);
}

function PurviewGuidedJobForm() {
    const {t} = useTranslation(['guidedJob']);

    return (
        <GuidedJobForm requiredParameterTypes={[ParameterModel.Type.PURVIEW_SERVICE]}
            getGuides={getPurviewOperationGuides} closeButtonAriaLabel={t('guidedJob:option.closePurviewGuidedJob')}/>
    )
}

function getPurviewOperationGuides(aliasToOperation, props) {
    const workflowActions = getPurviewWorkflowActions(aliasToOperation);
    const guideProps = {workflowActions, ...props};
    const guides = [];

    if (workflowActions.setCase) {
        const operation = aliasToOperation[WorkflowBuilderOperation.Alias.SET_PURVIEW_CASE];
        guides.push(getPurviewUseCaseGuide(operation, guideProps));
    }
    if (workflowActions.addCustodialDataSources) {
        const operation = aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_ADD_CUSTODIAL_DATA_SOURCES];
        guides.push(getPurviewAddCustodialDataSourcesGuide(operation, guideProps));
    }
    if (workflowActions.addNonCustodialDataSources) {
        const operation = aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_ADD_NON_CUSTODIAL_DATA_SOURCES];
        guides.push(getPurviewAddNonCustodialDataSourcesGuide(operation, guideProps));
    }
    if (workflowActions.addToSearch) {
        const operation = aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_ADD_TO_SEARCH];
        guides.push(getPurviewAddToSearchGuide(operation, guideProps));
    }
    if (workflowActions.addToReviewSet) {
        const operation = aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_ADD_TO_REVIEW_SET];
        guides.push(getPurviewAddToReviewSetGuide(operation, guideProps));
    }
    if (workflowActions.exportReviewSet) {
        const operation = aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_EXPORT_REVIEW_SET];
        guides.push(getPurviewExportReviewSetGuide(operation, guideProps));
    }
    if (workflowActions.selectExports) {
        let operation = aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_SET_EXPORT];
        if (operation == null) operation = aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_DOWNLOAD_EXPORT];
        guides.push(getPurviewSelectExportGuide(operation, guideProps));
    }
    if (workflowActions.selectCustodians) {
        let operation = aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_APPLY_HOLD_TO_CUSTODIANS];
        if (operation == null) operation = aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_REMOVE_HOLD_FROM_CUSTODIANS];
        if (operation == null) operation = aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_RELEASE_CUSTODIANS];
        guides.push(getPurviewSelectCustodiansGuide(operation, guideProps));
    }
    if (workflowActions.selectNonCustodialDataSources) {
        let operation = aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_APPLY_HOLD_TO_NON_CUSTODIAL_DATA_SOURCES];
        if (operation == null) operation = aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_REMOVE_HOLD_FROM_NON_CUSTODIAL_DATA_SOURCES];
        if (operation == null) operation = aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_RELEASE_NON_CUSTODIAL_DATA_SOURCES];
        guides.push(getPurviewSelectNonCustodialDataSourcesGuide(operation, guideProps));
    }
    return guides;
}

function getPurviewWorkflowActions(aliasToOperation) {
    const actions = {};

    actions.setCase = GuidedJobModel.validGuideAction(aliasToOperation[WorkflowBuilderOperation.Alias.SET_PURVIEW_CASE]);
    actions.addCustodialDataSources = GuidedJobModel.validGuideAction(aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_ADD_CUSTODIAL_DATA_SOURCES], true);
    actions.addNonCustodialDataSources = GuidedJobModel.validGuideAction(aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_ADD_NON_CUSTODIAL_DATA_SOURCES], true);

    actions.applyHoldToCustodians = GuidedJobModel.validGuideAction(aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_APPLY_HOLD_TO_CUSTODIANS], true);
    actions.applyHoldToNonCustodialDataSources = GuidedJobModel.validGuideAction(aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_APPLY_HOLD_TO_NON_CUSTODIAL_DATA_SOURCES], true);

    actions.addToSearch = GuidedJobModel.validGuideAction(aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_ADD_TO_SEARCH]);
    actions.addToReviewSet = GuidedJobModel.validGuideAction(aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_ADD_TO_REVIEW_SET]);
    actions.exportReviewSet = GuidedJobModel.validGuideAction(aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_EXPORT_REVIEW_SET]);

    actions.releaseCustodians = GuidedJobModel.validGuideAction(aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_RELEASE_CUSTODIANS], true);
    actions.releaseNonCustodialDataSources = GuidedJobModel.validGuideAction(aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_RELEASE_NON_CUSTODIAL_DATA_SOURCES], true);

    actions.removeHoldFromCustodians = GuidedJobModel.validGuideAction(aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_REMOVE_HOLD_FROM_CUSTODIANS], true);
    actions.removeHoldFromNonCustodialDataSources = GuidedJobModel.validGuideAction(aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_REMOVE_HOLD_FROM_NON_CUSTODIAL_DATA_SOURCES], true);

    actions.selectCustodians = !actions.addCustodialDataSources
        && (actions.applyHoldToCustodians || actions.releaseCustodians || actions.removeHoldFromCustodians);

    actions.selectNonCustodialDataSources = !actions.addNonCustodialDataSources
        && (actions.applyHoldToNonCustodialDataSources || actions.releaseNonCustodialDataSources || actions.removeHoldFromNonCustodialDataSources);

    actions.createNewCase = [WorkflowBuilderOperation.Alias.PURVIEW_ADD_CUSTODIAL_DATA_SOURCES, WorkflowBuilderOperation.Alias.PURVIEW_ADD_NON_CUSTODIAL_DATA_SOURCES]
        .some(alias => aliasToOperation[alias] != null);

    actions.exportToAzureStorage = [WorkflowBuilderOperation.Alias.AZURE_STORAGE_ACCOUNT_CONFIGURE_CONNECTION, WorkflowBuilderOperation.Alias.AZURE_STORAGE_ACCOUNT_CREATE_CONTAINER,
        WorkflowBuilderOperation.Alias.AZURE_STORAGE_ACCOUNT_GENERATE_SAS_TOKEN].every(alias => aliasToOperation[alias] != null && !aliasToOperation[alias].disabled);

    const addToReviewSetOperation = aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_ADD_TO_REVIEW_SET];
    actions.selectExistingReviewSetPane = (addToReviewSetOperation == null || addToReviewSetOperation.disabled) && actions.exportReviewSet;

    actions.downloadExport = GuidedJobModel.validGuideAction(aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_DOWNLOAD_EXPORT]);

    const exportReviewSetOperation = aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_EXPORT_REVIEW_SET];
    actions.selectExports = (exportReviewSetOperation == null || exportReviewSetOperation.disabled) &&
        (GuidedJobModel.validGuideAction(aliasToOperation[WorkflowBuilderOperation.Alias.PURVIEW_SET_EXPORT]) || actions.downloadExport);

    return actions;
}

export default PurviewGuidedJobForm;
