import React, {useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getLocaleDateTimeFromUTC, getNonEmptyValueOrDefault} from "../../../utilities/helperFunctions";
import PurviewCache from "../../../models/guidedjob/PurviewCache";
import SelectableTable from "../../common/Tables/SelectableTable";
import {StatusNameDescriptionCell, TableLabelCell} from "../../common/CustomTable/CustomTable";
import Text from "../../common/Text/Text";
import TextArea from "../../common/TextArea/TextArea";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import WorkflowBuilderOperation from "../../../models/workflowbuilder/WorkflowBuilderOperation";
import PurviewOperationPane from "./PurviewOperationPane";
import {objectIdentifierType} from "../../../utilities/constants";
import {buildOperationFieldParameterNames} from "../../guidedJob/operationFieldParameterNames";
import {purviewJobParameterNames} from "../PurviewGuidedJobForm";
import RadioButton from "../../common/RadioButton/RadioButton";


const fieldNames = ['caseIdentifierType', 'caseIdentifier', 'createCaseIfNotExists', 'caseDescription', 'caseExternalId'];
export const purviewUseCaseParameterNames = buildOperationFieldParameterNames(WorkflowBuilderOperation.Alias.SET_PURVIEW_CASE, fieldNames);

function getNextEnabled(getParameter) {
    const caseIdentifierType = getParameter(purviewUseCaseParameterNames.caseIdentifierType);
    const caseIdentifier = getParameter(purviewUseCaseParameterNames.caseIdentifier);

    return {
        useCase: objectIdentifierType[caseIdentifierType.value] != null && caseIdentifier.value.length > 0
    };
}

export function getPurviewUseCaseGuide(operation, props) {
    const {
        workflowActions,
        getParameter,
        updateParameter
    } = props;

    const caseIdentifierType = getParameter(purviewUseCaseParameterNames.caseIdentifierType);
    const caseIdentifier = getParameter(purviewUseCaseParameterNames.caseIdentifier);
    const createNewCase = caseIdentifierType.value !== objectIdentifierType.ID;

    if (createNewCase) {
        if (workflowActions.createNewCase) {
            updateParameter(purviewUseCaseParameterNames.caseIdentifierType, objectIdentifierType.NAME);
        } else {
            updateParameter(purviewUseCaseParameterNames.caseIdentifierType, objectIdentifierType.ID);
            if (!caseIdentifier.value) {
                updateParameter(purviewUseCaseParameterNames.caseIdentifier, '');
            }
        }
    }

    return {
        getPanes: function(t, props) {
            const setPurviewCaseTitle = getNonEmptyValueOrDefault(operation.notes, t('guidedJob:panes.setPurviewCase'));
            const nextEnabled = getNextEnabled(props.getParameter, workflowActions);

            return [{
                title: setPurviewCaseTitle,
                isNextEnabled: nextEnabled.useCase,
                component: <PurviewOperationPane {...props}
                    workflowActions={workflowActions} OperationPane={PurviewUseCasePane}/>
            }];
        }
    };
}

function PurviewUseCasePane(props) {
    const {t} = useTranslation(['guidedJob', 'common']);
    const {
        guidedJobForm,
        workflowActions,
        proxyPurviewRequest,
        getParameter,
        updateParameter,
        updateState,
        isDisabled
    } = props;

    const casesKey = PurviewCache.getResourceKey(PurviewCache.Resource.CASES);
    const eDiscoveryCases = useSelector(state => state.purviewCache[casesKey]) || {};
    const isLoadingCases = eDiscoveryCases.cache == null;

    const caseIdentifierType = getParameter(purviewUseCaseParameterNames.caseIdentifierType);
    const caseIdentifier = getParameter(purviewUseCaseParameterNames.caseIdentifier);
    const description = getParameter(purviewUseCaseParameterNames.caseDescription);
    const number = getParameter(purviewUseCaseParameterNames.caseExternalId);

    const useExistingCase = caseIdentifierType.value === objectIdentifierType.ID;
    const createNewCase = !useExistingCase;
    const isCreateCaseDisabled = isDisabled || !workflowActions.createNewCase;

    const isCaseIdentifierTypeDisabled = isDisabled || getParameter(purviewUseCaseParameterNames.caseIdentifierTypeDisabled, false, {parse: true}).value;
    const isCaseIdentifierDisabled = isCreateCaseDisabled || getParameter(purviewUseCaseParameterNames.caseIdentifierDisabled, false, {parse: true}).value;
    const isExternalIdDisabled = isCreateCaseDisabled || getParameter(purviewUseCaseParameterNames.caseExternalIdDisabled, false, {parse: true}).value;

    const headers = useRef([
        {label: t('common:label.name')},
        {label: t('common:label.createdDate'), noStretch: true},
        {label: t('common:label.lastModifiedDate'), noStretch: true}
    ]);

    const matterName = useSelector(state => (state.matterDetailsMap.get(guidedJobForm.matterId) || {}).name);
    useEffect(() => {
        if (createNewCase && matterName) {
            if (!caseIdentifier.value || caseIdentifier.value === guidedJobForm.prevMatterName) {
                updateParameter(purviewUseCaseParameterNames.caseIdentifier, matterName);
                updateState({prevMatterName: matterName});
            }
        }
    }, [matterName]);

    useEffect(() => {
        if (useExistingCase) {
            proxyPurviewRequest(PurviewCache.Resource.CASES);
        }
        updateParameter(purviewUseCaseParameterNames.createCaseIfNotExists, JSON.stringify(!useExistingCase));
    }, [useExistingCase]);

    function inputHandler(event) {
        const {name, value} = event.target;
        updateParameter(name, value);
        if (name === purviewUseCaseParameterNames.caseIdentifierType && caseIdentifierType.value !== value) {
            updateParameter(purviewUseCaseParameterNames.caseIdentifier, '');

        } else if (name === purviewUseCaseParameterNames.caseIdentifier && !useExistingCase) {
            updateParameter(purviewJobParameterNames.caseName, value);
        }
    }

    function valueHandler(event) {
        const {name, value, displayname} = event.currentTarget.dataset;
        updateParameter(name, value);

        if (useExistingCase){
            updateParameter(purviewJobParameterNames.caseName, displayname);
        }
    }


    return (
        <>
            <div className="flex-column" style={{marginTop: '0.5rem'}}>
                <RadioButton name={purviewUseCaseParameterNames.caseIdentifierType} label={t(`guidedJob:option.createNewPurviewCase`)}
                    style={{marginTop: '0.5rem', marginBottom: '1rem'}} selected={caseIdentifierType.value} value={objectIdentifierType.NAME}
                    onClick={inputHandler} isDisabled={isCaseIdentifierTypeDisabled || isCreateCaseDisabled}/>
                <RadioButton name={purviewUseCaseParameterNames.caseIdentifierType} label={t(`guidedJob:option.useExistingPurviewCase`)}
                    selected={caseIdentifierType.value} value={objectIdentifierType.ID} onClick={inputHandler} isDisabled={isCaseIdentifierTypeDisabled}/>
            </div>

            {createNewCase &&
                <div>
                    <div className="display-input">
                        <HTMLTextInput label={t('guidedJob:label.caseName')} name={purviewUseCaseParameterNames.caseIdentifier}
                            value={caseIdentifier.value} onChange={inputHandler} isRequired isDisabled={isCaseIdentifierDisabled}/>
                    </div>

                    <div className="display-input">
                        <HTMLTextInput label={t('guidedJob:label.caseNumber')} name={purviewUseCaseParameterNames.caseExternalId}
                            value={number.value} onChange={inputHandler} isDisabled={isExternalIdDisabled}/>
                    </div>

                    <div className="display-input">
                        <TextArea label={t('guidedJob:label.description')} name={purviewUseCaseParameterNames.caseDescription}
                            value={description.value} onChange={inputHandler} isDisabled={isCreateCaseDisabled}/>
                    </div>
                </div>
            }

            {useExistingCase &&
                <div className="display-input">
                    <div style={{display: 'flex', marginBottom: '0.25rem'}}>
                        <Text value={`${t('guidedJob:label.purviewCase')}:`} style={{marginRight: '0.5rem'}} isDisabled={isDisabled}/>
                        <Text value={caseIdentifier.value} isDisabled={isDisabled}/>
                    </div>

                    <SelectableTable name={purviewUseCaseParameterNames.caseIdentifier} items={eDiscoveryCases.cache} headers={headers.current}
                        selectedItemId={caseIdentifier.value} onItemSelect={valueHandler} isLoading={isLoadingCases} isDisabled={isDisabled}
                        emptyMessage={t('guidedJob:message.purviewServiceHasNoCases')} isStale={eDiscoveryCases.stale} isRequired
                        RowComponent={props => (
                            <>
                                <StatusNameDescriptionCell name={props.displayName} description={props.description}/>
                                <TableLabelCell label={getLocaleDateTimeFromUTC(props.createdDate)}/>
                                <TableLabelCell label={getLocaleDateTimeFromUTC(props.lastModifiedDate)}/>
                            </>
                        )}
                    />
                </div>
            }
        </>
    )
}
