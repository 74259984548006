import React from "react";
import {getLocaleDateTimeFromUTC} from "../../utilities/helperFunctions";
import {useSelector} from "react-redux";
import {StatusNameDescriptionCell, TableLabelCell, TableRow} from "../common/CustomTable/CustomTable";
import {useTranslation} from "react-i18next";
import Text from "../common/Text/Text";
import LegalHoldModel from "../../models/legalhold/LegalHoldModel";
import {statusKeys} from "../../i18next/keys";


function LegalHoldRow(props) {
    const {t} = useTranslation(['legalHold']);

    const {
        isActive,
        legalHoldId,
        onSelect,
    } = props;

    const legalHold =  useSelector(state => state.legalHoldDetailsMap.get(legalHoldId));

    const {
        name,
        description,

        state,
        startDate,
        endDate,

        taskState,
        taskPercentProgress,

        status,
        emailCounter
    } = legalHold;

    const isLegalHoldBusy = taskState && taskState !== LegalHoldModel.TaskState.DONE;
    const showPercentage = isLegalHoldBusy && taskPercentProgress != null;

    let _status = status;
    if (_status == null || !statusErrorCodes.includes(status.code)) {
        if (emailCounter != null && statusErrorCodes.includes(emailCounter.status.code)) {
            _status = emailCounter.status;
        }
    }

    return (
        <TableRow value={legalHoldId} isActive={isActive} onClick={onSelect}>
            <div className="table-cell">
                {isLegalHoldBusy ?
                    <Text value={t(`legalHold:taskState.${taskState}`)}/>
                :
                    <Text value={t(`legalHold:state.${state}`)} isEllipsis/>
                }
            </div>

            <StatusNameDescriptionCell name={name} status={_status}
                description={description}/>

            <TableLabelCell label={getLocaleDateTimeFromUTC(startDate)}/>

            <TableLabelCell label={getLocaleDateTimeFromUTC(endDate)}/>
        </TableRow>
    )
}

const statusErrorCodes = [statusKeys.ERROR, statusKeys.WARNING];

export default LegalHoldRow;
