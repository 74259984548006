import React from 'react';
import {ListDropdown} from "../../../../components/common/Dropdown/Dropdown";
import {MailType} from "../../../utilities/constants";
import {useTranslation} from "react-i18next";

function MailTypeDropdown(props) {
    const {t} = useTranslation(['aria', 'wizard']);

    const {selectedMailType, onMailTypeSelected, ...rest} = props;
    const mailTypeValues = [
        {value: MailType.NATIVE, name: t('wizard:questions.discover.mailType.NATIVE')},
        {value: MailType.EML, name: t('wizard:questions.discover.mailType.EML')},
        {value: MailType.HTML, name: t('wizard:questions.discover.mailType.HTML')},
        {value: MailType.MIME_HTML, name: t('wizard:questions.discover.mailType.MIME_HTML')},
        {value: MailType.MSG, name: t('wizard:questions.discover.mailType.MSG')},
        {value: MailType.DXL, name: t('wizard:questions.discover.mailType.DXL')},
        {value: MailType.MBOX, name: t('wizard:questions.discover.mailType.MBOX')},
        {value: MailType.PST, name: t('wizard:questions.discover.mailType.PST')},
        {value: MailType.NSF, name: t('wizard:questions.discover.mailType.NSF')},
    ]

    return (
        <ListDropdown id={'mailTypeDropdown'} name={'mailType'}
            aria-label={t('aria:hiddenAssistText.endpointType')} value={selectedMailType}
                        items={mailTypeValues} onItemSelect={onMailTypeSelected}
                        buttonStyle={{marginLeft: '0.5rem'}}
                        {...rest}
        />
    )
}

export default MailTypeDropdown;