import React from "react";
import './Text.css';
import {buildClassName} from "../../../utilities/helperFunctions";

const Text = React.forwardRef(function (props, ref) {

    const {
        text,
        value=text,
        children,
        className,
        ElementTag='label',

        size,
        isNote,
        isCentered,
        isCenter=isCentered,
        isEllipsis,
        isWordWrap,
        isBreakWord,
        isNoWrap,
        isHeading,
        isBold,
        isItalic,
        isReadOnly,
        isDisabled,
        ...attr
    } = props;


    const _className = buildClassName(
        'label',
        size && `is-${size.toLowerCase()}`,
        isCenter && 'is-center',
        isNote && 'is-note',
        isEllipsis && 'is-ellipsis',
        isWordWrap && 'is-wordwrap',
        isBreakWord && 'break-all',
        isNoWrap && 'no-wrap',
        isHeading && 'is-heading',
        isBold && 'is-bold',
        isItalic && 'is-italic',
        isReadOnly && 'is-read-only',
        isDisabled && 'is-disabled',
        className
    );

    return (
        <ElementTag ref={ref} className={_className} {...attr}>
            {value}
            {children}
        </ElementTag>
    );
})

const Label = React.forwardRef((props, ref) => {
    return (
        <Text ref={ref} ElementTag="label" {...props} />
    )
});

const Paragraph = React.forwardRef((props, ref) => {
    return (
        <Text ref={ref} ElementTag="p" isWordWrap
            {...props} />
    )
});

export default Text;
export {Label, Paragraph};
