import {
    DateFilterLevel,
    DateFilterType,
    DiscoverFileRepository,
    RelativityWorkspaceIdentifierType,
    timezones
} from "./constants";

export function validate ({name, values}) {
    switch (name) {
        case "workflowInformation":
            return validateWorkflowInformation (values);
        case "caseSettings":
            return validateCaseSettings (values);
        case "ingestionSettings":
            return validateIngestionSettings (values);
        case "advancedIngestionSettings":
            return validateAdvancedIngestionSettings (values);
        case "legalExportSettings":
            return validateLegalExportSettings(values);
        case "processingReportSettings":
            return validateProcessingReportSettings(values);
        case "relativityConfiguration":
            return validateRelativityConfigurationSettings(values);
        case "discoverConfiguration":
            return validateDiscoverConfigurationSettings(values);
        case "ocrSettings":
            return validateOcrSettings(values);
        default:
            return false;
    }
}


function validateWorkflowInformation (values) {
    const {timezoneRadio, timezoneSelect, useConfigurationProfile, useProcessingProfile, configurationProfilePath, processingProfilePath} = values;
    const validTimezone = (([2, 3].includes(timezoneRadio)) || (timezoneRadio === 1 && timezones.includes(timezoneSelect)));
    const validProfiles = (validateWorkflowProfiles(useConfigurationProfile, configurationProfilePath) && validateWorkflowProfiles(useProcessingProfile, processingProfilePath));
    return (validTimezone && validProfiles);
}

function validateCaseSettings (values) {
    const {caseStructure, baseFolderPath} = values;
    return ((caseStructure === 3) || ([1, 2].includes(caseStructure) && baseFolderPath.length > 1))
}

function validateIngestionSettings (values) {
    const {datasetId, dataStructure, sourceDataFolder} = values;
    return (([2, 3, 4].includes(dataStructure)) || (dataStructure === 1 && (datasetId.length > 0 && sourceDataFolder.length > 0)));
}

function validateAdvancedIngestionSettings (values) {
    const {useDateFilter, dateFilterType, filterAfterDate, filterBeforeDate, dateFilterActionName, dateFilterLevel, keywordsSearch, keywordsFile} = values;
    let validFilterDate = false;

    switch (dateFilterType) {

        case DateFilterType.BETWEEN:
        case DateFilterType.NOT_BETWEEN:
            validFilterDate = (filterAfterDate.length === 24 && filterBeforeDate.length === 24);
            break;

        case DateFilterType.AFTER:
            validFilterDate = (filterAfterDate.length === 24);
            break;

        case DateFilterType.BEFORE:
            validFilterDate = (filterBeforeDate.length === 24);
            break;

        default:
        case DateFilterType.NONE:
            validFilterDate = true;
            break;
    }

    const validActionName = ((dateFilterLevel === DateFilterLevel.INGESTION) || (dateFilterLevel === DateFilterLevel.CASE && dateFilterActionName.length > 0));
    return ((!useDateFilter) || (useDateFilter && validFilterDate && validActionName));
}

function validateLegalExportSettings (values) {
    const {exportType, exportFiletypes, useMetadataProfile, useProductionProfile, metadataProfileName, productionProfilePath, fieldsMappingFileType, fieldsMappingFile, metadataMappingFileType, metadataMappingFile} = values;
    const oneExportType = (exportFiletypes["exportNative"] || exportFiletypes["exportText"] ||
        exportFiletypes["exportPdf"] || exportFiletypes["exportImage"] || exportFiletypes["exportXhtmlMetadataReport"] || exportFiletypes["exportThumbnailFile"])
    const validProfiles = (validateWorkflowProfiles(useMetadataProfile, metadataProfileName) && validateWorkflowProfiles(useProductionProfile, productionProfilePath));

    const validRelativity = (exportType !== "RELATIVITY" || (fieldsMappingFileType !== 3 || fieldsMappingFileType === 3 && fieldsMappingFile.length > 0));
    const validDiscover = (exportType !== "RINGTAIL" || (metadataMappingFileType !== 3 || metadataMappingFileType === 3 && metadataMappingFile.length > 0));

    return ((exportType.length > 0 && oneExportType) && validProfiles && (validRelativity && validDiscover))
}

function validateProcessingReportSettings (values) {
    const {reportType} = values;
    return reportType === 1;
}

function validateRelativityConfigurationSettings (values) {
    const {serviceEndpoint, workspaceIdentifierType, workspaceIdentifier, folderPath, createIfNotExists, importThreads, importThreadTimeout, importThreadRetries, metadataThreads} = values;

    const isFolderPathValid = createIfNotExists || folderPath.length > 0;

    return ((serviceEndpoint.length > 0) && (workspaceIdentifier.length > 0) && isFolderPathValid &&
        (importThreads > 0) && (importThreadTimeout > 0) && (importThreadRetries > 0) && (metadataThreads > 0) &&
        (workspaceIdentifierType === RelativityWorkspaceIdentifierType.NAME || workspaceIdentifierType === RelativityWorkspaceIdentifierType.ARTIFACT_ID));
}

function validateDiscoverConfigurationSettings (values) {
    const {hostname, caseIdentifierTypeId, discoverFileRepositoryType, windowsFileRepository, apiToken, caseIdentifier} = values;

    return ((hostname.length > 0) && (([1, 2].includes(caseIdentifierTypeId) && caseIdentifier.length > 0))
        && (discoverFileRepositoryType === DiscoverFileRepository.AMAZON_S3 || (discoverFileRepositoryType === DiscoverFileRepository.WINDOWS_FILE_SHARE && windowsFileRepository.length > 0))
        && (apiToken.length > 0));
}

function validateOcrSettings (values) {
    const {performOcr, defineProfileType, ocrProfileName} = values;
    return (!performOcr || (defineProfileType !== 2 || defineProfileType === 2 && ocrProfileName.length > 0))
}


// Helper functions
export function validateProfileName (profileName, extension) {
    return profileName.endsWith(extension);
}

export function validateWorkflowProfiles (profile, value) {
    return ((!profile) || (profile && value.length > 0))
}