import SubsetDetailsModel, {SubsetDetailsSaga} from "../generics/SubsetDetailsModel";
import {actionCreator} from "../../utilities/helperFunctions";
import {axiosInstance} from "../api/AxiosProxy";
import {contextCall, contextSaga, isDisabledWrapper} from "../../saga/sagaFunctions";
import {all, put, select, takeLeading} from "redux-saga/effects";
import ComponentStateModel from "../generics/ComponentStateModel";
import PopupModel from "../scheduler/PopupModel";
import {permissionKeys, popupInfoKeys} from "../../i18next/keys";
import SchedulerModel from "../scheduler/SchedulerModel";
import {tryCatchWrapper} from "../../saga/tryCatchWrapper";
import UserNoticeModel, {UserNoticeSaga} from "./UserNoticeModel";


class NoticeCommentModel extends SubsetDetailsModel {

    static nom = 'NoticeCommentModel';
    static parentKey = 'noticeEventId';

    static actions = NoticeCommentModel.buildActions('NOTICE_COMMENT');
    static actionCreators = NoticeCommentModel.buildActionCreators(NoticeCommentModel.actions);
    static reducer = NoticeCommentModel.buildReducer(NoticeCommentModel.actions);

    static componentActionCreators = {
        ...NoticeCommentModel.buildComponentActionCreators()
    };

    constructor(props) {
        super(props);
        const {type, noticeEventId, parentCommentId, userId, message, createdDate} = props;

        this.type = type;
        this.noticeEventId = noticeEventId;
        this.parentCommentId = parentCommentId;
        this.userId = userId;
        this.username = props.username;
        this.message = message;
        this.createdDate = createdDate;
    }

    static buildActionCreators(actions) {
        return {
            ...super.buildActionCreators(actions),
            submitForm: actionCreator(actions.SUBMIT_FORM, 'noticeId', 'parentId', 'submit', 'formData')
        }
    }


    static buildComponentActionCreators() {
        const components = [
            {
                key: 'noticeCommentDisplay',
                type: 'Display',
                state: {
                    isNoticeCommentFormActive: false
                }
            },
            {
                key: 'noticeCommentForm',
                type: 'Form',
                state: {
                    type: null,
                    message: '',
                    isAddEnabled: false,
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }
}

export class NoticeCommentApi {

    static post(noticeId, id, comment) {
        return axiosInstance.post(`/scheduler/notices/${noticeId}/events/${id}/comments`, comment);
    }
}

export class NoticeCommentSaga extends SubsetDetailsSaga {

    static ModelType = NoticeCommentModel;
    static ModelApi = NoticeCommentApi;

    static activationComponent = 'LEGAL_HOLD_PAGE';
    static variableNames = {
        detailsMap: 'noticeCommentDetailsMap',
        instanceId: 'noticeCommentId',
        isFormActive: 'isNoticeCommentFormActive',
        updateDisplay: 'updateDisplay'
    };

    static translations = {
        itemTitle: '$t(notice:label.comment)',
        itemLower: '$t(notice:label.comment_lower)'
    };

    static buildActivationEffects(dispatch) {
        return [
            // ACTIVATION EFFECTS
            takeLeading(this.ModelType.actions.SHOW_FORM, contextSaga(this, 'showForm')),
            takeLeading(this.ModelType.actions.HIDE_FORM, contextSaga(this, 'hideForm')),
            takeLeading(this.ModelType.actions.SUBMIT_FORM, tryCatchWrapper, isDisabledWrapper, this.ModelType.componentActionCreators.updateForm, contextSaga(this, 'submitForm'))
        ];
    }

    static* callCommentFormDiscardingEffect(effect) {
        const yieldEffectAndHideForm = all([
            effect,
            put(NoticeCommentModel.actionCreators.hideForm())
        ]);

        const [{isNoticeCommentFormActive}, {message}] = yield all([
            select(state => state.componentStates.noticeCommentDisplay),
            select(state => state.componentStates.noticeCommentForm)
        ]);

        // If comment form active and message defined
        if (isNoticeCommentFormActive && !!message) {
            // Show discard warning
            yield put(PopupModel.actionCreators.showWarning({
                info: {
                    key: popupInfoKeys.DISCARD_COMMENT
                },
                buttons: [{
                    titleKey: 'common:option.discard',
                    onClick: () => this.dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(yieldEffectAndHideForm))
                }]
            }));

        } else {
            yield yieldEffectAndHideForm;
        }
    }

    static* submitForm(action) {
        const {noticeId, parentId, submit, formData} = yield action.payload;
        const saveValues = yield contextCall(this, 'getSaveValues', formData);

        const {data} = yield contextCall(NoticeCommentApi, 'post', noticeId, parentId, saveValues);
        yield all([
            put(NoticeCommentModel.actionCreators.addDetails(data)),
            put(NoticeCommentModel.actionCreators.hideForm())
        ]);

        const userNotice = yield select(state => state.userNoticeDetailsMap.get(parentId));
        // Update outstanding if admin
        if (userNotice.userPermissions.includes(permissionKeys.MANAGE)) {
            yield put(UserNoticeModel.actionCreators.updateDetails({[parentId]: {outstanding: false}}));
        }

        switch (submit) {
            case "next":
                yield contextCall(UserNoticeSaga, 'showNextOutstandingNotice', parentId);
        }
    }

    static* getEditValues(id) {
        const comment = yield select(state => state.noticeCommentDetailsMap.get(id));

        return {
            ...comment
        }
    }

    static getSaveValues(values) {
        return values;
    }
}

export default NoticeCommentModel;
