import {getObjectText, getValues, isSomeTruthy} from "../../utilities/helperFunctions";
import i18next from "i18next";
import {SAME_AS_TRIGGERING_JOB} from "../../utilities/constants";
import {jobPageViewKeys} from "../../i18next/keys";

export function selectJobFromDisplay(state, id) {
    const isArchiveMode = state.componentStates.jobPage.activeView === jobPageViewKeys.ARCHIVE;
    let job;
    if (isArchiveMode) {
        job = state.jobArchiveMap.get(id);
    } else {
        job = state.jobDetailsMap.get(id);
    }
    return job || {};
}

const typesUsedInJobs = ['client', 'matter', 'library', 'workflowTemplate', 'executionProfile',
    'resourcePool', 'engine'];
export function selectDetailsMapForJobNames(state) {
    return typesUsedInJobs.reduce((acc, curr) => {
        acc[curr] = state[`${curr}DetailsMap`];
        return acc;
    }, {});
}

export function selectNamesFromJob(detailsMap, job) {
    // Get details from respective detailsMap
    const names = typesUsedInJobs
        .reduce((typeToName, type) => {
            const details = detailsMap[type].get(job[`${type}Id`]);
            typeToName[type] = (details && details.name);
            return typeToName;
        }, {});

    // Remote engines
    names.remoteEngines = (job.workerAgentIds || [])
        .map(id => (detailsMap.engine.get(id) || {}).name)
        .filter(name => !!name)
        .join(', ');

    return names;
}

export function selectJobText(job, state) {
    const names = getValues(selectNamesFromJob(state, job)).join('');
    return (getObjectText(job) + names).toLowerCase();
}

export function selectJobScheduleText(state, jobSchedule) {
    // Get names
    const names = getValues(selectNamesFromJob(state, jobSchedule)).join('');

    let jobScheduleInfoText = '';
    // Same as triggering job text
    if (isSomeTruthy([jobSchedule.sameMatterAsTriggerMatter, jobSchedule.sameWorkflowTemplateAsTriggerMatter,
        jobSchedule.sameExecutionProfileAsTriggerMatter, jobSchedule.sameResourcePoolIdAsTriggerMatter, jobSchedule.samePriorityAsTriggerMatter])) {

        jobScheduleInfoText += i18next.t(`jobSchedule:option.${SAME_AS_TRIGGERING_JOB}`);
    }

    return (getObjectText(jobSchedule) + names + jobScheduleInfoText).toLowerCase();
}