import React from "react";
import {useTranslation} from "react-i18next";
import {SettingsRowSeparator, SettingsRowValue} from "../../common/CustomTable/CustomTable";
import ExpandableContent from "../../common/ExpandableContent/ExpandableContent";
import Text from "../../common/Text/Text";
import {buildClassName} from "../../../utilities/helperFunctions";

function SmtpServiceSettings(props) {
    const {t} = useTranslation(['thirdPartyService']);
    const {
        thirdPartyService,
        style,
        isDisabled
    } = props;

    return (
        <>
            <ExpandableContent label={t('thirdPartyService:label.smtpSettings')}
                style={style} isDisabled={isDisabled}
            >
                <div className="settings-table">
                    <div className="table-row-group">
                        <SettingsRowValue label={t('thirdPartyService:label.host')}
                            value={thirdPartyService.host} isDisabled={isDisabled}/>

                        <SettingsRowValue label={t('thirdPartyService:label.port')}
                            value={thirdPartyService.port} isDisabled={isDisabled}/>

                        <SettingsRowValue label={t('thirdPartyService:label.from')}
                            value={thirdPartyService.from} isDisabled={isDisabled}/>

                        {thirdPartyService.tls &&
                            <Text value={t('thirdPartyService:label.tlsEnabled')} isDisabled={isDisabled}/>
                        }

                        {(thirdPartyService.emailRetryInterval || thirdPartyService.emailMaxAttempts) &&
                            <SettingsRowSeparator/>
                        }
                        {thirdPartyService.emailRetryInterval &&
                            <SettingsRowValue label={t('thirdPartyService:label.emailRetryInterval')}
                                value={t('common:label.hours_value', {count: thirdPartyService.emailRetryInterval})} isDisabled={isDisabled}/>
                        }

                        <SettingsRowValue label={t('thirdPartyService:label.emailMaxAttempts')}
                            value={thirdPartyService.emailMaxAttempts} isDisabled={isDisabled}/>
                    </div>
                </div>
            </ExpandableContent>
        </>
    )
}

export function SmtpEmailCounterSettings(props) {
    const {t} = useTranslation(['thirdPartyService']);
    const {
        emailCounter,
        style,
        isDisabled
    } = props;

    const tableClassName = buildClassName(
        'tight-padding',
        isDisabled && 'is-disabled'
    );

    return (
        <ExpandableContent label={t('thirdPartyService:label.emailCounts')}
            style={style} isDisabled={isDisabled}
        >
            <table className={tableClassName}>
                <thead className="table-header-group">
                <tr>
                    <th className="table-header">
                        <Text value={t('thirdPartyService:label.state')} isBold/>
                    </th>
                    <th className="table-header">
                        <Text value={t('thirdPartyService:label.count')} isBold/>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="table-cell">
                        <Text value={t('thirdPartyService:label.pending')}/>
                    </td>
                    <td className="table-cell">
                        <Text value={emailCounter.pendingEmailCount}/>
                    </td>
                </tr>
                <tr>
                    <td className="table-cell">
                        <Text value={t('thirdPartyService:label.sent')}/>
                    </td>
                    <td className="table-cell">
                        <Text value={emailCounter.sentEmailCount}/>
                    </td>
                </tr>
                <tr>
                    <td className="table-cell">
                        <Text value={t('thirdPartyService:label.error')}/>
                    </td>
                    <td className="table-cell">
                        <Text value={emailCounter.errorEmailCount}/>
                    </td>
                </tr>
                <tr>
                    <td className="table-cell">
                        <Text value={t('thirdPartyService:label.purged')}/>
                    </td>
                    <td className="table-cell">
                        <Text value={emailCounter.purgedEmailCount}/>
                    </td>
                </tr>
                </tbody>
            </table>
        </ExpandableContent>
    )
}

export default SmtpServiceSettings;
