import {capitalizeFirstLetter, objEquals} from "../../utilities/helperFunctions";

class ComponentStateModel {

    static nom = 'ComponentStateModel';
    static actions = ComponentStateModel.buildActions();
    static reducer = ComponentStateModel.buildReducer();

    static buildActions() {
        return {
            // COMPONENT STATE ACTIONS
            UPDATE_COMPONENT_STATE: 'UPDATE_COMPONENT_STATE',
            RESET_COMPONENT_STATE: 'RESET_COMPONENT_STATE',
            // SET COMPONENT ACTIVE ACTIONS
            SET_COMPONENT_ACTIVE: 'SET_COMPONENT_ACTIVE',
            SET_COMPONENT_INACTIVE: 'SET_COMPONENT_INACTIVE'
        }
    }

    // {key, type, state}
    static buildUpdateActionCreators(...components) {
        const actionCreators = {};
        for (let i = 0; i < components.length; i++) {
            const c = components[i];
            // COMPONENT INITIAL STATE
            ComponentStateModel.initialState = {
                ...ComponentStateModel.initialState,
                [c.key]: c.state
            };

            // COMPONENT STATE ACTION CREATORS
            actionCreators[`update${capitalizeFirstLetter(c.type)}`] = updates => ({
                type: ComponentStateModel.actions.UPDATE_COMPONENT_STATE,
                payload: {component: c.key, updates}
            });
            actionCreators[`reset${capitalizeFirstLetter(c.type)}`] = initialState => ({
                type: ComponentStateModel.actions.RESET_COMPONENT_STATE,
                payload: {component: c.key, initialState}
            });
        }

        return actionCreators;
    }

    static buildSetActiveActionCreators(...components) {
        const actionCreators = [];
        for (let i = 0; i < components.length; i++) {
            const c = components[i];
            // SET ACTIVE COMPONENT ACTION CREATORS
            actionCreators[`set${capitalizeFirstLetter(c.type)}Active`] = () => ({
                type: ComponentStateModel.actions.SET_COMPONENT_ACTIVE,
                payload: {component: c.key}
            });
            actionCreators[`set${capitalizeFirstLetter(c.type)}Inactive`] = () => ({
                type: ComponentStateModel.actions.SET_COMPONENT_INACTIVE,
                payload: {component: c.key}
            });
        }

        return actionCreators;
    }

    static buildReducer() {
        return function (state = ComponentStateModel.initialState, action) {
            switch (action.type) {
                case ComponentStateModel.actions.UPDATE_COMPONENT_STATE: {
                    const {component, updates} = action.payload;

                    const oldComponentState = state[component];
                    const newComponentState = {
                        ...oldComponentState,
                        ...((typeof updates === 'function') ? updates(oldComponentState) : updates)
                    };

                    if (!objEquals(oldComponentState, newComponentState)) {
                        return {
                            ...state,
                            [component]: newComponentState
                        }
                    }

                    return state;
                }
                case ComponentStateModel.actions.RESET_COMPONENT_STATE: {
                    const {component, initialState} = action.payload;

                    return {
                        ...state,
                        [component]: {
                            ...ComponentStateModel.initialState[component],
                            ...initialState
                        }
                    };
                }
                default: {
                    return state;
                }
            }
        }
    }
}

export default ComponentStateModel;