import SettingModel from "../generics/SettingModel";

class JobSoftErrorsModel extends SettingModel.Simple {

    static nom = 'JobSoftErrorsModel';
    static actions = JobSoftErrorsModel.buildActions('JOB_SOFT_ERRORS');
    static actionCreators = JobSoftErrorsModel.buildActionCreators(JobSoftErrorsModel.actions);
    static reducer = JobSoftErrorsModel.buildReducer(JobSoftErrorsModel.actions);

}

export default JobSoftErrorsModel;