import React, {useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {buildFakeEvent, getKeys, getNonEmptyValueOrDefault, stringToBool} from "../../../utilities/helperFunctions";
import {
    purviewDataLocationType,
    purviewDataSourceInputType,
    userServiceObjectTypes
} from "../../../utilities/constants";
import {ListDropdown} from "../../common/Dropdown/Dropdown";
import DataSourcesTable from "../DataSourcesTable";
import PurviewOperationPane from "./PurviewOperationPane";
import WorkflowBuilderOperation from "../../../models/workflowbuilder/WorkflowBuilderOperation";
import {blobUrlDownload} from "../../../utilities/downloadHelper";
import Papa from "papaparse";
import {purviewJobParameterNames} from "../PurviewGuidedJobForm";
import Checkbox from "../../common/Checkbox/Checkbox";
import {buildOperationFieldParameterNames} from "../../guidedJob/operationFieldParameterNames";
import GuidedJobModel from "../../../models/guidedjob/GuidedJobModel";
import {ThirdPartyServiceApi} from "../../../models/thirdparty/ThirdPartyServiceModel";
import SchedulerModel from "../../../models/scheduler/SchedulerModel";
import {useDispatch} from "react-redux";
import PurviewCache from "../../../models/guidedjob/PurviewCache";
import UserServiceObjectDropdown from "../../userservice/UserServiceObjectDropdown";

const fieldNames = ['disabled', 'useNonCustodialDataSourcesFile', 'nonCustodialDataSources'];
export const purviewAddNonCustodialDataSourcesParameterNames = buildOperationFieldParameterNames(WorkflowBuilderOperation.Alias.PURVIEW_ADD_NON_CUSTODIAL_DATA_SOURCES, fieldNames);

const applyHoldToNonCustodialDataSourcesFieldNames = ['disabled', 'inputType', 'nonCustodialDataSourceIdsJson', 'waitForCompletion'];
export const purviewApplyHoldToNonCustodialDataSourcesParameterNames = buildOperationFieldParameterNames(WorkflowBuilderOperation.Alias.PURVIEW_APPLY_HOLD_TO_NON_CUSTODIAL_DATA_SOURCES, applyHoldToNonCustodialDataSourcesFieldNames);

function getNextEnabled(getParameter) {
    const addNonCustodialDataSourcesDisabled = getParameter(purviewAddNonCustodialDataSourcesParameterNames.disabled, false, {parse: true});
    const nonCustodialDataSources = getParameter(purviewAddNonCustodialDataSourcesParameterNames.nonCustodialDataSources, '[]', {parse: true});
    return {
        addDataSources: addNonCustodialDataSourcesDisabled.value || (nonCustodialDataSources.value.length > 0
            && nonCustodialDataSources.value.every(source => !!(source.type && source.value)))
    }
}

export function getPurviewAddNonCustodialDataSourcesGuide(operation, props) {
    const {
        workflowActions,
        getParameter,
        updateParameter
    } = props;

    const nonCustodialDataSources = getParameter(purviewAddNonCustodialDataSourcesParameterNames.nonCustodialDataSources);
    const useNonCustodialDataSourcesFile = getParameter(purviewAddNonCustodialDataSourcesParameterNames.useNonCustodialDataSourcesFile);

    if (!nonCustodialDataSources?.value.startsWith('[') || !nonCustodialDataSources?.value.endsWith(']')) {
        updateParameter(purviewAddNonCustodialDataSourcesParameterNames.nonCustodialDataSources, JSON.stringify([]));
    }
    if (stringToBool(useNonCustodialDataSourcesFile.value)) {
        updateParameter(purviewAddNonCustodialDataSourcesParameterNames.useNonCustodialDataSourcesFile, JSON.stringify(false));
    }
    if (workflowActions.applyHoldToNonCustodialDataSources) {
        updateParameter(purviewApplyHoldToNonCustodialDataSourcesParameterNames.inputType, purviewDataSourceInputType.IDS_JSON);
        updateParameter(purviewApplyHoldToNonCustodialDataSourcesParameterNames.nonCustodialDataSourceIdsJson, purviewJobParameterNames.nonCustodialDataSourceIds);
    }

    return {
        getPanes: function(t, props) {
            const addNonCustodialDataSourcesTitle = getNonEmptyValueOrDefault(operation.notes, t('guidedJob:panes.addNonCustodialDataSources'));
            const nextEnabled = getNextEnabled(props.getParameter);

            return [{
                title: addNonCustodialDataSourcesTitle,
                isNextEnabled: nextEnabled.addDataSources,
                component: <PurviewOperationPane {...props}
                    workflowActions={workflowActions} OperationPane={PurviewAddNonCustodialDataSourcesPane}/>
            }];
        }
    }
}

const defaultNonCustodialDataSource = {
    type: purviewDataLocationType.EXCHANGE_LOCATION,
    value: ''
};

function PurviewAddNonCustodialDataSourcesPane(props) {
    const {t} = useTranslation(['guidedJob', 'common']);
    const dispatch = useDispatch();

    const {
        purviewService,
        getParameter,
        updateParameter,
        isDisabled
    } = props;

    const addNonCustodialDataSourcesDisabled = getParameter(purviewAddNonCustodialDataSourcesParameterNames.disabled, false, {parse: true});
    const isOperationDisabled = isDisabled || addNonCustodialDataSourcesDisabled.value;

    const nonCustodialDataSources = getParameter(purviewAddNonCustodialDataSourcesParameterNames.nonCustodialDataSources, '[]', {parse: true});
    const [dataSources, setDataSources] = useState(nonCustodialDataSources.value);

    useEffect(() => {
        updateParameter(purviewAddNonCustodialDataSourcesParameterNames.nonCustodialDataSources, JSON.stringify(dataSources));
    }, [dataSources]);

    const headers = useRef([
        {value: t('guidedJob:label.type'), style: {width: '14rem'}},
        {value: t('guidedJob:label.emailUrl')}
    ]);

    const rowProps = useRef({
        userServiceId: purviewService.authenticationServiceId
    });

    const exportCsv = useCallback(() => {
        const fields = headers.current.map(header => header.value);
        const data = dataSources.map(dataSource =>
            [dataSource.type, dataSource.value]
        );
        const fileName = `Non-Custodial Data Sources.csv`;
        blobUrlDownload(Papa.unparse({fields, data}), {type: 'text/csv;charset=utf-8;', fileName});
    }, [dataSources]);

    const importCsv = useCallback(async resultArray => {
        try {
            dispatch(GuidedJobModel.actionCreators.update({isDisabled: true}));
            const {data} = await ThirdPartyServiceApi.parseImportedObjects(purviewService.id, PurviewCache.Resource.NON_CUSTODIAL_DATA_SOURCES, resultArray);
            const values = {
                importedCount: data.objects.length,
                invalidCount: data.invalidCount
            };
            dispatch(GuidedJobModel.showImportPopup(values));
            setDataSources(data.objects);
        } catch (error) {
            dispatch(SchedulerModel.actionCreators.handleResponseError(error));
        } finally {
            dispatch(GuidedJobModel.actionCreators.update({isDisabled: false}));
        }
    }, [purviewService.id]);

    function skipToggleHandler(event) {
        const {checked} = event.target;
        [purviewAddNonCustodialDataSourcesParameterNames, purviewApplyHoldToNonCustodialDataSourcesParameterNames].forEach(paramName => {
            updateParameter(paramName.disabled, checked);
        });
    }


    return (
        <div className="display-input">
            <Checkbox label={t('guidedJob:label.skipAddingNonCustodialDataSources')}
                containerStyle={{marginBottom: '0.75rem'}} value={addNonCustodialDataSourcesDisabled.value} onClick={skipToggleHandler}
                isDisabled={isDisabled}/>

            <DataSourcesTable id={"NonCustodialDataSources"} label={t('guidedJob:label.nonCustodialDataSources')} dataSources={dataSources}
                setDataSources={setDataSources} defaultObject={defaultNonCustodialDataSource} headers={headers.current}
                RowCells={NonCustodialDataSourceRowCells} rowProps={rowProps.current} importCsv={importCsv} exportCsv={exportCsv}
                ariaLabelKey={'NonCustodialDataSource'} isDisabled={isOperationDisabled}/>
        </div>
    )
}

function NonCustodialDataSourceRowCells(props) {
    const {
        rIndex,
        userServiceId,
        dataSource,
        dropdownHandler,
        dropdownButtonStyle,
        isSelected,
        isDisabled
    } = props;

    const dataLocationTypeDropdownHandler = useCallback(event => {
        dropdownHandler(event);
        const newType = event.currentTarget.dataset.value;
        if (newType !== dataSource.type) {
            dropdownHandler(buildFakeEvent({name: 'value', value: '', index: rIndex}));
        }
    }, [dataSource.type]);

    let valueObjectType;
    if (dataSource.type === purviewDataLocationType.EXCHANGE_LOCATION) {
        valueObjectType = [userServiceObjectTypes.USER_ACCOUNT, userServiceObjectTypes.UNIFIED_GROUP];
    } else if (dataSource.type === purviewDataLocationType.SHAREPOINT_SITE) {
        valueObjectType = userServiceObjectTypes.SHARE_POINT_SITE;
    }

    return (
        <>
            <td>
                <DataLocationTypeDropdown id={`purviewDataLocationTypeDropdown${rIndex}`} name={'type'} value={dataSource.type}
                    onItemSelect={dataLocationTypeDropdownHandler} buttonStyle={dropdownButtonStyle}
                    isRequired isSelected={isSelected} isDisabled={isDisabled}/>
            </td>
            <td>
                <UserServiceObjectDropdown id={`purviewDataSourceValueDropdown${rIndex}`} name={'value'}
                    value={dataSource.value} userServiceId={userServiceId} objectType={valueObjectType} onItemSelect={dropdownHandler}
                    buttonStyle={dropdownButtonStyle} isRequired isSelected={isSelected} isDisabled={isDisabled}/>
            </td>
        </>
    )
}

function DataLocationTypeDropdown(props) {
    const {t} = useTranslation(['guidedJob', 'aria']);

    const dataLocationTypeItems = getKeys(purviewDataLocationType)
        .map(type => ({
            name: t(`guidedJob:purviewDataLocationType.${type}`),
            value: type
        }));

    return (
        <ListDropdown id={'purviewDataLocationTypeDropdown'} name={'dataLocationType'}
            noneSelectedMessage={t('guidedJob:option.selectDataLocationType')} aria-label={t('aria:hiddenAssistText.purviewDataLocationType')}
            isOutlined items={dataLocationTypeItems} {...props}
        />
    );
}
