import React, {useRef, useState} from "react"
import "./SelectionList.css";
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {makeGetEditDetails} from "../../reselect/selectors";
import DataRepositoryModel, {DataRepositoryApi, DataRepositorySaga} from "../../models/data/DataRepositoryModel";
import EditModel from "../../models/scheduler/EditModel";
import {createEditHandler, createInputHandler, createModifyingActionHandler} from "../../utilities/componentFunctions";
import PopupModel from "../../models/scheduler/PopupModel";
import {
    arrayIsNotEmptyNorFalsy,
    bytesCountToReadableCount,
    getLocaleDateTimeFromUTC,
    spreadNonNullObj,
    switchcaseF
} from "../../utilities/helperFunctions";
import {permissionKeys, statusKeys} from "../../i18next/keys";
import Tablet, {EditTabletHeader} from "../common/Tablet/Tablet";
import {DefaultEditPanel} from "../common/EditPanel/EditPanel";
import ExpandableContent, {ExpandableEditTextArea} from "../common/ExpandableContent/ExpandableContent";
import {
    SettingsRowSeparator,
    SettingsRowValue,
    TableLabelCell,
    TableLabelHeader
} from "../common/CustomTable/CustomTable";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import SchedulerModel from "../../models/scheduler/SchedulerModel";
import FileTable from "../common/FileTable/FileTable";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import {ToggleSwitch} from "../common/ToggleSwitch/ToggleSwitch";
import {createToggleHandler} from "../common/Checkbox/helpers";
import TextArea from "../common/TextArea/TextArea";
import {ExpandableStatusLog} from "../common/Common";
import Text from "../common/Text/Text";
import {contextCall} from "../../saga/sagaFunctions";
import {datasetType} from "../../utilities/constants";
import {AzureStorageAccountFormBody} from "./DataRepositoryForm";
import LimitedTablet from "../limited/LimitedTablet";
import {useAsyncEffect} from "../../utilities/hooks";


const selectEditDetails = makeGetEditDetails()
function selectReduxState(state, props) {

    const {activeModel, values, isSaveEnabled} = selectEditDetails(state, {model: DataRepositoryModel.nom})
    const isEditActive = (activeModel === DataRepositoryModel.nom)

    return {
        dataRepositoryName: props.name,
        ...props,
        ...values,
        isEditActive,
        isSaveEnabled,
        ...state.componentStates.dataRepositoryTablet
    }
}

function DataRepositoryTablet(props) {
    const {t} = useTranslation(['dataRepository', 'common'])
    const dispatch = useDispatch()

    const reduxState = useSelector(state => selectReduxState(state, props), shallowEqual);
    const {
        id,
        type,
        status,
        dataRepositoryName,
        description,
        quotaEnabled,
        computeFileSystemUsableSpace,
        userPermissions,

        isEditActive,
        isSaveEnabled,
        isDisabled
    } = reduxState;

    const accountKeyRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [fileNodes, setFileNodes] = useState([]);
    const [azureContainers, setAzureContainers] = useState([]);

    const isManagedType = type === datasetType.MANAGED;
    const isInPlaceType = type === datasetType.IN_PLACE;
    const isAzureType = type === datasetType.AZURE_STORE_ACCOUNT;

    const {
        saveEdit,
        cancelEdit,
        updateEdit,
        setEditSaveEnabled
    } = EditModel.buildDispatchers(dispatch)

    const editHandler = createEditHandler({
        updateEdit,
        setEditSaveEnabled,
        shouldEnable: DataRepositoryModel.validateFormData,
        passwordOptions: {
            'accountKey': {
                ref: accountKeyRef,
                resetNames: ['accountUrl', 'accountName']
            }
        }
    })

    const inputHandler = createInputHandler({
        handler: editHandler
    })

    const toggleHandler = createToggleHandler({
        handler: editHandler
    })

    const getSafeModifyingAction = createModifyingActionHandler({
        item: t('dataRepository:label.name'),
        showError: payload => dispatch(PopupModel.actionCreators.showError(payload)),
        userPermissions
    })


    // Query shallow fileNodeTree
    useAsyncEffect(async () => {
        try {
            if (isManagedType || isInPlaceType) {
                const {data} = await DataRepositoryApi.getRepositoryFileTree(id);
                setFileNodes(data);

            } else if (isAzureType) {
                const {data} = await DataRepositoryApi.getAzureStorageAccountContainers(id);
                setAzureContainers(data);
            }
        } catch (error) {
            dispatch(SchedulerModel.actionCreators.handleResponseError(error))
        } finally {
            setIsLoading(false);
        }
    }, [id, dispatch]);

    async function populatePaths() {
        dispatch(DataRepositoryModel.componentActionCreators.updateTablet({isDisabled: true}))

        try {
            const {data} = await DataRepositoryApi.getRepositoryFileTree(id, {viewPaths: true})
            setFileNodes(prevNodes => updateNodes(prevNodes, data))

        } catch (error) {
            dispatch(SchedulerModel.actionCreators.handleResponseError(error))
        } finally {
            dispatch(DataRepositoryModel.componentActionCreators.updateTablet({isDisabled: false}))
        }
    }

    function updateNodes(oldNodes, newNodes) {

        const updatedNodes = []
        for (let i = 0; i < newNodes.length; i++) {

            updatedNodes.push(
                // Only update non-null values
                spreadNonNullObj(oldNodes[i], newNodes[i])
            )
        }

        return updatedNodes;
    }


    function onClose() {
        dispatch(DataRepositoryModel.actionCreators.hideTablet())
    }

    function menuOptionHandler(event) {
        const {value} = event.currentTarget.dataset

        switchcaseF({
            'edit': () => dispatch(DataRepositoryModel.actionCreators.startEdit(id)),
            'promptDelete': () => dispatch(DataRepositoryModel.actionCreators.promptDelete(id)),

            'viewPaths': populatePaths,
            'checkSpace': () => dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(
                contextCall(DataRepositorySaga, 'computeRepositoryUsableSpace', id)
            ))
        })()(value)
    }

    const menuOptions = [
        {name: t('common:option.edit'), value: 'edit'},
        {name: t('common:option.delete'), value: 'promptDelete'}
    ]

    if (isManagedType || isInPlaceType) {
        menuOptions.push(
            {isSeparator: true},
            {name: t('dataRepository:option.viewPaths'), value: 'viewPaths', isDisabled: (fileNodes.length === 0)}
        );
    }
    if (isManagedType) {
        menuOptions.push(
            {name: t('dataRepository:option.checkSpace'), value: 'checkSpace', isDisabled: !(computeFileSystemUsableSpace || quotaEnabled)}
        );
    }


    const canModify = userPermissions.includes(permissionKeys.MODIFY)
    const showDescription = isEditActive || description;

    const height = isEditActive ? 'auto' : '50vh'

    const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);
    if (isViewLimited && !canModify) {
        return (
            <LimitedTablet id={id} type={`dataRepository${type}`} name={dataRepositoryName} label={t(`dataRepository:label.name_${type}`)}
                           status={status} enabled={true} canModify={canModify} isDisabled={isDisabled} description={description}
                           onClose={onClose}
            />
        )
    }

    return (
        <Tablet width={'70rem'} height={height} onClose={onClose} isDisabled={isDisabled}
            closeButtonAriaLabel={t('dataRepository:option.closeTablet_name', {name: dataRepositoryName})}
            header={
                <EditTabletHeader label={t(`dataRepository:label.name_${type}`)} type={`dataRepository${type}`}
                    id={id} value={dataRepositoryName} menuName={'dataRepository'} menuOptions={menuOptions} menuOptionHandler={menuOptionHandler}
                    name={'dataRepositoryName'} inputHandler={inputHandler} isEditActive={isEditActive} canModify={canModify} isDisabled={isDisabled}
                />
            }

            body={
                <LoadingWrapper isLoading={isLoading}>
                    {status.code === statusKeys.ERROR && !isEditActive &&
                    <div className="display-item">
                        <ExpandableStatusLog {...status} isDisabled={isDisabled}/>
                    </div>
                    }

                    <div className="display-item">
                        <DefaultEditPanel isActive={isEditActive} isSaveEnabled={isSaveEnabled} onSave={saveEdit} onCancel={cancelEdit}>

                            {showDescription &&
                            <ExpandableEditTextArea label={t('common:label.description')} name={'description'} value={description}
                                onChange={getSafeModifyingAction(inputHandler)} isEditActive={isEditActive} isDisabled={isDisabled}/>
                            }

                            <SettingsTable {...reduxState} updateState={editHandler} accountKeyRef={accountKeyRef}
                                inputHandler={inputHandler} toggleHandler={toggleHandler} isDisabled={isDisabled}/>

                            {(isManagedType || isInPlaceType) && !isEditActive && fileNodes.length > 0 &&
                            <ExpandableContent label={t('dataRepository:label.datasets')} isDisabled={isDisabled}>
                                <FileTable nodes={fileNodes} isDisabled={isDisabled}
                                    style={{marginTop: '0.25rem'}} leafIcon={'closedFolder'}
                                />
                            </ExpandableContent>
                            }

                            {(isAzureType) && !isEditActive && azureContainers.length > 0 &&
                                <ExpandableContent label={t('dataRepository:label.containers')} isDisabled={isDisabled}>
                                    <AzureStorageAccountContainersTable containers={azureContainers} isDisabled={isDisabled}/>
                                </ExpandableContent>
                            }

                        </DefaultEditPanel>
                    </div>
                </LoadingWrapper>
            }
        />
    )
}

function AzureStorageAccountContainersTable(props) {
    const {t} = useTranslation(['dataRepository', 'common']);
    const {
        containers,
        isDisabled
    } = props;

    const disabled = isDisabled ? ' is-disabled' : '';

    return (
        <div className={'display-table no-pointer' + disabled}>
            <div className="table-header-group">
                <TableLabelHeader label={t('common:label.name')}/>
                <TableLabelHeader label={t('common:label.lastModifiedDate')} alignRight/>
            </div>

            <div className="table-row-group">
                {Array.isArray(containers) && containers.map(container => {
                    const lastModified = container.Properties && (container.Properties['Last-Modified'] * 1000);
                    return (
                        <div className="table-row" key={container.Name}>
                            <TableLabelCell label={container.Name}/>
                            <TableLabelCell label={getLocaleDateTimeFromUTC(lastModified)} alignRight/>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

function SettingsTable(props) {
    const {t} = useTranslation(['dataRepository', 'common'])

    const {
        type,
        path,
        quota,
        quotaEnabled,
        datasetMaxSize,
        datasetMaxSizeEnabled,
        uploadMaxSize,
        uploadMaxSizeEnabled,
        computeFileSystemUsableSpace,
        usableSpace,
        allowedFileExtensions,
        allowedFileExtensionsEnabled,
        datasetAutoExpireInterval,
        datasetAutoExpireIntervalEnabled,
        datasetAutoHide,
        datasetAutoArchive,
        datasetAutoArchiveJobWarning,
        datasetAutoArchiveJobError,
        datasetAutoArchiveJobSoftError,
        azureStorageAccount,

        accountKeyRef,
        updateState,
        inputHandler,
        toggleHandler,
        isEditActive,
        isDisabled
    } = props

    const isManagedType = type === datasetType.MANAGED;
    const isInPlaceType = type === datasetType.IN_PLACE;
    const isAzureType = type === datasetType.AZURE_STORE_ACCOUNT;

    const computeFileSystemInvalid = (!quotaEnabled && !computeFileSystemUsableSpace);
    const quotaInvalid = !quota || quota < 0;
    const datasetMaxSizeInvalid = !datasetMaxSize || datasetMaxSize < 0 || (quotaEnabled && (parseInt(datasetMaxSize) > quota));
    const uploadMaxSizeInvalid = !uploadMaxSize || uploadMaxSize < 0 || (datasetMaxSizeEnabled && parseInt(uploadMaxSize) > datasetMaxSize) || (quotaEnabled && (parseInt(uploadMaxSize) > quota));

    const disabled = isDisabled ? ' is-disabled' : '';
    return (
        <ExpandableContent label={t('common:label.settings')} isDisabled={isDisabled}>
            <div className={'settings-table align-top' + disabled}>
                <div className="table-row-group">

                    {isAzureType && !isEditActive &&
                        <>
                            <SettingsRowValue label={t('dataRepository:label.storageAccountUrl')} value={azureStorageAccount.accountUrl} isBreakWord/>
                            <SettingsRowValue label={t('dataRepository:label.accountName')} value={azureStorageAccount.accountName} isBreakWord/>
                        </>
                    }

                    {isAzureType && isEditActive &&
                        <AzureStorageAccountFormBody azureStorageAccount={azureStorageAccount} accountKeyRef={accountKeyRef}
                            updateState={updateState} isDisabled={isDisabled}/>
                    }

                    {(isManagedType || isInPlaceType) &&
                    <SettingsRowValue label={t('dataRepository:label.path')} value={path} isBreakWord/>
                    }

                    {isManagedType && (isEditActive || (quotaEnabled && quota != null)) &&
                    <SettingsRowValue labelId="dataRepository:quotaLabel" label={t('dataRepository:label.quota')}
                        htmlFor={'dataRepositoryQuotaInput'}
                        value={isEditActive ?
                            <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                <ToggleSwitch id="quotaEnabled" aria-labelledby="dataRepository:quotaLabel" name={'quotaEnabled'} checked={quotaEnabled}
                                    onClick={toggleHandler} isInvalid={computeFileSystemInvalid} isDisabled={isDisabled}
                                />

                                <HTMLTextInput id={'dataRepositoryQuotaInput'} name={'quota'} value={quota} onChange={inputHandler}
                                    type="number" step={'any'} style={{width: '8rem', margin: '0 0.5rem 0 1rem'}}
                                    isInvalid={quotaInvalid} isDisabled={!quotaEnabled || isDisabled}
                                />

                                <span className="label">
                                    GB
                                </span>
                            </div>
                            :
                            bytesCountToReadableCount(quota)
                        }
                    />
                    }

                    {isManagedType && (isEditActive || (datasetMaxSizeEnabled && datasetMaxSize != null)) &&
                        <SettingsRowValue labelId="dataRepository:datasetMaxSize" label={t('dataRepository:label.datasetMaxSize')}
                            htmlFor={'dataRepositoryDatasetMaxSizeInput'}
                            value={isEditActive ?
                                <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                    <ToggleSwitch id="datasetMaxSizeEnabled" aria-labelledby="dataRepository:datasetMaxSize" name={'datasetMaxSizeEnabled'}
                                        checked={datasetMaxSizeEnabled} onClick={toggleHandler} isDisabled={isDisabled}
                                    />

                                    <HTMLTextInput id={'dataRepositoryDatasetMaxSizeInput'} name={'datasetMaxSize'} value={datasetMaxSize} onChange={inputHandler}
                                        type="number" step={'any'} style={{width: '8rem', margin: '0 0.5rem 0 1rem'}}
                                        isInvalid={datasetMaxSizeInvalid} isDisabled={!datasetMaxSizeEnabled || isDisabled}
                                    />

                                    <span className="label">
                                        GB
                                    </span>
                                </div>
                                :
                                bytesCountToReadableCount(datasetMaxSize)
                            }
                        />
                    }

                    {isManagedType && (isEditActive || (uploadMaxSizeEnabled && uploadMaxSize != null)) &&
                        <SettingsRowValue labelId="dataRepository:uploadMaxSize" label={t('dataRepository:label.uploadMaxSize')}
                            htmlFor={'dataRepositoryUploadMaxSizeInput'}
                            value={isEditActive ?
                                <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                    <ToggleSwitch id="uploadMaxSizeEnabled" aria-labelledby="dataRepository:uploadMaxSize" name={'uploadMaxSizeEnabled'}
                                        checked={uploadMaxSizeEnabled} onClick={toggleHandler} isDisabled={isDisabled}
                                    />

                                    <HTMLTextInput id={'dataRepositoryUploadMaxSizeInput'} name={'uploadMaxSize'} value={uploadMaxSize} onChange={inputHandler}
                                        type="number" step={'any'} style={{width: '8rem', margin: '0 0.5rem 0 1rem'}}
                                        isInvalid={uploadMaxSizeInvalid} isDisabled={!uploadMaxSizeEnabled || isDisabled}
                                    />

                                    <label className="label">
                                        GB
                                    </label>
                                </div>
                                :
                                bytesCountToReadableCount(uploadMaxSize)
                            }
                        />
                    }

                    {isManagedType && (isEditActive || arrayIsNotEmptyNorFalsy(allowedFileExtensions)) &&
                    <SettingsRowValue labelId="dataRepository:allowedFileExtensions" label={t('dataRepository:label.allowedFileExtensions')}
                        htmlFor="dataRepositoryAllowedFileExtensionsTextArea"
                        value={isEditActive ?
                            <div style={{display: 'flex'}}>
                                <ToggleSwitch id="allowedFileExtensionsEnabled" aria-labelledby="dataRepository:allowedFileExtensions"
                                    name={'allowedFileExtensionsEnabled'} checked={allowedFileExtensionsEnabled} onClick={toggleHandler}
                                    style={{marginRight: '1rem'}} isDisabled={isDisabled}
                                />

                                <TextArea id="dataRepositoryAllowedFileExtensionsTextArea" name={'allowedFileExtensions'} value={allowedFileExtensions}
                                    aria-labelledby="dataRepository:allowedFileExtensions" expandContainer onChange={inputHandler}
                                    rows={2} placeholder={'pst; zip'} isDisabled={!allowedFileExtensionsEnabled || isDisabled}
                                />
                            </div>

                            :
                            <div className="selection-list">
                                {allowedFileExtensions.map(fileExtension =>
                                    <SelectionItem key={fileExtension} value={fileExtension}/>
                                )}
                            </div>
                        }
                    />
                    }

                    {isEditActive &&
                        <>
                            <SettingsRowSeparator/>
                            {isManagedType &&
                                <SettingsRowValue
                                    value={
                                        <ToggleSwitch id="computeFileSystemUsableSpace" name={'computeFileSystemUsableSpace'} checked={computeFileSystemUsableSpace}
                                            label={t('dataRepository:label.computeFileSystemUsableSpace')} labelStyle={{marginLeft: '1rem'}}
                                            onClick={toggleHandler} isInvalid={computeFileSystemInvalid} isDisabled={isDisabled} isWordWrap={false}/>
                                    }
                                />
                            }

                            {(isManagedType || isInPlaceType) &&
                                <>
                                    <SettingsRowValue
                                        value={
                                            <ToggleSwitch id="datasetAutoHide" name={'datasetAutoHide'} checked={datasetAutoHide}
                                                label={t('dataRepository:message.datasetAutoHide')} labelStyle={{marginLeft: '1rem'}}
                                                onClick={toggleHandler} isDisabled={isDisabled} isWordWrap={false}
                                            />
                                        }
                                    />

                                    <SettingsRowValue
                                        value={
                                            <ToggleSwitch id="datasetAutoArchive" name={'datasetAutoArchive'} checked={datasetAutoArchive}
                                                label={t('dataRepository:message.datasetAutoArchive')} labelStyle={{marginLeft: '1rem'}}
                                                onClick={toggleHandler} isDisabled={isDisabled} isWordWrap={false}
                                            />
                                        }
                                    />

                                    <SettingsRowValue
                                        value={
                                            <ToggleSwitch id="datasetAutoArchiveJobWarning" name={'datasetAutoArchiveJobWarning'} checked={datasetAutoArchiveJobWarning}
                                                label={t('dataRepository:message.datasetAutoArchiveJobWarning')} labelStyle={{marginLeft: '1rem'}}
                                                onClick={toggleHandler} isDisabled={isDisabled} isWordWrap={false}
                                            />
                                        }
                                    />

                                    <SettingsRowValue
                                        value={
                                            <ToggleSwitch id="datasetAutoArchiveJobSoftError" name={'datasetAutoArchiveJobSoftError'} checked={datasetAutoArchiveJobSoftError}
                                                label={t('dataRepository:message.datasetAutoArchiveJobSoftError')} labelStyle={{marginLeft: '1rem'}}
                                                onClick={toggleHandler} isDisabled={isDisabled} isWordWrap={false}
                                            />
                                        }
                                    />

                                    <SettingsRowValue
                                        value={
                                            <ToggleSwitch id="datasetAutoArchiveJobError" name={'datasetAutoArchiveJobError'} checked={datasetAutoArchiveJobError}
                                                label={t('dataRepository:message.datasetAutoArchiveJobError')} labelStyle={{marginLeft: '1rem'}}
                                                onClick={toggleHandler} isDisabled={isDisabled} isWordWrap={false}
                                            />
                                        }
                                    />

                                    <SettingsRowValue
                                        value={
                                            <div style={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                marginTop: '-0.25rem',
                                                marginBottom: '1rem'
                                            }}>
                                                <ToggleSwitch id="autoExpireIntervalEnabled" name={'datasetAutoExpireIntervalEnabled'} checked={datasetAutoExpireIntervalEnabled}
                                                    label={t('dataRepository:message.datasetAutoExpireInterval')} labelStyle={{marginLeft: '1rem'}}
                                                    onClick={toggleHandler} isDisabled={isDisabled} isWordWrap={false}
                                                />

                                                <HTMLTextInput id={'datasetAutoExpireInterval'} name={'datasetAutoExpireInterval'} value={datasetAutoExpireInterval}
                                                    onChange={inputHandler} type="number" style={{width: 'calc(8rem)', margin: '0 0.5rem 0 1rem'}}
                                                    isInvalid={datasetAutoExpireInterval < 0} isDisabled={!datasetAutoExpireIntervalEnabled || isDisabled}
                                                />

                                                <label htmlFor={'datasetAutoExpireInterval'} className="label">
                                                    {t('dataRepository:label.hours')}
                                                </label>
                                            </div>
                                        }
                                    />
                            </>
                            }
                        </>
                    }

                    {!isEditActive && isManagedType && (quotaEnabled || computeFileSystemUsableSpace) &&
                        <>
                            <SettingsRowSeparator/>

                            {(quotaEnabled || computeFileSystemUsableSpace) &&
                                <SettingsRowValue label={t('dataRepository:label.effectiveUsableSpace')}
                                    value={usableSpace == null ?
                                        '-'
                                        :
                                        bytesCountToReadableCount(usableSpace.effectiveUsableSpace)
                                    }
                                />
                            }

                            {computeFileSystemUsableSpace &&
                                <SettingsRowValue label={t('dataRepository:label.fileSystemUsableSpace')}
                                    value={usableSpace == null ?
                                        '-'
                                        :
                                        bytesCountToReadableCount(usableSpace.fileSystemUsableSpace)}
                                />
                            }
                        </>
                    }
                </div>
            </div>

            {!isEditActive &&
            <div className="display-item">
                {datasetAutoHide &&
                <Text value={t('dataRepository:message.datasetAutoHide')} isDisabled={isDisabled}/>
                }
                {datasetAutoArchive &&
                <Text value={t('dataRepository:message.datasetAutoArchive')} isDisabled={isDisabled}/>
                }
                {datasetAutoArchiveJobWarning &&
                <Text value={t('dataRepository:message.datasetAutoArchiveJobWarning')} isDisabled={isDisabled}/>
                }
                {datasetAutoArchiveJobSoftError &&
                <Text value={t('dataRepository:message.datasetAutoArchiveJobSoftError')} isDisabled={isDisabled}/>
                }
                {datasetAutoArchiveJobError &&
                <Text value={t('dataRepository:message.datasetAutoArchiveJobError')} isDisabled={isDisabled}/>
                }
                {datasetAutoExpireInterval &&
                <div style={{display: 'flex'}}>
                    <Text value={t('dataRepository:message.datasetAutoExpireInterval')} isDisabled={isDisabled}/>
                    <Text style={{margin: '0 0.5rem'}} value={datasetAutoExpireInterval / 1000 / 60 / 60} isDisabled={isDisabled}/>
                    <Text value={t('dataRepository:label.hours')} isDisabled={isDisabled}/>
                </div>
                }
            </div>
            }
        </ExpandableContent>
    )
}

export function SelectionItem(props) {
    const {value} = props;

    return (
        <div className="selection-list__item">
            <label className="label is-wordwrap">
                {value}
            </label>
        </div>
    )
}

export default DataRepositoryTablet