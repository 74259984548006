import {getObjectText, getValues} from "../../utilities/helperFunctions";
import {createSelector} from "reselect";
import {makeGetEditDetails} from "../../reselect/selectors";
import ClientModel from "../../models/client/ClientModel";


function makeSelectClientDetails() {
  const selectEditDetails = makeGetEditDetails();

  return function(state, props) {

    const {activeModel, values, isSaveEnabled} = selectEditDetails(state, {model: ClientModel.nom});
    const isEditActive = (activeModel === ClientModel.nom);

    const {name: clientName, defaultExecutionProfileId, defaultResourcePoolId, ...otherDetails} = state.clientDetailsMap.get(props.clientId);
    const {defaultExecutionProfileId: editExecutionProfileId, defaultResourcePoolId: editResourcePoolId, ...otherEditValues} = values;

    let defaultExecutionProfile = (state.executionProfileDetailsMap.get(defaultExecutionProfileId) || {}).name;
    let defaultResourcePool = (state.resourcePoolDetailsMap.get(defaultResourcePoolId) || {}).name;

    if (isEditActive) {
      defaultExecutionProfile = editExecutionProfileId;
      defaultResourcePool = editResourcePoolId;
    }

    return {
      clientName,
      defaultExecutionProfile,
      defaultResourcePool,
      ...otherDetails,
      ...otherEditValues,
      isEditActive,
      isSaveEnabled
    }
  }
}
export const selectClientDetails = makeSelectClientDetails();

export const selectFilteredMatterIds = createSelector(
  state => state.matterDetailsMap,
  (ignore, props) => props.clientId,
  (ignore, props) => props.searchText,
  (matterDetailsMap, clientId, searchText) => {

    return getValues(matterDetailsMap)
      .filter(_matter => getObjectText(_matter).includes(searchText.toLowerCase()))
      .filter(_matter => _matter.clientId === clientId)
      .map(_matter => _matter.id);
  }
);
