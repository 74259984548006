// import issue requires sagaFunctions to be imported here first
import {cancelled} from "redux-saga/effects";
import {checkNestedExists, generateUUID4} from "../../utilities/helperFunctions";

class AxiosProxy {

    static etags = {};
    static shouldUpdate(key, response) {
        let etag = null;
        if (checkNestedExists(response, 'headers', 'etag')) {
            etag = response.headers.etag;
        } else if (Array.isArray(response)) {
            etag = response.map(res => res.headers.etag).filter(etag => etag).join('')
        } else if (typeof response === 'string') {
            etag = response;
        }

        if (!this.etags[key] || !etag || this.etags[key] !== etag) {
            this.etags[key] = etag;
            return true;
        }
    }

    constructor() {
        const version = 'api/v1';
        const browserUrl = window.location.origin + window.location.pathname

        const url = browserUrl === 'http://localhost:3000/' ? 'http://127.0.0.1:8000/' : browserUrl;
        this.baseUrl = url + version;

        this.idToRequest = {};
        this.worker = new Worker(new URL('AxiosWorker.js', import.meta.url));
        this.worker.postMessage(['initialize', {baseURL: this.baseUrl}]);
        this.worker.onmessage = this.handlerWorkerResponse.bind(this);

        this.maskGetErrors = false;
    }

    get(...args) {
        const req = this.postWorkerRequest('get', ...args);
        if (this.maskGetErrors) {
            return req.catch(err => Promise.reject(JSON.stringify(err)));
        }
        return req;
    }

    post(...args) {
        return this.postWorkerRequest('post', ...args);
    }

    put(...args) {
        return this.postWorkerRequest('put', ...args);
    }

    del(...args) {
        return this.postWorkerRequest('del', ...args);
    }

    setAcceptLanguageHeader(language) {
        this.worker.postMessage(['setAcceptLanguageHeader', language]);
    }

    setAxiosToken(token) {
        this.worker.postMessage(['setAxiosToken', token]);
    }

    clearAxiosToken() {
        this.worker.postMessage(['clearAxiosToken']);
    }

    cancel(id) {
        this.worker.postMessage(['cancel', id]);
    }

    *cancelWrapper(method, ...args) {
        const id = generateUUID4();
        try {
            return yield this.postWorkerRequestWithId(id, method, ...args);
        } finally {
            if (yield cancelled()) {
                this.cancel(id);
            }
        }
    }

    postWorkerRequest(method, ...args) {
        const id = generateUUID4();
        return this.postWorkerRequestWithId(id, method, ...args);
    }

    postWorkerRequestWithId(id, method, ...args) {
        return new Promise(async (resolve, reject) => {
            this.worker.postMessage(['request', id, method, ...args]);
            this.idToRequest[id] = {resolve, reject};
        });
    }

    handlerWorkerResponse(event) {
        const [action, ...args] = event.data;
        switch (action) {
            case 'resolve':
            case 'reject': {
                const [id, ...others] = args;

                // Resolve or reject promise
                const request = this.idToRequest[id];
                if (request != null && typeof request[action] === 'function') {

                    // mask 502/504 status for all requests
                    const maskError = action === 'reject' && this.maskGetErrors
                        && (args.status === 502 || args.status === 504);

                    if (maskError) {
                        request.reject(JSON.stringify(others));
                    } else {
                        request[action](...others);
                    }
                }

                delete this.idToRequest[id];
                break;
            }
        }
    }
}

let axiosInstance;

export function setAxiosInstance(instance) {
    if (axiosInstance != null) {
        axiosInstance.worker.terminate();
    }
    axiosInstance = instance;
}
export {axiosInstance};
export default AxiosProxy;
