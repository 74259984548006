
class CustomIntersectionObserver {
    constructor(callback, options={}) {
        this.callback = callback;
        this.invisibleCallback = options.invisibleCallback;
        this.root = options.root;
        this.visibleRatio = options.visibleRatio || 0.5;
        this.visibleBoundsMargin = options.visibleBoundsMargin || {};
        this.delay = options.delay;
        if (this.delay == null) {
            this.delay = 500;
        }
    }

    observe(element) {
        this.element = element;
        if (typeof this.callback === 'function') {
            this.root.addEventListener('scroll', this);
        }
    }

    handleEvent() {
        if (this.debouncedScrollEvent != null) {
            this.debouncedScrollEvent.debounced = true;
        } else {
            this.triggerCallback();
            this.debouncedScrollEvent = {
                timeout: setTimeout(() => {
                    if (!this.disconnected && this.debouncedScrollEvent.debounced) {
                        this.triggerCallback();
                    }
                    delete this.debouncedScrollEvent;
                }, 250)
            }
        }
    }

    triggerCallback() {
        if (this.isVisible()) {
            if (this.callbackTimeout == null) {
                this.callbackTimeout = setTimeout(() => {
                    if (!this.disconnected) this.callback();
                    this.callbackTimeout = null;
                }, this.delay);
            }
            if (this.invisibleCallbackTimeout != null) {
                clearTimeout(this.invisibleCallbackTimeout);
                this.invisibleCallbackTimeout = null;
            }
        } else {
            if (typeof this.invisibleCallback === 'function' && this.invisibleCallbackTimeout == null) {
                this.invisibleCallbackTimeout = setTimeout(() => {
                    if (!this.disconnected) this.invisibleCallback();
                    this.invisibleCallbackTimeout = null;
                }, this.delay);
            }
            if (this.callbackTimeout != null) {
                clearTimeout(this.callbackTimeout);
                this.callbackTimeout = null;
            }
        }
    }

    isVisible() {
        const {top: topMargin, bottom: bottomMargin} = this.visibleBoundsMargin;
        const {top, bottom} = this.element.getBoundingClientRect();
        let {top: containerTop, bottom: containerBottom} = this.root.getBoundingClientRect();
        if (!isNaN(topMargin)) containerTop += topMargin;
        if (!isNaN(bottomMargin)) containerBottom -= bottomMargin;

        // Element bounds are within container
        let satisfiesCondition = top > containerTop && bottom < containerBottom;
        if (!satisfiesCondition) {
            // Element bounds takes up required container visibleRatio
            const topBound = Math.max(top, containerTop);
            const bottomBound = Math.min(bottom, containerBottom);
            const visibleRatio = (bottomBound - topBound) / (containerBottom - containerTop);
            satisfiesCondition = visibleRatio > this.visibleRatio;
        }
        return satisfiesCondition;
    }

    disconnect() {
        this.root.removeEventListener('scroll', this);
        this.disconnected = true;
    }
}

export default CustomIntersectionObserver;
