import SettingModel from "../generics/SettingModel";

class JobInfosModel extends SettingModel.Simple {

    static nom = 'JobInfosModel';
    static actions = JobInfosModel.buildActions('JOB_INFOS');
    static actionCreators = JobInfosModel.buildActionCreators(JobInfosModel.actions);
    static reducer = JobInfosModel.buildReducer(JobInfosModel.actions);

}

export default JobInfosModel;