import React from 'react';
import {ListDropdown} from "../../../../components/common/Dropdown/Dropdown";
import {useTranslation} from "react-i18next";

function ExportTypeDropdown(props) {
    const {t} = useTranslation(['aria', 'wizard']);

    const {selectedExportTypeId, onExportTypeSelected, ...rest} = props;
    const exportTypeValues = [
        {value: "CONCORDANCE", name: t('wizard:questions.legalExport.exportTypeDropdown.concordance')},
        {value: "SUMMATION", name: t('wizard:questions.legalExport.exportTypeDropdown.summation')},
        {value: "DISCOVERY_RADAR", name: t('wizard:questions.legalExport.exportTypeDropdown.discovery')},
        {value: "DOCUMATRIX", name: t('wizard:questions.legalExport.exportTypeDropdown.docu')},
        {value: "EDRM_XML", name: t('wizard:questions.legalExport.exportTypeDropdown.edrmXml')},
        {value: "EDRM_XML_ZIP", name: t('wizard:questions.legalExport.exportTypeDropdown.edrmXmlZip')},
        {value: "IPRO", name: t('wizard:questions.legalExport.exportTypeDropdown.iPro')},
        {value: "XHTML_SUMMARY_REPORT", name: t('wizard:questions.legalExport.exportTypeDropdown.XHTML')},
        {value: "CSV_SUMMARY_REPORT", name: t('wizard:questions.legalExport.exportTypeDropdown.csv')},
    ]

    return (
        <ListDropdown id={'exportTypeDropdown'} name={'exportType'}
            aria-label={t('aria:hiddenAssistText.exportType')} value={selectedExportTypeId}
            items={exportTypeValues} onItemSelect={onExportTypeSelected}
            buttonStyle={{width: '12rem', justifyContent: 'flex-start'}}
            {...rest}
        />
    )
}

export default ExportTypeDropdown;