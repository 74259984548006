import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {getAwsResourcePoolIds, getAzureResourcePoolIds, getLocalResourcePoolIds} from '../../reselect/selectors';
import LocalResourcePoolForm from './local/LocalResourcePoolForm';
import AwsResourcePoolForm from './aws/AwsResourcePoolForm';
import LocalResourcePoolTable from './local/LocalResourcePoolTable';
import AwsResourcePoolTable from './aws/AwsResourcePoolTable';
import LocalResourcePoolTablet from './local/LocalResourcePoolTablet';
import AwsResourcePoolTablet from './aws/AwsResourcePoolTablet';
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import AzureResourcePoolTable from "./azure/AzureResourcePoolTable";
import AzureResourcePoolForm from "./azure/AzureResourcePoolForm";
import AzureResourcePoolTablet from "./azure/AzureResourcePoolTablet";
import {applicationFeatures, details} from "../../utilities/constants";
import ResourcePoolModel from "../../models/settings/ResourcePoolModel";
import {MainPageContent, MainPageHeader} from "../app/pages/MainPage";

class ResourcePoolDisplay extends Component {

    componentDidMount() {
        this.props.setDisplayActive();
    }

    componentWillUnmount() {
        this.props.setDisplayInactive();
    }

    render() {

        const {
            t,
            isLoading,
            resourcePoolId,
            localResourcePoolIds,
            awsResourcePoolIds,
            azureResourcePoolIds,
            isLocalResourcePoolFormActive,
            isAwsResourcePoolFormActive,
            isAzureResourcePoolFormActive,
            userFeatures,
            ...attr
        } = this.props;

        const canViewCloudResourcePools = userFeatures.includes(applicationFeatures.CLOUD_RESOURCE_POOLS)

        return (
            <MainPageContent {...attr}
                title={
                    <MainPageHeader text={t('resourcePool:page.title')}
                        description={t('resourcePool:page.description')}/>
                }
                list={
                    <LoadingWrapper isLoading={isLoading}>
                        <LocalResourcePoolTable/>
                        {canViewCloudResourcePools &&
                            <>
                                <AwsResourcePoolTable/>
                                <AzureResourcePoolTable/>
                            </>
                        }
                    </LoadingWrapper>
                }
                children={
                    <>
                    {localResourcePoolIds.includes(resourcePoolId) &&
                    <LocalResourcePoolTablet resourcePoolId={resourcePoolId}/>
                    }
                    {awsResourcePoolIds.includes(resourcePoolId) &&
                    <AwsResourcePoolTablet resourcePoolId={resourcePoolId}/>
                    }
                    {azureResourcePoolIds.includes(resourcePoolId) &&
                    <AzureResourcePoolTablet resourcePoolId={resourcePoolId}/>
                    }
                    {isLocalResourcePoolFormActive &&
                    <LocalResourcePoolForm/>
                    }
                    {isAwsResourcePoolFormActive &&
                    <AwsResourcePoolForm/>
                    }
                    {isAzureResourcePoolFormActive &&
                    <AzureResourcePoolForm/>
                    }
                    </>
                }
            />
        );
    }
}

const mapStateToProps = state => {
    const {currentUser} = state;
    return {
        ...state.componentStates.resourcePoolDisplay,
        localResourcePoolIds: getLocalResourcePoolIds(state),
        awsResourcePoolIds: getAwsResourcePoolIds(state),
        azureResourcePoolIds: getAzureResourcePoolIds(state),
        isLoading: !state.hasLoaded[details.RESOURCE_POOLS],
        userFeatures: currentUser.features,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setDisplayActive: () => dispatch(ResourcePoolModel.componentActionCreators.setDisplayActive()),
        setDisplayInactive: () => dispatch(ResourcePoolModel.componentActionCreators.setDisplayInactive())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['resourcePool'])(ResourcePoolDisplay));