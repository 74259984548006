import React from 'react';
import {ListDropdown} from "../../../../components/common/Dropdown/Dropdown";
import {DiscoverFileRepository} from "../../../utilities/constants";
import {useTranslation} from "react-i18next";

function DiscoverFileRepositoryDropdown(props) {
    const {t} = useTranslation(['aria', 'wizard']);

    const {selectedRepositoryType, onRepositoryTypeSelected, ...rest} = props;
    const dateFilterTypeValues = [
        {value: DiscoverFileRepository.AMAZON_S3, name: "Amazon S3"},
        {value: DiscoverFileRepository.WINDOWS_FILE_SHARE, name: "Windows File Share"}
    ]

    return (
        <ListDropdown id={'repositoryTypeDropdown'} name={'discoverFileRepositoryType'}
            aria-label={t('aria:hiddenAssistText.endpointType')} value={selectedRepositoryType}
                        items={dateFilterTypeValues} onItemSelect={onRepositoryTypeSelected}
                        buttonStyle={{maxWidth: '12rem'}}
                        {...rest}
        />
    )
}

export default DiscoverFileRepositoryDropdown;