import React, {useCallback, useState} from "react";
import CustomModal from "../common/CustomModal/CustomModal";
import {FormFooter, FormHeader} from "../common/Form/Form";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import LibraryPanel from "../panels/LibraryPanel";
import WorkflowTemplatePanel from "../panels/WorkflowTemplatePanel";
import WorkflowBuilderModel from "../../models/workflowbuilder/WorkflowBuilderModel";
import {getValues} from "../../utilities/helperFunctions";
import {permissionKeys} from "../../i18next/keys";

function SelectTemplateOption(props) {
    const {t} = useTranslation(['workflow', 'common']);
    const dispatch = useDispatch();

    const {
        onClose,
        libraryId,
        isDisabled
    } = props;

    const [state, setState] = useState({libraryId: null, workflowTemplateId: null});
    function updateState(update) {
        setState(prevState => ({...prevState, ...update}));
    }

    function openWorkflowBuilder() {
        dispatch(WorkflowBuilderModel.actionCreators.openWithTemplate(libraryId, state.workflowTemplateId));
    }

    const librarySelector = useCallback(_state => {
        return getValues(_state.libraryDetailsMap);
    }, []);

    const workflowSelector = useCallback(_state => {
        return getValues(_state.workflowTemplateDetailsMap)
            .filter(template => template.libraryId === state.libraryId && template.userPermissions.includes(permissionKeys.VIEW_SENSITIVE));
    }, [state.libraryId]);

    const isAddEnabled = !!state.workflowTemplateId;
    return (
        <CustomModal isActive onCancel={onClose} style={{minWidth: '46rem'}}
            closeButtonAriaLabel={t('workflow:option.closeSelectTemplateForm')}
            aria-labelledby={'selectTemplateOptionHeader'}
            header={
                <section className="workflow-template__form-header">
                    <FormHeader headerId={'selectTemplateOptionHeader'} text={t('workflow:label.selectTemplate')}
                        isDisabled={isDisabled}/>
                </section>
            }
            body={
                <section className="workflow-template__select-template-body">
                    <LibraryPanel libraryId={state.libraryId} librarySelector={librarySelector}
                        updateState={updateState} isDisabled={isDisabled}/>

                    <WorkflowTemplatePanel libraryId={state.libraryId} workflowTemplateId={state.workflowTemplateId}
                        workflowSelector={workflowSelector} canUpload={false} updateState={updateState} isDisabled={isDisabled}/>
                </section>
            }
            footer={
                <section className="form-footer">
                    <FormFooter addText={t('common:option.start')} onAddClick={openWorkflowBuilder} onCancel={onClose}
                        isAddEnabled={isAddEnabled} isDisabled={isDisabled}/>
                </section>
            }
        />
    )
}

export default SelectTemplateOption;
