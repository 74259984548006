import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import UserServiceModel from "../../../models/user/UserServiceModel";
import Form, {FormHeader} from "../../common/Form/Form";
import {createCloseHandler, createInputHandler, createStateHandler} from "../../../utilities/componentFunctions";
import {createToggleHandler} from "../../common/Checkbox/helpers";
import PopupModel from "../../../models/scheduler/PopupModel";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import Checkbox from "../../common/Checkbox/Checkbox";
import TextArea from "../../common/TextArea/TextArea";
import {LinkLoginFormBody, SynchronizeUsersFormBody} from "../ldap/LdapUserServiceForm";
import {ButtonGroup} from "../../common/Button/Button";
import {createDropdownHandler} from "../../common/Dropdown/helpers";
import {loginLinkScopeKeys} from "../../../i18next/keys";
import UsersInputTable from "./UsersInputTable";
import {ValueList} from "../../common/InputList/InputList";

function ManagedUserServiceForm() {
    const {t} = useTranslation(['userService', 'common']);
    const dispatch = useDispatch();

    const {
        isDisabled
    } = useSelector(state => state.componentStates.userServiceForm);

    const [users, setUsers] = useState([]);
    const [state, setState] = useState({
        type: UserServiceModel.types.MANAGED,
        name: '',
        description: '',
        enabled: true,
        restrictByUrl: false,
        allowedUrls: [],

        enableLoginLinks: true,
        loginLinkScope: loginLinkScopeKeys.LEGAL_HOLD,
        // hours
        loginLinkAutoExpireInterval: 4,

        enableAuthentication: false,
        enableCollections: false,

        synchronizeUsers: true,
        usersEligibleLegalHoldAdministrator: true,
        usersEligibleLegalHoldCustodian: true
    });

    const stateHandler = createStateHandler({
        updateState: setState
    });
    const inputHandler = createInputHandler({
        handler: stateHandler
    })
    const toggleHandler = createToggleHandler({
        handler: stateHandler
    });
    const dropdownHandler = createDropdownHandler({
        handler: stateHandler
    });

    const [allowedUrls, setAllowedUrls] = useState([]);

    function onSubmit() {
        dispatch(UserServiceModel.actionCreators.submitForm({
            ...state,
            users,
            allowedUrls
        }));
    }

    const onClose = createCloseHandler({
        t,
        item: t('userService:label.name'),
        values: {...state, users},
        blacklist: ['type', 'loginLinkScope', 'loginLinkAutoExpireInterval'],
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        close: () => dispatch(UserServiceModel.actionCreators.hideForm(UserServiceModel.types.MANAGED))
    });

    const {
        name,
        description,
        enabled,
        restrictByUrl,

        loginLinkScope,
        loginLinkAutoExpireInterval,

        usersEligibleLegalHoldAdministrator,
        usersEligibleLegalHoldCustodian
    } = state;

    const isAddEnabled = UserServiceModel.validateManagedFormData({...state, users});

    return (
        <Form onClose={onClose} isDisabled={isDisabled} closeButtonAriaLabel={t('userService:option.closeFormManaged')}
            header={
                <FormHeader text={t('userService:label.managedUserServiceTitle')} iconName={`userServiceManaged`} isDisabled={isDisabled} />
            }
            body={
            <>
                <div className="display-input">
                    <HTMLTextInput label={t('common:label.name')} name={'name'} value={name} onChange={inputHandler}
                        isDisabled={isDisabled} isRequired/>
                </div>

                <Checkbox label={t('common:label.enabled')} name={'enabled'} checked={enabled} onClick={toggleHandler}
                    isDisabled={isDisabled}/>

                <div className="display-input">
                    <TextArea label={t('common:label.description')} name={'description'} value={description} onChange={inputHandler}
                        isDisabled={isDisabled}/>
                </div>

                <div className="display-input">
                    <Checkbox label={t('userService:label.restrictByUrl')} name={'restrictByUrl'}
                              checked={restrictByUrl} onClick={toggleHandler}
                              isDisabled={isDisabled}/>
                </div>

                {restrictByUrl &&
                    <div className="display-input">
                        <ValueList id={'allowedUrls'} label={t('userService:label.allowedUrls')}
                                   values={allowedUrls} setValues={setAllowedUrls}
                                   ariaLabelKey={'AllowedUrls'} isDisabled={isDisabled}/>
                    </div>
                }

                <SynchronizeUsersFormBody usersEligibleLegalHoldAdministrator={usersEligibleLegalHoldAdministrator}
                    usersEligibleLegalHoldCustodian={usersEligibleLegalHoldCustodian} toggleHandler={toggleHandler} isDisabled={isDisabled}/>

                <LinkLoginFormBody forceLoginLinks loginLinkScope={loginLinkScope} loginLinkAutoExpireInterval={loginLinkAutoExpireInterval}
                    inputHandler={inputHandler} toggleHandler={toggleHandler} dropdownHandler={dropdownHandler} isDisabled={isDisabled}/>

                <div className="display-input">
                    <UsersInputTable users={users} setUsers={setUsers} canModify
                        isDisabled={isDisabled}/>
                </div>
            </>
            }
            footer={
                <ButtonGroup buttons={[{
                    id: 'formFooterBackButton',
                    label: t('common:option.cancel'),
                    onClick: onClose,
                    isDisabled
                }, {
                    id: 'formFooterNextButton',
                    label: t('userService:option.addUserService'),
                    onClick: onSubmit,
                    isDisabled: isDisabled || !isAddEnabled
                }]}/>
            }
        />
    )
}

export default ManagedUserServiceForm;
