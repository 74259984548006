import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {createEditHandler, createInputHandler} from "../../utilities/componentFunctions";
import {isNotEmptyNorFalsy, switchcaseF} from "../../utilities/helperFunctions";
import Tablet, {EditTabletHeader} from "../common/Tablet/Tablet";
import {DefaultEditPanel} from "../common/EditPanel/EditPanel";
import ExpandableContent, {
    ExpandableEditTextArea,
    ExpandableWorkflowParametersV2
} from "../common/ExpandableContent/ExpandableContent";
import {SwitchItemContainer} from "../common/ListContainer/ListContainer";
import {makeGetEditDetails} from "../../reselect/selectors";
import {shouldEnableClientPool} from "../../utilities/shouldEnableFunctions";
import {permissionKeys, statusKeys} from "../../i18next/keys";
import ClientPoolModel from "../../models/settings/ClientPoolModel";
import EditModel from "../../models/scheduler/EditModel";
import KeyValueList from "../common/KeyValueList/KeyValueList";
import ParameterModel from "../../models/library/ParameterModel";
import {InputTableV2} from "../common/InputTable/InputTable";
import DatasetModel from "../../models/data/DatasetModel";
import LimitedTablet from "../limited/LimitedTablet";
import ClientModel from "../../models/client/ClientModel";
import {ExpandableStatusLog} from "../common/Common";
import {onWorkflowParameterNormalize} from "../common/InputTable/helpers";


const selectEditDetails = makeGetEditDetails();
function selectClientPoolDetails (state, props) {
    const {activeModel, values, isSaveEnabled} = selectEditDetails(state, {model: ClientPoolModel.nom});
    const isEditActive = (activeModel === ClientPoolModel.nom);

    const {name: clientPoolName, clientIds, ...others} = state.clientPoolDetailsMap.get(props.clientPoolId);
    const clients = clientIds.map(id => (state.clientDetailsMap.get(id) || {}).name)
        .sort();

    return {
        clientPoolName,
        clients,
        ...others,
        ...values,
        isEditActive,
        isSaveEnabled
    }
}

function ClientPoolTablet(props) {
    const {t} = useTranslation(['clientPool', 'dataset', 'common']);
    const dispatch = useDispatch();

    const {isDisabled} = useSelector(state => state.componentStates.clientPoolTablet);

    const {clientPoolId} = props;
    const {
        clientPoolName,
        description,
        clients,
        allowedParameterValues,
        requiredMetadataHeaders,
        workflowParameters,
        status,
        userPermissions,
        isEditActive,
        isSaveEnabled
    } = useSelector(state => selectClientPoolDetails(state, {clientPoolId}));

    const {
        updateEdit,
        setEditSaveEnabled,
        saveEdit,
        cancelEdit
    } = EditModel.buildDispatchers(dispatch);

    const editHandler = createEditHandler({
        updateEdit,
        setEditSaveEnabled,
        shouldEnable: shouldEnableClientPool,
        values: {clientPoolName}
    });
    const inputHandler = createInputHandler({
        handler: editHandler
    });

    const [clearParameterPasswordState, setClearParameterPasswordState] = useState({});
    useEffect(() => {
        setClearParameterPasswordState({});
    }, [isEditActive]);

    const workflowParameterNormalizeHandler = onWorkflowParameterNormalize(clearParameterPasswordState, setClearParameterPasswordState);

    function setClients(update) {
        const updates = {
            clients: update(clients)
        };
        editHandler(updates);
    }

    function setAllowedParameterValues(update) {
        const updates = {
            allowedParameterValues: update(allowedParameterValues)
        };
        editHandler(updates);
    }

    function setRequiredMetadataHeaders(update) {
        const updates = {
            requiredMetadataHeaders: update(requiredMetadataHeaders)
        }
        editHandler(updates);
    }

    function setWorkflowParameters(update) {
        const updates = {
            workflowParameters: update(workflowParameters)
        };

        editHandler(updates);
    }

    function menuOptionHandler(event) {
        const {value} = event.currentTarget.dataset;
        switchcaseF({
            'edit': () => dispatch(ClientPoolModel.actionCreators.startEdit(clientPoolId)),
            'promptDelete': () => dispatch(ClientPoolModel.actionCreators.promptDelete(clientPoolId)),
            'duplicate': () => dispatch(ClientPoolModel.actionCreators.duplicateDetails(clientPoolId))
        })()(value);
    }

    const menuOptions = [
        {name: t('common:option.edit'), value: 'edit'},
        {name: t('common:option.delete'), value: 'promptDelete'},
        {isSeparator: true},
        {name: t('common:option.duplicate'), value: 'duplicate'},
    ];


    const canModifyClientPool = userPermissions.includes(permissionKeys.MODIFY) || userPermissions.includes(permissionKeys.MODIFY_CHILDREN);
    const showDescription = isEditActive || !!description;
    const showClients = isEditActive || isNotEmptyNorFalsy(clients);
    const showAllowedParameterValues = isEditActive || isNotEmptyNorFalsy(allowedParameterValues);
    const showRequiredMetadataHeaders = isEditActive || isNotEmptyNorFalsy(requiredMetadataHeaders);
    const showWorkflowParameters = isEditActive || isNotEmptyNorFalsy(workflowParameters);

    const height = isEditActive ? 'auto' : '50vh';

    const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);
    if (isViewLimited && !canModifyClientPool) {
        return (
            <LimitedTablet id={clientPoolId} type={'clientPool'} name={clientPoolName} label={t(`clientPool:label.name`)}
                           enabled={true} canModify={canModifyClientPool} isDisabled={isDisabled} description={description}
                           onClose={() => dispatch(ClientPoolModel.actionCreators.hideTablet())}
            />
        )
    }

    return (
        <Tablet height={height} onClose={() => dispatch(ClientPoolModel.actionCreators.hideTablet())}
            isDisabled={isDisabled} closeButtonAriaLabel={t('clientPool:option.closeTablet_name', {name})}
            header={
                <EditTabletHeader label={t('clientPool:label.name')} type={'clientPool'}
                    id={clientPoolId} name={'clientPoolName'} value={clientPoolName} inputHandler={inputHandler}
                    canModify={canModifyClientPool} menuOptions={menuOptions} menuOptionHandler={menuOptionHandler}
                    isEditActive={isEditActive} isDisabled={isDisabled}
                />
            }
            body={
                <>
                    {status.code === statusKeys.ERROR && !isEditActive &&
                        <div className="display-item">
                            <ExpandableStatusLog {...status} isDisabled={isDisabled}/>
                        </div>
                    }

                    <div className="display-item">
                        <DefaultEditPanel isActive={isEditActive} isSaveEnabled={isSaveEnabled} onSave={saveEdit} onCancel={cancelEdit}>
                            {showDescription &&
                            <ExpandableEditTextArea label={t('common:label.description')} name={'description'} value={description}
                                onChange={inputHandler} isEditActive={isEditActive} isDisabled={isDisabled}
                            />
                            }

                            {showClients &&
                            <ExpandableContent label={t('clientPool:label.clients')} isDisabled={isDisabled}>
                                {isEditActive ?
                                    <SwitchItemContainer id="clientSwitchContainer" items={clients} setItems={setClients}
                                        ariaLabelKey={'Client'} isDisabled={isDisabled}
                                    />
                                    :
                                    clients.map((name, index) =>
                                        <label key={index} className="label">
                                            {name}
                                        </label>
                                    )
                                }
                            </ExpandableContent>
                            }

                            {showWorkflowParameters &&
                                <div className="display-item">
                                    <ExpandableWorkflowParametersV2 label={t('client:label.workflowParameters')} name={'workflowParameters'} force={workflowParameters.length > 0}
                                                                  initialExpanded={workflowParameters.length > 0} isEditActive={isEditActive} workflowParameters={workflowParameters}
                                                                  headers={[{value: t('client:header.name')}, {value: t('client:header.value')}]}
                                                                  inputTable={workflowParameters} setInputTable={setWorkflowParameters}
                                                                  isReadOnly={!isEditActive} defaultRow={ClientModel.workflowParametersRowTemplate}
                                                                  blurHandler={[ParameterModel.normalizeParameterName, null]} inputNormalizer={workflowParameterNormalizeHandler}
                                    />
                                </div>
                            }

                            {showAllowedParameterValues &&
                            <div className="display-item">
                                <ExpandableContent label={t('matter:label.allowedParameters')} isDisabled={isDisabled}
                                    initialExpanded={allowedParameterValues.length > 0}
                                >
                                    <KeyValueList keysTitle={t('workflow:label.parameterName')} style={{marginTop: '0.25rem'}}
                                        blurHandler={ParameterModel.normalizeParameterName} entries={allowedParameterValues} setEntries={setAllowedParameterValues}
                                        ariaLabelKey={'Parameter'} isReadOnly={!isEditActive} isDisabled={isDisabled} isMoveEnabled={true}
                                    />
                                </ExpandableContent>
                            </div>
                            }

                            {showRequiredMetadataHeaders &&
                            <div className="display-item">
                                <ExpandableContent label={t('dataset:label.requiredMetadataHeaders')} isDisabled={isDisabled}
                                    initialExpanded={requiredMetadataHeaders.length > 0}
                                >

                                    <InputTableV2 headers={[{value: t('dataset:label.header')}, {value: t('dataset:label.regex')}]}
                                        inputTable={requiredMetadataHeaders} setInputTable={setRequiredMetadataHeaders}
                                        ariaLabelKey={'RequiredMetadata'}
                                        defaultRow={DatasetModel.requiredMetadataHeadersRowTemplate} isReadOnly={!isEditActive}
                                    />
                                </ExpandableContent>
                            </div>
                            }

                        </DefaultEditPanel>
                    </div>
                </>
            }
        />
    )
}

export default ClientPoolTablet;