import React, {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import NavigatePane from "../common/NavigatePane/NavigatePane";
import PopupModel from "../../models/scheduler/PopupModel";
import {permissionKeys, popupInfoKeys} from "../../i18next/keys";
import {objEqualsNotOrdered, switchcase} from "../../utilities/helperFunctions";
import HoldConfirmPane from "./panes/HoldConfirmPane";
import HoldNoticeConfigurationsPane from "./panes/HoldNoticeConfigurationsPane";
import LegalHoldModel from "../../models/legalhold/LegalHoldModel";
import {makeGetEditDetails} from "../../reselect/selectors";
import {updateMode} from "../../utilities/constants";
import HoldAdministratorsPane from "./panes/HoldAdministratorsPane";
import HoldMatterPane from "./panes/HoldMatterPane";
import HoldCustodiansPane from "./panes/HoldCustodiansPane";
import HoldSettingsPane from "./panes/HoldSettingsPane";
import HoldTriggerConfigurationPane from "./panes/HoldTriggerConfigurationPane";


function LegalHoldForm() {
    const {t} = useTranslation(['legalHold', 'common']);
    const dispatch = useDispatch();


    const legalHoldForm = useSelector(state => state.componentStates.legalHoldForm);
    const noticeTemplateDetailsMap = useSelector(state => state.noticeTemplateDetailsMap);
    const {
        clientId,
        userPermissions,
        force,
        isDisabled
    } = legalHoldForm;

    // useMemo to memoize selector instance
    const getEditDetails = useMemo(makeGetEditDetails, []);
    const mode = useSelector(state => {
        return getEditDetails(state, {model: LegalHoldModel.nom})
            .activeModel === LegalHoldModel.nom ? updateMode.EDIT : updateMode.REDUX
    });

    // Only considering 1st pane values (can't proceed to 2nd pane without passing 1st)
    const onClose = useCallback(() => {
        const close = () => dispatch(LegalHoldModel.actionCreators.hideForm());

        if (clientId == null || mode === updateMode.EDIT) {
            close();
        } else {

            dispatch(PopupModel.actionCreators.showWarning({
                info: {
                    key: popupInfoKeys.DISCARD_LEGAL_HOLD
                },
                buttons: [{
                    title: t('common:option.discard'),
                    onClick: close
                }]
            }));
        }
    }, [mode, clientId, dispatch]);

    function onSubmit() {
        const submit = () => dispatch(LegalHoldModel.actionCreators.submitForm());
        const {
            id,
            state,
            noticeTemplateItemKeyToSurveyFormOptions,
            noticeTemplateItems,

            force,
            isDisabled,

            initialValues,
            ...formValues
        } = legalHoldForm;


        if (!objEqualsNotOrdered(initialValues, formValues)) {
            submit();
        } else {
            dispatch(PopupModel.actionCreators.showWarning({
                info: {
                    key: popupInfoKeys.DUPLICATE_VALUES
                },
                buttons: [{
                    title: t('common:option.add'),
                    onClick: submit
                }]
            }));
        }
    }

    const saveEdit = () => dispatch(LegalHoldModel.actionCreators.saveEdit());
    const cancelEdit = () => dispatch(LegalHoldModel.actionCreators.cancelEdit());


    const [onCancel, submitTitle, submit] = switchcase({
        [updateMode.REDUX]: [onClose, t('common:option.add'), onSubmit],
        [updateMode.EDIT]: [cancelEdit, t('common:option.save'), saveEdit]
    })()(mode);

    const nextEnabled = LegalHoldModel.validateFormData(legalHoldForm, {noticeTemplateDetailsMap});

    const canModify = userPermissions == null || userPermissions.includes(permissionKeys.MODIFY);
    const panes = [];

    if (canModify) {
        panes.push({
            title: t('job:label.queueMatter'), isNextEnabled: nextEnabled.matterPane,
            backTitle: t('common:option.cancel'), onBackClick: onCancel,
            component: <HoldMatterPane/>
        }, {
            title: t('legalHold:label.notices'), isNextEnabled: nextEnabled.noticeConfigurationsPane,
            component: <HoldNoticeConfigurationsPane/>
        }, {
            title: t('legalHold:label.triggers'), isNextEnabled: nextEnabled.triggerConfigurationPane,
            onNextClick: () => dispatch(LegalHoldModel.actionCreators.prepareSettings()),
            component: <HoldTriggerConfigurationPane/>
        }, {
            title: t('common:label.settings'), isNextEnabled: nextEnabled.settingsPane,
            component: <HoldSettingsPane/>
        }, {
            title: t('legalHold:label.administrators'), isNextEnabled: nextEnabled.administratorsPane,
            component: <HoldAdministratorsPane/>
        }, {
            title: t('legalHold:label.custodians'), isNextEnabled: nextEnabled.custodiansPane,
            component: <HoldCustodiansPane/>
        });
    } else {
        panes.push({
            title: t('legalHold:label.custodians'), isNextEnabled: nextEnabled.custodiansPane,
            backTitle: t('common:option.cancel'), onBackClick: onCancel,
            component: <HoldCustodiansPane/>
        })
    }

    panes.push( {
        title: t('common:label.confirm'), nextTitle: submitTitle, onNextClick: submit, isNextEnabled: nextEnabled.submit,
        component: <HoldConfirmPane/>
    });

    return (
        <NavigatePane id={'legalHoldForm'} panes={panes} onClose={onClose} force={force}
            isDisabled={isDisabled} bodyStyle={{height: '62.5vh'}} closeButtonAriaLabel={t('legalHold:option.closeForm')}/>
    )
}

export default LegalHoldForm;
