import {useTranslation} from "react-i18next";
import {InputTableV2} from "../../common/InputTable/InputTable";
import UserServiceModel from "../../../models/user/UserServiceModel";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {AddRemoveButtons, ImportExportCsvButtons} from "../../common/Button/Button";
import {isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";

function UsersInputTable(props) {
    const {t} = useTranslation(['userService', 'common']);
    const {
        users,
        setUsers,
        exportUsersCsv,
        canModify
    } = props;

    const [isDisabled, setIsDisabled] = useState();
    useEffect(() => {
        if (isDisabled !== props.isDisabled) {
            setIsDisabled(props.isDisabled);
        }
    }, [props.isDisabled, isDisabled]);

    const uploadUsersCsv = useCallback(function(resultArray) {
        const firstRow = resultArray.shift();
        const colIndices = {};

        for (let i = 0; i < firstRow.length; i++) {
            const cellValue = firstRow[i].toUpperCase();
            if (cellValue === 'NAME') {
                colIndices.name = i;
            } else if (cellValue === 'EMAIL') {
                colIndices.email = i;
            }
        }

        const hasHeaders = isNotEmptyNorFalsy(colIndices);
        if (!hasHeaders) {
            resultArray.unshift(firstRow);
            colIndices.name = 0;
            colIndices.email = 1;
        }

        const users = resultArray.reduce((acc, row) => {
            const name = row[colIndices.name] || '';
            const email = row[colIndices.email] || '';
            if (name || email) {
                acc.push([{value: name}, {value: email}]);
            }
            return acc;
        }, []);

        setUsers(users);
    }, []);

    const InputTableHeader = useMemo(() => {
        return headerProps =>
            <UsersInputTableHeader {...headerProps} canModify={canModify} setIsDisabled={setIsDisabled}
                uploadUsersCsv={uploadUsersCsv} exportUsersCsv={exportUsersCsv}/>
    }, [uploadUsersCsv, exportUsersCsv, canModify]);

    return (
        <InputTableV2 id="usersInputTable" label={t('userService:label.users')} headers={[{value: t('common:label.name')}, {value: t('common:label.email')}]}
            inputTable={users} setInputTable={setUsers} defaultRow={UserServiceModel.managedUsersRowTemplate} isDisabled={isDisabled} isRequired
            HeaderComponent={InputTableHeader}
            ariaLabelKey={'User'}
        />
    )
}

function UsersInputTableHeader(props) {
    const {
        label,
        onAddClick,
        onRemoveClick,
        noPadding,
        isReadOnly,
        isRemoveDisabled,
        isDisabled,

        setIsDisabled,
        hasUsers,
        canModify,
        exportUsersCsv,
        uploadUsersCsv
    } = props;

    return (
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <AddRemoveButtons label={label} onAddClick={onAddClick} isReadOnly={isReadOnly} noPadding={noPadding}
                ariaLabelKey={'User'} onRemoveClick={onRemoveClick}
                isRemoveDisabled={isRemoveDisabled} isDisabled={isDisabled}/>

            <ImportExportCsvButtons importInputId={'managedUserServiceUsersCsvUploadInput'} importCsv={uploadUsersCsv}
                exportCsv={exportUsersCsv} canModify={canModify} setIsDisabled={setIsDisabled}
                ariaLabelKey={'User'} isExportDisabled={!hasUsers} isDisabled={isDisabled}/>
        </div>
    )
}

export default UsersInputTable;
