import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    StatusNameDescriptionCell,
    TableEnableToggleCell,
    TableLabelCell,
    TableRow
} from "../common/CustomTable/CustomTable";
import UserServiceModel from "../../models/user/UserServiceModel";
import {permissionKeys} from "../../i18next/keys";
import {useTranslation} from "react-i18next";
import LimitedTableRow from "../limited/LimitedTableRow";

const UserServiceRow = props => {
    const {t} = useTranslation(['userService', 'common']);
    const dispatch = useDispatch();

    const {
        userServiceId
    } = props;

    const isActive = useSelector(state => state.componentStates.userServiceDisplay.userServiceId === userServiceId);
    const userService = useSelector(state => state.userServiceDetailsMap.get(userServiceId));

    const {
        type,
        name,
        description,
        enabled,
        serviceAccountName,
        searchDelay,

        synchronizeUsers,
        synchronizeComputers,
        enableLoginLinks,

        status,
        warningStatus,
        userPermissions
    } = userService;

    function showUserServiceTablet() {
        dispatch(UserServiceModel.actionCreators.showTablet(userServiceId));
    }

    function toggleEnabled() {
        dispatch(UserServiceModel.actionCreators.toggleEnabled(userServiceId));
    }

    const canTypeSync = UserServiceModel.canTypeSyncUsers(type);
    const canModify = userPermissions && userPermissions.includes(permissionKeys.MODIFY);
    const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);

    if (isViewLimited && !canModify) {
        let cellCount = 2;
        if (canTypeSync) {
            cellCount += 3;
        }
        if (type === UserServiceModel.types.LDAP) {
            cellCount += 2;
        }

        return (
            <LimitedTableRow id={userServiceId} isActive={isActive} onClick={showUserServiceTablet} cellCount={cellCount}
                             nameComponent={<StatusNameDescriptionCell statuses={[status, warningStatus]} name={name} description={description}/>}
                             toggleComponent={<TableEnableToggleCell ariaLabelKey={userService.type} displayName={name} name={userServiceId}
                                                               checked={enabled} onClick={toggleEnabled} isReadOnly={!canModify}/>}
            />
        )
    }

    return (
        <TableRow value={userServiceId} isActive={isActive} onClick={showUserServiceTablet}>
            <TableEnableToggleCell ariaLabelKey={userService.type} displayName={name} name={userServiceId}
                checked={enabled} onClick={toggleEnabled} isReadOnly={!canModify}/>

            <StatusNameDescriptionCell statuses={[status, warningStatus]}
                name={name} description={description}/>
            {canTypeSync &&
                <TableLabelCell label={synchronizeUsers ? t('common:option.yes') : t('common:option.no')}/>
            }

            {type === UserServiceModel.types.LDAP &&
                <TableLabelCell label={synchronizeComputers ? t('common:option.yes') : t('common:option.no')}/>
            }
            {canTypeSync &&
                <>
                    <TableLabelCell label={(synchronizeUsers || synchronizeComputers) ? serviceAccountName : ""}/>

                    <TableLabelCell label={(synchronizeUsers || synchronizeComputers) ? searchDelay : ""}/>
                </>
            }

            {type === UserServiceModel.types.LDAP &&
                <TableLabelCell label={enableLoginLinks ? t('common:option.yes') : t('common:option.no')}/>
            }
        </TableRow>
    );
}

export default UserServiceRow;
