import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import ExecutionProfileTable from "./ExecutionProfileTable";
import ExecutionProfileForm from "./ExecutionProfileForm";
import {getExecutionProfileIds} from "../../reselect/selectors";
import {details} from "../../utilities/constants";
import ExecutionProfileTablet from "./ExecutionProfileTablet";
import ExecutionProfileModel from "../../models/settings/ExecutionProfileModel";
import {MainPageContent, MainPageHeader} from "../app/pages/MainPage";

class ExecutionProfileDisplay extends Component {

    componentDidMount() {
        this.props.setDisplayActive();
    }

    componentWillUnmount() {
        this.props.setDisplayInactive();
    }

    render() {

        const {
            t,
            isLoading,
            executionProfileId,
            executionProfileIds,
            isExecutionProfileFormActive,
            ...attr
        } = this.props;

        return (
            <MainPageContent {...attr}
                title={
                    <MainPageHeader text={t('executionProfile:page.title')} description={t('executionProfile:page.description')} />
                }
                list={
                    <LoadingWrapper isLoading={isLoading}>
                        <ExecutionProfileTable />
                    </LoadingWrapper>
                }
                children={
                    (executionProfileIds.includes(executionProfileId) && <ExecutionProfileTablet />)
                    ||
                    (isExecutionProfileFormActive && <ExecutionProfileForm />)
                }
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state.componentStates.executionProfileDisplay,
        executionProfileIds: getExecutionProfileIds(state),
        isLoading: !state.hasLoaded[details.EXECUTION_PROFILES]
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setDisplayActive: () => dispatch(ExecutionProfileModel.componentActionCreators.setDisplayActive()),
        setDisplayInactive: () => dispatch(ExecutionProfileModel.componentActionCreators.setDisplayInactive())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['executionProfile'])(ExecutionProfileDisplay));