import React from "react";
import {useTranslation} from "react-i18next";
import ExpandableContent from "../common/ExpandableContent/ExpandableContent";
import {TableLabelCell, TableLabelHeader} from "../common/CustomTable/CustomTable";
import {noticeTypeKeys} from "../../utilities/constants";
import {useSelector} from "react-redux";
import Switch from "../common/Switch/Switch";
import Text from "../common/Text/Text";
import {
    getLocaleStringFromFormattedDate,
    getMapValueName,
    getPluralTranslations
} from "../../utilities/helperFunctions";


export function ExpandableNoticeConfigurations(props) {
    const {t} = useTranslation(['legalHold', 'noticeTemplate']);

    const {
        holdNoticeConfiguration,
        releaseNoticeConfiguration,
        surveyNoticeConfigurations,
        recurringNoticeConfiguration,

        isDisabled
    } = props;


    return (
        <ExpandableContent label={t('legalHold:label.noticeConfigurations')} isDisabled={isDisabled}>
            <div className="display-table tight-padding">
                <div className="table-header-group">
                    <TableLabelHeader label={t('noticeTemplate:label.type')} style={{width: '8rem'}}
                        isDisabled={isDisabled}/>

                    <TableLabelHeader label={t('noticeTemplate:label.template')} isDisabled={isDisabled}/>

                    <TableLabelHeader label={t('noticeTemplate:label.respondBy')} isDisabled={isDisabled}/>

                    <TableLabelHeader label={t('noticeTemplate:label.remindEvery')} isDisabled={isDisabled}/>

                    <TableLabelHeader label={t('noticeTemplate:label.reminderTemplate')} isDisabled={isDisabled}/>

                    <TableLabelHeader label={t('noticeTemplate:label.escalateTemplate')} isDisabled={isDisabled}/>
                </div>

                <div className="table-row-group">
                    <NoticeConfigurationRow type={noticeTypeKeys.HOLD} noticeConfiguration={holdNoticeConfiguration}
                        isDisabled={isDisabled}/>

                    {(recurringNoticeConfiguration != null && recurringNoticeConfiguration.enabled) &&
                        <NoticeConfigurationRow type={noticeTypeKeys.RECURRING} noticeConfiguration={recurringNoticeConfiguration}
                                                isDisabled={isDisabled}/>
                    }

                    <NoticeConfigurationRow type={noticeTypeKeys.RELEASE} noticeConfiguration={releaseNoticeConfiguration}
                        isDisabled={isDisabled}/>

                    {surveyNoticeConfigurations.map((surveyNoticeConfiguration, index) =>
                        <NoticeConfigurationRow key={index} type={noticeTypeKeys.SURVEY} noticeConfiguration={surveyNoticeConfiguration}
                            isDisabled={isDisabled}/>
                    )}
                </div>
            </div>
        </ExpandableContent>
    )
}


function selectNoticeTemplateNames(state, config) {
    return {
        noticeTemplate: getMapValueName(state.noticeTemplateDetailsMap, config.noticeTemplateId),
        reminderNoticeTemplate: getMapValueName(state.noticeTemplateDetailsMap, config.reminderNoticeTemplateId),
        escalateNoticeTemplate: getMapValueName(state.noticeTemplateDetailsMap, config.escalateNoticeTemplateId)
    };
}

function NoticeConfigurationRow(props) {
    const {t} = useTranslation(['noticeTemplate']);

    const {
        type,
        noticeConfiguration
    } = props;

    const templateNames = useSelector(state => selectNoticeTemplateNames(state, noticeConfiguration));
    const {
        enabled,

        requireResponse,
        respondByKey,
        respondByDate,
        respondByNDays,

        enableRemind,
        remindEveryNDays,

        escalateOnResponseFail,
    } = noticeConfiguration;

    const isDisabled = props.isDisabled || !enabled;

    return (
        <div className="table-row no-pointer">
            <TableLabelCell label={t(`noticeTemplate:type.${type}`)}
                isDisabled={isDisabled}/>

            <TableLabelCell label={templateNames.noticeTemplate}
                isDisabled={isDisabled}/>

            <div className="table-cell">
                {requireResponse &&
                <Switch>
                    {respondByKey === 'respondByDate' &&
                    <Text value={getLocaleStringFromFormattedDate(respondByDate)}
                        isDisabled={isDisabled}/>
                    }
                    {respondByKey === 'respondByNDays' &&
                    <Text value={getPluralTranslations(t, 'common:label.NDays', {count: respondByNDays})}
                        isDisabled={isDisabled}/>
                    }
                </Switch>
                }
            </div>

            <div className="table-cell">
                {enableRemind &&
                <Text value={getPluralTranslations(t, 'common:label.NDays', {count: remindEveryNDays})}
                    isDisabled={isDisabled}/>
                }
            </div>

            <div className="table-cell">
                {enableRemind &&
                <Text value={templateNames.reminderNoticeTemplate}
                    isDisabled={isDisabled}/>
                }
            </div>

            <div className="table-cell">
                {escalateOnResponseFail &&
                <Text value={templateNames.escalateNoticeTemplate}
                    isDisabled={isDisabled}/>
                }
            </div>
        </div>
    );
}

export default ExpandableNoticeConfigurations;
