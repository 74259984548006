import React, {useEffect, useRef, useState} from "react";
import "./FormBuilder.css";
import {AddRemoveButtons, Button} from "../Button/Button";
import {
    initialSelectedState,
    useClearSelectedEffect,
    useMoveUpDown,
    useTabNavigateEffect,
    useValueSelectHandler
} from "../../../utilities/hooks";
import {
    buildClassName,
    filterArrayIndices,
    getNewArrayWithUpdatedValue,
    getParentDatasetAttr,
    getValues,
    isNotEmptyNorFalsy,
    isSomeTruthy,
    objectTruthyValues
} from "../../../utilities/helperFunctions";
import {useTranslation} from "react-i18next";
import {ListDropdown} from "../Dropdown/Dropdown";
import TextArea from "../TextArea/TextArea";
import Switch from "../Switch/Switch";
import HTMLTextInput from "../HTMLTextInput/HTMLTextInput";
import Checkbox from "../Checkbox/Checkbox";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import {createInputHandler, createUpdateHandler} from "../../../utilities/componentFunctions";
import {createDropdownHandler} from "../Dropdown/helpers";
import {createToggleHandler} from "../Checkbox/helpers";
import {ValuesBox} from "../KeyValueList/KeyValueList";
import NoticeTemplateModel from "../../../models/notice/NoticeTemplateModel";
import NavigateButtons from "../NavigateButtons/NavigateButtons";
import Text from "../Text/Text";
import DataUpload from "./DataUpload";
import {icon} from "../../../utilities/iconResolver";
import {formElementTypes} from "../../../utilities/constants";
import {getInvalidMessageSpan} from "../InvalidMessageSpan";

// [{}, {}] = formOptions
function FormBuilder(props) {
    const {t} = useTranslation(['formBuilder', 'common']);

    const {
        id,
        label,
        formOptions,
        setFormOptions,
        onCreateDataset,
        setUploadActive,

        showHiddenValues,
        isBold,
        isDisabled
    } = props;

    const containerRef = useRef();
    const [selected, setSelected] = useState(initialSelectedState);

    const valueSelectHandler = useValueSelectHandler({setSelected});
    const [moveUp, moveDown] = useMoveUpDown({selected, setSelected, setArray: setFormOptions});
    useClearSelectedEffect({containerRef, setSelected});
    useTabNavigateEffect({containerRef});

    const [isPreviewMode, setIsPreviewMode] = useState(false);

    const inputHandler = createInputHandler({
        handler
    });
    const toggleHandler = createToggleHandler({
        handler
    });
    const dropdownHandler = createDropdownHandler({
        handler
    });
    const allowedValuesHandler = createUpdateHandler({
        handler,
        name: 'allowedValues'
    });


    const [uploadActive, _setUploadActive] = useState({})

    function onUploadActive(key, val) {
        _setUploadActive(prev => ({
            ...prev,
            [key]: !!val
        }));
    }

    useEffect(() => {
        if (typeof setUploadActive === 'function') {
            setUploadActive(isSomeTruthy(uploadActive));
        }
    }, [uploadActive]);


    function addRow() {
        setFormOptions(prevFormOptions => ([
            ...prevFormOptions,
            NoticeTemplateModel.makeFormElementRow()
        ]));
    }

    function removeSelectedRows() {
        setFormOptions(prevFormOptions => {
            const selectedRowIndices = objectTruthyValues(selected.values);

            return filterArrayIndices(prevFormOptions, selectedRowIndices);
        });

        setSelected(prevSelected => ({
            ...prevSelected,
            values: {},
            lastSelectedValue: null
        }));
    }

    function togglePreview() {
        setIsPreviewMode(prev => !prev);
    }

    function handler(updates, event) {
        const index = getParentDatasetAttr(event.target || event.currentTarget, 'index');
        // update row
        setFormOptions(prevFormOptions => {
            const newOptions = {
                ...prevFormOptions[index],
                ...updates
            };

            return getNewArrayWithUpdatedValue(prevFormOptions, newOptions, index);
        });
    }


    const isRequired = props.isRequired && formOptions.every(opt => !opt.enabled);
    const isEditActive = props.isEditActive && !props.isReadOnly && !isPreviewMode;
    const isReadOnly = props.isReadOnly;


    let evaluatedFormOptions = formOptions;
    if (isNotEmptyNorFalsy(props.parameters) && (!isEditActive || isPreviewMode)) {
        evaluatedFormOptions = evaluatedFormOptions.map(opt => NoticeTemplateModel.evaluateParametersInputTable(opt, props.parameters));
    }


    const disableSelection = isDisabled || !isEditActive;
    const selectedLength = objectTruthyValues(selected.values).length;

    const tableClassName = buildClassName(
        'form-table',
        'align-top',
        isRequired && 'is-required',
        isEditActive && 'is-edit',
        (isReadOnly || isPreviewMode) && 'is-readonly'
    );

    return (
        <div id={id || 'formBuilder'} className="form-builder" ref={containerRef}>

            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {isEditActive &&
                    <>
                    <AddRemoveButtons onAddClick={addRow} isReadOnly={!isEditActive} noPadding isBold={isBold}
                        ariaLabelKey={'Option'} onRemoveClick={removeSelectedRows}
                        isRemoveDisabled={selectedLength < 1} isDisabled={isDisabled}
                    />

                    <Text value={label} style={{margin: '0 0.5rem'}}
                        isDisabled={isDisabled}/>

                    <NavigateButtons onClick={[moveUp, moveDown]} length={[selectedLength, selectedLength]}
                        ariaLabelKey={'Option'} isVertical isDisabled={isDisabled}/>
                    </>
                    }
                </div>

                {props.isEditActive &&
                <Button id="previewButton" aria-label={t('common:label.preview')} onClick={togglePreview}
                    isImg isClear isDisabled={isDisabled}
                >
                    <span className="icon">
                        {isPreviewMode ?
                            <img src={icon('actionPreviewCancel')}
                                alt={t('aria:hiddenAssistText.actionPreviewCancelIcon')}/>
                            :
                            <img src={icon('actionPreview')}
                                alt={t('aria:hiddenAssistText.actionPreviewIcon')}/>
                        }
                    </span>
                </Button>
                }
            </div>


            <div className={tableClassName}>
                <div className="table-row-group">
                    {evaluatedFormOptions
                        .map((opt, index) => {
                            if (!isEditActive) {
                                // Handler uses index to update formOptions
                                // need to return null to update correct index
                                if (!opt.enabled || !NoticeTemplateModel.isFormOptionValid(opt)) {
                                    return null;
                                }
                            }

                            const rowClassName = buildClassName(
                                'table-row',
                                isEditActive && !isDisabled && selected.values[index] && 'is-selected',
                                (isDisabled || !isEditActive) && 'no-hover'
                            );

                            return (
                                <div className={rowClassName} key={index} data-index={index} data-option-type={opt.type}
                                    onClick={disableSelection ? null : valueSelectHandler}
                                >
                                    {isEditActive &&
                                    <>
                                        <div className="table-cell no-stretch">
                                            <ToggleSwitch name={'enabled'} checked={opt.enabled}
                                                onClick={toggleHandler} isDisabled={isDisabled}/>
                                        </div>

                                        <div className="table-cell" style={{padding: '0.5rem 0', width: '121px'}}>
                                            <FormElementTypeDropdown id={`formElementTypeDropdown_${index}`} name={'type'} selectedFormElementType={opt.type}
                                                onFormElementTypeSelect={dropdownHandler} isDisabled={!opt.enabled || isDisabled}/>

                                            {opt.type !== formElementTypes.HEADER &&
                                            <Checkbox name={'optional'} checked={opt.optional} style={{marginTop: '0.75rem'}}
                                                label={t('common:label.optional')} onClick={toggleHandler} isDisabled={!opt.enabled || isDisabled}/>
                                            }
                                        </div>
                                    </>
                                    }

                                    <FormElementCell index={index} cellOptions={opt} onCreateDataset={onCreateDataset} onUploadActive={onUploadActive}
                                        allowedValuesHandler={isPreviewMode ? null : allowedValuesHandler} inputHandler={isPreviewMode ? null : inputHandler}
                                        toggleHandler={isPreviewMode ? null : toggleHandler} dropdownHandler={isPreviewMode ? null : dropdownHandler}
                                        isReadOnly={isReadOnly} isEditActive={isEditActive} isDisabled={!opt.enabled || isDisabled} showHiddenValues={showHiddenValues}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}


const optionalQuestions = [
    formElementTypes.HEADER,
    formElementTypes.CHECKBOX,
    formElementTypes.DATA_UPLOAD
];

function FormElementCell(props) {
    const {t} = useTranslation(['formBuilder', 'common']);
    const {
        index,
        cellOptions,

        allowedValuesHandler,
        inputHandler,
        toggleHandler,
        dropdownHandler,
        onCreateDataset,
        onUploadActive,

        showHiddenValues,
        isEditActive,
        isDisabled
    } = props;

    const containerRef = useRef();
    const [selected, setSelected] = useState({
        ...initialSelectedState,
        key: 'allowedValues'
    });

    const valueSelectHandler = useValueSelectHandler({setSelected, key: 'allowedValues'});
    useClearSelectedEffect({containerRef, setSelected});
    useTabNavigateEffect({containerRef});


    function addAllowedValue(event) {
        allowedValuesHandler([
            ...cellOptions.allowedValues,
            ''
        ], event);
    }

    function removeSelectedAllowedValues(event) {
        const selectedIndices = objectTruthyValues(selected.values);

        allowedValuesHandler(filterArrayIndices(cellOptions.allowedValues, selectedIndices), event);
    }

    function allowedValuesInputHandler(event) {
        const {value, dataset: {index}} = event.target;

        allowedValuesHandler(getNewArrayWithUpdatedValue(cellOptions.allowedValues, value, index), event);
    }


    const isReadOnly = props.isReadOnly || isEditActive;
    const isRequired = !cellOptions.optional && !isEditActive;
    const disabled = isDisabled ? ' is-disabled' : '';

    return (
        <div className="table-cell">
            {(cellOptions.type === formElementTypes.HEADER) && (
                isEditActive ?
                    <HTMLTextInput placeholder={t('formBuilder:label.title_optional')} isHeading
                        name={'title'} value={cellOptions.title} onChange={inputHandler}
                        style={{marginBottom: '0.5rem'}} isDisabled={isDisabled}/>
                        :
                    cellOptions.title &&
                    <label className={'label is-heading' + disabled}>
                        {cellOptions.title}
                    </label>
            )}

            {cellOptions.type === formElementTypes.DATA_UPLOAD && isEditActive &&
            <HTMLTextInput name={'modelNamingPattern'} label={t('formBuilder:label.datasetName')} style={{marginBottom: '0.5rem'}}
                value={cellOptions.modelNamingPattern} onChange={inputHandler} isDisabled={isDisabled} isRequired={!isDisabled}/>
            }

            {cellOptions.type === formElementTypes.DATA_UPLOAD && showHiddenValues &&
            <div style={{display: 'flex'}}>
                <Text value={t('formBuilder:label.datasetName') + ':'} style={{marginRight: '0.5rem'}}
                    isDisabled={isDisabled}/>
                <Text value={cellOptions.modelNamingPattern} isDisabled={isDisabled}/>
            </div>
            }

            {isEditActive ?
                <TextArea placeholder={t(`formBuilder:label.${optionalQuestions.includes(cellOptions.type) ? 'description_optional' : 'description'}`)}
                    name={'description'} value={cellOptions.description} onChange={inputHandler} rows={2} style={{marginBottom: '0.5rem'}}
                    isRequired={!isDisabled && !optionalQuestions.includes(cellOptions.type)} isDisabled={isDisabled}/>
                    :
                cellOptions.description &&
                <label className={'label is-wordwrap' + disabled}>
                    {cellOptions.description}
                </label>
            }

            <Switch>
                {cellOptions.type === formElementTypes.CHECKBOX && (
                    isEditActive ?
                        <div style={{display: 'flex'}}>
                            <Checkbox style={{marginRight: '0.5rem'}}
                                aria-label={t('aria:input.checkboxResponse')} isDisabled/>

                            <HTMLTextInput placeholder={t('formBuilder:label.label')} name={'label'} value={cellOptions.label}
                                onChange={inputHandler} isDisabled={isDisabled} isRequired={!isDisabled}/>
                        </div>
                        :
                        <FormElementTypeCheckbox cellOptions={cellOptions} toggleHandler={toggleHandler}
                            isReadOnly={isReadOnly} isRequired={isRequired} isDisabled={isDisabled}/>
                )}

                {cellOptions.type === formElementTypes.TEXTAREA &&
                <TextArea name={'value'} value={cellOptions.value} onChange={inputHandler} id={cellOptions.key}
                    aria-label={t('aria:input.textAreaResponse')} isDisabled={isDisabled} isReadOnly={isReadOnly} isRequired={isRequired}/>
                }
                {cellOptions.type === formElementTypes.NUMBER &&
                <HTMLTextInput type={'number'} name={'value'} value={cellOptions.value} style={{width: '10rem'}} id={cellOptions.key}
                    aria-label={t('aria:input.numberResponse')} onChange={inputHandler} isDisabled={isDisabled} isReadOnly={isReadOnly} isRequired={isRequired}/>
                }
                {cellOptions.type === formElementTypes.DATE &&
                <HTMLTextInput type={'date'} name={'value'} value={cellOptions.value} style={{width: '10rem'}} id={cellOptions.key}
                    aria-label={t('aria:input.dateResponse')} onChange={inputHandler} isDisabled={isDisabled} isReadOnly={isReadOnly} isRequired={isRequired}/>
                }
                {cellOptions.type === formElementTypes.TIME &&
                <HTMLTextInput type={'time'} name={'value'} value={cellOptions.value} style={{width: '10rem'}} id={cellOptions.key}
                    aria-label={t('aria:input.timeResponse')} onChange={inputHandler} isDisabled={isDisabled} isReadOnly={isReadOnly} isRequired={isRequired}/>
                }
                {/*{cellOptions.type === formElementTypes.DATE_TIME &&*/}
                {/*<>*/}
                {/*    <HTMLTextInput type={'date'} name={'value'} value={cellOptions.value} style={{width: '10rem', marginRight: '0.5rem'}} id={`${cellOptions.key}_date`}*/}
                {/*        onChange={inputHandler} isDisabled={isDisabled} isReadOnly={isReadOnly} isRequired={isRequired}/>*/}
                {/*    <HTMLTextInput type={'time'} name={'value'} value={cellOptions.value} style={{width: '8rem'}} id={`${cellOptions.key}_time`}*/}
                {/*        onChange={inputHandler} isDisabled={isDisabled} isReadOnly={isReadOnly} isRequired={isRequired}/>*/}
                {/*</>*/}
                {/*}*/}

                {cellOptions.type === formElementTypes.DROPDOWN && ((!props.isReadOnly || isEditActive) ?
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div>
                            <ListDropdown name={'value'} value={cellOptions.value} onItemSelect={dropdownHandler} noneSelectedMessage={t('formBuilder:option.selectValue')}
                                items={cellOptions.allowedValues.map(value => ({name: value, value}))} id={cellOptions.key}
                                isEdit isDisabled={isDisabled || isReadOnly} isRequired={isRequired}/>
                        </div>

                        {isEditActive &&
                        <ValuesBox id={`dropdownAllowedValuesBox_${index}`} values={cellOptions.allowedValues} addValue={addAllowedValue}
                            removeValue={removeSelectedAllowedValues} inputHandler={allowedValuesInputHandler} selected={selected}
                            selectHandler={valueSelectHandler} ariaLabelKey={'Option'} isRequired={!isDisabled} isDisabled={isDisabled}/>
                        }
                    </div>
                    :
                    <HTMLTextInput value={cellOptions.value} style={{width: '10rem'}} id={cellOptions.key}
                        aria-label={t('aria:input.dropdownResponse')} isDisabled={isDisabled} isReadOnly isRequired={isRequired}/>
                )}

                {cellOptions.type === formElementTypes.DATA_UPLOAD &&
                <DataUpload id={cellOptions.key} datasetId={cellOptions.value} label={cellOptions.label} onCreateDataset={onCreateDataset} inputHandler={inputHandler}
                    onUploadActive={onUploadActive} isEditActive={isEditActive} isDisabled={isDisabled} isReadOnly={isReadOnly} isRequired={isRequired}/>
                }

                {cellOptions.type !== formElementTypes.HEADER &&
                <HTMLTextInput name={'value'} value={cellOptions.value} onChange={inputHandler} id={cellOptions.key}
                    aria-label={t('aria:input.inputResponse')} isDisabled={isDisabled} isReadOnly={isReadOnly} isRequired={isRequired}/>
                }
            </Switch>
        </div>
    )
}

function FormElementTypeCheckbox(props) {
    const {
        cellOptions,
        toggleHandler,

        isRequired,
        isReadOnly,
        isDisabled
    } = props;

    const required = !isDisabled && (isRequired && !cellOptions.value);

    const id = cellOptions.key;

    const {
        invalidMessageSpan
    } = getInvalidMessageSpan({
        id,
        isRequired: required
    });

    return (
        <>
            <div className={'checkbox-row' + ((isRequired && !cellOptions.value) ? ' is-required' : '')}
                style={{display: 'inline-block'}}
            >
                <Checkbox label={cellOptions.label} id={cellOptions.key}
                    name={'value'} checked={cellOptions.value} onClick={toggleHandler}
                    isWordWrap isDisabled={isDisabled} isReadOnly={isReadOnly}/>
            </div>
            {invalidMessageSpan}
        </>
    )
}


function FormElementTypeDropdown(props) {
    const {selectedFormElementType, onFormElementTypeSelect, ...attr} = props;
    const {t} = useTranslation(['aria', 'formBuilder']);

    const formElementTypeItems = getValues(formElementTypes)
        .map(type => ({
            name: t(`formBuilder:type.${type}`),
            value: type
        }));

    return (
        <ListDropdown aria-label={t('aria:hiddenAssistText.formElementType')}
            name={'formElementType'} value={selectedFormElementType}
            items={formElementTypeItems} onItemSelect={onFormElementTypeSelect}
            {...attr}
        />
    )
}

export default FormBuilder;
