import React, {useLayoutEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import QueueJob from './QueueJob';
import JobQueueDisplay from "./JobQueueDisplay";
import JobArchiveDisplay from "./JobArchiveDisplay";
import JobModel from "../../models/job/JobModel";
import {jobPageViewKeys} from "../../i18next/keys";
import JobScheduleDisplay from "../schedule/JobScheduleDisplay";
import Switch from "../common/Switch/Switch";
import {applicationFeatures} from "../../utilities/constants";
import PurviewJobDisplay from "../purview/PurviewJobDisplay";
import VaultJobDisplay from "../vault/VaultJobDisplay";


function JobPage() {
	const dispatch = useDispatch();

	const {
		activeView,
		isQueueJobFormActive
	} = useSelector(state => state.componentStates.jobPage);

	const userFeatures = useSelector(state => state.currentUser.features);

	useLayoutEffect(() => {
		dispatch(JobModel.componentActionCreators.setPageActive());
		return () => dispatch(JobModel.componentActionCreators.setPageInactive());
	}, []);


	return (
		<main className="mainPage column-direction">
			<Switch>
				{activeView === jobPageViewKeys.QUEUE && userFeatures.includes(applicationFeatures.VIEW_JOBS) &&
					<JobQueueDisplay/>
				}
				{activeView === jobPageViewKeys.PURVIEW && userFeatures.includes(applicationFeatures.VIEW_PURVIEW_JOBS) &&
					<PurviewJobDisplay/>
				}
				{activeView === jobPageViewKeys.VAULT && userFeatures.includes(applicationFeatures.VIEW_VAULT_JOBS) &&
					<VaultJobDisplay/>
				}
				{activeView === jobPageViewKeys.SCHEDULE && userFeatures.includes(applicationFeatures.VIEW_SCHEDULES) &&
					<JobScheduleDisplay/>
				}
				{activeView === jobPageViewKeys.ARCHIVE && userFeatures.includes(applicationFeatures.VIEW_JOBS) &&
					<JobArchiveDisplay/>
				}
			</Switch>

			{isQueueJobFormActive &&
				<QueueJob/>
			}
		</main>
	);
}

export default JobPage;
