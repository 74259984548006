import {TableRow} from "../common/CustomTable/CustomTable";
import React from "react";

function LimitedTableRow(props) {
    const {
        id,
        onClick,
        isActive,
        value,

        nameComponent,
        toggleComponent,

        cellCount
    } = props;

    const cells = []
    let start = 1
    if (!!toggleComponent) {
        start = 2;
    }

    for (let i = start; i < cellCount; i++) {
        cells.push(
            <div key={i} className={"table-cell"}/>
        )
    }

    return (
        <TableRow key={id} value={id} isActive={isActive} onClick={onClick}>
            {toggleComponent &&
                <>
                    {toggleComponent}
                </>
            }

            {nameComponent &&
                <>
                    {nameComponent}
                </>
            }

            {cells}
        </TableRow>
    )
}

export default LimitedTableRow;