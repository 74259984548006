import ComponentStateModel from "../generics/ComponentStateModel";
import {actionCreator, includesSome, objEquals, stringToBool} from "../../utilities/helperFunctions";
import {call, delay, put} from "redux-saga/effects";
import {applicationFeatures, SLOW_QUERY_INTERVAL} from "../../utilities/constants";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import {contextCall, contextSaga, contextTakeLatest, poll} from "../../saga/sagaFunctions";
import SagaRunnable from "../generics/SagaRunnable";
import {AUTOMATE_USERTOKEN, AUTOMATE_USERTOKEN_DATE, setUiToken} from "../../utilities/localStorageHelper";


class CurrentUserModel {

    static nom = 'CurrentUserModel';
    static actions = CurrentUserModel.buildActions();
    static activities = CurrentUserModel.buildActivities();
    static actionCreators = CurrentUserModel.buildActionCreators(CurrentUserModel.actions);
    static reducer = CurrentUserModel.buildReducer(CurrentUserModel.actions);

    static componentActionCreators = CurrentUserModel.buildComponentActionCreators();

    static validateFeatures(features) {
        return includesSome(features, [
            applicationFeatures.VIEW_JOBS,
            applicationFeatures.VIEW_CLIENTS,
            applicationFeatures.VIEW_LIBRARIES,
            applicationFeatures.VIEW_AUTOMATE_LICENCE,
            applicationFeatures.VIEW_NUIX_LICENCE_SOURCES,
            applicationFeatures.VIEW_RESOURCE_POOLS,
            applicationFeatures.VIEW_NOTIFICATION_RULES,
            applicationFeatures.VIEW_EXECUTION_PROFILES,
            applicationFeatures.VIEW_CLIENT_POOLS,
            applicationFeatures.VIEW_DATA_REPOSITORIES,
            applicationFeatures.VIEW_USER_SERVICES,
            applicationFeatures.VIEW_NOTICE_TEMPLATES,
            applicationFeatures.VIEW_LEGAL_HOLDS,
            applicationFeatures.VIEW_LEGAL_HOLD_NOTICES,
            applicationFeatures.VIEW_SECURITY,
            applicationFeatures.VIEW_RESOURCES,
            applicationFeatures.VIEW_API_KEYS,
            applicationFeatures.VIEW_WEBHOOKS,
            applicationFeatures.VIEW_THIRD_PARTY_SERVICES,
            applicationFeatures.VIEW_FILE_LIBRARIES,
            applicationFeatures.DOWNLOAD_UTILIZATION_FULL,
            applicationFeatures.DOWNLOAD_UTILIZATION_ANONYMOUS,
            applicationFeatures.DOWNLOAD_SYSTEM_LOGS
        ]);
    }

    static buildActions() {
        return {
            // CURRENT USER ACTIONS
            SET_CURRENT_USER: 'SET_CURRENT_USER',
            SET_USER_FEATURES: 'SET_USER_FEATURES',
            // AUTHENTICATION ACTIONS
            LOGIN_USER: 'LOGIN_USER',
            SSO_LOGIN: 'SSO_LOGIN',
            SET_USER_REFRESHED: 'SET_USER_REFRESHED',

            LOGOUT_USER: 'LOGOUT_USER',
            SAFE_LOGOUT: 'SAFE_LOGOUT',
            CLEAR_CURRENT_USER: 'CLEAR_CURRENT_USER',
            // ACTIVITY ACTIONS
            POLL_ACTIVITY: 'POLL_USER_ACTIVITY',

            POLL_REFRESH_TOKEN: 'POLL_USER_REFRESH_TOKEN'
        }
    }

    static buildActivities() {
        return {
            // CURRENT USER ACTIVITIES
            WORKFLOW_DESIGN: 'WORKFLOW_DESIGN',
            ADMINISTRATION: 'ADMINISTRATION',
            JOB_SETUP: 'JOB_SETUP',
            JOB_MONITORING: 'JOB_MONITORING',
            DATA_UPLOAD: 'DATA_UPLOAD'
        }
    }

    static buildActionCreators(actions) {
        return {
            // CURRENT USER ACTION CREATORS
            setCurrentUser: actionCreator(actions.SET_CURRENT_USER, 'id', 'name', 'features'),
            setUserFeatures: actionCreator(actions.SET_USER_FEATURES, 'features'),
            // AUTHENTICATION ACTION CREATORS
            loginUser: actionCreator(actions.LOGIN_USER, 'password'),
            ssoLogin: actionCreator(actions.SSO_LOGIN, 'userData'),
            setUserRefreshed: actionCreator(actions.SET_USER_REFRESHED),

            logoutUser: actionCreator(actions.LOGOUT_USER, 'hidePopup'),
            safeLogout: actionCreator(actions.SAFE_LOGOUT, 'hidePopup'),
            clearCurrentUser: actionCreator(actions.CLEAR_CURRENT_USER),
            // ACTIVITY ACTION CREATORS
            pollActivity: actionCreator(actions.POLL_ACTIVITY, 'activity', 'matterId'),
            resetPollActivity: () => ({type: actions.POLL_ACTIVITY, payload: {activity: CurrentUserModel.activities.ADMINISTRATION}}),

            startPollingRefreshToken: actionCreator(actions.POLL_REFRESH_TOKEN)
        }
    }

    static buildComponentActionCreators() {
        const components = [
            {
                key: 'loginPage',
                type: 'LoginPage',
                state: {
                    username: '',
                    invalidMessage: ''
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildReducer(actions) {
        return function (state = {}, action) {
            switch (action.type) {
                case actions.SET_CURRENT_USER: {
                    const {name, features} = action.payload;

                    return {
                        name,
                        features,
                        isAuthenticated: true
                    };
                }
                case actions.SET_USER_FEATURES: {
                    const {features} = action.payload;

                    if (!objEquals(state.features, features)) {
                        return {
                            ...state,
                            features
                        }
                    }

                    return state;
                }
                case actions.SET_USER_REFRESHED: {
                    return {
                        ...state,
                        refreshed: !state.refreshed
                    };
                }
                case actions.CLEAR_CURRENT_USER: {
                    return {};
                }
                default: {
                    return state;
                }
            }
        }
    }
}

export class CurrentUserApi {

    static getMe() {
        return axiosInstance.get('/users/me');
    }

    static getFeatures() {
        return axiosInstance.get('/users/features');
    }

    static trackUtilization(activityType, matterId){
        return axiosInstance.post('/users/utilization/'+activityType, matterId);
    }

    static postLogin(username, password, scope) {
        return axiosInstance.post('/users/password', {username, password, scope});
    }

    static postLoginLink(loginLink) {
        return axiosInstance.post(`/users/loginLink`, loginLink);
    }

    static refreshLoginLink(loginLink) {
        return axiosInstance.post(`/users/loginLink/refresh`, loginLink);
    }

    static refreshToken() {
        return axiosInstance.post(`/users/refresh`);
    }

    static postOidcComplete() {
        return axiosInstance.post('/users/oidcComplete');
    }

    static postOidcError() {
        return axiosInstance.get('/users/oidcError/');
    }

    static deleteLogin() {
        return axiosInstance.del('/users');
    }
}

export class CurrentUserSaga extends SagaRunnable {

    static activationComponent = 'APP';
    static wasUserActive = false;
    static trackingEvents = ['mousemove', 'mousedown', 'keydown', 'touchstart', 'scroll'];

    static buildActivationEffects() {
        this.trackingEvents.forEach(e => document.body.addEventListener(e, this.setUserActive));

        return [
            contextTakeLatest(CurrentUserModel.actions.POLL_ACTIVITY, this, 'pollActivity'),
            contextTakeLatest(CurrentUserModel.actions.POLL_REFRESH_TOKEN, this, 'pollRefreshToken')
        ]
    }

    static buildDeactivationEffects() {
        this.trackingEvents.forEach(e => document.body.removeEventListener(e, this.setUserActive));
    }

    static* pollActivity(action) {
        const {activity, matterId} = action.payload;

        CurrentUserSaga.wasUserActive = true;
        yield call(poll, 30000, function* () {
            if (CurrentUserSaga.wasUserActive) {
                // Reset user active
                CurrentUserSaga.wasUserActive = false;
                yield contextCall(CurrentUserApi, 'trackUtilization', activity, matterId);
            }
        });
    }

    static setUserActive() {
        CurrentUserSaga.wasUserActive = true;
    }

    static refreshPollingFlag = 'refreshPollingActive';
    static refreshRate;

    static refreshTokenStorageListener = event => {
        if (event.key === AUTOMATE_USERTOKEN && event.newValue != null) {
            axiosInstance.setAxiosToken(event.newValue);

        } else if (event.key === this.refreshPollingFlag && !stringToBool(event.newValue)) {
            // If current refreshPolling becomes inactive, start a new poll
            this.dispatch(CurrentUserModel.actionCreators.startPollingRefreshToken());
        }
    }

    // Set flag false
    static clearRefreshPollingFlagOnUnload = () => {
        localStorage.setItem(this.refreshPollingFlag, false);
    }

    static* pollRefreshToken() {
        if (this.refreshRate <= 0) {
            return;
        }
        // Start polling only if inactive OR if not downgradeWebWorker
        if (CurrentUserSaga.downgradeWebWorkerToken) {
            // Random delay so multiple tabs don't refresh synchronized (2 tabs refresh in sync will both start refresh polling)
            yield delay(Math.floor(Math.random() * 1000));
            const refreshPollActive = stringToBool(localStorage.getItem(CurrentUserSaga.refreshPollingFlag));
            if (refreshPollActive) return;

            // Set flag active and attach listener to set flag inactive onUnload
            localStorage.setItem(this.refreshPollingFlag, true);
            window.addEventListener('beforeunload', this.clearRefreshPollingFlagOnUnload);
        }

        try {
            let refreshDelay = this.refreshRate;
            const tokenDate = +localStorage.getItem(AUTOMATE_USERTOKEN_DATE);

            // Reduce refreshDelay by time since last refresh
            if (!isNaN(tokenDate)) {
                const timeSinceLastRefresh = (Date.now() - tokenDate);
                refreshDelay = Math.max(0, refreshDelay - timeSinceLastRefresh);
            }

            if (refreshDelay) {
                yield delay(refreshDelay);
            }

            yield call(poll, this.refreshRate, contextSaga(this, 'refreshToken'));
        } finally {
            // Remove eventListener
            if (CurrentUserSaga.downgradeWebWorkerToken) {
                window.removeEventListener('beforeunload', this.clearRefreshPollingFlagOnUnload);
            }
        }
    }

    static* refreshToken() {
        const {data} = yield contextCall(CurrentUserApi, 'refreshToken');
        setUiToken(data.uiToken);
        // Update user refreshed to force re-renders
        yield put(CurrentUserModel.actionCreators.setUserRefreshed());
    }

    static* pollFeatures() {
        yield call(poll, SLOW_QUERY_INTERVAL, contextSaga(this, 'queryFeatures'));
    }

    static* queryFeatures() {
        const response = yield contextCall(CurrentUserApi, 'getFeatures');
        if (AxiosProxy.shouldUpdate('userFeatures', response)) {
            // If features invalid, force-logout; otherwise continue and update
            if (CurrentUserModel.validateFeatures(response.data)) {
                yield put(CurrentUserModel.actionCreators.setUserFeatures(response.data));
            } else {
                yield put(CurrentUserModel.actionCreators.logoutUser());
            }
        }
    }
}

export default CurrentUserModel;