import React, {useLayoutEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {applicationFeatures, details} from "../../utilities/constants";
import WebhookModel from "../../models/settings/WebhookModel";
import WebhookTablet from "./WebhookTablet";
import WebhookForm from "./WebhookForm";
import Switch from "../common/Switch/Switch";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {AddButtonHeader} from "../common/Button/Button";
import {isNotEmptyNorFalsy} from "../../utilities/helperFunctions";
import WebhookTable from "./WebhookTable";
import {MainPageContent, MainPageHeader} from "../app/pages/MainPage";


function WebhookDisplay(props) {
    const {t} = useTranslation(['aria', 'webhook']);
    const dispatch = useDispatch();

    const {
        ...attr
    } = props;

    const {
        webhookId,
        isWebhookFormActive
    } = useSelector(state => state.componentStates.webhookDisplay);

    const isLoading = useSelector(state => !state.hasLoaded[details.WEBHOOKS]);

    const canModify = useSelector(state => state.currentUser.features.includes(applicationFeatures.ADD_WEBHOOKS));
    const webhooks = useSelector(state => state.webhookDetailsMap);

    useLayoutEffect(() => {
        dispatch(WebhookModel.componentActionCreators.setDisplayActive())

        return () => dispatch(WebhookModel.componentActionCreators.setDisplayInactive())
    }, [dispatch]);


    function showForm() {
        dispatch(WebhookModel.actionCreators.showForm())
    }

    const selectedWebhook = webhooks.get(webhookId);

    const isNotEmpty = isNotEmptyNorFalsy(webhooks);

    return (
        <MainPageContent {...attr}
            title={
                <MainPageHeader
                    text={t('webhook:page.title')}
                    description={t('webhook:page.description')}
                />
            }
            list={
                <LoadingWrapper isLoading={isLoading}>
                    {(canModify || isNotEmpty) &&
                        <div className="display-item">
                            <AddButtonHeader aria-label={t('aria:hiddenAssistText.addWebhook')} text={t('webhook:label.name')}
                                canModify={canModify} onClick={showForm}
                            />

                            {isNotEmpty &&
                            <WebhookTable webhooks={webhooks}/>
                            }
                        </div>
                    }
                </LoadingWrapper>
            }
        >

            <Switch>
                {(selectedWebhook != null) &&
                <WebhookTablet {...selectedWebhook}/>
                }

                {isWebhookFormActive &&
                <WebhookForm />
                }
            </Switch>
        </MainPageContent>
    )
}

export default WebhookDisplay;
