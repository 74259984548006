import {DetailsSaga} from "../generics/DetailsModel";
import ComponentStateModel from "../generics/ComponentStateModel";
import {
    actionCreator,
    deepCopy,
    getInputFormattedDateAndTime,
    getKeys,
    getValues,
    objectTruthyValues,
    stringToBool
} from "../../utilities/helperFunctions";
import {all, put, select, take, takeLeading} from "redux-saga/effects";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import SubsetDetailsModel from "../generics/SubsetDetailsModel";
import {contextCall, contextPollUntil, contextSaga, isDisabledWrapper} from "../../saga/sagaFunctions";
import EditModel from "../scheduler/EditModel";
import {tryCatchWrapper} from "../../saga/tryCatchWrapper";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import {details, formElementTypes, modelTypes, routes, userNoticeCommands} from "../../utilities/constants";
import {legalHoldPageViewKeys, noticeStateFilterKeys, permissionKeys, popupInfoKeys} from "../../i18next/keys";
import NoticeCommentModel, {NoticeCommentSaga} from "./NoticeCommentModel";
import LegalHoldModel, {LegalHoldSaga} from "../legalhold/LegalHoldModel";
import PopupModel from "../scheduler/PopupModel";


class UserNoticeModel extends SubsetDetailsModel {

    static nom = 'UserNoticeModel';
    static parentKey = 'legalHoldId';

    static actions = UserNoticeModel.buildActions('USER_NOTICE');
    static actionCreators = UserNoticeModel.buildActionCreators(UserNoticeModel.actions);
    static reducer = UserNoticeModel.buildReducer(UserNoticeModel.actions);

    static componentActionCreators = {
        ...UserNoticeModel.buildComponentUpdateActionCreators()
    };

    constructor(model={}) {
        super(model);
        const {legalHoldId, noticeId, userId, type, subject, message, surveyFormOptions, outstanding,
            createdDate, sentDate, lastViewedDate, lastRespondedDate, respondedDate, respondByDate, escalatedDate, lastRemindedDate,
            adminLastViewedDate, lastCommentedDate, lastAdminNoteDate, disableComments, disableAdminNotes, noticeDisableComments, noticeEnabled} = model;

        this.legalHoldId = legalHoldId;
        this.noticeId = noticeId;
        this.userId = userId;
        
        this.type = type;
        this.subject = subject;
        this.message = message;
        this.surveyFormOptions = surveyFormOptions || [];
        this.outstanding = outstanding;

        // Change checkbox option values to bool
        for (const opt of this.surveyFormOptions) {
            if (opt.type === formElementTypes.CHECKBOX) {
                opt.value = stringToBool(opt.value);
            }
        }

        this.createdDate = createdDate;
        this.sentDate = sentDate;
        this.lastViewedDate = lastViewedDate;
        this.lastRespondedDate = lastRespondedDate;
        this.respondedDate = respondedDate;
        this.respondByDate = respondByDate;
        this.escalatedDate = escalatedDate;

        this.lastRemindedDate = lastRemindedDate;
        this.lastCommentedDate = lastCommentedDate;
        this.lastAdminNoteDate = lastAdminNoteDate;
        this.adminLastViewedDate = adminLastViewedDate || {};

        this.disableComments = disableComments;
        this.disableAdminNotes = disableAdminNotes;
        this.noticeDisableComments = noticeDisableComments;
        this.noticeEnabled = noticeEnabled;
    }

    isSubmitEnabled(surveyFormOptions=this.surveyFormOptions) {
        // Opt either not enabled, is a header, not required, or is defined
        return surveyFormOptions.every(opt => {
            if (!opt.enabled || opt.optional || opt.type === formElementTypes.HEADER) {
                return true;
            }
            return !!opt.value;
        });
    }

    hasSurveyForm() {
        return this.surveyFormOptions.some(opt => opt.enabled && opt.type !== formElementTypes.HEADER);
    }

    getViewedAndResponded() {
        if (this.enabled && this.userPermissions.includes(permissionKeys.RESPOND)) {
            const viewed = this.lastViewedDate != null && (this.lastCommentedDate == null || this.lastViewedDate >= this.lastCommentedDate);
            if (this.hasSurveyForm()) {
                return viewed && this.lastRespondedDate != null;
            }
            return viewed;
        }
        return true;
    }

    getStates() {
        const states = {
            viewed: this.lastViewedDate != null,
            escalated: this.escalatedDate != null,
            reminded: this.lastRemindedDate != null
        };

        // Not all notices have surveys
        if (this.hasSurveyForm()) {
            states.responded = this.lastRespondedDate != null;
        }

        return states;
    }

    getStateKeys() {
        const states = this.getStates();

        const stateKeys = [
            states.viewed ? noticeStateFilterKeys.viewed : noticeStateFilterKeys.notViewed
        ];

        if (states.responded != null) {
            stateKeys.push(states.responded ? noticeStateFilterKeys.responded : noticeStateFilterKeys.notResponded);
        }
        // Only show escalated state if escalated
        if (states.escalated) {
            stateKeys.push(noticeStateFilterKeys.escalated);
        }
        // Only show reminded state if reminded
        if (states.reminded) {

        }

        return stateKeys;
    }

    static sortUserNotices(userNotices) {
        return userNotices.sort((u1, u2) => {
            let u1Date, u2Date;
            if (u1.userPermissions.includes(permissionKeys.RESPOND)) {
                u1Date = u1.sentDate;
            } else if (u1.userPermissions.includes(permissionKeys.MANAGE)) {
                if (u1.lastAdminNoteDate == null || (u1.lastCommentedDate != null && u1.lastCommentedDate < u1.lastAdminNoteDate)) {
                    u2Date = u1.lastCommentedDate;
                } else {
                    u1Date = u1.lastAdminNoteDate;
                }
            }

            if (u2.userPermissions.includes(permissionKeys.RESPOND)) {
                u2Date = u2.sentDate;
            } else if (u2.userPermissions.includes(permissionKeys.MANAGE)) {
                if (u2.lastAdminNoteDate == null || (u2.lastCommentedDate != null && u2.lastCommentedDate < u2.lastAdminNoteDate)) {
                    u2Date = u2.lastCommentedDate;
                } else {
                    u2Date = u2.lastAdminNoteDate;
                }
            }

            return (u1Date || 0) - (u2Date || 0);
        });
    }

    static buildActions(type) {
        return {
            ...super.buildActions(type),
            /// USER NOTICE MODEL ACTIONS
            SET_VIEWED: 'SET_USER_NOTICE_VIEWED',
            SEND_COMMAND: 'SEND_USER_NOTICE_COMMAND',
            RESET_SUBMISSION: 'RESET_USER_NOTICE_SUBMISSION',

            START_POLLING_OUTSTANDING_NOTICES: 'START_POLLING_OUTSTANDING_NOTICES',
            STOP_POLLING_OUTSTANDING_NOTICES: 'STOP_POLLING_OUTSTANDING_NOTICES'
        }
    }

    static buildActionCreators(actions) {
        return {
            ...super.buildActionCreators(actions),
            // USER NOTICE MODEL ACTION CREATORS
            setViewed: actionCreator(actions.SET_VIEWED, 'noticeId', 'id'),
            sendCommand: actionCreator(actions.SEND_COMMAND, 'noticeId', 'id', 'command', 'args'),
            resetSubmission: actionCreator(actions.RESET_SUBMISSION, 'noticeId', 'id'),

            startPollingOutstandingNotices: actionCreator(actions.START_POLLING_OUTSTANDING_NOTICES, 'period'),
            stopPollingOutstandingNotices: actionCreator(actions.STOP_POLLING_OUTSTANDING_NOTICES),

            saveEdit: actionCreator(EditModel.actions.SAVE, 'submit'),
            startPollingSettings: actionCreator(actions.START_POLLING_SETTINGS, 'noticeId', 'id', 'period')
        }
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
              key: 'outstandingWorkDisplay',
              type: 'OutstandingWorkDisplay',
              state: {
                  userNoticeIds: []
              }
            },
            {
              key: 'legalHoldNoticeDisplay',
              type: 'LegalHoldNoticeDisplay',
              state: {
                  searchText: '',
                  fromDate: getInputFormattedDateAndTime(-1)[0],
                  toDate: getInputFormattedDateAndTime()[0],
                  viewCount: 50,
                  userIds: {},
                  clientIds: {},
                  matterIds: {},
                  legalHoldIds: {},
                  noticeTypes: {},
                  noticeStateFilter: {}
              }
            },
            {
                key: 'userNoticeView',
                type: 'View',
                state: {
                    legalHoldId: null,
                    userNoticeId: null,
                    isUploadActive: false,
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static setSubsetDetailsMap(state, parentId, details) {
        const newSubset = new Map();

        for (let i = 0; i < details.length; i++) {
            const model = new this(details[i]);
            const oldModel = state.get(model['id']);

            if (model.equals(oldModel)) {
                newSubset.set(model['id'], oldModel);
            } else {
                newSubset.set(model['id'], model);
            }
        }

        // Subset update is for legalHoldId + userId
        const userId = (details[0] || {}).userId;
        // All models other than this subset
        const others = [];
        for (const entry of state) {
            const [key, model] = entry;

            // Other if different parentId (subset) OR if newState doesn't have key and is not same userId
            // If newState doesn't have key and is same userId, assume deleted
            if (model[this.parentKey] !== parentId || (!newSubset.has(key) && model.userId !== userId)) {
                others.push(entry);
            }
        }

        const newState = new Map([...newSubset, ...others]);

        // Check if state has been updated
        if (newState.size !== state.size) {
            return newState;
        }
        // Determine if matter or order has been updated
        const values = getValues(newState);
        const oldValues = getValues(state);
        for (let i = 0; i < values.length; i++) {
            // If there's a new reference (re-ordered/state updated) return new state
            if (values[i] !== oldValues[i]) {
                return newState;
            }
        }
        // Else, return old state
        return state;
    }
}

export class UserNoticeApi {

    static get(noticeId, id) {
        return axiosInstance.get(`/scheduler/notices/${noticeId}/events/${id}`);
    }

    static getDetails(filter) {
        return axiosInstance.post('/scheduler/notices', filter);
    }

    static getComments(noticeId, id) {
        return axiosInstance.get(`/scheduler/notices/${noticeId}/events/${id}/comments`);
    }

    static getOutstanding() {
        return axiosInstance.get(`/scheduler/notices/outstanding`);
    }

    static getUsers() {
        return axiosInstance.get(`/scheduler/notices/users`);
    }

    static postResponses(noticeId, id, responses, submit=false) {
        return axiosInstance.post(`/scheduler/notices/${noticeId}/events/${id}/response?submit=${submit}`, responses)
    }

    static postCommand(noticeId, id, command) {
        return axiosInstance.post(`/scheduler/notices/${noticeId}/events/${id}`, command)
    }

    static postUserNoticeViewed(noticeId, id) {
        return axiosInstance.post(`/scheduler/notices/${noticeId}/events/${id}/viewed`)
    }
}

export class UserNoticeSaga extends DetailsSaga {

    static ModelType = UserNoticeModel;
    static ModelApi = UserNoticeApi;

    static activationComponent = 'LEGAL_HOLD_PAGE';
    static variableNames = {
        detailsMap: 'userNoticeDetailsMap',
        instanceId: 'userNoticeId',
        updatePane: 'updateView',
        updateDisplay: 'updateLegalHoldNoticeDisplay',
        route: routes.LEGAL_HOLD
    };

    static translations = {
        itemTitle: '$t(notice:label.name)',
        itemLower: '$t(notice:label.name_lower)'
    };

    static buildActivationEffects(dispatch) {
        return [
            ...super.buildActivationEffects(dispatch),
            // ACTIVATION EFFECTS
            takeLeading(UserNoticeModel.actions.SET_VIEWED, tryCatchWrapper, contextSaga(this, 'setViewed')),
            takeLeading(UserNoticeModel.actions.SEND_COMMAND, tryCatchWrapper, isDisabledWrapper, UserNoticeModel.componentActionCreators.updateView, contextSaga(this, 'sendCommand')),
            takeLeading(UserNoticeModel.actions.RESET_SUBMISSION, isDisabledWrapper, UserNoticeModel.componentActionCreators.updateView, contextSaga(this, 'resetSubmission'))
        ]
    }

    static* initializeState() {
        yield all([
            contextCall(LegalHoldSaga, 'queryDetails'),
            contextCall(this, 'queryDetails'),
            contextCall(this, 'queryOutstandingNotices')
        ]);
    }

    static* setReduxState(args) {
        yield contextCall(this, 'initializeState');
        const {detailsMap, route} = this.variableNames;

        const details = yield select(state => state[detailsMap].get(args.id));
        if (args.id != null && details == null) {
            yield put(PopupModel.actionCreators.showError({
                info: {
                    key: 'ModelCannotBeFound',
                    valueKeys: {
                        itemTitle: this.translations.itemLower
                    }
                }
            }));

        } else {
            switch (args.modelType) {
                case modelTypes.userNotice:
                    yield all([
                        put(LegalHoldModel.componentActionCreators.updatePage({activeView: legalHoldPageViewKeys.NOTICES})),
                        put(UserNoticeModel.componentActionCreators.updateLegalHoldNoticeDisplay({userNoticeId: args.id}))
                    ]);
                    break;
                case modelTypes.overview:
                    yield all([
                        put(LegalHoldModel.componentActionCreators.updatePage({activeView: legalHoldPageViewKeys.OVERVIEW})),
                        put(UserNoticeModel.componentActionCreators.updateView({legalHoldId: (details || {}).legalHoldId, userNoticeId: args.id}))
                    ]);
                    break;
            }
        }

        if (args.cbEffect != null) {
            yield args.cbEffect;
        }
        window.location.href = `#${route}`;
    }

    static* updateUserNoticeSurveyFormValues(id, surveyFormValues) {
        const userNotice = yield select(state => state.userNoticeDetailsMap.get(id));
        const surveyFormOptions = [];
        // Update value for each form option
        for (const opt of userNotice.surveyFormOptions) {
            const {key} = opt;

            surveyFormOptions.push({
                ...opt,
                value: surveyFormValues[key]
            });
        }
        yield put(this.ModelType.actionCreators.updateDetails({[id]: {surveyFormOptions}}));
    }

    static* resetSubmission(action) {
        const {noticeId, id} = action.payload;

        const userNotice = yield select(state => state.userNoticeDetailsMap.get(id));
        const user = yield select(state => state.userDetailsMap.get(userNotice.userId));
        const popupInfo = {
            values: {
                name: user.name
            }
        }

        // Show warning if userNotice has a dataset
        const hasDataset = userNotice.surveyFormOptions.some(formOption => formOption.type === formElementTypes.DATA_UPLOAD);
        let confirmed;
        if (hasDataset) {
            popupInfo.key = 'userNoticeResetSubmissionUnlinksDatasets'
            confirmed = yield contextCall(this, 'showSaveWarning', popupInfo, {confirmTitleKey: 'notice:option.reset', throwError: false});
        } else {
            popupInfo.key = 'userNoticeResetSubmission'
            confirmed = yield contextCall(this, 'showConfirmPopup', popupInfo, {confirmTitleKey: 'notice:option.reset'});
        }

        if (confirmed) {
            yield put(UserNoticeModel.actionCreators.sendCommand(noticeId, id, userNoticeCommands.RESET_SUBMISSION));
        }
    }

    static* sendCommand(action) {
        const {noticeId, id, command, args} = action.payload;

        const {data} = yield contextCall(UserNoticeApi, 'postCommand', noticeId, id, {command, ...args});
        yield put(UserNoticeModel.actionCreators.updateDetails({[id]: new UserNoticeModel(data)}));
    }

    static* setViewed(action) {
        const {noticeId, id} = action.payload;

        const {data} = yield contextCall(UserNoticeApi, 'postUserNoticeViewed', noticeId, id);
        yield put(UserNoticeModel.actionCreators.updateDetails({[id]: new UserNoticeModel(data)}));
    }

    static* showPane(action) {
        const {id} = action.payload;
        yield put(this.ModelType.componentActionCreators.updateView({userNoticeId: id}));
    }

    static* hidePane() {
        const effect = put(this.ModelType.componentActionCreators.updateView({userNoticeId: null}));
        yield contextCall(NoticeCommentSaga, 'callCommentFormDiscardingEffect', effect);
    }

    static* verifySave(action) {
        const {submit} = action.payload;
        // Warn user that submit cannot be undone
        if (submit) {
            yield contextCall(this, 'showSaveWarning', {key: popupInfoKeys.USER_NOTICE_RESPONSE_SUBMIT_WARNING}, {confirmTitleKey: 'common:option.submit'});
        }
    }

    static* showNextOutstandingNotice(userNoticeId) {
        const nextOutstandingNotice = yield select(state => {

            const sortedUserNotices = getValues(state.userNoticeDetailsMap);
            UserNoticeModel.sortUserNotices(sortedUserNotices);

            let index = sortedUserNotices.findIndex(_userNotice => _userNotice.id === userNoticeId);
            if (index < 0) {
                index = 0;
            }

            for (let i = 1; i < sortedUserNotices.length; i++) {
                // starts from current index and rotates all the way around to previous index
                const nextIndex = (i + index) % sortedUserNotices.length;

                const userNotice = sortedUserNotices[nextIndex];
                if (userNotice != null && userNotice.outstanding) {
                    return userNotice;
                }
            }

            return {};
        });

        yield put(UserNoticeModel.componentActionCreators.updateView({
            legalHoldId: nextOutstandingNotice.legalHoldId,
            userNoticeId: nextOutstandingNotice.id
        }));
    }

    static* saveEdit(id, action) {
        const {submit} = action.payload;

        const editValues = yield select(state => state.editDetails.values);
        const saveValues = yield contextCall(this, 'getSaveValues', editValues);

        const {noticeId, responses} = saveValues;
        const {data} = yield contextCall(this.ModelApi, 'postResponses', noticeId, id, responses, !!submit);
        yield put(UserNoticeModel.actionCreators.updateDetails({[id]: new UserNoticeModel(data)}));

        switch (submit) {
            case "next":
                yield contextCall(this, 'showNextOutstandingNotice', id);
        }
    }

    static* checkElsewhereTake() {
        yield take('USER_NOTICE_EDIT_BLOCKING');
    }

    static* getEditValues(id) {
        // Only edit surveyFormOptions
        const {noticeId, surveyFormOptions} = yield select(state => state.userNoticeDetailsMap.get(id));
        return {
            id,
            noticeId,
            surveyFormOptions: deepCopy(surveyFormOptions)
        }
    }

    static getSaveValues(values) {
        const {noticeId, surveyFormOptions} = values;
        // Return map of key => value
        const responses = {};
        for (const opt of surveyFormOptions) {
            responses[opt.key] = opt.value;
        }

        return {
            noticeId,
            responses
        };
    }

    static* pollOutstandingNotices(action) {
        const {period} = action.payload;

        yield contextPollUntil(UserNoticeModel.actions.STOP_POLLING_OUTSTANDING_NOTICES, period, this, 'queryOutstandingNotices', action);
    }

    static* queryOutstandingNotices() {
        const {data} = yield contextCall(UserNoticeApi, 'getOutstanding');

        const idToModels = data.reduce((acc, curr) => {
            acc[curr.id] = curr;
            return acc;
        }, {});

        yield all([
            put(UserNoticeModel.actionCreators.updateDetails(idToModels)),
            put(UserNoticeModel.componentActionCreators.updateOutstandingWorkDisplay({userNoticeIds: getKeys(idToModels)}))
        ]);
    }

    static* queryDetails() {
        const {
            fromDate,
            toDate,
            viewCount,
            userIds,
            clientIds,
            matterIds,
            legalHoldIds,
            noticeTypes,
            noticeStateFilter
        } = yield select(state => state.componentStates.legalHoldNoticeDisplay);

        // Unload previous userNotices
        yield put(ReduxStateModel.actionCreators.setHasLoaded(details.USER_NOTICES, false));

        const from = new Date(fromDate.split('-'));
        const to = new Date(toDate.split('-'));
        to.setDate(to.getDate() + 1);
        to.setMilliseconds(to.getMilliseconds() - 1);

        const filter = {
            afterDate: from.getTime(),
            beforeDate: to.getTime(),
            count: viewCount,
            userIds: objectTruthyValues(userIds),
            clientIds: objectTruthyValues(clientIds),
            matterIds: objectTruthyValues(matterIds),
            legalHoldIds: objectTruthyValues(legalHoldIds),
            types: objectTruthyValues(noticeTypes),
            stateFilter: noticeStateFilter
        };

        try {
            const {data} = yield contextCall(UserNoticeApi, 'getDetails', filter);
            yield put(UserNoticeModel.actionCreators.setDetailsMap(data));

        } finally {

            yield all([
                put(ReduxStateModel.actionCreators.setHasLoaded(details.USER_NOTICES)),
                put(UserNoticeModel.componentActionCreators.updateLegalHoldNoticeDisplay({searchText: ''}))
            ]);
        }
    }

    static* querySettings(action) {
        const {noticeId, id} = action.payload;
        if (noticeId == null || id == null)
            return;

        try {
            const response = yield contextCall(UserNoticeApi, 'getComments', noticeId, id);
            if (AxiosProxy.shouldUpdate(id, response)) {
                yield put(NoticeCommentModel.actionCreators.setSubsetDetailsMap(id, response.data))
            }
        } finally {
            yield put(ReduxStateModel.actionCreators.setHasLoaded(id));
        }
    }
}

export default UserNoticeModel;
