import React, {useCallback, useLayoutEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import WorkflowBuilderModel from "../../../models/workflowbuilder/WorkflowBuilderModel";
import OperationsList from "../views/OperationList";
import AddOperationForm from "../views/AddOperationForm";
import OperationForm from "../views/OperationForm";
import {initialSelectedState} from "../../../utilities/hooks";
import {deepCopy} from "../../../utilities/helperFunctions";

function BuilderPane() {
    const dispatch = useDispatch();

    const workflowBuilder = useSelector(state => state.workflowBuilder);
    const updateState = useCallback(updates => dispatch(WorkflowBuilderModel.actionCreators.update(updates)), []);

    const [selected, setSelected] = useState(initialSelectedState);

    // Initialize with selectedOperationIndex
    useLayoutEffect(() => {
        if (workflowBuilder.selectedOperationIndex != null) {
            setSelected(prevState => ({
                ...prevState,
                values: {[workflowBuilder.selectedOperationIndex]: true},
                lastSelectedValue: workflowBuilder.selectedOperationIndex
            }));
        }
    }, []);

    // Update selectedOperationIndex on selection
    useLayoutEffect(() => {
        updateState({selectedOperationIndex: selected.lastSelectedValue});
    }, [selected.lastSelectedValue]);


    const updateSelectedOperation = useCallback(updates => {
        updateState(prevState => {
            const newOperations = [...prevState.operations];
            const selectedOperation = newOperations[workflowBuilder.selectedOperationIndex];
            if (selectedOperation == null) return prevState;

            const update = typeof updates === 'function' ? updates(selectedOperation) : updates;
            newOperations[workflowBuilder.selectedOperationIndex] = selectedOperation.shallowDuplicate(update);
            return {
                operations: newOperations
            }
        });
    }, [updateState, workflowBuilder.selectedOperationIndex]);

    const addOperation = useCallback((operations, replace, highlightAll) => {
        updateState(prevState => {
            const newOperations = [...prevState.operations];

            let operationIndex = prevState.operations.length - 1;
            if (prevState.selectedOperationIndex != null) {
                operationIndex = prevState.selectedOperationIndex;
                if (replace) {
                    operationIndex--;
                }
            }
            for (const operation of operations) {
                operationIndex++;
                newOperations.splice(operationIndex, replace ? 1 : 0, deepCopy(operation));
            }
            // Set selected to last operation added
            setTimeout(() => {
                if (highlightAll) {
                    const values = {}
                    for (let i = 0; i < operations.length; i++) {
                        values[operationIndex - i] = true;
                        setSelected({...initialSelectedState, values: values, lastSelectedValue: operationIndex});
                    }
                } else {
                    setSelected({...initialSelectedState, values: {[operationIndex]: true}, lastSelectedValue: operationIndex});
                }
            }, 10);

            return {
                operations: newOperations
            }
        });
    }, [selected, updateState]);

    const onCloseAddOperation = useCallback(() => {
        dispatch(WorkflowBuilderModel.actionCreators.update({isAddOperationFormActive: false}));
    }, []);


    const selectedOperation = workflowBuilder.operations[workflowBuilder.selectedOperationIndex];

    return (
        <section className="pane-margin fill-height" style={{padding: '0.75rem 0'}}>
            <section className="workflow-builder__view">

                <OperationsList updateState={updateState} addOperation={addOperation}
                    operations={workflowBuilder.operations} formConfigurations={workflowBuilder.operationFormConfigurations}
                    selected={selected} setSelected={setSelected} isDisabled={workflowBuilder.isDisabled}/>

                <section id="workflowBuilderContent" className="workflow-builder__content">
                    {(selectedOperation != null) &&
                        <OperationForm key={workflowBuilder.selectedOperationIndex} formKey={selectedOperation.operationAlias}
                            operation={selectedOperation} formConfiguration={workflowBuilder.operationFormConfigurations.get(selectedOperation.operationAlias)}
                            updateState={updateSelectedOperation} isDisabled={selectedOperation.disabled || workflowBuilder.isDisabled}/>
                    }
                </section>
            </section>

            {workflowBuilder.isAddOperationFormActive &&
                <AddOperationForm updateState={updateState} addOperation={addOperation} operationFilter={workflowBuilder.operationFilter}
                    onClose={onCloseAddOperation} operationFormConfigurations={workflowBuilder.operationFormConfigurations} isDisabled={workflowBuilder.isDisabled}/>
            }
        </section>
    )
}



export default BuilderPane;
