import React, {useEffect, useState} from "react";
import {objectIdentifierType} from "../../../utilities/constants";
import {useSelector} from "react-redux";
import {SwitchItemContainer} from "../../common/ListContainer/ListContainer";
import VaultOperationPane from "./VaultOperatonPane";
import {vaultExportSavedQueriesParameterNames} from "./VaultExportOptionsPane";
import {vaultSetMatterParameterNames} from "./VaultSetMatterPane";
import VaultCache from "../../../models/guidedjob/VaultCache";
import {useAsyncEffect} from "../../../utilities/hooks";
import {asyncSeparateItems} from "../../common/ListContainer/helpers";

function getNextEnabled(getParameter) {
    const savedQueryIdentifierType = getParameter(vaultExportSavedQueriesParameterNames.savedQueryIdentifierType);
    const savedQueryIdentifiers = getParameter(vaultExportSavedQueriesParameterNames.savedQueryIdentifiers, '[]', {parse: true});
    return {
        selectSavedQueries: savedQueryIdentifierType.value === objectIdentifierType.ID && savedQueryIdentifiers.value.length > 0
    };
}

export function getVaultSelectSavedQueriesGuide(operation, props) {
    const {
        workflowActions,
        updateParameter
    } = props;

    updateParameter(vaultExportSavedQueriesParameterNames.savedQueryIdentifierType, objectIdentifierType.ID);

    return {
        getPanes: function(t, props) {
            const nextEnabled = getNextEnabled(props.getParameter);
            return [{
                title: t(`guidedJob:panes.selectVaultSavedQueries`),
                isNextEnabled: nextEnabled.selectSavedQueries,
                component: <VaultOperationPane {...props}
                    workflowActions={workflowActions} OperationPane={VaultSelectSavedQueriesPane}/>
            }];
        }
    }
}

function VaultSelectSavedQueriesPane(props) {
    const {
        proxyRequest,
        getParameter,
        updateParameter,
        isDisabled
    } = props;

    const matterIdentifier = getParameter(vaultSetMatterParameterNames.matterIdentifier);
    const savedQueryIdentifiers = getParameter(vaultExportSavedQueriesParameterNames.savedQueryIdentifiers, '[]', {parse: true});
    const [savedQueryItems, setSavedQueryItems] = useState({left: [], right: []});

    const savedQueriesKey = VaultCache.getResourceKey(VaultCache.Resource.SAVED_QUERIES, {matterId: matterIdentifier.value});
    const savedQueries = useSelector(state => state.vaultCache[savedQueriesKey]) || {};
    const isLoadingSavedQueries = savedQueries.cache == null;

    useEffect(() => {
        proxyRequest(VaultCache.Resource.SAVED_QUERIES, {matterId: matterIdentifier.value});
    }, []);

    useEffect(() => {
        const savedQueryIds = savedQueryItems.right.map(item => item.value);
        updateParameter(vaultExportSavedQueriesParameterNames.savedQueryIdentifiers, JSON.stringify(savedQueryIds));
    }, [savedQueryItems]);

    useAsyncEffect(async () => {
        if (Array.isArray(savedQueries.cache)) {
            const savedQueryItems = savedQueries.cache.map(savedQuery => ({
                name: savedQuery.displayName,
                value: savedQuery.savedQueryId
            }));
            const items = await asyncSeparateItems(savedQueryIdentifiers.value, savedQueryItems);
            setSavedQueryItems(items);
        }
    }, [savedQueries.cache]);


    const containerStyle = {flex: 1};
    if (!isLoadingSavedQueries && savedQueries.stale) {
        containerStyle.opacity = 0.6;
    }

    return (
        <div className="display-input flex-expand">
            <SwitchItemContainer id="vaultSavedQueriesSwitchContainer" style={containerStyle}
                ariaLabelKey={'SavedQuery'} items={savedQueryItems} setItems={setSavedQueryItems} enableVirtualRendering
                isSearchable isLoading={isLoadingSavedQueries} isDisabled={isDisabled}/>
        </div>
    )
}
