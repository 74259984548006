import React, {useMemo, useRef, useState} from "react";
import "./ParameterList.css";
import {formatNuixDate, getValues} from "../../utilities/helperFunctions";
import {useTranslation} from "react-i18next";
import {
    initialSelectedState,
    useClearSelectedEffect,
    useKeySelectHandler,
    useTabNavigateEffect
} from "../../utilities/hooks";
import Text, {Label} from "../common/Text/Text";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import {SettingsRowValue} from "../common/CustomTable/CustomTable";
import ParameterModel from "../../models/library/ParameterModel";

// parameters -> map of ParameterModels {'' -> parameter}
function WorkflowParameters(props) {
    const {
        parameters,
        style,
        isDisabled
    } = props;

    const containerRef = useRef();
    const [selected, setSelected] = useState(initialSelectedState);

    const keySelectHandler = useKeySelectHandler({setSelected});
    useClearSelectedEffect({containerRef, setSelected});
    useTabNavigateEffect({containerRef});

    const paramNames = getValues(parameters).map(entry => [entry.name, entry.friendlyName]);
    const selectedParameter = parameters.get(selected.key);
    const selectedAllowedValues = selectedParameter && selectedParameter.parameterAllowedValues;

    const hasAllowedValues = Array.isArray(selectedAllowedValues) && selectedAllowedValues.length > 0;
    const isAllowedValuesHidden = selected.key == null || !hasAllowedValues || selectedParameter.isPasswordParameter() || selectedParameter.isDatasetParameter()
        || selectedParameter.isAzureStorageAccountParameter() || selectedParameter.isThirdPartyServiceParameter()
        || selectedParameter.isFileParameter() || selectedParameter.isSeparatorParameter();

    return (
        <div className={'value-list' + (isDisabled ? ' is-disabled' : '')} ref={containerRef} style={style}>
            <NameBox names={paramNames} selected={selected}
                selectHandler={keySelectHandler}/>

            <ParameterInfoBox selectedParameter={selectedParameter}/>

            <ParameterAllowedValuesBox allowedValues={selectedAllowedValues} selected={selected}
                isHidden={isAllowedValuesHidden}/>
        </div>
    )
}

function NameBox(props) {
    const {t} = useTranslation(['workflow']);
    const {names, selected, selectHandler} = props;

    return (
        <div className="list-box">
            <div className="box-header">
                <Label id="workflowParameters:friendlyName"
                    value={t('workflow:label.friendlyName')}/>
            </div>

            <div className="input-box">
                {names.map(([name, friendlyName]) => {
                    const isSelected = (selected.key === name);
                    return (
                        <HTMLTextInput id={name} key={name} containerClassName={'input-list-container'} className={'is-pointer'}
                            data-index={name} value={friendlyName} onClick={selectHandler}
                            aria-labelledby="workflowParameters:friendlyName" isSelected={isSelected} readOnly/>
                    )
                })}
            </div>
        </div>
    )
}

function ParameterInfoBox(props) {
    const {t} = useTranslation(['workflow', 'workflowBuilder', 'common']);
    const {selectedParameter} = props;

    const info = useMemo(() => {
        if (selectedParameter == null) return;
        const {min, max, displayCondition, relativityCondition} = selectedParameter;
        const info = {};

        if (min != null || max != null) {
            const _min = parseInt(min);
            const _max = parseInt(max);

            if (selectedParameter.isNumberParameter()) {
                info.filter = `[${!isNaN(_min) ? _min : ':'} TO ${!isNaN(_max) ? _max : ':'}]`;

            } else if (selectedParameter.isDateParameter()) {
                const minDate = !isNaN(_min) ? formatNuixDate(_min).slice(0, -7) : ':';
                const maxDate = !isNaN(_max) ? formatNuixDate(_max).slice(0, -7) : ':';
                info.filter = `[${minDate} TO ${maxDate}]`;

            } else if (selectedParameter.isDateTimeParameter()) {
                const minDate = !isNaN(_min) ? formatNuixDate(_min) : ':';
                const maxDate = !isNaN(_max) ? formatNuixDate(_max) : ':';
                info.filter = `[${minDate} TO ${maxDate}]`;
            }
        }
        if (displayCondition.parameter != null && displayCondition.condition != null && displayCondition.value != null) {
            const {parameter, condition, value} = displayCondition;
            info.displayCondition = `${parameter} ${t('workflow:displayCondition.'+condition)} ${value}`
        }
        if (relativityCondition.filterParameter != null && relativityCondition.filterType != null) {
            const {filterParameter, filterType} = relativityCondition;
            info.relativityCondition = `${t('workflow:relativityCondition.'+filterType)} ${filterParameter}`
        }
        return info;
    }, [selectedParameter]);


    if (selectedParameter == null) {
        return (
            <div className="list-box"/>
        )
    }

    let parameterDisplayValue = selectedParameter.value;
    if (selectedParameter.parameterType === ParameterModel.Type.FILE_CONTENTS && selectedParameter.value != null) {
        parameterDisplayValue = selectedParameter.value.split(":")[0];
    }

    return (
        <div className="list-box">
            <div className="box-header no-bottom">
                <Text value={t('common:label.details')}/>
            </div>

            <div className="info-box">
                <div className="settings-table is-tight align-top">
                    <div className="table-row-group">
                        <SettingsRowValue label={t('common:label.name')} value={selectedParameter.name}/>
                        <SettingsRowValue label={t('common:label.type')} value={t('workflowBuilder:ParameterType.'+selectedParameter.parameterType)}/>
                        <SettingsRowValue label={t('workflow:label.defaultValue')} value={parameterDisplayValue}/>
                        <SettingsRowValue label={t('common:label.description')} value={selectedParameter.description}/>
                        <SettingsRowValue label={t('workflow:label.regex')} value={selectedParameter.regex}/>
                        <SettingsRowValue label={t('workflow:label.displayCondition')} value={info.displayCondition}/>
                        <SettingsRowValue label={t('workflow:label.relativityCondition')} value={info.relativityCondition}/>
                        <SettingsRowValue label={t('workflow:label.range')} value={info.filter}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ParameterAllowedValuesBox(props) {
    const {allowedValues, selected, isHidden, isDisabled} = props;
    const {t} = useTranslation(['aria', 'workflow']);

    return (
        <div className="list-box">
            {!isHidden &&
            <>
                <div className={'box-header'}>
                    <Text value={t('workflow:label.allowedValues')} isDisabled={isDisabled}/>
                </div>

                <div className="input-box">
                    {getValues(allowedValues).map(({value}, index) => {
                        return (
                            <HTMLTextInput key={index} containerClassName={'input-list-container'}
                                aria-label={t('aria:input.allowedValue')} name={selected.key} value={value}
                                isInputDisabled/>
                        )
                    })}
                </div>
            </>
            }

        </div>
    )
}

export default WorkflowParameters;