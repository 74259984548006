import React from 'react';
import {useTranslation} from "react-i18next";
import {stringToBool} from "../../../utilities/helperFunctions";
import ToggleSwitch from "../../common/ToggleSwitch/ToggleSwitch";
import {componentKeys} from "../../../utilities/constants";
import {Label} from "../../common/Text/Text";

function QueueJobDesigner(props) {
    const {setting: queueJobOptions, updateSetting} = props;
    const {t} = useTranslation(['queueJobDesigner']);

    function setComponentOrderSetting(event) {
        const {name, checked} = event.currentTarget.dataset;

        const componentOrder = stringToBool(checked) ? [componentKeys.PARAMETERS_PANEL, componentKeys.NOTES] : [componentKeys.NOTES, componentKeys.PARAMETERS_PANEL];
        updateSetting(name, componentOrder);
    }

    function updateQueueJobSetting(event) {
        const {name, checked} = event.currentTarget.dataset;
        updateSetting(name, stringToBool(checked));
    }

    const notesLabelId = 'queueJobDesigner:notesAtBottom';

    return (
        <table className="display-input">
            <thead className="table-header-group">
            <tr>
                <td className="table-header" colSpan={2}/>
            </tr>
            </thead>

            <tbody>
            <tr>
                <td className="table-cell enable-cell no-stretch">
                    <ToggleSwitch aria-labelledby={notesLabelId} name={'componentOrder'}
                        checked={queueJobOptions.componentOrder[0] === componentKeys.PARAMETERS_PANEL}
                        onClick={setComponentOrderSetting}/>
                </td>
                <td className="table-cell">
                    <label id={notesLabelId} className="label">
                        {t('queueJobDesigner:position.notes')}
                    </label>
                </td>
            </tr>

            <tr>
                <td className="table-cell enable-cell no-stretch">
                    <ToggleSwitch aria-labelledby={'queueJobDesigner:autoArchiveOnJobResubmitLabel'} name={'autoArchiveOnJobResubmit'}
                        checked={queueJobOptions.autoArchiveOnJobResubmit} onClick={updateQueueJobSetting}
                    />
                </td>
                <td className="table-cell">
                    <Label id={'queueJobDesigner:autoArchiveOnJobResubmitLabel'}
                        value={t('queueJobDesigner:label.autoArchiveOnJobResubmit')}/>
                </td>
            </tr>
            </tbody>
        </table>
    )
}

export default QueueJobDesigner;