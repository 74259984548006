import {camelCaseToSnakeCase, getValues} from "../../utilities/helperFunctions";

export const operationFieldParameterNames = new Set();

export function getOperationFieldParameterName(operationName, fieldName) {
    return `{${operationName.toLowerCase()}_${camelCaseToSnakeCase(fieldName)}}`;
}

export function buildOperationFieldParameterNames(operationAlias, fieldNames) {
    const parameterNames = fieldNames.reduce((acc, field) => {
        const fieldParameterName = getOperationFieldParameterName(operationAlias, field);
        acc[field] = fieldParameterName;
        if (!field.toLowerCase().endsWith('disabled')) {
            acc[`${field}Disabled`] = fieldParameterName.slice(0, -1) + '_disabled}';
        }
        return acc;
    }, {});

    for (const name of getValues(parameterNames)) {
        operationFieldParameterNames.add(name);
    }
    return parameterNames;
}
