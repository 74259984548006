import {updateGenericValues} from "../../../redux/WizardSlice/wizardSlice";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {createInputHandler, createValueHandler} from "../../../../utilities/componentFunctions";
import {createDropdownHandler} from "../../../../components/common/Dropdown/helpers";
import {createToggleHandler} from "../../../../components/common/Checkbox/helpers";
import React, {useEffect} from "react";
import QuestionRadio from "../../Question/QuestionRadio/QuestionRadio";
import {QuestionInput} from "../../Question/QuestionInput/QuestionInput";
import ToggleSwitch from "../../../../components/common/ToggleSwitch/ToggleSwitch";
import HTMLTextInput from "../../../../components/common/HTMLTextInput/HTMLTextInput";
import DiscoverCaseIdentifierDropdown from "../../Dropdown/DiscoverDropdown/DiscoverCaseIdentifierDropdown";
import MailTypeDropdown from "../../Dropdown/DiscoverDropdown/MailTypeDropdown";
import DiscoverUserCaseCategoryDropdown from "../../Dropdown/DiscoverDropdown/DiscoverUserCaseCategoryDropdown";
import DiscoverCaseCreationDropdown from "../../Dropdown/DiscoverDropdown/DiscoverCaseCreationDropdown";
import ExpandableContent from "../../../../components/common/ExpandableContent/ExpandableContent";

function DiscoverExport (props) {
    const {t} = useTranslation(['wizard'])
    const {updateState} = props;


    const {
        windowsFileRepository,
        caseIdentifier,
        deploymentType,
        deploymentUrl,
        caseNameType,
        createCaseFromTemplate,
        setConvertMail,
        sourceCaseName,
        clonedCaseOrganization,
        userToCase,
        caseGroupName,
        mailType,
        setAssignUserToCase,
        setAssignGroupToCase,
        defineTokenAtExecution
    } = props;

    const questions = [
        {
            question: t('wizard:questions.discover.deployment')
        },
        {
            question: t('wizard:questions.discover.caseDefined'),
            dataName: "caseNameType",
            options: [
                {id: 1, value: t('wizard:questions.discover.case.same')},
                {id: 2, value: t('wizard:questions.discover.case.predefined')},
                {id: 3, value: t('wizard:questions.discover.case.prompt')}
            ]
        },
        {
            question: t('wizard:questions.discover.caseName')
        },
        {
            question: t('wizard:questions.discover.fileRepositoryPath')
        },
        {
            question: t('wizard:questions.discover.caseCreation')
        },
        {
            question: t('wizard:questions.discover.casePermissions')
        }
    ]

    const inputHandler = createInputHandler({
        handler: updateState
    });
    const dropdownHandler = createDropdownHandler({
        handler: updateState
    });
    const valueHandler = createValueHandler({
        handler: updateState
    });
    const toggleHandler = createToggleHandler({
        handler: updateState
    });

    useEffect(() => {
        const deploymentTypeUrl = deploymentType === "SAAS" ? "https://ringtail.us.nuix.com" : "https://aio.discover.local";

        if (["https://ringtail.us.nuix.com", "https://aio.discover.local"].includes(deploymentUrl)) {
            inputHandler({target: {name: "deploymentUrl", value: deploymentTypeUrl}})
        }
    }, [deploymentType])

    const cloneOptionsDisabled = createCaseFromTemplate !== "TEMPLATE";
    const cloneLabelDisabled = createCaseFromTemplate !== "TEMPLATE" ? ' is-disabled' : ''
    const cloneWidthStyle = {
        width: '15rem'
    }

    return (
        <div className="wizard-step-container">
            <div className={"display-item"}>
                <label className="label is-heading">
                    {questions[0].question}
                </label>
                <div className='settings-table no-border tight-padding'
                     style={{border: '1px solid transparent', marginTop: "0.5rem", width: '45rem'}}>
                    <div className="table-row-group">
                        <div className="table-row align-middle no-hover">
                            <div className="table-cell no-stretch">
                                <label className="label is-small">{t('wizard:questions.discover.deploymentType')}</label>
                                <DiscoverCaseIdentifierDropdown
                                    selectedCaseIdentifierType={deploymentType}
                                    onCaseIdentifierTypeSelected={dropdownHandler}
                                    isRequired={true}
                                    isDisabled={false}/>
                            </div>
                            <div className="table-cell">
                                <label className="label is-small">{t('wizard:questions.discover.discoverUrl')}</label>
                                <HTMLTextInput name={"deploymentUrl"}
                                               value={deploymentUrl}
                                               onChange={inputHandler}
                                               isRequired={true}/>
                            </div>
                            <div className="table-cell no-stretch">
                                <label className="label is-small" style={{marginTop: '1.25rem'}}></label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {deploymentType === "LOCAL" &&
                <QuestionInput
                    name={"windowsFileRepository"}
                    question={questions[3].question}
                    value={windowsFileRepository}
                    onChange={inputHandler}
                    isRequired={true}/>
            }

            <QuestionRadio
                question={questions[1].question}
                dataName={questions[1].dataName}
                options={questions[1].options}
                selectedValue={caseNameType}
                onChange={valueHandler} />

            {caseNameType === 2 &&
            <QuestionInput
                name={"caseIdentifier"}
                question={questions[2].question}
                value={caseIdentifier}
                onChange={inputHandler}
                isRequired={true}/>
            }

            <div className={"display-item"}>
                <label className="label is-heading">
                    {questions[4].question}
                </label>
                <div className='settings-table no-border tight-padding'
                     style={{border: '1px solid transparent', width: '50rem'}}>
                    <div className="table-row-group">
                        <div className="table-row align-middle no-hover">
                            <div className="table-cell no-stretch">
                                <label className="label is-small">Existing case</label>
                                <DiscoverCaseCreationDropdown
                                    selectedCaseCreationType={createCaseFromTemplate}
                                    onCaseCreationTypeSelected={dropdownHandler}/>
                            </div>
                            <div className="table-cell">
                                <label className={"label is-small" + cloneLabelDisabled}>{t('wizard:questions.discover.sourceCase')}</label>
                                <HTMLTextInput name={"sourceCaseName"} value={sourceCaseName} onChange={inputHandler} isRequired={false} isDisabled={cloneOptionsDisabled} style={cloneWidthStyle}/>
                            </div>
                            <div className="table-cell">
                                <label className={"label is-small" + cloneLabelDisabled}>{t('wizard:questions.discover.clonedCase')}</label>
                                <HTMLTextInput name={"clonedCaseOrganization"} value={clonedCaseOrganization} onChange={inputHandler} isRequired={false} isDisabled={cloneOptionsDisabled} style={cloneWidthStyle}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"display-item"}>
                <label className="label is-heading">
                    {questions[5].question}
                </label>
                <div className='settings-table no-border tight-padding'
                     style={{border: '1px solid transparent', width: '50rem'}}>
                    <div className="table-row-group">
                        <div className="table-row align-middle no-hover">
                            <div className="table-cell" style={{display: 'flex', flexFlow: 'row no-wrap', alignItems: 'center', maxWidth: '35rem'}}>
                                <ToggleSwitch id={"setAssignUserToCase"} name={"setAssignUserToCase"}
                                              label={t('wizard:questions.discover.userToCase')} checked={setAssignUserToCase} onClick={toggleHandler}/>
                                <DiscoverUserCaseCategoryDropdown
                                    selectedUserCaseCategory={userToCase}
                                    onUserCaseCategorySelected={dropdownHandler}
                                    isRequired={false}
                                    isDisabled={!setAssignUserToCase}/>
                            </div>
                        </div>
                        <div className="table-row align-middle no-hover">
                            <div className="table-cell" style={{display: 'flex', flexFlow: 'row', alignItems: 'center', maxWidth: '35rem'}}>
                                <ToggleSwitch id={"setAssignGroupToCase"} name={"setAssignGroupToCase"}
                                              label={t('wizard:questions.discover.assignGroupCase')} checked={setAssignGroupToCase} onClick={toggleHandler}/>
                                <HTMLTextInput name={"caseGroupName"} value={caseGroupName} onChange={inputHandler} isRequired={false} isDisabled={!setAssignGroupToCase} style={{marginLeft: '0.5rem', flex: '1'}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"display-item"}>
                <ExpandableContent label={t("wizard:questions.discover.advanced")} initialExpanded={false}>

                    <div style={{display: 'flex', flexFlow: 'row no-wrap', alignItems: 'center', maxWidth: '35rem', padding: '0.25rem 0.75rem'}}>
                        <ToggleSwitch id={"createCaseFromTemplate"} name={"setConvertMail"}
                                      label={t('wizard:questions.discover.convertMail')} checked={setConvertMail} onClick={toggleHandler}/>
                        <MailTypeDropdown
                            selectedMailType={mailType}
                            onMailTypeSelected={dropdownHandler}
                            isRequired={setConvertMail}
                            isDisabled={!setConvertMail}/>
                    </div>

                    <div style={{padding: '0.25rem 0.75rem'}}>
                        <ToggleSwitch id={"defineTokenAtExecution"} name={"defineTokenAtExecution"}
                        label={t('wizard:questions.discover.defineTokenAtExecution')} checked={defineTokenAtExecution} onClick={toggleHandler}/>

                        {!defineTokenAtExecution &&
                            <div className="dynamic-examples-container">
                                <label className="label is-small">{t('wizard:questions.discover.expectedExample', {parameter: "{discover_api_token_protected}"})}</label>
                            </div>
                        }
                    </div>
                </ExpandableContent>
            </div>

        </div>
    )

}

function mapStateToProps (state) {
    const {wizardSlice:{
        discoverConfiguration: {hostname, caseIdentifierType, caseIdentifierTypeId, discoverFileRepositoryType, windowsFileRepository, apiToken, caseIdentifier,
            deploymentType, deploymentUrl, caseNameType, createCaseFromTemplate, setConvertMail, sourceCaseName, clonedCaseOrganization, createCaseFromTemplateType,
            userToCase, caseGroupName, mailType, setAssignUserToCase, setAssignGroupToCase, defineTokenAtExecution}}} = state;
    return {
        hostname,
        caseIdentifierType,
        discoverFileRepositoryType,
        windowsFileRepository,
        apiToken,
        caseIdentifier,
        caseIdentifierTypeId,
        deploymentType,
        deploymentUrl,
        caseNameType,
        createCaseFromTemplate,
        setConvertMail,
        sourceCaseName,
        clonedCaseOrganization,
        createCaseFromTemplateType,
        userToCase,
        caseGroupName,
        mailType,
        setAssignUserToCase,
        setAssignGroupToCase,
        defineTokenAtExecution
    };
}

function mapDispatchToProps (dispatch) {
    return {
        updateState: updates => dispatch(updateGenericValues({stateName: "discoverConfiguration", updates: updates}))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverExport);