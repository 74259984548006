import React, {useEffect, useRef, useState} from "react";
import "./DropdownSelectedList.css";
import {
    initialSelectedState,
    useClearSelectedEffect,
    useTabNavigateEffect,
    useValueSelectHandler
} from "../../../utilities/hooks";
import {
    buildClassName,
    filterArrayIndices,
    isNotEmptyNorFalsy,
    objectTruthyValues
} from "../../../utilities/helperFunctions";
import Text from "../Text/Text";
import {ListDropdown} from "../Dropdown/Dropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {Button} from "../Button/Button";

function DropdownSelectedList(props) {
    const {t} = useTranslation(['aria']);
    const {
        id,
        name,
        label,

        availableItems,
        values,
        setValues,

        isRequired,
        isDisabled,
    } = props;

    const containerRef = useRef();
    const [selected, setSelected] = useState(initialSelectedState);

    const valueSelectHandler = useValueSelectHandler({setSelected});
    useClearSelectedEffect({containerRef, setSelected});
    useTabNavigateEffect({containerRef});

    const [dropdownItems, setDropdownItems] = useState([]);
    const [listItems, setListItems] = useState([]);

    useEffect(() => {
        if (availableItems == null || values == null) return;
        const newDropdownItems = [], newListItems = [];
        for (const value of values) {
            const item = availableItems.find(_item => _item.value === value) || {name: value, value, invalid: true};
            newListItems.push(item);
        }
        for (const item of availableItems) {
            if (!newListItems.includes(item)) {
                newDropdownItems.push(item);
            }
        }
        setDropdownItems(newDropdownItems);
        setListItems(newListItems);
    }, [values, availableItems]);

    function addItem(event) {
        const value = event.currentTarget.dataset.value;
        const item = dropdownItems.find(item => item.value === value);
        setValues(prevValues => prevValues == null ? [item.value] : [...prevValues, item.value]);
        setSelected(prevSelected => ({...prevSelected, values: {}, lastSelectedValue: null}));
    }

    function removeItem() {
        const selectedIndices = objectTruthyValues(selected.values);
        setValues(prevValues => filterArrayIndices(prevValues, selectedIndices));
        setSelected(prevSelected => ({...prevSelected, values: {}, lastSelectedValue: null}));
    }

    const containerClassName = buildClassName(
        'dropdown-selected-list__table',
        (isRequired && !isNotEmptyNorFalsy(values)) && 'is-required',
        isDisabled && 'is-disabled'
    );

    const isRemoveDisabled = isDisabled || !objectTruthyValues(selected.values).length > 0;

    return (
        <div id={id}>
            <div className="add-remove-buttons">
                <ListDropdown id={`${id}AddDropdown`} aria-label={t(`aria:hiddenAssistText.add${name}`)}
                    ButtonComponent={DropdownButton} items={dropdownItems} onItemSelect={addItem} isDisabled={isDisabled}
                />

                <Button name="removeButton" isImg aria-label={t(`aria:hiddenAssistText.remove${name}`)}
                    onClick={removeItem} isDisabled={isRemoveDisabled}
                >
                    <FontAwesomeIcon icon="minus"/>
                </Button>

                {label &&
                    <Text value={label} isDisabled={isDisabled}/>
                }
            </div>

            <table className={containerClassName}>
                <tbody className="table-row-group">
                    {listItems.map((item, index) =>
                        <DefaultRow key={index} data-index={index} item={item} onClick={valueSelectHandler}
                            isSelected={selected.values[index]} isDisabled={isDisabled}/>
                    )}
                </tbody>
            </table>
        </div>
    )
}

const DropdownButton = React.forwardRef((props, ref) => {
    const {
        isClear,
        ...attr
    } = props;

    return (
        <Button ref={ref} isImg isWhite={isClear} {...attr}>
            <FontAwesomeIcon icon="plus"/>
        </Button>
    )
});

function DefaultRow(props) {
    const {item, onClick, isSelected, isDisabled, ...attr} = props;

    const className = buildClassName(
        'dropdown-selected-list__row',
        isSelected && 'is-selected',
        item.invalid && 'is-invalid'
    );

    return (
        <tr className={className} onClick={isDisabled ? null : onClick} {...attr}>
            <td className="dropdown-selected-list__cell">
                <Text value={item.name}/>
            </td>
        </tr>
    )
}

export default DropdownSelectedList;
