import React from "react";
import {UserNoticeBody} from "../notice/UserNoticeDisplay";
import {icon} from "../../utilities/iconResolver";
import {UserNoticeHeader} from "../legalhold/UserNoticeTablet";


function UserNoticeHtmlTemplate(props) {
    const {
        legalHoldName,
        userNotice,
        comments
    } = props;

    const {
        surveyFormOptions
    } = userNotice

    const recipientName = 'user';
    const recipientIcon = icon('user');

    return (
        <>
            <UserNoticeHeader userNotice={userNotice} inlineIcon/>

            <div className="work-tablet__body">
                <UserNoticeBody legalHoldName={legalHoldName} userNotice={userNotice} surveyFormOptions={surveyFormOptions} comments={comments}
                    recipientName={recipientName} recipientIcon={recipientIcon} isReadOnly isTemplate/>
            </div>
        </>
    )
}

export default UserNoticeHtmlTemplate;