import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {createCloseHandler, createInputHandler, createStateHandler} from "../../utilities/componentFunctions";
import PopupModel from "../../models/scheduler/PopupModel";
import FileLibraryModel from "../../models/filelibrary/FileLibraryModel";
import Form, {FormFooter, FormHeader} from "../common/Form/Form";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import TextArea from "../common/TextArea/TextArea";
import {shouldEnableFileLibraryResource} from "../../utilities/shouldEnableFunctions";

function FileLibraryForm () {
    const dispatch = useDispatch()
    const {t} = useTranslation(['fileLibrary', 'common'])

    const {
        isDisabled
    } = useSelector(state => state.componentStates.fileLibraryForm)

    const [state, setState] = useState({
        fileLibraryName: '',
        description: '',
        isAddEnabled: false
    });

    const stateHandler = createStateHandler({
        updateState: setState,
        shouldEnable: shouldEnableFileLibraryResource
    });

    const inputHandler = createInputHandler({
        handler: stateHandler
    });

    function onSubmit() {
        dispatch(FileLibraryModel.actionCreators.submitForm(state))
    }

    const onClose = createCloseHandler({
        t,
        item: t('fileLibrary:label.name_simple'),
        values: state,
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        close: () => dispatch(FileLibraryModel.componentActionCreators.updateDisplay({isFileLibraryFormActive: false}))
    });

    const {
        fileLibraryName,
        description,
        isAddEnabled
    } = state;

    return (
        <Form onClose={onClose} isDisabled={isDisabled} closeButtonAriaLabel={t('fileLibrary:option.closeForm')}
              header={
                  <FormHeader text={t('fileLibrary:label.name')} iconName={'fileLibrary'}
                              isDisabled={isDisabled}
                  />
              }

              body={
                  <>
                      <div className="display-input">
                          <HTMLTextInput label={t('common:label.name')} name={'fileLibraryName'} value={fileLibraryName} onChange={inputHandler}
                                         isDisabled={isDisabled} isRequired
                          />
                      </div>

                      <div className="display-input">
                          <TextArea label={t('common:label.description')} name={'description'} value={description} onChange={inputHandler}
                                    isDisabled={isDisabled}
                          />
                      </div>
                  </>
              }

              footer={
                  <FormFooter addText={t('common:button.addFileLibrary')}
                              onAddClick={onSubmit} onCancel={onClose} isAddEnabled={isAddEnabled} isDisabled={isDisabled}
                  />
              }
        />
    )
}

export default FileLibraryForm;