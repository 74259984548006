import {useTranslation} from "react-i18next";
import {createToggleHandler} from "../../common/Checkbox/helpers";
import {updateMode} from "../../../utilities/constants";
import {createNestedUpdateState} from "../../../utilities/componentFunctions";
import {chunkArray, getValues, isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import {datasetEventTriggerKeys} from "../../../i18next/keys";
import Checkbox from "../../common/Checkbox/Checkbox";
import React from "react";
import PropertyFilterRow from "./PropertyFilterRow";

function DatasetEventTriggersPanel(props) {
    const {eventTrigger, updateState} = props;
    const {t} = useTranslation(['dataset', 'jobSchedule']);

    const toggleEnable = createToggleHandler({
        mode: updateMode.REDUX,
        updateState: createNestedUpdateState(updateState, ['eventTrigger', 'datasetEvents'])
    });

    const {
        datasetEvents,
        datasetName,
        dataRepositoryName,
        clientName,
        matterName
    } = eventTrigger;

    const chunkEvents = chunkArray(getValues(datasetEventTriggerKeys), 3);
    const required = isNotEmptyNorFalsy(datasetEvents) ? '' : ' is-required';

    return (
        <>
            <div className="display-input">
                <label className="label is-heading">
                    {t('jobSchedule:label.datasetEvents')}
                </label>
                <label className="label is-note">
                    {t('jobSchedule:message.datasetEventsTriggerSchedule')}
                </label>

                <div className={'settings-table no-border tight-padding display-input' + required} style={{border: '1px solid transparent'}}>
                    <div className="table-row-group">
                        {chunkEvents.map((evnts, index) =>

                            <div key={index} className="settings-row">
                                {evnts.map(evnt =>

                                    <div key={evnt} className="table-cell no-stretch">
                                        <Checkbox name={evnt} label={t(`dataset:trigger.${evnt}`)}
                                            checked={datasetEvents[evnt]} onClick={toggleEnable}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="display-item">
                <label className="label is-heading">
                    {t('jobSchedule:label.propertyFilters')}
                </label>
                <label className="label is-note">
                    {t('jobSchedule:message.datasetEventsTriggerPropertyFilterUsage')}
                </label>

                <div className="settings-table align-middle tight-padding no-table-padding no-border" style={{marginTop: '0.5rem'}}>
                    <div className="table-header-group" style={{background: 'none'}}>
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('jobSchedule:label.property')}
                            </label>
                        </div>
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('jobSchedule:label.contains')}
                            </label>
                        </div>
                    </div>

                    <div className="table-row-group">
                        <PropertyFilterRow label={t('jobSchedule:filter.datasetName')} name={'datasetName'} property={datasetName} updateState={updateState}/>

                        <PropertyFilterRow label={t('jobSchedule:filter.dataRepositoryName')} name={'dataRepositoryName'} property={dataRepositoryName} updateState={updateState}/>

                        <PropertyFilterRow label={t('jobSchedule:filter.clientName')} name={'clientName'} property={clientName} updateState={updateState}/>

                        <PropertyFilterRow label={t('jobSchedule:filter.matterName')} name={'matterName'} property={matterName} updateState={updateState}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DatasetEventTriggersPanel;
