import React, {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import ToggleSwitch from "../common/ToggleSwitch/ToggleSwitch";
import {TableInputCell} from "../common/CustomTable/CustomTable";
import {ListDropdown, RecurringNoticeFrequencyDropdown} from "../common/Dropdown/Dropdown";
import {noticeTypeKeys} from "../../utilities/constants";
import {buildClassName, capitalize, getValues} from "../../utilities/helperFunctions";
import Text from "../common/Text/Text";
import {useSelector} from "react-redux";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import RadioButton from "../common/RadioButton/RadioButton";


function NoticeConfigurationTable(props) {
    const {t} = useTranslation(['legalHold', 'common']);

    const {
        id='',
        type='',
        noticeConfiguration,
        lastAddedIndex,

        inputHandler,
        toggleHandler,
        dropdownHandler,

        isLegalHoldActive,
        isSelected,
        isDisabled,
        ...attr
    } = props;

    const {
        noticeId,
        noticeTemplateId,

        enabled,
        disableComments,
        disableAdminNotes,

        requireResponse,
        respondByKey,
        respondByDate,
        respondByNDays,

        enableRemind,
        remindEveryNDays,
        reminderNoticeTemplateId,
        reminderNoticeId,

        escalateOnResponseFail,
        escalateNoticeTemplateId,
        escalateNoticeId,

        sendRecurringNoticeEveryN,
        recurringNoticeFrequency

    } = noticeConfiguration;


    const ref = useRef();
    useEffect(() => {
        if (lastAddedIndex != null) {
            ref.current?.focus();
        }
    }, [lastAddedIndex]);


    const className = buildClassName(
        'display-table',
        isSelected && 'is-selected'
    );

    const isTableDisabled = !enabled || isDisabled;
    const isResponseDisabled = !requireResponse || isTableDisabled;
    const isRemindResponseDisabled = !enableRemind || isResponseDisabled;
    const isEscalationDisabled = !escalateOnResponseFail || isResponseDisabled;
    const showRecurringNoticeFields = type === noticeTypeKeys.RECURRING;

    const isRespondByDateInvalid = respondByKey === 'respondByDate'
        && noticeId == null && !isResponseDisabled && !(new Date(respondByDate).getTime() > new Date().getTime());

    let minDate;
    if (noticeId == null) {
        minDate = new Date();
        minDate.setHours(24);
    }

    return (
        <form ref={ref} className={className} id={id}
            tabIndex={0} {...attr}
        >
            <div className="table-row-group">

                <div className="table-row no-hover">
                    <div className="table-cell no-stretch">
                        <ToggleSwitch aria-label={t(`aria:hiddenAssistText.enable${type}NoticeConfiguration`)} name={'enabled'} checked={enabled} onClick={toggleHandler}
                            isReadOnly={type === noticeTypeKeys.HOLD || type === noticeTypeKeys.RELEASE} isDisabled={isDisabled}
                        />
                    </div>

                    <div className="table-cell no-stretch">
                        <Text value={`${t(`legalHold:label.noticeTemplate`)}:`}
                            isDisabled={isTableDisabled}/>
                    </div>

                    {showRecurringNoticeFields ? (
                        <>
                            <div className="table-cell" style={{width: '18rem'}}>
                                <NoticeTemplateDropdown id={`${id}NoticeTemplateDropdown`} name={'noticeTemplateId'} type={type}
                                    selectedNoticeTemplateId={noticeTemplateId} onNoticeTemplateSelect={dropdownHandler}
                                    isRequired={!isTableDisabled} isDisabled={noticeId != null || isTableDisabled}
                                />
                            </div>
                            <div className="table-cell"/>
                        </>
                    ) : (
                        <>
                            <div className="table-cell no-stretch"/>
                            <div className="table-cell" style={{width: '18rem'}}>
                                <NoticeTemplateDropdown id={`${id}NoticeTemplateDropdown`} name={'noticeTemplateId'} type={type}
                                    selectedNoticeTemplateId={noticeTemplateId} onNoticeTemplateSelect={dropdownHandler}
                                    isRequired={!isTableDisabled} isDisabled={noticeId != null || isTableDisabled}
                                />
                            </div>
                        </>
                    )}

                    <div className="table-cell no-stretch">
                        <ToggleSwitch label={`${t('legalHold:label.enableRemind')}:`} isWordWrap={false}
                            name={'enableRemind'} checked={enableRemind} onClick={toggleHandler} isDisabled={isResponseDisabled}
                        />
                    </div>

                    <TableInputCell id={`${id}RemindEveryNDays`} label={t('common:label.days')}
                        type={'number'} name={'remindEveryNDays'} value={remindEveryNDays || ''} onChange={inputHandler}
                        isRequired={!isRemindResponseDisabled} isDisabled={isRemindResponseDisabled}
                        style={{width: '4rem', marginRight: '0.5rem'}}
                    />
                </div>

                {showRecurringNoticeFields ? (
                    <>
                        <div className="table-row no-hover">
                            <div className="table-cell"/>

                            <div className={"table-cell no-stretch"}>
                                <Text isCenter
                                    value={`${t('legalHold:label.sendEvery')}:`} isDisabled={isTableDisabled}/>
                            </div>

                            <div className="table-cell no-stretch">
                                <div className={"flex-center"}>
                                    <HTMLTextInput id={`${id}recurringNoticeInterval`} type={'number'} name={'sendRecurringNoticeEveryN'}
                                        value={sendRecurringNoticeEveryN || ''} aria-label={recurringNoticeFrequency}
                                        onChange={inputHandler} isRequired={!isTableDisabled} isDisabled={isTableDisabled} style={{width: '4rem'}}/>


                                    <RecurringNoticeFrequencyDropdown value={recurringNoticeFrequency} onItemSelect={dropdownHandler}
                                                                      isDisabled={isTableDisabled} isRequired={!isTableDisabled}/>
                                </div>
                            </div>

                            <div className="table-cell"/>

                            <div className="table-cell no-stretch">
                                <Text value={`${t('legalHold:label.reminderNoticeTemplate')}:`}
                                       isDisabled={isRemindResponseDisabled || isTableDisabled}/>
                            </div>

                            <div className="table-cell">
                                <NoticeTemplateDropdown id={`${id}ReminderNoticeTemplateDropdown`} name={'reminderNoticeTemplateId'} type={noticeTypeKeys.REMINDER}
                                    selectedNoticeTemplateId={reminderNoticeTemplateId} onNoticeTemplateSelect={dropdownHandler}
                                    isRequired={!isRemindResponseDisabled} isDisabled={reminderNoticeId != null || isRemindResponseDisabled}
                                />
                            </div>
                        </div>

                        <div className="table-row no-hover">
                            <div className="table-cell"/>
                            <div className="table-cell no-stretch">
                                <ToggleSwitch label={`${t('legalHold:label.requireResponse')}:`} isWordWrap={false}
                                              name={'requireResponse'} checked={requireResponse} onClick={toggleHandler} isDisabled={isTableDisabled}
                                />
                            </div>

                            <TableInputCell id={`${id}RespondByNDays`} label={t('common:label.days')}
                                            type={'number'} name={'respondByNDays'} value={respondByNDays || ''} onChange={inputHandler}
                                            isRequired={!isResponseDisabled}
                                            isDisabled={isResponseDisabled}
                                            style={{width: '4rem', marginRight: '0.5rem'}}
                            />
                            <div className="table-cell"/>

                            <div className="table-cell no-stretch">
                                <ToggleSwitch label={t('legalHold:label.escalateOnResponseFail')} isWordWrap={false}
                                              name={'escalateOnResponseFail'} checked={escalateOnResponseFail} onClick={toggleHandler}
                                              isDisabled={isResponseDisabled}
                                />
                            </div>

                            <div className="table-cell"/>
                        </div>
                        <div className="table-row no-hover">
                            <div className="table-cell"/>
                            <div className="table-cell">
                                <ToggleSwitch label={t('legalHold:label.disableComments')} isWordWrap={false}
                                              name={'disableComments'} checked={disableComments} onClick={toggleHandler} isDisabled={isTableDisabled}
                                />
                            </div>
                            <div className="table-cell"/>
                            <div className="table-cell"/>

                            <div className="table-cell no-stretch">
                                <Text value={`${t('legalHold:label.escalateNoticeTemplate')}:`}
                                       isDisabled={isEscalationDisabled || isTableDisabled}/>
                            </div>

                            <div className="table-cell">
                                <NoticeTemplateDropdown id={`${id}EscalateNoticeTemplateDropdown`} name={'escalateNoticeTemplateId'} type={noticeTypeKeys.ESCALATION}
                                    selectedNoticeTemplateId={escalateNoticeTemplateId} onNoticeTemplateSelect={dropdownHandler}
                                    isRequired={!isEscalationDisabled} isDisabled={escalateNoticeId != null || isEscalationDisabled}
                                />
                            </div>
                        </div>
                        <div className="table-row no-hover">
                            <div className="table-cell"/>

                            <div className="table-cell">
                                <ToggleSwitch label={t('legalHold:label.disableAdminNotes')} isWordWrap={false}
                                              name={'disableAdminNotes'} checked={disableAdminNotes} onClick={toggleHandler} isDisabled={isTableDisabled}
                                />
                            </div>

                            <div className="table-cell"/>
                            <div className="table-cell"/>
                            <div className="table-cell"/>
                            <div className="table-cell"/>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="table-row no-hover">
                            <div className="table-cell"/>

                            <div className="table-cell no-stretch">
                                <ToggleSwitch label={`${t('legalHold:label.requireResponse')}:`} isWordWrap={false}
                                    name={'requireResponse'} checked={requireResponse} onClick={toggleHandler} isDisabled={isTableDisabled}
                                />
                            </div>

                            <div className="table-cell no-stretch">
                                <RadioButton selected={respondByKey} name={'respondByKey'} aria-label={t('legalHold:label.requireResponse')}
                                             value={'respondByDate'} onClick={inputHandler} isDisabled={isResponseDisabled}
                                />
                            </div>

                            <TableInputCell id={`${id}RespondByDate`} type={'date'} name={'respondByDate'} value={respondByDate} onChange={inputHandler}
                                min={minDate && minDate.toISOString().split('T')[0]} isInvalid={isRespondByDateInvalid}
                                aria-label={t('aria:hiddenAssistText.respondByDate')}
                                isRequired={respondByKey === 'respondByDate' && !isResponseDisabled}
                                isDisabled={respondByKey !== 'respondByDate' || isResponseDisabled}
                                style={{width: '10rem'}}
                            />

                            <div className="table-cell no-stretch">
                                <Text value={`${t('legalHold:label.reminderNoticeTemplate')}:`}
                                       isDisabled={isRemindResponseDisabled || isTableDisabled}/>
                            </div>

                            <div className="table-cell">
                                <NoticeTemplateDropdown id={`${id}ReminderNoticeTemplateDropdown`} name={'reminderNoticeTemplateId'} type={noticeTypeKeys.REMINDER}
                                    selectedNoticeTemplateId={reminderNoticeTemplateId} onNoticeTemplateSelect={dropdownHandler}
                                    isRequired={!isRemindResponseDisabled} isDisabled={reminderNoticeId != null || isRemindResponseDisabled}
                                />
                            </div>
                        </div>

                        <div className="table-row no-hover">
                            <div className="table-cell"/>
                            <div className="table-cell"/>

                            <div className="table-cell no-stretch">
                                <RadioButton selected={respondByKey} name={'respondByKey'} aria-label={t('legalHold:label.requireResponse')}
                                             value={'respondByNDays'} onClick={inputHandler} isDisabled={isResponseDisabled}
                                />
                            </div>

                            <TableInputCell id={`${id}RespondByNDays`} label={t('common:label.days')}
                                            type={'number'} name={'respondByNDays'} value={respondByNDays || ''} onChange={inputHandler}
                                            isRequired={respondByKey === 'respondByNDays' && !isResponseDisabled}
                                            isDisabled={respondByKey !== 'respondByNDays' || isResponseDisabled}
                                            style={{width: '4rem', marginRight: '0.5rem'}}
                            />

                            <div className="table-cell no-stretch">
                                <ToggleSwitch label={t('legalHold:label.escalateOnResponseFail')} isWordWrap={false}
                                              name={'escalateOnResponseFail'} checked={escalateOnResponseFail} onClick={toggleHandler}
                                              isDisabled={isResponseDisabled}
                                />
                            </div>

                            <div className="table-cell"/>
                        </div>

                        <div className="table-row no-hover">
                            <div className="table-cell"/>
                            <div className="table-cell">
                                <ToggleSwitch label={t('legalHold:label.disableComments')} isWordWrap={false}
                                              name={'disableComments'} checked={disableComments} onClick={toggleHandler} isDisabled={isTableDisabled}
                                />
                            </div>
                            <div className="table-cell"/>
                            <div className="table-cell"/>

                            <div className="table-cell no-stretch">
                                <Text value={`${t('legalHold:label.escalateNoticeTemplate')}:`}
                                       isDisabled={isEscalationDisabled || isTableDisabled}/>
                            </div>

                            <div className="table-cell">
                                <NoticeTemplateDropdown id={`${id}EscalateNoticeTemplateDropdown`} name={'escalateNoticeTemplateId'} type={noticeTypeKeys.ESCALATION}
                                    selectedNoticeTemplateId={escalateNoticeTemplateId} onNoticeTemplateSelect={dropdownHandler}
                                    isRequired={!isEscalationDisabled} isDisabled={escalateNoticeId != null || isEscalationDisabled}
                                />
                            </div>
                        </div>

                        <div className="table-row no-hover">
                            <div className="table-cell"/>

                            <div className="table-cell">
                                <ToggleSwitch label={t('legalHold:label.disableAdminNotes')} isWordWrap={false}
                                              name={'disableAdminNotes'} checked={disableAdminNotes} onClick={toggleHandler} isDisabled={isTableDisabled}
                                />
                            </div>

                            <div className="table-cell"/>
                            <div className="table-cell"/>
                            <div className="table-cell"/>
                            <div className="table-cell"/>
                        </div>
                    </>
                )}
            </div>
        </form>
    )
}

function NoticeTemplateDropdown(props) {
    const {type='', selectedNoticeTemplateId, onNoticeTemplateSelect, ...attr} = props;
    const {t} = useTranslation(['aria', 'noticeTemplate']);

    const noticeTemplateDetailsMap = useSelector(state => state.noticeTemplateDetailsMap);
    const noticeTemplateItems = getValues(noticeTemplateDetailsMap)
        .filter(template => template.enabled && (!type || template.type === type))
        .map(({name, id, type}) => ({
            type,
            name,
            value: id
        }));

    const typeLower = type.toLowerCase();
    return (
        <ListDropdown id={`${typeLower}NoticeTemplateDropdown`} name={`${typeLower}NoticeTemplateId`}
            aria-label={t(`aria:hiddenAssistText.${typeLower}NoticeTemplate`)}
            noneSelectedMessage={t(`noticeTemplate:option.select${capitalize(type)}NoticeTemplate`)}
            value={selectedNoticeTemplateId} items={noticeTemplateItems} onItemSelect={onNoticeTemplateSelect}
            {...attr}
        />
    );
}

export default NoticeConfigurationTable;
