import React, {useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import LegalHoldModel from "../../../models/legalhold/LegalHoldModel";
import {createInputHandler, createStateHandler} from "../../../utilities/componentFunctions";
import {legalHoldStateKeys, permissionKeys} from "../../../i18next/keys";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import TextArea from "../../common/TextArea/TextArea";
import {createDropdownHandler} from "../../common/Dropdown/helpers";
import DataRepositoryModel from "../../../models/data/DataRepositoryModel";
import {isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import {DataRepositoryDropdown} from "../../common/Dropdown/Dropdown";
import Checkbox from "../../common/Checkbox/Checkbox";
import {createToggleHandler} from "../../common/Checkbox/helpers";
import {StatusLabel} from "../../common/Common";
import {datasetType} from "../../../utilities/constants";
import Text from "../../common/Text/Text";
import ParametersPanel from "../../parameters/ParametersPanel";
import {
    ExecutionProfileSettingsRow,
    PrioritySettingsRow,
    ResourcePoolSettingsRow
} from "../../schedule/panes/ScheduleJobPane";
import ThirdPartyServiceModel from "../../../models/thirdparty/ThirdPartyServiceModel";
import {SmtpServiceDropdown} from "../../thirdPartyService/smtp/SmtpServiceDropdown";
import RadioButton from "../../common/RadioButton/RadioButton";

function selectHoldNoticeTemplateSurveyFormOptions(state) {
    const {silent, holdNoticeConfiguration, noticeTemplateItemKeyToSurveyFormOptions} = state.componentStates.legalHoldForm;
    if (silent) {
        return;
    }

    const holdNoticeTemplate = state.noticeTemplateDetailsMap.get(holdNoticeConfiguration.noticeTemplateId);
    const {key} = LegalHoldModel.getNoticeConfigTemplateOptions(holdNoticeConfiguration, holdNoticeTemplate);

    return noticeTemplateItemKeyToSurveyFormOptions[key];
}

function HoldSettingsPane() {
    const {t} = useTranslation(['legalHold', 'common']);
    const dispatch = useDispatch();

    const {
        state,
        legalHoldName,
        description,
        silent,
        enableLoginLinks,
        surveyNoticesTrigger,

        triggerConfigurations,
        useTriggerConfigurations,
        executionProfileId,
        resourcePoolId,
        priority,

        parameters,
        requiresDataRepository,
        dataRepositoryId,
        smtpServiceId,
        schedulerUrl,

        userPermissions,
        isDisabled
    } = useSelector(state => state.componentStates.legalHoldForm);

    const holdNoticeTemplateSurveyFormOptions = useSelector(selectHoldNoticeTemplateSurveyFormOptions);
    const areHoldNoticeTemplateSurveyFormOptionsEmpty = !isNotEmptyNorFalsy(holdNoticeTemplateSurveyFormOptions);
    const areTriggerConfigurationsEmpty = !isNotEmptyNorFalsy(triggerConfigurations)

    const updateState = useCallback(updates => dispatch(LegalHoldModel.componentActionCreators.updateForm(updates)), [dispatch]);
    const reduxHandler = createStateHandler({
        updateState
    });

    const inputHandler = createInputHandler({
        handler: reduxHandler
    });
    const toggleHandler = createToggleHandler({
        handler: reduxHandler
    });
    const dropdownHandler = createDropdownHandler({
        handler: reduxHandler
    });


    useEffect(() => {
        if (requiresDataRepository) {
            dispatch(DataRepositoryModel.actionCreators.queryDetails());
        }
        dispatch(ThirdPartyServiceModel.actionCreators.queryDetails());
    }, []);

    // If holdNoticeTemplate has no surveyFormOptions, force surveyNoticeTrigger ON_HOLD
    useEffect(() => {
        if (areHoldNoticeTemplateSurveyFormOptionsEmpty) {
            reduxHandler({surveyNoticesTrigger: LegalHoldModel.SurveyNoticesTrigger.ON_HOLD});
        }
    }, [areHoldNoticeTemplateSurveyFormOptionsEmpty]);

    const updateParameters = useCallback(updates => {
        updateState(prevState => ({
            parameters: updates(prevState.parameters)
        }));
    }, [updateState]);

    function loadTsvParameters(tsvText) {
        dispatch(LegalHoldModel.actionCreators.loadTsvParameters(tsvText));
    }

    function resetTsvParameters() {
        dispatch(LegalHoldModel.actionCreators.resetParameters());
    }

    const isDraft = state === legalHoldStateKeys.DRAFT;
    const isActive = state === legalHoldStateKeys.ACTIVE;
    const cantModify = userPermissions != null && !userPermissions.includes(permissionKeys.MODIFY);
    // Can modify when DRAFT, partial when ACTIVE
    const isDraftDisabled = isDisabled || !isDraft || cantModify;
    const isDraftActiveDisabled = isDisabled || (!isDraft && !isActive) || cantModify;

    const isTriggerConfigurationSettingsVisible = useTriggerConfigurations && !areTriggerConfigurationsEmpty;
    const isTriggerConfigurationsRequired = isTriggerConfigurationSettingsVisible;

    const isExecutionProfileInvalid = isTriggerConfigurationsRequired && executionProfileId == null;
    const isResourcePoolInvalid = isTriggerConfigurationsRequired && resourcePoolId == null;
    const isPriorityInvalid = isTriggerConfigurationsRequired && priority == null;

    return (
        <div className="pane-margin">
            <div className="display-input">
                <HTMLTextInput label={t('common:label.name')} name={'legalHoldName'} value={legalHoldName}
                    onChange={inputHandler} isDisabled={isDraftDisabled} isRequired/>
            </div>

            <div className="display-input">
                <TextArea label={t('common:label.description')} name={'description'} value={description} onChange={inputHandler}
                    isDisabled={isDraftDisabled}/>
            </div>

            <div className="settings-table display-input">
                <div className="table-row-group">
                    {requiresDataRepository &&
                        <StatusWarningRow label={t('legalHold:label.dataRepository')} statusMessage={t('legalHold:message.dataRepositoryRequiredToActivate')}
                            value={smtpServiceId} isDisabled={isDraftActiveDisabled}>

                            <DataRepositoryDropdown type={datasetType.MANAGED} selectedDataRepositoryId={dataRepositoryId} buttonStyle={{maxWidth: '100%'}}
                                onDataRepositorySelect={dropdownHandler} autoSelect isRequired={isActive} isDisabled={isDraftActiveDisabled}/>
                        </StatusWarningRow>
                    }

                    {!silent &&
                        <StatusWarningRow label={t('legalHold:label.smtpService')} statusMessage={t('legalHold:message.smtpServiceRequiredToActivate')}
                            value={smtpServiceId} isDisabled={isDraftActiveDisabled}>

                            <SmtpServiceDropdown value={smtpServiceId} onItemSelect={dropdownHandler} buttonStyle={{maxWidth: '100%'}}
                                autoSelect isRequired={isActive} isDisabled={isDraftActiveDisabled}/>
                        </StatusWarningRow>
                    }

                    {isTriggerConfigurationSettingsVisible &&
                        <>
                            <ExecutionProfileSettingsRow executionProfileId={executionProfileId} onItemSelect={dropdownHandler} buttonStyle={{maxWidth: '100%'}}
                                isDisabled={isDisabled} isRequired={isTriggerConfigurationsRequired} isInvalid={isExecutionProfileInvalid}/>
                            <ResourcePoolSettingsRow resourcePoolId={resourcePoolId} onItemSelect={dropdownHandler} buttonStyle={{maxWidth: '100%'}}
                                isDisabled={isDisabled} isRequired={isTriggerConfigurationsRequired} isInvalid={isResourcePoolInvalid}/>
                            <PrioritySettingsRow priority={priority} onItemSelect={dropdownHandler} buttonStyle={{maxWidth: '100%'}}
                                isDisabled={isDisabled} isRequired={isTriggerConfigurationsRequired} isInvalid={isPriorityInvalid}/>
                        </>
                    }
                </div>
            </div>

            {!silent &&
                <>
                    <div className="display-input">
                        <Text value={t('legalHold:label.surveyNoticesTrigger')} isDisabled={isDraftDisabled}/>
                        <div style={{padding: '0.45rem 0'}}>
                            <RadioButton name={'surveyNoticesTrigger'} selected={surveyNoticesTrigger} value={LegalHoldModel.SurveyNoticesTrigger.ON_HOLD}
                                label={t(`legalHold:surveyNoticesTrigger.${LegalHoldModel.SurveyNoticesTrigger.ON_HOLD}`)}
                                onClick={inputHandler} isWordWrap={false} isDisabled={isDraftDisabled}/>
                        </div>

                            <div style={{padding: '0.45rem 0'}}>
                                <RadioButton name={'surveyNoticesTrigger'} selected={surveyNoticesTrigger} value={LegalHoldModel.SurveyNoticesTrigger.ON_HOLD_RESPONSE}
                                    label={t(`legalHold:surveyNoticesTrigger.${LegalHoldModel.SurveyNoticesTrigger.ON_HOLD_RESPONSE}`)}
                                    onClick={inputHandler} isWordWrap={false} isDisabled={areHoldNoticeTemplateSurveyFormOptionsEmpty || isDraftDisabled}/>
                            </div>
                    </div>
                </>
            }

            {!silent &&
            <div className="display-input">
                <HTMLTextInput label={t('legalHold:label.schedulerUrl')} name={'schedulerUrl'} value={schedulerUrl}
                    onChange={inputHandler} isDisabled={isDraftActiveDisabled} isRequired/>
            </div>
            }

            {!silent &&
            <div style={{marginBottom: '0.75rem'}}>
                <Checkbox containerId={'legalHoldEnableLoginLinks'} label={t('legalHold:option.enableLoginLinks')} name={'enableLoginLinks'}
                    checked={enableLoginLinks} onClick={toggleHandler} isDisabled={isDraftActiveDisabled}/>
            </div>
            }

            {!silent && isNotEmptyNorFalsy(parameters) &&
            <div className="display-input">
                <ParametersPanel parameters={parameters} updateParameters={updateParameters} loadTsvParameters={loadTsvParameters}
                    resetParameters={resetTsvParameters} isDisabled={isDraftActiveDisabled}/>
            </div>
            }
        </div>
    )
}

function StatusWarningRow(props) {
    const {
        label,
        statusMessage,
        value,
        children,
        isDisabled
    } = props;

    return (
        <div className={"settings-row"}>
            <div className={"settings-label-cell no-stretch"}>
                <Text value={label} isDisabled={isDisabled}/>
            </div>

            <div className={"settings-value-cell"} style={{display: "flex", marginLeft: '1rem'}}>
                {children}
                {!value &&
                    <StatusLabel isDisabled={isDisabled} message={statusMessage}/>
                }
            </div>
        </div>
    )
}

export default HoldSettingsPane;