import React, {useEffect, useState} from "react";
import VaultOperationPane from "./VaultOperatonPane";
import VaultCache from "../../../models/guidedjob/VaultCache";
import WorkflowBuilderOperation from "../../../models/workflowbuilder/WorkflowBuilderOperation";
import {vaultHoldLocationsInputTypes} from "../../../utilities/constants";
import {vaultSetMatterParameterNames} from "./VaultSetMatterPane";
import {buildOperationFieldParameterNames} from "../../guidedJob/operationFieldParameterNames";
import {getNonEmptyValueOrDefault, stringToBool} from "../../../utilities/helperFunctions";
import {useSelector} from "react-redux";
import {SwitchItemContainer} from "../../common/ListContainer/ListContainer";
import {useAsyncEffect} from "../../../utilities/hooks";
import {asyncSeparateItems} from "../../common/ListContainer/helpers";
import Checkbox from "../../common/Checkbox/Checkbox";
import {useTranslation} from "react-i18next";

const fieldNames = ['disabled', 'holdLocationsInputType', 'holdLocations', 'removeFromAllHolds', 'holdIdentifierType', 'holdIdentifiers'];
export const vaultRemoveLocationsFromHoldsParameterNames = buildOperationFieldParameterNames(WorkflowBuilderOperation.Alias.VAULT_REMOVE_LOCATIONS_FROM_HOLDS, fieldNames);

function getNextEnabled(getParameter) {
    const selectHeldLocationsDisabled = getParameter(vaultRemoveLocationsFromHoldsParameterNames.disabled, false, {parse: true});
    const removeFromAllHolds = getParameter(vaultRemoveLocationsFromHoldsParameterNames.removeFromAllHolds, false, {parse: true});
    const holdLocations = getParameter(vaultRemoveLocationsFromHoldsParameterNames.holdLocations, '[]', {parse: true});
    const holdLocationsInputType = getParameter(vaultRemoveLocationsFromHoldsParameterNames.holdLocationsInputType);
    return {
        selectHeldLocations: selectHeldLocationsDisabled.value ||
            (removeFromAllHolds && holdLocationsInputType.value === vaultHoldLocationsInputTypes.VALUES && holdLocations.value.length > 0)
    };
}

export function getVaultSelectHeldLocationsGuide(operation, props) {
    const {
        workflowActions,
        getParameter,
        updateParameter
    } = props;

    const removeFromAllHolds = getParameter(vaultRemoveLocationsFromHoldsParameterNames.removeFromAllHolds);
    const holdLocations = getParameter(vaultRemoveLocationsFromHoldsParameterNames.holdLocations, '[]');

    updateParameter(vaultRemoveLocationsFromHoldsParameterNames.holdLocationsInputType, vaultHoldLocationsInputTypes.VALUES);
    if (!holdLocations.value.startsWith('[') || !holdLocations.value.endsWith(']')) {
        updateParameter(vaultRemoveLocationsFromHoldsParameterNames.holdLocations, '[]');
    }
    if (removeFromAllHolds.value !== JSON.stringify(stringToBool(removeFromAllHolds.value))) {
        updateParameter(vaultRemoveLocationsFromHoldsParameterNames.removeFromAllHolds, JSON.stringify(true));
    }

    return {
        getPanes: function(t, props) {
            const selectHeldLocationsTitle = getNonEmptyValueOrDefault(operation.notes, t('guidedJob:panes.selectHeldLocations'));
            const nextEnabled = getNextEnabled(props.getParameter);
            return [{
                title: selectHeldLocationsTitle,
                isNextEnabled: nextEnabled.selectHeldLocations,
                component: <VaultOperationPane {...props}
                    workflowActions={workflowActions} OperationPane={VaultSelectHeldLocationsPane}/>
            }];
        }
    }
}

function VaultSelectHeldLocationsPane(props) {
    const {t} = useTranslation(['guidedJob', 'common']);

    const {
        proxyRequest,
        getParameter,
        updateParameter,
        isDisabled
    } = props;

    const selectHeldLocationsDisabled = getParameter(vaultRemoveLocationsFromHoldsParameterNames.disabled, false, {parse: true});
    const isOperationDisabled = isDisabled || selectHeldLocationsDisabled.value;

    const matterIdentifier = getParameter(vaultSetMatterParameterNames.matterIdentifier);
    const holdLocations = getParameter(vaultRemoveLocationsFromHoldsParameterNames.holdLocations, '[]', {parse: true});
    const [heldLocationItems, setHeldLocationItems] = useState({left: [], right: []});

    const heldLocationsKey = VaultCache.getResourceKey(VaultCache.Resource.ALL_HELD_LOCATIONS, {matterId: matterIdentifier.value});
    const heldLocations = useSelector(state => state.vaultCache[heldLocationsKey]) || {};
    const isLoadingHeldLocations = heldLocations.cache == null;

    useEffect(() => {
        proxyRequest(VaultCache.Resource.ALL_HELD_LOCATIONS, {matterId: matterIdentifier.value});
    }, []);

    useEffect(() => {
        const holdLocationValues = heldLocationItems.right.map(item => item.value);
        updateParameter(vaultRemoveLocationsFromHoldsParameterNames.holdLocations, JSON.stringify(holdLocationValues));
    }, [heldLocationItems]);

    useAsyncEffect(async () => {
        if (Array.isArray(heldLocations.cache)) {
            const heldLocationItems = [...heldLocations.cache];
            const items = await asyncSeparateItems(holdLocations.value, heldLocationItems);
            setHeldLocationItems(items);
        }
    }, [heldLocations.cache]);

    function toggleHandler(event) {
        const {name, checked} = event.target;
        updateParameter(name, JSON.stringify(checked));
    }

    const containerStyle = {flex: 1};
    if (!isLoadingHeldLocations && heldLocations.stale) {
        containerStyle.opacity = 0.6;
    }

    return (
        <div className="display-input flex-expand">
            <Checkbox label={t('guidedJob:label.skipRemovingHeldLocations')} containerStyle={{marginBottom: '0.75rem'}}
                name={vaultRemoveLocationsFromHoldsParameterNames.disabled} value={selectHeldLocationsDisabled.value}
                onClick={toggleHandler} isDisabled={isDisabled}/>

            <SwitchItemContainer id="vaultHeldLocationsSwitchContainer" style={containerStyle}
                ariaLabelKey={'Location'} items={heldLocationItems} setItems={setHeldLocationItems} enableVirtualRendering
                isSearchable isLoading={isLoadingHeldLocations} isDisabled={isOperationDisabled}/>
        </div>
    )
}