import {actionCreator, getKeys} from "../../utilities/helperFunctions";
import {call, put, select, take} from "redux-saga/effects";
import SchedulerModel from "./SchedulerModel";
import PopupModel from "./PopupModel";
import {popupInfoKeys} from "../../i18next/keys";
import SagaRunnable from "../generics/SagaRunnable";

class EditModel {

    static nom = 'EditModel';
    static actions = EditModel.buildActions();
    static actionCreators = EditModel.buildActionCreators(EditModel.actions);
    static reducer = EditModel.buildReducer(EditModel.actions);

    constructor() {
        this.activeModel = null;
        this.values = {};

        this.isSaveEnabled = false;
        this.isChanged = false;
    }

    static buildActions() {
        return {
            // EDIT ACTIONS
            START: 'START_EDIT',
            EDIT: 'UPDATE_EDIT',
            SET_SAVE_ENABLED: 'SET_SAVE_ENABLED',
            SAVE: 'SAVE_EDIT',
            CANCEL: 'CANCEL_EDIT',
            RESET: 'RESET_EDIT',

            SAVE_SUCCESS: 'EDIT_SAVE_SUCCESS',
            SAVE_FAIL: 'EDIT_SAVE_FAIL',

            CALL_EDIT_BREAKING_FUNC: 'CALL_EDIT_BREAKING_FUNC'
        }
    }

    static buildActionCreators(actions) {
        return {
            // EDIT ACTION CREATORS
            start: actionCreator(actions.START, 'model', 'values'),
            edit: actionCreator(actions.EDIT, 'edits', 'options'),
            setIsSaveEnabled: actionCreator(actions.SET_SAVE_ENABLED, 'isSaveEnabled'),
            save: actionCreator(actions.SAVE),
            cancel: actionCreator(actions.CANCEL),
            reset: actionCreator(actions.RESET),

            saveSuccess: actionCreator(actions.SAVE_SUCCESS),
            saveFail: actionCreator(actions.SAVE_FAIL),

            callEditBreakingFunc: (func, ...args) => ({
                type: actions.CALL_EDIT_BREAKING_FUNC,
                payload: {
                    func,
                    args
                }
            })
        }
    }

    static buildDispatchers(dispatch) {
        return {
            // EDIT ACTION DISPATCHERS
            setEditSaveEnabled: isSaveEnabled => dispatch(EditModel.actionCreators.setIsSaveEnabled(isSaveEnabled)),
            updateEdit: (edits, options={}) => dispatch(EditModel.actionCreators.edit(edits, options)),
            saveEdit: () => dispatch(EditModel.actionCreators.save()),
            cancelEdit: () => dispatch(EditModel.actionCreators.cancel())
        }
    };

    static buildReducer(actions) {
        return function(state = new this(), action) {
            switch (action.type) {
                case actions.START: {
                    const {model, values} = action.payload;

                    if (state.model == null) {
                        return {
                            ...state,
                            values,
                            activeModel: model
                        }
                    }

                    return state;
                }
                case actions.EDIT: {
                    const {edits, options: {shouldEnable, passwordName='password', passwordRef, passwordResetNames=['username'], passwordOptions}} = action.payload;

                    let newValues = edits;
                    if (typeof edits === 'function') {
                        newValues = edits(state.values);
                    }

                    let _passwordOptions = passwordOptions;
                    // New password options format to handle multiple passwords
                    if (_passwordOptions == null && passwordRef != null) {
                        _passwordOptions = {[passwordName]: {ref: passwordRef, resetNames: passwordResetNames}};
                    }

                    const passwords = {};
                    if (_passwordOptions != null) {
                        for (const name of getKeys(_passwordOptions)) {
                            const {ref, resetNames = []} = _passwordOptions[name];
                            // clear password if a resetName is being updated
                            if (ref != null && ref.current != null) {
                                if (resetNames.some(resetName => newValues[resetName] != null)) {
                                    ref.current.value = '';
                                    newValues[name] = '';
                                }
                                // value for shouldEnable function
                                passwords[name] = ref.current.value;
                            }

                            // For case ref isn't pointing to password element, try to get defaultValue
                            if (passwords[name] == null) {
                                passwords[name] = newValues[name] || state.values[name];
                            }
                        }
                    }

                    let isSaveEnabled = state.isSaveEnabled;
                    if (typeof shouldEnable === 'function') {
                        isSaveEnabled = shouldEnable({
                            ...state.values,
                            ...newValues,
                            ...passwords
                        });
                    }

                    return {
                        ...state,
                        values: {
                            ...state.values,
                            ...newValues
                        },
                        isChanged: true,
                        isSaveEnabled
                    }
                }
                case actions.SET_SAVE_ENABLED: {
                    const {isSaveEnabled} = action.payload;

                    if (isSaveEnabled !== state.isSaveEnabled) {
                        return {
                            ...state,
                            isSaveEnabled
                        }
                    }

                    return state;
                }
                case actions.RESET: {
                    return new this();
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }
}

export class EditSaga extends SagaRunnable {

    static activationComponent = 'APP';

    static* callEditBreakingFunc(action) {
        const {func, args} = action.payload;

        const {activeModel, isSaveEnabled, isChanged} = yield select(state => state.editDetails);
        if (activeModel == null) {
            return yield call(func, ...args);
        }

        const waitForEditResetEffect = call(function* () {
            yield take(EditModel.actions.RESET);
            yield call(func, ...args);
        });

        if (isChanged) {
            yield put(PopupModel.actionCreators.show({
                info: {
                    key: popupInfoKeys.SAFE_CLOSE
                },
                buttons: [{
                    titleKey: 'common:option.dontSave',
                    onClick: function () {
                        this.dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(waitForEditResetEffect));
                        this.dispatch(EditModel.actionCreators.cancel());
                    }.bind(this)
                },
                    {
                        titleKey: 'common:option.save',
                        onClick: function () {
                            this.dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(waitForEditResetEffect));
                            this.dispatch(EditModel.actionCreators.save());
                        }.bind(this),
                        isDisabled: !isSaveEnabled
                    }]
            }));

        } else {
            yield put(SchedulerModel.actionCreators.yieldEffectDescriptor(waitForEditResetEffect));
            yield put(EditModel.actionCreators.cancel());
        }
    }
}

export default EditModel;