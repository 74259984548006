import DetailsModel, {DetailsApi, DetailsSaga} from "../generics/DetailsModel";
import ComponentStateModel from "../generics/ComponentStateModel";
import EngineModel from "./EngineModel";
import LicenceSourceModel from "./LicenceSourceModel";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import {all, put, select, take} from "redux-saga/effects";
import {
    capitalize,
    getValues,
    isNotEmptyNorFalsy,
    parseTimeStampedLog,
    postProtectedValue
} from "../../utilities/helperFunctions";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import {
    azureVmSources,
    details,
    PASSWORD_PLACEHOLDER,
    resourcePoolTypes,
    routes,
    setAwsRegions,
    setAzureRegions,
    setAzureVmSizes
} from "../../utilities/constants";
import {awsIdTypeKeys, settingsDisplayKeys} from "../../i18next/keys";
import {contextCall, contextSpawn} from "../../saga/sagaFunctions";
import {asyncSeparateItems} from "../../components/common/ListContainer/helpers";
import SchedulerModel from "../scheduler/SchedulerModel";

class ResourcePoolModel extends DetailsModel {

    static nom = 'ResourcePoolModel';
    static actions = ResourcePoolModel.buildActions('RESOURCE_POOL');
    static actionCreators = ResourcePoolModel.buildActionCreators(ResourcePoolModel.actions);
    static reducer = ResourcePoolModel.buildReducer(ResourcePoolModel.actions);

    static componentActionCreators = {
        ...ResourcePoolModel.buildComponentUpdateActionCreators(),
        ...ResourcePoolModel.buildComponentSetActiveActionCreators()
    };

    constructor(model = {}) {
        super(model);
        const {type, active, engineIds, remoteEngineIds, cloudSettings, log, status, workerBrokerIp, workerBrokerPort, referenceId} = model || {};

        this.type = type;
        this.active = active;
        this.engineIds = engineIds;
        this.remoteEngineIds = remoteEngineIds;
        this.cloudSettings = new ResourcePoolModel.CloudSettings(cloudSettings);
        this.log = parseTimeStampedLog(log);
        this.workerBrokerIp = workerBrokerIp;
        this.workerBrokerPort = workerBrokerPort;
        this.referenceId = referenceId;
    }

    static CloudSettings = class CloudSettings {
        constructor(model = {}) {
            const {nuixLicenceSourceId, targetNuixWorkers, minNuixWorkers, whitelistedCertFingerprints, instanceIdleAction, forceIdleActionBetweenJobs,
                awsSettings, azureSettings, useRemoteEngines, remoteNuixLicenceSourceId, remoteTargetNuixWorkers, remoteMinNuixWorkers, remoteInstanceIdleAction,
                remoteNoIdleBeforeFirstJob, remoteNoIdleDuringJob, remoteAwsSettings, remoteAzureSettings
            } = model || {};

            this.nuixLicenceSourceId = nuixLicenceSourceId;
            this.targetNuixWorkers = targetNuixWorkers;
            this.minNuixWorkers = minNuixWorkers;
            this.whitelistedCertFingerprints = whitelistedCertFingerprints;
            this.instanceIdleAction = instanceIdleAction;
            this.forceIdleActionBetweenJobs = forceIdleActionBetweenJobs;
            this.awsSettings = new ResourcePoolModel.AwsSettings(awsSettings);
            this.azureSettings = new ResourcePoolModel.AzureSettings(azureSettings);

            this.useRemoteEngines = useRemoteEngines;
            this.remoteNuixLicenceSourceId = remoteNuixLicenceSourceId;
            this.remoteTargetNuixWorkers = remoteTargetNuixWorkers;
            this.remoteMinNuixWorkers = remoteMinNuixWorkers;
            this.remoteInstanceIdleAction = remoteInstanceIdleAction;
            this.remoteNoIdleBeforeFirstJob = remoteNoIdleBeforeFirstJob;
            this.remoteNoIdleDuringJob = remoteNoIdleDuringJob;
            this.remoteAwsSettings = new ResourcePoolModel.RemoteAwsSettings(remoteAwsSettings);
            this.remoteAzureSettings = new ResourcePoolModel.RemoteAzureSettings(remoteAzureSettings);
        }
    }

    static AwsSettings = class AwsSettings {
        constructor(model = {}) {
            const {accessKey, secretKey, region, instanceIds, tagName, tagValue, launchTemplateId, maxConcurrentInstances} = model || {};

            this.accessKey = accessKey;
            this.secretKey = secretKey;
            this.region = region;

            this.instanceIds = instanceIds;
            this.tagName = tagName;
            this.tagValue = tagValue;
            this.launchTemplateId = launchTemplateId;
            this.maxConcurrentInstances = maxConcurrentInstances;
        }
    }

    static RemoteAwsSettings = class AwsSettings {
        constructor(model = {}) {
            const {instanceIds, tagName, tagValue, launchTemplateId, maxConcurrentInstances} = model || {};

            this.remoteInstanceIds = instanceIds;
            this.remoteTagName = tagName;
            this.remoteTagValue = tagValue;
            this.remoteLaunchTemplateId = launchTemplateId;
            this.remoteMaxConcurrentInstances = maxConcurrentInstances;
        }
    }

    static AzureSettings = class AzureSettings {
        constructor(model = {}) {
            const {environment, tenant, key, appId, subscriptionId, vmNames, region, resourceGroupId, networkName, networkSubnetName, networkSecurityGroup,
                customVmImageId, customVmUsername, customVmPassword,tagName, tagValue, maxConcurrentInstances, vmType, vmSize, diskSize} = model || {};


            if (isNotEmptyNorFalsy(vmNames)) {
                this.azureVmSource = azureVmSources.vmNames;
            } else if (!!tagName){
                this.azureVmSource = azureVmSources.tags;
            } else {
                this.azureVmSource = azureVmSources.customVmImage;
            }

            this.azureEnvironment = environment;
            this.tenant = tenant;
            this.key = key;
            this.appId = appId;
            this.subscriptionId = subscriptionId;

            this.vmNames = vmNames || [];
            this.region = region;
            this.resourceGroupId = resourceGroupId || '';
            this.networkName = networkName || '';
            this.networkSubnetName = networkSubnetName || '';
            this.networkSecurityGroup = networkSecurityGroup || '';
            this.customVmImageId = customVmImageId || '';
            this.customVmUsername = customVmUsername || '';
            this.customVmPassword = customVmPassword || '';
            this.tagName = tagName || '';
            this.tagValue = tagValue || '';
            this.maxConcurrentInstances = maxConcurrentInstances || '';
            this.vmType = vmType;
            this.vmSize = vmSize;
            this.diskSize = diskSize || '';
        }
    }

    static RemoteAzureSettings = class RemoteAzureSettings {
        constructor(model={}) {
            const {vmNames, region, resourceGroupId, networkName, networkSubnetName, networkSecurityGroup,
                customVmImageId, customVmUsername,customVmPassword, maxConcurrentInstances, vmType, vmSize, diskSize, tagName, tagValue} = model || {};

            if (isNotEmptyNorFalsy(vmNames)) {
                this.remoteAzureVmSource = azureVmSources.vmNames;
            } else if (!!tagName){
                this.remoteAzureVmSource = azureVmSources.tags;
            } else {
                this.remoteAzureVmSource = azureVmSources.customVmImage;
            }

            this.remoteVmNames = vmNames || [];
            this.remoteRegion = region;
            this.remoteResourceGroupId = resourceGroupId || '';
            this.remoteNetworkName = networkName || '';
            this.remoteNetworkSubnetName = networkSubnetName || '';
            this.remoteNetworkSecurityGroup = networkSecurityGroup || '';
            this.remoteCustomVmImageId = customVmImageId || '';
            this.remoteCustomVmUsername = customVmUsername || '';
            this.remoteCustomVmPassword = customVmPassword || '';
            this.remoteTagName = tagName || '';
            this.remoteTagValue = tagValue || '';
            this.remoteMaxConcurrentInstances = maxConcurrentInstances || '';
            this.remoteVmType = vmType;
            this.remoteVmSize = vmSize;
            this.remoteDiskSize = diskSize || '';
        }
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'resourcePoolDisplay',
                type: 'Display',
                state: {
                    resourcePoolId: null,
                    isLocalResourcePoolFormActive: false,
                    isAwsResourcePoolFormActive: false,
                    isAzureResourcePoolFormActive: false
                }
            },
            {
                key: 'resourcePoolTablet',
                type: 'Tablet',
                state: {}
            },
            {
                key: 'resourcePoolForm',
                type: 'Form',
                state: {
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'RESOURCE_POOL_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }
}

export class ResourcePoolApi extends DetailsApi {

    static location = '/resources';
    static type = '/resourcePools';

    static getAwsRegions() {
        return axiosInstance.get('/scheduler/util/awsRegions');
    }

    static getAzureLocations() {
        return axiosInstance.get('/scheduler/util/azureLocations');
    }

    static getAzureVmSizes() {
        return axiosInstance.get('/scheduler/util/azureVmSizes');
    }
}

export class ResourcePoolSaga extends DetailsSaga {

    static ModelType = ResourcePoolModel;
    static ModelApi = ResourcePoolApi;

    static activationComponent = 'RESOURCE_POOL_DISPLAY';
    static variableNames = {
        detailsMap: 'resourcePoolDetailsMap',
        instanceId: 'resourcePoolId',
        updateDisplay: 'updateDisplay',
        updatePane: 'updateTablet',
        route: routes.SETTINGS
    };

    static translations = {
        itemTitle: '$t(resourcePool:label.name)',
        itemLower: '$t(resourcePool:label.name_lower)'
    };

    static buildActivationEffects(dispatch) {
        return [
            ...super.buildActivationEffects(dispatch),
            // ACTIVATION EFFECTS
            put(ResourcePoolModel.actionCreators.startPollingDetails()),
            put(EngineModel.actionCreators.startPollingDetails()),
            put(LicenceSourceModel.actionCreators.startPollingDetails()),
            contextSpawn(ResourcePoolSaga, 'populateAwsRegions'),
            contextSpawn(ResourcePoolSaga, 'populateAzureLocations'),
            contextSpawn(ResourcePoolSaga, 'populateAzureVmSizes')
        ]
    }

    static buildDeactivationEffects() {
        return [
            ...super.buildDeactivationEffects(),
            // DEACTIVATION EFFECTS
            put(ResourcePoolModel.actionCreators.stopPollingDetails()),
            put(EngineModel.actionCreators.stopPollingDetails()),
            put(LicenceSourceModel.actionCreators.stopPollingDetails())
        ]
    }

    static* setInstanceId(args) {
        const {updateDisplay, instanceId} = this.variableNames;

        yield all ([
            put(SchedulerModel.actionCreators.setSettingsDisplay(settingsDisplayKeys.RESOURCE_POOLS)),
            put(this.ModelType.componentActionCreators[updateDisplay]({[instanceId]: args.id}))
        ]);
    }

    static getIsFormActive(args) {
        const type = capitalize(args.type);
        return `is${type}ResourcePoolFormActive`;
    }

    static getModelName(args) {
        return `${args.type.toLowerCase()}ResourcePoolName`;
    }

    static* getDuplicateValues(editValues) {
        const {resourcePoolId} =  yield select(state => state.componentStates.resourcePoolDisplay)

        switch (editValues.type) {
            case resourcePoolTypes.LOCAL:
                editValues.isAddEnabled = true;
                break;

            case resourcePoolTypes.AWS:
                editValues.referenceValue = editValues.accessKey;
                break;

            case resourcePoolTypes.AZURE:
                editValues.referenceValue = editValues.tenant;
                break;
        }
        editValues.referenceId = resourcePoolId;
        return editValues;
    }

    static* toggleEnabled(action) {
        const {id} = action.payload;
        const {active} = yield select(state => state.resourcePoolDetailsMap.get(id));

        const {data} = yield contextCall(ResourcePoolApi, 'putDetails', id, {active: !active});
        yield put(ResourcePoolModel.actionCreators.updateDetails({[id]: {active: data.active}}));
    }

    static* getEditValues(id) {
        const details = yield select(state => state.resourcePoolDetailsMap.get(id));

        switch (details.type) {
            case resourcePoolTypes.LOCAL:
                return yield contextCall(this, 'buildLocalPoolEditModel', details);
            case resourcePoolTypes.AWS:
                return this.buildAwsPoolEditModel(details);
            case resourcePoolTypes.AZURE:
                return this.buildAzurePoolEditModel(details);
            default:
                throw new Error("Type not implemented");
        }
    }

    static getSaveValues(values) {
        switch (values.type) {
            case resourcePoolTypes.LOCAL:
                return this.buildLocalPoolSaveModel(values);
            case resourcePoolTypes.AWS:
                return this.buildAwsPoolSaveModel(values);
            case resourcePoolTypes.AZURE:
                return this.buildAzurePoolSaveModel(values);
            default:
                throw new Error("Type not implemented");
        }
    }

    static* buildLocalPoolEditModel(values) {
        const {name: localResourcePoolName, description, type, engineIds, remoteEngineIds} = values;

        const hasLoaded = yield select(state => state.hasLoaded[details.ENGINES]);
        if (!hasLoaded) {
            yield take(EngineModel.actions.SET_DETAILS_MAP);
        }

        const engineNameValues = yield select(state => getValues(state.engineDetailsMap)
            .filter(engine => engine.tags.eligibleForLocalPool)
            .map(engine => ({name: engine.name, value: engine.id})));

        const engines = yield asyncSeparateItems(engineIds, engineNameValues);
        const remoteEngines = yield asyncSeparateItems(remoteEngineIds, engineNameValues);

        return {
            localResourcePoolName,
            description,
            type,
            engines,
            remoteEngines
        };
    }

    static buildLocalPoolSaveModel(values) {
        const {localResourcePoolName: name, description, active, engines, remoteEngines} = values;

        const engineIds = engines.right.map(({value}) => value);
        const remoteEngineIds = remoteEngines.right.map(({value}) => value);

        return {
            type: resourcePoolTypes.LOCAL,
            name,
            description,
            active,
            engineIds,
            remoteEngineIds
        }
    }

    static buildAwsPoolEditModel(details) {
        const {name: awsResourcePoolName, description, type, cloudSettings: {awsSettings, remoteAwsSettings, azureSettings, ...otherCloudSettings}} = details;

        return {
            awsResourcePoolName,
            description,
            type,
            awsIdType: !!awsSettings.launchTemplateId ? awsIdTypeKeys.LAUNCH_TEMPLATE_ID : !!awsSettings.tagName ? awsIdTypeKeys.TAGS : awsIdTypeKeys.INSTANCE_IDS,
            ...awsSettings,
            remoteAwsIdType: !!remoteAwsSettings.remoteLaunchTemplateId ? awsIdTypeKeys.LAUNCH_TEMPLATE_ID : !!remoteAwsSettings.remoteTagName ? awsIdTypeKeys.TAGS : awsIdTypeKeys.INSTANCE_IDS,
            ...remoteAwsSettings,
            ...otherCloudSettings,
            secretKey: PASSWORD_PLACEHOLDER
        }

    }

    static buildAwsPoolSaveModel(values) {
        const {
            awsResourcePoolName: name, description, active, accessKey, region, nuixLicenceSourceId, targetNuixWorkers, minNuixWorkers,
            whitelistedCertFingerprints, awsIdType, launchTemplateId, maxConcurrentInstances, instanceIds, tagName, tagValue, instanceIdleAction, forceIdleActionBetweenJobs,
            remoteNuixLicenceSourceId, remoteTargetNuixWorkers, remoteMinNuixWorkers, remoteAwsIdType, remoteLaunchTemplateId, remoteMaxConcurrentInstances,
            remoteInstanceIds, remoteTagName, remoteTagValue, remoteInstanceIdleAction, useRemoteEngines, remoteNoIdleBeforeFirstJob, remoteNoIdleDuringJob,
            referenceId
        } = values;

        // Get password from DOM (assume only 1 password input)
        const secretKey = document.getElementsByName('secretKey')[0].value;

        const awsSettings = {
            accessKey,
            region,
            // Only send secretKey if it was updated
            secretKey: postProtectedValue(secretKey),

            ...(
                awsIdType === awsIdTypeKeys.LAUNCH_TEMPLATE_ID ? {
                    instanceIds: [],
                    tagName :'',
                    tagValue :'',
                    launchTemplateId,
                    maxConcurrentInstances
                } : awsIdType === awsIdTypeKeys.TAGS ?
                    {
                        instanceIds: [],
                        launchTemplateId: '',
                        tagName,
                        tagValue
                    } : {
                        instanceIds,
                        launchTemplateId: '',
                        tagName :'',
                        tagValue :''
                    }
            )
        };

        const remoteAwsSettings = {
            ...(remoteAwsIdType === awsIdTypeKeys.LAUNCH_TEMPLATE_ID ? {
                    instanceIds: [],
                    launchTemplateId: remoteLaunchTemplateId,
                    maxConcurrentInstances: remoteMaxConcurrentInstances
                } : remoteAwsIdType === awsIdTypeKeys.TAGS ?
                    {
                        instanceIds: [],
                        launchTemplateId: '',
                        tagName: remoteTagName,
                        tagValue: remoteTagValue
                    } : {
                        instanceIds : remoteInstanceIds,
                        launchTemplateId: '',
                        tagName :'',
                        tagValue :''
                    }
            )
        };


        const cloudSettings = {
            awsSettings,
            remoteAwsSettings,
            nuixLicenceSourceId,
            targetNuixWorkers,
            minNuixWorkers,
            whitelistedCertFingerprints,
            instanceIdleAction: instanceIdleAction.toUpperCase(),
            forceIdleActionBetweenJobs,

            remoteNuixLicenceSourceId,
            remoteTargetNuixWorkers,
            remoteMinNuixWorkers,
            remoteInstanceIdleAction: remoteInstanceIdleAction && remoteInstanceIdleAction.toUpperCase(),
            useRemoteEngines,
            remoteNoIdleBeforeFirstJob,
            remoteNoIdleDuringJob

        };

        return {
            type: resourcePoolTypes.AWS,
            name,
            description,
            active,
            cloudSettings,
            referenceId
        }
    }

    static buildAzurePoolEditModel(details) {
        const {name: azureResourcePoolName, description, type, cloudSettings: {awsSettings, azureSettings: {key, ...otherAzureSettings}, remoteAzureSettings, ...otherCloudSettings}} = details;

        return {
            azureResourcePoolName,
            description,
            type,
            ...otherAzureSettings,
            ...remoteAzureSettings,
            ...otherCloudSettings,
            azureKey: PASSWORD_PLACEHOLDER,
            customVmPassword: (otherAzureSettings.azureVmSource === azureVmSources.customVmImage && !!otherAzureSettings.customVmUsername) ? PASSWORD_PLACEHOLDER : '',
            remoteCustomVmPassword: (remoteAzureSettings.remoteAzureVmSource === azureVmSources.customVmImage && !!remoteAzureSettings.remoteCustomVmUsername) ? PASSWORD_PLACEHOLDER : ''
        }
    }

    static buildAzurePoolSaveModel(values) {
        const {azureResourcePoolName: name, description, active,  azureEnvironment, tenant, appId, subscriptionId, referenceId,

            azureVmSource, region, resourceGroupId, networkName, networkSubnetName, networkSecurityGroup, customVmImageId, customVmUsername, maxConcurrentInstances, vmType, vmSize, diskSize, nuixLicenceSourceId,
            targetNuixWorkers, minNuixWorkers, vmNames, instanceIdleAction, forceIdleActionBetweenJobs, tagName, tagValue,

            useRemoteEngines, remoteAzureVmSource, remoteRegion, remoteResourceGroupId, remoteNetworkName, remoteNetworkSubnetName, remoteNetworkSecurityGroup, remoteCustomVmImageId, remoteCustomVmUsername, remoteMaxConcurrentInstances, remoteVmType,
            remoteVmSize, remoteDiskSize, remoteNuixLicenceSourceId, remoteTargetNuixWorkers, remoteMinNuixWorkers, remoteVmNames, remoteInstanceIdleAction, remoteNoIdleBeforeFirstJob, remoteNoIdleDuringJob,
            remoteTagName, remoteTagValue,
            whitelistedCertFingerprints
        } = values;

        // Get password from DOM (assume only 1 password input)
        const azureKey = document.getElementsByName('azureKey')[0].value;
        const azureSettings = {
            environment: azureEnvironment,
            tenant,
            appId,
            subscriptionId,
            key: postProtectedValue(azureKey)
        };

        if (azureVmSource === azureVmSources.vmNames) {
            azureSettings.vmNames = vmNames;

        } else if (azureVmSource === azureVmSources.tags) {
            azureSettings.tagName = tagName;
            azureSettings.tagValue = tagValue;

        } else if (azureVmSource === azureVmSources.customVmImage) {
            azureSettings.region = region;
            azureSettings.resourceGroupId = resourceGroupId;
            azureSettings.networkName = networkName;
            azureSettings.networkSubnetName = networkSubnetName;
            azureSettings.networkSecurityGroup = networkSecurityGroup;
            azureSettings.customVmImageId = customVmImageId;
            azureSettings.customVmUsername=customVmUsername;
            azureSettings.customVmPassword = postProtectedValue(document.getElementsByName('customVmPassword')[0].value);
            azureSettings.maxConcurrentInstances = maxConcurrentInstances;
            azureSettings.vmType = vmType;
            azureSettings.vmSize = vmSize;
            azureSettings.diskSize = diskSize;
        }

        const remoteAzureSettings = {};

        if (useRemoteEngines) {
            if (remoteAzureVmSource === azureVmSources.vmNames) {
                remoteAzureSettings.vmNames = remoteVmNames;

            } else if (remoteAzureVmSource === azureVmSources.tags) {
                remoteAzureSettings.tagName = remoteTagName;
                remoteAzureSettings.tagValue = remoteTagValue;

            } else if (remoteAzureVmSource === azureVmSources.customVmImage) {
                remoteAzureSettings.region = remoteRegion;
                remoteAzureSettings.resourceGroupId = remoteResourceGroupId;
                remoteAzureSettings.networkName = remoteNetworkName;
                remoteAzureSettings.networkSubnetName = remoteNetworkSubnetName;
                remoteAzureSettings.networkSecurityGroup = remoteNetworkSecurityGroup;
                remoteAzureSettings.customVmImageId = remoteCustomVmImageId;
                remoteAzureSettings.customVmUsername = remoteCustomVmUsername;
                remoteAzureSettings.customVmPassword = postProtectedValue(document.getElementsByName('remoteCustomVmPassword')[0].value);
                remoteAzureSettings.maxConcurrentInstances = remoteMaxConcurrentInstances;
                remoteAzureSettings.vmType = remoteVmType;
                remoteAzureSettings.vmSize = remoteVmSize;
                remoteAzureSettings.diskSize = remoteDiskSize;
            }
        }

        const cloudSettings = {
            azureSettings,
            nuixLicenceSourceId,
            targetNuixWorkers,
            minNuixWorkers,
            instanceIdleAction: instanceIdleAction && instanceIdleAction.toUpperCase(),
            forceIdleActionBetweenJobs,

            useRemoteEngines,
            remoteAzureSettings,
            remoteNuixLicenceSourceId,
            remoteTargetNuixWorkers,
            remoteMinNuixWorkers,
            remoteInstanceIdleAction: remoteInstanceIdleAction && remoteInstanceIdleAction.toUpperCase(),
            remoteNoIdleBeforeFirstJob,
            remoteNoIdleDuringJob,

            whitelistedCertFingerprints
        };

        return {
            type: resourcePoolTypes.AZURE,
            name,
            description,
            active,
            cloudSettings,
            referenceId
        }
    }

    static* queryDetails() {
        const response = yield contextCall(ResourcePoolApi, 'getDetails');
        const key = details.RESOURCE_POOLS;

        if (AxiosProxy.shouldUpdate(key, response)) {
            const resourcePools = getValues(response.data);
            yield all([
                put(ResourcePoolModel.actionCreators.setDetailsMap(resourcePools)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }

    static* populateAwsRegions() {
        const {data} = yield contextCall(ResourcePoolApi, 'getAwsRegions');
        setAwsRegions(data);
    }

    static* populateAzureLocations() {
        const {data} = yield contextCall(ResourcePoolApi, 'getAzureLocations');
        setAzureRegions(data);
    }

    static* populateAzureVmSizes() {
        const {data} = yield contextCall(ResourcePoolApi, 'getAzureVmSizes');
        setAzureVmSizes(data);
    }
}

export default ResourcePoolModel;