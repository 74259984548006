import React from 'react';
import './EditPanel.css';
import {useTranslation} from "react-i18next";
import {ButtonGroup} from "../Button/Button";
import {buildClassName} from "../../../utilities/helperFunctions";

function EditPanel(props) {
    const {children, isActive, options, isTight, flexExpand, flexExpandChildren=[], className, ...attr} = props;
    if (!children)
        return null;

    let components;
    if (children.constructor === Array) {
        components = children
    } else {
        components = [children];
    }

    const _className = buildClassName(
        'edit-panel',
        isActive && 'is-active',
        flexExpand && 'flex-expand',
        className
    );

    const _contentClassName = buildClassName(
        'editable-content',
        isTight && 'is-tight'
    );

    return (
        <div className={_className} {...attr}>
            {components.map((child, index) => child &&
                <div key={index} className={_contentClassName + (flexExpandChildren[index] ? ' flex-expand' : '')}>
                    {child}
                </div>
            )}
            {isActive && options}
        </div>    
    );
}

export function DefaultEditPanel(props) {
    const {isSaveEnabled, onCancel, onSave, isDisabled, ...attr} = props;
    const {t} = useTranslation(['common']);

    return (
        <EditPanel {...attr}
            options={
                <div style={{marginTop: 'auto', paddingTop: '0.75rem'}}>
                    <ButtonGroup
                        buttons={[
                            {
                                label: t('common:option.cancel'),
                                onClick: onCancel,
                                isDisabled
                            },
                            {
                                label: t('common:option.save'),
                                onClick: onSave,
                                isDisabled: !isSaveEnabled || isDisabled
                            }
                        ]}
                    />
                </div>
            }
        />
    )
}

export default EditPanel;