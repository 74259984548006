import React, {Component, useRef, useState} from 'react';
import './InputTable.css';
import {
    deepCopy,
    filterArrayIndices,
    getEntries,
    getNewArrayWithUpdatedValue,
    getProtectedValue,
    isNotEmptyNorFalsy,
    isSomeTruthy,
    objectTruthyValues
} from "../../../utilities/helperFunctions";
import {buildIndices} from "./helpers";
import {AddRemoveButtons} from "../Button/Button";
import HTMLTextInput from "../HTMLTextInput/HTMLTextInput";
import {
    getRenderedHeightOffset,
    initialSelectedState,
    useClearSelectedEffect,
    useRenderedItemHeight,
    useSimpleVirtualRendering,
    useTabNavigateEffect,
    useValueSelectHandler
} from "../../../utilities/hooks";
import Text from "../Text/Text";
import ParameterModel from "../../../models/library/ParameterModel";
import {getInvalidMessageSpan} from "../InvalidMessageSpan";

// Table => [[{value: '', ...attr}, ...], ...]
export function InputTableV2(props) {

    const containerRef = useRef();
    const [selected, setSelected] = useState(initialSelectedState);

    const valueSelectHandler = useValueSelectHandler({setSelected});
    useClearSelectedEffect({containerRef, setSelected});
    useTabNavigateEffect({containerRef});

    const {
        id,
        label,
        HeaderComponent=AddRemoveButtons,
        headers=[],
        inputTable=[],
        setInputTable,

        inputColumnProps=[],
        inputNormalizer,
        defaultRow=[],
        blurHandler,
        invalidMessage,
        hideInvalidMessage,
        requiredMessage,

        ariaLabelKey,
        columnAriaLabels,

        isRequired,
        isReadOnly,
        isInputDisabled,
        isRowDisabled,
        isDisabled,
        ...attr
    } = props;

    const listContainerSelector = 'tbody';
    const itemHeightRef = useRenderedItemHeight(containerRef, listContainerSelector, 34.8906);
    const heightOffset = getRenderedHeightOffset(containerRef, listContainerSelector, 32.3906 + itemHeightRef.current);
    const virtualRenderRows = useSimpleVirtualRendering({
        containerRef,
        itemHeightRef,
        size: inputTable.length,
        heightOffset
    });

    function addRow() {
        setInputTable(prevInputTable => {
            return [
                ...prevInputTable,
                deepCopy(defaultRow)
            ]
        });
    }

    function removeSelectedRows() {
        setInputTable(prevInputTable => {
            const selectedRowIndices = objectTruthyValues(selected.values);
            return filterArrayIndices(prevInputTable, selectedRowIndices);
        });

        setSelected(prevSelected => ({
            ...prevSelected,
            values: {},
            lastSelectedValue: null
        }));
    }

    function inputHandler(event) {
        const {value, dataset: {row, col}} = event.target;

        setInputTable(prevInputTable => {
            const newCell = {
                ...prevInputTable[row][col],
                value
            };

            const newRow = getNewArrayWithUpdatedValue(prevInputTable[row], newCell, col);
            const updatedTable = getNewArrayWithUpdatedValue(prevInputTable, newRow, row);

            if (typeof inputNormalizer === "function") {
                inputNormalizer(updatedTable, prevInputTable, row, col);
            }

            return updatedTable;
        });
    }

    function inputBlurHandler(event) {
        const {value, dataset: {row, col}} = event.target;
        if (blurHandler == null || blurHandler[col] == null) {
            return;
        }

        inputHandler({
            target: {
                value: blurHandler[col](value),
                dataset: {row, col}
            }
        });
    }

    const labelId = label && `${id}:label`;
    const required = !isDisabled && isRequired && (!Array.isArray(inputTable) || inputTable.length === 0);

    const {
        combinedAriaLabelledBy,
        invalidMessageSpan
    } = getInvalidMessageSpan({
        id,
        ariaLabelledBy: [labelId],
        isRequired: required,
        invalidMessage,
        requiredMessage
    });


    const canRemove = objectTruthyValues(selected.values).length > 0;
    const readOnly = isSomeTruthy(isReadOnly);
    const inputDisabled = isSomeTruthy(isInputDisabled);

    const disableInteractions = (readOnly || inputDisabled);

    return (
        <div id={id} ref={containerRef} aria-labelledby={combinedAriaLabelledBy}>
            <HeaderComponent labelId={labelId} label={label} onAddClick={addRow} isReadOnly={disableInteractions} noPadding
                ariaLabelKey={ariaLabelKey} onRemoveClick={removeSelectedRows} isRemoveDisabled={!canRemove} isDisabled={isDisabled}/>

            <div style={{paddingBottom: '1px'}}>
                <table className="input-tableV2" {...attr}>
                    {Array.isArray(headers) &&
                    <thead>
                    <tr>
                        {headers.map(({value, ...attr}, index) =>
                            <td key={index} {...attr}>
                                <Text value={value} isBold isDisabled={isDisabled}/>
                            </td>
                        )}
                    </tr>
                    </thead>
                    }

                    <tbody>
                    {virtualRenderRows((rindex, topOffset) => {
                        const row = inputTable[rindex];
                        if (row == null) {
                            return null;
                        }

                        return (
                            <tr key={rindex} data-index={rindex} onClick={disableInteractions ? null : valueSelectHandler}
                                style={{position: 'relative', top: `${topOffset}px`}}>

                                {row.map(({value, ...attr}, cindex) => {
                                    const _required = isRequired === true || (typeof isRequired === 'object' && isRequired[cindex]);
                                    const _readOnly = isReadOnly === true || (typeof isReadOnly === 'object' && isReadOnly[cindex]);
                                    const _inputDisabled = isInputDisabled === true || (typeof isInputDisabled === 'object' && isInputDisabled[cindex])
                                        || (typeof isRowDisabled === 'object' && isRowDisabled[rindex]);

                                    const key = `${rindex}${cindex}`;
                                    const ariaLabel = (Array.isArray(columnAriaLabels) && columnAriaLabels[cindex])
                                        || (Array.isArray(headers) && headers[cindex]?.value);

                                    const colProps = inputColumnProps[cindex] || {};

                                    let val, onChange;
                                    // Only on colProps, to prevent changing component from controlled/uncontrolled
                                    if (colProps.type !== 'password') {
                                        val = value;
                                        onChange = inputHandler;
                                    }

                                    return (
                                        <td key={key}>
                                            <HTMLTextInput id={`${id}${key}`} value={val} onChange={onChange} onBlur={inputBlurHandler}
                                                aria-label={ariaLabel} data-row={rindex} data-col={cindex} isSelected={selected.values[rindex]}
                                                isInputDisabled={_inputDisabled} isReadOnly={_readOnly} isRequired={_required}
                                                isDisabled={isDisabled} {...colProps} {...attr}/>
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>

            {!hideInvalidMessage && invalidMessageSpan}
        </div>
    )
}

// Only works for 2 columns
export function buildMapFromInputTable(inputTable, elementId, colProps=[]) {
    return inputTable.reduce((acc, curr, index) => {
        const name = curr[0].value;

        if (name.trim().length > 0) {
            if (elementId != null && colProps[1]?.type === 'password') {
                const passElementId = `${elementId}${index}1`;
                const passElement = document.getElementById(passElementId);
                if (passElement != null) {
                    acc[name] = passElement.value;
                }
            }
            if (acc[name] == null) {
                acc[name] = curr[1].value;
            }
        }
        return acc;
    }, {});
}

// Only works for 2 columns
export function buildInputTableValuesFromMap(map) {
    return getEntries(map || [])
        .map(([key, value]) => ([{value: key}, {value}]));
}

export function buildInputTableParameterValues(parameters) {
    return getEntries(parameters || [])
        .map(([name, value]) => ([
            {value: name},
            {...(ParameterModel.isPasswordParameter(name, value) ? {value: getProtectedValue(value), type: 'password'} : {value})}
        ]));
}

class InputTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            lastAccessedIndex: null,
            selectedIndexSet: {}
        };

        this.containerRef = React.createRef();

        this.onRowFocus = this.onRowFocus.bind(this);
        this.onPasswordBeforeInput = this.onPasswordBeforeInput.bind(this);

        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.clearSelected = this.clearSelected.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.clearSelected);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.clearSelected);
    }

    onRowFocus(event) {
        const { index } = event.currentTarget.dataset;
        const keyPressed = {
            ctrl: event.ctrlKey,
            shft: event.shiftKey
        };

        this.setState(prevState => {
            const selectedIndexSet = (keyPressed.ctrl || keyPressed.shft) ? {...prevState.selectedIndexSet} : {};
            selectedIndexSet[index] = !(keyPressed.ctrl && selectedIndexSet[index]);

            if (keyPressed.shft && prevState.lastAccessedIndex) {
                const indices = index > prevState.lastAccessedIndex ? {start: prevState.lastAccessedIndex, end: index} : {start: index, end: prevState.lastAccessedIndex};
                for (let i = indices.start; i < indices.end; i++) {
                    selectedIndexSet[i] = true;
                }
            }
            return {
                lastAccessedIndex: selectedIndexSet[index] ? index : null,
                selectedIndexSet
            };
        })
    }

    clearSelected(event) {
        if (!this.containerRef.current.contains(event.target)) {
            this.setState({
                selectedIndexSet: {}
            });
        }
    }

    onDeleteClick() {
        const selectedIndices = objectTruthyValues(this.state.selectedIndexSet);

        this.props.onDeleteClick(selectedIndices);
        this.setState({
            selectedIndexSet: {}
        });
    }

    didClear = {};
    onPasswordBeforeInput(event) {
        const indices = buildIndices(event);
        const [, y] = indices;

        let name;
        const nameField = this.props.rows[y][0];
        if (nameField != null) {
            name = nameField.value;
        } else {
            name = indices.join('');
        }

        if (this.didClear[name] != null) {
            return;
        }
        // Hacking a fake event to update value using generic onValueChange
        const fakeEvent = {
            target: {
                value: '',
                dataset: {
                    index: indices[0]
                },
                parentNode: {
                    dataset: {
                        index: indices[1]
                    }
                },
            }
        };
        this.props.onValueChange(fakeEvent);
        this.didClear[name] = true;
    }

    render() {
        const {
            id,
            label,
            headers,
            rows,
            onValueChange,
            onValueBlur,
            onAddClick,
            requiredColumns,
            isRequired,
            isDisabled
        } = this.props;
        const {
            selectedIndexSet
        } = this.state;

        const required = (isRequired && rows.every(values => values.every((value, index) => requiredColumns[index] && !value))) ? ' is-required' : '';
        const selectedLength = objectTruthyValues(selectedIndexSet).length;

        return (
            <div id={id} ref={this.containerRef}>
                <AddRemoveButtons label={label} ariaLabelKey={this.props.ariaLabelKey} onAddClick={onAddClick}
                    onRemoveClick={this.onDeleteClick} isRemoveDisabled={selectedLength === 0} isDisabled={isDisabled}/>

                <div className={'inputTable-container' + required}>
                    <div className="input-table">
                        <div className="table-header-group">
                            {isNotEmptyNorFalsy(headers) && headers.map((header, index) => ParseHeader(header, index))}
                        </div>

                        <div className="table-row-group">

                            {rows.map((row, parentIndex) =>
                                <div className={'table-row' + (selectedIndexSet[parentIndex] ? ' is-selected' : '')} key={`row${parentIndex}`} data-index={parentIndex} onMouseDown={this.onRowFocus}>

                                    {row.map((val, index) => {
                                        const {value, type = 'text'} = val;
                                        const title = (headers || [])[index]?.title;

                                        return (
                                            <div className="table-cell" key={`cell${index}`}>
                                                <HTMLTextInput id={`${id}${parentIndex}${index}`} aria-label={title} type={type} value={value}
                                                    data-index={index} onBeforeInput={type === 'password' ? this.onPasswordBeforeInput : null}
                                                    onBlur={onValueBlur} onChange={onValueChange} isDisabled={isDisabled}/>
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


function ParseHeader(header, index) {
    const {title, isBold} = header;
    const bold = isBold ? ' is-bold' : '';

    return (
        <div className="table-header" key={`header${index}`}>
            <label className={'label' + bold}>
                {title}
            </label>
        </div>
    )
}

export default InputTable;