import {useTranslation} from "react-i18next";
import {
    buildFakeEvent,
    capitalizeFirstLetter,
    getTranslatedValueOrDefault,
    getValues,
    objEquals
} from "../../../utilities/helperFunctions";
import Dropdown, {CheckedDropdown} from "../../common/Dropdown/Dropdown";
import React, {useEffect, useMemo, useState} from "react";
import {NameSelectableItem} from "../../common/SelectableItem/SelectableItem";
import DropdownSelectedList from "../../common/DropdownSelectedList/DropdownSelectedList";
import {useSelector} from "react-redux";
import FormBuilderComponentConfiguration from "../../../models/workflowbuilder/FormBuilderComponentConfiguration";
import {datasetType} from "../../../utilities/constants";
import {selectPrecedingParameters} from "./ParameterField";

const useCommonDropdownConfiguration = options => {
    const {t} = useTranslation(['workflowBuilder']);
    const {
        formKey,
        configuration,
        checkedValues
    } = options;

    const items = useMemo(() => {
        return configuration.allowedValues.map(value => {
            let name = value;
            if (configuration.enumName) {
                const translationKeys = [
                    `workflowBuilder:${configuration.enumName}.${value}`,
                    `workflowBuilder:${formKey}.${configuration.enumName}.${value}`
                ];
                name = getTranslatedValueOrDefault(t, value, ...translationKeys);
            }
            return {name, value, isChecked: checkedValues != null && checkedValues.has(value)};
        });
    }, [formKey, configuration.allowedValues, configuration.enumName, checkedValues]);

    const translationKeys = [
        `workflowBuilder:${configuration.enumName}.select`,
        `workflowBuilder:option.select${configuration.enumName || capitalizeFirstLetter(configuration.name)}`,
        `workflowBuilder:${formKey}.select${configuration.enumName || capitalizeFirstLetter(configuration.name)}`,
    ];
    const noneSelectedMessage = getTranslatedValueOrDefault(t, t('workflowBuilder:option.select', {name: configuration.enumName || capitalizeFirstLetter(configuration.name)}), ...translationKeys);
    return {items, noneSelectedMessage};
}

function DropdownField(props) {
    const {
        formKey,
        configuration,
        ...rest
    } = props;

    const {items, noneSelectedMessage} = useCommonDropdownConfiguration({formKey, configuration});
    return (
        <InternalDropdownField configuration={configuration} items={items}
            noneSelectedMessage={noneSelectedMessage} {...rest}/>
    )
}

export function InternalDropdownField(props) {
    const {t} = useTranslation(['common']);
    const {
        value,
        configuration,
        expandDropdown,
        dropdownHandler,
        onClear,
        selectableItems,

        items,
        noneSelectedMessage,
        isRequired,
        isDisabled,
        ...rest
    } = props;

    let onClearClick;
    if (configuration.showClearSelectedOption) {
        onClearClick = onClear;
        if (typeof onClearClick !== 'function') {
            onClearClick = function () {
                dropdownHandler(buildFakeEvent({name: configuration.name, value: '', index: rest.index}));
            }
        }
    }

    let buttonStyle;
    if (expandDropdown) {
        buttonStyle = {maxWidth: '42rem'};
    }

    let selectedItem = null;
    if (value !== undefined) {
        selectedItem = items.find(item => item.value === value);
    }
    const selectedName = (selectedItem != null && selectedItem.name) || value || noneSelectedMessage;

    const _items = (
        <>
            {configuration.showClearSelectedOption &&
                <NameSelectableItem item={{name: t('common:option.clearSelected')}} onItemClick={onClearClick}
                    isNoWrap isCentered isBorder isDisabled={isDisabled || (!value && !configuration.enableClearSelectedOption)}
                />
            }
            {selectableItems}
            {items.map((item, index) =>
                <NameSelectableItem key={index} item={item} onItemClick={dropdownHandler}
                    isDisabled={isDisabled}/>
            )}
        </>
    )

    return (
        <Dropdown name={configuration.name} data-value={value} selectedItems={selectedName} buttonStyle={buttonStyle}
            isRequired={isRequired && !value} isDisabled={isDisabled} isBold {...rest} items={_items}/>
    )
}

const translateValueToCheckedValues = value => value.reduce((acc, val) => {
    acc.add(val);
    return acc;
}, new Set());

export function CheckedDropdownField(props) {
    const {
        value,
        formKey,
        configuration,
        updateState,
        expandDropdown,
        ...attr
    } = props;

    // Track as internal state to prevent re-computing on every render
    const [checkedValues, setCheckedValues] = useState(translateValueToCheckedValues(value));
    useEffect(() => {
        setCheckedValues(translateValueToCheckedValues(value));
    }, [value]);

    function onToggle(event) {
        const {dataset: {value}} = event.currentTarget;
        updateState(prevState => {
            const prevValue = prevState[configuration.name];
            return {
                [configuration.name]: prevValue.includes(value) ? prevValue.filter(val => val !== value) : [...prevValue, value]
            }
        });
    }

    function onClear() {
        updateState({[configuration.name]: []});
    }

    const {items, noneSelectedMessage} = useCommonDropdownConfiguration({formKey, configuration, checkedValues});
    let buttonStyle;
    if (expandDropdown) {
        buttonStyle = {maxWidth: '42rem'};
    }

    return (
        <CheckedDropdown name={configuration.name} noneSelectedMessage={noneSelectedMessage}
            items={items} onToggle={onToggle} onClear={onClear} buttonStyle={buttonStyle} {...attr}/>
    )
}

export function DropdownSelectedListField(props) {
    const {
        value,
        formKey,
        configuration,
        updateState,
        ...attr
    } = props;

    const allowedValues = useDynamicAllowedValues(configuration);

    function setValues(updates) {
        updateState(prevState => {
            return {
                [configuration.name]: updates(prevState[configuration.name])
            }
        });
    }

    return (
        <DropdownSelectedList name={configuration.name} availableItems={allowedValues}
            values={value || []} setValues={setValues} {...attr}/>
    )
}

function useDynamicAllowedValues(configuration) {
    const [allowedValues, setAllowedValues] = useState(configuration.allowedValues);

    const detailsMap = useSelector(state => {
        switch (configuration.dynamicValuesType) {
            case FormBuilderComponentConfiguration.DynamicValuesType.IN_PLACE_DATA_REPOSITORIES:
                return getValues(state.dataRepositoryDetailsMap).filter(repo => repo.type === datasetType.IN_PLACE);
            case FormBuilderComponentConfiguration.DynamicValuesType.PARAMETERS:
                return selectPrecedingParameters(state.workflowBuilder, configuration);
        }
    }, objEquals);

    useEffect(() => {
        if (detailsMap != null) {
            switch (configuration.dynamicValuesType) {
                case FormBuilderComponentConfiguration.DynamicValuesType.PARAMETERS:
                    setAllowedValues(getValues(detailsMap).map(param => ({name: param.name, value: param.name})));
                    break;
                default:
                    setAllowedValues(getValues(detailsMap).map(details => ({name: details.name, value: details.id})));
                    break;
            }
        }
    }, [detailsMap]);

    return allowedValues;
}

export default DropdownField;
