import React from "react";
import {SettingsRowValue} from "../../common/CustomTable/CustomTable";
import {useTranslation} from "react-i18next";
import ExpandableContent from "../../common/ExpandableContent/ExpandableContent";
import {getEntries, isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";

function ElasticsearchServiceSettings(props) {
    const {t} = useTranslation(['thirdPartyService']);
    const {
        thirdPartyService,
        style,
        isDisabled
    } = props;

    return (
        <ExpandableContent label={t('thirdPartyService:label.elasticsearchSettings')}
            style={style} isDisabled={isDisabled}
        >
            <div className="settings-table">
                <div className="table-row-group">
                    {isNotEmptyNorFalsy(thirdPartyService.elasticProperties) && getEntries(thirdPartyService.elasticProperties).map(([key, value]) =>
                        <SettingsRowValue key={key} label={key} showIfNull
                            value={value} isDisabled={isDisabled}/>
                    )}
                </div>
            </div>
        </ExpandableContent>
    )
}

export default ElasticsearchServiceSettings;
