import {getHandler} from "../../../../utilities/componentFunctions";
import {stringToBool} from "../../../../utilities/helperFunctions";

export function createNestedToggleHandler(options) {
    const handler = getHandler(options);

    return function(event) {
        const {name, checked, nested} = event.target;
        const updates = {
            [name]: stringToBool(checked),
            nested: nested
        };

        handler(updates);
    }
}

export function createNestedSwitchHandler(options) {
    const handler = getHandler(options);

    return function(event) {
        const {name, checked, nested} = event.currentTarget.dataset;
        const updates = {
            [name]: stringToBool(checked),
            nested: nested
        };

        handler(updates);
    }
}

export function createNestedUpdateHandler(options) {
    const handler = getHandler(options);

    return function(event) {
        const {name, value, nested} = event.update;
        const updates = {
            [name]: value,
            nested: nested
        };

        handler(updates);
    }
}