import React from "react";
import {NumberingOptions} from "../../Numbering/NumberingOptions";
import {updateGenericValues} from "../../../redux/WizardSlice/wizardSlice";
import {connect} from "react-redux";
import {createValueHandler} from "../../../../utilities/componentFunctions";
import {useTranslation} from "react-i18next";
import QuestionRadio from "../../Question/QuestionRadio/QuestionRadio";

function CustomExportNumbering (props) {
    const {updateState} = props;
    const {t} = useTranslation(['wizard']);

    const {
        exportNumberingType,
        numberingProps
    } = props;

    const valuesHandler = createValueHandler({
        handler: updateState
    })

    const questions = [
        {
            question: t("wizard:questions.customNumbering.numberingScheme"),
            dataName: "exportNumberingType",
            options: [
                {id: 1, value: t("wizard:questions.customNumbering.documentId")},
                {id: 2, value: t("wizard:questions.customNumbering.familyDocumentId")},
                {id: 3, value: t("wizard:questions.customNumbering.filenameDocumentId")},
                {id: 4, value: t("wizard:questions.customNumbering.prefixPage")},
                {id: 5, value: t("wizard:questions.customNumbering.prefixFolderPage")},
                {id: 6, value: t("wizard:questions.customNumbering.prefixBoxFolderPage")},
            ]
        }
    ]

    return (
        <div className={"wizard-step-container"}>
            <QuestionRadio
                question={questions[0].question}
                dataName={questions[0].dataName}
                options={questions[0].options}
                selectedValue={exportNumberingType}
                onChange={valuesHandler} />


            <NumberingOptions
                exportNumberingType={exportNumberingType}
                onChange={valuesHandler}
                numberingProps={numberingProps}
                updateState={updateState}
                nested={"numberingProps"}/>
        </div>
    )
}

function mapStateToProps (state) {
    const {wizardSlice: {legalExportSettings:{exportNumberingType, numberingProps}}} = state;
    return {
        exportNumberingType,
        numberingProps
    };
}

function mapDispatchToProps (dispatch) {
    return {
        updateState: updates => dispatch(updateGenericValues({stateName: "legalExportSettings", updates: updates}))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomExportNumbering);