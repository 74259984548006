import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import Tablet, {EditTabletHeader} from '../../common/Tablet/Tablet';
import {clearOnFirstCallback, getModifyPrivilegedAction, onChange} from "../../../utilities/componentFunctions";
import {onToggleEnable} from "../../common/Checkbox/helpers";
import {awsIdTypeKeys, instanceIdleActionKeys, permissionKeys, popupInfoKeys, statusKeys} from "../../../i18next/keys";
import {DefaultEditPanel} from "../../common/EditPanel/EditPanel";
import ExpandableContent, {
    ExpandableEditTextArea,
    ExpandableInputList,
    ExpandableTimeStampedLog
} from "../../common/ExpandableContent/ExpandableContent";
import {
    boolToString,
    compareNumStrings,
    getDataNameFromParentNode,
    isNotEmptyNorFalsy
} from "../../../utilities/helperFunctions";
import {SettingsRowValue} from "../../common/CustomTable/CustomTable";
import {
    AwsIdTypeDropdown,
    AwsRegionDropdown,
    InstanceIdleActionDropdown,
    NuixLicenceSourceDropdown,
} from "../../common/Dropdown/Dropdown";
import Checkbox from "../../common/Checkbox/Checkbox";
import InputList from "../../common/InputList/InputList";
import {onDropdownItemClick, onMenuOptionClick} from "../../common/Dropdown/helpers";
import {onInputListAddInput, onInputListDeleteInput, onInputListInputChange} from "../../common/InputList/helpers";
import {shouldEnableAwsResourcePool} from "../../../utilities/shouldEnableFunctions";
import {updateMode} from "../../../utilities/constants";
import PopupModel from "../../../models/scheduler/PopupModel";
import ResourcePoolModel from "../../../models/settings/ResourcePoolModel";
import EditModel from "../../../models/scheduler/EditModel";
import {makeGetEditDetails} from "../../../reselect/selectors";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import {ExpandableStatusLog} from "../../common/Common";
import Switch from "../../common/Switch/Switch";
import LimitedTablet from "../../limited/LimitedTablet";

class AwsResourcePoolTablet extends Component {

    constructor(props) {
        super(props);

        this.state = {
            expanded: {
                description: true,
                settings: true,
                awsIdList: true,
                whitelistedCertFingerprints: true,
                log: true
            }
        };

        this.passwordRef = React.createRef();

        this.onInputChange = this.onInputChange.bind(this);
        this.editResourcePool = this.editResourcePool.bind(this);
        this.toggleResourcePoolActive = this.toggleResourcePoolActive.bind(this);
        this.deleteResourcePool = this.deleteResourcePool.bind(this);
        this.duplicate = this.duplicate.bind(this);

        this.onInstanceIdleActionDropdownClick = this.onInstanceIdleActionDropdownClick.bind(this);
        this.onRemoteInstanceIdleActionDropdownClick = this.onRemoteInstanceIdleActionDropdownClick.bind(this);

        this.clearOnFirstCallback = clearOnFirstCallback.bind(this);
        this.getModifyPrivilegedAction = getModifyPrivilegedAction('resourcePool', this.props.t('resourcePool:label.name')).bind(this);
        this.onMenuOptionClick = onMenuOptionClick.bind(this);

        this.onEditDropdownItemClick = onDropdownItemClick({
            mode: updateMode.EDIT,
            shouldEnable: shouldEnableAwsResourcePool,
            passwordRef: this.passwordRef,
            passwordName: 'secretKey'
        }).bind(this);
        this.onEditToggleEnable = onToggleEnable({
            mode: updateMode.EDIT,
            shouldEnable: shouldEnableAwsResourcePool,
            passwordRef: this.passwordRef,
            passwordName: 'secretKey'
        }).bind(this);

        this.onEditAddAwsInstanceId = onInputListAddInput({
            mode: updateMode.EDIT,
            listName: 'instanceIds'
        }).bind(this);
        this.onEditDeleteAwsInstanceId = onInputListDeleteInput({
            mode: updateMode.EDIT,
            listName: 'instanceIds',
            shouldEnable: shouldEnableAwsResourcePool,
            passwordRef: this.passwordRef,
            passwordName: 'secretKey'
        }).bind(this);
        this.onEditAwsInstanceIdChange = onInputListInputChange({
            mode: updateMode.EDIT,
            listName: 'instanceIds',
            shouldEnable: shouldEnableAwsResourcePool,
            passwordRef: this.passwordRef,
            passwordName: 'secretKey'
        }).bind(this);

        this.onAddRemoteAwsInstanceId = onInputListAddInput({
            mode: updateMode.EDIT,
            listName: 'remoteInstanceIds'
        }).bind(this);
        this.onDeleteRemoteAwsInstanceId = onInputListDeleteInput({
            mode: updateMode.EDIT,
            listName: 'remoteInstanceIds',
            shouldEnable: shouldEnableAwsResourcePool,
            passwordRef: this.passwordRef,
            passwordName: 'secretKey'
        }).bind(this);
        this.onRemoteAwsInstanceIdChange = onInputListInputChange({
            mode: updateMode.EDIT,
            listName: 'remoteInstanceIds',
            shouldEnable: shouldEnableAwsResourcePool,
            passwordRef: this.passwordRef,
            passwordName: 'secretKey'
        }).bind(this);


        this.onEditAddWhitelistCertFingerprint = onInputListAddInput({
            mode: updateMode.EDIT,
            listName: 'whitelistedCertFingerprints'
        }).bind(this);
        this.onEditDeleteWhitelistCertFingerprint = onInputListDeleteInput({
            mode: updateMode.EDIT,
            listName: 'whitelistedCertFingerprints',
            shouldEnable: shouldEnableAwsResourcePool,
            passwordRef: this.passwordRef,
            passwordName: 'secretKey'
        }).bind(this);
        this.onEditWhitelistCertFingerprintChange = onInputListInputChange({
            mode: updateMode.EDIT,
            listName: 'whitelistedCertFingerprints',
            shouldEnable: shouldEnableAwsResourcePool,
            passwordRef: this.passwordRef,
            passwordName: 'secretKey'
        }).bind(this);
    }

    onInputChange(event) {
        const {name, value, type} = event.target;

        const updates = {};
        // Using same inputHandler for password field for isAddEnabled logic
        // Do not track password value
        if (type !== 'password')
            updates[name] = value;

        // Clear password when user changes value for below
        if (['accessKey'].includes(name)) {
            this.passwordRef.current.value = '';
        }

        const isSaveEnabled = shouldEnableAwsResourcePool({
            ...this.props,
            ...updates,
            secretKey: this.passwordRef.current.value
        });

        this.props.setEditSaveEnabled(isSaveEnabled);
        this.props.updateEdit(updates);
    }

    editResourcePool() {
        this.props.editResourcePool(this.props.resourcePoolId);
    }

    toggleResourcePoolActive() {
        this.props.toggleResourcePoolActive(this.props.resourcePoolId);
    }

    deleteResourcePool() {
        this.props.promptDeleteResourcePool(this.props.resourcePoolId);
    }

    duplicate() {
        this.props.duplicate(this.props.resourcePoolId);
    }

    onInstanceIdleActionDropdownClick(event) {
        const {dataset: {value}, parentNode} = event.currentTarget;
        if (this.props.instanceIdleAction === value)
            return;

        const name = getDataNameFromParentNode(parentNode);
        const updates = {[name]: value};
        const updateCall = () => onChange.call(this, updates, {
            mode: updateMode.EDIT,
            shouldEnable: shouldEnableAwsResourcePool
        });

        if (value === instanceIdleActionKeys.TERMINATE) {
            this.props.showWarning({
                info: {
                    key: popupInfoKeys.INSTANCE_IDLE_ACTION_TERMINATE_WARNING
                },
                buttons: [{
                    titleKey: 'common:option.ok',
                    onClick: updateCall
                }]
            });

        } else {
            updateCall();
        }
    }

    onRemoteInstanceIdleActionDropdownClick(event) {
        const {dataset: {value}, parentNode} = event.currentTarget;
        if (this.props.remoteInstanceIdleAction === value)
            return;

        const name = getDataNameFromParentNode(parentNode);
        const updates = {[name]: value};
        const updateCall = () => onChange.call(this, updates, {
            mode: updateMode.EDIT,
            shouldEnable: shouldEnableAwsResourcePool
        });

        if (value === instanceIdleActionKeys.TERMINATE) {
            this.props.showWarning({
                info: {
                    key: popupInfoKeys.INSTANCE_IDLE_ACTION_TERMINATE_WARNING
                },
                buttons: [{
                    titleKey: 'common:option.ok',
                    onClick: updateCall
                }]
            });

        } else {
            updateCall();
        }
    }

    render() {

        const {
            t,
            nuixLicenceSourceName,
            nuixLicenceSourceId,
            awsIdType,
            awsResourcePoolName,
            description,
            active,
            targetNuixWorkers,
            minNuixWorkers,
            whitelistedCertFingerprints,
            instanceIdleAction,
            forceIdleActionBetweenJobs,
            accessKey,
            secretKey,
            region,
            instanceIds,
            tagName,
            tagValue,
            launchTemplateId,
            maxConcurrentInstances,

            remoteNuixLicenceSourceName,
            remoteAwsIdType,
            remoteLaunchTemplateId,
            remoteMaxConcurrentInstances,
            remoteInstanceIds,
            remoteTagName,
            remoteTagValue,
            remoteNuixLicenceSourceId,
            remoteTargetNuixWorkers,
            remoteMinNuixWorkers,
            remoteInstanceIdleAction,
            useRemoteEngines,
            remoteNoIdleBeforeFirstJob,
            remoteNoIdleDuringJob,

            log,
            userPermissions,
            status,
            isEditActive,
            isSaveEnabled,
            isDisabled,

            resourcePoolId
        } = this.props;

        const menuOptions = [
            {name: t('common:option.edit'), value: 'editResourcePool', isDisabled: false},
            {name: t(`common:option.${active ? 'deactivate' : 'activate'}`), value: 'toggleResourcePoolActive', isDisabled: false},
            {name: t('common:option.delete'), value: 'deleteResourcePool', isDisabled: false},
            {isSeparator: true},
            {name: t('common:option.duplicate'), value: 'duplicate'},
        ];


        const descriptionProps = {
            label: t('common:label.description'),
            name: 'description',
            value: description,
            onChange: this.getModifyPrivilegedAction(this.onInputChange),
            isEditActive
        };

        const accessKeyInputProps = {
            name: 'accessKey',
            value: accessKey,
            onChange: this.getModifyPrivilegedAction(this.onInputChange),
            isRequired: true
        };
        const awsRegionDropdownProps = {
            selectedAwsRegion: region,
            onAwsRegionSelect: this.getModifyPrivilegedAction(this.onEditDropdownItemClick),
            isEdit: isEditActive
        };

        const nuixLicenceSourceDropdownProps = {
            selectedNuixLicenceSourceId: nuixLicenceSourceId,
            onNuixLicenceSourceSelect: this.getModifyPrivilegedAction(this.onEditDropdownItemClick),
            isEdit: isEditActive
        };
        const targetNuixWorkersInputProps = {
            type: 'number',
            style: {width: '5rem'},
            name: 'targetNuixWorkers',
            value: targetNuixWorkers,
            onChange: this.getModifyPrivilegedAction(this.onInputChange),
            isRequired: true
        };
        const minNuixWorkersInputProps = {
            type: 'number',
            style: {width: '5rem'},
            name: 'minNuixWorkers',
            value: minNuixWorkers,
            onChange: this.getModifyPrivilegedAction(this.onInputChange),
            isRequired: true,
            isInvalid: compareNumStrings(targetNuixWorkers, minNuixWorkers) < 0
        };
        const instanceIdleActionDropdownProps = {
            selectedInstanceIdleAction: instanceIdleAction,
            onInstanceIdleActionSelect: this.getModifyPrivilegedAction(this.onInstanceIdleActionDropdownClick),
            isEdit: isEditActive
        };
        const forceIdleActionBetweenJobsCheckboxProps = {
            name: 'forceIdleActionBetweenJobs',
            checked: forceIdleActionBetweenJobs,
            onClick: this.getModifyPrivilegedAction(this.onEditToggleEnable)
        };

        const remoteNoIdleBeforeFirstJobCheckboxProps = {
            name: 'remoteNoIdleBeforeFirstJob',
            checked: remoteNoIdleBeforeFirstJob,
            onClick: this.getModifyPrivilegedAction(this.onEditToggleEnable)
        };

        const remoteNoIdleDuringJobCheckboxProps = {
            name: 'remoteNoIdleDuringJob',
            checked: remoteNoIdleDuringJob,
            onClick: this.getModifyPrivilegedAction(this.onEditToggleEnable)
        };

        const awsIdTypeDropdownProps = {
            selectedAwsIdType: awsIdType,
            onAwsIdTypeSelect: this.getModifyPrivilegedAction(this.onEditDropdownItemClick),
            isEdit: isEditActive
        };
        const instanceIdsInputListProps = {
            id: 'awsInstanceIds',
            values: instanceIds,
            onValueChange: this.getModifyPrivilegedAction(this.onEditAwsInstanceIdChange),
            onAddClick: this.getModifyPrivilegedAction(this.onEditAddAwsInstanceId),
            onDeleteClick: this.getModifyPrivilegedAction(this.onEditDeleteAwsInstanceId),
            isRequired: true
        };

        const launchTemplateIdInputProps = {
            name: 'launchTemplateId',
            value: launchTemplateId || '',
            onChange: this.getModifyPrivilegedAction(this.onInputChange),
            isRequired: true
        };

        const tagNameInputProps = {
            name: 'tagName',
            value: tagName || '',
            onChange: this.getModifyPrivilegedAction(this.onInputChange),
            isRequired: true
        };

        const tagValueIdInputProps = {
            name: 'tagValue',
            value: tagValue || '',
            onChange: this.getModifyPrivilegedAction(this.onInputChange),
            isRequired: true
        };

        const maxConcurrentInstancesInputProps = {
            type: 'number',
            style: {width: '5rem'},
            name: 'maxConcurrentInstances',
            value: maxConcurrentInstances,
            onChange: this.getModifyPrivilegedAction(this.onInputChange),
            isRequired: true
        };

        const remoteNuixLicenceSourceDropdownProps = {
            id: 'remoteLicenceSourceDropdown',
            name: 'remoteNuixLicenceSourceId',
            selectedNuixLicenceSourceId: remoteNuixLicenceSourceId,
            onNuixLicenceSourceSelect: this.getModifyPrivilegedAction(this.onEditDropdownItemClick),
            isEdit: isEditActive,
            isRequired: true
        };
        const remoteTargetNuixWorkersInputProps = {
            type: 'number',
            style: {width: '5rem'},
            name: 'remoteTargetNuixWorkers',
            value: remoteTargetNuixWorkers,
            onChange: this.getModifyPrivilegedAction(this.onInputChange),
            isRequired: true
        };
        const remoteMinNuixWorkersInputProps = {
            type: 'number',
            style: {width: '5rem'},
            name: 'remoteMinNuixWorkers',
            value: remoteMinNuixWorkers,
            onChange: this.getModifyPrivilegedAction(this.onInputChange),
            isRequired: true,
            isInvalid: compareNumStrings(remoteTargetNuixWorkers, remoteMinNuixWorkers) < 0
        };
        const remoteInstanceIdleActionDropdownProps = {
            id: 'remoteInstanceIdleActionDropdown',
            name: 'remoteInstanceIdleAction',
            selectedInstanceIdleAction: remoteInstanceIdleAction,
            onInstanceIdleActionSelect: this.getModifyPrivilegedAction(this.onRemoteInstanceIdleActionDropdownClick),
            isEdit: isEditActive
        };

        const remoteAwsIdTypeDropdownProps = {
            id: 'remoteAwsIdTypeDropdown',
            name: 'remoteAwsIdType',
            selectedAwsIdType: remoteAwsIdType,
            onAwsIdTypeSelect: this.getModifyPrivilegedAction(this.onEditDropdownItemClick),
            isEdit: isEditActive
        };
        const remoteInstanceIdsInputListProps = {
            id: 'remoteAwsInstanceIds',
            values: remoteInstanceIds,
            onValueChange: this.getModifyPrivilegedAction(this.onRemoteAwsInstanceIdChange),
            onAddClick: this.getModifyPrivilegedAction(this.onAddRemoteAwsInstanceId),
            onDeleteClick: this.getModifyPrivilegedAction(this.onDeleteRemoteAwsInstanceId),
            isRequired: true
        };
        const remoteLaunchTemplateIdInputProps = {
            name: 'remoteLaunchTemplateId',
            value: remoteLaunchTemplateId || '',
            onChange: this.getModifyPrivilegedAction(this.onInputChange),
            isRequired: true
        };

        const remoteTagNameInputProps = {
            name: 'remoteTagName',
            value: remoteTagName || '',
            onChange: this.getModifyPrivilegedAction(this.onInputChange),
            isRequired: true
        };

        const remoteTagValueInputProps = {
            name: 'remoteTagValue',
            value: remoteTagValue || '',
            onChange: this.getModifyPrivilegedAction(this.onInputChange),
            isRequired: true
        };

        const remoteMaxConcurrentInstancesInputProps = {
            type: 'number',
            style: {width: '5rem'},
            name: 'remoteMaxConcurrentInstances',
            value: remoteMaxConcurrentInstances,
            onChange: this.getModifyPrivilegedAction(this.onInputChange),
            isRequired: true
        };

        const awsIdTypeLabel = isEditActive ? awsIdType : !!launchTemplateId ? awsIdTypeKeys.LAUNCH_TEMPLATE_ID : !!tagName ? awsIdTypeKeys.TAGS : awsIdTypeKeys.INSTANCE_IDS;
        const remoteAwsIdTypeLabel = isEditActive ? remoteAwsIdType : !!remoteLaunchTemplateId ? awsIdTypeKeys.LAUNCH_TEMPLATE_ID : !!remoteTagName ? awsIdTypeKeys.TAGS : awsIdTypeKeys.INSTANCE_IDS;
        
        const whitelistedCertFingerprintsProps = {
            id: 'whitelistedCertFingerprints',
            label: t('resourcePool:label.whitelistedCertFingerprints'),
            values: whitelistedCertFingerprints,
            onInputChange: this.getModifyPrivilegedAction(this.onEditWhitelistCertFingerprintChange),
            onAddClick: this.getModifyPrivilegedAction(this.onEditAddWhitelistCertFingerprint),
            onDeleteClick: this.getModifyPrivilegedAction(this.onEditDeleteWhitelistCertFingerprint),
            isEditActive
        };
        const logProps = {
            label: t('resourcePool:label.log'),
            log
        };

        const canModifyResourcePool = userPermissions.includes(permissionKeys.MODIFY);
        const isDescriptionVisible = isEditActive || description;
        const isWhitelistedCertFingerprintsVisible = isEditActive || (whitelistedCertFingerprints || []).some(fingerprint => fingerprint);

        const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);
        if (isViewLimited && !canModifyResourcePool) {
            return (
                <LimitedTablet id={resourcePoolId} type={'awsResourcePool'} name={awsResourcePoolName} label={t(`resourcePool:label.awsName`)}
                               status={status} enabled={active} canModify={canModifyResourcePool} isDisabled={isDisabled} description={description}
                               onClose={this.props.close}
                />
            )
        }

        return (
            <Tablet width={'100rem'} height={'auto'} onClose={this.props.close}
                closeButtonAriaLabel={t('resourcePool:option.closeAwsTablet_name', {name: awsResourcePoolName})}
                header={
                    <EditTabletHeader label={t('resourcePool:label.awsName')} type={'awsResourcePool'}
                        id={resourcePoolId} name={'awsResourcePoolName'} value={awsResourcePoolName} enabled={active} inputHandler={this.onInputChange}
                        canModify={canModifyResourcePool} menuOptions={menuOptions} menuOptionHandler={this.onMenuOptionClick}
                        isEditActive={isEditActive} isDisabled={isDisabled}
                    />
                }
                body={
                    <>
                        {status.code === statusKeys.ERROR && !isEditActive &&
                        <div className="display-item">
                            <ExpandableStatusLog {...status} isDisabled={isDisabled}/>
                        </div>
                        }

                        <div className="display-item">
                            <DefaultEditPanel isActive={isEditActive} isSaveEnabled={isSaveEnabled}
                                onSave={this.props.saveEdit} onCancel={this.props.cancelEdit}>
                                {isDescriptionVisible && <ExpandableEditTextArea {...descriptionProps} />}

                                <ExpandableContent label={t('common:label.settings')}>
                                    <div className="settings-table">
                                        <div className="table-row-group">
                                            {SettingsRowValue({
                                                label: t('resourcePool:label.accessKey'),
                                                htmlFor: 'awsResourcePoolAccessKey',
                                                value: isEditActive ?
                                                    <HTMLTextInput id={'awsResourcePoolAccessKey'} {...accessKeyInputProps}/>
                                                    :
                                                    accessKey
                                            })}
                                            {isEditActive && SettingsRowValue({
                                                label: t('resourcePool:label.secretKey'),
                                                htmlFor: 'awsResourcePoolSecretKey',
                                                value: <HTMLTextInput id={'awsResourcePoolSecretKey'} inputRef={this.passwordRef} name={'secretKey'} type={'password'}
                                                    defaultValue={secretKey} onChange={this.onInputChange} onBeforeInput={this.clearOnFirstCallback} isRequired/>
                                            })}
                                            {SettingsRowValue({
                                                label: t('resourcePool:label.region'),
                                                value: isEditActive ?
                                                    <AwsRegionDropdown {...awsRegionDropdownProps} />
                                                    :
                                                    region
                                            })}
                                        </div>
                                    </div>
                                </ExpandableContent>

                                <ExpandableContent label={t('resourcePool:label.engines')}>
                                    <div className="settings-table">
                                        <div className="table-row-group">
                                            {SettingsRowValue({
                                                label: t('resourcePool:label.nuixLicenceSource'),
                                                value: isEditActive ?
                                                    <NuixLicenceSourceDropdown {...nuixLicenceSourceDropdownProps} />
                                                    :
                                                    nuixLicenceSourceName
                                            })}
                                            {SettingsRowValue({
                                                label: t('resourcePool:label.targetWorkers'),
                                                htmlFor: 'awsResourcePoolTargetWorkers',
                                                value: isEditActive ?
                                                    <HTMLTextInput id={'awsResourcePoolTargetWorkers'} {...targetNuixWorkersInputProps}/>
                                                    :
                                                    targetNuixWorkers
                                            })}
                                            {SettingsRowValue({
                                                label: t('resourcePool:label.minWorkers'),
                                                htmlFor: 'awsResourcePoolMinWorkers',
                                                value: isEditActive ?
                                                    <HTMLTextInput id={'awsResourcePoolMinWorkers'} {...minNuixWorkersInputProps}/>
                                                    :
                                                    minNuixWorkers
                                            })}
                                            {SettingsRowValue({
                                                label: t('resourcePool:label.instanceIdleAction'),
                                                value: isEditActive ?
                                                    <InstanceIdleActionDropdown {...instanceIdleActionDropdownProps} />
                                                    :
                                                    t(`resourcePool:instanceIdleAction.${instanceIdleAction}`)
                                            })}
                                            {SettingsRowValue({
                                                label: t('resourcePool:label.forceIdleAction'),
                                                htmlFor: 'awsResourcePoolForceIdleActionBetweenJobs',
                                                value: isEditActive ?
                                                    <Checkbox id={'awsResourcePoolForceIdleActionBetweenJobs'} {...forceIdleActionBetweenJobsCheckboxProps}/>
                                                    :
                                                    boolToString(forceIdleActionBetweenJobs)
                                            })}

                                            {SettingsRowValue({
                                                label: t('resourcePool:label.startVmFrom'),
                                                value: isEditActive ?
                                                    <AwsIdTypeDropdown {...awsIdTypeDropdownProps} />
                                                    :
                                                    t(`resourcePool:awsIdType.${awsIdTypeLabel}`)
                                            })}

                                            <Switch>
                                                {(awsIdTypeLabel === awsIdTypeKeys.LAUNCH_TEMPLATE_ID &&
                                                    <>
                                                        {SettingsRowValue({
                                                            label: t('resourcePool:label.launchTemplateId'),
                                                            htmlFor: 'awsResourcePoolLaunchTemplateId',
                                                            value: isEditActive ?
                                                                <HTMLTextInput id={'awsResourcePoolLaunchTemplateId'} {...launchTemplateIdInputProps}/>
                                                                :
                                                                launchTemplateId
                                                        })}
                                                        {SettingsRowValue({
                                                            label: t('resourcePool:label.maxConcurrentInstances'),
                                                            htmlFor: 'awsResourcePoolMaxConcurrentInstances',
                                                            value: isEditActive ?
                                                                <HTMLTextInput id={'awsResourcePoolMaxConcurrentInstances'} {...maxConcurrentInstancesInputProps}/>
                                                                :
                                                                maxConcurrentInstances
                                                        })}
                                                        </>
                                                )}

                                                {(awsIdTypeLabel === awsIdTypeKeys.INSTANCE_IDS &&
                                                        SettingsRowValue({
                                                        label: t(`resourcePool:awsIdType.${awsIdTypeLabel}`),
                                                        labelStyle: {verticalAlign: 'top', paddingTop: isEditActive ? '5px' : ''},
                                                        value: isEditActive ?
                                                        <InputList {...instanceIdsInputListProps} />
                                                        :
                                                        instanceIds.map(instanceId =>
                                                        <label className="label"
                                                        key={instanceId}>{instanceId}</label>
                                                        )
                                                    })
                                                )}

                                                {(awsIdTypeLabel === awsIdTypeKeys.TAGS &&
                                                    <>
                                                        {SettingsRowValue({
                                                            label: t('resourcePool:label.tagName'),
                                                            htmlFor: 'awsResourcePoolTagName',
                                                            value: isEditActive ?
                                                                <HTMLTextInput id={'awsResourcePoolTagName'} {...tagNameInputProps}/>
                                                                :
                                                                tagName
                                                        })}
                                                        {SettingsRowValue({
                                                            label: t('resourcePool:label.tagValue'),
                                                            htmlFor: 'awsResourcePoolTagValue',
                                                            value: isEditActive ?
                                                                <HTMLTextInput id={'awsResourcePoolTagValue'} {...tagValueIdInputProps}/>
                                                                :
                                                                tagValue
                                                        })}
                                                    </>
                                                )}
                                            </Switch>
                                        </div>
                                    </div>
                                </ExpandableContent>

                                {(useRemoteEngines || isEditActive) &&
                                <ExpandableContent label={t('resourcePool:label.remoteEngines')}>
                                    <div className="settings-table">
                                        <div className="table-row-group">
                                            {isEditActive && SettingsRowValue({
                                                label: t('resourcePool:label.useRemoteEngines'),
                                                htmlFor: 'useRemoteEngines',
                                                value:
                                                    <Checkbox id={'useRemoteEngines'} name={'useRemoteEngines'} checked={useRemoteEngines} onClick={this.onEditToggleEnable}
                                                        isDisabled={isDisabled}
                                                    />
                                            })}

                                            {useRemoteEngines &&
                                            <>
                                            {SettingsRowValue({
                                                label: t('resourcePool:label.nuixLicenceSource'),
                                                value: isEditActive ?
                                                    <NuixLicenceSourceDropdown {...remoteNuixLicenceSourceDropdownProps} />
                                                    :
                                                    remoteNuixLicenceSourceName
                                            })}
                                            {SettingsRowValue({
                                                label: t('resourcePool:label.targetWorkers'),
                                                htmlFor: 'remoteAwsResourcePoolTargetWorkers',
                                                value: isEditActive ?
                                                    <HTMLTextInput id={'remoteAwsResourcePoolTargetWorkers'} {...remoteTargetNuixWorkersInputProps}/>
                                                    :
                                                    remoteTargetNuixWorkers
                                            })}
                                            {SettingsRowValue({
                                                label: t('resourcePool:label.minWorkers'),
                                                htmlFor: 'remoteAwsResourcePoolMinWorkers',
                                                value: isEditActive ?
                                                    <HTMLTextInput id={'remoteAwsResourcePoolMinWorkers'} {...remoteMinNuixWorkersInputProps}/>
                                                    :
                                                    remoteMinNuixWorkers
                                            })}
                                            {SettingsRowValue({
                                                label: t('resourcePool:label.instanceIdleAction'),
                                                value: isEditActive ?
                                                    <InstanceIdleActionDropdown {...remoteInstanceIdleActionDropdownProps} />
                                                    :
                                                    t(`resourcePool:instanceIdleAction.${remoteInstanceIdleAction}`)
                                            })}

                                            {SettingsRowValue({
                                                label: t('resourcePool:label.remoteNoIdleBeforeFirstJob'),
                                                htmlFor: 'awsRemoteNoIdleBeforeFirstJob',
                                                value: isEditActive ?
                                                    <Checkbox id={'awsRemoteNoIdleBeforeFirstJob'} {...remoteNoIdleBeforeFirstJobCheckboxProps}/>
                                                    :
                                                    boolToString(remoteNoIdleBeforeFirstJob)
                                            })}

                                            {SettingsRowValue({
                                                label: t('resourcePool:label.remoteNoIdleDuringJob'),
                                                htmlFor: 'awsRemoteNoIdleDuringJob',
                                                value: isEditActive ?
                                                    <Checkbox id={'awsRemoteNoIdleDuringJob'} {...remoteNoIdleDuringJobCheckboxProps}/>
                                                    :
                                                    boolToString(remoteNoIdleDuringJob)
                                            })}

                                            {SettingsRowValue({
                                                label: t('resourcePool:label.startVmFrom'),
                                                value: isEditActive ?
                                                    <AwsIdTypeDropdown {...remoteAwsIdTypeDropdownProps} />
                                                    :
                                                    t(`resourcePool:awsIdType.${remoteAwsIdTypeLabel}`)
                                            })}

                                            <Switch>
                                                {(remoteAwsIdTypeLabel === awsIdTypeKeys.LAUNCH_TEMPLATE_ID &&
                                                        <>
                                                            {SettingsRowValue({
                                                                label: t('resourcePool:label.launchTemplateId'),
                                                                htmlFor: 'remoteAwsResourcePoolLaunchTemplateId',
                                                                value: isEditActive ?
                                                                    <HTMLTextInput id={'remoteAwsResourcePoolLaunchTemplateId'} {...remoteLaunchTemplateIdInputProps}/>
                                                                    :
                                                                    remoteLaunchTemplateId
                                                            })}
                                                            {SettingsRowValue({
                                                                label: t('resourcePool:label.maxConcurrentInstances'),
                                                                htmlFor: 'remoteAwsResourcePoolMaxConcurrentInstances',
                                                                value: isEditActive ?
                                                                    <HTMLTextInput id={'remoteAwsResourcePoolMaxConcurrentInstances'} {...remoteMaxConcurrentInstancesInputProps}/>
                                                                    :
                                                                    remoteMaxConcurrentInstances
                                                            })}
                                                        </>
                                                )}

                                                {(remoteAwsIdTypeLabel === awsIdTypeKeys.INSTANCE_IDS &&
                                                        SettingsRowValue({
                                                            label: t(`resourcePool:awsIdType.${remoteAwsIdTypeLabel}`),
                                                            labelStyle: {verticalAlign: 'top', paddingTop: isEditActive ? '5px' : ''},
                                                            value: isEditActive ?
                                                                <InputList {...remoteInstanceIdsInputListProps} />
                                                                :
                                                                remoteInstanceIds.map(instanceId =>
                                                                    <label className="label"
                                                                        key={instanceId}>{instanceId}</label>
                                                                )
                                                        })
                                                )}

                                                {(remoteAwsIdTypeLabel === awsIdTypeKeys.TAGS &&
                                                    <>
                                                        {SettingsRowValue({
                                                            label: t('resourcePool:label.tagName'),
                                                            htmlFor: 'remoteAwsResourcePoolTagName',
                                                            value: isEditActive ?
                                                                <HTMLTextInput id={'remoteAwsResourcePoolTagName'} {...remoteTagNameInputProps}/>
                                                                :
                                                                remoteTagName
                                                        })}
                                                        {SettingsRowValue({
                                                            label: t('resourcePool:label.tagValue'),
                                                            htmlFor: 'remoteAwsResourcePoolTagValue',
                                                            value: isEditActive ?
                                                                <HTMLTextInput id={'remoteAwsResourcePoolTagValue'} {...remoteTagValueInputProps}/>
                                                                :
                                                                remoteTagValue
                                                        })}
                                                    </>
                                                )}

                                            </Switch>
                                            </>
                                            }
                                        </div>
                                    </div>
                                </ExpandableContent>
                                }

                                {isWhitelistedCertFingerprintsVisible &&
                                <ExpandableInputList {...whitelistedCertFingerprintsProps} />}
                            </DefaultEditPanel>
                        </div>

                        {isNotEmptyNorFalsy(log) && !isEditActive &&
                        <div className="display-item">
                            <ExpandableTimeStampedLog {...logProps} />
                        </div>
                        }
                    </>
                }
            />
        );
    }
}


const makeMapStateToProps = () => {
    const getEditDetails = makeGetEditDetails();
    return state => {
        const {componentStates: {resourcePoolDisplay: {resourcePoolId}}} = state;

        const {name: awsResourcePoolName, cloudSettings, ...otherDetails} = state.resourcePoolDetailsMap.get(resourcePoolId);
        const {nuixLicenceSourceId, awsSettings, remoteNuixLicenceSourceId, remoteAwsSettings, ...otherCloudSettings} = cloudSettings;

        const nuixLicenceSourceDetails = state.nuixLicenceSourceDetailsMap.get(nuixLicenceSourceId);
        const remoteNuixLicenceSourceDetails = state.nuixLicenceSourceDetailsMap.get(remoteNuixLicenceSourceId);

        const nuixLicenceSourceName = nuixLicenceSourceDetails ? nuixLicenceSourceDetails.name : nuixLicenceSourceId;
        const remoteNuixLicenceSourceName = remoteNuixLicenceSourceDetails ? remoteNuixLicenceSourceDetails.name : remoteNuixLicenceSourceId;

        const {activeModel, values, isSaveEnabled} = getEditDetails(state, {model: ResourcePoolModel.nom});
        const isEditActive = (activeModel === ResourcePoolModel.nom);

        return {
            resourcePoolId,
            awsResourcePoolName,
            nuixLicenceSourceName,
            remoteNuixLicenceSourceName,
            nuixLicenceSourceId,
            remoteNuixLicenceSourceId,
            ...otherCloudSettings,
            ...awsSettings,
            ...remoteAwsSettings,
            ...otherDetails,
            ...values,
            isEditActive,
            isSaveEnabled
        };
    }
};

const mapDispatchToProps = dispatch => {
    return {
        editResourcePool: resourcePoolId => dispatch(ResourcePoolModel.actionCreators.startEdit(resourcePoolId)),
        toggleResourcePoolActive: resourcePoolId => dispatch(ResourcePoolModel.actionCreators.toggleEnabled(resourcePoolId)),
        promptDeleteResourcePool: resourcePoolId => dispatch(ResourcePoolModel.actionCreators.promptDelete(resourcePoolId)),
        duplicate: resourcePoolId => dispatch(ResourcePoolModel.actionCreators.duplicateDetails(resourcePoolId)),

        ...EditModel.buildDispatchers(dispatch),

        showPopup: payload => dispatch(PopupModel.actionCreators.show(payload)),
        showError: payload => dispatch(PopupModel.actionCreators.showError(payload)),
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        close: () => dispatch(ResourcePoolModel.actionCreators.hideTablet())
    };
};

export default connect(makeMapStateToProps, mapDispatchToProps)(withTranslation(['resourcePool', 'common'])(AwsResourcePoolTablet));
