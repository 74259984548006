import React, {useEffect, useState} from 'react';
import './Tablet.css';
import CustomModal from '../CustomModal/CustomModal';
import {useTranslation} from "react-i18next";
import EditPanel from '../EditPanel/EditPanel';
import {MenuDropdown} from '../Dropdown/Dropdown';
import {icon} from '../../../utilities/iconResolver';
import {useDispatch, useSelector} from "react-redux";
import {userSettings} from "../../../utilities/constants";
import Switch from "../Switch/Switch";
import {isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";

function Tablet(props) {
    const {header, body, width, height, onClose, isDisabled, bodyStyle, ...attr} = props;

    const _bodyStyle = {
        width,
        height,
        ...bodyStyle
    }

    return (
        <CustomModal id={"tablet"} isActive={true} onCancel={onClose}
            isDisabled={isDisabled} isRigid={isDisabled}
            aria-labelledby={'tabletHeader'} {...attr}
            header={
                <section id={"tabletHeader"} className="tablet-header"
                    style={width ? {width: `calc(${width} - 1.5em + 0.75em + 2px - 1.5em)`} : {}}
                >
                    {header}
                </section>
            }
            body={
                <section id={"tabletBody"} className="tablet-body" style={_bodyStyle}>
                    {body}
                </section>
            }
            footer={
                <section className="tablet-footer"/>
            }
        />
    )
}

export function EditTabletHeader(props) {
    const {t} = useTranslation(['aria', 'common']);

    const {
        id,
        label,
        type,
        name,
        value,
        enabled=true,
        canModify,
        menuName,
        menuOptions,
        menuOptionHandler,
        inputHandler,
        isEditActive,
        hideMenu,
        isLoading,
        isDisabled
    } = props;

    const disabled = isDisabled ? ' is-disabled' : '';
    const title = enabled ? value : `${value} (${t('common:label.inactive')})`;

    return (
        <EditPanel isActive={isEditActive} style={{flex: 1, minWidth: '0'}}>
            <div className="tablet__edit-header">
                <div className="tablet-header-left">
                    <Switch>
                        {isEditActive &&
                        <input aria-label={t('aria:input.name')} className={'input subtitle-edit' + (value ? '' : ' is-required') + disabled}
                            name={name} value={value} onChange={inputHandler}/>
                        }

                        {!isLoading &&
                        <ObjectIdSubtitle id={id} subtitle={title} style={{flexShrink: 1, minWidth: 0}}
                            isDisabled={isDisabled}/>
                        }
                    </Switch>

                    {!isEditActive && canModify && !hideMenu && isNotEmptyNorFalsy(menuOptions) &&
                    <div className="display-menu">
                        <MenuDropdown id={`${menuName || type}MenuDropdown`} aria-label={t(`aria:hiddenAssistText.${menuName || type}Menu`)}
                            menuOptions={menuOptions} onOptionClick={menuOptionHandler} isDisabled={isDisabled}
                        />
                    </div>
                    }
                </div>

                <IconSubtitle type={type} subtitle={label} isDisabled={isDisabled}/>
            </div>
        </EditPanel>
    );
}

export function ObjectIdSubtitle(props) {

    const {
        id,
        subtitle,
        isDisabled,
        ...attr
    } = props;

    const {showObjectIds} = useSelector(state => state.userSettingsMap.get(userSettings.TROUBLESHOOT));
    const disabled = isDisabled ? ' is-disabled' : '';

    return (
        <div className="object-id-subtitle" {...attr}>
            <h2 className={'subtitle is-bold' + disabled}>
                {subtitle}
            </h2>
            {showObjectIds &&
            <label className={'id-label' + disabled}>
                {id}
            </label>
            }
        </div>
    )
}

export const ICON_SRC_SET = 'ICON_SUBTITLE_ICON_SRC_SET';
export function IconSubtitle(props) {
    const {t} = useTranslation(['aria'])
    const dispatch = useDispatch();

    const {
        type,
        subtitle,
        size='medium',

        inlineIcon,
        isDisabled,
        ...attr
    } = props;

    const [iconSrc, setIconSrc] = useState();

    useEffect(() => {
        const src = icon(type);

        if (inlineIcon) {
            fetch(src)
                .then(res => res.text())
                .then(svgText => {
                    setIconSrc(`data:image/svg+xml;utf8,${encodeURIComponent(svgText)}`);
                    dispatch({type: ICON_SRC_SET});
                });
        } else {
            setIconSrc(src);
        }
    }, [inlineIcon, type]);

    const disabled = isDisabled ? ' is-disabled' : '';

    return (
        <div className="icon-subtitle" {...attr}>
            {subtitle &&
            <h2 className={'subtitle is-bold' + disabled} style={{marginRight: '0.5rem'}}>
                {subtitle}
            </h2>
            }
            <span className={`icon is-${size}` + disabled}>
                <img src={iconSrc} alt=""/>
            </span>
        </div>
    )
}

export default Tablet;