import React, {useEffect} from "react";
import "./UploadMonitor.css";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import FileUploadHistoryModel from "../../models/data/FileUploadHistoryModel";
import UploadMonitorFilterHeader from "./UploadMonitorFilterHeader";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {details} from "../../utilities/constants";
import {getMapValueName, getObjectText, getValues} from "../../utilities/helperFunctions";
import UploadInfoTable from "./UploadInfoTable";
import FileUploadHistoryTable from "./FileUploadHistoryTable";
import Text from "../common/Text/Text";
import {MainPageContent, MainPageHeader} from "../app/pages/MainPage";


function getInfoText(state, info) {
    const {clientId, matterId, datasetId, dataRepositoryId, ...rest} = info;
    const textObj = {
        client: getMapValueName(state.clientDetailsMap, clientId),
        matter: getMapValueName(state.matterDetailsMap, matterId),
        dataset: getMapValueName(state.datasetDetailsMap, datasetId),
        dataRepository: getMapValueName(state.dataRepositoryDetailsMap, dataRepositoryId),
        ...rest
    };
    return getObjectText(textObj);
}

export function selectFilteredUploadInfos(state) {
    const {searchText} = state.componentStates.uploadMonitorDisplay;

    return getValues(state.uploadInfoDetailsMap)
        .filter(uploadInfo => getInfoText(state, uploadInfo).includes(searchText.toLowerCase()));
}

export function selectFilteredFileUploadHistories(state) {
    const {searchText} = state.componentStates.uploadMonitorDisplay;

    return getValues(state.fileUploadHistoryDetailsMap)
        .filter(uploadHistory => getInfoText(state, uploadHistory).includes(searchText.toLowerCase()));
}

function UploadMonitorDisplay(props) {
    const {t} = useTranslation(['uploadMonitor']);
    const dispatch = useDispatch();

    const {
        ...attr
    } = props;

    const isLoadingUploads = useSelector(state => !state.hasLoaded[details.UPLOAD_INFOS]);
    const isLoadingFiles = useSelector(state => !state.hasLoaded[details.FILE_UPLOAD_HISTORY]);
    const uploadInfos = useSelector(selectFilteredUploadInfos);
    const fileUploadHistories = useSelector(selectFilteredFileUploadHistories);

    useEffect(() => {
        dispatch(FileUploadHistoryModel.componentActionCreators.setDisplayActive());
        return () => dispatch(FileUploadHistoryModel.componentActionCreators.setDisplayInactive());
    }, []);

    return (
        <MainPageContent {...attr}
            title={
                <MainPageHeader text={t('uploadMonitor:page.title')}/>
            }
            list={
                <>
                    <div className="header">
                        <UploadMonitorFilterHeader/>
                    </div>

                    <div className="display-item">
                        <LoadingWrapper isLoading={isLoadingUploads}>
                            {uploadInfos.length > 0 &&
                                <>
                                    <Text value={t('uploadMonitor:label.activeUploads')} isHeading/>
                                    <UploadInfoTable uploadInfos={uploadInfos}/>
                                </>
                            }
                        </LoadingWrapper>
                    </div>

                    <div style={isLoadingFiles ? {} : {width: 'fit-content'}}>
                        <LoadingWrapper isLoading={isLoadingFiles}>
                            {fileUploadHistories.length > 0 &&
                                <>
                                    <Text value={t('uploadMonitor:label.pastUploads')} isHeading/>
                                    <FileUploadHistoryTable fileUploadHistories={fileUploadHistories}/>
                                </>
                            }
                        </LoadingWrapper>
                    </div>
                </>
            }
        />
    )
}

export default UploadMonitorDisplay;