import React, {useMemo, useRef, useState} from "react";
import {initialSelectedState, useClearSelectedEffect, useValueSelectHandler} from "../../../utilities/hooks";
import {
    buildClassName,
    filterArrayIndices,
    getNewArrayWithUpdatedValue,
    getParentDatasetAttr,
    isNotEmptyNorFalsy,
    objectTruthyValues,
    shallowCopy
} from "../../../utilities/helperFunctions";
import {AddRemoveButtons, HelpButton} from "../../common/Button/Button";
import {useTranslation} from "react-i18next";
import FieldComponent from "./FieldComponent";
import {createInputHandler} from "../../../utilities/componentFunctions";
import {createToggleHandler} from "../../common/Checkbox/helpers";
import {createDropdownHandler} from "../../common/Dropdown/helpers";
import Text from "../../common/Text/Text";
import FormBuilderConfiguration from "../../../models/workflowbuilder/FormBuilderConfiguration";
import FormBuilderComponentConfiguration from "../../../models/workflowbuilder/FormBuilderComponentConfiguration";
import {getInvalidMessageSpan} from "../../common/InvalidMessageSpan";

const centeredHeaderComponentTypes = new Set([
    FormBuilderComponentConfiguration.ComponentType.CHECKBOX
]);

function TableField(props) {
    const {t} = useTranslation(['workflowBuilder']);

    const {
        id,
        label,
        value,
        configuration,
        formKey,
        state,
        updateState,

        'data-type': dataType,
        invalidMessage,
        hideInvalidMessage,
        requiredMessage,

        isInvalid,
        isDisabled
    } = props;

    const containerRef = useRef();
    const [selected, setSelected] = useState(initialSelectedState);

    const valueSelectHandler = useValueSelectHandler({setSelected});
    useClearSelectedEffect({containerRef, setSelected});


    function updateField(update) {
        updateState(prevState => {
            return {
                [configuration.name]: typeof update === 'function' ? update(prevState[configuration.name]) : update
            };
        });
    }

    function updateFieldColumn(update, event) {
        const rowIndex = getParentDatasetAttr(event.target || event.currentTarget, 'index');
        updateField(prevField => {
            const updatedObject = shallowCopy(prevField[rowIndex], update);
            return getNewArrayWithUpdatedValue(prevField, updatedObject, rowIndex);
        });
    }

    function addRow() {
        const defaultObject = configuration.generateDefaultObject();
        updateField(prevField => {
            if (!Array.isArray(prevField)) {
                return [defaultObject];
            }
            return [...prevField, defaultObject]
        });
    }

    function removeSelectedRows() {
        updateField(prevField => {
            const selectedRowIndices = objectTruthyValues(selected.values);
            return filterArrayIndices(prevField, selectedRowIndices);
        });
        setSelected(initialSelectedState);
    }

    const inputHandler = createInputHandler({
        handler: updateFieldColumn
    });
    const toggleHandler = createToggleHandler({
        handler: updateFieldColumn
    });
    const dropdownHandler = createDropdownHandler({
        handler: updateFieldColumn
    });

    const headers = useMemo(() => {
        return configuration.componentConfigurations
            .map(config => {
                const label = FormBuilderConfiguration.getLabelTranslation(t, formKey, config.label);
                if (label) {
                    return {label, isCentered: centeredHeaderComponentTypes.has(config.componentType)}
                }
            })
            .filter(c => c);
    }, [configuration]);


    const componentHasHelpers = isNotEmptyNorFalsy(configuration.helperValues);
    const helpButton = componentHasHelpers && (
        <HelpButton helpers={configuration.helperValues} value={props.value} isDisabled={props.isDisabled}/>
    );

    const required = isInvalid && !isDisabled && (!Array.isArray(value) || value.length === 0);
    const labelId = label && `${id}:label`;

    const {
        combinedAriaLabelledBy,
        invalidMessageSpan
    } = getInvalidMessageSpan({
        id,
        ariaLabelledBy: [labelId],
        isRequired: required,
        invalidMessage,
        requiredMessage
    });

    const className = buildClassName(
        'workflow-builder__table-field',
        required && 'is-invalid'
    );

    const isRemoveDisabled = objectTruthyValues(selected.values).length === 0;
    return (
        <div>
            <div id={id} data-type={dataType} className={className} ref={containerRef}
                aria-labelledby={combinedAriaLabelledBy} aria-invalid={required}
            >
                <AddRemoveButtons labelId={labelId} label={label} onAddClick={addRow} onRemoveClick={removeSelectedRows} noPadding
                    ariaLabelKey={configuration.name} isRemoveDisabled={isRemoveDisabled}
                    isDisabled={isDisabled} afterLabel={helpButton}/>

                <table className="input-tableV2">
                    {Array.isArray(headers) && headers.length > 0 &&
                        <thead>
                        <tr>
                            {headers.map((header, index) =>
                                <td key={index} style={header.isCentered ? {textAlign: 'center'} : {}}>
                                    <Text value={header.label} isEllipsis
                                        isDisabled={isDisabled}/>
                                </td>
                            )}
                        </tr>
                        </thead>
                    }

                    <tbody>
                    {Array.isArray(value) && value.map((rowObject, rIndex) => {
                        const isSelected = !isDisabled && selected.values[rIndex];
                        return (
                            <tr key={rIndex} data-index={rIndex} onClick={valueSelectHandler}>
                                {configuration.componentConfigurations.map((columnConfiguration, cIndex) => {
                                    const columnValue = rowObject[columnConfiguration.name];
                                    const isInvalid = !columnConfiguration.isValid(columnValue);

                                    const parentId = `${id}:${rIndex}`;
                                    const ariaLabel = (Array.isArray(headers) && headers[cIndex]?.label)
                                        || t(`aria:hiddenAssistText.${(columnConfiguration.label || columnConfiguration.name)}`);

                                    return (
                                        <td key={cIndex}>
                                            <FieldComponent parentId={parentId} configuration={columnConfiguration} value={columnValue} formKey={formKey}
                                                state={state} updateState={updateFieldColumn} expandCheckbox simple isDropdownOutlined expandDropdown
                                                inputHandler={inputHandler} toggleHandler={toggleHandler} dropdownHandler={dropdownHandler} isSelected={isSelected}
                                                aria-label={ariaLabel} isRequired={columnConfiguration.required} isInvalid={isInvalid} isDisabled={isDisabled}/>
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            {!hideInvalidMessage && invalidMessageSpan}
        </div>
    )
}

export default TableField;
