import UsernamePasswordUserCredentialFormBody from "../userCredential/UsernamePasswordCredentialFormBody";
import ThirdPartyUserCredential from "../../../models/thirdparty/ThirdPartyUserCredential";
import {useTranslation} from "react-i18next";
import {SettingsRowValue} from "../../common/CustomTable/CustomTable";
import React from "react";

function EccUserCredentialFormBody(props) {
    return (
        <UsernamePasswordUserCredentialFormBody {...props} type={ThirdPartyUserCredential.Type.ECC}
                                                SettingRows={EccUserCredentialFormBodySettingRows}/>
    )
}

function EccUserCredentialFormBodySettingRows(props) {
    const {t} = useTranslation(['thirdPartyService', 'common']);
    const {
        thirdPartyService,
        isDisabled
    } = props;

    return (
        <>
            <SettingsRowValue label={t('thirdPartyService:label.hostname')} value={thirdPartyService.hostname}
                              isTight isDisabled={isDisabled}/>
        </>
    )
}

export default EccUserCredentialFormBody;