import React from 'react';
import {ListDropdown} from "../../common/Dropdown/Dropdown";
import {getDataNameFromParentNode, getValues} from "../../../utilities/helperFunctions";
import {localeKeys} from "../../../i18next/keys";
import {useTranslation} from "react-i18next";
import {browserDefaultLanguage} from "../../../i18next/i18n";

function UiLanguageOption(props) {
  const {setting: uiLanguage, updateSetting} = props;

  function onLocaleSelect(event) {
    const {dataset: {value}, parentNode} = event.currentTarget;
    const setting = getDataNameFromParentNode(parentNode);

    // Save undefined and null values as null
    updateSetting(setting, value == null ? null : value);
  }

  return (
    <div className="options-table display-input">
      <div className="table-header-group">
        <div className="table-header"/>
      </div>

      <div className="table-row-group">
        <div className="options-row">
          <div className="table-cell">
            <LocaleDropdown selectedLocale={uiLanguage.locale} onLocaleSelect={onLocaleSelect}/>
          </div>
        </div>
      </div>
    </div>
  )
}

function LocaleDropdown(props) {
    const {selectedLocale, onLocaleSelect, ...rest} = props;
    const {t} = useTranslation(['aria', 'uiLanguage']);

    const {BROWSER_DEFAULT, ...otherLocaleKeys} = localeKeys;
    const localeItems = [
        {
            name: t(`uiLanguage:locale.${BROWSER_DEFAULT}`, {
                lng: browserDefaultLanguage,
                language: browserDefaultLanguage
            }),
            value: BROWSER_DEFAULT
        },
        ...getValues(otherLocaleKeys)
            .map(_locale => ({
                name: t(`uiLanguage:locale.${_locale}`, {lng: _locale}),
                value: _locale
            }))
    ];

    return (
        <ListDropdown id={'localeDropdown'} name={'locale'}
            aria-label={t('aria:hiddenAssistText.locale')} value={selectedLocale}
            items={localeItems} onItemSelect={onLocaleSelect}
            buttonStyle={{maxWidth: '20rem', justifyContent: 'flex-start'}}
            {...rest}
        />
    )
}

export default UiLanguageOption;