import ObjectCache, {ObjectCacheSaga} from "../thirdparty/ObjectCache";
import {purviewDataSourceType} from "../../utilities/constants";

class PurviewCache extends ObjectCache {

    static nom = 'PurviewCache';
    static actions = PurviewCache.buildActions('PURVIEW_CACHE');
    static actionCreators = PurviewCache.buildActionCreators(PurviewCache.actions);
    static reducer = PurviewCache.buildReducer(PurviewCache.actions);

    static Resource = {
        CASES: 'CASES',
        SEARCHES: 'SEARCHES',
        REVIEW_SETS: 'REVIEW_SETS',
        REVIEW_SET_QUERIES: 'REVIEW_SET_QUERIES',
        EXPORT_JOBS: 'EXPORT_JOBS',

        USERS: 'USERS',
        UNIFIED_GROUPS: 'UNIFIED_GROUPS',
        ALL_SHARE_POINT_SITES: 'ALL_SHARE_POINT_SITES',

        CUSTODIANS: 'CUSTODIANS',
        CUSTODIAN_DATA_SOURCES: 'CUSTODIAN_DATA_SOURCES',
        ALL_CUSTODIAL_DATA_SOURCES: 'ALL_CUSTODIAL_DATA_SOURCES',
        NON_CUSTODIAL_DATA_SOURCES: 'NON_CUSTODIAL_DATA_SOURCES'
    };

    static getDataSourceValue(dataSource) {
        let value = dataSource.email;
        switch (dataSource.sourceType) {
            case purviewDataSourceType.UNIFIED_GROUP:
                if (dataSource.group != null) {
                    value = dataSource.group.email;
                }
                break;
            case purviewDataSourceType.SITE:
                if (dataSource.site != null) {
                    value = dataSource.site.webUrl;
                }
                break;
        }
        return value;
    }
}

export class PurviewCacheSaga extends ObjectCacheSaga {
    static CacheModel = PurviewCache;
}

export default PurviewCache;
