import SettingModel from "./SettingModel";

class AuditLogModel extends SettingModel.Complex {

    static nom = 'AuditLogModel';
    static actions = AuditLogModel.buildActions('AUDIT_LOG');
    static actionCreators = AuditLogModel.buildActionCreators(AuditLogModel.actions);
    static reducer = AuditLogModel.buildReducer(AuditLogModel.actions);

    constructor(model = {}) {
        super(model);
        this.date = model.date;
        this.performedBy = model.performedBy;
        this.legacyEventType = model.legacyEventType;
        this.details = model.details;
        this.downloadId = model.downloadId;
    }
}

export default AuditLogModel;
