import ObjectCache, {ObjectCacheSaga} from "../thirdparty/ObjectCache";

class VaultCache extends ObjectCache {

    static nom = 'VaultCache';
    static actions = VaultCache.buildActions('VAULT_CACHE');
    static actionCreators = VaultCache.buildActionCreators(VaultCache.actions);
    static reducer = VaultCache.buildReducer(VaultCache.actions);

    static Resource = {
        MATTERS: 'MATTERS',
        SAVED_QUERIES: 'SAVED_QUERIES',
        EXPORTS: 'EXPORTS',
        ALL_HELD_LOCATIONS: 'ALL_HELD_LOCATIONS'
    };
}

export class VaultCacheSaga extends ObjectCacheSaga {
    static CacheModel = VaultCache;
}

export default VaultCache;
