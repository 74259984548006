import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {createCloseHandler, createInputHandler, createStateHandler} from "../../utilities/componentFunctions";
import {shouldEnableApiKey} from "../../utilities/shouldEnableFunctions";
import Form, {FormFooter, FormHeader} from "../common/Form/Form";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import PopupModel from "../../models/scheduler/PopupModel";
import ApiKeyModel from "../../models/settings/ApiKeyModel";


function ApiKeyForm() {
    const {t} = useTranslation(['apiKey', 'common']);
    const dispatch = useDispatch();

    const {
        isDisabled
    } = useSelector(state => state.componentStates.apiKeyForm);

    const [state, setState] = useState({
        apiKeyName: '',
        validityDays: 1825,
        isAddEnabled: false
    });

    const stateHandler = createStateHandler({
        updateState: setState,
        shouldEnable: shouldEnableApiKey
    });

    const inputHandler = createInputHandler({
        handler: stateHandler
    });

    const onClose = createCloseHandler({
        t,
        item: t('apiKey:label.name'),
        values: state,
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        close: () => dispatch(ApiKeyModel.componentActionCreators.updateDisplay({isApiKeyFormActive: false}))
    });


    function onSubmit() {
        dispatch(ApiKeyModel.actionCreators.submitForm(state));
    }


    const {
        apiKeyName,
        validityDays,
        isAddEnabled
    } = state;


    return (
        <>
            <Form onClose={onClose} isDisabled={isDisabled} closeButtonAriaLabel={t('apiKey:option.closeForm')}
                header={
                    <FormHeader text={t('apiKey:label.name')} iconName={'apiKey'} isDisabled={isDisabled}/>
                }
                body={
                    <>
                        <div className="display-input">
                            <HTMLTextInput label={t('common:label.name')} name={'apiKeyName'} value={apiKeyName}
                                onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                        </div>

                        <div className="display-input">
                            <HTMLTextInput label={t('apiKey:label.validityDays')} name={'validityDays'} value={validityDays} type="number"
                                           onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                        </div>

                    </>
                }
                footer={
                  <FormFooter addText={t('common:button.addApiKey')}
                      onAddClick={onSubmit} onCancel={onClose} isAddEnabled={isAddEnabled} isDisabled={isDisabled}
                  />
                }
            />
        </>
    )
}

export default ApiKeyForm;
