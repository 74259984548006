import DetailsModel, {DetailsApi, DetailsSaga} from "../generics/DetailsModel";
import ComponentStateModel from "../generics/ComponentStateModel";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import {
    actionCreator,
    deepCopy,
    getEntries,
    getInputFormattedDateAndTime,
    getLocaleDateTimeFromUTC,
    getMapValueIdIfExists,
    getValues,
    isNotEmptyNorFalsy,
    nameLocaleCompare,
    pauseLoop,
    readLines,
    readTsvText
} from "../../utilities/helperFunctions";
import {all, call, fork, put, race, select, take, takeLatest} from "redux-saga/effects";
import {
    contextCall,
    contextSaga,
    contextTakeLatest,
    contextTakeLeading,
    isDisabledWrapper,
    poll
} from "../../saga/sagaFunctions";
import {
    custodianState,
    details,
    formElementTypes,
    legalHoldCommands,
    legalHoldRoles,
    QUERY_INTERVAL,
    recurringNoticeFrequencyKeys,
    routes,
    SLOW_QUERY_INTERVAL,
    userAccountStateKeys
} from "../../utilities/constants";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import ParameterModel from "../library/ParameterModel";
import EditModel from "../scheduler/EditModel";
import {retryCall, tryCatchWrapper} from "../../saga/tryCatchWrapper";
import {asyncSeparateItems} from "../../components/common/ListContainer/helpers";
import NoticeTemplateModel, {NoticeTemplateApi} from "../notice/NoticeTemplateModel";
import UserNoticeModel from "../notice/UserNoticeModel";
import NoticeConfiguration from "../notice/NoticeConfiguration";
import {
    legalHoldPageViewKeys,
    legalHoldStateKeys,
    permissionKeys,
    popupInfoKeys,
    priorityKeys
} from "../../i18next/keys";
import LegalHoldParticipationModel from "./LegalHoldParticipationModel";
import UserModel from "../user/UserModel";
import ClientModel from "../client/ClientModel";
import MatterModel from "../client/MatterModel";
import {NoticeCommentSaga} from "../notice/NoticeCommentModel";
import NoticeInfoModel from "../notice/NoticeInfoModel";
import {renderSubmitForm} from "../../components/common/SubmitForm/SubmitForm";
import DataRepositoryModel from "../data/DataRepositoryModel";
import PopupModel from "../scheduler/PopupModel";
import {validateParameters} from "../../utilities/shouldEnableFunctions";
import AuditLogModel from "../generics/AuditLogModel";
import {initialSelectedState} from "../../utilities/hooks";
import UserServiceModel from "../user/UserServiceModel";
import DatasetModel from "../data/DatasetModel";
import NoticeDatasetEventModel from "./NoticeDatasetEventModel";
import RelativityProxyModel from "../relativity/RelativityProxyModel";
import TriggerConfiguration from "./TriggerConfiguration";
import TriggerConfigurationEventModel from "./TriggerConfigurationEventModel";
import WorkflowTemplateModel from "../library/WorkflowTemplateModel";
import ExecutionProfileModel from "../settings/ExecutionProfileModel";
import ResourcePoolModel from "../settings/ResourcePoolModel";
import {JobSaga} from "../job/JobModel";
import ThirdPartyServiceModel from "../thirdparty/ThirdPartyServiceModel";


class LegalHoldModel extends DetailsModel {

    static nom = 'LegalHoldModel';
    static actions = LegalHoldModel.buildActions('LEGAL_HOLD');
    static actionCreators = LegalHoldModel.buildActionCreators(LegalHoldModel.actions);
    static reducer = LegalHoldModel.buildReducer(LegalHoldModel.actions);

    static componentActionCreators = {
        ...LegalHoldModel.buildComponentUpdateActionCreators(),
        ...LegalHoldModel.buildComponentSetActiveActionCreators()
    };

    constructor(model={}) {
        super(model);

        this.state = model.state;
        this.clientId = model.clientId;
        this.matterId = model.matterId;

        this.dataRepositoryId = model.dataRepositoryId;
        this.smtpServiceId = model.smtpServerId;
        this.schedulerUrl = model.schedulerUrl;
        this.enableLoginLinks = model.enableLoginLinks;
        this.surveyNoticesTrigger = model.surveyNoticesTrigger || LegalHoldModel.SurveyNoticesTrigger.ON_HOLD;
        this.emailCounter = model.emailCounter;

        this.startDate = model.startDate;
        this.endDate = model.endDate;

        this.createdBy = model.createdBy;
        this.createdDate = getLocaleDateTimeFromUTC(model.createdDate);

        this.lastChangedBy = model.lastChangedBy;
        this.lastChangedDate = getLocaleDateTimeFromUTC(model.lastChangedDate);

        this.silent = model.silent;
        this.holdNoticeConfiguration = model.holdNoticeConfiguration && new NoticeConfiguration(model.holdNoticeConfiguration);
        this.recurringNoticeConfiguration = model.recurringNoticeConfiguration && new NoticeConfiguration(model.recurringNoticeConfiguration);
        this.releaseNoticeConfiguration = model.releaseNoticeConfiguration && new NoticeConfiguration(model.releaseNoticeConfiguration);
        this.surveyNoticeConfigurations = getValues(model.surveyNoticeConfigurations).map(config => new NoticeConfiguration(config));
        this.parameters = ParameterModel.buildParametersMap(model.parameters);

        this.triggerConfigurations = getValues(model.triggerConfigurations).map(config => new TriggerConfiguration(config))
        this.taskState = model.taskState;
        this.taskPercentProgress = model.taskPercentProgress;

        this.useTriggerConfigurations = model.triggerJobs;
        this.executionProfileId = model.executionProfileId;
        this.resourcePoolId = model.resourcePoolId;
        this.priority = model.priority || priorityKeys.MEDIUM;
    }

    static State = {
        DRAFT: 'DRAFT',
        ACTIVE: 'ACTIVE',
        RELEASED: 'RELEASED',
        ARCHIVED: 'ARCHIVED',
        ERROR: 'ERROR'
    };

    static TaskState = {
        DONE: 'DONE',
        PENDING: 'PENDING',
        ERROR: 'ERROR',

        ISSUING_HOLDS: 'ISSUING_HOLDS',
        RELEASING_HOLDS: 'RELEASING_HOLDS',
        UPDATING_PARTICIPATIONS: 'UPDATING_PARTICIPATIONS',
        UPDATING_EVENTS: 'UPDATING_EVENTS',
        DISABLING_NOTICES: 'DISABLING_NOTICES',
        DELETING: 'DELETING'
    };

    static SurveyNoticesTrigger = {
        ON_HOLD: 'ON_HOLD',
        ON_HOLD_RESPONSE: 'ON_HOLD_RESPONSE'
    };

    static getNoticeConfigTemplateOptions(noticeConfig, noticeTemplate) {
        if (noticeTemplate != null && (noticeConfig.noticeId == null || noticeTemplate.version === noticeConfig.noticeTemplateVersion)) {
            return {
                name: noticeTemplate.name,
                key: `${noticeConfig.noticeTemplateId}:${noticeTemplate.version}`,
                surveyFormOptions: noticeTemplate.surveyFormOptions
            };
        }
        return {
            name: `${noticeTemplate != null ? noticeTemplate.name : noticeConfig.noticeTemplateId} (${noticeConfig.noticeTemplateVersion.slice(0, 8)})`,
            key: `${noticeConfig.noticeTemplateId}:${noticeConfig.noticeTemplateVersion}`
        }
    }

    static* buildDefaultFormState(state, props={}) {
        const respondByDate = getInputFormattedDateAndTime(1, props.utc)[0];
        const defaultNoticeConfiguration = {
            enabled: true,
            respondByDate
        };

        const defaultProfilePool = yield *JobSaga.getDefaultExecutionProfileAndResourcePool(props);

        const defaultState = {
            force: null,

            legalHoldName: '',
            description: '',
            state: legalHoldStateKeys.DRAFT,

            clientId: null,
            matterId: null,

            dataRepositoryId: null,
            smtpServiceId: null,
            schedulerUrl: window.location.origin + window.location.pathname,
            enableLoginLinks: true,
            surveyNoticesTrigger: 'ON_HOLD',

            silent: false,
            holdNoticeConfiguration: new NoticeConfiguration(defaultNoticeConfiguration),
            releaseNoticeConfiguration: new NoticeConfiguration(defaultNoticeConfiguration),
            recurringNoticeConfiguration: new NoticeConfiguration({
                enabled: false,
                respondByDate,
                sendRecurringNoticeEveryN: 3,
                recurringNoticeFrequency: recurringNoticeFrequencyKeys.MONTHS,
                respondByKey: 'respondByNDays'
            }),

            // Array of configurations
            surveyNoticeConfigurations: [],
            parameters: null,

            noticeTemplateItems: [],
            noticeTemplateItemKeyToSurveyFormOptions: {},
            useTriggerConfigurations: false,
            triggerConfigurations: [new TriggerConfiguration()],
            executionProfileId: defaultProfilePool.executionProfileId,
            resourcePoolId: defaultProfilePool.resourcePoolId,
            priority: priorityKeys.MEDIUM,

            // switchItems data struct
            administrators: {left: [], right: []},
            custodians: {left: [], right: []},

            isDisabled: false
        }

        if (state != null) {
            const administratorItems = [], custodianItems = [];
            yield pauseLoop(getValues(state.userDetailsMap), user => {
                const userService = state.userServiceDetailsMap.get(user.userServiceId) || {};
                // Active user
                if ((user.state === userAccountStateKeys.ACTIVE
                            || (user.state === userAccountStateKeys.INACTIVE && userService.includeInactiveUsers)
                            || (user.state === userAccountStateKeys.DELETED && userService.includeDeletedUsers))
                        && userService.enabled && userService.synchronizeUsers) {
                    const userItem = {
                        name: user.displayName || user.name,
                        value: user.id,
                        email: user.email
                    };
                    if (user.platform === UserModel.Platform.AZURE_AD) {
                        userItem.upn = user.name;
                    }

                    // Eligible for admin or user already selected
                    if (userService.usersEligibleLegalHoldAdministrator || (props.administratorIds != null && props.administratorIds.includes(user.id))) {
                        administratorItems.push(userItem);
                    }
                    // Eligible for custodian or user already selected
                    if (userService.usersEligibleLegalHoldCustodian || (props.custodianIds != null && props.custodianIds.includes(user.id))) {
                        custodianItems.push(userItem);
                    }
                }
            });

            return {
                ...defaultState,
                administrators: yield asyncSeparateItems(props.administratorIds || [], administratorItems),
                custodians: yield asyncSeparateItems(props.custodianIds || [], custodianItems)
            }
        }

        return defaultState;
    }

    static getRequiresDataRepository(formData, noticeTemplateDetailsMap) {
        const {silent, holdNoticeConfiguration, releaseNoticeConfiguration, surveyNoticeConfigurations} = formData;

        if (!silent) {
            for (const config of [holdNoticeConfiguration, releaseNoticeConfiguration, ...surveyNoticeConfigurations]) {
                const noticeTemplate = noticeTemplateDetailsMap.get(config.noticeTemplateId);
                if (noticeTemplate != null && noticeTemplate.surveyFormOptions.some(option => option && option.type === formElementTypes.DATA_UPLOAD)) {
                    return true;
                }
            }
        }
    }

    static validateFormData(formData, reduxState) {
        const {state, clientId, matterId, dataRepositoryId, smtpServiceId, schedulerUrl, legalHoldName, parameters, userPermissions,
            silent, holdNoticeConfiguration, releaseNoticeConfiguration, surveyNoticeConfigurations, recurringNoticeConfiguration,
            triggerConfigurations, useTriggerConfigurations, executionProfileId, resourcePoolId, priority} = formData;

        const areNoticeConfigurationsValid = [holdNoticeConfiguration, releaseNoticeConfiguration, recurringNoticeConfiguration, ...surveyNoticeConfigurations]
            .every(config => config.isValid({state}));

        const areTriggerConfigurationsValid = !useTriggerConfigurations || triggerConfigurations.every(config => config.isValid(silent));

        const requiresDataRepository = LegalHoldModel.getRequiresDataRepository(formData, reduxState.noticeTemplateDetailsMap);

        const areSettingsValid = !!legalHoldName && (state === legalHoldStateKeys.DRAFT || ((!requiresDataRepository || !!dataRepositoryId) && (silent || !!smtpServiceId)))
            && (silent || (!!schedulerUrl && validateParameters(parameters))) && (!useTriggerConfigurations || (executionProfileId != null && resourcePoolId != null && priority != null));

        const canModify = userPermissions == null || userPermissions.includes(permissionKeys.MODIFY);
        if (canModify) {
            return {
                matterPane: !!(clientId && matterId),
                noticeConfigurationsPane: silent || areNoticeConfigurationsValid,
                triggerConfigurationPane: areTriggerConfigurationsValid,
                settingsPane: areSettingsValid,
                administratorsPane: true,
                custodiansPane: true,
                submit: true
            }
        }
        return {
            custodiansPane: true,
            submit: true
        }
    }

    static buildActions(type) {
        return {
            ...super.buildActions(type),
            // LEGAL HOLD ACTIONS
            EXPORT_LEGAL_HOLD: 'EXPORT_LEGAL_HOLD',
            PREPARE_SETTINGS: 'PREPARE_LEGAL_HOLD_SETTINGS',
            PREPARE_COLLECTION_CONFIGURATION_SETTINGS: 'PREPARE_LEGAL_HOLD_COLLECTION_CONFIGURATION_SETTINGS',
            PREPARE_TRIGGER_CONFIGURATION_SETTINGS: 'PREPARE_LEGAL_HOLD_TRIGGER_CONFIGURATION_SETTINGS',

            IMPORT_CUSTODIANS: 'LEGAL_HOLD_IMPORT_CUSTODIANS',
            IMPORT_CUSTODIANS_TO_FORM: 'LEGAL_HOLD_IMPORT_CUSTODIANS_TO_FORM',

            SEND_COMMAND: 'SEND_LEGAL_HOLD_COMMAND',
            LOAD_TSV_PARAMETERS: 'LOAD_LEGAL_HOLD_TSV_PARAMETERS',
            RESET_PARAMETERS: 'RESET_LEGAL_HOLD_PARAMETERS',

            SAVE_EDIT: 'SAVE_LEGAL_HOLD_EDIT',
            CANCEL_EDIT: 'CANCEL_LEGAL_HOLD_EDIT'
        }
    }

    static buildActionCreators(actions) {
        return {
            ...super.buildActionCreators(actions),
            // LEGAL HOLD ACTION CREATORS
            exportLegalHold: actionCreator(actions.EXPORT_LEGAL_HOLD, 'id', 'exportOptions'),
            prepareTriggerConfigurationSettings: actionCreator(actions.PREPARE_TRIGGER_CONFIGURATION_SETTINGS),
            prepareSettings: actionCreator(actions.PREPARE_SETTINGS),

            importCustodians: actionCreator(actions.IMPORT_CUSTODIANS, 'id', 'text'),
            importCustodiansToForm: actionCreator(actions.IMPORT_CUSTODIANS_TO_FORM, 'text'),

            sendCommand: actionCreator(actions.SEND_COMMAND, 'id', 'command', 'args'),
            loadTsvParameters: actionCreator(actions.LOAD_TSV_PARAMETERS, 'tsvText'),
            resetParameters: actionCreator(actions.RESET_PARAMETERS),

            saveEdit: actionCreator(actions.SAVE_EDIT),
            cancelEdit: actionCreator(actions.CANCEL_EDIT)
        }
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'legalHoldPage',
                type: 'Page',
                state: {
                    activeView: legalHoldPageViewKeys.OVERVIEW
                }
            },
            {
                key: 'legalHoldDisplay',
                type: 'Display',
                state: {
                    legalHoldId: null,
                    isLegalHoldFormActive: false
                }
            },
            {
                key: 'legalHoldTablet',
                type: 'Tablet',
                state: {
                    isExportFormActive: false,
                    isDisabled: false
                }
            },
            {
                key: 'legalHoldForm',
                type: 'Form',
                state: {}
            },
            {
                key: 'legalHoldExportForm',
                type: 'ExportForm',
                state: {
                    exportCustodiansOverview: true,
                    exportDatasetsOverview: true,
                    exportNoticesOverview: true,
                    exportNoticeResponses: true,
                    exportUserNoticeHtml: true,
                    includeIcons: true,
                    includeComments: true,
                    includeAdminNotes: true,
                    includeDatasetFiles: true
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'LEGAL_HOLD_PAGE',
                type: 'Page'
            },
            {
                key: 'LEGAL_HOLD_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }
}

export class LegalHoldApi extends DetailsApi {

    static location = '/legalHold';

    static getDetails() {
        return axiosInstance.get(`/scheduler/legalHold`);
    }

    static postCommand(id, command) {
        return axiosInstance.post(`/scheduler/legalHold/${id}/command`, command);
    }

    static postParticipations(id, role, identifier, identifiers) {
        return axiosInstance.post(`/scheduler/legalHold/${id}/participations?identifier=${identifier}&role=${role}`, identifiers);
    }

    static getUserNotices(id) {
        return axiosInstance.get(`/scheduler/legalHold/${id}/userNotices`);
    }

    static getParticipations(id) {
        return axiosInstance.get(`/scheduler/legalHold/${id}/participations`);
    }

    static getCollectionEvents(id) {
        return axiosInstance.get(`/scheduler/legalHold/${id}/collectionEvents`);
    }

    static getTriggerConfigurationEvents(id) {
        return axiosInstance.get(`/scheduler/legalHold/${id}/triggerConfigurationEvents`)
    }

    static getNoticeDatasetEvents(id) {
        return axiosInstance.get(`/scheduler/legalHold/${id}/noticeDatasetEvents`);
    }

    static getNoticeInfos(id) {
        return axiosInstance.get(`/scheduler/legalHold/${id}/noticeInfos`);
    }

    static getAuditLog(id) {
        return axiosInstance.get(`/scheduler/legalHold/${id}/auditLog`);
    }
}

export class LegalHoldSaga extends DetailsSaga {

    static ModelType = LegalHoldModel;
    static ModelApi = LegalHoldApi;

    static activationComponent = 'LEGAL_HOLD_PAGE';
    static variableNames = {
        detailsMap: 'legalHoldDetailsMap',
        instanceId: 'legalHoldId',
        isFormActive: 'isLegalHoldFormActive',
        formState: 'legalHoldForm',
        updateDisplay: 'updateDisplay',
        updatePane: 'updateForm',
        route: routes.LEGAL_HOLD
    };

    static editValuesBlackList = [
        'administrators.left',
        'custodians.left'
    ]

    static translations = {
        itemTitle: '$t(legalHold:label.name)',
        itemLower: '$t(legalHold:label.name_lower)'
    };

    static buildActivationEffects(dispatch) {
        function* initializeLegalHoldDisplay() {
            // Call modelNames after respective display query returns
            yield take(LegalHoldModel.actions.SET_DETAILS_MAP);
            yield contextCall(LegalHoldSaga, 'getModelNames');
        }

        return [
            ...super.buildActivationEffects(dispatch),

            fork(initializeLegalHoldDisplay),
            // ACTIVATION EFFECTS
            fork(poll, QUERY_INTERVAL, contextSaga(this, 'getModelNames')),

            put(LegalHoldModel.actionCreators.startPollingDetails()),
            put(NoticeTemplateModel.actionCreators.startPollingDetails(SLOW_QUERY_INTERVAL)),
            put(UserModel.actionCreators.startPollingDetails(SLOW_QUERY_INTERVAL)),
            put(UserServiceModel.actionCreators.startPollingDetails(SLOW_QUERY_INTERVAL)),
            put(ThirdPartyServiceModel.actionCreators.startPollingDetails(SLOW_QUERY_INTERVAL)),

            contextTakeLatest(LegalHoldModel.actions.PREPARE_TRIGGER_CONFIGURATION_SETTINGS, this, 'prepareTriggerConfigurationSettings'),
            contextTakeLatest(LegalHoldModel.actions.PREPARE_SETTINGS, this, 'prepareSettings'),

            takeLatest(LegalHoldModel.actions.SEND_COMMAND, tryCatchWrapper, isDisabledWrapper, LegalHoldModel.componentActionCreators.updateTablet, contextSaga(this, 'sendCommand')),
            takeLatest(LegalHoldModel.actions.EXPORT_LEGAL_HOLD, tryCatchWrapper, isDisabledWrapper, LegalHoldModel.componentActionCreators.updateTablet, contextSaga(this, 'exportLegalHold')),

            takeLatest(LegalHoldModel.actions.IMPORT_CUSTODIANS, tryCatchWrapper, isDisabledWrapper, LegalHoldModel.componentActionCreators.updateTablet, contextSaga(this, 'importCustodians')),
            takeLatest(LegalHoldModel.actions.IMPORT_CUSTODIANS_TO_FORM, tryCatchWrapper, isDisabledWrapper, LegalHoldModel.componentActionCreators.updateForm, contextSaga(this, 'importCustodiansToForm')),

            takeLatest(LegalHoldModel.actions.LOAD_TSV_PARAMETERS, tryCatchWrapper, isDisabledWrapper, LegalHoldModel.componentActionCreators.updateForm, contextSaga(this, 'loadTsvParameters')),
            takeLatest(LegalHoldModel.actions.RESET_PARAMETERS, isDisabledWrapper, LegalHoldModel.componentActionCreators.updateForm, contextSaga(this, 'resetParameters')),

            contextTakeLeading(LegalHoldModel.actions.SAVE_EDIT, this, 'editSave'),
            contextTakeLeading(LegalHoldModel.actions.CANCEL_EDIT, this, 'editCancel')
        ]
    }

    static buildDeactivationEffects() {
        return [
            ...super.buildDeactivationEffects(),
            put(LegalHoldModel.actionCreators.stopPollingDetails()),
            put(NoticeTemplateModel.actionCreators.stopPollingDetails()),
            put(UserModel.actionCreators.stopPollingDetails()),
            put(UserServiceModel.actionCreators.stopPollingDetails())
        ]
    }

    static* setReduxState(args) {
        yield contextCall(this, 'initializeState');
        const {detailsMap, updateDisplay, instanceId, route} = this.variableNames;

        if (args.id != null) {
            const [legalHoldId, userNoticeId] = args.id.split('/notice?id=');

            const details = yield select(state => state[detailsMap].get(legalHoldId));
            if (details == null) {
                yield put(PopupModel.actionCreators.showError({
                    info: {
                        key: 'ModelCannotBeFound',
                        valueKeys: {
                            itemTitle: this.translations.itemTitle
                        }
                    }
                }));
            }

            yield all([
                put(this.ModelType.componentActionCreators[updateDisplay]({[instanceId]: legalHoldId})),
                put(UserNoticeModel.componentActionCreators.updateView({userNoticeId}))
            ])
        }
        yield put(LegalHoldModel.componentActionCreators.updatePage({activeView: legalHoldPageViewKeys.MATTERS}));

        if (args.cbEffect != null) {
            yield args.cbEffect;
        }

        window.location.href = `#${route}`;
    }

    static* hidePane() {
        const {updateDisplay, instanceId} = this.variableNames;

        const effect = all([
            put(this.ModelType.componentActionCreators[updateDisplay]({[instanceId]: null})),
            put(UserNoticeModel.componentActionCreators.resetView())
        ]);
        yield contextCall(NoticeCommentSaga, 'callCommentFormDiscardingEffect', effect);
    }

    static* sendCommand(action) {
        const {id, command, args} = action.payload;

        const legalHold = yield select(state => state.legalHoldDetailsMap.get(id));
        // Determine if need to show submission reset warning popup when re-issuing hold
        if (!legalHold.silent && command === legalHoldCommands.ISSUE_HOLD) {
            const idToParticipation = (yield select(state => state.legalHoldParticipationDetailsMap.get(id))).reduce((acc, curr) => {
                if (curr.role === legalHoldRoles.CUSTODIAN) {
                    acc[curr.userId] = curr;
                }
                return acc;
            }, {});

            let hasReissuedUser;
            for (const userId of args.userIds) {
                const participation = idToParticipation[userId];

                if (participation != null && participation.custodianState === custodianState.RELEASED) {
                    hasReissuedUser = true;
                    break;
                }
            }

            if (hasReissuedUser) {
                const confirmed = yield contextCall(this, 'showSaveWarning', {key: 'userNoticeReissueHoldResetsSubmission'}, {
                    confirmTitleKey: 'common:option.ok', throwError: false
                });
                if (!confirmed) return;
            }
        }

        const {data} = yield contextCall(LegalHoldApi, 'postCommand', id, {command, ...args});
        yield all([
            put(LegalHoldModel.actionCreators.updateDetails({[id]: new LegalHoldModel(data)})),
            put(LegalHoldModel.actionCreators.querySettings(id))
        ]);
    }

    static* importCustodians(action) {
        const {id, text} = action.payload;

        const userEmails = new Set(readLines(text));
        const {data} = yield contextCall(LegalHoldApi, 'postParticipations', id, legalHoldRoles.CUSTODIAN, 'email', [...userEmails]);

        const {filename, csv, addedUserIds, totalCount} = data;
        const blobUrl = window.URL.createObjectURL(new Blob([csv]));

        const {state} = yield select(state => state.legalHoldDetailsMap.get(id));
        // show csv link in success popup
        yield all([
            put(PopupModel.actionCreators.showSuccess({
                info: {
                    key: "legalHoldCustodianImport",
                    values: {
                        addedCount: addedUserIds.length,
                        totalCount
                    }
                },
                content:
                    <a href={blobUrl} download={filename} target="_blank" rel="noopener noreferrer">
                        {filename}
                    </a>,
                buttons: [{
                    titleKey: 'legalHold:option.okAndIssueHolds',
                    onClick: function () {
                        // clear blobUrl
                        window.URL.revokeObjectURL(blobUrl);

                        // Ask for confirmation
                        this.dispatch(PopupModel.actionCreators.show({
                            info: {
                                key: 'legalHoldCommandISSUE_HOLD_count',
                                values: {count: addedUserIds.length}
                            },
                            buttons: [{
                                titleKey: 'legalHold:option.ISSUE_HOLD',
                                onClick: function () {
                                    this.dispatch(LegalHoldModel.actionCreators.sendCommand(id, legalHoldCommands.ISSUE_HOLD, {userIds: addedUserIds}));
                                }.bind(this)
                            }]
                        }));
                    }.bind(this),
                    isDisabled: legalHoldStateKeys.ACTIVE !== state || addedUserIds.length === 0
                }],
                cancelButton: {
                    onClick: function () {
                        // clear blobUrl
                        window.URL.revokeObjectURL(blobUrl);
                    }
                }
            })),
            put(LegalHoldModel.actionCreators.querySettings(id))
        ]);
    }

    static* importCustodiansToForm(action) {
        const {text} = action.payload;

        const userEmails = new Set(readLines(text)
            .map(line => line.toLowerCase())
        );

        const {custodians} = yield select(state => state.componentStates.legalHoldForm);
        const newCustodians = {left: [], right: [...custodians.right]};

        for (const availableCustodian of custodians.left) {
            const custodianEmail = availableCustodian.email.trim().toLowerCase();
            if (userEmails.has(custodianEmail)) {
                newCustodians.right.push(availableCustodian);
            } else {
                newCustodians.left.push(availableCustodian);
            }
        }

        yield put(LegalHoldModel.componentActionCreators.updateForm({
            custodians: newCustodians,
            force: {
                left: {
                    selected: initialSelectedState
                }
            }
        }));
    }

    static* exportLegalHold(action) {
        const {id, exportOptions} = action.payload;

        const exportForm = yield select(state => state.componentStates.legalHoldExportForm);
        const isRelativityApplication = yield select(state => state.schedulerDetails.isRelativityApplication);

        const formData = {
            ...exportOptions,
            ...exportForm
        };

        const url = `/scheduler/legalHold/${id}/export`;
        const fields = [
            {name: 'formData', value: JSON.stringify(formData)}
        ];
        if (axiosInstance.uiToken) {
            fields.push({name: 'token', value: axiosInstance.uiToken});
        }

        if (isRelativityApplication) {
            yield put(RelativityProxyModel.actionCreators.proxyDownload(url, fields, LegalHoldModel.componentActionCreators.updateTablet))
        } else {
            renderSubmitForm(url, {fields});
        }
    }

    static* duplicate(action) {
        const {id} = action.payload;
        try {
            yield put(LegalHoldModel.componentActionCreators.updateTablet({isDisabled: true}));
            const editValues = yield contextCall(this, 'getEditValues', id);

            const legalHoldDetailsMap = yield select(state => state.legalHoldDetailsMap);
            const name = yield contextCall(this, 'getUniqueName', editValues.legalHoldName, legalHoldDetailsMap);
            // set state to DRAFT
            editValues.id = null;
            editValues.state = legalHoldStateKeys.DRAFT;
            editValues.legalHoldName = name;
            editValues.force = null;

            // notice configurations
            editValues.holdNoticeConfiguration.clearSensitive();
            editValues.releaseNoticeConfiguration.clearSensitive();
            editValues.recurringNoticeConfiguration.clearSensitive()

            for (const surveyConfig of editValues.surveyNoticeConfigurations) {
                surveyConfig.clearSensitive();
            }

            for (const triggerConfig of editValues.triggerConfigurations) {
                triggerConfig.clearSensitive();
            }

            // Track initialValues
            const {isDisabled, id: ignore, state, noticeTemplateItemKeyToSurveyFormOptions, noticeTemplateItems, force, ...formValues} = editValues;
            const initialValues = deepCopy(formValues);

            yield all([
                put(this.ModelType.actionCreators.showForm({
                    initialValues,
                    ...editValues
                })),
                put(this.ModelType.actionCreators.hideTablet())
            ]);
        } finally {
            yield put(LegalHoldModel.componentActionCreators.updateTablet({isDisabled: false}));
        }
    }

    static* loadTsvParameters(action) {
        const {tsvText} = action.payload;

        const parameters = yield select(state => new Map(state.componentStates.legalHoldForm.parameters));
        for (const [name, value] of readTsvText(tsvText)) {

            const parameter = parameters.get(name);
            if (parameter != null) {

                parameters.set(name, parameter.duplicate({
                    valid: parameter.validate(value),
                    value
                }));
            }
        }

        yield put(LegalHoldModel.componentActionCreators.updateForm({parameters}));
    }

    static* resetParameters() {
        yield put(LegalHoldModel.componentActionCreators.updateForm({parameters: new Map()}));
        yield contextCall(this, 'prepareSettings');
    }

    static* prepareSettings() {
        const [legalHoldForm, noticeTemplateDetailsMap] = yield select(state => [state.componentStates.legalHoldForm, state.noticeTemplateDetailsMap]);
        const {
            holdNoticeConfiguration,
            releaseNoticeConfiguration,
            surveyNoticeConfigurations,
            recurringNoticeConfiguration,
            parameters
        } = legalHoldForm;

        const updates = {
            parameters: new Map(),
            requiresDataRepository: LegalHoldModel.getRequiresDataRepository(legalHoldForm, noticeTemplateDetailsMap)
        };

        // Loop through each config and build required
        for (const config of [holdNoticeConfiguration, releaseNoticeConfiguration, recurringNoticeConfiguration, ...surveyNoticeConfigurations]) {
            let ids = [
                config.noticeTemplateId,
                config.requireResponse && config.enableRemind && config.reminderNoticeTemplateId,
                config.requireResponse && config.escalateOnResponseFail && config.escalateNoticeTemplateId
            ];

            const parameterDisabled = (config.noticeTemplateVersion != null)
            for (const id of ids) {
                const template = noticeTemplateDetailsMap.get(id);
                if (template != null && Array.isArray(template.parameters)) {
                    const templateParameters = template.parameters;
                    for (const param of templateParameters) {

                        if (parameters == null || !parameters.has(param.name)) {
                            updates.parameters.set(param.name, param.duplicate({valid: param.validate()}));
                        } else {

                            let oldParam;
                            if (legalHoldForm.state === legalHoldStateKeys.DRAFT) {
                                oldParam = param;
                            } else {
                                oldParam = parameters.get(param.name);
                            }

                            const newParam = oldParam.duplicate({value: parameters.get(param.name).value});
                            newParam.valid = newParam.validate();
                            newParam.disabled = parameterDisabled || updates.parameters.has(param.name) && updates.parameters.get(param.name).disabled;

                            updates.parameters.set(param.name, newParam);
                        }
                    }
                }
            }
        }

        yield put(LegalHoldModel.componentActionCreators.updateForm(updates));
    }

    static* prepareTriggerConfigurationSettings() {
        const [legalHoldForm, noticeTemplateDetailsMap] = yield select(state => [state.componentStates.legalHoldForm, state.noticeTemplateDetailsMap]);

        const {holdNoticeConfiguration, releaseNoticeConfiguration, recurringNoticeConfiguration, surveyNoticeConfigurations} = legalHoldForm;
        const updates = {
            noticeTemplateItemKeyToSurveyFormOptions: {}
        };
        // Build list of possible noticeTemplate items for linked collections + their surveyFormOptions
        const items = {};
        for (const noticeConfig of [holdNoticeConfiguration, releaseNoticeConfiguration, recurringNoticeConfiguration, ...surveyNoticeConfigurations]) {

            const noticeTemplate = noticeTemplateDetailsMap.get(noticeConfig.noticeTemplateId);
            if (noticeConfig.noticeId == null && noticeTemplate == null) {
                continue;
            }

            let {name, key, surveyFormOptions} = LegalHoldModel.getNoticeConfigTemplateOptions(noticeConfig, noticeTemplate);
            if (items[key] == null) {
                if (surveyFormOptions == null) {
                    surveyFormOptions = (yield contextCall(NoticeTemplateApi, 'getSurveyFormOptions', noticeConfig.noticeTemplateId, noticeConfig.noticeTemplateVersion)).data;
                }

                if (Array.isArray(surveyFormOptions)) {
                    surveyFormOptions = surveyFormOptions
                        .filter(opt => opt.enabled && (opt.type === formElementTypes.CHECKBOX || opt.type === formElementTypes.DROPDOWN))
                        .reduce((acc, opt) => {
                            acc[opt.key] = opt;
                            return acc;
                        }, {});

                    if (isNotEmptyNorFalsy(surveyFormOptions)) {
                        items[key] = {name, value: key};
                        updates.noticeTemplateItemKeyToSurveyFormOptions[key] = surveyFormOptions;
                    }
                }
            }
        }

        updates.noticeTemplateItems = getValues(items).sort(nameLocaleCompare);
        yield put(LegalHoldModel.componentActionCreators.updateForm(updates));
    }

    static* submitForm(action) {
        const formData = {
            ...(yield select(state => state.componentStates.legalHoldForm)),
            ...action.payload.formData
        };
        const legalHold = yield contextCall(this, 'getSaveValues', formData);

        const {data} = yield contextCall(LegalHoldApi, 'post', legalHold);
        yield put(LegalHoldModel.actionCreators.addDetails(data));

        yield all([
            put(LegalHoldModel.actionCreators.hideForm()),
            put(LegalHoldModel.actionCreators.showTablet(data.id))
        ]);
    }

    static* startEdit(action) {
        this.startEditDate = new Date();

        const {id} = action.payload;
        let editValues;

        yield call(isDisabledWrapper, LegalHoldModel.componentActionCreators.updateTablet, function* () {
            editValues = yield contextCall(this, 'getEditValues', id);
            yield all([
                put(this.ModelType.actionCreators.showForm(editValues)),
                put(this.ModelType.actionCreators.hideTablet())
            ]);
        }.bind(this));

        yield all([
            put(EditModel.actionCreators.start(this.ModelType.nom, editValues)),
            retryCall(contextSaga(this, 'waitForEditAction'), id, editValues)
        ]);

        yield put(EditModel.actionCreators.reset());
    }

    static* saveEdit(id) {
        const legalHoldForm = yield select(state => state.componentStates.legalHoldForm);

        try {
            const legalHold = yield contextCall(this, 'getSaveValues', legalHoldForm);
            const {data} = yield contextCall(this.ModelApi, 'putDetails', id, legalHold);

            yield all([
                put(this.ModelType.actionCreators.updateDetails({[id]: new this.ModelType(data)})),
                put(EditModel.actionCreators.saveSuccess())
            ]);
        } catch (error) {
            yield put(EditModel.actionCreators.saveFail());
            throw error;
        }
    }

    static* editSave() {
        const {id} = yield select(state => state.componentStates.legalHoldForm);
        yield put(EditModel.actionCreators.save());

        const [success] = yield race([
            take(EditModel.actions.SAVE_SUCCESS),
            take(EditModel.actions.SAVE_FAIL)
        ]);

        if (success) {
            yield put(this.ModelType.componentActionCreators.updateDisplay({isLegalHoldFormActive: false, legalHoldId: id}));
        }
    }

    static* editCancel() {
        const {id} = yield select(state => state.componentStates.legalHoldForm);
        yield put(EditModel.actionCreators.cancel());

        yield put(this.ModelType.componentActionCreators.updateDisplay({isLegalHoldFormActive: false, legalHoldId: id}));
    }

    static* getEditValues(id) {
        const {name: legalHoldName, createdBy, createdDate, lastChangedBy, lastChangedDate, state, taskState, taskPercentProgress,
            parameters, holdNoticeConfiguration, releaseNoticeConfiguration, surveyNoticeConfigurations, recurringNoticeConfiguration,
            triggerConfigurations, userPermissions, emailCounter, ...rest} = yield select(state => state.legalHoldDetailsMap.get(id));

        const hasLoaded = yield select(state => state.hasLoaded[id]);
        if (!hasLoaded) {
            yield take(LegalHoldParticipationModel.actions.SET_SETTING);
        }

        const participations = yield select(state => state.legalHoldParticipationDetailsMap.get(id));
        const canModify = userPermissions != null && userPermissions.includes(permissionKeys.MODIFY);
        // Check if client/matter/smtp/dataRepo exists
        const objectIds = yield select(state => {
            if (canModify) {
                return {
                    clientId: getMapValueIdIfExists(state.clientDetailsMap, rest.clientId),
                    matterId: getMapValueIdIfExists(state.matterDetailsMap, rest.matterId),
                    smtpServiceId: getMapValueIdIfExists(state.thirdPartyServiceDetailsMap, rest.smtpServiceId),
                    dataRepositoryId: getMapValueIdIfExists(state.dataRepositoryDetailsMap, rest.dataRepositoryId)
                }
            }

            return {
                clientId: rest.clientId,
                matterId: rest.matterId,
                smtpServiceId: rest.smtpServiceId,
                dataRepositoryId: rest.dataRepositoryId
            }
        });

        const administratorIds = [], custodianIds = [];
        yield pauseLoop(participations, participation => {
            switch (participation.role) {
                case legalHoldRoles.ADMINISTRATOR:
                    administratorIds.push(participation.userId);
                    break;
                case legalHoldRoles.CUSTODIAN:
                    custodianIds.push(participation.userId);
                    break;
            }
        });

        const params = new Map();
        for (const [name, param] of parameters) {
            params.set(name, param.duplicate({valid: param.validate()}));
        }

        const defaultFormState = yield LegalHoldModel.buildDefaultFormState(yield select(), {...objectIds, administratorIds, custodianIds, utc: this.startEditDate});
        const editValues = {
            id,
            ...defaultFormState,
            legalHoldName,
            state,
            holdNoticeConfiguration: holdNoticeConfiguration.getEditValues(),
            releaseNoticeConfiguration: releaseNoticeConfiguration.getEditValues(),
            surveyNoticeConfigurations: surveyNoticeConfigurations.map(config => config.getEditValues()),
            recurringNoticeConfiguration: recurringNoticeConfiguration.getEditValues(),
            triggerConfigurations: triggerConfigurations.map(config => config.getEditValues()),
            requiresDataRepository: objectIds.dataRepositoryId != null,
            parameters: params,
            ...rest,
            ...objectIds,
            userPermissions
        };

        if (state === legalHoldStateKeys.ACTIVE && canModify) {
            editValues.force = {
                position: rest.silent ? 2 : 1
            };
        }

        return editValues;
    }

    static* getSaveValues(values) {
        const {
            id,
            state,
            clientId,

            legalHoldName: name,

            holdNoticeConfiguration,
            releaseNoticeConfiguration,
            surveyNoticeConfigurations,
            recurringNoticeConfiguration,

            triggerConfigurations,
            useTriggerConfigurations,
            executionProfileId,
            resourcePoolId,
            smtpServiceId,
            priority,

            parameters,
            administrators,
            custodians,

            requiresDataRepository,
            force,
            isDisabled,
            ...rest
        } = values;

        const saveValues = {
            name,

            smtpServerId: smtpServiceId,
            holdNoticeConfiguration: holdNoticeConfiguration.getSaveValues(),
            releaseNoticeConfiguration: releaseNoticeConfiguration.getSaveValues(),
            recurringNoticeConfiguration: recurringNoticeConfiguration.getSaveValues(),
            surveyNoticeConfigurations: surveyNoticeConfigurations.reduce((acc, config) => {
                acc[config.id] = config.getSaveValues();
                return acc;
            }, {}),

            triggerJobs: useTriggerConfigurations,
            ["triggerConfigurations"]: triggerConfigurations.reduce((acc, config) => {
                acc[config.id] = config.getSaveValues();
                return acc;
            }, {}),

            parameters: getValues(parameters).reduce((acc, curr) => {
                acc[curr.name] = curr;
                return acc;
            }, {}),
            administratorIds: administrators.right.map(item => item.value),
            custodianIds: custodians.right.map(item => item.value),
            ...rest
        };

        if (useTriggerConfigurations) {
            saveValues["executionProfileId"] = executionProfileId;
            saveValues["resourcePoolId"] = resourcePoolId;
            saveValues["priority"] = priority
        }

        const oldLegalHold = yield select(state => state.legalHoldDetailsMap.get(id));
        const isActive = oldLegalHold != null && oldLegalHold.state === legalHoldStateKeys.ACTIVE;
        if (isActive) {
            // Check if configurations modified
            const {holdNoticeConfiguration: oldHoldConfig, releaseNoticeConfiguration: oldReleaseConfig, recurringNoticeConfiguration: oldRecurringConfig, surveyNoticeConfigurations: oldSurveyConfigs, triggerConfigurations: oldTriggerConfigurations} = oldLegalHold;

            if (holdNoticeConfiguration.wasEnabledDisabled(oldHoldConfig) || releaseNoticeConfiguration.wasEnabledDisabled(oldReleaseConfig) || recurringNoticeConfiguration.wasEnabledDisabled(oldRecurringConfig)
                || surveyNoticeConfigurations.some((surveyConfig, index) => surveyConfig.wasEnabledDisabled(oldSurveyConfigs[index]))) {

                yield contextCall(this, 'showSaveWarning', {key: popupInfoKeys.LEGAL_HOLD_ACTIVE_CONFIG_CHANGE_WARNING});
            }

            const custodianParticipations = yield select(state => state.legalHoldParticipationDetailsMap.get(id)
                .filter(participation => participation.role === legalHoldRoles.CUSTODIAN)
            );
            const custodianIds = custodianParticipations.reduce((acc, participation) => {
                acc[participation.userId] = true;
                return acc;
            }, {});

            // Check for change in custodianParticipations (length =/= OR id doesn't have participation
            let requireCustodiansWarning = custodianParticipations.length !== saveValues.custodianIds.length || saveValues.custodianIds.some(id => custodianIds[id] == null);
            if (requireCustodiansWarning) {
                yield contextCall(this, 'showSaveWarning', {key: oldLegalHold.silent ? 'addingCustodiansToActiveSilentLegalHold' : 'addingCustodiansToActiveLegalHold'});
            }

            if (triggerConfigurations.some((triggerConfig, index) => triggerConfig.wasEnabledDisabled(oldTriggerConfigurations[index]))) {
                yield contextCall(this, 'showSaveWarning', {key: 'legalHoldActiveTriggerConfigChangeWarning'});
            }
        }

        return saveValues;
    }

    static* queryDetails() {
        const response =  yield contextCall(LegalHoldApi, 'getDetails');
        const key = details.LEGAL_HOLDS;

        if (AxiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(LegalHoldModel.actionCreators.setDetailsMap(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }

    static* querySettings(action) {
        const {id} = action.payload;
        if (id == null)
            return;

        const responses = yield all([
            contextCall(LegalHoldApi, 'getUserNotices', id),
            contextCall(LegalHoldApi, 'getParticipations', id),
            contextCall(LegalHoldApi, 'getTriggerConfigurationEvents', id),
            contextCall(LegalHoldApi, 'getNoticeDatasetEvents', id),
            contextCall(LegalHoldApi, 'getNoticeInfos', id),
            contextCall(LegalHoldApi, 'getAuditLog', id)
        ]);

        if (AxiosProxy.shouldUpdate(id, responses)) {
            yield all([
                put(UserNoticeModel.actionCreators.setSubsetDetailsMap(id, responses[0].data)),
                put(LegalHoldParticipationModel.actionCreators.setSetting(id, responses[1].data.participations)),
                put(TriggerConfigurationEventModel.actionCreators.setSetting(id, responses[2].data)),
                put(NoticeDatasetEventModel.actionCreators.setSetting(id, responses[3].data)),
                put(NoticeInfoModel.actionCreators.setSetting(id, responses[4].data)),
                put(AuditLogModel.actionCreators.setSetting(id, responses[5].data))
            ]);
            yield put(ReduxStateModel.actionCreators.setHasLoaded(id));
        }

        yield this.querySettingsModelNames(id);
    }

    static* getModelNames() {
        const legalHoldDetailsMap = yield select(state => state.legalHoldDetailsMap);

        const idToType = {};
        for (const legalHold of getValues(legalHoldDetailsMap)) {
            if (legalHold.clientId) {
                idToType[legalHold.clientId] = ClientModel.nom;
            }
            if (legalHold.matterId) {
                idToType[legalHold.matterId] = MatterModel.nom;
            }
            if (legalHold.dataRepositoryId) {
                idToType[legalHold.dataRepositoryId] = DataRepositoryModel.nom;
            }
            if (legalHold.smtpServiceId) {
                idToType[legalHold.smtpServiceId] = ThirdPartyServiceModel.nom;
            }
            if (isNotEmptyNorFalsy(legalHold.triggerConfigurations)) {
                legalHold.triggerConfigurations.forEach(triggerConfiguration => {
                    idToType[triggerConfiguration.workflowTemplateId] = WorkflowTemplateModel.nom;
                })
            }
            if (legalHold.executionProfileId) {
                idToType[legalHold.executionProfileId] = ExecutionProfileModel.nom
            }
            if (legalHold.resourcePoolId) {
                idToType[legalHold.resourcePoolId] = ResourcePoolModel.nom
            }
        }

        const typeToUpdate = yield contextCall(this, 'queryModelNames', idToType);
        const updateActionMap = {
            [ClientModel.nom]: ClientModel.actionCreators.updateDetails,
            [MatterModel.nom]: MatterModel.actionCreators.updateDetails,
            [DataRepositoryModel.nom]: DataRepositoryModel.actionCreators.updateDetails,
            [ThirdPartyServiceModel.nom]: ThirdPartyServiceModel.actionCreators.updateDetails,
            [ExecutionProfileModel.nom]: ExecutionProfileModel.actionCreators.updateDetails,
            [ResourcePoolModel.nom]: ResourcePoolModel.actionCreators.updateDetails,
            [WorkflowTemplateModel.nom]: WorkflowTemplateModel.actionCreators.updateDetails,
        };

        const updateEffects = [];
        getEntries(typeToUpdate)
            .forEach(([type, updates]) => {

                const updateAction = updateActionMap[type](updates);
                updateEffects.push(
                    put(updateAction)
                );
            });

        yield all(updateEffects);
    }

    static* querySettingsModelNames(legalHoldId) {
        const [triggerConfigurationEvents, noticeDatasetEvents] = yield select(state => [state.triggerConfigurationEventDetailsMap.get(legalHoldId), state.noticeDatasetEventDetailsMap.get(legalHoldId)]);

        const idToType = {};
        if (triggerConfigurationEvents != null) {
            for (const event of triggerConfigurationEvents) {
                idToType[event.workflowTemplateId] = WorkflowTemplateModel.nom;
            }
        }

        if (noticeDatasetEvents != null) {
            for (const event of getValues(noticeDatasetEvents).flat()) {
                idToType[event.datasetId] = DatasetModel.nom;
            }
        }

        const typeToUpdate = yield contextCall(this, 'queryModelNames', idToType);
        const updateActionMap = {
            [WorkflowTemplateModel.nom]: WorkflowTemplateModel.actionCreators.updateDetails,
            [DatasetModel.nom]: DatasetModel.actionCreators.updateDetails
        };

        const updateEffects = [];
        getEntries(typeToUpdate)
            .forEach(([type, updates]) => {

                const updateAction = updateActionMap[type](updates);
                updateEffects.push(
                    put(updateAction)
                );
            });

        yield all(updateEffects);
    }
}

export default LegalHoldModel;