import React, {useRef, useState} from "react"
import {useTranslation} from "react-i18next";
import {
    createCloseHandler,
    createInputHandler,
    createNestedUpdateState,
    createStateHandler
} from "../../utilities/componentFunctions";
import {useDispatch, useSelector} from "react-redux";
import DataRepositoryModel, {DataRepositorySaga} from "../../models/data/DataRepositoryModel";
import PopupModel from "../../models/scheduler/PopupModel";
import Form, {FormHeader} from "../common/Form/Form";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import TextArea from "../common/TextArea/TextArea";
import {ToggleSwitch} from "../common/ToggleSwitch/ToggleSwitch";
import {createToggleHandler} from "../common/Checkbox/helpers";
import {ButtonGroup} from "../common/Button/Button";
import SchedulerModel from "../../models/scheduler/SchedulerModel";
import {contextCall} from "../../saga/sagaFunctions";
import {datasetType} from "../../utilities/constants";
import {useClearOnFirstCallback} from "../../utilities/formHooks";
import {Label} from "../common/Text/Text";


function DataRepositoryForm() {
    const dispatch = useDispatch();
    const {t} = useTranslation(['dataRepository', 'common']);

    const {
        type,
        isDisabled
    } = useSelector(state => state.componentStates.dataRepositoryForm);

    const isManaged = type === datasetType.MANAGED;
    const isInPlace = type === datasetType.IN_PLACE;
    const isAzure = type === datasetType.AZURE_STORE_ACCOUNT;

    const accountKeyRef = useRef();
    const [state, setState] = useState({
        dataRepositoryName: '',
        description: '',
        path: '',

        azureStorageAccount: {
            accountUrl: '',
            accountName: ''
        },

        // Tracked in GB
        quota: 100,
        quotaEnabled: true,
        datasetMaxSize: 20,
        datasetMaxSizeEnabled: false,
        uploadMaxSize: 5,
        uploadMaxSizeEnabled: false,
        allowedFileExtensions: '',
        allowedFileExtensionsEnabled: false,
        computeFileSystemUsableSpace: true,
        // Track in hours
        datasetAutoExpireInterval: 12,
        datasetAutoExpireIntervalEnabled: false,
        datasetAutoHide: true,
        datasetAutoArchive: true,
        datasetAutoArchiveJobWarning: true,
        datasetAutoArchiveJobError: false,
        datasetAutoArchiveJobSoftError: false
    });

    const stateHandler = createStateHandler({
        updateState: setState
    });
    const inputHandler = createInputHandler({
        handler: stateHandler
    });
    const toggleHandler = createToggleHandler({
        handler: stateHandler
    });


    function onTest() {
        dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(
            contextCall(DataRepositorySaga, 'testRepositoryUsableSpace', {...state, type})
        ));
    }

    function onSubmit() {
        const formData = {...state, type};
        dispatch(DataRepositoryModel.actionCreators.submitForm(formData));
    }

    const onClose = createCloseHandler({
        t,
        item: t('dataRepository:label.name_simple'),
        values: state,
        blacklist: ['quota', 'datasetMaxSize', 'uploadMaxSize', 'datasetAutoExpireInterval'],
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        close: () => dispatch(DataRepositoryModel.componentActionCreators.updateDisplay({isDataRepositoryFormActive: false}))
    });


    const {
        dataRepositoryName,
        description,
        path,

        azureStorageAccount,

        quota,
        quotaEnabled,
        datasetMaxSize,
        datasetMaxSizeEnabled,
        uploadMaxSize,
        uploadMaxSizeEnabled,
        allowedFileExtensions,
        allowedFileExtensionsEnabled,
        computeFileSystemUsableSpace,
        datasetAutoExpireInterval,
        datasetAutoExpireIntervalEnabled,
        datasetAutoHide,
        datasetAutoArchive,
        datasetAutoArchiveJobWarning,
        datasetAutoArchiveJobError,
        datasetAutoArchiveJobSoftError
    } = state;

    let formSubmitText;
    if (isAzure) {
        formSubmitText = t('common:button.addAzureAccount');
    } else {
        formSubmitText = t('common:button.addDataRepository');
    }

    const computeFileSystemInvalid = (!quotaEnabled && !computeFileSystemUsableSpace);
    const quotaInvalid = !quota || quota < 0;
    const datasetMaxSizeInvalid = !datasetMaxSize || datasetMaxSize < 0 || (quotaEnabled && (parseInt(datasetMaxSize) > quota));
    const uploadMaxSizeInvalid = !uploadMaxSize || uploadMaxSize < 0 || (datasetMaxSizeEnabled && parseInt(uploadMaxSize) > datasetMaxSize) || (quotaEnabled && (parseInt(uploadMaxSize) > quota));
    const disabled = isDisabled ? ' is-disabled' : ''

    const isAddEnabled = DataRepositoryModel.validateFormData({...state, type, accountKey: accountKeyRef.current && accountKeyRef.current.value});

    return (
        <Form onClose={onClose} isDisabled={isDisabled} closeButtonAriaLabel={t('dataRepository:option.closeForm')}
            header={
                <FormHeader text={t(`dataRepository:label.name_${type}`)} iconName={`dataRepository${type}`}
                    isDisabled={isDisabled}
                />
            }

            body={
                <>
                    <div className="display-input">
                        <HTMLTextInput label={t('common:label.name')} name={'dataRepositoryName'} value={dataRepositoryName} onChange={inputHandler}
                            isDisabled={isDisabled} isRequired
                        />
                    </div>

                    {!isAzure &&
                    <div className="display-input">
                        <HTMLTextInput label={t('dataRepository:label.path')} name={'path'} value={path} onChange={inputHandler}
                            isDisabled={isDisabled} isRequired
                        />
                    </div>
                    }

                    <div className="display-input">
                        <TextArea text={t('common:label.description')} name={'description'} value={description} onChange={inputHandler}
                            isDisabled={isDisabled}
                        />
                    </div>

                    {isManaged &&
                    <>
                        <div className="display-input">
                            <Label id="dataRepository:quotaLabel" htmlFor={'dataRepositoryQuotaInput'}
                                value={t('dataRepository:label.quota')} isDisabled={isDisabled}/>

                            <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                <ToggleSwitch id="quotaEnabled" aria-labelledby="dataRepository:quotaLabel" name={'quotaEnabled'}
                                    checked={quotaEnabled} onClick={toggleHandler} isInvalid={computeFileSystemInvalid} isDisabled={isDisabled}
                                />

                                <HTMLTextInput id={'dataRepositoryQuotaInput'} name={'quota'} value={quota} onChange={inputHandler}
                                    type="number" min={1} style={{width: '8rem', margin: '0 0.5rem 0 1rem'}}
                                    isInvalid={quotaInvalid} isDisabled={!quotaEnabled || isDisabled}
                                />

                                <span className={'label' + disabled}>
                                    GB
                                </span>
                            </div>
                        </div>

                        <div className="display-input">
                            <Label id="dataRepository:datasetMaxSizeLabel" htmlFor={'dataRepositoryDatasetMaxSizeInput'}
                                value={t('dataRepository:label.datasetMaxSize')} isDisabled={isDisabled}/>

                            <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                <ToggleSwitch id="datasetMaxSizeEnabled" aria-labelledby="dataRepository:datasetMaxSizeLabel" name={'datasetMaxSizeEnabled'}
                                    checked={datasetMaxSizeEnabled} onClick={toggleHandler} isDisabled={isDisabled}
                                />

                                <HTMLTextInput id={'dataRepositoryDatasetMaxSizeInput'} name={'datasetMaxSize'} value={datasetMaxSize} onChange={inputHandler}
                                    type="number" min={1} style={{width: '8rem', margin: '0 0.5rem 0 1rem'}}
                                    isInvalid={datasetMaxSizeInvalid} isDisabled={!datasetMaxSizeEnabled || isDisabled}
                                />

                                <span className={'label' + disabled}>
                                    GB
                                </span>
                            </div>
                        </div>

                        <div className="display-input">
                            <Label id="dataRepository::uploadMaxSizeLabel" htmlFor={'dataRepositoryUploadMaxSizeInput'}
                                value={t('dataRepository:label.uploadMaxSize')} isDisabled={isDisabled}/>

                            <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                <ToggleSwitch id="uploadMaxSizeEnabled" aria-labelledby="dataRepository::uploadMaxSizeLabel" name={'uploadMaxSizeEnabled'}
                                    checked={uploadMaxSizeEnabled} onClick={toggleHandler} isDisabled={isDisabled}
                                />

                                <HTMLTextInput id={'dataRepositoryUploadMaxSizeInput'} name={'uploadMaxSize'} value={uploadMaxSize} onChange={inputHandler}
                                    type="number" min={1} style={{width: '8rem', margin: '0 0.5rem 0 1rem'}}
                                    isInvalid={uploadMaxSizeInvalid} isDisabled={!uploadMaxSizeEnabled || isDisabled}
                                />

                                <span className={'label' + disabled}>
                                    GB
                                </span>
                            </div>
                        </div>

                        <div className="display-input">
                            <Label id="dataRepository:allowedFileExtensionsLabel" htmlFor="dataRepositoryAllowedFileExtensionsTextArea"
                                value={t('dataRepository:label.allowedFileExtensions')} isDisabled={isDisabled}/>

                            <div style={{display: 'flex'}}>
                                <ToggleSwitch id="allowedFileExtensionsEnabled" aria-labelledby="dataRepository:allowedFileExtensionsLabel" name={'allowedFileExtensionsEnabled'}
                                    checked={allowedFileExtensionsEnabled} onClick={toggleHandler} style={{marginRight: '1rem'}} isDisabled={isDisabled}
                                />

                                <TextArea id={'dataRepositoryAllowedFileExtensionsTextArea'} name={'allowedFileExtensions'} value={allowedFileExtensions}
                                    aria-labelledby="dataRepository:allowedFileExtensionsLabel" expandContainer onChange={inputHandler}
                                    rows={2} placeholder={'pst; zip'} isDisabled={!allowedFileExtensionsEnabled || isDisabled}
                                />
                            </div>
                        </div>

                        <div className="display-input">
                            <ToggleSwitch id="computeFileSystemUsableSpace" name={'computeFileSystemUsableSpace'} checked={computeFileSystemUsableSpace}
                                text={t('dataRepository:label.computeFileSystemUsableSpace')} labelStyle={{marginLeft: '1rem'}}
                                onClick={toggleHandler} isInvalid={computeFileSystemInvalid} isDisabled={isDisabled} isWordWrap={false}/>
                        </div>
                    </>
                    }

                    {(isManaged || isInPlace) &&
                        <>
                            <div className="display-input">
                                <ToggleSwitch id="datasetAutoHide" name={'datasetAutoHide'} checked={datasetAutoHide}
                                    text={t('dataRepository:message.datasetAutoHide')} labelStyle={{marginLeft: '1rem'}}
                                    onClick={toggleHandler} isDisabled={isDisabled} isWordWrap={false}
                                />
                            </div>

                            <div className="display-input">
                                <ToggleSwitch id="datasetAutoArchive" name={'datasetAutoArchive'} checked={datasetAutoArchive}
                                    text={t('dataRepository:message.datasetAutoArchive')} labelStyle={{marginLeft: '1rem'}}
                                    onClick={toggleHandler} isDisabled={isDisabled} isWordWrap={false}
                                />
                            </div>

                            <div className="display-input">
                                <ToggleSwitch id="datasetAutoArchiveJobWarning" name={'datasetAutoArchiveJobWarning'} checked={datasetAutoArchiveJobWarning}
                                    text={t('dataRepository:message.datasetAutoArchiveJobWarning')} labelStyle={{marginLeft: '1rem'}}
                                    onClick={toggleHandler} isDisabled={isDisabled} isWordWrap={false}
                                />
                            </div>

                            <div className="display-input">
                                <ToggleSwitch id="datasetAutoArchiveJobSoftError" name={'datasetAutoArchiveJobSoftError'} checked={datasetAutoArchiveJobSoftError}
                                    text={t('dataRepository:message.datasetAutoArchiveJobSoftError')} labelStyle={{marginLeft: '1rem'}}
                                    onClick={toggleHandler} isDisabled={isDisabled} isWordWrap={false}
                                />
                            </div>

                            <div className="display-input">
                                <ToggleSwitch id="datasetAutoArchiveJobError" name={'datasetAutoArchiveJobError'} checked={datasetAutoArchiveJobError}
                                    text={t('dataRepository:message.datasetAutoArchiveJobError')} labelStyle={{marginLeft: '1rem'}}
                                    onClick={toggleHandler} isDisabled={isDisabled} isWordWrap={false}
                                />
                            </div>

                            <div style={{display: 'inline-flex', alignItems: 'center', marginTop: '-0.25rem', marginBottom: '1rem'}}>
                                <ToggleSwitch id="autoExpireIntervalEnabled" name={'datasetAutoExpireIntervalEnabled'} checked={datasetAutoExpireIntervalEnabled}
                                    text={t('dataRepository:message.datasetAutoExpireInterval')} labelStyle={{marginLeft: '1rem'}}
                                    onClick={toggleHandler} isDisabled={isDisabled} isWordWrap={false}
                                />

                                <HTMLTextInput id={'datasetAutoExpireInterval'} name={'datasetAutoExpireInterval'} value={datasetAutoExpireInterval}
                                    onChange={inputHandler} type="number" style={{width: 'calc(8rem)', margin: '0 0.5rem 0 1rem'}}
                                    isInvalid={datasetAutoExpireInterval < 0} isDisabled={!datasetAutoExpireIntervalEnabled || isDisabled}
                                />

                                <label htmlFor={'datasetAutoExpireInterval'} className={'label' + disabled}>
                                    {t('dataRepository:label.hours')}
                                </label>
                            </div>
                        </>
                    }

                    {isAzure &&
                        <AzureStorageAccountFormBody azureStorageAccount={azureStorageAccount} accountKeyRef={accountKeyRef}
                            updateState={setState} isDisabled={isDisabled}/>
                    }
                </>
            }

            footer={
                <ButtonGroup buttons={[{
                    id: 'formFooterBackButton',
                    label: t('common:option.cancel'),
                    onClick: onClose,
                    isDisabled
                }, isManaged && {
                    id: 'formTestButton',
                    label: t('dataRepository:option.checkSpace'),
                    onClick: onTest,
                    isDisabled: isDisabled || !path || !computeFileSystemUsableSpace
                }, {
                    id: 'formFooterNextButton',
                    label: formSubmitText,
                    onClick: onSubmit,
                    isDisabled: isDisabled || !isAddEnabled
                }]}/>
            }
        />
    )
}

export function AzureStorageAccountFormBody(props) {
    const {t} = useTranslation(['dataRepository']);

    const {
        azureStorageAccount,
        accountKeyRef,

        updateState,
        isDisabled
    } = props;

    const clearOnFirstCallback = useClearOnFirstCallback();
    const azureStorageAccountHandler = createStateHandler({
        updateState: createNestedUpdateState(updateState, ['azureStorageAccount']),
        passwordOptions: {
            'accountKey': {
                ref: accountKeyRef,
                resetNames: ['accountUrl', 'accountName']
            }
        }
    });
    const azureStorageAccountInputHandler = createInputHandler({
        handler: azureStorageAccountHandler
    });

    return (
        <>
            <div className="display-input">
                <HTMLTextInput label={t(`dataRepository:label.storageAccountUrl`)} name={'accountUrl'} value={azureStorageAccount.accountUrl} onChange={azureStorageAccountInputHandler}
                    placeholder={'https://<storage-account>.blob.core.windows.net'} isDisabled={isDisabled} isRequired/>
            </div>

            <div className="display-input">
                <HTMLTextInput label={t('dataRepository:label.accountName')} name={'accountName'} value={azureStorageAccount.accountName}
                    onChange={azureStorageAccountInputHandler} isDisabled={isDisabled} isRequired/>
            </div>

            <div className="display-input">
                <HTMLTextInput label={t('dataRepository:label.accountKey')} name={'accountKey'} type={'password'} inputRef={accountKeyRef}
                    onChange={azureStorageAccountInputHandler} defaultValue={azureStorageAccount.accountKey} onBeforeInput={clearOnFirstCallback}
                    isDisabled={isDisabled} isRequired/>
            </div>
        </>
    )
}

export default DataRepositoryForm