import React from 'react';
import {stringToBool} from "../../../utilities/helperFunctions";
import {useTranslation} from "react-i18next";
import {TableEnableToggleCell, TableLabelCell} from "../../common/CustomTable/CustomTable";

function AccessibilityOptions(props) {
    const {setting: accessibility, updateSetting} = props;

    function setAccessibilityOption(event) {
        const {name: setting, checked} = event.currentTarget.dataset;
        updateSetting(setting, stringToBool(checked));
    }

    return (
        <div className="options-table display-input">
            <div className="table-header-group">
                <div className="table-header"/>
                <div className="table-header"/>
            </div>

            <div className="table-row-group">
                {Object
                    .keys(accessibility)
                    .map(key =>
                        <AccessibilityOptionRow key={key} name={key} enabled={accessibility[key]}
                            setShowDisabledItemSetting={setAccessibilityOption}/>
                    )
                }
            </div>
        </div>
    )
}

function AccessibilityOptionRow(props) {
    const {name, enabled, setShowDisabledItemSetting} = props;
    const {t} = useTranslation(['showDisabledItems']);

    return (
        <div className="options-row">
            <TableEnableToggleCell ariaLabelKey={'AccessibilityOption'} displayName={t(`accessibility:option.${name}`)}
                name={name} checked={enabled} onClick={setShowDisabledItemSetting} />

            <TableLabelCell label={t(`accessibility:option.${name}`)}/>
        </div>
    )
}

export default AccessibilityOptions;
