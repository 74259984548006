import React from 'react';
import {ListDropdown} from "../../../../components/common/Dropdown/Dropdown";
import {useTranslation} from "react-i18next";
import {DateFilterCriteria} from "../../../utilities/constants";

function DateFilterCriteriaDropdown(props) {
    const {t} = useTranslation(['aria', 'wizard']);

    const {selectedDateFilterCriteria, onDateFilterCriteriaSelected, ...rest} = props;
    const dateFilterTypeValues = [
        {value: DateFilterCriteria.ITEM_DATE, name: t('wizard:questions.advancedIngestionSettings.dateFilterCriteria.ITEM_DATE')},
        {value: DateFilterCriteria.TOP_LEVEL_ITEM_DATE, name: t('wizard:questions.advancedIngestionSettings.dateFilterCriteria.TOP_LEVEL_ITEM_DATE')}
    ]

    return (
        <ListDropdown id={'dateFilterCriteriaDropdown'} name={'dateFilterCriteria'}
            aria-label={t('aria:hiddenAssistText.dateFilterCriteria')} value={selectedDateFilterCriteria}
            items={dateFilterTypeValues} onItemSelect={onDateFilterCriteriaSelected}
            buttonStyle={{maxWidth: '12rem', justifyContent: 'flex-start'}}
            {...rest}
        />
    )
}

export default DateFilterCriteriaDropdown;