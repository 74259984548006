import {useTranslation} from "react-i18next";
import {purviewExportReviewSetParameterNames} from "./PurviewExportReviewSetPane";
import PurviewCache from "../../../models/guidedjob/PurviewCache";
import {useSelector} from "react-redux";
import React, {useEffect, useRef} from "react";
import {objectIdentifierType} from "../../../utilities/constants";
import Text from "../../common/Text/Text";
import SelectableTable from "../../common/Tables/SelectableTable";
import {StatusNameDescriptionCell, TableLabelCell} from "../../common/CustomTable/CustomTable";
import {getLocaleDateTimeFromUTC} from "../../../utilities/helperFunctions";
import {purviewUseCaseParameterNames} from "./PurviewUseCasePane";

function PurviewSelectReviewSetPane(props) {
    const {t} = useTranslation(['guidedJob', 'common']);
    const {
        proxyPurviewRequest,
        getParameter,
        updateParameter,
        isDisabled
    } = props;

    const caseIdentifierType = getParameter(purviewUseCaseParameterNames.caseIdentifierType);
    const caseIdentifier = getParameter(purviewUseCaseParameterNames.caseIdentifier);

    const reviewSetIdentifierType = getParameter(purviewExportReviewSetParameterNames.reviewSetIdentifierType);
    const reviewSetIdentifier = getParameter(purviewExportReviewSetParameterNames.reviewSetIdentifier);

    const useExistingCase = caseIdentifierType.value === objectIdentifierType.ID;
    const useExistingReviewSet = reviewSetIdentifierType.value === objectIdentifierType.ID;
    const isUseExistingReviewSetDisabled = isDisabled || !useExistingCase;

    const reviewSetsKey = PurviewCache.getResourceKey(PurviewCache.Resource.REVIEW_SETS, {caseId: caseIdentifier.value});
    const reviewSets = useSelector(state => state.purviewCache[reviewSetsKey]) || {};
    const isLoadingReviewSets = reviewSets.cache == null;

    useEffect(() => {
        if (useExistingReviewSet && useExistingCase) {
            proxyPurviewRequest(PurviewCache.Resource.REVIEW_SETS, {caseId: caseIdentifier.value});
        }
    }, [useExistingReviewSet]);

    useEffect(() => {
        if (Array.isArray(reviewSets.cache)) {
            if (reviewSets.cache.every(reviewSet => reviewSet.id !== reviewSetIdentifier.value)) {
                updateParameter(purviewExportReviewSetParameterNames.reviewSetIdentifier, '');
            }
        }
    }, [reviewSets.cache]);

    function valueHandler(event) {
        const {name, value} = event.currentTarget.dataset;
        updateParameter(name, value);
    }

    const headers = useRef([
        {label: t('common:label.name')},
        {label: t('common:label.createdDate'), noStretch: true}
    ]);

    return (
        <div className="display-input">
            <div style={{display: 'flex', marginBottom: '0.25rem'}}>
                <Text value={`${t('guidedJob:label.reviewSet')}:`} style={{marginRight: '0.5rem'}} isDisabled={isUseExistingReviewSetDisabled}/>
                <Text value={reviewSetIdentifier.value} isDisabled={isUseExistingReviewSetDisabled}/>
            </div>

            <SelectableTable name={purviewExportReviewSetParameterNames.reviewSetIdentifier} items={reviewSets.cache} headers={headers.current}
                selectedItemId={reviewSetIdentifier.value} onItemSelect={valueHandler} emptyMessage={t('guidedJob:message.purviewCaseHasNoReviewSets')}
                isLoading={isLoadingReviewSets} isDisabled={isUseExistingReviewSetDisabled} isStale={reviewSets.stale} isRequired
                RowComponent={props => (
                    <>
                        <StatusNameDescriptionCell name={props.displayName} description={props.description}/>
                        <TableLabelCell label={getLocaleDateTimeFromUTC(props.createdDate)}/>
                    </>
                )}
            />
        </div>
    )
}

export default PurviewSelectReviewSetPane;
