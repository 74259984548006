import DetailsModel, {DetailsApi, DetailsSaga} from "../generics/DetailsModel";
import ParameterModel from "../library/ParameterModel";
import ComponentStateModel from "../generics/ComponentStateModel";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import {all, call, put, select, takeLeading} from "redux-saga/effects";
import {actionCreator, getEntries, getProtectedValue, postProtectedValue} from "../../utilities/helperFunctions";
import {contextCall, contextSaga, isDisabledWrapper} from "../../saga/sagaFunctions";
import {details, PASSWORD_PLACEHOLDER, routes} from "../../utilities/constants";
import {getNotificationRuleNameValues} from "../../reselect/selectors";
import {separateNameValues} from "../../components/common/ListContainer/helpers";
import NotificationRuleModel from "./NotificationRuleModel";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import PopupModel from "../scheduler/PopupModel";
import SchedulerModel from "../scheduler/SchedulerModel";
import {settingsDisplayKeys} from "../../i18next/keys";
import {tryCatchWrapper} from "../../saga/tryCatchWrapper";
import FileLibraryModel from "../filelibrary/FileLibraryModel";


class ExecutionProfileModel extends DetailsModel {

    static nom = 'ExecutionProfileModel';
    static actions = ExecutionProfileModel.buildActions('EXECUTION_PROFILE');
    static actionCreators = ExecutionProfileModel.buildActionCreators(ExecutionProfileModel.actions);
    static reducer = ExecutionProfileModel.buildReducer(ExecutionProfileModel.actions);

    static componentActionCreators = {
        ...ExecutionProfileModel.buildComponentUpdateActionCreators(),
        ...ExecutionProfileModel.buildComponentSetActiveActionCreators()
    };

    constructor(model = {}) {
        super(model);
        const {engineAccountUsername, engineAccountPassword, engineCommandLineParameters, schedulerUrl, workflowParameters} = model;
        const {engineLogFolder, nuixEngineFolder, javaFolder, notificationRuleIds, jobTimeoutSettings, nuixProfiles, additionalFiles} = model;

        this.engineAccountUsername = engineAccountUsername || '';
        this.engineAccountPassword = engineAccountPassword;
        this.schedulerUrl = schedulerUrl || '';
        this.engineCommandLineParameters = engineCommandLineParameters;
        this.workflowParameters = ExecutionProfileModel.buildParameters(workflowParameters || {});
        this.nuixProfiles = ExecutionProfileModel.buildProfiles(nuixProfiles || {});
        this.additionalFiles = ExecutionProfileModel.buildAdditionalFiles(additionalFiles || {});
        this.engineLogFolder = engineLogFolder;
        this.nuixEngineFolder = nuixEngineFolder || '';
        this.javaFolder = javaFolder || '';
        this.notificationRuleIds = notificationRuleIds || [];
        this.jobTimeoutSettings = jobTimeoutSettings;
    }

    static buildActions (type) {
        return {
            ...super.buildActions(type),
            PROMPT_PROPAGATE_PROFILES: 'PROMPT_PROPAGATE_EXECUTION_PROFILES',
            PROPAGATE_PROFILES: 'PROPAGATE_EXECUTION_PROFILES',
            PROMPT_DELETE_PROPAGATED_PROFILES: 'PROMPT_DELETE_PROPAGATED_EXECUTION_PROFILES',
            DELETE_PROPAGATED_PROFILES: 'DELETE_PROPAGATED_EXECUTION_PROFILES'
        }
    }

    static buildActionCreators (actions) {
        return {
            ...super.buildActionCreators(actions),
            promptPropagateProfile: actionCreator(actions.PROMPT_PROPAGATE_PROFILES, 'id'),
            promptDeletePropagatedProfiles: actionCreator(actions.PROMPT_DELETE_PROPAGATED_PROFILES, 'id')
        }
    }

    static buildParameters(parameters) {
        const paramArr = getEntries(parameters)
            .map(([name, value]) => ({name, value}));

        return ParameterModel.buildParametersMap(paramArr);
    }

    static buildProfiles (files) {
        return Object.keys(files).map(key => ({name: files[key], fileId: key}));
    }

    static buildAdditionalFiles (files) {
        return Object.keys(files).map(key => ({parameterName: key, fileId: files[key]}));
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'executionProfileDisplay',
                type: 'Display',
                state: {
                    executionProfileId: null,
                    isExecutionProfileActive: false,
                    isDisabled: false
                }
            },
            {
                key: 'executionProfileTablet',
                type: 'Tablet',
                state: {}
            },
            {
                key: 'executionProfileForm',
                type: 'Form',
                state: {
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'EXECUTION_PROFILE_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }
}

export class ExecutionProfileApi extends DetailsApi {

    static location = '/resources';
    static type = '/executionProfiles';

    static propagateExecutionProfileForVersions (executionProfileId, force) {
        return axiosInstance.put(`/scheduler/resources/executionProfiles/${executionProfileId}/duplicate?force=${force}`)
    }

    static deletePropagatedExecutionProfiles (executionProfileId) {
        return axiosInstance.del(`/scheduler/resources/executionProfiles/${executionProfileId}/duplicate`)
    }
}

export class ExecutionProfileSaga extends DetailsSaga {

    static ModelType = ExecutionProfileModel;
    static ModelApi = ExecutionProfileApi;

    static activationComponent = 'EXECUTION_PROFILE_DISPLAY';
    static variableNames = {
        detailsMap: 'executionProfileDetailsMap',
        instanceId: 'executionProfileId',
        modelName: 'executionProfileName',
        isFormActive: 'isExecutionProfileFormActive',
        updateDisplay: 'updateDisplay',
        updatePane: 'updateTablet',
        route: routes.SETTINGS
    };

    static translations = {
        itemTitle: '$t(executionProfile:label.name_simple)',
        itemLower: '$t(executionProfile:label.name_simple_lower)'
    };

    static buildActivationEffects(dispatch) {
        return [
            ...super.buildActivationEffects(dispatch),
            takeLeading(ExecutionProfileModel.actions.PROMPT_PROPAGATE_PROFILES, contextSaga(this, 'promptPropagateProfiles')),
            takeLeading(ExecutionProfileModel.actions.PROPAGATE_PROFILES, tryCatchWrapper, isDisabledWrapper, ExecutionProfileModel.componentActionCreators.updateDisplay, contextSaga(this, 'propagateProfiles')),

            takeLeading(ExecutionProfileModel.actions.PROMPT_DELETE_PROPAGATED_PROFILES, contextSaga(this, 'promptDeletePropagatedProfiles')),
            takeLeading(ExecutionProfileModel.actions.DELETE_PROPAGATED_PROFILES, tryCatchWrapper, isDisabledWrapper, ExecutionProfileModel.componentActionCreators.updateDisplay, contextSaga(this, 'deletePropagatedProfiles')),

            // ACTIVATION EFFECTS
            put(ExecutionProfileModel.actionCreators.startPollingDetails()),
            put(NotificationRuleModel.actionCreators.startPollingDetails()),
            put(FileLibraryModel.actionCreators.startPollingDetails())
        ]
    }

    static buildDeactivationEffects() {
        return [
            ...super.buildDeactivationEffects(),
            // DEACTIVATION EFFECTS
            put(ExecutionProfileModel.actionCreators.stopPollingDetails()),
            put(NotificationRuleModel.actionCreators.stopPollingDetails()),
            put(FileLibraryModel.actionCreators.stopPollingDetails())
        ]
    }

    static* setInstanceId(args) {
        const {updateDisplay, instanceId} = this.variableNames;

        yield all ([
            put(SchedulerModel.actionCreators.setSettingsDisplay(settingsDisplayKeys.EXECUTION_PROFILES)),
            put(this.ModelType.componentActionCreators[updateDisplay]({[instanceId]: args.id}))
        ]);
    }

    static* promptPropagateProfiles (action) {
        const {id} = action.payload;
        const {data} = yield contextCall(ExecutionProfileApi, 'propagateExecutionProfileForVersions', id, false);

        yield put(PopupModel.actionCreators.showWarning({
            info: data,
            buttons: [{
                titleKey: 'executionProfile:option.propagate',
                onClick: () => this.dispatch({type: ExecutionProfileModel.actions.PROPAGATE_PROFILES, payload: {id}})
            }]
        }));
    }

    static* propagateProfiles (action) {
        const {id} = action.payload;
        const {data} = yield contextCall(ExecutionProfileApi, 'propagateExecutionProfileForVersions', id, true);

        yield put(PopupModel.actionCreators.showSuccess({
            info: data
        }));
    }

    static* promptDeletePropagatedProfiles (action) {
        const {id} = action.payload;

        yield put(PopupModel.actionCreators.showWarning({
            info: {
                key: "promptDeleteDuplicate",
            },
            buttons: [{
                titleKey: 'common:option.delete',
                onClick: () => this.dispatch({type: ExecutionProfileModel.actions.DELETE_PROPAGATED_PROFILES, payload: {id}})
            }]
        }));
    }

    static* deletePropagatedProfiles (action) {
        const {id} = action.payload;
        const {data} = yield contextCall(ExecutionProfileApi, 'deletePropagatedExecutionProfiles', id);

        yield put(PopupModel.actionCreators.showSuccess({
            info: data
        }));
    }

    static* getEditValues(id) {
        const {name: executionProfileName, engineAccountUsername, engineAccountPassword,
            workflowParameters, nuixProfiles, additionalFiles, notificationRuleIds, jobTimeoutSettings, ...rest} = yield select(state => state.executionProfileDetailsMap.get(id));

        const notificationRuleNameValues = getNotificationRuleNameValues(yield select());
        const {available: availableNotificationRules, selected: selectedNotificationRules} = yield call(separateNameValues, notificationRuleIds, notificationRuleNameValues);

        const {jobProgressMinPercentage, jobProgressTimeoutHours, operationProgressMinPercentage, operationProgressTimeoutHours, skipOperationProgressMinPercentage, skipOperationProgressTimeoutHours} = jobTimeoutSettings || {};

        const timeoutSettingEnabled = {
            job: jobProgressMinPercentage != null,
            operation: operationProgressMinPercentage != null,
            skipOperation: skipOperationProgressMinPercentage != null
        };
        const timeoutSettings = {
            jobProgressMinPercentage: jobProgressMinPercentage || 1,
            jobProgressTimeoutHours: jobProgressTimeoutHours || 48,
            operationProgressMinPercentage: operationProgressMinPercentage || 1,
            operationProgressTimeoutHours: operationProgressTimeoutHours || 24,
            skipOperationProgressMinPercentage: skipOperationProgressMinPercentage || 1,
            skipOperationProgressTimeoutHours: skipOperationProgressTimeoutHours || 12
        };

        return {
            executionProfileName,
            useServiceAccount: !!engineAccountUsername,
            engineAccountUsername: engineAccountUsername || '',
            engineAccountPassword: !!engineAccountUsername ? PASSWORD_PLACEHOLDER : '',
            workflowParameters: this.buildTableRowParameters(workflowParameters),
            nuixProfiles: nuixProfiles,
            additionalFiles: additionalFiles,
            availableNotificationRules,
            selectedNotificationRules,
            timeoutSettingEnabled,
            jobTimeoutSettings: timeoutSettings,
            ...rest
        }
    }

    static getSaveValues(values) {
        const {executionProfileName: name, description, useServiceAccount, engineAccountUsername, engineCommandLineParameters, schedulerUrl,
            engineLogFolder, nuixEngineFolder, javaFolder, timeoutSettingEnabled, jobTimeoutSettings, duplicateReferenceId} = values;


        const workflowParameters = {};
        for (let i = 0; i < values.workflowParameters.length; i++) {
            const [{value: name}, {value}] = values.workflowParameters[i];

            workflowParameters[name] = postProtectedValue(value);
        }

        const nuixProfiles = {};
        values.nuixProfiles.forEach(profile => {
            nuixProfiles[profile.fileId] = profile.name;
        });

        const additionalFiles = {};
        values.additionalFiles.forEach(file => {
            const parameterName = file.parameterName || file.name
            if (parameterName) {
                additionalFiles[parameterName] = file.fileId
            }
        });

        const saveValues = {
            duplicateReferenceId,
            name,
            description,
            workflowParameters,
            nuixProfiles,
            additionalFiles,
            engineAccountUsername: '',
            engineAccountPassword: '',
            engineCommandLineParameters,
            schedulerUrl,
            engineLogFolder,
            nuixEngineFolder,
            javaFolder,
            notificationRuleIds: values.selectedNotificationRules.items.map(item => item.value),
            jobTimeoutSettings: {}
        };

        if (useServiceAccount) {
            // Get password from DOM (assume only 1 password input)
            const engineAccountPassword = document.getElementsByName('engineAccountPassword')[0].value;
            saveValues.engineAccountUsername = engineAccountUsername;
            saveValues.engineAccountPassword = postProtectedValue(engineAccountPassword)
        }

        if (timeoutSettingEnabled.job) {
            saveValues.jobTimeoutSettings.jobProgressMinPercentage = jobTimeoutSettings.jobProgressMinPercentage;
            saveValues.jobTimeoutSettings.jobProgressTimeoutHours = jobTimeoutSettings.jobProgressTimeoutHours;
        }

        if (timeoutSettingEnabled.operation) {
            saveValues.jobTimeoutSettings.operationProgressMinPercentage = jobTimeoutSettings.operationProgressMinPercentage;
            saveValues.jobTimeoutSettings.operationProgressTimeoutHours = jobTimeoutSettings.operationProgressTimeoutHours;
        }

        if (timeoutSettingEnabled.skipOperation) {
            saveValues.jobTimeoutSettings.skipOperationProgressMinPercentage = jobTimeoutSettings.skipOperationProgressMinPercentage;
            saveValues.jobTimeoutSettings.skipOperationProgressTimeoutHours = jobTimeoutSettings.skipOperationProgressTimeoutHours;
        }

        return saveValues;
    }

    static buildTableRowParameters(workflowParameters) {
        return getEntries(workflowParameters)
            .map(([name, parameter]) => {
                const {value} = parameter;

                return [
                    {value: name},
                    {...(ParameterModel.isPasswordParameter(name, value) ? {value: getProtectedValue(value), type: 'password'} : {value})}
                ]
            });
    }

    static buildTableRowProfiles (profiles) {
        return profiles.map(profile => ({[profile.name]: profile.fileId}));
    }

    static buildTableRowFiles (fileParameterList) {
        return fileParameterList.map(file => ({[file.parameterName]: file.fileId}));
    }

    static* queryDetails() {
        const response = yield contextCall(ExecutionProfileApi, 'getDetails');
        const key = details.EXECUTION_PROFILES;

        if (AxiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(ExecutionProfileModel.actionCreators.setDetailsMap(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ])
        }
    }
}

export default ExecutionProfileModel;