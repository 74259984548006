import {useTranslation} from "react-i18next";
import React, {useCallback, useState} from "react";
import {PASSWORD_PLACEHOLDER} from "../../../utilities/constants";
import {Button} from "../Button/Button";
import Text from "./Text";

function CopySecretLabel(props) {
    const {t} = useTranslation(['common']);

    const {
        copyLabel,
        copyText,
        isDisabled,
        ...attr
    } = props;

    const [visible, setVisible] = useState(false);
    const visibleButtonText = visible ? t('common:label.hide') : t('common:label.show');
    const copyButtonText = copyLabel || t('common:label.copy');

    const onCopyClick = useCallback(() => {
        navigator.clipboard.writeText(copyText)
            .catch(err => console.log('Failed to copy text:', err));
    }, [copyText]);

    const toggleView = useCallback(() => {
        setVisible(prev => !prev);
    }, []);

    return (
        <section className="copy-label">
            <Text ElementTag="span" value={visible ? copyText : PASSWORD_PLACEHOLDER}
                isDisabled={isDisabled} {...attr} />

            <section className="button-group align-start">
                <Button id="copyButton" onClick={onCopyClick}
                    label={copyButtonText} isDisabled={isDisabled}/>

                <Button id="viewButton" onClick={toggleView}
                    label={visibleButtonText} isDisabled={isDisabled}/>
            </section>
        </section>
    )
}

export default CopySecretLabel;
