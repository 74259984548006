import React, {Component} from "react";
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import NotificationRuleModel from "../../models/settings/NotificationRuleModel";
import {details} from "../../utilities/constants";
import {getEmailNotificationRuleIds, getWebhookNotificationRuleIds} from "../../reselect/selectors";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import EmailNotificationRuleForm from "./email/EmailNotificationRuleForm";
import WebhookNotificationRuleForm from "./webhook/WebhookNotificationRuleForm";
import EmailNotificationRuleTablet from "./email/EmailNotificationRuleTablet";
import WebhookNotificationRuleTable from "./webhook/WebhookNotificationRuleTable";
import WebhookNotificationRuleTablet from "./webhook/WebhookNotificationRuleTablet";
import EmailNotificationRuleTable from "./email/EmailNotificationRuleTable";
import {MainPageContent, MainPageHeader} from "../app/pages/MainPage";

class NotificationRuleDisplay extends Component {

    componentDidMount() {
        this.props.setDisplayActive();
    }

    componentWillUnmount() {
        this.props.setDisplayInactive();
    }

    render() {

        const {
            t,
            isLoading,
            notificationRuleId,
            emailNotificationRuleIds,
            webhookNotificationRuleIds,
            isEmailNotificationRuleFormActive,
            isWebhookNotificationRuleFormActive,
            ...attr
        } = this.props;

        return (
            <MainPageContent {...attr}
                title={
                    <MainPageHeader text={t('notificationRule:page.title')}
                        description={t('notificationRule:page.description')}/>
                }
                list={
                    <LoadingWrapper isLoading={isLoading}>
                        <EmailNotificationRuleTable />
                        <WebhookNotificationRuleTable />
                    </LoadingWrapper>
                }
                children={
                    <>
                        {emailNotificationRuleIds.includes(notificationRuleId) &&
                        <EmailNotificationRuleTablet id={notificationRuleId}/>
                        }
                        {webhookNotificationRuleIds.includes(notificationRuleId) &&
                        <WebhookNotificationRuleTablet notificationRuleId={notificationRuleId}/>
                        }
                        {isEmailNotificationRuleFormActive &&
                        <EmailNotificationRuleForm/>
                        }
                        {isWebhookNotificationRuleFormActive &&
                        <WebhookNotificationRuleForm/>
                        }
                    </>
                }
            />
        );
    }
}

const mapStateToProps = state => {
    const {componentStates: {notificationRuleDisplay}, hasLoaded: {[details.NOTIFICATION_RULES]: hasLoaded}} = state;

    return {
        ...notificationRuleDisplay,
        emailNotificationRuleIds: getEmailNotificationRuleIds(state),
        webhookNotificationRuleIds: getWebhookNotificationRuleIds(state),
        isLoading: !hasLoaded
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setDisplayActive: () => dispatch(NotificationRuleModel.componentActionCreators.setDisplayActive()),
        setDisplayInactive: () => dispatch(NotificationRuleModel.componentActionCreators.setDisplayInactive())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['notificationRule'])(NotificationRuleDisplay));