import React, {useEffect, useState} from "react";
import FormBuilderComponentConfiguration from "../../../models/workflowbuilder/FormBuilderComponentConfiguration";
import "./FieldComponent.css";
import Switch from "../../common/Switch/Switch";
import {getLocalDateTimeValueFromUtc, getUtcFromDateTimeValue} from "../../common/HTMLTextInput/UtcDateTimeInput";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import TextArea from "../../common/TextArea/TextArea";
import Checkbox from "../../common/Checkbox/Checkbox";
import TableField from "./TableField";
import ScriptPaneField from "./ScriptPaneField";
import ListField from "./ListField";
import {RadioButtonField} from "./FieldRadioButtonGroup";
import DropdownField, {CheckedDropdownField, DropdownSelectedListField} from "./DropdownField";
import ObjectListField from "./ObjectListField";
import ObjectField, {ObjectFieldWrapper} from "./ObjectField";
import {isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import ParameterListField from "./ParameterListField";
import {ParameterDropdownField, ParameterInputField} from "./ParameterField";
import HighlightHTMLTextInput from "../../common/HTMLTextInput/HighlightHTMLTextInput";
import {HelpButton} from "../../common/Button/Button";
import {getOperationFieldParameterName} from "../../guidedJob/operationFieldParameterNames";
import {CopyLabel} from "../../common/Common";
import {getLocalDateValueFromUtc} from "../../common/HTMLTextInput/UtcDateInput";
import NuixProfileField from "./NuixProfileField";
import {useSelector} from "react-redux";
import {userSettings} from "../../../utilities/constants";


// Test if monaco-editor local files exist
let monacoError = false;
if (!window.location.origin.includes('localhost:3000')) {
    fetch(`monaco/loader.js`)
        .then(res => {
            if (res.status < 200 || res.status >= 300) {
                monacoError = true;
            }
        })
        .catch(() => {
            monacoError = true;
        });
}

function FieldComponent(props) {
    const {
        parentId,
        formKey,
        configuration,

        label,
        scriptLanguage,
        addColonToLabel,
        value,

        state,
        updateState,
        inputHandler,
        toggleHandler,
        dropdownHandler,

        'aria-label': ariaLabel,
        'aria-labelledby': ariaLabelledBy,

        autoFocus,
        simple,
        expandCheckbox,
        expandDropdown,
        isDropdownOutlined,
        isSelected,
        isRequired,
        isInvalid,
        isDisabled
    } = props;

    const {showFieldOverwriteParameterNames} = useSelector(state => state.userSettingsMap.get(userSettings.TROUBLESHOOT));
    
    let componentType = configuration.componentType;
    if (componentType === FormBuilderComponentConfiguration.ComponentType.SCRIPT_PANE && monacoError) {
        componentType = FormBuilderComponentConfiguration.ComponentType.TEXTAREA;
    }

    // React doesn't like when inputs have null values; update null values to ''
    let nullValueRequiresUpdate;
    if (value == null && componentType !== FormBuilderComponentConfiguration.ComponentType.FAKE_OBJECT) {
        switch (componentType) {
            case FormBuilderComponentConfiguration.ComponentType.INPUT:
            case FormBuilderComponentConfiguration.ComponentType.NUMBER:
            case FormBuilderComponentConfiguration.ComponentType.TEXTAREA:
            case FormBuilderComponentConfiguration.ComponentType.SCRIPT_PANE:
                nullValueRequiresUpdate = true;
                break;
        }
        if (configuration.formConfiguration != null) {
            nullValueRequiresUpdate = true;
        }
    }

    useEffect(() => {
        if (nullValueRequiresUpdate) {
            if (configuration.formConfiguration != null) {
                updateState({[configuration.name]: configuration.formConfiguration.generateDefaultObject()});
            } else {
                updateState({[configuration.name]: ''});
            }
        }
    }, [nullValueRequiresUpdate]);
    if (nullValueRequiresUpdate) {
        return null;
    }

    const callbacks = {};
    if (configuration.callbacks != null) {
        Object.assign(callbacks, configuration.callbacks);
    }

    let checkboxContainerStyle;
    if (expandCheckbox && componentType === FormBuilderComponentConfiguration.ComponentType.CHECKBOX) {
        checkboxContainerStyle = {width: '100%', height: '100%', justifyContent: 'center'};
    }

    let helpButton;
    if (isNotEmptyNorFalsy(configuration.helperValues)) {
        helpButton = (
            <HelpButton helpers={configuration.helperValues} value={value}
                isDisabled={isDisabled}/>
        );
    }

    let parameterNameCopyLabel;
    if (showFieldOverwriteParameterNames && !simple && state.enableFieldOverwrite && state.operationAlias) {
        const parameterName = getOperationFieldParameterName(state.operationAlias, configuration.name);
        parameterNameCopyLabel = (
            <CopyLabel value={parameterName} isDisabled={isDisabled}/>
        );
    }

    const afterLabel = (
        <>
            {helpButton}
        </>
    );

    const _isRequired = configuration.isRequired(value) && (isRequired == null || isRequired);
    const _isInvalid = isInvalid && (_isRequired || isNotEmptyNorFalsy(value));

    const id = FormBuilderComponentConfiguration.getId(configuration, parentId);

    const component = (
        <Switch>
            {componentType === FormBuilderComponentConfiguration.ComponentType.INPUT &&
                <HighlightHTMLTextInput id={id} data-type={componentType} label={label} name={configuration.name} value={value}
                    onChange={inputHandler} {...callbacks} autoFocus={autoFocus} placeholder={configuration.placeholder}
                    isSelected={isSelected} isRequired={_isRequired} isInvalid={_isInvalid} isDisabled={isDisabled}
                    aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} afterLabel={afterLabel}/>
            }

            {componentType === FormBuilderComponentConfiguration.ComponentType.TEXTAREA &&
                <TextArea id={id} data-type={componentType} label={label} name={configuration.name} value={value}
                    placeholder={configuration.placeholder} rows={configuration.rowSize} onChange={inputHandler}
                    isSelected={isSelected} isRequired={_isRequired} isInvalid={_isInvalid} isDisabled={isDisabled}
                    aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} afterLabel={afterLabel}/>
            }

            {componentType === FormBuilderComponentConfiguration.ComponentType.CHECKBOX &&
                <Checkbox id={id} data-type={componentType} label={label} name={configuration.name} checked={value}
                    onClick={toggleHandler} containerStyle={checkboxContainerStyle} isSelected={isSelected}
                    aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} isRequired={_isRequired}
                    isInvalid={_isInvalid} isDisabled={isDisabled}/>
            }

            {componentType === FormBuilderComponentConfiguration.ComponentType.RADIO_BUTTON &&
                <RadioButtonField id={id} data-type={componentType} label={label} selected={value} configuration={configuration}
                    formKey={formKey} addColonToLabel={addColonToLabel} inputHandler={inputHandler} toggleHandler={toggleHandler}
                    aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} isRequired={_isRequired}
                    isInvalid={_isInvalid} isDisabled={isDisabled}/>
            }

            {componentType === FormBuilderComponentConfiguration.ComponentType.NUMBER &&
                <NumberField id={id} data-type={componentType} label={label} configuration={configuration}
                    value={value} updateState={updateState} isSelected={isSelected} isRequired={_isRequired}
                    aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} isInvalid={_isInvalid}
                    isDisabled={isDisabled} afterLabel={afterLabel}/>
            }

            {componentType === FormBuilderComponentConfiguration.ComponentType.DATE &&
                <DateField id={id} data-type={componentType} label={label} name={configuration.name} value={value} updateState={updateState}
                    mode={configuration.dateMode} min={configuration.min} max={configuration.max} isSelected={isSelected}
                    aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} isRequired={_isRequired} isInvalid={_isInvalid}
                    isDisabled={isDisabled} afterLabel={afterLabel}/>
            }

            {componentType === FormBuilderComponentConfiguration.ComponentType.TIME &&
                <HTMLTextInput id={id} data-type={componentType} type={'time'} label={label} name={configuration.name}
                    value={value} onChange={inputHandler} style={{width: '8rem'}} isSelected={isSelected}
                    aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} isRequired={_isRequired} isInvalid={_isInvalid}
                    isDisabled={isDisabled} afterLabel={afterLabel}/>
            }

            {componentType === FormBuilderComponentConfiguration.ComponentType.DATE_TIME &&
                <DateTimeField id={id} data-type={componentType} label={label} name={configuration.name} value={value} updateState={updateState}
                    min={configuration.min} max={configuration.max} isSelected={isSelected} isRequired={_isRequired}
                    aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} isInvalid={_isInvalid}
                    isDisabled={isDisabled} afterLabel={afterLabel}/>
            }

            {componentType === FormBuilderComponentConfiguration.ComponentType.DROPDOWN &&
                <DropdownField id={id} data-type={componentType} label={label} value={value} formKey={formKey} configuration={configuration}
                    dropdownHandler={dropdownHandler} expandDropdown={expandDropdown} isOutlined={isDropdownOutlined} {...callbacks}
                    aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} isRequired={_isRequired} isSelected={isSelected}
                    isInvalid={_isInvalid} isDisabled={isDisabled}/>
            }

            {componentType === FormBuilderComponentConfiguration.ComponentType.CHECKED_DROPDOWN &&
                <CheckedDropdownField id={id} data-type={componentType} label={label} value={value} formKey={formKey} configuration={configuration}
                    updateState={updateState} expandDropdown={expandDropdown} isOutlined={isDropdownOutlined} isRequired={_isRequired}
                    aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} isSelected={isSelected}
                    isInvalid={_isInvalid} isDisabled={isDisabled}/>
            }

            {componentType === FormBuilderComponentConfiguration.ComponentType.DROPDOWN_SELECTED_LIST &&
                <DropdownSelectedListField id={id} data-type={componentType} label={label} value={value} formKey={formKey} configuration={configuration}
                    updateState={updateState} isRequired={_isRequired} isSelected={isSelected}
                    aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} isInvalid={_isInvalid} isDisabled={isDisabled}/>
            }

            {componentType === FormBuilderComponentConfiguration.ComponentType.NUIX_PROFILE &&
                <NuixProfileField id={id} data-type={componentType} label={label} value={value} formKey={formKey} configuration={configuration}
                    dropdownHandler={dropdownHandler} expandDropdown={expandDropdown} isOutlined={isDropdownOutlined} {...callbacks}
                    aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} onChange={inputHandler} autoFocus={autoFocus}
                    isRequired={_isRequired} isSelected={isSelected} isInvalid={_isInvalid} isDisabled={isDisabled}/>
            }

            {!simple && componentType === FormBuilderComponentConfiguration.ComponentType.SCRIPT_PANE &&
                <ScriptPaneField id={id} data-type={componentType} label={label} configuration={configuration} value={value} updateState={updateState}
                    scriptLanguage={scriptLanguage} isRequired={_isRequired} isInvalid={_isInvalid}
                    aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} isDisabled={isDisabled}/>
            }

            {!simple && componentType === FormBuilderComponentConfiguration.ComponentType.INPUT_LIST &&
                <ListField id={id} data-type={componentType} label={label} configuration={configuration} formKey={formKey} value={value}
                    state={state} updateState={updateState} isRequired={_isRequired} isInvalid={_isInvalid}
                    aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} isDisabled={isDisabled}/>
            }

            {!simple && componentType === FormBuilderComponentConfiguration.ComponentType.OBJECT_TABLE &&
                <TableField id={id} data-type={componentType} label={label} configuration={configuration} formKey={formKey} value={value}
                    state={state} updateState={updateState} isRequired={_isRequired} isInvalid={_isInvalid}
                    aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} isDisabled={isDisabled}/>
            }

            {!simple && componentType === FormBuilderComponentConfiguration.ComponentType.OBJECT &&
                <ObjectFieldWrapper id={id} data-type={componentType} label={label} configuration={configuration} formKey={formKey} value={value}
                    updateState={updateState} isRequired={_isRequired} isInvalid={_isInvalid}
                    aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} isDisabled={isDisabled}/>
            }

            {!simple && componentType === FormBuilderComponentConfiguration.ComponentType.FAKE_OBJECT &&
                <ObjectField id={id} data-type={componentType} label={label} configuration={configuration} formKey={formKey} value={state}
                    updateState={updateState} isRequired={_isRequired} isInvalid={_isInvalid}
                    aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} isDisabled={isDisabled}/>
            }

            {!simple && componentType === FormBuilderComponentConfiguration.ComponentType.OBJECT_LIST &&
                <ObjectListField id={id} data-type={componentType} label={label} configuration={configuration} formKey={formKey} value={value}
                    updateState={updateState} isRequired={_isRequired} isInvalid={_isInvalid}
                    aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} isDisabled={isDisabled}/>
            }

            {!simple && componentType === FormBuilderComponentConfiguration.ComponentType.PARAMETER_TABLE &&
                <ParameterListField id={id} data-type={componentType} label={label} configuration={configuration} formKey={formKey} value={value}
                    updateState={updateState} isRequired={_isRequired} isInvalid={_isInvalid}
                    aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} isDisabled={isDisabled}/>
            }

            {componentType === FormBuilderComponentConfiguration.ComponentType.PARAMETER_INPUT &&
                <ParameterInputField id={id} data-type={componentType} label={label} configuration={configuration}
                    state={state} value={value} updateState={updateState} isSelected={isSelected} isRequired={_isRequired}
                    aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} isInvalid={_isInvalid} isDisabled={isDisabled}/>
            }

            {componentType === FormBuilderComponentConfiguration.ComponentType.PARAMETER_DROPDOWN &&
                <ParameterDropdownField id={id} data-type={componentType} label={label} configuration={configuration}
                    value={value} dropdownHandler={dropdownHandler} expandDropdown={expandDropdown} isOutlined={isDropdownOutlined}
                    {...callbacks} isRequired={_isRequired} isInvalid={_isInvalid} isDisabled={isDisabled}
                    aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} />
            }
        </Switch>
    );

    return (
        <>
            {parameterNameCopyLabel}
            {component}
        </>
    )
}

function NumberField(props) {
    const {
        value,
        updateState,
        configuration,
        ...rest
    } = props;

    const [number, setNumber] = useState();
    useEffect(() => {
        let number = value;
        if (configuration.divisor != null && configuration.divisor > 0) {
            number = value / configuration.divisor;
        }
        setNumber(number);
    }, [value, configuration.divisor]);

    function onChange(event) {
        const {name, value} = event.target;
        const update = {[name]: value};
        if (configuration.divisor != null && configuration.divisor > 0) {
            update[name] = value * configuration.divisor;
        }
        updateState(update);
    }

    if (number == null) return null;
    return (
        <HTMLTextInput type={'number'} step={'any'} name={configuration.name} style={{width: '8rem'}}
            value={number} onChange={onChange} min={configuration.min} max={configuration.max}
            {...rest}/>
    )
}

function DateField(props) {
    const {
        value,
        updateState,
        mode,
        min,
        max,
        ...rest
    } = props;

    // Track as internal state to prevent re-computing on every render
    const [dateValue, setDateValue] = useState();
    const [minMaxDates, setMinMaxDates] = useState({});

    useEffect(() => {
        if (true || mode === 'utc') {
            setDateValue(getLocalDateValueFromUtc(value));
            setMinMaxDates({
                max: isNaN(parseInt(max)) ? null : getLocalDateValueFromUtc(max),
                min: isNaN(parseInt(min)) ? null : getLocalDateValueFromUtc(min),
            });
        } else {
            setDateValue(value);
            setMinMaxDates({min, max});
        }
    }, [value, min, max]);

    // Translate to UTC and send update
    function onChange(event) {
        const {name, value} = event.target;
        if (true || mode === 'utc') {
            updateState({[name]: getUtcFromDateTimeValue(value)});
        } else {
            updateState({[name]: value});
        }
    }

    if (dateValue == null) return null;
    return (
        <HTMLTextInput type={'date'} value={dateValue} min={minMaxDates.min} max={minMaxDates.max}
            onChange={onChange} style={{width: '9rem'}} {...rest}/>
    )
}

function DateTimeField(props) {
    const {
        value,
        updateState,
        min,
        max,
        ...rest
    } = props;

    // Track as internal state to prevent re-computing on every render
    const [dateTimeValue, setDateTimeValue] = useState();
    const [minMaxDates, setMinMaxDates] = useState({});

    useEffect(() => {
        setDateTimeValue(getLocalDateTimeValueFromUtc(value));
        setMinMaxDates({
            max: isNaN(parseInt(max)) ? null : getLocalDateTimeValueFromUtc(max),
            min: isNaN(parseInt(min)) ? null : getLocalDateTimeValueFromUtc(min),
        });
    }, [value, min, max]);

    // Translate to UTC and send update
    function onChange(event) {
        const {name, value} = event.target;
        updateState({[name]: getUtcFromDateTimeValue(value)});
    }

    if (dateTimeValue == null) return null;
    return (
        <HTMLTextInput type={'datetime-local'} value={dateTimeValue} min={minMaxDates.min} max={minMaxDates.max}
            onChange={onChange} style={{width: '13rem'}} {...rest}/>
    )
}

export default FieldComponent;
