import ReduxModel from "../generics/ReduxModel";

class PopupModel extends ReduxModel {

    static nom = 'PopupModel';
    static actions = PopupModel.buildActions();
    static actionCreators = PopupModel.buildActionCreators(PopupModel.actions);
    static reducer = PopupModel.buildReducer(PopupModel.actions);

    constructor(model = {}) {
        super();
        const {info, icon, content, buttons, cancelButton, showX, hideTitle, hideButtons, isRigid, isDisabled} = model || {};

        this.info = info;
        this.icon = icon;
        this.content = content;
        this.buttons = buttons;
        this.cancelButton = cancelButton;

        this.showX = showX;
        this.hideTitle = hideTitle;
        this.hideButtons = hideButtons;
        this.isRigid = isRigid;
        this.isDisabled = isDisabled;
    }

    static buildActions() {
        return {
            // POPUP ACTIONS
            SHOW: `SHOW_POPUP`,
            HIDE: `HIDE_POPUP`,
            SET_DISABLED: 'SET_POPUP_DISABLED'
        }
    }

    static buildActionCreators(actions) {
        return {
            // POPUP ACTION CREATORS
            show: payload => ({
                type: actions.SHOW,
                payload
            }),
            showSuccess: ({cancelButton, ...rest}) => ({
                type: actions.SHOW,
                payload: {id: 'success', icon: 'success', isRigid: true, cancelButton: {titleKey: 'common:option.ok', ...cancelButton}, ...rest}
            }),
            showInfo: ({cancelButton, ...rest}) => ({
                type: actions.SHOW,
                payload: {id: 'info', icon: 'info', cancelButton: {titleKey: 'common:option.ok', ...cancelButton}, ...rest}
            }),
            showWarning: payload => ({
                type: actions.SHOW,
                payload: {id: 'warning', icon: 'warning', isRigid: true, ...payload}
            }),
            showError: ({cancelButton, ...rest}) => ({
                type: actions.SHOW,
                payload: {id: 'error', icon: 'error', isRigid: true, cancelButton: {titleKey: 'common:option.ok', ...cancelButton},  ...rest}
            }),
            setDisabled: (id, isDisabled) => ({
                type: actions.SET_DISABLED,
                payload: {id, isDisabled}
            }),
            hide: id => ({
                type: actions.HIDE,
                payload: {id}
            })
        }
    }

    static buildReducer(actions) {
        return function (state = new Map(), action) {
            switch (action.type) {
                case actions.SHOW: {
                    const id = action.payload.id;
                    const popup = new PopupModel(action.payload);

                    return new Map(state).set(id, popup);
                }
                case actions.SET_DISABLED: {
                    const {id, isDisabled} = action.payload;
                    const popup = state.get(id);

                    if (popup != null) {
                        return new Map(state).set(id, popup.duplicate({isDisabled}));
                    }
                    return state;
                }
                case actions.HIDE: {
                    const {id} = action.payload;

                    const newState = new Map(state);
                    newState.delete(id);

                    return newState;
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }
}

export default PopupModel;
