import React, {useLayoutEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {applicationFeatures, details} from "../../utilities/constants";
import ApiKeyModel from "../../models/settings/ApiKeyModel";
import ApiKeyTablet from "./ApiKeyTablet";
import ApiKeyForm from "./ApiKeyForm";
import Switch from "../common/Switch/Switch";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {AddButtonHeader} from "../common/Button/Button";
import {isNotEmptyNorFalsy} from "../../utilities/helperFunctions";
import ApiKeyTable from "./ApiKeyTable";
import {MainPageContent, MainPageHeader} from "../app/pages/MainPage";


function ApiKeyDisplay(props) {
    const {t} = useTranslation(['aria', 'apiKey']);
    const dispatch = useDispatch();

    const {
        ...attr
    } = props;

    const {
        apiKeyId,
        isApiKeyFormActive,
        createdApiKeySecret
    } = useSelector(state => state.componentStates.apiKeyDisplay);

    const isLoading = useSelector(state => !state.hasLoaded[details.API_KEYS]);

    const canModify = useSelector(state => state.currentUser.features.includes(applicationFeatures.ADD_API_KEYS));
    const apiKeys = useSelector(state => state.apiKeyDetailsMap);

    useLayoutEffect(() => {
        dispatch(ApiKeyModel.componentActionCreators.setDisplayActive())

        return () => dispatch(ApiKeyModel.componentActionCreators.setDisplayInactive())
    }, [dispatch]);

    function showForm() {
        dispatch(ApiKeyModel.actionCreators.showForm())
    }

    const selectedApiKey = apiKeys.get(apiKeyId);

    const isNotEmpty = isNotEmptyNorFalsy(apiKeys);

    return (
        <MainPageContent {...attr}
            title={
                <MainPageHeader
                    text={t('apiKey:page.title')}
                    description={t('apiKey:page.description')}
                />
            }
            list={
                <LoadingWrapper isLoading={isLoading}>
                    {(canModify || isNotEmpty) &&
                        <div className="display-item">
                            <AddButtonHeader aria-label={t('aria:hiddenAssistText.addApiKey')} text={t('apiKey:label.name')}
                                canModify={canModify} onClick={showForm}
                            />

                            {isNotEmpty &&
                            <ApiKeyTable apiKeys={apiKeys}/>
                            }
                        </div>
                    }
                </LoadingWrapper>
            }
        >

            <Switch>
                {(selectedApiKey != null) &&
                <ApiKeyTablet secret={createdApiKeySecret} {...selectedApiKey}/>
                }

                {isApiKeyFormActive &&
                <ApiKeyForm />
                }
            </Switch>
        </MainPageContent>
    )
}

export default ApiKeyDisplay;
