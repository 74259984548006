import React, {useEffect} from "react";
import {arrayIsNotEmptyNorFalsy, getValues} from "../../../utilities/helperFunctions";
import UploadInfoTable from "../../dataset/UploadInfoTable";
import {datasetStateKeys, popupInfoKeys} from "../../../i18next/keys";
import {NonEditTable, selectFileInfoRows} from "../../dataset/FileInfoTable";
import Switch from "../Switch/Switch";
import {useDispatch, useSelector} from "react-redux";
import DatasetModel, {DatasetApi, DatasetSaga} from "../../../models/data/DatasetModel";
import UploadInfoModel from "../../../models/data/UploadInfoModel";
import SchedulerModel from "../../../models/scheduler/SchedulerModel";
import {useObjectState} from "../../../utilities/hooks";
import {setIsIdleHandlerPausedId} from "../../../saga/idleHandler";
import UppyUpload from "../../dataset/uppy/UppyUpload";
import {Button} from "../Button/Button";
import {useTranslation} from "react-i18next";
import PopupModel from "../../../models/scheduler/PopupModel";
import {call, put} from "redux-saga/effects";
import {contextCall} from "../../../saga/sagaFunctions";
import FileInfoModel, {FileInfoSaga} from "../../../models/data/FileInfoModel";
import Text from "../Text/Text";
import {datasetType} from "../../../utilities/constants";


function DataUpload(props) {
    const {t} = useTranslation(['common']);
    const dispatch = useDispatch();

    const {
        id,
        datasetId,
        onCreateDataset,
        onUploadActive,

        label,
        isEditActive,
        inputHandler,

        isRequired,
        isReadOnly,
        isDisabled
    } = props;

    const [state, setState] = useObjectState({
        isUploadActive: false,
        isDisabled: false
    });


    const dataset = useSelector(state => state.datasetDetailsMap.get(datasetId)) || {};
    const dataRepository = useSelector(state => state.dataRepositoryDetailsMap.get(dataset.dataRepositoryId));
    const uploadInfos = useSelector(state => getValues(state.uploadInfoDetailsMap).filter(info => info.datasetId === datasetId));
    const fileInfoRows = useSelector(state => {

        const fileInfos = state.fileInfoRowsMap.get(datasetId) || [];
        return selectFileInfoRows(state, {t, fileInfoRows: fileInfos, isEditActive: false});
    });

    const isDatasetValid = (dataset instanceof DatasetModel);

    // Query for dataset
    useEffect(() => {
        if (!!datasetId && !isDatasetValid) {
            dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(
                contextCall(DatasetSaga, 'query', {payload: {id: datasetId}})
            ));
        }
    }, [datasetId, isDatasetValid, dispatch]);

    // Start and stop polling fileInfos and uploadInfos
    useEffect(() => {
        if (isDatasetValid && !state.isUploadActive) {
            dispatch(DatasetModel.actionCreators.startPollingSettings(datasetId));
        }

        return () => dispatch(DatasetModel.actionCreators.stopPollingSettings(datasetId));
    }, [isDatasetValid, datasetId, state.isUploadActive, dispatch]);

    // Reset isUploadActive if state switches away from DRAFT (e.g. from another source)
    useEffect(() => {
        if (dataset.state !== datasetStateKeys.DRAFT) {
            setState({isUploadActive: false});
        }
    }, [dataset.state]);

    // Pause idleHandler when isUploadActive
    // Set disable when isUploadActive
    useEffect(() => {
        if (state.isUploadActive) {
            setIsIdleHandlerPausedId(datasetId, true);
            // onReset clear pause
            return () => setIsIdleHandlerPausedId(datasetId, false);
        }
        onUploadActive(id, state.isUploadActive);
    }, [state.isUploadActive]);



    async function onFileInfosDelete(fileInfoIds) {
        // Disable pane
        setState({isDisabled: true});
        try {
            const fileInfosRes = await DatasetApi.deleteFiles(datasetId, fileInfoIds);

            // Using yieldEffectDescriptor to leverage sagas
            dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(call(function* () {
                const fileInfoRows = yield contextCall(FileInfoSaga, 'buildFileInfoRows', datasetId, fileInfosRes.data);
                yield put(FileInfoModel.actionCreators.setDetails(datasetId, fileInfoRows));
            })));
        } catch (error) {
            dispatch(SchedulerModel.actionCreators.handleResponseError(error));

        } finally {
            setState({isDisabled: false});
        }
    }

    async function onUploadFilesDelete(uploadInfoIds) {
        // Disable pane
        setState({isDisabled: true});
        try {
            const uploadInfosRes = await DatasetApi.deleteUploadInfos(datasetId, uploadInfoIds);
            dispatch(UploadInfoModel.actionCreators.setDetailsMap(datasetId, uploadInfosRes.data));

        } catch (error) {
            dispatch(SchedulerModel.actionCreators.handleResponseError(error));

        } finally {
            setState({isDisabled: false});
        }
    }

    function onShowUpload() {

        if (!datasetId && !isDatasetValid && typeof onCreateDataset === 'function') {
            setState({isDisabled: true});

            onCreateDataset(id).then(() => {
                setState({isUploadActive: true, isDisabled: false});
            });

        } else {
            setState({isUploadActive: true});
        }
    }

    function onCancelUpload() {
        dispatch(PopupModel.actionCreators.showWarning({
            info: {
                key: popupInfoKeys.CANCEL_DATASET_UPLOAD
            },
            buttons: [{
                titleKey: 'common:option.yes',
                onClick: function () {
                    // Setting isUploadActive->false will close uppy instance and cancel uploads
                    setState({isUploadActive: false});
                }
            }],
            cancelButton: {
                titleKey: 'common:option.no'
            }
        }));
    }


    const canModify = !isReadOnly;
    const hasUploads = arrayIsNotEmptyNorFalsy(uploadInfos);
    const uploadMaxSize = dataset.type === datasetType.MANAGED && dataRepository && dataRepository.uploadMaxSizeEnabled && dataRepository.uploadMaxSize;

    return (
        <div id={id} className="data-upload">
            <Switch>
                {(canModify && state.isUploadActive) &&
                <>
                    <Text value={label} isDisabled={isDisabled}/>

                    <UppyUpload datasetId={datasetId} uploadMaxSize={uploadMaxSize}
                        height={'32rem'} isDisabled={isDisabled} updateView={setState}/>

                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button label={t(`common:option.cancel`)} onClick={onCancelUpload}
                            isDisabled={isDisabled}
                        />
                    </div>
                </>
                }

                <>
                    {hasUploads &&
                    <UploadInfoTable uploadInfos={uploadInfos} onUploadFilesDelete={onUploadFilesDelete} label={label}
                        canModify={canModify} isFinalized={dataset.state === datasetStateKeys.FINALIZED} isDisabled={isDisabled}
                    />
                    }

                    <NonEditTable datasetId={datasetId} label={label} fileInfoRows={fileInfoRows}
                        onFileInfosDelete={onFileInfosDelete} onShowUpload={onShowUpload} inputHandler={inputHandler}
                        isEditActive={isEditActive} canModifyMetadata={false} canModify={canModify} isRequired={isRequired} isDisabled={isDisabled}/>
                </>
            </Switch>
        </div>
    )
}

export default DataUpload;
