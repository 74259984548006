import React, {useEffect, useState} from "react";
import FormBuilderComponentConfiguration from "../../../models/workflowbuilder/FormBuilderComponentConfiguration";
import HighlightHTMLTextInput from "../../common/HTMLTextInput/HighlightHTMLTextInput";
import DropdownField from "./DropdownField";
import {nuixFileTypeKeys} from "../../../i18next/keys";
import {useSelector} from "react-redux";
import {buildFakeEvent, isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import {useTranslation} from "react-i18next";
import {NameSelectableItem} from "../../common/SelectableItem/SelectableItem";


function NuixProfileField(props) {
    const {t} = useTranslation(['workflowBuilder']);

    const {
        configuration,
        ...attr
    } = props;

    const {
        formKey,
        dropdownHandler,
        isOutlined,
        expandDropdown,

        name,
        onChange,
        autoFocus=true,
        afterLabel,
        ...commonProps
    } = attr;

    const userDataDir = useSelector(state => state.userDataDir);
    const [nuixProfileConfiguration, setNuixProfileConfiguration] = useState();
    const [useTextInput, setUseTextInput] = useState();

    useEffect(() => {
        let profileConfiguration = configuration;
        const fileType = nuixProfileDynamicTypeToFileType[configuration.dynamicValuesType];

        if (fileType != null && Array.isArray(userDataDir?.files)) {
            const allowedValues = userDataDir.files
                .filter(file => file.fileType === fileType)
                .map(file => file.name);

            if (allowedValues.length > 0) {
                profileConfiguration = configuration.duplicate();
                profileConfiguration.allowedValues = allowedValues;
            }
        }
        setNuixProfileConfiguration(profileConfiguration);
    }, []);

    if (nuixProfileConfiguration == null) {
        return null;
    }


    if (!useTextInput && isNotEmptyNorFalsy(nuixProfileConfiguration.allowedValues)) {
        function onSwitchToTextInput() {
            setUseTextInput(true);
            if (attr.value == null) {
                onChange(buildFakeEvent({name: configuration.name, value: ''}));
            }
        }

        const selectableItems = (
            <NameSelectableItem item={{name: t('workflowBuilder:option.useTextInput')}}
                onItemClick={onSwitchToTextInput} isNoWrap isCentered isBorder
                isDisabled={commonProps.isDisabled}/>
        );

        return (
            <DropdownField noneSelectedMessage={t('workflowBuilder:option.selectProfile')}
                dropdownHandler={dropdownHandler} isOutlined={isOutlined} expandDropdown={expandDropdown}
                formKey={formKey} configuration={nuixProfileConfiguration} selectableItems={selectableItems}
                {...commonProps} />
        )
    }

    return (
        <HighlightHTMLTextInput name={configuration.name} placeholder={configuration.placeholder}
            onChange={onChange} autoFocus={autoFocus} afterLabel={afterLabel} {...commonProps} />
    )
}

const nuixProfileDynamicTypeToFileType = {
    [FormBuilderComponentConfiguration.NuixProfileDynamicValuesType.PROCESSING_PROFILES]: nuixFileTypeKeys.PROCESSING_PROFILE,
    [FormBuilderComponentConfiguration.NuixProfileDynamicValuesType.CONFIGURATION_PROFILES]: nuixFileTypeKeys.CONFIGURATION_PROFILE,
    [FormBuilderComponentConfiguration.NuixProfileDynamicValuesType.METADATA_PROFILES]: nuixFileTypeKeys.METADATA_PROFILE,
    [FormBuilderComponentConfiguration.NuixProfileDynamicValuesType.METADATA_IMPORT_PROFILES]: nuixFileTypeKeys.METADATA_IMPORT_PROFILE,
    [FormBuilderComponentConfiguration.NuixProfileDynamicValuesType.PLAYBOOKS]: nuixFileTypeKeys.PLAYBOOK,
    [FormBuilderComponentConfiguration.NuixProfileDynamicValuesType.OCR_PROFILES]: nuixFileTypeKeys.OCR_PROFILE,
    [FormBuilderComponentConfiguration.NuixProfileDynamicValuesType.IMAGING_PROFILES]: nuixFileTypeKeys.IMAGING_PROFILE,
    [FormBuilderComponentConfiguration.NuixProfileDynamicValuesType.PRODUCTION_PROFILES]: nuixFileTypeKeys.PRODUCTION_PROFILE
};

export default NuixProfileField;
