import React, {useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {vaultCorpusTypes} from "../../../utilities/constants";
import {getEntries, getValues, stringToBool} from "../../../utilities/helperFunctions";
import Checkbox from "../../common/Checkbox/Checkbox";
import Text from "../../common/Text/Text";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import VaultOperationPane from "./VaultOperatonPane";
import {vaultCreateSavedQueriesParameterNames} from "./VaultSavedQueryOptionsPane";
import {vaultAddHoldsParameterNames} from "./VaultHoldOptionsPane";
import {getVaultLocations, VaultCorpusTypeDropdown} from "./VaultAddLocationsPane";
import DataSourcesTable from "../../purview/DataSourcesTable";
import {blobUrlDownload} from "../../../utilities/downloadHelper";
import Papa from "papaparse";
import GuidedJobModel from "../../../models/guidedjob/GuidedJobModel";
import SchedulerModel from "../../../models/scheduler/SchedulerModel";
import {useDispatch} from "react-redux";
import {icon} from "../../../utilities/iconResolver";


function getNextEnabled() {
    return {
        serviceQueryTerms: true
    }
}

export function getVaultServiceQueryTermsGuide(props) {
    const {
        workflowActions,
        getParameter,
        updateParameter
    } = props;

    let defaultValue = '[]';
    if (workflowActions.createSavedQueries) {
        const queryTerms = getParameter(vaultCreateSavedQueriesParameterNames.queryTerms, '[]');
        if (!queryTerms.value.startsWith('[') || !queryTerms.value.endsWith(']')) {
            updateParameter(vaultCreateSavedQueriesParameterNames.queryTerms, JSON.stringify([]));
        } else {
            defaultValue = queryTerms.value;
        }
    }
    if (workflowActions.addHolds) {
        const queryTerms = getParameter(vaultAddHoldsParameterNames.queryTerms, '[]');
        if (!queryTerms.value.startsWith('[') || !queryTerms.value.endsWith(']')) {
            updateParameter(vaultAddHoldsParameterNames.queryTerms, defaultValue);
        }
    }

    return {
        getPanes: function(t, props) {
            const nextEnabled = getNextEnabled(props.getParameter);
            return [{
                title: t(`guidedJob:panes.serviceQueryTerms`),
                isNextEnabled: nextEnabled.serviceQueryTerms,
                component: <VaultOperationPane {...props}
                    workflowActions={workflowActions} OperationPane={VaultServiceQueryTermsPane}/>
            }];
        }
    }
}

function getQueryTerms(workflowActions, getParameter) {
    if (workflowActions.createSavedQueries) {
        return getParameter(vaultCreateSavedQueriesParameterNames.queryTerms, '[]', {parse: true});
    }
    return getParameter(vaultAddHoldsParameterNames.queryTerms, '[]', {parse: true});
}

function VaultServiceQueryTermsPane(props) {
    const {t} = useTranslation(['guidedJob', 'common']);
    const dispatch = useDispatch();

    const {
        workflowActions,
        guidedJobForm,
        getParameter,
        updateParameter,
        updateState,
        isDisabled
    } = props;

    const _getQueryTerms = () => getQueryTerms(workflowActions, getParameter).value;
    const [queryTerms, setQueryTerms] = useState(_getQueryTerms);
    const [selectedTypes, setSelectedTypes] = useState({});

    const allowedServices = getVaultLocations(workflowActions, getParameter).value
        .reduce((acc, curr) => {
            acc[curr.corpusType] = true;
            return acc;
        }, {});
    const allowedTypes = (workflowActions.createSavedQueries ? queryAllowedTypes : holdAllowedTypes)
        .filter(type => allowedServices[type]);

    const headers = useRef([
        {value: t('guidedJob:label.service'), style: {width: '12rem'}},
        {value: t('guidedJob:label.terms')}
    ]);

    const rowProps = {
        selectedTypes,
        allowedTypes
    };

    useEffect(() => {
        if (workflowActions.createSavedQueries) {
            updateParameter(vaultCreateSavedQueriesParameterNames.queryTerms, JSON.stringify(queryTerms));
        }
        if (workflowActions.addHolds) {
            updateParameter(vaultAddHoldsParameterNames.queryTerms, JSON.stringify(queryTerms));
        }
        const newSelectedTypes = queryTerms.reduce((acc, curr) => {
            acc[curr.corpusType] = true;
            return acc;
        }, {});
        setSelectedTypes(newSelectedTypes);
    }, [queryTerms]);

    const exportCsv = useCallback(() => {
        const fields = headers.current.map(header => header.value);
        const data = queryTerms.map(terms =>
            [terms.corpusType, terms.value]
        );
        const fileName = `Query Terms.csv`;
        blobUrlDownload(Papa.unparse({fields, data}), {type: 'text/csv;charset=utf-8;', fileName});
    }, [queryTerms]);

    const importCsv = useCallback(async resultArray => {
        try {
            dispatch(GuidedJobModel.actionCreators.update({isDisabled: true}));
            const values = {importedCount: 0, invalidCount: 0};
            const terms = resultArray.reduce((acc, curr, i) => {
                const type = curr[0];
                const value = curr[1];
                if (value && typeof value === 'string' &&
                    acc[type] == null && vaultCorpusTypes[type] != null) {
                    acc[type] = value;
                    values.importedCount++;
                } else {
                    if (i > 0) {
                        values.invalidCount++;
                    }
                }
                return acc;
            }, {});

            const updatedQueryTerms = getEntries(terms).map(([corpusType, value]) => ({corpusType, value}));
            setQueryTerms(updatedQueryTerms);
            dispatch(GuidedJobModel.showImportPopup(values));
        } catch (error) {
            dispatch(SchedulerModel.actionCreators.handleResponseError(error));
        } finally {
            dispatch(GuidedJobModel.actionCreators.update({isDisabled: false}));
        }
    }, []);

    const skipTermsToggleHandler = useCallback(event => {
        const {name, checked} = event.target;
        updateState({[name]: checked});
        // Reset queryTerms
        if (stringToBool(checked)) {
            setQueryTerms([]);
        }
    }, []);

    const isQueryTermsDisabled = isDisabled || guidedJobForm.skipServiceQueryTerms;
    const isAddDisabled = queryTerms.length >= allowedTypes.length;

    return (
        <div>
            <div className="display-input">
                <Text value={t('guidedJob:message.vaultServiceQueryTermsDescription')}
                    isDisabled={isDisabled}/>
            </div>

            <div className="display-input">
                <Checkbox label={t('guidedJob:label.skipServiceQueryTerms')} name={'skipServiceQueryTerms'}
                    value={guidedJobForm.skipServiceQueryTerms} onClick={skipTermsToggleHandler} isDisabled={isDisabled}/>
            </div>

            <div className="display-input">
                <DataSourcesTable id={"vaultLocations"} label={t('guidedJob:label.vaultLocations')} dataSources={queryTerms}
                    setDataSources={setQueryTerms} defaultObject={defaultObject} headers={headers.current}
                    RowCells={VaultServiceQueryTermsRowCells} rowProps={rowProps} exportCsv={exportCsv} isRequired={false}
                    ariaLabelKey={'ServiceQueryTerm'} importCsv={importCsv} isAddDisabled={isAddDisabled} isDisabled={isQueryTermsDisabled}
                    afterLabel={
                        <a href={vaultSearchTermsGuideUrl} className="button is-img" target="_blank" rel="noopener noreferrer"
                            aria-label={t('guidedJob:message.vaultSearchTermsGuide')} style={{marginLeft: '0.25rem'}}
                        >
                            <span className="icon">
                                <img src={icon("operationHelper")} alt={t(`aria:hiddenAssistText.userGuideIcon`)}/>
                            </span>
                        </a>
                    }/>
            </div>
        </div>
    )
}

function VaultServiceQueryTermsRowCells(props) {
    const {t} = useTranslation(['aria']);
    const {
        rIndex,
        selectedTypes,
        allowedTypes,
        dataSource,
        inputHandler,
        dropdownHandler,
        dropdownButtonStyle,
        isSelected,
        isDisabled
    } = props;

    return (
        <>
            <td style={{height: '1px'}}>
                <VaultCorpusTypeDropdown id={`vaultCorpusTypeDropdown${rIndex}`} name={'corpusType'}
                    value={dataSource.corpusType} onItemSelect={dropdownHandler} buttonStyle={dropdownButtonStyle}
                    allowedTypes={allowedTypes} hiddenValues={selectedTypes} isRequired
                    isSelected={isSelected} isDisabled={isDisabled}/>
            </td>
            <td style={{height: '1px'}}>
                <HTMLTextInput id={`vaultServiceQueryTermsValue${rIndex}`} name={'value'} value={dataSource.value}
                    placeholder={servicePlaceHolders[dataSource.corpusType]} onChange={inputHandler}
                    aria-label={t('aria:hiddenAssistText.queryTerm')} isRequired isSelected={isSelected} isDisabled={isDisabled}/>
            </td>
        </>
    )
}

const queryAllowedTypes = getValues(vaultCorpusTypes);
const holdAllowedTypes = [vaultCorpusTypes.MAIL, vaultCorpusTypes.GROUPS];

const defaultObject = {
    corpusType: null,
    value: ''
}

const servicePlaceHolders = {
    [vaultCorpusTypes.MAIL]: 'from:username1 subject:Hello has:attachment',
    [vaultCorpusTypes.DRIVE]: 'type:document owner:username1',
    [vaultCorpusTypes.GROUPS]: 'from:group1 has:attachment',
    [vaultCorpusTypes.HANGOUTS_CHAT]: 'is:dm',
    [vaultCorpusTypes.VOICE]: 'is:voicemail with:+14155550132',
}

const vaultSearchTermsGuideUrl = 'https://support.google.com/vault/answer/2474474?sjid=3352073077541228724-NC'
