import React, {useState} from "react";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {applicationFeatures, details} from "../../utilities/constants";
import LegalHoldModel from "../../models/legalhold/LegalHoldModel";
import {getEntries, getObjectText, isNotEmptyNorFalsy} from "../../utilities/helperFunctions";
import SearchBar from "../common/SearchBar/SearchBar";
import LegalHoldRow from "./LegalHoldRow";
import Switch from "../common/Switch/Switch";
import LegalHoldTablet from "./LegalHoldTablet";
import LegalHoldForm from "./LegalHoldForm";
import {AddButtonHeader} from "../common/Button/Button";
import {TableLabelHeader} from "../common/CustomTable/CustomTable";


function selectLegalHoldIds(state, props) {
    return getEntries(state.legalHoldDetailsMap)
        .filter(([ignore, _legalHold]) =>
            getObjectText(_legalHold, {blacklist: ['id', 'matterId', 'clientId']}).includes(props.searchText.toLowerCase()))
        .map(([id]) => id);
}

function LegalHoldMatterDisplay() {
    const {t} = useTranslation(['legalHold', 'common']);
    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState('');

    const legalHoldIds = useSelector(state => selectLegalHoldIds(state, {searchText}));
    const canAddLegalHold = useSelector(state => state.currentUser.features.includes(applicationFeatures.ADD_LEGAL_HOLD));

    const isLoading = useSelector(state => !state.hasLoaded[details.LEGAL_HOLDS]);

    const {
        legalHoldId,
        isLegalHoldFormActive
    } = useSelector(state => state.componentStates.legalHoldDisplay);

    function showForm() {
        dispatch(LegalHoldModel.actionCreators.showForm());
    }

    function showTablet(event) {
        const {value} = event.currentTarget.dataset;
        dispatch(LegalHoldModel.actionCreators.showTablet(value));
    }

    return (
        <>
            <section className="mainPage-header">
                <section className="header-section left">
                    <article className="header-item">
                        <AddButtonHeader useH1 aria-label={t('aria:hiddenAssistText.addLegalHold')} text={t('legalHold:label.name')}
                            onClick={showForm} style={{margin: '0'}} canModify={canAddLegalHold}/>
                    </article>
                </section>

                <section className="header-section right">
                    <article className="header-item">
                        <SearchBar id="legalHoldSearchBar" value={searchText} onChange={event => setSearchText(event.target.value)}/>
                    </article>
                </section>
            </section>

            <section className="mainPage-content" id={'legalHoldMattersDisplay'}>
                <LoadingWrapper isLoading={isLoading}>

                    {isNotEmptyNorFalsy(legalHoldIds) &&
                    <div className="display-table">
                        <div className="table-header-group">
                            <TableLabelHeader label={t('common:label.state')} style={{width: '12rem'}}/>

                            <TableLabelHeader label={t('common:label.name')}/>

                            <TableLabelHeader label={t('legalHold:label.startDate')} style={{width: '8rem'}}/>

                            <TableLabelHeader label={t('legalHold:label.endDate')} style={{width: '8rem'}}/>
                        </div>

                        <div className="table-row-group">
                            {legalHoldIds.map(id =>

                                <LegalHoldRow key={id} legalHoldId={id} onSelect={showTablet}
                                    isActive={id === legalHoldId}
                                />
                            )}
                        </div>
                    </div>
                    }
                </LoadingWrapper>
            </section>

            <Switch>
                {isLegalHoldFormActive &&
                <LegalHoldForm/>
                }

                {legalHoldIds.includes(legalHoldId) &&
                <LegalHoldTablet legalHoldId={legalHoldId}/>
                }
            </Switch>
        </>
    )
}

export default LegalHoldMatterDisplay;
