import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import React, {useCallback, useRef, useState} from "react";
import {
    initialSelectedState,
    useCutCopyPasteAndDelete,
    useKeyPressEffect,
    useMoveUpDown,
    useScrollIntoView,
    useSelectAll,
    useValueSelectHandler
} from "../../../utilities/hooks";
import {
    buildClassName,
    deepCopy,
    filterArrayIndices,
    getValues,
    objectTruthyValues,
    textContainsEverySearchWord
} from "../../../utilities/helperFunctions";
import WorkflowBuilderOperation from "../../../models/workflowbuilder/WorkflowBuilderOperation";
import PopupModel from "../../../models/scheduler/PopupModel";
import {AddRemoveButtons} from "../../common/Button/Button";
import Text from "../../common/Text/Text";
import {MenuDropdown} from "../../common/Dropdown/Dropdown";
import SearchBar from "../../common/SearchBar/SearchBar";
import {operationIconModel} from "../../../models/generics/IconModel";
import WorkflowBuilderModel from "../../../models/workflowbuilder/WorkflowBuilderModel";
import NavigateButtons from "../../common/NavigateButtons/NavigateButtons";
import {createFileHandler} from "../../../utilities/componentFunctions";
import encode from "base64-arraybuffer";
import OperationStateIcons from "./OperationStateIcon";

function OperationsList(props) {
    const {t} = useTranslation(['workflowBuilder', 'aria']);
    const dispatch = useDispatch();

    const {
        operations,
        formConfigurations,
        updateState,
        addOperation,
        selected,
        setSelected,
        isDisabled
    } = props;

    const workflowUploadRef = useRef();
    const containerRef = useRef();
    const setOperations = useCallback(function (updates) {
        updateState(prevState => ({operations: typeof updates === 'function' ? updates(prevState.operations) : updates}));
    }, []);

    const onPasteOperations = useCallback(() => {
        navigator.clipboard.readText().then((text) => {
            try {
                const operationsArray = JSON.parse(text).map((op) => new WorkflowBuilderOperation(op));
                addOperation(operationsArray, false, true);
            } catch (e) {
                console.log("Cannot paste from clipboard", e)
            }
        });
    }, [addOperation]);

    function onCopyOperations () {
        const selectedValues = objectTruthyValues(selected.values).sort((e1, e2) => e1 - e2);
        const selectedOperations = selectedValues.map(value => deepCopy(operations[value]));

        const jsonStr = JSON.stringify(selectedOperations, undefined, 2);
        navigator.clipboard.writeText(jsonStr).catch((ex) => {
            console.log("Cannot write to clipboard",ex)
        });
    }

    function onCutOperations () {
        function cutOperations () {
            onCopyOperations();

            const selectedRowIndices = objectTruthyValues(selected.values);
            const newOperations = filterArrayIndices(operations, selectedRowIndices);
            setSelected(() => autoSelectNextOperation(selectedRowIndices, newOperations));
            setOperations(newOperations)
        }

        const selectedCount = objectTruthyValues(selected.values).length;
        showPopup(WorkflowBuilderOperation.Action.CUT, operations[selected.lastSelectedValue].operationAlias, selectedCount, cutOperations);
    }

    function onDeleteOperations() {
        actionOptionHandler({currentTarget: {dataset: {value: WorkflowBuilderOperation.Action.DELETE}}})
    }

    useSelectAll({containerRef, selected, array: operations, setSelected})
    useCutCopyPasteAndDelete({containerRef, selected, array: operations, onPaste: onPasteOperations, onCut: onCutOperations, onDelete: onDeleteOperations});
    const keyToCb = useRef({'Enter': 'click'});
    useKeyPressEffect({containerRef, keyToCb: keyToCb.current});

    const valueSelectHandler = useValueSelectHandler({setSelected});
    const [moveUp, moveDown] = useMoveUpDown({selected, setSelected, setArray: setOperations});

    const [searchText, setSearchText] = useState('');
    function onSearchTextChange(event) {
        setSearchText(event.target.value);
    }

    function onAddOperation() {
        dispatch(WorkflowBuilderModel.actionCreators.update(prevState => ({
            isAddOperationFormActive: true,
            operationFilter: prevState.operationFilter.duplicate({searchText: ''})
        })));
    }

    function onRemoveOperations() {
        actionOptionHandler({currentTarget: {dataset: {value: WorkflowBuilderOperation.Action.DELETE}}});
    }

    function onOperationSelect(event) {
        valueSelectHandler(event);
    }

    function openWorkflowFileForm() {
        workflowUploadRef.current.click();
    }

    const onWorkflowFileChange = createFileHandler({
        readAs: 'readAsArrayBuffer',
        onloadend: (event) => {
            const workflowXml = encode.encode(event.target.result)
            dispatch(WorkflowBuilderModel.actionCreators.appendWorkflow(workflowXml, setSelected));
        }
    });

    function actionOptionHandler(event) {
        const {value: action} = event.currentTarget.dataset;
        const selectedRowIndices = objectTruthyValues(selected.values);

        function performBulkAction() {
            setOperations(prevOperations => {
                if (action === WorkflowBuilderOperation.Action.DELETE) {
                    const newOperations = filterArrayIndices(prevOperations, selectedRowIndices);
                    setSelected(() => autoSelectNextOperation(selectedRowIndices, newOperations));
                    return newOperations;
                }
                const property = WorkflowBuilderOperation.getActionProperty(action);
                const value = [WorkflowBuilderOperation.Action.DISABLE, WorkflowBuilderOperation.Action.MAKE_SKIPPABLE,
                    WorkflowBuilderOperation.Action.ENABLE_SOFT_FAIL, WorkflowBuilderOperation.Action.ENABLE_SUPPRESS_WARNINGS,
                    WorkflowBuilderOperation.Action.ENABLE_FIELD_OVERWRITE].includes(action);

                const newOperations = [...prevOperations];
                for (const index of selectedRowIndices) {
                    newOperations[index] = newOperations[index].shallowDuplicate({[property]: value});
                }
                return newOperations;
            });
        }

        switch (action) {
            case WorkflowBuilderOperation.Action.MAKE_SKIPPABLE:
            case WorkflowBuilderOperation.Action.ENABLE_SOFT_FAIL:
            case WorkflowBuilderOperation.Action.ENABLE_SUPPRESS_WARNINGS:
            case WorkflowBuilderOperation.Action.DELETE:
                showPopup(action, operations[selected.lastSelectedValue].operationAlias, selectedRowIndices.length, performBulkAction);
                break;
            case WorkflowBuilderOperation.Action.APPEND_WORKFLOW:
                openWorkflowFileForm();
                break;
            case WorkflowBuilderOperation.Action.CUT:
                onCutOperations();
                break;
            case WorkflowBuilderOperation.Action.COPY:
                onCopyOperations();
                break;
            case WorkflowBuilderOperation.Action.PASTE:
                onPasteOperations();
                break;
            default:
                performBulkAction();
                break;
        }
    }

    function showPopup (action, alias, count, callbackFn) {
        dispatch(PopupModel.actionCreators.showWarning({
            info: {
                key: `workflowBuilderOperationWarning${action}`,
                values: {
                    count: count,
                    name: t(`workflowBuilder:operationAlias.${alias}`)
                }
            },
            buttons: [{
                titleKey: `workflowBuilder:operationAction.${action}`,
                onClick: callbackFn
            }]
        }));
    }


    const enableAction = {};
    const selectedIndices = objectTruthyValues(selected.values);
    if (selectedIndices.length > 0 && !isDisabled) {
        for (const index of selectedIndices) {
            const op = operations[index];
            if (op.disabled) {
                enableAction[WorkflowBuilderOperation.Action.ENABLE] = true;
            } else {
                enableAction[WorkflowBuilderOperation.Action.DISABLE] = true;
            }
            if (op.skippable) {
                enableAction[WorkflowBuilderOperation.Action.REMOVE_SKIPPABLE] = true;
            } else {
                enableAction[WorkflowBuilderOperation.Action.MAKE_SKIPPABLE] = true;
            }
            if (op.softFail) {
                enableAction[WorkflowBuilderOperation.Action.DISABLE_SOFT_FAIL] = true;
            } else {
                enableAction[WorkflowBuilderOperation.Action.ENABLE_SOFT_FAIL] = true;
            }
            if (op.suppressWarnings) {
                enableAction[WorkflowBuilderOperation.Action.DISABLE_SUPPRESS_WARNINGS] = true;
            } else {
                enableAction[WorkflowBuilderOperation.Action.ENABLE_SUPPRESS_WARNINGS] = true;
            }
            if (op.enableFieldOverwrite) {
                enableAction[WorkflowBuilderOperation.Action.DISABLE_FIELD_OVERWRITE] = true;
            } else {
                enableAction[WorkflowBuilderOperation.Action.ENABLE_FIELD_OVERWRITE] = true;
            }
        }
    }

    const {
        [WorkflowBuilderOperation.Action.DELETE]: deleteAction,
        [WorkflowBuilderOperation.Action.APPEND_WORKFLOW]: appendWorkflowAction,
        [WorkflowBuilderOperation.Action.CUT]: cutAction,
        [WorkflowBuilderOperation.Action.COPY]: copyAction,
        [WorkflowBuilderOperation.Action.PASTE]: pasteAction,
        ...workflowActions
    } = WorkflowBuilderOperation.Action;

    const actionOptions = getValues(workflowActions).map(action => ({
        name: t(`workflowBuilder:operationAction.${action}`), value: action, isDisabled: !enableAction[action]
    }));
    actionOptions.push(
        {isSeparator: true},
        {name: t(`workflowBuilder:operationAction.${appendWorkflowAction}`), value: appendWorkflowAction, isDisabled: isDisabled},
        {isSeparator: true},
        {name: t(`workflowBuilder:operationAction.${cutAction}`), value: cutAction, isDisabled: selectedIndices.length < 1 || isDisabled},
        {name: t(`workflowBuilder:operationAction.${copyAction}`), value: copyAction, isDisabled: selectedIndices.length < 1 || isDisabled},
        {name: t(`workflowBuilder:operationAction.${pasteAction}`), value: pasteAction, isDisabled: isDisabled},
        {name: t(`workflowBuilder:operationAction.${deleteAction}`), value: deleteAction, isDisabled: selectedIndices.length < 1 || isDisabled}
    );


    const isAddDisabled = isDisabled;
    const isRemoveDisabled = selectedIndices.length < 1 || isDisabled;

    const filteredOperations = operations.map((operation, index) => [operation, index]).filter(([operation], index) => {
        const objectText = `${index + 1}.` + operation.getText(t);
        return textContainsEverySearchWord(searchText, objectText);
    });

    return (
        <aside className="workflow-builder__operations-list">
            <section className="workflow-builder__sticky-header">
                <div className="actions-row">
                    <div>
                        <div className="action-item flex-center">
                            <AddRemoveButtons onAddClick={onAddOperation} onRemoveClick={onRemoveOperations}
                                ariaLabelKey={'Operation'} isAddDisabled={isAddDisabled}
                                isRemoveDisabled={isRemoveDisabled} isDisabled={isDisabled}/>

                            <NavigateButtons onClick={[moveUp, moveDown]} length={[selectedIndices.length, selectedIndices.length]}
                                ariaLabelKey={'Operation'} isVertical isDisabled={isDisabled}/>
                        </div>
                    </div>

                    <div className="action-item">
                        <MenuDropdown id={'workflowBuilderOperationActionMenuDropdown'} menuOptions={actionOptions} onOptionClick={actionOptionHandler}
                            selectedItems={t('workflowBuilder:option.action')} aria-label={t('aria:hiddenAssistText.workflowBuilderOperationActionMenu')}
                            isIconDropdown={false} menuIcon={null} isDisabled={isDisabled}/>
                    </div>
                </div>

                <div className="actions-row">
                    <SearchBar value={searchText} onChange={onSearchTextChange} style={{width: '100%'}}
                        isDisabled={isDisabled}/>
                </div>
            </section>

            <section className="workflow-builder__operations-list-container" ref={containerRef}>
                <div className="table-row-group">
                    {filteredOperations.map(([operation, index]) => {
                        const formConfiguration = formConfigurations.get(operation.operationAlias);
                        return (
                            <OperationRow operation={operation} index={index} key={index} onClick={onOperationSelect}
                                formConfiguration={formConfiguration} isSelected={selected.values[index]} isDisabled={isDisabled}/>
                        )
                    })}
                    {operations.length > 0 && filteredOperations.length === 0 &&
                        <Text className="workflow-builder__operations-list-filtered-note"
                            value={t('workflowBuilder:message.operationsHiddenByFilter')} isItalic isDisabled={isDisabled}/>
                    }
                </div>
            </section>

            <input ref={workflowUploadRef} type="file" accept=".rfn,.zip"
                   style={{display: 'none'}} onChange={onWorkflowFileChange}/>
        </aside>
    )
}

function OperationRow(props) {
    const {t} = useTranslation(['workflowBuilder']);

    const {
        operation,
        formConfiguration,
        index,
        onClick,
        isSelected,
        isDisabled
    } = props;

    const ref = useRef();
    useScrollIntoView({ref, trigger: isSelected});

    const operationIcon = operationIconModel.useIcon(operation.operationAlias);
    const className = buildClassName(
        'table-row workflow-builder__operation-row',
        isSelected && 'is-selected',
        (formConfiguration == null || (!formConfiguration.isValid(operation) && !operation.enableFieldOverwrite)) && 'is-required',
        isDisabled && 'is-disabled',
        operation.disabled && 'operation-disabled',
        operation.softFail && !operation.disabled && 'operation-softFail',
        operation.suppressWarnings && !operation.disabled && 'operation-suppressWarnings'
    );

    const operationName = t(`workflowBuilder:operationAlias.${operation.operationAlias}`);

    return (
        <div className={className} data-index={index} tabIndex={0}
            ref={ref} onClick={onClick}
        >
            <div className="table-cell">
                <Text value={`${index + 1}.`}/>
            </div>

            <div className="table-cell workflow-builder__operation-cell">
                <div className="flex-center space-between">
                    <div className="flex-center" style={{overflow: 'hidden', marginRight: '0.5rem'}}>
                        <span className="icon" style={{marginRight: '0.5rem'}}>
                            <img src={operationIcon} alt={operationName}/>
                        </span>
                        <Text value={operationName} isEllipsis/>
                    </div>

                    <OperationStateIcons operation={operation}/>
                </div>

                <div style={{height: '1rem', marginTop: '0.25rem'}}>
                    <Text className={'workflow-builder__operation-notes'}
                        isEllipsis value={operation.notes}/>
                </div>
            </div>
        </div>
    )
}

function autoSelectNextOperation(selectedRowIndices, newOperations) {
    let newIndex = (Math.max(...selectedRowIndices) + 1) - selectedRowIndices.length;

    while (newIndex > 0 && newOperations[newIndex] == null) {
        newIndex--;
    }
    if (newIndex < 0 || newOperations[newIndex] == null) {
        return initialSelectedState;
    }

    return {...initialSelectedState, values: {[newIndex]: true}, lastSelectedValue: newIndex};
}

export default OperationsList;
