import ReduxModel from "../generics/ReduxModel";
import SagaRunnable from "../generics/SagaRunnable";
import {
    actionCreator,
    bytesCountToReadableCount,
    getBytesPowAndSuffix,
    getEntries,
    getInputFormattedDateAndTime,
    getKeys,
    getLocaleDateTimeFromUTC,
    getMapValueName,
    getReadableStopWatch,
    objectTruthyValues
} from "../../utilities/helperFunctions";
import ComponentStateModel from "../generics/ComponentStateModel";
import {axiosInstance} from "../api/AxiosProxy";
import {all, fork, put, select, takeLeading} from "redux-saga/effects";
import {contextCall, contextPollUntil, contextSaga} from "../../saga/sagaFunctions";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import {details} from "../../utilities/constants";
import UploadInfoModel, {UploadInfoSaga} from "./UploadInfoModel";
import ClientModel from "../client/ClientModel";
import MatterModel from "../client/MatterModel";
import DataRepositoryModel from "./DataRepositoryModel";
import {DetailsSaga} from "../generics/DetailsModel";
import DatasetModel from "./DatasetModel";
import i18next from "i18next";
import {uploadInfoStateKeys} from "../../i18next/keys";
import {
    selectFilteredFileUploadHistories,
    selectFilteredUploadInfos
} from "../../components/uploadMonitor/UploadMonitorDisplay";

class FileUploadHistoryModel extends ReduxModel {

    static nom = 'FileUploadHistoryModel';
    static actions = FileUploadHistoryModel.buildActions();
    static actionCreators = FileUploadHistoryModel.buildActionCreators(FileUploadHistoryModel.actions);
    static reducer = FileUploadHistoryModel.buildReducer(FileUploadHistoryModel.actions);

    static componentActionCreators = {
        ...FileUploadHistoryModel.buildComponentUpdateActionCreators(),
        ...FileUploadHistoryModel.buildComponentSetActiveActionCreators()
    };

    constructor(model={}) {
        super(model);

        this.id = model.id;
        this.username = model.username;
        this.clientIpAddresses = model.clientIpAddresses;
        this.serverIpAddress = model.serverIpAddress;
        this.serverName = model.serverName;
        this.serverRole = model.serverRole;

        this.clientId = model.clientId;
        this.matterId = model.matterId;
        this.datasetId = model.datasetId;
        this.dataRepositoryId = model.dataRepositoryId;

        this.fileCount = model.fileCount;
        this.interruptedCount = model.interruptedCount;
        this.size = model.size;
        this.uploadDuration = model.uploadDuration;
    }

    static buildActions() {
        return {
            SET_DETAILS_MAP: 'SET_FILE_UPLOAD_HISTORY_MAP',
            CLEAR_DETAILS_MAP: 'CLEAR_FILE_UPLOAD_HISTORY_MAP',

            QUERY_PAST_AND_ACTIVE_UPLOADS: 'QUERY_PAST_AND_ACTIVE_UPLOADS',
            START_POLLING_PAST_AND_ACTIVE_UPLOADS: 'START_POLLING_PAST_AND_ACTIVE_UPLOADS',
            STOP_POLLING_PAST_AND_ACTIVE_UPLOADS: 'STOP_POLLING_PAST_AND_ACTIVE_UPLOADS',

            COPY_UPLOAD_MONITOR_TABLES: 'COPY_UPLOAD_MONITOR_TABLES'
        }
    }

    static buildActionCreators(actions) {
        return {
            setDetailsMap: actionCreator(actions.SET_DETAILS_MAP, 'details'),
            clearDetailsMap: actionCreator(actions.CLEAR_DETAILS_MAP),

            queryPastAndActiveUploads: actionCreator(actions.QUERY_PAST_AND_ACTIVE_UPLOADS),
            startPollingPastAndActiveUploads: actionCreator(actions.START_POLLING_PAST_AND_ACTIVE_UPLOADS),
            stopPollingPastAndActiveUploads: actionCreator(actions.STOP_POLLING_PAST_AND_ACTIVE_UPLOADS),

            copyUploadMonitorTables: actionCreator(actions.COPY_UPLOAD_MONITOR_TABLES)
        }
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'uploadMonitorDisplay',
                type: 'Display',
                state: {
                    isPollingActive: true,
                    searchText: '',
                    fromDate: getInputFormattedDateAndTime(-1)[0],
                    toDate: getInputFormattedDateAndTime()[0],
                    usernames: {},
                    clientIds: {},
                    matterIds: {},
                    datasetIds: {},
                    dataRepositoryIds: {}
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'UPLOAD_MONITOR_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }

    static buildReducer(actions) {
        return function (state = new Map(), action) {
            switch (action.type) {
                case actions.SET_DETAILS_MAP: {
                    const {details} = action.payload;

                    this.lastUpdated = Date.now();
                    return this.setDetailsMapGeneric(state, details, 'id');
                }
                case actions.CLEAR_DETAILS_MAP: {
                    this.lastUpdated = Date.now();
                    return new Map();
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }
}

export class FileUploadHistoryApi {

    static getFileUploadHistory(filter) {
        return axiosInstance.post('/scheduler/resources/dataset/fileUploadHistory', filter);
    }
}

export class FileUploadHistorySaga extends SagaRunnable {

    static activationComponent = 'UPLOAD_MONITOR_DISPLAY';

    static buildActivationEffects(dispatch) {
        return [
            takeLeading([FileUploadHistoryModel.actions.SET_DETAILS_MAP, UploadInfoModel.actions.SET_DETAILS_MAP], contextSaga(FileUploadHistorySaga, 'getModelNames')),
            takeLeading(FileUploadHistoryModel.actions.COPY_UPLOAD_MONITOR_TABLES, contextSaga(this, 'copyUploadMonitorTables')),

            // Query on-start
            fork(function* () {
                const {isPollingActive} = yield select(state => state.componentStates.uploadMonitorDisplay);
                if (!isPollingActive) {
                    yield put(FileUploadHistoryModel.actionCreators.queryPastAndActiveUploads());
                }
            })
        ]
    }

    static buildDeactivationEffects() {
        return [
            put(FileUploadHistoryModel.actionCreators.stopPollingPastAndActiveUploads())
        ]
    }

    static* copyUploadMonitorTables() {
        const [clientMap, matterMap, datasetMap, dataRepositoryMap, filter] =
            yield select(state => [state.clientDetailsMap, state.matterDetailsMap, state.datasetDetailsMap, state.dataRepositoryDetailsMap, state.componentStates.uploadMonitorDisplay]);

        function translateFilterSetToNames(set, map) {
            return getKeys(set).map(id => getMapValueName(map, id));
        }

        const tsvRows = ['Filter'];
        const filterHeaders = ['searchText', 'fromDate', 'toDate', 'usernames', 'clients', 'matters', 'datasets', 'dataRepositories'];
        tsvRows.push(filterHeaders.map(header => i18next.t(`uploadMonitor:label.${header}`)));

        const filterValues = [
            filter.searchText, filter.fromDate, filter.toDate, getKeys(filter.usernames).join(';'),
            translateFilterSetToNames(filter.clientIds, clientMap).join(';'), translateFilterSetToNames(filter.matterIds, matterMap).join(';'),
            translateFilterSetToNames(filter.datasetIds, datasetMap).join(';'), translateFilterSetToNames(filter.dataRepositoryIds, dataRepositoryMap).join(';')
        ];
        tsvRows.push(filterValues);

        const uploadInfos = yield select(selectFilteredUploadInfos);
        if (uploadInfos.length > 0) {
            tsvRows.push('');
            tsvRows.push(i18next.t('uploadMonitor:label.activeUploads'));

            const headers = ['user', 'clientIpAddress', 'serverIpAddress', 'serverName', 'serverRole', 'client', 'matter', 'dataset', 'dataRepository',
                'progress', 'filePath', 'speed', 'uploaded', 'interruptions', 'uploadDuration', 'expiryDate'];
            tsvRows.push(headers.map(header => i18next.t(`uploadMonitor:label.${header}`)));

            for (const info of uploadInfos) {
                const percentageComplete = ((info.offset / info.length) * 100).toFixed(0);
                const speed = info.transferInterval ? (info.lastTransferred / (info.transferInterval / 1000)) : 0;

                const {pow, suffix} = getBytesPowAndSuffix(info.length);
                const offsetFixed = (info.offset / Math.pow(1000, pow)).toFixed((pow > 2) ? 2 : 0);
                const lengthFixed = (info.length / Math.pow(1000, pow)).toFixed((pow > 2) ? 2 : 0);

                const uploadProgress = i18next.t('uploadMonitor:message.offsetOfLength', {offset: offsetFixed, length: lengthFixed, suffix});

                const values = [
                    info.ownerKey, info.clientIpAddresses, info.serverIpAddress, info.serverName, info.serverRole && i18next.t(`scheduler:role.${info.serverRole}`),
                    getMapValueName(clientMap, info.clientId), getMapValueName(matterMap, info.matterId), getMapValueName(datasetMap, info.datasetId), getMapValueName(dataRepositoryMap, info.dataRepositoryId),
                    `${percentageComplete}%`, info.relativePath, info.state === uploadInfoStateKeys.ACTIVE ? `${bytesCountToReadableCount(speed)}/s` : '', uploadProgress,
                    info.interruptedCount, getReadableStopWatch(info.uploadDuration), getLocaleDateTimeFromUTC(info.expiryDate).replace(',', ' ')
                ];
                tsvRows.push(values);
            }
        }

        const fileUploadHistories = yield select(selectFilteredFileUploadHistories);
        if (fileUploadHistories.length > 0) {
            tsvRows.push('');
            tsvRows.push(i18next.t('uploadMonitor:label.pastUploads'));

            const headers = ['user', 'clientIpAddress', 'serverIpAddress', 'serverName', 'serverRole', 'client', 'matter', 'dataset', 'dataRepository',
                'files', 'size', 'averageSpeed'];
            tsvRows.push(headers.map(header => i18next.t(`uploadMonitor:label.${header}`)));

            for (const history of fileUploadHistories) {
                const averageSpeed = history.uploadDuration ? (history.size / (history.uploadDuration / 1000)) : 0;
                const averageSpeedText = `${bytesCountToReadableCount(averageSpeed)}/s`;

                const values = [
                    history.username, history.clientIpAddresses, history.serverIpAddress, history.serverName, history.serverRole && i18next.t(`scheduler:role.${history.serverRole}`),
                    getMapValueName(clientMap, history.clientId), getMapValueName(matterMap, history.matterId), getMapValueName(datasetMap, history.datasetId), getMapValueName(dataRepositoryMap, history.dataRepositoryId),
                    history.fileCount, bytesCountToReadableCount(history.size), averageSpeedText
                ];
                tsvRows.push(values);
            }
        }

        const tsvText = tsvRows.map(row => typeof row === 'string' ? row : row.join('\t')).join('\n');
        navigator.clipboard.writeText(tsvText)
            .catch(err => console.log('Failed to copy text:', err));
    }

    static* buildFilter() {
        const {
            fromDate,
            toDate,
            usernames,
            clientIds,
            matterIds,
            datasetIds,
            dataRepositoryIds
        } = yield select(state => state.componentStates.uploadMonitorDisplay);

        const from = new Date(fromDate.split('-'));
        const to = new Date(toDate.split('-'));
        to.setDate(to.getDate() + 1);
        to.setMilliseconds(to.getMilliseconds() - 1);

        return {
            afterDate: from.getTime(),
            beforeDate: to.getTime(),
            usernames: objectTruthyValues(usernames),
            clientIds: objectTruthyValues(clientIds),
            matterIds: objectTruthyValues(matterIds),
            datasetIds: objectTruthyValues(datasetIds),
            dataRepositoryIds: objectTruthyValues(dataRepositoryIds)
        };
    }

    static* getModelNames() {
        const models = yield select(state => [...state.fileUploadHistoryDetailsMap.values(), ...state.uploadInfoDetailsMap.values()]);

        const idToType = {};
        for (const model of models) {
            if (model.clientId) {
                idToType[model.clientId] = ClientModel.nom;
            }
            if (model.matterId) {
                idToType[model.matterId] = MatterModel.nom;
            }
            if (model.datasetId) {
                idToType[model.datasetId] = DatasetModel.nom;
            }
            if (model.dataRepositoryId) {
                idToType[model.dataRepositoryId] = DataRepositoryModel.nom;
            }
        }

        const typeToUpdate = yield* DetailsSaga.queryModelNames(idToType);
        const updateActionMap = {
            [ClientModel.nom]: ClientModel.actionCreators.updateDetails,
            [MatterModel.nom]: MatterModel.actionCreators.updateDetails,
            [DatasetModel.nom]: DatasetModel.actionCreators.updateDetails,
            [DataRepositoryModel.nom]: DataRepositoryModel.actionCreators.updateDetails
        };

        const updateEffects = [];
        getEntries(typeToUpdate)
            .forEach(([type, updates]) => {

                const updateAction = updateActionMap[type](updates);
                updateEffects.push(
                    put(updateAction)
                );
            });

        yield all(updateEffects);
    }

    static* pollPastAndActiveUploads(action) {
        const {period} = action.payload;

        yield contextPollUntil(FileUploadHistoryModel.actions.STOP_POLLING_PAST_AND_ACTIVE_UPLOADS, period, this, 'queryPastAndActiveUploads');
    }

    static* queryPastAndActiveUploads() {
        const filter = yield* this.buildFilter();

        yield all([
            contextCall(UploadInfoSaga, 'queryDetails', {payload: {filter}}),
            contextCall(FileUploadHistorySaga, 'queryDetails', {payload: {filter}})
        ]);
    }

    static* queryDetails(action) {
        const {filter} = action.payload;

        try {
            const {data} = yield FileUploadHistoryApi.getFileUploadHistory(filter);
            yield put(FileUploadHistoryModel.actionCreators.setDetailsMap(data));
        } finally {
            yield put(ReduxStateModel.actionCreators.setHasLoaded(details.FILE_UPLOAD_HISTORY))
        }
    }
}

export default FileUploadHistoryModel;