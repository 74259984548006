import React, {useEffect, useRef, useState} from "react";
import Editor, {loader} from '@monaco-editor/react';

import Text from "../../common/Text/Text";
import {buildClassName} from "../../../utilities/helperFunctions";
import {useTranslation} from "react-i18next";
import {Button} from "../../common/Button/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Form from "../../common/Form/Form";
import {getInvalidMessageSpan} from "../../common/InvalidMessageSpan";

if (!window.location.origin.includes('localhost:3000')) {
    loader.config({
        paths: {
            vs: 'monaco'
        }
    });
}

// https://microsoft.github.io/monaco-editor/typedoc/interfaces/editor.ICodeEditor.html
// https://microsoft.github.io/monaco-editor/typedoc/interfaces/editor.IStandaloneEditorConstructionOptions.html#automaticLayout
// console.log(monaco.languages.getLanguages())

const editorOptions = {
    acceptSuggestionOnCommitCharacter: true,
    acceptSuggestionOnEnter: 'on',
    accessibilitySupport: 'auto',
    autoIndent: false,
    automaticLayout: true,
    colorDecorators: true,
    contextmenu: true,
    scrollbar: {useShadows: false},
    cursorBlinking: 'smooth',
    dragAndDrop: true,
    fixedOverflowWidgets: false,
    overviewRulerLanes: 2,
    quickSuggestionsDelay: 100,
    renderLineHighlight: 'none',
    scrollBeyondLastLine: false,
    padding: {top: 6, bottom: 6},
    lineNumbers: 'on'
};

function ScriptPaneField(props) {
    const {t} = useTranslation(['common']);
    const {
        id,
        label,
        scriptLanguage,
        configuration,

        value,
        updateState,

        invalidMessage,
        hideInvalidMessage,
        requiredMessage,

        isInvalid,
        isDisabled
    } = props;

    const [isPopoutActive, setIsPopoutActive] = useState();
    function togglePopoutActive() {
        setIsPopoutActive(prev => !prev);
    }

    const editorRef = useRef();
    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.updateOptions({
                readOnly: isDisabled,
                domReadOnly: isDisabled
            });
        }
    }, [isDisabled]);

    function handleEditorChange(value) {
        updateState({[configuration.name]: value});
    }

    const initialHeight = 18 * 24;
    const containerRef = useRef();
    function handleContentSizeChange() {
        if (containerRef.current != null) {
            const maxHeight = 28 * 24;
            const contentHeight = Math.min(maxHeight, Math.max(initialHeight, editorRef.current.getContentHeight()));
            containerRef.current.style.height = `${contentHeight}px`;
        }
    }

    function handleEditorDidMount(editor, monaco) {
        editorRef.current = editor;
        editor.onDidContentSizeChange(handleContentSizeChange)
    }

    const className = buildClassName(
        'workflow-builder__script-pane',
        isDisabled && 'is-disabled',
        isInvalid && !isDisabled && 'is-required'
    );

    const language = normalizeScriptLanguage(scriptLanguage);
    const path = configuration.name;

    const labelId = label && `${id}:label`;
    const required = isInvalid && !isDisabled;

    const {
        combinedAriaLabelledBy,
        invalidMessageSpan
    } = getInvalidMessageSpan({
        id,
        ariaLabelledBy: [labelId],
        isRequired: required,
        invalidMessage,
        requiredMessage
    });

    if (isPopoutActive) {
        return (
            <PopoutScriptPaneField language={language} value={value} path={path}
                handleEditorChange={handleEditorChange} onClose={togglePopoutActive} isDisabled={isDisabled}/>
        );
    }

    return (
        <>
            <div className="flex-center space-between">
                <Text id={labelId} value={label} isDisabled={isDisabled}/>
                <Button aria-label={t('aria:hiddenAssistText.popupScriptPane')} onClick={togglePopoutActive}
                    isImg isClear isDisabled={isDisabled}
                >
                    <FontAwesomeIcon icon="up-right-from-square"/>
                </Button>
            </div>

            <div>
                <div id={id} data-type={configuration.componentType} className={className} aria-labelledby={combinedAriaLabelledBy}
                    aria-invalid={required} ref={containerRef} style={{height: `${initialHeight}px`}}
                >
                    <Editor options={editorOptions} language={language} path={path}
                        value={value} onMount={handleEditorDidMount} onChange={handleEditorChange}/>
                </div>

                {!hideInvalidMessage && invalidMessageSpan}
            </div>
        </>
    )
}

function PopoutScriptPaneField(props) {
    const {t} = useTranslation(['common']);
    const {
        language,
        value,
        path,
        handleEditorChange,
        onClose,
        isDisabled
    } = props;

    const title = t('workflowBuilder:label.codeEditor');
    return (
        <Form className="workflow-builder__script-pane-popout-body" onClose={onClose}
            disableFocusTrap isDisabled={isDisabled} closeButtonAriaLabel={t('workflowBuilder:option.closePopupScriptPane')}
            header={
                <h2 className={'subtitle is-5 is-bold' + (isDisabled ? ' is-disabled' : '')}>
                    {title}
                </h2>
            }
            body={
                <Editor options={editorOptions} language={language} path={path}
                    value={value} onChange={handleEditorChange}/>
            }
            footer={
                <Button label={t('common:option.close')} onClick={onClose}
                    isDisabled={isDisabled}/>
            }
        />
    )
}

function normalizeScriptLanguage(scriptLanguage) {
    if (scriptLanguage) {
        const language = scriptLanguage.toLowerCase();
        switch (language) {
            case 'ecma_script':
                return 'javascript';
            default:
                return language;
        }
    }
}

export default ScriptPaneField;