import {useEffect as useReactEffect, useState as useReactState} from "react";
import {useEffect as usePreactEffect, useState as usePreactState} from "preact/hooks";
import {axiosInstance} from "../api/AxiosProxy";
import {getFileExtension} from "../../utilities/helperFunctions";

const reactHooks = [useReactEffect, useReactState];
const preactHooks = [usePreactEffect, usePreactState];

class IconModel {

    constructor(getIconFn) {
        this.getIcon = getIconFn;
        this.iconMap = {};
        this.largeIconMap = {};
    }

    useIcon(key, opts={getLargeIcon:false}) {
        const {
            usePreact=false,
            getLargeIcon=false
        } = opts;

        let useEffect, useState;
        if (usePreact) {
            [useEffect, useState] = preactHooks;
        } else {
            [useEffect, useState] = reactHooks;
        }

        let iconMap;
        if (getLargeIcon) {
            iconMap = this.largeIconMap;
        } else {
            iconMap = this.iconMap;
        }

        const [icon, setIcon] = useState(iconMap[key]);
        // If icon is cached, iconMap[key] will contain a URL string to it's blob, else not cached
        useEffect(() => {
            if (key != null && typeof iconMap[key] !== 'string') {

                let isCancelled = false;
                // If undefined => first time seeing @key, start query and track promise
                if (iconMap[key] === undefined) {
                    iconMap[key] = this.getIcon(key, opts, {responseType: 'blob'})
                        .then(res => {
                            iconMap[key] = URL.createObjectURL(res.data);
                        })
                        // Ignore errors
                        .catch(() => {
                            if (!isCancelled) {
                                setIcon(null);
                            }
                        });
                }
                // iconMap[key] is tracking query promise, attach .then() to update icon once query promise has returned
                iconMap[key]
                    .then(() => {
                        if (!isCancelled) {
                            setIcon(iconMap[key]);
                        }
                    });

                return () => isCancelled = true;
            }
        }, [key, iconMap[key] == null]);

        // For case icon updated elsewhere
        useEffect(() => {
            if (iconMap[key] == null || typeof iconMap[key] === 'string') {
                setIcon(iconMap[key]);
            }
        }, [iconMap[key]]);

        return icon;
    }

    clearIcons() {
        clearIconMap(this.iconMap);
        clearIconMap(this.largeIconMap);
    }
}

class FileIconModel extends IconModel {
    useFileNameIcon(fileName, opts={}) {
        const fileExtension = opts.isDirectory ? 'directory' : getFileExtension(fileName, 'empty');
        return super.useIcon(fileExtension, opts);
    }
}

function clearIconMap(iconMap) {
    for (const prop in iconMap) {
        if (iconMap.hasOwnProperty(prop)) {
            window.URL.revokeObjectURL(iconMap[prop]);
            delete iconMap[prop];
        }
    }
}

export const operationIconModel = new IconModel((alias, opts, config) => {
    return axiosInstance.get(`/media/operationIcon/${alias}`, {mimeType: 'image/svg+xml', ...config});
});

export const userIconModel = new IconModel((user, opts, config) => {
    return axiosInstance.get(`/media/userIcon/${user}`, {mimeType: 'image/svg+xml', ...config});
});

export const fileIconModel = new FileIconModel((extension, opts, config) => {
    return axiosInstance.get(`/media/fileIcon/${extension}?getLargeIcon=${!!opts.getLargeIcon}`, {mimeType: 'image/png', ...config});
});

export const mimeTypeIconModel = new IconModel((mimeType, opts, config) => {
    return axiosInstance.post(`/media/mimeTypeIcon?getLargeIcon=${!!opts.getLargeIcon}`, {mimeType}, {mimeType: 'image/png', ...config});
});

export const thirdPartyServiceIconModel = new IconModel((type, opts, config) => {
    return axiosInstance.get(`/platform/application/${type}`, {mimeType: 'image/png', ...config});
});

export function clearAllIcons() {
    operationIconModel.clearIcons();
    userIconModel.clearIcons();
    fileIconModel.clearIcons();
    mimeTypeIconModel.clearIcons();
    thirdPartyServiceIconModel.clearIcons();
}
