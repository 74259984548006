import React from "react";
import {useTranslation} from "react-i18next";
import ExpandableContent from "../../common/ExpandableContent/ExpandableContent";
import {SettingsRowSeparator, SettingsRowValue} from "../../common/CustomTable/CustomTable";
import {boolToString, getLocaleDateTimeFromUTC} from "../../../utilities/helperFunctions";
import Text from "../../common/Text/Text";

function PurviewServiceSettings(props) {
    const {t} = useTranslation(['thirdPartyService']);
    const {
        thirdPartyService,
        style,
        isDisabled
    } = props;

    const downloadUserCredential = thirdPartyService.userCredential.downloadUserCredential;

    return (
        <ExpandableContent label={t('thirdPartyService:label.purviewSettings')}
            style={style} isDisabled={isDisabled}
        >
            <Text value={t(`thirdPartyService:label.usePurviewDownload${boolToString(thirdPartyService.usePurviewDownload)}`)}
                isDisabled={isDisabled}/>

            <div className="settings-table">
                <div className="table-row-group">

                    {thirdPartyService.usePurviewDownload && downloadUserCredential?.signedIn
                        && (downloadUserCredential.username || downloadUserCredential.tokenLastRefreshDate || downloadUserCredential.tokenExpiryDate) &&
                        <>
                            <SettingsRowSeparator/>
                            <SettingsRowValue label={t('thirdPartyService:label.downloaderUsername')} value={downloadUserCredential.username}
                                isDisabled={isDisabled}/>
                            <SettingsRowValue label={t('thirdPartyService:label.downloadTokenLastRefreshDate')}
                                value={getLocaleDateTimeFromUTC(downloadUserCredential.tokenLastRefreshDate)} isDisabled={isDisabled}/>
                            <SettingsRowValue label={t('thirdPartyService:label.downloadTokenExpiryDate')}
                                value={getLocaleDateTimeFromUTC(downloadUserCredential.tokenExpiryDate)} isDisabled={isDisabled}/>
                        </>
                    }
                </div>
            </div>
        </ExpandableContent>
    )
}

export default PurviewServiceSettings;
