import React, {useState} from 'react';
import {getStatusIconImgContent, icon, translatedStatusIcons} from "../../utilities/iconResolver";
import {useTranslation} from "react-i18next";
import ExpandableContent from "./ExpandableContent/ExpandableContent";
import {buildClassName, getLocaleDateTimeFromUTC} from "../../utilities/helperFunctions";
import HighlightText from "../userSettings/HighlightOption/HighlightText";
import Text from "./Text/Text";
import {Button} from "./Button/Button";
import {statusKeys} from "../../i18next/keys";


export function InfoLabel(props) {
    return (
        <StatusLabel iconName={'statusInfo'}
            style={{margin: '0.5rem 0'}} {...props}/>
    )
}

export function WarningLabel(props) {
    return (
        <StatusLabel iconName={'statusWarning'}
            style={{margin: '0.5rem 0'}} {...props}/>
    )
}

export function StatusIcon(props) {
    const {t} = useTranslation(['common']);
    const {
        status,
        codes = [statusKeys.ERROR, statusKeys.WARNING, statusKeys.INFO]
    } = props;

    if (status == null || !codes.includes(status.code)) {
        return null;
    }
    return (
        <span className="icon" title={t(`common:status.${status.code}`)}>
            {translatedStatusIcons(status.code)}
        </span>
    )
}

export function StatusLabel(props) {
    const {iconName='statusWarning', message, style, enableHighlightText, labelStyle, ...attr} = props;

    const disabled = attr.isDisabled ? ' is-disabled' : '';

    return (
        <div style={{display: 'flex', alignItems: 'center', flex: 1, ...style}}>
            <span className={'icon is-small' + disabled} style={{margin: '0 0.5rem'}}>
                {getStatusIconImgContent(iconName)}
            </span>
            <Text style={{flex: 1, margin: 'auto'}} style={labelStyle} {...attr}>
                <HighlightText text={message} isDisabled={!enableHighlightText}/>
            </Text>
        </div>
    )
}

export function ExpandableStatusLogWrapper(props) {
    const {
        status,
        showOnCodes=[statusKeys.WARNING, statusKeys.ERROR],
        ...rest
    } = props;

    if (status == null) {
        return null;
    }
    if (!showOnCodes.includes(status.code)) {
        return null;
    }

    return (
        <div className="display-item">
            <ExpandableStatusLog {...status} {...rest}/>
        </div>
    )
}

export function ExpandableStatusLog(props) {
    const {t} = useTranslation(['common']);
    const {
        lastUpdatedDate,
        code,
        message,
        isDisabled
    } = props;

    const disabled = isDisabled ? ' is-disabled' : '';
    return (
        <ExpandableContent isDisabled={isDisabled}
            header={
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <span className={'icon is-small' + disabled} style={{marginRight: '0.5rem'}}>
                        {translatedStatusIcons(code)}
                    </span>
                    <Text value={t(`common:status.${code}`)} style={{marginTop: '-2px'}}
                        isHeading isDisabled={isDisabled}/>
                </div>
            }
        >
            <table>
                <tbody>
                <tr>
                    {lastUpdatedDate &&
                    <td style={{width: '12.5rem', whiteSpace: 'nowrap', verticalAlign: 'top', paddingRight: '0.5rem'}}>
                        <Text value={`${getLocaleDateTimeFromUTC(lastUpdatedDate)}:`}
                            isDisabled={isDisabled}/>
                    </td>
                    }
                    <td>
                        <Text value={message}
                            isWordWrap isDisabled={isDisabled}/>
                    </td>
                </tr>
                </tbody>
            </table>
        </ExpandableContent>
    );
}

export function CopyLabel(props) {
    const {t} = useTranslation(['common']);
    const {
        value,
        hideLabel,
        style,
        ...rest
    } = props;

    const [showCopiedText, setShowCopiedText] = useState(false)
    function copyToClipboard() {
        navigator.clipboard.writeText(value)
            .catch(err => console.log('Failed to copy text:', err));
        setShowCopiedText(true);
    }

    function hideCopiedText () {
        setTimeout(() => {
            setShowCopiedText(false);
        }, 500);
    }

    const copyButtonAriaLabel = hideLabel ? value : t('common:label.copy');

    return (
        <div className="flex-center" style={style} onMouseLeave={hideCopiedText}>
            {!hideLabel &&
                <Text {...rest}>
                    <HighlightText text={value} isDisabled={rest.isDisabled}/>
                </Text>
            }

            <Button aria-label={copyButtonAriaLabel} name="copyButton"
                isImg onClick={copyToClipboard} icon={<img src={icon('copyToClipboard')}
                alt={t('aria:hiddenAssistText.copyToClipboardIcon')}/>} isDisabled={rest.isDisabled}/>

            {showCopiedText && (
                <Text value={t('common:label.copied')}/>
            )}
        </div>
    )
}

export function CenteredMessage(props) {
    const {
        message,
        isDisabled
    } = props;

    const disabled = isDisabled ? ' is-disabled' : '';

    return (
        <div style={{position: 'absolute', top: '50%', left: '50%'}}>
            <label className={'label is-italic' + disabled} style={{position: 'relative', left: '-50%'}}>
                {message}
            </label>
        </div>
    )
}

export function SpanIcon(props) {
    const {
        size='small',
        isDisabled,
        icon,
        alt,
        ...attr
    } = props;

    const className = buildClassName(
        `icon is-${size}`,
        isDisabled && 'is-disabled'
    );

    return (
        <span className={className} {...attr}>
            <img src={icon} alt={alt}/>
        </span>
    )
}
