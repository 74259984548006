import React from 'react';
import {connect} from 'react-redux';
import Checkbox from "../../common/Checkbox/Checkbox";
import ToggleSwitch from "../../common/ToggleSwitch/ToggleSwitch";
import TextArea from "../../common/TextArea/TextArea";
import {useTranslation} from "react-i18next";
import {createToggleHandler} from "../../common/Checkbox/helpers";
import JobScheduleModel from "../../../models/job/JobScheduleModel";
import {getPluralTranslations} from "../../../utilities/helperFunctions";
import {createInputHandler, createNestedUpdateState, createStateHandler} from "../../../utilities/componentFunctions";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import {Label} from "../../common/Text/Text";

function ScheduleSettingsPane(props) {
    const {updateState} = props;
    const {t} = useTranslation(['common']);

    const reduxHandler = createStateHandler({
        updateState
    });

    const inputHandler = createInputHandler({
        handler: reduxHandler
    });
    const toggleEnable = createToggleHandler({
        handler: reduxHandler
    });

    const {
        scheduleName,
        enabled,
        description,
        conditions
    } = props;

    return (
        <div className="pane-margin">
            <div className="display-input">
                <HTMLTextInput label={t('common:label.name')} name={'scheduleName'} isRequired
                    value={scheduleName} onChange={inputHandler}
                />
            </div>

            <div className="display-input">
                <Checkbox label={t('common:label.active')} name={'enabled'} checked={enabled} onClick={toggleEnable} />
            </div>
            <div className="display-input">
                <TextArea label={t('common:label.description')} name={'description'} value={description} onChange={inputHandler}/>
            </div>

            <div className="display-item">
                <label className="label is-heading">
                    {t('jobSchedule:label.conditions')}
                </label>

                <div className="settings-table align-middle tight-padding no-table-padding no-border">
                    <DateConditionRow name={'commenceAfter'} label={t('jobSchedule:condition.commenceAfter')} property={conditions.commenceAfter} updateState={updateState}/>

                    <DateConditionRow name={'expireAfter'} label={t('jobSchedule:condition.expireAfter')} property={conditions.expireAfter} updateState={updateState}/>

                    <SkipIfConditionRow name={'skipIfJobsRunning'} message={getPluralTranslations(t, 'jobSchedule:condition.jobsRunning', {count: conditions.skipIfJobsRunning.count})}
                        property={conditions.skipIfJobsRunning} updateState={updateState}/>

                    <SkipIfConditionRow name={'skipIfJobsQueued'} message={getPluralTranslations(t, 'jobSchedule:condition.jobsQueued', {count: conditions.skipIfJobsQueued.count})}
                        property={conditions.skipIfJobsQueued} updateState={updateState}/>
                </div>
            </div>
        </div>
    )
}

function DateConditionRow(props) {
    const {label, name, property, updateState} = props;
    const {t} = useTranslation(['aria']);

    const reduxHandler = createStateHandler({
        updateState: createNestedUpdateState(updateState, ['conditions', name])
    });

    const inputHandler = createInputHandler({
        handler: reduxHandler
    });
    const toggleEnable = createToggleHandler({
        handler: reduxHandler
    });

    return (
        <div className="settings-row">
            <div className="table-cell no-stretch">
                <ToggleSwitch id={`${name}Toggle`} label={`${label}:`} name={'enabled'}
                    checked={property.enabled} onClick={toggleEnable} isWordWrap={false}
                />
            </div>

            <div className="table-cell" style={{display: 'flex'}}>
                <HTMLTextInput id={`${name}Date`} aria-label={t('aria:input.date', {name})} name={'date'} value={property.date} type={'date'} onChange={inputHandler}
                    isDisabled={!property.enabled} containerStyle={{flexBasis: '10rem', marginRight: '0.25rem'}}
                />

                <HTMLTextInput id={`${name}Time`} aria-label={t('aria:input.time', {name})} name={'time'} value={property.time}
                    type={'time'} onChange={inputHandler} isDisabled={!property.enabled} containerStyle={{flexBasis: '8rem'}}
                />
            </div>
        </div>
    )
}

function SkipIfConditionRow(props) {
    const {message, name, property, updateState} = props;
    const {t} = useTranslation(['jobSchedule']);

    const reduxHandler = createStateHandler({
        updateState: createNestedUpdateState(updateState, ['conditions', name])
    });

    const inputHandler = createInputHandler({
        handler: reduxHandler
    });
    const toggleEnable = createToggleHandler({
        handler: reduxHandler
    });

    return (
        <div className="settings-row">
            <div className="table-cell no-stretch">
                <ToggleSwitch id={`${name}Toggle`} label={`${t('jobSchedule:condition.skipIf')}:`} name={'enabled'}
                    checked={property.enabled} onClick={toggleEnable} isWordWrap={false}
                />
            </div>

            <div className="table-cell" style={{display: 'flex', alignItems: 'center'}}>

                <HTMLTextInput id={`${name}Count`} name={'count'} type={'number'} containerStyle={{flexBasis: '4rem', marginRight: '0.5rem'}}
                    value={property.count} onChange={inputHandler} isDisabled={!property.enabled} isRequired
                />

                <Label htmlFor={`${name}Count`} value={message}/>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const {componentStates: {jobScheduleForm: {scheduleName, enabled, description, conditions}}} = state;

    return {
        scheduleName,
        enabled,
        description,
        conditions
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateState: updates => dispatch(JobScheduleModel.componentActionCreators.updateForm(updates))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleSettingsPane);