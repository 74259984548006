import React from "react";
import {useTranslation} from "react-i18next";
import {StatusNameDescriptionCell, TableLabelCell, TableLabelHeader, TableRow} from "../common/CustomTable/CustomTable";
import {arrayIsNotEmptyNorFalsy, bytesCountToReadableCount, isNotEmptyNorFalsy} from "../../utilities/helperFunctions";
import {useDispatch} from "react-redux";
import DataRepositoryModel from "../../models/data/DataRepositoryModel";
import {SelectionItem} from "./DataRepositoryTablet";
import {datasetType} from "../../utilities/constants";
import {AddButtonHeader} from "../common/Button/Button";
import {permissionKeys} from "../../i18next/keys";
import LimitedTableRow from "../limited/LimitedTableRow";


function DataRepositoryTable(props) {
    const {t} = useTranslation(['dataRepository', 'common'])
    const dispatch = useDispatch()

    const {
        type,
        canModify,
        activeRepositoryId,
        dataRepositories
    } = props

    function showTablet(event) {
        const {value} = event.currentTarget.dataset
        dispatch(DataRepositoryModel.actionCreators.showDisplay(value))
    }

    function showForm() {
        dispatch(DataRepositoryModel.actionCreators.showForm({type}))
    }

    const isManaged = type === datasetType.MANAGED;
    const isInPlace = type === datasetType.IN_PLACE;

    const isNotEmpty = isNotEmptyNorFalsy(dataRepositories);
    if (!canModify && !isNotEmpty) {
        return null;
    }

    return (
        <div className="display-item">
            <AddButtonHeader aria-label={t(`aria:hiddenAssistText.addDataRepository${type}`)}
                text={t(`dataRepository:label.name_${type}`)} canModify={canModify} onClick={showForm}
            />

            {isNotEmpty &&
                <div className="display-table">
                    <div className="table-header-group">

                        <TableLabelHeader label={t('common:label.name')} style={{width: '25rem'}}/>

                        {(isInPlace || isManaged) &&
                            <TableLabelHeader label={t('dataRepository:label.path')}/>
                        }

                        {isManaged &&
                            <>
                                <TableLabelHeader label={t('dataRepository:label.allowedFileExtensions')}/>

                                <TableLabelHeader label={t('dataRepository:label.quota')} style={{width: '8rem'}} alignRight/>

                                <TableLabelHeader label={t('dataRepository:label.effectiveUsableSpace')} style={{width: '8rem'}} alignRight/>

                                <TableLabelHeader label={t('dataRepository:label.fileSystemUsableSpace')} style={{width: '8rem'}} alignRight/>

                                <TableLabelHeader label={t('dataRepository:label.datasetMaxSize')} style={{width: '8rem'}} alignRight/>

                                <TableLabelHeader label={t('dataRepository:label.uploadMaxSize')} style={{width: '8rem'}} alignRight/>
                            </>
                        }
                    </div>

                    <div className="table-row-group">
                        {dataRepositories.map(repository =>

                            <DataRepositoryRow key={repository.id} type={type} repository={repository}
                                isActive={repository.id === activeRepositoryId} showTablet={showTablet}/>
                        )}
                    </div>
                </div>
            }
        </div>
    )
}

function DataRepositoryRow(props) {

    const {
        type,
        repository,
        isActive
    } = props

    const {
        id,
        status,
        name,
        description,
        path,
        quotaEnabled,
        quota,
        datasetMaxSizeEnabled,
        datasetMaxSize,
        uploadMaxSizeEnabled,
        uploadMaxSize,
        allowedFileExtensions,
        usableSpace,
        computeFileSystemUsableSpace,
        userPermissions
    } = repository;

    const isManaged = type === datasetType.MANAGED;
    const isInPlace = type === datasetType.IN_PLACE;

    const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);
    const canModify = userPermissions.includes(permissionKeys.MODIFY);
    if (isViewLimited && !canModify) {
        let cellCount = 1;
        if (isInPlace || isManaged) {
            cellCount += 1;

            if (isManaged) {
                cellCount += 6;
            }
        }

        return (
            <LimitedTableRow id={id} isActive={isActive} onClick={props.showTablet} cellCount={cellCount}
                             nameComponent={<StatusNameDescriptionCell status={status} name={name} description={description}/>}
            />
        )
    }

    return (
        <TableRow value={id} isActive={isActive} onClick={props.showTablet}>
            <StatusNameDescriptionCell status={status} name={name} description={description}/>

            {(isManaged || isInPlace) &&
                <TableLabelCell label={path} style={{maxWidth: '25rem'}}/>
            }

            {isManaged &&
                <>
                    <div className="table-cell">
                        {arrayIsNotEmptyNorFalsy(allowedFileExtensions) &&
                            <div className="selection-list">
                                {allowedFileExtensions.map(fileExtension =>
                                    <SelectionItem key={fileExtension} value={fileExtension}/>
                                )}
                            </div>
                        }
                    </div>

                    <TableLabelCell alignRight label={quotaEnabled && bytesCountToReadableCount(quota)}/>

                    <TableLabelCell alignRight label={(quotaEnabled || computeFileSystemUsableSpace) &&
                        (usableSpace != null ? bytesCountToReadableCount(usableSpace.effectiveUsableSpace) : '-')
                    }/>

                    <TableLabelCell alignRight label={computeFileSystemUsableSpace &&
                        (usableSpace != null ? bytesCountToReadableCount(usableSpace.fileSystemUsableSpace) : '-')
                    }/>

                    <TableLabelCell alignRight label={datasetMaxSizeEnabled && bytesCountToReadableCount(datasetMaxSize)}/>

                    <TableLabelCell alignRight label={uploadMaxSizeEnabled && bytesCountToReadableCount(uploadMaxSize)}/>
                </>
            }
        </TableRow>
    )
}

export default DataRepositoryTable