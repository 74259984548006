import {buildObj} from "../../utilities/helperFunctions";
import {jobSortKeys, localeKeys} from "../../i18next/keys";
import {componentKeys, userSettings} from "../../utilities/constants";

export const jobDesignerOptionsBuilder = (time, type, position, size, isBold) =>
    buildObj('time', 'type', 'position', 'size', 'isBold', 'isItalic', 'value')(time, type, position, size, isBold, false, '');


export default new Map([
    [userSettings.DID_USER_CHANGE, {
        languageFromEnglish: false
    }],
    [userSettings.UI_LANGUAGE, {
        locale: localeKeys.BROWSER_DEFAULT
    }],
    [userSettings.ACCESSIBILITY, {
        outlineInputsOnFocus: false
    }],
    [userSettings.SHOW_DISABLED_ITEMS, {
        clients: true,
        matters: true,
        libraries: true,
        workflows: true
    }],
    [userSettings.JOB_CARD, {

    }],
    [userSettings.JOB_PANEL, {
        showOperationProcessingSpeed: true,
        showExecutionParameters: false
    }],
    [userSettings.QUEUE_JOB, {
        componentOrder: [componentKeys.NOTES, componentKeys.PARAMETERS_PANEL],
        autoArchiveOnJobResubmit: true
    }],
    [userSettings.DEFAULT_JOB_SETTINGS, {
        executionProfileId: null,
        resourcePoolId: null
    }],
    [userSettings.JOB_QUEUE_SORT, {
        [jobSortKeys.BACKLOG]: {sortOrder: 'NORMAL', sortField: 'PRIORITY_SUBMISSION_DATE'},
        [jobSortKeys.RUNNING]: {sortOrder: 'NORMAL', sortField: 'PRIORITY_SUBMISSION_DATE'},
        [jobSortKeys.FINISHED]: {sortOrder: 'REVERSED', sortField: 'CHANGED_DATE'}
    }],
    [userSettings.JOB_LANE, {
        hideStagingLaneWhenEmpty: true,
        showJobListCount: true
    }],
    [userSettings.DATASET_OPTION, {
        builtInHeaders: {
            name: true,
            addedBy: false,
            addedDate: false,
            hashes: false,
            fileCount: true,
            size: true
        },
        promptUserToRemoveInvalidFiles: true
    }],
    [userSettings.LEGAL_HOLD, {
        warnOnComment: true
    }],
    [userSettings.WORKFLOW_OPTION, {
        showWorkflowOptions: true
    }],
    [userSettings.NEW_CLIENT_POOL, {
        allowedParameterValues: []
    }],
    [userSettings.NEW_CLIENT, {
        allowedParameterValues: []
    }],
    [userSettings.NEW_MATTER, {
        allowedParameterValues: [],
        synchronizeJobs: false
    }],
    [userSettings.HIGHLIGHTS, {
        0: {
            enabled: false,
            regex: '[^\\n]*error[^\\n]*',
            color: 'white',
            backgroundColor: 'red',
            isBold: true,
            isItalic: false,
            isUnderline: false,
            isLineThrough: false,
            lineColor: 'black',
            lineStyle: 'solid'
        },
        1: {
            enabled: false,
            regex: '[^\\n]*warning[^\\n]*',
            color: 'black',
            backgroundColor: 'yellow',
            isBold: false,
            isItalic: false,
            isUnderline: true,
            isLineThrough: false,
            lineColor: 'black',
            lineStyle: 'solid'
        },
        2: {
            enabled: true,
            regex: '\\{[a-z0-9_]+\\}',
            color: 'workflowBlue',
            backgroundColor: 'transparent',
            isBold: false,
            isItalic: false,
            isUnderline: false,
            isLineThrough: false,
            lineColor: null,
            lineStyle: null
        }
    }],
    [userSettings.TROUBLESHOOT, {
        showObjectIds: false,
        showFieldOverwriteParameterNames: false,
        enabledInferredUtilizationEditing: false,
        maskApiGetErrors: true,
        logApiAutomate: false,
        logApiNlp: false,
        logApiDiscover: false,
        logApiRelativity: false,
        logApiPurview: false,
    }],
]);