import React from "react";
import {connect} from "react-redux";
import NotificationRuleModel from "../../../models/settings/NotificationRuleModel";
import {getWebhookNotificationRuleIds} from "../../../reselect/selectors";
import {applicationFeatures} from "../../../utilities/constants";
import {useTranslation} from "react-i18next";
import {AddButtonHeader} from "../../common/Button/Button";
import {includesSome, isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import WebhookNotificationRuleRow from "./WebhookNotificationRuleRow";

function WebhookNotificationRuleTable(props) {
    const {webhookNotificationRuleIds, showWebhookNotificationRuleForm, userFeatures} = props;
    const {t} = useTranslation(['aria', 'notificationRule']);

    const canAddNotificationRule = includesSome(userFeatures, [applicationFeatures.ADD_RESOURCE, applicationFeatures.ADD_NOTIFICATION_RULE]);
    const isNotEmpty = isNotEmptyNorFalsy(webhookNotificationRuleIds);
    if (!canAddNotificationRule && !isNotEmpty) {
        return null;
    }

    return (
        <div className="display-item">
            <AddButtonHeader aria-label={t('aria:hiddenAssistText.addWebhookNotificationRule')}
                text={t('notificationRule:label.webhookName')}
                canModify={canAddNotificationRule} onClick={showWebhookNotificationRuleForm}
            />

            {isNotEmpty &&
                <div className="display-table">
                    <div className="table-header-group">

                        <div className="table-header">
                            <label className="label is-bold">
                                {t('common:label.name')}
                            </label>
                        </div>
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('notificationRule:label.webhookPlatform')}
                            </label>
                        </div>
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('notificationRule:label.webhookUrl')}
                            </label>
                        </div>
                        <div className="table-header">
                            <label className="label is-bold">
                                {t('notificationRule:label.triggers')}
                            </label>
                        </div>

                    </div>

                    <div className="table-row-group">
                        {webhookNotificationRuleIds.map(id =>
                            <WebhookNotificationRuleRow key={id} notificationRuleId={id}/>
                        )}
                    </div>
                </div>
            }
        </div>
    )
}

const mapStateToProps = state => {
    return {
        webhookNotificationRuleIds: getWebhookNotificationRuleIds(state),
        userFeatures: state.currentUser.features
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showWebhookNotificationRuleForm: () => dispatch(NotificationRuleModel.componentActionCreators.updateDisplay({isWebhookNotificationRuleFormActive: true}))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebhookNotificationRuleTable);