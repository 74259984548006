import {getHandler, onChange} from "../../../utilities/componentFunctions";
import {stringToBool} from "../../../utilities/helperFunctions";

export function onToggleEnable(options) {
    return function (event) {
        const {name, checked} = (event.target.type === 'checkbox') ? event.target : event.currentTarget.dataset;
        const updates = {
            [name]: stringToBool(checked)
        };

        onChange.call(this, updates, options);
    }
}

export function createToggleHandler(options) {
    const handler = getHandler(options);

    return function(event) {
        const type = event.target.type;
        let {name, checked, value} = (type === 'checkbox' || type === 'radio') ? event.target : event.currentTarget.dataset;
        if (type === 'radio') {
            checked = value;
        }

        const updates = {
            [name]: stringToBool(checked)
        };
        handler(updates, event);
    }
}
