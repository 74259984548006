import DetailsModel, {DetailsApi, DetailsSaga} from "../generics/DetailsModel";
import ComponentStateModel from "../generics/ComponentStateModel";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import {all, put, select} from "redux-saga/effects";
import {contextCall, contextSpawn} from "../../saga/sagaFunctions";
import {details, routes, setWebhookEventTriggers, webhookEventTriggers} from "../../utilities/constants";
import SchedulerModel from "../scheduler/SchedulerModel";
import {settingsDisplayKeys} from "../../i18next/keys";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import {separateItems} from "../../components/common/ListContainer/helpers";
import {getEntries} from "../../utilities/helperFunctions";
import i18n from "../../i18next/i18n";
import WebhookEventModel from "./WebhookEventModel";

class WebhookModel extends DetailsModel {

    static nom = 'WebhookModel';
    static actions = WebhookModel.buildActions('WEBHOOK');
    static actionCreators = WebhookModel.buildActionCreators(WebhookModel.actions);
    static reducer = WebhookModel.buildReducer(WebhookModel.actions);

    static componentActionCreators = {
        ...WebhookModel.buildComponentUpdateActionCreators(),
        ...WebhookModel.buildComponentSetActiveActionCreators()
    };

    constructor(model = {}) {
        super(model);
        const {active, triggers, status, callbackAddress, historyEnabled, whitelistedCertFingerprints, auditLog} = model;

        this.active = active;
        this.triggers = triggers;
        this.callbackAddress = callbackAddress;
        this.historyEnabled = historyEnabled;
        this.whitelistedCertFingerprints = whitelistedCertFingerprints;
        this.auditLog = auditLog;
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'webhookDisplay',
                type: 'Display',
                state: {
                    webhookId: null,
                    isWebhookFormActive: false,
                }
            },
            {
                key: 'webhookTablet',
                type: 'Tablet',
                state: {
                    webhookEventId: null
                }
            },
            {
                key: 'webhookForm',
                type: 'Form',
                state: {
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'WEBHOOK_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }

    static getEventTriggerItems() {
        return getEntries(webhookEventTriggers).flatMap(([type, triggers]) =>
            triggers.map(trigger => ({
                name: i18n.t(`events:trigger.${trigger}`),
                value: trigger,
                type
            })));
    }
}

export class WebhookApi extends DetailsApi {
    static location = '/resources';
    static type = '/webhook';

    static getEventTriggers() {
        return axiosInstance.get('/scheduler/resources/webhook/trigger');
    }

    static getEvents(id) {
        return axiosInstance.get(`/scheduler/resources/webhook/${id}/event`);
    }
}

export class WebhookSaga extends DetailsSaga {

    static ModelType = WebhookModel;
    static ModelApi = WebhookApi;

    static activationComponent = 'WEBHOOK_DISPLAY';
    static variableNames = {
        detailsMap: 'webhookDetailsMap',
        instanceId: 'webhookId',
        modelName: 'webhookName',
        isFormActive: 'isWebhookFormActive',
        updateDisplay: 'updateDisplay',
        updatePane: 'updateTablet',
        route: routes.SETTINGS
    };

    static translations = {
        itemTitle: '$t(webhook:label.name)',
        itemLower: '$t(webhook:label.name_lower)'
    };

    static buildActivationEffects(dispatch) {
        return [
            ...super.buildActivationEffects(dispatch),
            // ACTIVATION EFFECTS
            put(WebhookModel.actionCreators.startPollingDetails()),
            contextSpawn(WebhookSaga, 'populateEventTriggers')
        ]
    }

    static buildDeactivationEffects() {
        return [
            ...super.buildDeactivationEffects(),
            // DEACTIVATION EFFECTS
            put(WebhookModel.actionCreators.stopPollingDetails()),
        ]
    }

    static* submitForm(action) {
        const {updateDisplay, updatePane, instanceId} = this.variableNames;

        const {formData} = action.payload;
        const saveValues = yield contextCall(this, 'getSaveValues', formData);

        const {data} = yield contextCall(this.ModelApi, 'post', saveValues);
        const isFormActive = this.getIsFormActive(formData);

        yield all([
            put(this.ModelType.actionCreators.addDetails(data)),
            put(this.ModelType.componentActionCreators[updateDisplay]({[isFormActive]: false, [instanceId]: data.id})),
            put(this.ModelType.componentActionCreators[updatePane]({signatureKey: data.signatureKey}))
        ]);
    }

    static* setInstanceId(args) {
        const {updateDisplay, instanceId} = this.variableNames;

        yield all ([
            put(SchedulerModel.actionCreators.setSettingsDisplay(settingsDisplayKeys.WEBHOOKS)),
            put(this.ModelType.componentActionCreators[updateDisplay]({[instanceId]: args.id}))
        ]);
    }

    static* toggleEnabled(action) {
        const {detailsMap} = this.variableNames;

        const {id} = action.payload;
        const {active} = yield select(state => state[detailsMap].get(id));

        const {data} = yield contextCall(this.ModelApi, 'putDetails', id, {active: !active});
        yield put(this.ModelType.actionCreators.updateDetails({[id]: {active: data.active}}));
    }

    static* getEditValues(id) {
        const {name: webhookName, callbackAddress, historyEnabled, triggers, whitelistedCertFingerprints} = yield select(state => state.webhookDetailsMap.get(id));

        return {
            webhookName,
            callbackAddress,
            historyEnabled,
            triggers: separateItems(triggers, WebhookModel.getEventTriggerItems()),
            whitelistedCertFingerprints
        }
    }

    static getSaveValues(values) {
        const {webhookName: name, active, callbackAddress, historyEnabled, triggers, whitelistedCertFingerprints} = values;

        return {
            name,
            active,
            callbackAddress,
            historyEnabled,
            triggers: triggers.right.map(item => item.value),
            whitelistedCertFingerprints
        }
    }

    static* queryDetails() {
        const response = yield contextCall(WebhookApi, 'getDetails');
        const key = details.WEBHOOKS;

        if (AxiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(WebhookModel.actionCreators.setDetailsMap(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }

    static* populateEventTriggers() {
        const {data} = yield contextCall(WebhookApi, 'getEventTriggers');

        setWebhookEventTriggers(data);
    }

    static* querySettings(action) {
        const {id} = action.payload;
        if (id == null) {
            return;
        }

        try {
            const response = yield contextCall(WebhookApi, 'getEvents', id);
            if (AxiosProxy.shouldUpdate(id, response)) {
                yield put(WebhookEventModel.actionCreators.setSubsetDetailsMap(id, response.data));
            }
        } finally {
            yield put(ReduxStateModel.actionCreators.setHasLoaded(id));
        }
    }
}

export default WebhookModel;