import React from 'react';
import {ListDropdown} from "../../../../components/common/Dropdown/Dropdown";
import {timezones} from "../../../utilities/constants";
import {useTranslation} from "react-i18next";


function TimezoneDropdown(props) {
    const {t} = useTranslation(['aria']);

    const {selectedTimezoneId, onTimezoneSelect, ...rest} = props;
    const timezoneDropdownValues = timezones.map((item, index) => {
        return {value: item, name: item}
    })

    return (
        <ListDropdown id={'timezoneDropdown'} name={'timezoneSelect'}
            aria-label={t('aria:hiddenAssistText.timezone')} noneSelectedMessage={'Select a Timezone'}
            value={selectedTimezoneId} items={timezoneDropdownValues} onItemSelect={onTimezoneSelect}
            buttonStyle={{maxWidth: '20rem', justifyContent: 'flex-start'}}
            {...rest}
        />
    )
}

export default TimezoneDropdown;