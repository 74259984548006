import SagaRunnable from "../generics/SagaRunnable";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import ComponentStateModel from "../generics/ComponentStateModel";
import {
    actionCreator,
    getInputFormattedDateAndTime,
    getLocaleDateTimeFromUTC,
    objectTruthyValues
} from "../../utilities/helperFunctions";
import {all, call, put, select, takeLeading} from "redux-saga/effects";
import {contextCall, contextPollUntil, contextSaga} from "../../saga/sagaFunctions";
import {axiosInstance} from "../api/AxiosProxy";
import {details, QUERY_INTERVAL, routes} from "../../utilities/constants";
import SchedulerModel from "../scheduler/SchedulerModel";
import DetailsModel from "../generics/DetailsModel";
import {tryCatchWrapper} from "../../saga/tryCatchWrapper";
import {jobPageViewKeys} from "../../i18next/keys";
import PopupModel from "../scheduler/PopupModel";
import JobModel from "./JobModel";


class JobArchiveModel extends DetailsModel {

    static nom = 'JobArchiveModel';
    static actions = JobArchiveModel.buildActions();
    static actionCreators = JobArchiveModel.buildActionCreators(JobArchiveModel.actions);
    static reducer = JobArchiveModel.buildReducer(JobArchiveModel.actions);

    static componentActionCreators = {
        ...JobArchiveModel.buildComponentUpdateActionCreators(),
        ...JobArchiveModel.buildComponentSetActiveActionCreators()
    };

    constructor(model = {}) {
        super(model);
        const {confidential, excludeUtilization, notes, priority, locked, submittedBy, submittedDate, executionState, percentageComplete, hasWarnings,
            hasSoftErrors, clientId, matterId, libraryId, libraryWorkflowId, executionProfileId, resourcePoolId, engineId} = model;

        this.excludeUtilization = excludeUtilization;
        this.confidential = confidential;
        this.notes = notes || '';
        this.priority = priority;
        this.locked = locked;
        this.submittedBy = submittedBy;
        this.submittedDate = getLocaleDateTimeFromUTC(submittedDate);
        this.executionState = executionState;
        this.percentageComplete = (!isNaN(percentageComplete) && percentageComplete !== null) ? Math.round(percentageComplete) : '0';
        this.hasWarnings = hasWarnings;
        this.hasSoftErrors = hasSoftErrors;

        this.clientId = clientId;
        this.matterId = matterId;
        this.libraryId = libraryId;
        this.workflowTemplateId = libraryWorkflowId;
        this.executionProfileId = executionProfileId;
        this.resourcePoolId = resourcePoolId;
        this.engineId = engineId;
    }

    static buildActions() {
        return {
            // ARCHIVE ACTIONS
            SET_DETAILS_MAP: 'SET_ARCHIVE_DETAILS_MAP',
            UPDATE_DETAILS: `BULK_UPDATE_ARCHIVE_DETAILS`,
            LOAD_ARCHIVES: 'LOAD_JOB_ARCHIVES',
            // ARCHIVE POLLING ACTIONS
            START_POLLING_ARCHIVE_EXISTS: 'START_POLLING_ARCHIVE_EXISTS',
            STOP_POLLING_ARCHIVE_EXISTS: 'STOP_POLLING_HAS_ARCHIVES',
            QUERY_ARCHIVE_EXISTS: 'QUERY_JOB_ARCHIVE_EXISTS'
        }
    }

    static buildActionCreators(actions) {
        return {
            // ARCHIVE ACTION CREATORS
            setDetailsMap: actionCreator(actions.SET_DETAILS_MAP, 'details'),
            updateDetails: actionCreator(actions.UPDATE_DETAILS, 'idToUpdates'),
            loadArchives: actionCreator(actions.LOAD_ARCHIVES),
            // ARCHIVE POLLING ACTION CREATORS
            startPollingArchiveExists: actionCreator(actions.START_POLLING_ARCHIVE_EXISTS),
            stopPollingArchiveExists: actionCreator(actions.STOP_POLLING_ARCHIVE_EXISTS),
            queryArchiveExists: actionCreator(actions.QUERY_ARCHIVE_EXISTS)
        }
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'jobArchiveDisplay',
                type: 'Display',
                state: {
                    jobArchiveId: null,
                    searchText: '',
                    submitters: {},
                    clientIds: {},
                    matterIds: {},
                    fromDate: getInputFormattedDateAndTime(-1)[0],
                    toDate: getInputFormattedDateAndTime()[0],
                    viewCount: 50
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'JOB_ARCHIVE_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }

    static buildReducer(actions) {
        return function (state = new Map(), action) {
            switch (action.type) {
                case actions.SET_DETAILS_MAP: {
                    const {details} = action.payload;

                    return this.setDetailsMapGeneric(state, details, 'id');
                }
                case actions.UPDATE_DETAILS: {
                    const {idToUpdates} = action.payload;

                    return this.bulkUpdateDetails(state, idToUpdates);
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }
}

export class JobArchiveApi {

    static getArchivedUsers() {
        return axiosInstance.get('/scheduler/jobs/archivedUsers');
    }

    static getArchivesExists() {
        return axiosInstance.post('/scheduler/jobs/archiveExists');
    }

    static getArchives(filter) {
        return axiosInstance.post('/scheduler/jobs/filterArchive', filter);
    }
}

export class JobArchiveSaga extends SagaRunnable {

    static activationComponent = 'JOB_ARCHIVE_DISPLAY';

    static buildActivationEffects(dispatch) {
        return [
            // ACTIVATION EFFECTS
            takeLeading(JobArchiveModel.actions.LOAD_ARCHIVES, tryCatchWrapper, contextSaga(this, 'queryArchives')),
            // Query on-start
            call(tryCatchWrapper, contextSaga(this, 'queryArchives'))
        ]
    }

    static* setReduxState(args) {
        yield contextCall(this, 'queryArchives');

        const details = yield select(state => state.jobArchiveMap.get(args.id));
        if (args.id != null && details == null) {
            yield put(PopupModel.actionCreators.showError({
                info: {
                    key: 'ModelCannotBeFound',
                    valueKeys: {
                        itemTitle: '$t(job:label.jobArchive)'
                    }
                }
            }));
        }

        yield put(JobModel.componentActionCreators.updatePage({activeView: jobPageViewKeys.ARCHIVE, jobId: args.id}));
        if (args.cbEffect != null) {
            yield args.cbEffect;
        }

        window.location.href = `#${routes.JOBS}`;
    }

    static* pollArchiveExists() {
        yield contextPollUntil(SchedulerModel.actions.SET_ARCHIVE_EXISTS, QUERY_INTERVAL, this, 'queryArchiveExists');
    }

    static* queryArchiveExists() {
        const {data} = yield contextCall(JobArchiveApi, 'getArchivesExists');

        if (data === true) {
            yield put(SchedulerModel.actionCreators.setArchiveExists());
        }
    }

    static* queryArchives() {
        const {
            fromDate,
            toDate,
            viewCount,
            submitters,
            clientIds,
            matterIds
        } = yield select(state => state.componentStates.jobArchiveDisplay);

        //Unload previous archives
        yield put(ReduxStateModel.actionCreators.setHasLoaded(details.ARCHIVE_JOBS, false));

        const from = new Date(fromDate.split('-'));
        const to = new Date(toDate.split('-'));
        to.setDate(to.getDate() + 1);
        to.setMilliseconds(to.getMilliseconds() - 1);

        const filter = {
            lastStateChangedDateOnOrAfter: from.getTime(),
            lastStateChangedDateOnOrBefore: to.getTime(),
            maxCount: viewCount,
            submitters: objectTruthyValues(submitters),
            clientIds: objectTruthyValues(clientIds),
            matterIds: objectTruthyValues(matterIds)
        };

        try {
            const {data} = yield contextCall(JobArchiveApi, 'getArchives', filter);
            yield put(JobArchiveModel.actionCreators.setDetailsMap(data));
        } finally {
            yield all([
                put(ReduxStateModel.actionCreators.setHasLoaded(details.ARCHIVE_JOBS)),
                put(JobArchiveModel.componentActionCreators.updateDisplay({searchText: ''}))
            ]);
        }

    }
}

export default JobArchiveModel;