import React, {useEffect, useLayoutEffect} from 'react';
import './JobPdfPage.css';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from "react-router-dom";
import {JobSaga} from "../../models/job/JobModel";

import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {all} from "redux-saga/effects";
import {contextCall} from "../../saga/sagaFunctions";
import SchedulerModel from "../../models/scheduler/SchedulerModel";
import {JobExecutionLogSaga} from "../../models/job/JobExecutionLogModel";
import {JobOperationMimeTypeStatsSaga} from "../../models/job/JobOperationMimeTypeStatsModel";
import {selectJobFromDisplay} from "./selectors";
import {isNotEmptyNorFalsy} from "../../utilities/helperFunctions";
import JobTabletHeader from "./JobTabletHeader";
import JobTabletBody from "./JobTabletBody";


function JobPdfPage() {
    const locationSplit = useLocation().pathname.split('/');
    const jobId = locationSplit[locationSplit.length - 1];

    const dispatch = useDispatch();
    const isLoading = useSelector(state => !state.hasLoaded[jobId]);
    const jobExists = useSelector(state => isNotEmptyNorFalsy(selectJobFromDisplay(state, jobId)));

    useLayoutEffect(() => {
        if (!jobExists) {
            history.back()
        }
    }, [jobExists])

    // Query for jobSettings
    useEffect(() => {
        const jobSettingsQueryEffect = all([
            contextCall(JobExecutionLogSaga, 'querySettings', {payload: {jobId}}),
            contextCall(JobOperationMimeTypeStatsSaga, 'querySettings', {payload: {jobId}}),
            contextCall(JobSaga, 'querySettings', {payload: {id: jobId}}),
            contextCall(JobSaga, 'getModelNames'),
        ]);

        dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(jobSettingsQueryEffect));
    }, [jobId, dispatch]);

    useEffect(() => {
        if (!isLoading) {
            setTimeout(() => {
                window.print()
                history.back()
            }, 250)
        }
    }, [isLoading])

    return (
        <LoadingWrapper isLoading={isLoading}>
            <div className="jobPdfPage">
                <JobTabletHeader jobId={jobId}/>
                <JobTabletBody jobId={jobId} hideCommentForm/>
            </div>
        </LoadingWrapper>
    );
}

export default JobPdfPage;
