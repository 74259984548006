import React from 'react';
import {useTranslation} from "react-i18next";

export function GenericTextExample (props) {
    const {t} = useTranslation(['wizard'])
    const {textExample, translationKey} = props;

    const separator = (<div className={"folder-separator"}><span> <label className={"label is-small"}>/</label> </span> <span> <hr/> </span>
        <span> <label className={"placeholder label is-small"}>a</label> </span></div>);

    return (
        <>
            <div className={"suffix-text"} key={`example-container-${translationKey}`}>
                <span>
                    <label className={"label is-small"} key={`example-label-${translationKey}`}>{textExample}</label>
                    <hr className={"line-separator"} key={`example-hr-${translationKey}`}/>
                    <label className={"label is-small"} key={`example-descriptor-${translationKey}`}>{t(`wizard:examples.${translationKey}`)}</label>
                </span>
            </div>
            {separator}
        </>
    )
}

export function ClientMatterExample (props) {
    const {client, matter} = props;

    return (
      <>
          <GenericTextExample textExample={client} translationKey={"clientName"}/>
          <GenericTextExample textExample={matter} translationKey={"matterName"}/>
      </>
    );
}

export function SingleFolderExample (props) {
    const {caseName} = props;

    return (
        <>
            <GenericTextExample textExample={caseName} translationKey={"caseName"}/>
        </>
    );
}