import {actionCreator, getLocaleDateTimeFromUTC} from "../../utilities/helperFunctions";
import {axiosInstance} from "../api/AxiosProxy";
import {contextCall, contextPollUntil} from "../../saga/sagaFunctions";
import {put} from "redux-saga/effects";

class JobRunningLogModel {

    static nom = 'JobRunningLogModel';
    static actions = JobRunningLogModel.buildActions('JOB_RUNNING_LOG');
    static actionCreators = JobRunningLogModel.buildActionCreators(JobRunningLogModel.actions);
    static reducer = JobRunningLogModel.buildReducer(JobRunningLogModel.actions);

    static buildActions(type) {
        return {
            // Details
            UPDATE_RUNNING_LOG: `UPDATE_${type}`,
            DELETE_RUNNING_LOG: `DELETE_${type}`,

            // POLLING ACTIONS
            START_POLLING_SETTINGS: `START_POLLING_${type}`,
            STOP_POLLING_SETTINGS: `STOP_POLLING_${type}`,
            QUERY_SETTINGS: `QUERY_SETTINGS_${type}`
        }
    }

    static buildActionCreators (actions) {
        return {
            setRunningLog: actionCreator(actions.UPDATE_RUNNING_LOG, 'runningLog'),
            deleteRunningLog: actionCreator(actions.DELETE_RUNNING_LOG),

            // POLLING ACTION CREATORS
            startPollingSettings: actionCreator(actions.START_POLLING_SETTINGS, 'jobId', 'pollingDelay'),
            stopPollingSettings: actionCreator(actions.STOP_POLLING_SETTINGS),
            querySettings: actionCreator(actions.QUERY_SETTINGS, 'jobId')
        }
    }

    static buildReducer(actions) {
        return function (state = {runningLog: [], runningLogIndex: -1}, action) {
            switch (action.type) {
                case actions.UPDATE_RUNNING_LOG: {
                    const {runningLog: {runningLogs, runningLogIndex}} = action.payload;

                    if (runningLogs != null && runningLogs.length > 0) {
                        state.runningLog = runningLogs.map(e => [getLocaleDateTimeFromUTC(e.timestamp), e.string]);
                    }

                    state.runningLogIndex = runningLogIndex;

                    return state;
                }
                case actions.DELETE_RUNNING_LOG: {
                    return {runningLog: [], runningLogIndex: -1};
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }
}

export class JobRunningLogApi {

    static getRunningLog(id) {
        return axiosInstance.get(`/scheduler/jobs/${id}/runningLog`)
    }
}

export class JobRunningLogSaga {

    static* pollSettings(action) {
        const {pollingDelay} = action.payload;

        yield contextPollUntil(JobRunningLogModel.actions.STOP_POLLING_SETTINGS, pollingDelay, this, 'querySettings', action);
    }

    static* querySettings(action) {
        const {jobId} = action.payload;
        if (jobId == null) {
            return;
        }

        try {
            const {data} = yield contextCall(JobRunningLogApi, 'getRunningLog', jobId)
            yield put(JobRunningLogModel.actionCreators.setRunningLog(data));
        } catch (e) {}
    }
}

export default JobRunningLogModel;