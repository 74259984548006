import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {createInputHandler} from '../../utilities/componentFunctions';
import {permissionKeys} from "../../i18next/keys";
import {DefaultEditPanel} from '../common/EditPanel/EditPanel';
import TextArea from '../common/TextArea/TextArea';
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {shouldEnableLibrary} from "../../utilities/shouldEnableFunctions";
import {switchcaseF} from "../../utilities/helperFunctions";
import {updateMode, userSettings} from "../../utilities/constants";
import LibraryModel from "../../models/library/LibraryModel";
import WorkflowTemplateModel from "../../models/library/WorkflowTemplateModel";
import EditModel from "../../models/scheduler/EditModel";
import {selectLibraryDetails} from "./selector";
import {MenuDropdown} from "../common/Dropdown/Dropdown";
import WorkflowWizard from "../../workflow-wizard/WorkflowWizard";
import WorkflowBuilder from "../workflowBuilder/WorkflowBuilder";
import WorkflowTemplateTable from "../workflowTemplate/WorkflowTemplateTable";
import WorkflowTemplateFormOptions from "../workflowTemplate/WorkflowTemplateFormOptions";
import {download} from "../../utilities/downloadHelper";
import {MainPageContent} from "../app/pages/MainPage";

function LibraryDisplay(props) {
    const {t} = useTranslation(['aria', 'library', 'workflow', 'common']);
    const dispatch = useDispatch();

    const {
        libraryId
    } = props;

    const {showObjectIds} = useSelector(state => state.userSettingsMap.get(userSettings.TROUBLESHOOT));
    const isLoading = useSelector(state => !state.hasLoaded[libraryId]);
    const isWorkflowBuilderActive = useSelector(state => state.workflowBuilder.isActive);

    const {
        libraryName,
        description,
        enabled,
        userPermissions,
        isEditActive,
        isSaveEnabled
    } = useSelector(state => selectLibraryDetails(state, {libraryId}), shallowEqual);
    const {
        workflowTemplateId,
        isWorkflowTemplateFormActive,
        isWorkflowWizardActive,
    } = useSelector(state => state.componentStates.libraryDisplay);


    const {saveEdit, cancelEdit, updateEdit, setEditSaveEnabled} = EditModel.buildDispatchers(dispatch);
    const inputHandler = createInputHandler({
        mode: updateMode.EDIT,
        updateEdit,
        setEditSaveEnabled,
        shouldEnable: shouldEnableLibrary,
        values: {libraryName}
    });

    // Start/stop polling workflows
    useEffect(() => {
        dispatch(WorkflowTemplateModel.actionCreators.startPollingDetailsSubset(libraryId));
        return () => dispatch(WorkflowTemplateModel.actionCreators.stopPollingDetailsSubset());
    }, [libraryId, dispatch]);

    const menuOptions = [
        {name: t('common:option.edit'), value: 'edit', isDisabled: isLoading},
        {name: t(`common:option.${enabled ? 'deactivate' : 'activate'}`), value: 'toggleEnable'},
        {name: t('common:option.delete'), value: 'promptDelete'},
        {isSeparator: true},
        {name: t('common:option.download'), value: 'download'}
    ];

    function downloadLibrary() {
        download(`/scheduler/library/${libraryId}/downloadZip`);
    }

    function menuOptionHandler(event) {
        const {value} = event.currentTarget.dataset;
        switchcaseF({
            'edit': () => dispatch(LibraryModel.actionCreators.startEdit(libraryId)),
            'toggleEnable': () => dispatch(LibraryModel.actionCreators.toggleEnabled(libraryId)),
            'promptDelete': () => dispatch(LibraryModel.actionCreators.promptDelete(libraryId)),
            'download': downloadLibrary
        })()(value);
    }

    const canModifyLibrary = userPermissions.includes(permissionKeys.MODIFY);
    const canAddWorkflow = userPermissions.includes(permissionKeys.MODIFY_CHILDREN) || userPermissions.includes(permissionKeys.MODIFY);
    const isDescriptionVisible = isEditActive || description;

    return (
        <MainPageContent id={`${libraryId}_TABPANEL`} aria-labelledby={`${libraryId}_TAB`}
            title={isEditActive ?
                <DefaultEditPanel isActive={isEditActive} isSaveEnabled={isSaveEnabled}
                    onSave={saveEdit} onCancel={cancelEdit}
                >
                    <input aria-label={t('aria:input.name')} className={'input subtitle-edit' + (libraryName ? '' : ' is-required')}
                        name="libraryName" value={libraryName} onChange={inputHandler}
                    />

                    {isDescriptionVisible &&
                    <TextArea aria-label={t('aria:input.description')} name="description" value={description}
                        onChange={inputHandler}
                    />
                    }
                </DefaultEditPanel>
                :
                <>
                    <div className="display-title">
                        <h2 className="subtitle is-bold">
                            {libraryName + (enabled ? '' : ` (${t('common:label.inactive')})`)}
                        </h2>

                        {canModifyLibrary &&
                        <div className="display-menu">
                            <MenuDropdown menuOptions={menuOptions} onOptionClick={menuOptionHandler}
                                id={'libraryMenuDropdown'} aria-label={t('aria:hiddenAssistText.libraryMenu')}
                            />
                        </div>
                        }
                    </div>

                    {showObjectIds &&
                        <div className="id-label">
                            {libraryId}
                        </div>
                    }

                    <label className="display-item label">{description}</label>
                </>
            }
            list={
                <LoadingWrapper isLoading={isLoading}>
                    <WorkflowTemplateTable libraryId={libraryId} workflowTemplateId={workflowTemplateId} canAddWorkflow={canAddWorkflow}/>
                </LoadingWrapper>
            }
        >
            {isWorkflowTemplateFormActive &&
                <WorkflowTemplateFormOptions libraryId={libraryId}/>
            }

            {isWorkflowBuilderActive &&
                <WorkflowBuilder libraryId={libraryId}/>
            }

            {isWorkflowWizardActive &&
                <WorkflowWizard uploadState={workflowTemplateId} selectedLibraryId={libraryId}/>
            }
        </MainPageContent>
    )
}


export default LibraryDisplay
