import React, {useRef, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from "react-i18next";
import Tablet, {EditTabletHeader} from '../../common/Tablet/Tablet';
import {createEditHandler, createInputHandler} from '../../../utilities/componentFunctions';
import EditPanel from '../../common/EditPanel/EditPanel';
import ExpandableContent, {
    ExpandableEditTextArea,
    ExpandableValueList
} from '../../common/ExpandableContent/ExpandableContent';
import {FormElementRow, SettingsRowValue} from '../../common/CustomTable/CustomTable';
import {shouldEnableNmsLicenceSource} from "../../../utilities/shouldEnableFunctions";
import {permissionKeys, statusKeys} from "../../../i18next/keys";
import {licenceSourceTypes, licenseRelayTypes} from "../../../utilities/constants";
import PopupModel from "../../../models/scheduler/PopupModel";
import LicenceSourceModel, {LicenceSourceSaga} from "../../../models/settings/LicenceSourceModel";
import EditModel from "../../../models/scheduler/EditModel";
import {makeGetEditDetails} from "../../../reselect/selectors";
import {switchcaseF} from "../../../utilities/helperFunctions";
import {contextCall} from "../../../saga/sagaFunctions";
import SchedulerModel from "../../../models/scheduler/SchedulerModel";
import {ButtonGroup} from "../../common/Button/Button";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import {ExpandableStatusLog} from "../../common/Common";
import {LicenseRelayTypeDropdown} from "./NmsLicenceSourceForm";
import {createDropdownHandler} from "../../common/Dropdown/helpers";
import {useClearOnFirstCallback} from "../../../utilities/formHooks";
import LimitedTablet from "../../limited/LimitedTablet";

function NmsLicenceSourceTablet(props) {
    const {t} = useTranslation(['nuixLicenceSource', 'common']);

    const {
        nuixLicenceSourceId,
        nmsLicenceSourceName,
        description,
        filter,
        relayType,

        username,
        nmsLicenseSourceUsername,
        nmsLicenseSourcePassword,

        nmsName,
        nmsPort,
        whitelistedCertFingerprints,
        userPermissions,
        status
    } = props;

    const nmsLicenseSourcePasswordRef = useRef();
    const {updateEdit, setEditSaveEnabled} = props;

    const editHandler = createEditHandler({
        updateEdit,
        setEditSaveEnabled,
        passwordRef: nmsLicenseSourcePasswordRef,
        passwordName: 'nmsLicenseSourcePassword',
        passwordResetNames: ['nmsName', 'nmsPort', 'nmsLicenseSourceUsername'],
        shouldEnable: shouldEnableNmsLicenceSource,
        values: {nmsLicenceSourceName, nmsLicenseSourceUsername, nmsName, nmsPort}
    });

    const inputHandler = createInputHandler({
        handler: editHandler
    });

    const dropdownHandler = createDropdownHandler({
        handler: editHandler
    });

    function setWhitelistedCertFingerprints(update) {
        const updates = {
            whitelistedCertFingerprints: update(whitelistedCertFingerprints)
        };

        editHandler(updates);
    }

    function testSource() {
        const testEffect = contextCall(LicenceSourceSaga, 'testRule', nuixLicenceSourceId);
        props.yieldEffect(testEffect);
    }

    function testSourceEdit() {
        const values = {
            id: nuixLicenceSourceId,
            sourceType: licenceSourceTypes.NMS,
            nmsLicenceSourceName,
            description,
            filter,
            relayType,
            nmsName,
            nmsPort,
            nmsLicenseSourceUsername,
            whitelistedCertFingerprints
        };

        const testEffect = contextCall(LicenceSourceSaga, 'testRuleData', 'Tablet', values);
        props.yieldEffect(testEffect);
    }

    const {edit, promptDelete} = props;
    function menuOptionHandler(event) {
        const {value} = event.currentTarget.dataset;

        switchcaseF({
            'edit': edit,
            'testSource': testSource,
            'promptDelete': promptDelete
        })()(value);
    }

    const [menuOptions] = useState([
        {name: t('common:option.edit'), value: 'edit'},
        {name: t('common:option.delete'), value: 'promptDelete'},
        {isSeparator: true},
        {name: t('common:option.test'), value: 'testSource'}
    ]);

    const {
        close,
        saveEdit,
        cancelEdit,
        isEditActive,
        isSaveEnabled,
        isDisabled
    } = props;

    const canModify = userPermissions.includes(permissionKeys.MODIFY);
    const showDescription = isEditActive || description;
    const showWhitelistedCertFingerprints = isEditActive || (whitelistedCertFingerprints || []).some(fingerprint => fingerprint);

    const height = isEditActive ? 'auto' : '50vh';

    const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);
    if (isViewLimited && !canModify) {
        return (
            <LimitedTablet id={nuixLicenceSourceId} type={'nmsLicenceSource'} name={nmsLicenceSourceName} label={t(`nuixLicenceSource:label.nmsLicenceSourceName`)}
                           status={status} enabled={true} canModify={canModify} isDisabled={isDisabled} description={description}
                           onClose={close}
            />
        )
    }

    return (
        <Tablet height={height} onClose={close} isDisabled={isDisabled}
            closeButtonAriaLabel={t('nuixLicenceSource:option.closeNmsTablet_name', {name: nmsLicenceSourceName})}
            header={
                <EditTabletHeader label={t('nuixLicenceSource:label.nmsLicenceSourceName')} type={'nmsLicenceSource'}
                    id={nuixLicenceSourceId} name={'nmsLicenceSourceName'} value={nmsLicenceSourceName} inputHandler={inputHandler}
                    canModify={canModify} menuOptions={menuOptions} menuOptionHandler={menuOptionHandler}
                    isEditActive={isEditActive} isDisabled={isDisabled}
                />
            }
            body={
                <>
                    {status.code === statusKeys.ERROR && !isEditActive &&
                    <div className="display-item">
                        <ExpandableStatusLog {...status} isDisabled={isDisabled}/>
                    </div>
                    }

                    <div className="display-item">
                        <EditPanel isActive={isEditActive}
                            options={
                                <ButtonGroup
                                    buttons={[{
                                        label: t('common:option.cancel'),
                                        onClick: cancelEdit,
                                        isDisabled
                                    }, {
                                        label: t('common:option.test'),
                                        onClick: testSourceEdit,
                                        isDisabled: isDisabled || !isSaveEnabled
                                    }, {
                                        label: t('common:option.save'),
                                        onClick: saveEdit,
                                        isDisabled: isDisabled || !isSaveEnabled
                                    }]}
                                    style={{
                                        marginTop: '0.75rem'
                                    }}
                                />
                            }
                        >

                            {showDescription &&
                            <ExpandableEditTextArea label={t('common:label.description')} name={'description'}
                                value={description} onChange={inputHandler}
                                isEditActive={isEditActive} isDisabled={isDisabled}/>
                            }

                            <ExpandableSettings nmsLicenseSourcePasswordRef={nmsLicenseSourcePasswordRef} initialPassword={nmsLicenseSourcePassword} filter={filter} relayType={relayType}
                                nmsName={nmsName} nmsPort={nmsPort} username={username} nmsLicenseSourceUsername={nmsLicenseSourceUsername}
                                dropdownHandler={dropdownHandler} inputHandler={inputHandler} isEditActive={isEditActive} isDisabled={isDisabled}/>

                            {showWhitelistedCertFingerprints &&
                            <ExpandableValueList id={'whitelistedCertFingerprints'} label={t('common:label.whitelistedCertFingerprints')}
                                ariaLabelKey={'WhitelistedCertFingerprint'} values={whitelistedCertFingerprints}
                                setValues={setWhitelistedCertFingerprints} isEditActive={isEditActive} isDisabled={isDisabled}/>
                            }
                        </EditPanel>
                    </div>
                </>
            }
        />
    )
}

function ExpandableSettings(props) {
    const {isEditActive, nmsLicenseSourcePasswordRef, initialPassword, ...attr} = props;
    const {t} = useTranslation(['common']);

    return (
        <ExpandableContent label={t('common:label.settings')} isDisabled={attr.isDisabled}>
            {isEditActive ?
                <EditLicenceSettings nmsLicenseSourcePasswordRef={nmsLicenseSourcePasswordRef} initialPassword={initialPassword} {...attr}/>
                :
                <LicenceSettings {...attr}/>
            }
        </ExpandableContent>
    );
}

function LicenceSettings(props) {
    const {filter, relayType, nmsName, nmsPort, username, isDisabled} = props;
    const {t} = useTranslation(['nuixLicenceSource', 'common']);

    return (
        <div className="settings-table">
            <div className="table-row-group">
                {filter &&
                <SettingsRowValue label={t('nuixLicenceSource:label.filter')} value={filter} isDisabled={isDisabled}/>
                }
                <SettingsRowValue label={t('nuixLicenceSource:label.serverName')} value={nmsName} isDisabled={isDisabled}/>
                <SettingsRowValue label={t('nuixLicenceSource:label.serverPort')} value={nmsPort} isDisabled={isDisabled}/>

                {relayType
                && (relayType === licenseRelayTypes.RELAY_CLS_AUTH || relayType === licenseRelayTypes.RELAY_NMS_AUTH) &&
                <SettingsRowValue label={t('nuixLicenceSource:label.relayType')} value={t(`nuixLicenceSource:relayType.${relayType}`)}
                                  isDisabled={isDisabled}/>
                }


                <SettingsRowValue label={t('common:label.username')} value={username} isDisabled={isDisabled}/>
            </div>
        </div>
    );
}

function EditLicenceSettings(props) {
    const {nmsLicenseSourcePasswordRef, initialPassword, filter, relayType, nmsName, nmsPort, nmsLicenseSourceUsername, inputHandler, dropdownHandler, isDisabled} = props;
    const {t} = useTranslation(['nuixLicenceSource', 'common']);
    const clearOnFirstCallback = useClearOnFirstCallback();

    return (
        <>
            <HTMLTextInput label={t('nuixLicenceSource:label.filter')} name={'filter'} value={filter}
                onChange={inputHandler} isDisabled={isDisabled}/>

            <div className="display-input" style={{display: 'flex'}}>
                <HTMLTextInput label={t('nuixLicenceSource:label.serverName')} name={'nmsName'} value={nmsName}
                    onChange={inputHandler} isDisabled={isDisabled} isRequired containerStyle={{flex: 1, marginRight: '2.5rem'}}
                />

                <HTMLTextInput label={t('nuixLicenceSource:label.serverPort')} name={'nmsPort'} value={nmsPort}
                    onChange={inputHandler} type={'number'} isDisabled={isDisabled} isRequired containerStyle={{flexBasis: '5rem'}}
                />
            </div>

            <div className="forms-table display-input">
                <div className="table-row-group">
                    <FormElementRow label={t('nuixLicenceSource:label.relayType')} isDisabled={isDisabled}
                        element={
                            <LicenseRelayTypeDropdown value={relayType} onItemSelect={dropdownHandler}
                                isEdit isRequired isDisabled={isDisabled}/>
                        }/>
                </div>
            </div>

            <div className="display-input">
                <HTMLTextInput label={t('common:label.username')} name={'nmsLicenseSourceUsername'} value={nmsLicenseSourceUsername}
                    onChange={inputHandler} isDisabled={isDisabled} isRequired/>
            </div>

            <div className="display-input">
                <HTMLTextInput label={t('common:label.password')} inputRef={nmsLicenseSourcePasswordRef} name={'nmsLicenseSourcePassword'} type={'password'}
                    defaultValue={initialPassword} onChange={inputHandler} onBeforeInput={clearOnFirstCallback} isDisabled={isDisabled} isRequired/>
            </div>
        </>
    );
}

const makeMapStateToProps = () => {
    const getEditDetails = makeGetEditDetails();
    return (state, ownProps) => {
        const {nuixLicenceSourceId} = ownProps;

        const {nuixLicenceSourceDetailsMap, componentStates: {licenceSourceTablet}} = state;
        const {name: nmsLicenceSourceName, serverName: nmsName, serverPort: nmsPort, ...otherDetails} = nuixLicenceSourceDetailsMap.get(nuixLicenceSourceId);

        const {activeModel, values, isSaveEnabled} = getEditDetails(state, {model: LicenceSourceModel.nom});
        const isEditActive = (activeModel === LicenceSourceModel.nom);

        return {
            nuixLicenceSourceId,
            nmsLicenceSourceName,
            nmsName,
            nmsPort,
            ...otherDetails,
            ...values,
            isEditActive,
            isSaveEnabled,
            ...licenceSourceTablet
        }
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        yieldEffect: effect => dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(effect)),

        edit: () => dispatch(LicenceSourceModel.actionCreators.startEdit(ownProps.nuixLicenceSourceId)),
        promptDelete: () => dispatch(LicenceSourceModel.actionCreators.promptDelete(ownProps.nuixLicenceSourceId)),

        ...EditModel.buildDispatchers(dispatch),

        showError: payload => dispatch(PopupModel.actionCreators.showError(payload)),
        close: () => dispatch(LicenceSourceModel.actionCreators.hideTablet())
    };
};

export default connect(makeMapStateToProps, mapDispatchToProps)(NmsLicenceSourceTablet);