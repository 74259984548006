import React from "react";
import {useTranslation} from "react-i18next";
import {executionStateIcon, icon} from "../../../utilities/iconResolver";
import HighlightText from "../../userSettings/HighlightOption/HighlightText";
import "./PrintableOptions.css"
import {executionStateKeys, popupInfoKeys} from "../../../i18next/keys";
import {useDispatch, useSelector} from "react-redux";
import {jobCommands, userSettings} from "../../../utilities/constants";
import {operationIconModel} from "../../../models/generics/IconModel";
import {buildClassName} from "../../../utilities/helperFunctions";
import {Button} from "../../common/Button/Button";
import PopupModel from "../../../models/scheduler/PopupModel";
import JobModel from "../../../models/job/JobModel";

export function OperationBacklogTable(props) {
    const {id, operations, viewSensitive, enableHighlightText, isDisabled} = props;
    const {t} = useTranslation(['workflow']);

    const showWorkflowOptions = useSelector(state => viewSensitive && state.userSettingsMap.get(userSettings.WORKFLOW_OPTION).showWorkflowOptions);
    const className = buildClassName(
        'display-table',
        'align-middle',
        isDisabled && 'is-disabled'
    );

    return (
        <div className={className} style={{marginTop: '0.5rem'}}>
            <div className="table-header-group">

                <div className="table-header">
                    <label className="label is-bold">
                        #
                    </label>
                </div>

                <div className="table-header" style={{minWidth: '13rem'}}>
                    <label className="label is-bold">
                        {t('workflow:label.operation')}
                    </label>
                </div>

                <div className="table-header" style={{width: '40%'}}>
                    <label className="label is-bold">
                        {t('workflow:label.notes')}
                    </label>
                </div>

                {showWorkflowOptions &&
                    <div className="table-header" style={{width: '40%'}}>
                        <label className="label is-bold">
                            {t('workflow:label.options')}
                        </label>
                    </div>
                }

            </div>

            <div className="table-row-group">
                {operations &&
                    operations.map((op, index) =>
                        <OperationBacklogRow key={`WF${id}_OP${index}`} number={index + 1} {...op}
                            enableHighlightText={enableHighlightText} showWorkflowOptions={showWorkflowOptions}
                        />
                    )}
            </div>
        </div>
    )
}

export function OperationProgressTable(props) {
    const {id, operations, enableHighlightText, runningOperationId} = props;
    const runningOperation = (operations != null && operations[runningOperationId]) || {};
    const {t} = useTranslation(['workflow']);
    const dispatch = useDispatch();

    const {showOperationProcessingSpeed} = useSelector(state => state.userSettingsMap.get(userSettings.JOB_PANEL));

    function skipOperation() {
        dispatch(PopupModel.actionCreators.showWarning({
            info: {
                key: popupInfoKeys.SKIP_OPERATION,
                values: {
                    operationNumber: (runningOperationId + 1),
                    operationName: runningOperation.name
                }
            },
            buttons: [{
                title: t('job:option.skipOperation'),
                onClick: () => dispatch(JobModel.actionCreators.sendCommand(id, jobCommands.SKIP, {operationId: runningOperationId}))
            }]
        }));
    }

    return (
        <div className="display-table" style={{marginTop: '0.5rem'}}>
            <div className="table-header-group">

                <div className="table-header">
                    <label className="label is-bold">
                        #
                    </label>
                </div>

                <div className="table-header" style={{minWidth: '13rem'}}>
                    <label className="label is-bold">
                        {t('workflow:label.operation')}
                    </label>
                </div>

                <div className="table-header" style={{width: '50%'}}>
                    <label className="label is-bold">
                        {t('workflow:label.notes')}
                    </label>
                </div>

                <div className="table-header">
                    <label className="label is-bold">
                        {t('workflow:label.eta')}
                    </label>
                </div>

                <div className="table-header" style={{width: '12rem'}}>
                    <label className="label is-bold">
                        {t('workflow:label.status')}
                    </label>
                </div>
                <div className="table-header"/>
                <div className="table-header"/>
            </div>

            <div className="table-row-group">
                {operations &&
                    operations.map((op, index) =>
                        <OperationProgressRow key={`WF${id}_OP${index}`} number={index + 1} {...op}
                            enableHighlightText={enableHighlightText} onSkipOperation={skipOperation}
                            showOperationProcessingSpeed={showOperationProcessingSpeed} runningOperationId={runningOperationId}
                        />
                    )
                }
            </div>
        </div>
    )
}

function OperationBacklogRow(props) {
    const {name, number, operationAlias, showWorkflowOptions, notes, disabled,
        options, enableHighlightText} = props;

    const className = buildClassName(
        'table-row no-pointer',
        disabled && 'operation-disabled',
        props.softFail && 'operation-softFail',
        props.suppressWarnings && 'operation-suppressWarnings'
    );

    return (
        <div className={className}>
            <div className="table-cell no-stretch">
                <label className="label">
                    {number}
                </label>
            </div>

            <div className="table-cell">
                <label className="label is-wordwrap">
                    <OperationName name={name}
                        alias={operationAlias} enableHighlightText={enableHighlightText}/>
                </label>
            </div>

            <div className="table-cell">
                <label className="label is-wordwrap">
                    <HighlightText text={notes} isDisabled={!enableHighlightText}/>
                </label>
            </div>

            {showWorkflowOptions &&
                <div className="table-cell">
                    {Array.isArray(options) ?
                        options.map((option, index) =>
                            <PrintableOption key={index} optionKey={option.key} value={option.value} style={option.style} enableHighlightText={true}/>
                        )
                        :
                        <label className="label is-wordwrap">
                            <HighlightText text={options} isDisabled={enableHighlightText}/>
                        </label>
                    }
                </div>
            }
        </div>
    )
}

function PrintableOption (props) {
    const {optionKey, value, style} = props;

    let component;
    if (!!value && Array.isArray(value)) {
        component = <ListPrintableOption {...props}/>
    } else if (!!style && style === 'CODE') {
        component = <MultilinePrintableOption {...props}/>
    } else if (optionKey == null && value != null) {
        component = <SinglePrintableOption {...props}/>
    } else {
        component = <TextPrintableOption {...props}/>
    }

    return component;
}

function TextPrintableOption (props) {
    const {optionKey, value, enableHighlightText} = props;

    return (
        <label className="label is-wordwrap">
            <HighlightText text={`${optionKey}: ${value}`} isDisabled={!enableHighlightText}/>
        </label>
    )
}

function SinglePrintableOption (props) {
    const {optionKey, value, enableHighlightText} = props;

    const styleKey = !!value ? "operation-"+value.toLowerCase() : "";

    return (
        <label className={`label is-wordwrap ${styleKey}`}>
            <HighlightText text={value} isDisabled={!enableHighlightText}/>
        </label>
    )
}

function MultilinePrintableOption (props) {
    const {optionKey, value, enableHighlightText} = props;

    return (

        <table className={"settings"}>
            <tbody>
            <tr>
                <td>
                    {!!optionKey && (
                        <label className="label">{`${optionKey}: `}</label>
                    )}
                </td>
                <td className={"printable-list"}>
                    <div className={"multiline"}>
                        <label className="label is-wordwrap">
                            <HighlightText text={`${value}`} isDisabled={!enableHighlightText}/>
                        </label>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>

    )
}

function ListPrintableOption (props) {
    const {optionKey, value, enableHighlightText} = props;
    const listItems = !!value && value.flat();

    return (
        <table className={"settings"}>
            <tbody>
            <tr>
                <td>
                    {!!optionKey && (
                        <label className="label">{`${optionKey}: `}</label>
                    )}
                </td>
                <td className={"printable-list"}>
                    <ul>
                        {!!listItems && listItems.map((item, index) => {
                            if (Array.isArray(item)) {
                                return (
                                    <ul key={`parent_list_${index}`}>
                                        {item.map((_item, _index) => (
                                            <li key={`sub_item_${_index}`}>
                                                <label className="label">
                                                    <HighlightText text={_item} isDisabled={!enableHighlightText}/>
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                )
                            } else {
                                return (
                                    <li key={`item_${index}`}>
                                        <label className="label">
                                            <HighlightText text={item} isDisabled={!enableHighlightText}/>
                                        </label>
                                    </li>
                                )
                            }
                            }
                        )}
                    </ul>
                </td>
            </tr>
            </tbody>
        </table>
    )
}

function ListItemPrintableOption (props) {
    const {header, items, optionKey, enableHighlightText} = props;

    const showHeader = !!header

    return (
        <>
            {showHeader ? (
                <li>
                    <label className="label">
                        <HighlightText text={`${header}: `} isDisabled={!enableHighlightText}/>
                    </label>

                    <ul>
                        {!!items && items.map((item, index) => (
                            <li key={index}>
                                <label className="label">
                                    <HighlightText text={item} isDisabled={!enableHighlightText}/>
                                </label>
                            </li>
                        ))}
                    </ul>
                </li>
            ) : (
                <ul>
                    {!!items && items.map((item, index) => (
                        <li key={index}>
                            <label className="label">
                                <HighlightText text={item} isDisabled={!enableHighlightText}/>
                            </label>
                        </li>
                    ))}
                </ul>
            )}
        </>
    )
}

function OperationProgressRow(props) {
    const {number, name, alias, notes, eta, executionState, percentageComplete, progressText, processingSpeedText, warnings, enableHighlightText,
        skippable, onSkipOperation, runningOperationId, showOperationProcessingSpeed} = props;
    const {t} = useTranslation(['aria', 'common']);

    const hasWarnings = warnings.length > 0;
    const hasSoftErrors = executionState === executionStateKeys.SOFT_ERROR;
    const showSkippableButton = skippable && (runningOperationId+1) === number && executionState === executionStateKeys.RUNNING;

    return (
        <div className="table-row no-pointer">

            <div className="table-cell no-stretch">
                <label className="label">
                    {number}
                </label>
            </div>

            <div className="table-cell">
                <label className="label is-wordwrap">
                    <OperationName name={name} alias={alias} enableHighlightText={enableHighlightText}/>
                </label>
            </div>

            <div className="table-cell">
                <label className="label is-wordwrap">
                    <HighlightText text={notes} isDisabled={!enableHighlightText}/>
                </label>
            </div>

            <div className="table-cell">
                <label className="label is-wordwrap">
                    <HighlightText text={eta} isDisabled={!enableHighlightText}/>
                </label>
            </div>

            <div className="table-cell">
                <label className="label is-wordwrap">
                    <HighlightText text={progressText} isDisabled={!enableHighlightText}/>
                </label>
                {showOperationProcessingSpeed && processingSpeedText &&
                <label className="label is-wordwrap">
                    <HighlightText text={processingSpeedText} isDisabled={!enableHighlightText}/>
                </label>
                }
            </div>

            <div className="table-cell">
                <span className="icon is-small">
                    {executionStateIcon({executionState, percentageComplete, hasWarnings, hasSoftErrors, size: 'small'})}
                </span>
            </div>

            <div className="table-cell no-stretch">
                {showSkippableButton &&
                    <Button text={t('common:option.skip')} aria-label={t('aria:hiddenAssistText.skipOperation')}
                        onClick={onSkipOperation}
                    >
                        <span className="icon is-small">
                            <img src={icon('operationSkip')} alt={t(`aria:hiddenAssistText.skipOperationIcon`)}/>
                        </span>
                    </Button>
                }
            </div>
        </div>
    )
}

function OperationName(props) {
    const {t} = useTranslation(['workflowBuilder']);
    const {name, alias, enableHighlightText} = props;
    const operationIcon = operationIconModel.useIcon(alias || name);

    const _name = !alias ? name : t(`workflowBuilder:operationAlias.${alias}`);

    return (
        <div className="operation-name">
            <img className="operation-icon spacing-right" src={operationIcon} alt={name}/>
            <label className="label">
                <HighlightText text={_name} isDisabled={!enableHighlightText}/>
            </label>
        </div>
    );
}
