import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TableLabelCell, TableLabelHeader} from "../../common/CustomTable/CustomTable";
import {useSelector} from "react-redux";
import {noticeTypeKeys} from "../../../utilities/constants";
import Text from "../../common/Text/Text";
import Switch from "../../common/Switch/Switch";
import {getLocaleStringFromFormattedDate, getPluralTranslations} from "../../../utilities/helperFunctions";


function NoticeInfoTable(props) {
    const {t} = useTranslation(['notice', 'noticeTemplate']);

    const {
        noticeConfigurations,
        noticeInfos,
        isDisabled
    } = props;

    const [infos, setInfos] = useState({});

    useEffect(() => {
        setInfos(
            noticeInfos.reduce((acc, curr) => {
                acc[curr.id] = curr;
                return acc;
            }, {})
        );

    }, [noticeInfos]);


    return (
        <div className="display-table tight-padding">
            <div className="table-header-group">
                <TableLabelHeader label={t('notice:label.type')} style={{width: '8rem'}}
                    isDisabled={isDisabled}/>

                <TableLabelHeader label={t('noticeTemplate:label.template')} style={{width: '14rem'}}
                    isDisabled={isDisabled}/>

                <TableLabelHeader label={t('noticeTemplate:label.respondBy')} style={{width: '10rem'}}
                    isDisabled={isDisabled}/>

                <TableLabelHeader label={t('noticeTemplate:label.remindEvery')} style={{width: '10rem'}}
                    isDisabled={isDisabled}/>

                <TableLabelHeader label={t('notice:label.subject')} style={{minWidth: '8rem'}}
                    isDisabled={isDisabled}/>

                <TableLabelHeader label={t('notice:label.sent')} style={{width: '6rem'}}
                    alignRight isDisabled={isDisabled}/>

                <TableLabelHeader label={t('notice:label.viewed')} style={{width: '6rem'}}
                    alignRight isDisabled={isDisabled}/>

                <TableLabelHeader label={t('notice:label.responded')} style={{width: '6rem'}}
                    alignRight isDisabled={isDisabled}/>

                <TableLabelHeader label={t('notice:label.escalated')} style={{width: '6rem'}}
                    alignRight isDisabled={isDisabled}/>
            </div>

            <div className="table-row-group">
                {noticeConfigurations.map(configuration => {

                    const noticeInfo = infos[configuration.noticeId];
                    const reminderInfo = infos[configuration.reminderNoticeId];
                    const escalateInfo = infos[configuration.escalateNoticeId];

                    return (
                        <React.Fragment key={configuration.id}>
                            {noticeInfo &&
                            <NoticeInfoRow key={noticeInfo.id} {...configuration} {...noticeInfo} isDisabled={isDisabled}/>
                            }

                            {reminderInfo &&
                            <NoticeInfoRow key={reminderInfo.id} {...reminderInfo} noticeTemplateId={configuration.reminderNoticeTemplateId}
                                isDisabled={isDisabled}/>
                            }

                            {escalateInfo &&
                            <NoticeInfoRow key={escalateInfo.id} {...escalateInfo} noticeTemplateId={configuration.escalateNoticeTemplateId}
                                isDisabled={isDisabled}/>
                            }
                        </React.Fragment>
                    )
                })}
            </div>
        </div>
    )
}

function NoticeInfoRow(props) {
    const {t} = useTranslation(['noticeTemplate']);

    const {
        type,
        enabled,

        noticeTemplateId,
        requireResponse,
        respondByKey,
        respondByDate,
        respondByNDays,

        enableRemind,
        remindEveryNDays,

        subject,
        eventsCount,
        viewedCount,
        respondedCount,
        escalatedCount
    } = props;

    const isDisabled = props.isDisabled || !enabled;
    const {name: templateName} = useSelector(state => state.noticeTemplateDetailsMap.get(noticeTemplateId)) || {};

    return (
        <div className="table-row no-pointer">
            <div className="table-cell">
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {(type === noticeTypeKeys.REMINDER || type === noticeTypeKeys.ESCALATION) &&
                    <span className={'dot-span' + (isDisabled ? ' is-disabled': '')} style={{marginRight: '0.25rem'}}/>
                    }
                    <Text value={t(`noticeTemplate:type.${type}`)} isEllipsis
                        isDisabled={isDisabled}/>
                </div>
            </div>

            <TableLabelCell label={templateName} isWordWrap
                isDisabled={isDisabled}/>

            <div className="table-cell">
                {requireResponse &&
                <Switch>
                    {respondByKey === 'respondByDate' &&
                    <Text value={getLocaleStringFromFormattedDate(respondByDate)}
                        isDisabled={isDisabled}/>
                    }
                    {respondByKey === 'respondByNDays' &&
                    <Text value={getPluralTranslations(t, 'common:label.NDays', {count: respondByNDays})}
                        isDisabled={isDisabled}/>
                    }
                </Switch>
                }
            </div>

            <div className="table-cell">
                {enableRemind &&
                <Text value={getPluralTranslations(t, 'common:label.NDays', {count: remindEveryNDays})}
                    isDisabled={isDisabled}/>
                }
            </div>

            <TableLabelCell label={subject} isWordWrap
                isDisabled={isDisabled}/>

            <TableLabelCell label={eventsCount} alignRight
                isDisabled={isDisabled}/>

            <TableLabelCell label={viewedCount} alignRight
                isDisabled={isDisabled}/>

            <TableLabelCell label={respondedCount} alignRight
                isDisabled={isDisabled}/>

            <TableLabelCell label={escalatedCount} alignRight
                isDisabled={isDisabled}/>
        </div>
    )
}

export default NoticeInfoTable;
