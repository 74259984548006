import React from 'react';
import {updateGenericValues} from "../../../redux/WizardSlice/wizardSlice";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {createToggleHandler} from "../../../../components/common/Checkbox/helpers";
import {createInputHandler, createValueHandler} from "../../../../utilities/componentFunctions";
import {createDropdownHandler} from "../../../../components/common/Dropdown/helpers";
import ExpandableContent from "../../../../components/common/ExpandableContent/ExpandableContent";
import {RelativityWorkspaceIdentifierInput} from "../../Input/Relativity/RelativityWorkspaceIdentifierInput";
import {QuestionInput} from "../../Question/QuestionInput/QuestionInput";
import HTMLTextInput from "../../../../components/common/HTMLTextInput/HTMLTextInput";
import {ComponentTableRow, SpinnerTableRow, SwitchTableRow} from "../../Table/TableRow";
import ToggleSwitch from "../../../../components/common/ToggleSwitch/ToggleSwitch";

function RelativityConfiguration (props) {
    const {t} = useTranslation(['wizard']);
    const {updateState} = props;

    const {
        urlOptions,
        hostName,
        serviceEndpoint,
        endpointType,
        username,
        password,
        workspaceIdentifierType,
        workspaceIdentifier,
        folderPath,
        createIfNotExists,
        importThreads,
        importThreadTimeout,
        importThreadRetries,
        metadataThreads,
        patchInvalidEntries,
        useCustomClientVersion,
        customClientVersion
    } = props;

    const toggleEnable = createToggleHandler({
        handler: updateState
    });
    const inputHandler = createInputHandler({
        handler: updateState
    });
    const valuesHandler = createValueHandler({
        handler: updateState
    });
    const dropdownHandler = createDropdownHandler({
        handler: updateState
    });


    const serviceEndpointProps = {
        name: "serviceEndpoint",
        value: serviceEndpoint,
        onChange: inputHandler,
        isRequired: false,
        isDisabled: false
    }

    const hostnameProps = {
        name: "hostName",
        dataName: "hostName",
        value: hostName,
        onClick: toggleEnable,
        checked: hostName
    }

    const usernameProps = {
        name: "username",
        dataName: "username",
        value: username,
        onClick: toggleEnable,
        checked: username
    }

    const passwordProps = {
        name: "password",
        dataName: "password",
        value: password,
        onClick: toggleEnable,
        checked: password
    }

    const importThreadsProps = {
        name: "importThreads",
        value: importThreads,
        onChange: inputHandler,
        type: "number",
        isRequired: false,
        isDisabled: false
    }

    const importThreadTimeoutProps = {
        name: "importThreadTimeout",
        value: importThreadTimeout,
        onChange: inputHandler,
        type: "number",
        isRequired: false,
        isDisabled: false
    }

    const importThreadRetriesProps = {
        name: "importThreadRetries",
        value: importThreadRetries,
        onChange: inputHandler,
        type: "number",
        isRequired: false,
        isDisabled: false
    }

    const metadataThreadsProps = {
        name: "metadataThreads",
        value: metadataThreads,
        onChange: inputHandler,
        type: "number",
        isRequired: false,
        isDisabled: false
    }

    return (
        <div className={"wizard-step-container"}>
            <RelativityWorkspaceIdentifierInput
                workspaceId={workspaceIdentifierType}
                workspaceText={workspaceIdentifier}
                dropdownChange={dropdownHandler}
                inputChange={inputHandler}/>

            <QuestionInput
                name={"folderPath"}
                question={t('wizard:questions.relativity.folderPath')}
                value={folderPath}
                onChange={inputHandler}
                isRequired={true} />

            <div className="dynamic-examples-container">
                <label className="label is-bold">
                    {t('wizard:questions.relativity.setup.p1')}
                </label>
                <label className="label is-small" style={{fontSize: '0.85rem'}}>
                    {t('wizard:questions.relativity.setup.p2')}
                    <li style={{marginLeft: '1rem'}}><label className="is-bold">{"{relativity_hostname}"}</label>{t('wizard:questions.relativity.setup.p3')}</li>
                    <li style={{marginLeft: '1rem'}}><label className="is-bold">{"{relativity_username}"}</label>{t('wizard:questions.relativity.setup.p4')}</li>
                    <li style={{marginLeft: '1rem'}}><label className="is-bold">{"{relativity_password}"}</label>{t('wizard:questions.relativity.setup.p5')}</li>
                </label>
                <br/>
                <label className="label is-small" style={{fontSize: '0.85rem'}}>
                    {t('wizard:questions.relativity.setup.p6')}<label className="is-bold">{t('wizard:questions.relativity.setup.p7')}</label>
                    {t('wizard:questions.relativity.setup.p8')}<label className="is-bold">{t('wizard:questions.relativity.setup.p9')}</label>.
                </label>
            </div>

            <div className={"display-item"}>
                <ExpandableContent label={t('wizard:questions.relativity.setup.p7')} initialExpanded={false}
                    style={{
                        marginLeft: "17px"
                    }}
                >
                    <div className={'settings-table no-border tight-padding'} style={{border: '1px solid transparent', maxWidth: '35rem'}}>
                        <div className="table-row-group">
                            <SwitchTableRow stateName={hostnameProps.name} label={t('wizard:questions.relativity.advanced.defineExecution', {parameter: "{relativity_hostname}"})} component={<ToggleSwitch {...hostnameProps}/>}/>
                            <ComponentTableRow stateName={serviceEndpointProps.name} label={t('wizard:questions.relativity.advanced.serviceEndpoint')} component={<HTMLTextInput {...serviceEndpointProps}/>}/>
                            <SwitchTableRow stateName={usernameProps.name} label={t('wizard:questions.relativity.advanced.defineExecution', {parameter: "{relativity_username}"})} component={<ToggleSwitch {...usernameProps}/>}/>
                            <SwitchTableRow stateName={passwordProps.name} label={t('wizard:questions.relativity.advanced.defineExecution', {parameter: "{relativity_password}"})} component={<ToggleSwitch {...passwordProps}/>}/>
                            <SpinnerTableRow stateName={importThreadsProps.name} label={t('wizard:questions.relativity.advanced.importThreads')} component={<HTMLTextInput {...importThreadsProps}/>}/>
                            <SpinnerTableRow stateName={importThreadTimeoutProps.name} label={t('wizard:questions.relativity.advanced.importThreadTimeout')} component={<HTMLTextInput {...importThreadTimeoutProps}/>} addLabel={"seconds"}/>
                            <SpinnerTableRow stateName={importThreadRetriesProps.name} label={t('wizard:questions.relativity.advanced.importThreadRetries')} component={<HTMLTextInput {...importThreadRetriesProps}/>}/>
                            <SpinnerTableRow stateName={metadataThreadsProps.name} label={t('wizard:questions.relativity.advanced.metadataThreads')} component={<HTMLTextInput {...metadataThreadsProps}/>}/>

                        </div>
                    </div>
                </ExpandableContent>
            </div>

        </div>
    )
}

function mapStateToProps (state) {
    const {wizardSlice:{relativityConfiguration:
            {hostName, serviceEndpoint, endpointType, username, password, workspaceIdentifierType, workspaceIdentifier, folderPath, createIfNotExists,
                importThreads, importThreadTimeout, importThreadRetries, metadataThreads, patchInvalidEntries, useCustomClientVersion, customClientVersion}}} = state;
    return {
        hostName,
        serviceEndpoint,
        endpointType,
        username,
        password,
        workspaceIdentifierType,
        workspaceIdentifier,
        folderPath,
        createIfNotExists,
        importThreads,
        importThreadTimeout,
        importThreadRetries,
        metadataThreads,
        patchInvalidEntries,
        useCustomClientVersion,
        customClientVersion
    };
}

function mapDispatchToProps (dispatch) {
    return {
        updateState: updates => dispatch(updateGenericValues({stateName: "relativityConfiguration", updates: updates}))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RelativityConfiguration);