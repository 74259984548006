import React from "react";
import {useDispatch, useSelector} from "react-redux";
import NotificationRuleModel from "../../../models/settings/NotificationRuleModel";
import {useTranslation} from "react-i18next";
import {StatusNameDescriptionCell, TableRow} from "../../common/CustomTable/CustomTable";
import LimitedTableRow from "../../limited/LimitedTableRow";
import {permissionKeys} from "../../../i18next/keys";
import {getMapValueName} from "../../../utilities/helperFunctions";
import Text from "../../common/Text/Text";

function EmailNotificationRuleRow(props) {
    const {t} = useTranslation(['notificationRule', 'job']);
    const dispatch = useDispatch();

    const {
        rule
    } = props;

    const isActive = useSelector(state => state.componentStates.notificationRuleDisplay.notificationRuleId === rule.id);

    function showTablet(event) {
        dispatch(NotificationRuleModel.actionCreators.showTablet(event.currentTarget.dataset.value));
    }

    const smtpServiceName = useSelector(state => {
        return getMapValueName(state.thirdPartyServiceDetailsMap, rule.smtpServiceId);
    });

    const isViewLimited = rule.userPermissions.includes(permissionKeys.VIEW_LIMITED);
    const canModify = rule.userPermissions.includes(permissionKeys.MODIFY);

    if (isViewLimited && !canModify) {
        return (
            <LimitedTableRow id={rule.id} isActive={isActive} onClick={showTablet} cellCount={4}
                nameComponent={
                <StatusNameDescriptionCell status={rule.status} name={ruke.name}
                    description={rule.description}/>
            }/>
        )
    }

    return (
        <TableRow value={rule.id} isActive={isActive} onClick={showTablet}>
            <StatusNameDescriptionCell style={{maxWidth: '12rem'}}
                status={rule.status} name={rule.name} description={rule.description} />

            <div className="table-cell max-width-12">
                <label className="label is-ellipsis">{smtpServiceName}</label>
            </div>

            <div className="table-cell max-width-12">
                <label className="label is-ellipsis">{rule.to}</label>
            </div>

            <div className="table-cell">
                {rule.triggers.map((trigger, index) =>
                    <Text key={index} value={t(`job:trigger.${trigger}`)} isEllipsis/>
                )}
            </div>
        </TableRow>
    )
}

export default EmailNotificationRuleRow;
