import React, {useCallback, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {createEditHandler, createInputHandler} from "../../../utilities/componentFunctions";
import {createToggleHandler} from "../../common/Checkbox/helpers";
import Tablet, {EditTabletHeader} from "../../common/Tablet/Tablet";
import {DefaultEditPanel} from "../../common/EditPanel/EditPanel";
import ExpandableContent, {
    ExpandableEditTextArea,
    ExpandableTimeStampedLog,
    ExpandableValueList
} from "../../common/ExpandableContent/ExpandableContent";
import {bytesCountToReadableCount, isNotEmptyNorFalsy, switchcaseF} from "../../../utilities/helperFunctions";
import {SettingsRowValue} from "../../common/CustomTable/CustomTable";
import Checkbox from "../../common/Checkbox/Checkbox";
import {shouldEnableAzureResourcePool} from "../../../utilities/shouldEnableFunctions";
import {permissionKeys, statusKeys} from "../../../i18next/keys";
import ResourcePoolModel from "../../../models/settings/ResourcePoolModel";
import EditModel from "../../../models/scheduler/EditModel";
import {makeGetEditDetails} from "../../../reselect/selectors";
import {ExpandableStatusLog} from "../../common/Common";
import {AzurePoolEngineForm, AzurePoolSettingsForm} from "./AzureResourcePoolForm";
import Text from "../../common/Text/Text";
import Switch from "../../common/Switch/Switch";
import {azureVmSources} from "../../../utilities/constants";
import {createDropdownHandler} from "../../common/Dropdown/helpers";
import LimitedTablet from "../../limited/LimitedTablet";


const selectEditDetails = makeGetEditDetails();
function selectAzurePoolDetails(state, resourcePoolId) {

    const {activeModel, values, isSaveEnabled} = selectEditDetails(state, {model: ResourcePoolModel.nom});
    const isEditActive = (activeModel === ResourcePoolModel.nom);

    const {name: azureResourcePoolName, cloudSettings, ...otherDetails} = state.resourcePoolDetailsMap.get(resourcePoolId);
    const {nuixLicenceSourceId, remoteNuixLicenceSourceId, azureSettings, remoteAzureSettings, ...otherCloudSettings} = cloudSettings;

    const nuixLicenceSourceDetails = state.nuixLicenceSourceDetailsMap.get(nuixLicenceSourceId) || {};
    const nuixLicenceSourceName = nuixLicenceSourceDetails.name || nuixLicenceSourceId;

    const remoteNuixLicenceSourceDetails = state.nuixLicenceSourceDetailsMap.get(remoteNuixLicenceSourceId) || {};
    const remoteNuixLicenceSourceName = remoteNuixLicenceSourceDetails.name || remoteNuixLicenceSourceId;

    return {
        azureResourcePoolName,
        nuixLicenceSourceName,
        nuixLicenceSourceId,
        remoteNuixLicenceSourceName,
        remoteNuixLicenceSourceId,
        ...otherDetails,
        ...otherCloudSettings,
        ...azureSettings,
        ...remoteAzureSettings,
        ...values,
        isEditActive,
        isSaveEnabled
    };
}

function AzureResourcePoolTablet(props) {
    const {t} = useTranslation(['resourcePool', 'common']);
    const dispatch = useDispatch();

    const {
        resourcePoolId
    } = props;

    const {
        isDisabled
    } = useSelector(state => state.componentStates.resourcePoolTablet);

    const passwordRef = useRef({});
    const customVmPasswordRef = useRef({});
    const remoteCustomVmPasswordRef = useRef({});

    const {
        azureResourcePoolName,
        description,
        active,

        azureEnvironment,
        tenant,
        azureKey,
        appId,
        subscriptionId,

        azureVmSource,
        vmNames,
        tagName,
        tagValue,
        region,
        resourceGroupId,
        networkName,
        networkSubnetName,
        networkSecurityGroup,
        customVmImageId,
        customVmUsername,
        customVmPassword,
        maxConcurrentInstances,
        vmType,
        vmSize,
        diskSize,
        nuixLicenceSourceName,
        nuixLicenceSourceId,
        targetNuixWorkers,
        minNuixWorkers,
        instanceIdleAction,
        forceIdleActionBetweenJobs,

        useRemoteEngines,
        remoteAzureVmSource,
        remoteVmNames,
        remoteTagName,
        remoteTagValue,
        remoteRegion,
        remoteResourceGroupId,
        remoteNetworkName,
        remoteNetworkSubnetName,
        remoteNetworkSecurityGroup,
        remoteCustomVmImageId,
        remoteCustomVmUsername,
        remoteCustomVmPassword,
        remoteMaxConcurrentInstances,
        remoteVmType,
        remoteVmSize,
        remoteDiskSize,
        remoteNuixLicenceSourceName,
        remoteNuixLicenceSourceId,
        remoteTargetNuixWorkers,
        remoteMinNuixWorkers,
        remoteInstanceIdleAction,
        remoteNoIdleBeforeFirstJob,
        remoteNoIdleDuringJob,

        whitelistedCertFingerprints,
        log,
        userPermissions,
        status,

        isEditActive,
        isSaveEnabled
    } = useSelector(state => selectAzurePoolDetails(state, resourcePoolId));

    const {
        saveEdit,
        cancelEdit,
        updateEdit,
        setEditSaveEnabled
    } = EditModel.buildDispatchers(dispatch);

    const editHandler = createEditHandler({
        updateEdit,
        setEditSaveEnabled,
        shouldEnable: shouldEnableAzureResourcePool,
        passwordOptions: {
            azureKey: {
                ref: passwordRef,
                resetNames: ['tenant']
            },
            customVmPassword: {
                ref: customVmPasswordRef,
                resetNames: ['customVmUsername']
            },
            remoteCustomVmPassword: {
                ref: remoteCustomVmPasswordRef,
                resetNames: ['remoteCustomVmUsername', 'useRemoteEngines']
            }
        }
    });

    const inputHandler = createInputHandler({
        handler: editHandler
    });

    const toggleHandler = createToggleHandler({
        handler: editHandler
    });

    const dropdownHandler = createDropdownHandler({
        handler: editHandler
    });

    function setWhitelistedCertFingerprints(update) {
        editHandler({
            whitelistedCertFingerprints: update(whitelistedCertFingerprints)
        });
    }

    function setVmNames(update) {
        editHandler({
            vmNames: update(vmNames)
        });
    }

    function setRemoteVmNames(update) {
        editHandler({
            remoteVmNames: update(remoteVmNames)
        });
    }

    const onClose = useCallback(() => {
        dispatch(ResourcePoolModel.actionCreators.hideTablet())
    }, []);

    const menuOptionHandler = useCallback(event => {
        const {value} = event.currentTarget.dataset;

        switchcaseF({
            'edit': () => dispatch(ResourcePoolModel.actionCreators.startEdit(resourcePoolId)),
            'toggleActive': () => dispatch(ResourcePoolModel.actionCreators.toggleEnabled(resourcePoolId)),
            'promptDelete': () => dispatch(ResourcePoolModel.actionCreators.promptDelete(resourcePoolId)),
            'duplicate': () => dispatch(ResourcePoolModel.actionCreators.duplicateDetails(resourcePoolId))
        })()(value);
    }, [resourcePoolId]);

    const menuOptions = [
        {name: t('common:option.edit'), value: 'edit', isDisabled: false},
        {name: t(`common:option.${active ? 'deactivate' : 'activate'}`), value: 'toggleActive', isDisabled: false},
        {name: t('common:option.delete'), value: 'promptDelete', isDisabled: false},
        {isSeparator: true},
        {name: t('common:option.duplicate'), value: 'duplicate'}
    ];


    const engineConfiguration = {
        azureVmSource, region, resourceGroupId, networkName, networkSubnetName, networkSecurityGroup, customVmImageId, customVmUsername, customVmPassword, maxConcurrentInstances, vmType, vmSize, diskSize,
        nuixLicenceSourceId, targetNuixWorkers, minNuixWorkers, instanceIdleAction, forceIdleActionBetweenJobs, tagName, tagValue
    };
    const remoteEngineConfiguration = {
        azureVmSource: remoteAzureVmSource, region: remoteRegion, resourceGroupId: remoteResourceGroupId, networkName: remoteNetworkName, networkSubnetName: remoteNetworkSubnetName, networkSecurityGroup: remoteNetworkSecurityGroup,
        customVmImageId: remoteCustomVmImageId, customVmUsername: remoteCustomVmUsername, customVmPassword: remoteCustomVmPassword, maxConcurrentInstances: remoteMaxConcurrentInstances, vmType: remoteVmType, vmSize: remoteVmSize, diskSize: remoteDiskSize,
        nuixLicenceSourceId: remoteNuixLicenceSourceId, targetNuixWorkers: remoteTargetNuixWorkers, minNuixWorkers: remoteMinNuixWorkers,
        instanceIdleAction: remoteInstanceIdleAction, noIdleBeforeFirstJob: remoteNoIdleBeforeFirstJob, noIdleDuringJob: remoteNoIdleDuringJob,
        tagName : remoteTagName, tagValue: remoteTagValue
    };

    const canModifyResourcePool = userPermissions.includes(permissionKeys.MODIFY);
    const showDescription = isEditActive || description;
    const showWhitelistedCertFingerprints = isEditActive || (whitelistedCertFingerprints || []).some(fingerprint => fingerprint);

    const isViewLimited = userPermissions.includes(permissionKeys.VIEW_LIMITED);
    if (isViewLimited && !canModifyResourcePool) {
        return (
            <LimitedTablet id={resourcePoolId} type={'azureResourcePool'} name={azureResourcePoolName} label={t(`resourcePool:label.azureName`)}
                           status={status} enabled={active} canModify={canModifyResourcePool} isDisabled={isDisabled} description={description}
                           onClose={onClose}
            />
        )
    }

    return (
        <Tablet width={'100rem'} height={'auto'} onClose={onClose}
            closeButtonAriaLabel={t('resourcePool:option.closeAzureTablet_name', {name: azureResourcePoolName})}
            header={
                <EditTabletHeader label={t('resourcePool:label.azureName')} type={'azureResourcePool'} name={'azureResourcePoolName'}
                    id={resourcePoolId} value={azureResourcePoolName} enabled={active} menuOptions={menuOptions} menuOptionHandler={menuOptionHandler}
                    inputHandler={inputHandler} canModify={canModifyResourcePool} isEditActive={isEditActive} isDisabled={isDisabled}/>
            }
            body={
                <>
                    {status.code === statusKeys.ERROR && !isEditActive &&
                    <div className="display-item">
                        <ExpandableStatusLog {...status} isDisabled={isDisabled}/>
                    </div>
                    }

                    <div className="display-item">
                        <DefaultEditPanel isActive={isEditActive} isSaveEnabled={isSaveEnabled} onSave={saveEdit} onCancel={cancelEdit}>
                            {showDescription &&
                            <ExpandableEditTextArea label={t('common:label.description')} name={'description'}
                                value={description} onChange={inputHandler} isEditActive={isEditActive} isDisabled={isDisabled}
                            />
                            }

                            <ExpandableContent label={t('common:label.settings')}>
                                {isEditActive ?
                                    <AzurePoolSettingsForm azureEnvironment={azureEnvironment} tenant={tenant} azureKey={azureKey} appId={appId} subscriptionId={subscriptionId}
                                        passwordRef={passwordRef} inputHandler={inputHandler} dropdownHandler={dropdownHandler} isDisabled={isDisabled}/>
                                    :
                                    <AzurePoolSettings azureEnvironment={azureEnvironment} tenant={tenant} appId={appId} subscriptionId={subscriptionId} isDisabled={isDisabled}/>
                                }
                            </ExpandableContent>

                            <ExpandableContent label={t('resourcePool:label.engines')}
                                isDisabled={isDisabled}
                            >
                                {isEditActive ?
                                    <AzurePoolEngineForm {...engineConfiguration} customVmPasswordRef={customVmPasswordRef}
                                        inputHandler={inputHandler} toggleHandler={toggleHandler} dropdownHandler={dropdownHandler}
                                        vmNames={vmNames} setVmNames={setVmNames} isDisabled={isDisabled}/>
                                    :
                                    <AzurePoolEngineSettings {...engineConfiguration} nuixLicenceSourceName={nuixLicenceSourceName}
                                        vmNames={vmNames} isDisabled={isDisabled}/>
                                }
                            </ExpandableContent>

                            {(useRemoteEngines || isEditActive) &&
                            <ExpandableContent label={t('resourcePool:label.remoteEngines')}
                                isDisabled={isDisabled}
                            >
                                {isEditActive ?
                                    <>
                                        <div style={{marginTop: '0.25rem', marginBottom: '0.5rem'}}>
                                            <Checkbox label={t('resourcePool:label.useRemoteEngines')} name={'useRemoteEngines'} checked={useRemoteEngines}
                                                onClick={toggleHandler} isDisabled={isDisabled}/>
                                        </div>

                                        {useRemoteEngines &&
                                        <AzurePoolEngineForm {...remoteEngineConfiguration} customVmPasswordRef={remoteCustomVmPasswordRef}
                                            inputHandler={inputHandler} toggleHandler={toggleHandler} dropdownHandler={dropdownHandler}
                                            vmNames={remoteVmNames} setVmNames={setRemoteVmNames} isRemoteConfiguration  isDisabled={isDisabled}/>
                                        }
                                    </>
                                    :
                                    <AzurePoolEngineSettings {...remoteEngineConfiguration} nuixLicenceSourceName={remoteNuixLicenceSourceName}
                                        vmNames={remoteVmNames} isDisabled={isDisabled}/>
                                }
                            </ExpandableContent>
                            }

                            {showWhitelistedCertFingerprints &&
                            <ExpandableValueList id={'whitelistedCertFingerprints'} label={t('common:label.whitelistedCertFingerprints')}
                                ariaLabelKey={'WhitelistedCertFingerprint'} values={whitelistedCertFingerprints}
                                setValues={setWhitelistedCertFingerprints} isEditActive={isEditActive} isDisabled={isDisabled}/>
                            }
                        </DefaultEditPanel>
                    </div>

                    {isNotEmptyNorFalsy(log) && !isEditActive &&
                    <div className="display-item">
                        <ExpandableTimeStampedLog label={t('resourcePool:label.log')} log={log} isDisabled={isDisabled}/>
                    </div>
                    }
                </>
            }
        />
    )
}

function AzurePoolSettings(props) {
    const {t} = useTranslation(['resourcePool']);

    const {
        azureEnvironment,
        tenant,
        appId,
        subscriptionId,

        isDisabled
    } = props;

    return (
        <div className="settings-table">
            <div className="table-row-group">
                <SettingsRowValue label={t('resourcePool:label.environment')} value={azureEnvironment} isDisabled={isDisabled}/>

                <SettingsRowValue label={t('resourcePool:label.tenant')} value={tenant} isDisabled={isDisabled}/>

                <SettingsRowValue label={t('resourcePool:label.appId')} value={appId} isDisabled={isDisabled}/>

                <SettingsRowValue label={t('resourcePool:label.subscriptionId')} value={subscriptionId} isDisabled={isDisabled}/>
            </div>
        </div>
    )
}

function AzurePoolEngineSettings(props) {
    const {t} = useTranslation(['resourcePool']);

    const {
        nuixLicenceSourceName,
        targetNuixWorkers,
        minNuixWorkers,

        azureVmSource,
        vmNames,
        tagName,
        tagValue,
        region,
        resourceGroupId,
        networkName,
        networkSubnetName,
        networkSecurityGroup,
        customVmImageId,
        customVmUsername,
        maxConcurrentInstances,
        vmType,
        vmSize,
        diskSize,

        instanceIdleAction,
        forceIdleActionBetweenJobs,
        noIdleBeforeFirstJob,
        noIdleDuringJob,

        isDisabled
    } = props;

    return (
        <>
            <div className="settings-table">
                <div className="table-row-group">

                    <SettingsRowValue label={t('resourcePool:label.nuixLicenceSource')} value={nuixLicenceSourceName} isDisabled={isDisabled}/>

                    <SettingsRowValue label={t('resourcePool:label.targetWorkers')} value={targetNuixWorkers} isDisabled={isDisabled}/>

                    <SettingsRowValue label={t('resourcePool:label.minWorkers')} value={minNuixWorkers} isDisabled={isDisabled}/>

                    <Switch>
                        {azureVmSource === azureVmSources.vmNames &&
                        <SettingsRowValue label={t('resourcePool:label.vmNames')} isDisabled={isDisabled}
                            value={vmNames.map(name =>
                                <Text key={name} value={name} isDisabled={isDisabled}/>
                            )}/>
                        }

                        {azureVmSource === azureVmSources.tags &&
                        <>
                            <SettingsRowValue label={t('resourcePool:label.tagName')} value={tagName} isDisabled={isDisabled}/>
                            <SettingsRowValue label={t('resourcePool:label.tagValue')} value={tagValue} isDisabled={isDisabled}/>
                        </>
                        }


                        {azureVmSource === azureVmSources.customVmImage &&
                        <>
                            <SettingsRowValue label={t('resourcePool:label.region')} value={region} isDisabled={isDisabled}/>

                            <SettingsRowValue label={t('resourcePool:label.resourceGroupId')} value={resourceGroupId} isDisabled={isDisabled}/>

                            <SettingsRowValue label={t('resourcePool:label.networkName')} value={networkName} isDisabled={isDisabled}/>

                            <SettingsRowValue label={t('resourcePool:label.networkSubnetName')} value={networkSubnetName} isDisabled={isDisabled}/>

                            <SettingsRowValue label={t('resourcePool:label.networkSecurityGroup')} value={networkSecurityGroup} isDisabled={isDisabled}/>

                            <SettingsRowValue label={t('resourcePool:label.customVmImageId')} value={customVmImageId} isDisabled={isDisabled}/>

                            <SettingsRowValue label={t('resourcePool:label.customVmUsername')} value={customVmUsername} isDisabled={isDisabled}/>

                            <SettingsRowValue label={t('resourcePool:label.maxConcurrentInstances')} value={maxConcurrentInstances} isDisabled={isDisabled}/>

                            <SettingsRowValue label={t('resourcePool:label.vmType')} value={vmType} isDisabled={isDisabled}/>

                            <SettingsRowValue label={t('resourcePool:label.vmSize')} value={vmSize} isDisabled={isDisabled}/>

                            <SettingsRowValue label={t('resourcePool:label.diskSize')} value={bytesCountToReadableCount(diskSize * 1000000000)} isDisabled={isDisabled}/>
                        </>
                        }
                    </Switch>

                    <SettingsRowValue label={t('resourcePool:label.instanceIdleAction')} value={t(`resourcePool:instanceIdleAction.${instanceIdleAction}`)}
                        isDisabled={isDisabled}/>
                </div>
            </div>

            {(forceIdleActionBetweenJobs || noIdleBeforeFirstJob || noIdleDuringJob) &&
            <div className="display-input">
                {forceIdleActionBetweenJobs &&
                <Text value={t('resourcePool:label.forceIdleActionBetweenJobs')} isDisabled={isDisabled}/>
                }
                {noIdleBeforeFirstJob &&
                <Text value={t('resourcePool:label.remoteNoIdleBeforeFirstJob')} isDisabled={isDisabled}/>
                }
                {noIdleDuringJob &&
                <Text value={t('resourcePool:label.remoteNoIdleDuringJob')} isDisabled={isDisabled}/>
                }
            </div>
            }
        </>
    )
}

export default AzureResourcePoolTablet;
