import React, {useEffect, useRef, useState} from "react";
import {buildClassName, isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import {getInvalidMessageSpan} from "../InvalidMessageSpan";
import {useGetMissingClasses} from "../AccessibilityAssist";


function HTMLTextInput(props, ref) {
    const _ref = useRef();

    const {
        inputRef=ref,
        containerClassName,
        containerStyle,
        expandContainer,
        className,

        placeholder,
        label,
        value,
        defaultValue,
        onChange,
        invalidMessage,
        hideInvalidMessage,
        requiredMessage,
        'aria-labelledby': ariaLabelledBy,
        'aria-label': ariaLabel=placeholder,

        isRequired,
        isInvalid,
        isDisabled,
        isInputDisabled,
        isReadOnly,
        isSelected,
        isHeading,
        afterLabel,
        ...attr
    } = props;


    // To start a re-render
    const [isEmpty, setIsEmpty] = useState(typeof value !== 'number' && (value || defaultValue || '').length === 0);

    useEffect(() => {
        if (inputRef != null)
            inputRef.current = _ref.current;

        const refCopy = _ref.current;
        // Clear input on unmount
        return () => {
            refCopy.value = null;
            if (inputRef != null)
                inputRef.current = {};
        }
    }, [inputRef, _ref]);

    useEffect(() => {
        if (_ref.current != null) {
            setIsEmpty(_ref.current.value.length === 0);
        }
    }, [value, _ref.current && _ref.current.value]);

    function onInputChange(event) {
        // Trigger onChange if defined
        typeof onChange == 'function' && onChange(event);
    }

    const id = attr.id || attr.name;
    const labelId = label && `${id}:label`;

    const required = isRequired && isEmpty && !isDisabled;
    const invalid = isInvalid && !isDisabled;

    const {
        combinedAriaLabel,
        combinedAriaLabelledBy,
        invalidMessageSpan
    } = getInvalidMessageSpan({
        id,
        ariaLabel,
        ariaLabelledBy: [labelId, ariaLabelledBy],
        isInvalid: invalid,
        isRequired: required,
        invalidMessage,
        requiredMessage
    });


    // Hack because htmlFor ele.labels doesn't always get populated on first render
    const [initialRender, setInitialRender] = useState(true);
    useEffect(() => {
        setInitialRender(false);
    }, []);

    const missingClasses = useGetMissingClasses(label, {ariaLabel: combinedAriaLabel, ariaLabelledBy: combinedAriaLabelledBy});
    const indexOfMissingAccessibilityClass = missingClasses.indexOf('missing-accessibility');
    // Check if input has an associated label (via htmlFor attr)
    if (indexOfMissingAccessibilityClass >= 0) {
        const ele = document.getElementById(id);
        if (initialRender || isNotEmptyNorFalsy(ele?.labels)) {
            missingClasses.splice(indexOfMissingAccessibilityClass, 1);
        }
    }

    const labelClassName = buildClassName(
        'label',
        isDisabled && 'is-disabled'
    );
    const inputClassName = buildClassName(
        'input',
        'is-small',
        className,
        required && 'is-required',
        invalid && 'is-invalid',
        isInputDisabled && 'is-input-disabled',
        isReadOnly && 'is-readonly',
        isSelected && 'is-selected',
        isHeading && 'is-heading',
        isDisabled && !label && 'is-disabled',
        missingClasses
    );
    const inputContainerClassName = buildClassName(
        'input-container',
        expandContainer && 'expand',
        containerClassName
    );


    const input = (
        <input className={inputClassName} id={id} ref={_ref} value={value} defaultValue={defaultValue} min={0}
            onChange={onInputChange} required={isRequired} disabled={isDisabled || isInputDisabled || isReadOnly}
            aria-label={combinedAriaLabel} title={combinedAriaLabel} aria-labelledby={combinedAriaLabelledBy}
            aria-invalid={required || invalid} placeholder={placeholder} {...attr}/>
    );

    if (afterLabel && label) {
        return (
            <span className={inputContainerClassName} style={containerStyle}>
                <div className={"flex-center"}>
                    <label id={labelId} className={labelClassName} htmlFor={id}>
                        {label}
                    </label>
                    {afterLabel}
                </div>

                {input}
                {!hideInvalidMessage && invalidMessageSpan}
            </span>
        );
    }

    return (
        <span className={inputContainerClassName} style={containerStyle}>
            {label ?
                <label id={labelId} className={labelClassName} htmlFor={id}>
                    {label}
                    {input}
                </label>
                :
                input
            }

            {!hideInvalidMessage && invalidMessageSpan}
        </span>
    )
}

export default React.forwardRef(HTMLTextInput);
