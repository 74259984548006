import React, {useCallback, useRef, useState} from "react";
import {initialSelectedState, useClearSelectedEffect, useValueSelectHandler} from "../../utilities/hooks";
import {
    buildClassName,
    filterArrayIndices,
    getNewArrayWithUpdatedValue,
    getParentDatasetAttr,
    isNotEmptyNorFalsy,
    objectTruthyValues,
    objEquals,
    shallowCopy
} from "../../utilities/helperFunctions";
import {AddRemoveButtons, ImportExportCsvButtons} from "../common/Button/Button";
import Text from "../common/Text/Text";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import {useDropdownHandler, useInputHandler, useToggleHandler} from "../../utilities/formHooks";

function DataSourcesTable(props) {
    const {
        id,
        label,
        afterLabel,
        dataSources,
        setDataSources,
        defaultObject,

        ariaLabelKey,
        headers,
        RowCells,
        rowProps,

        importCsv,
        exportCsv,
        isAddDisabled,
        isRequired,
        isLoading,
        isDisabled
    } = props;

    const containerRef = useRef();
    const [selected, setSelected] = useState(initialSelectedState);

    const selectHandler = useValueSelectHandler({setSelected});
    useClearSelectedEffect({containerRef, setSelected});

    const updateColumn = useCallback((update, event) => {
        const rowIndex = getParentDatasetAttr(event.target || event.currentTarget, 'index');
        setDataSources(prevDataSources => {
            const updatedObject = shallowCopy(prevDataSources[rowIndex], update);
            if (objEquals(updatedObject, prevDataSources[rowIndex])) {
                return prevDataSources;
            }
            return getNewArrayWithUpdatedValue(prevDataSources, updatedObject, rowIndex);
        });
    }, []);

    const addRow = useCallback(() => {
        const _defaultObject = {...defaultObject};
        setDataSources(prevDataSources => {
            if (!Array.isArray(prevDataSources)) {
                return [_defaultObject];
            }
            return [...prevDataSources, _defaultObject]
        });
    }, []);

    const removeSelectedRows = useCallback(() => {
        setDataSources(prevDataSources => {
            const selectedRowIndices = objectTruthyValues(selected.values);
            return filterArrayIndices(prevDataSources, selectedRowIndices);
        });
        setSelected(initialSelectedState);
    }, [selected.values]);

    const inputHandler = useInputHandler({
        updateHandler: updateColumn
    })
    const toggleHandler = useToggleHandler({
        updateHandler: updateColumn
    });
    const dropdownHandler = useDropdownHandler({
        updateHandler: updateColumn
    });

    const containerClassName = buildClassName(
        isRequired && !isDisabled && !isNotEmptyNorFalsy(dataSources) && 'is-required'
    );
    const className = buildClassName(
        'input-tableV2',
        isDisabled && 'is-disabled'
    );

    const isExportDisabled = dataSources.length === 0;
    const isRemoveDisabled = objectTruthyValues(selected.values).length === 0;
    const dropdownButtonStyle = useRef({width: '100%', maxWidth: '100%', justifyContent: 'space-between'});
    const checkboxContainerStyle = useRef({width: '100%', height: '100%', justifyContent: 'center'});
    const checkboxStyle = useRef({marginTop: '-0.125rem'});

    return (
        <div id={id} className={containerClassName} ref={containerRef} style={{border: '1px solid transparent'}}>
            <div className="flex-center space-between">
                <AddRemoveButtons label={label} afterLabel={afterLabel} onAddClick={addRow} onRemoveClick={removeSelectedRows} noPadding
                    isRemoveDisabled={isRemoveDisabled || isLoading} isAddDisabled={isLoading || isAddDisabled}
                    ariaLabelKey={ariaLabelKey} isDisabled={isDisabled}/>

                <ImportExportCsvButtons ariaLabelKey={ariaLabelKey} importCsv={importCsv} exportCsv={exportCsv}
                    canModify isExportDisabled={isExportDisabled} isDisabled={isDisabled || isLoading}/>
            </div>

            <table className={className}>
                <thead>
                <tr>
                    {headers.map(header =>
                        <td key={header.value} style={header.style}>
                            <Text value={header.value}/>
                        </td>
                    )}
                </tr>
                </thead>

                <tbody>
                {Array.isArray(dataSources) && dataSources.map((dataSource, rIndex) =>
                    <tr key={rIndex} data-index={rIndex} onClick={selectHandler}>
                        <RowCells rIndex={rIndex} dataSource={dataSource} inputHandler={inputHandler} toggleHandler={toggleHandler}
                            dropdownHandler={dropdownHandler} dropdownButtonStyle={dropdownButtonStyle.current} checkboxStyle={checkboxStyle.current}
                            checkboxContainerStyle={checkboxContainerStyle.current} isSelected={!isDisabled && selected.values[rIndex]}
                            isDisabled={isDisabled} {...rowProps} />
                    </tr>
                )}
                </tbody>
            </table>
            <LoadingWrapper isLoading={isLoading} style={{top: '4rem'}}/>
        </div>
    )
}

export default DataSourcesTable;
