import React from "react";

export function ComponentTableRow (props) {
    const {stateName, component, label, example} = props;

    return (
        <div key={`${stateName}-row`} className="table-row align-middle no-hover">
            <div key={`${stateName}-label`} className="table-cell no-stretch">
                <label className="label is-small">
                    {label}
                </label>
            </div>
            <div key={`${stateName}-component`} className="table-cell">
                {component}
                {example &&
                    <label className="label is-wordwrap" style={{fontSize: '0.75rem'}}>
                        {example}
                    </label>
                }
            </div>
        </div>
    )
}

export function SwitchTableRow (props) {
    const {stateName, component, label} = props;

    return (
        <div key={`${stateName}-row`} className="table-row align-middle no-hover">
            <div key={`${stateName}-switch`} className="table-cell no-stretch">
                {component}
            </div>
            <div key={`${stateName}-label`} className="table-cell">
                <label className="label is-small">
                    {label}
                </label>
            </div>
        </div>
    )
}

export function SpinnerTableRow (props) {
    const {stateName, component, label, addLabel} = props;

    return (
        <div key={`${stateName}-row`} className="table-row align-middle no-hover">
            <div key={`${stateName}-label`} className="table-cell no-stretch">
                <label className="label is-small">
                    {label}
                </label>
            </div>
            <div key={`${stateName}-component`} className="table-cell">
                <div style={{display: 'flex'}}>
                    <div style={{flexFlow: '1'}}>
                        {component}
                    </div>
                    {addLabel &&
                        <label className="label is-small" style={{alignSelf: 'center', marginLeft: '4px'}}>
                            {addLabel}
                        </label>
                    }
                </div>
            </div>
        </div>
    )
}