import VaultOperationPane from "./VaultOperatonPane";
import React, {useEffect, useState} from "react";
import {objectIdentifierType} from "../../../utilities/constants";
import {vaultSetMatterParameterNames} from "./VaultSetMatterPane";
import VaultCache from "../../../models/guidedjob/VaultCache";
import {useSelector} from "react-redux";
import {useAsyncEffect} from "../../../utilities/hooks";
import {asyncSeparateItems} from "../../common/ListContainer/helpers";
import {SwitchItemContainer} from "../../common/ListContainer/ListContainer";
import {vaultDownloadExportsParameterNames} from "./VaultDownloadExportsPane";
import {getNonEmptyValueOrDefault} from "../../../utilities/helperFunctions";
import {buildOperationFieldParameterNames} from "../../guidedJob/operationFieldParameterNames";
import WorkflowBuilderOperation from "../../../models/workflowbuilder/WorkflowBuilderOperation";

const fieldNames = ['includeLinkedExports', 'waitForCompletion', 'exportIdentifiers', 'exportIdentifierType'];
export const vaultSetExportsParameterNames = buildOperationFieldParameterNames(WorkflowBuilderOperation.Alias.VAULT_SET_EXPORTS, fieldNames);

function getNextEnabled(getParameter) {
    const exportIdentifierType = getParameter(vaultSetExportsParameterNames.exportIdentifierType);
    const exportIdentifiers = getParameter(vaultSetExportsParameterNames.exportIdentifiers, '[]', {parse: true});
    return {
        selectExports: exportIdentifierType.value === objectIdentifierType.ID && exportIdentifiers.value.length > 0
    }
}

export function getVaultSelectExportsGuide(props, operation) {
    const {
        workflowActions,
        updateParameter
    } = props;

    updateParameter(vaultSetExportsParameterNames.waitForCompletion, JSON.stringify(true));
    updateParameter(vaultSetExportsParameterNames.exportIdentifierType, objectIdentifierType.ID);
    updateParameter(vaultDownloadExportsParameterNames.exportIdentifierType, objectIdentifierType.ID);

    return {
        getPanes: function(t, props) {
            const selectExportsTitle = getNonEmptyValueOrDefault(operation?.notes, t('guidedJob:panes.selectExports'));
            const nextEnabled = getNextEnabled(props.getParameter);

            return [{
                title: selectExportsTitle,
                isNextEnabled: nextEnabled.selectExports,
                component: <VaultOperationPane {...props}
                    workflowActions={workflowActions} OperationPane={VaultSelectExportsPane}/>
            }];
        }
    }
}

function VaultSelectExportsPane(props) {
    const {
        proxyRequest,
        getParameter,
        updateParameter,
        isDisabled
    } = props;

    const matterIdentifier = getParameter(vaultSetMatterParameterNames.matterIdentifier);
    const exportIdentifiers = getParameter(vaultSetExportsParameterNames.exportIdentifiers, '[]', {parse: true});
    const [exportItems, setExportItems] = useState({left: [], right: []});

    const exportsKey = VaultCache.getResourceKey(VaultCache.Resource.EXPORTS, {matterId: matterIdentifier.value});
    const exports = useSelector(state => state.vaultCache[exportsKey]) || {};
    const isLoadingExports = exports.cache == null;

    useEffect(() => {
        proxyRequest(VaultCache.Resource.EXPORTS, {matterId: matterIdentifier.value});
    }, []);

    useEffect(() => {
        const exportIds = exportItems.right.map(item => item.value);
        updateParameter(vaultSetExportsParameterNames.exportIdentifiers, JSON.stringify(exportIds));
        updateParameter(vaultDownloadExportsParameterNames.exportIdentifiers, JSON.stringify(exportIds));
    }, [exportItems]);

    useAsyncEffect(async () => {
        if (Array.isArray(exports.cache)) {
            const exportItems = exports.cache.map(exprt => ({
                name: exprt.name,
                value: exprt.id
            }));
            const items = await asyncSeparateItems(exportIdentifiers.value, exportItems);
            setExportItems(items);
        }
    }, [exports.cache]);


    const containerStyle = {flex: 1};
    if (!isLoadingExports && exports.stale) {
        containerStyle.opacity = 0.6;
    }

    return (
        <div className="display-input flex-expand">
            <SwitchItemContainer id="vaultExportsSwitchContainer" style={containerStyle}
                ariaLabelKey={'Export'} items={exportItems} setItems={setExportItems} enableVirtualRendering
                isSearchable isLoading={isLoadingExports} isDisabled={isDisabled}/>
        </div>
    )
}
