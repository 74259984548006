import Tablet, {EditTabletHeader} from "../common/Tablet/Tablet";
import React from "react";
import {useTranslation} from "react-i18next";
import {statusKeys} from "../../i18next/keys";
import {ExpandableStatusLog} from "../common/Common";
import {ExpandableEditTextArea} from "../common/ExpandableContent/ExpandableContent";

function LimitedTablet(props) {
    const {t} = useTranslation('common');
    const {
        id,
        type,
        name,
        label,
        status,
        enabled,
        canModify,
        isDisabled,
        description,

        onClose
    } = props;

    const isDescriptionVisible = !!description;
    const displayableStatusCodes = [statusKeys.ERROR, statusKeys.WARNING, statusKeys.INFO];

    return (
        <Tablet height={'50vh'} onClose={onClose} isDisabled={isDisabled}
            closeButtonAriaLabel={t('common:option.close_name', {name})}
                header={
                    <EditTabletHeader id={id} label={label} type={type} name={'name'} value={name} enabled={enabled}
                                      canModify={canModify} isEditActive={false} isDisabled={isDisabled}
                    />
                }
                body = {
                    <>
                        {(!!status && displayableStatusCodes.includes(status.code)) &&
                            <div className="display-item">
                                <ExpandableStatusLog {...status} isDisabled={isDisabled}/>
                            </div>
                        }

                        {isDescriptionVisible &&
                            <ExpandableEditTextArea label={t('common:label.description')} name={'description'}
                                                    value={description} isEditActive={false} isDisabled={isDisabled}/>
                        }
                    </>
                }
        />
    )
}

export default LimitedTablet;