import React from "react";
import {useTranslation} from "react-i18next";
import {createInputHandler} from "../../../utilities/componentFunctions";
import {createToggleHandler} from "../../common/Checkbox/helpers";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import Checkbox from "../../common/Checkbox/Checkbox";
import TextArea from "../../common/TextArea/TextArea";
import NoticeTemplateModel from "../../../models/notice/NoticeTemplateModel";
import ParameterInputTable from "../../parameters/ParameterInputTable";


function NoticeSettingsPane(props) {
    const {t} = useTranslation(['noticeTemplate', 'common']);

    const {
        model,
        noticeName,
        enabled,

        description,
        parameters,

        handler,
        isDisabled
    } = props;


    const inputHandler = createInputHandler({
        handler
    });
    const toggleHandler = createToggleHandler({
        handler
    });

    function parametersHandler(updates) {
        handler({parameters: updates(parameters)});
    }


    return (
        <div className="pane-margin">
            {model === NoticeTemplateModel.nom &&
            <>
                <div className="display-input">
                    <HTMLTextInput label={t('common:label.name')} name={'noticeName'} value={noticeName}
                        onChange={inputHandler} isDisabled={isDisabled} isRequired />
                </div>

                <div>
                    <Checkbox label={t('common:label.enabled')} name={'enabled'} checked={enabled} onClick={toggleHandler}
                        isDisabled={isDisabled}/>
                </div>

                <div className="display-input">
                    <TextArea label={t('common:label.description')} name={'description'} value={description} onChange={inputHandler}
                        isDisabled={isDisabled}/>
                </div>
            </>
            }

            <div className="display-input">
                <ParameterInputTable parameters={parameters} setParameters={parametersHandler}
                    ignoreRegex isDisabled={isDisabled}/>
            </div>
        </div>
    )
}

export default NoticeSettingsPane;
