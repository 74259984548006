import React, {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useKeyPressEffect} from "../../utilities/hooks";
import {buildClassName, getLocaleDateTimeFromUTC, getValues, isNotEmptyNorFalsy} from "../../utilities/helperFunctions";
import Text from "../common/Text/Text";
import WebhookModel from "../../models/settings/WebhookModel";
import WebhookEventDisplay from "./WebhookEventDisplay";
import {statusKeys} from "../../i18next/keys";
import {translatedStatusIcons} from "../../utilities/iconResolver";
import Switch from "../common/Switch/Switch";


function selectWebhookEvents(state, {webhookId}) {
    return getValues(state.webhookEventDetailsMap)
        .filter(event => event.webhookId === webhookId);
}

function WebhookEventView(props) {
    const {t} = useTranslation(['webhook']);
    const dispatch = useDispatch();

    const {
        id,
        canViewConfidential
    } = props;

    const {
        webhookEventId,
        isDisabled
    } = useSelector(state => state.componentStates.webhookTablet);

    const webhookEvents = useSelector(state => selectWebhookEvents(state, {webhookId: id}));
    const selectedEvent = webhookEvents.find(event => event.id === webhookEventId);

    useEffect(() => {
        if (webhookEventId == null && webhookEvents.length > 0) {

            const lastWebhookEventId = webhookEvents[webhookEvents.length - 1].id;
            dispatch(WebhookModel.componentActionCreators.updateTablet({webhookEventId: lastWebhookEventId}));
        }
    }, [webhookEventId, webhookEvents]);

    function onWebhookEventSelect(event) {
        const {value} = event.currentTarget.dataset;

        dispatch(WebhookModel.componentActionCreators.updateTablet({webhookEventId: value}));
    }


    return (
        <section className="table-view__border-container">
            <header className="tablet-view__header"/>

            <section className="tablet-view">
                <Switch>
                    {isNotEmptyNorFalsy(webhookEvents) &&
                    <aside className="tablet-view__panels">

                        <section className="tablet-view__list">
                            {webhookEvents.map(event =>

                                <WebhookEventPanel key={event.id} {...event} isActive={event.id === webhookEventId}
                                    onClick={onWebhookEventSelect} isDisabled={isDisabled}/>
                            )}
                        </section>
                    </aside>
                    }

                    <Text value={t('webhook:message.noEventsAvailable')} isItalic style={{margin: '1rem auto'}}
                        isDisabled={isDisabled}/>
                </Switch>

                {selectedEvent != null &&
                <WebhookEventDisplay {...selectedEvent} canViewConfidential={canViewConfidential}
                    isDisabled={isDisabled}/>
                }
            </section>

            <footer className="tablet-view__footer"/>
        </section>
    )
}

function WebhookEventPanel(props) {
    const {t} = useTranslation(['webhook', 'common']);

    const {
        id,
        triggerType,
        status,
        epoch,

        onClick,
        isActive,
        isDisabled
    } = props;

    const containerRef = useRef();
    const keyToCb = useRef({
        'Enter': 'click'
    });
    useKeyPressEffect({containerRef, keyToCb: keyToCb.current});


    const className = buildClassName(
        'tablet-view__panel',
        'selectPanelSimple',
        isActive && 'is-active',
        isDisabled && 'is-disabled'
    );


    return (
        <article className={className} data-value={id}
            ref={containerRef} onClick={isDisabled ? null : onClick} tabIndex={isDisabled ? -1 : 0}
        >
            <Text value={t(`events:trigger.${triggerType}`)} isBold isEllipsis
                isDisabled={isDisabled}/>

            <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap-reverse'}}>
                <div style={{display: 'flex'}}>
                    {(status.code === statusKeys.ERROR || status.code === statusKeys.WARNING) &&
                    <>
                    <span className="icon is-small" title={t(`common:status.${status.code}`)} style={{margin: 'auto 0.5rem auto 0'}}>
                        {translatedStatusIcons(status.code)}
                    </span>

                    <Text value={t(`common:status.${status.code}`)} isDisabled={isDisabled}/>
                    </>
                    }
                </div>

                <Text value={getLocaleDateTimeFromUTC(epoch)} isDisabled={isDisabled}/>
            </div>
        </article>
    )
}

export default WebhookEventView;
