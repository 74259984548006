import ReduxModel from "../generics/ReduxModel";
import {actionCreator, getValues} from "../../utilities/helperFunctions";
import AxiosProxy, {axiosInstance} from "../api/AxiosProxy";
import {all, put} from "redux-saga/effects";
import {contextCall, contextPollUntil} from "../../saga/sagaFunctions";
import {details, SLOW_QUERY_INTERVAL} from "../../utilities/constants";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";

class UserModel extends ReduxModel {

    static nom = 'UserModel';
    static actions = UserModel.buildActions();
    static actionCreators = UserModel.buildActionCreators(UserModel.actions);
    static reducer = UserModel.buildReducer(UserModel.actions);

    constructor(model = {}) {
        super();

        this.id = model.id;
        this.userServiceId = model.userServiceId;
        this.platform = model.platform;
        this.name = model.name;
        this.email = model.email;
        this.state = model.userAccountState;

        if (model?.attributes?.displayName) {
            this.displayName = model.attributes.displayName;
        }
    }

    static Platform = {
        AZURE_AD: 'AZURE_AD'
    };

    static useUserNameAndIconName(name) {
        const userDetailsMap = useSelector(state => state.userDetailsMap);
        const [usernameAndIcon, setUsernameAndIcon] = useState([name, name]);

        useEffect(() => {
            let nameAndIcon = [name, name];
            if (name) {
                const user = getValues(userDetailsMap).find(user => user.name === name || user.email === name);
                if (user != null) {
                    nameAndIcon = [user.displayName || name, user.name];
                }
            }
            setUsernameAndIcon(nameAndIcon);
        }, [name, userDetailsMap]);

        return usernameAndIcon;
    }

    static buildActions() {
        return {
            // USER ACTIONS
            SET_DETAILS_MAP: 'SET_USER_DETAILS_MAP',
            UPDATE_DETAILS: `BULK_UPDATE_USER_DETAILS`,
            QUERY_DETAILS: 'QUERY_DETAILS',
            // POLLING ACTIONS
            START_POLLING_DETAILS: 'START_POLLING_USER_DETAILS',
            STOP_POLLING_DETAILS: 'STOP_POLLING_USER_DETAILS'
        }
    }

    static buildActionCreators(actions) {
        return {
            // USER ACTION CREATORS
            setDetailsMap: actionCreator(actions.SET_DETAILS_MAP, 'details'),
            updateDetails: actionCreator(actions.UPDATE_DETAILS, 'idToUpdates'),
            queryDetails: actionCreator(actions.QUERY_DETAILS),
            // POLLING ACTION CREATORS
            startPollingDetails: actionCreator(actions.START_POLLING_DETAILS),
            stopPollingDetails: actionCreator(actions.STOP_POLLING_DETAILS)
        }
    }

    static buildReducer(actions) {
        return function (state = new Map(), action) {
            switch (action.type) {
                case actions.SET_DETAILS_MAP: {
                    const {details} = action.payload;

                    this.lastUpdated = Date.now();
                    return this.setDetailsMapGeneric(state, details, 'id');
                }
                case actions.UPDATE_DETAILS: {
                    const {idToUpdates} = action.payload;

                    this.lastUpdated = Date.now();
                    return this.bulkUpdateDetails(state, idToUpdates);
                }
                default: {
                    return state;
                }
            }
        }.bind(this);
    }
}

export class UserApi {

    static getUserModels(disabled=false) {
        return axiosInstance.get(`/scheduler/users?disabled=${disabled}`);
    }
}

export class UserSaga {

    static* pollDetails() {
        yield contextPollUntil(UserModel.actions.STOP_POLLING_DETAILS, SLOW_QUERY_INTERVAL, this, 'queryDetails');
    }

    static* queryDetails() {
        const response = yield contextCall(UserApi, 'getUserModels');

        const key = details.USERS;
        if (AxiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(UserModel.actionCreators.setDetailsMap(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }
}

export default UserModel;
