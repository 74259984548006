import React from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {updateGenericValues} from "../../../redux/WizardSlice/wizardSlice";
import {createNestedToggleHandler} from "../../Question/QuestionCheckBox/helpers";
import Checkbox from "../../../../components/common/Checkbox/Checkbox";

function ExportOptions(props) {
    const {updateState} = props;
    const {t} = useTranslation(['wizard']);

    const {exporting} = props;

    const questions = [
        {
            question: t('wizard:questions.optional.performOptionalExport'),
            dataName: "optionalExports",
            options: [
                {id: "exportToDiscover", value: t('wizard:questions.optional.exportOptions.discoverExport'), checked: exporting.exportToDiscover},
                {id: "exportToRelativity", value: t('wizard:questions.optional.exportOptions.relativityExport'), checked: exporting.exportToRelativity},
                {id: "legalExport", value: t('wizard:questions.optional.exportOptions.legalExport'), checked: exporting.legalExport},
                {id: "customExportNumbering", value: t('wizard:questions.optional.exportOptions.customNumbering'), checked: (exporting.customExportNumbering && exporting.legalExport), dependant: exporting.legalExport},
                // {id: "exportItems", value: t('wizard:questions.optional.exportOptions.exportItems'), checked: exporting.exportItems},
            ]
        }
    ]

    const toggleEnable = createNestedToggleHandler({
        handler: updateState
    });

    const update = (event) => {
        const {name, checked} = event.target;

        if (name === "exportToRelativity" && checked) {
            toggleEnable({target: {name: "legalExport", checked: true, nested: "exporting"}})
        }

        if (name === "legalExport") {
            if (!checked && exporting.exportToRelativity) {
                return;
            }
            toggleEnable({target: {name: "customExportNumbering", checked: false, nested: "exporting"}});
        }

        return toggleEnable({target: {name: name, checked: checked, nested: "exporting"}});
    }

    const option = questions[0].options;

    return (
        <div className={"wizard-step-container"}>
            <div className={"display-item"}>
                {questions[0].question.length > 0 &&
                <label className="label is-heading">
                    {questions[0].question}
                </label>
                }
                <div className={'settings-table no-border tight-padding'} style={{border: '1px solid transparent'}}>
                    <div className="table-row-group">
                        <div className="table-row no-hover">
                            <div className="table-cell no-stretch">
                                <Checkbox name={option[0].id} label={option[0].value} checked={option[0].checked} onClick={update} isWordWrap={false}/>
                            </div>
                        </div>
                        <div className="table-row no-hover">
                            <div className="table-cell no-stretch">
                                <Checkbox name={option[1].id} label={option[1].value} checked={option[1].checked} onClick={update} isWordWrap={false}/>
                            </div>
                        </div>
                        <div className="table-row no-hover">
                            <div className="table-cell no-stretch">
                                <Checkbox name={option[2].id} label={option[2].value} checked={option[2].checked} onClick={update} isWordWrap={false}/>
                            </div>
                        </div>
                        {option[2].checked &&
                            <div className="table-row no-hover">
                                <div className="table-cell no-stretch" style={{paddingLeft: '1.75rem'}}>
                                    <Checkbox name={option[3].id} label={option[3].value} checked={option[3].checked}
                                              onClick={update} isWordWrap={false}/>
                                </div>
                            </div>
                        }
                        {/*<div className="table-row no-hover">*/}
                        {/*    <div className="table-cell no-stretch">*/}
                        {/*        <Checkbox name={option[4].id} label={option[4].value} checked={option[4].checked} onClick={update} isWordWrap={false}/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps (state) {
    const {wizardSlice:{optionalSteps: {exporting}}} = state;
    return {
        exporting
    };
}

function mapDispatchToProps (dispatch) {
    return {
        updateState: updates => dispatch(updateGenericValues({stateName: "optionalSteps", updates: updates}))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportOptions);