import React from "react";
import "./WorkTablet.css";
import {useDispatch, useSelector} from "react-redux";
import {IconSubtitle, ObjectIdSubtitle} from "../common/Tablet/Tablet";
import {useTranslation} from "react-i18next";
import {Button} from "../common/Button/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserNoticeDisplay from "../notice/UserNoticeDisplay";
import {legalHoldStateKeys, permissionKeys} from "../../i18next/keys";
import {switchcaseF} from "../../utilities/helperFunctions";
import {MenuDropdown} from "../common/Dropdown/Dropdown";
import UserNoticeModel from "../../models/notice/UserNoticeModel";
import {noticeTypeKeys} from "../../utilities/constants";
import FocusTrap from "focus-trap-react";


function UserNoticeTablet(props) {

    const {
        legalHoldId,
        userNoticeId,
        onClose
    } = props;

    const userNotice = useSelector(state => state.userNoticeDetailsMap.get(userNoticeId)) || {};
    const legalHold = useSelector(state => state.legalHoldDetailsMap.get(legalHoldId || userNotice.legalHoldId)) || {};

    const {isDisabled: isUserNoticeViewDisabled, isUploadActive} = useSelector(state => state.componentStates.userNoticeView);
    const isTabletDisabled = props.isDisabled || isUploadActive;
    const isDisabled = props.isDisabled || isUserNoticeViewDisabled;


    return (
        <div id="tablet" className="work-modal is-active">
            <div className="work-modal__background" onClick={isTabletDisabled ? null : onClose}/>

            <FocusTrap
                focusTrapOptions={{initialFocus: false, allowOutsideClick: true, escapeDeactivates: onClose, fallbackFocus: document.activeElement}}
            >
                <div className="work-modal__content">

                    <UserNoticeHeader userNotice={userNotice} onClose={onClose}
                        isTabletDisabled={isTabletDisabled} isDisabled={isDisabled}/>

                    <div id="tabletBody" className="work-tablet__body">
                        <UserNoticeDisplay userNotice={userNotice} legalHoldName={legalHold.name}
                            isDisabled={isDisabled}/>
                    </div>
                </div>
            </FocusTrap>
        </div>
    )
}

export function UserNoticeHeader(props) {
    const {t} = useTranslation(['notice', 'aria']);
    const dispatch = useDispatch();

    const {
        userNotice,
        onClose,
        inlineIcon,
        isTabletDisabled,
        isDisabled
    } = props;

    const {
        id,
        noticeId,
        legalHoldId,
        type,
        subject,
        lastRespondedDate,

        userPermissions
    } = userNotice;

    const {state} = useSelector(state => state.legalHoldDetailsMap.get(legalHoldId)) || {};
    const canManage = userPermissions.includes(permissionKeys.MANAGE);
    const canResetSubmission = ((type === noticeTypeKeys.HOLD || type === noticeTypeKeys.SURVEY) && state === legalHoldStateKeys.ACTIVE)
        || (type === noticeTypeKeys.RELEASE && state === legalHoldStateKeys.RELEASED);
    const showMenu = canManage && canResetSubmission;

    function menuOptionHandler(event) {
        const {value} = event.currentTarget.dataset

        switchcaseF({
            'resetSubmission': () => dispatch(UserNoticeModel.actionCreators.resetSubmission(noticeId, id))
        })()(value);
    }

    const menuOptions = [{
        name: t('notice:option.resetSubmission'),
        value: 'resetSubmission',
        isDisabled: !canResetSubmission || lastRespondedDate == null
    }];

    return (
        <div id="tabletHeader" className="work-tablet__header">
            <div className="tablet-header-left">
                <ObjectIdSubtitle id={id} subtitle={subject}
                    isDisabled={isDisabled}/>

                {showMenu &&
                <div className="display-menu">
                    <MenuDropdown id={'userNoticeMenuDropdown'} aria-label={t('aria:hiddenAssistText.userNoticeMenu')}
                        menuOptions={menuOptions} onOptionClick={menuOptionHandler} isDisabled={isDisabled}
                    />
                </div>
                }
            </div>

            <IconSubtitle id="userNoticeHeaderIconSubtitle" type={`noticeType${type}`} subtitle={t(`notice:type.${type}`)}
                inlineIcon={inlineIcon} isDisabled={isDisabled}/>

            {onClose &&
            <Button id="tabletCloseButton" className="work-tablet__close-button" aria-label={t('aria:hiddenAssistText.closeUserNotice', {name: subject})}
                onClick={isTabletDisabled ? null : onClose} isImg isClear isDisabled={isTabletDisabled}
            >
                <FontAwesomeIcon icon="times"/>
            </Button>
            }
        </div>
    )
}

export default UserNoticeTablet;
