import React from "react";
import "../userComments/UserComment.css";
import NoticeCommentForm from "./NoticeCommentForm";
import UserCommentBody, {UserComment} from "../userComments/UserCommentBody";


function NoticeCommentDisplay(props) {

    const {
        userNoticeId,
        noticeId,

        comments,
        canComment,
        canAddAdminNotes,
        showNext,

        isReadOnly,
        isTemplate,
        isDisabled
    } = props;


    return (
        <div className="user-comment__display">
            {comments.map(comment => isTemplate ?
                <UserCommentBody key={comment.id} {...comment} username={comment.userId}
                    isReadOnly={isReadOnly}/>
                :
                <UserComment key={comment.id} {...comment}
                    isReadOnly={isReadOnly} isDisabled={isDisabled}/>
            )}

            {!isReadOnly && (canComment || canAddAdminNotes) &&
            <NoticeCommentForm userNoticeId={userNoticeId} noticeId={noticeId}
                canComment={canComment} canAddAdminNotes={canAddAdminNotes} showNext={showNext} isDisabled={isDisabled}/>
            }
        </div>
    )
}

export default NoticeCommentDisplay;
