import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getValues, switchcaseF} from "../../utilities/helperFunctions";
import EditModel from "../../models/scheduler/EditModel";
import FileLibraryModel from "../../models/filelibrary/FileLibraryModel";
import Tablet, {EditTabletHeader} from "../common/Tablet/Tablet";
import {createEditHandler, createInputHandler} from "../../utilities/componentFunctions";
import {shouldEnableFileLibraryResource} from "../../utilities/shouldEnableFunctions";
import {DefaultEditPanel} from "../common/EditPanel/EditPanel";
import LibraryFileView from "./LibraryFileView";
import {makeGetEditDetails} from "../../reselect/selectors";
import LibraryFileModel from "../../models/filelibrary/LibraryFileModel";
import {ExpandableEditTextArea} from "../common/ExpandableContent/ExpandableContent";

const selectEditDetails = makeGetEditDetails();
function selectFileLibraryDetails (state, fileLibraryId) {

    const {activeModel, values, isSaveEnabled} = selectEditDetails(state, {model: FileLibraryModel.nom});
    const isEditActive = (activeModel === FileLibraryModel.nom);
    const isLibraryFileEditActive = state.editDetails.activeModel === LibraryFileModel.nom;

    const {name: fileLibraryName, ...otherDetails} = state.fileLibraryDetailsMap.get(fileLibraryId);

    return {
        fileLibraryName,
        ...otherDetails,
        ...values,
        isEditActive,
        isSaveEnabled,
        isLibraryFileEditActive
    }
}

export function selectFileLibraryHasFiles (state, libraryFileId) {
    return getValues(state.libraryFileDetailsMap)
        .filter(libraryFile => libraryFile.libraryFileId === libraryFileId)
        .length > 0;
}

function FileLibraryTablet (props) {
    const {t} = useTranslation(['aria', 'fileLibrary', 'common'])
    const dispatch = useDispatch();

    const {canModify, fileLibraryId} = props;
    const {
        fileLibraryName,
        description,
        isEditActive,
        isSaveEnabled,
        isLibraryFileEditActive
    } = useSelector(state => selectFileLibraryDetails(state, fileLibraryId));

    const hasLibraryFiles = useSelector(state => selectFileLibraryHasFiles(state, fileLibraryId))
    const {
        isDisabled
    } = useSelector(state => state.componentStates.fileLibraryTablet);

    const isLoading = useSelector(state => !state.hasLoaded[fileLibraryId]);

    useEffect(() => {
        dispatch(FileLibraryModel.actionCreators.startPollingSettings(fileLibraryId));

        return () => dispatch(FileLibraryModel.actionCreators.stopPollingSettings())
    }, [fileLibraryId, dispatch])

    const {saveEdit, cancelEdit, updateEdit, setEditSaveEnabled} = EditModel.buildDispatchers(dispatch)
    const editHandler = createEditHandler({
        updateEdit,
        setEditSaveEnabled,
        shouldEnable: shouldEnableFileLibraryResource,
        values: {fileLibraryName, description}
    })

    const inputHandler = createInputHandler({
        handler: editHandler
    })


    function menuOptionHandler(event) {
        const {value} = event.currentTarget.dataset

        switchcaseF({
            'edit': () => dispatch(FileLibraryModel.actionCreators.startEdit(fileLibraryId)),
            'promptDelete': () => dispatch(FileLibraryModel.actionCreators.promptDelete(fileLibraryId)),
        })()(value)
    }

    const menuOptions = [
        {name: t('common:option.edit'), value: 'edit'},
        {name: t('common:option.delete'), value: 'promptDelete'},
    ]

    const isFileLibraryDisabled = isDisabled || isLibraryFileEditActive;
    const isTabletDisabled = isDisabled;

    const showDescription = isEditActive || description;
    const showLibraryFiles = hasLibraryFiles || canModify;

    const height = isEditActive ? 'auto' : '65vh';

    return (
        <Tablet width={'100rem'} height={height} onClose={() => dispatch(FileLibraryModel.actionCreators.hideTablet())}
            isDisabled={isTabletDisabled} bodyStyle={{padding: 0}}
            closeButtonAriaLabel={t('fileLibrary:option.closeTablet_name', {name: fileLibraryName})}
                header={
                    <EditTabletHeader label={t('fileLibrary:label.name')} type={'fileLibrary'}
                                      id={fileLibraryId} value={fileLibraryName} name={'fileLibraryName'} inputHandler={inputHandler}
                                      menuOptions={menuOptions} menuOptionHandler={menuOptionHandler}
                                      canModify={canModify} isEditActive={isEditActive} isDisabled={isFileLibraryDisabled}
                    />
                }

                body={
                    <>
                        <DefaultEditPanel isActive={isEditActive} isSaveEnabled={isSaveEnabled} style={(isEditActive || !(showDescription)) ? {} : {marginBottom: '-2rem'}}
                                          onSave={saveEdit} onCancel={cancelEdit}>
                            {showDescription &&
                            <div className="display-item">
                                <ExpandableEditTextArea label={t('common:label.description')} name={'description'}
                                                        value={description} onChange={inputHandler} isEditActive={isEditActive}
                                                        isDisabled={isFileLibraryDisabled}
                                />
                            </div>
                            }
                        </DefaultEditPanel>

                        {!isEditActive && showLibraryFiles &&
                            <LibraryFileView fileLibraryId={fileLibraryId} canModify={canModify} isDisabled={isDisabled}/>
                        }
                    </>
                }
        />
    )
}

export default FileLibraryTablet;