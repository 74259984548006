import ObjectCache, {ObjectCacheSaga} from "./ObjectCache";

class ServiceObjectCache extends ObjectCache {

    static nom = 'ServiceObjectCache';
    static actions = ServiceObjectCache.buildActions('SERVICE_OBJECT_CACHE');
    static actionCreators = ServiceObjectCache.buildActionCreators(ServiceObjectCache.actions);
    static reducer = ServiceObjectCache.buildReducer(ServiceObjectCache.actions);

    static Resource = {
        EMAIL_COUNTER: 'EMAIL_COUNTER'
    };
}

export class ServiceObjectCacheSaga extends ObjectCacheSaga {
    static CacheModel = ServiceObjectCache;
}

export default ServiceObjectCache;
