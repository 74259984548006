import React from 'react';
import './Tables.css';
import {getStatusIconImgContent} from '../../../utilities/iconResolver';
import {isNotEmptyNorFalsy} from '../../../utilities/helperFunctions';
import {genOpKey} from '../../../utilities/keyGenerators';
import HighlightText from "../../userSettings/HighlightOption/HighlightText";
import {executionStateKeys} from "../../../i18next/keys";

export function LinksAlertTable(props) {
    const {id, links} = props;

    return (
        <table>
            <tbody>
            {WorkflowLinkRows({id, links: links})}
            </tbody>
        </table>
    );
}

export function InfoAlertTable(props) {
    const {id, infos} = props;

    return (
        <table>
            <tbody>
                {WorkflowAlertRows({id, alerts: infos})}
            </tbody>
        </table>
    );
}

export function WarningAlertTable(props) {
    const { id, warnings, operations } = props;

    return (
        <table>
            <tbody>
                {WorkflowAlertRow({alerts: warnings})}
                {operations && operations.map(operation => operation.warnings &&
                    operation.warnings.map((warning, index) => OperationAlertRow({id, index, name: operation.name, alerts: warning}))
                )}
            </tbody>
        </table>
    );
}

export function SoftErrorAlertTable(props) {
    const { id, softErrors, operations } = props;

    return (
        <table>
            <tbody>
            {WorkflowAlertRow({alerts: softErrors})}
            {operations && operations
                .filter(op => op.executionState === executionStateKeys.SOFT_ERROR)
                .map((operation, index) => OperationAlertRow({id, index, name: operation.name, alerts: operation.error}))}
            </tbody>
        </table>
    );
}

export function ErrorAlertTable(props) {
    const { id, errors, operations } = props;

    return (
        <table>
            <tbody>
                {WorkflowAlertRow({alerts: errors})}
                {operations && operations
                    .filter(op => op.executionState === executionStateKeys.ERROR)
                    .map((operation, index) => OperationAlertRow({id, index, name: operation.name, alerts: operation.error}))}
            </tbody>
        </table>
    );
}

function WorkflowAlertRow(props) {
    const { alerts } = props;

    return ( isNotEmptyNorFalsy(alerts) &&
        <tr>
            <td colSpan="2">
              <label className="label is-wordwrap">
                <HighlightText text={alerts}/>
              </label>
            </td>
        </tr>    
    );
}

function WorkflowLinkRows(props) {
    const {id, links} = props;

    return ( isNotEmptyNorFalsy(links) &&
        links.map((link, index) =>
            <tr key={`${id}${index}`}>
                <td colSpan="2">
                    <label className="label is-wordwrap">{link.prefix + (link.prefix.length>0?" ":"")}
                        <a href={link.linkUrl} target="_blank" rel="noopener noreferrer">{link.linkName}</a>
                        {" "+link.suffix}
                    </label>
                </td>
            </tr>
        )
    );
}

function WorkflowAlertRows(props) {
    const {id, alerts} = props;

    return ( isNotEmptyNorFalsy(alerts) &&
        alerts.map((alert, index) =>
            <tr key={`${id}${index}`}>
                <td colSpan="2"><label className="label is-wordwrap">{alert}</label></td>
            </tr>
        )
    );
}


function OperationAlertRow(props) {
    const { id, index, name, alerts } = props;

    return ( isNotEmptyNorFalsy(alerts) &&
        <tr className="operation-alert" key={genOpKey(id, index)}>
            <td style={{width: '12.5rem'}}>
              <label className="label">
                <HighlightText text={`${name}:`}/>
              </label>
            </td>
            <td>
              <label className="label is-wordwrap">
                <HighlightText text={alerts}/>
              </label>
            </td>
        </tr>
    );
}

export function AlertHeader(props) {
    const { label, iconName } = props;

    return (
        <div className="alert-header">
            <span className="icon is-small spacing-right">
                {getStatusIconImgContent(iconName)}
            </span>
            <label className="label is-heading">
                {label}
            </label>
        </div>
    );
}
