import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {createCloseHandler, createInputHandler, createStateHandler} from "../../utilities/componentFunctions";
import {shouldEnableWebhook} from "../../utilities/shouldEnableFunctions";
import Form, {FormFooter, FormHeader} from "../common/Form/Form";
import HTMLTextInput from "../common/HTMLTextInput/HTMLTextInput";
import PopupModel from "../../models/scheduler/PopupModel";
import WebhookModel from "../../models/settings/WebhookModel";
import {ValueList} from "../common/InputList/InputList";
import Checkbox from "../common/Checkbox/Checkbox";
import {createToggleHandler} from "../common/Checkbox/helpers";
import {SwitchItemContainer} from "../common/ListContainer/ListContainer";
import {WebhookEventTriggerTypeDropdown} from "../common/Dropdown/Dropdown";
import Text from "../common/Text/Text";
import {createDropdownHandler} from "../common/Dropdown/helpers";


function WebhookForm() {
    const {t} = useTranslation(['webhook', 'common']);
    const dispatch = useDispatch();

    const {
        isDisabled
    } = useSelector(state => state.componentStates.webhookForm);

    const [state, setState] = useState({
        webhookName: '',
        active: true,
        callbackAddress: '',
        historyEnabled: true,
        eventTriggerType: ''
    });

    // left -> available, right -> selected
    const [triggers, setTriggers] = useState({
        left: WebhookModel.getEventTriggerItems(),
        right: []
    });
    const [whitelistedCertFingerprints, setWhitelistedCertFingerprints] = useState(['']);

    const stateHandler = createStateHandler({
        updateState: setState
    });

    const inputHandler = createInputHandler({
        handler: stateHandler
    });

    const toggleHandler = createToggleHandler({
        handler: stateHandler
    });

    const dropdownHandler = createDropdownHandler({
        handler: stateHandler
    });

    const onClose = createCloseHandler({
        t,
        item: t('webhook:label.name'),
        values: {...state, triggers: triggers.right, whitelistedCertFingerprints},
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        close: () => dispatch(WebhookModel.componentActionCreators.updateDisplay({isWebhookFormActive: false}))
    });

    function onSubmit() {
        dispatch(WebhookModel.actionCreators.submitForm({
            ...state,
            triggers,
            whitelistedCertFingerprints
        }));
    }


    const {
        webhookName,
        active,
        callbackAddress,
        historyEnabled,
        eventTriggerType
    } = state;

    const isAddEnabled = shouldEnableWebhook({...state, triggers})

    return (
        <>
            <Form onClose={onClose} isDisabled={isDisabled} closeButtonAriaLabel={t('webhook:option.closeForm')}
                header={
                    <FormHeader text={t('webhook:label.name')} iconName={'webhook'} isDisabled={isDisabled}/>
                }
                body={
                    <>
                        <div className="display-input">
                            <HTMLTextInput label={t('common:label.name')} name={'webhookName'} value={webhookName}
                                onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                        </div>

                        <div className="inline-checkboxes">
                            <Checkbox label={t('common:label.enabled')} name={'active'} checked={active} onClick={toggleHandler}
                                isDisabled={isDisabled}/>

                            <Checkbox label={t('webhook:label.historyEnabled')} name={'historyEnabled'} checked={historyEnabled} onClick={toggleHandler}
                                isDisabled={isDisabled}/>
                        </div>

                        <div className="display-input">
                            <HTMLTextInput label={t('webhook:label.callbackAddress')} name={'callbackAddress'} value={callbackAddress}
                                           onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                        </div>

                        <div className="display-input">
                            <Text value={t('webhook:label.triggers')} style={{marginBottom: '0.25rem'}}
                                isDisabled={isDisabled}/>

                            <WebhookEventTriggerTypeDropdown selectedEventTriggerType={eventTriggerType} onEventTriggerTypeSelect={dropdownHandler}
                                isDisabled={isDisabled} isRequired/>

                            <SwitchItemContainer id="webhookEventTriggerSwitchContainer" style={{marginTop: '0.125rem', height: '15rem'}}
                                items={triggers} setItems={setTriggers} filter={{left: {type: eventTriggerType}}}
                                ariaLabelKey={'EventTrigger'} isDisabled={isDisabled} isRequired/>
                        </div>

                        <div className="display-input">
                            <ValueList id={'whitelistedCertFingerprints'} label={t('common:label.whitelistedCertFingerprints')}
                                ariaLabelKey={'WhitelistedCertFingerprint'} values={whitelistedCertFingerprints}
                                setValues={setWhitelistedCertFingerprints} isDisabled={isDisabled}/>
                        </div>
                    </>
                }
                footer={
                  <FormFooter addText={t('common:button.addWebhook')} onAddClick={onSubmit} onCancel={onClose}
                      isAddEnabled={isAddEnabled} isDisabled={isDisabled}
                  />
                }
            />
        </>
    )
}

export default WebhookForm;
