import React from 'react';
import {ListDropdown} from "../../../../components/common/Dropdown/Dropdown";
import {useTranslation} from "react-i18next";
import {nameLocaleCompare} from "../../../../utilities/helperFunctions";
import {WizardStatusLabel} from "../../Text/WizardLabel/WizardStatusLabel";

export function fileTypeFilter (file, type) {
    return file.type === type;
}

export function fileExtensionFilter (libraryFile, ext) {
    return libraryFile.name.matches(`.*\\.${ext}`);
}

function WizardFileDropdown(props) {
    const {id, name, value, onFileSelected, selectedFiles, files, filter, noFileWarning, isRequired, isDisabled, ...rest} = props;
    const {t} = useTranslation(['aria', 'wizard']);

    let sortedLibraryFiles = files.sort(nameLocaleCompare);
    if (typeof filter === 'function') {
        sortedLibraryFiles = sortedLibraryFiles.filter(filter);
    }

    const hasFileWarning = sortedLibraryFiles.length === 0;

    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <ListDropdown id={id} name={name} aria-label={t('aria:hiddenAssistText.fileDropdown')}
                          value={value} items={sortedLibraryFiles} onItemSelect={onFileSelected}
                          buttonStyle={{width: '20rem', justifyContent: 'flex-start'}}
                          isRequired={isRequired} isDisabled={isDisabled} {...rest}
            />

            {(hasFileWarning && !isDisabled) &&
                <label className="label is-small">
                    <WizardStatusLabel message={noFileWarning}/>
                </label>
            }
        </div>
    )
}

export default WizardFileDropdown;