import React from "react";
import {useTranslation} from "react-i18next";
import HighlightText from "../userSettings/HighlightOption/HighlightText";

export function ExecutionParametersTable(props) {
    const {executionParameters, isDisabled} = props;
    const {t} = useTranslation(['common']);

    const disabled = isDisabled ? ' is-disabled' : '';
    return (
        <div className={'display-table' + disabled} style={{marginTop: '0.5rem'}}>
            <div className="table-header-group">

                <div className="table-header" style={{width: '13rem'}}>
                    <label className="label is-bold">
                        {t('common:label.friendlyName')}
                    </label>
                </div>

                <div className="table-header" style={{width: '13rem'}}>
                    <label className="label is-bold">
                        {t('common:label.name')}
                    </label>
                </div>

                <div className="table-header">
                    <label className="label is-bold">
                        {t('common:label.type')}
                    </label>
                </div>

                <div className="table-header" style={{width: '50%'}}>
                    <label className="label is-bold">
                        {t('common:label.value')}
                    </label>
                </div>
            </div>

            <div className="table-row-group">
                {executionParameters &&
                executionParameters.map((param, index) =>
                    <ExecutionParametersRow key={`EXECUTION_PARAMETERS${index}_${param.name}`} {...param}/>
                )}
            </div>
        </div>
    )
}

function ExecutionParametersRow(props) {
    const {name, type, friendlyName, value} = props;
    const {t} = useTranslation(['workflowBuilder']);

    return (
        <div className="table-row no-pointer">

            <div className="table-cell">
                <label className="label is-wordwrap">
                    <HighlightText text={friendlyName}/>
                </label>
            </div>

            <div className="table-cell">
                <label className="label is-wordwrap">
                    <HighlightText text={name}/>
                </label>
            </div>

            <div className="table-cell">
                <label className="label is-wordwrap">
                    <HighlightText text={t(`workflowBuilder:ParameterType.${type}`)}/>
                </label>
            </div>

            <div className="table-cell">
                <label className="label is-wordwrap break-all">
                    <HighlightText text={value}/>
                </label>
            </div>
        </div>
    );
}
