import React, {useCallback, useEffect, useState} from "react";
import ObjectListField from "./ObjectListField";
import ParameterFieldForm from "./ParameterFieldForm";
import {useTranslation} from "react-i18next";
import {Button} from "../../common/Button/Button";
import Text from "../../common/Text/Text";
import FormBuilderConfiguration from "../../../models/workflowbuilder/FormBuilderConfiguration";
import ParameterModel from "../../../models/library/ParameterModel";
import {useSelector} from "react-redux";
import WorkflowBuilderOperation from "../../../models/workflowbuilder/WorkflowBuilderOperation";
import {deepCopy, isNotEmptyNorFalsy} from "../../../utilities/helperFunctions";
import ExpandableContent from "../../common/ExpandableContent/ExpandableContent";
import {icon} from "../../../utilities/iconResolver";

function selectPrecedingParameters(workflowBuilder) {
    const precedingOperations = workflowBuilder.operations.slice(0, workflowBuilder.selectedOperationIndex);
    const currentOperation = workflowBuilder.operations[workflowBuilder.selectedOperationIndex];

    const parameters = precedingOperations
        .filter(operation => operation.operationAlias === WorkflowBuilderOperation.Alias.CONFIGURE_PARAMETERS)
        .flatMap(operation => [...operation.staticParameters, ...operation.userParameters]);

    parameters.push(...currentOperation.staticParameters);
    return parameters.filter(param => param.name);
}

function useParametersConfiguration(configuration) {
    const [parametersConfiguration, setParametersConfiguration] = useState();

    useEffect(() => {
        const parametersConfiguration = configuration.duplicate();
        const formConfiguration = FormBuilderConfiguration.buildFormConfiguration(parametersConfiguration.formConfiguration);
        parametersConfiguration.formConfiguration = formConfiguration;

        formConfiguration.template.allowedValuesMatchBy = ParameterModel.AllowedValuesMatchBy.SYSTEM_VALUE;

        setParametersConfiguration(parametersConfiguration);
    }, [configuration]);

    return parametersConfiguration;
}

function ParameterListField(props) {
    const {t} = useTranslation(['workflowBuilder']);
    const {
        id,
        formKey,
        configuration,

        'data-type': dataType,
        label,
        value,
        updateState,

        isRequired,
        isInvalid,
        isDisabled
    } = props;

    const precedingParameters = useSelector(state => selectPrecedingParameters(state.workflowBuilder));
    const parametersConfiguration = useParametersConfiguration(configuration);

    // Set _invalid for invalid conditions (due to condition dependant parameter being updated/moved/deleted)
    useEffect(() => {
        const fieldConfigurations = configuration.formConfiguration?.fieldConfigurations;
        if (fieldConfigurations == null) {
            return;
        }
        if (fieldConfigurations.get('displayCondition') == null && fieldConfigurations.get('relativityCondition') == null && fieldConfigurations.get('dependantParameters') == null) {
            return;
        }

        updateState(prevState => {
            const prevParameters = prevState[configuration.name];
            const updatedParams = [...prevParameters];

            for (let i = 0; i < prevParameters.length; i++) {
                const parameter = prevParameters[i];

                // displayCondition parameter must exist in precedingParameters
                if (parameter.displayCondition?.parameter) {
                    const displayConditionParameter = precedingParameters.find(param => param.name === parameter.displayCondition.parameter);
                    if (displayConditionParameter == null) {
                        if (!parameter.displayCondition._invalid) {
                            updatedParams[i] = deepCopy(parameter);
                            updatedParams[i].displayCondition._invalid = true;
                        }
                    } else if (parameter.displayCondition._invalid) {
                        updatedParams[i] = deepCopy(parameter);
                        delete updatedParams[i].displayCondition._invalid;
                    }
                }
                // relativityCondition parameter must exist in precedingParameters + belong to required filterType
                if (parameter.relativityCondition?.filterParameter || (parameter.parameterType === ParameterModel.Type.RELATIVITY_FOLDER || parameter.parameterType === ParameterModel.Type.RELATIVITY_SAVED_SEARCH)) {
                    const filterTypeAllowedValues = ParameterModel.getFilterTypesForRelativityParameterType(parameter.parameterType);
                    const relativityConditionParameter = precedingParameters.find(param =>
                        param.name === parameter.relativityCondition.filterParameter && filterTypeAllowedValues.includes(param.parameterType)
                    );

                    if (relativityConditionParameter == null) {
                        if (!parameter.relativityCondition._invalid) {
                            updatedParams[i] = deepCopy(parameter);
                            updatedParams[i].relativityCondition._invalid = true;
                        }
                    } else if (parameter.relativityCondition._invalid) {
                        updatedParams[i] = deepCopy(parameter);
                        delete updatedParams[i].relativityCondition._invalid;
                    }
                }
                // dependantParameters must exist in precedingParameters
                if (parameter.parameterType === ParameterModel.Type.SCRIPTED && isNotEmptyNorFalsy(parameter.dependantParameters)) {
                    const invalid = parameter.dependantParameters.some(dependant => precedingParameters.find(param => param.name === dependant) == null);
                    if (invalid) {
                        if (!parameter.dependantParameters._invalid) {
                            updatedParams[i] = deepCopy(parameter);
                            updatedParams[i].dependantParameters._invalid = true;
                        }
                    } else if (parameter.dependantParameters._invalid) {
                        updatedParams[i] = deepCopy(parameter);
                        delete updatedParams[i].dependantParameters._invalid;
                    }
                }

                precedingParameters.push(parameter);
            }

            // Only return updatedState if a param was updated; else return prevState
            for (let i = 0; i < prevParameters.length; i++) {
                if (updatedParams[i] !== prevParameters[i]) {
                    return {[configuration.name]: updatedParams};
                }
            }
            return prevState;
        });
    }, [value]);


    if (parametersConfiguration == null) {
        return null;
    }

    const tableHeaders = ['name', 'type', 'value', 'friendlyName', 'description', 'displayCondition', 'filter']
        .map(header => FormBuilderConfiguration.getLabelTranslation(t, formKey, header));

    return (
        <ExpandableContent label={label} isDisabled={isDisabled}>
            <ObjectListField id={id} data-type={dataType} FormComponent={ParameterFieldForm} RowComponent={ParameterFieldRow}
                             tableHeaders={tableHeaders} configuration={parametersConfiguration} formKey={formKey} value={value}
                             updateState={updateState} isRequired={isRequired} isInvalid={isInvalid} isDisabled={isDisabled} isExpandable/>
        </ExpandableContent>
    )
}

export function ParameterFieldRow(props) {
    const {t} = useTranslation(['aria']);
    const {
        className,
        index,

        formKey,
        configuration,
        object,
        selectHandler,
        setActiveIndex,

        isDisabled
    } = props;

    const componentConfigurations = configuration.formConfiguration.componentConfigurations;
    const getValueTranslation = useCallback((config, value) => {
        return config.getValueTextArray(t, formKey, value);
    }, []);


    let displayConditionLabel;
    const displayCondition = object.displayCondition || {};
    if (displayCondition.condition && displayCondition.parameter && displayCondition.value) {
        displayConditionLabel = t(`workflowBuilder:${formKey}.displayConditionValue`, {
            condition: t(`workflowBuilder:DisplayConditionType.${displayCondition.condition}`),
            parameterName: displayCondition.parameter,
            parameterValue: displayCondition.value
        });
    }

    let relativityConditionLabel;
    const relativityCondition = object.relativityCondition || {};
    if (relativityCondition.filterType && relativityCondition.filterParameter) {
        relativityConditionLabel = t(`workflowBuilder:${formKey}.relativityConditionValue`, {
            parameterType: t(`workflowBuilder:ParameterType.${relativityCondition.filterType}`),
            parameterName: relativityCondition.filterParameter
        });
    }

    const componentConfigurationMap = {};
    componentConfigurations.forEach(componentConfiguration => {
        componentConfigurationMap[componentConfiguration.name] = componentConfiguration;
    });

    return (
        <div className={className} data-index={index} onClick={selectHandler}>
            <div className="table-cell">
                <Button aria-label={t(`aria:hiddenAssistText.edit${configuration.name}`)} isImg isClear
                    onClick={() => setActiveIndex(index)} isDisabled={isDisabled}
                    icon={<img src={icon("edit")} alt={t('aria:hiddenAssistText.editIcon')}/>}/>
            </div>

            <ConfigurationTableLabelCell object={object} configuration={componentConfigurationMap["name"]}
                getValueTranslation={getValueTranslation}/>

            <ConfigurationTableLabelCell object={object} configuration={componentConfigurationMap["parameterType"]}
                getValueTranslation={getValueTranslation}/>

            <ConfigurationTableLabelCell object={object} configuration={componentConfigurationMap["value"]}
                getValueTranslation={getValueTranslation}/>

            <ConfigurationTableLabelCell object={object} configuration={componentConfigurationMap["friendlyName"]}
                getValueTranslation={getValueTranslation}/>

            <ConfigurationTableLabelCell object={object} configuration={componentConfigurationMap["description"]}
                getValueTranslation={getValueTranslation}/>

            <div className="table-cell">
                {displayConditionLabel &&
                    <Text value={displayConditionLabel}/>
                }
            </div>

            <div className="table-cell">
                <table>
                    <tbody>
                    {!relativityConditionLabel ? null :
                    <tr>
                        <td colSpan={2}>
                            <Text value={relativityConditionLabel}/>
                        </td>
                    </tr>
                    }
                    {!object.regex ? null :
                        <tr>
                            <td style={{paddingRight: '0.5rem', verticalAlign: 'top'}}>
                                <Text value={`${t(`workflowBuilder:${formKey}.regex`)}:`}/>
                            </td>
                            <td>
                                <Text isWordWrap isBreakWord
                                    value={object.regex}/>
                            </td>
                        </tr>
                    }
                    {!(Array.isArray(object.allowedValues) && object.allowedValues.length > 0) ? null :
                        <tr>
                            <td style={{paddingRight: '0.5rem', verticalAlign: 'top'}}>
                                <Text value={`${t(`workflowBuilder:${formKey}.values`)}:`}/>
                            </td>
                            <td>
                                <Text isWordWrap isBreakWord
                                    value={getValueTranslation(componentConfigurations[6], object.allowedValues)}/>
                            </td>
                        </tr>
                    }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

function ConfigurationTableLabelCell(props) {
    const {
        object,
        configuration,
        getValueTranslation
    } = props;

    const labelProps = {value: object[configuration.name]};
    if (configuration.name === 'value') {
        labelProps.isBreakWord = true;
        if (object.parameterType === ParameterModel.Type.FILE_CONTENTS) {
            labelProps.value = object[configuration.name].split(":")[0];
        }
    }
    labelProps.value = getValueTranslation(configuration, labelProps.value);

    return (
        <div className="table-cell">
            <Text {...labelProps}/>
        </div>
    )
}

export default ParameterListField;
