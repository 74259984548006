import React, {useState} from "react";
import './Tables.css';
import {buildClassName, isNotEmptyNorFalsy, textContainsEverySearchWord} from "../../../utilities/helperFunctions";
import LoadingWrapper from "../LoadingWrapper/LoadingWrapper";
import Text from "../Text/Text";
import {TableLabelHeader} from "../CustomTable/CustomTable";
import SearchBar from "../SearchBar/SearchBar";
import {MIN_SIZE_FOR_SEARCH} from "../../../utilities/constants";

function SelectableTable(props) {

    const {
        itemIdKey='id',
        itemNameKey='name',
        name,
        items,
        selectedItemId,
        onItemSelect,
        headers,
        RowComponent,
        emptyMessage,
        isStale,
        isLoading,
        isRequired,
        isDisabled,
        style,
        ...attr
    } = props;

    const [searchText, setSearchText] = useState('');
    function searchHandler(event) {
        const {value} = event.target;
        setSearchText(value);
    }

    const filteredItems = (items || []).filter(item => {
        let itemText = '';
        if (item[itemNameKey]) itemText += item[itemNameKey];
        if (item.displayName) itemText += item.displayName;
        if (item.description) itemText += item.description;
        return textContainsEverySearchWord(searchText, itemText);
    });

    const className = buildClassName(
        'display-table',
        isRequired && !isDisabled && !selectedItemId && 'is-invalid',
        isDisabled && 'is-disabled'
    );

    const rowStyle = {position: 'relative'};
    if (!isLoading && isStale) {
        rowStyle.opacity = 0.6;
    }

    const showSearch = items?.length > MIN_SIZE_FOR_SEARCH;

    return (
        <>
            {showSearch &&
            <div style={{marginBottom: '0.25rem'}}>
                <SearchBar value={searchText} onChange={searchHandler}
                    autoFocus isDisabled={isDisabled}/>
            </div>
            }

            <div className={className} style={style} {...attr}>
                <div className="table-header-group">
                    {isNotEmptyNorFalsy(headers) && headers.map((header, index) =>
                        <TableLabelHeader key={index} {...header}/>
                    )}

                    {!isNotEmptyNorFalsy(headers) &&
                        <div className="table-header"/>
                    }
                </div>

                <div className="table-row-group" style={rowStyle}>
                    <LoadingWrapper isLoading={isLoading} ignoreRelativeWrapper style={{top: '2rem'}}>
                        {Array.isArray(filteredItems) && filteredItems.map(item => {

                            const isActive = item[itemIdKey] === selectedItemId;
                            const optionClassName = buildClassName(
                                'table-row',
                                isActive && 'is-active'
                            );

                            return (
                                <div key={item[itemIdKey]} className={optionClassName} onClick={isDisabled ? null : onItemSelect}
                                    data-name={name} data-value={item[itemIdKey]} data-displayname={item.displayName || item[itemNameKey]}
                                >
                                    <RowComponent {...item} isDisabled={isDisabled} />
                                </div>
                            )
                        })}

                        {Array.isArray(items) && items.length === 0 &&
                        <Text className="absolutely-centered" value={emptyMessage} style={{top: '2rem'}}
                            isItalic isDisabled={isDisabled}/>
                        }
                    </LoadingWrapper>
                </div>
            </div>
        </>
    )
}

export default SelectableTable;
