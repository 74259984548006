import React, {useLayoutEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import LoadingWrapper from "../common/LoadingWrapper/LoadingWrapper";
import UserServiceModel from "../../models/user/UserServiceModel";
import {details} from "../../utilities/constants";
import UserServiceTablet from "./UserServiceTablet";
import LdapUserServiceTable from "./ldap/LdapUserServiceTable";
import UmsUserServiceTable from "./ums/UmsUserServiceTable";
import LdapUserServiceForm from "./ldap/LdapUserServiceForm";
import UmsUserServiceForm from "./ums/UmsUserServiceForm";
import OidcUserServiceTable from "./oidc/OidcUserServiceTable";
import OidcUserServiceForm from "./oidc/OidcUserServiceForm";
import MicrosoftUserServiceForm from "./purview/MicrosoftUserServiceForm";
import MicrosoftUserServiceTable from "./purview/MicrosoftUserServiceTable";
import {getValues, isNotEmptyNorFalsy} from "../../utilities/helperFunctions";
import InternalUserServiceTable from "./internal/InternalUserServiceTable";
import ManagedUserServiceTable from "./managed/ManagedUserServiceTable";
import ManagedUserServiceForm from "./managed/ManagedUserServiceForm";
import {getUserCanViewRoutePrivileges} from "../../reselect/selectors";
import Switch from "../common/Switch/Switch";
import {MainPageContent, MainPageHeader} from "../app/pages/MainPage";


function UserServiceDisplay(props) {
    const {t} = useTranslation(['userService']);
    const dispatch = useDispatch();

    const {
        ...attr
    } = props;

    const {
        userServiceId,
        isManagedUserServiceFormActive,
        isLdapUserServiceFormActive,
        isUmsUserServiceFormActive,
        isOidcUserServiceFormActive,
        isMicrosoftUserServiceFormActive,
        isRelativityImpersonationUserServiceFormActive
    } = useSelector(state => state.componentStates.userServiceDisplay);

    const isLoading = useSelector(state => !state.hasLoaded[details.USER_SERVICES]);
    const canViewRoute = useSelector(getUserCanViewRoutePrivileges);

    const userServiceDetailsMap = useSelector(state => state.userServiceDetailsMap);
    const selectedUserService = userServiceDetailsMap.get(userServiceId);

    const internalUserServices = getValues(userServiceDetailsMap)
        .filter(userService => userService.type === UserServiceModel.types.INTERNAL);
    const managedUserServices = getValues(userServiceDetailsMap)
        .filter(userService => userService.type === UserServiceModel.types.MANAGED);

    useLayoutEffect(() => {
        dispatch(UserServiceModel.componentActionCreators.setDisplayActive());

        return () => dispatch(UserServiceModel.componentActionCreators.setDisplayInactive());
    }, [dispatch]);


    const showInternalUserServices = isNotEmptyNorFalsy(internalUserServices);
    const showManagedUserServices = canViewRoute.legalHold || isNotEmptyNorFalsy(managedUserServices);

    return (
        <MainPageContent {...attr}
                title={
                    <MainPageHeader text={t('userService:page.title')} description={t('userService:page.description')}/>
                }
                list={
                    <LoadingWrapper isLoading={isLoading}>
                        {showInternalUserServices &&
                            <InternalUserServiceTable/>
                        }

                        {showManagedUserServices &&
                        <ManagedUserServiceTable/>
                        }

                        <LdapUserServiceTable/>

                        <UmsUserServiceTable/>

                        <OidcUserServiceTable/>

                        <MicrosoftUserServiceTable/>

                    </LoadingWrapper>
                }
                children={
                    <Switch>
                        {selectedUserService != null &&
                            <UserServiceTablet id={userServiceId}/>
                        }

                        {isManagedUserServiceFormActive &&
                            <ManagedUserServiceForm/>
                        }
                        {isLdapUserServiceFormActive &&
                            <LdapUserServiceForm />
                        }
                        {isUmsUserServiceFormActive &&
                            <UmsUserServiceForm />
                        }
                        {isOidcUserServiceFormActive &&
                            <OidcUserServiceForm/>
                        }
                        {isMicrosoftUserServiceFormActive &&
                            <MicrosoftUserServiceForm/>
                        }
                    </Switch>
                }
            >
        </MainPageContent>
    )
}

export default UserServiceDisplay;
