import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import React, {useRef, useState} from "react";
import UserServiceModel from "../../../models/user/UserServiceModel";
import {createCloseHandler, createInputHandler, createStateHandler} from "../../../utilities/componentFunctions";
import PopupModel from "../../../models/scheduler/PopupModel";
import Form, {FormHeader} from "../../common/Form/Form";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import TextArea from "../../common/TextArea/TextArea";
import {ButtonGroup} from "../../common/Button/Button";
import Checkbox from "../../common/Checkbox/Checkbox";
import {createToggleHandler} from "../../common/Checkbox/helpers";
import {SynchronizationFormBody, SynchronizeUsersFormBody} from "../ldap/LdapUserServiceForm";
import {ValueList} from "../../common/InputList/InputList";

function UmsUserServiceForm() {
    const {t} = useTranslation(['userService', 'common']);
    const dispatch = useDispatch();

    const {
        isDisabled
    } = useSelector(state => state.componentStates.userServiceForm);

    const [state, setState] = useState({
        type: UserServiceModel.types.UMS,
        name: '',
        description: '',
        enabled: true,
        restrictByUrl: false,
        allowedUrls: [],

        serviceAccountName: '',
        searchDelay: 30,
        url: '',
        synchronizeUsers: false,

        enableAuthentication: true,
        enableCollections: false,
        usersEligibleLegalHoldAdministrator: true,
        usersEligibleLegalHoldCustodian: true,
        isAddEnabled: false
    });

    const serviceAccountPasswordRef = useRef({});
    const stateHandler = createStateHandler({
        updateState: setState,
        passwordRef: serviceAccountPasswordRef,
        passwordName: 'serviceAccountPassword',
        passwordResetNames: ['serviceAccountName', 'url'],
        shouldEnable: UserServiceModel.validateFormData
    });
    const inputHandler = createInputHandler({
        handler: stateHandler
    })
    const toggleHandler = createToggleHandler({
        handler: stateHandler
    });

    const [allowedUrls, setAllowedUrls] = useState([]);

    function onSubmit() {
        dispatch(UserServiceModel.actionCreators.submitForm({
            ...state,
            allowedUrls
        }));
    }

    const onClose = createCloseHandler({
        t,
        item: t('userService:label.name'),
        values: state,
        blacklist: ['type', 'searchDelay'],
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        close: () => dispatch(UserServiceModel.actionCreators.hideForm(UserServiceModel.types.UMS))
    });

    const {
        name,
        description,
        enabled,
        restrictByUrl,

        serviceAccountName,
        searchDelay,
        url,
        synchronizeUsers,
        usersEligibleLegalHoldAdministrator,
        usersEligibleLegalHoldCustodian,

        isAddEnabled
    } = state;

    return (
        <Form onClose={onClose} isDisabled={isDisabled} closeButtonAriaLabel={t('userService:option.closeFormUms')}
              header={
                  <FormHeader text={t('userService:label.umsUserServiceTitle')} iconName={`userServiceUms`} isDisabled={isDisabled} />
              }
              body={
                  <>
                      <div className="display-input">
                          <HTMLTextInput label={t('common:label.name')} name={'name'} value={name} onChange={inputHandler}
                                         isDisabled={isDisabled} isRequired/>
                      </div>

                      <Checkbox label={t('common:label.enabled')} name={'enabled'} checked={enabled} onClick={toggleHandler}
                          isDisabled={isDisabled}/>

                      <div className="display-input">
                          <TextArea label={t('common:label.description')} name={'description'} value={description} onChange={inputHandler}
                                    isDisabled={isDisabled}/>
                      </div>

                      <div className="display-input">
                          <Checkbox label={t('userService:label.restrictByUrl')} name={'restrictByUrl'}
                                    checked={restrictByUrl} onClick={toggleHandler}
                                    isDisabled={isDisabled}/>
                      </div>

                      {restrictByUrl &&
                          <div className="display-input">
                              <ValueList id={'allowedUrls'} label={t('userService:label.allowedUrls')}
                                         values={allowedUrls} setValues={setAllowedUrls}
                                         ariaLabelKey={'AllowedUrls'} isDisabled={isDisabled}/>
                          </div>
                      }

                      <div className="display-input">
                          <HTMLTextInput label={t('userService:label.umsUrl')} name={'url'} placeholder={"http://127.0.0.1:8480"}
                                         value={url} onChange={inputHandler} isDisabled={isDisabled} isRequired/>
                      </div>

                      <div className="display-input">
                          <Checkbox label={t('userService:label.synchronizeUsers')} name={"synchronizeUsers"} checked={synchronizeUsers}
                              onClick={toggleHandler} isDisabled={isDisabled}/>
                      </div>

                      {synchronizeUsers &&
                          <>
                              <SynchronizeUsersFormBody usersEligibleLegalHoldAdministrator={usersEligibleLegalHoldAdministrator}
                                  usersEligibleLegalHoldCustodian={usersEligibleLegalHoldCustodian} toggleHandler={toggleHandler} isDisabled={isDisabled}/>

                              <SynchronizationFormBody type={UserServiceModel.types.UMS} serviceAccountName={serviceAccountName} serviceAccountNamePlaceHolder={'nuixroot'}
                                  serviceAccountPasswordRef={serviceAccountPasswordRef} searchDelay={searchDelay} inputHandler={inputHandler} isDisabled={isDisabled}/>
                          </>
                      }
                  </>
              }
              footer={
                  <ButtonGroup buttons={[{
                      id: 'formFooterBackButton',
                      label: t('common:option.cancel'),
                      onClick: onClose,
                      isDisabled
                  }, {
                      id: 'formFooterNextButton',
                      label: t('userService:option.addUserService'),
                      onClick: onSubmit,
                      isDisabled: isDisabled || !isAddEnabled
                  }]}/>
              }
        />
    )
}

export default UmsUserServiceForm;
