import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {userSettings} from "../../utilities/constants";
import {MenuDropdown} from "../common/Dropdown/Dropdown";
import {SettingsRowValue, SettingsUserIconRow} from "../common/CustomTable/CustomTable";
import LibraryFileModel, {LibraryFileApi} from "../../models/filelibrary/LibraryFileModel";
import {ExpandableChangeLogTable, ExpandableEditTextArea} from "../common/ExpandableContent/ExpandableContent";
import {createEditHandler, createInputHandler} from "../../utilities/componentFunctions";
import EditModel from "../../models/scheduler/EditModel";
import {shouldEnableLibraryFile} from "../../utilities/shouldEnableFunctions";
import {
    bytesCountToReadableCount,
    getFileExtension,
    getFileNameWithoutExtension,
    isNotEmptyNorFalsy
} from "../../utilities/helperFunctions";
import SchedulerModel from "../../models/scheduler/SchedulerModel";
import {libraryFileTypeKeys} from "../../i18next/keys";
import {icon} from "../../utilities/iconResolver";
import encode from "base64-arraybuffer";
import {fileIconModel, userIconModel} from "../../models/generics/IconModel";
import UserModel from "../../models/user/UserModel";
import './FileLibraryTablet.css';

function selectLibraryFileEditActive (state) {
    return state.editDetails.activeModel === LibraryFileModel.nom;
}


function LibraryFileDisplay(props) {
    const {t} = useTranslation(['aria', 'fileLibrary']);
    const dispatch = useDispatch();

    const {
        libraryFileId,
        canModify,
        isDisabled
    } = props;

    useEffect(() => {
        dispatch(LibraryFileModel.actionCreators.startPollingSettings(libraryFileId));
        return () => dispatch(LibraryFileModel.actionCreators.stopPollingSettings(libraryFileId));
    }, [libraryFileId])


    const libraryFile = useSelector(state => state.libraryFileDetailsMap.get(libraryFileId));
    const {
        name,
        description,
        nuixFileType,
        version,
        fileData,
        createdBy,
        createdDate,
        lastStateChangedDate,
        size
    } = libraryFile;

    const {saveEdit, cancelEdit, updateEdit, setEditSaveEnabled} = EditModel.buildDispatchers(dispatch)
    const editHandler = createEditHandler({
        updateEdit,
        setEditSaveEnabled,
        shouldEnable: shouldEnableLibraryFile,
        values: {fileName: name, fileData}
    })

    const inputHandler = createInputHandler({
        handler: editHandler
    })

    function onDownloadLibraryFile() {
        LibraryFileApi.downloadLibraryFile(libraryFileId, libraryFile.fileLibraryId)
            .then(res => {
                const blob = new Blob([encode.decode(res.data)]);
                const url = URL.createObjectURL(blob);
                // Download with temp a-link
                const link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute('download', `${name}`);
                link.style.visibility = 'hidden';
                // Firefox requires link to be attached to DOM
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                // clear blobUrl
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                dispatch(SchedulerModel.actionCreators.handleResponseError(error));
            });
    }

    const [createdByUsername, createdByUserIconName] = UserModel.useUserNameAndIconName(createdBy);
    const createdByIcon = userIconModel.useIcon(createdByUserIconName);
    const {showObjectIds} = useSelector(state => state.userSettingsMap.get(userSettings.TROUBLESHOOT));

    function menuHandler(event) {
        const {value} = event.currentTarget.dataset;

        switch (value) {
            case 'delete':
                dispatch(LibraryFileModel.actionCreators.promptDelete(libraryFileId));
                break;
            case 'update':
                dispatch(LibraryFileModel.actionCreators.startUpdate(libraryFileId));
                break;
            case 'download':
                onDownloadLibraryFile();
                break;
        }
    }

    let menuOptions = [];
    menuOptions.push({
        name: t('fileLibrary:option.update'),
        value: 'update',
        isDisabled
    });
    menuOptions.push({
        name: t('common:option.delete'),
        value: 'delete',
        isDisabled
    });
    menuOptions.push({
        isSeparator: true
    });
    menuOptions.push({
        name: t('fileLibrary:option.download'),
        value: 'download',
        isDisabled
    });

    const displayName = getFileNameWithoutExtension(name);
    const fileExtension = getFileExtension(name, 'empty');
    const fileIcon = fileIconModel.useIcon(fileExtension, {getLargeIcon: true});

    return (
        <div className="fileLibraryTablet-display is-overflow">
            <div className="fileLibraryTablet-display-header">
                <div style={{display: 'inline-flex'}}>
                    <h2 className="subtitle is-bold">
                        {displayName}
                    </h2>

                    {canModify && menuOptions.length > 0 &&
                    <div className="display-menu">
                        <MenuDropdown id={"libraryFileMenuDropdown"} aria-label={t('aria:hiddenAssistText.libraryFileMenu')}
                            menuOptions={menuOptions} onOptionClick={menuHandler} isDisabled={isDisabled}
                        />
                    </div>
                    }
                </div>


                <div style={{display: 'inline-flex', alignItems: 'center'}}>
                    <h2 className="subtitle" style={{flexShrink: '0', marginLeft: '2.5rem', marginRight: '0.5rem'}}>
                        {t(`fileLibrary:file.type.${nuixFileType}`)}
                    </h2>

                    { (nuixFileType === libraryFileTypeKeys.CUSTOM_FILE) &&
                    <span className="icon is-medium">
                        <img src={fileIcon} alt={t(`aria:hiddenAssistText.${nuixFileType}Icon`)} title={fileExtension !== "empty" ?
                            t('fileLibrary:label.typeFile', {extension: fileExtension.toUpperCase()}) : t('fileLibrary:label.unknown')}/>
                    </span>
                    }

                    { (nuixFileType === libraryFileTypeKeys.PYTHON_SCRIPT) &&
                    <span className="icon is-medium">
                        <img src={fileIcon} alt={t(`aria:hiddenAssistText.${nuixFileType}Icon`)}
                            title={t(`fileLibrary:label.pythonScript`)}/>
                    </span>
                    }

                    { (nuixFileType === libraryFileTypeKeys.RUBY_SCRIPT) &&
                    <span className="icon is-medium">
                        <img src={fileIcon} alt={t(`aria:hiddenAssistText.${nuixFileType}Icon`)}
                            title={t(`fileLibrary:label.rubyScript`)}/>
                    </span>
                    }

                    { (nuixFileType === libraryFileTypeKeys.JS_SCRIPT) &&
                    <span className="icon is-medium">
                        <img src={fileIcon} alt={t(`aria:hiddenAssistText.${nuixFileType}Icon`)}
                            title={t(`fileLibrary:label.jsScript`)}/>
                    </span>
                    }

                    { (nuixFileType === libraryFileTypeKeys.POWERSHELL_SCRIPT) &&
                    <span className="icon is-medium">
                        <img src={fileIcon} alt={t(`aria:hiddenAssistText.${nuixFileType}Icon`)}
                            title={t(`fileLibrary:label.powershellScript`)}/>
                    </span>
                    }


                    {(nuixFileType !== libraryFileTypeKeys.CUSTOM_FILE
                        && nuixFileType !== libraryFileTypeKeys.PYTHON_SCRIPT
                        && nuixFileType !== libraryFileTypeKeys.RUBY_SCRIPT
                        && nuixFileType !== libraryFileTypeKeys.JS_SCRIPT
                        && nuixFileType !== libraryFileTypeKeys.POWERSHELL_SCRIPT) &&
                    <span className="icon is-medium">
                        <img src={icon("fileNuix")} alt={t(`aria:hiddenAssistText.${nuixFileType}Icon`)}
                            title={t(`fileLibrary:file.type.${nuixFileType}`)}/>
                    </span>
                    }
                </div>

            </div>

            {showObjectIds &&
            <label className="label fileLibrary-objectId" style={{fontSize: '0.75rem'}}>
                {libraryFileId}
            </label>
            }

            <div className="fileLibraryTablet-display-list-container">
                <div className="settings-table display-item">
                    <div className="table-row-group">
                        <SettingsUserIconRow label={t('fileLibrary:label.createdBy')} value={createdByUsername} icon={createdByIcon}/>
                        <SettingsRowValue label={t('fileLibrary:label.createdDate')} value={createdDate}/>
                        <SettingsRowValue label={t('fileLibrary:label.lastStateChanged')} value={lastStateChangedDate}/>
                        <SettingsRowValue label={t('fileLibrary:label.version')} value={version}/>
                    </div>
                </div>

                <div className="settings-table display-item">
                    <div className="table-row-group">
                        <SettingsRowValue label={t('fileLibrary:label.fileName')} value={name}/>
                        <SettingsRowValue label={t('fileLibrary:label.size')} value={bytesCountToReadableCount(size)}/>
                    </div>
                </div>

                <LibraryFileDisplayBody libraryFileId={libraryFileId} canModify={canModify} isDisabled={isDisabled} inputHandler={inputHandler}/>
            </div>
        </div>
    )
}


export function LibraryFileDisplayBody(props) {
    const {t} = useTranslation(['aria', 'fileLibrary']);
    const dispatch = useDispatch();

    const {
        libraryFileId,
        canModify,
        inputHandler,
        isDisabled
    } = props;

    const auditLogMap = useSelector(state => state.auditLogMap);
    const auditLogs = auditLogMap.get(libraryFileId) || {};

    const libraryFile = useSelector(state => state.libraryFileDetailsMap.get(libraryFileId));
    const {
        name,
        description,
    } = libraryFile;

    const isLoading = useSelector(state => !state.hasLoaded[libraryFileId]);
    const isLibraryFileEditActive = useSelector(selectLibraryFileEditActive);



    return (
        <>
            {description &&
            <div className="display-item">
                    <ExpandableEditTextArea label={t('fileLibrary:label.notes')} name={'description'}
                                            value={description} onChange={inputHandler} isEditActive={isLibraryFileEditActive}
                                            isDisabled={isDisabled}
                    />
            </div>
            }

            {isNotEmptyNorFalsy(auditLogs) && !isLibraryFileEditActive &&
            <div className="display-item">
                <ExpandableChangeLogTable label={t('common:label.auditLog')} changeLogs={auditLogs}/>
            </div>
            }
        </>
    )
}

export default LibraryFileDisplay;
