import {createSlice} from "@reduxjs/toolkit";
import {getSystemDefaultTimezone} from "../../utilities/helperFunctions";
import {validate} from "../../utilities/validate";
import {
    DateFilterAction,
    DateFilterActionScope,
    DateFilterCriteria,
    DateFilterLevel,
    DateFilterScope,
    DateFilterType,
    DiscoverUserCaseCategory,
    KeywordsType,
    MailType,
    RelativityWorkspaceIdentifierType
} from "../../utilities/constants";

const initialState = {
    workflowInformation: {
        timezoneRadio: 1,
        timezoneSelect: getSystemDefaultTimezone(),
        useConfigurationProfile: false,
        useProcessingProfile: false,
        configurationProfilePath: "",
        processingProfilePath: "",
    },
    caseSettings: {
        caseStructure: 1,
        baseFolderPath: "C:\\Cases",
    },
    ingestionSettings: {
        dataStructure: 1,
        sourceDataFolder: "C:\\Data",
        datasetId: "001"
    },
    advancedIngestionSettings: {
        deduplicateItems: false,
        generateDuplicateCustodians: false,
        useDateFilter: false,
        keywordsSearch: false,
        keywordsType: KeywordsType.RETAIN,
        keywordsFile: "",
        dateFilterLevel: DateFilterLevel.CASE,
        dateFilterType: DateFilterType.NONE,
        dateFilterScope: DateFilterScope.TOP_LEVEL_COMMUNICATION,
        filterBeforeDate: "2020-01-01T23:59:59.000Z",
        filterAfterDate: "2020-01-01T00:00:00.000Z",
        dateFilterAction: DateFilterAction.EXCLUDE,
        dateFilterActionName: "Date Filter {date_time}",
        dateFilterCriteria: DateFilterCriteria.TOP_LEVEL_ITEM_DATE,
        dateFilterActionScope: DateFilterActionScope.MATCHES
    },
    legalExportSettings: {
        exportType: "CONCORDANCE",
        exportFiletypes: {
            exportNative: true,
            exportText: true,
            exportPdf: false,
            exportImage: false,
            exportXhtmlMetadataReport: false,
            exportThumbnailFile: false
        },
        useMetadataProfile: false,
        useProductionProfile: false,
        metadataMappingFileType: 1,
        fieldsMappingFileType: 1,
        metadataMappingFile: "",
        fieldsMappingFile: "",
        metadataProfileName: "",
        productionProfilePath: "",
        exportNumberingType: 1,
        numberingProps: {
            prefix: "{case_prefix}",
            numberingDigits: 7,
            numberingType: 1,
            startNumberingAt: 1,
            boxStartAt: 1,
            boxCycleFrom: 1,
            boxCycleTo: 999,
            folderStartAt: 1,
            folderCycleFrom: 1,
            folderCycleTo: 999,
            pageStartAt: 1,
            pageCycleFrom: 1,
            pageCycleTo: 9000,
            delimiter: ".",
            separator: ".",
            familyDigits: 3
        }
    },
    relativityConfiguration: {
        urlOptions: 1,
        hostName: false,
        serviceEndpoint: "/relativitywebapi",
        endpointType: "HTTPS",
        username: false,
        password: false,
        workspaceIdentifierType: RelativityWorkspaceIdentifierType.NAME,
        workspaceIdentifier: "Sample Workspace",
        folderPath: "Upload_{date_time}",
        createIfNotExists: true,
        importThreads: 1,
        importThreadTimeout: 3600,
        importThreadRetries: 3,
        metadataThreads: 8,
        patchInvalidEntries: true,
        useCustomClientVersion: false,
        relativityRestVersion: "REST_V1",
        customClientVersion: "",

        defineUsernameExecution: true,
        definePasswordExecution: true,
        defineWorkspaceExecution: true,
        defineHostNameExecution: true,
        defineFieldsMappingExecution: true,
        definedWorkspace: 1
    },
    discoverConfiguration: {
        hostname: "ringtail.us.nuix.com",
        waitForCaseToBeActive: false,
        disabled: false,
        assignUserToGroup: false,
        caseUserGroupIdentifier: "Case Manager",
        assignUserToCaseCategory: "ADMINISTRATOR",
        clonedCaseDescription: "Case created by workflow",
        clonedCaseName: "",
        waitForCaseToBeActiveTimeout: 60,
        caseIdentifierType: "NAME",
        caseIdentifierTypeId: 1,
        cloneCaseIfNotExists: false,
        discoverFileRepositoryType: "AMAZON_S3",
        cloneSourceCaseIdentifierType: "NAME",
        caseUserGroupIdentifierType: "NAME",
        cloneSourceCaseIdentifier: "Template Case",
        windowsFileRepository: "\\\\AIO.DISCOVER.local\\Repository\\Import\\{discover_case_name}",
        assignUserToCase: false,
        clonedCaseOrgIdentifier: "",
        apiToken: "{discover_api_token_protected}",
        clonedCaseOrgIdentifierType: "NAME",
        clonedCaseScheduleMetrics: false,
        caseIdentifier: "Automate_{case_name}",

        deploymentType: "SAAS",
        deploymentUrl: "https://ringtail.us.nuix.com",
        caseNameType: 1,
        createCaseFromTemplate: "NO_TEMPLATE",
        sourceCaseName: "Template Case",
        clonedCaseOrganization: "Automate",
        createCaseFromTemplateType: 1,
        setAssignUserToCase: false,
        setAssignGroupToCase: false,
        userToCase: DiscoverUserCaseCategory.ADMINISTRATOR,
        caseGroupName: "Case Manager",
        setConvertMail: false,
        mailType: MailType.HTML,
        defineTokenAtExecution: true
    },
    discoverExport: {
        metadataProfileEnabled: false,
        disabled: false,
        exportSplitAt: 10000,
        failedItemTagName: "Automate|Failed Items|Promote to Discover|{export_production_set_name}",
        documentIdType: "DISCOVER_SEQUENTIAL",
        untagSuccessfulItems: true,
        waitForDiscoverJobToFinish: false,
        metadataProfile: "Default",
        documentsPerLevel: 1000,
        tagFailedItems: true,
        exportFileTypeNative: true,
        mailTypeEnabled: true,
        runDeduplicationInDiscover: false,
        exportSchemeEnabled: false,
        productionSetName: "{last_production_set_name}",
        exportFolder: "{case_folder}/Export/{export_production_set_name}_{date_time}",
        exportScheme: "LEAVE_ATTACHMENTS_ON_EMAILS",
        runIndexingInDiscover: true,
        level: "Imports",
        notes: "",
        mailType: "HTML",
        exportFileTypeText: false,
    },
    ocrSettings: {
        performOcr: true,
        defineProfileType: 1,
        ocrProfileName: "Default"
    },
    optionalSteps: {
        exporting: {
            legalExport: true,
            customExportNumbering: true,
            exportItems: false,
            exportToDiscover: false,
            exportToRelativity: false
        },
        reporting: {
            report: false,
            treeCountSizeReport: false
        }
    },
    workflowInformationValid: true,
    caseSettingsValid: true,
    ingestionSettingsValid: true,
    advancedIngestionSettingsValid: true,
    legalExportSettingsValid: true,
    processingReportSettingsValid: true,
    discoverExportValid: true,
    ocrSettingsValid: true,
    relativityConfigurationValid: true,
    wasChanged: false
}

const wizardSlice = createSlice({
    name: "wizardSlice",
    initialState,
    reducers: {
        updateGenericValues(state, action) {
          const {stateName, updates} = action.payload;
          let nestedGroup = updates.nested ? updates.nested : null;

          const key = Object.keys(updates)[0];
          if (nestedGroup) {
              state[stateName][nestedGroup][key] = updates[key];
          } else {
              state[stateName][key] = updates[key];
          }

          state[`${stateName}Valid`] = validate({name: stateName, values: state[stateName]});
          return state;
        },
        saveWorkflowState(state, action) {

        },
        loadWorkflowState(state, action) {

        },
        updateOptionalSteps(state, action) {
            const {group, name, value} = action.payload;
            state.optionalSteps[group][name] = value;
            return state;
        },
        setWizardWasChanged(state, action) {
            const {changed} = action.payload;
            state.wasChanged = changed;
            return state;
        },
        resetWizard(state, action) {
            state = {...initialState}
            return state;
        }
    }
});

export const {
    updateOptionalSteps,
    setWizardWasChanged,
    updateGenericValues,
    resetWizard,
    saveWorkflowState,
    loadWorkflowState
} = wizardSlice.actions;
export default wizardSlice.reducer;
