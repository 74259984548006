import React, {useLayoutEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {icon} from '../../../utilities/iconResolver';
import LibraryForm from '../../library/LibraryForm';
import LibraryDisplay from '../../library/LibraryDisplay';
import {applicationFeatures, details, MIN_SIZE_FOR_SEARCH} from '../../../utilities/constants';
import LibraryModel from "../../../models/library/LibraryModel";
import {useAutoSelectId} from "../../../utilities/hooks";
import CurrentUserModel from "../../../models/user/CurrentUserModel";
import {AddButtonHeader} from "../../common/Button/Button";
import SearchBar from "../../common/SearchBar/SearchBar";
import {selectFilteredLibraryIds} from "./selectors";
import LoadingWrapper from "../../common/LoadingWrapper/LoadingWrapper";
import {LibrarySelectPanel} from "../../panels/SelectPanel";
import {MainPageAsideHeader} from "./MainPage";


function LibraryPage() {
    const {t} = useTranslation(['aria', 'library', 'common']);
    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState('');

    const isLoading = useSelector(state => !state.hasLoaded[details.LIBRARIES]);
    const {libraryId, isLibraryFormActive} = useSelector(state => state.componentStates.libraryPage);
    const libraryIds = useSelector(state => selectFilteredLibraryIds(state, {searchText}));

    const canAddLibrary = useSelector(state => state.currentUser.features.includes(applicationFeatures.ADD_LIBRARY));
    const canSearchLibraries = useSelector(state => state.libraryDetailsMap.size >= MIN_SIZE_FOR_SEARCH);


    // dispatch page active/inactive
    useLayoutEffect(() => {
        dispatch(LibraryModel.componentActionCreators.setPageActive());
        dispatch(CurrentUserModel.actionCreators.pollActivity(CurrentUserModel.activities.ADMINISTRATION));

        return () => dispatch(LibraryModel.componentActionCreators.setPageInactive());
    }, [dispatch]);

    useAutoSelectId(libraryId, libraryIds,
        id => dispatch(LibraryModel.actionCreators.showDisplay(id))
    );


    function librarySelectHandler(event) {
        const {value} = event.currentTarget.dataset;

        if (libraryId !== value) {
            dispatch(LibraryModel.actionCreators.showDisplay(value));
        }
    }

    return (
        <main className="mainPage">
            <aside className="selectPage-aside">
                <section className="panel-header">
                    <MainPageAsideHeader text={t('library:label.name_plural')}
                        icon={icon('library')} ariaKey={'library'}
                    />
                </section>

                <section className="panel-action-bar">
                    {canAddLibrary &&
                        <AddButtonHeader text={t('library:label.name')} canModify={canAddLibrary}
                            aria-label={t('aria:hiddenAssistText.addLibrary')}
                            onClick={() => dispatch(LibraryModel.actionCreators.showForm())}
                        />
                    }

                    {canSearchLibraries &&
                        <SearchBar id={'librarySearchBar'} value={searchText}
                            onChange={event => setSearchText(event.target.value)}
                        />
                    }
                </section>

                <section className="panel-list" role="tablist">
                    <LoadingWrapper isLoading={isLoading}>
                        {libraryIds.map(id =>
                            <LibrarySelectPanel key={id} libraryId={id}
                                isActive={id === libraryId} onPanelSelect={librarySelectHandler}
                            />
                        )}
                    </LoadingWrapper>
                </section>
            </aside>

            {libraryIds.includes(libraryId) &&
                <LibraryDisplay libraryId={libraryId}/>
            }
            {isLibraryFormActive && canAddLibrary &&
                <LibraryForm/>
            }
        </main>
    )
}

export default LibraryPage;
