import React from 'react';
import {ListDropdown} from "../../../../components/common/Dropdown/Dropdown";
import {DiscoverUserCaseCategory} from "../../../utilities/constants";
import {useTranslation} from "react-i18next";

function DiscoverUserCaseCategoryDropdown(props) {
    const {t} = useTranslation(['aria', 'wizard']);

    const {selectedUserCaseCategory, onUserCaseCategorySelected, ...rest} = props;
    const userCaseCategoryValues = [
        {value: DiscoverUserCaseCategory.ADMINISTRATOR, name: t('wizard:questions.discover.userCaseCategory.ADMINISTRATOR')},
        {value: DiscoverUserCaseCategory.GROUP_LEADER, name: t('wizard:questions.discover.userCaseCategory.GROUP_LEADER')},
        {value: DiscoverUserCaseCategory.GROUP_MEMBER, name: t('wizard:questions.discover.userCaseCategory.GROUP_MEMBER')},
    ]

    return (
        <ListDropdown id={'userCaseCategoryDropdown'} name={'userToCase'}
            aria-label={t('aria:hiddenAssistText.endpointType')} value={selectedUserCaseCategory}
                        items={userCaseCategoryValues} onItemSelect={onUserCaseCategorySelected}
                        buttonStyle={{maxWidth: '12rem', marginLeft: '0.5rem'}}
                        {...rest}
        />
    )
}

export default DiscoverUserCaseCategoryDropdown;