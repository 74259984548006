import React from "react";
import {useTranslation} from "react-i18next";
import {userCommentTypes} from "../../utilities/constants";
import {buildClassName, getLocaleDateTimeFromUTC, getTimeSince} from "../../utilities/helperFunctions";
import Text from "../common/Text/Text";
import {useSelector} from "react-redux";
import {userIconModel} from "../../models/generics/IconModel";

const UserCommentBody = React.forwardRef(function (props, ref) {
    const {t} = useTranslation(['userComment']);

    const {
        type,
        createdDate,
        message,

        username,
        userIcon,

        viewedByUser,
        isReadOnly,
        isDisabled
    } = props;

    const isNotViewed = viewedByUser != null && !viewedByUser;
    const isAdminNote = type === userCommentTypes.ADMIN_NOTE;
    const disabled = isDisabled ? ' is-disabled' : '';

    const backgroundClassName = buildClassName(
        'user-comment__background',
        isAdminNote && 'is-admin-note',
        isNotViewed && 'has-notification'
    );

    return (
        <article className={backgroundClassName} ref={ref}>
            <section className="user-comment">
                <span className={'icon is-medium' + disabled}>
                    <img className="round-image" src={userIcon} alt={t('aria:hiddenAssistText.userIcon')}/>
                </span>

                <section className="user-comment__body">
                    <div className="user-comment__username">
                        <Text ElementTag="h6" value={username} isDisabled={isDisabled}/>

                        {isReadOnly ?
                            <Text value={getLocaleDateTimeFromUTC(createdDate)}
                                isDisabled={isDisabled}/>
                            :
                            <Text value={getTimeSince(createdDate)} title={getLocaleDateTimeFromUTC(createdDate)}
                                isDisabled={isDisabled}/>
                        }

                        {isAdminNote &&
                            <>
                                <span className="dot-span"/>
                                <Text value={t('userComment:label.adminNote')}/>
                            </>
                        }
                    </div>

                    <Text ElementTag="p" value={message} isWordWrap isBreakWord
                        isDisabled={isDisabled}/>
                </section>
            </section>
        </article>
    )
});

export const UserComment = React.forwardRef(function (props, ref) {
    let username = props.username || props.userId;
    let userIconName = username;

    const user = useSelector(state => state.userDetailsMap.get(props.userId));
    if (user != null) {
        username = user.displayName || user.name;
        userIconName = user.name;
    }

    const userIcon = userIconModel.useIcon(userIconName);
    return (
        <UserCommentBody {...props} ref={ref}
            username={username} userIcon={userIcon}/>
    )
});

export default UserCommentBody;
