import React from 'react';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import QuestionRadio from "../../Question/QuestionRadio/QuestionRadio";
import {QuestionIngestionInput} from "../../Question/QuestionInput/QuestionInput";
import {updateGenericValues} from "../../../redux/WizardSlice/wizardSlice";
import SourceDataExample from "../../Text/SourceDataExample/SourceDataExample";
import {createInputHandler, createValueHandler} from "../../../../utilities/componentFunctions";

function IngestionSettings (props) {
    const {updateState} = props;
    const {t} = useTranslation(['wizard']);

    const {
        datasetId,
        dataStructure,
        sourceDataFolder
    } = props;

    const questions = [
        {
            question: t('wizard:questions.ingestion.dataStructure'),
            options: [
                {id: 4, value: t('wizard:questions.ingestion.options.datasetEvidence')},
                {id: 1, value: t('wizard:questions.ingestion.options.clientMatter')},
                {id: 2, value: t('wizard:questions.ingestion.options.workflowExecution')},
                {id: 3, value: t('wizard:questions.ingestion.options.noIngestion')}
            ]
        },
        {
            question: t('wizard:questions.ingestion.sourceDataLocation')
        }
    ]

    const inputHandler = createInputHandler({
        handler: updateState
    })

    const valuesHandler = createValueHandler({
        handler: updateState
    })


    const isDisabled = (dataStructure !== 1);
    const isRequired = !isDisabled;

    return (
        <div className={"wizard-step-container"}>
            <QuestionRadio
                question={questions[0].question}
                dataName={"dataStructure"}
                options={questions[0].options}
                selectedValue={dataStructure}
                onChange={valuesHandler} />

            {dataStructure === 1 &&
            <QuestionIngestionInput
                name={"dataStructure"}
                question={questions[1].question}
                onChange={inputHandler}
                sourceDataValue={sourceDataFolder}
                datasetValue={datasetId}
                isRequired={isRequired}
                isDisabled={isDisabled} />
            }

            {dataStructure === 1 &&
                <div className={"dynamic-examples-container"}>
                    <SourceDataExample sourceDataFolder={sourceDataFolder} datasetId={datasetId} />
                </div>
            }
        </div>
    )
}

function mapStateToProps (state) {
    const {wizardSlice:{ingestionSettings: {datasetId, dataStructure, sourceDataFolder}}} = state;
    return {
        datasetId,
        dataStructure,
        sourceDataFolder
    };
}

function mapDispatchToProps (dispatch) {
    return {
        updateState: updates => dispatch(updateGenericValues({stateName: "ingestionSettings", updates: updates}))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IngestionSettings);