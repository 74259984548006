import DetailsModel, {DetailsApi, DetailsSaga} from "../generics/DetailsModel";
import ComponentStateModel from "../generics/ComponentStateModel";
import ReduxStateModel from "../scheduler/ReduxStateModel";
import {all, put, select} from "redux-saga/effects";
import {priorityKeys, settingsDisplayKeys} from "../../i18next/keys";
import {contextCall} from "../../saga/sagaFunctions";
import {details, FAST_QUERY_INTERVAL, routes} from "../../utilities/constants";
import ServerModel from "./ServerModel";
import LicenceSourceModel from "./LicenceSourceModel";
import SchedulerModel from "../scheduler/SchedulerModel";
import AxiosProxy from "../api/AxiosProxy";

class EngineModel extends DetailsModel {

    static nom = 'EngineModel';
    static actions = EngineModel.buildActions('ENGINE');
    static actionCreators = EngineModel.buildActionCreators(EngineModel.actions);
    static reducer = EngineModel.buildReducer(EngineModel.actions);

    static componentActionCreators = {
        ...EngineModel.buildComponentUpdateActionCreators(),
        ...EngineModel.buildComponentSetActiveActionCreators()
    };

    constructor(model = {}) {
        super(model);
        const {serverId, nuixLicenceSourceId, priority, nuixLicenceType, nuixVersion, javaVersion, supportedExecutionMode, currentExecutionMode, automateLicence, runningJobPercentageComplete, workerAgentOnly, logFile, runningJobId, executionProfileId} = model;
        const {targetNuixWorkers, minNuixWorkers, acquiredWorkers, status, tags, error} = model;

        this.serverId = serverId;
        this.nuixLicenceSourceId = nuixLicenceSourceId;
        this.priority = priority || priorityKeys.MEDIUM;
        this.nuixLicenceType = nuixLicenceType;
        this.nuixVersion = nuixVersion;
        this.javaVersion = javaVersion;
        this.supportedExecutionMode = supportedExecutionMode
        this.currentExecutionMode = currentExecutionMode;
        this.automateLicence = automateLicence;
        this.runningJobPercentageComplete = runningJobPercentageComplete;
        this.workerAgentOnly = workerAgentOnly;
        this.logFile = logFile;
        this.runningJobId = runningJobId;
        this.executionProfileId = executionProfileId;

        this.targetNuixWorkers = targetNuixWorkers;
        this.minNuixWorkers = minNuixWorkers;
        this.acquiredWorkers = acquiredWorkers;
        this.tags = tags;
        this.error = error;
    }

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'engineDisplay',
                type: 'Display',
                state: {
                    engineId: null,
                    isEngineFormActive: false
                }
            },
            {
                key: 'engineTablet',
                type: 'Tablet',
                state: {
                    isDisabled: false
                }
            },
            {
                key: 'engineForm',
                type: 'Form',
                state: {
                    isDisabled: false
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'ENGINE_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }
}

export class EngineApi extends DetailsApi {

    static location = '/resources';
    static type = '/engines';
}

export class EngineSaga extends DetailsSaga {

    static ModelType = EngineModel;
    static ModelApi = EngineApi;

    static activationComponent = 'ENGINE_DISPLAY';
    static variableNames = {
        detailsMap: 'engineDetailsMap',
        instanceId: 'engineId',
        isFormActive: 'isEngineFormActive',
        updateDisplay: 'updateDisplay',
        updatePane: 'updateTablet',
        route: routes.SETTINGS
    };

    static translations = {
        itemTitle: '$t(engine:label.name)',
        itemLower: '$t(engine:label.name_lower)'
    };

    static buildActivationEffects(dispatch) {
        return [
            ...super.buildActivationEffects(dispatch),
            // ACTIVATION EFFECTS
            put(EngineModel.actionCreators.startPollingDetails(FAST_QUERY_INTERVAL)),
            put(ServerModel.actionCreators.startPollingDetails()),
            put(LicenceSourceModel.actionCreators.startPollingDetails())
        ]
    }

    static buildDeactivationEffects() {
        return [
            ...super.buildDeactivationEffects(),
            // DEACTIVATION EFFECTS
            put(EngineModel.actionCreators.stopPollingDetails()),
            put(ServerModel.actionCreators.stopPollingDetails()),
            put(LicenceSourceModel.actionCreators.stopPollingDetails())
        ]
    }

    static* setInstanceId(args) {
        const {updateDisplay, instanceId} = this.variableNames;

        yield all ([
            put(SchedulerModel.actionCreators.setSettingsDisplay(settingsDisplayKeys.ENGINES)),
            put(this.ModelType.componentActionCreators[updateDisplay]({[instanceId]: args.id}))
        ]);
    }

    static* getEditValues(id) {
        const engine = yield select(state => state.engineDetailsMap.get(id));

        return {
            engineName: engine.name,
            nuixLicenceSourceId: engine.nuixLicenceSourceId,
            priority: engine.priority,
            supportedExecutionMode: engine.supportedExecutionMode,
            targetNuixWorkers: engine.targetNuixWorkers || 1,
            minNuixWorkers: engine.minNuixWorkers || 1,
            executionProfileId: engine.executionProfileId
        }
    }

    static getSaveValues(values) {
        const {engineName: name, serverId, nuixLicenceSourceId, priority, supportedExecutionMode, targetNuixWorkers, minNuixWorkers, executionProfileId} = values;

        return {
            name,
            serverId,
            nuixLicenceSourceId,
            priority,
            supportedExecutionMode,
            targetNuixWorkers,
            minNuixWorkers,
            executionProfileId: executionProfileId === '' ? null : executionProfileId
        }
    }

    static* queryDetails() {
        const response = yield contextCall(EngineApi, 'getDetails');
        const key = details.ENGINES;

        if (AxiosProxy.shouldUpdate(key, response)) {
            yield all([
                put(EngineModel.actionCreators.setDetailsMap(response.data)),
                put(ReduxStateModel.actionCreators.setHasLoaded(key))
            ]);
        }
    }
}

export default EngineModel;