import {
    all,
    call,
    cancel,
    delay,
    fork,
    put,
    race,
    select,
    spawn,
    take,
    takeEvery,
    takeLatest,
    takeLeading
} from 'redux-saga/effects';
import {QUERY_INTERVAL} from "../utilities/constants";
import {genericErrorHandler} from "./tryCatchWrapper";
import {getValues} from "../utilities/helperFunctions";
import {popupInfoKeys} from "../i18next/keys";
import PopupModel from "../models/scheduler/PopupModel";
import EditModel from "../models/scheduler/EditModel";
import SchedulerModel from "../models/scheduler/SchedulerModel";

export function* isDisabledWrapper(updateState, saga, ...args) {
    if (typeof updateState !== 'function') {
        return yield call(saga, ...args);
    }
    try {
        const result = yield all([
            put(updateState({isDisabled: true})),
            call(saga, ...args)
        ]);
        return result[1];
    } finally {
        yield put(updateState({isDisabled: false}));
    }
}

//To be used with takeX sagaEffects where action is last argument passed
//Spreads saga arguments and action payload into saga
//USE ONLY WITH TAKE EFFECTS (needs action to be last argument passed)
export function* payloadSpread(saga, ...args) {
    const { payload } = args[args.length - 1];
    const argsWithoutAction = args.slice(0, -1);

    if (payload) {
        yield call(saga, ...argsWithoutAction, ...getValues(payload));
    } else {
        yield call(saga, ...argsWithoutAction);
    }
}

export function* editNavigation(dispatch, saga, ...args) {
    const { activeModel, isSaveEnabled, isChanged } = yield select(state => state.editDetails);
    if (activeModel == null) {
        yield call(saga, ...args);
    } else {
        const effectDescriptor = call(waitForEditResetThenCall, saga, ...args);
        if (isChanged) {
            yield put(PopupModel.actionCreators.show({
                info: {
                    key: popupInfoKeys.SAFE_CLOSE
                },
                buttons: [{
                    titleKey: 'common:option.dontSave',
                    onClick: () => {
                        dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(effectDescriptor));
                        dispatch(EditModel.actionCreators.cancel());
                    }
                }, {
                    titleKey: 'common:option.save',
                    onClick: () => {
                        dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(effectDescriptor));
                        dispatch(EditModel.actionCreators.save());
                    },
                    isDisabled: !isSaveEnabled
                }]
            }));

        } else {
            yield put(SchedulerModel.actionCreators.yieldEffectDescriptor(effectDescriptor));
            yield put(EditModel.actionCreators.cancel());
        }
    }
}

function* waitForEditResetThenCall(saga, ...args) {
    yield take(EditModel.actions.RESET);
    yield call(saga, ...args);
}

//////////////////////////////////////////////////////////GENERIC POLLING SAGAS//////////////////////////////////////////////////////
export function* spawnSaga(saga, ...args) {
    yield spawn(saga, ...args);
}

export function callUntil(pattern, saga, ...args) {
    return race([
        take(pattern),
        call(saga, ...args)
    ]);
}

export function pollUntil(pattern, period, saga, ...args) {
    return callUntil(pattern, poll, period, saga, ...args);
}

export function* poll(period = QUERY_INTERVAL, saga, ...args) {
    try {
        while (true) {
            yield call(saga, ...args);
            yield delay(period);
        }
    } catch (error) {
        yield call(genericErrorHandler, error);
    }
}

export function takeLeadingPayload(pattern, saga, ...args) {
    return fork(function* () {
        let payloadTasks = {};
        while(true) {
            const action = yield take(pattern);
            const key = JSON.stringify(action.payload);

            if (payloadTasks[key] && !payloadTasks[key].isRunning()) {
                payloadTasks[key] = null;
            }

            if (!payloadTasks[key]) {
                payloadTasks[key] = yield fork(saga, ...args.concat(action));
            }
        }
    });
}

export function takeLatestPayload(pattern, saga, ...args) {
    return fork(function* () {
        let payloadTasks = {};
        while (true) {
            const action = yield take(pattern);
            const key = JSON.stringify(action.payload);

            let prevTask = payloadTasks[key];
            if (prevTask != null) {
                yield cancel(prevTask);
                prevTask = null;
            }

            if (prevTask == null) {
                payloadTasks[key] = yield fork(saga, ...args.concat(action));
            }
        }
    });
}

export function contextSaga(context, saga) {
    if (!(saga in context))
        throw Error(saga + " saga does not exist in context: " + context);

    return [context, context[saga]];
}

export function contextCall(context, saga, ...args) {
    return call(contextSaga(context, saga), ...args);
}

export function contextFork(context, saga, ...args) {
    return fork(contextSaga(context, saga), ...args);
}

export function contextSpawn(context, saga, ...args) {
    return spawn(contextSaga(context, saga), ...args);
}

export function contextTakeLatest(pattern, context, saga, ...args) {
    return takeLatest(pattern, contextSaga(context, saga), ...args);
}

export function contextTakeLeading(pattern, context, saga, ...args) {
    return takeLeading(pattern, contextSaga(context, saga), ...args);
}

export function contextTakeLeadingPayload(pattern, context, saga, ...args) {
    return takeLeadingPayload(pattern, contextSaga(context, saga), ...args);
}

export function contextTakeEvery(pattern, context, saga, ...args) {
    return takeEvery(pattern, contextSaga(context, saga), ...args);
}

export function contextPollUntil(pattern, period, context, saga, ...args) {
    return pollUntil(pattern, period, contextSaga(context, saga), ...args);
}

export function serverErrorResponseValues(errorResponse) {
    return errorResponse.data.key ?
        null
        :
        {
            values: {
                title: errorResponse.data.title || `HTTP/${errorResponse.status} ${errorResponse.statusText}`,
                message: errorResponse.data.message || errorResponse.data
            }
        };
}