import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getValues, isNotEmptyNorFalsy, lowerCaseFirstLetter} from "../../utilities/helperFunctions";
import {AddButtonHeader} from "../common/Button/Button";
import {
    StatusNameDescriptionCell,
    TableEnableToggleCell,
    TableLabelCell,
    TableLabelHeader,
    TableRow
} from "../common/CustomTable/CustomTable";
import {selectThirdPartyServiceNames} from "./ThirdPartyServiceDisplay";
import ThirdPartyServiceModel from "../../models/thirdparty/ThirdPartyServiceModel";
import {permissionKeys} from "../../i18next/keys";
import LimitedTableRow from "../limited/LimitedTableRow";

function ThirdPartyServiceTable(props) {
    const {t} = useTranslation(['thirdPartyService', 'common']);
    const dispatch = useDispatch();

    const {
        type,
        thirdPartyServices,
        showForm,
        canModify,
        TableHeaders,
        ...rowProps
    } = props

    const {
        thirdPartyServiceId,
        idDisabled
    } = useSelector(state => state.componentStates.thirdPartyServiceDisplay);

    const toggleEnabled = useCallback(event => {
        const {name} = event.currentTarget.dataset;
        dispatch(ThirdPartyServiceModel.actionCreators.toggleEnabled(name));
    }, []);

    rowProps.thirdPartyServiceId = thirdPartyServiceId;
    rowProps.idDisabled = idDisabled;

    const camelCaseType = lowerCaseFirstLetter(type);
    const isNotEmpty = isNotEmptyNorFalsy(thirdPartyServices);
    if (!canModify && !isNotEmpty) {
        return null;
    }


    return (
        <div className="display-item">
            <AddButtonHeader aria-label={t(`aria:hiddenAssistText.add${type}`)} text={t(`thirdPartyService:label.${camelCaseType}`)}
                data-value={type} canModify={canModify} onClick={showForm}/>

            {isNotEmpty &&
                <div className="display-table">
                    <div className="table-header-group">
                        <div className="table-header"/>
                        <TableLabelHeader label={t('common:label.name')}/>
                        {TableHeaders &&
                            <TableHeaders/>
                        }
                        {!rowProps.hideScope &&
                            <TableLabelHeader label={t('thirdPartyService:label.authenticationScope')}/>
                        }
                        {rowProps.showMethod &&
                            <TableLabelHeader label={t('thirdPartyService:label.authenticationMethod')}/>
                        }
                        {rowProps.showService &&
                            <TableLabelHeader label={t('thirdPartyService:label.authenticationService')}/>
                        }
                    </div>

                    <div className="table-row-group">
                        {getValues(thirdPartyServices).map(thirdPartyService =>
                            <ThirdPartyServiceRow key={thirdPartyService.id} thirdPartyService={thirdPartyService}
                                toggleEnabled={toggleEnabled} {...rowProps}/>
                        )}
                    </div>
                </div>
            }
        </div>
    )
}

function ThirdPartyServiceRow(props) {
    const {t} = useTranslation(['thirdPartyService']);
    const {
        thirdPartyService,
        thirdPartyServiceId,
        TableRowCells,
        toggleEnabled,
        showTablet,
        hideScope,
        showMethod,
        showService,
        idDisabled
    } = props;

    const names = useSelector(state => selectThirdPartyServiceNames(state, thirdPartyService));
    const canModify = thirdPartyService.userPermissions.includes(permissionKeys.MODIFY);

    const isActive = thirdPartyServiceId === thirdPartyService.id
    const isDisabled = idDisabled === thirdPartyService.id;

    let authenticationService = '';
    if (thirdPartyService.authenticationMethod === ThirdPartyServiceModel.AuthenticationMethod.OIDC_AUTHORIZATION_CODE) {
        authenticationService = names.authenticationService;
    }

    const isViewLimited = thirdPartyService.userPermissions.includes(permissionKeys.VIEW_LIMITED);
    if (isViewLimited && !canModify) {

        let cellCount = 3;
        if (TableRowCells) {
            cellCount += 1;
        }
        if (thirdPartyService.type === ThirdPartyServiceModel.Type.RELATIVITY) {
            cellCount += 1;
        }
        if (hideScope) {
            cellCount -= 1;
        }
        if (showMethod) {
            cellCount += 1;
        }
        if (showService) {
            cellCount += 1;
        }

        return (
            <LimitedTableRow id={thirdPartyService.id} isActive={isActive} onClick={showTablet} cellCount={cellCount}
                             nameComponent={<StatusNameDescriptionCell status={thirdPartyService.status} name={thirdPartyService.name}
                                                                       description={thirdPartyService.description}/>}
                             toggleComponent={<TableEnableToggleCell ariaLabelKey={thirdPartyService.type} displayName={thirdPartyService.name} name={thirdPartyService.id}
                                 checked={thirdPartyService.enabled} onClick={toggleEnabled} isReadOnly={!canModify} isDisabled={isDisabled}/>}
            />
        )
    }

    return (
        <TableRow key={thirdPartyService.id} value={thirdPartyService.id} onClick={showTablet}
            isActive={isActive} isDisabled={isDisabled}
        >
            <TableEnableToggleCell ariaLabelKey={thirdPartyService.type} displayName={thirdPartyService.name} name={thirdPartyService.id}
                checked={thirdPartyService.enabled} onClick={toggleEnabled} isReadOnly={!canModify} isDisabled={isDisabled}/>

            <StatusNameDescriptionCell status={thirdPartyService.status} name={thirdPartyService.name}
                description={thirdPartyService.description}/>
            {TableRowCells &&
                <TableRowCells thirdPartyService={thirdPartyService}/>
            }
            {!hideScope &&
                <TableLabelCell label={t(`thirdPartyService:authenticationScope.${thirdPartyService.authenticationScope}`)}/>
            }
            {showMethod &&
                <TableLabelCell label={t(`thirdPartyService:authenticationMethod.${thirdPartyService.authenticationMethod}`)}/>
            }
            {showService &&
                <TableLabelCell label={authenticationService}/>
            }
        </TableRow>
    )
}

export default ThirdPartyServiceTable;
