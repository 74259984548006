import React from "react";
import {useTranslation} from "react-i18next";
import FormBuilderConfiguration from "../../../models/workflowbuilder/FormBuilderConfiguration";

function FieldConfigurationWrapper(props) {
    const {t} = useTranslation(['workflowBuilder']);
    const {
        Component,
        addColonToLabel,

        fieldConfigurations,
        configuration,

        formKey,
        state,
        isDisabled,
        ...attr
    } = props;

    let label;
    if (configuration.label) {
        label = FormBuilderConfiguration.getLabelTranslation(t, formKey, configuration.label);
        if (addColonToLabel && typeof label === 'string') {
            label += ':';
        }
    }

    let scriptLanguage = configuration.scriptLanguage;
    if (configuration.scriptLanguageField && state[configuration.scriptLanguageField]) {
        scriptLanguage = state[configuration.scriptLanguageField];
    }

    const value = state[configuration.name];
    const _isDisabled = isDisabled || !configuration.isEnabled(state, fieldConfigurations);
    const _isInvalid = !configuration.isValid(state[configuration.name]);

    return (
        <Component aria-label={label} {...attr} label={label} configuration={configuration}
            fieldConfigurations={fieldConfigurations} state={state} value={value} formKey={formKey}
            addColonToLabel={addColonToLabel} scriptLanguage={scriptLanguage} isInvalid={_isInvalid} isDisabled={_isDisabled}/>
    )
}

export default FieldConfigurationWrapper;
