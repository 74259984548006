import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import React, {useLayoutEffect, useRef} from "react";
import {createInputHandler} from "../../utilities/componentFunctions";
import TextArea from "../common/TextArea/TextArea";
import {Button, ButtonGroup} from "../common/Button/Button";
import Switch from "../common/Switch/Switch";
import Text from "../common/Text/Text";
import {userCommentTypes} from "../../utilities/constants";
import {buildClassName, getScrollElement} from "../../utilities/helperFunctions";
import {userIconModel} from "../../models/generics/IconModel";
import UserCommentModel from "../../models/generics/UserCommentModel";
import UserModel from "../../models/user/UserModel";


function UserCommentForm(props) {
    const dispatch = useDispatch();

    const {
        objectType,
        objectId
    } = props;

    const {
        isUserCommentFormActive
    } = useSelector(state => state.componentStates.userCommentDisplay);

    const userCommentForm = useSelector(state => state.componentStates.userCommentForm);
    const isDisabled = props.isDisabled || userCommentForm.isDisabled;

    const inputHandler = createInputHandler({
        updateState: updates => dispatch(UserCommentModel.componentActionCreators.updateForm(updates)),
        shouldEnable: ({message}) => !!message
    });

    function showCommentForm(event) {
        const {type} = event.currentTarget.dataset;
        dispatch(UserCommentModel.actionCreators.showForm({type}));
    }

    function onSubmit() {
        dispatch(UserCommentModel.actionCreators.submitForm(objectType, objectId, userCommentForm));
    }

    function onClose() {
        dispatch(UserCommentModel.actionCreators.hideForm());
    }

    return (
        <CommentForm {...props} {...userCommentForm} inputHandler={inputHandler} showForm={showCommentForm}
            onSubmit={onSubmit} onClose={onClose} isFormActive={isUserCommentFormActive} isDisabled={isDisabled}/>
    )
}

export function CommentForm(props) {
    const {t} = useTranslation(['userComment']);
    const {
        type,
        message,
        inputHandler,

        showForm,
        showNext,
        canComment,
        canAddAdminNotes,

        onSubmit,
        onClose,
        isAddEnabled,
        isFormActive,
        isDisabled
    } = props;

    const commentBodyRef = useRef();
    const currentUsername = useSelector(state => state.currentUser.name);
    const [username, userIconName] = UserModel.useUserNameAndIconName(currentUsername);
    const userIcon = userIconModel.useIcon(userIconName);

    // Scroll to textArea onActive
    useLayoutEffect(() => {
        if (isFormActive && commentBodyRef.current != null) {
            const scrollElement = getScrollElement(commentBodyRef.current);
            scrollElement.scrollTop = scrollElement.scrollHeight;
        }
    }, [isFormActive]);


    const isAdminNote = type === userCommentTypes.ADMIN_NOTE;
    const className = buildClassName(
        'user-comment__background is-form',
        isFormActive && 'is-active',
        isAdminNote && 'is-admin-note'
    );

    const submitButtonText = t(`userComment:option.${isAdminNote ? 'addAdminNote' : 'addComment'}`);
    const submitAndNextButtonText = t(`userComment:option.${isAdminNote ? 'addAdminNoteAndNext' : 'addCommentAndNext'}`);
    const disabled = isDisabled ? ' is-disabled' : '';

    return (
        <article className={className}>
            <section className="user-comment">
                <span className={'icon is-medium' + disabled}>
                    <img className="round-image" src={userIcon} alt={t('aria:hiddenAssistText.userIcon')}/>
                </span>

                <section className="user-comment__body" ref={commentBodyRef}>
                    <Text ElementTag="h6" value={username} isDisabled={isDisabled}/>

                    <Switch>
                        {!isFormActive &&
                            <>
                                {canComment && !canAddAdminNotes &&
                                    <input id="userCommentAddInput" className={'user-comment__add-input' + disabled} onFocus={showForm}
                                        data-type={userCommentTypes.REGULAR} placeholder={t('userComment:message.addComment')} readOnly disabled={isDisabled}/>
                                }
                                {!canComment && canAddAdminNotes &&
                                    <input id="userCommentAdminNoteAddInput" className={'user-comment__add-input' + disabled} onFocus={showForm}
                                        data-type={userCommentTypes.ADMIN_NOTE} placeholder={t('userComment:message.addAdminNote')} readOnly disabled={isDisabled}/>
                                }
                                {canComment && canAddAdminNotes &&
                                    <div className={'user-comment__add-comment-or-note' + disabled}>
                                        <Button id="userCommentAdminNoteAddInput" text={t('userComment:message:addAdminNote')}
                                            isClear onClick={showForm} data-type={userCommentTypes.ADMIN_NOTE} isDisabled={isDisabled}/>

                                        <span className="slash-span">
                                            /
                                        </span>

                                        <Button id="userCommentAddInput" text={t('userComment:message:addComment')}
                                            isClear onClick={showForm} data-type={userCommentTypes.REGULAR} isDisabled={isDisabled}/>
                                    </div>
                                }
                            </>
                        }

                        <>
                            <TextArea id="userCommentTextArea" name={'message'} value={message} onChange={inputHandler}
                                aria-label={t('aria:hiddenAssistText.userCommentFormBody')} rows={6} autoFocus isDisabled={isDisabled}/>

                            <ButtonGroup buttons={[
                                {
                                    id: 'userCommentCancelButton',
                                    label: t('common:option.cancel'),
                                    onClick: onClose,
                                    isDisabled
                                },
                                {
                                    id: 'userCommentSubmitButton',
                                    label: submitButtonText,
                                    onClick: onSubmit,
                                    isDisabled: isDisabled || !isAddEnabled
                                },
                                showNext && {
                                    id: 'userCommentSubmitAndNextButton',
                                    label: submitAndNextButtonText,
                                    onClick: onSubmit,
                                    isDisabled: isDisabled || !isAddEnabled,
                                    "data-submit": "next"
                                }
                            ]}/>
                        </>
                    </Switch>
                </section>
            </section>
        </article>
    )
}

export default UserCommentForm;
