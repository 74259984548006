import React from "react";
import {useSelector} from "react-redux";
import LoadingWrapper from "../../common/LoadingWrapper/LoadingWrapper";
import {useTranslation} from "react-i18next";
import ExpandableContent, {ExpandableLog} from "../../common/ExpandableContent/ExpandableContent";
import {SettingsRowLabel, SettingsRowSeparator, SettingsRowValue} from "../../common/CustomTable/CustomTable";
import {
    boolObjectToArray,
    getEntries,
    getKeys,
    getLocaleStringFromFormattedDate,
    getPluralTranslations,
    getValues,
    isNotEmptyNorFalsy,
    objectTruthyValues,
    objEquals
} from "../../../utilities/helperFunctions";
import {scheduleFrequencyKeys, scheduleTriggerTypeKeys} from "../../../i18next/keys";
import {StatusLabel} from "../../common/Common";
import {ParametersTable} from "../../parameters/ParameterList";
import {
    SAME_AS_TRIGGERING_DATASET,
    SAME_AS_TRIGGERING_JOB,
    SAME_AS_TRIGGERING_LEGAL_HOLD
} from "../../../utilities/constants";
import {OperationBacklogTable} from "../../tables/OperationTable/OperationTable";
import Switch from "../../common/Switch/Switch";
import {PriorityLabel} from "../../job/JobTablet";
import Text from "../../common/Text/Text";
import CopySecretLabel from "../../common/Text/CopySecretLabel";

function ScheduleConfirmPane(props) {
    const {isLoadingSettings} = props;
    const {t} = useTranslation(['common']);

    const jobScheduleForm = useSelector(state => state.componentStates.jobScheduleForm);
    const names = useSelector(state => getScheduleObjectNames(state, jobScheduleForm), objEquals);

    const {
        scheduleName,
        enabled,
        description,
        conditions,
        scheduleTriggerType,
        scheduleTrigger,
        eventTrigger,
        webhookTrigger,
        priority,
        parameters,
        operations,
        isDisabled
    } = jobScheduleForm;

    return (
        <div className="pane-margin">
            <LoadingWrapper isLoading={isLoadingSettings}>
                <div className="display-input">
                    <ExpandableSettings scheduleName={scheduleName} enabled={enabled} isDisabled={isDisabled}/>
                </div>

                {description &&
                <div className="display-input">
                    <ExpandableLog label={t('common:label.description')} log={description} isDisabled={isDisabled}/>
                </div>
                }

                {getValues(conditions).some(condition => condition.enabled) &&
                <div className="display-input">
                    <ExpandableConditions conditions={conditions} isDisabled={isDisabled}/>
                </div>
                }


                <Switch>
                    {scheduleTriggerType === scheduleTriggerTypeKeys.ON_SCHEDULE &&
                    <div className="display-input">
                        <ExpandableScheduleTriggers scheduleTrigger={scheduleTrigger} isDisabled={isDisabled}/>
                    </div>
                    }

                    {scheduleTriggerType === scheduleTriggerTypeKeys.ON_JOB_EVENT &&
                    <>
                        <div className="display-input">
                            <ExpandableEventTriggers type={'job'} event={eventTrigger.jobEvents} isDisabled={isDisabled}/>
                        </div>
                        <div className="display-input">
                            <ExpandablePropertyFilters jobName={eventTrigger.jobName} description={eventTrigger.description} clientName={eventTrigger.clientName}
                                matterName={eventTrigger.matterName} libraryName={eventTrigger.libraryName} workflowName={eventTrigger.workflowName} priority={eventTrigger.priority}
                                submissionMechanism={eventTrigger.submissionMechanism} isDisabled={isDisabled}/>
                        </div>
                    </>
                    }

                    {scheduleTriggerType === scheduleTriggerTypeKeys.ON_DATASET_EVENT &&
                    <>
                        <div className="display-input">
                            <ExpandableEventTriggers type={'dataset'} event={eventTrigger.datasetEvents} isDisabled={isDisabled}/>
                        </div>
                        <div className="display-input">
                            <ExpandablePropertyFilters datasetName={eventTrigger.datasetName} dataRepositoryName={eventTrigger.dataRepositoryName}
                                clientName={eventTrigger.clientName} matterName={eventTrigger.matterName} isDisabled={isDisabled}
                            />
                        </div>
                    </>
                    }

                    {scheduleTriggerType === scheduleTriggerTypeKeys.ON_LEGAL_HOLD_EVENT &&
                        <>
                            <div className="display-input">
                                <ExpandableEventTriggers type={'legalHold'} event={eventTrigger.legalHoldEvents} isDisabled={isDisabled}/>
                            </div>
                            <div className="display-input">
                                <ExpandablePropertyFilters legalHoldName={eventTrigger.legalHoldName} legalHoldDescription={eventTrigger.legalHoldDescription}
                                    clientName={eventTrigger.clientName} matterName={eventTrigger.matterName} isDisabled={isDisabled}
                                />
                            </div>
                        </>
                    }

                    {scheduleTriggerType === scheduleTriggerTypeKeys.ON_WEBHOOK_TRIGGER &&
                        <div className="display-input">
                            <ExpandableWebhookTriggers webhookTrigger={webhookTrigger} showUrl={false}
                                isDisabled={isDisabled}/>
                        </div>
                    }
                </Switch>


                <div className="display-input">
                    <ExpandableJobSettings {...names} priority={priority} isDisabled={isDisabled}/>
                </div>

                {(isNotEmptyNorFalsy(parameters) || names.library === SAME_AS_TRIGGERING_JOB) &&
                <div className="display-input">
                    <ExpandableJobParameters parameters={parameters} isDisabled={isDisabled}
                        sameAs={{
                            job: names.library === SAME_AS_TRIGGERING_JOB
                        }}
                    />
                </div>
                }

                <div className="display-input">
                    <ExpandableBacklogOperationTable operations={operations} isDisabled={isDisabled}
                        sameAs={{
                            job: names.library === SAME_AS_TRIGGERING_JOB
                        }}
                    />
                </div>
            </LoadingWrapper>
        </div>
    )
}

function ExpandableSettings(props) {
    const {scheduleName, enabled, isDisabled} = props;
    const {t} = useTranslation(['common']);

    return (
        <ExpandableContent label={t('common:label.settings')} isDisabled={isDisabled}>
            <div className="settings-table">
                <div className="table-row-group">
                    <SettingsRowValue label={t('common:label.name')} value={scheduleName} isDisabled={isDisabled}/>
                    {!enabled &&
                    <SettingsRowLabel label={t('common:label.inactive')} isDisabled={isDisabled}/>
                    }
                </div>
            </div>
        </ExpandableContent>
    )
}

export function ExpandableWebhookTriggers(props) {
    const {t} = useTranslation(['jobSchedule']);
    const {
        webhookTrigger,
        showUrl=true,
        isDisabled
    } = props;

    return (
        <ExpandableContent label={t('jobSchedule:label.webhookTrigger')} isDisabled={isDisabled}>
            <div className="settings-table align-top">
                <div className="table-row-group">
                    <SettingsRowValue label={t('jobSchedule:label.httpVerb')} value={webhookTrigger.httpVerb} isDisabled={isDisabled}/>
                    <SettingsRowValue label={t('jobSchedule:label.validateSignature')} isDisabled={isDisabled}
                        value={t(`jobSchedule:label.${webhookTrigger.useSignatureKey ? 'enabled' : 'disabled'}`)}/>

                    {showUrl &&
                        <SettingsRowValue label={t('jobSchedule:label.url')} valueIsItalic={webhookTrigger.url == null} isDisabled={isDisabled}
                            value={webhookTrigger.url != null ?
                                <CopySecretLabel copyText={webhookTrigger.url} isDisabled={isDisabled}/>
                                :
                                t('jobSchedule:message.webhookUrlOnlyVisibleToUsersWithSubmitJob')
                            }
                        />
                    }
                </div>
            </div>
        </ExpandableContent>
    )
}

function ExpandableConditions(props) {
    const {conditions: {expireAfter, commenceAfter, skipIfJobsRunning, skipIfJobsQueued, isDisabled}} = props;
    const {t} = useTranslation(['jobSchedule', 'common']);

    return (
        <ExpandableContent label={t('jobSchedule:label.conditions')} isDisabled={isDisabled}>
            {(commenceAfter.enabled || expireAfter.enabled) &&
            <div className="settings-table">
                <div className="table-row-group">
                    {commenceAfter.enabled &&
                    <SettingsRowValue label={t('jobSchedule:condition.commenceAfter')}
                        value={getLocaleStringFromFormattedDate(commenceAfter.date, commenceAfter.time)}/>
                    }
                    {expireAfter.enabled &&
                    <SettingsRowValue label={t('jobSchedule:condition.expireAfter')}
                        value={getLocaleStringFromFormattedDate(expireAfter.date, expireAfter.time)}/>
                    }
                    <SettingsRowSeparator/>
                </div>
            </div>
            }

            {skipIfJobsRunning.enabled &&
            <label className="label">
                {getPluralTranslations(t, 'jobSchedule:condition.skipIfJobsRunning', {count: skipIfJobsRunning.count})}
            </label>
            }
            {skipIfJobsQueued.enabled &&
            <label className="label">
                {getPluralTranslations(t, 'jobSchedule:condition.skipIfJobsQueued', {count: skipIfJobsQueued.count})}
            </label>
            }
        </ExpandableContent>
    )
}

function ExpandableScheduleTriggers(props) {
    const {scheduleTrigger: {addNextJobToStaging, startDate, startTime, frequency, recurEvery, recurOnDay}, isDisabled} = props;
    const {t} = useTranslation(['jobSchedule']);

    const selectedRecurOnDays = boolObjectToArray(recurOnDay)
        .map(day => t(`jobSchedule:recurOnDay.${day}`))
        .join(', ');

    return (
        <ExpandableContent label={t('jobSchedule:label.schedule')} isDisabled={isDisabled}>
            <div className="settings-table">
                <div className="table-row-group">
                    <SettingsRowValue label={t('jobSchedule:label.frequency')}
                        value={getPluralTranslations(t, `jobSchedule:recurs.${frequency}`, {
                            count: (frequency === scheduleFrequencyKeys.ONE_TIME ? 0 : recurEvery),
                            days: selectedRecurOnDays
                        })}/>

                    <SettingsRowValue label={t('common:option.start')}
                        value={getLocaleStringFromFormattedDate(startDate, startTime)}/>
                </div>
            </div>
            {addNextJobToStaging &&
                <Text value={t('jobSchedule:label.addNextJobToStaging')} />
            }
        </ExpandableContent>
    )
}

function ExpandableEventTriggers(props) {
    const {type, event, isDisabled} = props;
    const {t} = useTranslation(['jobSchedule', 'job']);

    return (
        <ExpandableContent label={t(`jobSchedule:label.${type}Events`)} isDisabled={isDisabled}>
            {boolObjectToArray(event)
                .map(trigger =>
                    <label key={trigger} className="label">
                        {t(`${type}:trigger.${trigger}`)}
                    </label>
            )}
        </ExpandableContent>
    )
}

function ExpandablePropertyFilters(props) {
    const {isDisabled, submissionMechanism={}, priority={}, ...properties} = props;
    const {t} = useTranslation(['jobSchedule']);

    const showProperties = (submissionMechanism.enabled) || (priority.enabled) ||
        getValues(properties).some(property => property.enabled);

    return (showProperties &&
        <ExpandableContent label={t('jobSchedule:label.propertyFilters')} isDisabled={isDisabled}>
            <div className="settings-table align-middle tight-padding">
                <div className="table-header-group">
                    <div className="table-header">
                        <label className="label is-bold">
                            {t('jobSchedule:label.property')}
                        </label>
                    </div>

                    <div className="table-header">
                        <label className="label is-bold">
                            {t('jobSchedule:label.contains')}
                        </label>
                    </div>
                </div>

                <div className="table-row-group">
                    {submissionMechanism &&
                    <SubmissionMechanismFilterRow submissionMechanism={submissionMechanism}/>
                    }

                    {getKeys(properties).map(property =>
                        <PropertyFilterRow key={property} name={t(`jobSchedule:filter.${property}`)} property={properties[property]}/>
                    )}

                    {priority &&
                    <PriorityFilterRow priority={priority}/>
                    }
                </div>
            </div>
        </ExpandableContent>
    )
}

function SubmissionMechanismFilterRow(props) {
    const {submissionMechanism: {enabled, ...mechanisms}} = props;
    const {t} = useTranslation(['jobSchedule']);

    const contains = objectTruthyValues(mechanisms)
        .map(m => t(`jobSchedule:submissionMechanism.${m}`))
        .join(', ');

    return (
        <PropertyFilterRow name={t('jobSchedule:filter.submissionMechanism')} property={{enabled, contains}}/>
    )
}

function PriorityFilterRow(props) {
    const {priority: {enabled, ...priorities}} = props;
    const {t} = useTranslation(['common']);

    const contains = getKeys(priorities)
        .map(p => t(`common:priority.${p}`))
        .join(', ');

    return (
        <PropertyFilterRow name={t('jobSchedule:filter.priorities')} property={{enabled, contains}}/>
    )
}

function PropertyFilterRow(props) {
    const {name, property} = props;

    if (!property.enabled)
        return null;

    return (
        <div className="settings-row">
            <div className="table-cell" style={{width: '12rem'}}>
                <label className="label">
                    {name}
                </label>
            </div>

            <div className="table-cell">
                <label className="label">
                    {property.contains}
                </label>
            </div>
        </div>
    )
}

export function ExpandableJobSettings(props) {
    const {priority, isDisabled, ...names} = props;
    const {t} = useTranslation(['jobSchedule', 'job', 'common']);

    const labels = getEntries(names)
        .reduce((prev, [key, name]) => {
            if ([SAME_AS_TRIGGERING_JOB, SAME_AS_TRIGGERING_DATASET, SAME_AS_TRIGGERING_LEGAL_HOLD].includes(name)) {
                prev[key] = t(`jobSchedule:option.${name}`);
            } else {
                prev[key] = name;
            }

            return prev;
        }, {});

    let executionProfileLabel = labels.executionProfile;
    if (!labels.executionProfile) {
        executionProfileLabel =
            <div style={{display: 'flex'}}>
                <Text value={t('common:label.unassigned')} isDisabled={isDisabled}/>
                <StatusLabel message={t('job:message.unassignedExecutionProfile')} style={{marginLeft: '1rem'}}
                    isDisabled={isDisabled}/>
            </div>
    }

    let resourcePoolLabel = labels.resourcePool;
    if (!labels.resourcePool) {
        resourcePoolLabel =
            <div style={{display: 'flex'}}>
                <Text value={t('common:label.unassigned')} isDisabled={isDisabled}/>
                <StatusLabel message={t('job:message.unassignedResourcePool')} style={{marginLeft: '1rem'}}
                    isDisabled={isDisabled}/>
            </div>
    }

    let priorityLabel;
    if (priority === SAME_AS_TRIGGERING_JOB) {
        priorityLabel = t(`jobSchedule:option.${priority}`);
    } else if (priority == null) {
        priorityLabel = '-';
    } else {
        priorityLabel = t(`common:priority.${priority}`);
    }

    let matterLabel;
    if ([SAME_AS_TRIGGERING_JOB, SAME_AS_TRIGGERING_DATASET, SAME_AS_TRIGGERING_LEGAL_HOLD].includes(names.client)) {
        matterLabel = labels.client;
    } else {
        matterLabel = labels.matter;
    }

    let workflowLabel;
    if (names.library === SAME_AS_TRIGGERING_JOB) {
        workflowLabel = labels.library;
    } else {
        workflowLabel = labels.workflow;
    }

    return (
        <ExpandableContent label={t('job:label.settings')} isDisabled={isDisabled}>
            <div className="settings-table">
                <div className="table-row-group">
                    <SettingsRowValue label={t('job:label.executionProfile')} value={executionProfileLabel}
                        isDisabled={isDisabled}/>
                    <SettingsRowValue label={t('job:label.resourcePool')} value={resourcePoolLabel}
                        isDisabled={isDisabled}/>

                    <SettingsRowValue label={t('job:label.priority')} isDisabled={isDisabled} value={
                        <PriorityLabel priority={priority} priorityLabel={priorityLabel}
                            isDisabled={isDisabled}/>
                    }/>

                    <SettingsRowSeparator/>
                    <SettingsRowValue label={t('job:label.client')} value={labels.client} isDisabled={isDisabled}/>
                    <SettingsRowValue label={t('job:label.matter')} value={matterLabel} isDisabled={isDisabled}/>

                    <SettingsRowSeparator/>
                    <SettingsRowValue label={t('job:label.library')} value={labels.library} isDisabled={isDisabled}/>
                    <SettingsRowValue label={t('job:label.workflow')} value={workflowLabel} isDisabled={isDisabled}/>
                </div>
            </div>
        </ExpandableContent>
    )
}

export function ExpandableJobParameters(props) {
    const {sameAs={}, parameters, isDisabled} = props;
    const {t} = useTranslation(['jobSchedule', 'workflow']);

    return (
        <ExpandableContent label={t('workflow:label.parameters')} isDisabled={isDisabled}>
            <Switch>
                {sameAs.job &&
                <label className="label">
                    {t(`jobSchedule:option.${SAME_AS_TRIGGERING_JOB}`)}
                </label>
                }
                <ParametersTable parameters={parameters} useUserFromCredential
                    isDisabled={isDisabled}/>
            </Switch>
        </ExpandableContent>
    )
}

export function ExpandableBacklogOperationTable(props) {
    const {sameAs={}, operations, isDisabled} = props;
    const {t} = useTranslation(['jobSchedule', 'job']);

    return (
        <ExpandableContent label={t('job:label.workflow')} isDisabled={isDisabled}>
            <Switch>
                {sameAs.job &&
                    <Text value={t(`jobSchedule:option.${SAME_AS_TRIGGERING_JOB}`)} isDisabled={isDisabled}/>
                }
                <OperationBacklogTable operations={operations} isDisabled={isDisabled}/>
            </Switch>
        </ExpandableContent>
    )
}

export function getScheduleObjectNames(state, jobSchedule) {
    function getName(id, detailsMap) {
        if ([SAME_AS_TRIGGERING_JOB, SAME_AS_TRIGGERING_DATASET, SAME_AS_TRIGGERING_LEGAL_HOLD].includes(id)) {
            return id;
        }
        const details = detailsMap.get(id);
        if (details != null) {
            return details.name;
        }
    }

    return {
        client: getName(jobSchedule.clientId, state.clientDetailsMap),
        matter: getName(jobSchedule.matterId, state.matterDetailsMap),
        library: getName(jobSchedule.libraryId, state.libraryDetailsMap),
        workflow: getName(jobSchedule.workflowTemplateId, state.workflowTemplateDetailsMap),
        executionProfile: getName(jobSchedule.executionProfileId, state.executionProfileDetailsMap),
        resourcePool: getName(jobSchedule.resourcePoolId, state.resourcePoolDetailsMap)
    }
}

export default ScheduleConfirmPane;
