import React from 'react';
import {connect} from 'react-redux';
import {applicationFeatures, updateMode} from "../../utilities/constants";
import {AddButtonHeader} from "../common/Button/Button";
import SearchBar from "../common/SearchBar/SearchBar";
import {useTranslation} from "react-i18next";
import JobScheduleModel from "../../models/job/JobScheduleModel";
import {includesAll} from "../../utilities/helperFunctions";
import {createInputHandler} from "../../utilities/componentFunctions";

function JobScheduleHeader(props) {
    const {searchText, userFeatures, showScheduleForm, updateState} = props;
    const {t} = useTranslation(['jobSchedule']);

    const inputHandler = createInputHandler({
        mode: updateMode.REDUX,
        updateState
    });
    const canScheduleJob = includesAll(userFeatures, [applicationFeatures.ADD_JOB, applicationFeatures.ADD_SCHEDULE]);

    return (
        <>
            <div className="header-section left">
                <div className="header-item">
                    <AddButtonHeader useH1 aria-label={t('aria:hiddenAssistText.addJobSchedule')} text={t('jobSchedule:label.name')}
                        onClick={showScheduleForm} style={{margin: '0'}} canModify={canScheduleJob}/>
                </div>
            </div>

            <div className="header-section right">
                <div className="header-item">
                    <SearchBar value={searchText} onChange={inputHandler}/>
                </div>
            </div>
        </>
    )
}

function mapStateToProps(state) {
    const {currentUser, componentStates: {jobScheduleDisplay: {searchText}}} = state;

    return {
        userFeatures: currentUser.features,
        searchText
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateState: updates => dispatch(JobScheduleModel.componentActionCreators.updateDisplay(updates)),
        showScheduleForm: () => dispatch(JobScheduleModel.actionCreators.showForm())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobScheduleHeader);