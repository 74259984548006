import React, {useEffect, useLayoutEffect, useRef} from 'react';
import {useTranslation} from "react-i18next";
import {applicationFeatures, MED_QUERY_INTERVAL, systemResources} from "../../utilities/constants";
import {useDispatch, useSelector} from "react-redux";
import DownloadLogsOptions from "./DownloadLogsOption/DownloadLogsOption";
import DownloadUtilizationOptions from "./DownloadUtilizationOptions/DownloadUtilizationOptions";
import ExpandableContent from "../common/ExpandableContent/ExpandableContent";
import {StatusLabel} from "../common/Common";
import {SettingsRowValue} from "../common/CustomTable/CustomTable";
import Text from "../common/Text/Text";
import {Button} from "../common/Button/Button";
import UserDataDirModel, {UserDataDirApi} from "../../models/system/UserDataDirModel";
import SetUserDataDirForm from "../userDataDir/SetUserDataDirForm";
import PopupModel from "../../models/scheduler/PopupModel";
import SchedulerModel from "../../models/scheduler/SchedulerModel";
import {permissionKeys} from "../../i18next/keys";
import {getLocaleDateTimeFromUTC, getValues} from "../../utilities/helperFunctions";
import UserDataDirTablet from "../userDataDir/UserDataDirTablet";
import LibraryModel, {LibraryApi} from "../../models/library/LibraryModel";
import WorkflowTemplateModel from "../../models/library/WorkflowTemplateModel";
import {download} from "../../utilities/downloadHelper";
import {createFileHandler} from "../../utilities/componentFunctions";
import encode from "base64-arraybuffer";
import {pollUntil} from "../../saga/sagaFunctions";
import {MainPageContent, MainPageHeader} from "../app/pages/MainPage";

function SystemResourcesDisplay (props) {
    const {t} = useTranslation(['systemResources', 'systemLogs', 'common']);
    const dispatch = useDispatch();

    const {
        ...attr
    } = props;

    const userFeatures = useSelector(state => state.currentUser.features);
    const {javaVendor, javaVersion, javaVersionNotRecommended: javaVersionBelowRecommended} = useSelector(state => state.schedulerDetails.configuration);

    const {
        version
    } = useSelector(state => state.automateLicence);

    const {
        isTabletActive: isUserDataDirTabletActive,
        isFormActive: isUserDataDirFormActive
    } = useSelector(state => state.componentStates.userDataDirDisplay);

    useLayoutEffect(() => {
        dispatch(UserDataDirModel.componentActionCreators.setDisplayActive())
        return () => dispatch(UserDataDirModel.componentActionCreators.setDisplayInactive());
    }, [dispatch]);


    const canViewUserDataDir = userFeatures.includes(applicationFeatures.VIEW_USER_DATA_DIR);
    const canSetUserDataDir = userFeatures.includes(applicationFeatures.MODIFY_USER_DATA_DIR);
    const canDownloadLogs = userFeatures.includes(applicationFeatures.DOWNLOAD_SYSTEM_LOGS);
    const canDownloadFull = userFeatures.includes(applicationFeatures.DOWNLOAD_UTILIZATION_FULL);
    const canDownloadAnonymous = userFeatures.includes(applicationFeatures.DOWNLOAD_UTILIZATION_ANONYMOUS);
    const canUploadUtilization = userFeatures.includes(applicationFeatures.UPLOAD_UTILIZATION);
    const canViewLibraries = userFeatures.includes(applicationFeatures.VIEW_LIBRARIES);
    const canModifyLibraries = userFeatures.includes(applicationFeatures.ADD_LIBRARY);

    return (
        <MainPageContent {...attr}
            title={
                <MainPageHeader text={t('systemResources:page.title')}
                              description={"Automate " + version}/>
            }
            list={
                <>
                    <div className="display-item">
                        <ExpandableContent label={t('systemResources:label.java')}>
                            <div className="settings-table">
                                <div className="table-row-group">
                                    <SettingsRowValue label={t('systemResources:label.vendor')} value={javaVendor}/>
                                    <SettingsRowValue label={t('systemResources:label.version')} value={
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <Text value={javaVersion} style={{marginRight: '0.5rem'}}/>
                                            {javaVersionBelowRecommended &&
                                                <StatusLabel message={t('systemResources:message.javaVersionBelowRecommended', javaVersionBelowRecommended)}/>
                                            }
                                        </div>
                                    }/>
                                </div>
                            </div>
                        </ExpandableContent>
                    </div>

                    {canViewUserDataDir &&
                    <div className="display-item">
                        <UserDataDirSection canModify={canSetUserDataDir}/>
                    </div>
                    }

                    {(canViewLibraries || canModifyLibraries) &&
                        <div className="display-item">
                            <LibrariesSection canDownload={canViewLibraries} canUpload={canModifyLibraries}
                                isDisabled={attr.isDisabled}/>
                        </div>
                    }

                    {canDownloadLogs &&
                    <div className="display-item">
                        <ExpandableContent label={t('systemLogs:title')}>
                            <DownloadLogsOptions isDisabled={attr.isDisabled}/>
                        </ExpandableContent>
                    </div>
                    }

                    {(canDownloadFull || canDownloadAnonymous || canUploadUtilization) &&
                    <div className="display-item">
                        <ExpandableContent label={t('utilization:title')}>
                            <DownloadUtilizationOptions canDownloadFull={canDownloadFull} canDownloadAnonymous={canDownloadAnonymous}
                                canUploadUtilization={canUploadUtilization} isDisabled={attr.isDisabled}/>
                        </ExpandableContent>
                    </div>}
                </>
            }
            children={
                <>
                    {isUserDataDirTabletActive &&
                        <UserDataDirTablet/>
                    }
                    {isUserDataDirFormActive &&
                        <SetUserDataDirForm/>
                    }
                </>
            }
        />
    );
}

function LibrariesSection(props) {
    const {t} = useTranslation(['systemResources', 'common']);
    const dispatch = useDispatch();

    const {
        isDisabled
    } = props;

    const [libraryDetailsMap, workflowsCount] = useSelector(state => {
        return [state.libraryDetailsMap, getValues(state.workflowTemplateDetailsMap).length];
    });
    const uploadInputRef = useRef();

    useEffect(() => {
        dispatch(LibraryModel.actionCreators.startPollingDetails(MED_QUERY_INTERVAL));
    }, []);

    useEffect(() => {
        const flag = 'STOP_POLLING_ALL_WORKFLOWS';
        dispatch(SchedulerModel.actionCreators.yieldEffectDescriptor(pollUntil(flag, MED_QUERY_INTERVAL, function() {
            for (const [id, ] of libraryDetailsMap) {
                dispatch(WorkflowTemplateModel.actionCreators.queryDetailsSubset(id));
            }
        })));

        return () => {
            dispatch({type: flag});
        }
    }, [libraryDetailsMap]);

    function downloadLibrariesZip() {
        download('/scheduler/library/downloadZip');
    }

    function uploadLibrariesZip() {
        uploadInputRef.current.click();
    }

    const onFileChange =
        createFileHandler({
            onloadend: async event => {
                const fileData = encode.encode(event.target.result);
                const {data} = await LibraryApi.postLibraries(fileData);

                let popupAction = 'showSuccess', popupKey = 'uploadedLibrariesZipAddUpdated';

                if (data.librariesWithErrors || data.workflowsWithErrors || data.invalidEntries) {
                    popupAction = 'showWarning';
                    if (data.invalidEntries) {
                        popupKey = 'uploadedLibrariesZipAll'
                    } else {
                        popupKey = 'uploadedLibrariesZipAllValid'
                    }
                }

                dispatch(PopupModel.actionCreators[popupAction]({
                    info: {
                        key: popupKey, values: data
                    },
                    cancelButton: {
                        title: t('common:option.ok')
                    }
                }));
            }
        });


    const librariesCount = getValues(libraryDetailsMap).length;

    return (
        <ExpandableContent label={t('systemResources:label.libraries')}>
            {(librariesCount || workflowsCount) &&
                <div className="settings-table">
                    <div className="table-row-group">
                        <SettingsRowValue label={t('systemResources:label.librariesCount')} value={librariesCount}
                            enableHighlightText isDisabled={isDisabled}/>

                        <SettingsRowValue label={t('systemResources:label.workflowsCount')} value={workflowsCount}
                            enableHighlightText isDisabled={isDisabled}/>
                    </div>
                </div>
            }

            <div className="display-input flex-center" style={{gap: '0.5rem'}}>
                <Button label={t('common:option.download')} onClick={downloadLibrariesZip}
                    isDisabled={isDisabled}/>

                <Button label={t('common:option.upload')} onClick={uploadLibrariesZip}
                    isDisabled={isDisabled}/>

                <input style={{display: 'none'}} type="file" accept={".zip"} onChange={onFileChange}
                    ref={uploadInputRef} tabIndex={-1} disabled={isDisabled}/>
            </div>
        </ExpandableContent>
    )
}

function UserDataDirSection(props) {
    const {t} = useTranslation(['systemResources', 'common']);
    const dispatch = useDispatch();

    const {
        canModify
    } = props;

    const userDataDir = useSelector(state => state.userDataDir);

    function showTablet() {
        dispatch(UserDataDirModel.componentActionCreators.updateDisplay({isTabletActive: true}));
    }

    function showForm() {
        dispatch(UserDataDirModel.componentActionCreators.updateDisplay({isFormActive: true}));
    }

    function removePath() {
        dispatch(PopupModel.actionCreators.showWarning({
            info: {
                key: 'userDataDirRemovePathWarning'
            },
            buttons: [{
                titleKey: 'common:option.remove',
                onClick: async () => {
                    try {
                        await UserDataDirApi.remove(userDataDir.id);
                        dispatch(UserDataDirModel.actionCreators.removeUserDataDir());
                    } catch (error) {
                        dispatch(SchedulerModel.actionCreators.handleResponseError(error));
                    }
                }
            }]
        }));
    }

    const canRemovePath = !!userDataDir?.id;
    const canViewFiles = Array.isArray(userDataDir?.userPermissions) && userDataDir.userPermissions.includes(permissionKeys.VIEW);

    return (
        <ExpandableContent label={t('systemResources:label.userDataDir')}>
            <div className="display-input" style={{display: 'flex', gap: '0.5rem'}}>
                {canModify &&
                    <Button id="userDataDirSetPath" label={t('systemResources:label.setPath')}
                        onClick={showForm}/>
                }

                <Button id="userDataDirViewFiles" label={t('systemResources:label.viewFiles')}
                    onClick={showTablet} isDisabled={!canViewFiles}/>

                {canModify &&
                    <Button id="userDataDirRemovePath" label={t('systemResources:label.removePath')}
                        onClick={removePath} isDisabled={!canRemovePath}/>
                }
            </div>

            <div className="settings-table display-input">
                <div className="table-row-group">
                    <SettingsRowValue label={t('systemResources:label.path')}
                        value={userDataDir.path}/>
                    <SettingsRowValue label={t('systemResources:label.lastSyncDate')}
                        value={getLocaleDateTimeFromUTC(userDataDir.lastUpdatedDate)}/>
                    <SettingsRowValue label={t('systemResources:label.fileCount')}
                        value={userDataDir.fileCount}/>
                    {userDataDir.fileSkippedCount > 0 &&
                        <SettingsRowValue label={t('systemResources:label.fileSkippedCount')}
                            value={userDataDir.fileSkippedCount}/>
                    }
                </div>
            </div>

        </ExpandableContent>
    )
}


export default SystemResourcesDisplay;