import React from 'react';
import {connect} from 'react-redux';
import CustomizableCard from "../common/CustomizableCard/CustomizableCard";
import {buildCardItems} from "../userSettings/JobCardDesigner/helpers";
import {makeGetNamesFromJobDetails} from "../../reselect/selectors";
import {positionKeys} from "../../i18next/keys";
import {userSettings} from "../../utilities/constants";
import JobModel from "../../models/job/JobModel";
import {useTranslation} from "react-i18next";
import {userIconModel} from "../../models/generics/IconModel";
import UserModel from "../../models/user/UserModel";


const JobCard = props => {
    const {job, names, jobCardDesigner, showJobDisplay, isActive} = props;
    const {t} = useTranslation(['job', 'common']);

    const [submittedByUsername, submittedByUserIconName] = UserModel.useUserNameAndIconName(job.submittedBy);
    const userIconUrl = userIconModel.useIcon(submittedByUserIconName);

    const jobCardItems = buildCardItems(t, jobCardDesigner, {
        ...job,
        ...names,
        submittedBy: submittedByUsername,
        userIconUrl: <img className="round-image" src={userIconUrl}
            alt={t('aria:hiddenAssistText.userIcon')}/>
    });

    return (
        <CustomizableCard name={'jobCard'} value={job.id} onClick={showJobDisplay} isActive={isActive}
            topRight={jobCardItems[positionKeys.TOP_RIGHT]}
            topLeft={jobCardItems[positionKeys.TOP_LEFT]}
            middle={jobCardItems[positionKeys.MIDDLE]}
            bottomRight={jobCardItems[positionKeys.BOTTOM_RIGHT]}
            bottomLeft={jobCardItems[positionKeys.BOTTOM_LEFT]}
        />
    );
};

const makeMapStateToProps = () => {
    const geNamesFromJobDetails = makeGetNamesFromJobDetails();
    return (state, ownProps) => {
        const {userSettingsMap} = state;

        return {
            names: geNamesFromJobDetails(state, {jobId: ownProps.job.id}),
            jobCardDesigner: userSettingsMap.get(userSettings.JOB_CARD),
        };
    }
};

const mapDispatchToProps = dispatch => {
    return {
        showJobDisplay: event => dispatch(JobModel.actionCreators.showTablet(event.currentTarget.dataset.value))
    };
};

export default connect(makeMapStateToProps, mapDispatchToProps)(JobCard);