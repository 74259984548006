import React from "react";
import ExpandableContent from "../../common/ExpandableContent/ExpandableContent";
import {SettingsRowSeparator, SettingsRowValue} from "../../common/CustomTable/CustomTable";
import {useTranslation} from "react-i18next";

function RelativityServiceSettings(props) {
    const {t} = useTranslation(['thirdPartyService']);
    const {
        thirdPartyService,
        style,
        isDisabled
    } = props;

    return (
        <ExpandableContent label={t('thirdPartyService:label.relativitySettings')}
            style={style} isDisabled={isDisabled}
        >
            <div className="settings-table">
                <div className="table-row-group">
                    <SettingsRowValue label={t('thirdPartyService:label.hostname')}
                        value={thirdPartyService.hostname} isDisabled={isDisabled}/>

                    <SettingsRowValue label={t('thirdPartyService:label.serviceEndpoint')}
                        value={thirdPartyService.serviceEndpoint} isDisabled={isDisabled}/>

                    <SettingsRowValue label={t('thirdPartyService:label.endpointType')}
                        value={t(`thirdPartyService:endpointType.${thirdPartyService.endpointType}`)}
                        isDisabled={isDisabled}/>

                    <SettingsRowValue label={t('thirdPartyService:label.relativityRestVersion')}
                        value={t(`thirdPartyService:relativityRestVersion.${thirdPartyService.relativityRestVersion}`)} isDisabled={isDisabled}/>

                    <SettingsRowSeparator/>
                    <SettingsRowValue label={t('thirdPartyService:label.importThreads')}
                        value={thirdPartyService.importThreads} isDisabled={isDisabled}/>

                    <SettingsRowValue label={t('thirdPartyService:label.importThreadTimeout')}
                        value={t('common:duration.seconds', {count: thirdPartyService.importThreadTimeout})} isDisabled={isDisabled}/>

                    <SettingsRowValue label={t('thirdPartyService:label.importThreadRetries')}
                        value={thirdPartyService.importThreadRetries} isDisabled={isDisabled}/>

                    <SettingsRowValue label={t('thirdPartyService:label.metadataThreads')}
                        value={thirdPartyService.metadataThreads} isDisabled={isDisabled}/>

                    <SettingsRowSeparator/>
                    <SettingsRowValue label={t('thirdPartyService:label.patchInvalidEntries')}
                        value={t(`common:option.${!!thirdPartyService.patchInvalidEntries}`)} isDisabled={isDisabled}/>

                    {thirdPartyService.useCustomClientVersion &&
                        <SettingsRowValue label={t('thirdPartyService:label.customClientVersion')}
                            value={thirdPartyService.customClientVersion} isDisabled={isDisabled}/>
                    }
                </div>
            </div>
        </ExpandableContent>
    )
}

export default RelativityServiceSettings;
