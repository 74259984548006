import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import React, {useRef, useState} from "react";
import UserServiceModel from "../../../models/user/UserServiceModel";
import {createCloseHandler, createInputHandler, createStateHandler} from "../../../utilities/componentFunctions";
import PopupModel from "../../../models/scheduler/PopupModel";
import Form, {FormHeader} from "../../common/Form/Form";
import HTMLTextInput from "../../common/HTMLTextInput/HTMLTextInput";
import TextArea from "../../common/TextArea/TextArea";
import {ButtonGroup} from "../../common/Button/Button";
import Checkbox from "../../common/Checkbox/Checkbox";
import {createToggleHandler} from "../../common/Checkbox/helpers";
import {useClearOnFirstCallback} from "../../../utilities/formHooks";
import {ValueList} from "../../common/InputList/InputList";

function OidcUserServiceForm() {
    const {t} = useTranslation(['userService', 'common']);
    const dispatch = useDispatch();

    const {
        isDisabled
    } = useSelector(state => state.componentStates.userServiceForm);

    const [state, setState] = useState({
        type: UserServiceModel.types.OIDC,
        name: '',
        description: '',
        enabled: true,
        restrictByUrl: false,
        allowedUrls: [],

        clientId: '',
        scope: '',
        usernameClaim: '',
        groupClaim: '',
        wellKnownConfiguration: '',

        enableAuthentication: true,
        enableCollections: false,

        enableAuthorizationCodeFlow: true,
        enableJwtAccessToken: false,
        linkProfilesWithSyncedUsers:false,


        isAddEnabled: false
    });

    const clientSecretRef = useRef({});
    const stateHandler = createStateHandler({
        updateState: setState,
        passwordOptions: {
            'clientSecret': {
                ref: clientSecretRef,
                resetNames: ['clientId', 'scope', 'usernameClaim', 'groupClaim', 'wellKnownConfiguration']
            }
        },
        shouldEnable: UserServiceModel.validateFormData
    });
    const inputHandler = createInputHandler({
        handler: stateHandler
    })
    const toggleHandler = createToggleHandler({
        handler: stateHandler
    });
    const [whitelistedCertFingerprints, setWhitelistedCertFingerprints] = useState([]);

    const [allowedUrls, setAllowedUrls] = useState([]);

    function onSubmit() {
        dispatch(UserServiceModel.actionCreators.submitForm({...state, whitelistedCertFingerprints, allowedUrls}));
    }

    const onClose = createCloseHandler({
        t,
        item: t('userService:label.name'),
        values: state,
        blacklist: ['type', 'searchDelay'],
        showWarning: payload => dispatch(PopupModel.actionCreators.showWarning(payload)),
        close: () => dispatch(UserServiceModel.actionCreators.hideForm(UserServiceModel.types.OIDC))
    });

    const {
        name,
        description,
        enabled,
        restrictByUrl,
        wellKnownConfiguration,
        isAddEnabled,
    } = state;

    const iconName = UserServiceModel.getTranslationType(UserServiceModel.types.OIDC, wellKnownConfiguration);

    return (
        <Form onClose={onClose} isDisabled={isDisabled} closeButtonAriaLabel={t('userService:option.closeFormOidc')}
              header={
                  <FormHeader text={t('userService:label.oidcUserServiceTitle')} iconName={iconName} isDisabled={isDisabled} />
              }
              body={
                  <>
                      <div className="display-input">
                          <HTMLTextInput label={t('common:label.name')} name={'name'} value={name}
                                         onChange={inputHandler}
                                         isDisabled={isDisabled} isRequired/>
                      </div>

                      <div className="display-input">
                          <Checkbox label={t('common:label.enabled')} name={'enabled'} checked={enabled}
                                    onClick={toggleHandler}
                                    isDisabled={isDisabled}/>
                      </div>

                      <div className="display-input">
                          <TextArea label={t('common:label.description')} name={'description'} value={description}
                                    onChange={inputHandler}
                                    isDisabled={isDisabled}/>
                      </div>

                      <div className="display-input">
                          <Checkbox label={t('userService:label.restrictByUrl')} name={'restrictByUrl'}
                                    checked={restrictByUrl} onClick={toggleHandler}
                                    isDisabled={isDisabled}/>
                      </div>

                      {restrictByUrl &&
                          <div className="display-input">
                              <ValueList id={'allowedUrls'} label={t('userService:label.allowedUrls')}
                                         values={allowedUrls} setValues={setAllowedUrls}
                                         ariaLabelKey={'AllowedUrls'} isDisabled={isDisabled}/>
                          </div>
                      }

                      <OidcUserServiceSettings {...state} whitelistedCertFingerprints={whitelistedCertFingerprints}
                                               clientSecretRef={clientSecretRef}
                                               inputHandler={inputHandler} toggleHandler={toggleHandler}
                                               setWhitelistedCertFingerprints={setWhitelistedCertFingerprints}
                                               isDisabled={isDisabled}/>
                  </>
              }
              footer={
                  <ButtonGroup buttons={[{
                      id: 'formFooterBackButton',
                      label: t('common:option.cancel'),
                      onClick: onClose,
                      isDisabled
                  }, {
                      id: 'formFooterNextButton',
                      label: t('userService:option.addUserService'),
                      onClick: onSubmit,
                      isDisabled: isDisabled || !isAddEnabled
                  }]}/>
              }
        />
    )
}

export function OidcUserServiceSettings (props) {
    const {t} = useTranslation(['userService', 'common']);
    const {
        clientId,
        clientSecret,
        clientSecretRef,
        scope,
        usernameClaim,
        groupClaim,
        wellKnownConfiguration,
        enableAuthentication,
        whitelistedCertFingerprints,
        showWhitelistedCertFingerprints=true,

        enableAuthorizationCodeFlow,
        enableJwtAccessToken,
        linkProfilesWithSyncedUsers,

        inputHandler,
        toggleHandler,
        setWhitelistedCertFingerprints,
        isDisabled
    } = props;

    const clearOnFirstCallback = useClearOnFirstCallback();
    return (
        <>
            <div className="display-input">
                <HTMLTextInput label={t(`userService:oidc.GENERIC.wellKnownConfiguration`)}
                               name={'wellKnownConfiguration'} value={wellKnownConfiguration} onChange={inputHandler}
                               isDisabled={isDisabled} isRequired/>
            </div>

            <div className="display-input">
                <HTMLTextInput label={t(`userService:oidc.GENERIC.scope`)} name={'scope'} value={scope}
                               onChange={inputHandler}
                               isDisabled={isDisabled} isRequired/>
            </div>

            <div className="display-input">
                <HTMLTextInput label={t('userService:oidc.usernameClaim')} name={'usernameClaim'} value={usernameClaim}
                               onChange={inputHandler}
                               isDisabled={isDisabled} isRequired/>
            </div>

            <div className="display-input">
                <HTMLTextInput label={t('userService:oidc.groupClaim')} name={'groupClaim'} value={groupClaim}
                               onChange={inputHandler}
                               isDisabled={isDisabled}/>
            </div>

            <div className="display-input">
                <HTMLTextInput label={t('userService:oidc.clientId')} name={'clientId'} value={clientId}
                               onChange={inputHandler}
                               isDisabled={isDisabled} isRequired/>
            </div>

            <div className="display-input">
                <HTMLTextInput label={t('userService:oidc.clientSecret')} name={'clientSecret'} type={'password'}
                               inputRef={clientSecretRef}
                               onChange={inputHandler} onBeforeInput={clearOnFirstCallback} isDisabled={isDisabled}
                               defaultValue={clientSecret} isRequired/>
            </div>

            <div className="display-input">
                <Checkbox label={t('userService:label.enableAuthentication')} name={"enableAuthentication"}
                          checked={enableAuthentication} onClick={toggleHandler} isDisabled={isDisabled}/>
            </div>

            <div className="display-input">
                <Checkbox label={t('userService:label.enableAuthorizationCodeFlow')}
                          name={"enableAuthorizationCodeFlow"}
                          checked={enableAuthorizationCodeFlow} onClick={toggleHandler} isDisabled={isDisabled}/>
            </div>

            <div className="display-input">
                <Checkbox label={t('userService:label.enableJwtAccessToken')} name={"enableJwtAccessToken"}
                          checked={enableJwtAccessToken} onClick={toggleHandler} isDisabled={isDisabled}/>
            </div>

            <div className="display-input">
                <Checkbox label={t('userService:label.linkProfilesWithSyncedUsers')} name={"linkProfilesWithSyncedUsers"}
                          checked={linkProfilesWithSyncedUsers} onClick={toggleHandler} isDisabled={isDisabled}/>
            </div>

            {showWhitelistedCertFingerprints &&
                <div className="display-input">
                    <ValueList id={'whitelistedCertFingerprints'} label={t('common:label.whitelistedCertFingerprints')}
                        values={whitelistedCertFingerprints} setValues={setWhitelistedCertFingerprints}
                        ariaLabelKey={'WhitelistedCertFingerprint'} isDisabled={isDisabled}/>
                </div>
            }
        </>
    )
}

export default OidcUserServiceForm;
