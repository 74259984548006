import ComponentStateModel from "../generics/ComponentStateModel";
import SagaRunnable from "../generics/SagaRunnable";
import {put} from "redux-saga/effects";
import {FAST_QUERY_INTERVAL} from "../../utilities/constants";
import JobModel from "./JobModel";

class JobQueueModel {

    static componentActionCreators = {
        ...JobQueueModel.buildComponentUpdateActionCreators(),
        ...JobQueueModel.buildComponentSetActiveActionCreators()
    };

    static buildComponentUpdateActionCreators() {
        const components = [
            {
                key: 'jobQueueDisplay',
                type: 'Display',
                state: {
                    jobQueueId: null,
                    searchText: '',
                    stateFilters: {}
                }
            }
        ];

        return ComponentStateModel.buildUpdateActionCreators(...components);
    }

    static buildComponentSetActiveActionCreators() {
        const components = [
            {
                key: 'JOB_QUEUE_DISPLAY',
                type: 'Display'
            }
        ];

        return ComponentStateModel.buildSetActiveActionCreators(...components);
    }
}

export class JobQueueSaga extends SagaRunnable {

    static activationComponent = 'JOB_QUEUE_DISPLAY';

    static buildActivationEffects(dispatch) {
        return [
            // ACTIVATION EFFECTS
            put(JobModel.actionCreators.startPollingDetails(FAST_QUERY_INTERVAL))
        ]
    }

    static buildDeactivationEffects() {
        return [
            // DEACTIVATION EFFECTS
            put(JobModel.actionCreators.stopPollingDetails())
        ]
    }
}

export default JobQueueModel;